import OrderFormTemplate from './OrderFormTemplate';
import LabelInput from './LabelInput';
import { numRegEx } from '../utils/miscellaneous';
import './OrderForm2.scss';

export default function OrderForm2({ onNext, onBack, status, formik }) {
  const onClickNextHandler = async () => {
    formik.setTouched({
      ...formik.touched,
      companyName: true,
      firstName: true,
      lastName: true,
      email: true,
      phone: true,
    });

    const errors = await formik.validateForm();

    if (
      !errors.companyName &&
      !errors.firstName &&
      !errors.lastName &&
      !errors.email &&
      !errors.phone
    )
      onNext();
  };

  return (
    <OrderFormTemplate
      header='How do we get in touch?'
      status={status}
      onNext={onClickNextHandler}
      onBack={onBack}
      disabled={
        (formik.values.moveType === 'Freight' &&
          (formik.errors.companyName || !formik.values.companyName)) ||
        formik.errors.firstName ||
        formik.errors.lastName ||
        formik.errors.email ||
        formik.errors.phone ||
        !formik.values.firstName ||
        !formik.values.lastName ||
        !formik.values.email ||
        !formik.values.phone
      }
    >
      <div className='order-form-2-wrapper'>
        {formik.values.moveType === 'Freight' && (
          <LabelInput
            label='Company Name*'
            placeholder='Company name'
            name='companyName'
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.companyName && formik.errors.companyName
                ? formik.errors.companyName
                : null
            }
          />
        )}
        <LabelInput
          label='First Name*'
          placeholder='Your first name'
          name='firstName'
          value={formik.values.firstName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.firstName && formik.errors.firstName
              ? formik.errors.firstName
              : null
          }
        />
        <LabelInput
          label='Last Name*'
          placeholder='Your last name'
          name='lastName'
          value={formik.values.lastName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.lastName && formik.errors.lastName
              ? formik.errors.lastName
              : null
          }
        />
        <LabelInput
          label='Email Address*'
          placeholder='Your email address'
          type='email'
          name='email'
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
        />
        <div className='flag-input-wrapper'>
          <select>
            <option value='US'>USA</option>
            <option value='AU'>AUS</option>
          </select>
          <LabelInput
            className='phone-input'
            label='Phone number*'
            placeholder='Your phone'
            type='tel'
            name='phone'
            value={formik.values.phone}
            onChange={(e) => {
              if (numRegEx.test(e.target.value)) formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            error={
              formik.touched.phone && formik.errors.phone
                ? formik.errors.phone
                : null
            }
          />
        </div>
      </div>
    </OrderFormTemplate>
  );
}
