import './CheckBox.scss';

export default function CheckBox({
  label,
  name,
  checked,
  value,
  onChange,
  className,
}) {
  return (
    <div className={'checkbox' + (className ? ' ' + className : '')}>
      <input
        type='checkbox'
        id={name}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={name}>{label}</label>
    </div>
  );
}
