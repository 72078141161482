import Navigation from './Navigation';
import Container from './Container';
import { whiteLabelData } from '../utils/whitelabeling';
import './PageTemplate.scss';

export default function PageTemplate({
  children,
  refScrollArea,
  home,
  footer,
}) {
  const { background, backgroundHome, backgroundPosition } = whiteLabelData();

  return (
    <div className='page-template'>
      <div className='page-header-wrap'>
        <Navigation />
      </div>
      <div
        className={
          'page-content' +
          (home ? ' home-page' : '') +
          (footer ? ' with-footer' : '')
        }
        ref={refScrollArea}
        style={{
          backgroundImage: home ? backgroundHome : background,
          backgroundPosition: backgroundPosition,
        }}
      >
        <Container>{children}</Container>
      </div>
    </div>
  );
}
