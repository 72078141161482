import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import Details from './Details';

export default function Info({ status, onBack, formik, videosUrls }) {
  return (
    <OrderFormTemplate1Btn
      header='Order Details'
      btnText='BACK'
      onClick={onBack}
      status={status}
      btnBackground='whitesmoke'
      btnColor='black'
    >
      <Details formik={formik} videosUrls={videosUrls} />
    </OrderFormTemplate1Btn>
  );
}
