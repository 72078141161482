import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { functions } from '../utils/firebase';
import { httpsCallable } from 'firebase/functions';
import PaymentVisual from '../components/PaymentVisual';
import { useFormik } from 'formik';
import * as Yup from 'yup';

export const paymentMethods = {
  card: 'card',
  bank: 'bank',
};

export const accountTypes = {
  checking: 'checking',
  savings: 'savings',
};

export const accountOwnerTypes = {
  individual: 'individual',
  company: 'company',
};

export const paymentStatuses = {
  succeeded: {
    header: 'Success',
    msg: 'Payment successfully processed',
    closeBtn: false,
  },
  failed: {
    header: 'Fail',
    msg: 'Payment has failed.\nPlease check the form and try again',
    closeBtn: true,
  },
  pending: {
    header: 'Pending',
    msg: 'Payment is pending',
    closeBtn: false,
  },
  authorized: {
    header: 'Authorized',
    msg: "Payment status is 'Authorized'",
    closeBtn: false,
  },
  refunded: {
    header: 'Refunded',
    msg: "Payment status is 'Refunded'",
    closeBtn: false,
  },
  disputed: {
    header: 'Disputed',
    msg: "Payment status is 'Disputed'",
    closeBtn: false,
  },
};

const defaultCurrency = 'usd';

const initValCard = {
  name: '',
  number: '',
  verification: '',
  month: '',
  year: '',
  address_postal_code: '',
};

const initValBank = {
  account_owner_name: '',
  routing_number: '',
  account_number: '',
  account_type: accountTypes.checking,
  account_owner_type: accountOwnerTypes.individual,
  country: 'US',
  currency: 'usd',
};

export default function Payment() {
  const params = useParams();
  const [awaitFlag, setAwaitFlag] = useState(false);
  const [linkErr, setLinkErr] = useState(null);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [amount, setAmount] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState(paymentMethods.card);
  const orderGetOrderByLinkToPay = httpsCallable(
    functions,
    'order-getOrderByLinkToPay'
  );
  const orderCreateJustifiPayment = httpsCallable(
    functions,
    'order-createJustifiPayment'
  );

  const validationCard = new Yup.ObjectSchema({
    name: Yup.string().required('required'),
    number: Yup.number()
      .required('required')
      .typeError('not a number')
      .min(1000000000000000, 'too short')
      .max(9999999999999999, 'too long'),
    verification: Yup.number()
      .required('required')
      .typeError('not a number')
      .min(100, 'too short')
      .max(999, 'too long'),
    month: Yup.number()
      .required('required')
      .typeError('not a number')
      .max(12, 'wrong month'),
    year: Yup.number().required('required').typeError('not a number'),
    address_postal_code: Yup.number()
      .required('required')
      .typeError('not a number')
      .min(10000, 'too short')
      .max(99999, 'too long'),
  });

  const validationBank = new Yup.ObjectSchema({
    account_owner_name: Yup.string().required('required'),
    routing_number: Yup.number().required('required').typeError('not a number'),
    account_number: Yup.number().required('required').typeError('not a number'),
  });

  const formikCard = useFormik({
    initialValues: initValCard,
    validationSchema: validationCard,
  });

  const formikBank = useFormik({
    initialValues: initValBank,
    validationSchema: validationBank,
  });

  //Payment processing logic
  const paymentProcessing = async () => {
    setAwaitFlag(true);

    try {
      const paymentInfo = {
        linkID: params.linkId,
        amount: amount * 100,
        currency: defaultCurrency,
        description: '',
        email: '',
        card:
          paymentMethod === paymentMethods.card
            ? {
                ...formikCard.values,
              }
            : null,
        bankAccount:
          paymentMethod === paymentMethods.bank
            ? {
                ...formikBank.values,
              }
            : null,
      };
      const res = await orderCreateJustifiPayment(paymentInfo);
      if (res.data.status === 'failed') {
        setPaymentStatus({
          ...paymentStatuses[res.data.status],
          msg: `Payment has failed.\n${
            res.data.reason ? res.data.reason + '\n' : ''
          } Please check the form and try again.`,
        });
      } else setPaymentStatus(paymentStatuses[res.data.status]);
    } catch (error) {
      setLinkErr(error.message);
      console.error(error);
    }

    setAwaitFlag(false);
  };

  const onPayClick = async () => {
    let errors;
    switch (paymentMethod) {
      case paymentMethods.card:
        formikCard.setTouched({
          name: true,
          number: true,
          verification: true,
          month: true,
          year: true,
          address_postal_code: true,
        });
        errors = await formikCard.validateForm();
        if (
          !errors.name &&
          !errors.number &&
          !errors.verification &&
          !errors.month &&
          !errors.year &&
          !errors.address_postal_code
        )
          paymentProcessing();
        break;

      case paymentMethods.bank:
        formikBank.setTouched({
          account_owner_name: true,
          routing_number: true,
          account_number: true,
        });
        errors = await formikBank.validateForm();
        if (
          !errors.account_owner_name &&
          !errors.routing_number &&
          !errors.account_number
        )
          paymentProcessing();
        break;

      default:
        console.error('Unknown payment method');
        break;
    }
  };

  useEffect(() => {
    const getLinkInfo = async () => {
      setAwaitFlag(true);

      try {
        const res = await orderGetOrderByLinkToPay({ linkID: params.linkId });
        setAmount(res.data.amount);
      } catch (error) {
        setLinkErr(error.message);
        console.error(error);
      }

      setAwaitFlag(false);
    };

    getLinkInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.linkId]);

  return (
    <PaymentVisual
      amount={amount}
      paymentMethod={paymentMethod}
      setPaymentMethod={setPaymentMethod}
      onPayClick={onPayClick}
      formikCard={formikCard}
      formikBank={formikBank}
      awaitFlag={awaitFlag}
      linkErr={linkErr}
      paymentStatus={paymentStatus}
      setPaymentStatus={setPaymentStatus}
    />
  );
}
