import ChatDialogue from './ChatDialogue';
import ChatInput from './ChatInput';
import CloseIcon from '../assets/images/close.png';
import Button from './Button';
import './ChatWindow.scss';

export default function ChatWindow({
  orderId,
  show,
  onClose,
  onSend,
  messages,
}) {
  return (
    <div className={'chat-window' + (show ? ' show' : '')}>
      <p className='quote-num'>Quote #{orderId}</p>
      <Button
        className='close-button'
        icon={CloseIcon}
        background='transparent'
        nohover
        onClick={onClose}
      />
      <ChatDialogue messages={messages} />
      <ChatInput onSend={onSend} show={show} />
    </div>
  );
}
