import { useState, useEffect } from 'react';
import Bubble from './Bubble';
import './InfoBlock.scss';

export default function InfoBlock({ text }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (show) setTimeout(() => setShow(false), 2000);
  }, [show]);

  return (
    <div className='info-block' onClick={() => setShow((show) => !show)}>
      <Bubble show={show} text={text} />
    </div>
  );
}
