import './IntroTextHauler.scss';

export default function IntroTextHauler() {
  return (
    <div className='intro-text-hauler'>
      <h1 className='welcome'>Welcome to Hauler Network</h1>
      <h3>
        Muva technology connects&nbsp;you&nbsp;with&nbsp;the
        <br />
        expert network for all&nbsp;your&nbsp;business
        <br />
        transportation needs
      </h3>
    </div>
  );
}
