import Spinner from './Spinner';
import Button from './Button';
import './OrderFormTemplate.scss';

export default function OrderFormTemplate({
  header,
  onNext,
  onBack,
  status,
  children,
  spinner,
  disabled,
}) {
  return (
    <div className={'order-form-template ' + status}>
      <Spinner show={spinner} />
      <div className='header-wrapper'>
        <h4 className='header'>{header}</h4>
      </div>
      <div className='form-wrapper'>
        <form>{children}</form>
        <div className='buttons-wrapper'>
          <Button
            background='whitesmoke'
            color='black'
            onClick={onBack}
            type='button'
          >
            BACK
          </Button>
          <Button onClick={onNext} type='button' disabled={disabled}>
            NEXT
          </Button>
        </div>
      </div>
    </div>
  );
}
