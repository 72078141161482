import moment from 'moment';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import { adminState, getDisplayState } from '../utils/states';
import './QuotesList.scss';

export default function QuotesList({
  status,
  awaitFlag,
  quotes,
  setCurrentQuote,
  orderCreate,
}) {
  const videoUploadLeftOnly = (quote) => {
    if (
      quote.state === adminState.init &&
      quote.contact.name &&
      quote.contact.email &&
      quote.contact.phone &&
      (quote.moveType !== 'Freight' || quote.contact.companyName) &&
      quote.pickup.zip &&
      quote.pickup.address &&
      quote.pickup.city &&
      quote.destination.zip &&
      quote.destination.address &&
      quote.destination.city &&
      (!quote.videos || quote.videos.length === 0)
    )
      return true;

    return false;
  };

  return (
    <OrderFormTemplate1Btn
      header='Your quotes'
      btnText='NEW QUOTE'
      small
      status={status}
      spinner={awaitFlag}
      onClick={orderCreate}
    >
      <div className='quotes-list'>
        {quotes.length === 0 && <p>You have no quotes yet</p>}
        {quotes.length > 0 && (
          <div className='header'>
            <div className='num-date'>
              <h6 className='column-1 dt'>Number</h6>
              <h6 className='column-2 dt'>Move date</h6>
              <h6 className='column-1 mobile'>Number / Date</h6>
            </div>
            <h6 className='column-3'>State</h6>
          </div>
        )}
        {quotes.length > 0 && (
          <div className='quotes-wrapper'>
            {quotes
              .map((quote, i) => ({ ...quote, index: i })) //Saving original indexes before sorting
              .sort((a, b) =>
                a.details.moveDate < b.details.moveDate ? 1 : -1
              )
              .map((quote) => (
                <div
                  className='string'
                  key={quote.index}
                  onClick={() => setCurrentQuote(quote.index)}
                >
                  <div className='num-date'>
                    <p className='column-1'>{quote.id}</p>
                    <p className='column-2'>
                      {quote.details.moveDate
                        ? moment(quote.details.moveDate).format('MMM D, YYYY')
                        : '-'}
                    </p>
                  </div>
                  <p className='column-3'>
                    <span>{getDisplayState(quote.state)}</span>
                    {videoUploadLeftOnly(quote) && (
                      <div className='record-icon' />
                    )}
                  </p>
                </div>
              ))}
          </div>
        )}
      </div>
    </OrderFormTemplate1Btn>
  );
}
