import Button from './Button';
import ModalPopup from './ModalPopup';
import './ModalYesNo.scss';

export default function ModalYesNo({
  children,
  onYes,
  onNo,
  onClose,
  show,
  className,
}) {
  return (
    <ModalPopup
      show={show}
      onClose={onClose}
      className={'modal-yes-no' + (className ? ' ' + className : '')}
    >
      <div className='children-wrapper'>{children}</div>
      <div className='buttons-wrapper'>
        <Button color='black' background='whitesmoke' onClick={onNo}>
          No
        </Button>
        <Button onClick={onYes}>Yes</Button>
      </div>
    </ModalPopup>
  );
}
