import Autocomplete from 'react-autocomplete';
import './LabelAutocomplete.scss';
import Spinner from './Spinner';

export default function LabelAutocomplete({
  name,
  label,
  error,
  value,
  items,
  onChange,
  onBlur,
  onSelect,
  onMenuVisibilityChange,
  awaitFlag,
}) {
  return (
    <div className='label-autocomplete'>
      <label className={error ? 'error' : ''}>
        {error ? label + ': ' + error : label}
      </label>
      <Autocomplete
        items={items}
        getItemValue={(item) => item}
        renderItem={(item, isHighlighted) => (
          <div
            key={item}
            className={'item' + (isHighlighted ? ' highlighted' : '')}
          >
            <p>{item}</p>
          </div>
        )}
        value={value}
        onChange={onChange}
        onMenuVisibilityChange={onMenuVisibilityChange}
        inputProps={{
          name,
          onBlur,
        }}
        onSelect={onSelect}
        renderMenu={(items, value, style) => {
          return (
            <div
              style={{
                ...style,
                background: 'black',
                borderRadius: '8px',
                border: '1px solid rgba(255, 255, 255, 0.5)',
                position: 'absolute',
                left: 'unset',
                top: 'unset',
                zIndex: '1',
                overflow: 'hidden',
                minHeight: awaitFlag ? '190px' : '116px',
              }}
            >
              {items}
              <Spinner show={awaitFlag} />
            </div>
          );
        }}
      />
    </div>
  );
}
