import { whiteLabelData } from '../utils/whitelabeling';
import './IntroText.scss';

export default function IntroText() {
  return (
    <div className='intro-text'>
      <h1 className='welcome'>
        Welcome to
        <br />
        {`the ${whiteLabelData().title}`}
        <br />
        booking system
      </h1>
      <h4>
        {`${whiteLabelData().title} has been providing expert transportation of
          household items and freight for\u00A0over\u00A030\u00A0years`}
      </h4>
    </div>
  );
}
