import OrderFormTemplate from './OrderFormTemplate';
import UploadVideo from './UploadVideo';

export default function OrderForm7({
  onNext,
  onBack,
  status,
  formik,
  videosUrls,
  uploadingProgress,
}) {
  const onClickNext = async () => {
    formik.setFieldTouched('files', true);
    const errors = await formik.validateForm();

    if (!errors.files || videosUrls.length > 0) {
      formik.setFieldTouched('files', false);
      await onNext();
    }
  };

  return (
    <OrderFormTemplate
      header={
        formik.values.moveType === 'Freight'
          ? 'Upload relevant images and\u00A0videos'
          : 'Upload relevant videos'
      }
      status={status}
      onNext={onClickNext}
      onBack={onBack}
      disabled={
        uploadingProgress !== null ||
        (formik.errors.files && videosUrls.length === 0)
      }
    >
      <UploadVideo
        formik={formik}
        videosUrls={videosUrls}
        uploadingProgress={uploadingProgress}
      />
    </OrderFormTemplate>
  );
}
