import './Bubble.scss';

export default function Bubble({ text, show, position = 'right' }) {
  return (
    <div className={'bubble ' + position + (show ? ' show' : '')}>
      <div className={'arrow ' + position} />
      <p className='text'>{text}</p>
    </div>
  );
}
