import OrderFormTemplate from './OrderFormTemplate';
import Radio from './Radio';
import InfoBlock from './InfoBlock';
import './OrderForm1.scss';

export default function OrderForm1({ onNext, onBack, status, formik }) {
  return (
    <OrderFormTemplate
      header={'What is your move\u00A0type?'}
      status={status}
      onNext={onNext}
      onBack={onBack}
      disabled={!formik.values.moveType}
    >
      <div className='order-form-1'>
        <div className='radio-wrapper'>
          <div className='radio-i-wrap'>
            <Radio
              value='Household'
              label='Household'
              name='moveType'
              checked={formik.values.moveType === 'Household'}
              onChange={formik.handleChange}
            />
            <InfoBlock text='For any move of personal belongings, either from home address or storage' />
          </div>
          <div className='radio-i-wrap'>
            <Radio
              value='Freight'
              label='Freight'
              name='moveType'
              checked={formik.values.moveType === 'Freight'}
              onChange={formik.handleChange}
            />
            <InfoBlock text='For shipping of commercial goods' />
          </div>
        </div>
      </div>
    </OrderFormTemplate>
  );
}
