import PageTemplate from './PageTemplate';
import SignInPanel from './SignInPanel';
import IntroText from './IntroText';
import Footer from './Footer';
import './Home.scss';

export default function Home({
  intro: Intro = IntroText,
  outro: Outro = () => <></>,
  navigatePath = '/order',
}) {
  return (
    <div className='home'>
      <PageTemplate home footer>
        <div className='home-content'>
          <Intro />
          <SignInPanel navigatePath={navigatePath} />
          <Outro />
        </div>
      </PageTemplate>
      <Footer />
    </div>
  );
}
