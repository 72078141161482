import { useState } from 'react';
import OrderFormTemplate from './OrderFormTemplate';
import LabelDate from './LabelDate';
import LabelTime from './LabelTime';
import Radio from './Radio';
import ModalPopup from './ModalPopup';
import Button from './Button';
import { minDate, addDays } from '../utils/miscellaneous';
import InfoBlock from './InfoBlock';
import { createPortal } from 'react-dom';
import { isMuvaFreight } from '../utils/whitelabeling';
import LabelTextArea from './LabelTextArea';
import './OrderForm3.scss';

const warnTerm = 21;

export default function OrderForm3({ onNext, onBack, status, formik }) {
  const [showShortTermWarn, setShowShortTermWarn] = useState(false);
  const [tmpPickUpDate, setTmpPickUpDate] = useState();

  return (
    <OrderFormTemplate
      header='Pick Up Details'
      status={status}
      onNext={onNext}
      onBack={onBack}
      disabled={
        formik.values.noPacking === undefined ||
        formik.values.storageRequired === undefined
      }
    >
      <div className='order-form-3-wrapper'>
        <LabelDate
          label='Pick Up Date*'
          value={formik.values.pickUpDate}
          onChange={(newDate) => {
            if (addDays(new Date(), warnTerm) > newDate) {
              setTmpPickUpDate(newDate);
              setShowShortTermWarn(true);
            } else formik.setFieldValue('pickUpDate', newDate);
          }}
          minDate={minDate()}
        />
        <LabelTime
          label='Pick Up Time*'
          name='pickUpTime'
          value={formik.values.pickUpTime}
          onChange={formik.handleChange}
        />
        <div className='horizontal'>
          <div className='packing-block-wrapper'>
            <label>Packing Options*</label>
            <div className='radio-wrapper'>
              <div className='radio-i-wrap'>
                <Radio
                  value='packing'
                  label='Company pack'
                  name='noPacking'
                  checked={formik.values.noPacking === false}
                  onChange={() => formik.setFieldValue('noPacking', false)}
                />
                <InfoBlock text='The movers pack all goods, including small and fragile items' />
              </div>
              <div className='radio-i-wrap'>
                <Radio
                  value='noPacking'
                  label='Pack by owner'
                  name='noPacking'
                  checked={formik.values.noPacking === true}
                  onChange={() => formik.setFieldValue('noPacking', true)}
                />
                <InfoBlock text='You pack all items into boxes' />
              </div>
            </div>
            {!isMuvaFreight() && formik.values.moveType !== 'Freight' && (
              <label>
                If you want a custom pack, select 'Pack by owner' and list items
                during your video
              </label>
            )}
          </div>
          {!isMuvaFreight() && formik.values.moveType !== 'Freight' && (
            <div className='storage-block-wrapper'>
              <label>Require storage*</label>
              <div className='radio-wrapper'>
                <Radio
                  value='storageRequired'
                  label='Yes'
                  name='storageRequired'
                  checked={formik.values.storageRequired === true}
                  onChange={() => formik.setFieldValue('storageRequired', true)}
                />
                <Radio
                  value='storageNotRequired'
                  label='No'
                  name='storageRequired'
                  checked={formik.values.storageRequired === false}
                  onChange={() =>
                    formik.setFieldValue('storageRequired', false)
                  }
                />
              </div>
            </div>
          )}
        </div>
        {(isMuvaFreight() || formik.values.moveType === 'Freight') && (
          <LabelTextArea
            label='Content description and container requirements:'
            name='requirements'
            value={formik.values.requirements}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className='requirements'
          />
        )}
        {document.getElementById('order-form') &&
          createPortal(
            <ModalPopup
              className='modal-short-time-warn'
              show={showShortTermWarn}
              onClose={() => setShowShortTermWarn(false)}
            >
              <p>
                We do our best to accommodate all requests, but moves in less
                than {warnTerm} days may incur a short notice surcharge
              </p>
              <div className='btn-wrapper'>
                <Button
                  background='whitesmoke'
                  color='black'
                  onClick={() => setShowShortTermWarn(false)}
                >
                  CANCEL
                </Button>
                <Button
                  onClick={() => {
                    formik.setFieldValue('pickUpDate', tmpPickUpDate);
                    setShowShortTermWarn(false);
                  }}
                >
                  AGREE
                </Button>
              </div>
            </ModalPopup>,
            document.getElementById('order-form')
          )}
      </div>
    </OrderFormTemplate>
  );
}
