import { useState, useEffect } from 'react';
import ChatIcon from '../assets/images/chat.png';
import Bubble from './Bubble';
import Button from './Button';
import './ChatButton.scss';

export default function ChatButton({ onClick, show }) {
  const [showBubble, setShowBubble] = useState(false);

  useEffect(() => {
    setTimeout(() => setShowBubble(true), 3000);
    setTimeout(() => setShowBubble(false), 8000);
  }, []);

  return (
    <div className='chat-button-wrap'>
      <Button
        className={'chat-button' + (show ? ' show' : '')}
        icon={ChatIcon}
        onClick={onClick}
      />
      <Bubble
        text='Click here to chat with us to ask any questions'
        position='left'
        show={showBubble}
      />
    </div>
  );
}
