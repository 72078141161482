import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import './LabelDate.scss';

export default function LabelDate({
  label,
  value,
  onChange,
  disabled,
  error,
  minDate,
  className,
}) {
  return (
    <div className={'label-date' + (className ? ' ' + className : '')}>
      <label className={error ? 'error' : ''}>
        {error ? label + ': ' + error : label}
      </label>
      <div className='date-picker-wrap'>
        <DatePicker
          onChange={onChange}
          value={value}
          clearIcon={null}
          format='MMM d, y'
          disabled={disabled}
          minDate={minDate}
          calendarIcon={<div className='calendar-icon' />}
        />
      </div>
    </div>
  );
}
