import { Timestamp } from 'firebase/firestore';
import moment from 'moment';
import { tz } from 'moment-timezone';

//Regular expression to check a email
export const emailRegExp = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

export const zipPostCode = (country) => {
  switch (country) {
    case 'US':
      return 'Zip code';
    case 'AU':
      return 'Post code';
    default:
      return '';
  }
};

//Regular expression match numbers only
export const numRegEx = /^[0-9\b]*$/;

//Regular expression match letters and spaces only
export const letSpcRegEx = /^[a-zA-Z\s]+$/;

//Regular expression match letters, numbers and spaces only
export const letNumSpcRegEx = /^[a-zA-Z0-9\s]+$/;

//Add days to date
export const addDays = (date, days) => {
  return moment(date).add(days, 'day').toDate();
};

//Minimal acceptable moving date
export const minDate = () => {
  const minDays = 1;
  return addDays(new Date(), minDays);
};

//Change the date's timezone from local to custom
export const dateLocalToTz = (date, timezone = 'America/Los_Angeles') => {
  return tz(moment(date).format('YYYY-MM-DD'), timezone).toDate();
};

//Change the date's timezone from custom to local
export const dateTzToLocal = (date, timezone = 'America/Los_Angeles') => {
  return new Date(tz(date, timezone).format('YYYY-MM-DD') + 'T00:00:00');
};

//Scrolling area to the top
export const scrollTop = (ref) => {
  if (ref.current) ref.current.scrollTo({ top: 0, behavior: 'smooth' });
};
