import { useState, useEffect } from 'react';
import ModalYesNo from './ModalYesNo';
import Radio from './Radio';
import LabelInput from './LabelInput';
import { cancelCode } from '../utils/states';
import './CancelConfirmation.scss';

export default function CancelConfirmation({ show, onCancel, onClose }) {
  const [code, setCode] = useState('');
  const [reason, setReason] = useState('');
  const [showCodeErr, setShowCodeErr] = useState(false);
  const [showReasonErr, setShowReasonErr] = useState(false);

  const onRadioChange = (e) => setCode(e.target.value);

  const onClickYes = () => {
    if (!code) {
      setShowCodeErr(true);
      return;
    }

    if (code === cancelCode.other && !reason) {
      setShowReasonErr(true);
      return;
    }

    onCancel({ code, reason: reason || code });
    onClose();
  };

  useEffect(() => {
    if (code && showCodeErr) setShowCodeErr(false);
  }, [code, showCodeErr]);

  useEffect(() => {
    if (reason && showReasonErr) setShowReasonErr(false);
  }, [reason, showReasonErr]);

  return (
    <ModalYesNo
      show={show}
      onNo={onClose}
      onYes={onClickYes}
      onClose={onClose}
      className='cancel-confirmation'
    >
      <h5 className='header'>Are you sure?</h5>
      <div className='radio-box'>
        <Radio
          label='I found a better option'
          name='cancel-code'
          value={cancelCode.betterOption}
          checked={code === cancelCode.betterOption}
          onChange={onRadioChange}
        />
        <Radio
          label='I am no longer moving'
          name='cancel-code'
          value={cancelCode.noNeed}
          checked={code === cancelCode.noNeed}
          onChange={onRadioChange}
        />
        <Radio
          label='Other'
          name='cancel-code'
          value={cancelCode.other}
          checked={code === cancelCode.other}
          onChange={onRadioChange}
        />
      </div>
      <LabelInput
        label='Please explain*'
        className={code === cancelCode.other ? 'show' : ''}
        value={reason}
        onChange={(e) => setReason(e.target.value)}
        error={showReasonErr ? 'Required' : undefined}
      />
      <label className={'error-label' + (showCodeErr ? ' show' : '')}>
        Please choose the reason
      </label>
    </ModalYesNo>
  );
}
