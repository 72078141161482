import './IntroTextAmericanDream.scss';

export default function IntroTextAmericanDream() {
  return (
    <div className='intro-text-american-dream'>
      <h1 className='welcome'>
        Welcome to
        <br />
        the American Dream
        <br />
        booking system
      </h1>
      <div className='veteran-owned'>
        <h4>Veteran Owned Company</h4>
        <p>
          {
            'Licensed\u00A0&\u00A0Insured | DOT:\u00A02722770 | MC:\u00A0988657 | BHGS:\u00A0MTR0191677 | CAMC:\u00A0497805 | DBIDS:\u00A020235365 | FBI\u00A0&\u00A0Homeland\u00A0Security\u00A0Clearances'
          }
        </p>
      </div>
    </div>
  );
}
