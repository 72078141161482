import { useState, useContext } from 'react';
import { createPortal } from 'react-dom';
import moment from 'moment';
import { MarketContext } from '../containers/Market';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import Button from './Button';
import ModalYesNo from './ModalYesNo';
import './WellDone.scss';

export default function WellDone({ status, prevStep }) {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [spinner, setSpinner] = useState(false);
  const {
    availability,
    availabilityUpdate,
    currentAvailId,
    setCurrentAvailId,
  } = useContext(MarketContext);

  return (
    <OrderFormTemplate1Btn
      header='Well Done!'
      status={status}
      spinner={spinner}
    >
      <div className='well-done'>
        <p className='top-text'>
          We've received your application and will contact you shortly. We will
          do the best to prevent your deadheading.
        </p>
        <div className='availability-list'>
          {availability &&
            availability.map((avail) => (
              <div className='availability-item' key={avail.id}>
                <div className='route-dates'>
                  <p>{`${avail.pickup} - ${avail.destination}`}</p>
                  <label>{`${moment(avail.pickupStartAt?.toDate()).format(
                    'MMM D, YYYY'
                  )} - ${moment(avail.pickupEndAt?.toDate()).format(
                    'MMM D, YYYY'
                  )}`}</label>
                </div>
                <div className='btns'>
                  <Button
                    color='var(--primary-color)'
                    background='transparent'
                    nohover
                    small
                    onClick={() => {
                      setCurrentAvailId(avail.id);
                      prevStep();
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    color='red'
                    background='transparent'
                    nohover
                    small
                    onClick={() => {
                      setCurrentAvailId(avail.id);
                      setShowCancelConfirmation(true);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            ))}
        </div>
        <div className='add-btn-wrap'>
          <Button
            small
            onClick={() => {
              setCurrentAvailId(null);
              prevStep();
            }}
          >
            ADD AVAILABILITY
          </Button>
        </div>
      </div>
      {document.getElementById('market') &&
        createPortal(
          <ModalYesNo
            className='cancel-confirmation'
            show={showCancelConfirmation}
            onNo={() => setShowCancelConfirmation(false)}
            onClose={() => setShowCancelConfirmation(false)}
            onYes={async () => {
              setShowCancelConfirmation(false);
              try {
                setSpinner(true);
                await availabilityUpdate({
                  availabilityID: currentAvailId,
                  active: false,
                });
                setSpinner(false);
              } catch (error) {
                console.error(error.message);
                setSpinner(false);
              }
            }}
          >
            <h5 className='header'>Are you sure?</h5>
            <p>You want to cancel your application?</p>
          </ModalYesNo>,
          document.getElementById('market')
        )}
    </OrderFormTemplate1Btn>
  );
}
