import ModalPopup from './ModalPopup';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';

export default function InfoPopup({ show, header, message, onClose, spinner }) {
  return (
    <ModalPopup show={show} className='check-email-msg' onClose={onClose}>
      <OrderFormTemplate1Btn
        header={header}
        btnText='OK'
        onClick={onClose}
        noMarginTop
        small
        spinner={spinner}
      >
        <p>{message}</p>
      </OrderFormTemplate1Btn>
    </ModalPopup>
  );
}
