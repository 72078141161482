import { useState, useRef, useEffect } from 'react';
import FilesPreview from './FilesPreview';
import Button from './Button';
import VideoRecorder from './VideoRecorder';
import UploadingProgressBar from './UploadingProgressBar';
import IconSelect from '../assets/images/upload.png';
import IconRecord from '../assets/images/video.png';
import './UploadVideo.scss';
import Spinner from './Spinner';

const imagesExt =
  '.jpg, .jpeg, .jpe .jif, .jfif, .jfi, .png, .gif, .webp, .tiff, .tif, .psd, .raw, .arw, .cr2, .nrw, .k25, .bmp, .dib, .heif, .heic, .ind, .indd, .ind, .jp2, .j2k, .jpf, .jpx, .jpm, .mj2';

const videoExt =
  '.webm, .mpg, .mp2, .mpeg, .mpe, .mpv, .ogg, .mp4, .m4p, .m4v, .avi, .wmv, .mov, .qt, .avchd';

export default function UploadVideo({ formik, videosUrls, uploadingProgress }) {
  const [filesUrls, setFilesUrls] = useState([]);
  const [showVideoRecorder, setShowVideoRecorder] = useState(false);
  const fileInputRef = useRef(null);

  const revokeFiles = () => {
    filesUrls.forEach((fileUrl) => URL.revokeObjectURL(fileUrl.url));
  };

  useEffect(() => {
    revokeFiles();

    setFilesUrls(
      formik.values.files.map((file) => ({
        type: file instanceof File ? file.type.split('/')[0] : 'video',
        url: URL.createObjectURL(file),
      }))
    );

    return () => {
      revokeFiles();
    };
  }, [formik.values.files]);

  const uploadClickHandler = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const fileInputClickHandler = (event) => {
    formik.setFieldValue('files', [
      ...formik.values.files,
      ...Array.from(event.target.files).filter(
        (file) =>
          file.type.startsWith('video/') || file.type.startsWith('image/')
      ),
    ]);
  };

  const onVideoStopRec = (videoBlob) => {
    formik.setFieldValue('files', [...formik.values.files, videoBlob]);
    setShowVideoRecorder(false);
  };

  const onVideoRecClose = () => {
    setShowVideoRecorder(false);
  };

  const err =
    formik.touched.files && formik.errors.files && videosUrls.length === 0
      ? formik.errors.files
      : null;

  return (
    <div className='upload-video'>
      <p className='go-to-mobile'>
        Please login from your mobile device
        <br />
        to record a video
      </p>
      <label className={err ? 'error' : 'invisible'}>{err}</label>
      <div className='files-wrapper'>
        <Spinner show={uploadingProgress !== null} />
        {(videosUrls.length > 0 || filesUrls.length > 0) && (
          <FilesPreview
            videosUrls={filesUrls.length > 0 ? filesUrls : videosUrls}
          />
        )}
      </div>
      <input
        type='file'
        multiple
        accept={
          videoExt +
          (formik.values.moveType === 'Freight' ? ', ' + imagesExt : '')
        }
        ref={fileInputRef}
        onChange={fileInputClickHandler}
      />
      <UploadingProgressBar
        show={uploadingProgress !== null}
        percent={uploadingProgress}
      />
      <div className='buttons-wrapper'>
        <Button
          onClick={uploadClickHandler}
          icon={IconSelect}
          small
          disabled={uploadingProgress !== null}
        >
          UPLOAD
          {(formik.values.files.length > 0 ||
            formik.values.videos.length > 0) && (
            <>
              <br />
              ADDITIONAL
            </>
          )}
          <br />
          {formik.values.moveType === 'Freight' ? 'FILES' : 'VIDEO'}
        </Button>
        <Button
          onClick={() => setShowVideoRecorder(true)}
          icon={IconRecord}
          small
          disabled={uploadingProgress !== null}
        >
          RECORD
          {(formik.values.files.length > 0 ||
            formik.values.videos.length > 0) && (
            <>
              <br />
              ADDITIONAL
            </>
          )}
          <br />
          VIDEO
        </Button>
      </div>
      <VideoRecorder
        onVideoStopRec={onVideoStopRec}
        show={showVideoRecorder}
        onClose={onVideoRecClose}
      />
    </div>
  );
}
