import ChatMessage from './ChatMessage';
import './ChatDialogue.scss';
import { useRef, useEffect } from 'react';

export default function ChatDialogue({ messages }) {
  const scrollDiv = useRef(null);

  const lastId = messages.length > 0 ? messages[messages.length - 1].id : null;
  useEffect(() => {
    if (scrollDiv.current)
      scrollDiv.current.scrollTo({
        top: scrollDiv.current.scrollHeight,
        behavior: 'smooth',
      });
  }, [lastId]);

  return (
    <div className='chat-dialogue'>
      <div className='scrolling-wrap' ref={scrollDiv}>
        <div className='scrolling-area'>
          {messages.map((message, i) => (
            <div key={message.id}>
              {(i === 0 ||
                !message.moment.isSame(messages[i - 1].moment, 'day')) && (
                <div className='date'>
                  <label>{message.moment.format('dddd, MMM Do')}</label>
                </div>
              )}
              {(i === 0 || message.userID !== messages[i - 1].userID) && (
                <div
                  className={'name ' + (message.isUser ? 'user' : 'not-user')}
                >
                  <label>{message.name}</label>
                </div>
              )}
              <ChatMessage message={message} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
