import { useState, useEffect, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import { doc, collection, query, getDoc, onSnapshot } from 'firebase/firestore';
import { db, functions } from '../utils/firebase';
import { httpsCallable } from 'firebase/functions';
import ChatButton from '../components/ChatButton';
import ChatWindow from '../components/ChatWindow';

const chatSend = httpsCallable(functions, 'chat-send');

export default function Chat({ orderId }) {
  const [chatIsOpen, setChatIsOpen] = useState(false),
    order = useRef(null),
    chat = useRef(null),
    [messages, setMessages] = useState([]),
    unsub = useRef({ chat: null, messages: null });

  useEffect(() => {
    if (messages.length > 0 && !messages.at(-1).isUser) setChatIsOpen(true);
  }, [messages]);

  useEffect(() => {
    const onError = (err) =>
      console.error(`Chat loading failure: ${err.message}`);

    const onMessage = (querySnap) => {
      const newMsgs = [];

      querySnap.docChanges().forEach((change) => {
        if (change.type === 'removed') return;

        const { userID, timestamp } = change.doc.data();

        newMsgs.push({
          ...change.doc.data(),
          name: chat.current.names[userID],
          isUser: userID === order.current.userID,
          moment: moment(timestamp.toMillis()),
        });
      });

      setMessages((msgs) => {
        newMsgs.forEach((newMsg) => {
          if (!msgs.find((msg) => newMsg.id === msg.id)) msgs.push(newMsg);
        });

        return [...msgs].sort(
          (a, b) => a.timestamp.toMillis() - b.timestamp.toMillis()
        );
      });
    };

    const openChat = async () => {
      setMessages([]);
      const orderSnap = await getDoc(doc(db, 'quotes', orderId));
      order.current = orderSnap.data();

      const chatId = order.current.chats.admin;

      unsub.current.chat = onSnapshot(
        doc(db, 'chats', chatId),
        (doc) => (chat.current = { id: chatId, ...doc.data() }),
        onError
      );

      unsub.current.messages = onSnapshot(
        query(collection(db, 'chats', chatId, 'messages')),
        onMessage,
        onError
      );
    };

    const closeChat = () => {
      if (unsub.current.chat) unsub.current.chat();
      if (unsub.current.messages) unsub.current.messages();
    };

    openChat();
    return closeChat();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId]);

  const onSend = (msg) => {
    const msgTrim = msg.trim();
    if (!msgTrim) return;

    try {
      const now = Date.now();
      const msg = {
        id: uuidv4(),
        text: msgTrim,
        userID: order.current.userID,
        name: chat.current.names[order.current.userID],
        isUser: true,
        moment: moment(now),
        timestamp: { toMillis: () => now.valueOf() },
      };
      chatSend({
        chatID: chat.current.id,
        message: {
          id: msg.id,
          timestamp: now,
          text: msg.text,
        },
      });
      setMessages((msgs) => [...msgs, msg]);
    } catch (error) {
      console.error(`Failed to send message: ${error.message}`);
    }
  };

  return (
    <>
      <ChatButton show={!chatIsOpen} onClick={() => setChatIsOpen(true)} />
      <ChatWindow
        orderId={orderId}
        show={chatIsOpen}
        onClose={() => setChatIsOpen(false)}
        onSend={onSend}
        messages={messages}
      />
    </>
  );
}
