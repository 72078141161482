import InputZipCountry from './InputZipCountry';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import { zipPostCode } from '../utils/miscellaneous';
import './OrderForm0.scss';

export default function OrderForm0({ onNext, status, formik, awaitFlag }) {
  const onClickHandler = async () => {
    formik.setTouched({ ...formik.touched, zipFrom: true, zipTo: true });
    const errors = await formik.validateForm();
    if (!errors.zipFrom && !errors.zipTo) onNext();
  };

  const setState = ({ stateField, country }) => {
    let state = '';

    switch (country) {
      case 'US':
        state = 'Alabama';
        break;
      case 'AU':
        state = 'New South Wales';
        break;
      default:
    }

    const newVal = { ...formik.values };
    newVal[stateField] = state;
    formik.setValues(newVal);
  };

  return (
    <OrderFormTemplate1Btn
      header={'Start Your Move or\u00A0Shipment'}
      btnText='GET MUVA-ING!'
      onClick={onClickHandler}
      status={status}
      small
      spinner={awaitFlag}
      disabled={
        formik.errors.zipFrom ||
        !formik.values.zipFrom ||
        formik.errors.zipTo ||
        !formik.values.zipTo
      }
    >
      <div className='order-form-0'>
        <div className='from-to-wrapper'>
          <div className='from'>
            <p>FROM</p>
            <InputZipCountry
              label={`${zipPostCode(formik.values.countryFrom)}`}
              placeholder={`From ${zipPostCode(formik.values.countryFrom)}`}
              zipName='zipFrom'
              zip={formik.values.zipFrom}
              zipOnChange={formik.handleChange}
              zipOnBlur={formik.handleBlur}
              countryName='countryFrom'
              country={formik.values.countryFrom}
              countryOnChange={(e) => {
                setState({ stateField: 'stateFrom', country: e.target.value });
                formik.handleChange(e);
              }}
              error={
                formik.touched.zipFrom && formik.errors.zipFrom
                  ? formik.errors.zipFrom
                  : null
              }
            />
          </div>
          <div className='to'>
            <p>TO</p>
            <InputZipCountry
              label={`${zipPostCode(formik.values.countryTo)}`}
              placeholder={`To ${zipPostCode(formik.values.countryTo)}`}
              zipName='zipTo'
              zip={formik.values.zipTo}
              zipOnChange={formik.handleChange}
              zipOnBlur={formik.handleBlur}
              countryName='countryTo'
              country={formik.values.countryTo}
              countryOnChange={(e) => {
                setState({ stateField: 'stateTo', country: e.target.value });
                formik.handleChange(e);
              }}
              error={
                formik.touched.zipTo && formik.errors.zipTo
                  ? formik.errors.zipTo
                  : null
              }
            />
          </div>
        </div>
        {/* <Button background='whitesmoke' color='var(--primary-color)' small>
          REFER A FRIEND, GET $50
        </Button> */}
      </div>
    </OrderFormTemplate1Btn>
  );
}
