import './StepClock.scss';

export default function StepClock({ step, min, max }) {
  const polygon = (turn) => {
    let polyArr = [];

    if (turn > 0.875) {
      polyArr.push(`calc(50% + (50% * tan(${turn}turn))) 0`);
      turn = 0.875;
    }

    if (turn > 0.625) {
      polyArr.push(`0 calc(50% - (50% * tan(${turn - 0.75}turn)))`);
      turn = 0.625;
    }

    if (turn > 0.375) {
      polyArr.push(`calc(50% - (50% * tan(${turn}turn))) 100%`);
      turn = 0.375;
    }

    if (turn > 0.125) {
      polyArr.push(`100% calc(50% + 50% * tan(${turn - 0.25}turn))`);
      turn = 0.125;
    }

    polyArr.push(`calc(50% + (50% * tan(${turn}turn))) 0`);

    polyArr.push('50% 0', '50% 50%');

    // This needs for smooth transitions
    if (polyArr.length < 7) {
      const numItemsToAdd = 7 - polyArr.length;
      const itemsToAdd = Array(numItemsToAdd).fill(polyArr[0]);
      polyArr = [...itemsToAdd, ...polyArr];
    }

    return `polygon(${polyArr.join(', ')})`;
  };

  return (
    <div
      className={
        'progress-bar' + (step < min || step > max ? ' invisible' : '')
      }
    >
      <div className='chart-container'>
        <div className='circle' />
        <div
          className='chart-segment'
          style={{
            clipPath: polygon((step - min + 1) / (max - min + 1)),
          }}
        />
      </div>
      <p className='step-text'>{`STEP ${step - min + 1}/${max - min + 1}`}</p>
    </div>
  );
}
