import Button from './Button';
import './ModalPopup.scss';

export default function ModalPopup({ children, show, className, onClose }) {
  return (
    <div
      className={
        'modal-popup' +
        (show ? ' show' : '') +
        (className ? ' ' + className : '')
      }
    >
      <div className='modal-content-wrapper'>
        {onClose && (
          <div className='close-button-wrapper'>
            <Button
              small
              onClick={onClose}
              nohover
              background='transparent'
              className='close-button'
            >
              <div className='close-icon' />
            </Button>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
