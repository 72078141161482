import './Footer.scss';

export default function Footer() {
  return (
    <div className='footer'>
      <label>Powered by</label>
      <div className='logo' />
    </div>
  );
}
