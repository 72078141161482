import './InputZipCountry.scss';

import { isMuvaFreight } from '../utils/whitelabeling';

export default function InputZipCountry({
  label,
  placeholder,
  zipName,
  zip,
  zipOnChange,
  zipOnBlur,
  countryName,
  country,
  countryOnChange,
  error,
}) {
  return (
    <div className='input-zip-country'>
      <div className='zip'>
        <label className={error ? 'error' : ''}>
          {error ? label + ': ' + error : label}
        </label>
        <input
          id={zipName}
          name={zipName}
          placeholder={placeholder}
          value={zip}
          onChange={zipOnChange}
          onBlur={zipOnBlur}
        />
      </div>
      <div className='country'>
        <label>Country</label>
        <select
          id={countryName}
          name={countryName}
          value={country}
          onChange={countryOnChange}
          disabled={!isMuvaFreight()}
        >
          <option value='US'>USA</option>
          <option value='AU'>AUS</option>
        </select>
      </div>
    </div>
  );
}
