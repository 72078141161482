import { useEffect } from 'react';
import { createPortal } from 'react-dom';
import ModalPopup from './ModalPopup';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import UploadVideo from './UploadVideo';
import './AddVideo.scss';

export default function AddVideo({
  show,
  onClose,
  formik,
  onAddVideo,
  uploadingProgress,
}) {
  useEffect(() => {
    if (show) {
      formik.setFieldValue('files', []);
      formik.setFieldTouched('files', false);
    }
  }, [show]);

  const onClickAdd = async () => {
    formik.setFieldTouched('files', true);
    const errors = await formik.validateForm();

    if (!errors.files) {
      formik.setFieldTouched('files', false);
      await onAddVideo();
      onClose();
    }
  };

  return (
    <>
      {document.getElementById('order-form') &&
        createPortal(
          <ModalPopup show={show} onClose={onClose} className='add-video'>
            <OrderFormTemplate1Btn
              noMarginTop
              btnText='ADD VIDEO'
              small
              btnBackground='whitesmoke'
              btnColor='black'
              onClick={onClickAdd}
            >
              <div className='upload-video-wrapper'>
                <UploadVideo
                  formik={formik}
                  videosUrls={[]}
                  uploadingProgress={uploadingProgress}
                />
              </div>
            </OrderFormTemplate1Btn>
          </ModalPopup>,
          document.getElementById('order-form')
        )}
    </>
  );
}
