import './IntroTextDemo.scss';

export default function IntroTextDemo() {
  return (
    <div className='intro-text-demo'>
      <h1 className='welcome'>
        Welcome to
        <br />
        the demo app
        <br />
        by MUVA
      </h1>
      <h4>
        This web app is built to provide a clear understanding of the quote
        request and estimating process
      </h4>
    </div>
  );
}
