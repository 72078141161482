import LabelInput from './LabelInput';
import './PaymentFormCard.scss';

export default function PaymentFormCard({ formik }) {
  return (
    <form className='payment-card-form'>
      <div className='horizont'>
        <LabelInput
          label='Full name'
          placeholder='Full name'
          className='full-name-input'
          {...formik.getFieldProps('name')}
          error={
            formik.touched['name'] && formik.errors['name']
              ? formik.errors['name']
              : null
          }
        />
        <LabelInput
          label='ZIP'
          placeholder='ZIP'
          className='zip-input'
          maxLength={5}
          {...formik.getFieldProps('address_postal_code')}
          error={
            formik.touched['address_postal_code'] &&
            formik.errors['address_postal_code']
              ? formik.errors['address_postal_code']
              : null
          }
        />
      </div>
      <div className='horizont mob-wrap'>
        <LabelInput
          label='Card number'
          placeholder='Card number'
          className='card-number-input'
          maxLength={19}
          name='number'
          value={formik.values.number.replace(/(\d{4})/g, '$1 ').trim()}
          onChange={(e) =>
            formik.setFieldValue('number', e.target.value.replace(/\D/g, ''))
          }
          onBlur={formik.handleBlur}
          error={
            formik.touched['number'] && formik.errors['number']
              ? formik.errors['number']
              : null
          }
        />
        <LabelInput
          label='CVV'
          placeholder='CVV'
          className='cvv-input'
          maxLength={3}
          {...formik.getFieldProps('verification')}
          error={
            formik.touched['verification'] && formik.errors['verification']
              ? formik.errors['verification']
              : null
          }
        />
        <div className='year-slash-month'>
          <LabelInput
            label='Expiration'
            placeholder='MM'
            className='year-input'
            maxLength={2}
            {...formik.getFieldProps('month')}
            error={
              formik.touched['month'] && formik.errors['month']
                ? formik.errors['month']
                : formik.touched['year'] && formik.errors['year']
                ? formik.errors['year']
                : null
            }
          />
          <p className='slash'>/</p>
          <LabelInput
            label=' '
            placeholder='YY'
            className='month-input'
            maxLength={2}
            {...formik.getFieldProps('year')}
          />
        </div>
      </div>
    </form>
  );
}
