import { useState } from 'react';
import PageTemplate from './PageTemplate';
import SignInPanel from './SignInPanel';
import './Login.scss';

export default function Login() {
  const searchParams = new URLSearchParams(window.location.search);
  const redirect = searchParams.get('redirect');

  return (
    <div className='login-page'>
      <PageTemplate home>
        <div className='login-page-content'>
          <h3>Please login to continue</h3>
          <SignInPanel navigatePath={redirect} />
        </div>
      </PageTemplate>
    </div>
  );
}
