import { Link } from 'react-router-dom';
import './OutroTextFreightHauler.scss';

export default function OutroTextFreightHauler({ text, to }) {
  return (
    <Link to={to} className='outro-text-freight-hauler'>
      {text}
    </Link>
  );
}
