import PageTemplate from './PageTemplate';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import PaymentFormCard from './PaymentFormCard';
import PaymentFormBank from './PaymentFormBank';
import Radio from './Radio';
import ModalPopup from './ModalPopup';
import { paymentMethods } from '../containers/Payment';
import './PaymentVisual.scss';

export default function PaymentVisual({
  amount,
  paymentMethod,
  setPaymentMethod,
  onPayClick,
  formikCard,
  formikBank,
  awaitFlag,
  linkErr,
  paymentStatus,
  setPaymentStatus,
}) {
  const onPaymentMethodChange = (e) => setPaymentMethod(e.target.value);

  return (
    <PageTemplate home>
      <div className='payments'>
        <OrderFormTemplate1Btn
          header={'Payment amount: $' + amount}
          btnText='PAY'
          status='active'
          small
          onClick={onPayClick}
          spinner={awaitFlag}
        >
          <div className='form-template-content'>
            <div className='payment-method-radio'>
              <Radio
                label='Payng via card'
                name='payment-method'
                value={paymentMethods.card}
                checked={paymentMethod === paymentMethods.card}
                onChange={onPaymentMethodChange}
              />
              <Radio
                label='Payng via bank account'
                name='payment-method'
                value={paymentMethods.bank}
                checked={paymentMethod === paymentMethods.bank}
                onChange={onPaymentMethodChange}
              />
            </div>
            {paymentMethod === paymentMethods.card && (
              <PaymentFormCard formik={formikCard} />
            )}
            {paymentMethod === paymentMethods.bank && (
              <PaymentFormBank formik={formikBank} />
            )}
          </div>
        </OrderFormTemplate1Btn>
        <ModalPopup show={linkErr}>
          <OrderFormTemplate1Btn header='Error' noMarginTop>
            <h5>{linkErr}</h5>
          </OrderFormTemplate1Btn>
        </ModalPopup>
        <ModalPopup
          show={paymentStatus}
          onClose={
            paymentStatus && paymentStatus.closeBtn
              ? () => setPaymentStatus(null)
              : undefined
          }
        >
          <OrderFormTemplate1Btn
            header={paymentStatus ? paymentStatus.header : ''}
            noMarginTop
            onClick={
              paymentStatus && paymentStatus.closeBtn
                ? () => setPaymentStatus(null)
                : undefined
            }
            btnText={paymentStatus && paymentStatus.closeBtn ? 'OK' : undefined}
            small
          >
            <h5 className='payment-status-msg'>
              {paymentStatus ? paymentStatus.msg : ''}
            </h5>
          </OrderFormTemplate1Btn>
        </ModalPopup>
      </div>
    </PageTemplate>
  );
}
