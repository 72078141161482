export const zips = {
  AU: {
    1001: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1002: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1003: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1004: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1005: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1006: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1007: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1008: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1009: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1010: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1020: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1021: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1022: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1023: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1025: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1026: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1027: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1028: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1029: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1030: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1031: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1032: {
      lat: 150.874182,
      lng: -33.662834,
      city: 'Sydney',
      state: 'NSW',
    },
    1033: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1034: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1035: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1036: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1037: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1038: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1039: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1040: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1041: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1042: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1043: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1044: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1045: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1046: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1100: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1101: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1105: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1106: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1107: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1108: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1109: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1110: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1112: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1113: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1114: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1115: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1116: {
      lat: 150.866145,
      lng: -33.666729,
      city: 'Sydney',
      state: 'NSW',
    },
    1117: {
      lat: 150.87022,
      lng: -33.664575,
      city: 'Sydney',
      state: 'NSW',
    },
    1118: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1119: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1120: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1121: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1122: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1123: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1124: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1125: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1126: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1127: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1128: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1129: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1130: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1131: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1132: {
      lat: 150.874265,
      lng: -33.66279,
      city: 'Sydney',
      state: 'NSW',
    },
    1133: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1134: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1135: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1136: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1137: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1138: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1139: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1140: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1141: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1142: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1143: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1144: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1145: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1146: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1147: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1148: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1149: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1150: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1151: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1152: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1153: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1154: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1155: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1156: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1157: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1158: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1159: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1160: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1161: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1162: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1163: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1164: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1165: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1166: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1167: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1168: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1169: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1170: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1171: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1172: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1173: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1174: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1175: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1176: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1177: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1178: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1179: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1180: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1181: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1182: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1183: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1184: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1185: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1186: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1187: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1188: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1189: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1190: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1191: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1192: {
      lat: 147.685283,
      lng: -34.790684,
      city: 'Sydney',
      state: 'NSW',
    },
    1193: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1194: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1195: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1196: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1197: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1198: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1199: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1201: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1202: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1203: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1205: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1207: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1208: {
      lat: 151.659454,
      lng: -29.816475,
      city: 'Haymarket',
      state: 'NSW',
    },
    1209: {
      lat: 151.176251,
      lng: -33.891788,
      city: 'Australia Square',
      state: 'NSW',
    },
    1210: {
      lat: 151.176251,
      lng: -33.891788,
      city: 'Australia Square',
      state: 'NSW',
    },
    1211: {
      lat: 151.176251,
      lng: -33.891788,
      city: 'Australia Square',
      state: 'NSW',
    },
    1212: {
      lat: 151.176251,
      lng: -33.891788,
      city: 'Australia Square',
      state: 'NSW',
    },
    1213: {
      lat: 151.176251,
      lng: -33.891788,
      city: 'Australia Square',
      state: 'NSW',
    },
    1214: {
      lat: 151.176251,
      lng: -33.891788,
      city: 'Australia Square',
      state: 'NSW',
    },
    1215: {
      lat: 151.176251,
      lng: -33.891788,
      city: 'Australia Square',
      state: 'NSW',
    },
    1216: {
      lat: 151.034025,
      lng: -33.741311,
      city: 'Grosvenor Place',
      state: 'NSW',
    },
    1217: {
      lat: 151.034025,
      lng: -33.741311,
      city: 'Grosvenor Place',
      state: 'NSW',
    },
    1218: {
      lat: 151.034025,
      lng: -33.741311,
      city: 'Grosvenor Place',
      state: 'NSW',
    },
    1219: {
      lat: 151.034025,
      lng: -33.741311,
      city: 'Grosvenor Place',
      state: 'NSW',
    },
    1220: {
      lat: 151.034025,
      lng: -33.741311,
      city: 'Grosvenor Place',
      state: 'NSW',
    },
    1221: {
      lat: 151.207905,
      lng: -33.86533,
      city: 'Royal Exchange',
      state: 'NSW',
    },
    1222: {
      lat: 151.207905,
      lng: -33.86533,
      city: 'Royal Exchange',
      state: 'NSW',
    },
    1223: {
      lat: 151.207905,
      lng: -33.86533,
      city: 'Royal Exchange',
      state: 'NSW',
    },
    1224: {
      lat: 151.207905,
      lng: -33.86533,
      city: 'Royal Exchange',
      state: 'NSW',
    },
    1225: {
      lat: 151.207905,
      lng: -33.86533,
      city: 'Royal Exchange',
      state: 'NSW',
    },
    1226: {
      lat: 151.206708,
      lng: -33.871749,
      city: 'Queen Victoria Building',
      state: 'NSW',
    },
    1227: {
      lat: 151.206708,
      lng: -33.871749,
      city: 'Queen Victoria Building',
      state: 'NSW',
    },
    1228: {
      lat: 151.206708,
      lng: -33.871749,
      city: 'Queen Victoria Building',
      state: 'NSW',
    },
    1229: {
      lat: 151.206708,
      lng: -33.871749,
      city: 'Queen Victoria Building',
      state: 'NSW',
    },
    1230: {
      lat: 151.206708,
      lng: -33.871749,
      city: 'Queen Victoria Building',
      state: 'NSW',
    },
    1231: {
      lat: 151.042528,
      lng: -33.815551,
      city: 'Sydney South',
      state: 'NSW',
    },
    1232: {
      lat: 151.042528,
      lng: -33.815551,
      city: 'Sydney South',
      state: 'NSW',
    },
    1233: {
      lat: 151.042528,
      lng: -33.815551,
      city: 'Sydney South',
      state: 'NSW',
    },
    1234: {
      lat: 151.042528,
      lng: -33.815551,
      city: 'Sydney South',
      state: 'NSW',
    },
    1235: {
      lat: 151.042528,
      lng: -33.815551,
      city: 'Sydney South',
      state: 'NSW',
    },
    1236: {
      lat: 151.659454,
      lng: -29.816475,
      city: 'Haymarket',
      state: 'NSW',
    },
    1237: {
      lat: 151.659454,
      lng: -29.816475,
      city: 'Haymarket',
      state: 'NSW',
    },
    1238: {
      lat: 151.659454,
      lng: -29.816475,
      city: 'Haymarket',
      state: 'NSW',
    },
    1239: {
      lat: 151.659454,
      lng: -29.816475,
      city: 'Haymarket',
      state: 'NSW',
    },
    1240: {
      lat: 151.659454,
      lng: -29.816475,
      city: 'Haymarket',
      state: 'NSW',
    },
    1291: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1292: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1293: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1294: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1295: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1296: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1297: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1298: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1299: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    1300: {
      lat: 151.220876,
      lng: -33.877331,
      city: 'Darlinghurst',
      state: 'NSW',
    },
    1314: {
      lat: 151.21,
      lng: -33.9508,
      city: 'Eastern Suburbs Mc',
      state: 'NSW',
    },
    1335: {
      lat: 151.117575,
      lng: -33.82269,
      city: 'Potts Point',
      state: 'NSW',
    },
    1340: {
      lat: 148.021014,
      lng: -35.537721,
      city: 'Kings Cross',
      state: 'NSW',
    },
    1350: {
      lat: 151.240508,
      lng: -33.88871,
      city: 'Woollahra',
      state: 'NSW',
    },
    1355: {
      lat: 151.262502,
      lng: -33.893739,
      city: 'Bondi Junction',
      state: 'NSW',
    },
    1360: {
      lat: 151.241938,
      lng: -33.87584,
      city: 'Double Bay',
      state: 'NSW',
    },
    1401: {
      lat: 151.199825,
      lng: -33.884217,
      city: 'Broadway',
      state: 'NSW',
    },
    1405: { lat: 0, lng: 0, city: 'Chullora', state: 'NSW' },
    1419: {
      lat: 151.094,
      lng: -34.0641,
      city: 'Southern Suburbs Mc',
      state: 'NSW',
    },
    1420: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    1422: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    1423: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    1424: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    1425: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    1426: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    1427: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    1428: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    1429: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    1430: {
      lat: 151.199489,
      lng: -33.890232,
      city: 'Eveleigh',
      state: 'NSW',
    },
    1435: {
      lat: 151.108248,
      lng: -33.711785,
      city: 'Alexandria',
      state: 'NSW',
    },
    1440: {
      lat: 151.057914,
      lng: -33.902836,
      city: 'Waterloo',
      state: 'NSW',
    },
    1441: {
      lat: 151.057914,
      lng: -33.902836,
      city: 'Waterloo',
      state: 'NSW',
    },
    1445: {
      lat: 151.007691,
      lng: -34.083089,
      city: 'Rosebery',
      state: 'NSW',
    },
    1450: {
      lat: 153.13679,
      lng: -30.305815,
      city: 'Camperdown',
      state: 'NSW',
    },
    1455: {
      lat: 151.197644,
      lng: -33.947087,
      city: 'Botany',
      state: 'NSW',
    },
    1460: {
      lat: 151.210791,
      lng: -33.926669,
      city: 'Mascot',
      state: 'NSW',
    },
    1465: {
      lat: 151.140735,
      lng: -33.888549,
      city: 'Kensington',
      state: 'NSW',
    },
    1466: {
      lat: 151.234417,
      lng: -33.906561,
      city: 'Unsw Sydney',
      state: 'NSW',
    },
    1470: {
      lat: 151.151958,
      lng: -33.842999,
      city: 'Drummoyne',
      state: 'NSW',
    },
    1475: {
      lat: 151.15334,
      lng: -33.90911,
      city: 'Marrickville',
      state: 'NSW',
    },
    1476: {
      lat: 151.15334,
      lng: -33.90911,
      city: 'Marrickville',
      state: 'NSW',
    },
    1480: {
      lat: 151.100027,
      lng: -33.935923,
      city: 'Kingsgrove',
      state: 'NSW',
    },
    1481: {
      lat: 151.101,
      lng: -33.9646,
      city: 'Hurstville Bc',
      state: 'NSW',
    },
    1484: {
      lat: 151.101,
      lng: -33.9386,
      city: 'Kingsgrove Dc',
      state: 'NSW',
    },
    1485: {
      lat: 151.140345,
      lng: -33.974354,
      city: 'Kogarah',
      state: 'NSW',
    },
    1490: {
      lat: 151.107201,
      lng: -34.035878,
      city: 'Miranda',
      state: 'NSW',
    },
    1493: {
      lat: 151.088939,
      lng: -33.975869,
      city: 'Hurstville',
      state: 'NSW',
    },
    1495: {
      lat: 151.123943,
      lng: -34.046927,
      city: 'Caringbah',
      state: 'NSW',
    },
    1499: {
      lat: 151.0622,
      lng: -34.015705,
      city: 'Sutherland',
      state: 'NSW',
    },
    1515: {
      lat: 151.207261,
      lng: -33.824607,
      city: 'West Chatswood',
      state: 'NSW',
    },
    1560: {
      lat: 151.214797,
      lng: -33.812636,
      city: 'Northbridge',
      state: 'NSW',
    },
    1565: {
      lat: 151.193071,
      lng: -33.865367,
      city: 'Milsons Point',
      state: 'NSW',
    },
    1570: {
      lat: 151.192733,
      lng: -33.808087,
      city: 'Artarmon',
      state: 'NSW',
    },
    1585: {
      lat: 151.20085,
      lng: -33.83459,
      city: 'Crows Nest',
      state: 'NSW',
    },
    1590: {
      lat: 151.468652,
      lng: -33.292001,
      city: 'St Leonards',
      state: 'NSW',
    },
    1595: {
      lat: 151.187955,
      lng: -33.791875,
      city: 'Lane Cove',
      state: 'NSW',
    },
    1597: {
      lat: 151.187955,
      lng: -33.791875,
      city: 'Lane Cove',
      state: 'NSW',
    },
    1602: {
      lat: 151.166,
      lng: -33.8148,
      city: 'Lane Cove Dc',
      state: 'NSW',
    },
    1630: {
      lat: 151.099812,
      lng: -33.707684,
      city: 'Hornsby',
      state: 'NSW',
    },
    1635: {
      lat: 151.101,
      lng: -33.7046,
      city: 'Hornsby Westfield',
      state: 'NSW',
    },
    1639: {
      lat: 151.246751,
      lng: -33.793137,
      city: 'Frenchs Forest',
      state: 'NSW',
    },
    1640: {
      lat: 151.246751,
      lng: -33.793137,
      city: 'Frenchs Forest',
      state: 'NSW',
    },
    1655: {
      lat: 151.505125,
      lng: -33.329799,
      city: 'Manly',
      state: 'NSW',
    },
    1658: {
      lat: 151.216799,
      lng: -33.698773,
      city: 'Mona Vale',
      state: 'NSW',
    },
    1660: {
      lat: 151.216799,
      lng: -33.698773,
      city: 'Mona Vale',
      state: 'NSW',
    },
    1670: {
      lat: 151.12,
      lng: -33.7932,
      city: 'North Ryde Bc',
      state: 'NSW',
    },
    1675: {
      lat: 151.139681,
      lng: -33.833033,
      city: 'Gladesville',
      state: 'NSW',
    },
    1680: {
      lat: 151.137807,
      lng: -33.761498,
      city: 'Ryde',
      state: 'NSW',
    },
    1685: {
      lat: 151.09064,
      lng: -33.80406,
      city: 'West Ryde',
      state: 'NSW',
    },
    1700: {
      lat: 151.206982,
      lng: -33.950299,
      city: 'Ermington',
      state: 'NSW',
    },
    1710: {
      lat: 151.116696,
      lng: -33.78417,
      city: 'Epping',
      state: 'NSW',
    },
    1715: {
      lat: 151.049106,
      lng: -33.758433,
      city: 'Pennant Hills',
      state: 'NSW',
    },
    1730: {
      lat: 150.966912,
      lng: -33.760263,
      city: 'Seven Hills',
      state: 'NSW',
    },
    1740: {
      lat: 151.139472,
      lng: -33.886166,
      city: 'Parramatta',
      state: 'NSW',
    },
    1741: {
      lat: 151.139472,
      lng: -33.886166,
      city: 'Parramatta',
      state: 'NSW',
    },
    1750: {
      lat: 151.023102,
      lng: -33.857053,
      city: 'North Parramatta',
      state: 'NSW',
    },
    1755: {
      lat: 150.968177,
      lng: -33.767239,
      city: 'Baulkham Hills',
      state: 'NSW',
    },
    1765: {
      lat: 151.030535,
      lng: -33.735906,
      city: 'Castle Hill',
      state: 'NSW',
    },
    1771: {
      lat: 151.049106,
      lng: -33.758433,
      city: 'Pennant Hills',
      state: 'NSW',
    },
    1781: {
      lat: 150.932,
      lng: -33.7779,
      city: 'Seven Hills Mc',
      state: 'NSW',
    },
    1790: {
      lat: 151.19554,
      lng: -33.859047,
      city: 'St Marys',
      state: 'NSW',
    },
    1800: {
      lat: 151.124008,
      lng: -33.887602,
      city: 'Ashfield',
      state: 'NSW',
    },
    1805: {
      lat: 151.10082,
      lng: -33.891556,
      city: 'Burwood',
      state: 'NSW',
    },
    1811: {
      lat: 151.051375,
      lng: -33.82328,
      city: 'Silverwater',
      state: 'NSW',
    },
    1819: {
      lat: 151.093326,
      lng: -33.877139,
      city: 'Strathfield',
      state: 'NSW',
    },
    1825: {
      lat: 151.044768,
      lng: -33.872483,
      city: 'Lidcombe',
      state: 'NSW',
    },
    1830: {
      lat: 150.948582,
      lng: -33.859289,
      city: 'Granville',
      state: 'NSW',
    },
    1831: {
      lat: 150.948582,
      lng: -33.859289,
      city: 'Granville',
      state: 'NSW',
    },
    1835: {
      lat: 151.023796,
      lng: -33.883928,
      city: 'Auburn',
      state: 'NSW',
    },
    1851: {
      lat: 150.911,
      lng: -33.8498,
      city: 'Wetherill Park Dc',
      state: 'NSW',
    },
    1860: {
      lat: 150.961124,
      lng: -33.850457,
      city: 'Fairfield',
      state: 'NSW',
    },
    1871: {
      lat: 151.103632,
      lng: -33.888327,
      city: 'Liverpool',
      state: 'NSW',
    },
    1874: { lat: 0, lng: 0, city: 'Moorebank', state: 'NSW' },
    1875: {
      lat: 150.92236,
      lng: -33.954639,
      city: 'Moorebank',
      state: 'NSW',
    },
    1885: {
      lat: 151.024581,
      lng: -33.907417,
      city: 'Bankstown',
      state: 'NSW',
    },
    1888: {
      lat: 151.024581,
      lng: -33.907417,
      city: 'Bankstown',
      state: 'NSW',
    },
    1890: {
      lat: 150.802088,
      lng: -33.960035,
      city: 'Ingleburn',
      state: 'NSW',
    },
    1891: {
      lat: 151.000183,
      lng: -33.932221,
      city: 'Milperra',
      state: 'NSW',
    },
    2000: {
      lat: 151.256649,
      lng: -33.859953,
      city: 'The Rocks',
      state: 'NSW',
    },
    2001: {
      lat: 151.268071,
      lng: -33.794883,
      city: 'Sydney',
      state: 'NSW',
    },
    2002: {
      lat: 151.2069237,
      lng: -33.8771211,
      city: 'World Square',
      state: 'NSW',
    },
    2004: {
      lat: 151.21,
      lng: -33.9508,
      city: 'Eastern Suburbs Mc',
      state: 'NSW',
    },
    2006: {
      lat: 151.186507,
      lng: -33.889219,
      city: 'The University Of Sydney',
      state: 'NSW',
    },
    2007: {
      lat: 151.19665,
      lng: -33.883189,
      city: 'Ultimo',
      state: 'NSW',
    },
    2008: {
      lat: 151.193858,
      lng: -33.891146,
      city: 'Golden Grove',
      state: 'NSW',
    },
    2009: {
      lat: 151.193055,
      lng: -33.871222,
      city: 'Pyrmont',
      state: 'NSW',
    },
    2010: {
      lat: 151.212262,
      lng: -33.884119,
      city: 'Taylor Square',
      state: 'NSW',
    },
    2011: {
      lat: 151.221626,
      lng: -33.873599,
      city: 'Woolloomooloo',
      state: 'NSW',
    },
    2012: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    2013: {
      lat: 150.931838,
      lng: -33.726098,
      city: 'Strawberry Hills',
      state: 'NSW',
    },
    2015: {
      lat: 151.194825,
      lng: -33.910105,
      city: 'Eveleigh',
      state: 'NSW',
    },
    2016: {
      lat: 151.206211,
      lng: -33.894912,
      city: 'Redfern',
      state: 'NSW',
    },
    2017: {
      lat: 151.206316,
      lng: -33.903892,
      city: 'Zetland',
      state: 'NSW',
    },
    2018: {
      lat: 151.202697,
      lng: -33.9233,
      city: 'Rosebery',
      state: 'NSW',
    },
    2019: {
      lat: 151.207285,
      lng: -33.946923,
      city: 'Botany',
      state: 'NSW',
    },
    2020: {
      lat: 151.176775,
      lng: -33.936179,
      city: 'Sydney International Airport',
      state: 'NSW',
    },
    2021: {
      lat: 151.227236,
      lng: -33.895705,
      city: 'Paddington',
      state: 'NSW',
    },
    2022: {
      lat: 151.245049,
      lng: -33.896401,
      city: 'Queens Park',
      state: 'NSW',
    },
    2023: {
      lat: 151.25591,
      lng: -33.884685,
      city: 'Bellevue Hill',
      state: 'NSW',
    },
    2024: {
      lat: 151.259392,
      lng: -33.904414,
      city: 'Waverley',
      state: 'NSW',
    },
    2025: {
      lat: 151.240508,
      lng: -33.88871,
      city: 'Woollahra',
      state: 'NSW',
    },
    2026: {
      lat: 151.268968,
      lng: -33.891041,
      city: 'Tamarama',
      state: 'NSW',
    },
    2027: {
      lat: 151.250494,
      lng: -33.868972,
      city: 'Point Piper',
      state: 'NSW',
    },
    2028: {
      lat: 151.240965,
      lng: -33.878413,
      city: 'Double Bay',
      state: 'NSW',
    },
    2029: {
      lat: 151.266989,
      lng: -33.875709,
      city: 'Rose Bay',
      state: 'NSW',
    },
    2030: {
      lat: 151.275977,
      lng: -33.858378,
      city: 'Watsons Bay',
      state: 'NSW',
    },
    2031: {
      lat: 151.239167,
      lng: -33.914832,
      city: 'St Pauls',
      state: 'NSW',
    },
    2032: {
      lat: 151.223936,
      lng: -33.930314,
      city: 'Kingsford',
      state: 'NSW',
    },
    2033: {
      lat: 151.218435,
      lng: -33.91139,
      city: 'Kensington',
      state: 'NSW',
    },
    2034: {
      lat: 151.252171,
      lng: -33.929096,
      city: 'South Coogee',
      state: 'NSW',
    },
    2035: {
      lat: 151.241292,
      lng: -33.945635,
      city: 'Pagewood',
      state: 'NSW',
    },
    2036: {
      lat: 151.237844,
      lng: -33.969624,
      city: 'Port Botany',
      state: 'NSW',
    },
    2037: {
      lat: 151.184458,
      lng: -33.880179,
      city: 'Glebe',
      state: 'NSW',
    },
    2038: {
      lat: 151.170165,
      lng: -33.881624,
      city: 'Annandale',
      state: 'NSW',
    },
    2039: {
      lat: 151.171915,
      lng: -33.867187,
      city: 'Rozelle',
      state: 'NSW',
    },
    2040: {
      lat: 151.156819,
      lng: -33.878774,
      city: 'Lilyfield',
      state: 'NSW',
    },
    2041: {
      lat: 151.180095,
      lng: -33.858556,
      city: 'Birchgrove',
      state: 'NSW',
    },
    2042: {
      lat: 151.175354,
      lng: -33.900649,
      city: 'Newtown',
      state: 'NSW',
    },
    2043: {
      lat: 151.184665,
      lng: -33.903521,
      city: 'Erskineville',
      state: 'NSW',
    },
    2044: {
      lat: 151.17074,
      lng: -33.920698,
      city: 'Tempe',
      state: 'NSW',
    },
    2045: {
      lat: 151.138684,
      lng: -33.879301,
      city: 'Haberfield',
      state: 'NSW',
    },
    2046: {
      lat: 151.133865,
      lng: -33.866044,
      city: 'Wareemba',
      state: 'NSW',
    },
    2047: {
      lat: 151.165735,
      lng: -33.853924,
      city: 'Drummoyne',
      state: 'NSW',
    },
    2048: {
      lat: 151.16564,
      lng: -33.89418,
      city: 'Westgate',
      state: 'NSW',
    },
    2049: {
      lat: 151.15085,
      lng: -33.897219,
      city: 'Petersham North',
      state: 'NSW',
    },
    2050: {
      lat: 151.17598,
      lng: -33.89037,
      city: 'Missenden Road',
      state: 'NSW',
    },
    2052: {
      lat: 151.234417,
      lng: -33.906561,
      city: 'Unsw Sydney',
      state: 'NSW',
    },
    2055: {
      lat: 151.104935,
      lng: -33.802837,
      city: 'North Sydney',
      state: 'NSW',
    },
    2057: {
      lat: 151.1899,
      lng: -33.791988,
      city: 'Chatswood',
      state: 'NSW',
    },
    2058: {
      lat: 146.921,
      lng: -31.2532,
      city: 'Northern Suburbs Mc',
      state: 'NSW',
    },
    2059: {
      lat: 151.104935,
      lng: -33.802837,
      city: 'North Sydney',
      state: 'NSW',
    },
    2060: {
      lat: 151.204557,
      lng: -33.839863,
      city: 'Waverton',
      state: 'NSW',
    },
    2061: {
      lat: 151.212372,
      lng: -33.848785,
      city: 'Milsons Point',
      state: 'NSW',
    },
    2062: {
      lat: 151.21246,
      lng: -33.822837,
      city: 'Cammeray',
      state: 'NSW',
    },
    2063: {
      lat: 151.214797,
      lng: -33.812636,
      city: 'Northbridge',
      state: 'NSW',
    },
    2064: {
      lat: 151.183801,
      lng: -33.813209,
      city: 'Artarmon',
      state: 'NSW',
    },
    2065: {
      lat: 151.192212,
      lng: -33.828361,
      city: 'Wollstonecraft',
      state: 'NSW',
    },
    2066: {
      lat: 151.160912,
      lng: -33.818641,
      city: 'Riverview',
      state: 'NSW',
    },
    2067: {
      lat: 151.17711,
      lng: -33.798378,
      city: 'Chatswood West',
      state: 'NSW',
    },
    2068: {
      lat: 151.202715,
      lng: -33.800881,
      city: 'Willoughby North',
      state: 'NSW',
    },
    2069: {
      lat: 151.195368,
      lng: -33.780246,
      city: 'Roseville Chase',
      state: 'NSW',
    },
    2070: {
      lat: 151.162007,
      lng: -33.78042,
      city: 'Lindfield West',
      state: 'NSW',
    },
    2071: {
      lat: 151.171738,
      lng: -33.761834,
      city: 'Killara',
      state: 'NSW',
    },
    2072: {
      lat: 151.153176,
      lng: -33.754582,
      city: 'Gordon',
      state: 'NSW',
    },
    2073: {
      lat: 151.130278,
      lng: -33.757296,
      city: 'West Pymble',
      state: 'NSW',
    },
    2074: {
      lat: 151.123716,
      lng: -33.735222,
      city: 'Warrawee',
      state: 'NSW',
    },
    2075: {
      lat: 151.16729,
      lng: -33.723693,
      city: 'St Ives Chase',
      state: 'NSW',
    },
    2076: {
      lat: 151.108249,
      lng: -33.723263,
      city: 'Wahroonga',
      state: 'NSW',
    },
    2077: {
      lat: 151.097508,
      lng: -33.691922,
      city: 'Waitara',
      state: 'NSW',
    },
    2079: {
      lat: 151.112681,
      lng: -33.666637,
      city: 'Mount Colah',
      state: 'NSW',
    },
    2080: {
      lat: 151.143563,
      lng: -33.658174,
      city: 'Mount Kuring-gai',
      state: 'NSW',
    },
    2081: {
      lat: 151.150498,
      lng: -33.619756,
      city: 'Cowan',
      state: 'NSW',
    },
    2082: {
      lat: 151.168984,
      lng: -33.561858,
      city: 'Berowra Waters',
      state: 'NSW',
    },
    2083: {
      lat: 151.194452,
      lng: -33.525322,
      city: 'Mooney Mooney',
      state: 'NSW',
    },
    2084: {
      lat: 151.216929,
      lng: -33.650999,
      city: 'Terrey Hills',
      state: 'NSW',
    },
    2085: {
      lat: 151.211719,
      lng: -33.728782,
      city: 'Davidson',
      state: 'NSW',
    },
    2086: {
      lat: 151.229211,
      lng: -33.75185,
      city: 'Frenchs Forest East',
      state: 'NSW',
    },
    2087: {
      lat: 151.210531,
      lng: -33.766093,
      city: 'Killarney Heights',
      state: 'NSW',
    },
    2088: {
      lat: 151.243351,
      lng: -33.830687,
      city: 'Spit Junction',
      state: 'NSW',
    },
    2089: {
      lat: 151.216869,
      lng: -33.838021,
      city: 'Neutral Bay Junction',
      state: 'NSW',
    },
    2090: {
      lat: 151.223094,
      lng: -33.828949,
      city: 'Cremorne Point',
      state: 'NSW',
    },
    2091: {
      lat: 151.261,
      lng: -33.8279,
      city: 'Hmas Penguin',
      state: 'NSW',
    },
    2092: {
      lat: 151.241813,
      lng: -33.796636,
      city: 'Seaforth',
      state: 'NSW',
    },
    2093: {
      lat: 151.258069,
      lng: -33.79315,
      city: 'North Balgowlah',
      state: 'NSW',
    },
    2094: {
      lat: 151.273242,
      lng: -33.795227,
      city: 'Fairlight',
      state: 'NSW',
    },
    2095: {
      lat: 151.294675,
      lng: -33.815482,
      city: 'Manly East',
      state: 'NSW',
    },
    2096: {
      lat: 151.28494,
      lng: -33.776781,
      city: 'Queenscliff',
      state: 'NSW',
    },
    2097: {
      lat: 151.297895,
      lng: -33.739577,
      city: 'Wheeler Heights',
      state: 'NSW',
    },
    2099: {
      lat: 151.279999,
      lng: -33.748914,
      city: 'Wingala',
      state: 'NSW',
    },
    2100: {
      lat: 151.257781,
      lng: -33.766409,
      city: 'Warringah Mall',
      state: 'NSW',
    },
    2101: {
      lat: 151.27114,
      lng: -33.707117,
      city: 'North Narrabeen',
      state: 'NSW',
    },
    2102: {
      lat: 151.290953,
      lng: -33.689941,
      city: 'Warriewood Shopping Square',
      state: 'NSW',
    },
    2103: {
      lat: 151.302825,
      lng: -33.679336,
      city: 'Mona Vale',
      state: 'NSW',
    },
    2104: {
      lat: 151.290921,
      lng: -33.664921,
      city: 'Bayview',
      state: 'NSW',
    },
    2105: {
      lat: 151.278293,
      lng: -33.642202,
      city: 'Scotland Island',
      state: 'NSW',
    },
    2106: {
      lat: 151.314599,
      lng: -33.660707,
      city: 'Newport Beach',
      state: 'NSW',
    },
    2107: {
      lat: 151.323289,
      lng: -33.634816,
      city: 'Whale Beach',
      state: 'NSW',
    },
    2108: {
      lat: 151.322759,
      lng: -33.60382,
      city: 'The Basin',
      state: 'NSW',
    },
    2109: {
      lat: 151.113473,
      lng: -33.771558,
      city: 'Macquarie University',
      state: 'NSW',
    },
    2110: {
      lat: 151.149126,
      lng: -33.836102,
      city: 'Woolwich',
      state: 'NSW',
    },
    2111: {
      lat: 151.124488,
      lng: -33.829953,
      city: 'Tennyson Point',
      state: 'NSW',
    },
    2112: {
      lat: 151.108447,
      lng: -33.81658,
      city: 'Ryde',
      state: 'NSW',
    },
    2113: {
      lat: 151.130233,
      lng: -33.79419,
      city: 'North Ryde',
      state: 'NSW',
    },
    2114: {
      lat: 151.084181,
      lng: -33.810991,
      city: 'West Ryde',
      state: 'NSW',
    },
    2115: {
      lat: 151.059845,
      lng: -33.813106,
      city: 'Ermington',
      state: 'NSW',
    },
    2116: {
      lat: 151.036941,
      lng: -33.814368,
      city: 'Rydalmere',
      state: 'NSW',
    },
    2117: {
      lat: 151.04218,
      lng: -33.797127,
      city: 'Telopea',
      state: 'NSW',
    },
    2118: {
      lat: 151.043518,
      lng: -33.775596,
      city: 'Kingsdene',
      state: 'NSW',
    },
    2119: {
      lat: 151.064176,
      lng: -33.755499,
      city: 'Cheltenham',
      state: 'NSW',
    },
    2120: {
      lat: 151.07359,
      lng: -33.731105,
      city: 'Westleigh',
      state: 'NSW',
    },
    2121: {
      lat: 151.079919,
      lng: -33.774145,
      city: 'North Epping',
      state: 'NSW',
    },
    2122: {
      lat: 151.092072,
      lng: -33.791357,
      city: 'Marsfield',
      state: 'NSW',
    },
    2123: {
      lat: 150.999575,
      lng: -33.815446,
      city: 'Parramatta',
      state: 'NSW',
    },
    2124: {
      lat: 151.139472,
      lng: -33.886166,
      city: 'Parramatta',
      state: 'NSW',
    },
    2125: {
      lat: 151.031807,
      lng: -33.752645,
      city: 'West Pennant Hills',
      state: 'NSW',
    },
    2126: {
      lat: 151.043941,
      lng: -33.724898,
      city: 'Cherrybrook',
      state: 'NSW',
    },
    2127: {
      lat: 151.0771675,
      lng: -33.82764565,
      city: 'Wentworth Point',
      state: 'NSW',
    },
    2128: {
      lat: 151.047591,
      lng: -33.835928,
      city: 'Silverwater',
      state: 'NSW',
    },
    2129: {
      lat: 151.069553,
      lng: -33.864104,
      city: 'Sydney Markets',
      state: 'NSW',
    },
    2130: {
      lat: 151.135883,
      lng: -33.892783,
      city: 'Summer Hill',
      state: 'NSW',
    },
    2131: {
      lat: 151.124008,
      lng: -33.887602,
      city: 'Ashfield',
      state: 'NSW',
    },
    2132: {
      lat: 151.113385,
      lng: -33.882419,
      city: 'Croydon',
      state: 'NSW',
    },
    2133: {
      lat: 151.102087,
      lng: -33.898793,
      city: 'Enfield South',
      state: 'NSW',
    },
    2134: {
      lat: 151.101757,
      lng: -33.880049,
      city: 'Burwood North',
      state: 'NSW',
    },
    2135: {
      lat: 151.084759,
      lng: -33.881009,
      city: 'Strathfield',
      state: 'NSW',
    },
    2136: {
      lat: 151.087741,
      lng: -33.892944,
      city: 'Strathfield South',
      state: 'NSW',
    },
    2137: {
      lat: 151.103751,
      lng: -33.85749,
      city: 'North Strathfield',
      state: 'NSW',
    },
    2138: {
      lat: 151.086036,
      lng: -33.846098,
      city: 'Rhodes',
      state: 'NSW',
    },
    2139: {
      lat: 151.095163,
      lng: -33.835993,
      city: 'Concord Repatriation Hospital',
      state: 'NSW',
    },
    2140: {
      lat: 151.070853,
      lng: -33.835018,
      city: 'Homebush West',
      state: 'NSW',
    },
    2141: {
      lat: 151.044768,
      lng: -33.872483,
      city: 'Rookwood',
      state: 'NSW',
    },
    2142: {
      lat: 151.0106782,
      lng: -33.85780796,
      city: 'South Granville',
      state: 'NSW',
    },
    2143: {
      lat: 151.028,
      lng: -33.8857,
      city: 'Regents Park Dc',
      state: 'NSW',
    },
    2144: {
      lat: 151.026756,
      lng: -33.851868,
      city: 'Auburn South',
      state: 'NSW',
    },
    2145: {
      lat: 150.957425,
      lng: -33.813104,
      city: 'Westmead',
      state: 'NSW',
    },
    2146: {
      lat: 150.959508,
      lng: -33.789374,
      city: 'Toongabbie East',
      state: 'NSW',
    },
    2147: {
      lat: 150.935836,
      lng: -33.766075,
      city: 'Seven Hills West',
      state: 'NSW',
    },
    2148: {
      lat: 150.900547,
      lng: -33.783751,
      city: 'Prospect',
      state: 'NSW',
    },
    2150: {
      lat: 151.006506,
      lng: -33.814463,
      city: 'Parramatta Westfield',
      state: 'NSW',
    },
    2151: {
      lat: 151.012511,
      lng: -33.78709,
      city: 'North Rocks',
      state: 'NSW',
    },
    2152: {
      lat: 150.996388,
      lng: -33.782501,
      city: 'Northmead',
      state: 'NSW',
    },
    2153: {
      lat: 150.955942,
      lng: -33.731651,
      city: 'Winston Hills',
      state: 'NSW',
    },
    2154: {
      lat: 151.000948,
      lng: -33.73238,
      city: 'Castle Hill',
      state: 'NSW',
    },
    2155: {
      lat: 150.931074,
      lng: -33.731523,
      city: 'Rouse Hill',
      state: 'NSW',
    },
    2156: {
      lat: 150.974528,
      lng: -33.656984,
      city: 'Kenthurst',
      state: 'NSW',
    },
    2157: {
      lat: 150.991055,
      lng: -33.602167,
      city: 'Hillside',
      state: 'NSW',
    },
    2158: {
      lat: 151.040358,
      lng: -33.690331,
      city: 'Round Corner',
      state: 'NSW',
    },
    2159: {
      lat: 151.084146,
      lng: -33.539148,
      city: 'Galston',
      state: 'NSW',
    },
    2160: {
      lat: 150.976594,
      lng: -33.835883,
      city: 'Merrylands West',
      state: 'NSW',
    },
    2161: {
      lat: 150.983724,
      lng: -33.858335,
      city: 'Yennora',
      state: 'NSW',
    },
    2162: {
      lat: 151.004128,
      lng: -33.88349,
      city: 'Sefton',
      state: 'NSW',
    },
    2163: {
      lat: 150.979458,
      lng: -33.887203,
      city: 'Villawood',
      state: 'NSW',
    },
    2164: {
      lat: 150.875028,
      lng: -33.844153,
      city: 'Woodpark',
      state: 'NSW',
    },
    2165: {
      lat: 150.947496,
      lng: -33.872435,
      city: 'Fairfield West',
      state: 'NSW',
    },
    2166: {
      lat: 150.92937,
      lng: -33.892738,
      city: 'Lansvale',
      state: 'NSW',
    },
    2167: {
      lat: 150.890441,
      lng: -33.972167,
      city: 'Glenfield',
      state: 'NSW',
    },
    2168: {
      lat: 150.875345,
      lng: -33.915939,
      city: 'Sadleir',
      state: 'NSW',
    },
    2170: {
      lat: 150.921448,
      lng: -33.928392,
      city: 'Warwick Farm',
      state: 'NSW',
    },
    2171: {
      lat: 150.970931,
      lng: -33.959617,
      city: 'West Hoxton',
      state: 'NSW',
    },
    2172: {
      lat: 150.917444,
      lng: -33.951109,
      city: 'Voyager Point',
      state: 'NSW',
    },
    2173: {
      lat: 150.919554,
      lng: -34.074239,
      city: 'Wattle Grove',
      state: 'NSW',
    },
    2174: {
      lat: 150.856709,
      lng: -33.977497,
      city: 'Ingleburn Milpo',
      state: 'NSW',
    },
    2175: {
      lat: 150.848192,
      lng: -33.845034,
      city: 'Horsley Park',
      state: 'NSW',
    },
    2176: {
      lat: 150.88526,
      lng: -33.872014,
      city: 'Wakeley',
      state: 'NSW',
    },
    2177: {
      lat: 150.886505,
      lng: -33.888801,
      city: 'Wentworth Port',
      state: 'NSW',
    },
    2178: {
      lat: 150.838225,
      lng: -33.87478,
      city: 'Mount Vernon',
      state: 'NSW',
    },
    2179: {
      lat: 150.812031,
      lng: -33.933109,
      city: 'Leppington',
      state: 'NSW',
    },
    2190: {
      lat: 151.053858,
      lng: -33.901925,
      city: 'Mount Lewis',
      state: 'NSW',
    },
    2191: {
      lat: 151.085513,
      lng: -33.906803,
      city: 'Belfield',
      state: 'NSW',
    },
    2192: {
      lat: 151.089663,
      lng: -33.921018,
      city: 'Belmore',
      state: 'NSW',
    },
    2193: {
      lat: 151.12086,
      lng: -33.910308,
      city: 'Hurlstone Park',
      state: 'NSW',
    },
    2194: {
      lat: 151.101777,
      lng: -33.917601,
      city: 'Campsie',
      state: 'NSW',
    },
    2195: {
      lat: 151.076939,
      lng: -33.924525,
      city: 'Wiley Park',
      state: 'NSW',
    },
    2196: {
      lat: 151.057799,
      lng: -33.930089,
      city: 'Roselands',
      state: 'NSW',
    },
    2197: {
      lat: 150.998155,
      lng: -33.901198,
      city: 'Bass Hill',
      state: 'NSW',
    },
    2198: {
      lat: 150.986277,
      lng: -33.908788,
      city: 'Georges Hall',
      state: 'NSW',
    },
    2199: {
      lat: 151.023171,
      lng: -33.905586,
      city: 'Yagoona West',
      state: 'NSW',
    },
    2200: {
      lat: 151.014937,
      lng: -33.926559,
      city: 'Mount Lewis',
      state: 'NSW',
    },
    2203: {
      lat: 151.138999,
      lng: -33.90441,
      city: 'Dulwich Hill',
      state: 'NSW',
    },
    2204: {
      lat: 151.155539,
      lng: -33.912288,
      city: 'Marrickville South',
      state: 'NSW',
    },
    2205: {
      lat: 151.147956,
      lng: -33.937551,
      city: 'Wolli Creek',
      state: 'NSW',
    },
    2206: {
      lat: 151.122881,
      lng: -33.926056,
      city: 'Undercliffe',
      state: 'NSW',
    },
    2207: {
      lat: 151.119517,
      lng: -33.948744,
      city: 'Bexley South',
      state: 'NSW',
    },
    2208: {
      lat: 151.098002,
      lng: -33.942632,
      city: 'Kingsway West',
      state: 'NSW',
    },
    2209: {
      lat: 151.075304,
      lng: -33.94913,
      city: 'Narwee',
      state: 'NSW',
    },
    2210: {
      lat: 151.052257,
      lng: -33.957725,
      city: 'Riverwood',
      state: 'NSW',
    },
    2211: {
      lat: 151.03221,
      lng: -33.961366,
      city: 'Padstow Heights',
      state: 'NSW',
    },
    2212: {
      lat: 151.014547,
      lng: -33.947286,
      city: 'Revesby North',
      state: 'NSW',
    },
    2213: {
      lat: 150.997836,
      lng: -33.964102,
      city: 'Picnic Point',
      state: 'NSW',
    },
    2214: {
      lat: 150.981592,
      lng: -33.941443,
      city: 'Milperra',
      state: 'NSW',
    },
    2216: {
      lat: 151.138,
      lng: -33.9529,
      city: 'Rockdale Dc',
      state: 'NSW',
    },
    2217: {
      lat: 151.140345,
      lng: -33.974354,
      city: 'Ramsgate Beach',
      state: 'NSW',
    },
    2218: {
      lat: 151.117453,
      lng: -33.973161,
      city: 'Carlton',
      state: 'NSW',
    },
    2219: {
      lat: 151.132495,
      lng: -33.996587,
      city: 'Sans Souci',
      state: 'NSW',
    },
    2220: {
      lat: 151.101764,
      lng: -33.966692,
      city: 'Hurstville Westfield',
      state: 'NSW',
    },
    2221: {
      lat: 151.10599,
      lng: -33.992159,
      city: 'South Hurstville',
      state: 'NSW',
    },
    2222: {
      lat: 151.084774,
      lng: -33.964713,
      city: 'Penshurst',
      state: 'NSW',
    },
    2223: {
      lat: 151.071837,
      lng: -33.980264,
      city: 'Oatley',
      state: 'NSW',
    },
    2224: {
      lat: 151.101891,
      lng: -34.01362,
      city: 'Sylvania Waters',
      state: 'NSW',
    },
    2225: {
      lat: 151.087892,
      lng: -33.997441,
      city: 'Oyster Bay',
      state: 'NSW',
    },
    2226: {
      lat: 151.063808,
      lng: -34.009345,
      city: 'Jannali',
      state: 'NSW',
    },
    2227: {
      lat: 151.085005,
      lng: -34.04984,
      city: 'Gymea Bay',
      state: 'NSW',
    },
    2228: {
      lat: 151.101564,
      lng: -34.037692,
      city: 'Yowie Bay',
      state: 'NSW',
    },
    2229: {
      lat: 151.120091,
      lng: -34.05494,
      city: 'Warumbul',
      state: 'NSW',
    },
    2230: {
      lat: 151.136596,
      lng: -34.093213,
      city: 'Woolooware',
      state: 'NSW',
    },
    2231: {
      lat: 151.193492,
      lng: -34.029927,
      city: 'Kurnell',
      state: 'NSW',
    },
    2232: {
      lat: 151.0852,
      lng: -34.1016,
      city: 'Yenabilli',
      state: 'NSW',
    },
    2233: {
      lat: 151.027919,
      lng: -34.114626,
      city: 'Yarrawarrah',
      state: 'NSW',
    },
    2234: {
      lat: 151.004412,
      lng: -34.021938,
      city: 'Menai Central',
      state: 'NSW',
    },
    2250: {
      lat: 151.189243,
      lng: -33.325373,
      city: 'Wyoming',
      state: 'NSW',
    },
    2251: {
      lat: 151.401999,
      lng: -33.482209,
      city: 'Yattalunga',
      state: 'NSW',
    },
    2252: {
      lat: 151.234,
      lng: -33.3208,
      city: 'Central Coast Mc',
      state: 'NSW',
    },
    2256: {
      lat: 151.331154,
      lng: -33.482123,
      city: 'Woy Woy Bay',
      state: 'NSW',
    },
    2257: {
      lat: 151.374403,
      lng: -33.520358,
      city: 'Wagstaffe',
      state: 'NSW',
    },
    2258: {
      lat: 151.3433,
      lng: -33.333187,
      city: 'Palmdale',
      state: 'NSW',
    },
    2259: {
      lat: 151.377213,
      lng: -33.208801,
      city: 'Yarramalong',
      state: 'NSW',
    },
    2260: {
      lat: 151.436939,
      lng: -33.42537,
      city: 'Wamberal',
      state: 'NSW',
    },
    2261: {
      lat: 151.519708,
      lng: -33.315827,
      city: 'Tumbi Umbi',
      state: 'NSW',
    },
    2262: {
      lat: 151.528042,
      lng: -33.209125,
      city: 'San Remo',
      state: 'NSW',
    },
    2263: {
      lat: 151.556223,
      lng: -33.285097,
      city: 'Toukley',
      state: 'NSW',
    },
    2264: {
      lat: 151.533807,
      lng: -33.106675,
      city: 'Yarrawonga Park',
      state: 'NSW',
    },
    2265: {
      lat: 151.412119,
      lng: -33.095552,
      city: 'Martinsville',
      state: 'NSW',
    },
    2267: {
      lat: 151.591728,
      lng: -33.086545,
      city: 'Wangi Wangi',
      state: 'NSW',
    },
    2278: {
      lat: 151.590415,
      lng: -32.932412,
      city: 'Wakefield',
      state: 'NSW',
    },
    2280: {
      lat: 151.663175,
      lng: -33.019715,
      city: 'Valentine',
      state: 'NSW',
    },
    2281: {
      lat: 151.668857,
      lng: -33.089963,
      city: 'Swansea Heads',
      state: 'NSW',
    },
    2282: {
      lat: 151.651275,
      lng: -32.987279,
      city: 'Warners Bay',
      state: 'NSW',
    },
    2283: {
      lat: 151.57181,
      lng: -33.019117,
      city: 'Toronto',
      state: 'NSW',
    },
    2284: {
      lat: 151.621183,
      lng: -32.953236,
      city: 'Woodrising',
      state: 'NSW',
    },
    2285: {
      lat: 151.651095,
      lng: -32.933305,
      city: 'Macquarie Hills',
      state: 'NSW',
    },
    2286: {
      lat: 151.532669,
      lng: -32.923321,
      city: 'West Wallsend',
      state: 'NSW',
    },
    2287: {
      lat: 151.65807,
      lng: -32.892577,
      city: 'Wallsend South',
      state: 'NSW',
    },
    2289: {
      lat: 151.71008,
      lng: -32.948567,
      city: 'Kotara South',
      state: 'NSW',
    },
    2290: {
      lat: 151.707801,
      lng: -32.983963,
      city: 'Whitebridge',
      state: 'NSW',
    },
    2291: {
      lat: 151.742882,
      lng: -32.951039,
      city: 'The Junction',
      state: 'NSW',
    },
    2292: {
      lat: 151.740716,
      lng: -32.931589,
      city: 'Hamilton North',
      state: 'NSW',
    },
    2293: {
      lat: 151.754696,
      lng: -32.917776,
      city: 'Wickham',
      state: 'NSW',
    },
    2294: {
      lat: 151.767408,
      lng: -32.915791,
      city: 'Carrington',
      state: 'NSW',
    },
    2295: {
      lat: 151.80174,
      lng: -32.879469,
      city: 'Stockton',
      state: 'NSW',
    },
    2296: {
      lat: 151.746237,
      lng: -32.915698,
      city: 'Islington',
      state: 'NSW',
    },
    2297: {
      lat: 151.750126,
      lng: -32.909135,
      city: 'Tighes Hill',
      state: 'NSW',
    },
    2298: {
      lat: 151.722243,
      lng: -32.905633,
      city: 'Waratah West',
      state: 'NSW',
    },
    2299: {
      lat: 151.704581,
      lng: -32.909816,
      city: 'North Lambton',
      state: 'NSW',
    },
    2300: {
      lat: 151.778021,
      lng: -32.931635,
      city: 'The Hill',
      state: 'NSW',
    },
    2302: {
      lat: 151.752466,
      lng: -32.931265,
      city: 'Newcastle West',
      state: 'NSW',
    },
    2303: {
      lat: 151.743325,
      lng: -32.924571,
      city: 'Hamilton South',
      state: 'NSW',
    },
    2304: {
      lat: 151.73862,
      lng: -32.880319,
      city: 'Warabrook',
      state: 'NSW',
    },
    2305: {
      lat: 151.706545,
      lng: -32.930221,
      city: 'New Lambton Heights',
      state: 'NSW',
    },
    2306: {
      lat: 151.682517,
      lng: -32.993286,
      city: 'Windale',
      state: 'NSW',
    },
    2307: {
      lat: 151.688102,
      lng: -32.88365,
      city: 'Shortland',
      state: 'NSW',
    },
    2308: {
      lat: 151.697356,
      lng: -32.892884,
      city: 'Newcastle University',
      state: 'NSW',
    },
    2309: {
      lat: 148.890775,
      lng: -30.352158,
      city: 'Dangar',
      state: 'NSW',
    },
    2310: {
      lat: 151.701,
      lng: -32.1932,
      city: 'Hunter Region Mc',
      state: 'NSW',
    },
    2311: {
      lat: 151.4607343,
      lng: -32.14597291,
      city: 'Upper Allyn',
      state: 'NSW',
    },
    2312: {
      lat: 152.33084,
      lng: -32.133322,
      city: 'Nabiac',
      state: 'NSW',
    },
    2314: {
      lat: 151.840094,
      lng: -32.797453,
      city: 'Williamtown Raaf',
      state: 'NSW',
    },
    2315: {
      lat: 152.193698,
      lng: -32.746224,
      city: 'Shoal Bay',
      state: 'NSW',
    },
    2316: {
      lat: 152.083274,
      lng: -32.776919,
      city: 'Taylors Beach',
      state: 'NSW',
    },
    2317: {
      lat: 152.076399,
      lng: -32.720937,
      city: 'Soldiers Point',
      state: 'NSW',
    },
    2318: {
      lat: 151.851897,
      lng: -32.769906,
      city: 'Williamtown',
      state: 'NSW',
    },
    2319: {
      lat: 151.990451,
      lng: -32.75944213,
      city: 'Tilligerry Creek',
      state: 'NSW',
    },
    2320: {
      lat: 151.543675,
      lng: -32.741737,
      city: 'Windella',
      state: 'NSW',
    },
    2321: {
      lat: 151.683702,
      lng: -32.660186,
      city: 'Woodville',
      state: 'NSW',
    },
    2322: {
      lat: 151.65415,
      lng: -32.819819,
      city: 'Woodberry',
      state: 'NSW',
    },
    2323: {
      lat: 151.607554,
      lng: -32.757616,
      city: 'Tenambit',
      state: 'NSW',
    },
    2324: {
      lat: 152.332617,
      lng: -32.623108,
      city: 'Wallaroo',
      state: 'NSW',
    },
    2325: {
      lat: 150.9115105,
      lng: -33.01498358,
      city: 'Yengo National Park',
      state: 'NSW',
    },
    2326: {
      lat: 151.453148,
      lng: -32.785207,
      city: 'Weston',
      state: 'NSW',
    },
    2327: {
      lat: 151.486212,
      lng: -32.820688,
      city: 'Stanford Merthyr',
      state: 'NSW',
    },
    2328: {
      lat: 150.564817,
      lng: -32.57693,
      city: 'Yarrawa',
      state: 'NSW',
    },
    2329: {
      lat: 150.187517,
      lng: -32.047498,
      city: 'Uarbry',
      state: 'NSW',
    },
    2330: {
      lat: 150.972137,
      lng: -32.760486,
      city: 'Wylies Flat',
      state: 'NSW',
    },
    2331: {
      lat: 151.171479,
      lng: -32.61615,
      city: 'Singleton Milpo',
      state: 'NSW',
    },
    2333: {
      lat: 150.701949,
      lng: -32.248568,
      city: 'Wybong',
      state: 'NSW',
    },
    2334: {
      lat: 151.385451,
      lng: -32.728461,
      city: 'Greta',
      state: 'NSW',
    },
    2335: {
      lat: 151.319414,
      lng: -32.642118,
      city: 'Stanhope',
      state: 'NSW',
    },
    2336: {
      lat: 151.102917,
      lng: -32.14622,
      city: 'Upper Rouchel',
      state: 'NSW',
    },
    2337: {
      lat: 151.080412,
      lng: -31.966754,
      city: 'Woolooma',
      state: 'NSW',
    },
    2338: {
      lat: 151.029159,
      lng: -31.735174,
      city: 'Timor',
      state: 'NSW',
    },
    2339: {
      lat: 150.603274,
      lng: -31.735738,
      city: 'Willow Tree',
      state: 'NSW',
    },
    2340: {
      lat: 151.138322,
      lng: -31.333592,
      city: 'Woolomin',
      state: 'NSW',
    },
    2341: {
      lat: 150.611053,
      lng: -31.34664,
      city: 'Werris Creek',
      state: 'NSW',
    },
    2342: {
      lat: 150.54907,
      lng: -31.155223,
      city: 'Piallaway',
      state: 'NSW',
    },
    2343: {
      lat: 150.1491418,
      lng: -31.66545698,
      city: 'Yarraman',
      state: 'NSW',
    },
    2344: {
      lat: 150.729574,
      lng: -31.17375,
      city: 'Winton',
      state: 'NSW',
    },
    2345: {
      lat: 150.875031,
      lng: -30.864342,
      city: 'Garthowen',
      state: 'NSW',
    },
    2346: {
      lat: 150.518242,
      lng: -30.72850361,
      city: 'Wongo Creek',
      state: 'NSW',
    },
    2347: {
      lat: 150.523163,
      lng: -30.281899,
      city: 'Woodsreef',
      state: 'NSW',
    },
    2348: {
      lat: 146.921,
      lng: -31.2532,
      city: 'New England Mc',
      state: 'NSW',
    },
    2350: {
      lat: 151.906153,
      lng: -30.493397,
      city: 'Wongwibinda',
      state: 'NSW',
    },
    2351: {
      lat: 151.639627,
      lng: -30.489129,
      city: 'University Of New England',
      state: 'NSW',
    },
    2352: {
      lat: 151.246189,
      lng: -31.088119,
      city: 'Tintinhull',
      state: 'NSW',
    },
    2353: {
      lat: 151.095609,
      lng: -30.973619,
      city: 'Moonbi',
      state: 'NSW',
    },
    2354: {
      lat: 151.762627,
      lng: -31.145898,
      city: 'Yarrowitch',
      state: 'NSW',
    },
    2355: {
      lat: 151.154222,
      lng: -30.775229,
      city: 'Watsons Creek',
      state: 'NSW',
    },
    2356: {
      lat: 148.96949,
      lng: -30.619798,
      city: 'Gwabegar',
      state: 'NSW',
    },
    2357: {
      lat: 149.263874,
      lng: -31.193339,
      city: 'Yearinan',
      state: 'NSW',
    },
    2358: {
      lat: 151.297519,
      lng: -30.540297,
      city: 'Yarrowyck',
      state: 'NSW',
    },
    2359: {
      lat: 151.001122,
      lng: -30.140505,
      city: 'Camerons Creek',
      state: 'NSW',
    },
    2360: {
      lat: 151.314883,
      lng: -29.74182058,
      city: 'Woodstock',
      state: 'NSW',
    },
    2361: {
      lat: 151.141751,
      lng: -29.198493,
      city: 'Pindaroi',
      state: 'NSW',
    },
    2365: {
      lat: 151.673028,
      lng: -30.115913,
      city: 'Wandsworth',
      state: 'NSW',
    },
    2369: {
      lat: 151.265077,
      lng: -29.985367,
      city: 'Tingha',
      state: 'NSW',
    },
    2370: {
      lat: 151.869397,
      lng: -29.736171,
      city: 'Yarrowford',
      state: 'NSW',
    },
    2371: {
      lat: 151.814675,
      lng: -29.36625,
      city: 'Yellow Dam',
      state: 'NSW',
    },
    2372: {
      lat: 151.999661,
      lng: -29.083319,
      city: 'Wylie Creek',
      state: 'NSW',
    },
    2379: {
      lat: 149.7614891,
      lng: -31.18757986,
      city: 'Nombi',
      state: 'NSW',
    },
    2380: {
      lat: 150.199154,
      lng: -31.046117,
      city: 'Rangari',
      state: 'NSW',
    },
    2381: {
      lat: 150.457901,
      lng: -31.244175,
      city: 'Tambar Springs',
      state: 'NSW',
    },
    2382: {
      lat: 150.17806,
      lng: -30.595044,
      city: 'Willala',
      state: 'NSW',
    },
    2386: {
      lat: 149.1501936,
      lng: -30.01165066,
      city: 'Nowley',
      state: 'NSW',
    },
    2387: {
      lat: 148.837106,
      lng: -29.757915,
      city: 'Rowena',
      state: 'NSW',
    },
    2388: {
      lat: 149.001543,
      lng: -30.427329,
      city: 'Yarrie Lake',
      state: 'NSW',
    },
    2390: {
      lat: 149.767538,
      lng: -30.413331,
      city: 'Turrawan',
      state: 'NSW',
    },
    2395: {
      lat: 149.440922,
      lng: -31.584172,
      city: 'Weetaliba',
      state: 'NSW',
    },
    2396: {
      lat: 148.984063,
      lng: -30.777381,
      city: 'Kenebri',
      state: 'NSW',
    },
    2397: {
      lat: 149.690988,
      lng: -29.925739,
      city: 'Thalaba',
      state: 'NSW',
    },
    2398: {
      lat: 149.795738,
      lng: -29.741061,
      city: 'Gurley',
      state: 'NSW',
    },
    2399: {
      lat: 150.222662,
      lng: -29.409325,
      city: 'Pallamallawa',
      state: 'NSW',
    },
    2400: {
      lat: 149.462148,
      lng: -29.474921,
      city: 'Yarraman',
      state: 'NSW',
    },
    2401: {
      lat: 150.331619,
      lng: -29.611901,
      city: 'Gravesend',
      state: 'NSW',
    },
    2402: {
      lat: 150.547545,
      lng: -29.441821,
      city: 'Warialda Rail',
      state: 'NSW',
    },
    2403: {
      lat: 150.787333,
      lng: -29.620234,
      city: 'Myall Creek',
      state: 'NSW',
    },
    2404: {
      lat: 150.7354843,
      lng: -29.89153536,
      city: 'Whitlow',
      state: 'NSW',
    },
    2405: {
      lat: 149.647249,
      lng: -28.894651,
      city: 'Garah',
      state: 'NSW',
    },
    2406: {
      lat: 148.847796,
      lng: -28.893724,
      city: 'Weemelah',
      state: 'NSW',
    },
    2408: {
      lat: 150.5199163,
      lng: -29.11854168,
      city: 'Yallaroi',
      state: 'NSW',
    },
    2409: {
      lat: 150.5515363,
      lng: -28.76070035,
      city: 'Boonal',
      state: 'NSW',
    },
    2410: {
      lat: 150.742604,
      lng: -28.901048,
      city: 'Yetman',
      state: 'NSW',
    },
    2411: {
      lat: 150.307886,
      lng: -29.131193,
      city: 'Croppa Creek',
      state: 'NSW',
    },
    2415: {
      lat: 151.888327,
      lng: -32.306267,
      city: 'Weismantels',
      state: 'NSW',
    },
    2420: {
      lat: 151.7352991,
      lng: -32.45415227,
      city: 'Wirragulla',
      state: 'NSW',
    },
    2421: {
      lat: 151.5127294,
      lng: -32.58240999,
      city: 'Webbers Creek',
      state: 'NSW',
    },
    2422: {
      lat: 151.865806,
      lng: -31.973082,
      city: 'Woko',
      state: 'NSW',
    },
    2423: {
      lat: 152.4779446,
      lng: -32.4396064,
      city: 'Yagon',
      state: 'NSW',
    },
    2424: {
      lat: 151.964845,
      lng: -31.6402,
      city: 'Woodside',
      state: 'NSW',
    },
    2425: {
      lat: 152.002451,
      lng: -32.417598,
      city: 'Washpool',
      state: 'NSW',
    },
    2426: {
      lat: 152.595335,
      lng: -31.817926,
      city: 'Moto',
      state: 'NSW',
    },
    2427: {
      lat: 152.686583,
      lng: -31.842318,
      city: 'Harrington',
      state: 'NSW',
    },
    2428: {
      lat: 152.454216,
      lng: -32.196739,
      city: 'Whoota',
      state: 'NSW',
    },
    2429: {
      lat: 152.292395,
      lng: -31.770375,
      city: 'Yarratt Forest',
      state: 'NSW',
    },
    2430: {
      lat: 152.503209,
      lng: -31.899311,
      city: 'Wallabi Point',
      state: 'NSW',
    },
    2431: {
      lat: 153.053789,
      lng: -30.925233,
      city: 'South West Rocks',
      state: 'NSW',
    },
    2439: {
      lat: 152.652155,
      lng: -31.654722,
      city: 'Upsalls Creek',
      state: 'NSW',
    },
    2440: {
      lat: 153.019648,
      lng: -30.375791,
      city: 'Yessabah',
      state: 'NSW',
    },
    2441: {
      lat: 152.831369,
      lng: -30.816097,
      city: 'Yarrahapinni',
      state: 'NSW',
    },
    2442: {
      lat: 152.917,
      lng: -31.4352,
      city: 'Mid North Coast Msc',
      state: 'NSW',
    },
    2443: {
      lat: 152.747152,
      lng: -31.725436,
      city: 'West Haven',
      state: 'NSW',
    },
    2444: {
      lat: 152.87253,
      lng: -31.439614,
      city: 'Thrumster',
      state: 'NSW',
    },
    2445: {
      lat: 152.820899,
      lng: -31.583037,
      city: 'Lake Cathie',
      state: 'NSW',
    },
    2446: {
      lat: 152.466916,
      lng: -31.387394,
      city: 'Yippin Creek',
      state: 'NSW',
    },
    2447: {
      lat: 152.751629,
      lng: -30.77067,
      city: 'Yarranbella',
      state: 'NSW',
    },
    2448: {
      lat: 152.956681,
      lng: -30.604667,
      city: 'Viewmont',
      state: 'NSW',
    },
    2449: {
      lat: 152.737629,
      lng: -30.62816,
      city: 'Tewinga',
      state: 'NSW',
    },
    2450: {
      lat: 152.658268,
      lng: -30.097726,
      city: 'Upper Orara',
      state: 'NSW',
    },
    2452: {
      lat: 153.08188,
      lng: -30.357168,
      city: 'Toormina',
      state: 'NSW',
    },
    2453: {
      lat: 152.7468709,
      lng: -30.14637427,
      city: 'Wild Cattle Creek',
      state: 'NSW',
    },
    2454: {
      lat: 152.840266,
      lng: -30.459807,
      city: 'Valery',
      state: 'NSW',
    },
    2455: {
      lat: 152.96348,
      lng: -30.529366,
      city: 'Urunga',
      state: 'NSW',
    },
    2456: {
      lat: 153.143409,
      lng: -30.073663,
      city: 'Woolgoolga',
      state: 'NSW',
    },
    2460: {
      lat: 153.271074,
      lng: -29.320772,
      city: 'Wombat Creek',
      state: 'NSW',
    },
    2462: {
      lat: 153.187047,
      lng: -29.829194,
      city: 'Wooli',
      state: 'NSW',
    },
    2463: {
      lat: 153.1344664,
      lng: -29.5139517,
      city: 'Woodford Island',
      state: 'NSW',
    },
    2464: {
      lat: 153.3317549,
      lng: -29.53085664,
      city: 'Yuraygir',
      state: 'NSW',
    },
    2465: {
      lat: 153.243897,
      lng: -29.412622,
      city: 'Harwood',
      state: 'NSW',
    },
    2466: {
      lat: 153.350886,
      lng: -29.407475,
      city: 'Woody Head',
      state: 'NSW',
    },
    2469: {
      lat: 152.5044011,
      lng: -28.62860939,
      city: 'Yabbra',
      state: 'NSW',
    },
    2470: {
      lat: 152.749759,
      lng: -28.92786,
      city: 'Yorklea',
      state: 'NSW',
    },
    2471: {
      lat: 153.2086295,
      lng: -29.00263987,
      city: 'West Coraki',
      state: 'NSW',
    },
    2472: {
      lat: 153.303855,
      lng: -29.156771,
      city: 'Woodburn',
      state: 'NSW',
    },
    2473: {
      lat: 153.436052,
      lng: -29.120479,
      city: 'South Evans Head',
      state: 'NSW',
    },
    2474: {
      lat: 152.88504,
      lng: -28.499375,
      city: 'Wyneden',
      state: 'NSW',
    },
    2475: {
      lat: 152.388341,
      lng: -28.546257,
      city: 'Wallaby Creek',
      state: 'NSW',
    },
    2476: {
      lat: 152.49765,
      lng: -28.36456,
      city: 'Woodenbong',
      state: 'NSW',
    },
    2477: {
      lat: 153.427314,
      lng: -28.907675,
      city: 'Wollongbar',
      state: 'NSW',
    },
    2478: {
      lat: 153.532475,
      lng: -28.865483,
      city: 'West Ballina',
      state: 'NSW',
    },
    2479: {
      lat: 153.514963,
      lng: -28.709545,
      city: 'St Helena',
      state: 'NSW',
    },
    2480: {
      lat: 153.25943,
      lng: -28.706486,
      city: 'Wyrallah',
      state: 'NSW',
    },
    2481: {
      lat: 153.592843,
      lng: -28.660756,
      city: 'Tyagarah',
      state: 'NSW',
    },
    2482: {
      lat: 153.432398,
      lng: -28.547307,
      city: 'Wilsons Creek',
      state: 'NSW',
    },
    2483: {
      lat: 153.494236,
      lng: -28.461128,
      city: 'Yelgun',
      state: 'NSW',
    },
    2484: {
      lat: 153.31648,
      lng: -28.38059,
      city: 'Zara',
      state: 'NSW',
    },
    2485: {
      lat: 153.526816,
      lng: -28.184241,
      city: 'Tweed Heads West',
      state: 'NSW',
    },
    2486: {
      lat: 153.479217,
      lng: -28.215215,
      city: 'Upper Duroby',
      state: 'NSW',
    },
    2487: {
      lat: 153.548588,
      lng: -28.27973,
      city: 'Stotts Creek',
      state: 'NSW',
    },
    2488: {
      lat: 153.560693,
      lng: -28.34099,
      city: 'Tanglewood',
      state: 'NSW',
    },
    2489: {
      lat: 153.56855,
      lng: -28.379066,
      city: 'Pottsville Beach',
      state: 'NSW',
    },
    2490: {
      lat: 153.472644,
      lng: -28.271718,
      city: 'Tumbulgum',
      state: 'NSW',
    },
    2500: {
      lat: 150.874294,
      lng: -34.423171,
      city: 'Wollongong West',
      state: 'NSW',
    },
    2502: {
      lat: 150.873081,
      lng: -34.494723,
      city: 'Warrawong',
      state: 'NSW',
    },
    2505: {
      lat: 150.943899,
      lng: -34.466406,
      city: 'Port Kembla',
      state: 'NSW',
    },
    2506: {
      lat: 150.840792,
      lng: -34.48494,
      city: 'Berkeley',
      state: 'NSW',
    },
    2508: {
      lat: 150.9354342,
      lng: -34.16316178,
      city: 'Woronora Dam',
      state: 'NSW',
    },
    2515: {
      lat: 150.9299,
      lng: -34.300293,
      city: 'Wombarra',
      state: 'NSW',
    },
    2516: {
      lat: 150.904835,
      lng: -34.332109,
      city: 'Bulli Tops',
      state: 'NSW',
    },
    2517: {
      lat: 150.891169,
      lng: -34.349362,
      city: 'Woonona East',
      state: 'NSW',
    },
    2518: {
      lat: 150.886721,
      lng: -34.373654,
      city: 'Towradgi',
      state: 'NSW',
    },
    2519: {
      lat: 150.709623,
      lng: -34.35558,
      city: 'Reidtown',
      state: 'NSW',
    },
    2520: {
      lat: 151.139594,
      lng: -33.937789,
      city: 'Wollongong',
      state: 'NSW',
    },
    2522: {
      lat: 150.877805,
      lng: -34.405103,
      city: 'University Of Wollongong',
      state: 'NSW',
    },
    2525: {
      lat: 150.818904,
      lng: -34.437229,
      city: 'Figtree',
      state: 'NSW',
    },
    2526: {
      lat: 150.77681,
      lng: -34.380765,
      city: 'Unanderra Dc',
      state: 'NSW',
    },
    2527: {
      lat: 150.754592,
      lng: -34.583539,
      city: 'Yellow Rock',
      state: 'NSW',
    },
    2528: {
      lat: 150.855135,
      lng: -34.548722,
      city: 'Windang',
      state: 'NSW',
    },
    2529: {
      lat: 150.84584,
      lng: -34.592433,
      city: 'Shellharbour Square',
      state: 'NSW',
    },
    2530: {
      lat: 150.766132,
      lng: -34.505411,
      city: 'Yallah',
      state: 'NSW',
    },
    2533: {
      lat: 150.756883,
      lng: -34.645377,
      city: 'Saddleback Mountain',
      state: 'NSW',
    },
    2534: {
      lat: 150.800637,
      lng: -34.739425,
      city: 'Willow Vale',
      state: 'NSW',
    },
    2535: {
      lat: 150.678303,
      lng: -34.762116,
      city: 'Woodhill',
      state: 'NSW',
    },
    2536: {
      lat: 150.125945,
      lng: -35.671272,
      city: 'Woodlands',
      state: 'NSW',
    },
    2537: {
      lat: 149.912199,
      lng: -35.939649,
      city: 'Wamban',
      state: 'NSW',
    },
    2538: {
      lat: 150.320507,
      lng: -35.366161,
      city: 'Woodstock',
      state: 'NSW',
    },
    2539: {
      lat: 150.393202,
      lng: -35.522387,
      city: 'Yatte Yattah',
      state: 'NSW',
    },
    2540: {
      lat: 150.61644,
      lng: -34.863483,
      city: 'Yerriyong',
      state: 'NSW',
    },
    2541: {
      lat: 150.450994,
      lng: -34.990592,
      city: 'West Nowra',
      state: 'NSW',
    },
    2545: {
      lat: 149.792292,
      lng: -36.127663,
      city: 'Stony Creek',
      state: 'NSW',
    },
    2546: {
      lat: 150.225372,
      lng: -36.25486,
      city: 'Wallaga Lake Heights',
      state: 'NSW',
    },
    2548: {
      lat: 149.894825,
      lng: -36.860723,
      city: 'Yellow Pinch',
      state: 'NSW',
    },
    2549: {
      lat: 149.851694,
      lng: -36.953196,
      city: 'South Pambula',
      state: 'NSW',
    },
    2550: {
      lat: 149.699222,
      lng: -36.737758,
      city: 'Yowrie',
      state: 'NSW',
    },
    2551: {
      lat: 149.792364,
      lng: -37.267687,
      city: 'Wonboyn North',
      state: 'NSW',
    },
    2555: {
      lat: 150.741351,
      lng: -33.883376,
      city: 'Badgerys Creek',
      state: 'NSW',
    },
    2556: {
      lat: 150.725207,
      lng: -33.945707,
      city: 'Bringelly',
      state: 'NSW',
    },
    2557: {
      lat: 150.774858,
      lng: -33.993545,
      city: 'Rossmore',
      state: 'NSW',
    },
    2558: {
      lat: 150.799483,
      lng: -34.031602,
      city: 'Kearns',
      state: 'NSW',
    },
    2559: {
      lat: 150.793614,
      lng: -34.051274,
      city: 'Claymore',
      state: 'NSW',
    },
    2560: {
      lat: 150.768408,
      lng: -34.194216,
      city: 'Woodbine',
      state: 'NSW',
    },
    2563: {
      lat: 150.749847,
      lng: -34.102735,
      city: 'Menangle Park',
      state: 'NSW',
    },
    2564: {
      lat: 150.894916,
      lng: -33.998362,
      city: 'Macquarie Fields',
      state: 'NSW',
    },
    2565: {
      lat: 150.843761,
      lng: -33.996063,
      city: 'Macquarie Links',
      state: 'NSW',
    },
    2566: {
      lat: 150.842258,
      lng: -34.027283,
      city: 'Varroville',
      state: 'NSW',
    },
    2567: {
      lat: 150.756493,
      lng: -34.055155,
      city: 'Smeaton Grange',
      state: 'NSW',
    },
    2568: {
      lat: 150.718007,
      lng: -34.147379,
      city: 'Menangle',
      state: 'NSW',
    },
    2569: {
      lat: 150.708875,
      lng: -34.196004,
      city: 'Douglas Park',
      state: 'NSW',
    },
    2570: {
      lat: 150.525292,
      lng: -34.042913,
      city: 'Werombi',
      state: 'NSW',
    },
    2571: {
      lat: 150.622493,
      lng: -34.16715,
      city: 'Wilton',
      state: 'NSW',
    },
    2572: {
      lat: 150.559186,
      lng: -34.193052,
      city: 'Thirlmere',
      state: 'NSW',
    },
    2573: {
      lat: 150.584634,
      lng: -34.218439,
      city: 'Tahmoor',
      state: 'NSW',
    },
    2574: {
      lat: 150.575238,
      lng: -34.295634,
      city: 'Yanderra',
      state: 'NSW',
    },
    2575: {
      lat: 150.342214,
      lng: -34.347195,
      city: 'Yerrinbool',
      state: 'NSW',
    },
    2576: {
      lat: 150.503502,
      lng: -34.525612,
      city: 'Kangaloon',
      state: 'NSW',
    },
    2577: {
      lat: 150.410978,
      lng: -34.595027,
      city: 'Yarrunga',
      state: 'NSW',
    },
    2578: {
      lat: 150.293226,
      lng: -34.715579,
      city: 'Bundanoon',
      state: 'NSW',
    },
    2579: {
      lat: 150.13033,
      lng: -34.711848,
      city: 'Wingello',
      state: 'NSW',
    },
    2580: {
      lat: 149.729215,
      lng: -34.726283,
      city: 'Yarra',
      state: 'NSW',
    },
    2581: {
      lat: 149.286631,
      lng: -34.764484,
      city: 'Wollogorang',
      state: 'NSW',
    },
    2582: {
      lat: 149.125192,
      lng: -34.92659793,
      city: 'Yass River',
      state: 'NSW',
    },
    2583: {
      lat: 149.254163,
      lng: -34.196526,
      city: 'Wheeo',
      state: 'NSW',
    },
    2584: {
      lat: 148.555243,
      lng: -34.711018,
      city: 'Illalong Creek',
      state: 'NSW',
    },
    2585: {
      lat: 148.498501,
      lng: -34.572329,
      city: 'Galong',
      state: 'NSW',
    },
    2586: {
      lat: 148.797264,
      lng: -34.378631,
      city: 'Taylors Flat',
      state: 'NSW',
    },
    2587: {
      lat: 148.360971,
      lng: -34.505797,
      city: 'Wombat',
      state: 'NSW',
    },
    2588: {
      lat: 148.255601,
      lng: -34.599488,
      city: 'Wallendbeen',
      state: 'NSW',
    },
    2590: {
      lat: 147.896911,
      lng: -34.714515,
      city: 'Illabo',
      state: 'NSW',
    },
    2594: {
      lat: 148.10732,
      lng: -34.231083,
      city: 'Young',
      state: 'NSW',
    },
    2600: {
      lat: 149.201072,
      lng: -35.302911,
      city: 'Yarralumla',
      state: 'ACT',
    },
    2601: {
      lat: 149.113796,
      lng: -35.275863,
      city: 'City',
      state: 'ACT',
    },
    2602: {
      lat: 149.148147,
      lng: -35.248071,
      city: 'Watson',
      state: 'ACT',
    },
    2603: {
      lat: 149.125739,
      lng: -35.330382,
      city: 'Red Hill',
      state: 'ACT',
    },
    2604: {
      lat: 149.147614,
      lng: -35.336413,
      city: 'Narrabundah',
      state: 'ACT',
    },
    2605: {
      lat: 149.093455,
      lng: -35.335821,
      city: 'Hughes',
      state: 'ACT',
    },
    2606: {
      lat: 149.092601,
      lng: -35.351693,
      city: 'Woden',
      state: 'ACT',
    },
    2607: {
      lat: 149.094831,
      lng: -35.369991,
      city: 'Torrens',
      state: 'ACT',
    },
    2608: {
      lat: 149.129372,
      lng: -35.282868,
      city: 'Civic Square',
      state: 'ACT',
    },
    2609: {
      lat: 149.170023,
      lng: -35.31537,
      city: 'Symonston',
      state: 'ACT',
    },
    2610: {
      lat: 149.13,
      lng: -35.2809,
      city: 'Canberra Mc',
      state: 'ACT',
    },
    2611: {
      lat: 149.0335953,
      lng: -35.31927827,
      city: 'Wright',
      state: 'ACT',
    },
    2612: {
      lat: 149.133208,
      lng: -35.270615,
      city: 'Turner',
      state: 'ACT',
    },
    2614: {
      lat: 149.049796,
      lng: -35.257315,
      city: 'Weetangera',
      state: 'ACT',
    },
    2615: {
      lat: 148.986288,
      lng: -35.23269278,
      city: 'Strathnairn',
      state: 'ACT',
    },
    2616: {
      lat: 149.070336,
      lng: -35.248442,
      city: 'Belconnen',
      state: 'ACT',
    },
    2617: {
      lat: 149.086682,
      lng: -35.231279,
      city: 'University Of Canberra',
      state: 'ACT',
    },
    2618: {
      lat: 149.043278,
      lng: -35.135588,
      city: 'Wallaroo',
      state: 'NSW',
    },
    2619: {
      lat: 149.194535,
      lng: -35.378159,
      city: 'Jerrabomberra',
      state: 'NSW',
    },
    2620: {
      lat: 149.257101,
      lng: -35.317402,
      city: 'Yarrow',
      state: 'NSW',
    },
    2621: {
      lat: 149.484038,
      lng: -35.4698,
      city: 'Rossi',
      state: 'NSW',
    },
    2622: {
      lat: 149.752107,
      lng: -35.628428,
      city: 'Wyanbene',
      state: 'NSW',
    },
    2623: {
      lat: 149.445083,
      lng: -35.552827,
      city: 'Captains Flat',
      state: 'NSW',
    },
    2624: {
      lat: 148.441281,
      lng: -36.180818,
      city: 'Wilsons Valley',
      state: 'NSW',
    },
    2625: {
      lat: 148.301005,
      lng: -36.50661,
      city: 'Thredbo Village',
      state: 'NSW',
    },
    2626: {
      lat: 149.168553,
      lng: -35.941228,
      city: 'Colinton',
      state: 'NSW',
    },
    2627: {
      lat: 148.232341,
      lng: -36.409113,
      city: 'Sawpit Creek',
      state: 'NSW',
    },
    2628: {
      lat: 148.833511,
      lng: -36.404324,
      city: 'Snowy Plain',
      state: 'NSW',
    },
    2629: {
      lat: 148.769744,
      lng: -35.997349,
      city: 'Yaouk',
      state: 'NSW',
    },
    2630: {
      lat: 149.129166,
      lng: -36.177296,
      city: 'Wambrook',
      state: 'NSW',
    },
    2631: {
      lat: 149.306729,
      lng: -36.529386,
      city: 'Winifred',
      state: 'NSW',
    },
    2632: {
      lat: 149.220453,
      lng: -37.020122,
      city: 'Rosemeath',
      state: 'NSW',
    },
    2633: {
      lat: 148.701564,
      lng: -36.865206,
      city: 'Tombong',
      state: 'NSW',
    },
    2640: {
      lat: 147.026549,
      lng: -36.016196,
      city: 'Wymah',
      state: 'NSW',
    },
    2641: {
      lat: 146.930436,
      lng: -36.038655,
      city: 'Springdale Heights',
      state: 'NSW',
    },
    2642: {
      lat: 148.094043,
      lng: -36.044097,
      city: 'Yerong Creek',
      state: 'NSW',
    },
    2643: {
      lat: 146.617535,
      lng: -35.936556,
      city: 'Howlong',
      state: 'NSW',
    },
    2644: {
      lat: 147.6488785,
      lng: -35.72015098,
      city: 'Yarara',
      state: 'NSW',
    },
    2645: {
      lat: 146.332431,
      lng: -35.333843,
      city: 'Yuluma',
      state: 'NSW',
    },
    2646: {
      lat: 146.324523,
      lng: -35.757569,
      city: 'Savernake',
      state: 'NSW',
    },
    2647: {
      lat: 145.976131,
      lng: -35.905463,
      city: 'Mulwala',
      state: 'NSW',
    },
    2648: {
      lat: 141.90794,
      lng: -33.466819,
      city: 'Wentworth',
      state: 'NSW',
    },
    2649: {
      lat: 148.091211,
      lng: -35.608279,
      city: 'Nurenmerenmong',
      state: 'NSW',
    },
    2650: {
      lat: 147.376202,
      lng: -35.07459,
      city: 'Yathella',
      state: 'NSW',
    },
    2651: {
      lat: 147.463477,
      lng: -35.162259,
      city: 'Wagga Wagga Raaf',
      state: 'NSW',
    },
    2652: {
      lat: 147.64961,
      lng: -35.405064,
      city: 'Uranquinty',
      state: 'NSW',
    },
    2653: {
      lat: 148.041339,
      lng: -35.776209,
      city: 'Willigobung',
      state: 'NSW',
    },
    2655: {
      lat: 147.047366,
      lng: -35.225102,
      city: 'Tootool',
      state: 'NSW',
    },
    2656: {
      lat: 146.767674,
      lng: -35.356021,
      city: 'Urangeline East',
      state: 'NSW',
    },
    2658: {
      lat: 146.977849,
      lng: -35.535739,
      city: 'Ryan',
      state: 'NSW',
    },
    2659: {
      lat: 146.767477,
      lng: -35.610147,
      city: 'Walla Walla',
      state: 'NSW',
    },
    2660: {
      lat: 147.07831,
      lng: -35.699906,
      city: 'Morven',
      state: 'NSW',
    },
    2661: {
      lat: 147.277559,
      lng: -35.157697,
      city: 'Kapooka',
      state: 'NSW',
    },
    2663: {
      lat: 147.716767,
      lng: -34.933478,
      city: 'Wantiool',
      state: 'NSW',
    },
    2665: {
      lat: 146.841533,
      lng: -34.313241,
      city: 'Walleroobie',
      state: 'NSW',
    },
    2666: {
      lat: 147.583811,
      lng: -34.487779,
      city: 'Trungley Hall',
      state: 'NSW',
    },
    2668: {
      lat: 147.405434,
      lng: -34.121499,
      city: 'Barmedman',
      state: 'NSW',
    },
    2669: {
      lat: 146.667476,
      lng: -33.814887,
      city: 'Yaddra',
      state: 'NSW',
    },
    2671: {
      lat: 147.308172,
      lng: -33.839275,
      city: 'Wyalong',
      state: 'NSW',
    },
    2672: {
      lat: 146.40002,
      lng: -33.24358,
      city: 'Wargambegal',
      state: 'NSW',
    },
    2675: {
      lat: 145.479249,
      lng: -33.459192,
      city: 'White Top',
      state: 'NSW',
    },
    2678: {
      lat: 147.351953,
      lng: -35.059334,
      city: 'Riverina Msc',
      state: 'NSW',
    },
    2680: {
      lat: 145.914625,
      lng: -34.321422,
      city: 'Yoogali',
      state: 'NSW',
    },
    2681: {
      lat: 146.14674,
      lng: -34.116715,
      city: 'Yenda',
      state: 'NSW',
    },
    2700: {
      lat: 146.303599,
      lng: -34.902108,
      city: 'Widgiewa',
      state: 'NSW',
    },
    2701: {
      lat: 147.193088,
      lng: -34.804991,
      city: 'Tooyal',
      state: 'NSW',
    },
    2702: {
      lat: 147.014242,
      lng: -34.86668,
      city: 'Ganmain',
      state: 'NSW',
    },
    2703: {
      lat: 146.370827,
      lng: -34.607943,
      city: 'Yanco',
      state: 'NSW',
    },
    2705: {
      lat: 146.373471,
      lng: -34.489867,
      city: 'Whitton',
      state: 'NSW',
    },
    2706: {
      lat: 145.937532,
      lng: -34.569464,
      city: 'Darlington Point',
      state: 'NSW',
    },
    2707: {
      lat: 145.807866,
      lng: -34.768863,
      city: 'Coleambally',
      state: 'NSW',
    },
    2708: {
      lat: 143.744,
      lng: -35.3861,
      city: 'Murray Region Mc',
      state: 'NSW',
    },
    2710: {
      lat: 144.984378,
      lng: -35.357619,
      city: 'Yallakool',
      state: 'NSW',
    },
    2711: {
      lat: 143.7165416,
      lng: -34.73951684,
      city: 'Yanga',
      state: 'NSW',
    },
    2712: {
      lat: 145.872814,
      lng: -35.754185,
      city: 'Boomanoomana',
      state: 'NSW',
    },
    2713: {
      lat: 145.52068,
      lng: -35.586342,
      city: 'Retreat',
      state: 'NSW',
    },
    2714: {
      lat: 145.434084,
      lng: -35.76294,
      city: 'Tuppal',
      state: 'NSW',
    },
    2715: {
      lat: 143.491656,
      lng: -34.329424,
      city: 'The Vale',
      state: 'NSW',
    },
    2716: {
      lat: 145.7454551,
      lng: -35.48197899,
      city: 'Mairjimmy',
      state: 'NSW',
    },
    2717: {
      lat: 142.065808,
      lng: -34.090426,
      city: 'Dareton',
      state: 'NSW',
    },
    2720: {
      lat: 148.383619,
      lng: -35.379935,
      city: 'Yarrangobilly',
      state: 'NSW',
    },
    2721: {
      lat: 147.712064,
      lng: -34.04948,
      city: 'Quandialla',
      state: 'NSW',
    },
    2722: {
      lat: 148.102866,
      lng: -35.053523,
      city: 'Willie Ploma',
      state: 'NSW',
    },
    2725: {
      lat: 147.815806,
      lng: -34.44817,
      city: 'Stockinbingal',
      state: 'NSW',
    },
    2726: {
      lat: 148.326675,
      lng: -34.78436,
      city: 'Jugiong',
      state: 'NSW',
    },
    2727: {
      lat: 148.390372,
      lng: -34.983539,
      city: 'Mingay',
      state: 'NSW',
    },
    2729: {
      lat: 147.923797,
      lng: -35.227753,
      city: 'Yaven Creek',
      state: 'NSW',
    },
    2730: {
      lat: 148.034012,
      lng: -35.510039,
      city: 'Lower Bago',
      state: 'NSW',
    },
    2731: {
      lat: 144.641831,
      lng: -35.893394,
      city: 'Womboota',
      state: 'NSW',
    },
    2732: {
      lat: 144.230168,
      lng: -35.542622,
      city: 'Tullakool',
      state: 'NSW',
    },
    2733: {
      lat: 144.206139,
      lng: -35.013711,
      city: 'Perekerten',
      state: 'NSW',
    },
    2734: {
      lat: 143.387009,
      lng: -34.770931,
      city: 'Wetuppa',
      state: 'NSW',
    },
    2735: {
      lat: 143.544483,
      lng: -35.17512903,
      city: 'Speewa',
      state: 'NSW',
    },
    2736: {
      lat: 143.375822,
      lng: -34.965178,
      city: 'Tooleybuc',
      state: 'NSW',
    },
    2737: {
      lat: 142.87381,
      lng: -34.574113,
      city: 'Euston',
      state: 'NSW',
    },
    2738: {
      lat: 142.225122,
      lng: -34.164709,
      city: 'Trentham Cliffs',
      state: 'NSW',
    },
    2739: {
      lat: 142.1657571,
      lng: -34.13844435,
      city: 'Mourquong',
      state: 'NSW',
    },
    2745: {
      lat: 150.671487,
      lng: -33.871341,
      city: 'Wallacia',
      state: 'NSW',
    },
    2747: {
      lat: 150.739398,
      lng: -33.722323,
      city: 'Werrington Downs',
      state: 'NSW',
    },
    2748: {
      lat: 150.72319,
      lng: -33.811042,
      city: 'Orchard Hills',
      state: 'NSW',
    },
    2749: {
      lat: 150.686816,
      lng: -33.702167,
      city: 'Cranebrook',
      state: 'NSW',
    },
    2750: {
      lat: 150.678551,
      lng: -33.755476,
      city: 'South Penrith',
      state: 'NSW',
    },
    2751: {
      lat: 151.280352,
      lng: -33.732127,
      city: 'Penrith',
      state: 'NSW',
    },
    2752: {
      lat: 150.585995,
      lng: -33.936534,
      city: 'Warragamba',
      state: 'NSW',
    },
    2753: {
      lat: 150.646053,
      lng: -33.604022,
      city: 'Yarramundi',
      state: 'NSW',
    },
    2754: {
      lat: 150.72802,
      lng: -33.557893,
      city: 'The Slopes',
      state: 'NSW',
    },
    2756: {
      lat: 150.8001824,
      lng: -33.23594291,
      city: 'Womerah',
      state: 'NSW',
    },
    2757: {
      lat: 150.692086,
      lng: -33.554839,
      city: 'Kurmond',
      state: 'NSW',
    },
    2758: {
      lat: 150.601824,
      lng: -33.483614,
      city: 'Wheeny Creek',
      state: 'NSW',
    },
    2759: {
      lat: 150.781767,
      lng: -33.82252,
      city: 'St Clair',
      state: 'NSW',
    },
    2760: {
      lat: 150.780073,
      lng: -33.763976,
      city: 'St Marys South',
      state: 'NSW',
    },
    2761: {
      lat: 150.84746,
      lng: -33.742006,
      city: 'Plumpton',
      state: 'NSW',
    },
    2762: {
      lat: 150.888428,
      lng: -33.697217,
      city: 'Tallawong',
      state: 'NSW',
    },
    2763: {
      lat: 150.89547,
      lng: -33.725619,
      city: 'Quakers Hill',
      state: 'NSW',
    },
    2765: {
      lat: 150.860258,
      lng: -33.663002,
      city: 'Vineyard',
      state: 'NSW',
    },
    2766: {
      lat: 150.850764,
      lng: -33.810388,
      city: 'Rooty Hill',
      state: 'NSW',
    },
    2767: {
      lat: 150.871134,
      lng: -33.763843,
      city: 'Woodcroft',
      state: 'NSW',
    },
    2768: {
      lat: 150.922732,
      lng: -33.737863,
      city: 'Stanhope Gardens',
      state: 'NSW',
    },
    2769: {
      lat: 150.9054927,
      lng: -33.7054643,
      city: 'The Ponds',
      state: 'NSW',
    },
    2770: {
      lat: 150.810246,
      lng: -33.756443,
      city: 'Willmot',
      state: 'NSW',
    },
    2773: {
      lat: 150.626788,
      lng: -33.768837,
      city: 'Lapstone',
      state: 'NSW',
    },
    2774: {
      lat: 150.62902,
      lng: -33.732671,
      city: 'Warrimoo',
      state: 'NSW',
    },
    2775: {
      lat: 151.0387409,
      lng: -33.31145027,
      city: 'Wrights Creek',
      state: 'NSW',
    },
    2776: {
      lat: 150.553111,
      lng: -33.666855,
      city: 'Faulconbridge',
      state: 'NSW',
    },
    2777: {
      lat: 150.587962,
      lng: -33.692534,
      city: 'Yellow Rock',
      state: 'NSW',
    },
    2778: {
      lat: 150.484549,
      lng: -33.728545,
      city: 'Woodford',
      state: 'NSW',
    },
    2779: {
      lat: 150.45933,
      lng: -33.7237,
      city: 'Hazelbrook',
      state: 'NSW',
    },
    2780: {
      lat: 150.435639,
      lng: -33.832923,
      city: 'Yosemite',
      state: 'NSW',
    },
    2782: {
      lat: 150.366015,
      lng: -33.704416,
      city: 'Wentworth Falls',
      state: 'NSW',
    },
    2783: {
      lat: 150.431839,
      lng: -33.725566,
      city: 'Lawson',
      state: 'NSW',
    },
    2784: {
      lat: 150.407048,
      lng: -33.729916,
      city: 'Bullaburra',
      state: 'NSW',
    },
    2785: {
      lat: 150.181517,
      lng: -33.707725,
      city: 'Shipley',
      state: 'NSW',
    },
    2786: {
      lat: 150.317795,
      lng: -33.533055,
      city: 'Mount Wilson',
      state: 'NSW',
    },
    2787: {
      lat: 150.153221,
      lng: -34.06024,
      city: 'Yerranderie',
      state: 'NSW',
    },
    2790: {
      lat: 150.4413864,
      lng: -33.34286524,
      city: 'Wollangambe',
      state: 'NSW',
    },
    2791: {
      lat: 149.164475,
      lng: -33.634941,
      city: 'Errowanbang',
      state: 'NSW',
    },
    2792: {
      lat: 149.08632,
      lng: -33.626627,
      city: 'Mandurama',
      state: 'NSW',
    },
    2793: {
      lat: 148.90873,
      lng: -33.783955,
      city: 'Woodstock',
      state: 'NSW',
    },
    2794: {
      lat: 148.664838,
      lng: -33.898505,
      city: 'Westville',
      state: 'NSW',
    },
    2795: {
      lat: 149.474401,
      lng: -33.820797,
      city: 'Yetholme',
      state: 'NSW',
    },
    2797: {
      lat: 148.952525,
      lng: -33.605124,
      city: 'Lyndhurst',
      state: 'NSW',
    },
    2798: {
      lat: 149.158755,
      lng: -33.462193,
      city: 'Tallwood',
      state: 'NSW',
    },
    2799: {
      lat: 149.267666,
      lng: -33.571335,
      city: 'Vittoria',
      state: 'NSW',
    },
    2800: {
      lat: 149.0191144,
      lng: -33.20831682,
      city: 'Windera',
      state: 'NSW',
    },
    2803: {
      lat: 148.523704,
      lng: -34.176731,
      city: 'Wirrimah',
      state: 'NSW',
    },
    2804: {
      lat: 148.633991,
      lng: -33.539564,
      city: 'Nyrang Creek',
      state: 'NSW',
    },
    2805: {
      lat: 148.420031,
      lng: -33.64991,
      city: 'Gooloogong',
      state: 'NSW',
    },
    2806: {
      lat: 148.375577,
      lng: -33.375562,
      city: 'Trajere',
      state: 'NSW',
    },
    2807: {
      lat: 148.562165,
      lng: -34.036494,
      city: 'Koorawatha',
      state: 'NSW',
    },
    2808: {
      lat: 149.02317,
      lng: -33.976987,
      city: 'Wyangala',
      state: 'NSW',
    },
    2809: {
      lat: 148.432316,
      lng: -33.972859,
      city: 'Greenethorpe',
      state: 'NSW',
    },
    2810: {
      lat: 148.054607,
      lng: -33.902869,
      city: 'Warraderry',
      state: 'NSW',
    },
    2817: {
      lat: 148.9793,
      lng: -31.4941,
      city: 'Tooraweenah',
      state: 'NSW',
    },
    2818: {
      lat: 148.7315,
      lng: -32.4439,
      city: 'Terrabella',
      state: 'NSW',
    },
    2820: {
      lat: 148.989227,
      lng: -32.553282,
      city: 'Yarragal',
      state: 'NSW',
    },
    2821: {
      lat: 148.110385,
      lng: -32.255249,
      city: 'Wyanga',
      state: 'NSW',
    },
    2822: {
      lat: 148.6301,
      lng: -31.9849,
      city: 'Eumungerie',
      state: 'NSW',
    },
    2823: {
      lat: 147.838499,
      lng: -32.07687,
      city: 'Trangie',
      state: 'NSW',
    },
    2824: {
      lat: 147.785831,
      lng: -31.373201,
      city: 'Warren',
      state: 'NSW',
    },
    2825: {
      lat: 147.30573,
      lng: -31.934883,
      city: 'Warrigal',
      state: 'NSW',
    },
    2826: {
      lat: 147.6555,
      lng: -31.8559,
      city: 'Nevertire',
      state: 'NSW',
    },
    2827: {
      lat: 148.648353,
      lng: -31.63315,
      city: 'Merrigal',
      state: 'NSW',
    },
    2828: {
      lat: 148.581142,
      lng: -31.298962,
      city: 'Warrumbungle',
      state: 'NSW',
    },
    2829: {
      lat: 148.2838588,
      lng: -30.52197868,
      city: 'Wingadee',
      state: 'NSW',
    },
    2830: {
      lat: 148.657282,
      lng: -32.24224,
      city: 'Whylandra Crossing',
      state: 'NSW',
    },
    2831: {
      lat: 146.615174,
      lng: -31.927892,
      city: 'Wongarbon',
      state: 'NSW',
    },
    2832: {
      lat: 147.983033,
      lng: -29.958521,
      city: 'Walgett',
      state: 'NSW',
    },
    2833: {
      lat: 148.378523,
      lng: -29.391492,
      city: 'Collarenebri',
      state: 'NSW',
    },
    2834: {
      lat: 147.966635,
      lng: -29.445997,
      city: 'Lightning Ridge',
      state: 'NSW',
    },
    2835: {
      lat: 145.406579,
      lng: -31.551822,
      city: 'Tindarey',
      state: 'NSW',
    },
    2836: {
      lat: 143.270562,
      lng: -31.498123,
      city: 'Wilcannia',
      state: 'NSW',
    },
    2838: {
      lat: 147.404,
      lng: -29.3107,
      city: 'Goodooga',
      state: 'NSW',
    },
    2839: {
      lat: 146.94474,
      lng: -29.8313,
      city: 'Weilmoringle',
      state: 'NSW',
    },
    2840: {
      lat: 145.251371,
      lng: -30.205908,
      city: 'Yantabulla',
      state: 'NSW',
    },
    2842: {
      lat: 149.051954,
      lng: -31.690852,
      city: 'Yarragrin',
      state: 'NSW',
    },
    2843: {
      lat: 149.727798,
      lng: -31.82434,
      city: 'Coolah',
      state: 'NSW',
    },
    2844: {
      lat: 149.319295,
      lng: -31.924969,
      city: 'Leadville',
      state: 'NSW',
    },
    2845: {
      lat: 150.04201,
      lng: -33.404961,
      city: 'Wallerawang',
      state: 'NSW',
    },
    2846: {
      lat: 150.118609,
      lng: -33.071806,
      city: 'Round Swamp',
      state: 'NSW',
    },
    2847: {
      lat: 149.969799,
      lng: -33.404462,
      city: 'Portland',
      state: 'NSW',
    },
    2848: {
      lat: 149.964131,
      lng: -32.901412,
      city: 'Kandos',
      state: 'NSW',
    },
    2849: {
      lat: 150.059088,
      lng: -32.708935,
      city: 'Wirraba',
      state: 'NSW',
    },
    2850: {
      lat: 149.602925,
      lng: -32.685033,
      city: 'Yarrawonga',
      state: 'NSW',
    },
    2852: {
      lat: 149.45909,
      lng: -32.280785,
      city: 'Two Mile Flat',
      state: 'NSW',
    },
    2864: {
      lat: 148.731814,
      lng: -33.340269,
      city: 'Toogong',
      state: 'NSW',
    },
    2865: {
      lat: 148.684862,
      lng: -33.213883,
      city: 'Manildra',
      state: 'NSW',
    },
    2866: {
      lat: 148.904531,
      lng: -33.007264,
      city: 'Warnecliffe',
      state: 'NSW',
    },
    2867: {
      lat: 148.611239,
      lng: -32.965877,
      city: 'Yullundry',
      state: 'NSW',
    },
    2868: {
      lat: 148.561505,
      lng: -32.740876,
      city: 'Yeoval',
      state: 'NSW',
    },
    2869: {
      lat: 148.266655,
      lng: -32.584028,
      city: 'Trewilga',
      state: 'NSW',
    },
    2870: {
      lat: 148.16819,
      lng: -33.035496,
      city: 'Tichborne',
      state: 'NSW',
    },
    2871: {
      lat: 147.811688,
      lng: -33.476768,
      city: 'Yarragong',
      state: 'NSW',
    },
    2873: {
      lat: 147.260419,
      lng: -32.269414,
      city: 'Tottenham',
      state: 'NSW',
    },
    2874: {
      lat: 147.538565,
      lng: -32.619929,
      city: 'Yethera',
      state: 'NSW',
    },
    2875: {
      lat: 147.5778762,
      lng: -33.13540693,
      city: 'Yarrabandai',
      state: 'NSW',
    },
    2876: {
      lat: 147.772834,
      lng: -33.159023,
      city: 'Nelungaloo',
      state: 'NSW',
    },
    2877: {
      lat: 146.085138,
      lng: -32.844998,
      city: 'Mulguthrie',
      state: 'NSW',
    },
    2878: {
      lat: 144.495401,
      lng: -32.823221,
      city: 'Trida',
      state: 'NSW',
    },
    2879: {
      lat: 142.3328819,
      lng: -32.27503624,
      city: 'Sunset Strip',
      state: 'NSW',
    },
    2880: {
      lat: 142.203381,
      lng: -30.170441,
      city: 'Tibooburra',
      state: 'NSW',
    },
    2890: {
      lat: 150.1390075,
      lng: -32.8310013,
      city: 'Forces',
      state: 'NSW',
    },
    2891: {
      lat: 151.209,
      lng: -33.8688,
      city: 'Sydney Gateway Facility',
      state: 'NSW',
    },
    2898: {
      lat: 159.081217,
      lng: -31.55247,
      city: 'Lord Howe Island',
      state: 'NSW',
    },
    2899: {
      lat: 146.928783,
      lng: -36.084231,
      city: 'Norfolk Island',
      state: 'NSW',
    },
    2900: {
      lat: 149.063805,
      lng: -35.41891,
      city: 'Tuggeranong',
      state: 'ACT',
    },
    2901: {
      lat: 149.069,
      lng: -35.4197,
      city: 'Tuggeranong Dc',
      state: 'ACT',
    },
    2902: {
      lat: 149.059107,
      lng: -35.37869,
      city: 'Kambah Village',
      state: 'ACT',
    },
    2903: {
      lat: 149.081776,
      lng: -35.402705,
      city: 'Wanniassa',
      state: 'ACT',
    },
    2904: {
      lat: 149.111402,
      lng: -35.410206,
      city: 'Monash',
      state: 'ACT',
    },
    2905: {
      lat: 149.110025,
      lng: -35.431011,
      city: 'Theodore',
      state: 'ACT',
    },
    2906: {
      lat: 149.08972,
      lng: -35.45676,
      city: 'Gordon',
      state: 'ACT',
    },
    2911: {
      lat: 149.132505,
      lng: -35.218737,
      city: 'Mitchell',
      state: 'ACT',
    },
    2912: {
      lat: 149.123552,
      lng: -35.172453,
      city: 'Gungahlin',
      state: 'ACT',
    },
    2913: {
      lat: 149.1071988,
      lng: -35.14680361,
      city: 'Taylor',
      state: 'ACT',
    },
    2914: {
      lat: 149.1636815,
      lng: -35.19159199,
      city: 'Throsby',
      state: 'ACT',
    },
    3000: {
      lat: 144.956776,
      lng: -37.817403,
      city: 'Melbourne',
      state: 'VIC',
    },
    3001: {
      lat: 144.956776,
      lng: -37.817403,
      city: 'Melbourne',
      state: 'VIC',
    },
    3002: {
      lat: 144.982207,
      lng: -37.818517,
      city: 'East Melbourne',
      state: 'VIC',
    },
    3003: {
      lat: 144.949592,
      lng: -37.810871,
      city: 'West Melbourne',
      state: 'VIC',
    },
    3004: {
      lat: 144.976,
      lng: -37.8368,
      city: 'St Kilda Road Melbourne',
      state: 'VIC',
    },
    3005: {
      lat: 144.950858,
      lng: -37.824608,
      city: 'World Trade Centre',
      state: 'VIC',
    },
    3006: {
      lat: 144.965926,
      lng: -37.823258,
      city: 'Southbank',
      state: 'VIC',
    },
    3008: {
      lat: 144.948039,
      lng: -37.814719,
      city: 'Docklands',
      state: 'VIC',
    },
    3010: {
      lat: 144.961351,
      lng: -37.796152,
      city: 'University Of Melbourne',
      state: 'VIC',
    },
    3011: {
      lat: 144.907953,
      lng: -37.807101,
      city: 'Seddon West',
      state: 'VIC',
    },
    3012: {
      lat: 144.861162,
      lng: -37.807135,
      city: 'West Footscray',
      state: 'VIC',
    },
    3013: {
      lat: 144.888686,
      lng: -37.814183,
      city: 'Yarraville West',
      state: 'VIC',
    },
    3015: {
      lat: 144.880556,
      lng: -37.838242,
      city: 'Spotswood',
      state: 'VIC',
    },
    3016: {
      lat: 144.888461,
      lng: -37.863743,
      city: 'Williamstown North',
      state: 'VIC',
    },
    3018: {
      lat: 144.812736,
      lng: -37.86172,
      city: 'Seaholme',
      state: 'VIC',
    },
    3019: {
      lat: 144.854014,
      lng: -37.784683,
      city: 'Robinson',
      state: 'VIC',
    },
    3020: {
      lat: 144.829945,
      lng: -37.777204,
      city: 'Sunshine West',
      state: 'VIC',
    },
    3021: {
      lat: 144.796693,
      lng: -37.743361,
      city: 'St Albans',
      state: 'VIC',
    },
    3022: {
      lat: 144.793961,
      lng: -37.795937,
      city: 'Deer Park East',
      state: 'VIC',
    },
    3023: {
      lat: 144.770641,
      lng: -37.78272,
      city: 'Ravenhall',
      state: 'VIC',
    },
    3024: {
      lat: 144.556552,
      lng: -37.899873,
      city: 'Wyndham Vale',
      state: 'VIC',
    },
    3025: {
      lat: 144.839704,
      lng: -37.835562,
      city: 'Altona North',
      state: 'VIC',
    },
    3026: {
      lat: 144.774986,
      lng: -37.829923,
      city: 'Laverton North',
      state: 'VIC',
    },
    3027: {
      lat: 144.754147,
      lng: -37.932419,
      city: 'Williams Raaf',
      state: 'VIC',
    },
    3028: {
      lat: 144.777165,
      lng: -37.875066,
      city: 'Seabrook',
      state: 'VIC',
    },
    3029: {
      lat: 144.705831,
      lng: -37.837165,
      city: 'Truganina',
      state: 'VIC',
    },
    3030: {
      lat: 144.64209,
      lng: -37.91624,
      city: 'Werribee South',
      state: 'VIC',
    },
    3031: {
      lat: 144.923403,
      lng: -37.79119,
      city: 'Kensington',
      state: 'VIC',
    },
    3032: {
      lat: 144.897221,
      lng: -37.774486,
      city: 'Travancore',
      state: 'VIC',
    },
    3033: {
      lat: 144.857638,
      lng: -37.74107,
      city: 'Keilor East',
      state: 'VIC',
    },
    3034: {
      lat: 144.86194,
      lng: -37.761235,
      city: 'Avondale Heights',
      state: 'VIC',
    },
    3036: {
      lat: 144.82246,
      lng: -37.705324,
      city: 'Keilor North',
      state: 'VIC',
    },
    3037: {
      lat: 144.780474,
      lng: -37.715579,
      city: 'Taylors Hill',
      state: 'VIC',
    },
    3038: {
      lat: 144.766873,
      lng: -37.700454,
      city: 'Watergardens',
      state: 'VIC',
    },
    3039: {
      lat: 144.922995,
      lng: -37.766184,
      city: 'Moonee Ponds',
      state: 'VIC',
    },
    3040: {
      lat: 144.902556,
      lng: -37.751845,
      city: 'Essendon West',
      state: 'VIC',
    },
    3041: {
      lat: 144.904815,
      lng: -37.731464,
      city: 'Strathmore Heights',
      state: 'VIC',
    },
    3042: {
      lat: 144.886,
      lng: -37.7393,
      city: 'Niddrie North',
      state: 'VIC',
    },
    3043: {
      lat: 144.888368,
      lng: -37.691916,
      city: 'Tullamarine',
      state: 'VIC',
    },
    3044: {
      lat: 144.936356,
      lng: -37.731138,
      city: 'Pascoe Vale South',
      state: 'VIC',
    },
    3045: {
      lat: 144.836195,
      lng: -37.674739,
      city: 'Melbourne Airport',
      state: 'VIC',
    },
    3046: {
      lat: 144.927968,
      lng: -37.706578,
      city: 'Oak Park',
      state: 'VIC',
    },
    3047: {
      lat: 144.930247,
      lng: -37.681105,
      city: 'Jacana',
      state: 'VIC',
    },
    3048: {
      lat: 144.923609,
      lng: -37.653917,
      city: 'Meadow Heights',
      state: 'VIC',
    },
    3049: {
      lat: 144.888492,
      lng: -37.673182,
      city: 'Westmeadows',
      state: 'VIC',
    },
    3050: {
      lat: 144.954581,
      lng: -37.800324,
      city: 'Royal Melbourne Hospital',
      state: 'VIC',
    },
    3051: {
      lat: 144.943559,
      lng: -37.8006,
      city: 'North Melbourne',
      state: 'VIC',
    },
    3052: {
      lat: 144.948903,
      lng: -37.788996,
      city: 'Parkville',
      state: 'VIC',
    },
    3053: {
      lat: 144.966112,
      lng: -37.803569,
      city: 'Carlton South',
      state: 'VIC',
    },
    3054: {
      lat: 144.96724,
      lng: -37.786971,
      city: 'Princes Hill',
      state: 'VIC',
    },
    3055: {
      lat: 144.942222,
      lng: -37.763584,
      city: 'Moreland West',
      state: 'VIC',
    },
    3056: {
      lat: 144.960108,
      lng: -37.766292,
      city: 'Brunswick North',
      state: 'VIC',
    },
    3057: {
      lat: 144.98034,
      lng: -37.772232,
      city: 'Sumner',
      state: 'VIC',
    },
    3058: {
      lat: 144.964524,
      lng: -37.739901,
      city: 'Moreland',
      state: 'VIC',
    },
    3059: {
      lat: 144.879626,
      lng: -37.639887,
      city: 'Greenvale',
      state: 'VIC',
    },
    3060: {
      lat: 144.967874,
      lng: -37.707326,
      city: 'Fawkner North',
      state: 'VIC',
    },
    3061: {
      lat: 144.956763,
      lng: -37.666265,
      city: 'Campbellfield',
      state: 'VIC',
    },
    3062: {
      lat: 144.932557,
      lng: -37.632986,
      city: 'Somerton',
      state: 'VIC',
    },
    3063: {
      lat: 144.856082,
      lng: -37.595015,
      city: 'Yuroke',
      state: 'VIC',
    },
    3064: {
      lat: 144.942199,
      lng: -37.546522,
      city: 'Roxburgh Park',
      state: 'VIC',
    },
    3065: {
      lat: 144.977795,
      lng: -37.802608,
      city: 'Fitzroy',
      state: 'VIC',
    },
    3066: {
      lat: 144.986906,
      lng: -37.804846,
      city: 'Collingwood North',
      state: 'VIC',
    },
    3067: {
      lat: 144.998203,
      lng: -37.803515,
      city: 'Abbotsford',
      state: 'VIC',
    },
    3068: {
      lat: 144.98622,
      lng: -37.790974,
      city: 'Fitzroy North',
      state: 'VIC',
    },
    3070: {
      lat: 144.999745,
      lng: -37.774064,
      city: 'Northcote South',
      state: 'VIC',
    },
    3071: {
      lat: 145.007006,
      lng: -37.759426,
      city: 'Thornbury',
      state: 'VIC',
    },
    3072: {
      lat: 145.005702,
      lng: -37.74247,
      city: 'Sylvester',
      state: 'VIC',
    },
    3073: {
      lat: 145.005955,
      lng: -37.712474,
      city: 'Reservoir South',
      state: 'VIC',
    },
    3074: {
      lat: 145.006644,
      lng: -37.684684,
      city: 'Thomastown',
      state: 'VIC',
    },
    3075: {
      lat: 145.002679,
      lng: -37.66607,
      city: 'Lalor Plaza',
      state: 'VIC',
    },
    3076: {
      lat: 145.022137,
      lng: -37.643598,
      city: 'Epping Dc',
      state: 'VIC',
    },
    3078: {
      lat: 145.025853,
      lng: -37.778298,
      city: 'Fairfield',
      state: 'VIC',
    },
    3079: {
      lat: 145.048573,
      lng: -37.772675,
      city: 'Ivanhoe North',
      state: 'VIC',
    },
    3081: {
      lat: 145.046401,
      lng: -37.743008,
      city: 'Heidelberg West',
      state: 'VIC',
    },
    3082: {
      lat: 145.065658,
      lng: -37.664858,
      city: 'Mill Park',
      state: 'VIC',
    },
    3083: {
      lat: 145.056223,
      lng: -37.701346,
      city: 'La Trobe University',
      state: 'VIC',
    },
    3084: {
      lat: 145.082685,
      lng: -37.744406,
      city: 'Viewbank',
      state: 'VIC',
    },
    3085: {
      lat: 145.06988,
      lng: -37.724398,
      city: 'Yallambie',
      state: 'VIC',
    },
    3086: {
      lat: 145.047012,
      lng: -37.721328,
      city: 'La Trobe University',
      state: 'VIC',
    },
    3087: {
      lat: 145.082426,
      lng: -37.711787,
      city: 'Watsonia North',
      state: 'VIC',
    },
    3088: {
      lat: 145.1294801,
      lng: -37.68949912,
      city: 'St Helena',
      state: 'VIC',
    },
    3089: {
      lat: 145.150369,
      lng: -37.667713,
      city: 'Diamond Creek',
      state: 'VIC',
    },
    3090: {
      lat: 145.107802,
      lng: -37.668408,
      city: 'Plenty',
      state: 'VIC',
    },
    3091: {
      lat: 145.136688,
      lng: -37.637478,
      city: 'Yarrambat',
      state: 'VIC',
    },
    3093: {
      lat: 145.115208,
      lng: -37.73919,
      city: 'Lower Plenty',
      state: 'VIC',
    },
    3094: {
      lat: 145.124727,
      lng: -37.719759,
      city: 'Montmorency',
      state: 'VIC',
    },
    3095: {
      lat: 145.158579,
      lng: -37.713299,
      city: 'Research',
      state: 'VIC',
    },
    3096: {
      lat: 145.185826,
      lng: -37.670851,
      city: 'Wattle Glen',
      state: 'VIC',
    },
    3097: {
      lat: 145.224204,
      lng: -37.695254,
      city: 'Watsons Creek',
      state: 'VIC',
    },
    3099: {
      lat: 145.192854,
      lng: -37.592101,
      city: 'Strathewen',
      state: 'VIC',
    },
    3101: {
      lat: 145.036575,
      lng: -37.807397,
      city: 'Kew',
      state: 'VIC',
    },
    3102: {
      lat: 145.05219,
      lng: -37.793805,
      city: 'Kew East',
      state: 'VIC',
    },
    3103: {
      lat: 145.062,
      lng: -37.8007,
      city: 'Stradbroke Park',
      state: 'VIC',
    },
    3104: {
      lat: 145.081714,
      lng: -37.793695,
      city: 'Greythorn',
      state: 'VIC',
    },
    3105: {
      lat: 145.084806,
      lng: -37.772477,
      city: 'Bulleen South',
      state: 'VIC',
    },
    3106: {
      lat: 145.142675,
      lng: -37.758335,
      city: 'Templestowe',
      state: 'VIC',
    },
    3107: {
      lat: 145.110677,
      lng: -37.765225,
      city: 'Templestowe Lower',
      state: 'VIC',
    },
    3108: {
      lat: 145.125797,
      lng: -37.786429,
      city: 'Doncaster',
      state: 'VIC',
    },
    3109: {
      lat: 145.161649,
      lng: -37.784786,
      city: 'Tunstall Square Po',
      state: 'VIC',
    },
    3111: {
      lat: 145.186334,
      lng: -37.794054,
      city: 'Donvale',
      state: 'VIC',
    },
    3113: {
      lat: 145.204689,
      lng: -37.752123,
      city: 'Warrandyte',
      state: 'VIC',
    },
    3114: {
      lat: 145.212599,
      lng: -37.778595,
      city: 'Park Orchards',
      state: 'VIC',
    },
    3115: {
      lat: 145.267586,
      lng: -37.736734,
      city: 'Wonga Park',
      state: 'VIC',
    },
    3116: {
      lat: 145.313086,
      lng: -37.737629,
      city: 'Chirnside Park',
      state: 'VIC',
    },
    3121: {
      lat: 145.001788,
      lng: -37.823303,
      city: 'Victoria Gardens',
      state: 'VIC',
    },
    3122: {
      lat: 145.030602,
      lng: -37.822725,
      city: 'Hawthorn West',
      state: 'VIC',
    },
    3123: {
      lat: 145.049415,
      lng: -37.829752,
      city: 'Hawthorn East',
      state: 'VIC',
    },
    3124: {
      lat: 145.068191,
      lng: -37.840867,
      city: 'Middle Camberwell',
      state: 'VIC',
    },
    3125: {
      lat: 145.101,
      lng: -37.851305,
      city: 'Surrey Hills South',
      state: 'VIC',
    },
    3126: {
      lat: 145.075748,
      lng: -37.827203,
      city: 'Canterbury',
      state: 'VIC',
    },
    3127: {
      lat: 145.097236,
      lng: -37.825173,
      city: 'Surrey Hills North',
      state: 'VIC',
    },
    3128: {
      lat: 145.122393,
      lng: -37.828866,
      city: 'Wattle Park',
      state: 'VIC',
    },
    3129: {
      lat: 145.125084,
      lng: -37.806965,
      city: 'Mont Albert North',
      state: 'VIC',
    },
    3130: {
      lat: 145.149653,
      lng: -37.81805,
      city: 'Laburnum',
      state: 'VIC',
    },
    3131: {
      lat: 145.174489,
      lng: -37.817798,
      city: 'Nunawading',
      state: 'VIC',
    },
    3132: {
      lat: 145.196686,
      lng: -37.819222,
      city: 'Rangeview',
      state: 'VIC',
    },
    3133: {
      lat: 145.192303,
      lng: -37.848786,
      city: 'Vermont South',
      state: 'VIC',
    },
    3134: {
      lat: 145.232557,
      lng: -37.796091,
      city: 'Warranwood',
      state: 'VIC',
    },
    3135: {
      lat: 145.248209,
      lng: -37.819594,
      city: 'Ringwood East',
      state: 'VIC',
    },
    3136: {
      lat: 145.282396,
      lng: -37.779876,
      city: 'Croydon South',
      state: 'VIC',
    },
    3137: {
      lat: 145.313346,
      lng: -37.819364,
      city: 'Kilsyth South',
      state: 'VIC',
    },
    3138: {
      lat: 145.322794,
      lng: -37.78391,
      city: 'Mooroolbark',
      state: 'VIC',
    },
    3139: {
      lat: 145.532666,
      lng: -37.814506,
      city: 'Yellingbo',
      state: 'VIC',
    },
    3140: {
      lat: 145.363185,
      lng: -37.759154,
      city: 'Lilydale',
      state: 'VIC',
    },
    3141: {
      lat: 144.991264,
      lng: -37.840679,
      city: 'South Yarra',
      state: 'VIC',
    },
    3142: {
      lat: 145.017732,
      lng: -37.84411,
      city: 'Toorak',
      state: 'VIC',
    },
    3143: {
      lat: 145.019393,
      lng: -37.858918,
      city: 'Armadale North',
      state: 'VIC',
    },
    3144: {
      lat: 145.034127,
      lng: -37.857443,
      city: 'Malvern North',
      state: 'VIC',
    },
    3145: {
      lat: 145.049328,
      lng: -37.873557,
      city: 'Wattletree Road Po',
      state: 'VIC',
    },
    3146: {
      lat: 145.057833,
      lng: -37.857116,
      city: 'Tooronga',
      state: 'VIC',
    },
    3147: {
      lat: 145.092946,
      lng: -37.867864,
      city: 'Ashwood',
      state: 'VIC',
    },
    3148: {
      lat: 145.112084,
      lng: -37.8736,
      city: 'Jordanville',
      state: 'VIC',
    },
    3149: {
      lat: 145.128118,
      lng: -37.880748,
      city: 'Syndal',
      state: 'VIC',
    },
    3150: {
      lat: 145.170841,
      lng: -37.876968,
      city: 'Wheelers Hill',
      state: 'VIC',
    },
    3151: {
      lat: 145.151119,
      lng: -37.85523,
      city: 'Burwood Heights',
      state: 'VIC',
    },
    3152: {
      lat: 145.221695,
      lng: -37.864951,
      city: 'Wantirna South',
      state: 'VIC',
    },
    3153: {
      lat: 145.270141,
      lng: -37.845116,
      city: 'Bayswater North',
      state: 'VIC',
    },
    3154: {
      lat: 145.31784,
      lng: -37.856009,
      city: 'The Basin',
      state: 'VIC',
    },
    3155: {
      lat: 145.284418,
      lng: -37.861799,
      city: 'Boronia',
      state: 'VIC',
    },
    3156: {
      lat: 145.292895,
      lng: -37.917932,
      city: 'Upper Ferntree Gully',
      state: 'VIC',
    },
    3158: {
      lat: 145.324468,
      lng: -37.910077,
      city: 'Upwey',
      state: 'VIC',
    },
    3159: {
      lat: 145.3892,
      lng: -37.921894,
      city: 'Selby',
      state: 'VIC',
    },
    3160: {
      lat: 145.350821,
      lng: -37.935097,
      city: 'Tecoma',
      state: 'VIC',
    },
    3161: {
      lat: 145.019929,
      lng: -37.873733,
      city: 'Caulfield North',
      state: 'VIC',
    },
    3162: {
      lat: 145.023394,
      lng: -37.892139,
      city: 'Hopetoun Gardens',
      state: 'VIC',
    },
    3163: {
      lat: 145.057237,
      lng: -37.896497,
      city: 'Murrumbeena',
      state: 'VIC',
    },
    3164: {
      lat: 145.23738,
      lng: -38.02243,
      city: 'Dandenong South',
      state: 'VIC',
    },
    3165: {
      lat: 145.059426,
      lng: -37.922369,
      city: 'Coatesville',
      state: 'VIC',
    },
    3166: {
      lat: 145.089719,
      lng: -37.902805,
      city: 'Oakleigh East',
      state: 'VIC',
    },
    3167: {
      lat: 145.087614,
      lng: -37.923345,
      city: 'Oakleigh South',
      state: 'VIC',
    },
    3168: {
      lat: 145.12665,
      lng: -37.91342,
      city: 'Notting Hill',
      state: 'VIC',
    },
    3169: {
      lat: 145.115271,
      lng: -37.945149,
      city: 'Clayton South',
      state: 'VIC',
    },
    3170: {
      lat: 145.167859,
      lng: -37.920945,
      city: 'Waverley Gardens',
      state: 'VIC',
    },
    3171: {
      lat: 145.156783,
      lng: -37.944608,
      city: 'Springvale',
      state: 'VIC',
    },
    3172: {
      lat: 145.13465,
      lng: -37.975981,
      city: 'Springvale South',
      state: 'VIC',
    },
    3173: {
      lat: 145.164863,
      lng: -38.006771,
      city: 'Keysborough',
      state: 'VIC',
    },
    3174: {
      lat: 145.17877,
      lng: -37.966218,
      city: 'Noble Park North',
      state: 'VIC',
    },
    3175: {
      lat: 145.208504,
      lng: -38.016114,
      city: 'Dunearn',
      state: 'VIC',
    },
    3176: {
      lat: 145.23,
      lng: -37.8992,
      city: 'Scoresby Bc',
      state: 'VIC',
    },
    3177: {
      lat: 145.237462,
      lng: -37.990985,
      city: 'Eumemmerring',
      state: 'VIC',
    },
    3178: {
      lat: 145.241589,
      lng: -37.924431,
      city: 'Rowville',
      state: 'VIC',
    },
    3179: {
      lat: 145.222634,
      lng: -37.897137,
      city: 'Scoresby',
      state: 'VIC',
    },
    3180: {
      lat: 145.246731,
      lng: -37.893263,
      city: 'Knoxfield',
      state: 'VIC',
    },
    3181: {
      lat: 144.995504,
      lng: -37.854683,
      city: 'Windsor',
      state: 'VIC',
    },
    3182: {
      lat: 144.979478,
      lng: -37.865322,
      city: 'St Kilda West',
      state: 'VIC',
    },
    3183: {
      lat: 144.999026,
      lng: -37.869072,
      city: 'St Kilda East',
      state: 'VIC',
    },
    3184: {
      lat: 144.984034,
      lng: -37.881414,
      city: 'Elwood',
      state: 'VIC',
    },
    3185: {
      lat: 145.002571,
      lng: -37.891841,
      city: 'Ripponlea',
      state: 'VIC',
    },
    3186: {
      lat: 144.996329,
      lng: -37.915066,
      city: 'Were Street Po',
      state: 'VIC',
    },
    3187: {
      lat: 145.013593,
      lng: -37.925371,
      city: 'North Road',
      state: 'VIC',
    },
    3188: {
      lat: 145.007238,
      lng: -37.938889,
      city: 'Hampton North',
      state: 'VIC',
    },
    3189: {
      lat: 145.047802,
      lng: -37.942021,
      city: 'Wishart',
      state: 'VIC',
    },
    3190: {
      lat: 145.038147,
      lng: -37.951394,
      city: 'Highett',
      state: 'VIC',
    },
    3191: {
      lat: 145.013472,
      lng: -37.956585,
      city: 'Sandringham',
      state: 'VIC',
    },
    3192: {
      lat: 145.0623,
      lng: -37.965655,
      city: 'Southland Centre',
      state: 'VIC',
    },
    3193: {
      lat: 145.031561,
      lng: -37.978698,
      city: 'Cromer',
      state: 'VIC',
    },
    3194: {
      lat: 145.067356,
      lng: -37.985059,
      city: 'Moorabbin Airport',
      state: 'VIC',
    },
    3195: {
      lat: 145.104105,
      lng: -38.007024,
      city: 'Waterways',
      state: 'VIC',
    },
    3196: {
      lat: 145.122762,
      lng: -38.046479,
      city: 'Edithvale',
      state: 'VIC',
    },
    3197: {
      lat: 145.134111,
      lng: -38.072748,
      city: 'Patterson Lakes',
      state: 'VIC',
    },
    3198: {
      lat: 145.137811,
      lng: -38.103194,
      city: 'Seaford',
      state: 'VIC',
    },
    3199: {
      lat: 145.135961,
      lng: -38.162578,
      city: 'Karingal Centre',
      state: 'VIC',
    },
    3200: {
      lat: 145.157574,
      lng: -38.126458,
      city: 'Pines Forest',
      state: 'VIC',
    },
    3201: {
      lat: 145.177905,
      lng: -38.092874,
      city: 'Carrum Downs',
      state: 'VIC',
    },
    3202: {
      lat: 145.092698,
      lng: -37.960555,
      city: 'Heatherton',
      state: 'VIC',
    },
    3204: {
      lat: 145.036783,
      lng: -37.916956,
      city: 'Patterson',
      state: 'VIC',
    },
    3205: {
      lat: 144.958176,
      lng: -37.831693,
      city: 'South Melbourne Dc',
      state: 'VIC',
    },
    3206: {
      lat: 144.95086,
      lng: -37.846534,
      city: 'Middle Park',
      state: 'VIC',
    },
    3207: {
      lat: 144.918044,
      lng: -37.832179,
      city: 'Port Melbourne',
      state: 'VIC',
    },
    3211: {
      lat: 144.498479,
      lng: -37.943586,
      city: 'Little River',
      state: 'VIC',
    },
    3212: {
      lat: 144.422666,
      lng: -38.039958,
      city: 'Point Wilson',
      state: 'VIC',
    },
    3213: {
      lat: 144.2771,
      lng: -38.038,
      city: 'Moorabool',
      state: 'VIC',
    },
    3214: {
      lat: 144.352385,
      lng: -38.084781,
      city: 'North Shore',
      state: 'VIC',
    },
    3215: {
      lat: 144.33452,
      lng: -38.111002,
      city: 'Rippleside',
      state: 'VIC',
    },
    3216: {
      lat: 144.334005,
      lng: -38.215906,
      city: 'Waurn Ponds',
      state: 'VIC',
    },
    3217: {
      lat: 144.3127944,
      lng: -38.24983474,
      city: 'Mount Duneed',
      state: 'VIC',
    },
    3218: {
      lat: 144.223441,
      lng: -38.1220442,
      city: 'Stonehaven',
      state: 'VIC',
    },
    3219: {
      lat: 144.389533,
      lng: -38.17277,
      city: 'Whittington',
      state: 'VIC',
    },
    3220: {
      lat: 144.34652,
      lng: -38.157038,
      city: 'South Geelong',
      state: 'VIC',
    },
    3221: {
      lat: 144.427419,
      lng: -38.181608,
      city: 'Wye River',
      state: 'VIC',
    },
    3222: {
      lat: 144.5118946,
      lng: -38.22638039,
      city: 'Wallington',
      state: 'VIC',
    },
    3223: {
      lat: 144.668477,
      lng: -38.147989,
      city: 'St Leonards',
      state: 'VIC',
    },
    3224: {
      lat: 144.4248188,
      lng: -38.17988326,
      city: 'Moolap',
      state: 'VIC',
    },
    3225: {
      lat: 144.614191,
      lng: -38.271074,
      city: 'Swan Island',
      state: 'VIC',
    },
    3226: {
      lat: 144.523761,
      lng: -38.263811,
      city: 'Ocean Grove',
      state: 'VIC',
    },
    3227: {
      lat: 144.40814,
      lng: -38.263052,
      city: 'Connewarre',
      state: 'VIC',
    },
    3228: {
      lat: 144.299978,
      lng: -38.307545,
      city: 'Torquay',
      state: 'VIC',
    },
    3230: {
      lat: 144.176397,
      lng: -38.409363,
      city: 'Anglesea',
      state: 'VIC',
    },
    3231: {
      lat: 144.106892,
      lng: -38.459435,
      city: 'Moggs Creek',
      state: 'VIC',
    },
    3232: {
      lat: 143.970167,
      lng: -38.544977,
      city: 'Lorne',
      state: 'VIC',
    },
    3233: {
      lat: 143.671777,
      lng: -38.679417,
      city: 'Skenes Creek North',
      state: 'VIC',
    },
    3234: {
      lat: 143.8493949,
      lng: -38.62497369,
      city: 'Wye River',
      state: 'VIC',
    },
    3235: {
      lat: 143.924288,
      lng: -38.411964,
      city: 'Pennyroyal',
      state: 'VIC',
    },
    3236: {
      lat: 143.712384,
      lng: -38.521903,
      city: 'Mount Sabine',
      state: 'VIC',
    },
    3237: {
      lat: 143.474392,
      lng: -38.716838,
      city: 'Yuulong',
      state: 'VIC',
    },
    3238: {
      lat: 143.429978,
      lng: -38.781787,
      city: 'Lavers Hill',
      state: 'VIC',
    },
    3239: {
      lat: 143.448102,
      lng: -38.564014,
      city: 'Kennedys Creek',
      state: 'VIC',
    },
    3240: {
      lat: 144.174411,
      lng: -38.263005,
      city: 'Paraparap',
      state: 'VIC',
    },
    3241: {
      lat: 143.892824,
      lng: -38.20363,
      city: 'Wurdiboluc',
      state: 'VIC',
    },
    3242: {
      lat: 143.740326,
      lng: -38.346704,
      city: 'Birregurra',
      state: 'VIC',
    },
    3243: {
      lat: 143.82588,
      lng: -38.364034,
      city: 'Whoorel',
      state: 'VIC',
    },
    3249: {
      lat: 143.557159,
      lng: -38.388291,
      city: 'Yeodene',
      state: 'VIC',
    },
    3250: {
      lat: 143.584742,
      lng: -38.311513,
      city: 'Elliminyt',
      state: 'VIC',
    },
    3251: {
      lat: 143.648479,
      lng: -38.155186,
      city: 'Weering',
      state: 'VIC',
    },
    3254: {
      lat: 143.52228,
      lng: -38.289636,
      city: 'Cororooke',
      state: 'VIC',
    },
    3260: {
      lat: 143.244766,
      lng: -38.209584,
      city: 'Weerite',
      state: 'VIC',
    },
    3264: {
      lat: 142.884394,
      lng: -38.232443,
      city: 'Terang',
      state: 'VIC',
    },
    3265: {
      lat: 142.952372,
      lng: -38.178237,
      city: 'The Sisters',
      state: 'VIC',
    },
    3266: {
      lat: 143.133721,
      lng: -38.407094,
      city: 'Simpson',
      state: 'VIC',
    },
    3267: {
      lat: 143.06073,
      lng: -38.466647,
      city: 'Scotts Creek',
      state: 'VIC',
    },
    3268: {
      lat: 142.908911,
      lng: -38.535802,
      city: 'Timboon West',
      state: 'VIC',
    },
    3269: {
      lat: 143.177116,
      lng: -38.660637,
      city: 'Waarre',
      state: 'VIC',
    },
    3270: {
      lat: 142.870164,
      lng: -38.602117,
      city: 'Peterborough',
      state: 'VIC',
    },
    3271: {
      lat: 142.98753,
      lng: -37.888549,
      city: 'Pura Pura',
      state: 'VIC',
    },
    3272: {
      lat: 142.853634,
      lng: -37.982186,
      city: 'Woorndoo',
      state: 'VIC',
    },
    3273: {
      lat: 142.660643,
      lng: -37.981249,
      city: 'Hexham',
      state: 'VIC',
    },
    3274: {
      lat: 142.478612,
      lng: -37.960778,
      city: 'Caramut',
      state: 'VIC',
    },
    3275: {
      lat: 142.457611,
      lng: -38.301794,
      city: 'Mailors Flat',
      state: 'VIC',
    },
    3276: {
      lat: 142.409105,
      lng: -38.180923,
      city: 'Woolsthorpe',
      state: 'VIC',
    },
    3277: {
      lat: 142.660221,
      lng: -38.389835,
      city: 'Naringal East',
      state: 'VIC',
    },
    3278: {
      lat: 142.554533,
      lng: -38.29668931,
      city: 'Purnim West',
      state: 'VIC',
    },
    3279: {
      lat: 142.657483,
      lng: -38.214555,
      city: 'Wangoom',
      state: 'VIC',
    },
    3280: {
      lat: 142.482889,
      lng: -38.37884,
      city: 'Warrnambool West',
      state: 'VIC',
    },
    3281: {
      lat: 142.630109,
      lng: -38.254454,
      city: 'Woodford',
      state: 'VIC',
    },
    3282: {
      lat: 142.351658,
      lng: -38.268176,
      city: 'Koroit',
      state: 'VIC',
    },
    3283: {
      lat: 142.328761,
      lng: -38.313434,
      city: 'Yarpturk',
      state: 'VIC',
    },
    3284: {
      lat: 142.119266,
      lng: -38.270398,
      city: 'Port Fairy',
      state: 'VIC',
    },
    3285: {
      lat: 142.058916,
      lng: -38.290199,
      city: 'Yambuk',
      state: 'VIC',
    },
    3286: {
      lat: 142.0716061,
      lng: -37.98614494,
      city: 'Warrabkook',
      state: 'VIC',
    },
    3287: {
      lat: 142.31953,
      lng: -38.059868,
      city: 'Minhamite',
      state: 'VIC',
    },
    3289: {
      lat: 142.227754,
      lng: -37.893196,
      city: 'Tabor',
      state: 'VIC',
    },
    3292: {
      lat: 141.051987,
      lng: -38.038873,
      city: 'Nelson',
      state: 'VIC',
    },
    3293: {
      lat: 142.527253,
      lng: -37.655945,
      city: 'Narrapumelap South',
      state: 'VIC',
    },
    3294: {
      lat: 142.312764,
      lng: -37.573978,
      city: 'Woodhouse',
      state: 'VIC',
    },
    3300: {
      lat: 142.013356,
      lng: -37.743155,
      city: 'Hamilton',
      state: 'VIC',
    },
    3301: {
      lat: 142.034141,
      lng: -38.118855,
      city: 'Yulecart',
      state: 'VIC',
    },
    3302: {
      lat: 141.706379,
      lng: -37.847981,
      city: 'Grassdale',
      state: 'VIC',
    },
    3303: {
      lat: 141.789606,
      lng: -37.980868,
      city: 'Wallacedale',
      state: 'VIC',
    },
    3304: {
      lat: 141.952156,
      lng: -38.182084,
      city: 'Winnap',
      state: 'VIC',
    },
    3305: {
      lat: 141.437227,
      lng: -38.235475,
      city: 'Portland West',
      state: 'VIC',
    },
    3309: {
      lat: 141.511007,
      lng: -37.82644,
      city: 'Digby',
      state: 'VIC',
    },
    3310: {
      lat: 141.549163,
      lng: -37.70789,
      city: 'Tahara West',
      state: 'VIC',
    },
    3311: {
      lat: 141.229722,
      lng: -37.468159,
      city: 'Puralka',
      state: 'VIC',
    },
    3312: {
      lat: 141.365747,
      lng: -37.6391,
      city: 'Warrock',
      state: 'VIC',
    },
    3314: {
      lat: 141.935665,
      lng: -37.586718,
      city: 'Woohlpooer',
      state: 'VIC',
    },
    3315: {
      lat: 141.687516,
      lng: -37.408371,
      city: 'Wootong Vale',
      state: 'VIC',
    },
    3317: {
      lat: 141.451647,
      lng: -37.166673,
      city: 'Harrow',
      state: 'VIC',
    },
    3318: {
      lat: 141.269571,
      lng: -37.025316,
      city: 'Ullswater',
      state: 'VIC',
    },
    3319: {
      lat: 141.051389,
      lng: -36.873961,
      city: 'Bringalbert',
      state: 'VIC',
    },
    3321: {
      lat: 143.88056,
      lng: -38.087758,
      city: 'Wingeel',
      state: 'VIC',
    },
    3322: {
      lat: 143.743305,
      lng: -38.021333,
      city: 'Cressy',
      state: 'VIC',
    },
    3323: {
      lat: 143.520934,
      lng: -38.026314,
      city: 'Foxhow',
      state: 'VIC',
    },
    3324: {
      lat: 143.375414,
      lng: -37.941939,
      city: 'Mount Bute',
      state: 'VIC',
    },
    3325: {
      lat: 143.185911,
      lng: -37.877452,
      city: 'Vite Vite North',
      state: 'VIC',
    },
    3328: {
      lat: 144.043058,
      lng: -38.018664,
      city: 'Teesdale',
      state: 'VIC',
    },
    3329: {
      lat: 143.908716,
      lng: -37.983701,
      city: 'Shelford',
      state: 'VIC',
    },
    3330: {
      lat: 143.758576,
      lng: -37.918437,
      city: 'Rokewood',
      state: 'VIC',
    },
    3331: {
      lat: 144.194625,
      lng: -38.049195,
      city: 'Sutherlands Creek',
      state: 'VIC',
    },
    3332: {
      lat: 144.097178,
      lng: -37.944446,
      city: 'Lethbridge',
      state: 'VIC',
    },
    3333: {
      lat: 144.04476,
      lng: -37.87179,
      city: 'Meredith',
      state: 'VIC',
    },
    3334: {
      lat: 144.086089,
      lng: -37.75803,
      city: 'Mount Doran',
      state: 'VIC',
    },
    3335: {
      lat: 144.624896,
      lng: -37.73029166,
      city: 'Thornhill Park',
      state: 'VIC',
    },
    3336: {
      lat: 144.7126219,
      lng: -37.70482231,
      city: 'Fraser Rise',
      state: 'VIC',
    },
    3337: {
      lat: 144.571458,
      lng: -37.656057,
      city: 'Toolern Vale',
      state: 'VIC',
    },
    3338: {
      lat: 144.5683367,
      lng: -37.72870467,
      city: 'Weir Views',
      state: 'VIC',
    },
    3340: {
      lat: 144.2550519,
      lng: -37.84038931,
      city: 'Staughton Vale',
      state: 'VIC',
    },
    3341: {
      lat: 144.308232,
      lng: -37.573711,
      city: 'Pentland Hills',
      state: 'VIC',
    },
    3342: {
      lat: 144.239102,
      lng: -37.651463,
      city: 'Mount Wallace',
      state: 'VIC',
    },
    3345: {
      lat: 144.123664,
      lng: -37.5878,
      city: 'Gordon',
      state: 'VIC',
    },
    3350: {
      lat: 143.864505,
      lng: -37.571941,
      city: 'Sovereign Hill',
      state: 'VIC',
    },
    3351: {
      lat: 143.637463,
      lng: -37.758248,
      city: 'Westmere',
      state: 'VIC',
    },
    3352: {
      lat: 143.662387,
      lng: -37.482132,
      city: 'Yendon',
      state: 'VIC',
    },
    3353: {
      lat: 144.835743,
      lng: -37.7778,
      city: 'Ballarat',
      state: 'VIC',
    },
    3354: {
      lat: 143.867158,
      lng: -37.560917,
      city: 'Ballarat Mc',
      state: 'VIC',
    },
    3355: {
      lat: 143.82561,
      lng: -37.530285,
      city: 'Wendouree Village',
      state: 'VIC',
    },
    3356: {
      lat: 143.82846,
      lng: -37.592283,
      city: 'Sebastopol',
      state: 'VIC',
    },
    3357: {
      lat: 143.883416,
      lng: -37.653945,
      city: 'Yarrowee',
      state: 'VIC',
    },
    3358: {
      lat: 143.7928013,
      lng: -37.58012926,
      city: 'Winter Valley',
      state: 'VIC',
    },
    3360: {
      lat: 143.51253,
      lng: -37.779677,
      city: 'Willowvale',
      state: 'VIC',
    },
    3361: {
      lat: 143.373089,
      lng: -37.752643,
      city: 'Skipton',
      state: 'VIC',
    },
    3363: {
      lat: 143.88637,
      lng: -37.420588,
      city: 'Tourello',
      state: 'VIC',
    },
    3364: {
      lat: 143.907186,
      lng: -37.334655,
      city: 'Werona',
      state: 'VIC',
    },
    3370: {
      lat: 143.8880006,
      lng: -37.27629787,
      city: 'Ullina',
      state: 'VIC',
    },
    3371: {
      lat: 143.635622,
      lng: -37.196938,
      city: 'Talbot',
      state: 'VIC',
    },
    3373: {
      lat: 143.343429,
      lng: -37.457785,
      city: 'Waterloo',
      state: 'VIC',
    },
    3374: {
      lat: 142.956916,
      lng: -37.231524,
      city: 'Great Western',
      state: 'VIC',
    },
    3375: {
      lat: 143.143621,
      lng: -37.424288,
      city: 'Middle Creek',
      state: 'VIC',
    },
    3377: {
      lat: 142.956916,
      lng: -37.231524,
      city: 'Warrak',
      state: 'VIC',
    },
    3378: {
      lat: 142.985403,
      lng: -37.528792,
      city: 'Yalla-y-poora',
      state: 'VIC',
    },
    3379: {
      lat: 142.521439,
      lng: -37.534037,
      city: 'Willaura North',
      state: 'VIC',
    },
    3380: {
      lat: 142.771159,
      lng: -37.0666,
      city: 'Winjallok',
      state: 'VIC',
    },
    3381: {
      lat: 142.7154,
      lng: -36.988914,
      city: 'Wallaloo East',
      state: 'VIC',
    },
    3384: {
      lat: 143.126344,
      lng: -37.000261,
      city: 'Wattle Creek',
      state: 'VIC',
    },
    3385: {
      lat: 142.616484,
      lng: -36.81909176,
      city: 'Wal Wal',
      state: 'VIC',
    },
    3387: {
      lat: 142.9089779,
      lng: -36.80559338,
      city: 'Wallaloo East',
      state: 'VIC',
    },
    3388: {
      lat: 142.693946,
      lng: -36.597861,
      city: 'Rupanyup',
      state: 'VIC',
    },
    3390: {
      lat: 142.487468,
      lng: -36.583744,
      city: 'Murtoa',
      state: 'VIC',
    },
    3391: {
      lat: 142.446015,
      lng: -36.092132,
      city: 'Brim',
      state: 'VIC',
    },
    3392: {
      lat: 142.677858,
      lng: -36.430131,
      city: 'Sheep Hills',
      state: 'VIC',
    },
    3393: {
      lat: 142.387341,
      lng: -36.257725,
      city: 'Willenabrina',
      state: 'VIC',
    },
    3395: {
      lat: 142.4214713,
      lng: -35.83347402,
      city: 'Rosebery',
      state: 'VIC',
    },
    3396: {
      lat: 142.329159,
      lng: -35.7157,
      city: 'Yarto',
      state: 'VIC',
    },
    3399: {
      lat: 142.357023,
      lng: -36.608521,
      city: 'Longerenong',
      state: 'VIC',
    },
    3400: {
      lat: 142.203729,
      lng: -36.725245,
      city: 'Wonwondah South',
      state: 'VIC',
    },
    3401: {
      lat: 141.940832,
      lng: -36.881534,
      city: 'Zumsteins',
      state: 'VIC',
    },
    3402: {
      lat: 142.666302,
      lng: -37.164183,
      city: 'Horsham',
      state: 'VIC',
    },
    3407: {
      lat: 141.948371,
      lng: -37.298061,
      city: 'Vasey',
      state: 'VIC',
    },
    3409: {
      lat: 141.570286,
      lng: -37.02197072,
      city: 'Wombelano',
      state: 'VIC',
    },
    3412: {
      lat: 141.366071,
      lng: -36.658804,
      city: 'Goroke',
      state: 'VIC',
    },
    3413: {
      lat: 141.201068,
      lng: -36.699331,
      city: 'Peronne',
      state: 'VIC',
    },
    3414: {
      lat: 142.093883,
      lng: -36.388628,
      city: 'Tarranyurk',
      state: 'VIC',
    },
    3415: {
      lat: 141.339702,
      lng: -36.352459,
      city: 'Yarrunga',
      state: 'VIC',
    },
    3418: {
      lat: 141.538074,
      lng: -36.074144,
      city: 'Yanac',
      state: 'VIC',
    },
    3419: {
      lat: 141.101929,
      lng: -36.226015,
      city: 'Kaniva',
      state: 'VIC',
    },
    3420: {
      lat: 141.017332,
      lng: -36.41273,
      city: 'Telopea Downs',
      state: 'VIC',
    },
    3423: {
      lat: 141.940764,
      lng: -36.13191,
      city: 'Lake Hindmarsh',
      state: 'VIC',
    },
    3424: {
      lat: 141.930872,
      lng: -35.727789,
      city: 'Yaapeet',
      state: 'VIC',
    },
    3427: {
      lat: 144.707974,
      lng: -37.643461,
      city: 'Plumpton',
      state: 'VIC',
    },
    3428: {
      lat: 144.811494,
      lng: -37.591069,
      city: 'Bulla',
      state: 'VIC',
    },
    3429: {
      lat: 144.728973,
      lng: -37.56762,
      city: 'Wildwood',
      state: 'VIC',
    },
    3430: {
      lat: 144.806632,
      lng: -37.494056,
      city: 'Clarkefield',
      state: 'VIC',
    },
    3431: {
      lat: 144.69202,
      lng: -37.474551,
      city: 'Riddells Creek',
      state: 'VIC',
    },
    3432: {
      lat: 144.792581,
      lng: -37.448455,
      city: 'Bolinda',
      state: 'VIC',
    },
    3433: {
      lat: 144.752095,
      lng: -37.413302,
      city: 'Monegeetta',
      state: 'VIC',
    },
    3434: {
      lat: 144.750796,
      lng: -37.35388,
      city: 'Springfield',
      state: 'VIC',
    },
    3435: {
      lat: 144.743358,
      lng: -37.179453,
      city: 'Nulla Vale',
      state: 'VIC',
    },
    3437: {
      lat: 144.513018,
      lng: -37.501619,
      city: 'Gisborne South',
      state: 'VIC',
    },
    3438: {
      lat: 144.602032,
      lng: -37.457528,
      city: 'New Gisborne',
      state: 'VIC',
    },
    3440: {
      lat: 144.546151,
      lng: -37.413377,
      city: 'Macedon',
      state: 'VIC',
    },
    3441: {
      lat: 144.603892,
      lng: -37.399224,
      city: 'Mount Macedon',
      state: 'VIC',
    },
    3442: {
      lat: 144.548846,
      lng: -37.330661,
      city: 'Woodend North',
      state: 'VIC',
    },
    3444: {
      lat: 144.544716,
      lng: -37.171788,
      city: 'Tylden South',
      state: 'VIC',
    },
    3446: {
      lat: 144.374338,
      lng: -37.184831,
      city: 'Malmsbury',
      state: 'VIC',
    },
    3447: {
      lat: 144.322487,
      lng: -37.157744,
      city: 'Taradale',
      state: 'VIC',
    },
    3448: {
      lat: 144.387629,
      lng: -37.075364,
      city: 'Sutton Grange',
      state: 'VIC',
    },
    3450: {
      lat: 144.214903,
      lng: -37.061635,
      city: 'Moonlight Flat',
      state: 'VIC',
    },
    3451: {
      lat: 144.21419,
      lng: -37.125338,
      city: 'Yapeen',
      state: 'VIC',
    },
    3453: {
      lat: 144.281488,
      lng: -36.950365,
      city: 'Ravenswood South',
      state: 'VIC',
    },
    3458: {
      lat: 144.33471,
      lng: -37.424116,
      city: 'Trentham East',
      state: 'VIC',
    },
    3460: {
      lat: 144.144216,
      lng: -37.351704,
      city: 'Daylesford',
      state: 'VIC',
    },
    3461: {
      lat: 144.170995,
      lng: -37.287848,
      city: 'Yandoit Hills',
      state: 'VIC',
    },
    3462: {
      lat: 144.053611,
      lng: -37.083561,
      city: 'Welshmans Reef',
      state: 'VIC',
    },
    3463: {
      lat: 144.05651,
      lng: -36.957983,
      city: 'Woodstock West',
      state: 'VIC',
    },
    3464: {
      lat: 143.841414,
      lng: -37.019811,
      city: 'Carisbrook',
      state: 'VIC',
    },
    3465: {
      lat: 143.611335,
      lng: -37.021478,
      city: 'Wareek',
      state: 'VIC',
    },
    3467: {
      lat: 143.412675,
      lng: -37.105993,
      city: 'Moyreisk',
      state: 'VIC',
    },
    3468: {
      lat: 143.379493,
      lng: -37.261481,
      city: 'Mount Lonarch',
      state: 'VIC',
    },
    3469: {
      lat: 143.280627,
      lng: -37.172964,
      city: 'Nowhere Creek',
      state: 'VIC',
    },
    3472: {
      lat: 143.657638,
      lng: -36.864952,
      city: 'Mount Hooghly',
      state: 'VIC',
    },
    3475: {
      lat: 143.51213,
      lng: -36.70633,
      city: 'Logan',
      state: 'VIC',
    },
    3477: {
      lat: 142.9166939,
      lng: -36.58743626,
      city: 'York Plains',
      state: 'VIC',
    },
    3478: {
      lat: 143.230699,
      lng: -36.688551,
      city: 'Yawong Hills',
      state: 'VIC',
    },
    3480: {
      lat: 142.968057,
      lng: -36.31227,
      city: 'Swanwater West',
      state: 'VIC',
    },
    3482: {
      lat: 142.766588,
      lng: -36.17028,
      city: 'Watchem West',
      state: 'VIC',
    },
    3483: {
      lat: 142.890804,
      lng: -35.901765,
      city: 'Whirily',
      state: 'VIC',
    },
    3485: {
      lat: 142.671962,
      lng: -35.711533,
      city: 'Woomelang',
      state: 'VIC',
    },
    3487: {
      lat: 142.616079,
      lng: -35.515627,
      city: 'Lascelles',
      state: 'VIC',
    },
    3488: {
      lat: 142.427118,
      lng: -35.435634,
      city: 'Turriff East',
      state: 'VIC',
    },
    3489: {
      lat: 142.425302,
      lng: -35.332477,
      city: 'Tempy',
      state: 'VIC',
    },
    3490: {
      lat: 142.206711,
      lng: -34.911674,
      city: 'Tutye',
      state: 'VIC',
    },
    3491: {
      lat: 142.218273,
      lng: -35.434099,
      city: 'Patchewollock',
      state: 'VIC',
    },
    3494: {
      lat: 142.348315,
      lng: -34.486406,
      city: 'Nangiloc',
      state: 'VIC',
    },
    3496: {
      lat: 141.388479,
      lng: -34.386949,
      city: 'Werrimull',
      state: 'VIC',
    },
    3498: {
      lat: 142.172516,
      lng: -34.238385,
      city: 'Irymple',
      state: 'VIC',
    },
    3500: {
      lat: 142.154161,
      lng: -34.1931,
      city: 'Paringi',
      state: 'NSW',
    },
    3501: {
      lat: 142.100896,
      lng: -34.243279,
      city: 'Nichols Point',
      state: 'VIC',
    },
    3502: {
      lat: 145.25835,
      lng: -37.972887,
      city: 'Mildura',
      state: 'VIC',
    },
    3505: {
      lat: 142.004192,
      lng: -34.180532,
      city: 'Yelta',
      state: 'VIC',
    },
    3506: {
      lat: 141.385876,
      lng: -35.204199,
      city: 'Cowangie',
      state: 'VIC',
    },
    3507: {
      lat: 142.035659,
      lng: -35.120596,
      city: 'Walpeup',
      state: 'VIC',
    },
    3509: {
      lat: 141.680385,
      lng: -35.155031,
      city: 'Underbool',
      state: 'VIC',
    },
    3512: {
      lat: 141.0200955,
      lng: -35.12099315,
      city: 'Panitya',
      state: 'VIC',
    },
    3515: {
      lat: 144.062915,
      lng: -36.729444,
      city: 'Wilsons Hill',
      state: 'VIC',
    },
    3516: {
      lat: 144.041722,
      lng: -36.607616,
      city: 'Yarraberb',
      state: 'VIC',
    },
    3517: {
      lat: 143.886832,
      lng: -36.483116,
      city: 'Serpentine',
      state: 'VIC',
    },
    3518: {
      lat: 143.586215,
      lng: -36.474937,
      city: 'Woosang',
      state: 'VIC',
    },
    3520: {
      lat: 143.810786,
      lng: -36.405771,
      city: 'South Kinypanial',
      state: 'VIC',
    },
    3521: {
      lat: 144.912693,
      lng: -37.090071,
      city: 'Pyalong',
      state: 'VIC',
    },
    3522: {
      lat: 144.747197,
      lng: -37.050037,
      city: 'Tooborac',
      state: 'VIC',
    },
    3523: {
      lat: 144.768359,
      lng: -36.837461,
      city: 'Redcastle',
      state: 'VIC',
    },
    3525: {
      lat: 143.364316,
      lng: -36.2835,
      city: 'Yeungroon East',
      state: 'VIC',
    },
    3527: {
      lat: 143.241334,
      lng: -36.07096,
      city: 'Wycheproof South',
      state: 'VIC',
    },
    3529: {
      lat: 143.232677,
      lng: -35.824259,
      city: 'Nullawil',
      state: 'VIC',
    },
    3530: {
      lat: 143.152747,
      lng: -35.701439,
      city: 'Warne',
      state: 'VIC',
    },
    3531: {
      lat: 142.943814,
      lng: -35.616408,
      city: 'Springfield',
      state: 'VIC',
    },
    3533: {
      lat: 142.839304,
      lng: -35.384557,
      city: 'Tyrrell Downs',
      state: 'VIC',
    },
    3537: {
      lat: 143.744455,
      lng: -36.079335,
      city: 'Yando',
      state: 'VIC',
    },
    3540: {
      lat: 143.45008,
      lng: -35.89277,
      city: 'Quambatook',
      state: 'VIC',
    },
    3542: {
      lat: 143.406398,
      lng: -35.700788,
      city: 'Titybong',
      state: 'VIC',
    },
    3544: {
      lat: 143.14445,
      lng: -35.487989,
      city: 'Waitchie',
      state: 'VIC',
    },
    3546: {
      lat: 142.850456,
      lng: -35.060163,
      city: 'Winnambool',
      state: 'VIC',
    },
    3549: {
      lat: 142.820973,
      lng: -34.766049,
      city: 'Wemen',
      state: 'VIC',
    },
    3550: {
      lat: 144.285267,
      lng: -36.764269,
      city: 'White Hills',
      state: 'VIC',
    },
    3551: {
      lat: 144.457937,
      lng: -36.78137,
      city: 'Woodstock On Loddon',
      state: 'VIC',
    },
    3552: {
      lat: 145.011924,
      lng: -37.825288,
      city: 'Bendigo',
      state: 'VIC',
    },
    3554: {
      lat: 144.279,
      lng: -36.757,
      city: 'Bendigo Dc',
      state: 'VIC',
    },
    3555: {
      lat: 144.245239,
      lng: -36.777226,
      city: 'Specimen Hill',
      state: 'VIC',
    },
    3556: {
      lat: 144.178282,
      lng: -36.656422,
      city: 'Woodvale',
      state: 'VIC',
    },
    3557: {
      lat: 144.576787,
      lng: -36.63511,
      city: 'Muskerry East',
      state: 'VIC',
    },
    3558: {
      lat: 144.6702395,
      lng: -36.54452949,
      city: 'Runnymede',
      state: 'VIC',
    },
    3559: {
      lat: 144.805307,
      lng: -36.570077,
      city: 'Runnymede',
      state: 'VIC',
    },
    3561: {
      lat: 144.709543,
      lng: -36.321109,
      city: 'Timmering',
      state: 'VIC',
    },
    3562: {
      lat: 144.46276,
      lng: -36.062544,
      city: 'Torrumbarry',
      state: 'VIC',
    },
    3563: {
      lat: 144.515589,
      lng: -36.256251,
      city: 'Lockington',
      state: 'VIC',
    },
    3564: {
      lat: 144.728806,
      lng: -36.168997,
      city: 'Wharparilla',
      state: 'VIC',
    },
    3565: {
      lat: 144.511713,
      lng: -36.182336,
      city: 'Kotta',
      state: 'VIC',
    },
    3566: {
      lat: 144.383334,
      lng: -35.945748,
      city: 'Gunbower',
      state: 'VIC',
    },
    3567: {
      lat: 144.262942,
      lng: -35.931669,
      city: 'Leitchville',
      state: 'VIC',
    },
    3568: {
      lat: 144.184089,
      lng: -35.809462,
      city: 'Wee Wee Rup',
      state: 'VIC',
    },
    3570: {
      lat: 144.28053,
      lng: -36.515303,
      city: 'Summerfield',
      state: 'VIC',
    },
    3571: {
      lat: 144.206865,
      lng: -36.36819,
      city: 'Yallook',
      state: 'VIC',
    },
    3572: {
      lat: 144.257135,
      lng: -36.266642,
      city: 'Tennyson',
      state: 'VIC',
    },
    3573: {
      lat: 144.297958,
      lng: -36.164865,
      city: 'Terrick Terrick East',
      state: 'VIC',
    },
    3575: {
      lat: 144.159028,
      lng: -36.055725,
      city: 'Yarrawalla',
      state: 'VIC',
    },
    3576: {
      lat: 143.917515,
      lng: -36.128029,
      city: 'Durham Ox',
      state: 'VIC',
    },
    3579: {
      lat: 143.769445,
      lng: -35.767974,
      city: 'Westby',
      state: 'VIC',
    },
    3580: {
      lat: 144.094672,
      lng: -35.639761,
      city: 'Koondrook',
      state: 'VIC',
    },
    3581: {
      lat: 143.78521,
      lng: -35.628333,
      city: 'Lake Charm',
      state: 'VIC',
    },
    3583: {
      lat: 143.66458,
      lng: -35.509635,
      city: 'Tresco',
      state: 'VIC',
    },
    3584: {
      lat: 143.637617,
      lng: -35.431026,
      city: 'Tresco West',
      state: 'VIC',
    },
    3585: {
      lat: 143.336906,
      lng: -35.348388,
      city: 'Winlaton',
      state: 'VIC',
    },
    3586: {
      lat: 143.792594,
      lng: -35.114021,
      city: 'Ultima East',
      state: 'VIC',
    },
    3588: {
      lat: 143.470325,
      lng: -35.29429,
      city: 'Woorinen South',
      state: 'VIC',
    },
    3589: {
      lat: 143.441248,
      lng: -35.228436,
      city: 'Woorinen North',
      state: 'VIC',
    },
    3590: {
      lat: 143.493525,
      lng: -35.235708,
      city: 'Beverford',
      state: 'VIC',
    },
    3591: {
      lat: 143.394092,
      lng: -35.202209,
      city: 'Vinifera',
      state: 'VIC',
    },
    3594: {
      lat: 143.346028,
      lng: -35.138155,
      city: 'Nyah',
      state: 'VIC',
    },
    3595: {
      lat: 143.351682,
      lng: -35.202752,
      city: 'Nyah West',
      state: 'VIC',
    },
    3596: {
      lat: 143.327682,
      lng: -35.123806,
      city: 'Wood Wood',
      state: 'VIC',
    },
    3597: {
      lat: 143.189786,
      lng: -34.965359,
      city: 'Piangil',
      state: 'VIC',
    },
    3599: {
      lat: 143.143231,
      lng: -34.729742,
      city: 'Boundary Bend',
      state: 'VIC',
    },
    3607: {
      lat: 145.179762,
      lng: -36.839707,
      city: 'Tabilk',
      state: 'VIC',
    },
    3608: {
      lat: 145.095753,
      lng: -36.740713,
      city: 'Wirrate',
      state: 'VIC',
    },
    3610: {
      lat: 145.229349,
      lng: -36.577628,
      city: 'Murchison North',
      state: 'VIC',
    },
    3612: {
      lat: 145.019312,
      lng: -36.575936,
      city: 'Whroo',
      state: 'VIC',
    },
    3614: {
      lat: 145.2688853,
      lng: -36.48360219,
      city: 'Toolamba West',
      state: 'VIC',
    },
    3616: {
      lat: 145.068314,
      lng: -36.49176389,
      city: 'Waranga',
      state: 'VIC',
    },
    3617: {
      lat: 145.178369,
      lng: -36.423823,
      city: 'Byrneside',
      state: 'VIC',
    },
    3618: {
      lat: 145.125014,
      lng: -36.37708,
      city: 'Merrigum',
      state: 'VIC',
    },
    3619: {
      lat: 144.936295,
      lng: -37.659566,
      city: 'Kyabram',
      state: 'VIC',
    },
    3620: {
      lat: 145.082735,
      lng: -36.284584,
      city: 'Wyuna East',
      state: 'VIC',
    },
    3621: {
      lat: 144.961183,
      lng: -36.206055,
      city: 'Yambuna',
      state: 'VIC',
    },
    3622: {
      lat: 144.87573,
      lng: -36.231961,
      city: 'Strathallan',
      state: 'VIC',
    },
    3623: {
      lat: 144.976813,
      lng: -36.461381,
      city: 'Stanhope South',
      state: 'VIC',
    },
    3624: {
      lat: 144.965763,
      lng: -36.410186,
      city: 'Girgarre',
      state: 'VIC',
    },
    3629: {
      lat: 145.260653,
      lng: -36.287306,
      city: 'Undera',
      state: 'VIC',
    },
    3630: {
      lat: 145.403176,
      lng: -36.380853,
      city: 'Shepparton South',
      state: 'VIC',
    },
    3631: {
      lat: 145.526693,
      lng: -36.428694,
      city: 'Tamleugh West',
      state: 'VIC',
    },
    3632: {
      lat: 145.603242,
      lng: -36.544462,
      city: 'Shepparton',
      state: 'VIC',
    },
    3633: {
      lat: 145.46296,
      lng: -36.307936,
      city: 'Congupna',
      state: 'VIC',
    },
    3634: {
      lat: 145.440315,
      lng: -36.224186,
      city: 'Zeerust',
      state: 'VIC',
    },
    3635: {
      lat: 145.30827,
      lng: -36.142596,
      city: 'Wunghnu',
      state: 'VIC',
    },
    3636: {
      lat: 145.479471,
      lng: -36.093878,
      city: 'Numurkah',
      state: 'VIC',
    },
    3637: {
      lat: 145.329026,
      lng: -36.050686,
      city: 'Yalca',
      state: 'VIC',
    },
    3638: {
      lat: 145.246572,
      lng: -36.03202,
      city: 'Yielima',
      state: 'VIC',
    },
    3639: {
      lat: 145.047253,
      lng: -36.021295,
      city: 'Picola West',
      state: 'VIC',
    },
    3640: {
      lat: 145.486015,
      lng: -36.00978,
      city: 'Katunga',
      state: 'VIC',
    },
    3641: {
      lat: 145.428306,
      lng: -35.89718,
      city: 'Ulupna',
      state: 'VIC',
    },
    3643: {
      lat: 145.631874,
      lng: -35.955363,
      city: 'Cobram',
      state: 'VIC',
    },
    3644: {
      lat: 145.632682,
      lng: -35.945353,
      city: 'Yarroweyah',
      state: 'VIC',
    },
    3646: {
      lat: 145.704042,
      lng: -36.247446,
      city: 'Youanmite',
      state: 'VIC',
    },
    3647: {
      lat: 145.704473,
      lng: -36.385331,
      city: 'Dookie College',
      state: 'VIC',
    },
    3649: {
      lat: 145.688671,
      lng: -36.082665,
      city: 'Katamatite East',
      state: 'VIC',
    },
    3658: {
      lat: 145.111854,
      lng: -37.241401,
      city: 'Waterford Park',
      state: 'VIC',
    },
    3659: {
      lat: 145.07088,
      lng: -37.110344,
      city: 'Tallarook',
      state: 'VIC',
    },
    3660: {
      lat: 145.257867,
      lng: -37.035925,
      city: 'Whiteheads Creek',
      state: 'VIC',
    },
    3661: {
      lat: 146.535346,
      lng: -38.195993,
      city: 'Seymour',
      state: 'VIC',
    },
    3662: {
      lat: 144.960214,
      lng: -36.939204,
      city: 'Puckapunyal Milpo',
      state: 'VIC',
    },
    3663: {
      lat: 145.158863,
      lng: -36.910878,
      city: 'Mangalore',
      state: 'VIC',
    },
    3664: {
      lat: 145.4034627,
      lng: -36.89547953,
      city: 'Upton Hill',
      state: 'VIC',
    },
    3665: {
      lat: 145.357627,
      lng: -36.844091,
      city: 'Pranjip',
      state: 'VIC',
    },
    3666: {
      lat: 145.565512,
      lng: -36.786777,
      city: 'Tarcombe',
      state: 'VIC',
    },
    3669: {
      lat: 145.708668,
      lng: -36.625333,
      city: 'Violet Town',
      state: 'VIC',
    },
    3670: {
      lat: 145.852449,
      lng: -36.623748,
      city: 'Warrenbayne',
      state: 'VIC',
    },
    3671: {
      lat: 145.420691,
      lng: -36.385144,
      city: 'Benalla',
      state: 'VIC',
    },
    3672: {
      lat: 145.977761,
      lng: -36.554936,
      city: 'Benalla West',
      state: 'VIC',
    },
    3673: {
      lat: 146.062514,
      lng: -36.669929,
      city: 'Winton North',
      state: 'VIC',
    },
    3675: {
      lat: 146.266835,
      lng: -36.565078,
      city: 'Taminick',
      state: 'VIC',
    },
    3676: {
      lat: 146.338612,
      lng: -36.341245,
      city: 'Wangaratta',
      state: 'VIC',
    },
    3677: {
      lat: 146.330564,
      lng: -36.358017,
      city: 'Yarrunga',
      state: 'VIC',
    },
    3678: {
      lat: 146.482439,
      lng: -36.68892,
      city: 'Whitlands',
      state: 'VIC',
    },
    3682: {
      lat: 146.428656,
      lng: -36.174271,
      city: 'Springhurst',
      state: 'VIC',
    },
    3683: {
      lat: 146.604622,
      lng: -36.180787,
      city: 'Indigo',
      state: 'VIC',
    },
    3685: {
      lat: 146.470638,
      lng: -36.056449,
      city: 'Rutherglen',
      state: 'VIC',
    },
    3687: {
      lat: 146.415927,
      lng: -36.018771,
      city: 'Wahgunyah',
      state: 'VIC',
    },
    3688: {
      lat: 146.717193,
      lng: -36.119195,
      city: 'Indigo Valley',
      state: 'VIC',
    },
    3689: {
      lat: 144.989793,
      lng: -37.67576,
      city: 'Wodonga',
      state: 'VIC',
    },
    3690: {
      lat: 146.864361,
      lng: -36.135717,
      city: 'Wodonga Plaza',
      state: 'VIC',
    },
    3691: {
      lat: 147.880991,
      lng: -36.027154,
      city: 'Wodonga Forward',
      state: 'VIC',
    },
    3694: {
      lat: 146.919493,
      lng: -36.150678,
      city: 'Bandiana Milpo',
      state: 'VIC',
    },
    3695: {
      lat: 147.123674,
      lng: -36.304285,
      city: 'Sandy Creek',
      state: 'VIC',
    },
    3697: {
      lat: 147.17925,
      lng: -36.68753,
      city: 'Tawonga',
      state: 'VIC',
    },
    3698: {
      lat: 147.149468,
      lng: -36.722034,
      city: 'Tawonga South',
      state: 'VIC',
    },
    3699: {
      lat: 147.3656102,
      lng: -36.83390754,
      city: 'Nelse',
      state: 'VIC',
    },
    3700: {
      lat: 147.35527,
      lng: -36.335898,
      city: 'Tallangatta East',
      state: 'VIC',
    },
    3701: {
      lat: 147.476819,
      lng: -36.638769,
      city: 'Tallangatta Valley',
      state: 'VIC',
    },
    3704: {
      lat: 147.49344,
      lng: -36.132613,
      city: 'Koetong',
      state: 'VIC',
    },
    3705: {
      lat: 147.769911,
      lng: -36.322884,
      city: 'Nariel Valley',
      state: 'VIC',
    },
    3707: {
      lat: 148.037958,
      lng: -36.586316,
      city: 'Towong Upper',
      state: 'VIC',
    },
    3708: {
      lat: 147.942058,
      lng: -36.070928,
      city: 'Tintaldra',
      state: 'VIC',
    },
    3709: {
      lat: 147.667373,
      lng: -36.035908,
      city: 'Walwa',
      state: 'VIC',
    },
    3711: {
      lat: 145.682161,
      lng: -37.41047,
      city: 'Buxton',
      state: 'VIC',
    },
    3712: {
      lat: 145.839057,
      lng: -37.3209,
      city: 'Thornton',
      state: 'VIC',
    },
    3713: {
      lat: 145.880225,
      lng: -37.20794843,
      city: 'Taylor Bay',
      state: 'VIC',
    },
    3714: {
      lat: 145.6196525,
      lng: -37.2189348,
      city: 'Whanregarwen',
      state: 'VIC',
    },
    3715: {
      lat: 145.739419,
      lng: -36.985747,
      city: 'Woodfield',
      state: 'VIC',
    },
    3717: {
      lat: 145.435776,
      lng: -37.286611,
      city: 'Yea',
      state: 'VIC',
    },
    3718: {
      lat: 145.54096,
      lng: -37.144067,
      city: 'Molesworth',
      state: 'VIC',
    },
    3719: {
      lat: 145.608491,
      lng: -37.041215,
      city: 'Yarck',
      state: 'VIC',
    },
    3720: {
      lat: 145.850304,
      lng: -37.017241,
      city: 'Bonnie Doon',
      state: 'VIC',
    },
    3722: {
      lat: 146.253268,
      lng: -37.202634,
      city: 'Mirimbah',
      state: 'VIC',
    },
    3723: {
      lat: 146.256507,
      lng: -36.894465,
      city: 'Woods Point',
      state: 'VIC',
    },
    3724: {
      lat: 146.076832,
      lng: -37.053108,
      city: 'Mansfield',
      state: 'VIC',
    },
    3725: {
      lat: 145.937326,
      lng: -36.414359,
      city: 'Stewarton',
      state: 'VIC',
    },
    3726: {
      lat: 145.960209,
      lng: -36.332254,
      city: 'Thoona',
      state: 'VIC',
    },
    3727: {
      lat: 145.957776,
      lng: -36.242443,
      city: 'Yundool',
      state: 'VIC',
    },
    3728: {
      lat: 145.959015,
      lng: -36.148922,
      city: 'Youarang',
      state: 'VIC',
    },
    3730: {
      lat: 145.974755,
      lng: -36.051782,
      city: 'Yarrawonga South',
      state: 'VIC',
    },
    3732: {
      lat: 146.313751,
      lng: -36.686814,
      city: 'Myrrhee',
      state: 'VIC',
    },
    3733: {
      lat: 146.316338,
      lng: -36.812087,
      city: 'Whitfield',
      state: 'VIC',
    },
    3735: {
      lat: 146.603574,
      lng: -36.515712,
      city: 'Whorouly South',
      state: 'VIC',
    },
    3736: {
      lat: 146.479489,
      lng: -36.455136,
      city: 'Myrtleford',
      state: 'VIC',
    },
    3737: {
      lat: 146.8156764,
      lng: -37.13823976,
      city: 'Wonnangatta',
      state: 'VIC',
    },
    3738: {
      lat: 146.75397,
      lng: -36.587922,
      city: 'Ovens',
      state: 'VIC',
    },
    3739: {
      lat: 146.864194,
      lng: -36.641016,
      city: 'Eurobin',
      state: 'VIC',
    },
    3740: {
      lat: 146.834561,
      lng: -36.731686,
      city: 'Porepunkah',
      state: 'VIC',
    },
    3741: {
      lat: 147.063163,
      lng: -36.962316,
      city: 'Smoko',
      state: 'VIC',
    },
    3744: {
      lat: 146.95979,
      lng: -36.788479,
      city: 'Wandiligong',
      state: 'VIC',
    },
    3746: {
      lat: 146.51767,
      lng: -36.31396,
      city: 'Eldorado',
      state: 'VIC',
    },
    3747: {
      lat: 146.725705,
      lng: -36.361056,
      city: 'Wooragee',
      state: 'VIC',
    },
    3749: {
      lat: 146.842075,
      lng: -36.357399,
      city: 'Yackandandah',
      state: 'VIC',
    },
    3750: {
      lat: 145.005578,
      lng: -37.596078,
      city: 'Wollert',
      state: 'VIC',
    },
    3751: {
      lat: 145.052904,
      lng: -37.554655,
      city: 'Woodstock',
      state: 'VIC',
    },
    3752: {
      lat: 145.074353,
      lng: -37.619102,
      city: 'South Morang',
      state: 'VIC',
    },
    3753: {
      lat: 144.959512,
      lng: -37.476474,
      city: 'Beveridge',
      state: 'VIC',
    },
    3754: {
      lat: 145.112985,
      lng: -37.593554,
      city: 'Mernda',
      state: 'VIC',
    },
    3755: {
      lat: 145.142035,
      lng: -37.563905,
      city: 'Yan Yean',
      state: 'VIC',
    },
    3756: {
      lat: 144.956641,
      lng: -37.41233,
      city: 'Wallan East',
      state: 'VIC',
    },
    3757: {
      lat: 145.182221,
      lng: -37.469784,
      city: 'Whittlesea',
      state: 'VIC',
    },
    3758: {
      lat: 145.052552,
      lng: -37.354647,
      city: 'Wandong',
      state: 'VIC',
    },
    3759: {
      lat: 145.247277,
      lng: -37.650385,
      city: 'Panton Hill',
      state: 'VIC',
    },
    3760: {
      lat: 145.288344,
      lng: -37.6269998,
      city: 'Smiths Gully',
      state: 'VIC',
    },
    3761: {
      lat: 145.292381,
      lng: -37.585625,
      city: 'St Andrews',
      state: 'VIC',
    },
    3762: {
      lat: 144.936707,
      lng: -37.354143,
      city: 'Bylands',
      state: 'VIC',
    },
    3763: {
      lat: 145.353378,
      lng: -37.521863,
      city: 'Mount Slide',
      state: 'VIC',
    },
    3764: {
      lat: 144.900644,
      lng: -37.263736,
      city: 'Willowmavin',
      state: 'VIC',
    },
    3765: {
      lat: 145.346621,
      lng: -37.814771,
      city: 'Montrose',
      state: 'VIC',
    },
    3766: {
      lat: 145.384955,
      lng: -37.817818,
      city: 'Kalorama',
      state: 'VIC',
    },
    3767: {
      lat: 145.347505,
      lng: -37.839387,
      city: 'Mount Dandenong',
      state: 'VIC',
    },
    3770: {
      lat: 145.416004,
      lng: -37.708784,
      city: 'Yering',
      state: 'VIC',
    },
    3775: {
      lat: 145.365993,
      lng: -37.642852,
      city: 'Yarra Glen',
      state: 'VIC',
    },
    3777: {
      lat: 145.513804,
      lng: -37.606088,
      city: 'Toolangi',
      state: 'VIC',
    },
    3778: {
      lat: 145.623011,
      lng: -37.537492,
      city: 'Narbethong',
      state: 'VIC',
    },
    3779: {
      lat: 145.784751,
      lng: -37.539267,
      city: 'Marysville',
      state: 'VIC',
    },
    3781: {
      lat: 145.487862,
      lng: -37.938471,
      city: 'Nangana',
      state: 'VIC',
    },
    3782: {
      lat: 145.433024,
      lng: -37.938068,
      city: 'Macclesfield',
      state: 'VIC',
    },
    3783: {
      lat: 145.596576,
      lng: -37.947379,
      city: 'Gembrook',
      state: 'VIC',
    },
    3785: {
      lat: 145.316734,
      lng: -37.885138,
      city: 'Tremont',
      state: 'VIC',
    },
    3786: {
      lat: 145.332431,
      lng: -37.878188,
      city: 'Ferny Creek',
      state: 'VIC',
    },
    3787: {
      lat: 145.350888,
      lng: -37.866786,
      city: 'Sassafras Gully',
      state: 'VIC',
    },
    3788: {
      lat: 145.377128,
      lng: -37.848036,
      city: 'Olinda',
      state: 'VIC',
    },
    3789: {
      lat: 145.357336,
      lng: -37.889767,
      city: 'Sherbrooke',
      state: 'VIC',
    },
    3791: {
      lat: 145.379622,
      lng: -37.896917,
      city: 'Kallista',
      state: 'VIC',
    },
    3792: {
      lat: 145.405388,
      lng: -37.89424,
      city: 'The Patch',
      state: 'VIC',
    },
    3793: {
      lat: 145.429715,
      lng: -37.874533,
      city: 'Monbulk',
      state: 'VIC',
    },
    3795: {
      lat: 145.430311,
      lng: -37.836663,
      city: 'Silvan',
      state: 'VIC',
    },
    3796: {
      lat: 145.384922,
      lng: -37.786977,
      city: 'Mount Evelyn',
      state: 'VIC',
    },
    3797: {
      lat: 145.786343,
      lng: -37.867809,
      city: 'Yarra Junction',
      state: 'VIC',
    },
    3799: {
      lat: 145.818512,
      lng: -37.727566,
      city: 'Wesburn',
      state: 'VIC',
    },
    3800: {
      lat: 145.134935,
      lng: -37.910545,
      city: 'Monash University',
      state: 'VIC',
    },
    3802: {
      lat: 145.255599,
      lng: -37.971763,
      city: 'Endeavour Hills',
      state: 'VIC',
    },
    3803: {
      lat: 145.265524,
      lng: -38.009435,
      city: 'Hallam',
      state: 'VIC',
    },
    3804: {
      lat: 145.325994,
      lng: -37.980059,
      city: 'Narre Warren North',
      state: 'VIC',
    },
    3805: {
      lat: 145.304198,
      lng: -38.037026,
      city: 'Narre Warren South',
      state: 'VIC',
    },
    3806: {
      lat: 145.349488,
      lng: -38.025018,
      city: 'Harkaway',
      state: 'VIC',
    },
    3807: {
      lat: 145.371635,
      lng: -38.048316,
      city: 'Guys Hill',
      state: 'VIC',
    },
    3808: {
      lat: 145.42327,
      lng: -38.002518,
      city: 'Dewhurst',
      state: 'VIC',
    },
    3809: {
      lat: 145.406887,
      lng: -38.069094,
      city: 'Officer South',
      state: 'VIC',
    },
    3810: {
      lat: 145.472514,
      lng: -38.04658,
      city: 'Rythdale',
      state: 'VIC',
    },
    3812: {
      lat: 145.565304,
      lng: -38.070135,
      city: 'Nar Nar Goon North',
      state: 'VIC',
    },
    3813: {
      lat: 145.634339,
      lng: -38.064252,
      city: 'Tynong North',
      state: 'VIC',
    },
    3814: {
      lat: 145.669112,
      lng: -38.10718,
      city: 'Vervale',
      state: 'VIC',
    },
    3815: {
      lat: 145.726311,
      lng: -38.01378,
      city: 'Tonimbuk',
      state: 'VIC',
    },
    3816: {
      lat: 145.787991,
      lng: -38.043542,
      city: 'Modella',
      state: 'VIC',
    },
    3818: {
      lat: 145.867337,
      lng: -38.096289,
      city: 'Tarago',
      state: 'VIC',
    },
    3820: {
      lat: 145.922212,
      lng: -38.207476,
      city: 'Warragul',
      state: 'VIC',
    },
    3821: {
      lat: 145.945214,
      lng: -37.919937,
      city: 'Warragul West',
      state: 'VIC',
    },
    3822: {
      lat: 145.989901,
      lng: -38.194379,
      city: 'Gainsborough',
      state: 'VIC',
    },
    3823: {
      lat: 146.065656,
      lng: -38.210382,
      city: 'Yarragon South',
      state: 'VIC',
    },
    3824: {
      lat: 146.16397,
      lng: -38.245649,
      city: 'Trafalgar South',
      state: 'VIC',
    },
    3825: {
      lat: 146.342932,
      lng: -37.978862,
      city: 'Yallourn North',
      state: 'VIC',
    },
    3831: {
      lat: 146.013136,
      lng: -37.993258,
      city: 'Neerim South',
      state: 'VIC',
    },
    3832: {
      lat: 145.945214,
      lng: -37.919937,
      city: 'Neerim North',
      state: 'VIC',
    },
    3833: {
      lat: 146.077886,
      lng: -37.829347,
      city: 'Vesper',
      state: 'VIC',
    },
    3835: {
      lat: 146.175271,
      lng: -38.290637,
      city: 'Thorpdale',
      state: 'VIC',
    },
    3840: {
      lat: 146.424457,
      lng: -38.245215,
      city: 'Morwell Upper',
      state: 'VIC',
    },
    3841: {
      lat: 147.463,
      lng: -37.5832,
      city: 'Gippsland Mc',
      state: 'VIC',
    },
    3842: {
      lat: 146.414358,
      lng: -38.315452,
      city: 'Churchill',
      state: 'VIC',
    },
    3844: {
      lat: 146.607101,
      lng: -38.316869,
      city: 'Willung South',
      state: 'VIC',
    },
    3847: {
      lat: 146.8251888,
      lng: -38.32561417,
      city: 'Willung South',
      state: 'VIC',
    },
    3850: {
      lat: 147.106181,
      lng: -38.224191,
      city: 'Wurruk',
      state: 'VIC',
    },
    3851: {
      lat: 147.084398,
      lng: -38.035548,
      city: 'The Honeysuckles',
      state: 'VIC',
    },
    3852: {
      lat: 147.134356,
      lng: -38.106731,
      city: 'Sale East Raaf',
      state: 'VIC',
    },
    3853: {
      lat: 146.059953,
      lng: -38.095569,
      city: 'Sale',
      state: 'VIC',
    },
    3854: {
      lat: 146.596877,
      lng: -38.12413,
      city: 'Glengarry West',
      state: 'VIC',
    },
    3856: {
      lat: 146.672206,
      lng: -38.063907,
      city: 'Toongabbie',
      state: 'VIC',
    },
    3857: {
      lat: 146.694894,
      lng: -38.018616,
      city: 'Cowwarr',
      state: 'VIC',
    },
    3858: {
      lat: 146.5677589,
      lng: -37.82195914,
      city: 'Yangoura',
      state: 'VIC',
    },
    3859: {
      lat: 146.848259,
      lng: -37.886136,
      city: 'Tinamba West',
      state: 'VIC',
    },
    3860: {
      lat: 146.8512398,
      lng: -37.68351367,
      city: 'Wrixon',
      state: 'VIC',
    },
    3862: {
      lat: 147.0451171,
      lng: -37.25564455,
      city: 'Wongungarra',
      state: 'VIC',
    },
    3864: {
      lat: 147.341513,
      lng: -37.860878,
      city: 'The Fingerboard',
      state: 'VIC',
    },
    3865: {
      lat: 147.455946,
      lng: -37.796743,
      city: 'Lindenow',
      state: 'VIC',
    },
    3869: {
      lat: 146.359836,
      lng: -38.364689,
      city: 'Yinnar South',
      state: 'VIC',
    },
    3870: {
      lat: 146.319351,
      lng: -38.437946,
      city: 'Mirboo East',
      state: 'VIC',
    },
    3871: {
      lat: 146.158866,
      lng: -38.42207,
      city: 'Tarwin East',
      state: 'VIC',
    },
    3873: {
      lat: 146.708775,
      lng: -38.298267,
      city: 'Gormandale',
      state: 'VIC',
    },
    3874: {
      lat: 146.836248,
      lng: -38.514438,
      city: 'Woodside North',
      state: 'VIC',
    },
    3875: {
      lat: 147.63201,
      lng: -37.826032,
      city: 'Wy Yung',
      state: 'VIC',
    },
    3878: {
      lat: 147.680121,
      lng: -37.898544,
      city: 'Eagle Point',
      state: 'VIC',
    },
    3880: {
      lat: 147.727389,
      lng: -37.914728,
      city: 'Raymond Island',
      state: 'VIC',
    },
    3882: {
      lat: 147.764385,
      lng: -37.812017,
      city: 'Nicholson',
      state: 'VIC',
    },
    3885: {
      lat: 148.118511,
      lng: -37.291807,
      city: 'Yalmy',
      state: 'VIC',
    },
    3886: {
      lat: 148.426851,
      lng: -37.770977,
      city: 'Newmerella',
      state: 'VIC',
    },
    3887: {
      lat: 148.116641,
      lng: -37.757895,
      city: 'Wairewa',
      state: 'VIC',
    },
    3888: {
      lat: 148.660943,
      lng: -37.39051,
      city: 'Wombat Creek',
      state: 'VIC',
    },
    3889: {
      lat: 148.873952,
      lng: -37.610841,
      city: 'Manorina',
      state: 'VIC',
    },
    3890: {
      lat: 149.217216,
      lng: -37.566489,
      city: 'Weeragua',
      state: 'VIC',
    },
    3891: {
      lat: 149.538907,
      lng: -37.567684,
      city: 'Wroxham',
      state: 'VIC',
    },
    3892: {
      lat: 149.909868,
      lng: -37.563775,
      city: 'Mallacoota',
      state: 'VIC',
    },
    3893: {
      lat: 147.866067,
      lng: -37.495349,
      city: 'Tambo Crossing',
      state: 'VIC',
    },
    3895: {
      lat: 147.916566,
      lng: -37.40606605,
      city: 'Reedy Flat',
      state: 'VIC',
    },
    3896: {
      lat: 147.707962,
      lng: -37.264671,
      city: 'Tongio',
      state: 'VIC',
    },
    3898: {
      lat: 147.464799,
      lng: -37.066169,
      city: 'Shannonvale',
      state: 'VIC',
    },
    3900: {
      lat: 147.826143,
      lng: -36.88053,
      city: 'Uplands',
      state: 'VIC',
    },
    3902: {
      lat: 147.814894,
      lng: -37.797042,
      city: 'Johnsonville',
      state: 'VIC',
    },
    3903: {
      lat: 147.867533,
      lng: -37.811303,
      city: 'Swan Reach',
      state: 'VIC',
    },
    3904: {
      lat: 147.839367,
      lng: -37.881041,
      city: 'Metung',
      state: 'VIC',
    },
    3909: {
      lat: 147.970237,
      lng: -37.821921,
      city: 'Toorloo Arm',
      state: 'VIC',
    },
    3910: {
      lat: 145.198328,
      lng: -38.156369,
      city: 'Langwarrin',
      state: 'VIC',
    },
    3911: {
      lat: 145.169556,
      lng: -38.196908,
      city: 'Langwarrin South',
      state: 'VIC',
    },
    3912: {
      lat: 145.227373,
      lng: -38.217991,
      city: 'Somerville',
      state: 'VIC',
    },
    3913: {
      lat: 145.166426,
      lng: -38.262273,
      city: 'Tyabb',
      state: 'VIC',
    },
    3915: {
      lat: 145.136622,
      lng: -38.305806,
      city: 'Tuerong',
      state: 'VIC',
    },
    3916: {
      lat: 145.050587,
      lng: -38.415199,
      city: 'Shoreham',
      state: 'VIC',
    },
    3918: {
      lat: 145.155546,
      lng: -38.339871,
      city: 'Bittern',
      state: 'VIC',
    },
    3919: {
      lat: 145.199777,
      lng: -38.352327,
      city: 'Crib Point',
      state: 'VIC',
    },
    3920: {
      lat: 145.19304,
      lng: -38.38752,
      city: 'Hmas Cerberus',
      state: 'VIC',
    },
    3921: {
      lat: 145.367079,
      lng: -38.419488,
      city: 'Tankerton',
      state: 'VIC',
    },
    3922: {
      lat: 145.231974,
      lng: -38.482182,
      city: 'Wimbledon Heights',
      state: 'VIC',
    },
    3923: {
      lat: 145.293632,
      lng: -38.465552,
      city: 'Rhyll',
      state: 'VIC',
    },
    3925: {
      lat: 145.379683,
      lng: -38.528213,
      city: 'San Remo',
      state: 'VIC',
    },
    3926: {
      lat: 145.094502,
      lng: -38.355231,
      city: 'Merricks North',
      state: 'VIC',
    },
    3927: {
      lat: 145.153269,
      lng: -38.383368,
      city: 'Somers',
      state: 'VIC',
    },
    3928: {
      lat: 144.975131,
      lng: -38.407828,
      city: 'Main Ridge',
      state: 'VIC',
    },
    3929: {
      lat: 144.976673,
      lng: -38.460965,
      city: 'Flinders',
      state: 'VIC',
    },
    3930: {
      lat: 145.093743,
      lng: -38.195017,
      city: 'Mount Eliza',
      state: 'VIC',
    },
    3931: {
      lat: 145.049503,
      lng: -38.230038,
      city: 'Mornington',
      state: 'VIC',
    },
    3933: {
      lat: 145.096992,
      lng: -38.261168,
      city: 'Moorooduc',
      state: 'VIC',
    },
    3934: {
      lat: 145.031598,
      lng: -38.2783,
      city: 'Mount Martha',
      state: 'VIC',
    },
    3936: {
      lat: 145.007626,
      lng: -38.326447,
      city: 'Safety Beach',
      state: 'VIC',
    },
    3937: {
      lat: 145.01994,
      lng: -38.373694,
      city: 'Red Hill South',
      state: 'VIC',
    },
    3938: {
      lat: 144.931834,
      lng: -38.354321,
      city: 'Mccrae',
      state: 'VIC',
    },
    3939: {
      lat: 144.900413,
      lng: -38.42776,
      city: 'Rosebud Plaza',
      state: 'VIC',
    },
    3940: {
      lat: 144.873115,
      lng: -38.383367,
      city: 'Rosebud West',
      state: 'VIC',
    },
    3941: {
      lat: 144.827203,
      lng: -38.396077,
      city: 'Tootgarook',
      state: 'VIC',
    },
    3942: {
      lat: 144.768301,
      lng: -38.367874,
      city: 'Blairgowrie',
      state: 'VIC',
    },
    3943: {
      lat: 144.739101,
      lng: -38.345527,
      city: 'Sorrento',
      state: 'VIC',
    },
    3944: {
      lat: 144.692708,
      lng: -38.321871,
      city: 'Portsea',
      state: 'VIC',
    },
    3945: {
      lat: 145.697053,
      lng: -38.381582,
      city: 'Woodleigh',
      state: 'VIC',
    },
    3946: {
      lat: 145.723537,
      lng: -38.448155,
      city: 'Bena',
      state: 'VIC',
    },
    3950: {
      lat: 145.812945,
      lng: -38.435217,
      city: 'Whitelaw',
      state: 'VIC',
    },
    3951: {
      lat: 145.798419,
      lng: -38.47372,
      city: 'Ranceby',
      state: 'VIC',
    },
    3953: {
      lat: 146.0204631,
      lng: -38.40506851,
      city: 'Wooreen',
      state: 'VIC',
    },
    3954: {
      lat: 145.937973,
      lng: -38.550379,
      city: 'Koonwarra',
      state: 'VIC',
    },
    3956: {
      lat: 145.955341,
      lng: -38.597018,
      city: 'Walkerville South',
      state: 'VIC',
    },
    3957: {
      lat: 146.126718,
      lng: -38.589302,
      city: 'Stony Creek',
      state: 'VIC',
    },
    3958: {
      lat: 145.987059,
      lng: -38.673841,
      city: 'Buffalo Creek',
      state: 'VIC',
    },
    3959: {
      lat: 146.066529,
      lng: -38.734636,
      city: 'Waratah North',
      state: 'VIC',
    },
    3960: {
      lat: 146.360412,
      lng: -39.140761,
      city: 'Yanakie',
      state: 'VIC',
    },
    3962: {
      lat: 146.359804,
      lng: -38.60357,
      city: 'Woorarra East',
      state: 'VIC',
    },
    3964: {
      lat: 146.278804,
      lng: -38.685115,
      city: 'Port Franklin',
      state: 'VIC',
    },
    3965: {
      lat: 146.472132,
      lng: -38.693437,
      city: 'Port Welshpool',
      state: 'VIC',
    },
    3966: {
      lat: 146.436583,
      lng: -38.630568,
      city: 'Welshpool',
      state: 'VIC',
    },
    3967: {
      lat: 146.507929,
      lng: -38.647562,
      city: 'Hedley',
      state: 'VIC',
    },
    3971: {
      lat: 146.815723,
      lng: -38.631942,
      city: 'Yarram',
      state: 'VIC',
    },
    3975: {
      lat: 145.231114,
      lng: -38.05354,
      city: 'Lyndhurst',
      state: 'VIC',
    },
    3976: {
      lat: 145.264296,
      lng: -38.043995,
      city: 'Hampton Park',
      state: 'VIC',
    },
    3977: {
      lat: 145.268888,
      lng: -38.135253,
      city: 'Skye',
      state: 'VIC',
    },
    3978: {
      lat: 145.384606,
      lng: -38.135009,
      city: 'Clyde North',
      state: 'VIC',
    },
    3979: {
      lat: 145.591284,
      lng: -38.44818,
      city: 'Korrine',
      state: 'VIC',
    },
    3980: {
      lat: 145.356937,
      lng: -38.204448,
      city: 'Warneet',
      state: 'VIC',
    },
    3981: {
      lat: 145.575,
      lng: -38.207298,
      city: 'Yannathan',
      state: 'VIC',
    },
    3984: {
      lat: 145.563979,
      lng: -38.312647,
      city: 'The Gurdies',
      state: 'VIC',
    },
    3987: {
      lat: 145.689142,
      lng: -38.309048,
      city: 'Nyora',
      state: 'VIC',
    },
    3988: {
      lat: 145.811863,
      lng: -38.313447,
      city: 'Poowong North',
      state: 'VIC',
    },
    3989: {
      lat: -2.10323,
      lng: 49.1805,
      city: 'St Helier',
      state: 'VIC',
    },
    3990: {
      lat: 145.501279,
      lng: -38.455399,
      city: 'Glen Forbes',
      state: 'VIC',
    },
    3991: {
      lat: 145.466781,
      lng: -38.483041,
      city: 'Bass',
      state: 'VIC',
    },
    3992: {
      lat: 145.534315,
      lng: -38.569135,
      city: 'West Creek',
      state: 'VIC',
    },
    3995: {
      lat: 145.630713,
      lng: -38.578071,
      city: 'Woolamai',
      state: 'VIC',
    },
    3996: {
      lat: 145.745609,
      lng: -38.629561,
      city: 'Pound Creek',
      state: 'VIC',
    },
    4000: {
      lat: 153.022126,
      lng: -27.468391,
      city: 'Spring Hill',
      state: 'QLD',
    },
    4001: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Waterfront Place',
      state: 'QLD',
    },
    4002: {
      lat: 153.027,
      lng: -27.4693,
      city: 'Wintergarden',
      state: 'QLD',
    },
    4003: {
      lat: 152.348462,
      lng: -24.872431,
      city: 'George Street',
      state: 'QLD',
    },
    4004: {
      lat: 149.316593,
      lng: -24.045265,
      city: 'Spring Hill',
      state: 'QLD',
    },
    4005: {
      lat: 153.0483096,
      lng: -27.4561168,
      city: 'Teneriffe',
      state: 'QLD',
    },
    4006: {
      lat: 153.175242,
      lng: -27.36618,
      city: 'Newstead',
      state: 'QLD',
    },
    4007: {
      lat: 153.061914,
      lng: -27.436088,
      city: 'Whinstanes',
      state: 'QLD',
    },
    4008: {
      lat: 153.14232,
      lng: -27.399839,
      city: 'Pinkenba',
      state: 'QLD',
    },
    4009: {
      lat: 153.105247,
      lng: -27.405747,
      city: 'Eagle Farm Bc',
      state: 'QLD',
    },
    4010: {
      lat: 153.04354,
      lng: -27.433237,
      city: 'Breakfast Creek',
      state: 'QLD',
    },
    4011: {
      lat: 153.059749,
      lng: -27.419484,
      city: 'Hendra',
      state: 'QLD',
    },
    4012: {
      lat: 153.05355,
      lng: -27.399335,
      city: 'Wavell Heights North',
      state: 'QLD',
    },
    4013: {
      lat: 153.074982,
      lng: -27.397055,
      city: 'Northgate Mc',
      state: 'QLD',
    },
    4014: {
      lat: 153.084784,
      lng: -27.373947,
      city: 'Virginia Dc',
      state: 'QLD',
    },
    4017: {
      lat: 153.047339,
      lng: -27.318074,
      city: 'Shorncliffe',
      state: 'QLD',
    },
    4018: {
      lat: 153.031804,
      lng: -27.349074,
      city: 'Taigum',
      state: 'QLD',
    },
    4019: {
      lat: 153.088498,
      lng: -27.248337,
      city: 'Woody Point',
      state: 'QLD',
    },
    4020: {
      lat: 153.106378,
      lng: -27.214362,
      city: 'Scarborough',
      state: 'QLD',
    },
    4021: {
      lat: 153.07529,
      lng: -27.220098,
      city: 'Kippa-ring',
      state: 'QLD',
    },
    4022: {
      lat: 153.046414,
      lng: -27.211886,
      city: 'Rothwell',
      state: 'QLD',
    },
    4025: {
      lat: 153.393497,
      lng: -27.162277,
      city: 'Tangalooma',
      state: 'QLD',
    },
    4029: {
      lat: 153.026443,
      lng: -27.449336,
      city: 'Royal Brisbane Hospital',
      state: 'QLD',
    },
    4030: {
      lat: 153.029681,
      lng: -27.42687,
      city: 'Wooloowin',
      state: 'QLD',
    },
    4031: {
      lat: 153.030695,
      lng: -27.408542,
      city: 'Kedron',
      state: 'QLD',
    },
    4032: {
      lat: 153.019449,
      lng: -27.385605,
      city: 'Craigslea',
      state: 'QLD',
    },
    4034: {
      lat: 153.040051,
      lng: -27.354711,
      city: 'Zillmere',
      state: 'QLD',
    },
    4035: {
      lat: 152.979085,
      lng: -27.356025,
      city: 'Cashs Crossing',
      state: 'QLD',
    },
    4036: {
      lat: 153.008731,
      lng: -27.314022,
      city: 'Bald Hills',
      state: 'QLD',
    },
    4037: {
      lat: 152.936519,
      lng: -27.341128,
      city: 'Eatons Hill',
      state: 'QLD',
    },
    4051: {
      lat: 153.000297,
      lng: -27.427508,
      city: 'Wilston',
      state: 'QLD',
    },
    4053: {
      lat: 152.98725,
      lng: -27.399573,
      city: 'Stafford Heights',
      state: 'QLD',
    },
    4054: {
      lat: 152.956014,
      lng: -27.409817,
      city: 'Keperra',
      state: 'QLD',
    },
    4055: {
      lat: 152.92424,
      lng: -27.393676,
      city: 'Upper Kedron',
      state: 'QLD',
    },
    4059: {
      lat: 153.008893,
      lng: -27.452067,
      city: 'Red Hill',
      state: 'QLD',
    },
    4060: {
      lat: 152.985176,
      lng: -27.445107,
      city: 'St Johns Wood',
      state: 'QLD',
    },
    4061: {
      lat: 152.944258,
      lng: -27.45085,
      city: 'The Gap',
      state: 'QLD',
    },
    4064: {
      lat: 152.999039,
      lng: -27.464676,
      city: 'Rosalie',
      state: 'QLD',
    },
    4065: {
      lat: 152.975992,
      lng: -27.462504,
      city: 'Rainworth',
      state: 'QLD',
    },
    4066: {
      lat: 152.964146,
      lng: -27.479866,
      city: 'Torwood',
      state: 'QLD',
    },
    4067: {
      lat: 153.005045,
      lng: -27.500518,
      city: 'St Lucia South',
      state: 'QLD',
    },
    4068: {
      lat: 152.976926,
      lng: -27.506907,
      city: 'Taringa',
      state: 'QLD',
    },
    4069: {
      lat: 152.898911,
      lng: -27.502784,
      city: 'Upper Brookfield',
      state: 'QLD',
    },
    4070: {
      lat: 152.871141,
      lng: -27.559944,
      city: 'Priors Pocket',
      state: 'QLD',
    },
    4072: {
      lat: 152.330088,
      lng: -27.548962,
      city: 'University Of Queensland',
      state: 'QLD',
    },
    4073: {
      lat: 152.951385,
      lng: -27.549179,
      city: 'Sinnamon Park',
      state: 'QLD',
    },
    4074: {
      lat: 152.921521,
      lng: -27.554082,
      city: 'Westlake',
      state: 'QLD',
    },
    4075: {
      lat: 152.975619,
      lng: -27.552135,
      city: 'Sherwood',
      state: 'QLD',
    },
    4076: {
      lat: 152.921067,
      lng: -27.584561,
      city: 'Wacol East Immigration Centre',
      state: 'QLD',
    },
    4077: {
      lat: 152.967822,
      lng: -27.605551,
      city: 'Serviceton',
      state: 'QLD',
    },
    4078: {
      lat: 152.950579,
      lng: -27.612653,
      city: 'Forest Lake',
      state: 'QLD',
    },
    4101: {
      lat: 153.009661,
      lng: -27.484254,
      city: 'West End',
      state: 'QLD',
    },
    4102: {
      lat: 153.0328,
      lng: -27.495458,
      city: 'Woolloongabba',
      state: 'QLD',
    },
    4103: {
      lat: 153.028768,
      lng: -27.512175,
      city: 'Thompson Estate',
      state: 'QLD',
    },
    4104: {
      lat: 153.011535,
      lng: -27.515758,
      city: 'Yeronga West',
      state: 'QLD',
    },
    4105: {
      lat: 153.018893,
      lng: -27.534438,
      city: 'Yeerongpilly',
      state: 'QLD',
    },
    4106: {
      lat: 153.004615,
      lng: -27.552137,
      city: 'Rocklea Dc',
      state: 'QLD',
    },
    4107: {
      lat: 153.030643,
      lng: -27.552052,
      city: 'Salisbury East',
      state: 'QLD',
    },
    4108: {
      lat: 153.020197,
      lng: -27.568359,
      city: 'Coopers Plains',
      state: 'QLD',
    },
    4109: {
      lat: 153.057452,
      lng: -27.57925,
      city: 'Sunnybank South',
      state: 'QLD',
    },
    4110: {
      lat: 153.009526,
      lng: -27.612178,
      city: 'Willawong',
      state: 'QLD',
    },
    4111: {
      lat: 153.052226,
      lng: -27.550643,
      city: 'Nathan',
      state: 'QLD',
    },
    4112: {
      lat: 153.087839,
      lng: -27.611129,
      city: 'Kuraby',
      state: 'QLD',
    },
    4113: {
      lat: 153.077926,
      lng: -27.590722,
      city: 'Runcorn',
      state: 'QLD',
    },
    4114: {
      lat: 153.110764,
      lng: -27.652812,
      city: 'Woodridge East',
      state: 'QLD',
    },
    4115: {
      lat: 153.022499,
      lng: -27.643845,
      city: 'Parkinson',
      state: 'QLD',
    },
    4116: {
      lat: 153.050793,
      lng: -27.633988,
      city: 'Stretton',
      state: 'QLD',
    },
    4117: {
      lat: 153.08304,
      lng: -27.644688,
      city: 'Karawatha',
      state: 'QLD',
    },
    4118: {
      lat: 153.027323,
      lng: -27.673806,
      city: 'Regents Park',
      state: 'QLD',
    },
    4119: {
      lat: 153.109769,
      lng: -27.611502,
      city: 'Underwood',
      state: 'QLD',
    },
    4120: {
      lat: 153.044217,
      lng: -27.508707,
      city: 'Stones Corner',
      state: 'QLD',
    },
    4121: {
      lat: 153.053952,
      lng: -27.526966,
      city: 'Wellers Hill',
      state: 'QLD',
    },
    4122: {
      lat: 153.088155,
      lng: -27.543316,
      city: 'Wishart',
      state: 'QLD',
    },
    4123: {
      lat: 153.116151,
      lng: -27.583528,
      city: 'Rochedale South',
      state: 'QLD',
    },
    4124: {
      lat: 152.9264476,
      lng: -27.78983267,
      city: 'Silverbark Ridge',
      state: 'QLD',
    },
    4125: {
      lat: 153.04108,
      lng: -27.730432,
      city: 'Park Ridge South',
      state: 'QLD',
    },
    4127: {
      lat: 153.142035,
      lng: -27.629474,
      city: 'Springwood Bc',
      state: 'QLD',
    },
    4128: {
      lat: 153.174214,
      lng: -27.652403,
      city: 'Tanah Merah',
      state: 'QLD',
    },
    4129: {
      lat: 153.186817,
      lng: -27.681519,
      city: 'Loganholme Dc',
      state: 'QLD',
    },
    4130: {
      lat: 153.24333,
      lng: -27.669244,
      city: 'Cornubia',
      state: 'QLD',
    },
    4131: {
      lat: 153.136072,
      lng: -27.669913,
      city: 'Meadowbrook',
      state: 'QLD',
    },
    4132: {
      lat: 153.084397,
      lng: -27.681063,
      city: 'Marsden Postal Depot',
      state: 'QLD',
    },
    4133: {
      lat: 153.102664,
      lng: -27.723837,
      city: 'Waterford West',
      state: 'QLD',
    },
    4151: {
      lat: 153.058605,
      lng: -27.500807,
      city: 'Coorparoo Dc',
      state: 'QLD',
    },
    4152: {
      lat: 153.096812,
      lng: -27.501349,
      city: 'Whites Hill',
      state: 'QLD',
    },
    4153: {
      lat: 153.131429,
      lng: -27.510035,
      city: 'Belmont Heights',
      state: 'QLD',
    },
    4154: {
      lat: 153.158727,
      lng: -27.49069,
      city: 'Wakerley',
      state: 'QLD',
    },
    4155: {
      lat: 153.156446,
      lng: -27.5181,
      city: 'Chandler',
      state: 'QLD',
    },
    4156: {
      lat: 153.154177,
      lng: -27.558858,
      city: 'Mackenzie',
      state: 'QLD',
    },
    4157: {
      lat: 153.207096,
      lng: -27.568178,
      city: 'Sheldon',
      state: 'QLD',
    },
    4158: {
      lat: 153.1998,
      lng: -27.487689,
      city: 'Thorneside',
      state: 'QLD',
    },
    4159: {
      lat: 153.209624,
      lng: -27.502289,
      city: 'Birkdale',
      state: 'QLD',
    },
    4160: {
      lat: 153.243312,
      lng: -27.506409,
      city: 'Wellington Point',
      state: 'QLD',
    },
    4161: {
      lat: 153.226864,
      lng: -27.533407,
      city: 'Alexandra Hills',
      state: 'QLD',
    },
    4163: {
      lat: 153.26571,
      lng: -27.535216,
      city: 'Cleveland Dc',
      state: 'QLD',
    },
    4164: {
      lat: 153.260833,
      lng: -27.569965,
      city: 'Thornlands',
      state: 'QLD',
    },
    4165: {
      lat: 153.263801,
      lng: -27.623975,
      city: 'Victoria Point West',
      state: 'QLD',
    },
    4169: {
      lat: 153.040982,
      lng: -27.484177,
      city: 'Stanley Bridge',
      state: 'QLD',
    },
    4170: {
      lat: 153.074231,
      lng: -27.470778,
      city: 'Seven Hills',
      state: 'QLD',
    },
    4171: {
      lat: 153.06036,
      lng: -27.456031,
      city: 'Hawthorne',
      state: 'QLD',
    },
    4172: {
      lat: 153.103331,
      lng: -27.458767,
      city: 'Murarrie',
      state: 'QLD',
    },
    4173: {
      lat: 153.120634,
      lng: -27.475814,
      city: 'Tingalpa Dc',
      state: 'QLD',
    },
    4174: {
      lat: 153.129424,
      lng: -27.450089,
      city: 'Hemmant',
      state: 'QLD',
    },
    4178: {
      lat: 153.232567,
      lng: -27.425651,
      city: 'Wynnum West',
      state: 'QLD',
    },
    4179: {
      lat: 153.234041,
      lng: -27.390823,
      city: 'Manly West',
      state: 'QLD',
    },
    4183: {
      lat: 153.451696,
      lng: -27.545157,
      city: 'Point Lookout',
      state: 'QLD',
    },
    4184: {
      lat: 153.384655,
      lng: -27.676499,
      city: 'Russell Island',
      state: 'QLD',
    },
    4205: {
      lat: 153.156289,
      lng: -27.687389,
      city: 'Bethania',
      state: 'QLD',
    },
    4207: {
      lat: 153.204523,
      lng: -27.733991,
      city: 'Yellow Wood',
      state: 'QLD',
    },
    4208: {
      lat: 153.27631,
      lng: -27.767029,
      city: 'Ormeau Hills',
      state: 'QLD',
    },
    4209: {
      lat: 153.392892,
      lng: -27.805498,
      city: 'Willow Vale',
      state: 'QLD',
    },
    4210: {
      lat: 153.279483,
      lng: -27.932207,
      city: 'Wongawallan',
      state: 'QLD',
    },
    4211: {
      lat: 153.241258,
      lng: -28.035453,
      city: 'Southern Lamington',
      state: 'QLD',
    },
    4212: {
      lat: 153.360694,
      lng: -27.869823,
      city: 'Santa Barbara',
      state: 'QLD',
    },
    4213: {
      lat: 153.318916,
      lng: -28.110164,
      city: 'Worongary',
      state: 'QLD',
    },
    4214: {
      lat: 153.365576,
      lng: -27.967005,
      city: 'Parkwood',
      state: 'QLD',
    },
    4215: {
      lat: 153.397952,
      lng: -27.967134,
      city: 'Sundale',
      state: 'QLD',
    },
    4216: {
      lat: 153.419405,
      lng: -27.839347,
      city: 'Sovereign Islands',
      state: 'QLD',
    },
    4217: {
      lat: 153.412197,
      lng: -28.008783,
      city: 'The Spit',
      state: 'QLD',
    },
    4218: {
      lat: 153.41692,
      lng: -28.037627,
      city: 'Rio Vista',
      state: 'QLD',
    },
    4219: {
      lat: 153.434197,
      lng: -28.111748,
      city: 'West Burleigh',
      state: 'QLD',
    },
    4220: {
      lat: 153.434406,
      lng: -28.084952,
      city: 'Tally Valley',
      state: 'QLD',
    },
    4221: {
      lat: 153.45837,
      lng: -28.127323,
      city: 'Palm Beach',
      state: 'QLD',
    },
    4222: {
      lat: 153.052226,
      lng: -27.550643,
      city: 'Griffith University',
      state: 'QLD',
    },
    4223: {
      lat: 153.415088,
      lng: -28.19076,
      city: 'Currumbin Waters',
      state: 'QLD',
    },
    4224: {
      lat: 153.489332,
      lng: -28.150224,
      city: 'Tugun Heights',
      state: 'QLD',
    },
    4225: {
      lat: 153.517333,
      lng: -28.168787,
      city: 'Rainbow Bay',
      state: 'QLD',
    },
    4226: {
      lat: 153.40282,
      lng: -28.06794,
      city: 'Robina Dc',
      state: 'QLD',
    },
    4227: {
      lat: 153.410392,
      lng: -28.101892,
      city: 'Varsity Lakes',
      state: 'QLD',
    },
    4228: {
      lat: 153.37739,
      lng: -28.182084,
      city: 'Tallebudgera Valley',
      state: 'QLD',
    },
    4229: {
      lat: 153.413136,
      lng: -28.077892,
      city: 'Bond University',
      state: 'QLD',
    },
    4230: {
      lat: 153.38531,
      lng: -28.077467,
      city: 'Robina Town Centre',
      state: 'QLD',
    },
    4270: {
      lat: 153.135566,
      lng: -27.862349,
      city: 'Tamborine Village',
      state: 'QLD',
    },
    4271: {
      lat: 153.201472,
      lng: -27.91838,
      city: 'Eagle Heights',
      state: 'QLD',
    },
    4272: {
      lat: 153.1936491,
      lng: -27.94216485,
      city: 'Tamborine Mountain',
      state: 'QLD',
    },
    4275: {
      lat: 153.134987,
      lng: -28.111411,
      city: 'Wonglepong',
      state: 'QLD',
    },
    4280: {
      lat: 153.048563,
      lng: -27.81609,
      city: 'Stockleigh',
      state: 'QLD',
    },
    4285: {
      lat: 152.975131,
      lng: -28.056979,
      city: 'Woodhill',
      state: 'QLD',
    },
    4287: {
      lat: 152.822971,
      lng: -28.259143,
      city: 'Running Creek',
      state: 'QLD',
    },
    4300: {
      lat: 152.884999,
      lng: -27.682273,
      city: 'Springfield Lakes',
      state: 'QLD',
    },
    4301: {
      lat: 152.858086,
      lng: -27.629394,
      city: 'Redbank Plains',
      state: 'QLD',
    },
    4303: {
      lat: 152.840187,
      lng: -27.604588,
      city: 'Riverview',
      state: 'QLD',
    },
    4304: {
      lat: 152.80457,
      lng: -27.620656,
      city: 'Silkstone',
      state: 'QLD',
    },
    4305: {
      lat: 152.753696,
      lng: -27.627522,
      city: 'Yamanto',
      state: 'QLD',
    },
    4306: {
      lat: 152.725408,
      lng: -27.480898,
      city: 'Wivenhoe Pocket',
      state: 'QLD',
    },
    4307: {
      lat: 152.592399,
      lng: -27.851804,
      city: 'Wilsons Plains',
      state: 'QLD',
    },
    4309: {
      lat: 152.523366,
      lng: -27.98728,
      city: 'Washpool',
      state: 'QLD',
    },
    4310: {
      lat: 152.600598,
      lng: -28.121399,
      city: 'Wyaralong',
      state: 'QLD',
    },
    4311: {
      lat: 152.438771,
      lng: -27.418572,
      city: 'Wivenhoe Hill',
      state: 'QLD',
    },
    4312: {
      lat: 152.482636,
      lng: -27.256065,
      city: 'Somerset Dam',
      state: 'QLD',
    },
    4313: {
      lat: 152.321536,
      lng: -27.089517,
      city: 'Yimbun',
      state: 'QLD',
    },
    4314: {
      lat: 152.0973,
      lng: -26.7728,
      city: 'Teelah',
      state: 'QLD',
    },
    4340: {
      lat: 152.472702,
      lng: -27.803453,
      city: 'Woolshed',
      state: 'QLD',
    },
    4341: {
      lat: 152.396656,
      lng: -27.68768,
      city: 'Townson',
      state: 'QLD',
    },
    4342: {
      lat: 152.377687,
      lng: -27.543847,
      city: 'Lynford',
      state: 'QLD',
    },
    4343: {
      lat: 152.224616,
      lng: -27.656074,
      city: 'Woodlands',
      state: 'QLD',
    },
    4344: {
      lat: 152.060385,
      lng: -27.572999,
      city: 'Upper Flagstone',
      state: 'QLD',
    },
    4345: {
      lat: 152.336382,
      lng: -27.549094,
      city: 'Gatton College',
      state: 'QLD',
    },
    4346: {
      lat: 152.588235,
      lng: -27.56237,
      city: 'Marburg',
      state: 'QLD',
    },
    4347: {
      lat: 152.142704,
      lng: -27.655062,
      city: 'Winwill',
      state: 'QLD',
    },
    4350: {
      lat: 151.852689,
      lng: -27.576308,
      city: 'Wyalla Plaza',
      state: 'QLD',
    },
    4352: {
      lat: 151.94182,
      lng: -27.627661,
      city: 'Yandilla',
      state: 'QLD',
    },
    4353: {
      lat: 151.904578,
      lng: -27.256984,
      city: 'Mount Darry',
      state: 'QLD',
    },
    4354: {
      lat: 151.887919,
      lng: -27.343884,
      city: 'Kilbirnie',
      state: 'QLD',
    },
    4355: {
      lat: 152.008635,
      lng: -27.18463,
      city: 'Upper Pinelands',
      state: 'QLD',
    },
    4356: {
      lat: 151.550271,
      lng: -27.663074,
      city: 'Yarranlea',
      state: 'QLD',
    },
    4357: {
      lat: 151.103872,
      lng: -27.957654,
      city: 'Woondul',
      state: 'QLD',
    },
    4358: {
      lat: 151.859857,
      lng: -27.7283,
      city: 'Vale View',
      state: 'QLD',
    },
    4359: {
      lat: 152.03681,
      lng: -27.794456,
      city: 'West Haldon',
      state: 'QLD',
    },
    4360: {
      lat: 151.907036,
      lng: -27.853801,
      city: 'Nobby',
      state: 'QLD',
    },
    4361: {
      lat: 151.948279,
      lng: -27.965214,
      city: 'Victoria Hill',
      state: 'QLD',
    },
    4362: {
      lat: 151.8857142,
      lng: -28.03874008,
      city: 'Talgai',
      state: 'QLD',
    },
    4363: {
      lat: 151.739695,
      lng: -27.676647,
      city: 'Southbrook',
      state: 'QLD',
    },
    4364: {
      lat: 151.396183,
      lng: -27.710492,
      city: 'Brookstead',
      state: 'QLD',
    },
    4365: {
      lat: 151.597527,
      lng: -28.017149,
      city: 'Leyburn',
      state: 'QLD',
    },
    4370: {
      lat: 152.0406741,
      lng: -28.18048116,
      city: 'Womina',
      state: 'QLD',
    },
    4371: {
      lat: 152.234242,
      lng: -28.199024,
      city: 'Yangan',
      state: 'QLD',
    },
    4372: {
      lat: 152.233089,
      lng: -28.303067,
      city: 'Tannymorel',
      state: 'QLD',
    },
    4373: {
      lat: 152.4411132,
      lng: -28.26428492,
      city: 'The Head',
      state: 'QLD',
    },
    4374: {
      lat: 151.7856881,
      lng: -28.35569278,
      city: 'Palgrave',
      state: 'QLD',
    },
    4375: {
      lat: 151.9153278,
      lng: -28.52776721,
      city: 'Fleurbaix',
      state: 'QLD',
    },
    4376: {
      lat: 151.933783,
      lng: -28.541494,
      city: 'Thulimbah',
      state: 'QLD',
    },
    4377: {
      lat: 151.918897,
      lng: -28.571936,
      city: 'The Summit',
      state: 'QLD',
    },
    4378: {
      lat: 151.955491,
      lng: -28.613948,
      city: 'Applethorpe',
      state: 'QLD',
    },
    4380: {
      lat: 152.1822634,
      lng: -28.62255099,
      city: 'Undercliffe',
      state: 'NSW',
    },
    4381: {
      lat: 151.839611,
      lng: -28.743816,
      city: 'Glen Aplin',
      state: 'QLD',
    },
    4382: {
      lat: 151.847374,
      lng: -28.845315,
      city: 'Wyberba',
      state: 'QLD',
    },
    4383: {
      lat: 151.948363,
      lng: -28.904615,
      city: 'Wallangarra',
      state: 'QLD',
    },
    4384: {
      lat: 151.183208,
      lng: -28.728006,
      city: 'Limevale',
      state: 'QLD',
    },
    4385: {
      lat: 151.24801,
      lng: -28.777563,
      city: 'Watsons Crossing',
      state: 'QLD',
    },
    4387: {
      lat: 150.967836,
      lng: -28.341631,
      city: 'Whetstone',
      state: 'QLD',
    },
    4388: {
      lat: 150.646259,
      lng: -28.508802,
      city: 'Yelarbon',
      state: 'QLD',
    },
    4390: {
      lat: 150.321016,
      lng: -28.228906,
      city: 'Yagaburne',
      state: 'QLD',
    },
    4400: {
      lat: 151.856298,
      lng: -27.4855,
      city: 'Kingsthorpe',
      state: 'QLD',
    },
    4401: {
      lat: 151.703647,
      lng: -27.473135,
      city: 'Yargullen',
      state: 'QLD',
    },
    4402: {
      lat: 151.803967,
      lng: -26.978981,
      city: 'Upper Cooyar Creek',
      state: 'QLD',
    },
    4403: {
      lat: 151.596963,
      lng: -27.320633,
      city: 'West Prairie',
      state: 'QLD',
    },
    4404: {
      lat: 151.480666,
      lng: -27.33128,
      city: 'Wainui',
      state: 'QLD',
    },
    4405: {
      lat: 150.910979,
      lng: -27.316799,
      city: 'Yamsion',
      state: 'QLD',
    },
    4406: {
      lat: 151.433657,
      lng: -27.064207,
      city: 'Weir River',
      state: 'QLD',
    },
    4407: {
      lat: 150.961981,
      lng: -27.563685,
      city: 'Nangwee',
      state: 'QLD',
    },
    4408: {
      lat: 151.426657,
      lng: -26.851248,
      city: 'Cooranga North',
      state: 'QLD',
    },
    4410: {
      lat: 151.109816,
      lng: -26.781197,
      city: 'Langlands',
      state: 'QLD',
    },
    4411: {
      lat: 150.812644,
      lng: -27.013381,
      city: 'Warra',
      state: 'QLD',
    },
    4412: {
      lat: 150.790571,
      lng: -26.843852,
      city: 'Wychie',
      state: 'QLD',
    },
    4413: {
      lat: 150.638563,
      lng: -26.360226,
      city: 'Wieambilla',
      state: 'QLD',
    },
    4415: {
      lat: 150.205114,
      lng: -26.627339,
      city: 'Pelham',
      state: 'QLD',
    },
    4416: {
      lat: 149.798038,
      lng: -26.995752,
      city: 'Yulabilla',
      state: 'QLD',
    },
    4417: {
      lat: 149.070172,
      lng: -27.348199,
      city: 'Weribone',
      state: 'QLD',
    },
    4418: {
      lat: 150.143323,
      lng: -26.290166,
      city: 'Guluguba',
      state: 'QLD',
    },
    4419: {
      lat: 149.934875,
      lng: -26.32673679,
      city: 'Woleebee',
      state: 'QLD',
    },
    4420: {
      lat: 149.525293,
      lng: -25.494713,
      city: 'Waikola',
      state: 'QLD',
    },
    4421: {
      lat: 150.444304,
      lng: -27.324371,
      city: 'Tara',
      state: 'QLD',
    },
    4422: {
      lat: 149.7572563,
      lng: -27.88867614,
      city: 'Westmar',
      state: 'QLD',
    },
    4423: {
      lat: 149.525379,
      lng: -27.383801,
      city: 'Teelba',
      state: 'QLD',
    },
    4424: {
      lat: 149.960268,
      lng: -26.598388,
      city: 'Glenaubyn',
      state: 'QLD',
    },
    4425: {
      lat: 149.773144,
      lng: -26.649074,
      city: 'Dulacca',
      state: 'QLD',
    },
    4426: {
      lat: 149.590426,
      lng: -26.539914,
      city: 'Jackson South',
      state: 'QLD',
    },
    4427: {
      lat: 149.381938,
      lng: -26.75002,
      city: 'Yuleba South',
      state: 'QLD',
    },
    4428: {
      lat: 149.206489,
      lng: -26.40373,
      city: 'Wallumbilla South',
      state: 'QLD',
    },
    4454: {
      lat: 148.589834,
      lng: -25.55725,
      city: 'Westgrove',
      state: 'QLD',
    },
    4455: {
      lat: 148.690231,
      lng: -26.73282,
      city: 'Wycombe',
      state: 'QLD',
    },
    4461: {
      lat: 148.293883,
      lng: -26.663912,
      city: 'Muckadilla',
      state: 'QLD',
    },
    4462: {
      lat: 148.2478725,
      lng: -26.35135957,
      city: 'Walhallow',
      state: 'QLD',
    },
    4465: {
      lat: 148.062548,
      lng: -26.968591,
      city: 'Womblebank',
      state: 'QLD',
    },
    4467: {
      lat: 147.5374764,
      lng: -26.26329299,
      city: 'Tyrconnel',
      state: 'QLD',
    },
    4468: {
      lat: 147.026276,
      lng: -26.600192,
      city: 'Morven',
      state: 'QLD',
    },
    4470: {
      lat: 146.2406534,
      lng: -25.68590212,
      city: 'Ward',
      state: 'QLD',
    },
    4471: {
      lat: 145.892163,
      lng: -27.631116,
      city: 'Nardoo Siding',
      state: 'QLD',
    },
    4472: {
      lat: 145.141177,
      lng: -24.784155,
      city: 'Mount Enniskillen',
      state: 'QLD',
    },
    4474: {
      lat: 144.495283,
      lng: -25.666985,
      city: 'Adavale',
      state: 'QLD',
    },
    4475: {
      lat: 144.975368,
      lng: -26.844909,
      city: 'Cheepie',
      state: 'QLD',
    },
    4477: {
      lat: 147.3189625,
      lng: -25.15423668,
      city: 'Upper Warrego',
      state: 'QLD',
    },
    4478: {
      lat: 146.399409,
      lng: -24.889395,
      city: 'Yandarlo',
      state: 'QLD',
    },
    4479: {
      lat: 145.397502,
      lng: -26.724017,
      city: 'Cooladdi',
      state: 'QLD',
    },
    4480: {
      lat: 143.274156,
      lng: -26.248504,
      city: 'Quilpie',
      state: 'QLD',
    },
    4481: {
      lat: 141.581266,
      lng: -25.377582,
      city: 'Windorah',
      state: 'QLD',
    },
    4482: {
      lat: 139.323274,
      lng: -25.60309,
      city: 'Birdsville',
      state: 'QLD',
    },
    4486: {
      lat: 147.830117,
      lng: -28.61221,
      city: 'Hebel',
      state: 'QLD',
    },
    4487: {
      lat: 148.408512,
      lng: -27.931188,
      city: 'St George',
      state: 'QLD',
    },
    4488: {
      lat: 146.990784,
      lng: -28.110523,
      city: 'Nebine',
      state: 'QLD',
    },
    4489: {
      lat: 145.655744,
      lng: -27.258572,
      city: 'Wyandra',
      state: 'QLD',
    },
    4490: {
      lat: 145.596265,
      lng: -28.418047,
      city: 'Yowah',
      state: 'QLD',
    },
    4491: {
      lat: 145.083656,
      lng: -27.91583,
      city: 'Eulo',
      state: 'QLD',
    },
    4492: {
      lat: 143.224764,
      lng: -28.190899,
      city: 'Thargomindah',
      state: 'QLD',
    },
    4493: {
      lat: 144.560358,
      lng: -28.941714,
      city: 'Hungerford',
      state: 'QLD',
    },
    4494: {
      lat: 149.606552,
      lng: -28.143515,
      city: 'Tarawera',
      state: 'QLD',
    },
    4496: {
      lat: 149.267966,
      lng: -28.364992,
      city: 'Talwood',
      state: 'QLD',
    },
    4497: {
      lat: 148.753584,
      lng: -28.645485,
      city: 'Weengallon',
      state: 'QLD',
    },
    4498: {
      lat: 149.824698,
      lng: -28.415979,
      city: 'Toobeah',
      state: 'QLD',
    },
    4500: {
      lat: 152.939438,
      lng: -27.307845,
      city: 'Warner',
      state: 'QLD',
    },
    4501: {
      lat: 152.946201,
      lng: -27.277628,
      city: 'Lawnton',
      state: 'QLD',
    },
    4502: {
      lat: 152.966813,
      lng: -27.264974,
      city: 'Petrie',
      state: 'QLD',
    },
    4503: {
      lat: 152.993187,
      lng: -27.240638,
      city: 'Whiteside',
      state: 'QLD',
    },
    4504: {
      lat: 152.932083,
      lng: -27.186624,
      city: 'Narangba',
      state: 'QLD',
    },
    4505: {
      lat: 153.0042078,
      lng: -27.14126334,
      city: 'Burpengary East',
      state: 'QLD',
    },
    4506: {
      lat: 152.936289,
      lng: -27.128401,
      city: 'Morayfield',
      state: 'QLD',
    },
    4507: {
      lat: 153.12414,
      lng: -26.987776,
      city: 'Woorim',
      state: 'QLD',
    },
    4508: {
      lat: 153.013716,
      lng: -27.181203,
      city: 'Deception Bay',
      state: 'QLD',
    },
    4509: {
      lat: 153.0138289,
      lng: -27.22196846,
      city: 'North Lakes',
      state: 'QLD',
    },
    4510: {
      lat: 152.960273,
      lng: -27.070177,
      city: 'Upper Caboolture',
      state: 'QLD',
    },
    4511: {
      lat: 153.080799,
      lng: -27.077933,
      city: 'Sandstone Point',
      state: 'QLD',
    },
    4512: {
      lat: 152.858311,
      lng: -27.022484,
      city: 'Wamuran Basin',
      state: 'QLD',
    },
    4514: {
      lat: 152.760081,
      lng: -26.91784,
      city: 'Woodford',
      state: 'QLD',
    },
    4515: {
      lat: 152.4987372,
      lng: -26.94116786,
      city: 'Woolmar',
      state: 'QLD',
    },
    4516: {
      lat: 152.957238,
      lng: -27.005542,
      city: 'Elimbah',
      state: 'QLD',
    },
    4517: {
      lat: 152.987647,
      lng: -26.951082,
      city: 'Beerburrum',
      state: 'QLD',
    },
    4518: {
      lat: 152.986033,
      lng: -26.902425,
      city: 'Glass House Mountains',
      state: 'QLD',
    },
    4519: {
      lat: 152.91803,
      lng: -26.849388,
      city: 'Peachester',
      state: 'QLD',
    },
    4520: {
      lat: 152.814527,
      lng: -27.322775,
      city: 'Yugar',
      state: 'QLD',
    },
    4521: {
      lat: 152.786172,
      lng: -27.169774,
      city: 'Rush Creek',
      state: 'QLD',
    },
    4550: {
      lat: 152.971346,
      lng: -26.812085,
      city: 'Mount Mellum',
      state: 'QLD',
    },
    4551: {
      lat: 153.088435,
      lng: -26.828067,
      city: 'Shelly Beach',
      state: 'QLD',
    },
    4552: {
      lat: 152.766898,
      lng: -26.734305,
      city: 'Wootha',
      state: 'QLD',
    },
    4553: {
      lat: 153.017962,
      lng: -26.771797,
      city: 'Palmview',
      state: 'QLD',
    },
    4554: {
      lat: 152.965037,
      lng: -26.731692,
      city: 'Ilkley',
      state: 'QLD',
    },
    4555: {
      lat: 152.95452,
      lng: -26.691701,
      city: 'Palmwoods',
      state: 'QLD',
    },
    4556: {
      lat: 153.049784,
      lng: -26.700379,
      city: 'Tanawha',
      state: 'QLD',
    },
    4557: {
      lat: 153.113416,
      lng: -26.686321,
      city: 'Mountain Creek',
      state: 'QLD',
    },
    4558: {
      lat: 153.075373,
      lng: -26.655067,
      city: 'Sunshine Plaza',
      state: 'QLD',
    },
    4559: {
      lat: 152.965013,
      lng: -26.66324,
      city: 'Woombye',
      state: 'QLD',
    },
    4560: {
      lat: 152.917384,
      lng: -26.635087,
      city: 'Towen Mountain',
      state: 'QLD',
    },
    4561: {
      lat: 152.964814,
      lng: -26.531487,
      city: 'Yandina Creek',
      state: 'QLD',
    },
    4562: {
      lat: 152.803089,
      lng: -26.505863,
      city: 'Weyba Downs',
      state: 'QLD',
    },
    4563: {
      lat: 152.909206,
      lng: -26.425754,
      city: 'Tinbeerwah',
      state: 'QLD',
    },
    4564: {
      lat: 153.079239,
      lng: -26.612359,
      city: 'Twin Waters',
      state: 'QLD',
    },
    4565: {
      lat: 153.042307,
      lng: -26.156837,
      city: 'Tewantin',
      state: 'QLD',
    },
    4566: {
      lat: 153.047416,
      lng: -26.411731,
      city: 'Noosaville Dc',
      state: 'QLD',
    },
    4567: {
      lat: 153.096957,
      lng: -26.408596,
      city: 'Sunshine Beach',
      state: 'QLD',
    },
    4568: {
      lat: 152.876607,
      lng: -26.313995,
      city: 'Pomona',
      state: 'QLD',
    },
    4569: {
      lat: 152.822844,
      lng: -26.334574,
      city: 'Cooran',
      state: 'QLD',
    },
    4570: {
      lat: 152.616943,
      lng: -26.153831,
      city: 'Woondum',
      state: 'QLD',
    },
    4571: {
      lat: 152.931885,
      lng: -26.219743,
      city: 'Wahpunga',
      state: 'QLD',
    },
    4572: {
      lat: 153.101997,
      lng: -26.671589,
      city: 'Alexandra Headland',
      state: 'QLD',
    },
    4573: {
      lat: 153.068303,
      lng: -26.516942,
      city: 'Yaroomba',
      state: 'QLD',
    },
    4574: {
      lat: 152.761131,
      lng: -26.630369,
      city: 'Obi Obi',
      state: 'QLD',
    },
    4575: {
      lat: 153.119478,
      lng: -26.742043,
      city: 'Wurtulla',
      state: 'QLD',
    },
    4580: {
      lat: 152.928987,
      lng: -25.891004,
      city: 'Tin Can Bay',
      state: 'QLD',
    },
    4581: {
      lat: 153.097862,
      lng: -25.990771,
      city: 'Rainbow Beach',
      state: 'QLD',
    },
    4600: {
      lat: 152.221081,
      lng: -26.055099,
      city: 'Oakview',
      state: 'QLD',
    },
    4601: {
      lat: 152.012412,
      lng: -26.145002,
      city: 'Wrattens Forest',
      state: 'QLD',
    },
    4605: {
      lat: 151.870256,
      lng: -26.112408,
      city: 'Wooroonden',
      state: 'QLD',
    },
    4606: {
      lat: 151.823079,
      lng: -26.26117,
      city: 'Wondai',
      state: 'QLD',
    },
    4608: {
      lat: 151.741338,
      lng: -26.35256,
      city: 'Wooroolin',
      state: 'QLD',
    },
    4610: {
      lat: 151.522575,
      lng: -26.576904,
      city: 'Wattle Grove',
      state: 'QLD',
    },
    4611: {
      lat: 151.776012,
      lng: -26.176717,
      city: 'Mondure',
      state: 'QLD',
    },
    4612: {
      lat: 151.589811,
      lng: -26.004636,
      city: 'Wigton',
      state: 'QLD',
    },
    4613: {
      lat: 151.338904,
      lng: -26.279772,
      city: 'Stalworth',
      state: 'QLD',
    },
    4614: {
      lat: 151.964673,
      lng: -26.848329,
      city: 'Yarraman',
      state: 'QLD',
    },
    4615: {
      lat: 152.010432,
      lng: -26.659559,
      city: 'Wyalla',
      state: 'QLD',
    },
    4620: {
      lat: 152.280868,
      lng: -25.557047,
      city: 'Woocoo',
      state: 'QLD',
    },
    4621: {
      lat: 152.042802,
      lng: -25.535828,
      city: 'Woowoonga',
      state: 'QLD',
    },
    4625: {
      lat: 151.644182,
      lng: -25.571054,
      city: 'Yenda',
      state: 'QLD',
    },
    4626: {
      lat: 151.105529,
      lng: -25.765416,
      city: 'Riverleigh',
      state: 'QLD',
    },
    4627: {
      lat: 150.778657,
      lng: -25.350178,
      city: 'Wuruma Dam',
      state: 'QLD',
    },
    4630: {
      lat: 150.975499,
      lng: -24.860668,
      city: 'Yarrol',
      state: 'QLD',
    },
    4650: {
      lat: 152.791302,
      lng: -25.640978,
      city: 'Yerra',
      state: 'QLD',
    },
    4655: {
      lat: 152.78368,
      lng: -25.337998,
      city: 'Wondunna',
      state: 'QLD',
    },
    4659: {
      lat: 152.568678,
      lng: -25.266279,
      city: 'Pacific Haven',
      state: 'QLD',
    },
    4660: {
      lat: 152.300968,
      lng: -25.189298,
      city: 'Woodgate',
      state: 'QLD',
    },
    4662: {
      lat: 152.59878,
      lng: -25.347212,
      city: 'Torbanlea',
      state: 'QLD',
    },
    4670: {
      lat: 152.297855,
      lng: -24.88776,
      city: 'Woongarra',
      state: 'QLD',
    },
    4671: {
      lat: 151.78942,
      lng: -25.07645,
      city: 'Wonbah Forest',
      state: 'QLD',
    },
    4673: {
      lat: 152.088816,
      lng: -24.729644,
      city: 'Yandaran',
      state: 'QLD',
    },
    4674: {
      lat: 151.872531,
      lng: -24.688503,
      city: 'Taunton',
      state: 'QLD',
    },
    4676: {
      lat: 151.621961,
      lng: -24.632323,
      city: 'Lowmead',
      state: 'QLD',
    },
    4677: {
      lat: 151.727736,
      lng: -24.318471,
      city: 'Seventeen Seventy',
      state: 'QLD',
    },
    4678: {
      lat: 151.634285,
      lng: -24.146903,
      city: 'Turkey Beach',
      state: 'QLD',
    },
    4680: {
      lat: 151.917285,
      lng: -23.446826,
      city: 'Wurdong Heights',
      state: 'QLD',
    },
    4694: {
      lat: 151.121567,
      lng: -23.832434,
      city: 'Yarwun',
      state: 'QLD',
    },
    4695: {
      lat: 150.965859,
      lng: -23.807115,
      city: 'The Narrows',
      state: 'QLD',
    },
    4697: {
      lat: 150.81952,
      lng: -23.711361,
      city: 'Raglan',
      state: 'QLD',
    },
    4699: {
      lat: 150.643168,
      lng: -23.657893,
      city: 'Port Alma',
      state: 'QLD',
    },
    4700: {
      lat: 150.958111,
      lng: -23.180174,
      city: 'West Rockhampton',
      state: 'QLD',
    },
    4701: {
      lat: 150.557538,
      lng: -23.354937,
      city: 'The Common',
      state: 'QLD',
    },
    4702: {
      lat: 150.885772,
      lng: -23.279639,
      city: 'Yaraka',
      state: 'QLD',
    },
    4703: {
      lat: 150.894331,
      lng: -23.074563,
      city: 'Yeppoon',
      state: 'QLD',
    },
    4704: {
      lat: 150.381164,
      lng: -23.135744,
      city: 'Yaamba',
      state: 'QLD',
    },
    4705: {
      lat: 149.719215,
      lng: -22.771751,
      city: 'Mount Gardiner',
      state: 'QLD',
    },
    4706: {
      lat: 149.675382,
      lng: -22.580866,
      city: 'Ogmore',
      state: 'QLD',
    },
    4707: {
      lat: 149.235514,
      lng: -22.515013,
      city: 'The Percy Group',
      state: 'QLD',
    },
    4709: {
      lat: 148.429161,
      lng: -23.059653,
      city: 'Tieri',
      state: 'QLD',
    },
    4710: {
      lat: 150.82635,
      lng: -23.257223,
      city: 'Zilzie',
      state: 'QLD',
    },
    4711: {
      lat: 150.476229,
      lng: -23.252534,
      city: 'Glenlee',
      state: 'QLD',
    },
    4712: {
      lat: 149.673726,
      lng: -23.69521,
      city: 'Duaringa',
      state: 'QLD',
    },
    4713: {
      lat: 149.430965,
      lng: -24.114138,
      city: 'Woorabinda',
      state: 'QLD',
    },
    4714: {
      lat: 150.394906,
      lng: -23.66268,
      city: 'Wura',
      state: 'QLD',
    },
    4715: {
      lat: 150.458694,
      lng: -24.393618,
      city: 'Valentine Plains',
      state: 'QLD',
    },
    4716: {
      lat: 150.806049,
      lng: -24.483472,
      city: 'Thangool',
      state: 'QLD',
    },
    4717: {
      lat: 148.891333,
      lng: -23.632816,
      city: 'Blackwater',
      state: 'QLD',
    },
    4718: {
      lat: 149.615027,
      lng: -24.675045,
      city: 'Warnoah',
      state: 'QLD',
    },
    4719: {
      lat: 150.213774,
      lng: -24.999569,
      city: 'Theodore',
      state: 'QLD',
    },
    4720: {
      lat: 148.187851,
      lng: -23.68492,
      city: 'Yamala',
      state: 'QLD',
    },
    4721: {
      lat: 147.8150713,
      lng: -22.64097825,
      city: 'Wolfang',
      state: 'QLD',
    },
    4722: {
      lat: 148.0209965,
      lng: -24.47115119,
      city: 'Wealwandangie',
      state: 'QLD',
    },
    4723: {
      lat: 148.231917,
      lng: -23.010007,
      city: 'Wyuna',
      state: 'QLD',
    },
    4724: {
      lat: 146.6182351,
      lng: -23.13846998,
      city: 'Surbiton',
      state: 'QLD',
    },
    4725: {
      lat: 144.9428922,
      lng: -23.52905633,
      city: 'Tara Station',
      state: 'QLD',
    },
    4726: {
      lat: 145.3880016,
      lng: -22.86094029,
      city: 'Pelican Creek',
      state: 'QLD',
    },
    4727: {
      lat: 144.616977,
      lng: -23.515643,
      city: 'Ilfracombe',
      state: 'QLD',
    },
    4728: {
      lat: 146.1959751,
      lng: -23.86725647,
      city: 'Mexico',
      state: 'QLD',
    },
    4730: {
      lat: 143.402522,
      lng: -23.790711,
      city: 'Vergemont',
      state: 'QLD',
    },
    4731: {
      lat: 144.2278,
      lng: -24.8925,
      city: 'Yaraka',
      state: 'QLD',
    },
    4732: {
      lat: 144.5105389,
      lng: -22.39476733,
      city: 'Tablederry',
      state: 'QLD',
    },
    4733: {
      lat: 143.503025,
      lng: -21.771226,
      city: 'Corfield',
      state: 'QLD',
    },
    4735: {
      lat: 141.861769,
      lng: -22.810583,
      city: 'Winton',
      state: 'QLD',
    },
    4736: {
      lat: 143.103133,
      lng: -24.936614,
      city: 'Jundah',
      state: 'QLD',
    },
    4737: {
      lat: 149.178072,
      lng: -21.43004,
      city: 'Sunnyside',
      state: 'QLD',
    },
    4738: {
      lat: 149.267446,
      lng: -21.622237,
      city: 'Koumala',
      state: 'QLD',
    },
    4739: {
      lat: 149.395052,
      lng: -21.930567,
      city: 'Carmila',
      state: 'QLD',
    },
    4740: {
      lat: 149.059737,
      lng: -21.33763,
      city: 'Yakapari',
      state: 'QLD',
    },
    4741: {
      lat: 149.271699,
      lng: -20.808597,
      city: 'Yalboroo',
      state: 'QLD',
    },
    4742: {
      lat: 148.569564,
      lng: -21.754127,
      city: 'Valkyrie',
      state: 'QLD',
    },
    4743: {
      lat: 147.906986,
      lng: -21.453727,
      city: 'Suttor',
      state: 'QLD',
    },
    4744: {
      lat: 148.045134,
      lng: -22.018392,
      city: 'Peak Downs Mine',
      state: 'QLD',
    },
    4745: {
      lat: 148.259592,
      lng: -22.497884,
      city: 'Norwich Park',
      state: 'QLD',
    },
    4746: {
      lat: 148.850932,
      lng: -22.851944,
      city: 'Middlemount',
      state: 'QLD',
    },
    4750: {
      lat: 149.152281,
      lng: -21.042567,
      city: 'Shoal Point',
      state: 'QLD',
    },
    4751: {
      lat: 149.040703,
      lng: -21.221858,
      city: 'Walkerston',
      state: 'QLD',
    },
    4753: {
      lat: 148.952546,
      lng: -21.169015,
      city: 'Marian',
      state: 'QLD',
    },
    4754: {
      lat: 148.832763,
      lng: -21.188063,
      city: 'Septimus',
      state: 'QLD',
    },
    4756: {
      lat: 148.609682,
      lng: -21.126329,
      city: 'Netherdale',
      state: 'QLD',
    },
    4757: {
      lat: 148.50081,
      lng: -21.054268,
      city: 'Eungella Dam',
      state: 'QLD',
    },
    4798: {
      lat: 148.737792,
      lng: -20.889915,
      city: 'Wagoora',
      state: 'QLD',
    },
    4799: {
      lat: 148.622011,
      lng: -20.720423,
      city: 'Midge Point',
      state: 'QLD',
    },
    4800: {
      lat: 148.516317,
      lng: -20.465423,
      city: 'Wilson Beach',
      state: 'QLD',
    },
    4801: {
      lat: 148.887166,
      lng: -20.053296,
      city: 'Hayman Island',
      state: 'QLD',
    },
    4802: {
      lat: 148.989038,
      lng: -20.271072,
      city: 'Woodwark',
      state: 'QLD',
    },
    4803: {
      lat: 148.958824,
      lng: -20.354067,
      city: 'Hamilton Island',
      state: 'QLD',
    },
    4804: {
      lat: 147.518462,
      lng: -20.867952,
      city: 'Springlands',
      state: 'QLD',
    },
    4805: {
      lat: 148.457987,
      lng: -20.016612,
      city: 'Rose Bay',
      state: 'QLD',
    },
    4806: {
      lat: 147.416856,
      lng: -19.862878,
      city: 'Wunjunga',
      state: 'QLD',
    },
    4807: {
      lat: 147.133033,
      lng: -19.914965,
      city: 'Swans Lagoon',
      state: 'QLD',
    },
    4808: {
      lat: 147.304401,
      lng: -19.510071,
      city: 'Colevale',
      state: 'QLD',
    },
    4809: {
      lat: 147.139821,
      lng: -19.53085,
      city: 'Upper Haughton',
      state: 'QLD',
    },
    4810: {
      lat: 146.764659,
      lng: -19.227783,
      city: 'Yarrawonga',
      state: 'QLD',
    },
    4811: {
      lat: 146.840325,
      lng: -19.323109,
      city: 'Wulguru',
      state: 'QLD',
    },
    4812: {
      lat: 146.787716,
      lng: -19.286635,
      city: 'Rosslea',
      state: 'QLD',
    },
    4813: {
      lat: 146.786368,
      lng: -19.338928,
      city: 'Townsville Milpo',
      state: 'QLD',
    },
    4814: {
      lat: 146.754745,
      lng: -19.285821,
      city: 'Vincent',
      state: 'QLD',
    },
    4815: {
      lat: 146.707586,
      lng: -19.372329,
      city: 'Rasmussen',
      state: 'QLD',
    },
    4816: {
      lat: 146.791529,
      lng: -19.787858,
      city: 'Woodstock',
      state: 'QLD',
    },
    4817: {
      lat: 146.652307,
      lng: -19.310498,
      city: 'Thuringowa Central',
      state: 'QLD',
    },
    4818: {
      lat: 146.540275,
      lng: -19.23794,
      city: 'Yabulu',
      state: 'QLD',
    },
    4819: {
      lat: 146.8166793,
      lng: -19.13469076,
      city: 'West Point',
      state: 'QLD',
    },
    4820: {
      lat: 146.2505114,
      lng: -20.09048269,
      city: 'Towers Hill',
      state: 'QLD',
    },
    4821: {
      lat: 144.218437,
      lng: -20.79424,
      city: 'Tangorin',
      state: 'QLD',
    },
    4822: {
      lat: 143.4430132,
      lng: -19.97470077,
      city: 'Woolgar',
      state: 'QLD',
    },
    4823: {
      lat: 141.243755,
      lng: -19.214466,
      city: 'Warburton',
      state: 'QLD',
    },
    4824: {
      lat: 140.652531,
      lng: -20.777471,
      city: 'Three Rivers',
      state: 'QLD',
    },
    4825: {
      lat: 137.432341,
      lng: -21.017327,
      city: 'Winston',
      state: 'QLD',
    },
    4828: {
      lat: 138.350723,
      lng: -19.543752,
      city: 'Camooweal',
      state: 'QLD',
    },
    4829: {
      lat: 140.22124,
      lng: -23.329938,
      city: 'Wills',
      state: 'QLD',
    },
    4830: {
      lat: 138.862024,
      lng: -17.956336,
      city: 'Nicholson',
      state: 'QLD',
    },
    4849: {
      lat: 146.027929,
      lng: -18.265652,
      city: 'Rungoo',
      state: 'QLD',
    },
    4850: {
      lat: 146.49572,
      lng: -18.552533,
      city: 'Yuruga',
      state: 'QLD',
    },
    4852: {
      lat: 146.09996,
      lng: -17.830091,
      city: 'Wongaling Beach',
      state: 'QLD',
    },
    4854: {
      lat: 145.842063,
      lng: -18.020178,
      city: 'Warrami',
      state: 'QLD',
    },
    4855: {
      lat: 146.010914,
      lng: -17.815378,
      city: 'Shell Pocket',
      state: 'QLD',
    },
    4856: {
      lat: 145.924432,
      lng: -17.741424,
      city: 'Walter Lever Estate',
      state: 'QLD',
    },
    4857: {
      lat: 146.059181,
      lng: -17.748858,
      city: 'Silkwood East',
      state: 'QLD',
    },
    4858: {
      lat: 146.078807,
      lng: -17.622583,
      city: 'New Harbourline',
      state: 'QLD',
    },
    4859: {
      lat: 145.959335,
      lng: -17.608772,
      city: 'South Johnstone',
      state: 'QLD',
    },
    4860: {
      lat: 145.888782,
      lng: -17.520343,
      city: 'Wooroonooran',
      state: 'QLD',
    },
    4861: {
      lat: 145.872166,
      lng: -17.352821,
      city: 'East Russell',
      state: 'QLD',
    },
    4865: {
      lat: 145.782765,
      lng: -17.095516,
      city: 'Pyramid',
      state: 'QLD',
    },
    4868: {
      lat: 145.729193,
      lng: -16.993753,
      city: 'Woree',
      state: 'QLD',
    },
    4869: {
      lat: 145.730423,
      lng: -17.025592,
      city: 'Wrights Creek',
      state: 'QLD',
    },
    4870: {
      lat: 145.742303,
      lng: -16.923761,
      city: 'Whitfield',
      state: 'QLD',
    },
    4871: {
      lat: 145.994075,
      lng: -16.934184,
      city: 'Yarrabah',
      state: 'QLD',
    },
    4872: {
      lat: 145.157257,
      lng: -18.028128,
      city: 'Walkamin',
      state: 'QLD',
    },
    4873: {
      lat: 145.4206259,
      lng: -16.34703692,
      city: 'Wonga Beach',
      state: 'QLD',
    },
    4874: {
      lat: 142.6252767,
      lng: -12.49857052,
      city: 'Wenlock',
      state: 'QLD',
    },
    4875: {
      lat: 143.769211,
      lng: -9.584901,
      city: 'Yorke Island',
      state: 'QLD',
    },
    4876: {
      lat: 142.465399,
      lng: -11.33852,
      city: 'Umagico',
      state: 'QLD',
    },
    4877: {
      lat: 145.467604,
      lng: -16.535286,
      city: 'Wangetti',
      state: 'QLD',
    },
    4878: {
      lat: 145.711531,
      lng: -16.839378,
      city: 'Yorkeys Knob',
      state: 'QLD',
    },
    4879: {
      lat: 145.666474,
      lng: -16.789777,
      city: 'Trinity Park',
      state: 'QLD',
    },
    4880: {
      lat: 145.3288229,
      lng: -16.97139021,
      city: 'Paddys Green',
      state: 'QLD',
    },
    4881: {
      lat: 145.6257271,
      lng: -16.89087135,
      city: 'Speewah',
      state: 'QLD',
    },
    4882: {
      lat: 145.47284,
      lng: -17.202517,
      city: 'Tolga',
      state: 'QLD',
    },
    4883: {
      lat: 145.476659,
      lng: -17.33327,
      city: 'Wongabel',
      state: 'QLD',
    },
    4884: {
      lat: 145.5969857,
      lng: -17.27149723,
      city: 'Yungaburra',
      state: 'QLD',
    },
    4885: {
      lat: 145.624143,
      lng: -17.384187,
      city: 'Topaz',
      state: 'QLD',
    },
    4886: {
      lat: 145.633789,
      lng: -17.535676,
      city: 'Mungalli',
      state: 'QLD',
    },
    4887: {
      lat: 145.386636,
      lng: -17.384931,
      city: 'Wondecla',
      state: 'QLD',
    },
    4888: {
      lat: 145.491052,
      lng: -17.499336,
      city: 'Tumoulin',
      state: 'QLD',
    },
    4890: {
      lat: 141.185062,
      lng: -17.741867,
      city: 'Normanton',
      state: 'QLD',
    },
    4891: {
      lat: 140.92883,
      lng: -17.447378,
      city: 'Karumba',
      state: 'QLD',
    },
    4892: {
      lat: 143.3735,
      lng: -14.6604,
      city: 'Yarraden',
      state: 'QLD',
    },
    4895: {
      lat: 145.3121238,
      lng: -15.9723604,
      city: 'Wujal Wujal',
      state: 'QLD',
    },
    5000: {
      lat: 138.608637,
      lng: -34.937459,
      city: 'Sturt Street',
      state: 'SA',
    },
    5001: {
      lat: 139.273782,
      lng: -35.120097,
      city: 'Adelaide',
      state: 'SA',
    },
    5005: {
      lat: 138.60351,
      lng: -34.919398,
      city: 'University Of Adelaide',
      state: 'SA',
    },
    5006: {
      lat: 138.584497,
      lng: -34.911523,
      city: 'North Adelaide Melbourne St',
      state: 'SA',
    },
    5007: {
      lat: 138.56787,
      lng: -34.903259,
      city: 'West Hindmarsh',
      state: 'SA',
    },
    5008: {
      lat: 138.559407,
      lng: -34.887511,
      city: 'West Croydon',
      state: 'SA',
    },
    5009: {
      lat: 138.542493,
      lng: -34.896906,
      city: 'Kilkenny',
      state: 'SA',
    },
    5010: {
      lat: 138.558318,
      lng: -34.857497,
      city: 'Regency Park Bc',
      state: 'SA',
    },
    5011: {
      lat: 138.527677,
      lng: -34.883282,
      city: 'Woodville West',
      state: 'SA',
    },
    5012: {
      lat: 138.541637,
      lng: -34.86303,
      city: 'Woodville North',
      state: 'SA',
    },
    5013: {
      lat: 138.553453,
      lng: -34.841984,
      city: 'Wingfield',
      state: 'SA',
    },
    5014: {
      lat: 138.509247,
      lng: -34.86475,
      city: 'Royal Park',
      state: 'SA',
    },
    5015: {
      lat: 138.496803,
      lng: -34.840209,
      city: 'Torrens Island',
      state: 'SA',
    },
    5016: {
      lat: 138.493796,
      lng: -34.824516,
      city: 'Peterhead',
      state: 'SA',
    },
    5017: {
      lat: 138.498128,
      lng: -34.806494,
      city: 'Taperoo',
      state: 'SA',
    },
    5018: {
      lat: 138.49958,
      lng: -34.779057,
      city: 'Outer Harbor',
      state: 'SA',
    },
    5019: {
      lat: 138.477054,
      lng: -34.847707,
      city: 'Semaphore South',
      state: 'SA',
    },
    5020: {
      lat: 138.481639,
      lng: -34.867318,
      city: 'West Lakes Shore',
      state: 'SA',
    },
    5021: {
      lat: 138.492908,
      lng: -34.86628,
      city: 'West Lakes',
      state: 'SA',
    },
    5022: {
      lat: 138.49264,
      lng: -34.905461,
      city: 'Tennyson',
      state: 'SA',
    },
    5023: {
      lat: 138.51199,
      lng: -34.90073,
      city: 'Seaton North',
      state: 'SA',
    },
    5024: {
      lat: 138.506052,
      lng: -34.948827,
      city: 'West Beach',
      state: 'SA',
    },
    5025: {
      lat: 138.529573,
      lng: -34.912384,
      city: 'Kidman Park',
      state: 'SA',
    },
    5031: {
      lat: 138.570873,
      lng: -34.936926,
      city: 'Torrensville Plaza',
      state: 'SA',
    },
    5032: {
      lat: 138.544358,
      lng: -34.935734,
      city: 'Underdale',
      state: 'SA',
    },
    5033: {
      lat: 138.558451,
      lng: -34.938858,
      city: 'West Richmond',
      state: 'SA',
    },
    5034: {
      lat: 138.58811,
      lng: -34.956227,
      city: 'Wayville',
      state: 'SA',
    },
    5035: {
      lat: 138.575187,
      lng: -34.952075,
      city: 'Keswick Terminal',
      state: 'SA',
    },
    5037: {
      lat: 138.545826,
      lng: -34.957399,
      city: 'North Plympton',
      state: 'SA',
    },
    5038: {
      lat: 138.548367,
      lng: -34.968051,
      city: 'South Plympton',
      state: 'SA',
    },
    5039: {
      lat: 138.570739,
      lng: -34.976263,
      city: 'Melrose Park Dc',
      state: 'SA',
    },
    5040: {
      lat: 138.527412,
      lng: -34.963083,
      city: 'Novar Gardens',
      state: 'SA',
    },
    5041: {
      lat: 138.594176,
      lng: -35.00298,
      city: 'Westbourne Park',
      state: 'SA',
    },
    5042: {
      lat: 138.5709927,
      lng: -35.00971638,
      city: 'Tonsley',
      state: 'SA',
    },
    5043: {
      lat: 138.553853,
      lng: -35.005492,
      city: 'Park Holme',
      state: 'SA',
    },
    5044: {
      lat: 138.516314,
      lng: -35.001213,
      city: 'Somerton Park',
      state: 'SA',
    },
    5045: {
      lat: 138.516973,
      lng: -34.977122,
      city: 'Glenelg South',
      state: 'SA',
    },
    5046: {
      lat: 138.535434,
      lng: -35.003794,
      city: 'Warradale North',
      state: 'SA',
    },
    5047: {
      lat: 138.546572,
      lng: -35.030702,
      city: 'Sturt',
      state: 'SA',
    },
    5048: {
      lat: 138.520459,
      lng: -35.017416,
      city: 'South Brighton',
      state: 'SA',
    },
    5049: {
      lat: 138.52272,
      lng: -35.045389,
      city: 'Seaview Downs',
      state: 'SA',
    },
    5050: {
      lat: 138.601272,
      lng: -35.026307,
      city: 'Eden Hills',
      state: 'SA',
    },
    5051: {
      lat: 138.620405,
      lng: -35.033769,
      city: 'Hawthorndene',
      state: 'SA',
    },
    5052: {
      lat: 138.618684,
      lng: -35.003888,
      city: 'Glenalta',
      state: 'SA',
    },
    5061: {
      lat: 138.60648,
      lng: -34.957195,
      city: 'Unley Park',
      state: 'SA',
    },
    5062: {
      lat: 138.602387,
      lng: -35.004063,
      city: 'Torrens Park',
      state: 'SA',
    },
    5063: {
      lat: 138.621477,
      lng: -34.954325,
      city: 'Parkside',
      state: 'SA',
    },
    5064: {
      lat: 138.649183,
      lng: -34.963195,
      city: 'Urrbrae',
      state: 'SA',
    },
    5065: {
      lat: 138.633974,
      lng: -34.940362,
      city: 'Tusmore',
      state: 'SA',
    },
    5066: {
      lat: 138.66029,
      lng: -34.940962,
      city: 'Wattle Park',
      state: 'SA',
    },
    5067: {
      lat: 138.630575,
      lng: -34.919985,
      city: 'Rose Park',
      state: 'SA',
    },
    5068: {
      lat: 138.654999,
      lng: -34.922605,
      city: 'Trinity Gardens',
      state: 'SA',
    },
    5069: {
      lat: 138.623527,
      lng: -34.907597,
      city: 'Stepney',
      state: 'SA',
    },
    5070: {
      lat: 138.642172,
      lng: -34.900496,
      city: 'Royston Park',
      state: 'SA',
    },
    5071: {
      lat: 138.623005,
      lng: -34.922911,
      city: 'Kent Town Dc',
      state: 'SA',
    },
    5072: {
      lat: 138.696113,
      lng: -34.912078,
      city: 'Woodforde',
      state: 'SA',
    },
    5073: {
      lat: 138.683151,
      lng: -34.89528,
      city: 'Tranmere North',
      state: 'SA',
    },
    5074: {
      lat: 138.669275,
      lng: -34.884233,
      city: 'Newton',
      state: 'SA',
    },
    5075: {
      lat: 138.675254,
      lng: -34.861332,
      city: 'Paradise',
      state: 'SA',
    },
    5076: {
      lat: 138.703683,
      lng: -34.873844,
      city: 'Castambul',
      state: 'SA',
    },
    5081: {
      lat: 138.617673,
      lng: -34.891417,
      city: 'Walkerville',
      state: 'SA',
    },
    5082: {
      lat: 138.587083,
      lng: -34.886018,
      city: 'Thorngate',
      state: 'SA',
    },
    5083: {
      lat: 138.609927,
      lng: -34.879357,
      city: 'Sefton Park',
      state: 'SA',
    },
    5084: {
      lat: 138.581605,
      lng: -34.864409,
      city: 'Kilburn North',
      state: 'SA',
    },
    5085: {
      lat: 138.612959,
      lng: -34.856239,
      city: 'Northgate',
      state: 'SA',
    },
    5086: {
      lat: 138.64344,
      lng: -34.854247,
      city: 'Oakden',
      state: 'SA',
    },
    5087: {
      lat: 138.637707,
      lng: -34.876988,
      city: 'Windsor Gardens',
      state: 'SA',
    },
    5088: {
      lat: 138.673783,
      lng: -34.847141,
      city: 'Holden Hill',
      state: 'SA',
    },
    5089: {
      lat: 138.724487,
      lng: -34.851049,
      city: 'Highbury',
      state: 'SA',
    },
    5090: {
      lat: 138.704628,
      lng: -34.843346,
      city: 'Hope Valley',
      state: 'SA',
    },
    5091: {
      lat: 138.735572,
      lng: -34.822615,
      city: 'Vista',
      state: 'SA',
    },
    5092: {
      lat: 138.680226,
      lng: -34.821319,
      city: 'Modbury North Dc',
      state: 'SA',
    },
    5093: {
      lat: 138.657974,
      lng: -34.83977,
      city: 'Valley View',
      state: 'SA',
    },
    5094: {
      lat: 138.581513,
      lng: -34.802736,
      city: 'Gepps Cross',
      state: 'SA',
    },
    5095: {
      lat: 138.6199,
      lng: -34.820685,
      city: 'The Levels',
      state: 'SA',
    },
    5096: {
      lat: 138.644685,
      lng: -34.798549,
      city: 'Para Hills West',
      state: 'SA',
    },
    5097: {
      lat: 138.707261,
      lng: -34.819614,
      city: 'St Agnes',
      state: 'SA',
    },
    5098: {
      lat: 138.638946,
      lng: -34.829603,
      city: 'Walkley Heights',
      state: 'SA',
    },
    5106: {
      lat: 138.629463,
      lng: -34.793169,
      city: 'Salisbury South Dc',
      state: 'SA',
    },
    5107: {
      lat: 138.609874,
      lng: -34.784447,
      city: 'Parafield Gardens',
      state: 'SA',
    },
    5108: {
      lat: 138.61884,
      lng: -34.761553,
      city: 'Salisbury North Whites Road',
      state: 'SA',
    },
    5109: {
      lat: 138.663871,
      lng: -34.771432,
      city: 'Salisbury Plain',
      state: 'SA',
    },
    5110: {
      lat: 138.565037,
      lng: -34.72213,
      city: 'Waterloo Corner',
      state: 'SA',
    },
    5111: {
      lat: 138.645293,
      lng: -34.716548,
      city: 'Edinburgh Raaf',
      state: 'SA',
    },
    5112: {
      lat: 138.666615,
      lng: -34.726229,
      city: 'Hillbank',
      state: 'SA',
    },
    5113: {
      lat: 138.674449,
      lng: -34.702506,
      city: 'Elizabeth West Dc',
      state: 'SA',
    },
    5114: {
      lat: 138.754254,
      lng: -34.718681,
      city: 'Yattalunga',
      state: 'SA',
    },
    5115: {
      lat: 138.681702,
      lng: -34.669486,
      city: 'Munno Para West',
      state: 'SA',
    },
    5116: {
      lat: 138.724059,
      lng: -34.622783,
      city: 'Hillier',
      state: 'SA',
    },
    5117: {
      lat: 138.636537,
      lng: -34.641267,
      city: 'Angle Vale',
      state: 'SA',
    },
    5118: {
      lat: 138.705675,
      lng: -34.58378,
      city: 'Willaston',
      state: 'SA',
    },
    5120: {
      lat: 138.507899,
      lng: -34.668553,
      city: 'Virginia',
      state: 'SA',
    },
    5121: {
      lat: 138.616157,
      lng: -34.681077,
      city: 'Penfield Gardens',
      state: 'SA',
    },
    5125: {
      lat: 138.738733,
      lng: -34.773234,
      city: 'Greenwith',
      state: 'SA',
    },
    5126: {
      lat: 138.724953,
      lng: -34.793414,
      city: 'Yatala Vale',
      state: 'SA',
    },
    5127: {
      lat: 138.674657,
      lng: -34.796682,
      city: 'Wynn Vale',
      state: 'SA',
    },
    5131: {
      lat: 138.758111,
      lng: -34.795292,
      city: 'Upper Hermitage',
      state: 'SA',
    },
    5132: {
      lat: 138.75703,
      lng: -34.857324,
      city: 'Paracombe',
      state: 'SA',
    },
    5133: {
      lat: 138.776976,
      lng: -34.810928,
      city: 'Inglewood',
      state: 'SA',
    },
    5134: {
      lat: 138.760552,
      lng: -34.891863,
      city: 'Montacute',
      state: 'SA',
    },
    5136: {
      lat: 138.735107,
      lng: -34.921123,
      city: 'Norton Summit',
      state: 'SA',
    },
    5137: {
      lat: 138.735962,
      lng: -34.945777,
      city: 'Marble Hill',
      state: 'SA',
    },
    5138: {
      lat: 138.768586,
      lng: -34.934914,
      city: 'Basket Range',
      state: 'SA',
    },
    5139: {
      lat: 138.788705,
      lng: -34.924843,
      city: 'Forest Range',
      state: 'SA',
    },
    5140: {
      lat: 138.710203,
      lng: -34.939245,
      city: 'Greenhill',
      state: 'SA',
    },
    5141: {
      lat: 138.722419,
      lng: -34.957325,
      city: 'Summertown',
      state: 'SA',
    },
    5142: {
      lat: 138.740223,
      lng: -34.960813,
      city: 'Uraidla',
      state: 'SA',
    },
    5144: {
      lat: 138.769031,
      lng: -34.968504,
      city: 'Carey Gully',
      state: 'SA',
    },
    5150: {
      lat: 138.654942,
      lng: -35.010886,
      city: 'Leawood Gardens',
      state: 'SA',
    },
    5151: {
      lat: 138.723897,
      lng: -34.974283,
      city: 'Piccadilly',
      state: 'SA',
    },
    5152: {
      lat: 138.703957,
      lng: -34.998401,
      city: 'Stirling',
      state: 'SA',
    },
    5153: {
      lat: 138.757945,
      lng: -35.091002,
      city: 'Scott Creek',
      state: 'SA',
    },
    5154: {
      lat: 138.73592,
      lng: -35.023252,
      city: 'Aldgate',
      state: 'SA',
    },
    5155: {
      lat: 138.756728,
      lng: -35.020077,
      city: 'Mount George',
      state: 'SA',
    },
    5156: {
      lat: 138.656697,
      lng: -35.030185,
      city: 'Upper Sturt',
      state: 'SA',
    },
    5157: {
      lat: 138.633118,
      lng: -35.143492,
      city: 'Mcharg Creek',
      state: 'SA',
    },
    5158: {
      lat: 138.519781,
      lng: -35.084001,
      city: 'Trott Park',
      state: 'SA',
    },
    5159: {
      lat: 138.603897,
      lng: -35.061705,
      city: 'Happy Valley',
      state: 'SA',
    },
    5160: {
      lat: 138.50172,
      lng: -35.110123,
      city: 'Port Stanvac',
      state: 'SA',
    },
    5161: {
      lat: 138.515652,
      lng: -35.096383,
      city: 'Reynella East',
      state: 'SA',
    },
    5162: {
      lat: 138.565224,
      lng: -35.112119,
      city: 'Woodcroft',
      state: 'SA',
    },
    5163: {
      lat: 138.552209,
      lng: -35.147923,
      city: 'Onkaparinga Hills',
      state: 'SA',
    },
    5164: {
      lat: 138.500365,
      lng: -35.124824,
      city: 'Christie Downs',
      state: 'SA',
    },
    5165: {
      lat: 138.475092,
      lng: -35.137389,
      city: 'Christies Beach North',
      state: 'SA',
    },
    5166: {
      lat: 138.473209,
      lng: -35.124126,
      city: "O'sullivan Beach",
      state: 'SA',
    },
    5167: {
      lat: 138.481211,
      lng: -35.166866,
      city: 'Port Noarlunga South',
      state: 'SA',
    },
    5168: {
      lat: 138.49632,
      lng: -35.149737,
      city: 'Old Noarlunga',
      state: 'SA',
    },
    5169: {
      lat: 138.477958,
      lng: -35.194211,
      city: 'Seaford Rise',
      state: 'SA',
    },
    5170: {
      lat: 138.478943,
      lng: -35.237265,
      city: 'Maslin Beach',
      state: 'SA',
    },
    5171: {
      lat: 138.553437,
      lng: -35.196481,
      city: 'Tatachilla',
      state: 'SA',
    },
    5172: {
      lat: 138.555169,
      lng: -35.28775,
      city: 'Yundi',
      state: 'SA',
    },
    5173: {
      lat: 138.470002,
      lng: -35.290568,
      city: 'Silver Sands',
      state: 'SA',
    },
    5174: {
      lat: 138.47117,
      lng: -35.330569,
      city: 'Sellicks Hill',
      state: 'SA',
    },
    5201: {
      lat: 138.727179,
      lng: -35.206107,
      city: 'Prospect Hill',
      state: 'SA',
    },
    5202: {
      lat: 138.505922,
      lng: -35.386025,
      city: 'Myponga Beach',
      state: 'SA',
    },
    5203: {
      lat: 138.388132,
      lng: -35.450839,
      city: 'Yankalilla',
      state: 'SA',
    },
    5204: {
      lat: 138.324567,
      lng: -35.572164,
      city: 'Wirrina Cove',
      state: 'SA',
    },
    5210: {
      lat: 138.655684,
      lng: -35.387709,
      city: 'Nangkita',
      state: 'SA',
    },
    5211: {
      lat: 138.627975,
      lng: -35.566153,
      city: 'Yilki',
      state: 'SA',
    },
    5212: {
      lat: 138.668775,
      lng: -35.520797,
      city: 'Port Elliot',
      state: 'SA',
    },
    5213: {
      lat: 138.710195,
      lng: -35.500615,
      city: 'Middleton',
      state: 'SA',
    },
    5214: {
      lat: 138.834806,
      lng: -35.491206,
      city: 'Mundoo Island',
      state: 'SA',
    },
    5220: {
      lat: 137.236746,
      lng: -35.744959,
      city: 'Parndana',
      state: 'SA',
    },
    5221: {
      lat: 137.72022,
      lng: -35.793776,
      city: 'Muston',
      state: 'SA',
    },
    5222: {
      lat: 137.924659,
      lng: -35.803421,
      city: 'Willson River',
      state: 'SA',
    },
    5223: {
      lat: 137.541715,
      lng: -35.811358,
      city: 'Wisanger',
      state: 'SA',
    },
    5231: {
      lat: 138.809837,
      lng: -34.82644568,
      city: 'Millbrook',
      state: 'SA',
    },
    5232: {
      lat: 138.815964,
      lng: -34.855449,
      city: 'Cudlee Creek',
      state: 'SA',
    },
    5233: {
      lat: 138.882403,
      lng: -34.839937,
      city: 'Warren',
      state: 'SA',
    },
    5234: {
      lat: 138.966075,
      lng: -34.805141,
      city: 'Birdwood',
      state: 'SA',
    },
    5235: {
      lat: 139.0896755,
      lng: -34.68706828,
      city: 'Taunton',
      state: 'SA',
    },
    5236: {
      lat: 139.051698,
      lng: -34.829433,
      city: 'Tungkillo',
      state: 'SA',
    },
    5237: {
      lat: 139.191868,
      lng: -34.823114,
      city: 'Sanderston',
      state: 'SA',
    },
    5238: {
      lat: 139.5495493,
      lng: -34.87448967,
      city: 'Younghusband Holdings',
      state: 'SA',
    },
    5240: {
      lat: 138.815277,
      lng: -34.906905,
      city: 'Lenswood',
      state: 'SA',
    },
    5241: {
      lat: 138.864359,
      lng: -34.897167,
      city: 'Lobethal',
      state: 'SA',
    },
    5242: {
      lat: 138.819125,
      lng: -34.998416,
      city: 'Balhannah',
      state: 'SA',
    },
    5243: {
      lat: 138.828161,
      lng: -34.96322,
      city: 'Oakbank',
      state: 'SA',
    },
    5244: {
      lat: 138.946384,
      lng: -34.938664,
      city: 'Woodside',
      state: 'SA',
    },
    5245: {
      lat: 138.791651,
      lng: -35.020412,
      city: 'Verdun',
      state: 'SA',
    },
    5250: {
      lat: 138.8432149,
      lng: -35.04756013,
      city: 'Totness',
      state: 'SA',
    },
    5251: {
      lat: 138.881803,
      lng: -35.093257,
      city: 'Wistow',
      state: 'SA',
    },
    5252: {
      lat: 138.9799078,
      lng: -35.11040704,
      city: 'St Ives',
      state: 'SA',
    },
    5253: {
      lat: 139.273641,
      lng: -35.109083,
      city: 'Woods Point',
      state: 'SA',
    },
    5254: {
      lat: 139.294604,
      lng: -34.96862859,
      city: 'Zadows Landing',
      state: 'SA',
    },
    5255: {
      lat: 138.975861,
      lng: -35.248539,
      city: 'Woodchester',
      state: 'SA',
    },
    5256: {
      lat: 139.0336,
      lng: -35.492114,
      city: 'Tolderol',
      state: 'SA',
    },
    5259: {
      lat: 139.3821462,
      lng: -35.3937685,
      city: 'Wellington East',
      state: 'SA',
    },
    5260: {
      lat: 139.511686,
      lng: -35.233606,
      city: 'Tailem Bend',
      state: 'SA',
    },
    5261: {
      lat: 139.935161,
      lng: -35.59601,
      city: 'Yumali',
      state: 'SA',
    },
    5262: {
      lat: 140.904543,
      lng: -36.780524,
      city: 'Kybybolite',
      state: 'SA',
    },
    5263: {
      lat: 140.838441,
      lng: -37.307042,
      city: 'Coonawarra',
      state: 'SA',
    },
    5264: {
      lat: 139.275737,
      lng: -35.669947,
      city: 'Waltowa',
      state: 'SA',
    },
    5265: {
      lat: 139.743422,
      lng: -35.718823,
      city: 'Field',
      state: 'SA',
    },
    5266: {
      lat: 139.815539,
      lng: -35.902842,
      city: 'Tintinara',
      state: 'SA',
    },
    5267: {
      lat: 140.348707,
      lng: -36.093534,
      city: 'Wirrega',
      state: 'SA',
    },
    5268: {
      lat: 140.788199,
      lng: -36.189376,
      city: 'Western Flat',
      state: 'SA',
    },
    5269: {
      lat: 140.919727,
      lng: -36.343395,
      city: 'Wolseley',
      state: 'SA',
    },
    5270: {
      lat: 140.601672,
      lng: -36.404918,
      city: 'Swede Flat',
      state: 'SA',
    },
    5271: {
      lat: 140.496496,
      lng: -36.792946,
      city: 'Wrattonbully',
      state: 'SA',
    },
    5272: {
      lat: 140.321798,
      lng: -37.162862,
      city: 'Woolumbool',
      state: 'SA',
    },
    5273: {
      lat: 140.246064,
      lng: -36.942586,
      city: 'Avenue Range',
      state: 'SA',
    },
    5275: {
      lat: 139.937126,
      lng: -36.61323,
      city: 'Wyomi',
      state: 'SA',
    },
    5276: {
      lat: 139.917377,
      lng: -37.214147,
      city: 'Robe',
      state: 'SA',
    },
    5277: {
      lat: 140.852283,
      lng: -37.435507,
      city: 'Tarpeena',
      state: 'SA',
    },
    5278: {
      lat: 140.616636,
      lng: -37.527142,
      city: 'Wepar',
      state: 'SA',
    },
    5279: {
      lat: 140.462487,
      lng: -37.574691,
      city: 'Wattle Range East',
      state: 'SA',
    },
    5280: {
      lat: 140.5070183,
      lng: -37.4273323,
      city: 'Wattle Range',
      state: 'SA',
    },
    5290: {
      lat: 140.764545,
      lng: -37.839841,
      city: 'Mount Gambier Dc',
      state: 'SA',
    },
    5291: {
      lat: 140.719634,
      lng: -37.927953,
      city: 'Yahl',
      state: 'SA',
    },
    5301: {
      lat: 140.030554,
      lng: -35.449887,
      city: 'Wilkawatt',
      state: 'SA',
    },
    5302: {
      lat: 140.32532,
      lng: -35.579513,
      city: 'Smithville',
      state: 'SA',
    },
    5303: {
      lat: 140.685318,
      lng: -35.191375,
      city: 'Parilla',
      state: 'SA',
    },
    5304: {
      lat: 140.732182,
      lng: -35.55097,
      city: 'Pinnaroo',
      state: 'SA',
    },
    5306: {
      lat: 139.690888,
      lng: -35.096798,
      city: 'Wynarka',
      state: 'SA',
    },
    5307: {
      lat: 140.242147,
      lng: -35.037558,
      city: 'Mootatunga',
      state: 'SA',
    },
    5308: {
      lat: 140.050653,
      lng: -34.627126,
      city: 'Perponda',
      state: 'SA',
    },
    5309: {
      lat: 140.114394,
      lng: -34.850669,
      city: 'Sandalwood',
      state: 'SA',
    },
    5310: {
      lat: 140.367313,
      lng: -34.794834,
      city: 'Wanbi',
      state: 'SA',
    },
    5311: {
      lat: 140.811093,
      lng: -34.721845,
      city: 'Wunkar',
      state: 'SA',
    },
    5320: {
      lat: 139.6710914,
      lng: -34.11697257,
      city: 'Wombats Rest',
      state: 'SA',
    },
    5321: {
      lat: 139.7548023,
      lng: -34.0322977,
      city: 'Cadell Lagoon',
      state: 'SA',
    },
    5322: {
      lat: 139.955939,
      lng: -34.177881,
      city: 'Sunlands',
      state: 'SA',
    },
    5330: {
      lat: 139.975421,
      lng: -33.94707,
      city: 'Woolpunda',
      state: 'SA',
    },
    5331: {
      lat: 140.319351,
      lng: -34.214119,
      city: 'Kingston-on-murray',
      state: 'SA',
    },
    5332: {
      lat: 140.28104,
      lng: -34.251101,
      city: 'Yinkanie',
      state: 'SA',
    },
    5333: {
      lat: 140.63513,
      lng: -34.485635,
      city: 'Taplan',
      state: 'SA',
    },
    5340: {
      lat: 140.8905312,
      lng: -34.28950256,
      city: 'Yamba',
      state: 'SA',
    },
    5341: {
      lat: 140.753178,
      lng: -33.739084,
      city: 'Yamba',
      state: 'SA',
    },
    5342: {
      lat: 140.551874,
      lng: -34.240296,
      city: 'Monash',
      state: 'SA',
    },
    5343: {
      lat: 140.556181,
      lng: -34.321288,
      city: 'Winkie',
      state: 'SA',
    },
    5344: {
      lat: 140.51443,
      lng: -34.285345,
      city: 'Glossop',
      state: 'SA',
    },
    5345: {
      lat: 140.454506,
      lng: -34.187027,
      city: 'Spectacle Lake',
      state: 'SA',
    },
    5346: {
      lat: 140.397201,
      lng: -34.250013,
      city: 'Cobdogla',
      state: 'SA',
    },
    5350: {
      lat: 138.83423,
      lng: -34.550897,
      city: 'Sandy Creek',
      state: 'SA',
    },
    5351: {
      lat: 138.897802,
      lng: -34.638218,
      city: 'Williamstown',
      state: 'SA',
    },
    5352: {
      lat: 139.0044687,
      lng: -34.52155944,
      city: 'Vine Vale',
      state: 'SA',
    },
    5353: {
      lat: 139.326979,
      lng: -34.577328,
      city: 'Towitta',
      state: 'SA',
    },
    5354: {
      lat: 139.652642,
      lng: -34.538013,
      city: 'Swan Reach',
      state: 'SA',
    },
    5355: {
      lat: 139.007886,
      lng: -34.443966,
      city: 'Stockwell',
      state: 'SA',
    },
    5356: {
      lat: 139.240946,
      lng: -34.353109,
      city: 'Truro',
      state: 'SA',
    },
    5357: {
      lat: 139.525937,
      lng: -34.343393,
      city: 'Wigley Flat',
      state: 'SA',
    },
    5360: {
      lat: 138.897955,
      lng: -34.446813,
      city: 'Nain',
      state: 'SA',
    },
    5371: {
      lat: 138.765349,
      lng: -34.517974,
      city: 'Templers',
      state: 'SA',
    },
    5372: {
      lat: 138.798551,
      lng: -34.435921,
      city: 'Freeling',
      state: 'SA',
    },
    5373: {
      lat: 138.930335,
      lng: -34.303007,
      city: 'St Johns',
      state: 'SA',
    },
    5374: {
      lat: 139.12488,
      lng: -34.152477,
      city: 'Sutherlands',
      state: 'SA',
    },
    5381: {
      lat: 139.140266,
      lng: -33.965473,
      city: 'Worlds End Creek',
      state: 'SA',
    },
    5400: {
      lat: 138.6448171,
      lng: -34.48615107,
      city: 'Woolsheds',
      state: 'SA',
    },
    5401: {
      lat: 138.659866,
      lng: -34.29774,
      city: 'Salter Springs',
      state: 'SA',
    },
    5410: {
      lat: 138.768325,
      lng: -34.366816,
      city: 'Stockport',
      state: 'SA',
    },
    5411: {
      lat: 138.772345,
      lng: -34.256363,
      city: 'Tarlee',
      state: 'SA',
    },
    5412: {
      lat: 138.775266,
      lng: -34.18289,
      city: 'Woolshed Flat',
      state: 'SA',
    },
    5413: {
      lat: 138.883459,
      lng: -34.080278,
      city: 'Waterloo',
      state: 'SA',
    },
    5414: {
      lat: 138.773663,
      lng: -33.949431,
      city: 'Manoora',
      state: 'SA',
    },
    5415: {
      lat: 138.722876,
      lng: -33.942677,
      city: 'Stanley',
      state: 'SA',
    },
    5416: {
      lat: 138.8771899,
      lng: -33.82344346,
      city: 'Porter Lagoon',
      state: 'SA',
    },
    5417: {
      lat: 139.5284697,
      lng: -33.5536325,
      city: 'Warnes',
      state: 'SA',
    },
    5418: {
      lat: 139.143828,
      lng: -33.357329,
      city: 'Mount Bryan',
      state: 'SA',
    },
    5419: {
      lat: 138.893337,
      lng: -33.397741,
      city: 'Wonna',
      state: 'SA',
    },
    5420: {
      lat: 138.961232,
      lng: -33.252592,
      city: 'Whyte Yarcowie',
      state: 'SA',
    },
    5421: {
      lat: 138.985832,
      lng: -33.13467,
      city: 'Terowie',
      state: 'SA',
    },
    5422: {
      lat: 138.771209,
      lng: -32.97338,
      city: 'Yatina',
      state: 'SA',
    },
    5431: {
      lat: 138.883978,
      lng: -32.212021,
      city: 'Yalpara',
      state: 'SA',
    },
    5432: {
      lat: 138.468686,
      lng: -32.242313,
      city: 'Yanyarrie',
      state: 'SA',
    },
    5433: {
      lat: 138.1056,
      lng: -32.180856,
      city: 'Yarrah',
      state: 'SA',
    },
    5434: {
      lat: 138.70161,
      lng: -31.88228497,
      city: 'Worumba',
      state: 'SA',
    },
    5440: {
      lat: 140.027346,
      lng: -32.339759,
      city: 'Yunta',
      state: 'SA',
    },
    5451: {
      lat: 138.620883,
      lng: -34.092233,
      city: 'Undalya',
      state: 'SA',
    },
    5452: {
      lat: 138.652781,
      lng: -33.969454,
      city: 'Watervale',
      state: 'SA',
    },
    5453: {
      lat: 138.582681,
      lng: -33.802932,
      city: 'Stanley Flat',
      state: 'SA',
    },
    5454: {
      lat: 138.5946476,
      lng: -33.37077758,
      city: 'Washpool',
      state: 'SA',
    },
    5455: {
      lat: 138.633474,
      lng: -33.673948,
      city: 'Hilltown',
      state: 'SA',
    },
    5460: {
      lat: 138.517968,
      lng: -34.312427,
      city: 'Stockyard Creek',
      state: 'SA',
    },
    5461: {
      lat: 138.444457,
      lng: -34.095314,
      city: 'Whitwarta',
      state: 'SA',
    },
    5462: {
      lat: 138.43015,
      lng: -33.896378,
      city: 'Blyth',
      state: 'SA',
    },
    5464: {
      lat: 138.39411,
      lng: -33.676424,
      city: 'Rochester',
      state: 'SA',
    },
    5470: {
      lat: 138.482681,
      lng: -33.575379,
      city: 'Yacka',
      state: 'SA',
    },
    5471: {
      lat: 138.424322,
      lng: -33.47611,
      city: 'Gulnare',
      state: 'SA',
    },
    5472: {
      lat: 138.410287,
      lng: -33.387234,
      city: 'Georgetown',
      state: 'SA',
    },
    5473: {
      lat: 138.351282,
      lng: -33.257131,
      city: 'Gladstone',
      state: 'SA',
    },
    5480: {
      lat: 138.319834,
      lng: -33.125751,
      city: 'Stone Hut',
      state: 'SA',
    },
    5481: {
      lat: 138.239719,
      lng: -33.012446,
      city: 'Wongyarra',
      state: 'SA',
    },
    5482: {
      lat: 138.357347,
      lng: -32.842201,
      city: 'Wepowie',
      state: 'SA',
    },
    5483: {
      lat: 138.12371,
      lng: -32.8863,
      city: 'Melrose',
      state: 'SA',
    },
    5485: {
      lat: 138.207041,
      lng: -32.546383,
      city: 'Wilmington',
      state: 'SA',
    },
    5490: {
      lat: 138.3910467,
      lng: -33.15461443,
      city: 'Caltowie West',
      state: 'SA',
    },
    5491: {
      lat: 138.4941508,
      lng: -33.30647586,
      city: 'West Bundaleer',
      state: 'SA',
    },
    5493: {
      lat: 138.746595,
      lng: -33.015031,
      city: 'Yongala',
      state: 'SA',
    },
    5495: {
      lat: 138.0327999,
      lng: -33.10214154,
      city: 'Weeroona Island',
      state: 'SA',
    },
    5501: {
      lat: 138.547998,
      lng: -34.595073,
      city: 'Windsor',
      state: 'SA',
    },
    5502: {
      lat: 138.560286,
      lng: -34.507413,
      city: 'Reeves Plains',
      state: 'SA',
    },
    5510: {
      lat: 138.188988,
      lng: -33.9491,
      city: 'Lochiel',
      state: 'SA',
    },
    5520: {
      lat: 138.12018,
      lng: -33.769107,
      city: 'Wokurna',
      state: 'SA',
    },
    5521: {
      lat: 138.210043,
      lng: -33.567546,
      city: 'Redhill',
      state: 'SA',
    },
    5522: {
      lat: 138.003325,
      lng: -33.578188,
      city: 'Ward Hill',
      state: 'SA',
    },
    5523: {
      lat: 137.9305289,
      lng: -33.40073023,
      city: 'Wandearah West',
      state: 'SA',
    },
    5540: {
      lat: 138.080191,
      lng: -33.205773,
      city: 'Warnertown',
      state: 'SA',
    },
    5550: {
      lat: 138.243785,
      lng: -34.093038,
      city: 'South Hummocks',
      state: 'SA',
    },
    5552: {
      lat: 137.862942,
      lng: -34.061253,
      city: 'Thrington',
      state: 'SA',
    },
    5554: {
      lat: 137.756193,
      lng: -33.928029,
      city: 'Willamulka',
      state: 'SA',
    },
    5555: {
      lat: 138.191161,
      lng: -33.656811,
      city: 'Winulta',
      state: 'SA',
    },
    5556: {
      lat: 137.610828,
      lng: -33.950853,
      city: 'Warburto',
      state: 'SA',
    },
    5558: {
      lat: 137.598449,
      lng: -34.105205,
      city: 'Yelta',
      state: 'SA',
    },
    5560: {
      lat: 138.031867,
      lng: -33.895055,
      city: 'Ninnes',
      state: 'SA',
    },
    5570: {
      lat: 137.8992912,
      lng: -34.27432669,
      city: 'Winulta',
      state: 'SA',
    },
    5571: {
      lat: 137.811541,
      lng: -34.51494,
      city: 'Tiddy Widdy Beach',
      state: 'SA',
    },
    5572: {
      lat: 137.812526,
      lng: -34.237139,
      city: 'Port Arthur',
      state: 'SA',
    },
    5573: {
      lat: 137.508603,
      lng: -34.514149,
      city: 'Yorke Valley',
      state: 'SA',
    },
    5575: {
      lat: 137.544605,
      lng: -34.823141,
      city: 'Wool Bay',
      state: 'SA',
    },
    5576: {
      lat: 137.56046,
      lng: -35.050773,
      city: 'Yorketown',
      state: 'SA',
    },
    5577: {
      lat: 137.310027,
      lng: -35.035673,
      city: 'Warooka',
      state: 'SA',
    },
    5580: {
      lat: 137.8689205,
      lng: -34.64760231,
      city: 'Port Julia',
      state: 'SA',
    },
    5581: {
      lat: 137.748829,
      lng: -34.789625,
      city: 'Sheaoak Flat',
      state: 'SA',
    },
    5582: {
      lat: 137.734592,
      lng: -34.94231,
      city: 'Stansbury',
      state: 'SA',
    },
    5583: {
      lat: 137.7390799,
      lng: -35.1183494,
      city: 'Sultana Point',
      state: 'SA',
    },
    5600: {
      lat: 137.277293,
      lng: -33.036339,
      city: 'Whyalla Playford',
      state: 'SA',
    },
    5601: {
      lat: 137.5647905,
      lng: -32.97404304,
      city: 'Whyalla Barson',
      state: 'SA',
    },
    5602: {
      lat: 136.860876,
      lng: -33.530464,
      city: 'Port Gibbon',
      state: 'SA',
    },
    5603: {
      lat: 136.499961,
      lng: -33.831212,
      city: 'Wharminda',
      state: 'SA',
    },
    5604: {
      lat: 136.301759,
      lng: -34.00333,
      city: 'Port Neill',
      state: 'SA',
    },
    5605: {
      lat: 136.154091,
      lng: -34.2583,
      city: 'Tumby Bay',
      state: 'SA',
    },
    5606: {
      lat: 136.4660851,
      lng: -35.15635681,
      city: 'Wedge Island',
      state: 'SA',
    },
    5607: {
      lat: 136.456454,
      lng: -35.156446,
      city: 'Yallunda Flat',
      state: 'SA',
    },
    5608: {
      lat: 137.525354,
      lng: -33.034213,
      city: 'Whyalla Stuart',
      state: 'SA',
    },
    5609: {
      lat: 137.512634,
      lng: -33.021612,
      city: 'Whyalla Jenkins',
      state: 'SA',
    },
    5611: {
      lat: 136.8962127,
      lng: -33.25171556,
      city: 'Secret Rocks',
      state: 'SA',
    },
    5630: {
      lat: 135.6694704,
      lng: -34.41994907,
      city: 'Edillilie',
      state: 'SA',
    },
    5631: {
      lat: 135.66453,
      lng: -34.285785,
      city: 'Cummins',
      state: 'SA',
    },
    5632: {
      lat: 135.635831,
      lng: -34.129198,
      city: 'Yeelanna',
      state: 'SA',
    },
    5633: {
      lat: 135.7111465,
      lng: -33.4173351,
      city: 'Ulyerra',
      state: 'SA',
    },
    5640: {
      lat: 136.437661,
      lng: -33.606021,
      city: 'Waddikee',
      state: 'SA',
    },
    5641: {
      lat: 136.3112934,
      lng: -32.78078835,
      city: 'Yeltana',
      state: 'SA',
    },
    5642: {
      lat: 136.103172,
      lng: -33.558726,
      city: 'Rudall',
      state: 'SA',
    },
    5650: {
      lat: 135.740152,
      lng: -33.152294,
      city: 'Warramboo',
      state: 'SA',
    },
    5651: {
      lat: 135.528428,
      lng: -33.169608,
      city: 'Kyancutta',
      state: 'SA',
    },
    5652: {
      lat: 135.249834,
      lng: -33.091309,
      city: 'Wudinna',
      state: 'SA',
    },
    5653: {
      lat: 135.229267,
      lng: -32.967987,
      city: 'Yaninee',
      state: 'SA',
    },
    5654: {
      lat: 135.02092,
      lng: -32.833204,
      city: 'Mount Damper',
      state: 'SA',
    },
    5655: {
      lat: 134.790236,
      lng: -32.708545,
      city: 'Pygery',
      state: 'SA',
    },
    5660: {
      lat: 134.650428,
      lng: -32.544993,
      city: 'Cungena',
      state: 'SA',
    },
    5661: {
      lat: 134.539431,
      lng: -32.360111,
      city: 'Yantanabie',
      state: 'SA',
    },
    5670: {
      lat: 135.02078,
      lng: -33.409359,
      city: 'Ulyerra',
      state: 'SA',
    },
    5671: {
      lat: 134.539577,
      lng: -33.13972293,
      city: 'Witera',
      state: 'SA',
    },
    5680: {
      lat: 134.2389533,
      lng: -32.93215968,
      city: 'Yanerbie',
      state: 'SA',
    },
    5690: {
      lat: 133.6102266,
      lng: -31.68926163,
      city: 'Yumbarra',
      state: 'SA',
    },
    5700: {
      lat: 137.9070038,
      lng: -32.62227011,
      city: 'Winninowie',
      state: 'SA',
    },
    5701: {
      lat: 137.961,
      lng: -32.5778,
      city: 'Woolundunga',
      state: 'SA',
    },
    5710: {
      lat: 134.430044,
      lng: -27.467217,
      city: 'Tarcoola',
      state: 'SA',
    },
    5713: {
      lat: 137.8624,
      lng: -31.8966,
      city: 'Yadlamalka',
      state: 'SA',
    },
    5715: {
      lat: 137.0666,
      lng: -32.115,
      city: 'Yudnapinna',
      state: 'SA',
    },
    5717: { lat: 135.5849, lng: -32.2797, city: 'Yardea', state: 'SA' },
    5719: {
      lat: 136.3252,
      lng: -31.2327,
      city: 'Wirraminna',
      state: 'SA',
    },
    5720: {
      lat: 136.05216,
      lng: -30.499936,
      city: 'Woomera',
      state: 'SA',
    },
    5722: {
      lat: 137.1683897,
      lng: -30.65609741,
      city: 'Andamooka Station',
      state: 'SA',
    },
    5723: {
      lat: 133.841904,
      lng: -27.80520392,
      city: 'Wintinna',
      state: 'SA',
    },
    5724: {
      lat: 133.9139,
      lng: -27.39572544,
      city: 'Welbourn Hill',
      state: 'SA',
    },
    5725: {
      lat: 136.6673261,
      lng: -30.62777096,
      city: 'Roxby Downs Station',
      state: 'SA',
    },
    5730: {
      lat: 139.1395749,
      lng: -31.20746884,
      city: 'Wirrealpa',
      state: 'SA',
    },
    5731: {
      lat: 139.666239,
      lng: -29.818331,
      city: 'Witchelina',
      state: 'SA',
    },
    5732: {
      lat: 138.9647658,
      lng: -30.33286364,
      city: 'Yankaninna',
      state: 'SA',
    },
    5733: {
      lat: 139.7621514,
      lng: -26.37140727,
      city: 'Pandie Pandie',
      state: 'SA',
    },
    5734: {
      lat: 135.675034,
      lng: -26.33851018,
      city: 'Witjira',
      state: 'SA',
    },
    5800: {
      lat: 139.273782,
      lng: -35.120097,
      city: 'Adelaide',
      state: 'SA',
    },
    5801: { lat: 0, lng: 0, city: 'Adelaide', state: 'SA' },
    5810: {
      lat: 139.273782,
      lng: -35.120097,
      city: 'Adelaide',
      state: 'SA',
    },
    5839: {
      lat: 139.273782,
      lng: -35.120097,
      city: 'Adelaide',
      state: 'SA',
    },
    5942: {
      lat: 138.565906,
      lng: -34.860017,
      city: 'Regency Park',
      state: 'SA',
    },
    5950: {
      lat: 138.530183,
      lng: -34.945146,
      city: 'Export Park',
      state: 'SA',
    },
    6000: {
      lat: 115.859912,
      lng: -31.948762,
      city: 'Perth Gpo',
      state: 'WA',
    },
    6001: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6003: {
      lat: 115.869136,
      lng: -31.939272,
      city: 'Northbridge',
      state: 'WA',
    },
    6004: {
      lat: 115.874601,
      lng: -31.956931,
      city: 'East Perth',
      state: 'WA',
    },
    6005: {
      lat: 115.836896,
      lng: -31.95707,
      city: 'West Perth',
      state: 'WA',
    },
    6006: {
      lat: 115.852913,
      lng: -31.92934,
      city: 'North Perth',
      state: 'WA',
    },
    6007: {
      lat: 115.834335,
      lng: -31.935675,
      city: 'West Leederville',
      state: 'WA',
    },
    6008: {
      lat: 115.811432,
      lng: -31.956599,
      city: 'Subiaco East',
      state: 'WA',
    },
    6009: {
      lat: 115.804692,
      lng: -31.985791,
      city: 'Nedlands Dc',
      state: 'WA',
    },
    6010: {
      lat: 115.77639,
      lng: -31.971647,
      city: 'Swanbourne',
      state: 'WA',
    },
    6011: {
      lat: 115.757646,
      lng: -31.996371,
      city: 'Peppermint Grove',
      state: 'WA',
    },
    6012: {
      lat: 115.763404,
      lng: -32.015976,
      city: 'Mosman Park',
      state: 'WA',
    },
    6014: {
      lat: 115.808169,
      lng: -31.936389,
      city: 'Wembley',
      state: 'WA',
    },
    6015: {
      lat: 115.764683,
      lng: -31.938454,
      city: 'City Beach',
      state: 'WA',
    },
    6016: {
      lat: 115.831181,
      lng: -31.921222,
      city: 'Mount Hawthorn',
      state: 'WA',
    },
    6017: {
      lat: 115.814562,
      lng: -31.908357,
      city: 'Osborne Park Dc',
      state: 'WA',
    },
    6018: {
      lat: 115.786821,
      lng: -31.891957,
      city: 'Woodlands',
      state: 'WA',
    },
    6019: {
      lat: 115.769183,
      lng: -31.90733,
      city: 'Wembley Downs',
      state: 'WA',
    },
    6020: {
      lat: 115.765396,
      lng: -31.852698,
      city: 'Watermans Bay',
      state: 'WA',
    },
    6021: {
      lat: 115.811933,
      lng: -31.871671,
      city: 'Stirling',
      state: 'WA',
    },
    6022: {
      lat: 115.80924,
      lng: -31.851998,
      city: 'Hamersley',
      state: 'WA',
    },
    6023: {
      lat: 115.774028,
      lng: -31.834469,
      city: 'Glengarry',
      state: 'WA',
    },
    6024: {
      lat: 115.803068,
      lng: -31.83489,
      city: 'Warwick',
      state: 'WA',
    },
    6025: {
      lat: 115.754405,
      lng: -31.79583,
      city: 'Padbury',
      state: 'WA',
    },
    6026: {
      lat: 115.799832,
      lng: -31.801707,
      city: 'Woodvale',
      state: 'WA',
    },
    6027: {
      lat: 115.756405,
      lng: -31.763347,
      city: 'Ocean Reef',
      state: 'WA',
    },
    6028: {
      lat: 115.747655,
      lng: -31.733717,
      city: 'Kinross',
      state: 'WA',
    },
    6029: {
      lat: 115.755689,
      lng: -31.876897,
      city: 'Trigg',
      state: 'WA',
    },
    6030: {
      lat: 115.711684,
      lng: -31.687341,
      city: 'Tamala Park',
      state: 'WA',
    },
    6031: {
      lat: 115.772138,
      lng: -31.692538,
      city: 'Neerabup',
      state: 'WA',
    },
    6032: {
      lat: 115.74887,
      lng: -31.643227,
      city: 'Nowergup',
      state: 'WA',
    },
    6033: {
      lat: 115.711357,
      lng: -31.609115,
      city: 'Carabooda',
      state: 'WA',
    },
    6034: {
      lat: 115.663878,
      lng: -31.585614,
      city: 'Eglinton',
      state: 'WA',
    },
    6035: {
      lat: 115.729901,
      lng: -31.524716,
      city: 'Yanchep',
      state: 'WA',
    },
    6036: {
      lat: 115.70986,
      lng: -31.649385,
      city: 'Jindalee',
      state: 'WA',
    },
    6037: {
      lat: 115.616105,
      lng: -31.48847,
      city: 'Two Rocks',
      state: 'WA',
    },
    6038: {
      lat: 115.723047,
      lng: -32.289149,
      city: 'Alkimos',
      state: 'WA',
    },
    6041: {
      lat: 115.568551,
      lng: -31.379673,
      city: 'Woodridge',
      state: 'WA',
    },
    6042: {
      lat: 115.508625,
      lng: -31.254414,
      city: 'Seabird',
      state: 'WA',
    },
    6043: {
      lat: 115.424522,
      lng: -31.141763,
      city: 'Ledge Point',
      state: 'WA',
    },
    6044: {
      lat: 115.424656,
      lng: -30.972846,
      city: 'Wedge Island',
      state: 'WA',
    },
    6050: {
      lat: 115.871864,
      lng: -31.930574,
      city: 'Mount Lawley',
      state: 'WA',
    },
    6051: {
      lat: 115.899589,
      lng: -31.938107,
      city: 'Maylands',
      state: 'WA',
    },
    6052: {
      lat: 115.883976,
      lng: -31.915463,
      city: 'Inglewood',
      state: 'WA',
    },
    6053: {
      lat: 115.914851,
      lng: -31.920863,
      city: 'Bayswater',
      state: 'WA',
    },
    6054: {
      lat: 115.942572,
      lng: -31.901801,
      city: 'Lockridge',
      state: 'WA',
    },
    6055: {
      lat: 115.980754,
      lng: -31.842527,
      city: 'West Swan',
      state: 'WA',
    },
    6056: {
      lat: 116.0261,
      lng: -31.838398,
      city: 'Woodbridge',
      state: 'WA',
    },
    6057: {
      lat: 116.004245,
      lng: -31.94994,
      city: 'Maida Vale',
      state: 'WA',
    },
    6058: {
      lat: 116.006688,
      lng: -31.985241,
      city: 'Forrestfield',
      state: 'WA',
    },
    6059: {
      lat: 115.861726,
      lng: -31.882862,
      city: 'Dianella',
      state: 'WA',
    },
    6060: {
      lat: 115.84939,
      lng: -31.89415,
      city: 'Yokine South',
      state: 'WA',
    },
    6061: {
      lat: 115.843478,
      lng: -31.864113,
      city: 'Westminster',
      state: 'WA',
    },
    6062: {
      lat: 115.89088,
      lng: -31.886322,
      city: 'Noranda',
      state: 'WA',
    },
    6063: {
      lat: 115.9421996,
      lng: -31.8589186,
      city: 'Bennett Springs',
      state: 'WA',
    },
    6064: {
      lat: 115.849098,
      lng: -31.835333,
      city: 'Marangaroo',
      state: 'WA',
    },
    6065: {
      lat: 115.845329,
      lng: -31.69444,
      city: 'Wanneroo',
      state: 'WA',
    },
    6066: {
      lat: 115.896508,
      lng: -31.84269,
      city: 'Ballajura',
      state: 'WA',
    },
    6067: {
      lat: 115.899644,
      lng: -31.816059,
      city: 'Cullacabardee',
      state: 'WA',
    },
    6068: {
      lat: 115.941108,
      lng: -31.826199,
      city: 'Whiteman',
      state: 'WA',
    },
    6069: {
      lat: 116.007663,
      lng: -31.771612,
      city: 'Upper Swan',
      state: 'WA',
    },
    6070: {
      lat: 116.079266,
      lng: -31.929036,
      city: 'Darlington',
      state: 'WA',
    },
    6071: {
      lat: 116.100432,
      lng: -31.889087,
      city: 'Hovea',
      state: 'WA',
    },
    6072: {
      lat: 116.132045,
      lng: -31.907828,
      city: 'Mahogany Creek',
      state: 'WA',
    },
    6073: {
      lat: 116.28143,
      lng: -31.949349,
      city: 'Mundaring Weir',
      state: 'WA',
    },
    6074: {
      lat: 116.20454,
      lng: -31.896593,
      city: 'Sawyers Valley',
      state: 'WA',
    },
    6076: {
      lat: 116.208263,
      lng: -32.108961,
      city: 'Walliston Dc',
      state: 'WA',
    },
    6077: {
      lat: 115.845329,
      lng: -31.69444,
      city: 'Jandabup',
      state: 'WA',
    },
    6078: {
      lat: 115.845329,
      lng: -31.69444,
      city: 'Pinjar',
      state: 'WA',
    },
    6079: {
      lat: 115.845329,
      lng: -31.69444,
      city: 'Melaleuca',
      state: 'WA',
    },
    6081: {
      lat: 116.146074,
      lng: -31.876046,
      city: 'Stoneville',
      state: 'WA',
    },
    6082: {
      lat: 116.295706,
      lng: -31.738368,
      city: 'Mount Helena',
      state: 'WA',
    },
    6083: {
      lat: 116.196639,
      lng: -31.793051,
      city: 'Morangup',
      state: 'WA',
    },
    6084: {
      lat: 116.072828,
      lng: -31.622021,
      city: 'Walyunga National Park',
      state: 'WA',
    },
    6090: {
      lat: 115.894254,
      lng: -31.862589,
      city: 'Malaga',
      state: 'WA',
    },
    6100: {
      lat: 115.896029,
      lng: -31.971725,
      city: 'Victoria Park',
      state: 'WA',
    },
    6101: {
      lat: 115.908576,
      lng: -31.985247,
      city: 'East Victoria Park',
      state: 'WA',
    },
    6102: {
      lat: 115.904425,
      lng: -32.005644,
      city: 'St James',
      state: 'WA',
    },
    6103: {
      lat: 115.913458,
      lng: -31.960364,
      city: 'Rivervale',
      state: 'WA',
    },
    6104: {
      lat: 115.977633,
      lng: -31.965498,
      city: 'Redcliffe',
      state: 'WA',
    },
    6105: {
      lat: 115.963293,
      lng: -31.95388,
      city: 'Perth Airport',
      state: 'WA',
    },
    6106: {
      lat: 115.945564,
      lng: -31.992082,
      city: 'Welshpool Dc',
      state: 'WA',
    },
    6107: {
      lat: 115.967142,
      lng: -32.017663,
      city: 'Wilson',
      state: 'WA',
    },
    6108: {
      lat: 115.953584,
      lng: -32.06089,
      city: 'Thornlie',
      state: 'WA',
    },
    6109: {
      lat: 116.013165,
      lng: -32.039897,
      city: 'Orange Grove',
      state: 'WA',
    },
    6110: {
      lat: 116.008603,
      lng: -32.078535,
      city: 'Southern River',
      state: 'WA',
    },
    6111: {
      lat: 116.095955,
      lng: -32.09788,
      city: 'Westfield',
      state: 'WA',
    },
    6112: {
      lat: 115.997934,
      lng: -32.158675,
      city: 'Wungong',
      state: 'WA',
    },
    6121: {
      lat: 115.916459,
      lng: -32.208934,
      city: 'Oldbury',
      state: 'WA',
    },
    6122: {
      lat: 116.0072,
      lng: -32.221725,
      city: 'Karrakup',
      state: 'WA',
    },
    6123: {
      lat: 115.98589,
      lng: -32.295176,
      city: 'Whitby',
      state: 'WA',
    },
    6124: {
      lat: 116.072494,
      lng: -32.338316,
      city: 'Jarrahdale',
      state: 'WA',
    },
    6125: {
      lat: 115.901277,
      lng: -32.364427,
      city: 'Serpentine',
      state: 'WA',
    },
    6126: {
      lat: 115.977043,
      lng: -32.440033,
      city: 'Keysbrook',
      state: 'WA',
    },
    6147: {
      lat: 115.941353,
      lng: -32.043771,
      city: 'Parkwood',
      state: 'WA',
    },
    6148: {
      lat: 115.924691,
      lng: -32.030183,
      city: 'Shelley',
      state: 'WA',
    },
    6149: {
      lat: 115.858679,
      lng: -32.064941,
      city: 'Leeming',
      state: 'WA',
    },
    6150: {
      lat: 115.833608,
      lng: -32.061682,
      city: 'Winthrop',
      state: 'WA',
    },
    6151: {
      lat: 115.869482,
      lng: -31.982199,
      city: 'South Perth Angelo St',
      state: 'WA',
    },
    6152: {
      lat: 115.870058,
      lng: -32.008509,
      city: 'Waterford',
      state: 'WA',
    },
    6153: {
      lat: 115.837525,
      lng: -32.024363,
      city: 'Mount Pleasant',
      state: 'WA',
    },
    6154: {
      lat: 115.825994,
      lng: -32.040061,
      city: 'Myaree',
      state: 'WA',
    },
    6155: {
      lat: 115.890779,
      lng: -32.061501,
      city: 'Willetton',
      state: 'WA',
    },
    6156: {
      lat: 115.800233,
      lng: -32.03954,
      city: 'Willagee Central',
      state: 'WA',
    },
    6157: {
      lat: 115.783732,
      lng: -32.038228,
      city: 'Palmyra Dc',
      state: 'WA',
    },
    6158: {
      lat: 115.766561,
      lng: -32.038541,
      city: 'East Fremantle',
      state: 'WA',
    },
    6159: {
      lat: 115.750311,
      lng: -32.035224,
      city: 'North Fremantle',
      state: 'WA',
    },
    6160: {
      lat: 115.754545,
      lng: -32.054065,
      city: 'Fremantle',
      state: 'WA',
    },
    6161: {
      lat: 115.520516,
      lng: -32.005554,
      city: 'Rottnest Island',
      state: 'WA',
    },
    6162: {
      lat: 115.759022,
      lng: -32.068768,
      city: 'White Gum Valley',
      state: 'WA',
    },
    6163: {
      lat: 115.798722,
      lng: -32.084784,
      city: 'Spearwood',
      state: 'WA',
    },
    6164: {
      lat: 115.854584,
      lng: -32.131993,
      city: 'Yangebup',
      state: 'WA',
    },
    6165: {
      lat: 115.799431,
      lng: -32.19598,
      city: 'Naval Base',
      state: 'WA',
    },
    6166: {
      lat: 115.790704,
      lng: -32.158361,
      city: 'Wattleup',
      state: 'WA',
    },
    6167: {
      lat: 115.835308,
      lng: -32.223657,
      city: 'Wandi',
      state: 'WA',
    },
    6168: {
      lat: 115.75773,
      lng: -32.281957,
      city: 'Rockingham Dc',
      state: 'WA',
    },
    6169: {
      lat: 115.763949,
      lng: -32.338504,
      city: 'Warnbro',
      state: 'WA',
    },
    6170: {
      lat: 115.835972,
      lng: -32.265645,
      city: 'Wellard',
      state: 'WA',
    },
    6171: {
      lat: 115.833257,
      lng: -32.328863,
      city: 'Baldivis',
      state: 'WA',
    },
    6172: {
      lat: 115.753147,
      lng: -32.37547,
      city: 'Port Kennedy',
      state: 'WA',
    },
    6173: {
      lat: 115.757908,
      lng: -32.407414,
      city: 'Secret Harbour',
      state: 'WA',
    },
    6174: {
      lat: 115.760224,
      lng: -32.42656,
      city: 'Golden Bay',
      state: 'WA',
    },
    6175: {
      lat: 115.755289,
      lng: -32.445896,
      city: 'Singleton',
      state: 'WA',
    },
    6176: {
      lat: 115.814323,
      lng: -32.421669,
      city: 'Karnup',
      state: 'WA',
    },
    6180: {
      lat: 115.728286,
      lng: -32.557981,
      city: 'Parklands',
      state: 'WA',
    },
    6181: {
      lat: 115.728286,
      lng: -32.557981,
      city: 'Stake Hill',
      state: 'WA',
    },
    6182: {
      lat: 115.832257,
      lng: -32.44631007,
      city: 'Keralup',
      state: 'WA',
    },
    6207: {
      lat: 115.959595,
      lng: -32.514294,
      city: 'Whittaker',
      state: 'WA',
    },
    6208: {
      lat: 115.847592,
      lng: -32.630616,
      city: 'West Pinjarra',
      state: 'WA',
    },
    6209: {
      lat: 115.728286,
      lng: -32.557981,
      city: 'Furnissdale',
      state: 'WA',
    },
    6210: {
      lat: 115.728286,
      lng: -32.557981,
      city: 'Wannanup',
      state: 'WA',
    },
    6211: {
      lat: 115.728286,
      lng: -32.557981,
      city: 'Herron',
      state: 'WA',
    },
    6213: {
      lat: 116.128249,
      lng: -32.702065,
      city: 'Teesdale',
      state: 'WA',
    },
    6214: {
      lat: 115.859383,
      lng: -32.750429,
      city: 'West Coolup',
      state: 'WA',
    },
    6215: {
      lat: 115.896715,
      lng: -32.853807,
      city: 'Waroona',
      state: 'WA',
    },
    6218: {
      lat: 115.903112,
      lng: -32.946154,
      city: 'Yarloop',
      state: 'WA',
    },
    6219: {
      lat: 115.8515,
      lng: -33.0035,
      city: 'Cookernup',
      state: 'WA',
    },
    6220: {
      lat: 116.029777,
      lng: -33.031221,
      city: 'Warawarrup',
      state: 'WA',
    },
    6221: {
      lat: 115.907354,
      lng: -33.119332,
      city: 'Wokalup',
      state: 'WA',
    },
    6223: {
      lat: 115.903221,
      lng: -33.18208,
      city: 'Benger',
      state: 'WA',
    },
    6224: {
      lat: 115.846788,
      lng: -33.245138,
      city: 'Brunswick',
      state: 'WA',
    },
    6225: {
      lat: 116.204886,
      lng: -33.35257,
      city: 'Yourdamung Lake',
      state: 'WA',
    },
    6226: {
      lat: 115.847234,
      lng: -33.291834,
      city: 'Roelands',
      state: 'WA',
    },
    6227: {
      lat: 115.868603,
      lng: -33.322813,
      city: 'Burekup',
      state: 'WA',
    },
    6228: {
      lat: 115.776497,
      lng: -33.338057,
      city: 'Waterloo',
      state: 'WA',
    },
    6229: {
      lat: 115.707383,
      lng: -33.352103,
      city: 'Picton East',
      state: 'WA',
    },
    6230: {
      lat: 115.649205,
      lng: -33.341076,
      city: 'Withers',
      state: 'WA',
    },
    6231: {
      lat: 115.65556,
      lng: -33.364375,
      city: 'Bunbury',
      state: 'WA',
    },
    6232: {
      lat: 115.704263,
      lng: -33.316625,
      city: 'Millbridge',
      state: 'WA',
    },
    6233: {
      lat: 115.71443,
      lng: -33.279034,
      city: 'Wellesley',
      state: 'WA',
    },
    6236: {
      lat: 115.828921,
      lng: -33.410436,
      city: 'Wellington Mill',
      state: 'WA',
    },
    6237: {
      lat: 115.674056,
      lng: -33.475994,
      city: 'The Plains',
      state: 'WA',
    },
    6239: {
      lat: 115.923933,
      lng: -33.564872,
      city: 'Yabberup',
      state: 'WA',
    },
    6240: {
      lat: 115.966143,
      lng: -33.549593,
      city: 'Lowden',
      state: 'WA',
    },
    6243: {
      lat: 116.206928,
      lng: -33.68873,
      city: 'Wilga West',
      state: 'WA',
    },
    6244: {
      lat: 116.5751665,
      lng: -33.62888392,
      city: 'Trigwell',
      state: 'WA',
    },
    6251: {
      lat: 115.888662,
      lng: -33.682661,
      city: 'Newlands',
      state: 'WA',
    },
    6252: {
      lat: 115.956731,
      lng: -33.748279,
      city: 'Mullalyup',
      state: 'WA',
    },
    6253: {
      lat: 116.03654,
      lng: -33.773937,
      city: 'Southampton',
      state: 'WA',
    },
    6254: {
      lat: 116.072643,
      lng: -33.844406,
      city: 'North Greenbushes',
      state: 'WA',
    },
    6255: {
      lat: 116.155378,
      lng: -33.971605,
      city: 'Winnejup',
      state: 'WA',
    },
    6256: {
      lat: 116.166583,
      lng: -34.052334,
      city: 'Yornup',
      state: 'WA',
    },
    6258: {
      lat: 116.402179,
      lng: -34.312823,
      city: 'Yanmah',
      state: 'WA',
    },
    6260: {
      lat: 115.860627,
      lng: -34.438738,
      city: 'Yeagarup',
      state: 'WA',
    },
    6262: {
      lat: 116.141244,
      lng: -34.706106,
      city: 'Windy Harbour',
      state: 'WA',
    },
    6271: {
      lat: 115.615674,
      lng: -33.621176,
      city: 'Stirling Estate',
      state: 'WA',
    },
    6275: {
      lat: 115.626518,
      lng: -34.055304,
      city: 'Yoganup',
      state: 'WA',
    },
    6280: {
      lat: 115.302732,
      lng: -33.728945,
      city: 'Yoongarillup',
      state: 'WA',
    },
    6281: {
      lat: 115.0768,
      lng: -33.610787,
      city: 'Quindalup',
      state: 'WA',
    },
    6282: {
      lat: 115.023916,
      lng: -33.722885,
      city: 'Yallingup Siding',
      state: 'WA',
    },
    6284: {
      lat: 115.188682,
      lng: -33.857591,
      city: 'Treeton',
      state: 'WA',
    },
    6285: { lat: 114.9968, lng: -33.9261, city: 'Yebble', state: 'WA' },
    6286: {
      lat: 115.176529,
      lng: -34.061823,
      city: 'Witchcliffe',
      state: 'WA',
    },
    6288: {
      lat: 115.233622,
      lng: -34.171024,
      city: 'Warner Glen',
      state: 'WA',
    },
    6290: {
      lat: 115.142231,
      lng: -34.293827,
      city: 'Molloy Island',
      state: 'WA',
    },
    6302: {
      lat: 116.742895,
      lng: -31.896269,
      city: 'York',
      state: 'WA',
    },
    6304: {
      lat: 116.76028,
      lng: -32.176607,
      city: 'Westdale',
      state: 'WA',
    },
    6306: {
      lat: 117.140629,
      lng: -32.296524,
      city: 'Kweda',
      state: 'WA',
    },
    6308: {
      lat: 116.665242,
      lng: -32.579015,
      city: 'West Pingelly',
      state: 'WA',
    },
    6309: {
      lat: 117.129227,
      lng: -32.668322,
      city: 'West Popanyinning',
      state: 'WA',
    },
    6311: {
      lat: 117.120895,
      lng: -32.803533,
      city: 'Yornaning',
      state: 'WA',
    },
    6312: {
      lat: 117.306862,
      lng: -32.979123,
      city: 'Yilliminning',
      state: 'WA',
    },
    6313: {
      lat: 117.243277,
      lng: -33.05792,
      city: 'Highbury',
      state: 'WA',
    },
    6315: {
      lat: 117.342795,
      lng: -33.286272,
      city: 'Wedgecarrup',
      state: 'WA',
    },
    6316: {
      lat: 117.384564,
      lng: -33.532315,
      city: 'Woodanilling',
      state: 'WA',
    },
    6317: {
      lat: 117.738912,
      lng: -33.647362,
      city: 'South Glencoe',
      state: 'WA',
    },
    6318: {
      lat: 117.651128,
      lng: -33.862021,
      city: 'Broomehill West',
      state: 'WA',
    },
    6320: {
      lat: 117.717905,
      lng: -34.096397,
      city: 'Wansbrough',
      state: 'WA',
    },
    6321: {
      lat: 117.398532,
      lng: -34.26948,
      city: 'Cranbrook',
      state: 'WA',
    },
    6322: {
      lat: 117.491623,
      lng: -34.411973,
      city: 'Tenterden',
      state: 'WA',
    },
    6323: {
      lat: 117.62552,
      lng: -34.486238,
      city: 'Kendenup',
      state: 'WA',
    },
    6324: {
      lat: 117.781468,
      lng: -34.618289,
      city: 'Woogenellup',
      state: 'WA',
    },
    6326: {
      lat: 117.785337,
      lng: -34.769363,
      city: 'Narrikup',
      state: 'WA',
    },
    6327: {
      lat: 117.593417,
      lng: -34.858542,
      city: 'Redmond West',
      state: 'WA',
    },
    6328: {
      lat: 118.462801,
      lng: -34.919086,
      city: 'Wellstead',
      state: 'WA',
    },
    6330: {
      lat: 117.863286,
      lng: -34.905438,
      city: 'Youngs Siding',
      state: 'WA',
    },
    6331: {
      lat: 117.884,
      lng: -35.0269,
      city: 'Albany Dc',
      state: 'WA',
    },
    6332: {
      lat: 117.884,
      lng: -35.0269,
      city: 'Albany Po',
      state: 'WA',
    },
    6333: {
      lat: 117.158861,
      lng: -34.902512,
      city: 'William Bay',
      state: 'WA',
    },
    6335: {
      lat: 118.112783,
      lng: -33.869017,
      city: 'Pallinup',
      state: 'WA',
    },
    6336: {
      lat: 118.596801,
      lng: -33.928235,
      city: 'Toompup',
      state: 'WA',
    },
    6337: {
      lat: 119.237195,
      lng: -33.990448,
      city: 'West Fitzgerald',
      state: 'WA',
    },
    6338: {
      lat: 118.657118,
      lng: -34.362864,
      city: 'Stirling Range National Park',
      state: 'WA',
    },
    6341: {
      lat: 118.215173,
      lng: -33.513647,
      city: 'Nyabing',
      state: 'WA',
    },
    6343: {
      lat: 118.79925,
      lng: -33.535559,
      city: 'Pingrup',
      state: 'WA',
    },
    6346: {
      lat: 120.349013,
      lng: -33.319068,
      city: 'West River',
      state: 'WA',
    },
    6348: {
      lat: 120.145773,
      lng: -33.881306,
      city: 'Hopetoun',
      state: 'WA',
    },
    6350: {
      lat: 117.783872,
      lng: -33.281233,
      city: 'Nippering',
      state: 'WA',
    },
    6351: {
      lat: 117.922069,
      lng: -33.189372,
      city: 'North Moulyinning',
      state: 'WA',
    },
    6352: {
      lat: 118.086163,
      lng: -33.185465,
      city: 'South Kukerin',
      state: 'WA',
    },
    6353: {
      lat: 118.510341,
      lng: -33.131383,
      city: 'Tarin Rock',
      state: 'WA',
    },
    6355: {
      lat: 119.098339,
      lng: -33.032688,
      city: 'Varley',
      state: 'WA',
    },
    6356: {
      lat: 119.713036,
      lng: -33.121892,
      city: 'Mount Madden',
      state: 'WA',
    },
    6357: {
      lat: 118.724162,
      lng: -32.80966,
      city: 'Pingaring',
      state: 'WA',
    },
    6358: {
      lat: 118.671921,
      lng: -32.483917,
      city: 'Karlgarin',
      state: 'WA',
    },
    6359: {
      lat: 119.1284213,
      lng: -32.66654931,
      city: 'Little Italy',
      state: 'WA',
    },
    6361: {
      lat: 117.7572,
      lng: -32.9894,
      city: 'Tincurrin',
      state: 'WA',
    },
    6363: {
      lat: 118.004212,
      lng: -32.919792,
      city: 'Walyurin',
      state: 'WA',
    },
    6365: {
      lat: 118.288784,
      lng: -32.723142,
      city: 'Kulin West',
      state: 'WA',
    },
    6367: {
      lat: 118.351936,
      lng: -32.422646,
      city: 'Kondinin',
      state: 'WA',
    },
    6368: {
      lat: 118.339949,
      lng: -32.189465,
      city: 'South Kumminin',
      state: 'WA',
    },
    6369: {
      lat: 118.806453,
      lng: -32.023679,
      city: 'Woolocutty',
      state: 'WA',
    },
    6370: {
      lat: 117.626013,
      lng: -32.80605,
      city: 'Wogolin',
      state: 'WA',
    },
    6372: {
      lat: 117.690219,
      lng: -32.62616,
      city: 'Yealering',
      state: 'WA',
    },
    6373: {
      lat: 117.763902,
      lng: -32.495421,
      city: 'Bullaring',
      state: 'WA',
    },
    6375: {
      lat: 117.879836,
      lng: -32.286284,
      city: 'Kurrenkutten',
      state: 'WA',
    },
    6383: {
      lat: 117.406271,
      lng: -31.951084,
      city: 'Yoting',
      state: 'WA',
    },
    6384: {
      lat: 117.666205,
      lng: -31.955552,
      city: 'Pantapin',
      state: 'WA',
    },
    6385: {
      lat: 117.747301,
      lng: -31.902072,
      city: 'Kwolyin',
      state: 'WA',
    },
    6386: {
      lat: 117.815142,
      lng: -31.950074,
      city: 'Shackleton',
      state: 'WA',
    },
    6390: {
      lat: 116.413369,
      lng: -32.805438,
      city: 'Wuraming',
      state: 'WA',
    },
    6391: {
      lat: 116.773204,
      lng: -33.0633,
      city: 'Williams',
      state: 'WA',
    },
    6392: {
      lat: 116.6387687,
      lng: -33.24859545,
      city: 'Meeking',
      state: 'WA',
    },
    6393: {
      lat: 116.85396,
      lng: -33.538228,
      city: 'Trigwell',
      state: 'WA',
    },
    6394: {
      lat: 116.972036,
      lng: -33.83319,
      city: 'Qualeup',
      state: 'WA',
    },
    6395: {
      lat: 117.065276,
      lng: -33.885153,
      city: 'Ryansbrook',
      state: 'WA',
    },
    6396: {
      lat: 116.9668031,
      lng: -34.33890039,
      city: 'Frankland River',
      state: 'WA',
    },
    6397: {
      lat: 116.955359,
      lng: -34.543671,
      city: 'Rocky Gully',
      state: 'WA',
    },
    6398: {
      lat: 116.604043,
      lng: -34.788611,
      city: 'Walpole',
      state: 'WA',
    },
    6401: {
      lat: 116.681727,
      lng: -31.640292,
      city: 'Wongamine',
      state: 'WA',
    },
    6403: {
      lat: 116.797476,
      lng: -31.643902,
      city: 'Grass Valley',
      state: 'WA',
    },
    6405: {
      lat: 117.020194,
      lng: -31.605825,
      city: 'Warding East',
      state: 'WA',
    },
    6407: {
      lat: 117.222062,
      lng: -31.651142,
      city: 'Youndegin',
      state: 'WA',
    },
    6409: {
      lat: 117.471029,
      lng: -31.605391,
      city: 'Yorkrakine',
      state: 'WA',
    },
    6410: {
      lat: 117.679993,
      lng: -31.579991,
      city: 'North Kellerberrin',
      state: 'WA',
    },
    6411: {
      lat: 117.905311,
      lng: -31.550535,
      city: 'South Doodlakine',
      state: 'WA',
    },
    6412: {
      lat: 117.979823,
      lng: -31.601076,
      city: 'North Baandee',
      state: 'WA',
    },
    6413: {
      lat: 118.064042,
      lng: -31.555637,
      city: 'Hines Hill',
      state: 'WA',
    },
    6414: {
      lat: 118.167797,
      lng: -31.526755,
      city: 'Nangeenan',
      state: 'WA',
    },
    6415: {
      lat: 118.289983,
      lng: -31.481223,
      city: 'Tandegin',
      state: 'WA',
    },
    6418: {
      lat: 118.1626,
      lng: -31.895542,
      city: 'Yarding',
      state: 'WA',
    },
    6419: {
      lat: 118.097777,
      lng: -32.031182,
      city: 'Ardath',
      state: 'WA',
    },
    6420: {
      lat: 118.718262,
      lng: -31.748338,
      city: 'Muntadgin',
      state: 'WA',
    },
    6421: {
      lat: 118.524208,
      lng: -31.327544,
      city: 'Warralakin',
      state: 'WA',
    },
    6422: {
      lat: 118.564405,
      lng: -31.384119,
      city: 'Walgoolan',
      state: 'WA',
    },
    6423: {
      lat: 118.681242,
      lng: -31.169257,
      city: 'Westonia',
      state: 'WA',
    },
    6424: {
      lat: 118.896267,
      lng: -31.296345,
      city: 'South Bodallin',
      state: 'WA',
    },
    6425: {
      lat: 119.143202,
      lng: -31.398146,
      city: 'Moorine Rock',
      state: 'WA',
    },
    6426: {
      lat: 119.311267,
      lng: -30.361305,
      city: 'Yellowdine',
      state: 'WA',
    },
    6427: {
      lat: 119.540903,
      lng: -30.850566,
      city: 'Koolyanobbing',
      state: 'WA',
    },
    6428: {
      lat: 118.020545,
      lng: -32.142001,
      city: 'Babakin',
      state: 'WA',
    },
    6429: {
      lat: 120.898892,
      lng: -30.949795,
      city: 'Wallaroo',
      state: 'WA',
    },
    6430: {
      lat: 121.446478,
      lng: -30.599071,
      city: 'Yilkari',
      state: 'WA',
    },
    6431: {
      lat: 122.683881,
      lng: -30.40364,
      city: 'Warburton',
      state: 'WA',
    },
    6432: {
      lat: 121.541783,
      lng: -30.871011,
      city: 'Victory Heights',
      state: 'WA',
    },
    6433: {
      lat: 121.466,
      lng: -30.749,
      city: 'Kalgoorlie Po',
      state: 'WA',
    },
    6434: {
      lat: 125.672818,
      lng: -30.820157,
      city: 'Zanthus',
      state: 'WA',
    },
    6435: {
      lat: 120.44875,
      lng: -28.219307,
      city: 'Agnew',
      state: 'WA',
    },
    6436: {
      lat: 124.856838,
      lng: -29.602251,
      city: 'Ularring',
      state: 'WA',
    },
    6437: {
      lat: 120.529798,
      lng: -27.68439,
      city: 'Sir Samuel',
      state: 'WA',
    },
    6438: {
      lat: 121.092655,
      lng: -28.900034,
      city: 'Leonora',
      state: 'WA',
    },
    6440: {
      lat: 125.733157,
      lng: -26.168944,
      city: 'Neale',
      state: 'WA',
    },
    6442: {
      lat: 121.5795589,
      lng: -31.19518028,
      city: 'Kambalda West',
      state: 'WA',
    },
    6443: {
      lat: 123.744186,
      lng: -32.39502,
      city: 'Widgiemooltha',
      state: 'WA',
    },
    6445: {
      lat: 121.638735,
      lng: -32.981696,
      city: 'Salmon Gums',
      state: 'WA',
    },
    6446: {
      lat: 121.682321,
      lng: -33.223445,
      city: 'Grass Patch',
      state: 'WA',
    },
    6447: {
      lat: 121.732214,
      lng: -33.451323,
      city: 'Wittenoom Hills',
      state: 'WA',
    },
    6448: {
      lat: 121.798896,
      lng: -33.650554,
      city: 'Gibson',
      state: 'WA',
    },
    6450: {
      lat: 122.211127,
      lng: -33.401152,
      city: 'Windabout',
      state: 'WA',
    },
    6452: {
      lat: 122.895,
      lng: -32.91833,
      city: 'Israelite Bay',
      state: 'WA',
    },
    6460: {
      lat: 116.779032,
      lng: -31.266649,
      city: 'Walyormouring',
      state: 'WA',
    },
    6461: {
      lat: 117.083327,
      lng: -31.140717,
      city: 'Koomberkine',
      state: 'WA',
    },
    6462: {
      lat: 117.204349,
      lng: -31.125288,
      city: 'Ucarty',
      state: 'WA',
    },
    6463: {
      lat: 117.249295,
      lng: -31.126499,
      city: 'Benjaberring',
      state: 'WA',
    },
    6465: {
      lat: 117.071157,
      lng: -30.859977,
      city: 'Manmanning',
      state: 'WA',
    },
    6466: {
      lat: 117.100375,
      lng: -30.719092,
      city: 'Cadoux',
      state: 'WA',
    },
    6467: {
      lat: 117.153374,
      lng: -30.561306,
      city: 'Burakin',
      state: 'WA',
    },
    6468: {
      lat: 117.144456,
      lng: -30.214559,
      city: 'Petrudor',
      state: 'WA',
    },
    6470: {
      lat: 117.287007,
      lng: -30.466429,
      city: 'Kulja',
      state: 'WA',
    },
    6472: {
      lat: 117.8045997,
      lng: -30.19419562,
      city: 'Tampu',
      state: 'WA',
    },
    6473: {
      lat: 118.13352,
      lng: -30.488998,
      city: 'Wialki',
      state: 'WA',
    },
    6475: {
      lat: 117.440487,
      lng: -30.613981,
      city: 'Newcarlbeon',
      state: 'WA',
    },
    6476: {
      lat: 117.679166,
      lng: -30.764934,
      city: 'Gabbin',
      state: 'WA',
    },
    6477: {
      lat: 117.93876,
      lng: -30.775956,
      city: 'Welbungin',
      state: 'WA',
    },
    6479: {
      lat: 118.448501,
      lng: -30.748419,
      city: 'Wilgoyne',
      state: 'WA',
    },
    6480: {
      lat: 118.22061,
      lng: -31.290084,
      city: 'Nukarni',
      state: 'WA',
    },
    6484: {
      lat: 119.078912,
      lng: -30.960681,
      city: 'Lake Deborah',
      state: 'WA',
    },
    6485: {
      lat: 117.381055,
      lng: -31.184717,
      city: 'Wyalkatchem',
      state: 'WA',
    },
    6487: {
      lat: 117.660055,
      lng: -31.168259,
      city: 'Yelbeni',
      state: 'WA',
    },
    6488: {
      lat: 117.735836,
      lng: -31.127571,
      city: 'Trayning',
      state: 'WA',
    },
    6489: {
      lat: 117.945624,
      lng: -31.12327,
      city: 'South Kununoppin',
      state: 'WA',
    },
    6490: {
      lat: 118.107061,
      lng: -31.161835,
      city: 'Talgomine',
      state: 'WA',
    },
    6501: {
      lat: 115.945497,
      lng: -31.541813,
      city: 'Muchea',
      state: 'WA',
    },
    6502: {
      lat: 116.184272,
      lng: -31.297561,
      city: 'Bindoon Training Area',
      state: 'WA',
    },
    6503: {
      lat: 115.82111,
      lng: -31.237406,
      city: 'Yeal',
      state: 'WA',
    },
    6504: {
      lat: 116.042298,
      lng: -31.310791,
      city: 'Mooliabeenee',
      state: 'WA',
    },
    6505: {
      lat: 116.104431,
      lng: -31.161423,
      city: 'Wannamal',
      state: 'WA',
    },
    6506: {
      lat: 116.110277,
      lng: -31.070282,
      city: 'Mogumber',
      state: 'WA',
    },
    6507: {
      lat: 115.718713,
      lng: -30.72155,
      city: 'Yathroo',
      state: 'WA',
    },
    6509: {
      lat: 116.252618,
      lng: -30.912266,
      city: 'Yarawindah',
      state: 'WA',
    },
    6510: {
      lat: 116.090272,
      lng: -30.701145,
      city: 'Walebing',
      state: 'WA',
    },
    6511: {
      lat: 115.250201,
      lng: -30.568116,
      city: 'Cervantes',
      state: 'WA',
    },
    6512: {
      lat: 116.070173,
      lng: -30.446675,
      city: 'Namban',
      state: 'WA',
    },
    6513: {
      lat: 116.060619,
      lng: -30.27368,
      city: 'Watheroo',
      state: 'WA',
    },
    6514: {
      lat: 115.087155,
      lng: -30.027371,
      city: 'Leeman',
      state: 'WA',
    },
    6515: {
      lat: 115.856422,
      lng: -29.967278,
      city: 'Waddy Forest',
      state: 'WA',
    },
    6516: {
      lat: 115.20926,
      lng: -30.22554,
      city: 'Jurien Bay',
      state: 'WA',
    },
    6517: {
      lat: 115.839422,
      lng: -29.740667,
      city: 'Winchester',
      state: 'WA',
    },
    6518: {
      lat: 115.180736,
      lng: -29.774917,
      city: 'Warradarge',
      state: 'WA',
    },
    6519: {
      lat: 115.597715,
      lng: -29.514304,
      city: 'Womarden',
      state: 'WA',
    },
    6521: {
      lat: 115.640925,
      lng: -30.348514,
      city: 'Nambung',
      state: 'WA',
    },
    6522: {
      lat: 115.485567,
      lng: -29.139369,
      city: 'Yarragadee',
      state: 'WA',
    },
    6525: {
      lat: 115.098073,
      lng: -29.327323,
      city: 'Yardarino',
      state: 'WA',
    },
    6528: {
      lat: 114.815184,
      lng: -28.985892,
      city: 'Walkaway',
      state: 'WA',
    },
    6530: {
      lat: 114.862505,
      lng: -28.802491,
      city: 'Woorree',
      state: 'WA',
    },
    6531: {
      lat: 114.612,
      lng: -28.7738,
      city: 'Geraldton Po',
      state: 'WA',
    },
    6532: {
      lat: 115.004595,
      lng: -28.440886,
      city: 'Yuna',
      state: 'WA',
    },
    6535: {
      lat: 114.852437,
      lng: -27.832196,
      city: 'Yallabatharra',
      state: 'WA',
    },
    6536: {
      lat: 114.379678,
      lng: -27.541838,
      city: 'Zuytdorp',
      state: 'WA',
    },
    6537: {
      lat: 114.627287,
      lng: -26.904889,
      city: 'Useless Loop',
      state: 'WA',
    },
    6556: {
      lat: 116.310249,
      lng: -31.860591,
      city: 'The Lakes',
      state: 'WA',
    },
    6558: {
      lat: 116.313391,
      lng: -31.771446,
      city: 'Wooroloo',
      state: 'WA',
    },
    6560: {
      lat: 116.428564,
      lng: -31.817666,
      city: 'Wundowie',
      state: 'WA',
    },
    6562: {
      lat: 116.473434,
      lng: -31.746189,
      city: 'Woottating',
      state: 'WA',
    },
    6564: {
      lat: 116.538966,
      lng: -31.726613,
      city: 'Clackline',
      state: 'WA',
    },
    6566: {
      lat: 116.38064,
      lng: -31.484853,
      city: 'West Toodyay',
      state: 'WA',
    },
    6567: {
      lat: 116.419958,
      lng: -31.459208,
      city: 'Moondyne',
      state: 'WA',
    },
    6568: {
      lat: 116.537461,
      lng: -31.246581,
      city: 'Wyening',
      state: 'WA',
    },
    6569: {
      lat: 116.452845,
      lng: -31.085084,
      city: 'Old Plains',
      state: 'WA',
    },
    6571: {
      lat: 116.396621,
      lng: -30.940448,
      city: 'Yerecoin',
      state: 'WA',
    },
    6572: {
      lat: 116.396304,
      lng: -30.804675,
      city: 'Piawaning',
      state: 'WA',
    },
    6574: {
      lat: 116.425241,
      lng: -30.650914,
      city: 'Gabalong',
      state: 'WA',
    },
    6575: {
      lat: 116.424426,
      lng: -30.410873,
      city: 'Miling',
      state: 'WA',
    },
    6603: {
      lat: 116.725982,
      lng: -30.874243,
      city: 'Wongan Hills',
      state: 'WA',
    },
    6605: {
      lat: 116.762957,
      lng: -30.730158,
      city: 'Kondut',
      state: 'WA',
    },
    6606: {
      lat: 116.829163,
      lng: -30.614263,
      city: 'West Ballidu',
      state: 'WA',
    },
    6608: {
      lat: 116.826594,
      lng: -30.429334,
      city: 'Pithara',
      state: 'WA',
    },
    6609: {
      lat: 116.750631,
      lng: -30.266489,
      city: 'Xantippe',
      state: 'WA',
    },
    6612: {
      lat: 117.835179,
      lng: -29.545139,
      city: 'Wubin',
      state: 'WA',
    },
    6613: {
      lat: 116.523551,
      lng: -29.962626,
      city: 'Buntine',
      state: 'WA',
    },
    6614: {
      lat: 116.551869,
      lng: -29.888797,
      city: 'Maya',
      state: 'WA',
    },
    6616: {
      lat: 116.434223,
      lng: -29.76691,
      city: 'Latham',
      state: 'WA',
    },
    6620: {
      lat: 116.702611,
      lng: -29.340545,
      city: 'Rothsay',
      state: 'WA',
    },
    6623: {
      lat: 116.120459,
      lng: -29.021683,
      city: 'Pintharuka',
      state: 'WA',
    },
    6625: {
      lat: 115.946714,
      lng: -29.107426,
      city: 'Merkanooka',
      state: 'WA',
    },
    6627: {
      lat: 115.902023,
      lng: -28.850457,
      city: 'Canna',
      state: 'WA',
    },
    6628: {
      lat: 115.764797,
      lng: -28.833846,
      city: 'Tardun',
      state: 'WA',
    },
    6630: {
      lat: 115.72384,
      lng: -27.569679,
      city: 'Woolgorong',
      state: 'WA',
    },
    6631: {
      lat: 115.755437,
      lng: -28.46368,
      city: 'Pindar',
      state: 'WA',
    },
    6632: {
      lat: 115.338356,
      lng: -28.619887,
      city: 'Tenindewa',
      state: 'WA',
    },
    6635: {
      lat: 116.689412,
      lng: -28.150726,
      city: 'Yalgoo',
      state: 'WA',
    },
    6638: {
      lat: 118.157573,
      lng: -28.612449,
      city: 'Paynesville',
      state: 'WA',
    },
    6639: {
      lat: 119.409802,
      lng: -27.68492,
      city: 'Sandstone',
      state: 'WA',
    },
    6640: {
      lat: 117.674648,
      lng: -27.003199,
      city: 'Weld Range',
      state: 'WA',
    },
    6642: {
      lat: 118.554936,
      lng: -25.639378,
      city: 'Peak Hill',
      state: 'WA',
    },
    6646: {
      lat: 121.214907,
      lng: -25.912724,
      city: 'Wiluna',
      state: 'WA',
    },
    6701: {
      lat: 115.15198,
      lng: -24.734545,
      city: 'Yandoo Creek',
      state: 'WA',
    },
    6705: {
      lat: 115.011494,
      lng: -25.111289,
      city: 'West Lyons River',
      state: 'WA',
    },
    6707: {
      lat: 113.954791,
      lng: -22.265028,
      city: 'North West Cape',
      state: 'WA',
    },
    6710: {
      lat: 115.448968,
      lng: -22.133456,
      city: 'Yannarie',
      state: 'WA',
    },
    6711: {
      lat: 115.001086,
      lng: -21.461934,
      city: 'Thevenard Island',
      state: 'WA',
    },
    6712: {
      lat: 115.407335,
      lng: -20.785813,
      city: 'Barrow Island',
      state: 'WA',
    },
    6713: {
      lat: 116.709548,
      lng: -20.676313,
      city: 'Dampier Archipelago',
      state: 'WA',
    },
    6714: {
      lat: 116.349933,
      lng: -21.145063,
      city: 'Stove Hill',
      state: 'WA',
    },
    6716: {
      lat: 116.311985,
      lng: -21.605319,
      city: 'Pannawonica',
      state: 'WA',
    },
    6718: {
      lat: 117.740969,
      lng: -20.971499,
      city: 'Whim Creek',
      state: 'WA',
    },
    6720: {
      lat: 117.067614,
      lng: -20.699288,
      city: 'Wickham',
      state: 'WA',
    },
    6721: {
      lat: 118.441716,
      lng: -20.710799,
      city: 'Wedgefield',
      state: 'WA',
    },
    6722: {
      lat: 118.606673,
      lng: -20.407853,
      city: 'South Hedland',
      state: 'WA',
    },
    6723: {
      lat: 119.727488,
      lng: -20.2103,
      city: 'Goldsworthy',
      state: 'WA',
    },
    6725: {
      lat: 122.753792,
      lng: -18.773365,
      city: 'Waterbank',
      state: 'WA',
    },
    6726: {
      lat: 122.196423,
      lng: -17.950181,
      city: 'Cable Beach',
      state: 'WA',
    },
    6728: {
      lat: 124.591962,
      lng: -17.090606,
      city: 'Willare',
      state: 'WA',
    },
    6731: {
      lat: 123.610523,
      lng: -16.09862,
      city: 'Cockatoo Island',
      state: 'WA',
    },
    6733: {
      lat: 123.753964,
      lng: -16.134853,
      city: 'Koolan Island',
      state: 'WA',
    },
    6740: {
      lat: 128.397192,
      lng: -14.962686,
      city: 'Wyndham',
      state: 'WA',
    },
    6743: {
      lat: 128.550721,
      lng: -15.174208,
      city: 'Wijilawarrim',
      state: 'WA',
    },
    6751: {
      lat: 117.057776,
      lng: -22.141251,
      city: 'Wittenoom',
      state: 'WA',
    },
    6753: {
      lat: 119.714082,
      lng: -23.154069,
      city: 'Newman',
      state: 'WA',
    },
    6754: {
      lat: 117.47663,
      lng: -23.303751,
      city: 'Paraburdoo',
      state: 'WA',
    },
    6758: {
      lat: 120.282267,
      lng: -22.055582,
      city: 'Nullagine',
      state: 'WA',
    },
    6760: {
      lat: 124.383893,
      lng: -22.026615,
      city: 'Marble Bar',
      state: 'WA',
    },
    6761: {
      lat: 120.469973,
      lng: -20.403598,
      city: 'Shay Gap',
      state: 'WA',
    },
    6762: {
      lat: 122.224801,
      lng: -21.712224,
      city: 'Telfer',
      state: 'WA',
    },
    6765: {
      lat: 125.282055,
      lng: -18.752827,
      city: 'Mount Hardman',
      state: 'WA',
    },
    6770: {
      lat: 127.5009013,
      lng: -20.80936757,
      city: 'Tanami',
      state: 'WA',
    },
    6798: {
      lat: 105.64067,
      lng: -10.487053,
      city: 'Christmas Island',
      state: 'WA',
    },
    6799: {
      lat: 96.83152,
      lng: -12.169719,
      city: 'West Island Cocos (keeling) Islands',
      state: 'WA',
    },
    6800: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6803: {
      lat: 115.869136,
      lng: -31.939272,
      city: 'Northbridge',
      state: 'WA',
    },
    6809: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6817: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6820: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6827: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6830: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6831: {
      lat: 115.857527,
      lng: -31.95505,
      city: 'Perth St Georges Tce',
      state: 'WA',
    },
    6832: {
      lat: 115.866732,
      lng: -31.958153,
      city: 'Perth East St Georges Tce',
      state: 'WA',
    },
    6837: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6838: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6839: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6840: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6841: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6842: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6843: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6844: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6845: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6846: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6847: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6848: {
      lat: 115.763228,
      lng: -31.99212,
      city: 'Perth',
      state: 'WA',
    },
    6849: {
      lat: 115.864,
      lng: -31.9488,
      city: 'Perth Bc',
      state: 'WA',
    },
    6850: {
      lat: 115.854,
      lng: -31.9525,
      city: 'Cloisters Square Po',
      state: 'WA',
    },
    6865: {
      lat: 115.869136,
      lng: -31.939272,
      city: 'Northbridge',
      state: 'WA',
    },
    6872: {
      lat: 115.876395,
      lng: -31.94319,
      city: 'West Perth',
      state: 'WA',
    },
    6892: {
      lat: 115.817894,
      lng: -31.777103,
      city: 'East Perth',
      state: 'WA',
    },
    6900: {
      lat: 115.834335,
      lng: -31.935675,
      city: 'Leederville',
      state: 'WA',
    },
    6901: {
      lat: 115.875924,
      lng: -31.944548,
      city: 'West Leederville',
      state: 'WA',
    },
    6902: {
      lat: 115.834335,
      lng: -31.935675,
      city: 'Leederville',
      state: 'WA',
    },
    6903: {
      lat: 115.834335,
      lng: -31.935675,
      city: 'Leederville',
      state: 'WA',
    },
    6904: {
      lat: 115.824,
      lng: -31.9499,
      city: 'Subiaco Po',
      state: 'WA',
    },
    6905: {
      lat: 121.628,
      lng: -27.6728,
      city: 'Northlands Po',
      state: 'WA',
    },
    6906: {
      lat: 115.760961,
      lng: -31.982001,
      city: 'North Perth',
      state: 'WA',
    },
    6907: {
      lat: 115.804692,
      lng: -31.985791,
      city: 'Nedlands',
      state: 'WA',
    },
    6909: {
      lat: 115.804692,
      lng: -31.985791,
      city: 'Nedlands',
      state: 'WA',
    },
    6910: {
      lat: 115.774504,
      lng: -31.981493,
      city: 'Claremont',
      state: 'WA',
    },
    6911: {
      lat: 115.761982,
      lng: -32.403407,
      city: 'Cottesloe',
      state: 'WA',
    },
    6912: {
      lat: 115.763135,
      lng: -32.011748,
      city: 'Mosman Park',
      state: 'WA',
    },
    6913: {
      lat: 115.808169,
      lng: -31.936389,
      city: 'Wembley',
      state: 'WA',
    },
    6914: {
      lat: 115.815211,
      lng: -31.861596,
      city: 'Balcatta',
      state: 'WA',
    },
    6915: {
      lat: 115.848262,
      lng: -31.954127,
      city: 'Mount Hawthorn',
      state: 'WA',
    },
    6916: {
      lat: 115.804802,
      lng: -31.885883,
      city: 'Osborne Park Dc',
      state: 'WA',
    },
    6917: {
      lat: 115.804802,
      lng: -31.885883,
      city: 'Osborne Park',
      state: 'WA',
    },
    6918: {
      lat: 115.786821,
      lng: -31.891957,
      city: 'Innaloo',
      state: 'WA',
    },
    6919: {
      lat: 115.756405,
      lng: -31.763347,
      city: 'Joondalup Dc',
      state: 'WA',
    },
    6920: {
      lat: 115.775442,
      lng: -31.859408,
      city: 'North Beach',
      state: 'WA',
    },
    6921: {
      lat: 115.791881,
      lng: -31.882332,
      city: 'Karrinyup',
      state: 'WA',
    },
    6922: {
      lat: 115.79501,
      lng: -31.901385,
      city: 'Scarborough',
      state: 'WA',
    },
    6923: {
      lat: 115.754405,
      lng: -31.79583,
      city: 'Hillarys',
      state: 'WA',
    },
    6924: {
      lat: 115.797347,
      lng: -32.559825,
      city: 'Greenwood',
      state: 'WA',
    },
    6925: {
      lat: 116.208263,
      lng: -32.108961,
      city: 'Walliston Dc',
      state: 'WA',
    },
    6926: {
      lat: 116.012518,
      lng: -31.939027,
      city: 'Kalamunda',
      state: 'WA',
    },
    6929: {
      lat: 115.848262,
      lng: -31.954127,
      city: 'Mount Lawley',
      state: 'WA',
    },
    6931: {
      lat: 115.899589,
      lng: -31.938107,
      city: 'Maylands',
      state: 'WA',
    },
    6932: {
      lat: 115.882764,
      lng: -31.923239,
      city: 'Inglewood',
      state: 'WA',
    },
    6933: {
      lat: 115.896545,
      lng: -31.907152,
      city: 'Bayswater',
      state: 'WA',
    },
    6934: {
      lat: 115.921101,
      lng: -31.915103,
      city: 'Bassendean',
      state: 'WA',
    },
    6935: {
      lat: 115.921132,
      lng: -31.923723,
      city: 'Guildford',
      state: 'WA',
    },
    6936: {
      lat: 116.013,
      lng: -31.8918,
      city: 'Midland Dc',
      state: 'WA',
    },
    6937: {
      lat: 115.846807,
      lng: -31.896748,
      city: 'Tuart Hill',
      state: 'WA',
    },
    6938: {
      lat: 115.846807,
      lng: -31.896748,
      city: 'Tuart Hill',
      state: 'WA',
    },
    6939: {
      lat: 115.846807,
      lng: -31.896748,
      city: 'Tuart Hill',
      state: 'WA',
    },
    6940: {
      lat: 115.846807,
      lng: -31.896748,
      city: 'Tuart Hill',
      state: 'WA',
    },
    6941: {
      lat: 115.855186,
      lng: -31.842045,
      city: 'Mirrabooka',
      state: 'WA',
    },
    6942: {
      lat: 115.942572,
      lng: -31.901801,
      city: 'Bassendean Dc',
      state: 'WA',
    },
    6943: {
      lat: 115.89292,
      lng: -31.887921,
      city: 'Morley',
      state: 'WA',
    },
    6944: {
      lat: 115.895533,
      lng: -31.864862,
      city: 'Malaga',
      state: 'WA',
    },
    6945: {
      lat: 115.893,
      lng: -31.8556,
      city: 'Malaga Dc',
      state: 'WA',
    },
    6946: {
      lat: 115.715081,
      lng: -31.617559,
      city: 'Wanneroo',
      state: 'WA',
    },
    6947: {
      lat: 115.845329,
      lng: -31.69444,
      city: 'Wangara Dc',
      state: 'WA',
    },
    6951: {
      lat: 115.821722,
      lng: -32.068705,
      city: 'South Perth',
      state: 'WA',
    },
    6952: {
      lat: 115.817919,
      lng: -31.891696,
      city: 'Como',
      state: 'WA',
    },
    6953: {
      lat: 115.837525,
      lng: -32.024363,
      city: 'Applecross',
      state: 'WA',
    },
    6954: {
      lat: 115.825994,
      lng: -32.040061,
      city: 'Booragoon',
      state: 'WA',
    },
    6955: {
      lat: 115.890779,
      lng: -32.061501,
      city: 'Willetton',
      state: 'WA',
    },
    6956: {
      lat: 115.853588,
      lng: -31.988065,
      city: 'Melville',
      state: 'WA',
    },
    6957: {
      lat: 115.783732,
      lng: -32.038228,
      city: 'Palmyra',
      state: 'WA',
    },
    6958: {
      lat: -120.74,
      lng: 47.7511,
      city: 'Royal Australian Navy Warships',
      state: 'WA',
    },
    6959: {
      lat: 115.986396,
      lng: -32.067114,
      city: 'Fremantle',
      state: 'WA',
    },
    6960: { lat: 115.816, lng: -32.04, city: 'Myaree Bc', state: 'WA' },
    6961: {
      lat: 115.783732,
      lng: -32.038228,
      city: 'Palmyra Dc',
      state: 'WA',
    },
    6963: {
      lat: 115.788706,
      lng: -32.076945,
      city: 'Hamilton Hill',
      state: 'WA',
    },
    6964: {
      lat: 115.860179,
      lng: -32.022746,
      city: 'Success',
      state: 'WA',
    },
    6965: {
      lat: 115.798722,
      lng: -32.084784,
      city: 'Bibra Lake Dc',
      state: 'WA',
    },
    6966: {
      lat: 115.859834,
      lng: -32.143195,
      city: 'Kwinana',
      state: 'WA',
    },
    6967: {
      lat: 115.75773,
      lng: -32.281957,
      city: 'Rockingham Dc',
      state: 'WA',
    },
    6968: {
      lat: 115.782689,
      lng: -32.107766,
      city: 'Rockingham',
      state: 'WA',
    },
    6969: {
      lat: 115.745072,
      lng: -32.264021,
      city: 'Rockingham Beach',
      state: 'WA',
    },
    6970: {
      lat: 115.890779,
      lng: -32.061501,
      city: 'Canning Vale Dc',
      state: 'WA',
    },
    6979: {
      lat: 115.896195,
      lng: -31.954583,
      city: 'Victoria Park',
      state: 'WA',
    },
    6980: {
      lat: 115.967142,
      lng: -32.017663,
      city: 'Cannington',
      state: 'WA',
    },
    6981: {
      lat: 115.876964,
      lng: -31.951832,
      city: 'East Victoria Park',
      state: 'WA',
    },
    6982: {
      lat: 116.169125,
      lng: -31.869817,
      city: 'Bentley',
      state: 'WA',
    },
    6983: {
      lat: 115.904425,
      lng: -32.005644,
      city: 'Bentley Dc',
      state: 'WA',
    },
    6984: {
      lat: 115.933458,
      lng: -31.965766,
      city: 'Belmont',
      state: 'WA',
    },
    6985: {
      lat: 115.600155,
      lng: -33.625285,
      city: 'Cloverdale',
      state: 'WA',
    },
    6986: {
      lat: 115.945564,
      lng: -31.992082,
      city: 'Welshpool Dc',
      state: 'WA',
    },
    6987: {
      lat: 115.967142,
      lng: -32.017663,
      city: 'Cannington',
      state: 'WA',
    },
    6988: {
      lat: 115.964764,
      lng: -32.050009,
      city: 'Thornlie',
      state: 'WA',
    },
    6989: {
      lat: 116.009575,
      lng: -32.039597,
      city: 'Maddington',
      state: 'WA',
    },
    6990: {
      lat: 116.008461,
      lng: -32.060407,
      city: 'Gosnells',
      state: 'WA',
    },
    6991: {
      lat: 116.095955,
      lng: -32.09788,
      city: 'Kelmscott',
      state: 'WA',
    },
    6992: {
      lat: 115.920395,
      lng: -31.964503,
      city: 'Armadale',
      state: 'WA',
    },
    6997: {
      lat: 116.095955,
      lng: -32.09788,
      city: 'Kelmscott Dc',
      state: 'WA',
    },
    7000: {
      lat: 147.308002,
      lng: -42.878355,
      city: 'West Hobart',
      state: 'TAS',
    },
    7001: {
      lat: 146.356055,
      lng: -43.535913,
      city: 'Tasman Island',
      state: 'TAS',
    },
    7002: {
      lat: 147.446349,
      lng: -42.899691,
      city: 'North Hobart',
      state: 'TAS',
    },
    7004: {
      lat: 147.328928,
      lng: -42.890148,
      city: 'South Hobart',
      state: 'TAS',
    },
    7005: {
      lat: 147.3368,
      lng: -42.916204,
      city: 'University Of Tasmania',
      state: 'TAS',
    },
    7006: {
      lat: 147.344395,
      lng: -42.908108,
      city: 'Sandy Bay',
      state: 'TAS',
    },
    7007: {
      lat: 147.319501,
      lng: -42.922863,
      city: 'Tolmans Hill',
      state: 'TAS',
    },
    7008: {
      lat: 147.293268,
      lng: -42.861932,
      city: 'New Town',
      state: 'TAS',
    },
    7009: {
      lat: 147.289682,
      lng: -42.842607,
      city: 'West Moonah',
      state: 'TAS',
    },
    7010: {
      lat: 147.212366,
      lng: -42.85913,
      city: 'Rosetta',
      state: 'TAS',
    },
    7011: {
      lat: 147.230065,
      lng: -42.793393,
      city: 'Claremont',
      state: 'TAS',
    },
    7012: {
      lat: 147.174382,
      lng: -42.833718,
      city: 'Glenlusk',
      state: 'TAS',
    },
    7015: {
      lat: 147.360174,
      lng: -42.83903,
      city: 'Rose Bay',
      state: 'TAS',
    },
    7016: {
      lat: 147.346381,
      lng: -42.813551,
      city: 'Risdon Vale',
      state: 'TAS',
    },
    7017: {
      lat: 147.315111,
      lng: -42.719274,
      city: 'Tea Tree',
      state: 'TAS',
    },
    7018: {
      lat: 147.385681,
      lng: -42.877588,
      city: 'Warrane',
      state: 'TAS',
    },
    7019: {
      lat: 147.433337,
      lng: -42.898778,
      city: 'Rokeby',
      state: 'TAS',
    },
    7020: {
      lat: 147.47851,
      lng: -42.965741,
      city: 'Sandford',
      state: 'TAS',
    },
    7021: {
      lat: 147.480017,
      lng: -42.906796,
      city: 'Lauderdale',
      state: 'TAS',
    },
    7022: {
      lat: 147.47988,
      lng: -43.053156,
      city: 'South Arm',
      state: 'TAS',
    },
    7023: {
      lat: 147.409324,
      lng: -42.988314,
      city: 'Opossum Bay',
      state: 'TAS',
    },
    7024: {
      lat: 147.512182,
      lng: -42.950412,
      city: 'Cremorne',
      state: 'TAS',
    },
    7025: {
      lat: 147.439671,
      lng: -42.730264,
      city: 'Richmond',
      state: 'TAS',
    },
    7026: {
      lat: 147.451363,
      lng: -42.646044,
      city: 'Campania',
      state: 'TAS',
    },
    7027: {
      lat: 147.383495,
      lng: -42.536377,
      city: 'Colebrook',
      state: 'TAS',
    },
    7030: {
      lat: 146.958381,
      lng: -42.304288,
      city: 'Wilburville',
      state: 'TAS',
    },
    7050: {
      lat: 147.298181,
      lng: -42.975502,
      city: 'Kingston Beach',
      state: 'TAS',
    },
    7051: {
      lat: 147.327238,
      lng: -42.987394,
      city: 'Kingston',
      state: 'TAS',
    },
    7052: {
      lat: 147.309302,
      lng: -43.008052,
      city: 'Blackmans Bay',
      state: 'TAS',
    },
    7053: {
      lat: 147.334695,
      lng: -42.947314,
      city: 'Taroona',
      state: 'TAS',
    },
    7054: {
      lat: 147.256698,
      lng: -43.00658,
      city: 'Wellington Park',
      state: 'TAS',
    },
    7055: {
      lat: 147.285203,
      lng: -42.994715,
      city: 'Huntingfield',
      state: 'TAS',
    },
    7109: {
      lat: 146.524064,
      lng: -43.571087,
      city: 'Woodstock',
      state: 'TAS',
    },
    7112: {
      lat: 147.140014,
      lng: -43.291937,
      city: 'Verona Sands',
      state: 'TAS',
    },
    7113: {
      lat: 146.970489,
      lng: -43.091051,
      city: 'Franklin',
      state: 'TAS',
    },
    7116: {
      lat: 147.03435,
      lng: -43.262123,
      city: 'Surveyors Bay',
      state: 'TAS',
    },
    7117: {
      lat: 147.040135,
      lng: -43.338435,
      city: 'Dover',
      state: 'TAS',
    },
    7119: {
      lat: 147.365372,
      lng: -42.431887,
      city: 'Stonor',
      state: 'TAS',
    },
    7120: {
      lat: 147.48727,
      lng: -42.31694,
      city: 'York Plains',
      state: 'TAS',
    },
    7139: {
      lat: 145.925302,
      lng: -42.715494,
      city: 'Strathgordon',
      state: 'TAS',
    },
    7140: {
      lat: 146.45502,
      lng: -42.544215,
      city: 'Westerway',
      state: 'TAS',
    },
    7150: {
      lat: 147.958441,
      lng: -43.077586,
      city: 'Upper Woodstock',
      state: 'TAS',
    },
    7151: {
      lat: 146.64266,
      lng: -41.518719,
      city: 'Mcdonald Islands',
      state: 'TAS',
    },
    7155: {
      lat: 147.216918,
      lng: -43.128868,
      city: 'Kettering',
      state: 'TAS',
    },
    7162: {
      lat: 147.209628,
      lng: -43.158652,
      city: 'Woodbridge',
      state: 'TAS',
    },
    7163: {
      lat: 147.227479,
      lng: -43.209774,
      city: 'Middleton',
      state: 'TAS',
    },
    7170: {
      lat: 147.449794,
      lng: -42.85132,
      city: 'Seven Mile Beach',
      state: 'TAS',
    },
    7171: {
      lat: 147.488282,
      lng: -42.78011,
      city: 'Penna',
      state: 'TAS',
    },
    7172: {
      lat: 147.631695,
      lng: -42.730285,
      city: 'Wattle Hill',
      state: 'TAS',
    },
    7173: {
      lat: 147.630737,
      lng: -42.823649,
      city: 'Primrose Sands',
      state: 'TAS',
    },
    7174: {
      lat: 147.743285,
      lng: -42.807682,
      city: 'Copping',
      state: 'TAS',
    },
    7175: {
      lat: 147.828594,
      lng: -42.818606,
      city: 'Marion Bay',
      state: 'TAS',
    },
    7176: {
      lat: 147.837675,
      lng: -42.753098,
      city: 'Kellevie',
      state: 'TAS',
    },
    7177: {
      lat: 147.976495,
      lng: -42.884195,
      city: 'Dunalley',
      state: 'TAS',
    },
    7178: {
      lat: 147.906152,
      lng: -42.958471,
      city: 'Murdunna',
      state: 'TAS',
    },
    7179: {
      lat: 147.944957,
      lng: -43.033826,
      city: 'Eaglehawk Neck',
      state: 'TAS',
    },
    7180: {
      lat: 148.006561,
      lng: -43.138712,
      city: 'Taranna',
      state: 'TAS',
    },
    7182: {
      lat: 147.822931,
      lng: -43.170598,
      city: 'Port Arthur',
      state: 'TAS',
    },
    7183: {
      lat: 147.750593,
      lng: -43.171185,
      city: 'Highcroft',
      state: 'TAS',
    },
    7184: {
      lat: 147.70789,
      lng: -43.152512,
      city: 'White Beach',
      state: 'TAS',
    },
    7185: {
      lat: 147.753782,
      lng: -43.062899,
      city: 'Premaydena',
      state: 'TAS',
    },
    7186: {
      lat: 147.663981,
      lng: -43.029525,
      city: 'Sloping Main',
      state: 'TAS',
    },
    7187: {
      lat: 147.848028,
      lng: -43.027742,
      city: 'Koonya',
      state: 'TAS',
    },
    7190: {
      lat: 148.091696,
      lng: -42.62988,
      city: 'Triabunna',
      state: 'TAS',
    },
    7209: {
      lat: 147.614307,
      lng: -42.104584,
      city: 'Tooms Lake',
      state: 'TAS',
    },
    7210: {
      lat: 147.410747,
      lng: -41.915923,
      city: 'Lake Leake',
      state: 'TAS',
    },
    7211: {
      lat: 147.317089,
      lng: -41.788619,
      city: 'Epping Forest',
      state: 'TAS',
    },
    7212: {
      lat: 147.488412,
      lng: -41.567876,
      city: 'Western Junction',
      state: 'TAS',
    },
    7213: {
      lat: 147.720441,
      lng: -41.782149,
      city: 'Storys Creek',
      state: 'TAS',
    },
    7214: {
      lat: 147.897402,
      lng: -41.600719,
      city: 'Upper Esk',
      state: 'TAS',
    },
    7215: {
      lat: 148.281407,
      lng: -42.314526,
      city: 'Upper Scamander',
      state: 'TAS',
    },
    7216: {
      lat: 148.076401,
      lng: -41.284242,
      city: 'The Gardens',
      state: 'TAS',
    },
    7248: {
      lat: 147.130185,
      lng: -41.397239,
      city: 'Rocherlea',
      state: 'TAS',
    },
    7249: {
      lat: 147.157786,
      lng: -41.472435,
      city: 'Youngtown',
      state: 'TAS',
    },
    7250: {
      lat: 147.077589,
      lng: -41.432254,
      city: 'West Launceston',
      state: 'TAS',
    },
    7252: {
      lat: 146.99252,
      lng: -41.148031,
      city: 'Windermere',
      state: 'TAS',
    },
    7253: {
      lat: 146.83066,
      lng: -41.117415,
      city: 'Low Head',
      state: 'TAS',
    },
    7254: {
      lat: 147.198018,
      lng: -41.13407,
      city: 'Wyena',
      state: 'TAS',
    },
    7255: {
      lat: 148.329247,
      lng: -39.949379,
      city: 'Wingaroo',
      state: 'TAS',
    },
    7256: {
      lat: 144.159775,
      lng: -39.830946,
      city: 'Yarra Creek',
      state: 'TAS',
    },
    7257: {
      lat: 148.338962,
      lng: -40.505978,
      city: 'Cape Barren Island',
      state: 'TAS',
    },
    7258: {
      lat: 147.209446,
      lng: -41.499942,
      city: 'White Hills',
      state: 'TAS',
    },
    7259: {
      lat: 147.357686,
      lng: -41.358817,
      city: 'Tayene',
      state: 'TAS',
    },
    7260: {
      lat: 147.502082,
      lng: -41.158791,
      city: 'West Scottsdale',
      state: 'TAS',
    },
    7261: {
      lat: 147.567819,
      lng: -41.12694,
      city: 'Warrentinna',
      state: 'TAS',
    },
    7262: {
      lat: 147.630044,
      lng: -40.801511,
      city: 'Waterhouse',
      state: 'TAS',
    },
    7263: {
      lat: 147.702655,
      lng: -41.308785,
      city: 'Trenah',
      state: 'TAS',
    },
    7264: {
      lat: 148.026137,
      lng: -41.015197,
      city: 'Weldborough',
      state: 'TAS',
    },
    7265: {
      lat: 147.823431,
      lng: -41.08106,
      city: 'Winnaleah',
      state: 'TAS',
    },
    7267: {
      lat: 147.086396,
      lng: -41.264912,
      city: 'Turners Marsh',
      state: 'TAS',
    },
    7268: {
      lat: 147.152757,
      lng: -41.333887,
      city: 'Underwood',
      state: 'TAS',
    },
    7270: {
      lat: 146.783947,
      lng: -41.177924,
      city: 'York Town',
      state: 'TAS',
    },
    7275: {
      lat: 146.835317,
      lng: -41.319891,
      city: 'Winkleigh',
      state: 'TAS',
    },
    7276: {
      lat: 146.972023,
      lng: -41.293081,
      city: 'Gravelly Beach',
      state: 'TAS',
    },
    7277: {
      lat: 146.996977,
      lng: -41.366416,
      city: 'Rosevears',
      state: 'TAS',
    },
    7290: {
      lat: 147.068603,
      lng: -41.512507,
      city: 'Hadspen',
      state: 'TAS',
    },
    7291: {
      lat: 147.013597,
      lng: -41.530631,
      city: 'Carrick',
      state: 'TAS',
    },
    7292: {
      lat: 146.944728,
      lng: -41.472098,
      city: 'Westwood',
      state: 'TAS',
    },
    7300: {
      lat: 147.223173,
      lng: -41.616219,
      city: 'Powranna',
      state: 'TAS',
    },
    7301: {
      lat: 147.046481,
      lng: -41.600123,
      city: 'Toiberry',
      state: 'TAS',
    },
    7302: {
      lat: 146.988293,
      lng: -41.70457,
      city: 'Poatina',
      state: 'TAS',
    },
    7303: {
      lat: 146.815084,
      lng: -41.594913,
      city: 'Whitemore',
      state: 'TAS',
    },
    7304: {
      lat: 146.165998,
      lng: -41.710417,
      city: 'Western Creek',
      state: 'TAS',
    },
    7305: {
      lat: 146.412127,
      lng: -41.352322,
      city: 'Sunnyside',
      state: 'TAS',
    },
    7306: {
      lat: 146.193428,
      lng: -41.465311,
      city: 'West Kentish',
      state: 'TAS',
    },
    7307: {
      lat: 146.57356,
      lng: -41.210495,
      city: 'Wesley Vale',
      state: 'TAS',
    },
    7310: {
      lat: 146.239406,
      lng: -41.237355,
      city: 'Wilmot',
      state: 'TAS',
    },
    7315: {
      lat: 146.072407,
      lng: -41.303174,
      city: 'West Ulverstone',
      state: 'TAS',
    },
    7316: {
      lat: 145.931776,
      lng: -41.269708,
      city: 'West Pine',
      state: 'TAS',
    },
    7320: {
      lat: 145.862079,
      lng: -41.08422,
      city: 'Wivenhoe',
      state: 'TAS',
    },
    7321: {
      lat: 145.503726,
      lng: -41.456389,
      city: 'Wiltshire',
      state: 'TAS',
    },
    7322: {
      lat: 145.804533,
      lng: -41.044472,
      city: 'Somerset',
      state: 'TAS',
    },
    7325: {
      lat: 145.607556,
      lng: -41.100305,
      city: 'Yolla',
      state: 'TAS',
    },
    7330: {
      lat: 145.057623,
      lng: -40.782341,
      city: 'Woolnorth',
      state: 'TAS',
    },
    7331: {
      lat: 145.275421,
      lng: -40.758553,
      city: 'Stanley',
      state: 'TAS',
    },
    7466: {
      lat: 145.807453,
      lng: -42.291313,
      city: 'Gormanston',
      state: 'TAS',
    },
    7467: {
      lat: 145.529693,
      lng: -42.093728,
      city: 'Queenstown',
      state: 'TAS',
    },
    7468: {
      lat: 145.537671,
      lng: -42.706031,
      city: 'Strahan',
      state: 'TAS',
    },
    7469: {
      lat: 145.201632,
      lng: -41.784391,
      city: 'Zeehan',
      state: 'TAS',
    },
    7470: {
      lat: 145.500566,
      lng: -41.902129,
      city: 'Rosebery',
      state: 'TAS',
    },
    8001: {
      lat: 144.76592,
      lng: -38.365017,
      city: 'Melbourne',
      state: 'VIC',
    },
    8002: {
      lat: 144.982207,
      lng: -37.818517,
      city: 'East Melbourne',
      state: 'VIC',
    },
    8003: {
      lat: 144.957,
      lng: -37.8183,
      city: 'Collins Street East',
      state: 'VIC',
    },
    8004: {
      lat: 145.4689942,
      lng: -36.5587997,
      city: 'St Kilda Road',
      state: 'VIC',
    },
    8005: {
      lat: 144.950858,
      lng: -37.824608,
      city: 'World Trade Centre',
      state: 'VIC',
    },
    8006: {
      lat: 144.9593062,
      lng: -37.8097006,
      city: 'Abeckett Street',
      state: 'VIC',
    },
    8007: {
      lat: 144.957,
      lng: -37.8183,
      city: 'Collins Street West',
      state: 'VIC',
    },
    8008: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'St Kilda Road Central',
      state: 'VIC',
    },
    8009: {
      lat: 144.964531,
      lng: -37.817201,
      city: 'Flinders Lane',
      state: 'VIC',
    },
    8010: {
      lat: 146.293728,
      lng: -38.185857,
      city: 'Law Courts',
      state: 'VIC',
    },
    8011: {
      lat: 144.9617461,
      lng: -37.8113144,
      city: 'Little Lonsdale Street',
      state: 'VIC',
    },
    8012: {
      lat: 144.948039,
      lng: -37.814719,
      city: 'Docklands',
      state: 'VIC',
    },
    8045: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8051: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8066: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8069: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8070: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8071: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8102: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8107: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8111: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8120: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8205: {
      lat: 144.970161,
      lng: -37.844246,
      city: 'Melbourne',
      state: 'VIC',
    },
    8438: { lat: 0, lng: 0, city: 'Sunshine West', state: 'VIC' },
    8511: { lat: 0, lng: 0, city: 'Sunshine West', state: 'VIC' },
    8785: {
      lat: 145.208504,
      lng: -38.016114,
      city: 'Dandenong South',
      state: 'VIC',
    },
    9000: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Brisbane',
      state: 'QLD',
    },
    9001: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Brisbane',
      state: 'QLD',
    },
    9002: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Brisbane',
      state: 'QLD',
    },
    9005: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Brisbane',
      state: 'QLD',
    },
    9007: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Brisbane',
      state: 'QLD',
    },
    9009: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Brisbane',
      state: 'QLD',
    },
    9010: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Brisbane',
      state: 'QLD',
    },
    9013: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Brisbane',
      state: 'QLD',
    },
    9015: {
      lat: 152.823141,
      lng: -27.603479,
      city: 'Brisbane',
      state: 'QLD',
    },
    9464: {
      lat: 153.074982,
      lng: -27.397055,
      city: 'Northgate Mc',
      state: 'QLD',
    },
    9726: {
      lat: 153.412197,
      lng: -28.008783,
      city: 'Gold Coast Mc',
      state: 'QLD',
    },
    9999: {
      lat: 144.956776,
      lng: -37.817403,
      city: 'North Pole',
      state: 'VIC',
    },
    '0200': {
      lat: 149.1189,
      lng: -35.2777,
      city: 'Australian National University',
      state: 'ACT',
    },
    '0800': {
      lat: 130.83668,
      lng: -12.458684,
      city: 'Darwin City',
      state: 'NT',
    },
    '0801': {
      lat: 130.83668,
      lng: -12.458684,
      city: 'Darwin',
      state: 'NT',
    },
    '0803': { lat: 0, lng: 0, city: 'Wagait Beach', state: 'NT' },
    '0804': {
      lat: 130.873315,
      lng: -12.428017,
      city: 'Parap',
      state: 'NT',
    },
    '0810': {
      lat: 130.866242,
      lng: -12.381806,
      city: 'Wanguri',
      state: 'NT',
    },
    '0811': {
      lat: 130.866242,
      lng: -12.381806,
      city: 'Casuarina',
      state: 'NT',
    },
    '0812': {
      lat: 130.916098,
      lng: -12.404778,
      city: 'Wulagi',
      state: 'NT',
    },
    '0813': {
      lat: 130.916098,
      lng: -12.404778,
      city: 'Sanderson',
      state: 'NT',
    },
    '0814': {
      lat: 130.866242,
      lng: -12.381806,
      city: 'Nightcliff',
      state: 'NT',
    },
    '0815': {
      lat: 130.869,
      lng: -12.3718,
      city: 'Charles Darwin University',
      state: 'NT',
    },
    '0820': {
      lat: 130.873315,
      lng: -12.428017,
      city: 'Woolner',
      state: 'NT',
    },
    '0821': {
      lat: 136.947051,
      lng: -13.643283,
      city: 'Winnellie',
      state: 'NT',
    },
    '0822': {
      lat: 130.6254299,
      lng: -11.75841975,
      city: 'Wurrumiyanga',
      state: 'NT',
    },
    '0828': {
      lat: 130.932068,
      lng: -12.432024,
      city: 'Knuckey Lagoon',
      state: 'NT',
    },
    '0829': {
      lat: 130.9597671,
      lng: -12.45763297,
      city: 'Pinelands',
      state: 'NT',
    },
    '0830': {
      lat: 130.974879,
      lng: -12.475071,
      city: 'Yarrawonga',
      state: 'NT',
    },
    '0831': {
      lat: 130.974879,
      lng: -12.475071,
      city: 'Palmerston',
      state: 'NT',
    },
    '0832': {
      lat: 131.0058233,
      lng: -12.51070433,
      city: 'Zuccoli',
      state: 'NT',
    },
    '0834': {
      lat: 131.0234875,
      lng: -12.54686461,
      city: 'Virginia',
      state: 'NT',
    },
    '0835': {
      lat: 131.013741,
      lng: -12.452568,
      city: 'Virginia',
      state: 'NT',
    },
    '0836': {
      lat: 131.1272564,
      lng: -12.58487247,
      city: 'Humpty Doo',
      state: 'NT',
    },
    '0837': {
      lat: 131.094044,
      lng: -12.64187171,
      city: 'Noonamah',
      state: 'NT',
    },
    '0838': {
      lat: 131.0067348,
      lng: -12.71878122,
      city: 'Berry Springs',
      state: 'NT',
    },
    '0839': {
      lat: 131.0402017,
      lng: -12.52001124,
      city: 'Coolalinga',
      state: 'NT',
    },
    '0840': {
      lat: 130.4518371,
      lng: -12.71366907,
      city: 'Dundee Forest',
      state: 'NT',
    },
    '0841': {
      lat: 130.969862,
      lng: -12.81922863,
      city: 'Darwin River',
      state: 'NT',
    },
    '0845': {
      lat: 131.038475,
      lng: -13.045118,
      city: 'Batchelor',
      state: 'NT',
    },
    '0846': {
      lat: 131.095002,
      lng: -13.210299,
      city: 'Adelaide River',
      state: 'NT',
    },
    '0847': {
      lat: 131.759154,
      lng: -14.012122,
      city: 'Pine Creek',
      state: 'NT',
    },
    '0850': {
      lat: 132.3888705,
      lng: -14.43162875,
      city: 'Lansdowne',
      state: 'NT',
    },
    '0851': {
      lat: 137.050719,
      lng: -15.711424,
      city: 'Katherine',
      state: 'NT',
    },
    '0852': {
      lat: 130.8418661,
      lng: -16.4525888,
      city: 'Yarralin',
      state: 'NT',
    },
    '0853': {
      lat: 132.417121,
      lng: -14.558122,
      city: 'Tindal Raaf',
      state: 'NT',
    },
    '0854': {
      lat: 136.3091159,
      lng: -16.070754,
      city: 'King Ash Bay',
      state: 'NT',
    },
    '0860': {
      lat: 134.173599,
      lng: -19.641301,
      city: 'Tennant Creek',
      state: 'NT',
    },
    '0861': {
      lat: 134.463153,
      lng: -18.251528,
      city: 'Tennant Creek',
      state: 'NT',
    },
    '0862': {
      lat: 134.463153,
      lng: -18.251528,
      city: 'Wycliffe Well',
      state: 'NT',
    },
    '0870': {
      lat: 133.7578528,
      lng: -23.75188561,
      city: 'White Gums',
      state: 'NT',
    },
    '0871': {
      lat: 131.298809,
      lng: -21.949513,
      city: 'Alice Springs',
      state: 'NT',
    },
    '0872': {
      lat: 132.4550072,
      lng: -26.32328109,
      city: 'Yunyarinyi',
      state: 'SA',
    },
    '0873': {
      lat: 133.9050112,
      lng: -23.74471408,
      city: 'Ross',
      state: 'NT',
    },
    '0874': {
      lat: 133.9224797,
      lng: -23.68480339,
      city: 'Undoolya',
      state: 'NT',
    },
    '0875': {
      lat: 133.8311794,
      lng: -23.68028355,
      city: 'Larapinta',
      state: 'NT',
    },
    '0880': {
      lat: 136.8772098,
      lng: -12.2425718,
      city: 'Yirrkala',
      state: 'NT',
    },
    '0881': {
      lat: 136.824863,
      lng: -11.670366,
      city: 'Nhulunbuy',
      state: 'NT',
    },
    '0885': {
      lat: 136.420066,
      lng: -13.85078586,
      city: 'Alyangula',
      state: 'NT',
    },
    '0886': {
      lat: 132.563373,
      lng: -13.13118,
      city: 'Jabiru',
      state: 'NT',
    },
    '0906': {
      lat: 136.947051,
      lng: -13.643283,
      city: 'Winnellie',
      state: 'NT',
    },
    '0907': {
      lat: 136.947051,
      lng: -13.643283,
      city: 'Winnellie',
      state: 'NT',
    },
    '0909': {
      lat: 130.869,
      lng: -12.3718,
      city: 'University',
      state: 'NT',
    },
  },
  US: {
    10001: {
      lat: 40.75064,
      lng: -73.99728,
      city: 'New York',
      state: 'NY',
    },
    10002: {
      lat: 40.71597,
      lng: -73.98692,
      city: 'New York',
      state: 'NY',
    },
    10003: {
      lat: 40.73184,
      lng: -73.98915,
      city: 'New York',
      state: 'NY',
    },
    10004: {
      lat: 40.69431,
      lng: -74.01835,
      city: 'New York',
      state: 'NY',
    },
    10005: {
      lat: 40.70614,
      lng: -74.00862,
      city: 'New York',
      state: 'NY',
    },
    10006: {
      lat: 40.70949,
      lng: -74.01306,
      city: 'New York',
      state: 'NY',
    },
    10007: {
      lat: 40.71387,
      lng: -74.00792,
      city: 'New York',
      state: 'NY',
    },
    10009: {
      lat: 40.72639,
      lng: -73.97865,
      city: 'New York',
      state: 'NY',
    },
    10010: {
      lat: 40.73899,
      lng: -73.98243,
      city: 'New York',
      state: 'NY',
    },
    10011: {
      lat: 40.74173,
      lng: -74.00037,
      city: 'New York',
      state: 'NY',
    },
    10012: {
      lat: 40.7256,
      lng: -73.99794,
      city: 'New York',
      state: 'NY',
    },
    10013: {
      lat: 40.72001,
      lng: -74.00472,
      city: 'New York',
      state: 'NY',
    },
    10014: {
      lat: 40.73407,
      lng: -74.00601,
      city: 'New York',
      state: 'NY',
    },
    10016: {
      lat: 40.74515,
      lng: -73.97807,
      city: 'New York',
      state: 'NY',
    },
    10017: {
      lat: 40.75235,
      lng: -73.9726,
      city: 'New York',
      state: 'NY',
    },
    10018: {
      lat: 40.75536,
      lng: -73.99265,
      city: 'New York',
      state: 'NY',
    },
    10019: {
      lat: 40.76586,
      lng: -73.9853,
      city: 'New York',
      state: 'NY',
    },
    10020: {
      lat: 40.75917,
      lng: -73.98071,
      city: 'New York',
      state: 'NY',
    },
    10021: {
      lat: 40.76963,
      lng: -73.95899,
      city: 'New York',
      state: 'NY',
    },
    10022: {
      lat: 40.75856,
      lng: -73.96787,
      city: 'New York',
      state: 'NY',
    },
    10023: {
      lat: 40.77464,
      lng: -73.97836,
      city: 'New York',
      state: 'NY',
    },
    10024: {
      lat: 40.78534,
      lng: -73.97138,
      city: 'New York',
      state: 'NY',
    },
    10025: {
      lat: 40.79825,
      lng: -73.96831,
      city: 'New York',
      state: 'NY',
    },
    10026: {
      lat: 40.80302,
      lng: -73.95348,
      city: 'New York',
      state: 'NY',
    },
    10027: {
      lat: 40.81187,
      lng: -73.95459,
      city: 'New York',
      state: 'NY',
    },
    10028: {
      lat: 40.77643,
      lng: -73.95339,
      city: 'New York',
      state: 'NY',
    },
    10029: {
      lat: 40.79265,
      lng: -73.94788,
      city: 'New York',
      state: 'NY',
    },
    10030: {
      lat: 40.81826,
      lng: -73.94283,
      city: 'New York',
      state: 'NY',
    },
    10031: {
      lat: 40.82485,
      lng: -73.95023,
      city: 'New York',
      state: 'NY',
    },
    10032: {
      lat: 40.839,
      lng: -73.94282,
      city: 'New York',
      state: 'NY',
    },
    10033: {
      lat: 40.84744,
      lng: -73.93549,
      city: 'New York',
      state: 'NY',
    },
    10034: {
      lat: 40.86749,
      lng: -73.92358,
      city: 'New York',
      state: 'NY',
    },
    10035: {
      lat: 40.79538,
      lng: -73.92965,
      city: 'New York',
      state: 'NY',
    },
    10036: {
      lat: 40.75976,
      lng: -73.99064,
      city: 'New York',
      state: 'NY',
    },
    10037: {
      lat: 40.81302,
      lng: -73.93776,
      city: 'New York',
      state: 'NY',
    },
    10038: {
      lat: 40.70914,
      lng: -74.00298,
      city: 'New York',
      state: 'NY',
    },
    10039: {
      lat: 40.82631,
      lng: -73.93752,
      city: 'New York',
      state: 'NY',
    },
    10040: {
      lat: 40.85807,
      lng: -73.9296,
      city: 'New York',
      state: 'NY',
    },
    10044: {
      lat: 40.76209,
      lng: -73.94981,
      city: 'New York',
      state: 'NY',
    },
    10065: {
      lat: 40.76472,
      lng: -73.96332,
      city: 'New York',
      state: 'NY',
    },
    10069: {
      lat: 40.77752,
      lng: -73.98875,
      city: 'New York',
      state: 'NY',
    },
    10075: {
      lat: 40.77342,
      lng: -73.95642,
      city: 'New York',
      state: 'NY',
    },
    10103: {
      lat: 40.76078,
      lng: -73.97767,
      city: 'New York',
      state: 'NY',
    },
    10110: {
      lat: 40.7545,
      lng: -73.98226,
      city: 'New York',
      state: 'NY',
    },
    10111: {
      lat: 40.75953,
      lng: -73.97858,
      city: 'New York',
      state: 'NY',
    },
    10112: {
      lat: 40.75891,
      lng: -73.97904,
      city: 'New York',
      state: 'NY',
    },
    10115: {
      lat: 40.81085,
      lng: -73.96374,
      city: 'New York',
      state: 'NY',
    },
    10119: {
      lat: 40.75064,
      lng: -73.99273,
      city: 'New York',
      state: 'NY',
    },
    10128: {
      lat: 40.78151,
      lng: -73.95018,
      city: 'New York',
      state: 'NY',
    },
    10152: {
      lat: 40.75872,
      lng: -73.9718,
      city: 'New York',
      state: 'NY',
    },
    10153: {
      lat: 40.76362,
      lng: -73.97244,
      city: 'New York',
      state: 'NY',
    },
    10154: {
      lat: 40.75778,
      lng: -73.97249,
      city: 'New York',
      state: 'NY',
    },
    10162: {
      lat: 40.76936,
      lng: -73.94986,
      city: 'New York',
      state: 'NY',
    },
    10165: {
      lat: 40.75213,
      lng: -73.97872,
      city: 'New York',
      state: 'NY',
    },
    10167: {
      lat: 40.75465,
      lng: -73.97477,
      city: 'New York',
      state: 'NY',
    },
    10168: {
      lat: 40.75145,
      lng: -73.9771,
      city: 'New York',
      state: 'NY',
    },
    10169: {
      lat: 40.75467,
      lng: -73.97643,
      city: 'New York',
      state: 'NY',
    },
    10170: {
      lat: 40.75263,
      lng: -73.97588,
      city: 'New York',
      state: 'NY',
    },
    10171: {
      lat: 40.7559,
      lng: -73.97386,
      city: 'New York',
      state: 'NY',
    },
    10172: {
      lat: 40.75527,
      lng: -73.97431,
      city: 'New York',
      state: 'NY',
    },
    10173: {
      lat: 40.75379,
      lng: -73.97961,
      city: 'New York',
      state: 'NY',
    },
    10174: {
      lat: 40.75144,
      lng: -73.975,
      city: 'New York',
      state: 'NY',
    },
    10177: {
      lat: 40.75498,
      lng: -73.97589,
      city: 'New York',
      state: 'NY',
    },
    10199: {
      lat: 40.75138,
      lng: -73.99715,
      city: 'New York',
      state: 'NY',
    },
    10271: {
      lat: 40.70844,
      lng: -74.01033,
      city: 'New York',
      state: 'NY',
    },
    10278: {
      lat: 40.71513,
      lng: -74.00373,
      city: 'New York',
      state: 'NY',
    },
    10279: {
      lat: 40.71263,
      lng: -74.00867,
      city: 'New York',
      state: 'NY',
    },
    10280: {
      lat: 40.70985,
      lng: -74.01651,
      city: 'New York',
      state: 'NY',
    },
    10282: {
      lat: 40.71655,
      lng: -74.01495,
      city: 'New York',
      state: 'NY',
    },
    10301: {
      lat: 40.62803,
      lng: -74.09564,
      city: 'Staten Island',
      state: 'NY',
    },
    10302: {
      lat: 40.63055,
      lng: -74.13776,
      city: 'Staten Island',
      state: 'NY',
    },
    10303: {
      lat: 40.63223,
      lng: -74.16903,
      city: 'Staten Island',
      state: 'NY',
    },
    10304: {
      lat: 40.60671,
      lng: -74.0931,
      city: 'Staten Island',
      state: 'NY',
    },
    10305: {
      lat: 40.59592,
      lng: -74.07539,
      city: 'Staten Island',
      state: 'NY',
    },
    10306: {
      lat: 40.57149,
      lng: -74.1243,
      city: 'Staten Island',
      state: 'NY',
    },
    10307: {
      lat: 40.50817,
      lng: -74.24254,
      city: 'Staten Island',
      state: 'NY',
    },
    10308: {
      lat: 40.55213,
      lng: -74.15029,
      city: 'Staten Island',
      state: 'NY',
    },
    10309: {
      lat: 40.53001,
      lng: -74.21912,
      city: 'Staten Island',
      state: 'NY',
    },
    10310: {
      lat: 40.63243,
      lng: -74.11653,
      city: 'Staten Island',
      state: 'NY',
    },
    10311: {
      lat: 40.60523,
      lng: -74.17956,
      city: 'Staten Island',
      state: 'NY',
    },
    10312: {
      lat: 40.54489,
      lng: -74.18084,
      city: 'Staten Island',
      state: 'NY',
    },
    10314: {
      lat: 40.60086,
      lng: -74.16306,
      city: 'Staten Island',
      state: 'NY',
    },
    10451: {
      lat: 40.82097,
      lng: -73.92383,
      city: 'Bronx',
      state: 'NY',
    },
    10452: { lat: 40.8378, lng: -73.92333, city: 'Bronx', state: 'NY' },
    10453: { lat: 40.85248, lng: -73.9133, city: 'Bronx', state: 'NY' },
    10454: { lat: 40.8056, lng: -73.91664, city: 'Bronx', state: 'NY' },
    10455: { lat: 40.8148, lng: -73.90862, city: 'Bronx', state: 'NY' },
    10456: {
      lat: 40.83064,
      lng: -73.90812,
      city: 'Bronx',
      state: 'NY',
    },
    10457: {
      lat: 40.84571,
      lng: -73.89794,
      city: 'Bronx',
      state: 'NY',
    },
    10458: {
      lat: 40.86249,
      lng: -73.88643,
      city: 'Bronx',
      state: 'NY',
    },
    10459: {
      lat: 40.82572,
      lng: -73.89295,
      city: 'Bronx',
      state: 'NY',
    },
    10460: {
      lat: 40.84273,
      lng: -73.87891,
      city: 'Bronx',
      state: 'NY',
    },
    10461: {
      lat: 40.84671,
      lng: -73.84079,
      city: 'Bronx',
      state: 'NY',
    },
    10462: {
      lat: 40.84297,
      lng: -73.85873,
      city: 'Bronx',
      state: 'NY',
    },
    10463: {
      lat: 40.88841,
      lng: -73.89905,
      city: 'Bronx',
      state: 'NY',
    },
    10464: {
      lat: 40.86821,
      lng: -73.80044,
      city: 'Bronx',
      state: 'NY',
    },
    10465: { lat: 40.8286, lng: -73.82179, city: 'Bronx', state: 'NY' },
    10466: { lat: 40.89104, lng: -73.8461, city: 'Bronx', state: 'NY' },
    10467: { lat: 40.87522, lng: -73.872, city: 'Bronx', state: 'NY' },
    10468: { lat: 40.8681, lng: -73.89985, city: 'Bronx', state: 'NY' },
    10469: {
      lat: 40.86851,
      lng: -73.84666,
      city: 'Bronx',
      state: 'NY',
    },
    10470: {
      lat: 40.89598,
      lng: -73.86805,
      city: 'Bronx',
      state: 'NY',
    },
    10471: { lat: 40.90002, lng: -73.9067, city: 'Bronx', state: 'NY' },
    10472: {
      lat: 40.82968,
      lng: -73.86942,
      city: 'Bronx',
      state: 'NY',
    },
    10473: {
      lat: 40.81817,
      lng: -73.85889,
      city: 'Bronx',
      state: 'NY',
    },
    10474: { lat: 40.81061, lng: -73.8846, city: 'Bronx', state: 'NY' },
    10475: {
      lat: 40.87528,
      lng: -73.82702,
      city: 'Bronx',
      state: 'NY',
    },
    10501: {
      lat: 41.2954,
      lng: -73.76006,
      city: 'Amawalk',
      state: 'NY',
    },
    10502: {
      lat: 41.01126,
      lng: -73.8409,
      city: 'Ardsley',
      state: 'NY',
    },
    10503: {
      lat: 41.02654,
      lng: -73.8753,
      city: 'Ardsley On Hudson',
      state: 'NY',
    },
    10504: {
      lat: 41.13065,
      lng: -73.70629,
      city: 'Armonk',
      state: 'NY',
    },
    10505: {
      lat: 41.34207,
      lng: -73.74791,
      city: 'Baldwin Place',
      state: 'NY',
    },
    10506: {
      lat: 41.1889,
      lng: -73.63411,
      city: 'Bedford',
      state: 'NY',
    },
    10507: {
      lat: 41.22879,
      lng: -73.6878,
      city: 'Bedford Hills',
      state: 'NY',
    },
    10509: {
      lat: 41.41205,
      lng: -73.5937,
      city: 'Brewster',
      state: 'NY',
    },
    10510: {
      lat: 41.14152,
      lng: -73.83411,
      city: 'Briarcliff Manor',
      state: 'NY',
    },
    10511: {
      lat: 41.26302,
      lng: -73.94391,
      city: 'Buchanan',
      state: 'NY',
    },
    10512: {
      lat: 41.4483,
      lng: -73.71054,
      city: 'Carmel',
      state: 'NY',
    },
    10514: {
      lat: 41.17331,
      lng: -73.77071,
      city: 'Chappaqua',
      state: 'NY',
    },
    10516: {
      lat: 41.45342,
      lng: -73.90233,
      city: 'Cold Spring',
      state: 'NY',
    },
    10517: {
      lat: 41.29837,
      lng: -73.86013,
      city: 'Crompond',
      state: 'NY',
    },
    10518: {
      lat: 41.26787,
      lng: -73.60073,
      city: 'Cross River',
      state: 'NY',
    },
    10519: {
      lat: 41.34701,
      lng: -73.66196,
      city: 'Croton Falls',
      state: 'NY',
    },
    10520: {
      lat: 41.22183,
      lng: -73.87348,
      city: 'Croton On Hudson',
      state: 'NY',
    },
    10522: {
      lat: 41.01221,
      lng: -73.8646,
      city: 'Dobbs Ferry',
      state: 'NY',
    },
    10523: {
      lat: 41.06005,
      lng: -73.81859,
      city: 'Elmsford',
      state: 'NY',
    },
    10524: {
      lat: 41.37076,
      lng: -73.92201,
      city: 'Garrison',
      state: 'NY',
    },
    10526: {
      lat: 41.29388,
      lng: -73.66171,
      city: 'Goldens Bridge',
      state: 'NY',
    },
    10527: {
      lat: 41.3195,
      lng: -73.76502,
      city: 'Granite Springs',
      state: 'NY',
    },
    10528: {
      lat: 40.97819,
      lng: -73.72306,
      city: 'Harrison',
      state: 'NY',
    },
    10530: {
      lat: 41.02304,
      lng: -73.80764,
      city: 'Hartsdale',
      state: 'NY',
    },
    10532: {
      lat: 41.09873,
      lng: -73.80066,
      city: 'Hawthorne',
      state: 'NY',
    },
    10533: {
      lat: 41.03826,
      lng: -73.8556,
      city: 'Irvington',
      state: 'NY',
    },
    10535: {
      lat: 41.33536,
      lng: -73.79965,
      city: 'Jefferson Valley',
      state: 'NY',
    },
    10536: {
      lat: 41.26971,
      lng: -73.68943,
      city: 'Katonah',
      state: 'NY',
    },
    10537: {
      lat: 41.33784,
      lng: -73.88528,
      city: 'Lake Peekskill',
      state: 'NY',
    },
    10538: {
      lat: 40.9376,
      lng: -73.75707,
      city: 'Larchmont',
      state: 'NY',
    },
    10540: {
      lat: 41.32575,
      lng: -73.72745,
      city: 'Lincolndale',
      state: 'NY',
    },
    10541: {
      lat: 41.37855,
      lng: -73.75122,
      city: 'Mahopac',
      state: 'NY',
    },
    10542: {
      lat: 41.37115,
      lng: -73.76436,
      city: 'Mahopac Falls',
      state: 'NY',
    },
    10543: {
      lat: 40.95274,
      lng: -73.73645,
      city: 'Mamaroneck',
      state: 'NY',
    },
    10545: {
      lat: 41.17861,
      lng: -73.83547,
      city: 'Maryknoll',
      state: 'NY',
    },
    10546: {
      lat: 41.19472,
      lng: -73.8002,
      city: 'Millwood',
      state: 'NY',
    },
    10547: {
      lat: 41.31178,
      lng: -73.84637,
      city: 'Mohegan Lake',
      state: 'NY',
    },
    10548: {
      lat: 41.24599,
      lng: -73.93643,
      city: 'Montrose',
      state: 'NY',
    },
    10549: {
      lat: 41.19981,
      lng: -73.72028,
      city: 'Mount Kisco',
      state: 'NY',
    },
    10550: {
      lat: 40.90627,
      lng: -73.8351,
      city: 'Mount Vernon',
      state: 'NY',
    },
    10552: {
      lat: 40.92376,
      lng: -73.82497,
      city: 'Mount Vernon',
      state: 'NY',
    },
    10553: {
      lat: 40.90858,
      lng: -73.82161,
      city: 'Mount Vernon',
      state: 'NY',
    },
    10560: {
      lat: 41.33303,
      lng: -73.60264,
      city: 'North Salem',
      state: 'NY',
    },
    10562: {
      lat: 41.19295,
      lng: -73.8305,
      city: 'Ossining',
      state: 'NY',
    },
    10566: {
      lat: 41.28924,
      lng: -73.91837,
      city: 'Peekskill',
      state: 'NY',
    },
    10567: {
      lat: 41.28922,
      lng: -73.89633,
      city: 'Cortlandt Manor',
      state: 'NY',
    },
    10570: {
      lat: 41.12854,
      lng: -73.78917,
      city: 'Pleasantville',
      state: 'NY',
    },
    10573: {
      lat: 41.01676,
      lng: -73.67735,
      city: 'Port Chester',
      state: 'NY',
    },
    10576: {
      lat: 41.21323,
      lng: -73.57289,
      city: 'Pound Ridge',
      state: 'NY',
    },
    10577: {
      lat: 41.03755,
      lng: -73.71349,
      city: 'Purchase',
      state: 'NY',
    },
    10578: { lat: 41.31815, lng: -73.673, city: 'Purdys', state: 'NY' },
    10579: {
      lat: 41.39651,
      lng: -73.83384,
      city: 'Putnam Valley',
      state: 'NY',
    },
    10580: { lat: 40.97854, lng: -73.69258, city: 'Rye', state: 'NY' },
    10583: {
      lat: 40.98916,
      lng: -73.79443,
      city: 'Scarsdale',
      state: 'NY',
    },
    10588: {
      lat: 41.33222,
      lng: -73.82624,
      city: 'Shrub Oak',
      state: 'NY',
    },
    10589: {
      lat: 41.33178,
      lng: -73.6933,
      city: 'Somers',
      state: 'NY',
    },
    10590: {
      lat: 41.25421,
      lng: -73.53719,
      city: 'South Salem',
      state: 'NY',
    },
    10591: {
      lat: 41.08436,
      lng: -73.84653,
      city: 'Tarrytown',
      state: 'NY',
    },
    10594: {
      lat: 41.11579,
      lng: -73.771,
      city: 'Thornwood',
      state: 'NY',
    },
    10595: {
      lat: 41.08626,
      lng: -73.78097,
      city: 'Valhalla',
      state: 'NY',
    },
    10596: {
      lat: 41.25552,
      lng: -73.95854,
      city: 'Verplanck',
      state: 'NY',
    },
    10597: {
      lat: 41.29423,
      lng: -73.59703,
      city: 'Waccabuc',
      state: 'NY',
    },
    10598: {
      lat: 41.28309,
      lng: -73.79516,
      city: 'Yorktown Heights',
      state: 'NY',
    },
    10601: {
      lat: 41.03288,
      lng: -73.7648,
      city: 'White Plains',
      state: 'NY',
    },
    10603: {
      lat: 41.05364,
      lng: -73.78014,
      city: 'White Plains',
      state: 'NY',
    },
    10604: {
      lat: 41.06013,
      lng: -73.73955,
      city: 'West Harrison',
      state: 'NY',
    },
    10605: {
      lat: 41.0095,
      lng: -73.74518,
      city: 'White Plains',
      state: 'NY',
    },
    10606: {
      lat: 41.02065,
      lng: -73.77588,
      city: 'White Plains',
      state: 'NY',
    },
    10607: {
      lat: 41.03949,
      lng: -73.81214,
      city: 'White Plains',
      state: 'NY',
    },
    10701: {
      lat: 40.94702,
      lng: -73.88034,
      city: 'Yonkers',
      state: 'NY',
    },
    10703: {
      lat: 40.95765,
      lng: -73.88114,
      city: 'Yonkers',
      state: 'NY',
    },
    10704: {
      lat: 40.91911,
      lng: -73.86187,
      city: 'Yonkers',
      state: 'NY',
    },
    10705: {
      lat: 40.91929,
      lng: -73.88928,
      city: 'Yonkers',
      state: 'NY',
    },
    10706: {
      lat: 40.99047,
      lng: -73.86951,
      city: 'Hastings On Hudson',
      state: 'NY',
    },
    10707: {
      lat: 40.95961,
      lng: -73.82291,
      city: 'Tuckahoe',
      state: 'NY',
    },
    10708: {
      lat: 40.9379,
      lng: -73.83167,
      city: 'Bronxville',
      state: 'NY',
    },
    10709: {
      lat: 40.95337,
      lng: -73.80885,
      city: 'Eastchester',
      state: 'NY',
    },
    10710: {
      lat: 40.96732,
      lng: -73.84739,
      city: 'Yonkers',
      state: 'NY',
    },
    10801: {
      lat: 40.91751,
      lng: -73.78396,
      city: 'New Rochelle',
      state: 'NY',
    },
    10803: {
      lat: 40.89978,
      lng: -73.80619,
      city: 'Pelham',
      state: 'NY',
    },
    10804: {
      lat: 40.94914,
      lng: -73.78629,
      city: 'New Rochelle',
      state: 'NY',
    },
    10805: {
      lat: 40.89593,
      lng: -73.78028,
      city: 'New Rochelle',
      state: 'NY',
    },
    10901: {
      lat: 41.14554,
      lng: -74.12504,
      city: 'Suffern',
      state: 'NY',
    },
    10910: { lat: 41.2773, lng: -74.13625, city: 'Arden', state: 'NY' },
    10911: {
      lat: 41.30798,
      lng: -73.99243,
      city: 'Bear Mountain',
      state: 'NY',
    },
    10913: {
      lat: 41.06845,
      lng: -73.95574,
      city: 'Blauvelt',
      state: 'NY',
    },
    10914: {
      lat: 41.41671,
      lng: -74.20087,
      city: 'Blooming Grove',
      state: 'NY',
    },
    10915: {
      lat: 41.54022,
      lng: -74.35714,
      city: 'Bullville',
      state: 'NY',
    },
    10916: {
      lat: 41.44881,
      lng: -74.24311,
      city: 'Campbell Hall',
      state: 'NY',
    },
    10917: {
      lat: 41.31851,
      lng: -74.11094,
      city: 'Central Valley',
      state: 'NY',
    },
    10918: {
      lat: 41.34816,
      lng: -74.25828,
      city: 'Chester',
      state: 'NY',
    },
    10919: {
      lat: 41.52836,
      lng: -74.38327,
      city: 'Circleville',
      state: 'NY',
    },
    10920: {
      lat: 41.15477,
      lng: -73.9393,
      city: 'Congers',
      state: 'NY',
    },
    10921: {
      lat: 41.3292,
      lng: -74.3651,
      city: 'Florida',
      state: 'NY',
    },
    10922: {
      lat: 41.32794,
      lng: -73.99604,
      city: 'Fort Montgomery',
      state: 'NY',
    },
    10923: {
      lat: 41.20265,
      lng: -74.00031,
      city: 'Garnerville',
      state: 'NY',
    },
    10924: { lat: 41.3788, lng: -74.3512, city: 'Goshen', state: 'NY' },
    10925: {
      lat: 41.20076,
      lng: -74.29535,
      city: 'Greenwood Lake',
      state: 'NY',
    },
    10926: {
      lat: 41.25856,
      lng: -74.1307,
      city: 'Harriman',
      state: 'NY',
    },
    10927: {
      lat: 41.19263,
      lng: -73.96735,
      city: 'Haverstraw',
      state: 'NY',
    },
    10928: {
      lat: 41.3457,
      lng: -74.00265,
      city: 'Highland Falls',
      state: 'NY',
    },
    10930: {
      lat: 41.3667,
      lng: -74.11035,
      city: 'Highland Mills',
      state: 'NY',
    },
    10931: {
      lat: 41.12769,
      lng: -74.17012,
      city: 'Hillburn',
      state: 'NY',
    },
    10932: {
      lat: 41.482,
      lng: -74.46352,
      city: 'Howells',
      state: 'NY',
    },
    10933: {
      lat: 41.36527,
      lng: -74.51106,
      city: 'Johnson',
      state: 'NY',
    },
    10940: {
      lat: 41.45149,
      lng: -74.46997,
      city: 'Middletown',
      state: 'NY',
    },
    10941: {
      lat: 41.48923,
      lng: -74.34556,
      city: 'Middletown',
      state: 'NY',
    },
    10950: {
      lat: 41.31919,
      lng: -74.19825,
      city: 'Monroe',
      state: 'NY',
    },
    10952: {
      lat: 41.11385,
      lng: -74.07975,
      city: 'Monsey',
      state: 'NY',
    },
    10953: {
      lat: 41.40581,
      lng: -74.07783,
      city: 'Mountainville',
      state: 'NY',
    },
    10954: {
      lat: 41.09969,
      lng: -74.01269,
      city: 'Nanuet',
      state: 'NY',
    },
    10956: {
      lat: 41.15562,
      lng: -73.99314,
      city: 'New City',
      state: 'NY',
    },
    10958: {
      lat: 41.3764,
      lng: -74.42881,
      city: 'New Hampton',
      state: 'NY',
    },
    10960: {
      lat: 41.08892,
      lng: -73.92642,
      city: 'Nyack',
      state: 'NY',
    },
    10962: {
      lat: 41.04857,
      lng: -73.96057,
      city: 'Orangeburg',
      state: 'NY',
    },
    10963: {
      lat: 41.4669,
      lng: -74.54121,
      city: 'Otisville',
      state: 'NY',
    },
    10964: {
      lat: 41.01642,
      lng: -73.91431,
      city: 'Palisades',
      state: 'NY',
    },
    10965: {
      lat: 41.06176,
      lng: -74.01286,
      city: 'Pearl River',
      state: 'NY',
    },
    10968: {
      lat: 41.03976,
      lng: -73.91804,
      city: 'Piermont',
      state: 'NY',
    },
    10969: {
      lat: 41.292,
      lng: -74.48879,
      city: 'Pine Island',
      state: 'NY',
    },
    10970: {
      lat: 41.1852,
      lng: -74.06568,
      city: 'Pomona',
      state: 'NY',
    },
    10973: {
      lat: 41.38442,
      lng: -74.47566,
      city: 'Slate Hill',
      state: 'NY',
    },
    10974: {
      lat: 41.15826,
      lng: -74.18993,
      city: 'Sloatsburg',
      state: 'NY',
    },
    10975: {
      lat: 41.25951,
      lng: -74.17042,
      city: 'Southfields',
      state: 'NY',
    },
    10976: {
      lat: 41.02871,
      lng: -73.92594,
      city: 'Sparkill',
      state: 'NY',
    },
    10977: {
      lat: 41.11749,
      lng: -74.04825,
      city: 'Spring Valley',
      state: 'NY',
    },
    10979: {
      lat: 41.18237,
      lng: -74.31877,
      city: 'Sterling Forest',
      state: 'NY',
    },
    10980: {
      lat: 41.23157,
      lng: -74.05709,
      city: 'Stony Point',
      state: 'NY',
    },
    10983: {
      lat: 41.02747,
      lng: -73.94826,
      city: 'Tappan',
      state: 'NY',
    },
    10984: {
      lat: 41.20928,
      lng: -74.02008,
      city: 'Thiells',
      state: 'NY',
    },
    10985: {
      lat: 41.57697,
      lng: -74.35782,
      city: 'Thompson Ridge',
      state: 'NY',
    },
    10986: {
      lat: 41.27917,
      lng: -73.99445,
      city: 'Tomkins Cove',
      state: 'NY',
    },
    10987: {
      lat: 41.20737,
      lng: -74.22284,
      city: 'Tuxedo Park',
      state: 'NY',
    },
    10988: {
      lat: 41.30133,
      lng: -74.56218,
      city: 'Unionville',
      state: 'NY',
    },
    10989: {
      lat: 41.12479,
      lng: -73.93632,
      city: 'Valley Cottage',
      state: 'NY',
    },
    10990: {
      lat: 41.2651,
      lng: -74.3663,
      city: 'Warwick',
      state: 'NY',
    },
    10992: {
      lat: 41.42648,
      lng: -74.16567,
      city: 'Washingtonville',
      state: 'NY',
    },
    10993: {
      lat: 41.21011,
      lng: -73.97565,
      city: 'West Haverstraw',
      state: 'NY',
    },
    10994: {
      lat: 41.09702,
      lng: -73.97322,
      city: 'West Nyack',
      state: 'NY',
    },
    10996: {
      lat: 41.38996,
      lng: -73.97257,
      city: 'West Point',
      state: 'NY',
    },
    10998: {
      lat: 41.33118,
      lng: -74.53989,
      city: 'Westtown',
      state: 'NY',
    },
    11001: {
      lat: 40.72356,
      lng: -73.70451,
      city: 'Floral Park',
      state: 'NY',
    },
    11003: {
      lat: 40.70143,
      lng: -73.7078,
      city: 'Elmont',
      state: 'NY',
    },
    11004: {
      lat: 40.74621,
      lng: -73.71147,
      city: 'Glen Oaks',
      state: 'NY',
    },
    11005: {
      lat: 40.75661,
      lng: -73.71424,
      city: 'Floral Park',
      state: 'NY',
    },
    11010: {
      lat: 40.70066,
      lng: -73.67514,
      city: 'Franklin Square',
      state: 'NY',
    },
    11020: {
      lat: 40.77098,
      lng: -73.71293,
      city: 'Great Neck',
      state: 'NY',
    },
    11021: {
      lat: 40.78644,
      lng: -73.7289,
      city: 'Great Neck',
      state: 'NY',
    },
    11023: {
      lat: 40.7994,
      lng: -73.73282,
      city: 'Great Neck',
      state: 'NY',
    },
    11024: {
      lat: 40.81642,
      lng: -73.74205,
      city: 'Great Neck',
      state: 'NY',
    },
    11030: {
      lat: 40.79293,
      lng: -73.68868,
      city: 'Manhasset',
      state: 'NY',
    },
    11040: {
      lat: 40.74572,
      lng: -73.67979,
      city: 'New Hyde Park',
      state: 'NY',
    },
    11042: {
      lat: 40.75854,
      lng: -73.6975,
      city: 'New Hyde Park',
      state: 'NY',
    },
    11050: {
      lat: 40.83815,
      lng: -73.69113,
      city: 'Port Washington',
      state: 'NY',
    },
    11096: {
      lat: 40.62143,
      lng: -73.75292,
      city: 'Inwood',
      state: 'NY',
    },
    11101: {
      lat: 40.74679,
      lng: -73.93916,
      city: 'Long Island City',
      state: 'NY',
    },
    11102: {
      lat: 40.77161,
      lng: -73.92619,
      city: 'Astoria',
      state: 'NY',
    },
    11103: {
      lat: 40.76261,
      lng: -73.91334,
      city: 'Astoria',
      state: 'NY',
    },
    11104: {
      lat: 40.74484,
      lng: -73.92049,
      city: 'Sunnyside',
      state: 'NY',
    },
    11105: {
      lat: 40.779,
      lng: -73.90652,
      city: 'Astoria',
      state: 'NY',
    },
    11106: {
      lat: 40.76191,
      lng: -73.93169,
      city: 'Astoria',
      state: 'NY',
    },
    11109: {
      lat: 40.7463,
      lng: -73.95699,
      city: 'Long Island City',
      state: 'NY',
    },
    11201: {
      lat: 40.69477,
      lng: -73.99031,
      city: 'Brooklyn',
      state: 'NY',
    },
    11203: {
      lat: 40.64958,
      lng: -73.93431,
      city: 'Brooklyn',
      state: 'NY',
    },
    11204: {
      lat: 40.61883,
      lng: -73.98496,
      city: 'Brooklyn',
      state: 'NY',
    },
    11205: {
      lat: 40.69654,
      lng: -73.96807,
      city: 'Brooklyn',
      state: 'NY',
    },
    11206: {
      lat: 40.7019,
      lng: -73.94254,
      city: 'Brooklyn',
      state: 'NY',
    },
    11207: {
      lat: 40.67016,
      lng: -73.89383,
      city: 'Brooklyn',
      state: 'NY',
    },
    11208: {
      lat: 40.67481,
      lng: -73.87243,
      city: 'Brooklyn',
      state: 'NY',
    },
    11209: {
      lat: 40.62175,
      lng: -74.03013,
      city: 'Brooklyn',
      state: 'NY',
    },
    11210: {
      lat: 40.62801,
      lng: -73.94631,
      city: 'Brooklyn',
      state: 'NY',
    },
    11211: {
      lat: 40.71428,
      lng: -73.94602,
      city: 'Brooklyn',
      state: 'NY',
    },
    11212: {
      lat: 40.6629,
      lng: -73.91308,
      city: 'Brooklyn',
      state: 'NY',
    },
    11213: {
      lat: 40.67117,
      lng: -73.93632,
      city: 'Brooklyn',
      state: 'NY',
    },
    11214: {
      lat: 40.59928,
      lng: -73.99644,
      city: 'Brooklyn',
      state: 'NY',
    },
    11215: {
      lat: 40.66255,
      lng: -73.98687,
      city: 'Brooklyn',
      state: 'NY',
    },
    11216: {
      lat: 40.68085,
      lng: -73.94929,
      city: 'Brooklyn',
      state: 'NY',
    },
    11217: {
      lat: 40.68242,
      lng: -73.97943,
      city: 'Brooklyn',
      state: 'NY',
    },
    11218: {
      lat: 40.64315,
      lng: -73.97645,
      city: 'Brooklyn',
      state: 'NY',
    },
    11219: {
      lat: 40.63267,
      lng: -73.99672,
      city: 'Brooklyn',
      state: 'NY',
    },
    11220: {
      lat: 40.64142,
      lng: -74.01741,
      city: 'Brooklyn',
      state: 'NY',
    },
    11221: {
      lat: 40.6914,
      lng: -73.92764,
      city: 'Brooklyn',
      state: 'NY',
    },
    11222: {
      lat: 40.72862,
      lng: -73.94764,
      city: 'Brooklyn',
      state: 'NY',
    },
    11223: {
      lat: 40.59711,
      lng: -73.97344,
      city: 'Brooklyn',
      state: 'NY',
    },
    11224: {
      lat: 40.57652,
      lng: -73.9891,
      city: 'Brooklyn',
      state: 'NY',
    },
    11225: {
      lat: 40.6631,
      lng: -73.95392,
      city: 'Brooklyn',
      state: 'NY',
    },
    11226: {
      lat: 40.64636,
      lng: -73.95666,
      city: 'Brooklyn',
      state: 'NY',
    },
    11228: {
      lat: 40.61678,
      lng: -74.01337,
      city: 'Brooklyn',
      state: 'NY',
    },
    11229: {
      lat: 40.60047,
      lng: -73.94453,
      city: 'Brooklyn',
      state: 'NY',
    },
    11230: {
      lat: 40.6221,
      lng: -73.96525,
      city: 'Brooklyn',
      state: 'NY',
    },
    11231: {
      lat: 40.67603,
      lng: -74.00644,
      city: 'Brooklyn',
      state: 'NY',
    },
    11232: {
      lat: 40.65692,
      lng: -74.00727,
      city: 'Brooklyn',
      state: 'NY',
    },
    11233: {
      lat: 40.67827,
      lng: -73.91994,
      city: 'Brooklyn',
      state: 'NY',
    },
    11234: {
      lat: 40.6072,
      lng: -73.91107,
      city: 'Brooklyn',
      state: 'NY',
    },
    11235: {
      lat: 40.58411,
      lng: -73.94331,
      city: 'Brooklyn',
      state: 'NY',
    },
    11236: {
      lat: 40.63951,
      lng: -73.90098,
      city: 'Brooklyn',
      state: 'NY',
    },
    11237: {
      lat: 40.70427,
      lng: -73.92108,
      city: 'Brooklyn',
      state: 'NY',
    },
    11238: {
      lat: 40.67105,
      lng: -73.96636,
      city: 'Brooklyn',
      state: 'NY',
    },
    11239: {
      lat: 40.64733,
      lng: -73.87136,
      city: 'Brooklyn',
      state: 'NY',
    },
    11249: {
      lat: 40.71121,
      lng: -73.96318,
      city: 'Brooklyn',
      state: 'NY',
    },
    11354: {
      lat: 40.7686,
      lng: -73.82743,
      city: 'Flushing',
      state: 'NY',
    },
    11355: {
      lat: 40.75143,
      lng: -73.82125,
      city: 'Flushing',
      state: 'NY',
    },
    11356: {
      lat: 40.78492,
      lng: -73.84149,
      city: 'College Point',
      state: 'NY',
    },
    11357: {
      lat: 40.78636,
      lng: -73.81094,
      city: 'Whitestone',
      state: 'NY',
    },
    11358: {
      lat: 40.76038,
      lng: -73.79648,
      city: 'Flushing',
      state: 'NY',
    },
    11359: {
      lat: 40.79147,
      lng: -73.77662,
      city: 'Bayside',
      state: 'NY',
    },
    11360: {
      lat: 40.7805,
      lng: -73.78159,
      city: 'Bayside',
      state: 'NY',
    },
    11361: {
      lat: 40.76393,
      lng: -73.77294,
      city: 'Bayside',
      state: 'NY',
    },
    11362: {
      lat: 40.75899,
      lng: -73.73583,
      city: 'Little Neck',
      state: 'NY',
    },
    11363: {
      lat: 40.77251,
      lng: -73.74708,
      city: 'Little Neck',
      state: 'NY',
    },
    11364: {
      lat: 40.74625,
      lng: -73.75598,
      city: 'Oakland Gardens',
      state: 'NY',
    },
    11365: {
      lat: 40.73983,
      lng: -73.79499,
      city: 'Fresh Meadows',
      state: 'NY',
    },
    11366: {
      lat: 40.73112,
      lng: -73.7804,
      city: 'Fresh Meadows',
      state: 'NY',
    },
    11367: {
      lat: 40.73044,
      lng: -73.82274,
      city: 'Flushing',
      state: 'NY',
    },
    11368: {
      lat: 40.74962,
      lng: -73.85264,
      city: 'Corona',
      state: 'NY',
    },
    11369: {
      lat: 40.76345,
      lng: -73.87222,
      city: 'East Elmhurst',
      state: 'NY',
    },
    11370: {
      lat: 40.77554,
      lng: -73.88766,
      city: 'East Elmhurst',
      state: 'NY',
    },
    11371: {
      lat: 40.77445,
      lng: -73.87316,
      city: 'Flushing',
      state: 'NY',
    },
    11372: {
      lat: 40.75172,
      lng: -73.88367,
      city: 'Jackson Heights',
      state: 'NY',
    },
    11373: {
      lat: 40.73892,
      lng: -73.87854,
      city: 'Elmhurst',
      state: 'NY',
    },
    11374: {
      lat: 40.72647,
      lng: -73.86144,
      city: 'Rego Park',
      state: 'NY',
    },
    11375: {
      lat: 40.72315,
      lng: -73.84373,
      city: 'Forest Hills',
      state: 'NY',
    },
    11377: {
      lat: 40.74483,
      lng: -73.90516,
      city: 'Woodside',
      state: 'NY',
    },
    11378: {
      lat: 40.72493,
      lng: -73.91034,
      city: 'Maspeth',
      state: 'NY',
    },
    11379: {
      lat: 40.71709,
      lng: -73.88056,
      city: 'Middle Village',
      state: 'NY',
    },
    11385: {
      lat: 40.70103,
      lng: -73.89011,
      city: 'Ridgewood',
      state: 'NY',
    },
    11411: {
      lat: 40.69406,
      lng: -73.73623,
      city: 'Cambria Heights',
      state: 'NY',
    },
    11412: {
      lat: 40.69804,
      lng: -73.759,
      city: 'Saint Albans',
      state: 'NY',
    },
    11413: {
      lat: 40.67002,
      lng: -73.75116,
      city: 'Springfield Gardens',
      state: 'NY',
    },
    11414: {
      lat: 40.65818,
      lng: -73.8448,
      city: 'Howard Beach',
      state: 'NY',
    },
    11415: {
      lat: 40.70808,
      lng: -73.82801,
      city: 'Kew Gardens',
      state: 'NY',
    },
    11416: {
      lat: 40.68458,
      lng: -73.84967,
      city: 'Ozone Park',
      state: 'NY',
    },
    11417: {
      lat: 40.67641,
      lng: -73.84455,
      city: 'Ozone Park',
      state: 'NY',
    },
    11418: {
      lat: 40.70032,
      lng: -73.83603,
      city: 'Richmond Hill',
      state: 'NY',
    },
    11419: {
      lat: 40.68863,
      lng: -73.82302,
      city: 'South Richmond Hill',
      state: 'NY',
    },
    11420: {
      lat: 40.67352,
      lng: -73.8177,
      city: 'South Ozone Park',
      state: 'NY',
    },
    11421: {
      lat: 40.69403,
      lng: -73.85865,
      city: 'Woodhaven',
      state: 'NY',
    },
    11422: {
      lat: 40.66053,
      lng: -73.73658,
      city: 'Rosedale',
      state: 'NY',
    },
    11423: {
      lat: 40.71564,
      lng: -73.76826,
      city: 'Hollis',
      state: 'NY',
    },
    11424: {
      lat: 40.71397,
      lng: -73.82809,
      city: 'Jamaica',
      state: 'NY',
    },
    11426: {
      lat: 40.73641,
      lng: -73.7223,
      city: 'Bellerose',
      state: 'NY',
    },
    11427: {
      lat: 40.73094,
      lng: -73.74569,
      city: 'Queens Village',
      state: 'NY',
    },
    11428: {
      lat: 40.72099,
      lng: -73.74222,
      city: 'Queens Village',
      state: 'NY',
    },
    11429: {
      lat: 40.70958,
      lng: -73.73843,
      city: 'Queens Village',
      state: 'NY',
    },
    11430: {
      lat: 40.64703,
      lng: -73.78717,
      city: 'Jamaica',
      state: 'NY',
    },
    11432: {
      lat: 40.71491,
      lng: -73.79295,
      city: 'Jamaica',
      state: 'NY',
    },
    11433: {
      lat: 40.69805,
      lng: -73.78675,
      city: 'Jamaica',
      state: 'NY',
    },
    11434: {
      lat: 40.67683,
      lng: -73.77623,
      city: 'Jamaica',
      state: 'NY',
    },
    11435: {
      lat: 40.70111,
      lng: -73.80968,
      city: 'Jamaica',
      state: 'NY',
    },
    11436: {
      lat: 40.67587,
      lng: -73.79664,
      city: 'Jamaica',
      state: 'NY',
    },
    11439: {
      lat: 40.72195,
      lng: -73.79455,
      city: 'Jamaica',
      state: 'NY',
    },
    11451: {
      lat: 40.7012,
      lng: -73.79579,
      city: 'Jamaica',
      state: 'NY',
    },
    11501: {
      lat: 40.74629,
      lng: -73.63921,
      city: 'Mineola',
      state: 'NY',
    },
    11507: {
      lat: 40.77082,
      lng: -73.6521,
      city: 'Albertson',
      state: 'NY',
    },
    11509: {
      lat: 40.58872,
      lng: -73.7286,
      city: 'Atlantic Beach',
      state: 'NY',
    },
    11510: {
      lat: 40.65027,
      lng: -73.60809,
      city: 'Baldwin',
      state: 'NY',
    },
    11514: {
      lat: 40.74979,
      lng: -73.61298,
      city: 'Carle Place',
      state: 'NY',
    },
    11516: {
      lat: 40.6258,
      lng: -73.7263,
      city: 'Cedarhurst',
      state: 'NY',
    },
    11518: {
      lat: 40.63772,
      lng: -73.66691,
      city: 'East Rockaway',
      state: 'NY',
    },
    11520: {
      lat: 40.65007,
      lng: -73.58305,
      city: 'Freeport',
      state: 'NY',
    },
    11530: {
      lat: 40.72716,
      lng: -73.63654,
      city: 'Garden City',
      state: 'NY',
    },
    11542: {
      lat: 40.87073,
      lng: -73.62879,
      city: 'Glen Cove',
      state: 'NY',
    },
    11545: {
      lat: 40.82685,
      lng: -73.58853,
      city: 'Glen Head',
      state: 'NY',
    },
    11547: {
      lat: 40.82881,
      lng: -73.64505,
      city: 'Glenwood Landing',
      state: 'NY',
    },
    11548: {
      lat: 40.81414,
      lng: -73.6097,
      city: 'Greenvale',
      state: 'NY',
    },
    11549: {
      lat: 40.71896,
      lng: -73.59973,
      city: 'Hempstead',
      state: 'NY',
    },
    11550: {
      lat: 40.70158,
      lng: -73.62039,
      city: 'Hempstead',
      state: 'NY',
    },
    11552: {
      lat: 40.6916,
      lng: -73.65073,
      city: 'West Hempstead',
      state: 'NY',
    },
    11553: {
      lat: 40.70529,
      lng: -73.5923,
      city: 'Uniondale',
      state: 'NY',
    },
    11554: {
      lat: 40.71974,
      lng: -73.56044,
      city: 'East Meadow',
      state: 'NY',
    },
    11556: {
      lat: 40.71997,
      lng: -73.58327,
      city: 'Uniondale',
      state: 'NY',
    },
    11557: {
      lat: 40.63723,
      lng: -73.6921,
      city: 'Hewlett',
      state: 'NY',
    },
    11558: {
      lat: 40.60539,
      lng: -73.64952,
      city: 'Island Park',
      state: 'NY',
    },
    11559: {
      lat: 40.60615,
      lng: -73.71688,
      city: 'Lawrence',
      state: 'NY',
    },
    11560: {
      lat: 40.88068,
      lng: -73.59057,
      city: 'Locust Valley',
      state: 'NY',
    },
    11561: {
      lat: 40.58969,
      lng: -73.64455,
      city: 'Long Beach',
      state: 'NY',
    },
    11563: {
      lat: 40.65746,
      lng: -73.67408,
      city: 'Lynbrook',
      state: 'NY',
    },
    11565: {
      lat: 40.67505,
      lng: -73.67177,
      city: 'Malverne',
      state: 'NY',
    },
    11566: {
      lat: 40.66307,
      lng: -73.55407,
      city: 'Merrick',
      state: 'NY',
    },
    11568: {
      lat: 40.78659,
      lng: -73.59719,
      city: 'Old Westbury',
      state: 'NY',
    },
    11569: {
      lat: 40.5903,
      lng: -73.58046,
      city: 'Point Lookout',
      state: 'NY',
    },
    11570: {
      lat: 40.66504,
      lng: -73.63882,
      city: 'Rockville Centre',
      state: 'NY',
    },
    11572: {
      lat: 40.63231,
      lng: -73.63686,
      city: 'Oceanside',
      state: 'NY',
    },
    11575: {
      lat: 40.68044,
      lng: -73.58525,
      city: 'Roosevelt',
      state: 'NY',
    },
    11576: {
      lat: 40.79791,
      lng: -73.64711,
      city: 'Roslyn',
      state: 'NY',
    },
    11577: {
      lat: 40.78288,
      lng: -73.6391,
      city: 'Roslyn Heights',
      state: 'NY',
    },
    11579: {
      lat: 40.84406,
      lng: -73.64426,
      city: 'Sea Cliff',
      state: 'NY',
    },
    11580: {
      lat: 40.67491,
      lng: -73.70374,
      city: 'Valley Stream',
      state: 'NY',
    },
    11581: {
      lat: 40.65153,
      lng: -73.71653,
      city: 'Valley Stream',
      state: 'NY',
    },
    11590: {
      lat: 40.75541,
      lng: -73.57463,
      city: 'Westbury',
      state: 'NY',
    },
    11596: {
      lat: 40.75968,
      lng: -73.6422,
      city: 'Williston Park',
      state: 'NY',
    },
    11598: {
      lat: 40.63109,
      lng: -73.71188,
      city: 'Woodmere',
      state: 'NY',
    },
    11691: {
      lat: 40.60152,
      lng: -73.76173,
      city: 'Far Rockaway',
      state: 'NY',
    },
    11692: {
      lat: 40.59373,
      lng: -73.79201,
      city: 'Arverne',
      state: 'NY',
    },
    11693: {
      lat: 40.59649,
      lng: -73.81663,
      city: 'Far Rockaway',
      state: 'NY',
    },
    11694: {
      lat: 40.57532,
      lng: -73.85099,
      city: 'Rockaway Park',
      state: 'NY',
    },
    11697: {
      lat: 40.55749,
      lng: -73.91361,
      city: 'Breezy Point',
      state: 'NY',
    },
    11701: {
      lat: 40.6817,
      lng: -73.41289,
      city: 'Amityville',
      state: 'NY',
    },
    11702: {
      lat: 40.65155,
      lng: -73.33824,
      city: 'Babylon',
      state: 'NY',
    },
    11703: {
      lat: 40.73325,
      lng: -73.32533,
      city: 'North Babylon',
      state: 'NY',
    },
    11704: {
      lat: 40.71623,
      lng: -73.35908,
      city: 'West Babylon',
      state: 'NY',
    },
    11705: {
      lat: 40.74514,
      lng: -73.05461,
      city: 'Bayport',
      state: 'NY',
    },
    11706: {
      lat: 40.706,
      lng: -73.24208,
      city: 'Bay Shore',
      state: 'NY',
    },
    11709: {
      lat: 40.90665,
      lng: -73.56005,
      city: 'Bayville',
      state: 'NY',
    },
    11710: {
      lat: 40.67217,
      lng: -73.53293,
      city: 'Bellmore',
      state: 'NY',
    },
    11713: {
      lat: 40.77774,
      lng: -72.94253,
      city: 'Bellport',
      state: 'NY',
    },
    11714: {
      lat: 40.74267,
      lng: -73.48615,
      city: 'Bethpage',
      state: 'NY',
    },
    11715: {
      lat: 40.75005,
      lng: -73.03531,
      city: 'Blue Point',
      state: 'NY',
    },
    11716: {
      lat: 40.77131,
      lng: -73.12729,
      city: 'Bohemia',
      state: 'NY',
    },
    11717: {
      lat: 40.78405,
      lng: -73.25223,
      city: 'Brentwood',
      state: 'NY',
    },
    11718: {
      lat: 40.71891,
      lng: -73.26391,
      city: 'Brightwaters',
      state: 'NY',
    },
    11719: {
      lat: 40.77935,
      lng: -72.91175,
      city: 'Brookhaven',
      state: 'NY',
    },
    11720: {
      lat: 40.87025,
      lng: -73.08202,
      city: 'Centereach',
      state: 'NY',
    },
    11721: {
      lat: 40.89362,
      lng: -73.37009,
      city: 'Centerport',
      state: 'NY',
    },
    11722: {
      lat: 40.78358,
      lng: -73.19657,
      city: 'Central Islip',
      state: 'NY',
    },
    11724: {
      lat: 40.86522,
      lng: -73.45288,
      city: 'Cold Spring Harbor',
      state: 'NY',
    },
    11725: {
      lat: 40.84056,
      lng: -73.28085,
      city: 'Commack',
      state: 'NY',
    },
    11726: {
      lat: 40.67891,
      lng: -73.39564,
      city: 'Copiague',
      state: 'NY',
    },
    11727: {
      lat: 40.88019,
      lng: -73.00368,
      city: 'Coram',
      state: 'NY',
    },
    11729: {
      lat: 40.76249,
      lng: -73.32161,
      city: 'Deer Park',
      state: 'NY',
    },
    11730: {
      lat: 40.72701,
      lng: -73.18456,
      city: 'East Islip',
      state: 'NY',
    },
    11731: {
      lat: 40.86262,
      lng: -73.31703,
      city: 'East Northport',
      state: 'NY',
    },
    11732: {
      lat: 40.84245,
      lng: -73.53916,
      city: 'East Norwich',
      state: 'NY',
    },
    11733: {
      lat: 40.93742,
      lng: -73.10544,
      city: 'East Setauket',
      state: 'NY',
    },
    11735: {
      lat: 40.73177,
      lng: -73.43284,
      city: 'Farmingdale',
      state: 'NY',
    },
    11738: {
      lat: 40.83779,
      lng: -73.03761,
      city: 'Farmingville',
      state: 'NY',
    },
    11739: {
      lat: 40.71058,
      lng: -73.16275,
      city: 'Great River',
      state: 'NY',
    },
    11740: {
      lat: 40.86536,
      lng: -73.36118,
      city: 'Greenlawn',
      state: 'NY',
    },
    11741: {
      lat: 40.79473,
      lng: -73.07043,
      city: 'Holbrook',
      state: 'NY',
    },
    11742: {
      lat: 40.81034,
      lng: -73.0419,
      city: 'Holtsville',
      state: 'NY',
    },
    11743: {
      lat: 40.88018,
      lng: -73.4266,
      city: 'Huntington',
      state: 'NY',
    },
    11746: {
      lat: 40.81421,
      lng: -73.3608,
      city: 'Huntington Station',
      state: 'NY',
    },
    11747: {
      lat: 40.78372,
      lng: -73.40931,
      city: 'Melville',
      state: 'NY',
    },
    11749: {
      lat: 40.80674,
      lng: -73.1708,
      city: 'Islandia',
      state: 'NY',
    },
    11751: {
      lat: 40.72999,
      lng: -73.21424,
      city: 'Islip',
      state: 'NY',
    },
    11752: {
      lat: 40.75791,
      lng: -73.17359,
      city: 'Islip Terrace',
      state: 'NY',
    },
    11753: {
      lat: 40.78976,
      lng: -73.5411,
      city: 'Jericho',
      state: 'NY',
    },
    11754: {
      lat: 40.88771,
      lng: -73.24738,
      city: 'Kings Park',
      state: 'NY',
    },
    11755: {
      lat: 40.8583,
      lng: -73.11748,
      city: 'Lake Grove',
      state: 'NY',
    },
    11756: {
      lat: 40.72545,
      lng: -73.51656,
      city: 'Levittown',
      state: 'NY',
    },
    11757: {
      lat: 40.68892,
      lng: -73.37356,
      city: 'Lindenhurst',
      state: 'NY',
    },
    11758: {
      lat: 40.65361,
      lng: -73.45626,
      city: 'Massapequa',
      state: 'NY',
    },
    11762: {
      lat: 40.68283,
      lng: -73.44707,
      city: 'Massapequa Park',
      state: 'NY',
    },
    11763: {
      lat: 40.82529,
      lng: -72.98245,
      city: 'Medford',
      state: 'NY',
    },
    11764: {
      lat: 40.93585,
      lng: -72.97544,
      city: 'Miller Place',
      state: 'NY',
    },
    11765: {
      lat: 40.88249,
      lng: -73.55862,
      city: 'Mill Neck',
      state: 'NY',
    },
    11766: {
      lat: 40.93602,
      lng: -73.01645,
      city: 'Mount Sinai',
      state: 'NY',
    },
    11767: {
      lat: 40.84438,
      lng: -73.14535,
      city: 'Nesconset',
      state: 'NY',
    },
    11768: {
      lat: 40.91052,
      lng: -73.32611,
      city: 'Northport',
      state: 'NY',
    },
    11769: {
      lat: 40.73703,
      lng: -73.12929,
      city: 'Oakdale',
      state: 'NY',
    },
    11770: {
      lat: 40.64945,
      lng: -73.14123,
      city: 'Ocean Beach',
      state: 'NY',
    },
    11771: {
      lat: 40.8674,
      lng: -73.52651,
      city: 'Oyster Bay',
      state: 'NY',
    },
    11772: {
      lat: 40.76118,
      lng: -72.9875,
      city: 'Patchogue',
      state: 'NY',
    },
    11776: {
      lat: 40.91345,
      lng: -73.04567,
      city: 'Port Jefferson Station',
      state: 'NY',
    },
    11777: {
      lat: 40.95171,
      lng: -73.0645,
      city: 'Port Jefferson',
      state: 'NY',
    },
    11778: {
      lat: 40.94312,
      lng: -72.93073,
      city: 'Rocky Point',
      state: 'NY',
    },
    11779: {
      lat: 40.8127,
      lng: -73.1156,
      city: 'Ronkonkoma',
      state: 'NY',
    },
    11780: {
      lat: 40.89249,
      lng: -73.16973,
      city: 'Saint James',
      state: 'NY',
    },
    11782: {
      lat: 40.73727,
      lng: -73.08024,
      city: 'Sayville',
      state: 'NY',
    },
    11783: {
      lat: 40.67766,
      lng: -73.49068,
      city: 'Seaford',
      state: 'NY',
    },
    11784: {
      lat: 40.86855,
      lng: -73.0422,
      city: 'Selden',
      state: 'NY',
    },
    11786: {
      lat: 40.94711,
      lng: -72.88939,
      city: 'Shoreham',
      state: 'NY',
    },
    11787: {
      lat: 40.85338,
      lng: -73.21089,
      city: 'Smithtown',
      state: 'NY',
    },
    11788: {
      lat: 40.81808,
      lng: -73.21318,
      city: 'Hauppauge',
      state: 'NY',
    },
    11789: {
      lat: 40.95759,
      lng: -72.97214,
      city: 'Sound Beach',
      state: 'NY',
    },
    11790: {
      lat: 40.90719,
      lng: -73.12777,
      city: 'Stony Brook',
      state: 'NY',
    },
    11791: {
      lat: 40.82819,
      lng: -73.50282,
      city: 'Syosset',
      state: 'NY',
    },
    11792: {
      lat: 40.94832,
      lng: -72.8302,
      city: 'Wading River',
      state: 'NY',
    },
    11793: {
      lat: 40.6462,
      lng: -73.51024,
      city: 'Wantagh',
      state: 'NY',
    },
    11794: {
      lat: 40.91915,
      lng: -73.12475,
      city: 'Stony Brook',
      state: 'NY',
    },
    11795: {
      lat: 40.70803,
      lng: -73.29827,
      city: 'West Islip',
      state: 'NY',
    },
    11796: {
      lat: 40.72958,
      lng: -73.09986,
      city: 'West Sayville',
      state: 'NY',
    },
    11797: {
      lat: 40.81739,
      lng: -73.47004,
      city: 'Woodbury',
      state: 'NY',
    },
    11798: {
      lat: 40.752,
      lng: -73.37497,
      city: 'Wyandanch',
      state: 'NY',
    },
    11801: {
      lat: 40.76256,
      lng: -73.52383,
      city: 'Hicksville',
      state: 'NY',
    },
    11803: {
      lat: 40.78177,
      lng: -73.4733,
      city: 'Plainview',
      state: 'NY',
    },
    11804: {
      lat: 40.75829,
      lng: -73.45706,
      city: 'Old Bethpage',
      state: 'NY',
    },
    11901: {
      lat: 40.92424,
      lng: -72.64697,
      city: 'Riverhead',
      state: 'NY',
    },
    11930: {
      lat: 40.98877,
      lng: -72.10482,
      city: 'Amagansett',
      state: 'NY',
    },
    11931: {
      lat: 40.93347,
      lng: -72.62195,
      city: 'Aquebogue',
      state: 'NY',
    },
    11932: {
      lat: 40.93322,
      lng: -72.30603,
      city: 'Bridgehampton',
      state: 'NY',
    },
    11933: {
      lat: 40.92449,
      lng: -72.75453,
      city: 'Calverton',
      state: 'NY',
    },
    11934: {
      lat: 40.80201,
      lng: -72.79428,
      city: 'Center Moriches',
      state: 'NY',
    },
    11935: {
      lat: 41.01795,
      lng: -72.48933,
      city: 'Cutchogue',
      state: 'NY',
    },
    11937: {
      lat: 40.99714,
      lng: -72.20253,
      city: 'East Hampton',
      state: 'NY',
    },
    11939: {
      lat: 41.12627,
      lng: -72.34223,
      city: 'East Marion',
      state: 'NY',
    },
    11940: {
      lat: 40.80819,
      lng: -72.75664,
      city: 'East Moriches',
      state: 'NY',
    },
    11941: {
      lat: 40.83311,
      lng: -72.72315,
      city: 'Eastport',
      state: 'NY',
    },
    11942: {
      lat: 40.84901,
      lng: -72.57764,
      city: 'East Quogue',
      state: 'NY',
    },
    11944: {
      lat: 41.09888,
      lng: -72.37223,
      city: 'Greenport',
      state: 'NY',
    },
    11946: {
      lat: 40.87033,
      lng: -72.52294,
      city: 'Hampton Bays',
      state: 'NY',
    },
    11947: {
      lat: 40.94302,
      lng: -72.58189,
      city: 'Jamesport',
      state: 'NY',
    },
    11948: {
      lat: 40.9662,
      lng: -72.55797,
      city: 'Laurel',
      state: 'NY',
    },
    11949: {
      lat: 40.86496,
      lng: -72.80097,
      city: 'Manorville',
      state: 'NY',
    },
    11950: {
      lat: 40.80627,
      lng: -72.84734,
      city: 'Mastic',
      state: 'NY',
    },
    11951: {
      lat: 40.76624,
      lng: -72.83655,
      city: 'Mastic Beach',
      state: 'NY',
    },
    11952: {
      lat: 40.99716,
      lng: -72.54369,
      city: 'Mattituck',
      state: 'NY',
    },
    11953: {
      lat: 40.88585,
      lng: -72.95054,
      city: 'Middle Island',
      state: 'NY',
    },
    11954: {
      lat: 41.04458,
      lng: -71.9518,
      city: 'Montauk',
      state: 'NY',
    },
    11955: {
      lat: 40.80777,
      lng: -72.82289,
      city: 'Moriches',
      state: 'NY',
    },
    11956: {
      lat: 40.98067,
      lng: -72.46868,
      city: 'New Suffolk',
      state: 'NY',
    },
    11957: {
      lat: 41.1492,
      lng: -72.26181,
      city: 'Orient',
      state: 'NY',
    },
    11958: {
      lat: 41.03688,
      lng: -72.4613,
      city: 'Peconic',
      state: 'NY',
    },
    11959: {
      lat: 40.82903,
      lng: -72.60977,
      city: 'Quogue',
      state: 'NY',
    },
    11960: {
      lat: 40.80894,
      lng: -72.70615,
      city: 'Remsenburg',
      state: 'NY',
    },
    11961: {
      lat: 40.90458,
      lng: -72.88488,
      city: 'Ridge',
      state: 'NY',
    },
    11962: {
      lat: 40.93715,
      lng: -72.27379,
      city: 'Sagaponack',
      state: 'NY',
    },
    11963: {
      lat: 40.99921,
      lng: -72.32271,
      city: 'Sag Harbor',
      state: 'NY',
    },
    11964: {
      lat: 41.05743,
      lng: -72.31492,
      city: 'Shelter Island',
      state: 'NY',
    },
    11965: {
      lat: 41.07727,
      lng: -72.34244,
      city: 'Shelter Island Heights',
      state: 'NY',
    },
    11967: {
      lat: 40.80092,
      lng: -72.87611,
      city: 'Shirley',
      state: 'NY',
    },
    11968: {
      lat: 40.90035,
      lng: -72.42081,
      city: 'Southampton',
      state: 'NY',
    },
    11970: {
      lat: 40.94029,
      lng: -72.57403,
      city: 'South Jamesport',
      state: 'NY',
    },
    11971: {
      lat: 41.05949,
      lng: -72.42617,
      city: 'Southold',
      state: 'NY',
    },
    11972: {
      lat: 40.82362,
      lng: -72.70045,
      city: 'Speonk',
      state: 'NY',
    },
    11973: {
      lat: 40.86583,
      lng: -72.88718,
      city: 'Upton',
      state: 'NY',
    },
    11975: {
      lat: 40.95146,
      lng: -72.25093,
      city: 'Wainscott',
      state: 'NY',
    },
    11976: {
      lat: 40.92986,
      lng: -72.34721,
      city: 'Water Mill',
      state: 'NY',
    },
    11977: {
      lat: 40.82769,
      lng: -72.67947,
      city: 'Westhampton',
      state: 'NY',
    },
    11978: {
      lat: 40.82878,
      lng: -72.64669,
      city: 'Westhampton Beach',
      state: 'NY',
    },
    11980: {
      lat: 40.8321,
      lng: -72.92198,
      city: 'Yaphank',
      state: 'NY',
    },
    12007: {
      lat: 42.48889,
      lng: -73.93159,
      city: 'Alcove',
      state: 'NY',
    },
    12008: {
      lat: 42.85367,
      lng: -73.9063,
      city: 'Alplaus',
      state: 'NY',
    },
    12009: {
      lat: 42.69824,
      lng: -74.03398,
      city: 'Altamont',
      state: 'NY',
    },
    12010: {
      lat: 42.93752,
      lng: -74.17287,
      city: 'Amsterdam',
      state: 'NY',
    },
    12015: {
      lat: 42.28315,
      lng: -73.82543,
      city: 'Athens',
      state: 'NY',
    },
    12017: {
      lat: 42.31453,
      lng: -73.45682,
      city: 'Austerlitz',
      state: 'NY',
    },
    12018: {
      lat: 42.62926,
      lng: -73.52662,
      city: 'Averill Park',
      state: 'NY',
    },
    12019: {
      lat: 42.92993,
      lng: -73.88245,
      city: 'Ballston Lake',
      state: 'NY',
    },
    12020: {
      lat: 43.00208,
      lng: -73.87401,
      city: 'Ballston Spa',
      state: 'NY',
    },
    12022: {
      lat: 42.66653,
      lng: -73.33401,
      city: 'Berlin',
      state: 'NY',
    },
    12023: {
      lat: 42.60656,
      lng: -74.16573,
      city: 'Berne',
      state: 'NY',
    },
    12024: {
      lat: 42.48134,
      lng: -73.53419,
      city: 'Brainard',
      state: 'NY',
    },
    12025: {
      lat: 43.09142,
      lng: -74.11924,
      city: 'Broadalbin',
      state: 'NY',
    },
    12027: {
      lat: 42.92122,
      lng: -73.90872,
      city: 'Burnt Hills',
      state: 'NY',
    },
    12028: {
      lat: 42.94545,
      lng: -73.44213,
      city: 'Buskirk',
      state: 'NY',
    },
    12029: {
      lat: 42.40397,
      lng: -73.42466,
      city: 'Canaan',
      state: 'NY',
    },
    12031: {
      lat: 42.76579,
      lng: -74.46617,
      city: 'Carlisle',
      state: 'NY',
    },
    12032: {
      lat: 43.24042,
      lng: -74.55666,
      city: 'Caroga Lake',
      state: 'NY',
    },
    12033: {
      lat: 42.53665,
      lng: -73.70558,
      city: 'Castleton On Hudson',
      state: 'NY',
    },
    12035: {
      lat: 42.73453,
      lng: -74.37534,
      city: 'Central Bridge',
      state: 'NY',
    },
    12036: {
      lat: 42.53564,
      lng: -74.68468,
      city: 'Charlotteville',
      state: 'NY',
    },
    12037: {
      lat: 42.33506,
      lng: -73.55714,
      city: 'Chatham',
      state: 'NY',
    },
    12040: {
      lat: 42.63047,
      lng: -73.35916,
      city: 'Cherry Plain',
      state: 'NY',
    },
    12041: {
      lat: 42.55893,
      lng: -73.97028,
      city: 'Clarksville',
      state: 'NY',
    },
    12042: {
      lat: 42.38185,
      lng: -73.89162,
      city: 'Climax',
      state: 'NY',
    },
    12043: {
      lat: 42.70357,
      lng: -74.5267,
      city: 'Cobleskill',
      state: 'NY',
    },
    12045: {
      lat: 42.47974,
      lng: -73.79682,
      city: 'Coeymans',
      state: 'NY',
    },
    12046: {
      lat: 42.48864,
      lng: -73.92119,
      city: 'Coeymans Hollow',
      state: 'NY',
    },
    12047: {
      lat: 42.78396,
      lng: -73.72655,
      city: 'Cohoes',
      state: 'NY',
    },
    12051: {
      lat: 42.34493,
      lng: -73.83938,
      city: 'Coxsackie',
      state: 'NY',
    },
    12052: {
      lat: 42.75767,
      lng: -73.47847,
      city: 'Cropseyville',
      state: 'NY',
    },
    12053: {
      lat: 42.75283,
      lng: -74.18791,
      city: 'Delanson',
      state: 'NY',
    },
    12054: {
      lat: 42.60764,
      lng: -73.86864,
      city: 'Delmar',
      state: 'NY',
    },
    12056: {
      lat: 42.76761,
      lng: -74.0905,
      city: 'Duanesburg',
      state: 'NY',
    },
    12057: {
      lat: 42.97316,
      lng: -73.34335,
      city: 'Eagle Bridge',
      state: 'NY',
    },
    12058: { lat: 42.3498, lng: -73.923, city: 'Earlton', state: 'NY' },
    12059: {
      lat: 42.61403,
      lng: -74.05811,
      city: 'East Berne',
      state: 'NY',
    },
    12060: {
      lat: 42.41325,
      lng: -73.49284,
      city: 'East Chatham',
      state: 'NY',
    },
    12061: {
      lat: 42.59915,
      lng: -73.65658,
      city: 'East Greenbush',
      state: 'NY',
    },
    12062: {
      lat: 42.52871,
      lng: -73.501,
      city: 'East Nassau',
      state: 'NY',
    },
    12063: {
      lat: 42.56442,
      lng: -73.63826,
      city: 'East Schodack',
      state: 'NY',
    },
    12064: {
      lat: 42.60711,
      lng: -74.65887,
      city: 'East Worcester',
      state: 'NY',
    },
    12065: {
      lat: 42.85402,
      lng: -73.78654,
      city: 'Clifton Park',
      state: 'NY',
    },
    12066: {
      lat: 42.78011,
      lng: -74.30291,
      city: 'Esperance',
      state: 'NY',
    },
    12067: {
      lat: 42.56083,
      lng: -73.91663,
      city: 'Feura Bush',
      state: 'NY',
    },
    12068: {
      lat: 42.95701,
      lng: -74.40467,
      city: 'Fonda',
      state: 'NY',
    },
    12069: {
      lat: 42.94336,
      lng: -74.28193,
      city: 'Fort Hunter',
      state: 'NY',
    },
    12070: {
      lat: 42.98513,
      lng: -74.25441,
      city: 'Fort Johnson',
      state: 'NY',
    },
    12071: {
      lat: 42.56971,
      lng: -74.41521,
      city: 'Fultonham',
      state: 'NY',
    },
    12072: {
      lat: 42.88102,
      lng: -74.36164,
      city: 'Fultonville',
      state: 'NY',
    },
    12074: {
      lat: 43.05219,
      lng: -74.04709,
      city: 'Galway',
      state: 'NY',
    },
    12075: {
      lat: 42.30145,
      lng: -73.64046,
      city: 'Ghent',
      state: 'NY',
    },
    12076: {
      lat: 42.40868,
      lng: -74.38506,
      city: 'Gilboa',
      state: 'NY',
    },
    12077: {
      lat: 42.59426,
      lng: -73.7841,
      city: 'Glenmont',
      state: 'NY',
    },
    12078: {
      lat: 43.12338,
      lng: -74.36255,
      city: 'Gloversville',
      state: 'NY',
    },
    12082: {
      lat: 42.76998,
      lng: -73.44672,
      city: 'Grafton',
      state: 'NY',
    },
    12083: {
      lat: 42.43151,
      lng: -74.03155,
      city: 'Greenville',
      state: 'NY',
    },
    12084: {
      lat: 42.70411,
      lng: -73.90182,
      city: 'Guilderland',
      state: 'NY',
    },
    12085: {
      lat: 42.70043,
      lng: -73.96659,
      city: 'Guilderland Center',
      state: 'NY',
    },
    12086: {
      lat: 42.99684,
      lng: -74.10508,
      city: 'Hagaman',
      state: 'NY',
    },
    12087: {
      lat: 42.42794,
      lng: -73.8931,
      city: 'Hannacroix',
      state: 'NY',
    },
    12090: {
      lat: 42.88073,
      lng: -73.35624,
      city: 'Hoosick Falls',
      state: 'NY',
    },
    12092: {
      lat: 42.70396,
      lng: -74.38456,
      city: 'Howes Cave',
      state: 'NY',
    },
    12093: {
      lat: 42.49599,
      lng: -74.6262,
      city: 'Jefferson',
      state: 'NY',
    },
    12094: {
      lat: 42.89091,
      lng: -73.48737,
      city: 'Johnsonville',
      state: 'NY',
    },
    12095: {
      lat: 43.03151,
      lng: -74.41553,
      city: 'Johnstown',
      state: 'NY',
    },
    12106: {
      lat: 42.38871,
      lng: -73.71228,
      city: 'Kinderhook',
      state: 'NY',
    },
    12108: {
      lat: 43.47045,
      lng: -74.42768,
      city: 'Lake Pleasant',
      state: 'NY',
    },
    12110: {
      lat: 42.75349,
      lng: -73.77671,
      city: 'Latham',
      state: 'NY',
    },
    12115: {
      lat: 42.46747,
      lng: -73.59338,
      city: 'Malden Bridge',
      state: 'NY',
    },
    12116: {
      lat: 42.53849,
      lng: -74.91278,
      city: 'Maryland',
      state: 'NY',
    },
    12117: {
      lat: 43.16894,
      lng: -74.25557,
      city: 'Mayfield',
      state: 'NY',
    },
    12118: {
      lat: 42.92096,
      lng: -73.72049,
      city: 'Mechanicville',
      state: 'NY',
    },
    12120: {
      lat: 42.45781,
      lng: -74.1409,
      city: 'Medusa',
      state: 'NY',
    },
    12121: {
      lat: 42.84368,
      lng: -73.60398,
      city: 'Melrose',
      state: 'NY',
    },
    12122: {
      lat: 42.54341,
      lng: -74.31138,
      city: 'Middleburgh',
      state: 'NY',
    },
    12123: {
      lat: 42.52239,
      lng: -73.60636,
      city: 'Nassau',
      state: 'NY',
    },
    12124: {
      lat: 42.44652,
      lng: -73.78494,
      city: 'New Baltimore',
      state: 'NY',
    },
    12125: {
      lat: 42.47633,
      lng: -73.40394,
      city: 'New Lebanon',
      state: 'NY',
    },
    12130: {
      lat: 42.44156,
      lng: -73.65835,
      city: 'Niverville',
      state: 'NY',
    },
    12131: {
      lat: 42.46573,
      lng: -74.46249,
      city: 'North Blenheim',
      state: 'NY',
    },
    12132: {
      lat: 42.47102,
      lng: -73.63362,
      city: 'North Chatham',
      state: 'NY',
    },
    12134: {
      lat: 43.26533,
      lng: -74.23059,
      city: 'Northville',
      state: 'NY',
    },
    12136: {
      lat: 42.43249,
      lng: -73.56016,
      city: 'Old Chatham',
      state: 'NY',
    },
    12137: {
      lat: 42.85727,
      lng: -74.12922,
      city: 'Pattersonville',
      state: 'NY',
    },
    12138: {
      lat: 42.73735,
      lng: -73.37128,
      city: 'Petersburg',
      state: 'NY',
    },
    12139: {
      lat: 43.52096,
      lng: -74.56552,
      city: 'Piseco',
      state: 'NY',
    },
    12140: {
      lat: 42.689,
      lng: -73.56689,
      city: 'Poestenkill',
      state: 'NY',
    },
    12143: {
      lat: 42.48525,
      lng: -73.85218,
      city: 'Ravena',
      state: 'NY',
    },
    12144: {
      lat: 42.63106,
      lng: -73.71801,
      city: 'Rensselaer',
      state: 'NY',
    },
    12147: {
      lat: 42.51365,
      lng: -74.15147,
      city: 'Rensselaerville',
      state: 'NY',
    },
    12148: {
      lat: 42.83361,
      lng: -73.84652,
      city: 'Rexford',
      state: 'NY',
    },
    12149: {
      lat: 42.62011,
      lng: -74.56692,
      city: 'Richmondville',
      state: 'NY',
    },
    12150: {
      lat: 42.87613,
      lng: -74.04923,
      city: 'Rotterdam Junction',
      state: 'NY',
    },
    12151: {
      lat: 42.92553,
      lng: -73.78874,
      city: 'Round Lake',
      state: 'NY',
    },
    12153: {
      lat: 42.63606,
      lng: -73.46692,
      city: 'Sand Lake',
      state: 'NY',
    },
    12154: {
      lat: 42.93713,
      lng: -73.60124,
      city: 'Schaghticoke',
      state: 'NY',
    },
    12155: {
      lat: 42.59229,
      lng: -74.82337,
      city: 'Schenevus',
      state: 'NY',
    },
    12156: {
      lat: 42.47764,
      lng: -73.74354,
      city: 'Schodack Landing',
      state: 'NY',
    },
    12157: {
      lat: 42.66742,
      lng: -74.29892,
      city: 'Schoharie',
      state: 'NY',
    },
    12158: {
      lat: 42.53927,
      lng: -73.81903,
      city: 'Selkirk',
      state: 'NY',
    },
    12159: {
      lat: 42.64328,
      lng: -73.88503,
      city: 'Slingerlands',
      state: 'NY',
    },
    12160: {
      lat: 42.76325,
      lng: -74.36619,
      city: 'Sloansville',
      state: 'NY',
    },
    12161: {
      lat: 42.53369,
      lng: -73.84606,
      city: 'South Bethlehem',
      state: 'NY',
    },
    12164: {
      lat: 43.62883,
      lng: -74.42717,
      city: 'Speculator',
      state: 'NY',
    },
    12165: {
      lat: 42.31518,
      lng: -73.50756,
      city: 'Spencertown',
      state: 'NY',
    },
    12166: {
      lat: 42.83846,
      lng: -74.45644,
      city: 'Sprakers',
      state: 'NY',
    },
    12167: {
      lat: 42.42101,
      lng: -74.59011,
      city: 'Stamford',
      state: 'NY',
    },
    12168: {
      lat: 42.5614,
      lng: -73.3751,
      city: 'Stephentown',
      state: 'NY',
    },
    12169: {
      lat: 42.58249,
      lng: -73.43559,
      city: 'Stephentown',
      state: 'NY',
    },
    12170: {
      lat: 42.99936,
      lng: -73.66234,
      city: 'Stillwater',
      state: 'NY',
    },
    12172: {
      lat: 42.28915,
      lng: -73.73895,
      city: 'Stottville',
      state: 'NY',
    },
    12173: {
      lat: 42.39042,
      lng: -73.76196,
      city: 'Stuyvesant',
      state: 'NY',
    },
    12174: {
      lat: 42.35314,
      lng: -73.72802,
      city: 'Stuyvesant Falls',
      state: 'NY',
    },
    12175: {
      lat: 42.55489,
      lng: -74.56161,
      city: 'Summit',
      state: 'NY',
    },
    12176: {
      lat: 42.38839,
      lng: -73.96787,
      city: 'Surprise',
      state: 'NY',
    },
    12177: {
      lat: 42.95184,
      lng: -74.28459,
      city: 'Tribes Hill',
      state: 'NY',
    },
    12180: { lat: 42.7516, lng: -73.59997, city: 'Troy', state: 'NY' },
    12182: { lat: 42.80044, lng: -73.63519, city: 'Troy', state: 'NY' },
    12183: { lat: 42.74742, lng: -73.69259, city: 'Troy', state: 'NY' },
    12184: {
      lat: 42.42272,
      lng: -73.65387,
      city: 'Valatie',
      state: 'NY',
    },
    12185: {
      lat: 42.90102,
      lng: -73.53507,
      city: 'Valley Falls',
      state: 'NY',
    },
    12186: {
      lat: 42.62502,
      lng: -73.9737,
      city: 'Voorheesville',
      state: 'NY',
    },
    12187: {
      lat: 42.62632,
      lng: -74.46537,
      city: 'Warnerville',
      state: 'NY',
    },
    12188: {
      lat: 42.8199,
      lng: -73.69589,
      city: 'Waterford',
      state: 'NY',
    },
    12189: {
      lat: 42.73618,
      lng: -73.71688,
      city: 'Watervliet',
      state: 'NY',
    },
    12190: { lat: 43.47359, lng: -74.2733, city: 'Wells', state: 'NY' },
    12192: {
      lat: 42.40428,
      lng: -73.82136,
      city: 'West Coxsackie',
      state: 'NY',
    },
    12193: {
      lat: 42.51713,
      lng: -74.04327,
      city: 'Westerlo',
      state: 'NY',
    },
    12194: {
      lat: 42.53839,
      lng: -74.45431,
      city: 'West Fulton',
      state: 'NY',
    },
    12195: {
      lat: 42.47876,
      lng: -73.46505,
      city: 'West Lebanon',
      state: 'NY',
    },
    12196: {
      lat: 42.63149,
      lng: -73.61708,
      city: 'West Sand Lake',
      state: 'NY',
    },
    12197: {
      lat: 42.6094,
      lng: -74.72587,
      city: 'Worcester',
      state: 'NY',
    },
    12198: {
      lat: 42.67819,
      lng: -73.63285,
      city: 'Wynantskill',
      state: 'NY',
    },
    12202: { lat: 42.63355, lng: -73.763, city: 'Albany', state: 'NY' },
    12203: {
      lat: 42.68138,
      lng: -73.85099,
      city: 'Albany',
      state: 'NY',
    },
    12204: {
      lat: 42.68723,
      lng: -73.72869,
      city: 'Albany',
      state: 'NY',
    },
    12205: {
      lat: 42.71719,
      lng: -73.83006,
      city: 'Albany',
      state: 'NY',
    },
    12206: {
      lat: 42.67415,
      lng: -73.78196,
      city: 'Albany',
      state: 'NY',
    },
    12207: {
      lat: 42.65657,
      lng: -73.74749,
      city: 'Albany',
      state: 'NY',
    },
    12208: {
      lat: 42.65293,
      lng: -73.80835,
      city: 'Albany',
      state: 'NY',
    },
    12209: {
      lat: 42.6382,
      lng: -73.78976,
      city: 'Albany',
      state: 'NY',
    },
    12210: {
      lat: 42.66399,
      lng: -73.75985,
      city: 'Albany',
      state: 'NY',
    },
    12211: {
      lat: 42.7083,
      lng: -73.76393,
      city: 'Albany',
      state: 'NY',
    },
    12222: {
      lat: 42.68637,
      lng: -73.82656,
      city: 'Albany',
      state: 'NY',
    },
    12226: {
      lat: 42.67931,
      lng: -73.80987,
      city: 'Albany',
      state: 'NY',
    },
    12302: {
      lat: 42.88188,
      lng: -73.98323,
      city: 'Schenectady',
      state: 'NY',
    },
    12303: {
      lat: 42.74938,
      lng: -73.92562,
      city: 'Schenectady',
      state: 'NY',
    },
    12304: {
      lat: 42.77375,
      lng: -73.89742,
      city: 'Schenectady',
      state: 'NY',
    },
    12305: {
      lat: 42.81219,
      lng: -73.95036,
      city: 'Schenectady',
      state: 'NY',
    },
    12306: {
      lat: 42.80686,
      lng: -74.03344,
      city: 'Schenectady',
      state: 'NY',
    },
    12307: {
      lat: 42.80507,
      lng: -73.93341,
      city: 'Schenectady',
      state: 'NY',
    },
    12308: {
      lat: 42.82152,
      lng: -73.92006,
      city: 'Schenectady',
      state: 'NY',
    },
    12309: {
      lat: 42.79693,
      lng: -73.86785,
      city: 'Schenectady',
      state: 'NY',
    },
    12401: {
      lat: 41.93504,
      lng: -74.05446,
      city: 'Kingston',
      state: 'NY',
    },
    12404: {
      lat: 41.81602,
      lng: -74.23632,
      city: 'Accord',
      state: 'NY',
    },
    12405: { lat: 42.31585, lng: -74.0989, city: 'Acra', state: 'NY' },
    12406: {
      lat: 42.09647,
      lng: -74.56502,
      city: 'Arkville',
      state: 'NY',
    },
    12407: {
      lat: 42.30075,
      lng: -74.35343,
      city: 'Ashland',
      state: 'NY',
    },
    12409: {
      lat: 42.05662,
      lng: -74.16655,
      city: 'Bearsville',
      state: 'NY',
    },
    12410: {
      lat: 42.0706,
      lng: -74.45212,
      city: 'Big Indian',
      state: 'NY',
    },
    12411: {
      lat: 41.87975,
      lng: -74.04094,
      city: 'Bloomington',
      state: 'NY',
    },
    12412: {
      lat: 42.01218,
      lng: -74.27698,
      city: 'Boiceville',
      state: 'NY',
    },
    12413: {
      lat: 42.31338,
      lng: -74.02628,
      city: 'Cairo',
      state: 'NY',
    },
    12414: {
      lat: 42.2211,
      lng: -73.92356,
      city: 'Catskill',
      state: 'NY',
    },
    12416: {
      lat: 42.09554,
      lng: -74.28592,
      city: 'Chichester',
      state: 'NY',
    },
    12417: {
      lat: 41.9073,
      lng: -73.98943,
      city: 'Connelly',
      state: 'NY',
    },
    12418: {
      lat: 42.35863,
      lng: -74.1609,
      city: 'Cornwallville',
      state: 'NY',
    },
    12419: {
      lat: 41.85948,
      lng: -74.10265,
      city: 'Cottekill',
      state: 'NY',
    },
    12420: {
      lat: 41.6743,
      lng: -74.37769,
      city: 'Cragsmoor',
      state: 'NY',
    },
    12421: { lat: 42.2512, lng: -74.5336, city: 'Denver', state: 'NY' },
    12422: {
      lat: 42.39205,
      lng: -74.20232,
      city: 'Durham',
      state: 'NY',
    },
    12423: {
      lat: 42.37968,
      lng: -74.11136,
      city: 'East Durham',
      state: 'NY',
    },
    12424: {
      lat: 42.24689,
      lng: -74.14358,
      city: 'East Jewett',
      state: 'NY',
    },
    12427: {
      lat: 42.14771,
      lng: -74.11921,
      city: 'Elka Park',
      state: 'NY',
    },
    12428: {
      lat: 41.73817,
      lng: -74.44615,
      city: 'Ellenville',
      state: 'NY',
    },
    12429: { lat: 41.83323, lng: -73.961, city: 'Esopus', state: 'NY' },
    12430: {
      lat: 42.20306,
      lng: -74.49933,
      city: 'Fleischmanns',
      state: 'NY',
    },
    12431: {
      lat: 42.35877,
      lng: -74.01843,
      city: 'Freehold',
      state: 'NY',
    },
    12432: {
      lat: 42.04702,
      lng: -73.94403,
      city: 'Glasco',
      state: 'NY',
    },
    12433: {
      lat: 42.00533,
      lng: -74.15526,
      city: 'Glenford',
      state: 'NY',
    },
    12434: {
      lat: 42.36491,
      lng: -74.50793,
      city: 'Grand Gorge',
      state: 'NY',
    },
    12435: {
      lat: 41.72715,
      lng: -74.51148,
      city: 'Greenfield Park',
      state: 'NY',
    },
    12436: {
      lat: 42.20447,
      lng: -74.0746,
      city: 'Haines Falls',
      state: 'NY',
    },
    12438: {
      lat: 42.21251,
      lng: -74.59667,
      city: 'Halcottsville',
      state: 'NY',
    },
    12439: {
      lat: 42.29674,
      lng: -74.18789,
      city: 'Hensonville',
      state: 'NY',
    },
    12440: {
      lat: 41.78665,
      lng: -74.16338,
      city: 'High Falls',
      state: 'NY',
    },
    12441: {
      lat: 42.1502,
      lng: -74.50625,
      city: 'Highmount',
      state: 'NY',
    },
    12442: {
      lat: 42.22275,
      lng: -74.23545,
      city: 'Hunter',
      state: 'NY',
    },
    12443: {
      lat: 41.93388,
      lng: -74.08536,
      city: 'Hurley',
      state: 'NY',
    },
    12444: {
      lat: 42.24942,
      lng: -74.28993,
      city: 'Jewett',
      state: 'NY',
    },
    12446: {
      lat: 41.79063,
      lng: -74.31655,
      city: 'Kerhonkson',
      state: 'NY',
    },
    12448: {
      lat: 42.07955,
      lng: -74.20067,
      city: 'Lake Hill',
      state: 'NY',
    },
    12449: {
      lat: 41.99377,
      lng: -73.99338,
      city: 'Lake Katrine',
      state: 'NY',
    },
    12450: {
      lat: 42.13804,
      lng: -74.22176,
      city: 'Lanesville',
      state: 'NY',
    },
    12451: {
      lat: 42.30337,
      lng: -73.94802,
      city: 'Leeds',
      state: 'NY',
    },
    12452: {
      lat: 42.23891,
      lng: -74.34986,
      city: 'Lexington',
      state: 'NY',
    },
    12453: {
      lat: 42.0929,
      lng: -73.93629,
      city: 'Malden On Hudson',
      state: 'NY',
    },
    12454: {
      lat: 42.2869,
      lng: -74.15038,
      city: 'Maplecrest',
      state: 'NY',
    },
    12455: {
      lat: 42.14871,
      lng: -74.66334,
      city: 'Margaretville',
      state: 'NY',
    },
    12456: {
      lat: 42.03308,
      lng: -73.99703,
      city: 'Mount Marion',
      state: 'NY',
    },
    12457: {
      lat: 42.03997,
      lng: -74.24659,
      city: 'Mount Tremper',
      state: 'NY',
    },
    12458: {
      lat: 41.82273,
      lng: -74.41666,
      city: 'Napanoch',
      state: 'NY',
    },
    12459: {
      lat: 42.23976,
      lng: -74.6805,
      city: 'New Kingston',
      state: 'NY',
    },
    12460: {
      lat: 42.42357,
      lng: -74.16676,
      city: 'Oak Hill',
      state: 'NY',
    },
    12461: {
      lat: 41.90531,
      lng: -74.25246,
      city: 'Olivebridge',
      state: 'NY',
    },
    12463: {
      lat: 42.1895,
      lng: -74.01573,
      city: 'Palenville',
      state: 'NY',
    },
    12464: {
      lat: 42.04071,
      lng: -74.34452,
      city: 'Phoenicia',
      state: 'NY',
    },
    12465: {
      lat: 42.15278,
      lng: -74.46725,
      city: 'Pine Hill',
      state: 'NY',
    },
    12466: {
      lat: 41.90671,
      lng: -73.97826,
      city: 'Port Ewen',
      state: 'NY',
    },
    12468: {
      lat: 42.29998,
      lng: -74.41375,
      city: 'Prattsville',
      state: 'NY',
    },
    12469: {
      lat: 42.44521,
      lng: -74.2421,
      city: 'Preston Hollow',
      state: 'NY',
    },
    12470: {
      lat: 42.26932,
      lng: -74.05515,
      city: 'Purling',
      state: 'NY',
    },
    12471: {
      lat: 41.83799,
      lng: -74.04195,
      city: 'Rifton',
      state: 'NY',
    },
    12472: {
      lat: 41.84732,
      lng: -74.07447,
      city: 'Rosendale',
      state: 'NY',
    },
    12473: {
      lat: 42.26157,
      lng: -74.02827,
      city: 'Round Top',
      state: 'NY',
    },
    12474: {
      lat: 42.29378,
      lng: -74.58117,
      city: 'Roxbury',
      state: 'NY',
    },
    12475: { lat: 42.01397, lng: -74.01332, city: 'Ruby', state: 'NY' },
    12477: {
      lat: 42.08528,
      lng: -74.00028,
      city: 'Saugerties',
      state: 'NY',
    },
    12480: {
      lat: 42.13803,
      lng: -74.37683,
      city: 'Shandaken',
      state: 'NY',
    },
    12481: {
      lat: 41.97519,
      lng: -74.22718,
      city: 'Shokan',
      state: 'NY',
    },
    12482: {
      lat: 42.26841,
      lng: -73.95587,
      city: 'South Cairo',
      state: 'NY',
    },
    12483: {
      lat: 41.66796,
      lng: -74.44184,
      city: 'Spring Glen',
      state: 'NY',
    },
    12484: {
      lat: 41.87024,
      lng: -74.17338,
      city: 'Stone Ridge',
      state: 'NY',
    },
    12485: {
      lat: 42.20507,
      lng: -74.15288,
      city: 'Tannersville',
      state: 'NY',
    },
    12486: {
      lat: 41.83438,
      lng: -74.06235,
      city: 'Tillson',
      state: 'NY',
    },
    12487: {
      lat: 41.86095,
      lng: -73.99555,
      city: 'Ulster Park',
      state: 'NY',
    },
    12489: {
      lat: 41.75597,
      lng: -74.35462,
      city: 'Wawarsing',
      state: 'NY',
    },
    12490: {
      lat: 42.12355,
      lng: -73.9251,
      city: 'West Camp',
      state: 'NY',
    },
    12491: {
      lat: 41.96957,
      lng: -74.13311,
      city: 'West Hurley',
      state: 'NY',
    },
    12492: {
      lat: 42.18758,
      lng: -74.33453,
      city: 'West Kill',
      state: 'NY',
    },
    12493: {
      lat: 41.79316,
      lng: -73.97283,
      city: 'West Park',
      state: 'NY',
    },
    12494: {
      lat: 41.94857,
      lng: -74.30169,
      city: 'West Shokan',
      state: 'NY',
    },
    12495: {
      lat: 42.08264,
      lng: -74.24563,
      city: 'Willow',
      state: 'NY',
    },
    12496: {
      lat: 42.32669,
      lng: -74.27256,
      city: 'Windham',
      state: 'NY',
    },
    12498: {
      lat: 42.04772,
      lng: -74.11007,
      city: 'Woodstock',
      state: 'NY',
    },
    12501: {
      lat: 41.85664,
      lng: -73.55953,
      city: 'Amenia',
      state: 'NY',
    },
    12502: {
      lat: 42.08324,
      lng: -73.65409,
      city: 'Ancram',
      state: 'NY',
    },
    12503: {
      lat: 42.03615,
      lng: -73.58139,
      city: 'Ancramdale',
      state: 'NY',
    },
    12504: {
      lat: 42.02342,
      lng: -73.9094,
      city: 'Annandale On Hudson',
      state: 'NY',
    },
    12507: {
      lat: 42.00735,
      lng: -73.92126,
      city: 'Barrytown',
      state: 'NY',
    },
    12508: {
      lat: 41.49637,
      lng: -73.95351,
      city: 'Beacon',
      state: 'NY',
    },
    12512: {
      lat: 41.5542,
      lng: -73.96471,
      city: 'Chelsea',
      state: 'NY',
    },
    12513: {
      lat: 42.21661,
      lng: -73.71639,
      city: 'Claverack',
      state: 'NY',
    },
    12514: {
      lat: 41.87137,
      lng: -73.76274,
      city: 'Clinton Corners',
      state: 'NY',
    },
    12515: {
      lat: 41.68008,
      lng: -74.06451,
      city: 'Clintondale',
      state: 'NY',
    },
    12516: {
      lat: 42.10722,
      lng: -73.55343,
      city: 'Copake',
      state: 'NY',
    },
    12517: {
      lat: 42.13392,
      lng: -73.51103,
      city: 'Copake Falls',
      state: 'NY',
    },
    12518: {
      lat: 41.41847,
      lng: -74.03571,
      city: 'Cornwall',
      state: 'NY',
    },
    12520: {
      lat: 41.43362,
      lng: -74.00693,
      city: 'Cornwall On Hudson',
      state: 'NY',
    },
    12521: {
      lat: 42.17071,
      lng: -73.65164,
      city: 'Craryville',
      state: 'NY',
    },
    12522: {
      lat: 41.72693,
      lng: -73.58893,
      city: 'Dover Plains',
      state: 'NY',
    },
    12523: {
      lat: 42.08627,
      lng: -73.75311,
      city: 'Elizaville',
      state: 'NY',
    },
    12524: {
      lat: 41.53092,
      lng: -73.89245,
      city: 'Fishkill',
      state: 'NY',
    },
    12525: {
      lat: 41.68115,
      lng: -74.17751,
      city: 'Gardiner',
      state: 'NY',
    },
    12526: {
      lat: 42.12236,
      lng: -73.85938,
      city: 'Germantown',
      state: 'NY',
    },
    12527: {
      lat: 41.51931,
      lng: -73.93703,
      city: 'Glenham',
      state: 'NY',
    },
    12528: {
      lat: 41.72273,
      lng: -74.00477,
      city: 'Highland',
      state: 'NY',
    },
    12529: {
      lat: 42.21335,
      lng: -73.53802,
      city: 'Hillsdale',
      state: 'NY',
    },
    12530: {
      lat: 42.20692,
      lng: -73.68413,
      city: 'Hollowville',
      state: 'NY',
    },
    12531: {
      lat: 41.53689,
      lng: -73.66917,
      city: 'Holmes',
      state: 'NY',
    },
    12533: {
      lat: 41.55925,
      lng: -73.79401,
      city: 'Hopewell Junction',
      state: 'NY',
    },
    12534: {
      lat: 42.22434,
      lng: -73.75427,
      city: 'Hudson',
      state: 'NY',
    },
    12538: {
      lat: 41.79246,
      lng: -73.89336,
      city: 'Hyde Park',
      state: 'NY',
    },
    12540: {
      lat: 41.67073,
      lng: -73.72524,
      city: 'Lagrangeville',
      state: 'NY',
    },
    12542: {
      lat: 41.60772,
      lng: -73.99666,
      city: 'Marlboro',
      state: 'NY',
    },
    12543: {
      lat: 41.48602,
      lng: -74.22354,
      city: 'Maybrook',
      state: 'NY',
    },
    12544: {
      lat: 42.26129,
      lng: -73.66822,
      city: 'Mellenville',
      state: 'NY',
    },
    12545: {
      lat: 41.7914,
      lng: -73.67251,
      city: 'Millbrook',
      state: 'NY',
    },
    12546: {
      lat: 41.96627,
      lng: -73.53313,
      city: 'Millerton',
      state: 'NY',
    },
    12547: {
      lat: 41.65463,
      lng: -73.98322,
      city: 'Milton',
      state: 'NY',
    },
    12548: {
      lat: 41.66307,
      lng: -74.10157,
      city: 'Modena',
      state: 'NY',
    },
    12549: {
      lat: 41.52957,
      lng: -74.25843,
      city: 'Montgomery',
      state: 'NY',
    },
    12550: {
      lat: 41.53671,
      lng: -74.05196,
      city: 'Newburgh',
      state: 'NY',
    },
    12553: {
      lat: 41.46146,
      lng: -74.07311,
      city: 'New Windsor',
      state: 'NY',
    },
    12561: {
      lat: 41.75602,
      lng: -74.0958,
      city: 'New Paltz',
      state: 'NY',
    },
    12563: {
      lat: 41.49417,
      lng: -73.5891,
      city: 'Patterson',
      state: 'NY',
    },
    12564: {
      lat: 41.57827,
      lng: -73.5862,
      city: 'Pawling',
      state: 'NY',
    },
    12565: {
      lat: 42.24849,
      lng: -73.64584,
      city: 'Philmont',
      state: 'NY',
    },
    12566: {
      lat: 41.62223,
      lng: -74.33256,
      city: 'Pine Bush',
      state: 'NY',
    },
    12567: {
      lat: 41.98206,
      lng: -73.65211,
      city: 'Pine Plains',
      state: 'NY',
    },
    12569: {
      lat: 41.7346,
      lng: -73.79731,
      city: 'Pleasant Valley',
      state: 'NY',
    },
    12570: {
      lat: 41.62377,
      lng: -73.66983,
      city: 'Poughquag',
      state: 'NY',
    },
    12571: {
      lat: 42.00663,
      lng: -73.81152,
      city: 'Red Hook',
      state: 'NY',
    },
    12572: {
      lat: 41.92548,
      lng: -73.8717,
      city: 'Rhinebeck',
      state: 'NY',
    },
    12574: {
      lat: 41.91764,
      lng: -73.94566,
      city: 'Rhinecliff',
      state: 'NY',
    },
    12575: {
      lat: 41.48514,
      lng: -74.15714,
      city: 'Rock Tavern',
      state: 'NY',
    },
    12577: {
      lat: 41.42048,
      lng: -74.11678,
      city: 'Salisbury Mills',
      state: 'NY',
    },
    12578: {
      lat: 41.80629,
      lng: -73.79673,
      city: 'Salt Point',
      state: 'NY',
    },
    12580: {
      lat: 41.85348,
      lng: -73.87302,
      city: 'Staatsburg',
      state: 'NY',
    },
    12581: {
      lat: 41.90625,
      lng: -73.69374,
      city: 'Stanfordville',
      state: 'NY',
    },
    12582: {
      lat: 41.54693,
      lng: -73.72919,
      city: 'Stormville',
      state: 'NY',
    },
    12583: {
      lat: 42.05625,
      lng: -73.88014,
      city: 'Tivoli',
      state: 'NY',
    },
    12585: {
      lat: 41.72396,
      lng: -73.7025,
      city: 'Verbank',
      state: 'NY',
    },
    12586: {
      lat: 41.5657,
      lng: -74.17289,
      city: 'Walden',
      state: 'NY',
    },
    12589: {
      lat: 41.6278,
      lng: -74.15875,
      city: 'Wallkill',
      state: 'NY',
    },
    12590: {
      lat: 41.59559,
      lng: -73.88774,
      city: 'Wappingers Falls',
      state: 'NY',
    },
    12592: {
      lat: 41.78601,
      lng: -73.5528,
      city: 'Wassaic',
      state: 'NY',
    },
    12594: {
      lat: 41.66049,
      lng: -73.56004,
      city: 'Wingdale',
      state: 'NY',
    },
    12601: {
      lat: 41.70602,
      lng: -73.91119,
      city: 'Poughkeepsie',
      state: 'NY',
    },
    12603: {
      lat: 41.67816,
      lng: -73.86744,
      city: 'Poughkeepsie',
      state: 'NY',
    },
    12604: {
      lat: 41.68844,
      lng: -73.89448,
      city: 'Poughkeepsie',
      state: 'NY',
    },
    12701: {
      lat: 41.65125,
      lng: -74.6982,
      city: 'Monticello',
      state: 'NY',
    },
    12719: {
      lat: 41.48431,
      lng: -74.90543,
      city: 'Barryville',
      state: 'NY',
    },
    12720: {
      lat: 41.65794,
      lng: -74.90029,
      city: 'Bethel',
      state: 'NY',
    },
    12721: {
      lat: 41.56741,
      lng: -74.42832,
      city: 'Bloomingburg',
      state: 'NY',
    },
    12722: {
      lat: 41.59042,
      lng: -74.3748,
      city: 'Burlingham',
      state: 'NY',
    },
    12723: {
      lat: 41.77809,
      lng: -75.02751,
      city: 'Callicoon',
      state: 'NY',
    },
    12724: {
      lat: 41.85195,
      lng: -74.93535,
      city: 'Callicoon Center',
      state: 'NY',
    },
    12725: {
      lat: 41.95679,
      lng: -74.54794,
      city: 'Claryville',
      state: 'NY',
    },
    12726: {
      lat: 41.69269,
      lng: -74.96902,
      city: 'Cochecton',
      state: 'NY',
    },
    12729: {
      lat: 41.49411,
      lng: -74.61582,
      city: 'Cuddebackville',
      state: 'NY',
    },
    12732: {
      lat: 41.56075,
      lng: -74.87353,
      city: 'Eldred',
      state: 'NY',
    },
    12733: {
      lat: 41.73378,
      lng: -74.6102,
      city: 'Fallsburg',
      state: 'NY',
    },
    12734: {
      lat: 41.7354,
      lng: -74.7531,
      city: 'Ferndale',
      state: 'NY',
    },
    12736: {
      lat: 41.85327,
      lng: -75.01917,
      city: 'Fremont Center',
      state: 'NY',
    },
    12737: {
      lat: 41.50529,
      lng: -74.79941,
      city: 'Glen Spey',
      state: 'NY',
    },
    12738: {
      lat: 41.66009,
      lng: -74.59119,
      city: 'Glen Wild',
      state: 'NY',
    },
    12740: {
      lat: 41.89499,
      lng: -74.46616,
      city: 'Grahamsville',
      state: 'NY',
    },
    12741: {
      lat: 41.84368,
      lng: -75.07816,
      city: 'Hankins',
      state: 'NY',
    },
    12742: {
      lat: 41.71796,
      lng: -74.72108,
      city: 'Harris',
      state: 'NY',
    },
    12743: {
      lat: 41.54065,
      lng: -74.84687,
      city: 'Highland Lake',
      state: 'NY',
    },
    12745: {
      lat: 41.78503,
      lng: -75.02633,
      city: 'Hortonville',
      state: 'NY',
    },
    12746: {
      lat: 41.44929,
      lng: -74.65582,
      city: 'Huguenot',
      state: 'NY',
    },
    12747: {
      lat: 41.76596,
      lng: -74.65934,
      city: 'Hurleyville',
      state: 'NY',
    },
    12748: {
      lat: 41.77604,
      lng: -74.91845,
      city: 'Jeffersonville',
      state: 'NY',
    },
    12749: {
      lat: 41.69149,
      lng: -74.82768,
      city: 'Kauneonga Lake',
      state: 'NY',
    },
    12750: {
      lat: 41.73595,
      lng: -74.96382,
      city: 'Kenoza Lake',
      state: 'NY',
    },
    12751: {
      lat: 41.69209,
      lng: -74.66119,
      city: 'Kiamesha Lake',
      state: 'NY',
    },
    12752: {
      lat: 41.68278,
      lng: -74.9905,
      city: 'Lake Huntington',
      state: 'NY',
    },
    12754: {
      lat: 41.80004,
      lng: -74.73643,
      city: 'Liberty',
      state: 'NY',
    },
    12758: {
      lat: 41.9428,
      lng: -74.74942,
      city: 'Livingston Manor',
      state: 'NY',
    },
    12759: {
      lat: 41.78399,
      lng: -74.65758,
      city: 'Loch Sheldrake',
      state: 'NY',
    },
    12760: {
      lat: 41.90063,
      lng: -75.10559,
      city: 'Long Eddy',
      state: 'NY',
    },
    12762: {
      lat: 41.65582,
      lng: -74.79462,
      city: 'Mongaup Valley',
      state: 'NY',
    },
    12763: {
      lat: 41.68221,
      lng: -74.52205,
      city: 'Mountain Dale',
      state: 'NY',
    },
    12764: {
      lat: 41.59639,
      lng: -74.98515,
      city: 'Narrowsburg',
      state: 'NY',
    },
    12765: {
      lat: 41.85265,
      lng: -74.6281,
      city: 'Neversink',
      state: 'NY',
    },
    12766: {
      lat: 41.81408,
      lng: -74.97923,
      city: 'North Branch',
      state: 'NY',
    },
    12767: {
      lat: 41.83504,
      lng: -75.00284,
      city: 'Obernburg',
      state: 'NY',
    },
    12768: {
      lat: 41.87157,
      lng: -74.71507,
      city: 'Parksville',
      state: 'NY',
    },
    12769: {
      lat: 41.65925,
      lng: -74.47071,
      city: 'Phillipsport',
      state: 'NY',
    },
    12770: {
      lat: 41.44681,
      lng: -74.85028,
      city: 'Pond Eddy',
      state: 'NY',
    },
    12771: {
      lat: 41.37394,
      lng: -74.62429,
      city: 'Port Jervis',
      state: 'NY',
    },
    12775: {
      lat: 41.62266,
      lng: -74.58232,
      city: 'Rock Hill',
      state: 'NY',
    },
    12776: {
      lat: 41.96486,
      lng: -74.93197,
      city: 'Roscoe',
      state: 'NY',
    },
    12777: {
      lat: 41.55172,
      lng: -74.71067,
      city: 'Forestburgh',
      state: 'NY',
    },
    12778: {
      lat: 41.66174,
      lng: -74.8138,
      city: 'Smallwood',
      state: 'NY',
    },
    12779: {
      lat: 41.69804,
      lng: -74.62703,
      city: 'South Fallsburg',
      state: 'NY',
    },
    12780: {
      lat: 41.44514,
      lng: -74.71989,
      city: 'Sparrow Bush',
      state: 'NY',
    },
    12781: {
      lat: 41.61448,
      lng: -74.47208,
      city: 'Summitville',
      state: 'NY',
    },
    12783: {
      lat: 41.74026,
      lng: -74.83502,
      city: 'Swan Lake',
      state: 'NY',
    },
    12784: {
      lat: 41.66907,
      lng: -74.61485,
      city: 'Thompsonville',
      state: 'NY',
    },
    12785: {
      lat: 41.52429,
      lng: -74.58051,
      city: 'Westbrookville',
      state: 'NY',
    },
    12786: {
      lat: 41.64306,
      lng: -74.85393,
      city: 'White Lake',
      state: 'NY',
    },
    12787: {
      lat: 41.7931,
      lng: -74.8365,
      city: 'White Sulphur Springs',
      state: 'NY',
    },
    12788: {
      lat: 41.79552,
      lng: -74.58846,
      city: 'Woodbourne',
      state: 'NY',
    },
    12789: {
      lat: 41.71374,
      lng: -74.57233,
      city: 'Woodridge',
      state: 'NY',
    },
    12790: {
      lat: 41.5863,
      lng: -74.51705,
      city: 'Wurtsboro',
      state: 'NY',
    },
    12791: {
      lat: 41.81126,
      lng: -74.88692,
      city: 'Youngsville',
      state: 'NY',
    },
    12792: {
      lat: 41.51447,
      lng: -74.95172,
      city: 'Yulan',
      state: 'NY',
    },
    12801: {
      lat: 43.31097,
      lng: -73.64545,
      city: 'Glens Falls',
      state: 'NY',
    },
    12803: {
      lat: 43.28891,
      lng: -73.63024,
      city: 'South Glens Falls',
      state: 'NY',
    },
    12804: {
      lat: 43.3375,
      lng: -73.68307,
      city: 'Queensbury',
      state: 'NY',
    },
    12808: {
      lat: 43.74782,
      lng: -73.75393,
      city: 'Adirondack',
      state: 'NY',
    },
    12809: {
      lat: 43.24097,
      lng: -73.4624,
      city: 'Argyle',
      state: 'NY',
    },
    12810: {
      lat: 43.49862,
      lng: -73.95217,
      city: 'Athol',
      state: 'NY',
    },
    12811: {
      lat: 43.57502,
      lng: -74.07508,
      city: 'Bakers Mills',
      state: 'NY',
    },
    12812: {
      lat: 43.85731,
      lng: -74.41213,
      city: 'Blue Mountain Lake',
      state: 'NY',
    },
    12814: {
      lat: 43.6004,
      lng: -73.63678,
      city: 'Bolton Landing',
      state: 'NY',
    },
    12815: {
      lat: 43.70161,
      lng: -73.69459,
      city: 'Brant Lake',
      state: 'NY',
    },
    12816: {
      lat: 43.05085,
      lng: -73.37829,
      city: 'Cambridge',
      state: 'NY',
    },
    12817: {
      lat: 43.63697,
      lng: -73.82614,
      city: 'Chestertown',
      state: 'NY',
    },
    12819: {
      lat: 43.61144,
      lng: -73.46315,
      city: 'Clemons',
      state: 'NY',
    },
    12820: {
      lat: 43.46022,
      lng: -73.64067,
      city: 'Cleverdale',
      state: 'NY',
    },
    12821: {
      lat: 43.45866,
      lng: -73.41332,
      city: 'Comstock',
      state: 'NY',
    },
    12822: {
      lat: 43.2407,
      lng: -73.88959,
      city: 'Corinth',
      state: 'NY',
    },
    12823: {
      lat: 43.17945,
      lng: -73.40628,
      city: 'Cossayuna',
      state: 'NY',
    },
    12824: {
      lat: 43.5279,
      lng: -73.71386,
      city: 'Diamond Point',
      state: 'NY',
    },
    12827: {
      lat: 43.44917,
      lng: -73.51241,
      city: 'Fort Ann',
      state: 'NY',
    },
    12828: {
      lat: 43.25534,
      lng: -73.56841,
      city: 'Fort Edward',
      state: 'NY',
    },
    12831: {
      lat: 43.19533,
      lng: -73.68791,
      city: 'Gansevoort',
      state: 'NY',
    },
    12832: {
      lat: 43.36813,
      lng: -73.31716,
      city: 'Granville',
      state: 'NY',
    },
    12833: {
      lat: 43.13866,
      lng: -73.84355,
      city: 'Greenfield Center',
      state: 'NY',
    },
    12834: {
      lat: 43.09706,
      lng: -73.50423,
      city: 'Greenwich',
      state: 'NY',
    },
    12835: {
      lat: 43.32496,
      lng: -74.00589,
      city: 'Hadley',
      state: 'NY',
    },
    12836: {
      lat: 43.74994,
      lng: -73.56816,
      city: 'Hague',
      state: 'NY',
    },
    12837: {
      lat: 43.50842,
      lng: -73.26651,
      city: 'Hampton',
      state: 'NY',
    },
    12838: {
      lat: 43.34354,
      lng: -73.40756,
      city: 'Hartford',
      state: 'NY',
    },
    12839: {
      lat: 43.34496,
      lng: -73.55058,
      city: 'Hudson Falls',
      state: 'NY',
    },
    12841: {
      lat: 43.59919,
      lng: -73.5165,
      city: 'Huletts Landing',
      state: 'NY',
    },
    12842: {
      lat: 43.75058,
      lng: -74.2746,
      city: 'Indian Lake',
      state: 'NY',
    },
    12843: {
      lat: 43.5849,
      lng: -73.97771,
      city: 'Johnsburg',
      state: 'NY',
    },
    12844: {
      lat: 43.49423,
      lng: -73.64081,
      city: 'Kattskill Bay',
      state: 'NY',
    },
    12845: {
      lat: 43.42757,
      lng: -73.70703,
      city: 'Lake George',
      state: 'NY',
    },
    12846: {
      lat: 43.34022,
      lng: -73.80592,
      city: 'Lake Luzerne',
      state: 'NY',
    },
    12847: {
      lat: 43.98698,
      lng: -74.59024,
      city: 'Long Lake',
      state: 'NY',
    },
    12849: {
      lat: 43.44812,
      lng: -73.2985,
      city: 'Middle Granville',
      state: 'NY',
    },
    12850: {
      lat: 43.09886,
      lng: -73.97941,
      city: 'Middle Grove',
      state: 'NY',
    },
    12851: {
      lat: 43.85171,
      lng: -74.06211,
      city: 'Minerva',
      state: 'NY',
    },
    12852: {
      lat: 43.99293,
      lng: -74.13598,
      city: 'Newcomb',
      state: 'NY',
    },
    12853: {
      lat: 43.68816,
      lng: -73.99804,
      city: 'North Creek',
      state: 'NY',
    },
    12854: {
      lat: 43.45019,
      lng: -73.32767,
      city: 'North Granville',
      state: 'NY',
    },
    12855: {
      lat: 44.00121,
      lng: -73.79762,
      city: 'North Hudson',
      state: 'NY',
    },
    12856: {
      lat: 43.65531,
      lng: -74.14625,
      city: 'North River',
      state: 'NY',
    },
    12857: {
      lat: 43.84033,
      lng: -73.93354,
      city: 'Olmstedville',
      state: 'NY',
    },
    12858: {
      lat: 43.88899,
      lng: -73.6703,
      city: 'Paradox',
      state: 'NY',
    },
    12859: {
      lat: 43.16587,
      lng: -73.90569,
      city: 'Porter Corners',
      state: 'NY',
    },
    12860: {
      lat: 43.72843,
      lng: -73.80942,
      city: 'Pottersville',
      state: 'NY',
    },
    12861: {
      lat: 43.74982,
      lng: -73.41502,
      city: 'Putnam Station',
      state: 'NY',
    },
    12862: {
      lat: 43.67419,
      lng: -73.91878,
      city: 'Riparius',
      state: 'NY',
    },
    12863: {
      lat: 43.06031,
      lng: -73.93162,
      city: 'Rock City Falls',
      state: 'NY',
    },
    12864: {
      lat: 43.72733,
      lng: -74.3054,
      city: 'Sabael',
      state: 'NY',
    },
    12865: {
      lat: 43.21225,
      lng: -73.34191,
      city: 'Salem',
      state: 'NY',
    },
    12866: {
      lat: 43.07537,
      lng: -73.7401,
      city: 'Saratoga Springs',
      state: 'NY',
    },
    12870: {
      lat: 43.83931,
      lng: -73.7625,
      city: 'Schroon Lake',
      state: 'NY',
    },
    12871: {
      lat: 43.09249,
      lng: -73.61196,
      city: 'Schuylerville',
      state: 'NY',
    },
    12872: {
      lat: 43.8839,
      lng: -73.72309,
      city: 'Severance',
      state: 'NY',
    },
    12873: {
      lat: 43.1185,
      lng: -73.30682,
      city: 'Shushan',
      state: 'NY',
    },
    12874: {
      lat: 43.69035,
      lng: -73.54836,
      city: 'Silver Bay',
      state: 'NY',
    },
    12878: {
      lat: 43.44217,
      lng: -74.0479,
      city: 'Stony Creek',
      state: 'NY',
    },
    12883: {
      lat: 43.85291,
      lng: -73.50786,
      city: 'Ticonderoga',
      state: 'NY',
    },
    12884: {
      lat: 43.08855,
      lng: -73.5921,
      city: 'Victory Mills',
      state: 'NY',
    },
    12885: {
      lat: 43.52929,
      lng: -73.83161,
      city: 'Warrensburg',
      state: 'NY',
    },
    12886: {
      lat: 43.63689,
      lng: -73.92654,
      city: 'Wevertown',
      state: 'NY',
    },
    12887: {
      lat: 43.54504,
      lng: -73.37471,
      city: 'Whitehall',
      state: 'NY',
    },
    12901: {
      lat: 44.70842,
      lng: -73.47465,
      city: 'Plattsburgh',
      state: 'NY',
    },
    12903: {
      lat: 44.65698,
      lng: -73.46306,
      city: 'Plattsburgh',
      state: 'NY',
    },
    12910: {
      lat: 44.8546,
      lng: -73.64593,
      city: 'Altona',
      state: 'NY',
    },
    12911: {
      lat: 44.53464,
      lng: -73.46687,
      city: 'Keeseville',
      state: 'NY',
    },
    12912: {
      lat: 44.46233,
      lng: -73.74201,
      city: 'Au Sable Forks',
      state: 'NY',
    },
    12913: {
      lat: 44.42627,
      lng: -74.01562,
      city: 'Bloomingdale',
      state: 'NY',
    },
    12914: {
      lat: 44.93289,
      lng: -74.6018,
      city: 'Bombay',
      state: 'NY',
    },
    12915: {
      lat: 44.8535,
      lng: -74.035,
      city: 'Brainardsville',
      state: 'NY',
    },
    12916: {
      lat: 44.83572,
      lng: -74.5145,
      city: 'Brushton',
      state: 'NY',
    },
    12917: {
      lat: 44.92468,
      lng: -74.17387,
      city: 'Burke',
      state: 'NY',
    },
    12918: {
      lat: 44.68975,
      lng: -73.67863,
      city: 'Cadyville',
      state: 'NY',
    },
    12919: {
      lat: 44.96793,
      lng: -73.45072,
      city: 'Champlain',
      state: 'NY',
    },
    12920: {
      lat: 44.89332,
      lng: -74.06016,
      city: 'Chateaugay',
      state: 'NY',
    },
    12921: {
      lat: 44.89188,
      lng: -73.43574,
      city: 'Chazy',
      state: 'NY',
    },
    12922: {
      lat: 44.33381,
      lng: -74.7827,
      city: 'Childwold',
      state: 'NY',
    },
    12923: {
      lat: 44.94546,
      lng: -73.95802,
      city: 'Churubusco',
      state: 'NY',
    },
    12924: {
      lat: 44.47589,
      lng: -73.58751,
      city: 'Keeseville',
      state: 'NY',
    },
    12926: {
      lat: 44.95223,
      lng: -74.32214,
      city: 'Constable',
      state: 'NY',
    },
    12927: {
      lat: 44.2005,
      lng: -74.81818,
      city: 'Cranberry Lake',
      state: 'NY',
    },
    12928: {
      lat: 43.9539,
      lng: -73.52898,
      city: 'Crown Point',
      state: 'NY',
    },
    12929: {
      lat: 44.71971,
      lng: -73.71909,
      city: 'Dannemora',
      state: 'NY',
    },
    12930: {
      lat: 44.72349,
      lng: -74.5437,
      city: 'Dickinson Center',
      state: 'NY',
    },
    12932: {
      lat: 44.21222,
      lng: -73.6082,
      city: 'Elizabethtown',
      state: 'NY',
    },
    12933: {
      lat: 44.89118,
      lng: -73.84512,
      city: 'Ellenburg',
      state: 'NY',
    },
    12934: {
      lat: 44.84603,
      lng: -73.87659,
      city: 'Ellenburg Center',
      state: 'NY',
    },
    12935: {
      lat: 44.85709,
      lng: -73.79461,
      city: 'Ellenburg Depot',
      state: 'NY',
    },
    12936: { lat: 44.26869, lng: -73.4029, city: 'Essex', state: 'NY' },
    12937: {
      lat: 44.96176,
      lng: -74.47807,
      city: 'Fort Covington',
      state: 'NY',
    },
    12939: {
      lat: 44.43859,
      lng: -74.16541,
      city: 'Gabriels',
      state: 'NY',
    },
    12941: { lat: 44.35147, lng: -73.7126, city: 'Jay', state: 'NY' },
    12942: {
      lat: 44.26023,
      lng: -73.79523,
      city: 'Keene',
      state: 'NY',
    },
    12943: {
      lat: 44.13934,
      lng: -73.82075,
      city: 'Keene Valley',
      state: 'NY',
    },
    12944: {
      lat: 44.44585,
      lng: -73.51193,
      city: 'Keeseville',
      state: 'NY',
    },
    12945: {
      lat: 44.33451,
      lng: -74.23829,
      city: 'Lake Clear',
      state: 'NY',
    },
    12946: {
      lat: 44.23162,
      lng: -73.98903,
      city: 'Lake Placid',
      state: 'NY',
    },
    12950: {
      lat: 44.32685,
      lng: -73.57675,
      city: 'Lewis',
      state: 'NY',
    },
    12952: {
      lat: 44.71181,
      lng: -73.91362,
      city: 'Lyon Mountain',
      state: 'NY',
    },
    12953: {
      lat: 44.74198,
      lng: -74.26347,
      city: 'Malone',
      state: 'NY',
    },
    12955: {
      lat: 44.78934,
      lng: -73.95855,
      city: 'Lyon Mountain',
      state: 'NY',
    },
    12956: {
      lat: 44.09301,
      lng: -73.49628,
      city: 'Mineville',
      state: 'NY',
    },
    12957: {
      lat: 44.85353,
      lng: -74.57706,
      city: 'Moira',
      state: 'NY',
    },
    12958: {
      lat: 44.95661,
      lng: -73.57419,
      city: 'Mooers',
      state: 'NY',
    },
    12959: {
      lat: 44.96073,
      lng: -73.7014,
      city: 'Mooers Forks',
      state: 'NY',
    },
    12960: {
      lat: 44.02226,
      lng: -73.54123,
      city: 'Moriah',
      state: 'NY',
    },
    12961: {
      lat: 44.06103,
      lng: -73.57972,
      city: 'Moriah Center',
      state: 'NY',
    },
    12962: {
      lat: 44.70642,
      lng: -73.60141,
      city: 'Morrisonville',
      state: 'NY',
    },
    12964: {
      lat: 44.13224,
      lng: -73.63625,
      city: 'New Russia',
      state: 'NY',
    },
    12965: {
      lat: 44.70587,
      lng: -74.684,
      city: 'Nicholville',
      state: 'NY',
    },
    12966: {
      lat: 44.79226,
      lng: -74.41915,
      city: 'North Bangor',
      state: 'NY',
    },
    12967: {
      lat: 44.76864,
      lng: -74.65897,
      city: 'North Lawrence',
      state: 'NY',
    },
    12969: {
      lat: 44.71102,
      lng: -74.0812,
      city: 'Owls Head',
      state: 'NY',
    },
    12970: {
      lat: 44.44949,
      lng: -74.33222,
      city: 'Paul Smiths',
      state: 'NY',
    },
    12972: { lat: 44.559, lng: -73.56516, city: 'Peru', state: 'NY' },
    12973: {
      lat: 44.30279,
      lng: -74.59187,
      city: 'Piercefield',
      state: 'NY',
    },
    12974: {
      lat: 44.05582,
      lng: -73.46931,
      city: 'Port Henry',
      state: 'NY',
    },
    12975: {
      lat: 44.52977,
      lng: -73.431,
      city: 'Port Kent',
      state: 'NY',
    },
    12976: {
      lat: 44.50469,
      lng: -74.20498,
      city: 'Rainbow Lake',
      state: 'NY',
    },
    12977: {
      lat: 44.29484,
      lng: -74.09673,
      city: 'Ray Brook',
      state: 'NY',
    },
    12978: {
      lat: 44.6181,
      lng: -73.79589,
      city: 'Redford',
      state: 'NY',
    },
    12979: {
      lat: 44.98926,
      lng: -73.37213,
      city: 'Rouses Point',
      state: 'NY',
    },
    12980: {
      lat: 44.57254,
      lng: -74.50875,
      city: 'Saint Regis Falls',
      state: 'NY',
    },
    12981: {
      lat: 44.62235,
      lng: -73.84035,
      city: 'Saranac',
      state: 'NY',
    },
    12983: {
      lat: 44.34627,
      lng: -74.14243,
      city: 'Saranac Lake',
      state: 'NY',
    },
    12985: {
      lat: 44.56413,
      lng: -73.73395,
      city: 'Schuyler Falls',
      state: 'NY',
    },
    12986: {
      lat: 44.22279,
      lng: -74.44056,
      city: 'Tupper Lake',
      state: 'NY',
    },
    12987: {
      lat: 44.33948,
      lng: -73.76386,
      city: 'Upper Jay',
      state: 'NY',
    },
    12989: {
      lat: 44.53935,
      lng: -74.06439,
      city: 'Vermontville',
      state: 'NY',
    },
    12992: {
      lat: 44.81822,
      lng: -73.51582,
      city: 'West Chazy',
      state: 'NY',
    },
    12993: {
      lat: 44.19711,
      lng: -73.48097,
      city: 'Westport',
      state: 'NY',
    },
    12996: {
      lat: 44.36129,
      lng: -73.42129,
      city: 'Willsboro',
      state: 'NY',
    },
    12997: {
      lat: 44.3755,
      lng: -73.84314,
      city: 'Wilmington',
      state: 'NY',
    },
    12998: {
      lat: 44.0879,
      lng: -73.57584,
      city: 'Witherbee',
      state: 'NY',
    },
    13020: {
      lat: 42.81646,
      lng: -76.07458,
      city: 'Apulia Station',
      state: 'NY',
    },
    13021: {
      lat: 42.91713,
      lng: -76.5542,
      city: 'Auburn',
      state: 'NY',
    },
    13024: {
      lat: 42.93453,
      lng: -76.57424,
      city: 'Auburn',
      state: 'NY',
    },
    13026: {
      lat: 42.75044,
      lng: -76.65968,
      city: 'Aurora',
      state: 'NY',
    },
    13027: {
      lat: 43.16741,
      lng: -76.36258,
      city: 'Baldwinsville',
      state: 'NY',
    },
    13028: {
      lat: 43.29226,
      lng: -75.93107,
      city: 'Bernhards Bay',
      state: 'NY',
    },
    13029: {
      lat: 43.2236,
      lng: -76.14993,
      city: 'Brewerton',
      state: 'NY',
    },
    13030: {
      lat: 43.15962,
      lng: -75.96052,
      city: 'Bridgeport',
      state: 'NY',
    },
    13031: {
      lat: 43.0448,
      lng: -76.30775,
      city: 'Camillus',
      state: 'NY',
    },
    13032: {
      lat: 43.07793,
      lng: -75.76275,
      city: 'Canastota',
      state: 'NY',
    },
    13033: { lat: 43.1852, lng: -76.57044, city: 'Cato', state: 'NY' },
    13034: {
      lat: 42.91366,
      lng: -76.69502,
      city: 'Cayuga',
      state: 'NY',
    },
    13035: {
      lat: 42.93828,
      lng: -75.82551,
      city: 'Cazenovia',
      state: 'NY',
    },
    13036: {
      lat: 43.32268,
      lng: -76.17402,
      city: 'Central Square',
      state: 'NY',
    },
    13037: {
      lat: 43.06046,
      lng: -75.86521,
      city: 'Chittenango',
      state: 'NY',
    },
    13039: {
      lat: 43.16678,
      lng: -76.0654,
      city: 'Cicero',
      state: 'NY',
    },
    13040: {
      lat: 42.56033,
      lng: -75.93029,
      city: 'Cincinnatus',
      state: 'NY',
    },
    13041: { lat: 43.19079, lng: -76.19711, city: 'Clay', state: 'NY' },
    13042: {
      lat: 43.26565,
      lng: -75.85154,
      city: 'Cleveland',
      state: 'NY',
    },
    13044: {
      lat: 43.2977,
      lng: -75.99595,
      city: 'Constantia',
      state: 'NY',
    },
    13045: {
      lat: 42.58692,
      lng: -76.19029,
      city: 'Cortland',
      state: 'NY',
    },
    13051: {
      lat: 42.88339,
      lng: -75.91021,
      city: 'Delphi Falls',
      state: 'NY',
    },
    13052: {
      lat: 42.72838,
      lng: -75.86957,
      city: 'De Ruyter',
      state: 'NY',
    },
    13053: {
      lat: 42.47513,
      lng: -76.26471,
      city: 'Dryden',
      state: 'NY',
    },
    13054: {
      lat: 43.16947,
      lng: -75.66923,
      city: 'Durhamville',
      state: 'NY',
    },
    13057: {
      lat: 43.09543,
      lng: -76.04014,
      city: 'East Syracuse',
      state: 'NY',
    },
    13060: {
      lat: 43.02381,
      lng: -76.41926,
      city: 'Elbridge',
      state: 'NY',
    },
    13061: {
      lat: 42.86493,
      lng: -75.75173,
      city: 'Erieville',
      state: 'NY',
    },
    13062: { lat: 42.48121, lng: -76.38694, city: 'Etna', state: 'NY' },
    13063: {
      lat: 42.84429,
      lng: -75.98023,
      city: 'Fabius',
      state: 'NY',
    },
    13065: {
      lat: 42.81884,
      lng: -76.81577,
      city: 'Fayette',
      state: 'NY',
    },
    13066: {
      lat: 43.03297,
      lng: -76.00259,
      city: 'Fayetteville',
      state: 'NY',
    },
    13068: {
      lat: 42.49905,
      lng: -76.35555,
      city: 'Freeville',
      state: 'NY',
    },
    13069: {
      lat: 43.33081,
      lng: -76.37867,
      city: 'Fulton',
      state: 'NY',
    },
    13071: {
      lat: 42.67584,
      lng: -76.54252,
      city: 'Genoa',
      state: 'NY',
    },
    13072: {
      lat: 42.76243,
      lng: -75.76296,
      city: 'Georgetown',
      state: 'NY',
    },
    13073: {
      lat: 42.58514,
      lng: -76.39832,
      city: 'Groton',
      state: 'NY',
    },
    13074: {
      lat: 43.31035,
      lng: -76.54786,
      city: 'Hannibal',
      state: 'NY',
    },
    13076: {
      lat: 43.35364,
      lng: -76.15182,
      city: 'Hastings',
      state: 'NY',
    },
    13077: {
      lat: 42.71557,
      lng: -76.20089,
      city: 'Homer',
      state: 'NY',
    },
    13078: {
      lat: 42.96206,
      lng: -76.06171,
      city: 'Jamesville',
      state: 'NY',
    },
    13080: {
      lat: 43.08338,
      lng: -76.47776,
      city: 'Jordan',
      state: 'NY',
    },
    13081: {
      lat: 42.67364,
      lng: -76.62527,
      city: 'King Ferry',
      state: 'NY',
    },
    13082: {
      lat: 43.10148,
      lng: -75.95253,
      city: 'Kirkville',
      state: 'NY',
    },
    13083: {
      lat: 43.64853,
      lng: -75.98091,
      city: 'Lacona',
      state: 'NY',
    },
    13084: {
      lat: 42.88938,
      lng: -76.11314,
      city: 'La Fayette',
      state: 'NY',
    },
    13087: {
      lat: 42.69634,
      lng: -76.16493,
      city: 'Little York',
      state: 'NY',
    },
    13088: {
      lat: 43.10787,
      lng: -76.19096,
      city: 'Liverpool',
      state: 'NY',
    },
    13090: {
      lat: 43.15162,
      lng: -76.21344,
      city: 'Liverpool',
      state: 'NY',
    },
    13092: {
      lat: 42.65145,
      lng: -76.42502,
      city: 'Locke',
      state: 'NY',
    },
    13101: {
      lat: 42.60211,
      lng: -76.06665,
      city: 'McGraw',
      state: 'NY',
    },
    13102: {
      lat: 42.55276,
      lng: -76.29253,
      city: 'McLean',
      state: 'NY',
    },
    13103: {
      lat: 43.32652,
      lng: -76.10507,
      city: 'Mallory',
      state: 'NY',
    },
    13104: {
      lat: 42.96378,
      lng: -75.95523,
      city: 'Manlius',
      state: 'NY',
    },
    13108: {
      lat: 42.97349,
      lng: -76.33069,
      city: 'Marcellus',
      state: 'NY',
    },
    13110: {
      lat: 42.89019,
      lng: -76.28507,
      city: 'Marietta',
      state: 'NY',
    },
    13111: {
      lat: 43.25988,
      lng: -76.61858,
      city: 'Martville',
      state: 'NY',
    },
    13112: {
      lat: 43.10306,
      lng: -76.41926,
      city: 'Memphis',
      state: 'NY',
    },
    13113: {
      lat: 43.16425,
      lng: -76.53488,
      city: 'Meridian',
      state: 'NY',
    },
    13114: {
      lat: 43.46386,
      lng: -76.24328,
      city: 'Mexico',
      state: 'NY',
    },
    13115: {
      lat: 43.3937,
      lng: -76.47462,
      city: 'Minetto',
      state: 'NY',
    },
    13116: {
      lat: 43.07449,
      lng: -76.00796,
      city: 'Minoa',
      state: 'NY',
    },
    13117: {
      lat: 43.01007,
      lng: -76.70838,
      city: 'Montezuma',
      state: 'NY',
    },
    13118: {
      lat: 42.75226,
      lng: -76.39522,
      city: 'Moravia',
      state: 'NY',
    },
    13119: {
      lat: 42.97655,
      lng: -76.44488,
      city: 'Mottville',
      state: 'NY',
    },
    13120: {
      lat: 42.94233,
      lng: -76.1695,
      city: 'Nedrow',
      state: 'NY',
    },
    13121: {
      lat: 43.48491,
      lng: -76.30966,
      city: 'New Haven',
      state: 'NY',
    },
    13122: {
      lat: 42.83857,
      lng: -75.85919,
      city: 'New Woodstock',
      state: 'NY',
    },
    13123: {
      lat: 43.22796,
      lng: -75.75325,
      city: 'North Bay',
      state: 'NY',
    },
    13124: {
      lat: 42.64578,
      lng: -75.81718,
      city: 'North Pitcher',
      state: 'NY',
    },
    13126: {
      lat: 43.43469,
      lng: -76.46331,
      city: 'Oswego',
      state: 'NY',
    },
    13131: {
      lat: 43.41964,
      lng: -76.09539,
      city: 'Parish',
      state: 'NY',
    },
    13132: {
      lat: 43.26547,
      lng: -76.24446,
      city: 'Pennellville',
      state: 'NY',
    },
    13134: {
      lat: 42.96731,
      lng: -75.68476,
      city: 'Peterboro',
      state: 'NY',
    },
    13135: {
      lat: 43.25801,
      lng: -76.31676,
      city: 'Phoenix',
      state: 'NY',
    },
    13136: {
      lat: 42.60368,
      lng: -75.84582,
      city: 'Pitcher',
      state: 'NY',
    },
    13138: {
      lat: 42.89915,
      lng: -76.01461,
      city: 'Pompey',
      state: 'NY',
    },
    13140: {
      lat: 43.05891,
      lng: -76.65841,
      city: 'Port Byron',
      state: 'NY',
    },
    13141: {
      lat: 42.76684,
      lng: -76.18616,
      city: 'Preble',
      state: 'NY',
    },
    13142: {
      lat: 43.55189,
      lng: -76.12959,
      city: 'Pulaski',
      state: 'NY',
    },
    13143: {
      lat: 43.23325,
      lng: -76.71545,
      city: 'Red Creek',
      state: 'NY',
    },
    13144: {
      lat: 43.57034,
      lng: -75.97464,
      city: 'Richland',
      state: 'NY',
    },
    13145: {
      lat: 43.65188,
      lng: -76.1232,
      city: 'Sandy Creek',
      state: 'NY',
    },
    13146: {
      lat: 43.09711,
      lng: -76.76031,
      city: 'Savannah',
      state: 'NY',
    },
    13147: {
      lat: 42.77336,
      lng: -76.56603,
      city: 'Scipio Center',
      state: 'NY',
    },
    13148: {
      lat: 42.9208,
      lng: -76.78106,
      city: 'Seneca Falls',
      state: 'NY',
    },
    13152: {
      lat: 42.89323,
      lng: -76.38518,
      city: 'Skaneateles',
      state: 'NY',
    },
    13153: {
      lat: 42.9945,
      lng: -76.45476,
      city: 'Skaneateles Falls',
      state: 'NY',
    },
    13154: {
      lat: 43.13378,
      lng: -76.76192,
      city: 'South Butler',
      state: 'NY',
    },
    13155: {
      lat: 42.65945,
      lng: -75.77136,
      city: 'South Otselic',
      state: 'NY',
    },
    13156: {
      lat: 43.33355,
      lng: -76.66249,
      city: 'Sterling',
      state: 'NY',
    },
    13157: {
      lat: 43.20288,
      lng: -75.7254,
      city: 'Sylvan Beach',
      state: 'NY',
    },
    13158: {
      lat: 42.71603,
      lng: -75.97626,
      city: 'Truxton',
      state: 'NY',
    },
    13159: {
      lat: 42.80321,
      lng: -76.11545,
      city: 'Tully',
      state: 'NY',
    },
    13160: {
      lat: 42.82613,
      lng: -76.65157,
      city: 'Union Springs',
      state: 'NY',
    },
    13162: {
      lat: 43.18833,
      lng: -75.71823,
      city: 'Verona Beach',
      state: 'NY',
    },
    13163: {
      lat: 43.08194,
      lng: -75.70841,
      city: 'Wampsville',
      state: 'NY',
    },
    13164: {
      lat: 43.09377,
      lng: -76.31626,
      city: 'Warners',
      state: 'NY',
    },
    13165: {
      lat: 42.91185,
      lng: -76.87601,
      city: 'Waterloo',
      state: 'NY',
    },
    13166: {
      lat: 43.07219,
      lng: -76.5568,
      city: 'Weedsport',
      state: 'NY',
    },
    13167: {
      lat: 43.3171,
      lng: -76.0571,
      city: 'West Monroe',
      state: 'NY',
    },
    13202: {
      lat: 43.04356,
      lng: -76.15065,
      city: 'Syracuse',
      state: 'NY',
    },
    13203: {
      lat: 43.061,
      lng: -76.13486,
      city: 'Syracuse',
      state: 'NY',
    },
    13204: {
      lat: 43.05204,
      lng: -76.17666,
      city: 'Syracuse',
      state: 'NY',
    },
    13205: {
      lat: 43.00474,
      lng: -76.14163,
      city: 'Syracuse',
      state: 'NY',
    },
    13206: {
      lat: 43.07354,
      lng: -76.10584,
      city: 'Syracuse',
      state: 'NY',
    },
    13207: {
      lat: 43.01221,
      lng: -76.16354,
      city: 'Syracuse',
      state: 'NY',
    },
    13208: {
      lat: 43.07878,
      lng: -76.14521,
      city: 'Syracuse',
      state: 'NY',
    },
    13209: {
      lat: 43.08581,
      lng: -76.24355,
      city: 'Syracuse',
      state: 'NY',
    },
    13210: {
      lat: 43.03186,
      lng: -76.12653,
      city: 'Syracuse',
      state: 'NY',
    },
    13211: {
      lat: 43.10333,
      lng: -76.11954,
      city: 'Syracuse',
      state: 'NY',
    },
    13212: {
      lat: 43.12839,
      lng: -76.12889,
      city: 'Syracuse',
      state: 'NY',
    },
    13214: {
      lat: 43.03865,
      lng: -76.0741,
      city: 'Syracuse',
      state: 'NY',
    },
    13215: {
      lat: 42.97852,
      lng: -76.22979,
      city: 'Syracuse',
      state: 'NY',
    },
    13219: {
      lat: 43.03969,
      lng: -76.22277,
      city: 'Syracuse',
      state: 'NY',
    },
    13224: {
      lat: 43.03892,
      lng: -76.10264,
      city: 'Syracuse',
      state: 'NY',
    },
    13244: {
      lat: 43.02318,
      lng: -76.12329,
      city: 'Syracuse',
      state: 'NY',
    },
    13290: {
      lat: 43.07132,
      lng: -76.17368,
      city: 'Syracuse',
      state: 'NY',
    },
    13301: {
      lat: 43.42225,
      lng: -75.21546,
      city: 'Alder Creek',
      state: 'NY',
    },
    13302: {
      lat: 43.50261,
      lng: -75.95044,
      city: 'Altmar',
      state: 'NY',
    },
    13303: { lat: 43.36992, lng: -75.46636, city: 'Ava', state: 'NY' },
    13304: {
      lat: 43.23954,
      lng: -75.1589,
      city: 'Barneveld',
      state: 'NY',
    },
    13305: {
      lat: 43.89329,
      lng: -75.43144,
      city: 'Beaver Falls',
      state: 'NY',
    },
    13308: {
      lat: 43.24865,
      lng: -75.66686,
      city: 'Blossvale',
      state: 'NY',
    },
    13309: {
      lat: 43.47398,
      lng: -75.34103,
      city: 'Boonville',
      state: 'NY',
    },
    13310: {
      lat: 42.89055,
      lng: -75.57065,
      city: 'Bouckville',
      state: 'NY',
    },
    13312: {
      lat: 43.70247,
      lng: -75.20758,
      city: 'Brantingham',
      state: 'NY',
    },
    13313: {
      lat: 42.88715,
      lng: -75.26228,
      city: 'Bridgewater',
      state: 'NY',
    },
    13314: {
      lat: 42.82476,
      lng: -75.31568,
      city: 'Brookfield',
      state: 'NY',
    },
    13315: {
      lat: 42.7438,
      lng: -75.1385,
      city: 'Burlington Flats',
      state: 'NY',
    },
    13316: {
      lat: 43.41492,
      lng: -75.73963,
      city: 'Camden',
      state: 'NY',
    },
    13317: {
      lat: 42.85638,
      lng: -74.58844,
      city: 'Canajoharie',
      state: 'NY',
    },
    13318: {
      lat: 42.92366,
      lng: -75.25958,
      city: 'Cassville',
      state: 'NY',
    },
    13319: {
      lat: 43.02705,
      lng: -75.26593,
      city: 'Chadwicks',
      state: 'NY',
    },
    13320: {
      lat: 42.77353,
      lng: -74.74325,
      city: 'Cherry Valley',
      state: 'NY',
    },
    13321: {
      lat: 43.08901,
      lng: -75.37259,
      city: 'Clark Mills',
      state: 'NY',
    },
    13322: {
      lat: 42.96661,
      lng: -75.20445,
      city: 'Clayville',
      state: 'NY',
    },
    13323: {
      lat: 43.04198,
      lng: -75.38144,
      city: 'Clinton',
      state: 'NY',
    },
    13324: {
      lat: 43.3165,
      lng: -74.93325,
      city: 'Cold Brook',
      state: 'NY',
    },
    13325: {
      lat: 43.58398,
      lng: -75.54091,
      city: 'Constableville',
      state: 'NY',
    },
    13326: {
      lat: 42.71865,
      lng: -74.90374,
      city: 'Cooperstown',
      state: 'NY',
    },
    13327: {
      lat: 43.96047,
      lng: -75.28474,
      city: 'Croghan',
      state: 'NY',
    },
    13328: {
      lat: 42.98247,
      lng: -75.42836,
      city: 'Deansboro',
      state: 'NY',
    },
    13329: {
      lat: 43.11094,
      lng: -74.70017,
      city: 'Dolgeville',
      state: 'NY',
    },
    13331: {
      lat: 43.81706,
      lng: -74.97284,
      city: 'Eagle Bay',
      state: 'NY',
    },
    13332: {
      lat: 42.75831,
      lng: -75.57452,
      city: 'Earlville',
      state: 'NY',
    },
    13333: {
      lat: 42.83091,
      lng: -74.82207,
      city: 'East Springfield',
      state: 'NY',
    },
    13334: {
      lat: 42.83445,
      lng: -75.65986,
      city: 'Eaton',
      state: 'NY',
    },
    13335: {
      lat: 42.70193,
      lng: -75.24847,
      city: 'Edmeston',
      state: 'NY',
    },
    13337: {
      lat: 42.75114,
      lng: -74.9816,
      city: 'Fly Creek',
      state: 'NY',
    },
    13338: {
      lat: 43.49706,
      lng: -74.97833,
      city: 'Forestport',
      state: 'NY',
    },
    13339: {
      lat: 42.93995,
      lng: -74.66631,
      city: 'Fort Plain',
      state: 'NY',
    },
    13340: {
      lat: 43.04814,
      lng: -75.11502,
      city: 'Frankfort',
      state: 'NY',
    },
    13342: {
      lat: 42.64766,
      lng: -75.18918,
      city: 'Garrattsville',
      state: 'NY',
    },
    13343: {
      lat: 43.75271,
      lng: -75.31062,
      city: 'Glenfield',
      state: 'NY',
    },
    13345: { lat: 43.6889, lng: -75.33153, city: 'Greig', state: 'NY' },
    13346: {
      lat: 42.82093,
      lng: -75.54453,
      city: 'Hamilton',
      state: 'NY',
    },
    13348: {
      lat: 42.70202,
      lng: -75.06398,
      city: 'Hartwick',
      state: 'NY',
    },
    13350: {
      lat: 43.06352,
      lng: -74.98817,
      city: 'Herkimer',
      state: 'NY',
    },
    13352: {
      lat: 43.31122,
      lng: -75.12058,
      city: 'Hinckley',
      state: 'NY',
    },
    13353: {
      lat: 43.47123,
      lng: -74.72732,
      city: 'Hoffmeister',
      state: 'NY',
    },
    13354: {
      lat: 43.26612,
      lng: -75.27199,
      city: 'Holland Patent',
      state: 'NY',
    },
    13355: {
      lat: 42.80971,
      lng: -75.42401,
      city: 'Hubbardsville',
      state: 'NY',
    },
    13357: {
      lat: 42.96952,
      lng: -75.08037,
      city: 'Ilion',
      state: 'NY',
    },
    13360: {
      lat: 43.72457,
      lng: -74.73577,
      city: 'Inlet',
      state: 'NY',
    },
    13361: {
      lat: 42.89807,
      lng: -74.86256,
      city: 'Jordanville',
      state: 'NY',
    },
    13363: {
      lat: 43.33127,
      lng: -75.50984,
      city: 'Lee Center',
      state: 'NY',
    },
    13364: {
      lat: 42.80501,
      lng: -75.26105,
      city: 'Leonardsville',
      state: 'NY',
    },
    13365: {
      lat: 43.09804,
      lng: -74.84937,
      city: 'Little Falls',
      state: 'NY',
    },
    13367: {
      lat: 43.85563,
      lng: -75.31772,
      city: 'Lowville',
      state: 'NY',
    },
    13368: {
      lat: 43.6446,
      lng: -75.33209,
      city: 'Lyons Falls',
      state: 'NY',
    },
    13402: {
      lat: 42.90209,
      lng: -75.50728,
      city: 'Madison',
      state: 'NY',
    },
    13403: {
      lat: 43.17041,
      lng: -75.27188,
      city: 'Marcy',
      state: 'NY',
    },
    13404: {
      lat: 43.73842,
      lng: -75.48089,
      city: 'Martinsburg',
      state: 'NY',
    },
    13406: {
      lat: 43.14326,
      lng: -74.92257,
      city: 'Middleville',
      state: 'NY',
    },
    13407: {
      lat: 42.9677,
      lng: -74.94087,
      city: 'Mohawk',
      state: 'NY',
    },
    13408: {
      lat: 42.92205,
      lng: -75.66722,
      city: 'Morrisville',
      state: 'NY',
    },
    13409: {
      lat: 42.97602,
      lng: -75.59131,
      city: 'Munnsville',
      state: 'NY',
    },
    13410: {
      lat: 42.92908,
      lng: -74.60922,
      city: 'Nelliston',
      state: 'NY',
    },
    13411: {
      lat: 42.63723,
      lng: -75.30981,
      city: 'New Berlin',
      state: 'NY',
    },
    13413: {
      lat: 43.0576,
      lng: -75.27495,
      city: 'New Hartford',
      state: 'NY',
    },
    13415: {
      lat: 42.59714,
      lng: -75.19729,
      city: 'New Lisbon',
      state: 'NY',
    },
    13416: {
      lat: 43.18768,
      lng: -74.9571,
      city: 'Newport',
      state: 'NY',
    },
    13417: {
      lat: 43.10002,
      lng: -75.29399,
      city: 'New York Mills',
      state: 'NY',
    },
    13418: {
      lat: 42.84519,
      lng: -75.38213,
      city: 'North Brookfield',
      state: 'NY',
    },
    13420: {
      lat: 43.65069,
      lng: -74.9619,
      city: 'Old Forge',
      state: 'NY',
    },
    13421: {
      lat: 43.06293,
      lng: -75.64654,
      city: 'Oneida',
      state: 'NY',
    },
    13424: {
      lat: 43.15228,
      lng: -75.36291,
      city: 'Oriskany',
      state: 'NY',
    },
    13425: {
      lat: 42.9635,
      lng: -75.48675,
      city: 'Oriskany Falls',
      state: 'NY',
    },
    13428: {
      lat: 42.92096,
      lng: -74.53311,
      city: 'Palatine Bridge',
      state: 'NY',
    },
    13431: {
      lat: 43.20691,
      lng: -75.07608,
      city: 'Poland',
      state: 'NY',
    },
    13433: {
      lat: 43.59914,
      lng: -75.24765,
      city: 'Port Leyden',
      state: 'NY',
    },
    13435: {
      lat: 43.30255,
      lng: -75.15134,
      city: 'Prospect',
      state: 'NY',
    },
    13436: {
      lat: 43.80501,
      lng: -74.67034,
      city: 'Raquette Lake',
      state: 'NY',
    },
    13437: {
      lat: 43.59677,
      lng: -75.80383,
      city: 'Redfield',
      state: 'NY',
    },
    13438: {
      lat: 43.35171,
      lng: -75.15527,
      city: 'Remsen',
      state: 'NY',
    },
    13439: {
      lat: 42.85491,
      lng: -74.99724,
      city: 'Richfield Springs',
      state: 'NY',
    },
    13440: { lat: 43.21807, lng: -75.45941, city: 'Rome', state: 'NY' },
    13441: { lat: 43.22711, lng: -75.40758, city: 'Rome', state: 'NY' },
    13450: {
      lat: 42.7026,
      lng: -74.81263,
      city: 'Roseboom',
      state: 'NY',
    },
    13452: {
      lat: 43.03718,
      lng: -74.63001,
      city: 'Saint Johnsville',
      state: 'NY',
    },
    13454: {
      lat: 43.16885,
      lng: -74.75719,
      city: 'Salisbury Center',
      state: 'NY',
    },
    13455: {
      lat: 42.91791,
      lng: -75.37655,
      city: 'Sangerfield',
      state: 'NY',
    },
    13456: {
      lat: 43.00145,
      lng: -75.25855,
      city: 'Sauquoit',
      state: 'NY',
    },
    13457: {
      lat: 42.77527,
      lng: -75.03691,
      city: 'Schuyler Lake',
      state: 'NY',
    },
    13459: {
      lat: 42.77721,
      lng: -74.59315,
      city: 'Sharon Springs',
      state: 'NY',
    },
    13460: {
      lat: 42.68492,
      lng: -75.45572,
      city: 'Sherburne',
      state: 'NY',
    },
    13461: {
      lat: 43.07115,
      lng: -75.59866,
      city: 'Sherrill',
      state: 'NY',
    },
    13464: {
      lat: 42.68632,
      lng: -75.61998,
      city: 'Smyrna',
      state: 'NY',
    },
    13468: {
      lat: 42.84696,
      lng: -74.86448,
      city: 'Springfield Center',
      state: 'NY',
    },
    13469: {
      lat: 43.22028,
      lng: -75.29853,
      city: 'Stittville',
      state: 'NY',
    },
    13470: {
      lat: 43.21075,
      lng: -74.66825,
      city: 'Stratford',
      state: 'NY',
    },
    13471: {
      lat: 43.37606,
      lng: -75.6122,
      city: 'Taberg',
      state: 'NY',
    },
    13472: {
      lat: 43.69491,
      lng: -75.06072,
      city: 'Thendara',
      state: 'NY',
    },
    13473: {
      lat: 43.64414,
      lng: -75.44517,
      city: 'Turin',
      state: 'NY',
    },
    13475: {
      lat: 42.88789,
      lng: -74.81196,
      city: 'Van Hornesville',
      state: 'NY',
    },
    13476: {
      lat: 43.08728,
      lng: -75.51219,
      city: 'Vernon',
      state: 'NY',
    },
    13477: {
      lat: 43.03633,
      lng: -75.51441,
      city: 'Vernon Center',
      state: 'NY',
    },
    13478: {
      lat: 43.14916,
      lng: -75.58009,
      city: 'Verona',
      state: 'NY',
    },
    13480: {
      lat: 42.91424,
      lng: -75.36699,
      city: 'Waterville',
      state: 'NY',
    },
    13482: {
      lat: 42.69661,
      lng: -75.17695,
      city: 'West Burlington',
      state: 'NY',
    },
    13483: {
      lat: 43.40075,
      lng: -75.81995,
      city: 'Westdale',
      state: 'NY',
    },
    13484: {
      lat: 42.85426,
      lng: -75.65549,
      city: 'West Eaton',
      state: 'NY',
    },
    13485: {
      lat: 42.7865,
      lng: -75.3165,
      city: 'West Edmeston',
      state: 'NY',
    },
    13486: {
      lat: 43.35707,
      lng: -75.34088,
      city: 'Westernville',
      state: 'NY',
    },
    13488: {
      lat: 42.67447,
      lng: -74.76796,
      city: 'Westford',
      state: 'NY',
    },
    13489: {
      lat: 43.46698,
      lng: -75.52928,
      city: 'West Leyden',
      state: 'NY',
    },
    13490: {
      lat: 43.10612,
      lng: -75.42991,
      city: 'Westmoreland',
      state: 'NY',
    },
    13491: {
      lat: 42.85957,
      lng: -75.17698,
      city: 'West Winfield',
      state: 'NY',
    },
    13492: {
      lat: 43.1172,
      lng: -75.33023,
      city: 'Whitesboro',
      state: 'NY',
    },
    13493: {
      lat: 43.43162,
      lng: -75.90102,
      city: 'Williamstown',
      state: 'NY',
    },
    13494: {
      lat: 43.53136,
      lng: -75.14198,
      city: 'Woodgate',
      state: 'NY',
    },
    13495: {
      lat: 43.11087,
      lng: -75.27744,
      city: 'Yorkville',
      state: 'NY',
    },
    13501: {
      lat: 43.08138,
      lng: -75.22611,
      city: 'Utica',
      state: 'NY',
    },
    13502: {
      lat: 43.14121,
      lng: -75.16584,
      city: 'Utica',
      state: 'NY',
    },
    13601: {
      lat: 43.96949,
      lng: -75.90965,
      city: 'Watertown',
      state: 'NY',
    },
    13602: {
      lat: 44.04877,
      lng: -75.75747,
      city: 'Fort Drum',
      state: 'NY',
    },
    13603: {
      lat: 44.03826,
      lng: -75.79765,
      city: 'Watertown',
      state: 'NY',
    },
    13605: {
      lat: 43.80641,
      lng: -76.05195,
      city: 'Adams',
      state: 'NY',
    },
    13606: {
      lat: 43.87007,
      lng: -76.01601,
      city: 'Adams Center',
      state: 'NY',
    },
    13607: {
      lat: 44.33233,
      lng: -75.91187,
      city: 'Alexandria Bay',
      state: 'NY',
    },
    13608: {
      lat: 44.23879,
      lng: -75.62508,
      city: 'Antwerp',
      state: 'NY',
    },
    13611: {
      lat: 43.77726,
      lng: -76.12374,
      city: 'Belleville',
      state: 'NY',
    },
    13612: {
      lat: 43.98571,
      lng: -75.76911,
      city: 'Black River',
      state: 'NY',
    },
    13613: {
      lat: 44.85884,
      lng: -74.73879,
      city: 'Brasher Falls',
      state: 'NY',
    },
    13614: {
      lat: 44.53081,
      lng: -75.69194,
      city: 'Brier Hill',
      state: 'NY',
    },
    13615: {
      lat: 44.00569,
      lng: -75.98328,
      city: 'Brownville',
      state: 'NY',
    },
    13616: {
      lat: 44.02538,
      lng: -75.85896,
      city: 'Calcium',
      state: 'NY',
    },
    13617: {
      lat: 44.5789,
      lng: -75.14668,
      city: 'Canton',
      state: 'NY',
    },
    13618: {
      lat: 44.11641,
      lng: -76.27851,
      city: 'Cape Vincent',
      state: 'NY',
    },
    13619: {
      lat: 43.97705,
      lng: -75.59432,
      city: 'Carthage',
      state: 'NY',
    },
    13620: {
      lat: 43.89989,
      lng: -75.45265,
      city: 'Castorland',
      state: 'NY',
    },
    13621: {
      lat: 44.8452,
      lng: -75.06596,
      city: 'Chase Mills',
      state: 'NY',
    },
    13622: {
      lat: 44.09654,
      lng: -76.115,
      city: 'Chaumont',
      state: 'NY',
    },
    13623: {
      lat: 44.44877,
      lng: -75.75299,
      city: 'Chippewa Bay',
      state: 'NY',
    },
    13624: {
      lat: 44.22284,
      lng: -76.09736,
      city: 'Clayton',
      state: 'NY',
    },
    13625: {
      lat: 44.5384,
      lng: -74.92135,
      city: 'Colton',
      state: 'NY',
    },
    13626: {
      lat: 43.85143,
      lng: -75.7202,
      city: 'Copenhagen',
      state: 'NY',
    },
    13628: {
      lat: 44.03764,
      lng: -75.68307,
      city: 'Deferiet',
      state: 'NY',
    },
    13630: {
      lat: 44.49742,
      lng: -75.31756,
      city: 'De Kalb Junction',
      state: 'NY',
    },
    13632: {
      lat: 44.12698,
      lng: -76.06826,
      city: 'Depauville',
      state: 'NY',
    },
    13633: {
      lat: 44.48259,
      lng: -75.48825,
      city: 'De Peyster',
      state: 'NY',
    },
    13634: {
      lat: 44.01197,
      lng: -76.07034,
      city: 'Dexter',
      state: 'NY',
    },
    13635: {
      lat: 44.29915,
      lng: -75.26188,
      city: 'Edwards',
      state: 'NY',
    },
    13636: {
      lat: 43.74107,
      lng: -76.12762,
      city: 'Ellisburg',
      state: 'NY',
    },
    13637: {
      lat: 44.09751,
      lng: -75.83346,
      city: 'Evans Mills',
      state: 'NY',
    },
    13638: {
      lat: 44.02077,
      lng: -75.75443,
      city: 'Felts Mills',
      state: 'NY',
    },
    13639: { lat: 44.2594, lng: -75.14324, city: 'Fine', state: 'NY' },
    13640: {
      lat: 44.3198,
      lng: -75.99937,
      city: 'Wellesley Island',
      state: 'NY',
    },
    13641: {
      lat: 44.27341,
      lng: -76.00573,
      city: 'Fishers Landing',
      state: 'NY',
    },
    13642: {
      lat: 44.3263,
      lng: -75.46742,
      city: 'Gouverneur',
      state: 'NY',
    },
    13643: {
      lat: 44.03207,
      lng: -75.71738,
      city: 'Great Bend',
      state: 'NY',
    },
    13645: {
      lat: 44.30878,
      lng: -75.44655,
      city: 'Hailesboro',
      state: 'NY',
    },
    13646: {
      lat: 44.44144,
      lng: -75.69379,
      city: 'Hammond',
      state: 'NY',
    },
    13647: {
      lat: 44.6017,
      lng: -74.99264,
      city: 'Hannawa Falls',
      state: 'NY',
    },
    13648: {
      lat: 44.14977,
      lng: -75.29908,
      city: 'Harrisville',
      state: 'NY',
    },
    13649: {
      lat: 44.91246,
      lng: -74.69983,
      city: 'Helena',
      state: 'NY',
    },
    13650: {
      lat: 43.80742,
      lng: -76.20629,
      city: 'Henderson',
      state: 'NY',
    },
    13651: {
      lat: 43.86513,
      lng: -76.18125,
      city: 'Henderson Harbor',
      state: 'NY',
    },
    13652: {
      lat: 44.43579,
      lng: -75.21338,
      city: 'Hermon',
      state: 'NY',
    },
    13654: {
      lat: 44.56654,
      lng: -75.46067,
      city: 'Heuvelton',
      state: 'NY',
    },
    13655: {
      lat: 44.97865,
      lng: -74.64781,
      city: 'Hogansburg',
      state: 'NY',
    },
    13656: {
      lat: 44.19141,
      lng: -75.95058,
      city: 'La Fargeville',
      state: 'NY',
    },
    13658: {
      lat: 44.73979,
      lng: -75.27756,
      city: 'Lisbon',
      state: 'NY',
    },
    13659: {
      lat: 43.73783,
      lng: -75.8707,
      city: 'Lorraine',
      state: 'NY',
    },
    13660: {
      lat: 44.77727,
      lng: -75.15764,
      city: 'Madrid',
      state: 'NY',
    },
    13661: {
      lat: 43.70924,
      lng: -76.1054,
      city: 'Mannsville',
      state: 'NY',
    },
    13662: {
      lat: 44.9378,
      lng: -74.89383,
      city: 'Massena',
      state: 'NY',
    },
    13664: {
      lat: 44.58423,
      lng: -75.64537,
      city: 'Morristown',
      state: 'NY',
    },
    13665: {
      lat: 44.05227,
      lng: -75.44933,
      city: 'Natural Bridge',
      state: 'NY',
    },
    13666: {
      lat: 44.21404,
      lng: -74.95557,
      city: 'Newton Falls',
      state: 'NY',
    },
    13667: {
      lat: 44.83768,
      lng: -74.96149,
      city: 'Norfolk',
      state: 'NY',
    },
    13668: {
      lat: 44.75432,
      lng: -74.98764,
      city: 'Norwood',
      state: 'NY',
    },
    13669: {
      lat: 44.65004,
      lng: -75.49861,
      city: 'Ogdensburg',
      state: 'NY',
    },
    13670: {
      lat: 44.18264,
      lng: -75.11635,
      city: 'Oswegatchie',
      state: 'NY',
    },
    13672: {
      lat: 44.48166,
      lng: -74.67987,
      city: 'Parishville',
      state: 'NY',
    },
    13673: {
      lat: 44.17105,
      lng: -75.72133,
      city: 'Philadelphia',
      state: 'NY',
    },
    13674: {
      lat: 43.73763,
      lng: -76.05192,
      city: 'Pierrepont Manor',
      state: 'NY',
    },
    13675: {
      lat: 44.28227,
      lng: -75.83872,
      city: 'Plessis',
      state: 'NY',
    },
    13676: {
      lat: 44.65178,
      lng: -74.91377,
      city: 'Potsdam',
      state: 'NY',
    },
    13678: {
      lat: 44.83165,
      lng: -74.96775,
      city: 'Raymondville',
      state: 'NY',
    },
    13679: {
      lat: 44.32664,
      lng: -75.76554,
      city: 'Redwood',
      state: 'NY',
    },
    13680: {
      lat: 44.59416,
      lng: -75.32814,
      city: 'Rensselaer Falls',
      state: 'NY',
    },
    13681: {
      lat: 44.42933,
      lng: -75.37142,
      city: 'Richville',
      state: 'NY',
    },
    13682: {
      lat: 43.84024,
      lng: -75.88924,
      city: 'Rodman',
      state: 'NY',
    },
    13683: {
      lat: 44.97395,
      lng: -74.73543,
      city: 'Rooseveltown',
      state: 'NY',
    },
    13684: {
      lat: 44.36577,
      lng: -75.0464,
      city: 'Russell',
      state: 'NY',
    },
    13685: {
      lat: 43.93343,
      lng: -76.09399,
      city: 'Sackets Harbor',
      state: 'NY',
    },
    13687: {
      lat: 44.44744,
      lng: -74.82516,
      city: 'South Colton',
      state: 'NY',
    },
    13690: {
      lat: 44.12612,
      lng: -75.02319,
      city: 'Star Lake',
      state: 'NY',
    },
    13691: {
      lat: 44.22747,
      lng: -75.78208,
      city: 'Theresa',
      state: 'NY',
    },
    13692: {
      lat: 44.28861,
      lng: -76.0258,
      city: 'Thousand Island Park',
      state: 'NY',
    },
    13693: {
      lat: 44.03237,
      lng: -76.24824,
      city: 'Three Mile Bay',
      state: 'NY',
    },
    13694: {
      lat: 44.8575,
      lng: -75.1645,
      city: 'Waddington',
      state: 'NY',
    },
    13695: {
      lat: 44.10505,
      lng: -74.92872,
      city: 'Wanakena',
      state: 'NY',
    },
    13696: {
      lat: 44.70425,
      lng: -74.88971,
      city: 'West Stockholm',
      state: 'NY',
    },
    13697: {
      lat: 44.74841,
      lng: -74.80656,
      city: 'Winthrop',
      state: 'NY',
    },
    13699: {
      lat: 44.66372,
      lng: -74.99851,
      city: 'Potsdam',
      state: 'NY',
    },
    13730: {
      lat: 42.23201,
      lng: -75.53127,
      city: 'Afton',
      state: 'NY',
    },
    13731: { lat: 42.13059, lng: -74.7882, city: 'Andes', state: 'NY' },
    13732: {
      lat: 42.04396,
      lng: -76.16772,
      city: 'Apalachin',
      state: 'NY',
    },
    13733: {
      lat: 42.30459,
      lng: -75.4793,
      city: 'Bainbridge',
      state: 'NY',
    },
    13734: {
      lat: 42.07752,
      lng: -76.40864,
      city: 'Barton',
      state: 'NY',
    },
    13736: {
      lat: 42.31722,
      lng: -76.19389,
      city: 'Berkshire',
      state: 'NY',
    },
    13738: {
      lat: 42.56236,
      lng: -76.11986,
      city: 'Blodgett Mills',
      state: 'NY',
    },
    13739: {
      lat: 42.36614,
      lng: -74.78982,
      city: 'Bloomville',
      state: 'NY',
    },
    13740: {
      lat: 42.27535,
      lng: -74.74498,
      city: 'Bovina Center',
      state: 'NY',
    },
    13743: {
      lat: 42.21706,
      lng: -76.33898,
      city: 'Candor',
      state: 'NY',
    },
    13744: {
      lat: 42.24078,
      lng: -75.90629,
      city: 'Castle Creek',
      state: 'NY',
    },
    13746: {
      lat: 42.26868,
      lng: -75.82085,
      city: 'Chenango Forks',
      state: 'NY',
    },
    13747: {
      lat: 42.49003,
      lng: -74.98209,
      city: 'Colliersville',
      state: 'NY',
    },
    13748: {
      lat: 42.03428,
      lng: -75.81727,
      city: 'Conklin',
      state: 'NY',
    },
    13750: {
      lat: 42.47739,
      lng: -74.83904,
      city: 'Davenport',
      state: 'NY',
    },
    13751: {
      lat: 42.45745,
      lng: -74.88363,
      city: 'Davenport Center',
      state: 'NY',
    },
    13752: {
      lat: 42.17257,
      lng: -74.90379,
      city: 'Delancey',
      state: 'NY',
    },
    13753: {
      lat: 42.30873,
      lng: -74.92538,
      city: 'Delhi',
      state: 'NY',
    },
    13754: {
      lat: 42.09387,
      lng: -75.43625,
      city: 'Deposit',
      state: 'NY',
    },
    13755: {
      lat: 42.05976,
      lng: -75.01524,
      city: 'Downsville',
      state: 'NY',
    },
    13756: {
      lat: 42.01025,
      lng: -75.10394,
      city: 'East Branch',
      state: 'NY',
    },
    13757: {
      lat: 42.40915,
      lng: -74.89321,
      city: 'East Meredith',
      state: 'NY',
    },
    13760: {
      lat: 42.13425,
      lng: -76.08354,
      city: 'Endicott',
      state: 'NY',
    },
    13774: {
      lat: 41.96192,
      lng: -75.17283,
      city: 'Fishs Eddy',
      state: 'NY',
    },
    13775: {
      lat: 42.33706,
      lng: -75.14728,
      city: 'Franklin',
      state: 'NY',
    },
    13776: {
      lat: 42.47023,
      lng: -75.33458,
      city: 'Gilbertsville',
      state: 'NY',
    },
    13777: {
      lat: 42.25894,
      lng: -75.9866,
      city: 'Glen Aubrey',
      state: 'NY',
    },
    13778: {
      lat: 42.34795,
      lng: -75.75977,
      city: 'Greene',
      state: 'NY',
    },
    13780: {
      lat: 42.42496,
      lng: -75.48128,
      city: 'Guilford',
      state: 'NY',
    },
    13782: {
      lat: 42.17304,
      lng: -74.9836,
      city: 'Hamden',
      state: 'NY',
    },
    13783: {
      lat: 41.99037,
      lng: -75.26764,
      city: 'Hancock',
      state: 'NY',
    },
    13784: {
      lat: 42.43542,
      lng: -76.2177,
      city: 'Harford',
      state: 'NY',
    },
    13786: {
      lat: 42.4485,
      lng: -74.69563,
      city: 'Harpersfield',
      state: 'NY',
    },
    13787: {
      lat: 42.20606,
      lng: -75.66334,
      city: 'Harpursville',
      state: 'NY',
    },
    13788: {
      lat: 42.34914,
      lng: -74.65658,
      city: 'Hobart',
      state: 'NY',
    },
    13790: {
      lat: 42.16937,
      lng: -75.99796,
      city: 'Johnson City',
      state: 'NY',
    },
    13795: {
      lat: 42.05844,
      lng: -75.77419,
      city: 'Kirkwood',
      state: 'NY',
    },
    13796: {
      lat: 42.5513,
      lng: -75.13644,
      city: 'Laurens',
      state: 'NY',
    },
    13797: {
      lat: 42.32765,
      lng: -76.04389,
      city: 'Lisle',
      state: 'NY',
    },
    13801: {
      lat: 42.50327,
      lng: -75.78028,
      city: 'McDonough',
      state: 'NY',
    },
    13802: {
      lat: 42.25164,
      lng: -76.04207,
      city: 'Maine',
      state: 'NY',
    },
    13803: {
      lat: 42.45787,
      lng: -76.06028,
      city: 'Marathon',
      state: 'NY',
    },
    13804: {
      lat: 42.20708,
      lng: -75.37562,
      city: 'Masonville',
      state: 'NY',
    },
    13806: {
      lat: 42.37894,
      lng: -74.97315,
      city: 'Meridale',
      state: 'NY',
    },
    13807: {
      lat: 42.61718,
      lng: -74.98612,
      city: 'Milford',
      state: 'NY',
    },
    13808: {
      lat: 42.52797,
      lng: -75.25657,
      city: 'Morris',
      state: 'NY',
    },
    13809: {
      lat: 42.40849,
      lng: -75.3914,
      city: 'Mount Upton',
      state: 'NY',
    },
    13810: {
      lat: 42.61188,
      lng: -75.11157,
      city: 'Mount Vision',
      state: 'NY',
    },
    13811: {
      lat: 42.23099,
      lng: -76.17481,
      city: 'Newark Valley',
      state: 'NY',
    },
    13812: {
      lat: 42.02993,
      lng: -76.35461,
      city: 'Nichols',
      state: 'NY',
    },
    13813: {
      lat: 42.16808,
      lng: -75.54266,
      city: 'Nineveh',
      state: 'NY',
    },
    13814: {
      lat: 42.61655,
      lng: -75.52584,
      city: 'North Norwich',
      state: 'NY',
    },
    13815: {
      lat: 42.54712,
      lng: -75.53413,
      city: 'Norwich',
      state: 'NY',
    },
    13820: {
      lat: 42.47204,
      lng: -75.03893,
      city: 'Oneonta',
      state: 'NY',
    },
    13825: {
      lat: 42.44329,
      lng: -75.20154,
      city: 'Otego',
      state: 'NY',
    },
    13826: {
      lat: 42.10517,
      lng: -75.63701,
      city: 'Ouaquaga',
      state: 'NY',
    },
    13827: {
      lat: 42.11178,
      lng: -76.25216,
      city: 'Owego',
      state: 'NY',
    },
    13830: {
      lat: 42.43868,
      lng: -75.6295,
      city: 'Oxford',
      state: 'NY',
    },
    13832: {
      lat: 42.6531,
      lng: -75.66286,
      city: 'Plymouth',
      state: 'NY',
    },
    13833: {
      lat: 42.19829,
      lng: -75.76432,
      city: 'Port Crane',
      state: 'NY',
    },
    13834: {
      lat: 42.54903,
      lng: -74.96727,
      city: 'Portlandville',
      state: 'NY',
    },
    13835: {
      lat: 42.38906,
      lng: -76.16939,
      city: 'Richford',
      state: 'NY',
    },
    13838: {
      lat: 42.28423,
      lng: -75.39595,
      city: 'Sidney',
      state: 'NY',
    },
    13839: {
      lat: 42.24824,
      lng: -75.26007,
      city: 'Sidney Center',
      state: 'NY',
    },
    13841: {
      lat: 42.41408,
      lng: -75.84171,
      city: 'Smithville Flats',
      state: 'NY',
    },
    13842: {
      lat: 42.37922,
      lng: -74.72302,
      city: 'South Kortright',
      state: 'NY',
    },
    13843: {
      lat: 42.53468,
      lng: -75.37584,
      city: 'South New Berlin',
      state: 'NY',
    },
    13844: {
      lat: 42.60927,
      lng: -75.68083,
      city: 'South Plymouth',
      state: 'NY',
    },
    13846: {
      lat: 42.36037,
      lng: -75.05465,
      city: 'Treadwell',
      state: 'NY',
    },
    13847: {
      lat: 42.20443,
      lng: -75.27777,
      city: 'Trout Creek',
      state: 'NY',
    },
    13849: {
      lat: 42.33785,
      lng: -75.31392,
      city: 'Unadilla',
      state: 'NY',
    },
    13850: {
      lat: 42.04873,
      lng: -76.02663,
      city: 'Vestal',
      state: 'NY',
    },
    13856: {
      lat: 42.16666,
      lng: -75.16633,
      city: 'Walton',
      state: 'NY',
    },
    13859: {
      lat: 42.37123,
      lng: -75.24916,
      city: 'Wells Bridge',
      state: 'NY',
    },
    13860: {
      lat: 42.45573,
      lng: -74.94989,
      city: 'West Davenport',
      state: 'NY',
    },
    13861: {
      lat: 42.5048,
      lng: -75.14156,
      city: 'West Oneonta',
      state: 'NY',
    },
    13862: {
      lat: 42.3369,
      lng: -75.9372,
      city: 'Whitney Point',
      state: 'NY',
    },
    13863: { lat: 42.44164, lng: -75.904, city: 'Willet', state: 'NY' },
    13864: {
      lat: 42.29095,
      lng: -76.39225,
      city: 'Willseyville',
      state: 'NY',
    },
    13865: {
      lat: 42.06282,
      lng: -75.62879,
      city: 'Windsor',
      state: 'NY',
    },
    13901: {
      lat: 42.18253,
      lng: -75.88048,
      city: 'Binghamton',
      state: 'NY',
    },
    13902: {
      lat: 42.08895,
      lng: -75.96891,
      city: 'Binghamton',
      state: 'NY',
    },
    13903: {
      lat: 42.04749,
      lng: -75.89573,
      city: 'Binghamton',
      state: 'NY',
    },
    13904: {
      lat: 42.13072,
      lng: -75.81587,
      city: 'Binghamton',
      state: 'NY',
    },
    13905: {
      lat: 42.18086,
      lng: -75.94476,
      city: 'Binghamton',
      state: 'NY',
    },
    14001: {
      lat: 43.03149,
      lng: -78.51154,
      city: 'Akron',
      state: 'NY',
    },
    14004: {
      lat: 42.89487,
      lng: -78.51008,
      city: 'Alden',
      state: 'NY',
    },
    14005: {
      lat: 42.91788,
      lng: -78.24703,
      city: 'Alexander',
      state: 'NY',
    },
    14006: {
      lat: 42.63366,
      lng: -79.02056,
      city: 'Angola',
      state: 'NY',
    },
    14008: {
      lat: 43.30792,
      lng: -78.62728,
      city: 'Appleton',
      state: 'NY',
    },
    14009: {
      lat: 42.57542,
      lng: -78.39241,
      city: 'Arcade',
      state: 'NY',
    },
    14011: {
      lat: 42.83158,
      lng: -78.29784,
      city: 'Attica',
      state: 'NY',
    },
    14012: {
      lat: 43.33431,
      lng: -78.52572,
      city: 'Barker',
      state: 'NY',
    },
    14013: {
      lat: 43.07639,
      lng: -78.39704,
      city: 'Basom',
      state: 'NY',
    },
    14020: {
      lat: 42.99513,
      lng: -78.21508,
      city: 'Batavia',
      state: 'NY',
    },
    14024: {
      lat: 42.58111,
      lng: -78.24431,
      city: 'Bliss',
      state: 'NY',
    },
    14025: {
      lat: 42.6232,
      lng: -78.72609,
      city: 'Boston',
      state: 'NY',
    },
    14026: {
      lat: 42.94212,
      lng: -78.68794,
      city: 'Bowmansville',
      state: 'NY',
    },
    14027: {
      lat: 42.59408,
      lng: -79.02067,
      city: 'Brant',
      state: 'NY',
    },
    14028: { lat: 43.31498, lng: -78.71664, city: 'Burt', state: 'NY' },
    14029: {
      lat: 42.47293,
      lng: -78.26346,
      city: 'Centerville',
      state: 'NY',
    },
    14030: {
      lat: 42.56518,
      lng: -78.50052,
      city: 'Chaffee',
      state: 'NY',
    },
    14031: {
      lat: 42.98661,
      lng: -78.60908,
      city: 'Clarence',
      state: 'NY',
    },
    14032: {
      lat: 43.04587,
      lng: -78.63189,
      city: 'Clarence Center',
      state: 'NY',
    },
    14033: {
      lat: 42.65865,
      lng: -78.68436,
      city: 'Colden',
      state: 'NY',
    },
    14034: {
      lat: 42.4985,
      lng: -78.86238,
      city: 'Collins',
      state: 'NY',
    },
    14035: {
      lat: 42.49099,
      lng: -78.85936,
      city: 'Collins Center',
      state: 'NY',
    },
    14036: {
      lat: 42.97447,
      lng: -78.38712,
      city: 'Corfu',
      state: 'NY',
    },
    14037: {
      lat: 42.80637,
      lng: -78.45448,
      city: 'Cowlesville',
      state: 'NY',
    },
    14039: { lat: 42.83593, lng: -78.17101, city: 'Dale', state: 'NY' },
    14040: {
      lat: 42.88809,
      lng: -78.37847,
      city: 'Darien Center',
      state: 'NY',
    },
    14041: {
      lat: 42.4059,
      lng: -78.97699,
      city: 'Dayton',
      state: 'NY',
    },
    14042: {
      lat: 42.46882,
      lng: -78.48377,
      city: 'Delevan',
      state: 'NY',
    },
    14043: {
      lat: 42.90142,
      lng: -78.70431,
      city: 'Depew',
      state: 'NY',
    },
    14047: {
      lat: 42.68579,
      lng: -78.99328,
      city: 'Derby',
      state: 'NY',
    },
    14048: {
      lat: 42.48087,
      lng: -79.31128,
      city: 'Dunkirk',
      state: 'NY',
    },
    14051: {
      lat: 43.04168,
      lng: -78.69927,
      city: 'East Amherst',
      state: 'NY',
    },
    14052: {
      lat: 42.77054,
      lng: -78.58084,
      city: 'East Aurora',
      state: 'NY',
    },
    14054: {
      lat: 42.90606,
      lng: -78.1312,
      city: 'East Bethany',
      state: 'NY',
    },
    14055: {
      lat: 42.5536,
      lng: -78.60121,
      city: 'East Concord',
      state: 'NY',
    },
    14056: {
      lat: 42.99183,
      lng: -78.30663,
      city: 'East Pembroke',
      state: 'NY',
    },
    14057: { lat: 42.64587, lng: -78.87542, city: 'Eden', state: 'NY' },
    14058: { lat: 43.10167, lng: -78.16211, city: 'Elba', state: 'NY' },
    14059: { lat: 42.83241, lng: -78.63678, city: 'Elma', state: 'NY' },
    14060: {
      lat: 42.43986,
      lng: -78.30884,
      city: 'Farmersville Station',
      state: 'NY',
    },
    14061: {
      lat: 42.59111,
      lng: -79.08949,
      city: 'Farnham',
      state: 'NY',
    },
    14062: {
      lat: 42.44364,
      lng: -79.14897,
      city: 'Forestville',
      state: 'NY',
    },
    14063: {
      lat: 42.41451,
      lng: -79.32518,
      city: 'Fredonia',
      state: 'NY',
    },
    14065: {
      lat: 42.48741,
      lng: -78.31158,
      city: 'Freedom',
      state: 'NY',
    },
    14066: {
      lat: 42.62381,
      lng: -78.18051,
      city: 'Gainesville',
      state: 'NY',
    },
    14067: {
      lat: 43.21565,
      lng: -78.56644,
      city: 'Gasport',
      state: 'NY',
    },
    14068: {
      lat: 43.02696,
      lng: -78.75808,
      city: 'Getzville',
      state: 'NY',
    },
    14069: {
      lat: 42.59984,
      lng: -78.62984,
      city: 'Glenwood',
      state: 'NY',
    },
    14070: {
      lat: 42.43673,
      lng: -78.92798,
      city: 'Gowanda',
      state: 'NY',
    },
    14072: {
      lat: 43.01673,
      lng: -78.96308,
      city: 'Grand Island',
      state: 'NY',
    },
    14075: {
      lat: 42.71297,
      lng: -78.82985,
      city: 'Hamburg',
      state: 'NY',
    },
    14080: {
      lat: 42.64289,
      lng: -78.54886,
      city: 'Holland',
      state: 'NY',
    },
    14081: {
      lat: 42.56332,
      lng: -79.06528,
      city: 'Irving',
      state: 'NY',
    },
    14082: {
      lat: 42.65689,
      lng: -78.38435,
      city: 'Java Center',
      state: 'NY',
    },
    14083: {
      lat: 42.67921,
      lng: -78.43446,
      city: 'Java Village',
      state: 'NY',
    },
    14085: {
      lat: 42.71494,
      lng: -78.92691,
      city: 'Lake View',
      state: 'NY',
    },
    14086: {
      lat: 42.90661,
      lng: -78.6291,
      city: 'Lancaster',
      state: 'NY',
    },
    14091: {
      lat: 42.54224,
      lng: -78.88868,
      city: 'Lawtons',
      state: 'NY',
    },
    14092: {
      lat: 43.17314,
      lng: -78.99521,
      city: 'Lewiston',
      state: 'NY',
    },
    14094: {
      lat: 43.15989,
      lng: -78.70032,
      city: 'Lockport',
      state: 'NY',
    },
    14098: {
      lat: 43.33464,
      lng: -78.37827,
      city: 'Lyndonville',
      state: 'NY',
    },
    14101: {
      lat: 42.3863,
      lng: -78.54292,
      city: 'Machias',
      state: 'NY',
    },
    14102: {
      lat: 42.84272,
      lng: -78.55481,
      city: 'Marilla',
      state: 'NY',
    },
    14103: {
      lat: 43.2125,
      lng: -78.37696,
      city: 'Medina',
      state: 'NY',
    },
    14105: {
      lat: 43.19043,
      lng: -78.4813,
      city: 'Middleport',
      state: 'NY',
    },
    14108: {
      lat: 43.26365,
      lng: -78.7274,
      city: 'Newfane',
      state: 'NY',
    },
    14109: {
      lat: 43.13718,
      lng: -79.03516,
      city: 'Niagara University',
      state: 'NY',
    },
    14111: {
      lat: 42.58206,
      lng: -78.89803,
      city: 'North Collins',
      state: 'NY',
    },
    14112: {
      lat: 42.6981,
      lng: -78.9395,
      city: 'North Evans',
      state: 'NY',
    },
    14113: {
      lat: 42.66813,
      lng: -78.33624,
      city: 'North Java',
      state: 'NY',
    },
    14120: {
      lat: 43.07337,
      lng: -78.83585,
      city: 'North Tonawanda',
      state: 'NY',
    },
    14125: {
      lat: 43.08773,
      lng: -78.28207,
      city: 'Oakfield',
      state: 'NY',
    },
    14126: {
      lat: 43.33529,
      lng: -78.72335,
      city: 'Olcott',
      state: 'NY',
    },
    14127: {
      lat: 42.75204,
      lng: -78.74402,
      city: 'Orchard Park',
      state: 'NY',
    },
    14129: {
      lat: 42.4805,
      lng: -79.0092,
      city: 'Perrysburg',
      state: 'NY',
    },
    14130: { lat: 42.55318, lng: -78.14959, city: 'Pike', state: 'NY' },
    14131: {
      lat: 43.23556,
      lng: -78.89935,
      city: 'Ransomville',
      state: 'NY',
    },
    14132: {
      lat: 43.14787,
      lng: -78.87856,
      city: 'Sanborn',
      state: 'NY',
    },
    14134: {
      lat: 42.53057,
      lng: -78.51738,
      city: 'Sardinia',
      state: 'NY',
    },
    14135: {
      lat: 42.48489,
      lng: -79.2437,
      city: 'Sheridan',
      state: 'NY',
    },
    14136: {
      lat: 42.52387,
      lng: -79.16935,
      city: 'Silver Creek',
      state: 'NY',
    },
    14138: {
      lat: 42.37497,
      lng: -79.05376,
      city: 'South Dayton',
      state: 'NY',
    },
    14139: {
      lat: 42.71723,
      lng: -78.53606,
      city: 'South Wales',
      state: 'NY',
    },
    14141: {
      lat: 42.52366,
      lng: -78.7109,
      city: 'Springville',
      state: 'NY',
    },
    14143: {
      lat: 42.97547,
      lng: -78.0759,
      city: 'Stafford',
      state: 'NY',
    },
    14145: {
      lat: 42.73154,
      lng: -78.43205,
      city: 'Strykersville',
      state: 'NY',
    },
    14150: {
      lat: 42.99794,
      lng: -78.87815,
      city: 'Tonawanda',
      state: 'NY',
    },
    14166: {
      lat: 42.45088,
      lng: -79.41592,
      city: 'Van Buren Point',
      state: 'NY',
    },
    14167: {
      lat: 42.74327,
      lng: -78.32629,
      city: 'Varysburg',
      state: 'NY',
    },
    14168: {
      lat: 42.51187,
      lng: -78.98745,
      city: 'Versailles',
      state: 'NY',
    },
    14169: {
      lat: 42.76446,
      lng: -78.52938,
      city: 'Wales Center',
      state: 'NY',
    },
    14170: {
      lat: 42.70115,
      lng: -78.67294,
      city: 'West Falls',
      state: 'NY',
    },
    14171: {
      lat: 42.42491,
      lng: -78.64286,
      city: 'West Valley',
      state: 'NY',
    },
    14172: {
      lat: 43.28201,
      lng: -78.81757,
      city: 'Wilson',
      state: 'NY',
    },
    14173: {
      lat: 42.52594,
      lng: -78.47306,
      city: 'Yorkshire',
      state: 'NY',
    },
    14174: {
      lat: 43.24178,
      lng: -79.00548,
      city: 'Youngstown',
      state: 'NY',
    },
    14201: {
      lat: 42.89706,
      lng: -78.88752,
      city: 'Buffalo',
      state: 'NY',
    },
    14202: {
      lat: 42.88798,
      lng: -78.88358,
      city: 'Buffalo',
      state: 'NY',
    },
    14203: {
      lat: 42.86244,
      lng: -78.86507,
      city: 'Buffalo',
      state: 'NY',
    },
    14204: { lat: 42.882, lng: -78.8614, city: 'Buffalo', state: 'NY' },
    14206: {
      lat: 42.88078,
      lng: -78.81018,
      city: 'Buffalo',
      state: 'NY',
    },
    14207: {
      lat: 42.95201,
      lng: -78.89824,
      city: 'Buffalo',
      state: 'NY',
    },
    14208: {
      lat: 42.9146,
      lng: -78.85146,
      city: 'Buffalo',
      state: 'NY',
    },
    14209: {
      lat: 42.91365,
      lng: -78.86622,
      city: 'Buffalo',
      state: 'NY',
    },
    14210: {
      lat: 42.86407,
      lng: -78.82811,
      city: 'Buffalo',
      state: 'NY',
    },
    14211: {
      lat: 42.90813,
      lng: -78.81883,
      city: 'Buffalo',
      state: 'NY',
    },
    14212: {
      lat: 42.89438,
      lng: -78.81957,
      city: 'Buffalo',
      state: 'NY',
    },
    14213: {
      lat: 42.91762,
      lng: -78.89099,
      city: 'Buffalo',
      state: 'NY',
    },
    14214: {
      lat: 42.93936,
      lng: -78.84101,
      city: 'Buffalo',
      state: 'NY',
    },
    14215: {
      lat: 42.93451,
      lng: -78.81023,
      city: 'Buffalo',
      state: 'NY',
    },
    14216: {
      lat: 42.94855,
      lng: -78.862,
      city: 'Buffalo',
      state: 'NY',
    },
    14217: {
      lat: 42.97189,
      lng: -78.87674,
      city: 'Buffalo',
      state: 'NY',
    },
    14218: {
      lat: 42.81921,
      lng: -78.82772,
      city: 'Buffalo',
      state: 'NY',
    },
    14219: {
      lat: 42.78903,
      lng: -78.82694,
      city: 'Buffalo',
      state: 'NY',
    },
    14220: {
      lat: 42.84571,
      lng: -78.82264,
      city: 'Buffalo',
      state: 'NY',
    },
    14221: {
      lat: 42.97918,
      lng: -78.72158,
      city: 'Buffalo',
      state: 'NY',
    },
    14222: {
      lat: 42.92002,
      lng: -78.8771,
      city: 'Buffalo',
      state: 'NY',
    },
    14223: {
      lat: 42.97348,
      lng: -78.84612,
      city: 'Buffalo',
      state: 'NY',
    },
    14224: {
      lat: 42.83767,
      lng: -78.74771,
      city: 'Buffalo',
      state: 'NY',
    },
    14225: {
      lat: 42.92879,
      lng: -78.752,
      city: 'Buffalo',
      state: 'NY',
    },
    14226: {
      lat: 42.97078,
      lng: -78.79844,
      city: 'Buffalo',
      state: 'NY',
    },
    14227: {
      lat: 42.88402,
      lng: -78.74665,
      city: 'Buffalo',
      state: 'NY',
    },
    14228: {
      lat: 43.04214,
      lng: -78.7798,
      city: 'Buffalo',
      state: 'NY',
    },
    14260: {
      lat: 43.00165,
      lng: -78.79066,
      city: 'Buffalo',
      state: 'NY',
    },
    14261: {
      lat: 43.00188,
      lng: -78.7848,
      city: 'Buffalo',
      state: 'NY',
    },
    14301: {
      lat: 43.09701,
      lng: -79.03726,
      city: 'Niagara Falls',
      state: 'NY',
    },
    14302: {
      lat: 43.0939,
      lng: -79.04919,
      city: 'Niagara Falls',
      state: 'NY',
    },
    14303: {
      lat: 43.08428,
      lng: -79.03908,
      city: 'Niagara Falls',
      state: 'NY',
    },
    14304: {
      lat: 43.10197,
      lng: -78.95282,
      city: 'Niagara Falls',
      state: 'NY',
    },
    14305: {
      lat: 43.1199,
      lng: -79.02265,
      city: 'Niagara Falls',
      state: 'NY',
    },
    14411: {
      lat: 43.23456,
      lng: -78.21349,
      city: 'Albion',
      state: 'NY',
    },
    14413: { lat: 43.2229, lng: -76.97067, city: 'Alton', state: 'NY' },
    14414: { lat: 42.89358, lng: -77.73501, city: 'Avon', state: 'NY' },
    14415: {
      lat: 42.7559,
      lng: -77.01643,
      city: 'Bellona',
      state: 'NY',
    },
    14416: {
      lat: 43.08003,
      lng: -77.97849,
      city: 'Bergen',
      state: 'NY',
    },
    14418: {
      lat: 42.60717,
      lng: -77.21681,
      city: 'Branchport',
      state: 'NY',
    },
    14420: {
      lat: 43.21385,
      lng: -77.93305,
      city: 'Brockport',
      state: 'NY',
    },
    14422: {
      lat: 43.08357,
      lng: -78.06704,
      city: 'Byron',
      state: 'NY',
    },
    14423: {
      lat: 42.94382,
      lng: -77.82788,
      city: 'Caledonia',
      state: 'NY',
    },
    14424: {
      lat: 42.84649,
      lng: -77.3104,
      city: 'Canandaigua',
      state: 'NY',
    },
    14425: {
      lat: 42.98962,
      lng: -77.33734,
      city: 'Farmington',
      state: 'NY',
    },
    14427: {
      lat: 42.61828,
      lng: -78.05442,
      city: 'Castile',
      state: 'NY',
    },
    14428: {
      lat: 43.08029,
      lng: -77.85963,
      city: 'Churchville',
      state: 'NY',
    },
    14432: {
      lat: 42.95696,
      lng: -77.14333,
      city: 'Clifton Springs',
      state: 'NY',
    },
    14433: {
      lat: 43.07939,
      lng: -76.87779,
      city: 'Clyde',
      state: 'NY',
    },
    14435: {
      lat: 42.71496,
      lng: -77.66721,
      city: 'Conesus',
      state: 'NY',
    },
    14437: {
      lat: 42.57072,
      lng: -77.73435,
      city: 'Dansville',
      state: 'NY',
    },
    14441: {
      lat: 42.68608,
      lng: -76.95756,
      city: 'Dresden',
      state: 'NY',
    },
    14445: {
      lat: 43.11312,
      lng: -77.48978,
      city: 'East Rochester',
      state: 'NY',
    },
    14450: {
      lat: 43.0921,
      lng: -77.41623,
      city: 'Fairport',
      state: 'NY',
    },
    14453: {
      lat: 43.01602,
      lng: -77.47338,
      city: 'Fishers',
      state: 'NY',
    },
    14454: {
      lat: 42.79731,
      lng: -77.76879,
      city: 'Geneseo',
      state: 'NY',
    },
    14456: {
      lat: 42.84584,
      lng: -77.00012,
      city: 'Geneva',
      state: 'NY',
    },
    14462: {
      lat: 42.68652,
      lng: -77.75112,
      city: 'Groveland',
      state: 'NY',
    },
    14463: { lat: 42.79539, lng: -77.0689, city: 'Hall', state: 'NY' },
    14464: {
      lat: 43.32659,
      lng: -77.9374,
      city: 'Hamlin',
      state: 'NY',
    },
    14466: {
      lat: 42.77773,
      lng: -77.5842,
      city: 'Hemlock',
      state: 'NY',
    },
    14467: {
      lat: 43.04119,
      lng: -77.60996,
      city: 'Henrietta',
      state: 'NY',
    },
    14468: {
      lat: 43.29242,
      lng: -77.80542,
      city: 'Hilton',
      state: 'NY',
    },
    14469: {
      lat: 42.87778,
      lng: -77.46735,
      city: 'Bloomfield',
      state: 'NY',
    },
    14470: {
      lat: 43.21099,
      lng: -78.05456,
      city: 'Holley',
      state: 'NY',
    },
    14471: {
      lat: 42.75308,
      lng: -77.49425,
      city: 'Honeoye',
      state: 'NY',
    },
    14472: {
      lat: 42.96589,
      lng: -77.58173,
      city: 'Honeoye Falls',
      state: 'NY',
    },
    14475: {
      lat: 42.93683,
      lng: -77.49787,
      city: 'Ionia',
      state: 'NY',
    },
    14476: {
      lat: 43.32946,
      lng: -78.04226,
      city: 'Kendall',
      state: 'NY',
    },
    14477: { lat: 43.33353, lng: -78.13415, city: 'Kent', state: 'NY' },
    14478: {
      lat: 42.58011,
      lng: -77.12791,
      city: 'Keuka Park',
      state: 'NY',
    },
    14479: {
      lat: 43.23768,
      lng: -78.31008,
      city: 'Knowlesville',
      state: 'NY',
    },
    14480: {
      lat: 42.83809,
      lng: -77.70736,
      city: 'Lakeville',
      state: 'NY',
    },
    14481: {
      lat: 42.77173,
      lng: -77.91643,
      city: 'Leicester',
      state: 'NY',
    },
    14482: {
      lat: 42.97457,
      lng: -77.97014,
      city: 'Le Roy',
      state: 'NY',
    },
    14485: { lat: 42.88016, lng: -77.60131, city: 'Lima', state: 'NY' },
    14486: {
      lat: 42.89293,
      lng: -77.92295,
      city: 'Linwood',
      state: 'NY',
    },
    14487: {
      lat: 42.81174,
      lng: -77.63873,
      city: 'Livonia',
      state: 'NY',
    },
    14489: {
      lat: 43.09008,
      lng: -76.99238,
      city: 'Lyons',
      state: 'NY',
    },
    14502: {
      lat: 43.09532,
      lng: -77.33598,
      city: 'Macedon',
      state: 'NY',
    },
    14504: {
      lat: 42.96839,
      lng: -77.2327,
      city: 'Manchester',
      state: 'NY',
    },
    14505: {
      lat: 43.15846,
      lng: -77.17503,
      city: 'Marion',
      state: 'NY',
    },
    14506: {
      lat: 43.0012,
      lng: -77.50508,
      city: 'Mendon',
      state: 'NY',
    },
    14507: {
      lat: 42.69635,
      lng: -77.27373,
      city: 'Middlesex',
      state: 'NY',
    },
    14510: {
      lat: 42.69485,
      lng: -77.87167,
      city: 'Mount Morris',
      state: 'NY',
    },
    14511: {
      lat: 42.99603,
      lng: -77.87762,
      city: 'Mumford',
      state: 'NY',
    },
    14512: {
      lat: 42.64411,
      lng: -77.39296,
      city: 'Naples',
      state: 'NY',
    },
    14513: {
      lat: 43.07204,
      lng: -77.0961,
      city: 'Newark',
      state: 'NY',
    },
    14514: {
      lat: 43.08597,
      lng: -77.80303,
      city: 'North Chili',
      state: 'NY',
    },
    14515: {
      lat: 43.25287,
      lng: -77.73175,
      city: 'North Greece',
      state: 'NY',
    },
    14516: {
      lat: 43.19855,
      lng: -76.91153,
      city: 'North Rose',
      state: 'NY',
    },
    14517: { lat: 42.59217, lng: -77.8971, city: 'Nunda', state: 'NY' },
    14519: {
      lat: 43.23184,
      lng: -77.31389,
      city: 'Ontario',
      state: 'NY',
    },
    14521: { lat: 42.67639, lng: -76.80876, city: 'Ovid', state: 'NY' },
    14522: {
      lat: 43.05911,
      lng: -77.22152,
      city: 'Palmyra',
      state: 'NY',
    },
    14525: {
      lat: 42.87242,
      lng: -78.01471,
      city: 'Pavilion',
      state: 'NY',
    },
    14526: {
      lat: 43.15129,
      lng: -77.44806,
      city: 'Penfield',
      state: 'NY',
    },
    14527: {
      lat: 42.66764,
      lng: -77.06473,
      city: 'Penn Yan',
      state: 'NY',
    },
    14529: {
      lat: 42.53748,
      lng: -77.64418,
      city: 'Perkinsville',
      state: 'NY',
    },
    14530: {
      lat: 42.73446,
      lng: -78.00276,
      city: 'Perry',
      state: 'NY',
    },
    14532: {
      lat: 42.96462,
      lng: -77.02601,
      city: 'Phelps',
      state: 'NY',
    },
    14533: {
      lat: 42.84632,
      lng: -77.88468,
      city: 'Piffard',
      state: 'NY',
    },
    14534: {
      lat: 43.0564,
      lng: -77.52083,
      city: 'Pittsford',
      state: 'NY',
    },
    14536: {
      lat: 42.53958,
      lng: -78.08358,
      city: 'Portageville',
      state: 'NY',
    },
    14537: {
      lat: 43.03539,
      lng: -77.15604,
      city: 'Port Gibson',
      state: 'NY',
    },
    14539: {
      lat: 42.83418,
      lng: -77.87521,
      city: 'Retsof',
      state: 'NY',
    },
    14541: {
      lat: 42.75704,
      lng: -76.84403,
      city: 'Romulus',
      state: 'NY',
    },
    14542: { lat: 43.15306, lng: -76.87595, city: 'Rose', state: 'NY' },
    14543: { lat: 42.98777, lng: -77.67747, city: 'Rush', state: 'NY' },
    14544: {
      lat: 42.75562,
      lng: -77.23958,
      city: 'Rushville',
      state: 'NY',
    },
    14545: {
      lat: 42.66166,
      lng: -77.71056,
      city: 'Scottsburg',
      state: 'NY',
    },
    14546: {
      lat: 43.0296,
      lng: -77.77372,
      city: 'Scottsville',
      state: 'NY',
    },
    14548: {
      lat: 42.97538,
      lng: -77.24341,
      city: 'Shortsville',
      state: 'NY',
    },
    14549: {
      lat: 42.69667,
      lng: -78.02212,
      city: 'Silver Lake',
      state: 'NY',
    },
    14550: {
      lat: 42.67757,
      lng: -78.09166,
      city: 'Silver Springs',
      state: 'NY',
    },
    14551: {
      lat: 43.21886,
      lng: -77.04596,
      city: 'Sodus',
      state: 'NY',
    },
    14555: {
      lat: 43.25946,
      lng: -76.98665,
      city: 'Sodus Point',
      state: 'NY',
    },
    14556: {
      lat: 42.67906,
      lng: -77.83222,
      city: 'Sonyea',
      state: 'NY',
    },
    14557: {
      lat: 43.04807,
      lng: -78.06126,
      city: 'South Byron',
      state: 'NY',
    },
    14559: {
      lat: 43.18823,
      lng: -77.81778,
      city: 'Spencerport',
      state: 'NY',
    },
    14560: {
      lat: 42.68716,
      lng: -77.57691,
      city: 'Springwater',
      state: 'NY',
    },
    14561: {
      lat: 42.82218,
      lng: -77.13131,
      city: 'Stanley',
      state: 'NY',
    },
    14564: {
      lat: 42.98329,
      lng: -77.42417,
      city: 'Victor',
      state: 'NY',
    },
    14568: {
      lat: 43.14657,
      lng: -77.28365,
      city: 'Walworth',
      state: 'NY',
    },
    14569: {
      lat: 42.74012,
      lng: -78.17269,
      city: 'Warsaw',
      state: 'NY',
    },
    14571: {
      lat: 43.34397,
      lng: -78.24894,
      city: 'Waterport',
      state: 'NY',
    },
    14572: {
      lat: 42.5669,
      lng: -77.57017,
      city: 'Wayland',
      state: 'NY',
    },
    14580: {
      lat: 43.21883,
      lng: -77.44561,
      city: 'Webster',
      state: 'NY',
    },
    14585: {
      lat: 42.90587,
      lng: -77.55301,
      city: 'West Bloomfield',
      state: 'NY',
    },
    14586: {
      lat: 43.04151,
      lng: -77.68693,
      city: 'West Henrietta',
      state: 'NY',
    },
    14588: {
      lat: 42.67973,
      lng: -76.87047,
      city: 'Willard',
      state: 'NY',
    },
    14589: {
      lat: 43.23997,
      lng: -77.17224,
      city: 'Williamson',
      state: 'NY',
    },
    14590: {
      lat: 43.24533,
      lng: -76.83099,
      city: 'Wolcott',
      state: 'NY',
    },
    14591: {
      lat: 42.82865,
      lng: -78.09364,
      city: 'Wyoming',
      state: 'NY',
    },
    14592: { lat: 42.86875, lng: -77.89066, city: 'York', state: 'NY' },
    14604: {
      lat: 43.1569,
      lng: -77.60536,
      city: 'Rochester',
      state: 'NY',
    },
    14605: {
      lat: 43.16838,
      lng: -77.60177,
      city: 'Rochester',
      state: 'NY',
    },
    14606: {
      lat: 43.17069,
      lng: -77.69933,
      city: 'Rochester',
      state: 'NY',
    },
    14607: {
      lat: 43.15099,
      lng: -77.58682,
      city: 'Rochester',
      state: 'NY',
    },
    14608: {
      lat: 43.15335,
      lng: -77.62362,
      city: 'Rochester',
      state: 'NY',
    },
    14609: {
      lat: 43.17731,
      lng: -77.55234,
      city: 'Rochester',
      state: 'NY',
    },
    14610: {
      lat: 43.14196,
      lng: -77.5463,
      city: 'Rochester',
      state: 'NY',
    },
    14611: {
      lat: 43.14756,
      lng: -77.64731,
      city: 'Rochester',
      state: 'NY',
    },
    14612: {
      lat: 43.26513,
      lng: -77.67654,
      city: 'Rochester',
      state: 'NY',
    },
    14613: {
      lat: 43.1822,
      lng: -77.64068,
      city: 'Rochester',
      state: 'NY',
    },
    14614: {
      lat: 43.1574,
      lng: -77.61473,
      city: 'Rochester',
      state: 'NY',
    },
    14615: {
      lat: 43.20337,
      lng: -77.65637,
      city: 'Rochester',
      state: 'NY',
    },
    14616: {
      lat: 43.23431,
      lng: -77.65822,
      city: 'Rochester',
      state: 'NY',
    },
    14617: {
      lat: 43.22488,
      lng: -77.59323,
      city: 'Rochester',
      state: 'NY',
    },
    14618: {
      lat: 43.11305,
      lng: -77.55547,
      city: 'Rochester',
      state: 'NY',
    },
    14619: {
      lat: 43.1361,
      lng: -77.649,
      city: 'Rochester',
      state: 'NY',
    },
    14620: {
      lat: 43.12878,
      lng: -77.60463,
      city: 'Rochester',
      state: 'NY',
    },
    14621: {
      lat: 43.18911,
      lng: -77.60408,
      city: 'Rochester',
      state: 'NY',
    },
    14622: {
      lat: 43.21389,
      lng: -77.55359,
      city: 'Rochester',
      state: 'NY',
    },
    14623: {
      lat: 43.08798,
      lng: -77.64314,
      city: 'Rochester',
      state: 'NY',
    },
    14624: {
      lat: 43.12765,
      lng: -77.7317,
      city: 'Rochester',
      state: 'NY',
    },
    14625: {
      lat: 43.14891,
      lng: -77.50551,
      city: 'Rochester',
      state: 'NY',
    },
    14626: {
      lat: 43.21348,
      lng: -77.71272,
      city: 'Rochester',
      state: 'NY',
    },
    14627: {
      lat: 43.12931,
      lng: -77.62912,
      city: 'Rochester',
      state: 'NY',
    },
    14642: {
      lat: 43.12281,
      lng: -77.62508,
      city: 'Rochester',
      state: 'NY',
    },
    14701: {
      lat: 42.08091,
      lng: -79.25502,
      city: 'Jamestown',
      state: 'NY',
    },
    14706: {
      lat: 42.10663,
      lng: -78.52795,
      city: 'Allegany',
      state: 'NY',
    },
    14707: {
      lat: 42.08256,
      lng: -78.06196,
      city: 'Allentown',
      state: 'NY',
    },
    14708: { lat: 42.01424, lng: -78.06988, city: 'Alma', state: 'NY' },
    14709: {
      lat: 42.35279,
      lng: -77.98267,
      city: 'Angelica',
      state: 'NY',
    },
    14710: {
      lat: 42.08957,
      lng: -79.42306,
      city: 'Ashville',
      state: 'NY',
    },
    14711: {
      lat: 42.32088,
      lng: -78.12625,
      city: 'Belfast',
      state: 'NY',
    },
    14712: {
      lat: 42.18163,
      lng: -79.36812,
      city: 'Bemus Point',
      state: 'NY',
    },
    14714: {
      lat: 42.28368,
      lng: -78.23493,
      city: 'Black Creek',
      state: 'NY',
    },
    14715: {
      lat: 42.07323,
      lng: -78.14832,
      city: 'Bolivar',
      state: 'NY',
    },
    14716: {
      lat: 42.38056,
      lng: -79.43406,
      city: 'Brocton',
      state: 'NY',
    },
    14717: {
      lat: 42.36258,
      lng: -78.18235,
      city: 'Caneadea',
      state: 'NY',
    },
    14718: {
      lat: 42.34417,
      lng: -79.28813,
      city: 'Cassadaga',
      state: 'NY',
    },
    14719: {
      lat: 42.34323,
      lng: -78.87198,
      city: 'Cattaraugus',
      state: 'NY',
    },
    14720: {
      lat: 42.10857,
      lng: -79.27997,
      city: 'Celoron',
      state: 'NY',
    },
    14721: {
      lat: 42.01645,
      lng: -78.26801,
      city: 'Ceres',
      state: 'NY',
    },
    14722: {
      lat: 42.20961,
      lng: -79.46882,
      city: 'Chautauqua',
      state: 'NY',
    },
    14723: {
      lat: 42.3101,
      lng: -79.13489,
      city: 'Cherry Creek',
      state: 'NY',
    },
    14724: {
      lat: 42.05272,
      lng: -79.67031,
      city: 'Clymer',
      state: 'NY',
    },
    14726: {
      lat: 42.25255,
      lng: -79.02221,
      city: 'Conewango Valley',
      state: 'NY',
    },
    14727: { lat: 42.2129, lng: -78.29358, city: 'Cuba', state: 'NY' },
    14728: {
      lat: 42.2648,
      lng: -79.41689,
      city: 'Dewittville',
      state: 'NY',
    },
    14729: {
      lat: 42.40404,
      lng: -78.73628,
      city: 'East Otto',
      state: 'NY',
    },
    14731: {
      lat: 42.30063,
      lng: -78.65092,
      city: 'Ellicottville',
      state: 'NY',
    },
    14732: {
      lat: 42.2188,
      lng: -79.10696,
      city: 'Ellington',
      state: 'NY',
    },
    14733: {
      lat: 42.14996,
      lng: -79.17499,
      city: 'Falconer',
      state: 'NY',
    },
    14735: {
      lat: 42.45027,
      lng: -78.09681,
      city: 'Fillmore',
      state: 'NY',
    },
    14736: {
      lat: 42.13715,
      lng: -79.74644,
      city: 'Findley Lake',
      state: 'NY',
    },
    14737: {
      lat: 42.33524,
      lng: -78.42926,
      city: 'Franklinville',
      state: 'NY',
    },
    14738: {
      lat: 42.04539,
      lng: -79.05926,
      city: 'Frewsburg',
      state: 'NY',
    },
    14739: {
      lat: 42.1925,
      lng: -78.14932,
      city: 'Friendship',
      state: 'NY',
    },
    14740: {
      lat: 42.21962,
      lng: -79.18053,
      city: 'Gerry',
      state: 'NY',
    },
    14741: {
      lat: 42.21868,
      lng: -78.5963,
      city: 'Great Valley',
      state: 'NY',
    },
    14742: {
      lat: 42.11934,
      lng: -79.30878,
      city: 'Greenhurst',
      state: 'NY',
    },
    14743: {
      lat: 42.20532,
      lng: -78.40631,
      city: 'Hinsdale',
      state: 'NY',
    },
    14744: {
      lat: 42.4219,
      lng: -78.21516,
      city: 'Houghton',
      state: 'NY',
    },
    14747: {
      lat: 42.15346,
      lng: -79.09061,
      city: 'Kennedy',
      state: 'NY',
    },
    14748: {
      lat: 42.1392,
      lng: -78.63636,
      city: 'Kill Buck',
      state: 'NY',
    },
    14750: {
      lat: 42.07939,
      lng: -79.32752,
      city: 'Lakewood',
      state: 'NY',
    },
    14752: {
      lat: 42.3517,
      lng: -79.31965,
      city: 'Lily Dale',
      state: 'NY',
    },
    14753: {
      lat: 42.03899,
      lng: -78.63516,
      city: 'Limestone',
      state: 'NY',
    },
    14754: {
      lat: 42.02385,
      lng: -78.19799,
      city: 'Little Genesee',
      state: 'NY',
    },
    14755: {
      lat: 42.23477,
      lng: -78.81398,
      city: 'Little Valley',
      state: 'NY',
    },
    14756: {
      lat: 42.19708,
      lng: -79.42373,
      city: 'Maple Springs',
      state: 'NY',
    },
    14757: {
      lat: 42.23944,
      lng: -79.50457,
      city: 'Mayville',
      state: 'NY',
    },
    14760: {
      lat: 42.07098,
      lng: -78.41421,
      city: 'Olean',
      state: 'NY',
    },
    14767: {
      lat: 42.05344,
      lng: -79.51583,
      city: 'Panama',
      state: 'NY',
    },
    14769: {
      lat: 42.36885,
      lng: -79.47413,
      city: 'Portland',
      state: 'NY',
    },
    14770: {
      lat: 42.0468,
      lng: -78.29963,
      city: 'Portville',
      state: 'NY',
    },
    14772: {
      lat: 42.15383,
      lng: -78.95086,
      city: 'Randolph',
      state: 'NY',
    },
    14774: {
      lat: 42.08875,
      lng: -78.14658,
      city: 'Richburg',
      state: 'NY',
    },
    14775: {
      lat: 42.23198,
      lng: -79.69532,
      city: 'Ripley',
      state: 'NY',
    },
    14777: {
      lat: 42.38999,
      lng: -78.26167,
      city: 'Rushford',
      state: 'NY',
    },
    14778: {
      lat: 42.07759,
      lng: -78.4827,
      city: 'Saint Bonaventure',
      state: 'NY',
    },
    14779: {
      lat: 42.10703,
      lng: -78.76977,
      city: 'Salamanca',
      state: 'NY',
    },
    14781: {
      lat: 42.16402,
      lng: -79.604,
      city: 'Sherman',
      state: 'NY',
    },
    14782: {
      lat: 42.2665,
      lng: -79.2559,
      city: 'Sinclairville',
      state: 'NY',
    },
    14783: {
      lat: 42.06014,
      lng: -78.91816,
      city: 'Steamburg',
      state: 'NY',
    },
    14784: {
      lat: 42.31445,
      lng: -79.38028,
      city: 'Stockton',
      state: 'NY',
    },
    14785: { lat: 42.15572, lng: -79.40337, city: 'Stow', state: 'NY' },
    14787: {
      lat: 42.31342,
      lng: -79.5675,
      city: 'Westfield',
      state: 'NY',
    },
    14788: {
      lat: 42.06012,
      lng: -78.37661,
      city: 'Westons Mills',
      state: 'NY',
    },
    14801: {
      lat: 42.09718,
      lng: -77.28461,
      city: 'Addison',
      state: 'NY',
    },
    14802: {
      lat: 42.2537,
      lng: -77.78989,
      city: 'Alfred',
      state: 'NY',
    },
    14803: {
      lat: 42.25135,
      lng: -77.78808,
      city: 'Alfred Station',
      state: 'NY',
    },
    14804: {
      lat: 42.31587,
      lng: -77.8476,
      city: 'Almond',
      state: 'NY',
    },
    14805: {
      lat: 42.3563,
      lng: -76.72618,
      city: 'Alpine',
      state: 'NY',
    },
    14806: {
      lat: 42.15543,
      lng: -77.78662,
      city: 'Andover',
      state: 'NY',
    },
    14807: {
      lat: 42.41821,
      lng: -77.71949,
      city: 'Arkport',
      state: 'NY',
    },
    14808: {
      lat: 42.55817,
      lng: -77.46882,
      city: 'Atlanta',
      state: 'NY',
    },
    14809: {
      lat: 42.41961,
      lng: -77.44355,
      city: 'Avoca',
      state: 'NY',
    },
    14810: { lat: 42.34678, lng: -77.34599, city: 'Bath', state: 'NY' },
    14812: {
      lat: 42.29199,
      lng: -76.99413,
      city: 'Beaver Dams',
      state: 'NY',
    },
    14813: {
      lat: 42.24638,
      lng: -77.99209,
      city: 'Belmont',
      state: 'NY',
    },
    14814: {
      lat: 42.15525,
      lng: -76.95148,
      city: 'Big Flats',
      state: 'NY',
    },
    14815: {
      lat: 42.37335,
      lng: -77.09463,
      city: 'Bradford',
      state: 'NY',
    },
    14816: {
      lat: 42.19728,
      lng: -76.73161,
      city: 'Breesport',
      state: 'NY',
    },
    14817: {
      lat: 42.36295,
      lng: -76.34548,
      city: 'Brooktondale',
      state: 'NY',
    },
    14818: {
      lat: 42.44878,
      lng: -76.81688,
      city: 'Burdett',
      state: 'NY',
    },
    14819: {
      lat: 42.21924,
      lng: -77.43466,
      city: 'Cameron',
      state: 'NY',
    },
    14820: {
      lat: 42.18629,
      lng: -77.36258,
      city: 'Cameron Mills',
      state: 'NY',
    },
    14821: {
      lat: 42.23541,
      lng: -77.21623,
      city: 'Campbell',
      state: 'NY',
    },
    14822: {
      lat: 42.43522,
      lng: -77.84702,
      city: 'Canaseraga',
      state: 'NY',
    },
    14823: {
      lat: 42.23984,
      lng: -77.5722,
      city: 'Canisteo',
      state: 'NY',
    },
    14824: {
      lat: 42.26875,
      lng: -76.70881,
      city: 'Cayuta',
      state: 'NY',
    },
    14825: {
      lat: 42.05564,
      lng: -76.62116,
      city: 'Chemung',
      state: 'NY',
    },
    14826: {
      lat: 42.49354,
      lng: -77.49335,
      city: 'Cohocton',
      state: 'NY',
    },
    14827: {
      lat: 42.18069,
      lng: -77.14292,
      city: 'Coopers Plains',
      state: 'NY',
    },
    14830: {
      lat: 42.12927,
      lng: -77.02783,
      city: 'Corning',
      state: 'NY',
    },
    14836: {
      lat: 42.51526,
      lng: -77.91526,
      city: 'Dalton',
      state: 'NY',
    },
    14837: {
      lat: 42.49919,
      lng: -77.01668,
      city: 'Dundee',
      state: 'NY',
    },
    14838: { lat: 42.18951, lng: -76.66635, city: 'Erin', state: 'NY' },
    14839: {
      lat: 42.13961,
      lng: -77.64331,
      city: 'Greenwood',
      state: 'NY',
    },
    14840: {
      lat: 42.44119,
      lng: -77.18758,
      city: 'Hammondsport',
      state: 'NY',
    },
    14841: {
      lat: 42.52699,
      lng: -76.84249,
      city: 'Hector',
      state: 'NY',
    },
    14842: { lat: 42.59666, lng: -76.963, city: 'Himrod', state: 'NY' },
    14843: {
      lat: 42.32819,
      lng: -77.64371,
      city: 'Hornell',
      state: 'NY',
    },
    14845: {
      lat: 42.20225,
      lng: -76.8334,
      city: 'Horseheads',
      state: 'NY',
    },
    14846: { lat: 42.5301, lng: -78.00319, city: 'Hunt', state: 'NY' },
    14847: {
      lat: 42.6031,
      lng: -76.74047,
      city: 'Interlaken',
      state: 'NY',
    },
    14850: {
      lat: 42.43249,
      lng: -76.49891,
      city: 'Ithaca',
      state: 'NY',
    },
    14853: {
      lat: 42.44727,
      lng: -76.48149,
      city: 'Ithaca',
      state: 'NY',
    },
    14854: {
      lat: 42.51138,
      lng: -76.60874,
      city: 'Jacksonville',
      state: 'NY',
    },
    14855: {
      lat: 42.13978,
      lng: -77.50112,
      city: 'Jasper',
      state: 'NY',
    },
    14856: {
      lat: 42.37737,
      lng: -77.36944,
      city: 'Kanona',
      state: 'NY',
    },
    14858: {
      lat: 42.02909,
      lng: -77.13315,
      city: 'Lindley',
      state: 'NY',
    },
    14859: {
      lat: 42.12118,
      lng: -76.53985,
      city: 'Lockwood',
      state: 'NY',
    },
    14860: { lat: 42.59099, lng: -76.84201, city: 'Lodi', state: 'NY' },
    14861: { lat: 42.0914, lng: -76.6888, city: 'Lowman', state: 'NY' },
    14864: {
      lat: 42.2804,
      lng: -76.84205,
      city: 'Millport',
      state: 'NY',
    },
    14865: {
      lat: 42.35091,
      lng: -76.83178,
      city: 'Montour Falls',
      state: 'NY',
    },
    14867: {
      lat: 42.34243,
      lng: -76.61461,
      city: 'Newfield',
      state: 'NY',
    },
    14869: {
      lat: 42.36403,
      lng: -76.77052,
      city: 'Odessa',
      state: 'NY',
    },
    14870: {
      lat: 42.16816,
      lng: -77.13234,
      city: 'Painted Post',
      state: 'NY',
    },
    14871: {
      lat: 42.03838,
      lng: -76.91093,
      city: 'Pine City',
      state: 'NY',
    },
    14872: {
      lat: 42.24141,
      lng: -76.86473,
      city: 'Pine Valley',
      state: 'NY',
    },
    14873: {
      lat: 42.52181,
      lng: -77.29814,
      city: 'Prattsburgh',
      state: 'NY',
    },
    14874: {
      lat: 42.53231,
      lng: -77.17234,
      city: 'Pulteney',
      state: 'NY',
    },
    14877: {
      lat: 42.06181,
      lng: -77.68384,
      city: 'Rexville',
      state: 'NY',
    },
    14878: {
      lat: 42.44946,
      lng: -76.94401,
      city: 'Rock Stream',
      state: 'NY',
    },
    14879: {
      lat: 42.31521,
      lng: -77.19323,
      city: 'Savona',
      state: 'NY',
    },
    14880: { lat: 42.17363, lng: -77.97334, city: 'Scio', state: 'NY' },
    14881: {
      lat: 42.4017,
      lng: -76.35795,
      city: 'Slaterville Springs',
      state: 'NY',
    },
    14882: {
      lat: 42.5801,
      lng: -76.56576,
      city: 'Lansing',
      state: 'NY',
    },
    14883: {
      lat: 42.24056,
      lng: -76.482,
      city: 'Spencer',
      state: 'NY',
    },
    14884: {
      lat: 42.47613,
      lng: -77.88724,
      city: 'Swain',
      state: 'NY',
    },
    14885: {
      lat: 42.048,
      lng: -77.57117,
      city: 'Troupsburg',
      state: 'NY',
    },
    14886: {
      lat: 42.50408,
      lng: -76.68836,
      city: 'Trumansburg',
      state: 'NY',
    },
    14889: {
      lat: 42.21605,
      lng: -76.58138,
      city: 'Van Etten',
      state: 'NY',
    },
    14891: {
      lat: 42.37823,
      lng: -76.95035,
      city: 'Watkins Glen',
      state: 'NY',
    },
    14892: {
      lat: 42.0478,
      lng: -76.52804,
      city: 'Waverly',
      state: 'NY',
    },
    14893: {
      lat: 42.46952,
      lng: -77.11182,
      city: 'Wayne',
      state: 'NY',
    },
    14894: {
      lat: 42.02442,
      lng: -76.77185,
      city: 'Wellsburg',
      state: 'NY',
    },
    14895: {
      lat: 42.07772,
      lng: -77.93387,
      city: 'Wellsville',
      state: 'NY',
    },
    14897: {
      lat: 42.02949,
      lng: -77.79076,
      city: 'Whitesville',
      state: 'NY',
    },
    14898: {
      lat: 42.05729,
      lng: -77.43482,
      city: 'Woodhull',
      state: 'NY',
    },
    14901: {
      lat: 42.07363,
      lng: -76.74137,
      city: 'Elmira',
      state: 'NY',
    },
    14903: {
      lat: 42.12372,
      lng: -76.87771,
      city: 'Elmira',
      state: 'NY',
    },
    14904: {
      lat: 42.07007,
      lng: -76.80798,
      city: 'Elmira',
      state: 'NY',
    },
    14905: {
      lat: 42.08692,
      lng: -76.84284,
      city: 'Elmira',
      state: 'NY',
    },
    15001: {
      lat: 40.59161,
      lng: -80.32078,
      city: 'Aliquippa',
      state: 'PA',
    },
    15003: {
      lat: 40.60153,
      lng: -80.21415,
      city: 'Ambridge',
      state: 'PA',
    },
    15004: {
      lat: 40.34216,
      lng: -80.38289,
      city: 'Atlasburg',
      state: 'PA',
    },
    15005: {
      lat: 40.64431,
      lng: -80.17607,
      city: 'Baden',
      state: 'PA',
    },
    15006: {
      lat: 40.63351,
      lng: -79.87678,
      city: 'Bairdford',
      state: 'PA',
    },
    15007: {
      lat: 40.65128,
      lng: -79.93173,
      city: 'Bakerstown',
      state: 'PA',
    },
    15009: {
      lat: 40.69933,
      lng: -80.36464,
      city: 'Beaver',
      state: 'PA',
    },
    15010: {
      lat: 40.76829,
      lng: -80.35873,
      city: 'Beaver Falls',
      state: 'PA',
    },
    15012: {
      lat: 40.15871,
      lng: -79.81188,
      city: 'Belle Vernon',
      state: 'PA',
    },
    15014: {
      lat: 40.60859,
      lng: -79.74149,
      city: 'Brackenridge',
      state: 'PA',
    },
    15015: {
      lat: 40.63622,
      lng: -80.08164,
      city: 'Bradfordwoods',
      state: 'PA',
    },
    15017: {
      lat: 40.34348,
      lng: -80.12865,
      city: 'Bridgeville',
      state: 'PA',
    },
    15018: {
      lat: 40.26924,
      lng: -79.79805,
      city: 'Buena Vista',
      state: 'PA',
    },
    15019: {
      lat: 40.40805,
      lng: -80.33053,
      city: 'Bulger',
      state: 'PA',
    },
    15020: { lat: 40.2283, lng: -79.9479, city: 'Bunola', state: 'PA' },
    15021: {
      lat: 40.39373,
      lng: -80.43583,
      city: 'Burgettstown',
      state: 'PA',
    },
    15022: {
      lat: 40.13329,
      lng: -79.93661,
      city: 'Charleroi',
      state: 'PA',
    },
    15024: {
      lat: 40.57649,
      lng: -79.84649,
      city: 'Cheswick',
      state: 'PA',
    },
    15025: {
      lat: 40.29887,
      lng: -79.9213,
      city: 'Clairton',
      state: 'PA',
    },
    15026: {
      lat: 40.51043,
      lng: -80.35662,
      city: 'Clinton',
      state: 'PA',
    },
    15027: {
      lat: 40.66706,
      lng: -80.23988,
      city: 'Conway',
      state: 'PA',
    },
    15028: {
      lat: 40.30194,
      lng: -79.79891,
      city: 'Coulters',
      state: 'PA',
    },
    15030: {
      lat: 40.58492,
      lng: -79.78074,
      city: 'Creighton',
      state: 'PA',
    },
    15031: {
      lat: 40.34802,
      lng: -80.16235,
      city: 'Cuddy',
      state: 'PA',
    },
    15033: {
      lat: 40.18011,
      lng: -79.86501,
      city: 'Donora',
      state: 'PA',
    },
    15034: {
      lat: 40.35083,
      lng: -79.89067,
      city: 'Dravosburg',
      state: 'PA',
    },
    15035: {
      lat: 40.38488,
      lng: -79.80726,
      city: 'East McKeesport',
      state: 'PA',
    },
    15037: {
      lat: 40.25649,
      lng: -79.85017,
      city: 'Elizabeth',
      state: 'PA',
    },
    15038: {
      lat: 40.2499,
      lng: -79.92379,
      city: 'Elrama',
      state: 'PA',
    },
    15042: {
      lat: 40.68786,
      lng: -80.20411,
      city: 'Freedom',
      state: 'PA',
    },
    15043: {
      lat: 40.55585,
      lng: -80.48994,
      city: 'Georgetown',
      state: 'PA',
    },
    15044: {
      lat: 40.64009,
      lng: -79.9492,
      city: 'Gibsonia',
      state: 'PA',
    },
    15045: {
      lat: 40.32613,
      lng: -79.88614,
      city: 'Glassport',
      state: 'PA',
    },
    15046: {
      lat: 40.55618,
      lng: -80.22868,
      city: 'Crescent',
      state: 'PA',
    },
    15047: {
      lat: 40.3123,
      lng: -79.79513,
      city: 'Greenock',
      state: 'PA',
    },
    15049: {
      lat: 40.55545,
      lng: -79.80366,
      city: 'Harwick',
      state: 'PA',
    },
    15050: {
      lat: 40.5617,
      lng: -80.44038,
      city: 'Hookstown',
      state: 'PA',
    },
    15051: {
      lat: 40.56812,
      lng: -79.86076,
      city: 'Indianola',
      state: 'PA',
    },
    15052: {
      lat: 40.6669,
      lng: -80.43318,
      city: 'Industry',
      state: 'PA',
    },
    15053: {
      lat: 40.38056,
      lng: -80.35975,
      city: 'Joffre',
      state: 'PA',
    },
    15054: {
      lat: 40.36353,
      lng: -80.4008,
      city: 'Langeloth',
      state: 'PA',
    },
    15055: {
      lat: 40.30642,
      lng: -80.12296,
      city: 'Lawrence',
      state: 'PA',
    },
    15056: {
      lat: 40.56512,
      lng: -80.21407,
      city: 'Leetsdale',
      state: 'PA',
    },
    15057: {
      lat: 40.35571,
      lng: -80.24713,
      city: 'McDonald',
      state: 'PA',
    },
    15059: {
      lat: 40.68056,
      lng: -80.49182,
      city: 'Midland',
      state: 'PA',
    },
    15060: {
      lat: 40.37695,
      lng: -80.29113,
      city: 'Midway',
      state: 'PA',
    },
    15061: {
      lat: 40.66013,
      lng: -80.31082,
      city: 'Monaca',
      state: 'PA',
    },
    15062: {
      lat: 40.15184,
      lng: -79.88277,
      city: 'Monessen',
      state: 'PA',
    },
    15063: {
      lat: 40.19487,
      lng: -79.92228,
      city: 'Monongahela',
      state: 'PA',
    },
    15064: {
      lat: 40.35538,
      lng: -80.14849,
      city: 'Morgan',
      state: 'PA',
    },
    15065: {
      lat: 40.64192,
      lng: -79.72766,
      city: 'Natrona Heights',
      state: 'PA',
    },
    15066: {
      lat: 40.75119,
      lng: -80.25269,
      city: 'New Brighton',
      state: 'PA',
    },
    15067: {
      lat: 40.20823,
      lng: -79.96106,
      city: 'New Eagle',
      state: 'PA',
    },
    15068: {
      lat: 40.56241,
      lng: -79.7124,
      city: 'New Kensington',
      state: 'PA',
    },
    15071: {
      lat: 40.41486,
      lng: -80.18981,
      city: 'Oakdale',
      state: 'PA',
    },
    15072: {
      lat: 40.13922,
      lng: -79.85605,
      city: 'Pricedale',
      state: 'PA',
    },
    15074: {
      lat: 40.72837,
      lng: -80.21937,
      city: 'Rochester',
      state: 'PA',
    },
    15075: {
      lat: 40.58665,
      lng: -79.82693,
      city: 'Rural Ridge',
      state: 'PA',
    },
    15076: {
      lat: 40.60949,
      lng: -79.8374,
      city: 'Russellton',
      state: 'PA',
    },
    15077: {
      lat: 40.62517,
      lng: -80.4173,
      city: 'Shippingport',
      state: 'PA',
    },
    15078: {
      lat: 40.35949,
      lng: -80.38501,
      city: 'Slovan',
      state: 'PA',
    },
    15081: {
      lat: 40.57471,
      lng: -80.23614,
      city: 'South Heights',
      state: 'PA',
    },
    15082: {
      lat: 40.38031,
      lng: -80.21061,
      city: 'Sturgeon',
      state: 'PA',
    },
    15083: {
      lat: 40.24802,
      lng: -79.79041,
      city: 'Sutersville',
      state: 'PA',
    },
    15084: {
      lat: 40.6282,
      lng: -79.8028,
      city: 'Tarentum',
      state: 'PA',
    },
    15085: {
      lat: 40.38497,
      lng: -79.72539,
      city: 'Trafford',
      state: 'PA',
    },
    15086: {
      lat: 40.664,
      lng: -80.09593,
      city: 'Warrendale',
      state: 'PA',
    },
    15087: {
      lat: 40.19504,
      lng: -79.84948,
      city: 'Webster',
      state: 'PA',
    },
    15088: {
      lat: 40.27159,
      lng: -79.89607,
      city: 'West Elizabeth',
      state: 'PA',
    },
    15089: {
      lat: 40.22063,
      lng: -79.73719,
      city: 'West Newton',
      state: 'PA',
    },
    15090: {
      lat: 40.62587,
      lng: -80.06766,
      city: 'Wexford',
      state: 'PA',
    },
    15101: {
      lat: 40.58139,
      lng: -79.95531,
      city: 'Allison Park',
      state: 'PA',
    },
    15102: {
      lat: 40.32125,
      lng: -80.03707,
      city: 'Bethel Park',
      state: 'PA',
    },
    15104: {
      lat: 40.40379,
      lng: -79.86249,
      city: 'Braddock',
      state: 'PA',
    },
    15106: {
      lat: 40.40882,
      lng: -80.10863,
      city: 'Carnegie',
      state: 'PA',
    },
    15108: {
      lat: 40.50006,
      lng: -80.20031,
      city: 'Coraopolis',
      state: 'PA',
    },
    15110: {
      lat: 40.37321,
      lng: -79.85024,
      city: 'Duquesne',
      state: 'PA',
    },
    15112: {
      lat: 40.40453,
      lng: -79.83734,
      city: 'East Pittsburgh',
      state: 'PA',
    },
    15116: {
      lat: 40.5384,
      lng: -79.95211,
      city: 'Glenshaw',
      state: 'PA',
    },
    15120: {
      lat: 40.39597,
      lng: -79.90689,
      city: 'Homestead',
      state: 'PA',
    },
    15122: {
      lat: 40.35976,
      lng: -79.90628,
      city: 'West Mifflin',
      state: 'PA',
    },
    15123: {
      lat: 40.34817,
      lng: -79.93471,
      city: 'West Mifflin',
      state: 'PA',
    },
    15126: {
      lat: 40.46019,
      lng: -80.28727,
      city: 'Imperial',
      state: 'PA',
    },
    15129: {
      lat: 40.29348,
      lng: -79.99515,
      city: 'South Park',
      state: 'PA',
    },
    15131: {
      lat: 40.33477,
      lng: -79.80132,
      city: 'Mckeesport',
      state: 'PA',
    },
    15132: {
      lat: 40.33982,
      lng: -79.84299,
      city: 'Mckeesport',
      state: 'PA',
    },
    15133: {
      lat: 40.3257,
      lng: -79.86127,
      city: 'Mckeesport',
      state: 'PA',
    },
    15135: {
      lat: 40.29959,
      lng: -79.81153,
      city: 'Mckeesport',
      state: 'PA',
    },
    15136: {
      lat: 40.46872,
      lng: -80.10233,
      city: 'McKees Rocks',
      state: 'PA',
    },
    15137: {
      lat: 40.37762,
      lng: -79.80902,
      city: 'North Versailles',
      state: 'PA',
    },
    15139: {
      lat: 40.52192,
      lng: -79.83479,
      city: 'Oakmont',
      state: 'PA',
    },
    15140: {
      lat: 40.4073,
      lng: -79.7783,
      city: 'Pitcairn',
      state: 'PA',
    },
    15142: {
      lat: 40.38474,
      lng: -80.12231,
      city: 'Presto',
      state: 'PA',
    },
    15143: {
      lat: 40.57298,
      lng: -80.14725,
      city: 'Sewickley',
      state: 'PA',
    },
    15144: {
      lat: 40.54759,
      lng: -79.78317,
      city: 'Springdale',
      state: 'PA',
    },
    15145: {
      lat: 40.41451,
      lng: -79.82352,
      city: 'Turtle Creek',
      state: 'PA',
    },
    15146: {
      lat: 40.4264,
      lng: -79.76005,
      city: 'Monroeville',
      state: 'PA',
    },
    15147: {
      lat: 40.49704,
      lng: -79.82858,
      city: 'Verona',
      state: 'PA',
    },
    15148: {
      lat: 40.39351,
      lng: -79.79525,
      city: 'Wilmerding',
      state: 'PA',
    },
    15201: {
      lat: 40.47565,
      lng: -79.95389,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15202: {
      lat: 40.50566,
      lng: -80.06902,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15203: {
      lat: 40.4257,
      lng: -79.97491,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15204: {
      lat: 40.45597,
      lng: -80.06051,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15205: {
      lat: 40.4381,
      lng: -80.10552,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15206: {
      lat: 40.47244,
      lng: -79.91324,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15207: {
      lat: 40.39813,
      lng: -79.9334,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15208: {
      lat: 40.45387,
      lng: -79.89883,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15209: {
      lat: 40.50007,
      lng: -79.97463,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15210: {
      lat: 40.40679,
      lng: -79.98411,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15211: {
      lat: 40.4302,
      lng: -80.01533,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15212: {
      lat: 40.47106,
      lng: -80.00844,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15213: {
      lat: 40.44293,
      lng: -79.95345,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15214: {
      lat: 40.48541,
      lng: -80.01455,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15215: {
      lat: 40.50457,
      lng: -79.91338,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15216: {
      lat: 40.40268,
      lng: -80.0348,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15217: {
      lat: 40.43083,
      lng: -79.91999,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15218: {
      lat: 40.42366,
      lng: -79.88924,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15219: {
      lat: 40.44304,
      lng: -79.98443,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15220: {
      lat: 40.41875,
      lng: -80.04894,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15221: {
      lat: 40.43457,
      lng: -79.86631,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15222: {
      lat: 40.44998,
      lng: -79.9912,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15223: {
      lat: 40.50431,
      lng: -79.95199,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15224: {
      lat: 40.46737,
      lng: -79.94623,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15225: {
      lat: 40.50636,
      lng: -80.1116,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15226: {
      lat: 40.39527,
      lng: -80.01374,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15227: {
      lat: 40.375,
      lng: -79.97077,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15228: {
      lat: 40.37075,
      lng: -80.04395,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15229: {
      lat: 40.51995,
      lng: -80.03866,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15232: {
      lat: 40.45246,
      lng: -79.93193,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15233: {
      lat: 40.45985,
      lng: -80.03387,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15234: {
      lat: 40.36808,
      lng: -80.01782,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15235: {
      lat: 40.45968,
      lng: -79.82194,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15236: {
      lat: 40.34756,
      lng: -79.97652,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15237: {
      lat: 40.54834,
      lng: -80.04682,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15238: {
      lat: 40.53405,
      lng: -79.88115,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15239: {
      lat: 40.48437,
      lng: -79.73753,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15241: {
      lat: 40.33293,
      lng: -80.08291,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15243: {
      lat: 40.37474,
      lng: -80.07305,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15260: {
      lat: 40.4432,
      lng: -79.95306,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15275: {
      lat: 40.45246,
      lng: -80.18405,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15282: {
      lat: 40.43602,
      lng: -79.99042,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15290: {
      lat: 40.45735,
      lng: -80.01932,
      city: 'Pittsburgh',
      state: 'PA',
    },
    15301: {
      lat: 40.16411,
      lng: -80.25304,
      city: 'Washington',
      state: 'PA',
    },
    15310: {
      lat: 39.79255,
      lng: -80.48108,
      city: 'Aleppo',
      state: 'PA',
    },
    15311: {
      lat: 40.04608,
      lng: -80.18592,
      city: 'Amity',
      state: 'PA',
    },
    15312: {
      lat: 40.25404,
      lng: -80.43757,
      city: 'Avella',
      state: 'PA',
    },
    15313: {
      lat: 40.06318,
      lng: -80.02937,
      city: 'Beallsville',
      state: 'PA',
    },
    15314: {
      lat: 40.14555,
      lng: -80.01775,
      city: 'Bentleyville',
      state: 'PA',
    },
    15315: {
      lat: 39.75741,
      lng: -79.98319,
      city: 'Bobtown',
      state: 'PA',
    },
    15316: {
      lat: 39.76514,
      lng: -80.26185,
      city: 'Brave',
      state: 'PA',
    },
    15317: {
      lat: 40.27105,
      lng: -80.16535,
      city: 'Canonsburg',
      state: 'PA',
    },
    15320: {
      lat: 39.87386,
      lng: -79.99768,
      city: 'Carmichaels',
      state: 'PA',
    },
    15321: {
      lat: 40.32167,
      lng: -80.18716,
      city: 'Cecil',
      state: 'PA',
    },
    15322: {
      lat: 39.97655,
      lng: -80.0488,
      city: 'Clarksville',
      state: 'PA',
    },
    15323: {
      lat: 40.10845,
      lng: -80.40926,
      city: 'Claysville',
      state: 'PA',
    },
    15324: {
      lat: 40.10074,
      lng: -80.0654,
      city: 'Cokeburg',
      state: 'PA',
    },
    15325: {
      lat: 39.94893,
      lng: -79.96331,
      city: 'Crucible',
      state: 'PA',
    },
    15327: {
      lat: 39.75088,
      lng: -79.96293,
      city: 'Dilliner',
      state: 'PA',
    },
    15329: {
      lat: 40.02813,
      lng: -80.27205,
      city: 'Prosperity',
      state: 'PA',
    },
    15330: {
      lat: 40.18227,
      lng: -80.09492,
      city: 'Eighty Four',
      state: 'PA',
    },
    15331: {
      lat: 40.10754,
      lng: -80.02214,
      city: 'Ellsworth',
      state: 'PA',
    },
    15332: {
      lat: 40.24226,
      lng: -79.99134,
      city: 'Finleyville',
      state: 'PA',
    },
    15333: {
      lat: 40.02693,
      lng: -80.01429,
      city: 'Fredericktown',
      state: 'PA',
    },
    15334: {
      lat: 39.81292,
      lng: -79.9673,
      city: 'Garards Fort',
      state: 'PA',
    },
    15337: {
      lat: 39.9505,
      lng: -80.37521,
      city: 'Graysville',
      state: 'PA',
    },
    15338: {
      lat: 39.81654,
      lng: -79.96206,
      city: 'Greensboro',
      state: 'PA',
    },
    15340: {
      lat: 40.28654,
      lng: -80.32258,
      city: 'Hickory',
      state: 'PA',
    },
    15341: {
      lat: 39.82381,
      lng: -80.34728,
      city: 'Holbrook',
      state: 'PA',
    },
    15342: {
      lat: 40.24533,
      lng: -80.22061,
      city: 'Houston',
      state: 'PA',
    },
    15344: {
      lat: 39.92289,
      lng: -80.05068,
      city: 'Jefferson',
      state: 'PA',
    },
    15345: {
      lat: 40.02173,
      lng: -80.10743,
      city: 'Marianna',
      state: 'PA',
    },
    15346: {
      lat: 39.93545,
      lng: -80.07536,
      city: 'Mather',
      state: 'PA',
    },
    15347: {
      lat: 40.21807,
      lng: -80.22469,
      city: 'Meadow Lands',
      state: 'PA',
    },
    15348: {
      lat: 39.98748,
      lng: -79.99624,
      city: 'Millsboro',
      state: 'PA',
    },
    15349: {
      lat: 39.75885,
      lng: -80.07795,
      city: 'Mount Morris',
      state: 'PA',
    },
    15350: { lat: 40.29377, lng: -80.20056, city: 'Muse', state: 'PA' },
    15351: {
      lat: 39.87827,
      lng: -79.93068,
      city: 'Nemacolin',
      state: 'PA',
    },
    15352: {
      lat: 39.75418,
      lng: -80.40253,
      city: 'New Freeport',
      state: 'PA',
    },
    15353: {
      lat: 39.96223,
      lng: -80.31107,
      city: 'Nineveh',
      state: 'PA',
    },
    15357: {
      lat: 39.93721,
      lng: -79.98153,
      city: 'Rices Landing',
      state: 'PA',
    },
    15358: {
      lat: 40.055,
      lng: -80.00309,
      city: 'Richeyville',
      state: 'PA',
    },
    15359: {
      lat: 39.87667,
      lng: -80.27555,
      city: 'Rogersville',
      state: 'PA',
    },
    15360: {
      lat: 40.08846,
      lng: -80.08144,
      city: 'Scenery Hill',
      state: 'PA',
    },
    15361: {
      lat: 40.33277,
      lng: -80.2623,
      city: 'Southview',
      state: 'PA',
    },
    15362: {
      lat: 39.75821,
      lng: -80.21301,
      city: 'Spraggs',
      state: 'PA',
    },
    15363: {
      lat: 40.25159,
      lng: -80.19761,
      city: 'Strabane',
      state: 'PA',
    },
    15364: {
      lat: 39.94557,
      lng: -80.2849,
      city: 'Sycamore',
      state: 'PA',
    },
    15365: {
      lat: 40.15564,
      lng: -80.38548,
      city: 'Taylorstown',
      state: 'PA',
    },
    15366: {
      lat: 40.1598,
      lng: -79.974,
      city: 'Van Voorhis',
      state: 'PA',
    },
    15367: {
      lat: 40.26321,
      lng: -80.05474,
      city: 'Venetia',
      state: 'PA',
    },
    15368: {
      lat: 40.0145,
      lng: -79.9892,
      city: 'Vestaburg',
      state: 'PA',
    },
    15370: {
      lat: 39.8734,
      lng: -80.17991,
      city: 'Waynesburg',
      state: 'PA',
    },
    15376: {
      lat: 40.10493,
      lng: -80.48744,
      city: 'West Alexander',
      state: 'PA',
    },
    15377: {
      lat: 39.97973,
      lng: -80.45083,
      city: 'West Finley',
      state: 'PA',
    },
    15378: {
      lat: 40.27771,
      lng: -80.27433,
      city: 'Westland',
      state: 'PA',
    },
    15379: {
      lat: 40.2432,
      lng: -80.42519,
      city: 'West Middletown',
      state: 'PA',
    },
    15380: {
      lat: 39.8725,
      lng: -80.4692,
      city: 'Wind Ridge',
      state: 'PA',
    },
    15401: {
      lat: 39.89914,
      lng: -79.74965,
      city: 'Uniontown',
      state: 'PA',
    },
    15410: { lat: 39.91246, lng: -79.90352, city: 'Adah', state: 'PA' },
    15411: {
      lat: 39.74724,
      lng: -79.34344,
      city: 'Addison',
      state: 'PA',
    },
    15412: {
      lat: 40.09496,
      lng: -79.85218,
      city: 'Allenport',
      state: 'PA',
    },
    15413: {
      lat: 39.98493,
      lng: -79.87141,
      city: 'Allison',
      state: 'PA',
    },
    15416: {
      lat: 39.85903,
      lng: -79.72286,
      city: 'Brownfield',
      state: 'PA',
    },
    15417: {
      lat: 40.01466,
      lng: -79.91464,
      city: 'Brownsville',
      state: 'PA',
    },
    15419: {
      lat: 40.05861,
      lng: -79.89408,
      city: 'California',
      state: 'PA',
    },
    15420: {
      lat: 39.95981,
      lng: -79.86537,
      city: 'Cardale',
      state: 'PA',
    },
    15421: {
      lat: 39.84527,
      lng: -79.59743,
      city: 'Chalk Hill',
      state: 'PA',
    },
    15422: {
      lat: 39.9781,
      lng: -79.80927,
      city: 'Chestnut Ridge',
      state: 'PA',
    },
    15423: {
      lat: 40.08804,
      lng: -79.92538,
      city: 'Coal Center',
      state: 'PA',
    },
    15424: {
      lat: 39.829,
      lng: -79.35377,
      city: 'Confluence',
      state: 'PA',
    },
    15425: {
      lat: 40.02702,
      lng: -79.55411,
      city: 'Connellsville',
      state: 'PA',
    },
    15427: {
      lat: 40.06742,
      lng: -79.97065,
      city: 'Daisytown',
      state: 'PA',
    },
    15428: {
      lat: 40.0811,
      lng: -79.68184,
      city: 'Dawson',
      state: 'PA',
    },
    15429: {
      lat: 40.00678,
      lng: -79.94021,
      city: 'Denbo',
      state: 'PA',
    },
    15430: {
      lat: 40.04025,
      lng: -79.65534,
      city: 'Dickerson Run',
      state: 'PA',
    },
    15431: {
      lat: 39.94066,
      lng: -79.58075,
      city: 'Dunbar',
      state: 'PA',
    },
    15432: {
      lat: 40.11301,
      lng: -79.85913,
      city: 'Dunlevy',
      state: 'PA',
    },
    15433: {
      lat: 39.97413,
      lng: -79.95799,
      city: 'East Millsboro',
      state: 'PA',
    },
    15434: { lat: 40.08013, lng: -79.88037, city: 'Elco', state: 'PA' },
    15435: {
      lat: 39.94314,
      lng: -79.84731,
      city: 'Fairbank',
      state: 'PA',
    },
    15436: {
      lat: 39.81844,
      lng: -79.72543,
      city: 'Fairchance',
      state: 'PA',
    },
    15437: {
      lat: 39.79202,
      lng: -79.59846,
      city: 'Farmington',
      state: 'PA',
    },
    15438: {
      lat: 40.07485,
      lng: -79.84431,
      city: 'Fayette City',
      state: 'PA',
    },
    15440: {
      lat: 39.73775,
      lng: -79.64347,
      city: 'Gibbon Glade',
      state: 'PA',
    },
    15442: {
      lat: 40.01542,
      lng: -79.84019,
      city: 'Grindstone',
      state: 'PA',
    },
    15443: {
      lat: 39.91924,
      lng: -79.88448,
      city: 'Hibbs',
      state: 'PA',
    },
    15444: {
      lat: 40.0134,
      lng: -79.90836,
      city: 'Hiller',
      state: 'PA',
    },
    15445: {
      lat: 39.87343,
      lng: -79.65766,
      city: 'Hopwood',
      state: 'PA',
    },
    15446: {
      lat: 40.03172,
      lng: -79.39433,
      city: 'Indian Head',
      state: 'PA',
    },
    15447: {
      lat: 39.9445,
      lng: -79.93257,
      city: 'Isabella',
      state: 'PA',
    },
    15448: {
      lat: 40.13572,
      lng: -79.73394,
      city: 'Jacobs Creek',
      state: 'PA',
    },
    15449: {
      lat: 39.97267,
      lng: -79.78055,
      city: 'Keisterville',
      state: 'PA',
    },
    15450: {
      lat: 40.00289,
      lng: -79.97616,
      city: 'La Belle',
      state: 'PA',
    },
    15451: {
      lat: 39.74018,
      lng: -79.84005,
      city: 'Lake Lynn',
      state: 'PA',
    },
    15454: {
      lat: 39.86386,
      lng: -79.87286,
      city: 'Leckrone',
      state: 'PA',
    },
    15455: {
      lat: 40.00614,
      lng: -79.64609,
      city: 'Leisenring',
      state: 'PA',
    },
    15456: {
      lat: 39.92919,
      lng: -79.6532,
      city: 'Lemont Furnace',
      state: 'PA',
    },
    15458: {
      lat: 39.88715,
      lng: -79.85099,
      city: 'McClellandtown',
      state: 'PA',
    },
    15459: {
      lat: 39.76483,
      lng: -79.4648,
      city: 'Markleysburg',
      state: 'PA',
    },
    15460: {
      lat: 39.80712,
      lng: -79.90756,
      city: 'Martin',
      state: 'PA',
    },
    15461: {
      lat: 39.83978,
      lng: -79.89843,
      city: 'Masontown',
      state: 'PA',
    },
    15462: {
      lat: 40.06136,
      lng: -79.38113,
      city: 'Melcroft',
      state: 'PA',
    },
    15463: {
      lat: 39.9629,
      lng: -79.89916,
      city: 'Merrittstown',
      state: 'PA',
    },
    15464: {
      lat: 39.92463,
      lng: -79.43695,
      city: 'Mill Run',
      state: 'PA',
    },
    15465: {
      lat: 39.93883,
      lng: -79.64126,
      city: 'Mount Braddock',
      state: 'PA',
    },
    15466: {
      lat: 40.07562,
      lng: -79.89199,
      city: 'Newell',
      state: 'PA',
    },
    15467: {
      lat: 39.78931,
      lng: -79.90526,
      city: 'New Geneva',
      state: 'PA',
    },
    15468: {
      lat: 39.94872,
      lng: -79.83761,
      city: 'New Salem',
      state: 'PA',
    },
    15469: {
      lat: 40.01366,
      lng: -79.41861,
      city: 'Normalville',
      state: 'PA',
    },
    15470: {
      lat: 39.85321,
      lng: -79.53078,
      city: 'Ohiopyle',
      state: 'PA',
    },
    15472: {
      lat: 39.91933,
      lng: -79.71679,
      city: 'Oliver',
      state: 'PA',
    },
    15473: {
      lat: 40.07256,
      lng: -79.77352,
      city: 'Perryopolis',
      state: 'PA',
    },
    15474: {
      lat: 39.75201,
      lng: -79.90145,
      city: 'Point Marion',
      state: 'PA',
    },
    15475: {
      lat: 39.95812,
      lng: -79.88057,
      city: 'Republic',
      state: 'PA',
    },
    15476: {
      lat: 39.86888,
      lng: -79.92228,
      city: 'Ronco',
      state: 'PA',
    },
    15477: {
      lat: 40.07856,
      lng: -79.86243,
      city: 'Roscoe',
      state: 'PA',
    },
    15478: {
      lat: 39.78601,
      lng: -79.80099,
      city: 'Smithfield',
      state: 'PA',
    },
    15479: {
      lat: 40.15224,
      lng: -79.71329,
      city: 'Smithton',
      state: 'PA',
    },
    15480: {
      lat: 39.98644,
      lng: -79.76962,
      city: 'Smock',
      state: 'PA',
    },
    15482: {
      lat: 40.06073,
      lng: -79.76645,
      city: 'Star Junction',
      state: 'PA',
    },
    15483: {
      lat: 40.08352,
      lng: -79.85036,
      city: 'Stockdale',
      state: 'PA',
    },
    15484: {
      lat: 39.89242,
      lng: -79.77842,
      city: 'Uledi',
      state: 'PA',
    },
    15486: {
      lat: 40.02762,
      lng: -79.70981,
      city: 'Vanderbilt',
      state: 'PA',
    },
    15488: {
      lat: 39.97749,
      lng: -79.75974,
      city: 'Waltersburg',
      state: 'PA',
    },
    15489: {
      lat: 39.96323,
      lng: -79.69531,
      city: 'West Leisenring',
      state: 'PA',
    },
    15490: {
      lat: 40.06782,
      lng: -79.45327,
      city: 'White',
      state: 'PA',
    },
    15492: {
      lat: 40.11974,
      lng: -79.76306,
      city: 'Wickhaven',
      state: 'PA',
    },
    15501: {
      lat: 40.03582,
      lng: -79.13024,
      city: 'Somerset',
      state: 'PA',
    },
    15502: {
      lat: 40.03641,
      lng: -79.25176,
      city: 'Hidden Valley',
      state: 'PA',
    },
    15510: {
      lat: 39.96435,
      lng: -79.04009,
      city: 'Somerset',
      state: 'PA',
    },
    15520: {
      lat: 40.10626,
      lng: -79.06017,
      city: 'Acosta',
      state: 'PA',
    },
    15521: {
      lat: 40.19972,
      lng: -78.63176,
      city: 'Alum Bank',
      state: 'PA',
    },
    15522: {
      lat: 39.94931,
      lng: -78.55173,
      city: 'Bedford',
      state: 'PA',
    },
    15530: {
      lat: 39.94531,
      lng: -78.91253,
      city: 'Berlin',
      state: 'PA',
    },
    15531: {
      lat: 40.18537,
      lng: -79.0794,
      city: 'Boswell',
      state: 'PA',
    },
    15532: {
      lat: 39.76232,
      lng: -79.06084,
      city: 'Boynton',
      state: 'PA',
    },
    15533: {
      lat: 39.98585,
      lng: -78.24566,
      city: 'Breezewood',
      state: 'PA',
    },
    15534: {
      lat: 39.89712,
      lng: -78.69589,
      city: 'Buffalo Mills',
      state: 'PA',
    },
    15535: {
      lat: 39.826,
      lng: -78.45001,
      city: 'Clearville',
      state: 'PA',
    },
    15536: {
      lat: 39.94956,
      lng: -78.2109,
      city: 'Crystal Spring',
      state: 'PA',
    },
    15537: {
      lat: 39.9981,
      lng: -78.36506,
      city: 'Everett',
      state: 'PA',
    },
    15538: {
      lat: 39.86644,
      lng: -78.83233,
      city: 'Fairhope',
      state: 'PA',
    },
    15539: {
      lat: 40.12835,
      lng: -78.59341,
      city: 'Fishertown',
      state: 'PA',
    },
    15540: {
      lat: 39.79549,
      lng: -79.23712,
      city: 'Fort Hill',
      state: 'PA',
    },
    15541: {
      lat: 40.04905,
      lng: -78.98076,
      city: 'Friedens',
      state: 'PA',
    },
    15542: {
      lat: 39.86742,
      lng: -79.07851,
      city: 'Garrett',
      state: 'PA',
    },
    15544: { lat: 40.13254, lng: -79.09656, city: 'Gray', state: 'PA' },
    15545: {
      lat: 39.77943,
      lng: -78.776,
      city: 'Hyndman',
      state: 'PA',
    },
    15546: {
      lat: 40.13717,
      lng: -79.04758,
      city: 'Jenners',
      state: 'PA',
    },
    15547: {
      lat: 40.1629,
      lng: -79.07019,
      city: 'Jennerstown',
      state: 'PA',
    },
    15550: {
      lat: 39.99025,
      lng: -78.64493,
      city: 'Manns Choice',
      state: 'PA',
    },
    15551: {
      lat: 39.88413,
      lng: -79.26761,
      city: 'Markleton',
      state: 'PA',
    },
    15552: {
      lat: 39.79629,
      lng: -78.98232,
      city: 'Meyersdale',
      state: 'PA',
    },
    15554: {
      lat: 40.12525,
      lng: -78.61575,
      city: 'New Paris',
      state: 'PA',
    },
    15555: {
      lat: 40.09758,
      lng: -79.08132,
      city: 'Quecreek',
      state: 'PA',
    },
    15557: {
      lat: 39.92757,
      lng: -79.20945,
      city: 'Rockwood',
      state: 'PA',
    },
    15558: {
      lat: 39.7494,
      lng: -79.09755,
      city: 'Salisbury',
      state: 'PA',
    },
    15559: {
      lat: 40.05914,
      lng: -78.68241,
      city: 'Schellsburg',
      state: 'PA',
    },
    15560: {
      lat: 40.01687,
      lng: -78.90664,
      city: 'Shanksville',
      state: 'PA',
    },
    15561: {
      lat: 40.09379,
      lng: -79.0907,
      city: 'Sipesville',
      state: 'PA',
    },
    15562: {
      lat: 39.74165,
      lng: -79.1219,
      city: 'Springs',
      state: 'PA',
    },
    15563: {
      lat: 40.0962,
      lng: -78.93618,
      city: 'Stoystown',
      state: 'PA',
    },
    15564: {
      lat: 39.72967,
      lng: -78.84542,
      city: 'Wellersburg',
      state: 'PA',
    },
    15565: {
      lat: 39.75907,
      lng: -79.11552,
      city: 'West Salisbury',
      state: 'PA',
    },
    15601: {
      lat: 40.31397,
      lng: -79.53643,
      city: 'Greensburg',
      state: 'PA',
    },
    15610: { lat: 40.13699, lng: -79.41613, city: 'Acme', state: 'PA' },
    15611: {
      lat: 40.30656,
      lng: -79.65266,
      city: 'Adamsburg',
      state: 'PA',
    },
    15612: {
      lat: 40.13793,
      lng: -79.59938,
      city: 'Alverton',
      state: 'PA',
    },
    15613: {
      lat: 40.55355,
      lng: -79.56577,
      city: 'Apollo',
      state: 'PA',
    },
    15615: {
      lat: 40.36133,
      lng: -79.73397,
      city: 'Ardara',
      state: 'PA',
    },
    15616: {
      lat: 40.23502,
      lng: -79.55093,
      city: 'Armbrust',
      state: 'PA',
    },
    15617: {
      lat: 40.26856,
      lng: -79.65805,
      city: 'Arona',
      state: 'PA',
    },
    15618: {
      lat: 40.56229,
      lng: -79.44628,
      city: 'Avonmore',
      state: 'PA',
    },
    15619: {
      lat: 40.32051,
      lng: -79.49719,
      city: 'Bovard',
      state: 'PA',
    },
    15620: {
      lat: 40.32336,
      lng: -79.33882,
      city: 'Bradenville',
      state: 'PA',
    },
    15622: {
      lat: 40.0356,
      lng: -79.31724,
      city: 'Champion',
      state: 'PA',
    },
    15623: {
      lat: 40.3689,
      lng: -79.62263,
      city: 'Claridge',
      state: 'PA',
    },
    15624: {
      lat: 40.36638,
      lng: -79.47124,
      city: 'Crabtree',
      state: 'PA',
    },
    15625: {
      lat: 40.27048,
      lng: -79.67925,
      city: 'Darragh',
      state: 'PA',
    },
    15626: {
      lat: 40.40868,
      lng: -79.57592,
      city: 'Delmont',
      state: 'PA',
    },
    15627: {
      lat: 40.35064,
      lng: -79.30667,
      city: 'Derry',
      state: 'PA',
    },
    15628: {
      lat: 40.10009,
      lng: -79.37008,
      city: 'Donegal',
      state: 'PA',
    },
    15629: {
      lat: 40.59731,
      lng: -79.56304,
      city: 'East Vandergrift',
      state: 'PA',
    },
    15631: {
      lat: 40.08968,
      lng: -79.58527,
      city: 'Everson',
      state: 'PA',
    },
    15632: {
      lat: 40.43803,
      lng: -79.60668,
      city: 'Export',
      state: 'PA',
    },
    15633: {
      lat: 40.35941,
      lng: -79.51699,
      city: 'Forbes Road',
      state: 'PA',
    },
    15634: {
      lat: 40.32533,
      lng: -79.60596,
      city: 'Grapeville',
      state: 'PA',
    },
    15635: {
      lat: 40.34891,
      lng: -79.49877,
      city: 'Hannastown',
      state: 'PA',
    },
    15636: {
      lat: 40.36459,
      lng: -79.65619,
      city: 'Harrison City',
      state: 'PA',
    },
    15637: {
      lat: 40.26125,
      lng: -79.71203,
      city: 'Herminie',
      state: 'PA',
    },
    15638: {
      lat: 40.26466,
      lng: -79.4017,
      city: 'Hostetter',
      state: 'PA',
    },
    15639: {
      lat: 40.20927,
      lng: -79.59379,
      city: 'Hunker',
      state: 'PA',
    },
    15640: {
      lat: 40.22315,
      lng: -79.73243,
      city: 'Hutchinson',
      state: 'PA',
    },
    15641: {
      lat: 40.63151,
      lng: -79.58909,
      city: 'Hyde Park',
      state: 'PA',
    },
    15642: {
      lat: 40.32045,
      lng: -79.72119,
      city: 'Irwin',
      state: 'PA',
    },
    15644: {
      lat: 40.34681,
      lng: -79.61278,
      city: 'Jeannette',
      state: 'PA',
    },
    15646: {
      lat: 40.09517,
      lng: -79.34448,
      city: 'Jones Mills',
      state: 'PA',
    },
    15647: {
      lat: 40.34262,
      lng: -79.72009,
      city: 'Larimer',
      state: 'PA',
    },
    15650: {
      lat: 40.27527,
      lng: -79.39213,
      city: 'Latrobe',
      state: 'PA',
    },
    15655: {
      lat: 40.19739,
      lng: -79.18141,
      city: 'Laughlintown',
      state: 'PA',
    },
    15656: {
      lat: 40.64114,
      lng: -79.61981,
      city: 'Leechburg',
      state: 'PA',
    },
    15658: {
      lat: 40.25467,
      lng: -79.22217,
      city: 'Ligonier',
      state: 'PA',
    },
    15660: {
      lat: 40.24669,
      lng: -79.77293,
      city: 'Lowber',
      state: 'PA',
    },
    15661: {
      lat: 40.32192,
      lng: -79.36007,
      city: 'Loyalhanna',
      state: 'PA',
    },
    15662: {
      lat: 40.33892,
      lng: -79.47972,
      city: 'Luxor',
      state: 'PA',
    },
    15663: {
      lat: 40.25233,
      lng: -79.67958,
      city: 'Madison',
      state: 'PA',
    },
    15665: {
      lat: 40.33925,
      lng: -79.66338,
      city: 'Manor',
      state: 'PA',
    },
    15666: {
      lat: 40.15858,
      lng: -79.51398,
      city: 'Mount Pleasant',
      state: 'PA',
    },
    15668: {
      lat: 40.46352,
      lng: -79.67168,
      city: 'Murrysville',
      state: 'PA',
    },
    15670: {
      lat: 40.40783,
      lng: -79.4321,
      city: 'New Alexandria',
      state: 'PA',
    },
    15671: {
      lat: 40.36038,
      lng: -79.32146,
      city: 'New Derry',
      state: 'PA',
    },
    15672: {
      lat: 40.24428,
      lng: -79.63069,
      city: 'New Stanton',
      state: 'PA',
    },
    15673: {
      lat: 40.59383,
      lng: -79.55634,
      city: 'North Apollo',
      state: 'PA',
    },
    15674: {
      lat: 40.20672,
      lng: -79.49636,
      city: 'Norvelt',
      state: 'PA',
    },
    15675: { lat: 40.33363, lng: -79.63867, city: 'Penn', state: 'PA' },
    15676: {
      lat: 40.24303,
      lng: -79.45913,
      city: 'Pleasant Unity',
      state: 'PA',
    },
    15677: {
      lat: 40.15048,
      lng: -79.23113,
      city: 'Rector',
      state: 'PA',
    },
    15678: {
      lat: 40.28447,
      lng: -79.72579,
      city: 'Rillton',
      state: 'PA',
    },
    15679: {
      lat: 40.18126,
      lng: -79.64982,
      city: 'Ruffs Dale',
      state: 'PA',
    },
    15680: {
      lat: 40.52104,
      lng: -79.49638,
      city: 'Salina',
      state: 'PA',
    },
    15681: {
      lat: 40.5023,
      lng: -79.43873,
      city: 'Saltsburg',
      state: 'PA',
    },
    15682: {
      lat: 40.68301,
      lng: -79.63696,
      city: 'Schenley',
      state: 'PA',
    },
    15683: {
      lat: 40.10926,
      lng: -79.60492,
      city: 'Scottdale',
      state: 'PA',
    },
    15684: {
      lat: 40.4629,
      lng: -79.50629,
      city: 'Slickville',
      state: 'PA',
    },
    15686: {
      lat: 40.61802,
      lng: -79.43635,
      city: 'Spring Church',
      state: 'PA',
    },
    15687: {
      lat: 40.13149,
      lng: -79.31977,
      city: 'Stahlstown',
      state: 'PA',
    },
    15688: { lat: 40.1734, lng: -79.58602, city: 'Tarrs', state: 'PA' },
    15689: {
      lat: 40.2253,
      lng: -79.49164,
      city: 'United',
      state: 'PA',
    },
    15690: {
      lat: 40.6455,
      lng: -79.54243,
      city: 'Vandergrift',
      state: 'PA',
    },
    15691: {
      lat: 40.29668,
      lng: -79.68411,
      city: 'Wendel',
      state: 'PA',
    },
    15692: {
      lat: 40.33086,
      lng: -79.67861,
      city: 'Westmoreland City',
      state: 'PA',
    },
    15693: {
      lat: 40.24939,
      lng: -79.40592,
      city: 'Whitney',
      state: 'PA',
    },
    15695: {
      lat: 40.19707,
      lng: -79.69284,
      city: 'Wyano',
      state: 'PA',
    },
    15696: {
      lat: 40.2798,
      lng: -79.36587,
      city: 'Youngstown',
      state: 'PA',
    },
    15697: {
      lat: 40.24054,
      lng: -79.5803,
      city: 'Youngwood',
      state: 'PA',
    },
    15698: { lat: 40.2167, lng: -79.68894, city: 'Yukon', state: 'PA' },
    15701: {
      lat: 40.62935,
      lng: -79.15056,
      city: 'Indiana',
      state: 'PA',
    },
    15705: {
      lat: 40.61588,
      lng: -79.15983,
      city: 'Indiana',
      state: 'PA',
    },
    15710: {
      lat: 40.64019,
      lng: -78.87193,
      city: 'Alverda',
      state: 'PA',
    },
    15711: {
      lat: 41.00926,
      lng: -78.96235,
      city: 'Anita',
      state: 'PA',
    },
    15712: {
      lat: 40.79323,
      lng: -78.84692,
      city: 'Arcadia',
      state: 'PA',
    },
    15713: {
      lat: 40.5698,
      lng: -79.26184,
      city: 'Aultman',
      state: 'PA',
    },
    15714: {
      lat: 40.63962,
      lng: -78.82978,
      city: 'Northern Cambria',
      state: 'PA',
    },
    15715: {
      lat: 40.97017,
      lng: -78.87655,
      city: 'Big Run',
      state: 'PA',
    },
    15716: {
      lat: 40.47025,
      lng: -79.19031,
      city: 'Black Lick',
      state: 'PA',
    },
    15717: {
      lat: 40.45354,
      lng: -79.23896,
      city: 'Blairsville',
      state: 'PA',
    },
    15720: {
      lat: 40.53896,
      lng: -79.06625,
      city: 'Brush Valley',
      state: 'PA',
    },
    15721: {
      lat: 40.82029,
      lng: -78.78739,
      city: 'Burnside',
      state: 'PA',
    },
    15722: {
      lat: 40.59833,
      lng: -78.72532,
      city: 'Carrolltown',
      state: 'PA',
    },
    15723: {
      lat: 40.70066,
      lng: -79.1582,
      city: 'Chambersville',
      state: 'PA',
    },
    15724: {
      lat: 40.73545,
      lng: -78.81746,
      city: 'Cherry Tree',
      state: 'PA',
    },
    15725: {
      lat: 40.51751,
      lng: -79.33954,
      city: 'Clarksburg',
      state: 'PA',
    },
    15727: {
      lat: 40.56498,
      lng: -79.31496,
      city: 'Clune',
      state: 'PA',
    },
    15728: {
      lat: 40.6757,
      lng: -78.96644,
      city: 'Clymer',
      state: 'PA',
    },
    15729: {
      lat: 40.70583,
      lng: -78.92049,
      city: 'Commodore',
      state: 'PA',
    },
    15730: {
      lat: 41.02749,
      lng: -79.07921,
      city: 'Coolspring',
      state: 'PA',
    },
    15731: { lat: 40.50383, lng: -79.1767, city: 'Coral', state: 'PA' },
    15732: {
      lat: 40.72829,
      lng: -79.21336,
      city: 'Creekside',
      state: 'PA',
    },
    15733: {
      lat: 40.9869,
      lng: -78.96072,
      city: 'De Lancey',
      state: 'PA',
    },
    15734: {
      lat: 40.72046,
      lng: -79.00836,
      city: 'Dixonville',
      state: 'PA',
    },
    15736: {
      lat: 40.69945,
      lng: -79.36946,
      city: 'Elderton',
      state: 'PA',
    },
    15737: {
      lat: 40.59871,
      lng: -78.75356,
      city: 'Elmora',
      state: 'PA',
    },
    15738: {
      lat: 40.69039,
      lng: -78.78174,
      city: 'Emeigh',
      state: 'PA',
    },
    15739: {
      lat: 40.67875,
      lng: -79.16454,
      city: 'Ernest',
      state: 'PA',
    },
    15741: {
      lat: 40.80601,
      lng: -78.88924,
      city: 'Gipsy',
      state: 'PA',
    },
    15742: {
      lat: 40.81042,
      lng: -78.8688,
      city: 'Glen Campbell',
      state: 'PA',
    },
    15744: {
      lat: 40.91722,
      lng: -79.07119,
      city: 'Hamilton',
      state: 'PA',
    },
    15745: {
      lat: 40.62264,
      lng: -78.91347,
      city: 'Heilwood',
      state: 'PA',
    },
    15746: {
      lat: 40.75285,
      lng: -78.87455,
      city: 'Hillsdale',
      state: 'PA',
    },
    15747: { lat: 40.76533, lng: -79.14562, city: 'Home', state: 'PA' },
    15748: {
      lat: 40.52489,
      lng: -79.087,
      city: 'Homer City',
      state: 'PA',
    },
    15750: {
      lat: 40.48377,
      lng: -79.18256,
      city: 'Josephine',
      state: 'PA',
    },
    15752: { lat: 40.5408, lng: -79.28177, city: 'Kent', state: 'PA' },
    15753: {
      lat: 40.78681,
      lng: -78.64728,
      city: 'La Jose',
      state: 'PA',
    },
    15754: {
      lat: 40.55667,
      lng: -79.15152,
      city: 'Lucernemines',
      state: 'PA',
    },
    15756: {
      lat: 40.57134,
      lng: -79.30096,
      city: 'McIntyre',
      state: 'PA',
    },
    15757: {
      lat: 40.8902,
      lng: -78.73733,
      city: 'Mahaffey',
      state: 'PA',
    },
    15759: {
      lat: 40.7721,
      lng: -79.03045,
      city: 'Marion Center',
      state: 'PA',
    },
    15760: {
      lat: 40.64583,
      lng: -78.80509,
      city: 'Marsteller',
      state: 'PA',
    },
    15761: {
      lat: 40.63399,
      lng: -78.89019,
      city: 'Mentcle',
      state: 'PA',
    },
    15762: {
      lat: 40.6001,
      lng: -78.83309,
      city: 'Nicktown',
      state: 'PA',
    },
    15763: {
      lat: 40.90718,
      lng: -79.12795,
      city: 'Northpoint',
      state: 'PA',
    },
    15764: {
      lat: 40.99423,
      lng: -79.02773,
      city: 'Oliveburg',
      state: 'PA',
    },
    15765: {
      lat: 40.59661,
      lng: -78.99574,
      city: 'Penn Run',
      state: 'PA',
    },
    15767: {
      lat: 40.95859,
      lng: -78.96974,
      city: 'Punxsutawney',
      state: 'PA',
    },
    15770: {
      lat: 40.99528,
      lng: -79.15747,
      city: 'Ringgold',
      state: 'PA',
    },
    15771: {
      lat: 40.83309,
      lng: -78.99009,
      city: 'Rochester Mills',
      state: 'PA',
    },
    15772: {
      lat: 40.87392,
      lng: -78.89984,
      city: 'Rossiter',
      state: 'PA',
    },
    15773: {
      lat: 40.63075,
      lng: -78.73041,
      city: 'Saint Benedict',
      state: 'PA',
    },
    15774: {
      lat: 40.65729,
      lng: -79.32508,
      city: 'Shelocta',
      state: 'PA',
    },
    15775: {
      lat: 40.63488,
      lng: -78.78405,
      city: 'Spangler',
      state: 'PA',
    },
    15776: {
      lat: 41.01348,
      lng: -79.11442,
      city: 'Sprankle Mills',
      state: 'PA',
    },
    15777: {
      lat: 40.69749,
      lng: -78.96255,
      city: 'Starford',
      state: 'PA',
    },
    15778: {
      lat: 40.96728,
      lng: -79.19901,
      city: 'Timblin',
      state: 'PA',
    },
    15779: {
      lat: 40.40008,
      lng: -79.21846,
      city: 'Torrance',
      state: 'PA',
    },
    15780: {
      lat: 40.91628,
      lng: -79.05606,
      city: 'Valier',
      state: 'PA',
    },
    15781: {
      lat: 40.96375,
      lng: -78.98679,
      city: 'Walston',
      state: 'PA',
    },
    15783: {
      lat: 40.60297,
      lng: -79.34495,
      city: 'West Lebanon',
      state: 'PA',
    },
    15784: {
      lat: 41.02449,
      lng: -79.13937,
      city: 'Worthville',
      state: 'PA',
    },
    15801: {
      lat: 41.12867,
      lng: -78.72352,
      city: 'Du Bois',
      state: 'PA',
    },
    15821: {
      lat: 41.35868,
      lng: -78.36453,
      city: 'Benezett',
      state: 'PA',
    },
    15823: {
      lat: 41.25888,
      lng: -78.71001,
      city: 'Brockport',
      state: 'PA',
    },
    15824: {
      lat: 41.24831,
      lng: -78.83828,
      city: 'Brockway',
      state: 'PA',
    },
    15825: {
      lat: 41.17005,
      lng: -79.03885,
      city: 'Brookville',
      state: 'PA',
    },
    15827: {
      lat: 41.29479,
      lng: -78.50582,
      city: 'Byrnedale',
      state: 'PA',
    },
    15828: {
      lat: 41.35104,
      lng: -79.13906,
      city: 'Clarington',
      state: 'PA',
    },
    15829: {
      lat: 41.17337,
      lng: -79.19324,
      city: 'Corsica',
      state: 'PA',
    },
    15831: {
      lat: 41.35305,
      lng: -78.604,
      city: 'Dagus Mines',
      state: 'PA',
    },
    15832: {
      lat: 41.35301,
      lng: -78.19053,
      city: 'Driftwood',
      state: 'PA',
    },
    15834: {
      lat: 41.51228,
      lng: -78.27811,
      city: 'Emporium',
      state: 'PA',
    },
    15840: {
      lat: 41.16927,
      lng: -78.82708,
      city: 'Falls Creek',
      state: 'PA',
    },
    15841: {
      lat: 41.25798,
      lng: -78.50441,
      city: 'Force',
      state: 'PA',
    },
    15845: {
      lat: 41.50714,
      lng: -78.69066,
      city: 'Johnsonburg',
      state: 'PA',
    },
    15846: {
      lat: 41.33375,
      lng: -78.6147,
      city: 'Kersey',
      state: 'PA',
    },
    15847: {
      lat: 41.08614,
      lng: -79.02948,
      city: 'Knox Dale',
      state: 'PA',
    },
    15848: {
      lat: 41.03137,
      lng: -78.71908,
      city: 'Luthersburg',
      state: 'PA',
    },
    15849: {
      lat: 41.17838,
      lng: -78.55592,
      city: 'Penfield',
      state: 'PA',
    },
    15851: {
      lat: 41.10115,
      lng: -78.90316,
      city: 'Reynoldsville',
      state: 'PA',
    },
    15853: {
      lat: 41.39253,
      lng: -78.79357,
      city: 'Ridgway',
      state: 'PA',
    },
    15856: {
      lat: 41.08906,
      lng: -78.6154,
      city: 'Rockton',
      state: 'PA',
    },
    15857: {
      lat: 41.45671,
      lng: -78.53439,
      city: 'Saint Marys',
      state: 'PA',
    },
    15860: { lat: 41.3435, lng: -79.04697, city: 'Sigel', state: 'PA' },
    15861: {
      lat: 41.3221,
      lng: -78.07246,
      city: 'Sinnamahoning',
      state: 'PA',
    },
    15863: {
      lat: 41.0157,
      lng: -78.83847,
      city: 'Stump Creek',
      state: 'PA',
    },
    15864: {
      lat: 41.09911,
      lng: -79.19532,
      city: 'Summerville',
      state: 'PA',
    },
    15865: {
      lat: 41.0508,
      lng: -78.81418,
      city: 'Sykesville',
      state: 'PA',
    },
    15866: {
      lat: 41.02587,
      lng: -78.78628,
      city: 'Troutville',
      state: 'PA',
    },
    15868: {
      lat: 41.29885,
      lng: -78.41799,
      city: 'Weedville',
      state: 'PA',
    },
    15870: {
      lat: 41.58116,
      lng: -78.57935,
      city: 'Wilcox',
      state: 'PA',
    },
    15901: {
      lat: 40.32845,
      lng: -78.9146,
      city: 'Johnstown',
      state: 'PA',
    },
    15902: {
      lat: 40.32,
      lng: -78.87911,
      city: 'Johnstown',
      state: 'PA',
    },
    15904: {
      lat: 40.3056,
      lng: -78.84382,
      city: 'Johnstown',
      state: 'PA',
    },
    15905: {
      lat: 40.29423,
      lng: -78.98035,
      city: 'Johnstown',
      state: 'PA',
    },
    15906: {
      lat: 40.37806,
      lng: -78.93356,
      city: 'Johnstown',
      state: 'PA',
    },
    15909: {
      lat: 40.40783,
      lng: -78.87047,
      city: 'Johnstown',
      state: 'PA',
    },
    15920: {
      lat: 40.46705,
      lng: -79.05006,
      city: 'Armagh',
      state: 'PA',
    },
    15921: {
      lat: 40.31642,
      lng: -78.69786,
      city: 'Beaverdale',
      state: 'PA',
    },
    15922: {
      lat: 40.51853,
      lng: -78.87794,
      city: 'Belsano',
      state: 'PA',
    },
    15923: {
      lat: 40.35518,
      lng: -79.16447,
      city: 'Bolivar',
      state: 'PA',
    },
    15924: {
      lat: 40.10684,
      lng: -78.77527,
      city: 'Cairnbrook',
      state: 'PA',
    },
    15925: {
      lat: 40.40851,
      lng: -78.6407,
      city: 'Cassandra',
      state: 'PA',
    },
    15926: {
      lat: 40.04783,
      lng: -78.81934,
      city: 'Central City',
      state: 'PA',
    },
    15927: {
      lat: 40.54148,
      lng: -78.78019,
      city: 'Colver',
      state: 'PA',
    },
    15928: {
      lat: 40.23943,
      lng: -78.91591,
      city: 'Davidsville',
      state: 'PA',
    },
    15929: {
      lat: 40.47683,
      lng: -79.0042,
      city: 'Dilltown',
      state: 'PA',
    },
    15930: {
      lat: 40.29468,
      lng: -78.71732,
      city: 'Dunlo',
      state: 'PA',
    },
    15931: {
      lat: 40.51612,
      lng: -78.76225,
      city: 'Ebensburg',
      state: 'PA',
    },
    15934: {
      lat: 40.28107,
      lng: -78.79589,
      city: 'Elton',
      state: 'PA',
    },
    15935: {
      lat: 40.20112,
      lng: -78.96755,
      city: 'Hollsopple',
      state: 'PA',
    },
    15936: {
      lat: 40.16221,
      lng: -78.89584,
      city: 'Hooversville',
      state: 'PA',
    },
    15937: {
      lat: 40.21463,
      lng: -78.98105,
      city: 'Jerome',
      state: 'PA',
    },
    15938: { lat: 40.42215, lng: -78.6057, city: 'Lilly', state: 'PA' },
    15940: {
      lat: 40.52128,
      lng: -78.63218,
      city: 'Loretto',
      state: 'PA',
    },
    15942: {
      lat: 40.40584,
      lng: -78.81215,
      city: 'Mineral Point',
      state: 'PA',
    },
    15943: {
      lat: 40.47437,
      lng: -78.83513,
      city: 'Nanty Glo',
      state: 'PA',
    },
    15944: {
      lat: 40.36295,
      lng: -79.0816,
      city: 'New Florence',
      state: 'PA',
    },
    15945: {
      lat: 40.35909,
      lng: -78.86867,
      city: 'Parkhill',
      state: 'PA',
    },
    15946: {
      lat: 40.37248,
      lng: -78.63573,
      city: 'Portage',
      state: 'PA',
    },
    15948: {
      lat: 40.48986,
      lng: -78.76699,
      city: 'Revloc',
      state: 'PA',
    },
    15949: {
      lat: 40.4055,
      lng: -79.13442,
      city: 'Robinson',
      state: 'PA',
    },
    15951: {
      lat: 40.3307,
      lng: -78.77209,
      city: 'Saint Michael',
      state: 'PA',
    },
    15952: {
      lat: 40.30348,
      lng: -78.77614,
      city: 'Salix',
      state: 'PA',
    },
    15953: {
      lat: 40.21113,
      lng: -78.89049,
      city: 'Seanor',
      state: 'PA',
    },
    15954: {
      lat: 40.41837,
      lng: -79.00051,
      city: 'Seward',
      state: 'PA',
    },
    15955: {
      lat: 40.30878,
      lng: -78.69285,
      city: 'Sidman',
      state: 'PA',
    },
    15956: {
      lat: 40.35022,
      lng: -78.78819,
      city: 'South Fork',
      state: 'PA',
    },
    15957: {
      lat: 40.53953,
      lng: -78.89744,
      city: 'Strongstown',
      state: 'PA',
    },
    15958: {
      lat: 40.3891,
      lng: -78.73284,
      city: 'Summerhill',
      state: 'PA',
    },
    15960: {
      lat: 40.49848,
      lng: -78.85184,
      city: 'Twin Rocks',
      state: 'PA',
    },
    15961: {
      lat: 40.46187,
      lng: -78.9382,
      city: 'Vintondale',
      state: 'PA',
    },
    15962: {
      lat: 40.38942,
      lng: -78.71644,
      city: 'Wilmore',
      state: 'PA',
    },
    15963: {
      lat: 40.21676,
      lng: -78.77234,
      city: 'Windber',
      state: 'PA',
    },
    16001: {
      lat: 40.91049,
      lng: -79.94376,
      city: 'Butler',
      state: 'PA',
    },
    16002: {
      lat: 40.81568,
      lng: -79.85638,
      city: 'Butler',
      state: 'PA',
    },
    16020: {
      lat: 41.11651,
      lng: -79.89648,
      city: 'Boyers',
      state: 'PA',
    },
    16022: { lat: 41.05461, lng: -79.7333, city: 'Bruin', state: 'PA' },
    16023: {
      lat: 40.78495,
      lng: -79.74921,
      city: 'Cabot',
      state: 'PA',
    },
    16024: {
      lat: 40.73961,
      lng: -80.03719,
      city: 'Callery',
      state: 'PA',
    },
    16025: {
      lat: 40.94464,
      lng: -79.75841,
      city: 'Chicora',
      state: 'PA',
    },
    16027: {
      lat: 40.81822,
      lng: -80.01531,
      city: 'Connoquenessing',
      state: 'PA',
    },
    16028: {
      lat: 40.95684,
      lng: -79.63985,
      city: 'East Brady',
      state: 'PA',
    },
    16029: {
      lat: 40.87996,
      lng: -79.84602,
      city: 'East Butler',
      state: 'PA',
    },
    16030: {
      lat: 41.13619,
      lng: -79.79699,
      city: 'Eau Claire',
      state: 'PA',
    },
    16033: {
      lat: 40.79355,
      lng: -80.04618,
      city: 'Evans City',
      state: 'PA',
    },
    16034: {
      lat: 40.86051,
      lng: -79.7288,
      city: 'Fenelton',
      state: 'PA',
    },
    16035: {
      lat: 41.10779,
      lng: -80.01659,
      city: 'Forestville',
      state: 'PA',
    },
    16036: {
      lat: 41.13519,
      lng: -79.66948,
      city: 'Foxburg',
      state: 'PA',
    },
    16037: {
      lat: 40.85103,
      lng: -80.12592,
      city: 'Harmony',
      state: 'PA',
    },
    16038: {
      lat: 41.15967,
      lng: -79.95042,
      city: 'Harrisville',
      state: 'PA',
    },
    16040: {
      lat: 41.09399,
      lng: -79.84537,
      city: 'Hilliards',
      state: 'PA',
    },
    16041: {
      lat: 41.00693,
      lng: -79.71395,
      city: 'Karns City',
      state: 'PA',
    },
    16045: {
      lat: 40.85277,
      lng: -79.91626,
      city: 'Lyndora',
      state: 'PA',
    },
    16046: { lat: 40.70192, lng: -80.02866, city: 'Mars', state: 'PA' },
    16048: {
      lat: 41.05217,
      lng: -79.80349,
      city: 'North Washington',
      state: 'PA',
    },
    16049: {
      lat: 41.09622,
      lng: -79.67258,
      city: 'Parker',
      state: 'PA',
    },
    16050: {
      lat: 41.04362,
      lng: -79.77379,
      city: 'Petrolia',
      state: 'PA',
    },
    16051: {
      lat: 40.94459,
      lng: -80.14152,
      city: 'Portersville',
      state: 'PA',
    },
    16052: {
      lat: 40.90579,
      lng: -80.06154,
      city: 'Prospect',
      state: 'PA',
    },
    16053: {
      lat: 40.81194,
      lng: -79.9826,
      city: 'Renfrew',
      state: 'PA',
    },
    16054: {
      lat: 41.15651,
      lng: -79.65711,
      city: 'Saint Petersburg',
      state: 'PA',
    },
    16055: {
      lat: 40.7157,
      lng: -79.74753,
      city: 'Sarver',
      state: 'PA',
    },
    16056: {
      lat: 40.72301,
      lng: -79.83911,
      city: 'Saxonburg',
      state: 'PA',
    },
    16057: {
      lat: 41.03427,
      lng: -80.0579,
      city: 'Slippery Rock',
      state: 'PA',
    },
    16059: {
      lat: 40.70425,
      lng: -79.92812,
      city: 'Valencia',
      state: 'PA',
    },
    16061: {
      lat: 41.00866,
      lng: -79.88687,
      city: 'West Sunbury',
      state: 'PA',
    },
    16063: {
      lat: 40.76352,
      lng: -80.12773,
      city: 'Zelienople',
      state: 'PA',
    },
    16066: {
      lat: 40.71114,
      lng: -80.10606,
      city: 'Cranberry Township',
      state: 'PA',
    },
    16101: {
      lat: 40.98776,
      lng: -80.28814,
      city: 'New Castle',
      state: 'PA',
    },
    16102: {
      lat: 40.96173,
      lng: -80.41616,
      city: 'New Castle',
      state: 'PA',
    },
    16105: {
      lat: 41.05285,
      lng: -80.33918,
      city: 'New Castle',
      state: 'PA',
    },
    16110: {
      lat: 41.49757,
      lng: -80.38591,
      city: 'Adamsville',
      state: 'PA',
    },
    16111: {
      lat: 41.51936,
      lng: -80.28463,
      city: 'Atlantic',
      state: 'PA',
    },
    16112: {
      lat: 40.96812,
      lng: -80.50099,
      city: 'Bessemer',
      state: 'PA',
    },
    16113: { lat: 41.2828, lng: -80.42569, city: 'Clark', state: 'PA' },
    16114: {
      lat: 41.40432,
      lng: -80.18766,
      city: 'Clarks Mills',
      state: 'PA',
    },
    16115: {
      lat: 40.79433,
      lng: -80.46289,
      city: 'Darlington',
      state: 'PA',
    },
    16116: {
      lat: 41.03744,
      lng: -80.45212,
      city: 'Edinburg',
      state: 'PA',
    },
    16117: {
      lat: 40.8743,
      lng: -80.2568,
      city: 'Ellwood City',
      state: 'PA',
    },
    16120: {
      lat: 40.892,
      lng: -80.47344,
      city: 'Enon Valley',
      state: 'PA',
    },
    16121: {
      lat: 41.21121,
      lng: -80.49696,
      city: 'Farrell',
      state: 'PA',
    },
    16123: {
      lat: 40.82389,
      lng: -80.19844,
      city: 'Fombell',
      state: 'PA',
    },
    16124: {
      lat: 41.33336,
      lng: -80.26486,
      city: 'Fredonia',
      state: 'PA',
    },
    16125: {
      lat: 41.40565,
      lng: -80.37135,
      city: 'Greenville',
      state: 'PA',
    },
    16127: {
      lat: 41.17433,
      lng: -80.07002,
      city: 'Grove City',
      state: 'PA',
    },
    16130: {
      lat: 41.44315,
      lng: -80.21668,
      city: 'Hadley',
      state: 'PA',
    },
    16131: {
      lat: 41.54908,
      lng: -80.37617,
      city: 'Hartstown',
      state: 'PA',
    },
    16132: {
      lat: 41.00603,
      lng: -80.50449,
      city: 'Hillsville',
      state: 'PA',
    },
    16133: {
      lat: 41.27101,
      lng: -80.11562,
      city: 'Jackson Center',
      state: 'PA',
    },
    16134: {
      lat: 41.50315,
      lng: -80.46571,
      city: 'Jamestown',
      state: 'PA',
    },
    16136: {
      lat: 40.83425,
      lng: -80.32369,
      city: 'Koppel',
      state: 'PA',
    },
    16137: {
      lat: 41.23219,
      lng: -80.2397,
      city: 'Mercer',
      state: 'PA',
    },
    16140: {
      lat: 41.09355,
      lng: -80.51078,
      city: 'New Bedford',
      state: 'PA',
    },
    16141: {
      lat: 40.87237,
      lng: -80.3968,
      city: 'New Galilee',
      state: 'PA',
    },
    16142: {
      lat: 41.13908,
      lng: -80.34084,
      city: 'New Wilmington',
      state: 'PA',
    },
    16143: {
      lat: 41.10528,
      lng: -80.45434,
      city: 'Pulaski',
      state: 'PA',
    },
    16145: {
      lat: 41.38574,
      lng: -80.07422,
      city: 'Sandy Lake',
      state: 'PA',
    },
    16146: {
      lat: 41.23478,
      lng: -80.49932,
      city: 'Sharon',
      state: 'PA',
    },
    16148: {
      lat: 41.23147,
      lng: -80.43199,
      city: 'Hermitage',
      state: 'PA',
    },
    16150: {
      lat: 41.28687,
      lng: -80.44509,
      city: 'Sharpsville',
      state: 'PA',
    },
    16151: {
      lat: 41.4428,
      lng: -80.2056,
      city: 'Sheakleyville',
      state: 'PA',
    },
    16153: {
      lat: 41.31845,
      lng: -80.08122,
      city: 'Stoneboro',
      state: 'PA',
    },
    16154: {
      lat: 41.32539,
      lng: -80.42111,
      city: 'Transfer',
      state: 'PA',
    },
    16155: {
      lat: 41.06771,
      lng: -80.51073,
      city: 'Villa Maria',
      state: 'PA',
    },
    16156: {
      lat: 41.09702,
      lng: -80.21943,
      city: 'Volant',
      state: 'PA',
    },
    16157: {
      lat: 40.88902,
      lng: -80.33509,
      city: 'Wampum',
      state: 'PA',
    },
    16159: {
      lat: 41.15967,
      lng: -80.46858,
      city: 'West Middlesex',
      state: 'PA',
    },
    16160: {
      lat: 40.9283,
      lng: -80.36235,
      city: 'West Pittsburg',
      state: 'PA',
    },
    16161: {
      lat: 41.20232,
      lng: -80.49282,
      city: 'Wheatland',
      state: 'PA',
    },
    16172: {
      lat: 41.1177,
      lng: -80.33105,
      city: 'New Wilmington',
      state: 'PA',
    },
    16201: {
      lat: 40.80984,
      lng: -79.47204,
      city: 'Kittanning',
      state: 'PA',
    },
    16210: { lat: 40.89697, lng: -79.519, city: 'Adrian', state: 'PA' },
    16211: { lat: 40.79436, lng: -79.2023, city: 'Beyer', state: 'PA' },
    16212: {
      lat: 40.75239,
      lng: -79.57795,
      city: 'Cadogan',
      state: 'PA',
    },
    16213: {
      lat: 41.12556,
      lng: -79.55831,
      city: 'Callensburg',
      state: 'PA',
    },
    16214: {
      lat: 41.2008,
      lng: -79.36046,
      city: 'Clarion',
      state: 'PA',
    },
    16217: {
      lat: 41.34908,
      lng: -79.18095,
      city: 'Cooksburg',
      state: 'PA',
    },
    16218: {
      lat: 40.92705,
      lng: -79.59325,
      city: 'Cowansville',
      state: 'PA',
    },
    16220: {
      lat: 41.41817,
      lng: -79.26659,
      city: 'Crown',
      state: 'PA',
    },
    16222: {
      lat: 40.87171,
      lng: -79.25719,
      city: 'Dayton',
      state: 'PA',
    },
    16223: {
      lat: 40.98701,
      lng: -79.35381,
      city: 'Distant',
      state: 'PA',
    },
    16224: {
      lat: 41.06314,
      lng: -79.29245,
      city: 'Fairmount City',
      state: 'PA',
    },
    16226: {
      lat: 40.71139,
      lng: -79.48608,
      city: 'Ford City',
      state: 'PA',
    },
    16228: {
      lat: 40.76076,
      lng: -79.5358,
      city: 'Ford Cliff',
      state: 'PA',
    },
    16229: {
      lat: 40.71624,
      lng: -79.64567,
      city: 'Freeport',
      state: 'PA',
    },
    16230: {
      lat: 41.02099,
      lng: -79.27817,
      city: 'Hawthorn',
      state: 'PA',
    },
    16232: { lat: 41.22197, lng: -79.54923, city: 'Knox', state: 'PA' },
    16233: {
      lat: 41.36162,
      lng: -79.27588,
      city: 'Leeper',
      state: 'PA',
    },
    16235: {
      lat: 41.31759,
      lng: -79.34943,
      city: 'Lucinda',
      state: 'PA',
    },
    16236: {
      lat: 40.78119,
      lng: -79.52376,
      city: 'McGrann',
      state: 'PA',
    },
    16238: {
      lat: 40.78778,
      lng: -79.52036,
      city: 'Manorville',
      state: 'PA',
    },
    16239: {
      lat: 41.49507,
      lng: -79.1297,
      city: 'Marienville',
      state: 'PA',
    },
    16240: {
      lat: 41.03316,
      lng: -79.22432,
      city: 'Mayport',
      state: 'PA',
    },
    16242: {
      lat: 41.0031,
      lng: -79.35479,
      city: 'New Bethlehem',
      state: 'PA',
    },
    16244: {
      lat: 40.80499,
      lng: -79.27905,
      city: 'Nu Mine',
      state: 'PA',
    },
    16245: {
      lat: 41.00153,
      lng: -79.29311,
      city: 'Oak Ridge',
      state: 'PA',
    },
    16246: {
      lat: 40.79167,
      lng: -79.18215,
      city: 'Plumville',
      state: 'PA',
    },
    16248: {
      lat: 41.03412,
      lng: -79.50348,
      city: 'Rimersburg',
      state: 'PA',
    },
    16249: {
      lat: 40.76209,
      lng: -79.32163,
      city: 'Rural Valley',
      state: 'PA',
    },
    16250: {
      lat: 40.77566,
      lng: -79.23782,
      city: 'Sagamore',
      state: 'PA',
    },
    16253: {
      lat: 40.954,
      lng: -79.34106,
      city: 'Seminole',
      state: 'PA',
    },
    16254: {
      lat: 41.26557,
      lng: -79.45259,
      city: 'Shippenville',
      state: 'PA',
    },
    16255: { lat: 41.1267, lng: -79.46809, city: 'Sligo', state: 'PA' },
    16256: {
      lat: 40.8661,
      lng: -79.13611,
      city: 'Smicksburg',
      state: 'PA',
    },
    16258: {
      lat: 41.2307,
      lng: -79.27217,
      city: 'Strattanville',
      state: 'PA',
    },
    16259: {
      lat: 40.92699,
      lng: -79.45692,
      city: 'Templeton',
      state: 'PA',
    },
    16260: {
      lat: 41.41102,
      lng: -79.23092,
      city: 'Vowinckel',
      state: 'PA',
    },
    16262: {
      lat: 40.8377,
      lng: -79.65266,
      city: 'Worthington',
      state: 'PA',
    },
    16263: {
      lat: 40.80118,
      lng: -79.34108,
      city: 'Yatesboro',
      state: 'PA',
    },
    16301: {
      lat: 41.45596,
      lng: -79.64946,
      city: 'Oil City',
      state: 'PA',
    },
    16311: {
      lat: 41.45706,
      lng: -80.03477,
      city: 'Carlton',
      state: 'PA',
    },
    16312: {
      lat: 41.93218,
      lng: -79.30324,
      city: 'Chandlers Valley',
      state: 'PA',
    },
    16313: {
      lat: 41.72294,
      lng: -79.13884,
      city: 'Clarendon',
      state: 'PA',
    },
    16314: {
      lat: 41.51895,
      lng: -80.06782,
      city: 'Cochranton',
      state: 'PA',
    },
    16316: {
      lat: 41.6012,
      lng: -80.29958,
      city: 'Conneaut Lake',
      state: 'PA',
    },
    16317: {
      lat: 41.53232,
      lng: -79.85258,
      city: 'Cooperstown',
      state: 'PA',
    },
    16319: {
      lat: 41.3216,
      lng: -79.62942,
      city: 'Cranberry',
      state: 'PA',
    },
    16321: {
      lat: 41.56835,
      lng: -79.40084,
      city: 'East Hickory',
      state: 'PA',
    },
    16322: {
      lat: 41.60567,
      lng: -79.38428,
      city: 'Endeavor',
      state: 'PA',
    },
    16323: {
      lat: 41.41286,
      lng: -79.83047,
      city: 'Franklin',
      state: 'PA',
    },
    16326: {
      lat: 41.36733,
      lng: -79.43182,
      city: 'Fryburg',
      state: 'PA',
    },
    16327: {
      lat: 41.6323,
      lng: -79.95878,
      city: 'Guys Mills',
      state: 'PA',
    },
    16328: {
      lat: 41.651,
      lng: -79.72716,
      city: 'Hydetown',
      state: 'PA',
    },
    16329: {
      lat: 41.80642,
      lng: -79.27684,
      city: 'Irvine',
      state: 'PA',
    },
    16331: {
      lat: 41.29047,
      lng: -79.56435,
      city: 'Kossuth',
      state: 'PA',
    },
    16332: {
      lat: 41.36096,
      lng: -79.36873,
      city: 'Lickingville',
      state: 'PA',
    },
    16333: {
      lat: 41.74922,
      lng: -78.9323,
      city: 'Ludlow',
      state: 'PA',
    },
    16334: {
      lat: 41.29749,
      lng: -79.44968,
      city: 'Marble',
      state: 'PA',
    },
    16335: {
      lat: 41.63143,
      lng: -80.15378,
      city: 'Meadville',
      state: 'PA',
    },
    16340: {
      lat: 41.79864,
      lng: -79.41749,
      city: 'Pittsfield',
      state: 'PA',
    },
    16341: {
      lat: 41.56288,
      lng: -79.54703,
      city: 'Pleasantville',
      state: 'PA',
    },
    16342: { lat: 41.31995, lng: -79.9294, city: 'Polk', state: 'PA' },
    16343: { lat: 41.42204, lng: -79.75233, city: 'Reno', state: 'PA' },
    16344: {
      lat: 41.47045,
      lng: -79.684,
      city: 'Rouseville',
      state: 'PA',
    },
    16345: {
      lat: 41.95085,
      lng: -79.07548,
      city: 'Russell',
      state: 'PA',
    },
    16346: {
      lat: 41.37845,
      lng: -79.6727,
      city: 'Seneca',
      state: 'PA',
    },
    16347: {
      lat: 41.64671,
      lng: -79.06481,
      city: 'Sheffield',
      state: 'PA',
    },
    16350: {
      lat: 41.96323,
      lng: -79.3322,
      city: 'Sugar Grove',
      state: 'PA',
    },
    16351: {
      lat: 41.67583,
      lng: -79.37285,
      city: 'Tidioute',
      state: 'PA',
    },
    16352: {
      lat: 41.76615,
      lng: -79.03644,
      city: 'Tiona',
      state: 'PA',
    },
    16353: {
      lat: 41.49359,
      lng: -79.38839,
      city: 'Tionesta',
      state: 'PA',
    },
    16354: {
      lat: 41.61476,
      lng: -79.70637,
      city: 'Titusville',
      state: 'PA',
    },
    16360: {
      lat: 41.68167,
      lng: -79.89028,
      city: 'Townville',
      state: 'PA',
    },
    16361: {
      lat: 41.38597,
      lng: -79.3422,
      city: 'Tylersburg',
      state: 'PA',
    },
    16362: {
      lat: 41.45032,
      lng: -79.96284,
      city: 'Utica',
      state: 'PA',
    },
    16364: {
      lat: 41.36379,
      lng: -79.51517,
      city: 'Venus',
      state: 'PA',
    },
    16365: {
      lat: 41.83943,
      lng: -79.15324,
      city: 'Warren',
      state: 'PA',
    },
    16370: {
      lat: 41.56586,
      lng: -79.44796,
      city: 'West Hickory',
      state: 'PA',
    },
    16371: {
      lat: 41.86751,
      lng: -79.3187,
      city: 'Youngsville',
      state: 'PA',
    },
    16372: {
      lat: 41.20121,
      lng: -79.87406,
      city: 'Clintonville',
      state: 'PA',
    },
    16373: {
      lat: 41.20786,
      lng: -79.69796,
      city: 'Emlenton',
      state: 'PA',
    },
    16374: {
      lat: 41.26136,
      lng: -79.81066,
      city: 'Kennerdell',
      state: 'PA',
    },
    16401: {
      lat: 41.88044,
      lng: -80.40395,
      city: 'Albion',
      state: 'PA',
    },
    16402: {
      lat: 41.96459,
      lng: -79.48076,
      city: 'Bear Lake',
      state: 'PA',
    },
    16403: {
      lat: 41.7912,
      lng: -80.02017,
      city: 'Cambridge Springs',
      state: 'PA',
    },
    16404: {
      lat: 41.72483,
      lng: -79.79018,
      city: 'Centerville',
      state: 'PA',
    },
    16405: {
      lat: 41.938,
      lng: -79.5335,
      city: 'Columbus',
      state: 'PA',
    },
    16406: {
      lat: 41.75047,
      lng: -80.36273,
      city: 'Conneautville',
      state: 'PA',
    },
    16407: {
      lat: 41.91743,
      lng: -79.66374,
      city: 'Corry',
      state: 'PA',
    },
    16410: {
      lat: 41.92012,
      lng: -80.30482,
      city: 'Cranesville',
      state: 'PA',
    },
    16411: {
      lat: 41.97781,
      lng: -80.45037,
      city: 'East Springfield',
      state: 'PA',
    },
    16412: {
      lat: 41.88212,
      lng: -80.16392,
      city: 'Edinboro',
      state: 'PA',
    },
    16415: {
      lat: 42.03122,
      lng: -80.22873,
      city: 'Fairview',
      state: 'PA',
    },
    16416: {
      lat: 41.82606,
      lng: -79.47311,
      city: 'Garland',
      state: 'PA',
    },
    16417: {
      lat: 41.97107,
      lng: -80.31039,
      city: 'Girard',
      state: 'PA',
    },
    16420: {
      lat: 41.69481,
      lng: -79.54544,
      city: 'Grand Valley',
      state: 'PA',
    },
    16421: {
      lat: 42.17036,
      lng: -79.9351,
      city: 'Harborcreek',
      state: 'PA',
    },
    16422: {
      lat: 41.66555,
      lng: -80.31447,
      city: 'Harmonsburg',
      state: 'PA',
    },
    16423: {
      lat: 42.01881,
      lng: -80.34253,
      city: 'Lake City',
      state: 'PA',
    },
    16424: {
      lat: 41.65887,
      lng: -80.44175,
      city: 'Linesville',
      state: 'PA',
    },
    16426: {
      lat: 41.98121,
      lng: -80.13937,
      city: 'McKean',
      state: 'PA',
    },
    16427: {
      lat: 41.87466,
      lng: -79.97132,
      city: 'Mill Village',
      state: 'PA',
    },
    16428: {
      lat: 42.17109,
      lng: -79.82907,
      city: 'North East',
      state: 'PA',
    },
    16430: {
      lat: 42.00413,
      lng: -80.41823,
      city: 'North Springfield',
      state: 'PA',
    },
    16433: {
      lat: 41.73553,
      lng: -80.14316,
      city: 'Saegertown',
      state: 'PA',
    },
    16434: {
      lat: 41.79442,
      lng: -79.66765,
      city: 'Spartansburg',
      state: 'PA',
    },
    16435: {
      lat: 41.81937,
      lng: -80.38356,
      city: 'Springboro',
      state: 'PA',
    },
    16436: {
      lat: 41.85563,
      lng: -79.49889,
      city: 'Spring Creek',
      state: 'PA',
    },
    16438: {
      lat: 41.89084,
      lng: -79.84709,
      city: 'Union City',
      state: 'PA',
    },
    16440: {
      lat: 41.78673,
      lng: -80.13088,
      city: 'Venango',
      state: 'PA',
    },
    16441: {
      lat: 41.9554,
      lng: -79.98552,
      city: 'Waterford',
      state: 'PA',
    },
    16442: {
      lat: 42.04094,
      lng: -79.82631,
      city: 'Wattsburg',
      state: 'PA',
    },
    16443: {
      lat: 41.93745,
      lng: -80.47831,
      city: 'West Springfield',
      state: 'PA',
    },
    16444: {
      lat: 41.87071,
      lng: -80.12175,
      city: 'Edinboro',
      state: 'PA',
    },
    16475: {
      lat: 41.87645,
      lng: -80.36107,
      city: 'Albion',
      state: 'PA',
    },
    16501: { lat: 42.12096, lng: -80.08958, city: 'Erie', state: 'PA' },
    16502: { lat: 42.11073, lng: -80.10123, city: 'Erie', state: 'PA' },
    16503: { lat: 42.12718, lng: -80.06103, city: 'Erie', state: 'PA' },
    16504: { lat: 42.10942, lng: -80.0489, city: 'Erie', state: 'PA' },
    16505: { lat: 42.11789, lng: -80.14304, city: 'Erie', state: 'PA' },
    16506: { lat: 42.06449, lng: -80.15472, city: 'Erie', state: 'PA' },
    16507: { lat: 42.13575, lng: -80.08743, city: 'Erie', state: 'PA' },
    16508: { lat: 42.09719, lng: -80.09249, city: 'Erie', state: 'PA' },
    16509: { lat: 42.05861, lng: -80.04419, city: 'Erie', state: 'PA' },
    16510: { lat: 42.11184, lng: -79.95395, city: 'Erie', state: 'PA' },
    16511: { lat: 42.16529, lng: -79.99667, city: 'Erie', state: 'PA' },
    16546: { lat: 42.10389, lng: -80.05338, city: 'Erie', state: 'PA' },
    16550: { lat: 42.13378, lng: -80.08495, city: 'Erie', state: 'PA' },
    16563: { lat: 42.11929, lng: -79.98643, city: 'Erie', state: 'PA' },
    16601: {
      lat: 40.55338,
      lng: -78.37283,
      city: 'Altoona',
      state: 'PA',
    },
    16602: {
      lat: 40.51491,
      lng: -78.37052,
      city: 'Altoona',
      state: 'PA',
    },
    16611: {
      lat: 40.56719,
      lng: -78.10214,
      city: 'Alexandria',
      state: 'PA',
    },
    16613: {
      lat: 40.53723,
      lng: -78.53214,
      city: 'Ashville',
      state: 'PA',
    },
    16616: {
      lat: 40.76832,
      lng: -78.45128,
      city: 'Beccaria',
      state: 'PA',
    },
    16617: {
      lat: 40.6036,
      lng: -78.32995,
      city: 'Bellwood',
      state: 'PA',
    },
    16619: {
      lat: 40.68367,
      lng: -78.41591,
      city: 'Blandburg',
      state: 'PA',
    },
    16620: {
      lat: 40.83947,
      lng: -78.35259,
      city: 'Brisbin',
      state: 'PA',
    },
    16621: {
      lat: 40.22888,
      lng: -78.12298,
      city: 'Broad Top',
      state: 'PA',
    },
    16622: {
      lat: 40.32853,
      lng: -78.0692,
      city: 'Calvin',
      state: 'PA',
    },
    16623: {
      lat: 40.27618,
      lng: -78.04556,
      city: 'Cassville',
      state: 'PA',
    },
    16624: {
      lat: 40.5651,
      lng: -78.60664,
      city: 'Chest Springs',
      state: 'PA',
    },
    16625: {
      lat: 40.28482,
      lng: -78.50855,
      city: 'Claysburg',
      state: 'PA',
    },
    16627: {
      lat: 40.75272,
      lng: -78.50036,
      city: 'Coalport',
      state: 'PA',
    },
    16630: {
      lat: 40.46231,
      lng: -78.58003,
      city: 'Cresson',
      state: 'PA',
    },
    16631: {
      lat: 40.27141,
      lng: -78.34754,
      city: 'Curryville',
      state: 'PA',
    },
    16633: {
      lat: 40.15969,
      lng: -78.23259,
      city: 'Defiance',
      state: 'PA',
    },
    16634: {
      lat: 40.2105,
      lng: -78.17308,
      city: 'Dudley',
      state: 'PA',
    },
    16635: {
      lat: 40.41621,
      lng: -78.48743,
      city: 'Duncansville',
      state: 'PA',
    },
    16636: {
      lat: 40.60214,
      lng: -78.50161,
      city: 'Dysart',
      state: 'PA',
    },
    16637: {
      lat: 40.34214,
      lng: -78.47079,
      city: 'East Freedom',
      state: 'PA',
    },
    16638: {
      lat: 40.33809,
      lng: -78.2012,
      city: 'Entriken',
      state: 'PA',
    },
    16639: {
      lat: 40.68566,
      lng: -78.44912,
      city: 'Fallentimber',
      state: 'PA',
    },
    16640: {
      lat: 40.70657,
      lng: -78.5702,
      city: 'Flinton',
      state: 'PA',
    },
    16641: {
      lat: 40.50774,
      lng: -78.57146,
      city: 'Gallitzin',
      state: 'PA',
    },
    16645: {
      lat: 40.80204,
      lng: -78.49932,
      city: 'Glen Hope',
      state: 'PA',
    },
    16646: {
      lat: 40.68291,
      lng: -78.71579,
      city: 'Hastings',
      state: 'PA',
    },
    16647: {
      lat: 40.39514,
      lng: -78.09798,
      city: 'Hesston',
      state: 'PA',
    },
    16648: {
      lat: 40.44231,
      lng: -78.33242,
      city: 'Hollidaysburg',
      state: 'PA',
    },
    16650: {
      lat: 40.11592,
      lng: -78.29237,
      city: 'Hopewell',
      state: 'PA',
    },
    16651: {
      lat: 40.8505,
      lng: -78.37945,
      city: 'Houtzdale',
      state: 'PA',
    },
    16652: {
      lat: 40.51692,
      lng: -77.95302,
      city: 'Huntingdon',
      state: 'PA',
    },
    16654: {
      lat: 40.49189,
      lng: -78.02854,
      city: 'Huntingdon',
      state: 'PA',
    },
    16655: {
      lat: 40.24898,
      lng: -78.55092,
      city: 'Imler',
      state: 'PA',
    },
    16656: {
      lat: 40.8066,
      lng: -78.56353,
      city: 'Irvona',
      state: 'PA',
    },
    16657: {
      lat: 40.32486,
      lng: -78.17757,
      city: 'James Creek',
      state: 'PA',
    },
    16659: {
      lat: 40.16063,
      lng: -78.39138,
      city: 'Loysburg',
      state: 'PA',
    },
    16661: {
      lat: 40.83058,
      lng: -78.45026,
      city: 'Madera',
      state: 'PA',
    },
    16662: {
      lat: 40.30635,
      lng: -78.29961,
      city: 'Martinsburg',
      state: 'PA',
    },
    16664: {
      lat: 40.17822,
      lng: -78.42437,
      city: 'New Enterprise',
      state: 'PA',
    },
    16665: {
      lat: 40.38898,
      lng: -78.43491,
      city: 'Newry',
      state: 'PA',
    },
    16666: {
      lat: 40.87056,
      lng: -78.31286,
      city: 'Osceola Mills',
      state: 'PA',
    },
    16667: {
      lat: 40.18213,
      lng: -78.52546,
      city: 'Osterburg',
      state: 'PA',
    },
    16668: {
      lat: 40.65072,
      lng: -78.61974,
      city: 'Patton',
      state: 'PA',
    },
    16669: {
      lat: 40.65737,
      lng: -77.90675,
      city: 'Petersburg',
      state: 'PA',
    },
    16670: {
      lat: 40.25828,
      lng: -78.50556,
      city: 'Queen',
      state: 'PA',
    },
    16671: {
      lat: 40.79414,
      lng: -78.40203,
      city: 'Ramey',
      state: 'PA',
    },
    16672: {
      lat: 40.16935,
      lng: -78.24324,
      city: 'Riddlesburg',
      state: 'PA',
    },
    16673: {
      lat: 40.30671,
      lng: -78.39026,
      city: 'Roaring Spring',
      state: 'PA',
    },
    16674: {
      lat: 40.1865,
      lng: -78.07926,
      city: 'Robertsdale',
      state: 'PA',
    },
    16675: {
      lat: 40.68796,
      lng: -78.67905,
      city: 'Saint Boniface',
      state: 'PA',
    },
    16677: {
      lat: 40.81507,
      lng: -78.2457,
      city: 'Sandy Ridge',
      state: 'PA',
    },
    16678: {
      lat: 40.2252,
      lng: -78.23421,
      city: 'Saxton',
      state: 'PA',
    },
    16679: {
      lat: 40.15659,
      lng: -78.195,
      city: 'Six Mile Run',
      state: 'PA',
    },
    16680: {
      lat: 40.75149,
      lng: -78.40254,
      city: 'Smithmill',
      state: 'PA',
    },
    16682: {
      lat: 40.27206,
      lng: -78.4565,
      city: 'Sproul',
      state: 'PA',
    },
    16683: {
      lat: 40.65595,
      lng: -78.07313,
      city: 'Spruce Creek',
      state: 'PA',
    },
    16684: {
      lat: 40.63541,
      lng: -78.29685,
      city: 'Tipton',
      state: 'PA',
    },
    16685: { lat: 40.28019, lng: -78.08063, city: 'Todd', state: 'PA' },
    16686: {
      lat: 40.67056,
      lng: -78.24102,
      city: 'Tyrone',
      state: 'PA',
    },
    16689: {
      lat: 40.10737,
      lng: -78.10028,
      city: 'Waterfall',
      state: 'PA',
    },
    16691: {
      lat: 40.07744,
      lng: -78.1473,
      city: 'Wells Tannery',
      state: 'PA',
    },
    16692: {
      lat: 40.75935,
      lng: -78.72053,
      city: 'Westover',
      state: 'PA',
    },
    16693: {
      lat: 40.46519,
      lng: -78.21593,
      city: 'Williamsburg',
      state: 'PA',
    },
    16694: { lat: 40.17259, lng: -78.12852, city: 'Wood', state: 'PA' },
    16695: {
      lat: 40.20327,
      lng: -78.35943,
      city: 'Woodbury',
      state: 'PA',
    },
    16699: {
      lat: 40.44736,
      lng: -78.56054,
      city: 'Cresson',
      state: 'PA',
    },
    16701: {
      lat: 41.91105,
      lng: -78.77385,
      city: 'Bradford',
      state: 'PA',
    },
    16720: {
      lat: 41.5793,
      lng: -78.02599,
      city: 'Austin',
      state: 'PA',
    },
    16724: {
      lat: 41.74674,
      lng: -78.39022,
      city: 'Crosby',
      state: 'PA',
    },
    16725: {
      lat: 41.89881,
      lng: -78.66328,
      city: 'Custer City',
      state: 'PA',
    },
    16726: {
      lat: 41.80456,
      lng: -78.57787,
      city: 'Cyclone',
      state: 'PA',
    },
    16727: {
      lat: 41.98501,
      lng: -78.5338,
      city: 'Derrick City',
      state: 'PA',
    },
    16729: {
      lat: 41.96501,
      lng: -78.4788,
      city: 'Duke Center',
      state: 'PA',
    },
    16730: {
      lat: 41.81339,
      lng: -78.42309,
      city: 'East Smethport',
      state: 'PA',
    },
    16731: {
      lat: 41.94625,
      lng: -78.37126,
      city: 'Eldred',
      state: 'PA',
    },
    16732: {
      lat: 41.85921,
      lng: -78.60843,
      city: 'Gifford',
      state: 'PA',
    },
    16733: {
      lat: 41.70303,
      lng: -78.59514,
      city: 'Hazel Hurst',
      state: 'PA',
    },
    16734: {
      lat: 41.61806,
      lng: -78.84418,
      city: 'James City',
      state: 'PA',
    },
    16735: { lat: 41.63045, lng: -78.81, city: 'Kane', state: 'PA' },
    16738: {
      lat: 41.80511,
      lng: -78.73929,
      city: 'Lewis Run',
      state: 'PA',
    },
    16740: {
      lat: 41.69255,
      lng: -78.54998,
      city: 'Mount Jewett',
      state: 'PA',
    },
    16743: {
      lat: 41.7888,
      lng: -78.26016,
      city: 'Port Allegany',
      state: 'PA',
    },
    16744: { lat: 41.87567, lng: -78.56941, city: 'Rew', state: 'PA' },
    16745: {
      lat: 41.92578,
      lng: -78.49042,
      city: 'Rixford',
      state: 'PA',
    },
    16746: {
      lat: 41.79064,
      lng: -78.13858,
      city: 'Roulette',
      state: 'PA',
    },
    16748: {
      lat: 41.9427,
      lng: -78.14425,
      city: 'Shinglehouse',
      state: 'PA',
    },
    16749: {
      lat: 41.75578,
      lng: -78.4214,
      city: 'Smethport',
      state: 'PA',
    },
    16750: {
      lat: 41.88796,
      lng: -78.29682,
      city: 'Turtlepoint',
      state: 'PA',
    },
    16801: {
      lat: 40.78538,
      lng: -77.84214,
      city: 'State College',
      state: 'PA',
    },
    16802: {
      lat: 40.79867,
      lng: -77.86481,
      city: 'University Park',
      state: 'PA',
    },
    16803: {
      lat: 40.80849,
      lng: -77.89449,
      city: 'State College',
      state: 'PA',
    },
    16820: {
      lat: 40.91995,
      lng: -77.38702,
      city: 'Aaronsburg',
      state: 'PA',
    },
    16821: {
      lat: 40.95888,
      lng: -78.19875,
      city: 'Allport',
      state: 'PA',
    },
    16822: {
      lat: 41.15813,
      lng: -77.71441,
      city: 'Beech Creek',
      state: 'PA',
    },
    16823: {
      lat: 40.92734,
      lng: -77.76685,
      city: 'Bellefonte',
      state: 'PA',
    },
    16825: {
      lat: 40.98803,
      lng: -78.31208,
      city: 'Bigler',
      state: 'PA',
    },
    16826: {
      lat: 41.06199,
      lng: -77.58598,
      city: 'Blanchard',
      state: 'PA',
    },
    16827: {
      lat: 40.76607,
      lng: -77.77739,
      city: 'Boalsburg',
      state: 'PA',
    },
    16828: {
      lat: 40.80704,
      lng: -77.69097,
      city: 'Centre Hall',
      state: 'PA',
    },
    16829: {
      lat: 41.08223,
      lng: -77.89386,
      city: 'Clarence',
      state: 'PA',
    },
    16830: {
      lat: 41.08569,
      lng: -78.43317,
      city: 'Clearfield',
      state: 'PA',
    },
    16832: {
      lat: 40.84711,
      lng: -77.4347,
      city: 'Coburn',
      state: 'PA',
    },
    16833: {
      lat: 40.94158,
      lng: -78.57862,
      city: 'Curwensville',
      state: 'PA',
    },
    16834: {
      lat: 41.0391,
      lng: -78.10389,
      city: 'Drifting',
      state: 'PA',
    },
    16835: {
      lat: 40.90668,
      lng: -77.87731,
      city: 'Fleming',
      state: 'PA',
    },
    16836: {
      lat: 41.14633,
      lng: -78.26454,
      city: 'Frenchville',
      state: 'PA',
    },
    16837: {
      lat: 40.94576,
      lng: -78.47494,
      city: 'Glen Richey',
      state: 'PA',
    },
    16838: {
      lat: 40.98923,
      lng: -78.64292,
      city: 'Grampian',
      state: 'PA',
    },
    16839: {
      lat: 41.00319,
      lng: -78.11113,
      city: 'Grassflat',
      state: 'PA',
    },
    16840: {
      lat: 40.92555,
      lng: -78.20214,
      city: 'Hawk Run',
      state: 'PA',
    },
    16841: {
      lat: 41.03399,
      lng: -77.69011,
      city: 'Howard',
      state: 'PA',
    },
    16843: { lat: 41.00849, lng: -78.46269, city: 'Hyde', state: 'PA' },
    16844: {
      lat: 40.91285,
      lng: -77.93261,
      city: 'Julian',
      state: 'PA',
    },
    16845: {
      lat: 41.15641,
      lng: -78.02422,
      city: 'Karthaus',
      state: 'PA',
    },
    16847: {
      lat: 40.99941,
      lng: -78.16439,
      city: 'Kylertown',
      state: 'PA',
    },
    16848: {
      lat: 41.01247,
      lng: -77.53455,
      city: 'Lamar',
      state: 'PA',
    },
    16849: {
      lat: 40.95978,
      lng: -78.11431,
      city: 'Lanse',
      state: 'PA',
    },
    16851: {
      lat: 40.81229,
      lng: -77.80132,
      city: 'Lemont',
      state: 'PA',
    },
    16852: {
      lat: 40.93639,
      lng: -77.5386,
      city: 'Madisonburg',
      state: 'PA',
    },
    16853: {
      lat: 40.94045,
      lng: -77.78638,
      city: 'Milesburg',
      state: 'PA',
    },
    16854: {
      lat: 40.89697,
      lng: -77.47228,
      city: 'Millheim',
      state: 'PA',
    },
    16855: {
      lat: 40.99902,
      lng: -78.36046,
      city: 'Mineral Springs',
      state: 'PA',
    },
    16856: {
      lat: 40.92871,
      lng: -77.65005,
      city: 'Mingoville',
      state: 'PA',
    },
    16858: {
      lat: 41.00056,
      lng: -78.20033,
      city: 'Morrisdale',
      state: 'PA',
    },
    16859: {
      lat: 41.04903,
      lng: -78.03519,
      city: 'Moshannon',
      state: 'PA',
    },
    16860: {
      lat: 40.94673,
      lng: -78.17219,
      city: 'Munson',
      state: 'PA',
    },
    16861: {
      lat: 40.86764,
      lng: -78.51991,
      city: 'New Millport',
      state: 'PA',
    },
    16863: {
      lat: 40.91162,
      lng: -78.47204,
      city: 'Olanta',
      state: 'PA',
    },
    16864: {
      lat: 41.15428,
      lng: -77.79857,
      city: 'Orviston',
      state: 'PA',
    },
    16865: {
      lat: 40.72086,
      lng: -77.96396,
      city: 'Pennsylvania Furnace',
      state: 'PA',
    },
    16866: {
      lat: 40.86684,
      lng: -78.17163,
      city: 'Philipsburg',
      state: 'PA',
    },
    16868: {
      lat: 40.72725,
      lng: -77.89066,
      city: 'Pine Grove Mills',
      state: 'PA',
    },
    16870: {
      lat: 40.80149,
      lng: -78.04969,
      city: 'Port Matilda',
      state: 'PA',
    },
    16871: {
      lat: 41.1879,
      lng: -78.03705,
      city: 'Pottersdale',
      state: 'PA',
    },
    16872: {
      lat: 40.96715,
      lng: -77.35939,
      city: 'Rebersburg',
      state: 'PA',
    },
    16874: {
      lat: 41.01141,
      lng: -77.94077,
      city: 'Snow Shoe',
      state: 'PA',
    },
    16875: {
      lat: 40.84536,
      lng: -77.56882,
      city: 'Spring Mills',
      state: 'PA',
    },
    16876: {
      lat: 40.96648,
      lng: -78.29087,
      city: 'Wallaceton',
      state: 'PA',
    },
    16877: {
      lat: 40.73291,
      lng: -78.06846,
      city: 'Warriors Mark',
      state: 'PA',
    },
    16878: {
      lat: 40.94325,
      lng: -78.33609,
      city: 'West Decatur',
      state: 'PA',
    },
    16879: {
      lat: 40.97116,
      lng: -78.15162,
      city: 'Winburne',
      state: 'PA',
    },
    16881: {
      lat: 41.02743,
      lng: -78.31119,
      city: 'Woodland',
      state: 'PA',
    },
    16882: {
      lat: 40.90707,
      lng: -77.33841,
      city: 'Woodward',
      state: 'PA',
    },
    16901: {
      lat: 41.73038,
      lng: -77.31841,
      city: 'Wellsboro',
      state: 'PA',
    },
    16910: { lat: 41.70477, lng: -76.83124, city: 'Alba', state: 'PA' },
    16911: {
      lat: 41.67787,
      lng: -77.10392,
      city: 'Arnot',
      state: 'PA',
    },
    16912: {
      lat: 41.66803,
      lng: -77.05845,
      city: 'Blossburg',
      state: 'PA',
    },
    16914: {
      lat: 41.8618,
      lng: -76.77687,
      city: 'Columbia Cross Roads',
      state: 'PA',
    },
    16915: {
      lat: 41.77481,
      lng: -77.96104,
      city: 'Coudersport',
      state: 'PA',
    },
    16917: {
      lat: 41.723,
      lng: -77.05519,
      city: 'Covington',
      state: 'PA',
    },
    16920: {
      lat: 41.98218,
      lng: -77.28851,
      city: 'Elkland',
      state: 'PA',
    },
    16921: {
      lat: 41.69614,
      lng: -77.54654,
      city: 'Gaines',
      state: 'PA',
    },
    16922: {
      lat: 41.67191,
      lng: -77.70064,
      city: 'Galeton',
      state: 'PA',
    },
    16923: {
      lat: 41.94103,
      lng: -77.8761,
      city: 'Genesee',
      state: 'PA',
    },
    16925: {
      lat: 41.95319,
      lng: -76.78997,
      city: 'Gillett',
      state: 'PA',
    },
    16926: {
      lat: 41.71506,
      lng: -76.70485,
      city: 'Granville Summit',
      state: 'PA',
    },
    16927: {
      lat: 41.9655,
      lng: -77.65941,
      city: 'Harrison Valley',
      state: 'PA',
    },
    16928: {
      lat: 41.95203,
      lng: -77.43371,
      city: 'Knoxville',
      state: 'PA',
    },
    16929: {
      lat: 41.97352,
      lng: -77.15028,
      city: 'Lawrenceville',
      state: 'PA',
    },
    16930: {
      lat: 41.57693,
      lng: -77.14021,
      city: 'Liberty',
      state: 'PA',
    },
    16932: {
      lat: 41.77574,
      lng: -76.94017,
      city: 'Mainesburg',
      state: 'PA',
    },
    16933: {
      lat: 41.81904,
      lng: -77.06716,
      city: 'Mansfield',
      state: 'PA',
    },
    16935: {
      lat: 41.87032,
      lng: -77.32404,
      city: 'Middlebury Center',
      state: 'PA',
    },
    16936: {
      lat: 41.94318,
      lng: -76.95837,
      city: 'Millerton',
      state: 'PA',
    },
    16937: {
      lat: 41.96421,
      lng: -77.71004,
      city: 'Mills',
      state: 'PA',
    },
    16938: {
      lat: 41.56935,
      lng: -77.34487,
      city: 'Morris',
      state: 'PA',
    },
    16939: {
      lat: 41.68097,
      lng: -77.0141,
      city: 'Morris Run',
      state: 'PA',
    },
    16940: {
      lat: 41.97752,
      lng: -77.23373,
      city: 'Nelson',
      state: 'PA',
    },
    16941: {
      lat: 41.98841,
      lng: -77.75796,
      city: 'Genesee',
      state: 'PA',
    },
    16942: {
      lat: 41.9654,
      lng: -77.35414,
      city: 'Osceola',
      state: 'PA',
    },
    16943: {
      lat: 41.8406,
      lng: -77.61915,
      city: 'Sabinsville',
      state: 'PA',
    },
    16946: {
      lat: 41.91422,
      lng: -77.13999,
      city: 'Tioga',
      state: 'PA',
    },
    16947: { lat: 41.76969, lng: -76.79519, city: 'Troy', state: 'PA' },
    16948: {
      lat: 41.85369,
      lng: -77.75683,
      city: 'Ulysses',
      state: 'PA',
    },
    16950: {
      lat: 41.89816,
      lng: -77.53429,
      city: 'Westfield',
      state: 'PA',
    },
    17002: {
      lat: 40.46463,
      lng: -77.86761,
      city: 'Allensville',
      state: 'PA',
    },
    17003: {
      lat: 40.35735,
      lng: -76.55528,
      city: 'Annville',
      state: 'PA',
    },
    17004: {
      lat: 40.59966,
      lng: -77.73278,
      city: 'Belleville',
      state: 'PA',
    },
    17005: {
      lat: 40.60304,
      lng: -76.81065,
      city: 'Berrysburg',
      state: 'PA',
    },
    17006: {
      lat: 40.28495,
      lng: -77.54364,
      city: 'Blain',
      state: 'PA',
    },
    17007: {
      lat: 40.13428,
      lng: -77.11801,
      city: 'Boiling Springs',
      state: 'PA',
    },
    17009: {
      lat: 40.6359,
      lng: -77.56609,
      city: 'Burnham',
      state: 'PA',
    },
    17010: {
      lat: 40.27809,
      lng: -76.58335,
      city: 'Campbelltown',
      state: 'PA',
    },
    17011: {
      lat: 40.23508,
      lng: -76.92931,
      city: 'Camp Hill',
      state: 'PA',
    },
    17013: {
      lat: 40.241,
      lng: -77.19286,
      city: 'Carlisle',
      state: 'PA',
    },
    17014: {
      lat: 40.6596,
      lng: -77.19358,
      city: 'Cocolamus',
      state: 'PA',
    },
    17015: {
      lat: 40.18392,
      lng: -77.23234,
      city: 'Carlisle',
      state: 'PA',
    },
    17016: {
      lat: 40.27322,
      lng: -76.40705,
      city: 'Cornwall',
      state: 'PA',
    },
    17017: {
      lat: 40.6275,
      lng: -76.89854,
      city: 'Dalmatia',
      state: 'PA',
    },
    17018: {
      lat: 40.40246,
      lng: -76.88117,
      city: 'Dauphin',
      state: 'PA',
    },
    17019: {
      lat: 40.09078,
      lng: -77.02462,
      city: 'Dillsburg',
      state: 'PA',
    },
    17020: {
      lat: 40.41014,
      lng: -77.04367,
      city: 'Duncannon',
      state: 'PA',
    },
    17021: {
      lat: 40.33758,
      lng: -77.66093,
      city: 'East Waterford',
      state: 'PA',
    },
    17022: {
      lat: 40.16677,
      lng: -76.60823,
      city: 'Elizabethtown',
      state: 'PA',
    },
    17023: {
      lat: 40.5813,
      lng: -76.81169,
      city: 'Elizabethville',
      state: 'PA',
    },
    17024: {
      lat: 40.40992,
      lng: -77.30854,
      city: 'Elliottsburg',
      state: 'PA',
    },
    17025: {
      lat: 40.29506,
      lng: -76.97731,
      city: 'Enola',
      state: 'PA',
    },
    17026: {
      lat: 40.46734,
      lng: -76.43626,
      city: 'Fredericksburg',
      state: 'PA',
    },
    17027: {
      lat: 40.15653,
      lng: -76.99316,
      city: 'Grantham',
      state: 'PA',
    },
    17028: {
      lat: 40.4033,
      lng: -76.65073,
      city: 'Grantville',
      state: 'PA',
    },
    17029: {
      lat: 40.55416,
      lng: -77.62196,
      city: 'Granville',
      state: 'PA',
    },
    17030: {
      lat: 40.60833,
      lng: -76.74417,
      city: 'Gratz',
      state: 'PA',
    },
    17032: {
      lat: 40.49448,
      lng: -76.81788,
      city: 'Halifax',
      state: 'PA',
    },
    17033: {
      lat: 40.26829,
      lng: -76.62754,
      city: 'Hershey',
      state: 'PA',
    },
    17034: {
      lat: 40.20831,
      lng: -76.78489,
      city: 'Highspire',
      state: 'PA',
    },
    17035: {
      lat: 40.41638,
      lng: -77.57443,
      city: 'Honey Grove',
      state: 'PA',
    },
    17036: {
      lat: 40.28579,
      lng: -76.69611,
      city: 'Hummelstown',
      state: 'PA',
    },
    17037: {
      lat: 40.43329,
      lng: -77.42872,
      city: 'Ickesburg',
      state: 'PA',
    },
    17038: {
      lat: 40.46787,
      lng: -76.54895,
      city: 'Jonestown',
      state: 'PA',
    },
    17039: {
      lat: 40.2928,
      lng: -76.24077,
      city: 'Kleinfeltersville',
      state: 'PA',
    },
    17040: {
      lat: 40.31558,
      lng: -77.32571,
      city: 'Landisburg',
      state: 'PA',
    },
    17041: { lat: 40.21667, lng: -76.5395, city: 'Lawn', state: 'PA' },
    17042: {
      lat: 40.29488,
      lng: -76.42271,
      city: 'Lebanon',
      state: 'PA',
    },
    17043: {
      lat: 40.24679,
      lng: -76.90033,
      city: 'Lemoyne',
      state: 'PA',
    },
    17044: {
      lat: 40.58236,
      lng: -77.59013,
      city: 'Lewistown',
      state: 'PA',
    },
    17045: {
      lat: 40.58841,
      lng: -77.00389,
      city: 'Liverpool',
      state: 'PA',
    },
    17046: {
      lat: 40.38242,
      lng: -76.4306,
      city: 'Lebanon',
      state: 'PA',
    },
    17047: {
      lat: 40.37098,
      lng: -77.41426,
      city: 'Loysville',
      state: 'PA',
    },
    17048: {
      lat: 40.60554,
      lng: -76.72669,
      city: 'Lykens',
      state: 'PA',
    },
    17049: {
      lat: 40.65172,
      lng: -77.25948,
      city: 'McAlisterville',
      state: 'PA',
    },
    17050: {
      lat: 40.24907,
      lng: -77.03005,
      city: 'Mechanicsburg',
      state: 'PA',
    },
    17051: {
      lat: 40.45881,
      lng: -77.77306,
      city: 'McVeytown',
      state: 'PA',
    },
    17052: {
      lat: 40.31577,
      lng: -77.98195,
      city: 'Mapleton Depot',
      state: 'PA',
    },
    17053: {
      lat: 40.3255,
      lng: -77.03256,
      city: 'Marysville',
      state: 'PA',
    },
    17054: {
      lat: 40.49746,
      lng: -77.72764,
      city: 'Mattawana',
      state: 'PA',
    },
    17055: {
      lat: 40.17756,
      lng: -76.99789,
      city: 'Mechanicsburg',
      state: 'PA',
    },
    17056: {
      lat: 40.53349,
      lng: -77.35585,
      city: 'Mexico',
      state: 'PA',
    },
    17057: {
      lat: 40.19437,
      lng: -76.72659,
      city: 'Middletown',
      state: 'PA',
    },
    17058: {
      lat: 40.50111,
      lng: -77.54792,
      city: 'Mifflin',
      state: 'PA',
    },
    17059: {
      lat: 40.59241,
      lng: -77.38165,
      city: 'Mifflintown',
      state: 'PA',
    },
    17060: {
      lat: 40.49144,
      lng: -77.88158,
      city: 'Mill Creek',
      state: 'PA',
    },
    17061: {
      lat: 40.56542,
      lng: -76.90046,
      city: 'Millersburg',
      state: 'PA',
    },
    17062: {
      lat: 40.55153,
      lng: -77.16813,
      city: 'Millerstown',
      state: 'PA',
    },
    17063: {
      lat: 40.76274,
      lng: -77.47343,
      city: 'Milroy',
      state: 'PA',
    },
    17064: {
      lat: 40.24135,
      lng: -76.47553,
      city: 'Mount Gretna',
      state: 'PA',
    },
    17065: {
      lat: 40.09837,
      lng: -77.20527,
      city: 'Mount Holly Springs',
      state: 'PA',
    },
    17066: {
      lat: 40.35885,
      lng: -77.85892,
      city: 'Mount Union',
      state: 'PA',
    },
    17067: {
      lat: 40.38848,
      lng: -76.31583,
      city: 'Myerstown',
      state: 'PA',
    },
    17068: {
      lat: 40.40685,
      lng: -77.17795,
      city: 'New Bloomfield',
      state: 'PA',
    },
    17069: {
      lat: 40.45722,
      lng: -76.97921,
      city: 'New Buffalo',
      state: 'PA',
    },
    17070: {
      lat: 40.20217,
      lng: -76.86377,
      city: 'New Cumberland',
      state: 'PA',
    },
    17071: {
      lat: 40.30061,
      lng: -77.58928,
      city: 'New Germantown',
      state: 'PA',
    },
    17073: {
      lat: 40.30047,
      lng: -76.25924,
      city: 'Newmanstown',
      state: 'PA',
    },
    17074: {
      lat: 40.47983,
      lng: -77.14456,
      city: 'Newport',
      state: 'PA',
    },
    17075: {
      lat: 40.39372,
      lng: -77.83429,
      city: 'Newton Hamilton',
      state: 'PA',
    },
    17076: {
      lat: 40.62084,
      lng: -77.30758,
      city: 'Oakland Mills',
      state: 'PA',
    },
    17077: { lat: 40.40232, lng: -76.53651, city: 'Ono', state: 'PA' },
    17078: {
      lat: 40.28952,
      lng: -76.58484,
      city: 'Palmyra',
      state: 'PA',
    },
    17080: {
      lat: 40.64057,
      lng: -76.80299,
      city: 'Pillow',
      state: 'PA',
    },
    17081: {
      lat: 40.20121,
      lng: -77.28322,
      city: 'Plainfield',
      state: 'PA',
    },
    17082: {
      lat: 40.49762,
      lng: -77.42579,
      city: 'Port Royal',
      state: 'PA',
    },
    17084: {
      lat: 40.68705,
      lng: -77.62692,
      city: 'Reedsville',
      state: 'PA',
    },
    17086: {
      lat: 40.69067,
      lng: -77.12502,
      city: 'Richfield',
      state: 'PA',
    },
    17087: {
      lat: 40.40523,
      lng: -76.26356,
      city: 'Richland',
      state: 'PA',
    },
    17088: {
      lat: 40.30046,
      lng: -76.295,
      city: 'Schaefferstown',
      state: 'PA',
    },
    17090: {
      lat: 40.32953,
      lng: -77.18302,
      city: 'Shermans Dale',
      state: 'PA',
    },
    17093: {
      lat: 40.30998,
      lng: -76.92817,
      city: 'Summerdale',
      state: 'PA',
    },
    17094: {
      lat: 40.58414,
      lng: -77.2168,
      city: 'Thompsontown',
      state: 'PA',
    },
    17097: {
      lat: 40.57414,
      lng: -76.67704,
      city: 'Wiconisco',
      state: 'PA',
    },
    17098: {
      lat: 40.58998,
      lng: -76.63071,
      city: 'Williamstown',
      state: 'PA',
    },
    17099: {
      lat: 40.64535,
      lng: -77.57639,
      city: 'Yeagertown',
      state: 'PA',
    },
    17101: {
      lat: 40.2595,
      lng: -76.88705,
      city: 'Harrisburg',
      state: 'PA',
    },
    17102: {
      lat: 40.27119,
      lng: -76.89686,
      city: 'Harrisburg',
      state: 'PA',
    },
    17103: {
      lat: 40.27499,
      lng: -76.86326,
      city: 'Harrisburg',
      state: 'PA',
    },
    17104: {
      lat: 40.23901,
      lng: -76.84985,
      city: 'Harrisburg',
      state: 'PA',
    },
    17109: {
      lat: 40.28986,
      lng: -76.82414,
      city: 'Harrisburg',
      state: 'PA',
    },
    17110: {
      lat: 40.31776,
      lng: -76.88354,
      city: 'Harrisburg',
      state: 'PA',
    },
    17111: {
      lat: 40.27041,
      lng: -76.78265,
      city: 'Harrisburg',
      state: 'PA',
    },
    17112: {
      lat: 40.37497,
      lng: -76.77163,
      city: 'Harrisburg',
      state: 'PA',
    },
    17113: {
      lat: 40.23032,
      lng: -76.82083,
      city: 'Harrisburg',
      state: 'PA',
    },
    17120: {
      lat: 40.26511,
      lng: -76.88281,
      city: 'Harrisburg',
      state: 'PA',
    },
    17201: {
      lat: 39.96229,
      lng: -77.65677,
      city: 'Chambersburg',
      state: 'PA',
    },
    17202: {
      lat: 39.92133,
      lng: -77.67442,
      city: 'Chambersburg',
      state: 'PA',
    },
    17210: {
      lat: 40.18989,
      lng: -77.661,
      city: 'Amberson',
      state: 'PA',
    },
    17211: {
      lat: 39.75191,
      lng: -78.41457,
      city: 'Artemas',
      state: 'PA',
    },
    17212: {
      lat: 39.81,
      lng: -78.07073,
      city: 'Big Cove Tannery',
      state: 'PA',
    },
    17213: {
      lat: 40.25381,
      lng: -77.77249,
      city: 'Blairs Mills',
      state: 'PA',
    },
    17214: {
      lat: 39.74811,
      lng: -77.47025,
      city: 'Blue Ridge Summit',
      state: 'PA',
    },
    17215: {
      lat: 40.09234,
      lng: -77.89008,
      city: 'Burnt Cabins',
      state: 'PA',
    },
    17217: {
      lat: 40.23822,
      lng: -77.72235,
      city: 'Concord',
      state: 'PA',
    },
    17219: {
      lat: 40.22367,
      lng: -77.69318,
      city: 'Doylesburg',
      state: 'PA',
    },
    17220: {
      lat: 40.17807,
      lng: -77.74108,
      city: 'Dry Run',
      state: 'PA',
    },
    17221: {
      lat: 40.05796,
      lng: -77.82019,
      city: 'Fannettsburg',
      state: 'PA',
    },
    17222: {
      lat: 39.89983,
      lng: -77.49242,
      city: 'Fayetteville',
      state: 'PA',
    },
    17223: {
      lat: 40.06521,
      lng: -77.9389,
      city: 'Fort Littleton',
      state: 'PA',
    },
    17224: {
      lat: 39.9723,
      lng: -77.89195,
      city: 'Fort Loudon',
      state: 'PA',
    },
    17225: {
      lat: 39.78706,
      lng: -77.75709,
      city: 'Greencastle',
      state: 'PA',
    },
    17228: {
      lat: 39.99512,
      lng: -78.09831,
      city: 'Harrisonville',
      state: 'PA',
    },
    17229: {
      lat: 40.07853,
      lng: -78.01144,
      city: 'Hustontown',
      state: 'PA',
    },
    17232: {
      lat: 40.09462,
      lng: -77.63341,
      city: 'Lurgan',
      state: 'PA',
    },
    17233: {
      lat: 39.95118,
      lng: -78.00406,
      city: 'McConnellsburg',
      state: 'PA',
    },
    17235: {
      lat: 39.85699,
      lng: -77.69743,
      city: 'Marion',
      state: 'PA',
    },
    17236: {
      lat: 39.79997,
      lng: -77.94305,
      city: 'Mercersburg',
      state: 'PA',
    },
    17237: {
      lat: 39.83705,
      lng: -77.54091,
      city: 'Mont Alto',
      state: 'PA',
    },
    17238: {
      lat: 39.8612,
      lng: -78.13003,
      city: 'Needmore',
      state: 'PA',
    },
    17239: {
      lat: 40.13973,
      lng: -77.83009,
      city: 'Neelyton',
      state: 'PA',
    },
    17240: {
      lat: 40.15411,
      lng: -77.58227,
      city: 'Newburg',
      state: 'PA',
    },
    17241: {
      lat: 40.17793,
      lng: -77.40326,
      city: 'Newville',
      state: 'PA',
    },
    17243: {
      lat: 40.28368,
      lng: -77.81484,
      city: 'Orbisonia',
      state: 'PA',
    },
    17244: {
      lat: 40.08595,
      lng: -77.66562,
      city: 'Orrstown',
      state: 'PA',
    },
    17246: {
      lat: 40.0629,
      lng: -77.68255,
      city: 'Pleasant Hall',
      state: 'PA',
    },
    17247: {
      lat: 39.79883,
      lng: -77.57933,
      city: 'Quincy',
      state: 'PA',
    },
    17249: {
      lat: 40.24186,
      lng: -77.9002,
      city: 'Rockhill Furnace',
      state: 'PA',
    },
    17250: {
      lat: 39.73668,
      lng: -77.52338,
      city: 'Rouzerville',
      state: 'PA',
    },
    17251: {
      lat: 40.12159,
      lng: -77.66695,
      city: 'Roxbury',
      state: 'PA',
    },
    17252: {
      lat: 39.91317,
      lng: -77.81695,
      city: 'Saint Thomas',
      state: 'PA',
    },
    17253: {
      lat: 40.21241,
      lng: -78.00628,
      city: 'Saltillo',
      state: 'PA',
    },
    17254: {
      lat: 39.96853,
      lng: -77.58917,
      city: 'Scotland',
      state: 'PA',
    },
    17255: {
      lat: 40.15426,
      lng: -77.86756,
      city: 'Shade Gap',
      state: 'PA',
    },
    17257: {
      lat: 40.0508,
      lng: -77.49027,
      city: 'Shippensburg',
      state: 'PA',
    },
    17260: {
      lat: 40.2977,
      lng: -77.89489,
      city: 'Shirleysburg',
      state: 'PA',
    },
    17261: {
      lat: 39.85981,
      lng: -77.51164,
      city: 'South Mountain',
      state: 'PA',
    },
    17262: {
      lat: 40.14489,
      lng: -77.74174,
      city: 'Spring Run',
      state: 'PA',
    },
    17263: {
      lat: 39.72409,
      lng: -77.71727,
      city: 'State Line',
      state: 'PA',
    },
    17264: {
      lat: 40.17993,
      lng: -77.99331,
      city: 'Three Springs',
      state: 'PA',
    },
    17265: {
      lat: 40.03846,
      lng: -77.77297,
      city: 'Upperstrasburg',
      state: 'PA',
    },
    17266: {
      lat: 40.08745,
      lng: -77.412,
      city: 'Walnut Bottom',
      state: 'PA',
    },
    17267: {
      lat: 39.80389,
      lng: -78.23958,
      city: 'Warfordsburg',
      state: 'PA',
    },
    17268: {
      lat: 39.77421,
      lng: -77.57354,
      city: 'Waynesboro',
      state: 'PA',
    },
    17271: {
      lat: 40.10183,
      lng: -77.80367,
      city: 'Willow Hill',
      state: 'PA',
    },
    17301: {
      lat: 39.89119,
      lng: -76.9815,
      city: 'Abbottstown',
      state: 'PA',
    },
    17302: {
      lat: 39.81659,
      lng: -76.41069,
      city: 'Airville',
      state: 'PA',
    },
    17303: {
      lat: 39.92615,
      lng: -77.29755,
      city: 'Arendtsville',
      state: 'PA',
    },
    17304: {
      lat: 39.97696,
      lng: -77.23099,
      city: 'Aspers',
      state: 'PA',
    },
    17306: {
      lat: 39.98383,
      lng: -77.24966,
      city: 'Bendersville',
      state: 'PA',
    },
    17307: {
      lat: 39.94853,
      lng: -77.314,
      city: 'Biglerville',
      state: 'PA',
    },
    17309: {
      lat: 39.87351,
      lng: -76.44894,
      city: 'Brogue',
      state: 'PA',
    },
    17311: {
      lat: 39.81709,
      lng: -76.84191,
      city: 'Codorus',
      state: 'PA',
    },
    17313: {
      lat: 39.88472,
      lng: -76.65719,
      city: 'Dallastown',
      state: 'PA',
    },
    17314: {
      lat: 39.75469,
      lng: -76.32852,
      city: 'Delta',
      state: 'PA',
    },
    17315: { lat: 40.01859, lng: -76.8685, city: 'Dover', state: 'PA' },
    17316: {
      lat: 39.96721,
      lng: -77.00226,
      city: 'East Berlin',
      state: 'PA',
    },
    17317: {
      lat: 39.97136,
      lng: -76.52267,
      city: 'East Prospect',
      state: 'PA',
    },
    17318: {
      lat: 40.01847,
      lng: -76.7265,
      city: 'Emigsville',
      state: 'PA',
    },
    17319: {
      lat: 40.1585,
      lng: -76.79468,
      city: 'Etters',
      state: 'PA',
    },
    17320: {
      lat: 39.7695,
      lng: -77.38749,
      city: 'Fairfield',
      state: 'PA',
    },
    17321: {
      lat: 39.75406,
      lng: -76.44742,
      city: 'Fawn Grove',
      state: 'PA',
    },
    17322: {
      lat: 39.85548,
      lng: -76.53513,
      city: 'Felton',
      state: 'PA',
    },
    17323: {
      lat: 40.07523,
      lng: -77.02669,
      city: 'Franklintown',
      state: 'PA',
    },
    17324: {
      lat: 40.03616,
      lng: -77.22772,
      city: 'Gardners',
      state: 'PA',
    },
    17325: {
      lat: 39.82762,
      lng: -77.22641,
      city: 'Gettysburg',
      state: 'PA',
    },
    17327: {
      lat: 39.77934,
      lng: -76.75373,
      city: 'Glen Rock',
      state: 'PA',
    },
    17329: {
      lat: 39.76295,
      lng: -76.85374,
      city: 'Glenville',
      state: 'PA',
    },
    17331: {
      lat: 39.79085,
      lng: -76.98028,
      city: 'Hanover',
      state: 'PA',
    },
    17339: {
      lat: 40.13438,
      lng: -76.88188,
      city: 'Lewisberry',
      state: 'PA',
    },
    17340: {
      lat: 39.75566,
      lng: -77.11183,
      city: 'Littlestown',
      state: 'PA',
    },
    17342: {
      lat: 39.85275,
      lng: -76.70756,
      city: 'Loganville',
      state: 'PA',
    },
    17343: {
      lat: 39.86863,
      lng: -77.32948,
      city: 'McKnightstown',
      state: 'PA',
    },
    17344: {
      lat: 39.80535,
      lng: -77.01887,
      city: 'McSherrystown',
      state: 'PA',
    },
    17345: {
      lat: 40.07929,
      lng: -76.72812,
      city: 'Manchester',
      state: 'PA',
    },
    17347: {
      lat: 40.04927,
      lng: -76.68247,
      city: 'Mount Wolf',
      state: 'PA',
    },
    17349: {
      lat: 39.75536,
      lng: -76.68003,
      city: 'New Freedom',
      state: 'PA',
    },
    17350: {
      lat: 39.88918,
      lng: -77.07896,
      city: 'New Oxford',
      state: 'PA',
    },
    17352: {
      lat: 39.76199,
      lng: -76.50008,
      city: 'New Park',
      state: 'PA',
    },
    17353: {
      lat: 39.88741,
      lng: -77.38714,
      city: 'Orrtanna',
      state: 'PA',
    },
    17355: {
      lat: 39.75978,
      lng: -76.696,
      city: 'Railroad',
      state: 'PA',
    },
    17356: {
      lat: 39.89834,
      lng: -76.58366,
      city: 'Red Lion',
      state: 'PA',
    },
    17360: {
      lat: 39.85196,
      lng: -76.75377,
      city: 'Seven Valleys',
      state: 'PA',
    },
    17361: {
      lat: 39.76617,
      lng: -76.67702,
      city: 'Shrewsbury',
      state: 'PA',
    },
    17362: {
      lat: 39.85088,
      lng: -76.86872,
      city: 'Spring Grove',
      state: 'PA',
    },
    17363: {
      lat: 39.76736,
      lng: -76.58504,
      city: 'Stewartstown',
      state: 'PA',
    },
    17364: {
      lat: 39.92985,
      lng: -76.90049,
      city: 'Thomasville',
      state: 'PA',
    },
    17365: {
      lat: 40.05696,
      lng: -76.9392,
      city: 'Wellsville',
      state: 'PA',
    },
    17366: {
      lat: 39.93184,
      lng: -76.55585,
      city: 'Windsor',
      state: 'PA',
    },
    17368: {
      lat: 39.98463,
      lng: -76.51966,
      city: 'Wrightsville',
      state: 'PA',
    },
    17370: {
      lat: 40.1174,
      lng: -76.78124,
      city: 'York Haven',
      state: 'PA',
    },
    17371: {
      lat: 39.90258,
      lng: -76.78797,
      city: 'York New Salem',
      state: 'PA',
    },
    17372: {
      lat: 40.00037,
      lng: -77.10633,
      city: 'York Springs',
      state: 'PA',
    },
    17401: { lat: 39.95893, lng: -76.73359, city: 'York', state: 'PA' },
    17402: { lat: 39.95799, lng: -76.66045, city: 'York', state: 'PA' },
    17403: { lat: 39.92142, lng: -76.71277, city: 'York', state: 'PA' },
    17404: { lat: 40.00496, lng: -76.77217, city: 'York', state: 'PA' },
    17406: { lat: 40.01224, lng: -76.64249, city: 'York', state: 'PA' },
    17407: { lat: 39.8855, lng: -76.71244, city: 'York', state: 'PA' },
    17408: { lat: 39.93451, lng: -76.80203, city: 'York', state: 'PA' },
    17501: {
      lat: 40.15697,
      lng: -76.20532,
      city: 'Akron',
      state: 'PA',
    },
    17502: {
      lat: 40.10081,
      lng: -76.66732,
      city: 'Bainbridge',
      state: 'PA',
    },
    17504: {
      lat: 40.02448,
      lng: -76.32855,
      city: 'Bausman',
      state: 'PA',
    },
    17505: {
      lat: 40.05915,
      lng: -76.18999,
      city: 'Bird In Hand',
      state: 'PA',
    },
    17506: {
      lat: 40.11718,
      lng: -76.0527,
      city: 'Blue Ball',
      state: 'PA',
    },
    17507: {
      lat: 40.19806,
      lng: -76.01624,
      city: 'Bowmansville',
      state: 'PA',
    },
    17508: {
      lat: 40.12455,
      lng: -76.21394,
      city: 'Brownstown',
      state: 'PA',
    },
    17509: {
      lat: 39.90923,
      lng: -76.03073,
      city: 'Christiana',
      state: 'PA',
    },
    17512: {
      lat: 40.04,
      lng: -76.48558,
      city: 'Columbia',
      state: 'PA',
    },
    17516: {
      lat: 39.93773,
      lng: -76.38703,
      city: 'Conestoga',
      state: 'PA',
    },
    17517: {
      lat: 40.24402,
      lng: -76.13151,
      city: 'Denver',
      state: 'PA',
    },
    17518: {
      lat: 39.80519,
      lng: -76.25932,
      city: 'Drumore',
      state: 'PA',
    },
    17519: {
      lat: 40.14278,
      lng: -76.02145,
      city: 'East Earl',
      state: 'PA',
    },
    17520: {
      lat: 40.09813,
      lng: -76.34897,
      city: 'East Petersburg',
      state: 'PA',
    },
    17522: {
      lat: 40.17499,
      lng: -76.17009,
      city: 'Ephrata',
      state: 'PA',
    },
    17527: { lat: 40.01495, lng: -75.99333, city: 'Gap', state: 'PA' },
    17529: {
      lat: 40.04036,
      lng: -76.10008,
      city: 'Gordonville',
      state: 'PA',
    },
    17532: {
      lat: 39.85731,
      lng: -76.2923,
      city: 'Holtwood',
      state: 'PA',
    },
    17534: {
      lat: 40.03665,
      lng: -76.11214,
      city: 'Intercourse',
      state: 'PA',
    },
    17535: {
      lat: 40.01224,
      lng: -76.03813,
      city: 'Kinzers',
      state: 'PA',
    },
    17536: {
      lat: 39.84345,
      lng: -76.07316,
      city: 'Kirkwood',
      state: 'PA',
    },
    17538: {
      lat: 40.08804,
      lng: -76.41528,
      city: 'Landisville',
      state: 'PA',
    },
    17540: { lat: 40.09877, lng: -76.1888, city: 'Leola', state: 'PA' },
    17543: {
      lat: 40.18217,
      lng: -76.30038,
      city: 'Lititz',
      state: 'PA',
    },
    17545: {
      lat: 40.17307,
      lng: -76.42792,
      city: 'Manheim',
      state: 'PA',
    },
    17547: {
      lat: 40.07056,
      lng: -76.58436,
      city: 'Marietta',
      state: 'PA',
    },
    17550: {
      lat: 40.07492,
      lng: -76.58339,
      city: 'Maytown',
      state: 'PA',
    },
    17551: {
      lat: 39.97911,
      lng: -76.37608,
      city: 'Millersville',
      state: 'PA',
    },
    17552: {
      lat: 40.10935,
      lng: -76.51174,
      city: 'Mount Joy',
      state: 'PA',
    },
    17554: {
      lat: 40.03923,
      lng: -76.42584,
      city: 'Mountville',
      state: 'PA',
    },
    17555: {
      lat: 40.12075,
      lng: -75.96514,
      city: 'Narvon',
      state: 'PA',
    },
    17557: {
      lat: 40.10328,
      lng: -76.07543,
      city: 'New Holland',
      state: 'PA',
    },
    17560: {
      lat: 39.91117,
      lng: -76.22712,
      city: 'New Providence',
      state: 'PA',
    },
    17562: {
      lat: 39.97636,
      lng: -76.09264,
      city: 'Paradise',
      state: 'PA',
    },
    17563: {
      lat: 39.76404,
      lng: -76.18649,
      city: 'Peach Bottom',
      state: 'PA',
    },
    17565: {
      lat: 39.89191,
      lng: -76.32994,
      city: 'Pequea',
      state: 'PA',
    },
    17566: {
      lat: 39.86924,
      lng: -76.1476,
      city: 'Quarryville',
      state: 'PA',
    },
    17568: {
      lat: 39.94806,
      lng: -76.23416,
      city: 'Refton',
      state: 'PA',
    },
    17569: {
      lat: 40.27043,
      lng: -76.09652,
      city: 'Reinholds',
      state: 'PA',
    },
    17570: {
      lat: 40.12574,
      lng: -76.57035,
      city: 'Rheems',
      state: 'PA',
    },
    17572: {
      lat: 40.00937,
      lng: -76.15105,
      city: 'Ronks',
      state: 'PA',
    },
    17576: {
      lat: 40.03561,
      lng: -76.19782,
      city: 'Smoketown',
      state: 'PA',
    },
    17578: {
      lat: 40.22397,
      lng: -76.16405,
      city: 'Stevens',
      state: 'PA',
    },
    17579: {
      lat: 39.95938,
      lng: -76.18032,
      city: 'Strasburg',
      state: 'PA',
    },
    17581: {
      lat: 40.15617,
      lng: -76.05535,
      city: 'Terre Hill',
      state: 'PA',
    },
    17582: {
      lat: 39.98928,
      lng: -76.44756,
      city: 'Washington Boro',
      state: 'PA',
    },
    17584: {
      lat: 39.95981,
      lng: -76.26463,
      city: 'Willow Street',
      state: 'PA',
    },
    17601: {
      lat: 40.07296,
      lng: -76.31457,
      city: 'Lancaster',
      state: 'PA',
    },
    17602: {
      lat: 40.01486,
      lng: -76.24632,
      city: 'Lancaster',
      state: 'PA',
    },
    17603: {
      lat: 40.0127,
      lng: -76.35287,
      city: 'Lancaster',
      state: 'PA',
    },
    17606: {
      lat: 40.11174,
      lng: -76.30401,
      city: 'Lancaster',
      state: 'PA',
    },
    17701: {
      lat: 41.34206,
      lng: -76.88821,
      city: 'Williamsport',
      state: 'PA',
    },
    17702: {
      lat: 41.18215,
      lng: -77.07728,
      city: 'Williamsport',
      state: 'PA',
    },
    17720: {
      lat: 41.18046,
      lng: -77.20503,
      city: 'Antes Fort',
      state: 'PA',
    },
    17721: { lat: 41.18155, lng: -77.31927, city: 'Avis', state: 'PA' },
    17723: {
      lat: 41.43125,
      lng: -77.45629,
      city: 'Cammal',
      state: 'PA',
    },
    17724: {
      lat: 41.63827,
      lng: -76.80034,
      city: 'Canton',
      state: 'PA',
    },
    17727: {
      lat: 41.53904,
      lng: -77.47914,
      city: 'Cedar Run',
      state: 'PA',
    },
    17728: {
      lat: 41.32546,
      lng: -77.08215,
      city: 'Cogan Station',
      state: 'PA',
    },
    17729: {
      lat: 41.49798,
      lng: -77.74106,
      city: 'Cross Fork',
      state: 'PA',
    },
    17730: {
      lat: 41.10677,
      lng: -76.87591,
      city: 'Dewart',
      state: 'PA',
    },
    17731: {
      lat: 41.42713,
      lng: -76.57293,
      city: 'Eagles Mere',
      state: 'PA',
    },
    17737: {
      lat: 41.28673,
      lng: -76.68149,
      city: 'Hughesville',
      state: 'PA',
    },
    17739: {
      lat: 41.41289,
      lng: -77.40156,
      city: 'Jersey Mills',
      state: 'PA',
    },
    17740: {
      lat: 41.2342,
      lng: -77.26171,
      city: 'Jersey Shore',
      state: 'PA',
    },
    17742: {
      lat: 41.23919,
      lng: -76.5829,
      city: 'Lairdsville',
      state: 'PA',
    },
    17744: {
      lat: 41.24397,
      lng: -77.16139,
      city: 'Linden',
      state: 'PA',
    },
    17745: {
      lat: 41.28589,
      lng: -77.48097,
      city: 'Lock Haven',
      state: 'PA',
    },
    17747: {
      lat: 41.03847,
      lng: -77.31497,
      city: 'Loganton',
      state: 'PA',
    },
    17748: {
      lat: 41.15147,
      lng: -77.35043,
      city: 'McElhattan',
      state: 'PA',
    },
    17749: {
      lat: 41.07232,
      lng: -76.81352,
      city: 'McEwensville',
      state: 'PA',
    },
    17750: {
      lat: 41.05517,
      lng: -77.47805,
      city: 'Mackeyville',
      state: 'PA',
    },
    17751: {
      lat: 41.13086,
      lng: -77.53201,
      city: 'Mill Hall',
      state: 'PA',
    },
    17752: {
      lat: 41.18244,
      lng: -76.92144,
      city: 'Montgomery',
      state: 'PA',
    },
    17754: {
      lat: 41.31108,
      lng: -76.88421,
      city: 'Montoursville',
      state: 'PA',
    },
    17756: {
      lat: 41.20841,
      lng: -76.73325,
      city: 'Muncy',
      state: 'PA',
    },
    17758: {
      lat: 41.35234,
      lng: -76.55155,
      city: 'Muncy Valley',
      state: 'PA',
    },
    17760: {
      lat: 41.3966,
      lng: -77.66602,
      city: 'North Bend',
      state: 'PA',
    },
    17762: {
      lat: 41.28326,
      lng: -76.70751,
      city: 'Picture Rocks',
      state: 'PA',
    },
    17763: {
      lat: 41.50999,
      lng: -76.91388,
      city: 'Ralston',
      state: 'PA',
    },
    17764: {
      lat: 41.34316,
      lng: -77.82735,
      city: 'Renovo',
      state: 'PA',
    },
    17765: {
      lat: 41.57591,
      lng: -76.97168,
      city: 'Roaring Branch',
      state: 'PA',
    },
    17768: { lat: 41.54632, lng: -76.7477, city: 'Shunk', state: 'PA' },
    17769: {
      lat: 41.49939,
      lng: -77.50073,
      city: 'Slate Run',
      state: 'PA',
    },
    17771: {
      lat: 41.43137,
      lng: -77.04524,
      city: 'Trout Run',
      state: 'PA',
    },
    17772: {
      lat: 41.11832,
      lng: -76.71756,
      city: 'Turbotville',
      state: 'PA',
    },
    17774: {
      lat: 41.24782,
      lng: -76.5416,
      city: 'Unityville',
      state: 'PA',
    },
    17776: {
      lat: 41.41704,
      lng: -77.31257,
      city: 'Waterville',
      state: 'PA',
    },
    17777: {
      lat: 41.10332,
      lng: -76.82678,
      city: 'Watsontown',
      state: 'PA',
    },
    17778: {
      lat: 41.28532,
      lng: -77.96477,
      city: 'Westport',
      state: 'PA',
    },
    17779: {
      lat: 41.20551,
      lng: -77.38019,
      city: 'Woolrich',
      state: 'PA',
    },
    17801: {
      lat: 40.83669,
      lng: -76.75762,
      city: 'Sunbury',
      state: 'PA',
    },
    17810: {
      lat: 41.1123,
      lng: -77.02543,
      city: 'Allenwood',
      state: 'PA',
    },
    17812: {
      lat: 40.73626,
      lng: -77.23641,
      city: 'Beaver Springs',
      state: 'PA',
    },
    17813: {
      lat: 40.78505,
      lng: -77.1772,
      city: 'Beavertown',
      state: 'PA',
    },
    17814: {
      lat: 41.26885,
      lng: -76.3751,
      city: 'Benton',
      state: 'PA',
    },
    17815: {
      lat: 41.02216,
      lng: -76.43061,
      city: 'Bloomsburg',
      state: 'PA',
    },
    17820: {
      lat: 40.9011,
      lng: -76.40783,
      city: 'Catawissa',
      state: 'PA',
    },
    17821: {
      lat: 40.9917,
      lng: -76.63959,
      city: 'Danville',
      state: 'PA',
    },
    17822: {
      lat: 40.96861,
      lng: -76.60365,
      city: 'Danville',
      state: 'PA',
    },
    17823: {
      lat: 40.72099,
      lng: -76.72541,
      city: 'Dornsife',
      state: 'PA',
    },
    17824: {
      lat: 40.85652,
      lng: -76.49848,
      city: 'Elysburg',
      state: 'PA',
    },
    17827: {
      lat: 40.75482,
      lng: -76.96133,
      city: 'Freeburg',
      state: 'PA',
    },
    17829: {
      lat: 40.90057,
      lng: -77.15546,
      city: 'Hartleton',
      state: 'PA',
    },
    17830: {
      lat: 40.68791,
      lng: -76.79449,
      city: 'Herndon',
      state: 'PA',
    },
    17832: {
      lat: 40.80554,
      lng: -76.45499,
      city: 'Marion Heights',
      state: 'PA',
    },
    17834: {
      lat: 40.77982,
      lng: -76.46701,
      city: 'Kulpmont',
      state: 'PA',
    },
    17835: {
      lat: 40.88365,
      lng: -77.19795,
      city: 'Laurelton',
      state: 'PA',
    },
    17836: {
      lat: 40.71446,
      lng: -76.60136,
      city: 'Leck Kill',
      state: 'PA',
    },
    17837: {
      lat: 40.97485,
      lng: -76.94247,
      city: 'Lewisburg',
      state: 'PA',
    },
    17840: {
      lat: 40.77537,
      lng: -76.43291,
      city: 'Locust Gap',
      state: 'PA',
    },
    17841: {
      lat: 40.72455,
      lng: -77.35888,
      city: 'McClure',
      state: 'PA',
    },
    17842: {
      lat: 40.80389,
      lng: -77.04329,
      city: 'Middleburg',
      state: 'PA',
    },
    17844: {
      lat: 40.95904,
      lng: -77.08747,
      city: 'Mifflinburg',
      state: 'PA',
    },
    17845: {
      lat: 40.88308,
      lng: -77.21628,
      city: 'Millmont',
      state: 'PA',
    },
    17846: {
      lat: 41.14647,
      lng: -76.51681,
      city: 'Millville',
      state: 'PA',
    },
    17847: {
      lat: 41.00609,
      lng: -76.80612,
      city: 'Milton',
      state: 'PA',
    },
    17850: {
      lat: 40.9671,
      lng: -76.85847,
      city: 'Montandon',
      state: 'PA',
    },
    17851: {
      lat: 40.80255,
      lng: -76.433,
      city: 'Mount Carmel',
      state: 'PA',
    },
    17853: {
      lat: 40.69212,
      lng: -77.01254,
      city: 'Mount Pleasant Mills',
      state: 'PA',
    },
    17855: {
      lat: 40.88735,
      lng: -76.97217,
      city: 'New Berlin',
      state: 'PA',
    },
    17856: {
      lat: 41.05636,
      lng: -76.9722,
      city: 'New Columbia',
      state: 'PA',
    },
    17857: {
      lat: 40.92789,
      lng: -76.76712,
      city: 'Northumberland',
      state: 'PA',
    },
    17858: {
      lat: 40.84238,
      lng: -76.42314,
      city: 'Numidia',
      state: 'PA',
    },
    17859: {
      lat: 41.11821,
      lng: -76.39498,
      city: 'Orangeville',
      state: 'PA',
    },
    17860: {
      lat: 40.82685,
      lng: -76.63402,
      city: 'Paxinos',
      state: 'PA',
    },
    17861: {
      lat: 40.77361,
      lng: -77.0788,
      city: 'Paxtonville',
      state: 'PA',
    },
    17862: {
      lat: 40.86069,
      lng: -77.05661,
      city: 'Penns Creek',
      state: 'PA',
    },
    17864: {
      lat: 40.70294,
      lng: -76.90687,
      city: 'Port Trevorton',
      state: 'PA',
    },
    17865: {
      lat: 40.98688,
      lng: -76.78979,
      city: 'Potts Grove',
      state: 'PA',
    },
    17866: {
      lat: 40.78866,
      lng: -76.55035,
      city: 'Coal Township',
      state: 'PA',
    },
    17867: {
      lat: 40.71464,
      lng: -76.69025,
      city: 'Rebuck',
      state: 'PA',
    },
    17868: {
      lat: 40.95536,
      lng: -76.63098,
      city: 'Riverside',
      state: 'PA',
    },
    17870: {
      lat: 40.80992,
      lng: -76.8897,
      city: 'Selinsgrove',
      state: 'PA',
    },
    17872: {
      lat: 40.7677,
      lng: -76.61317,
      city: 'Shamokin',
      state: 'PA',
    },
    17876: {
      lat: 40.85571,
      lng: -76.82319,
      city: 'Shamokin Dam',
      state: 'PA',
    },
    17878: {
      lat: 41.1721,
      lng: -76.32364,
      city: 'Stillwater',
      state: 'PA',
    },
    17880: {
      lat: 40.89109,
      lng: -77.12383,
      city: 'Swengel',
      state: 'PA',
    },
    17881: {
      lat: 40.78098,
      lng: -76.68632,
      city: 'Trevorton',
      state: 'PA',
    },
    17884: {
      lat: 41.05201,
      lng: -76.67509,
      city: 'Washingtonville',
      state: 'PA',
    },
    17885: {
      lat: 40.87042,
      lng: -77.28941,
      city: 'Weikert',
      state: 'PA',
    },
    17886: {
      lat: 41.01847,
      lng: -76.87598,
      city: 'West Milton',
      state: 'PA',
    },
    17887: {
      lat: 41.07508,
      lng: -76.8849,
      city: 'White Deer',
      state: 'PA',
    },
    17888: {
      lat: 40.81931,
      lng: -76.37306,
      city: 'Wilburton',
      state: 'PA',
    },
    17889: {
      lat: 40.88228,
      lng: -76.9143,
      city: 'Winfield',
      state: 'PA',
    },
    17901: {
      lat: 40.69364,
      lng: -76.25526,
      city: 'Pottsville',
      state: 'PA',
    },
    17920: {
      lat: 40.81328,
      lng: -76.32989,
      city: 'Aristes',
      state: 'PA',
    },
    17921: {
      lat: 40.75422,
      lng: -76.35732,
      city: 'Ashland',
      state: 'PA',
    },
    17922: { lat: 40.592, lng: -76.11212, city: 'Auburn', state: 'PA' },
    17923: {
      lat: 40.66136,
      lng: -76.31871,
      city: 'Branchdale',
      state: 'PA',
    },
    17925: {
      lat: 40.75609,
      lng: -76.07047,
      city: 'Brockton',
      state: 'PA',
    },
    17929: {
      lat: 40.63104,
      lng: -76.19273,
      city: 'Cressona',
      state: 'PA',
    },
    17930: {
      lat: 40.70867,
      lng: -76.11529,
      city: 'Cumbola',
      state: 'PA',
    },
    17931: {
      lat: 40.78378,
      lng: -76.21638,
      city: 'Frackville',
      state: 'PA',
    },
    17933: {
      lat: 40.60357,
      lng: -76.24244,
      city: 'Friedensburg',
      state: 'PA',
    },
    17934: {
      lat: 40.79786,
      lng: -76.21205,
      city: 'Gilberton',
      state: 'PA',
    },
    17935: {
      lat: 40.79417,
      lng: -76.28,
      city: 'Girardville',
      state: 'PA',
    },
    17936: {
      lat: 40.75048,
      lng: -76.33997,
      city: 'Gordon',
      state: 'PA',
    },
    17938: {
      lat: 40.65558,
      lng: -76.50198,
      city: 'Hegins',
      state: 'PA',
    },
    17941: {
      lat: 40.68614,
      lng: -76.61472,
      city: 'Klingerstown',
      state: 'PA',
    },
    17943: {
      lat: 40.76161,
      lng: -76.37949,
      city: 'Lavelle',
      state: 'PA',
    },
    17944: {
      lat: 40.6605,
      lng: -76.28903,
      city: 'Llewellyn',
      state: 'PA',
    },
    17945: {
      lat: 40.78458,
      lng: -76.37523,
      city: 'Locustdale',
      state: 'PA',
    },
    17946: {
      lat: 40.8123,
      lng: -76.2449,
      city: 'Lost Creek',
      state: 'PA',
    },
    17948: {
      lat: 40.82485,
      lng: -76.14223,
      city: 'Mahanoy City',
      state: 'PA',
    },
    17949: {
      lat: 40.79624,
      lng: -76.23547,
      city: 'Mahanoy Plane',
      state: 'PA',
    },
    17951: {
      lat: 40.67919,
      lng: -76.24425,
      city: 'Mar Lin',
      state: 'PA',
    },
    17952: {
      lat: 40.76434,
      lng: -76.05997,
      city: 'Mary D',
      state: 'PA',
    },
    17953: {
      lat: 40.74728,
      lng: -76.09969,
      city: 'Middleport',
      state: 'PA',
    },
    17954: {
      lat: 40.69047,
      lng: -76.25985,
      city: 'Minersville',
      state: 'PA',
    },
    17957: { lat: 40.59359, lng: -76.51651, city: 'Muir', state: 'PA' },
    17959: {
      lat: 40.74224,
      lng: -76.15141,
      city: 'New Philadelphia',
      state: 'PA',
    },
    17960: {
      lat: 40.69932,
      lng: -75.94656,
      city: 'New Ringgold',
      state: 'PA',
    },
    17961: {
      lat: 40.64713,
      lng: -76.05988,
      city: 'Orwigsburg',
      state: 'PA',
    },
    17963: {
      lat: 40.56133,
      lng: -76.39548,
      city: 'Pine Grove',
      state: 'PA',
    },
    17964: {
      lat: 40.71415,
      lng: -76.49785,
      city: 'Pitman',
      state: 'PA',
    },
    17965: {
      lat: 40.69919,
      lng: -76.16536,
      city: 'Port Carbon',
      state: 'PA',
    },
    17967: {
      lat: 40.86047,
      lng: -76.20945,
      city: 'Ringtown',
      state: 'PA',
    },
    17968: {
      lat: 40.6417,
      lng: -76.62625,
      city: 'Sacramento',
      state: 'PA',
    },
    17970: {
      lat: 40.72072,
      lng: -76.19056,
      city: 'Saint Clair',
      state: 'PA',
    },
    17972: {
      lat: 40.58199,
      lng: -76.21367,
      city: 'Schuylkill Haven',
      state: 'PA',
    },
    17974: {
      lat: 40.69592,
      lng: -76.23606,
      city: 'Seltzer',
      state: 'PA',
    },
    17976: {
      lat: 40.8204,
      lng: -76.21131,
      city: 'Shenandoah',
      state: 'PA',
    },
    17978: {
      lat: 40.6165,
      lng: -76.65219,
      city: 'Spring Glen',
      state: 'PA',
    },
    17979: {
      lat: 40.56729,
      lng: -76.20207,
      city: 'Summit Station',
      state: 'PA',
    },
    17980: {
      lat: 40.531,
      lng: -76.62065,
      city: 'Tower City',
      state: 'PA',
    },
    17981: {
      lat: 40.63077,
      lng: -76.39523,
      city: 'Tremont',
      state: 'PA',
    },
    17982: {
      lat: 40.76798,
      lng: -76.03827,
      city: 'Tuscarora',
      state: 'PA',
    },
    17983: {
      lat: 40.64403,
      lng: -76.55027,
      city: 'Valley View',
      state: 'PA',
    },
    17985: {
      lat: 40.91935,
      lng: -76.23729,
      city: 'Zion Grove',
      state: 'PA',
    },
    18011: {
      lat: 40.47288,
      lng: -75.64395,
      city: 'Alburtis',
      state: 'PA',
    },
    18012: {
      lat: 40.81135,
      lng: -75.59158,
      city: 'Aquashicola',
      state: 'PA',
    },
    18013: {
      lat: 40.85511,
      lng: -75.17114,
      city: 'Bangor',
      state: 'PA',
    },
    18014: { lat: 40.7654, lng: -75.40923, city: 'Bath', state: 'PA' },
    18015: {
      lat: 40.5887,
      lng: -75.36786,
      city: 'Bethlehem',
      state: 'PA',
    },
    18017: {
      lat: 40.66069,
      lng: -75.38739,
      city: 'Bethlehem',
      state: 'PA',
    },
    18018: {
      lat: 40.62766,
      lng: -75.39538,
      city: 'Bethlehem',
      state: 'PA',
    },
    18020: {
      lat: 40.67267,
      lng: -75.32692,
      city: 'Bethlehem',
      state: 'PA',
    },
    18030: {
      lat: 40.80408,
      lng: -75.66336,
      city: 'Bowmanstown',
      state: 'PA',
    },
    18031: {
      lat: 40.554,
      lng: -75.65361,
      city: 'Breinigsville',
      state: 'PA',
    },
    18032: {
      lat: 40.65694,
      lng: -75.46776,
      city: 'Catasauqua',
      state: 'PA',
    },
    18034: {
      lat: 40.54182,
      lng: -75.41654,
      city: 'Center Valley',
      state: 'PA',
    },
    18035: {
      lat: 40.75053,
      lng: -75.54148,
      city: 'Cherryville',
      state: 'PA',
    },
    18036: {
      lat: 40.50925,
      lng: -75.38891,
      city: 'Coopersburg',
      state: 'PA',
    },
    18037: {
      lat: 40.68203,
      lng: -75.54638,
      city: 'Coplay',
      state: 'PA',
    },
    18038: {
      lat: 40.79574,
      lng: -75.48246,
      city: 'Danielsville',
      state: 'PA',
    },
    18040: {
      lat: 40.7459,
      lng: -75.22172,
      city: 'Easton',
      state: 'PA',
    },
    18041: {
      lat: 40.41742,
      lng: -75.51306,
      city: 'East Greenville',
      state: 'PA',
    },
    18042: {
      lat: 40.6497,
      lng: -75.22376,
      city: 'Easton',
      state: 'PA',
    },
    18045: {
      lat: 40.6919,
      lng: -75.27494,
      city: 'Easton',
      state: 'PA',
    },
    18046: {
      lat: 40.54803,
      lng: -75.56029,
      city: 'East Texas',
      state: 'PA',
    },
    18049: {
      lat: 40.51912,
      lng: -75.50018,
      city: 'Emmaus',
      state: 'PA',
    },
    18051: {
      lat: 40.59409,
      lng: -75.66415,
      city: 'Fogelsville',
      state: 'PA',
    },
    18052: {
      lat: 40.65668,
      lng: -75.50443,
      city: 'Whitehall',
      state: 'PA',
    },
    18053: {
      lat: 40.71802,
      lng: -75.70999,
      city: 'Germansville',
      state: 'PA',
    },
    18054: {
      lat: 40.35321,
      lng: -75.43824,
      city: 'Green Lane',
      state: 'PA',
    },
    18055: {
      lat: 40.58794,
      lng: -75.29929,
      city: 'Hellertown',
      state: 'PA',
    },
    18056: {
      lat: 40.44798,
      lng: -75.54911,
      city: 'Hereford',
      state: 'PA',
    },
    18058: {
      lat: 40.8922,
      lng: -75.49094,
      city: 'Kunkletown',
      state: 'PA',
    },
    18059: {
      lat: 40.7233,
      lng: -75.53644,
      city: 'Laurys Station',
      state: 'PA',
    },
    18062: {
      lat: 40.5035,
      lng: -75.57886,
      city: 'Macungie',
      state: 'PA',
    },
    18063: {
      lat: 40.78229,
      lng: -75.18164,
      city: 'Martins Creek',
      state: 'PA',
    },
    18064: {
      lat: 40.75755,
      lng: -75.31927,
      city: 'Nazareth',
      state: 'PA',
    },
    18065: {
      lat: 40.69814,
      lng: -75.61086,
      city: 'Neffs',
      state: 'PA',
    },
    18066: {
      lat: 40.6635,
      lng: -75.74492,
      city: 'New Tripoli',
      state: 'PA',
    },
    18067: {
      lat: 40.71792,
      lng: -75.47937,
      city: 'Northampton',
      state: 'PA',
    },
    18069: { lat: 40.626, lng: -75.616, city: 'Orefield', state: 'PA' },
    18070: { lat: 40.4317, lng: -75.53884, city: 'Palm', state: 'PA' },
    18071: {
      lat: 40.83098,
      lng: -75.57843,
      city: 'Palmerton',
      state: 'PA',
    },
    18072: {
      lat: 40.84548,
      lng: -75.25842,
      city: 'Pen Argyl',
      state: 'PA',
    },
    18073: {
      lat: 40.38378,
      lng: -75.47702,
      city: 'Pennsburg',
      state: 'PA',
    },
    18074: {
      lat: 40.31971,
      lng: -75.51424,
      city: 'Perkiomenville',
      state: 'PA',
    },
    18076: {
      lat: 40.37382,
      lng: -75.48154,
      city: 'Red Hill',
      state: 'PA',
    },
    18077: {
      lat: 40.56603,
      lng: -75.24115,
      city: 'Riegelsville',
      state: 'PA',
    },
    18078: {
      lat: 40.67181,
      lng: -75.62096,
      city: 'Schnecksville',
      state: 'PA',
    },
    18079: {
      lat: 40.74415,
      lng: -75.65754,
      city: 'Slatedale',
      state: 'PA',
    },
    18080: {
      lat: 40.73618,
      lng: -75.63227,
      city: 'Slatington',
      state: 'PA',
    },
    18081: {
      lat: 40.56236,
      lng: -75.28396,
      city: 'Springtown',
      state: 'PA',
    },
    18083: {
      lat: 40.75494,
      lng: -75.2637,
      city: 'Stockertown',
      state: 'PA',
    },
    18085: {
      lat: 40.74174,
      lng: -75.25434,
      city: 'Tatamy',
      state: 'PA',
    },
    18086: {
      lat: 40.73867,
      lng: -75.54757,
      city: 'Treichlers',
      state: 'PA',
    },
    18087: {
      lat: 40.55398,
      lng: -75.59588,
      city: 'Trexlertown',
      state: 'PA',
    },
    18088: {
      lat: 40.76578,
      lng: -75.5576,
      city: 'Walnutport',
      state: 'PA',
    },
    18091: {
      lat: 40.82656,
      lng: -75.32068,
      city: 'Wind Gap',
      state: 'PA',
    },
    18092: {
      lat: 40.46844,
      lng: -75.51372,
      city: 'Zionsville',
      state: 'PA',
    },
    18101: {
      lat: 40.60301,
      lng: -75.4698,
      city: 'Allentown',
      state: 'PA',
    },
    18102: {
      lat: 40.60792,
      lng: -75.47613,
      city: 'Allentown',
      state: 'PA',
    },
    18103: {
      lat: 40.57223,
      lng: -75.48138,
      city: 'Allentown',
      state: 'PA',
    },
    18104: {
      lat: 40.61122,
      lng: -75.54699,
      city: 'Allentown',
      state: 'PA',
    },
    18106: {
      lat: 40.57472,
      lng: -75.59542,
      city: 'Allentown',
      state: 'PA',
    },
    18109: {
      lat: 40.63673,
      lng: -75.44043,
      city: 'Allentown',
      state: 'PA',
    },
    18195: {
      lat: 40.58427,
      lng: -75.62479,
      city: 'Allentown',
      state: 'PA',
    },
    18201: {
      lat: 40.95198,
      lng: -75.95395,
      city: 'Hazleton',
      state: 'PA',
    },
    18202: {
      lat: 40.94874,
      lng: -76.0483,
      city: 'Hazleton',
      state: 'PA',
    },
    18210: {
      lat: 41.00057,
      lng: -75.59146,
      city: 'Albrightsville',
      state: 'PA',
    },
    18211: {
      lat: 40.74315,
      lng: -75.82314,
      city: 'Andreas',
      state: 'PA',
    },
    18212: {
      lat: 40.77292,
      lng: -75.71883,
      city: 'Ashfield',
      state: 'PA',
    },
    18214: {
      lat: 40.80667,
      lng: -76.08093,
      city: 'Barnesville',
      state: 'PA',
    },
    18216: {
      lat: 40.94212,
      lng: -75.90517,
      city: 'Beaver Meadows',
      state: 'PA',
    },
    18218: {
      lat: 40.81974,
      lng: -75.91611,
      city: 'Coaldale',
      state: 'PA',
    },
    18219: {
      lat: 40.99105,
      lng: -76.05824,
      city: 'Conyngham',
      state: 'PA',
    },
    18220: {
      lat: 40.83958,
      lng: -76.07509,
      city: 'Delano',
      state: 'PA',
    },
    18221: {
      lat: 41.00152,
      lng: -75.919,
      city: 'Drifton',
      state: 'PA',
    },
    18222: {
      lat: 41.03569,
      lng: -76.00097,
      city: 'Drums',
      state: 'PA',
    },
    18223: {
      lat: 40.98221,
      lng: -75.95011,
      city: 'Ebervale',
      state: 'PA',
    },
    18224: {
      lat: 41.02499,
      lng: -75.88728,
      city: 'Freeland',
      state: 'PA',
    },
    18225: {
      lat: 40.98137,
      lng: -75.97111,
      city: 'Harleigh',
      state: 'PA',
    },
    18229: {
      lat: 40.93245,
      lng: -75.68247,
      city: 'Jim Thorpe',
      state: 'PA',
    },
    18230: {
      lat: 40.9201,
      lng: -75.93077,
      city: 'Junedale',
      state: 'PA',
    },
    18231: {
      lat: 40.90068,
      lng: -76.00446,
      city: 'Kelayres',
      state: 'PA',
    },
    18232: {
      lat: 40.83515,
      lng: -75.87939,
      city: 'Lansford',
      state: 'PA',
    },
    18234: {
      lat: 40.99259,
      lng: -75.96468,
      city: 'Lattimer Mines',
      state: 'PA',
    },
    18235: {
      lat: 40.83603,
      lng: -75.692,
      city: 'Lehighton',
      state: 'PA',
    },
    18237: {
      lat: 40.88591,
      lng: -76.02582,
      city: 'Mcadoo',
      state: 'PA',
    },
    18239: {
      lat: 40.98962,
      lng: -75.98154,
      city: 'Milnesville',
      state: 'PA',
    },
    18240: {
      lat: 40.86279,
      lng: -75.84727,
      city: 'Nesquehoning',
      state: 'PA',
    },
    18241: {
      lat: 40.94439,
      lng: -76.16492,
      city: 'Nuremberg',
      state: 'PA',
    },
    18242: {
      lat: 40.90606,
      lng: -76.12349,
      city: 'Oneida',
      state: 'PA',
    },
    18244: {
      lat: 40.82361,
      lng: -75.66879,
      city: 'Parryville',
      state: 'PA',
    },
    18245: {
      lat: 40.8528,
      lng: -76.03011,
      city: 'Quakake',
      state: 'PA',
    },
    18246: {
      lat: 40.95743,
      lng: -76.195,
      city: 'Rock Glen',
      state: 'PA',
    },
    18248: {
      lat: 40.90089,
      lng: -76.09448,
      city: 'Sheppton',
      state: 'PA',
    },
    18249: {
      lat: 40.98457,
      lng: -76.1206,
      city: 'Sugarloaf',
      state: 'PA',
    },
    18250: {
      lat: 40.81744,
      lng: -75.86145,
      city: 'Summit Hill',
      state: 'PA',
    },
    18252: {
      lat: 40.77857,
      lng: -75.97893,
      city: 'Tamaqua',
      state: 'PA',
    },
    18254: {
      lat: 40.91594,
      lng: -75.96563,
      city: 'Tresckow',
      state: 'PA',
    },
    18255: {
      lat: 40.93308,
      lng: -75.82996,
      city: 'Weatherly',
      state: 'PA',
    },
    18256: {
      lat: 40.94044,
      lng: -76.14752,
      city: 'Weston',
      state: 'PA',
    },
    18301: {
      lat: 41.03141,
      lng: -75.18316,
      city: 'East Stroudsburg',
      state: 'PA',
    },
    18302: {
      lat: 41.09243,
      lng: -75.11783,
      city: 'East Stroudsburg',
      state: 'PA',
    },
    18321: {
      lat: 41.01734,
      lng: -75.28754,
      city: 'Bartonsville',
      state: 'PA',
    },
    18322: {
      lat: 40.9221,
      lng: -75.39714,
      city: 'Brodheadsville',
      state: 'PA',
    },
    18323: {
      lat: 41.19558,
      lng: -75.27264,
      city: 'Buck Hill Falls',
      state: 'PA',
    },
    18324: {
      lat: 41.14712,
      lng: -75.0001,
      city: 'Bushkill',
      state: 'PA',
    },
    18325: {
      lat: 41.22545,
      lng: -75.22268,
      city: 'Canadensis',
      state: 'PA',
    },
    18326: {
      lat: 41.15617,
      lng: -75.25186,
      city: 'Cresco',
      state: 'PA',
    },
    18327: {
      lat: 40.98341,
      lng: -75.14665,
      city: 'Delaware Water Gap',
      state: 'PA',
    },
    18328: {
      lat: 41.22531,
      lng: -75.00633,
      city: 'Dingmans Ferry',
      state: 'PA',
    },
    18330: {
      lat: 40.96505,
      lng: -75.45285,
      city: 'Effort',
      state: 'PA',
    },
    18331: {
      lat: 40.90878,
      lng: -75.44076,
      city: 'Gilbert',
      state: 'PA',
    },
    18332: {
      lat: 41.0922,
      lng: -75.25968,
      city: 'Henryville',
      state: 'PA',
    },
    18333: {
      lat: 40.89833,
      lng: -75.49104,
      city: 'Kresgeville',
      state: 'PA',
    },
    18334: {
      lat: 41.07208,
      lng: -75.44298,
      city: 'Long Pond',
      state: 'PA',
    },
    18335: {
      lat: 41.06413,
      lng: -75.10455,
      city: 'Marshalls Creek',
      state: 'PA',
    },
    18336: {
      lat: 41.37566,
      lng: -74.73674,
      city: 'Matamoras',
      state: 'PA',
    },
    18337: {
      lat: 41.33406,
      lng: -74.87342,
      city: 'Milford',
      state: 'PA',
    },
    18340: {
      lat: 41.42027,
      lng: -74.77503,
      city: 'Millrift',
      state: 'PA',
    },
    18342: {
      lat: 41.17045,
      lng: -75.30485,
      city: 'Mountainhome',
      state: 'PA',
    },
    18343: {
      lat: 40.89043,
      lng: -75.1058,
      city: 'Mount Bethel',
      state: 'PA',
    },
    18344: {
      lat: 41.11735,
      lng: -75.34442,
      city: 'Mount Pocono',
      state: 'PA',
    },
    18346: {
      lat: 41.14643,
      lng: -75.42752,
      city: 'Pocono Summit',
      state: 'PA',
    },
    18347: {
      lat: 41.15116,
      lng: -75.53664,
      city: 'Pocono Lake',
      state: 'PA',
    },
    18349: {
      lat: 41.0995,
      lng: -75.3682,
      city: 'Pocono Manor',
      state: 'PA',
    },
    18350: {
      lat: 41.11339,
      lng: -75.45633,
      city: 'Pocono Pines',
      state: 'PA',
    },
    18351: {
      lat: 40.92321,
      lng: -75.10063,
      city: 'Portland',
      state: 'PA',
    },
    18353: {
      lat: 40.89848,
      lng: -75.36953,
      city: 'Saylorsburg',
      state: 'PA',
    },
    18354: {
      lat: 40.92561,
      lng: -75.32831,
      city: 'Sciota',
      state: 'PA',
    },
    18355: {
      lat: 41.07301,
      lng: -75.35553,
      city: 'Scotrun',
      state: 'PA',
    },
    18356: {
      lat: 41.01574,
      lng: -75.11192,
      city: 'Shawnee On Delaware',
      state: 'PA',
    },
    18357: {
      lat: 41.22956,
      lng: -75.2191,
      city: 'Skytop',
      state: 'PA',
    },
    18360: {
      lat: 40.97254,
      lng: -75.28562,
      city: 'Stroudsburg',
      state: 'PA',
    },
    18370: {
      lat: 41.08926,
      lng: -75.33771,
      city: 'Swiftwater',
      state: 'PA',
    },
    18371: {
      lat: 41.15837,
      lng: -75.03658,
      city: 'Tamiment',
      state: 'PA',
    },
    18372: {
      lat: 41.04777,
      lng: -75.32512,
      city: 'Tannersville',
      state: 'PA',
    },
    18403: {
      lat: 41.50495,
      lng: -75.53529,
      city: 'Archbald',
      state: 'PA',
    },
    18405: {
      lat: 41.59792,
      lng: -75.09509,
      city: 'Beach Lake',
      state: 'PA',
    },
    18407: {
      lat: 41.59971,
      lng: -75.53026,
      city: 'Carbondale',
      state: 'PA',
    },
    18411: {
      lat: 41.45655,
      lng: -75.74295,
      city: 'Clarks Summit',
      state: 'PA',
    },
    18413: {
      lat: 41.64767,
      lng: -75.53258,
      city: 'Clifford',
      state: 'PA',
    },
    18414: {
      lat: 41.53893,
      lng: -75.7427,
      city: 'Dalton',
      state: 'PA',
    },
    18415: {
      lat: 41.74821,
      lng: -75.11315,
      city: 'Damascus',
      state: 'PA',
    },
    18417: {
      lat: 41.80705,
      lng: -75.20122,
      city: 'Equinunk',
      state: 'PA',
    },
    18419: {
      lat: 41.57858,
      lng: -75.7994,
      city: 'Factoryville',
      state: 'PA',
    },
    18420: {
      lat: 41.60333,
      lng: -75.70589,
      city: 'Fleetville',
      state: 'PA',
    },
    18421: {
      lat: 41.66337,
      lng: -75.4691,
      city: 'Forest City',
      state: 'PA',
    },
    18424: {
      lat: 41.24267,
      lng: -75.52234,
      city: 'Gouldsboro',
      state: 'PA',
    },
    18425: {
      lat: 41.42263,
      lng: -75.02206,
      city: 'Greeley',
      state: 'PA',
    },
    18426: {
      lat: 41.32774,
      lng: -75.23577,
      city: 'Greentown',
      state: 'PA',
    },
    18428: {
      lat: 41.42946,
      lng: -75.1253,
      city: 'Hawley',
      state: 'PA',
    },
    18430: {
      lat: 41.7428,
      lng: -75.47779,
      city: 'Herrick Center',
      state: 'PA',
    },
    18431: {
      lat: 41.62214,
      lng: -75.25531,
      city: 'Honesdale',
      state: 'PA',
    },
    18433: {
      lat: 41.57107,
      lng: -75.58452,
      city: 'Jermyn',
      state: 'PA',
    },
    18434: {
      lat: 41.46014,
      lng: -75.54823,
      city: 'Jessup',
      state: 'PA',
    },
    18435: {
      lat: 41.49221,
      lng: -75.00661,
      city: 'Lackawaxen',
      state: 'PA',
    },
    18436: {
      lat: 41.43867,
      lng: -75.3959,
      city: 'Lake Ariel',
      state: 'PA',
    },
    18437: {
      lat: 41.86859,
      lng: -75.30345,
      city: 'Lake Como',
      state: 'PA',
    },
    18438: {
      lat: 41.43591,
      lng: -75.24499,
      city: 'Lakeville',
      state: 'PA',
    },
    18439: {
      lat: 41.82674,
      lng: -75.34735,
      city: 'Lakewood',
      state: 'PA',
    },
    18440: {
      lat: 41.55931,
      lng: -75.76505,
      city: 'La Plume',
      state: 'PA',
    },
    18441: {
      lat: 41.65833,
      lng: -75.62565,
      city: 'Lenoxville',
      state: 'PA',
    },
    18443: {
      lat: 41.66262,
      lng: -75.11059,
      city: 'Milanville',
      state: 'PA',
    },
    18444: {
      lat: 41.33912,
      lng: -75.54667,
      city: 'Moscow',
      state: 'PA',
    },
    18445: {
      lat: 41.2948,
      lng: -75.34751,
      city: 'Newfoundland',
      state: 'PA',
    },
    18446: {
      lat: 41.64513,
      lng: -75.76044,
      city: 'Nicholson',
      state: 'PA',
    },
    18447: {
      lat: 41.5132,
      lng: -75.61684,
      city: 'Olyphant',
      state: 'PA',
    },
    18451: {
      lat: 41.39849,
      lng: -75.22023,
      city: 'Paupack',
      state: 'PA',
    },
    18452: {
      lat: 41.48387,
      lng: -75.58951,
      city: 'Peckville',
      state: 'PA',
    },
    18453: {
      lat: 41.74971,
      lng: -75.361,
      city: 'Pleasant Mount',
      state: 'PA',
    },
    18454: {
      lat: 41.81963,
      lng: -75.42731,
      city: 'Poyntelle',
      state: 'PA',
    },
    18455: {
      lat: 41.88467,
      lng: -75.35541,
      city: 'Preston Park',
      state: 'PA',
    },
    18456: {
      lat: 41.61843,
      lng: -75.3404,
      city: 'Prompton',
      state: 'PA',
    },
    18457: {
      lat: 41.4696,
      lng: -75.05084,
      city: 'Rowland',
      state: 'PA',
    },
    18458: {
      lat: 41.40916,
      lng: -74.91757,
      city: 'Shohola',
      state: 'PA',
    },
    18460: {
      lat: 41.26719,
      lng: -75.34744,
      city: 'South Sterling',
      state: 'PA',
    },
    18461: {
      lat: 41.92774,
      lng: -75.32434,
      city: 'Starlight',
      state: 'PA',
    },
    18462: {
      lat: 41.9049,
      lng: -75.42988,
      city: 'Starrucca',
      state: 'PA',
    },
    18463: {
      lat: 41.34825,
      lng: -75.37964,
      city: 'Sterling',
      state: 'PA',
    },
    18464: {
      lat: 41.40181,
      lng: -75.17824,
      city: 'Tafton',
      state: 'PA',
    },
    18465: {
      lat: 41.83654,
      lng: -75.51811,
      city: 'Thompson',
      state: 'PA',
    },
    18466: {
      lat: 41.19709,
      lng: -75.37796,
      city: 'Tobyhanna',
      state: 'PA',
    },
    18469: {
      lat: 41.70352,
      lng: -75.14597,
      city: 'Tyler Hill',
      state: 'PA',
    },
    18470: {
      lat: 41.732,
      lng: -75.53502,
      city: 'Union Dale',
      state: 'PA',
    },
    18471: {
      lat: 41.5291,
      lng: -75.69807,
      city: 'Waverly',
      state: 'PA',
    },
    18472: {
      lat: 41.58738,
      lng: -75.40002,
      city: 'Waymart',
      state: 'PA',
    },
    18473: {
      lat: 41.52828,
      lng: -75.20821,
      city: 'White Mills',
      state: 'PA',
    },
    18503: {
      lat: 41.41007,
      lng: -75.66767,
      city: 'Scranton',
      state: 'PA',
    },
    18504: {
      lat: 41.42375,
      lng: -75.70581,
      city: 'Scranton',
      state: 'PA',
    },
    18505: {
      lat: 41.38874,
      lng: -75.65109,
      city: 'Scranton',
      state: 'PA',
    },
    18507: {
      lat: 41.35541,
      lng: -75.68016,
      city: 'Moosic',
      state: 'PA',
    },
    18508: {
      lat: 41.45503,
      lng: -75.65827,
      city: 'Scranton',
      state: 'PA',
    },
    18509: {
      lat: 41.43062,
      lng: -75.64321,
      city: 'Scranton',
      state: 'PA',
    },
    18510: {
      lat: 41.40735,
      lng: -75.64376,
      city: 'Scranton',
      state: 'PA',
    },
    18512: {
      lat: 41.42648,
      lng: -75.5912,
      city: 'Scranton',
      state: 'PA',
    },
    18517: {
      lat: 41.39524,
      lng: -75.71461,
      city: 'Taylor',
      state: 'PA',
    },
    18518: {
      lat: 41.37495,
      lng: -75.73963,
      city: 'Old Forge',
      state: 'PA',
    },
    18519: {
      lat: 41.46207,
      lng: -75.63062,
      city: 'Scranton',
      state: 'PA',
    },
    18602: {
      lat: 41.18749,
      lng: -75.75526,
      city: 'Bear Creek',
      state: 'PA',
    },
    18603: {
      lat: 41.08506,
      lng: -76.24591,
      city: 'Berwick',
      state: 'PA',
    },
    18610: {
      lat: 41.05959,
      lng: -75.53226,
      city: 'Blakeslee',
      state: 'PA',
    },
    18612: {
      lat: 41.35025,
      lng: -75.98207,
      city: 'Dallas',
      state: 'PA',
    },
    18614: {
      lat: 41.48373,
      lng: -76.35768,
      city: 'Dushore',
      state: 'PA',
    },
    18615: {
      lat: 41.45968,
      lng: -75.85752,
      city: 'Falls',
      state: 'PA',
    },
    18616: {
      lat: 41.51907,
      lng: -76.63954,
      city: 'Forksville',
      state: 'PA',
    },
    18617: {
      lat: 41.17149,
      lng: -76.07707,
      city: 'Glen Lyon',
      state: 'PA',
    },
    18618: {
      lat: 41.37883,
      lng: -76.0313,
      city: 'Harveys Lake',
      state: 'PA',
    },
    18619: {
      lat: 41.43574,
      lng: -76.7195,
      city: 'Hillsgrove',
      state: 'PA',
    },
    18621: {
      lat: 41.25441,
      lng: -76.09129,
      city: 'Hunlock Creek',
      state: 'PA',
    },
    18622: {
      lat: 41.20228,
      lng: -76.27348,
      city: 'Huntington Mills',
      state: 'PA',
    },
    18623: {
      lat: 41.67297,
      lng: -76.14768,
      city: 'Laceyville',
      state: 'PA',
    },
    18624: {
      lat: 41.05529,
      lng: -75.62088,
      city: 'Lake Harmony',
      state: 'PA',
    },
    18625: {
      lat: 41.51311,
      lng: -75.85027,
      city: 'Lake Winola',
      state: 'PA',
    },
    18626: {
      lat: 41.41136,
      lng: -76.4635,
      city: 'Laporte',
      state: 'PA',
    },
    18627: {
      lat: 41.31559,
      lng: -76.02235,
      city: 'Lehman',
      state: 'PA',
    },
    18628: {
      lat: 41.41474,
      lng: -76.32047,
      city: 'Lopez',
      state: 'PA',
    },
    18629: {
      lat: 41.52393,
      lng: -76.13365,
      city: 'Mehoopany',
      state: 'PA',
    },
    18630: {
      lat: 41.66361,
      lng: -76.02698,
      city: 'Meshoppen',
      state: 'PA',
    },
    18631: {
      lat: 41.03079,
      lng: -76.30233,
      city: 'Mifflinville',
      state: 'PA',
    },
    18632: {
      lat: 41.46769,
      lng: -76.3764,
      city: 'Mildred',
      state: 'PA',
    },
    18634: {
      lat: 41.18984,
      lng: -76.0218,
      city: 'Nanticoke',
      state: 'PA',
    },
    18635: {
      lat: 41.02961,
      lng: -76.20598,
      city: 'Nescopeck',
      state: 'PA',
    },
    18636: {
      lat: 41.41215,
      lng: -76.08859,
      city: 'Noxen',
      state: 'PA',
    },
    18640: {
      lat: 41.29724,
      lng: -75.74024,
      city: 'Pittston',
      state: 'PA',
    },
    18641: {
      lat: 41.33644,
      lng: -75.73007,
      city: 'Pittston',
      state: 'PA',
    },
    18642: {
      lat: 41.35359,
      lng: -75.77681,
      city: 'Duryea',
      state: 'PA',
    },
    18643: {
      lat: 41.36456,
      lng: -75.8344,
      city: 'Pittston',
      state: 'PA',
    },
    18644: {
      lat: 41.33192,
      lng: -75.87753,
      city: 'Wyoming',
      state: 'PA',
    },
    18651: {
      lat: 41.24748,
      lng: -75.962,
      city: 'Plymouth',
      state: 'PA',
    },
    18655: {
      lat: 41.18327,
      lng: -76.19619,
      city: 'Shickshinny',
      state: 'PA',
    },
    18656: {
      lat: 41.32703,
      lng: -76.20442,
      city: 'Sweet Valley',
      state: 'PA',
    },
    18657: {
      lat: 41.52712,
      lng: -75.95601,
      city: 'Tunkhannock',
      state: 'PA',
    },
    18660: {
      lat: 41.10195,
      lng: -76.06196,
      city: 'Wapwallopen',
      state: 'PA',
    },
    18661: {
      lat: 41.08435,
      lng: -75.75666,
      city: 'White Haven',
      state: 'PA',
    },
    18701: {
      lat: 41.2433,
      lng: -75.88523,
      city: 'Wilkes Barre',
      state: 'PA',
    },
    18702: {
      lat: 41.21406,
      lng: -75.76925,
      city: 'Wilkes Barre',
      state: 'PA',
    },
    18704: {
      lat: 41.27676,
      lng: -75.89474,
      city: 'Kingston',
      state: 'PA',
    },
    18705: {
      lat: 41.27156,
      lng: -75.84241,
      city: 'Wilkes Barre',
      state: 'PA',
    },
    18706: {
      lat: 41.20432,
      lng: -75.91231,
      city: 'Wilkes Barre',
      state: 'PA',
    },
    18707: {
      lat: 41.13325,
      lng: -75.9228,
      city: 'Mountain Top',
      state: 'PA',
    },
    18708: {
      lat: 41.297,
      lng: -75.95927,
      city: 'Shavertown',
      state: 'PA',
    },
    18709: {
      lat: 41.28635,
      lng: -75.89674,
      city: 'Luzerne',
      state: 'PA',
    },
    18801: {
      lat: 41.82861,
      lng: -75.9437,
      city: 'Montrose',
      state: 'PA',
    },
    18810: {
      lat: 41.93738,
      lng: -76.50181,
      city: 'Athens',
      state: 'PA',
    },
    18812: {
      lat: 41.97203,
      lng: -75.96822,
      city: 'Brackney',
      state: 'PA',
    },
    18814: {
      lat: 41.78151,
      lng: -76.60121,
      city: 'Burlington',
      state: 'PA',
    },
    18816: {
      lat: 41.73977,
      lng: -75.90966,
      city: 'Dimock',
      state: 'PA',
    },
    18817: {
      lat: 41.87431,
      lng: -76.62075,
      city: 'East Smithfield',
      state: 'PA',
    },
    18818: {
      lat: 41.91343,
      lng: -76.04843,
      city: 'Friendsville',
      state: 'PA',
    },
    18820: {
      lat: 41.7779,
      lng: -75.65029,
      city: 'Gibson',
      state: 'PA',
    },
    18821: {
      lat: 41.98588,
      lng: -75.73466,
      city: 'Great Bend',
      state: 'PA',
    },
    18822: {
      lat: 41.9482,
      lng: -75.80116,
      city: 'Hallstead',
      state: 'PA',
    },
    18823: {
      lat: 41.77415,
      lng: -75.69168,
      city: 'Harford',
      state: 'PA',
    },
    18824: {
      lat: 41.70422,
      lng: -75.786,
      city: 'Hop Bottom',
      state: 'PA',
    },
    18825: {
      lat: 41.82276,
      lng: -75.599,
      city: 'Jackson',
      state: 'PA',
    },
    18826: {
      lat: 41.7495,
      lng: -75.74276,
      city: 'Kingsley',
      state: 'PA',
    },
    18828: {
      lat: 41.79672,
      lng: -76.11245,
      city: 'Lawton',
      state: 'PA',
    },
    18829: {
      lat: 41.8395,
      lng: -76.17088,
      city: 'Le Raysville',
      state: 'PA',
    },
    18830: {
      lat: 41.95049,
      lng: -76.10904,
      city: 'Little Meadows',
      state: 'PA',
    },
    18831: {
      lat: 41.89356,
      lng: -76.60832,
      city: 'Milan',
      state: 'PA',
    },
    18832: {
      lat: 41.67625,
      lng: -76.55639,
      city: 'Monroeton',
      state: 'PA',
    },
    18833: {
      lat: 41.59433,
      lng: -76.46515,
      city: 'New Albany',
      state: 'PA',
    },
    18834: {
      lat: 41.84185,
      lng: -75.71189,
      city: 'New Milford',
      state: 'PA',
    },
    18837: { lat: 41.91928, lng: -76.29122, city: 'Rome', state: 'PA' },
    18840: {
      lat: 41.97433,
      lng: -76.52968,
      city: 'Sayre',
      state: 'PA',
    },
    18842: {
      lat: 41.75594,
      lng: -75.61652,
      city: 'South Gibson',
      state: 'PA',
    },
    18843: {
      lat: 41.80446,
      lng: -75.89416,
      city: 'South Montrose',
      state: 'PA',
    },
    18844: {
      lat: 41.69247,
      lng: -75.91351,
      city: 'Springville',
      state: 'PA',
    },
    18845: {
      lat: 41.78994,
      lng: -76.18454,
      city: 'Stevensville',
      state: 'PA',
    },
    18846: {
      lat: 41.60494,
      lng: -76.23929,
      city: 'Sugar Run',
      state: 'PA',
    },
    18847: {
      lat: 41.93539,
      lng: -75.56893,
      city: 'Susquehanna',
      state: 'PA',
    },
    18848: {
      lat: 41.75358,
      lng: -76.45615,
      city: 'Towanda',
      state: 'PA',
    },
    18850: {
      lat: 41.85916,
      lng: -76.49126,
      city: 'Ulster',
      state: 'PA',
    },
    18851: {
      lat: 41.93677,
      lng: -76.17992,
      city: 'Warren Center',
      state: 'PA',
    },
    18853: {
      lat: 41.71654,
      lng: -76.27534,
      city: 'Wyalusing',
      state: 'PA',
    },
    18854: {
      lat: 41.79532,
      lng: -76.35472,
      city: 'Wysox',
      state: 'PA',
    },
    18901: {
      lat: 40.30529,
      lng: -75.15026,
      city: 'Doylestown',
      state: 'PA',
    },
    18902: {
      lat: 40.35474,
      lng: -75.09855,
      city: 'Doylestown',
      state: 'PA',
    },
    18912: {
      lat: 40.32134,
      lng: -75.05852,
      city: 'Buckingham',
      state: 'PA',
    },
    18913: {
      lat: 40.37727,
      lng: -75.0545,
      city: 'Carversville',
      state: 'PA',
    },
    18914: {
      lat: 40.28908,
      lng: -75.21046,
      city: 'Chalfont',
      state: 'PA',
    },
    18915: {
      lat: 40.27255,
      lng: -75.25628,
      city: 'Colmar',
      state: 'PA',
    },
    18917: { lat: 40.37088, lng: -75.202, city: 'Dublin', state: 'PA' },
    18920: {
      lat: 40.49264,
      lng: -75.09067,
      city: 'Erwinna',
      state: 'PA',
    },
    18921: {
      lat: 40.52946,
      lng: -75.1738,
      city: 'Ferndale',
      state: 'PA',
    },
    18923: {
      lat: 40.3532,
      lng: -75.16798,
      city: 'Fountainville',
      state: 'PA',
    },
    18925: {
      lat: 40.2851,
      lng: -75.05934,
      city: 'Furlong',
      state: 'PA',
    },
    18927: {
      lat: 40.31952,
      lng: -75.24514,
      city: 'Hilltown',
      state: 'PA',
    },
    18929: {
      lat: 40.25674,
      lng: -75.07885,
      city: 'Jamison',
      state: 'PA',
    },
    18930: {
      lat: 40.52926,
      lng: -75.2142,
      city: 'Kintnersville',
      state: 'PA',
    },
    18932: {
      lat: 40.29457,
      lng: -75.25581,
      city: 'Line Lexington',
      state: 'PA',
    },
    18933: {
      lat: 40.40265,
      lng: -75.042,
      city: 'Lumberville',
      state: 'PA',
    },
    18935: {
      lat: 40.43444,
      lng: -75.39714,
      city: 'Milford Square',
      state: 'PA',
    },
    18936: {
      lat: 40.22993,
      lng: -75.23154,
      city: 'Montgomeryville',
      state: 'PA',
    },
    18938: {
      lat: 40.35126,
      lng: -74.99779,
      city: 'New Hope',
      state: 'PA',
    },
    18940: {
      lat: 40.26349,
      lng: -74.9483,
      city: 'Newtown',
      state: 'PA',
    },
    18942: {
      lat: 40.47167,
      lng: -75.16261,
      city: 'Ottsville',
      state: 'PA',
    },
    18944: {
      lat: 40.39252,
      lng: -75.23381,
      city: 'Perkasie',
      state: 'PA',
    },
    18946: {
      lat: 40.28923,
      lng: -75.01009,
      city: 'Pineville',
      state: 'PA',
    },
    18947: {
      lat: 40.42677,
      lng: -75.1138,
      city: 'Pipersville',
      state: 'PA',
    },
    18950: {
      lat: 40.44709,
      lng: -75.06544,
      city: 'Point Pleasant',
      state: 'PA',
    },
    18951: {
      lat: 40.45294,
      lng: -75.33833,
      city: 'Quakertown',
      state: 'PA',
    },
    18954: {
      lat: 40.2224,
      lng: -75.00032,
      city: 'Richboro',
      state: 'PA',
    },
    18955: {
      lat: 40.48469,
      lng: -75.3141,
      city: 'Richlandtown',
      state: 'PA',
    },
    18960: {
      lat: 40.36376,
      lng: -75.32366,
      city: 'Sellersville',
      state: 'PA',
    },
    18962: {
      lat: 40.34462,
      lng: -75.27033,
      city: 'Silverdale',
      state: 'PA',
    },
    18964: {
      lat: 40.29759,
      lng: -75.33327,
      city: 'Souderton',
      state: 'PA',
    },
    18966: {
      lat: 40.1879,
      lng: -75.00557,
      city: 'Southampton',
      state: 'PA',
    },
    18969: {
      lat: 40.32644,
      lng: -75.37239,
      city: 'Telford',
      state: 'PA',
    },
    18970: {
      lat: 40.41212,
      lng: -75.38129,
      city: 'Trumbauersville',
      state: 'PA',
    },
    18972: {
      lat: 40.53395,
      lng: -75.11997,
      city: 'Upper Black Eddy',
      state: 'PA',
    },
    18974: {
      lat: 40.21675,
      lng: -75.07321,
      city: 'Warminster',
      state: 'PA',
    },
    18976: {
      lat: 40.24676,
      lng: -75.14321,
      city: 'Warrington',
      state: 'PA',
    },
    18977: {
      lat: 40.28655,
      lng: -74.87865,
      city: 'Washington Crossing',
      state: 'PA',
    },
    18980: {
      lat: 40.27276,
      lng: -75.01105,
      city: 'Wycombe',
      state: 'PA',
    },
    19001: {
      lat: 40.12624,
      lng: -75.12652,
      city: 'Abington',
      state: 'PA',
    },
    19002: {
      lat: 40.18386,
      lng: -75.21104,
      city: 'Ambler',
      state: 'PA',
    },
    19003: {
      lat: 40.00217,
      lng: -75.29841,
      city: 'Ardmore',
      state: 'PA',
    },
    19004: {
      lat: 40.01362,
      lng: -75.22979,
      city: 'Bala Cynwyd',
      state: 'PA',
    },
    19006: {
      lat: 40.13323,
      lng: -75.06222,
      city: 'Huntingdon Valley',
      state: 'PA',
    },
    19007: {
      lat: 40.11483,
      lng: -74.85474,
      city: 'Bristol',
      state: 'PA',
    },
    19008: {
      lat: 39.97425,
      lng: -75.36107,
      city: 'Broomall',
      state: 'PA',
    },
    19009: {
      lat: 40.13775,
      lng: -75.06308,
      city: 'Bryn Athyn',
      state: 'PA',
    },
    19010: {
      lat: 40.02362,
      lng: -75.3298,
      city: 'Bryn Mawr',
      state: 'PA',
    },
    19012: {
      lat: 40.05966,
      lng: -75.10586,
      city: 'Cheltenham',
      state: 'PA',
    },
    19013: {
      lat: 39.84529,
      lng: -75.37474,
      city: 'Chester',
      state: 'PA',
    },
    19014: { lat: 39.8637, lng: -75.4338, city: 'Aston', state: 'PA' },
    19015: {
      lat: 39.86811,
      lng: -75.39164,
      city: 'Brookhaven',
      state: 'PA',
    },
    19017: {
      lat: 39.89078,
      lng: -75.46295,
      city: 'Chester Heights',
      state: 'PA',
    },
    19018: {
      lat: 39.92312,
      lng: -75.29834,
      city: 'Clifton Heights',
      state: 'PA',
    },
    19020: {
      lat: 40.10453,
      lng: -74.93853,
      city: 'Bensalem',
      state: 'PA',
    },
    19021: {
      lat: 40.08874,
      lng: -74.89385,
      city: 'Croydon',
      state: 'PA',
    },
    19022: {
      lat: 39.85862,
      lng: -75.33507,
      city: 'Crum Lynne',
      state: 'PA',
    },
    19023: {
      lat: 39.91724,
      lng: -75.26708,
      city: 'Darby',
      state: 'PA',
    },
    19025: {
      lat: 40.14556,
      lng: -75.1632,
      city: 'Dresher',
      state: 'PA',
    },
    19026: {
      lat: 39.95035,
      lng: -75.304,
      city: 'Drexel Hill',
      state: 'PA',
    },
    19027: {
      lat: 40.07347,
      lng: -75.12453,
      city: 'Elkins Park',
      state: 'PA',
    },
    19029: {
      lat: 39.86345,
      lng: -75.29271,
      city: 'Essington',
      state: 'PA',
    },
    19030: {
      lat: 40.1812,
      lng: -74.83269,
      city: 'Fairless Hills',
      state: 'PA',
    },
    19031: {
      lat: 40.1083,
      lng: -75.21747,
      city: 'Flourtown',
      state: 'PA',
    },
    19032: {
      lat: 39.88907,
      lng: -75.277,
      city: 'Folcroft',
      state: 'PA',
    },
    19033: {
      lat: 39.89077,
      lng: -75.32892,
      city: 'Folsom',
      state: 'PA',
    },
    19034: {
      lat: 40.13353,
      lng: -75.20953,
      city: 'Fort Washington',
      state: 'PA',
    },
    19035: {
      lat: 40.04851,
      lng: -75.28126,
      city: 'Gladwyne',
      state: 'PA',
    },
    19036: {
      lat: 39.90368,
      lng: -75.29323,
      city: 'Glenolden',
      state: 'PA',
    },
    19038: {
      lat: 40.10197,
      lng: -75.17214,
      city: 'Glenside',
      state: 'PA',
    },
    19040: {
      lat: 40.17732,
      lng: -75.10624,
      city: 'Hatboro',
      state: 'PA',
    },
    19041: {
      lat: 40.00663,
      lng: -75.31747,
      city: 'Haverford',
      state: 'PA',
    },
    19043: {
      lat: 39.90042,
      lng: -75.30891,
      city: 'Holmes',
      state: 'PA',
    },
    19044: {
      lat: 40.18901,
      lng: -75.15253,
      city: 'Horsham',
      state: 'PA',
    },
    19046: {
      lat: 40.10133,
      lng: -75.10558,
      city: 'Jenkintown',
      state: 'PA',
    },
    19047: {
      lat: 40.18207,
      lng: -74.91062,
      city: 'Langhorne',
      state: 'PA',
    },
    19050: {
      lat: 39.93764,
      lng: -75.26355,
      city: 'Lansdowne',
      state: 'PA',
    },
    19052: {
      lat: 39.89721,
      lng: -75.44532,
      city: 'Lenni',
      state: 'PA',
    },
    19053: {
      lat: 40.15475,
      lng: -74.97918,
      city: 'Feasterville Trevose',
      state: 'PA',
    },
    19054: {
      lat: 40.17055,
      lng: -74.82016,
      city: 'Levittown',
      state: 'PA',
    },
    19055: {
      lat: 40.14984,
      lng: -74.83881,
      city: 'Levittown',
      state: 'PA',
    },
    19056: {
      lat: 40.15044,
      lng: -74.88534,
      city: 'Levittown',
      state: 'PA',
    },
    19057: {
      lat: 40.14013,
      lng: -74.85718,
      city: 'Levittown',
      state: 'PA',
    },
    19060: {
      lat: 39.85048,
      lng: -75.4921,
      city: 'Garnet Valley',
      state: 'PA',
    },
    19061: {
      lat: 39.82741,
      lng: -75.432,
      city: 'Marcus Hook',
      state: 'PA',
    },
    19063: {
      lat: 39.92248,
      lng: -75.41419,
      city: 'Media',
      state: 'PA',
    },
    19064: {
      lat: 39.93214,
      lng: -75.33873,
      city: 'Springfield',
      state: 'PA',
    },
    19066: {
      lat: 40.0022,
      lng: -75.2489,
      city: 'Merion Station',
      state: 'PA',
    },
    19067: {
      lat: 40.19595,
      lng: -74.80976,
      city: 'Morrisville',
      state: 'PA',
    },
    19070: {
      lat: 39.90628,
      lng: -75.32452,
      city: 'Morton',
      state: 'PA',
    },
    19072: {
      lat: 40.02419,
      lng: -75.25697,
      city: 'Narberth',
      state: 'PA',
    },
    19073: {
      lat: 39.98093,
      lng: -75.4346,
      city: 'Newtown Square',
      state: 'PA',
    },
    19074: {
      lat: 39.88642,
      lng: -75.29643,
      city: 'Norwood',
      state: 'PA',
    },
    19075: {
      lat: 40.1136,
      lng: -75.18852,
      city: 'Oreland',
      state: 'PA',
    },
    19076: {
      lat: 39.88586,
      lng: -75.30763,
      city: 'Prospect Park',
      state: 'PA',
    },
    19078: {
      lat: 39.87328,
      lng: -75.32159,
      city: 'Ridley Park',
      state: 'PA',
    },
    19079: {
      lat: 39.90238,
      lng: -75.26591,
      city: 'Sharon Hill',
      state: 'PA',
    },
    19081: {
      lat: 39.89864,
      lng: -75.34751,
      city: 'Swarthmore',
      state: 'PA',
    },
    19082: {
      lat: 39.96043,
      lng: -75.27032,
      city: 'Upper Darby',
      state: 'PA',
    },
    19083: {
      lat: 39.97715,
      lng: -75.31192,
      city: 'Havertown',
      state: 'PA',
    },
    19085: {
      lat: 40.03759,
      lng: -75.34969,
      city: 'Villanova',
      state: 'PA',
    },
    19086: {
      lat: 39.89094,
      lng: -75.37014,
      city: 'Wallingford',
      state: 'PA',
    },
    19087: {
      lat: 40.06172,
      lng: -75.40004,
      city: 'Wayne',
      state: 'PA',
    },
    19090: {
      lat: 40.1484,
      lng: -75.12378,
      city: 'Willow Grove',
      state: 'PA',
    },
    19094: {
      lat: 39.87499,
      lng: -75.3467,
      city: 'Woodlyn',
      state: 'PA',
    },
    19095: {
      lat: 40.08588,
      lng: -75.15137,
      city: 'Wyncote',
      state: 'PA',
    },
    19096: {
      lat: 39.99813,
      lng: -75.27388,
      city: 'Wynnewood',
      state: 'PA',
    },
    19102: {
      lat: 39.95301,
      lng: -75.16544,
      city: 'Philadelphia',
      state: 'PA',
    },
    19103: {
      lat: 39.95303,
      lng: -75.17386,
      city: 'Philadelphia',
      state: 'PA',
    },
    19104: {
      lat: 39.95891,
      lng: -75.19836,
      city: 'Philadelphia',
      state: 'PA',
    },
    19106: {
      lat: 39.94589,
      lng: -75.14224,
      city: 'Philadelphia',
      state: 'PA',
    },
    19107: {
      lat: 39.9518,
      lng: -75.15864,
      city: 'Philadelphia',
      state: 'PA',
    },
    19108: {
      lat: 39.95987,
      lng: -75.16083,
      city: 'Philadelphia',
      state: 'PA',
    },
    19109: {
      lat: 39.94958,
      lng: -75.16387,
      city: 'Philadelphia',
      state: 'PA',
    },
    19111: {
      lat: 40.06065,
      lng: -75.08019,
      city: 'Philadelphia',
      state: 'PA',
    },
    19112: {
      lat: 39.88999,
      lng: -75.16985,
      city: 'Philadelphia',
      state: 'PA',
    },
    19113: {
      lat: 39.86679,
      lng: -75.27911,
      city: 'Philadelphia',
      state: 'PA',
    },
    19114: {
      lat: 40.06743,
      lng: -75.00361,
      city: 'Philadelphia',
      state: 'PA',
    },
    19115: {
      lat: 40.09094,
      lng: -75.04595,
      city: 'Philadelphia',
      state: 'PA',
    },
    19116: {
      lat: 40.11655,
      lng: -75.01399,
      city: 'Philadelphia',
      state: 'PA',
    },
    19118: {
      lat: 40.07233,
      lng: -75.21247,
      city: 'Philadelphia',
      state: 'PA',
    },
    19119: {
      lat: 40.05303,
      lng: -75.19223,
      city: 'Philadelphia',
      state: 'PA',
    },
    19120: {
      lat: 40.03417,
      lng: -75.11986,
      city: 'Philadelphia',
      state: 'PA',
    },
    19121: {
      lat: 39.98231,
      lng: -75.17995,
      city: 'Philadelphia',
      state: 'PA',
    },
    19122: {
      lat: 39.97767,
      lng: -75.14571,
      city: 'Philadelphia',
      state: 'PA',
    },
    19123: {
      lat: 39.96399,
      lng: -75.14568,
      city: 'Philadelphia',
      state: 'PA',
    },
    19124: {
      lat: 40.01728,
      lng: -75.09387,
      city: 'Philadelphia',
      state: 'PA',
    },
    19125: {
      lat: 39.97611,
      lng: -75.12472,
      city: 'Philadelphia',
      state: 'PA',
    },
    19126: {
      lat: 40.05516,
      lng: -75.13728,
      city: 'Philadelphia',
      state: 'PA',
    },
    19127: {
      lat: 40.02854,
      lng: -75.22746,
      city: 'Philadelphia',
      state: 'PA',
    },
    19128: {
      lat: 40.05003,
      lng: -75.23003,
      city: 'Philadelphia',
      state: 'PA',
    },
    19129: {
      lat: 40.01338,
      lng: -75.18454,
      city: 'Philadelphia',
      state: 'PA',
    },
    19130: {
      lat: 39.9676,
      lng: -75.17495,
      city: 'Philadelphia',
      state: 'PA',
    },
    19131: {
      lat: 39.98865,
      lng: -75.21835,
      city: 'Philadelphia',
      state: 'PA',
    },
    19132: {
      lat: 39.99649,
      lng: -75.17102,
      city: 'Philadelphia',
      state: 'PA',
    },
    19133: {
      lat: 39.99238,
      lng: -75.14149,
      city: 'Philadelphia',
      state: 'PA',
    },
    19134: {
      lat: 39.98953,
      lng: -75.10838,
      city: 'Philadelphia',
      state: 'PA',
    },
    19135: {
      lat: 40.02192,
      lng: -75.04966,
      city: 'Philadelphia',
      state: 'PA',
    },
    19136: {
      lat: 40.03973,
      lng: -75.01932,
      city: 'Philadelphia',
      state: 'PA',
    },
    19137: {
      lat: 39.99455,
      lng: -75.074,
      city: 'Philadelphia',
      state: 'PA',
    },
    19138: {
      lat: 40.05594,
      lng: -75.15947,
      city: 'Philadelphia',
      state: 'PA',
    },
    19139: {
      lat: 39.96144,
      lng: -75.22964,
      city: 'Philadelphia',
      state: 'PA',
    },
    19140: {
      lat: 40.01224,
      lng: -75.14512,
      city: 'Philadelphia',
      state: 'PA',
    },
    19141: {
      lat: 40.03788,
      lng: -75.14541,
      city: 'Philadelphia',
      state: 'PA',
    },
    19142: {
      lat: 39.92278,
      lng: -75.23375,
      city: 'Philadelphia',
      state: 'PA',
    },
    19143: {
      lat: 39.94315,
      lng: -75.22664,
      city: 'Philadelphia',
      state: 'PA',
    },
    19144: {
      lat: 40.03278,
      lng: -75.17523,
      city: 'Philadelphia',
      state: 'PA',
    },
    19145: {
      lat: 39.91469,
      lng: -75.19194,
      city: 'Philadelphia',
      state: 'PA',
    },
    19146: {
      lat: 39.93917,
      lng: -75.18398,
      city: 'Philadelphia',
      state: 'PA',
    },
    19147: {
      lat: 39.93645,
      lng: -75.15468,
      city: 'Philadelphia',
      state: 'PA',
    },
    19148: {
      lat: 39.91175,
      lng: -75.1514,
      city: 'Philadelphia',
      state: 'PA',
    },
    19149: {
      lat: 40.03781,
      lng: -75.06544,
      city: 'Philadelphia',
      state: 'PA',
    },
    19150: {
      lat: 40.07251,
      lng: -75.17165,
      city: 'Philadelphia',
      state: 'PA',
    },
    19151: {
      lat: 39.97562,
      lng: -75.25616,
      city: 'Philadelphia',
      state: 'PA',
    },
    19152: {
      lat: 40.06158,
      lng: -75.04663,
      city: 'Philadelphia',
      state: 'PA',
    },
    19153: {
      lat: 39.88391,
      lng: -75.2343,
      city: 'Philadelphia',
      state: 'PA',
    },
    19154: {
      lat: 40.0951,
      lng: -74.98159,
      city: 'Philadelphia',
      state: 'PA',
    },
    19301: { lat: 40.0414, lng: -75.4809, city: 'Paoli', state: 'PA' },
    19310: {
      lat: 39.93478,
      lng: -75.97038,
      city: 'Atglen',
      state: 'PA',
    },
    19311: {
      lat: 39.82163,
      lng: -75.76887,
      city: 'Avondale',
      state: 'PA',
    },
    19312: {
      lat: 40.0309,
      lng: -75.45083,
      city: 'Berwyn',
      state: 'PA',
    },
    19316: {
      lat: 40.05597,
      lng: -75.83804,
      city: 'Brandamore',
      state: 'PA',
    },
    19317: {
      lat: 39.85722,
      lng: -75.60244,
      city: 'Chadds Ford',
      state: 'PA',
    },
    19319: {
      lat: 39.92723,
      lng: -75.52319,
      city: 'Cheyney',
      state: 'PA',
    },
    19320: {
      lat: 39.97046,
      lng: -75.83069,
      city: 'Coatesville',
      state: 'PA',
    },
    19330: {
      lat: 39.87359,
      lng: -75.91695,
      city: 'Cochranville',
      state: 'PA',
    },
    19333: {
      lat: 40.04208,
      lng: -75.42331,
      city: 'Devon',
      state: 'PA',
    },
    19335: {
      lat: 40.02271,
      lng: -75.72045,
      city: 'Downingtown',
      state: 'PA',
    },
    19341: {
      lat: 40.04213,
      lng: -75.63814,
      city: 'Exton',
      state: 'PA',
    },
    19342: {
      lat: 39.90458,
      lng: -75.50243,
      city: 'Glen Mills',
      state: 'PA',
    },
    19343: {
      lat: 40.10203,
      lng: -75.75461,
      city: 'Glenmoore',
      state: 'PA',
    },
    19344: {
      lat: 40.08279,
      lng: -75.88338,
      city: 'Honey Brook',
      state: 'PA',
    },
    19345: {
      lat: 40.02967,
      lng: -75.56955,
      city: 'Immaculata',
      state: 'PA',
    },
    19348: {
      lat: 39.86706,
      lng: -75.71763,
      city: 'Kennett Square',
      state: 'PA',
    },
    19350: {
      lat: 39.76244,
      lng: -75.79528,
      city: 'Landenberg',
      state: 'PA',
    },
    19352: {
      lat: 39.78036,
      lng: -75.89054,
      city: 'Lincoln University',
      state: 'PA',
    },
    19355: {
      lat: 40.0466,
      lng: -75.5302,
      city: 'Malvern',
      state: 'PA',
    },
    19358: {
      lat: 39.96362,
      lng: -75.80659,
      city: 'Modena',
      state: 'PA',
    },
    19362: {
      lat: 39.75175,
      lng: -76.06576,
      city: 'Nottingham',
      state: 'PA',
    },
    19363: {
      lat: 39.7864,
      lng: -75.97279,
      city: 'Oxford',
      state: 'PA',
    },
    19365: {
      lat: 39.9655,
      lng: -75.92766,
      city: 'Parkesburg',
      state: 'PA',
    },
    19367: {
      lat: 39.96285,
      lng: -75.88395,
      city: 'Pomeroy',
      state: 'PA',
    },
    19369: {
      lat: 39.98082,
      lng: -75.89496,
      city: 'Sadsburyville',
      state: 'PA',
    },
    19372: {
      lat: 39.99941,
      lng: -75.75972,
      city: 'Thorndale',
      state: 'PA',
    },
    19373: {
      lat: 39.90674,
      lng: -75.53255,
      city: 'Thornton',
      state: 'PA',
    },
    19374: {
      lat: 39.83333,
      lng: -75.75267,
      city: 'Toughkenamon',
      state: 'PA',
    },
    19375: {
      lat: 39.8996,
      lng: -75.73993,
      city: 'Unionville',
      state: 'PA',
    },
    19380: {
      lat: 39.98594,
      lng: -75.60358,
      city: 'West Chester',
      state: 'PA',
    },
    19382: {
      lat: 39.92766,
      lng: -75.61475,
      city: 'West Chester',
      state: 'PA',
    },
    19383: {
      lat: 39.94303,
      lng: -75.60155,
      city: 'West Chester',
      state: 'PA',
    },
    19390: {
      lat: 39.83683,
      lng: -75.83962,
      city: 'West Grove',
      state: 'PA',
    },
    19401: {
      lat: 40.13042,
      lng: -75.33143,
      city: 'Norristown',
      state: 'PA',
    },
    19403: {
      lat: 40.14898,
      lng: -75.38034,
      city: 'Norristown',
      state: 'PA',
    },
    19405: {
      lat: 40.09949,
      lng: -75.33497,
      city: 'Bridgeport',
      state: 'PA',
    },
    19406: {
      lat: 40.09467,
      lng: -75.38402,
      city: 'King Of Prussia',
      state: 'PA',
    },
    19422: {
      lat: 40.15835,
      lng: -75.27858,
      city: 'Blue Bell',
      state: 'PA',
    },
    19425: {
      lat: 40.10383,
      lng: -75.64476,
      city: 'Chester Springs',
      state: 'PA',
    },
    19426: {
      lat: 40.18972,
      lng: -75.43734,
      city: 'Collegeville',
      state: 'PA',
    },
    19428: {
      lat: 40.08011,
      lng: -75.30317,
      city: 'Conshohocken',
      state: 'PA',
    },
    19435: {
      lat: 40.32788,
      lng: -75.56518,
      city: 'Frederick',
      state: 'PA',
    },
    19436: {
      lat: 40.20253,
      lng: -75.24842,
      city: 'Gwynedd',
      state: 'PA',
    },
    19437: {
      lat: 40.18706,
      lng: -75.25274,
      city: 'Gwynedd Valley',
      state: 'PA',
    },
    19438: {
      lat: 40.27018,
      lng: -75.39554,
      city: 'Harleysville',
      state: 'PA',
    },
    19440: {
      lat: 40.285,
      lng: -75.29102,
      city: 'Hatfield',
      state: 'PA',
    },
    19442: {
      lat: 40.13233,
      lng: -75.57863,
      city: 'Kimberton',
      state: 'PA',
    },
    19444: {
      lat: 40.08686,
      lng: -75.25427,
      city: 'Lafayette Hill',
      state: 'PA',
    },
    19446: {
      lat: 40.23068,
      lng: -75.30369,
      city: 'Lansdale',
      state: 'PA',
    },
    19453: {
      lat: 40.13886,
      lng: -75.50141,
      city: 'Mont Clare',
      state: 'PA',
    },
    19454: {
      lat: 40.22303,
      lng: -75.24365,
      city: 'North Wales',
      state: 'PA',
    },
    19456: { lat: 40.1367, lng: -75.45962, city: 'Oaks', state: 'PA' },
    19457: {
      lat: 40.20512,
      lng: -75.58758,
      city: 'Parker Ford',
      state: 'PA',
    },
    19460: {
      lat: 40.12674,
      lng: -75.5294,
      city: 'Phoenixville',
      state: 'PA',
    },
    19462: {
      lat: 40.11542,
      lng: -75.28453,
      city: 'Plymouth Meeting',
      state: 'PA',
    },
    19464: {
      lat: 40.25882,
      lng: -75.616,
      city: 'Pottstown',
      state: 'PA',
    },
    19465: {
      lat: 40.19981,
      lng: -75.6727,
      city: 'Pottstown',
      state: 'PA',
    },
    19468: {
      lat: 40.20776,
      lng: -75.53137,
      city: 'Royersford',
      state: 'PA',
    },
    19472: {
      lat: 40.34036,
      lng: -75.58189,
      city: 'Sassamansville',
      state: 'PA',
    },
    19473: {
      lat: 40.25842,
      lng: -75.47363,
      city: 'Schwenksville',
      state: 'PA',
    },
    19474: {
      lat: 40.22427,
      lng: -75.40507,
      city: 'Skippack',
      state: 'PA',
    },
    19475: {
      lat: 40.17028,
      lng: -75.60042,
      city: 'Spring City',
      state: 'PA',
    },
    19477: {
      lat: 40.18447,
      lng: -75.23583,
      city: 'Spring House',
      state: 'PA',
    },
    19490: {
      lat: 40.19673,
      lng: -75.35401,
      city: 'Worcester',
      state: 'PA',
    },
    19492: {
      lat: 40.28817,
      lng: -75.50623,
      city: 'Zieglerville',
      state: 'PA',
    },
    19501: {
      lat: 40.24314,
      lng: -76.05822,
      city: 'Adamstown',
      state: 'PA',
    },
    19503: { lat: 40.40808, lng: -75.5755, city: 'Bally', state: 'PA' },
    19504: {
      lat: 40.40699,
      lng: -75.58965,
      city: 'Barto',
      state: 'PA',
    },
    19505: {
      lat: 40.3795,
      lng: -75.62331,
      city: 'Bechtelsville',
      state: 'PA',
    },
    19506: {
      lat: 40.45873,
      lng: -76.12379,
      city: 'Bernville',
      state: 'PA',
    },
    19507: {
      lat: 40.49268,
      lng: -76.28235,
      city: 'Bethel',
      state: 'PA',
    },
    19508: {
      lat: 40.25913,
      lng: -75.83984,
      city: 'Birdsboro',
      state: 'PA',
    },
    19510: {
      lat: 40.44551,
      lng: -75.88179,
      city: 'Blandon',
      state: 'PA',
    },
    19511: {
      lat: 40.48473,
      lng: -75.74381,
      city: 'Bowers',
      state: 'PA',
    },
    19512: {
      lat: 40.35,
      lng: -75.67937,
      city: 'Boyertown',
      state: 'PA',
    },
    19516: {
      lat: 40.48686,
      lng: -76.00526,
      city: 'Centerport',
      state: 'PA',
    },
    19518: {
      lat: 40.27187,
      lng: -75.74981,
      city: 'Douglassville',
      state: 'PA',
    },
    19520: {
      lat: 40.15968,
      lng: -75.79959,
      city: 'Elverson',
      state: 'PA',
    },
    19522: {
      lat: 40.44948,
      lng: -75.81676,
      city: 'Fleetwood',
      state: 'PA',
    },
    19523: {
      lat: 40.20116,
      lng: -75.84974,
      city: 'Geigertown',
      state: 'PA',
    },
    19525: {
      lat: 40.30784,
      lng: -75.58591,
      city: 'Gilbertsville',
      state: 'PA',
    },
    19526: {
      lat: 40.5465,
      lng: -75.99802,
      city: 'Hamburg',
      state: 'PA',
    },
    19529: {
      lat: 40.63387,
      lng: -75.86158,
      city: 'Kempton',
      state: 'PA',
    },
    19530: {
      lat: 40.54012,
      lng: -75.77675,
      city: 'Kutztown',
      state: 'PA',
    },
    19533: {
      lat: 40.42245,
      lng: -75.99021,
      city: 'Leesport',
      state: 'PA',
    },
    19534: {
      lat: 40.57813,
      lng: -75.88111,
      city: 'Lenhartsville',
      state: 'PA',
    },
    19535: {
      lat: 40.3394,
      lng: -75.79537,
      city: 'Limekiln',
      state: 'PA',
    },
    19536: {
      lat: 40.48069,
      lng: -75.75891,
      city: 'Lyon Station',
      state: 'PA',
    },
    19538: {
      lat: 40.54655,
      lng: -75.70023,
      city: 'Maxatawny',
      state: 'PA',
    },
    19539: {
      lat: 40.49071,
      lng: -75.68823,
      city: 'Mertztown',
      state: 'PA',
    },
    19540: {
      lat: 40.23714,
      lng: -75.9658,
      city: 'Mohnton',
      state: 'PA',
    },
    19541: {
      lat: 40.47821,
      lng: -76.02597,
      city: 'Mohrsville',
      state: 'PA',
    },
    19543: {
      lat: 40.1697,
      lng: -75.89635,
      city: 'Morgantown',
      state: 'PA',
    },
    19544: {
      lat: 40.41669,
      lng: -76.29619,
      city: 'Mount Aetna',
      state: 'PA',
    },
    19545: {
      lat: 40.3411,
      lng: -75.62977,
      city: 'New Berlinville',
      state: 'PA',
    },
    19547: { lat: 40.38315, lng: -75.76644, city: 'Oley', state: 'PA' },
    19549: {
      lat: 40.58176,
      lng: -76.02672,
      city: 'Port Clinton',
      state: 'PA',
    },
    19550: {
      lat: 40.45593,
      lng: -76.25055,
      city: 'Rehrersburg',
      state: 'PA',
    },
    19551: {
      lat: 40.35951,
      lng: -76.14213,
      city: 'Robesonia',
      state: 'PA',
    },
    19554: {
      lat: 40.51288,
      lng: -76.10377,
      city: 'Shartlesville',
      state: 'PA',
    },
    19555: {
      lat: 40.49335,
      lng: -75.94993,
      city: 'Shoemakersville',
      state: 'PA',
    },
    19559: {
      lat: 40.49234,
      lng: -76.18661,
      city: 'Strausstown',
      state: 'PA',
    },
    19560: {
      lat: 40.40053,
      lng: -75.88993,
      city: 'Temple',
      state: 'PA',
    },
    19562: {
      lat: 40.50316,
      lng: -75.70135,
      city: 'Topton',
      state: 'PA',
    },
    19564: {
      lat: 40.51926,
      lng: -75.87403,
      city: 'Virginville',
      state: 'PA',
    },
    19565: {
      lat: 40.34231,
      lng: -76.08873,
      city: 'Wernersville',
      state: 'PA',
    },
    19567: {
      lat: 40.3957,
      lng: -76.20989,
      city: 'Womelsdorf',
      state: 'PA',
    },
    19601: {
      lat: 40.3526,
      lng: -75.9408,
      city: 'Reading',
      state: 'PA',
    },
    19602: {
      lat: 40.32695,
      lng: -75.91701,
      city: 'Reading',
      state: 'PA',
    },
    19604: {
      lat: 40.35791,
      lng: -75.90954,
      city: 'Reading',
      state: 'PA',
    },
    19605: {
      lat: 40.39917,
      lng: -75.94596,
      city: 'Reading',
      state: 'PA',
    },
    19606: {
      lat: 40.3357,
      lng: -75.85755,
      city: 'Reading',
      state: 'PA',
    },
    19607: {
      lat: 40.29147,
      lng: -75.94521,
      city: 'Reading',
      state: 'PA',
    },
    19608: {
      lat: 40.31401,
      lng: -76.03574,
      city: 'Reading',
      state: 'PA',
    },
    19609: {
      lat: 40.32774,
      lng: -75.99622,
      city: 'Reading',
      state: 'PA',
    },
    19610: {
      lat: 40.34015,
      lng: -75.97612,
      city: 'Reading',
      state: 'PA',
    },
    19611: {
      lat: 40.32473,
      lng: -75.9411,
      city: 'Reading',
      state: 'PA',
    },
    19701: { lat: 39.58427, lng: -75.69914, city: 'Bear', state: 'DE' },
    19702: {
      lat: 39.62199,
      lng: -75.72803,
      city: 'Newark',
      state: 'DE',
    },
    19703: {
      lat: 39.8043,
      lng: -75.45794,
      city: 'Claymont',
      state: 'DE',
    },
    19706: {
      lat: 39.5715,
      lng: -75.5905,
      city: 'Delaware City',
      state: 'DE',
    },
    19707: {
      lat: 39.78512,
      lng: -75.68147,
      city: 'Hockessin',
      state: 'DE',
    },
    19709: {
      lat: 39.48923,
      lng: -75.68314,
      city: 'Middletown',
      state: 'DE',
    },
    19710: {
      lat: 39.78964,
      lng: -75.58458,
      city: 'Montchanin',
      state: 'DE',
    },
    19711: {
      lat: 39.71379,
      lng: -75.74033,
      city: 'Newark',
      state: 'DE',
    },
    19713: {
      lat: 39.67097,
      lng: -75.70972,
      city: 'Newark',
      state: 'DE',
    },
    19716: {
      lat: 39.66779,
      lng: -75.74595,
      city: 'Newark',
      state: 'DE',
    },
    19717: {
      lat: 39.68121,
      lng: -75.75343,
      city: 'Newark',
      state: 'DE',
    },
    19720: {
      lat: 39.645,
      lng: -75.60678,
      city: 'New Castle',
      state: 'DE',
    },
    19730: {
      lat: 39.45443,
      lng: -75.66077,
      city: 'Odessa',
      state: 'DE',
    },
    19731: {
      lat: 39.5102,
      lng: -75.59616,
      city: 'Port Penn',
      state: 'DE',
    },
    19732: {
      lat: 39.80033,
      lng: -75.57455,
      city: 'Rockland',
      state: 'DE',
    },
    19733: {
      lat: 39.55928,
      lng: -75.65143,
      city: 'Saint Georges',
      state: 'DE',
    },
    19734: {
      lat: 39.38377,
      lng: -75.65026,
      city: 'Townsend',
      state: 'DE',
    },
    19735: {
      lat: 39.80666,
      lng: -75.5973,
      city: 'Winterthur',
      state: 'DE',
    },
    19736: {
      lat: 39.78031,
      lng: -75.64274,
      city: 'Yorklyn',
      state: 'DE',
    },
    19801: {
      lat: 39.72782,
      lng: -75.54615,
      city: 'Wilmington',
      state: 'DE',
    },
    19802: {
      lat: 39.75715,
      lng: -75.52873,
      city: 'Wilmington',
      state: 'DE',
    },
    19803: {
      lat: 39.79912,
      lng: -75.5422,
      city: 'Wilmington',
      state: 'DE',
    },
    19804: {
      lat: 39.7166,
      lng: -75.62094,
      city: 'Wilmington',
      state: 'DE',
    },
    19805: {
      lat: 39.74523,
      lng: -75.59373,
      city: 'Wilmington',
      state: 'DE',
    },
    19806: {
      lat: 39.762,
      lng: -75.56336,
      city: 'Wilmington',
      state: 'DE',
    },
    19807: {
      lat: 39.79767,
      lng: -75.61284,
      city: 'Wilmington',
      state: 'DE',
    },
    19808: {
      lat: 39.73777,
      lng: -75.66596,
      city: 'Wilmington',
      state: 'DE',
    },
    19809: {
      lat: 39.75801,
      lng: -75.50218,
      city: 'Wilmington',
      state: 'DE',
    },
    19810: {
      lat: 39.81855,
      lng: -75.50628,
      city: 'Wilmington',
      state: 'DE',
    },
    19901: {
      lat: 39.17335,
      lng: -75.47758,
      city: 'Dover',
      state: 'DE',
    },
    19902: {
      lat: 39.12765,
      lng: -75.46694,
      city: 'Dover Afb',
      state: 'DE',
    },
    19904: {
      lat: 39.17289,
      lng: -75.59517,
      city: 'Dover',
      state: 'DE',
    },
    19930: {
      lat: 38.56311,
      lng: -75.07085,
      city: 'Bethany Beach',
      state: 'DE',
    },
    19931: {
      lat: 38.56928,
      lng: -75.61029,
      city: 'Bethel',
      state: 'DE',
    },
    19933: {
      lat: 38.73699,
      lng: -75.60586,
      city: 'Bridgeville',
      state: 'DE',
    },
    19934: {
      lat: 39.08135,
      lng: -75.63113,
      city: 'Camden Wyoming',
      state: 'DE',
    },
    19936: {
      lat: 39.21846,
      lng: -75.58498,
      city: 'Cheswold',
      state: 'DE',
    },
    19938: {
      lat: 39.25646,
      lng: -75.69884,
      city: 'Clayton',
      state: 'DE',
    },
    19939: {
      lat: 38.56575,
      lng: -75.2022,
      city: 'Dagsboro',
      state: 'DE',
    },
    19940: {
      lat: 38.47723,
      lng: -75.56828,
      city: 'Delmar',
      state: 'DE',
    },
    19941: {
      lat: 38.7949,
      lng: -75.42476,
      city: 'Ellendale',
      state: 'DE',
    },
    19943: {
      lat: 39.00562,
      lng: -75.60756,
      city: 'Felton',
      state: 'DE',
    },
    19944: {
      lat: 38.47542,
      lng: -75.05559,
      city: 'Fenwick Island',
      state: 'DE',
    },
    19945: {
      lat: 38.50336,
      lng: -75.22855,
      city: 'Frankford',
      state: 'DE',
    },
    19946: {
      lat: 39.03224,
      lng: -75.45297,
      city: 'Frederica',
      state: 'DE',
    },
    19947: {
      lat: 38.67421,
      lng: -75.39864,
      city: 'Georgetown',
      state: 'DE',
    },
    19950: {
      lat: 38.82007,
      lng: -75.59691,
      city: 'Greenwood',
      state: 'DE',
    },
    19951: {
      lat: 38.68763,
      lng: -75.25124,
      city: 'Harbeson',
      state: 'DE',
    },
    19952: {
      lat: 38.91654,
      lng: -75.62531,
      city: 'Harrington',
      state: 'DE',
    },
    19953: {
      lat: 39.15145,
      lng: -75.69695,
      city: 'Hartly',
      state: 'DE',
    },
    19954: {
      lat: 38.89891,
      lng: -75.52092,
      city: 'Houston',
      state: 'DE',
    },
    19955: {
      lat: 39.22652,
      lng: -75.6654,
      city: 'Kenton',
      state: 'DE',
    },
    19956: {
      lat: 38.5465,
      lng: -75.53961,
      city: 'Laurel',
      state: 'DE',
    },
    19958: {
      lat: 38.72737,
      lng: -75.16632,
      city: 'Lewes',
      state: 'DE',
    },
    19960: {
      lat: 38.85082,
      lng: -75.41363,
      city: 'Lincoln',
      state: 'DE',
    },
    19962: {
      lat: 39.07005,
      lng: -75.49753,
      city: 'Magnolia',
      state: 'DE',
    },
    19963: {
      lat: 38.93347,
      lng: -75.37799,
      city: 'Milford',
      state: 'DE',
    },
    19964: {
      lat: 39.09436,
      lng: -75.72439,
      city: 'Marydel',
      state: 'DE',
    },
    19966: {
      lat: 38.58118,
      lng: -75.28274,
      city: 'Millsboro',
      state: 'DE',
    },
    19967: {
      lat: 38.53978,
      lng: -75.1209,
      city: 'Millville',
      state: 'DE',
    },
    19968: {
      lat: 38.77553,
      lng: -75.28466,
      city: 'Milton',
      state: 'DE',
    },
    19970: {
      lat: 38.55504,
      lng: -75.09901,
      city: 'Ocean View',
      state: 'DE',
    },
    19971: {
      lat: 38.69113,
      lng: -75.10132,
      city: 'Rehoboth Beach',
      state: 'DE',
    },
    19973: {
      lat: 38.63404,
      lng: -75.62454,
      city: 'Seaford',
      state: 'DE',
    },
    19975: {
      lat: 38.46862,
      lng: -75.17462,
      city: 'Selbyville',
      state: 'DE',
    },
    19977: {
      lat: 39.29653,
      lng: -75.55132,
      city: 'Smyrna',
      state: 'DE',
    },
    19979: {
      lat: 39.05041,
      lng: -75.57642,
      city: 'Viola',
      state: 'DE',
    },
    19980: {
      lat: 39.07349,
      lng: -75.57008,
      city: 'Woodside',
      state: 'DE',
    },
    20001: {
      lat: 38.91002,
      lng: -77.018,
      city: 'Washington',
      state: 'DC',
    },
    20002: {
      lat: 38.90511,
      lng: -76.98431,
      city: 'Washington',
      state: 'DC',
    },
    20003: {
      lat: 38.88193,
      lng: -76.99033,
      city: 'Washington',
      state: 'DC',
    },
    20004: {
      lat: 38.89504,
      lng: -77.02661,
      city: 'Washington',
      state: 'DC',
    },
    20005: {
      lat: 38.90443,
      lng: -77.0316,
      city: 'Washington',
      state: 'DC',
    },
    20006: {
      lat: 38.89847,
      lng: -77.04119,
      city: 'Washington',
      state: 'DC',
    },
    20007: {
      lat: 38.91259,
      lng: -77.07756,
      city: 'Washington',
      state: 'DC',
    },
    20008: {
      lat: 38.93539,
      lng: -77.05935,
      city: 'Washington',
      state: 'DC',
    },
    20009: {
      lat: 38.91986,
      lng: -77.03749,
      city: 'Washington',
      state: 'DC',
    },
    20010: {
      lat: 38.93255,
      lng: -77.03128,
      city: 'Washington',
      state: 'DC',
    },
    20011: {
      lat: 38.95295,
      lng: -77.02323,
      city: 'Washington',
      state: 'DC',
    },
    20012: {
      lat: 38.97863,
      lng: -77.03076,
      city: 'Washington',
      state: 'DC',
    },
    20015: {
      lat: 38.96661,
      lng: -77.05837,
      city: 'Washington',
      state: 'DC',
    },
    20016: {
      lat: 38.93822,
      lng: -77.09139,
      city: 'Washington',
      state: 'DC',
    },
    20017: {
      lat: 38.93813,
      lng: -76.99294,
      city: 'Washington',
      state: 'DC',
    },
    20018: {
      lat: 38.92596,
      lng: -76.97281,
      city: 'Washington',
      state: 'DC',
    },
    20019: {
      lat: 38.89214,
      lng: -76.94195,
      city: 'Washington',
      state: 'DC',
    },
    20020: {
      lat: 38.86234,
      lng: -76.97446,
      city: 'Washington',
      state: 'DC',
    },
    20024: {
      lat: 38.87517,
      lng: -77.0295,
      city: 'Washington',
      state: 'DC',
    },
    20032: {
      lat: 38.83142,
      lng: -77.01514,
      city: 'Washington',
      state: 'DC',
    },
    20036: {
      lat: 38.90705,
      lng: -77.04155,
      city: 'Washington',
      state: 'DC',
    },
    20037: {
      lat: 38.89213,
      lng: -77.0535,
      city: 'Washington',
      state: 'DC',
    },
    20045: {
      lat: 38.89673,
      lng: -77.0308,
      city: 'Washington',
      state: 'DC',
    },
    20052: {
      lat: 38.89884,
      lng: -77.04779,
      city: 'Washington',
      state: 'DC',
    },
    20057: {
      lat: 38.90892,
      lng: -77.07511,
      city: 'Washington',
      state: 'DC',
    },
    20059: {
      lat: 38.92247,
      lng: -77.02003,
      city: 'Washington',
      state: 'DC',
    },
    20064: {
      lat: 38.93637,
      lng: -76.99924,
      city: 'Washington',
      state: 'DC',
    },
    20105: {
      lat: 38.95949,
      lng: -77.60829,
      city: 'Aldie',
      state: 'VA',
    },
    20106: {
      lat: 38.6871,
      lng: -78.0133,
      city: 'Amissville',
      state: 'VA',
    },
    20109: {
      lat: 38.79472,
      lng: -77.53105,
      city: 'Manassas',
      state: 'VA',
    },
    20110: {
      lat: 38.7464,
      lng: -77.48699,
      city: 'Manassas',
      state: 'VA',
    },
    20111: {
      lat: 38.74907,
      lng: -77.43039,
      city: 'Manassas',
      state: 'VA',
    },
    20112: {
      lat: 38.67274,
      lng: -77.42901,
      city: 'Manassas',
      state: 'VA',
    },
    20115: {
      lat: 38.82138,
      lng: -77.89764,
      city: 'Marshall',
      state: 'VA',
    },
    20117: {
      lat: 38.99473,
      lng: -77.73851,
      city: 'Middleburg',
      state: 'VA',
    },
    20119: {
      lat: 38.62159,
      lng: -77.62819,
      city: 'Catlett',
      state: 'VA',
    },
    20120: {
      lat: 38.85576,
      lng: -77.47987,
      city: 'Centreville',
      state: 'VA',
    },
    20121: {
      lat: 38.8175,
      lng: -77.46015,
      city: 'Centreville',
      state: 'VA',
    },
    20124: {
      lat: 38.78059,
      lng: -77.39085,
      city: 'Clifton',
      state: 'VA',
    },
    20129: {
      lat: 39.16673,
      lng: -77.59404,
      city: 'Paeonian Springs',
      state: 'VA',
    },
    20130: {
      lat: 39.02756,
      lng: -77.94243,
      city: 'Paris',
      state: 'VA',
    },
    20132: {
      lat: 39.16648,
      lng: -77.72748,
      city: 'Purcellville',
      state: 'VA',
    },
    20135: {
      lat: 39.08602,
      lng: -77.87659,
      city: 'Bluemont',
      state: 'VA',
    },
    20136: {
      lat: 38.7374,
      lng: -77.55473,
      city: 'Bristow',
      state: 'VA',
    },
    20137: {
      lat: 38.80682,
      lng: -77.72992,
      city: 'Broad Run',
      state: 'VA',
    },
    20141: {
      lat: 39.11675,
      lng: -77.79159,
      city: 'Round Hill',
      state: 'VA',
    },
    20143: {
      lat: 38.86421,
      lng: -77.56792,
      city: 'Catharpin',
      state: 'VA',
    },
    20144: {
      lat: 38.92117,
      lng: -77.93759,
      city: 'Delaplane',
      state: 'VA',
    },
    20147: {
      lat: 39.04151,
      lng: -77.47958,
      city: 'Ashburn',
      state: 'VA',
    },
    20148: {
      lat: 38.99062,
      lng: -77.53284,
      city: 'Ashburn',
      state: 'VA',
    },
    20151: {
      lat: 38.89583,
      lng: -77.44453,
      city: 'Chantilly',
      state: 'VA',
    },
    20152: {
      lat: 38.8998,
      lng: -77.51917,
      city: 'Chantilly',
      state: 'VA',
    },
    20155: {
      lat: 38.80646,
      lng: -77.62145,
      city: 'Gainesville',
      state: 'VA',
    },
    20158: {
      lat: 39.14126,
      lng: -77.65514,
      city: 'Hamilton',
      state: 'VA',
    },
    20164: {
      lat: 39.01244,
      lng: -77.39586,
      city: 'Sterling',
      state: 'VA',
    },
    20165: {
      lat: 39.05058,
      lng: -77.38854,
      city: 'Sterling',
      state: 'VA',
    },
    20166: {
      lat: 38.97146,
      lng: -77.4637,
      city: 'Sterling',
      state: 'VA',
    },
    20169: {
      lat: 38.87172,
      lng: -77.64627,
      city: 'Haymarket',
      state: 'VA',
    },
    20170: {
      lat: 38.98075,
      lng: -77.38042,
      city: 'Herndon',
      state: 'VA',
    },
    20171: {
      lat: 38.9246,
      lng: -77.39824,
      city: 'Herndon',
      state: 'VA',
    },
    20175: {
      lat: 39.0641,
      lng: -77.59939,
      city: 'Leesburg',
      state: 'VA',
    },
    20176: {
      lat: 39.18147,
      lng: -77.5345,
      city: 'Leesburg',
      state: 'VA',
    },
    20180: {
      lat: 39.26775,
      lng: -77.63804,
      city: 'Lovettsville',
      state: 'VA',
    },
    20181: {
      lat: 38.66687,
      lng: -77.55374,
      city: 'Nokesville',
      state: 'VA',
    },
    20184: {
      lat: 39.00143,
      lng: -77.87874,
      city: 'Upperville',
      state: 'VA',
    },
    20186: {
      lat: 38.69045,
      lng: -77.843,
      city: 'Warrenton',
      state: 'VA',
    },
    20187: {
      lat: 38.71846,
      lng: -77.74275,
      city: 'Warrenton',
      state: 'VA',
    },
    20190: {
      lat: 38.95968,
      lng: -77.33806,
      city: 'Reston',
      state: 'VA',
    },
    20191: {
      lat: 38.93438,
      lng: -77.35184,
      city: 'Reston',
      state: 'VA',
    },
    20194: {
      lat: 38.97998,
      lng: -77.34354,
      city: 'Reston',
      state: 'VA',
    },
    20197: {
      lat: 39.19379,
      lng: -77.62252,
      city: 'Waterford',
      state: 'VA',
    },
    20198: {
      lat: 38.87523,
      lng: -77.76128,
      city: 'The Plains',
      state: 'VA',
    },
    20204: {
      lat: 38.88556,
      lng: -77.01443,
      city: 'Washington',
      state: 'DC',
    },
    20220: {
      lat: 38.89713,
      lng: -77.03436,
      city: 'Washington',
      state: 'DC',
    },
    20230: {
      lat: 38.89373,
      lng: -77.03567,
      city: 'Washington',
      state: 'DC',
    },
    20240: {
      lat: 38.89446,
      lng: -77.04261,
      city: 'Washington',
      state: 'DC',
    },
    20245: {
      lat: 38.89358,
      lng: -77.04503,
      city: 'Washington',
      state: 'DC',
    },
    20250: {
      lat: 38.88556,
      lng: -77.03276,
      city: 'Washington',
      state: 'DC',
    },
    20260: {
      lat: 38.88355,
      lng: -77.02504,
      city: 'Washington',
      state: 'DC',
    },
    20317: {
      lat: 38.93645,
      lng: -77.0128,
      city: 'Washington',
      state: 'DC',
    },
    20319: {
      lat: 38.86706,
      lng: -77.01695,
      city: 'Washington',
      state: 'DC',
    },
    20373: {
      lat: 38.85779,
      lng: -77.01051,
      city: 'Naval Anacost Annex',
      state: 'DC',
    },
    20388: {
      lat: 38.8718,
      lng: -76.99481,
      city: 'Washington Navy Yard',
      state: 'DC',
    },
    20390: {
      lat: 38.87902,
      lng: -76.99375,
      city: 'Washington',
      state: 'DC',
    },
    20408: {
      lat: 38.89284,
      lng: -77.02301,
      city: 'Washington',
      state: 'DC',
    },
    20415: {
      lat: 38.89494,
      lng: -77.0442,
      city: 'Washington',
      state: 'DC',
    },
    20418: {
      lat: 38.8928,
      lng: -77.04776,
      city: 'Washington',
      state: 'DC',
    },
    20422: {
      lat: 38.92714,
      lng: -77.0103,
      city: 'Washington',
      state: 'DC',
    },
    20427: {
      lat: 38.90207,
      lng: -77.04755,
      city: 'Washington',
      state: 'DC',
    },
    20431: {
      lat: 38.89895,
      lng: -77.04419,
      city: 'Washington',
      state: 'DC',
    },
    20510: {
      lat: 38.89278,
      lng: -77.00689,
      city: 'Washington',
      state: 'DC',
    },
    20515: {
      lat: 38.88681,
      lng: -77.00901,
      city: 'Washington',
      state: 'DC',
    },
    20520: {
      lat: 38.8947,
      lng: -77.04848,
      city: 'Washington',
      state: 'DC',
    },
    20530: {
      lat: 38.89313,
      lng: -77.02503,
      city: 'Washington',
      state: 'DC',
    },
    20535: {
      lat: 38.89514,
      lng: -77.02493,
      city: 'Washington',
      state: 'DC',
    },
    20540: {
      lat: 38.88793,
      lng: -77.00471,
      city: 'Washington',
      state: 'DC',
    },
    20542: {
      lat: 38.94136,
      lng: -77.02801,
      city: 'Washington',
      state: 'DC',
    },
    20551: {
      lat: 38.8928,
      lng: -77.0458,
      city: 'Washington',
      state: 'DC',
    },
    20560: {
      lat: 38.8902,
      lng: -77.02745,
      city: 'Washington',
      state: 'DC',
    },
    20565: {
      lat: 38.89007,
      lng: -77.01955,
      city: 'Washington',
      state: 'DC',
    },
    20566: {
      lat: 38.89667,
      lng: -77.05536,
      city: 'Washington',
      state: 'DC',
    },
    20591: {
      lat: 38.88695,
      lng: -77.02297,
      city: 'Washington',
      state: 'DC',
    },
    20601: {
      lat: 38.61863,
      lng: -76.85715,
      city: 'Waldorf',
      state: 'MD',
    },
    20602: {
      lat: 38.58335,
      lng: -76.89466,
      city: 'Waldorf',
      state: 'MD',
    },
    20603: {
      lat: 38.62972,
      lng: -76.97473,
      city: 'Waldorf',
      state: 'MD',
    },
    20606: {
      lat: 38.25412,
      lng: -76.73878,
      city: 'Abell',
      state: 'MD',
    },
    20607: {
      lat: 38.67161,
      lng: -77.02478,
      city: 'Accokeek',
      state: 'MD',
    },
    20608: {
      lat: 38.58638,
      lng: -76.70702,
      city: 'Aquasco',
      state: 'MD',
    },
    20609: {
      lat: 38.27191,
      lng: -76.74625,
      city: 'Avenue',
      state: 'MD',
    },
    20611: {
      lat: 38.46251,
      lng: -76.98538,
      city: 'Bel Alton',
      state: 'MD',
    },
    20612: {
      lat: 38.50708,
      lng: -76.67789,
      city: 'Benedict',
      state: 'MD',
    },
    20613: {
      lat: 38.67179,
      lng: -76.80576,
      city: 'Brandywine',
      state: 'MD',
    },
    20615: {
      lat: 38.4204,
      lng: -76.54772,
      city: 'Broomes Island',
      state: 'MD',
    },
    20616: {
      lat: 38.65443,
      lng: -77.08848,
      city: 'Bryans Road',
      state: 'MD',
    },
    20617: {
      lat: 38.54351,
      lng: -76.854,
      city: 'Bryantown',
      state: 'MD',
    },
    20618: {
      lat: 38.28152,
      lng: -76.78891,
      city: 'Bushwood',
      state: 'MD',
    },
    20619: {
      lat: 38.2919,
      lng: -76.52714,
      city: 'California',
      state: 'MD',
    },
    20620: {
      lat: 38.23288,
      lng: -76.52196,
      city: 'Callaway',
      state: 'MD',
    },
    20621: {
      lat: 38.33062,
      lng: -76.79438,
      city: 'Chaptico',
      state: 'MD',
    },
    20622: {
      lat: 38.45281,
      lng: -76.84504,
      city: 'Charlotte Hall',
      state: 'MD',
    },
    20623: {
      lat: 38.7423,
      lng: -76.83747,
      city: 'Cheltenham',
      state: 'MD',
    },
    20624: {
      lat: 38.33523,
      lng: -76.73451,
      city: 'Clements',
      state: 'MD',
    },
    20625: {
      lat: 38.26212,
      lng: -76.84877,
      city: 'Cobb Island',
      state: 'MD',
    },
    20626: {
      lat: 38.23256,
      lng: -76.76404,
      city: 'Coltons Point',
      state: 'MD',
    },
    20628: {
      lat: 38.1459,
      lng: -76.35027,
      city: 'Dameron',
      state: 'MD',
    },
    20629: {
      lat: 38.33996,
      lng: -76.45388,
      city: 'Dowell',
      state: 'MD',
    },
    20630: {
      lat: 38.16653,
      lng: -76.47087,
      city: 'Drayden',
      state: 'MD',
    },
    20632: {
      lat: 38.42591,
      lng: -76.95682,
      city: 'Faulkner',
      state: 'MD',
    },
    20634: {
      lat: 38.24582,
      lng: -76.49412,
      city: 'Great Mills',
      state: 'MD',
    },
    20636: {
      lat: 38.35582,
      lng: -76.57212,
      city: 'Hollywood',
      state: 'MD',
    },
    20637: {
      lat: 38.52577,
      lng: -76.77897,
      city: 'Hughesville',
      state: 'MD',
    },
    20639: {
      lat: 38.60823,
      lng: -76.6086,
      city: 'Huntingtown',
      state: 'MD',
    },
    20640: {
      lat: 38.55408,
      lng: -77.1657,
      city: 'Indian Head',
      state: 'MD',
    },
    20645: {
      lat: 38.29838,
      lng: -76.91413,
      city: 'Issue',
      state: 'MD',
    },
    20646: {
      lat: 38.52114,
      lng: -76.99989,
      city: 'La Plata',
      state: 'MD',
    },
    20650: {
      lat: 38.27186,
      lng: -76.63276,
      city: 'Leonardtown',
      state: 'MD',
    },
    20653: {
      lat: 38.22795,
      lng: -76.43139,
      city: 'Lexington Park',
      state: 'MD',
    },
    20656: {
      lat: 38.33914,
      lng: -76.6787,
      city: 'Loveville',
      state: 'MD',
    },
    20657: {
      lat: 38.38358,
      lng: -76.44537,
      city: 'Lusby',
      state: 'MD',
    },
    20658: {
      lat: 38.56973,
      lng: -77.15958,
      city: 'Marbury',
      state: 'MD',
    },
    20659: {
      lat: 38.41886,
      lng: -76.73238,
      city: 'Mechanicsville',
      state: 'MD',
    },
    20660: {
      lat: 38.36928,
      lng: -76.70741,
      city: 'Morganza',
      state: 'MD',
    },
    20661: {
      lat: 38.26474,
      lng: -76.84473,
      city: 'Mount Victoria',
      state: 'MD',
    },
    20662: {
      lat: 38.43359,
      lng: -77.19972,
      city: 'Nanjemoy',
      state: 'MD',
    },
    20664: {
      lat: 38.35062,
      lng: -76.91631,
      city: 'Newburg',
      state: 'MD',
    },
    20667: {
      lat: 38.21782,
      lng: -76.44537,
      city: 'Park Hall',
      state: 'MD',
    },
    20670: {
      lat: 38.2824,
      lng: -76.42031,
      city: 'Patuxent River',
      state: 'MD',
    },
    20674: {
      lat: 38.13307,
      lng: -76.50019,
      city: 'Piney Point',
      state: 'MD',
    },
    20675: {
      lat: 38.58349,
      lng: -77.01522,
      city: 'Pomfret',
      state: 'MD',
    },
    20676: {
      lat: 38.4869,
      lng: -76.54788,
      city: 'Port Republic',
      state: 'MD',
    },
    20677: {
      lat: 38.49992,
      lng: -77.04016,
      city: 'Port Tobacco',
      state: 'MD',
    },
    20678: {
      lat: 38.52164,
      lng: -76.60072,
      city: 'Prince Frederick',
      state: 'MD',
    },
    20680: {
      lat: 38.11071,
      lng: -76.37353,
      city: 'Ridge',
      state: 'MD',
    },
    20684: {
      lat: 38.14308,
      lng: -76.41027,
      city: 'Saint Inigoes',
      state: 'MD',
    },
    20685: {
      lat: 38.43465,
      lng: -76.52904,
      city: 'Saint Leonard',
      state: 'MD',
    },
    20686: {
      lat: 38.18557,
      lng: -76.43898,
      city: 'Saint Marys City',
      state: 'MD',
    },
    20687: {
      lat: 38.07578,
      lng: -76.34382,
      city: 'Scotland',
      state: 'MD',
    },
    20688: {
      lat: 38.33475,
      lng: -76.46965,
      city: 'Solomons',
      state: 'MD',
    },
    20689: {
      lat: 38.66963,
      lng: -76.58591,
      city: 'Sunderland',
      state: 'MD',
    },
    20690: {
      lat: 38.15916,
      lng: -76.52631,
      city: 'Tall Timbers',
      state: 'MD',
    },
    20692: {
      lat: 38.18352,
      lng: -76.50682,
      city: 'Valley Lee',
      state: 'MD',
    },
    20693: {
      lat: 38.45924,
      lng: -77.08931,
      city: 'Welcome',
      state: 'MD',
    },
    20695: {
      lat: 38.58885,
      lng: -76.97269,
      city: 'White Plains',
      state: 'MD',
    },
    20701: {
      lat: 39.12877,
      lng: -76.78803,
      city: 'Annapolis Junction',
      state: 'MD',
    },
    20705: {
      lat: 39.04503,
      lng: -76.90009,
      city: 'Beltsville',
      state: 'MD',
    },
    20706: {
      lat: 38.96548,
      lng: -76.85091,
      city: 'Lanham',
      state: 'MD',
    },
    20707: {
      lat: 39.09563,
      lng: -76.8842,
      city: 'Laurel',
      state: 'MD',
    },
    20708: {
      lat: 39.04546,
      lng: -76.83462,
      city: 'Laurel',
      state: 'MD',
    },
    20710: {
      lat: 38.94204,
      lng: -76.92623,
      city: 'Bladensburg',
      state: 'MD',
    },
    20711: {
      lat: 38.80485,
      lng: -76.65245,
      city: 'Lothian',
      state: 'MD',
    },
    20712: {
      lat: 38.94306,
      lng: -76.96517,
      city: 'Mount Rainier',
      state: 'MD',
    },
    20714: {
      lat: 38.71813,
      lng: -76.53987,
      city: 'North Beach',
      state: 'MD',
    },
    20715: {
      lat: 38.98643,
      lng: -76.73967,
      city: 'Bowie',
      state: 'MD',
    },
    20716: {
      lat: 38.92734,
      lng: -76.71494,
      city: 'Bowie',
      state: 'MD',
    },
    20720: { lat: 38.9971, lng: -76.78588, city: 'Bowie', state: 'MD' },
    20721: {
      lat: 38.92106,
      lng: -76.78881,
      city: 'Bowie',
      state: 'MD',
    },
    20722: {
      lat: 38.9351,
      lng: -76.94944,
      city: 'Brentwood',
      state: 'MD',
    },
    20723: {
      lat: 39.13664,
      lng: -76.86647,
      city: 'Laurel',
      state: 'MD',
    },
    20724: {
      lat: 39.08028,
      lng: -76.79054,
      city: 'Laurel',
      state: 'MD',
    },
    20732: {
      lat: 38.65573,
      lng: -76.54292,
      city: 'Chesapeake Beach',
      state: 'MD',
    },
    20733: {
      lat: 38.80628,
      lng: -76.53139,
      city: 'Churchton',
      state: 'MD',
    },
    20735: {
      lat: 38.75034,
      lng: -76.90566,
      city: 'Clinton',
      state: 'MD',
    },
    20736: {
      lat: 38.6913,
      lng: -76.62855,
      city: 'Owings',
      state: 'MD',
    },
    20737: {
      lat: 38.96247,
      lng: -76.91571,
      city: 'Riverdale',
      state: 'MD',
    },
    20740: {
      lat: 39.00193,
      lng: -76.93096,
      city: 'College Park',
      state: 'MD',
    },
    20742: {
      lat: 38.99422,
      lng: -76.94347,
      city: 'College Park',
      state: 'MD',
    },
    20743: {
      lat: 38.88372,
      lng: -76.89362,
      city: 'Capitol Heights',
      state: 'MD',
    },
    20744: {
      lat: 38.75362,
      lng: -76.98393,
      city: 'Fort Washington',
      state: 'MD',
    },
    20745: {
      lat: 38.80552,
      lng: -76.99495,
      city: 'Oxon Hill',
      state: 'MD',
    },
    20746: {
      lat: 38.83664,
      lng: -76.91936,
      city: 'Suitland',
      state: 'MD',
    },
    20747: {
      lat: 38.85348,
      lng: -76.88377,
      city: 'District Heights',
      state: 'MD',
    },
    20748: {
      lat: 38.81692,
      lng: -76.94124,
      city: 'Temple Hills',
      state: 'MD',
    },
    20751: {
      lat: 38.79552,
      lng: -76.56221,
      city: 'Deale',
      state: 'MD',
    },
    20754: {
      lat: 38.736,
      lng: -76.65493,
      city: 'Dunkirk',
      state: 'MD',
    },
    20755: {
      lat: 39.09892,
      lng: -76.74479,
      city: 'Fort George G Meade',
      state: 'MD',
    },
    20758: {
      lat: 38.73399,
      lng: -76.58961,
      city: 'Friendship',
      state: 'MD',
    },
    20759: {
      lat: 39.15419,
      lng: -76.92877,
      city: 'Fulton',
      state: 'MD',
    },
    20762: {
      lat: 38.80748,
      lng: -76.87457,
      city: 'Andrews Air Force Base',
      state: 'MD',
    },
    20763: {
      lat: 39.13786,
      lng: -76.82134,
      city: 'Savage',
      state: 'MD',
    },
    20764: {
      lat: 38.83374,
      lng: -76.51146,
      city: 'Shady Side',
      state: 'MD',
    },
    20765: {
      lat: 38.84533,
      lng: -76.54654,
      city: 'Galesville',
      state: 'MD',
    },
    20769: {
      lat: 38.98818,
      lng: -76.81635,
      city: 'Glenn Dale',
      state: 'MD',
    },
    20770: {
      lat: 38.99981,
      lng: -76.88567,
      city: 'Greenbelt',
      state: 'MD',
    },
    20771: {
      lat: 39.00289,
      lng: -76.85534,
      city: 'Greenbelt',
      state: 'MD',
    },
    20772: {
      lat: 38.7785,
      lng: -76.76911,
      city: 'Upper Marlboro',
      state: 'MD',
    },
    20774: {
      lat: 38.87589,
      lng: -76.76925,
      city: 'Upper Marlboro',
      state: 'MD',
    },
    20776: {
      lat: 38.86913,
      lng: -76.6067,
      city: 'Harwood',
      state: 'MD',
    },
    20777: {
      lat: 39.17487,
      lng: -76.96783,
      city: 'Highland',
      state: 'MD',
    },
    20778: {
      lat: 38.83733,
      lng: -76.56647,
      city: 'West River',
      state: 'MD',
    },
    20779: {
      lat: 38.76388,
      lng: -76.57665,
      city: 'Tracys Landing',
      state: 'MD',
    },
    20781: {
      lat: 38.94229,
      lng: -76.93655,
      city: 'Hyattsville',
      state: 'MD',
    },
    20782: {
      lat: 38.96469,
      lng: -76.9649,
      city: 'Hyattsville',
      state: 'MD',
    },
    20783: {
      lat: 38.99988,
      lng: -76.96886,
      city: 'Hyattsville',
      state: 'MD',
    },
    20784: {
      lat: 38.95145,
      lng: -76.89025,
      city: 'Hyattsville',
      state: 'MD',
    },
    20785: {
      lat: 38.91849,
      lng: -76.88237,
      city: 'Hyattsville',
      state: 'MD',
    },
    20794: {
      lat: 39.15107,
      lng: -76.78882,
      city: 'Jessup',
      state: 'MD',
    },
    20812: {
      lat: 38.96677,
      lng: -77.14357,
      city: 'Glen Echo',
      state: 'MD',
    },
    20814: {
      lat: 39.00506,
      lng: -77.10295,
      city: 'Bethesda',
      state: 'MD',
    },
    20815: {
      lat: 38.98369,
      lng: -77.07845,
      city: 'Chevy Chase',
      state: 'MD',
    },
    20816: {
      lat: 38.95612,
      lng: -77.11873,
      city: 'Bethesda',
      state: 'MD',
    },
    20817: {
      lat: 38.99809,
      lng: -77.14919,
      city: 'Bethesda',
      state: 'MD',
    },
    20818: {
      lat: 38.97347,
      lng: -77.16188,
      city: 'Cabin John',
      state: 'MD',
    },
    20832: {
      lat: 39.15134,
      lng: -77.07331,
      city: 'Olney',
      state: 'MD',
    },
    20833: {
      lat: 39.20595,
      lng: -77.05552,
      city: 'Brookeville',
      state: 'MD',
    },
    20837: {
      lat: 39.11208,
      lng: -77.40823,
      city: 'Poolesville',
      state: 'MD',
    },
    20838: {
      lat: 39.22341,
      lng: -77.37223,
      city: 'Barnesville',
      state: 'MD',
    },
    20839: {
      lat: 39.17909,
      lng: -77.41438,
      city: 'Beallsville',
      state: 'MD',
    },
    20841: { lat: 39.1858, lng: -77.32581, city: 'Boyds', state: 'MD' },
    20842: {
      lat: 39.20831,
      lng: -77.42473,
      city: 'Dickerson',
      state: 'MD',
    },
    20850: {
      lat: 39.0908,
      lng: -77.1809,
      city: 'Rockville',
      state: 'MD',
    },
    20851: {
      lat: 39.07767,
      lng: -77.12089,
      city: 'Rockville',
      state: 'MD',
    },
    20852: {
      lat: 39.05134,
      lng: -77.12507,
      city: 'Rockville',
      state: 'MD',
    },
    20853: {
      lat: 39.10237,
      lng: -77.09462,
      city: 'Rockville',
      state: 'MD',
    },
    20854: {
      lat: 39.03413,
      lng: -77.22149,
      city: 'Potomac',
      state: 'MD',
    },
    20855: {
      lat: 39.13702,
      lng: -77.13197,
      city: 'Derwood',
      state: 'MD',
    },
    20860: {
      lat: 39.15072,
      lng: -77.03069,
      city: 'Sandy Spring',
      state: 'MD',
    },
    20861: {
      lat: 39.14653,
      lng: -76.99886,
      city: 'Ashton',
      state: 'MD',
    },
    20862: {
      lat: 39.18039,
      lng: -77.01879,
      city: 'Brinklow',
      state: 'MD',
    },
    20866: {
      lat: 39.10969,
      lng: -76.93411,
      city: 'Burtonsville',
      state: 'MD',
    },
    20868: {
      lat: 39.12534,
      lng: -76.96834,
      city: 'Spencerville',
      state: 'MD',
    },
    20871: {
      lat: 39.2592,
      lng: -77.28187,
      city: 'Clarksburg',
      state: 'MD',
    },
    20872: {
      lat: 39.29142,
      lng: -77.21619,
      city: 'Damascus',
      state: 'MD',
    },
    20874: {
      lat: 39.1291,
      lng: -77.29721,
      city: 'Germantown',
      state: 'MD',
    },
    20876: {
      lat: 39.20706,
      lng: -77.23284,
      city: 'Germantown',
      state: 'MD',
    },
    20877: {
      lat: 39.14068,
      lng: -77.18865,
      city: 'Gaithersburg',
      state: 'MD',
    },
    20878: {
      lat: 39.11376,
      lng: -77.24781,
      city: 'Gaithersburg',
      state: 'MD',
    },
    20879: {
      lat: 39.16992,
      lng: -77.17416,
      city: 'Gaithersburg',
      state: 'MD',
    },
    20880: {
      lat: 39.14077,
      lng: -77.17456,
      city: 'Washington Grove',
      state: 'MD',
    },
    20882: {
      lat: 39.23182,
      lng: -77.14514,
      city: 'Gaithersburg',
      state: 'MD',
    },
    20886: {
      lat: 39.17952,
      lng: -77.197,
      city: 'Montgomery Village',
      state: 'MD',
    },
    20889: {
      lat: 39.00193,
      lng: -77.09038,
      city: 'Bethesda',
      state: 'MD',
    },
    20892: {
      lat: 39.0006,
      lng: -77.10373,
      city: 'Bethesda',
      state: 'MD',
    },
    20894: {
      lat: 38.99591,
      lng: -77.10221,
      city: 'Bethesda',
      state: 'MD',
    },
    20895: {
      lat: 39.02696,
      lng: -77.07781,
      city: 'Kensington',
      state: 'MD',
    },
    20896: {
      lat: 39.03521,
      lng: -77.09494,
      city: 'Garrett Park',
      state: 'MD',
    },
    20899: {
      lat: 39.14442,
      lng: -77.21621,
      city: 'Gaithersburg',
      state: 'MD',
    },
    20901: {
      lat: 39.02155,
      lng: -77.00919,
      city: 'Silver Spring',
      state: 'MD',
    },
    20902: {
      lat: 39.04381,
      lng: -77.0421,
      city: 'Silver Spring',
      state: 'MD',
    },
    20903: {
      lat: 39.02128,
      lng: -76.9821,
      city: 'Silver Spring',
      state: 'MD',
    },
    20904: {
      lat: 39.06608,
      lng: -76.97918,
      city: 'Silver Spring',
      state: 'MD',
    },
    20905: {
      lat: 39.11052,
      lng: -76.99036,
      city: 'Silver Spring',
      state: 'MD',
    },
    20906: {
      lat: 39.08858,
      lng: -77.05522,
      city: 'Silver Spring',
      state: 'MD',
    },
    20910: {
      lat: 39.00227,
      lng: -77.03634,
      city: 'Silver Spring',
      state: 'MD',
    },
    20912: {
      lat: 38.98182,
      lng: -77.00106,
      city: 'Takoma Park',
      state: 'MD',
    },
    21001: {
      lat: 39.51077,
      lng: -76.20274,
      city: 'Aberdeen',
      state: 'MD',
    },
    21005: {
      lat: 39.44101,
      lng: -76.16509,
      city: 'Aberdeen Proving Ground',
      state: 'MD',
    },
    21009: {
      lat: 39.47032,
      lng: -76.29378,
      city: 'Abingdon',
      state: 'MD',
    },
    21010: {
      lat: 39.38953,
      lng: -76.29851,
      city: 'Gunpowder',
      state: 'MD',
    },
    21012: {
      lat: 39.04529,
      lng: -76.49716,
      city: 'Arnold',
      state: 'MD',
    },
    21013: {
      lat: 39.51123,
      lng: -76.49124,
      city: 'Baldwin',
      state: 'MD',
    },
    21014: {
      lat: 39.53606,
      lng: -76.35164,
      city: 'Bel Air',
      state: 'MD',
    },
    21015: {
      lat: 39.54691,
      lng: -76.29619,
      city: 'Bel Air',
      state: 'MD',
    },
    21017: {
      lat: 39.47498,
      lng: -76.23667,
      city: 'Belcamp',
      state: 'MD',
    },
    21023: {
      lat: 39.52368,
      lng: -76.73984,
      city: 'Butler',
      state: 'MD',
    },
    21028: {
      lat: 39.56832,
      lng: -76.24162,
      city: 'Churchville',
      state: 'MD',
    },
    21029: {
      lat: 39.20633,
      lng: -76.95206,
      city: 'Clarksville',
      state: 'MD',
    },
    21030: {
      lat: 39.49002,
      lng: -76.6659,
      city: 'Cockeysville',
      state: 'MD',
    },
    21031: {
      lat: 39.4874,
      lng: -76.65792,
      city: 'Hunt Valley',
      state: 'MD',
    },
    21032: {
      lat: 39.03376,
      lng: -76.60401,
      city: 'Crownsville',
      state: 'MD',
    },
    21034: {
      lat: 39.64905,
      lng: -76.22261,
      city: 'Darlington',
      state: 'MD',
    },
    21035: {
      lat: 38.93637,
      lng: -76.63875,
      city: 'Davidsonville',
      state: 'MD',
    },
    21036: {
      lat: 39.23416,
      lng: -77.00223,
      city: 'Dayton',
      state: 'MD',
    },
    21037: {
      lat: 38.91476,
      lng: -76.54361,
      city: 'Edgewater',
      state: 'MD',
    },
    21040: {
      lat: 39.42953,
      lng: -76.28947,
      city: 'Edgewood',
      state: 'MD',
    },
    21042: {
      lat: 39.27105,
      lng: -76.8924,
      city: 'Ellicott City',
      state: 'MD',
    },
    21043: {
      lat: 39.25759,
      lng: -76.79773,
      city: 'Ellicott City',
      state: 'MD',
    },
    21044: {
      lat: 39.21047,
      lng: -76.88353,
      city: 'Columbia',
      state: 'MD',
    },
    21045: {
      lat: 39.20622,
      lng: -76.82747,
      city: 'Columbia',
      state: 'MD',
    },
    21046: {
      lat: 39.17357,
      lng: -76.84183,
      city: 'Columbia',
      state: 'MD',
    },
    21047: {
      lat: 39.52818,
      lng: -76.43781,
      city: 'Fallston',
      state: 'MD',
    },
    21048: {
      lat: 39.49568,
      lng: -76.91057,
      city: 'Finksburg',
      state: 'MD',
    },
    21050: {
      lat: 39.58569,
      lng: -76.39228,
      city: 'Forest Hill',
      state: 'MD',
    },
    21051: { lat: 39.4721, lng: -76.45388, city: 'Fork', state: 'MD' },
    21052: {
      lat: 39.20238,
      lng: -76.44379,
      city: 'Fort Howard',
      state: 'MD',
    },
    21053: {
      lat: 39.69296,
      lng: -76.71567,
      city: 'Freeland',
      state: 'MD',
    },
    21054: {
      lat: 39.02624,
      lng: -76.67083,
      city: 'Gambrills',
      state: 'MD',
    },
    21056: {
      lat: 39.07805,
      lng: -76.4338,
      city: 'Gibson Island',
      state: 'MD',
    },
    21057: {
      lat: 39.45111,
      lng: -76.50606,
      city: 'Glen Arm',
      state: 'MD',
    },
    21060: {
      lat: 39.16893,
      lng: -76.58346,
      city: 'Glen Burnie',
      state: 'MD',
    },
    21061: {
      lat: 39.16116,
      lng: -76.63226,
      city: 'Glen Burnie',
      state: 'MD',
    },
    21071: {
      lat: 39.48516,
      lng: -76.8081,
      city: 'Glyndon',
      state: 'MD',
    },
    21074: {
      lat: 39.61746,
      lng: -76.84351,
      city: 'Hampstead',
      state: 'MD',
    },
    21075: {
      lat: 39.20414,
      lng: -76.75301,
      city: 'Elkridge',
      state: 'MD',
    },
    21076: {
      lat: 39.16856,
      lng: -76.72239,
      city: 'Hanover',
      state: 'MD',
    },
    21077: {
      lat: 39.1583,
      lng: -76.69953,
      city: 'Harmans',
      state: 'MD',
    },
    21078: {
      lat: 39.56911,
      lng: -76.14776,
      city: 'Havre De Grace',
      state: 'MD',
    },
    21082: {
      lat: 39.48284,
      lng: -76.46735,
      city: 'Hydes',
      state: 'MD',
    },
    21084: {
      lat: 39.61659,
      lng: -76.46317,
      city: 'Jarrettsville',
      state: 'MD',
    },
    21085: {
      lat: 39.44462,
      lng: -76.35601,
      city: 'Joppa',
      state: 'MD',
    },
    21087: {
      lat: 39.44728,
      lng: -76.41583,
      city: 'Kingsville',
      state: 'MD',
    },
    21090: {
      lat: 39.20919,
      lng: -76.66796,
      city: 'Linthicum Heights',
      state: 'MD',
    },
    21093: {
      lat: 39.43773,
      lng: -76.64318,
      city: 'Lutherville Timonium',
      state: 'MD',
    },
    21102: {
      lat: 39.68732,
      lng: -76.84564,
      city: 'Manchester',
      state: 'MD',
    },
    21104: {
      lat: 39.35044,
      lng: -76.90654,
      city: 'Marriottsville',
      state: 'MD',
    },
    21105: {
      lat: 39.71512,
      lng: -76.65225,
      city: 'Maryland Line',
      state: 'MD',
    },
    21108: {
      lat: 39.08932,
      lng: -76.62139,
      city: 'Millersville',
      state: 'MD',
    },
    21111: {
      lat: 39.57568,
      lng: -76.58089,
      city: 'Monkton',
      state: 'MD',
    },
    21113: {
      lat: 39.05448,
      lng: -76.71519,
      city: 'Odenton',
      state: 'MD',
    },
    21114: {
      lat: 39.00967,
      lng: -76.6839,
      city: 'Crofton',
      state: 'MD',
    },
    21117: {
      lat: 39.42719,
      lng: -76.77795,
      city: 'Owings Mills',
      state: 'MD',
    },
    21120: {
      lat: 39.64723,
      lng: -76.67703,
      city: 'Parkton',
      state: 'MD',
    },
    21122: {
      lat: 39.11631,
      lng: -76.49941,
      city: 'Pasadena',
      state: 'MD',
    },
    21128: {
      lat: 39.40526,
      lng: -76.44628,
      city: 'Perry Hall',
      state: 'MD',
    },
    21130: {
      lat: 39.47495,
      lng: -76.18921,
      city: 'Perryman',
      state: 'MD',
    },
    21131: {
      lat: 39.50338,
      lng: -76.57486,
      city: 'Phoenix',
      state: 'MD',
    },
    21132: {
      lat: 39.69601,
      lng: -76.42577,
      city: 'Pylesville',
      state: 'MD',
    },
    21133: {
      lat: 39.37705,
      lng: -76.81174,
      city: 'Randallstown',
      state: 'MD',
    },
    21136: {
      lat: 39.48851,
      lng: -76.80888,
      city: 'Reisterstown',
      state: 'MD',
    },
    21140: { lat: 38.95062, lng: -76.58569, city: 'Riva', state: 'MD' },
    21144: {
      lat: 39.12058,
      lng: -76.67767,
      city: 'Severn',
      state: 'MD',
    },
    21146: {
      lat: 39.07737,
      lng: -76.55613,
      city: 'Severna Park',
      state: 'MD',
    },
    21152: {
      lat: 39.54905,
      lng: -76.68107,
      city: 'Sparks Glencoe',
      state: 'MD',
    },
    21153: {
      lat: 39.41649,
      lng: -76.707,
      city: 'Stevenson',
      state: 'MD',
    },
    21154: {
      lat: 39.65314,
      lng: -76.36087,
      city: 'Street',
      state: 'MD',
    },
    21155: {
      lat: 39.57183,
      lng: -76.80597,
      city: 'Upperco',
      state: 'MD',
    },
    21156: {
      lat: 39.43752,
      lng: -76.397,
      city: 'Upper Falls',
      state: 'MD',
    },
    21157: {
      lat: 39.54889,
      lng: -76.98162,
      city: 'Westminster',
      state: 'MD',
    },
    21158: {
      lat: 39.6466,
      lng: -77.03217,
      city: 'Westminster',
      state: 'MD',
    },
    21160: {
      lat: 39.70728,
      lng: -76.30754,
      city: 'Whiteford',
      state: 'MD',
    },
    21161: {
      lat: 39.65983,
      lng: -76.56475,
      city: 'White Hall',
      state: 'MD',
    },
    21162: {
      lat: 39.39084,
      lng: -76.40164,
      city: 'White Marsh',
      state: 'MD',
    },
    21163: {
      lat: 39.34024,
      lng: -76.85706,
      city: 'Woodstock',
      state: 'MD',
    },
    21201: {
      lat: 39.29487,
      lng: -76.62237,
      city: 'Baltimore',
      state: 'MD',
    },
    21202: {
      lat: 39.29705,
      lng: -76.60747,
      city: 'Baltimore',
      state: 'MD',
    },
    21204: {
      lat: 39.40353,
      lng: -76.63024,
      city: 'Towson',
      state: 'MD',
    },
    21205: {
      lat: 39.30227,
      lng: -76.56413,
      city: 'Baltimore',
      state: 'MD',
    },
    21206: {
      lat: 39.33932,
      lng: -76.53706,
      city: 'Baltimore',
      state: 'MD',
    },
    21207: {
      lat: 39.32417,
      lng: -76.72017,
      city: 'Gwynn Oak',
      state: 'MD',
    },
    21208: {
      lat: 39.38401,
      lng: -76.72547,
      city: 'Pikesville',
      state: 'MD',
    },
    21209: {
      lat: 39.37261,
      lng: -76.66802,
      city: 'Baltimore',
      state: 'MD',
    },
    21210: {
      lat: 39.35878,
      lng: -76.63521,
      city: 'Baltimore',
      state: 'MD',
    },
    21211: {
      lat: 39.32857,
      lng: -76.6389,
      city: 'Baltimore',
      state: 'MD',
    },
    21212: {
      lat: 39.3678,
      lng: -76.61456,
      city: 'Baltimore',
      state: 'MD',
    },
    21213: {
      lat: 39.31501,
      lng: -76.57723,
      city: 'Baltimore',
      state: 'MD',
    },
    21214: {
      lat: 39.35217,
      lng: -76.5644,
      city: 'Baltimore',
      state: 'MD',
    },
    21215: {
      lat: 39.34578,
      lng: -76.68367,
      city: 'Baltimore',
      state: 'MD',
    },
    21216: {
      lat: 39.3108,
      lng: -76.67145,
      city: 'Baltimore',
      state: 'MD',
    },
    21217: {
      lat: 39.30701,
      lng: -76.63833,
      city: 'Baltimore',
      state: 'MD',
    },
    21218: {
      lat: 39.33031,
      lng: -76.60235,
      city: 'Baltimore',
      state: 'MD',
    },
    21219: {
      lat: 39.23097,
      lng: -76.44383,
      city: 'Sparrows Point',
      state: 'MD',
    },
    21220: {
      lat: 39.345,
      lng: -76.3966,
      city: 'Middle River',
      state: 'MD',
    },
    21221: {
      lat: 39.29462,
      lng: -76.44034,
      city: 'Essex',
      state: 'MD',
    },
    21222: {
      lat: 39.26424,
      lng: -76.49537,
      city: 'Dundalk',
      state: 'MD',
    },
    21223: {
      lat: 39.28421,
      lng: -76.65323,
      city: 'Baltimore',
      state: 'MD',
    },
    21224: {
      lat: 39.28309,
      lng: -76.54088,
      city: 'Baltimore',
      state: 'MD',
    },
    21225: {
      lat: 39.22597,
      lng: -76.61508,
      city: 'Brooklyn',
      state: 'MD',
    },
    21226: {
      lat: 39.2078,
      lng: -76.5627,
      city: 'Curtis Bay',
      state: 'MD',
    },
    21227: {
      lat: 39.24024,
      lng: -76.67913,
      city: 'Halethorpe',
      state: 'MD',
    },
    21228: {
      lat: 39.27272,
      lng: -76.74687,
      city: 'Catonsville',
      state: 'MD',
    },
    21229: {
      lat: 39.28509,
      lng: -76.69083,
      city: 'Baltimore',
      state: 'MD',
    },
    21230: {
      lat: 39.26651,
      lng: -76.62213,
      city: 'Baltimore',
      state: 'MD',
    },
    21231: {
      lat: 39.28775,
      lng: -76.592,
      city: 'Baltimore',
      state: 'MD',
    },
    21233: {
      lat: 39.29244,
      lng: -76.60503,
      city: 'Baltimore',
      state: 'MD',
    },
    21234: {
      lat: 39.395,
      lng: -76.53252,
      city: 'Parkville',
      state: 'MD',
    },
    21236: {
      lat: 39.38789,
      lng: -76.48552,
      city: 'Nottingham',
      state: 'MD',
    },
    21237: {
      lat: 39.33938,
      lng: -76.49456,
      city: 'Rosedale',
      state: 'MD',
    },
    21239: {
      lat: 39.36517,
      lng: -76.58709,
      city: 'Baltimore',
      state: 'MD',
    },
    21240: {
      lat: 39.17483,
      lng: -76.67181,
      city: 'Baltimore',
      state: 'MD',
    },
    21244: {
      lat: 39.3342,
      lng: -76.78217,
      city: 'Windsor Mill',
      state: 'MD',
    },
    21250: {
      lat: 39.25558,
      lng: -76.71117,
      city: 'Baltimore',
      state: 'MD',
    },
    21251: {
      lat: 39.34993,
      lng: -76.58027,
      city: 'Baltimore',
      state: 'MD',
    },
    21252: {
      lat: 39.39413,
      lng: -76.61144,
      city: 'Towson',
      state: 'MD',
    },
    21285: {
      lat: 39.3875,
      lng: -76.61702,
      city: 'Towson',
      state: 'MD',
    },
    21286: {
      lat: 39.41374,
      lng: -76.57375,
      city: 'Towson',
      state: 'MD',
    },
    21287: {
      lat: 39.29899,
      lng: -76.58817,
      city: 'Baltimore',
      state: 'MD',
    },
    21401: {
      lat: 38.98967,
      lng: -76.5505,
      city: 'Annapolis',
      state: 'MD',
    },
    21402: {
      lat: 38.98816,
      lng: -76.47202,
      city: 'Annapolis',
      state: 'MD',
    },
    21403: {
      lat: 38.94433,
      lng: -76.49296,
      city: 'Annapolis',
      state: 'MD',
    },
    21405: {
      lat: 39.02996,
      lng: -76.54971,
      city: 'Annapolis',
      state: 'MD',
    },
    21409: {
      lat: 39.01968,
      lng: -76.44738,
      city: 'Annapolis',
      state: 'MD',
    },
    21502: {
      lat: 39.64143,
      lng: -78.75609,
      city: 'Cumberland',
      state: 'MD',
    },
    21520: {
      lat: 39.63661,
      lng: -79.30786,
      city: 'Accident',
      state: 'MD',
    },
    21521: {
      lat: 39.55042,
      lng: -79.04836,
      city: 'Barton',
      state: 'MD',
    },
    21523: {
      lat: 39.47956,
      lng: -79.07925,
      city: 'Bloomington',
      state: 'MD',
    },
    21524: {
      lat: 39.70666,
      lng: -78.80203,
      city: 'Corriganville',
      state: 'MD',
    },
    21529: {
      lat: 39.71692,
      lng: -78.77474,
      city: 'Ellerslie',
      state: 'MD',
    },
    21530: {
      lat: 39.68878,
      lng: -78.54138,
      city: 'Flintstone',
      state: 'MD',
    },
    21531: {
      lat: 39.64862,
      lng: -79.42221,
      city: 'Friendsville',
      state: 'MD',
    },
    21532: {
      lat: 39.65559,
      lng: -78.96025,
      city: 'Frostburg',
      state: 'MD',
    },
    21536: {
      lat: 39.65619,
      lng: -79.16924,
      city: 'Grantsville',
      state: 'MD',
    },
    21538: {
      lat: 39.40133,
      lng: -79.22109,
      city: 'Kitzmiller',
      state: 'MD',
    },
    21539: {
      lat: 39.59828,
      lng: -79.01074,
      city: 'Lonaconing',
      state: 'MD',
    },
    21540: { lat: 39.47659, lng: -79.05929, city: 'Luke', state: 'MD' },
    21541: {
      lat: 39.55727,
      lng: -79.36732,
      city: 'McHenry',
      state: 'MD',
    },
    21542: {
      lat: 39.59114,
      lng: -78.95087,
      city: 'Midland',
      state: 'MD',
    },
    21543: {
      lat: 39.64157,
      lng: -78.96231,
      city: 'Midlothian',
      state: 'MD',
    },
    21545: {
      lat: 39.6991,
      lng: -78.86513,
      city: 'Mount Savage',
      state: 'MD',
    },
    21550: {
      lat: 39.39107,
      lng: -79.39085,
      city: 'Oakland',
      state: 'MD',
    },
    21555: {
      lat: 39.58777,
      lng: -78.55685,
      city: 'Oldtown',
      state: 'MD',
    },
    21557: {
      lat: 39.5191,
      lng: -78.92353,
      city: 'Rawlings',
      state: 'MD',
    },
    21560: {
      lat: 39.56508,
      lng: -78.70632,
      city: 'Spring Gap',
      state: 'MD',
    },
    21561: {
      lat: 39.49976,
      lng: -79.20482,
      city: 'Swanton',
      state: 'MD',
    },
    21562: {
      lat: 39.50326,
      lng: -79.05046,
      city: 'Westernport',
      state: 'MD',
    },
    21601: {
      lat: 38.79275,
      lng: -76.08242,
      city: 'Easton',
      state: 'MD',
    },
    21607: {
      lat: 39.13398,
      lng: -75.85644,
      city: 'Barclay',
      state: 'MD',
    },
    21610: {
      lat: 39.3652,
      lng: -76.06845,
      city: 'Betterton',
      state: 'MD',
    },
    21612: {
      lat: 38.74928,
      lng: -76.27695,
      city: 'Bozman',
      state: 'MD',
    },
    21613: {
      lat: 38.49537,
      lng: -76.07169,
      city: 'Cambridge',
      state: 'MD',
    },
    21617: {
      lat: 39.04985,
      lng: -76.03819,
      city: 'Centreville',
      state: 'MD',
    },
    21619: {
      lat: 38.95285,
      lng: -76.27939,
      city: 'Chester',
      state: 'MD',
    },
    21620: {
      lat: 39.20667,
      lng: -76.08739,
      city: 'Chestertown',
      state: 'MD',
    },
    21622: {
      lat: 38.40821,
      lng: -76.17381,
      city: 'Church Creek',
      state: 'MD',
    },
    21623: {
      lat: 39.11915,
      lng: -75.96268,
      city: 'Church Hill',
      state: 'MD',
    },
    21624: {
      lat: 38.83407,
      lng: -76.27212,
      city: 'Claiborne',
      state: 'MD',
    },
    21625: {
      lat: 38.86925,
      lng: -75.99216,
      city: 'Cordova',
      state: 'MD',
    },
    21626: {
      lat: 38.33902,
      lng: -76.08398,
      city: 'Crapo',
      state: 'MD',
    },
    21627: {
      lat: 38.23528,
      lng: -76.04967,
      city: 'Crocheron',
      state: 'MD',
    },
    21628: {
      lat: 39.23577,
      lng: -75.92238,
      city: 'Crumpton',
      state: 'MD',
    },
    21629: {
      lat: 38.86111,
      lng: -75.82753,
      city: 'Denton',
      state: 'MD',
    },
    21631: {
      lat: 38.58784,
      lng: -75.94598,
      city: 'East New Market',
      state: 'MD',
    },
    21632: {
      lat: 38.72287,
      lng: -75.77478,
      city: 'Federalsburg',
      state: 'MD',
    },
    21634: {
      lat: 38.27995,
      lng: -76.185,
      city: 'Fishing Creek',
      state: 'MD',
    },
    21635: {
      lat: 39.34006,
      lng: -75.83826,
      city: 'Galena',
      state: 'MD',
    },
    21636: {
      lat: 39.0261,
      lng: -75.79839,
      city: 'Goldsboro',
      state: 'MD',
    },
    21638: {
      lat: 38.94513,
      lng: -76.20263,
      city: 'Grasonville',
      state: 'MD',
    },
    21639: {
      lat: 38.96533,
      lng: -75.7928,
      city: 'Greensboro',
      state: 'MD',
    },
    21640: {
      lat: 39.07036,
      lng: -75.82533,
      city: 'Henderson',
      state: 'MD',
    },
    21641: {
      lat: 38.91696,
      lng: -75.94161,
      city: 'Hillsboro',
      state: 'MD',
    },
    21643: {
      lat: 38.6442,
      lng: -75.87124,
      city: 'Hurlock',
      state: 'MD',
    },
    21644: {
      lat: 39.11728,
      lng: -75.87441,
      city: 'Ingleside',
      state: 'MD',
    },
    21645: {
      lat: 39.31979,
      lng: -75.96216,
      city: 'Kennedyville',
      state: 'MD',
    },
    21647: {
      lat: 38.80114,
      lng: -76.2831,
      city: 'Mcdaniel',
      state: 'MD',
    },
    21648: {
      lat: 38.4966,
      lng: -76.23386,
      city: 'Madison',
      state: 'MD',
    },
    21649: {
      lat: 39.1366,
      lng: -75.77453,
      city: 'Marydel',
      state: 'MD',
    },
    21650: {
      lat: 39.31286,
      lng: -75.80795,
      city: 'Massey',
      state: 'MD',
    },
    21651: {
      lat: 39.25821,
      lng: -75.85802,
      city: 'Millington',
      state: 'MD',
    },
    21652: {
      lat: 38.72028,
      lng: -76.28108,
      city: 'Neavitt',
      state: 'MD',
    },
    21653: {
      lat: 38.74704,
      lng: -76.17744,
      city: 'Newcomb',
      state: 'MD',
    },
    21654: {
      lat: 38.6908,
      lng: -76.12518,
      city: 'Oxford',
      state: 'MD',
    },
    21655: {
      lat: 38.74601,
      lng: -75.92103,
      city: 'Preston',
      state: 'MD',
    },
    21657: {
      lat: 38.9534,
      lng: -75.99042,
      city: 'Queen Anne',
      state: 'MD',
    },
    21658: {
      lat: 38.94071,
      lng: -76.13819,
      city: 'Queenstown',
      state: 'MD',
    },
    21659: {
      lat: 38.57442,
      lng: -75.79985,
      city: 'Rhodesdale',
      state: 'MD',
    },
    21660: {
      lat: 38.95534,
      lng: -75.88839,
      city: 'Ridgely',
      state: 'MD',
    },
    21661: {
      lat: 39.12905,
      lng: -76.21967,
      city: 'Rock Hall',
      state: 'MD',
    },
    21662: {
      lat: 38.7136,
      lng: -76.20209,
      city: 'Royal Oak',
      state: 'MD',
    },
    21663: {
      lat: 38.78333,
      lng: -76.23303,
      city: 'Saint Michaels',
      state: 'MD',
    },
    21664: {
      lat: 38.6081,
      lng: -75.94732,
      city: 'Secretary',
      state: 'MD',
    },
    21665: {
      lat: 38.75158,
      lng: -76.34469,
      city: 'Sherwood',
      state: 'MD',
    },
    21666: {
      lat: 38.93956,
      lng: -76.33272,
      city: 'Stevensville',
      state: 'MD',
    },
    21667: {
      lat: 39.34377,
      lng: -76.05188,
      city: 'Still Pond',
      state: 'MD',
    },
    21668: {
      lat: 39.18967,
      lng: -75.85139,
      city: 'Sudlersville',
      state: 'MD',
    },
    21669: {
      lat: 38.45897,
      lng: -76.28509,
      city: 'Taylors Island',
      state: 'MD',
    },
    21671: {
      lat: 38.69658,
      lng: -76.33562,
      city: 'Tilghman',
      state: 'MD',
    },
    21672: {
      lat: 38.27707,
      lng: -76.06148,
      city: 'Toddville',
      state: 'MD',
    },
    21673: {
      lat: 38.65235,
      lng: -76.04163,
      city: 'Trappe',
      state: 'MD',
    },
    21675: {
      lat: 38.29011,
      lng: -76.0884,
      city: 'Wingate',
      state: 'MD',
    },
    21676: {
      lat: 38.78628,
      lng: -76.30548,
      city: 'Wittman',
      state: 'MD',
    },
    21677: {
      lat: 38.48454,
      lng: -76.18544,
      city: 'Woolford',
      state: 'MD',
    },
    21678: {
      lat: 39.30889,
      lng: -76.10336,
      city: 'Worton',
      state: 'MD',
    },
    21679: {
      lat: 38.91722,
      lng: -76.08875,
      city: 'Wye Mills',
      state: 'MD',
    },
    21701: {
      lat: 39.44729,
      lng: -77.33512,
      city: 'Frederick',
      state: 'MD',
    },
    21702: {
      lat: 39.48019,
      lng: -77.4578,
      city: 'Frederick',
      state: 'MD',
    },
    21703: {
      lat: 39.36961,
      lng: -77.47088,
      city: 'Frederick',
      state: 'MD',
    },
    21704: {
      lat: 39.3506,
      lng: -77.3735,
      city: 'Frederick',
      state: 'MD',
    },
    21705: {
      lat: 39.40887,
      lng: -77.41002,
      city: 'Frederick',
      state: 'MD',
    },
    21710: {
      lat: 39.29652,
      lng: -77.44941,
      city: 'Adamstown',
      state: 'MD',
    },
    21711: {
      lat: 39.66808,
      lng: -78.01247,
      city: 'Big Pool',
      state: 'MD',
    },
    21713: {
      lat: 39.52618,
      lng: -77.66933,
      city: 'Boonsboro',
      state: 'MD',
    },
    21714: {
      lat: 39.41059,
      lng: -77.51003,
      city: 'Braddock Heights',
      state: 'MD',
    },
    21715: {
      lat: 39.37879,
      lng: -77.66063,
      city: 'Brownsville',
      state: 'MD',
    },
    21716: {
      lat: 39.31798,
      lng: -77.6262,
      city: 'Brunswick',
      state: 'MD',
    },
    21717: {
      lat: 39.33865,
      lng: -77.43147,
      city: 'Buckeystown',
      state: 'MD',
    },
    21718: {
      lat: 39.39343,
      lng: -77.62734,
      city: 'Burkittsville',
      state: 'MD',
    },
    21719: {
      lat: 39.70501,
      lng: -77.49977,
      city: 'Cascade',
      state: 'MD',
    },
    21722: {
      lat: 39.66575,
      lng: -77.91457,
      city: 'Clear Spring',
      state: 'MD',
    },
    21723: {
      lat: 39.32825,
      lng: -77.01056,
      city: 'Cooksville',
      state: 'MD',
    },
    21727: {
      lat: 39.68865,
      lng: -77.33096,
      city: 'Emmitsburg',
      state: 'MD',
    },
    21733: {
      lat: 39.55004,
      lng: -77.75838,
      city: 'Fairplay',
      state: 'MD',
    },
    21734: {
      lat: 39.60583,
      lng: -77.70382,
      city: 'Funkstown',
      state: 'MD',
    },
    21737: {
      lat: 39.2543,
      lng: -77.01959,
      city: 'Glenelg',
      state: 'MD',
    },
    21738: {
      lat: 39.27973,
      lng: -77.02554,
      city: 'Glenwood',
      state: 'MD',
    },
    21740: {
      lat: 39.63233,
      lng: -77.73902,
      city: 'Hagerstown',
      state: 'MD',
    },
    21742: {
      lat: 39.67363,
      lng: -77.65928,
      city: 'Hagerstown',
      state: 'MD',
    },
    21746: {
      lat: 39.56591,
      lng: -77.71409,
      city: 'Hagerstown',
      state: 'MD',
    },
    21750: {
      lat: 39.68698,
      lng: -78.2129,
      city: 'Hancock',
      state: 'MD',
    },
    21754: {
      lat: 39.33684,
      lng: -77.31131,
      city: 'Ijamsville',
      state: 'MD',
    },
    21755: {
      lat: 39.36101,
      lng: -77.56887,
      city: 'Jefferson',
      state: 'MD',
    },
    21756: {
      lat: 39.45758,
      lng: -77.70118,
      city: 'Keedysville',
      state: 'MD',
    },
    21757: {
      lat: 39.59902,
      lng: -77.25906,
      city: 'Keymar',
      state: 'MD',
    },
    21758: {
      lat: 39.34567,
      lng: -77.66265,
      city: 'Knoxville',
      state: 'MD',
    },
    21762: {
      lat: 39.48058,
      lng: -77.24842,
      city: 'Libertytown',
      state: 'MD',
    },
    21766: {
      lat: 39.65928,
      lng: -78.39859,
      city: 'Little Orleans',
      state: 'MD',
    },
    21767: {
      lat: 39.69773,
      lng: -77.74638,
      city: 'Maugansville',
      state: 'MD',
    },
    21769: {
      lat: 39.44582,
      lng: -77.56489,
      city: 'Middletown',
      state: 'MD',
    },
    21770: {
      lat: 39.35245,
      lng: -77.25791,
      city: 'Monrovia',
      state: 'MD',
    },
    21771: {
      lat: 39.3955,
      lng: -77.15869,
      city: 'Mount Airy',
      state: 'MD',
    },
    21773: {
      lat: 39.53725,
      lng: -77.55301,
      city: 'Myersville',
      state: 'MD',
    },
    21774: {
      lat: 39.4088,
      lng: -77.27844,
      city: 'New Market',
      state: 'MD',
    },
    21776: {
      lat: 39.51861,
      lng: -77.10376,
      city: 'New Windsor',
      state: 'MD',
    },
    21777: {
      lat: 39.27918,
      lng: -77.5287,
      city: 'Point Of Rocks',
      state: 'MD',
    },
    21778: {
      lat: 39.61576,
      lng: -77.33754,
      city: 'Rocky Ridge',
      state: 'MD',
    },
    21779: {
      lat: 39.42447,
      lng: -77.65398,
      city: 'Rohrersville',
      state: 'MD',
    },
    21780: {
      lat: 39.67676,
      lng: -77.46777,
      city: 'Sabillasville',
      state: 'MD',
    },
    21781: {
      lat: 39.57123,
      lng: -77.75981,
      city: 'Saint James',
      state: 'MD',
    },
    21782: {
      lat: 39.4552,
      lng: -77.75995,
      city: 'Sharpsburg',
      state: 'MD',
    },
    21783: {
      lat: 39.64885,
      lng: -77.55903,
      city: 'Smithsburg',
      state: 'MD',
    },
    21784: {
      lat: 39.40087,
      lng: -76.9726,
      city: 'Sykesville',
      state: 'MD',
    },
    21787: {
      lat: 39.67169,
      lng: -77.17317,
      city: 'Taneytown',
      state: 'MD',
    },
    21788: {
      lat: 39.59429,
      lng: -77.41958,
      city: 'Thurmont',
      state: 'MD',
    },
    21790: {
      lat: 39.25908,
      lng: -77.49559,
      city: 'Tuscarora',
      state: 'MD',
    },
    21791: {
      lat: 39.54267,
      lng: -77.18713,
      city: 'Union Bridge',
      state: 'MD',
    },
    21793: {
      lat: 39.48963,
      lng: -77.34357,
      city: 'Walkersville',
      state: 'MD',
    },
    21794: {
      lat: 39.29705,
      lng: -76.97388,
      city: 'West Friendship',
      state: 'MD',
    },
    21795: {
      lat: 39.582,
      lng: -77.82733,
      city: 'Williamsport',
      state: 'MD',
    },
    21797: {
      lat: 39.33284,
      lng: -77.07002,
      city: 'Woodbine',
      state: 'MD',
    },
    21798: {
      lat: 39.53858,
      lng: -77.29985,
      city: 'Woodsboro',
      state: 'MD',
    },
    21801: {
      lat: 38.38106,
      lng: -75.64073,
      city: 'Salisbury',
      state: 'MD',
    },
    21804: {
      lat: 38.31726,
      lng: -75.53673,
      city: 'Salisbury',
      state: 'MD',
    },
    21810: {
      lat: 38.29005,
      lng: -75.68887,
      city: 'Allen',
      state: 'MD',
    },
    21811: {
      lat: 38.31977,
      lng: -75.22024,
      city: 'Berlin',
      state: 'MD',
    },
    21813: {
      lat: 38.42658,
      lng: -75.17293,
      city: 'Bishopville',
      state: 'MD',
    },
    21814: {
      lat: 38.29176,
      lng: -75.88669,
      city: 'Bivalve',
      state: 'MD',
    },
    21817: {
      lat: 37.98081,
      lng: -75.83537,
      city: 'Crisfield',
      state: 'MD',
    },
    21821: {
      lat: 38.16664,
      lng: -75.90611,
      city: 'Deal Island',
      state: 'MD',
    },
    21822: { lat: 38.28129, lng: -75.65566, city: 'Eden', state: 'MD' },
    21824: {
      lat: 37.97982,
      lng: -76.02871,
      city: 'Ewell',
      state: 'MD',
    },
    21826: {
      lat: 38.32077,
      lng: -75.62353,
      city: 'Fruitland',
      state: 'MD',
    },
    21829: {
      lat: 38.09892,
      lng: -75.38324,
      city: 'Girdletree',
      state: 'MD',
    },
    21830: {
      lat: 38.40202,
      lng: -75.72841,
      city: 'Hebron',
      state: 'MD',
    },
    21835: {
      lat: 38.53355,
      lng: -75.93758,
      city: 'Linkwood',
      state: 'MD',
    },
    21837: {
      lat: 38.4594,
      lng: -75.76731,
      city: 'Mardela Springs',
      state: 'MD',
    },
    21838: {
      lat: 38.01452,
      lng: -75.73098,
      city: 'Marion Station',
      state: 'MD',
    },
    21840: {
      lat: 38.25814,
      lng: -75.89276,
      city: 'Nanticoke',
      state: 'MD',
    },
    21841: {
      lat: 38.24385,
      lng: -75.29588,
      city: 'Newark',
      state: 'MD',
    },
    21842: {
      lat: 38.3778,
      lng: -75.09084,
      city: 'Ocean City',
      state: 'MD',
    },
    21849: {
      lat: 38.37006,
      lng: -75.46064,
      city: 'Parsonsburg',
      state: 'MD',
    },
    21850: {
      lat: 38.36171,
      lng: -75.39867,
      city: 'Pittsville',
      state: 'MD',
    },
    21851: {
      lat: 38.07825,
      lng: -75.54375,
      city: 'Pocomoke City',
      state: 'MD',
    },
    21853: {
      lat: 38.20453,
      lng: -75.7102,
      city: 'Princess Anne',
      state: 'MD',
    },
    21856: {
      lat: 38.33807,
      lng: -75.79224,
      city: 'Quantico',
      state: 'MD',
    },
    21861: {
      lat: 38.53525,
      lng: -75.72834,
      city: 'Sharptown',
      state: 'MD',
    },
    21862: {
      lat: 38.39716,
      lng: -75.21514,
      city: 'Showell',
      state: 'MD',
    },
    21863: {
      lat: 38.19167,
      lng: -75.40327,
      city: 'Snow Hill',
      state: 'MD',
    },
    21864: {
      lat: 38.04355,
      lng: -75.40532,
      city: 'Stockton',
      state: 'MD',
    },
    21865: {
      lat: 38.28605,
      lng: -75.83296,
      city: 'Tyaskin',
      state: 'MD',
    },
    21866: {
      lat: 37.9672,
      lng: -76.02137,
      city: 'Tylerton',
      state: 'MD',
    },
    21867: {
      lat: 38.11025,
      lng: -75.79399,
      city: 'Upper Fairmount',
      state: 'MD',
    },
    21869: {
      lat: 38.43246,
      lng: -75.89012,
      city: 'Vienna',
      state: 'MD',
    },
    21871: {
      lat: 38.10489,
      lng: -75.73127,
      city: 'Westover',
      state: 'MD',
    },
    21872: {
      lat: 38.4163,
      lng: -75.29399,
      city: 'Whaleyville',
      state: 'MD',
    },
    21874: {
      lat: 38.39266,
      lng: -75.35417,
      city: 'Willards',
      state: 'MD',
    },
    21875: {
      lat: 38.44038,
      lng: -75.54559,
      city: 'Delmar',
      state: 'MD',
    },
    21890: {
      lat: 38.15673,
      lng: -75.6998,
      city: 'Westover',
      state: 'MD',
    },
    21901: {
      lat: 39.58593,
      lng: -75.95821,
      city: 'North East',
      state: 'MD',
    },
    21902: {
      lat: 39.55229,
      lng: -76.06157,
      city: 'Perry Point',
      state: 'MD',
    },
    21903: {
      lat: 39.57274,
      lng: -76.04378,
      city: 'Perryville',
      state: 'MD',
    },
    21904: {
      lat: 39.62303,
      lng: -76.07812,
      city: 'Port Deposit',
      state: 'MD',
    },
    21911: {
      lat: 39.68931,
      lng: -76.03886,
      city: 'Rising Sun',
      state: 'MD',
    },
    21912: {
      lat: 39.42486,
      lng: -75.81607,
      city: 'Warwick',
      state: 'MD',
    },
    21913: {
      lat: 39.40973,
      lng: -75.87073,
      city: 'Cecilton',
      state: 'MD',
    },
    21914: {
      lat: 39.57256,
      lng: -75.98158,
      city: 'Charlestown',
      state: 'MD',
    },
    21915: {
      lat: 39.50095,
      lng: -75.84764,
      city: 'Chesapeake City',
      state: 'MD',
    },
    21917: {
      lat: 39.67106,
      lng: -76.09823,
      city: 'Colora',
      state: 'MD',
    },
    21918: {
      lat: 39.68327,
      lng: -76.16662,
      city: 'Conowingo',
      state: 'MD',
    },
    21919: {
      lat: 39.42047,
      lng: -75.93372,
      city: 'Earleville',
      state: 'MD',
    },
    21920: {
      lat: 39.65675,
      lng: -75.83449,
      city: 'Elk Mills',
      state: 'MD',
    },
    21921: {
      lat: 39.62568,
      lng: -75.85904,
      city: 'Elkton',
      state: 'MD',
    },
    21930: {
      lat: 39.37198,
      lng: -75.89115,
      city: 'Georgetown',
      state: 'MD',
    },
    22003: {
      lat: 38.8308,
      lng: -77.21402,
      city: 'Annandale',
      state: 'VA',
    },
    22015: {
      lat: 38.78784,
      lng: -77.28136,
      city: 'Burke',
      state: 'VA',
    },
    22025: {
      lat: 38.59874,
      lng: -77.3491,
      city: 'Dumfries',
      state: 'VA',
    },
    22026: {
      lat: 38.56717,
      lng: -77.29476,
      city: 'Dumfries',
      state: 'VA',
    },
    22027: {
      lat: 38.89411,
      lng: -77.22159,
      city: 'Dunn Loring',
      state: 'VA',
    },
    22030: {
      lat: 38.83891,
      lng: -77.34188,
      city: 'Fairfax',
      state: 'VA',
    },
    22031: {
      lat: 38.85954,
      lng: -77.2599,
      city: 'Fairfax',
      state: 'VA',
    },
    22032: {
      lat: 38.82012,
      lng: -77.29032,
      city: 'Fairfax',
      state: 'VA',
    },
    22033: {
      lat: 38.87564,
      lng: -77.38419,
      city: 'Fairfax',
      state: 'VA',
    },
    22035: {
      lat: 38.85628,
      lng: -77.36269,
      city: 'Fairfax',
      state: 'VA',
    },
    22039: {
      lat: 38.74992,
      lng: -77.31536,
      city: 'Fairfax Station',
      state: 'VA',
    },
    22041: {
      lat: 38.8485,
      lng: -77.14212,
      city: 'Falls Church',
      state: 'VA',
    },
    22042: {
      lat: 38.86463,
      lng: -77.19406,
      city: 'Falls Church',
      state: 'VA',
    },
    22043: {
      lat: 38.90076,
      lng: -77.19746,
      city: 'Falls Church',
      state: 'VA',
    },
    22044: {
      lat: 38.85957,
      lng: -77.15627,
      city: 'Falls Church',
      state: 'VA',
    },
    22046: {
      lat: 38.88696,
      lng: -77.18025,
      city: 'Falls Church',
      state: 'VA',
    },
    22060: {
      lat: 38.70525,
      lng: -77.15636,
      city: 'Fort Belvoir',
      state: 'VA',
    },
    22066: {
      lat: 39.01032,
      lng: -77.30258,
      city: 'Great Falls',
      state: 'VA',
    },
    22079: {
      lat: 38.67998,
      lng: -77.2055,
      city: 'Lorton',
      state: 'VA',
    },
    22101: {
      lat: 38.9386,
      lng: -77.16513,
      city: 'McLean',
      state: 'VA',
    },
    22102: {
      lat: 38.95199,
      lng: -77.22901,
      city: 'McLean',
      state: 'VA',
    },
    22124: {
      lat: 38.8909,
      lng: -77.33025,
      city: 'Oakton',
      state: 'VA',
    },
    22125: {
      lat: 38.68217,
      lng: -77.26073,
      city: 'Occoquan',
      state: 'VA',
    },
    22134: {
      lat: 38.53186,
      lng: -77.409,
      city: 'Quantico',
      state: 'VA',
    },
    22150: {
      lat: 38.77198,
      lng: -77.18605,
      city: 'Springfield',
      state: 'VA',
    },
    22151: {
      lat: 38.80283,
      lng: -77.2096,
      city: 'Springfield',
      state: 'VA',
    },
    22152: {
      lat: 38.77564,
      lng: -77.23149,
      city: 'Springfield',
      state: 'VA',
    },
    22153: {
      lat: 38.74443,
      lng: -77.23451,
      city: 'Springfield',
      state: 'VA',
    },
    22172: {
      lat: 38.5742,
      lng: -77.3895,
      city: 'Triangle',
      state: 'VA',
    },
    22180: {
      lat: 38.89619,
      lng: -77.25426,
      city: 'Vienna',
      state: 'VA',
    },
    22181: {
      lat: 38.9053,
      lng: -77.29466,
      city: 'Vienna',
      state: 'VA',
    },
    22182: {
      lat: 38.93611,
      lng: -77.2736,
      city: 'Vienna',
      state: 'VA',
    },
    22191: {
      lat: 38.6249,
      lng: -77.26849,
      city: 'Woodbridge',
      state: 'VA',
    },
    22192: {
      lat: 38.68358,
      lng: -77.31821,
      city: 'Woodbridge',
      state: 'VA',
    },
    22193: {
      lat: 38.64233,
      lng: -77.34881,
      city: 'Woodbridge',
      state: 'VA',
    },
    22201: {
      lat: 38.88684,
      lng: -77.09501,
      city: 'Arlington',
      state: 'VA',
    },
    22202: {
      lat: 38.8569,
      lng: -77.05175,
      city: 'Arlington',
      state: 'VA',
    },
    22203: {
      lat: 38.87377,
      lng: -77.1164,
      city: 'Arlington',
      state: 'VA',
    },
    22204: {
      lat: 38.86076,
      lng: -77.09894,
      city: 'Arlington',
      state: 'VA',
    },
    22205: {
      lat: 38.88337,
      lng: -77.13948,
      city: 'Arlington',
      state: 'VA',
    },
    22206: {
      lat: 38.8442,
      lng: -77.0886,
      city: 'Arlington',
      state: 'VA',
    },
    22207: {
      lat: 38.90665,
      lng: -77.12384,
      city: 'Arlington',
      state: 'VA',
    },
    22209: {
      lat: 38.89413,
      lng: -77.07309,
      city: 'Arlington',
      state: 'VA',
    },
    22211: {
      lat: 38.88011,
      lng: -77.07482,
      city: 'Fort Myer',
      state: 'VA',
    },
    22213: {
      lat: 38.89459,
      lng: -77.16239,
      city: 'Arlington',
      state: 'VA',
    },
    22214: {
      lat: 38.87499,
      lng: -77.06501,
      city: 'Arlington',
      state: 'VA',
    },
    22301: {
      lat: 38.81995,
      lng: -77.05934,
      city: 'Alexandria',
      state: 'VA',
    },
    22302: {
      lat: 38.82799,
      lng: -77.08432,
      city: 'Alexandria',
      state: 'VA',
    },
    22303: {
      lat: 38.794,
      lng: -77.08175,
      city: 'Alexandria',
      state: 'VA',
    },
    22304: {
      lat: 38.81361,
      lng: -77.11498,
      city: 'Alexandria',
      state: 'VA',
    },
    22305: {
      lat: 38.83655,
      lng: -77.06234,
      city: 'Alexandria',
      state: 'VA',
    },
    22306: {
      lat: 38.75655,
      lng: -77.09254,
      city: 'Alexandria',
      state: 'VA',
    },
    22307: {
      lat: 38.7708,
      lng: -77.05694,
      city: 'Alexandria',
      state: 'VA',
    },
    22308: {
      lat: 38.72957,
      lng: -77.05876,
      city: 'Alexandria',
      state: 'VA',
    },
    22309: {
      lat: 38.71931,
      lng: -77.10724,
      city: 'Alexandria',
      state: 'VA',
    },
    22310: {
      lat: 38.78447,
      lng: -77.12231,
      city: 'Alexandria',
      state: 'VA',
    },
    22311: {
      lat: 38.83442,
      lng: -77.12313,
      city: 'Alexandria',
      state: 'VA',
    },
    22312: {
      lat: 38.81685,
      lng: -77.15372,
      city: 'Alexandria',
      state: 'VA',
    },
    22314: {
      lat: 38.80732,
      lng: -77.0563,
      city: 'Alexandria',
      state: 'VA',
    },
    22315: {
      lat: 38.75735,
      lng: -77.14803,
      city: 'Alexandria',
      state: 'VA',
    },
    22401: {
      lat: 38.29928,
      lng: -77.48704,
      city: 'Fredericksburg',
      state: 'VA',
    },
    22405: {
      lat: 38.31568,
      lng: -77.40377,
      city: 'Fredericksburg',
      state: 'VA',
    },
    22406: {
      lat: 38.39964,
      lng: -77.55596,
      city: 'Fredericksburg',
      state: 'VA',
    },
    22407: {
      lat: 38.28581,
      lng: -77.58055,
      city: 'Fredericksburg',
      state: 'VA',
    },
    22408: {
      lat: 38.21916,
      lng: -77.44013,
      city: 'Fredericksburg',
      state: 'VA',
    },
    22427: {
      lat: 38.07177,
      lng: -77.28624,
      city: 'Bowling Green',
      state: 'VA',
    },
    22432: {
      lat: 37.8637,
      lng: -76.35043,
      city: 'Burgess',
      state: 'VA',
    },
    22433: {
      lat: 38.35875,
      lng: -77.86093,
      city: 'Burr Hill',
      state: 'VA',
    },
    22435: {
      lat: 37.97216,
      lng: -76.57288,
      city: 'Callao',
      state: 'VA',
    },
    22436: {
      lat: 38.04698,
      lng: -77.07471,
      city: 'Caret',
      state: 'VA',
    },
    22437: {
      lat: 37.7764,
      lng: -76.8156,
      city: 'Center Cross',
      state: 'VA',
    },
    22438: {
      lat: 38.03788,
      lng: -76.99971,
      city: 'Champlain',
      state: 'VA',
    },
    22443: {
      lat: 38.18078,
      lng: -76.99776,
      city: 'Colonial Beach',
      state: 'VA',
    },
    22448: {
      lat: 38.33996,
      lng: -77.03238,
      city: 'Dahlgren',
      state: 'VA',
    },
    22454: {
      lat: 37.83599,
      lng: -76.85032,
      city: 'Dunnsville',
      state: 'VA',
    },
    22460: {
      lat: 37.87612,
      lng: -76.60692,
      city: 'Farnham',
      state: 'VA',
    },
    22469: { lat: 38.0667, lng: -76.64749, city: 'Hague', state: 'VA' },
    22472: {
      lat: 37.95897,
      lng: -76.67257,
      city: 'Haynesville',
      state: 'VA',
    },
    22473: {
      lat: 37.88316,
      lng: -76.41386,
      city: 'Heathsville',
      state: 'VA',
    },
    22476: {
      lat: 38.03807,
      lng: -77.06872,
      city: 'Hustle',
      state: 'VA',
    },
    22480: {
      lat: 37.66631,
      lng: -76.41498,
      city: 'Irvington',
      state: 'VA',
    },
    22482: {
      lat: 37.73723,
      lng: -76.35245,
      city: 'Kilmarnock',
      state: 'VA',
    },
    22485: {
      lat: 38.2717,
      lng: -77.15996,
      city: 'King George',
      state: 'VA',
    },
    22488: {
      lat: 38.04986,
      lng: -76.58796,
      city: 'Kinsale',
      state: 'VA',
    },
    22503: {
      lat: 37.7624,
      lng: -76.50432,
      city: 'Lancaster',
      state: 'VA',
    },
    22504: {
      lat: 37.76346,
      lng: -76.72675,
      city: 'Laneview',
      state: 'VA',
    },
    22508: {
      lat: 38.31732,
      lng: -77.7913,
      city: 'Locust Grove',
      state: 'VA',
    },
    22509: {
      lat: 38.10642,
      lng: -77.06669,
      city: 'Loretto',
      state: 'VA',
    },
    22511: {
      lat: 37.98352,
      lng: -76.50323,
      city: 'Lottsburg',
      state: 'VA',
    },
    22514: {
      lat: 37.9724,
      lng: -77.24471,
      city: 'Milford',
      state: 'VA',
    },
    22520: {
      lat: 38.11651,
      lng: -76.80634,
      city: 'Montross',
      state: 'VA',
    },
    22529: {
      lat: 38.01036,
      lng: -76.67942,
      city: 'Oldhams',
      state: 'VA',
    },
    22534: {
      lat: 38.08473,
      lng: -77.67062,
      city: 'Partlow',
      state: 'VA',
    },
    22535: {
      lat: 38.12314,
      lng: -77.19619,
      city: 'Port Royal',
      state: 'VA',
    },
    22538: {
      lat: 38.18941,
      lng: -77.27334,
      city: 'Rappahannock Academy',
      state: 'VA',
    },
    22539: {
      lat: 37.85865,
      lng: -76.28731,
      city: 'Reedville',
      state: 'VA',
    },
    22542: {
      lat: 38.29348,
      lng: -77.88837,
      city: 'Rhoadesville',
      state: 'VA',
    },
    22546: {
      lat: 37.94342,
      lng: -77.44565,
      city: 'Ruther Glen',
      state: 'VA',
    },
    22548: {
      lat: 37.82929,
      lng: -76.69256,
      city: 'Sharps',
      state: 'VA',
    },
    22551: {
      lat: 38.17273,
      lng: -77.70575,
      city: 'Spotsylvania',
      state: 'VA',
    },
    22553: {
      lat: 38.26594,
      lng: -77.6397,
      city: 'Spotsylvania',
      state: 'VA',
    },
    22554: {
      lat: 38.42285,
      lng: -77.3835,
      city: 'Stafford',
      state: 'VA',
    },
    22556: {
      lat: 38.48624,
      lng: -77.513,
      city: 'Stafford',
      state: 'VA',
    },
    22560: {
      lat: 37.91289,
      lng: -76.96196,
      city: 'Tappahannock',
      state: 'VA',
    },
    22567: {
      lat: 38.23395,
      lng: -77.92401,
      city: 'Unionville',
      state: 'VA',
    },
    22572: {
      lat: 37.97552,
      lng: -76.77495,
      city: 'Warsaw',
      state: 'VA',
    },
    22576: {
      lat: 37.69398,
      lng: -76.43277,
      city: 'Weems',
      state: 'VA',
    },
    22578: {
      lat: 37.65063,
      lng: -76.36268,
      city: 'White Stone',
      state: 'VA',
    },
    22580: {
      lat: 38.10941,
      lng: -77.44419,
      city: 'Woodford',
      state: 'VA',
    },
    22601: {
      lat: 39.1743,
      lng: -78.1743,
      city: 'Winchester',
      state: 'VA',
    },
    22602: {
      lat: 39.1508,
      lng: -78.26555,
      city: 'Winchester',
      state: 'VA',
    },
    22603: {
      lat: 39.28153,
      lng: -78.2056,
      city: 'Winchester',
      state: 'VA',
    },
    22610: {
      lat: 38.81803,
      lng: -78.28102,
      city: 'Bentonville',
      state: 'VA',
    },
    22611: {
      lat: 39.16374,
      lng: -77.98512,
      city: 'Berryville',
      state: 'VA',
    },
    22620: {
      lat: 39.06417,
      lng: -78.03317,
      city: 'Boyce',
      state: 'VA',
    },
    22623: {
      lat: 38.85158,
      lng: -78.13734,
      city: 'Chester Gap',
      state: 'VA',
    },
    22624: {
      lat: 39.26535,
      lng: -78.09507,
      city: 'Clear Brook',
      state: 'VA',
    },
    22625: {
      lat: 39.37397,
      lng: -78.31172,
      city: 'Cross Junction',
      state: 'VA',
    },
    22627: {
      lat: 38.76554,
      lng: -78.11371,
      city: 'Flint Hill',
      state: 'VA',
    },
    22630: {
      lat: 38.92909,
      lng: -78.18052,
      city: 'Front Royal',
      state: 'VA',
    },
    22637: { lat: 39.25057, lng: -78.3605, city: 'Gore', state: 'VA' },
    22639: { lat: 38.82625, lng: -78.0321, city: 'Hume', state: 'VA' },
    22640: {
      lat: 38.80737,
      lng: -78.13302,
      city: 'Huntly',
      state: 'VA',
    },
    22641: {
      lat: 39.07795,
      lng: -78.38588,
      city: 'Strasburg',
      state: 'VA',
    },
    22642: {
      lat: 38.91532,
      lng: -78.06538,
      city: 'Linden',
      state: 'VA',
    },
    22643: {
      lat: 38.9034,
      lng: -78.00801,
      city: 'Markham',
      state: 'VA',
    },
    22644: {
      lat: 38.96362,
      lng: -78.5314,
      city: 'Maurertown',
      state: 'VA',
    },
    22645: {
      lat: 39.02152,
      lng: -78.27457,
      city: 'Middletown',
      state: 'VA',
    },
    22646: {
      lat: 39.07019,
      lng: -78.04803,
      city: 'Millwood',
      state: 'VA',
    },
    22650: {
      lat: 38.75893,
      lng: -78.35817,
      city: 'Rileyville',
      state: 'VA',
    },
    22652: {
      lat: 38.83921,
      lng: -78.42894,
      city: 'Fort Valley',
      state: 'VA',
    },
    22654: {
      lat: 39.06863,
      lng: -78.4586,
      city: 'Star Tannery',
      state: 'VA',
    },
    22655: {
      lat: 39.06443,
      lng: -78.21425,
      city: 'Stephens City',
      state: 'VA',
    },
    22656: {
      lat: 39.21723,
      lng: -78.08901,
      city: 'Stephenson',
      state: 'VA',
    },
    22657: {
      lat: 39.00155,
      lng: -78.36755,
      city: 'Strasburg',
      state: 'VA',
    },
    22660: {
      lat: 38.95628,
      lng: -78.4383,
      city: 'Toms Brook',
      state: 'VA',
    },
    22663: {
      lat: 39.05,
      lng: -78.11716,
      city: 'White Post',
      state: 'VA',
    },
    22664: {
      lat: 38.8944,
      lng: -78.53119,
      city: 'Woodstock',
      state: 'VA',
    },
    22701: {
      lat: 38.44038,
      lng: -77.99633,
      city: 'Culpeper',
      state: 'VA',
    },
    22709: {
      lat: 38.32769,
      lng: -78.21732,
      city: 'Aroda',
      state: 'VA',
    },
    22711: {
      lat: 38.46239,
      lng: -78.27634,
      city: 'Banco',
      state: 'VA',
    },
    22712: {
      lat: 38.55632,
      lng: -77.75884,
      city: 'Bealeton',
      state: 'VA',
    },
    22713: {
      lat: 38.54123,
      lng: -78.13204,
      city: 'Boston',
      state: 'VA',
    },
    22714: {
      lat: 38.52068,
      lng: -77.89363,
      city: 'Brandy Station',
      state: 'VA',
    },
    22715: {
      lat: 38.41032,
      lng: -78.18541,
      city: 'Brightwood',
      state: 'VA',
    },
    22716: {
      lat: 38.62596,
      lng: -78.10794,
      city: 'Castleton',
      state: 'VA',
    },
    22718: {
      lat: 38.47076,
      lng: -77.82026,
      city: 'Elkwood',
      state: 'VA',
    },
    22719: {
      lat: 38.52888,
      lng: -78.28337,
      city: 'Etlan',
      state: 'VA',
    },
    22720: {
      lat: 38.48044,
      lng: -77.6468,
      city: 'Goldvein',
      state: 'VA',
    },
    22722: {
      lat: 38.46304,
      lng: -78.22645,
      city: 'Haywood',
      state: 'VA',
    },
    22723: { lat: 38.33789, lng: -78.38821, city: 'Hood', state: 'VA' },
    22724: {
      lat: 38.63286,
      lng: -77.90752,
      city: 'Jeffersonton',
      state: 'VA',
    },
    22725: { lat: 38.44029, lng: -78.1527, city: 'Leon', state: 'VA' },
    22726: {
      lat: 38.40179,
      lng: -77.81796,
      city: 'Lignum',
      state: 'VA',
    },
    22727: {
      lat: 38.39626,
      lng: -78.30058,
      city: 'Madison',
      state: 'VA',
    },
    22728: {
      lat: 38.57773,
      lng: -77.68639,
      city: 'Midland',
      state: 'VA',
    },
    22729: {
      lat: 38.35658,
      lng: -78.01596,
      city: 'Mitchells',
      state: 'VA',
    },
    22730: {
      lat: 38.36782,
      lng: -78.16635,
      city: 'Oakpark',
      state: 'VA',
    },
    22731: {
      lat: 38.34413,
      lng: -78.26151,
      city: 'Pratts',
      state: 'VA',
    },
    22732: {
      lat: 38.30886,
      lng: -78.19153,
      city: 'Radiant',
      state: 'VA',
    },
    22733: {
      lat: 38.33191,
      lng: -78.0467,
      city: 'Rapidan',
      state: 'VA',
    },
    22734: {
      lat: 38.52757,
      lng: -77.81119,
      city: 'Remington',
      state: 'VA',
    },
    22735: { lat: 38.47941, lng: -78.18336, city: 'Reva', state: 'VA' },
    22736: {
      lat: 38.39633,
      lng: -77.69673,
      city: 'Richardsville',
      state: 'VA',
    },
    22737: {
      lat: 38.59024,
      lng: -78.00205,
      city: 'Rixeyville',
      state: 'VA',
    },
    22738: {
      lat: 38.28692,
      lng: -78.27685,
      city: 'Rochelle',
      state: 'VA',
    },
    22740: {
      lat: 38.63805,
      lng: -78.26163,
      city: 'Sperryville',
      state: 'VA',
    },
    22741: {
      lat: 38.44541,
      lng: -77.86819,
      city: 'Stevensburg',
      state: 'VA',
    },
    22742: {
      lat: 38.4589,
      lng: -77.71179,
      city: 'Sumerduck',
      state: 'VA',
    },
    22743: {
      lat: 38.52477,
      lng: -78.37132,
      city: 'Syria',
      state: 'VA',
    },
    22746: {
      lat: 38.62922,
      lng: -78.0195,
      city: 'Viewtown',
      state: 'VA',
    },
    22747: {
      lat: 38.7122,
      lng: -78.16768,
      city: 'Washington',
      state: 'VA',
    },
    22749: {
      lat: 38.61945,
      lng: -78.18394,
      city: 'Woodville',
      state: 'VA',
    },
    22801: {
      lat: 38.40519,
      lng: -78.87931,
      city: 'Harrisonburg',
      state: 'VA',
    },
    22802: {
      lat: 38.50016,
      lng: -78.8585,
      city: 'Harrisonburg',
      state: 'VA',
    },
    22807: {
      lat: 38.43495,
      lng: -78.86736,
      city: 'Harrisonburg',
      state: 'VA',
    },
    22810: {
      lat: 38.84466,
      lng: -78.78554,
      city: 'Basye',
      state: 'VA',
    },
    22811: {
      lat: 38.79264,
      lng: -78.9757,
      city: 'Bergton',
      state: 'VA',
    },
    22812: {
      lat: 38.37934,
      lng: -79.03836,
      city: 'Bridgewater',
      state: 'VA',
    },
    22815: {
      lat: 38.63469,
      lng: -78.80939,
      city: 'Broadway',
      state: 'VA',
    },
    22820: {
      lat: 38.74387,
      lng: -79.00948,
      city: 'Criders',
      state: 'VA',
    },
    22821: {
      lat: 38.46349,
      lng: -79.0743,
      city: 'Dayton',
      state: 'VA',
    },
    22824: {
      lat: 38.84743,
      lng: -78.6278,
      city: 'Edinburg',
      state: 'VA',
    },
    22827: { lat: 38.3903, lng: -78.6131, city: 'Elkton', state: 'VA' },
    22830: {
      lat: 38.65789,
      lng: -78.97902,
      city: 'Fulks Run',
      state: 'VA',
    },
    22831: {
      lat: 38.56395,
      lng: -79.10005,
      city: 'Hinton',
      state: 'VA',
    },
    22832: {
      lat: 38.45778,
      lng: -78.75692,
      city: 'Keezletown',
      state: 'VA',
    },
    22834: {
      lat: 38.55709,
      lng: -78.85769,
      city: 'Linville',
      state: 'VA',
    },
    22835: { lat: 38.67199, lng: -78.4548, city: 'Luray', state: 'VA' },
    22840: {
      lat: 38.38642,
      lng: -78.73845,
      city: 'McGaheysville',
      state: 'VA',
    },
    22841: {
      lat: 38.33887,
      lng: -78.89417,
      city: 'Mount Crawford',
      state: 'VA',
    },
    22842: {
      lat: 38.77191,
      lng: -78.6992,
      city: 'Mount Jackson',
      state: 'VA',
    },
    22843: {
      lat: 38.36703,
      lng: -79.16053,
      city: 'Mount Solon',
      state: 'VA',
    },
    22844: {
      lat: 38.65886,
      lng: -78.66038,
      city: 'New Market',
      state: 'VA',
    },
    22845: {
      lat: 38.77991,
      lng: -78.8375,
      city: 'Orkney Springs',
      state: 'VA',
    },
    22846: {
      lat: 38.38791,
      lng: -78.79239,
      city: 'Penn Laird',
      state: 'VA',
    },
    22847: {
      lat: 38.71792,
      lng: -78.70972,
      city: 'Quicksburg',
      state: 'VA',
    },
    22849: {
      lat: 38.5256,
      lng: -78.6253,
      city: 'Shenandoah',
      state: 'VA',
    },
    22850: {
      lat: 38.56668,
      lng: -78.92048,
      city: 'Singers Glen',
      state: 'VA',
    },
    22851: {
      lat: 38.56061,
      lng: -78.51061,
      city: 'Stanley',
      state: 'VA',
    },
    22853: {
      lat: 38.65156,
      lng: -78.76406,
      city: 'Timberville',
      state: 'VA',
    },
    22901: {
      lat: 38.08874,
      lng: -78.55569,
      city: 'Charlottesville',
      state: 'VA',
    },
    22902: {
      lat: 37.95566,
      lng: -78.46883,
      city: 'Charlottesville',
      state: 'VA',
    },
    22903: {
      lat: 38.01239,
      lng: -78.60159,
      city: 'Charlottesville',
      state: 'VA',
    },
    22904: {
      lat: 38.03492,
      lng: -78.51161,
      city: 'Charlottesville',
      state: 'VA',
    },
    22908: {
      lat: 38.03103,
      lng: -78.49639,
      city: 'Charlottesville',
      state: 'VA',
    },
    22911: {
      lat: 38.09522,
      lng: -78.4078,
      city: 'Charlottesville',
      state: 'VA',
    },
    22920: {
      lat: 37.97294,
      lng: -78.81276,
      city: 'Afton',
      state: 'VA',
    },
    22922: {
      lat: 37.68621,
      lng: -78.92511,
      city: 'Arrington',
      state: 'VA',
    },
    22923: {
      lat: 38.18671,
      lng: -78.32405,
      city: 'Barboursville',
      state: 'VA',
    },
    22931: {
      lat: 37.90374,
      lng: -78.71696,
      city: 'Covesville',
      state: 'VA',
    },
    22932: {
      lat: 38.15037,
      lng: -78.69743,
      city: 'Crozet',
      state: 'VA',
    },
    22935: { lat: 38.25785, lng: -78.55474, city: 'Dyke', state: 'VA' },
    22936: {
      lat: 38.1671,
      lng: -78.49974,
      city: 'Earlysville',
      state: 'VA',
    },
    22937: {
      lat: 37.81297,
      lng: -78.60891,
      city: 'Esmont',
      state: 'VA',
    },
    22938: {
      lat: 37.86256,
      lng: -78.78248,
      city: 'Faber',
      state: 'VA',
    },
    22939: {
      lat: 38.10575,
      lng: -78.97288,
      city: 'Fishersville',
      state: 'VA',
    },
    22940: {
      lat: 38.20559,
      lng: -78.59705,
      city: 'Free Union',
      state: 'VA',
    },
    22942: {
      lat: 38.10397,
      lng: -78.19684,
      city: 'Gordonsville',
      state: 'VA',
    },
    22943: {
      lat: 38.04508,
      lng: -78.78369,
      city: 'Greenwood',
      state: 'VA',
    },
    22946: {
      lat: 37.84789,
      lng: -78.56053,
      city: 'Keene',
      state: 'VA',
    },
    22947: {
      lat: 38.04326,
      lng: -78.32624,
      city: 'Keswick',
      state: 'VA',
    },
    22948: {
      lat: 38.35154,
      lng: -78.12264,
      city: 'Locust Dale',
      state: 'VA',
    },
    22949: {
      lat: 37.788,
      lng: -78.88257,
      city: 'Lovingston',
      state: 'VA',
    },
    22952: {
      lat: 37.95836,
      lng: -78.97136,
      city: 'Lyndhurst',
      state: 'VA',
    },
    22957: {
      lat: 38.22998,
      lng: -78.1918,
      city: 'Montpelier Station',
      state: 'VA',
    },
    22958: {
      lat: 37.8983,
      lng: -78.89611,
      city: 'Nellysford',
      state: 'VA',
    },
    22959: {
      lat: 37.93758,
      lng: -78.64794,
      city: 'North Garden',
      state: 'VA',
    },
    22960: {
      lat: 38.21992,
      lng: -78.06624,
      city: 'Orange',
      state: 'VA',
    },
    22963: {
      lat: 37.84793,
      lng: -78.28524,
      city: 'Palmyra',
      state: 'VA',
    },
    22964: {
      lat: 37.71722,
      lng: -78.98861,
      city: 'Piney River',
      state: 'VA',
    },
    22967: {
      lat: 37.79945,
      lng: -79.0192,
      city: 'Roseland',
      state: 'VA',
    },
    22968: {
      lat: 38.24111,
      lng: -78.39407,
      city: 'Ruckersville',
      state: 'VA',
    },
    22969: {
      lat: 37.79699,
      lng: -78.69367,
      city: 'Schuyler',
      state: 'VA',
    },
    22971: {
      lat: 37.75019,
      lng: -78.80299,
      city: 'Shipman',
      state: 'VA',
    },
    22972: {
      lat: 38.21746,
      lng: -78.23451,
      city: 'Somerset',
      state: 'VA',
    },
    22973: {
      lat: 38.33588,
      lng: -78.46915,
      city: 'Stanardsville',
      state: 'VA',
    },
    22974: { lat: 37.96207, lng: -78.2753, city: 'Troy', state: 'VA' },
    22976: { lat: 37.84555, lng: -79.04036, city: 'Tyro', state: 'VA' },
    22980: {
      lat: 38.09588,
      lng: -78.88585,
      city: 'Waynesboro',
      state: 'VA',
    },
    22989: {
      lat: 38.29328,
      lng: -78.11943,
      city: 'Woodberry Forest',
      state: 'VA',
    },
    23002: {
      lat: 37.34917,
      lng: -77.96357,
      city: 'Amelia Court House',
      state: 'VA',
    },
    23004: {
      lat: 37.68307,
      lng: -78.40567,
      city: 'Arvonia',
      state: 'VA',
    },
    23005: {
      lat: 37.75982,
      lng: -77.4826,
      city: 'Ashland',
      state: 'VA',
    },
    23009: {
      lat: 37.81038,
      lng: -77.18717,
      city: 'Aylett',
      state: 'VA',
    },
    23011: {
      lat: 37.47562,
      lng: -76.80691,
      city: 'Barhamsville',
      state: 'VA',
    },
    23015: {
      lat: 37.93127,
      lng: -77.63124,
      city: 'Beaverdam',
      state: 'VA',
    },
    23021: {
      lat: 37.40102,
      lng: -76.37187,
      city: 'Bohannon',
      state: 'VA',
    },
    23022: {
      lat: 37.7353,
      lng: -78.26432,
      city: 'Bremo Bluff',
      state: 'VA',
    },
    23023: {
      lat: 37.78293,
      lng: -76.96586,
      city: 'Bruington',
      state: 'VA',
    },
    23024: {
      lat: 37.92448,
      lng: -77.77554,
      city: 'Bumpass',
      state: 'VA',
    },
    23025: {
      lat: 37.41447,
      lng: -76.36204,
      city: 'Cardinal',
      state: 'VA',
    },
    23027: {
      lat: 37.64551,
      lng: -78.13183,
      city: 'Cartersville',
      state: 'VA',
    },
    23030: {
      lat: 37.34652,
      lng: -77.05962,
      city: 'Charles City',
      state: 'VA',
    },
    23032: {
      lat: 37.65552,
      lng: -76.68199,
      city: 'Church View',
      state: 'VA',
    },
    23035: {
      lat: 37.49809,
      lng: -76.37679,
      city: 'Cobbs Creek',
      state: 'VA',
    },
    23038: {
      lat: 37.75627,
      lng: -78.14552,
      city: 'Columbia',
      state: 'VA',
    },
    23039: {
      lat: 37.65503,
      lng: -77.80596,
      city: 'Crozier',
      state: 'VA',
    },
    23040: {
      lat: 37.51017,
      lng: -78.24919,
      city: 'Cumberland',
      state: 'VA',
    },
    23043: {
      lat: 37.55519,
      lng: -76.34357,
      city: 'Deltaville',
      state: 'VA',
    },
    23045: {
      lat: 37.42937,
      lng: -76.27069,
      city: 'Diggs',
      state: 'VA',
    },
    23047: {
      lat: 37.84765,
      lng: -77.48973,
      city: 'Doswell',
      state: 'VA',
    },
    23050: {
      lat: 37.48918,
      lng: -76.41986,
      city: 'Dutton',
      state: 'VA',
    },
    23055: {
      lat: 37.7706,
      lng: -78.21852,
      city: 'Fork Union',
      state: 'VA',
    },
    23056: {
      lat: 37.41809,
      lng: -76.38724,
      city: 'Foster',
      state: 'VA',
    },
    23059: {
      lat: 37.70424,
      lng: -77.56539,
      city: 'Glen Allen',
      state: 'VA',
    },
    23060: {
      lat: 37.65981,
      lng: -77.53427,
      city: 'Glen Allen',
      state: 'VA',
    },
    23061: {
      lat: 37.4331,
      lng: -76.55019,
      city: 'Gloucester',
      state: 'VA',
    },
    23062: {
      lat: 37.258,
      lng: -76.50404,
      city: 'Gloucester Point',
      state: 'VA',
    },
    23063: {
      lat: 37.71694,
      lng: -77.99882,
      city: 'Goochland',
      state: 'VA',
    },
    23065: {
      lat: 37.8016,
      lng: -77.92979,
      city: 'Gum Spring',
      state: 'VA',
    },
    23066: {
      lat: 37.49836,
      lng: -76.28831,
      city: 'Gwynn',
      state: 'VA',
    },
    23068: {
      lat: 37.49511,
      lng: -76.33727,
      city: 'Hallieford',
      state: 'VA',
    },
    23069: {
      lat: 37.76924,
      lng: -77.32874,
      city: 'Hanover',
      state: 'VA',
    },
    23070: {
      lat: 37.55065,
      lng: -76.38528,
      city: 'Hardyville',
      state: 'VA',
    },
    23071: {
      lat: 37.54326,
      lng: -76.45228,
      city: 'Hartfield',
      state: 'VA',
    },
    23072: {
      lat: 37.29543,
      lng: -76.48148,
      city: 'Hayes',
      state: 'VA',
    },
    23075: {
      lat: 37.54938,
      lng: -77.31591,
      city: 'Henrico',
      state: 'VA',
    },
    23076: {
      lat: 37.47328,
      lng: -76.30499,
      city: 'Hudgins',
      state: 'VA',
    },
    23079: {
      lat: 37.7248,
      lng: -76.68941,
      city: 'Jamaica',
      state: 'VA',
    },
    23081: {
      lat: 37.20758,
      lng: -76.7617,
      city: 'Jamestown',
      state: 'VA',
    },
    23083: {
      lat: 37.31899,
      lng: -78.11111,
      city: 'Jetersville',
      state: 'VA',
    },
    23084: {
      lat: 37.8864,
      lng: -78.11162,
      city: 'Kents Store',
      state: 'VA',
    },
    23085: {
      lat: 37.70623,
      lng: -76.84205,
      city: 'King And Queen Court House',
      state: 'VA',
    },
    23086: {
      lat: 37.66296,
      lng: -77.05394,
      city: 'King William',
      state: 'VA',
    },
    23089: {
      lat: 37.47593,
      lng: -76.91212,
      city: 'Lanexa',
      state: 'VA',
    },
    23091: {
      lat: 37.64647,
      lng: -76.80167,
      city: 'Little Plymouth',
      state: 'VA',
    },
    23092: {
      lat: 37.59402,
      lng: -76.50755,
      city: 'Locust Hill',
      state: 'VA',
    },
    23093: {
      lat: 38.00153,
      lng: -78.03595,
      city: 'Louisa',
      state: 'VA',
    },
    23102: {
      lat: 37.71053,
      lng: -77.83325,
      city: 'Maidens',
      state: 'VA',
    },
    23103: {
      lat: 37.6506,
      lng: -77.72754,
      city: 'Manakin Sabot',
      state: 'VA',
    },
    23106: {
      lat: 37.71524,
      lng: -77.2062,
      city: 'Manquin',
      state: 'VA',
    },
    23108: {
      lat: 37.65523,
      lng: -76.73015,
      city: 'Mascot',
      state: 'VA',
    },
    23109: {
      lat: 37.43263,
      lng: -76.32445,
      city: 'Mathews',
      state: 'VA',
    },
    23110: {
      lat: 37.58338,
      lng: -76.76578,
      city: 'Mattaponi',
      state: 'VA',
    },
    23111: {
      lat: 37.6112,
      lng: -77.24697,
      city: 'Mechanicsville',
      state: 'VA',
    },
    23112: {
      lat: 37.43738,
      lng: -77.66394,
      city: 'Midlothian',
      state: 'VA',
    },
    23113: {
      lat: 37.53935,
      lng: -77.68093,
      city: 'Midlothian',
      state: 'VA',
    },
    23114: {
      lat: 37.48317,
      lng: -77.66054,
      city: 'Midlothian',
      state: 'VA',
    },
    23116: {
      lat: 37.67152,
      lng: -77.33458,
      city: 'Mechanicsville',
      state: 'VA',
    },
    23117: {
      lat: 37.97736,
      lng: -77.87365,
      city: 'Mineral',
      state: 'VA',
    },
    23119: { lat: 37.45139, lng: -76.27978, city: 'Moon', state: 'VA' },
    23120: {
      lat: 37.40872,
      lng: -77.78468,
      city: 'Moseley',
      state: 'VA',
    },
    23123: {
      lat: 37.65238,
      lng: -78.3031,
      city: 'New Canton',
      state: 'VA',
    },
    23124: {
      lat: 37.55271,
      lng: -77.05949,
      city: 'New Kent',
      state: 'VA',
    },
    23126: {
      lat: 37.92285,
      lng: -77.1448,
      city: 'Newtown',
      state: 'VA',
    },
    23128: { lat: 37.4514, lng: -76.43216, city: 'North', state: 'VA' },
    23129: {
      lat: 37.705,
      lng: -77.78261,
      city: 'Oilville',
      state: 'VA',
    },
    23130: {
      lat: 37.39048,
      lng: -76.26494,
      city: 'Onemo',
      state: 'VA',
    },
    23138: {
      lat: 37.36245,
      lng: -76.30062,
      city: 'Port Haywood',
      state: 'VA',
    },
    23139: {
      lat: 37.54999,
      lng: -77.93507,
      city: 'Powhatan',
      state: 'VA',
    },
    23140: {
      lat: 37.43852,
      lng: -77.04818,
      city: 'Providence Forge',
      state: 'VA',
    },
    23141: {
      lat: 37.53042,
      lng: -77.15791,
      city: 'Quinton',
      state: 'VA',
    },
    23146: {
      lat: 37.7273,
      lng: -77.70928,
      city: 'Rockville',
      state: 'VA',
    },
    23147: {
      lat: 37.36965,
      lng: -77.0391,
      city: 'Ruthville',
      state: 'VA',
    },
    23148: {
      lat: 37.84262,
      lng: -77.06223,
      city: 'Saint Stephens Church',
      state: 'VA',
    },
    23149: {
      lat: 37.56794,
      lng: -76.61599,
      city: 'Saluda',
      state: 'VA',
    },
    23150: {
      lat: 37.50718,
      lng: -77.25402,
      city: 'Sandston',
      state: 'VA',
    },
    23153: {
      lat: 37.77641,
      lng: -77.95719,
      city: 'Sandy Hook',
      state: 'VA',
    },
    23156: {
      lat: 37.5244,
      lng: -76.71106,
      city: 'Shacklefords',
      state: 'VA',
    },
    23160: {
      lat: 37.62757,
      lng: -77.84028,
      city: 'State Farm',
      state: 'VA',
    },
    23161: {
      lat: 37.72152,
      lng: -76.93037,
      city: 'Stevensville',
      state: 'VA',
    },
    23163: { lat: 37.3572, lng: -76.29765, city: 'Susan', state: 'VA' },
    23168: { lat: 37.3942, lng: -76.832, city: 'Toano', state: 'VA' },
    23169: {
      lat: 37.58945,
      lng: -76.46225,
      city: 'Topping',
      state: 'VA',
    },
    23173: {
      lat: 37.57677,
      lng: -77.54069,
      city: 'Richmond',
      state: 'VA',
    },
    23175: {
      lat: 37.65343,
      lng: -76.62407,
      city: 'Urbanna',
      state: 'VA',
    },
    23176: { lat: 37.56934, lng: -76.41991, city: 'Wake', state: 'VA' },
    23177: {
      lat: 37.73088,
      lng: -77.00295,
      city: 'Walkerton',
      state: 'VA',
    },
    23180: {
      lat: 37.69977,
      lng: -76.62045,
      city: 'Water View',
      state: 'VA',
    },
    23181: {
      lat: 37.59809,
      lng: -76.889,
      city: 'West Point',
      state: 'VA',
    },
    23185: {
      lat: 37.24927,
      lng: -76.68702,
      city: 'Williamsburg',
      state: 'VA',
    },
    23188: {
      lat: 37.33849,
      lng: -76.74925,
      city: 'Williamsburg',
      state: 'VA',
    },
    23192: {
      lat: 37.81732,
      lng: -77.68209,
      city: 'Montpelier',
      state: 'VA',
    },
    23219: {
      lat: 37.54076,
      lng: -77.43484,
      city: 'Richmond',
      state: 'VA',
    },
    23220: {
      lat: 37.54923,
      lng: -77.46012,
      city: 'Richmond',
      state: 'VA',
    },
    23221: {
      lat: 37.5533,
      lng: -77.49056,
      city: 'Richmond',
      state: 'VA',
    },
    23222: {
      lat: 37.58265,
      lng: -77.41894,
      city: 'Richmond',
      state: 'VA',
    },
    23223: {
      lat: 37.55595,
      lng: -77.37927,
      city: 'Richmond',
      state: 'VA',
    },
    23224: {
      lat: 37.4985,
      lng: -77.46563,
      city: 'Richmond',
      state: 'VA',
    },
    23225: {
      lat: 37.51872,
      lng: -77.51044,
      city: 'Richmond',
      state: 'VA',
    },
    23226: {
      lat: 37.57774,
      lng: -77.5198,
      city: 'Richmond',
      state: 'VA',
    },
    23227: {
      lat: 37.61486,
      lng: -77.44234,
      city: 'Richmond',
      state: 'VA',
    },
    23228: {
      lat: 37.62588,
      lng: -77.49125,
      city: 'Henrico',
      state: 'VA',
    },
    23229: {
      lat: 37.58785,
      lng: -77.56973,
      city: 'Henrico',
      state: 'VA',
    },
    23230: {
      lat: 37.587,
      lng: -77.49153,
      city: 'Richmond',
      state: 'VA',
    },
    23231: {
      lat: 37.44219,
      lng: -77.31903,
      city: 'Henrico',
      state: 'VA',
    },
    23233: {
      lat: 37.64461,
      lng: -77.62828,
      city: 'Henrico',
      state: 'VA',
    },
    23234: {
      lat: 37.45293,
      lng: -77.46752,
      city: 'Richmond',
      state: 'VA',
    },
    23235: {
      lat: 37.51314,
      lng: -77.56426,
      city: 'Richmond',
      state: 'VA',
    },
    23236: {
      lat: 37.47438,
      lng: -77.58634,
      city: 'Richmond',
      state: 'VA',
    },
    23237: {
      lat: 37.40173,
      lng: -77.45433,
      city: 'Richmond',
      state: 'VA',
    },
    23238: {
      lat: 37.59595,
      lng: -77.64138,
      city: 'Henrico',
      state: 'VA',
    },
    23250: {
      lat: 37.50517,
      lng: -77.32098,
      city: 'Richmond',
      state: 'VA',
    },
    23284: {
      lat: 37.54759,
      lng: -77.45273,
      city: 'Richmond',
      state: 'VA',
    },
    23294: {
      lat: 37.63048,
      lng: -77.54205,
      city: 'Henrico',
      state: 'VA',
    },
    23298: {
      lat: 37.5416,
      lng: -77.42954,
      city: 'Richmond',
      state: 'VA',
    },
    23301: {
      lat: 37.68402,
      lng: -75.6397,
      city: 'Accomac',
      state: 'VA',
    },
    23302: {
      lat: 37.85858,
      lng: -75.52197,
      city: 'Assawoman',
      state: 'VA',
    },
    23303: {
      lat: 37.90346,
      lng: -75.50562,
      city: 'Atlantic',
      state: 'VA',
    },
    23304: {
      lat: 36.99586,
      lng: -76.56993,
      city: 'Battery Park',
      state: 'VA',
    },
    23306: {
      lat: 37.56859,
      lng: -75.88148,
      city: 'Belle Haven',
      state: 'VA',
    },
    23307: {
      lat: 37.43282,
      lng: -75.87095,
      city: 'Birdsnest',
      state: 'VA',
    },
    23308: {
      lat: 37.82431,
      lng: -75.61773,
      city: 'Bloxom',
      state: 'VA',
    },
    23310: {
      lat: 37.26407,
      lng: -75.95676,
      city: 'Cape Charles',
      state: 'VA',
    },
    23314: {
      lat: 36.95054,
      lng: -76.53625,
      city: 'Carrollton',
      state: 'VA',
    },
    23315: {
      lat: 36.73861,
      lng: -76.84289,
      city: 'Carrsville',
      state: 'VA',
    },
    23316: {
      lat: 37.29517,
      lng: -75.96635,
      city: 'Cheriton',
      state: 'VA',
    },
    23320: {
      lat: 36.75208,
      lng: -76.21807,
      city: 'Chesapeake',
      state: 'VA',
    },
    23321: {
      lat: 36.80067,
      lng: -76.42189,
      city: 'Chesapeake',
      state: 'VA',
    },
    23322: {
      lat: 36.62703,
      lng: -76.26929,
      city: 'Chesapeake',
      state: 'VA',
    },
    23323: {
      lat: 36.70785,
      lng: -76.37949,
      city: 'Chesapeake',
      state: 'VA',
    },
    23324: {
      lat: 36.79996,
      lng: -76.27517,
      city: 'Chesapeake',
      state: 'VA',
    },
    23325: {
      lat: 36.81507,
      lng: -76.239,
      city: 'Chesapeake',
      state: 'VA',
    },
    23336: {
      lat: 37.95461,
      lng: -75.33759,
      city: 'Chincoteague Island',
      state: 'VA',
    },
    23337: {
      lat: 37.93332,
      lng: -75.46691,
      city: 'Wallops Island',
      state: 'VA',
    },
    23347: {
      lat: 37.36026,
      lng: -75.96798,
      city: 'Eastville',
      state: 'VA',
    },
    23350: {
      lat: 37.51489,
      lng: -75.87428,
      city: 'Exmore',
      state: 'VA',
    },
    23354: {
      lat: 37.47806,
      lng: -75.90225,
      city: 'Franktown',
      state: 'VA',
    },
    23356: {
      lat: 37.9905,
      lng: -75.39228,
      city: 'Greenbackville',
      state: 'VA',
    },
    23357: {
      lat: 37.75862,
      lng: -75.67459,
      city: 'Greenbush',
      state: 'VA',
    },
    23358: {
      lat: 37.64406,
      lng: -75.86495,
      city: 'Hacksneck',
      state: 'VA',
    },
    23359: {
      lat: 37.88643,
      lng: -75.60422,
      city: 'Hallwood',
      state: 'VA',
    },
    23389: {
      lat: 37.65731,
      lng: -75.83226,
      city: 'Harborton',
      state: 'VA',
    },
    23395: {
      lat: 37.97051,
      lng: -75.45159,
      city: 'Horntown',
      state: 'VA',
    },
    23398: {
      lat: 37.52054,
      lng: -75.93971,
      city: 'Jamesville',
      state: 'VA',
    },
    23401: {
      lat: 37.62776,
      lng: -75.77843,
      city: 'Keller',
      state: 'VA',
    },
    23405: {
      lat: 37.41466,
      lng: -75.91969,
      city: 'Machipongo',
      state: 'VA',
    },
    23407: {
      lat: 37.8432,
      lng: -75.55362,
      city: 'Mappsville',
      state: 'VA',
    },
    23408: {
      lat: 37.4533,
      lng: -75.84587,
      city: 'Marionville',
      state: 'VA',
    },
    23409: {
      lat: 37.88017,
      lng: -75.64709,
      city: 'Mears',
      state: 'VA',
    },
    23410: {
      lat: 37.63802,
      lng: -75.74306,
      city: 'Melfa',
      state: 'VA',
    },
    23413: {
      lat: 37.4653,
      lng: -75.85356,
      city: 'Nassawadox',
      state: 'VA',
    },
    23414: {
      lat: 37.81375,
      lng: -75.58688,
      city: 'Nelsonia',
      state: 'VA',
    },
    23415: {
      lat: 37.97459,
      lng: -75.53443,
      city: 'New Church',
      state: 'VA',
    },
    23416: {
      lat: 37.93371,
      lng: -75.557,
      city: 'Oak Hall',
      state: 'VA',
    },
    23417: {
      lat: 37.73061,
      lng: -75.76687,
      city: 'Onancock',
      state: 'VA',
    },
    23418: {
      lat: 37.63821,
      lng: -75.66926,
      city: 'Onley',
      state: 'VA',
    },
    23420: {
      lat: 37.58275,
      lng: -75.81166,
      city: 'Painter',
      state: 'VA',
    },
    23421: {
      lat: 37.76564,
      lng: -75.61429,
      city: 'Parksley',
      state: 'VA',
    },
    23422: {
      lat: 37.63647,
      lng: -75.82397,
      city: 'Pungoteague',
      state: 'VA',
    },
    23423: {
      lat: 37.52361,
      lng: -75.73969,
      city: 'Quinby',
      state: 'VA',
    },
    23426: {
      lat: 37.92706,
      lng: -75.67506,
      city: 'Sanford',
      state: 'VA',
    },
    23427: { lat: 37.9124, lng: -75.71344, city: 'Saxis', state: 'VA' },
    23430: {
      lat: 36.98615,
      lng: -76.66337,
      city: 'Smithfield',
      state: 'VA',
    },
    23432: {
      lat: 36.87099,
      lng: -76.55097,
      city: 'Suffolk',
      state: 'VA',
    },
    23433: {
      lat: 36.90763,
      lng: -76.49183,
      city: 'Suffolk',
      state: 'VA',
    },
    23434: {
      lat: 36.70163,
      lng: -76.59074,
      city: 'Suffolk',
      state: 'VA',
    },
    23435: {
      lat: 36.83751,
      lng: -76.4803,
      city: 'Suffolk',
      state: 'VA',
    },
    23436: {
      lat: 36.89516,
      lng: -76.50873,
      city: 'Suffolk',
      state: 'VA',
    },
    23437: {
      lat: 36.63533,
      lng: -76.80256,
      city: 'Suffolk',
      state: 'VA',
    },
    23438: {
      lat: 36.58116,
      lng: -76.69749,
      city: 'Suffolk',
      state: 'VA',
    },
    23440: {
      lat: 37.83048,
      lng: -75.99019,
      city: 'Tangier',
      state: 'VA',
    },
    23441: {
      lat: 37.71208,
      lng: -75.69943,
      city: 'Tasley',
      state: 'VA',
    },
    23442: {
      lat: 37.90127,
      lng: -75.56874,
      city: 'Temperanceville',
      state: 'VA',
    },
    23451: {
      lat: 36.87175,
      lng: -76.00689,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23452: {
      lat: 36.84604,
      lng: -76.09197,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23453: {
      lat: 36.785,
      lng: -76.07679,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23454: {
      lat: 36.83216,
      lng: -76.02616,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23455: {
      lat: 36.88974,
      lng: -76.1441,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23456: {
      lat: 36.73435,
      lng: -76.03492,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23457: {
      lat: 36.61686,
      lng: -76.01576,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23459: {
      lat: 36.91607,
      lng: -76.17043,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23460: {
      lat: 36.81848,
      lng: -76.03161,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23461: {
      lat: 36.77244,
      lng: -75.96172,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23462: {
      lat: 36.8366,
      lng: -76.15105,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23464: {
      lat: 36.79732,
      lng: -76.17899,
      city: 'Virginia Beach',
      state: 'VA',
    },
    23480: {
      lat: 37.61388,
      lng: -75.69077,
      city: 'Wachapreague',
      state: 'VA',
    },
    23486: {
      lat: 37.5177,
      lng: -75.80824,
      city: 'Willis Wharf',
      state: 'VA',
    },
    23487: {
      lat: 36.85197,
      lng: -76.72303,
      city: 'Windsor',
      state: 'VA',
    },
    23488: {
      lat: 37.95237,
      lng: -75.60831,
      city: 'Withams',
      state: 'VA',
    },
    23502: {
      lat: 36.86274,
      lng: -76.20983,
      city: 'Norfolk',
      state: 'VA',
    },
    23503: {
      lat: 36.94727,
      lng: -76.26223,
      city: 'Norfolk',
      state: 'VA',
    },
    23504: {
      lat: 36.85677,
      lng: -76.26568,
      city: 'Norfolk',
      state: 'VA',
    },
    23505: {
      lat: 36.91418,
      lng: -76.29081,
      city: 'Norfolk',
      state: 'VA',
    },
    23507: {
      lat: 36.8642,
      lng: -76.30304,
      city: 'Norfolk',
      state: 'VA',
    },
    23508: {
      lat: 36.8844,
      lng: -76.30898,
      city: 'Norfolk',
      state: 'VA',
    },
    23509: {
      lat: 36.88215,
      lng: -76.26203,
      city: 'Norfolk',
      state: 'VA',
    },
    23510: {
      lat: 36.8518,
      lng: -76.29154,
      city: 'Norfolk',
      state: 'VA',
    },
    23511: { lat: 36.94, lng: -76.30055, city: 'Norfolk', state: 'VA' },
    23513: {
      lat: 36.88948,
      lng: -76.23831,
      city: 'Norfolk',
      state: 'VA',
    },
    23517: {
      lat: 36.86927,
      lng: -76.29198,
      city: 'Norfolk',
      state: 'VA',
    },
    23518: {
      lat: 36.91638,
      lng: -76.21586,
      city: 'Norfolk',
      state: 'VA',
    },
    23523: {
      lat: 36.83254,
      lng: -76.27199,
      city: 'Norfolk',
      state: 'VA',
    },
    23551: {
      lat: 36.92359,
      lng: -76.29238,
      city: 'Norfolk',
      state: 'VA',
    },
    23601: {
      lat: 37.05862,
      lng: -76.46414,
      city: 'Newport News',
      state: 'VA',
    },
    23602: {
      lat: 37.11511,
      lng: -76.51625,
      city: 'Newport News',
      state: 'VA',
    },
    23603: {
      lat: 37.19188,
      lng: -76.56563,
      city: 'Newport News',
      state: 'VA',
    },
    23604: {
      lat: 37.13195,
      lng: -76.58902,
      city: 'Fort Eustis',
      state: 'VA',
    },
    23605: {
      lat: 37.01754,
      lng: -76.43527,
      city: 'Newport News',
      state: 'VA',
    },
    23606: {
      lat: 37.07847,
      lng: -76.49557,
      city: 'Newport News',
      state: 'VA',
    },
    23607: {
      lat: 36.98753,
      lng: -76.42235,
      city: 'Newport News',
      state: 'VA',
    },
    23608: {
      lat: 37.15093,
      lng: -76.54343,
      city: 'Newport News',
      state: 'VA',
    },
    23651: {
      lat: 37.0099,
      lng: -76.30451,
      city: 'Fort Monroe',
      state: 'VA',
    },
    23661: {
      lat: 37.00804,
      lng: -76.38631,
      city: 'Hampton',
      state: 'VA',
    },
    23662: {
      lat: 37.13178,
      lng: -76.35678,
      city: 'Poquoson',
      state: 'VA',
    },
    23663: {
      lat: 37.03216,
      lng: -76.31589,
      city: 'Hampton',
      state: 'VA',
    },
    23664: {
      lat: 37.07467,
      lng: -76.2925,
      city: 'Hampton',
      state: 'VA',
    },
    23665: {
      lat: 37.08963,
      lng: -76.36302,
      city: 'Hampton',
      state: 'VA',
    },
    23666: {
      lat: 37.05818,
      lng: -76.40817,
      city: 'Hampton',
      state: 'VA',
    },
    23667: {
      lat: 37.02081,
      lng: -76.33093,
      city: 'Hampton',
      state: 'VA',
    },
    23668: {
      lat: 37.02034,
      lng: -76.33839,
      city: 'Hampton',
      state: 'VA',
    },
    23669: {
      lat: 37.05052,
      lng: -76.33952,
      city: 'Hampton',
      state: 'VA',
    },
    23681: {
      lat: 37.09697,
      lng: -76.38389,
      city: 'Hampton',
      state: 'VA',
    },
    23690: {
      lat: 37.21829,
      lng: -76.52371,
      city: 'Yorktown',
      state: 'VA',
    },
    23691: {
      lat: 37.25906,
      lng: -76.57322,
      city: 'Yorktown',
      state: 'VA',
    },
    23692: {
      lat: 37.18107,
      lng: -76.45868,
      city: 'Yorktown',
      state: 'VA',
    },
    23693: {
      lat: 37.12669,
      lng: -76.44755,
      city: 'Yorktown',
      state: 'VA',
    },
    23696: {
      lat: 37.19249,
      lng: -76.42589,
      city: 'Seaford',
      state: 'VA',
    },
    23701: {
      lat: 36.81158,
      lng: -76.36986,
      city: 'Portsmouth',
      state: 'VA',
    },
    23702: {
      lat: 36.80292,
      lng: -76.32679,
      city: 'Portsmouth',
      state: 'VA',
    },
    23703: {
      lat: 36.88039,
      lng: -76.37101,
      city: 'Portsmouth',
      state: 'VA',
    },
    23704: {
      lat: 36.82603,
      lng: -76.3123,
      city: 'Portsmouth',
      state: 'VA',
    },
    23707: {
      lat: 36.84255,
      lng: -76.34002,
      city: 'Portsmouth',
      state: 'VA',
    },
    23708: {
      lat: 36.84804,
      lng: -76.30646,
      city: 'Portsmouth',
      state: 'VA',
    },
    23709: {
      lat: 36.81685,
      lng: -76.29846,
      city: 'Portsmouth',
      state: 'VA',
    },
    23801: {
      lat: 37.23618,
      lng: -77.33582,
      city: 'Fort Gregg Adams',
      state: 'VA',
    },
    23803: {
      lat: 37.21183,
      lng: -77.48028,
      city: 'Petersburg',
      state: 'VA',
    },
    23805: {
      lat: 37.12922,
      lng: -77.3953,
      city: 'Petersburg',
      state: 'VA',
    },
    23821: {
      lat: 36.876,
      lng: -77.91794,
      city: 'Alberta',
      state: 'VA',
    },
    23824: {
      lat: 37.08767,
      lng: -77.95749,
      city: 'Blackstone',
      state: 'VA',
    },
    23827: {
      lat: 36.61491,
      lng: -77.20724,
      city: 'Boykins',
      state: 'VA',
    },
    23828: {
      lat: 36.58693,
      lng: -77.28557,
      city: 'Branchville',
      state: 'VA',
    },
    23829: {
      lat: 36.73393,
      lng: -77.22525,
      city: 'Capron',
      state: 'VA',
    },
    23830: {
      lat: 37.02098,
      lng: -77.39771,
      city: 'Carson',
      state: 'VA',
    },
    23831: {
      lat: 37.34437,
      lng: -77.44998,
      city: 'Chester',
      state: 'VA',
    },
    23832: {
      lat: 37.39436,
      lng: -77.59491,
      city: 'Chesterfield',
      state: 'VA',
    },
    23833: {
      lat: 37.21094,
      lng: -77.66947,
      city: 'Church Road',
      state: 'VA',
    },
    23834: {
      lat: 37.28911,
      lng: -77.40063,
      city: 'Colonial Heights',
      state: 'VA',
    },
    23836: {
      lat: 37.35087,
      lng: -77.33253,
      city: 'Chester',
      state: 'VA',
    },
    23837: {
      lat: 36.75816,
      lng: -77.08753,
      city: 'Courtland',
      state: 'VA',
    },
    23838: {
      lat: 37.31772,
      lng: -77.63288,
      city: 'Chesterfield',
      state: 'VA',
    },
    23839: {
      lat: 37.08718,
      lng: -76.91895,
      city: 'Dendron',
      state: 'VA',
    },
    23840: {
      lat: 37.05638,
      lng: -77.67076,
      city: 'Dewitt',
      state: 'VA',
    },
    23841: {
      lat: 37.05421,
      lng: -77.55305,
      city: 'Dinwiddie',
      state: 'VA',
    },
    23842: {
      lat: 37.13625,
      lng: -77.22086,
      city: 'Disputanta',
      state: 'VA',
    },
    23843: {
      lat: 36.84988,
      lng: -77.8109,
      city: 'Dolphin',
      state: 'VA',
    },
    23844: {
      lat: 36.67032,
      lng: -77.3358,
      city: 'Drewryville',
      state: 'VA',
    },
    23845: {
      lat: 36.57048,
      lng: -77.99322,
      city: 'Ebony',
      state: 'VA',
    },
    23846: {
      lat: 37.05947,
      lng: -76.83224,
      city: 'Elberon',
      state: 'VA',
    },
    23847: {
      lat: 36.668,
      lng: -77.54972,
      city: 'Emporia',
      state: 'VA',
    },
    23850: { lat: 37.16276, lng: -77.7434, city: 'Ford', state: 'VA' },
    23851: {
      lat: 36.6521,
      lng: -76.95776,
      city: 'Franklin',
      state: 'VA',
    },
    23856: {
      lat: 36.78735,
      lng: -77.70291,
      city: 'Freeman',
      state: 'VA',
    },
    23857: {
      lat: 36.58389,
      lng: -77.88588,
      city: 'Gasburg',
      state: 'VA',
    },
    23860: {
      lat: 37.28267,
      lng: -77.22267,
      city: 'Hopewell',
      state: 'VA',
    },
    23866: { lat: 36.92012, lng: -76.86693, city: 'Ivor', state: 'VA' },
    23867: {
      lat: 36.82063,
      lng: -77.4878,
      city: 'Jarratt',
      state: 'VA',
    },
    23868: {
      lat: 36.70991,
      lng: -77.8038,
      city: 'Lawrenceville',
      state: 'VA',
    },
    23870: {
      lat: 36.75241,
      lng: -77.50016,
      city: 'Jarratt',
      state: 'VA',
    },
    23872: {
      lat: 36.98351,
      lng: -77.72629,
      city: 'McKenney',
      state: 'VA',
    },
    23874: {
      lat: 36.60761,
      lng: -77.09803,
      city: 'Newsoms',
      state: 'VA',
    },
    23875: {
      lat: 37.22941,
      lng: -77.25845,
      city: 'Prince George',
      state: 'VA',
    },
    23876: {
      lat: 36.95635,
      lng: -77.83343,
      city: 'Rawlings',
      state: 'VA',
    },
    23878: {
      lat: 36.8291,
      lng: -77.02065,
      city: 'Sedley',
      state: 'VA',
    },
    23879: {
      lat: 36.59224,
      lng: -77.59547,
      city: 'Skippers',
      state: 'VA',
    },
    23881: {
      lat: 37.1917,
      lng: -76.98588,
      city: 'Spring Grove',
      state: 'VA',
    },
    23882: {
      lat: 36.92591,
      lng: -77.41543,
      city: 'Stony Creek',
      state: 'VA',
    },
    23883: { lat: 37.12546, lng: -76.7502, city: 'Surry', state: 'VA' },
    23884: {
      lat: 36.91733,
      lng: -77.28011,
      city: 'Sussex',
      state: 'VA',
    },
    23885: {
      lat: 37.18521,
      lng: -77.57834,
      city: 'Sutherland',
      state: 'VA',
    },
    23887: {
      lat: 36.56762,
      lng: -77.81658,
      city: 'Valentines',
      state: 'VA',
    },
    23888: {
      lat: 36.95383,
      lng: -76.97802,
      city: 'Wakefield',
      state: 'VA',
    },
    23889: {
      lat: 36.8972,
      lng: -77.74395,
      city: 'Warfield',
      state: 'VA',
    },
    23890: {
      lat: 37.00755,
      lng: -77.11837,
      city: 'Waverly',
      state: 'VA',
    },
    23891: {
      lat: 37.04752,
      lng: -77.20367,
      city: 'Waverly',
      state: 'VA',
    },
    23893: {
      lat: 36.6244,
      lng: -77.93658,
      city: 'White Plains',
      state: 'VA',
    },
    23894: {
      lat: 37.13867,
      lng: -77.82404,
      city: 'Wilsons',
      state: 'VA',
    },
    23897: { lat: 36.83399, lng: -77.28234, city: 'Yale', state: 'VA' },
    23898: { lat: 36.82737, lng: -76.85662, city: 'Zuni', state: 'VA' },
    23899: {
      lat: 37.23079,
      lng: -76.96562,
      city: 'Claremont',
      state: 'VA',
    },
    23901: {
      lat: 37.32309,
      lng: -78.41186,
      city: 'Farmville',
      state: 'VA',
    },
    23909: {
      lat: 37.29891,
      lng: -78.39635,
      city: 'Farmville',
      state: 'VA',
    },
    23915: {
      lat: 36.70668,
      lng: -78.28627,
      city: 'Baskerville',
      state: 'VA',
    },
    23917: {
      lat: 36.62378,
      lng: -78.34144,
      city: 'Boydton',
      state: 'VA',
    },
    23919: {
      lat: 36.59103,
      lng: -78.13305,
      city: 'Bracey',
      state: 'VA',
    },
    23920: {
      lat: 36.72452,
      lng: -77.97266,
      city: 'Brodnax',
      state: 'VA',
    },
    23921: {
      lat: 37.59143,
      lng: -78.61413,
      city: 'Buckingham',
      state: 'VA',
    },
    23922: {
      lat: 37.17987,
      lng: -78.21219,
      city: 'Burkeville',
      state: 'VA',
    },
    23923: {
      lat: 37.09316,
      lng: -78.64627,
      city: 'Charlotte Court House',
      state: 'VA',
    },
    23924: {
      lat: 36.80919,
      lng: -78.43396,
      city: 'Chase City',
      state: 'VA',
    },
    23927: {
      lat: 36.60899,
      lng: -78.51258,
      city: 'Clarksville',
      state: 'VA',
    },
    23930: {
      lat: 37.17468,
      lng: -78.09608,
      city: 'Crewe',
      state: 'VA',
    },
    23934: {
      lat: 37.17613,
      lng: -78.62373,
      city: 'Cullen',
      state: 'VA',
    },
    23936: {
      lat: 37.52494,
      lng: -78.4819,
      city: 'Dillwyn',
      state: 'VA',
    },
    23937: {
      lat: 36.94547,
      lng: -78.52463,
      city: 'Drakes Branch',
      state: 'VA',
    },
    23938: {
      lat: 36.90541,
      lng: -78.00464,
      city: 'Dundas',
      state: 'VA',
    },
    23942: {
      lat: 37.13241,
      lng: -78.30138,
      city: 'Green Bay',
      state: 'VA',
    },
    23943: {
      lat: 37.24181,
      lng: -78.46041,
      city: 'Hampden Sydney',
      state: 'VA',
    },
    23944: {
      lat: 36.9056,
      lng: -78.13319,
      city: 'Kenbridge',
      state: 'VA',
    },
    23947: {
      lat: 37.02265,
      lng: -78.44284,
      city: 'Keysville',
      state: 'VA',
    },
    23950: {
      lat: 36.66005,
      lng: -78.07699,
      city: 'La Crosse',
      state: 'VA',
    },
    23952: {
      lat: 36.91786,
      lng: -78.27886,
      city: 'Lunenburg',
      state: 'VA',
    },
    23954: {
      lat: 37.10893,
      lng: -78.38705,
      city: 'Meherrin',
      state: 'VA',
    },
    23955: {
      lat: 37.10816,
      lng: -78.06556,
      city: 'Nottoway',
      state: 'VA',
    },
    23958: {
      lat: 37.26848,
      lng: -78.65581,
      city: 'Pamplin',
      state: 'VA',
    },
    23959: {
      lat: 37.10472,
      lng: -78.79654,
      city: 'Phenix',
      state: 'VA',
    },
    23960: {
      lat: 37.31531,
      lng: -78.5579,
      city: 'Prospect',
      state: 'VA',
    },
    23962: {
      lat: 36.93865,
      lng: -78.70399,
      city: 'Randolph',
      state: 'VA',
    },
    23963: {
      lat: 37.195,
      lng: -78.80168,
      city: 'Red House',
      state: 'VA',
    },
    23964: {
      lat: 36.77713,
      lng: -78.63149,
      city: 'Red Oak',
      state: 'VA',
    },
    23966: { lat: 37.29177, lng: -78.27418, city: 'Rice', state: 'VA' },
    23967: { lat: 36.91813, lng: -78.62857, city: 'Saxe', state: 'VA' },
    23968: {
      lat: 36.72123,
      lng: -78.51922,
      city: 'Skipwith',
      state: 'VA',
    },
    23970: {
      lat: 36.74818,
      lng: -78.18637,
      city: 'South Hill',
      state: 'VA',
    },
    23974: {
      lat: 36.97323,
      lng: -78.24549,
      city: 'Victoria',
      state: 'VA',
    },
    23976: {
      lat: 36.8482,
      lng: -78.5797,
      city: 'Wylliesburg',
      state: 'VA',
    },
    24011: {
      lat: 37.27048,
      lng: -79.94128,
      city: 'Roanoke',
      state: 'VA',
    },
    24012: {
      lat: 37.31585,
      lng: -79.89833,
      city: 'Roanoke',
      state: 'VA',
    },
    24013: {
      lat: 37.26691,
      lng: -79.92219,
      city: 'Roanoke',
      state: 'VA',
    },
    24014: {
      lat: 37.22427,
      lng: -79.91282,
      city: 'Roanoke',
      state: 'VA',
    },
    24015: {
      lat: 37.25588,
      lng: -79.97979,
      city: 'Roanoke',
      state: 'VA',
    },
    24016: {
      lat: 37.27208,
      lng: -79.95468,
      city: 'Roanoke',
      state: 'VA',
    },
    24017: {
      lat: 37.29472,
      lng: -79.99183,
      city: 'Roanoke',
      state: 'VA',
    },
    24018: {
      lat: 37.21238,
      lng: -80.04261,
      city: 'Roanoke',
      state: 'VA',
    },
    24019: {
      lat: 37.3446,
      lng: -79.95564,
      city: 'Roanoke',
      state: 'VA',
    },
    24020: {
      lat: 37.35739,
      lng: -79.94279,
      city: 'Roanoke',
      state: 'VA',
    },
    24022: {
      lat: 37.27689,
      lng: -79.93429,
      city: 'Roanoke',
      state: 'VA',
    },
    24053: {
      lat: 36.60999,
      lng: -80.53283,
      city: 'Ararat',
      state: 'VA',
    },
    24054: {
      lat: 36.67806,
      lng: -79.70396,
      city: 'Axton',
      state: 'VA',
    },
    24055: {
      lat: 36.76333,
      lng: -79.99741,
      city: 'Bassett',
      state: 'VA',
    },
    24058: {
      lat: 37.18257,
      lng: -80.61637,
      city: 'Belspring',
      state: 'VA',
    },
    24059: {
      lat: 37.15577,
      lng: -80.13159,
      city: 'Bent Mountain',
      state: 'VA',
    },
    24060: {
      lat: 37.25772,
      lng: -80.41961,
      city: 'Blacksburg',
      state: 'VA',
    },
    24061: {
      lat: 37.22539,
      lng: -80.42658,
      city: 'Blacksburg',
      state: 'VA',
    },
    24064: {
      lat: 37.37237,
      lng: -79.7759,
      city: 'Blue Ridge',
      state: 'VA',
    },
    24065: {
      lat: 37.11814,
      lng: -79.96453,
      city: 'Boones Mill',
      state: 'VA',
    },
    24066: {
      lat: 37.5382,
      lng: -79.67822,
      city: 'Buchanan',
      state: 'VA',
    },
    24067: {
      lat: 37.03737,
      lng: -80.06884,
      city: 'Callaway',
      state: 'VA',
    },
    24069: {
      lat: 36.58376,
      lng: -79.63779,
      city: 'Cascade',
      state: 'VA',
    },
    24070: {
      lat: 37.37316,
      lng: -80.21238,
      city: 'Catawba',
      state: 'VA',
    },
    24072: {
      lat: 37.03809,
      lng: -80.23113,
      city: 'Check',
      state: 'VA',
    },
    24073: {
      lat: 37.13053,
      lng: -80.4225,
      city: 'Christiansburg',
      state: 'VA',
    },
    24076: {
      lat: 36.59704,
      lng: -80.42116,
      city: 'Claudville',
      state: 'VA',
    },
    24077: {
      lat: 37.36337,
      lng: -79.90278,
      city: 'Cloverdale',
      state: 'VA',
    },
    24078: {
      lat: 36.72376,
      lng: -79.91303,
      city: 'Collinsville',
      state: 'VA',
    },
    24079: {
      lat: 37.0539,
      lng: -80.15418,
      city: 'Copper Hill',
      state: 'VA',
    },
    24082: {
      lat: 36.62923,
      lng: -80.13204,
      city: 'Critz',
      state: 'VA',
    },
    24083: {
      lat: 37.40614,
      lng: -79.92031,
      city: 'Daleville',
      state: 'VA',
    },
    24084: {
      lat: 37.11468,
      lng: -80.79088,
      city: 'Dublin',
      state: 'VA',
    },
    24085: {
      lat: 37.67539,
      lng: -79.83639,
      city: 'Eagle Rock',
      state: 'VA',
    },
    24086: {
      lat: 37.27268,
      lng: -80.63585,
      city: 'Eggleston',
      state: 'VA',
    },
    24087: {
      lat: 37.22594,
      lng: -80.24748,
      city: 'Elliston',
      state: 'VA',
    },
    24088: {
      lat: 36.89492,
      lng: -80.0798,
      city: 'Ferrum',
      state: 'VA',
    },
    24089: {
      lat: 36.70392,
      lng: -79.96078,
      city: 'Fieldale',
      state: 'VA',
    },
    24090: {
      lat: 37.52633,
      lng: -79.88921,
      city: 'Fincastle',
      state: 'VA',
    },
    24091: {
      lat: 36.92131,
      lng: -80.32264,
      city: 'Floyd',
      state: 'VA',
    },
    24092: {
      lat: 37.00158,
      lng: -79.76306,
      city: 'Glade Hill',
      state: 'VA',
    },
    24093: {
      lat: 37.38784,
      lng: -80.85657,
      city: 'Glen Lyn',
      state: 'VA',
    },
    24095: {
      lat: 37.22304,
      lng: -79.73787,
      city: 'Goodview',
      state: 'VA',
    },
    24101: {
      lat: 37.17702,
      lng: -79.80111,
      city: 'Hardy',
      state: 'VA',
    },
    24102: {
      lat: 36.83994,
      lng: -79.99891,
      city: 'Henry',
      state: 'VA',
    },
    24104: {
      lat: 37.13546,
      lng: -79.48773,
      city: 'Huddleston',
      state: 'VA',
    },
    24105: {
      lat: 36.90278,
      lng: -80.60127,
      city: 'Indian Valley',
      state: 'VA',
    },
    24112: {
      lat: 36.72378,
      lng: -79.84921,
      city: 'Martinsville',
      state: 'VA',
    },
    24120: {
      lat: 36.71891,
      lng: -80.41277,
      city: 'Meadows Of Dan',
      state: 'VA',
    },
    24121: {
      lat: 37.1709,
      lng: -79.64141,
      city: 'Moneta',
      state: 'VA',
    },
    24122: {
      lat: 37.4212,
      lng: -79.69372,
      city: 'Montvale',
      state: 'VA',
    },
    24124: {
      lat: 37.30996,
      lng: -80.86774,
      city: 'Narrows',
      state: 'VA',
    },
    24127: {
      lat: 37.47949,
      lng: -80.19468,
      city: 'New Castle',
      state: 'VA',
    },
    24128: {
      lat: 37.3426,
      lng: -80.47196,
      city: 'Newport',
      state: 'VA',
    },
    24129: {
      lat: 37.13947,
      lng: -80.5918,
      city: 'New River',
      state: 'VA',
    },
    24131: {
      lat: 37.56836,
      lng: -80.22894,
      city: 'Paint Bank',
      state: 'VA',
    },
    24132: {
      lat: 37.20534,
      lng: -80.64707,
      city: 'Parrott',
      state: 'VA',
    },
    24133: {
      lat: 36.67563,
      lng: -80.12794,
      city: 'Patrick Springs',
      state: 'VA',
    },
    24134: {
      lat: 37.24595,
      lng: -80.77901,
      city: 'Pearisburg',
      state: 'VA',
    },
    24136: {
      lat: 37.31927,
      lng: -80.60686,
      city: 'Pembroke',
      state: 'VA',
    },
    24137: {
      lat: 36.92587,
      lng: -79.65456,
      city: 'Penhook',
      state: 'VA',
    },
    24138: {
      lat: 37.05052,
      lng: -80.31684,
      city: 'Pilot',
      state: 'VA',
    },
    24139: {
      lat: 37.01092,
      lng: -79.4752,
      city: 'Pittsville',
      state: 'VA',
    },
    24141: {
      lat: 37.10105,
      lng: -80.56677,
      city: 'Radford',
      state: 'VA',
    },
    24142: {
      lat: 37.13833,
      lng: -80.55084,
      city: 'Radford',
      state: 'VA',
    },
    24147: {
      lat: 37.40178,
      lng: -80.82815,
      city: 'Rich Creek',
      state: 'VA',
    },
    24148: {
      lat: 36.58156,
      lng: -79.87231,
      city: 'Ridgeway',
      state: 'VA',
    },
    24149: {
      lat: 37.02277,
      lng: -80.43424,
      city: 'Riner',
      state: 'VA',
    },
    24150: {
      lat: 37.40229,
      lng: -80.59694,
      city: 'Ripplemead',
      state: 'VA',
    },
    24151: {
      lat: 36.95827,
      lng: -79.86457,
      city: 'Rocky Mount',
      state: 'VA',
    },
    24153: {
      lat: 37.29674,
      lng: -80.11609,
      city: 'Salem',
      state: 'VA',
    },
    24161: {
      lat: 36.98132,
      lng: -79.54391,
      city: 'Sandy Level',
      state: 'VA',
    },
    24162: {
      lat: 37.13735,
      lng: -80.25828,
      city: 'Shawsville',
      state: 'VA',
    },
    24165: {
      lat: 36.58368,
      lng: -80.05319,
      city: 'Spencer',
      state: 'VA',
    },
    24167: {
      lat: 37.24718,
      lng: -80.7345,
      city: 'Staffordsville',
      state: 'VA',
    },
    24168: {
      lat: 36.73613,
      lng: -79.94876,
      city: 'Stanleytown',
      state: 'VA',
    },
    24171: {
      lat: 36.67095,
      lng: -80.23835,
      city: 'Stuart',
      state: 'VA',
    },
    24174: {
      lat: 37.35087,
      lng: -79.66762,
      city: 'Thaxton',
      state: 'VA',
    },
    24175: {
      lat: 37.42589,
      lng: -79.93788,
      city: 'Troutville',
      state: 'VA',
    },
    24176: {
      lat: 37.00937,
      lng: -79.69193,
      city: 'Union Hall',
      state: 'VA',
    },
    24179: {
      lat: 37.2855,
      lng: -79.79963,
      city: 'Vinton',
      state: 'VA',
    },
    24184: { lat: 37.0881, lng: -79.77642, city: 'Wirtz', state: 'VA' },
    24185: {
      lat: 36.80868,
      lng: -80.27557,
      city: 'Woolwine',
      state: 'VA',
    },
    24201: {
      lat: 36.61059,
      lng: -82.16971,
      city: 'Bristol',
      state: 'VA',
    },
    24202: {
      lat: 36.65904,
      lng: -82.21564,
      city: 'Bristol',
      state: 'VA',
    },
    24210: {
      lat: 36.77231,
      lng: -82.02244,
      city: 'Abingdon',
      state: 'VA',
    },
    24211: {
      lat: 36.65413,
      lng: -81.95643,
      city: 'Abingdon',
      state: 'VA',
    },
    24216: {
      lat: 36.94237,
      lng: -82.79571,
      city: 'Appalachia',
      state: 'VA',
    },
    24217: { lat: 37.1014, lng: -82.17783, city: 'Bee', state: 'VA' },
    24219: {
      lat: 36.84831,
      lng: -82.74645,
      city: 'Big Stone Gap',
      state: 'VA',
    },
    24220: {
      lat: 37.11957,
      lng: -82.22641,
      city: 'Birchleaf',
      state: 'VA',
    },
    24221: {
      lat: 36.63398,
      lng: -82.9943,
      city: 'Blackwater',
      state: 'VA',
    },
    24224: {
      lat: 36.86059,
      lng: -82.28322,
      city: 'Castlewood',
      state: 'VA',
    },
    24225: {
      lat: 36.97543,
      lng: -82.14115,
      city: 'Cleveland',
      state: 'VA',
    },
    24226: {
      lat: 37.14223,
      lng: -82.33384,
      city: 'Clinchco',
      state: 'VA',
    },
    24228: {
      lat: 37.17047,
      lng: -82.44963,
      city: 'Clintwood',
      state: 'VA',
    },
    24230: {
      lat: 36.96928,
      lng: -82.45449,
      city: 'Coeburn',
      state: 'VA',
    },
    24236: {
      lat: 36.64408,
      lng: -81.73689,
      city: 'Damascus',
      state: 'VA',
    },
    24237: {
      lat: 37.03939,
      lng: -82.26424,
      city: 'Dante',
      state: 'VA',
    },
    24239: {
      lat: 37.1136,
      lng: -82.14577,
      city: 'Davenport',
      state: 'VA',
    },
    24243: {
      lat: 36.78174,
      lng: -82.92083,
      city: 'Dryden',
      state: 'VA',
    },
    24244: {
      lat: 36.71075,
      lng: -82.79785,
      city: 'Duffield',
      state: 'VA',
    },
    24245: {
      lat: 36.84045,
      lng: -82.51598,
      city: 'Dungannon',
      state: 'VA',
    },
    24246: {
      lat: 36.86609,
      lng: -82.74395,
      city: 'East Stone Gap',
      state: 'VA',
    },
    24248: {
      lat: 36.62708,
      lng: -83.50767,
      city: 'Ewing',
      state: 'VA',
    },
    24250: {
      lat: 36.75752,
      lng: -82.58705,
      city: 'Fort Blackmore',
      state: 'VA',
    },
    24251: {
      lat: 36.65807,
      lng: -82.60582,
      city: 'Gate City',
      state: 'VA',
    },
    24256: {
      lat: 37.22344,
      lng: -82.28503,
      city: 'Haysi',
      state: 'VA',
    },
    24258: {
      lat: 36.6517,
      lng: -82.41199,
      city: 'Hiltons',
      state: 'VA',
    },
    24260: {
      lat: 37.02291,
      lng: -82.00059,
      city: 'Honaker',
      state: 'VA',
    },
    24263: {
      lat: 36.67164,
      lng: -83.15079,
      city: 'Jonesville',
      state: 'VA',
    },
    24265: {
      lat: 36.83811,
      lng: -82.93904,
      city: 'Keokee',
      state: 'VA',
    },
    24266: {
      lat: 36.8671,
      lng: -82.10523,
      city: 'Lebanon',
      state: 'VA',
    },
    24269: {
      lat: 37.0803,
      lng: -82.37894,
      city: 'McClure',
      state: 'VA',
    },
    24270: {
      lat: 36.72846,
      lng: -82.24693,
      city: 'Mendota',
      state: 'VA',
    },
    24271: {
      lat: 36.74712,
      lng: -82.41714,
      city: 'Nickelsville',
      state: 'VA',
    },
    24272: { lat: 37.0302, lng: -82.33401, city: 'Nora', state: 'VA' },
    24273: {
      lat: 36.96142,
      lng: -82.65632,
      city: 'Norton',
      state: 'VA',
    },
    24277: {
      lat: 36.74948,
      lng: -83.03037,
      city: 'Pennington Gap',
      state: 'VA',
    },
    24279: {
      lat: 37.10383,
      lng: -82.61909,
      city: 'Pound',
      state: 'VA',
    },
    24280: {
      lat: 36.95575,
      lng: -81.94167,
      city: 'Rosedale',
      state: 'VA',
    },
    24281: {
      lat: 36.65051,
      lng: -83.3295,
      city: 'Rose Hill',
      state: 'VA',
    },
    24282: {
      lat: 36.82159,
      lng: -83.05533,
      city: 'Saint Charles',
      state: 'VA',
    },
    24283: {
      lat: 36.94155,
      lng: -82.34554,
      city: 'Saint Paul',
      state: 'VA',
    },
    24290: {
      lat: 36.61186,
      lng: -82.56688,
      city: 'Weber City',
      state: 'VA',
    },
    24292: {
      lat: 36.6147,
      lng: -81.5791,
      city: 'Whitetop',
      state: 'VA',
    },
    24293: { lat: 37.00559, lng: -82.56458, city: 'Wise', state: 'VA' },
    24301: {
      lat: 37.06319,
      lng: -80.79735,
      city: 'Pulaski',
      state: 'VA',
    },
    24311: {
      lat: 36.87292,
      lng: -81.40161,
      city: 'Atkins',
      state: 'VA',
    },
    24312: {
      lat: 36.83117,
      lng: -80.86897,
      city: 'Austinville',
      state: 'VA',
    },
    24313: {
      lat: 36.9105,
      lng: -80.79805,
      city: 'Barren Springs',
      state: 'VA',
    },
    24314: {
      lat: 37.17166,
      lng: -81.20728,
      city: 'Bastian',
      state: 'VA',
    },
    24315: { lat: 37.1393, lng: -81.0534, city: 'Bland', state: 'VA' },
    24316: {
      lat: 36.95277,
      lng: -81.67145,
      city: 'Broadford',
      state: 'VA',
    },
    24317: { lat: 36.60138, lng: -80.66876, city: 'Cana', state: 'VA' },
    24318: {
      lat: 37.00047,
      lng: -81.38658,
      city: 'Ceres',
      state: 'VA',
    },
    24319: {
      lat: 36.76048,
      lng: -81.65778,
      city: 'Chilhowie',
      state: 'VA',
    },
    24322: {
      lat: 36.80534,
      lng: -81.1195,
      city: 'Cripple Creek',
      state: 'VA',
    },
    24323: {
      lat: 36.86513,
      lng: -81.1997,
      city: 'Crockett',
      state: 'VA',
    },
    24324: {
      lat: 36.97461,
      lng: -80.78836,
      city: 'Draper',
      state: 'VA',
    },
    24325: {
      lat: 36.80918,
      lng: -80.60608,
      city: 'Dugspur',
      state: 'VA',
    },
    24326: {
      lat: 36.72771,
      lng: -81.19625,
      city: 'Elk Creek',
      state: 'VA',
    },
    24327: {
      lat: 36.77357,
      lng: -81.82971,
      city: 'Emory',
      state: 'VA',
    },
    24328: {
      lat: 36.66185,
      lng: -80.69382,
      city: 'Fancy Gap',
      state: 'VA',
    },
    24330: {
      lat: 36.72375,
      lng: -81.02471,
      city: 'Fries',
      state: 'VA',
    },
    24333: {
      lat: 36.64057,
      lng: -80.92921,
      city: 'Galax',
      state: 'VA',
    },
    24340: {
      lat: 36.75267,
      lng: -81.75891,
      city: 'Glade Spring',
      state: 'VA',
    },
    24343: {
      lat: 36.76612,
      lng: -80.70045,
      city: 'Hillsville',
      state: 'VA',
    },
    24347: {
      lat: 36.95958,
      lng: -80.65598,
      city: 'Hiwassee',
      state: 'VA',
    },
    24348: {
      lat: 36.63897,
      lng: -81.17392,
      city: 'Independence',
      state: 'VA',
    },
    24350: {
      lat: 36.81109,
      lng: -81.01757,
      city: 'Ivanhoe',
      state: 'VA',
    },
    24351: {
      lat: 36.58081,
      lng: -80.76591,
      city: 'Lambsburg',
      state: 'VA',
    },
    24352: {
      lat: 36.71547,
      lng: -80.53437,
      city: 'Laurel Fork',
      state: 'VA',
    },
    24354: {
      lat: 36.82159,
      lng: -81.54001,
      city: 'Marion',
      state: 'VA',
    },
    24360: {
      lat: 36.93917,
      lng: -80.9023,
      city: 'Max Meadows',
      state: 'VA',
    },
    24361: {
      lat: 36.76672,
      lng: -81.84552,
      city: 'Meadowview',
      state: 'VA',
    },
    24363: {
      lat: 36.6137,
      lng: -81.40084,
      city: 'Mouth Of Wilson',
      state: 'VA',
    },
    24366: {
      lat: 37.26426,
      lng: -81.10315,
      city: 'Rocky Gap',
      state: 'VA',
    },
    24368: {
      lat: 36.89283,
      lng: -81.30003,
      city: 'Rural Retreat',
      state: 'VA',
    },
    24370: {
      lat: 36.91345,
      lng: -81.69107,
      city: 'Saltville',
      state: 'VA',
    },
    24374: {
      lat: 36.79129,
      lng: -81.20178,
      city: 'Speedwell',
      state: 'VA',
    },
    24375: {
      lat: 36.77128,
      lng: -81.39026,
      city: 'Sugar Grove',
      state: 'VA',
    },
    24377: {
      lat: 37.00206,
      lng: -81.56961,
      city: 'Tannersville',
      state: 'VA',
    },
    24378: {
      lat: 36.69126,
      lng: -81.42944,
      city: 'Troutdale',
      state: 'VA',
    },
    24380: {
      lat: 36.85672,
      lng: -80.50183,
      city: 'Willis',
      state: 'VA',
    },
    24381: {
      lat: 36.72453,
      lng: -80.83924,
      city: 'Woodlawn',
      state: 'VA',
    },
    24382: {
      lat: 36.95702,
      lng: -81.10652,
      city: 'Wytheville',
      state: 'VA',
    },
    24401: {
      lat: 38.13257,
      lng: -79.08846,
      city: 'Staunton',
      state: 'VA',
    },
    24411: {
      lat: 38.11784,
      lng: -79.33052,
      city: 'Augusta Springs',
      state: 'VA',
    },
    24412: {
      lat: 38.05774,
      lng: -79.83096,
      city: 'Bacova',
      state: 'VA',
    },
    24413: {
      lat: 38.50753,
      lng: -79.55925,
      city: 'Blue Grass',
      state: 'VA',
    },
    24415: {
      lat: 37.91099,
      lng: -79.31126,
      city: 'Brownsburg',
      state: 'VA',
    },
    24416: {
      lat: 37.72892,
      lng: -79.35534,
      city: 'Buena Vista',
      state: 'VA',
    },
    24421: {
      lat: 38.26389,
      lng: -79.20019,
      city: 'Churchville',
      state: 'VA',
    },
    24422: {
      lat: 37.83041,
      lng: -79.75128,
      city: 'Clifton Forge',
      state: 'VA',
    },
    24426: {
      lat: 37.76589,
      lng: -80.06927,
      city: 'Covington',
      state: 'VA',
    },
    24430: {
      lat: 38.076,
      lng: -79.34181,
      city: 'Craigsville',
      state: 'VA',
    },
    24431: {
      lat: 38.16897,
      lng: -78.83924,
      city: 'Crimora',
      state: 'VA',
    },
    24432: {
      lat: 38.17639,
      lng: -79.43845,
      city: 'Deerfield',
      state: 'VA',
    },
    24433: {
      lat: 38.41817,
      lng: -79.46461,
      city: 'Doe Hill',
      state: 'VA',
    },
    24435: {
      lat: 37.86716,
      lng: -79.30663,
      city: 'Fairfield',
      state: 'VA',
    },
    24437: {
      lat: 38.2116,
      lng: -78.933,
      city: 'Fort Defiance',
      state: 'VA',
    },
    24439: {
      lat: 38.01151,
      lng: -79.47359,
      city: 'Goshen',
      state: 'VA',
    },
    24440: {
      lat: 37.99595,
      lng: -79.16574,
      city: 'Greenville',
      state: 'VA',
    },
    24441: {
      lat: 38.22823,
      lng: -78.8109,
      city: 'Grottoes',
      state: 'VA',
    },
    24442: {
      lat: 38.35971,
      lng: -79.39028,
      city: 'Head Waters',
      state: 'VA',
    },
    24445: {
      lat: 37.96615,
      lng: -79.88013,
      city: 'Hot Springs',
      state: 'VA',
    },
    24448: {
      lat: 37.79836,
      lng: -79.79002,
      city: 'Iron Gate',
      state: 'VA',
    },
    24450: {
      lat: 37.77883,
      lng: -79.53888,
      city: 'Lexington',
      state: 'VA',
    },
    24457: {
      lat: 37.79379,
      lng: -79.87014,
      city: 'Low Moor',
      state: 'VA',
    },
    24458: {
      lat: 38.33066,
      lng: -79.51201,
      city: 'McDowell',
      state: 'VA',
    },
    24459: {
      lat: 38.02955,
      lng: -79.28621,
      city: 'Middlebrook',
      state: 'VA',
    },
    24460: {
      lat: 38.02237,
      lng: -79.65048,
      city: 'Millboro',
      state: 'VA',
    },
    24464: {
      lat: 37.86082,
      lng: -79.10398,
      city: 'Montebello',
      state: 'VA',
    },
    24465: {
      lat: 38.39611,
      lng: -79.64636,
      city: 'Monterey',
      state: 'VA',
    },
    24467: {
      lat: 38.2592,
      lng: -78.97249,
      city: 'Mount Sidney',
      state: 'VA',
    },
    24469: {
      lat: 38.20099,
      lng: -78.90828,
      city: 'New Hope',
      state: 'VA',
    },
    24471: {
      lat: 38.30837,
      lng: -78.79645,
      city: 'Port Republic',
      state: 'VA',
    },
    24472: {
      lat: 37.93833,
      lng: -79.22194,
      city: 'Raphine',
      state: 'VA',
    },
    24473: {
      lat: 37.9129,
      lng: -79.41052,
      city: 'Rockbridge Baths',
      state: 'VA',
    },
    24474: {
      lat: 37.80504,
      lng: -79.84731,
      city: 'Selma',
      state: 'VA',
    },
    24476: {
      lat: 37.97231,
      lng: -79.22,
      city: 'Steeles Tavern',
      state: 'VA',
    },
    24477: {
      lat: 38.00416,
      lng: -79.04374,
      city: 'Stuarts Draft',
      state: 'VA',
    },
    24479: {
      lat: 38.15353,
      lng: -79.23992,
      city: 'Swoope',
      state: 'VA',
    },
    24482: {
      lat: 38.20502,
      lng: -78.99706,
      city: 'Verona',
      state: 'VA',
    },
    24483: {
      lat: 37.81484,
      lng: -79.2256,
      city: 'Vesuvius',
      state: 'VA',
    },
    24484: {
      lat: 38.1448,
      lng: -79.82013,
      city: 'Warm Springs',
      state: 'VA',
    },
    24485: {
      lat: 38.27521,
      lng: -79.33165,
      city: 'West Augusta',
      state: 'VA',
    },
    24486: {
      lat: 38.29366,
      lng: -78.92925,
      city: 'Weyers Cave',
      state: 'VA',
    },
    24487: {
      lat: 38.20099,
      lng: -79.58105,
      city: 'Williamsville',
      state: 'VA',
    },
    24501: {
      lat: 37.35565,
      lng: -79.15608,
      city: 'Lynchburg',
      state: 'VA',
    },
    24502: {
      lat: 37.35624,
      lng: -79.2194,
      city: 'Lynchburg',
      state: 'VA',
    },
    24503: {
      lat: 37.45272,
      lng: -79.24286,
      city: 'Lynchburg',
      state: 'VA',
    },
    24504: {
      lat: 37.36673,
      lng: -79.04953,
      city: 'Lynchburg',
      state: 'VA',
    },
    24515: {
      lat: 37.35261,
      lng: -79.17827,
      city: 'Lynchburg',
      state: 'VA',
    },
    24517: {
      lat: 37.14532,
      lng: -79.23258,
      city: 'Altavista',
      state: 'VA',
    },
    24520: {
      lat: 36.58486,
      lng: -79.03752,
      city: 'Alton',
      state: 'VA',
    },
    24521: {
      lat: 37.63672,
      lng: -79.09539,
      city: 'Amherst',
      state: 'VA',
    },
    24522: {
      lat: 37.37324,
      lng: -78.78591,
      city: 'Appomattox',
      state: 'VA',
    },
    24523: {
      lat: 37.3364,
      lng: -79.52347,
      city: 'Bedford',
      state: 'VA',
    },
    24526: {
      lat: 37.55452,
      lng: -79.40169,
      city: 'Big Island',
      state: 'VA',
    },
    24527: {
      lat: 36.7324,
      lng: -79.34577,
      city: 'Blairs',
      state: 'VA',
    },
    24528: {
      lat: 37.07733,
      lng: -78.8777,
      city: 'Brookneal',
      state: 'VA',
    },
    24529: {
      lat: 36.62452,
      lng: -78.64001,
      city: 'Buffalo Junction',
      state: 'VA',
    },
    24530: {
      lat: 36.80785,
      lng: -79.61044,
      city: 'Callands',
      state: 'VA',
    },
    24531: {
      lat: 36.83922,
      lng: -79.44332,
      city: 'Chatham',
      state: 'VA',
    },
    24534: {
      lat: 36.87835,
      lng: -78.76691,
      city: 'Clover',
      state: 'VA',
    },
    24536: {
      lat: 37.48996,
      lng: -79.32097,
      city: 'Coleman Falls',
      state: 'VA',
    },
    24538: {
      lat: 37.32908,
      lng: -78.95367,
      city: 'Concord',
      state: 'VA',
    },
    24539: {
      lat: 36.85376,
      lng: -78.90728,
      city: 'Crystal Hill',
      state: 'VA',
    },
    24540: {
      lat: 36.64235,
      lng: -79.42473,
      city: 'Danville',
      state: 'VA',
    },
    24541: {
      lat: 36.59072,
      lng: -79.51572,
      city: 'Danville',
      state: 'VA',
    },
    24549: {
      lat: 36.71584,
      lng: -79.50632,
      city: 'Dry Fork',
      state: 'VA',
    },
    24550: {
      lat: 37.23493,
      lng: -79.26898,
      city: 'Evington',
      state: 'VA',
    },
    24551: {
      lat: 37.36655,
      lng: -79.3268,
      city: 'Forest',
      state: 'VA',
    },
    24553: {
      lat: 37.54845,
      lng: -78.81907,
      city: 'Gladstone',
      state: 'VA',
    },
    24554: {
      lat: 37.13674,
      lng: -79.06639,
      city: 'Gladys',
      state: 'VA',
    },
    24555: {
      lat: 37.6678,
      lng: -79.47007,
      city: 'Glasgow',
      state: 'VA',
    },
    24556: { lat: 37.38093, lng: -79.4021, city: 'Goode', state: 'VA' },
    24557: {
      lat: 36.9666,
      lng: -79.30401,
      city: 'Gretna',
      state: 'VA',
    },
    24558: {
      lat: 36.77914,
      lng: -78.95741,
      city: 'Halifax',
      state: 'VA',
    },
    24562: {
      lat: 37.70577,
      lng: -78.62241,
      city: 'Howardsville',
      state: 'VA',
    },
    24563: { lat: 37.06106, lng: -79.27385, city: 'Hurt', state: 'VA' },
    24565: { lat: 36.85259, lng: -79.18588, city: 'Java', state: 'VA' },
    24566: {
      lat: 36.72747,
      lng: -79.25069,
      city: 'Keeling',
      state: 'VA',
    },
    24569: {
      lat: 37.0463,
      lng: -79.12129,
      city: 'Long Island',
      state: 'VA',
    },
    24570: { lat: 37.3486, lng: -79.4272, city: 'Lowry', state: 'VA' },
    24571: {
      lat: 37.13671,
      lng: -79.37983,
      city: 'Lynch Station',
      state: 'VA',
    },
    24572: {
      lat: 37.46514,
      lng: -79.09628,
      city: 'Madison Heights',
      state: 'VA',
    },
    24574: {
      lat: 37.57589,
      lng: -79.24714,
      city: 'Monroe',
      state: 'VA',
    },
    24577: {
      lat: 36.93762,
      lng: -78.97553,
      city: 'Nathalie',
      state: 'VA',
    },
    24578: {
      lat: 37.66269,
      lng: -79.55693,
      city: 'Natural Bridge',
      state: 'VA',
    },
    24579: {
      lat: 37.58763,
      lng: -79.50483,
      city: 'Natural Bridge Station',
      state: 'VA',
    },
    24580: { lat: 36.58328, lng: -78.68, city: 'Nelson', state: 'VA' },
    24581: {
      lat: 37.65692,
      lng: -78.8098,
      city: 'Norwood',
      state: 'VA',
    },
    24586: {
      lat: 36.60776,
      lng: -79.2828,
      city: 'Ringgold',
      state: 'VA',
    },
    24588: {
      lat: 37.26408,
      lng: -79.09997,
      city: 'Rustburg',
      state: 'VA',
    },
    24589: {
      lat: 36.75528,
      lng: -78.75555,
      city: 'Scottsburg',
      state: 'VA',
    },
    24590: {
      lat: 37.79353,
      lng: -78.47767,
      city: 'Scottsville',
      state: 'VA',
    },
    24592: {
      lat: 36.67808,
      lng: -78.95474,
      city: 'South Boston',
      state: 'VA',
    },
    24593: {
      lat: 37.32651,
      lng: -78.91342,
      city: 'Spout Spring',
      state: 'VA',
    },
    24594: {
      lat: 36.64217,
      lng: -79.18504,
      city: 'Sutherlin',
      state: 'VA',
    },
    24595: {
      lat: 37.55904,
      lng: -79.08421,
      city: 'Sweet Briar',
      state: 'VA',
    },
    24597: {
      lat: 36.78553,
      lng: -79.1095,
      city: 'Vernon Hill',
      state: 'VA',
    },
    24598: {
      lat: 36.60521,
      lng: -78.7828,
      city: 'Virgilina',
      state: 'VA',
    },
    24599: {
      lat: 37.63289,
      lng: -78.72452,
      city: 'Wingina',
      state: 'VA',
    },
    24601: {
      lat: 37.19068,
      lng: -81.64049,
      city: 'Amonate',
      state: 'VA',
    },
    24602: {
      lat: 37.18383,
      lng: -81.65762,
      city: 'Bandy',
      state: 'VA',
    },
    24603: {
      lat: 37.35607,
      lng: -82.1914,
      city: 'Big Rock',
      state: 'VA',
    },
    24604: {
      lat: 37.21088,
      lng: -81.54378,
      city: 'Bishop',
      state: 'VA',
    },
    24605: {
      lat: 37.24093,
      lng: -81.36123,
      city: 'Bluefield',
      state: 'VA',
    },
    24606: {
      lat: 37.28814,
      lng: -81.39183,
      city: 'Boissevain',
      state: 'VA',
    },
    24607: {
      lat: 37.29498,
      lng: -82.26533,
      city: 'Breaks',
      state: 'VA',
    },
    24609: {
      lat: 37.02955,
      lng: -81.795,
      city: 'Cedar Bluff',
      state: 'VA',
    },
    24612: {
      lat: 37.09728,
      lng: -81.84461,
      city: 'Doran',
      state: 'VA',
    },
    24613: {
      lat: 37.262,
      lng: -81.3385,
      city: 'Falls Mills',
      state: 'VA',
    },
    24614: {
      lat: 37.31466,
      lng: -82.07181,
      city: 'Grundy',
      state: 'VA',
    },
    24620: {
      lat: 37.43335,
      lng: -82.01932,
      city: 'Hurley',
      state: 'VA',
    },
    24622: {
      lat: 37.22005,
      lng: -81.79638,
      city: 'Jewell Ridge',
      state: 'VA',
    },
    24630: {
      lat: 37.17292,
      lng: -81.4782,
      city: 'North Tazewell',
      state: 'VA',
    },
    24631: {
      lat: 37.2202,
      lng: -81.99714,
      city: 'Oakwood',
      state: 'VA',
    },
    24634: {
      lat: 37.27411,
      lng: -81.90253,
      city: 'Pilgrims Knob',
      state: 'VA',
    },
    24635: {
      lat: 37.30683,
      lng: -81.34962,
      city: 'Pocahontas',
      state: 'VA',
    },
    24637: {
      lat: 37.05258,
      lng: -81.70981,
      city: 'Pounding Mill',
      state: 'VA',
    },
    24639: {
      lat: 37.17357,
      lng: -81.90461,
      city: 'Raven',
      state: 'VA',
    },
    24641: {
      lat: 37.11797,
      lng: -81.80802,
      city: 'Richlands',
      state: 'VA',
    },
    24646: { lat: 37.13751, lng: -82.02976, city: 'Rowe', state: 'VA' },
    24649: {
      lat: 37.08303,
      lng: -81.92198,
      city: 'Swords Creek',
      state: 'VA',
    },
    24651: {
      lat: 37.07732,
      lng: -81.50054,
      city: 'Tazewell',
      state: 'VA',
    },
    24656: {
      lat: 37.1743,
      lng: -82.12364,
      city: 'Vansant',
      state: 'VA',
    },
    24657: {
      lat: 37.22363,
      lng: -81.84737,
      city: 'Whitewood',
      state: 'VA',
    },
    24701: {
      lat: 37.29929,
      lng: -81.2065,
      city: 'Bluefield',
      state: 'WV',
    },
    24712: {
      lat: 37.46903,
      lng: -81.02029,
      city: 'Athens',
      state: 'WV',
    },
    24714: {
      lat: 37.47627,
      lng: -81.18352,
      city: 'Beeson',
      state: 'WV',
    },
    24715: {
      lat: 37.33408,
      lng: -81.32933,
      city: 'Bramwell',
      state: 'WV',
    },
    24716: { lat: 37.53331, lng: -81.37337, city: 'Bud', state: 'WV' },
    24719: {
      lat: 37.48722,
      lng: -81.32652,
      city: 'Covel',
      state: 'WV',
    },
    24724: {
      lat: 37.33603,
      lng: -81.31579,
      city: 'Freeman',
      state: 'WV',
    },
    24726: {
      lat: 37.52084,
      lng: -81.37229,
      city: 'Herndon',
      state: 'WV',
    },
    24729: {
      lat: 37.44759,
      lng: -81.24699,
      city: 'Hiawatha',
      state: 'WV',
    },
    24731: {
      lat: 37.39733,
      lng: -81.15162,
      city: 'Kegley',
      state: 'WV',
    },
    24733: {
      lat: 37.44986,
      lng: -81.20094,
      city: 'Lashmeet',
      state: 'WV',
    },
    24736: {
      lat: 37.45045,
      lng: -81.29589,
      city: 'Matoaka',
      state: 'WV',
    },
    24737: {
      lat: 37.35042,
      lng: -81.26055,
      city: 'Montcalm',
      state: 'WV',
    },
    24738: {
      lat: 37.3032,
      lng: -81.32013,
      city: 'Nemours',
      state: 'WV',
    },
    24739: {
      lat: 37.36784,
      lng: -80.9949,
      city: 'Princeton',
      state: 'WV',
    },
    24740: {
      lat: 37.4042,
      lng: -81.10205,
      city: 'Princeton',
      state: 'WV',
    },
    24747: { lat: 37.40182, lng: -81.2273, city: 'Rock', state: 'WV' },
    24801: {
      lat: 37.46841,
      lng: -81.55544,
      city: 'Welch',
      state: 'WV',
    },
    24808: {
      lat: 37.33142,
      lng: -81.41048,
      city: 'Anawalt',
      state: 'WV',
    },
    24811: {
      lat: 37.40881,
      lng: -81.75381,
      city: 'Avondale',
      state: 'WV',
    },
    24813: {
      lat: 37.35954,
      lng: -81.72489,
      city: 'Bartley',
      state: 'WV',
    },
    24815: {
      lat: 37.22036,
      lng: -81.63026,
      city: 'Berwind',
      state: 'WV',
    },
    24816: {
      lat: 37.46139,
      lng: -81.70516,
      city: 'Big Sandy',
      state: 'WV',
    },
    24817: {
      lat: 37.34206,
      lng: -81.81721,
      city: 'Bradshaw',
      state: 'WV',
    },
    24818: {
      lat: 37.60809,
      lng: -81.62275,
      city: 'Brenton',
      state: 'WV',
    },
    24822: {
      lat: 37.62908,
      lng: -81.68755,
      city: 'Clear Fork',
      state: 'WV',
    },
    24823: {
      lat: 37.66761,
      lng: -81.74105,
      city: 'Coal Mountain',
      state: 'WV',
    },
    24826: {
      lat: 37.29212,
      lng: -81.62411,
      city: 'Cucumber',
      state: 'WV',
    },
    24827: {
      lat: 37.75186,
      lng: -81.65997,
      city: 'Cyclone',
      state: 'WV',
    },
    24828: { lat: 37.49975, lng: -81.6553, city: 'Davy', state: 'WV' },
    24830: {
      lat: 37.33399,
      lng: -81.55799,
      city: 'Elbert',
      state: 'WV',
    },
    24831: {
      lat: 37.38943,
      lng: -81.44085,
      city: 'Elkhorn',
      state: 'WV',
    },
    24834: {
      lat: 37.54313,
      lng: -81.66093,
      city: 'Fanrock',
      state: 'WV',
    },
    24836: { lat: 37.36846, lng: -81.54908, city: 'Gary', state: 'WV' },
    24839: {
      lat: 37.58216,
      lng: -81.76504,
      city: 'Hanover',
      state: 'WV',
    },
    24843: {
      lat: 37.48378,
      lng: -81.70923,
      city: 'Hensley',
      state: 'WV',
    },
    24844: {
      lat: 37.48554,
      lng: -81.78703,
      city: 'Iaeger',
      state: 'WV',
    },
    24845: {
      lat: 37.52643,
      lng: -81.80041,
      city: 'Ikes Fork',
      state: 'WV',
    },
    24846: {
      lat: 37.52611,
      lng: -81.89175,
      city: 'Isaban',
      state: 'WV',
    },
    24847: {
      lat: 37.57339,
      lng: -81.41455,
      city: 'Itmann',
      state: 'WV',
    },
    24848: {
      lat: 37.28206,
      lng: -81.43413,
      city: 'Jenkinjones',
      state: 'WV',
    },
    24849: {
      lat: 37.67435,
      lng: -81.55848,
      city: 'Jesse',
      state: 'WV',
    },
    24850: { lat: 37.37807, lng: -81.88516, city: 'Jolo', state: 'WV' },
    24851: {
      lat: 37.59922,
      lng: -81.83805,
      city: 'Justice',
      state: 'WV',
    },
    24853: {
      lat: 37.42924,
      lng: -81.51686,
      city: 'Kimball',
      state: 'WV',
    },
    24854: {
      lat: 37.73997,
      lng: -81.5789,
      city: 'Kopperston',
      state: 'WV',
    },
    24855: { lat: 37.40882, lng: -81.42499, city: 'Kyle', state: 'WV' },
    24857: {
      lat: 37.69591,
      lng: -81.67557,
      city: 'Lynco',
      state: 'WV',
    },
    24860: {
      lat: 37.65919,
      lng: -81.59075,
      city: 'Matheny',
      state: 'WV',
    },
    24861: {
      lat: 37.36182,
      lng: -81.36525,
      city: 'Maybeury',
      state: 'WV',
    },
    24862: {
      lat: 37.47905,
      lng: -81.9489,
      city: 'Mohawk',
      state: 'WV',
    },
    24866: {
      lat: 37.25216,
      lng: -81.59925,
      city: 'Newhall',
      state: 'WV',
    },
    24867: {
      lat: 37.60432,
      lng: -81.45261,
      city: 'New Richmond',
      state: 'WV',
    },
    24868: {
      lat: 37.42412,
      lng: -81.40259,
      city: 'Northfork',
      state: 'WV',
    },
    24869: {
      lat: 37.56133,
      lng: -81.82315,
      city: 'North Spring',
      state: 'WV',
    },
    24870: {
      lat: 37.73784,
      lng: -81.53203,
      city: 'Oceana',
      state: 'WV',
    },
    24871: {
      lat: 37.31693,
      lng: -81.48552,
      city: 'Pageton',
      state: 'WV',
    },
    24872: {
      lat: 37.44986,
      lng: -81.8932,
      city: 'Panther',
      state: 'WV',
    },
    24873: {
      lat: 37.35734,
      lng: -81.87415,
      city: 'Paynesville',
      state: 'WV',
    },
    24874: {
      lat: 37.60201,
      lng: -81.53072,
      city: 'Pineville',
      state: 'WV',
    },
    24878: {
      lat: 37.4321,
      lng: -81.63086,
      city: 'Premier',
      state: 'WV',
    },
    24879: {
      lat: 37.31639,
      lng: -81.76725,
      city: 'Raysal',
      state: 'WV',
    },
    24880: {
      lat: 37.64679,
      lng: -81.53187,
      city: 'Rock View',
      state: 'WV',
    },
    24881: {
      lat: 37.44435,
      lng: -81.68785,
      city: 'Roderfield',
      state: 'WV',
    },
    24882: {
      lat: 37.62771,
      lng: -81.76623,
      city: 'Simon',
      state: 'WV',
    },
    24884: {
      lat: 37.27943,
      lng: -81.55048,
      city: 'Squire',
      state: 'WV',
    },
    24887: {
      lat: 37.37808,
      lng: -81.38664,
      city: 'Switchback',
      state: 'WV',
    },
    24888: {
      lat: 37.36195,
      lng: -81.48876,
      city: 'Thorpe',
      state: 'WV',
    },
    24892: { lat: 37.31691, lng: -81.69699, city: 'War', state: 'WV' },
    24894: {
      lat: 37.25186,
      lng: -81.69052,
      city: 'Warriormine',
      state: 'WV',
    },
    24895: {
      lat: 37.37718,
      lng: -81.58208,
      city: 'Wilcoe',
      state: 'WV',
    },
    24898: {
      lat: 37.58752,
      lng: -81.57504,
      city: 'Wyoming',
      state: 'WV',
    },
    24901: {
      lat: 37.85298,
      lng: -80.47598,
      city: 'Lewisburg',
      state: 'WV',
    },
    24910: {
      lat: 37.74173,
      lng: -80.66835,
      city: 'Alderson',
      state: 'WV',
    },
    24915: {
      lat: 38.4693,
      lng: -79.78668,
      city: 'Arbovale',
      state: 'WV',
    },
    24916: {
      lat: 37.82735,
      lng: -80.57735,
      city: 'Asbury',
      state: 'WV',
    },
    24918: {
      lat: 37.51793,
      lng: -80.75461,
      city: 'Ballard',
      state: 'WV',
    },
    24920: {
      lat: 38.58918,
      lng: -79.71723,
      city: 'Bartow',
      state: 'WV',
    },
    24924: {
      lat: 38.19294,
      lng: -80.15174,
      city: 'Buckeye',
      state: 'WV',
    },
    24925: {
      lat: 37.72919,
      lng: -80.38348,
      city: 'Caldwell',
      state: 'WV',
    },
    24927: { lat: 38.38999, lng: -79.965, city: 'Cass', state: 'WV' },
    24931: {
      lat: 37.91471,
      lng: -80.59355,
      city: 'Crawley',
      state: 'WV',
    },
    24934: {
      lat: 38.32836,
      lng: -79.89267,
      city: 'Dunmore',
      state: 'WV',
    },
    24935: {
      lat: 37.56362,
      lng: -80.80344,
      city: 'Forest Hill',
      state: 'WV',
    },
    24938: {
      lat: 37.92516,
      lng: -80.38083,
      city: 'Frankford',
      state: 'WV',
    },
    24941: {
      lat: 37.58824,
      lng: -80.3577,
      city: 'Gap Mills',
      state: 'WV',
    },
    24943: {
      lat: 37.83632,
      lng: -80.72709,
      city: 'Grassy Meadows',
      state: 'WV',
    },
    24944: {
      lat: 38.39926,
      lng: -79.77108,
      city: 'Green Bank',
      state: 'WV',
    },
    24945: {
      lat: 37.54113,
      lng: -80.68795,
      city: 'Greenville',
      state: 'WV',
    },
    24946: {
      lat: 38.17891,
      lng: -80.2671,
      city: 'Hillsboro',
      state: 'WV',
    },
    24951: {
      lat: 37.48358,
      lng: -80.63103,
      city: 'Lindside',
      state: 'WV',
    },
    24954: {
      lat: 38.22932,
      lng: -80.07436,
      city: 'Marlinton',
      state: 'WV',
    },
    24957: {
      lat: 37.88729,
      lng: -80.40827,
      city: 'Maxwelton',
      state: 'WV',
    },
    24962: {
      lat: 37.66029,
      lng: -80.72041,
      city: 'Pence Springs',
      state: 'WV',
    },
    24963: {
      lat: 37.43743,
      lng: -80.77617,
      city: 'Peterstown',
      state: 'WV',
    },
    24966: {
      lat: 38.03345,
      lng: -80.35283,
      city: 'Renick',
      state: 'WV',
    },
    24970: {
      lat: 37.73238,
      lng: -80.47574,
      city: 'Ronceverte',
      state: 'WV',
    },
    24974: {
      lat: 37.65395,
      lng: -80.44693,
      city: 'Secondcreek',
      state: 'WV',
    },
    24976: {
      lat: 37.66513,
      lng: -80.52273,
      city: 'Sinks Grove',
      state: 'WV',
    },
    24977: {
      lat: 37.90823,
      lng: -80.67527,
      city: 'Smoot',
      state: 'WV',
    },
    24981: {
      lat: 37.63256,
      lng: -80.73968,
      city: 'Talcott',
      state: 'WV',
    },
    24983: {
      lat: 37.59165,
      lng: -80.51672,
      city: 'Union',
      state: 'WV',
    },
    24984: {
      lat: 37.48942,
      lng: -80.41749,
      city: 'Waiteville',
      state: 'WV',
    },
    24985: {
      lat: 37.59989,
      lng: -80.68948,
      city: 'Wayside',
      state: 'WV',
    },
    24986: {
      lat: 37.91189,
      lng: -80.19455,
      city: 'White Sulphur Springs',
      state: 'WV',
    },
    24991: {
      lat: 38.00702,
      lng: -80.48972,
      city: 'Williamsburg',
      state: 'WV',
    },
    24993: {
      lat: 37.66095,
      lng: -80.63512,
      city: 'Wolfcreek',
      state: 'WV',
    },
    25002: {
      lat: 38.13087,
      lng: -81.25466,
      city: 'Alloy',
      state: 'WV',
    },
    25003: {
      lat: 38.25101,
      lng: -81.77925,
      city: 'Alum Creek',
      state: 'WV',
    },
    25005: { lat: 38.58201, lng: -81.26982, city: 'Amma', state: 'WV' },
    25007: {
      lat: 37.8252,
      lng: -81.42766,
      city: 'Arnett',
      state: 'WV',
    },
    25008: {
      lat: 37.95044,
      lng: -81.35766,
      city: 'Artie',
      state: 'WV',
    },
    25009: {
      lat: 38.19237,
      lng: -81.71301,
      city: 'Ashford',
      state: 'WV',
    },
    25011: {
      lat: 38.51169,
      lng: -81.8363,
      city: 'Bancroft',
      state: 'WV',
    },
    25015: {
      lat: 38.24293,
      lng: -81.49226,
      city: 'Belle',
      state: 'WV',
    },
    25019: {
      lat: 38.37663,
      lng: -81.08955,
      city: 'Bickmore',
      state: 'WV',
    },
    25021: { lat: 37.91587, lng: -81.68482, city: 'Bim', state: 'WV' },
    25022: {
      lat: 37.84157,
      lng: -81.79241,
      city: 'Blair',
      state: 'WV',
    },
    25024: {
      lat: 38.15744,
      lng: -81.6266,
      city: 'Bloomingrose',
      state: 'WV',
    },
    25025: {
      lat: 38.29378,
      lng: -81.4018,
      city: 'Blount',
      state: 'WV',
    },
    25028: {
      lat: 37.95598,
      lng: -81.72535,
      city: 'Bob White',
      state: 'WV',
    },
    25030: {
      lat: 38.45028,
      lng: -81.22599,
      city: 'Bomont',
      state: 'WV',
    },
    25031: {
      lat: 38.14615,
      lng: -81.2801,
      city: 'Boomer',
      state: 'WV',
    },
    25033: {
      lat: 38.61095,
      lng: -81.92511,
      city: 'Buffalo',
      state: 'WV',
    },
    25035: {
      lat: 38.16339,
      lng: -81.52399,
      city: 'Cabin Creek',
      state: 'WV',
    },
    25036: {
      lat: 38.19168,
      lng: -81.26438,
      city: 'Cannelton',
      state: 'WV',
    },
    25039: {
      lat: 38.24642,
      lng: -81.36524,
      city: 'Cedar Grove',
      state: 'WV',
    },
    25040: {
      lat: 38.13673,
      lng: -81.24385,
      city: 'Charlton Heights',
      state: 'WV',
    },
    25043: { lat: 38.44092, lng: -80.98981, city: 'Clay', state: 'WV' },
    25044: {
      lat: 37.91139,
      lng: -81.32118,
      city: 'Clear Creek',
      state: 'WV',
    },
    25045: {
      lat: 38.45603,
      lng: -81.32474,
      city: 'Clendenin',
      state: 'WV',
    },
    25047: {
      lat: 37.9219,
      lng: -81.76027,
      city: 'Clothier',
      state: 'WV',
    },
    25048: {
      lat: 37.95442,
      lng: -81.44656,
      city: 'Colcord',
      state: 'WV',
    },
    25049: {
      lat: 38.11954,
      lng: -81.57315,
      city: 'Comfort',
      state: 'WV',
    },
    25051: {
      lat: 38.15885,
      lng: -81.70929,
      city: 'Costa',
      state: 'WV',
    },
    25053: {
      lat: 38.04725,
      lng: -81.87633,
      city: 'Danville',
      state: 'WV',
    },
    25054: {
      lat: 38.12226,
      lng: -81.45044,
      city: 'Dawes',
      state: 'WV',
    },
    25057: {
      lat: 38.1078,
      lng: -81.23783,
      city: 'Deep Water',
      state: 'WV',
    },
    25059: { lat: 38.25422, lng: -81.2153, city: 'Dixie', state: 'WV' },
    25060: {
      lat: 37.97058,
      lng: -81.47286,
      city: 'Dorothy',
      state: 'WV',
    },
    25061: {
      lat: 38.1751,
      lng: -81.43342,
      city: 'Drybranch',
      state: 'WV',
    },
    25062: {
      lat: 37.87549,
      lng: -81.44411,
      city: 'Dry Creek',
      state: 'WV',
    },
    25063: { lat: 38.57761, lng: -80.92572, city: 'Duck', state: 'WV' },
    25064: {
      lat: 38.37631,
      lng: -81.74764,
      city: 'Dunbar',
      state: 'WV',
    },
    25067: {
      lat: 38.20963,
      lng: -81.44018,
      city: 'East Bank',
      state: 'WV',
    },
    25070: {
      lat: 38.54667,
      lng: -81.93179,
      city: 'Eleanor',
      state: 'WV',
    },
    25071: {
      lat: 38.47732,
      lng: -81.46756,
      city: 'Elkview',
      state: 'WV',
    },
    25075: {
      lat: 38.0526,
      lng: -81.42915,
      city: 'Eskdale',
      state: 'WV',
    },
    25076: { lat: 37.86889, lng: -81.937, city: 'Ethel', state: 'WV' },
    25081: {
      lat: 38.08235,
      lng: -81.76875,
      city: 'Foster',
      state: 'WV',
    },
    25082: {
      lat: 38.60861,
      lng: -82.02879,
      city: 'Fraziers Bottom',
      state: 'WV',
    },
    25083: {
      lat: 38.06809,
      lng: -81.36118,
      city: 'Gallagher',
      state: 'WV',
    },
    25085: {
      lat: 38.16762,
      lng: -81.18867,
      city: 'Gauley Bridge',
      state: 'WV',
    },
    25086: {
      lat: 38.21326,
      lng: -81.41238,
      city: 'Glasgow',
      state: 'WV',
    },
    25088: { lat: 38.37963, lng: -81.21551, city: 'Glen', state: 'WV' },
    25090: {
      lat: 38.15544,
      lng: -81.21655,
      city: 'Glen Ferris',
      state: 'WV',
    },
    25093: {
      lat: 37.9653,
      lng: -81.64137,
      city: 'Gordon',
      state: 'WV',
    },
    25102: {
      lat: 38.16735,
      lng: -81.35192,
      city: 'Handley',
      state: 'WV',
    },
    25103: {
      lat: 38.18207,
      lng: -81.38225,
      city: 'Hansford',
      state: 'WV',
    },
    25106: {
      lat: 38.77598,
      lng: -82.08237,
      city: 'Henderson',
      state: 'WV',
    },
    25107: {
      lat: 38.22427,
      lng: -81.64493,
      city: 'Hernshaw',
      state: 'WV',
    },
    25108: {
      lat: 37.9655,
      lng: -81.87289,
      city: 'Hewett',
      state: 'WV',
    },
    25109: {
      lat: 38.53097,
      lng: -81.86062,
      city: 'Hometown',
      state: 'WV',
    },
    25110: {
      lat: 38.21324,
      lng: -81.3264,
      city: 'Hugheston',
      state: 'WV',
    },
    25111: {
      lat: 38.34841,
      lng: -81.12591,
      city: 'Indore',
      state: 'WV',
    },
    25112: {
      lat: 38.38103,
      lng: -81.76633,
      city: 'Institute',
      state: 'WV',
    },
    25113: {
      lat: 38.55741,
      lng: -81.03338,
      city: 'Ivydale',
      state: 'WV',
    },
    25114: {
      lat: 37.98199,
      lng: -81.7986,
      city: 'Jeffrey',
      state: 'WV',
    },
    25115: {
      lat: 38.13065,
      lng: -81.18997,
      city: 'Kanawha Falls',
      state: 'WV',
    },
    25118: {
      lat: 38.11882,
      lng: -81.32294,
      city: 'Kimberly',
      state: 'WV',
    },
    25119: {
      lat: 38.03362,
      lng: -81.27494,
      city: 'Kincaid',
      state: 'WV',
    },
    25121: { lat: 37.91167, lng: -81.87787, city: 'Lake', state: 'WV' },
    25123: { lat: 38.73172, lng: -81.90214, city: 'Leon', state: 'WV' },
    25124: {
      lat: 38.62006,
      lng: -81.76536,
      city: 'Liberty',
      state: 'WV',
    },
    25125: {
      lat: 38.31554,
      lng: -81.19074,
      city: 'Lizemores',
      state: 'WV',
    },
    25126: {
      lat: 38.19058,
      lng: -81.35382,
      city: 'London',
      state: 'WV',
    },
    25130: {
      lat: 38.02776,
      lng: -81.7455,
      city: 'Madison',
      state: 'WV',
    },
    25132: {
      lat: 38.29328,
      lng: -81.33441,
      city: 'Mammoth',
      state: 'WV',
    },
    25133: {
      lat: 38.47976,
      lng: -81.11831,
      city: 'Maysel',
      state: 'WV',
    },
    25134: {
      lat: 38.13596,
      lng: -81.48553,
      city: 'Miami',
      state: 'WV',
    },
    25136: {
      lat: 38.15864,
      lng: -81.31977,
      city: 'Montgomery',
      state: 'WV',
    },
    25139: {
      lat: 38.11038,
      lng: -81.2874,
      city: 'Mount Carbon',
      state: 'WV',
    },
    25140: {
      lat: 37.86738,
      lng: -81.49733,
      city: 'Naoma',
      state: 'WV',
    },
    25141: { lat: 38.6305, lng: -81.03592, city: 'Nebo', state: 'WV' },
    25142: {
      lat: 38.15001,
      lng: -81.73066,
      city: 'Nellis',
      state: 'WV',
    },
    25143: {
      lat: 38.42001,
      lng: -81.82414,
      city: 'Nitro',
      state: 'WV',
    },
    25148: {
      lat: 38.03511,
      lng: -81.53695,
      city: 'Orgas',
      state: 'WV',
    },
    25149: { lat: 37.9558, lng: -81.7745, city: 'Ottawa', state: 'WV' },
    25152: { lat: 38.0411, lng: -81.24004, city: 'Page', state: 'WV' },
    25154: {
      lat: 38.11572,
      lng: -81.70249,
      city: 'Peytona',
      state: 'WV',
    },
    25156: {
      lat: 38.41181,
      lng: -81.48217,
      city: 'Pinch',
      state: 'WV',
    },
    25159: { lat: 38.50186, lng: -81.7806, city: 'Poca', state: 'WV' },
    25160: {
      lat: 38.29884,
      lng: -81.27314,
      city: 'Pond Gap',
      state: 'WV',
    },
    25161: {
      lat: 38.06123,
      lng: -81.3189,
      city: 'Powellton',
      state: 'WV',
    },
    25162: {
      lat: 38.20712,
      lng: -81.38435,
      city: 'Pratt',
      state: 'WV',
    },
    25164: {
      lat: 38.50406,
      lng: -81.19373,
      city: 'Procious',
      state: 'WV',
    },
    25165: {
      lat: 38.14907,
      lng: -81.65308,
      city: 'Racine',
      state: 'WV',
    },
    25168: {
      lat: 38.56394,
      lng: -81.87733,
      city: 'Red House',
      state: 'WV',
    },
    25169: {
      lat: 38.12788,
      lng: -81.76288,
      city: 'Ridgeview',
      state: 'WV',
    },
    25173: {
      lat: 38.07519,
      lng: -81.21898,
      city: 'Robson',
      state: 'WV',
    },
    25174: {
      lat: 37.86115,
      lng: -81.41376,
      city: 'Rock Creek',
      state: 'WV',
    },
    25177: {
      lat: 38.36928,
      lng: -81.85021,
      city: 'Saint Albans',
      state: 'WV',
    },
    25180: {
      lat: 37.78961,
      lng: -81.43316,
      city: 'Saxon',
      state: 'WV',
    },
    25181: { lat: 38.04962, lng: -81.63476, city: 'Seth', state: 'WV' },
    25183: {
      lat: 37.9074,
      lng: -81.79745,
      city: 'Sharples',
      state: 'WV',
    },
    25185: {
      lat: 38.22149,
      lng: -81.23587,
      city: 'Mount Olive',
      state: 'WV',
    },
    25186: {
      lat: 38.17113,
      lng: -81.29811,
      city: 'Smithers',
      state: 'WV',
    },
    25187: {
      lat: 38.72617,
      lng: -82.01878,
      city: 'Southside',
      state: 'WV',
    },
    25193: {
      lat: 38.01672,
      lng: -81.55089,
      city: 'Sylvester',
      state: 'WV',
    },
    25201: { lat: 38.3489, lng: -81.48645, city: 'Tad', state: 'WV' },
    25202: {
      lat: 38.31593,
      lng: -81.85372,
      city: 'Tornado',
      state: 'WV',
    },
    25203: {
      lat: 38.02125,
      lng: -81.88162,
      city: 'Turtle Creek',
      state: 'WV',
    },
    25204: {
      lat: 37.91546,
      lng: -81.60914,
      city: 'Twilight',
      state: 'WV',
    },
    25205: {
      lat: 38.01841,
      lng: -81.78926,
      city: 'Uneeda',
      state: 'WV',
    },
    25206: { lat: 37.9596, lng: -81.69296, city: 'Van', state: 'WV' },
    25208: {
      lat: 37.84579,
      lng: -81.64282,
      city: 'Wharton',
      state: 'WV',
    },
    25209: {
      lat: 37.96271,
      lng: -81.52861,
      city: 'Whitesville',
      state: 'WV',
    },
    25211: {
      lat: 38.46107,
      lng: -80.87932,
      city: 'Widen',
      state: 'WV',
    },
    25213: {
      lat: 38.50776,
      lng: -81.91796,
      city: 'Winfield',
      state: 'WV',
    },
    25214: {
      lat: 38.18947,
      lng: -81.55127,
      city: 'Winifrede',
      state: 'WV',
    },
    25231: { lat: 38.60464, lng: -81.591, city: 'Advent', state: 'WV' },
    25234: {
      lat: 38.81291,
      lng: -81.12343,
      city: 'Arnoldsburg',
      state: 'WV',
    },
    25235: {
      lat: 38.66939,
      lng: -81.08874,
      city: 'Chloe',
      state: 'WV',
    },
    25239: {
      lat: 38.84616,
      lng: -81.83941,
      city: 'Cottageville',
      state: 'WV',
    },
    25241: {
      lat: 38.78427,
      lng: -81.80801,
      city: 'Evans',
      state: 'WV',
    },
    25243: {
      lat: 38.67841,
      lng: -81.48216,
      city: 'Gandeeville',
      state: 'WV',
    },
    25244: { lat: 38.77068, lng: -81.54237, city: 'Gay', state: 'WV' },
    25245: { lat: 38.69916, lng: -81.7393, city: 'Given', state: 'WV' },
    25247: {
      lat: 39.001,
      lng: -81.98987,
      city: 'Hartford',
      state: 'WV',
    },
    25248: {
      lat: 38.64255,
      lng: -81.61454,
      city: 'Kenna',
      state: 'WV',
    },
    25251: {
      lat: 38.62119,
      lng: -81.23173,
      city: 'Left Hand',
      state: 'WV',
    },
    25252: {
      lat: 38.88345,
      lng: -81.53679,
      city: 'Le Roy',
      state: 'WV',
    },
    25253: {
      lat: 38.91947,
      lng: -81.9669,
      city: 'Letart',
      state: 'WV',
    },
    25259: {
      lat: 38.67465,
      lng: -81.24836,
      city: 'Looneyville',
      state: 'WV',
    },
    25260: {
      lat: 39.01653,
      lng: -82.02465,
      city: 'Mason',
      state: 'WV',
    },
    25261: {
      lat: 38.83027,
      lng: -81.08886,
      city: 'Millstone',
      state: 'WV',
    },
    25262: {
      lat: 38.90751,
      lng: -81.83219,
      city: 'Millwood',
      state: 'WV',
    },
    25264: {
      lat: 38.85547,
      lng: -81.89516,
      city: 'Mount Alto',
      state: 'WV',
    },
    25265: {
      lat: 38.98129,
      lng: -81.97356,
      city: 'New Haven',
      state: 'WV',
    },
    25266: {
      lat: 38.60224,
      lng: -81.1589,
      city: 'Newton',
      state: 'WV',
    },
    25267: {
      lat: 38.85778,
      lng: -80.94187,
      city: 'Normantown',
      state: 'WV',
    },
    25268: { lat: 38.73969, lng: -81.107, city: 'Orma', state: 'WV' },
    25270: {
      lat: 38.88174,
      lng: -81.43395,
      city: 'Reedy',
      state: 'WV',
    },
    25271: {
      lat: 38.78845,
      lng: -81.68625,
      city: 'Ripley',
      state: 'WV',
    },
    25275: {
      lat: 38.92451,
      lng: -81.61687,
      city: 'Sandyville',
      state: 'WV',
    },
    25276: {
      lat: 38.78803,
      lng: -81.33203,
      city: 'Spencer',
      state: 'WV',
    },
    25285: {
      lat: 38.54723,
      lng: -81.1129,
      city: 'Wallback',
      state: 'WV',
    },
    25286: {
      lat: 38.62098,
      lng: -81.39728,
      city: 'Walton',
      state: 'WV',
    },
    25287: {
      lat: 38.96158,
      lng: -82.04834,
      city: 'West Columbia',
      state: 'WV',
    },
    25301: {
      lat: 38.35123,
      lng: -81.62761,
      city: 'Charleston',
      state: 'WV',
    },
    25302: {
      lat: 38.39053,
      lng: -81.59879,
      city: 'Charleston',
      state: 'WV',
    },
    25303: {
      lat: 38.36033,
      lng: -81.68679,
      city: 'South Charleston',
      state: 'WV',
    },
    25304: {
      lat: 38.30521,
      lng: -81.59044,
      city: 'Charleston',
      state: 'WV',
    },
    25305: {
      lat: 38.33682,
      lng: -81.61313,
      city: 'Charleston',
      state: 'WV',
    },
    25306: {
      lat: 38.31071,
      lng: -81.49087,
      city: 'Charleston',
      state: 'WV',
    },
    25309: {
      lat: 38.31177,
      lng: -81.75715,
      city: 'South Charleston',
      state: 'WV',
    },
    25311: {
      lat: 38.36308,
      lng: -81.55357,
      city: 'Charleston',
      state: 'WV',
    },
    25312: {
      lat: 38.46156,
      lng: -81.656,
      city: 'Charleston',
      state: 'WV',
    },
    25313: {
      lat: 38.41547,
      lng: -81.75704,
      city: 'Charleston',
      state: 'WV',
    },
    25314: {
      lat: 38.29705,
      lng: -81.65463,
      city: 'Charleston',
      state: 'WV',
    },
    25315: {
      lat: 38.21032,
      lng: -81.57992,
      city: 'Charleston',
      state: 'WV',
    },
    25320: {
      lat: 38.54124,
      lng: -81.62706,
      city: 'Charleston',
      state: 'WV',
    },
    25387: {
      lat: 38.38523,
      lng: -81.6642,
      city: 'Charleston',
      state: 'WV',
    },
    25401: {
      lat: 39.45642,
      lng: -77.97267,
      city: 'Martinsburg',
      state: 'WV',
    },
    25403: {
      lat: 39.4842,
      lng: -78.00725,
      city: 'Martinsburg',
      state: 'WV',
    },
    25404: {
      lat: 39.48631,
      lng: -77.89782,
      city: 'Martinsburg',
      state: 'WV',
    },
    25405: {
      lat: 39.40892,
      lng: -77.96235,
      city: 'Martinsburg',
      state: 'WV',
    },
    25411: {
      lat: 39.55497,
      lng: -78.22004,
      city: 'Berkeley Springs',
      state: 'WV',
    },
    25413: {
      lat: 39.31632,
      lng: -78.053,
      city: 'Bunker Hill',
      state: 'WV',
    },
    25414: {
      lat: 39.25273,
      lng: -77.87055,
      city: 'Charles Town',
      state: 'WV',
    },
    25419: {
      lat: 39.58286,
      lng: -77.88493,
      city: 'Falling Waters',
      state: 'WV',
    },
    25420: {
      lat: 39.38095,
      lng: -78.11969,
      city: 'Gerrardstown',
      state: 'WV',
    },
    25421: {
      lat: 39.37821,
      lng: -78.16384,
      city: 'Glengary',
      state: 'WV',
    },
    25422: {
      lat: 39.55812,
      lng: -78.35887,
      city: 'Great Cacapon',
      state: 'WV',
    },
    25425: {
      lat: 39.27613,
      lng: -77.79184,
      city: 'Harpers Ferry',
      state: 'WV',
    },
    25427: {
      lat: 39.52608,
      lng: -78.09098,
      city: 'Hedgesville',
      state: 'WV',
    },
    25428: {
      lat: 39.37407,
      lng: -78.02729,
      city: 'Inwood',
      state: 'WV',
    },
    25430: {
      lat: 39.34024,
      lng: -77.93899,
      city: 'Kearneysville',
      state: 'WV',
    },
    25431: {
      lat: 39.48682,
      lng: -78.57279,
      city: 'Levels',
      state: 'WV',
    },
    25432: {
      lat: 39.29562,
      lng: -77.78663,
      city: 'Millville',
      state: 'WV',
    },
    25434: {
      lat: 39.45797,
      lng: -78.45038,
      city: 'Paw Paw',
      state: 'WV',
    },
    25437: {
      lat: 39.4225,
      lng: -78.57623,
      city: 'Points',
      state: 'WV',
    },
    25438: {
      lat: 39.31853,
      lng: -77.86448,
      city: 'Ranson',
      state: 'WV',
    },
    25442: {
      lat: 39.37157,
      lng: -77.83181,
      city: 'Shenandoah Junction',
      state: 'WV',
    },
    25443: {
      lat: 39.43835,
      lng: -77.81477,
      city: 'Shepherdstown',
      state: 'WV',
    },
    25444: {
      lat: 39.43543,
      lng: -78.52081,
      city: 'Slanesville',
      state: 'WV',
    },
    25446: {
      lat: 39.24013,
      lng: -77.95705,
      city: 'Summit Point',
      state: 'WV',
    },
    25501: {
      lat: 38.16035,
      lng: -81.95582,
      city: 'Alkol',
      state: 'WV',
    },
    25502: {
      lat: 38.66501,
      lng: -82.11024,
      city: 'Apple Grove',
      state: 'WV',
    },
    25503: {
      lat: 38.60835,
      lng: -82.11666,
      city: 'Ashton',
      state: 'WV',
    },
    25504: {
      lat: 38.37297,
      lng: -82.26674,
      city: 'Barboursville',
      state: 'WV',
    },
    25505: {
      lat: 38.01967,
      lng: -82.03075,
      city: 'Big Creek',
      state: 'WV',
    },
    25506: {
      lat: 38.20826,
      lng: -82.18665,
      city: 'Branchland',
      state: 'WV',
    },
    25507: {
      lat: 38.39945,
      lng: -82.55193,
      city: 'Ceredo',
      state: 'WV',
    },
    25508: {
      lat: 37.96388,
      lng: -82.01394,
      city: 'Chapmanville',
      state: 'WV',
    },
    25510: {
      lat: 38.38832,
      lng: -82.06599,
      city: 'Culloden',
      state: 'WV',
    },
    25511: {
      lat: 38.03194,
      lng: -82.35637,
      city: 'Dunlow',
      state: 'WV',
    },
    25512: {
      lat: 38.17784,
      lng: -82.32056,
      city: 'East Lynn',
      state: 'WV',
    },
    25514: {
      lat: 38.08136,
      lng: -82.51678,
      city: 'Fort Gay',
      state: 'WV',
    },
    25515: {
      lat: 38.75731,
      lng: -82.15262,
      city: 'Gallipolis Ferry',
      state: 'WV',
    },
    25517: {
      lat: 38.10989,
      lng: -82.45368,
      city: 'Genoa',
      state: 'WV',
    },
    25520: {
      lat: 38.55773,
      lng: -82.17899,
      city: 'Glenwood',
      state: 'WV',
    },
    25521: {
      lat: 38.22915,
      lng: -81.98641,
      city: 'Griffithsville',
      state: 'WV',
    },
    25523: {
      lat: 38.27934,
      lng: -82.06754,
      city: 'Hamlin',
      state: 'WV',
    },
    25524: {
      lat: 38.01309,
      lng: -82.11712,
      city: 'Harts',
      state: 'WV',
    },
    25526: {
      lat: 38.3984,
      lng: -81.98279,
      city: 'Hurricane',
      state: 'WV',
    },
    25529: {
      lat: 38.15573,
      lng: -81.8245,
      city: 'Julian',
      state: 'WV',
    },
    25530: {
      lat: 38.35629,
      lng: -82.56337,
      city: 'Kenova',
      state: 'WV',
    },
    25534: {
      lat: 38.08065,
      lng: -82.27781,
      city: 'Kiahsville',
      state: 'WV',
    },
    25535: {
      lat: 38.30772,
      lng: -82.43405,
      city: 'Lavalette',
      state: 'WV',
    },
    25537: {
      lat: 38.53399,
      lng: -82.26926,
      city: 'Lesage',
      state: 'WV',
    },
    25540: {
      lat: 38.15709,
      lng: -82.13914,
      city: 'Midkiff',
      state: 'WV',
    },
    25541: {
      lat: 38.45884,
      lng: -82.12707,
      city: 'Milton',
      state: 'WV',
    },
    25544: { lat: 38.23078, lng: -82.11017, city: 'Myra', state: 'WV' },
    25545: { lat: 38.45847, lng: -82.22456, city: 'Ona', state: 'WV' },
    25547: {
      lat: 37.92627,
      lng: -81.9537,
      city: 'Pecks Mill',
      state: 'WV',
    },
    25550: {
      lat: 38.86655,
      lng: -82.0749,
      city: 'Point Pleasant',
      state: 'WV',
    },
    25555: {
      lat: 38.23525,
      lng: -82.55913,
      city: 'Prichard',
      state: 'WV',
    },
    25557: {
      lat: 38.09923,
      lng: -82.16262,
      city: 'Ranger',
      state: 'WV',
    },
    25559: {
      lat: 38.32753,
      lng: -82.23181,
      city: 'Salt Rock',
      state: 'WV',
    },
    25560: {
      lat: 38.44736,
      lng: -81.89908,
      city: 'Scott Depot',
      state: 'WV',
    },
    25564: { lat: 38.26302, lng: -81.88835, city: 'Sod', state: 'WV' },
    25565: {
      lat: 38.10998,
      lng: -81.96631,
      city: 'Spurlockville',
      state: 'WV',
    },
    25567: {
      lat: 38.21971,
      lng: -81.87294,
      city: 'Sumerco',
      state: 'WV',
    },
    25570: {
      lat: 38.23508,
      lng: -82.41927,
      city: 'Wayne',
      state: 'WV',
    },
    25571: {
      lat: 38.2947,
      lng: -82.15632,
      city: 'West Hamlin',
      state: 'WV',
    },
    25573: {
      lat: 38.22725,
      lng: -81.94549,
      city: 'Yawkey',
      state: 'WV',
    },
    25601: { lat: 37.86255, lng: -82.0026, city: 'Logan', state: 'WV' },
    25606: {
      lat: 37.76641,
      lng: -81.82878,
      city: 'Accoville',
      state: 'WV',
    },
    25607: {
      lat: 37.78125,
      lng: -81.77081,
      city: 'Amherstdale',
      state: 'WV',
    },
    25608: {
      lat: 37.57512,
      lng: -81.89165,
      city: 'Baisden',
      state: 'WV',
    },
    25611: { lat: 37.6832, lng: -81.87068, city: 'Bruno', state: 'WV' },
    25612: {
      lat: 37.76396,
      lng: -81.98064,
      city: 'Chauncey',
      state: 'WV',
    },
    25617: {
      lat: 37.74549,
      lng: -81.80275,
      city: 'Davin',
      state: 'WV',
    },
    25621: {
      lat: 37.63149,
      lng: -81.89643,
      city: 'Gilbert',
      state: 'WV',
    },
    25624: {
      lat: 37.90393,
      lng: -81.97162,
      city: 'Henlawson',
      state: 'WV',
    },
    25625: {
      lat: 37.82237,
      lng: -82.09206,
      city: 'Holden',
      state: 'WV',
    },
    25628: {
      lat: 37.7657,
      lng: -81.86237,
      city: 'Kistler',
      state: 'WV',
    },
    25630: {
      lat: 37.79821,
      lng: -81.6816,
      city: 'Lorado',
      state: 'WV',
    },
    25632: {
      lat: 37.76292,
      lng: -81.9368,
      city: 'Lyburn',
      state: 'WV',
    },
    25634: {
      lat: 37.73017,
      lng: -81.82461,
      city: 'Mallory',
      state: 'WV',
    },
    25635: { lat: 37.71937, lng: -81.87261, city: 'Man', state: 'WV' },
    25637: {
      lat: 37.85086,
      lng: -82.02805,
      city: 'Mount Gay',
      state: 'WV',
    },
    25638: { lat: 37.74423, lng: -82.05133, city: 'Omar', state: 'WV' },
    25639: {
      lat: 37.88674,
      lng: -81.95905,
      city: 'Peach Creek',
      state: 'WV',
    },
    25644: {
      lat: 37.70191,
      lng: -82.00107,
      city: 'Sarah Ann',
      state: 'WV',
    },
    25646: {
      lat: 37.83187,
      lng: -81.89349,
      city: 'Stollings',
      state: 'WV',
    },
    25647: {
      lat: 37.786,
      lng: -81.98738,
      city: 'Switzer',
      state: 'WV',
    },
    25649: {
      lat: 37.87166,
      lng: -82.08489,
      city: 'Verdunville',
      state: 'WV',
    },
    25650: {
      lat: 37.68358,
      lng: -81.78773,
      city: 'Verner',
      state: 'WV',
    },
    25651: {
      lat: 37.57142,
      lng: -81.9662,
      city: 'Wharncliffe',
      state: 'WV',
    },
    25652: {
      lat: 37.78702,
      lng: -82.04865,
      city: 'Whitman',
      state: 'WV',
    },
    25653: {
      lat: 37.8287,
      lng: -81.9944,
      city: 'Wilkinson',
      state: 'WV',
    },
    25654: {
      lat: 37.80302,
      lng: -81.87401,
      city: 'Yolyn',
      state: 'WV',
    },
    25661: {
      lat: 37.71062,
      lng: -82.25377,
      city: 'Williamson',
      state: 'WV',
    },
    25666: {
      lat: 37.9203,
      lng: -82.29432,
      city: 'Breeden',
      state: 'WV',
    },
    25667: {
      lat: 37.70619,
      lng: -82.2738,
      city: 'Chattaroy',
      state: 'WV',
    },
    25669: { lat: 37.93186, lng: -82.4519, city: 'Crum', state: 'WV' },
    25670: {
      lat: 37.7385,
      lng: -82.15588,
      city: 'Delbarton',
      state: 'WV',
    },
    25671: {
      lat: 37.90411,
      lng: -82.20283,
      city: 'Dingess',
      state: 'WV',
    },
    25672: {
      lat: 37.57017,
      lng: -82.10044,
      city: 'Edgarton',
      state: 'WV',
    },
    25674: {
      lat: 37.87114,
      lng: -82.34672,
      city: 'Kermit',
      state: 'WV',
    },
    25676: {
      lat: 37.82509,
      lng: -82.22972,
      city: 'Lenore',
      state: 'WV',
    },
    25678: {
      lat: 37.59564,
      lng: -82.08187,
      city: 'Matewan',
      state: 'WV',
    },
    25685: {
      lat: 37.79592,
      lng: -82.31426,
      city: 'Naugatuck',
      state: 'WV',
    },
    25688: {
      lat: 37.63952,
      lng: -82.15832,
      city: 'North Matewan',
      state: 'WV',
    },
    25690: {
      lat: 37.69124,
      lng: -82.15505,
      city: 'Ragland',
      state: 'WV',
    },
    25692: {
      lat: 37.61804,
      lng: -82.07375,
      city: 'Red Jacket',
      state: 'WV',
    },
    25696: {
      lat: 37.66116,
      lng: -82.07154,
      city: 'Varney',
      state: 'WV',
    },
    25699: {
      lat: 37.96297,
      lng: -82.31493,
      city: 'Wilsondale',
      state: 'WV',
    },
    25701: {
      lat: 38.36809,
      lng: -82.41127,
      city: 'Huntington',
      state: 'WV',
    },
    25702: {
      lat: 38.43825,
      lng: -82.33344,
      city: 'Huntington',
      state: 'WV',
    },
    25703: {
      lat: 38.42201,
      lng: -82.41827,
      city: 'Huntington',
      state: 'WV',
    },
    25704: {
      lat: 38.34969,
      lng: -82.50693,
      city: 'Huntington',
      state: 'WV',
    },
    25705: {
      lat: 38.40636,
      lng: -82.36276,
      city: 'Huntington',
      state: 'WV',
    },
    25755: {
      lat: 38.42359,
      lng: -82.42796,
      city: 'Huntington',
      state: 'WV',
    },
    25801: {
      lat: 37.83805,
      lng: -81.27017,
      city: 'Beckley',
      state: 'WV',
    },
    25810: {
      lat: 37.58355,
      lng: -81.34953,
      city: 'Allen Junction',
      state: 'WV',
    },
    25811: { lat: 37.60835, lng: -81.3065, city: 'Amigo', state: 'WV' },
    25812: {
      lat: 38.14048,
      lng: -81.11512,
      city: 'Ansted',
      state: 'WV',
    },
    25813: {
      lat: 37.78309,
      lng: -81.10001,
      city: 'Beaver',
      state: 'WV',
    },
    25817: { lat: 37.76393, lng: -81.42191, city: 'Bolt', state: 'WV' },
    25818: {
      lat: 37.8608,
      lng: -81.19252,
      city: 'Bradley',
      state: 'WV',
    },
    25820: {
      lat: 37.50812,
      lng: -81.13549,
      city: 'Camp Creek',
      state: 'WV',
    },
    25823: {
      lat: 37.66626,
      lng: -81.18231,
      city: 'Coal City',
      state: 'WV',
    },
    25825: {
      lat: 37.64736,
      lng: -81.08862,
      city: 'Cool Ridge',
      state: 'WV',
    },
    25826: {
      lat: 37.57291,
      lng: -81.36565,
      city: 'Corinne',
      state: 'WV',
    },
    25827: {
      lat: 37.73869,
      lng: -81.25368,
      city: 'Crab Orchard',
      state: 'WV',
    },
    25831: {
      lat: 37.95645,
      lng: -80.94037,
      city: 'Danese',
      state: 'WV',
    },
    25832: {
      lat: 37.72637,
      lng: -81.10494,
      city: 'Daniels',
      state: 'WV',
    },
    25836: {
      lat: 37.78693,
      lng: -81.27804,
      city: 'Eccles',
      state: 'WV',
    },
    25837: {
      lat: 38.06041,
      lng: -81.0383,
      city: 'Edmond',
      state: 'WV',
    },
    25839: {
      lat: 37.78091,
      lng: -81.38228,
      city: 'Fairdale',
      state: 'WV',
    },
    25840: {
      lat: 38.04001,
      lng: -81.11442,
      city: 'Fayetteville',
      state: 'WV',
    },
    25841: {
      lat: 37.55325,
      lng: -81.09061,
      city: 'Flat Top',
      state: 'WV',
    },
    25843: {
      lat: 37.62129,
      lng: -81.14129,
      city: 'Ghent',
      state: 'WV',
    },
    25844: {
      lat: 37.81056,
      lng: -81.37022,
      city: 'Glen Daniel',
      state: 'WV',
    },
    25845: {
      lat: 37.70291,
      lng: -81.51107,
      city: 'Glen Fork',
      state: 'WV',
    },
    25846: {
      lat: 37.92476,
      lng: -81.16628,
      city: 'Glen Jean',
      state: 'WV',
    },
    25848: {
      lat: 37.71427,
      lng: -81.43117,
      city: 'Glen Rogers',
      state: 'WV',
    },
    25849: {
      lat: 37.73175,
      lng: -81.27799,
      city: 'Glen White',
      state: 'WV',
    },
    25851: {
      lat: 37.80177,
      lng: -81.26154,
      city: 'Harper',
      state: 'WV',
    },
    25853: {
      lat: 37.64616,
      lng: -81.27827,
      city: 'Helen',
      state: 'WV',
    },
    25854: { lat: 38.12629, lng: -80.97537, city: 'Hico', state: 'WV' },
    25855: {
      lat: 37.94052,
      lng: -81.15296,
      city: 'Hilltop',
      state: 'WV',
    },
    25857: {
      lat: 37.61443,
      lng: -81.23288,
      city: 'Josephine',
      state: 'WV',
    },
    25862: {
      lat: 38.08448,
      lng: -81.05192,
      city: 'Lansing',
      state: 'WV',
    },
    25864: {
      lat: 37.87948,
      lng: -81.01781,
      city: 'Layland',
      state: 'WV',
    },
    25865: {
      lat: 37.73076,
      lng: -81.35212,
      city: 'Lester',
      state: 'WV',
    },
    25866: {
      lat: 38.00883,
      lng: -81.15738,
      city: 'Lochgelly',
      state: 'WV',
    },
    25868: {
      lat: 38.07172,
      lng: -80.96461,
      city: 'Lookout',
      state: 'WV',
    },
    25870: { lat: 37.63931, lng: -81.3813, city: 'Maben', state: 'WV' },
    25871: {
      lat: 37.76917,
      lng: -81.21165,
      city: 'Mabscott',
      state: 'WV',
    },
    25873: {
      lat: 37.75136,
      lng: -81.21515,
      city: 'MacArthur',
      state: 'WV',
    },
    25875: {
      lat: 37.66907,
      lng: -81.45956,
      city: 'McGraws',
      state: 'WV',
    },
    25876: {
      lat: 37.63677,
      lng: -81.44731,
      city: 'Saulsville',
      state: 'WV',
    },
    25878: {
      lat: 37.71303,
      lng: -81.22464,
      city: 'Midway',
      state: 'WV',
    },
    25879: {
      lat: 37.98048,
      lng: -81.10453,
      city: 'Minden',
      state: 'WV',
    },
    25880: {
      lat: 37.87672,
      lng: -81.20095,
      city: 'Mount Hope',
      state: 'WV',
    },
    25882: {
      lat: 37.60359,
      lng: -81.39892,
      city: 'Mullens',
      state: 'WV',
    },
    25901: {
      lat: 37.93575,
      lng: -81.11242,
      city: 'Oak Hill',
      state: 'WV',
    },
    25902: { lat: 37.56301, lng: -81.21115, city: 'Odd', state: 'WV' },
    25904: { lat: 37.9128, lng: -81.25213, city: 'Pax', state: 'WV' },
    25907: {
      lat: 37.8593,
      lng: -81.07196,
      city: 'Prince',
      state: 'WV',
    },
    25908: {
      lat: 37.66782,
      lng: -81.24434,
      city: 'Princewick',
      state: 'WV',
    },
    25909: {
      lat: 37.83687,
      lng: -81.20192,
      city: 'Prosperity',
      state: 'WV',
    },
    25911: {
      lat: 37.75232,
      lng: -81.17848,
      city: 'Raleigh',
      state: 'WV',
    },
    25913: {
      lat: 37.70781,
      lng: -81.47301,
      city: 'Ravencliff',
      state: 'WV',
    },
    25915: {
      lat: 37.61575,
      lng: -81.28691,
      city: 'Rhodell',
      state: 'WV',
    },
    25916: {
      lat: 37.67818,
      lng: -81.49019,
      city: 'Sabine',
      state: 'WV',
    },
    25917: {
      lat: 37.97944,
      lng: -81.25021,
      city: 'Scarbro',
      state: 'WV',
    },
    25918: {
      lat: 37.74575,
      lng: -81.00993,
      city: 'Shady Spring',
      state: 'WV',
    },
    25920: {
      lat: 37.68646,
      lng: -81.33661,
      city: 'Slab Fork',
      state: 'WV',
    },
    25921: {
      lat: 37.69181,
      lng: -81.25882,
      city: 'Sophia',
      state: 'WV',
    },
    25922: {
      lat: 37.47147,
      lng: -81.09966,
      city: 'Spanishburg',
      state: 'WV',
    },
    25928: {
      lat: 37.55481,
      lng: -81.34126,
      city: 'Stephenson',
      state: 'WV',
    },
    25932: {
      lat: 37.75623,
      lng: -81.30282,
      city: 'Surveyor',
      state: 'WV',
    },
    25936: {
      lat: 37.92917,
      lng: -81.03105,
      city: 'Thurmond',
      state: 'WV',
    },
    25938: {
      lat: 38.15993,
      lng: -81.03447,
      city: 'Victor',
      state: 'WV',
    },
    25942: {
      lat: 38.02691,
      lng: -80.98836,
      city: 'Winona',
      state: 'WV',
    },
    25943: { lat: 37.59657, lng: -81.34914, city: 'Wyco', state: 'WV' },
    25951: {
      lat: 37.65535,
      lng: -80.87197,
      city: 'Hinton',
      state: 'WV',
    },
    25958: {
      lat: 38.02156,
      lng: -80.76077,
      city: 'Charmco',
      state: 'WV',
    },
    25962: {
      lat: 37.97044,
      lng: -80.8098,
      city: 'Rainelle',
      state: 'WV',
    },
    25966: {
      lat: 37.7975,
      lng: -80.76729,
      city: 'Green Sulphur Springs',
      state: 'WV',
    },
    25969: {
      lat: 37.61657,
      lng: -81.00903,
      city: 'Jumping Branch',
      state: 'WV',
    },
    25971: {
      lat: 37.48001,
      lng: -80.97821,
      city: 'Lerona',
      state: 'WV',
    },
    25972: {
      lat: 38.03576,
      lng: -80.7222,
      city: 'Leslie',
      state: 'WV',
    },
    25976: {
      lat: 37.84372,
      lng: -80.86221,
      city: 'Meadow Bridge',
      state: 'WV',
    },
    25977: {
      lat: 37.80819,
      lng: -80.90571,
      city: 'Meadow Creek',
      state: 'WV',
    },
    25978: {
      lat: 37.62861,
      lng: -80.94138,
      city: 'Nimitz',
      state: 'WV',
    },
    25979: {
      lat: 37.5126,
      lng: -80.91736,
      city: 'Pipestem',
      state: 'WV',
    },
    25981: {
      lat: 38.08321,
      lng: -80.70274,
      city: 'Quinwood',
      state: 'WV',
    },
    25984: {
      lat: 38.01409,
      lng: -80.6026,
      city: 'Rupert',
      state: 'WV',
    },
    25985: {
      lat: 37.75752,
      lng: -80.83044,
      city: 'Sandstone',
      state: 'WV',
    },
    25986: {
      lat: 37.89395,
      lng: -80.81741,
      city: 'Spring Dale',
      state: 'WV',
    },
    25989: {
      lat: 37.67864,
      lng: -81.04594,
      city: 'White Oak',
      state: 'WV',
    },
    26003: {
      lat: 40.0693,
      lng: -80.6508,
      city: 'Wheeling',
      state: 'WV',
    },
    26030: {
      lat: 40.21662,
      lng: -80.65965,
      city: 'Beech Bottom',
      state: 'WV',
    },
    26031: {
      lat: 40.00236,
      lng: -80.71985,
      city: 'Benwood',
      state: 'WV',
    },
    26032: {
      lat: 40.18646,
      lng: -80.55024,
      city: 'Bethany',
      state: 'WV',
    },
    26033: {
      lat: 39.8284,
      lng: -80.57985,
      city: 'Cameron',
      state: 'WV',
    },
    26034: {
      lat: 40.59404,
      lng: -80.55258,
      city: 'Chester',
      state: 'WV',
    },
    26035: {
      lat: 40.3482,
      lng: -80.55522,
      city: 'Colliers',
      state: 'WV',
    },
    26036: {
      lat: 39.98406,
      lng: -80.54339,
      city: 'Dallas',
      state: 'WV',
    },
    26037: {
      lat: 40.3344,
      lng: -80.58494,
      city: 'Follansbee',
      state: 'WV',
    },
    26038: {
      lat: 39.96329,
      lng: -80.72595,
      city: 'Glen Dale',
      state: 'WV',
    },
    26039: {
      lat: 39.81641,
      lng: -80.66969,
      city: 'Glen Easton',
      state: 'WV',
    },
    26040: {
      lat: 39.98389,
      lng: -80.73104,
      city: 'Mcmechen',
      state: 'WV',
    },
    26041: {
      lat: 39.89764,
      lng: -80.7107,
      city: 'Moundsville',
      state: 'WV',
    },
    26047: {
      lat: 40.53223,
      lng: -80.5837,
      city: 'New Cumberland',
      state: 'WV',
    },
    26050: {
      lat: 40.60108,
      lng: -80.62646,
      city: 'Newell',
      state: 'WV',
    },
    26055: {
      lat: 39.75338,
      lng: -80.77396,
      city: 'Proctor',
      state: 'WV',
    },
    26056: {
      lat: 40.5211,
      lng: -80.57022,
      city: 'New Manchester',
      state: 'WV',
    },
    26058: {
      lat: 40.18622,
      lng: -80.66453,
      city: 'Short Creek',
      state: 'WV',
    },
    26059: {
      lat: 40.07156,
      lng: -80.59379,
      city: 'Triadelphia',
      state: 'WV',
    },
    26060: {
      lat: 40.10465,
      lng: -80.54649,
      city: 'Valley Grove',
      state: 'WV',
    },
    26062: {
      lat: 40.42189,
      lng: -80.56123,
      city: 'Weirton',
      state: 'WV',
    },
    26070: {
      lat: 40.25008,
      lng: -80.58037,
      city: 'Wellsburg',
      state: 'WV',
    },
    26074: {
      lat: 40.16889,
      lng: -80.59813,
      city: 'West Liberty',
      state: 'WV',
    },
    26075: {
      lat: 40.19748,
      lng: -80.64567,
      city: 'Windsor Heights',
      state: 'WV',
    },
    26101: {
      lat: 39.23394,
      lng: -81.57483,
      city: 'Parkersburg',
      state: 'WV',
    },
    26104: {
      lat: 39.27413,
      lng: -81.47779,
      city: 'Parkersburg',
      state: 'WV',
    },
    26105: {
      lat: 39.32949,
      lng: -81.52267,
      city: 'Vienna',
      state: 'WV',
    },
    26133: {
      lat: 39.11973,
      lng: -81.66968,
      city: 'Belleville',
      state: 'WV',
    },
    26134: {
      lat: 39.37686,
      lng: -81.27544,
      city: 'Belmont',
      state: 'WV',
    },
    26136: {
      lat: 38.96553,
      lng: -81.11478,
      city: 'Big Bend',
      state: 'WV',
    },
    26137: {
      lat: 39.00647,
      lng: -81.0385,
      city: 'Big Springs',
      state: 'WV',
    },
    26138: {
      lat: 39.02114,
      lng: -81.18155,
      city: 'Brohard',
      state: 'WV',
    },
    26141: {
      lat: 38.92925,
      lng: -81.26059,
      city: 'Creston',
      state: 'WV',
    },
    26142: {
      lat: 39.21488,
      lng: -81.45431,
      city: 'Davisville',
      state: 'WV',
    },
    26143: {
      lat: 39.06338,
      lng: -81.3764,
      city: 'Elizabeth',
      state: 'WV',
    },
    26146: {
      lat: 39.45053,
      lng: -81.03601,
      city: 'Friendly',
      state: 'WV',
    },
    26147: {
      lat: 38.91368,
      lng: -81.07629,
      city: 'Grantsville',
      state: 'WV',
    },
    26148: {
      lat: 39.07122,
      lng: -81.18088,
      city: 'Macfarlan',
      state: 'WV',
    },
    26149: {
      lat: 39.47429,
      lng: -80.88269,
      city: 'Middlebourne',
      state: 'WV',
    },
    26150: {
      lat: 39.1566,
      lng: -81.53697,
      city: 'Mineral Wells',
      state: 'WV',
    },
    26151: {
      lat: 38.89493,
      lng: -81.17503,
      city: 'Mount Zion',
      state: 'WV',
    },
    26152: {
      lat: 38.98355,
      lng: -81.19308,
      city: 'Munday',
      state: 'WV',
    },
    26155: {
      lat: 39.6333,
      lng: -80.76707,
      city: 'New Martinsville',
      state: 'WV',
    },
    26159: {
      lat: 39.60724,
      lng: -80.93216,
      city: 'Paden City',
      state: 'WV',
    },
    26160: {
      lat: 38.98066,
      lng: -81.41368,
      city: 'Palestine',
      state: 'WV',
    },
    26161: {
      lat: 39.16789,
      lng: -81.2526,
      city: 'Petroleum',
      state: 'WV',
    },
    26162: {
      lat: 39.57231,
      lng: -80.76572,
      city: 'Porters Falls',
      state: 'WV',
    },
    26164: {
      lat: 38.99061,
      lng: -81.71949,
      city: 'Ravenswood',
      state: 'WV',
    },
    26167: {
      lat: 39.53454,
      lng: -80.71429,
      city: 'Reader',
      state: 'WV',
    },
    26169: {
      lat: 39.07291,
      lng: -81.57121,
      city: 'Rockport',
      state: 'WV',
    },
    26170: {
      lat: 39.36136,
      lng: -81.17136,
      city: 'Saint Marys',
      state: 'WV',
    },
    26175: {
      lat: 39.53899,
      lng: -80.96577,
      city: 'Sistersville',
      state: 'WV',
    },
    26178: {
      lat: 39.06001,
      lng: -81.02888,
      city: 'Smithville',
      state: 'WV',
    },
    26180: {
      lat: 39.20016,
      lng: -81.36699,
      city: 'Walker',
      state: 'WV',
    },
    26181: {
      lat: 39.20707,
      lng: -81.6733,
      city: 'Washington',
      state: 'WV',
    },
    26184: {
      lat: 39.29739,
      lng: -81.35086,
      city: 'Waverly',
      state: 'WV',
    },
    26187: {
      lat: 39.36711,
      lng: -81.44894,
      city: 'Williamstown',
      state: 'WV',
    },
    26201: {
      lat: 39.00082,
      lng: -80.20249,
      city: 'Buckhannon',
      state: 'WV',
    },
    26202: {
      lat: 38.22657,
      lng: -80.62639,
      city: 'Fenwick',
      state: 'WV',
    },
    26203: {
      lat: 38.53136,
      lng: -80.60149,
      city: 'Erbacon',
      state: 'WV',
    },
    26205: {
      lat: 38.32078,
      lng: -80.64828,
      city: 'Craigsville',
      state: 'WV',
    },
    26206: {
      lat: 38.36992,
      lng: -80.47848,
      city: 'Cowen',
      state: 'WV',
    },
    26208: {
      lat: 38.34781,
      lng: -80.57314,
      city: 'Camden On Gauley',
      state: 'WV',
    },
    26209: {
      lat: 38.39401,
      lng: -80.04434,
      city: 'Snowshoe',
      state: 'WV',
    },
    26210: {
      lat: 38.90315,
      lng: -80.27812,
      city: 'Adrian',
      state: 'WV',
    },
    26215: {
      lat: 38.71931,
      lng: -80.37915,
      city: 'Cleveland',
      state: 'WV',
    },
    26217: {
      lat: 38.60837,
      lng: -80.45901,
      city: 'Diana',
      state: 'WV',
    },
    26218: {
      lat: 38.84506,
      lng: -80.26798,
      city: 'French Creek',
      state: 'WV',
    },
    26222: {
      lat: 38.66123,
      lng: -80.37122,
      city: 'Hacker Valley',
      state: 'WV',
    },
    26224: {
      lat: 38.74485,
      lng: -80.17474,
      city: 'Helvetia',
      state: 'WV',
    },
    26228: {
      lat: 38.75437,
      lng: -80.37054,
      city: 'Kanawha Head',
      state: 'WV',
    },
    26230: {
      lat: 38.6437,
      lng: -80.18881,
      city: 'Pickens',
      state: 'WV',
    },
    26234: {
      lat: 38.76618,
      lng: -80.30833,
      city: 'Rock Cave',
      state: 'WV',
    },
    26236: {
      lat: 38.71755,
      lng: -80.23088,
      city: 'Selbyville',
      state: 'WV',
    },
    26237: {
      lat: 38.84769,
      lng: -80.15145,
      city: 'Tallmansville',
      state: 'WV',
    },
    26238: { lat: 39.10178, lng: -80.1584, city: 'Volga', state: 'WV' },
    26241: {
      lat: 38.9163,
      lng: -79.79229,
      city: 'Elkins',
      state: 'WV',
    },
    26250: {
      lat: 39.02757,
      lng: -79.96198,
      city: 'Belington',
      state: 'WV',
    },
    26253: {
      lat: 38.80772,
      lng: -79.85654,
      city: 'Beverly',
      state: 'WV',
    },
    26254: {
      lat: 38.91261,
      lng: -79.65341,
      city: 'Bowden',
      state: 'WV',
    },
    26257: {
      lat: 38.9226,
      lng: -80.01239,
      city: 'Coalton',
      state: 'WV',
    },
    26259: {
      lat: 38.82536,
      lng: -79.91528,
      city: 'Dailey',
      state: 'WV',
    },
    26260: {
      lat: 39.08923,
      lng: -79.41147,
      city: 'Davis',
      state: 'WV',
    },
    26261: {
      lat: 38.18881,
      lng: -80.50454,
      city: 'Richwood',
      state: 'WV',
    },
    26263: {
      lat: 38.95705,
      lng: -79.43586,
      city: 'Dryfork',
      state: 'WV',
    },
    26264: {
      lat: 38.58923,
      lng: -79.82277,
      city: 'Durbin',
      state: 'WV',
    },
    26266: {
      lat: 38.43411,
      lng: -80.50219,
      city: 'Upperglade',
      state: 'WV',
    },
    26267: {
      lat: 38.92557,
      lng: -80.08069,
      city: 'Ellamore',
      state: 'WV',
    },
    26268: {
      lat: 38.71457,
      lng: -79.82276,
      city: 'Glady',
      state: 'WV',
    },
    26269: {
      lat: 39.11612,
      lng: -79.61617,
      city: 'Hambleton',
      state: 'WV',
    },
    26270: {
      lat: 38.81229,
      lng: -79.60034,
      city: 'Harman',
      state: 'WV',
    },
    26271: {
      lat: 39.049,
      lng: -79.55194,
      city: 'Hendricks',
      state: 'WV',
    },
    26273: {
      lat: 38.62993,
      lng: -79.963,
      city: 'Huttonsville',
      state: 'WV',
    },
    26275: {
      lat: 38.98313,
      lng: -79.94583,
      city: 'Junior',
      state: 'WV',
    },
    26276: {
      lat: 39.03201,
      lng: -79.75659,
      city: 'Kerens',
      state: 'WV',
    },
    26278: {
      lat: 38.82572,
      lng: -80.02547,
      city: 'Mabie',
      state: 'WV',
    },
    26280: {
      lat: 38.71738,
      lng: -80.03775,
      city: 'Mill Creek',
      state: 'WV',
    },
    26282: {
      lat: 38.52022,
      lng: -80.14619,
      city: 'Monterville',
      state: 'WV',
    },
    26283: {
      lat: 39.06436,
      lng: -79.82088,
      city: 'Montrose',
      state: 'WV',
    },
    26285: {
      lat: 38.9102,
      lng: -79.94945,
      city: 'Norton',
      state: 'WV',
    },
    26287: {
      lat: 39.16657,
      lng: -79.69057,
      city: 'Parsons',
      state: 'WV',
    },
    26288: {
      lat: 38.49664,
      lng: -80.3645,
      city: 'Webster Springs',
      state: 'WV',
    },
    26289: {
      lat: 38.98972,
      lng: -79.51268,
      city: 'Red Creek',
      state: 'WV',
    },
    26291: {
      lat: 38.44109,
      lng: -80.08637,
      city: 'Slatyfork',
      state: 'WV',
    },
    26292: {
      lat: 39.15247,
      lng: -79.52916,
      city: 'Thomas',
      state: 'WV',
    },
    26293: {
      lat: 38.7824,
      lng: -79.9365,
      city: 'Valley Bend',
      state: 'WV',
    },
    26294: {
      lat: 38.53443,
      lng: -80.02309,
      city: 'Valley Head',
      state: 'WV',
    },
    26296: {
      lat: 38.75425,
      lng: -79.56369,
      city: 'Whitmer',
      state: 'WV',
    },
    26298: {
      lat: 38.45054,
      lng: -80.26552,
      city: 'Bergoo',
      state: 'WV',
    },
    26301: {
      lat: 39.28629,
      lng: -80.37735,
      city: 'Clarksburg',
      state: 'WV',
    },
    26320: { lat: 39.41514, lng: -80.82614, city: 'Alma', state: 'WV' },
    26321: {
      lat: 39.05461,
      lng: -80.68732,
      city: 'Alum Bridge',
      state: 'WV',
    },
    26323: {
      lat: 39.2623,
      lng: -80.29057,
      city: 'Anmoore',
      state: 'WV',
    },
    26325: { lat: 39.09749, lng: -80.882, city: 'Auburn', state: 'WV' },
    26327: { lat: 39.12283, lng: -80.9406, city: 'Berea', state: 'WV' },
    26330: {
      lat: 39.28837,
      lng: -80.22109,
      city: 'Bridgeport',
      state: 'WV',
    },
    26335: {
      lat: 38.85364,
      lng: -80.67683,
      city: 'Burnsville',
      state: 'WV',
    },
    26337: {
      lat: 39.23856,
      lng: -81.16388,
      city: 'Cairo',
      state: 'WV',
    },
    26338: {
      lat: 39.08503,
      lng: -80.61941,
      city: 'Camden',
      state: 'WV',
    },
    26339: {
      lat: 39.42231,
      lng: -80.60998,
      city: 'Center Point',
      state: 'WV',
    },
    26342: {
      lat: 39.0248,
      lng: -80.84698,
      city: 'Coxs Mills',
      state: 'WV',
    },
    26343: {
      lat: 38.82665,
      lng: -80.39831,
      city: 'Crawford',
      state: 'WV',
    },
    26346: {
      lat: 39.29247,
      lng: -81.06294,
      city: 'Ellenboro',
      state: 'WV',
    },
    26347: {
      lat: 39.26371,
      lng: -80.1261,
      city: 'Flemington',
      state: 'WV',
    },
    26348: {
      lat: 39.46035,
      lng: -80.53474,
      city: 'Folsom',
      state: 'WV',
    },
    26349: {
      lat: 39.22757,
      lng: -80.11098,
      city: 'Galloway',
      state: 'WV',
    },
    26351: {
      lat: 38.92846,
      lng: -80.84479,
      city: 'Glenville',
      state: 'WV',
    },
    26354: {
      lat: 39.34847,
      lng: -80.02866,
      city: 'Grafton',
      state: 'WV',
    },
    26361: {
      lat: 39.36507,
      lng: -80.31776,
      city: 'Gypsy',
      state: 'WV',
    },
    26362: {
      lat: 39.15457,
      lng: -81.05705,
      city: 'Harrisville',
      state: 'WV',
    },
    26366: {
      lat: 39.38266,
      lng: -80.33474,
      city: 'Haywood',
      state: 'WV',
    },
    26369: {
      lat: 39.33527,
      lng: -80.33117,
      city: 'Hepzibah',
      state: 'WV',
    },
    26372: {
      lat: 38.95859,
      lng: -80.37155,
      city: 'Horner',
      state: 'WV',
    },
    26374: {
      lat: 39.44755,
      lng: -79.88101,
      city: 'Independence',
      state: 'WV',
    },
    26376: {
      lat: 38.77565,
      lng: -80.46864,
      city: 'Ireland',
      state: 'WV',
    },
    26377: {
      lat: 39.49185,
      lng: -80.64068,
      city: 'Jacksonburg',
      state: 'WV',
    },
    26378: {
      lat: 39.12264,
      lng: -80.44772,
      city: 'Jane Lew',
      state: 'WV',
    },
    26384: { lat: 38.97467, lng: -80.7178, city: 'Linn', state: 'WV' },
    26385: {
      lat: 39.15895,
      lng: -80.3517,
      city: 'Lost Creek',
      state: 'WV',
    },
    26386: {
      lat: 39.39878,
      lng: -80.40263,
      city: 'Lumberport',
      state: 'WV',
    },
    26404: {
      lat: 39.34636,
      lng: -80.30996,
      city: 'Meadowbrook',
      state: 'WV',
    },
    26405: {
      lat: 39.23093,
      lng: -79.89076,
      city: 'Moatsville',
      state: 'WV',
    },
    26408: {
      lat: 39.20313,
      lng: -80.28973,
      city: 'Mount Clare',
      state: 'WV',
    },
    26410: {
      lat: 39.4032,
      lng: -79.82389,
      city: 'Newburg',
      state: 'WV',
    },
    26411: {
      lat: 39.18078,
      lng: -80.70723,
      city: 'New Milton',
      state: 'WV',
    },
    26412: {
      lat: 38.89164,
      lng: -80.59457,
      city: 'Orlando',
      state: 'WV',
    },
    26415: {
      lat: 39.29808,
      lng: -80.93499,
      city: 'Pennsboro',
      state: 'WV',
    },
    26416: {
      lat: 39.16349,
      lng: -80.02245,
      city: 'Philippi',
      state: 'WV',
    },
    26419: {
      lat: 39.58469,
      lng: -80.631,
      city: 'Pine Grove',
      state: 'WV',
    },
    26421: {
      lat: 39.18412,
      lng: -80.91607,
      city: 'Pullman',
      state: 'WV',
    },
    26422: {
      lat: 39.29797,
      lng: -80.43323,
      city: 'Reynoldsville',
      state: 'WV',
    },
    26424: {
      lat: 39.28117,
      lng: -80.17272,
      city: 'Rosemont',
      state: 'WV',
    },
    26425: {
      lat: 39.3116,
      lng: -79.68651,
      city: 'Rowlesburg',
      state: 'WV',
    },
    26426: {
      lat: 39.28758,
      lng: -80.56313,
      city: 'Salem',
      state: 'WV',
    },
    26430: {
      lat: 38.88475,
      lng: -80.7598,
      city: 'Sand Fork',
      state: 'WV',
    },
    26431: {
      lat: 39.39144,
      lng: -80.28085,
      city: 'Shinnston',
      state: 'WV',
    },
    26435: {
      lat: 39.26743,
      lng: -80.08875,
      city: 'Simpson',
      state: 'WV',
    },
    26436: {
      lat: 39.2965,
      lng: -80.71683,
      city: 'Smithburg',
      state: 'WV',
    },
    26437: {
      lat: 39.53147,
      lng: -80.5193,
      city: 'Smithfield',
      state: 'WV',
    },
    26438: {
      lat: 39.34315,
      lng: -80.31631,
      city: 'Spelter',
      state: 'WV',
    },
    26440: {
      lat: 39.32947,
      lng: -79.90625,
      city: 'Thornton',
      state: 'WV',
    },
    26443: { lat: 39.06814, lng: -80.7554, city: 'Troy', state: 'WV' },
    26444: {
      lat: 39.36861,
      lng: -79.7626,
      city: 'Tunnelton',
      state: 'WV',
    },
    26447: {
      lat: 38.90546,
      lng: -80.4854,
      city: 'Walkersville',
      state: 'WV',
    },
    26448: {
      lat: 39.40595,
      lng: -80.49265,
      city: 'Wallace',
      state: 'WV',
    },
    26451: {
      lat: 39.20439,
      lng: -80.40352,
      city: 'West Milford',
      state: 'WV',
    },
    26452: {
      lat: 39.03286,
      lng: -80.51315,
      city: 'Weston',
      state: 'WV',
    },
    26456: {
      lat: 39.28078,
      lng: -80.77354,
      city: 'West Union',
      state: 'WV',
    },
    26463: {
      lat: 39.43174,
      lng: -80.35007,
      city: 'Wyatt',
      state: 'WV',
    },
    26501: {
      lat: 39.62872,
      lng: -80.04025,
      city: 'Morgantown',
      state: 'WV',
    },
    26505: {
      lat: 39.6511,
      lng: -79.94306,
      city: 'Morgantown',
      state: 'WV',
    },
    26506: {
      lat: 39.64677,
      lng: -79.9701,
      city: 'Morgantown',
      state: 'WV',
    },
    26508: {
      lat: 39.59941,
      lng: -79.89724,
      city: 'Morgantown',
      state: 'WV',
    },
    26519: {
      lat: 39.55179,
      lng: -79.63212,
      city: 'Albright',
      state: 'WV',
    },
    26520: {
      lat: 39.50105,
      lng: -79.82393,
      city: 'Arthurdale',
      state: 'WV',
    },
    26521: {
      lat: 39.71374,
      lng: -80.23065,
      city: 'Blacksville',
      state: 'WV',
    },
    26524: {
      lat: 39.54075,
      lng: -79.80716,
      city: 'Bretz',
      state: 'WV',
    },
    26525: {
      lat: 39.65465,
      lng: -79.6184,
      city: 'Bruceton Mills',
      state: 'WV',
    },
    26534: {
      lat: 39.64748,
      lng: -79.99733,
      city: 'Granville',
      state: 'WV',
    },
    26537: {
      lat: 39.4846,
      lng: -79.71189,
      city: 'Kingwood',
      state: 'WV',
    },
    26541: {
      lat: 39.68179,
      lng: -80.07458,
      city: 'Maidsville',
      state: 'WV',
    },
    26542: {
      lat: 39.573,
      lng: -79.79041,
      city: 'Masontown',
      state: 'WV',
    },
    26543: {
      lat: 39.66375,
      lng: -80.00219,
      city: 'Osage',
      state: 'WV',
    },
    26544: {
      lat: 39.71233,
      lng: -80.17064,
      city: 'Pentress',
      state: 'WV',
    },
    26546: {
      lat: 39.66099,
      lng: -80.03115,
      city: 'Pursglove',
      state: 'WV',
    },
    26547: {
      lat: 39.5133,
      lng: -79.80757,
      city: 'Reedsville',
      state: 'WV',
    },
    26554: {
      lat: 39.46475,
      lng: -80.11461,
      city: 'Fairmont',
      state: 'WV',
    },
    26559: {
      lat: 39.52141,
      lng: -80.18015,
      city: 'Barrackville',
      state: 'WV',
    },
    26560: {
      lat: 39.54088,
      lng: -80.14362,
      city: 'Baxter',
      state: 'WV',
    },
    26562: {
      lat: 39.63957,
      lng: -80.45072,
      city: 'Burton',
      state: 'WV',
    },
    26563: {
      lat: 39.48085,
      lng: -80.27369,
      city: 'Carolina',
      state: 'WV',
    },
    26568: {
      lat: 39.42542,
      lng: -80.27729,
      city: 'Enterprise',
      state: 'WV',
    },
    26570: {
      lat: 39.64284,
      lng: -80.23444,
      city: 'Fairview',
      state: 'WV',
    },
    26571: {
      lat: 39.51945,
      lng: -80.26013,
      city: 'Farmington',
      state: 'WV',
    },
    26572: {
      lat: 39.4871,
      lng: -80.31973,
      city: 'Four States',
      state: 'WV',
    },
    26574: {
      lat: 39.55926,
      lng: -80.17741,
      city: 'Grant Town',
      state: 'WV',
    },
    26575: {
      lat: 39.6978,
      lng: -80.43256,
      city: 'Hundred',
      state: 'WV',
    },
    26576: {
      lat: 39.49307,
      lng: -80.25644,
      city: 'Idamay',
      state: 'WV',
    },
    26581: {
      lat: 39.66554,
      lng: -80.56781,
      city: 'Littleton',
      state: 'WV',
    },
    26582: {
      lat: 39.53114,
      lng: -80.38767,
      city: 'Mannington',
      state: 'WV',
    },
    26585: { lat: 39.6065, lng: -80.42379, city: 'Metz', state: 'WV' },
    26586: {
      lat: 39.52786,
      lng: -80.10952,
      city: 'Montana Mines',
      state: 'WV',
    },
    26587: {
      lat: 39.51606,
      lng: -80.29751,
      city: 'Rachel',
      state: 'WV',
    },
    26588: {
      lat: 39.5738,
      lng: -80.15339,
      city: 'Rivesville',
      state: 'WV',
    },
    26590: { lat: 39.67428, lng: -80.31674, city: 'Wana', state: 'WV' },
    26591: {
      lat: 39.45499,
      lng: -80.2903,
      city: 'Worthington',
      state: 'WV',
    },
    26601: {
      lat: 38.63307,
      lng: -80.6567,
      city: 'Sutton',
      state: 'WV',
    },
    26610: {
      lat: 38.47769,
      lng: -80.71887,
      city: 'Birch River',
      state: 'WV',
    },
    26611: {
      lat: 38.84639,
      lng: -80.83339,
      city: 'Cedarville',
      state: 'WV',
    },
    26615: {
      lat: 38.83242,
      lng: -80.73136,
      city: 'Copen',
      state: 'WV',
    },
    26617: { lat: 38.4828, lng: -80.82572, city: 'Dille', state: 'WV' },
    26619: {
      lat: 38.77542,
      lng: -80.73562,
      city: 'Exchange',
      state: 'WV',
    },
    26621: {
      lat: 38.72186,
      lng: -80.56057,
      city: 'Flatwoods',
      state: 'WV',
    },
    26623: {
      lat: 38.6576,
      lng: -80.87714,
      city: 'Frametown',
      state: 'WV',
    },
    26624: {
      lat: 38.72071,
      lng: -80.80883,
      city: 'Gassaway',
      state: 'WV',
    },
    26627: {
      lat: 38.75578,
      lng: -80.5943,
      city: 'Heaters',
      state: 'WV',
    },
    26629: {
      lat: 38.56846,
      lng: -80.70152,
      city: 'Little Birch',
      state: 'WV',
    },
    26631: {
      lat: 38.79795,
      lng: -80.56856,
      city: 'Napier',
      state: 'WV',
    },
    26636: {
      lat: 38.73386,
      lng: -80.96599,
      city: 'Rosedale',
      state: 'WV',
    },
    26638: {
      lat: 38.77265,
      lng: -80.99878,
      city: 'Shock',
      state: 'WV',
    },
    26651: {
      lat: 38.3185,
      lng: -80.87902,
      city: 'Summersville',
      state: 'WV',
    },
    26656: {
      lat: 38.24358,
      lng: -81.18082,
      city: 'Belva',
      state: 'WV',
    },
    26660: {
      lat: 38.35513,
      lng: -80.70061,
      city: 'Calvin',
      state: 'WV',
    },
    26662: {
      lat: 38.25813,
      lng: -80.7695,
      city: 'Canvas',
      state: 'WV',
    },
    26667: {
      lat: 38.2384,
      lng: -80.99908,
      city: 'Drennen',
      state: 'WV',
    },
    26671: {
      lat: 38.31978,
      lng: -80.90929,
      city: 'Gilboa',
      state: 'WV',
    },
    26676: {
      lat: 38.16173,
      lng: -80.65944,
      city: 'Leivasy',
      state: 'WV',
    },
    26678: {
      lat: 38.16883,
      lng: -80.91085,
      city: 'Mount Lookout',
      state: 'WV',
    },
    26679: {
      lat: 38.16509,
      lng: -80.7971,
      city: 'Mount Nebo',
      state: 'WV',
    },
    26680: {
      lat: 38.08991,
      lng: -80.87423,
      city: 'Nallen',
      state: 'WV',
    },
    26681: {
      lat: 38.22983,
      lng: -80.71905,
      city: 'Nettie',
      state: 'WV',
    },
    26684: { lat: 38.16324, lng: -80.8544, city: 'Pool', state: 'WV' },
    26690: {
      lat: 38.21757,
      lng: -81.12069,
      city: 'Swiss',
      state: 'WV',
    },
    26691: {
      lat: 38.39785,
      lng: -80.67213,
      city: 'Tioga',
      state: 'WV',
    },
    26704: {
      lat: 39.28619,
      lng: -78.59471,
      city: 'Augusta',
      state: 'WV',
    },
    26705: {
      lat: 39.32388,
      lng: -79.56663,
      city: 'Aurora',
      state: 'WV',
    },
    26707: {
      lat: 39.22275,
      lng: -79.39418,
      city: 'Bayard',
      state: 'WV',
    },
    26710: {
      lat: 39.30996,
      lng: -78.94544,
      city: 'Burlington',
      state: 'WV',
    },
    26711: {
      lat: 39.30301,
      lng: -78.47073,
      city: 'Capon Bridge',
      state: 'WV',
    },
    26714: {
      lat: 39.17634,
      lng: -78.64177,
      city: 'Delray',
      state: 'WV',
    },
    26716: { lat: 39.2641, lng: -79.51892, city: 'Eglon', state: 'WV' },
    26717: {
      lat: 39.34583,
      lng: -79.17597,
      city: 'Elk Garden',
      state: 'WV',
    },
    26719: {
      lat: 39.49778,
      lng: -78.7678,
      city: 'Fort Ashby',
      state: 'WV',
    },
    26720: {
      lat: 39.27761,
      lng: -79.33126,
      city: 'Gormania',
      state: 'WV',
    },
    26722: {
      lat: 39.49774,
      lng: -78.63463,
      city: 'Green Spring',
      state: 'WV',
    },
    26726: {
      lat: 39.40508,
      lng: -78.98222,
      city: 'Keyser',
      state: 'WV',
    },
    26731: {
      lat: 39.15243,
      lng: -79.06859,
      city: 'Lahmansville',
      state: 'WV',
    },
    26739: {
      lat: 39.21508,
      lng: -79.2647,
      city: 'Mount Storm',
      state: 'WV',
    },
    26743: {
      lat: 39.28873,
      lng: -79.07998,
      city: 'New Creek',
      state: 'WV',
    },
    26750: {
      lat: 39.47764,
      lng: -79.04627,
      city: 'Piedmont',
      state: 'WV',
    },
    26753: {
      lat: 39.56017,
      lng: -78.79026,
      city: 'Ridgeley',
      state: 'WV',
    },
    26755: { lat: 39.15856, lng: -78.73534, city: 'Rio', state: 'WV' },
    26757: {
      lat: 39.31652,
      lng: -78.75001,
      city: 'Romney',
      state: 'WV',
    },
    26761: {
      lat: 39.27303,
      lng: -78.6997,
      city: 'Shanks',
      state: 'WV',
    },
    26763: {
      lat: 39.4789,
      lng: -78.69316,
      city: 'Springfield',
      state: 'WV',
    },
    26764: {
      lat: 39.45042,
      lng: -79.56035,
      city: 'Terra Alta',
      state: 'WV',
    },
    26767: {
      lat: 39.61581,
      lng: -78.75674,
      city: 'Wiley Ford',
      state: 'WV',
    },
    26801: {
      lat: 39.05694,
      lng: -78.79374,
      city: 'Baker',
      state: 'WV',
    },
    26802: {
      lat: 38.64454,
      lng: -79.18706,
      city: 'Brandywine',
      state: 'WV',
    },
    26804: {
      lat: 38.63306,
      lng: -79.54526,
      city: 'Circleville',
      state: 'WV',
    },
    26807: {
      lat: 38.62895,
      lng: -79.36157,
      city: 'Franklin',
      state: 'WV',
    },
    26808: {
      lat: 39.19563,
      lng: -78.44416,
      city: 'High View',
      state: 'WV',
    },
    26810: {
      lat: 38.97721,
      lng: -78.74853,
      city: 'Lost City',
      state: 'WV',
    },
    26812: {
      lat: 38.88184,
      lng: -78.87688,
      city: 'Mathias',
      state: 'WV',
    },
    26814: {
      lat: 38.75176,
      lng: -79.46648,
      city: 'Riverton',
      state: 'WV',
    },
    26815: {
      lat: 38.48923,
      lng: -79.35884,
      city: 'Sugar Grove',
      state: 'WV',
    },
    26817: {
      lat: 39.35957,
      lng: -78.39067,
      city: 'Bloomery',
      state: 'WV',
    },
    26818: {
      lat: 39.07662,
      lng: -79.02238,
      city: 'Fisher',
      state: 'WV',
    },
    26823: {
      lat: 39.13033,
      lng: -78.51139,
      city: 'Capon Springs',
      state: 'WV',
    },
    26833: {
      lat: 39.08119,
      lng: -79.2083,
      city: 'Maysville',
      state: 'WV',
    },
    26836: {
      lat: 39.00693,
      lng: -78.96366,
      city: 'Moorefield',
      state: 'WV',
    },
    26838: {
      lat: 38.80492,
      lng: -79.07237,
      city: 'Milam',
      state: 'WV',
    },
    26845: {
      lat: 39.16734,
      lng: -78.96193,
      city: 'Old Fields',
      state: 'WV',
    },
    26847: {
      lat: 38.95809,
      lng: -79.15189,
      city: 'Petersburg',
      state: 'WV',
    },
    26851: {
      lat: 39.04801,
      lng: -78.62878,
      city: 'Wardensville',
      state: 'WV',
    },
    26852: {
      lat: 39.2477,
      lng: -78.91599,
      city: 'Purgitsville',
      state: 'WV',
    },
    26855: {
      lat: 38.96933,
      lng: -79.25935,
      city: 'Cabins',
      state: 'WV',
    },
    26865: {
      lat: 39.19053,
      lng: -78.51932,
      city: 'Yellow Spring',
      state: 'WV',
    },
    26866: {
      lat: 38.80493,
      lng: -79.2512,
      city: 'Upper Tract',
      state: 'WV',
    },
    26884: {
      lat: 38.84273,
      lng: -79.40118,
      city: 'Seneca Rocks',
      state: 'WV',
    },
    26886: {
      lat: 38.88742,
      lng: -79.40064,
      city: 'Onego',
      state: 'WV',
    },
    27006: {
      lat: 35.94352,
      lng: -80.43673,
      city: 'Advance',
      state: 'NC',
    },
    27007: {
      lat: 36.38799,
      lng: -80.59144,
      city: 'Ararat',
      state: 'NC',
    },
    27009: {
      lat: 36.23089,
      lng: -80.07927,
      city: 'Belews Creek',
      state: 'NC',
    },
    27011: {
      lat: 36.21649,
      lng: -80.69728,
      city: 'Boonville',
      state: 'NC',
    },
    27012: {
      lat: 36.00434,
      lng: -80.37547,
      city: 'Clemmons',
      state: 'NC',
    },
    27013: {
      lat: 35.75633,
      lng: -80.70235,
      city: 'Cleveland',
      state: 'NC',
    },
    27014: {
      lat: 35.8121,
      lng: -80.55508,
      city: 'Cooleemee',
      state: 'NC',
    },
    27016: {
      lat: 36.44318,
      lng: -80.2219,
      city: 'Danbury',
      state: 'NC',
    },
    27017: {
      lat: 36.38141,
      lng: -80.76052,
      city: 'Dobson',
      state: 'NC',
    },
    27018: {
      lat: 36.1978,
      lng: -80.52096,
      city: 'East Bend',
      state: 'NC',
    },
    27019: {
      lat: 36.28604,
      lng: -80.23548,
      city: 'Germanton',
      state: 'NC',
    },
    27020: {
      lat: 36.1047,
      lng: -80.81272,
      city: 'Hamptonville',
      state: 'NC',
    },
    27021: { lat: 36.31859, lng: -80.3386, city: 'King', state: 'NC' },
    27022: {
      lat: 36.51409,
      lng: -80.21739,
      city: 'Lawsonville',
      state: 'NC',
    },
    27023: {
      lat: 36.09103,
      lng: -80.45101,
      city: 'Lewisville',
      state: 'NC',
    },
    27024: {
      lat: 36.52399,
      lng: -80.84027,
      city: 'Lowgap',
      state: 'NC',
    },
    27025: {
      lat: 36.3771,
      lng: -79.97148,
      city: 'Madison',
      state: 'NC',
    },
    27027: {
      lat: 36.4456,
      lng: -79.98883,
      city: 'Mayodan',
      state: 'NC',
    },
    27028: {
      lat: 35.92433,
      lng: -80.5742,
      city: 'Mocksville',
      state: 'NC',
    },
    27030: {
      lat: 36.48836,
      lng: -80.63013,
      city: 'Mount Airy',
      state: 'NC',
    },
    27040: {
      lat: 36.16869,
      lng: -80.38969,
      city: 'Pfafftown',
      state: 'NC',
    },
    27041: {
      lat: 36.41539,
      lng: -80.48465,
      city: 'Pilot Mountain',
      state: 'NC',
    },
    27042: {
      lat: 36.34177,
      lng: -80.05434,
      city: 'Pine Hall',
      state: 'NC',
    },
    27043: {
      lat: 36.33709,
      lng: -80.44917,
      city: 'Pinnacle',
      state: 'NC',
    },
    27045: {
      lat: 36.23482,
      lng: -80.29614,
      city: 'Rural Hall',
      state: 'NC',
    },
    27046: {
      lat: 36.49228,
      lng: -80.09223,
      city: 'Sandy Ridge',
      state: 'NC',
    },
    27047: {
      lat: 36.31178,
      lng: -80.57786,
      city: 'Siloam',
      state: 'NC',
    },
    27048: {
      lat: 36.47611,
      lng: -79.91295,
      city: 'Stoneville',
      state: 'NC',
    },
    27050: {
      lat: 36.23608,
      lng: -80.40116,
      city: 'Tobaccoville',
      state: 'NC',
    },
    27051: {
      lat: 36.18728,
      lng: -80.16012,
      city: 'Walkertown',
      state: 'NC',
    },
    27052: {
      lat: 36.32259,
      lng: -80.14552,
      city: 'Walnut Cove',
      state: 'NC',
    },
    27053: {
      lat: 36.47317,
      lng: -80.34989,
      city: 'Westfield',
      state: 'NC',
    },
    27054: {
      lat: 35.79304,
      lng: -80.59833,
      city: 'Woodleaf',
      state: 'NC',
    },
    27055: {
      lat: 36.11382,
      lng: -80.63586,
      city: 'Yadkinville',
      state: 'NC',
    },
    27101: {
      lat: 36.11204,
      lng: -80.19889,
      city: 'Winston Salem',
      state: 'NC',
    },
    27103: {
      lat: 36.05822,
      lng: -80.32125,
      city: 'Winston Salem',
      state: 'NC',
    },
    27104: {
      lat: 36.09465,
      lng: -80.32501,
      city: 'Winston Salem',
      state: 'NC',
    },
    27105: {
      lat: 36.16294,
      lng: -80.23521,
      city: 'Winston Salem',
      state: 'NC',
    },
    27106: {
      lat: 36.14255,
      lng: -80.3228,
      city: 'Winston Salem',
      state: 'NC',
    },
    27107: {
      lat: 36.01488,
      lng: -80.17819,
      city: 'Winston Salem',
      state: 'NC',
    },
    27109: {
      lat: 36.13371,
      lng: -80.27539,
      city: 'Winston Salem',
      state: 'NC',
    },
    27110: {
      lat: 36.08919,
      lng: -80.225,
      city: 'Winston Salem',
      state: 'NC',
    },
    27127: {
      lat: 36.02028,
      lng: -80.27847,
      city: 'Winston Salem',
      state: 'NC',
    },
    27201: {
      lat: 36.03258,
      lng: -79.48418,
      city: 'Alamance',
      state: 'NC',
    },
    27202: {
      lat: 36.18565,
      lng: -79.50594,
      city: 'Altamahaw',
      state: 'NC',
    },
    27203: {
      lat: 35.73253,
      lng: -79.78857,
      city: 'Asheboro',
      state: 'NC',
    },
    27205: {
      lat: 35.6399,
      lng: -79.85094,
      city: 'Asheboro',
      state: 'NC',
    },
    27207: {
      lat: 35.6064,
      lng: -79.38893,
      city: 'Bear Creek',
      state: 'NC',
    },
    27208: {
      lat: 35.56246,
      lng: -79.54231,
      city: 'Bennett',
      state: 'NC',
    },
    27209: {
      lat: 35.34254,
      lng: -79.74936,
      city: 'Biscoe',
      state: 'NC',
    },
    27212: {
      lat: 36.46207,
      lng: -79.27976,
      city: 'Blanch',
      state: 'NC',
    },
    27213: {
      lat: 35.6473,
      lng: -79.40454,
      city: 'Bonlee',
      state: 'NC',
    },
    27214: {
      lat: 36.21407,
      lng: -79.68283,
      city: 'Browns Summit',
      state: 'NC',
    },
    27215: {
      lat: 36.03055,
      lng: -79.48729,
      city: 'Burlington',
      state: 'NC',
    },
    27217: {
      lat: 36.19221,
      lng: -79.38404,
      city: 'Burlington',
      state: 'NC',
    },
    27229: {
      lat: 35.24853,
      lng: -79.79873,
      city: 'Candor',
      state: 'NC',
    },
    27231: {
      lat: 36.20455,
      lng: -79.17268,
      city: 'Cedar Grove',
      state: 'NC',
    },
    27233: { lat: 35.8882, lng: -79.6988, city: 'Climax', state: 'NC' },
    27235: {
      lat: 36.09734,
      lng: -80.00871,
      city: 'Colfax',
      state: 'NC',
    },
    27239: {
      lat: 35.60566,
      lng: -80.09024,
      city: 'Denton',
      state: 'NC',
    },
    27242: {
      lat: 35.33645,
      lng: -79.63761,
      city: 'Eagle Springs',
      state: 'NC',
    },
    27243: {
      lat: 36.07821,
      lng: -79.1945,
      city: 'Efland',
      state: 'NC',
    },
    27244: { lat: 36.21402, lng: -79.48747, city: 'Elon', state: 'NC' },
    27247: {
      lat: 35.46096,
      lng: -79.78784,
      city: 'Ether',
      state: 'NC',
    },
    27248: {
      lat: 35.78916,
      lng: -79.70953,
      city: 'Franklinville',
      state: 'NC',
    },
    27249: {
      lat: 36.16014,
      lng: -79.58134,
      city: 'Gibsonville',
      state: 'NC',
    },
    27252: {
      lat: 35.56033,
      lng: -79.35563,
      city: 'Goldston',
      state: 'NC',
    },
    27253: {
      lat: 35.96239,
      lng: -79.34581,
      city: 'Graham',
      state: 'NC',
    },
    27256: { lat: 35.56058, lng: -79.2725, city: 'Gulf', state: 'NC' },
    27258: {
      lat: 36.03401,
      lng: -79.32623,
      city: 'Haw River',
      state: 'NC',
    },
    27259: {
      lat: 35.49149,
      lng: -79.52624,
      city: 'Highfalls',
      state: 'NC',
    },
    27260: {
      lat: 35.95473,
      lng: -79.98697,
      city: 'High Point',
      state: 'NC',
    },
    27262: {
      lat: 35.96191,
      lng: -80.03845,
      city: 'High Point',
      state: 'NC',
    },
    27263: {
      lat: 35.90931,
      lng: -79.93829,
      city: 'High Point',
      state: 'NC',
    },
    27265: {
      lat: 36.00947,
      lng: -80.03272,
      city: 'High Point',
      state: 'NC',
    },
    27268: {
      lat: 35.97139,
      lng: -79.99471,
      city: 'High Point',
      state: 'NC',
    },
    27278: {
      lat: 36.08673,
      lng: -79.08778,
      city: 'Hillsborough',
      state: 'NC',
    },
    27281: {
      lat: 35.18538,
      lng: -79.62689,
      city: 'Jackson Springs',
      state: 'NC',
    },
    27282: {
      lat: 35.99656,
      lng: -79.92869,
      city: 'Jamestown',
      state: 'NC',
    },
    27283: {
      lat: 35.95293,
      lng: -79.63621,
      city: 'Julian',
      state: 'NC',
    },
    27284: {
      lat: 36.11818,
      lng: -80.07794,
      city: 'Kernersville',
      state: 'NC',
    },
    27288: { lat: 36.49812, lng: -79.74587, city: 'Eden', state: 'NC' },
    27291: {
      lat: 36.40173,
      lng: -79.16679,
      city: 'Leasburg',
      state: 'NC',
    },
    27292: {
      lat: 35.73469,
      lng: -80.20845,
      city: 'Lexington',
      state: 'NC',
    },
    27295: {
      lat: 35.86756,
      lng: -80.31623,
      city: 'Lexington',
      state: 'NC',
    },
    27298: {
      lat: 35.88563,
      lng: -79.56816,
      city: 'Liberty',
      state: 'NC',
    },
    27299: {
      lat: 35.75131,
      lng: -80.38402,
      city: 'Linwood',
      state: 'NC',
    },
    27301: {
      lat: 36.11512,
      lng: -79.66755,
      city: 'McLeansville',
      state: 'NC',
    },
    27302: {
      lat: 36.13749,
      lng: -79.2647,
      city: 'Mebane',
      state: 'NC',
    },
    27305: {
      lat: 36.52029,
      lng: -79.22553,
      city: 'Milton',
      state: 'NC',
    },
    27306: {
      lat: 35.22098,
      lng: -79.97685,
      city: 'Mount Gilead',
      state: 'NC',
    },
    27310: {
      lat: 36.17294,
      lng: -79.99159,
      city: 'Oak Ridge',
      state: 'NC',
    },
    27311: {
      lat: 36.48137,
      lng: -79.47577,
      city: 'Pelham',
      state: 'NC',
    },
    27312: {
      lat: 35.75446,
      lng: -79.19648,
      city: 'Pittsboro',
      state: 'NC',
    },
    27313: {
      lat: 35.92892,
      lng: -79.75142,
      city: 'Pleasant Garden',
      state: 'NC',
    },
    27314: {
      lat: 36.29072,
      lng: -79.18594,
      city: 'Prospect Hill',
      state: 'NC',
    },
    27315: {
      lat: 36.50613,
      lng: -79.39173,
      city: 'Providence',
      state: 'NC',
    },
    27316: {
      lat: 35.68996,
      lng: -79.62366,
      city: 'Ramseur',
      state: 'NC',
    },
    27317: {
      lat: 35.83809,
      lng: -79.80376,
      city: 'Randleman',
      state: 'NC',
    },
    27320: {
      lat: 36.3434,
      lng: -79.6599,
      city: 'Reidsville',
      state: 'NC',
    },
    27325: {
      lat: 35.46236,
      lng: -79.57249,
      city: 'Robbins',
      state: 'NC',
    },
    27326: {
      lat: 36.45542,
      lng: -79.55867,
      city: 'Ruffin',
      state: 'NC',
    },
    27330: {
      lat: 35.51624,
      lng: -79.18394,
      city: 'Sanford',
      state: 'NC',
    },
    27332: {
      lat: 35.37836,
      lng: -79.13599,
      city: 'Sanford',
      state: 'NC',
    },
    27340: {
      lat: 35.94557,
      lng: -79.31141,
      city: 'Saxapahaw',
      state: 'NC',
    },
    27341: {
      lat: 35.52467,
      lng: -79.70014,
      city: 'Seagrove',
      state: 'NC',
    },
    27342: {
      lat: 36.06641,
      lng: -79.6186,
      city: 'Sedalia',
      state: 'NC',
    },
    27343: {
      lat: 36.50229,
      lng: -79.08892,
      city: 'Semora',
      state: 'NC',
    },
    27344: {
      lat: 35.7289,
      lng: -79.43366,
      city: 'Siler City',
      state: 'NC',
    },
    27349: {
      lat: 35.89845,
      lng: -79.41202,
      city: 'Snow Camp',
      state: 'NC',
    },
    27350: {
      lat: 35.80631,
      lng: -79.88917,
      city: 'Sophia',
      state: 'NC',
    },
    27351: {
      lat: 35.65976,
      lng: -80.26786,
      city: 'Southmont',
      state: 'NC',
    },
    27355: {
      lat: 35.78859,
      lng: -79.5678,
      city: 'Staley',
      state: 'NC',
    },
    27356: { lat: 35.43991, lng: -79.7982, city: 'Star', state: 'NC' },
    27357: {
      lat: 36.26844,
      lng: -79.97641,
      city: 'Stokesdale',
      state: 'NC',
    },
    27358: {
      lat: 36.22723,
      lng: -79.88124,
      city: 'Summerfield',
      state: 'NC',
    },
    27360: {
      lat: 35.85684,
      lng: -80.09987,
      city: 'Thomasville',
      state: 'NC',
    },
    27370: {
      lat: 35.8116,
      lng: -79.99133,
      city: 'Trinity',
      state: 'NC',
    },
    27371: { lat: 35.42489, lng: -79.94912, city: 'Troy', state: 'NC' },
    27374: {
      lat: 35.91752,
      lng: -80.25777,
      city: 'Welcome',
      state: 'NC',
    },
    27376: {
      lat: 35.25607,
      lng: -79.53184,
      city: 'West End',
      state: 'NC',
    },
    27377: {
      lat: 36.03728,
      lng: -79.60221,
      city: 'Whitsett',
      state: 'NC',
    },
    27379: {
      lat: 36.37653,
      lng: -79.34653,
      city: 'Yanceyville',
      state: 'NC',
    },
    27401: {
      lat: 36.06995,
      lng: -79.76724,
      city: 'Greensboro',
      state: 'NC',
    },
    27403: {
      lat: 36.06406,
      lng: -79.82538,
      city: 'Greensboro',
      state: 'NC',
    },
    27405: {
      lat: 36.11373,
      lng: -79.73463,
      city: 'Greensboro',
      state: 'NC',
    },
    27406: {
      lat: 35.9982,
      lng: -79.76686,
      city: 'Greensboro',
      state: 'NC',
    },
    27407: {
      lat: 36.01124,
      lng: -79.87588,
      city: 'Greensboro',
      state: 'NC',
    },
    27408: {
      lat: 36.10321,
      lng: -79.81411,
      city: 'Greensboro',
      state: 'NC',
    },
    27409: {
      lat: 36.09242,
      lng: -79.94431,
      city: 'Greensboro',
      state: 'NC',
    },
    27410: {
      lat: 36.11826,
      lng: -79.89223,
      city: 'Greensboro',
      state: 'NC',
    },
    27411: {
      lat: 36.07665,
      lng: -79.77157,
      city: 'Greensboro',
      state: 'NC',
    },
    27412: {
      lat: 36.06903,
      lng: -79.81062,
      city: 'Greensboro',
      state: 'NC',
    },
    27455: {
      lat: 36.18283,
      lng: -79.80794,
      city: 'Greensboro',
      state: 'NC',
    },
    27501: {
      lat: 35.48684,
      lng: -78.68419,
      city: 'Angier',
      state: 'NC',
    },
    27502: { lat: 35.72123, lng: -78.90828, city: 'Apex', state: 'NC' },
    27503: {
      lat: 36.15832,
      lng: -78.87757,
      city: 'Bahama',
      state: 'NC',
    },
    27504: {
      lat: 35.39963,
      lng: -78.51863,
      city: 'Benson',
      state: 'NC',
    },
    27505: {
      lat: 35.414,
      lng: -79.00198,
      city: 'Broadway',
      state: 'NC',
    },
    27506: {
      lat: 35.40875,
      lng: -78.73896,
      city: 'Buies Creek',
      state: 'NC',
    },
    27507: {
      lat: 36.51253,
      lng: -78.55143,
      city: 'Bullock',
      state: 'NC',
    },
    27508: { lat: 35.95882, lng: -78.25105, city: 'Bunn', state: 'NC' },
    27509: {
      lat: 36.12889,
      lng: -78.77604,
      city: 'Butner',
      state: 'NC',
    },
    27510: {
      lat: 35.91147,
      lng: -79.08211,
      city: 'Carrboro',
      state: 'NC',
    },
    27511: { lat: 35.76324, lng: -78.78437, city: 'Cary', state: 'NC' },
    27513: { lat: 35.80269, lng: -78.80159, city: 'Cary', state: 'NC' },
    27514: {
      lat: 35.96776,
      lng: -79.04406,
      city: 'Chapel Hill',
      state: 'NC',
    },
    27516: {
      lat: 35.91239,
      lng: -79.15156,
      city: 'Chapel Hill',
      state: 'NC',
    },
    27517: {
      lat: 35.84389,
      lng: -79.03186,
      city: 'Chapel Hill',
      state: 'NC',
    },
    27518: { lat: 35.72927, lng: -78.7736, city: 'Cary', state: 'NC' },
    27519: { lat: 35.80815, lng: -78.88711, city: 'Cary', state: 'NC' },
    27520: {
      lat: 35.61274,
      lng: -78.47075,
      city: 'Clayton',
      state: 'NC',
    },
    27521: {
      lat: 35.41298,
      lng: -78.65847,
      city: 'Coats',
      state: 'NC',
    },
    27522: {
      lat: 36.10565,
      lng: -78.67676,
      city: 'Creedmoor',
      state: 'NC',
    },
    27523: { lat: 35.77102, lng: -78.95367, city: 'Apex', state: 'NC' },
    27524: {
      lat: 35.39392,
      lng: -78.37948,
      city: 'Four Oaks',
      state: 'NC',
    },
    27525: {
      lat: 36.11721,
      lng: -78.46997,
      city: 'Franklinton',
      state: 'NC',
    },
    27526: {
      lat: 35.54179,
      lng: -78.8274,
      city: 'Fuquay Varina',
      state: 'NC',
    },
    27527: {
      lat: 35.65767,
      lng: -78.38336,
      city: 'Clayton',
      state: 'NC',
    },
    27529: {
      lat: 35.65048,
      lng: -78.58167,
      city: 'Garner',
      state: 'NC',
    },
    27530: {
      lat: 35.36825,
      lng: -78.07511,
      city: 'Goldsboro',
      state: 'NC',
    },
    27531: {
      lat: 35.34362,
      lng: -77.96743,
      city: 'Goldsboro',
      state: 'NC',
    },
    27533: {
      lat: 35.38746,
      lng: -78.03896,
      city: 'Goldsboro',
      state: 'NC',
    },
    27534: {
      lat: 35.37153,
      lng: -77.90646,
      city: 'Goldsboro',
      state: 'NC',
    },
    27536: {
      lat: 36.32645,
      lng: -78.40754,
      city: 'Henderson',
      state: 'NC',
    },
    27537: {
      lat: 36.35343,
      lng: -78.38887,
      city: 'Henderson',
      state: 'NC',
    },
    27539: { lat: 35.67713, lng: -78.81269, city: 'Apex', state: 'NC' },
    27540: {
      lat: 35.6103,
      lng: -78.87332,
      city: 'Holly Springs',
      state: 'NC',
    },
    27541: {
      lat: 36.26176,
      lng: -79.0866,
      city: 'Hurdle Mills',
      state: 'NC',
    },
    27542: { lat: 35.61776, lng: -78.1377, city: 'Kenly', state: 'NC' },
    27544: {
      lat: 36.20018,
      lng: -78.44097,
      city: 'Kittrell',
      state: 'NC',
    },
    27545: {
      lat: 35.78513,
      lng: -78.48045,
      city: 'Knightdale',
      state: 'NC',
    },
    27546: {
      lat: 35.38458,
      lng: -78.86626,
      city: 'Lillington',
      state: 'NC',
    },
    27549: {
      lat: 36.10439,
      lng: -78.24282,
      city: 'Louisburg',
      state: 'NC',
    },
    27551: {
      lat: 36.43339,
      lng: -78.05231,
      city: 'Macon',
      state: 'NC',
    },
    27553: {
      lat: 36.48047,
      lng: -78.30643,
      city: 'Manson',
      state: 'NC',
    },
    27555: {
      lat: 35.56242,
      lng: -78.20353,
      city: 'Micro',
      state: 'NC',
    },
    27556: {
      lat: 36.39869,
      lng: -78.31294,
      city: 'Middleburg',
      state: 'NC',
    },
    27557: {
      lat: 35.77941,
      lng: -78.2,
      city: 'Middlesex',
      state: 'NC',
    },
    27559: {
      lat: 35.6299,
      lng: -79.0973,
      city: 'Moncure',
      state: 'NC',
    },
    27560: {
      lat: 35.85845,
      lng: -78.82544,
      city: 'Morrisville',
      state: 'NC',
    },
    27562: {
      lat: 35.64157,
      lng: -78.98141,
      city: 'New Hill',
      state: 'NC',
    },
    27563: {
      lat: 36.4312,
      lng: -78.23343,
      city: 'Norlina',
      state: 'NC',
    },
    27565: {
      lat: 36.36203,
      lng: -78.64604,
      city: 'Oxford',
      state: 'NC',
    },
    27568: {
      lat: 35.51132,
      lng: -78.24299,
      city: 'Pine Level',
      state: 'NC',
    },
    27569: {
      lat: 35.43306,
      lng: -78.17432,
      city: 'Princeton',
      state: 'NC',
    },
    27570: {
      lat: 36.43861,
      lng: -78.23662,
      city: 'Ridgeway',
      state: 'NC',
    },
    27571: {
      lat: 35.92151,
      lng: -78.4647,
      city: 'Rolesville',
      state: 'NC',
    },
    27572: {
      lat: 36.24872,
      lng: -78.88045,
      city: 'Rougemont',
      state: 'NC',
    },
    27573: {
      lat: 36.3978,
      lng: -78.97476,
      city: 'Roxboro',
      state: 'NC',
    },
    27574: {
      lat: 36.43166,
      lng: -78.94147,
      city: 'Roxboro',
      state: 'NC',
    },
    27576: {
      lat: 35.58297,
      lng: -78.25776,
      city: 'Selma',
      state: 'NC',
    },
    27577: {
      lat: 35.49166,
      lng: -78.3446,
      city: 'Smithfield',
      state: 'NC',
    },
    27581: { lat: 36.20155, lng: -78.73355, city: 'Stem', state: 'NC' },
    27582: {
      lat: 36.44736,
      lng: -78.5691,
      city: 'Stovall',
      state: 'NC',
    },
    27583: {
      lat: 36.29723,
      lng: -78.93153,
      city: 'Timberlake',
      state: 'NC',
    },
    27584: {
      lat: 36.4934,
      lng: -78.44601,
      city: 'Townsville',
      state: 'NC',
    },
    27587: {
      lat: 35.98098,
      lng: -78.54963,
      city: 'Wake Forest',
      state: 'NC',
    },
    27589: {
      lat: 36.33461,
      lng: -78.12819,
      city: 'Warrenton',
      state: 'NC',
    },
    27591: {
      lat: 35.7859,
      lng: -78.38638,
      city: 'Wendell',
      state: 'NC',
    },
    27592: {
      lat: 35.55922,
      lng: -78.67164,
      city: 'Willow Spring',
      state: 'NC',
    },
    27593: {
      lat: 35.58579,
      lng: -78.36231,
      city: 'Wilsons Mills',
      state: 'NC',
    },
    27594: { lat: 36.47294, lng: -78.16622, city: 'Wise', state: 'NC' },
    27596: {
      lat: 36.00906,
      lng: -78.44448,
      city: 'Youngsville',
      state: 'NC',
    },
    27597: {
      lat: 35.84034,
      lng: -78.30303,
      city: 'Zebulon',
      state: 'NC',
    },
    27599: {
      lat: 35.90394,
      lng: -79.05234,
      city: 'Chapel Hill',
      state: 'NC',
    },
    27601: {
      lat: 35.7737,
      lng: -78.63453,
      city: 'Raleigh',
      state: 'NC',
    },
    27603: {
      lat: 35.66408,
      lng: -78.66186,
      city: 'Raleigh',
      state: 'NC',
    },
    27604: {
      lat: 35.82011,
      lng: -78.56369,
      city: 'Raleigh',
      state: 'NC',
    },
    27605: {
      lat: 35.79046,
      lng: -78.65452,
      city: 'Raleigh',
      state: 'NC',
    },
    27606: {
      lat: 35.73991,
      lng: -78.71833,
      city: 'Raleigh',
      state: 'NC',
    },
    27607: {
      lat: 35.82145,
      lng: -78.72697,
      city: 'Raleigh',
      state: 'NC',
    },
    27608: {
      lat: 35.81004,
      lng: -78.64815,
      city: 'Raleigh',
      state: 'NC',
    },
    27609: {
      lat: 35.8437,
      lng: -78.63282,
      city: 'Raleigh',
      state: 'NC',
    },
    27610: {
      lat: 35.7435,
      lng: -78.54644,
      city: 'Raleigh',
      state: 'NC',
    },
    27612: {
      lat: 35.85034,
      lng: -78.70387,
      city: 'Raleigh',
      state: 'NC',
    },
    27613: {
      lat: 35.92633,
      lng: -78.71183,
      city: 'Raleigh',
      state: 'NC',
    },
    27614: {
      lat: 35.94739,
      lng: -78.62059,
      city: 'Raleigh',
      state: 'NC',
    },
    27615: {
      lat: 35.89772,
      lng: -78.636,
      city: 'Raleigh',
      state: 'NC',
    },
    27616: {
      lat: 35.86735,
      lng: -78.53792,
      city: 'Raleigh',
      state: 'NC',
    },
    27617: {
      lat: 35.90723,
      lng: -78.77221,
      city: 'Raleigh',
      state: 'NC',
    },
    27695: {
      lat: 35.78558,
      lng: -78.679,
      city: 'Raleigh',
      state: 'NC',
    },
    27697: {
      lat: 35.7788,
      lng: -78.62297,
      city: 'Raleigh',
      state: 'NC',
    },
    27701: {
      lat: 35.99858,
      lng: -78.89872,
      city: 'Durham',
      state: 'NC',
    },
    27703: {
      lat: 35.96299,
      lng: -78.80452,
      city: 'Durham',
      state: 'NC',
    },
    27704: {
      lat: 36.04088,
      lng: -78.83007,
      city: 'Durham',
      state: 'NC',
    },
    27705: {
      lat: 36.0259,
      lng: -78.98046,
      city: 'Durham',
      state: 'NC',
    },
    27707: {
      lat: 35.95463,
      lng: -78.95451,
      city: 'Durham',
      state: 'NC',
    },
    27708: {
      lat: 36.00009,
      lng: -78.94305,
      city: 'Durham',
      state: 'NC',
    },
    27709: {
      lat: 35.91441,
      lng: -78.86666,
      city: 'Durham',
      state: 'NC',
    },
    27712: {
      lat: 36.09606,
      lng: -78.90481,
      city: 'Durham',
      state: 'NC',
    },
    27713: { lat: 35.88401, lng: -78.936, city: 'Durham', state: 'NC' },
    27801: {
      lat: 35.91209,
      lng: -77.73656,
      city: 'Rocky Mount',
      state: 'NC',
    },
    27803: {
      lat: 35.89627,
      lng: -77.85407,
      city: 'Rocky Mount',
      state: 'NC',
    },
    27804: {
      lat: 35.98958,
      lng: -77.84778,
      city: 'Rocky Mount',
      state: 'NC',
    },
    27805: {
      lat: 36.20863,
      lng: -77.08861,
      city: 'Aulander',
      state: 'NC',
    },
    27806: {
      lat: 35.29713,
      lng: -76.76264,
      city: 'Aurora',
      state: 'NC',
    },
    27807: {
      lat: 35.80915,
      lng: -78.0917,
      city: 'Bailey',
      state: 'NC',
    },
    27808: { lat: 35.47228, lng: -76.76494, city: 'Bath', state: 'NC' },
    27809: {
      lat: 36.01932,
      lng: -77.74085,
      city: 'Battleboro',
      state: 'NC',
    },
    27810: {
      lat: 35.58637,
      lng: -76.49706,
      city: 'Belhaven',
      state: 'NC',
    },
    27812: {
      lat: 35.80506,
      lng: -77.37616,
      city: 'Bethel',
      state: 'NC',
    },
    27813: {
      lat: 35.63083,
      lng: -77.93587,
      city: 'Black Creek',
      state: 'NC',
    },
    27814: {
      lat: 35.37987,
      lng: -76.93513,
      city: 'Blounts Creek',
      state: 'NC',
    },
    27815: {
      lat: 35.92473,
      lng: -77.69304,
      city: 'Rocky Mount',
      state: 'NC',
    },
    27816: {
      lat: 36.10732,
      lng: -78.07245,
      city: 'Castalia',
      state: 'NC',
    },
    27817: {
      lat: 35.4506,
      lng: -77.0825,
      city: 'Chocowinity',
      state: 'NC',
    },
    27818: { lat: 36.50275, lng: -77.01394, city: 'Como', state: 'NC' },
    27819: {
      lat: 35.81412,
      lng: -77.45731,
      city: 'Conetoe',
      state: 'NC',
    },
    27820: {
      lat: 36.4154,
      lng: -77.24431,
      city: 'Conway',
      state: 'NC',
    },
    27821: {
      lat: 35.3237,
      lng: -76.87392,
      city: 'Edward',
      state: 'NC',
    },
    27822: {
      lat: 35.80402,
      lng: -77.83868,
      city: 'Elm City',
      state: 'NC',
    },
    27823: {
      lat: 36.20616,
      lng: -77.71685,
      city: 'Enfield',
      state: 'NC',
    },
    27824: {
      lat: 35.55688,
      lng: -76.02105,
      city: 'Engelhard',
      state: 'NC',
    },
    27825: {
      lat: 35.83527,
      lng: -77.17152,
      city: 'Everetts',
      state: 'NC',
    },
    27826: {
      lat: 35.58988,
      lng: -76.2521,
      city: 'Fairfield',
      state: 'NC',
    },
    27827: {
      lat: 35.69823,
      lng: -77.51224,
      city: 'Falkland',
      state: 'NC',
    },
    27828: {
      lat: 35.58509,
      lng: -77.58519,
      city: 'Farmville',
      state: 'NC',
    },
    27829: {
      lat: 35.69272,
      lng: -77.61215,
      city: 'Fountain',
      state: 'NC',
    },
    27830: {
      lat: 35.55557,
      lng: -77.95224,
      city: 'Fremont',
      state: 'NC',
    },
    27831: {
      lat: 36.45614,
      lng: -77.56574,
      city: 'Garysburg',
      state: 'NC',
    },
    27832: {
      lat: 36.51262,
      lng: -77.72178,
      city: 'Gaston',
      state: 'NC',
    },
    27834: {
      lat: 35.6571,
      lng: -77.37981,
      city: 'Greenville',
      state: 'NC',
    },
    27837: {
      lat: 35.51606,
      lng: -77.20892,
      city: 'Grimesland',
      state: 'NC',
    },
    27839: {
      lat: 36.28818,
      lng: -77.55009,
      city: 'Halifax',
      state: 'NC',
    },
    27840: {
      lat: 35.9343,
      lng: -77.23186,
      city: 'Hamilton',
      state: 'NC',
    },
    27841: {
      lat: 35.90859,
      lng: -77.27632,
      city: 'Hassell',
      state: 'NC',
    },
    27842: {
      lat: 36.52458,
      lng: -77.85593,
      city: 'Henrico',
      state: 'NC',
    },
    27843: {
      lat: 35.99332,
      lng: -77.39509,
      city: 'Hobgood',
      state: 'NC',
    },
    27844: {
      lat: 36.24555,
      lng: -77.94945,
      city: 'Hollister',
      state: 'NC',
    },
    27845: {
      lat: 36.37403,
      lng: -77.43331,
      city: 'Jackson',
      state: 'NC',
    },
    27846: {
      lat: 35.76519,
      lng: -76.89191,
      city: 'Jamesville',
      state: 'NC',
    },
    27847: {
      lat: 36.18597,
      lng: -77.20738,
      city: 'Kelford',
      state: 'NC',
    },
    27849: {
      lat: 36.06796,
      lng: -77.20675,
      city: 'Lewiston Woodville',
      state: 'NC',
    },
    27850: {
      lat: 36.40202,
      lng: -77.88141,
      city: 'Littleton',
      state: 'NC',
    },
    27851: {
      lat: 35.63965,
      lng: -78.02739,
      city: 'Lucama',
      state: 'NC',
    },
    27852: {
      lat: 35.75288,
      lng: -77.63581,
      city: 'Macclesfield',
      state: 'NC',
    },
    27853: {
      lat: 36.51716,
      lng: -77.30488,
      city: 'Margarettsville',
      state: 'NC',
    },
    27855: {
      lat: 36.42171,
      lng: -77.08432,
      city: 'Murfreesboro',
      state: 'NC',
    },
    27856: {
      lat: 36.00998,
      lng: -77.98151,
      city: 'Nashville',
      state: 'NC',
    },
    27857: {
      lat: 35.96968,
      lng: -77.27198,
      city: 'Oak City',
      state: 'NC',
    },
    27858: {
      lat: 35.53229,
      lng: -77.29709,
      city: 'Greenville',
      state: 'NC',
    },
    27860: {
      lat: 35.67693,
      lng: -76.65001,
      city: 'Pantego',
      state: 'NC',
    },
    27861: {
      lat: 35.81716,
      lng: -77.31899,
      city: 'Parmele',
      state: 'NC',
    },
    27862: {
      lat: 36.49264,
      lng: -77.18906,
      city: 'Pendleton',
      state: 'NC',
    },
    27863: {
      lat: 35.48739,
      lng: -77.96993,
      city: 'Pikeville',
      state: 'NC',
    },
    27864: {
      lat: 35.8075,
      lng: -77.64789,
      city: 'Pinetops',
      state: 'NC',
    },
    27865: {
      lat: 35.60588,
      lng: -76.83244,
      city: 'Pinetown',
      state: 'NC',
    },
    27866: {
      lat: 36.51832,
      lng: -77.51577,
      city: 'Pleasant Hill',
      state: 'NC',
    },
    27869: {
      lat: 36.26631,
      lng: -77.31665,
      city: 'Rich Square',
      state: 'NC',
    },
    27870: {
      lat: 36.43053,
      lng: -77.71828,
      city: 'Roanoke Rapids',
      state: 'NC',
    },
    27871: {
      lat: 35.81355,
      lng: -77.26306,
      city: 'Robersonville',
      state: 'NC',
    },
    27872: {
      lat: 36.20096,
      lng: -77.24601,
      city: 'Roxobel',
      state: 'NC',
    },
    27873: {
      lat: 35.65331,
      lng: -77.77606,
      city: 'Saratoga',
      state: 'NC',
    },
    27874: {
      lat: 36.12874,
      lng: -77.41037,
      city: 'Scotland Neck',
      state: 'NC',
    },
    27875: {
      lat: 35.45623,
      lng: -76.48532,
      city: 'Scranton',
      state: 'NC',
    },
    27876: {
      lat: 36.48015,
      lng: -77.40065,
      city: 'Seaboard',
      state: 'NC',
    },
    27877: {
      lat: 36.51883,
      lng: -77.19219,
      city: 'Severn',
      state: 'NC',
    },
    27878: {
      lat: 35.86606,
      lng: -77.83062,
      city: 'Sharpsburg',
      state: 'NC',
    },
    27879: {
      lat: 35.57456,
      lng: -77.27968,
      city: 'Simpson',
      state: 'NC',
    },
    27880: { lat: 35.73656, lng: -78.08912, city: 'Sims', state: 'NC' },
    27881: { lat: 35.9702, lng: -77.44228, city: 'Speed', state: 'NC' },
    27882: {
      lat: 35.94222,
      lng: -78.11825,
      city: 'Spring Hope',
      state: 'NC',
    },
    27883: {
      lat: 35.59141,
      lng: -77.80095,
      city: 'Stantonsburg',
      state: 'NC',
    },
    27884: {
      lat: 35.70771,
      lng: -77.27176,
      city: 'Stokes',
      state: 'NC',
    },
    27885: {
      lat: 35.44429,
      lng: -76.23125,
      city: 'Swanquarter',
      state: 'NC',
    },
    27886: {
      lat: 35.90369,
      lng: -77.52248,
      city: 'Tarboro',
      state: 'NC',
    },
    27888: {
      lat: 35.59589,
      lng: -77.70448,
      city: 'Walstonburg',
      state: 'NC',
    },
    27889: {
      lat: 35.58909,
      lng: -77.02386,
      city: 'Washington',
      state: 'NC',
    },
    27890: {
      lat: 36.41505,
      lng: -77.59194,
      city: 'Weldon',
      state: 'NC',
    },
    27891: {
      lat: 36.09968,
      lng: -77.74103,
      city: 'Whitakers',
      state: 'NC',
    },
    27892: {
      lat: 35.81844,
      lng: -77.06434,
      city: 'Williamston',
      state: 'NC',
    },
    27893: {
      lat: 35.69351,
      lng: -77.90458,
      city: 'Wilson',
      state: 'NC',
    },
    27896: {
      lat: 35.79303,
      lng: -77.97399,
      city: 'Wilson',
      state: 'NC',
    },
    27897: {
      lat: 36.3246,
      lng: -77.20969,
      city: 'Woodland',
      state: 'NC',
    },
    27909: {
      lat: 36.29548,
      lng: -76.28394,
      city: 'Elizabeth City',
      state: 'NC',
    },
    27910: {
      lat: 36.27996,
      lng: -76.99281,
      city: 'Ahoskie',
      state: 'NC',
    },
    27915: { lat: 35.40794, lng: -75.49465, city: 'Avon', state: 'NC' },
    27916: {
      lat: 36.32933,
      lng: -75.91996,
      city: 'Aydlett',
      state: 'NC',
    },
    27917: {
      lat: 36.36071,
      lng: -75.99071,
      city: 'Barco',
      state: 'NC',
    },
    27919: {
      lat: 36.31178,
      lng: -76.50293,
      city: 'Belvidere',
      state: 'NC',
    },
    27920: {
      lat: 35.25109,
      lng: -75.54755,
      city: 'Buxton',
      state: 'NC',
    },
    27921: {
      lat: 36.3446,
      lng: -76.15512,
      city: 'Camden',
      state: 'NC',
    },
    27922: {
      lat: 36.32118,
      lng: -76.84837,
      city: 'Cofield',
      state: 'NC',
    },
    27923: {
      lat: 36.38852,
      lng: -75.93562,
      city: 'Coinjock',
      state: 'NC',
    },
    27924: {
      lat: 36.17835,
      lng: -76.83822,
      city: 'Colerain',
      state: 'NC',
    },
    27925: {
      lat: 35.84336,
      lng: -76.18349,
      city: 'Columbia',
      state: 'NC',
    },
    27926: {
      lat: 36.51687,
      lng: -76.60288,
      city: 'Corapeake',
      state: 'NC',
    },
    27927: {
      lat: 36.4131,
      lng: -75.8497,
      city: 'Corolla',
      state: 'NC',
    },
    27928: {
      lat: 35.80857,
      lng: -76.43579,
      city: 'Creswell',
      state: 'NC',
    },
    27929: {
      lat: 36.42407,
      lng: -76.02534,
      city: 'Currituck',
      state: 'NC',
    },
    27932: {
      lat: 36.10223,
      lng: -76.59414,
      city: 'Edenton',
      state: 'NC',
    },
    27935: { lat: 36.43026, lng: -76.85962, city: 'Eure', state: 'NC' },
    27936: {
      lat: 35.24224,
      lng: -75.60547,
      city: 'Frisco',
      state: 'NC',
    },
    27937: {
      lat: 36.50453,
      lng: -76.77726,
      city: 'Gates',
      state: 'NC',
    },
    27938: {
      lat: 36.38422,
      lng: -76.71628,
      city: 'Gatesville',
      state: 'NC',
    },
    27939: {
      lat: 36.23528,
      lng: -75.87689,
      city: 'Grandy',
      state: 'NC',
    },
    27941: {
      lat: 36.09147,
      lng: -75.81678,
      city: 'Harbinger',
      state: 'NC',
    },
    27942: {
      lat: 36.28785,
      lng: -76.7597,
      city: 'Harrellsville',
      state: 'NC',
    },
    27943: {
      lat: 35.21432,
      lng: -75.69363,
      city: 'Hatteras',
      state: 'NC',
    },
    27944: {
      lat: 36.17908,
      lng: -76.41446,
      city: 'Hertford',
      state: 'NC',
    },
    27946: {
      lat: 36.35901,
      lng: -76.59648,
      city: 'Hobbsville',
      state: 'NC',
    },
    27947: {
      lat: 36.20278,
      lng: -75.86922,
      city: 'Jarvisburg',
      state: 'NC',
    },
    27948: {
      lat: 36.01761,
      lng: -75.68663,
      city: 'Kill Devil Hills',
      state: 'NC',
    },
    27949: {
      lat: 36.10814,
      lng: -75.73112,
      city: 'Kitty Hawk',
      state: 'NC',
    },
    27950: {
      lat: 36.52599,
      lng: -76.00081,
      city: 'Knotts Island',
      state: 'NC',
    },
    27953: {
      lat: 35.79298,
      lng: -75.8685,
      city: 'Manns Harbor',
      state: 'NC',
    },
    27954: {
      lat: 35.91056,
      lng: -75.68202,
      city: 'Manteo',
      state: 'NC',
    },
    27956: {
      lat: 36.39999,
      lng: -76.00115,
      city: 'Maple',
      state: 'NC',
    },
    27957: {
      lat: 36.05577,
      lng: -76.76293,
      city: 'Merry Hill',
      state: 'NC',
    },
    27958: {
      lat: 36.48971,
      lng: -76.15378,
      city: 'Moyock',
      state: 'NC',
    },
    27959: {
      lat: 35.88566,
      lng: -75.59641,
      city: 'Nags Head',
      state: 'NC',
    },
    27960: {
      lat: 35.12593,
      lng: -75.92165,
      city: 'Ocracoke',
      state: 'NC',
    },
    27962: {
      lat: 35.78437,
      lng: -76.75026,
      city: 'Plymouth',
      state: 'NC',
    },
    27964: {
      lat: 36.10074,
      lng: -75.80438,
      city: 'Point Harbor',
      state: 'NC',
    },
    27965: {
      lat: 36.28404,
      lng: -75.92681,
      city: 'Poplar Branch',
      state: 'NC',
    },
    27966: {
      lat: 36.13325,
      lng: -75.83833,
      city: 'Powells Point',
      state: 'NC',
    },
    27967: {
      lat: 36.22232,
      lng: -76.90838,
      city: 'Powellsville',
      state: 'NC',
    },
    27968: {
      lat: 35.68549,
      lng: -75.49195,
      city: 'Rodanthe',
      state: 'NC',
    },
    27969: {
      lat: 36.4633,
      lng: -76.80259,
      city: 'Roduco',
      state: 'NC',
    },
    27970: {
      lat: 35.87596,
      lng: -76.57945,
      city: 'Roper',
      state: 'NC',
    },
    27972: {
      lat: 35.55427,
      lng: -75.46986,
      city: 'Salvo',
      state: 'NC',
    },
    27973: {
      lat: 36.36448,
      lng: -76.07058,
      city: 'Shawboro',
      state: 'NC',
    },
    27974: {
      lat: 36.23941,
      lng: -76.00264,
      city: 'Shiloh',
      state: 'NC',
    },
    27976: {
      lat: 36.48185,
      lng: -76.34005,
      city: 'South Mills',
      state: 'NC',
    },
    27978: {
      lat: 35.66438,
      lng: -75.76959,
      city: 'Stumpy Point',
      state: 'NC',
    },
    27979: {
      lat: 36.44072,
      lng: -76.56243,
      city: 'Sunbury',
      state: 'NC',
    },
    27980: {
      lat: 36.24292,
      lng: -76.62161,
      city: 'Tyner',
      state: 'NC',
    },
    27981: {
      lat: 35.85602,
      lng: -75.64041,
      city: 'Wanchese',
      state: 'NC',
    },
    27982: { lat: 35.5635, lng: -75.46466, city: 'Waves', state: 'NC' },
    27983: {
      lat: 35.98198,
      lng: -76.94704,
      city: 'Windsor',
      state: 'NC',
    },
    27985: {
      lat: 36.22085,
      lng: -76.46928,
      city: 'Winfall',
      state: 'NC',
    },
    27986: {
      lat: 36.3891,
      lng: -76.92816,
      city: 'Winton',
      state: 'NC',
    },
    28001: {
      lat: 35.33594,
      lng: -80.20419,
      city: 'Albemarle',
      state: 'NC',
    },
    28006: {
      lat: 35.40664,
      lng: -81.0926,
      city: 'Alexis',
      state: 'NC',
    },
    28007: {
      lat: 35.12169,
      lng: -80.13052,
      city: 'Ansonville',
      state: 'NC',
    },
    28009: {
      lat: 35.40826,
      lng: -80.10743,
      city: 'Badin',
      state: 'NC',
    },
    28012: {
      lat: 35.21054,
      lng: -81.04077,
      city: 'Belmont',
      state: 'NC',
    },
    28016: {
      lat: 35.31729,
      lng: -81.28737,
      city: 'Bessemer City',
      state: 'NC',
    },
    28017: {
      lat: 35.24708,
      lng: -81.67059,
      city: 'Boiling Springs',
      state: 'NC',
    },
    28018: {
      lat: 35.48458,
      lng: -81.79393,
      city: 'Bostic',
      state: 'NC',
    },
    28019: {
      lat: 35.28395,
      lng: -81.79396,
      city: 'Caroleen',
      state: 'NC',
    },
    28020: {
      lat: 35.52408,
      lng: -81.64328,
      city: 'Casar',
      state: 'NC',
    },
    28021: {
      lat: 35.39952,
      lng: -81.41035,
      city: 'Cherryville',
      state: 'NC',
    },
    28023: {
      lat: 35.56922,
      lng: -80.60321,
      city: 'China Grove',
      state: 'NC',
    },
    28024: {
      lat: 35.24347,
      lng: -81.77066,
      city: 'Cliffside',
      state: 'NC',
    },
    28025: {
      lat: 35.38095,
      lng: -80.52719,
      city: 'Concord',
      state: 'NC',
    },
    28027: {
      lat: 35.40879,
      lng: -80.67524,
      city: 'Concord',
      state: 'NC',
    },
    28031: {
      lat: 35.47539,
      lng: -80.89344,
      city: 'Cornelius',
      state: 'NC',
    },
    28032: {
      lat: 35.23354,
      lng: -81.07925,
      city: 'Cramerton',
      state: 'NC',
    },
    28033: {
      lat: 35.42005,
      lng: -81.3239,
      city: 'Crouse',
      state: 'NC',
    },
    28034: {
      lat: 35.35323,
      lng: -81.18016,
      city: 'Dallas',
      state: 'NC',
    },
    28036: {
      lat: 35.48586,
      lng: -80.79375,
      city: 'Davidson',
      state: 'NC',
    },
    28037: {
      lat: 35.5064,
      lng: -81.01712,
      city: 'Denver',
      state: 'NC',
    },
    28039: {
      lat: 35.6803,
      lng: -80.43626,
      city: 'East Spencer',
      state: 'NC',
    },
    28040: {
      lat: 35.38738,
      lng: -81.74762,
      city: 'Ellenboro',
      state: 'NC',
    },
    28041: {
      lat: 35.58747,
      lng: -80.46249,
      city: 'Faith',
      state: 'NC',
    },
    28042: {
      lat: 35.42334,
      lng: -81.50747,
      city: 'Fallston',
      state: 'NC',
    },
    28043: {
      lat: 35.31301,
      lng: -81.86971,
      city: 'Forest City',
      state: 'NC',
    },
    28052: {
      lat: 35.22492,
      lng: -81.23266,
      city: 'Gastonia',
      state: 'NC',
    },
    28054: {
      lat: 35.26128,
      lng: -81.15072,
      city: 'Gastonia',
      state: 'NC',
    },
    28056: {
      lat: 35.21768,
      lng: -81.12503,
      city: 'Gastonia',
      state: 'NC',
    },
    28071: {
      lat: 35.51859,
      lng: -80.32175,
      city: 'Gold Hill',
      state: 'NC',
    },
    28072: {
      lat: 35.61326,
      lng: -80.44241,
      city: 'Granite Quarry',
      state: 'NC',
    },
    28073: {
      lat: 35.19776,
      lng: -81.48126,
      city: 'Grover',
      state: 'NC',
    },
    28074: {
      lat: 35.23933,
      lng: -81.8783,
      city: 'Harris',
      state: 'NC',
    },
    28075: {
      lat: 35.30192,
      lng: -80.63929,
      city: 'Harrisburg',
      state: 'NC',
    },
    28076: {
      lat: 35.25904,
      lng: -81.79074,
      city: 'Henrietta',
      state: 'NC',
    },
    28077: {
      lat: 35.40413,
      lng: -81.20284,
      city: 'High Shoals',
      state: 'NC',
    },
    28078: {
      lat: 35.40545,
      lng: -80.86633,
      city: 'Huntersville',
      state: 'NC',
    },
    28079: {
      lat: 35.1132,
      lng: -80.60183,
      city: 'Indian Trail',
      state: 'NC',
    },
    28080: {
      lat: 35.45269,
      lng: -81.10995,
      city: 'Iron Station',
      state: 'NC',
    },
    28081: {
      lat: 35.50199,
      lng: -80.67024,
      city: 'Kannapolis',
      state: 'NC',
    },
    28083: {
      lat: 35.49108,
      lng: -80.58092,
      city: 'Kannapolis',
      state: 'NC',
    },
    28086: {
      lat: 35.24381,
      lng: -81.37028,
      city: 'Kings Mountain',
      state: 'NC',
    },
    28088: {
      lat: 35.54437,
      lng: -80.61463,
      city: 'Landis',
      state: 'NC',
    },
    28089: {
      lat: 35.3202,
      lng: -81.66067,
      city: 'Lattimore',
      state: 'NC',
    },
    28090: {
      lat: 35.45987,
      lng: -81.55957,
      city: 'Lawndale',
      state: 'NC',
    },
    28091: {
      lat: 34.98628,
      lng: -79.93171,
      city: 'Lilesville',
      state: 'NC',
    },
    28092: {
      lat: 35.48404,
      lng: -81.25391,
      city: 'Lincolnton',
      state: 'NC',
    },
    28097: {
      lat: 35.29675,
      lng: -80.39327,
      city: 'Locust',
      state: 'NC',
    },
    28098: {
      lat: 35.27057,
      lng: -81.09749,
      city: 'Lowell',
      state: 'NC',
    },
    28101: {
      lat: 35.25703,
      lng: -81.07572,
      city: 'McAdenville',
      state: 'NC',
    },
    28102: {
      lat: 34.81482,
      lng: -79.97663,
      city: 'McFarlan',
      state: 'NC',
    },
    28103: {
      lat: 35.0037,
      lng: -80.35316,
      city: 'Marshville',
      state: 'NC',
    },
    28104: {
      lat: 35.06017,
      lng: -80.69666,
      city: 'Matthews',
      state: 'NC',
    },
    28105: {
      lat: 35.11914,
      lng: -80.71116,
      city: 'Matthews',
      state: 'NC',
    },
    28107: {
      lat: 35.24992,
      lng: -80.52115,
      city: 'Midland',
      state: 'NC',
    },
    28108: {
      lat: 34.93411,
      lng: -80.67975,
      city: 'Mineral Springs',
      state: 'NC',
    },
    28109: {
      lat: 35.48609,
      lng: -80.28628,
      city: 'Misenheimer',
      state: 'NC',
    },
    28110: {
      lat: 35.06552,
      lng: -80.52781,
      city: 'Monroe',
      state: 'NC',
    },
    28112: {
      lat: 34.89355,
      lng: -80.54113,
      city: 'Monroe',
      state: 'NC',
    },
    28114: {
      lat: 35.23,
      lng: -81.74814,
      city: 'Mooresboro',
      state: 'NC',
    },
    28115: {
      lat: 35.58001,
      lng: -80.77477,
      city: 'Mooresville',
      state: 'NC',
    },
    28117: {
      lat: 35.57405,
      lng: -80.89658,
      city: 'Mooresville',
      state: 'NC',
    },
    28119: {
      lat: 34.85112,
      lng: -80.01492,
      city: 'Morven',
      state: 'NC',
    },
    28120: {
      lat: 35.33305,
      lng: -81.02692,
      city: 'Mount Holly',
      state: 'NC',
    },
    28124: {
      lat: 35.40136,
      lng: -80.40406,
      city: 'Mount Pleasant',
      state: 'NC',
    },
    28125: {
      lat: 35.66061,
      lng: -80.69949,
      city: 'Mount Ulla',
      state: 'NC',
    },
    28127: {
      lat: 35.44978,
      lng: -80.16552,
      city: 'New London',
      state: 'NC',
    },
    28128: {
      lat: 35.21476,
      lng: -80.15862,
      city: 'Norwood',
      state: 'NC',
    },
    28129: {
      lat: 35.23224,
      lng: -80.32737,
      city: 'Oakboro',
      state: 'NC',
    },
    28133: {
      lat: 34.93468,
      lng: -80.26239,
      city: 'Peachland',
      state: 'NC',
    },
    28134: {
      lat: 35.08364,
      lng: -80.89125,
      city: 'Pineville',
      state: 'NC',
    },
    28135: {
      lat: 35.03547,
      lng: -80.21412,
      city: 'Polkton',
      state: 'NC',
    },
    28136: {
      lat: 35.4206,
      lng: -81.64241,
      city: 'Polkville',
      state: 'NC',
    },
    28137: {
      lat: 35.50009,
      lng: -80.25783,
      city: 'Richfield',
      state: 'NC',
    },
    28138: {
      lat: 35.52121,
      lng: -80.44193,
      city: 'Rockwell',
      state: 'NC',
    },
    28139: {
      lat: 35.34736,
      lng: -81.99702,
      city: 'Rutherfordton',
      state: 'NC',
    },
    28144: {
      lat: 35.70645,
      lng: -80.46341,
      city: 'Salisbury',
      state: 'NC',
    },
    28146: {
      lat: 35.62194,
      lng: -80.39563,
      city: 'Salisbury',
      state: 'NC',
    },
    28147: {
      lat: 35.67997,
      lng: -80.56275,
      city: 'Salisbury',
      state: 'NC',
    },
    28150: {
      lat: 35.34712,
      lng: -81.57502,
      city: 'Shelby',
      state: 'NC',
    },
    28152: {
      lat: 35.24188,
      lng: -81.59289,
      city: 'Shelby',
      state: 'NC',
    },
    28159: {
      lat: 35.69411,
      lng: -80.43528,
      city: 'Spencer',
      state: 'NC',
    },
    28160: {
      lat: 35.36064,
      lng: -81.92234,
      city: 'Spindale',
      state: 'NC',
    },
    28163: {
      lat: 35.20702,
      lng: -80.4285,
      city: 'Stanfield',
      state: 'NC',
    },
    28164: {
      lat: 35.39091,
      lng: -81.03846,
      city: 'Stanley',
      state: 'NC',
    },
    28166: {
      lat: 35.68025,
      lng: -80.87528,
      city: 'Troutman',
      state: 'NC',
    },
    28167: {
      lat: 35.5062,
      lng: -81.95986,
      city: 'Union Mills',
      state: 'NC',
    },
    28168: { lat: 35.55452, lng: -81.42228, city: 'Vale', state: 'NC' },
    28169: { lat: 35.36036, lng: -81.42935, city: 'Waco', state: 'NC' },
    28170: {
      lat: 34.99263,
      lng: -80.09259,
      city: 'Wadesboro',
      state: 'NC',
    },
    28173: {
      lat: 34.91532,
      lng: -80.73021,
      city: 'Waxhaw',
      state: 'NC',
    },
    28174: {
      lat: 34.96959,
      lng: -80.4382,
      city: 'Wingate',
      state: 'NC',
    },
    28202: {
      lat: 35.22773,
      lng: -80.8447,
      city: 'Charlotte',
      state: 'NC',
    },
    28203: {
      lat: 35.20832,
      lng: -80.8577,
      city: 'Charlotte',
      state: 'NC',
    },
    28204: {
      lat: 35.21469,
      lng: -80.82677,
      city: 'Charlotte',
      state: 'NC',
    },
    28205: {
      lat: 35.22019,
      lng: -80.78737,
      city: 'Charlotte',
      state: 'NC',
    },
    28206: {
      lat: 35.25631,
      lng: -80.82144,
      city: 'Charlotte',
      state: 'NC',
    },
    28207: {
      lat: 35.19512,
      lng: -80.82606,
      city: 'Charlotte',
      state: 'NC',
    },
    28208: {
      lat: 35.23153,
      lng: -80.90611,
      city: 'Charlotte',
      state: 'NC',
    },
    28209: {
      lat: 35.17878,
      lng: -80.85418,
      city: 'Charlotte',
      state: 'NC',
    },
    28210: {
      lat: 35.12969,
      lng: -80.8548,
      city: 'Charlotte',
      state: 'NC',
    },
    28211: {
      lat: 35.16816,
      lng: -80.79604,
      city: 'Charlotte',
      state: 'NC',
    },
    28212: {
      lat: 35.1896,
      lng: -80.74519,
      city: 'Charlotte',
      state: 'NC',
    },
    28213: {
      lat: 35.28459,
      lng: -80.73339,
      city: 'Charlotte',
      state: 'NC',
    },
    28214: {
      lat: 35.27177,
      lng: -80.96847,
      city: 'Charlotte',
      state: 'NC',
    },
    28215: {
      lat: 35.24854,
      lng: -80.69571,
      city: 'Charlotte',
      state: 'NC',
    },
    28216: {
      lat: 35.31039,
      lng: -80.8883,
      city: 'Charlotte',
      state: 'NC',
    },
    28217: {
      lat: 35.17146,
      lng: -80.9077,
      city: 'Charlotte',
      state: 'NC',
    },
    28223: {
      lat: 35.30824,
      lng: -80.73282,
      city: 'Charlotte',
      state: 'NC',
    },
    28226: {
      lat: 35.10767,
      lng: -80.81837,
      city: 'Charlotte',
      state: 'NC',
    },
    28227: {
      lat: 35.18851,
      lng: -80.6507,
      city: 'Charlotte',
      state: 'NC',
    },
    28244: {
      lat: 35.2246,
      lng: -80.8431,
      city: 'Charlotte',
      state: 'NC',
    },
    28262: {
      lat: 35.32609,
      lng: -80.74234,
      city: 'Charlotte',
      state: 'NC',
    },
    28269: {
      lat: 35.33747,
      lng: -80.8026,
      city: 'Charlotte',
      state: 'NC',
    },
    28270: {
      lat: 35.10963,
      lng: -80.76005,
      city: 'Charlotte',
      state: 'NC',
    },
    28273: {
      lat: 35.12624,
      lng: -80.94469,
      city: 'Charlotte',
      state: 'NC',
    },
    28274: {
      lat: 35.18909,
      lng: -80.83384,
      city: 'Charlotte',
      state: 'NC',
    },
    28277: {
      lat: 35.05212,
      lng: -80.81622,
      city: 'Charlotte',
      state: 'NC',
    },
    28278: {
      lat: 35.13063,
      lng: -81.00753,
      city: 'Charlotte',
      state: 'NC',
    },
    28280: {
      lat: 35.22633,
      lng: -80.84309,
      city: 'Charlotte',
      state: 'NC',
    },
    28282: {
      lat: 35.22481,
      lng: -80.84529,
      city: 'Charlotte',
      state: 'NC',
    },
    28301: {
      lat: 35.07442,
      lng: -78.88376,
      city: 'Fayetteville',
      state: 'NC',
    },
    28303: {
      lat: 35.0852,
      lng: -78.95815,
      city: 'Fayetteville',
      state: 'NC',
    },
    28304: {
      lat: 35.02498,
      lng: -78.99072,
      city: 'Fayetteville',
      state: 'NC',
    },
    28305: {
      lat: 35.05321,
      lng: -78.90687,
      city: 'Fayetteville',
      state: 'NC',
    },
    28306: {
      lat: 34.9582,
      lng: -78.89487,
      city: 'Fayetteville',
      state: 'NC',
    },
    28307: {
      lat: 35.14154,
      lng: -78.99447,
      city: 'Fort Liberty',
      state: 'NC',
    },
    28308: {
      lat: 35.17206,
      lng: -79.0149,
      city: 'Pope Army Airfield',
      state: 'NC',
    },
    28310: {
      lat: 35.12946,
      lng: -79.0523,
      city: 'Fort Liberty',
      state: 'NC',
    },
    28311: {
      lat: 35.17033,
      lng: -78.88223,
      city: 'Fayetteville',
      state: 'NC',
    },
    28312: {
      lat: 34.94713,
      lng: -78.73225,
      city: 'Fayetteville',
      state: 'NC',
    },
    28314: {
      lat: 35.0557,
      lng: -79.01678,
      city: 'Fayetteville',
      state: 'NC',
    },
    28315: {
      lat: 35.1122,
      lng: -79.44452,
      city: 'Aberdeen',
      state: 'NC',
    },
    28318: {
      lat: 35.0176,
      lng: -78.61665,
      city: 'Autryville',
      state: 'NC',
    },
    28320: {
      lat: 34.55378,
      lng: -78.77456,
      city: 'Bladenboro',
      state: 'NC',
    },
    28323: {
      lat: 35.31192,
      lng: -78.83842,
      city: 'Bunnlevel',
      state: 'NC',
    },
    28325: {
      lat: 35.15335,
      lng: -78.10425,
      city: 'Calypso',
      state: 'NC',
    },
    28326: {
      lat: 35.27938,
      lng: -79.16989,
      city: 'Cameron',
      state: 'NC',
    },
    28327: {
      lat: 35.35759,
      lng: -79.42333,
      city: 'Carthage',
      state: 'NC',
    },
    28328: {
      lat: 35.00504,
      lng: -78.33513,
      city: 'Clinton',
      state: 'NC',
    },
    28330: {
      lat: 34.91077,
      lng: -79.82332,
      city: 'Cordova',
      state: 'NC',
    },
    28331: {
      lat: 35.19883,
      lng: -78.64944,
      city: 'Cumberland',
      state: 'NC',
    },
    28332: {
      lat: 34.65808,
      lng: -78.70971,
      city: 'Dublin',
      state: 'NC',
    },
    28333: {
      lat: 35.28292,
      lng: -78.01239,
      city: 'Dudley',
      state: 'NC',
    },
    28334: { lat: 35.2681, lng: -78.57051, city: 'Dunn', state: 'NC' },
    28337: {
      lat: 34.66094,
      lng: -78.57733,
      city: 'Elizabethtown',
      state: 'NC',
    },
    28338: {
      lat: 35.09644,
      lng: -79.75506,
      city: 'Ellerbe',
      state: 'NC',
    },
    28339: {
      lat: 35.31409,
      lng: -78.72115,
      city: 'Erwin',
      state: 'NC',
    },
    28340: {
      lat: 34.47136,
      lng: -79.1403,
      city: 'Fairmont',
      state: 'NC',
    },
    28341: {
      lat: 35.11919,
      lng: -78.17577,
      city: 'Faison',
      state: 'NC',
    },
    28342: {
      lat: 35.18949,
      lng: -78.6499,
      city: 'Falcon',
      state: 'NC',
    },
    28343: {
      lat: 34.76454,
      lng: -79.58129,
      city: 'Gibson',
      state: 'NC',
    },
    28344: {
      lat: 35.16506,
      lng: -78.63139,
      city: 'Godwin',
      state: 'NC',
    },
    28345: {
      lat: 34.87464,
      lng: -79.6716,
      city: 'Hamlet',
      state: 'NC',
    },
    28347: {
      lat: 35.04652,
      lng: -79.56203,
      city: 'Hoffman',
      state: 'NC',
    },
    28348: {
      lat: 34.92691,
      lng: -78.91313,
      city: 'Hope Mills',
      state: 'NC',
    },
    28349: {
      lat: 34.98206,
      lng: -77.92033,
      city: 'Kenansville',
      state: 'NC',
    },
    28350: {
      lat: 35.2488,
      lng: -79.32064,
      city: 'Lakeview',
      state: 'NC',
    },
    28351: {
      lat: 34.84098,
      lng: -79.56102,
      city: 'Laurel Hill',
      state: 'NC',
    },
    28352: {
      lat: 34.76762,
      lng: -79.45321,
      city: 'Laurinburg',
      state: 'NC',
    },
    28355: {
      lat: 35.39053,
      lng: -79.19514,
      city: 'Lemon Springs',
      state: 'NC',
    },
    28356: {
      lat: 35.23092,
      lng: -78.79113,
      city: 'Linden',
      state: 'NC',
    },
    28357: {
      lat: 34.90606,
      lng: -79.08688,
      city: 'Lumber Bridge',
      state: 'NC',
    },
    28358: {
      lat: 34.60527,
      lng: -78.94097,
      city: 'Lumberton',
      state: 'NC',
    },
    28359: {
      lat: 34.58686,
      lng: -79.05475,
      city: 'Lumberton',
      state: 'NC',
    },
    28360: {
      lat: 34.67588,
      lng: -79.07582,
      city: 'Lumberton',
      state: 'NC',
    },
    28362: {
      lat: 34.36961,
      lng: -79.12882,
      city: 'Marietta',
      state: 'NC',
    },
    28363: {
      lat: 34.95772,
      lng: -79.53384,
      city: 'Marston',
      state: 'NC',
    },
    28364: {
      lat: 34.71447,
      lng: -79.32759,
      city: 'Maxton',
      state: 'NC',
    },
    28365: {
      lat: 35.17807,
      lng: -78.05827,
      city: 'Mount Olive',
      state: 'NC',
    },
    28366: {
      lat: 35.22974,
      lng: -78.36317,
      city: 'Newton Grove',
      state: 'NC',
    },
    28367: {
      lat: 35.16982,
      lng: -79.72325,
      city: 'Norman',
      state: 'NC',
    },
    28368: {
      lat: 35.36942,
      lng: -79.11331,
      city: 'Olivia',
      state: 'NC',
    },
    28369: {
      lat: 34.42184,
      lng: -79.02901,
      city: 'Orrum',
      state: 'NC',
    },
    28371: {
      lat: 34.9079,
      lng: -78.99018,
      city: 'Parkton',
      state: 'NC',
    },
    28372: {
      lat: 34.69991,
      lng: -79.1786,
      city: 'Pembroke',
      state: 'NC',
    },
    28373: {
      lat: 35.09079,
      lng: -79.48522,
      city: 'Pinebluff',
      state: 'NC',
    },
    28374: {
      lat: 35.19705,
      lng: -79.46009,
      city: 'Pinehurst',
      state: 'NC',
    },
    28375: {
      lat: 34.47723,
      lng: -79.04337,
      city: 'Proctorville',
      state: 'NC',
    },
    28376: {
      lat: 35.052,
      lng: -79.23342,
      city: 'Raeford',
      state: 'NC',
    },
    28377: {
      lat: 34.83526,
      lng: -79.21282,
      city: 'Red Springs',
      state: 'NC',
    },
    28378: { lat: 34.85872, lng: -79.0506, city: 'Rex', state: 'NC' },
    28379: {
      lat: 34.93073,
      lng: -79.78252,
      city: 'Rockingham',
      state: 'NC',
    },
    28382: {
      lat: 34.97546,
      lng: -78.50561,
      city: 'Roseboro',
      state: 'NC',
    },
    28383: {
      lat: 34.57553,
      lng: -79.26856,
      city: 'Rowland',
      state: 'NC',
    },
    28384: {
      lat: 34.79687,
      lng: -78.95035,
      city: 'Saint Pauls',
      state: 'NC',
    },
    28385: {
      lat: 35.04461,
      lng: -78.50012,
      city: 'Salemburg',
      state: 'NC',
    },
    28386: {
      lat: 34.85889,
      lng: -79.12984,
      city: 'Shannon',
      state: 'NC',
    },
    28387: {
      lat: 35.18059,
      lng: -79.37566,
      city: 'Southern Pines',
      state: 'NC',
    },
    28390: {
      lat: 35.21783,
      lng: -78.95488,
      city: 'Spring Lake',
      state: 'NC',
    },
    28391: {
      lat: 35.03045,
      lng: -78.70009,
      city: 'Stedman',
      state: 'NC',
    },
    28392: {
      lat: 34.73467,
      lng: -78.79465,
      city: 'Tar Heel',
      state: 'NC',
    },
    28393: {
      lat: 34.98205,
      lng: -78.18895,
      city: 'Turkey',
      state: 'NC',
    },
    28394: { lat: 35.21652, lng: -79.23777, city: 'Vass', state: 'NC' },
    28395: { lat: 35.13643, lng: -78.73855, city: 'Wade', state: 'NC' },
    28396: {
      lat: 34.93948,
      lng: -79.40971,
      city: 'Wagram',
      state: 'NC',
    },
    28398: {
      lat: 34.99307,
      lng: -78.07143,
      city: 'Warsaw',
      state: 'NC',
    },
    28399: {
      lat: 34.77885,
      lng: -78.72953,
      city: 'White Oak',
      state: 'NC',
    },
    28401: {
      lat: 34.29053,
      lng: -77.97053,
      city: 'Wilmington',
      state: 'NC',
    },
    28403: {
      lat: 34.22177,
      lng: -77.87902,
      city: 'Wilmington',
      state: 'NC',
    },
    28405: {
      lat: 34.26388,
      lng: -77.86743,
      city: 'Wilmington',
      state: 'NC',
    },
    28409: {
      lat: 34.15244,
      lng: -77.86477,
      city: 'Wilmington',
      state: 'NC',
    },
    28411: {
      lat: 34.3015,
      lng: -77.79307,
      city: 'Wilmington',
      state: 'NC',
    },
    28412: {
      lat: 34.13306,
      lng: -77.9191,
      city: 'Wilmington',
      state: 'NC',
    },
    28420: { lat: 34.07831, lng: -78.46087, city: 'Ash', state: 'NC' },
    28421: {
      lat: 34.52183,
      lng: -78.17802,
      city: 'Atkinson',
      state: 'NC',
    },
    28422: {
      lat: 34.05192,
      lng: -78.20147,
      city: 'Bolivia',
      state: 'NC',
    },
    28423: {
      lat: 34.27782,
      lng: -78.40483,
      city: 'Bolton',
      state: 'NC',
    },
    28424: {
      lat: 34.29032,
      lng: -78.69971,
      city: 'Brunswick',
      state: 'NC',
    },
    28425: {
      lat: 34.5591,
      lng: -77.90253,
      city: 'Burgaw',
      state: 'NC',
    },
    28428: {
      lat: 34.03831,
      lng: -77.90682,
      city: 'Carolina Beach',
      state: 'NC',
    },
    28429: {
      lat: 34.34171,
      lng: -77.9011,
      city: 'Castle Hayne',
      state: 'NC',
    },
    28430: {
      lat: 34.28419,
      lng: -78.93333,
      city: 'Cerro Gordo',
      state: 'NC',
    },
    28431: {
      lat: 34.31661,
      lng: -78.84397,
      city: 'Chadbourn',
      state: 'NC',
    },
    28432: {
      lat: 34.17018,
      lng: -78.75795,
      city: 'Clarendon',
      state: 'NC',
    },
    28433: {
      lat: 34.48979,
      lng: -78.62598,
      city: 'Clarkton',
      state: 'NC',
    },
    28434: {
      lat: 34.47154,
      lng: -78.47479,
      city: 'Council',
      state: 'NC',
    },
    28435: {
      lat: 34.41007,
      lng: -78.11508,
      city: 'Currie',
      state: 'NC',
    },
    28436: {
      lat: 34.26703,
      lng: -78.27552,
      city: 'Delco',
      state: 'NC',
    },
    28438: {
      lat: 34.4166,
      lng: -78.92221,
      city: 'Evergreen',
      state: 'NC',
    },
    28439: {
      lat: 34.29411,
      lng: -79.00947,
      city: 'Fair Bluff',
      state: 'NC',
    },
    28441: {
      lat: 34.79047,
      lng: -78.4355,
      city: 'Garland',
      state: 'NC',
    },
    28442: {
      lat: 34.31023,
      lng: -78.60106,
      city: 'Hallsboro',
      state: 'NC',
    },
    28443: {
      lat: 34.46211,
      lng: -77.67312,
      city: 'Hampstead',
      state: 'NC',
    },
    28444: {
      lat: 34.6885,
      lng: -78.31798,
      city: 'Harrells',
      state: 'NC',
    },
    28445: {
      lat: 34.54199,
      lng: -77.54019,
      city: 'Holly Ridge',
      state: 'NC',
    },
    28447: {
      lat: 34.59953,
      lng: -78.25848,
      city: 'Ivanhoe',
      state: 'NC',
    },
    28448: {
      lat: 34.49078,
      lng: -78.31933,
      city: 'Kelly',
      state: 'NC',
    },
    28449: {
      lat: 33.98151,
      lng: -77.92748,
      city: 'Kure Beach',
      state: 'NC',
    },
    28450: {
      lat: 34.29689,
      lng: -78.51425,
      city: 'Lake Waccamaw',
      state: 'NC',
    },
    28451: {
      lat: 34.2327,
      lng: -78.11503,
      city: 'Leland',
      state: 'NC',
    },
    28452: {
      lat: 34.0031,
      lng: -78.55068,
      city: 'Longwood',
      state: 'NC',
    },
    28453: {
      lat: 34.88283,
      lng: -78.06805,
      city: 'Magnolia',
      state: 'NC',
    },
    28454: {
      lat: 34.6703,
      lng: -77.65393,
      city: 'Maple Hill',
      state: 'NC',
    },
    28455: {
      lat: 34.11045,
      lng: -78.62865,
      city: 'Nakina',
      state: 'NC',
    },
    28456: {
      lat: 34.37678,
      lng: -78.29228,
      city: 'Riegelwood',
      state: 'NC',
    },
    28457: {
      lat: 34.45608,
      lng: -77.86618,
      city: 'Rocky Point',
      state: 'NC',
    },
    28458: {
      lat: 34.81886,
      lng: -78.07895,
      city: 'Rose Hill',
      state: 'NC',
    },
    28460: {
      lat: 34.52569,
      lng: -77.41873,
      city: 'Sneads Ferry',
      state: 'NC',
    },
    28461: {
      lat: 33.96607,
      lng: -78.03874,
      city: 'Southport',
      state: 'NC',
    },
    28462: { lat: 34, lng: -78.30497, city: 'Supply', state: 'NC' },
    28463: {
      lat: 34.10213,
      lng: -78.78001,
      city: 'Tabor City',
      state: 'NC',
    },
    28464: {
      lat: 34.77661,
      lng: -78.01854,
      city: 'Teachey',
      state: 'NC',
    },
    28465: {
      lat: 33.91227,
      lng: -78.09913,
      city: 'Oak Island',
      state: 'NC',
    },
    28466: {
      lat: 34.73219,
      lng: -77.8954,
      city: 'Wallace',
      state: 'NC',
    },
    28467: {
      lat: 33.91968,
      lng: -78.58434,
      city: 'Calabash',
      state: 'NC',
    },
    28468: {
      lat: 33.90333,
      lng: -78.52295,
      city: 'Sunset Beach',
      state: 'NC',
    },
    28469: {
      lat: 33.93271,
      lng: -78.47159,
      city: 'Ocean Isle Beach',
      state: 'NC',
    },
    28470: {
      lat: 33.96415,
      lng: -78.3987,
      city: 'Shallotte',
      state: 'NC',
    },
    28472: {
      lat: 34.29707,
      lng: -78.68452,
      city: 'Whiteville',
      state: 'NC',
    },
    28478: {
      lat: 34.64217,
      lng: -78.04255,
      city: 'Willard',
      state: 'NC',
    },
    28479: {
      lat: 34.09488,
      lng: -78.01496,
      city: 'Winnabow',
      state: 'NC',
    },
    28480: {
      lat: 34.21679,
      lng: -77.79957,
      city: 'Wrightsville Beach',
      state: 'NC',
    },
    28501: {
      lat: 35.23838,
      lng: -77.52658,
      city: 'Kinston',
      state: 'NC',
    },
    28504: {
      lat: 35.22755,
      lng: -77.63566,
      city: 'Kinston',
      state: 'NC',
    },
    28508: {
      lat: 35.09684,
      lng: -77.82789,
      city: 'Albertson',
      state: 'NC',
    },
    28509: {
      lat: 35.14524,
      lng: -76.80662,
      city: 'Alliance',
      state: 'NC',
    },
    28510: {
      lat: 35.00516,
      lng: -76.81171,
      city: 'Arapahoe',
      state: 'NC',
    },
    28511: {
      lat: 34.89959,
      lng: -76.35195,
      city: 'Atlantic',
      state: 'NC',
    },
    28512: {
      lat: 34.69857,
      lng: -76.77505,
      city: 'Atlantic Beach',
      state: 'NC',
    },
    28513: { lat: 35.44276, lng: -77.3897, city: 'Ayden', state: 'NC' },
    28515: {
      lat: 35.19285,
      lng: -76.70263,
      city: 'Bayboro',
      state: 'NC',
    },
    28516: {
      lat: 34.84827,
      lng: -76.61128,
      city: 'Beaufort',
      state: 'NC',
    },
    28518: {
      lat: 34.89678,
      lng: -77.75569,
      city: 'Beulaville',
      state: 'NC',
    },
    28519: {
      lat: 35.1268,
      lng: -77.01477,
      city: 'Bridgeton',
      state: 'NC',
    },
    28520: {
      lat: 34.95613,
      lng: -76.44484,
      city: 'Cedar Island',
      state: 'NC',
    },
    28521: {
      lat: 34.82136,
      lng: -77.73684,
      city: 'Chinquapin',
      state: 'NC',
    },
    28523: {
      lat: 35.20662,
      lng: -77.29541,
      city: 'Cove City',
      state: 'NC',
    },
    28524: {
      lat: 34.78893,
      lng: -76.47452,
      city: 'Davis',
      state: 'NC',
    },
    28525: {
      lat: 35.1241,
      lng: -77.68987,
      city: 'Deep Run',
      state: 'NC',
    },
    28526: {
      lat: 35.24439,
      lng: -77.40447,
      city: 'Dover',
      state: 'NC',
    },
    28527: {
      lat: 35.26396,
      lng: -77.01568,
      city: 'Ernul',
      state: 'NC',
    },
    28528: {
      lat: 34.73695,
      lng: -76.5396,
      city: 'Gloucester',
      state: 'NC',
    },
    28529: {
      lat: 35.12237,
      lng: -76.86848,
      city: 'Grantsboro',
      state: 'NC',
    },
    28530: {
      lat: 35.372,
      lng: -77.41579,
      city: 'Grifton',
      state: 'NC',
    },
    28531: {
      lat: 34.70015,
      lng: -76.56116,
      city: 'Harkers Island',
      state: 'NC',
    },
    28532: {
      lat: 34.88857,
      lng: -76.90872,
      city: 'Havelock',
      state: 'NC',
    },
    28533: {
      lat: 34.90167,
      lng: -76.89955,
      city: 'Cherry Point',
      state: 'NC',
    },
    28537: {
      lat: 35.25558,
      lng: -76.55912,
      city: 'Hobucken',
      state: 'NC',
    },
    28538: {
      lat: 35.41769,
      lng: -77.56366,
      city: 'Hookerton',
      state: 'NC',
    },
    28539: {
      lat: 34.6891,
      lng: -77.23615,
      city: 'Hubert',
      state: 'NC',
    },
    28540: {
      lat: 34.74361,
      lng: -77.49833,
      city: 'Jacksonville',
      state: 'NC',
    },
    28542: {
      lat: 34.72992,
      lng: -77.46198,
      city: 'Camp Lejeune',
      state: 'NC',
    },
    28543: {
      lat: 34.73263,
      lng: -77.39825,
      city: 'Tarawa Terrace',
      state: 'NC',
    },
    28544: {
      lat: 34.70853,
      lng: -77.31562,
      city: 'Midway Park',
      state: 'NC',
    },
    28546: {
      lat: 34.83461,
      lng: -77.38818,
      city: 'Jacksonville',
      state: 'NC',
    },
    28547: {
      lat: 34.62518,
      lng: -77.36307,
      city: 'Camp Lejeune',
      state: 'NC',
    },
    28551: {
      lat: 35.32204,
      lng: -77.78087,
      city: 'La Grange',
      state: 'NC',
    },
    28552: {
      lat: 35.29281,
      lng: -76.54382,
      city: 'Lowland',
      state: 'NC',
    },
    28553: {
      lat: 34.73217,
      lng: -76.51335,
      city: 'Marshallberg',
      state: 'NC',
    },
    28554: { lat: 35.47569, lng: -77.5926, city: 'Maury', state: 'NC' },
    28555: {
      lat: 34.86982,
      lng: -77.24543,
      city: 'Maysville',
      state: 'NC',
    },
    28556: {
      lat: 35.12233,
      lng: -76.66904,
      city: 'Merritt',
      state: 'NC',
    },
    28557: {
      lat: 34.74064,
      lng: -76.74566,
      city: 'Morehead City',
      state: 'NC',
    },
    28560: {
      lat: 35.13354,
      lng: -76.97575,
      city: 'New Bern',
      state: 'NC',
    },
    28562: {
      lat: 35.08105,
      lng: -77.12486,
      city: 'New Bern',
      state: 'NC',
    },
    28570: {
      lat: 34.78123,
      lng: -76.88003,
      city: 'Newport',
      state: 'NC',
    },
    28571: {
      lat: 35.05679,
      lng: -76.72311,
      city: 'Oriental',
      state: 'NC',
    },
    28572: {
      lat: 35.02256,
      lng: -77.72637,
      city: 'Pink Hill',
      state: 'NC',
    },
    28573: {
      lat: 34.97997,
      lng: -77.19794,
      city: 'Pollocksville',
      state: 'NC',
    },
    28574: {
      lat: 34.87716,
      lng: -77.59212,
      city: 'Richlands',
      state: 'NC',
    },
    28575: {
      lat: 34.68833,
      lng: -76.88129,
      city: 'Salter Path',
      state: 'NC',
    },
    28577: {
      lat: 34.91494,
      lng: -76.40769,
      city: 'Sealevel',
      state: 'NC',
    },
    28578: {
      lat: 35.20017,
      lng: -77.86332,
      city: 'Seven Springs',
      state: 'NC',
    },
    28579: {
      lat: 34.80411,
      lng: -76.50422,
      city: 'Smyrna',
      state: 'NC',
    },
    28580: {
      lat: 35.45116,
      lng: -77.68053,
      city: 'Snow Hill',
      state: 'NC',
    },
    28581: {
      lat: 34.84992,
      lng: -76.43341,
      city: 'Stacy',
      state: 'NC',
    },
    28582: {
      lat: 34.7613,
      lng: -77.14507,
      city: 'Stella',
      state: 'NC',
    },
    28584: {
      lat: 34.72632,
      lng: -77.10002,
      city: 'Swansboro',
      state: 'NC',
    },
    28585: {
      lat: 35.05264,
      lng: -77.41497,
      city: 'Trenton',
      state: 'NC',
    },
    28586: {
      lat: 35.31884,
      lng: -77.16602,
      city: 'Vanceboro',
      state: 'NC',
    },
    28587: {
      lat: 35.18714,
      lng: -76.66783,
      city: 'Vandemere',
      state: 'NC',
    },
    28589: {
      lat: 34.79343,
      lng: -76.49398,
      city: 'Williston',
      state: 'NC',
    },
    28590: {
      lat: 35.52046,
      lng: -77.41651,
      city: 'Winterville',
      state: 'NC',
    },
    28594: {
      lat: 34.66486,
      lng: -77.02918,
      city: 'Emerald Isle',
      state: 'NC',
    },
    28601: {
      lat: 35.7729,
      lng: -81.32704,
      city: 'Hickory',
      state: 'NC',
    },
    28602: {
      lat: 35.67574,
      lng: -81.38459,
      city: 'Hickory',
      state: 'NC',
    },
    28604: {
      lat: 36.17701,
      lng: -81.84745,
      city: 'Banner Elk',
      state: 'NC',
    },
    28605: {
      lat: 36.13454,
      lng: -81.70218,
      city: 'Blowing Rock',
      state: 'NC',
    },
    28606: {
      lat: 36.05346,
      lng: -81.31413,
      city: 'Boomer',
      state: 'NC',
    },
    28607: {
      lat: 36.21547,
      lng: -81.64427,
      city: 'Boone',
      state: 'NC',
    },
    28609: {
      lat: 35.67608,
      lng: -81.05314,
      city: 'Catawba',
      state: 'NC',
    },
    28610: {
      lat: 35.73103,
      lng: -81.13837,
      city: 'Claremont',
      state: 'NC',
    },
    28611: {
      lat: 36.00608,
      lng: -81.73699,
      city: 'Collettsville',
      state: 'NC',
    },
    28612: {
      lat: 35.6641,
      lng: -81.53619,
      city: 'Connelly Springs',
      state: 'NC',
    },
    28613: {
      lat: 35.73978,
      lng: -81.20897,
      city: 'Conover',
      state: 'NC',
    },
    28615: {
      lat: 36.46407,
      lng: -81.65368,
      city: 'Creston',
      state: 'NC',
    },
    28616: {
      lat: 36.02138,
      lng: -81.93021,
      city: 'Crossnore',
      state: 'NC',
    },
    28617: {
      lat: 36.48433,
      lng: -81.38264,
      city: 'Crumpler',
      state: 'NC',
    },
    28618: {
      lat: 36.20888,
      lng: -81.51291,
      city: 'Deep Gap',
      state: 'NC',
    },
    28619: {
      lat: 35.76013,
      lng: -81.60674,
      city: 'Drexel',
      state: 'NC',
    },
    28621: {
      lat: 36.30863,
      lng: -80.83612,
      city: 'Elkin',
      state: 'NC',
    },
    28622: {
      lat: 36.19492,
      lng: -81.94899,
      city: 'Elk Park',
      state: 'NC',
    },
    28623: {
      lat: 36.53088,
      lng: -80.97185,
      city: 'Ennice',
      state: 'NC',
    },
    28624: {
      lat: 36.11971,
      lng: -81.41327,
      city: 'Ferguson',
      state: 'NC',
    },
    28625: {
      lat: 35.86487,
      lng: -80.88709,
      city: 'Statesville',
      state: 'NC',
    },
    28626: {
      lat: 36.29256,
      lng: -81.50119,
      city: 'Fleetwood',
      state: 'NC',
    },
    28627: {
      lat: 36.4528,
      lng: -81.01294,
      city: 'Glade Valley',
      state: 'NC',
    },
    28628: {
      lat: 35.72914,
      lng: -81.77826,
      city: 'Glen Alpine',
      state: 'NC',
    },
    28629: {
      lat: 36.34196,
      lng: -81.36423,
      city: 'Glendale Springs',
      state: 'NC',
    },
    28630: {
      lat: 35.82771,
      lng: -81.41998,
      city: 'Granite Falls',
      state: 'NC',
    },
    28631: {
      lat: 36.55218,
      lng: -81.40994,
      city: 'Grassy Creek',
      state: 'NC',
    },
    28634: {
      lat: 35.97052,
      lng: -80.75528,
      city: 'Harmony',
      state: 'NC',
    },
    28635: { lat: 36.33297, lng: -81.12603, city: 'Hays', state: 'NC' },
    28636: {
      lat: 35.94241,
      lng: -81.06589,
      city: 'Hiddenite',
      state: 'NC',
    },
    28637: {
      lat: 35.71768,
      lng: -81.41939,
      city: 'Hildebran',
      state: 'NC',
    },
    28638: {
      lat: 35.84411,
      lng: -81.47701,
      city: 'Hudson',
      state: 'NC',
    },
    28640: {
      lat: 36.40385,
      lng: -81.40751,
      city: 'Jefferson',
      state: 'NC',
    },
    28641: {
      lat: 35.97902,
      lng: -81.89684,
      city: 'Jonas Ridge',
      state: 'NC',
    },
    28642: {
      lat: 36.22433,
      lng: -80.81628,
      city: 'Jonesville',
      state: 'NC',
    },
    28643: {
      lat: 36.52982,
      lng: -81.53486,
      city: 'Lansing',
      state: 'NC',
    },
    28644: {
      lat: 36.43134,
      lng: -81.26758,
      city: 'Laurel Springs',
      state: 'NC',
    },
    28645: {
      lat: 35.97778,
      lng: -81.54371,
      city: 'Lenoir',
      state: 'NC',
    },
    28646: {
      lat: 36.08261,
      lng: -81.83811,
      city: 'Linville',
      state: 'NC',
    },
    28649: {
      lat: 36.33198,
      lng: -81.20326,
      city: 'McGrady',
      state: 'NC',
    },
    28650: {
      lat: 35.57347,
      lng: -81.15668,
      city: 'Maiden',
      state: 'NC',
    },
    28651: {
      lat: 36.27241,
      lng: -81.28496,
      city: 'Millers Creek',
      state: 'NC',
    },
    28652: {
      lat: 36.08969,
      lng: -81.98656,
      city: 'Minneapolis',
      state: 'NC',
    },
    28653: {
      lat: 36.06615,
      lng: -81.90072,
      city: 'Montezuma',
      state: 'NC',
    },
    28654: {
      lat: 36.06338,
      lng: -81.15003,
      city: 'Moravian Falls',
      state: 'NC',
    },
    28655: {
      lat: 35.75578,
      lng: -81.74212,
      city: 'Morganton',
      state: 'NC',
    },
    28657: {
      lat: 36.02833,
      lng: -81.93977,
      city: 'Newland',
      state: 'NC',
    },
    28658: {
      lat: 35.63557,
      lng: -81.24222,
      city: 'Newton',
      state: 'NC',
    },
    28659: {
      lat: 36.16647,
      lng: -81.07913,
      city: 'North Wilkesboro',
      state: 'NC',
    },
    28660: { lat: 35.9689, lng: -80.85785, city: 'Olin', state: 'NC' },
    28662: {
      lat: 36.02237,
      lng: -81.89171,
      city: 'Pineola',
      state: 'NC',
    },
    28663: {
      lat: 36.53477,
      lng: -81.30063,
      city: 'Piney Creek',
      state: 'NC',
    },
    28664: {
      lat: 36.04249,
      lng: -82.00596,
      city: 'Plumtree',
      state: 'NC',
    },
    28665: {
      lat: 36.20969,
      lng: -81.37392,
      city: 'Purlear',
      state: 'NC',
    },
    28666: {
      lat: 35.71864,
      lng: -81.47057,
      city: 'Icard',
      state: 'NC',
    },
    28667: {
      lat: 35.77423,
      lng: -81.42793,
      city: 'Rhodhiss',
      state: 'NC',
    },
    28668: {
      lat: 36.40698,
      lng: -80.99024,
      city: 'Roaring Gap',
      state: 'NC',
    },
    28669: {
      lat: 36.22538,
      lng: -80.99443,
      city: 'Roaring River',
      state: 'NC',
    },
    28670: {
      lat: 36.19508,
      lng: -80.92282,
      city: 'Ronda',
      state: 'NC',
    },
    28671: {
      lat: 35.7502,
      lng: -81.52668,
      city: 'Rutherford College',
      state: 'NC',
    },
    28672: {
      lat: 36.48381,
      lng: -81.32735,
      city: 'Scottville',
      state: 'NC',
    },
    28673: {
      lat: 35.60704,
      lng: -80.99817,
      city: 'Sherrills Ford',
      state: 'NC',
    },
    28675: {
      lat: 36.50642,
      lng: -81.13546,
      city: 'Sparta',
      state: 'NC',
    },
    28676: {
      lat: 36.3283,
      lng: -80.85722,
      city: 'State Road',
      state: 'NC',
    },
    28677: {
      lat: 35.73743,
      lng: -80.92272,
      city: 'Statesville',
      state: 'NC',
    },
    28678: {
      lat: 35.83586,
      lng: -81.06151,
      city: 'Stony Point',
      state: 'NC',
    },
    28679: {
      lat: 36.25962,
      lng: -81.84207,
      city: 'Sugar Grove',
      state: 'NC',
    },
    28681: {
      lat: 35.92185,
      lng: -81.22151,
      city: 'Taylorsville',
      state: 'NC',
    },
    28682: {
      lat: 35.5838,
      lng: -80.96369,
      city: 'Terrell',
      state: 'NC',
    },
    28683: {
      lat: 36.38835,
      lng: -80.91219,
      city: 'Thurmond',
      state: 'NC',
    },
    28684: { lat: 36.33541, lng: -81.6163, city: 'Todd', state: 'NC' },
    28685: {
      lat: 36.34729,
      lng: -81.04097,
      city: 'Traphill',
      state: 'NC',
    },
    28689: {
      lat: 36.04319,
      lng: -80.93345,
      city: 'Union Grove',
      state: 'NC',
    },
    28690: {
      lat: 35.73237,
      lng: -81.5739,
      city: 'Valdese',
      state: 'NC',
    },
    28692: {
      lat: 36.28023,
      lng: -81.79259,
      city: 'Vilas',
      state: 'NC',
    },
    28693: {
      lat: 36.46622,
      lng: -81.55434,
      city: 'Warrensville',
      state: 'NC',
    },
    28694: {
      lat: 36.36197,
      lng: -81.47465,
      city: 'West Jefferson',
      state: 'NC',
    },
    28697: {
      lat: 36.13666,
      lng: -81.16317,
      city: 'Wilkesboro',
      state: 'NC',
    },
    28698: {
      lat: 36.344,
      lng: -81.73604,
      city: 'Zionville',
      state: 'NC',
    },
    28701: {
      lat: 35.70558,
      lng: -82.64096,
      city: 'Alexander',
      state: 'NC',
    },
    28702: {
      lat: 35.39346,
      lng: -83.62906,
      city: 'Almond',
      state: 'NC',
    },
    28704: {
      lat: 35.46305,
      lng: -82.57142,
      city: 'Arden',
      state: 'NC',
    },
    28705: {
      lat: 36.04349,
      lng: -82.1573,
      city: 'Bakersville',
      state: 'NC',
    },
    28707: {
      lat: 35.39873,
      lng: -83.0662,
      city: 'Balsam',
      state: 'NC',
    },
    28708: {
      lat: 35.27105,
      lng: -82.85979,
      city: 'Balsam Grove',
      state: 'NC',
    },
    28709: {
      lat: 35.76321,
      lng: -82.40428,
      city: 'Barnardsville',
      state: 'NC',
    },
    28710: {
      lat: 35.45683,
      lng: -82.27971,
      city: 'Bat Cave',
      state: 'NC',
    },
    28711: {
      lat: 35.59666,
      lng: -82.28767,
      city: 'Black Mountain',
      state: 'NC',
    },
    28712: {
      lat: 35.17324,
      lng: -82.76711,
      city: 'Brevard',
      state: 'NC',
    },
    28713: {
      lat: 35.3697,
      lng: -83.50559,
      city: 'Bryson City',
      state: 'NC',
    },
    28714: {
      lat: 35.87739,
      lng: -82.30714,
      city: 'Burnsville',
      state: 'NC',
    },
    28715: {
      lat: 35.51247,
      lng: -82.71196,
      city: 'Candler',
      state: 'NC',
    },
    28716: {
      lat: 35.4358,
      lng: -82.86126,
      city: 'Canton',
      state: 'NC',
    },
    28717: {
      lat: 35.07131,
      lng: -83.09458,
      city: 'Cashiers',
      state: 'NC',
    },
    28718: {
      lat: 35.16614,
      lng: -82.63108,
      city: 'Cedar Mountain',
      state: 'NC',
    },
    28719: {
      lat: 35.53223,
      lng: -83.47056,
      city: 'Cherokee',
      state: 'NC',
    },
    28720: {
      lat: 35.42766,
      lng: -82.25145,
      city: 'Chimney Rock',
      state: 'NC',
    },
    28721: {
      lat: 35.63378,
      lng: -82.93081,
      city: 'Clyde',
      state: 'NC',
    },
    28722: {
      lat: 35.23408,
      lng: -82.12794,
      city: 'Columbus',
      state: 'NC',
    },
    28723: {
      lat: 35.27776,
      lng: -83.12195,
      city: 'Cullowhee',
      state: 'NC',
    },
    28725: {
      lat: 35.37631,
      lng: -83.26411,
      city: 'Dillsboro',
      state: 'NC',
    },
    28726: {
      lat: 35.282,
      lng: -82.41752,
      city: 'East Flat Rock',
      state: 'NC',
    },
    28729: {
      lat: 35.31961,
      lng: -82.60283,
      city: 'Etowah',
      state: 'NC',
    },
    28730: {
      lat: 35.52792,
      lng: -82.37588,
      city: 'Fairview',
      state: 'NC',
    },
    28731: {
      lat: 35.28905,
      lng: -82.37796,
      city: 'Flat Rock',
      state: 'NC',
    },
    28732: {
      lat: 35.44667,
      lng: -82.46577,
      city: 'Fletcher',
      state: 'NC',
    },
    28733: {
      lat: 35.43536,
      lng: -83.78403,
      city: 'Fontana Dam',
      state: 'NC',
    },
    28734: {
      lat: 35.1825,
      lng: -83.42599,
      city: 'Franklin',
      state: 'NC',
    },
    28735: {
      lat: 35.47544,
      lng: -82.3485,
      city: 'Gerton',
      state: 'NC',
    },
    28736: {
      lat: 35.18039,
      lng: -83.0838,
      city: 'Glenville',
      state: 'NC',
    },
    28739: {
      lat: 35.2719,
      lng: -82.5419,
      city: 'Hendersonville',
      state: 'NC',
    },
    28740: {
      lat: 36.04118,
      lng: -82.30287,
      city: 'Green Mountain',
      state: 'NC',
    },
    28741: {
      lat: 35.06614,
      lng: -83.21053,
      city: 'Highlands',
      state: 'NC',
    },
    28742: {
      lat: 35.39486,
      lng: -82.65436,
      city: 'Horse Shoe',
      state: 'NC',
    },
    28743: {
      lat: 35.80869,
      lng: -82.90114,
      city: 'Hot Springs',
      state: 'NC',
    },
    28745: {
      lat: 35.52524,
      lng: -82.97152,
      city: 'Lake Junaluska',
      state: 'NC',
    },
    28746: {
      lat: 35.461,
      lng: -82.16779,
      city: 'Lake Lure',
      state: 'NC',
    },
    28747: {
      lat: 35.15185,
      lng: -82.9249,
      city: 'Lake Toxaway',
      state: 'NC',
    },
    28748: {
      lat: 35.65492,
      lng: -82.76032,
      city: 'Leicester',
      state: 'NC',
    },
    28749: {
      lat: 35.84098,
      lng: -82.09797,
      city: 'Little Switzerland',
      state: 'NC',
    },
    28751: {
      lat: 35.50547,
      lng: -83.11955,
      city: 'Maggie Valley',
      state: 'NC',
    },
    28752: {
      lat: 35.71124,
      lng: -82.03631,
      city: 'Marion',
      state: 'NC',
    },
    28753: {
      lat: 35.87181,
      lng: -82.7002,
      city: 'Marshall',
      state: 'NC',
    },
    28754: {
      lat: 35.87696,
      lng: -82.51441,
      city: 'Mars Hill',
      state: 'NC',
    },
    28755: {
      lat: 35.90825,
      lng: -82.21756,
      city: 'Micaville',
      state: 'NC',
    },
    28756: {
      lat: 35.34256,
      lng: -82.18219,
      city: 'Mill Spring',
      state: 'NC',
    },
    28757: {
      lat: 35.64781,
      lng: -82.30006,
      city: 'Montreat',
      state: 'NC',
    },
    28758: {
      lat: 35.37489,
      lng: -82.49569,
      city: 'Mountain Home',
      state: 'NC',
    },
    28759: {
      lat: 35.38348,
      lng: -82.58431,
      city: 'Mills River',
      state: 'NC',
    },
    28761: { lat: 35.69134, lng: -81.90714, city: 'Nebo', state: 'NC' },
    28762: {
      lat: 35.63517,
      lng: -82.18929,
      city: 'Old Fort',
      state: 'NC',
    },
    28763: { lat: 35.03796, lng: -83.43215, city: 'Otto', state: 'NC' },
    28766: {
      lat: 35.24945,
      lng: -82.61923,
      city: 'Penrose',
      state: 'NC',
    },
    28768: {
      lat: 35.30437,
      lng: -82.70925,
      city: 'Pisgah Forest',
      state: 'NC',
    },
    28770: {
      lat: 35.62104,
      lng: -82.2782,
      city: 'Ridgecrest',
      state: 'NC',
    },
    28771: {
      lat: 35.34245,
      lng: -83.8567,
      city: 'Robbinsville',
      state: 'NC',
    },
    28772: {
      lat: 35.13927,
      lng: -82.83709,
      city: 'Rosman',
      state: 'NC',
    },
    28773: {
      lat: 35.25246,
      lng: -82.31166,
      city: 'Saluda',
      state: 'NC',
    },
    28774: {
      lat: 35.08976,
      lng: -82.99791,
      city: 'Sapphire',
      state: 'NC',
    },
    28775: {
      lat: 35.01916,
      lng: -83.32186,
      city: 'Scaly Mountain',
      state: 'NC',
    },
    28777: {
      lat: 35.90833,
      lng: -82.06617,
      city: 'Spruce Pine',
      state: 'NC',
    },
    28778: {
      lat: 35.61667,
      lng: -82.40768,
      city: 'Swannanoa',
      state: 'NC',
    },
    28779: { lat: 35.3558, lng: -83.20962, city: 'Sylva', state: 'NC' },
    28781: {
      lat: 35.21417,
      lng: -83.63877,
      city: 'Topton',
      state: 'NC',
    },
    28782: { lat: 35.23569, lng: -82.1519, city: 'Tryon', state: 'NC' },
    28783: {
      lat: 35.24906,
      lng: -83.02,
      city: 'Tuckasegee',
      state: 'NC',
    },
    28785: {
      lat: 35.64632,
      lng: -83.08952,
      city: 'Waynesville',
      state: 'NC',
    },
    28786: {
      lat: 35.46272,
      lng: -82.99294,
      city: 'Waynesville',
      state: 'NC',
    },
    28787: {
      lat: 35.72795,
      lng: -82.52306,
      city: 'Weaverville',
      state: 'NC',
    },
    28788: {
      lat: 35.35044,
      lng: -83.22266,
      city: 'Webster',
      state: 'NC',
    },
    28789: {
      lat: 35.4077,
      lng: -83.31727,
      city: 'Whittier',
      state: 'NC',
    },
    28790: {
      lat: 35.20036,
      lng: -82.49507,
      city: 'Zirconia',
      state: 'NC',
    },
    28791: {
      lat: 35.35674,
      lng: -82.508,
      city: 'Hendersonville',
      state: 'NC',
    },
    28792: {
      lat: 35.39289,
      lng: -82.36786,
      city: 'Hendersonville',
      state: 'NC',
    },
    28801: {
      lat: 35.59441,
      lng: -82.55785,
      city: 'Asheville',
      state: 'NC',
    },
    28803: {
      lat: 35.52949,
      lng: -82.52007,
      city: 'Asheville',
      state: 'NC',
    },
    28804: {
      lat: 35.64814,
      lng: -82.5612,
      city: 'Asheville',
      state: 'NC',
    },
    28805: {
      lat: 35.61528,
      lng: -82.47923,
      city: 'Asheville',
      state: 'NC',
    },
    28806: {
      lat: 35.57154,
      lng: -82.61386,
      city: 'Asheville',
      state: 'NC',
    },
    28901: {
      lat: 35.20127,
      lng: -83.80506,
      city: 'Andrews',
      state: 'NC',
    },
    28902: {
      lat: 35.02503,
      lng: -83.9571,
      city: 'Brasstown',
      state: 'NC',
    },
    28904: {
      lat: 35.06185,
      lng: -83.73041,
      city: 'Hayesville',
      state: 'NC',
    },
    28905: { lat: 35.1631, lng: -83.9409, city: 'Marble', state: 'NC' },
    28906: {
      lat: 35.11873,
      lng: -84.13343,
      city: 'Murphy',
      state: 'NC',
    },
    28909: {
      lat: 35.00125,
      lng: -83.90319,
      city: 'Warne',
      state: 'NC',
    },
    29001: {
      lat: 33.78528,
      lng: -80.17338,
      city: 'Alcolu',
      state: 'SC',
    },
    29003: {
      lat: 33.24135,
      lng: -81.01838,
      city: 'Bamberg',
      state: 'SC',
    },
    29006: {
      lat: 33.87673,
      lng: -81.55294,
      city: 'Batesburg',
      state: 'SC',
    },
    29009: {
      lat: 34.44097,
      lng: -80.37642,
      city: 'Bethune',
      state: 'SC',
    },
    29010: {
      lat: 34.21487,
      lng: -80.26946,
      city: 'Bishopville',
      state: 'SC',
    },
    29014: {
      lat: 34.54368,
      lng: -81.13553,
      city: 'Blackstock',
      state: 'SC',
    },
    29015: { lat: 34.4412, lng: -81.33807, city: 'Blair', state: 'SC' },
    29016: {
      lat: 34.20367,
      lng: -80.99296,
      city: 'Blythewood',
      state: 'SC',
    },
    29018: {
      lat: 33.34619,
      lng: -80.64373,
      city: 'Bowman',
      state: 'SC',
    },
    29020: {
      lat: 34.31036,
      lng: -80.59334,
      city: 'Camden',
      state: 'SC',
    },
    29030: {
      lat: 33.57917,
      lng: -80.65441,
      city: 'Cameron',
      state: 'SC',
    },
    29031: {
      lat: 34.60624,
      lng: -81.42665,
      city: 'Carlisle',
      state: 'SC',
    },
    29032: {
      lat: 34.34002,
      lng: -80.44374,
      city: 'Cassatt',
      state: 'SC',
    },
    29033: { lat: 33.9552, lng: -81.05691, city: 'Cayce', state: 'SC' },
    29036: {
      lat: 34.13396,
      lng: -81.33622,
      city: 'Chapin',
      state: 'SC',
    },
    29037: {
      lat: 34.18961,
      lng: -81.86868,
      city: 'Chappells',
      state: 'SC',
    },
    29038: { lat: 33.37494, lng: -80.98095, city: 'Cope', state: 'SC' },
    29039: {
      lat: 33.41855,
      lng: -80.90556,
      city: 'Cordova',
      state: 'SC',
    },
    29040: {
      lat: 34.06253,
      lng: -80.43455,
      city: 'Dalzell',
      state: 'SC',
    },
    29042: {
      lat: 33.30735,
      lng: -81.14723,
      city: 'Denmark',
      state: 'SC',
    },
    29044: {
      lat: 33.93437,
      lng: -80.69446,
      city: 'Eastover',
      state: 'SC',
    },
    29045: { lat: 34.18389, lng: -80.8141, city: 'Elgin', state: 'SC' },
    29046: {
      lat: 34.11252,
      lng: -80.16131,
      city: 'Elliott',
      state: 'SC',
    },
    29047: {
      lat: 33.53218,
      lng: -80.57956,
      city: 'Elloree',
      state: 'SC',
    },
    29048: {
      lat: 33.39057,
      lng: -80.29702,
      city: 'Eutawville',
      state: 'SC',
    },
    29051: { lat: 33.8669, lng: -80.12231, city: 'Gable', state: 'SC' },
    29052: {
      lat: 33.81036,
      lng: -80.73832,
      city: 'Gadsden',
      state: 'SC',
    },
    29053: {
      lat: 33.82734,
      lng: -81.08646,
      city: 'Gaston',
      state: 'SC',
    },
    29054: {
      lat: 33.94834,
      lng: -81.37875,
      city: 'Gilbert',
      state: 'SC',
    },
    29055: {
      lat: 34.57248,
      lng: -80.91753,
      city: 'Great Falls',
      state: 'SC',
    },
    29056: {
      lat: 33.60798,
      lng: -79.99473,
      city: 'Greeleyville',
      state: 'SC',
    },
    29058: {
      lat: 34.56297,
      lng: -80.73473,
      city: 'Heath Springs',
      state: 'SC',
    },
    29059: {
      lat: 33.32853,
      lng: -80.41891,
      city: 'Holly Hill',
      state: 'SC',
    },
    29061: {
      lat: 33.89923,
      lng: -80.84955,
      city: 'Hopkins',
      state: 'SC',
    },
    29062: {
      lat: 33.99818,
      lng: -80.60785,
      city: 'Horatio',
      state: 'SC',
    },
    29063: { lat: 34.13948, lng: -81.20457, city: 'Irmo', state: 'SC' },
    29065: {
      lat: 34.29495,
      lng: -81.29239,
      city: 'Jenkinsville',
      state: 'SC',
    },
    29067: {
      lat: 34.54976,
      lng: -80.53268,
      city: 'Kershaw',
      state: 'SC',
    },
    29069: {
      lat: 34.18798,
      lng: -80.09654,
      city: 'Lamar',
      state: 'SC',
    },
    29070: {
      lat: 33.91742,
      lng: -81.45517,
      city: 'Leesville',
      state: 'SC',
    },
    29072: {
      lat: 33.9985,
      lng: -81.27098,
      city: 'Lexington',
      state: 'SC',
    },
    29073: {
      lat: 33.89299,
      lng: -81.23774,
      city: 'Lexington',
      state: 'SC',
    },
    29074: {
      lat: 34.4512,
      lng: -80.8144,
      city: 'Liberty Hill',
      state: 'SC',
    },
    29075: {
      lat: 34.19985,
      lng: -81.37581,
      city: 'Little Mountain',
      state: 'SC',
    },
    29078: {
      lat: 34.18162,
      lng: -80.6994,
      city: 'Lugoff',
      state: 'SC',
    },
    29079: { lat: 34.289, lng: -80.11318, city: 'Lydia', state: 'SC' },
    29080: {
      lat: 34.01525,
      lng: -80.08374,
      city: 'Lynchburg',
      state: 'SC',
    },
    29081: {
      lat: 33.09161,
      lng: -81.0426,
      city: 'Ehrhardt',
      state: 'SC',
    },
    29082: {
      lat: 33.04012,
      lng: -80.93868,
      city: 'Lodge',
      state: 'SC',
    },
    29101: {
      lat: 34.47798,
      lng: -80.24038,
      city: 'McBee',
      state: 'SC',
    },
    29102: {
      lat: 33.6437,
      lng: -80.19225,
      city: 'Manning',
      state: 'SC',
    },
    29104: {
      lat: 33.99524,
      lng: -80.21373,
      city: 'Mayesville',
      state: 'SC',
    },
    29105: {
      lat: 33.80764,
      lng: -81.59047,
      city: 'Monetta',
      state: 'SC',
    },
    29107: {
      lat: 33.52348,
      lng: -81.11856,
      city: 'Neeses',
      state: 'SC',
    },
    29108: {
      lat: 34.29805,
      lng: -81.62433,
      city: 'Newberry',
      state: 'SC',
    },
    29111: {
      lat: 33.78648,
      lng: -80.01433,
      city: 'New Zion',
      state: 'SC',
    },
    29112: { lat: 33.62662, lng: -81.0837, city: 'North', state: 'SC' },
    29113: {
      lat: 33.43514,
      lng: -81.13173,
      city: 'Norway',
      state: 'SC',
    },
    29114: {
      lat: 33.95436,
      lng: -79.93537,
      city: 'Olanta',
      state: 'SC',
    },
    29115: {
      lat: 33.47673,
      lng: -80.84758,
      city: 'Orangeburg',
      state: 'SC',
    },
    29117: {
      lat: 33.49828,
      lng: -80.85,
      city: 'Orangeburg',
      state: 'SC',
    },
    29118: {
      lat: 33.57056,
      lng: -80.89501,
      city: 'Orangeburg',
      state: 'SC',
    },
    29122: { lat: 34.24015, lng: -81.32532, city: 'Peak', state: 'SC' },
    29123: {
      lat: 33.76563,
      lng: -81.25844,
      city: 'Pelion',
      state: 'SC',
    },
    29125: {
      lat: 33.70165,
      lng: -80.47434,
      city: 'Pinewood',
      state: 'SC',
    },
    29126: {
      lat: 34.32195,
      lng: -81.41932,
      city: 'Pomaria',
      state: 'SC',
    },
    29127: {
      lat: 34.16568,
      lng: -81.51192,
      city: 'Prosperity',
      state: 'SC',
    },
    29128: {
      lat: 34.0963,
      lng: -80.53268,
      city: 'Rembert',
      state: 'SC',
    },
    29129: {
      lat: 33.79236,
      lng: -81.66823,
      city: 'Ridge Spring',
      state: 'SC',
    },
    29130: {
      lat: 34.32757,
      lng: -80.89205,
      city: 'Ridgeway',
      state: 'SC',
    },
    29133: {
      lat: 33.35726,
      lng: -80.80698,
      city: 'Rowesville',
      state: 'SC',
    },
    29135: {
      lat: 33.68834,
      lng: -80.7875,
      city: 'Saint Matthews',
      state: 'SC',
    },
    29137: {
      lat: 33.59626,
      lng: -81.32226,
      city: 'Salley',
      state: 'SC',
    },
    29138: {
      lat: 34.04491,
      lng: -81.77152,
      city: 'Saluda',
      state: 'SC',
    },
    29142: {
      lat: 33.46491,
      lng: -80.52329,
      city: 'Santee',
      state: 'SC',
    },
    29145: {
      lat: 34.21905,
      lng: -81.77453,
      city: 'Silverstreet',
      state: 'SC',
    },
    29146: {
      lat: 33.50816,
      lng: -81.29812,
      city: 'Springfield',
      state: 'SC',
    },
    29148: {
      lat: 33.53731,
      lng: -80.34386,
      city: 'Summerton',
      state: 'SC',
    },
    29150: {
      lat: 33.87621,
      lng: -80.35609,
      city: 'Sumter',
      state: 'SC',
    },
    29152: {
      lat: 33.97517,
      lng: -80.47019,
      city: 'Shaw Afb',
      state: 'SC',
    },
    29153: {
      lat: 33.96515,
      lng: -80.30675,
      city: 'Sumter',
      state: 'SC',
    },
    29154: {
      lat: 33.88515,
      lng: -80.47547,
      city: 'Sumter',
      state: 'SC',
    },
    29160: {
      lat: 33.74358,
      lng: -81.04188,
      city: 'Swansea',
      state: 'SC',
    },
    29161: {
      lat: 34.10532,
      lng: -79.94143,
      city: 'Timmonsville',
      state: 'SC',
    },
    29162: {
      lat: 33.88751,
      lng: -80.00865,
      city: 'Turbeville',
      state: 'SC',
    },
    29163: {
      lat: 33.42019,
      lng: -80.43918,
      city: 'Vance',
      state: 'SC',
    },
    29164: {
      lat: 33.65699,
      lng: -81.42586,
      city: 'Wagener',
      state: 'SC',
    },
    29166: { lat: 33.90102, lng: -81.71304, city: 'Ward', state: 'SC' },
    29168: {
      lat: 33.84848,
      lng: -80.54483,
      city: 'Wedgefield',
      state: 'SC',
    },
    29169: {
      lat: 33.9967,
      lng: -81.09676,
      city: 'West Columbia',
      state: 'SC',
    },
    29170: {
      lat: 33.93929,
      lng: -81.14487,
      city: 'West Columbia',
      state: 'SC',
    },
    29172: {
      lat: 33.90603,
      lng: -81.07264,
      city: 'West Columbia',
      state: 'SC',
    },
    29175: {
      lat: 34.44266,
      lng: -80.60666,
      city: 'Westville',
      state: 'SC',
    },
    29177: {
      lat: 34.1405,
      lng: -81.2675,
      city: 'White Rock',
      state: 'SC',
    },
    29178: {
      lat: 34.4992,
      lng: -81.5961,
      city: 'Whitmire',
      state: 'SC',
    },
    29180: {
      lat: 34.36472,
      lng: -81.09821,
      city: 'Winnsboro',
      state: 'SC',
    },
    29201: {
      lat: 33.98381,
      lng: -81.02829,
      city: 'Columbia',
      state: 'SC',
    },
    29203: {
      lat: 34.10198,
      lng: -81.04009,
      city: 'Columbia',
      state: 'SC',
    },
    29204: {
      lat: 34.02938,
      lng: -81.00008,
      city: 'Columbia',
      state: 'SC',
    },
    29205: {
      lat: 33.99001,
      lng: -80.99661,
      city: 'Columbia',
      state: 'SC',
    },
    29206: {
      lat: 34.02848,
      lng: -80.95828,
      city: 'Columbia',
      state: 'SC',
    },
    29207: {
      lat: 34.04306,
      lng: -80.84208,
      city: 'Columbia',
      state: 'SC',
    },
    29208: {
      lat: 33.99751,
      lng: -81.02681,
      city: 'Columbia',
      state: 'SC',
    },
    29209: {
      lat: 33.92442,
      lng: -80.95284,
      city: 'Columbia',
      state: 'SC',
    },
    29210: {
      lat: 34.04764,
      lng: -81.10697,
      city: 'Columbia',
      state: 'SC',
    },
    29212: {
      lat: 34.0777,
      lng: -81.2022,
      city: 'Columbia',
      state: 'SC',
    },
    29223: {
      lat: 34.09243,
      lng: -80.92153,
      city: 'Columbia',
      state: 'SC',
    },
    29225: {
      lat: 33.99538,
      lng: -81.03006,
      city: 'Columbia',
      state: 'SC',
    },
    29229: {
      lat: 34.14697,
      lng: -80.89316,
      city: 'Columbia',
      state: 'SC',
    },
    29301: {
      lat: 34.93516,
      lng: -82.01301,
      city: 'Spartanburg',
      state: 'SC',
    },
    29302: {
      lat: 34.89399,
      lng: -81.84151,
      city: 'Spartanburg',
      state: 'SC',
    },
    29303: {
      lat: 34.99424,
      lng: -81.96883,
      city: 'Spartanburg',
      state: 'SC',
    },
    29306: {
      lat: 34.89338,
      lng: -81.92177,
      city: 'Spartanburg',
      state: 'SC',
    },
    29307: {
      lat: 34.98381,
      lng: -81.83146,
      city: 'Spartanburg',
      state: 'SC',
    },
    29316: {
      lat: 35.04179,
      lng: -81.9766,
      city: 'Boiling Springs',
      state: 'SC',
    },
    29320: {
      lat: 34.95847,
      lng: -81.9922,
      city: 'Arcadia',
      state: 'SC',
    },
    29321: {
      lat: 34.71975,
      lng: -81.74151,
      city: 'Buffalo',
      state: 'SC',
    },
    29322: {
      lat: 35.12254,
      lng: -82.13157,
      city: 'Campobello',
      state: 'SC',
    },
    29323: {
      lat: 35.12911,
      lng: -81.90902,
      city: 'Chesnee',
      state: 'SC',
    },
    29324: {
      lat: 34.98944,
      lng: -81.82523,
      city: 'Clifton',
      state: 'SC',
    },
    29325: {
      lat: 34.47682,
      lng: -81.83973,
      city: 'Clinton',
      state: 'SC',
    },
    29329: {
      lat: 34.99262,
      lng: -81.83682,
      city: 'Converse',
      state: 'SC',
    },
    29330: {
      lat: 35.05362,
      lng: -81.80463,
      city: 'Cowpens',
      state: 'SC',
    },
    29331: {
      lat: 34.64351,
      lng: -81.85836,
      city: 'Cross Anchor',
      state: 'SC',
    },
    29332: {
      lat: 34.28329,
      lng: -81.97292,
      city: 'Cross Hill',
      state: 'SC',
    },
    29333: {
      lat: 34.97092,
      lng: -81.91069,
      city: 'Drayton',
      state: 'SC',
    },
    29334: {
      lat: 34.90372,
      lng: -82.12526,
      city: 'Duncan',
      state: 'SC',
    },
    29335: {
      lat: 34.65714,
      lng: -81.90035,
      city: 'Enoree',
      state: 'SC',
    },
    29338: {
      lat: 35.13444,
      lng: -82.00172,
      city: 'Fingerville',
      state: 'SC',
    },
    29340: {
      lat: 34.98225,
      lng: -81.59427,
      city: 'Gaffney',
      state: 'SC',
    },
    29341: {
      lat: 35.1184,
      lng: -81.71321,
      city: 'Gaffney',
      state: 'SC',
    },
    29346: {
      lat: 34.94524,
      lng: -81.83627,
      city: 'Glendale',
      state: 'SC',
    },
    29349: { lat: 35.07045, lng: -82.0688, city: 'Inman', state: 'SC' },
    29351: {
      lat: 34.42177,
      lng: -81.80967,
      city: 'Joanna',
      state: 'SC',
    },
    29353: {
      lat: 34.83064,
      lng: -81.65636,
      city: 'Jonesville',
      state: 'SC',
    },
    29355: {
      lat: 34.37012,
      lng: -81.75894,
      city: 'Kinards',
      state: 'SC',
    },
    29356: {
      lat: 35.14411,
      lng: -82.26187,
      city: 'Landrum',
      state: 'SC',
    },
    29360: {
      lat: 34.49361,
      lng: -82.04884,
      city: 'Laurens',
      state: 'SC',
    },
    29364: {
      lat: 34.78846,
      lng: -81.46202,
      city: 'Lockhart',
      state: 'SC',
    },
    29365: {
      lat: 34.98079,
      lng: -82.16869,
      city: 'Lyman',
      state: 'SC',
    },
    29368: { lat: 35.08262, lng: -81.86281, city: 'Mayo', state: 'SC' },
    29369: {
      lat: 34.86535,
      lng: -82.02207,
      city: 'Moore',
      state: 'SC',
    },
    29370: {
      lat: 34.37315,
      lng: -81.96816,
      city: 'Mountville',
      state: 'SC',
    },
    29372: {
      lat: 34.9059,
      lng: -81.70878,
      city: 'Pacolet',
      state: 'SC',
    },
    29373: {
      lat: 34.92442,
      lng: -81.74276,
      city: 'Pacolet Mills',
      state: 'SC',
    },
    29374: {
      lat: 34.7861,
      lng: -81.84854,
      city: 'Pauline',
      state: 'SC',
    },
    29375: {
      lat: 34.86049,
      lng: -82.11386,
      city: 'Reidville',
      state: 'SC',
    },
    29376: {
      lat: 34.81564,
      lng: -81.94921,
      city: 'Roebuck',
      state: 'SC',
    },
    29377: {
      lat: 34.93036,
      lng: -82.0971,
      city: 'Startex',
      state: 'SC',
    },
    29378: { lat: 34.96684, lng: -81.96857, city: 'Una', state: 'SC' },
    29379: {
      lat: 34.68772,
      lng: -81.60403,
      city: 'Union',
      state: 'SC',
    },
    29384: {
      lat: 34.33976,
      lng: -82.0904,
      city: 'Waterloo',
      state: 'SC',
    },
    29385: {
      lat: 34.97623,
      lng: -82.1024,
      city: 'Wellford',
      state: 'SC',
    },
    29388: {
      lat: 34.76292,
      lng: -82.04299,
      city: 'Woodruff',
      state: 'SC',
    },
    29401: {
      lat: 32.77868,
      lng: -79.9354,
      city: 'Charleston',
      state: 'SC',
    },
    29403: {
      lat: 32.80347,
      lng: -79.94561,
      city: 'Charleston',
      state: 'SC',
    },
    29404: {
      lat: 32.89783,
      lng: -80.05246,
      city: 'Charleston Afb',
      state: 'SC',
    },
    29405: {
      lat: 32.85207,
      lng: -79.97903,
      city: 'North Charleston',
      state: 'SC',
    },
    29406: {
      lat: 32.93417,
      lng: -80.03083,
      city: 'Charleston',
      state: 'SC',
    },
    29407: {
      lat: 32.79912,
      lng: -79.99872,
      city: 'Charleston',
      state: 'SC',
    },
    29409: {
      lat: 32.79662,
      lng: -79.96072,
      city: 'Charleston',
      state: 'SC',
    },
    29410: {
      lat: 32.93258,
      lng: -80.0056,
      city: 'Hanahan',
      state: 'SC',
    },
    29412: {
      lat: 32.70979,
      lng: -79.95115,
      city: 'Charleston',
      state: 'SC',
    },
    29414: {
      lat: 32.84479,
      lng: -80.10756,
      city: 'Charleston',
      state: 'SC',
    },
    29418: {
      lat: 32.88896,
      lng: -80.0644,
      city: 'North Charleston',
      state: 'SC',
    },
    29420: {
      lat: 32.93202,
      lng: -80.09938,
      city: 'North Charleston',
      state: 'SC',
    },
    29424: {
      lat: 32.78494,
      lng: -79.93778,
      city: 'Charleston',
      state: 'SC',
    },
    29425: {
      lat: 32.78516,
      lng: -79.94828,
      city: 'Charleston',
      state: 'SC',
    },
    29426: {
      lat: 32.79275,
      lng: -80.37023,
      city: 'Adams Run',
      state: 'SC',
    },
    29429: {
      lat: 32.98699,
      lng: -79.68011,
      city: 'Awendaw',
      state: 'SC',
    },
    29431: {
      lat: 33.27708,
      lng: -79.87711,
      city: 'Bonneau',
      state: 'SC',
    },
    29432: {
      lat: 33.2294,
      lng: -80.81428,
      city: 'Branchville',
      state: 'SC',
    },
    29434: {
      lat: 33.14027,
      lng: -79.8453,
      city: 'Cordesville',
      state: 'SC',
    },
    29435: {
      lat: 32.9526,
      lng: -80.4672,
      city: 'Cottageville',
      state: 'SC',
    },
    29436: {
      lat: 33.31658,
      lng: -80.18457,
      city: 'Cross',
      state: 'SC',
    },
    29437: {
      lat: 33.13673,
      lng: -80.42319,
      city: 'Dorchester',
      state: 'SC',
    },
    29438: {
      lat: 32.5722,
      lng: -80.31893,
      city: 'Edisto Island',
      state: 'SC',
    },
    29439: {
      lat: 32.66636,
      lng: -79.92902,
      city: 'Folly Beach',
      state: 'SC',
    },
    29440: {
      lat: 33.38229,
      lng: -79.32908,
      city: 'Georgetown',
      state: 'SC',
    },
    29445: {
      lat: 32.9976,
      lng: -79.98757,
      city: 'Goose Creek',
      state: 'SC',
    },
    29446: {
      lat: 32.6281,
      lng: -80.54028,
      city: 'Green Pond',
      state: 'SC',
    },
    29448: {
      lat: 33.24062,
      lng: -80.44639,
      city: 'Harleyville',
      state: 'SC',
    },
    29449: {
      lat: 32.70183,
      lng: -80.28038,
      city: 'Hollywood',
      state: 'SC',
    },
    29450: {
      lat: 33.08731,
      lng: -79.77164,
      city: 'Huger',
      state: 'SC',
    },
    29451: {
      lat: 32.81633,
      lng: -79.75266,
      city: 'Isle Of Palms',
      state: 'SC',
    },
    29452: {
      lat: 32.6997,
      lng: -80.46998,
      city: 'Jacksonboro',
      state: 'SC',
    },
    29453: {
      lat: 33.21984,
      lng: -79.61269,
      city: 'Jamestown',
      state: 'SC',
    },
    29455: {
      lat: 32.68581,
      lng: -80.08604,
      city: 'Johns Island',
      state: 'SC',
    },
    29456: {
      lat: 32.98433,
      lng: -80.11206,
      city: 'Ladson',
      state: 'SC',
    },
    29458: {
      lat: 33.11922,
      lng: -79.4872,
      city: 'McClellanville',
      state: 'SC',
    },
    29461: {
      lat: 33.17009,
      lng: -80.00773,
      city: 'Moncks Corner',
      state: 'SC',
    },
    29464: {
      lat: 32.82082,
      lng: -79.85583,
      city: 'Mount Pleasant',
      state: 'SC',
    },
    29466: {
      lat: 32.87486,
      lng: -79.78785,
      city: 'Mount Pleasant',
      state: 'SC',
    },
    29468: {
      lat: 33.42624,
      lng: -80.06856,
      city: 'Pineville',
      state: 'SC',
    },
    29469: {
      lat: 33.24366,
      lng: -80.13106,
      city: 'Pinopolis',
      state: 'SC',
    },
    29470: {
      lat: 32.81904,
      lng: -80.23855,
      city: 'Ravenel',
      state: 'SC',
    },
    29471: {
      lat: 33.18714,
      lng: -80.66955,
      city: 'Reevesville',
      state: 'SC',
    },
    29472: {
      lat: 33.05379,
      lng: -80.31928,
      city: 'Ridgeville',
      state: 'SC',
    },
    29474: {
      lat: 32.90833,
      lng: -80.52448,
      city: 'Round O',
      state: 'SC',
    },
    29475: {
      lat: 32.96687,
      lng: -80.8189,
      city: 'Ruffin',
      state: 'SC',
    },
    29477: {
      lat: 33.16626,
      lng: -80.57176,
      city: 'Saint George',
      state: 'SC',
    },
    29479: {
      lat: 33.3808,
      lng: -79.89499,
      city: 'Saint Stephen',
      state: 'SC',
    },
    29481: {
      lat: 33.11382,
      lng: -80.80301,
      city: 'Smoaks',
      state: 'SC',
    },
    29482: {
      lat: 32.76845,
      lng: -79.83556,
      city: 'Sullivans Island',
      state: 'SC',
    },
    29483: {
      lat: 33.03126,
      lng: -80.23205,
      city: 'Summerville',
      state: 'SC',
    },
    29485: {
      lat: 32.94398,
      lng: -80.19362,
      city: 'Summerville',
      state: 'SC',
    },
    29486: {
      lat: 33.10744,
      lng: -80.1458,
      city: 'Summerville',
      state: 'SC',
    },
    29487: {
      lat: 32.65691,
      lng: -80.18476,
      city: 'Wadmalaw Island',
      state: 'SC',
    },
    29488: {
      lat: 32.90664,
      lng: -80.67458,
      city: 'Walterboro',
      state: 'SC',
    },
    29492: {
      lat: 32.91657,
      lng: -79.87818,
      city: 'Charleston',
      state: 'SC',
    },
    29493: {
      lat: 33.04536,
      lng: -80.84276,
      city: 'Williams',
      state: 'SC',
    },
    29501: {
      lat: 34.20414,
      lng: -79.82654,
      city: 'Florence',
      state: 'SC',
    },
    29505: {
      lat: 34.12348,
      lng: -79.68974,
      city: 'Florence',
      state: 'SC',
    },
    29506: {
      lat: 34.2103,
      lng: -79.64432,
      city: 'Florence',
      state: 'SC',
    },
    29510: {
      lat: 33.45326,
      lng: -79.61068,
      city: 'Andrews',
      state: 'SC',
    },
    29511: {
      lat: 33.97519,
      lng: -79.11043,
      city: 'Aynor',
      state: 'SC',
    },
    29512: {
      lat: 34.62733,
      lng: -79.71091,
      city: 'Bennettsville',
      state: 'SC',
    },
    29516: {
      lat: 34.44323,
      lng: -79.62414,
      city: 'Blenheim',
      state: 'SC',
    },
    29518: { lat: 33.7892, lng: -79.84851, city: 'Cades', state: 'SC' },
    29519: {
      lat: 34.01622,
      lng: -79.36048,
      city: 'Centenary',
      state: 'SC',
    },
    29520: {
      lat: 34.68083,
      lng: -79.92601,
      city: 'Cheraw',
      state: 'SC',
    },
    29525: { lat: 34.5722, lng: -79.53327, city: 'Clio', state: 'SC' },
    29526: {
      lat: 33.86696,
      lng: -78.9718,
      city: 'Conway',
      state: 'SC',
    },
    29527: {
      lat: 33.78271,
      lng: -79.14715,
      city: 'Conway',
      state: 'SC',
    },
    29530: {
      lat: 33.98984,
      lng: -79.74222,
      city: 'Coward',
      state: 'SC',
    },
    29532: {
      lat: 34.28421,
      lng: -79.85119,
      city: 'Darlington',
      state: 'SC',
    },
    29536: {
      lat: 34.41911,
      lng: -79.37241,
      city: 'Dillon',
      state: 'SC',
    },
    29540: {
      lat: 34.38903,
      lng: -79.85028,
      city: 'Darlington',
      state: 'SC',
    },
    29541: {
      lat: 34.0653,
      lng: -79.74087,
      city: 'Effingham',
      state: 'SC',
    },
    29543: { lat: 34.28759, lng: -79.26591, city: 'Fork', state: 'SC' },
    29544: {
      lat: 34.00067,
      lng: -79.21083,
      city: 'Galivants Ferry',
      state: 'SC',
    },
    29545: {
      lat: 34.16175,
      lng: -78.96635,
      city: 'Green Sea',
      state: 'SC',
    },
    29546: {
      lat: 33.89001,
      lng: -79.35151,
      city: 'Gresham',
      state: 'SC',
    },
    29547: {
      lat: 34.48684,
      lng: -79.33243,
      city: 'Hamer',
      state: 'SC',
    },
    29550: {
      lat: 34.39309,
      lng: -80.07595,
      city: 'Hartsville',
      state: 'SC',
    },
    29554: {
      lat: 33.69879,
      lng: -79.38816,
      city: 'Hemingway',
      state: 'SC',
    },
    29555: {
      lat: 33.83864,
      lng: -79.46191,
      city: 'Johnsonville',
      state: 'SC',
    },
    29556: {
      lat: 33.66849,
      lng: -79.76403,
      city: 'Kingstree',
      state: 'SC',
    },
    29560: {
      lat: 33.83535,
      lng: -79.74747,
      city: 'Lake City',
      state: 'SC',
    },
    29563: {
      lat: 34.35301,
      lng: -79.2044,
      city: 'Lake View',
      state: 'SC',
    },
    29564: { lat: 33.47686, lng: -79.85522, city: 'Lane', state: 'SC' },
    29565: {
      lat: 34.35841,
      lng: -79.50457,
      city: 'Latta',
      state: 'SC',
    },
    29566: {
      lat: 33.8821,
      lng: -78.65906,
      city: 'Little River',
      state: 'SC',
    },
    29567: {
      lat: 34.55325,
      lng: -79.42649,
      city: 'Little Rock',
      state: 'SC',
    },
    29568: {
      lat: 33.90815,
      lng: -78.75455,
      city: 'Longs',
      state: 'SC',
    },
    29569: { lat: 34.0326, lng: -78.90359, city: 'Loris', state: 'SC' },
    29570: {
      lat: 34.67495,
      lng: -79.5624,
      city: 'McColl',
      state: 'SC',
    },
    29571: { lat: 34.138, lng: -79.42205, city: 'Marion', state: 'SC' },
    29572: {
      lat: 33.76798,
      lng: -78.79279,
      city: 'Myrtle Beach',
      state: 'SC',
    },
    29574: {
      lat: 34.17134,
      lng: -79.26695,
      city: 'Mullins',
      state: 'SC',
    },
    29575: {
      lat: 33.628,
      lng: -78.97369,
      city: 'Myrtle Beach',
      state: 'SC',
    },
    29576: {
      lat: 33.56952,
      lng: -79.05076,
      city: 'Murrells Inlet',
      state: 'SC',
    },
    29577: {
      lat: 33.69711,
      lng: -78.90495,
      city: 'Myrtle Beach',
      state: 'SC',
    },
    29579: {
      lat: 33.76052,
      lng: -78.91463,
      city: 'Myrtle Beach',
      state: 'SC',
    },
    29580: {
      lat: 33.65827,
      lng: -79.56164,
      city: 'Nesmith',
      state: 'SC',
    },
    29581: {
      lat: 34.19864,
      lng: -79.11295,
      city: 'Nichols',
      state: 'SC',
    },
    29582: {
      lat: 33.83423,
      lng: -78.66049,
      city: 'North Myrtle Beach',
      state: 'SC',
    },
    29583: {
      lat: 33.9724,
      lng: -79.57045,
      city: 'Pamplico',
      state: 'SC',
    },
    29584: {
      lat: 34.5764,
      lng: -80.05419,
      city: 'Patrick',
      state: 'SC',
    },
    29585: {
      lat: 33.50537,
      lng: -79.13557,
      city: 'Pawleys Island',
      state: 'SC',
    },
    29588: {
      lat: 33.67112,
      lng: -79.02574,
      city: 'Myrtle Beach',
      state: 'SC',
    },
    29589: {
      lat: 34.10037,
      lng: -79.32015,
      city: 'Rains',
      state: 'SC',
    },
    29590: {
      lat: 33.55892,
      lng: -79.84784,
      city: 'Salters',
      state: 'SC',
    },
    29591: {
      lat: 33.93894,
      lng: -79.759,
      city: 'Scranton',
      state: 'SC',
    },
    29592: {
      lat: 34.26941,
      lng: -79.47704,
      city: 'Sellers',
      state: 'SC',
    },
    29593: {
      lat: 34.49462,
      lng: -79.85429,
      city: 'Society Hill',
      state: 'SC',
    },
    29594: {
      lat: 34.64017,
      lng: -79.57728,
      city: 'Tatum',
      state: 'SC',
    },
    29596: {
      lat: 34.74456,
      lng: -79.83978,
      city: 'Wallace',
      state: 'SC',
    },
    29601: {
      lat: 34.84744,
      lng: -82.40257,
      city: 'Greenville',
      state: 'SC',
    },
    29605: {
      lat: 34.77693,
      lng: -82.38064,
      city: 'Greenville',
      state: 'SC',
    },
    29607: {
      lat: 34.80952,
      lng: -82.33193,
      city: 'Greenville',
      state: 'SC',
    },
    29609: {
      lat: 34.91209,
      lng: -82.38928,
      city: 'Greenville',
      state: 'SC',
    },
    29611: {
      lat: 34.83137,
      lng: -82.45965,
      city: 'Greenville',
      state: 'SC',
    },
    29613: {
      lat: 34.9257,
      lng: -82.4384,
      city: 'Greenville',
      state: 'SC',
    },
    29614: {
      lat: 34.87386,
      lng: -82.36302,
      city: 'Greenville',
      state: 'SC',
    },
    29615: {
      lat: 34.85591,
      lng: -82.29553,
      city: 'Greenville',
      state: 'SC',
    },
    29617: {
      lat: 34.91262,
      lng: -82.4666,
      city: 'Greenville',
      state: 'SC',
    },
    29620: {
      lat: 34.1813,
      lng: -82.42804,
      city: 'Abbeville',
      state: 'SC',
    },
    29621: {
      lat: 34.50598,
      lng: -82.60328,
      city: 'Anderson',
      state: 'SC',
    },
    29624: {
      lat: 34.43778,
      lng: -82.62553,
      city: 'Anderson',
      state: 'SC',
    },
    29625: {
      lat: 34.55577,
      lng: -82.76294,
      city: 'Anderson',
      state: 'SC',
    },
    29626: {
      lat: 34.46004,
      lng: -82.76057,
      city: 'Anderson',
      state: 'SC',
    },
    29627: {
      lat: 34.51807,
      lng: -82.47861,
      city: 'Belton',
      state: 'SC',
    },
    29628: {
      lat: 34.09253,
      lng: -82.55821,
      city: 'Calhoun Falls',
      state: 'SC',
    },
    29630: {
      lat: 34.73882,
      lng: -82.79646,
      city: 'Central',
      state: 'SC',
    },
    29631: {
      lat: 34.68133,
      lng: -82.81716,
      city: 'Clemson',
      state: 'SC',
    },
    29634: {
      lat: 34.67517,
      lng: -82.83978,
      city: 'Clemson',
      state: 'SC',
    },
    29635: {
      lat: 35.07754,
      lng: -82.63152,
      city: 'Cleveland',
      state: 'SC',
    },
    29638: {
      lat: 34.36087,
      lng: -82.3346,
      city: 'Donalds',
      state: 'SC',
    },
    29639: {
      lat: 34.31402,
      lng: -82.4205,
      city: 'Due West',
      state: 'SC',
    },
    29640: {
      lat: 34.88706,
      lng: -82.57725,
      city: 'Easley',
      state: 'SC',
    },
    29642: {
      lat: 34.77487,
      lng: -82.56538,
      city: 'Easley',
      state: 'SC',
    },
    29643: {
      lat: 34.50506,
      lng: -82.97441,
      city: 'Fair Play',
      state: 'SC',
    },
    29644: {
      lat: 34.66864,
      lng: -82.19714,
      city: 'Fountain Inn',
      state: 'SC',
    },
    29645: {
      lat: 34.59453,
      lng: -82.12375,
      city: 'Gray Court',
      state: 'SC',
    },
    29646: {
      lat: 34.13995,
      lng: -82.15032,
      city: 'Greenwood',
      state: 'SC',
    },
    29649: {
      lat: 34.24354,
      lng: -82.13825,
      city: 'Greenwood',
      state: 'SC',
    },
    29650: {
      lat: 34.89805,
      lng: -82.25826,
      city: 'Greer',
      state: 'SC',
    },
    29651: {
      lat: 34.94653,
      lng: -82.22649,
      city: 'Greer',
      state: 'SC',
    },
    29653: {
      lat: 34.30028,
      lng: -82.23173,
      city: 'Hodges',
      state: 'SC',
    },
    29654: {
      lat: 34.46621,
      lng: -82.36354,
      city: 'Honea Path',
      state: 'SC',
    },
    29655: { lat: 34.267, lng: -82.64032, city: 'Iva', state: 'SC' },
    29657: {
      lat: 34.76713,
      lng: -82.68941,
      city: 'Liberty',
      state: 'SC',
    },
    29658: {
      lat: 34.76191,
      lng: -83.29858,
      city: 'Long Creek',
      state: 'SC',
    },
    29659: {
      lat: 34.21167,
      lng: -82.64535,
      city: 'Lowndesville',
      state: 'SC',
    },
    29661: {
      lat: 35.06706,
      lng: -82.53852,
      city: 'Marietta',
      state: 'SC',
    },
    29662: {
      lat: 34.77837,
      lng: -82.30557,
      city: 'Mauldin',
      state: 'SC',
    },
    29664: {
      lat: 34.86393,
      lng: -83.15991,
      city: 'Mountain Rest',
      state: 'SC',
    },
    29665: {
      lat: 34.72399,
      lng: -82.91413,
      city: 'Newry',
      state: 'SC',
    },
    29666: {
      lat: 34.12345,
      lng: -81.99121,
      city: 'Ninety Six',
      state: 'SC',
    },
    29667: {
      lat: 34.76446,
      lng: -82.75655,
      city: 'Norris',
      state: 'SC',
    },
    29669: {
      lat: 34.6405,
      lng: -82.41495,
      city: 'Pelzer',
      state: 'SC',
    },
    29670: {
      lat: 34.64191,
      lng: -82.72455,
      city: 'Pendleton',
      state: 'SC',
    },
    29671: {
      lat: 34.94071,
      lng: -82.72057,
      city: 'Pickens',
      state: 'SC',
    },
    29672: {
      lat: 34.75036,
      lng: -82.94257,
      city: 'Seneca',
      state: 'SC',
    },
    29673: {
      lat: 34.71901,
      lng: -82.455,
      city: 'Piedmont',
      state: 'SC',
    },
    29676: {
      lat: 34.93326,
      lng: -82.96006,
      city: 'Salem',
      state: 'SC',
    },
    29678: {
      lat: 34.63365,
      lng: -82.93991,
      city: 'Seneca',
      state: 'SC',
    },
    29680: {
      lat: 34.69011,
      lng: -82.29073,
      city: 'Simpsonville',
      state: 'SC',
    },
    29681: {
      lat: 34.76988,
      lng: -82.22218,
      city: 'Simpsonville',
      state: 'SC',
    },
    29682: {
      lat: 34.83295,
      lng: -82.84701,
      city: 'Six Mile',
      state: 'SC',
    },
    29683: {
      lat: 35.03032,
      lng: -82.49306,
      city: 'Slater',
      state: 'SC',
    },
    29684: { lat: 34.3791, lng: -82.72722, city: 'Starr', state: 'SC' },
    29685: {
      lat: 34.98297,
      lng: -82.84727,
      city: 'Sunset',
      state: 'SC',
    },
    29686: {
      lat: 34.92858,
      lng: -83.04541,
      city: 'Tamassee',
      state: 'SC',
    },
    29687: {
      lat: 34.9855,
      lng: -82.32833,
      city: 'Taylors',
      state: 'SC',
    },
    29689: {
      lat: 34.53624,
      lng: -82.87658,
      city: 'Townville',
      state: 'SC',
    },
    29690: {
      lat: 35.06008,
      lng: -82.42136,
      city: 'Travelers Rest',
      state: 'SC',
    },
    29691: {
      lat: 34.78547,
      lng: -83.07669,
      city: 'Walhalla',
      state: 'SC',
    },
    29692: {
      lat: 34.42142,
      lng: -82.22208,
      city: 'Ware Shoals',
      state: 'SC',
    },
    29693: {
      lat: 34.65641,
      lng: -83.15284,
      city: 'Westminster',
      state: 'SC',
    },
    29696: {
      lat: 34.77458,
      lng: -83.00771,
      city: 'West Union',
      state: 'SC',
    },
    29697: {
      lat: 34.62887,
      lng: -82.54786,
      city: 'Williamston',
      state: 'SC',
    },
    29702: {
      lat: 35.11674,
      lng: -81.47884,
      city: 'Blacksburg',
      state: 'SC',
    },
    29704: {
      lat: 34.82293,
      lng: -80.91592,
      city: 'Catawba',
      state: 'SC',
    },
    29706: {
      lat: 34.71773,
      lng: -81.23055,
      city: 'Chester',
      state: 'SC',
    },
    29707: {
      lat: 34.98148,
      lng: -80.85669,
      city: 'Fort Mill',
      state: 'SC',
    },
    29708: {
      lat: 35.04655,
      lng: -80.98604,
      city: 'Fort Mill',
      state: 'SC',
    },
    29709: {
      lat: 34.71882,
      lng: -80.09752,
      city: 'Chesterfield',
      state: 'SC',
    },
    29710: {
      lat: 35.10598,
      lng: -81.22209,
      city: 'Clover',
      state: 'SC',
    },
    29712: {
      lat: 34.79439,
      lng: -80.98398,
      city: 'Edgemoor',
      state: 'SC',
    },
    29714: {
      lat: 34.71171,
      lng: -80.9145,
      city: 'Fort Lawn',
      state: 'SC',
    },
    29715: {
      lat: 35.01028,
      lng: -80.92752,
      city: 'Fort Mill',
      state: 'SC',
    },
    29717: {
      lat: 34.95119,
      lng: -81.44304,
      city: 'Hickory Grove',
      state: 'SC',
    },
    29718: {
      lat: 34.6291,
      lng: -80.35469,
      city: 'Jefferson',
      state: 'SC',
    },
    29720: {
      lat: 34.74265,
      lng: -80.73684,
      city: 'Lancaster',
      state: 'SC',
    },
    29724: {
      lat: 34.77503,
      lng: -81.01248,
      city: 'Lando',
      state: 'SC',
    },
    29726: {
      lat: 34.85738,
      lng: -81.23223,
      city: 'McConnells',
      state: 'SC',
    },
    29727: {
      lat: 34.71566,
      lng: -80.2684,
      city: 'Mount Croghan',
      state: 'SC',
    },
    29728: {
      lat: 34.76831,
      lng: -80.40445,
      city: 'Pageland',
      state: 'SC',
    },
    29729: {
      lat: 34.67716,
      lng: -81.00528,
      city: 'Richburg',
      state: 'SC',
    },
    29730: {
      lat: 34.8878,
      lng: -81.02116,
      city: 'Rock Hill',
      state: 'SC',
    },
    29732: {
      lat: 34.96979,
      lng: -81.07963,
      city: 'Rock Hill',
      state: 'SC',
    },
    29733: {
      lat: 34.93945,
      lng: -81.03173,
      city: 'Rock Hill',
      state: 'SC',
    },
    29741: { lat: 34.72511, lng: -80.20138, city: 'Ruby', state: 'SC' },
    29742: {
      lat: 34.86939,
      lng: -81.38155,
      city: 'Sharon',
      state: 'SC',
    },
    29743: {
      lat: 35.02865,
      lng: -81.40772,
      city: 'Smyrna',
      state: 'SC',
    },
    29745: { lat: 34.99549, lng: -81.21573, city: 'York', state: 'SC' },
    29801: {
      lat: 33.58675,
      lng: -81.69701,
      city: 'Aiken',
      state: 'SC',
    },
    29803: {
      lat: 33.45889,
      lng: -81.70076,
      city: 'Aiken',
      state: 'SC',
    },
    29805: { lat: 33.6495, lng: -81.61044, city: 'Aiken', state: 'SC' },
    29809: {
      lat: 33.41653,
      lng: -81.6889,
      city: 'New Ellenton',
      state: 'SC',
    },
    29810: {
      lat: 32.99358,
      lng: -81.3798,
      city: 'Allendale',
      state: 'SC',
    },
    29812: {
      lat: 33.21185,
      lng: -81.37911,
      city: 'Barnwell',
      state: 'SC',
    },
    29816: { lat: 33.50105, lng: -81.87164, city: 'Bath', state: 'SC' },
    29817: {
      lat: 33.36989,
      lng: -81.27096,
      city: 'Blackville',
      state: 'SC',
    },
    29819: {
      lat: 34.04764,
      lng: -82.22632,
      city: 'Bradley',
      state: 'SC',
    },
    29821: {
      lat: 33.64242,
      lng: -82.11667,
      city: 'Clarks Hill',
      state: 'SC',
    },
    29824: {
      lat: 33.81647,
      lng: -81.99666,
      city: 'Edgefield',
      state: 'SC',
    },
    29826: { lat: 33.383, lng: -81.36697, city: 'Elko', state: 'SC' },
    29827: {
      lat: 32.96951,
      lng: -81.24542,
      city: 'Fairfax',
      state: 'SC',
    },
    29828: {
      lat: 33.52299,
      lng: -81.82891,
      city: 'Gloverville',
      state: 'SC',
    },
    29829: {
      lat: 33.57715,
      lng: -81.85537,
      city: 'Graniteville',
      state: 'SC',
    },
    29831: {
      lat: 33.33657,
      lng: -81.84293,
      city: 'Jackson',
      state: 'SC',
    },
    29832: {
      lat: 33.83922,
      lng: -81.80656,
      city: 'Johnston',
      state: 'SC',
    },
    29834: {
      lat: 33.5178,
      lng: -81.84216,
      city: 'Langley',
      state: 'SC',
    },
    29835: {
      lat: 33.91779,
      lng: -82.29559,
      city: 'McCormick',
      state: 'SC',
    },
    29836: {
      lat: 33.09046,
      lng: -81.50026,
      city: 'Martin',
      state: 'SC',
    },
    29838: { lat: 33.73452, lng: -82.1667, city: 'Modoc', state: 'SC' },
    29840: {
      lat: 33.99797,
      lng: -82.52749,
      city: 'Mount Carmel',
      state: 'SC',
    },
    29841: {
      lat: 33.52508,
      lng: -81.93954,
      city: 'North Augusta',
      state: 'SC',
    },
    29842: {
      lat: 33.44559,
      lng: -81.8645,
      city: 'Beech Island',
      state: 'SC',
    },
    29843: { lat: 33.18385, lng: -81.18218, city: 'Olar', state: 'SC' },
    29844: {
      lat: 33.78528,
      lng: -82.2142,
      city: 'Parksville',
      state: 'SC',
    },
    29845: {
      lat: 33.8231,
      lng: -82.20788,
      city: 'Plum Branch',
      state: 'SC',
    },
    29847: {
      lat: 33.69941,
      lng: -81.84878,
      city: 'Trenton',
      state: 'SC',
    },
    29848: { lat: 33.99616, lng: -82.18304, city: 'Troy', state: 'SC' },
    29849: {
      lat: 33.10168,
      lng: -81.23641,
      city: 'Ulmer',
      state: 'SC',
    },
    29851: {
      lat: 33.51098,
      lng: -81.81597,
      city: 'Warrenville',
      state: 'SC',
    },
    29853: {
      lat: 33.39871,
      lng: -81.43217,
      city: 'Williston',
      state: 'SC',
    },
    29856: {
      lat: 33.48365,
      lng: -81.51276,
      city: 'Windsor',
      state: 'SC',
    },
    29860: {
      lat: 33.60667,
      lng: -81.97776,
      city: 'North Augusta',
      state: 'SC',
    },
    29899: {
      lat: 33.92801,
      lng: -82.25064,
      city: 'McCormick',
      state: 'SC',
    },
    29902: {
      lat: 32.37426,
      lng: -80.69438,
      city: 'Beaufort',
      state: 'SC',
    },
    29904: {
      lat: 32.47899,
      lng: -80.72198,
      city: 'Beaufort',
      state: 'SC',
    },
    29905: {
      lat: 32.35383,
      lng: -80.69537,
      city: 'Parris Island',
      state: 'SC',
    },
    29906: {
      lat: 32.44521,
      lng: -80.75159,
      city: 'Beaufort',
      state: 'SC',
    },
    29907: {
      lat: 32.45326,
      lng: -80.60926,
      city: 'Beaufort',
      state: 'SC',
    },
    29909: {
      lat: 32.34042,
      lng: -80.85465,
      city: 'Okatie',
      state: 'SC',
    },
    29910: {
      lat: 32.2204,
      lng: -80.88277,
      city: 'Bluffton',
      state: 'SC',
    },
    29911: {
      lat: 32.9327,
      lng: -81.14536,
      city: 'Brunson',
      state: 'SC',
    },
    29912: {
      lat: 32.58262,
      lng: -80.93102,
      city: 'Coosawhatchie',
      state: 'SC',
    },
    29915: {
      lat: 32.11464,
      lng: -80.86892,
      city: 'Daufuskie Island',
      state: 'SC',
    },
    29916: {
      lat: 32.72863,
      lng: -80.95708,
      city: 'Early Branch',
      state: 'SC',
    },
    29918: {
      lat: 32.74461,
      lng: -81.24899,
      city: 'Estill',
      state: 'SC',
    },
    29920: {
      lat: 32.36441,
      lng: -80.54946,
      city: 'Saint Helena Island',
      state: 'SC',
    },
    29921: {
      lat: 32.68664,
      lng: -81.18011,
      city: 'Furman',
      state: 'SC',
    },
    29922: {
      lat: 32.59632,
      lng: -81.27407,
      city: 'Garnett',
      state: 'SC',
    },
    29923: {
      lat: 32.84821,
      lng: -81.23856,
      city: 'Gifford',
      state: 'SC',
    },
    29924: {
      lat: 32.87737,
      lng: -81.10749,
      city: 'Hampton',
      state: 'SC',
    },
    29926: {
      lat: 32.22936,
      lng: -80.74931,
      city: 'Hilton Head Island',
      state: 'SC',
    },
    29927: {
      lat: 32.24343,
      lng: -81.04971,
      city: 'Hardeeville',
      state: 'SC',
    },
    29928: {
      lat: 32.16214,
      lng: -80.76274,
      city: 'Hilton Head Island',
      state: 'SC',
    },
    29929: {
      lat: 32.93805,
      lng: -80.9434,
      city: 'Islandton',
      state: 'SC',
    },
    29932: {
      lat: 32.82529,
      lng: -81.34853,
      city: 'Luray',
      state: 'SC',
    },
    29934: {
      lat: 32.59651,
      lng: -81.11245,
      city: 'Pineland',
      state: 'SC',
    },
    29935: {
      lat: 32.38277,
      lng: -80.6963,
      city: 'Port Royal',
      state: 'SC',
    },
    29936: {
      lat: 32.48475,
      lng: -80.96766,
      city: 'Ridgeland',
      state: 'SC',
    },
    29939: {
      lat: 32.68127,
      lng: -81.24538,
      city: 'Scotia',
      state: 'SC',
    },
    29940: {
      lat: 32.5524,
      lng: -80.67511,
      city: 'Seabrook',
      state: 'SC',
    },
    29941: {
      lat: 32.54357,
      lng: -80.81509,
      city: 'Sheldon',
      state: 'SC',
    },
    29943: {
      lat: 32.4821,
      lng: -81.14952,
      city: 'Tillman',
      state: 'SC',
    },
    29944: {
      lat: 32.79129,
      lng: -81.03409,
      city: 'Varnville',
      state: 'SC',
    },
    29945: {
      lat: 32.68694,
      lng: -80.80912,
      city: 'Yemassee',
      state: 'SC',
    },
    30002: {
      lat: 33.77309,
      lng: -84.26093,
      city: 'Avondale Estates',
      state: 'GA',
    },
    30004: {
      lat: 34.14352,
      lng: -84.29926,
      city: 'Alpharetta',
      state: 'GA',
    },
    30005: {
      lat: 34.08582,
      lng: -84.22158,
      city: 'Alpharetta',
      state: 'GA',
    },
    30008: {
      lat: 33.8966,
      lng: -84.58847,
      city: 'Marietta',
      state: 'GA',
    },
    30009: {
      lat: 34.07711,
      lng: -84.3029,
      city: 'Alpharetta',
      state: 'GA',
    },
    30011: {
      lat: 34.02281,
      lng: -83.8364,
      city: 'Auburn',
      state: 'GA',
    },
    30012: {
      lat: 33.71739,
      lng: -83.99913,
      city: 'Conyers',
      state: 'GA',
    },
    30013: {
      lat: 33.643,
      lng: -83.97147,
      city: 'Conyers',
      state: 'GA',
    },
    30014: {
      lat: 33.58109,
      lng: -83.8191,
      city: 'Covington',
      state: 'GA',
    },
    30016: {
      lat: 33.51836,
      lng: -83.93073,
      city: 'Covington',
      state: 'GA',
    },
    30017: {
      lat: 33.88939,
      lng: -83.96348,
      city: 'Grayson',
      state: 'GA',
    },
    30018: {
      lat: 33.71737,
      lng: -83.80034,
      city: 'Jersey',
      state: 'GA',
    },
    30019: {
      lat: 33.97642,
      lng: -83.88252,
      city: 'Dacula',
      state: 'GA',
    },
    30021: {
      lat: 33.80803,
      lng: -84.23909,
      city: 'Clarkston',
      state: 'GA',
    },
    30022: {
      lat: 34.02745,
      lng: -84.24643,
      city: 'Alpharetta',
      state: 'GA',
    },
    30024: {
      lat: 34.06341,
      lng: -84.09116,
      city: 'Suwanee',
      state: 'GA',
    },
    30025: {
      lat: 33.65689,
      lng: -83.69869,
      city: 'Social Circle',
      state: 'GA',
    },
    30028: {
      lat: 34.28928,
      lng: -84.17985,
      city: 'Cumming',
      state: 'GA',
    },
    30030: {
      lat: 33.7721,
      lng: -84.29135,
      city: 'Decatur',
      state: 'GA',
    },
    30032: {
      lat: 33.73987,
      lng: -84.2641,
      city: 'Decatur',
      state: 'GA',
    },
    30033: {
      lat: 33.81342,
      lng: -84.28355,
      city: 'Decatur',
      state: 'GA',
    },
    30034: {
      lat: 33.69198,
      lng: -84.24835,
      city: 'Decatur',
      state: 'GA',
    },
    30035: {
      lat: 33.72563,
      lng: -84.20541,
      city: 'Decatur',
      state: 'GA',
    },
    30038: {
      lat: 33.67078,
      lng: -84.14209,
      city: 'Lithonia',
      state: 'GA',
    },
    30039: {
      lat: 33.79871,
      lng: -84.03414,
      city: 'Snellville',
      state: 'GA',
    },
    30040: {
      lat: 34.21622,
      lng: -84.17978,
      city: 'Cumming',
      state: 'GA',
    },
    30041: {
      lat: 34.20166,
      lng: -84.09371,
      city: 'Cumming',
      state: 'GA',
    },
    30043: {
      lat: 34.00353,
      lng: -84.00703,
      city: 'Lawrenceville',
      state: 'GA',
    },
    30044: {
      lat: 33.92207,
      lng: -84.06924,
      city: 'Lawrenceville',
      state: 'GA',
    },
    30045: {
      lat: 33.93046,
      lng: -83.93019,
      city: 'Lawrenceville',
      state: 'GA',
    },
    30046: {
      lat: 33.9497,
      lng: -83.99389,
      city: 'Lawrenceville',
      state: 'GA',
    },
    30047: {
      lat: 33.87104,
      lng: -84.11304,
      city: 'Lilburn',
      state: 'GA',
    },
    30052: {
      lat: 33.81654,
      lng: -83.89538,
      city: 'Loganville',
      state: 'GA',
    },
    30054: {
      lat: 33.67651,
      lng: -83.87558,
      city: 'Oxford',
      state: 'GA',
    },
    30055: {
      lat: 33.49055,
      lng: -83.74224,
      city: 'Mansfield',
      state: 'GA',
    },
    30056: {
      lat: 33.49496,
      lng: -83.65068,
      city: 'Newborn',
      state: 'GA',
    },
    30058: {
      lat: 33.7377,
      lng: -84.10779,
      city: 'Lithonia',
      state: 'GA',
    },
    30060: {
      lat: 33.9209,
      lng: -84.543,
      city: 'Marietta',
      state: 'GA',
    },
    30062: {
      lat: 33.99977,
      lng: -84.46899,
      city: 'Marietta',
      state: 'GA',
    },
    30064: {
      lat: 33.93809,
      lng: -84.61691,
      city: 'Marietta',
      state: 'GA',
    },
    30066: {
      lat: 34.034,
      lng: -84.50798,
      city: 'Marietta',
      state: 'GA',
    },
    30067: {
      lat: 33.93286,
      lng: -84.46187,
      city: 'Marietta',
      state: 'GA',
    },
    30068: {
      lat: 33.96887,
      lng: -84.43273,
      city: 'Marietta',
      state: 'GA',
    },
    30070: {
      lat: 33.5733,
      lng: -83.89546,
      city: 'Porterdale',
      state: 'GA',
    },
    30071: {
      lat: 33.93977,
      lng: -84.20561,
      city: 'Norcross',
      state: 'GA',
    },
    30072: {
      lat: 33.79115,
      lng: -84.20598,
      city: 'Pine Lake',
      state: 'GA',
    },
    30075: {
      lat: 34.05282,
      lng: -84.38739,
      city: 'Roswell',
      state: 'GA',
    },
    30076: {
      lat: 34.02777,
      lng: -84.31723,
      city: 'Roswell',
      state: 'GA',
    },
    30078: {
      lat: 33.86118,
      lng: -84.01795,
      city: 'Snellville',
      state: 'GA',
    },
    30079: {
      lat: 33.79173,
      lng: -84.25839,
      city: 'Scottdale',
      state: 'GA',
    },
    30080: {
      lat: 33.87864,
      lng: -84.5053,
      city: 'Smyrna',
      state: 'GA',
    },
    30082: {
      lat: 33.85517,
      lng: -84.5373,
      city: 'Smyrna',
      state: 'GA',
    },
    30083: {
      lat: 33.79641,
      lng: -84.19231,
      city: 'Stone Mountain',
      state: 'GA',
    },
    30084: {
      lat: 33.85457,
      lng: -84.21657,
      city: 'Tucker',
      state: 'GA',
    },
    30087: {
      lat: 33.81087,
      lng: -84.13135,
      city: 'Stone Mountain',
      state: 'GA',
    },
    30088: {
      lat: 33.75959,
      lng: -84.17918,
      city: 'Stone Mountain',
      state: 'GA',
    },
    30090: {
      lat: 33.953,
      lng: -84.54837,
      city: 'Marietta',
      state: 'GA',
    },
    30092: {
      lat: 33.97145,
      lng: -84.23537,
      city: 'Peachtree Corners',
      state: 'GA',
    },
    30093: {
      lat: 33.90879,
      lng: -84.17839,
      city: 'Norcross',
      state: 'GA',
    },
    30094: {
      lat: 33.61243,
      lng: -84.0555,
      city: 'Conyers',
      state: 'GA',
    },
    30096: {
      lat: 33.97691,
      lng: -84.14791,
      city: 'Duluth',
      state: 'GA',
    },
    30097: {
      lat: 34.0271,
      lng: -84.14702,
      city: 'Duluth',
      state: 'GA',
    },
    30101: {
      lat: 34.04355,
      lng: -84.71244,
      city: 'Acworth',
      state: 'GA',
    },
    30102: {
      lat: 34.10857,
      lng: -84.64622,
      city: 'Acworth',
      state: 'GA',
    },
    30103: {
      lat: 34.36457,
      lng: -84.92014,
      city: 'Adairsville',
      state: 'GA',
    },
    30104: {
      lat: 34.0879,
      lng: -85.06982,
      city: 'Aragon',
      state: 'GA',
    },
    30105: {
      lat: 34.47012,
      lng: -85.15532,
      city: 'Armuchee',
      state: 'GA',
    },
    30106: {
      lat: 33.83476,
      lng: -84.62947,
      city: 'Austell',
      state: 'GA',
    },
    30107: {
      lat: 34.3352,
      lng: -84.34744,
      city: 'Ball Ground',
      state: 'GA',
    },
    30108: {
      lat: 33.53172,
      lng: -85.26242,
      city: 'Bowdon',
      state: 'GA',
    },
    30110: { lat: 33.74071, lng: -85.137, city: 'Bremen', state: 'GA' },
    30111: {
      lat: 33.8318,
      lng: -84.65499,
      city: 'Clarkdale',
      state: 'GA',
    },
    30113: {
      lat: 33.8531,
      lng: -85.20967,
      city: 'Buchanan',
      state: 'GA',
    },
    30114: {
      lat: 34.24433,
      lng: -84.5393,
      city: 'Canton',
      state: 'GA',
    },
    30115: {
      lat: 34.21296,
      lng: -84.40138,
      city: 'Canton',
      state: 'GA',
    },
    30116: {
      lat: 33.55224,
      lng: -85.01298,
      city: 'Carrollton',
      state: 'GA',
    },
    30117: {
      lat: 33.58113,
      lng: -85.13197,
      city: 'Carrollton',
      state: 'GA',
    },
    30118: {
      lat: 33.57288,
      lng: -85.09938,
      city: 'Carrollton',
      state: 'GA',
    },
    30120: {
      lat: 34.16639,
      lng: -84.85597,
      city: 'Cartersville',
      state: 'GA',
    },
    30121: {
      lat: 34.21073,
      lng: -84.77802,
      city: 'Cartersville',
      state: 'GA',
    },
    30122: {
      lat: 33.76329,
      lng: -84.6386,
      city: 'Lithia Springs',
      state: 'GA',
    },
    30124: {
      lat: 34.12872,
      lng: -85.35589,
      city: 'Cave Spring',
      state: 'GA',
    },
    30125: {
      lat: 34.00067,
      lng: -85.27331,
      city: 'Cedartown',
      state: 'GA',
    },
    30126: {
      lat: 33.81588,
      lng: -84.55171,
      city: 'Mableton',
      state: 'GA',
    },
    30127: {
      lat: 33.87647,
      lng: -84.69648,
      city: 'Powder Springs',
      state: 'GA',
    },
    30132: {
      lat: 33.99042,
      lng: -84.85279,
      city: 'Dallas',
      state: 'GA',
    },
    30134: {
      lat: 33.77436,
      lng: -84.78241,
      city: 'Douglasville',
      state: 'GA',
    },
    30135: {
      lat: 33.67285,
      lng: -84.74523,
      city: 'Douglasville',
      state: 'GA',
    },
    30137: {
      lat: 34.11657,
      lng: -84.7657,
      city: 'Emerson',
      state: 'GA',
    },
    30139: {
      lat: 34.43346,
      lng: -84.70575,
      city: 'Fairmount',
      state: 'GA',
    },
    30141: {
      lat: 33.86244,
      lng: -84.77521,
      city: 'Hiram',
      state: 'GA',
    },
    30143: {
      lat: 34.46198,
      lng: -84.42385,
      city: 'Jasper',
      state: 'GA',
    },
    30144: {
      lat: 34.03672,
      lng: -84.59174,
      city: 'Kennesaw',
      state: 'GA',
    },
    30145: {
      lat: 34.24177,
      lng: -84.98076,
      city: 'Kingston',
      state: 'GA',
    },
    30147: {
      lat: 34.13751,
      lng: -85.21877,
      city: 'Lindale',
      state: 'GA',
    },
    30148: {
      lat: 34.46009,
      lng: -84.2648,
      city: 'Marble Hill',
      state: 'GA',
    },
    30149: {
      lat: 34.31107,
      lng: -85.21691,
      city: 'Mount Berry',
      state: 'GA',
    },
    30152: {
      lat: 33.99435,
      lng: -84.6474,
      city: 'Kennesaw',
      state: 'GA',
    },
    30153: {
      lat: 33.96678,
      lng: -85.05425,
      city: 'Rockmart',
      state: 'GA',
    },
    30157: {
      lat: 33.88641,
      lng: -84.8731,
      city: 'Dallas',
      state: 'GA',
    },
    30161: { lat: 34.23758, lng: -85.17319, city: 'Rome', state: 'GA' },
    30165: { lat: 34.30279, lng: -85.27233, city: 'Rome', state: 'GA' },
    30168: {
      lat: 33.78354,
      lng: -84.58765,
      city: 'Austell',
      state: 'GA',
    },
    30170: {
      lat: 33.43065,
      lng: -85.16821,
      city: 'Roopville',
      state: 'GA',
    },
    30171: {
      lat: 34.36122,
      lng: -84.73584,
      city: 'Rydal',
      state: 'GA',
    },
    30173: {
      lat: 34.13541,
      lng: -85.14217,
      city: 'Silver Creek',
      state: 'GA',
    },
    30175: {
      lat: 34.54239,
      lng: -84.52793,
      city: 'Talking Rock',
      state: 'GA',
    },
    30176: {
      lat: 33.76972,
      lng: -85.30398,
      city: 'Tallapoosa',
      state: 'GA',
    },
    30177: { lat: 34.40705, lng: -84.37401, city: 'Tate', state: 'GA' },
    30178: {
      lat: 34.09709,
      lng: -84.97051,
      city: 'Taylorsville',
      state: 'GA',
    },
    30179: {
      lat: 33.78088,
      lng: -85.01961,
      city: 'Temple',
      state: 'GA',
    },
    30180: {
      lat: 33.72018,
      lng: -84.91956,
      city: 'Villa Rica',
      state: 'GA',
    },
    30182: { lat: 33.65603, lng: -85.24628, city: 'Waco', state: 'GA' },
    30183: {
      lat: 34.33854,
      lng: -84.59762,
      city: 'Waleska',
      state: 'GA',
    },
    30184: {
      lat: 34.26042,
      lng: -84.71603,
      city: 'White',
      state: 'GA',
    },
    30185: {
      lat: 33.51975,
      lng: -84.90879,
      city: 'Whitesburg',
      state: 'GA',
    },
    30187: {
      lat: 33.65736,
      lng: -84.85454,
      city: 'Winston',
      state: 'GA',
    },
    30188: {
      lat: 34.11873,
      lng: -84.45526,
      city: 'Woodstock',
      state: 'GA',
    },
    30189: {
      lat: 34.12835,
      lng: -84.57148,
      city: 'Woodstock',
      state: 'GA',
    },
    30204: {
      lat: 33.05844,
      lng: -84.12343,
      city: 'Barnesville',
      state: 'GA',
    },
    30205: {
      lat: 33.26911,
      lng: -84.46634,
      city: 'Brooks',
      state: 'GA',
    },
    30206: {
      lat: 33.09671,
      lng: -84.46303,
      city: 'Concord',
      state: 'GA',
    },
    30213: {
      lat: 33.59113,
      lng: -84.6333,
      city: 'Fairburn',
      state: 'GA',
    },
    30214: {
      lat: 33.49222,
      lng: -84.48697,
      city: 'Fayetteville',
      state: 'GA',
    },
    30215: {
      lat: 33.3888,
      lng: -84.45709,
      city: 'Fayetteville',
      state: 'GA',
    },
    30216: {
      lat: 33.23951,
      lng: -83.88162,
      city: 'Flovilla',
      state: 'GA',
    },
    30217: {
      lat: 33.28671,
      lng: -85.13309,
      city: 'Franklin',
      state: 'GA',
    },
    30218: { lat: 33.12706, lng: -84.58093, city: 'Gay', state: 'GA' },
    30220: {
      lat: 33.22849,
      lng: -84.82463,
      city: 'Grantville',
      state: 'GA',
    },
    30222: {
      lat: 33.04113,
      lng: -84.74294,
      city: 'Greenville',
      state: 'GA',
    },
    30223: {
      lat: 33.28635,
      lng: -84.28012,
      city: 'Griffin',
      state: 'GA',
    },
    30224: {
      lat: 33.20411,
      lng: -84.23587,
      city: 'Griffin',
      state: 'GA',
    },
    30228: {
      lat: 33.40374,
      lng: -84.30575,
      city: 'Hampton',
      state: 'GA',
    },
    30229: {
      lat: 33.22791,
      lng: -84.57033,
      city: 'Haralson',
      state: 'GA',
    },
    30230: {
      lat: 33.16699,
      lng: -84.92071,
      city: 'Hogansville',
      state: 'GA',
    },
    30233: {
      lat: 33.29492,
      lng: -83.97501,
      city: 'Jackson',
      state: 'GA',
    },
    30234: {
      lat: 33.33001,
      lng: -84.02617,
      city: 'Jenkinsburg',
      state: 'GA',
    },
    30236: {
      lat: 33.52157,
      lng: -84.32405,
      city: 'Jonesboro',
      state: 'GA',
    },
    30238: {
      lat: 33.4949,
      lng: -84.38181,
      city: 'Jonesboro',
      state: 'GA',
    },
    30240: {
      lat: 33.03757,
      lng: -85.12222,
      city: 'Lagrange',
      state: 'GA',
    },
    30241: {
      lat: 33.02115,
      lng: -84.95285,
      city: 'Lagrange',
      state: 'GA',
    },
    30248: {
      lat: 33.34975,
      lng: -84.10838,
      city: 'Locust Grove',
      state: 'GA',
    },
    30250: {
      lat: 33.43473,
      lng: -84.30603,
      city: 'Lovejoy',
      state: 'GA',
    },
    30251: {
      lat: 33.18871,
      lng: -84.69983,
      city: 'Luthersville',
      state: 'GA',
    },
    30252: {
      lat: 33.47206,
      lng: -84.06416,
      city: 'Mcdonough',
      state: 'GA',
    },
    30253: {
      lat: 33.45028,
      lng: -84.18579,
      city: 'Mcdonough',
      state: 'GA',
    },
    30256: {
      lat: 33.01654,
      lng: -84.31515,
      city: 'Meansville',
      state: 'GA',
    },
    30257: {
      lat: 33.14218,
      lng: -84.18606,
      city: 'Milner',
      state: 'GA',
    },
    30258: {
      lat: 32.99278,
      lng: -84.47024,
      city: 'Molena',
      state: 'GA',
    },
    30259: {
      lat: 33.26522,
      lng: -84.74073,
      city: 'Moreland',
      state: 'GA',
    },
    30260: {
      lat: 33.5862,
      lng: -84.33046,
      city: 'Morrow',
      state: 'GA',
    },
    30263: {
      lat: 33.38852,
      lng: -84.85911,
      city: 'Newnan',
      state: 'GA',
    },
    30265: {
      lat: 33.41649,
      lng: -84.70749,
      city: 'Newnan',
      state: 'GA',
    },
    30268: {
      lat: 33.54357,
      lng: -84.72262,
      city: 'Palmetto',
      state: 'GA',
    },
    30269: {
      lat: 33.39375,
      lng: -84.56974,
      city: 'Peachtree City',
      state: 'GA',
    },
    30272: {
      lat: 33.62313,
      lng: -84.50175,
      city: 'Red Oak',
      state: 'GA',
    },
    30273: { lat: 33.58365, lng: -84.27208, city: 'Rex', state: 'GA' },
    30274: {
      lat: 33.55583,
      lng: -84.40091,
      city: 'Riverdale',
      state: 'GA',
    },
    30275: {
      lat: 33.43066,
      lng: -84.86798,
      city: 'Sargent',
      state: 'GA',
    },
    30276: { lat: 33.26552, lng: -84.582, city: 'Senoia', state: 'GA' },
    30277: {
      lat: 33.37962,
      lng: -84.64767,
      city: 'Sharpsburg',
      state: 'GA',
    },
    30281: {
      lat: 33.56338,
      lng: -84.1961,
      city: 'Stockbridge',
      state: 'GA',
    },
    30284: {
      lat: 33.34032,
      lng: -84.28858,
      city: 'Sunny Side',
      state: 'GA',
    },
    30285: {
      lat: 32.98647,
      lng: -84.25255,
      city: 'The Rock',
      state: 'GA',
    },
    30286: {
      lat: 32.88078,
      lng: -84.33334,
      city: 'Thomaston',
      state: 'GA',
    },
    30288: {
      lat: 33.65502,
      lng: -84.32813,
      city: 'Conley',
      state: 'GA',
    },
    30289: {
      lat: 33.32358,
      lng: -84.63368,
      city: 'Turin',
      state: 'GA',
    },
    30290: {
      lat: 33.47675,
      lng: -84.58804,
      city: 'Tyrone',
      state: 'GA',
    },
    30291: {
      lat: 33.57569,
      lng: -84.5408,
      city: 'Union City',
      state: 'GA',
    },
    30292: {
      lat: 33.17207,
      lng: -84.39438,
      city: 'Williamson',
      state: 'GA',
    },
    30293: {
      lat: 32.97581,
      lng: -84.59612,
      city: 'Woodbury',
      state: 'GA',
    },
    30294: {
      lat: 33.6371,
      lng: -84.26289,
      city: 'Ellenwood',
      state: 'GA',
    },
    30295: {
      lat: 33.09319,
      lng: -84.31819,
      city: 'Zebulon',
      state: 'GA',
    },
    30296: {
      lat: 33.56351,
      lng: -84.44254,
      city: 'Riverdale',
      state: 'GA',
    },
    30297: {
      lat: 33.61511,
      lng: -84.37145,
      city: 'Forest Park',
      state: 'GA',
    },
    30303: {
      lat: 33.75284,
      lng: -84.39028,
      city: 'Atlanta',
      state: 'GA',
    },
    30305: {
      lat: 33.83518,
      lng: -84.38931,
      city: 'Atlanta',
      state: 'GA',
    },
    30306: {
      lat: 33.78867,
      lng: -84.35136,
      city: 'Atlanta',
      state: 'GA',
    },
    30307: {
      lat: 33.77053,
      lng: -84.33419,
      city: 'Atlanta',
      state: 'GA',
    },
    30308: {
      lat: 33.77094,
      lng: -84.37773,
      city: 'Atlanta',
      state: 'GA',
    },
    30309: {
      lat: 33.798,
      lng: -84.38774,
      city: 'Atlanta',
      state: 'GA',
    },
    30310: {
      lat: 33.72695,
      lng: -84.42726,
      city: 'Atlanta',
      state: 'GA',
    },
    30311: {
      lat: 33.72345,
      lng: -84.47442,
      city: 'Atlanta',
      state: 'GA',
    },
    30312: {
      lat: 33.74463,
      lng: -84.37577,
      city: 'Atlanta',
      state: 'GA',
    },
    30313: {
      lat: 33.76369,
      lng: -84.39761,
      city: 'Atlanta',
      state: 'GA',
    },
    30314: {
      lat: 33.75787,
      lng: -84.43193,
      city: 'Atlanta',
      state: 'GA',
    },
    30315: {
      lat: 33.70312,
      lng: -84.38272,
      city: 'Atlanta',
      state: 'GA',
    },
    30316: {
      lat: 33.71277,
      lng: -84.33218,
      city: 'Atlanta',
      state: 'GA',
    },
    30317: {
      lat: 33.74913,
      lng: -84.31537,
      city: 'Atlanta',
      state: 'GA',
    },
    30318: {
      lat: 33.79235,
      lng: -84.44831,
      city: 'Atlanta',
      state: 'GA',
    },
    30319: {
      lat: 33.87875,
      lng: -84.33596,
      city: 'Atlanta',
      state: 'GA',
    },
    30322: {
      lat: 33.7948,
      lng: -84.32539,
      city: 'Atlanta',
      state: 'GA',
    },
    30324: {
      lat: 33.8196,
      lng: -84.3572,
      city: 'Atlanta',
      state: 'GA',
    },
    30326: {
      lat: 33.84957,
      lng: -84.36342,
      city: 'Atlanta',
      state: 'GA',
    },
    30327: {
      lat: 33.86708,
      lng: -84.42358,
      city: 'Atlanta',
      state: 'GA',
    },
    30328: {
      lat: 33.93194,
      lng: -84.38617,
      city: 'Atlanta',
      state: 'GA',
    },
    30329: {
      lat: 33.82625,
      lng: -84.32282,
      city: 'Atlanta',
      state: 'GA',
    },
    30331: {
      lat: 33.70627,
      lng: -84.54439,
      city: 'Atlanta',
      state: 'GA',
    },
    30332: {
      lat: 33.77757,
      lng: -84.40429,
      city: 'Atlanta',
      state: 'GA',
    },
    30334: {
      lat: 33.74855,
      lng: -84.38804,
      city: 'Atlanta',
      state: 'GA',
    },
    30336: {
      lat: 33.73718,
      lng: -84.56768,
      city: 'Atlanta',
      state: 'GA',
    },
    30337: {
      lat: 33.64019,
      lng: -84.44962,
      city: 'Atlanta',
      state: 'GA',
    },
    30338: {
      lat: 33.9437,
      lng: -84.31801,
      city: 'Atlanta',
      state: 'GA',
    },
    30339: {
      lat: 33.86786,
      lng: -84.46483,
      city: 'Atlanta',
      state: 'GA',
    },
    30340: {
      lat: 33.89808,
      lng: -84.25113,
      city: 'Atlanta',
      state: 'GA',
    },
    30341: {
      lat: 33.88711,
      lng: -84.28992,
      city: 'Atlanta',
      state: 'GA',
    },
    30342: {
      lat: 33.88227,
      lng: -84.37429,
      city: 'Atlanta',
      state: 'GA',
    },
    30344: {
      lat: 33.67508,
      lng: -84.46029,
      city: 'Atlanta',
      state: 'GA',
    },
    30345: {
      lat: 33.85082,
      lng: -84.28317,
      city: 'Atlanta',
      state: 'GA',
    },
    30346: {
      lat: 33.92425,
      lng: -84.33881,
      city: 'Atlanta',
      state: 'GA',
    },
    30349: {
      lat: 33.62346,
      lng: -84.52591,
      city: 'Atlanta',
      state: 'GA',
    },
    30350: {
      lat: 33.97881,
      lng: -84.33366,
      city: 'Atlanta',
      state: 'GA',
    },
    30354: {
      lat: 33.66058,
      lng: -84.38609,
      city: 'Atlanta',
      state: 'GA',
    },
    30360: {
      lat: 33.93198,
      lng: -84.27302,
      city: 'Atlanta',
      state: 'GA',
    },
    30363: {
      lat: 33.79147,
      lng: -84.39887,
      city: 'Atlanta',
      state: 'GA',
    },
    30401: {
      lat: 32.604,
      lng: -82.34825,
      city: 'Swainsboro',
      state: 'GA',
    },
    30410: {
      lat: 32.18655,
      lng: -82.51767,
      city: 'Ailey',
      state: 'GA',
    },
    30411: { lat: 32.1254, lng: -82.79362, city: 'Alamo', state: 'GA' },
    30412: {
      lat: 32.07973,
      lng: -82.48643,
      city: 'Alston',
      state: 'GA',
    },
    30413: {
      lat: 32.88467,
      lng: -82.51393,
      city: 'Bartow',
      state: 'GA',
    },
    30414: {
      lat: 32.15618,
      lng: -81.98522,
      city: 'Bellville',
      state: 'GA',
    },
    30415: {
      lat: 32.32773,
      lng: -81.6008,
      city: 'Brooklet',
      state: 'GA',
    },
    30417: {
      lat: 32.16953,
      lng: -81.91978,
      city: 'Claxton',
      state: 'GA',
    },
    30420: {
      lat: 32.30363,
      lng: -82.12958,
      city: 'Cobbtown',
      state: 'GA',
    },
    30421: {
      lat: 32.18401,
      lng: -82.11096,
      city: 'Collins',
      state: 'GA',
    },
    30423: {
      lat: 32.14601,
      lng: -81.82301,
      city: 'Daisy',
      state: 'GA',
    },
    30425: {
      lat: 32.64732,
      lng: -82.02337,
      city: 'Garfield',
      state: 'GA',
    },
    30426: {
      lat: 33.03546,
      lng: -81.65159,
      city: 'Girard',
      state: 'GA',
    },
    30427: {
      lat: 31.92271,
      lng: -81.96622,
      city: 'Glennville',
      state: 'GA',
    },
    30428: {
      lat: 32.21706,
      lng: -82.71436,
      city: 'Glenwood',
      state: 'GA',
    },
    30429: {
      lat: 32.15897,
      lng: -81.93714,
      city: 'Hagan',
      state: 'GA',
    },
    30434: {
      lat: 33.02867,
      lng: -82.3727,
      city: 'Louisville',
      state: 'GA',
    },
    30436: { lat: 32.1494, lng: -82.29026, city: 'Lyons', state: 'GA' },
    30439: {
      lat: 32.4169,
      lng: -82.07112,
      city: 'Metter',
      state: 'GA',
    },
    30441: {
      lat: 32.81467,
      lng: -82.23682,
      city: 'Midville',
      state: 'GA',
    },
    30442: {
      lat: 32.81677,
      lng: -81.97549,
      city: 'Millen',
      state: 'GA',
    },
    30445: {
      lat: 32.16715,
      lng: -82.58413,
      city: 'Mount Vernon',
      state: 'GA',
    },
    30446: {
      lat: 32.5599,
      lng: -81.46734,
      city: 'Newington',
      state: 'GA',
    },
    30448: {
      lat: 32.48754,
      lng: -82.36444,
      city: 'Nunez',
      state: 'GA',
    },
    30449: {
      lat: 32.52254,
      lng: -81.52987,
      city: 'Oliver',
      state: 'GA',
    },
    30450: {
      lat: 32.55964,
      lng: -81.93322,
      city: 'Portal',
      state: 'GA',
    },
    30451: {
      lat: 32.39325,
      lng: -81.95622,
      city: 'Pulaski',
      state: 'GA',
    },
    30452: {
      lat: 32.31706,
      lng: -81.89417,
      city: 'Register',
      state: 'GA',
    },
    30453: {
      lat: 32.01926,
      lng: -82.11899,
      city: 'Reidsville',
      state: 'GA',
    },
    30454: {
      lat: 32.43857,
      lng: -82.73421,
      city: 'Rockledge',
      state: 'GA',
    },
    30455: {
      lat: 32.70111,
      lng: -81.80652,
      city: 'Rocky Ford',
      state: 'GA',
    },
    30456: {
      lat: 32.98137,
      lng: -81.79246,
      city: 'Sardis',
      state: 'GA',
    },
    30457: {
      lat: 32.39852,
      lng: -82.56498,
      city: 'Soperton',
      state: 'GA',
    },
    30458: {
      lat: 32.39732,
      lng: -81.82694,
      city: 'Statesboro',
      state: 'GA',
    },
    30460: {
      lat: 32.42718,
      lng: -81.78272,
      city: 'Statesboro',
      state: 'GA',
    },
    30461: {
      lat: 32.5072,
      lng: -81.71941,
      city: 'Statesboro',
      state: 'GA',
    },
    30464: {
      lat: 32.43509,
      lng: -82.22186,
      city: 'Stillmore',
      state: 'GA',
    },
    30467: {
      lat: 32.75114,
      lng: -81.60468,
      city: 'Sylvania',
      state: 'GA',
    },
    30470: {
      lat: 32.33018,
      lng: -82.51818,
      city: 'Tarrytown',
      state: 'GA',
    },
    30471: {
      lat: 32.53816,
      lng: -82.18563,
      city: 'Twin City',
      state: 'GA',
    },
    30473: {
      lat: 32.01613,
      lng: -82.47198,
      city: 'Uvalda',
      state: 'GA',
    },
    30474: {
      lat: 32.22393,
      lng: -82.41381,
      city: 'Vidalia',
      state: 'GA',
    },
    30477: {
      lat: 32.86279,
      lng: -82.3979,
      city: 'Wadley',
      state: 'GA',
    },
    30501: {
      lat: 34.31844,
      lng: -83.81636,
      city: 'Gainesville',
      state: 'GA',
    },
    30504: {
      lat: 34.26639,
      lng: -83.88751,
      city: 'Gainesville',
      state: 'GA',
    },
    30506: {
      lat: 34.34918,
      lng: -83.89486,
      city: 'Gainesville',
      state: 'GA',
    },
    30507: {
      lat: 34.26082,
      lng: -83.76622,
      city: 'Gainesville',
      state: 'GA',
    },
    30510: { lat: 34.44194, lng: -83.58446, city: 'Alto', state: 'GA' },
    30511: {
      lat: 34.45674,
      lng: -83.47474,
      city: 'Baldwin',
      state: 'GA',
    },
    30512: {
      lat: 34.85667,
      lng: -83.97204,
      city: 'Blairsville',
      state: 'GA',
    },
    30513: {
      lat: 34.82469,
      lng: -84.31891,
      city: 'Blue Ridge',
      state: 'GA',
    },
    30516: {
      lat: 34.37535,
      lng: -83.03748,
      city: 'Bowersville',
      state: 'GA',
    },
    30517: {
      lat: 34.13047,
      lng: -83.7975,
      city: 'Braselton',
      state: 'GA',
    },
    30518: { lat: 34.132, lng: -84.02578, city: 'Buford', state: 'GA' },
    30519: {
      lat: 34.08792,
      lng: -83.9423,
      city: 'Buford',
      state: 'GA',
    },
    30520: {
      lat: 34.33833,
      lng: -83.08661,
      city: 'Canon',
      state: 'GA',
    },
    30521: {
      lat: 34.36202,
      lng: -83.29355,
      city: 'Carnesville',
      state: 'GA',
    },
    30522: {
      lat: 34.78351,
      lng: -84.34233,
      city: 'Cherry Log',
      state: 'GA',
    },
    30523: {
      lat: 34.72012,
      lng: -83.54166,
      city: 'Clarkesville',
      state: 'GA',
    },
    30525: {
      lat: 34.91289,
      lng: -83.35853,
      city: 'Clayton',
      state: 'GA',
    },
    30527: {
      lat: 34.47948,
      lng: -83.7863,
      city: 'Clermont',
      state: 'GA',
    },
    30528: {
      lat: 34.6083,
      lng: -83.77589,
      city: 'Cleveland',
      state: 'GA',
    },
    30529: {
      lat: 34.2155,
      lng: -83.482,
      city: 'Commerce',
      state: 'GA',
    },
    30530: {
      lat: 34.22691,
      lng: -83.38433,
      city: 'Commerce',
      state: 'GA',
    },
    30531: {
      lat: 34.51017,
      lng: -83.59103,
      city: 'Cornelia',
      state: 'GA',
    },
    30533: {
      lat: 34.57344,
      lng: -84.01576,
      city: 'Dahlonega',
      state: 'GA',
    },
    30534: {
      lat: 34.43085,
      lng: -84.1439,
      city: 'Dawsonville',
      state: 'GA',
    },
    30535: {
      lat: 34.58063,
      lng: -83.57652,
      city: 'Demorest',
      state: 'GA',
    },
    30536: {
      lat: 34.65151,
      lng: -84.35223,
      city: 'Ellijay',
      state: 'GA',
    },
    30537: {
      lat: 34.97855,
      lng: -83.32975,
      city: 'Dillard',
      state: 'GA',
    },
    30538: {
      lat: 34.5035,
      lng: -83.26888,
      city: 'Eastanollee',
      state: 'GA',
    },
    30539: {
      lat: 34.68061,
      lng: -84.47016,
      city: 'East Ellijay',
      state: 'GA',
    },
    30540: {
      lat: 34.72313,
      lng: -84.54421,
      city: 'Ellijay',
      state: 'GA',
    },
    30541: {
      lat: 34.91164,
      lng: -84.53482,
      city: 'Epworth',
      state: 'GA',
    },
    30542: {
      lat: 34.17846,
      lng: -83.90915,
      city: 'Flowery Branch',
      state: 'GA',
    },
    30543: {
      lat: 34.29781,
      lng: -83.64971,
      city: 'Gillsville',
      state: 'GA',
    },
    30545: {
      lat: 34.74208,
      lng: -83.75813,
      city: 'Helen',
      state: 'GA',
    },
    30546: {
      lat: 34.90673,
      lng: -83.71016,
      city: 'Hiawassee',
      state: 'GA',
    },
    30547: {
      lat: 34.35737,
      lng: -83.46095,
      city: 'Homer',
      state: 'GA',
    },
    30548: {
      lat: 34.09311,
      lng: -83.7646,
      city: 'Hoschton',
      state: 'GA',
    },
    30549: {
      lat: 34.10731,
      lng: -83.57684,
      city: 'Jefferson',
      state: 'GA',
    },
    30552: {
      lat: 34.76965,
      lng: -83.44575,
      city: 'Lakemont',
      state: 'GA',
    },
    30553: {
      lat: 34.44473,
      lng: -83.08771,
      city: 'Lavonia',
      state: 'GA',
    },
    30554: { lat: 34.40093, lng: -83.65926, city: 'Lula', state: 'GA' },
    30555: {
      lat: 34.97216,
      lng: -84.42459,
      city: 'McCaysville',
      state: 'GA',
    },
    30557: {
      lat: 34.48143,
      lng: -83.18484,
      city: 'Martin',
      state: 'GA',
    },
    30558: {
      lat: 34.2683,
      lng: -83.56787,
      city: 'Maysville',
      state: 'GA',
    },
    30559: {
      lat: 34.95233,
      lng: -84.26612,
      city: 'Mineral Bluff',
      state: 'GA',
    },
    30560: {
      lat: 34.87785,
      lng: -84.19528,
      city: 'Morganton',
      state: 'GA',
    },
    30562: {
      lat: 34.91937,
      lng: -83.38177,
      city: 'Mountain City',
      state: 'GA',
    },
    30563: {
      lat: 34.5596,
      lng: -83.46283,
      city: 'Mount Airy',
      state: 'GA',
    },
    30564: {
      lat: 34.47251,
      lng: -83.8826,
      city: 'Murrayville',
      state: 'GA',
    },
    30565: {
      lat: 34.08802,
      lng: -83.41203,
      city: 'Nicholson',
      state: 'GA',
    },
    30566: {
      lat: 34.23747,
      lng: -83.89477,
      city: 'Oakwood',
      state: 'GA',
    },
    30567: {
      lat: 34.18326,
      lng: -83.6814,
      city: 'Pendergrass',
      state: 'GA',
    },
    30568: {
      lat: 34.95348,
      lng: -83.41218,
      city: 'Rabun Gap',
      state: 'GA',
    },
    30571: {
      lat: 34.71219,
      lng: -83.68322,
      city: 'Sautee Nacoochee',
      state: 'GA',
    },
    30572: {
      lat: 34.73539,
      lng: -84.07364,
      city: 'Suches',
      state: 'GA',
    },
    30573: {
      lat: 34.74242,
      lng: -83.39986,
      city: 'Tallulah Falls',
      state: 'GA',
    },
    30575: {
      lat: 34.20353,
      lng: -83.71556,
      city: 'Talmo',
      state: 'GA',
    },
    30576: {
      lat: 34.82682,
      lng: -83.44539,
      city: 'Tiger',
      state: 'GA',
    },
    30577: {
      lat: 34.55549,
      lng: -83.32632,
      city: 'Toccoa',
      state: 'GA',
    },
    30581: {
      lat: 34.80195,
      lng: -83.42405,
      city: 'Wiley',
      state: 'GA',
    },
    30582: {
      lat: 34.9494,
      lng: -83.89869,
      city: 'Young Harris',
      state: 'GA',
    },
    30597: {
      lat: 34.52762,
      lng: -83.98462,
      city: 'Dahlonega',
      state: 'GA',
    },
    30598: {
      lat: 34.59663,
      lng: -83.35411,
      city: 'Toccoa Falls',
      state: 'GA',
    },
    30601: {
      lat: 34.00032,
      lng: -83.34851,
      city: 'Athens',
      state: 'GA',
    },
    30602: {
      lat: 33.94252,
      lng: -83.37256,
      city: 'Athens',
      state: 'GA',
    },
    30605: {
      lat: 33.90551,
      lng: -83.32405,
      city: 'Athens',
      state: 'GA',
    },
    30606: {
      lat: 33.93873,
      lng: -83.43305,
      city: 'Athens',
      state: 'GA',
    },
    30607: {
      lat: 34.01895,
      lng: -83.44774,
      city: 'Athens',
      state: 'GA',
    },
    30609: {
      lat: 33.93289,
      lng: -83.37223,
      city: 'Athens',
      state: 'GA',
    },
    30619: {
      lat: 33.85752,
      lng: -83.2403,
      city: 'Arnoldsville',
      state: 'GA',
    },
    30620: {
      lat: 33.92917,
      lng: -83.75958,
      city: 'Bethlehem',
      state: 'GA',
    },
    30621: {
      lat: 33.80194,
      lng: -83.4791,
      city: 'Bishop',
      state: 'GA',
    },
    30622: {
      lat: 33.92041,
      lng: -83.53076,
      city: 'Bogart',
      state: 'GA',
    },
    30623: {
      lat: 33.73113,
      lng: -83.51592,
      city: 'Bostwick',
      state: 'GA',
    },
    30624: {
      lat: 34.18097,
      lng: -83.03885,
      city: 'Bowman',
      state: 'GA',
    },
    30625: {
      lat: 33.52128,
      lng: -83.34211,
      city: 'Buckhead',
      state: 'GA',
    },
    30627: {
      lat: 33.98264,
      lng: -82.96265,
      city: 'Carlton',
      state: 'GA',
    },
    30628: {
      lat: 34.03115,
      lng: -83.21364,
      city: 'Colbert',
      state: 'GA',
    },
    30629: {
      lat: 34.07779,
      lng: -83.11625,
      city: 'Comer',
      state: 'GA',
    },
    30630: {
      lat: 33.89894,
      lng: -83.14366,
      city: 'Crawford',
      state: 'GA',
    },
    30631: {
      lat: 33.56553,
      lng: -82.87034,
      city: 'Crawfordville',
      state: 'GA',
    },
    30633: {
      lat: 34.17896,
      lng: -83.24612,
      city: 'Danielsville',
      state: 'GA',
    },
    30634: {
      lat: 34.20307,
      lng: -82.94522,
      city: 'Dewy Rose',
      state: 'GA',
    },
    30635: {
      lat: 34.10346,
      lng: -82.80145,
      city: 'Elberton',
      state: 'GA',
    },
    30639: {
      lat: 34.28283,
      lng: -83.15232,
      city: 'Franklin Springs',
      state: 'GA',
    },
    30641: {
      lat: 33.77872,
      lng: -83.56456,
      city: 'Good Hope',
      state: 'GA',
    },
    30642: {
      lat: 33.5446,
      lng: -83.1832,
      city: 'Greensboro',
      state: 'GA',
    },
    30643: {
      lat: 34.36464,
      lng: -82.91143,
      city: 'Hartwell',
      state: 'GA',
    },
    30646: { lat: 34.08011, lng: -83.31254, city: 'Hull', state: 'GA' },
    30648: {
      lat: 33.87577,
      lng: -83.03319,
      city: 'Lexington',
      state: 'GA',
    },
    30650: {
      lat: 33.5907,
      lng: -83.46693,
      city: 'Madison',
      state: 'GA',
    },
    30655: {
      lat: 33.78214,
      lng: -83.69072,
      city: 'Monroe',
      state: 'GA',
    },
    30656: {
      lat: 33.86302,
      lng: -83.71903,
      city: 'Monroe',
      state: 'GA',
    },
    30660: {
      lat: 33.78237,
      lng: -82.94369,
      city: 'Rayle',
      state: 'GA',
    },
    30662: {
      lat: 34.26383,
      lng: -83.14624,
      city: 'Royston',
      state: 'GA',
    },
    30663: {
      lat: 33.61572,
      lng: -83.60357,
      city: 'Rutledge',
      state: 'GA',
    },
    30664: {
      lat: 33.55786,
      lng: -82.79904,
      city: 'Sharon',
      state: 'GA',
    },
    30665: {
      lat: 33.53707,
      lng: -83.07475,
      city: 'Siloam',
      state: 'GA',
    },
    30666: {
      lat: 33.95891,
      lng: -83.58266,
      city: 'Statham',
      state: 'GA',
    },
    30667: {
      lat: 33.78526,
      lng: -83.16634,
      city: 'Stephens',
      state: 'GA',
    },
    30668: {
      lat: 33.90835,
      lng: -82.69486,
      city: 'Tignall',
      state: 'GA',
    },
    30669: {
      lat: 33.67159,
      lng: -83.11187,
      city: 'Union Point',
      state: 'GA',
    },
    30673: {
      lat: 33.72716,
      lng: -82.71594,
      city: 'Washington',
      state: 'GA',
    },
    30677: {
      lat: 33.7929,
      lng: -83.38186,
      city: 'Watkinsville',
      state: 'GA',
    },
    30678: {
      lat: 33.44626,
      lng: -83.07598,
      city: 'White Plains',
      state: 'GA',
    },
    30680: {
      lat: 33.99725,
      lng: -83.69743,
      city: 'Winder',
      state: 'GA',
    },
    30683: {
      lat: 33.94703,
      lng: -83.25775,
      city: 'Winterville',
      state: 'GA',
    },
    30701: {
      lat: 34.49535,
      lng: -84.95385,
      city: 'Calhoun',
      state: 'GA',
    },
    30705: {
      lat: 34.74711,
      lng: -84.74929,
      city: 'Chatsworth',
      state: 'GA',
    },
    30707: {
      lat: 34.77943,
      lng: -85.36028,
      city: 'Chickamauga',
      state: 'GA',
    },
    30708: {
      lat: 34.93985,
      lng: -84.66754,
      city: 'Cisco',
      state: 'GA',
    },
    30710: {
      lat: 34.95002,
      lng: -84.91993,
      city: 'Cohutta',
      state: 'GA',
    },
    30711: {
      lat: 34.92905,
      lng: -84.76259,
      city: 'Crandall',
      state: 'GA',
    },
    30720: {
      lat: 34.73472,
      lng: -85.00289,
      city: 'Dalton',
      state: 'GA',
    },
    30721: {
      lat: 34.79168,
      lng: -84.91187,
      city: 'Dalton',
      state: 'GA',
    },
    30724: { lat: 34.8253, lng: -84.75795, city: 'Eton', state: 'GA' },
    30725: {
      lat: 34.92443,
      lng: -85.35359,
      city: 'Flintstone',
      state: 'GA',
    },
    30726: {
      lat: 34.97579,
      lng: -85.13959,
      city: 'Graysville',
      state: 'GA',
    },
    30728: {
      lat: 34.68015,
      lng: -85.23417,
      city: 'La Fayette',
      state: 'GA',
    },
    30730: {
      lat: 34.36017,
      lng: -85.42172,
      city: 'Lyerly',
      state: 'GA',
    },
    30731: {
      lat: 34.58317,
      lng: -85.47894,
      city: 'Menlo',
      state: 'GA',
    },
    30733: {
      lat: 34.41574,
      lng: -85.04349,
      city: 'Plainville',
      state: 'GA',
    },
    30734: {
      lat: 34.53361,
      lng: -84.71346,
      city: 'Ranger',
      state: 'GA',
    },
    30735: {
      lat: 34.60183,
      lng: -84.89375,
      city: 'Resaca',
      state: 'GA',
    },
    30736: {
      lat: 34.90244,
      lng: -85.13306,
      city: 'Ringgold',
      state: 'GA',
    },
    30738: {
      lat: 34.79534,
      lng: -85.47846,
      city: 'Rising Fawn',
      state: 'GA',
    },
    30739: {
      lat: 34.80345,
      lng: -85.21515,
      city: 'Rock Spring',
      state: 'GA',
    },
    30740: {
      lat: 34.75407,
      lng: -85.07786,
      city: 'Rocky Face',
      state: 'GA',
    },
    30741: {
      lat: 34.95168,
      lng: -85.28213,
      city: 'Rossville',
      state: 'GA',
    },
    30742: {
      lat: 34.93566,
      lng: -85.24482,
      city: 'Fort Oglethorpe',
      state: 'GA',
    },
    30746: {
      lat: 34.59214,
      lng: -85.03285,
      city: 'Sugar Valley',
      state: 'GA',
    },
    30747: {
      lat: 34.50179,
      lng: -85.29407,
      city: 'Summerville',
      state: 'GA',
    },
    30750: {
      lat: 34.93681,
      lng: -85.3882,
      city: 'Lookout Mountain',
      state: 'GA',
    },
    30751: {
      lat: 34.98212,
      lng: -84.73743,
      city: 'Tennga',
      state: 'GA',
    },
    30752: {
      lat: 34.91136,
      lng: -85.53079,
      city: 'Trenton',
      state: 'GA',
    },
    30753: {
      lat: 34.57896,
      lng: -85.28806,
      city: 'Trion',
      state: 'GA',
    },
    30755: {
      lat: 34.86944,
      lng: -85.04072,
      city: 'Tunnel Hill',
      state: 'GA',
    },
    30756: {
      lat: 34.90103,
      lng: -84.97458,
      city: 'Varnell',
      state: 'GA',
    },
    30757: {
      lat: 34.94164,
      lng: -85.4324,
      city: 'Wildwood',
      state: 'GA',
    },
    30802: {
      lat: 33.60215,
      lng: -82.31832,
      city: 'Appling',
      state: 'GA',
    },
    30803: {
      lat: 33.13878,
      lng: -82.55013,
      city: 'Avera',
      state: 'GA',
    },
    30805: {
      lat: 33.28012,
      lng: -82.19936,
      city: 'Blythe',
      state: 'GA',
    },
    30807: {
      lat: 33.45517,
      lng: -82.64777,
      city: 'Camak',
      state: 'GA',
    },
    30808: {
      lat: 33.38234,
      lng: -82.40237,
      city: 'Dearing',
      state: 'GA',
    },
    30809: {
      lat: 33.55522,
      lng: -82.16357,
      city: 'Evans',
      state: 'GA',
    },
    30810: {
      lat: 33.23247,
      lng: -82.58849,
      city: 'Gibson',
      state: 'GA',
    },
    30812: {
      lat: 33.36731,
      lng: -82.0291,
      city: 'Gracewood',
      state: 'GA',
    },
    30813: {
      lat: 33.47037,
      lng: -82.2193,
      city: 'Grovetown',
      state: 'GA',
    },
    30814: {
      lat: 33.43604,
      lng: -82.31879,
      city: 'Harlem',
      state: 'GA',
    },
    30815: {
      lat: 33.2869,
      lng: -82.0829,
      city: 'Hephzibah',
      state: 'GA',
    },
    30816: {
      lat: 33.1639,
      lng: -82.17061,
      city: 'Keysville',
      state: 'GA',
    },
    30817: {
      lat: 33.7746,
      lng: -82.43469,
      city: 'Lincolnton',
      state: 'GA',
    },
    30818: {
      lat: 33.26765,
      lng: -82.32646,
      city: 'Matthews',
      state: 'GA',
    },
    30820: {
      lat: 33.22457,
      lng: -82.71989,
      city: 'Mitchell',
      state: 'GA',
    },
    30821: {
      lat: 33.48502,
      lng: -82.73121,
      city: 'Norwood',
      state: 'GA',
    },
    30822: {
      lat: 32.91866,
      lng: -81.94439,
      city: 'Perkins',
      state: 'GA',
    },
    30823: {
      lat: 33.222,
      lng: -82.45647,
      city: 'Stapleton',
      state: 'GA',
    },
    30824: {
      lat: 33.51586,
      lng: -82.51467,
      city: 'Thomson',
      state: 'GA',
    },
    30828: {
      lat: 33.37281,
      lng: -82.65351,
      city: 'Warrenton',
      state: 'GA',
    },
    30830: {
      lat: 33.08204,
      lng: -81.9751,
      city: 'Waynesboro',
      state: 'GA',
    },
    30833: {
      lat: 33.18934,
      lng: -82.36014,
      city: 'Wrens',
      state: 'GA',
    },
    30901: {
      lat: 33.43811,
      lng: -81.95327,
      city: 'Augusta',
      state: 'GA',
    },
    30904: {
      lat: 33.47864,
      lng: -82.01315,
      city: 'Augusta',
      state: 'GA',
    },
    30905: {
      lat: 33.4087,
      lng: -82.15073,
      city: 'Augusta',
      state: 'GA',
    },
    30906: {
      lat: 33.34842,
      lng: -81.97239,
      city: 'Augusta',
      state: 'GA',
    },
    30907: {
      lat: 33.52193,
      lng: -82.08596,
      city: 'Augusta',
      state: 'GA',
    },
    30909: {
      lat: 33.47068,
      lng: -82.08376,
      city: 'Augusta',
      state: 'GA',
    },
    30912: {
      lat: 33.47021,
      lng: -81.98789,
      city: 'Augusta',
      state: 'GA',
    },
    31001: {
      lat: 31.97595,
      lng: -83.33979,
      city: 'Abbeville',
      state: 'GA',
    },
    31002: {
      lat: 32.54802,
      lng: -82.59012,
      city: 'Adrian',
      state: 'GA',
    },
    31003: {
      lat: 32.59688,
      lng: -83.22299,
      city: 'Allentown',
      state: 'GA',
    },
    31004: {
      lat: 32.95167,
      lng: -83.80536,
      city: 'Bolingbroke',
      state: 'GA',
    },
    31005: {
      lat: 32.54533,
      lng: -83.59497,
      city: 'Bonaire',
      state: 'GA',
    },
    31006: {
      lat: 32.58164,
      lng: -84.25531,
      city: 'Butler',
      state: 'GA',
    },
    31007: {
      lat: 32.18917,
      lng: -83.93377,
      city: 'Byromville',
      state: 'GA',
    },
    31008: {
      lat: 32.65479,
      lng: -83.78287,
      city: 'Byron',
      state: 'GA',
    },
    31009: {
      lat: 32.27636,
      lng: -83.00564,
      city: 'Cadwell',
      state: 'GA',
    },
    31011: {
      lat: 32.13315,
      lng: -83.0556,
      city: 'Chauncey',
      state: 'GA',
    },
    31012: {
      lat: 32.39422,
      lng: -83.17717,
      city: 'Chester',
      state: 'GA',
    },
    31014: {
      lat: 32.41439,
      lng: -83.34504,
      city: 'Cochran',
      state: 'GA',
    },
    31015: {
      lat: 31.93729,
      lng: -83.77444,
      city: 'Cordele',
      state: 'GA',
    },
    31016: {
      lat: 32.83656,
      lng: -84.11477,
      city: 'Culloden',
      state: 'GA',
    },
    31017: {
      lat: 32.62075,
      lng: -83.23305,
      city: 'Danville',
      state: 'GA',
    },
    31018: {
      lat: 32.97888,
      lng: -82.62012,
      city: 'Davisboro',
      state: 'GA',
    },
    31019: {
      lat: 32.42622,
      lng: -83.0534,
      city: 'Dexter',
      state: 'GA',
    },
    31020: {
      lat: 32.65376,
      lng: -83.501,
      city: 'Dry Branch',
      state: 'GA',
    },
    31021: {
      lat: 32.49002,
      lng: -82.93435,
      city: 'Dublin',
      state: 'GA',
    },
    31022: {
      lat: 32.51216,
      lng: -83.10954,
      city: 'Dudley',
      state: 'GA',
    },
    31023: {
      lat: 32.18288,
      lng: -83.1991,
      city: 'Eastman',
      state: 'GA',
    },
    31024: {
      lat: 33.32344,
      lng: -83.36987,
      city: 'Eatonton',
      state: 'GA',
    },
    31025: { lat: 32.33833, lng: -83.73876, city: 'Elko', state: 'GA' },
    31027: {
      lat: 32.57405,
      lng: -82.81959,
      city: 'East Dublin',
      state: 'GA',
    },
    31028: {
      lat: 32.63164,
      lng: -83.68459,
      city: 'Centerville',
      state: 'GA',
    },
    31029: {
      lat: 33.03894,
      lng: -83.92977,
      city: 'Forsyth',
      state: 'GA',
    },
    31030: {
      lat: 32.56861,
      lng: -83.89103,
      city: 'Fort Valley',
      state: 'GA',
    },
    31031: {
      lat: 32.87424,
      lng: -83.32555,
      city: 'Gordon',
      state: 'GA',
    },
    31032: { lat: 33.01039, lng: -83.57292, city: 'Gray', state: 'GA' },
    31033: {
      lat: 33.07661,
      lng: -83.43643,
      city: 'Haddock',
      state: 'GA',
    },
    31034: {
      lat: 33.05473,
      lng: -83.22538,
      city: 'Hardwick',
      state: 'GA',
    },
    31035: {
      lat: 32.84601,
      lng: -82.7062,
      city: 'Harrison',
      state: 'GA',
    },
    31036: {
      lat: 32.27055,
      lng: -83.49597,
      city: 'Hawkinsville',
      state: 'GA',
    },
    31037: {
      lat: 32.12056,
      lng: -82.93245,
      city: 'McRae Helena',
      state: 'GA',
    },
    31038: {
      lat: 33.15554,
      lng: -83.61644,
      city: 'Hillsboro',
      state: 'GA',
    },
    31039: {
      lat: 32.6024,
      lng: -84.38036,
      city: 'Howard',
      state: 'GA',
    },
    31041: { lat: 32.37905, lng: -84.1721, city: 'Ideal', state: 'GA' },
    31042: {
      lat: 32.74421,
      lng: -83.16964,
      city: 'Irwinton',
      state: 'GA',
    },
    31044: {
      lat: 32.66857,
      lng: -83.37612,
      city: 'Jeffersonville',
      state: 'GA',
    },
    31045: {
      lat: 33.28484,
      lng: -82.78933,
      city: 'Jewell',
      state: 'GA',
    },
    31046: {
      lat: 33.05237,
      lng: -83.78302,
      city: 'Juliette',
      state: 'GA',
    },
    31047: {
      lat: 32.46786,
      lng: -83.58009,
      city: 'Kathleen',
      state: 'GA',
    },
    31049: { lat: 32.68281, lng: -82.52634, city: 'Kite', state: 'GA' },
    31050: {
      lat: 32.72924,
      lng: -83.94096,
      city: 'Knoxville',
      state: 'GA',
    },
    31051: {
      lat: 32.14456,
      lng: -83.87812,
      city: 'Lilly',
      state: 'GA',
    },
    31052: {
      lat: 32.7743,
      lng: -83.84664,
      city: 'Lizella',
      state: 'GA',
    },
    31054: {
      lat: 32.88214,
      lng: -83.20723,
      city: 'McIntyre',
      state: 'GA',
    },
    31055: {
      lat: 31.98248,
      lng: -82.89046,
      city: 'McRae Helena',
      state: 'GA',
    },
    31057: {
      lat: 32.43319,
      lng: -83.94324,
      city: 'Marshallville',
      state: 'GA',
    },
    31058: { lat: 32.4911, lng: -84.41273, city: 'Mauk', state: 'GA' },
    31060: {
      lat: 31.98333,
      lng: -83.05673,
      city: 'Milan',
      state: 'GA',
    },
    31061: {
      lat: 33.06041,
      lng: -83.22816,
      city: 'Milledgeville',
      state: 'GA',
    },
    31062: {
      lat: 33.04336,
      lng: -83.21563,
      city: 'Milledgeville',
      state: 'GA',
    },
    31063: {
      lat: 32.29249,
      lng: -83.9648,
      city: 'Montezuma',
      state: 'GA',
    },
    31064: {
      lat: 33.29031,
      lng: -83.69986,
      city: 'Monticello',
      state: 'GA',
    },
    31065: {
      lat: 32.56301,
      lng: -83.16642,
      city: 'Montrose',
      state: 'GA',
    },
    31066: {
      lat: 32.80757,
      lng: -83.98953,
      city: 'Musella',
      state: 'GA',
    },
    31067: {
      lat: 32.85229,
      lng: -82.96471,
      city: 'Oconee',
      state: 'GA',
    },
    31068: {
      lat: 32.33226,
      lng: -84.10704,
      city: 'Oglethorpe',
      state: 'GA',
    },
    31069: { lat: 32.4406, lng: -83.74373, city: 'Perry', state: 'GA' },
    31070: {
      lat: 32.18801,
      lng: -83.78669,
      city: 'Pinehurst',
      state: 'GA',
    },
    31071: {
      lat: 32.13325,
      lng: -83.52255,
      city: 'Pineview',
      state: 'GA',
    },
    31072: {
      lat: 31.96582,
      lng: -83.56619,
      city: 'Pitts',
      state: 'GA',
    },
    31075: {
      lat: 32.35428,
      lng: -82.95277,
      city: 'Rentz',
      state: 'GA',
    },
    31076: {
      lat: 32.54709,
      lng: -84.10762,
      city: 'Reynolds',
      state: 'GA',
    },
    31077: {
      lat: 31.93489,
      lng: -83.17727,
      city: 'Rhine',
      state: 'GA',
    },
    31078: {
      lat: 32.6987,
      lng: -84.06456,
      city: 'Roberta',
      state: 'GA',
    },
    31079: {
      lat: 31.94104,
      lng: -83.46303,
      city: 'Rochelle',
      state: 'GA',
    },
    31081: {
      lat: 32.40824,
      lng: -84.27725,
      city: 'Rupert',
      state: 'GA',
    },
    31082: {
      lat: 32.992,
      lng: -82.89815,
      city: 'Sandersville',
      state: 'GA',
    },
    31083: {
      lat: 32.04473,
      lng: -82.81957,
      city: 'Scotland',
      state: 'GA',
    },
    31084: {
      lat: 31.96152,
      lng: -83.6028,
      city: 'Seville',
      state: 'GA',
    },
    31085: {
      lat: 33.42557,
      lng: -83.631,
      city: 'Shady Dale',
      state: 'GA',
    },
    31087: {
      lat: 33.26609,
      lng: -83.00712,
      city: 'Sparta',
      state: 'GA',
    },
    31088: {
      lat: 32.58587,
      lng: -83.65528,
      city: 'Warner Robins',
      state: 'GA',
    },
    31089: {
      lat: 32.85239,
      lng: -82.86361,
      city: 'Tennille',
      state: 'GA',
    },
    31090: {
      lat: 32.79599,
      lng: -83.0512,
      city: 'Toomsboro',
      state: 'GA',
    },
    31091: {
      lat: 32.23653,
      lng: -83.69966,
      city: 'Unadilla',
      state: 'GA',
    },
    31092: {
      lat: 32.08984,
      lng: -83.78304,
      city: 'Vienna',
      state: 'GA',
    },
    31093: {
      lat: 32.64815,
      lng: -83.65688,
      city: 'Warner Robins',
      state: 'GA',
    },
    31094: {
      lat: 33.11378,
      lng: -82.79892,
      city: 'Warthen',
      state: 'GA',
    },
    31096: {
      lat: 32.71875,
      lng: -82.70636,
      city: 'Wrightsville',
      state: 'GA',
    },
    31097: {
      lat: 32.89947,
      lng: -84.15554,
      city: 'Yatesville',
      state: 'GA',
    },
    31098: {
      lat: 32.61898,
      lng: -83.57357,
      city: 'Warner Robins',
      state: 'GA',
    },
    31201: {
      lat: 32.81433,
      lng: -83.61846,
      city: 'Macon',
      state: 'GA',
    },
    31204: {
      lat: 32.84996,
      lng: -83.67609,
      city: 'Macon',
      state: 'GA',
    },
    31206: { lat: 32.79383, lng: -83.6832, city: 'Macon', state: 'GA' },
    31207: {
      lat: 32.82859,
      lng: -83.64928,
      city: 'Macon',
      state: 'GA',
    },
    31210: { lat: 32.90486, lng: -83.7356, city: 'Macon', state: 'GA' },
    31211: {
      lat: 32.90665,
      lng: -83.60435,
      city: 'Macon',
      state: 'GA',
    },
    31213: {
      lat: 32.84009,
      lng: -83.63937,
      city: 'Macon',
      state: 'GA',
    },
    31216: {
      lat: 32.72885,
      lng: -83.67914,
      city: 'Macon',
      state: 'GA',
    },
    31217: {
      lat: 32.84506,
      lng: -83.50568,
      city: 'Macon',
      state: 'GA',
    },
    31220: { lat: 32.87813, lng: -83.8209, city: 'Macon', state: 'GA' },
    31301: {
      lat: 31.7581,
      lng: -81.60386,
      city: 'Allenhurst',
      state: 'GA',
    },
    31302: {
      lat: 32.12567,
      lng: -81.34134,
      city: 'Bloomingdale',
      state: 'GA',
    },
    31303: { lat: 32.49631, lng: -81.30616, city: 'Clyo', state: 'GA' },
    31305: {
      lat: 31.39057,
      lng: -81.39054,
      city: 'Darien',
      state: 'GA',
    },
    31307: { lat: 32.17979, lng: -81.3967, city: 'Eden', state: 'GA' },
    31308: {
      lat: 32.1758,
      lng: -81.48024,
      city: 'Ellabell',
      state: 'GA',
    },
    31309: {
      lat: 31.88382,
      lng: -81.44358,
      city: 'Fleming',
      state: 'GA',
    },
    31312: {
      lat: 32.3211,
      lng: -81.40126,
      city: 'Guyton',
      state: 'GA',
    },
    31313: {
      lat: 31.83534,
      lng: -81.6183,
      city: 'Hinesville',
      state: 'GA',
    },
    31314: {
      lat: 31.90628,
      lng: -81.61671,
      city: 'Fort Stewart',
      state: 'GA',
    },
    31315: {
      lat: 31.88768,
      lng: -81.60598,
      city: 'Fort Stewart',
      state: 'GA',
    },
    31316: {
      lat: 31.7183,
      lng: -81.73457,
      city: 'Ludowici',
      state: 'GA',
    },
    31318: {
      lat: 32.13575,
      lng: -81.37566,
      city: 'Meldrim',
      state: 'GA',
    },
    31320: {
      lat: 31.76707,
      lng: -81.36231,
      city: 'Midway',
      state: 'GA',
    },
    31321: {
      lat: 32.18185,
      lng: -81.66472,
      city: 'Pembroke',
      state: 'GA',
    },
    31322: {
      lat: 32.10265,
      lng: -81.25626,
      city: 'Pooler',
      state: 'GA',
    },
    31323: {
      lat: 31.67359,
      lng: -81.45304,
      city: 'Riceboro',
      state: 'GA',
    },
    31324: {
      lat: 31.85707,
      lng: -81.26951,
      city: 'Richmond Hill',
      state: 'GA',
    },
    31326: {
      lat: 32.29653,
      lng: -81.22824,
      city: 'Rincon',
      state: 'GA',
    },
    31327: {
      lat: 31.44909,
      lng: -81.25132,
      city: 'Sapelo Island',
      state: 'GA',
    },
    31328: {
      lat: 32.01046,
      lng: -80.89009,
      city: 'Tybee Island',
      state: 'GA',
    },
    31329: {
      lat: 32.4251,
      lng: -81.34314,
      city: 'Springfield',
      state: 'GA',
    },
    31331: {
      lat: 31.5159,
      lng: -81.42042,
      city: 'Townsend',
      state: 'GA',
    },
    31333: {
      lat: 31.76786,
      lng: -81.62758,
      city: 'Walthourville',
      state: 'GA',
    },
    31401: {
      lat: 32.06831,
      lng: -81.09252,
      city: 'Savannah',
      state: 'GA',
    },
    31404: {
      lat: 32.05064,
      lng: -81.05367,
      city: 'Savannah',
      state: 'GA',
    },
    31405: {
      lat: 32.0392,
      lng: -81.17352,
      city: 'Savannah',
      state: 'GA',
    },
    31406: {
      lat: 31.97783,
      lng: -81.08517,
      city: 'Savannah',
      state: 'GA',
    },
    31407: {
      lat: 32.18232,
      lng: -81.19942,
      city: 'Port Wentworth',
      state: 'GA',
    },
    31408: {
      lat: 32.11164,
      lng: -81.18158,
      city: 'Savannah',
      state: 'GA',
    },
    31409: {
      lat: 32.0106,
      lng: -81.15539,
      city: 'Savannah',
      state: 'GA',
    },
    31410: {
      lat: 32.01821,
      lng: -80.99215,
      city: 'Savannah',
      state: 'GA',
    },
    31411: {
      lat: 31.94002,
      lng: -81.03511,
      city: 'Savannah',
      state: 'GA',
    },
    31415: {
      lat: 32.0802,
      lng: -81.126,
      city: 'Savannah',
      state: 'GA',
    },
    31419: {
      lat: 31.98479,
      lng: -81.23867,
      city: 'Savannah',
      state: 'GA',
    },
    31421: {
      lat: 32.08712,
      lng: -81.08016,
      city: 'Savannah',
      state: 'GA',
    },
    31501: {
      lat: 31.22693,
      lng: -82.34852,
      city: 'Waycross',
      state: 'GA',
    },
    31503: {
      lat: 31.17687,
      lng: -82.40173,
      city: 'Waycross',
      state: 'GA',
    },
    31510: { lat: 31.54729, lng: -82.43992, city: 'Alma', state: 'GA' },
    31512: {
      lat: 31.54535,
      lng: -83.01255,
      city: 'Ambrose',
      state: 'GA',
    },
    31513: {
      lat: 31.77792,
      lng: -82.34622,
      city: 'Baxley',
      state: 'GA',
    },
    31516: {
      lat: 31.30946,
      lng: -82.2565,
      city: 'Blackshear',
      state: 'GA',
    },
    31518: {
      lat: 31.51272,
      lng: -82.18325,
      city: 'Bristol',
      state: 'GA',
    },
    31519: {
      lat: 31.67641,
      lng: -82.86946,
      city: 'Broxton',
      state: 'GA',
    },
    31520: {
      lat: 31.17745,
      lng: -81.48729,
      city: 'Brunswick',
      state: 'GA',
    },
    31522: {
      lat: 31.23595,
      lng: -81.35528,
      city: 'Saint Simons Island',
      state: 'GA',
    },
    31523: {
      lat: 31.21113,
      lng: -81.61519,
      city: 'Brunswick',
      state: 'GA',
    },
    31524: {
      lat: 31.24098,
      lng: -81.47449,
      city: 'Brunswick',
      state: 'GA',
    },
    31525: {
      lat: 31.3114,
      lng: -81.5229,
      city: 'Brunswick',
      state: 'GA',
    },
    31527: {
      lat: 31.07155,
      lng: -81.42225,
      city: 'Jekyll Island',
      state: 'GA',
    },
    31532: {
      lat: 31.72462,
      lng: -82.74889,
      city: 'Denton',
      state: 'GA',
    },
    31533: {
      lat: 31.55021,
      lng: -82.8287,
      city: 'Douglas',
      state: 'GA',
    },
    31535: {
      lat: 31.45841,
      lng: -82.86504,
      city: 'Douglas',
      state: 'GA',
    },
    31537: {
      lat: 30.89223,
      lng: -82.12492,
      city: 'Folkston',
      state: 'GA',
    },
    31539: {
      lat: 31.82393,
      lng: -82.60658,
      city: 'Hazlehurst',
      state: 'GA',
    },
    31542: {
      lat: 31.1528,
      lng: -82.12166,
      city: 'Hoboken',
      state: 'GA',
    },
    31543: {
      lat: 31.34455,
      lng: -81.82697,
      city: 'Hortense',
      state: 'GA',
    },
    31544: {
      lat: 31.83802,
      lng: -82.95561,
      city: 'Jacksonville',
      state: 'GA',
    },
    31545: { lat: 31.64867, lng: -81.9405, city: 'Jesup', state: 'GA' },
    31546: { lat: 31.52521, lng: -81.8113, city: 'Jesup', state: 'GA' },
    31547: {
      lat: 30.79096,
      lng: -81.53443,
      city: 'Kings Bay',
      state: 'GA',
    },
    31548: {
      lat: 30.7953,
      lng: -81.71043,
      city: 'Kingsland',
      state: 'GA',
    },
    31549: {
      lat: 31.94503,
      lng: -82.70922,
      city: 'Lumber City',
      state: 'GA',
    },
    31550: {
      lat: 31.13924,
      lng: -82.59575,
      city: 'Manor',
      state: 'GA',
    },
    31551: {
      lat: 31.49223,
      lng: -82.27322,
      city: 'Mershon',
      state: 'GA',
    },
    31552: {
      lat: 31.29144,
      lng: -82.5996,
      city: 'Millwood',
      state: 'GA',
    },
    31553: {
      lat: 31.17307,
      lng: -81.98026,
      city: 'Nahunta',
      state: 'GA',
    },
    31554: {
      lat: 31.48265,
      lng: -82.61861,
      city: 'Nicholls',
      state: 'GA',
    },
    31555: { lat: 31.70275, lng: -82.06647, city: 'Odum', state: 'GA' },
    31556: {
      lat: 31.40508,
      lng: -82.1152,
      city: 'Offerman',
      state: 'GA',
    },
    31557: {
      lat: 31.3745,
      lng: -82.09711,
      city: 'Patterson',
      state: 'GA',
    },
    31558: {
      lat: 30.80173,
      lng: -81.51606,
      city: 'Saint Marys',
      state: 'GA',
    },
    31560: {
      lat: 31.50312,
      lng: -82.02896,
      city: 'Screven',
      state: 'GA',
    },
    31561: {
      lat: 31.20199,
      lng: -81.3373,
      city: 'Sea Island',
      state: 'GA',
    },
    31562: {
      lat: 30.47778,
      lng: -82.10755,
      city: 'Saint George',
      state: 'GA',
    },
    31563: {
      lat: 31.73653,
      lng: -82.18288,
      city: 'Surrency',
      state: 'GA',
    },
    31564: {
      lat: 31.24872,
      lng: -82.47093,
      city: 'Waresboro',
      state: 'GA',
    },
    31565: {
      lat: 31.06868,
      lng: -81.64783,
      city: 'Waverly',
      state: 'GA',
    },
    31566: {
      lat: 31.18357,
      lng: -81.8084,
      city: 'Waynesville',
      state: 'GA',
    },
    31567: {
      lat: 31.63011,
      lng: -82.70705,
      city: 'West Green',
      state: 'GA',
    },
    31568: {
      lat: 31.00975,
      lng: -81.82218,
      city: 'White Oak',
      state: 'GA',
    },
    31569: {
      lat: 30.90167,
      lng: -81.68249,
      city: 'Woodbine',
      state: 'GA',
    },
    31599: {
      lat: 31.56333,
      lng: -81.90271,
      city: 'Jesup',
      state: 'GA',
    },
    31601: {
      lat: 30.74772,
      lng: -83.33968,
      city: 'Valdosta',
      state: 'GA',
    },
    31602: {
      lat: 30.86734,
      lng: -83.34269,
      city: 'Valdosta',
      state: 'GA',
    },
    31605: {
      lat: 30.93334,
      lng: -83.23482,
      city: 'Valdosta',
      state: 'GA',
    },
    31606: {
      lat: 30.7963,
      lng: -83.18647,
      city: 'Valdosta',
      state: 'GA',
    },
    31620: { lat: 31.11861, lng: -83.43731, city: 'Adel', state: 'GA' },
    31622: {
      lat: 31.37446,
      lng: -83.2023,
      city: 'Alapaha',
      state: 'GA',
    },
    31623: { lat: 31.07137, lng: -82.644, city: 'Argyle', state: 'GA' },
    31624: {
      lat: 31.29911,
      lng: -82.72612,
      city: 'Axson',
      state: 'GA',
    },
    31625: {
      lat: 30.9918,
      lng: -83.52556,
      city: 'Barney',
      state: 'GA',
    },
    31626: {
      lat: 30.77609,
      lng: -83.80208,
      city: 'Boston',
      state: 'GA',
    },
    31627: {
      lat: 31.03923,
      lng: -83.39238,
      city: 'Cecil',
      state: 'GA',
    },
    31629: {
      lat: 30.82051,
      lng: -83.68809,
      city: 'Dixie',
      state: 'GA',
    },
    31630: {
      lat: 30.92354,
      lng: -82.85667,
      city: 'Du Pont',
      state: 'GA',
    },
    31631: {
      lat: 30.71119,
      lng: -82.64657,
      city: 'Fargo',
      state: 'GA',
    },
    31632: {
      lat: 30.9876,
      lng: -83.36006,
      city: 'Hahira',
      state: 'GA',
    },
    31634: {
      lat: 31.02867,
      lng: -82.7321,
      city: 'Homerville',
      state: 'GA',
    },
    31635: {
      lat: 31.103,
      lng: -83.04317,
      city: 'Lakeland',
      state: 'GA',
    },
    31636: {
      lat: 30.7337,
      lng: -83.1096,
      city: 'Lake Park',
      state: 'GA',
    },
    31637: { lat: 31.293, lng: -83.44092, city: 'Lenox', state: 'GA' },
    31638: {
      lat: 30.90627,
      lng: -83.51589,
      city: 'Morven',
      state: 'GA',
    },
    31639: {
      lat: 31.21239,
      lng: -83.19248,
      city: 'Nashville',
      state: 'GA',
    },
    31641: {
      lat: 30.93182,
      lng: -83.09672,
      city: 'Naylor',
      state: 'GA',
    },
    31642: {
      lat: 31.25989,
      lng: -82.88273,
      city: 'Pearson',
      state: 'GA',
    },
    31643: {
      lat: 30.77061,
      lng: -83.55859,
      city: 'Quitman',
      state: 'GA',
    },
    31645: {
      lat: 31.06245,
      lng: -83.21846,
      city: 'Ray City',
      state: 'GA',
    },
    31647: {
      lat: 31.21033,
      lng: -83.43158,
      city: 'Sparks',
      state: 'GA',
    },
    31648: {
      lat: 30.70834,
      lng: -83.00838,
      city: 'Statenville',
      state: 'GA',
    },
    31649: {
      lat: 30.94349,
      lng: -83.00781,
      city: 'Stockton',
      state: 'GA',
    },
    31650: {
      lat: 31.38327,
      lng: -83.02761,
      city: 'Willacoochee',
      state: 'GA',
    },
    31698: {
      lat: 30.84749,
      lng: -83.29051,
      city: 'Valdosta',
      state: 'GA',
    },
    31699: {
      lat: 30.96666,
      lng: -83.19237,
      city: 'Moody Afb',
      state: 'GA',
    },
    31701: {
      lat: 31.57376,
      lng: -84.15508,
      city: 'Albany',
      state: 'GA',
    },
    31704: { lat: 31.551, lng: -84.05634, city: 'Albany', state: 'GA' },
    31705: {
      lat: 31.51169,
      lng: -84.05333,
      city: 'Albany',
      state: 'GA',
    },
    31707: {
      lat: 31.58736,
      lng: -84.20689,
      city: 'Albany',
      state: 'GA',
    },
    31709: {
      lat: 32.05071,
      lng: -84.14011,
      city: 'Americus',
      state: 'GA',
    },
    31711: {
      lat: 32.18766,
      lng: -84.12258,
      city: 'Andersonville',
      state: 'GA',
    },
    31712: { lat: 31.8215, lng: -83.72806, city: 'Arabi', state: 'GA' },
    31714: {
      lat: 31.71075,
      lng: -83.67496,
      city: 'Ashburn',
      state: 'GA',
    },
    31716: {
      lat: 31.36512,
      lng: -84.11445,
      city: 'Baconton',
      state: 'GA',
    },
    31719: {
      lat: 32.08261,
      lng: -84.29768,
      city: 'Americus',
      state: 'GA',
    },
    31720: {
      lat: 30.87986,
      lng: -83.72297,
      city: 'Barwick',
      state: 'GA',
    },
    31721: {
      lat: 31.5087,
      lng: -84.30331,
      city: 'Albany',
      state: 'GA',
    },
    31722: {
      lat: 31.05492,
      lng: -83.61559,
      city: 'Berlin',
      state: 'GA',
    },
    31727: {
      lat: 31.41778,
      lng: -83.3874,
      city: 'Brookfield',
      state: 'GA',
    },
    31730: {
      lat: 31.20749,
      lng: -84.28438,
      city: 'Camilla',
      state: 'GA',
    },
    31733: {
      lat: 31.59293,
      lng: -83.48599,
      city: 'Chula',
      state: 'GA',
    },
    31735: { lat: 31.97195, lng: -83.96446, city: 'Cobb', state: 'GA' },
    31738: {
      lat: 31.01133,
      lng: -83.87502,
      city: 'Coolidge',
      state: 'GA',
    },
    31743: {
      lat: 31.90089,
      lng: -84.00604,
      city: 'De Soto',
      state: 'GA',
    },
    31744: {
      lat: 31.33709,
      lng: -83.90646,
      city: 'Doerun',
      state: 'GA',
    },
    31747: {
      lat: 31.1753,
      lng: -83.58736,
      city: 'Ellenton',
      state: 'GA',
    },
    31749: {
      lat: 31.42128,
      lng: -83.3315,
      city: 'Enigma',
      state: 'GA',
    },
    31750: {
      lat: 31.73391,
      lng: -83.21752,
      city: 'Fitzgerald',
      state: 'GA',
    },
    31753: {
      lat: 31.20044,
      lng: -83.87287,
      city: 'Funston',
      state: 'GA',
    },
    31756: {
      lat: 31.17558,
      lng: -83.96224,
      city: 'Hartsfield',
      state: 'GA',
    },
    31757: {
      lat: 30.87291,
      lng: -83.89945,
      city: 'Thomasville',
      state: 'GA',
    },
    31763: {
      lat: 31.73889,
      lng: -84.14711,
      city: 'Leesburg',
      state: 'GA',
    },
    31764: {
      lat: 31.9772,
      lng: -84.08135,
      city: 'Leslie',
      state: 'GA',
    },
    31765: { lat: 31.09514, lng: -84.0346, city: 'Meigs', state: 'GA' },
    31768: {
      lat: 31.19329,
      lng: -83.84047,
      city: 'Moultrie',
      state: 'GA',
    },
    31771: {
      lat: 31.24332,
      lng: -83.64775,
      city: 'Norman Park',
      state: 'GA',
    },
    31772: {
      lat: 31.72213,
      lng: -83.96504,
      city: 'Oakfield',
      state: 'GA',
    },
    31773: {
      lat: 30.97795,
      lng: -84.04624,
      city: 'Ochlocknee',
      state: 'GA',
    },
    31774: {
      lat: 31.56102,
      lng: -83.27125,
      city: 'Ocilla',
      state: 'GA',
    },
    31775: {
      lat: 31.33418,
      lng: -83.60366,
      city: 'Omega',
      state: 'GA',
    },
    31778: { lat: 30.9754, lng: -83.70931, city: 'Pavo', state: 'GA' },
    31779: {
      lat: 31.10032,
      lng: -84.19117,
      city: 'Pelham',
      state: 'GA',
    },
    31780: {
      lat: 32.02898,
      lng: -84.39024,
      city: 'Plains',
      state: 'GA',
    },
    31781: {
      lat: 31.56062,
      lng: -83.79316,
      city: 'Poulan',
      state: 'GA',
    },
    31783: {
      lat: 31.76416,
      lng: -83.4738,
      city: 'Rebecca',
      state: 'GA',
    },
    31784: {
      lat: 31.25671,
      lng: -84.0431,
      city: 'Sale City',
      state: 'GA',
    },
    31787: {
      lat: 31.89994,
      lng: -84.24947,
      city: 'Smithville',
      state: 'GA',
    },
    31788: {
      lat: 31.10891,
      lng: -83.68099,
      city: 'Moultrie',
      state: 'GA',
    },
    31789: {
      lat: 31.47858,
      lng: -83.73257,
      city: 'Sumner',
      state: 'GA',
    },
    31790: {
      lat: 31.64823,
      lng: -83.5693,
      city: 'Sycamore',
      state: 'GA',
    },
    31791: {
      lat: 31.53383,
      lng: -83.87833,
      city: 'Sylvester',
      state: 'GA',
    },
    31792: {
      lat: 30.77079,
      lng: -84.04238,
      city: 'Thomasville',
      state: 'GA',
    },
    31793: {
      lat: 31.46104,
      lng: -83.58386,
      city: 'Tifton',
      state: 'GA',
    },
    31794: {
      lat: 31.44418,
      lng: -83.45151,
      city: 'Tifton',
      state: 'GA',
    },
    31795: {
      lat: 31.47611,
      lng: -83.67633,
      city: 'Ty Ty',
      state: 'GA',
    },
    31796: {
      lat: 31.77351,
      lng: -83.87659,
      city: 'Warwick',
      state: 'GA',
    },
    31798: { lat: 31.64117, lng: -83.04333, city: 'Wray', state: 'GA' },
    31801: {
      lat: 32.51765,
      lng: -84.5906,
      city: 'Box Springs',
      state: 'GA',
    },
    31803: {
      lat: 32.30501,
      lng: -84.51682,
      city: 'Buena Vista',
      state: 'GA',
    },
    31804: {
      lat: 32.66199,
      lng: -84.88402,
      city: 'Cataula',
      state: 'GA',
    },
    31805: {
      lat: 32.26621,
      lng: -84.74612,
      city: 'Cusseta',
      state: 'GA',
    },
    31806: {
      lat: 32.26147,
      lng: -84.30043,
      city: 'Ellaville',
      state: 'GA',
    },
    31807: {
      lat: 32.64054,
      lng: -84.80142,
      city: 'Ellerslie',
      state: 'GA',
    },
    31808: {
      lat: 32.63545,
      lng: -85.00707,
      city: 'Fortson',
      state: 'GA',
    },
    31810: {
      lat: 32.56775,
      lng: -84.53236,
      city: 'Geneva',
      state: 'GA',
    },
    31811: {
      lat: 32.74522,
      lng: -84.93327,
      city: 'Hamilton',
      state: 'GA',
    },
    31812: {
      lat: 32.63173,
      lng: -84.42019,
      city: 'Junction City',
      state: 'GA',
    },
    31814: {
      lat: 32.20531,
      lng: -84.8614,
      city: 'Louvale',
      state: 'GA',
    },
    31815: {
      lat: 32.04117,
      lng: -84.85407,
      city: 'Lumpkin',
      state: 'GA',
    },
    31816: {
      lat: 32.8762,
      lng: -84.60169,
      city: 'Manchester',
      state: 'GA',
    },
    31820: {
      lat: 32.57576,
      lng: -84.82947,
      city: 'Midland',
      state: 'GA',
    },
    31821: {
      lat: 32.14048,
      lng: -84.97083,
      city: 'Omaha',
      state: 'GA',
    },
    31822: {
      lat: 32.86708,
      lng: -84.91107,
      city: 'Pine Mountain',
      state: 'GA',
    },
    31823: {
      lat: 32.80061,
      lng: -84.81377,
      city: 'Pine Mountain Valley',
      state: 'GA',
    },
    31824: {
      lat: 32.05687,
      lng: -84.5229,
      city: 'Preston',
      state: 'GA',
    },
    31825: {
      lat: 32.1081,
      lng: -84.6693,
      city: 'Richland',
      state: 'GA',
    },
    31826: {
      lat: 32.79526,
      lng: -84.70292,
      city: 'Shiloh',
      state: 'GA',
    },
    31827: {
      lat: 32.69821,
      lng: -84.49847,
      city: 'Talbotton',
      state: 'GA',
    },
    31829: {
      lat: 32.56681,
      lng: -84.72787,
      city: 'Upatoi',
      state: 'GA',
    },
    31830: {
      lat: 32.8951,
      lng: -84.72689,
      city: 'Warm Springs',
      state: 'GA',
    },
    31831: {
      lat: 32.66486,
      lng: -84.70701,
      city: 'Waverly Hall',
      state: 'GA',
    },
    31832: {
      lat: 31.95501,
      lng: -84.61709,
      city: 'Weston',
      state: 'GA',
    },
    31833: {
      lat: 32.84963,
      lng: -85.09202,
      city: 'West Point',
      state: 'GA',
    },
    31836: {
      lat: 32.79189,
      lng: -84.54891,
      city: 'Woodland',
      state: 'GA',
    },
    31901: {
      lat: 32.46461,
      lng: -84.97986,
      city: 'Columbus',
      state: 'GA',
    },
    31903: {
      lat: 32.41484,
      lng: -84.95321,
      city: 'Columbus',
      state: 'GA',
    },
    31904: {
      lat: 32.55189,
      lng: -85.00009,
      city: 'Columbus',
      state: 'GA',
    },
    31905: {
      lat: 32.37304,
      lng: -84.84331,
      city: 'Fort Moore',
      state: 'GA',
    },
    31906: {
      lat: 32.46815,
      lng: -84.94963,
      city: 'Columbus',
      state: 'GA',
    },
    31907: {
      lat: 32.48388,
      lng: -84.89976,
      city: 'Columbus',
      state: 'GA',
    },
    31909: {
      lat: 32.54517,
      lng: -84.92385,
      city: 'Columbus',
      state: 'GA',
    },
    32003: {
      lat: 30.09813,
      lng: -81.71255,
      city: 'Fleming Island',
      state: 'FL',
    },
    32008: {
      lat: 29.90351,
      lng: -82.90311,
      city: 'Branford',
      state: 'FL',
    },
    32009: {
      lat: 30.42078,
      lng: -81.97252,
      city: 'Bryceville',
      state: 'FL',
    },
    32011: {
      lat: 30.56985,
      lng: -81.83975,
      city: 'Callahan',
      state: 'FL',
    },
    32013: { lat: 30.19366, lng: -83.29116, city: 'Day', state: 'FL' },
    32024: {
      lat: 30.0793,
      lng: -82.72597,
      city: 'Lake City',
      state: 'FL',
    },
    32025: {
      lat: 30.11266,
      lng: -82.57072,
      city: 'Lake City',
      state: 'FL',
    },
    32033: {
      lat: 29.78851,
      lng: -81.44263,
      city: 'Elkton',
      state: 'FL',
    },
    32034: {
      lat: 30.624,
      lng: -81.49392,
      city: 'Fernandina Beach',
      state: 'FL',
    },
    32038: {
      lat: 29.93617,
      lng: -82.69924,
      city: 'Fort White',
      state: 'FL',
    },
    32040: {
      lat: 30.24696,
      lng: -82.23531,
      city: 'Glen Saint Mary',
      state: 'FL',
    },
    32043: {
      lat: 29.92991,
      lng: -81.74304,
      city: 'Green Cove Springs',
      state: 'FL',
    },
    32044: {
      lat: 29.85344,
      lng: -82.16425,
      city: 'Hampton',
      state: 'FL',
    },
    32046: {
      lat: 30.70253,
      lng: -81.91718,
      city: 'Hilliard',
      state: 'FL',
    },
    32052: {
      lat: 30.48683,
      lng: -82.94499,
      city: 'Jasper',
      state: 'FL',
    },
    32053: {
      lat: 30.56417,
      lng: -83.1519,
      city: 'Jennings',
      state: 'FL',
    },
    32054: {
      lat: 30.0281,
      lng: -82.39143,
      city: 'Lake Butler',
      state: 'FL',
    },
    32055: {
      lat: 30.27031,
      lng: -82.62507,
      city: 'Lake City',
      state: 'FL',
    },
    32058: {
      lat: 30.07988,
      lng: -82.10743,
      city: 'Lawtey',
      state: 'FL',
    },
    32059: { lat: 30.38672, lng: -83.27066, city: 'Lee', state: 'FL' },
    32060: {
      lat: 30.27554,
      lng: -83.04935,
      city: 'Live Oak',
      state: 'FL',
    },
    32061: { lat: 30.08311, lng: -82.51666, city: 'Lulu', state: 'FL' },
    32062: {
      lat: 30.13276,
      lng: -82.9754,
      city: 'McAlpin',
      state: 'FL',
    },
    32063: {
      lat: 30.27045,
      lng: -82.11974,
      city: 'Macclenny',
      state: 'FL',
    },
    32064: {
      lat: 30.28907,
      lng: -83.02375,
      city: 'Live Oak',
      state: 'FL',
    },
    32065: {
      lat: 30.15613,
      lng: -81.81762,
      city: 'Orange Park',
      state: 'FL',
    },
    32066: { lat: 30.04423, lng: -83.22495, city: 'Mayo', state: 'FL' },
    32068: {
      lat: 30.08218,
      lng: -81.88245,
      city: 'Middleburg',
      state: 'FL',
    },
    32071: {
      lat: 30.04097,
      lng: -82.945,
      city: "O'Brien",
      state: 'FL',
    },
    32073: {
      lat: 30.16766,
      lng: -81.74069,
      city: 'Orange Park',
      state: 'FL',
    },
    32079: {
      lat: 29.97814,
      lng: -81.80563,
      city: 'Penney Farms',
      state: 'FL',
    },
    32080: {
      lat: 29.82453,
      lng: -81.27412,
      city: 'Saint Augustine',
      state: 'FL',
    },
    32081: {
      lat: 30.12143,
      lng: -81.42089,
      city: 'Ponte Vedra',
      state: 'FL',
    },
    32082: {
      lat: 30.12488,
      lng: -81.36577,
      city: 'Ponte Vedra Beach',
      state: 'FL',
    },
    32083: {
      lat: 30.09074,
      lng: -82.23222,
      city: 'Raiford',
      state: 'FL',
    },
    32084: {
      lat: 29.91412,
      lng: -81.36417,
      city: 'Saint Augustine',
      state: 'FL',
    },
    32086: {
      lat: 29.75656,
      lng: -81.30282,
      city: 'Saint Augustine',
      state: 'FL',
    },
    32087: {
      lat: 30.36999,
      lng: -82.29741,
      city: 'Sanderson',
      state: 'FL',
    },
    32091: {
      lat: 29.93205,
      lng: -82.11324,
      city: 'Starke',
      state: 'FL',
    },
    32092: {
      lat: 29.92849,
      lng: -81.52209,
      city: 'Saint Augustine',
      state: 'FL',
    },
    32094: {
      lat: 30.19797,
      lng: -82.82767,
      city: 'Wellborn',
      state: 'FL',
    },
    32095: {
      lat: 30.01693,
      lng: -81.40927,
      city: 'Saint Augustine',
      state: 'FL',
    },
    32096: {
      lat: 30.43678,
      lng: -82.7011,
      city: 'White Springs',
      state: 'FL',
    },
    32097: {
      lat: 30.65851,
      lng: -81.64478,
      city: 'Yulee',
      state: 'FL',
    },
    32102: { lat: 29.1359, lng: -81.52544, city: 'Astor', state: 'FL' },
    32110: {
      lat: 29.44456,
      lng: -81.36131,
      city: 'Bunnell',
      state: 'FL',
    },
    32112: {
      lat: 29.42664,
      lng: -81.55649,
      city: 'Crescent City',
      state: 'FL',
    },
    32113: {
      lat: 29.39836,
      lng: -82.08127,
      city: 'Citra',
      state: 'FL',
    },
    32114: {
      lat: 29.19312,
      lng: -81.04915,
      city: 'Daytona Beach',
      state: 'FL',
    },
    32117: {
      lat: 29.23351,
      lng: -81.06727,
      city: 'Daytona Beach',
      state: 'FL',
    },
    32118: {
      lat: 29.21035,
      lng: -81.00425,
      city: 'Daytona Beach',
      state: 'FL',
    },
    32119: {
      lat: 29.16001,
      lng: -81.02707,
      city: 'Daytona Beach',
      state: 'FL',
    },
    32124: {
      lat: 29.15802,
      lng: -81.15732,
      city: 'Daytona Beach',
      state: 'FL',
    },
    32127: {
      lat: 29.10866,
      lng: -80.97907,
      city: 'Port Orange',
      state: 'FL',
    },
    32128: {
      lat: 29.09773,
      lng: -81.07467,
      city: 'Port Orange',
      state: 'FL',
    },
    32129: {
      lat: 29.13721,
      lng: -81.02381,
      city: 'Port Orange',
      state: 'FL',
    },
    32130: {
      lat: 29.15704,
      lng: -81.34501,
      city: 'De Leon Springs',
      state: 'FL',
    },
    32131: {
      lat: 29.67803,
      lng: -81.56785,
      city: 'East Palatka',
      state: 'FL',
    },
    32132: {
      lat: 28.97731,
      lng: -80.92345,
      city: 'Edgewater',
      state: 'FL',
    },
    32133: {
      lat: 29.01061,
      lng: -81.92036,
      city: 'Eastlake Weir',
      state: 'FL',
    },
    32134: {
      lat: 29.37177,
      lng: -81.82562,
      city: 'Fort McCoy',
      state: 'FL',
    },
    32136: {
      lat: 29.45252,
      lng: -81.14025,
      city: 'Flagler Beach',
      state: 'FL',
    },
    32137: {
      lat: 29.58057,
      lng: -81.22455,
      city: 'Palm Coast',
      state: 'FL',
    },
    32139: {
      lat: 29.37693,
      lng: -81.61431,
      city: 'Georgetown',
      state: 'FL',
    },
    32140: {
      lat: 29.76274,
      lng: -81.86306,
      city: 'Florahome',
      state: 'FL',
    },
    32141: {
      lat: 28.92322,
      lng: -80.90196,
      city: 'Edgewater',
      state: 'FL',
    },
    32145: {
      lat: 29.67899,
      lng: -81.41973,
      city: 'Hastings',
      state: 'FL',
    },
    32147: {
      lat: 29.63157,
      lng: -81.79577,
      city: 'Hollister',
      state: 'FL',
    },
    32148: {
      lat: 29.60088,
      lng: -81.88191,
      city: 'Interlachen',
      state: 'FL',
    },
    32157: {
      lat: 29.46296,
      lng: -81.58165,
      city: 'Lake Como',
      state: 'FL',
    },
    32159: {
      lat: 28.92456,
      lng: -81.90528,
      city: 'Lady Lake',
      state: 'FL',
    },
    32162: {
      lat: 28.91629,
      lng: -81.99374,
      city: 'The Villages',
      state: 'FL',
    },
    32163: {
      lat: 28.81878,
      lng: -81.98663,
      city: 'The Villages',
      state: 'FL',
    },
    32164: {
      lat: 29.48316,
      lng: -81.2174,
      city: 'Palm Coast',
      state: 'FL',
    },
    32168: {
      lat: 28.98214,
      lng: -81.03721,
      city: 'New Smyrna Beach',
      state: 'FL',
    },
    32169: {
      lat: 28.97934,
      lng: -80.87386,
      city: 'New Smyrna Beach',
      state: 'FL',
    },
    32174: {
      lat: 29.28928,
      lng: -81.16446,
      city: 'Ormond Beach',
      state: 'FL',
    },
    32176: {
      lat: 29.33216,
      lng: -81.06327,
      city: 'Ormond Beach',
      state: 'FL',
    },
    32177: {
      lat: 29.69011,
      lng: -81.70565,
      city: 'Palatka',
      state: 'FL',
    },
    32179: {
      lat: 29.09631,
      lng: -81.89686,
      city: 'Ocklawaha',
      state: 'FL',
    },
    32180: {
      lat: 29.23437,
      lng: -81.44521,
      city: 'Pierson',
      state: 'FL',
    },
    32181: {
      lat: 29.50658,
      lng: -81.60126,
      city: 'Pomona Park',
      state: 'FL',
    },
    32182: {
      lat: 29.50926,
      lng: -81.94113,
      city: 'Orange Springs',
      state: 'FL',
    },
    32187: {
      lat: 29.57827,
      lng: -81.55392,
      city: 'San Mateo',
      state: 'FL',
    },
    32189: {
      lat: 29.54991,
      lng: -81.64819,
      city: 'Satsuma',
      state: 'FL',
    },
    32190: {
      lat: 29.33289,
      lng: -81.49056,
      city: 'Seville',
      state: 'FL',
    },
    32192: {
      lat: 29.35005,
      lng: -82.11109,
      city: 'Sparr',
      state: 'FL',
    },
    32193: {
      lat: 29.47605,
      lng: -81.6647,
      city: 'Welaka',
      state: 'FL',
    },
    32195: {
      lat: 28.9854,
      lng: -81.89814,
      city: 'Weirsdale',
      state: 'FL',
    },
    32202: {
      lat: 30.32505,
      lng: -81.64786,
      city: 'Jacksonville',
      state: 'FL',
    },
    32203: {
      lat: 30.33702,
      lng: -81.67129,
      city: 'Jacksonville',
      state: 'FL',
    },
    32204: {
      lat: 30.31636,
      lng: -81.68095,
      city: 'Jacksonville',
      state: 'FL',
    },
    32205: {
      lat: 30.30275,
      lng: -81.72257,
      city: 'Jacksonville',
      state: 'FL',
    },
    32206: {
      lat: 30.35069,
      lng: -81.63865,
      city: 'Jacksonville',
      state: 'FL',
    },
    32207: {
      lat: 30.29,
      lng: -81.64018,
      city: 'Jacksonville',
      state: 'FL',
    },
    32208: {
      lat: 30.39351,
      lng: -81.68245,
      city: 'Jacksonville',
      state: 'FL',
    },
    32209: {
      lat: 30.36141,
      lng: -81.69573,
      city: 'Jacksonville',
      state: 'FL',
    },
    32210: {
      lat: 30.26491,
      lng: -81.74746,
      city: 'Jacksonville',
      state: 'FL',
    },
    32211: {
      lat: 30.33276,
      lng: -81.58428,
      city: 'Jacksonville',
      state: 'FL',
    },
    32212: {
      lat: 30.21916,
      lng: -81.67869,
      city: 'Jacksonville',
      state: 'FL',
    },
    32214: {
      lat: 30.20749,
      lng: -81.68334,
      city: 'Jacksonville',
      state: 'FL',
    },
    32216: {
      lat: 30.27891,
      lng: -81.58305,
      city: 'Jacksonville',
      state: 'FL',
    },
    32217: {
      lat: 30.2394,
      lng: -81.62722,
      city: 'Jacksonville',
      state: 'FL',
    },
    32218: {
      lat: 30.48673,
      lng: -81.66959,
      city: 'Jacksonville',
      state: 'FL',
    },
    32219: {
      lat: 30.41815,
      lng: -81.81367,
      city: 'Jacksonville',
      state: 'FL',
    },
    32220: {
      lat: 30.33693,
      lng: -81.85335,
      city: 'Jacksonville',
      state: 'FL',
    },
    32221: {
      lat: 30.26043,
      lng: -81.86764,
      city: 'Jacksonville',
      state: 'FL',
    },
    32222: {
      lat: 30.21651,
      lng: -81.8293,
      city: 'Jacksonville',
      state: 'FL',
    },
    32223: {
      lat: 30.1549,
      lng: -81.64821,
      city: 'Jacksonville',
      state: 'FL',
    },
    32224: {
      lat: 30.26815,
      lng: -81.4642,
      city: 'Jacksonville',
      state: 'FL',
    },
    32225: {
      lat: 30.35768,
      lng: -81.50592,
      city: 'Jacksonville',
      state: 'FL',
    },
    32226: {
      lat: 30.46444,
      lng: -81.50999,
      city: 'Jacksonville',
      state: 'FL',
    },
    32227: {
      lat: 30.38792,
      lng: -81.40571,
      city: 'Jacksonville',
      state: 'FL',
    },
    32228: {
      lat: 30.39158,
      lng: -81.40022,
      city: 'Jacksonville',
      state: 'FL',
    },
    32233: {
      lat: 30.35943,
      lng: -81.42098,
      city: 'Atlantic Beach',
      state: 'FL',
    },
    32234: {
      lat: 30.20784,
      lng: -82.01096,
      city: 'Jacksonville',
      state: 'FL',
    },
    32244: {
      lat: 30.2171,
      lng: -81.75384,
      city: 'Jacksonville',
      state: 'FL',
    },
    32246: {
      lat: 30.29422,
      lng: -81.51782,
      city: 'Jacksonville',
      state: 'FL',
    },
    32250: {
      lat: 30.27846,
      lng: -81.41035,
      city: 'Jacksonville Beach',
      state: 'FL',
    },
    32254: {
      lat: 30.34164,
      lng: -81.73549,
      city: 'Jacksonville',
      state: 'FL',
    },
    32256: {
      lat: 30.1929,
      lng: -81.50309,
      city: 'Jacksonville',
      state: 'FL',
    },
    32257: {
      lat: 30.19515,
      lng: -81.6123,
      city: 'Jacksonville',
      state: 'FL',
    },
    32258: {
      lat: 30.13874,
      lng: -81.54941,
      city: 'Jacksonville',
      state: 'FL',
    },
    32259: {
      lat: 30.07109,
      lng: -81.6029,
      city: 'Saint Johns',
      state: 'FL',
    },
    32266: {
      lat: 30.31648,
      lng: -81.41177,
      city: 'Neptune Beach',
      state: 'FL',
    },
    32277: {
      lat: 30.3728,
      lng: -81.59465,
      city: 'Jacksonville',
      state: 'FL',
    },
    32301: {
      lat: 30.42701,
      lng: -84.25799,
      city: 'Tallahassee',
      state: 'FL',
    },
    32303: {
      lat: 30.51237,
      lng: -84.34386,
      city: 'Tallahassee',
      state: 'FL',
    },
    32304: {
      lat: 30.45346,
      lng: -84.35314,
      city: 'Tallahassee',
      state: 'FL',
    },
    32305: {
      lat: 30.3337,
      lng: -84.26977,
      city: 'Tallahassee',
      state: 'FL',
    },
    32306: {
      lat: 30.44227,
      lng: -84.29455,
      city: 'Tallahassee',
      state: 'FL',
    },
    32307: {
      lat: 30.42173,
      lng: -84.28686,
      city: 'Tallahassee',
      state: 'FL',
    },
    32308: {
      lat: 30.4781,
      lng: -84.21968,
      city: 'Tallahassee',
      state: 'FL',
    },
    32309: {
      lat: 30.57174,
      lng: -84.10128,
      city: 'Tallahassee',
      state: 'FL',
    },
    32310: {
      lat: 30.39924,
      lng: -84.50694,
      city: 'Tallahassee',
      state: 'FL',
    },
    32311: {
      lat: 30.39647,
      lng: -84.16843,
      city: 'Tallahassee',
      state: 'FL',
    },
    32312: {
      lat: 30.60421,
      lng: -84.23996,
      city: 'Tallahassee',
      state: 'FL',
    },
    32313: {
      lat: 30.44168,
      lng: -84.30287,
      city: 'Tallahassee',
      state: 'FL',
    },
    32317: {
      lat: 30.46746,
      lng: -84.11442,
      city: 'Tallahassee',
      state: 'FL',
    },
    32320: {
      lat: 29.74232,
      lng: -85.06938,
      city: 'Apalachicola',
      state: 'FL',
    },
    32321: {
      lat: 30.26196,
      lng: -84.9891,
      city: 'Bristol',
      state: 'FL',
    },
    32322: {
      lat: 29.89948,
      lng: -84.62327,
      city: 'Carrabelle',
      state: 'FL',
    },
    32323: {
      lat: 29.89185,
      lng: -84.6122,
      city: 'Lanark Village',
      state: 'FL',
    },
    32324: {
      lat: 30.65635,
      lng: -84.79126,
      city: 'Chattahoochee',
      state: 'FL',
    },
    32327: {
      lat: 30.18705,
      lng: -84.30472,
      city: 'Crawfordville',
      state: 'FL',
    },
    32328: {
      lat: 29.8463,
      lng: -84.90991,
      city: 'Eastpoint',
      state: 'FL',
    },
    32330: {
      lat: 30.57859,
      lng: -84.74799,
      city: 'Greensboro',
      state: 'FL',
    },
    32331: {
      lat: 30.45851,
      lng: -83.6294,
      city: 'Greenville',
      state: 'FL',
    },
    32332: {
      lat: 30.61168,
      lng: -84.6657,
      city: 'Gretna',
      state: 'FL',
    },
    32333: {
      lat: 30.60677,
      lng: -84.41249,
      city: 'Havana',
      state: 'FL',
    },
    32334: {
      lat: 30.2612,
      lng: -84.75835,
      city: 'Hosford',
      state: 'FL',
    },
    32336: {
      lat: 30.26371,
      lng: -83.86319,
      city: 'Lamont',
      state: 'FL',
    },
    32337: {
      lat: 30.48307,
      lng: -84.02104,
      city: 'Lloyd',
      state: 'FL',
    },
    32340: {
      lat: 30.45651,
      lng: -83.42667,
      city: 'Madison',
      state: 'FL',
    },
    32343: {
      lat: 30.48515,
      lng: -84.47652,
      city: 'Midway',
      state: 'FL',
    },
    32344: {
      lat: 30.44645,
      lng: -83.91902,
      city: 'Monticello',
      state: 'FL',
    },
    32346: {
      lat: 29.97919,
      lng: -84.38387,
      city: 'Panacea',
      state: 'FL',
    },
    32347: {
      lat: 30.14416,
      lng: -83.64268,
      city: 'Perry',
      state: 'FL',
    },
    32348: {
      lat: 29.94808,
      lng: -83.56762,
      city: 'Perry',
      state: 'FL',
    },
    32350: {
      lat: 30.58662,
      lng: -83.32242,
      city: 'Pinetta',
      state: 'FL',
    },
    32351: {
      lat: 30.52358,
      lng: -84.68362,
      city: 'Quincy',
      state: 'FL',
    },
    32352: {
      lat: 30.6499,
      lng: -84.58535,
      city: 'Quincy',
      state: 'FL',
    },
    32355: {
      lat: 30.1695,
      lng: -84.21002,
      city: 'Saint Marks',
      state: 'FL',
    },
    32356: {
      lat: 29.85387,
      lng: -83.40018,
      city: 'Salem',
      state: 'FL',
    },
    32358: {
      lat: 30.09535,
      lng: -84.54468,
      city: 'Sopchoppy',
      state: 'FL',
    },
    32359: {
      lat: 29.66181,
      lng: -83.37047,
      city: 'Steinhatchee',
      state: 'FL',
    },
    32360: {
      lat: 30.36506,
      lng: -84.81238,
      city: 'Telogia',
      state: 'FL',
    },
    32361: {
      lat: 30.35765,
      lng: -83.98865,
      city: 'Wacissa',
      state: 'FL',
    },
    32399: {
      lat: 30.40162,
      lng: -84.24426,
      city: 'Tallahassee',
      state: 'FL',
    },
    32401: {
      lat: 30.14477,
      lng: -85.67448,
      city: 'Panama City',
      state: 'FL',
    },
    32403: {
      lat: 30.05337,
      lng: -85.55679,
      city: 'Panama City',
      state: 'FL',
    },
    32404: {
      lat: 30.19075,
      lng: -85.51122,
      city: 'Panama City',
      state: 'FL',
    },
    32405: {
      lat: 30.20002,
      lng: -85.66869,
      city: 'Panama City',
      state: 'FL',
    },
    32407: {
      lat: 30.20276,
      lng: -85.7912,
      city: 'Panama City Beach',
      state: 'FL',
    },
    32408: {
      lat: 30.1593,
      lng: -85.75324,
      city: 'Panama City',
      state: 'FL',
    },
    32409: {
      lat: 30.33447,
      lng: -85.70516,
      city: 'Panama City',
      state: 'FL',
    },
    32410: {
      lat: 29.94805,
      lng: -85.41812,
      city: 'Mexico Beach',
      state: 'FL',
    },
    32413: {
      lat: 30.30975,
      lng: -85.88745,
      city: 'Panama City Beach',
      state: 'FL',
    },
    32420: {
      lat: 30.63637,
      lng: -85.38123,
      city: 'Alford',
      state: 'FL',
    },
    32421: {
      lat: 30.52973,
      lng: -85.19308,
      city: 'Altha',
      state: 'FL',
    },
    32423: {
      lat: 30.94496,
      lng: -85.06073,
      city: 'Bascom',
      state: 'FL',
    },
    32424: {
      lat: 30.38901,
      lng: -85.08232,
      city: 'Blountstown',
      state: 'FL',
    },
    32425: {
      lat: 30.85538,
      lng: -85.72225,
      city: 'Bonifay',
      state: 'FL',
    },
    32426: {
      lat: 30.94861,
      lng: -85.36714,
      city: 'Campbellton',
      state: 'FL',
    },
    32427: {
      lat: 30.71587,
      lng: -85.79311,
      city: 'Caryville',
      state: 'FL',
    },
    32428: {
      lat: 30.61797,
      lng: -85.57154,
      city: 'Chipley',
      state: 'FL',
    },
    32430: {
      lat: 30.41872,
      lng: -85.22562,
      city: 'Clarksville',
      state: 'FL',
    },
    32431: {
      lat: 30.78609,
      lng: -85.40982,
      city: 'Cottondale',
      state: 'FL',
    },
    32432: {
      lat: 30.71415,
      lng: -85.07727,
      city: 'Cypress',
      state: 'FL',
    },
    32433: {
      lat: 30.84848,
      lng: -86.19822,
      city: 'Defuniak Springs',
      state: 'FL',
    },
    32435: {
      lat: 30.65367,
      lng: -86.11597,
      city: 'Defuniak Springs',
      state: 'FL',
    },
    32437: { lat: 30.43315, lng: -85.90694, city: 'Ebro', state: 'FL' },
    32438: {
      lat: 30.51251,
      lng: -85.41744,
      city: 'Fountain',
      state: 'FL',
    },
    32439: {
      lat: 30.49082,
      lng: -86.12933,
      city: 'Freeport',
      state: 'FL',
    },
    32440: {
      lat: 30.92025,
      lng: -85.52588,
      city: 'Graceville',
      state: 'FL',
    },
    32442: {
      lat: 30.68531,
      lng: -85.00829,
      city: 'Grand Ridge',
      state: 'FL',
    },
    32443: {
      lat: 30.86586,
      lng: -85.11695,
      city: 'Greenwood',
      state: 'FL',
    },
    32444: {
      lat: 30.23854,
      lng: -85.65185,
      city: 'Lynn Haven',
      state: 'FL',
    },
    32445: {
      lat: 30.96924,
      lng: -85.19504,
      city: 'Malone',
      state: 'FL',
    },
    32446: {
      lat: 30.84493,
      lng: -85.23017,
      city: 'Marianna',
      state: 'FL',
    },
    32448: {
      lat: 30.66663,
      lng: -85.21616,
      city: 'Marianna',
      state: 'FL',
    },
    32449: {
      lat: 30.27611,
      lng: -85.20607,
      city: 'Wewahitchka',
      state: 'FL',
    },
    32455: {
      lat: 30.63831,
      lng: -85.96395,
      city: 'Ponce De Leon',
      state: 'FL',
    },
    32456: {
      lat: 29.84586,
      lng: -85.3188,
      city: 'Port Saint Joe',
      state: 'FL',
    },
    32459: {
      lat: 30.36527,
      lng: -86.18429,
      city: 'Santa Rosa Beach',
      state: 'FL',
    },
    32460: {
      lat: 30.77565,
      lng: -84.94949,
      city: 'Sneads',
      state: 'FL',
    },
    32461: {
      lat: 30.30905,
      lng: -86.03508,
      city: 'Inlet Beach',
      state: 'FL',
    },
    32462: {
      lat: 30.55753,
      lng: -85.83241,
      city: 'Vernon',
      state: 'FL',
    },
    32463: {
      lat: 30.6279,
      lng: -85.58728,
      city: 'Wausau',
      state: 'FL',
    },
    32464: {
      lat: 30.89687,
      lng: -85.95592,
      city: 'Westville',
      state: 'FL',
    },
    32465: {
      lat: 30.00239,
      lng: -85.18615,
      city: 'Wewahitchka',
      state: 'FL',
    },
    32466: {
      lat: 30.38618,
      lng: -85.464,
      city: 'Youngstown',
      state: 'FL',
    },
    32501: {
      lat: 30.42826,
      lng: -87.22277,
      city: 'Pensacola',
      state: 'FL',
    },
    32502: {
      lat: 30.40928,
      lng: -87.22636,
      city: 'Pensacola',
      state: 'FL',
    },
    32503: {
      lat: 30.46022,
      lng: -87.21372,
      city: 'Pensacola',
      state: 'FL',
    },
    32504: {
      lat: 30.48495,
      lng: -87.19093,
      city: 'Pensacola',
      state: 'FL',
    },
    32505: {
      lat: 30.45575,
      lng: -87.26082,
      city: 'Pensacola',
      state: 'FL',
    },
    32506: {
      lat: 30.39152,
      lng: -87.37167,
      city: 'Pensacola',
      state: 'FL',
    },
    32507: {
      lat: 30.34035,
      lng: -87.37706,
      city: 'Pensacola',
      state: 'FL',
    },
    32508: {
      lat: 30.35614,
      lng: -87.29866,
      city: 'Pensacola',
      state: 'FL',
    },
    32509: {
      lat: 30.47096,
      lng: -87.34115,
      city: 'Pensacola',
      state: 'FL',
    },
    32511: {
      lat: 30.4064,
      lng: -87.29129,
      city: 'Pensacola',
      state: 'FL',
    },
    32512: {
      lat: 30.399,
      lng: -87.29925,
      city: 'Pensacola',
      state: 'FL',
    },
    32514: {
      lat: 30.53209,
      lng: -87.22235,
      city: 'Pensacola',
      state: 'FL',
    },
    32526: {
      lat: 30.49824,
      lng: -87.36543,
      city: 'Pensacola',
      state: 'FL',
    },
    32530: {
      lat: 30.5926,
      lng: -87.02344,
      city: 'Bagdad',
      state: 'FL',
    },
    32531: { lat: 30.88513, lng: -86.696, city: 'Baker', state: 'FL' },
    32533: {
      lat: 30.61329,
      lng: -87.32561,
      city: 'Cantonment',
      state: 'FL',
    },
    32534: {
      lat: 30.53276,
      lng: -87.28374,
      city: 'Pensacola',
      state: 'FL',
    },
    32535: {
      lat: 30.96515,
      lng: -87.34673,
      city: 'Century',
      state: 'FL',
    },
    32536: {
      lat: 30.75399,
      lng: -86.59491,
      city: 'Crestview',
      state: 'FL',
    },
    32539: {
      lat: 30.78128,
      lng: -86.46646,
      city: 'Crestview',
      state: 'FL',
    },
    32541: {
      lat: 30.39666,
      lng: -86.47486,
      city: 'Destin',
      state: 'FL',
    },
    32542: {
      lat: 30.58743,
      lng: -86.59296,
      city: 'Eglin Afb',
      state: 'FL',
    },
    32544: {
      lat: 30.42801,
      lng: -86.70441,
      city: 'Hurlburt Field',
      state: 'FL',
    },
    32547: {
      lat: 30.46649,
      lng: -86.65513,
      city: 'Fort Walton Beach',
      state: 'FL',
    },
    32548: {
      lat: 30.4094,
      lng: -86.6494,
      city: 'Fort Walton Beach',
      state: 'FL',
    },
    32550: {
      lat: 30.38541,
      lng: -86.34438,
      city: 'Miramar Beach',
      state: 'FL',
    },
    32561: {
      lat: 30.35165,
      lng: -87.10906,
      city: 'Gulf Breeze',
      state: 'FL',
    },
    32563: {
      lat: 30.38782,
      lng: -87.0331,
      city: 'Gulf Breeze',
      state: 'FL',
    },
    32564: { lat: 30.72755, lng: -86.7875, city: 'Holt', state: 'FL' },
    32565: { lat: 30.89241, lng: -87.14071, city: 'Jay', state: 'FL' },
    32566: {
      lat: 30.4253,
      lng: -86.8843,
      city: 'Navarre',
      state: 'FL',
    },
    32567: {
      lat: 30.92207,
      lng: -86.43396,
      city: 'Laurel Hill',
      state: 'FL',
    },
    32568: {
      lat: 30.87085,
      lng: -87.46792,
      city: 'McDavid',
      state: 'FL',
    },
    32569: {
      lat: 30.40666,
      lng: -86.72962,
      city: 'Mary Esther',
      state: 'FL',
    },
    32570: {
      lat: 30.80022,
      lng: -86.98158,
      city: 'Milton',
      state: 'FL',
    },
    32571: {
      lat: 30.67162,
      lng: -87.20004,
      city: 'Milton',
      state: 'FL',
    },
    32577: {
      lat: 30.73453,
      lng: -87.37471,
      city: 'Molino',
      state: 'FL',
    },
    32578: {
      lat: 30.50871,
      lng: -86.4535,
      city: 'Niceville',
      state: 'FL',
    },
    32579: {
      lat: 30.44553,
      lng: -86.57401,
      city: 'Shalimar',
      state: 'FL',
    },
    32580: {
      lat: 30.50337,
      lng: -86.50942,
      city: 'Valparaiso',
      state: 'FL',
    },
    32583: {
      lat: 30.60084,
      lng: -86.97513,
      city: 'Milton',
      state: 'FL',
    },
    32601: {
      lat: 29.64886,
      lng: -82.32506,
      city: 'Gainesville',
      state: 'FL',
    },
    32603: {
      lat: 29.65305,
      lng: -82.35054,
      city: 'Gainesville',
      state: 'FL',
    },
    32605: {
      lat: 29.67659,
      lng: -82.37351,
      city: 'Gainesville',
      state: 'FL',
    },
    32606: {
      lat: 29.68169,
      lng: -82.44391,
      city: 'Gainesville',
      state: 'FL',
    },
    32607: {
      lat: 29.64524,
      lng: -82.41923,
      city: 'Gainesville',
      state: 'FL',
    },
    32608: {
      lat: 29.59271,
      lng: -82.3935,
      city: 'Gainesville',
      state: 'FL',
    },
    32609: {
      lat: 29.76296,
      lng: -82.27978,
      city: 'Gainesville',
      state: 'FL',
    },
    32610: {
      lat: 29.64077,
      lng: -82.34326,
      city: 'Gainesville',
      state: 'FL',
    },
    32612: {
      lat: 29.64469,
      lng: -82.35307,
      city: 'Gainesville',
      state: 'FL',
    },
    32615: {
      lat: 29.81706,
      lng: -82.49065,
      city: 'Alachua',
      state: 'FL',
    },
    32616: {
      lat: 29.78205,
      lng: -82.49933,
      city: 'Alachua',
      state: 'FL',
    },
    32617: {
      lat: 29.30659,
      lng: -82.07553,
      city: 'Anthony',
      state: 'FL',
    },
    32618: {
      lat: 29.53789,
      lng: -82.52295,
      city: 'Archer',
      state: 'FL',
    },
    32619: { lat: 29.75661, lng: -82.86668, city: 'Bell', state: 'FL' },
    32621: {
      lat: 29.426,
      lng: -82.62936,
      city: 'Bronson',
      state: 'FL',
    },
    32622: {
      lat: 29.89914,
      lng: -82.31216,
      city: 'Brooker',
      state: 'FL',
    },
    32625: {
      lat: 29.23005,
      lng: -82.98331,
      city: 'Cedar Key',
      state: 'FL',
    },
    32626: {
      lat: 29.42146,
      lng: -82.89464,
      city: 'Chiefland',
      state: 'FL',
    },
    32628: {
      lat: 29.65471,
      lng: -83.16247,
      city: 'Cross City',
      state: 'FL',
    },
    32631: {
      lat: 29.71719,
      lng: -82.10225,
      city: 'Earleton',
      state: 'FL',
    },
    32634: {
      lat: 29.3646,
      lng: -82.25419,
      city: 'Fairfield',
      state: 'FL',
    },
    32639: {
      lat: 29.23092,
      lng: -82.72007,
      city: 'Gulf Hammock',
      state: 'FL',
    },
    32640: {
      lat: 29.56889,
      lng: -82.08021,
      city: 'Hawthorne',
      state: 'FL',
    },
    32641: {
      lat: 29.62956,
      lng: -82.22557,
      city: 'Gainesville',
      state: 'FL',
    },
    32643: {
      lat: 29.81845,
      lng: -82.64155,
      city: 'High Springs',
      state: 'FL',
    },
    32648: {
      lat: 29.50515,
      lng: -83.24498,
      city: 'Horseshoe Beach',
      state: 'FL',
    },
    32653: {
      lat: 29.74506,
      lng: -82.40546,
      city: 'Gainesville',
      state: 'FL',
    },
    32656: {
      lat: 29.82533,
      lng: -81.95287,
      city: 'Keystone Heights',
      state: 'FL',
    },
    32658: {
      lat: 29.84817,
      lng: -82.41214,
      city: 'La Crosse',
      state: 'FL',
    },
    32664: {
      lat: 29.44986,
      lng: -82.22187,
      city: 'McIntosh',
      state: 'FL',
    },
    32666: {
      lat: 29.72301,
      lng: -81.99145,
      city: 'Melrose',
      state: 'FL',
    },
    32667: {
      lat: 29.49484,
      lng: -82.31084,
      city: 'Micanopy',
      state: 'FL',
    },
    32668: {
      lat: 29.26327,
      lng: -82.50321,
      city: 'Morriston',
      state: 'FL',
    },
    32669: {
      lat: 29.63666,
      lng: -82.60015,
      city: 'Newberry',
      state: 'FL',
    },
    32680: {
      lat: 29.58144,
      lng: -83.08041,
      city: 'Old Town',
      state: 'FL',
    },
    32681: {
      lat: 29.42694,
      lng: -82.21527,
      city: 'Orange Lake',
      state: 'FL',
    },
    32683: {
      lat: 29.29519,
      lng: -82.76743,
      city: 'Otter Creek',
      state: 'FL',
    },
    32686: {
      lat: 29.36214,
      lng: -82.26236,
      city: 'Reddick',
      state: 'FL',
    },
    32692: {
      lat: 29.32519,
      lng: -83.15995,
      city: 'Suwannee',
      state: 'FL',
    },
    32693: {
      lat: 29.62814,
      lng: -82.79446,
      city: 'Trenton',
      state: 'FL',
    },
    32694: {
      lat: 29.79086,
      lng: -82.15367,
      city: 'Waldo',
      state: 'FL',
    },
    32696: {
      lat: 29.39544,
      lng: -82.46728,
      city: 'Williston',
      state: 'FL',
    },
    32697: {
      lat: 29.92831,
      lng: -82.42538,
      city: 'Worthington Springs',
      state: 'FL',
    },
    32701: {
      lat: 28.66509,
      lng: -81.36916,
      city: 'Altamonte Springs',
      state: 'FL',
    },
    32702: {
      lat: 29.04642,
      lng: -81.6259,
      city: 'Altoona',
      state: 'FL',
    },
    32703: {
      lat: 28.65579,
      lng: -81.52139,
      city: 'Apopka',
      state: 'FL',
    },
    32707: {
      lat: 28.66255,
      lng: -81.31403,
      city: 'Casselberry',
      state: 'FL',
    },
    32708: {
      lat: 28.68751,
      lng: -81.27086,
      city: 'Winter Springs',
      state: 'FL',
    },
    32709: {
      lat: 28.49266,
      lng: -80.98801,
      city: 'Christmas',
      state: 'FL',
    },
    32712: {
      lat: 28.72917,
      lng: -81.54103,
      city: 'Apopka',
      state: 'FL',
    },
    32713: { lat: 28.8816, lng: -81.3238, city: 'Debary', state: 'FL' },
    32714: {
      lat: 28.66237,
      lng: -81.4115,
      city: 'Altamonte Springs',
      state: 'FL',
    },
    32720: {
      lat: 29.01775,
      lng: -81.37802,
      city: 'Deland',
      state: 'FL',
    },
    32723: {
      lat: 29.03454,
      lng: -81.30452,
      city: 'Deland',
      state: 'FL',
    },
    32724: {
      lat: 29.0506,
      lng: -81.23284,
      city: 'Deland',
      state: 'FL',
    },
    32725: {
      lat: 28.88368,
      lng: -81.25061,
      city: 'Deltona',
      state: 'FL',
    },
    32726: { lat: 28.8541, lng: -81.68, city: 'Eustis', state: 'FL' },
    32730: {
      lat: 28.65242,
      lng: -81.34301,
      city: 'Casselberry',
      state: 'FL',
    },
    32732: {
      lat: 28.74565,
      lng: -81.10563,
      city: 'Geneva',
      state: 'FL',
    },
    32735: {
      lat: 28.89099,
      lng: -81.73718,
      city: 'Grand Island',
      state: 'FL',
    },
    32736: {
      lat: 28.89831,
      lng: -81.49317,
      city: 'Eustis',
      state: 'FL',
    },
    32738: {
      lat: 28.90755,
      lng: -81.18862,
      city: 'Deltona',
      state: 'FL',
    },
    32744: {
      lat: 28.98497,
      lng: -81.21899,
      city: 'Lake Helen',
      state: 'FL',
    },
    32746: {
      lat: 28.76297,
      lng: -81.35499,
      city: 'Lake Mary',
      state: 'FL',
    },
    32750: {
      lat: 28.7074,
      lng: -81.34955,
      city: 'Longwood',
      state: 'FL',
    },
    32751: {
      lat: 28.631,
      lng: -81.36457,
      city: 'Maitland',
      state: 'FL',
    },
    32754: { lat: 28.68565, lng: -80.9208, city: 'Mims', state: 'FL' },
    32757: {
      lat: 28.77954,
      lng: -81.637,
      city: 'Mount Dora',
      state: 'FL',
    },
    32759: {
      lat: 28.84004,
      lng: -80.9034,
      city: 'Oak Hill',
      state: 'FL',
    },
    32763: {
      lat: 28.94291,
      lng: -81.30593,
      city: 'Orange City',
      state: 'FL',
    },
    32764: {
      lat: 28.83883,
      lng: -81.07239,
      city: 'Osteen',
      state: 'FL',
    },
    32765: {
      lat: 28.66732,
      lng: -81.20033,
      city: 'Oviedo',
      state: 'FL',
    },
    32766: {
      lat: 28.64761,
      lng: -81.08124,
      city: 'Oviedo',
      state: 'FL',
    },
    32767: {
      lat: 29.01957,
      lng: -81.52065,
      city: 'Paisley',
      state: 'FL',
    },
    32768: {
      lat: 28.69577,
      lng: -81.55795,
      city: 'Plymouth',
      state: 'FL',
    },
    32771: {
      lat: 28.81496,
      lng: -81.31751,
      city: 'Sanford',
      state: 'FL',
    },
    32773: {
      lat: 28.75264,
      lng: -81.24659,
      city: 'Sanford',
      state: 'FL',
    },
    32775: {
      lat: 28.76704,
      lng: -80.8767,
      city: 'Scottsmoor',
      state: 'FL',
    },
    32776: {
      lat: 28.81033,
      lng: -81.4872,
      city: 'Sorrento',
      state: 'FL',
    },
    32778: {
      lat: 28.77554,
      lng: -81.72884,
      city: 'Tavares',
      state: 'FL',
    },
    32779: {
      lat: 28.71632,
      lng: -81.41343,
      city: 'Longwood',
      state: 'FL',
    },
    32780: {
      lat: 28.54672,
      lng: -80.7841,
      city: 'Titusville',
      state: 'FL',
    },
    32784: {
      lat: 28.97626,
      lng: -81.7232,
      city: 'Umatilla',
      state: 'FL',
    },
    32789: {
      lat: 28.59977,
      lng: -81.35235,
      city: 'Winter Park',
      state: 'FL',
    },
    32792: {
      lat: 28.61066,
      lng: -81.29923,
      city: 'Winter Park',
      state: 'FL',
    },
    32796: {
      lat: 28.62591,
      lng: -80.84051,
      city: 'Titusville',
      state: 'FL',
    },
    32798: {
      lat: 28.72647,
      lng: -81.58648,
      city: 'Zellwood',
      state: 'FL',
    },
    32801: {
      lat: 28.542,
      lng: -81.37343,
      city: 'Orlando',
      state: 'FL',
    },
    32803: {
      lat: 28.55592,
      lng: -81.34895,
      city: 'Orlando',
      state: 'FL',
    },
    32804: {
      lat: 28.57688,
      lng: -81.39748,
      city: 'Orlando',
      state: 'FL',
    },
    32805: {
      lat: 28.52981,
      lng: -81.40614,
      city: 'Orlando',
      state: 'FL',
    },
    32806: {
      lat: 28.5123,
      lng: -81.36031,
      city: 'Orlando',
      state: 'FL',
    },
    32807: {
      lat: 28.55245,
      lng: -81.30165,
      city: 'Orlando',
      state: 'FL',
    },
    32808: {
      lat: 28.57932,
      lng: -81.44342,
      city: 'Orlando',
      state: 'FL',
    },
    32809: {
      lat: 28.46301,
      lng: -81.38428,
      city: 'Orlando',
      state: 'FL',
    },
    32810: {
      lat: 28.62113,
      lng: -81.42863,
      city: 'Orlando',
      state: 'FL',
    },
    32811: {
      lat: 28.51624,
      lng: -81.44516,
      city: 'Orlando',
      state: 'FL',
    },
    32812: {
      lat: 28.4848,
      lng: -81.32767,
      city: 'Orlando',
      state: 'FL',
    },
    32814: {
      lat: 28.57002,
      lng: -81.32663,
      city: 'Orlando',
      state: 'FL',
    },
    32816: {
      lat: 28.60075,
      lng: -81.19511,
      city: 'Orlando',
      state: 'FL',
    },
    32817: {
      lat: 28.59002,
      lng: -81.2445,
      city: 'Orlando',
      state: 'FL',
    },
    32818: {
      lat: 28.58518,
      lng: -81.48716,
      city: 'Orlando',
      state: 'FL',
    },
    32819: {
      lat: 28.45565,
      lng: -81.472,
      city: 'Orlando',
      state: 'FL',
    },
    32820: {
      lat: 28.58493,
      lng: -81.12127,
      city: 'Orlando',
      state: 'FL',
    },
    32821: {
      lat: 28.38095,
      lng: -81.47719,
      city: 'Orlando',
      state: 'FL',
    },
    32822: {
      lat: 28.49001,
      lng: -81.29056,
      city: 'Orlando',
      state: 'FL',
    },
    32824: {
      lat: 28.38991,
      lng: -81.34471,
      city: 'Orlando',
      state: 'FL',
    },
    32825: {
      lat: 28.53681,
      lng: -81.24243,
      city: 'Orlando',
      state: 'FL',
    },
    32826: {
      lat: 28.58543,
      lng: -81.18263,
      city: 'Orlando',
      state: 'FL',
    },
    32827: {
      lat: 28.41399,
      lng: -81.29032,
      city: 'Orlando',
      state: 'FL',
    },
    32828: {
      lat: 28.53052,
      lng: -81.17022,
      city: 'Orlando',
      state: 'FL',
    },
    32829: {
      lat: 28.47639,
      lng: -81.22478,
      city: 'Orlando',
      state: 'FL',
    },
    32830: {
      lat: 28.38643,
      lng: -81.56732,
      city: 'Orlando',
      state: 'FL',
    },
    32831: {
      lat: 28.47439,
      lng: -81.14729,
      city: 'Orlando',
      state: 'FL',
    },
    32832: {
      lat: 28.39843,
      lng: -81.18407,
      city: 'Orlando',
      state: 'FL',
    },
    32833: { lat: 28.4948, lng: -81.081, city: 'Orlando', state: 'FL' },
    32835: {
      lat: 28.52182,
      lng: -81.48473,
      city: 'Orlando',
      state: 'FL',
    },
    32836: {
      lat: 28.41734,
      lng: -81.51536,
      city: 'Orlando',
      state: 'FL',
    },
    32837: {
      lat: 28.38074,
      lng: -81.41998,
      city: 'Orlando',
      state: 'FL',
    },
    32839: {
      lat: 28.48833,
      lng: -81.40689,
      city: 'Orlando',
      state: 'FL',
    },
    32899: {
      lat: 28.58331,
      lng: -80.63544,
      city: 'Orlando',
      state: 'FL',
    },
    32901: {
      lat: 28.07887,
      lng: -80.62268,
      city: 'Melbourne',
      state: 'FL',
    },
    32903: {
      lat: 28.10818,
      lng: -80.58709,
      city: 'Indialantic',
      state: 'FL',
    },
    32904: {
      lat: 28.01,
      lng: -80.79104,
      city: 'Melbourne',
      state: 'FL',
    },
    32905: {
      lat: 28.03136,
      lng: -80.59997,
      city: 'Palm Bay',
      state: 'FL',
    },
    32907: {
      lat: 28.01565,
      lng: -80.68187,
      city: 'Palm Bay',
      state: 'FL',
    },
    32908: {
      lat: 27.95622,
      lng: -80.69827,
      city: 'Palm Bay',
      state: 'FL',
    },
    32909: {
      lat: 27.90435,
      lng: -80.65515,
      city: 'Palm Bay',
      state: 'FL',
    },
    32920: {
      lat: 28.44603,
      lng: -80.58503,
      city: 'Cape Canaveral',
      state: 'FL',
    },
    32922: {
      lat: 28.37252,
      lng: -80.74279,
      city: 'Cocoa',
      state: 'FL',
    },
    32925: {
      lat: 28.24272,
      lng: -80.62287,
      city: 'Patrick Afb',
      state: 'FL',
    },
    32926: {
      lat: 28.39051,
      lng: -80.81773,
      city: 'Cocoa',
      state: 'FL',
    },
    32927: {
      lat: 28.46372,
      lng: -80.80709,
      city: 'Cocoa',
      state: 'FL',
    },
    32931: {
      lat: 28.32193,
      lng: -80.62731,
      city: 'Cocoa Beach',
      state: 'FL',
    },
    32934: {
      lat: 28.13532,
      lng: -80.71153,
      city: 'Melbourne',
      state: 'FL',
    },
    32935: {
      lat: 28.1444,
      lng: -80.64832,
      city: 'Melbourne',
      state: 'FL',
    },
    32937: {
      lat: 28.17464,
      lng: -80.61146,
      city: 'Satellite Beach',
      state: 'FL',
    },
    32940: {
      lat: 28.21813,
      lng: -80.7543,
      city: 'Melbourne',
      state: 'FL',
    },
    32948: {
      lat: 27.75901,
      lng: -80.61826,
      city: 'Fellsmere',
      state: 'FL',
    },
    32949: {
      lat: 27.92005,
      lng: -80.55203,
      city: 'Grant',
      state: 'FL',
    },
    32950: {
      lat: 27.9757,
      lng: -80.57839,
      city: 'Malabar',
      state: 'FL',
    },
    32951: {
      lat: 27.9595,
      lng: -80.51498,
      city: 'Melbourne Beach',
      state: 'FL',
    },
    32952: {
      lat: 28.31749,
      lng: -80.66832,
      city: 'Merritt Island',
      state: 'FL',
    },
    32953: {
      lat: 28.45613,
      lng: -80.69916,
      city: 'Merritt Island',
      state: 'FL',
    },
    32955: {
      lat: 28.29414,
      lng: -80.77528,
      city: 'Rockledge',
      state: 'FL',
    },
    32958: {
      lat: 27.79364,
      lng: -80.49135,
      city: 'Sebastian',
      state: 'FL',
    },
    32959: {
      lat: 28.44513,
      lng: -80.7637,
      city: 'Sharpes',
      state: 'FL',
    },
    32960: {
      lat: 27.64069,
      lng: -80.40366,
      city: 'Vero Beach',
      state: 'FL',
    },
    32962: {
      lat: 27.5865,
      lng: -80.38414,
      city: 'Vero Beach',
      state: 'FL',
    },
    32963: {
      lat: 27.71951,
      lng: -80.39646,
      city: 'Vero Beach',
      state: 'FL',
    },
    32966: {
      lat: 27.68716,
      lng: -80.70693,
      city: 'Vero Beach',
      state: 'FL',
    },
    32967: {
      lat: 27.71359,
      lng: -80.46015,
      city: 'Vero Beach',
      state: 'FL',
    },
    32968: {
      lat: 27.58808,
      lng: -80.50993,
      city: 'Vero Beach',
      state: 'FL',
    },
    32970: {
      lat: 27.75402,
      lng: -80.45136,
      city: 'Wabasso',
      state: 'FL',
    },
    32976: {
      lat: 27.86198,
      lng: -80.53721,
      city: 'Sebastian',
      state: 'FL',
    },
    33001: {
      lat: 24.82041,
      lng: -80.80992,
      city: 'Long Key',
      state: 'FL',
    },
    33004: {
      lat: 26.05939,
      lng: -80.13637,
      city: 'Dania',
      state: 'FL',
    },
    33009: {
      lat: 25.98531,
      lng: -80.1466,
      city: 'Hallandale',
      state: 'FL',
    },
    33010: {
      lat: 25.83309,
      lng: -80.27902,
      city: 'Hialeah',
      state: 'FL',
    },
    33012: {
      lat: 25.86583,
      lng: -80.30247,
      city: 'Hialeah',
      state: 'FL',
    },
    33013: {
      lat: 25.86209,
      lng: -80.27032,
      city: 'Hialeah',
      state: 'FL',
    },
    33014: {
      lat: 25.90332,
      lng: -80.30173,
      city: 'Hialeah',
      state: 'FL',
    },
    33015: {
      lat: 25.94116,
      lng: -80.31761,
      city: 'Hialeah',
      state: 'FL',
    },
    33016: {
      lat: 25.89361,
      lng: -80.33186,
      city: 'Hialeah',
      state: 'FL',
    },
    33018: { lat: 25.92, lng: -80.39274, city: 'Hialeah', state: 'FL' },
    33019: {
      lat: 26.02346,
      lng: -80.1236,
      city: 'Hollywood',
      state: 'FL',
    },
    33020: {
      lat: 26.01911,
      lng: -80.15251,
      city: 'Hollywood',
      state: 'FL',
    },
    33021: {
      lat: 26.02277,
      lng: -80.18742,
      city: 'Hollywood',
      state: 'FL',
    },
    33022: {
      lat: 26.01385,
      lng: -80.14407,
      city: 'Hollywood',
      state: 'FL',
    },
    33023: {
      lat: 25.98812,
      lng: -80.21263,
      city: 'Hollywood',
      state: 'FL',
    },
    33024: {
      lat: 26.02501,
      lng: -80.24475,
      city: 'Hollywood',
      state: 'FL',
    },
    33025: {
      lat: 25.98737,
      lng: -80.28134,
      city: 'Hollywood',
      state: 'FL',
    },
    33026: {
      lat: 26.02575,
      lng: -80.29625,
      city: 'Hollywood',
      state: 'FL',
    },
    33027: {
      lat: 25.98245,
      lng: -80.34359,
      city: 'Hollywood',
      state: 'FL',
    },
    33028: {
      lat: 26.01852,
      lng: -80.34488,
      city: 'Pembroke Pines',
      state: 'FL',
    },
    33029: {
      lat: 25.99184,
      lng: -80.40794,
      city: 'Hollywood',
      state: 'FL',
    },
    33030: {
      lat: 25.4848,
      lng: -80.50857,
      city: 'Homestead',
      state: 'FL',
    },
    33031: {
      lat: 25.52709,
      lng: -80.50312,
      city: 'Homestead',
      state: 'FL',
    },
    33032: {
      lat: 25.52963,
      lng: -80.39234,
      city: 'Homestead',
      state: 'FL',
    },
    33033: {
      lat: 25.47858,
      lng: -80.4057,
      city: 'Homestead',
      state: 'FL',
    },
    33034: {
      lat: 25.4195,
      lng: -80.86342,
      city: 'Homestead',
      state: 'FL',
    },
    33035: {
      lat: 25.4216,
      lng: -80.4036,
      city: 'Homestead',
      state: 'FL',
    },
    33036: {
      lat: 24.9227,
      lng: -80.6385,
      city: 'Islamorada',
      state: 'FL',
    },
    33037: {
      lat: 25.19779,
      lng: -80.38634,
      city: 'Key Largo',
      state: 'FL',
    },
    33039: {
      lat: 25.49796,
      lng: -80.39818,
      city: 'Homestead',
      state: 'FL',
    },
    33040: {
      lat: 24.5855,
      lng: -81.74904,
      city: 'Key West',
      state: 'FL',
    },
    33042: {
      lat: 24.67481,
      lng: -81.50717,
      city: 'Summerland Key',
      state: 'FL',
    },
    33043: {
      lat: 24.70563,
      lng: -81.3587,
      city: 'Big Pine Key',
      state: 'FL',
    },
    33050: {
      lat: 24.72843,
      lng: -81.03405,
      city: 'Marathon',
      state: 'FL',
    },
    33051: {
      lat: 24.72328,
      lng: -81.02155,
      city: 'Key Colony Beach',
      state: 'FL',
    },
    33054: {
      lat: 25.90775,
      lng: -80.25753,
      city: 'Opa Locka',
      state: 'FL',
    },
    33055: {
      lat: 25.94812,
      lng: -80.27809,
      city: 'Opa Locka',
      state: 'FL',
    },
    33056: {
      lat: 25.94922,
      lng: -80.24562,
      city: 'Miami Gardens',
      state: 'FL',
    },
    33060: {
      lat: 26.23513,
      lng: -80.12084,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33062: {
      lat: 26.24101,
      lng: -80.09399,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33063: {
      lat: 26.25138,
      lng: -80.21014,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33064: {
      lat: 26.27806,
      lng: -80.11524,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33065: {
      lat: 26.27318,
      lng: -80.26029,
      city: 'Coral Springs',
      state: 'FL',
    },
    33066: {
      lat: 26.25347,
      lng: -80.17753,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33067: {
      lat: 26.307,
      lng: -80.2257,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33068: {
      lat: 26.21644,
      lng: -80.21535,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33069: {
      lat: 26.23565,
      lng: -80.15808,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33070: {
      lat: 25.00873,
      lng: -80.52501,
      city: 'Tavernier',
      state: 'FL',
    },
    33071: {
      lat: 26.24395,
      lng: -80.26627,
      city: 'Coral Springs',
      state: 'FL',
    },
    33073: {
      lat: 26.29818,
      lng: -80.1827,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33076: {
      lat: 26.31788,
      lng: -80.27431,
      city: 'Pompano Beach',
      state: 'FL',
    },
    33101: {
      lat: 25.77976,
      lng: -80.19877,
      city: 'Miami',
      state: 'FL',
    },
    33109: {
      lat: 25.7616,
      lng: -80.14197,
      city: 'Miami Beach',
      state: 'FL',
    },
    33122: {
      lat: 25.80523,
      lng: -80.31706,
      city: 'Miami',
      state: 'FL',
    },
    33125: { lat: 25.7839, lng: -80.23738, city: 'Miami', state: 'FL' },
    33126: {
      lat: 25.78061,
      lng: -80.30054,
      city: 'Miami',
      state: 'FL',
    },
    33127: {
      lat: 25.81307,
      lng: -80.20553,
      city: 'Miami',
      state: 'FL',
    },
    33128: {
      lat: 25.77661,
      lng: -80.20407,
      city: 'Miami',
      state: 'FL',
    },
    33129: { lat: 25.7527, lng: -80.20046, city: 'Miami', state: 'FL' },
    33130: {
      lat: 25.76805,
      lng: -80.20291,
      city: 'Miami',
      state: 'FL',
    },
    33131: {
      lat: 25.76417,
      lng: -80.18459,
      city: 'Miami',
      state: 'FL',
    },
    33132: {
      lat: 25.77719,
      lng: -80.17395,
      city: 'Miami',
      state: 'FL',
    },
    33133: {
      lat: 25.72983,
      lng: -80.24327,
      city: 'Miami',
      state: 'FL',
    },
    33134: { lat: 25.75338, lng: -80.2712, city: 'Miami', state: 'FL' },
    33135: {
      lat: 25.76657,
      lng: -80.23495,
      city: 'Miami',
      state: 'FL',
    },
    33136: {
      lat: 25.78703,
      lng: -80.20444,
      city: 'Miami',
      state: 'FL',
    },
    33137: {
      lat: 25.81538,
      lng: -80.17798,
      city: 'Miami',
      state: 'FL',
    },
    33138: {
      lat: 25.85388,
      lng: -80.17846,
      city: 'Miami',
      state: 'FL',
    },
    33139: {
      lat: 25.78523,
      lng: -80.15085,
      city: 'Miami Beach',
      state: 'FL',
    },
    33140: {
      lat: 25.81606,
      lng: -80.13895,
      city: 'Miami Beach',
      state: 'FL',
    },
    33141: {
      lat: 25.85116,
      lng: -80.14147,
      city: 'Miami Beach',
      state: 'FL',
    },
    33142: {
      lat: 25.80665,
      lng: -80.25517,
      city: 'Miami',
      state: 'FL',
    },
    33143: {
      lat: 25.70255,
      lng: -80.29792,
      city: 'Miami',
      state: 'FL',
    },
    33144: {
      lat: 25.76335,
      lng: -80.31228,
      city: 'Miami',
      state: 'FL',
    },
    33145: {
      lat: 25.75322,
      lng: -80.23461,
      city: 'Miami',
      state: 'FL',
    },
    33146: {
      lat: 25.72059,
      lng: -80.27274,
      city: 'Miami',
      state: 'FL',
    },
    33147: {
      lat: 25.85104,
      lng: -80.23861,
      city: 'Miami',
      state: 'FL',
    },
    33149: {
      lat: 25.72479,
      lng: -80.16118,
      city: 'Key Biscayne',
      state: 'FL',
    },
    33150: {
      lat: 25.85196,
      lng: -80.20702,
      city: 'Miami',
      state: 'FL',
    },
    33154: {
      lat: 25.88609,
      lng: -80.13332,
      city: 'Miami Beach',
      state: 'FL',
    },
    33155: {
      lat: 25.73672,
      lng: -80.31117,
      city: 'Miami',
      state: 'FL',
    },
    33156: {
      lat: 25.66779,
      lng: -80.29728,
      city: 'Miami',
      state: 'FL',
    },
    33157: {
      lat: 25.60671,
      lng: -80.34314,
      city: 'Miami',
      state: 'FL',
    },
    33158: {
      lat: 25.63744,
      lng: -80.30945,
      city: 'Miami',
      state: 'FL',
    },
    33160: {
      lat: 25.93234,
      lng: -80.13583,
      city: 'North Miami Beach',
      state: 'FL',
    },
    33161: {
      lat: 25.89357,
      lng: -80.18285,
      city: 'Miami',
      state: 'FL',
    },
    33162: {
      lat: 25.92845,
      lng: -80.17844,
      city: 'Miami',
      state: 'FL',
    },
    33165: {
      lat: 25.73437,
      lng: -80.35862,
      city: 'Miami',
      state: 'FL',
    },
    33166: { lat: 25.8253, lng: -80.31049, city: 'Miami', state: 'FL' },
    33167: { lat: 25.8848, lng: -80.23631, city: 'Miami', state: 'FL' },
    33168: { lat: 25.89318, lng: -80.2093, city: 'Miami', state: 'FL' },
    33169: { lat: 25.94298, lng: -80.2147, city: 'Miami', state: 'FL' },
    33170: {
      lat: 25.55827,
      lng: -80.45313,
      city: 'Miami',
      state: 'FL',
    },
    33172: { lat: 25.78655, lng: -80.3623, city: 'Miami', state: 'FL' },
    33173: {
      lat: 25.70163,
      lng: -80.35765,
      city: 'Miami',
      state: 'FL',
    },
    33174: {
      lat: 25.76199,
      lng: -80.36029,
      city: 'Miami',
      state: 'FL',
    },
    33175: {
      lat: 25.73435,
      lng: -80.40621,
      city: 'Miami',
      state: 'FL',
    },
    33176: {
      lat: 25.65876,
      lng: -80.35919,
      city: 'Miami',
      state: 'FL',
    },
    33177: {
      lat: 25.59703,
      lng: -80.40458,
      city: 'Miami',
      state: 'FL',
    },
    33178: { lat: 25.83578, lng: -80.4213, city: 'Miami', state: 'FL' },
    33179: {
      lat: 25.95821,
      lng: -80.17981,
      city: 'Miami',
      state: 'FL',
    },
    33180: { lat: 25.96145, lng: -80.1415, city: 'Miami', state: 'FL' },
    33181: { lat: 25.89717, lng: -80.1531, city: 'Miami', state: 'FL' },
    33182: {
      lat: 25.78252,
      lng: -80.40182,
      city: 'Miami',
      state: 'FL',
    },
    33183: {
      lat: 25.70047,
      lng: -80.40578,
      city: 'Miami',
      state: 'FL',
    },
    33184: { lat: 25.7597, lng: -80.4066, city: 'Miami', state: 'FL' },
    33185: {
      lat: 25.73035,
      lng: -80.45448,
      city: 'Miami',
      state: 'FL',
    },
    33186: {
      lat: 25.65565,
      lng: -80.41062,
      city: 'Miami',
      state: 'FL',
    },
    33187: {
      lat: 25.54889,
      lng: -80.57638,
      city: 'Miami',
      state: 'FL',
    },
    33189: {
      lat: 25.57314,
      lng: -80.33929,
      city: 'Miami',
      state: 'FL',
    },
    33190: {
      lat: 25.55734,
      lng: -80.34897,
      city: 'Miami',
      state: 'FL',
    },
    33193: {
      lat: 25.70175,
      lng: -80.46672,
      city: 'Miami',
      state: 'FL',
    },
    33194: { lat: 25.7163, lng: -80.62799, city: 'Miami', state: 'FL' },
    33196: {
      lat: 25.64505,
      lng: -80.55524,
      city: 'Miami',
      state: 'FL',
    },
    33301: {
      lat: 26.12128,
      lng: -80.12745,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33304: {
      lat: 26.13845,
      lng: -80.12166,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33305: {
      lat: 26.15375,
      lng: -80.123,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33306: {
      lat: 26.16548,
      lng: -80.11404,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33308: {
      lat: 26.18952,
      lng: -80.10859,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33309: {
      lat: 26.18793,
      lng: -80.17385,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33311: {
      lat: 26.14412,
      lng: -80.17319,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33312: {
      lat: 26.08835,
      lng: -80.18166,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33313: {
      lat: 26.15069,
      lng: -80.22634,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33314: {
      lat: 26.06822,
      lng: -80.22391,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33315: {
      lat: 26.08703,
      lng: -80.15297,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33316: {
      lat: 26.09913,
      lng: -80.12398,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33317: {
      lat: 26.11268,
      lng: -80.22607,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33319: {
      lat: 26.1826,
      lng: -80.22641,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33321: {
      lat: 26.2121,
      lng: -80.2697,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33322: {
      lat: 26.14997,
      lng: -80.27429,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33323: {
      lat: 26.14988,
      lng: -80.32222,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33324: {
      lat: 26.11205,
      lng: -80.27469,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33325: {
      lat: 26.11032,
      lng: -80.32261,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33326: {
      lat: 26.11033,
      lng: -80.37403,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33327: {
      lat: 26.24429,
      lng: -80.39267,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33328: {
      lat: 26.06673,
      lng: -80.27301,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33330: {
      lat: 26.06034,
      lng: -80.32333,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33331: {
      lat: 26.0587,
      lng: -80.36989,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33332: {
      lat: 26.03825,
      lng: -80.44352,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33334: {
      lat: 26.18316,
      lng: -80.13456,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33351: {
      lat: 26.17938,
      lng: -80.27463,
      city: 'Fort Lauderdale',
      state: 'FL',
    },
    33388: {
      lat: 26.11715,
      lng: -80.25409,
      city: 'Plantation',
      state: 'FL',
    },
    33401: {
      lat: 26.71805,
      lng: -80.0685,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33403: {
      lat: 26.8032,
      lng: -80.07562,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33404: {
      lat: 26.78248,
      lng: -80.06567,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33405: {
      lat: 26.66774,
      lng: -80.0584,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33406: {
      lat: 26.66503,
      lng: -80.09093,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33407: {
      lat: 26.75841,
      lng: -80.09195,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33408: {
      lat: 26.84115,
      lng: -80.05677,
      city: 'North Palm Beach',
      state: 'FL',
    },
    33409: {
      lat: 26.7161,
      lng: -80.0965,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33410: {
      lat: 26.84629,
      lng: -80.08823,
      city: 'Palm Beach Gardens',
      state: 'FL',
    },
    33411: {
      lat: 26.71517,
      lng: -80.20305,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33412: {
      lat: 26.80561,
      lng: -80.21557,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33413: {
      lat: 26.66397,
      lng: -80.1553,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33414: {
      lat: 26.64829,
      lng: -80.24943,
      city: 'Wellington',
      state: 'FL',
    },
    33415: {
      lat: 26.66002,
      lng: -80.12742,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33417: {
      lat: 26.71994,
      lng: -80.12495,
      city: 'West Palm Beach',
      state: 'FL',
    },
    33418: {
      lat: 26.86077,
      lng: -80.16641,
      city: 'Palm Beach Gardens',
      state: 'FL',
    },
    33426: {
      lat: 26.53359,
      lng: -80.08295,
      city: 'Boynton Beach',
      state: 'FL',
    },
    33428: {
      lat: 26.35054,
      lng: -80.22792,
      city: 'Boca Raton',
      state: 'FL',
    },
    33430: {
      lat: 26.61415,
      lng: -80.57952,
      city: 'Belle Glade',
      state: 'FL',
    },
    33431: {
      lat: 26.37996,
      lng: -80.1028,
      city: 'Boca Raton',
      state: 'FL',
    },
    33432: {
      lat: 26.34546,
      lng: -80.0838,
      city: 'Boca Raton',
      state: 'FL',
    },
    33433: {
      lat: 26.34706,
      lng: -80.15919,
      city: 'Boca Raton',
      state: 'FL',
    },
    33434: {
      lat: 26.38139,
      lng: -80.16913,
      city: 'Boca Raton',
      state: 'FL',
    },
    33435: {
      lat: 26.52565,
      lng: -80.06297,
      city: 'Boynton Beach',
      state: 'FL',
    },
    33436: {
      lat: 26.52423,
      lng: -80.10758,
      city: 'Boynton Beach',
      state: 'FL',
    },
    33437: {
      lat: 26.51164,
      lng: -80.14917,
      city: 'Boynton Beach',
      state: 'FL',
    },
    33438: {
      lat: 26.95443,
      lng: -80.56524,
      city: 'Canal Point',
      state: 'FL',
    },
    33440: {
      lat: 26.54065,
      lng: -81.04325,
      city: 'Clewiston',
      state: 'FL',
    },
    33441: {
      lat: 26.31096,
      lng: -80.09885,
      city: 'Deerfield Beach',
      state: 'FL',
    },
    33442: {
      lat: 26.31012,
      lng: -80.14503,
      city: 'Deerfield Beach',
      state: 'FL',
    },
    33444: {
      lat: 26.45781,
      lng: -80.08002,
      city: 'Delray Beach',
      state: 'FL',
    },
    33445: {
      lat: 26.4548,
      lng: -80.1063,
      city: 'Delray Beach',
      state: 'FL',
    },
    33446: {
      lat: 26.45444,
      lng: -80.18353,
      city: 'Delray Beach',
      state: 'FL',
    },
    33449: {
      lat: 26.59272,
      lng: -80.2339,
      city: 'Lake Worth',
      state: 'FL',
    },
    33455: {
      lat: 27.05748,
      lng: -80.1703,
      city: 'Hobe Sound',
      state: 'FL',
    },
    33458: {
      lat: 26.93477,
      lng: -80.12906,
      city: 'Jupiter',
      state: 'FL',
    },
    33460: {
      lat: 26.61872,
      lng: -80.05527,
      city: 'Lake Worth Beach',
      state: 'FL',
    },
    33461: {
      lat: 26.61936,
      lng: -80.09048,
      city: 'Lake Worth',
      state: 'FL',
    },
    33462: {
      lat: 26.58027,
      lng: -80.07337,
      city: 'Lake Worth',
      state: 'FL',
    },
    33463: {
      lat: 26.59508,
      lng: -80.12968,
      city: 'Lake Worth',
      state: 'FL',
    },
    33467: {
      lat: 26.59472,
      lng: -80.17567,
      city: 'Lake Worth',
      state: 'FL',
    },
    33469: {
      lat: 26.98275,
      lng: -80.1092,
      city: 'Jupiter',
      state: 'FL',
    },
    33470: {
      lat: 26.7832,
      lng: -80.38063,
      city: 'Loxahatchee',
      state: 'FL',
    },
    33471: {
      lat: 26.9105,
      lng: -81.19754,
      city: 'Moore Haven',
      state: 'FL',
    },
    33472: {
      lat: 26.53974,
      lng: -80.18903,
      city: 'Boynton Beach',
      state: 'FL',
    },
    33473: {
      lat: 26.50328,
      lng: -80.19225,
      city: 'Boynton Beach',
      state: 'FL',
    },
    33476: {
      lat: 26.80109,
      lng: -80.65305,
      city: 'Pahokee',
      state: 'FL',
    },
    33477: {
      lat: 26.91401,
      lng: -80.0799,
      city: 'Jupiter',
      state: 'FL',
    },
    33478: {
      lat: 26.93788,
      lng: -80.26298,
      city: 'Jupiter',
      state: 'FL',
    },
    33480: {
      lat: 26.69796,
      lng: -80.04075,
      city: 'Palm Beach',
      state: 'FL',
    },
    33483: {
      lat: 26.46157,
      lng: -80.0638,
      city: 'Delray Beach',
      state: 'FL',
    },
    33484: {
      lat: 26.45432,
      lng: -80.13434,
      city: 'Delray Beach',
      state: 'FL',
    },
    33486: {
      lat: 26.34729,
      lng: -80.11449,
      city: 'Boca Raton',
      state: 'FL',
    },
    33487: {
      lat: 26.40991,
      lng: -80.09169,
      city: 'Boca Raton',
      state: 'FL',
    },
    33493: {
      lat: 26.47559,
      lng: -80.72306,
      city: 'South Bay',
      state: 'FL',
    },
    33496: {
      lat: 26.40887,
      lng: -80.16139,
      city: 'Boca Raton',
      state: 'FL',
    },
    33498: {
      lat: 26.39529,
      lng: -80.21982,
      city: 'Boca Raton',
      state: 'FL',
    },
    33503: { lat: 27.76375, lng: -82.27425, city: 'Balm', state: 'FL' },
    33510: {
      lat: 27.95564,
      lng: -82.29986,
      city: 'Brandon',
      state: 'FL',
    },
    33511: {
      lat: 27.9099,
      lng: -82.29405,
      city: 'Brandon',
      state: 'FL',
    },
    33513: {
      lat: 28.66849,
      lng: -82.17143,
      city: 'Bushnell',
      state: 'FL',
    },
    33514: {
      lat: 28.65771,
      lng: -81.99886,
      city: 'Center Hill',
      state: 'FL',
    },
    33521: {
      lat: 28.79788,
      lng: -82.0752,
      city: 'Coleman',
      state: 'FL',
    },
    33523: {
      lat: 28.41787,
      lng: -82.26476,
      city: 'Dade City',
      state: 'FL',
    },
    33525: {
      lat: 28.33503,
      lng: -82.18383,
      city: 'Dade City',
      state: 'FL',
    },
    33527: { lat: 27.97571, lng: -82.2138, city: 'Dover', state: 'FL' },
    33530: {
      lat: 27.90773,
      lng: -82.17236,
      city: 'Durant',
      state: 'FL',
    },
    33534: {
      lat: 27.82273,
      lng: -82.37505,
      city: 'Gibsonton',
      state: 'FL',
    },
    33538: {
      lat: 28.84679,
      lng: -82.18218,
      city: 'Lake Panasoffkee',
      state: 'FL',
    },
    33540: {
      lat: 28.21936,
      lng: -82.14866,
      city: 'Zephyrhills',
      state: 'FL',
    },
    33541: {
      lat: 28.23029,
      lng: -82.22073,
      city: 'Zephyrhills',
      state: 'FL',
    },
    33542: {
      lat: 28.23562,
      lng: -82.17708,
      city: 'Zephyrhills',
      state: 'FL',
    },
    33543: {
      lat: 28.20279,
      lng: -82.30503,
      city: 'Wesley Chapel',
      state: 'FL',
    },
    33544: {
      lat: 28.25132,
      lng: -82.37039,
      city: 'Wesley Chapel',
      state: 'FL',
    },
    33545: {
      lat: 28.26578,
      lng: -82.28832,
      city: 'Wesley Chapel',
      state: 'FL',
    },
    33547: {
      lat: 27.77239,
      lng: -82.13665,
      city: 'Lithia',
      state: 'FL',
    },
    33548: { lat: 28.13788, lng: -82.48184, city: 'Lutz', state: 'FL' },
    33549: { lat: 28.1392, lng: -82.44624, city: 'Lutz', state: 'FL' },
    33550: {
      lat: 27.97956,
      lng: -82.30698,
      city: 'Mango',
      state: 'FL',
    },
    33556: {
      lat: 28.17029,
      lng: -82.59264,
      city: 'Odessa',
      state: 'FL',
    },
    33558: { lat: 28.15871, lng: -82.51597, city: 'Lutz', state: 'FL' },
    33559: { lat: 28.15788, lng: -82.41007, city: 'Lutz', state: 'FL' },
    33563: {
      lat: 28.01694,
      lng: -82.12544,
      city: 'Plant City',
      state: 'FL',
    },
    33565: {
      lat: 28.08858,
      lng: -82.15595,
      city: 'Plant City',
      state: 'FL',
    },
    33566: {
      lat: 27.99241,
      lng: -82.1172,
      city: 'Plant City',
      state: 'FL',
    },
    33567: {
      lat: 27.92159,
      lng: -82.1217,
      city: 'Plant City',
      state: 'FL',
    },
    33569: {
      lat: 27.84429,
      lng: -82.29069,
      city: 'Riverview',
      state: 'FL',
    },
    33570: {
      lat: 27.69354,
      lng: -82.45861,
      city: 'Ruskin',
      state: 'FL',
    },
    33572: {
      lat: 27.7659,
      lng: -82.39999,
      city: 'Apollo Beach',
      state: 'FL',
    },
    33573: {
      lat: 27.72179,
      lng: -82.36,
      city: 'Sun City Center',
      state: 'FL',
    },
    33574: {
      lat: 28.33811,
      lng: -82.257,
      city: 'Saint Leo',
      state: 'FL',
    },
    33576: {
      lat: 28.32472,
      lng: -82.31682,
      city: 'San Antonio',
      state: 'FL',
    },
    33578: {
      lat: 27.86187,
      lng: -82.34775,
      city: 'Riverview',
      state: 'FL',
    },
    33579: {
      lat: 27.79585,
      lng: -82.28376,
      city: 'Riverview',
      state: 'FL',
    },
    33584: {
      lat: 28.00637,
      lng: -82.28959,
      city: 'Seffner',
      state: 'FL',
    },
    33585: {
      lat: 28.74089,
      lng: -82.03347,
      city: 'Sumterville',
      state: 'FL',
    },
    33592: {
      lat: 28.10087,
      lng: -82.28698,
      city: 'Thonotosassa',
      state: 'FL',
    },
    33594: {
      lat: 27.94081,
      lng: -82.24207,
      city: 'Valrico',
      state: 'FL',
    },
    33596: {
      lat: 27.88765,
      lng: -82.23591,
      city: 'Valrico',
      state: 'FL',
    },
    33597: {
      lat: 28.52675,
      lng: -82.08613,
      city: 'Webster',
      state: 'FL',
    },
    33598: {
      lat: 27.69531,
      lng: -82.29025,
      city: 'Wimauma',
      state: 'FL',
    },
    33602: {
      lat: 27.95371,
      lng: -82.45675,
      city: 'Tampa',
      state: 'FL',
    },
    33603: {
      lat: 27.98526,
      lng: -82.46458,
      city: 'Tampa',
      state: 'FL',
    },
    33604: {
      lat: 28.01686,
      lng: -82.45519,
      city: 'Tampa',
      state: 'FL',
    },
    33605: {
      lat: 27.95121,
      lng: -82.42924,
      city: 'Tampa',
      state: 'FL',
    },
    33606: {
      lat: 27.93209,
      lng: -82.46493,
      city: 'Tampa',
      state: 'FL',
    },
    33607: {
      lat: 27.96677,
      lng: -82.51408,
      city: 'Tampa',
      state: 'FL',
    },
    33609: {
      lat: 27.94307,
      lng: -82.51108,
      city: 'Tampa',
      state: 'FL',
    },
    33610: { lat: 27.9972, lng: -82.38015, city: 'Tampa', state: 'FL' },
    33611: {
      lat: 27.89059,
      lng: -82.50632,
      city: 'Tampa',
      state: 'FL',
    },
    33612: { lat: 28.05071, lng: -82.451, city: 'Tampa', state: 'FL' },
    33613: {
      lat: 28.08344,
      lng: -82.44873,
      city: 'Tampa',
      state: 'FL',
    },
    33614: {
      lat: 28.00619,
      lng: -82.50598,
      city: 'Tampa',
      state: 'FL',
    },
    33615: {
      lat: 28.00546,
      lng: -82.58144,
      city: 'Tampa',
      state: 'FL',
    },
    33616: { lat: 27.86712, lng: -82.5287, city: 'Tampa', state: 'FL' },
    33617: {
      lat: 28.03893,
      lng: -82.39327,
      city: 'Tampa',
      state: 'FL',
    },
    33618: {
      lat: 28.07313,
      lng: -82.49781,
      city: 'Tampa',
      state: 'FL',
    },
    33619: {
      lat: 27.93536,
      lng: -82.37841,
      city: 'Tampa',
      state: 'FL',
    },
    33620: {
      lat: 28.06112,
      lng: -82.41172,
      city: 'Tampa',
      state: 'FL',
    },
    33621: { lat: 27.8436, lng: -82.50089, city: 'Tampa', state: 'FL' },
    33624: {
      lat: 28.07903,
      lng: -82.52608,
      city: 'Tampa',
      state: 'FL',
    },
    33625: {
      lat: 28.06807,
      lng: -82.56016,
      city: 'Tampa',
      state: 'FL',
    },
    33626: {
      lat: 28.06256,
      lng: -82.61526,
      city: 'Tampa',
      state: 'FL',
    },
    33629: {
      lat: 27.92181,
      lng: -82.51007,
      city: 'Tampa',
      state: 'FL',
    },
    33634: {
      lat: 28.00769,
      lng: -82.54614,
      city: 'Tampa',
      state: 'FL',
    },
    33635: {
      lat: 28.02408,
      lng: -82.61882,
      city: 'Tampa',
      state: 'FL',
    },
    33637: {
      lat: 28.04715,
      lng: -82.36118,
      city: 'Tampa',
      state: 'FL',
    },
    33647: {
      lat: 28.13401,
      lng: -82.36017,
      city: 'Tampa',
      state: 'FL',
    },
    33701: {
      lat: 27.7705,
      lng: -82.63566,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33702: {
      lat: 27.84567,
      lng: -82.63251,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33703: {
      lat: 27.81756,
      lng: -82.62433,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33704: {
      lat: 27.79666,
      lng: -82.63292,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33705: {
      lat: 27.73641,
      lng: -82.64265,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33706: {
      lat: 27.74396,
      lng: -82.75014,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33707: {
      lat: 27.74913,
      lng: -82.72293,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33708: {
      lat: 27.81192,
      lng: -82.7933,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33709: {
      lat: 27.81721,
      lng: -82.73075,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33710: {
      lat: 27.7898,
      lng: -82.73069,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33711: {
      lat: 27.73884,
      lng: -82.68845,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33712: {
      lat: 27.73719,
      lng: -82.66535,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33713: {
      lat: 27.78882,
      lng: -82.67753,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33714: {
      lat: 27.81696,
      lng: -82.67794,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33715: {
      lat: 27.66323,
      lng: -82.72214,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33716: {
      lat: 27.8774,
      lng: -82.65119,
      city: 'Saint Petersburg',
      state: 'FL',
    },
    33744: {
      lat: 27.8084,
      lng: -82.77451,
      city: 'Bay Pines',
      state: 'FL',
    },
    33755: {
      lat: 27.9793,
      lng: -82.78007,
      city: 'Clearwater',
      state: 'FL',
    },
    33756: {
      lat: 27.94421,
      lng: -82.79216,
      city: 'Clearwater',
      state: 'FL',
    },
    33759: {
      lat: 27.98095,
      lng: -82.7145,
      city: 'Clearwater',
      state: 'FL',
    },
    33760: {
      lat: 27.90535,
      lng: -82.71389,
      city: 'Clearwater',
      state: 'FL',
    },
    33761: {
      lat: 28.03103,
      lng: -82.72511,
      city: 'Clearwater',
      state: 'FL',
    },
    33762: {
      lat: 27.89685,
      lng: -82.67946,
      city: 'Clearwater',
      state: 'FL',
    },
    33763: {
      lat: 28.00298,
      lng: -82.74347,
      city: 'Clearwater',
      state: 'FL',
    },
    33764: {
      lat: 27.93679,
      lng: -82.7424,
      city: 'Clearwater',
      state: 'FL',
    },
    33765: {
      lat: 27.97452,
      lng: -82.74419,
      city: 'Clearwater',
      state: 'FL',
    },
    33767: {
      lat: 27.97022,
      lng: -82.82329,
      city: 'Clearwater Beach',
      state: 'FL',
    },
    33770: { lat: 27.91477, lng: -82.8035, city: 'Largo', state: 'FL' },
    33771: { lat: 27.90585, lng: -82.7583, city: 'Largo', state: 'FL' },
    33772: {
      lat: 27.84286,
      lng: -82.79666,
      city: 'Seminole',
      state: 'FL',
    },
    33773: {
      lat: 27.88156,
      lng: -82.75594,
      city: 'Largo',
      state: 'FL',
    },
    33774: { lat: 27.88275, lng: -82.8278, city: 'Largo', state: 'FL' },
    33776: {
      lat: 27.84975,
      lng: -82.82634,
      city: 'Seminole',
      state: 'FL',
    },
    33777: {
      lat: 27.85257,
      lng: -82.76026,
      city: 'Seminole',
      state: 'FL',
    },
    33778: {
      lat: 27.88266,
      lng: -82.79843,
      city: 'Largo',
      state: 'FL',
    },
    33781: {
      lat: 27.83861,
      lng: -82.71512,
      city: 'Pinellas Park',
      state: 'FL',
    },
    33782: {
      lat: 27.85964,
      lng: -82.70901,
      city: 'Pinellas Park',
      state: 'FL',
    },
    33785: {
      lat: 27.88559,
      lng: -82.84425,
      city: 'Indian Rocks Beach',
      state: 'FL',
    },
    33786: {
      lat: 27.92385,
      lng: -82.83635,
      city: 'Belleair Beach',
      state: 'FL',
    },
    33801: {
      lat: 28.0401,
      lng: -81.8947,
      city: 'Lakeland',
      state: 'FL',
    },
    33803: {
      lat: 28.00887,
      lng: -81.93604,
      city: 'Lakeland',
      state: 'FL',
    },
    33805: {
      lat: 28.09829,
      lng: -81.91279,
      city: 'Lakeland',
      state: 'FL',
    },
    33809: {
      lat: 28.20291,
      lng: -81.96597,
      city: 'Lakeland',
      state: 'FL',
    },
    33810: {
      lat: 28.13027,
      lng: -82.03989,
      city: 'Lakeland',
      state: 'FL',
    },
    33811: {
      lat: 27.97933,
      lng: -82.01866,
      city: 'Lakeland',
      state: 'FL',
    },
    33812: {
      lat: 27.97262,
      lng: -81.89304,
      city: 'Lakeland',
      state: 'FL',
    },
    33813: {
      lat: 27.94789,
      lng: -81.92701,
      city: 'Lakeland',
      state: 'FL',
    },
    33815: {
      lat: 28.04067,
      lng: -81.99634,
      city: 'Lakeland',
      state: 'FL',
    },
    33823: {
      lat: 28.09041,
      lng: -81.81267,
      city: 'Auburndale',
      state: 'FL',
    },
    33825: {
      lat: 27.62704,
      lng: -81.44343,
      city: 'Avon Park',
      state: 'FL',
    },
    33827: {
      lat: 27.81445,
      lng: -81.51484,
      city: 'Babson Park',
      state: 'FL',
    },
    33830: {
      lat: 27.88234,
      lng: -81.80565,
      city: 'Bartow',
      state: 'FL',
    },
    33834: {
      lat: 27.62778,
      lng: -81.92115,
      city: 'Bowling Green',
      state: 'FL',
    },
    33835: {
      lat: 27.78829,
      lng: -81.97256,
      city: 'Bradley',
      state: 'FL',
    },
    33837: {
      lat: 28.19012,
      lng: -81.59163,
      city: 'Davenport',
      state: 'FL',
    },
    33838: {
      lat: 28.01851,
      lng: -81.60956,
      city: 'Dundee',
      state: 'FL',
    },
    33839: {
      lat: 27.97481,
      lng: -81.75365,
      city: 'Eagle Lake',
      state: 'FL',
    },
    33840: {
      lat: 28.00915,
      lng: -81.90506,
      city: 'Eaton Park',
      state: 'FL',
    },
    33841: {
      lat: 27.7381,
      lng: -81.7674,
      city: 'Fort Meade',
      state: 'FL',
    },
    33843: {
      lat: 27.73131,
      lng: -81.5108,
      city: 'Frostproof',
      state: 'FL',
    },
    33844: {
      lat: 28.08616,
      lng: -81.58717,
      city: 'Haines City',
      state: 'FL',
    },
    33847: {
      lat: 27.80864,
      lng: -81.82381,
      city: 'Homeland',
      state: 'FL',
    },
    33848: {
      lat: 28.27594,
      lng: -81.50824,
      city: 'Intercession City',
      state: 'FL',
    },
    33849: {
      lat: 28.21613,
      lng: -82.07414,
      city: 'Kathleen',
      state: 'FL',
    },
    33850: {
      lat: 28.11886,
      lng: -81.73191,
      city: 'Lake Alfred',
      state: 'FL',
    },
    33851: {
      lat: 28.04439,
      lng: -81.62677,
      city: 'Lake Hamilton',
      state: 'FL',
    },
    33852: {
      lat: 27.25904,
      lng: -81.37844,
      city: 'Lake Placid',
      state: 'FL',
    },
    33853: {
      lat: 27.90023,
      lng: -81.58556,
      city: 'Lake Wales',
      state: 'FL',
    },
    33854: {
      lat: 27.85608,
      lng: -81.41033,
      city: 'Lakeshore',
      state: 'FL',
    },
    33855: {
      lat: 27.79104,
      lng: -81.35971,
      city: 'Indian Lake Estates',
      state: 'FL',
    },
    33856: {
      lat: 27.85648,
      lng: -81.42828,
      city: 'Nalcrest',
      state: 'FL',
    },
    33857: {
      lat: 27.35647,
      lng: -81.16517,
      city: 'Lorida',
      state: 'FL',
    },
    33858: {
      lat: 28.24506,
      lng: -81.55832,
      city: 'Loughman',
      state: 'FL',
    },
    33859: {
      lat: 27.88428,
      lng: -81.63106,
      city: 'Lake Wales',
      state: 'FL',
    },
    33860: {
      lat: 27.8056,
      lng: -81.99511,
      city: 'Mulberry',
      state: 'FL',
    },
    33865: { lat: 27.42944, lng: -81.96262, city: 'Ona', state: 'FL' },
    33867: {
      lat: 27.78249,
      lng: -81.20136,
      city: 'River Ranch',
      state: 'FL',
    },
    33868: {
      lat: 28.25882,
      lng: -81.84769,
      city: 'Polk City',
      state: 'FL',
    },
    33870: {
      lat: 27.50571,
      lng: -81.36071,
      city: 'Sebring',
      state: 'FL',
    },
    33872: {
      lat: 27.50911,
      lng: -81.53147,
      city: 'Sebring',
      state: 'FL',
    },
    33873: {
      lat: 27.56181,
      lng: -81.77855,
      city: 'Wauchula',
      state: 'FL',
    },
    33875: {
      lat: 27.41813,
      lng: -81.49198,
      city: 'Sebring',
      state: 'FL',
    },
    33876: {
      lat: 27.4269,
      lng: -81.35086,
      city: 'Sebring',
      state: 'FL',
    },
    33877: {
      lat: 27.98766,
      lng: -81.61985,
      city: 'Waverly',
      state: 'FL',
    },
    33880: {
      lat: 27.9914,
      lng: -81.77448,
      city: 'Winter Haven',
      state: 'FL',
    },
    33881: {
      lat: 28.05439,
      lng: -81.70081,
      city: 'Winter Haven',
      state: 'FL',
    },
    33884: {
      lat: 27.97669,
      lng: -81.67653,
      city: 'Winter Haven',
      state: 'FL',
    },
    33890: {
      lat: 27.4558,
      lng: -81.68354,
      city: 'Zolfo Springs',
      state: 'FL',
    },
    33896: {
      lat: 28.24663,
      lng: -81.57526,
      city: 'Davenport',
      state: 'FL',
    },
    33897: {
      lat: 28.28088,
      lng: -81.69533,
      city: 'Davenport',
      state: 'FL',
    },
    33898: {
      lat: 27.83946,
      lng: -81.34943,
      city: 'Lake Wales',
      state: 'FL',
    },
    33901: {
      lat: 26.62304,
      lng: -81.88228,
      city: 'Fort Myers',
      state: 'FL',
    },
    33903: {
      lat: 26.68217,
      lng: -81.90688,
      city: 'North Fort Myers',
      state: 'FL',
    },
    33904: {
      lat: 26.57601,
      lng: -81.94536,
      city: 'Cape Coral',
      state: 'FL',
    },
    33905: {
      lat: 26.66873,
      lng: -81.76166,
      city: 'Fort Myers',
      state: 'FL',
    },
    33907: {
      lat: 26.56447,
      lng: -81.87171,
      city: 'Fort Myers',
      state: 'FL',
    },
    33908: {
      lat: 26.48857,
      lng: -81.91319,
      city: 'Fort Myers',
      state: 'FL',
    },
    33909: {
      lat: 26.72032,
      lng: -81.96443,
      city: 'Cape Coral',
      state: 'FL',
    },
    33912: {
      lat: 26.53288,
      lng: -81.82502,
      city: 'Fort Myers',
      state: 'FL',
    },
    33913: {
      lat: 26.51718,
      lng: -81.69712,
      city: 'Fort Myers',
      state: 'FL',
    },
    33914: {
      lat: 26.57052,
      lng: -82.01944,
      city: 'Cape Coral',
      state: 'FL',
    },
    33916: {
      lat: 26.63998,
      lng: -81.84104,
      city: 'Fort Myers',
      state: 'FL',
    },
    33917: {
      lat: 26.75403,
      lng: -81.84779,
      city: 'North Fort Myers',
      state: 'FL',
    },
    33919: {
      lat: 26.55607,
      lng: -81.90388,
      city: 'Fort Myers',
      state: 'FL',
    },
    33920: { lat: 26.72903, lng: -81.6529, city: 'Alva', state: 'FL' },
    33921: {
      lat: 26.77919,
      lng: -82.25386,
      city: 'Boca Grande',
      state: 'FL',
    },
    33922: {
      lat: 26.66476,
      lng: -82.15897,
      city: 'Bokeelia',
      state: 'FL',
    },
    33924: {
      lat: 26.54521,
      lng: -82.19832,
      city: 'Captiva',
      state: 'FL',
    },
    33928: {
      lat: 26.43545,
      lng: -81.71002,
      city: 'Estero',
      state: 'FL',
    },
    33930: {
      lat: 26.55916,
      lng: -81.40069,
      city: 'Felda',
      state: 'FL',
    },
    33931: {
      lat: 26.44036,
      lng: -81.90974,
      city: 'Fort Myers Beach',
      state: 'FL',
    },
    33935: {
      lat: 26.75692,
      lng: -81.41966,
      city: 'Labelle',
      state: 'FL',
    },
    33936: {
      lat: 26.6292,
      lng: -81.5602,
      city: 'Lehigh Acres',
      state: 'FL',
    },
    33944: {
      lat: 26.97163,
      lng: -81.36924,
      city: 'Palmdale',
      state: 'FL',
    },
    33946: {
      lat: 26.853,
      lng: -82.26958,
      city: 'Placida',
      state: 'FL',
    },
    33947: {
      lat: 26.89015,
      lng: -82.27184,
      city: 'Rotonda West',
      state: 'FL',
    },
    33948: {
      lat: 26.98411,
      lng: -82.15126,
      city: 'Port Charlotte',
      state: 'FL',
    },
    33950: {
      lat: 26.91166,
      lng: -82.04009,
      city: 'Punta Gorda',
      state: 'FL',
    },
    33952: {
      lat: 26.99008,
      lng: -82.0972,
      city: 'Port Charlotte',
      state: 'FL',
    },
    33953: {
      lat: 26.99991,
      lng: -82.21222,
      city: 'Port Charlotte',
      state: 'FL',
    },
    33954: {
      lat: 27.02506,
      lng: -82.12167,
      city: 'Port Charlotte',
      state: 'FL',
    },
    33955: {
      lat: 26.82528,
      lng: -81.97007,
      city: 'Punta Gorda',
      state: 'FL',
    },
    33956: {
      lat: 26.54622,
      lng: -82.09589,
      city: 'Saint James City',
      state: 'FL',
    },
    33957: {
      lat: 26.4534,
      lng: -82.10228,
      city: 'Sanibel',
      state: 'FL',
    },
    33960: {
      lat: 27.07709,
      lng: -81.41682,
      city: 'Venus',
      state: 'FL',
    },
    33965: {
      lat: 26.46126,
      lng: -81.76862,
      city: 'Fort Myers',
      state: 'FL',
    },
    33966: {
      lat: 26.58234,
      lng: -81.83206,
      city: 'Fort Myers',
      state: 'FL',
    },
    33967: {
      lat: 26.47226,
      lng: -81.81231,
      city: 'Fort Myers',
      state: 'FL',
    },
    33971: {
      lat: 26.63856,
      lng: -81.69948,
      city: 'Lehigh Acres',
      state: 'FL',
    },
    33972: {
      lat: 26.64954,
      lng: -81.61721,
      city: 'Lehigh Acres',
      state: 'FL',
    },
    33973: {
      lat: 26.6014,
      lng: -81.73012,
      city: 'Lehigh Acres',
      state: 'FL',
    },
    33974: {
      lat: 26.56205,
      lng: -81.60271,
      city: 'Lehigh Acres',
      state: 'FL',
    },
    33976: {
      lat: 26.59128,
      lng: -81.68543,
      city: 'Lehigh Acres',
      state: 'FL',
    },
    33980: {
      lat: 26.98062,
      lng: -82.05206,
      city: 'Punta Gorda',
      state: 'FL',
    },
    33981: {
      lat: 26.87544,
      lng: -82.21047,
      city: 'Port Charlotte',
      state: 'FL',
    },
    33982: {
      lat: 26.91587,
      lng: -81.75753,
      city: 'Punta Gorda',
      state: 'FL',
    },
    33983: {
      lat: 27.00712,
      lng: -82.01508,
      city: 'Punta Gorda',
      state: 'FL',
    },
    33990: {
      lat: 26.62875,
      lng: -81.94602,
      city: 'Cape Coral',
      state: 'FL',
    },
    33991: {
      lat: 26.63074,
      lng: -82.01065,
      city: 'Cape Coral',
      state: 'FL',
    },
    33993: {
      lat: 26.67194,
      lng: -82.04522,
      city: 'Cape Coral',
      state: 'FL',
    },
    34101: {
      lat: 26.05694,
      lng: -81.78056,
      city: 'Naples',
      state: 'FL',
    },
    34102: {
      lat: 26.13783,
      lng: -81.79654,
      city: 'Naples',
      state: 'FL',
    },
    34103: {
      lat: 26.19197,
      lng: -81.80207,
      city: 'Naples',
      state: 'FL',
    },
    34104: {
      lat: 26.15321,
      lng: -81.74214,
      city: 'Naples',
      state: 'FL',
    },
    34105: {
      lat: 26.18938,
      lng: -81.7626,
      city: 'Naples',
      state: 'FL',
    },
    34108: {
      lat: 26.24533,
      lng: -81.80694,
      city: 'Naples',
      state: 'FL',
    },
    34109: {
      lat: 26.24073,
      lng: -81.76365,
      city: 'Naples',
      state: 'FL',
    },
    34110: {
      lat: 26.29619,
      lng: -81.78505,
      city: 'Naples',
      state: 'FL',
    },
    34112: {
      lat: 26.12053,
      lng: -81.73908,
      city: 'Naples',
      state: 'FL',
    },
    34113: {
      lat: 26.05685,
      lng: -81.73523,
      city: 'Naples',
      state: 'FL',
    },
    34114: {
      lat: 25.98902,
      lng: -81.58517,
      city: 'Naples',
      state: 'FL',
    },
    34116: {
      lat: 26.18816,
      lng: -81.71058,
      city: 'Naples',
      state: 'FL',
    },
    34117: { lat: 26.11864, lng: -81.548, city: 'Naples', state: 'FL' },
    34119: {
      lat: 26.26658,
      lng: -81.71493,
      city: 'Naples',
      state: 'FL',
    },
    34120: {
      lat: 26.31382,
      lng: -81.58344,
      city: 'Naples',
      state: 'FL',
    },
    34134: {
      lat: 26.36634,
      lng: -81.83503,
      city: 'Bonita Springs',
      state: 'FL',
    },
    34135: {
      lat: 26.36923,
      lng: -81.73303,
      city: 'Bonita Springs',
      state: 'FL',
    },
    34137: {
      lat: 26.03542,
      lng: -81.40757,
      city: 'Copeland',
      state: 'FL',
    },
    34138: {
      lat: 25.82255,
      lng: -81.37084,
      city: 'Chokoloskee',
      state: 'FL',
    },
    34139: {
      lat: 25.85449,
      lng: -81.38253,
      city: 'Everglades City',
      state: 'FL',
    },
    34140: {
      lat: 25.92531,
      lng: -81.65888,
      city: 'Goodland',
      state: 'FL',
    },
    34141: {
      lat: 25.91774,
      lng: -81.07706,
      city: 'Ochopee',
      state: 'FL',
    },
    34142: {
      lat: 26.31392,
      lng: -81.28601,
      city: 'Immokalee',
      state: 'FL',
    },
    34145: {
      lat: 25.91903,
      lng: -81.69506,
      city: 'Marco Island',
      state: 'FL',
    },
    34201: {
      lat: 27.4029,
      lng: -82.46784,
      city: 'Bradenton',
      state: 'FL',
    },
    34202: {
      lat: 27.40722,
      lng: -82.39254,
      city: 'Bradenton',
      state: 'FL',
    },
    34203: {
      lat: 27.44319,
      lng: -82.50834,
      city: 'Bradenton',
      state: 'FL',
    },
    34205: {
      lat: 27.48418,
      lng: -82.5835,
      city: 'Bradenton',
      state: 'FL',
    },
    34207: {
      lat: 27.43762,
      lng: -82.57993,
      city: 'Bradenton',
      state: 'FL',
    },
    34208: {
      lat: 27.48693,
      lng: -82.51404,
      city: 'Bradenton',
      state: 'FL',
    },
    34209: {
      lat: 27.49489,
      lng: -82.6433,
      city: 'Bradenton',
      state: 'FL',
    },
    34210: {
      lat: 27.43897,
      lng: -82.63117,
      city: 'Bradenton',
      state: 'FL',
    },
    34211: {
      lat: 27.44944,
      lng: -82.37738,
      city: 'Bradenton',
      state: 'FL',
    },
    34212: {
      lat: 27.49559,
      lng: -82.40528,
      city: 'Bradenton',
      state: 'FL',
    },
    34215: {
      lat: 27.47444,
      lng: -82.68409,
      city: 'Cortez',
      state: 'FL',
    },
    34216: {
      lat: 27.52837,
      lng: -82.7331,
      city: 'Anna Maria',
      state: 'FL',
    },
    34217: {
      lat: 27.48916,
      lng: -82.7044,
      city: 'Bradenton Beach',
      state: 'FL',
    },
    34219: {
      lat: 27.58041,
      lng: -82.26094,
      city: 'Parrish',
      state: 'FL',
    },
    34221: {
      lat: 27.57777,
      lng: -82.54285,
      city: 'Palmetto',
      state: 'FL',
    },
    34222: {
      lat: 27.53474,
      lng: -82.50699,
      city: 'Ellenton',
      state: 'FL',
    },
    34223: {
      lat: 26.97963,
      lng: -82.33257,
      city: 'Englewood',
      state: 'FL',
    },
    34224: {
      lat: 26.91902,
      lng: -82.30581,
      city: 'Englewood',
      state: 'FL',
    },
    34228: {
      lat: 27.39258,
      lng: -82.63407,
      city: 'Longboat Key',
      state: 'FL',
    },
    34229: {
      lat: 27.19159,
      lng: -82.48309,
      city: 'Osprey',
      state: 'FL',
    },
    34231: {
      lat: 27.26651,
      lng: -82.51626,
      city: 'Sarasota',
      state: 'FL',
    },
    34232: {
      lat: 27.32601,
      lng: -82.47239,
      city: 'Sarasota',
      state: 'FL',
    },
    34233: {
      lat: 27.2836,
      lng: -82.47311,
      city: 'Sarasota',
      state: 'FL',
    },
    34234: {
      lat: 27.36934,
      lng: -82.5574,
      city: 'Sarasota',
      state: 'FL',
    },
    34235: {
      lat: 27.36673,
      lng: -82.47724,
      city: 'Sarasota',
      state: 'FL',
    },
    34236: {
      lat: 27.32964,
      lng: -82.55874,
      city: 'Sarasota',
      state: 'FL',
    },
    34237: {
      lat: 27.33761,
      lng: -82.51409,
      city: 'Sarasota',
      state: 'FL',
    },
    34238: {
      lat: 27.23049,
      lng: -82.4687,
      city: 'Sarasota',
      state: 'FL',
    },
    34239: {
      lat: 27.31094,
      lng: -82.52179,
      city: 'Sarasota',
      state: 'FL',
    },
    34240: {
      lat: 27.34218,
      lng: -82.35177,
      city: 'Sarasota',
      state: 'FL',
    },
    34241: {
      lat: 27.24944,
      lng: -82.37305,
      city: 'Sarasota',
      state: 'FL',
    },
    34242: {
      lat: 27.26237,
      lng: -82.53997,
      city: 'Sarasota',
      state: 'FL',
    },
    34243: {
      lat: 27.40264,
      lng: -82.52809,
      city: 'Sarasota',
      state: 'FL',
    },
    34250: {
      lat: 27.57168,
      lng: -82.58923,
      city: 'Terra Ceia',
      state: 'FL',
    },
    34251: {
      lat: 27.37819,
      lng: -82.17152,
      city: 'Myakka City',
      state: 'FL',
    },
    34266: {
      lat: 27.19895,
      lng: -81.80304,
      city: 'Arcadia',
      state: 'FL',
    },
    34267: {
      lat: 27.0893,
      lng: -81.9543,
      city: 'Fort Ogden',
      state: 'FL',
    },
    34268: {
      lat: 27.14459,
      lng: -81.91775,
      city: 'Nocatee',
      state: 'FL',
    },
    34269: {
      lat: 27.08865,
      lng: -81.9639,
      city: 'Arcadia',
      state: 'FL',
    },
    34275: {
      lat: 27.15203,
      lng: -82.41966,
      city: 'Nokomis',
      state: 'FL',
    },
    34285: {
      lat: 27.0918,
      lng: -82.43408,
      city: 'Venice',
      state: 'FL',
    },
    34286: {
      lat: 27.09099,
      lng: -82.14147,
      city: 'North Port',
      state: 'FL',
    },
    34287: {
      lat: 27.05735,
      lng: -82.24677,
      city: 'North Port',
      state: 'FL',
    },
    34288: {
      lat: 27.05385,
      lng: -82.11161,
      city: 'North Port',
      state: 'FL',
    },
    34289: {
      lat: 27.08587,
      lng: -82.13664,
      city: 'North Port',
      state: 'FL',
    },
    34291: {
      lat: 27.11494,
      lng: -82.21722,
      city: 'North Port',
      state: 'FL',
    },
    34292: {
      lat: 27.10308,
      lng: -82.33496,
      city: 'Venice',
      state: 'FL',
    },
    34293: {
      lat: 27.04642,
      lng: -82.35595,
      city: 'Venice',
      state: 'FL',
    },
    34420: {
      lat: 29.05359,
      lng: -82.04008,
      city: 'Belleview',
      state: 'FL',
    },
    34428: {
      lat: 28.9608,
      lng: -82.63421,
      city: 'Crystal River',
      state: 'FL',
    },
    34429: {
      lat: 28.85737,
      lng: -82.65152,
      city: 'Crystal River',
      state: 'FL',
    },
    34431: {
      lat: 29.1339,
      lng: -82.52963,
      city: 'Dunnellon',
      state: 'FL',
    },
    34432: {
      lat: 29.06588,
      lng: -82.36353,
      city: 'Dunnellon',
      state: 'FL',
    },
    34433: {
      lat: 28.996,
      lng: -82.52383,
      city: 'Dunnellon',
      state: 'FL',
    },
    34434: {
      lat: 28.99535,
      lng: -82.43775,
      city: 'Dunnellon',
      state: 'FL',
    },
    34436: {
      lat: 28.72672,
      lng: -82.29134,
      city: 'Floral City',
      state: 'FL',
    },
    34442: {
      lat: 28.93084,
      lng: -82.39122,
      city: 'Hernando',
      state: 'FL',
    },
    34445: {
      lat: 28.97143,
      lng: -82.42414,
      city: 'Holder',
      state: 'FL',
    },
    34446: {
      lat: 28.74904,
      lng: -82.52344,
      city: 'Homosassa',
      state: 'FL',
    },
    34448: {
      lat: 28.77009,
      lng: -82.61201,
      city: 'Homosassa',
      state: 'FL',
    },
    34449: {
      lat: 29.10451,
      lng: -82.68694,
      city: 'Inglis',
      state: 'FL',
    },
    34450: {
      lat: 28.82699,
      lng: -82.25767,
      city: 'Inverness',
      state: 'FL',
    },
    34452: {
      lat: 28.76231,
      lng: -82.37986,
      city: 'Inverness',
      state: 'FL',
    },
    34453: {
      lat: 28.89276,
      lng: -82.33663,
      city: 'Inverness',
      state: 'FL',
    },
    34461: {
      lat: 28.81544,
      lng: -82.48029,
      city: 'Lecanto',
      state: 'FL',
    },
    34465: {
      lat: 28.92869,
      lng: -82.49092,
      city: 'Beverly Hills',
      state: 'FL',
    },
    34470: {
      lat: 29.20098,
      lng: -82.08262,
      city: 'Ocala',
      state: 'FL',
    },
    34471: {
      lat: 29.16054,
      lng: -82.12786,
      city: 'Ocala',
      state: 'FL',
    },
    34472: {
      lat: 29.11187,
      lng: -81.99625,
      city: 'Ocala',
      state: 'FL',
    },
    34473: {
      lat: 29.00506,
      lng: -82.18833,
      city: 'Ocala',
      state: 'FL',
    },
    34474: {
      lat: 29.15515,
      lng: -82.21106,
      city: 'Ocala',
      state: 'FL',
    },
    34475: {
      lat: 29.25282,
      lng: -82.16065,
      city: 'Ocala',
      state: 'FL',
    },
    34476: {
      lat: 29.08579,
      lng: -82.19279,
      city: 'Ocala',
      state: 'FL',
    },
    34479: {
      lat: 29.25285,
      lng: -82.10945,
      city: 'Ocala',
      state: 'FL',
    },
    34480: {
      lat: 29.10178,
      lng: -82.09975,
      city: 'Ocala',
      state: 'FL',
    },
    34481: {
      lat: 29.11902,
      lng: -82.30769,
      city: 'Ocala',
      state: 'FL',
    },
    34482: {
      lat: 29.24984,
      lng: -82.27702,
      city: 'Ocala',
      state: 'FL',
    },
    34484: {
      lat: 28.93417,
      lng: -82.08867,
      city: 'Oxford',
      state: 'FL',
    },
    34487: {
      lat: 28.78148,
      lng: -82.61272,
      city: 'Homosassa',
      state: 'FL',
    },
    34488: {
      lat: 29.22584,
      lng: -81.93226,
      city: 'Silver Springs',
      state: 'FL',
    },
    34491: {
      lat: 28.99634,
      lng: -82.04665,
      city: 'Summerfield',
      state: 'FL',
    },
    34498: {
      lat: 29.03299,
      lng: -82.734,
      city: 'Yankeetown',
      state: 'FL',
    },
    34601: {
      lat: 28.5892,
      lng: -82.36857,
      city: 'Brooksville',
      state: 'FL',
    },
    34602: {
      lat: 28.50252,
      lng: -82.28007,
      city: 'Brooksville',
      state: 'FL',
    },
    34604: {
      lat: 28.4781,
      lng: -82.43322,
      city: 'Brooksville',
      state: 'FL',
    },
    34606: {
      lat: 28.46961,
      lng: -82.59759,
      city: 'Spring Hill',
      state: 'FL',
    },
    34607: {
      lat: 28.57528,
      lng: -82.62715,
      city: 'Spring Hill',
      state: 'FL',
    },
    34608: {
      lat: 28.48258,
      lng: -82.5532,
      city: 'Spring Hill',
      state: 'FL',
    },
    34609: {
      lat: 28.4792,
      lng: -82.50818,
      city: 'Spring Hill',
      state: 'FL',
    },
    34610: {
      lat: 28.38339,
      lng: -82.50058,
      city: 'Spring Hill',
      state: 'FL',
    },
    34613: {
      lat: 28.56183,
      lng: -82.53803,
      city: 'Brooksville',
      state: 'FL',
    },
    34614: {
      lat: 28.64627,
      lng: -82.51324,
      city: 'Brooksville',
      state: 'FL',
    },
    34637: {
      lat: 28.29589,
      lng: -82.46368,
      city: "Land O'Lakes",
      state: 'FL',
    },
    34638: {
      lat: 28.26035,
      lng: -82.52036,
      city: "Land O'Lakes",
      state: 'FL',
    },
    34639: {
      lat: 28.24456,
      lng: -82.42475,
      city: "Land O'Lakes",
      state: 'FL',
    },
    34652: {
      lat: 28.2368,
      lng: -82.73661,
      city: 'New Port Richey',
      state: 'FL',
    },
    34653: {
      lat: 28.24266,
      lng: -82.69308,
      city: 'New Port Richey',
      state: 'FL',
    },
    34654: {
      lat: 28.29757,
      lng: -82.62904,
      city: 'New Port Richey',
      state: 'FL',
    },
    34655: {
      lat: 28.21201,
      lng: -82.65516,
      city: 'New Port Richey',
      state: 'FL',
    },
    34661: {
      lat: 28.62108,
      lng: -82.26507,
      city: 'Nobleton',
      state: 'FL',
    },
    34667: {
      lat: 28.38926,
      lng: -82.65654,
      city: 'Hudson',
      state: 'FL',
    },
    34668: {
      lat: 28.30176,
      lng: -82.70516,
      city: 'Port Richey',
      state: 'FL',
    },
    34669: {
      lat: 28.3577,
      lng: -82.61476,
      city: 'Hudson',
      state: 'FL',
    },
    34677: {
      lat: 28.05293,
      lng: -82.67549,
      city: 'Oldsmar',
      state: 'FL',
    },
    34679: {
      lat: 28.42226,
      lng: -82.67716,
      city: 'Aripeka',
      state: 'FL',
    },
    34681: {
      lat: 28.0903,
      lng: -82.77801,
      city: 'Crystal Beach',
      state: 'FL',
    },
    34683: {
      lat: 28.08626,
      lng: -82.76025,
      city: 'Palm Harbor',
      state: 'FL',
    },
    34684: {
      lat: 28.08122,
      lng: -82.72779,
      city: 'Palm Harbor',
      state: 'FL',
    },
    34685: {
      lat: 28.09995,
      lng: -82.68851,
      city: 'Palm Harbor',
      state: 'FL',
    },
    34688: {
      lat: 28.14445,
      lng: -82.68543,
      city: 'Tarpon Springs',
      state: 'FL',
    },
    34689: {
      lat: 28.14971,
      lng: -82.75956,
      city: 'Tarpon Springs',
      state: 'FL',
    },
    34690: {
      lat: 28.19228,
      lng: -82.72726,
      city: 'Holiday',
      state: 'FL',
    },
    34691: {
      lat: 28.19042,
      lng: -82.76603,
      city: 'Holiday',
      state: 'FL',
    },
    34695: {
      lat: 28.00927,
      lng: -82.69673,
      city: 'Safety Harbor',
      state: 'FL',
    },
    34698: {
      lat: 28.03204,
      lng: -82.78433,
      city: 'Dunedin',
      state: 'FL',
    },
    34705: {
      lat: 28.69367,
      lng: -81.71138,
      city: 'Astatula',
      state: 'FL',
    },
    34711: {
      lat: 28.52724,
      lng: -81.75349,
      city: 'Clermont',
      state: 'FL',
    },
    34714: {
      lat: 28.40406,
      lng: -81.80132,
      city: 'Clermont',
      state: 'FL',
    },
    34715: {
      lat: 28.6242,
      lng: -81.73019,
      city: 'Clermont',
      state: 'FL',
    },
    34729: {
      lat: 28.61764,
      lng: -81.69901,
      city: 'Ferndale',
      state: 'FL',
    },
    34731: {
      lat: 28.86788,
      lng: -81.89638,
      city: 'Fruitland Park',
      state: 'FL',
    },
    34734: { lat: 28.5376, lng: -81.52204, city: 'Gotha', state: 'FL' },
    34736: {
      lat: 28.57883,
      lng: -81.88844,
      city: 'Groveland',
      state: 'FL',
    },
    34737: {
      lat: 28.69638,
      lng: -81.80243,
      city: 'Howey In The Hills',
      state: 'FL',
    },
    34739: {
      lat: 27.92236,
      lng: -81.10762,
      city: 'Kenansville',
      state: 'FL',
    },
    34741: {
      lat: 28.30544,
      lng: -81.42567,
      city: 'Kissimmee',
      state: 'FL',
    },
    34743: {
      lat: 28.33066,
      lng: -81.35441,
      city: 'Kissimmee',
      state: 'FL',
    },
    34744: {
      lat: 28.2954,
      lng: -81.34675,
      city: 'Kissimmee',
      state: 'FL',
    },
    34746: {
      lat: 28.24233,
      lng: -81.4423,
      city: 'Kissimmee',
      state: 'FL',
    },
    34747: {
      lat: 28.31111,
      lng: -81.59107,
      city: 'Kissimmee',
      state: 'FL',
    },
    34748: {
      lat: 28.77366,
      lng: -81.88552,
      city: 'Leesburg',
      state: 'FL',
    },
    34753: {
      lat: 28.58684,
      lng: -81.89624,
      city: 'Mascotte',
      state: 'FL',
    },
    34756: {
      lat: 28.58651,
      lng: -81.68214,
      city: 'Montverde',
      state: 'FL',
    },
    34758: {
      lat: 28.19142,
      lng: -81.4871,
      city: 'Kissimmee',
      state: 'FL',
    },
    34759: {
      lat: 28.09113,
      lng: -81.43774,
      city: 'Kissimmee',
      state: 'FL',
    },
    34760: {
      lat: 28.55694,
      lng: -81.62916,
      city: 'Oakland',
      state: 'FL',
    },
    34761: {
      lat: 28.57975,
      lng: -81.53386,
      city: 'Ocoee',
      state: 'FL',
    },
    34762: {
      lat: 28.75772,
      lng: -81.9152,
      city: 'The Villages',
      state: 'FL',
    },
    34769: {
      lat: 28.24366,
      lng: -81.29412,
      city: 'Saint Cloud',
      state: 'FL',
    },
    34771: {
      lat: 28.26647,
      lng: -81.11908,
      city: 'Saint Cloud',
      state: 'FL',
    },
    34772: {
      lat: 28.16534,
      lng: -81.27273,
      city: 'Saint Cloud',
      state: 'FL',
    },
    34773: {
      lat: 28.14695,
      lng: -80.99968,
      city: 'Saint Cloud',
      state: 'FL',
    },
    34785: {
      lat: 28.84516,
      lng: -82.06065,
      city: 'Wildwood',
      state: 'FL',
    },
    34786: {
      lat: 28.48161,
      lng: -81.55501,
      city: 'Windermere',
      state: 'FL',
    },
    34787: {
      lat: 28.48309,
      lng: -81.62833,
      city: 'Winter Garden',
      state: 'FL',
    },
    34788: {
      lat: 28.88542,
      lng: -81.79678,
      city: 'Leesburg',
      state: 'FL',
    },
    34797: {
      lat: 28.73517,
      lng: -81.81813,
      city: 'Yalaha',
      state: 'FL',
    },
    34945: {
      lat: 27.45219,
      lng: -80.55213,
      city: 'Fort Pierce',
      state: 'FL',
    },
    34946: {
      lat: 27.50456,
      lng: -80.36106,
      city: 'Fort Pierce',
      state: 'FL',
    },
    34947: {
      lat: 27.44903,
      lng: -80.37633,
      city: 'Fort Pierce',
      state: 'FL',
    },
    34949: {
      lat: 27.4668,
      lng: -80.30417,
      city: 'Fort Pierce',
      state: 'FL',
    },
    34950: {
      lat: 27.44341,
      lng: -80.32969,
      city: 'Fort Pierce',
      state: 'FL',
    },
    34951: {
      lat: 27.52268,
      lng: -80.41783,
      city: 'Fort Pierce',
      state: 'FL',
    },
    34952: {
      lat: 27.2969,
      lng: -80.29748,
      city: 'Port Saint Lucie',
      state: 'FL',
    },
    34953: {
      lat: 27.24938,
      lng: -80.38137,
      city: 'Port Saint Lucie',
      state: 'FL',
    },
    34956: {
      lat: 27.07969,
      lng: -80.50074,
      city: 'Indiantown',
      state: 'FL',
    },
    34957: {
      lat: 27.28399,
      lng: -80.24252,
      city: 'Jensen Beach',
      state: 'FL',
    },
    34972: {
      lat: 27.48016,
      lng: -80.90926,
      city: 'Okeechobee',
      state: 'FL',
    },
    34974: {
      lat: 27.15062,
      lng: -80.91371,
      city: 'Okeechobee',
      state: 'FL',
    },
    34981: {
      lat: 27.39329,
      lng: -80.37163,
      city: 'Fort Pierce',
      state: 'FL',
    },
    34982: {
      lat: 27.37669,
      lng: -80.31082,
      city: 'Fort Pierce',
      state: 'FL',
    },
    34983: {
      lat: 27.32295,
      lng: -80.35052,
      city: 'Port Saint Lucie',
      state: 'FL',
    },
    34984: {
      lat: 27.24784,
      lng: -80.33456,
      city: 'Port Saint Lucie',
      state: 'FL',
    },
    34986: {
      lat: 27.32804,
      lng: -80.40524,
      city: 'Port Saint Lucie',
      state: 'FL',
    },
    34987: {
      lat: 27.28637,
      lng: -80.50953,
      city: 'Port Saint Lucie',
      state: 'FL',
    },
    34990: {
      lat: 27.13623,
      lng: -80.35655,
      city: 'Palm City',
      state: 'FL',
    },
    34994: {
      lat: 27.19954,
      lng: -80.2557,
      city: 'Stuart',
      state: 'FL',
    },
    34996: {
      lat: 27.20071,
      lng: -80.19644,
      city: 'Stuart',
      state: 'FL',
    },
    34997: {
      lat: 27.05085,
      lng: -80.27268,
      city: 'Stuart',
      state: 'FL',
    },
    35004: {
      lat: 33.60369,
      lng: -86.49333,
      city: 'Moody',
      state: 'AL',
    },
    35005: {
      lat: 33.59215,
      lng: -86.99833,
      city: 'Adamsville',
      state: 'AL',
    },
    35006: {
      lat: 33.43159,
      lng: -87.19961,
      city: 'Adger',
      state: 'AL',
    },
    35007: {
      lat: 33.21751,
      lng: -86.79307,
      city: 'Alabaster',
      state: 'AL',
    },
    35010: {
      lat: 32.91433,
      lng: -85.93816,
      city: 'Alexander City',
      state: 'AL',
    },
    35013: {
      lat: 33.90166,
      lng: -86.51785,
      city: 'Allgood',
      state: 'AL',
    },
    35014: {
      lat: 33.36097,
      lng: -86.25909,
      city: 'Alpine',
      state: 'AL',
    },
    35016: { lat: 34.32545, lng: -86.5033, city: 'Arab', state: 'AL' },
    35019: {
      lat: 34.29828,
      lng: -86.63496,
      city: 'Baileyton',
      state: 'AL',
    },
    35020: {
      lat: 33.40502,
      lng: -86.95436,
      city: 'Bessemer',
      state: 'AL',
    },
    35022: {
      lat: 33.33107,
      lng: -86.95959,
      city: 'Bessemer',
      state: 'AL',
    },
    35023: {
      lat: 33.46674,
      lng: -87.08891,
      city: 'Bessemer',
      state: 'AL',
    },
    35031: {
      lat: 34.10256,
      lng: -86.5455,
      city: 'Blountsville',
      state: 'AL',
    },
    35032: {
      lat: 33.26321,
      lng: -86.33408,
      city: 'Bon Air',
      state: 'AL',
    },
    35033: {
      lat: 33.94517,
      lng: -87.02904,
      city: 'Bremen',
      state: 'AL',
    },
    35034: { lat: 32.9016, lng: -87.26948, city: 'Brent', state: 'AL' },
    35035: {
      lat: 33.04423,
      lng: -86.97149,
      city: 'Brierfield',
      state: 'AL',
    },
    35036: {
      lat: 33.64095,
      lng: -86.91601,
      city: 'Brookside',
      state: 'AL',
    },
    35040: {
      lat: 33.10487,
      lng: -86.72958,
      city: 'Calera',
      state: 'AL',
    },
    35042: {
      lat: 32.93297,
      lng: -87.12542,
      city: 'Centreville',
      state: 'AL',
    },
    35043: {
      lat: 33.30926,
      lng: -86.66028,
      city: 'Chelsea',
      state: 'AL',
    },
    35044: {
      lat: 33.26859,
      lng: -86.36324,
      city: 'Childersburg',
      state: 'AL',
    },
    35045: {
      lat: 32.81127,
      lng: -86.67249,
      city: 'Clanton',
      state: 'AL',
    },
    35046: {
      lat: 32.923,
      lng: -86.54127,
      city: 'Clanton',
      state: 'AL',
    },
    35049: {
      lat: 33.95674,
      lng: -86.59904,
      city: 'Cleveland',
      state: 'AL',
    },
    35051: {
      lat: 33.20457,
      lng: -86.61523,
      city: 'Columbiana',
      state: 'AL',
    },
    35052: {
      lat: 33.59228,
      lng: -86.40771,
      city: 'Cook Springs',
      state: 'AL',
    },
    35053: {
      lat: 34.05495,
      lng: -87.06527,
      city: 'Crane Hill',
      state: 'AL',
    },
    35054: {
      lat: 33.50417,
      lng: -86.32693,
      city: 'Cropwell',
      state: 'AL',
    },
    35055: {
      lat: 34.1542,
      lng: -86.75932,
      city: 'Cullman',
      state: 'AL',
    },
    35057: {
      lat: 34.13788,
      lng: -86.95876,
      city: 'Cullman',
      state: 'AL',
    },
    35058: {
      lat: 34.23082,
      lng: -86.73265,
      city: 'Cullman',
      state: 'AL',
    },
    35060: {
      lat: 33.55768,
      lng: -86.92822,
      city: 'Docena',
      state: 'AL',
    },
    35061: {
      lat: 33.47529,
      lng: -86.9469,
      city: 'Dolomite',
      state: 'AL',
    },
    35062: { lat: 33.72862, lng: -87.02775, city: 'Dora', state: 'AL' },
    35063: {
      lat: 33.8221,
      lng: -87.00765,
      city: 'Empire',
      state: 'AL',
    },
    35064: {
      lat: 33.47474,
      lng: -86.91964,
      city: 'Fairfield',
      state: 'AL',
    },
    35068: {
      lat: 33.60933,
      lng: -86.83174,
      city: 'Fultondale',
      state: 'AL',
    },
    35070: {
      lat: 34.01394,
      lng: -86.74443,
      city: 'Garden City',
      state: 'AL',
    },
    35071: {
      lat: 33.70376,
      lng: -86.84286,
      city: 'Gardendale',
      state: 'AL',
    },
    35072: {
      lat: 33.08985,
      lng: -86.06057,
      city: 'Goodwater',
      state: 'AL',
    },
    35073: {
      lat: 33.65377,
      lng: -86.98051,
      city: 'Graysville',
      state: 'AL',
    },
    35074: {
      lat: 33.22235,
      lng: -87.12415,
      city: 'Green Pond',
      state: 'AL',
    },
    35077: {
      lat: 34.01984,
      lng: -86.83108,
      city: 'Hanceville',
      state: 'AL',
    },
    35078: {
      lat: 33.32279,
      lng: -86.44116,
      city: 'Harpersville',
      state: 'AL',
    },
    35079: {
      lat: 33.94118,
      lng: -86.75261,
      city: 'Hayden',
      state: 'AL',
    },
    35080: {
      lat: 33.25508,
      lng: -86.91894,
      city: 'Helena',
      state: 'AL',
    },
    35082: {
      lat: 33.1563,
      lng: -86.15456,
      city: 'Hollins',
      state: 'AL',
    },
    35083: {
      lat: 34.19718,
      lng: -86.5931,
      city: 'Holly Pond',
      state: 'AL',
    },
    35085: {
      lat: 32.97975,
      lng: -86.73584,
      city: 'Jemison',
      state: 'AL',
    },
    35087: {
      lat: 34.30556,
      lng: -86.58252,
      city: 'Joppa',
      state: 'AL',
    },
    35089: {
      lat: 32.9426,
      lng: -86.07457,
      city: 'Kellyton',
      state: 'AL',
    },
    35091: {
      lat: 33.78344,
      lng: -86.78963,
      city: 'Kimberly',
      state: 'AL',
    },
    35094: {
      lat: 33.52057,
      lng: -86.56127,
      city: 'Leeds',
      state: 'AL',
    },
    35096: {
      lat: 33.62141,
      lng: -86.10849,
      city: 'Lincoln',
      state: 'AL',
    },
    35097: {
      lat: 33.89871,
      lng: -86.62526,
      city: 'Locust Fork',
      state: 'AL',
    },
    35098: {
      lat: 34.12779,
      lng: -87.04626,
      city: 'Logan',
      state: 'AL',
    },
    35111: {
      lat: 33.28309,
      lng: -87.09002,
      city: 'McCalla',
      state: 'AL',
    },
    35112: {
      lat: 33.69374,
      lng: -86.47248,
      city: 'Margaret',
      state: 'AL',
    },
    35114: {
      lat: 33.22274,
      lng: -86.86739,
      city: 'Alabaster',
      state: 'AL',
    },
    35115: {
      lat: 33.12133,
      lng: -86.89605,
      city: 'Montevallo',
      state: 'AL',
    },
    35116: {
      lat: 33.73167,
      lng: -86.76819,
      city: 'Morris',
      state: 'AL',
    },
    35117: {
      lat: 33.67085,
      lng: -86.89678,
      city: 'Mount Olive',
      state: 'AL',
    },
    35118: {
      lat: 33.53916,
      lng: -87.05212,
      city: 'Mulga',
      state: 'AL',
    },
    35119: {
      lat: 33.63809,
      lng: -86.77864,
      city: 'New Castle',
      state: 'AL',
    },
    35120: {
      lat: 33.66777,
      lng: -86.42064,
      city: 'Odenville',
      state: 'AL',
    },
    35121: { lat: 33.9397, lng: -86.454, city: 'Oneonta', state: 'AL' },
    35124: {
      lat: 33.31368,
      lng: -86.76194,
      city: 'Pelham',
      state: 'AL',
    },
    35125: {
      lat: 33.62567,
      lng: -86.29594,
      city: 'Pell City',
      state: 'AL',
    },
    35126: {
      lat: 33.73071,
      lng: -86.65385,
      city: 'Pinson',
      state: 'AL',
    },
    35127: {
      lat: 33.49118,
      lng: -86.97581,
      city: 'Pleasant Grove',
      state: 'AL',
    },
    35128: {
      lat: 33.54738,
      lng: -86.34596,
      city: 'Pell City',
      state: 'AL',
    },
    35130: {
      lat: 33.63235,
      lng: -87.13313,
      city: 'Quinton',
      state: 'AL',
    },
    35131: {
      lat: 33.72062,
      lng: -86.19965,
      city: 'Ragland',
      state: 'AL',
    },
    35133: {
      lat: 33.81969,
      lng: -86.59878,
      city: 'Remlap',
      state: 'AL',
    },
    35135: {
      lat: 33.61814,
      lng: -86.1991,
      city: 'Riverside',
      state: 'AL',
    },
    35136: {
      lat: 32.87877,
      lng: -86.28965,
      city: 'Rockford',
      state: 'AL',
    },
    35143: {
      lat: 33.08797,
      lng: -86.55369,
      city: 'Shelby',
      state: 'AL',
    },
    35146: {
      lat: 33.79275,
      lng: -86.43258,
      city: 'Springville',
      state: 'AL',
    },
    35147: {
      lat: 33.41233,
      lng: -86.53164,
      city: 'Sterrett',
      state: 'AL',
    },
    35148: {
      lat: 33.75643,
      lng: -87.04556,
      city: 'Sumiton',
      state: 'AL',
    },
    35149: {
      lat: 33.24378,
      lng: -86.20709,
      city: 'Sycamore',
      state: 'AL',
    },
    35150: {
      lat: 33.18581,
      lng: -86.24454,
      city: 'Sylacauga',
      state: 'AL',
    },
    35151: {
      lat: 33.108,
      lng: -86.36704,
      city: 'Sylacauga',
      state: 'AL',
    },
    35160: {
      lat: 33.36898,
      lng: -86.1005,
      city: 'Talladega',
      state: 'AL',
    },
    35171: {
      lat: 32.87823,
      lng: -86.74636,
      city: 'Thorsby',
      state: 'AL',
    },
    35172: {
      lat: 33.84389,
      lng: -86.69788,
      city: 'Trafford',
      state: 'AL',
    },
    35173: {
      lat: 33.65284,
      lng: -86.56337,
      city: 'Trussville',
      state: 'AL',
    },
    35175: {
      lat: 34.43731,
      lng: -86.51673,
      city: 'Union Grove',
      state: 'AL',
    },
    35176: {
      lat: 33.4817,
      lng: -86.50221,
      city: 'Vandiver',
      state: 'AL',
    },
    35178: {
      lat: 33.42003,
      lng: -86.39993,
      city: 'Vincent',
      state: 'AL',
    },
    35179: {
      lat: 34.27004,
      lng: -86.96847,
      city: 'Vinemont',
      state: 'AL',
    },
    35180: {
      lat: 33.82388,
      lng: -86.83987,
      city: 'Warrior',
      state: 'AL',
    },
    35181: {
      lat: 33.62647,
      lng: -86.87999,
      city: 'Watson',
      state: 'AL',
    },
    35183: {
      lat: 33.00675,
      lng: -86.35706,
      city: 'Weogufka',
      state: 'AL',
    },
    35184: {
      lat: 33.1152,
      lng: -87.13931,
      city: 'West Blocton',
      state: 'AL',
    },
    35185: {
      lat: 33.34903,
      lng: -86.53871,
      city: 'Westover',
      state: 'AL',
    },
    35186: {
      lat: 33.25699,
      lng: -86.51442,
      city: 'Wilsonville',
      state: 'AL',
    },
    35188: {
      lat: 33.18326,
      lng: -87.17322,
      city: 'Woodstock',
      state: 'AL',
    },
    35203: {
      lat: 33.51847,
      lng: -86.80999,
      city: 'Birmingham',
      state: 'AL',
    },
    35204: {
      lat: 33.52203,
      lng: -86.84067,
      city: 'Birmingham',
      state: 'AL',
    },
    35205: {
      lat: 33.49509,
      lng: -86.8084,
      city: 'Birmingham',
      state: 'AL',
    },
    35206: {
      lat: 33.57024,
      lng: -86.71328,
      city: 'Birmingham',
      state: 'AL',
    },
    35207: {
      lat: 33.5682,
      lng: -86.8223,
      city: 'Birmingham',
      state: 'AL',
    },
    35208: {
      lat: 33.49717,
      lng: -86.87909,
      city: 'Birmingham',
      state: 'AL',
    },
    35209: {
      lat: 33.46507,
      lng: -86.81107,
      city: 'Birmingham',
      state: 'AL',
    },
    35210: {
      lat: 33.5431,
      lng: -86.6661,
      city: 'Birmingham',
      state: 'AL',
    },
    35211: {
      lat: 33.45262,
      lng: -86.87046,
      city: 'Birmingham',
      state: 'AL',
    },
    35212: {
      lat: 33.54765,
      lng: -86.75272,
      city: 'Birmingham',
      state: 'AL',
    },
    35213: {
      lat: 33.50659,
      lng: -86.74241,
      city: 'Birmingham',
      state: 'AL',
    },
    35214: {
      lat: 33.57322,
      lng: -86.89081,
      city: 'Birmingham',
      state: 'AL',
    },
    35215: {
      lat: 33.64911,
      lng: -86.70435,
      city: 'Birmingham',
      state: 'AL',
    },
    35216: {
      lat: 33.41995,
      lng: -86.78746,
      city: 'Birmingham',
      state: 'AL',
    },
    35217: {
      lat: 33.60711,
      lng: -86.7613,
      city: 'Birmingham',
      state: 'AL',
    },
    35218: {
      lat: 33.50771,
      lng: -86.89388,
      city: 'Birmingham',
      state: 'AL',
    },
    35221: {
      lat: 33.4497,
      lng: -86.89901,
      city: 'Birmingham',
      state: 'AL',
    },
    35222: {
      lat: 33.52378,
      lng: -86.76994,
      city: 'Birmingham',
      state: 'AL',
    },
    35223: {
      lat: 33.48777,
      lng: -86.73605,
      city: 'Birmingham',
      state: 'AL',
    },
    35224: {
      lat: 33.52965,
      lng: -86.94518,
      city: 'Birmingham',
      state: 'AL',
    },
    35226: {
      lat: 33.39823,
      lng: -86.84421,
      city: 'Birmingham',
      state: 'AL',
    },
    35228: {
      lat: 33.45606,
      lng: -86.92165,
      city: 'Birmingham',
      state: 'AL',
    },
    35229: {
      lat: 33.46434,
      lng: -86.79399,
      city: 'Birmingham',
      state: 'AL',
    },
    35233: {
      lat: 33.50916,
      lng: -86.80132,
      city: 'Birmingham',
      state: 'AL',
    },
    35234: {
      lat: 33.54005,
      lng: -86.80382,
      city: 'Birmingham',
      state: 'AL',
    },
    35235: {
      lat: 33.61723,
      lng: -86.65008,
      city: 'Birmingham',
      state: 'AL',
    },
    35242: {
      lat: 33.42371,
      lng: -86.67073,
      city: 'Birmingham',
      state: 'AL',
    },
    35243: {
      lat: 33.44185,
      lng: -86.74055,
      city: 'Birmingham',
      state: 'AL',
    },
    35244: {
      lat: 33.35205,
      lng: -86.8267,
      city: 'Birmingham',
      state: 'AL',
    },
    35249: {
      lat: 33.50657,
      lng: -86.80316,
      city: 'Birmingham',
      state: 'AL',
    },
    35254: {
      lat: 33.51375,
      lng: -86.85282,
      city: 'Birmingham',
      state: 'AL',
    },
    35401: {
      lat: 33.179,
      lng: -87.61586,
      city: 'Tuscaloosa',
      state: 'AL',
    },
    35404: {
      lat: 33.21661,
      lng: -87.48655,
      city: 'Tuscaloosa',
      state: 'AL',
    },
    35405: {
      lat: 33.12087,
      lng: -87.55082,
      city: 'Tuscaloosa',
      state: 'AL',
    },
    35406: {
      lat: 33.33194,
      lng: -87.4683,
      city: 'Tuscaloosa',
      state: 'AL',
    },
    35441: {
      lat: 32.85616,
      lng: -87.73114,
      city: 'Akron',
      state: 'AL',
    },
    35442: {
      lat: 33.06016,
      lng: -88.20159,
      city: 'Aliceville',
      state: 'AL',
    },
    35443: {
      lat: 32.76538,
      lng: -88.01908,
      city: 'Boligee',
      state: 'AL',
    },
    35444: {
      lat: 33.33474,
      lng: -87.30387,
      city: 'Brookwood',
      state: 'AL',
    },
    35446: { lat: 33.21642, lng: -87.74833, city: 'Buhl', state: 'AL' },
    35447: {
      lat: 33.25701,
      lng: -88.15716,
      city: 'Carrollton',
      state: 'AL',
    },
    35452: { lat: 33.28349, lng: -87.6854, city: 'Coker', state: 'AL' },
    35453: {
      lat: 33.16176,
      lng: -87.387,
      city: 'Cottondale',
      state: 'AL',
    },
    35456: {
      lat: 33.05651,
      lng: -87.43515,
      city: 'Duncanville',
      state: 'AL',
    },
    35457: {
      lat: 33.29891,
      lng: -87.77229,
      city: 'Echola',
      state: 'AL',
    },
    35458: { lat: 33.3814, lng: -87.79713, city: 'Elrod', state: 'AL' },
    35459: {
      lat: 32.79337,
      lng: -88.3003,
      city: 'Emelle',
      state: 'AL',
    },
    35460: { lat: 32.71882, lng: -88.19452, city: 'Epes', state: 'AL' },
    35461: {
      lat: 33.41749,
      lng: -88.21158,
      city: 'Ethelsville',
      state: 'AL',
    },
    35462: {
      lat: 32.93389,
      lng: -87.94183,
      city: 'Eutaw',
      state: 'AL',
    },
    35463: {
      lat: 33.0563,
      lng: -87.68687,
      city: 'Fosters',
      state: 'AL',
    },
    35464: {
      lat: 32.78369,
      lng: -88.14383,
      city: 'Gainesville',
      state: 'AL',
    },
    35466: {
      lat: 33.25904,
      lng: -87.91554,
      city: 'Gordo',
      state: 'AL',
    },
    35469: {
      lat: 33.01447,
      lng: -87.80578,
      city: 'Knoxville',
      state: 'AL',
    },
    35470: {
      lat: 32.56801,
      lng: -88.09894,
      city: 'Livingston',
      state: 'AL',
    },
    35473: {
      lat: 33.27236,
      lng: -87.58481,
      city: 'Northport',
      state: 'AL',
    },
    35474: {
      lat: 32.93679,
      lng: -87.57226,
      city: 'Moundville',
      state: 'AL',
    },
    35475: {
      lat: 33.42792,
      lng: -87.5854,
      city: 'Northport',
      state: 'AL',
    },
    35476: {
      lat: 33.2271,
      lng: -87.60464,
      city: 'Northport',
      state: 'AL',
    },
    35477: {
      lat: 32.96304,
      lng: -88.23121,
      city: 'Panola',
      state: 'AL',
    },
    35480: {
      lat: 33.09794,
      lng: -87.80734,
      city: 'Ralph',
      state: 'AL',
    },
    35481: {
      lat: 33.39852,
      lng: -88.03934,
      city: 'Reform',
      state: 'AL',
    },
    35487: {
      lat: 33.21426,
      lng: -87.54782,
      city: 'Tuscaloosa',
      state: 'AL',
    },
    35490: {
      lat: 33.21607,
      lng: -87.24546,
      city: 'Vance',
      state: 'AL',
    },
    35491: {
      lat: 32.93665,
      lng: -88.09774,
      city: 'West Greene',
      state: 'AL',
    },
    35501: {
      lat: 33.82145,
      lng: -87.29544,
      city: 'Jasper',
      state: 'AL',
    },
    35503: {
      lat: 33.93664,
      lng: -87.30147,
      city: 'Jasper',
      state: 'AL',
    },
    35504: {
      lat: 33.89311,
      lng: -87.16672,
      city: 'Jasper',
      state: 'AL',
    },
    35540: {
      lat: 34.23066,
      lng: -87.18374,
      city: 'Addison',
      state: 'AL',
    },
    35541: { lat: 34.07936, lng: -87.1801, city: 'Arley', state: 'AL' },
    35542: {
      lat: 33.7083,
      lng: -87.68161,
      city: 'Bankston',
      state: 'AL',
    },
    35543: {
      lat: 34.21219,
      lng: -87.75197,
      city: 'Bear Creek',
      state: 'AL',
    },
    35544: {
      lat: 33.95392,
      lng: -88.02022,
      city: 'Beaverton',
      state: 'AL',
    },
    35545: { lat: 33.6421, lng: -87.93147, city: 'Belk', state: 'AL' },
    35546: {
      lat: 33.60555,
      lng: -87.54763,
      city: 'Berry',
      state: 'AL',
    },
    35548: {
      lat: 34.06263,
      lng: -87.75251,
      city: 'Brilliant',
      state: 'AL',
    },
    35549: {
      lat: 33.83474,
      lng: -87.55001,
      city: 'Carbon Hill',
      state: 'AL',
    },
    35550: {
      lat: 33.74249,
      lng: -87.16534,
      city: 'Cordova',
      state: 'AL',
    },
    35552: {
      lat: 34.07311,
      lng: -88.14266,
      city: 'Detroit',
      state: 'AL',
    },
    35553: {
      lat: 34.12471,
      lng: -87.38794,
      city: 'Double Springs',
      state: 'AL',
    },
    35554: {
      lat: 33.89745,
      lng: -87.65593,
      city: 'Eldridge',
      state: 'AL',
    },
    35555: {
      lat: 33.66829,
      lng: -87.82309,
      city: 'Fayette',
      state: 'AL',
    },
    35559: {
      lat: 33.91166,
      lng: -87.73537,
      city: 'Glen Allen',
      state: 'AL',
    },
    35563: { lat: 33.99678, lng: -87.90149, city: 'Guin', state: 'AL' },
    35564: {
      lat: 34.25733,
      lng: -87.84306,
      city: 'Hackleburg',
      state: 'AL',
    },
    35565: {
      lat: 34.23555,
      lng: -87.56309,
      city: 'Haleyville',
      state: 'AL',
    },
    35570: {
      lat: 34.16532,
      lng: -87.99466,
      city: 'Hamilton',
      state: 'AL',
    },
    35571: {
      lat: 34.35153,
      lng: -87.94672,
      city: 'Hodges',
      state: 'AL',
    },
    35572: {
      lat: 34.20672,
      lng: -87.29447,
      city: 'Houston',
      state: 'AL',
    },
    35573: {
      lat: 33.90137,
      lng: -87.54999,
      city: 'Kansas',
      state: 'AL',
    },
    35574: {
      lat: 33.5683,
      lng: -87.95757,
      city: 'Kennedy',
      state: 'AL',
    },
    35575: { lat: 34.04521, lng: -87.57716, city: 'Lynn', state: 'AL' },
    35576: {
      lat: 33.582,
      lng: -88.1315,
      city: 'Millport',
      state: 'AL',
    },
    35577: {
      lat: 34.09175,
      lng: -87.59931,
      city: 'Natural Bridge',
      state: 'AL',
    },
    35578: { lat: 33.982, lng: -87.46638, city: 'Nauvoo', state: 'AL' },
    35579: {
      lat: 33.63069,
      lng: -87.34441,
      city: 'Oakman',
      state: 'AL',
    },
    35580: {
      lat: 33.7011,
      lng: -87.27331,
      city: 'Parrish',
      state: 'AL',
    },
    35581: {
      lat: 34.35789,
      lng: -87.71267,
      city: 'Phil Campbell',
      state: 'AL',
    },
    35582: {
      lat: 34.48577,
      lng: -88.09536,
      city: 'Red Bay',
      state: 'AL',
    },
    35584: {
      lat: 33.82161,
      lng: -87.08692,
      city: 'Sipsey',
      state: 'AL',
    },
    35585: {
      lat: 34.41868,
      lng: -87.82008,
      city: 'Spruce Pine',
      state: 'AL',
    },
    35586: {
      lat: 33.87332,
      lng: -88.13837,
      city: 'Sulligent',
      state: 'AL',
    },
    35587: {
      lat: 33.80071,
      lng: -87.4521,
      city: 'Townley',
      state: 'AL',
    },
    35592: {
      lat: 33.74315,
      lng: -88.09672,
      city: 'Vernon',
      state: 'AL',
    },
    35593: { lat: 34.35721, lng: -88.07328, city: 'Vina', state: 'AL' },
    35594: {
      lat: 33.92222,
      lng: -87.7813,
      city: 'Winfield',
      state: 'AL',
    },
    35601: {
      lat: 34.60616,
      lng: -87.00795,
      city: 'Decatur',
      state: 'AL',
    },
    35603: {
      lat: 34.53712,
      lng: -86.96717,
      city: 'Decatur',
      state: 'AL',
    },
    35610: {
      lat: 34.95361,
      lng: -87.23714,
      city: 'Anderson',
      state: 'AL',
    },
    35611: {
      lat: 34.76554,
      lng: -87.08418,
      city: 'Athens',
      state: 'AL',
    },
    35613: {
      lat: 34.82336,
      lng: -86.88001,
      city: 'Athens',
      state: 'AL',
    },
    35614: {
      lat: 34.86433,
      lng: -87.08457,
      city: 'Athens',
      state: 'AL',
    },
    35615: {
      lat: 34.66229,
      lng: -86.87596,
      city: 'Belle Mina',
      state: 'AL',
    },
    35616: {
      lat: 34.7382,
      lng: -88.01889,
      city: 'Cherokee',
      state: 'AL',
    },
    35618: {
      lat: 34.64334,
      lng: -87.30552,
      city: 'Courtland',
      state: 'AL',
    },
    35619: {
      lat: 34.38606,
      lng: -87.14545,
      city: 'Danville',
      state: 'AL',
    },
    35620: {
      lat: 34.93141,
      lng: -86.99086,
      city: 'Elkmont',
      state: 'AL',
    },
    35621: { lat: 34.34967, lng: -86.7239, city: 'Eva', state: 'AL' },
    35622: {
      lat: 34.34491,
      lng: -86.89535,
      city: 'Falkville',
      state: 'AL',
    },
    35630: {
      lat: 34.82526,
      lng: -87.66018,
      city: 'Florence',
      state: 'AL',
    },
    35632: {
      lat: 34.80763,
      lng: -87.68043,
      city: 'Florence',
      state: 'AL',
    },
    35633: {
      lat: 34.87586,
      lng: -87.79645,
      city: 'Florence',
      state: 'AL',
    },
    35634: {
      lat: 34.92306,
      lng: -87.60738,
      city: 'Florence',
      state: 'AL',
    },
    35640: {
      lat: 34.4414,
      lng: -86.94315,
      city: 'Hartselle',
      state: 'AL',
    },
    35643: {
      lat: 34.66882,
      lng: -87.20707,
      city: 'Hillsboro',
      state: 'AL',
    },
    35645: {
      lat: 34.89605,
      lng: -87.4936,
      city: 'Killen',
      state: 'AL',
    },
    35646: {
      lat: 34.66988,
      lng: -87.53165,
      city: 'Leighton',
      state: 'AL',
    },
    35647: {
      lat: 34.96539,
      lng: -87.11933,
      city: 'Lester',
      state: 'AL',
    },
    35648: {
      lat: 34.95857,
      lng: -87.38022,
      city: 'Lexington',
      state: 'AL',
    },
    35649: {
      lat: 34.62091,
      lng: -86.87965,
      city: 'Mooresville',
      state: 'AL',
    },
    35650: {
      lat: 34.45176,
      lng: -87.30289,
      city: 'Moulton',
      state: 'AL',
    },
    35651: {
      lat: 34.46254,
      lng: -87.48044,
      city: 'Mount Hope',
      state: 'AL',
    },
    35652: {
      lat: 34.84742,
      lng: -87.31137,
      city: 'Rogersville',
      state: 'AL',
    },
    35653: {
      lat: 34.53131,
      lng: -87.86974,
      city: 'Russellville',
      state: 'AL',
    },
    35654: {
      lat: 34.49971,
      lng: -87.62,
      city: 'Russellville',
      state: 'AL',
    },
    35660: {
      lat: 34.75837,
      lng: -87.69847,
      city: 'Sheffield',
      state: 'AL',
    },
    35661: {
      lat: 34.77737,
      lng: -87.56331,
      city: 'Muscle Shoals',
      state: 'AL',
    },
    35670: {
      lat: 34.46318,
      lng: -86.72356,
      city: 'Somerville',
      state: 'AL',
    },
    35671: {
      lat: 34.66607,
      lng: -86.95191,
      city: 'Tanner',
      state: 'AL',
    },
    35672: {
      lat: 34.65106,
      lng: -87.40705,
      city: 'Town Creek',
      state: 'AL',
    },
    35673: {
      lat: 34.58601,
      lng: -87.13166,
      city: 'Trinity',
      state: 'AL',
    },
    35674: {
      lat: 34.66343,
      lng: -87.75996,
      city: 'Tuscumbia',
      state: 'AL',
    },
    35677: {
      lat: 34.95367,
      lng: -88.02529,
      city: 'Waterloo',
      state: 'AL',
    },
    35739: {
      lat: 34.96914,
      lng: -86.79501,
      city: 'Ardmore',
      state: 'AL',
    },
    35740: {
      lat: 34.95009,
      lng: -85.75855,
      city: 'Bridgeport',
      state: 'AL',
    },
    35741: {
      lat: 34.7242,
      lng: -86.47745,
      city: 'Brownsboro',
      state: 'AL',
    },
    35744: {
      lat: 34.61435,
      lng: -85.90603,
      city: 'Dutton',
      state: 'AL',
    },
    35745: {
      lat: 34.94101,
      lng: -86.20002,
      city: 'Estillfork',
      state: 'AL',
    },
    35746: {
      lat: 34.8287,
      lng: -85.99868,
      city: 'Fackler',
      state: 'AL',
    },
    35747: {
      lat: 34.49414,
      lng: -86.30497,
      city: 'Grant',
      state: 'AL',
    },
    35748: {
      lat: 34.71925,
      lng: -86.39036,
      city: 'Gurley',
      state: 'AL',
    },
    35749: {
      lat: 34.82314,
      lng: -86.75662,
      city: 'Harvest',
      state: 'AL',
    },
    35750: {
      lat: 34.95407,
      lng: -86.5951,
      city: 'Hazel Green',
      state: 'AL',
    },
    35751: {
      lat: 34.81066,
      lng: -86.27931,
      city: 'Hollytree',
      state: 'AL',
    },
    35752: {
      lat: 34.75593,
      lng: -85.95119,
      city: 'Hollywood',
      state: 'AL',
    },
    35754: {
      lat: 34.51819,
      lng: -86.61099,
      city: 'Laceys Spring',
      state: 'AL',
    },
    35755: {
      lat: 34.50676,
      lng: -86.12609,
      city: 'Langston',
      state: 'AL',
    },
    35756: {
      lat: 34.64287,
      lng: -86.82114,
      city: 'Madison',
      state: 'AL',
    },
    35757: {
      lat: 34.78426,
      lng: -86.74419,
      city: 'Madison',
      state: 'AL',
    },
    35758: {
      lat: 34.71417,
      lng: -86.74707,
      city: 'Madison',
      state: 'AL',
    },
    35759: {
      lat: 34.86856,
      lng: -86.55262,
      city: 'Meridianville',
      state: 'AL',
    },
    35760: {
      lat: 34.54768,
      lng: -86.38324,
      city: 'New Hope',
      state: 'AL',
    },
    35761: {
      lat: 34.914,
      lng: -86.41001,
      city: 'New Market',
      state: 'AL',
    },
    35763: {
      lat: 34.62022,
      lng: -86.46615,
      city: 'Owens Cross Roads',
      state: 'AL',
    },
    35764: {
      lat: 34.73102,
      lng: -86.31381,
      city: 'Paint Rock',
      state: 'AL',
    },
    35765: {
      lat: 34.69393,
      lng: -85.82423,
      city: 'Pisgah',
      state: 'AL',
    },
    35766: {
      lat: 34.87342,
      lng: -86.27661,
      city: 'Princeton',
      state: 'AL',
    },
    35768: {
      lat: 34.77852,
      lng: -86.10383,
      city: 'Scottsboro',
      state: 'AL',
    },
    35769: {
      lat: 34.59255,
      lng: -86.09472,
      city: 'Scottsboro',
      state: 'AL',
    },
    35771: {
      lat: 34.53635,
      lng: -85.99843,
      city: 'Section',
      state: 'AL',
    },
    35772: {
      lat: 34.89152,
      lng: -85.88614,
      city: 'Stevenson',
      state: 'AL',
    },
    35773: {
      lat: 34.89431,
      lng: -86.71282,
      city: 'Toney',
      state: 'AL',
    },
    35774: {
      lat: 34.7611,
      lng: -86.22805,
      city: 'Trenton',
      state: 'AL',
    },
    35775: {
      lat: 34.5477,
      lng: -86.69623,
      city: 'Valhermoso Springs',
      state: 'AL',
    },
    35776: {
      lat: 34.66083,
      lng: -86.24338,
      city: 'Woodville',
      state: 'AL',
    },
    35801: {
      lat: 34.72497,
      lng: -86.56304,
      city: 'Huntsville',
      state: 'AL',
    },
    35802: {
      lat: 34.67398,
      lng: -86.55449,
      city: 'Huntsville',
      state: 'AL',
    },
    35803: {
      lat: 34.56476,
      lng: -86.52185,
      city: 'Huntsville',
      state: 'AL',
    },
    35805: {
      lat: 34.70875,
      lng: -86.6219,
      city: 'Huntsville',
      state: 'AL',
    },
    35806: {
      lat: 34.76615,
      lng: -86.68324,
      city: 'Huntsville',
      state: 'AL',
    },
    35808: {
      lat: 34.63236,
      lng: -86.65579,
      city: 'Huntsville',
      state: 'AL',
    },
    35810: {
      lat: 34.80183,
      lng: -86.60264,
      city: 'Huntsville',
      state: 'AL',
    },
    35811: {
      lat: 34.79925,
      lng: -86.50386,
      city: 'Huntsville',
      state: 'AL',
    },
    35816: {
      lat: 34.73923,
      lng: -86.62985,
      city: 'Huntsville',
      state: 'AL',
    },
    35824: {
      lat: 34.64458,
      lng: -86.75193,
      city: 'Huntsville',
      state: 'AL',
    },
    35896: {
      lat: 34.75318,
      lng: -86.65213,
      city: 'Huntsville',
      state: 'AL',
    },
    35901: {
      lat: 34.04848,
      lng: -85.92395,
      city: 'Gadsden',
      state: 'AL',
    },
    35903: {
      lat: 34.03144,
      lng: -85.85703,
      city: 'Gadsden',
      state: 'AL',
    },
    35904: {
      lat: 34.07569,
      lng: -85.98519,
      city: 'Gadsden',
      state: 'AL',
    },
    35905: {
      lat: 33.93667,
      lng: -85.89842,
      city: 'Gadsden',
      state: 'AL',
    },
    35906: {
      lat: 33.93159,
      lng: -86.09033,
      city: 'Rainbow City',
      state: 'AL',
    },
    35907: {
      lat: 33.89874,
      lng: -86.02408,
      city: 'Gadsden',
      state: 'AL',
    },
    35950: {
      lat: 34.25273,
      lng: -86.26023,
      city: 'Albertville',
      state: 'AL',
    },
    35951: {
      lat: 34.33713,
      lng: -86.1553,
      city: 'Albertville',
      state: 'AL',
    },
    35952: {
      lat: 34.05322,
      lng: -86.3082,
      city: 'Altoona',
      state: 'AL',
    },
    35953: {
      lat: 33.81058,
      lng: -86.20986,
      city: 'Ashville',
      state: 'AL',
    },
    35954: {
      lat: 34.08952,
      lng: -86.05771,
      city: 'Attalla',
      state: 'AL',
    },
    35956: { lat: 34.1433, lng: -86.14799, city: 'Boaz', state: 'AL' },
    35957: { lat: 34.19105, lng: -86.19531, city: 'Boaz', state: 'AL' },
    35958: {
      lat: 34.91796,
      lng: -85.64731,
      city: 'Bryant',
      state: 'AL',
    },
    35959: {
      lat: 34.23968,
      lng: -85.60575,
      city: 'Cedar Bluff',
      state: 'AL',
    },
    35960: {
      lat: 34.12515,
      lng: -85.56786,
      city: 'Centre',
      state: 'AL',
    },
    35961: {
      lat: 34.27753,
      lng: -85.86241,
      city: 'Collinsville',
      state: 'AL',
    },
    35962: {
      lat: 34.29916,
      lng: -86.03795,
      city: 'Crossville',
      state: 'AL',
    },
    35963: {
      lat: 34.35812,
      lng: -85.93114,
      city: 'Dawson',
      state: 'AL',
    },
    35966: {
      lat: 34.79696,
      lng: -85.68833,
      city: 'Flat Rock',
      state: 'AL',
    },
    35967: {
      lat: 34.3981,
      lng: -85.69698,
      city: 'Fort Payne',
      state: 'AL',
    },
    35968: {
      lat: 34.48095,
      lng: -85.77232,
      city: 'Fort Payne',
      state: 'AL',
    },
    35971: { lat: 34.45603, lng: -85.9466, city: 'Fyffe', state: 'AL' },
    35972: {
      lat: 33.98797,
      lng: -86.24943,
      city: 'Gallant',
      state: 'AL',
    },
    35973: {
      lat: 34.34222,
      lng: -85.5554,
      city: 'Gaylesville',
      state: 'AL',
    },
    35974: {
      lat: 34.36284,
      lng: -86.0099,
      city: 'Geraldine',
      state: 'AL',
    },
    35975: {
      lat: 34.43832,
      lng: -86.06655,
      city: 'Groveoak',
      state: 'AL',
    },
    35976: {
      lat: 34.34819,
      lng: -86.32919,
      city: 'Guntersville',
      state: 'AL',
    },
    35978: {
      lat: 34.64239,
      lng: -85.72983,
      city: 'Henagar',
      state: 'AL',
    },
    35979: {
      lat: 34.82994,
      lng: -85.6125,
      city: 'Higdon',
      state: 'AL',
    },
    35980: {
      lat: 34.17719,
      lng: -86.38335,
      city: 'Horton',
      state: 'AL',
    },
    35981: { lat: 34.72218, lng: -85.64072, city: 'Ider', state: 'AL' },
    35983: {
      lat: 34.17088,
      lng: -85.76746,
      city: 'Leesburg',
      state: 'AL',
    },
    35984: {
      lat: 34.52781,
      lng: -85.56594,
      city: 'Mentone',
      state: 'AL',
    },
    35986: {
      lat: 34.50524,
      lng: -85.84012,
      city: 'Rainsville',
      state: 'AL',
    },
    35987: {
      lat: 33.91557,
      lng: -86.23889,
      city: 'Steele',
      state: 'AL',
    },
    35988: {
      lat: 34.5589,
      lng: -85.79994,
      city: 'Sylvania',
      state: 'AL',
    },
    35989: {
      lat: 34.60858,
      lng: -85.63162,
      city: 'Valley Head',
      state: 'AL',
    },
    35990: {
      lat: 34.06676,
      lng: -86.3052,
      city: 'Walnut Grove',
      state: 'AL',
    },
    36003: {
      lat: 32.44651,
      lng: -86.72536,
      city: 'Autaugaville',
      state: 'AL',
    },
    36005: {
      lat: 31.88137,
      lng: -85.72802,
      city: 'Banks',
      state: 'AL',
    },
    36006: {
      lat: 32.63831,
      lng: -86.72531,
      city: 'Billingsley',
      state: 'AL',
    },
    36008: {
      lat: 32.49747,
      lng: -86.56416,
      city: 'Booth',
      state: 'AL',
    },
    36009: {
      lat: 31.58395,
      lng: -86.30531,
      city: 'Brantley',
      state: 'AL',
    },
    36010: {
      lat: 31.66751,
      lng: -85.81439,
      city: 'Brundidge',
      state: 'AL',
    },
    36013: { lat: 32.2976, lng: -85.97959, city: 'Cecil', state: 'AL' },
    36016: {
      lat: 31.84562,
      lng: -85.4255,
      city: 'Clayton',
      state: 'AL',
    },
    36017: { lat: 31.68142, lng: -85.55411, city: 'Clio', state: 'AL' },
    36020: {
      lat: 32.49616,
      lng: -86.31858,
      city: 'Coosada',
      state: 'AL',
    },
    36022: {
      lat: 32.61951,
      lng: -86.40492,
      city: 'Deatsville',
      state: 'AL',
    },
    36024: {
      lat: 32.6654,
      lng: -86.03312,
      city: 'Eclectic',
      state: 'AL',
    },
    36025: {
      lat: 32.54664,
      lng: -86.33941,
      city: 'Elmore',
      state: 'AL',
    },
    36026: {
      lat: 32.77587,
      lng: -86.13269,
      city: 'Equality',
      state: 'AL',
    },
    36027: {
      lat: 31.93307,
      lng: -85.20968,
      city: 'Eufaula',
      state: 'AL',
    },
    36028: {
      lat: 31.49031,
      lng: -86.38271,
      city: 'Dozier',
      state: 'AL',
    },
    36029: {
      lat: 32.18479,
      lng: -85.92953,
      city: 'Fitzpatrick',
      state: 'AL',
    },
    36030: {
      lat: 31.86044,
      lng: -86.85697,
      city: 'Forest Home',
      state: 'AL',
    },
    36031: {
      lat: 32.24944,
      lng: -85.75157,
      city: 'Fort Davis',
      state: 'AL',
    },
    36032: {
      lat: 31.99857,
      lng: -86.57179,
      city: 'Fort Deposit',
      state: 'AL',
    },
    36033: {
      lat: 31.66983,
      lng: -86.771,
      city: 'Georgiana',
      state: 'AL',
    },
    36034: {
      lat: 31.62308,
      lng: -86.11155,
      city: 'Glenwood',
      state: 'AL',
    },
    36035: {
      lat: 31.79285,
      lng: -86.12415,
      city: 'Goshen',
      state: 'AL',
    },
    36036: {
      lat: 31.97541,
      lng: -86.16063,
      city: 'Grady',
      state: 'AL',
    },
    36037: {
      lat: 31.79384,
      lng: -86.62356,
      city: 'Greenville',
      state: 'AL',
    },
    36038: { lat: 31.40788, lng: -86.4869, city: 'Gantt', state: 'AL' },
    36039: {
      lat: 32.28939,
      lng: -85.82789,
      city: 'Hardaway',
      state: 'AL',
    },
    36040: {
      lat: 32.17795,
      lng: -86.66972,
      city: 'Hayneville',
      state: 'AL',
    },
    36041: {
      lat: 31.92361,
      lng: -86.32172,
      city: 'Highland Home',
      state: 'AL',
    },
    36042: {
      lat: 31.86655,
      lng: -86.44897,
      city: 'Honoraville',
      state: 'AL',
    },
    36043: {
      lat: 32.1939,
      lng: -86.42811,
      city: 'Hope Hull',
      state: 'AL',
    },
    36046: {
      lat: 31.9994,
      lng: -86.33948,
      city: 'Lapine',
      state: 'AL',
    },
    36047: {
      lat: 32.07868,
      lng: -86.52474,
      city: 'Letohatchee',
      state: 'AL',
    },
    36048: {
      lat: 31.79011,
      lng: -85.59066,
      city: 'Louisville',
      state: 'AL',
    },
    36049: {
      lat: 31.76612,
      lng: -86.28092,
      city: 'Luverne',
      state: 'AL',
    },
    36051: {
      lat: 32.68771,
      lng: -86.488,
      city: 'Marbury',
      state: 'AL',
    },
    36052: {
      lat: 32.17935,
      lng: -86.04309,
      city: 'Mathews',
      state: 'AL',
    },
    36053: {
      lat: 32.03514,
      lng: -85.43945,
      city: 'Midway',
      state: 'AL',
    },
    36054: {
      lat: 32.46821,
      lng: -86.35962,
      city: 'Millbrook',
      state: 'AL',
    },
    36057: {
      lat: 32.37464,
      lng: -86.08461,
      city: 'Mount Meigs',
      state: 'AL',
    },
    36064: {
      lat: 32.3008,
      lng: -86.08186,
      city: 'Pike Road',
      state: 'AL',
    },
    36066: {
      lat: 32.48216,
      lng: -86.42431,
      city: 'Prattville',
      state: 'AL',
    },
    36067: {
      lat: 32.51134,
      lng: -86.57084,
      city: 'Prattville',
      state: 'AL',
    },
    36069: { lat: 32.0905, lng: -86.16008, city: 'Ramer', state: 'AL' },
    36071: {
      lat: 31.70534,
      lng: -86.39815,
      city: 'Rutledge',
      state: 'AL',
    },
    36075: {
      lat: 32.38913,
      lng: -85.91257,
      city: 'Shorter',
      state: 'AL',
    },
    36078: {
      lat: 32.53991,
      lng: -85.93328,
      city: 'Tallassee',
      state: 'AL',
    },
    36079: { lat: 31.76605, lng: -86.01206, city: 'Troy', state: 'AL' },
    36080: {
      lat: 32.70497,
      lng: -86.28662,
      city: 'Titus',
      state: 'AL',
    },
    36081: { lat: 31.90663, lng: -85.89804, city: 'Troy', state: 'AL' },
    36082: { lat: 31.80271, lng: -85.95514, city: 'Troy', state: 'AL' },
    36083: {
      lat: 32.39512,
      lng: -85.68672,
      city: 'Tuskegee',
      state: 'AL',
    },
    36088: {
      lat: 32.41911,
      lng: -85.718,
      city: 'Tuskegee Institute',
      state: 'AL',
    },
    36089: {
      lat: 32.15745,
      lng: -85.69872,
      city: 'Union Springs',
      state: 'AL',
    },
    36091: {
      lat: 32.74881,
      lng: -86.52021,
      city: 'Verbena',
      state: 'AL',
    },
    36092: {
      lat: 32.61401,
      lng: -86.19619,
      city: 'Wetumpka',
      state: 'AL',
    },
    36093: {
      lat: 32.49584,
      lng: -86.13956,
      city: 'Wetumpka',
      state: 'AL',
    },
    36101: {
      lat: 32.38177,
      lng: -86.29763,
      city: 'Montgomery',
      state: 'AL',
    },
    36104: {
      lat: 32.39889,
      lng: -86.31593,
      city: 'Montgomery',
      state: 'AL',
    },
    36105: {
      lat: 32.21808,
      lng: -86.28778,
      city: 'Montgomery',
      state: 'AL',
    },
    36106: {
      lat: 32.35264,
      lng: -86.25793,
      city: 'Montgomery',
      state: 'AL',
    },
    36107: {
      lat: 32.38434,
      lng: -86.2795,
      city: 'Montgomery',
      state: 'AL',
    },
    36108: {
      lat: 32.33956,
      lng: -86.40602,
      city: 'Montgomery',
      state: 'AL',
    },
    36109: {
      lat: 32.38682,
      lng: -86.24052,
      city: 'Montgomery',
      state: 'AL',
    },
    36110: {
      lat: 32.44899,
      lng: -86.25566,
      city: 'Montgomery',
      state: 'AL',
    },
    36111: {
      lat: 32.33755,
      lng: -86.27154,
      city: 'Montgomery',
      state: 'AL',
    },
    36112: {
      lat: 32.38343,
      lng: -86.36,
      city: 'Montgomery',
      state: 'AL',
    },
    36113: {
      lat: 32.38146,
      lng: -86.34172,
      city: 'Montgomery',
      state: 'AL',
    },
    36114: {
      lat: 32.40484,
      lng: -86.24568,
      city: 'Montgomery',
      state: 'AL',
    },
    36115: {
      lat: 32.40688,
      lng: -86.24685,
      city: 'Montgomery',
      state: 'AL',
    },
    36116: {
      lat: 32.27312,
      lng: -86.21765,
      city: 'Montgomery',
      state: 'AL',
    },
    36117: {
      lat: 32.37831,
      lng: -86.14627,
      city: 'Montgomery',
      state: 'AL',
    },
    36130: {
      lat: 32.37778,
      lng: -86.29926,
      city: 'Montgomery',
      state: 'AL',
    },
    36201: {
      lat: 33.65082,
      lng: -85.88264,
      city: 'Anniston',
      state: 'AL',
    },
    36203: {
      lat: 33.58205,
      lng: -85.83312,
      city: 'Oxford',
      state: 'AL',
    },
    36205: {
      lat: 33.70037,
      lng: -85.78898,
      city: 'Anniston',
      state: 'AL',
    },
    36206: {
      lat: 33.73225,
      lng: -85.81638,
      city: 'Anniston',
      state: 'AL',
    },
    36207: {
      lat: 33.68196,
      lng: -85.71356,
      city: 'Anniston',
      state: 'AL',
    },
    36250: {
      lat: 33.76564,
      lng: -85.89675,
      city: 'Alexandria',
      state: 'AL',
    },
    36251: {
      lat: 33.22996,
      lng: -85.86532,
      city: 'Ashland',
      state: 'AL',
    },
    36255: {
      lat: 33.18094,
      lng: -85.73933,
      city: 'Cragford',
      state: 'AL',
    },
    36256: {
      lat: 33.04181,
      lng: -85.70775,
      city: 'Daviston',
      state: 'AL',
    },
    36258: { lat: 33.47795, lng: -85.716, city: 'Delta', state: 'AL' },
    36260: {
      lat: 33.58662,
      lng: -86.00025,
      city: 'Eastaboga',
      state: 'AL',
    },
    36262: {
      lat: 33.7965,
      lng: -85.46741,
      city: 'Fruithurst',
      state: 'AL',
    },
    36263: {
      lat: 33.46727,
      lng: -85.36016,
      city: 'Graham',
      state: 'AL',
    },
    36264: {
      lat: 33.59305,
      lng: -85.53817,
      city: 'Heflin',
      state: 'AL',
    },
    36265: {
      lat: 33.84659,
      lng: -85.78662,
      city: 'Jacksonville',
      state: 'AL',
    },
    36266: {
      lat: 33.35338,
      lng: -85.75062,
      city: 'Lineville',
      state: 'AL',
    },
    36267: {
      lat: 33.19513,
      lng: -85.94659,
      city: 'Millerville',
      state: 'AL',
    },
    36268: {
      lat: 33.49469,
      lng: -85.94016,
      city: 'Munford',
      state: 'AL',
    },
    36269: {
      lat: 33.73569,
      lng: -85.38314,
      city: 'Muscadine',
      state: 'AL',
    },
    36271: {
      lat: 33.78215,
      lng: -86.01624,
      city: 'Ohatchee',
      state: 'AL',
    },
    36272: {
      lat: 33.94441,
      lng: -85.60454,
      city: 'Piedmont',
      state: 'AL',
    },
    36273: {
      lat: 33.53904,
      lng: -85.37742,
      city: 'Ranburne',
      state: 'AL',
    },
    36274: {
      lat: 33.17687,
      lng: -85.36283,
      city: 'Roanoke',
      state: 'AL',
    },
    36276: {
      lat: 33.12644,
      lng: -85.57772,
      city: 'Wadley',
      state: 'AL',
    },
    36277: {
      lat: 33.7609,
      lng: -85.81541,
      city: 'Weaver',
      state: 'AL',
    },
    36278: {
      lat: 33.31885,
      lng: -85.5253,
      city: 'Wedowee',
      state: 'AL',
    },
    36279: {
      lat: 33.87237,
      lng: -85.88645,
      city: 'Wellington',
      state: 'AL',
    },
    36280: {
      lat: 33.38427,
      lng: -85.3891,
      city: 'Woodland',
      state: 'AL',
    },
    36301: {
      lat: 31.14387,
      lng: -85.40321,
      city: 'Dothan',
      state: 'AL',
    },
    36303: {
      lat: 31.26389,
      lng: -85.39213,
      city: 'Dothan',
      state: 'AL',
    },
    36305: {
      lat: 31.20229,
      lng: -85.49798,
      city: 'Dothan',
      state: 'AL',
    },
    36310: {
      lat: 31.60296,
      lng: -85.21229,
      city: 'Abbeville',
      state: 'AL',
    },
    36311: {
      lat: 31.60092,
      lng: -85.68372,
      city: 'Ariton',
      state: 'AL',
    },
    36312: {
      lat: 31.16748,
      lng: -85.23867,
      city: 'Ashford',
      state: 'AL',
    },
    36313: {
      lat: 31.18024,
      lng: -85.79368,
      city: 'Bellwood',
      state: 'AL',
    },
    36314: {
      lat: 31.02141,
      lng: -85.77645,
      city: 'Black',
      state: 'AL',
    },
    36316: {
      lat: 31.17835,
      lng: -85.86496,
      city: 'Chancellor',
      state: 'AL',
    },
    36317: {
      lat: 31.63532,
      lng: -85.38748,
      city: 'Clopton',
      state: 'AL',
    },
    36318: {
      lat: 31.17612,
      lng: -85.95653,
      city: 'Coffee Springs',
      state: 'AL',
    },
    36319: {
      lat: 31.32367,
      lng: -85.14931,
      city: 'Columbia',
      state: 'AL',
    },
    36320: {
      lat: 31.05105,
      lng: -85.31765,
      city: 'Cottonwood',
      state: 'AL',
    },
    36321: {
      lat: 31.20558,
      lng: -85.30093,
      city: 'Cowarts',
      state: 'AL',
    },
    36322: {
      lat: 31.25575,
      lng: -85.73249,
      city: 'Daleville',
      state: 'AL',
    },
    36323: { lat: 31.42702, lng: -86.07221, city: 'Elba', state: 'AL' },
    36330: {
      lat: 31.30743,
      lng: -85.85854,
      city: 'Enterprise',
      state: 'AL',
    },
    36340: {
      lat: 31.05391,
      lng: -85.89155,
      city: 'Geneva',
      state: 'AL',
    },
    36343: {
      lat: 31.05753,
      lng: -85.11812,
      city: 'Gordon',
      state: 'AL',
    },
    36344: {
      lat: 31.1007,
      lng: -85.70931,
      city: 'Hartford',
      state: 'AL',
    },
    36345: { lat: 31.3741, lng: -85.31, city: 'Headland', state: 'AL' },
    36346: { lat: 31.55783, lng: -85.9331, city: 'Jack', state: 'AL' },
    36350: {
      lat: 31.3435,
      lng: -85.49669,
      city: 'Midland City',
      state: 'AL',
    },
    36351: {
      lat: 31.41469,
      lng: -85.90596,
      city: 'New Brockton',
      state: 'AL',
    },
    36352: {
      lat: 31.25682,
      lng: -85.61629,
      city: 'Newton',
      state: 'AL',
    },
    36353: {
      lat: 31.4817,
      lng: -85.36208,
      city: 'Newville',
      state: 'AL',
    },
    36360: {
      lat: 31.46648,
      lng: -85.61641,
      city: 'Ozark',
      state: 'AL',
    },
    36362: {
      lat: 31.35072,
      lng: -85.69027,
      city: 'Fort Novosel',
      state: 'AL',
    },
    36370: {
      lat: 31.13175,
      lng: -85.15688,
      city: 'Pansey',
      state: 'AL',
    },
    36371: {
      lat: 31.3208,
      lng: -85.54738,
      city: 'Pinckard',
      state: 'AL',
    },
    36373: {
      lat: 31.52131,
      lng: -85.10355,
      city: 'Shorterville',
      state: 'AL',
    },
    36374: {
      lat: 31.5723,
      lng: -85.48956,
      city: 'Skipperville',
      state: 'AL',
    },
    36375: {
      lat: 31.07681,
      lng: -85.54111,
      city: 'Slocomb',
      state: 'AL',
    },
    36376: { lat: 31.25213, lng: -85.26961, city: 'Webb', state: 'AL' },
    36401: {
      lat: 31.47123,
      lng: -86.94928,
      city: 'Evergreen',
      state: 'AL',
    },
    36420: {
      lat: 31.18092,
      lng: -86.57618,
      city: 'Andalusia',
      state: 'AL',
    },
    36421: {
      lat: 31.32769,
      lng: -86.50903,
      city: 'Andalusia',
      state: 'AL',
    },
    36425: {
      lat: 31.75884,
      lng: -87.19731,
      city: 'Beatrice',
      state: 'AL',
    },
    36426: {
      lat: 31.14386,
      lng: -87.076,
      city: 'Brewton',
      state: 'AL',
    },
    36432: {
      lat: 31.30055,
      lng: -87.03888,
      city: 'Castleberry',
      state: 'AL',
    },
    36435: { lat: 31.87889, lng: -87.45564, city: 'Coy', state: 'AL' },
    36436: {
      lat: 31.76636,
      lng: -87.6614,
      city: 'Dickinson',
      state: 'AL',
    },
    36439: {
      lat: 31.42252,
      lng: -87.34512,
      city: 'Excel',
      state: 'AL',
    },
    36441: {
      lat: 31.04064,
      lng: -87.25885,
      city: 'Flomaton',
      state: 'AL',
    },
    36442: {
      lat: 31.07312,
      lng: -86.36355,
      city: 'Florala',
      state: 'AL',
    },
    36444: {
      lat: 31.65935,
      lng: -87.4651,
      city: 'Franklin',
      state: 'AL',
    },
    36445: {
      lat: 31.44397,
      lng: -87.44982,
      city: 'Frisco City',
      state: 'AL',
    },
    36446: {
      lat: 31.79146,
      lng: -87.72446,
      city: 'Fulton',
      state: 'AL',
    },
    36451: {
      lat: 31.69769,
      lng: -87.80664,
      city: 'Grove Hill',
      state: 'AL',
    },
    36453: {
      lat: 31.19788,
      lng: -86.14726,
      city: 'Kinston',
      state: 'AL',
    },
    36454: {
      lat: 31.34368,
      lng: -87.20185,
      city: 'Lenox',
      state: 'AL',
    },
    36455: {
      lat: 31.01212,
      lng: -86.35321,
      city: 'Lockhart',
      state: 'AL',
    },
    36456: {
      lat: 31.57103,
      lng: -86.82556,
      city: 'McKenzie',
      state: 'AL',
    },
    36460: {
      lat: 31.52679,
      lng: -87.30786,
      city: 'Monroeville',
      state: 'AL',
    },
    36467: { lat: 31.2795, lng: -86.25579, city: 'Opp', state: 'AL' },
    36470: {
      lat: 31.51602,
      lng: -87.55518,
      city: 'Perdue Hill',
      state: 'AL',
    },
    36471: {
      lat: 31.62616,
      lng: -87.22861,
      city: 'Peterman',
      state: 'AL',
    },
    36473: {
      lat: 31.32009,
      lng: -87.21967,
      city: 'Range',
      state: 'AL',
    },
    36474: {
      lat: 31.42664,
      lng: -86.62022,
      city: 'Red Level',
      state: 'AL',
    },
    36475: {
      lat: 31.38511,
      lng: -87.22717,
      city: 'Repton',
      state: 'AL',
    },
    36476: {
      lat: 31.35359,
      lng: -86.54633,
      city: 'River Falls',
      state: 'AL',
    },
    36477: {
      lat: 31.08741,
      lng: -86.05407,
      city: 'Samson',
      state: 'AL',
    },
    36480: {
      lat: 31.32976,
      lng: -87.61125,
      city: 'Uriah',
      state: 'AL',
    },
    36481: {
      lat: 31.77356,
      lng: -87.37553,
      city: 'Vredenburgh',
      state: 'AL',
    },
    36482: {
      lat: 31.6223,
      lng: -87.63424,
      city: 'Whatley',
      state: 'AL',
    },
    36483: { lat: 31.04273, lng: -86.68494, city: 'Wing', state: 'AL' },
    36502: {
      lat: 31.14498,
      lng: -87.49303,
      city: 'Atmore',
      state: 'AL',
    },
    36503: {
      lat: 31.15674,
      lng: -87.4654,
      city: 'Atmore',
      state: 'AL',
    },
    36505: { lat: 30.94978, lng: -88.0225, city: 'Axis', state: 'AL' },
    36507: {
      lat: 30.85893,
      lng: -87.74188,
      city: 'Bay Minette',
      state: 'AL',
    },
    36509: {
      lat: 30.4077,
      lng: -88.24726,
      city: 'Bayou La Batre',
      state: 'AL',
    },
    36511: {
      lat: 30.31173,
      lng: -87.74259,
      city: 'Bon Secour',
      state: 'AL',
    },
    36512: {
      lat: 31.00327,
      lng: -88.01023,
      city: 'Bucks',
      state: 'AL',
    },
    36513: {
      lat: 31.15516,
      lng: -87.99021,
      city: 'Calvert',
      state: 'AL',
    },
    36518: {
      lat: 31.47082,
      lng: -88.27546,
      city: 'Chatom',
      state: 'AL',
    },
    36521: {
      lat: 30.96241,
      lng: -88.18526,
      city: 'Chunchula',
      state: 'AL',
    },
    36522: {
      lat: 31.08296,
      lng: -88.27795,
      city: 'Citronelle',
      state: 'AL',
    },
    36523: {
      lat: 30.37852,
      lng: -88.16536,
      city: 'Coden',
      state: 'AL',
    },
    36524: {
      lat: 31.80868,
      lng: -88.0203,
      city: 'Coffeeville',
      state: 'AL',
    },
    36525: {
      lat: 30.8898,
      lng: -88.02329,
      city: 'Creola',
      state: 'AL',
    },
    36526: {
      lat: 30.61187,
      lng: -87.86151,
      city: 'Daphne',
      state: 'AL',
    },
    36527: {
      lat: 30.69679,
      lng: -87.87734,
      city: 'Daphne',
      state: 'AL',
    },
    36528: {
      lat: 30.25253,
      lng: -88.14381,
      city: 'Dauphin Island',
      state: 'AL',
    },
    36529: {
      lat: 31.29279,
      lng: -88.27281,
      city: 'Deer Park',
      state: 'AL',
    },
    36530: {
      lat: 30.41192,
      lng: -87.55445,
      city: 'Elberta',
      state: 'AL',
    },
    36532: {
      lat: 30.48197,
      lng: -87.86207,
      city: 'Fairhope',
      state: 'AL',
    },
    36535: {
      lat: 30.38562,
      lng: -87.72514,
      city: 'Foley',
      state: 'AL',
    },
    36538: {
      lat: 31.65948,
      lng: -88.13836,
      city: 'Frankville',
      state: 'AL',
    },
    36539: {
      lat: 31.3475,
      lng: -88.39358,
      city: 'Fruitdale',
      state: 'AL',
    },
    36540: {
      lat: 31.44702,
      lng: -87.66487,
      city: 'Gainestown',
      state: 'AL',
    },
    36541: {
      lat: 30.49084,
      lng: -88.34384,
      city: 'Grand Bay',
      state: 'AL',
    },
    36542: {
      lat: 30.27683,
      lng: -87.73867,
      city: 'Gulf Shores',
      state: 'AL',
    },
    36543: {
      lat: 31.22129,
      lng: -87.46592,
      city: 'Huxford',
      state: 'AL',
    },
    36544: {
      lat: 30.47436,
      lng: -88.234,
      city: 'Irvington',
      state: 'AL',
    },
    36545: {
      lat: 31.48767,
      lng: -87.87424,
      city: 'Jackson',
      state: 'AL',
    },
    36547: {
      lat: 30.25278,
      lng: -87.65405,
      city: 'Gulf Shores',
      state: 'AL',
    },
    36548: {
      lat: 31.48326,
      lng: -87.97226,
      city: 'Leroy',
      state: 'AL',
    },
    36549: {
      lat: 30.41377,
      lng: -87.44951,
      city: 'Lillian',
      state: 'AL',
    },
    36550: {
      lat: 31.25212,
      lng: -87.74294,
      city: 'Little River',
      state: 'AL',
    },
    36551: {
      lat: 30.63982,
      lng: -87.7504,
      city: 'Loxley',
      state: 'AL',
    },
    36553: {
      lat: 31.22852,
      lng: -88.08658,
      city: 'McIntosh',
      state: 'AL',
    },
    36555: {
      lat: 30.40006,
      lng: -87.77312,
      city: 'Magnolia Springs',
      state: 'AL',
    },
    36556: {
      lat: 31.20649,
      lng: -87.97127,
      city: 'Malcolm',
      state: 'AL',
    },
    36558: {
      lat: 31.61606,
      lng: -88.33034,
      city: 'Millry',
      state: 'AL',
    },
    36559: {
      lat: 30.56894,
      lng: -87.90393,
      city: 'Montrose',
      state: 'AL',
    },
    36560: {
      lat: 31.08553,
      lng: -88.04991,
      city: 'Mount Vernon',
      state: 'AL',
    },
    36561: {
      lat: 30.28813,
      lng: -87.57142,
      city: 'Orange Beach',
      state: 'AL',
    },
    36562: {
      lat: 31.03738,
      lng: -87.66274,
      city: 'Perdido',
      state: 'AL',
    },
    36564: {
      lat: 30.46983,
      lng: -87.91467,
      city: 'Point Clear',
      state: 'AL',
    },
    36567: {
      lat: 30.61483,
      lng: -87.55792,
      city: 'Robertsdale',
      state: 'AL',
    },
    36568: {
      lat: 30.51291,
      lng: -88.27067,
      city: 'Saint Elmo',
      state: 'AL',
    },
    36569: {
      lat: 31.54418,
      lng: -88.07907,
      city: 'Saint Stephens',
      state: 'AL',
    },
    36571: {
      lat: 30.85055,
      lng: -88.10415,
      city: 'Saraland',
      state: 'AL',
    },
    36572: {
      lat: 30.85617,
      lng: -88.05956,
      city: 'Satsuma',
      state: 'AL',
    },
    36574: {
      lat: 30.5141,
      lng: -87.48258,
      city: 'Seminole',
      state: 'AL',
    },
    36575: {
      lat: 30.76825,
      lng: -88.27276,
      city: 'Semmes',
      state: 'AL',
    },
    36576: {
      lat: 30.52654,
      lng: -87.76654,
      city: 'Silverhill',
      state: 'AL',
    },
    36578: {
      lat: 30.73867,
      lng: -87.77275,
      city: 'Stapleton',
      state: 'AL',
    },
    36579: {
      lat: 31.11395,
      lng: -87.78616,
      city: 'Stockton',
      state: 'AL',
    },
    36580: {
      lat: 30.48085,
      lng: -87.70321,
      city: 'Summerdale',
      state: 'AL',
    },
    36581: {
      lat: 31.36645,
      lng: -88.0098,
      city: 'Sunflower',
      state: 'AL',
    },
    36582: {
      lat: 30.51616,
      lng: -88.17719,
      city: 'Theodore',
      state: 'AL',
    },
    36583: {
      lat: 31.36677,
      lng: -88.1869,
      city: 'Tibbie',
      state: 'AL',
    },
    36584: {
      lat: 31.19579,
      lng: -88.38385,
      city: 'Vinegar Bend',
      state: 'AL',
    },
    36585: {
      lat: 31.36985,
      lng: -88.02338,
      city: 'Wagarville',
      state: 'AL',
    },
    36587: {
      lat: 30.83599,
      lng: -88.35006,
      city: 'Wilmer',
      state: 'AL',
    },
    36602: {
      lat: 30.69834,
      lng: -88.03815,
      city: 'Mobile',
      state: 'AL',
    },
    36603: {
      lat: 30.68426,
      lng: -88.05273,
      city: 'Mobile',
      state: 'AL',
    },
    36604: {
      lat: 30.68229,
      lng: -88.06837,
      city: 'Mobile',
      state: 'AL',
    },
    36605: {
      lat: 30.60649,
      lng: -88.09064,
      city: 'Mobile',
      state: 'AL',
    },
    36606: {
      lat: 30.67088,
      lng: -88.10617,
      city: 'Mobile',
      state: 'AL',
    },
    36607: { lat: 30.699, lng: -88.10552, city: 'Mobile', state: 'AL' },
    36608: {
      lat: 30.68115,
      lng: -88.29593,
      city: 'Mobile',
      state: 'AL',
    },
    36609: {
      lat: 30.66086,
      lng: -88.16378,
      city: 'Mobile',
      state: 'AL',
    },
    36610: {
      lat: 30.73721,
      lng: -88.07389,
      city: 'Mobile',
      state: 'AL',
    },
    36611: {
      lat: 30.7702,
      lng: -88.07747,
      city: 'Mobile',
      state: 'AL',
    },
    36612: {
      lat: 30.75283,
      lng: -88.11285,
      city: 'Mobile',
      state: 'AL',
    },
    36613: {
      lat: 30.81175,
      lng: -88.18176,
      city: 'Eight Mile',
      state: 'AL',
    },
    36615: {
      lat: 30.63563,
      lng: -88.06717,
      city: 'Mobile',
      state: 'AL',
    },
    36617: {
      lat: 30.71544,
      lng: -88.09518,
      city: 'Mobile',
      state: 'AL',
    },
    36618: {
      lat: 30.73612,
      lng: -88.17333,
      city: 'Mobile',
      state: 'AL',
    },
    36619: {
      lat: 30.59488,
      lng: -88.19302,
      city: 'Mobile',
      state: 'AL',
    },
    36652: {
      lat: 30.69586,
      lng: -88.0803,
      city: 'Mobile',
      state: 'AL',
    },
    36663: {
      lat: 30.73683,
      lng: -88.10896,
      city: 'Mobile',
      state: 'AL',
    },
    36688: {
      lat: 30.69559,
      lng: -88.18783,
      city: 'Mobile',
      state: 'AL',
    },
    36693: {
      lat: 30.62676,
      lng: -88.14989,
      city: 'Mobile',
      state: 'AL',
    },
    36695: {
      lat: 30.6309,
      lng: -88.27775,
      city: 'Mobile',
      state: 'AL',
    },
    36701: { lat: 32.49672, lng: -87.0568, city: 'Selma', state: 'AL' },
    36703: {
      lat: 32.43387,
      lng: -86.91118,
      city: 'Selma',
      state: 'AL',
    },
    36720: {
      lat: 32.16493,
      lng: -87.34684,
      city: 'Alberta',
      state: 'AL',
    },
    36722: {
      lat: 32.08124,
      lng: -87.57661,
      city: 'Arlington',
      state: 'AL',
    },
    36723: {
      lat: 32.06633,
      lng: -87.2907,
      city: 'Boykin',
      state: 'AL',
    },
    36726: {
      lat: 31.97043,
      lng: -87.27532,
      city: 'Camden',
      state: 'AL',
    },
    36727: {
      lat: 31.91193,
      lng: -88.02054,
      city: 'Campbell',
      state: 'AL',
    },
    36728: {
      lat: 32.16625,
      lng: -87.48416,
      city: 'Catherine',
      state: 'AL',
    },
    36732: {
      lat: 32.41638,
      lng: -87.91367,
      city: 'Demopolis',
      state: 'AL',
    },
    36736: {
      lat: 32.07832,
      lng: -87.76444,
      city: 'Dixons Mills',
      state: 'AL',
    },
    36738: {
      lat: 32.43421,
      lng: -87.62481,
      city: 'Faunsdale',
      state: 'AL',
    },
    36740: {
      lat: 32.62421,
      lng: -87.83209,
      city: 'Forkland',
      state: 'AL',
    },
    36741: {
      lat: 32.02198,
      lng: -87.02594,
      city: 'Furman',
      state: 'AL',
    },
    36742: {
      lat: 32.45836,
      lng: -87.74215,
      city: 'Gallion',
      state: 'AL',
    },
    36744: {
      lat: 32.69178,
      lng: -87.59845,
      city: 'Greensboro',
      state: 'AL',
    },
    36748: {
      lat: 32.27084,
      lng: -87.77728,
      city: 'Linden',
      state: 'AL',
    },
    36749: {
      lat: 32.54759,
      lng: -86.85303,
      city: 'Jones',
      state: 'AL',
    },
    36750: {
      lat: 32.78396,
      lng: -86.84361,
      city: 'Maplesville',
      state: 'AL',
    },
    36751: {
      lat: 31.80808,
      lng: -87.54865,
      city: 'Lower Peach Tree',
      state: 'AL',
    },
    36752: {
      lat: 32.30628,
      lng: -86.62544,
      city: 'Lowndesboro',
      state: 'AL',
    },
    36753: {
      lat: 31.83698,
      lng: -87.10762,
      city: 'McWilliams',
      state: 'AL',
    },
    36754: {
      lat: 32.15731,
      lng: -87.70238,
      city: 'Magnolia',
      state: 'AL',
    },
    36756: {
      lat: 32.69327,
      lng: -87.29873,
      city: 'Marion',
      state: 'AL',
    },
    36758: {
      lat: 32.6491,
      lng: -86.89975,
      city: 'Plantersville',
      state: 'AL',
    },
    36759: {
      lat: 32.42977,
      lng: -87.28722,
      city: 'Marion Junction',
      state: 'AL',
    },
    36761: {
      lat: 32.07872,
      lng: -86.97602,
      city: 'Minter',
      state: 'AL',
    },
    36763: {
      lat: 32.26184,
      lng: -87.96509,
      city: 'Myrtlewood',
      state: 'AL',
    },
    36764: {
      lat: 32.11518,
      lng: -87.98526,
      city: 'Nanafalia',
      state: 'AL',
    },
    36765: {
      lat: 32.59278,
      lng: -87.51933,
      city: 'Newbern',
      state: 'AL',
    },
    36766: {
      lat: 31.95505,
      lng: -87.08668,
      city: 'Oak Hill',
      state: 'AL',
    },
    36767: {
      lat: 32.25083,
      lng: -87.21042,
      city: 'Orrville',
      state: 'AL',
    },
    36768: {
      lat: 31.8837,
      lng: -87.01186,
      city: 'Pine Apple',
      state: 'AL',
    },
    36769: {
      lat: 31.99306,
      lng: -87.55049,
      city: 'Pine Hill',
      state: 'AL',
    },
    36773: {
      lat: 32.28252,
      lng: -87.38607,
      city: 'Safford',
      state: 'AL',
    },
    36775: {
      lat: 32.20066,
      lng: -86.95487,
      city: 'Sardis',
      state: 'AL',
    },
    36776: {
      lat: 32.74513,
      lng: -87.74488,
      city: 'Sawyerville',
      state: 'AL',
    },
    36782: {
      lat: 32.11684,
      lng: -87.90996,
      city: 'Sweet Water',
      state: 'AL',
    },
    36783: {
      lat: 32.24085,
      lng: -87.6081,
      city: 'Thomaston',
      state: 'AL',
    },
    36784: {
      lat: 31.94208,
      lng: -87.82271,
      city: 'Thomasville',
      state: 'AL',
    },
    36785: {
      lat: 32.27615,
      lng: -86.82683,
      city: 'Tyler',
      state: 'AL',
    },
    36786: {
      lat: 32.43832,
      lng: -87.49002,
      city: 'Uniontown',
      state: 'AL',
    },
    36790: {
      lat: 32.72758,
      lng: -86.9107,
      city: 'Stanton',
      state: 'AL',
    },
    36792: {
      lat: 32.92146,
      lng: -86.91147,
      city: 'Randolph',
      state: 'AL',
    },
    36793: {
      lat: 32.8292,
      lng: -87.00947,
      city: 'Lawley',
      state: 'AL',
    },
    36801: {
      lat: 32.701,
      lng: -85.39243,
      city: 'Opelika',
      state: 'AL',
    },
    36804: {
      lat: 32.53045,
      lng: -85.33875,
      city: 'Opelika',
      state: 'AL',
    },
    36830: {
      lat: 32.5339,
      lng: -85.49449,
      city: 'Auburn',
      state: 'AL',
    },
    36832: {
      lat: 32.59447,
      lng: -85.58163,
      city: 'Auburn',
      state: 'AL',
    },
    36849: {
      lat: 32.59953,
      lng: -85.48858,
      city: 'Auburn University',
      state: 'AL',
    },
    36850: {
      lat: 32.78857,
      lng: -85.65336,
      city: 'Camp Hill',
      state: 'AL',
    },
    36851: {
      lat: 32.15039,
      lng: -85.08094,
      city: 'Cottonton',
      state: 'AL',
    },
    36852: {
      lat: 32.75343,
      lng: -85.27942,
      city: 'Cusseta',
      state: 'AL',
    },
    36853: {
      lat: 32.80557,
      lng: -85.76809,
      city: 'Dadeville',
      state: 'AL',
    },
    36854: {
      lat: 32.75257,
      lng: -85.18484,
      city: 'Valley',
      state: 'AL',
    },
    36855: {
      lat: 33.03709,
      lng: -85.30516,
      city: 'Five Points',
      state: 'AL',
    },
    36856: {
      lat: 32.26897,
      lng: -84.99757,
      city: 'Fort Mitchell',
      state: 'AL',
    },
    36858: {
      lat: 32.30061,
      lng: -85.30138,
      city: 'Hatchechubbee',
      state: 'AL',
    },
    36860: {
      lat: 32.26118,
      lng: -85.42064,
      city: 'Hurtsboro',
      state: 'AL',
    },
    36861: {
      lat: 32.88585,
      lng: -85.83232,
      city: 'Jacksons Gap',
      state: 'AL',
    },
    36862: {
      lat: 32.90612,
      lng: -85.44283,
      city: 'Lafayette',
      state: 'AL',
    },
    36863: {
      lat: 32.89722,
      lng: -85.25916,
      city: 'Lanett',
      state: 'AL',
    },
    36865: {
      lat: 32.60259,
      lng: -85.58946,
      city: 'Loachapoka',
      state: 'AL',
    },
    36866: {
      lat: 32.55468,
      lng: -85.71459,
      city: 'Notasulga',
      state: 'AL',
    },
    36867: {
      lat: 32.49823,
      lng: -85.02304,
      city: 'Phenix City',
      state: 'AL',
    },
    36869: {
      lat: 32.41891,
      lng: -85.0779,
      city: 'Phenix City',
      state: 'AL',
    },
    36870: {
      lat: 32.48308,
      lng: -85.11942,
      city: 'Phenix City',
      state: 'AL',
    },
    36871: {
      lat: 32.17455,
      lng: -85.14892,
      city: 'Pittsview',
      state: 'AL',
    },
    36874: {
      lat: 32.56603,
      lng: -85.20322,
      city: 'Salem',
      state: 'AL',
    },
    36875: {
      lat: 32.32632,
      lng: -85.16295,
      city: 'Seale',
      state: 'AL',
    },
    36877: {
      lat: 32.55465,
      lng: -85.0974,
      city: 'Smiths Station',
      state: 'AL',
    },
    36879: {
      lat: 32.73091,
      lng: -85.55449,
      city: 'Waverly',
      state: 'AL',
    },
    36901: {
      lat: 32.42284,
      lng: -88.1465,
      city: 'Bellamy',
      state: 'AL',
    },
    36904: {
      lat: 32.10287,
      lng: -88.25596,
      city: 'Butler',
      state: 'AL',
    },
    36907: { lat: 32.43895, lng: -88.34797, city: 'Cuba', state: 'AL' },
    36908: {
      lat: 31.89818,
      lng: -88.30807,
      city: 'Gilbertown',
      state: 'AL',
    },
    36910: {
      lat: 32.23078,
      lng: -88.15928,
      city: 'Jachin',
      state: 'AL',
    },
    36912: {
      lat: 32.23825,
      lng: -88.30369,
      city: 'Lisman',
      state: 'AL',
    },
    36913: {
      lat: 31.96069,
      lng: -88.44667,
      city: 'Melvin',
      state: 'AL',
    },
    36915: {
      lat: 31.95894,
      lng: -88.36019,
      city: 'Needham',
      state: 'AL',
    },
    36916: {
      lat: 32.23788,
      lng: -88.04143,
      city: 'Pennington',
      state: 'AL',
    },
    36919: { lat: 31.76765, lng: -88.2894, city: 'Silas', state: 'AL' },
    36921: {
      lat: 31.96956,
      lng: -88.19108,
      city: 'Toxey',
      state: 'AL',
    },
    36922: { lat: 32.30061, lng: -88.15068, city: 'Ward', state: 'AL' },
    36925: { lat: 32.43468, lng: -88.1791, city: 'York', state: 'AL' },
    37010: { lat: 36.5675, lng: -87.10894, city: 'Adams', state: 'TN' },
    37012: {
      lat: 36.07112,
      lng: -86.00563,
      city: 'Alexandria',
      state: 'TN',
    },
    37013: {
      lat: 36.04658,
      lng: -86.63445,
      city: 'Antioch',
      state: 'TN',
    },
    37014: {
      lat: 35.8725,
      lng: -86.6532,
      city: 'Arrington',
      state: 'TN',
    },
    37015: {
      lat: 36.28798,
      lng: -87.08113,
      city: 'Ashland City',
      state: 'TN',
    },
    37016: {
      lat: 35.96355,
      lng: -86.11364,
      city: 'Auburntown',
      state: 'TN',
    },
    37018: {
      lat: 35.64255,
      lng: -86.19876,
      city: 'Beechgrove',
      state: 'TN',
    },
    37019: {
      lat: 35.39094,
      lng: -86.70943,
      city: 'Belfast',
      state: 'TN',
    },
    37020: {
      lat: 35.62563,
      lng: -86.39927,
      city: 'Bell Buckle',
      state: 'TN',
    },
    37022: {
      lat: 36.4997,
      lng: -86.31017,
      city: 'Bethpage',
      state: 'TN',
    },
    37023: {
      lat: 36.58409,
      lng: -87.78302,
      city: 'Big Rock',
      state: 'TN',
    },
    37025: {
      lat: 35.95263,
      lng: -87.30855,
      city: 'Bon Aqua',
      state: 'TN',
    },
    37026: {
      lat: 35.69578,
      lng: -86.10746,
      city: 'Bradyville',
      state: 'TN',
    },
    37027: {
      lat: 35.99978,
      lng: -86.78439,
      city: 'Brentwood',
      state: 'TN',
    },
    37028: {
      lat: 36.62793,
      lng: -87.85723,
      city: 'Bumpus Mills',
      state: 'TN',
    },
    37029: { lat: 36.04253, lng: -87.2678, city: 'Burns', state: 'TN' },
    37030: {
      lat: 36.27374,
      lng: -85.95035,
      city: 'Carthage',
      state: 'TN',
    },
    37031: {
      lat: 36.3721,
      lng: -86.30328,
      city: 'Castalian Springs',
      state: 'TN',
    },
    37032: {
      lat: 36.52518,
      lng: -87.01647,
      city: 'Cedar Hill',
      state: 'TN',
    },
    37033: {
      lat: 35.73493,
      lng: -87.55365,
      city: 'Centerville',
      state: 'TN',
    },
    37034: {
      lat: 35.63977,
      lng: -86.69158,
      city: 'Chapel Hill',
      state: 'TN',
    },
    37035: {
      lat: 36.3849,
      lng: -87.12602,
      city: 'Chapmansboro',
      state: 'TN',
    },
    37036: {
      lat: 36.23979,
      lng: -87.27182,
      city: 'Charlotte',
      state: 'TN',
    },
    37037: {
      lat: 35.70272,
      lng: -86.35318,
      city: 'Christiana',
      state: 'TN',
    },
    37040: {
      lat: 36.52408,
      lng: -87.33287,
      city: 'Clarksville',
      state: 'TN',
    },
    37042: {
      lat: 36.56894,
      lng: -87.41225,
      city: 'Clarksville',
      state: 'TN',
    },
    37043: {
      lat: 36.49842,
      lng: -87.23197,
      city: 'Clarksville',
      state: 'TN',
    },
    37046: {
      lat: 35.77995,
      lng: -86.71582,
      city: 'College Grove',
      state: 'TN',
    },
    37047: {
      lat: 35.31291,
      lng: -86.83191,
      city: 'Cornersville',
      state: 'TN',
    },
    37048: {
      lat: 36.4904,
      lng: -86.59112,
      city: 'Cottontown',
      state: 'TN',
    },
    37049: {
      lat: 36.55985,
      lng: -86.67887,
      city: 'Cross Plains',
      state: 'TN',
    },
    37050: {
      lat: 36.3713,
      lng: -87.63586,
      city: 'Cumberland City',
      state: 'TN',
    },
    37051: {
      lat: 36.30426,
      lng: -87.41405,
      city: 'Cumberland Furnace',
      state: 'TN',
    },
    37052: {
      lat: 36.37534,
      lng: -87.40095,
      city: 'Cunningham',
      state: 'TN',
    },
    37055: {
      lat: 36.07921,
      lng: -87.44238,
      city: 'Dickson',
      state: 'TN',
    },
    37057: {
      lat: 36.40466,
      lng: -86.02726,
      city: 'Dixon Springs',
      state: 'TN',
    },
    37058: { lat: 36.47872, lng: -87.8807, city: 'Dover', state: 'TN' },
    37059: {
      lat: 35.98611,
      lng: -85.90798,
      city: 'Dowelltown',
      state: 'TN',
    },
    37060: {
      lat: 35.74924,
      lng: -86.63428,
      city: 'Eagleville',
      state: 'TN',
    },
    37061: { lat: 36.28918, lng: -87.66528, city: 'Erin', state: 'TN' },
    37062: {
      lat: 35.98833,
      lng: -87.13483,
      city: 'Fairview',
      state: 'TN',
    },
    37064: {
      lat: 35.88966,
      lng: -86.96112,
      city: 'Franklin',
      state: 'TN',
    },
    37066: {
      lat: 36.39871,
      lng: -86.45594,
      city: 'Gallatin',
      state: 'TN',
    },
    37067: {
      lat: 35.91314,
      lng: -86.77461,
      city: 'Franklin',
      state: 'TN',
    },
    37069: {
      lat: 35.98753,
      lng: -86.90664,
      city: 'Franklin',
      state: 'TN',
    },
    37072: {
      lat: 36.35686,
      lng: -86.74494,
      city: 'Goodlettsville',
      state: 'TN',
    },
    37073: {
      lat: 36.42834,
      lng: -86.81414,
      city: 'Greenbrier',
      state: 'TN',
    },
    37074: {
      lat: 36.41552,
      lng: -86.14724,
      city: 'Hartsville',
      state: 'TN',
    },
    37075: {
      lat: 36.34085,
      lng: -86.60845,
      city: 'Hendersonville',
      state: 'TN',
    },
    37076: {
      lat: 36.14512,
      lng: -86.57977,
      city: 'Hermitage',
      state: 'TN',
    },
    37078: {
      lat: 35.92366,
      lng: -87.77138,
      city: 'Hurricane Mills',
      state: 'TN',
    },
    37079: {
      lat: 36.4826,
      lng: -87.65939,
      city: 'Indian Mound',
      state: 'TN',
    },
    37080: {
      lat: 36.32901,
      lng: -86.91633,
      city: 'Joelton',
      state: 'TN',
    },
    37082: {
      lat: 36.09717,
      lng: -87.11484,
      city: 'Kingston Springs',
      state: 'TN',
    },
    37083: {
      lat: 36.54357,
      lng: -86.00149,
      city: 'Lafayette',
      state: 'TN',
    },
    37085: {
      lat: 35.95308,
      lng: -86.27912,
      city: 'Lascassas',
      state: 'TN',
    },
    37086: {
      lat: 36.02177,
      lng: -86.55971,
      city: 'La Vergne',
      state: 'TN',
    },
    37087: {
      lat: 36.2733,
      lng: -86.27224,
      city: 'Lebanon',
      state: 'TN',
    },
    37090: {
      lat: 36.12439,
      lng: -86.29707,
      city: 'Lebanon',
      state: 'TN',
    },
    37091: {
      lat: 35.48546,
      lng: -86.76405,
      city: 'Lewisburg',
      state: 'TN',
    },
    37095: {
      lat: 35.98295,
      lng: -85.95919,
      city: 'Liberty',
      state: 'TN',
    },
    37096: {
      lat: 35.60487,
      lng: -87.86054,
      city: 'Linden',
      state: 'TN',
    },
    37097: {
      lat: 35.7785,
      lng: -87.8303,
      city: 'Lobelville',
      state: 'TN',
    },
    37098: {
      lat: 35.87142,
      lng: -87.31075,
      city: 'Lyles',
      state: 'TN',
    },
    37101: {
      lat: 36.06625,
      lng: -87.63741,
      city: 'McEwen',
      state: 'TN',
    },
    37110: {
      lat: 35.6436,
      lng: -85.74314,
      city: 'Mcminnville',
      state: 'TN',
    },
    37115: {
      lat: 36.25444,
      lng: -86.69636,
      city: 'Madison',
      state: 'TN',
    },
    37118: {
      lat: 35.92569,
      lng: -86.18903,
      city: 'Milton',
      state: 'TN',
    },
    37122: {
      lat: 36.17791,
      lng: -86.48872,
      city: 'Mount Juliet',
      state: 'TN',
    },
    37127: {
      lat: 35.77947,
      lng: -86.32723,
      city: 'Murfreesboro',
      state: 'TN',
    },
    37128: {
      lat: 35.80343,
      lng: -86.47483,
      city: 'Murfreesboro',
      state: 'TN',
    },
    37129: {
      lat: 35.92556,
      lng: -86.44098,
      city: 'Murfreesboro',
      state: 'TN',
    },
    37130: {
      lat: 35.8809,
      lng: -86.33003,
      city: 'Murfreesboro',
      state: 'TN',
    },
    37132: {
      lat: 35.84873,
      lng: -86.36284,
      city: 'Murfreesboro',
      state: 'TN',
    },
    37134: {
      lat: 36.0014,
      lng: -87.95063,
      city: 'New Johnsonville',
      state: 'TN',
    },
    37135: {
      lat: 35.93562,
      lng: -86.6625,
      city: 'Nolensville',
      state: 'TN',
    },
    37137: {
      lat: 35.87912,
      lng: -87.50276,
      city: 'Nunnelly',
      state: 'TN',
    },
    37138: {
      lat: 36.24652,
      lng: -86.61938,
      city: 'Old Hickory',
      state: 'TN',
    },
    37140: { lat: 35.86345, lng: -87.65245, city: 'Only', state: 'TN' },
    37141: {
      lat: 36.6145,
      lng: -86.69894,
      city: 'Orlinda',
      state: 'TN',
    },
    37142: {
      lat: 36.41164,
      lng: -87.50112,
      city: 'Palmyra',
      state: 'TN',
    },
    37143: {
      lat: 36.13137,
      lng: -87.03038,
      city: 'Pegram',
      state: 'TN',
    },
    37144: {
      lat: 35.30516,
      lng: -86.65585,
      city: 'Petersburg',
      state: 'TN',
    },
    37145: {
      lat: 36.38179,
      lng: -85.91203,
      city: 'Pleasant Shade',
      state: 'TN',
    },
    37146: {
      lat: 36.4047,
      lng: -87.03123,
      city: 'Pleasant View',
      state: 'TN',
    },
    37148: {
      lat: 36.57694,
      lng: -86.50224,
      city: 'Portland',
      state: 'TN',
    },
    37149: {
      lat: 35.80876,
      lng: -86.19311,
      city: 'Readyville',
      state: 'TN',
    },
    37150: {
      lat: 36.54437,
      lng: -85.81082,
      city: 'Red Boiling Springs',
      state: 'TN',
    },
    37151: {
      lat: 36.31288,
      lng: -86.04536,
      city: 'Riddleton',
      state: 'TN',
    },
    37153: {
      lat: 35.7645,
      lng: -86.55531,
      city: 'Rockvale',
      state: 'TN',
    },
    37160: {
      lat: 35.46562,
      lng: -86.48268,
      city: 'Shelbyville',
      state: 'TN',
    },
    37165: {
      lat: 36.29005,
      lng: -87.46943,
      city: 'Slayden',
      state: 'TN',
    },
    37166: {
      lat: 35.92444,
      lng: -85.78978,
      city: 'Smithville',
      state: 'TN',
    },
    37167: {
      lat: 35.96325,
      lng: -86.52922,
      city: 'Smyrna',
      state: 'TN',
    },
    37171: {
      lat: 36.36442,
      lng: -87.30365,
      city: 'Southside',
      state: 'TN',
    },
    37172: {
      lat: 36.53661,
      lng: -86.86005,
      city: 'Springfield',
      state: 'TN',
    },
    37174: {
      lat: 35.72197,
      lng: -86.8995,
      city: 'Spring Hill',
      state: 'TN',
    },
    37175: {
      lat: 36.32349,
      lng: -87.89678,
      city: 'Stewart',
      state: 'TN',
    },
    37178: {
      lat: 36.3195,
      lng: -87.78694,
      city: 'Tennessee Ridge',
      state: 'TN',
    },
    37179: {
      lat: 35.8073,
      lng: -86.91517,
      city: 'Thompsons Station',
      state: 'TN',
    },
    37180: {
      lat: 35.6189,
      lng: -86.57679,
      city: 'Unionville',
      state: 'TN',
    },
    37181: {
      lat: 36.241,
      lng: -87.49706,
      city: 'Vanleer',
      state: 'TN',
    },
    37183: {
      lat: 35.5104,
      lng: -86.30162,
      city: 'Wartrace',
      state: 'TN',
    },
    37184: {
      lat: 36.08682,
      lng: -86.14524,
      city: 'Watertown',
      state: 'TN',
    },
    37185: {
      lat: 36.07118,
      lng: -87.8392,
      city: 'Waverly',
      state: 'TN',
    },
    37186: {
      lat: 36.58644,
      lng: -86.22868,
      city: 'Westmoreland',
      state: 'TN',
    },
    37187: {
      lat: 36.1471,
      lng: -87.19885,
      city: 'White Bluff',
      state: 'TN',
    },
    37188: {
      lat: 36.48048,
      lng: -86.68101,
      city: 'White House',
      state: 'TN',
    },
    37189: {
      lat: 36.27875,
      lng: -86.82983,
      city: 'Whites Creek',
      state: 'TN',
    },
    37190: {
      lat: 35.82599,
      lng: -86.0424,
      city: 'Woodbury',
      state: 'TN',
    },
    37191: {
      lat: 36.50726,
      lng: -87.52392,
      city: 'Woodlawn',
      state: 'TN',
    },
    37201: {
      lat: 36.16662,
      lng: -86.77799,
      city: 'Nashville',
      state: 'TN',
    },
    37203: {
      lat: 36.14937,
      lng: -86.78986,
      city: 'Nashville',
      state: 'TN',
    },
    37204: {
      lat: 36.10669,
      lng: -86.77463,
      city: 'Nashville',
      state: 'TN',
    },
    37205: {
      lat: 36.11075,
      lng: -86.87274,
      city: 'Nashville',
      state: 'TN',
    },
    37206: {
      lat: 36.18085,
      lng: -86.73117,
      city: 'Nashville',
      state: 'TN',
    },
    37207: {
      lat: 36.23278,
      lng: -86.77765,
      city: 'Nashville',
      state: 'TN',
    },
    37208: {
      lat: 36.17691,
      lng: -86.80901,
      city: 'Nashville',
      state: 'TN',
    },
    37209: {
      lat: 36.15591,
      lng: -86.91638,
      city: 'Nashville',
      state: 'TN',
    },
    37210: {
      lat: 36.1442,
      lng: -86.73374,
      city: 'Nashville',
      state: 'TN',
    },
    37211: {
      lat: 36.06704,
      lng: -86.72345,
      city: 'Nashville',
      state: 'TN',
    },
    37212: {
      lat: 36.13376,
      lng: -86.80167,
      city: 'Nashville',
      state: 'TN',
    },
    37213: {
      lat: 36.16654,
      lng: -86.76731,
      city: 'Nashville',
      state: 'TN',
    },
    37214: {
      lat: 36.16789,
      lng: -86.66296,
      city: 'Nashville',
      state: 'TN',
    },
    37215: {
      lat: 36.08197,
      lng: -86.83492,
      city: 'Nashville',
      state: 'TN',
    },
    37216: {
      lat: 36.21589,
      lng: -86.72676,
      city: 'Nashville',
      state: 'TN',
    },
    37217: {
      lat: 36.10836,
      lng: -86.66178,
      city: 'Nashville',
      state: 'TN',
    },
    37218: {
      lat: 36.20814,
      lng: -86.89158,
      city: 'Nashville',
      state: 'TN',
    },
    37219: {
      lat: 36.1663,
      lng: -86.78299,
      city: 'Nashville',
      state: 'TN',
    },
    37220: {
      lat: 36.06759,
      lng: -86.78127,
      city: 'Nashville',
      state: 'TN',
    },
    37221: {
      lat: 36.05991,
      lng: -86.97189,
      city: 'Nashville',
      state: 'TN',
    },
    37228: {
      lat: 36.19513,
      lng: -86.80418,
      city: 'Nashville',
      state: 'TN',
    },
    37232: {
      lat: 36.1411,
      lng: -86.8029,
      city: 'Nashville',
      state: 'TN',
    },
    37238: {
      lat: 36.1657,
      lng: -86.77946,
      city: 'Nashville',
      state: 'TN',
    },
    37301: {
      lat: 35.42765,
      lng: -85.80295,
      city: 'Altamont',
      state: 'TN',
    },
    37302: {
      lat: 35.01303,
      lng: -85.01961,
      city: 'Apison',
      state: 'TN',
    },
    37303: {
      lat: 35.4403,
      lng: -84.64051,
      city: 'Athens',
      state: 'TN',
    },
    37305: {
      lat: 35.45795,
      lng: -85.70195,
      city: 'Beersheba Springs',
      state: 'TN',
    },
    37306: {
      lat: 35.09776,
      lng: -86.18928,
      city: 'Belvidere',
      state: 'TN',
    },
    37307: {
      lat: 35.16676,
      lng: -84.62158,
      city: 'Benton',
      state: 'TN',
    },
    37308: {
      lat: 35.3476,
      lng: -84.99728,
      city: 'Birchwood',
      state: 'TN',
    },
    37309: {
      lat: 35.30595,
      lng: -84.74453,
      city: 'Calhoun',
      state: 'TN',
    },
    37310: {
      lat: 35.26237,
      lng: -84.77767,
      city: 'Charleston',
      state: 'TN',
    },
    37311: {
      lat: 35.10054,
      lng: -84.92298,
      city: 'Cleveland',
      state: 'TN',
    },
    37312: {
      lat: 35.2286,
      lng: -84.87344,
      city: 'Cleveland',
      state: 'TN',
    },
    37313: {
      lat: 35.37703,
      lng: -85.70245,
      city: 'Coalmont',
      state: 'TN',
    },
    37315: {
      lat: 35.04722,
      lng: -85.05158,
      city: 'Collegedale',
      state: 'TN',
    },
    37316: {
      lat: 35.00161,
      lng: -84.72975,
      city: 'Conasauga',
      state: 'TN',
    },
    37317: {
      lat: 35.03079,
      lng: -84.44341,
      city: 'Copperhill',
      state: 'TN',
    },
    37318: {
      lat: 35.17879,
      lng: -85.97994,
      city: 'Cowan',
      state: 'TN',
    },
    37321: {
      lat: 35.50239,
      lng: -85.00801,
      city: 'Dayton',
      state: 'TN',
    },
    37322: {
      lat: 35.49562,
      lng: -84.81512,
      city: 'Decatur',
      state: 'TN',
    },
    37323: {
      lat: 35.09627,
      lng: -84.81984,
      city: 'Cleveland',
      state: 'TN',
    },
    37324: {
      lat: 35.25251,
      lng: -86.00044,
      city: 'Decherd',
      state: 'TN',
    },
    37325: {
      lat: 35.24726,
      lng: -84.61108,
      city: 'Delano',
      state: 'TN',
    },
    37326: {
      lat: 35.04023,
      lng: -84.38329,
      city: 'Ducktown',
      state: 'TN',
    },
    37327: {
      lat: 35.42251,
      lng: -85.39993,
      city: 'Dunlap',
      state: 'TN',
    },
    37328: {
      lat: 35.02825,
      lng: -86.36876,
      city: 'Elora',
      state: 'TN',
    },
    37329: {
      lat: 35.39201,
      lng: -84.46017,
      city: 'Englewood',
      state: 'TN',
    },
    37330: {
      lat: 35.28411,
      lng: -86.12967,
      city: 'Estill Springs',
      state: 'TN',
    },
    37331: {
      lat: 35.31662,
      lng: -84.5336,
      city: 'Etowah',
      state: 'TN',
    },
    37332: {
      lat: 35.59418,
      lng: -84.96277,
      city: 'Evensville',
      state: 'TN',
    },
    37333: {
      lat: 35.14844,
      lng: -84.35981,
      city: 'Farner',
      state: 'TN',
    },
    37334: {
      lat: 35.16532,
      lng: -86.57966,
      city: 'Fayetteville',
      state: 'TN',
    },
    37335: {
      lat: 35.06584,
      lng: -86.40548,
      city: 'Flintville',
      state: 'TN',
    },
    37336: {
      lat: 35.30117,
      lng: -84.93413,
      city: 'Georgetown',
      state: 'TN',
    },
    37337: {
      lat: 35.78594,
      lng: -84.86651,
      city: 'Grandview',
      state: 'TN',
    },
    37338: {
      lat: 35.42356,
      lng: -85.20942,
      city: 'Graysville',
      state: 'TN',
    },
    37339: {
      lat: 35.37386,
      lng: -85.63222,
      city: 'Gruetli Laager',
      state: 'TN',
    },
    37340: {
      lat: 35.02085,
      lng: -85.53179,
      city: 'Guild',
      state: 'TN',
    },
    37341: {
      lat: 35.21386,
      lng: -85.0834,
      city: 'Harrison',
      state: 'TN',
    },
    37342: {
      lat: 35.38175,
      lng: -85.94937,
      city: 'Hillsboro',
      state: 'TN',
    },
    37343: {
      lat: 35.16904,
      lng: -85.20899,
      city: 'Hixson',
      state: 'TN',
    },
    37345: {
      lat: 35.0366,
      lng: -86.24299,
      city: 'Huntland',
      state: 'TN',
    },
    37347: {
      lat: 35.06088,
      lng: -85.61894,
      city: 'Jasper',
      state: 'TN',
    },
    37348: {
      lat: 35.11935,
      lng: -86.44222,
      city: 'Kelso',
      state: 'TN',
    },
    37350: {
      lat: 34.99445,
      lng: -85.3516,
      city: 'Lookout Mountain',
      state: 'TN',
    },
    37351: {
      lat: 35.10073,
      lng: -85.26135,
      city: 'Lupton City',
      state: 'TN',
    },
    37352: {
      lat: 35.27981,
      lng: -86.35417,
      city: 'Lynchburg',
      state: 'TN',
    },
    37353: {
      lat: 35.12288,
      lng: -84.9855,
      city: 'McDonald',
      state: 'TN',
    },
    37354: {
      lat: 35.50195,
      lng: -84.34387,
      city: 'Madisonville',
      state: 'TN',
    },
    37355: {
      lat: 35.50843,
      lng: -86.07866,
      city: 'Manchester',
      state: 'TN',
    },
    37356: {
      lat: 35.22952,
      lng: -85.82393,
      city: 'Monteagle',
      state: 'TN',
    },
    37357: {
      lat: 35.59454,
      lng: -85.92003,
      city: 'Morrison',
      state: 'TN',
    },
    37359: {
      lat: 35.19776,
      lng: -86.4119,
      city: 'Mulberry',
      state: 'TN',
    },
    37360: {
      lat: 35.44702,
      lng: -86.25586,
      city: 'Normandy',
      state: 'TN',
    },
    37361: { lat: 35.08543, lng: -84.6551, city: 'Ocoee', state: 'TN' },
    37362: {
      lat: 35.03152,
      lng: -84.70028,
      city: 'Old Fort',
      state: 'TN',
    },
    37363: {
      lat: 35.11551,
      lng: -85.0549,
      city: 'Ooltewah',
      state: 'TN',
    },
    37365: {
      lat: 35.37287,
      lng: -85.54013,
      city: 'Palmer',
      state: 'TN',
    },
    37366: {
      lat: 35.32054,
      lng: -85.84224,
      city: 'Pelham',
      state: 'TN',
    },
    37367: {
      lat: 35.62959,
      lng: -85.21832,
      city: 'Pikeville',
      state: 'TN',
    },
    37369: {
      lat: 35.19908,
      lng: -84.47707,
      city: 'Reliance',
      state: 'TN',
    },
    37370: {
      lat: 35.35903,
      lng: -84.70064,
      city: 'Riceville',
      state: 'TN',
    },
    37373: {
      lat: 35.39476,
      lng: -85.10373,
      city: 'Sale Creek',
      state: 'TN',
    },
    37374: {
      lat: 35.16404,
      lng: -85.65818,
      city: 'Sequatchie',
      state: 'TN',
    },
    37375: {
      lat: 35.15355,
      lng: -85.88173,
      city: 'Sewanee',
      state: 'TN',
    },
    37376: {
      lat: 35.04748,
      lng: -85.95834,
      city: 'Sherwood',
      state: 'TN',
    },
    37377: {
      lat: 35.21243,
      lng: -85.3389,
      city: 'Signal Mountain',
      state: 'TN',
    },
    37379: {
      lat: 35.29407,
      lng: -85.17429,
      city: 'Soddy Daisy',
      state: 'TN',
    },
    37380: {
      lat: 35.07265,
      lng: -85.75754,
      city: 'South Pittsburg',
      state: 'TN',
    },
    37381: {
      lat: 35.68533,
      lng: -84.8775,
      city: 'Spring City',
      state: 'TN',
    },
    37383: {
      lat: 35.20984,
      lng: -85.91373,
      city: 'Sewanee',
      state: 'TN',
    },
    37385: {
      lat: 35.33494,
      lng: -84.22933,
      city: 'Tellico Plains',
      state: 'TN',
    },
    37387: {
      lat: 35.27745,
      lng: -85.71427,
      city: 'Tracy City',
      state: 'TN',
    },
    37388: {
      lat: 35.35141,
      lng: -86.19747,
      city: 'Tullahoma',
      state: 'TN',
    },
    37391: {
      lat: 35.09049,
      lng: -84.35327,
      city: 'Turtletown',
      state: 'TN',
    },
    37394: {
      lat: 35.53918,
      lng: -85.86047,
      city: 'Viola',
      state: 'TN',
    },
    37396: {
      lat: 35.00198,
      lng: -85.50353,
      city: 'Whiteside',
      state: 'TN',
    },
    37397: {
      lat: 35.21046,
      lng: -85.51819,
      city: 'Whitwell',
      state: 'TN',
    },
    37398: {
      lat: 35.18182,
      lng: -86.13808,
      city: 'Winchester',
      state: 'TN',
    },
    37402: {
      lat: 35.04672,
      lng: -85.31553,
      city: 'Chattanooga',
      state: 'TN',
    },
    37403: {
      lat: 35.0476,
      lng: -85.29448,
      city: 'Chattanooga',
      state: 'TN',
    },
    37404: {
      lat: 35.02827,
      lng: -85.27331,
      city: 'Chattanooga',
      state: 'TN',
    },
    37405: {
      lat: 35.11757,
      lng: -85.40429,
      city: 'Chattanooga',
      state: 'TN',
    },
    37406: {
      lat: 35.07246,
      lng: -85.24541,
      city: 'Chattanooga',
      state: 'TN',
    },
    37407: {
      lat: 35.002,
      lng: -85.28941,
      city: 'Chattanooga',
      state: 'TN',
    },
    37408: {
      lat: 35.03048,
      lng: -85.31237,
      city: 'Chattanooga',
      state: 'TN',
    },
    37409: {
      lat: 35.0012,
      lng: -85.34466,
      city: 'Chattanooga',
      state: 'TN',
    },
    37410: {
      lat: 35.002,
      lng: -85.31366,
      city: 'Chattanooga',
      state: 'TN',
    },
    37411: {
      lat: 35.02655,
      lng: -85.23026,
      city: 'Chattanooga',
      state: 'TN',
    },
    37412: {
      lat: 34.9974,
      lng: -85.22726,
      city: 'Chattanooga',
      state: 'TN',
    },
    37415: {
      lat: 35.12735,
      lng: -85.27932,
      city: 'Chattanooga',
      state: 'TN',
    },
    37416: {
      lat: 35.09856,
      lng: -85.15751,
      city: 'Chattanooga',
      state: 'TN',
    },
    37419: {
      lat: 35.04052,
      lng: -85.41734,
      city: 'Chattanooga',
      state: 'TN',
    },
    37421: {
      lat: 35.02915,
      lng: -85.153,
      city: 'Chattanooga',
      state: 'TN',
    },
    37450: {
      lat: 35.04897,
      lng: -85.31217,
      city: 'Chattanooga',
      state: 'TN',
    },
    37601: {
      lat: 36.32838,
      lng: -82.31974,
      city: 'Johnson City',
      state: 'TN',
    },
    37604: {
      lat: 36.29923,
      lng: -82.38302,
      city: 'Johnson City',
      state: 'TN',
    },
    37614: {
      lat: 36.30097,
      lng: -82.36741,
      city: 'Johnson City',
      state: 'TN',
    },
    37615: {
      lat: 36.39771,
      lng: -82.45246,
      city: 'Johnson City',
      state: 'TN',
    },
    37616: { lat: 36.22619, lng: -82.7498, city: 'Afton', state: 'TN' },
    37617: {
      lat: 36.53155,
      lng: -82.37248,
      city: 'Blountville',
      state: 'TN',
    },
    37618: {
      lat: 36.46555,
      lng: -82.20833,
      city: 'Bluff City',
      state: 'TN',
    },
    37620: {
      lat: 36.55124,
      lng: -82.09867,
      city: 'Bristol',
      state: 'TN',
    },
    37640: {
      lat: 36.33023,
      lng: -81.9773,
      city: 'Butler',
      state: 'TN',
    },
    37641: {
      lat: 36.20015,
      lng: -82.66306,
      city: 'Chuckey',
      state: 'TN',
    },
    37642: {
      lat: 36.53261,
      lng: -82.7267,
      city: 'Church Hill',
      state: 'TN',
    },
    37643: {
      lat: 36.37513,
      lng: -82.13729,
      city: 'Elizabethton',
      state: 'TN',
    },
    37645: {
      lat: 36.56275,
      lng: -82.66243,
      city: 'Mount Carmel',
      state: 'TN',
    },
    37650: {
      lat: 36.10416,
      lng: -82.45938,
      city: 'Erwin',
      state: 'TN',
    },
    37656: {
      lat: 36.39997,
      lng: -82.63075,
      city: 'Fall Branch',
      state: 'TN',
    },
    37657: {
      lat: 36.01725,
      lng: -82.56057,
      city: 'Flag Pond',
      state: 'TN',
    },
    37658: {
      lat: 36.26352,
      lng: -82.1535,
      city: 'Hampton',
      state: 'TN',
    },
    37659: {
      lat: 36.28074,
      lng: -82.4938,
      city: 'Jonesborough',
      state: 'TN',
    },
    37660: {
      lat: 36.52938,
      lng: -82.57339,
      city: 'Kingsport',
      state: 'TN',
    },
    37663: {
      lat: 36.46466,
      lng: -82.48525,
      city: 'Kingsport',
      state: 'TN',
    },
    37664: {
      lat: 36.51163,
      lng: -82.50729,
      city: 'Kingsport',
      state: 'TN',
    },
    37665: {
      lat: 36.57951,
      lng: -82.57304,
      city: 'Kingsport',
      state: 'TN',
    },
    37680: {
      lat: 36.57462,
      lng: -81.72556,
      city: 'Laurel Bloomery',
      state: 'TN',
    },
    37681: {
      lat: 36.25465,
      lng: -82.62672,
      city: 'Limestone',
      state: 'TN',
    },
    37682: {
      lat: 36.30035,
      lng: -82.29169,
      city: 'Milligan College',
      state: 'TN',
    },
    37683: {
      lat: 36.45303,
      lng: -81.82233,
      city: 'Mountain City',
      state: 'TN',
    },
    37686: {
      lat: 36.44175,
      lng: -82.34639,
      city: 'Piney Flats',
      state: 'TN',
    },
    37687: {
      lat: 36.18667,
      lng: -82.09667,
      city: 'Roan Mountain',
      state: 'TN',
    },
    37688: {
      lat: 36.54548,
      lng: -81.88281,
      city: 'Shady Valley',
      state: 'TN',
    },
    37690: {
      lat: 36.25124,
      lng: -82.55895,
      city: 'Telford',
      state: 'TN',
    },
    37691: {
      lat: 36.36792,
      lng: -81.75519,
      city: 'Trade',
      state: 'TN',
    },
    37692: {
      lat: 36.18456,
      lng: -82.30413,
      city: 'Unicoi',
      state: 'TN',
    },
    37694: {
      lat: 36.3849,
      lng: -82.27588,
      city: 'Watauga',
      state: 'TN',
    },
    37701: {
      lat: 35.79393,
      lng: -83.98461,
      city: 'Alcoa',
      state: 'TN',
    },
    37705: {
      lat: 36.22862,
      lng: -84.00947,
      city: 'Andersonville',
      state: 'TN',
    },
    37708: {
      lat: 36.32312,
      lng: -83.33588,
      city: 'Bean Station',
      state: 'TN',
    },
    37709: {
      lat: 36.16798,
      lng: -83.66479,
      city: 'Blaine',
      state: 'TN',
    },
    37710: {
      lat: 36.15802,
      lng: -84.3177,
      city: 'Briceville',
      state: 'TN',
    },
    37711: {
      lat: 36.28102,
      lng: -83.03885,
      city: 'Bulls Gap',
      state: 'TN',
    },
    37713: {
      lat: 36.09073,
      lng: -83.13373,
      city: 'Bybee',
      state: 'TN',
    },
    37714: {
      lat: 36.26656,
      lng: -84.25277,
      city: 'Caryville',
      state: 'TN',
    },
    37715: {
      lat: 36.55323,
      lng: -83.95894,
      city: 'Clairfield',
      state: 'TN',
    },
    37716: {
      lat: 36.09919,
      lng: -84.16822,
      city: 'Clinton',
      state: 'TN',
    },
    37719: {
      lat: 36.02196,
      lng: -84.42815,
      city: 'Coalfield',
      state: 'TN',
    },
    37721: {
      lat: 36.13155,
      lng: -83.80829,
      city: 'Corryton',
      state: 'TN',
    },
    37722: {
      lat: 35.80611,
      lng: -83.23339,
      city: 'Cosby',
      state: 'TN',
    },
    37723: {
      lat: 35.96044,
      lng: -84.82171,
      city: 'Crab Orchard',
      state: 'TN',
    },
    37724: {
      lat: 36.54457,
      lng: -83.69328,
      city: 'Cumberland Gap',
      state: 'TN',
    },
    37725: {
      lat: 35.99778,
      lng: -83.39678,
      city: 'Dandridge',
      state: 'TN',
    },
    37726: {
      lat: 36.20464,
      lng: -84.8386,
      city: 'Deer Lodge',
      state: 'TN',
    },
    37727: {
      lat: 35.88885,
      lng: -83.00651,
      city: 'Del Rio',
      state: 'TN',
    },
    37729: { lat: 36.51412, lng: -84.03276, city: 'Duff', state: 'TN' },
    37730: {
      lat: 36.56837,
      lng: -83.97357,
      city: 'Eagan',
      state: 'TN',
    },
    37731: {
      lat: 36.54383,
      lng: -83.00873,
      city: 'Eidson',
      state: 'TN',
    },
    37732: { lat: 36.32792, lng: -84.6282, city: 'Elgin', state: 'TN' },
    37733: {
      lat: 36.35676,
      lng: -84.69497,
      city: 'Rugby',
      state: 'TN',
    },
    37737: {
      lat: 35.75778,
      lng: -84.11913,
      city: 'Friendsville',
      state: 'TN',
    },
    37738: {
      lat: 35.67125,
      lng: -83.47641,
      city: 'Gatlinburg',
      state: 'TN',
    },
    37742: {
      lat: 35.67548,
      lng: -84.18049,
      city: 'Greenback',
      state: 'TN',
    },
    37743: {
      lat: 36.06663,
      lng: -82.85659,
      city: 'Greeneville',
      state: 'TN',
    },
    37745: {
      lat: 36.2784,
      lng: -82.82631,
      city: 'Greeneville',
      state: 'TN',
    },
    37748: {
      lat: 35.94797,
      lng: -84.51413,
      city: 'Harriman',
      state: 'TN',
    },
    37752: {
      lat: 36.56735,
      lng: -83.55422,
      city: 'Harrogate',
      state: 'TN',
    },
    37753: {
      lat: 35.82276,
      lng: -83.098,
      city: 'Hartford',
      state: 'TN',
    },
    37754: {
      lat: 36.13059,
      lng: -84.03305,
      city: 'Heiskell',
      state: 'TN',
    },
    37755: {
      lat: 36.42249,
      lng: -84.53166,
      city: 'Helenwood',
      state: 'TN',
    },
    37756: {
      lat: 36.30165,
      lng: -84.40704,
      city: 'Huntsville',
      state: 'TN',
    },
    37757: {
      lat: 36.30182,
      lng: -84.14371,
      city: 'Jacksboro',
      state: 'TN',
    },
    37760: {
      lat: 36.10773,
      lng: -83.46443,
      city: 'Jefferson City',
      state: 'TN',
    },
    37762: {
      lat: 36.57657,
      lng: -84.12323,
      city: 'Jellico',
      state: 'TN',
    },
    37763: {
      lat: 35.82344,
      lng: -84.49356,
      city: 'Kingston',
      state: 'TN',
    },
    37764: {
      lat: 35.97074,
      lng: -83.61585,
      city: 'Kodak',
      state: 'TN',
    },
    37765: {
      lat: 36.57219,
      lng: -83.06534,
      city: 'Kyles Ford',
      state: 'TN',
    },
    37766: {
      lat: 36.39958,
      lng: -84.07275,
      city: 'La Follette',
      state: 'TN',
    },
    37769: {
      lat: 36.21799,
      lng: -84.15532,
      city: 'Rocky Top',
      state: 'TN',
    },
    37770: {
      lat: 36.15239,
      lng: -84.6418,
      city: 'Lancing',
      state: 'TN',
    },
    37771: {
      lat: 35.8382,
      lng: -84.31748,
      city: 'Lenoir City',
      state: 'TN',
    },
    37772: {
      lat: 35.78775,
      lng: -84.21876,
      city: 'Lenoir City',
      state: 'TN',
    },
    37774: {
      lat: 35.7317,
      lng: -84.35526,
      city: 'Loudon',
      state: 'TN',
    },
    37777: {
      lat: 35.82742,
      lng: -84.05186,
      city: 'Louisville',
      state: 'TN',
    },
    37779: {
      lat: 36.21093,
      lng: -83.75793,
      city: 'Luttrell',
      state: 'TN',
    },
    37801: {
      lat: 35.67134,
      lng: -84.08453,
      city: 'Maryville',
      state: 'TN',
    },
    37803: {
      lat: 35.67115,
      lng: -83.97761,
      city: 'Maryville',
      state: 'TN',
    },
    37804: {
      lat: 35.79228,
      lng: -83.89311,
      city: 'Maryville',
      state: 'TN',
    },
    37806: {
      lat: 36.08645,
      lng: -83.72368,
      city: 'Mascot',
      state: 'TN',
    },
    37807: {
      lat: 36.25955,
      lng: -83.82702,
      city: 'Maynardville',
      state: 'TN',
    },
    37809: {
      lat: 36.15431,
      lng: -83.03611,
      city: 'Midway',
      state: 'TN',
    },
    37810: {
      lat: 36.17984,
      lng: -83.10296,
      city: 'Mohawk',
      state: 'TN',
    },
    37811: {
      lat: 36.35558,
      lng: -83.2144,
      city: 'Mooresburg',
      state: 'TN',
    },
    37813: {
      lat: 36.17644,
      lng: -83.26542,
      city: 'Morristown',
      state: 'TN',
    },
    37814: {
      lat: 36.23604,
      lng: -83.3317,
      city: 'Morristown',
      state: 'TN',
    },
    37818: {
      lat: 36.18581,
      lng: -82.97233,
      city: 'Mosheim',
      state: 'TN',
    },
    37819: {
      lat: 36.55649,
      lng: -84.21169,
      city: 'Newcomb',
      state: 'TN',
    },
    37820: {
      lat: 36.08364,
      lng: -83.56715,
      city: 'New Market',
      state: 'TN',
    },
    37821: {
      lat: 35.97688,
      lng: -83.19506,
      city: 'Newport',
      state: 'TN',
    },
    37825: {
      lat: 36.41221,
      lng: -83.68196,
      city: 'New Tazewell',
      state: 'TN',
    },
    37826: {
      lat: 35.55825,
      lng: -84.58984,
      city: 'Niota',
      state: 'TN',
    },
    37828: {
      lat: 36.19788,
      lng: -84.06889,
      city: 'Norris',
      state: 'TN',
    },
    37829: {
      lat: 36.00094,
      lng: -84.61331,
      city: 'Oakdale',
      state: 'TN',
    },
    37830: {
      lat: 35.96431,
      lng: -84.29221,
      city: 'Oak Ridge',
      state: 'TN',
    },
    37840: {
      lat: 36.05849,
      lng: -84.37047,
      city: 'Oliver Springs',
      state: 'TN',
    },
    37841: {
      lat: 36.5088,
      lng: -84.54665,
      city: 'Oneida',
      state: 'TN',
    },
    37843: {
      lat: 36.01137,
      lng: -83.04979,
      city: 'Parrottsville',
      state: 'TN',
    },
    37845: {
      lat: 36.10369,
      lng: -84.45022,
      city: 'Petros',
      state: 'TN',
    },
    37846: {
      lat: 35.68008,
      lng: -84.48505,
      city: 'Philadelphia',
      state: 'TN',
    },
    37847: {
      lat: 36.43156,
      lng: -84.27346,
      city: 'Pioneer',
      state: 'TN',
    },
    37848: {
      lat: 36.23701,
      lng: -83.68179,
      city: 'Powder Springs',
      state: 'TN',
    },
    37849: {
      lat: 36.0578,
      lng: -84.04476,
      city: 'Powell',
      state: 'TN',
    },
    37851: {
      lat: 36.56558,
      lng: -83.79244,
      city: 'Pruden',
      state: 'TN',
    },
    37852: {
      lat: 36.32365,
      lng: -84.57454,
      city: 'Robbins',
      state: 'TN',
    },
    37853: {
      lat: 35.83723,
      lng: -83.90854,
      city: 'Rockford',
      state: 'TN',
    },
    37854: {
      lat: 35.88004,
      lng: -84.71454,
      city: 'Rockwood',
      state: 'TN',
    },
    37857: {
      lat: 36.42145,
      lng: -82.94269,
      city: 'Rogersville',
      state: 'TN',
    },
    37860: {
      lat: 36.24492,
      lng: -83.18441,
      city: 'Russellville',
      state: 'TN',
    },
    37861: {
      lat: 36.24516,
      lng: -83.51421,
      city: 'Rutledge',
      state: 'TN',
    },
    37862: {
      lat: 35.78314,
      lng: -83.61232,
      city: 'Sevierville',
      state: 'TN',
    },
    37863: {
      lat: 35.7864,
      lng: -83.56367,
      city: 'Pigeon Forge',
      state: 'TN',
    },
    37865: {
      lat: 35.84609,
      lng: -83.73974,
      city: 'Seymour',
      state: 'TN',
    },
    37866: {
      lat: 36.35017,
      lng: -83.8517,
      city: 'Sharps Chapel',
      state: 'TN',
    },
    37869: {
      lat: 36.51555,
      lng: -83.24579,
      city: 'Sneedville',
      state: 'TN',
    },
    37870: {
      lat: 36.46319,
      lng: -83.83562,
      city: 'Speedwell',
      state: 'TN',
    },
    37871: {
      lat: 36.04693,
      lng: -83.67932,
      city: 'Strawberry Plains',
      state: 'TN',
    },
    37872: {
      lat: 36.26869,
      lng: -84.68119,
      city: 'Sunbright',
      state: 'TN',
    },
    37873: {
      lat: 36.52446,
      lng: -82.84754,
      city: 'Surgoinsville',
      state: 'TN',
    },
    37874: {
      lat: 35.59749,
      lng: -84.45823,
      city: 'Sweetwater',
      state: 'TN',
    },
    37876: {
      lat: 35.8645,
      lng: -83.47876,
      city: 'Sevierville',
      state: 'TN',
    },
    37877: {
      lat: 36.15176,
      lng: -83.40503,
      city: 'Talbott',
      state: 'TN',
    },
    37878: {
      lat: 35.59406,
      lng: -83.98358,
      city: 'Tallassee',
      state: 'TN',
    },
    37879: {
      lat: 36.48403,
      lng: -83.48705,
      city: 'Tazewell',
      state: 'TN',
    },
    37880: {
      lat: 35.7012,
      lng: -84.66453,
      city: 'Ten Mile',
      state: 'TN',
    },
    37881: {
      lat: 36.39861,
      lng: -83.34393,
      city: 'Thorn Hill',
      state: 'TN',
    },
    37882: {
      lat: 35.60335,
      lng: -83.82118,
      city: 'Townsend',
      state: 'TN',
    },
    37885: {
      lat: 35.50392,
      lng: -84.12539,
      city: 'Vonore',
      state: 'TN',
    },
    37886: {
      lat: 35.74142,
      lng: -83.80747,
      city: 'Walland',
      state: 'TN',
    },
    37887: {
      lat: 36.0772,
      lng: -84.61573,
      city: 'Wartburg',
      state: 'TN',
    },
    37888: {
      lat: 36.31063,
      lng: -83.61266,
      city: 'Washburn',
      state: 'TN',
    },
    37890: {
      lat: 36.08144,
      lng: -83.29299,
      city: 'White Pine',
      state: 'TN',
    },
    37891: {
      lat: 36.29172,
      lng: -83.13992,
      city: 'Whitesburg',
      state: 'TN',
    },
    37892: {
      lat: 36.55666,
      lng: -84.37132,
      city: 'Winfield',
      state: 'TN',
    },
    37902: {
      lat: 35.96203,
      lng: -83.92039,
      city: 'Knoxville',
      state: 'TN',
    },
    37909: {
      lat: 35.94716,
      lng: -84.02275,
      city: 'Knoxville',
      state: 'TN',
    },
    37912: {
      lat: 36.00849,
      lng: -83.98465,
      city: 'Knoxville',
      state: 'TN',
    },
    37914: {
      lat: 35.97988,
      lng: -83.79937,
      city: 'Knoxville',
      state: 'TN',
    },
    37915: {
      lat: 35.97057,
      lng: -83.90002,
      city: 'Knoxville',
      state: 'TN',
    },
    37916: {
      lat: 35.95309,
      lng: -83.9335,
      city: 'Knoxville',
      state: 'TN',
    },
    37917: {
      lat: 36.00156,
      lng: -83.91265,
      city: 'Knoxville',
      state: 'TN',
    },
    37918: {
      lat: 36.0599,
      lng: -83.91082,
      city: 'Knoxville',
      state: 'TN',
    },
    37919: {
      lat: 35.91429,
      lng: -84.00106,
      city: 'Knoxville',
      state: 'TN',
    },
    37920: {
      lat: 35.91084,
      lng: -83.85909,
      city: 'Knoxville',
      state: 'TN',
    },
    37921: {
      lat: 35.97877,
      lng: -84.00028,
      city: 'Knoxville',
      state: 'TN',
    },
    37922: {
      lat: 35.86124,
      lng: -84.10127,
      city: 'Knoxville',
      state: 'TN',
    },
    37923: {
      lat: 35.92697,
      lng: -84.08076,
      city: 'Knoxville',
      state: 'TN',
    },
    37924: {
      lat: 36.03554,
      lng: -83.80211,
      city: 'Knoxville',
      state: 'TN',
    },
    37929: {
      lat: 35.96241,
      lng: -83.91597,
      city: 'Knoxville',
      state: 'TN',
    },
    37931: {
      lat: 35.97611,
      lng: -84.12496,
      city: 'Knoxville',
      state: 'TN',
    },
    37932: {
      lat: 35.92273,
      lng: -84.19562,
      city: 'Knoxville',
      state: 'TN',
    },
    37934: {
      lat: 35.87338,
      lng: -84.17882,
      city: 'Farragut',
      state: 'TN',
    },
    37938: {
      lat: 36.12499,
      lng: -83.93815,
      city: 'Knoxville',
      state: 'TN',
    },
    38001: {
      lat: 35.81323,
      lng: -89.15505,
      city: 'Alamo',
      state: 'TN',
    },
    38002: {
      lat: 35.28854,
      lng: -89.70926,
      city: 'Arlington',
      state: 'TN',
    },
    38004: {
      lat: 35.41909,
      lng: -89.76687,
      city: 'Atoka',
      state: 'TN',
    },
    38006: {
      lat: 35.69507,
      lng: -89.09761,
      city: 'Bells',
      state: 'TN',
    },
    38007: {
      lat: 36.16422,
      lng: -89.43925,
      city: 'Bogota',
      state: 'TN',
    },
    38008: {
      lat: 35.24455,
      lng: -88.99879,
      city: 'Bolivar',
      state: 'TN',
    },
    38011: {
      lat: 35.47266,
      lng: -89.71415,
      city: 'Brighton',
      state: 'TN',
    },
    38012: {
      lat: 35.60959,
      lng: -89.27085,
      city: 'Brownsville',
      state: 'TN',
    },
    38015: {
      lat: 35.55948,
      lng: -89.82436,
      city: 'Burlison',
      state: 'TN',
    },
    38016: {
      lat: 35.17956,
      lng: -89.76046,
      city: 'Cordova',
      state: 'TN',
    },
    38017: {
      lat: 35.06722,
      lng: -89.65347,
      city: 'Collierville',
      state: 'TN',
    },
    38018: {
      lat: 35.13781,
      lng: -89.76647,
      city: 'Cordova',
      state: 'TN',
    },
    38019: {
      lat: 35.56114,
      lng: -89.6304,
      city: 'Covington',
      state: 'TN',
    },
    38021: {
      lat: 35.87042,
      lng: -89.18411,
      city: 'Crockett Mills',
      state: 'TN',
    },
    38023: {
      lat: 35.47468,
      lng: -89.95114,
      city: 'Drummonds',
      state: 'TN',
    },
    38024: {
      lat: 36.03743,
      lng: -89.38511,
      city: 'Dyersburg',
      state: 'TN',
    },
    38028: { lat: 35.18486, lng: -89.63474, city: 'Eads', state: 'TN' },
    38029: {
      lat: 35.24205,
      lng: -89.82351,
      city: 'Ellendale',
      state: 'TN',
    },
    38030: {
      lat: 35.99942,
      lng: -89.59981,
      city: 'Finley',
      state: 'TN',
    },
    38034: {
      lat: 35.91273,
      lng: -89.236,
      city: 'Friendship',
      state: 'TN',
    },
    38036: {
      lat: 35.32715,
      lng: -89.61941,
      city: 'Gallaway',
      state: 'TN',
    },
    38037: {
      lat: 35.79433,
      lng: -89.39232,
      city: 'Gates',
      state: 'TN',
    },
    38039: {
      lat: 35.0571,
      lng: -89.18545,
      city: 'Grand Junction',
      state: 'TN',
    },
    38040: {
      lat: 35.88185,
      lng: -89.48065,
      city: 'Halls',
      state: 'TN',
    },
    38041: {
      lat: 35.64397,
      lng: -89.70204,
      city: 'Henning',
      state: 'TN',
    },
    38042: {
      lat: 35.15059,
      lng: -89.13848,
      city: 'Hickory Valley',
      state: 'TN',
    },
    38044: {
      lat: 35.20736,
      lng: -88.80244,
      city: 'Hornsby',
      state: 'TN',
    },
    38046: {
      lat: 35.04598,
      lng: -89.24408,
      city: 'La Grange',
      state: 'TN',
    },
    38047: {
      lat: 36.06499,
      lng: -89.55569,
      city: 'Lenox',
      state: 'TN',
    },
    38049: { lat: 35.38689, lng: -89.544, city: 'Mason', state: 'TN' },
    38050: {
      lat: 35.81635,
      lng: -89.22712,
      city: 'Maury City',
      state: 'TN',
    },
    38052: {
      lat: 35.08574,
      lng: -88.91308,
      city: 'Middleton',
      state: 'TN',
    },
    38053: {
      lat: 35.33583,
      lng: -89.95,
      city: 'Millington',
      state: 'TN',
    },
    38054: {
      lat: 35.3358,
      lng: -89.8744,
      city: 'Millington',
      state: 'TN',
    },
    38057: {
      lat: 35.05381,
      lng: -89.36772,
      city: 'Moscow',
      state: 'TN',
    },
    38058: {
      lat: 35.45861,
      lng: -89.81933,
      city: 'Munford',
      state: 'TN',
    },
    38059: {
      lat: 36.11132,
      lng: -89.24113,
      city: 'Newbern',
      state: 'TN',
    },
    38060: {
      lat: 35.21214,
      lng: -89.50468,
      city: 'Oakland',
      state: 'TN',
    },
    38061: {
      lat: 35.06704,
      lng: -88.7647,
      city: 'Pocahontas',
      state: 'TN',
    },
    38063: {
      lat: 35.74598,
      lng: -89.63412,
      city: 'Ripley',
      state: 'TN',
    },
    38066: {
      lat: 35.07421,
      lng: -89.53944,
      city: 'Rossville',
      state: 'TN',
    },
    38067: {
      lat: 35.08561,
      lng: -89.04804,
      city: 'Saulsbury',
      state: 'TN',
    },
    38068: {
      lat: 35.25972,
      lng: -89.3349,
      city: 'Somerville',
      state: 'TN',
    },
    38069: {
      lat: 35.46209,
      lng: -89.37151,
      city: 'Stanton',
      state: 'TN',
    },
    38070: {
      lat: 35.94045,
      lng: -89.24915,
      city: 'Tigrett',
      state: 'TN',
    },
    38075: {
      lat: 35.37197,
      lng: -89.15569,
      city: 'Whiteville',
      state: 'TN',
    },
    38076: {
      lat: 35.12301,
      lng: -89.45633,
      city: 'Williston',
      state: 'TN',
    },
    38077: {
      lat: 36.32852,
      lng: -89.4744,
      city: 'Wynnburg',
      state: 'TN',
    },
    38079: {
      lat: 36.41378,
      lng: -89.47032,
      city: 'Tiptonville',
      state: 'TN',
    },
    38080: {
      lat: 36.20295,
      lng: -89.52142,
      city: 'Ridgely',
      state: 'TN',
    },
    38103: {
      lat: 35.15308,
      lng: -90.05518,
      city: 'Memphis',
      state: 'TN',
    },
    38104: {
      lat: 35.13253,
      lng: -90.00435,
      city: 'Memphis',
      state: 'TN',
    },
    38105: {
      lat: 35.1512,
      lng: -90.03541,
      city: 'Memphis',
      state: 'TN',
    },
    38106: {
      lat: 35.10067,
      lng: -90.0375,
      city: 'Memphis',
      state: 'TN',
    },
    38107: {
      lat: 35.17054,
      lng: -90.02397,
      city: 'Memphis',
      state: 'TN',
    },
    38108: {
      lat: 35.1763,
      lng: -89.96382,
      city: 'Memphis',
      state: 'TN',
    },
    38109: {
      lat: 35.0341,
      lng: -90.13533,
      city: 'Memphis',
      state: 'TN',
    },
    38111: {
      lat: 35.11014,
      lng: -89.94429,
      city: 'Memphis',
      state: 'TN',
    },
    38112: {
      lat: 35.14791,
      lng: -89.97661,
      city: 'Memphis',
      state: 'TN',
    },
    38113: {
      lat: 35.10198,
      lng: -90.13285,
      city: 'Memphis',
      state: 'TN',
    },
    38114: {
      lat: 35.09648,
      lng: -89.98596,
      city: 'Memphis',
      state: 'TN',
    },
    38115: {
      lat: 35.05499,
      lng: -89.86362,
      city: 'Memphis',
      state: 'TN',
    },
    38116: {
      lat: 35.03213,
      lng: -90.01001,
      city: 'Memphis',
      state: 'TN',
    },
    38117: {
      lat: 35.11504,
      lng: -89.90551,
      city: 'Memphis',
      state: 'TN',
    },
    38118: {
      lat: 35.03655,
      lng: -89.93021,
      city: 'Memphis',
      state: 'TN',
    },
    38119: {
      lat: 35.07986,
      lng: -89.84593,
      city: 'Memphis',
      state: 'TN',
    },
    38120: {
      lat: 35.12344,
      lng: -89.85237,
      city: 'Memphis',
      state: 'TN',
    },
    38122: {
      lat: 35.1571,
      lng: -89.92247,
      city: 'Memphis',
      state: 'TN',
    },
    38125: {
      lat: 35.02537,
      lng: -89.78936,
      city: 'Memphis',
      state: 'TN',
    },
    38126: {
      lat: 35.12695,
      lng: -90.04378,
      city: 'Memphis',
      state: 'TN',
    },
    38127: {
      lat: 35.23795,
      lng: -90.01753,
      city: 'Memphis',
      state: 'TN',
    },
    38128: {
      lat: 35.22623,
      lng: -89.92612,
      city: 'Memphis',
      state: 'TN',
    },
    38131: {
      lat: 35.06603,
      lng: -89.99266,
      city: 'Memphis',
      state: 'TN',
    },
    38132: {
      lat: 35.07069,
      lng: -90.00033,
      city: 'Memphis',
      state: 'TN',
    },
    38133: {
      lat: 35.21292,
      lng: -89.79474,
      city: 'Memphis',
      state: 'TN',
    },
    38134: {
      lat: 35.17674,
      lng: -89.85992,
      city: 'Memphis',
      state: 'TN',
    },
    38135: {
      lat: 35.23876,
      lng: -89.8497,
      city: 'Memphis',
      state: 'TN',
    },
    38138: {
      lat: 35.08477,
      lng: -89.7993,
      city: 'Germantown',
      state: 'TN',
    },
    38139: {
      lat: 35.08615,
      lng: -89.75581,
      city: 'Germantown',
      state: 'TN',
    },
    38141: {
      lat: 35.01599,
      lng: -89.85472,
      city: 'Memphis',
      state: 'TN',
    },
    38152: {
      lat: 35.11843,
      lng: -89.93743,
      city: 'Memphis',
      state: 'TN',
    },
    38201: {
      lat: 36.12335,
      lng: -88.53131,
      city: 'McKenzie',
      state: 'TN',
    },
    38220: { lat: 35.98836, lng: -88.673, city: 'Atwood', state: 'TN' },
    38221: {
      lat: 36.24552,
      lng: -88.03685,
      city: 'Big Sandy',
      state: 'TN',
    },
    38222: {
      lat: 36.44512,
      lng: -88.16288,
      city: 'Buchanan',
      state: 'TN',
    },
    38224: {
      lat: 36.39172,
      lng: -88.48774,
      city: 'Cottage Grove',
      state: 'TN',
    },
    38225: {
      lat: 36.31764,
      lng: -88.67937,
      city: 'Dresden',
      state: 'TN',
    },
    38226: {
      lat: 36.47722,
      lng: -88.66382,
      city: 'Dukedom',
      state: 'TN',
    },
    38229: {
      lat: 36.22108,
      lng: -88.60939,
      city: 'Gleason',
      state: 'TN',
    },
    38230: {
      lat: 36.15592,
      lng: -88.77154,
      city: 'Greenfield',
      state: 'TN',
    },
    38231: {
      lat: 36.21277,
      lng: -88.42442,
      city: 'Henry',
      state: 'TN',
    },
    38232: {
      lat: 36.34828,
      lng: -89.32295,
      city: 'Hornbeak',
      state: 'TN',
    },
    38233: {
      lat: 36.20411,
      lng: -89.04622,
      city: 'Kenton',
      state: 'TN',
    },
    38235: {
      lat: 35.99116,
      lng: -88.58109,
      city: 'McLemoresville',
      state: 'TN',
    },
    38236: {
      lat: 36.17572,
      lng: -88.26463,
      city: 'Mansfield',
      state: 'TN',
    },
    38237: {
      lat: 36.36406,
      lng: -88.83162,
      city: 'Martin',
      state: 'TN',
    },
    38240: {
      lat: 36.25091,
      lng: -89.32045,
      city: 'Obion',
      state: 'TN',
    },
    38241: {
      lat: 36.42852,
      lng: -88.58964,
      city: 'Palmersville',
      state: 'TN',
    },
    38242: { lat: 36.3076, lng: -88.33244, city: 'Paris', state: 'TN' },
    38251: {
      lat: 36.44636,
      lng: -88.3539,
      city: 'Puryear',
      state: 'TN',
    },
    38253: {
      lat: 36.29821,
      lng: -89.05822,
      city: 'Rives',
      state: 'TN',
    },
    38254: {
      lat: 36.37444,
      lng: -89.34662,
      city: 'Samburg',
      state: 'TN',
    },
    38255: {
      lat: 36.23954,
      lng: -88.86483,
      city: 'Sharon',
      state: 'TN',
    },
    38256: {
      lat: 36.31388,
      lng: -88.12539,
      city: 'Springville',
      state: 'TN',
    },
    38257: {
      lat: 36.46386,
      lng: -88.88397,
      city: 'South Fulton',
      state: 'TN',
    },
    38258: {
      lat: 36.02094,
      lng: -88.61188,
      city: 'Trezevant',
      state: 'TN',
    },
    38259: {
      lat: 36.20204,
      lng: -89.18439,
      city: 'Trimble',
      state: 'TN',
    },
    38260: { lat: 36.36961, lng: -89.21128, city: 'Troy', state: 'TN' },
    38261: {
      lat: 36.43214,
      lng: -89.11583,
      city: 'Union City',
      state: 'TN',
    },
    38301: {
      lat: 35.5908,
      lng: -88.8531,
      city: 'Jackson',
      state: 'TN',
    },
    38305: {
      lat: 35.70108,
      lng: -88.76779,
      city: 'Jackson',
      state: 'TN',
    },
    38310: {
      lat: 35.26175,
      lng: -88.39832,
      city: 'Adamsville',
      state: 'TN',
    },
    38311: {
      lat: 35.43842,
      lng: -88.09737,
      city: 'Bath Springs',
      state: 'TN',
    },
    38313: {
      lat: 35.60107,
      lng: -88.62541,
      city: 'Beech Bluff',
      state: 'TN',
    },
    38315: {
      lat: 35.26918,
      lng: -88.6241,
      city: 'Bethel Springs',
      state: 'TN',
    },
    38316: {
      lat: 36.06873,
      lng: -88.81547,
      city: 'Bradford',
      state: 'TN',
    },
    38317: {
      lat: 36.05425,
      lng: -88.26965,
      city: 'Bruceton',
      state: 'TN',
    },
    38318: {
      lat: 35.95687,
      lng: -88.26189,
      city: 'Buena Vista',
      state: 'TN',
    },
    38320: {
      lat: 36.04764,
      lng: -88.1095,
      city: 'Camden',
      state: 'TN',
    },
    38321: {
      lat: 35.83341,
      lng: -88.53013,
      city: 'Cedar Grove',
      state: 'TN',
    },
    38326: {
      lat: 35.04419,
      lng: -88.27514,
      city: 'Counce',
      state: 'TN',
    },
    38327: {
      lat: 35.22348,
      lng: -88.30085,
      city: 'Crump',
      state: 'TN',
    },
    38328: {
      lat: 35.67601,
      lng: -88.21222,
      city: 'Darden',
      state: 'TN',
    },
    38329: {
      lat: 35.54541,
      lng: -88.11283,
      city: 'Decaturville',
      state: 'TN',
    },
    38330: { lat: 36.0675, lng: -89.03316, city: 'Dyer', state: 'TN' },
    38332: {
      lat: 35.41978,
      lng: -88.42934,
      city: 'Enville',
      state: 'TN',
    },
    38333: { lat: 36.12236, lng: -87.9709, city: 'Eva', state: 'TN' },
    38334: {
      lat: 35.35342,
      lng: -88.58977,
      city: 'Finger',
      state: 'TN',
    },
    38337: {
      lat: 35.78515,
      lng: -89.01149,
      city: 'Gadsden',
      state: 'TN',
    },
    38338: {
      lat: 35.87483,
      lng: -88.84863,
      city: 'Gibson',
      state: 'TN',
    },
    38339: { lat: 35.0524, lng: -88.5147, city: 'Guys', state: 'TN' },
    38340: {
      lat: 35.40021,
      lng: -88.68226,
      city: 'Henderson',
      state: 'TN',
    },
    38341: {
      lat: 35.87677,
      lng: -88.08888,
      city: 'Holladay',
      state: 'TN',
    },
    38342: {
      lat: 36.10766,
      lng: -88.29024,
      city: 'Hollow Rock',
      state: 'TN',
    },
    38343: {
      lat: 35.82727,
      lng: -88.92819,
      city: 'Humboldt',
      state: 'TN',
    },
    38344: {
      lat: 35.97716,
      lng: -88.43066,
      city: 'Huntingdon',
      state: 'TN',
    },
    38345: {
      lat: 35.59109,
      lng: -88.51163,
      city: 'Huron',
      state: 'TN',
    },
    38347: {
      lat: 35.47632,
      lng: -88.49846,
      city: 'Jacks Creek',
      state: 'TN',
    },
    38348: {
      lat: 35.86893,
      lng: -88.65198,
      city: 'Lavinia',
      state: 'TN',
    },
    38351: {
      lat: 35.66324,
      lng: -88.40401,
      city: 'Lexington',
      state: 'TN',
    },
    38352: {
      lat: 35.53037,
      lng: -88.53308,
      city: 'Luray',
      state: 'TN',
    },
    38355: {
      lat: 35.78637,
      lng: -88.76942,
      city: 'Medina',
      state: 'TN',
    },
    38356: {
      lat: 35.43877,
      lng: -88.89029,
      city: 'Medon',
      state: 'TN',
    },
    38357: {
      lat: 35.04888,
      lng: -88.4112,
      city: 'Michie',
      state: 'TN',
    },
    38358: {
      lat: 35.91661,
      lng: -88.75934,
      city: 'Milan',
      state: 'TN',
    },
    38359: {
      lat: 35.37222,
      lng: -88.35109,
      city: 'Milledgeville',
      state: 'TN',
    },
    38361: {
      lat: 35.31897,
      lng: -88.29232,
      city: 'Morris Chapel',
      state: 'TN',
    },
    38362: {
      lat: 35.72696,
      lng: -88.78508,
      city: 'Oakfield',
      state: 'TN',
    },
    38363: {
      lat: 35.68625,
      lng: -88.11051,
      city: 'Parsons',
      state: 'TN',
    },
    38365: {
      lat: 35.05383,
      lng: -88.23627,
      city: 'Pickwick Dam',
      state: 'TN',
    },
    38366: {
      lat: 35.47797,
      lng: -88.74461,
      city: 'Pinson',
      state: 'TN',
    },
    38367: {
      lat: 35.05266,
      lng: -88.62728,
      city: 'Ramer',
      state: 'TN',
    },
    38368: {
      lat: 35.50191,
      lng: -88.35659,
      city: 'Reagan',
      state: 'TN',
    },
    38369: {
      lat: 36.13253,
      lng: -88.9652,
      city: 'Rutherford',
      state: 'TN',
    },
    38370: {
      lat: 35.38942,
      lng: -88.2465,
      city: 'Saltillo',
      state: 'TN',
    },
    38371: {
      lat: 35.42346,
      lng: -88.2967,
      city: 'Sardis',
      state: 'TN',
    },
    38372: {
      lat: 35.16516,
      lng: -88.14523,
      city: 'Savannah',
      state: 'TN',
    },
    38374: {
      lat: 35.51192,
      lng: -88.23469,
      city: 'Scotts Hill',
      state: 'TN',
    },
    38375: {
      lat: 35.16142,
      lng: -88.58811,
      city: 'Selmer',
      state: 'TN',
    },
    38376: {
      lat: 35.12974,
      lng: -88.34447,
      city: 'Shiloh',
      state: 'TN',
    },
    38379: {
      lat: 35.16388,
      lng: -88.42825,
      city: 'Stantonville',
      state: 'TN',
    },
    38380: {
      lat: 35.79114,
      lng: -88.03263,
      city: 'Sugar Tree',
      state: 'TN',
    },
    38381: {
      lat: 35.35144,
      lng: -88.96205,
      city: 'Toone',
      state: 'TN',
    },
    38382: {
      lat: 35.96925,
      lng: -88.99186,
      city: 'Trenton',
      state: 'TN',
    },
    38387: {
      lat: 35.86573,
      lng: -88.26968,
      city: 'Westport',
      state: 'TN',
    },
    38388: {
      lat: 35.77672,
      lng: -88.31535,
      city: 'Wildersville',
      state: 'TN',
    },
    38389: {
      lat: 36.10315,
      lng: -89.12134,
      city: 'Yorkville',
      state: 'TN',
    },
    38390: { lat: 35.83845, lng: -88.37797, city: 'Yuma', state: 'TN' },
    38391: {
      lat: 35.55026,
      lng: -88.99033,
      city: 'Denmark',
      state: 'TN',
    },
    38392: {
      lat: 35.45908,
      lng: -89.03709,
      city: 'Mercer',
      state: 'TN',
    },
    38401: {
      lat: 35.62955,
      lng: -87.02097,
      city: 'Columbia',
      state: 'TN',
    },
    38425: {
      lat: 35.40706,
      lng: -87.96588,
      city: 'Clifton',
      state: 'TN',
    },
    38449: {
      lat: 35.05231,
      lng: -86.82954,
      city: 'Ardmore',
      state: 'TN',
    },
    38450: {
      lat: 35.18523,
      lng: -87.80143,
      city: 'Collinwood',
      state: 'TN',
    },
    38451: {
      lat: 35.46706,
      lng: -86.99573,
      city: 'Culleoka',
      state: 'TN',
    },
    38452: {
      lat: 35.07898,
      lng: -87.79239,
      city: 'Cypress Inn',
      state: 'TN',
    },
    38453: {
      lat: 35.11923,
      lng: -86.81472,
      city: 'Dellrose',
      state: 'TN',
    },
    38454: {
      lat: 35.72334,
      lng: -87.35538,
      city: 'Duck River',
      state: 'TN',
    },
    38455: {
      lat: 35.06199,
      lng: -86.89366,
      city: 'Elkton',
      state: 'TN',
    },
    38456: {
      lat: 35.33464,
      lng: -87.26559,
      city: 'Ethridge',
      state: 'TN',
    },
    38457: {
      lat: 35.0297,
      lng: -87.28482,
      city: 'Five Points',
      state: 'TN',
    },
    38459: {
      lat: 35.19135,
      lng: -86.80219,
      city: 'Frankewing',
      state: 'TN',
    },
    38460: {
      lat: 35.10116,
      lng: -87.15937,
      city: 'Goodspring',
      state: 'TN',
    },
    38461: {
      lat: 35.59514,
      lng: -87.33987,
      city: 'Hampshire',
      state: 'TN',
    },
    38462: {
      lat: 35.53142,
      lng: -87.54869,
      city: 'Hohenwald',
      state: 'TN',
    },
    38463: {
      lat: 35.06802,
      lng: -87.64494,
      city: 'Iron City',
      state: 'TN',
    },
    38464: {
      lat: 35.29035,
      lng: -87.42845,
      city: 'Lawrenceburg',
      state: 'TN',
    },
    38468: {
      lat: 35.13083,
      lng: -87.29072,
      city: 'Leoma',
      state: 'TN',
    },
    38469: {
      lat: 35.0741,
      lng: -87.42765,
      city: 'Loretto',
      state: 'TN',
    },
    38471: { lat: 35.0771, lng: -87.92134, city: 'Lutts', state: 'TN' },
    38472: {
      lat: 35.3773,
      lng: -87.03873,
      city: 'Lynnville',
      state: 'TN',
    },
    38473: {
      lat: 35.02775,
      lng: -87.17057,
      city: 'Minor Hill',
      state: 'TN',
    },
    38474: {
      lat: 35.51848,
      lng: -87.22966,
      city: 'Mount Pleasant',
      state: 'TN',
    },
    38475: {
      lat: 35.2624,
      lng: -88.02336,
      city: 'Olivehill',
      state: 'TN',
    },
    38476: {
      lat: 35.84341,
      lng: -87.20984,
      city: 'Primm Springs',
      state: 'TN',
    },
    38477: {
      lat: 35.05075,
      lng: -87.00932,
      city: 'Prospect',
      state: 'TN',
    },
    38478: {
      lat: 35.21565,
      lng: -87.01329,
      city: 'Pulaski',
      state: 'TN',
    },
    38481: {
      lat: 35.02813,
      lng: -87.48863,
      city: 'Saint Joseph',
      state: 'TN',
    },
    38482: {
      lat: 35.76792,
      lng: -87.14574,
      city: 'Santa Fe',
      state: 'TN',
    },
    38483: {
      lat: 35.43411,
      lng: -87.33476,
      city: 'Summertown',
      state: 'TN',
    },
    38485: {
      lat: 35.33281,
      lng: -87.77659,
      city: 'Waynesboro',
      state: 'TN',
    },
    38486: {
      lat: 35.17306,
      lng: -87.54029,
      city: 'Westpoint',
      state: 'TN',
    },
    38487: {
      lat: 35.72163,
      lng: -87.23589,
      city: 'Williamsport',
      state: 'TN',
    },
    38488: { lat: 35.03906, lng: -86.68569, city: 'Taft', state: 'TN' },
    38501: {
      lat: 36.22682,
      lng: -85.53841,
      city: 'Cookeville',
      state: 'TN',
    },
    38503: {
      lat: 36.16333,
      lng: -85.50588,
      city: 'Cookeville',
      state: 'TN',
    },
    38504: {
      lat: 36.39314,
      lng: -84.73528,
      city: 'Allardt',
      state: 'TN',
    },
    38505: {
      lat: 36.17316,
      lng: -85.50559,
      city: 'Cookeville',
      state: 'TN',
    },
    38506: {
      lat: 36.18334,
      lng: -85.44246,
      city: 'Cookeville',
      state: 'TN',
    },
    38541: {
      lat: 36.52903,
      lng: -85.33767,
      city: 'Allons',
      state: 'TN',
    },
    38542: {
      lat: 36.31576,
      lng: -85.20113,
      city: 'Allred',
      state: 'TN',
    },
    38543: {
      lat: 36.37514,
      lng: -85.15931,
      city: 'Alpine',
      state: 'TN',
    },
    38544: {
      lat: 36.11605,
      lng: -85.66238,
      city: 'Baxter',
      state: 'TN',
    },
    38545: {
      lat: 36.23133,
      lng: -85.65964,
      city: 'Bloomington Springs',
      state: 'TN',
    },
    38547: {
      lat: 36.14954,
      lng: -86.00833,
      city: 'Brush Creek',
      state: 'TN',
    },
    38548: {
      lat: 36.17252,
      lng: -85.79197,
      city: 'Buffalo Valley',
      state: 'TN',
    },
    38549: {
      lat: 36.5617,
      lng: -85.15243,
      city: 'Byrdstown',
      state: 'TN',
    },
    38550: {
      lat: 35.76982,
      lng: -85.63268,
      city: 'Campaign',
      state: 'TN',
    },
    38551: {
      lat: 36.55804,
      lng: -85.48699,
      city: 'Celina',
      state: 'TN',
    },
    38552: {
      lat: 36.2166,
      lng: -85.81195,
      city: 'Chestnut Mound',
      state: 'TN',
    },
    38553: {
      lat: 36.20322,
      lng: -85.00442,
      city: 'Clarkrange',
      state: 'TN',
    },
    38554: {
      lat: 36.25131,
      lng: -85.16095,
      city: 'Crawford',
      state: 'TN',
    },
    38555: {
      lat: 35.88956,
      lng: -84.97537,
      city: 'Crossville',
      state: 'TN',
    },
    38556: {
      lat: 36.41016,
      lng: -84.9249,
      city: 'Jamestown',
      state: 'TN',
    },
    38558: {
      lat: 36.00532,
      lng: -84.87374,
      city: 'Crossville',
      state: 'TN',
    },
    38559: {
      lat: 35.80692,
      lng: -85.51334,
      city: 'Doyle',
      state: 'TN',
    },
    38560: {
      lat: 36.23458,
      lng: -85.8713,
      city: 'Elmwood',
      state: 'TN',
    },
    38562: {
      lat: 36.35173,
      lng: -85.65932,
      city: 'Gainesboro',
      state: 'TN',
    },
    38563: {
      lat: 36.19658,
      lng: -86.0147,
      city: 'Gordonsville',
      state: 'TN',
    },
    38564: {
      lat: 36.27144,
      lng: -85.75364,
      city: 'Granville',
      state: 'TN',
    },
    38565: {
      lat: 36.26296,
      lng: -85.00992,
      city: 'Grimsley',
      state: 'TN',
    },
    38567: {
      lat: 36.13392,
      lng: -85.91518,
      city: 'Hickman',
      state: 'TN',
    },
    38568: {
      lat: 36.43594,
      lng: -85.46907,
      city: 'Hilham',
      state: 'TN',
    },
    38569: {
      lat: 36.10887,
      lng: -85.85647,
      city: 'Lancaster',
      state: 'TN',
    },
    38570: {
      lat: 36.37678,
      lng: -85.31983,
      city: 'Livingston',
      state: 'TN',
    },
    38571: {
      lat: 36.0513,
      lng: -85.01374,
      city: 'Crossville',
      state: 'TN',
    },
    38572: {
      lat: 35.84764,
      lng: -85.12778,
      city: 'Crossville',
      state: 'TN',
    },
    38573: {
      lat: 36.48945,
      lng: -85.21931,
      city: 'Monroe',
      state: 'TN',
    },
    38574: {
      lat: 36.141,
      lng: -85.22498,
      city: 'Monterey',
      state: 'TN',
    },
    38575: { lat: 36.55848, lng: -85.63515, city: 'Moss', state: 'TN' },
    38577: {
      lat: 36.56453,
      lng: -84.94651,
      city: 'Pall Mall',
      state: 'TN',
    },
    38578: {
      lat: 35.98031,
      lng: -85.19886,
      city: 'Pleasant Hill',
      state: 'TN',
    },
    38579: {
      lat: 35.83218,
      lng: -85.54919,
      city: 'Quebeck',
      state: 'TN',
    },
    38580: {
      lat: 36.27729,
      lng: -85.29522,
      city: 'Rickman',
      state: 'TN',
    },
    38581: {
      lat: 35.73834,
      lng: -85.62141,
      city: 'Rock Island',
      state: 'TN',
    },
    38582: {
      lat: 36.09431,
      lng: -85.75382,
      city: 'Silver Point',
      state: 'TN',
    },
    38583: {
      lat: 35.94649,
      lng: -85.43781,
      city: 'Sparta',
      state: 'TN',
    },
    38585: {
      lat: 35.68995,
      lng: -85.42274,
      city: 'Spencer',
      state: 'TN',
    },
    38587: {
      lat: 35.8677,
      lng: -85.61723,
      city: 'Walling',
      state: 'TN',
    },
    38588: {
      lat: 36.45613,
      lng: -85.7189,
      city: 'Whitleyville',
      state: 'TN',
    },
    38589: {
      lat: 36.28551,
      lng: -85.07624,
      city: 'Wilder',
      state: 'TN',
    },
    38601: {
      lat: 34.48146,
      lng: -89.45166,
      city: 'Abbeville',
      state: 'MS',
    },
    38603: {
      lat: 34.83949,
      lng: -89.14163,
      city: 'Ashland',
      state: 'MS',
    },
    38606: {
      lat: 34.30375,
      lng: -89.9581,
      city: 'Batesville',
      state: 'MS',
    },
    38610: {
      lat: 34.65357,
      lng: -89.02637,
      city: 'Blue Mountain',
      state: 'MS',
    },
    38611: {
      lat: 34.85467,
      lng: -89.67116,
      city: 'Byhalia',
      state: 'MS',
    },
    38614: {
      lat: 34.15914,
      lng: -90.60747,
      city: 'Clarksdale',
      state: 'MS',
    },
    38617: {
      lat: 34.36261,
      lng: -90.49342,
      city: 'Coahoma',
      state: 'MS',
    },
    38618: {
      lat: 34.70203,
      lng: -89.93415,
      city: 'Coldwater',
      state: 'MS',
    },
    38619: { lat: 34.51288, lng: -89.82678, city: 'Como', state: 'MS' },
    38620: {
      lat: 34.23206,
      lng: -89.9156,
      city: 'Courtland',
      state: 'MS',
    },
    38621: {
      lat: 34.4528,
      lng: -90.1731,
      city: 'Crenshaw',
      state: 'MS',
    },
    38622: {
      lat: 34.16944,
      lng: -90.13448,
      city: 'Crowder',
      state: 'MS',
    },
    38623: {
      lat: 34.36131,
      lng: -90.29692,
      city: 'Darling',
      state: 'MS',
    },
    38625: { lat: 34.61866, lng: -88.8334, city: 'Dumas', state: 'MS' },
    38626: {
      lat: 34.5182,
      lng: -90.43966,
      city: 'Dundee',
      state: 'MS',
    },
    38627: { lat: 34.44503, lng: -89.24563, city: 'Etta', state: 'MS' },
    38629: {
      lat: 34.86914,
      lng: -88.99421,
      city: 'Falkner',
      state: 'MS',
    },
    38631: {
      lat: 34.35891,
      lng: -90.62765,
      city: 'Friars Point',
      state: 'MS',
    },
    38632: {
      lat: 34.79973,
      lng: -90.00629,
      city: 'Hernando',
      state: 'MS',
    },
    38633: {
      lat: 34.64206,
      lng: -89.19671,
      city: 'Hickory Flat',
      state: 'MS',
    },
    38635: {
      lat: 34.7677,
      lng: -89.47896,
      city: 'Holly Springs',
      state: 'MS',
    },
    38637: {
      lat: 34.95105,
      lng: -90.05004,
      city: 'Horn Lake',
      state: 'MS',
    },
    38639: {
      lat: 34.30909,
      lng: -90.4285,
      city: 'Jonestown',
      state: 'MS',
    },
    38641: {
      lat: 34.90616,
      lng: -90.1857,
      city: 'Lake Cormorant',
      state: 'MS',
    },
    38642: {
      lat: 34.91861,
      lng: -89.33901,
      city: 'Lamar',
      state: 'MS',
    },
    38643: {
      lat: 34.15082,
      lng: -90.26281,
      city: 'Lambert',
      state: 'MS',
    },
    38644: { lat: 34.45899, lng: -90.47576, city: 'Lula', state: 'MS' },
    38645: { lat: 34.2469, lng: -90.47663, city: 'Lyon', state: 'MS' },
    38646: {
      lat: 34.28141,
      lng: -90.29673,
      city: 'Marks',
      state: 'MS',
    },
    38647: {
      lat: 34.95851,
      lng: -89.21746,
      city: 'Michigan City',
      state: 'MS',
    },
    38650: {
      lat: 34.52776,
      lng: -89.14165,
      city: 'Myrtle',
      state: 'MS',
    },
    38651: {
      lat: 34.89172,
      lng: -90.00359,
      city: 'Nesbit',
      state: 'MS',
    },
    38652: {
      lat: 34.48545,
      lng: -88.99652,
      city: 'New Albany',
      state: 'MS',
    },
    38654: {
      lat: 34.93635,
      lng: -89.82409,
      city: 'Olive Branch',
      state: 'MS',
    },
    38655: {
      lat: 34.33114,
      lng: -89.4825,
      city: 'Oxford',
      state: 'MS',
    },
    38658: { lat: 34.19011, lng: -89.89906, city: 'Pope', state: 'MS' },
    38659: {
      lat: 34.62073,
      lng: -89.32065,
      city: 'Potts Camp',
      state: 'MS',
    },
    38661: {
      lat: 34.89009,
      lng: -89.55918,
      city: 'Red Banks',
      state: 'MS',
    },
    38663: {
      lat: 34.74111,
      lng: -88.89718,
      city: 'Ripley',
      state: 'MS',
    },
    38664: {
      lat: 34.80434,
      lng: -90.33217,
      city: 'Robinsonville',
      state: 'MS',
    },
    38665: {
      lat: 34.58165,
      lng: -90.16693,
      city: 'Sarah',
      state: 'MS',
    },
    38666: {
      lat: 34.42007,
      lng: -89.92708,
      city: 'Sardis',
      state: 'MS',
    },
    38668: {
      lat: 34.60109,
      lng: -89.92505,
      city: 'Senatobia',
      state: 'MS',
    },
    38670: {
      lat: 34.42815,
      lng: -90.28899,
      city: 'Sledge',
      state: 'MS',
    },
    38671: {
      lat: 34.96376,
      lng: -89.99567,
      city: 'Southaven',
      state: 'MS',
    },
    38672: {
      lat: 34.93727,
      lng: -89.94943,
      city: 'Southaven',
      state: 'MS',
    },
    38673: {
      lat: 34.29607,
      lng: -89.62485,
      city: 'Taylor',
      state: 'MS',
    },
    38674: {
      lat: 34.8718,
      lng: -88.89549,
      city: 'Tiplersville',
      state: 'MS',
    },
    38676: {
      lat: 34.67274,
      lng: -90.3682,
      city: 'Tunica',
      state: 'MS',
    },
    38677: {
      lat: 34.36402,
      lng: -89.53859,
      city: 'University',
      state: 'MS',
    },
    38680: {
      lat: 34.96152,
      lng: -90.14463,
      city: 'Walls',
      state: 'MS',
    },
    38683: {
      lat: 34.93504,
      lng: -88.85997,
      city: 'Walnut',
      state: 'MS',
    },
    38685: {
      lat: 34.5953,
      lng: -89.48827,
      city: 'Waterford',
      state: 'MS',
    },
    38701: {
      lat: 33.30621,
      lng: -91.03346,
      city: 'Greenville',
      state: 'MS',
    },
    38703: {
      lat: 33.47081,
      lng: -91.04775,
      city: 'Greenville',
      state: 'MS',
    },
    38720: {
      lat: 34.11907,
      lng: -90.78741,
      city: 'Alligator',
      state: 'MS',
    },
    38721: {
      lat: 32.96598,
      lng: -90.75876,
      city: 'Anguilla',
      state: 'MS',
    },
    38722: {
      lat: 33.26116,
      lng: -90.88304,
      city: 'Arcola',
      state: 'MS',
    },
    38723: { lat: 33.22967, lng: -91.03845, city: 'Avon', state: 'MS' },
    38725: {
      lat: 33.63834,
      lng: -91.05543,
      city: 'Benoit',
      state: 'MS',
    },
    38726: {
      lat: 33.75207,
      lng: -90.97664,
      city: 'Beulah',
      state: 'MS',
    },
    38730: { lat: 33.6883, lng: -90.79932, city: 'Boyle', state: 'MS' },
    38731: {
      lat: 33.11524,
      lng: -91.13829,
      city: 'Chatham',
      state: 'MS',
    },
    38732: {
      lat: 33.75556,
      lng: -90.72558,
      city: 'Cleveland',
      state: 'MS',
    },
    38733: {
      lat: 33.74463,
      lng: -90.73504,
      city: 'Cleveland',
      state: 'MS',
    },
    38736: {
      lat: 33.64389,
      lng: -90.54173,
      city: 'Doddsville',
      state: 'MS',
    },
    38737: { lat: 33.85683, lng: -90.4928, city: 'Drew', state: 'MS' },
    38738: {
      lat: 33.93049,
      lng: -90.53653,
      city: 'Parchman',
      state: 'MS',
    },
    38740: {
      lat: 34.04825,
      lng: -90.79039,
      city: 'Duncan',
      state: 'MS',
    },
    38744: {
      lat: 33.03066,
      lng: -91.05371,
      city: 'Glen Allan',
      state: 'MS',
    },
    38745: {
      lat: 32.98592,
      lng: -91.00499,
      city: 'Grace',
      state: 'MS',
    },
    38746: {
      lat: 33.95677,
      lng: -90.94112,
      city: 'Gunnison',
      state: 'MS',
    },
    38748: {
      lat: 33.15787,
      lng: -90.87964,
      city: 'Hollandale',
      state: 'MS',
    },
    38751: {
      lat: 33.45326,
      lng: -90.67469,
      city: 'Indianola',
      state: 'MS',
    },
    38753: {
      lat: 33.34845,
      lng: -90.58583,
      city: 'Inverness',
      state: 'MS',
    },
    38754: {
      lat: 33.24816,
      lng: -90.62066,
      city: 'Isola',
      state: 'MS',
    },
    38756: {
      lat: 33.39326,
      lng: -90.85827,
      city: 'Leland',
      state: 'MS',
    },
    38759: {
      lat: 33.82495,
      lng: -90.73501,
      city: 'Merigold',
      state: 'MS',
    },
    38760: {
      lat: 33.4535,
      lng: -90.99394,
      city: 'Metcalfe',
      state: 'MS',
    },
    38761: {
      lat: 33.42507,
      lng: -90.47658,
      city: 'Moorhead',
      state: 'MS',
    },
    38762: {
      lat: 33.8885,
      lng: -90.72681,
      city: 'Mound Bayou',
      state: 'MS',
    },
    38764: { lat: 33.79878, lng: -90.87965, city: 'Pace', state: 'MS' },
    38765: {
      lat: 33.0493,
      lng: -90.8896,
      city: 'Panther Burn',
      state: 'MS',
    },
    38767: {
      lat: 34.12809,
      lng: -90.80552,
      city: 'Rena Lara',
      state: 'MS',
    },
    38768: { lat: 33.96576, lng: -90.48036, city: 'Rome', state: 'MS' },
    38769: {
      lat: 33.84255,
      lng: -90.97678,
      city: 'Rosedale',
      state: 'MS',
    },
    38771: {
      lat: 33.73914,
      lng: -90.5243,
      city: 'Ruleville',
      state: 'MS',
    },
    38772: {
      lat: 33.56254,
      lng: -91.08769,
      city: 'Scott',
      state: 'MS',
    },
    38773: { lat: 33.60455, lng: -90.77388, city: 'Shaw', state: 'MS' },
    38774: {
      lat: 33.9659,
      lng: -90.76695,
      city: 'Shelby',
      state: 'MS',
    },
    38776: {
      lat: 33.42466,
      lng: -90.92752,
      city: 'Stoneville',
      state: 'MS',
    },
    38778: {
      lat: 33.54564,
      lng: -90.51832,
      city: 'Sunflower',
      state: 'MS',
    },
    38781: {
      lat: 33.90876,
      lng: -90.75684,
      city: 'Winstonville',
      state: 'MS',
    },
    38801: {
      lat: 34.22256,
      lng: -88.7762,
      city: 'Tupelo',
      state: 'MS',
    },
    38804: {
      lat: 34.28094,
      lng: -88.67246,
      city: 'Tupelo',
      state: 'MS',
    },
    38821: {
      lat: 33.97503,
      lng: -88.43918,
      city: 'Amory',
      state: 'MS',
    },
    38824: {
      lat: 34.52303,
      lng: -88.64334,
      city: 'Baldwyn',
      state: 'MS',
    },
    38826: {
      lat: 34.30164,
      lng: -88.84655,
      city: 'Belden',
      state: 'MS',
    },
    38827: { lat: 34.53, lng: -88.20244, city: 'Belmont', state: 'MS' },
    38828: {
      lat: 34.42365,
      lng: -88.86789,
      city: 'Blue Springs',
      state: 'MS',
    },
    38829: {
      lat: 34.65229,
      lng: -88.53639,
      city: 'Booneville',
      state: 'MS',
    },
    38833: {
      lat: 34.82259,
      lng: -88.34274,
      city: 'Burnsville',
      state: 'MS',
    },
    38834: {
      lat: 34.91627,
      lng: -88.56027,
      city: 'Corinth',
      state: 'MS',
    },
    38838: { lat: 34.5448, lng: -88.2643, city: 'Dennis', state: 'MS' },
    38839: { lat: 33.8558, lng: -89.28404, city: 'Derma', state: 'MS' },
    38841: { lat: 34.34943, lng: -89.02237, city: 'Ecru', state: 'MS' },
    38843: {
      lat: 34.26753,
      lng: -88.37437,
      city: 'Fulton',
      state: 'MS',
    },
    38844: {
      lat: 33.839,
      lng: -88.25593,
      city: 'Gattman',
      state: 'MS',
    },
    38846: { lat: 34.85135, lng: -88.39501, city: 'Glen', state: 'MS' },
    38847: {
      lat: 34.40772,
      lng: -88.24074,
      city: 'Golden',
      state: 'MS',
    },
    38848: {
      lat: 33.94523,
      lng: -88.27797,
      city: 'Greenwood Springs',
      state: 'MS',
    },
    38849: {
      lat: 34.45442,
      lng: -88.69319,
      city: 'Guntown',
      state: 'MS',
    },
    38850: {
      lat: 34.04792,
      lng: -89.06705,
      city: 'Houlka',
      state: 'MS',
    },
    38851: {
      lat: 33.90763,
      lng: -88.9637,
      city: 'Houston',
      state: 'MS',
    },
    38852: { lat: 34.84484, lng: -88.21598, city: 'Iuka', state: 'MS' },
    38855: {
      lat: 34.32646,
      lng: -88.48659,
      city: 'Mantachie',
      state: 'MS',
    },
    38856: {
      lat: 34.46835,
      lng: -88.44553,
      city: 'Marietta',
      state: 'MS',
    },
    38857: {
      lat: 34.28004,
      lng: -88.57808,
      city: 'Mooreville',
      state: 'MS',
    },
    38858: {
      lat: 34.10145,
      lng: -88.53726,
      city: 'Nettleton',
      state: 'MS',
    },
    38859: {
      lat: 34.52865,
      lng: -88.37548,
      city: 'New Site',
      state: 'MS',
    },
    38860: {
      lat: 33.97649,
      lng: -88.75879,
      city: 'Okolona',
      state: 'MS',
    },
    38862: {
      lat: 34.16871,
      lng: -88.62048,
      city: 'Plantersville',
      state: 'MS',
    },
    38863: {
      lat: 34.2167,
      lng: -89.02738,
      city: 'Pontotoc',
      state: 'MS',
    },
    38864: {
      lat: 34.14325,
      lng: -89.20167,
      city: 'Randolph',
      state: 'MS',
    },
    38865: {
      lat: 34.78003,
      lng: -88.58821,
      city: 'Rienzi',
      state: 'MS',
    },
    38866: {
      lat: 34.36644,
      lng: -88.68187,
      city: 'Saltillo',
      state: 'MS',
    },
    38868: {
      lat: 34.12168,
      lng: -88.74692,
      city: 'Shannon',
      state: 'MS',
    },
    38869: {
      lat: 34.35627,
      lng: -88.83635,
      city: 'Sherman',
      state: 'MS',
    },
    38870: {
      lat: 34.06886,
      lng: -88.34125,
      city: 'Smithville',
      state: 'MS',
    },
    38871: {
      lat: 34.33582,
      lng: -89.2055,
      city: 'Thaxton',
      state: 'MS',
    },
    38873: {
      lat: 34.66169,
      lng: -88.25141,
      city: 'Tishomingo',
      state: 'MS',
    },
    38876: {
      lat: 34.21457,
      lng: -88.23112,
      city: 'Tremont',
      state: 'MS',
    },
    38878: {
      lat: 33.88179,
      lng: -89.19231,
      city: 'Vardaman',
      state: 'MS',
    },
    38879: {
      lat: 34.19519,
      lng: -88.71209,
      city: 'Verona',
      state: 'MS',
    },
    38901: {
      lat: 33.76275,
      lng: -89.79799,
      city: 'Grenada',
      state: 'MS',
    },
    38913: {
      lat: 34.13774,
      lng: -89.37836,
      city: 'Banner',
      state: 'MS',
    },
    38914: {
      lat: 33.86466,
      lng: -89.46453,
      city: 'Big Creek',
      state: 'MS',
    },
    38915: {
      lat: 34.02807,
      lng: -89.38957,
      city: 'Bruce',
      state: 'MS',
    },
    38916: {
      lat: 33.81815,
      lng: -89.35158,
      city: 'Calhoun City',
      state: 'MS',
    },
    38917: {
      lat: 33.54902,
      lng: -89.95501,
      city: 'Carrollton',
      state: 'MS',
    },
    38920: {
      lat: 33.8823,
      lng: -90.05018,
      city: 'Cascilla',
      state: 'MS',
    },
    38921: {
      lat: 33.98328,
      lng: -90.1425,
      city: 'Charleston',
      state: 'MS',
    },
    38922: {
      lat: 33.93641,
      lng: -89.64878,
      city: 'Coffeeville',
      state: 'MS',
    },
    38923: {
      lat: 33.37829,
      lng: -89.99354,
      city: 'Coila',
      state: 'MS',
    },
    38924: {
      lat: 33.29342,
      lng: -90.22392,
      city: 'Cruger',
      state: 'MS',
    },
    38925: {
      lat: 33.62917,
      lng: -89.61961,
      city: 'Duck Hill',
      state: 'MS',
    },
    38927: { lat: 34.13394, lng: -90.01827, city: 'Enid', state: 'MS' },
    38928: {
      lat: 33.85405,
      lng: -90.28731,
      city: 'Glendora',
      state: 'MS',
    },
    38929: {
      lat: 33.74014,
      lng: -89.52425,
      city: 'Gore Springs',
      state: 'MS',
    },
    38930: {
      lat: 33.52845,
      lng: -90.14245,
      city: 'Greenwood',
      state: 'MS',
    },
    38940: {
      lat: 33.75145,
      lng: -90.03505,
      city: 'Holcomb',
      state: 'MS',
    },
    38941: {
      lat: 33.48655,
      lng: -90.35909,
      city: 'Itta Bena',
      state: 'MS',
    },
    38943: {
      lat: 33.52033,
      lng: -89.84525,
      city: 'McCarley',
      state: 'MS',
    },
    38944: {
      lat: 33.76106,
      lng: -90.34242,
      city: 'Minter City',
      state: 'MS',
    },
    38946: {
      lat: 33.37701,
      lng: -90.35591,
      city: 'Morgan City',
      state: 'MS',
    },
    38947: {
      lat: 33.51718,
      lng: -89.92128,
      city: 'North Carrollton',
      state: 'MS',
    },
    38948: {
      lat: 34.07635,
      lng: -89.89364,
      city: 'Oakland',
      state: 'MS',
    },
    38949: { lat: 34.18542, lng: -89.4151, city: 'Paris', state: 'MS' },
    38950: {
      lat: 33.74281,
      lng: -90.19758,
      city: 'Philipp',
      state: 'MS',
    },
    38951: {
      lat: 33.97605,
      lng: -89.28802,
      city: 'Pittsboro',
      state: 'MS',
    },
    38952: {
      lat: 33.63041,
      lng: -90.33711,
      city: 'Schlater',
      state: 'MS',
    },
    38953: {
      lat: 33.91821,
      lng: -89.92325,
      city: 'Scobey',
      state: 'MS',
    },
    38954: {
      lat: 33.41301,
      lng: -90.21536,
      city: 'Sidon',
      state: 'MS',
    },
    38957: {
      lat: 33.98747,
      lng: -90.37296,
      city: 'Sumner',
      state: 'MS',
    },
    38961: {
      lat: 33.96463,
      lng: -89.8859,
      city: 'Tillatoba',
      state: 'MS',
    },
    38962: {
      lat: 33.87201,
      lng: -90.18465,
      city: 'Tippo',
      state: 'MS',
    },
    38963: {
      lat: 34.00442,
      lng: -90.40598,
      city: 'Tutwiler',
      state: 'MS',
    },
    38964: {
      lat: 34.11035,
      lng: -90.38868,
      city: 'Vance',
      state: 'MS',
    },
    38965: {
      lat: 34.13486,
      lng: -89.59257,
      city: 'Water Valley',
      state: 'MS',
    },
    38966: { lat: 33.9202, lng: -90.31828, city: 'Webb', state: 'MS' },
    38967: {
      lat: 33.45832,
      lng: -89.72026,
      city: 'Winona',
      state: 'MS',
    },
    39038: {
      lat: 33.20179,
      lng: -90.47023,
      city: 'Belzoni',
      state: 'MS',
    },
    39039: {
      lat: 32.82216,
      lng: -90.22757,
      city: 'Benton',
      state: 'MS',
    },
    39040: {
      lat: 32.63907,
      lng: -90.41314,
      city: 'Bentonia',
      state: 'MS',
    },
    39041: {
      lat: 32.41757,
      lng: -90.47273,
      city: 'Bolton',
      state: 'MS',
    },
    39042: {
      lat: 32.2135,
      lng: -89.88966,
      city: 'Brandon',
      state: 'MS',
    },
    39044: {
      lat: 32.02869,
      lng: -89.97161,
      city: 'Braxton',
      state: 'MS',
    },
    39045: {
      lat: 32.78735,
      lng: -89.8173,
      city: 'Camden',
      state: 'MS',
    },
    39046: {
      lat: 32.62834,
      lng: -89.99634,
      city: 'Canton',
      state: 'MS',
    },
    39047: {
      lat: 32.43356,
      lng: -89.95011,
      city: 'Brandon',
      state: 'MS',
    },
    39051: {
      lat: 32.7868,
      lng: -89.50718,
      city: 'Carthage',
      state: 'MS',
    },
    39054: { lat: 32.79855, lng: -90.93525, city: 'Cary', state: 'MS' },
    39056: {
      lat: 32.37384,
      lng: -90.35083,
      city: 'Clinton',
      state: 'MS',
    },
    39057: {
      lat: 32.49268,
      lng: -89.28381,
      city: 'Conehatta',
      state: 'MS',
    },
    39058: {
      lat: 32.33571,
      lng: -90.33143,
      city: 'Clinton',
      state: 'MS',
    },
    39059: {
      lat: 31.98194,
      lng: -90.35141,
      city: 'Crystal Springs',
      state: 'MS',
    },
    39061: {
      lat: 33.08597,
      lng: -90.80324,
      city: 'Delta City',
      state: 'MS',
    },
    39062: { lat: 31.98479, lng: -89.89815, city: 'D Lo', state: 'MS' },
    39063: {
      lat: 33.11698,
      lng: -89.89975,
      city: 'Durant',
      state: 'MS',
    },
    39066: {
      lat: 32.30188,
      lng: -90.60502,
      city: 'Edwards',
      state: 'MS',
    },
    39067: {
      lat: 33.11574,
      lng: -89.44853,
      city: 'Ethel',
      state: 'MS',
    },
    39069: {
      lat: 31.6836,
      lng: -91.04038,
      city: 'Fayette',
      state: 'MS',
    },
    39071: { lat: 32.54887, lng: -90.3185, city: 'Flora', state: 'MS' },
    39073: {
      lat: 32.10269,
      lng: -90.13049,
      city: 'Florence',
      state: 'MS',
    },
    39074: {
      lat: 32.3872,
      lng: -89.47398,
      city: 'Forest',
      state: 'MS',
    },
    39078: {
      lat: 31.87901,
      lng: -90.19596,
      city: 'Georgetown',
      state: 'MS',
    },
    39079: {
      lat: 32.93178,
      lng: -89.88449,
      city: 'Goodman',
      state: 'MS',
    },
    39082: {
      lat: 31.94663,
      lng: -90.12369,
      city: 'Harrisville',
      state: 'MS',
    },
    39083: {
      lat: 31.83467,
      lng: -90.46997,
      city: 'Hazlehurst',
      state: 'MS',
    },
    39086: {
      lat: 31.96275,
      lng: -90.75964,
      city: 'Hermanville',
      state: 'MS',
    },
    39088: {
      lat: 32.73287,
      lng: -90.7545,
      city: 'Holly Bluff',
      state: 'MS',
    },
    39090: {
      lat: 33.00775,
      lng: -89.54503,
      city: 'Kosciusko',
      state: 'MS',
    },
    39092: { lat: 32.31489, lng: -89.36833, city: 'Lake', state: 'MS' },
    39094: { lat: 32.57497, lng: -89.67591, city: 'Lena', state: 'MS' },
    39095: {
      lat: 33.11929,
      lng: -90.09452,
      city: 'Lexington',
      state: 'MS',
    },
    39096: {
      lat: 31.82508,
      lng: -91.11672,
      city: 'Lorman',
      state: 'MS',
    },
    39097: {
      lat: 32.99105,
      lng: -90.59478,
      city: 'Louise',
      state: 'MS',
    },
    39108: {
      lat: 33.15684,
      lng: -89.33033,
      city: 'McCool',
      state: 'MS',
    },
    39110: {
      lat: 32.50325,
      lng: -90.13791,
      city: 'Madison',
      state: 'MS',
    },
    39111: {
      lat: 31.89536,
      lng: -89.71816,
      city: 'Magee',
      state: 'MS',
    },
    39113: {
      lat: 32.82707,
      lng: -91.06476,
      city: 'Mayersville',
      state: 'MS',
    },
    39114: {
      lat: 31.95331,
      lng: -89.83257,
      city: 'Mendenhall',
      state: 'MS',
    },
    39115: {
      lat: 33.04679,
      lng: -90.56133,
      city: 'Midnight',
      state: 'MS',
    },
    39116: { lat: 31.89165, lng: -89.54925, city: 'Mize', state: 'MS' },
    39117: {
      lat: 32.31096,
      lng: -89.66413,
      city: 'Morton',
      state: 'MS',
    },
    39119: {
      lat: 31.75236,
      lng: -89.67509,
      city: 'Mount Olive',
      state: 'MS',
    },
    39120: {
      lat: 31.51148,
      lng: -91.35206,
      city: 'Natchez',
      state: 'MS',
    },
    39140: {
      lat: 31.75211,
      lng: -90.00512,
      city: 'Newhebron',
      state: 'MS',
    },
    39144: {
      lat: 31.82971,
      lng: -90.80155,
      city: 'Pattison',
      state: 'MS',
    },
    39145: {
      lat: 32.33862,
      lng: -89.79819,
      city: 'Pelahatchie',
      state: 'MS',
    },
    39146: {
      lat: 32.88078,
      lng: -89.99316,
      city: 'Pickens',
      state: 'MS',
    },
    39148: {
      lat: 32.06159,
      lng: -89.99459,
      city: 'Piney Woods',
      state: 'MS',
    },
    39149: {
      lat: 31.83354,
      lng: -90.00985,
      city: 'Pinola',
      state: 'MS',
    },
    39150: {
      lat: 31.99264,
      lng: -90.98487,
      city: 'Port Gibson',
      state: 'MS',
    },
    39152: {
      lat: 32.2011,
      lng: -89.57779,
      city: 'Pulaski',
      state: 'MS',
    },
    39153: {
      lat: 32.05133,
      lng: -89.50159,
      city: 'Raleigh',
      state: 'MS',
    },
    39154: {
      lat: 32.22695,
      lng: -90.44762,
      city: 'Raymond',
      state: 'MS',
    },
    39156: {
      lat: 32.53901,
      lng: -90.77056,
      city: 'Redwood',
      state: 'MS',
    },
    39157: {
      lat: 32.42537,
      lng: -90.16685,
      city: 'Ridgeland',
      state: 'MS',
    },
    39159: {
      lat: 32.82894,
      lng: -90.92476,
      city: 'Rolling Fork',
      state: 'MS',
    },
    39160: {
      lat: 32.99756,
      lng: -89.75336,
      city: 'Sallis',
      state: 'MS',
    },
    39162: {
      lat: 32.62627,
      lng: -90.63395,
      city: 'Satartia',
      state: 'MS',
    },
    39166: {
      lat: 33.05161,
      lng: -90.48208,
      city: 'Silver City',
      state: 'MS',
    },
    39167: { lat: 32.09316, lng: -90.04003, city: 'Star', state: 'MS' },
    39168: {
      lat: 31.82994,
      lng: -89.40974,
      city: 'Taylorsville',
      state: 'MS',
    },
    39169: {
      lat: 33.13515,
      lng: -90.29371,
      city: 'Tchula',
      state: 'MS',
    },
    39170: { lat: 32.11182, lng: -90.3377, city: 'Terry', state: 'MS' },
    39173: {
      lat: 32.74247,
      lng: -90.47219,
      city: 'Tinsley',
      state: 'MS',
    },
    39174: {
      lat: 32.4009,
      lng: -90.16018,
      city: 'Tougaloo',
      state: 'MS',
    },
    39175: {
      lat: 32.09238,
      lng: -90.62097,
      city: 'Utica',
      state: 'MS',
    },
    39176: {
      lat: 33.30398,
      lng: -89.74859,
      city: 'Vaiden',
      state: 'MS',
    },
    39177: {
      lat: 32.61286,
      lng: -90.84546,
      city: 'Valley Park',
      state: 'MS',
    },
    39179: {
      lat: 32.80873,
      lng: -90.0984,
      city: 'Vaughan',
      state: 'MS',
    },
    39180: {
      lat: 32.223,
      lng: -90.90225,
      city: 'Vicksburg',
      state: 'MS',
    },
    39183: {
      lat: 32.4554,
      lng: -90.83307,
      city: 'Vicksburg',
      state: 'MS',
    },
    39189: {
      lat: 32.60412,
      lng: -89.4003,
      city: 'Walnut Grove',
      state: 'MS',
    },
    39191: {
      lat: 31.71492,
      lng: -90.40764,
      city: 'Wesson',
      state: 'MS',
    },
    39192: { lat: 33.19153, lng: -89.75324, city: 'West', state: 'MS' },
    39193: {
      lat: 32.23625,
      lng: -90.07681,
      city: 'Whitfield',
      state: 'MS',
    },
    39194: {
      lat: 32.83423,
      lng: -90.49856,
      city: 'Yazoo City',
      state: 'MS',
    },
    39201: {
      lat: 32.28981,
      lng: -90.18398,
      city: 'Jackson',
      state: 'MS',
    },
    39202: {
      lat: 32.31105,
      lng: -90.16956,
      city: 'Jackson',
      state: 'MS',
    },
    39203: {
      lat: 32.30928,
      lng: -90.20042,
      city: 'Jackson',
      state: 'MS',
    },
    39204: {
      lat: 32.28313,
      lng: -90.23689,
      city: 'Jackson',
      state: 'MS',
    },
    39206: {
      lat: 32.37237,
      lng: -90.17268,
      city: 'Jackson',
      state: 'MS',
    },
    39208: {
      lat: 32.25827,
      lng: -90.08908,
      city: 'Pearl',
      state: 'MS',
    },
    39209: {
      lat: 32.39498,
      lng: -90.30168,
      city: 'Jackson',
      state: 'MS',
    },
    39210: {
      lat: 32.32255,
      lng: -90.17997,
      city: 'Jackson',
      state: 'MS',
    },
    39211: {
      lat: 32.37176,
      lng: -90.12219,
      city: 'Jackson',
      state: 'MS',
    },
    39212: {
      lat: 32.24081,
      lng: -90.26174,
      city: 'Jackson',
      state: 'MS',
    },
    39213: {
      lat: 32.39384,
      lng: -90.23007,
      city: 'Jackson',
      state: 'MS',
    },
    39216: {
      lat: 32.33463,
      lng: -90.1606,
      city: 'Jackson',
      state: 'MS',
    },
    39217: {
      lat: 32.29754,
      lng: -90.20963,
      city: 'Jackson',
      state: 'MS',
    },
    39218: {
      lat: 32.22825,
      lng: -90.15857,
      city: 'Richland',
      state: 'MS',
    },
    39232: {
      lat: 32.3361,
      lng: -90.08693,
      city: 'Flowood',
      state: 'MS',
    },
    39269: {
      lat: 32.30114,
      lng: -90.1889,
      city: 'Jackson',
      state: 'MS',
    },
    39272: {
      lat: 32.18838,
      lng: -90.26335,
      city: 'Byram',
      state: 'MS',
    },
    39301: {
      lat: 32.27289,
      lng: -88.59226,
      city: 'Meridian',
      state: 'MS',
    },
    39305: {
      lat: 32.46392,
      lng: -88.72465,
      city: 'Meridian',
      state: 'MS',
    },
    39307: {
      lat: 32.33847,
      lng: -88.79217,
      city: 'Meridian',
      state: 'MS',
    },
    39309: {
      lat: 32.55417,
      lng: -88.57422,
      city: 'Meridian',
      state: 'MS',
    },
    39320: {
      lat: 32.56169,
      lng: -88.74507,
      city: 'Bailey',
      state: 'MS',
    },
    39322: {
      lat: 31.58344,
      lng: -88.53203,
      city: 'Buckatunna',
      state: 'MS',
    },
    39323: {
      lat: 32.34971,
      lng: -88.92624,
      city: 'Chunky',
      state: 'MS',
    },
    39324: {
      lat: 31.58032,
      lng: -88.69755,
      city: 'Clara',
      state: 'MS',
    },
    39325: {
      lat: 32.56198,
      lng: -88.87362,
      city: 'Collinsville',
      state: 'MS',
    },
    39326: {
      lat: 32.59814,
      lng: -88.67904,
      city: 'Daleville',
      state: 'MS',
    },
    39327: {
      lat: 32.44563,
      lng: -89.12173,
      city: 'Decatur',
      state: 'MS',
    },
    39328: {
      lat: 32.75012,
      lng: -88.69564,
      city: 'De Kalb',
      state: 'MS',
    },
    39330: {
      lat: 32.19385,
      lng: -88.85689,
      city: 'Enterprise',
      state: 'MS',
    },
    39332: {
      lat: 32.30399,
      lng: -89.0046,
      city: 'Hickory',
      state: 'MS',
    },
    39335: {
      lat: 32.53214,
      lng: -88.49263,
      city: 'Lauderdale',
      state: 'MS',
    },
    39336: {
      lat: 32.29081,
      lng: -89.27253,
      city: 'Lawrence',
      state: 'MS',
    },
    39337: {
      lat: 32.51447,
      lng: -88.98528,
      city: 'Little Rock',
      state: 'MS',
    },
    39338: {
      lat: 32.11034,
      lng: -89.23274,
      city: 'Louin',
      state: 'MS',
    },
    39339: {
      lat: 33.10417,
      lng: -89.00259,
      city: 'Louisville',
      state: 'MS',
    },
    39341: { lat: 33.0998, lng: -88.55702, city: 'Macon', state: 'MS' },
    39342: {
      lat: 32.42566,
      lng: -88.64968,
      city: 'Marion',
      state: 'MS',
    },
    39345: {
      lat: 32.28717,
      lng: -89.12974,
      city: 'Newton',
      state: 'MS',
    },
    39346: {
      lat: 32.96171,
      lng: -89.089,
      city: 'Noxapater',
      state: 'MS',
    },
    39347: {
      lat: 32.05507,
      lng: -88.93419,
      city: 'Pachuta',
      state: 'MS',
    },
    39348: {
      lat: 32.00007,
      lng: -89.03475,
      city: 'Paulding',
      state: 'MS',
    },
    39350: {
      lat: 32.79674,
      lng: -89.12984,
      city: 'Philadelphia',
      state: 'MS',
    },
    39352: {
      lat: 32.66834,
      lng: -88.47808,
      city: 'Porterville',
      state: 'MS',
    },
    39354: {
      lat: 32.87945,
      lng: -88.83625,
      city: 'Preston',
      state: 'MS',
    },
    39355: {
      lat: 32.05808,
      lng: -88.61965,
      city: 'Quitman',
      state: 'MS',
    },
    39356: {
      lat: 32.13632,
      lng: -89.0283,
      city: 'Rose Hill',
      state: 'MS',
    },
    39358: {
      lat: 32.83317,
      lng: -88.45605,
      city: 'Scooba',
      state: 'MS',
    },
    39359: {
      lat: 32.56852,
      lng: -89.33206,
      city: 'Sebastopol',
      state: 'MS',
    },
    39360: {
      lat: 31.86357,
      lng: -88.7409,
      city: 'Shubuta',
      state: 'MS',
    },
    39361: {
      lat: 32.97952,
      lng: -88.61234,
      city: 'Shuqualak',
      state: 'MS',
    },
    39362: {
      lat: 31.39326,
      lng: -88.53988,
      city: 'State Line',
      state: 'MS',
    },
    39363: {
      lat: 32.11918,
      lng: -88.77628,
      city: 'Stonewall',
      state: 'MS',
    },
    39364: {
      lat: 32.40597,
      lng: -88.49617,
      city: 'Toomsuba',
      state: 'MS',
    },
    39365: {
      lat: 32.60486,
      lng: -89.12225,
      city: 'Union',
      state: 'MS',
    },
    39366: {
      lat: 31.93795,
      lng: -88.90057,
      city: 'Vossburg',
      state: 'MS',
    },
    39367: {
      lat: 31.68219,
      lng: -88.6644,
      city: 'Waynesboro',
      state: 'MS',
    },
    39401: {
      lat: 31.23496,
      lng: -89.26912,
      city: 'Hattiesburg',
      state: 'MS',
    },
    39402: {
      lat: 31.33446,
      lng: -89.4132,
      city: 'Hattiesburg',
      state: 'MS',
    },
    39406: {
      lat: 31.32895,
      lng: -89.33515,
      city: 'Hattiesburg',
      state: 'MS',
    },
    39421: {
      lat: 31.48411,
      lng: -89.71179,
      city: 'Bassfield',
      state: 'MS',
    },
    39422: {
      lat: 31.95953,
      lng: -89.26745,
      city: 'Bay Springs',
      state: 'MS',
    },
    39423: {
      lat: 31.17553,
      lng: -88.91103,
      city: 'Beaumont',
      state: 'MS',
    },
    39425: {
      lat: 31.05633,
      lng: -89.06322,
      city: 'Brooklyn',
      state: 'MS',
    },
    39426: {
      lat: 30.65375,
      lng: -89.65709,
      city: 'Carriere',
      state: 'MS',
    },
    39427: {
      lat: 31.48693,
      lng: -89.82701,
      city: 'Carson',
      state: 'MS',
    },
    39428: {
      lat: 31.65017,
      lng: -89.57215,
      city: 'Collins',
      state: 'MS',
    },
    39429: {
      lat: 31.25052,
      lng: -89.76337,
      city: 'Columbia',
      state: 'MS',
    },
    39437: {
      lat: 31.57883,
      lng: -89.21981,
      city: 'Ellisville',
      state: 'MS',
    },
    39439: {
      lat: 31.85865,
      lng: -89.00227,
      city: 'Heidelberg',
      state: 'MS',
    },
    39440: { lat: 31.694, lng: -89.14791, city: 'Laurel', state: 'MS' },
    39443: {
      lat: 31.71932,
      lng: -89.07376,
      city: 'Laurel',
      state: 'MS',
    },
    39451: {
      lat: 31.16722,
      lng: -88.61757,
      city: 'Leakesville',
      state: 'MS',
    },
    39452: {
      lat: 30.88002,
      lng: -88.59097,
      city: 'Lucedale',
      state: 'MS',
    },
    39455: {
      lat: 31.01084,
      lng: -89.46931,
      city: 'Lumberton',
      state: 'MS',
    },
    39456: {
      lat: 31.0282,
      lng: -88.81819,
      city: 'McLain',
      state: 'MS',
    },
    39459: {
      lat: 31.49021,
      lng: -89.30216,
      city: 'Moselle',
      state: 'MS',
    },
    39461: {
      lat: 31.18703,
      lng: -88.73992,
      city: 'Neely',
      state: 'MS',
    },
    39462: {
      lat: 31.22105,
      lng: -89.05738,
      city: 'New Augusta',
      state: 'MS',
    },
    39464: {
      lat: 31.49268,
      lng: -89.06129,
      city: 'Ovett',
      state: 'MS',
    },
    39465: {
      lat: 31.34456,
      lng: -89.18751,
      city: 'Petal',
      state: 'MS',
    },
    39466: {
      lat: 30.53328,
      lng: -89.63424,
      city: 'Picayune',
      state: 'MS',
    },
    39470: {
      lat: 30.81602,
      lng: -89.57581,
      city: 'Poplarville',
      state: 'MS',
    },
    39474: {
      lat: 31.61739,
      lng: -89.85458,
      city: 'Prentiss',
      state: 'MS',
    },
    39475: {
      lat: 31.15496,
      lng: -89.4129,
      city: 'Purvis',
      state: 'MS',
    },
    39476: {
      lat: 31.37855,
      lng: -88.86764,
      city: 'Richton',
      state: 'MS',
    },
    39477: {
      lat: 31.79291,
      lng: -89.03579,
      city: 'Sandersville',
      state: 'MS',
    },
    39478: {
      lat: 31.05306,
      lng: -89.85768,
      city: 'Sandy Hook',
      state: 'MS',
    },
    39479: {
      lat: 31.53775,
      lng: -89.46065,
      city: 'Seminary',
      state: 'MS',
    },
    39480: { lat: 31.70889, lng: -89.31529, city: 'Soso', state: 'MS' },
    39481: {
      lat: 31.86738,
      lng: -89.24457,
      city: 'Stringer',
      state: 'MS',
    },
    39482: {
      lat: 31.37271,
      lng: -89.57966,
      city: 'Sumrall',
      state: 'MS',
    },
    39483: {
      lat: 31.23515,
      lng: -89.92674,
      city: 'Foxworth',
      state: 'MS',
    },
    39501: {
      lat: 30.3857,
      lng: -89.10309,
      city: 'Gulfport',
      state: 'MS',
    },
    39503: {
      lat: 30.47432,
      lng: -89.14752,
      city: 'Gulfport',
      state: 'MS',
    },
    39507: {
      lat: 30.39945,
      lng: -89.03628,
      city: 'Gulfport',
      state: 'MS',
    },
    39520: {
      lat: 30.26885,
      lng: -89.47132,
      city: 'Bay Saint Louis',
      state: 'MS',
    },
    39525: {
      lat: 30.38123,
      lng: -89.37065,
      city: 'Diamondhead',
      state: 'MS',
    },
    39529: {
      lat: 30.37734,
      lng: -89.60211,
      city: 'Stennis Space Center',
      state: 'MS',
    },
    39530: {
      lat: 30.40344,
      lng: -88.89165,
      city: 'Biloxi',
      state: 'MS',
    },
    39531: {
      lat: 30.40459,
      lng: -88.96735,
      city: 'Biloxi',
      state: 'MS',
    },
    39532: {
      lat: 30.50155,
      lng: -88.97454,
      city: 'Biloxi',
      state: 'MS',
    },
    39534: {
      lat: 30.40856,
      lng: -88.92122,
      city: 'Biloxi',
      state: 'MS',
    },
    39540: {
      lat: 30.47108,
      lng: -88.90088,
      city: 'Diberville',
      state: 'MS',
    },
    39553: {
      lat: 30.41625,
      lng: -88.64848,
      city: 'Gautier',
      state: 'MS',
    },
    39556: { lat: 30.44457, lng: -89.42747, city: 'Kiln', state: 'MS' },
    39560: {
      lat: 30.37356,
      lng: -89.17356,
      city: 'Long Beach',
      state: 'MS',
    },
    39561: {
      lat: 30.70633,
      lng: -89.16049,
      city: 'McHenry',
      state: 'MS',
    },
    39562: {
      lat: 30.54573,
      lng: -88.49039,
      city: 'Moss Point',
      state: 'MS',
    },
    39563: {
      lat: 30.42449,
      lng: -88.52601,
      city: 'Moss Point',
      state: 'MS',
    },
    39564: {
      lat: 30.40518,
      lng: -88.75471,
      city: 'Ocean Springs',
      state: 'MS',
    },
    39565: {
      lat: 30.5759,
      lng: -88.75207,
      city: 'Vancleave',
      state: 'MS',
    },
    39567: {
      lat: 30.36192,
      lng: -88.55254,
      city: 'Pascagoula',
      state: 'MS',
    },
    39571: {
      lat: 30.41662,
      lng: -89.28125,
      city: 'Pass Christian',
      state: 'MS',
    },
    39572: {
      lat: 30.25089,
      lng: -89.60127,
      city: 'Pearlington',
      state: 'MS',
    },
    39573: {
      lat: 30.72692,
      lng: -89.08271,
      city: 'Perkinston',
      state: 'MS',
    },
    39574: {
      lat: 30.60912,
      lng: -89.10489,
      city: 'Saucier',
      state: 'MS',
    },
    39576: {
      lat: 30.28699,
      lng: -89.38511,
      city: 'Waveland',
      state: 'MS',
    },
    39577: {
      lat: 30.90213,
      lng: -89.07971,
      city: 'Wiggins',
      state: 'MS',
    },
    39581: {
      lat: 30.35048,
      lng: -88.49324,
      city: 'Pascagoula',
      state: 'MS',
    },
    39595: {
      lat: 30.33448,
      lng: -88.57587,
      city: 'Pascagoula',
      state: 'MS',
    },
    39601: {
      lat: 31.56548,
      lng: -90.47762,
      city: 'Brookhaven',
      state: 'MS',
    },
    39629: {
      lat: 31.43729,
      lng: -90.45737,
      city: 'Bogue Chitto',
      state: 'MS',
    },
    39630: { lat: 31.45939, lng: -90.84544, city: 'Bude', state: 'MS' },
    39631: {
      lat: 31.07321,
      lng: -91.09581,
      city: 'Centreville',
      state: 'MS',
    },
    39633: { lat: 31.287, lng: -91.16663, city: 'Crosby', state: 'MS' },
    39635: {
      lat: 31.18432,
      lng: -90.44859,
      city: 'Fernwood',
      state: 'MS',
    },
    39638: {
      lat: 31.22378,
      lng: -90.99747,
      city: 'Gloster',
      state: 'MS',
    },
    39641: {
      lat: 31.35717,
      lng: -90.17042,
      city: 'Jayess',
      state: 'MS',
    },
    39643: {
      lat: 31.24575,
      lng: -90.01368,
      city: 'Kokomo',
      state: 'MS',
    },
    39645: {
      lat: 31.1485,
      lng: -90.79399,
      city: 'Liberty',
      state: 'MS',
    },
    39647: {
      lat: 31.51498,
      lng: -90.7143,
      city: 'McCall Creek',
      state: 'MS',
    },
    39648: {
      lat: 31.18611,
      lng: -90.38804,
      city: 'Mccomb',
      state: 'MS',
    },
    39652: {
      lat: 31.11034,
      lng: -90.46701,
      city: 'Magnolia',
      state: 'MS',
    },
    39653: {
      lat: 31.46055,
      lng: -90.86727,
      city: 'Meadville',
      state: 'MS',
    },
    39654: {
      lat: 31.53716,
      lng: -90.13831,
      city: 'Monticello',
      state: 'MS',
    },
    39656: {
      lat: 31.45055,
      lng: -89.92843,
      city: 'Oak Vale',
      state: 'MS',
    },
    39657: {
      lat: 31.02727,
      lng: -90.50334,
      city: 'Osyka',
      state: 'MS',
    },
    39661: {
      lat: 31.48704,
      lng: -91.07952,
      city: 'Roxie',
      state: 'MS',
    },
    39662: { lat: 31.38778, lng: -90.29838, city: 'Ruth', state: 'MS' },
    39663: {
      lat: 31.58615,
      lng: -90.02955,
      city: 'Silver Creek',
      state: 'MS',
    },
    39664: {
      lat: 31.32894,
      lng: -90.68252,
      city: 'Smithdale',
      state: 'MS',
    },
    39665: {
      lat: 31.64125,
      lng: -90.21404,
      city: 'Sontag',
      state: 'MS',
    },
    39666: {
      lat: 31.2853,
      lng: -90.48857,
      city: 'Summit',
      state: 'MS',
    },
    39667: {
      lat: 31.1298,
      lng: -90.12656,
      city: 'Tylertown',
      state: 'MS',
    },
    39668: {
      lat: 31.69126,
      lng: -90.79126,
      city: 'Union Church',
      state: 'MS',
    },
    39669: {
      lat: 31.14966,
      lng: -91.39547,
      city: 'Woodville',
      state: 'MS',
    },
    39701: {
      lat: 33.41091,
      lng: -88.50807,
      city: 'Columbus',
      state: 'MS',
    },
    39702: {
      lat: 33.43876,
      lng: -88.34493,
      city: 'Columbus',
      state: 'MS',
    },
    39705: {
      lat: 33.58669,
      lng: -88.43705,
      city: 'Columbus',
      state: 'MS',
    },
    39730: {
      lat: 33.83926,
      lng: -88.56267,
      city: 'Aberdeen',
      state: 'MS',
    },
    39735: {
      lat: 33.34511,
      lng: -89.18998,
      city: 'Ackerman',
      state: 'MS',
    },
    39736: {
      lat: 33.4169,
      lng: -88.64178,
      city: 'Artesia',
      state: 'MS',
    },
    39737: {
      lat: 33.65211,
      lng: -89.33573,
      city: 'Bellefontaine',
      state: 'MS',
    },
    39739: {
      lat: 33.23843,
      lng: -88.57855,
      city: 'Brooksville',
      state: 'MS',
    },
    39740: {
      lat: 33.71668,
      lng: -88.31287,
      city: 'Caledonia',
      state: 'MS',
    },
    39741: {
      lat: 33.65254,
      lng: -88.85536,
      city: 'Cedarbluff',
      state: 'MS',
    },
    39743: {
      lat: 33.31913,
      lng: -88.61308,
      city: 'Crawford',
      state: 'MS',
    },
    39744: {
      lat: 33.58344,
      lng: -89.30374,
      city: 'Eupora',
      state: 'MS',
    },
    39745: {
      lat: 33.29984,
      lng: -89.46277,
      city: 'French Camp',
      state: 'MS',
    },
    39746: {
      lat: 33.74697,
      lng: -88.41544,
      city: 'Hamilton',
      state: 'MS',
    },
    39747: {
      lat: 33.42283,
      lng: -89.5538,
      city: 'Kilmichael',
      state: 'MS',
    },
    39750: {
      lat: 33.56331,
      lng: -89.05751,
      city: 'Maben',
      state: 'MS',
    },
    39751: {
      lat: 33.70444,
      lng: -89.10004,
      city: 'Mantee',
      state: 'MS',
    },
    39752: {
      lat: 33.5198,
      lng: -89.15427,
      city: 'Mathiston',
      state: 'MS',
    },
    39753: {
      lat: 33.48441,
      lng: -88.63052,
      city: 'Mayhew',
      state: 'MS',
    },
    39755: {
      lat: 33.61275,
      lng: -88.95138,
      city: 'Pheba',
      state: 'MS',
    },
    39756: {
      lat: 33.78356,
      lng: -88.74266,
      city: 'Prairie',
      state: 'MS',
    },
    39759: {
      lat: 33.43491,
      lng: -88.82631,
      city: 'Starkville',
      state: 'MS',
    },
    39762: {
      lat: 33.44648,
      lng: -88.79576,
      city: 'Mississippi State',
      state: 'MS',
    },
    39766: {
      lat: 33.5876,
      lng: -88.31598,
      city: 'Steens',
      state: 'MS',
    },
    39767: {
      lat: 33.47263,
      lng: -89.45001,
      city: 'Stewart',
      state: 'MS',
    },
    39769: {
      lat: 33.31867,
      lng: -89.01136,
      city: 'Sturgis',
      state: 'MS',
    },
    39771: {
      lat: 33.60694,
      lng: -89.27873,
      city: 'Walthall',
      state: 'MS',
    },
    39772: { lat: 33.30509, lng: -89.29893, city: 'Weir', state: 'MS' },
    39773: {
      lat: 33.63892,
      lng: -88.67558,
      city: 'West Point',
      state: 'MS',
    },
    39776: {
      lat: 33.78517,
      lng: -89.03239,
      city: 'Woodland',
      state: 'MS',
    },
    39813: {
      lat: 31.42631,
      lng: -84.68361,
      city: 'Arlington',
      state: 'GA',
    },
    39815: {
      lat: 30.74138,
      lng: -84.4935,
      city: 'Attapulgus',
      state: 'GA',
    },
    39817: {
      lat: 30.93953,
      lng: -84.59132,
      city: 'Bainbridge',
      state: 'GA',
    },
    39819: {
      lat: 30.78485,
      lng: -84.64297,
      city: 'Bainbridge',
      state: 'GA',
    },
    39823: {
      lat: 31.34004,
      lng: -84.94641,
      city: 'Blakely',
      state: 'GA',
    },
    39824: {
      lat: 31.52882,
      lng: -84.86552,
      city: 'Bluffton',
      state: 'GA',
    },
    39825: {
      lat: 30.92359,
      lng: -84.73496,
      city: 'Brinson',
      state: 'GA',
    },
    39826: {
      lat: 31.82092,
      lng: -84.32423,
      city: 'Bronwood',
      state: 'GA',
    },
    39827: {
      lat: 30.94911,
      lng: -84.21674,
      city: 'Cairo',
      state: 'GA',
    },
    39828: {
      lat: 30.79741,
      lng: -84.22444,
      city: 'Cairo',
      state: 'GA',
    },
    39832: {
      lat: 31.19304,
      lng: -85.0257,
      city: 'Cedar Springs',
      state: 'GA',
    },
    39834: {
      lat: 30.89298,
      lng: -84.42749,
      city: 'Climax',
      state: 'GA',
    },
    39836: {
      lat: 31.66293,
      lng: -84.87726,
      city: 'Coleman',
      state: 'GA',
    },
    39837: {
      lat: 31.15321,
      lng: -84.67283,
      city: 'Colquitt',
      state: 'GA',
    },
    39840: {
      lat: 31.78874,
      lng: -84.75986,
      city: 'Cuthbert',
      state: 'GA',
    },
    39841: {
      lat: 31.29031,
      lng: -84.69124,
      city: 'Damascus',
      state: 'GA',
    },
    39842: {
      lat: 31.75837,
      lng: -84.44314,
      city: 'Dawson',
      state: 'GA',
    },
    39845: {
      lat: 30.95011,
      lng: -84.89329,
      city: 'Donalsonville',
      state: 'GA',
    },
    39846: {
      lat: 31.56841,
      lng: -84.74271,
      city: 'Edison',
      state: 'GA',
    },
    39851: {
      lat: 31.63432,
      lng: -85.00086,
      city: 'Fort Gaines',
      state: 'GA',
    },
    39854: {
      lat: 31.86713,
      lng: -85.04881,
      city: 'Georgetown',
      state: 'GA',
    },
    39859: {
      lat: 30.99493,
      lng: -84.80731,
      city: 'Iron City',
      state: 'GA',
    },
    39861: { lat: 31.13635, lng: -84.9884, city: 'Jakin', state: 'GA' },
    39862: {
      lat: 31.45694,
      lng: -84.51615,
      city: 'Leary',
      state: 'GA',
    },
    39866: {
      lat: 31.55954,
      lng: -84.60553,
      city: 'Morgan',
      state: 'GA',
    },
    39867: {
      lat: 31.84076,
      lng: -84.93684,
      city: 'Morris',
      state: 'GA',
    },
    39870: {
      lat: 31.31944,
      lng: -84.41071,
      city: 'Newton',
      state: 'GA',
    },
    39877: {
      lat: 31.9183,
      lng: -84.50647,
      city: 'Parrott',
      state: 'GA',
    },
    39885: {
      lat: 31.72189,
      lng: -84.34304,
      city: 'Sasser',
      state: 'GA',
    },
    39886: {
      lat: 31.73469,
      lng: -84.61554,
      city: 'Shellman',
      state: 'GA',
    },
    39897: {
      lat: 30.91234,
      lng: -84.33297,
      city: 'Whigham',
      state: 'GA',
    },
    40003: {
      lat: 38.26863,
      lng: -85.04367,
      city: 'Bagdad',
      state: 'KY',
    },
    40004: {
      lat: 37.80279,
      lng: -85.4695,
      city: 'Bardstown',
      state: 'KY',
    },
    40006: {
      lat: 38.59626,
      lng: -85.32861,
      city: 'Bedford',
      state: 'KY',
    },
    40007: {
      lat: 38.4524,
      lng: -85.00176,
      city: 'Bethlehem',
      state: 'KY',
    },
    40008: {
      lat: 37.91735,
      lng: -85.28097,
      city: 'Bloomfield',
      state: 'KY',
    },
    40009: {
      lat: 37.47075,
      lng: -85.099,
      city: 'Bradfordsville',
      state: 'KY',
    },
    40010: {
      lat: 38.37226,
      lng: -85.45431,
      city: 'Buckner',
      state: 'KY',
    },
    40011: {
      lat: 38.53048,
      lng: -85.1718,
      city: 'Campbellsburg',
      state: 'KY',
    },
    40012: {
      lat: 37.90697,
      lng: -85.17376,
      city: 'Chaplin',
      state: 'KY',
    },
    40013: {
      lat: 37.93427,
      lng: -85.48684,
      city: 'Coxs Creek',
      state: 'KY',
    },
    40014: {
      lat: 38.3412,
      lng: -85.43181,
      city: 'Crestwood',
      state: 'KY',
    },
    40019: {
      lat: 38.37416,
      lng: -85.17437,
      city: 'Eminence',
      state: 'KY',
    },
    40020: {
      lat: 37.93503,
      lng: -85.38753,
      city: 'Fairfield',
      state: 'KY',
    },
    40022: {
      lat: 38.15242,
      lng: -85.3341,
      city: 'Finchville',
      state: 'KY',
    },
    40023: {
      lat: 38.16264,
      lng: -85.42367,
      city: 'Fisherville',
      state: 'KY',
    },
    40025: {
      lat: 38.30133,
      lng: -85.65202,
      city: 'Glenview',
      state: 'KY',
    },
    40026: {
      lat: 38.42687,
      lng: -85.52902,
      city: 'Goshen',
      state: 'KY',
    },
    40031: {
      lat: 38.42617,
      lng: -85.39368,
      city: 'La Grange',
      state: 'KY',
    },
    40033: {
      lat: 37.55935,
      lng: -85.23928,
      city: 'Lebanon',
      state: 'KY',
    },
    40036: {
      lat: 38.44317,
      lng: -84.97272,
      city: 'Lockport',
      state: 'KY',
    },
    40037: {
      lat: 37.65409,
      lng: -85.41196,
      city: 'Loretto',
      state: 'KY',
    },
    40040: {
      lat: 37.77433,
      lng: -85.05727,
      city: 'Mackville',
      state: 'KY',
    },
    40041: {
      lat: 38.25615,
      lng: -85.66475,
      city: 'Masonic Home',
      state: 'KY',
    },
    40045: {
      lat: 38.69376,
      lng: -85.35641,
      city: 'Milton',
      state: 'KY',
    },
    40046: {
      lat: 38.02324,
      lng: -85.17729,
      city: 'Mount Eden',
      state: 'KY',
    },
    40047: {
      lat: 38.04007,
      lng: -85.55453,
      city: 'Mount Washington',
      state: 'KY',
    },
    40049: {
      lat: 37.66442,
      lng: -85.39724,
      city: 'Nerinx',
      state: 'KY',
    },
    40050: {
      lat: 38.44596,
      lng: -85.17753,
      city: 'New Castle',
      state: 'KY',
    },
    40051: {
      lat: 37.65293,
      lng: -85.57949,
      city: 'New Haven',
      state: 'KY',
    },
    40052: {
      lat: 37.58417,
      lng: -85.51154,
      city: 'New Hope',
      state: 'KY',
    },
    40055: {
      lat: 38.49889,
      lng: -85.32867,
      city: 'Pendleton',
      state: 'KY',
    },
    40056: {
      lat: 38.30654,
      lng: -85.48784,
      city: 'Pewee Valley',
      state: 'KY',
    },
    40057: {
      lat: 38.38636,
      lng: -85.0491,
      city: 'Pleasureville',
      state: 'KY',
    },
    40058: {
      lat: 38.43265,
      lng: -85.17002,
      city: 'Port Royal',
      state: 'KY',
    },
    40059: {
      lat: 38.35784,
      lng: -85.58441,
      city: 'Prospect',
      state: 'KY',
    },
    40060: {
      lat: 37.53529,
      lng: -85.44371,
      city: 'Raywick',
      state: 'KY',
    },
    40061: {
      lat: 37.70954,
      lng: -85.26353,
      city: 'Saint Catharine',
      state: 'KY',
    },
    40062: {
      lat: 37.59969,
      lng: -85.43161,
      city: 'Saint Francis',
      state: 'KY',
    },
    40063: {
      lat: 37.57052,
      lng: -85.34336,
      city: 'Saint Mary',
      state: 'KY',
    },
    40065: {
      lat: 38.21225,
      lng: -85.20734,
      city: 'Shelbyville',
      state: 'KY',
    },
    40067: {
      lat: 38.2269,
      lng: -85.364,
      city: 'Simpsonville',
      state: 'KY',
    },
    40068: {
      lat: 38.38933,
      lng: -85.27131,
      city: 'Smithfield',
      state: 'KY',
    },
    40069: {
      lat: 37.71627,
      lng: -85.21764,
      city: 'Springfield',
      state: 'KY',
    },
    40070: {
      lat: 38.47969,
      lng: -85.24405,
      city: 'Sulphur',
      state: 'KY',
    },
    40071: {
      lat: 38.04327,
      lng: -85.37069,
      city: 'Taylorsville',
      state: 'KY',
    },
    40075: {
      lat: 38.56169,
      lng: -85.11346,
      city: 'Turners Station',
      state: 'KY',
    },
    40076: {
      lat: 38.11301,
      lng: -85.08671,
      city: 'Waddy',
      state: 'KY',
    },
    40077: {
      lat: 38.49996,
      lng: -85.43768,
      city: 'Westport',
      state: 'KY',
    },
    40078: {
      lat: 37.84272,
      lng: -85.14902,
      city: 'Willisburg',
      state: 'KY',
    },
    40104: {
      lat: 38.09012,
      lng: -86.34053,
      city: 'Battletown',
      state: 'KY',
    },
    40107: {
      lat: 37.76952,
      lng: -85.63798,
      city: 'Boston',
      state: 'KY',
    },
    40108: {
      lat: 37.96964,
      lng: -86.16791,
      city: 'Brandenburg',
      state: 'KY',
    },
    40109: {
      lat: 38.05857,
      lng: -85.75469,
      city: 'Brooks',
      state: 'KY',
    },
    40110: {
      lat: 37.93478,
      lng: -85.64015,
      city: 'Clermont',
      state: 'KY',
    },
    40111: {
      lat: 37.77881,
      lng: -86.62881,
      city: 'Cloverport',
      state: 'KY',
    },
    40115: {
      lat: 37.73784,
      lng: -86.22707,
      city: 'Custer',
      state: 'KY',
    },
    40117: { lat: 37.89919, lng: -86.1247, city: 'Ekron', state: 'KY' },
    40118: {
      lat: 38.09805,
      lng: -85.75572,
      city: 'Fairdale',
      state: 'KY',
    },
    40119: {
      lat: 37.60799,
      lng: -86.54925,
      city: 'Falls Of Rough',
      state: 'KY',
    },
    40121: {
      lat: 37.92614,
      lng: -85.95292,
      city: 'Fort Knox',
      state: 'KY',
    },
    40140: {
      lat: 37.71867,
      lng: -86.29047,
      city: 'Garfield',
      state: 'KY',
    },
    40142: {
      lat: 37.88898,
      lng: -86.21095,
      city: 'Guston',
      state: 'KY',
    },
    40143: {
      lat: 37.78541,
      lng: -86.50507,
      city: 'Hardinsburg',
      state: 'KY',
    },
    40144: {
      lat: 37.75899,
      lng: -86.37732,
      city: 'Harned',
      state: 'KY',
    },
    40145: {
      lat: 37.64607,
      lng: -86.32019,
      city: 'Hudson',
      state: 'KY',
    },
    40146: {
      lat: 37.84622,
      lng: -86.31954,
      city: 'Irvington',
      state: 'KY',
    },
    40150: {
      lat: 37.87458,
      lng: -85.68799,
      city: 'Lebanon Junction',
      state: 'KY',
    },
    40152: {
      lat: 37.61389,
      lng: -86.44185,
      city: 'McDaniels',
      state: 'KY',
    },
    40155: {
      lat: 37.94684,
      lng: -85.99303,
      city: 'Muldraugh',
      state: 'KY',
    },
    40157: {
      lat: 38.02111,
      lng: -86.37694,
      city: 'Payneville',
      state: 'KY',
    },
    40160: {
      lat: 37.8144,
      lng: -85.93971,
      city: 'Radcliff',
      state: 'KY',
    },
    40161: {
      lat: 38.00422,
      lng: -86.39887,
      city: 'Rhodelia',
      state: 'KY',
    },
    40162: {
      lat: 37.7517,
      lng: -86.04394,
      city: 'Rineyville',
      state: 'KY',
    },
    40165: {
      lat: 37.99407,
      lng: -85.68918,
      city: 'Shepherdsville',
      state: 'KY',
    },
    40170: {
      lat: 37.96479,
      lng: -86.49607,
      city: 'Stephensport',
      state: 'KY',
    },
    40171: {
      lat: 37.96435,
      lng: -86.43577,
      city: 'Union Star',
      state: 'KY',
    },
    40175: {
      lat: 37.81907,
      lng: -86.08635,
      city: 'Vine Grove',
      state: 'KY',
    },
    40176: {
      lat: 37.92377,
      lng: -86.34652,
      city: 'Webster',
      state: 'KY',
    },
    40177: {
      lat: 38.0009,
      lng: -85.92502,
      city: 'West Point',
      state: 'KY',
    },
    40178: {
      lat: 37.65834,
      lng: -86.40853,
      city: 'Westview',
      state: 'KY',
    },
    40202: {
      lat: 38.25288,
      lng: -85.75205,
      city: 'Louisville',
      state: 'KY',
    },
    40203: {
      lat: 38.24722,
      lng: -85.765,
      city: 'Louisville',
      state: 'KY',
    },
    40204: {
      lat: 38.23873,
      lng: -85.7208,
      city: 'Louisville',
      state: 'KY',
    },
    40205: {
      lat: 38.22313,
      lng: -85.68275,
      city: 'Louisville',
      state: 'KY',
    },
    40206: {
      lat: 38.25788,
      lng: -85.70205,
      city: 'Louisville',
      state: 'KY',
    },
    40207: {
      lat: 38.26357,
      lng: -85.65543,
      city: 'Louisville',
      state: 'KY',
    },
    40208: {
      lat: 38.2176,
      lng: -85.7659,
      city: 'Louisville',
      state: 'KY',
    },
    40209: {
      lat: 38.18492,
      lng: -85.74372,
      city: 'Louisville',
      state: 'KY',
    },
    40210: {
      lat: 38.23161,
      lng: -85.78592,
      city: 'Louisville',
      state: 'KY',
    },
    40211: {
      lat: 38.23425,
      lng: -85.82147,
      city: 'Louisville',
      state: 'KY',
    },
    40212: {
      lat: 38.26787,
      lng: -85.80256,
      city: 'Louisville',
      state: 'KY',
    },
    40213: {
      lat: 38.177,
      lng: -85.71571,
      city: 'Louisville',
      state: 'KY',
    },
    40214: {
      lat: 38.15141,
      lng: -85.78285,
      city: 'Louisville',
      state: 'KY',
    },
    40215: {
      lat: 38.19052,
      lng: -85.78555,
      city: 'Louisville',
      state: 'KY',
    },
    40216: {
      lat: 38.18707,
      lng: -85.83738,
      city: 'Louisville',
      state: 'KY',
    },
    40217: {
      lat: 38.21689,
      lng: -85.73725,
      city: 'Louisville',
      state: 'KY',
    },
    40218: {
      lat: 38.19036,
      lng: -85.65698,
      city: 'Louisville',
      state: 'KY',
    },
    40219: {
      lat: 38.13907,
      lng: -85.68877,
      city: 'Louisville',
      state: 'KY',
    },
    40220: {
      lat: 38.21535,
      lng: -85.61794,
      city: 'Louisville',
      state: 'KY',
    },
    40221: {
      lat: 38.18644,
      lng: -85.75302,
      city: 'Louisville',
      state: 'KY',
    },
    40222: {
      lat: 38.26436,
      lng: -85.61173,
      city: 'Louisville',
      state: 'KY',
    },
    40223: {
      lat: 38.26153,
      lng: -85.54328,
      city: 'Louisville',
      state: 'KY',
    },
    40228: {
      lat: 38.13931,
      lng: -85.62947,
      city: 'Louisville',
      state: 'KY',
    },
    40229: {
      lat: 38.09074,
      lng: -85.65597,
      city: 'Louisville',
      state: 'KY',
    },
    40231: {
      lat: 38.19551,
      lng: -85.69523,
      city: 'Louisville',
      state: 'KY',
    },
    40241: {
      lat: 38.30339,
      lng: -85.5774,
      city: 'Louisville',
      state: 'KY',
    },
    40242: {
      lat: 38.27639,
      lng: -85.59042,
      city: 'Louisville',
      state: 'KY',
    },
    40243: {
      lat: 38.23917,
      lng: -85.53188,
      city: 'Louisville',
      state: 'KY',
    },
    40245: {
      lat: 38.26574,
      lng: -85.45327,
      city: 'Louisville',
      state: 'KY',
    },
    40258: {
      lat: 38.14514,
      lng: -85.87253,
      city: 'Louisville',
      state: 'KY',
    },
    40272: {
      lat: 38.08291,
      lng: -85.85315,
      city: 'Louisville',
      state: 'KY',
    },
    40280: {
      lat: 38.24764,
      lng: -85.68704,
      city: 'Louisville',
      state: 'KY',
    },
    40291: {
      lat: 38.1275,
      lng: -85.57823,
      city: 'Louisville',
      state: 'KY',
    },
    40299: {
      lat: 38.16319,
      lng: -85.51613,
      city: 'Louisville',
      state: 'KY',
    },
    40310: { lat: 37.752, lng: -84.76941, city: 'Burgin', state: 'KY' },
    40311: {
      lat: 38.32407,
      lng: -84.02256,
      city: 'Carlisle',
      state: 'KY',
    },
    40312: {
      lat: 37.84386,
      lng: -83.93013,
      city: 'Clay City',
      state: 'KY',
    },
    40313: {
      lat: 38.13215,
      lng: -83.38973,
      city: 'Clearfield',
      state: 'KY',
    },
    40316: {
      lat: 37.93419,
      lng: -83.52895,
      city: 'Denniston',
      state: 'KY',
    },
    40322: {
      lat: 37.93367,
      lng: -83.63329,
      city: 'Frenchburg',
      state: 'KY',
    },
    40324: {
      lat: 38.24786,
      lng: -84.54956,
      city: 'Georgetown',
      state: 'KY',
    },
    40328: {
      lat: 37.56632,
      lng: -85.02565,
      city: 'Gravel Switch',
      state: 'KY',
    },
    40330: {
      lat: 37.78912,
      lng: -84.89016,
      city: 'Harrodsburg',
      state: 'KY',
    },
    40334: { lat: 38.0197, lng: -83.74943, city: 'Hope', state: 'KY' },
    40336: {
      lat: 37.68352,
      lng: -83.9832,
      city: 'Irvine',
      state: 'KY',
    },
    40337: {
      lat: 37.95286,
      lng: -83.85644,
      city: 'Jeffersonville',
      state: 'KY',
    },
    40339: {
      lat: 37.94313,
      lng: -84.64107,
      city: 'Keene',
      state: 'KY',
    },
    40342: {
      lat: 38.0036,
      lng: -84.9837,
      city: 'Lawrenceburg',
      state: 'KY',
    },
    40346: {
      lat: 37.98339,
      lng: -83.74384,
      city: 'Means',
      state: 'KY',
    },
    40347: {
      lat: 38.15794,
      lng: -84.71915,
      city: 'Midway',
      state: 'KY',
    },
    40348: {
      lat: 38.30296,
      lng: -84.14613,
      city: 'Millersburg',
      state: 'KY',
    },
    40350: {
      lat: 38.30512,
      lng: -83.87881,
      city: 'Moorefield',
      state: 'KY',
    },
    40351: {
      lat: 38.19817,
      lng: -83.4133,
      city: 'Morehead',
      state: 'KY',
    },
    40353: {
      lat: 38.06514,
      lng: -83.94853,
      city: 'Mount Sterling',
      state: 'KY',
    },
    40355: {
      lat: 38.61023,
      lng: -84.86535,
      city: 'New Liberty',
      state: 'KY',
    },
    40356: {
      lat: 37.87307,
      lng: -84.56554,
      city: 'Nicholasville',
      state: 'KY',
    },
    40357: {
      lat: 38.14519,
      lng: -84.10843,
      city: 'North Middletown',
      state: 'KY',
    },
    40358: {
      lat: 38.05769,
      lng: -83.6831,
      city: 'Olympia',
      state: 'KY',
    },
    40359: {
      lat: 38.48224,
      lng: -84.8209,
      city: 'Owenton',
      state: 'KY',
    },
    40360: {
      lat: 38.15665,
      lng: -83.76679,
      city: 'Owingsville',
      state: 'KY',
    },
    40361: {
      lat: 38.20536,
      lng: -84.22415,
      city: 'Paris',
      state: 'KY',
    },
    40363: {
      lat: 38.51439,
      lng: -85.00976,
      city: 'Perry Park',
      state: 'KY',
    },
    40370: {
      lat: 38.39382,
      lng: -84.52971,
      city: 'Sadieville',
      state: 'KY',
    },
    40371: {
      lat: 38.07716,
      lng: -83.59561,
      city: 'Salt Lick',
      state: 'KY',
    },
    40372: {
      lat: 37.90899,
      lng: -84.88537,
      city: 'Salvisa',
      state: 'KY',
    },
    40374: {
      lat: 38.21419,
      lng: -83.90359,
      city: 'Sharpsburg',
      state: 'KY',
    },
    40376: { lat: 37.7839, lng: -83.69604, city: 'Slade', state: 'KY' },
    40379: {
      lat: 38.30778,
      lng: -84.68992,
      city: 'Stamping Ground',
      state: 'KY',
    },
    40380: {
      lat: 37.81965,
      lng: -83.78006,
      city: 'Stanton',
      state: 'KY',
    },
    40383: {
      lat: 38.01022,
      lng: -84.73919,
      city: 'Versailles',
      state: 'KY',
    },
    40385: { lat: 37.73866, lng: -84.13085, city: 'Waco', state: 'KY' },
    40387: {
      lat: 37.93494,
      lng: -83.49027,
      city: 'Wellington',
      state: 'KY',
    },
    40390: {
      lat: 37.85407,
      lng: -84.67075,
      city: 'Wilmore',
      state: 'KY',
    },
    40391: {
      lat: 37.96969,
      lng: -84.14507,
      city: 'Winchester',
      state: 'KY',
    },
    40402: {
      lat: 37.29663,
      lng: -83.97008,
      city: 'Annville',
      state: 'KY',
    },
    40403: {
      lat: 37.57052,
      lng: -84.26622,
      city: 'Berea',
      state: 'KY',
    },
    40404: {
      lat: 37.57311,
      lng: -84.29037,
      city: 'Berea',
      state: 'KY',
    },
    40409: {
      lat: 37.37667,
      lng: -84.42971,
      city: 'Brodhead',
      state: 'KY',
    },
    40419: {
      lat: 37.44791,
      lng: -84.49204,
      city: 'Crab Orchard',
      state: 'KY',
    },
    40422: {
      lat: 37.64235,
      lng: -84.80488,
      city: 'Danville',
      state: 'KY',
    },
    40434: {
      lat: 37.39364,
      lng: -83.93949,
      city: 'Gray Hawk',
      state: 'KY',
    },
    40437: {
      lat: 37.44531,
      lng: -84.85486,
      city: 'Hustonville',
      state: 'KY',
    },
    40440: {
      lat: 37.57628,
      lng: -84.8322,
      city: 'Junction City',
      state: 'KY',
    },
    40442: {
      lat: 37.35205,
      lng: -84.72868,
      city: 'Kings Mountain',
      state: 'KY',
    },
    40444: {
      lat: 37.67893,
      lng: -84.58819,
      city: 'Lancaster',
      state: 'KY',
    },
    40445: {
      lat: 37.3125,
      lng: -84.21159,
      city: 'Livingston',
      state: 'KY',
    },
    40447: { lat: 37.45496, lng: -84.0299, city: 'McKee', state: 'KY' },
    40448: {
      lat: 37.45624,
      lng: -84.75799,
      city: 'McKinney',
      state: 'KY',
    },
    40456: {
      lat: 37.3538,
      lng: -84.32099,
      city: 'Mount Vernon',
      state: 'KY',
    },
    40460: {
      lat: 37.39385,
      lng: -84.23985,
      city: 'Orlando',
      state: 'KY',
    },
    40461: {
      lat: 37.59014,
      lng: -84.40849,
      city: 'Paint Lick',
      state: 'KY',
    },
    40464: {
      lat: 37.55579,
      lng: -84.91148,
      city: 'Parksville',
      state: 'KY',
    },
    40468: {
      lat: 37.65158,
      lng: -84.98782,
      city: 'Perryville',
      state: 'KY',
    },
    40472: {
      lat: 37.71841,
      lng: -83.87562,
      city: 'Ravenna',
      state: 'KY',
    },
    40475: {
      lat: 37.7635,
      lng: -84.30828,
      city: 'Richmond',
      state: 'KY',
    },
    40481: {
      lat: 37.42658,
      lng: -84.09116,
      city: 'Sandgap',
      state: 'KY',
    },
    40484: {
      lat: 37.5153,
      lng: -84.676,
      city: 'Stanford',
      state: 'KY',
    },
    40486: {
      lat: 37.37238,
      lng: -83.86123,
      city: 'Tyner',
      state: 'KY',
    },
    40489: {
      lat: 37.36629,
      lng: -84.64435,
      city: 'Waynesburg',
      state: 'KY',
    },
    40502: {
      lat: 38.01376,
      lng: -84.48189,
      city: 'Lexington',
      state: 'KY',
    },
    40503: {
      lat: 38.0047,
      lng: -84.53434,
      city: 'Lexington',
      state: 'KY',
    },
    40504: {
      lat: 38.04181,
      lng: -84.54133,
      city: 'Lexington',
      state: 'KY',
    },
    40505: {
      lat: 38.06039,
      lng: -84.45679,
      city: 'Lexington',
      state: 'KY',
    },
    40507: {
      lat: 38.04686,
      lng: -84.49628,
      city: 'Lexington',
      state: 'KY',
    },
    40508: {
      lat: 38.05004,
      lng: -84.50018,
      city: 'Lexington',
      state: 'KY',
    },
    40509: {
      lat: 37.99408,
      lng: -84.36791,
      city: 'Lexington',
      state: 'KY',
    },
    40510: {
      lat: 38.07243,
      lng: -84.60138,
      city: 'Lexington',
      state: 'KY',
    },
    40511: {
      lat: 38.13278,
      lng: -84.47392,
      city: 'Lexington',
      state: 'KY',
    },
    40513: {
      lat: 38.01626,
      lng: -84.60694,
      city: 'Lexington',
      state: 'KY',
    },
    40514: {
      lat: 37.98279,
      lng: -84.56352,
      city: 'Lexington',
      state: 'KY',
    },
    40515: {
      lat: 37.92021,
      lng: -84.4152,
      city: 'Lexington',
      state: 'KY',
    },
    40516: {
      lat: 38.07366,
      lng: -84.36631,
      city: 'Lexington',
      state: 'KY',
    },
    40517: {
      lat: 37.98386,
      lng: -84.48833,
      city: 'Lexington',
      state: 'KY',
    },
    40526: {
      lat: 38.0297,
      lng: -84.50153,
      city: 'Lexington',
      state: 'KY',
    },
    40536: {
      lat: 38.02979,
      lng: -84.50732,
      city: 'Lexington',
      state: 'KY',
    },
    40601: {
      lat: 38.23294,
      lng: -84.88327,
      city: 'Frankfort',
      state: 'KY',
    },
    40701: {
      lat: 36.91759,
      lng: -84.16651,
      city: 'Corbin',
      state: 'KY',
    },
    40729: {
      lat: 37.2435,
      lng: -84.14788,
      city: 'East Bernstadt',
      state: 'KY',
    },
    40734: { lat: 36.92642, lng: -83.96609, city: 'Gray', state: 'KY' },
    40737: {
      lat: 37.00838,
      lng: -84.13902,
      city: 'Keavy',
      state: 'KY',
    },
    40740: { lat: 37.02111, lng: -84.03888, city: 'Lily', state: 'KY' },
    40741: {
      lat: 37.13349,
      lng: -84.11636,
      city: 'London',
      state: 'KY',
    },
    40744: {
      lat: 37.03841,
      lng: -84.08864,
      city: 'London',
      state: 'KY',
    },
    40759: {
      lat: 36.82034,
      lng: -84.0571,
      city: 'Rockholds',
      state: 'KY',
    },
    40763: {
      lat: 36.68848,
      lng: -83.94403,
      city: 'Siler',
      state: 'KY',
    },
    40769: {
      lat: 36.7116,
      lng: -84.17284,
      city: 'Williamsburg',
      state: 'KY',
    },
    40771: {
      lat: 36.85942,
      lng: -84.03459,
      city: 'Woodbine',
      state: 'KY',
    },
    40801: {
      lat: 36.85692,
      lng: -83.25493,
      city: 'Ages Brookside',
      state: 'KY',
    },
    40803: {
      lat: 37.04572,
      lng: -83.42185,
      city: 'Asher',
      state: 'KY',
    },
    40806: {
      lat: 36.88595,
      lng: -83.2881,
      city: 'Baxter',
      state: 'KY',
    },
    40807: {
      lat: 36.9647,
      lng: -82.95578,
      city: 'Benham',
      state: 'KY',
    },
    40808: {
      lat: 36.9995,
      lng: -83.2801,
      city: 'Big Laurel',
      state: 'KY',
    },
    40810: {
      lat: 36.93176,
      lng: -83.30451,
      city: 'Bledsoe',
      state: 'KY',
    },
    40813: {
      lat: 36.71526,
      lng: -83.59838,
      city: 'Calvin',
      state: 'KY',
    },
    40815: {
      lat: 36.7876,
      lng: -83.22876,
      city: 'Cawood',
      state: 'KY',
    },
    40816: {
      lat: 37.00879,
      lng: -83.3667,
      city: 'Chappell',
      state: 'KY',
    },
    40818: {
      lat: 36.82003,
      lng: -83.2442,
      city: 'Coalgood',
      state: 'KY',
    },
    40819: {
      lat: 36.81025,
      lng: -83.46242,
      city: 'Coldiron',
      state: 'KY',
    },
    40820: {
      lat: 36.77552,
      lng: -83.16515,
      city: 'Cranks',
      state: 'KY',
    },
    40823: {
      lat: 36.95664,
      lng: -82.98864,
      city: 'Cumberland',
      state: 'KY',
    },
    40824: {
      lat: 36.82489,
      lng: -83.37221,
      city: 'Dayhoit',
      state: 'KY',
    },
    40826: { lat: 37.05637, lng: -82.7783, city: 'Eolia', state: 'KY' },
    40827: {
      lat: 37.05208,
      lng: -83.47187,
      city: 'Essie',
      state: 'KY',
    },
    40828: {
      lat: 36.87107,
      lng: -83.14868,
      city: 'Evarts',
      state: 'KY',
    },
    40829: {
      lat: 36.798,
      lng: -83.29655,
      city: 'Grays Knob',
      state: 'KY',
    },
    40830: {
      lat: 36.77644,
      lng: -83.33114,
      city: 'Gulston',
      state: 'KY',
    },
    40831: {
      lat: 36.76457,
      lng: -83.33454,
      city: 'Harlan',
      state: 'KY',
    },
    40840: {
      lat: 36.9457,
      lng: -83.41854,
      city: 'Helton',
      state: 'KY',
    },
    40843: {
      lat: 36.88826,
      lng: -82.93666,
      city: 'Holmes Mill',
      state: 'KY',
    },
    40844: {
      lat: 37.05506,
      lng: -83.35406,
      city: 'Hoskinston',
      state: 'KY',
    },
    40845: {
      lat: 36.79899,
      lng: -83.51755,
      city: 'Hulen',
      state: 'KY',
    },
    40847: {
      lat: 36.85281,
      lng: -83.15798,
      city: 'Kenvir',
      state: 'KY',
    },
    40849: {
      lat: 36.89941,
      lng: -83.13469,
      city: 'Lejunior',
      state: 'KY',
    },
    40854: {
      lat: 36.84664,
      lng: -83.35634,
      city: 'Loyall',
      state: 'KY',
    },
    40855: {
      lat: 36.94931,
      lng: -82.89836,
      city: 'Lynch',
      state: 'KY',
    },
    40856: {
      lat: 36.70574,
      lng: -83.52939,
      city: 'Miracle',
      state: 'KY',
    },
    40858: {
      lat: 37.01592,
      lng: -83.40106,
      city: 'Mozelle',
      state: 'KY',
    },
    40862: {
      lat: 37.01418,
      lng: -82.87096,
      city: 'Partridge',
      state: 'KY',
    },
    40863: {
      lat: 36.74048,
      lng: -83.44567,
      city: 'Pathfork',
      state: 'KY',
    },
    40865: {
      lat: 36.91637,
      lng: -83.21804,
      city: 'Putney',
      state: 'KY',
    },
    40868: {
      lat: 37.09367,
      lng: -83.41638,
      city: 'Stinnett',
      state: 'KY',
    },
    40870: { lat: 36.93211, lng: -83.14858, city: 'Totz', state: 'KY' },
    40873: {
      lat: 36.81541,
      lng: -83.40411,
      city: 'Wallins Creek',
      state: 'KY',
    },
    40874: {
      lat: 36.98407,
      lng: -83.47345,
      city: 'Warbranch',
      state: 'KY',
    },
    40902: {
      lat: 36.84824,
      lng: -83.62435,
      city: 'Arjay',
      state: 'KY',
    },
    40903: {
      lat: 36.80973,
      lng: -83.83163,
      city: 'Artemus',
      state: 'KY',
    },
    40906: {
      lat: 36.88828,
      lng: -83.87546,
      city: 'Barbourville',
      state: 'KY',
    },
    40913: {
      lat: 36.94986,
      lng: -83.54367,
      city: 'Beverly',
      state: 'KY',
    },
    40914: {
      lat: 37.0932,
      lng: -83.56154,
      city: 'Big Creek',
      state: 'KY',
    },
    40915: {
      lat: 36.89235,
      lng: -83.82025,
      city: 'Bimble',
      state: 'KY',
    },
    40921: {
      lat: 36.78195,
      lng: -83.90185,
      city: 'Bryants Store',
      state: 'KY',
    },
    40923: {
      lat: 36.92059,
      lng: -83.84923,
      city: 'Cannon',
      state: 'KY',
    },
    40927: {
      lat: 36.89081,
      lng: -83.03445,
      city: 'Closplint',
      state: 'KY',
    },
    40935: {
      lat: 36.91107,
      lng: -83.71264,
      city: 'Flat Lick',
      state: 'KY',
    },
    40939: {
      lat: 36.81217,
      lng: -83.71152,
      city: 'Fourmile',
      state: 'KY',
    },
    40940: {
      lat: 36.60991,
      lng: -83.92988,
      city: 'Frakes',
      state: 'KY',
    },
    40941: {
      lat: 37.12981,
      lng: -83.74585,
      city: 'Garrard',
      state: 'KY',
    },
    40943: {
      lat: 36.95494,
      lng: -83.85631,
      city: 'Girdler',
      state: 'KY',
    },
    40946: {
      lat: 36.95757,
      lng: -83.82467,
      city: 'Green Road',
      state: 'KY',
    },
    40949: {
      lat: 36.90253,
      lng: -83.86717,
      city: 'Heidrick',
      state: 'KY',
    },
    40953: {
      lat: 36.93771,
      lng: -83.80163,
      city: 'Hinkle',
      state: 'KY',
    },
    40958: {
      lat: 36.8088,
      lng: -83.5878,
      city: 'Kettle Island',
      state: 'KY',
    },
    40962: {
      lat: 37.14912,
      lng: -83.74922,
      city: 'Manchester',
      state: 'KY',
    },
    40964: {
      lat: 36.78264,
      lng: -83.33763,
      city: 'Mary Alice',
      state: 'KY',
    },
    40965: {
      lat: 36.62469,
      lng: -83.73461,
      city: 'Middlesboro',
      state: 'KY',
    },
    40972: {
      lat: 37.24501,
      lng: -83.60692,
      city: 'Oneida',
      state: 'KY',
    },
    40977: {
      lat: 36.72332,
      lng: -83.72867,
      city: 'Pineville',
      state: 'KY',
    },
    40979: { lat: 37.00491, lng: -83.495, city: 'Roark', state: 'KY' },
    40981: { lat: 37.27792, lng: -83.48531, city: 'Saul', state: 'KY' },
    40982: {
      lat: 36.93241,
      lng: -83.69421,
      city: 'Scalf',
      state: 'KY',
    },
    40983: {
      lat: 37.34608,
      lng: -83.75339,
      city: 'Sextons Creek',
      state: 'KY',
    },
    40988: {
      lat: 36.85928,
      lng: -83.52838,
      city: 'Stoney Fork',
      state: 'KY',
    },
    40995: {
      lat: 36.75268,
      lng: -83.82322,
      city: 'Trosper',
      state: 'KY',
    },
    40997: {
      lat: 36.88348,
      lng: -83.67711,
      city: 'Walker',
      state: 'KY',
    },
    41001: {
      lat: 38.91409,
      lng: -84.40256,
      city: 'Alexandria',
      state: 'KY',
    },
    41002: {
      lat: 38.73002,
      lng: -83.98282,
      city: 'Augusta',
      state: 'KY',
    },
    41003: {
      lat: 38.53181,
      lng: -84.39164,
      city: 'Berry',
      state: 'KY',
    },
    41004: {
      lat: 38.65549,
      lng: -84.10134,
      city: 'Brooksville',
      state: 'KY',
    },
    41005: {
      lat: 39.00709,
      lng: -84.76541,
      city: 'Burlington',
      state: 'KY',
    },
    41006: {
      lat: 38.78475,
      lng: -84.34298,
      city: 'Butler',
      state: 'KY',
    },
    41007: {
      lat: 38.89289,
      lng: -84.30788,
      city: 'California',
      state: 'KY',
    },
    41008: {
      lat: 38.65648,
      lng: -85.16873,
      city: 'Carrollton',
      state: 'KY',
    },
    41010: {
      lat: 38.50085,
      lng: -84.60763,
      city: 'Corinth',
      state: 'KY',
    },
    41011: {
      lat: 39.06802,
      lng: -84.53065,
      city: 'Covington',
      state: 'KY',
    },
    41014: {
      lat: 39.0655,
      lng: -84.50464,
      city: 'Covington',
      state: 'KY',
    },
    41015: {
      lat: 38.97885,
      lng: -84.48456,
      city: 'Latonia',
      state: 'KY',
    },
    41016: {
      lat: 39.08701,
      lng: -84.5487,
      city: 'Covington',
      state: 'KY',
    },
    41017: {
      lat: 39.02893,
      lng: -84.56177,
      city: 'Ft Mitchell',
      state: 'KY',
    },
    41018: {
      lat: 39.01546,
      lng: -84.60282,
      city: 'Erlanger',
      state: 'KY',
    },
    41030: {
      lat: 38.78582,
      lng: -84.58754,
      city: 'Crittenden',
      state: 'KY',
    },
    41031: {
      lat: 38.41383,
      lng: -84.28631,
      city: 'Cynthiana',
      state: 'KY',
    },
    41033: {
      lat: 38.77131,
      lng: -84.46114,
      city: 'De Mossville',
      state: 'KY',
    },
    41034: {
      lat: 38.70104,
      lng: -83.90426,
      city: 'Dover',
      state: 'KY',
    },
    41035: {
      lat: 38.70322,
      lng: -84.66141,
      city: 'Dry Ridge',
      state: 'KY',
    },
    41039: {
      lat: 38.41381,
      lng: -83.88074,
      city: 'Ewing',
      state: 'KY',
    },
    41040: {
      lat: 38.65232,
      lng: -84.33188,
      city: 'Falmouth',
      state: 'KY',
    },
    41041: {
      lat: 38.40548,
      lng: -83.72338,
      city: 'Flemingsburg',
      state: 'KY',
    },
    41042: {
      lat: 38.98595,
      lng: -84.64906,
      city: 'Florence',
      state: 'KY',
    },
    41043: {
      lat: 38.75644,
      lng: -84.19832,
      city: 'Foster',
      state: 'KY',
    },
    41044: {
      lat: 38.60513,
      lng: -83.97265,
      city: 'Germantown',
      state: 'KY',
    },
    41045: {
      lat: 38.72262,
      lng: -85.02091,
      city: 'Ghent',
      state: 'KY',
    },
    41046: {
      lat: 38.70953,
      lng: -84.80637,
      city: 'Glencoe',
      state: 'KY',
    },
    41048: {
      lat: 39.08366,
      lng: -84.70585,
      city: 'Hebron',
      state: 'KY',
    },
    41049: {
      lat: 38.27465,
      lng: -83.64581,
      city: 'Hillsboro',
      state: 'KY',
    },
    41051: {
      lat: 38.93233,
      lng: -84.54451,
      city: 'Independence',
      state: 'KY',
    },
    41052: {
      lat: 38.65914,
      lng: -84.77777,
      city: 'Jonesville',
      state: 'KY',
    },
    41055: {
      lat: 38.51787,
      lng: -83.86995,
      city: 'Mayslick',
      state: 'KY',
    },
    41056: {
      lat: 38.59913,
      lng: -83.77576,
      city: 'Maysville',
      state: 'KY',
    },
    41059: {
      lat: 39.00504,
      lng: -84.35213,
      city: 'Melbourne',
      state: 'KY',
    },
    41063: {
      lat: 38.85323,
      lng: -84.49983,
      city: 'Morning View',
      state: 'KY',
    },
    41064: {
      lat: 38.51462,
      lng: -84.07106,
      city: 'Mount Olivet',
      state: 'KY',
    },
    41071: {
      lat: 39.0732,
      lng: -84.48211,
      city: 'Newport',
      state: 'KY',
    },
    41073: {
      lat: 39.10123,
      lng: -84.47748,
      city: 'Bellevue',
      state: 'KY',
    },
    41074: {
      lat: 39.11018,
      lng: -84.4607,
      city: 'Dayton',
      state: 'KY',
    },
    41075: {
      lat: 39.07838,
      lng: -84.45248,
      city: 'Fort Thomas',
      state: 'KY',
    },
    41076: {
      lat: 39.0178,
      lng: -84.44084,
      city: 'Newport',
      state: 'KY',
    },
    41080: {
      lat: 39.0493,
      lng: -84.82938,
      city: 'Petersburg',
      state: 'KY',
    },
    41083: {
      lat: 38.67513,
      lng: -84.98805,
      city: 'Sanders',
      state: 'KY',
    },
    41085: {
      lat: 39.03508,
      lng: -84.39378,
      city: 'Silver Grove',
      state: 'KY',
    },
    41086: {
      lat: 38.69219,
      lng: -84.88241,
      city: 'Sparta',
      state: 'KY',
    },
    41091: { lat: 38.92056, lng: -84.7379, city: 'Union', state: 'KY' },
    41092: {
      lat: 38.81811,
      lng: -84.70115,
      city: 'Verona',
      state: 'KY',
    },
    41093: {
      lat: 38.39441,
      lng: -83.56269,
      city: 'Wallingford',
      state: 'KY',
    },
    41094: {
      lat: 38.88213,
      lng: -84.62292,
      city: 'Walton',
      state: 'KY',
    },
    41095: {
      lat: 38.79033,
      lng: -84.81567,
      city: 'Warsaw',
      state: 'KY',
    },
    41097: {
      lat: 38.62088,
      lng: -84.58033,
      city: 'Williamstown',
      state: 'KY',
    },
    41098: {
      lat: 38.59553,
      lng: -84.99513,
      city: 'Worthville',
      state: 'KY',
    },
    41101: {
      lat: 38.47392,
      lng: -82.65045,
      city: 'Ashland',
      state: 'KY',
    },
    41102: {
      lat: 38.42045,
      lng: -82.72425,
      city: 'Ashland',
      state: 'KY',
    },
    41121: {
      lat: 38.44911,
      lng: -82.83032,
      city: 'Argillite',
      state: 'KY',
    },
    41124: {
      lat: 38.02148,
      lng: -82.85118,
      city: 'Blaine',
      state: 'KY',
    },
    41129: {
      lat: 38.31755,
      lng: -82.64073,
      city: 'Catlettsburg',
      state: 'KY',
    },
    41132: {
      lat: 38.25667,
      lng: -82.8314,
      city: 'Denton',
      state: 'KY',
    },
    41135: {
      lat: 38.36026,
      lng: -83.28796,
      city: 'Emerson',
      state: 'KY',
    },
    41139: {
      lat: 38.50927,
      lng: -82.73288,
      city: 'Flatwoods',
      state: 'KY',
    },
    41141: {
      lat: 38.54381,
      lng: -83.13357,
      city: 'Garrison',
      state: 'KY',
    },
    41142: {
      lat: 38.27774,
      lng: -83.07308,
      city: 'Grahn',
      state: 'KY',
    },
    41143: {
      lat: 38.33916,
      lng: -82.97704,
      city: 'Grayson',
      state: 'KY',
    },
    41144: {
      lat: 38.53461,
      lng: -82.91208,
      city: 'Greenup',
      state: 'KY',
    },
    41146: {
      lat: 38.27701,
      lng: -82.88969,
      city: 'Hitchins',
      state: 'KY',
    },
    41149: {
      lat: 38.06008,
      lng: -83.05438,
      city: 'Isonville',
      state: 'KY',
    },
    41159: {
      lat: 38.01305,
      lng: -82.96993,
      city: 'Martha',
      state: 'KY',
    },
    41164: {
      lat: 38.28914,
      lng: -83.16231,
      city: 'Olive Hill',
      state: 'KY',
    },
    41166: {
      lat: 38.63464,
      lng: -83.09328,
      city: 'Quincy',
      state: 'KY',
    },
    41168: { lat: 38.30732, lng: -82.76992, city: 'Rush', state: 'KY' },
    41169: {
      lat: 38.52232,
      lng: -82.71316,
      city: 'Russell',
      state: 'KY',
    },
    41171: {
      lat: 38.09336,
      lng: -83.08901,
      city: 'Sandy Hook',
      state: 'KY',
    },
    41174: {
      lat: 38.69841,
      lng: -83.0139,
      city: 'South Portsmouth',
      state: 'KY',
    },
    41175: {
      lat: 38.64574,
      lng: -82.97791,
      city: 'South Shore',
      state: 'KY',
    },
    41179: {
      lat: 38.52483,
      lng: -83.38988,
      city: 'Vanceburg',
      state: 'KY',
    },
    41180: {
      lat: 38.13358,
      lng: -82.85999,
      city: 'Webbville',
      state: 'KY',
    },
    41181: {
      lat: 38.20758,
      lng: -82.87425,
      city: 'Willard',
      state: 'KY',
    },
    41183: {
      lat: 38.55,
      lng: -82.73707,
      city: 'Worthington',
      state: 'KY',
    },
    41189: {
      lat: 38.54493,
      lng: -83.56175,
      city: 'Tollesboro',
      state: 'KY',
    },
    41201: { lat: 38.06201, lng: -82.7576, city: 'Adams', state: 'KY' },
    41203: {
      lat: 37.85241,
      lng: -82.46112,
      city: 'Beauty',
      state: 'KY',
    },
    41204: {
      lat: 37.81784,
      lng: -82.6843,
      city: 'Boons Camp',
      state: 'KY',
    },
    41214: {
      lat: 37.79448,
      lng: -82.59839,
      city: 'Debord',
      state: 'KY',
    },
    41216: {
      lat: 37.73016,
      lng: -82.81308,
      city: 'East Point',
      state: 'KY',
    },
    41219: {
      lat: 37.92586,
      lng: -82.90882,
      city: 'Flatgap',
      state: 'KY',
    },
    41222: {
      lat: 37.77036,
      lng: -82.85032,
      city: 'Hagerhill',
      state: 'KY',
    },
    41224: { lat: 37.82089, lng: -82.53936, city: 'Inez', state: 'KY' },
    41226: {
      lat: 37.97853,
      lng: -82.9562,
      city: 'Keaton',
      state: 'KY',
    },
    41230: {
      lat: 38.0656,
      lng: -82.66196,
      city: 'Louisa',
      state: 'KY',
    },
    41231: {
      lat: 37.79447,
      lng: -82.38903,
      city: 'Lovely',
      state: 'KY',
    },
    41232: {
      lat: 37.93602,
      lng: -82.74007,
      city: 'Lowmansville',
      state: 'KY',
    },
    41234: {
      lat: 37.79654,
      lng: -82.74161,
      city: 'Meally',
      state: 'KY',
    },
    41238: {
      lat: 37.83943,
      lng: -82.95072,
      city: 'Oil Springs',
      state: 'KY',
    },
    41240: {
      lat: 37.82103,
      lng: -82.78927,
      city: 'Paintsville',
      state: 'KY',
    },
    41250: {
      lat: 37.73511,
      lng: -82.46079,
      city: 'Pilgrim',
      state: 'KY',
    },
    41254: { lat: 37.8845, lng: -82.72711, city: 'River', state: 'KY' },
    41255: {
      lat: 37.91014,
      lng: -82.83765,
      city: 'Sitka',
      state: 'KY',
    },
    41256: {
      lat: 37.82446,
      lng: -82.88135,
      city: 'Staffordsville',
      state: 'KY',
    },
    41257: {
      lat: 37.91372,
      lng: -82.8044,
      city: 'Stambaugh',
      state: 'KY',
    },
    41260: {
      lat: 37.82253,
      lng: -82.75126,
      city: 'Thelma',
      state: 'KY',
    },
    41262: {
      lat: 37.85112,
      lng: -82.61377,
      city: 'Tomahawk',
      state: 'KY',
    },
    41263: {
      lat: 37.85717,
      lng: -82.76256,
      city: 'Tutor Key',
      state: 'KY',
    },
    41264: {
      lat: 37.94021,
      lng: -82.677,
      city: 'Ulysses',
      state: 'KY',
    },
    41265: {
      lat: 37.75424,
      lng: -82.70446,
      city: 'Van Lear',
      state: 'KY',
    },
    41267: {
      lat: 37.87514,
      lng: -82.43591,
      city: 'Warfield',
      state: 'KY',
    },
    41268: {
      lat: 37.78797,
      lng: -82.78433,
      city: 'West Van Lear',
      state: 'KY',
    },
    41271: {
      lat: 37.82773,
      lng: -82.72453,
      city: 'Williamsport',
      state: 'KY',
    },
    41274: {
      lat: 37.86174,
      lng: -82.81164,
      city: 'Wittensville',
      state: 'KY',
    },
    41301: {
      lat: 37.72121,
      lng: -83.48941,
      city: 'Campton',
      state: 'KY',
    },
    41310: { lat: 37.64068, lng: -83.25147, city: 'Bays', state: 'KY' },
    41311: {
      lat: 37.57896,
      lng: -83.72163,
      city: 'Beattyville',
      state: 'KY',
    },
    41314: {
      lat: 37.44008,
      lng: -83.63743,
      city: 'Booneville',
      state: 'KY',
    },
    41317: {
      lat: 37.46029,
      lng: -83.19851,
      city: 'Clayhole',
      state: 'KY',
    },
    41332: {
      lat: 37.80029,
      lng: -83.37575,
      city: 'Hazel Green',
      state: 'KY',
    },
    41339: {
      lat: 37.53158,
      lng: -83.29231,
      city: 'Jackson',
      state: 'KY',
    },
    41348: {
      lat: 37.42577,
      lng: -83.31387,
      city: 'Lost Creek',
      state: 'KY',
    },
    41351: {
      lat: 37.32754,
      lng: -83.59363,
      city: 'Mistletoe',
      state: 'KY',
    },
    41352: { lat: 37.82357, lng: -83.33511, city: 'Mize', state: 'KY' },
    41360: {
      lat: 37.78889,
      lng: -83.6264,
      city: 'Pine Ridge',
      state: 'KY',
    },
    41364: {
      lat: 37.37569,
      lng: -83.64172,
      city: 'Ricetown',
      state: 'KY',
    },
    41365: {
      lat: 37.71117,
      lng: -83.62663,
      city: 'Rogers',
      state: 'KY',
    },
    41366: {
      lat: 37.62472,
      lng: -83.21959,
      city: 'Rousseau',
      state: 'KY',
    },
    41367: { lat: 37.4045, lng: -83.23218, city: 'Rowdy', state: 'KY' },
    41385: {
      lat: 37.64998,
      lng: -83.33665,
      city: 'Vancleve',
      state: 'KY',
    },
    41386: {
      lat: 37.47992,
      lng: -83.79273,
      city: 'Vincent',
      state: 'KY',
    },
    41390: { lat: 37.41872, lng: -83.3792, city: 'Whick', state: 'KY' },
    41397: { lat: 37.66683, lng: -83.67709, city: 'Zoe', state: 'KY' },
    41408: {
      lat: 37.79508,
      lng: -83.28828,
      city: 'Cannel City',
      state: 'KY',
    },
    41421: {
      lat: 37.99027,
      lng: -83.17397,
      city: 'Elkfork',
      state: 'KY',
    },
    41425: { lat: 37.88407, lng: -83.42761, city: 'Ezel', state: 'KY' },
    41464: {
      lat: 37.65974,
      lng: -82.97201,
      city: 'Royalton',
      state: 'KY',
    },
    41465: {
      lat: 37.72985,
      lng: -83.08494,
      city: 'Salyersville',
      state: 'KY',
    },
    41472: {
      lat: 37.94179,
      lng: -83.22334,
      city: 'West Liberty',
      state: 'KY',
    },
    41501: {
      lat: 37.50805,
      lng: -82.5154,
      city: 'Pikeville',
      state: 'KY',
    },
    41503: {
      lat: 37.66079,
      lng: -82.28446,
      city: 'South Williamson',
      state: 'KY',
    },
    41512: {
      lat: 37.25251,
      lng: -82.47772,
      city: 'Ashcamp',
      state: 'KY',
    },
    41513: {
      lat: 37.35282,
      lng: -82.34329,
      city: 'Belcher',
      state: 'KY',
    },
    41514: {
      lat: 37.67135,
      lng: -82.33169,
      city: 'Belfry',
      state: 'KY',
    },
    41517: {
      lat: 37.18919,
      lng: -82.60026,
      city: 'Burdine',
      state: 'KY',
    },
    41519: {
      lat: 37.58359,
      lng: -82.32334,
      city: 'Canada',
      state: 'KY',
    },
    41522: {
      lat: 37.31644,
      lng: -82.40088,
      city: 'Elkhorn City',
      state: 'KY',
    },
    41524: {
      lat: 37.43345,
      lng: -82.23444,
      city: 'Fedscreek',
      state: 'KY',
    },
    41526: {
      lat: 37.43372,
      lng: -82.51256,
      city: 'Fords Branch',
      state: 'KY',
    },
    41527: {
      lat: 37.63187,
      lng: -82.29378,
      city: 'Forest Hills',
      state: 'KY',
    },
    41528: {
      lat: 37.55458,
      lng: -82.13984,
      city: 'Freeburn',
      state: 'KY',
    },
    41531: {
      lat: 37.59509,
      lng: -82.23131,
      city: 'Hardy',
      state: 'KY',
    },
    41534: {
      lat: 37.27748,
      lng: -82.49644,
      city: 'Hellier',
      state: 'KY',
    },
    41535: {
      lat: 37.59368,
      lng: -82.28422,
      city: 'Huddy',
      state: 'KY',
    },
    41537: {
      lat: 37.22221,
      lng: -82.61619,
      city: 'Jenkins',
      state: 'KY',
    },
    41538: {
      lat: 37.30338,
      lng: -82.58891,
      city: 'Jonancy',
      state: 'KY',
    },
    41539: {
      lat: 37.50265,
      lng: -82.32231,
      city: 'Kimper',
      state: 'KY',
    },
    41540: {
      lat: 37.39013,
      lng: -82.32318,
      city: 'Lick Creek',
      state: 'KY',
    },
    41543: {
      lat: 37.54837,
      lng: -82.28226,
      city: 'McAndrews',
      state: 'KY',
    },
    41544: {
      lat: 37.59602,
      lng: -82.17368,
      city: 'McCarr',
      state: 'KY',
    },
    41547: {
      lat: 37.53821,
      lng: -82.09437,
      city: 'Majestic',
      state: 'KY',
    },
    41548: {
      lat: 37.37847,
      lng: -82.25372,
      city: 'Mouthcard',
      state: 'KY',
    },
    41553: {
      lat: 37.48057,
      lng: -82.16025,
      city: 'Phelps',
      state: 'KY',
    },
    41554: {
      lat: 37.43919,
      lng: -82.32446,
      city: 'Phyllis',
      state: 'KY',
    },
    41555: {
      lat: 37.53816,
      lng: -82.2589,
      city: 'Pinsonfork',
      state: 'KY',
    },
    41557: {
      lat: 37.48189,
      lng: -82.41548,
      city: 'Raccoon',
      state: 'KY',
    },
    41558: {
      lat: 37.53953,
      lng: -82.20819,
      city: 'Ransom',
      state: 'KY',
    },
    41559: {
      lat: 37.37997,
      lng: -82.39017,
      city: 'Regina',
      state: 'KY',
    },
    41560: {
      lat: 37.39423,
      lng: -82.57875,
      city: 'Robinson Creek',
      state: 'KY',
    },
    41562: {
      lat: 37.3991,
      lng: -82.47052,
      city: 'Shelbiana',
      state: 'KY',
    },
    41563: {
      lat: 37.22628,
      lng: -82.53694,
      city: 'Shelby Gap',
      state: 'KY',
    },
    41564: {
      lat: 37.60476,
      lng: -82.3667,
      city: 'Sidney',
      state: 'KY',
    },
    41566: {
      lat: 37.40836,
      lng: -82.1922,
      city: 'Steele',
      state: 'KY',
    },
    41567: {
      lat: 37.56897,
      lng: -82.28621,
      city: 'Stone',
      state: 'KY',
    },
    41568: {
      lat: 37.50369,
      lng: -82.05896,
      city: 'Stopover',
      state: 'KY',
    },
    41571: {
      lat: 37.62651,
      lng: -82.44437,
      city: 'Varney',
      state: 'KY',
    },
    41572: {
      lat: 37.31342,
      lng: -82.64368,
      city: 'Virgie',
      state: 'KY',
    },
    41601: {
      lat: 37.60969,
      lng: -82.72037,
      city: 'Allen',
      state: 'KY',
    },
    41602: {
      lat: 37.7387,
      lng: -82.75249,
      city: 'Auxier',
      state: 'KY',
    },
    41603: {
      lat: 37.55934,
      lng: -82.68704,
      city: 'Banner',
      state: 'KY',
    },
    41604: {
      lat: 37.38986,
      lng: -82.66782,
      city: 'Beaver',
      state: 'KY',
    },
    41605: {
      lat: 37.55498,
      lng: -82.63009,
      city: 'Betsy Layne',
      state: 'KY',
    },
    41606: {
      lat: 37.34594,
      lng: -82.7375,
      city: 'Bevinsville',
      state: 'KY',
    },
    41607: {
      lat: 37.62216,
      lng: -82.8438,
      city: 'Blue River',
      state: 'KY',
    },
    41612: {
      lat: 37.35451,
      lng: -82.72233,
      city: 'Bypro',
      state: 'KY',
    },
    41615: { lat: 37.54211, lng: -82.68337, city: 'Dana', state: 'KY' },
    41616: {
      lat: 37.58522,
      lng: -82.87847,
      city: 'David',
      state: 'KY',
    },
    41619: {
      lat: 37.48328,
      lng: -82.74187,
      city: 'Drift',
      state: 'KY',
    },
    41621: { lat: 37.6269, lng: -82.72663, city: 'Dwale', state: 'KY' },
    41622: {
      lat: 37.51377,
      lng: -82.80925,
      city: 'Eastern',
      state: 'KY',
    },
    41630: {
      lat: 37.46416,
      lng: -82.84992,
      city: 'Garrett',
      state: 'KY',
    },
    41631: {
      lat: 37.46801,
      lng: -82.65365,
      city: 'Grethel',
      state: 'KY',
    },
    41632: {
      lat: 37.55029,
      lng: -82.94982,
      city: 'Gunlock',
      state: 'KY',
    },
    41635: {
      lat: 37.48009,
      lng: -82.62831,
      city: 'Harold',
      state: 'KY',
    },
    41636: {
      lat: 37.39772,
      lng: -82.73087,
      city: 'Hi Hat',
      state: 'KY',
    },
    41640: {
      lat: 37.47649,
      lng: -82.92643,
      city: 'Hueysville',
      state: 'KY',
    },
    41642: { lat: 37.59569, lng: -82.64457, city: 'Ivel', state: 'KY' },
    41643: {
      lat: 37.43778,
      lng: -82.84307,
      city: 'Lackey',
      state: 'KY',
    },
    41645: {
      lat: 37.52872,
      lng: -82.79294,
      city: 'Langley',
      state: 'KY',
    },
    41647: {
      lat: 37.43056,
      lng: -82.71737,
      city: 'McDowell',
      state: 'KY',
    },
    41649: {
      lat: 37.57139,
      lng: -82.78337,
      city: 'Martin',
      state: 'KY',
    },
    41650: {
      lat: 37.34722,
      lng: -82.68166,
      city: 'Melvin',
      state: 'KY',
    },
    41651: {
      lat: 37.46716,
      lng: -82.75527,
      city: 'Minnie',
      state: 'KY',
    },
    41653: {
      lat: 37.6603,
      lng: -82.76466,
      city: 'Prestonsburg',
      state: 'KY',
    },
    41655: {
      lat: 37.50496,
      lng: -82.72465,
      city: 'Printer',
      state: 'KY',
    },
    41659: {
      lat: 37.58415,
      lng: -82.60064,
      city: 'Stanville',
      state: 'KY',
    },
    41660: {
      lat: 37.41341,
      lng: -82.63846,
      city: 'Teaberry',
      state: 'KY',
    },
    41663: { lat: 37.56844, lng: -82.65966, city: 'Tram', state: 'KY' },
    41666: {
      lat: 37.4396,
      lng: -82.80621,
      city: 'Wayland',
      state: 'KY',
    },
    41667: {
      lat: 37.31138,
      lng: -82.69881,
      city: 'Weeksbury',
      state: 'KY',
    },
    41669: {
      lat: 37.33375,
      lng: -82.71469,
      city: 'Wheelwright',
      state: 'KY',
    },
    41701: {
      lat: 37.29778,
      lng: -83.18781,
      city: 'Hazard',
      state: 'KY',
    },
    41712: { lat: 37.3744, lng: -83.13751, city: 'Ary', state: 'KY' },
    41713: {
      lat: 37.22703,
      lng: -83.27799,
      city: 'Avawam',
      state: 'KY',
    },
    41714: {
      lat: 37.16519,
      lng: -83.5075,
      city: 'Bear Branch',
      state: 'KY',
    },
    41719: {
      lat: 37.29727,
      lng: -83.24526,
      city: 'Bonnyman',
      state: 'KY',
    },
    41721: {
      lat: 37.31371,
      lng: -83.49949,
      city: 'Buckhorn',
      state: 'KY',
    },
    41722: {
      lat: 37.40441,
      lng: -83.10329,
      city: 'Bulan',
      state: 'KY',
    },
    41723: { lat: 37.25469, lng: -83.31409, city: 'Busy', state: 'KY' },
    41725: {
      lat: 37.31667,
      lng: -83.03244,
      city: 'Carrie',
      state: 'KY',
    },
    41727: {
      lat: 37.35624,
      lng: -83.32352,
      city: 'Chavies',
      state: 'KY',
    },
    41729: { lat: 37.2681, lng: -83.2159, city: 'Combs', state: 'KY' },
    41731: {
      lat: 37.10312,
      lng: -83.08393,
      city: 'Cornettsville',
      state: 'KY',
    },
    41735: {
      lat: 37.01793,
      lng: -83.09963,
      city: 'Delphia',
      state: 'KY',
    },
    41739: {
      lat: 37.32596,
      lng: -83.12802,
      city: 'Dwarf',
      state: 'KY',
    },
    41740: {
      lat: 37.35649,
      lng: -83.0467,
      city: 'Emmalena',
      state: 'KY',
    },
    41745: {
      lat: 37.34449,
      lng: -83.42848,
      city: 'Gays Creek',
      state: 'KY',
    },
    41746: {
      lat: 37.21574,
      lng: -83.09779,
      city: 'Happy',
      state: 'KY',
    },
    41749: { lat: 37.19364, lng: -83.4199, city: 'Hyden', state: 'KY' },
    41751: { lat: 37.2196, lng: -83.12659, city: 'Jeff', state: 'KY' },
    41754: {
      lat: 37.30025,
      lng: -83.32459,
      city: 'Krypton',
      state: 'KY',
    },
    41759: {
      lat: 37.23211,
      lng: -83.02521,
      city: 'Sassafras',
      state: 'KY',
    },
    41760: {
      lat: 37.19456,
      lng: -83.08588,
      city: 'Scuddy',
      state: 'KY',
    },
    41762: {
      lat: 37.20742,
      lng: -83.50077,
      city: 'Sizerock',
      state: 'KY',
    },
    41763: {
      lat: 37.05439,
      lng: -83.12157,
      city: 'Slemp',
      state: 'KY',
    },
    41764: {
      lat: 37.12924,
      lng: -83.25545,
      city: 'Smilax',
      state: 'KY',
    },
    41766: {
      lat: 37.18526,
      lng: -83.44443,
      city: 'Thousandsticks',
      state: 'KY',
    },
    41772: { lat: 37.4075, lng: -83.01679, city: 'Vest', state: 'KY' },
    41773: {
      lat: 37.23534,
      lng: -83.01721,
      city: 'Vicco',
      state: 'KY',
    },
    41774: {
      lat: 37.15512,
      lng: -83.13847,
      city: 'Viper',
      state: 'KY',
    },
    41775: {
      lat: 37.09373,
      lng: -83.34207,
      city: 'Wendover',
      state: 'KY',
    },
    41776: {
      lat: 37.15933,
      lng: -83.28158,
      city: 'Wooton',
      state: 'KY',
    },
    41777: {
      lat: 37.06022,
      lng: -83.23276,
      city: 'Yeaddiss',
      state: 'KY',
    },
    41778: {
      lat: 37.26612,
      lng: -83.3223,
      city: 'Yerkes',
      state: 'KY',
    },
    41804: {
      lat: 37.14923,
      lng: -82.98993,
      city: 'Blackey',
      state: 'KY',
    },
    41810: {
      lat: 37.18492,
      lng: -82.67278,
      city: 'Cromona',
      state: 'KY',
    },
    41812: { lat: 37.2473, lng: -82.74445, city: 'Deane', state: 'KY' },
    41815: {
      lat: 37.15821,
      lng: -82.79799,
      city: 'Ermine',
      state: 'KY',
    },
    41817: {
      lat: 37.35922,
      lng: -82.90038,
      city: 'Garner',
      state: 'KY',
    },
    41819: {
      lat: 36.99191,
      lng: -83.07788,
      city: 'Gordon',
      state: 'KY',
    },
    41821: {
      lat: 37.07563,
      lng: -83.00711,
      city: 'Hallie',
      state: 'KY',
    },
    41822: {
      lat: 37.31991,
      lng: -82.97848,
      city: 'Hindman',
      state: 'KY',
    },
    41824: { lat: 37.18771, lng: -82.88015, city: 'Isom', state: 'KY' },
    41825: {
      lat: 37.23323,
      lng: -82.70854,
      city: 'Jackhorn',
      state: 'KY',
    },
    41826: {
      lat: 37.17348,
      lng: -82.92115,
      city: 'Jeremiah',
      state: 'KY',
    },
    41828: { lat: 37.2971, lng: -82.78444, city: 'Kite', state: 'KY' },
    41831: {
      lat: 37.38531,
      lng: -82.94633,
      city: 'Leburn',
      state: 'KY',
    },
    41832: {
      lat: 37.15286,
      lng: -82.9548,
      city: 'Letcher',
      state: 'KY',
    },
    41833: {
      lat: 37.02919,
      lng: -82.97323,
      city: 'Linefork',
      state: 'KY',
    },
    41834: {
      lat: 37.26585,
      lng: -82.94661,
      city: 'Littcarr',
      state: 'KY',
    },
    41835: {
      lat: 37.21196,
      lng: -82.67114,
      city: 'McRoberts',
      state: 'KY',
    },
    41836: {
      lat: 37.25839,
      lng: -82.90887,
      city: 'Mallie',
      state: 'KY',
    },
    41837: {
      lat: 37.1215,
      lng: -82.75231,
      city: 'Mayking',
      state: 'KY',
    },
    41838: {
      lat: 37.19677,
      lng: -82.75144,
      city: 'Millstone',
      state: 'KY',
    },
    41839: {
      lat: 37.42041,
      lng: -82.87741,
      city: 'Mousie',
      state: 'KY',
    },
    41840: { lat: 37.19962, lng: -82.71813, city: 'Neon', state: 'KY' },
    41843: {
      lat: 37.27495,
      lng: -82.85938,
      city: 'Pine Top',
      state: 'KY',
    },
    41844: {
      lat: 37.32916,
      lng: -82.8643,
      city: 'Pippa Passes',
      state: 'KY',
    },
    41845: {
      lat: 37.1396,
      lng: -82.90301,
      city: 'Premium',
      state: 'KY',
    },
    41847: {
      lat: 37.20823,
      lng: -82.95961,
      city: 'Redfox',
      state: 'KY',
    },
    41848: {
      lat: 37.11491,
      lng: -82.94384,
      city: 'Roxana',
      state: 'KY',
    },
    41849: { lat: 37.1744, lng: -82.73251, city: 'Seco', state: 'KY' },
    41855: {
      lat: 37.18874,
      lng: -82.78496,
      city: 'Thornton',
      state: 'KY',
    },
    41858: {
      lat: 37.1454,
      lng: -82.84697,
      city: 'Whitesburg',
      state: 'KY',
    },
    41859: { lat: 37.39922, lng: -82.77606, city: 'Dema', state: 'KY' },
    41861: { lat: 37.39002, lng: -82.8264, city: 'Raven', state: 'KY' },
    41862: {
      lat: 37.34971,
      lng: -82.78929,
      city: 'Topmost',
      state: 'KY',
    },
    42001: {
      lat: 37.03317,
      lng: -88.7135,
      city: 'Paducah',
      state: 'KY',
    },
    42003: {
      lat: 37.00653,
      lng: -88.58472,
      city: 'Paducah',
      state: 'KY',
    },
    42020: { lat: 36.68913, lng: -88.29145, city: 'Almo', state: 'KY' },
    42021: {
      lat: 36.80129,
      lng: -88.9957,
      city: 'Arlington',
      state: 'KY',
    },
    42022: {
      lat: 37.14593,
      lng: -88.94553,
      city: 'Bandana',
      state: 'KY',
    },
    42023: {
      lat: 36.87623,
      lng: -88.98742,
      city: 'Bardwell',
      state: 'KY',
    },
    42024: {
      lat: 37.0742,
      lng: -89.05026,
      city: 'Barlow',
      state: 'KY',
    },
    42025: {
      lat: 36.85764,
      lng: -88.33441,
      city: 'Benton',
      state: 'KY',
    },
    42027: { lat: 36.91585, lng: -88.63018, city: 'Boaz', state: 'KY' },
    42028: {
      lat: 37.23637,
      lng: -88.33946,
      city: 'Burna',
      state: 'KY',
    },
    42029: {
      lat: 37.00963,
      lng: -88.38947,
      city: 'Calvert City',
      state: 'KY',
    },
    42031: {
      lat: 36.68741,
      lng: -88.98864,
      city: 'Clinton',
      state: 'KY',
    },
    42032: {
      lat: 36.751,
      lng: -89.10378,
      city: 'Columbus',
      state: 'KY',
    },
    42035: {
      lat: 36.90392,
      lng: -88.83674,
      city: 'Cunningham',
      state: 'KY',
    },
    42036: {
      lat: 36.72246,
      lng: -88.23048,
      city: 'Dexter',
      state: 'KY',
    },
    42037: {
      lat: 37.15843,
      lng: -88.18441,
      city: 'Dycusburg',
      state: 'KY',
    },
    42038: {
      lat: 37.04137,
      lng: -88.02364,
      city: 'Eddyville',
      state: 'KY',
    },
    42039: {
      lat: 36.78535,
      lng: -88.82809,
      city: 'Fancy Farm',
      state: 'KY',
    },
    42040: {
      lat: 36.62454,
      lng: -88.50371,
      city: 'Farmington',
      state: 'KY',
    },
    42041: {
      lat: 36.54593,
      lng: -88.87456,
      city: 'Fulton',
      state: 'KY',
    },
    42044: {
      lat: 36.95744,
      lng: -88.26417,
      city: 'Gilbertsville',
      state: 'KY',
    },
    42045: {
      lat: 37.072,
      lng: -88.26453,
      city: 'Grand Rivers',
      state: 'KY',
    },
    42047: {
      lat: 37.30061,
      lng: -88.39046,
      city: 'Hampton',
      state: 'KY',
    },
    42048: {
      lat: 36.76676,
      lng: -88.23533,
      city: 'Hardin',
      state: 'KY',
    },
    42049: {
      lat: 36.51874,
      lng: -88.31828,
      city: 'Hazel',
      state: 'KY',
    },
    42050: {
      lat: 36.54675,
      lng: -89.20347,
      city: 'Hickman',
      state: 'KY',
    },
    42051: {
      lat: 36.85629,
      lng: -88.64522,
      city: 'Hickory',
      state: 'KY',
    },
    42053: { lat: 37.1033, lng: -88.87606, city: 'Kevil', state: 'KY' },
    42054: {
      lat: 36.71734,
      lng: -88.43149,
      city: 'Kirksey',
      state: 'KY',
    },
    42055: {
      lat: 37.06959,
      lng: -88.16765,
      city: 'Kuttawa',
      state: 'KY',
    },
    42056: {
      lat: 37.11088,
      lng: -88.98452,
      city: 'La Center',
      state: 'KY',
    },
    42058: {
      lat: 37.05329,
      lng: -88.4714,
      city: 'Ledbetter',
      state: 'KY',
    },
    42060: {
      lat: 36.96385,
      lng: -88.82778,
      city: 'Lovelaceville',
      state: 'KY',
    },
    42061: {
      lat: 36.88262,
      lng: -88.76959,
      city: 'Lowes',
      state: 'KY',
    },
    42064: {
      lat: 37.35022,
      lng: -88.09158,
      city: 'Marion',
      state: 'KY',
    },
    42066: {
      lat: 36.73328,
      lng: -88.6403,
      city: 'Mayfield',
      state: 'KY',
    },
    42069: {
      lat: 36.92328,
      lng: -88.75828,
      city: 'Melber',
      state: 'KY',
    },
    42071: {
      lat: 36.62139,
      lng: -88.28291,
      city: 'Murray',
      state: 'KY',
    },
    42076: {
      lat: 36.55906,
      lng: -88.09473,
      city: 'New Concord',
      state: 'KY',
    },
    42078: {
      lat: 37.28876,
      lng: -88.27202,
      city: 'Salem',
      state: 'KY',
    },
    42079: {
      lat: 36.55978,
      lng: -88.57611,
      city: 'Sedalia',
      state: 'KY',
    },
    42081: {
      lat: 37.22821,
      lng: -88.40274,
      city: 'Smithland',
      state: 'KY',
    },
    42082: {
      lat: 36.91618,
      lng: -88.50092,
      city: 'Symsonia',
      state: 'KY',
    },
    42083: {
      lat: 37.16381,
      lng: -88.27767,
      city: 'Tiline',
      state: 'KY',
    },
    42085: {
      lat: 36.56942,
      lng: -88.80044,
      city: 'Water Valley',
      state: 'KY',
    },
    42086: {
      lat: 37.0828,
      lng: -88.76838,
      city: 'West Paducah',
      state: 'KY',
    },
    42087: {
      lat: 36.97328,
      lng: -89.00323,
      city: 'Wickliffe',
      state: 'KY',
    },
    42088: {
      lat: 36.61808,
      lng: -88.75409,
      city: 'Wingo',
      state: 'KY',
    },
    42101: {
      lat: 37.06552,
      lng: -86.47809,
      city: 'Bowling Green',
      state: 'KY',
    },
    42103: {
      lat: 36.94802,
      lng: -86.33396,
      city: 'Bowling Green',
      state: 'KY',
    },
    42104: {
      lat: 36.87718,
      lng: -86.45233,
      city: 'Bowling Green',
      state: 'KY',
    },
    42120: {
      lat: 36.67196,
      lng: -86.27465,
      city: 'Adolphus',
      state: 'KY',
    },
    42122: {
      lat: 36.85885,
      lng: -86.35718,
      city: 'Alvaton',
      state: 'KY',
    },
    42123: {
      lat: 36.82039,
      lng: -85.99573,
      city: 'Austin',
      state: 'KY',
    },
    42124: {
      lat: 36.86534,
      lng: -85.64817,
      city: 'Beaumont',
      state: 'KY',
    },
    42127: {
      lat: 37.12167,
      lng: -85.92746,
      city: 'Cave City',
      state: 'KY',
    },
    42129: {
      lat: 36.99,
      lng: -85.58992,
      city: 'Edmonton',
      state: 'KY',
    },
    42130: {
      lat: 36.9224,
      lng: -85.77816,
      city: 'Eighty Eight',
      state: 'KY',
    },
    42131: {
      lat: 36.82734,
      lng: -85.90915,
      city: 'Etoile',
      state: 'KY',
    },
    42133: {
      lat: 36.72248,
      lng: -85.95468,
      city: 'Fountain Run',
      state: 'KY',
    },
    42134: {
      lat: 36.72626,
      lng: -86.56433,
      city: 'Franklin',
      state: 'KY',
    },
    42140: {
      lat: 36.65549,
      lng: -85.83108,
      city: 'Gamaliel',
      state: 'KY',
    },
    42141: {
      lat: 36.94883,
      lng: -85.91489,
      city: 'Glasgow',
      state: 'KY',
    },
    42151: {
      lat: 36.64848,
      lng: -85.56058,
      city: 'Hestand',
      state: 'KY',
    },
    42153: {
      lat: 36.68006,
      lng: -86.04234,
      city: 'Holland',
      state: 'KY',
    },
    42154: {
      lat: 37.04876,
      lng: -85.72421,
      city: 'Knob Lick',
      state: 'KY',
    },
    42156: {
      lat: 36.85947,
      lng: -86.04952,
      city: 'Lucas',
      state: 'KY',
    },
    42157: {
      lat: 36.78872,
      lng: -85.8187,
      city: 'Mount Hermon',
      state: 'KY',
    },
    42159: {
      lat: 36.99594,
      lng: -86.24771,
      city: 'Oakland',
      state: 'KY',
    },
    42160: {
      lat: 37.10339,
      lng: -86.08035,
      city: 'Park City',
      state: 'KY',
    },
    42163: {
      lat: 37.07968,
      lng: -86.13466,
      city: 'Rocky Hill',
      state: 'KY',
    },
    42164: {
      lat: 36.7704,
      lng: -86.18096,
      city: 'Scottsville',
      state: 'KY',
    },
    42166: {
      lat: 36.87768,
      lng: -85.71503,
      city: 'Summer Shade',
      state: 'KY',
    },
    42167: {
      lat: 36.72432,
      lng: -85.66998,
      city: 'Tompkinsville',
      state: 'KY',
    },
    42170: {
      lat: 36.84678,
      lng: -86.57459,
      city: 'Woodburn',
      state: 'KY',
    },
    42171: {
      lat: 37.03609,
      lng: -86.16728,
      city: 'Smiths Grove',
      state: 'KY',
    },
    42202: {
      lat: 36.68732,
      lng: -86.85916,
      city: 'Adairville',
      state: 'KY',
    },
    42204: {
      lat: 36.69509,
      lng: -87.08096,
      city: 'Allensville',
      state: 'KY',
    },
    42206: {
      lat: 36.88221,
      lng: -86.72302,
      city: 'Auburn',
      state: 'KY',
    },
    42207: {
      lat: 37.30357,
      lng: -86.27858,
      city: 'Bee Spring',
      state: 'KY',
    },
    42210: {
      lat: 37.21724,
      lng: -86.31438,
      city: 'Brownsville',
      state: 'KY',
    },
    42211: {
      lat: 36.82016,
      lng: -87.83709,
      city: 'Cadiz',
      state: 'KY',
    },
    42214: {
      lat: 37.13428,
      lng: -85.68822,
      city: 'Center',
      state: 'KY',
    },
    42215: {
      lat: 36.99256,
      lng: -87.67018,
      city: 'Cerulean',
      state: 'KY',
    },
    42217: {
      lat: 37.04915,
      lng: -87.47212,
      city: 'Crofton',
      state: 'KY',
    },
    42220: {
      lat: 36.90129,
      lng: -87.18395,
      city: 'Elkton',
      state: 'KY',
    },
    42223: {
      lat: 36.6381,
      lng: -87.47258,
      city: 'Fort Campbell',
      state: 'KY',
    },
    42232: {
      lat: 36.86665,
      lng: -87.66167,
      city: 'Gracey',
      state: 'KY',
    },
    42234: {
      lat: 36.70656,
      lng: -87.17598,
      city: 'Guthrie',
      state: 'KY',
    },
    42236: {
      lat: 36.7196,
      lng: -87.61225,
      city: 'Herndon',
      state: 'KY',
    },
    42240: {
      lat: 36.88388,
      lng: -87.46024,
      city: 'Hopkinsville',
      state: 'KY',
    },
    42252: {
      lat: 37.23713,
      lng: -86.50029,
      city: 'Jetson',
      state: 'KY',
    },
    42254: {
      lat: 36.66435,
      lng: -87.63852,
      city: 'La Fayette',
      state: 'KY',
    },
    42256: {
      lat: 37.01666,
      lng: -86.94796,
      city: 'Lewisburg',
      state: 'KY',
    },
    42259: {
      lat: 37.23524,
      lng: -86.17206,
      city: 'Mammoth Cave',
      state: 'KY',
    },
    42261: {
      lat: 37.22405,
      lng: -86.67015,
      city: 'Morgantown',
      state: 'KY',
    },
    42262: {
      lat: 36.68386,
      lng: -87.44714,
      city: 'Oak Grove',
      state: 'KY',
    },
    42265: {
      lat: 36.739,
      lng: -87.00141,
      city: 'Olmstead',
      state: 'KY',
    },
    42266: {
      lat: 36.76562,
      lng: -87.3601,
      city: 'Pembroke',
      state: 'KY',
    },
    42273: {
      lat: 37.20128,
      lng: -86.85916,
      city: 'Rochester',
      state: 'KY',
    },
    42274: {
      lat: 36.94915,
      lng: -86.60534,
      city: 'Rockfield',
      state: 'KY',
    },
    42275: {
      lat: 37.22701,
      lng: -86.44628,
      city: 'Roundhill',
      state: 'KY',
    },
    42276: {
      lat: 36.86093,
      lng: -86.87727,
      city: 'Russellville',
      state: 'KY',
    },
    42280: {
      lat: 36.95055,
      lng: -87.09927,
      city: 'Sharon Grove',
      state: 'KY',
    },
    42285: {
      lat: 37.26418,
      lng: -86.29751,
      city: 'Sweeden',
      state: 'KY',
    },
    42286: {
      lat: 36.72749,
      lng: -87.26959,
      city: 'Trenton',
      state: 'KY',
    },
    42301: {
      lat: 37.73983,
      lng: -87.24643,
      city: 'Owensboro',
      state: 'KY',
    },
    42303: {
      lat: 37.76138,
      lng: -87.04889,
      city: 'Owensboro',
      state: 'KY',
    },
    42320: {
      lat: 37.35093,
      lng: -86.87291,
      city: 'Beaver Dam',
      state: 'KY',
    },
    42321: {
      lat: 37.17977,
      lng: -87.05195,
      city: 'Beech Creek',
      state: 'KY',
    },
    42322: {
      lat: 37.62083,
      lng: -87.38821,
      city: 'Beech Grove',
      state: 'KY',
    },
    42323: {
      lat: 37.17406,
      lng: -87.03261,
      city: 'Beechmont',
      state: 'KY',
    },
    42324: {
      lat: 37.13863,
      lng: -87.00974,
      city: 'Belton',
      state: 'KY',
    },
    42325: {
      lat: 37.32537,
      lng: -87.27375,
      city: 'Bremen',
      state: 'KY',
    },
    42326: {
      lat: 37.23666,
      lng: -87.00835,
      city: 'Browder',
      state: 'KY',
    },
    42327: {
      lat: 37.58721,
      lng: -87.30042,
      city: 'Calhoun',
      state: 'KY',
    },
    42328: {
      lat: 37.41898,
      lng: -87.0429,
      city: 'Centertown',
      state: 'KY',
    },
    42330: {
      lat: 37.31436,
      lng: -87.12245,
      city: 'Central City',
      state: 'KY',
    },
    42332: {
      lat: 37.25099,
      lng: -87.08935,
      city: 'Cleaton',
      state: 'KY',
    },
    42333: {
      lat: 37.37718,
      lng: -86.75609,
      city: 'Cromwell',
      state: 'KY',
    },
    42337: {
      lat: 37.22309,
      lng: -86.99964,
      city: 'Drakesboro',
      state: 'KY',
    },
    42338: {
      lat: 37.55295,
      lng: -86.77408,
      city: 'Dundee',
      state: 'KY',
    },
    42339: {
      lat: 37.11326,
      lng: -86.95033,
      city: 'Dunmor',
      state: 'KY',
    },
    42343: {
      lat: 37.63594,
      lng: -86.69795,
      city: 'Fordsville',
      state: 'KY',
    },
    42344: {
      lat: 37.25681,
      lng: -87.32053,
      city: 'Graham',
      state: 'KY',
    },
    42345: {
      lat: 37.14839,
      lng: -87.18943,
      city: 'Greenville',
      state: 'KY',
    },
    42347: {
      lat: 37.52414,
      lng: -86.88715,
      city: 'Hartford',
      state: 'KY',
    },
    42348: {
      lat: 37.83379,
      lng: -86.76578,
      city: 'Hawesville',
      state: 'KY',
    },
    42349: {
      lat: 37.42695,
      lng: -86.67425,
      city: 'Horse Branch',
      state: 'KY',
    },
    42350: {
      lat: 37.44852,
      lng: -87.1831,
      city: 'Island',
      state: 'KY',
    },
    42351: {
      lat: 37.9026,
      lng: -86.87903,
      city: 'Lewisport',
      state: 'KY',
    },
    42352: {
      lat: 37.51333,
      lng: -87.0833,
      city: 'Livermore',
      state: 'KY',
    },
    42354: {
      lat: 37.37915,
      lng: -86.9273,
      city: 'McHenry',
      state: 'KY',
    },
    42355: { lat: 37.86206, lng: -86.9895, city: 'Maceo', state: 'KY' },
    42356: {
      lat: 37.69364,
      lng: -87.32289,
      city: 'Maple Mount',
      state: 'KY',
    },
    42361: {
      lat: 37.51791,
      lng: -86.69183,
      city: 'Olaton',
      state: 'KY',
    },
    42366: {
      lat: 37.72404,
      lng: -86.93988,
      city: 'Philpot',
      state: 'KY',
    },
    42367: {
      lat: 37.2448,
      lng: -87.16133,
      city: 'Powderly',
      state: 'KY',
    },
    42368: {
      lat: 37.71258,
      lng: -86.75133,
      city: 'Reynolds Station',
      state: 'KY',
    },
    42369: {
      lat: 37.3517,
      lng: -87.00719,
      city: 'Rockport',
      state: 'KY',
    },
    42370: {
      lat: 37.4497,
      lng: -86.74121,
      city: 'Rosine',
      state: 'KY',
    },
    42371: {
      lat: 37.4978,
      lng: -87.29362,
      city: 'Rumsey',
      state: 'KY',
    },
    42372: {
      lat: 37.40588,
      lng: -87.2773,
      city: 'Sacramento',
      state: 'KY',
    },
    42374: {
      lat: 37.33333,
      lng: -87.14045,
      city: 'South Carrollton',
      state: 'KY',
    },
    42376: {
      lat: 37.61626,
      lng: -87.08619,
      city: 'Utica',
      state: 'KY',
    },
    42378: {
      lat: 37.65093,
      lng: -86.87258,
      city: 'Whitesville',
      state: 'KY',
    },
    42404: { lat: 37.47878, lng: -87.83636, city: 'Clay', state: 'KY' },
    42406: {
      lat: 37.77792,
      lng: -87.74496,
      city: 'Corydon',
      state: 'KY',
    },
    42408: {
      lat: 37.1797,
      lng: -87.69229,
      city: 'Dawson Springs',
      state: 'KY',
    },
    42409: { lat: 37.5436, lng: -87.69947, city: 'Dixon', state: 'KY' },
    42410: {
      lat: 37.27655,
      lng: -87.51078,
      city: 'Earlington',
      state: 'KY',
    },
    42411: {
      lat: 37.20315,
      lng: -88.02852,
      city: 'Fredonia',
      state: 'KY',
    },
    42413: {
      lat: 37.44257,
      lng: -87.46919,
      city: 'Hanson',
      state: 'KY',
    },
    42420: {
      lat: 37.79875,
      lng: -87.51517,
      city: 'Henderson',
      state: 'KY',
    },
    42431: {
      lat: 37.33319,
      lng: -87.47512,
      city: 'Madisonville',
      state: 'KY',
    },
    42436: {
      lat: 37.41462,
      lng: -87.56946,
      city: 'Manitou',
      state: 'KY',
    },
    42437: {
      lat: 37.66234,
      lng: -87.92067,
      city: 'Morganfield',
      state: 'KY',
    },
    42440: {
      lat: 37.23887,
      lng: -87.46782,
      city: 'Mortons Gap',
      state: 'KY',
    },
    42441: { lat: 37.36768, lng: -87.65962, city: 'Nebo', state: 'KY' },
    42442: {
      lat: 37.17408,
      lng: -87.48652,
      city: 'Nortonville',
      state: 'KY',
    },
    42444: { lat: 37.64152, lng: -87.6455, city: 'Poole', state: 'KY' },
    42445: {
      lat: 37.12861,
      lng: -87.86052,
      city: 'Princeton',
      state: 'KY',
    },
    42450: {
      lat: 37.38741,
      lng: -87.77535,
      city: 'Providence',
      state: 'KY',
    },
    42451: { lat: 37.88165, lng: -87.38966, city: 'Reed', state: 'KY' },
    42452: {
      lat: 37.68531,
      lng: -87.53654,
      city: 'Robards',
      state: 'KY',
    },
    42453: {
      lat: 37.14888,
      lng: -87.58275,
      city: 'Saint Charles',
      state: 'KY',
    },
    42455: {
      lat: 37.5858,
      lng: -87.53938,
      city: 'Sebree',
      state: 'KY',
    },
    42456: {
      lat: 37.51182,
      lng: -87.49929,
      city: 'Slaughters',
      state: 'KY',
    },
    42458: {
      lat: 37.85249,
      lng: -87.42367,
      city: 'Spottsville',
      state: 'KY',
    },
    42459: {
      lat: 37.5637,
      lng: -88.01837,
      city: 'Sturgis',
      state: 'KY',
    },
    42461: {
      lat: 37.78668,
      lng: -87.88807,
      city: 'Uniontown',
      state: 'KY',
    },
    42462: {
      lat: 37.74123,
      lng: -87.79834,
      city: 'Waverly',
      state: 'KY',
    },
    42463: {
      lat: 37.47897,
      lng: -87.86717,
      city: 'Wheatcroft',
      state: 'KY',
    },
    42464: {
      lat: 37.18132,
      lng: -87.37148,
      city: 'White Plains',
      state: 'KY',
    },
    42501: {
      lat: 37.06174,
      lng: -84.44973,
      city: 'Somerset',
      state: 'KY',
    },
    42503: {
      lat: 37.15677,
      lng: -84.52511,
      city: 'Somerset',
      state: 'KY',
    },
    42516: {
      lat: 37.22247,
      lng: -84.77881,
      city: 'Bethelridge',
      state: 'KY',
    },
    42518: {
      lat: 36.9253,
      lng: -84.64265,
      city: 'Bronston',
      state: 'KY',
    },
    42519: {
      lat: 36.93932,
      lng: -84.53573,
      city: 'Burnside',
      state: 'KY',
    },
    42528: {
      lat: 37.18335,
      lng: -85.01712,
      city: 'Dunnville',
      state: 'KY',
    },
    42533: {
      lat: 37.06752,
      lng: -84.59382,
      city: 'Ferguson',
      state: 'KY',
    },
    42539: {
      lat: 37.30566,
      lng: -84.95265,
      city: 'Liberty',
      state: 'KY',
    },
    42541: {
      lat: 37.37325,
      lng: -84.80778,
      city: 'Middleburg',
      state: 'KY',
    },
    42544: { lat: 37.03473, lng: -84.8143, city: 'Nancy', state: 'KY' },
    42553: {
      lat: 37.1716,
      lng: -84.70056,
      city: 'Science Hill',
      state: 'KY',
    },
    42565: {
      lat: 37.15008,
      lng: -84.90086,
      city: 'Windsor',
      state: 'KY',
    },
    42566: {
      lat: 37.28389,
      lng: -84.77932,
      city: 'Yosemite',
      state: 'KY',
    },
    42567: {
      lat: 37.26441,
      lng: -84.60454,
      city: 'Eubank',
      state: 'KY',
    },
    42602: {
      lat: 36.73201,
      lng: -85.13324,
      city: 'Albany',
      state: 'KY',
    },
    42603: {
      lat: 36.77875,
      lng: -85.01093,
      city: 'Alpha',
      state: 'KY',
    },
    42629: {
      lat: 36.92551,
      lng: -85.11732,
      city: 'Jamestown',
      state: 'KY',
    },
    42631: {
      lat: 36.74039,
      lng: -84.48098,
      city: 'Marshes Siding',
      state: 'KY',
    },
    42633: {
      lat: 36.79245,
      lng: -84.83155,
      city: 'Monticello',
      state: 'KY',
    },
    42634: {
      lat: 36.88043,
      lng: -84.41601,
      city: 'Parkers Lake',
      state: 'KY',
    },
    42635: {
      lat: 36.67601,
      lng: -84.40033,
      city: 'Pine Knot',
      state: 'KY',
    },
    42638: {
      lat: 36.67487,
      lng: -84.4689,
      city: 'Revelo',
      state: 'KY',
    },
    42642: {
      lat: 37.05624,
      lng: -85.0352,
      city: 'Russell Springs',
      state: 'KY',
    },
    42647: {
      lat: 36.6922,
      lng: -84.61144,
      city: 'Stearns',
      state: 'KY',
    },
    42649: {
      lat: 36.6253,
      lng: -84.41798,
      city: 'Strunk',
      state: 'KY',
    },
    42653: {
      lat: 36.78117,
      lng: -84.4618,
      city: 'Whitley City',
      state: 'KY',
    },
    42701: {
      lat: 37.70502,
      lng: -85.83881,
      city: 'Elizabethtown',
      state: 'KY',
    },
    42712: {
      lat: 37.56098,
      lng: -86.15791,
      city: 'Big Clifty',
      state: 'KY',
    },
    42713: {
      lat: 37.37631,
      lng: -85.88282,
      city: 'Bonnieville',
      state: 'KY',
    },
    42715: {
      lat: 36.94352,
      lng: -85.4134,
      city: 'Breeding',
      state: 'KY',
    },
    42716: {
      lat: 37.47495,
      lng: -85.61925,
      city: 'Buffalo',
      state: 'KY',
    },
    42717: {
      lat: 36.78169,
      lng: -85.38331,
      city: 'Burkesville',
      state: 'KY',
    },
    42718: {
      lat: 37.38994,
      lng: -85.37561,
      city: 'Campbellsville',
      state: 'KY',
    },
    42721: {
      lat: 37.42173,
      lng: -86.49593,
      city: 'Caneyville',
      state: 'KY',
    },
    42722: {
      lat: 37.27628,
      lng: -85.71703,
      city: 'Canmer',
      state: 'KY',
    },
    42724: {
      lat: 37.67293,
      lng: -86.06461,
      city: 'Cecilia',
      state: 'KY',
    },
    42726: {
      lat: 37.42423,
      lng: -86.15035,
      city: 'Clarkson',
      state: 'KY',
    },
    42728: {
      lat: 37.10943,
      lng: -85.28873,
      city: 'Columbia',
      state: 'KY',
    },
    42729: {
      lat: 37.30167,
      lng: -86.09719,
      city: 'Cub Run',
      state: 'KY',
    },
    42732: {
      lat: 37.61,
      lng: -86.11235,
      city: 'Eastview',
      state: 'KY',
    },
    42733: {
      lat: 37.34008,
      lng: -85.18028,
      city: 'Elk Horn',
      state: 'KY',
    },
    42740: {
      lat: 37.59174,
      lng: -85.94358,
      city: 'Glendale',
      state: 'KY',
    },
    42741: {
      lat: 36.9888,
      lng: -85.25183,
      city: 'Glens Fork',
      state: 'KY',
    },
    42742: {
      lat: 37.07774,
      lng: -85.47888,
      city: 'Gradyville',
      state: 'KY',
    },
    42743: {
      lat: 37.22958,
      lng: -85.53175,
      city: 'Greensburg',
      state: 'KY',
    },
    42746: {
      lat: 37.21233,
      lng: -85.73304,
      city: 'Hardyville',
      state: 'KY',
    },
    42748: {
      lat: 37.57029,
      lng: -85.70952,
      city: 'Hodgenville',
      state: 'KY',
    },
    42749: {
      lat: 37.18983,
      lng: -85.87516,
      city: 'Horse Cave',
      state: 'KY',
    },
    42753: {
      lat: 37.22299,
      lng: -85.17411,
      city: 'Knifley',
      state: 'KY',
    },
    42754: {
      lat: 37.47814,
      lng: -86.31379,
      city: 'Leitchfield',
      state: 'KY',
    },
    42757: {
      lat: 37.40192,
      lng: -85.72501,
      city: 'Magnolia',
      state: 'KY',
    },
    42758: {
      lat: 37.37656,
      lng: -85.19756,
      city: 'Mannsville',
      state: 'KY',
    },
    42759: {
      lat: 36.82909,
      lng: -85.51334,
      city: 'Marrowbone',
      state: 'KY',
    },
    42762: {
      lat: 37.45366,
      lng: -86.39527,
      city: 'Millwood',
      state: 'KY',
    },
    42764: {
      lat: 37.42948,
      lng: -85.62507,
      city: 'Mount Sherman',
      state: 'KY',
    },
    42765: {
      lat: 37.30699,
      lng: -85.93115,
      city: 'Munfordville',
      state: 'KY',
    },
    42776: {
      lat: 37.51577,
      lng: -85.91834,
      city: 'Sonora',
      state: 'KY',
    },
    42782: {
      lat: 37.34475,
      lng: -85.63702,
      city: 'Summersville',
      state: 'KY',
    },
    42784: { lat: 37.4503, lng: -85.92272, city: 'Upton', state: 'KY' },
    42788: {
      lat: 37.52774,
      lng: -86.0372,
      city: 'White Mills',
      state: 'KY',
    },
    43001: {
      lat: 40.08794,
      lng: -82.61289,
      city: 'Alexandria',
      state: 'OH',
    },
    43002: {
      lat: 40.05982,
      lng: -83.17305,
      city: 'Amlin',
      state: 'OH',
    },
    43003: {
      lat: 40.41017,
      lng: -82.96866,
      city: 'Ashley',
      state: 'OH',
    },
    43004: {
      lat: 40.01664,
      lng: -82.80025,
      city: 'Blacklick',
      state: 'OH',
    },
    43005: {
      lat: 40.28241,
      lng: -82.26728,
      city: 'Bladensburg',
      state: 'OH',
    },
    43006: {
      lat: 40.4591,
      lng: -82.14554,
      city: 'Brinkhaven',
      state: 'OH',
    },
    43007: {
      lat: 40.33154,
      lng: -83.40595,
      city: 'Broadway',
      state: 'OH',
    },
    43008: {
      lat: 39.93348,
      lng: -82.4817,
      city: 'Buckeye Lake',
      state: 'OH',
    },
    43009: {
      lat: 40.17188,
      lng: -83.64234,
      city: 'Cable',
      state: 'OH',
    },
    43010: {
      lat: 39.99964,
      lng: -83.6219,
      city: 'Catawba',
      state: 'OH',
    },
    43011: {
      lat: 40.30946,
      lng: -82.68456,
      city: 'Centerburg',
      state: 'OH',
    },
    43013: {
      lat: 40.23101,
      lng: -82.6895,
      city: 'Croton',
      state: 'OH',
    },
    43014: {
      lat: 40.46646,
      lng: -82.25242,
      city: 'Danville',
      state: 'OH',
    },
    43015: {
      lat: 40.29812,
      lng: -83.06049,
      city: 'Delaware',
      state: 'OH',
    },
    43016: {
      lat: 40.09732,
      lng: -83.15535,
      city: 'Dublin',
      state: 'OH',
    },
    43017: {
      lat: 40.1189,
      lng: -83.13314,
      city: 'Dublin',
      state: 'OH',
    },
    43018: { lat: 39.95377, lng: -82.67613, city: 'Etna', state: 'OH' },
    43019: {
      lat: 40.49527,
      lng: -82.57831,
      city: 'Fredericktown',
      state: 'OH',
    },
    43021: {
      lat: 40.20301,
      lng: -82.88476,
      city: 'Galena',
      state: 'OH',
    },
    43022: {
      lat: 40.33765,
      lng: -82.34135,
      city: 'Gambier',
      state: 'OH',
    },
    43023: {
      lat: 40.07603,
      lng: -82.53061,
      city: 'Granville',
      state: 'OH',
    },
    43025: {
      lat: 39.96653,
      lng: -82.51529,
      city: 'Hebron',
      state: 'OH',
    },
    43026: {
      lat: 40.02197,
      lng: -83.18472,
      city: 'Hilliard',
      state: 'OH',
    },
    43027: {
      lat: 40.25375,
      lng: -82.52253,
      city: 'Homer',
      state: 'OH',
    },
    43028: {
      lat: 40.40335,
      lng: -82.29783,
      city: 'Howard',
      state: 'OH',
    },
    43029: {
      lat: 40.09598,
      lng: -83.45408,
      city: 'Irwin',
      state: 'OH',
    },
    43030: {
      lat: 39.96783,
      lng: -82.40667,
      city: 'Jacksontown',
      state: 'OH',
    },
    43031: {
      lat: 40.16292,
      lng: -82.66378,
      city: 'Johnstown',
      state: 'OH',
    },
    43032: {
      lat: 40.32836,
      lng: -82.95812,
      city: 'Kilbourne',
      state: 'OH',
    },
    43033: {
      lat: 39.96098,
      lng: -82.59984,
      city: 'Kirkersville',
      state: 'OH',
    },
    43035: {
      lat: 40.18622,
      lng: -82.9969,
      city: 'Lewis Center',
      state: 'OH',
    },
    43036: {
      lat: 40.3524,
      lng: -83.26325,
      city: 'Magnetic Springs',
      state: 'OH',
    },
    43037: {
      lat: 40.28062,
      lng: -82.31654,
      city: 'Martinsburg',
      state: 'OH',
    },
    43040: {
      lat: 40.26115,
      lng: -83.35812,
      city: 'Marysville',
      state: 'OH',
    },
    43044: {
      lat: 40.05145,
      lng: -83.56423,
      city: 'Mechanicsburg',
      state: 'OH',
    },
    43045: {
      lat: 40.16932,
      lng: -83.44975,
      city: 'Milford Center',
      state: 'OH',
    },
    43046: {
      lat: 39.89917,
      lng: -82.53844,
      city: 'Millersport',
      state: 'OH',
    },
    43047: {
      lat: 40.20795,
      lng: -83.63797,
      city: 'Mingo',
      state: 'OH',
    },
    43050: {
      lat: 40.37188,
      lng: -82.49536,
      city: 'Mount Vernon',
      state: 'OH',
    },
    43054: {
      lat: 40.07998,
      lng: -82.79253,
      city: 'New Albany',
      state: 'OH',
    },
    43055: {
      lat: 40.1168,
      lng: -82.37672,
      city: 'Newark',
      state: 'OH',
    },
    43056: {
      lat: 40.00895,
      lng: -82.33057,
      city: 'Heath',
      state: 'OH',
    },
    43060: {
      lat: 40.21978,
      lng: -83.57295,
      city: 'North Lewisburg',
      state: 'OH',
    },
    43061: {
      lat: 40.28775,
      lng: -83.20256,
      city: 'Ostrander',
      state: 'OH',
    },
    43062: {
      lat: 40.00556,
      lng: -82.67964,
      city: 'Pataskala',
      state: 'OH',
    },
    43064: {
      lat: 40.09819,
      lng: -83.28863,
      city: 'Plain City',
      state: 'OH',
    },
    43065: {
      lat: 40.17655,
      lng: -83.09445,
      city: 'Powell',
      state: 'OH',
    },
    43066: {
      lat: 40.39877,
      lng: -83.16313,
      city: 'Radnor',
      state: 'OH',
    },
    43067: {
      lat: 40.34368,
      lng: -83.48388,
      city: 'Raymond',
      state: 'OH',
    },
    43068: {
      lat: 39.95667,
      lng: -82.78468,
      city: 'Reynoldsburg',
      state: 'OH',
    },
    43070: {
      lat: 40.22056,
      lng: -83.95651,
      city: 'Rosewood',
      state: 'OH',
    },
    43071: {
      lat: 40.18479,
      lng: -82.35509,
      city: 'Saint Louisville',
      state: 'OH',
    },
    43072: {
      lat: 40.1172,
      lng: -83.95514,
      city: 'Saint Paris',
      state: 'OH',
    },
    43074: {
      lat: 40.28008,
      lng: -82.84899,
      city: 'Sunbury',
      state: 'OH',
    },
    43076: {
      lat: 39.89594,
      lng: -82.39497,
      city: 'Thornville',
      state: 'OH',
    },
    43077: {
      lat: 40.13675,
      lng: -83.34165,
      city: 'Unionville Center',
      state: 'OH',
    },
    43078: {
      lat: 40.11302,
      lng: -83.77538,
      city: 'Urbana',
      state: 'OH',
    },
    43080: {
      lat: 40.24284,
      lng: -82.43126,
      city: 'Utica',
      state: 'OH',
    },
    43081: {
      lat: 40.11064,
      lng: -82.89083,
      city: 'Westerville',
      state: 'OH',
    },
    43082: {
      lat: 40.15537,
      lng: -82.88437,
      city: 'Westerville',
      state: 'OH',
    },
    43084: {
      lat: 40.15764,
      lng: -83.54223,
      city: 'Woodstock',
      state: 'OH',
    },
    43085: {
      lat: 40.09959,
      lng: -83.01639,
      city: 'Columbus',
      state: 'OH',
    },
    43101: {
      lat: 39.46466,
      lng: -82.74563,
      city: 'Adelphi',
      state: 'OH',
    },
    43102: {
      lat: 39.64076,
      lng: -82.76118,
      city: 'Amanda',
      state: 'OH',
    },
    43103: {
      lat: 39.72484,
      lng: -82.93428,
      city: 'Ashville',
      state: 'OH',
    },
    43105: {
      lat: 39.86513,
      lng: -82.61826,
      city: 'Baltimore',
      state: 'OH',
    },
    43106: {
      lat: 39.6448,
      lng: -83.41853,
      city: 'Bloomingburg',
      state: 'OH',
    },
    43107: {
      lat: 39.69254,
      lng: -82.40993,
      city: 'Bremen',
      state: 'OH',
    },
    43109: { lat: 39.9127, lng: -82.83385, city: 'Brice', state: 'OH' },
    43110: {
      lat: 39.82825,
      lng: -82.80143,
      city: 'Canal Winchester',
      state: 'OH',
    },
    43111: {
      lat: 39.50212,
      lng: -82.24257,
      city: 'Carbon Hill',
      state: 'OH',
    },
    43112: {
      lat: 39.79719,
      lng: -82.70472,
      city: 'Carroll',
      state: 'OH',
    },
    43113: {
      lat: 39.59114,
      lng: -82.96192,
      city: 'Circleville',
      state: 'OH',
    },
    43115: {
      lat: 39.48989,
      lng: -83.16579,
      city: 'Clarksburg',
      state: 'OH',
    },
    43116: {
      lat: 39.77284,
      lng: -83.06275,
      city: 'Commercial Point',
      state: 'OH',
    },
    43117: {
      lat: 39.76874,
      lng: -83.20622,
      city: 'Derby',
      state: 'OH',
    },
    43119: {
      lat: 39.93487,
      lng: -83.20059,
      city: 'Galloway',
      state: 'OH',
    },
    43123: {
      lat: 39.86801,
      lng: -83.12088,
      city: 'Grove City',
      state: 'OH',
    },
    43125: {
      lat: 39.83747,
      lng: -82.88588,
      city: 'Groveport',
      state: 'OH',
    },
    43126: {
      lat: 39.81077,
      lng: -83.16857,
      city: 'Harrisburg',
      state: 'OH',
    },
    43127: {
      lat: 39.47559,
      lng: -82.32183,
      city: 'Haydenville',
      state: 'OH',
    },
    43128: {
      lat: 39.66359,
      lng: -83.57893,
      city: 'Jeffersonville',
      state: 'OH',
    },
    43130: {
      lat: 39.70013,
      lng: -82.60992,
      city: 'Lancaster',
      state: 'OH',
    },
    43135: {
      lat: 39.4711,
      lng: -82.69736,
      city: 'Laurelville',
      state: 'OH',
    },
    43136: {
      lat: 39.80157,
      lng: -82.81543,
      city: 'Lithopolis',
      state: 'OH',
    },
    43137: {
      lat: 39.80486,
      lng: -82.98694,
      city: 'Lockbourne',
      state: 'OH',
    },
    43138: {
      lat: 39.52225,
      lng: -82.41335,
      city: 'Logan',
      state: 'OH',
    },
    43140: {
      lat: 39.88406,
      lng: -83.42946,
      city: 'London',
      state: 'OH',
    },
    43142: {
      lat: 39.59289,
      lng: -83.58777,
      city: 'Milledgeville',
      state: 'OH',
    },
    43143: {
      lat: 39.71801,
      lng: -83.29672,
      city: 'Mount Sterling',
      state: 'OH',
    },
    43144: {
      lat: 39.52797,
      lng: -82.19155,
      city: 'Murray City',
      state: 'OH',
    },
    43145: {
      lat: 39.56281,
      lng: -83.2594,
      city: 'New Holland',
      state: 'OH',
    },
    43146: {
      lat: 39.77293,
      lng: -83.14333,
      city: 'Orient',
      state: 'OH',
    },
    43147: {
      lat: 39.8982,
      lng: -82.74227,
      city: 'Pickerington',
      state: 'OH',
    },
    43148: {
      lat: 39.81809,
      lng: -82.49887,
      city: 'Pleasantville',
      state: 'OH',
    },
    43149: {
      lat: 39.53742,
      lng: -82.57188,
      city: 'Rockbridge',
      state: 'OH',
    },
    43150: {
      lat: 39.77446,
      lng: -82.40565,
      city: 'Rushville',
      state: 'OH',
    },
    43151: {
      lat: 39.73317,
      lng: -83.47661,
      city: 'Sedalia',
      state: 'OH',
    },
    43152: {
      lat: 39.39211,
      lng: -82.61433,
      city: 'South Bloomingville',
      state: 'OH',
    },
    43153: {
      lat: 39.74071,
      lng: -83.56781,
      city: 'South Solon',
      state: 'OH',
    },
    43154: {
      lat: 39.60327,
      lng: -82.82622,
      city: 'Stoutsville',
      state: 'OH',
    },
    43155: {
      lat: 39.6251,
      lng: -82.52618,
      city: 'Sugar Grove',
      state: 'OH',
    },
    43156: {
      lat: 39.55389,
      lng: -82.77621,
      city: 'Tarlton',
      state: 'OH',
    },
    43157: {
      lat: 39.84303,
      lng: -82.54675,
      city: 'Thurston',
      state: 'OH',
    },
    43158: {
      lat: 39.44968,
      lng: -82.35546,
      city: 'Union Furnace',
      state: 'OH',
    },
    43160: {
      lat: 39.52871,
      lng: -83.43917,
      city: 'Washington Court House',
      state: 'OH',
    },
    43162: {
      lat: 39.94369,
      lng: -83.30633,
      city: 'West Jefferson',
      state: 'OH',
    },
    43164: {
      lat: 39.58349,
      lng: -83.12009,
      city: 'Williamsport',
      state: 'OH',
    },
    43201: {
      lat: 39.99114,
      lng: -83.00056,
      city: 'Columbus',
      state: 'OH',
    },
    43202: {
      lat: 40.02126,
      lng: -83.01394,
      city: 'Columbus',
      state: 'OH',
    },
    43203: {
      lat: 39.9729,
      lng: -82.96922,
      city: 'Columbus',
      state: 'OH',
    },
    43204: {
      lat: 39.96135,
      lng: -83.08052,
      city: 'Columbus',
      state: 'OH',
    },
    43205: {
      lat: 39.9575,
      lng: -82.96244,
      city: 'Columbus',
      state: 'OH',
    },
    43206: {
      lat: 39.94205,
      lng: -82.97346,
      city: 'Columbus',
      state: 'OH',
    },
    43207: {
      lat: 39.89599,
      lng: -82.96392,
      city: 'Columbus',
      state: 'OH',
    },
    43209: {
      lat: 39.95462,
      lng: -82.92992,
      city: 'Columbus',
      state: 'OH',
    },
    43210: {
      lat: 40.00487,
      lng: -83.02182,
      city: 'Columbus',
      state: 'OH',
    },
    43211: {
      lat: 40.01163,
      lng: -82.97244,
      city: 'Columbus',
      state: 'OH',
    },
    43212: {
      lat: 39.98763,
      lng: -83.04215,
      city: 'Columbus',
      state: 'OH',
    },
    43213: {
      lat: 39.97117,
      lng: -82.86746,
      city: 'Columbus',
      state: 'OH',
    },
    43214: {
      lat: 40.0523,
      lng: -83.01996,
      city: 'Columbus',
      state: 'OH',
    },
    43215: {
      lat: 39.96633,
      lng: -83.01262,
      city: 'Columbus',
      state: 'OH',
    },
    43217: {
      lat: 39.81667,
      lng: -82.93279,
      city: 'Columbus',
      state: 'OH',
    },
    43218: {
      lat: 40.01765,
      lng: -82.92367,
      city: 'Columbus',
      state: 'OH',
    },
    43219: {
      lat: 40.00774,
      lng: -82.92233,
      city: 'Columbus',
      state: 'OH',
    },
    43220: {
      lat: 40.04847,
      lng: -83.07161,
      city: 'Columbus',
      state: 'OH',
    },
    43221: {
      lat: 40.02334,
      lng: -83.07744,
      city: 'Columbus',
      state: 'OH',
    },
    43222: {
      lat: 39.961,
      lng: -83.03581,
      city: 'Columbus',
      state: 'OH',
    },
    43223: {
      lat: 39.92636,
      lng: -83.03395,
      city: 'Columbus',
      state: 'OH',
    },
    43224: {
      lat: 40.04256,
      lng: -82.9664,
      city: 'Columbus',
      state: 'OH',
    },
    43227: {
      lat: 39.94511,
      lng: -82.88858,
      city: 'Columbus',
      state: 'OH',
    },
    43228: {
      lat: 39.96367,
      lng: -83.12478,
      city: 'Columbus',
      state: 'OH',
    },
    43229: {
      lat: 40.08569,
      lng: -82.97636,
      city: 'Columbus',
      state: 'OH',
    },
    43230: {
      lat: 40.03648,
      lng: -82.87057,
      city: 'Columbus',
      state: 'OH',
    },
    43231: {
      lat: 40.07843,
      lng: -82.93914,
      city: 'Columbus',
      state: 'OH',
    },
    43232: {
      lat: 39.92189,
      lng: -82.87053,
      city: 'Columbus',
      state: 'OH',
    },
    43235: {
      lat: 40.10193,
      lng: -83.05442,
      city: 'Columbus',
      state: 'OH',
    },
    43240: {
      lat: 40.14501,
      lng: -82.98209,
      city: 'Columbus',
      state: 'OH',
    },
    43302: {
      lat: 40.59995,
      lng: -83.12967,
      city: 'Marion',
      state: 'OH',
    },
    43310: {
      lat: 40.5346,
      lng: -83.78029,
      city: 'Belle Center',
      state: 'OH',
    },
    43311: {
      lat: 40.36427,
      lng: -83.75812,
      city: 'Bellefontaine',
      state: 'OH',
    },
    43314: {
      lat: 40.6474,
      lng: -82.96122,
      city: 'Caledonia',
      state: 'OH',
    },
    43315: {
      lat: 40.4841,
      lng: -82.87872,
      city: 'Cardington',
      state: 'OH',
    },
    43316: {
      lat: 40.95184,
      lng: -83.38596,
      city: 'Carey',
      state: 'OH',
    },
    43317: {
      lat: 40.47748,
      lng: -82.68202,
      city: 'Chesterville',
      state: 'OH',
    },
    43318: {
      lat: 40.30948,
      lng: -83.91378,
      city: 'De Graff',
      state: 'OH',
    },
    43319: {
      lat: 40.30525,
      lng: -83.57738,
      city: 'East Liberty',
      state: 'OH',
    },
    43320: {
      lat: 40.5886,
      lng: -82.89994,
      city: 'Edison',
      state: 'OH',
    },
    43321: { lat: 40.463, lng: -82.82889, city: 'Fulton', state: 'OH' },
    43322: {
      lat: 40.5327,
      lng: -83.20815,
      city: 'Green Camp',
      state: 'OH',
    },
    43323: {
      lat: 40.73116,
      lng: -83.25211,
      city: 'Harpster',
      state: 'OH',
    },
    43324: {
      lat: 40.44632,
      lng: -83.81207,
      city: 'Huntsville',
      state: 'OH',
    },
    43325: {
      lat: 40.66939,
      lng: -82.83102,
      city: 'Iberia',
      state: 'OH',
    },
    43326: {
      lat: 40.64149,
      lng: -83.61443,
      city: 'Kenton',
      state: 'OH',
    },
    43330: {
      lat: 40.81349,
      lng: -83.41935,
      city: 'Kirby',
      state: 'OH',
    },
    43331: {
      lat: 40.51794,
      lng: -83.9191,
      city: 'Lakeview',
      state: 'OH',
    },
    43332: {
      lat: 40.58369,
      lng: -83.37769,
      city: 'La Rue',
      state: 'OH',
    },
    43333: {
      lat: 40.43927,
      lng: -83.93221,
      city: 'Lewistown',
      state: 'OH',
    },
    43334: {
      lat: 40.39912,
      lng: -82.79863,
      city: 'Marengo',
      state: 'OH',
    },
    43336: {
      lat: 40.28337,
      lng: -83.57651,
      city: 'Middleburg',
      state: 'OH',
    },
    43337: {
      lat: 40.68336,
      lng: -83.24107,
      city: 'Morral',
      state: 'OH',
    },
    43338: {
      lat: 40.56207,
      lng: -82.75958,
      city: 'Mount Gilead',
      state: 'OH',
    },
    43340: {
      lat: 40.53748,
      lng: -83.48,
      city: 'Mount Victory',
      state: 'OH',
    },
    43341: {
      lat: 40.59577,
      lng: -83.31544,
      city: 'New Bloomington',
      state: 'OH',
    },
    43342: {
      lat: 40.47533,
      lng: -83.17994,
      city: 'Prospect',
      state: 'OH',
    },
    43343: {
      lat: 40.3073,
      lng: -83.97647,
      city: 'Quincy',
      state: 'OH',
    },
    43344: {
      lat: 40.43219,
      lng: -83.33832,
      city: 'Richwood',
      state: 'OH',
    },
    43345: {
      lat: 40.51341,
      lng: -83.58167,
      city: 'Ridgeway',
      state: 'OH',
    },
    43346: {
      lat: 40.5768,
      lng: -83.84518,
      city: 'Roundhead',
      state: 'OH',
    },
    43347: {
      lat: 40.47771,
      lng: -83.65804,
      city: 'Rushsylvania',
      state: 'OH',
    },
    43348: {
      lat: 40.46754,
      lng: -83.88857,
      city: 'Russells Point',
      state: 'OH',
    },
    43350: {
      lat: 40.39557,
      lng: -82.69977,
      city: 'Sparta',
      state: 'OH',
    },
    43351: {
      lat: 40.82501,
      lng: -83.30424,
      city: 'Upper Sandusky',
      state: 'OH',
    },
    43356: {
      lat: 40.45748,
      lng: -83.04788,
      city: 'Waldo',
      state: 'OH',
    },
    43357: {
      lat: 40.25316,
      lng: -83.74711,
      city: 'West Liberty',
      state: 'OH',
    },
    43358: {
      lat: 40.41898,
      lng: -83.53129,
      city: 'West Mansfield',
      state: 'OH',
    },
    43359: {
      lat: 40.86977,
      lng: -83.45319,
      city: 'Wharton',
      state: 'OH',
    },
    43360: {
      lat: 40.33272,
      lng: -83.63114,
      city: 'Zanesfield',
      state: 'OH',
    },
    43402: {
      lat: 41.40957,
      lng: -83.65389,
      city: 'Bowling Green',
      state: 'OH',
    },
    43403: {
      lat: 41.37784,
      lng: -83.63653,
      city: 'Bowling Green',
      state: 'OH',
    },
    43406: {
      lat: 41.33605,
      lng: -83.4372,
      city: 'Bradner',
      state: 'OH',
    },
    43407: {
      lat: 41.27717,
      lng: -83.24994,
      city: 'Burgoon',
      state: 'OH',
    },
    43408: {
      lat: 41.57179,
      lng: -83.36352,
      city: 'Clay Center',
      state: 'OH',
    },
    43410: {
      lat: 41.30792,
      lng: -82.95807,
      city: 'Clyde',
      state: 'OH',
    },
    43412: {
      lat: 41.62933,
      lng: -83.31961,
      city: 'Curtice',
      state: 'OH',
    },
    43413: {
      lat: 41.24205,
      lng: -83.65476,
      city: 'Cygnet',
      state: 'OH',
    },
    43414: {
      lat: 41.45165,
      lng: -83.61724,
      city: 'Dunbridge',
      state: 'OH',
    },
    43416: {
      lat: 41.48532,
      lng: -83.27055,
      city: 'Elmore',
      state: 'OH',
    },
    43420: {
      lat: 41.35749,
      lng: -83.11265,
      city: 'Fremont',
      state: 'OH',
    },
    43430: { lat: 41.5238, lng: -83.37329, city: 'Genoa', state: 'OH' },
    43431: {
      lat: 41.38998,
      lng: -83.33964,
      city: 'Gibsonburg',
      state: 'OH',
    },
    43432: {
      lat: 41.56115,
      lng: -83.24934,
      city: 'Graytown',
      state: 'OH',
    },
    43433: {
      lat: 41.50463,
      lng: -82.88033,
      city: 'Gypsum',
      state: 'OH',
    },
    43434: {
      lat: 41.69319,
      lng: -83.44474,
      city: 'Harbor View',
      state: 'OH',
    },
    43435: {
      lat: 41.32634,
      lng: -83.31197,
      city: 'Helena',
      state: 'OH',
    },
    43436: {
      lat: 41.71522,
      lng: -82.82098,
      city: 'Isle Saint George',
      state: 'OH',
    },
    43437: {
      lat: 41.25396,
      lng: -83.60176,
      city: 'Jerry City',
      state: 'OH',
    },
    43438: {
      lat: 41.60343,
      lng: -82.70268,
      city: 'Kelleys Island',
      state: 'OH',
    },
    43439: {
      lat: 41.51758,
      lng: -83.04275,
      city: 'Lacarne',
      state: 'OH',
    },
    43440: {
      lat: 41.52662,
      lng: -82.78145,
      city: 'Lakeside Marblehead',
      state: 'OH',
    },
    43442: {
      lat: 41.42911,
      lng: -83.21496,
      city: 'Lindsey',
      state: 'OH',
    },
    43443: {
      lat: 41.46099,
      lng: -83.46906,
      city: 'Luckey',
      state: 'OH',
    },
    43445: {
      lat: 41.58644,
      lng: -83.29488,
      city: 'Martin',
      state: 'OH',
    },
    43446: {
      lat: 41.68483,
      lng: -82.80863,
      city: 'Middle Bass',
      state: 'OH',
    },
    43447: {
      lat: 41.56654,
      lng: -83.43429,
      city: 'Millbury',
      state: 'OH',
    },
    43449: {
      lat: 41.5357,
      lng: -83.14151,
      city: 'Oak Harbor',
      state: 'OH',
    },
    43450: {
      lat: 41.40013,
      lng: -83.49324,
      city: 'Pemberville',
      state: 'OH',
    },
    43451: {
      lat: 41.31551,
      lng: -83.61614,
      city: 'Portage',
      state: 'OH',
    },
    43452: {
      lat: 41.51568,
      lng: -82.96522,
      city: 'Port Clinton',
      state: 'OH',
    },
    43456: {
      lat: 41.64934,
      lng: -82.82497,
      city: 'Put In Bay',
      state: 'OH',
    },
    43457: {
      lat: 41.26788,
      lng: -83.42508,
      city: 'Risingsun',
      state: 'OH',
    },
    43458: {
      lat: 41.53105,
      lng: -83.21258,
      city: 'Rocky Ridge',
      state: 'OH',
    },
    43460: {
      lat: 41.60392,
      lng: -83.56328,
      city: 'Rossford',
      state: 'OH',
    },
    43462: {
      lat: 41.28352,
      lng: -83.71887,
      city: 'Rudolph',
      state: 'OH',
    },
    43463: {
      lat: 41.51141,
      lng: -83.50511,
      city: 'Stony Ridge',
      state: 'OH',
    },
    43464: {
      lat: 41.40318,
      lng: -82.93116,
      city: 'Vickery',
      state: 'OH',
    },
    43465: {
      lat: 41.56593,
      lng: -83.50489,
      city: 'Walbridge',
      state: 'OH',
    },
    43466: {
      lat: 41.29234,
      lng: -83.51394,
      city: 'Wayne',
      state: 'OH',
    },
    43467: {
      lat: 41.24328,
      lng: -83.49098,
      city: 'West Millgrove',
      state: 'OH',
    },
    43468: {
      lat: 41.59974,
      lng: -83.34661,
      city: 'Williston',
      state: 'OH',
    },
    43469: {
      lat: 41.45947,
      lng: -83.36474,
      city: 'Woodville',
      state: 'OH',
    },
    43501: {
      lat: 41.66966,
      lng: -84.46095,
      city: 'Alvordton',
      state: 'OH',
    },
    43502: {
      lat: 41.53181,
      lng: -84.30201,
      city: 'Archbold',
      state: 'OH',
    },
    43504: {
      lat: 41.69736,
      lng: -83.8369,
      city: 'Berkey',
      state: 'OH',
    },
    43505: {
      lat: 41.52429,
      lng: -84.73101,
      city: 'Blakeslee',
      state: 'OH',
    },
    43506: {
      lat: 41.46739,
      lng: -84.55704,
      city: 'Bryan',
      state: 'OH',
    },
    43510: {
      lat: 41.46484,
      lng: -83.94712,
      city: 'Colton',
      state: 'OH',
    },
    43511: {
      lat: 41.26384,
      lng: -83.82376,
      city: 'Custar',
      state: 'OH',
    },
    43512: {
      lat: 41.30309,
      lng: -84.35874,
      city: 'Defiance',
      state: 'OH',
    },
    43515: {
      lat: 41.58656,
      lng: -84.00746,
      city: 'Delta',
      state: 'OH',
    },
    43516: {
      lat: 41.21579,
      lng: -83.91081,
      city: 'Deshler',
      state: 'OH',
    },
    43517: {
      lat: 41.44003,
      lng: -84.72833,
      city: 'Edgerton',
      state: 'OH',
    },
    43518: { lat: 41.58977, lng: -84.75385, city: 'Edon', state: 'OH' },
    43519: {
      lat: 41.42092,
      lng: -84.40854,
      city: 'Evansport',
      state: 'OH',
    },
    43520: {
      lat: 41.38008,
      lng: -84.63565,
      city: 'Farmer',
      state: 'OH',
    },
    43521: {
      lat: 41.66377,
      lng: -84.29717,
      city: 'Fayette',
      state: 'OH',
    },
    43522: {
      lat: 41.42058,
      lng: -83.84352,
      city: 'Grand Rapids',
      state: 'OH',
    },
    43523: {
      lat: 41.33875,
      lng: -83.99865,
      city: 'Grelton',
      state: 'OH',
    },
    43524: { lat: 41.22839, lng: -84.039, city: 'Hamler', state: 'OH' },
    43525: {
      lat: 41.46663,
      lng: -83.70098,
      city: 'Haskins',
      state: 'OH',
    },
    43526: {
      lat: 41.31339,
      lng: -84.73024,
      city: 'Hicksville',
      state: 'OH',
    },
    43527: {
      lat: 41.25121,
      lng: -84.1593,
      city: 'Holgate',
      state: 'OH',
    },
    43528: {
      lat: 41.62908,
      lng: -83.74923,
      city: 'Holland',
      state: 'OH',
    },
    43529: {
      lat: 41.18998,
      lng: -83.78431,
      city: 'Hoytville',
      state: 'OH',
    },
    43530: {
      lat: 41.32794,
      lng: -84.28933,
      city: 'Jewell',
      state: 'OH',
    },
    43531: {
      lat: 41.63511,
      lng: -84.4957,
      city: 'Kunkle',
      state: 'OH',
    },
    43532: {
      lat: 41.45486,
      lng: -83.97008,
      city: 'Liberty Center',
      state: 'OH',
    },
    43533: {
      lat: 41.69335,
      lng: -84.07997,
      city: 'Lyons',
      state: 'OH',
    },
    43534: {
      lat: 41.35446,
      lng: -83.92914,
      city: 'McClure',
      state: 'OH',
    },
    43535: {
      lat: 41.30592,
      lng: -84.02416,
      city: 'Malinta',
      state: 'OH',
    },
    43536: {
      lat: 41.31345,
      lng: -84.634,
      city: 'Mark Center',
      state: 'OH',
    },
    43537: {
      lat: 41.57433,
      lng: -83.68542,
      city: 'Maumee',
      state: 'OH',
    },
    43540: {
      lat: 41.69545,
      lng: -83.943,
      city: 'Metamora',
      state: 'OH',
    },
    43541: {
      lat: 41.30075,
      lng: -83.82953,
      city: 'Milton Center',
      state: 'OH',
    },
    43542: {
      lat: 41.56642,
      lng: -83.76531,
      city: 'Monclova',
      state: 'OH',
    },
    43543: {
      lat: 41.60575,
      lng: -84.6338,
      city: 'Montpelier',
      state: 'OH',
    },
    43545: {
      lat: 41.39163,
      lng: -84.12719,
      city: 'Napoleon',
      state: 'OH',
    },
    43547: {
      lat: 41.49214,
      lng: -83.87634,
      city: 'Neapolis',
      state: 'OH',
    },
    43548: {
      lat: 41.18867,
      lng: -84.17296,
      city: 'New Bavaria',
      state: 'OH',
    },
    43549: { lat: 41.37519, lng: -84.51818, city: 'Ney', state: 'OH' },
    43551: {
      lat: 41.52095,
      lng: -83.57503,
      city: 'Perrysburg',
      state: 'OH',
    },
    43553: {
      lat: 41.53096,
      lng: -84.22382,
      city: 'Pettisville',
      state: 'OH',
    },
    43554: {
      lat: 41.66024,
      lng: -84.56239,
      city: 'Pioneer',
      state: 'OH',
    },
    43555: {
      lat: 41.43772,
      lng: -84.25596,
      city: 'Ridgeville Corners',
      state: 'OH',
    },
    43556: {
      lat: 41.30575,
      lng: -84.56984,
      city: 'Sherwood',
      state: 'OH',
    },
    43557: {
      lat: 41.48354,
      lng: -84.39693,
      city: 'Stryker',
      state: 'OH',
    },
    43558: {
      lat: 41.5935,
      lng: -83.8781,
      city: 'Swanton',
      state: 'OH',
    },
    43560: {
      lat: 41.70067,
      lng: -83.73799,
      city: 'Sylvania',
      state: 'OH',
    },
    43565: {
      lat: 41.42044,
      lng: -83.74011,
      city: 'Tontogany',
      state: 'OH',
    },
    43566: {
      lat: 41.49467,
      lng: -83.75838,
      city: 'Waterville',
      state: 'OH',
    },
    43567: {
      lat: 41.57592,
      lng: -84.15248,
      city: 'Wauseon',
      state: 'OH',
    },
    43569: {
      lat: 41.3513,
      lng: -83.78844,
      city: 'Weston',
      state: 'OH',
    },
    43570: {
      lat: 41.58616,
      lng: -84.44116,
      city: 'West Unity',
      state: 'OH',
    },
    43571: {
      lat: 41.51574,
      lng: -83.81796,
      city: 'Whitehouse',
      state: 'OH',
    },
    43604: {
      lat: 41.65217,
      lng: -83.53977,
      city: 'Toledo',
      state: 'OH',
    },
    43605: {
      lat: 41.64734,
      lng: -83.50722,
      city: 'Toledo',
      state: 'OH',
    },
    43606: {
      lat: 41.67178,
      lng: -83.61085,
      city: 'Toledo',
      state: 'OH',
    },
    43607: {
      lat: 41.64761,
      lng: -83.60582,
      city: 'Toledo',
      state: 'OH',
    },
    43608: {
      lat: 41.68008,
      lng: -83.53062,
      city: 'Toledo',
      state: 'OH',
    },
    43609: {
      lat: 41.62849,
      lng: -83.57824,
      city: 'Toledo',
      state: 'OH',
    },
    43610: {
      lat: 41.67775,
      lng: -83.5614,
      city: 'Toledo',
      state: 'OH',
    },
    43611: {
      lat: 41.70241,
      lng: -83.49154,
      city: 'Toledo',
      state: 'OH',
    },
    43612: {
      lat: 41.70946,
      lng: -83.54899,
      city: 'Toledo',
      state: 'OH',
    },
    43613: {
      lat: 41.70628,
      lng: -83.60374,
      city: 'Toledo',
      state: 'OH',
    },
    43614: {
      lat: 41.60336,
      lng: -83.62715,
      city: 'Toledo',
      state: 'OH',
    },
    43615: {
      lat: 41.65045,
      lng: -83.67255,
      city: 'Toledo',
      state: 'OH',
    },
    43616: {
      lat: 41.65727,
      lng: -83.40931,
      city: 'Oregon',
      state: 'OH',
    },
    43617: {
      lat: 41.66416,
      lng: -83.72808,
      city: 'Toledo',
      state: 'OH',
    },
    43619: {
      lat: 41.60424,
      lng: -83.47348,
      city: 'Northwood',
      state: 'OH',
    },
    43620: {
      lat: 41.66519,
      lng: -83.5547,
      city: 'Toledo',
      state: 'OH',
    },
    43623: {
      lat: 41.70419,
      lng: -83.65095,
      city: 'Toledo',
      state: 'OH',
    },
    43701: {
      lat: 39.95986,
      lng: -81.99498,
      city: 'Zanesville',
      state: 'OH',
    },
    43711: { lat: 39.84262, lng: -81.57738, city: 'Ava', state: 'OH' },
    43713: {
      lat: 39.98979,
      lng: -81.17368,
      city: 'Barnesville',
      state: 'OH',
    },
    43716: {
      lat: 39.83683,
      lng: -81.00752,
      city: 'Beallsville',
      state: 'OH',
    },
    43717: {
      lat: 39.78943,
      lng: -81.5548,
      city: 'Belle Valley',
      state: 'OH',
    },
    43718: {
      lat: 40.00926,
      lng: -81.00596,
      city: 'Belmont',
      state: 'OH',
    },
    43719: {
      lat: 39.98907,
      lng: -81.07938,
      city: 'Bethesda',
      state: 'OH',
    },
    43720: {
      lat: 39.79272,
      lng: -81.86886,
      city: 'Blue Rock',
      state: 'OH',
    },
    43721: {
      lat: 39.94927,
      lng: -82.26015,
      city: 'Brownsville',
      state: 'OH',
    },
    43722: {
      lat: 39.91933,
      lng: -81.52001,
      city: 'Buffalo',
      state: 'OH',
    },
    43723: {
      lat: 39.96022,
      lng: -81.5412,
      city: 'Byesville',
      state: 'OH',
    },
    43724: {
      lat: 39.73267,
      lng: -81.5166,
      city: 'Caldwell',
      state: 'OH',
    },
    43725: {
      lat: 40.03949,
      lng: -81.59934,
      city: 'Cambridge',
      state: 'OH',
    },
    43727: {
      lat: 39.8714,
      lng: -81.79884,
      city: 'Chandlersville',
      state: 'OH',
    },
    43728: {
      lat: 39.49188,
      lng: -81.89676,
      city: 'Chesterhill',
      state: 'OH',
    },
    43730: {
      lat: 39.6294,
      lng: -82.09435,
      city: 'Corning',
      state: 'OH',
    },
    43731: {
      lat: 39.72768,
      lng: -82.0675,
      city: 'Crooksville',
      state: 'OH',
    },
    43732: {
      lat: 39.84409,
      lng: -81.65883,
      city: 'Cumberland',
      state: 'OH',
    },
    43733: {
      lat: 39.92736,
      lng: -81.53807,
      city: 'Derwent',
      state: 'OH',
    },
    43734: {
      lat: 39.88144,
      lng: -81.90489,
      city: 'Duncan Falls',
      state: 'OH',
    },
    43735: {
      lat: 39.85437,
      lng: -82.11917,
      city: 'East Fultonham',
      state: 'OH',
    },
    43736: {
      lat: 40.05755,
      lng: -81.23689,
      city: 'Fairview',
      state: 'OH',
    },
    43738: {
      lat: 39.8554,
      lng: -82.14063,
      city: 'Fultonham',
      state: 'OH',
    },
    43739: {
      lat: 39.90693,
      lng: -82.29025,
      city: 'Glenford',
      state: 'OH',
    },
    43740: {
      lat: 39.9515,
      lng: -82.21706,
      city: 'Gratiot',
      state: 'OH',
    },
    43746: {
      lat: 39.97146,
      lng: -82.17287,
      city: 'Hopewell',
      state: 'OH',
    },
    43747: {
      lat: 39.85784,
      lng: -81.12128,
      city: 'Jerusalem',
      state: 'OH',
    },
    43748: {
      lat: 39.69788,
      lng: -82.30899,
      city: 'Junction City',
      state: 'OH',
    },
    43749: {
      lat: 40.15932,
      lng: -81.56411,
      city: 'Kimbolton',
      state: 'OH',
    },
    43750: {
      lat: 39.99353,
      lng: -81.50975,
      city: 'Kipling',
      state: 'OH',
    },
    43754: {
      lat: 39.75586,
      lng: -81.24017,
      city: 'Lewisville',
      state: 'OH',
    },
    43755: {
      lat: 40.0537,
      lng: -81.43318,
      city: 'Lore City',
      state: 'OH',
    },
    43756: {
      lat: 39.68993,
      lng: -81.79574,
      city: 'Mcconnelsville',
      state: 'OH',
    },
    43758: { lat: 39.62482, lng: -81.9432, city: 'Malta', state: 'OH' },
    43759: {
      lat: 40.06393,
      lng: -81.06942,
      city: 'Morristown',
      state: 'OH',
    },
    43760: {
      lat: 39.8858,
      lng: -82.19577,
      city: 'Mount Perry',
      state: 'OH',
    },
    43761: {
      lat: 39.66829,
      lng: -82.14798,
      city: 'Moxahala',
      state: 'OH',
    },
    43762: {
      lat: 40.03156,
      lng: -81.74007,
      city: 'New Concord',
      state: 'OH',
    },
    43764: {
      lat: 39.71485,
      lng: -82.19239,
      city: 'New Lexington',
      state: 'OH',
    },
    43766: {
      lat: 39.59492,
      lng: -82.2501,
      city: 'New Straitsville',
      state: 'OH',
    },
    43767: {
      lat: 40.00653,
      lng: -81.80198,
      city: 'Norwich',
      state: 'OH',
    },
    43768: {
      lat: 40.03776,
      lng: -81.44477,
      city: 'Old Washington',
      state: 'OH',
    },
    43771: {
      lat: 39.83586,
      lng: -81.94135,
      city: 'Philo',
      state: 'OH',
    },
    43772: {
      lat: 39.88063,
      lng: -81.52448,
      city: 'Pleasant City',
      state: 'OH',
    },
    43773: {
      lat: 39.98758,
      lng: -81.29636,
      city: 'Quaker City',
      state: 'OH',
    },
    43777: {
      lat: 39.81896,
      lng: -82.08543,
      city: 'Roseville',
      state: 'OH',
    },
    43778: {
      lat: 40.01111,
      lng: -81.37001,
      city: 'Salesville',
      state: 'OH',
    },
    43779: {
      lat: 39.81718,
      lng: -81.42528,
      city: 'Sarahsville',
      state: 'OH',
    },
    43780: {
      lat: 39.92229,
      lng: -81.44024,
      city: 'Senecaville',
      state: 'OH',
    },
    43782: {
      lat: 39.62129,
      lng: -82.2185,
      city: 'Shawnee',
      state: 'OH',
    },
    43783: {
      lat: 39.80389,
      lng: -82.28878,
      city: 'Somerset',
      state: 'OH',
    },
    43786: {
      lat: 39.71086,
      lng: -81.27704,
      city: 'Stafford',
      state: 'OH',
    },
    43787: {
      lat: 39.53292,
      lng: -81.80554,
      city: 'Stockport',
      state: 'OH',
    },
    43788: {
      lat: 39.80175,
      lng: -81.3334,
      city: 'Summerfield',
      state: 'OH',
    },
    43791: {
      lat: 39.87757,
      lng: -82.08877,
      city: 'White Cottage',
      state: 'OH',
    },
    43793: {
      lat: 39.74362,
      lng: -81.10219,
      city: 'Woodsfield',
      state: 'OH',
    },
    43802: {
      lat: 40.09346,
      lng: -81.85687,
      city: 'Adamsville',
      state: 'OH',
    },
    43804: {
      lat: 40.4545,
      lng: -81.73704,
      city: 'Baltic',
      state: 'OH',
    },
    43805: {
      lat: 40.39177,
      lng: -81.97223,
      city: 'Blissfield',
      state: 'OH',
    },
    43811: {
      lat: 40.18285,
      lng: -81.93232,
      city: 'Conesville',
      state: 'OH',
    },
    43812: {
      lat: 40.27268,
      lng: -81.88347,
      city: 'Coshocton',
      state: 'OH',
    },
    43821: {
      lat: 40.13559,
      lng: -82.00695,
      city: 'Dresden',
      state: 'OH',
    },
    43822: {
      lat: 40.17409,
      lng: -82.16958,
      city: 'Frazeysburg',
      state: 'OH',
    },
    43824: {
      lat: 40.36407,
      lng: -81.76116,
      city: 'Fresno',
      state: 'OH',
    },
    43830: {
      lat: 40.06116,
      lng: -82.14812,
      city: 'Nashport',
      state: 'OH',
    },
    43832: {
      lat: 40.27333,
      lng: -81.57931,
      city: 'Newcomerstown',
      state: 'OH',
    },
    43836: {
      lat: 40.2087,
      lng: -81.71854,
      city: 'Plainfield',
      state: 'OH',
    },
    43837: {
      lat: 40.29773,
      lng: -81.47846,
      city: 'Port Washington',
      state: 'OH',
    },
    43840: {
      lat: 40.40511,
      lng: -81.60025,
      city: 'Stone Creek',
      state: 'OH',
    },
    43842: {
      lat: 40.13749,
      lng: -82.01182,
      city: 'Trinway',
      state: 'OH',
    },
    43843: {
      lat: 40.33485,
      lng: -82.17711,
      city: 'Walhonding',
      state: 'OH',
    },
    43844: {
      lat: 40.33349,
      lng: -82.05163,
      city: 'Warsaw',
      state: 'OH',
    },
    43845: {
      lat: 40.25692,
      lng: -81.73144,
      city: 'West Lafayette',
      state: 'OH',
    },
    43901: {
      lat: 40.21691,
      lng: -80.86637,
      city: 'Adena',
      state: 'OH',
    },
    43902: {
      lat: 39.88731,
      lng: -80.95596,
      city: 'Alledonia',
      state: 'OH',
    },
    43903: {
      lat: 40.47042,
      lng: -80.95131,
      city: 'Amsterdam',
      state: 'OH',
    },
    43905: {
      lat: 40.11159,
      lng: -80.8408,
      city: 'Barton',
      state: 'OH',
    },
    43906: {
      lat: 40.0132,
      lng: -80.80118,
      city: 'Bellaire',
      state: 'OH',
    },
    43907: { lat: 40.25697, lng: -81.0205, city: 'Cadiz', state: 'OH' },
    43908: {
      lat: 40.50867,
      lng: -80.87349,
      city: 'Bergholz',
      state: 'OH',
    },
    43909: {
      lat: 40.06774,
      lng: -80.81552,
      city: 'Blaine',
      state: 'OH',
    },
    43910: {
      lat: 40.37039,
      lng: -80.8214,
      city: 'Bloomingdale',
      state: 'OH',
    },
    43912: {
      lat: 40.08708,
      lng: -80.79255,
      city: 'Bridgeport',
      state: 'OH',
    },
    43913: {
      lat: 40.27048,
      lng: -80.63154,
      city: 'Brilliant',
      state: 'OH',
    },
    43914: {
      lat: 39.77532,
      lng: -80.95288,
      city: 'Cameron',
      state: 'OH',
    },
    43915: {
      lat: 39.75775,
      lng: -80.89729,
      city: 'Clarington',
      state: 'OH',
    },
    43917: {
      lat: 40.22396,
      lng: -80.80546,
      city: 'Dillonvale',
      state: 'OH',
    },
    43920: {
      lat: 40.67608,
      lng: -80.57965,
      city: 'East Liverpool',
      state: 'OH',
    },
    43925: {
      lat: 40.44583,
      lng: -80.86242,
      city: 'East Springfield',
      state: 'OH',
    },
    43926: {
      lat: 40.51068,
      lng: -80.62252,
      city: 'Empire',
      state: 'OH',
    },
    43928: {
      lat: 40.01088,
      lng: -80.88172,
      city: 'Glencoe',
      state: 'OH',
    },
    43930: {
      lat: 40.56978,
      lng: -80.77372,
      city: 'Hammondsville',
      state: 'OH',
    },
    43931: {
      lat: 39.67288,
      lng: -80.87137,
      city: 'Hannibal',
      state: 'OH',
    },
    43932: {
      lat: 40.53145,
      lng: -80.74699,
      city: 'Irondale',
      state: 'OH',
    },
    43933: {
      lat: 39.93917,
      lng: -80.89588,
      city: 'Jacobsburg',
      state: 'OH',
    },
    43934: {
      lat: 40.0752,
      lng: -80.79123,
      city: 'Lansing',
      state: 'OH',
    },
    43935: {
      lat: 40.1247,
      lng: -80.75225,
      city: 'Martins Ferry',
      state: 'OH',
    },
    43938: {
      lat: 40.30138,
      lng: -80.67199,
      city: 'Mingo Junction',
      state: 'OH',
    },
    43939: {
      lat: 40.16723,
      lng: -80.79993,
      city: 'Mount Pleasant',
      state: 'OH',
    },
    43940: {
      lat: 40.02863,
      lng: -80.82541,
      city: 'Neffs',
      state: 'OH',
    },
    43942: {
      lat: 39.86367,
      lng: -80.84875,
      city: 'Powhatan Point',
      state: 'OH',
    },
    43943: {
      lat: 40.2242,
      lng: -80.73269,
      city: 'Rayland',
      state: 'OH',
    },
    43944: {
      lat: 40.43623,
      lng: -80.76853,
      city: 'Richmond',
      state: 'OH',
    },
    43945: {
      lat: 40.63156,
      lng: -80.84797,
      city: 'Salineville',
      state: 'OH',
    },
    43946: {
      lat: 39.64792,
      lng: -80.96887,
      city: 'Sardis',
      state: 'OH',
    },
    43947: {
      lat: 39.95448,
      lng: -80.78755,
      city: 'Shadyside',
      state: 'OH',
    },
    43948: {
      lat: 40.26847,
      lng: -80.78002,
      city: 'Smithfield',
      state: 'OH',
    },
    43950: {
      lat: 40.09164,
      lng: -80.92193,
      city: 'Saint Clairsville',
      state: 'OH',
    },
    43951: {
      lat: 40.11475,
      lng: -81.01405,
      city: 'Lafferty',
      state: 'OH',
    },
    43952: {
      lat: 40.39763,
      lng: -80.66278,
      city: 'Steubenville',
      state: 'OH',
    },
    43953: {
      lat: 40.35815,
      lng: -80.70548,
      city: 'Steubenville',
      state: 'OH',
    },
    43961: {
      lat: 40.52343,
      lng: -80.63025,
      city: 'Stratton',
      state: 'OH',
    },
    43962: {
      lat: 40.67315,
      lng: -80.88919,
      city: 'Summitville',
      state: 'OH',
    },
    43963: {
      lat: 40.17155,
      lng: -80.69698,
      city: 'Tiltonsville',
      state: 'OH',
    },
    43964: {
      lat: 40.48695,
      lng: -80.66928,
      city: 'Toronto',
      state: 'OH',
    },
    43967: {
      lat: 40.02309,
      lng: -80.94371,
      city: 'Warnock',
      state: 'OH',
    },
    43968: {
      lat: 40.63365,
      lng: -80.68729,
      city: 'Wellsville',
      state: 'OH',
    },
    43971: {
      lat: 40.15811,
      lng: -80.71301,
      city: 'Yorkville',
      state: 'OH',
    },
    43972: {
      lat: 40.10164,
      lng: -80.9749,
      city: 'Bannock',
      state: 'OH',
    },
    43973: {
      lat: 40.19111,
      lng: -81.27688,
      city: 'Freeport',
      state: 'OH',
    },
    43974: {
      lat: 40.18205,
      lng: -80.88728,
      city: 'Harrisville',
      state: 'OH',
    },
    43976: {
      lat: 40.36981,
      lng: -80.90655,
      city: 'Hopedale',
      state: 'OH',
    },
    43977: {
      lat: 40.14519,
      lng: -81.10451,
      city: 'Flushing',
      state: 'OH',
    },
    43981: {
      lat: 40.18401,
      lng: -80.99466,
      city: 'New Athens',
      state: 'OH',
    },
    43983: {
      lat: 40.13886,
      lng: -81.20296,
      city: 'Piedmont',
      state: 'OH',
    },
    43985: {
      lat: 40.16168,
      lng: -81.13155,
      city: 'Holloway',
      state: 'OH',
    },
    43986: {
      lat: 40.3784,
      lng: -80.99036,
      city: 'Jewett',
      state: 'OH',
    },
    43988: { lat: 40.40486, lng: -81.09925, city: 'Scio', state: 'OH' },
    44001: {
      lat: 41.36825,
      lng: -82.25958,
      city: 'Amherst',
      state: 'OH',
    },
    44003: {
      lat: 41.6202,
      lng: -80.58361,
      city: 'Andover',
      state: 'OH',
    },
    44004: {
      lat: 41.85255,
      lng: -80.78738,
      city: 'Ashtabula',
      state: 'OH',
    },
    44010: {
      lat: 41.76087,
      lng: -80.85152,
      city: 'Austinburg',
      state: 'OH',
    },
    44011: { lat: 41.44855, lng: -82.0187, city: 'Avon', state: 'OH' },
    44012: {
      lat: 41.49443,
      lng: -82.01594,
      city: 'Avon Lake',
      state: 'OH',
    },
    44017: {
      lat: 41.36867,
      lng: -81.86303,
      city: 'Berea',
      state: 'OH',
    },
    44021: {
      lat: 41.44141,
      lng: -81.14579,
      city: 'Burton',
      state: 'OH',
    },
    44022: {
      lat: 41.44747,
      lng: -81.4045,
      city: 'Chagrin Falls',
      state: 'OH',
    },
    44023: {
      lat: 41.38732,
      lng: -81.28858,
      city: 'Chagrin Falls',
      state: 'OH',
    },
    44024: {
      lat: 41.57668,
      lng: -81.19112,
      city: 'Chardon',
      state: 'OH',
    },
    44026: {
      lat: 41.53182,
      lng: -81.3305,
      city: 'Chesterland',
      state: 'OH',
    },
    44028: {
      lat: 41.30986,
      lng: -81.93959,
      city: 'Columbia Station',
      state: 'OH',
    },
    44030: {
      lat: 41.8973,
      lng: -80.58061,
      city: 'Conneaut',
      state: 'OH',
    },
    44032: {
      lat: 41.6658,
      lng: -80.66954,
      city: 'Dorset',
      state: 'OH',
    },
    44035: {
      lat: 41.36539,
      lng: -82.13025,
      city: 'Elyria',
      state: 'OH',
    },
    44039: {
      lat: 41.38404,
      lng: -82.02014,
      city: 'North Ridgeville',
      state: 'OH',
    },
    44040: {
      lat: 41.53128,
      lng: -81.4112,
      city: 'Gates Mills',
      state: 'OH',
    },
    44041: {
      lat: 41.77579,
      lng: -80.95223,
      city: 'Geneva',
      state: 'OH',
    },
    44044: {
      lat: 41.26779,
      lng: -82.04188,
      city: 'Grafton',
      state: 'OH',
    },
    44045: {
      lat: 41.7461,
      lng: -81.28512,
      city: 'Grand River',
      state: 'OH',
    },
    44046: {
      lat: 41.54157,
      lng: -81.07227,
      city: 'Huntsburg',
      state: 'OH',
    },
    44047: {
      lat: 41.71887,
      lng: -80.73921,
      city: 'Jefferson',
      state: 'OH',
    },
    44048: {
      lat: 41.84697,
      lng: -80.64343,
      city: 'Kingsville',
      state: 'OH',
    },
    44049: {
      lat: 41.26626,
      lng: -82.30553,
      city: 'Kipton',
      state: 'OH',
    },
    44050: {
      lat: 41.24853,
      lng: -82.13119,
      city: 'Lagrange',
      state: 'OH',
    },
    44052: {
      lat: 41.45519,
      lng: -82.16133,
      city: 'Lorain',
      state: 'OH',
    },
    44053: {
      lat: 41.42694,
      lng: -82.22138,
      city: 'Lorain',
      state: 'OH',
    },
    44054: {
      lat: 41.46419,
      lng: -82.0931,
      city: 'Sheffield Lake',
      state: 'OH',
    },
    44055: {
      lat: 41.43309,
      lng: -82.13354,
      city: 'Lorain',
      state: 'OH',
    },
    44056: {
      lat: 41.31465,
      lng: -81.49868,
      city: 'Macedonia',
      state: 'OH',
    },
    44057: {
      lat: 41.76675,
      lng: -81.05965,
      city: 'Madison',
      state: 'OH',
    },
    44060: {
      lat: 41.67742,
      lng: -81.32806,
      city: 'Mentor',
      state: 'OH',
    },
    44062: {
      lat: 41.45899,
      lng: -81.02698,
      city: 'Middlefield',
      state: 'OH',
    },
    44064: {
      lat: 41.60567,
      lng: -81.0477,
      city: 'Montville',
      state: 'OH',
    },
    44065: {
      lat: 41.46308,
      lng: -81.24408,
      city: 'Newbury',
      state: 'OH',
    },
    44067: {
      lat: 41.31386,
      lng: -81.5476,
      city: 'Northfield',
      state: 'OH',
    },
    44068: {
      lat: 41.90619,
      lng: -80.67886,
      city: 'North Kingsville',
      state: 'OH',
    },
    44070: {
      lat: 41.41493,
      lng: -81.91905,
      city: 'North Olmsted',
      state: 'OH',
    },
    44072: {
      lat: 41.47196,
      lng: -81.32917,
      city: 'Novelty',
      state: 'OH',
    },
    44074: {
      lat: 41.28858,
      lng: -82.23643,
      city: 'Oberlin',
      state: 'OH',
    },
    44076: {
      lat: 41.52728,
      lng: -80.81867,
      city: 'Orwell',
      state: 'OH',
    },
    44077: {
      lat: 41.69933,
      lng: -81.21142,
      city: 'Painesville',
      state: 'OH',
    },
    44080: {
      lat: 41.36886,
      lng: -81.05966,
      city: 'Parkman',
      state: 'OH',
    },
    44081: {
      lat: 41.76589,
      lng: -81.14551,
      city: 'Perry',
      state: 'OH',
    },
    44082: {
      lat: 41.76012,
      lng: -80.56558,
      city: 'Pierpont',
      state: 'OH',
    },
    44084: {
      lat: 41.67242,
      lng: -80.89605,
      city: 'Rock Creek',
      state: 'OH',
    },
    44085: { lat: 41.60463, lng: -80.87128, city: 'Rome', state: 'OH' },
    44086: {
      lat: 41.67443,
      lng: -81.05599,
      city: 'Thompson',
      state: 'OH',
    },
    44087: {
      lat: 41.31706,
      lng: -81.44106,
      city: 'Twinsburg',
      state: 'OH',
    },
    44089: {
      lat: 41.38894,
      lng: -82.36552,
      city: 'Vermilion',
      state: 'OH',
    },
    44090: {
      lat: 41.16757,
      lng: -82.22836,
      city: 'Wellington',
      state: 'OH',
    },
    44092: {
      lat: 41.59797,
      lng: -81.46867,
      city: 'Wickliffe',
      state: 'OH',
    },
    44093: {
      lat: 41.53478,
      lng: -80.61637,
      city: 'Williamsfield',
      state: 'OH',
    },
    44094: {
      lat: 41.60985,
      lng: -81.3791,
      city: 'Willoughby',
      state: 'OH',
    },
    44095: {
      lat: 41.65223,
      lng: -81.44209,
      city: 'Eastlake',
      state: 'OH',
    },
    44099: {
      lat: 41.55125,
      lng: -80.97446,
      city: 'Windsor',
      state: 'OH',
    },
    44101: {
      lat: 41.48988,
      lng: -81.6717,
      city: 'Cleveland',
      state: 'OH',
    },
    44102: {
      lat: 41.4749,
      lng: -81.73751,
      city: 'Cleveland',
      state: 'OH',
    },
    44103: {
      lat: 41.51559,
      lng: -81.64301,
      city: 'Cleveland',
      state: 'OH',
    },
    44104: {
      lat: 41.48238,
      lng: -81.62702,
      city: 'Cleveland',
      state: 'OH',
    },
    44105: {
      lat: 41.45069,
      lng: -81.63032,
      city: 'Cleveland',
      state: 'OH',
    },
    44106: {
      lat: 41.5062,
      lng: -81.60679,
      city: 'Cleveland',
      state: 'OH',
    },
    44107: {
      lat: 41.48263,
      lng: -81.80025,
      city: 'Lakewood',
      state: 'OH',
    },
    44108: {
      lat: 41.53992,
      lng: -81.60988,
      city: 'Cleveland',
      state: 'OH',
    },
    44109: {
      lat: 41.44457,
      lng: -81.69681,
      city: 'Cleveland',
      state: 'OH',
    },
    44110: {
      lat: 41.56365,
      lng: -81.57111,
      city: 'Cleveland',
      state: 'OH',
    },
    44111: {
      lat: 41.45775,
      lng: -81.78986,
      city: 'Cleveland',
      state: 'OH',
    },
    44112: {
      lat: 41.53562,
      lng: -81.57369,
      city: 'Cleveland',
      state: 'OH',
    },
    44113: {
      lat: 41.48343,
      lng: -81.69638,
      city: 'Cleveland',
      state: 'OH',
    },
    44114: {
      lat: 41.5137,
      lng: -81.67625,
      city: 'Cleveland',
      state: 'OH',
    },
    44115: {
      lat: 41.49211,
      lng: -81.66999,
      city: 'Cleveland',
      state: 'OH',
    },
    44116: {
      lat: 41.47011,
      lng: -81.85194,
      city: 'Rocky River',
      state: 'OH',
    },
    44117: {
      lat: 41.57145,
      lng: -81.52695,
      city: 'Euclid',
      state: 'OH',
    },
    44118: {
      lat: 41.5016,
      lng: -81.55653,
      city: 'Cleveland',
      state: 'OH',
    },
    44119: {
      lat: 41.58673,
      lng: -81.54748,
      city: 'Cleveland',
      state: 'OH',
    },
    44120: {
      lat: 41.47337,
      lng: -81.58015,
      city: 'Cleveland',
      state: 'OH',
    },
    44121: {
      lat: 41.52626,
      lng: -81.53246,
      city: 'Cleveland',
      state: 'OH',
    },
    44122: {
      lat: 41.4701,
      lng: -81.51272,
      city: 'Beachwood',
      state: 'OH',
    },
    44123: {
      lat: 41.60365,
      lng: -81.52441,
      city: 'Euclid',
      state: 'OH',
    },
    44124: {
      lat: 41.49999,
      lng: -81.46774,
      city: 'Cleveland',
      state: 'OH',
    },
    44125: {
      lat: 41.40658,
      lng: -81.61548,
      city: 'Cleveland',
      state: 'OH',
    },
    44126: {
      lat: 41.44263,
      lng: -81.85278,
      city: 'Cleveland',
      state: 'OH',
    },
    44127: {
      lat: 41.471,
      lng: -81.64874,
      city: 'Cleveland',
      state: 'OH',
    },
    44128: {
      lat: 41.43766,
      lng: -81.54036,
      city: 'Cleveland',
      state: 'OH',
    },
    44129: {
      lat: 41.39035,
      lng: -81.73536,
      city: 'Cleveland',
      state: 'OH',
    },
    44130: {
      lat: 41.378,
      lng: -81.7868,
      city: 'Cleveland',
      state: 'OH',
    },
    44131: {
      lat: 41.3835,
      lng: -81.65349,
      city: 'Independence',
      state: 'OH',
    },
    44132: {
      lat: 41.60484,
      lng: -81.49958,
      city: 'Euclid',
      state: 'OH',
    },
    44133: {
      lat: 41.31388,
      lng: -81.74497,
      city: 'North Royalton',
      state: 'OH',
    },
    44134: {
      lat: 41.38496,
      lng: -81.70459,
      city: 'Cleveland',
      state: 'OH',
    },
    44135: {
      lat: 41.42472,
      lng: -81.82135,
      city: 'Cleveland',
      state: 'OH',
    },
    44136: {
      lat: 41.31265,
      lng: -81.81064,
      city: 'Strongsville',
      state: 'OH',
    },
    44137: {
      lat: 41.40903,
      lng: -81.56274,
      city: 'Maple Heights',
      state: 'OH',
    },
    44138: {
      lat: 41.37363,
      lng: -81.92302,
      city: 'Olmsted Falls',
      state: 'OH',
    },
    44139: {
      lat: 41.38325,
      lng: -81.44439,
      city: 'Solon',
      state: 'OH',
    },
    44140: {
      lat: 41.48508,
      lng: -81.93171,
      city: 'Bay Village',
      state: 'OH',
    },
    44141: {
      lat: 41.30139,
      lng: -81.61439,
      city: 'Brecksville',
      state: 'OH',
    },
    44142: {
      lat: 41.40205,
      lng: -81.82406,
      city: 'Brookpark',
      state: 'OH',
    },
    44143: {
      lat: 41.55358,
      lng: -81.47513,
      city: 'Cleveland',
      state: 'OH',
    },
    44144: {
      lat: 41.43738,
      lng: -81.74272,
      city: 'Cleveland',
      state: 'OH',
    },
    44145: {
      lat: 41.45245,
      lng: -81.92944,
      city: 'Westlake',
      state: 'OH',
    },
    44146: {
      lat: 41.38161,
      lng: -81.53018,
      city: 'Bedford',
      state: 'OH',
    },
    44147: {
      lat: 41.31956,
      lng: -81.67797,
      city: 'Broadview Heights',
      state: 'OH',
    },
    44149: {
      lat: 41.31223,
      lng: -81.8563,
      city: 'Strongsville',
      state: 'OH',
    },
    44201: {
      lat: 41.02741,
      lng: -81.18354,
      city: 'Atwater',
      state: 'OH',
    },
    44202: {
      lat: 41.31645,
      lng: -81.34243,
      city: 'Aurora',
      state: 'OH',
    },
    44203: {
      lat: 41.02013,
      lng: -81.62896,
      city: 'Barberton',
      state: 'OH',
    },
    44212: {
      lat: 41.24386,
      lng: -81.82863,
      city: 'Brunswick',
      state: 'OH',
    },
    44214: {
      lat: 40.95081,
      lng: -81.99935,
      city: 'Burbank',
      state: 'OH',
    },
    44215: {
      lat: 41.07195,
      lng: -81.8994,
      city: 'Chippewa Lake',
      state: 'OH',
    },
    44216: {
      lat: 40.93848,
      lng: -81.58397,
      city: 'Clinton',
      state: 'OH',
    },
    44217: {
      lat: 40.95561,
      lng: -81.9133,
      city: 'Creston',
      state: 'OH',
    },
    44221: {
      lat: 41.13898,
      lng: -81.47624,
      city: 'Cuyahoga Falls',
      state: 'OH',
    },
    44223: {
      lat: 41.16968,
      lng: -81.53051,
      city: 'Cuyahoga Falls',
      state: 'OH',
    },
    44224: { lat: 41.17697, lng: -81.44016, city: 'Stow', state: 'OH' },
    44230: {
      lat: 40.96237,
      lng: -81.69064,
      city: 'Doylestown',
      state: 'OH',
    },
    44231: {
      lat: 41.30692,
      lng: -81.07197,
      city: 'Garrettsville',
      state: 'OH',
    },
    44232: {
      lat: 40.93254,
      lng: -81.46354,
      city: 'Green',
      state: 'OH',
    },
    44233: {
      lat: 41.23943,
      lng: -81.73513,
      city: 'Hinckley',
      state: 'OH',
    },
    44234: {
      lat: 41.33454,
      lng: -81.15822,
      city: 'Hiram',
      state: 'OH',
    },
    44235: {
      lat: 41.0316,
      lng: -82.12246,
      city: 'Homerville',
      state: 'OH',
    },
    44236: {
      lat: 41.24669,
      lng: -81.44775,
      city: 'Hudson',
      state: 'OH',
    },
    44240: { lat: 41.13329, lng: -81.34241, city: 'Kent', state: 'OH' },
    44241: {
      lat: 41.23945,
      lng: -81.34728,
      city: 'Streetsboro',
      state: 'OH',
    },
    44243: { lat: 41.14793, lng: -81.34046, city: 'Kent', state: 'OH' },
    44250: {
      lat: 41.02159,
      lng: -81.43331,
      city: 'Lakemore',
      state: 'OH',
    },
    44251: {
      lat: 41.02814,
      lng: -81.92764,
      city: 'Westfield Center',
      state: 'OH',
    },
    44253: {
      lat: 41.16571,
      lng: -82.03767,
      city: 'Litchfield',
      state: 'OH',
    },
    44254: { lat: 41.04522, lng: -82.01282, city: 'Lodi', state: 'OH' },
    44255: {
      lat: 41.28658,
      lng: -81.22982,
      city: 'Mantua',
      state: 'OH',
    },
    44256: {
      lat: 41.14092,
      lng: -81.85954,
      city: 'Medina',
      state: 'OH',
    },
    44260: {
      lat: 41.03684,
      lng: -81.34079,
      city: 'Mogadore',
      state: 'OH',
    },
    44262: {
      lat: 41.13901,
      lng: -81.43492,
      city: 'Munroe Falls',
      state: 'OH',
    },
    44264: {
      lat: 41.22966,
      lng: -81.54311,
      city: 'Peninsula',
      state: 'OH',
    },
    44266: {
      lat: 41.1662,
      lng: -81.19829,
      city: 'Ravenna',
      state: 'OH',
    },
    44270: {
      lat: 40.96317,
      lng: -81.77696,
      city: 'Rittman',
      state: 'OH',
    },
    44272: {
      lat: 41.08691,
      lng: -81.18244,
      city: 'Rootstown',
      state: 'OH',
    },
    44273: {
      lat: 41.0291,
      lng: -81.87739,
      city: 'Seville',
      state: 'OH',
    },
    44274: {
      lat: 41.09944,
      lng: -81.7285,
      city: 'Sharon Center',
      state: 'OH',
    },
    44275: {
      lat: 41.09976,
      lng: -82.09927,
      city: 'Spencer',
      state: 'OH',
    },
    44276: {
      lat: 40.93667,
      lng: -81.83502,
      city: 'Sterling',
      state: 'OH',
    },
    44278: {
      lat: 41.10052,
      lng: -81.42137,
      city: 'Tallmadge',
      state: 'OH',
    },
    44280: {
      lat: 41.23812,
      lng: -81.92339,
      city: 'Valley City',
      state: 'OH',
    },
    44281: {
      lat: 41.05851,
      lng: -81.73854,
      city: 'Wadsworth',
      state: 'OH',
    },
    44285: {
      lat: 41.16241,
      lng: -81.06595,
      city: 'Wayland',
      state: 'OH',
    },
    44286: {
      lat: 41.23668,
      lng: -81.64017,
      city: 'Richfield',
      state: 'OH',
    },
    44287: {
      lat: 40.94,
      lng: -82.1005,
      city: 'West Salem',
      state: 'OH',
    },
    44288: {
      lat: 41.2392,
      lng: -81.08354,
      city: 'Windham',
      state: 'OH',
    },
    44301: {
      lat: 41.04398,
      lng: -81.52449,
      city: 'Akron',
      state: 'OH',
    },
    44302: {
      lat: 41.08951,
      lng: -81.53857,
      city: 'Akron',
      state: 'OH',
    },
    44303: {
      lat: 41.10448,
      lng: -81.53732,
      city: 'Akron',
      state: 'OH',
    },
    44304: {
      lat: 41.08585,
      lng: -81.50908,
      city: 'Akron',
      state: 'OH',
    },
    44305: {
      lat: 41.07643,
      lng: -81.46226,
      city: 'Akron',
      state: 'OH',
    },
    44306: {
      lat: 41.03927,
      lng: -81.48316,
      city: 'Akron',
      state: 'OH',
    },
    44307: { lat: 41.07049, lng: -81.546, city: 'Akron', state: 'OH' },
    44308: { lat: 41.0818, lng: -81.51751, city: 'Akron', state: 'OH' },
    44310: {
      lat: 41.10771,
      lng: -81.49948,
      city: 'Akron',
      state: 'OH',
    },
    44311: {
      lat: 41.06427,
      lng: -81.52047,
      city: 'Akron',
      state: 'OH',
    },
    44312: { lat: 41.01462, lng: -81.4412, city: 'Akron', state: 'OH' },
    44313: {
      lat: 41.13239,
      lng: -81.56512,
      city: 'Akron',
      state: 'OH',
    },
    44314: {
      lat: 41.04048,
      lng: -81.55838,
      city: 'Akron',
      state: 'OH',
    },
    44319: {
      lat: 40.98402,
      lng: -81.53169,
      city: 'Akron',
      state: 'OH',
    },
    44320: {
      lat: 41.07818,
      lng: -81.58379,
      city: 'Akron',
      state: 'OH',
    },
    44321: {
      lat: 41.09994,
      lng: -81.65182,
      city: 'Akron',
      state: 'OH',
    },
    44325: {
      lat: 41.07605,
      lng: -81.51221,
      city: 'Akron',
      state: 'OH',
    },
    44333: {
      lat: 41.16195,
      lng: -81.63419,
      city: 'Akron',
      state: 'OH',
    },
    44401: {
      lat: 41.02457,
      lng: -80.94658,
      city: 'Berlin Center',
      state: 'OH',
    },
    44402: {
      lat: 41.38893,
      lng: -80.85513,
      city: 'Bristolville',
      state: 'OH',
    },
    44403: {
      lat: 41.24053,
      lng: -80.58265,
      city: 'Brookfield',
      state: 'OH',
    },
    44404: {
      lat: 41.3277,
      lng: -80.54861,
      city: 'Burghill',
      state: 'OH',
    },
    44405: {
      lat: 41.07787,
      lng: -80.59136,
      city: 'Campbell',
      state: 'OH',
    },
    44406: {
      lat: 41.01132,
      lng: -80.77194,
      city: 'Canfield',
      state: 'OH',
    },
    44408: {
      lat: 40.88487,
      lng: -80.68569,
      city: 'Columbiana',
      state: 'OH',
    },
    44410: {
      lat: 41.34817,
      lng: -80.72629,
      city: 'Cortland',
      state: 'OH',
    },
    44411: {
      lat: 41.0326,
      lng: -81.05092,
      city: 'Deerfield',
      state: 'OH',
    },
    44412: {
      lat: 41.09671,
      lng: -81.0229,
      city: 'Diamond',
      state: 'OH',
    },
    44413: {
      lat: 40.84803,
      lng: -80.5549,
      city: 'East Palestine',
      state: 'OH',
    },
    44415: {
      lat: 40.77068,
      lng: -80.70455,
      city: 'Elkton',
      state: 'OH',
    },
    44417: {
      lat: 41.43336,
      lng: -80.66329,
      city: 'Farmdale',
      state: 'OH',
    },
    44418: {
      lat: 41.30692,
      lng: -80.60465,
      city: 'Fowler',
      state: 'OH',
    },
    44420: {
      lat: 41.17384,
      lng: -80.67726,
      city: 'Girard',
      state: 'OH',
    },
    44423: {
      lat: 40.7524,
      lng: -80.89963,
      city: 'Hanoverton',
      state: 'OH',
    },
    44424: {
      lat: 41.32201,
      lng: -80.58326,
      city: 'Hartford',
      state: 'OH',
    },
    44425: {
      lat: 41.17022,
      lng: -80.57395,
      city: 'Hubbard',
      state: 'OH',
    },
    44427: {
      lat: 40.71672,
      lng: -80.95686,
      city: 'Kensington',
      state: 'OH',
    },
    44428: {
      lat: 41.43458,
      lng: -80.5779,
      city: 'Kinsman',
      state: 'OH',
    },
    44429: {
      lat: 41.09648,
      lng: -80.98156,
      city: 'Lake Milton',
      state: 'OH',
    },
    44430: {
      lat: 41.24011,
      lng: -80.90804,
      city: 'Leavittsburg',
      state: 'OH',
    },
    44431: {
      lat: 40.84748,
      lng: -80.75365,
      city: 'Leetonia',
      state: 'OH',
    },
    44432: {
      lat: 40.74974,
      lng: -80.75149,
      city: 'Lisbon',
      state: 'OH',
    },
    44436: {
      lat: 41.05775,
      lng: -80.54241,
      city: 'Lowellville',
      state: 'OH',
    },
    44437: {
      lat: 41.15536,
      lng: -80.73,
      city: 'McDonald',
      state: 'OH',
    },
    44438: {
      lat: 41.24252,
      lng: -80.5345,
      city: 'Masury',
      state: 'OH',
    },
    44439: {
      lat: 41.45436,
      lng: -80.95552,
      city: 'Mesopotamia',
      state: 'OH',
    },
    44440: {
      lat: 41.1482,
      lng: -80.78439,
      city: 'Mineral Ridge',
      state: 'OH',
    },
    44441: {
      lat: 40.75802,
      lng: -80.55264,
      city: 'Negley',
      state: 'OH',
    },
    44442: {
      lat: 40.96591,
      lng: -80.54963,
      city: 'New Middletown',
      state: 'OH',
    },
    44443: {
      lat: 40.92351,
      lng: -80.59473,
      city: 'New Springfield',
      state: 'OH',
    },
    44444: {
      lat: 41.17559,
      lng: -80.97647,
      city: 'Newton Falls',
      state: 'OH',
    },
    44445: {
      lat: 40.84834,
      lng: -80.61732,
      city: 'New Waterford',
      state: 'OH',
    },
    44446: {
      lat: 41.18958,
      lng: -80.74692,
      city: 'Niles',
      state: 'OH',
    },
    44449: {
      lat: 40.97957,
      lng: -81.02446,
      city: 'North Benton',
      state: 'OH',
    },
    44450: {
      lat: 41.45397,
      lng: -80.82576,
      city: 'North Bloomfield',
      state: 'OH',
    },
    44451: {
      lat: 41.08104,
      lng: -80.86887,
      city: 'North Jackson',
      state: 'OH',
    },
    44452: {
      lat: 40.94711,
      lng: -80.66232,
      city: 'North Lima',
      state: 'OH',
    },
    44454: {
      lat: 40.9197,
      lng: -80.53976,
      city: 'Petersburg',
      state: 'OH',
    },
    44455: {
      lat: 40.77581,
      lng: -80.60485,
      city: 'Rogers',
      state: 'OH',
    },
    44460: {
      lat: 40.90221,
      lng: -80.87026,
      city: 'Salem',
      state: 'OH',
    },
    44470: {
      lat: 41.29783,
      lng: -80.95999,
      city: 'Southington',
      state: 'OH',
    },
    44471: {
      lat: 41.04793,
      lng: -80.58973,
      city: 'Struthers',
      state: 'OH',
    },
    44473: {
      lat: 41.24684,
      lng: -80.66326,
      city: 'Vienna',
      state: 'OH',
    },
    44481: {
      lat: 41.22736,
      lng: -80.86344,
      city: 'Warren',
      state: 'OH',
    },
    44483: {
      lat: 41.26537,
      lng: -80.81575,
      city: 'Warren',
      state: 'OH',
    },
    44484: {
      lat: 41.2348,
      lng: -80.74962,
      city: 'Warren',
      state: 'OH',
    },
    44485: { lat: 41.24032, lng: -80.848, city: 'Warren', state: 'OH' },
    44490: {
      lat: 40.90041,
      lng: -80.76801,
      city: 'Washingtonville',
      state: 'OH',
    },
    44491: {
      lat: 41.37368,
      lng: -80.96478,
      city: 'West Farmington',
      state: 'OH',
    },
    44493: {
      lat: 40.82192,
      lng: -80.88864,
      city: 'Winona',
      state: 'OH',
    },
    44502: {
      lat: 41.08365,
      lng: -80.64253,
      city: 'Youngstown',
      state: 'OH',
    },
    44503: {
      lat: 41.09918,
      lng: -80.64912,
      city: 'Youngstown',
      state: 'OH',
    },
    44504: {
      lat: 41.12327,
      lng: -80.65363,
      city: 'Youngstown',
      state: 'OH',
    },
    44505: {
      lat: 41.12735,
      lng: -80.61687,
      city: 'Youngstown',
      state: 'OH',
    },
    44506: {
      lat: 41.09356,
      lng: -80.62583,
      city: 'Youngstown',
      state: 'OH',
    },
    44507: {
      lat: 41.07436,
      lng: -80.65542,
      city: 'Youngstown',
      state: 'OH',
    },
    44509: {
      lat: 41.10986,
      lng: -80.69655,
      city: 'Youngstown',
      state: 'OH',
    },
    44510: {
      lat: 41.12246,
      lng: -80.67424,
      city: 'Youngstown',
      state: 'OH',
    },
    44511: {
      lat: 41.06927,
      lng: -80.69617,
      city: 'Youngstown',
      state: 'OH',
    },
    44512: {
      lat: 41.0249,
      lng: -80.66757,
      city: 'Youngstown',
      state: 'OH',
    },
    44514: {
      lat: 40.99838,
      lng: -80.60868,
      city: 'Youngstown',
      state: 'OH',
    },
    44515: {
      lat: 41.1029,
      lng: -80.76201,
      city: 'Youngstown',
      state: 'OH',
    },
    44555: {
      lat: 41.10726,
      lng: -80.64875,
      city: 'Youngstown',
      state: 'OH',
    },
    44601: {
      lat: 40.91978,
      lng: -81.1278,
      city: 'Alliance',
      state: 'OH',
    },
    44606: {
      lat: 40.73313,
      lng: -81.79625,
      city: 'Apple Creek',
      state: 'OH',
    },
    44607: {
      lat: 40.69589,
      lng: -81.02685,
      city: 'Augusta',
      state: 'OH',
    },
    44608: {
      lat: 40.65241,
      lng: -81.59714,
      city: 'Beach City',
      state: 'OH',
    },
    44609: { lat: 40.9121, lng: -80.9879, city: 'Beloit', state: 'OH' },
    44610: {
      lat: 40.56078,
      lng: -81.80139,
      city: 'Berlin',
      state: 'OH',
    },
    44611: {
      lat: 40.60785,
      lng: -82.08141,
      city: 'Big Prairie',
      state: 'OH',
    },
    44612: {
      lat: 40.63488,
      lng: -81.46098,
      city: 'Bolivar',
      state: 'OH',
    },
    44613: {
      lat: 40.71338,
      lng: -81.60026,
      city: 'Brewster',
      state: 'OH',
    },
    44614: {
      lat: 40.88689,
      lng: -81.58747,
      city: 'Canal Fulton',
      state: 'OH',
    },
    44615: {
      lat: 40.5669,
      lng: -81.0739,
      city: 'Carrollton',
      state: 'OH',
    },
    44618: {
      lat: 40.7722,
      lng: -81.68925,
      city: 'Dalton',
      state: 'OH',
    },
    44619: {
      lat: 40.90536,
      lng: -80.95381,
      city: 'Damascus',
      state: 'OH',
    },
    44620: {
      lat: 40.5811,
      lng: -81.21592,
      city: 'Dellroy',
      state: 'OH',
    },
    44621: {
      lat: 40.42878,
      lng: -81.28955,
      city: 'Dennison',
      state: 'OH',
    },
    44622: {
      lat: 40.54883,
      lng: -81.48375,
      city: 'Dover',
      state: 'OH',
    },
    44624: {
      lat: 40.61249,
      lng: -81.66157,
      city: 'Dundee',
      state: 'OH',
    },
    44625: {
      lat: 40.7623,
      lng: -80.99919,
      city: 'East Rochester',
      state: 'OH',
    },
    44626: {
      lat: 40.68994,
      lng: -81.38404,
      city: 'East Sparta',
      state: 'OH',
    },
    44627: {
      lat: 40.66665,
      lng: -81.83393,
      city: 'Fredericksburg',
      state: 'OH',
    },
    44628: {
      lat: 40.538,
      lng: -82.1551,
      city: 'Glenmont',
      state: 'OH',
    },
    44629: {
      lat: 40.35107,
      lng: -81.44553,
      city: 'Gnadenhutten',
      state: 'OH',
    },
    44630: {
      lat: 40.92695,
      lng: -81.40408,
      city: 'Greentown',
      state: 'OH',
    },
    44632: {
      lat: 40.96401,
      lng: -81.3048,
      city: 'Hartville',
      state: 'OH',
    },
    44633: {
      lat: 40.63182,
      lng: -81.93601,
      city: 'Holmesville',
      state: 'OH',
    },
    44634: {
      lat: 40.83385,
      lng: -81.04685,
      city: 'Homeworth',
      state: 'OH',
    },
    44637: {
      lat: 40.47996,
      lng: -82.03347,
      city: 'Killbuck',
      state: 'OH',
    },
    44638: {
      lat: 40.6502,
      lng: -82.13521,
      city: 'Lakeville',
      state: 'OH',
    },
    44639: {
      lat: 40.45166,
      lng: -81.20963,
      city: 'Leesville',
      state: 'OH',
    },
    44640: {
      lat: 40.98455,
      lng: -81.14858,
      city: 'Limaville',
      state: 'OH',
    },
    44641: {
      lat: 40.86528,
      lng: -81.23977,
      city: 'Louisville',
      state: 'OH',
    },
    44643: {
      lat: 40.64304,
      lng: -81.2998,
      city: 'Magnolia',
      state: 'OH',
    },
    44644: {
      lat: 40.68691,
      lng: -81.17639,
      city: 'Malvern',
      state: 'OH',
    },
    44645: {
      lat: 40.90796,
      lng: -81.71845,
      city: 'Marshallville',
      state: 'OH',
    },
    44646: {
      lat: 40.81055,
      lng: -81.49835,
      city: 'Massillon',
      state: 'OH',
    },
    44647: {
      lat: 40.79537,
      lng: -81.56575,
      city: 'Massillon',
      state: 'OH',
    },
    44651: {
      lat: 40.6231,
      lng: -80.9556,
      city: 'Mechanicstown',
      state: 'OH',
    },
    44652: {
      lat: 40.90088,
      lng: -81.33015,
      city: 'Middlebranch',
      state: 'OH',
    },
    44653: {
      lat: 40.43711,
      lng: -81.37105,
      city: 'Midvale',
      state: 'OH',
    },
    44654: {
      lat: 40.53861,
      lng: -81.87162,
      city: 'Millersburg',
      state: 'OH',
    },
    44656: {
      lat: 40.57027,
      lng: -81.33104,
      city: 'Mineral City',
      state: 'OH',
    },
    44657: {
      lat: 40.74313,
      lng: -81.09343,
      city: 'Minerva',
      state: 'OH',
    },
    44659: {
      lat: 40.6948,
      lng: -81.70191,
      city: 'Mount Eaton',
      state: 'OH',
    },
    44660: {
      lat: 40.62382,
      lng: -81.77487,
      city: 'Mount Hope',
      state: 'OH',
    },
    44661: {
      lat: 40.5874,
      lng: -82.11662,
      city: 'Nashville',
      state: 'OH',
    },
    44662: {
      lat: 40.71036,
      lng: -81.55468,
      city: 'Navarre',
      state: 'OH',
    },
    44663: {
      lat: 40.46066,
      lng: -81.44961,
      city: 'New Philadelphia',
      state: 'OH',
    },
    44665: {
      lat: 40.83555,
      lng: -80.98706,
      city: 'North Georgetown',
      state: 'OH',
    },
    44666: {
      lat: 40.84232,
      lng: -81.63202,
      city: 'North Lawrence',
      state: 'OH',
    },
    44667: {
      lat: 40.83386,
      lng: -81.76495,
      city: 'Orrville',
      state: 'OH',
    },
    44669: {
      lat: 40.79613,
      lng: -81.15619,
      city: 'Paris',
      state: 'OH',
    },
    44670: {
      lat: 40.76305,
      lng: -81.18788,
      city: 'Robertsville',
      state: 'OH',
    },
    44671: {
      lat: 40.64511,
      lng: -81.36565,
      city: 'Sandyville',
      state: 'OH',
    },
    44672: {
      lat: 40.92045,
      lng: -81.02375,
      city: 'Sebring',
      state: 'OH',
    },
    44675: {
      lat: 40.50245,
      lng: -81.24975,
      city: 'Sherrodsville',
      state: 'OH',
    },
    44676: {
      lat: 40.6891,
      lng: -82.03266,
      city: 'Shreve',
      state: 'OH',
    },
    44677: {
      lat: 40.87254,
      lng: -81.85983,
      city: 'Smithville',
      state: 'OH',
    },
    44678: {
      lat: 40.56478,
      lng: -81.36408,
      city: 'Somerdale',
      state: 'OH',
    },
    44680: {
      lat: 40.60174,
      lng: -81.54512,
      city: 'Strasburg',
      state: 'OH',
    },
    44681: {
      lat: 40.50018,
      lng: -81.6625,
      city: 'Sugarcreek',
      state: 'OH',
    },
    44682: {
      lat: 40.39765,
      lng: -81.40323,
      city: 'Tuscarawas',
      state: 'OH',
    },
    44683: {
      lat: 40.35505,
      lng: -81.3218,
      city: 'Uhrichsville',
      state: 'OH',
    },
    44685: {
      lat: 40.9596,
      lng: -81.42361,
      city: 'Uniontown',
      state: 'OH',
    },
    44687: {
      lat: 40.53941,
      lng: -81.71189,
      city: 'Walnut Creek',
      state: 'OH',
    },
    44688: {
      lat: 40.68458,
      lng: -81.25757,
      city: 'Waynesburg',
      state: 'OH',
    },
    44689: {
      lat: 40.65071,
      lng: -81.66209,
      city: 'Wilmot',
      state: 'OH',
    },
    44690: {
      lat: 40.61353,
      lng: -81.69391,
      city: 'Winesburg',
      state: 'OH',
    },
    44691: {
      lat: 40.80714,
      lng: -81.97971,
      city: 'Wooster',
      state: 'OH',
    },
    44693: {
      lat: 40.2978,
      lng: -81.18821,
      city: 'Deersville',
      state: 'OH',
    },
    44695: {
      lat: 40.43868,
      lng: -81.1754,
      city: 'Bowerston',
      state: 'OH',
    },
    44697: { lat: 40.60807, lng: -81.41175, city: 'Zoar', state: 'OH' },
    44699: {
      lat: 40.27789,
      lng: -81.28752,
      city: 'Tippecanoe',
      state: 'OH',
    },
    44702: {
      lat: 40.79992,
      lng: -81.37665,
      city: 'Canton',
      state: 'OH',
    },
    44703: {
      lat: 40.81093,
      lng: -81.38134,
      city: 'Canton',
      state: 'OH',
    },
    44704: {
      lat: 40.80001,
      lng: -81.34329,
      city: 'Canton',
      state: 'OH',
    },
    44705: {
      lat: 40.83347,
      lng: -81.3327,
      city: 'Canton',
      state: 'OH',
    },
    44706: {
      lat: 40.75382,
      lng: -81.4198,
      city: 'Canton',
      state: 'OH',
    },
    44707: {
      lat: 40.76031,
      lng: -81.34715,
      city: 'Canton',
      state: 'OH',
    },
    44708: {
      lat: 40.81708,
      lng: -81.43486,
      city: 'Canton',
      state: 'OH',
    },
    44709: {
      lat: 40.84218,
      lng: -81.38634,
      city: 'Canton',
      state: 'OH',
    },
    44710: {
      lat: 40.78962,
      lng: -81.42659,
      city: 'Canton',
      state: 'OH',
    },
    44714: {
      lat: 40.83606,
      lng: -81.35905,
      city: 'Canton',
      state: 'OH',
    },
    44718: {
      lat: 40.85122,
      lng: -81.44806,
      city: 'Canton',
      state: 'OH',
    },
    44720: {
      lat: 40.90112,
      lng: -81.43259,
      city: 'North Canton',
      state: 'OH',
    },
    44721: {
      lat: 40.89443,
      lng: -81.32694,
      city: 'Canton',
      state: 'OH',
    },
    44730: {
      lat: 40.76706,
      lng: -81.25814,
      city: 'East Canton',
      state: 'OH',
    },
    44802: {
      lat: 41.04534,
      lng: -83.41912,
      city: 'Alvada',
      state: 'OH',
    },
    44804: {
      lat: 41.10708,
      lng: -83.53538,
      city: 'Arcadia',
      state: 'OH',
    },
    44805: {
      lat: 40.87335,
      lng: -82.32096,
      city: 'Ashland',
      state: 'OH',
    },
    44807: {
      lat: 41.06508,
      lng: -82.87533,
      city: 'Attica',
      state: 'OH',
    },
    44809: {
      lat: 41.13215,
      lng: -83.29391,
      city: 'Bascom',
      state: 'OH',
    },
    44811: {
      lat: 41.24445,
      lng: -82.84474,
      city: 'Bellevue',
      state: 'OH',
    },
    44813: {
      lat: 40.60135,
      lng: -82.52814,
      city: 'Bellville',
      state: 'OH',
    },
    44814: {
      lat: 41.32162,
      lng: -82.4714,
      city: 'Berlin Heights',
      state: 'OH',
    },
    44815: {
      lat: 41.24697,
      lng: -83.23947,
      city: 'Bettsville',
      state: 'OH',
    },
    44817: {
      lat: 41.1987,
      lng: -83.54676,
      city: 'Bloomdale',
      state: 'OH',
    },
    44818: {
      lat: 41.0002,
      lng: -82.99645,
      city: 'Bloomville',
      state: 'OH',
    },
    44820: {
      lat: 40.81829,
      lng: -82.97186,
      city: 'Bucyrus',
      state: 'OH',
    },
    44822: {
      lat: 40.55705,
      lng: -82.39929,
      city: 'Butler',
      state: 'OH',
    },
    44824: {
      lat: 41.37952,
      lng: -82.80422,
      city: 'Castalia',
      state: 'OH',
    },
    44825: {
      lat: 40.95443,
      lng: -82.94194,
      city: 'Chatfield',
      state: 'OH',
    },
    44826: {
      lat: 41.23993,
      lng: -82.47931,
      city: 'Collins',
      state: 'OH',
    },
    44827: {
      lat: 40.81921,
      lng: -82.75923,
      city: 'Crestline',
      state: 'OH',
    },
    44828: {
      lat: 41.23367,
      lng: -82.85957,
      city: 'Flat Rock',
      state: 'OH',
    },
    44830: {
      lat: 41.16603,
      lng: -83.40081,
      city: 'Fostoria',
      state: 'OH',
    },
    44833: {
      lat: 40.71059,
      lng: -82.79484,
      city: 'Galion',
      state: 'OH',
    },
    44836: {
      lat: 41.23772,
      lng: -83.05455,
      city: 'Green Springs',
      state: 'OH',
    },
    44837: {
      lat: 41.01745,
      lng: -82.48241,
      city: 'Greenwich',
      state: 'OH',
    },
    44838: {
      lat: 40.77186,
      lng: -82.25947,
      city: 'Hayesville',
      state: 'OH',
    },
    44839: { lat: 41.3725, lng: -82.55656, city: 'Huron', state: 'OH' },
    44840: {
      lat: 40.79231,
      lng: -82.17973,
      city: 'Jeromesville',
      state: 'OH',
    },
    44841: {
      lat: 41.25126,
      lng: -83.31139,
      city: 'Kansas',
      state: 'OH',
    },
    44842: {
      lat: 40.64903,
      lng: -82.22498,
      city: 'Loudonville',
      state: 'OH',
    },
    44843: { lat: 40.6886, lng: -82.40959, city: 'Lucas', state: 'OH' },
    44844: {
      lat: 40.9926,
      lng: -83.25839,
      city: 'McCutchenville',
      state: 'OH',
    },
    44846: {
      lat: 41.31589,
      lng: -82.60832,
      city: 'Milan',
      state: 'OH',
    },
    44847: {
      lat: 41.23025,
      lng: -82.70821,
      city: 'Monroeville',
      state: 'OH',
    },
    44849: {
      lat: 40.80863,
      lng: -83.13186,
      city: 'Nevada',
      state: 'OH',
    },
    44850: {
      lat: 41.03539,
      lng: -82.68403,
      city: 'New Haven',
      state: 'OH',
    },
    44851: {
      lat: 41.10577,
      lng: -82.40166,
      city: 'New London',
      state: 'OH',
    },
    44853: {
      lat: 41.05501,
      lng: -83.30399,
      city: 'New Riegel',
      state: 'OH',
    },
    44854: {
      lat: 40.94723,
      lng: -82.85748,
      city: 'New Washington',
      state: 'OH',
    },
    44855: {
      lat: 41.10414,
      lng: -82.58664,
      city: 'North Fairfield',
      state: 'OH',
    },
    44856: {
      lat: 40.79398,
      lng: -82.85627,
      city: 'North Robinson',
      state: 'OH',
    },
    44857: {
      lat: 41.21785,
      lng: -82.58212,
      city: 'Norwalk',
      state: 'OH',
    },
    44859: { lat: 41.02103, lng: -82.32636, city: 'Nova', state: 'OH' },
    44861: {
      lat: 41.24254,
      lng: -83.14626,
      city: 'Old Fort',
      state: 'OH',
    },
    44864: {
      lat: 40.66748,
      lng: -82.31871,
      city: 'Perrysville',
      state: 'OH',
    },
    44865: {
      lat: 40.99165,
      lng: -82.68168,
      city: 'Plymouth',
      state: 'OH',
    },
    44866: { lat: 40.92082, lng: -82.19483, city: 'Polk', state: 'OH' },
    44867: {
      lat: 41.14755,
      lng: -82.98963,
      city: 'Republic',
      state: 'OH',
    },
    44870: {
      lat: 41.40623,
      lng: -82.7171,
      city: 'Sandusky',
      state: 'OH',
    },
    44874: {
      lat: 40.96859,
      lng: -82.36631,
      city: 'Savannah',
      state: 'OH',
    },
    44875: {
      lat: 40.88645,
      lng: -82.6489,
      city: 'Shelby',
      state: 'OH',
    },
    44878: {
      lat: 40.95111,
      lng: -82.51963,
      city: 'Shiloh',
      state: 'OH',
    },
    44880: {
      lat: 41.03352,
      lng: -82.21993,
      city: 'Sullivan',
      state: 'OH',
    },
    44881: {
      lat: 40.8703,
      lng: -82.87687,
      city: 'Sulphur Springs',
      state: 'OH',
    },
    44882: {
      lat: 40.94759,
      lng: -83.13734,
      city: 'Sycamore',
      state: 'OH',
    },
    44883: {
      lat: 41.12395,
      lng: -83.17459,
      city: 'Tiffin',
      state: 'OH',
    },
    44887: { lat: 40.9205, lng: -82.788, city: 'Tiro', state: 'OH' },
    44889: {
      lat: 41.24661,
      lng: -82.38195,
      city: 'Wakeman',
      state: 'OH',
    },
    44890: {
      lat: 41.0818,
      lng: -82.71248,
      city: 'Willard',
      state: 'OH',
    },
    44901: {
      lat: 40.76391,
      lng: -82.51247,
      city: 'Mansfield',
      state: 'OH',
    },
    44902: {
      lat: 40.7589,
      lng: -82.51095,
      city: 'Mansfield',
      state: 'OH',
    },
    44903: {
      lat: 40.77624,
      lng: -82.52655,
      city: 'Mansfield',
      state: 'OH',
    },
    44904: {
      lat: 40.66578,
      lng: -82.61505,
      city: 'Mansfield',
      state: 'OH',
    },
    44905: {
      lat: 40.77753,
      lng: -82.46915,
      city: 'Mansfield',
      state: 'OH',
    },
    44906: {
      lat: 40.76413,
      lng: -82.57778,
      city: 'Mansfield',
      state: 'OH',
    },
    44907: {
      lat: 40.72761,
      lng: -82.52129,
      city: 'Mansfield',
      state: 'OH',
    },
    45001: {
      lat: 39.13713,
      lng: -84.71045,
      city: 'Addyston',
      state: 'OH',
    },
    45002: {
      lat: 39.19871,
      lng: -84.73422,
      city: 'Cleves',
      state: 'OH',
    },
    45003: {
      lat: 39.58427,
      lng: -84.77942,
      city: 'College Corner',
      state: 'OH',
    },
    45005: {
      lat: 39.53478,
      lng: -84.3018,
      city: 'Franklin',
      state: 'OH',
    },
    45011: {
      lat: 39.42892,
      lng: -84.50173,
      city: 'Hamilton',
      state: 'OH',
    },
    45013: {
      lat: 39.40649,
      lng: -84.6501,
      city: 'Hamilton',
      state: 'OH',
    },
    45014: {
      lat: 39.32822,
      lng: -84.5521,
      city: 'Fairfield',
      state: 'OH',
    },
    45015: {
      lat: 39.36273,
      lng: -84.53785,
      city: 'Hamilton',
      state: 'OH',
    },
    45030: {
      lat: 39.25621,
      lng: -84.76287,
      city: 'Harrison',
      state: 'OH',
    },
    45032: {
      lat: 39.50268,
      lng: -84.00811,
      city: 'Harveysburg',
      state: 'OH',
    },
    45033: {
      lat: 39.17962,
      lng: -84.76469,
      city: 'Hooven',
      state: 'OH',
    },
    45034: {
      lat: 39.35749,
      lng: -84.24687,
      city: 'Kings Mills',
      state: 'OH',
    },
    45036: {
      lat: 39.44487,
      lng: -84.21832,
      city: 'Lebanon',
      state: 'OH',
    },
    45039: {
      lat: 39.32655,
      lng: -84.23586,
      city: 'Maineville',
      state: 'OH',
    },
    45040: {
      lat: 39.35023,
      lng: -84.30996,
      city: 'Mason',
      state: 'OH',
    },
    45041: {
      lat: 39.21114,
      lng: -84.70292,
      city: 'Miamitown',
      state: 'OH',
    },
    45042: {
      lat: 39.54867,
      lng: -84.43755,
      city: 'Middletown',
      state: 'OH',
    },
    45044: {
      lat: 39.45028,
      lng: -84.37988,
      city: 'Middletown',
      state: 'OH',
    },
    45050: {
      lat: 39.44437,
      lng: -84.36594,
      city: 'Monroe',
      state: 'OH',
    },
    45051: {
      lat: 39.09983,
      lng: -84.65504,
      city: 'Mount Saint Joseph',
      state: 'OH',
    },
    45052: {
      lat: 39.14505,
      lng: -84.77849,
      city: 'North Bend',
      state: 'OH',
    },
    45053: {
      lat: 39.35273,
      lng: -84.78053,
      city: 'Okeana',
      state: 'OH',
    },
    45054: {
      lat: 39.43844,
      lng: -84.07418,
      city: 'Oregonia',
      state: 'OH',
    },
    45055: {
      lat: 39.45701,
      lng: -84.51094,
      city: 'Overpeck',
      state: 'OH',
    },
    45056: {
      lat: 39.49029,
      lng: -84.74115,
      city: 'Oxford',
      state: 'OH',
    },
    45062: {
      lat: 39.48501,
      lng: -84.55371,
      city: 'Seven Mile',
      state: 'OH',
    },
    45064: {
      lat: 39.56806,
      lng: -84.59785,
      city: 'Somerville',
      state: 'OH',
    },
    45065: {
      lat: 39.37016,
      lng: -84.21426,
      city: 'South Lebanon',
      state: 'OH',
    },
    45066: {
      lat: 39.54925,
      lng: -84.22265,
      city: 'Springboro',
      state: 'OH',
    },
    45067: {
      lat: 39.49017,
      lng: -84.47948,
      city: 'Trenton',
      state: 'OH',
    },
    45068: {
      lat: 39.52813,
      lng: -84.05922,
      city: 'Waynesville',
      state: 'OH',
    },
    45069: {
      lat: 39.34213,
      lng: -84.4105,
      city: 'West Chester',
      state: 'OH',
    },
    45070: {
      lat: 39.58968,
      lng: -84.55804,
      city: 'West Elkton',
      state: 'OH',
    },
    45101: {
      lat: 38.69164,
      lng: -83.74024,
      city: 'Aberdeen',
      state: 'OH',
    },
    45102: {
      lat: 39.01718,
      lng: -84.20602,
      city: 'Amelia',
      state: 'OH',
    },
    45103: {
      lat: 39.0946,
      lng: -84.14006,
      city: 'Batavia',
      state: 'OH',
    },
    45105: {
      lat: 38.75015,
      lng: -83.61235,
      city: 'Bentonville',
      state: 'OH',
    },
    45106: {
      lat: 38.94633,
      lng: -84.06831,
      city: 'Bethel',
      state: 'OH',
    },
    45107: {
      lat: 39.28888,
      lng: -83.96967,
      city: 'Blanchester',
      state: 'OH',
    },
    45111: {
      lat: 39.20033,
      lng: -84.28746,
      city: 'Camp Dennison',
      state: 'OH',
    },
    45112: {
      lat: 38.79377,
      lng: -84.14144,
      city: 'Chilo',
      state: 'OH',
    },
    45113: {
      lat: 39.39991,
      lng: -83.98561,
      city: 'Clarksville',
      state: 'OH',
    },
    45115: {
      lat: 38.80818,
      lng: -83.70728,
      city: 'Decatur',
      state: 'OH',
    },
    45118: {
      lat: 39.17192,
      lng: -83.93107,
      city: 'Fayetteville',
      state: 'OH',
    },
    45119: {
      lat: 38.8781,
      lng: -83.99927,
      city: 'Feesburg',
      state: 'OH',
    },
    45120: {
      lat: 38.82597,
      lng: -84.09301,
      city: 'Felicity',
      state: 'OH',
    },
    45121: {
      lat: 38.86769,
      lng: -83.90911,
      city: 'Georgetown',
      state: 'OH',
    },
    45122: {
      lat: 39.22411,
      lng: -84.12698,
      city: 'Goshen',
      state: 'OH',
    },
    45123: {
      lat: 39.3487,
      lng: -83.38866,
      city: 'Greenfield',
      state: 'OH',
    },
    45130: {
      lat: 38.90886,
      lng: -83.99897,
      city: 'Hamersville',
      state: 'OH',
    },
    45131: {
      lat: 38.78997,
      lng: -83.96729,
      city: 'Higginsport',
      state: 'OH',
    },
    45132: {
      lat: 39.34395,
      lng: -83.5996,
      city: 'Highland',
      state: 'OH',
    },
    45133: {
      lat: 39.15695,
      lng: -83.57282,
      city: 'Hillsboro',
      state: 'OH',
    },
    45135: {
      lat: 39.35124,
      lng: -83.54859,
      city: 'Leesburg',
      state: 'OH',
    },
    45140: {
      lat: 39.25603,
      lng: -84.24433,
      city: 'Loveland',
      state: 'OH',
    },
    45142: {
      lat: 39.21033,
      lng: -83.80553,
      city: 'Lynchburg',
      state: 'OH',
    },
    45144: {
      lat: 38.70534,
      lng: -83.6142,
      city: 'Manchester',
      state: 'OH',
    },
    45146: {
      lat: 39.3154,
      lng: -83.79945,
      city: 'Martinsville',
      state: 'OH',
    },
    45147: {
      lat: 39.21114,
      lng: -84.30192,
      city: 'Miamiville',
      state: 'OH',
    },
    45148: {
      lat: 39.28849,
      lng: -83.88466,
      city: 'Midland',
      state: 'OH',
    },
    45150: {
      lat: 39.16567,
      lng: -84.23281,
      city: 'Milford',
      state: 'OH',
    },
    45152: {
      lat: 39.35005,
      lng: -84.12316,
      city: 'Morrow',
      state: 'OH',
    },
    45153: {
      lat: 38.86977,
      lng: -84.18474,
      city: 'Moscow',
      state: 'OH',
    },
    45154: {
      lat: 39.06137,
      lng: -83.91997,
      city: 'Mount Orab',
      state: 'OH',
    },
    45155: {
      lat: 39.04057,
      lng: -83.75181,
      city: 'Mowrystown',
      state: 'OH',
    },
    45156: {
      lat: 38.80947,
      lng: -84.21348,
      city: 'Neville',
      state: 'OH',
    },
    45157: {
      lat: 38.95599,
      lng: -84.22824,
      city: 'New Richmond',
      state: 'OH',
    },
    45158: {
      lat: 39.18229,
      lng: -84.07769,
      city: 'Newtonsville',
      state: 'OH',
    },
    45159: {
      lat: 39.33389,
      lng: -83.68972,
      city: 'New Vienna',
      state: 'OH',
    },
    45160: {
      lat: 39.12358,
      lng: -84.13725,
      city: 'Owensville',
      state: 'OH',
    },
    45162: {
      lat: 39.26913,
      lng: -84.07851,
      city: 'Pleasant Plain',
      state: 'OH',
    },
    45164: {
      lat: 39.55365,
      lng: -83.78532,
      city: 'Port William',
      state: 'OH',
    },
    45166: {
      lat: 39.4766,
      lng: -83.68774,
      city: 'Reesville',
      state: 'OH',
    },
    45167: {
      lat: 38.77234,
      lng: -83.79943,
      city: 'Ripley',
      state: 'OH',
    },
    45168: {
      lat: 38.85266,
      lng: -83.76783,
      city: 'Russellville',
      state: 'OH',
    },
    45169: { lat: 39.505, lng: -83.65035, city: 'Sabina', state: 'OH' },
    45171: {
      lat: 39.01414,
      lng: -83.7962,
      city: 'Sardinia',
      state: 'OH',
    },
    45172: {
      lat: 39.07447,
      lng: -83.38688,
      city: 'Sinking Spring',
      state: 'OH',
    },
    45174: {
      lat: 39.1588,
      lng: -84.31073,
      city: 'Terrace Park',
      state: 'OH',
    },
    45176: {
      lat: 39.08438,
      lng: -84.02205,
      city: 'Williamsburg',
      state: 'OH',
    },
    45177: {
      lat: 39.46123,
      lng: -83.83692,
      city: 'Wilmington',
      state: 'OH',
    },
    45202: {
      lat: 39.10885,
      lng: -84.50243,
      city: 'Cincinnati',
      state: 'OH',
    },
    45203: {
      lat: 39.10403,
      lng: -84.53337,
      city: 'Cincinnati',
      state: 'OH',
    },
    45204: {
      lat: 39.09536,
      lng: -84.57225,
      city: 'Cincinnati',
      state: 'OH',
    },
    45205: {
      lat: 39.11057,
      lng: -84.57518,
      city: 'Cincinnati',
      state: 'OH',
    },
    45206: {
      lat: 39.12736,
      lng: -84.48451,
      city: 'Cincinnati',
      state: 'OH',
    },
    45207: {
      lat: 39.14199,
      lng: -84.4713,
      city: 'Cincinnati',
      state: 'OH',
    },
    45208: {
      lat: 39.13522,
      lng: -84.43393,
      city: 'Cincinnati',
      state: 'OH',
    },
    45209: {
      lat: 39.15336,
      lng: -84.42762,
      city: 'Cincinnati',
      state: 'OH',
    },
    45211: {
      lat: 39.15746,
      lng: -84.59888,
      city: 'Cincinnati',
      state: 'OH',
    },
    45212: {
      lat: 39.16403,
      lng: -84.45173,
      city: 'Cincinnati',
      state: 'OH',
    },
    45213: {
      lat: 39.18111,
      lng: -84.42006,
      city: 'Cincinnati',
      state: 'OH',
    },
    45214: {
      lat: 39.12271,
      lng: -84.54442,
      city: 'Cincinnati',
      state: 'OH',
    },
    45215: {
      lat: 39.23536,
      lng: -84.46221,
      city: 'Cincinnati',
      state: 'OH',
    },
    45216: {
      lat: 39.20054,
      lng: -84.48114,
      city: 'Cincinnati',
      state: 'OH',
    },
    45217: {
      lat: 39.16718,
      lng: -84.49792,
      city: 'Cincinnati',
      state: 'OH',
    },
    45218: {
      lat: 39.26623,
      lng: -84.51975,
      city: 'Cincinnati',
      state: 'OH',
    },
    45219: {
      lat: 39.12668,
      lng: -84.51201,
      city: 'Cincinnati',
      state: 'OH',
    },
    45220: {
      lat: 39.14736,
      lng: -84.52101,
      city: 'Cincinnati',
      state: 'OH',
    },
    45223: {
      lat: 39.1696,
      lng: -84.55354,
      city: 'Cincinnati',
      state: 'OH',
    },
    45224: {
      lat: 39.20215,
      lng: -84.53222,
      city: 'Cincinnati',
      state: 'OH',
    },
    45225: {
      lat: 39.14341,
      lng: -84.55267,
      city: 'Cincinnati',
      state: 'OH',
    },
    45226: {
      lat: 39.11149,
      lng: -84.41989,
      city: 'Cincinnati',
      state: 'OH',
    },
    45227: {
      lat: 39.15403,
      lng: -84.38524,
      city: 'Cincinnati',
      state: 'OH',
    },
    45229: {
      lat: 39.15342,
      lng: -84.48612,
      city: 'Cincinnati',
      state: 'OH',
    },
    45230: {
      lat: 39.07445,
      lng: -84.39082,
      city: 'Cincinnati',
      state: 'OH',
    },
    45231: {
      lat: 39.24617,
      lng: -84.5383,
      city: 'Cincinnati',
      state: 'OH',
    },
    45232: {
      lat: 39.1818,
      lng: -84.51673,
      city: 'Cincinnati',
      state: 'OH',
    },
    45233: {
      lat: 39.1191,
      lng: -84.66559,
      city: 'Cincinnati',
      state: 'OH',
    },
    45236: {
      lat: 39.20949,
      lng: -84.39701,
      city: 'Cincinnati',
      state: 'OH',
    },
    45237: {
      lat: 39.19298,
      lng: -84.45244,
      city: 'Cincinnati',
      state: 'OH',
    },
    45238: {
      lat: 39.10927,
      lng: -84.61053,
      city: 'Cincinnati',
      state: 'OH',
    },
    45239: {
      lat: 39.20439,
      lng: -84.57979,
      city: 'Cincinnati',
      state: 'OH',
    },
    45240: {
      lat: 39.28434,
      lng: -84.52605,
      city: 'Cincinnati',
      state: 'OH',
    },
    45241: {
      lat: 39.27659,
      lng: -84.40175,
      city: 'Cincinnati',
      state: 'OH',
    },
    45242: {
      lat: 39.24348,
      lng: -84.35621,
      city: 'Cincinnati',
      state: 'OH',
    },
    45243: {
      lat: 39.18381,
      lng: -84.34171,
      city: 'Cincinnati',
      state: 'OH',
    },
    45244: {
      lat: 39.12009,
      lng: -84.32764,
      city: 'Cincinnati',
      state: 'OH',
    },
    45245: {
      lat: 39.06065,
      lng: -84.27748,
      city: 'Cincinnati',
      state: 'OH',
    },
    45246: {
      lat: 39.2906,
      lng: -84.46612,
      city: 'Cincinnati',
      state: 'OH',
    },
    45247: {
      lat: 39.22048,
      lng: -84.65172,
      city: 'Cincinnati',
      state: 'OH',
    },
    45248: {
      lat: 39.16442,
      lng: -84.66229,
      city: 'Cincinnati',
      state: 'OH',
    },
    45249: {
      lat: 39.27617,
      lng: -84.32777,
      city: 'Cincinnati',
      state: 'OH',
    },
    45251: {
      lat: 39.26789,
      lng: -84.59422,
      city: 'Cincinnati',
      state: 'OH',
    },
    45252: {
      lat: 39.27352,
      lng: -84.63214,
      city: 'Cincinnati',
      state: 'OH',
    },
    45255: {
      lat: 39.05862,
      lng: -84.32675,
      city: 'Cincinnati',
      state: 'OH',
    },
    45267: {
      lat: 39.13812,
      lng: -84.5031,
      city: 'Cincinnati',
      state: 'OH',
    },
    45301: {
      lat: 39.71098,
      lng: -84.02301,
      city: 'Alpha',
      state: 'OH',
    },
    45302: { lat: 40.40068, lng: -84.20756, city: 'Anna', state: 'OH' },
    45303: {
      lat: 40.2146,
      lng: -84.65627,
      city: 'Ansonia',
      state: 'OH',
    },
    45304: {
      lat: 39.98756,
      lng: -84.53145,
      city: 'Arcanum',
      state: 'OH',
    },
    45305: {
      lat: 39.63628,
      lng: -84.07769,
      city: 'Bellbrook',
      state: 'OH',
    },
    45306: {
      lat: 40.45856,
      lng: -84.18575,
      city: 'Botkins',
      state: 'OH',
    },
    45307: {
      lat: 39.58065,
      lng: -83.72347,
      city: 'Bowersville',
      state: 'OH',
    },
    45308: {
      lat: 40.12535,
      lng: -84.45699,
      city: 'Bradford',
      state: 'OH',
    },
    45309: {
      lat: 39.84475,
      lng: -84.42204,
      city: 'Brookville',
      state: 'OH',
    },
    45310: {
      lat: 40.35299,
      lng: -84.64264,
      city: 'Burkettsville',
      state: 'OH',
    },
    45311: {
      lat: 39.62791,
      lng: -84.66411,
      city: 'Camden',
      state: 'OH',
    },
    45312: {
      lat: 40.06028,
      lng: -84.07976,
      city: 'Casstown',
      state: 'OH',
    },
    45314: {
      lat: 39.74608,
      lng: -83.77363,
      city: 'Cedarville',
      state: 'OH',
    },
    45315: {
      lat: 39.85772,
      lng: -84.33875,
      city: 'Clayton',
      state: 'OH',
    },
    45316: {
      lat: 39.79754,
      lng: -83.82517,
      city: 'Clifton',
      state: 'OH',
    },
    45317: {
      lat: 40.17328,
      lng: -84.01971,
      city: 'Conover',
      state: 'OH',
    },
    45318: {
      lat: 40.12745,
      lng: -84.35391,
      city: 'Covington',
      state: 'OH',
    },
    45319: {
      lat: 39.91608,
      lng: -83.94324,
      city: 'Donnelsville',
      state: 'OH',
    },
    45320: {
      lat: 39.74673,
      lng: -84.68932,
      city: 'Eaton',
      state: 'OH',
    },
    45321: {
      lat: 39.87134,
      lng: -84.67775,
      city: 'Eldorado',
      state: 'OH',
    },
    45322: {
      lat: 39.89967,
      lng: -84.33206,
      city: 'Englewood',
      state: 'OH',
    },
    45323: { lat: 39.85131, lng: -83.92794, city: 'Enon', state: 'OH' },
    45324: {
      lat: 39.81986,
      lng: -84.00026,
      city: 'Fairborn',
      state: 'OH',
    },
    45325: {
      lat: 39.69297,
      lng: -84.4269,
      city: 'Farmersville',
      state: 'OH',
    },
    45326: {
      lat: 40.14201,
      lng: -84.09039,
      city: 'Fletcher',
      state: 'OH',
    },
    45327: {
      lat: 39.63286,
      lng: -84.39841,
      city: 'Germantown',
      state: 'OH',
    },
    45328: {
      lat: 40.11621,
      lng: -84.49676,
      city: 'Gettysburg',
      state: 'OH',
    },
    45330: {
      lat: 39.64821,
      lng: -84.5282,
      city: 'Gratis',
      state: 'OH',
    },
    45331: {
      lat: 40.09729,
      lng: -84.64616,
      city: 'Greenville',
      state: 'OH',
    },
    45332: {
      lat: 39.99967,
      lng: -84.78776,
      city: 'Hollansburg',
      state: 'OH',
    },
    45333: {
      lat: 40.2481,
      lng: -84.33209,
      city: 'Houston',
      state: 'OH',
    },
    45334: {
      lat: 40.44662,
      lng: -84.04517,
      city: 'Jackson Center',
      state: 'OH',
    },
    45335: {
      lat: 39.63079,
      lng: -83.73353,
      city: 'Jamestown',
      state: 'OH',
    },
    45336: {
      lat: 40.44256,
      lng: -84.25988,
      city: 'Kettlersville',
      state: 'OH',
    },
    45337: {
      lat: 39.98019,
      lng: -84.42095,
      city: 'Laura',
      state: 'OH',
    },
    45338: {
      lat: 39.85147,
      lng: -84.54417,
      city: 'Lewisburg',
      state: 'OH',
    },
    45339: {
      lat: 40.00826,
      lng: -84.34315,
      city: 'Ludlow Falls',
      state: 'OH',
    },
    45340: {
      lat: 40.37184,
      lng: -84.04935,
      city: 'Maplewood',
      state: 'OH',
    },
    45341: {
      lat: 39.87589,
      lng: -84.02045,
      city: 'Medway',
      state: 'OH',
    },
    45342: {
      lat: 39.62943,
      lng: -84.27545,
      city: 'Miamisburg',
      state: 'OH',
    },
    45344: {
      lat: 39.95567,
      lng: -84.00734,
      city: 'New Carlisle',
      state: 'OH',
    },
    45345: {
      lat: 39.7392,
      lng: -84.39926,
      city: 'New Lebanon',
      state: 'OH',
    },
    45346: {
      lat: 39.97934,
      lng: -84.70911,
      city: 'New Madison',
      state: 'OH',
    },
    45347: {
      lat: 39.88378,
      lng: -84.76208,
      city: 'New Paris',
      state: 'OH',
    },
    45348: {
      lat: 40.32663,
      lng: -84.65404,
      city: 'New Weston',
      state: 'OH',
    },
    45349: {
      lat: 39.98916,
      lng: -83.94052,
      city: 'North Hampton',
      state: 'OH',
    },
    45350: {
      lat: 40.32472,
      lng: -84.57359,
      city: 'North Star',
      state: 'OH',
    },
    45351: {
      lat: 40.33954,
      lng: -84.49574,
      city: 'Osgood',
      state: 'OH',
    },
    45352: {
      lat: 40.05006,
      lng: -84.74432,
      city: 'Palestine',
      state: 'OH',
    },
    45353: {
      lat: 40.29252,
      lng: -84.04274,
      city: 'Pemberton',
      state: 'OH',
    },
    45354: {
      lat: 39.91033,
      lng: -84.39983,
      city: 'Phillipsburg',
      state: 'OH',
    },
    45356: {
      lat: 40.16252,
      lng: -84.23097,
      city: 'Piqua',
      state: 'OH',
    },
    45358: {
      lat: 39.98724,
      lng: -84.48796,
      city: 'Pitsburg',
      state: 'OH',
    },
    45359: {
      lat: 40.05067,
      lng: -84.3511,
      city: 'Pleasant Hill',
      state: 'OH',
    },
    45360: {
      lat: 40.33028,
      lng: -84.09206,
      city: 'Port Jefferson',
      state: 'OH',
    },
    45361: {
      lat: 39.96374,
      lng: -84.41484,
      city: 'Potsdam',
      state: 'OH',
    },
    45362: {
      lat: 40.28684,
      lng: -84.63368,
      city: 'Rossburg',
      state: 'OH',
    },
    45363: {
      lat: 40.23925,
      lng: -84.39841,
      city: 'Russia',
      state: 'OH',
    },
    45365: {
      lat: 40.2828,
      lng: -84.15582,
      city: 'Sidney',
      state: 'OH',
    },
    45368: {
      lat: 39.84376,
      lng: -83.65413,
      city: 'South Charleston',
      state: 'OH',
    },
    45369: {
      lat: 39.95304,
      lng: -83.60304,
      city: 'South Vienna',
      state: 'OH',
    },
    45370: {
      lat: 39.60853,
      lng: -84.04015,
      city: 'Spring Valley',
      state: 'OH',
    },
    45371: {
      lat: 39.93885,
      lng: -84.16357,
      city: 'Tipp City',
      state: 'OH',
    },
    45372: {
      lat: 40.01471,
      lng: -83.83862,
      city: 'Tremont City',
      state: 'OH',
    },
    45373: { lat: 40.03353, lng: -84.19588, city: 'Troy', state: 'OH' },
    45377: {
      lat: 39.89636,
      lng: -84.22774,
      city: 'Vandalia',
      state: 'OH',
    },
    45378: {
      lat: 39.90291,
      lng: -84.48781,
      city: 'Verona',
      state: 'OH',
    },
    45380: {
      lat: 40.24511,
      lng: -84.51314,
      city: 'Versailles',
      state: 'OH',
    },
    45381: {
      lat: 39.73223,
      lng: -84.52433,
      city: 'West Alexandria',
      state: 'OH',
    },
    45382: {
      lat: 39.89621,
      lng: -84.61626,
      city: 'West Manchester',
      state: 'OH',
    },
    45383: {
      lat: 39.9572,
      lng: -84.3337,
      city: 'West Milton',
      state: 'OH',
    },
    45384: {
      lat: 39.71444,
      lng: -83.88638,
      city: 'Wilberforce',
      state: 'OH',
    },
    45385: {
      lat: 39.66813,
      lng: -83.91457,
      city: 'Xenia',
      state: 'OH',
    },
    45387: {
      lat: 39.79754,
      lng: -83.88622,
      city: 'Yellow Springs',
      state: 'OH',
    },
    45388: {
      lat: 40.32799,
      lng: -84.47901,
      city: 'Yorkshire',
      state: 'OH',
    },
    45389: {
      lat: 40.05639,
      lng: -84.02532,
      city: 'Christiansburg',
      state: 'OH',
    },
    45390: {
      lat: 40.21894,
      lng: -84.76778,
      city: 'Union City',
      state: 'OH',
    },
    45402: {
      lat: 39.75983,
      lng: -84.20751,
      city: 'Dayton',
      state: 'OH',
    },
    45403: {
      lat: 39.76303,
      lng: -84.14978,
      city: 'Dayton',
      state: 'OH',
    },
    45404: {
      lat: 39.79011,
      lng: -84.15324,
      city: 'Dayton',
      state: 'OH',
    },
    45405: {
      lat: 39.79241,
      lng: -84.21592,
      city: 'Dayton',
      state: 'OH',
    },
    45406: {
      lat: 39.78442,
      lng: -84.24178,
      city: 'Dayton',
      state: 'OH',
    },
    45409: {
      lat: 39.72334,
      lng: -84.19039,
      city: 'Dayton',
      state: 'OH',
    },
    45410: {
      lat: 39.74757,
      lng: -84.15907,
      city: 'Dayton',
      state: 'OH',
    },
    45414: {
      lat: 39.84008,
      lng: -84.21115,
      city: 'Dayton',
      state: 'OH',
    },
    45415: {
      lat: 39.83473,
      lng: -84.2568,
      city: 'Dayton',
      state: 'OH',
    },
    45416: {
      lat: 39.80754,
      lng: -84.25774,
      city: 'Dayton',
      state: 'OH',
    },
    45417: {
      lat: 39.73427,
      lng: -84.2844,
      city: 'Dayton',
      state: 'OH',
    },
    45419: {
      lat: 39.71436,
      lng: -84.1676,
      city: 'Dayton',
      state: 'OH',
    },
    45420: { lat: 39.719, lng: -84.13045, city: 'Dayton', state: 'OH' },
    45423: {
      lat: 39.7604,
      lng: -84.19112,
      city: 'Dayton',
      state: 'OH',
    },
    45424: {
      lat: 39.84695,
      lng: -84.11032,
      city: 'Dayton',
      state: 'OH',
    },
    45426: {
      lat: 39.80094,
      lng: -84.31461,
      city: 'Dayton',
      state: 'OH',
    },
    45428: {
      lat: 39.74211,
      lng: -84.25954,
      city: 'Dayton',
      state: 'OH',
    },
    45429: {
      lat: 39.68411,
      lng: -84.16248,
      city: 'Dayton',
      state: 'OH',
    },
    45430: {
      lat: 39.71296,
      lng: -84.08435,
      city: 'Dayton',
      state: 'OH',
    },
    45431: {
      lat: 39.76488,
      lng: -84.08012,
      city: 'Dayton',
      state: 'OH',
    },
    45432: {
      lat: 39.73934,
      lng: -84.08627,
      city: 'Dayton',
      state: 'OH',
    },
    45433: {
      lat: 39.81359,
      lng: -84.05273,
      city: 'Dayton',
      state: 'OH',
    },
    45434: {
      lat: 39.72284,
      lng: -84.03052,
      city: 'Dayton',
      state: 'OH',
    },
    45435: {
      lat: 39.78114,
      lng: -84.06312,
      city: 'Dayton',
      state: 'OH',
    },
    45439: {
      lat: 39.69867,
      lng: -84.22944,
      city: 'Dayton',
      state: 'OH',
    },
    45440: {
      lat: 39.67498,
      lng: -84.10001,
      city: 'Dayton',
      state: 'OH',
    },
    45449: {
      lat: 39.66472,
      lng: -84.24067,
      city: 'Dayton',
      state: 'OH',
    },
    45458: {
      lat: 39.60036,
      lng: -84.1576,
      city: 'Dayton',
      state: 'OH',
    },
    45459: {
      lat: 39.64658,
      lng: -84.16693,
      city: 'Dayton',
      state: 'OH',
    },
    45469: {
      lat: 39.74033,
      lng: -84.17911,
      city: 'Dayton',
      state: 'OH',
    },
    45501: {
      lat: 39.92634,
      lng: -83.80745,
      city: 'Springfield',
      state: 'OH',
    },
    45502: {
      lat: 39.92791,
      lng: -83.81703,
      city: 'Springfield',
      state: 'OH',
    },
    45503: {
      lat: 39.96073,
      lng: -83.7682,
      city: 'Springfield',
      state: 'OH',
    },
    45504: {
      lat: 39.94811,
      lng: -83.86481,
      city: 'Springfield',
      state: 'OH',
    },
    45505: {
      lat: 39.90737,
      lng: -83.7615,
      city: 'Springfield',
      state: 'OH',
    },
    45506: {
      lat: 39.89907,
      lng: -83.85233,
      city: 'Springfield',
      state: 'OH',
    },
    45601: {
      lat: 39.30996,
      lng: -82.9555,
      city: 'Chillicothe',
      state: 'OH',
    },
    45612: {
      lat: 39.21569,
      lng: -83.27171,
      city: 'Bainbridge',
      state: 'OH',
    },
    45613: {
      lat: 39.0318,
      lng: -82.84885,
      city: 'Beaver',
      state: 'OH',
    },
    45614: {
      lat: 38.92858,
      lng: -82.27819,
      city: 'Bidwell',
      state: 'OH',
    },
    45616: {
      lat: 38.76018,
      lng: -83.30864,
      city: 'Blue Creek',
      state: 'OH',
    },
    45617: {
      lat: 39.27967,
      lng: -83.15559,
      city: 'Bourneville',
      state: 'OH',
    },
    45618: {
      lat: 38.88502,
      lng: -83.61623,
      city: 'Cherry Fork',
      state: 'OH',
    },
    45619: {
      lat: 38.47257,
      lng: -82.45157,
      city: 'Chesapeake',
      state: 'OH',
    },
    45620: {
      lat: 38.95564,
      lng: -82.14052,
      city: 'Cheshire',
      state: 'OH',
    },
    45621: {
      lat: 39.11138,
      lng: -82.60996,
      city: 'Coalton',
      state: 'OH',
    },
    45622: {
      lat: 39.35905,
      lng: -82.49522,
      city: 'Creola',
      state: 'OH',
    },
    45623: {
      lat: 38.64125,
      lng: -82.26748,
      city: 'Crown City',
      state: 'OH',
    },
    45624: {
      lat: 39.17301,
      lng: -83.3487,
      city: 'Cynthiana',
      state: 'OH',
    },
    45628: {
      lat: 39.38959,
      lng: -83.19657,
      city: 'Frankfort',
      state: 'OH',
    },
    45629: {
      lat: 38.65221,
      lng: -82.81051,
      city: 'Franklin Furnace',
      state: 'OH',
    },
    45630: {
      lat: 38.69758,
      lng: -83.09275,
      city: 'Friendship',
      state: 'OH',
    },
    45631: {
      lat: 38.81672,
      lng: -82.25486,
      city: 'Gallipolis',
      state: 'OH',
    },
    45634: {
      lat: 39.17891,
      lng: -82.49037,
      city: 'Hamden',
      state: 'OH',
    },
    45636: {
      lat: 38.58416,
      lng: -82.81401,
      city: 'Haverhill',
      state: 'OH',
    },
    45638: {
      lat: 38.56217,
      lng: -82.67607,
      city: 'Ironton',
      state: 'OH',
    },
    45640: {
      lat: 39.02345,
      lng: -82.65032,
      city: 'Jackson',
      state: 'OH',
    },
    45642: {
      lat: 39.04692,
      lng: -83.05408,
      city: 'Jasper',
      state: 'OH',
    },
    45644: {
      lat: 39.45245,
      lng: -82.84176,
      city: 'Kingston',
      state: 'OH',
    },
    45645: {
      lat: 38.57082,
      lng: -82.53159,
      city: 'Kitts Hill',
      state: 'OH',
    },
    45646: {
      lat: 39.07686,
      lng: -83.31627,
      city: 'Latham',
      state: 'OH',
    },
    45647: {
      lat: 39.29597,
      lng: -82.73803,
      city: 'Londonderry',
      state: 'OH',
    },
    45648: {
      lat: 38.92161,
      lng: -83.01393,
      city: 'Lucasville',
      state: 'OH',
    },
    45650: { lat: 38.74415, lng: -83.42099, city: 'Lynx', state: 'OH' },
    45651: {
      lat: 39.27665,
      lng: -82.47706,
      city: 'McArthur',
      state: 'OH',
    },
    45652: {
      lat: 38.82911,
      lng: -83.08018,
      city: 'McDermott',
      state: 'OH',
    },
    45653: {
      lat: 38.88976,
      lng: -82.82306,
      city: 'Minford',
      state: 'OH',
    },
    45654: {
      lat: 39.36671,
      lng: -82.37774,
      city: 'New Plymouth',
      state: 'OH',
    },
    45656: {
      lat: 38.87565,
      lng: -82.59143,
      city: 'Oak Hill',
      state: 'OH',
    },
    45657: {
      lat: 38.85166,
      lng: -83.21011,
      city: 'Otway',
      state: 'OH',
    },
    45658: {
      lat: 38.76324,
      lng: -82.41411,
      city: 'Patriot',
      state: 'OH',
    },
    45659: {
      lat: 38.67117,
      lng: -82.62331,
      city: 'Pedro',
      state: 'OH',
    },
    45660: {
      lat: 38.98626,
      lng: -83.358,
      city: 'Peebles',
      state: 'OH',
    },
    45661: {
      lat: 39.04096,
      lng: -83.10479,
      city: 'Piketon',
      state: 'OH',
    },
    45662: {
      lat: 38.78938,
      lng: -82.92114,
      city: 'Portsmouth',
      state: 'OH',
    },
    45663: {
      lat: 38.74507,
      lng: -83.11198,
      city: 'West Portsmouth',
      state: 'OH',
    },
    45669: {
      lat: 38.4935,
      lng: -82.35857,
      city: 'Proctorville',
      state: 'OH',
    },
    45671: {
      lat: 38.97339,
      lng: -83.23775,
      city: 'Rarden',
      state: 'OH',
    },
    45672: { lat: 39.20549, lng: -82.67579, city: 'Ray', state: 'OH' },
    45673: {
      lat: 39.20399,
      lng: -82.81148,
      city: 'Richmond Dale',
      state: 'OH',
    },
    45674: {
      lat: 38.87798,
      lng: -82.38017,
      city: 'Rio Grande',
      state: 'OH',
    },
    45677: {
      lat: 38.79555,
      lng: -82.75511,
      city: 'Scioto Furnace',
      state: 'OH',
    },
    45678: {
      lat: 38.60595,
      lng: -82.37883,
      city: 'Scottown',
      state: 'OH',
    },
    45679: {
      lat: 38.97634,
      lng: -83.56788,
      city: 'Seaman',
      state: 'OH',
    },
    45680: {
      lat: 38.45526,
      lng: -82.54489,
      city: 'South Point',
      state: 'OH',
    },
    45681: {
      lat: 39.30551,
      lng: -83.24807,
      city: 'South Salem',
      state: 'OH',
    },
    45682: {
      lat: 38.80839,
      lng: -82.69658,
      city: 'South Webster',
      state: 'OH',
    },
    45684: {
      lat: 38.67546,
      lng: -83.27789,
      city: 'Stout',
      state: 'OH',
    },
    45685: {
      lat: 38.9333,
      lng: -82.4527,
      city: 'Thurman',
      state: 'OH',
    },
    45686: {
      lat: 39.00901,
      lng: -82.36843,
      city: 'Vinton',
      state: 'OH',
    },
    45688: {
      lat: 38.73013,
      lng: -82.53155,
      city: 'Waterloo',
      state: 'OH',
    },
    45690: {
      lat: 39.13106,
      lng: -83.01674,
      city: 'Waverly',
      state: 'OH',
    },
    45692: {
      lat: 39.11582,
      lng: -82.5426,
      city: 'Wellston',
      state: 'OH',
    },
    45693: {
      lat: 38.80921,
      lng: -83.53406,
      city: 'West Union',
      state: 'OH',
    },
    45694: {
      lat: 38.74807,
      lng: -82.78345,
      city: 'Wheelersburg',
      state: 'OH',
    },
    45695: {
      lat: 39.1529,
      lng: -82.36334,
      city: 'Wilkesville',
      state: 'OH',
    },
    45696: {
      lat: 38.60356,
      lng: -82.45201,
      city: 'Willow Wood',
      state: 'OH',
    },
    45697: {
      lat: 38.9352,
      lng: -83.66525,
      city: 'Winchester',
      state: 'OH',
    },
    45698: {
      lat: 39.28811,
      lng: -82.39604,
      city: 'Zaleski',
      state: 'OH',
    },
    45699: {
      lat: 38.87414,
      lng: -82.97045,
      city: 'Lucasville',
      state: 'OH',
    },
    45701: {
      lat: 39.31205,
      lng: -82.08079,
      city: 'Athens',
      state: 'OH',
    },
    45710: {
      lat: 39.19763,
      lng: -82.22574,
      city: 'Albany',
      state: 'OH',
    },
    45711: {
      lat: 39.42503,
      lng: -81.93038,
      city: 'Amesville',
      state: 'OH',
    },
    45714: {
      lat: 39.3183,
      lng: -81.62001,
      city: 'Belpre',
      state: 'OH',
    },
    45715: {
      lat: 39.60484,
      lng: -81.6268,
      city: 'Beverly',
      state: 'OH',
    },
    45716: {
      lat: 39.46295,
      lng: -82.18401,
      city: 'Buchtel',
      state: 'OH',
    },
    45719: {
      lat: 39.39951,
      lng: -82.1285,
      city: 'Chauncey',
      state: 'OH',
    },
    45720: {
      lat: 39.09404,
      lng: -81.92312,
      city: 'Chester',
      state: 'OH',
    },
    45721: {
      lat: 39.56563,
      lng: -81.58347,
      city: 'Coal Run',
      state: 'OH',
    },
    45723: {
      lat: 39.22173,
      lng: -81.8268,
      city: 'Coolville',
      state: 'OH',
    },
    45724: {
      lat: 39.38246,
      lng: -81.79233,
      city: 'Cutler',
      state: 'OH',
    },
    45727: {
      lat: 39.64495,
      lng: -81.48171,
      city: 'Dexter City',
      state: 'OH',
    },
    45729: {
      lat: 39.41653,
      lng: -81.60828,
      city: 'Fleming',
      state: 'OH',
    },
    45732: {
      lat: 39.51802,
      lng: -82.06705,
      city: 'Glouster',
      state: 'OH',
    },
    45734: {
      lat: 39.63982,
      lng: -81.18816,
      city: 'Graysville',
      state: 'OH',
    },
    45735: {
      lat: 39.25183,
      lng: -81.9229,
      city: 'Guysville',
      state: 'OH',
    },
    45739: {
      lat: 39.19055,
      lng: -81.7462,
      city: 'Hockingport',
      state: 'OH',
    },
    45740: {
      lat: 39.47709,
      lng: -82.08023,
      city: 'Jacksonville',
      state: 'OH',
    },
    45741: {
      lat: 39.06648,
      lng: -82.2527,
      city: 'Langsville',
      state: 'OH',
    },
    45742: {
      lat: 39.28563,
      lng: -81.73008,
      city: 'Little Hocking',
      state: 'OH',
    },
    45743: {
      lat: 39.076,
      lng: -81.85017,
      city: 'Long Bottom',
      state: 'OH',
    },
    45744: { lat: 39.5396, lng: -81.5077, city: 'Lowell', state: 'OH' },
    45745: {
      lat: 39.61678,
      lng: -81.32689,
      city: 'Lower Salem',
      state: 'OH',
    },
    45746: {
      lat: 39.604,
      lng: -81.44809,
      city: 'Macksburg',
      state: 'OH',
    },
    45750: {
      lat: 39.4301,
      lng: -81.42947,
      city: 'Marietta',
      state: 'OH',
    },
    45760: {
      lat: 39.01395,
      lng: -82.12244,
      city: 'Middleport',
      state: 'OH',
    },
    45761: {
      lat: 39.42642,
      lng: -82.0964,
      city: 'Millfield',
      state: 'OH',
    },
    45764: {
      lat: 39.45116,
      lng: -82.24336,
      city: 'Nelsonville',
      state: 'OH',
    },
    45766: {
      lat: 39.31931,
      lng: -82.26247,
      city: 'New Marshfield',
      state: 'OH',
    },
    45767: {
      lat: 39.54367,
      lng: -81.1312,
      city: 'New Matamoras',
      state: 'OH',
    },
    45768: {
      lat: 39.4149,
      lng: -81.26468,
      city: 'Newport',
      state: 'OH',
    },
    45769: {
      lat: 39.1062,
      lng: -82.04578,
      city: 'Pomeroy',
      state: 'OH',
    },
    45770: {
      lat: 38.98586,
      lng: -81.80238,
      city: 'Portland',
      state: 'OH',
    },
    45771: {
      lat: 38.98204,
      lng: -81.89902,
      city: 'Racine',
      state: 'OH',
    },
    45772: {
      lat: 39.1408,
      lng: -81.82268,
      city: 'Reedsville',
      state: 'OH',
    },
    45773: { lat: 39.45525, lng: -81.28656, city: 'Reno', state: 'OH' },
    45775: {
      lat: 39.0876,
      lng: -82.15596,
      city: 'Rutland',
      state: 'OH',
    },
    45776: { lat: 39.18732, lng: -82.0228, city: 'Shade', state: 'OH' },
    45778: {
      lat: 39.34672,
      lng: -81.89555,
      city: 'Stewart',
      state: 'OH',
    },
    45779: {
      lat: 38.99884,
      lng: -81.97082,
      city: 'Syracuse',
      state: 'OH',
    },
    45780: {
      lat: 39.36972,
      lng: -82.13112,
      city: 'The Plains',
      state: 'OH',
    },
    45782: {
      lat: 39.48624,
      lng: -82.08076,
      city: 'Trimble',
      state: 'OH',
    },
    45783: {
      lat: 39.17505,
      lng: -81.83929,
      city: 'Tuppers Plains',
      state: 'OH',
    },
    45784: {
      lat: 39.38879,
      lng: -81.68805,
      city: 'Vincent',
      state: 'OH',
    },
    45786: {
      lat: 39.50365,
      lng: -81.65451,
      city: 'Waterford',
      state: 'OH',
    },
    45788: {
      lat: 39.51349,
      lng: -81.37016,
      city: 'Whipple',
      state: 'OH',
    },
    45789: {
      lat: 39.5514,
      lng: -81.25592,
      city: 'Wingett Run',
      state: 'OH',
    },
    45801: { lat: 40.77476, lng: -84.03703, city: 'Lima', state: 'OH' },
    45804: { lat: 40.71569, lng: -84.06911, city: 'Lima', state: 'OH' },
    45805: { lat: 40.72979, lng: -84.16242, city: 'Lima', state: 'OH' },
    45806: { lat: 40.67615, lng: -84.12651, city: 'Lima', state: 'OH' },
    45807: { lat: 40.8072, lng: -84.16699, city: 'Lima', state: 'OH' },
    45808: {
      lat: 40.83178,
      lng: -83.9729,
      city: 'Beaverdam',
      state: 'OH',
    },
    45809: { lat: 40.8431, lng: -84.18522, city: 'Gomer', state: 'OH' },
    45810: { lat: 40.77721, lng: -83.81097, city: 'Ada', state: 'OH' },
    45812: { lat: 40.68341, lng: -83.8135, city: 'Alger', state: 'OH' },
    45813: {
      lat: 41.18914,
      lng: -84.73031,
      city: 'Antwerp',
      state: 'OH',
    },
    45814: {
      lat: 40.90119,
      lng: -83.61545,
      city: 'Arlington',
      state: 'OH',
    },
    45815: {
      lat: 41.157,
      lng: -83.94267,
      city: 'Belmore',
      state: 'OH',
    },
    45816: {
      lat: 41.00452,
      lng: -83.79392,
      city: 'Benton Ridge',
      state: 'OH',
    },
    45817: {
      lat: 40.87691,
      lng: -83.88279,
      city: 'Bluffton',
      state: 'OH',
    },
    45819: {
      lat: 40.62407,
      lng: -84.26033,
      city: 'Buckland',
      state: 'OH',
    },
    45820: {
      lat: 40.83074,
      lng: -84.08473,
      city: 'Cairo',
      state: 'OH',
    },
    45821: {
      lat: 41.22643,
      lng: -84.56208,
      city: 'Cecil',
      state: 'OH',
    },
    45822: {
      lat: 40.55266,
      lng: -84.59664,
      city: 'Celina',
      state: 'OH',
    },
    45826: {
      lat: 40.43658,
      lng: -84.49368,
      city: 'Chickasaw',
      state: 'OH',
    },
    45827: {
      lat: 41.00393,
      lng: -84.30122,
      city: 'Cloverdale',
      state: 'OH',
    },
    45828: {
      lat: 40.48672,
      lng: -84.67677,
      city: 'Coldwater',
      state: 'OH',
    },
    45830: {
      lat: 40.90616,
      lng: -84.09213,
      city: 'Columbus Grove',
      state: 'OH',
    },
    45831: {
      lat: 41.11141,
      lng: -84.24993,
      city: 'Continental',
      state: 'OH',
    },
    45832: {
      lat: 40.92779,
      lng: -84.7336,
      city: 'Convoy',
      state: 'OH',
    },
    45833: {
      lat: 40.83415,
      lng: -84.35264,
      city: 'Delphos',
      state: 'OH',
    },
    45835: { lat: 40.75898, lng: -83.70076, city: 'Dola', state: 'OH' },
    45836: {
      lat: 40.79304,
      lng: -83.64311,
      city: 'Dunkirk',
      state: 'OH',
    },
    45838: {
      lat: 40.74041,
      lng: -84.47781,
      city: 'Elgin',
      state: 'OH',
    },
    45840: {
      lat: 41.02626,
      lng: -83.64943,
      city: 'Findlay',
      state: 'OH',
    },
    45841: { lat: 40.87955, lng: -83.735, city: 'Jenera', state: 'OH' },
    45843: {
      lat: 40.78202,
      lng: -83.53513,
      city: 'Forest',
      state: 'OH',
    },
    45844: {
      lat: 40.91733,
      lng: -84.2856,
      city: 'Fort Jennings',
      state: 'OH',
    },
    45845: {
      lat: 40.32995,
      lng: -84.37806,
      city: 'Fort Loramie',
      state: 'OH',
    },
    45846: {
      lat: 40.40986,
      lng: -84.74644,
      city: 'Fort Recovery',
      state: 'OH',
    },
    45848: {
      lat: 41.02421,
      lng: -84.07695,
      city: 'Glandorf',
      state: 'OH',
    },
    45849: {
      lat: 41.01241,
      lng: -84.45452,
      city: 'Grover Hill',
      state: 'OH',
    },
    45850: {
      lat: 40.70382,
      lng: -83.91817,
      city: 'Harrod',
      state: 'OH',
    },
    45851: {
      lat: 41.03267,
      lng: -84.60289,
      city: 'Haviland',
      state: 'OH',
    },
    45853: {
      lat: 40.98625,
      lng: -84.19469,
      city: 'Kalida',
      state: 'OH',
    },
    45854: {
      lat: 40.7582,
      lng: -83.95231,
      city: 'Lafayette',
      state: 'OH',
    },
    45855: { lat: 41.08819, lng: -84.5835, city: 'Latty', state: 'OH' },
    45856: {
      lat: 41.11452,
      lng: -83.99917,
      city: 'Leipsic',
      state: 'OH',
    },
    45858: {
      lat: 41.10688,
      lng: -83.80338,
      city: 'McComb',
      state: 'OH',
    },
    45859: {
      lat: 40.69305,
      lng: -83.78574,
      city: 'McGuffey',
      state: 'OH',
    },
    45860: {
      lat: 40.39365,
      lng: -84.51566,
      city: 'Maria Stein',
      state: 'OH',
    },
    45861: {
      lat: 41.08587,
      lng: -84.42569,
      city: 'Melrose',
      state: 'OH',
    },
    45862: {
      lat: 40.67079,
      lng: -84.51356,
      city: 'Mendon',
      state: 'OH',
    },
    45863: {
      lat: 40.9041,
      lng: -84.45477,
      city: 'Middle Point',
      state: 'OH',
    },
    45864: {
      lat: 41.09936,
      lng: -84.14077,
      city: 'Miller City',
      state: 'OH',
    },
    45865: {
      lat: 40.39379,
      lng: -84.37219,
      city: 'Minster',
      state: 'OH',
    },
    45866: {
      lat: 40.48905,
      lng: -84.54969,
      city: 'Montezuma',
      state: 'OH',
    },
    45867: {
      lat: 40.89795,
      lng: -83.53825,
      city: 'Mount Blanchard',
      state: 'OH',
    },
    45868: {
      lat: 40.96986,
      lng: -83.84839,
      city: 'Mount Cory',
      state: 'OH',
    },
    45869: {
      lat: 40.4573,
      lng: -84.39432,
      city: 'New Bremen',
      state: 'OH',
    },
    45870: {
      lat: 40.55621,
      lng: -83.95142,
      city: 'New Hampshire',
      state: 'OH',
    },
    45871: {
      lat: 40.4932,
      lng: -84.30089,
      city: 'New Knoxville',
      state: 'OH',
    },
    45872: {
      lat: 41.18863,
      lng: -83.68366,
      city: 'North Baltimore',
      state: 'OH',
    },
    45873: {
      lat: 41.11935,
      lng: -84.39628,
      city: 'Oakwood',
      state: 'OH',
    },
    45874: {
      lat: 40.78765,
      lng: -84.67777,
      city: 'Ohio City',
      state: 'OH',
    },
    45875: {
      lat: 41.02271,
      lng: -84.06118,
      city: 'Ottawa',
      state: 'OH',
    },
    45876: {
      lat: 40.93414,
      lng: -84.33835,
      city: 'Ottoville',
      state: 'OH',
    },
    45877: {
      lat: 40.95393,
      lng: -83.93779,
      city: 'Pandora',
      state: 'OH',
    },
    45879: {
      lat: 41.12918,
      lng: -84.56471,
      city: 'Paulding',
      state: 'OH',
    },
    45880: { lat: 41.0662, lng: -84.73782, city: 'Payne', state: 'OH' },
    45881: {
      lat: 40.94976,
      lng: -83.77426,
      city: 'Rawson',
      state: 'OH',
    },
    45882: {
      lat: 40.67823,
      lng: -84.6754,
      city: 'Rockford',
      state: 'OH',
    },
    45883: {
      lat: 40.40574,
      lng: -84.62791,
      city: 'Saint Henry',
      state: 'OH',
    },
    45884: {
      lat: 40.54865,
      lng: -84.08333,
      city: 'Saint Johns',
      state: 'OH',
    },
    45885: {
      lat: 40.57182,
      lng: -84.3871,
      city: 'Saint Marys',
      state: 'OH',
    },
    45886: {
      lat: 40.99083,
      lng: -84.61062,
      city: 'Scott',
      state: 'OH',
    },
    45887: {
      lat: 40.71107,
      lng: -84.36359,
      city: 'Spencerville',
      state: 'OH',
    },
    45888: {
      lat: 40.60244,
      lng: -84.08583,
      city: 'Uniopolis',
      state: 'OH',
    },
    45889: {
      lat: 41.14606,
      lng: -83.64101,
      city: 'Van Buren',
      state: 'OH',
    },
    45890: {
      lat: 40.97752,
      lng: -83.49105,
      city: 'Vanlue',
      state: 'OH',
    },
    45891: {
      lat: 40.8748,
      lng: -84.57785,
      city: 'Van Wert',
      state: 'OH',
    },
    45893: {
      lat: 40.88146,
      lng: -84.14536,
      city: 'Vaughnsville',
      state: 'OH',
    },
    45894: {
      lat: 40.76252,
      lng: -84.4684,
      city: 'Venedocia',
      state: 'OH',
    },
    45895: {
      lat: 40.56991,
      lng: -84.15197,
      city: 'Wapakoneta',
      state: 'OH',
    },
    45896: {
      lat: 40.60667,
      lng: -83.93775,
      city: 'Waynesfield',
      state: 'OH',
    },
    45897: {
      lat: 40.83434,
      lng: -83.65676,
      city: 'Williamstown',
      state: 'OH',
    },
    45898: {
      lat: 40.73617,
      lng: -84.76429,
      city: 'Willshire',
      state: 'OH',
    },
    45899: { lat: 40.80088, lng: -84.77491, city: 'Wren', state: 'OH' },
    46001: {
      lat: 40.25141,
      lng: -85.65838,
      city: 'Alexandria',
      state: 'IN',
    },
    46011: {
      lat: 40.12759,
      lng: -85.76178,
      city: 'Anderson',
      state: 'IN',
    },
    46012: {
      lat: 40.1492,
      lng: -85.62552,
      city: 'Anderson',
      state: 'IN',
    },
    46013: {
      lat: 40.05021,
      lng: -85.67829,
      city: 'Anderson',
      state: 'IN',
    },
    46016: {
      lat: 40.09781,
      lng: -85.68152,
      city: 'Anderson',
      state: 'IN',
    },
    46017: {
      lat: 40.07358,
      lng: -85.60687,
      city: 'Anderson',
      state: 'IN',
    },
    46030: {
      lat: 40.17031,
      lng: -86.02118,
      city: 'Arcadia',
      state: 'IN',
    },
    46031: {
      lat: 40.20935,
      lng: -86.01018,
      city: 'Atlanta',
      state: 'IN',
    },
    46032: {
      lat: 39.96535,
      lng: -86.17104,
      city: 'Carmel',
      state: 'IN',
    },
    46033: {
      lat: 39.97975,
      lng: -86.08595,
      city: 'Carmel',
      state: 'IN',
    },
    46034: {
      lat: 40.13291,
      lng: -86.04128,
      city: 'Cicero',
      state: 'IN',
    },
    46035: {
      lat: 40.18925,
      lng: -86.67902,
      city: 'Colfax',
      state: 'IN',
    },
    46036: {
      lat: 40.29689,
      lng: -85.83289,
      city: 'Elwood',
      state: 'IN',
    },
    46037: {
      lat: 39.96077,
      lng: -85.94446,
      city: 'Fishers',
      state: 'IN',
    },
    46038: {
      lat: 39.96722,
      lng: -86.01712,
      city: 'Fishers',
      state: 'IN',
    },
    46039: {
      lat: 40.37022,
      lng: -86.31173,
      city: 'Forest',
      state: 'IN',
    },
    46040: {
      lat: 39.92773,
      lng: -85.83424,
      city: 'Fortville',
      state: 'IN',
    },
    46041: {
      lat: 40.29695,
      lng: -86.49216,
      city: 'Frankfort',
      state: 'IN',
    },
    46044: {
      lat: 40.2085,
      lng: -85.79026,
      city: 'Frankton',
      state: 'IN',
    },
    46045: {
      lat: 40.2898,
      lng: -86.14832,
      city: 'Goldsmith',
      state: 'IN',
    },
    46047: {
      lat: 40.28418,
      lng: -85.94739,
      city: 'Hobbs',
      state: 'IN',
    },
    46048: {
      lat: 39.9543,
      lng: -85.80755,
      city: 'Ingalls',
      state: 'IN',
    },
    46049: {
      lat: 40.29757,
      lng: -86.22165,
      city: 'Kempton',
      state: 'IN',
    },
    46050: {
      lat: 40.20515,
      lng: -86.34629,
      city: 'Kirklin',
      state: 'IN',
    },
    46051: {
      lat: 40.05855,
      lng: -85.83794,
      city: 'Lapel',
      state: 'IN',
    },
    46052: {
      lat: 40.04448,
      lng: -86.46086,
      city: 'Lebanon',
      state: 'IN',
    },
    46055: {
      lat: 39.88627,
      lng: -85.90404,
      city: 'Mccordsville',
      state: 'IN',
    },
    46056: {
      lat: 39.97351,
      lng: -85.6105,
      city: 'Markleville',
      state: 'IN',
    },
    46057: {
      lat: 40.33796,
      lng: -86.37801,
      city: 'Michigantown',
      state: 'IN',
    },
    46058: {
      lat: 40.35981,
      lng: -86.64777,
      city: 'Mulberry',
      state: 'IN',
    },
    46060: {
      lat: 40.06616,
      lng: -85.93137,
      city: 'Noblesville',
      state: 'IN',
    },
    46062: {
      lat: 40.06146,
      lng: -86.05523,
      city: 'Noblesville',
      state: 'IN',
    },
    46063: {
      lat: 40.27184,
      lng: -85.72389,
      city: 'Orestes',
      state: 'IN',
    },
    46064: {
      lat: 39.98473,
      lng: -85.74701,
      city: 'Pendleton',
      state: 'IN',
    },
    46065: {
      lat: 40.42318,
      lng: -86.60992,
      city: 'Rossville',
      state: 'IN',
    },
    46068: {
      lat: 40.37511,
      lng: -86.1112,
      city: 'Sharpsville',
      state: 'IN',
    },
    46069: {
      lat: 40.13829,
      lng: -86.23144,
      city: 'Sheridan',
      state: 'IN',
    },
    46070: {
      lat: 40.34315,
      lng: -85.65928,
      city: 'Summitville',
      state: 'IN',
    },
    46071: {
      lat: 40.11941,
      lng: -86.60549,
      city: 'Thorntown',
      state: 'IN',
    },
    46072: {
      lat: 40.28526,
      lng: -86.06891,
      city: 'Tipton',
      state: 'IN',
    },
    46074: {
      lat: 40.03651,
      lng: -86.17289,
      city: 'Westfield',
      state: 'IN',
    },
    46075: {
      lat: 40.02637,
      lng: -86.3346,
      city: 'Whitestown',
      state: 'IN',
    },
    46076: {
      lat: 40.36318,
      lng: -85.9374,
      city: 'Windfall',
      state: 'IN',
    },
    46077: {
      lat: 39.98281,
      lng: -86.28346,
      city: 'Zionsville',
      state: 'IN',
    },
    46102: {
      lat: 39.99556,
      lng: -86.61993,
      city: 'Advance',
      state: 'IN',
    },
    46103: { lat: 39.6896, lng: -86.61384, city: 'Amo', state: 'IN' },
    46104: {
      lat: 39.66041,
      lng: -85.59545,
      city: 'Arlington',
      state: 'IN',
    },
    46105: {
      lat: 39.76388,
      lng: -86.81487,
      city: 'Bainbridge',
      state: 'IN',
    },
    46106: {
      lat: 39.51555,
      lng: -86.20524,
      city: 'Bargersville',
      state: 'IN',
    },
    46107: {
      lat: 39.71661,
      lng: -86.09161,
      city: 'Beech Grove',
      state: 'IN',
    },
    46110: {
      lat: 39.56021,
      lng: -85.91333,
      city: 'Boggstown',
      state: 'IN',
    },
    46111: {
      lat: 39.5417,
      lng: -86.36863,
      city: 'Brooklyn',
      state: 'IN',
    },
    46112: {
      lat: 39.86561,
      lng: -86.38215,
      city: 'Brownsburg',
      state: 'IN',
    },
    46113: { lat: 39.635, lng: -86.30548, city: 'Camby', state: 'IN' },
    46115: {
      lat: 39.7375,
      lng: -85.56623,
      city: 'Carthage',
      state: 'IN',
    },
    46117: {
      lat: 39.81507,
      lng: -85.61763,
      city: 'Charlottesville',
      state: 'IN',
    },
    46118: {
      lat: 39.65317,
      lng: -86.52394,
      city: 'Clayton',
      state: 'IN',
    },
    46120: {
      lat: 39.51252,
      lng: -86.78711,
      city: 'Cloverdale',
      state: 'IN',
    },
    46121: {
      lat: 39.67776,
      lng: -86.67345,
      city: 'Coatesville',
      state: 'IN',
    },
    46122: {
      lat: 39.76822,
      lng: -86.5475,
      city: 'Danville',
      state: 'IN',
    },
    46123: { lat: 39.76502, lng: -86.40015, city: 'Avon', state: 'IN' },
    46124: {
      lat: 39.36914,
      lng: -85.94551,
      city: 'Edinburgh',
      state: 'IN',
    },
    46125: {
      lat: 39.52188,
      lng: -86.64179,
      city: 'Eminence',
      state: 'IN',
    },
    46126: {
      lat: 39.62053,
      lng: -85.88694,
      city: 'Fairland',
      state: 'IN',
    },
    46127: {
      lat: 39.71766,
      lng: -85.3158,
      city: 'Falmouth',
      state: 'IN',
    },
    46128: {
      lat: 39.65634,
      lng: -86.74312,
      city: 'Fillmore',
      state: 'IN',
    },
    46130: {
      lat: 39.67464,
      lng: -85.83571,
      city: 'Fountaintown',
      state: 'IN',
    },
    46131: {
      lat: 39.47196,
      lng: -86.04233,
      city: 'Franklin',
      state: 'IN',
    },
    46133: {
      lat: 39.58945,
      lng: -85.30044,
      city: 'Glenwood',
      state: 'IN',
    },
    46135: {
      lat: 39.65909,
      lng: -86.89107,
      city: 'Greencastle',
      state: 'IN',
    },
    46140: {
      lat: 39.80358,
      lng: -85.77049,
      city: 'Greenfield',
      state: 'IN',
    },
    46142: {
      lat: 39.62029,
      lng: -86.1764,
      city: 'Greenwood',
      state: 'IN',
    },
    46143: {
      lat: 39.59484,
      lng: -86.11323,
      city: 'Greenwood',
      state: 'IN',
    },
    46144: {
      lat: 39.66586,
      lng: -85.64706,
      city: 'Gwynneville',
      state: 'IN',
    },
    46146: {
      lat: 39.57968,
      lng: -85.56972,
      city: 'Homer',
      state: 'IN',
    },
    46147: {
      lat: 39.96988,
      lng: -86.62693,
      city: 'Jamestown',
      state: 'IN',
    },
    46148: {
      lat: 39.80906,
      lng: -85.51296,
      city: 'Knightstown',
      state: 'IN',
    },
    46149: {
      lat: 39.88545,
      lng: -86.55758,
      city: 'Lizton',
      state: 'IN',
    },
    46150: {
      lat: 39.54695,
      lng: -85.60232,
      city: 'Manilla',
      state: 'IN',
    },
    46151: {
      lat: 39.44783,
      lng: -86.4275,
      city: 'Martinsville',
      state: 'IN',
    },
    46154: {
      lat: 39.85639,
      lng: -85.77038,
      city: 'Maxwell',
      state: 'IN',
    },
    46155: { lat: 39.74355, lng: -85.42966, city: 'Mays', state: 'IN' },
    46156: {
      lat: 39.48451,
      lng: -85.4806,
      city: 'Milroy',
      state: 'IN',
    },
    46157: {
      lat: 39.55623,
      lng: -86.52741,
      city: 'Monrovia',
      state: 'IN',
    },
    46158: {
      lat: 39.58275,
      lng: -86.37405,
      city: 'Mooresville',
      state: 'IN',
    },
    46160: {
      lat: 39.35429,
      lng: -86.26753,
      city: 'Morgantown',
      state: 'IN',
    },
    46161: {
      lat: 39.66959,
      lng: -85.69395,
      city: 'Morristown',
      state: 'IN',
    },
    46162: {
      lat: 39.55021,
      lng: -85.95242,
      city: 'Needham',
      state: 'IN',
    },
    46163: {
      lat: 39.7287,
      lng: -85.90435,
      city: 'New Palestine',
      state: 'IN',
    },
    46164: {
      lat: 39.3036,
      lng: -86.12301,
      city: 'Nineveh',
      state: 'IN',
    },
    46165: {
      lat: 39.84522,
      lng: -86.64716,
      city: 'North Salem',
      state: 'IN',
    },
    46166: {
      lat: 39.42686,
      lng: -86.58755,
      city: 'Paragon',
      state: 'IN',
    },
    46167: {
      lat: 39.87675,
      lng: -86.46981,
      city: 'Pittsboro',
      state: 'IN',
    },
    46168: {
      lat: 39.68985,
      lng: -86.39262,
      city: 'Plainfield',
      state: 'IN',
    },
    46170: {
      lat: 39.57164,
      lng: -86.86277,
      city: 'Putnamville',
      state: 'IN',
    },
    46171: {
      lat: 39.53527,
      lng: -86.96815,
      city: 'Reelsville',
      state: 'IN',
    },
    46172: {
      lat: 39.82725,
      lng: -86.8144,
      city: 'Roachdale',
      state: 'IN',
    },
    46173: {
      lat: 39.60294,
      lng: -85.42677,
      city: 'Rushville',
      state: 'IN',
    },
    46175: {
      lat: 39.82199,
      lng: -86.97292,
      city: 'Russellville',
      state: 'IN',
    },
    46176: {
      lat: 39.52684,
      lng: -85.77297,
      city: 'Shelbyville',
      state: 'IN',
    },
    46180: {
      lat: 39.59652,
      lng: -86.61801,
      city: 'Stilesville',
      state: 'IN',
    },
    46181: {
      lat: 39.38044,
      lng: -86.16119,
      city: 'Trafalgar',
      state: 'IN',
    },
    46182: {
      lat: 39.46157,
      lng: -85.67005,
      city: 'Waldron',
      state: 'IN',
    },
    46183: {
      lat: 39.65397,
      lng: -86.28121,
      city: 'West Newton',
      state: 'IN',
    },
    46184: {
      lat: 39.55967,
      lng: -86.07361,
      city: 'Whiteland',
      state: 'IN',
    },
    46186: {
      lat: 39.89126,
      lng: -85.64264,
      city: 'Wilkinson',
      state: 'IN',
    },
    46201: {
      lat: 39.77369,
      lng: -86.10949,
      city: 'Indianapolis',
      state: 'IN',
    },
    46202: {
      lat: 39.78363,
      lng: -86.16187,
      city: 'Indianapolis',
      state: 'IN',
    },
    46203: {
      lat: 39.7368,
      lng: -86.09586,
      city: 'Indianapolis',
      state: 'IN',
    },
    46204: {
      lat: 39.77134,
      lng: -86.15703,
      city: 'Indianapolis',
      state: 'IN',
    },
    46205: {
      lat: 39.82813,
      lng: -86.13499,
      city: 'Indianapolis',
      state: 'IN',
    },
    46206: {
      lat: 39.76027,
      lng: -86.16107,
      city: 'Indianapolis',
      state: 'IN',
    },
    46208: {
      lat: 39.82307,
      lng: -86.17307,
      city: 'Indianapolis',
      state: 'IN',
    },
    46214: {
      lat: 39.7917,
      lng: -86.29009,
      city: 'Indianapolis',
      state: 'IN',
    },
    46216: {
      lat: 39.86593,
      lng: -86.00929,
      city: 'Indianapolis',
      state: 'IN',
    },
    46217: {
      lat: 39.67254,
      lng: -86.19338,
      city: 'Indianapolis',
      state: 'IN',
    },
    46218: {
      lat: 39.80713,
      lng: -86.09958,
      city: 'Indianapolis',
      state: 'IN',
    },
    46219: {
      lat: 39.78314,
      lng: -86.04444,
      city: 'Indianapolis',
      state: 'IN',
    },
    46220: {
      lat: 39.8676,
      lng: -86.10818,
      city: 'Indianapolis',
      state: 'IN',
    },
    46221: {
      lat: 39.69288,
      lng: -86.23664,
      city: 'Indianapolis',
      state: 'IN',
    },
    46222: {
      lat: 39.79088,
      lng: -86.21477,
      city: 'Indianapolis',
      state: 'IN',
    },
    46224: {
      lat: 39.79537,
      lng: -86.25624,
      city: 'Indianapolis',
      state: 'IN',
    },
    46225: {
      lat: 39.74062,
      lng: -86.16294,
      city: 'Indianapolis',
      state: 'IN',
    },
    46226: {
      lat: 39.83968,
      lng: -86.05117,
      city: 'Indianapolis',
      state: 'IN',
    },
    46227: {
      lat: 39.67607,
      lng: -86.13145,
      city: 'Indianapolis',
      state: 'IN',
    },
    46228: {
      lat: 39.8487,
      lng: -86.20045,
      city: 'Indianapolis',
      state: 'IN',
    },
    46229: {
      lat: 39.78852,
      lng: -85.978,
      city: 'Indianapolis',
      state: 'IN',
    },
    46231: {
      lat: 39.72086,
      lng: -86.32712,
      city: 'Indianapolis',
      state: 'IN',
    },
    46234: {
      lat: 39.81077,
      lng: -86.32665,
      city: 'Indianapolis',
      state: 'IN',
    },
    46235: {
      lat: 39.83784,
      lng: -85.97651,
      city: 'Indianapolis',
      state: 'IN',
    },
    46236: {
      lat: 39.89132,
      lng: -85.96766,
      city: 'Indianapolis',
      state: 'IN',
    },
    46237: {
      lat: 39.67247,
      lng: -86.0759,
      city: 'Indianapolis',
      state: 'IN',
    },
    46239: {
      lat: 39.72311,
      lng: -86.0004,
      city: 'Indianapolis',
      state: 'IN',
    },
    46240: {
      lat: 39.90697,
      lng: -86.12367,
      city: 'Indianapolis',
      state: 'IN',
    },
    46241: {
      lat: 39.72389,
      lng: -86.26842,
      city: 'Indianapolis',
      state: 'IN',
    },
    46250: {
      lat: 39.90399,
      lng: -86.06864,
      city: 'Indianapolis',
      state: 'IN',
    },
    46254: {
      lat: 39.84796,
      lng: -86.26936,
      city: 'Indianapolis',
      state: 'IN',
    },
    46256: {
      lat: 39.90682,
      lng: -86.01152,
      city: 'Indianapolis',
      state: 'IN',
    },
    46259: {
      lat: 39.65078,
      lng: -85.98692,
      city: 'Indianapolis',
      state: 'IN',
    },
    46260: {
      lat: 39.89744,
      lng: -86.17927,
      city: 'Indianapolis',
      state: 'IN',
    },
    46268: {
      lat: 39.89769,
      lng: -86.23253,
      city: 'Indianapolis',
      state: 'IN',
    },
    46278: {
      lat: 39.89389,
      lng: -86.29606,
      city: 'Indianapolis',
      state: 'IN',
    },
    46280: {
      lat: 39.93714,
      lng: -86.11786,
      city: 'Indianapolis',
      state: 'IN',
    },
    46290: {
      lat: 39.93376,
      lng: -86.16256,
      city: 'Indianapolis',
      state: 'IN',
    },
    46301: {
      lat: 41.68687,
      lng: -86.97438,
      city: 'Beverly Shores',
      state: 'IN',
    },
    46302: {
      lat: 41.3604,
      lng: -87.12645,
      city: 'Boone Grove',
      state: 'IN',
    },
    46303: {
      lat: 41.37198,
      lng: -87.4765,
      city: 'Cedar Lake',
      state: 'IN',
    },
    46304: {
      lat: 41.61311,
      lng: -87.04905,
      city: 'Chesterton',
      state: 'IN',
    },
    46307: {
      lat: 41.40215,
      lng: -87.32693,
      city: 'Crown Point',
      state: 'IN',
    },
    46310: {
      lat: 41.18205,
      lng: -87.23088,
      city: 'Demotte',
      state: 'IN',
    },
    46311: { lat: 41.46846, lng: -87.50918, city: 'Dyer', state: 'IN' },
    46312: {
      lat: 41.64705,
      lng: -87.45446,
      city: 'East Chicago',
      state: 'IN',
    },
    46319: {
      lat: 41.52217,
      lng: -87.42091,
      city: 'Griffith',
      state: 'IN',
    },
    46320: {
      lat: 41.60893,
      lng: -87.49754,
      city: 'Hammond',
      state: 'IN',
    },
    46321: {
      lat: 41.54704,
      lng: -87.50402,
      city: 'Munster',
      state: 'IN',
    },
    46322: {
      lat: 41.54818,
      lng: -87.45884,
      city: 'Highland',
      state: 'IN',
    },
    46323: {
      lat: 41.58961,
      lng: -87.45323,
      city: 'Hammond',
      state: 'IN',
    },
    46324: {
      lat: 41.58324,
      lng: -87.50155,
      city: 'Hammond',
      state: 'IN',
    },
    46327: {
      lat: 41.64048,
      lng: -87.50677,
      city: 'Hammond',
      state: 'IN',
    },
    46340: {
      lat: 41.38339,
      lng: -86.76846,
      city: 'Hanna',
      state: 'IN',
    },
    46341: {
      lat: 41.30666,
      lng: -87.21962,
      city: 'Hebron',
      state: 'IN',
    },
    46342: {
      lat: 41.51761,
      lng: -87.24468,
      city: 'Hobart',
      state: 'IN',
    },
    46345: {
      lat: 41.53032,
      lng: -86.69995,
      city: 'Kingsbury',
      state: 'IN',
    },
    46346: {
      lat: 41.47816,
      lng: -86.69332,
      city: 'Kingsford Heights',
      state: 'IN',
    },
    46347: {
      lat: 41.30502,
      lng: -87.00859,
      city: 'Kouts',
      state: 'IN',
    },
    46348: {
      lat: 41.30981,
      lng: -86.86244,
      city: 'La Crosse',
      state: 'IN',
    },
    46349: {
      lat: 41.10899,
      lng: -87.42359,
      city: 'Lake Village',
      state: 'IN',
    },
    46350: {
      lat: 41.60697,
      lng: -86.72157,
      city: 'La Porte',
      state: 'IN',
    },
    46356: {
      lat: 41.26117,
      lng: -87.41863,
      city: 'Lowell',
      state: 'IN',
    },
    46360: {
      lat: 41.68739,
      lng: -86.86464,
      city: 'Michigan City',
      state: 'IN',
    },
    46365: {
      lat: 41.60605,
      lng: -86.54112,
      city: 'Mill Creek',
      state: 'IN',
    },
    46366: {
      lat: 41.21443,
      lng: -86.76922,
      city: 'North Judson',
      state: 'IN',
    },
    46368: {
      lat: 41.5848,
      lng: -87.17937,
      city: 'Portage',
      state: 'IN',
    },
    46371: {
      lat: 41.67683,
      lng: -86.6005,
      city: 'Rolling Prairie',
      state: 'IN',
    },
    46373: {
      lat: 41.44578,
      lng: -87.46954,
      city: 'Saint John',
      state: 'IN',
    },
    46374: {
      lat: 41.20616,
      lng: -86.895,
      city: 'San Pierre',
      state: 'IN',
    },
    46375: {
      lat: 41.49162,
      lng: -87.45193,
      city: 'Schererville',
      state: 'IN',
    },
    46376: {
      lat: 41.1838,
      lng: -87.47603,
      city: 'Schneider',
      state: 'IN',
    },
    46377: {
      lat: 41.19432,
      lng: -87.34777,
      city: 'Shelby',
      state: 'IN',
    },
    46379: {
      lat: 41.15682,
      lng: -87.4302,
      city: 'Sumava Resorts',
      state: 'IN',
    },
    46381: {
      lat: 41.17635,
      lng: -87.33436,
      city: 'Thayer',
      state: 'IN',
    },
    46382: {
      lat: 41.46868,
      lng: -86.76212,
      city: 'Union Mills',
      state: 'IN',
    },
    46383: {
      lat: 41.45838,
      lng: -87.00156,
      city: 'Valparaiso',
      state: 'IN',
    },
    46385: {
      lat: 41.46395,
      lng: -87.12396,
      city: 'Valparaiso',
      state: 'IN',
    },
    46390: {
      lat: 41.41677,
      lng: -86.8742,
      city: 'Wanatah',
      state: 'IN',
    },
    46391: {
      lat: 41.5455,
      lng: -86.91446,
      city: 'Westville',
      state: 'IN',
    },
    46392: {
      lat: 41.18509,
      lng: -87.04948,
      city: 'Wheatfield',
      state: 'IN',
    },
    46393: {
      lat: 41.5139,
      lng: -87.16801,
      city: 'Wheeler',
      state: 'IN',
    },
    46394: {
      lat: 41.67733,
      lng: -87.50279,
      city: 'Whiting',
      state: 'IN',
    },
    46402: { lat: 41.61032, lng: -87.33297, city: 'Gary', state: 'IN' },
    46403: { lat: 41.60636, lng: -87.25803, city: 'Gary', state: 'IN' },
    46404: { lat: 41.58475, lng: -87.37502, city: 'Gary', state: 'IN' },
    46405: {
      lat: 41.57409,
      lng: -87.26285,
      city: 'Lake Station',
      state: 'IN',
    },
    46406: { lat: 41.6022, lng: -87.40804, city: 'Gary', state: 'IN' },
    46407: { lat: 41.57844, lng: -87.33047, city: 'Gary', state: 'IN' },
    46408: { lat: 41.54468, lng: -87.36941, city: 'Gary', state: 'IN' },
    46409: { lat: 41.54828, lng: -87.32422, city: 'Gary', state: 'IN' },
    46410: {
      lat: 41.48083,
      lng: -87.33293,
      city: 'Merrillville',
      state: 'IN',
    },
    46501: {
      lat: 41.22327,
      lng: -86.24868,
      city: 'Argos',
      state: 'IN',
    },
    46502: {
      lat: 41.26099,
      lng: -85.97699,
      city: 'Atwood',
      state: 'IN',
    },
    46504: {
      lat: 41.30057,
      lng: -86.11204,
      city: 'Bourbon',
      state: 'IN',
    },
    46506: {
      lat: 41.46316,
      lng: -86.16915,
      city: 'Bremen',
      state: 'IN',
    },
    46507: {
      lat: 41.72138,
      lng: -85.81571,
      city: 'Bristol',
      state: 'IN',
    },
    46508: {
      lat: 41.15499,
      lng: -85.96904,
      city: 'Burket',
      state: 'IN',
    },
    46510: {
      lat: 41.12068,
      lng: -85.874,
      city: 'Claypool',
      state: 'IN',
    },
    46511: {
      lat: 41.22182,
      lng: -86.42889,
      city: 'Culver',
      state: 'IN',
    },
    46513: {
      lat: 41.3629,
      lng: -86.44816,
      city: 'Donaldson',
      state: 'IN',
    },
    46514: {
      lat: 41.72328,
      lng: -85.97547,
      city: 'Elkhart',
      state: 'IN',
    },
    46516: {
      lat: 41.6756,
      lng: -85.94584,
      city: 'Elkhart',
      state: 'IN',
    },
    46517: {
      lat: 41.62429,
      lng: -85.99675,
      city: 'Elkhart',
      state: 'IN',
    },
    46524: {
      lat: 41.2979,
      lng: -86.02143,
      city: 'Etna Green',
      state: 'IN',
    },
    46526: {
      lat: 41.55307,
      lng: -85.88448,
      city: 'Goshen',
      state: 'IN',
    },
    46528: { lat: 41.60698, lng: -85.786, city: 'Goshen', state: 'IN' },
    46530: {
      lat: 41.74031,
      lng: -86.12749,
      city: 'Granger',
      state: 'IN',
    },
    46531: {
      lat: 41.35686,
      lng: -86.51558,
      city: 'Grovertown',
      state: 'IN',
    },
    46532: {
      lat: 41.41762,
      lng: -86.62119,
      city: 'Hamlet',
      state: 'IN',
    },
    46534: { lat: 41.28561, lng: -86.61878, city: 'Knox', state: 'IN' },
    46536: {
      lat: 41.51913,
      lng: -86.2867,
      city: 'Lakeville',
      state: 'IN',
    },
    46537: {
      lat: 41.45724,
      lng: -86.30669,
      city: 'Lapaz',
      state: 'IN',
    },
    46538: {
      lat: 41.32829,
      lng: -85.82703,
      city: 'Leesburg',
      state: 'IN',
    },
    46539: {
      lat: 41.16579,
      lng: -86.02545,
      city: 'Mentone',
      state: 'IN',
    },
    46540: {
      lat: 41.67571,
      lng: -85.69768,
      city: 'Middlebury',
      state: 'IN',
    },
    46542: {
      lat: 41.39303,
      lng: -85.87448,
      city: 'Milford',
      state: 'IN',
    },
    46543: {
      lat: 41.53341,
      lng: -85.67119,
      city: 'Millersburg',
      state: 'IN',
    },
    46544: {
      lat: 41.61778,
      lng: -86.13715,
      city: 'Mishawaka',
      state: 'IN',
    },
    46545: {
      lat: 41.69376,
      lng: -86.14602,
      city: 'Mishawaka',
      state: 'IN',
    },
    46550: {
      lat: 41.44701,
      lng: -86.00028,
      city: 'Nappanee',
      state: 'IN',
    },
    46552: {
      lat: 41.70731,
      lng: -86.485,
      city: 'New Carlisle',
      state: 'IN',
    },
    46553: {
      lat: 41.48004,
      lng: -85.85095,
      city: 'New Paris',
      state: 'IN',
    },
    46554: {
      lat: 41.56252,
      lng: -86.41947,
      city: 'North Liberty',
      state: 'IN',
    },
    46555: {
      lat: 41.32858,
      lng: -85.68833,
      city: 'North Webster',
      state: 'IN',
    },
    46556: {
      lat: 41.70198,
      lng: -86.24513,
      city: 'Notre Dame',
      state: 'IN',
    },
    46561: {
      lat: 41.66683,
      lng: -86.07599,
      city: 'Osceola',
      state: 'IN',
    },
    46562: {
      lat: 41.20947,
      lng: -85.69757,
      city: 'Pierceton',
      state: 'IN',
    },
    46563: {
      lat: 41.35541,
      lng: -86.32612,
      city: 'Plymouth',
      state: 'IN',
    },
    46565: {
      lat: 41.69285,
      lng: -85.5831,
      city: 'Shipshewana',
      state: 'IN',
    },
    46567: {
      lat: 41.41473,
      lng: -85.73044,
      city: 'Syracuse',
      state: 'IN',
    },
    46570: {
      lat: 41.20766,
      lng: -86.1207,
      city: 'Tippecanoe',
      state: 'IN',
    },
    46571: {
      lat: 41.56795,
      lng: -85.54667,
      city: 'Topeka',
      state: 'IN',
    },
    46572: { lat: 41.40804, lng: -86.393, city: 'Tyner', state: 'IN' },
    46573: {
      lat: 41.53941,
      lng: -86.06054,
      city: 'Wakarusa',
      state: 'IN',
    },
    46574: {
      lat: 41.47327,
      lng: -86.48829,
      city: 'Walkerton',
      state: 'IN',
    },
    46580: {
      lat: 41.20884,
      lng: -85.87212,
      city: 'Warsaw',
      state: 'IN',
    },
    46582: {
      lat: 41.28323,
      lng: -85.85235,
      city: 'Warsaw',
      state: 'IN',
    },
    46590: {
      lat: 41.21612,
      lng: -85.80862,
      city: 'Winona Lake',
      state: 'IN',
    },
    46595: {
      lat: 41.52633,
      lng: -86.16367,
      city: 'Wyatt',
      state: 'IN',
    },
    46601: {
      lat: 41.67065,
      lng: -86.252,
      city: 'South Bend',
      state: 'IN',
    },
    46613: {
      lat: 41.65389,
      lng: -86.264,
      city: 'South Bend',
      state: 'IN',
    },
    46614: {
      lat: 41.60359,
      lng: -86.28064,
      city: 'South Bend',
      state: 'IN',
    },
    46615: {
      lat: 41.67494,
      lng: -86.21151,
      city: 'South Bend',
      state: 'IN',
    },
    46616: {
      lat: 41.69693,
      lng: -86.26671,
      city: 'South Bend',
      state: 'IN',
    },
    46617: {
      lat: 41.68435,
      lng: -86.23513,
      city: 'South Bend',
      state: 'IN',
    },
    46619: {
      lat: 41.65997,
      lng: -86.3532,
      city: 'South Bend',
      state: 'IN',
    },
    46624: {
      lat: 41.70252,
      lng: -86.25164,
      city: 'South Bend',
      state: 'IN',
    },
    46628: {
      lat: 41.72103,
      lng: -86.33465,
      city: 'South Bend',
      state: 'IN',
    },
    46635: {
      lat: 41.71518,
      lng: -86.20832,
      city: 'South Bend',
      state: 'IN',
    },
    46637: {
      lat: 41.73358,
      lng: -86.24299,
      city: 'South Bend',
      state: 'IN',
    },
    46701: {
      lat: 41.35846,
      lng: -85.43034,
      city: 'Albion',
      state: 'IN',
    },
    46702: {
      lat: 40.82681,
      lng: -85.61732,
      city: 'Andrews',
      state: 'IN',
    },
    46703: {
      lat: 41.65305,
      lng: -85.00976,
      city: 'Angola',
      state: 'IN',
    },
    46704: {
      lat: 41.10371,
      lng: -85.29242,
      city: 'Arcola',
      state: 'IN',
    },
    46705: {
      lat: 41.51816,
      lng: -85.06029,
      city: 'Ashley',
      state: 'IN',
    },
    46706: {
      lat: 41.34242,
      lng: -85.03922,
      city: 'Auburn',
      state: 'IN',
    },
    46710: {
      lat: 41.35167,
      lng: -85.25168,
      city: 'Avilla',
      state: 'IN',
    },
    46711: { lat: 40.6635, lng: -84.92398, city: 'Berne', state: 'IN' },
    46714: {
      lat: 40.71774,
      lng: -85.17319,
      city: 'Bluffton',
      state: 'IN',
    },
    46721: {
      lat: 41.4237,
      lng: -84.87425,
      city: 'Butler',
      state: 'IN',
    },
    46723: {
      lat: 41.23828,
      lng: -85.32441,
      city: 'Churubusco',
      state: 'IN',
    },
    46725: {
      lat: 41.1518,
      lng: -85.47655,
      city: 'Columbia City',
      state: 'IN',
    },
    46730: {
      lat: 41.454,
      lng: -85.15504,
      city: 'Corunna',
      state: 'IN',
    },
    46731: {
      lat: 40.79751,
      lng: -85.10267,
      city: 'Craigville',
      state: 'IN',
    },
    46732: {
      lat: 41.37697,
      lng: -85.62169,
      city: 'Cromwell',
      state: 'IN',
    },
    46733: {
      lat: 40.83033,
      lng: -84.94023,
      city: 'Decatur',
      state: 'IN',
    },
    46737: {
      lat: 41.72209,
      lng: -84.92233,
      city: 'Fremont',
      state: 'IN',
    },
    46738: {
      lat: 41.32189,
      lng: -85.13731,
      city: 'Garrett',
      state: 'IN',
    },
    46740: {
      lat: 40.60298,
      lng: -84.9638,
      city: 'Geneva',
      state: 'IN',
    },
    46741: {
      lat: 41.21161,
      lng: -84.95176,
      city: 'Grabill',
      state: 'IN',
    },
    46742: {
      lat: 41.54919,
      lng: -84.87892,
      city: 'Hamilton',
      state: 'IN',
    },
    46743: {
      lat: 41.21511,
      lng: -84.85321,
      city: 'Harlan',
      state: 'IN',
    },
    46745: {
      lat: 40.95194,
      lng: -85.00887,
      city: 'Hoagland',
      state: 'IN',
    },
    46746: { lat: 41.72257, lng: -85.34914, city: 'Howe', state: 'IN' },
    46747: {
      lat: 41.56042,
      lng: -85.15349,
      city: 'Hudson',
      state: 'IN',
    },
    46748: {
      lat: 41.24829,
      lng: -85.16727,
      city: 'Huntertown',
      state: 'IN',
    },
    46750: {
      lat: 40.87677,
      lng: -85.49674,
      city: 'Huntington',
      state: 'IN',
    },
    46755: {
      lat: 41.45119,
      lng: -85.27209,
      city: 'Kendallville',
      state: 'IN',
    },
    46759: {
      lat: 40.59855,
      lng: -85.18299,
      city: 'Keystone',
      state: 'IN',
    },
    46760: {
      lat: 41.34441,
      lng: -85.55654,
      city: 'Kimmell',
      state: 'IN',
    },
    46761: {
      lat: 41.63576,
      lng: -85.37127,
      city: 'Lagrange',
      state: 'IN',
    },
    46763: {
      lat: 41.29418,
      lng: -85.2415,
      city: 'Laotto',
      state: 'IN',
    },
    46764: {
      lat: 41.2176,
      lng: -85.62376,
      city: 'Larwill',
      state: 'IN',
    },
    46765: { lat: 41.23361, lng: -85.04047, city: 'Leo', state: 'IN' },
    46766: {
      lat: 40.70984,
      lng: -85.29424,
      city: 'Liberty Center',
      state: 'IN',
    },
    46767: {
      lat: 41.46487,
      lng: -85.5764,
      city: 'Ligonier',
      state: 'IN',
    },
    46770: {
      lat: 40.8298,
      lng: -85.31873,
      city: 'Markle',
      state: 'IN',
    },
    46771: {
      lat: 41.69272,
      lng: -85.29639,
      city: 'Mongo',
      state: 'IN',
    },
    46772: {
      lat: 40.7221,
      lng: -84.91153,
      city: 'Monroe',
      state: 'IN',
    },
    46773: {
      lat: 40.98401,
      lng: -84.8759,
      city: 'Monroeville',
      state: 'IN',
    },
    46774: {
      lat: 41.09103,
      lng: -84.96955,
      city: 'New Haven',
      state: 'IN',
    },
    46776: {
      lat: 41.72793,
      lng: -85.16878,
      city: 'Orland',
      state: 'IN',
    },
    46777: {
      lat: 40.87248,
      lng: -85.15236,
      city: 'Ossian',
      state: 'IN',
    },
    46778: {
      lat: 40.61055,
      lng: -85.15491,
      city: 'Petroleum',
      state: 'IN',
    },
    46779: {
      lat: 41.5725,
      lng: -85.03094,
      city: 'Pleasant Lake',
      state: 'IN',
    },
    46781: {
      lat: 40.63659,
      lng: -85.27339,
      city: 'Poneto',
      state: 'IN',
    },
    46783: {
      lat: 40.97399,
      lng: -85.35578,
      city: 'Roanoke',
      state: 'IN',
    },
    46784: {
      lat: 41.49797,
      lng: -85.38797,
      city: 'Rome City',
      state: 'IN',
    },
    46785: {
      lat: 41.31974,
      lng: -84.88506,
      city: 'Saint Joe',
      state: 'IN',
    },
    46786: {
      lat: 41.53156,
      lng: -85.27269,
      city: 'South Milford',
      state: 'IN',
    },
    46787: {
      lat: 41.06598,
      lng: -85.62085,
      city: 'South Whitley',
      state: 'IN',
    },
    46788: {
      lat: 41.27005,
      lng: -84.90876,
      city: 'Spencerville',
      state: 'IN',
    },
    46789: {
      lat: 41.58274,
      lng: -85.19742,
      city: 'Stroh',
      state: 'IN',
    },
    46791: {
      lat: 40.83699,
      lng: -85.24325,
      city: 'Uniondale',
      state: 'IN',
    },
    46792: {
      lat: 40.68472,
      lng: -85.43798,
      city: 'Warren',
      state: 'IN',
    },
    46793: {
      lat: 41.46106,
      lng: -85.01928,
      city: 'Waterloo',
      state: 'IN',
    },
    46794: {
      lat: 41.46654,
      lng: -85.45946,
      city: 'Wawaka',
      state: 'IN',
    },
    46795: {
      lat: 41.55116,
      lng: -85.33941,
      city: 'Wolcottville',
      state: 'IN',
    },
    46796: {
      lat: 41.3367,
      lng: -85.49583,
      city: 'Wolflake',
      state: 'IN',
    },
    46797: {
      lat: 41.12749,
      lng: -84.86046,
      city: 'Woodburn',
      state: 'IN',
    },
    46798: {
      lat: 40.94256,
      lng: -85.22113,
      city: 'Yoder',
      state: 'IN',
    },
    46799: {
      lat: 40.91592,
      lng: -85.27923,
      city: 'Zanesville',
      state: 'IN',
    },
    46802: {
      lat: 41.06787,
      lng: -85.16299,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46803: {
      lat: 41.06972,
      lng: -85.08532,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46804: {
      lat: 41.05421,
      lng: -85.23913,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46805: {
      lat: 41.09918,
      lng: -85.11762,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46806: {
      lat: 41.04649,
      lng: -85.09224,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46807: {
      lat: 41.04489,
      lng: -85.14763,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46808: {
      lat: 41.09887,
      lng: -85.17619,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46809: {
      lat: 41.00088,
      lng: -85.2102,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46814: {
      lat: 41.04616,
      lng: -85.30805,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46815: {
      lat: 41.1034,
      lng: -85.05913,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46816: {
      lat: 41.00396,
      lng: -85.04169,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46818: {
      lat: 41.14698,
      lng: -85.25111,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46819: {
      lat: 40.96409,
      lng: -85.13256,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46825: {
      lat: 41.15397,
      lng: -85.12425,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46835: {
      lat: 41.15086,
      lng: -85.04536,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46845: {
      lat: 41.2051,
      lng: -85.10789,
      city: 'Fort Wayne',
      state: 'IN',
    },
    46901: {
      lat: 40.52487,
      lng: -86.15639,
      city: 'Kokomo',
      state: 'IN',
    },
    46902: {
      lat: 40.43743,
      lng: -86.09111,
      city: 'Kokomo',
      state: 'IN',
    },
    46910: {
      lat: 41.04537,
      lng: -86.04151,
      city: 'Akron',
      state: 'IN',
    },
    46911: {
      lat: 40.62859,
      lng: -85.94673,
      city: 'Amboy',
      state: 'IN',
    },
    46913: {
      lat: 40.50364,
      lng: -86.49739,
      city: 'Bringhurst',
      state: 'IN',
    },
    46914: {
      lat: 40.63068,
      lng: -86.09515,
      city: 'Bunker Hill',
      state: 'IN',
    },
    46915: {
      lat: 40.47832,
      lng: -86.38636,
      city: 'Burlington',
      state: 'IN',
    },
    46917: {
      lat: 40.62071,
      lng: -86.46893,
      city: 'Camden',
      state: 'IN',
    },
    46919: {
      lat: 40.58826,
      lng: -85.8706,
      city: 'Converse',
      state: 'IN',
    },
    46920: {
      lat: 40.46459,
      lng: -86.49502,
      city: 'Cutler',
      state: 'IN',
    },
    46922: {
      lat: 41.13993,
      lng: -86.42197,
      city: 'Delong',
      state: 'IN',
    },
    46923: { lat: 40.606, lng: -86.64568, city: 'Delphi', state: 'IN' },
    46926: {
      lat: 40.88283,
      lng: -86.04945,
      city: 'Denver',
      state: 'IN',
    },
    46928: {
      lat: 40.40763,
      lng: -85.68618,
      city: 'Fairmount',
      state: 'IN',
    },
    46929: {
      lat: 40.54802,
      lng: -86.47411,
      city: 'Flora',
      state: 'IN',
    },
    46930: {
      lat: 40.40946,
      lng: -85.57261,
      city: 'Fowlerton',
      state: 'IN',
    },
    46931: {
      lat: 40.94693,
      lng: -86.26456,
      city: 'Fulton',
      state: 'IN',
    },
    46932: {
      lat: 40.59675,
      lng: -86.26243,
      city: 'Galveston',
      state: 'IN',
    },
    46933: {
      lat: 40.47887,
      lng: -85.58091,
      city: 'Gas City',
      state: 'IN',
    },
    46936: {
      lat: 40.47942,
      lng: -85.93102,
      city: 'Greentown',
      state: 'IN',
    },
    46937: {
      lat: 40.41523,
      lng: -86.03693,
      city: 'Hemlock',
      state: 'IN',
    },
    46938: {
      lat: 40.4482,
      lng: -85.64892,
      city: 'Jonesboro',
      state: 'IN',
    },
    46939: {
      lat: 40.99337,
      lng: -86.40359,
      city: 'Kewanna',
      state: 'IN',
    },
    46940: {
      lat: 40.6882,
      lng: -85.69857,
      city: 'La Fontaine',
      state: 'IN',
    },
    46941: {
      lat: 40.82833,
      lng: -85.69723,
      city: 'Lagro',
      state: 'IN',
    },
    46943: {
      lat: 40.9735,
      lng: -85.84038,
      city: 'Laketon',
      state: 'IN',
    },
    46945: {
      lat: 41.1211,
      lng: -86.38516,
      city: 'Leiters Ford',
      state: 'IN',
    },
    46946: {
      lat: 41.03932,
      lng: -85.73019,
      city: 'Liberty Mills',
      state: 'IN',
    },
    46947: {
      lat: 40.76044,
      lng: -86.37303,
      city: 'Logansport',
      state: 'IN',
    },
    46950: {
      lat: 40.88391,
      lng: -86.37162,
      city: 'Lucerne',
      state: 'IN',
    },
    46951: { lat: 40.94616, lng: -86.10528, city: 'Macy', state: 'IN' },
    46952: {
      lat: 40.59882,
      lng: -85.63249,
      city: 'Marion',
      state: 'IN',
    },
    46953: { lat: 40.51928, lng: -85.643, city: 'Marion', state: 'IN' },
    46957: {
      lat: 40.38866,
      lng: -85.49917,
      city: 'Matthews',
      state: 'IN',
    },
    46958: {
      lat: 40.81817,
      lng: -86.12107,
      city: 'Mexico',
      state: 'IN',
    },
    46959: {
      lat: 40.61272,
      lng: -86.11245,
      city: 'Miami',
      state: 'IN',
    },
    46960: {
      lat: 41.15735,
      lng: -86.52397,
      city: 'Monterey',
      state: 'IN',
    },
    46961: {
      lat: 40.76573,
      lng: -86.18786,
      city: 'New Waverly',
      state: 'IN',
    },
    46962: {
      lat: 40.98774,
      lng: -85.76675,
      city: 'North Manchester',
      state: 'IN',
    },
    46965: {
      lat: 40.41601,
      lng: -86.10183,
      city: 'Oakford',
      state: 'IN',
    },
    46967: {
      lat: 40.69504,
      lng: -86.19483,
      city: 'Onward',
      state: 'IN',
    },
    46968: { lat: 41.17049, lng: -86.55179, city: 'Ora', state: 'IN' },
    46970: { lat: 40.75827, lng: -86.0614, city: 'Peru', state: 'IN' },
    46971: {
      lat: 40.6606,
      lng: -86.16168,
      city: 'Grissom Arb',
      state: 'IN',
    },
    46974: {
      lat: 40.94839,
      lng: -85.93041,
      city: 'Roann',
      state: 'IN',
    },
    46975: {
      lat: 41.0718,
      lng: -86.25177,
      city: 'Rochester',
      state: 'IN',
    },
    46978: {
      lat: 40.8587,
      lng: -86.51113,
      city: 'Royal Center',
      state: 'IN',
    },
    46979: {
      lat: 40.42759,
      lng: -86.27737,
      city: 'Russiaville',
      state: 'IN',
    },
    46980: {
      lat: 40.95598,
      lng: -85.74155,
      city: 'Servia',
      state: 'IN',
    },
    46982: {
      lat: 41.05717,
      lng: -85.8882,
      city: 'Silver Lake',
      state: 'IN',
    },
    46984: {
      lat: 40.66777,
      lng: -85.82975,
      city: 'Somerset',
      state: 'IN',
    },
    46985: {
      lat: 40.95485,
      lng: -86.58225,
      city: 'Star City',
      state: 'IN',
    },
    46986: {
      lat: 40.49481,
      lng: -85.81929,
      city: 'Swayzee',
      state: 'IN',
    },
    46987: {
      lat: 40.56847,
      lng: -85.76722,
      city: 'Sweetser',
      state: 'IN',
    },
    46988: {
      lat: 40.8802,
      lng: -86.23451,
      city: 'Twelve Mile',
      state: 'IN',
    },
    46989: {
      lat: 40.45056,
      lng: -85.48115,
      city: 'Upland',
      state: 'IN',
    },
    46990: {
      lat: 40.89851,
      lng: -85.73161,
      city: 'Urbana',
      state: 'IN',
    },
    46991: {
      lat: 40.63034,
      lng: -85.50642,
      city: 'Van Buren',
      state: 'IN',
    },
    46992: {
      lat: 40.78509,
      lng: -85.82961,
      city: 'Wabash',
      state: 'IN',
    },
    46994: {
      lat: 40.66661,
      lng: -86.25766,
      city: 'Walton',
      state: 'IN',
    },
    46996: {
      lat: 41.0626,
      lng: -86.65906,
      city: 'Winamac',
      state: 'IN',
    },
    46998: {
      lat: 40.57536,
      lng: -86.35081,
      city: 'Young America',
      state: 'IN',
    },
    47001: {
      lat: 39.0579,
      lng: -84.95689,
      city: 'Aurora',
      state: 'IN',
    },
    47003: {
      lat: 39.55628,
      lng: -84.84491,
      city: 'West College Corner',
      state: 'IN',
    },
    47006: {
      lat: 39.29141,
      lng: -85.21365,
      city: 'Batesville',
      state: 'IN',
    },
    47010: { lat: 39.4981, lng: -84.83925, city: 'Bath', state: 'IN' },
    47011: {
      lat: 38.8724,
      lng: -85.06887,
      city: 'Bennington',
      state: 'IN',
    },
    47012: {
      lat: 39.41892,
      lng: -84.97944,
      city: 'Brookville',
      state: 'IN',
    },
    47016: {
      lat: 39.37414,
      lng: -84.89484,
      city: 'Cedar Grove',
      state: 'IN',
    },
    47017: {
      lat: 38.93745,
      lng: -85.17713,
      city: 'Cross Plains',
      state: 'IN',
    },
    47018: {
      lat: 38.98684,
      lng: -85.0866,
      city: 'Dillsboro',
      state: 'IN',
    },
    47020: {
      lat: 38.8119,
      lng: -84.93221,
      city: 'Florence',
      state: 'IN',
    },
    47021: {
      lat: 38.97015,
      lng: -85.14855,
      city: 'Friendship',
      state: 'IN',
    },
    47022: {
      lat: 39.20722,
      lng: -84.95089,
      city: 'Guilford',
      state: 'IN',
    },
    47023: {
      lat: 39.07255,
      lng: -85.37467,
      city: 'Holton',
      state: 'IN',
    },
    47024: {
      lat: 39.47611,
      lng: -85.19724,
      city: 'Laurel',
      state: 'IN',
    },
    47025: {
      lat: 39.15415,
      lng: -84.87461,
      city: 'Lawrenceburg',
      state: 'IN',
    },
    47030: {
      lat: 39.42686,
      lng: -85.13319,
      city: 'Metamora',
      state: 'IN',
    },
    47031: {
      lat: 39.12872,
      lng: -85.15333,
      city: 'Milan',
      state: 'IN',
    },
    47032: {
      lat: 39.08109,
      lng: -85.05503,
      city: 'Moores Hill',
      state: 'IN',
    },
    47033: {
      lat: 39.28127,
      lng: -85.17232,
      city: 'Morris',
      state: 'IN',
    },
    47034: {
      lat: 39.20428,
      lng: -85.32739,
      city: 'Napoleon',
      state: 'IN',
    },
    47035: {
      lat: 39.31016,
      lng: -84.90394,
      city: 'New Trenton',
      state: 'IN',
    },
    47036: {
      lat: 39.38563,
      lng: -85.23972,
      city: 'Oldenburg',
      state: 'IN',
    },
    47037: {
      lat: 39.16431,
      lng: -85.31908,
      city: 'Osgood',
      state: 'IN',
    },
    47038: {
      lat: 38.84588,
      lng: -84.84212,
      city: 'Patriot',
      state: 'IN',
    },
    47040: {
      lat: 38.91703,
      lng: -84.92809,
      city: 'Rising Sun',
      state: 'IN',
    },
    47041: {
      lat: 39.23432,
      lng: -85.0797,
      city: 'Sunman',
      state: 'IN',
    },
    47042: {
      lat: 39.01247,
      lng: -85.25484,
      city: 'Versailles',
      state: 'IN',
    },
    47043: { lat: 38.7949, lng: -85.10009, city: 'Vevay', state: 'IN' },
    47060: {
      lat: 39.29828,
      lng: -84.8788,
      city: 'West Harrison',
      state: 'IN',
    },
    47102: {
      lat: 38.75745,
      lng: -85.7951,
      city: 'Austin',
      state: 'IN',
    },
    47104: {
      lat: 38.54568,
      lng: -85.43665,
      city: 'Bethlehem',
      state: 'IN',
    },
    47106: {
      lat: 38.46071,
      lng: -85.90326,
      city: 'Borden',
      state: 'IN',
    },
    47108: {
      lat: 38.6455,
      lng: -86.25725,
      city: 'Campbellsburg',
      state: 'IN',
    },
    47110: {
      lat: 38.09541,
      lng: -86.2077,
      city: 'Central',
      state: 'IN',
    },
    47111: {
      lat: 38.46604,
      lng: -85.63271,
      city: 'Charlestown',
      state: 'IN',
    },
    47112: {
      lat: 38.19235,
      lng: -86.14512,
      city: 'Corydon',
      state: 'IN',
    },
    47114: {
      lat: 38.28754,
      lng: -86.07335,
      city: 'Crandall',
      state: 'IN',
    },
    47115: {
      lat: 38.34465,
      lng: -86.21655,
      city: 'Depauw',
      state: 'IN',
    },
    47116: {
      lat: 38.32521,
      lng: -86.61668,
      city: 'Eckerty',
      state: 'IN',
    },
    47117: {
      lat: 38.10505,
      lng: -85.97275,
      city: 'Elizabeth',
      state: 'IN',
    },
    47118: {
      lat: 38.29788,
      lng: -86.48449,
      city: 'English',
      state: 'IN',
    },
    47119: {
      lat: 38.36699,
      lng: -85.88217,
      city: 'Floyds Knobs',
      state: 'IN',
    },
    47120: {
      lat: 38.45568,
      lng: -86.19075,
      city: 'Fredericksburg',
      state: 'IN',
    },
    47122: {
      lat: 38.3041,
      lng: -85.98303,
      city: 'Georgetown',
      state: 'IN',
    },
    47123: {
      lat: 38.2856,
      lng: -86.47181,
      city: 'Grantsburg',
      state: 'IN',
    },
    47124: {
      lat: 38.36591,
      lng: -86.00523,
      city: 'Greenville',
      state: 'IN',
    },
    47125: {
      lat: 38.46341,
      lng: -86.30825,
      city: 'Hardinsburg',
      state: 'IN',
    },
    47126: {
      lat: 38.54979,
      lng: -85.77844,
      city: 'Henryville',
      state: 'IN',
    },
    47129: {
      lat: 38.31378,
      lng: -85.76874,
      city: 'Clarksville',
      state: 'IN',
    },
    47130: {
      lat: 38.33528,
      lng: -85.69689,
      city: 'Jeffersonville',
      state: 'IN',
    },
    47135: {
      lat: 38.04108,
      lng: -86.0744,
      city: 'Laconia',
      state: 'IN',
    },
    47136: {
      lat: 38.23511,
      lng: -85.96194,
      city: 'Lanesville',
      state: 'IN',
    },
    47137: {
      lat: 38.18899,
      lng: -86.37626,
      city: 'Leavenworth',
      state: 'IN',
    },
    47138: {
      lat: 38.68215,
      lng: -85.59759,
      city: 'Lexington',
      state: 'IN',
    },
    47140: {
      lat: 38.38063,
      lng: -86.36892,
      city: 'Marengo',
      state: 'IN',
    },
    47141: {
      lat: 38.54925,
      lng: -85.60841,
      city: 'Marysville',
      state: 'IN',
    },
    47142: {
      lat: 38.0684,
      lng: -86.21042,
      city: 'Mauckport',
      state: 'IN',
    },
    47143: {
      lat: 38.46877,
      lng: -85.77314,
      city: 'Memphis',
      state: 'IN',
    },
    47145: {
      lat: 38.33454,
      lng: -86.30115,
      city: 'Milltown',
      state: 'IN',
    },
    47147: { lat: 38.5942, lng: -85.53866, city: 'Nabb', state: 'IN' },
    47150: {
      lat: 38.28831,
      lng: -85.84775,
      city: 'New Albany',
      state: 'IN',
    },
    47160: {
      lat: 38.14817,
      lng: -86.04711,
      city: 'New Middletown',
      state: 'IN',
    },
    47161: {
      lat: 38.32281,
      lng: -86.10366,
      city: 'New Salisbury',
      state: 'IN',
    },
    47162: {
      lat: 38.55477,
      lng: -85.48873,
      city: 'New Washington',
      state: 'IN',
    },
    47163: {
      lat: 38.54101,
      lng: -85.67928,
      city: 'Otisco',
      state: 'IN',
    },
    47164: {
      lat: 38.40462,
      lng: -86.09079,
      city: 'Palmyra',
      state: 'IN',
    },
    47165: {
      lat: 38.50152,
      lng: -86.00508,
      city: 'Pekin',
      state: 'IN',
    },
    47166: {
      lat: 38.30668,
      lng: -86.16901,
      city: 'Ramsey',
      state: 'IN',
    },
    47167: { lat: 38.6077, lng: -86.09047, city: 'Salem', state: 'IN' },
    47170: {
      lat: 38.68734,
      lng: -85.84667,
      city: 'Scottsburg',
      state: 'IN',
    },
    47172: {
      lat: 38.39745,
      lng: -85.76886,
      city: 'Sellersburg',
      state: 'IN',
    },
    47174: {
      lat: 38.22716,
      lng: -86.482,
      city: 'Sulphur',
      state: 'IN',
    },
    47175: {
      lat: 38.35902,
      lng: -86.55236,
      city: 'Taswell',
      state: 'IN',
    },
    47177: {
      lat: 38.59947,
      lng: -85.78197,
      city: 'Underwood',
      state: 'IN',
    },
    47201: {
      lat: 39.15074,
      lng: -85.99432,
      city: 'Columbus',
      state: 'IN',
    },
    47203: {
      lat: 39.23189,
      lng: -85.83281,
      city: 'Columbus',
      state: 'IN',
    },
    47220: {
      lat: 38.87786,
      lng: -86.05471,
      city: 'Brownstown',
      state: 'IN',
    },
    47223: {
      lat: 39.0555,
      lng: -85.48218,
      city: 'Butlerville',
      state: 'IN',
    },
    47224: {
      lat: 38.88816,
      lng: -85.22474,
      city: 'Canaan',
      state: 'IN',
    },
    47225: {
      lat: 39.43554,
      lng: -85.34424,
      city: 'Clarksburg',
      state: 'IN',
    },
    47226: {
      lat: 39.28266,
      lng: -85.86907,
      city: 'Clifford',
      state: 'IN',
    },
    47227: {
      lat: 38.87506,
      lng: -85.65678,
      city: 'Commiskey',
      state: 'IN',
    },
    47229: {
      lat: 38.80289,
      lng: -85.86688,
      city: 'Crothersville',
      state: 'IN',
    },
    47230: {
      lat: 38.7946,
      lng: -85.63139,
      city: 'Deputy',
      state: 'IN',
    },
    47231: {
      lat: 38.89792,
      lng: -85.4996,
      city: 'Dupont',
      state: 'IN',
    },
    47232: {
      lat: 39.11297,
      lng: -85.79428,
      city: 'Elizabethtown',
      state: 'IN',
    },
    47234: {
      lat: 39.37611,
      lng: -85.76837,
      city: 'Flat Rock',
      state: 'IN',
    },
    47235: {
      lat: 39.01385,
      lng: -86.14046,
      city: 'Freetown',
      state: 'IN',
    },
    47236: {
      lat: 39.15181,
      lng: -85.72536,
      city: 'Grammer',
      state: 'IN',
    },
    47240: {
      lat: 39.32394,
      lng: -85.48028,
      city: 'Greensburg',
      state: 'IN',
    },
    47243: {
      lat: 38.67214,
      lng: -85.48177,
      city: 'Hanover',
      state: 'IN',
    },
    47244: {
      lat: 39.21802,
      lng: -85.70232,
      city: 'Hartsville',
      state: 'IN',
    },
    47246: { lat: 39.29804, lng: -85.76606, city: 'Hope', state: 'IN' },
    47247: {
      lat: 39.05993,
      lng: -85.88783,
      city: 'Jonesville',
      state: 'IN',
    },
    47250: {
      lat: 38.81867,
      lng: -85.3552,
      city: 'Madison',
      state: 'IN',
    },
    47260: {
      lat: 38.83596,
      lng: -86.19909,
      city: 'Medora',
      state: 'IN',
    },
    47263: {
      lat: 39.30864,
      lng: -85.32832,
      city: 'New Point',
      state: 'IN',
    },
    47264: {
      lat: 38.96434,
      lng: -86.26313,
      city: 'Norman',
      state: 'IN',
    },
    47265: {
      lat: 39.00818,
      lng: -85.62685,
      city: 'North Vernon',
      state: 'IN',
    },
    47270: {
      lat: 38.8397,
      lng: -85.71913,
      city: 'Paris Crossing',
      state: 'IN',
    },
    47272: {
      lat: 39.41519,
      lng: -85.61942,
      city: 'Saint Paul',
      state: 'IN',
    },
    47273: {
      lat: 39.07472,
      lng: -85.7359,
      city: 'Scipio',
      state: 'IN',
    },
    47274: {
      lat: 38.96261,
      lng: -85.92877,
      city: 'Seymour',
      state: 'IN',
    },
    47280: {
      lat: 39.29655,
      lng: -85.95047,
      city: 'Taylorsville',
      state: 'IN',
    },
    47281: {
      lat: 38.78563,
      lng: -86.10324,
      city: 'Vallonia',
      state: 'IN',
    },
    47282: {
      lat: 38.98538,
      lng: -85.6101,
      city: 'Vernon',
      state: 'IN',
    },
    47283: {
      lat: 39.16978,
      lng: -85.58647,
      city: 'Westport',
      state: 'IN',
    },
    47302: {
      lat: 40.12992,
      lng: -85.37553,
      city: 'Muncie',
      state: 'IN',
    },
    47303: {
      lat: 40.27108,
      lng: -85.37648,
      city: 'Muncie',
      state: 'IN',
    },
    47304: {
      lat: 40.23398,
      lng: -85.45973,
      city: 'Muncie',
      state: 'IN',
    },
    47305: {
      lat: 40.19387,
      lng: -85.38556,
      city: 'Muncie',
      state: 'IN',
    },
    47306: {
      lat: 40.20542,
      lng: -85.40726,
      city: 'Muncie',
      state: 'IN',
    },
    47320: { lat: 40.2787, lng: -85.2672, city: 'Albany', state: 'IN' },
    47324: {
      lat: 39.74119,
      lng: -84.85172,
      city: 'Boston',
      state: 'IN',
    },
    47325: {
      lat: 39.69395,
      lng: -85.01834,
      city: 'Brownsville',
      state: 'IN',
    },
    47326: {
      lat: 40.54597,
      lng: -84.97018,
      city: 'Bryant',
      state: 'IN',
    },
    47327: {
      lat: 39.8318,
      lng: -85.17314,
      city: 'Cambridge City',
      state: 'IN',
    },
    47330: {
      lat: 39.78792,
      lng: -85.02226,
      city: 'Centerville',
      state: 'IN',
    },
    47331: {
      lat: 39.63086,
      lng: -85.16313,
      city: 'Connersville',
      state: 'IN',
    },
    47334: {
      lat: 40.11267,
      lng: -85.52357,
      city: 'Daleville',
      state: 'IN',
    },
    47335: {
      lat: 39.81171,
      lng: -85.20675,
      city: 'Dublin',
      state: 'IN',
    },
    47336: {
      lat: 40.39694,
      lng: -85.20821,
      city: 'Dunkirk',
      state: 'IN',
    },
    47337: {
      lat: 39.80286,
      lng: -85.43736,
      city: 'Dunreith',
      state: 'IN',
    },
    47338: {
      lat: 40.34363,
      lng: -85.33977,
      city: 'Eaton',
      state: 'IN',
    },
    47339: {
      lat: 39.96337,
      lng: -85.09471,
      city: 'Economy',
      state: 'IN',
    },
    47340: {
      lat: 40.181,
      lng: -85.1318,
      city: 'Farmland',
      state: 'IN',
    },
    47341: {
      lat: 39.9741,
      lng: -84.89361,
      city: 'Fountain City',
      state: 'IN',
    },
    47342: {
      lat: 40.32184,
      lng: -85.5105,
      city: 'Gaston',
      state: 'IN',
    },
    47344: {
      lat: 39.87857,
      lng: -85.464,
      city: 'Greensboro',
      state: 'IN',
    },
    47345: {
      lat: 39.892,
      lng: -85.04765,
      city: 'Greens Fork',
      state: 'IN',
    },
    47346: {
      lat: 39.93442,
      lng: -85.16306,
      city: 'Hagerstown',
      state: 'IN',
    },
    47348: {
      lat: 40.45725,
      lng: -85.34855,
      city: 'Hartford City',
      state: 'IN',
    },
    47351: {
      lat: 39.90442,
      lng: -85.52001,
      city: 'Kennard',
      state: 'IN',
    },
    47352: {
      lat: 39.79597,
      lng: -85.3637,
      city: 'Lewisville',
      state: 'IN',
    },
    47353: {
      lat: 39.62757,
      lng: -84.92057,
      city: 'Liberty',
      state: 'IN',
    },
    47354: {
      lat: 40.04628,
      lng: -85.19933,
      city: 'Losantville',
      state: 'IN',
    },
    47355: { lat: 40.04376, lng: -84.9258, city: 'Lynn', state: 'IN' },
    47356: {
      lat: 40.03079,
      lng: -85.50415,
      city: 'Middletown',
      state: 'IN',
    },
    47357: {
      lat: 39.75773,
      lng: -85.14795,
      city: 'Milton',
      state: 'IN',
    },
    47358: {
      lat: 40.05488,
      lng: -85.11204,
      city: 'Modoc',
      state: 'IN',
    },
    47359: {
      lat: 40.55203,
      lng: -85.28642,
      city: 'Montpelier',
      state: 'IN',
    },
    47360: {
      lat: 40.01677,
      lng: -85.2614,
      city: 'Mooreland',
      state: 'IN',
    },
    47361: {
      lat: 40.00415,
      lng: -85.38492,
      city: 'Mount Summit',
      state: 'IN',
    },
    47362: {
      lat: 39.93186,
      lng: -85.36998,
      city: 'New Castle',
      state: 'IN',
    },
    47366: {
      lat: 39.86127,
      lng: -85.26068,
      city: 'New Lisbon',
      state: 'IN',
    },
    47367: {
      lat: 40.08366,
      lng: -85.39207,
      city: 'Oakville',
      state: 'IN',
    },
    47368: {
      lat: 40.18082,
      lng: -85.20025,
      city: 'Parker City',
      state: 'IN',
    },
    47369: {
      lat: 40.50714,
      lng: -85.14715,
      city: 'Pennville',
      state: 'IN',
    },
    47370: {
      lat: 39.81281,
      lng: -85.13779,
      city: 'Pershing',
      state: 'IN',
    },
    47371: {
      lat: 40.41874,
      lng: -84.95896,
      city: 'Portland',
      state: 'IN',
    },
    47373: {
      lat: 40.33863,
      lng: -85.15356,
      city: 'Redkey',
      state: 'IN',
    },
    47374: {
      lat: 39.83376,
      lng: -84.88997,
      city: 'Richmond',
      state: 'IN',
    },
    47380: {
      lat: 40.28553,
      lng: -85.03439,
      city: 'Ridgeville',
      state: 'IN',
    },
    47381: {
      lat: 40.38665,
      lng: -84.86465,
      city: 'Salamonia',
      state: 'IN',
    },
    47382: {
      lat: 40.23692,
      lng: -84.91845,
      city: 'Saratoga',
      state: 'IN',
    },
    47383: {
      lat: 40.15123,
      lng: -85.26399,
      city: 'Selma',
      state: 'IN',
    },
    47384: {
      lat: 39.91365,
      lng: -85.55352,
      city: 'Shirley',
      state: 'IN',
    },
    47385: {
      lat: 39.83194,
      lng: -85.44825,
      city: 'Spiceland',
      state: 'IN',
    },
    47386: {
      lat: 40.05277,
      lng: -85.38305,
      city: 'Springport',
      state: 'IN',
    },
    47387: {
      lat: 39.83677,
      lng: -85.2945,
      city: 'Straughn',
      state: 'IN',
    },
    47388: {
      lat: 40.0056,
      lng: -85.44352,
      city: 'Sulphur Springs',
      state: 'IN',
    },
    47390: {
      lat: 40.20625,
      lng: -84.84671,
      city: 'Union City',
      state: 'IN',
    },
    47392: {
      lat: 39.91005,
      lng: -84.94394,
      city: 'Webster',
      state: 'IN',
    },
    47393: {
      lat: 39.96559,
      lng: -85.00325,
      city: 'Williamsburg',
      state: 'IN',
    },
    47394: {
      lat: 40.1679,
      lng: -84.98667,
      city: 'Winchester',
      state: 'IN',
    },
    47396: {
      lat: 40.18661,
      lng: -85.51632,
      city: 'Yorktown',
      state: 'IN',
    },
    47401: {
      lat: 39.08613,
      lng: -86.45138,
      city: 'Bloomington',
      state: 'IN',
    },
    47403: {
      lat: 39.08635,
      lng: -86.61082,
      city: 'Bloomington',
      state: 'IN',
    },
    47404: {
      lat: 39.22064,
      lng: -86.5896,
      city: 'Bloomington',
      state: 'IN',
    },
    47405: {
      lat: 39.16761,
      lng: -86.5211,
      city: 'Bloomington',
      state: 'IN',
    },
    47406: {
      lat: 39.17501,
      lng: -86.51516,
      city: 'Bloomington',
      state: 'IN',
    },
    47408: {
      lat: 39.2283,
      lng: -86.46452,
      city: 'Bloomington',
      state: 'IN',
    },
    47420: {
      lat: 38.91903,
      lng: -86.54933,
      city: 'Avoca',
      state: 'IN',
    },
    47421: {
      lat: 38.86415,
      lng: -86.45122,
      city: 'Bedford',
      state: 'IN',
    },
    47424: {
      lat: 39.01918,
      lng: -86.86998,
      city: 'Bloomfield',
      state: 'IN',
    },
    47427: {
      lat: 39.23915,
      lng: -87.01266,
      city: 'Coal City',
      state: 'IN',
    },
    47429: {
      lat: 39.27581,
      lng: -86.61444,
      city: 'Ellettsville',
      state: 'IN',
    },
    47431: {
      lat: 39.24052,
      lng: -86.90655,
      city: 'Freedom',
      state: 'IN',
    },
    47432: {
      lat: 38.49268,
      lng: -86.63942,
      city: 'French Lick',
      state: 'IN',
    },
    47433: {
      lat: 39.35569,
      lng: -86.655,
      city: 'Gosport',
      state: 'IN',
    },
    47434: {
      lat: 39.01277,
      lng: -86.54569,
      city: 'Harrodsburg',
      state: 'IN',
    },
    47435: {
      lat: 39.26576,
      lng: -86.29516,
      city: 'Helmsburg',
      state: 'IN',
    },
    47436: {
      lat: 38.9598,
      lng: -86.38358,
      city: 'Heltonville',
      state: 'IN',
    },
    47437: { lat: 38.7135, lng: -86.6769, city: 'Huron', state: 'IN' },
    47438: {
      lat: 39.1582,
      lng: -87.18237,
      city: 'Jasonville',
      state: 'IN',
    },
    47441: {
      lat: 39.03976,
      lng: -87.1743,
      city: 'Linton',
      state: 'IN',
    },
    47443: {
      lat: 38.96651,
      lng: -87.09059,
      city: 'Lyons',
      state: 'IN',
    },
    47445: {
      lat: 39.11289,
      lng: -87.18944,
      city: 'Midland',
      state: 'IN',
    },
    47446: {
      lat: 38.73818,
      lng: -86.52031,
      city: 'Mitchell',
      state: 'IN',
    },
    47448: {
      lat: 39.17531,
      lng: -86.24481,
      city: 'Nashville',
      state: 'IN',
    },
    47449: {
      lat: 38.92672,
      lng: -86.9894,
      city: 'Newberry',
      state: 'IN',
    },
    47451: {
      lat: 38.89176,
      lng: -86.52536,
      city: 'Oolitic',
      state: 'IN',
    },
    47452: {
      lat: 38.6422,
      lng: -86.41901,
      city: 'Orleans',
      state: 'IN',
    },
    47453: {
      lat: 38.93472,
      lng: -86.76773,
      city: 'Owensburg',
      state: 'IN',
    },
    47454: {
      lat: 38.51596,
      lng: -86.45989,
      city: 'Paoli',
      state: 'IN',
    },
    47455: {
      lat: 39.31271,
      lng: -86.95672,
      city: 'Patricksburg',
      state: 'IN',
    },
    47456: {
      lat: 39.46314,
      lng: -86.69781,
      city: 'Quincy',
      state: 'IN',
    },
    47457: {
      lat: 38.91183,
      lng: -86.9047,
      city: 'Scotland',
      state: 'IN',
    },
    47458: {
      lat: 39.07044,
      lng: -86.50712,
      city: 'Smithville',
      state: 'IN',
    },
    47459: {
      lat: 39.11167,
      lng: -86.75125,
      city: 'Solsberry',
      state: 'IN',
    },
    47460: {
      lat: 39.28153,
      lng: -86.78951,
      city: 'Spencer',
      state: 'IN',
    },
    47462: {
      lat: 38.95755,
      lng: -86.64815,
      city: 'Springville',
      state: 'IN',
    },
    47464: {
      lat: 39.29995,
      lng: -86.64979,
      city: 'Stinesville',
      state: 'IN',
    },
    47465: {
      lat: 39.05474,
      lng: -87.04338,
      city: 'Switz City',
      state: 'IN',
    },
    47467: {
      lat: 38.76776,
      lng: -86.34463,
      city: 'Tunnelton',
      state: 'IN',
    },
    47468: {
      lat: 39.27801,
      lng: -86.40482,
      city: 'Unionville',
      state: 'IN',
    },
    47469: {
      lat: 38.61892,
      lng: -86.61172,
      city: 'West Baden Springs',
      state: 'IN',
    },
    47470: {
      lat: 38.81623,
      lng: -86.64799,
      city: 'Williams',
      state: 'IN',
    },
    47471: {
      lat: 39.14253,
      lng: -86.99788,
      city: 'Worthington',
      state: 'IN',
    },
    47501: {
      lat: 38.66139,
      lng: -87.17479,
      city: 'Washington',
      state: 'IN',
    },
    47512: {
      lat: 38.779,
      lng: -87.31809,
      city: 'Bicknell',
      state: 'IN',
    },
    47513: {
      lat: 38.30405,
      lng: -86.6995,
      city: 'Birdseye',
      state: 'IN',
    },
    47514: {
      lat: 38.14861,
      lng: -86.59403,
      city: 'Branchville',
      state: 'IN',
    },
    47515: {
      lat: 38.17769,
      lng: -86.70226,
      city: 'Bristow',
      state: 'IN',
    },
    47516: {
      lat: 38.7645,
      lng: -87.41857,
      city: 'Bruceville',
      state: 'IN',
    },
    47519: {
      lat: 38.5976,
      lng: -86.98793,
      city: 'Cannelburg',
      state: 'IN',
    },
    47520: {
      lat: 37.99351,
      lng: -86.5902,
      city: 'Cannelton',
      state: 'IN',
    },
    47521: {
      lat: 38.39238,
      lng: -86.72941,
      city: 'Celestine',
      state: 'IN',
    },
    47522: {
      lat: 38.88545,
      lng: -86.80543,
      city: 'Crane',
      state: 'IN',
    },
    47523: { lat: 38.17354, lng: -87.02934, city: 'Dale', state: 'IN' },
    47524: {
      lat: 38.48312,
      lng: -87.61733,
      city: 'Decker',
      state: 'IN',
    },
    47525: {
      lat: 38.02856,
      lng: -86.55359,
      city: 'Derby',
      state: 'IN',
    },
    47527: {
      lat: 38.47232,
      lng: -86.77772,
      city: 'Dubois',
      state: 'IN',
    },
    47528: {
      lat: 38.84447,
      lng: -87.26806,
      city: 'Edwardsport',
      state: 'IN',
    },
    47529: {
      lat: 38.86386,
      lng: -87.07804,
      city: 'Elnora',
      state: 'IN',
    },
    47531: {
      lat: 38.04161,
      lng: -86.84867,
      city: 'Evanston',
      state: 'IN',
    },
    47532: {
      lat: 38.2198,
      lng: -86.86036,
      city: 'Ferdinand',
      state: 'IN',
    },
    47535: {
      lat: 38.86265,
      lng: -87.31285,
      city: 'Freelandville',
      state: 'IN',
    },
    47537: {
      lat: 38.10971,
      lng: -87.06888,
      city: 'Gentryville',
      state: 'IN',
    },
    47541: {
      lat: 38.23777,
      lng: -87.04673,
      city: 'Holland',
      state: 'IN',
    },
    47542: {
      lat: 38.29928,
      lng: -86.96712,
      city: 'Huntingburg',
      state: 'IN',
    },
    47546: {
      lat: 38.42071,
      lng: -86.93469,
      city: 'Jasper',
      state: 'IN',
    },
    47550: {
      lat: 38.06213,
      lng: -86.92877,
      city: 'Lamar',
      state: 'IN',
    },
    47551: {
      lat: 38.12153,
      lng: -86.56353,
      city: 'Leopold',
      state: 'IN',
    },
    47552: {
      lat: 38.10862,
      lng: -86.99527,
      city: 'Lincoln City',
      state: 'IN',
    },
    47553: {
      lat: 38.65398,
      lng: -86.91108,
      city: 'Loogootee',
      state: 'IN',
    },
    47557: {
      lat: 38.57005,
      lng: -87.34132,
      city: 'Monroe City',
      state: 'IN',
    },
    47558: {
      lat: 38.64506,
      lng: -87.04578,
      city: 'Montgomery',
      state: 'IN',
    },
    47561: {
      lat: 38.85705,
      lng: -87.43611,
      city: 'Oaktown',
      state: 'IN',
    },
    47562: { lat: 38.83493, lng: -86.98268, city: 'Odon', state: 'IN' },
    47564: {
      lat: 38.48166,
      lng: -87.09566,
      city: 'Otwell',
      state: 'IN',
    },
    47567: {
      lat: 38.46954,
      lng: -87.30554,
      city: 'Petersburg',
      state: 'IN',
    },
    47568: {
      lat: 38.79121,
      lng: -87.13654,
      city: 'Plainville',
      state: 'IN',
    },
    47573: {
      lat: 38.74418,
      lng: -87.322,
      city: 'Ragsdale',
      state: 'IN',
    },
    47574: { lat: 37.95265, lng: -86.56323, city: 'Rome', state: 'IN' },
    47575: {
      lat: 38.32314,
      lng: -86.81128,
      city: 'Saint Anthony',
      state: 'IN',
    },
    47576: {
      lat: 38.19089,
      lng: -86.58936,
      city: 'Saint Croix',
      state: 'IN',
    },
    47577: {
      lat: 38.15004,
      lng: -86.80766,
      city: 'Saint Meinrad',
      state: 'IN',
    },
    47578: {
      lat: 38.89288,
      lng: -87.19055,
      city: 'Sandborn',
      state: 'IN',
    },
    47579: {
      lat: 38.11149,
      lng: -86.91322,
      city: 'Santa Claus',
      state: 'IN',
    },
    47580: {
      lat: 38.35355,
      lng: -86.77008,
      city: 'Schnellville',
      state: 'IN',
    },
    47581: {
      lat: 38.65972,
      lng: -86.76908,
      city: 'Shoals',
      state: 'IN',
    },
    47584: {
      lat: 38.25111,
      lng: -87.25797,
      city: 'Spurgeon',
      state: 'IN',
    },
    47585: {
      lat: 38.26787,
      lng: -87.16617,
      city: 'Stendal',
      state: 'IN',
    },
    47586: {
      lat: 38.03684,
      lng: -86.69297,
      city: 'Tell City',
      state: 'IN',
    },
    47588: { lat: 38.06959, lng: -86.77826, city: 'Troy', state: 'IN' },
    47590: {
      lat: 38.35314,
      lng: -87.10113,
      city: 'Velpen',
      state: 'IN',
    },
    47591: {
      lat: 38.62136,
      lng: -87.50594,
      city: 'Vincennes',
      state: 'IN',
    },
    47596: {
      lat: 38.86318,
      lng: -87.23382,
      city: 'Westphalia',
      state: 'IN',
    },
    47597: {
      lat: 38.64745,
      lng: -87.29802,
      city: 'Wheatland',
      state: 'IN',
    },
    47598: {
      lat: 38.3875,
      lng: -87.20711,
      city: 'Winslow',
      state: 'IN',
    },
    47601: {
      lat: 38.05301,
      lng: -87.25526,
      city: 'Boonville',
      state: 'IN',
    },
    47610: {
      lat: 38.06041,
      lng: -87.39988,
      city: 'Chandler',
      state: 'IN',
    },
    47611: {
      lat: 38.02474,
      lng: -87.05812,
      city: 'Chrisney',
      state: 'IN',
    },
    47612: {
      lat: 38.1946,
      lng: -87.70155,
      city: 'Cynthiana',
      state: 'IN',
    },
    47613: {
      lat: 38.15413,
      lng: -87.42062,
      city: 'Elberfeld',
      state: 'IN',
    },
    47615: {
      lat: 37.97214,
      lng: -86.95643,
      city: 'Grandview',
      state: 'IN',
    },
    47616: {
      lat: 38.23482,
      lng: -87.91038,
      city: 'Griffin',
      state: 'IN',
    },
    47619: {
      lat: 38.1924,
      lng: -87.30742,
      city: 'Lynnville',
      state: 'IN',
    },
    47620: {
      lat: 37.93284,
      lng: -87.90555,
      city: 'Mount Vernon',
      state: 'IN',
    },
    47630: {
      lat: 37.95615,
      lng: -87.35569,
      city: 'Newburgh',
      state: 'IN',
    },
    47631: {
      lat: 38.10082,
      lng: -87.90967,
      city: 'New Harmony',
      state: 'IN',
    },
    47633: {
      lat: 38.16835,
      lng: -87.78736,
      city: 'Poseyville',
      state: 'IN',
    },
    47634: {
      lat: 37.93893,
      lng: -87.1848,
      city: 'Richland',
      state: 'IN',
    },
    47635: {
      lat: 37.8862,
      lng: -87.10751,
      city: 'Rockport',
      state: 'IN',
    },
    47637: {
      lat: 38.13679,
      lng: -87.15865,
      city: 'Tennyson',
      state: 'IN',
    },
    47638: {
      lat: 38.06959,
      lng: -87.77489,
      city: 'Wadesville',
      state: 'IN',
    },
    47639: {
      lat: 38.18228,
      lng: -87.57132,
      city: 'Haubstadt',
      state: 'IN',
    },
    47640: {
      lat: 38.47708,
      lng: -87.49301,
      city: 'Hazleton',
      state: 'IN',
    },
    47648: {
      lat: 38.24611,
      lng: -87.54851,
      city: 'Fort Branch',
      state: 'IN',
    },
    47649: {
      lat: 38.35154,
      lng: -87.44786,
      city: 'Francisco',
      state: 'IN',
    },
    47654: { lat: 38.2534, lng: -87.391, city: 'Mackey', state: 'IN' },
    47660: {
      lat: 38.29712,
      lng: -87.33191,
      city: 'Oakland City',
      state: 'IN',
    },
    47665: {
      lat: 38.28016,
      lng: -87.72738,
      city: 'Owensville',
      state: 'IN',
    },
    47666: {
      lat: 38.41922,
      lng: -87.60042,
      city: 'Patoka',
      state: 'IN',
    },
    47670: {
      lat: 38.34355,
      lng: -87.57797,
      city: 'Princeton',
      state: 'IN',
    },
    47683: {
      lat: 38.27696,
      lng: -87.37744,
      city: 'Somerville',
      state: 'IN',
    },
    47708: {
      lat: 37.97387,
      lng: -87.57357,
      city: 'Evansville',
      state: 'IN',
    },
    47710: {
      lat: 38.02518,
      lng: -87.57618,
      city: 'Evansville',
      state: 'IN',
    },
    47711: {
      lat: 38.0157,
      lng: -87.53606,
      city: 'Evansville',
      state: 'IN',
    },
    47712: {
      lat: 37.92869,
      lng: -87.65951,
      city: 'Evansville',
      state: 'IN',
    },
    47713: {
      lat: 37.9475,
      lng: -87.55432,
      city: 'Evansville',
      state: 'IN',
    },
    47714: {
      lat: 37.95768,
      lng: -87.52102,
      city: 'Evansville',
      state: 'IN',
    },
    47715: {
      lat: 37.97229,
      lng: -87.48097,
      city: 'Evansville',
      state: 'IN',
    },
    47720: {
      lat: 38.06065,
      lng: -87.64247,
      city: 'Evansville',
      state: 'IN',
    },
    47725: {
      lat: 38.10721,
      lng: -87.52855,
      city: 'Evansville',
      state: 'IN',
    },
    47802: {
      lat: 39.35153,
      lng: -87.4106,
      city: 'Terre Haute',
      state: 'IN',
    },
    47803: {
      lat: 39.46433,
      lng: -87.30855,
      city: 'Terre Haute',
      state: 'IN',
    },
    47804: {
      lat: 39.49756,
      lng: -87.39031,
      city: 'Terre Haute',
      state: 'IN',
    },
    47805: {
      lat: 39.54768,
      lng: -87.32739,
      city: 'Terre Haute',
      state: 'IN',
    },
    47807: {
      lat: 39.47061,
      lng: -87.40262,
      city: 'Terre Haute',
      state: 'IN',
    },
    47809: {
      lat: 39.47136,
      lng: -87.40986,
      city: 'Terre Haute',
      state: 'IN',
    },
    47831: {
      lat: 39.66549,
      lng: -87.52177,
      city: 'Blanford',
      state: 'IN',
    },
    47832: {
      lat: 39.87879,
      lng: -87.25463,
      city: 'Bloomingdale',
      state: 'IN',
    },
    47833: {
      lat: 39.35841,
      lng: -86.97773,
      city: 'Bowling Green',
      state: 'IN',
    },
    47834: {
      lat: 39.52423,
      lng: -87.12276,
      city: 'Brazil',
      state: 'IN',
    },
    47836: {
      lat: 39.64924,
      lng: -87.17221,
      city: 'Bridgeton',
      state: 'IN',
    },
    47837: {
      lat: 39.62929,
      lng: -87.11281,
      city: 'Carbon',
      state: 'IN',
    },
    47838: {
      lat: 38.95679,
      lng: -87.37812,
      city: 'Carlisle',
      state: 'IN',
    },
    47840: {
      lat: 39.40863,
      lng: -87.05937,
      city: 'Centerpoint',
      state: 'IN',
    },
    47841: {
      lat: 39.27764,
      lng: -87.11492,
      city: 'Clay City',
      state: 'IN',
    },
    47842: {
      lat: 39.67569,
      lng: -87.462,
      city: 'Clinton',
      state: 'IN',
    },
    47845: {
      lat: 39.1936,
      lng: -87.23386,
      city: 'Coalmont',
      state: 'IN',
    },
    47846: { lat: 39.36601, lng: -87.20041, city: 'Cory', state: 'IN' },
    47847: { lat: 39.82293, lng: -87.48865, city: 'Dana', state: 'IN' },
    47848: {
      lat: 39.04159,
      lng: -87.26108,
      city: 'Dugger',
      state: 'IN',
    },
    47849: {
      lat: 39.19196,
      lng: -87.56131,
      city: 'Fairbanks',
      state: 'IN',
    },
    47850: {
      lat: 39.24441,
      lng: -87.43763,
      city: 'Farmersburg',
      state: 'IN',
    },
    47851: {
      lat: 39.5736,
      lng: -87.24594,
      city: 'Fontanet',
      state: 'IN',
    },
    47853: {
      lat: 39.53757,
      lng: -87.07277,
      city: 'Harmony',
      state: 'IN',
    },
    47854: {
      lat: 39.80874,
      lng: -87.41299,
      city: 'Hillsdale',
      state: 'IN',
    },
    47855: {
      lat: 39.18844,
      lng: -87.30216,
      city: 'Hymera',
      state: 'IN',
    },
    47857: {
      lat: 39.52403,
      lng: -87.09111,
      city: 'Knightsville',
      state: 'IN',
    },
    47858: {
      lat: 39.26268,
      lng: -87.22868,
      city: 'Lewis',
      state: 'IN',
    },
    47859: {
      lat: 39.88595,
      lng: -87.16999,
      city: 'Marshall',
      state: 'IN',
    },
    47860: {
      lat: 39.72215,
      lng: -87.33013,
      city: 'Mecca',
      state: 'IN',
    },
    47861: {
      lat: 39.06528,
      lng: -87.56658,
      city: 'Merom',
      state: 'IN',
    },
    47862: {
      lat: 39.77184,
      lng: -87.34527,
      city: 'Montezuma',
      state: 'IN',
    },
    47863: {
      lat: 39.57848,
      lng: -87.45921,
      city: 'New Goshen',
      state: 'IN',
    },
    47865: {
      lat: 39.01908,
      lng: -87.39259,
      city: 'Paxton',
      state: 'IN',
    },
    47866: {
      lat: 39.28302,
      lng: -87.3247,
      city: 'Pimento',
      state: 'IN',
    },
    47868: {
      lat: 39.40892,
      lng: -86.89822,
      city: 'Poland',
      state: 'IN',
    },
    47869: {
      lat: 39.2751,
      lng: -87.49658,
      city: 'Prairie Creek',
      state: 'IN',
    },
    47870: {
      lat: 39.37016,
      lng: -87.47353,
      city: 'Prairieton',
      state: 'IN',
    },
    47871: {
      lat: 39.38978,
      lng: -87.30026,
      city: 'Riley',
      state: 'IN',
    },
    47872: {
      lat: 39.75331,
      lng: -87.16271,
      city: 'Rockville',
      state: 'IN',
    },
    47874: {
      lat: 39.62913,
      lng: -87.27792,
      city: 'Rosedale',
      state: 'IN',
    },
    47875: {
      lat: 39.71447,
      lng: -87.52222,
      city: 'Saint Bernice',
      state: 'IN',
    },
    47876: {
      lat: 39.50996,
      lng: -87.46113,
      city: 'Saint Mary Of The Woods',
      state: 'IN',
    },
    47879: {
      lat: 39.18863,
      lng: -87.37619,
      city: 'Shelburn',
      state: 'IN',
    },
    47880: {
      lat: 39.59976,
      lng: -87.41885,
      city: 'Shepardsville',
      state: 'IN',
    },
    47881: {
      lat: 39.48722,
      lng: -87.19029,
      city: 'Staunton',
      state: 'IN',
    },
    47882: {
      lat: 39.09034,
      lng: -87.41186,
      city: 'Sullivan',
      state: 'IN',
    },
    47884: {
      lat: 39.62308,
      lng: -87.45362,
      city: 'Universal',
      state: 'IN',
    },
    47885: {
      lat: 39.50872,
      lng: -87.47679,
      city: 'West Terre Haute',
      state: 'IN',
    },
    47901: {
      lat: 40.4178,
      lng: -86.88995,
      city: 'Lafayette',
      state: 'IN',
    },
    47904: {
      lat: 40.43835,
      lng: -86.87609,
      city: 'Lafayette',
      state: 'IN',
    },
    47905: {
      lat: 40.4218,
      lng: -86.76418,
      city: 'Lafayette',
      state: 'IN',
    },
    47906: {
      lat: 40.48541,
      lng: -86.98584,
      city: 'West Lafayette',
      state: 'IN',
    },
    47907: {
      lat: 40.42474,
      lng: -86.91665,
      city: 'West Lafayette',
      state: 'IN',
    },
    47909: {
      lat: 40.32357,
      lng: -86.89933,
      city: 'Lafayette',
      state: 'IN',
    },
    47916: {
      lat: 39.98343,
      lng: -87.05524,
      city: 'Alamo',
      state: 'IN',
    },
    47917: {
      lat: 40.46881,
      lng: -87.47633,
      city: 'Ambia',
      state: 'IN',
    },
    47918: {
      lat: 40.30388,
      lng: -87.20628,
      city: 'Attica',
      state: 'IN',
    },
    47920: {
      lat: 40.54712,
      lng: -86.8195,
      city: 'Battle Ground',
      state: 'IN',
    },
    47921: {
      lat: 40.47838,
      lng: -87.37485,
      city: 'Boswell',
      state: 'IN',
    },
    47922: { lat: 40.8762, lng: -87.35369, city: 'Brook', state: 'IN' },
    47923: {
      lat: 40.61015,
      lng: -86.92667,
      city: 'Brookston',
      state: 'IN',
    },
    47924: {
      lat: 40.48991,
      lng: -86.75759,
      city: 'Buck Creek',
      state: 'IN',
    },
    47925: {
      lat: 40.88205,
      lng: -86.74477,
      city: 'Buffalo',
      state: 'IN',
    },
    47926: {
      lat: 40.77789,
      lng: -86.59707,
      city: 'Burnettsville',
      state: 'IN',
    },
    47928: {
      lat: 39.93237,
      lng: -87.46501,
      city: 'Cayuga',
      state: 'IN',
    },
    47929: {
      lat: 40.67587,
      lng: -86.9185,
      city: 'Chalmers',
      state: 'IN',
    },
    47930: {
      lat: 40.23764,
      lng: -86.74137,
      city: 'Clarks Hill',
      state: 'IN',
    },
    47932: {
      lat: 40.12764,
      lng: -87.40761,
      city: 'Covington',
      state: 'IN',
    },
    47933: {
      lat: 40.03728,
      lng: -86.90234,
      city: 'Crawfordsville',
      state: 'IN',
    },
    47940: {
      lat: 40.11749,
      lng: -86.7447,
      city: 'Darlington',
      state: 'IN',
    },
    47941: {
      lat: 40.37539,
      lng: -86.77128,
      city: 'Dayton',
      state: 'IN',
    },
    47942: {
      lat: 40.68791,
      lng: -87.4472,
      city: 'Earl Park',
      state: 'IN',
    },
    47943: {
      lat: 41.0594,
      lng: -87.28006,
      city: 'Fair Oaks',
      state: 'IN',
    },
    47944: {
      lat: 40.61067,
      lng: -87.32804,
      city: 'Fowler',
      state: 'IN',
    },
    47946: {
      lat: 40.98427,
      lng: -86.87014,
      city: 'Francesville',
      state: 'IN',
    },
    47948: {
      lat: 40.77638,
      lng: -87.28676,
      city: 'Goodland',
      state: 'IN',
    },
    47949: {
      lat: 40.06288,
      lng: -87.13504,
      city: 'Hillsboro',
      state: 'IN',
    },
    47950: {
      lat: 40.80175,
      lng: -86.65355,
      city: 'Idaville',
      state: 'IN',
    },
    47951: {
      lat: 40.79049,
      lng: -87.4435,
      city: 'Kentland',
      state: 'IN',
    },
    47952: {
      lat: 39.96186,
      lng: -87.30049,
      city: 'Kingman',
      state: 'IN',
    },
    47954: {
      lat: 39.90048,
      lng: -86.8178,
      city: 'Ladoga',
      state: 'IN',
    },
    47955: {
      lat: 40.1954,
      lng: -86.86513,
      city: 'Linden',
      state: 'IN',
    },
    47957: {
      lat: 41.08937,
      lng: -86.8772,
      city: 'Medaryville',
      state: 'IN',
    },
    47958: {
      lat: 40.16452,
      lng: -87.14862,
      city: 'Mellott',
      state: 'IN',
    },
    47959: {
      lat: 40.86603,
      lng: -86.89786,
      city: 'Monon',
      state: 'IN',
    },
    47960: {
      lat: 40.78261,
      lng: -86.74938,
      city: 'Monticello',
      state: 'IN',
    },
    47963: {
      lat: 40.96811,
      lng: -87.44371,
      city: 'Morocco',
      state: 'IN',
    },
    47964: {
      lat: 40.9523,
      lng: -87.2996,
      city: 'Mount Ayr',
      state: 'IN',
    },
    47965: {
      lat: 39.95452,
      lng: -86.91996,
      city: 'New Market',
      state: 'IN',
    },
    47966: {
      lat: 39.88843,
      lng: -87.41433,
      city: 'Newport',
      state: 'IN',
    },
    47967: {
      lat: 40.1942,
      lng: -86.99899,
      city: 'New Richmond',
      state: 'IN',
    },
    47968: {
      lat: 39.96209,
      lng: -86.74542,
      city: 'New Ross',
      state: 'IN',
    },
    47969: {
      lat: 40.20355,
      lng: -87.14892,
      city: 'Newtown',
      state: 'IN',
    },
    47970: {
      lat: 40.47423,
      lng: -87.13031,
      city: 'Otterbein',
      state: 'IN',
    },
    47971: {
      lat: 40.52946,
      lng: -87.23751,
      city: 'Oxford',
      state: 'IN',
    },
    47974: {
      lat: 40.04297,
      lng: -87.47356,
      city: 'Perrysville',
      state: 'IN',
    },
    47975: {
      lat: 40.44436,
      lng: -87.25348,
      city: 'Pine Village',
      state: 'IN',
    },
    47977: {
      lat: 40.76132,
      lng: -87.15786,
      city: 'Remington',
      state: 'IN',
    },
    47978: {
      lat: 40.97991,
      lng: -87.1163,
      city: 'Rensselaer',
      state: 'IN',
    },
    47980: {
      lat: 40.75393,
      lng: -86.90131,
      city: 'Reynolds',
      state: 'IN',
    },
    47981: {
      lat: 40.24599,
      lng: -86.93128,
      city: 'Romney',
      state: 'IN',
    },
    47982: {
      lat: 40.19728,
      lng: -87.5268,
      city: 'State Line',
      state: 'IN',
    },
    47983: {
      lat: 40.28633,
      lng: -86.77345,
      city: 'Stockwell',
      state: 'IN',
    },
    47986: {
      lat: 40.51384,
      lng: -87.20719,
      city: 'Templeton',
      state: 'IN',
    },
    47987: {
      lat: 40.11916,
      lng: -87.23428,
      city: 'Veedersburg',
      state: 'IN',
    },
    47989: {
      lat: 39.88871,
      lng: -87.05243,
      city: 'Waveland',
      state: 'IN',
    },
    47990: {
      lat: 40.06118,
      lng: -87.05952,
      city: 'Waynetown',
      state: 'IN',
    },
    47991: {
      lat: 40.28034,
      lng: -87.44794,
      city: 'West Lebanon',
      state: 'IN',
    },
    47992: {
      lat: 40.31933,
      lng: -87.0519,
      city: 'Westpoint',
      state: 'IN',
    },
    47993: {
      lat: 40.31161,
      lng: -87.41603,
      city: 'Williamsport',
      state: 'IN',
    },
    47994: {
      lat: 40.18233,
      lng: -87.08231,
      city: 'Wingate',
      state: 'IN',
    },
    47995: {
      lat: 40.75861,
      lng: -87.0329,
      city: 'Wolcott',
      state: 'IN',
    },
    47997: {
      lat: 40.66769,
      lng: -86.72379,
      city: 'Yeoman',
      state: 'IN',
    },
    48001: {
      lat: 42.63732,
      lng: -82.57949,
      city: 'Algonac',
      state: 'MI',
    },
    48002: {
      lat: 42.93916,
      lng: -82.92601,
      city: 'Allenton',
      state: 'MI',
    },
    48003: {
      lat: 42.93228,
      lng: -83.04088,
      city: 'Almont',
      state: 'MI',
    },
    48005: {
      lat: 42.85022,
      lng: -82.92317,
      city: 'Armada',
      state: 'MI',
    },
    48006: { lat: 43.0754, lng: -82.69642, city: 'Avoca', state: 'MI' },
    48009: {
      lat: 42.54381,
      lng: -83.21765,
      city: 'Birmingham',
      state: 'MI',
    },
    48014: {
      lat: 43.02466,
      lng: -82.93115,
      city: 'Capac',
      state: 'MI',
    },
    48015: {
      lat: 42.48055,
      lng: -83.02743,
      city: 'Center Line',
      state: 'MI',
    },
    48017: {
      lat: 42.53669,
      lng: -83.15039,
      city: 'Clawson',
      state: 'MI',
    },
    48021: {
      lat: 42.46567,
      lng: -82.94598,
      city: 'Eastpointe',
      state: 'MI',
    },
    48022: {
      lat: 43.02824,
      lng: -82.81111,
      city: 'Emmett',
      state: 'MI',
    },
    48023: {
      lat: 42.70102,
      lng: -82.6593,
      city: 'Fair Haven',
      state: 'MI',
    },
    48025: {
      lat: 42.52032,
      lng: -83.26744,
      city: 'Franklin',
      state: 'MI',
    },
    48026: {
      lat: 42.53879,
      lng: -82.94964,
      city: 'Fraser',
      state: 'MI',
    },
    48027: {
      lat: 42.94466,
      lng: -82.68217,
      city: 'Goodells',
      state: 'MI',
    },
    48028: {
      lat: 42.58947,
      lng: -82.61948,
      city: 'Harsens Island',
      state: 'MI',
    },
    48030: {
      lat: 42.46193,
      lng: -83.09769,
      city: 'Hazel Park',
      state: 'MI',
    },
    48032: {
      lat: 43.13278,
      lng: -82.59457,
      city: 'Jeddo',
      state: 'MI',
    },
    48033: {
      lat: 42.46478,
      lng: -83.28812,
      city: 'Southfield',
      state: 'MI',
    },
    48034: {
      lat: 42.49689,
      lng: -83.29102,
      city: 'Southfield',
      state: 'MI',
    },
    48035: {
      lat: 42.55615,
      lng: -82.90948,
      city: 'Clinton Township',
      state: 'MI',
    },
    48036: {
      lat: 42.59227,
      lng: -82.89971,
      city: 'Clinton Township',
      state: 'MI',
    },
    48038: {
      lat: 42.6054,
      lng: -82.94152,
      city: 'Clinton Township',
      state: 'MI',
    },
    48039: {
      lat: 42.69664,
      lng: -82.55052,
      city: 'Marine City',
      state: 'MI',
    },
    48040: {
      lat: 42.90983,
      lng: -82.48022,
      city: 'Marysville',
      state: 'MI',
    },
    48041: {
      lat: 42.93861,
      lng: -82.80271,
      city: 'Memphis',
      state: 'MI',
    },
    48042: {
      lat: 42.68673,
      lng: -82.90857,
      city: 'Macomb',
      state: 'MI',
    },
    48043: {
      lat: 42.59763,
      lng: -82.88208,
      city: 'Mount Clemens',
      state: 'MI',
    },
    48044: {
      lat: 42.64988,
      lng: -82.9296,
      city: 'Macomb',
      state: 'MI',
    },
    48045: {
      lat: 42.59121,
      lng: -82.83017,
      city: 'Harrison Township',
      state: 'MI',
    },
    48047: {
      lat: 42.67754,
      lng: -82.77744,
      city: 'New Baltimore',
      state: 'MI',
    },
    48048: {
      lat: 42.7417,
      lng: -82.79558,
      city: 'New Haven',
      state: 'MI',
    },
    48049: {
      lat: 43.0361,
      lng: -82.57504,
      city: 'North Street',
      state: 'MI',
    },
    48050: {
      lat: 42.78486,
      lng: -82.80139,
      city: 'New Haven',
      state: 'MI',
    },
    48051: {
      lat: 42.69067,
      lng: -82.82582,
      city: 'New Baltimore',
      state: 'MI',
    },
    48054: {
      lat: 42.77054,
      lng: -82.54515,
      city: 'East China',
      state: 'MI',
    },
    48059: {
      lat: 43.08253,
      lng: -82.49913,
      city: 'Fort Gratiot',
      state: 'MI',
    },
    48060: {
      lat: 42.97835,
      lng: -82.46297,
      city: 'Port Huron',
      state: 'MI',
    },
    48062: {
      lat: 42.84899,
      lng: -82.79989,
      city: 'Richmond',
      state: 'MI',
    },
    48063: {
      lat: 42.85581,
      lng: -82.67676,
      city: 'Columbus',
      state: 'MI',
    },
    48064: {
      lat: 42.76719,
      lng: -82.67233,
      city: 'Casco',
      state: 'MI',
    },
    48065: {
      lat: 42.84554,
      lng: -83.04072,
      city: 'Romeo',
      state: 'MI',
    },
    48066: {
      lat: 42.50735,
      lng: -82.93697,
      city: 'Roseville',
      state: 'MI',
    },
    48067: {
      lat: 42.49053,
      lng: -83.13786,
      city: 'Royal Oak',
      state: 'MI',
    },
    48069: {
      lat: 42.47151,
      lng: -83.14447,
      city: 'Pleasant Ridge',
      state: 'MI',
    },
    48070: {
      lat: 42.48161,
      lng: -83.16852,
      city: 'Huntington Woods',
      state: 'MI',
    },
    48071: {
      lat: 42.50726,
      lng: -83.10337,
      city: 'Madison Heights',
      state: 'MI',
    },
    48072: {
      lat: 42.49789,
      lng: -83.18586,
      city: 'Berkley',
      state: 'MI',
    },
    48073: {
      lat: 42.51919,
      lng: -83.16437,
      city: 'Royal Oak',
      state: 'MI',
    },
    48074: {
      lat: 42.9484,
      lng: -82.56196,
      city: 'Smiths Creek',
      state: 'MI',
    },
    48075: {
      lat: 42.4618,
      lng: -83.2299,
      city: 'Southfield',
      state: 'MI',
    },
    48076: {
      lat: 42.49754,
      lng: -83.23067,
      city: 'Southfield',
      state: 'MI',
    },
    48079: {
      lat: 42.8576,
      lng: -82.54721,
      city: 'Saint Clair',
      state: 'MI',
    },
    48080: {
      lat: 42.46418,
      lng: -82.90031,
      city: 'Saint Clair Shores',
      state: 'MI',
    },
    48081: {
      lat: 42.4955,
      lng: -82.89912,
      city: 'Saint Clair Shores',
      state: 'MI',
    },
    48082: {
      lat: 42.5271,
      lng: -82.88711,
      city: 'Saint Clair Shores',
      state: 'MI',
    },
    48083: { lat: 42.557, lng: -83.11676, city: 'Troy', state: 'MI' },
    48084: { lat: 42.56065, lng: -83.17559, city: 'Troy', state: 'MI' },
    48085: { lat: 42.6006, lng: -83.11982, city: 'Troy', state: 'MI' },
    48088: {
      lat: 42.51573,
      lng: -82.98298,
      city: 'Warren',
      state: 'MI',
    },
    48089: {
      lat: 42.4702,
      lng: -82.99549,
      city: 'Warren',
      state: 'MI',
    },
    48091: {
      lat: 42.4681,
      lng: -83.05819,
      city: 'Warren',
      state: 'MI',
    },
    48092: {
      lat: 42.51308,
      lng: -83.05968,
      city: 'Warren',
      state: 'MI',
    },
    48093: {
      lat: 42.51483,
      lng: -83.01554,
      city: 'Warren',
      state: 'MI',
    },
    48094: {
      lat: 42.73645,
      lng: -83.03718,
      city: 'Washington',
      state: 'MI',
    },
    48095: {
      lat: 42.77977,
      lng: -83.03837,
      city: 'Washington',
      state: 'MI',
    },
    48096: { lat: 42.76132, lng: -82.9199, city: 'Ray', state: 'MI' },
    48097: { lat: 43.12639, lng: -82.82955, city: 'Yale', state: 'MI' },
    48098: { lat: 42.59903, lng: -83.1789, city: 'Troy', state: 'MI' },
    48101: {
      lat: 42.25976,
      lng: -83.21067,
      city: 'Allen Park',
      state: 'MI',
    },
    48103: {
      lat: 42.2581,
      lng: -83.84333,
      city: 'Ann Arbor',
      state: 'MI',
    },
    48104: {
      lat: 42.26343,
      lng: -83.71748,
      city: 'Ann Arbor',
      state: 'MI',
    },
    48105: {
      lat: 42.33117,
      lng: -83.70587,
      city: 'Ann Arbor',
      state: 'MI',
    },
    48108: {
      lat: 42.22206,
      lng: -83.7317,
      city: 'Ann Arbor',
      state: 'MI',
    },
    48109: {
      lat: 42.28408,
      lng: -83.72531,
      city: 'Ann Arbor',
      state: 'MI',
    },
    48111: {
      lat: 42.17976,
      lng: -83.48762,
      city: 'Belleville',
      state: 'MI',
    },
    48114: {
      lat: 42.56784,
      lng: -83.75537,
      city: 'Brighton',
      state: 'MI',
    },
    48116: {
      lat: 42.50428,
      lng: -83.78069,
      city: 'Brighton',
      state: 'MI',
    },
    48117: {
      lat: 42.04984,
      lng: -83.40997,
      city: 'Carleton',
      state: 'MI',
    },
    48118: {
      lat: 42.31151,
      lng: -84.03835,
      city: 'Chelsea',
      state: 'MI',
    },
    48120: {
      lat: 42.30524,
      lng: -83.1718,
      city: 'Dearborn',
      state: 'MI',
    },
    48122: {
      lat: 42.28018,
      lng: -83.17819,
      city: 'Melvindale',
      state: 'MI',
    },
    48124: {
      lat: 42.29828,
      lng: -83.24796,
      city: 'Dearborn',
      state: 'MI',
    },
    48125: {
      lat: 42.27793,
      lng: -83.26536,
      city: 'Dearborn Heights',
      state: 'MI',
    },
    48126: {
      lat: 42.32999,
      lng: -83.18678,
      city: 'Dearborn',
      state: 'MI',
    },
    48127: {
      lat: 42.33663,
      lng: -83.28292,
      city: 'Dearborn Heights',
      state: 'MI',
    },
    48128: {
      lat: 42.32054,
      lng: -83.25952,
      city: 'Dearborn',
      state: 'MI',
    },
    48130: {
      lat: 42.3547,
      lng: -83.90272,
      city: 'Dexter',
      state: 'MI',
    },
    48131: {
      lat: 41.96553,
      lng: -83.67268,
      city: 'Dundee',
      state: 'MI',
    },
    48133: { lat: 41.78428, lng: -83.48816, city: 'Erie', state: 'MI' },
    48134: {
      lat: 42.10813,
      lng: -83.29556,
      city: 'Flat Rock',
      state: 'MI',
    },
    48135: {
      lat: 42.32438,
      lng: -83.34122,
      city: 'Garden City',
      state: 'MI',
    },
    48137: {
      lat: 42.46191,
      lng: -84.07367,
      city: 'Gregory',
      state: 'MI',
    },
    48138: {
      lat: 42.13156,
      lng: -83.1586,
      city: 'Grosse Ile',
      state: 'MI',
    },
    48139: {
      lat: 42.45298,
      lng: -83.80796,
      city: 'Hamburg',
      state: 'MI',
    },
    48140: { lat: 41.88141, lng: -83.58274, city: 'Ida', state: 'MI' },
    48141: {
      lat: 42.29349,
      lng: -83.31483,
      city: 'Inkster',
      state: 'MI',
    },
    48143: {
      lat: 42.45877,
      lng: -83.84701,
      city: 'Lakeland',
      state: 'MI',
    },
    48144: {
      lat: 41.75325,
      lng: -83.62803,
      city: 'Lambertville',
      state: 'MI',
    },
    48145: {
      lat: 41.85122,
      lng: -83.46331,
      city: 'La Salle',
      state: 'MI',
    },
    48146: {
      lat: 42.24322,
      lng: -83.18114,
      city: 'Lincoln Park',
      state: 'MI',
    },
    48150: {
      lat: 42.36859,
      lng: -83.37261,
      city: 'Livonia',
      state: 'MI',
    },
    48152: {
      lat: 42.42597,
      lng: -83.37433,
      city: 'Livonia',
      state: 'MI',
    },
    48154: {
      lat: 42.39737,
      lng: -83.37315,
      city: 'Livonia',
      state: 'MI',
    },
    48157: {
      lat: 41.81495,
      lng: -83.43776,
      city: 'Luna Pier',
      state: 'MI',
    },
    48158: {
      lat: 42.15564,
      lng: -84.03031,
      city: 'Manchester',
      state: 'MI',
    },
    48159: {
      lat: 42.02562,
      lng: -83.55598,
      city: 'Maybee',
      state: 'MI',
    },
    48160: {
      lat: 42.08256,
      lng: -83.67978,
      city: 'Milan',
      state: 'MI',
    },
    48161: {
      lat: 41.90818,
      lng: -83.47263,
      city: 'Monroe',
      state: 'MI',
    },
    48162: {
      lat: 41.96045,
      lng: -83.43503,
      city: 'Monroe',
      state: 'MI',
    },
    48164: {
      lat: 42.1264,
      lng: -83.39391,
      city: 'New Boston',
      state: 'MI',
    },
    48165: {
      lat: 42.49968,
      lng: -83.61623,
      city: 'New Hudson',
      state: 'MI',
    },
    48166: {
      lat: 41.98677,
      lng: -83.29375,
      city: 'Newport',
      state: 'MI',
    },
    48167: {
      lat: 42.43455,
      lng: -83.5313,
      city: 'Northville',
      state: 'MI',
    },
    48168: {
      lat: 42.40559,
      lng: -83.54051,
      city: 'Northville',
      state: 'MI',
    },
    48169: {
      lat: 42.46348,
      lng: -83.94955,
      city: 'Pinckney',
      state: 'MI',
    },
    48170: {
      lat: 42.36758,
      lng: -83.53271,
      city: 'Plymouth',
      state: 'MI',
    },
    48173: {
      lat: 42.07638,
      lng: -83.21521,
      city: 'Rockwood',
      state: 'MI',
    },
    48174: {
      lat: 42.20862,
      lng: -83.35413,
      city: 'Romulus',
      state: 'MI',
    },
    48176: {
      lat: 42.15067,
      lng: -83.81477,
      city: 'Saline',
      state: 'MI',
    },
    48177: {
      lat: 41.8048,
      lng: -83.58157,
      city: 'Samaria',
      state: 'MI',
    },
    48178: {
      lat: 42.44463,
      lng: -83.66399,
      city: 'South Lyon',
      state: 'MI',
    },
    48179: {
      lat: 42.03882,
      lng: -83.26061,
      city: 'South Rockwood',
      state: 'MI',
    },
    48180: {
      lat: 42.22605,
      lng: -83.26881,
      city: 'Taylor',
      state: 'MI',
    },
    48182: {
      lat: 41.7919,
      lng: -83.57831,
      city: 'Temperance',
      state: 'MI',
    },
    48183: {
      lat: 42.1361,
      lng: -83.22045,
      city: 'Trenton',
      state: 'MI',
    },
    48184: {
      lat: 42.27403,
      lng: -83.39494,
      city: 'Wayne',
      state: 'MI',
    },
    48185: {
      lat: 42.33579,
      lng: -83.38458,
      city: 'Westland',
      state: 'MI',
    },
    48186: {
      lat: 42.29481,
      lng: -83.37461,
      city: 'Westland',
      state: 'MI',
    },
    48187: {
      lat: 42.3292,
      lng: -83.48784,
      city: 'Canton',
      state: 'MI',
    },
    48188: {
      lat: 42.28579,
      lng: -83.48638,
      city: 'Canton',
      state: 'MI',
    },
    48189: {
      lat: 42.41137,
      lng: -83.78306,
      city: 'Whitmore Lake',
      state: 'MI',
    },
    48190: {
      lat: 42.13162,
      lng: -83.60327,
      city: 'Whittaker',
      state: 'MI',
    },
    48191: {
      lat: 42.12022,
      lng: -83.56492,
      city: 'Willis',
      state: 'MI',
    },
    48192: {
      lat: 42.21156,
      lng: -83.15654,
      city: 'Wyandotte',
      state: 'MI',
    },
    48193: {
      lat: 42.17341,
      lng: -83.21042,
      city: 'Riverview',
      state: 'MI',
    },
    48195: {
      lat: 42.20472,
      lng: -83.20568,
      city: 'Southgate',
      state: 'MI',
    },
    48197: {
      lat: 42.1973,
      lng: -83.62903,
      city: 'Ypsilanti',
      state: 'MI',
    },
    48198: {
      lat: 42.27519,
      lng: -83.58822,
      city: 'Ypsilanti',
      state: 'MI',
    },
    48201: {
      lat: 42.34706,
      lng: -83.06009,
      city: 'Detroit',
      state: 'MI',
    },
    48202: {
      lat: 42.37463,
      lng: -83.07764,
      city: 'Detroit',
      state: 'MI',
    },
    48203: {
      lat: 42.42176,
      lng: -83.1031,
      city: 'Highland Park',
      state: 'MI',
    },
    48204: {
      lat: 42.36582,
      lng: -83.14286,
      city: 'Detroit',
      state: 'MI',
    },
    48205: {
      lat: 42.43317,
      lng: -82.98131,
      city: 'Detroit',
      state: 'MI',
    },
    48206: {
      lat: 42.3751,
      lng: -83.10782,
      city: 'Detroit',
      state: 'MI',
    },
    48207: {
      lat: 42.34753,
      lng: -83.01104,
      city: 'Detroit',
      state: 'MI',
    },
    48208: {
      lat: 42.34844,
      lng: -83.09173,
      city: 'Detroit',
      state: 'MI',
    },
    48209: {
      lat: 42.30524,
      lng: -83.11649,
      city: 'Detroit',
      state: 'MI',
    },
    48210: {
      lat: 42.33644,
      lng: -83.12875,
      city: 'Detroit',
      state: 'MI',
    },
    48211: {
      lat: 42.38175,
      lng: -83.04616,
      city: 'Detroit',
      state: 'MI',
    },
    48212: {
      lat: 42.41003,
      lng: -83.05601,
      city: 'Hamtramck',
      state: 'MI',
    },
    48213: {
      lat: 42.39787,
      lng: -82.9955,
      city: 'Detroit',
      state: 'MI',
    },
    48214: {
      lat: 42.36504,
      lng: -82.98697,
      city: 'Detroit',
      state: 'MI',
    },
    48215: {
      lat: 42.37523,
      lng: -82.95428,
      city: 'Detroit',
      state: 'MI',
    },
    48216: {
      lat: 42.32637,
      lng: -83.07927,
      city: 'Detroit',
      state: 'MI',
    },
    48217: {
      lat: 42.27803,
      lng: -83.15409,
      city: 'Detroit',
      state: 'MI',
    },
    48218: {
      lat: 42.27049,
      lng: -83.13076,
      city: 'River Rouge',
      state: 'MI',
    },
    48219: {
      lat: 42.42573,
      lng: -83.25282,
      city: 'Detroit',
      state: 'MI',
    },
    48220: {
      lat: 42.45805,
      lng: -83.13519,
      city: 'Ferndale',
      state: 'MI',
    },
    48221: {
      lat: 42.4272,
      lng: -83.14949,
      city: 'Detroit',
      state: 'MI',
    },
    48223: {
      lat: 42.39341,
      lng: -83.24619,
      city: 'Detroit',
      state: 'MI',
    },
    48224: {
      lat: 42.41074,
      lng: -82.94147,
      city: 'Detroit',
      state: 'MI',
    },
    48225: {
      lat: 42.43893,
      lng: -82.92965,
      city: 'Harper Woods',
      state: 'MI',
    },
    48226: {
      lat: 42.33182,
      lng: -83.05034,
      city: 'Detroit',
      state: 'MI',
    },
    48227: {
      lat: 42.38732,
      lng: -83.1926,
      city: 'Detroit',
      state: 'MI',
    },
    48228: {
      lat: 42.35562,
      lng: -83.21791,
      city: 'Detroit',
      state: 'MI',
    },
    48229: {
      lat: 42.24889,
      lng: -83.1399,
      city: 'Ecorse',
      state: 'MI',
    },
    48230: {
      lat: 42.38353,
      lng: -82.92304,
      city: 'Grosse Pointe',
      state: 'MI',
    },
    48233: {
      lat: 42.32324,
      lng: -83.06303,
      city: 'Detroit',
      state: 'MI',
    },
    48234: {
      lat: 42.4312,
      lng: -83.03952,
      city: 'Detroit',
      state: 'MI',
    },
    48235: {
      lat: 42.42702,
      lng: -83.1947,
      city: 'Detroit',
      state: 'MI',
    },
    48236: {
      lat: 42.42504,
      lng: -82.89687,
      city: 'Grosse Pointe',
      state: 'MI',
    },
    48237: {
      lat: 42.46494,
      lng: -83.18241,
      city: 'Oak Park',
      state: 'MI',
    },
    48238: {
      lat: 42.39626,
      lng: -83.14136,
      city: 'Detroit',
      state: 'MI',
    },
    48239: {
      lat: 42.37638,
      lng: -83.28574,
      city: 'Redford',
      state: 'MI',
    },
    48240: {
      lat: 42.42447,
      lng: -83.30128,
      city: 'Redford',
      state: 'MI',
    },
    48242: {
      lat: 42.21258,
      lng: -83.35203,
      city: 'Detroit',
      state: 'MI',
    },
    48243: {
      lat: 42.32994,
      lng: -83.03955,
      city: 'Detroit',
      state: 'MI',
    },
    48301: {
      lat: 42.54441,
      lng: -83.28284,
      city: 'Bloomfield Hills',
      state: 'MI',
    },
    48302: {
      lat: 42.5849,
      lng: -83.29608,
      city: 'Bloomfield Hills',
      state: 'MI',
    },
    48304: {
      lat: 42.58745,
      lng: -83.23442,
      city: 'Bloomfield Hills',
      state: 'MI',
    },
    48306: {
      lat: 42.72243,
      lng: -83.14944,
      city: 'Rochester',
      state: 'MI',
    },
    48307: {
      lat: 42.65953,
      lng: -83.12301,
      city: 'Rochester',
      state: 'MI',
    },
    48309: {
      lat: 42.65866,
      lng: -83.18348,
      city: 'Rochester',
      state: 'MI',
    },
    48310: {
      lat: 42.56511,
      lng: -83.06694,
      city: 'Sterling Heights',
      state: 'MI',
    },
    48312: {
      lat: 42.55831,
      lng: -83.00914,
      city: 'Sterling Heights',
      state: 'MI',
    },
    48313: {
      lat: 42.59985,
      lng: -82.99984,
      city: 'Sterling Heights',
      state: 'MI',
    },
    48314: {
      lat: 42.60978,
      lng: -83.05426,
      city: 'Sterling Heights',
      state: 'MI',
    },
    48315: { lat: 42.67254, lng: -82.9963, city: 'Utica', state: 'MI' },
    48316: {
      lat: 42.69059,
      lng: -83.05671,
      city: 'Utica',
      state: 'MI',
    },
    48317: {
      lat: 42.64673,
      lng: -83.05334,
      city: 'Utica',
      state: 'MI',
    },
    48320: {
      lat: 42.61274,
      lng: -83.33824,
      city: 'Keego Harbor',
      state: 'MI',
    },
    48322: {
      lat: 42.54214,
      lng: -83.38139,
      city: 'West Bloomfield',
      state: 'MI',
    },
    48323: {
      lat: 42.57036,
      lng: -83.37651,
      city: 'West Bloomfield',
      state: 'MI',
    },
    48324: {
      lat: 42.59702,
      lng: -83.39151,
      city: 'West Bloomfield',
      state: 'MI',
    },
    48326: {
      lat: 42.67536,
      lng: -83.2531,
      city: 'Auburn Hills',
      state: 'MI',
    },
    48327: {
      lat: 42.6443,
      lng: -83.41417,
      city: 'Waterford',
      state: 'MI',
    },
    48328: {
      lat: 42.64597,
      lng: -83.35603,
      city: 'Waterford',
      state: 'MI',
    },
    48329: {
      lat: 42.68826,
      lng: -83.38854,
      city: 'Waterford',
      state: 'MI',
    },
    48331: {
      lat: 42.50389,
      lng: -83.40863,
      city: 'Farmington',
      state: 'MI',
    },
    48334: {
      lat: 42.50662,
      lng: -83.34964,
      city: 'Farmington',
      state: 'MI',
    },
    48335: {
      lat: 42.46229,
      lng: -83.40271,
      city: 'Farmington',
      state: 'MI',
    },
    48336: {
      lat: 42.46333,
      lng: -83.34803,
      city: 'Farmington',
      state: 'MI',
    },
    48340: {
      lat: 42.67065,
      lng: -83.29019,
      city: 'Pontiac',
      state: 'MI',
    },
    48341: {
      lat: 42.6275,
      lng: -83.29433,
      city: 'Pontiac',
      state: 'MI',
    },
    48342: {
      lat: 42.64389,
      lng: -83.27591,
      city: 'Pontiac',
      state: 'MI',
    },
    48346: {
      lat: 42.72156,
      lng: -83.42388,
      city: 'Clarkston',
      state: 'MI',
    },
    48348: {
      lat: 42.76677,
      lng: -83.40088,
      city: 'Clarkston',
      state: 'MI',
    },
    48350: {
      lat: 42.74082,
      lng: -83.53374,
      city: 'Davisburg',
      state: 'MI',
    },
    48353: {
      lat: 42.64736,
      lng: -83.71627,
      city: 'Hartland',
      state: 'MI',
    },
    48356: {
      lat: 42.65532,
      lng: -83.58904,
      city: 'Highland',
      state: 'MI',
    },
    48357: {
      lat: 42.65358,
      lng: -83.6432,
      city: 'Highland',
      state: 'MI',
    },
    48359: {
      lat: 42.7218,
      lng: -83.27801,
      city: 'Lake Orion',
      state: 'MI',
    },
    48360: {
      lat: 42.74903,
      lng: -83.26789,
      city: 'Lake Orion',
      state: 'MI',
    },
    48362: {
      lat: 42.782,
      lng: -83.2696,
      city: 'Lake Orion',
      state: 'MI',
    },
    48363: {
      lat: 42.77209,
      lng: -83.16114,
      city: 'Oakland',
      state: 'MI',
    },
    48367: {
      lat: 42.84282,
      lng: -83.14143,
      city: 'Leonard',
      state: 'MI',
    },
    48370: {
      lat: 42.83797,
      lng: -83.19981,
      city: 'Oxford',
      state: 'MI',
    },
    48371: {
      lat: 42.84544,
      lng: -83.28785,
      city: 'Oxford',
      state: 'MI',
    },
    48374: { lat: 42.47083, lng: -83.52384, city: 'Novi', state: 'MI' },
    48375: { lat: 42.46537, lng: -83.46385, city: 'Novi', state: 'MI' },
    48377: { lat: 42.50646, lng: -83.4727, city: 'Novi', state: 'MI' },
    48380: {
      lat: 42.57902,
      lng: -83.66602,
      city: 'Milford',
      state: 'MI',
    },
    48381: {
      lat: 42.5628,
      lng: -83.59336,
      city: 'Milford',
      state: 'MI',
    },
    48382: {
      lat: 42.59006,
      lng: -83.507,
      city: 'Commerce Township',
      state: 'MI',
    },
    48383: {
      lat: 42.65533,
      lng: -83.53485,
      city: 'White Lake',
      state: 'MI',
    },
    48386: {
      lat: 42.65826,
      lng: -83.47549,
      city: 'White Lake',
      state: 'MI',
    },
    48390: {
      lat: 42.55197,
      lng: -83.4752,
      city: 'Walled Lake',
      state: 'MI',
    },
    48393: {
      lat: 42.52123,
      lng: -83.54532,
      city: 'Wixom',
      state: 'MI',
    },
    48401: {
      lat: 43.3526,
      lng: -82.66061,
      city: 'Applegate',
      state: 'MI',
    },
    48410: {
      lat: 43.56629,
      lng: -82.9362,
      city: 'Argyle',
      state: 'MI',
    },
    48411: {
      lat: 42.93577,
      lng: -83.53202,
      city: 'Atlas',
      state: 'MI',
    },
    48412: {
      lat: 43.06484,
      lng: -83.16932,
      city: 'Attica',
      state: 'MI',
    },
    48413: {
      lat: 43.80231,
      lng: -82.99663,
      city: 'Bad Axe',
      state: 'MI',
    },
    48414: {
      lat: 42.86391,
      lng: -84.08098,
      city: 'Bancroft',
      state: 'MI',
    },
    48415: {
      lat: 43.27165,
      lng: -83.80111,
      city: 'Birch Run',
      state: 'MI',
    },
    48416: {
      lat: 43.21791,
      lng: -82.98476,
      city: 'Brown City',
      state: 'MI',
    },
    48417: { lat: 43.25998, lng: -83.93686, city: 'Burt', state: 'MI' },
    48418: {
      lat: 42.80427,
      lng: -83.97066,
      city: 'Byron',
      state: 'MI',
    },
    48419: {
      lat: 43.42884,
      lng: -82.65762,
      city: 'Carsonville',
      state: 'MI',
    },
    48420: { lat: 43.17968, lng: -83.71176, city: 'Clio', state: 'MI' },
    48421: {
      lat: 43.15321,
      lng: -83.39262,
      city: 'Columbiaville',
      state: 'MI',
    },
    48422: {
      lat: 43.26265,
      lng: -82.65069,
      city: 'Croswell',
      state: 'MI',
    },
    48423: {
      lat: 43.03982,
      lng: -83.51404,
      city: 'Davison',
      state: 'MI',
    },
    48426: {
      lat: 43.50856,
      lng: -83.06025,
      city: 'Decker',
      state: 'MI',
    },
    48427: {
      lat: 43.53789,
      lng: -82.72378,
      city: 'Deckerville',
      state: 'MI',
    },
    48428: {
      lat: 42.93752,
      lng: -83.14756,
      city: 'Dryden',
      state: 'MI',
    },
    48429: {
      lat: 42.90774,
      lng: -84.00544,
      city: 'Durand',
      state: 'MI',
    },
    48430: {
      lat: 42.75752,
      lng: -83.74208,
      city: 'Fenton',
      state: 'MI',
    },
    48432: {
      lat: 43.90013,
      lng: -82.97923,
      city: 'Filion',
      state: 'MI',
    },
    48433: {
      lat: 43.07632,
      lng: -83.8661,
      city: 'Flushing',
      state: 'MI',
    },
    48434: {
      lat: 43.65988,
      lng: -82.61263,
      city: 'Forestville',
      state: 'MI',
    },
    48435: {
      lat: 43.24863,
      lng: -83.3598,
      city: 'Fostoria',
      state: 'MI',
    },
    48436: {
      lat: 42.87017,
      lng: -83.87704,
      city: 'Gaines',
      state: 'MI',
    },
    48437: {
      lat: 43.11248,
      lng: -83.61893,
      city: 'Genesee',
      state: 'MI',
    },
    48438: {
      lat: 42.91142,
      lng: -83.47819,
      city: 'Goodrich',
      state: 'MI',
    },
    48439: {
      lat: 42.91733,
      lng: -83.63018,
      city: 'Grand Blanc',
      state: 'MI',
    },
    48440: {
      lat: 42.95567,
      lng: -83.40785,
      city: 'Hadley',
      state: 'MI',
    },
    48441: {
      lat: 43.80515,
      lng: -82.71727,
      city: 'Harbor Beach',
      state: 'MI',
    },
    48442: {
      lat: 42.79316,
      lng: -83.60224,
      city: 'Holly',
      state: 'MI',
    },
    48444: {
      lat: 43.06455,
      lng: -83.05471,
      city: 'Imlay City',
      state: 'MI',
    },
    48445: {
      lat: 43.95243,
      lng: -82.98769,
      city: 'Kinde',
      state: 'MI',
    },
    48446: {
      lat: 43.05259,
      lng: -83.33745,
      city: 'Lapeer',
      state: 'MI',
    },
    48449: {
      lat: 42.99518,
      lng: -83.94552,
      city: 'Lennon',
      state: 'MI',
    },
    48450: {
      lat: 43.25289,
      lng: -82.54123,
      city: 'Lexington',
      state: 'MI',
    },
    48451: {
      lat: 42.80365,
      lng: -83.81683,
      city: 'Linden',
      state: 'MI',
    },
    48453: {
      lat: 43.34374,
      lng: -83.03493,
      city: 'Marlette',
      state: 'MI',
    },
    48454: {
      lat: 43.19999,
      lng: -82.81833,
      city: 'Melvin',
      state: 'MI',
    },
    48455: {
      lat: 42.94028,
      lng: -83.29131,
      city: 'Metamora',
      state: 'MI',
    },
    48456: {
      lat: 43.67102,
      lng: -82.75818,
      city: 'Minden City',
      state: 'MI',
    },
    48457: {
      lat: 43.19018,
      lng: -83.89306,
      city: 'Montrose',
      state: 'MI',
    },
    48458: {
      lat: 43.1209,
      lng: -83.68308,
      city: 'Mount Morris',
      state: 'MI',
    },
    48460: {
      lat: 43.12734,
      lng: -83.98586,
      city: 'New Lothrop',
      state: 'MI',
    },
    48461: {
      lat: 43.2024,
      lng: -83.20669,
      city: 'North Branch',
      state: 'MI',
    },
    48462: {
      lat: 42.84733,
      lng: -83.42576,
      city: 'Ortonville',
      state: 'MI',
    },
    48463: {
      lat: 43.16418,
      lng: -83.52202,
      city: 'Otisville',
      state: 'MI',
    },
    48464: {
      lat: 43.21562,
      lng: -83.41884,
      city: 'Otter Lake',
      state: 'MI',
    },
    48465: {
      lat: 43.61485,
      lng: -82.71099,
      city: 'Palms',
      state: 'MI',
    },
    48466: { lat: 43.27693, lng: -82.81731, city: 'Peck', state: 'MI' },
    48467: {
      lat: 44.00768,
      lng: -82.98763,
      city: 'Port Austin',
      state: 'MI',
    },
    48468: {
      lat: 43.94113,
      lng: -82.79021,
      city: 'Port Hope',
      state: 'MI',
    },
    48469: {
      lat: 43.43927,
      lng: -82.55849,
      city: 'Port Sanilac',
      state: 'MI',
    },
    48470: { lat: 43.73253, lng: -82.76158, city: 'Ruth', state: 'MI' },
    48471: {
      lat: 43.41868,
      lng: -82.85369,
      city: 'Sandusky',
      state: 'MI',
    },
    48472: {
      lat: 43.51211,
      lng: -82.95732,
      city: 'Snover',
      state: 'MI',
    },
    48473: {
      lat: 42.94012,
      lng: -83.8274,
      city: 'Swartz Creek',
      state: 'MI',
    },
    48475: { lat: 43.67141, lng: -82.93732, city: 'Ubly', state: 'MI' },
    48476: {
      lat: 42.93765,
      lng: -84.03319,
      city: 'Vernon',
      state: 'MI',
    },
    48502: {
      lat: 43.01429,
      lng: -83.68866,
      city: 'Flint',
      state: 'MI',
    },
    48503: {
      lat: 43.01139,
      lng: -83.68901,
      city: 'Flint',
      state: 'MI',
    },
    48504: {
      lat: 43.05632,
      lng: -83.74948,
      city: 'Flint',
      state: 'MI',
    },
    48505: {
      lat: 43.06948,
      lng: -83.69079,
      city: 'Flint',
      state: 'MI',
    },
    48506: {
      lat: 43.06775,
      lng: -83.62253,
      city: 'Flint',
      state: 'MI',
    },
    48507: {
      lat: 42.96498,
      lng: -83.71687,
      city: 'Flint',
      state: 'MI',
    },
    48509: {
      lat: 43.02635,
      lng: -83.60527,
      city: 'Burton',
      state: 'MI',
    },
    48519: {
      lat: 42.98772,
      lng: -83.60701,
      city: 'Burton',
      state: 'MI',
    },
    48529: {
      lat: 42.97237,
      lng: -83.66221,
      city: 'Burton',
      state: 'MI',
    },
    48532: {
      lat: 43.01203,
      lng: -83.79429,
      city: 'Flint',
      state: 'MI',
    },
    48601: {
      lat: 43.40096,
      lng: -83.8952,
      city: 'Saginaw',
      state: 'MI',
    },
    48602: {
      lat: 43.41971,
      lng: -83.97469,
      city: 'Saginaw',
      state: 'MI',
    },
    48603: {
      lat: 43.46332,
      lng: -84.03115,
      city: 'Saginaw',
      state: 'MI',
    },
    48604: {
      lat: 43.49749,
      lng: -83.95887,
      city: 'Saginaw',
      state: 'MI',
    },
    48607: {
      lat: 43.43136,
      lng: -83.93409,
      city: 'Saginaw',
      state: 'MI',
    },
    48609: {
      lat: 43.3878,
      lng: -84.08786,
      city: 'Saginaw',
      state: 'MI',
    },
    48610: { lat: 44.12789, lng: -84.1801, city: 'Alger', state: 'MI' },
    48611: {
      lat: 43.63187,
      lng: -84.0936,
      city: 'Auburn',
      state: 'MI',
    },
    48612: {
      lat: 43.88001,
      lng: -84.43637,
      city: 'Beaverton',
      state: 'MI',
    },
    48613: {
      lat: 43.94355,
      lng: -84.15791,
      city: 'Bentley',
      state: 'MI',
    },
    48614: {
      lat: 43.25084,
      lng: -84.30147,
      city: 'Brant',
      state: 'MI',
    },
    48615: {
      lat: 43.42671,
      lng: -84.47326,
      city: 'Breckenridge',
      state: 'MI',
    },
    48616: {
      lat: 43.19336,
      lng: -84.11311,
      city: 'Chesaning',
      state: 'MI',
    },
    48617: {
      lat: 43.84695,
      lng: -84.71717,
      city: 'Clare',
      state: 'MI',
    },
    48618: {
      lat: 43.73602,
      lng: -84.5584,
      city: 'Coleman',
      state: 'MI',
    },
    48619: {
      lat: 44.82224,
      lng: -84.01996,
      city: 'Comins',
      state: 'MI',
    },
    48620: {
      lat: 43.80865,
      lng: -84.37805,
      city: 'Edenville',
      state: 'MI',
    },
    48621: {
      lat: 44.72064,
      lng: -83.99408,
      city: 'Fairview',
      state: 'MI',
    },
    48622: {
      lat: 43.83304,
      lng: -84.884,
      city: 'Farwell',
      state: 'MI',
    },
    48623: {
      lat: 43.5198,
      lng: -84.13523,
      city: 'Freeland',
      state: 'MI',
    },
    48624: {
      lat: 44.05919,
      lng: -84.45859,
      city: 'Gladwin',
      state: 'MI',
    },
    48625: {
      lat: 44.04976,
      lng: -84.8345,
      city: 'Harrison',
      state: 'MI',
    },
    48626: {
      lat: 43.4218,
      lng: -84.22686,
      city: 'Hemlock',
      state: 'MI',
    },
    48627: {
      lat: 44.46177,
      lng: -84.74869,
      city: 'Higgins Lake',
      state: 'MI',
    },
    48628: { lat: 43.78166, lng: -84.32856, city: 'Hope', state: 'MI' },
    48629: {
      lat: 44.3107,
      lng: -84.77977,
      city: 'Houghton Lake',
      state: 'MI',
    },
    48630: {
      lat: 44.32532,
      lng: -84.773,
      city: 'Houghton Lake Heights',
      state: 'MI',
    },
    48631: {
      lat: 43.6849,
      lng: -84.00155,
      city: 'Kawkawlin',
      state: 'MI',
    },
    48632: { lat: 43.85597, lng: -85.01954, city: 'Lake', state: 'MI' },
    48633: {
      lat: 43.94827,
      lng: -84.93409,
      city: 'Lake George',
      state: 'MI',
    },
    48634: {
      lat: 43.75405,
      lng: -84.05414,
      city: 'Linwood',
      state: 'MI',
    },
    48635: {
      lat: 44.39575,
      lng: -83.99699,
      city: 'Lupton',
      state: 'MI',
    },
    48636: {
      lat: 44.58871,
      lng: -84.28569,
      city: 'Luzerne',
      state: 'MI',
    },
    48637: {
      lat: 43.41499,
      lng: -84.33646,
      city: 'Merrill',
      state: 'MI',
    },
    48638: {
      lat: 43.41681,
      lng: -84.01958,
      city: 'Saginaw',
      state: 'MI',
    },
    48640: {
      lat: 43.58394,
      lng: -84.33648,
      city: 'Midland',
      state: 'MI',
    },
    48642: {
      lat: 43.69945,
      lng: -84.22849,
      city: 'Midland',
      state: 'MI',
    },
    48647: { lat: 44.65309, lng: -84.08148, city: 'Mio', state: 'MI' },
    48649: {
      lat: 43.15695,
      lng: -84.21709,
      city: 'Oakley',
      state: 'MI',
    },
    48650: {
      lat: 43.85181,
      lng: -84.01746,
      city: 'Pinconning',
      state: 'MI',
    },
    48651: {
      lat: 44.25057,
      lng: -84.61687,
      city: 'Prudenville',
      state: 'MI',
    },
    48652: {
      lat: 43.84834,
      lng: -84.20716,
      city: 'Rhodes',
      state: 'MI',
    },
    48653: {
      lat: 44.48936,
      lng: -84.60624,
      city: 'Roscommon',
      state: 'MI',
    },
    48654: {
      lat: 44.45468,
      lng: -84.12627,
      city: 'Rose City',
      state: 'MI',
    },
    48655: {
      lat: 43.29171,
      lng: -84.15743,
      city: 'Saint Charles',
      state: 'MI',
    },
    48656: {
      lat: 44.34953,
      lng: -84.45801,
      city: 'Saint Helen',
      state: 'MI',
    },
    48657: {
      lat: 43.70945,
      lng: -84.4213,
      city: 'Sanford',
      state: 'MI',
    },
    48658: {
      lat: 43.98038,
      lng: -83.94072,
      city: 'Standish',
      state: 'MI',
    },
    48659: {
      lat: 44.06792,
      lng: -84.05154,
      city: 'Sterling',
      state: 'MI',
    },
    48661: {
      lat: 44.33005,
      lng: -84.24087,
      city: 'West Branch',
      state: 'MI',
    },
    48662: {
      lat: 43.40217,
      lng: -84.40961,
      city: 'Wheeler',
      state: 'MI',
    },
    48701: { lat: 43.5879, lng: -83.54649, city: 'Akron', state: 'MI' },
    48703: {
      lat: 44.06766,
      lng: -83.66904,
      city: 'Au Gres',
      state: 'MI',
    },
    48705: {
      lat: 44.68715,
      lng: -83.65279,
      city: 'Barton City',
      state: 'MI',
    },
    48706: {
      lat: 43.59937,
      lng: -83.95623,
      city: 'Bay City',
      state: 'MI',
    },
    48708: {
      lat: 43.55653,
      lng: -83.84853,
      city: 'Bay City',
      state: 'MI',
    },
    48710: {
      lat: 43.55649,
      lng: -83.98781,
      city: 'University Center',
      state: 'MI',
    },
    48720: {
      lat: 43.8294,
      lng: -83.34808,
      city: 'Bay Port',
      state: 'MI',
    },
    48721: {
      lat: 44.80453,
      lng: -83.3459,
      city: 'Black River',
      state: 'MI',
    },
    48722: {
      lat: 43.34554,
      lng: -83.83961,
      city: 'Bridgeport',
      state: 'MI',
    },
    48723: { lat: 43.4861, lng: -83.38499, city: 'Caro', state: 'MI' },
    48724: {
      lat: 43.4569,
      lng: -83.92709,
      city: 'Carrollton',
      state: 'MI',
    },
    48725: {
      lat: 43.93763,
      lng: -83.20596,
      city: 'Caseville',
      state: 'MI',
    },
    48726: {
      lat: 43.60776,
      lng: -83.16833,
      city: 'Cass City',
      state: 'MI',
    },
    48727: {
      lat: 43.30695,
      lng: -83.17935,
      city: 'Clifford',
      state: 'MI',
    },
    48728: {
      lat: 44.74659,
      lng: -83.82344,
      city: 'Curran',
      state: 'MI',
    },
    48729: {
      lat: 43.49621,
      lng: -83.17828,
      city: 'Deford',
      state: 'MI',
    },
    48730: {
      lat: 44.35443,
      lng: -83.48804,
      city: 'East Tawas',
      state: 'MI',
    },
    48731: {
      lat: 43.83898,
      lng: -83.15784,
      city: 'Elkton',
      state: 'MI',
    },
    48732: {
      lat: 43.60666,
      lng: -83.78274,
      city: 'Essexville',
      state: 'MI',
    },
    48733: {
      lat: 43.53306,
      lng: -83.59029,
      city: 'Fairgrove',
      state: 'MI',
    },
    48734: {
      lat: 43.35519,
      lng: -83.74321,
      city: 'Frankenmuth',
      state: 'MI',
    },
    48735: {
      lat: 43.66861,
      lng: -83.27294,
      city: 'Gagetown',
      state: 'MI',
    },
    48737: {
      lat: 44.54588,
      lng: -83.70871,
      city: 'Glennie',
      state: 'MI',
    },
    48738: {
      lat: 44.54995,
      lng: -83.33786,
      city: 'Greenbush',
      state: 'MI',
    },
    48739: { lat: 44.37597, lng: -83.83238, city: 'Hale', state: 'MI' },
    48740: {
      lat: 44.66014,
      lng: -83.37873,
      city: 'Harrisville',
      state: 'MI',
    },
    48741: {
      lat: 43.40487,
      lng: -83.18121,
      city: 'Kingston',
      state: 'MI',
    },
    48742: {
      lat: 44.71934,
      lng: -83.44542,
      city: 'Lincoln',
      state: 'MI',
    },
    48743: {
      lat: 44.44025,
      lng: -83.87066,
      city: 'Long Lake',
      state: 'MI',
    },
    48744: {
      lat: 43.34797,
      lng: -83.36051,
      city: 'Mayville',
      state: 'MI',
    },
    48745: {
      lat: 44.57059,
      lng: -83.47425,
      city: 'Mikado',
      state: 'MI',
    },
    48746: {
      lat: 43.26397,
      lng: -83.54155,
      city: 'Millington',
      state: 'MI',
    },
    48747: {
      lat: 43.52119,
      lng: -83.76085,
      city: 'Munger',
      state: 'MI',
    },
    48748: {
      lat: 44.30398,
      lng: -83.67392,
      city: 'National City',
      state: 'MI',
    },
    48749: { lat: 44.05512, lng: -83.89183, city: 'Omer', state: 'MI' },
    48750: {
      lat: 44.44338,
      lng: -83.45652,
      city: 'Oscoda',
      state: 'MI',
    },
    48754: {
      lat: 43.734,
      lng: -83.23774,
      city: 'Owendale',
      state: 'MI',
    },
    48755: {
      lat: 43.83571,
      lng: -83.26832,
      city: 'Pigeon',
      state: 'MI',
    },
    48756: {
      lat: 44.23142,
      lng: -83.97409,
      city: 'Prescott',
      state: 'MI',
    },
    48757: { lat: 43.46748, lng: -83.679, city: 'Reese', state: 'MI' },
    48759: {
      lat: 43.7265,
      lng: -83.39323,
      city: 'Sebewaing',
      state: 'MI',
    },
    48760: {
      lat: 43.32095,
      lng: -83.25595,
      city: 'Silverwood',
      state: 'MI',
    },
    48761: {
      lat: 44.51129,
      lng: -83.89658,
      city: 'South Branch',
      state: 'MI',
    },
    48762: {
      lat: 44.80757,
      lng: -83.48695,
      city: 'Spruce',
      state: 'MI',
    },
    48763: {
      lat: 44.25017,
      lng: -83.61441,
      city: 'Tawas City',
      state: 'MI',
    },
    48765: {
      lat: 44.15367,
      lng: -83.71885,
      city: 'Turner',
      state: 'MI',
    },
    48766: {
      lat: 44.11128,
      lng: -83.84794,
      city: 'Twining',
      state: 'MI',
    },
    48767: {
      lat: 43.64172,
      lng: -83.45541,
      city: 'Unionville',
      state: 'MI',
    },
    48768: {
      lat: 43.37077,
      lng: -83.57661,
      city: 'Vassar',
      state: 'MI',
    },
    48770: {
      lat: 44.24978,
      lng: -83.82037,
      city: 'Whittemore',
      state: 'MI',
    },
    48801: { lat: 43.37871, lng: -84.67667, city: 'Alma', state: 'MI' },
    48806: {
      lat: 43.18568,
      lng: -84.49675,
      city: 'Ashley',
      state: 'MI',
    },
    48807: {
      lat: 43.15277,
      lng: -84.40671,
      city: 'Bannister',
      state: 'MI',
    },
    48808: { lat: 42.82591, lng: -84.45081, city: 'Bath', state: 'MI' },
    48809: {
      lat: 43.0646,
      lng: -85.25712,
      city: 'Belding',
      state: 'MI',
    },
    48811: {
      lat: 43.18226,
      lng: -84.85518,
      city: 'Carson City',
      state: 'MI',
    },
    48813: {
      lat: 42.57862,
      lng: -84.84524,
      city: 'Charlotte',
      state: 'MI',
    },
    48815: {
      lat: 42.84155,
      lng: -85.25387,
      city: 'Clarksville',
      state: 'MI',
    },
    48816: {
      lat: 42.76151,
      lng: -83.95172,
      city: 'Cohoctah',
      state: 'MI',
    },
    48817: {
      lat: 43.01795,
      lng: -84.03772,
      city: 'Corunna',
      state: 'MI',
    },
    48818: {
      lat: 43.2759,
      lng: -84.89661,
      city: 'Crystal',
      state: 'MI',
    },
    48819: {
      lat: 42.54721,
      lng: -84.28521,
      city: 'Dansville',
      state: 'MI',
    },
    48820: {
      lat: 42.86277,
      lng: -84.59374,
      city: 'Dewitt',
      state: 'MI',
    },
    48821: {
      lat: 42.63873,
      lng: -84.64958,
      city: 'Dimondale',
      state: 'MI',
    },
    48822: {
      lat: 42.83407,
      lng: -84.76065,
      city: 'Eagle',
      state: 'MI',
    },
    48823: {
      lat: 42.76225,
      lng: -84.45407,
      city: 'East Lansing',
      state: 'MI',
    },
    48824: {
      lat: 42.72129,
      lng: -84.47607,
      city: 'East Lansing',
      state: 'MI',
    },
    48825: {
      lat: 42.72844,
      lng: -84.48067,
      city: 'East Lansing',
      state: 'MI',
    },
    48827: {
      lat: 42.509,
      lng: -84.65971,
      city: 'Eaton Rapids',
      state: 'MI',
    },
    48829: {
      lat: 43.40027,
      lng: -85.02037,
      city: 'Edmore',
      state: 'MI',
    },
    48831: { lat: 43.10253, lng: -84.3744, city: 'Elsie', state: 'MI' },
    48832: {
      lat: 43.41212,
      lng: -84.77767,
      city: 'Elwell',
      state: 'MI',
    },
    48834: {
      lat: 43.12981,
      lng: -85.03108,
      city: 'Fenwick',
      state: 'MI',
    },
    48835: {
      lat: 43.03008,
      lng: -84.74655,
      city: 'Fowler',
      state: 'MI',
    },
    48836: {
      lat: 42.66899,
      lng: -84.07421,
      city: 'Fowlerville',
      state: 'MI',
    },
    48837: {
      lat: 42.7453,
      lng: -84.76701,
      city: 'Grand Ledge',
      state: 'MI',
    },
    48838: {
      lat: 43.18268,
      lng: -85.25943,
      city: 'Greenville',
      state: 'MI',
    },
    48840: {
      lat: 42.76685,
      lng: -84.37071,
      city: 'Haslett',
      state: 'MI',
    },
    48841: {
      lat: 43.11372,
      lng: -84.2392,
      city: 'Henderson',
      state: 'MI',
    },
    48842: { lat: 42.63469, lng: -84.53931, city: 'Holt', state: 'MI' },
    48843: {
      lat: 42.56891,
      lng: -83.92333,
      city: 'Howell',
      state: 'MI',
    },
    48845: {
      lat: 43.10746,
      lng: -84.854,
      city: 'Hubbardston',
      state: 'MI',
    },
    48846: {
      lat: 42.98611,
      lng: -85.05754,
      city: 'Ionia',
      state: 'MI',
    },
    48847: { lat: 43.2714, lng: -84.5776, city: 'Ithaca', state: 'MI' },
    48848: {
      lat: 42.88584,
      lng: -84.35673,
      city: 'Laingsburg',
      state: 'MI',
    },
    48849: {
      lat: 42.7984,
      lng: -85.13298,
      city: 'Lake Odessa',
      state: 'MI',
    },
    48850: {
      lat: 43.4374,
      lng: -85.25519,
      city: 'Lakeview',
      state: 'MI',
    },
    48851: { lat: 42.95469, lng: -84.9364, city: 'Lyons', state: 'MI' },
    48852: {
      lat: 43.35256,
      lng: -85.04652,
      city: 'Mcbrides',
      state: 'MI',
    },
    48853: {
      lat: 43.10371,
      lng: -84.68999,
      city: 'Maple Rapids',
      state: 'MI',
    },
    48854: {
      lat: 42.58199,
      lng: -84.45182,
      city: 'Mason',
      state: 'MI',
    },
    48855: {
      lat: 42.68759,
      lng: -83.91331,
      city: 'Howell',
      state: 'MI',
    },
    48856: {
      lat: 43.19994,
      lng: -84.7372,
      city: 'Middleton',
      state: 'MI',
    },
    48857: {
      lat: 42.84243,
      lng: -84.15503,
      city: 'Morrice',
      state: 'MI',
    },
    48858: {
      lat: 43.61483,
      lng: -84.79576,
      city: 'Mount Pleasant',
      state: 'MI',
    },
    48859: {
      lat: 43.58632,
      lng: -84.77341,
      city: 'Mount Pleasant',
      state: 'MI',
    },
    48860: { lat: 43.05089, lng: -84.90939, city: 'Muir', state: 'MI' },
    48861: {
      lat: 42.73002,
      lng: -84.91741,
      city: 'Mulliken',
      state: 'MI',
    },
    48862: {
      lat: 43.24987,
      lng: -84.54847,
      city: 'North Star',
      state: 'MI',
    },
    48864: {
      lat: 42.70215,
      lng: -84.40902,
      city: 'Okemos',
      state: 'MI',
    },
    48865: {
      lat: 43.08785,
      lng: -85.10725,
      city: 'Orleans',
      state: 'MI',
    },
    48866: { lat: 42.99303, lng: -84.37219, city: 'Ovid', state: 'MI' },
    48867: {
      lat: 43.00537,
      lng: -84.18766,
      city: 'Owosso',
      state: 'MI',
    },
    48870: { lat: 43.11027, lng: -84.98483, city: 'Palo', state: 'MI' },
    48871: {
      lat: 43.15476,
      lng: -84.67874,
      city: 'Perrinton',
      state: 'MI',
    },
    48872: { lat: 42.80065, lng: -84.2189, city: 'Perry', state: 'MI' },
    48873: {
      lat: 43.00978,
      lng: -84.83608,
      city: 'Pewamo',
      state: 'MI',
    },
    48874: {
      lat: 43.18644,
      lng: -84.59498,
      city: 'Pompeii',
      state: 'MI',
    },
    48875: {
      lat: 42.8578,
      lng: -84.93201,
      city: 'Portland',
      state: 'MI',
    },
    48876: {
      lat: 42.64757,
      lng: -84.73631,
      city: 'Potterville',
      state: 'MI',
    },
    48877: {
      lat: 43.40068,
      lng: -84.84316,
      city: 'Riverdale',
      state: 'MI',
    },
    48878: {
      lat: 43.71225,
      lng: -84.77686,
      city: 'Rosebush',
      state: 'MI',
    },
    48879: {
      lat: 43.00629,
      lng: -84.57981,
      city: 'Saint Johns',
      state: 'MI',
    },
    48880: {
      lat: 43.458,
      lng: -84.57135,
      city: 'Saint Louis',
      state: 'MI',
    },
    48881: {
      lat: 42.934,
      lng: -85.20214,
      city: 'Saranac',
      state: 'MI',
    },
    48883: {
      lat: 43.54336,
      lng: -84.66771,
      city: 'Shepherd',
      state: 'MI',
    },
    48884: {
      lat: 43.20731,
      lng: -85.04249,
      city: 'Sheridan',
      state: 'MI',
    },
    48885: {
      lat: 43.24908,
      lng: -85.14356,
      city: 'Sidney',
      state: 'MI',
    },
    48886: {
      lat: 43.41994,
      lng: -85.16092,
      city: 'Six Lakes',
      state: 'MI',
    },
    48888: {
      lat: 43.3089,
      lng: -85.09566,
      city: 'Stanton',
      state: 'MI',
    },
    48889: {
      lat: 43.29133,
      lng: -84.79701,
      city: 'Sumner',
      state: 'MI',
    },
    48890: {
      lat: 42.76878,
      lng: -84.97167,
      city: 'Sunfield',
      state: 'MI',
    },
    48891: {
      lat: 43.39676,
      lng: -84.91469,
      city: 'Vestaburg',
      state: 'MI',
    },
    48892: {
      lat: 42.63662,
      lng: -84.17477,
      city: 'Webberville',
      state: 'MI',
    },
    48893: {
      lat: 43.68329,
      lng: -84.97805,
      city: 'Weidman',
      state: 'MI',
    },
    48894: {
      lat: 42.92841,
      lng: -84.79489,
      city: 'Westphalia',
      state: 'MI',
    },
    48895: {
      lat: 42.68946,
      lng: -84.28061,
      city: 'Williamston',
      state: 'MI',
    },
    48896: { lat: 43.52127, lng: -84.90101, city: 'Winn', state: 'MI' },
    48897: {
      lat: 42.70498,
      lng: -85.13193,
      city: 'Woodland',
      state: 'MI',
    },
    48906: {
      lat: 42.78489,
      lng: -84.58886,
      city: 'Lansing',
      state: 'MI',
    },
    48910: {
      lat: 42.6969,
      lng: -84.52541,
      city: 'Lansing',
      state: 'MI',
    },
    48911: {
      lat: 42.67351,
      lng: -84.57285,
      city: 'Lansing',
      state: 'MI',
    },
    48912: {
      lat: 42.73896,
      lng: -84.52319,
      city: 'Lansing',
      state: 'MI',
    },
    48915: {
      lat: 42.73885,
      lng: -84.57076,
      city: 'Lansing',
      state: 'MI',
    },
    48917: {
      lat: 42.72509,
      lng: -84.63897,
      city: 'Lansing',
      state: 'MI',
    },
    48933: {
      lat: 42.72956,
      lng: -84.55595,
      city: 'Lansing',
      state: 'MI',
    },
    49001: {
      lat: 42.2647,
      lng: -85.56265,
      city: 'Kalamazoo',
      state: 'MI',
    },
    49002: {
      lat: 42.19867,
      lng: -85.55906,
      city: 'Portage',
      state: 'MI',
    },
    49004: {
      lat: 42.34903,
      lng: -85.55954,
      city: 'Kalamazoo',
      state: 'MI',
    },
    49006: {
      lat: 42.29539,
      lng: -85.6291,
      city: 'Kalamazoo',
      state: 'MI',
    },
    49007: {
      lat: 42.30252,
      lng: -85.5883,
      city: 'Kalamazoo',
      state: 'MI',
    },
    49008: {
      lat: 42.26337,
      lng: -85.61528,
      city: 'Kalamazoo',
      state: 'MI',
    },
    49009: {
      lat: 42.30913,
      lng: -85.69278,
      city: 'Kalamazoo',
      state: 'MI',
    },
    49010: {
      lat: 42.53418,
      lng: -85.87298,
      city: 'Allegan',
      state: 'MI',
    },
    49011: {
      lat: 42.10288,
      lng: -85.22285,
      city: 'Athens',
      state: 'MI',
    },
    49012: {
      lat: 42.34626,
      lng: -85.34116,
      city: 'Augusta',
      state: 'MI',
    },
    49013: {
      lat: 42.30524,
      lng: -86.10796,
      city: 'Bangor',
      state: 'MI',
    },
    49014: {
      lat: 42.30996,
      lng: -85.10711,
      city: 'Battle Creek',
      state: 'MI',
    },
    49015: {
      lat: 42.27467,
      lng: -85.23115,
      city: 'Battle Creek',
      state: 'MI',
    },
    49017: {
      lat: 42.3979,
      lng: -85.2111,
      city: 'Battle Creek',
      state: 'MI',
    },
    49021: {
      lat: 42.4588,
      lng: -85.06435,
      city: 'Bellevue',
      state: 'MI',
    },
    49022: {
      lat: 42.11434,
      lng: -86.3675,
      city: 'Benton Harbor',
      state: 'MI',
    },
    49024: {
      lat: 42.20207,
      lng: -85.61917,
      city: 'Portage',
      state: 'MI',
    },
    49026: {
      lat: 42.37778,
      lng: -85.96273,
      city: 'Bloomingdale',
      state: 'MI',
    },
    49027: {
      lat: 42.3482,
      lng: -86.06939,
      city: 'Breedsville',
      state: 'MI',
    },
    49028: {
      lat: 41.84438,
      lng: -85.1837,
      city: 'Bronson',
      state: 'MI',
    },
    49029: {
      lat: 42.1423,
      lng: -85.09107,
      city: 'Burlington',
      state: 'MI',
    },
    49030: {
      lat: 41.85529,
      lng: -85.33129,
      city: 'Burr Oak',
      state: 'MI',
    },
    49031: {
      lat: 41.89883,
      lng: -85.98849,
      city: 'Cassopolis',
      state: 'MI',
    },
    49032: {
      lat: 41.91779,
      lng: -85.50767,
      city: 'Centreville',
      state: 'MI',
    },
    49033: {
      lat: 42.22654,
      lng: -85.09193,
      city: 'Ceresco',
      state: 'MI',
    },
    49034: {
      lat: 42.23178,
      lng: -85.33403,
      city: 'Climax',
      state: 'MI',
    },
    49036: {
      lat: 41.90565,
      lng: -85.02391,
      city: 'Coldwater',
      state: 'MI',
    },
    49037: {
      lat: 42.33448,
      lng: -85.24706,
      city: 'Battle Creek',
      state: 'MI',
    },
    49038: {
      lat: 42.20554,
      lng: -86.32489,
      city: 'Coloma',
      state: 'MI',
    },
    49040: {
      lat: 41.96847,
      lng: -85.32398,
      city: 'Colon',
      state: 'MI',
    },
    49042: {
      lat: 41.85665,
      lng: -85.67142,
      city: 'Constantine',
      state: 'MI',
    },
    49043: {
      lat: 42.28658,
      lng: -86.2656,
      city: 'Covert',
      state: 'MI',
    },
    49045: {
      lat: 42.1038,
      lng: -86.00219,
      city: 'Decatur',
      state: 'MI',
    },
    49046: {
      lat: 42.51229,
      lng: -85.40249,
      city: 'Delton',
      state: 'MI',
    },
    49047: {
      lat: 42.00437,
      lng: -86.12138,
      city: 'Dowagiac',
      state: 'MI',
    },
    49048: {
      lat: 42.27202,
      lng: -85.49893,
      city: 'Kalamazoo',
      state: 'MI',
    },
    49050: {
      lat: 42.50598,
      lng: -85.23651,
      city: 'Dowling',
      state: 'MI',
    },
    49051: {
      lat: 42.18207,
      lng: -85.23803,
      city: 'East Leroy',
      state: 'MI',
    },
    49052: {
      lat: 42.11538,
      lng: -85.31936,
      city: 'Fulton',
      state: 'MI',
    },
    49053: {
      lat: 42.28877,
      lng: -85.41722,
      city: 'Galesburg',
      state: 'MI',
    },
    49055: {
      lat: 42.37022,
      lng: -85.85327,
      city: 'Gobles',
      state: 'MI',
    },
    49056: {
      lat: 42.39951,
      lng: -86.05967,
      city: 'Grand Junction',
      state: 'MI',
    },
    49057: {
      lat: 42.18647,
      lng: -86.16691,
      city: 'Hartford',
      state: 'MI',
    },
    49058: {
      lat: 42.63331,
      lng: -85.29316,
      city: 'Hastings',
      state: 'MI',
    },
    49060: {
      lat: 42.42738,
      lng: -85.39589,
      city: 'Hickory Corners',
      state: 'MI',
    },
    49061: {
      lat: 41.87964,
      lng: -85.82551,
      city: 'Jones',
      state: 'MI',
    },
    49063: { lat: 42.4098, lng: -86.1396, city: 'Lacota', state: 'MI' },
    49064: {
      lat: 42.22028,
      lng: -86.04991,
      city: 'Lawrence',
      state: 'MI',
    },
    49065: {
      lat: 42.13645,
      lng: -85.83928,
      city: 'Lawton',
      state: 'MI',
    },
    49066: {
      lat: 42.03319,
      lng: -85.35007,
      city: 'Leonidas',
      state: 'MI',
    },
    49067: {
      lat: 42.02359,
      lng: -85.79982,
      city: 'Marcellus',
      state: 'MI',
    },
    49068: {
      lat: 42.2765,
      lng: -84.94063,
      city: 'Marshall',
      state: 'MI',
    },
    49070: {
      lat: 42.54023,
      lng: -85.62879,
      city: 'Martin',
      state: 'MI',
    },
    49071: {
      lat: 42.2275,
      lng: -85.78002,
      city: 'Mattawan',
      state: 'MI',
    },
    49072: {
      lat: 42.01295,
      lng: -85.45825,
      city: 'Mendon',
      state: 'MI',
    },
    49073: {
      lat: 42.57971,
      lng: -85.11717,
      city: 'Nashville',
      state: 'MI',
    },
    49074: {
      lat: 42.3198,
      lng: -85.53991,
      city: 'Nazareth',
      state: 'MI',
    },
    49075: {
      lat: 41.91783,
      lng: -85.44899,
      city: 'Nottawa',
      state: 'MI',
    },
    49076: {
      lat: 42.4309,
      lng: -84.88657,
      city: 'Olivet',
      state: 'MI',
    },
    49078: {
      lat: 42.46585,
      lng: -85.72846,
      city: 'Otsego',
      state: 'MI',
    },
    49079: {
      lat: 42.23997,
      lng: -85.90901,
      city: 'Paw Paw',
      state: 'MI',
    },
    49080: {
      lat: 42.46086,
      lng: -85.57711,
      city: 'Plainwell',
      state: 'MI',
    },
    49082: { lat: 41.94257, lng: -84.876, city: 'Quincy', state: 'MI' },
    49083: {
      lat: 42.3784,
      lng: -85.45899,
      city: 'Richland',
      state: 'MI',
    },
    49084: {
      lat: 42.18018,
      lng: -86.3852,
      city: 'Riverside',
      state: 'MI',
    },
    49085: {
      lat: 42.04883,
      lng: -86.45923,
      city: 'Saint Joseph',
      state: 'MI',
    },
    49087: {
      lat: 42.12209,
      lng: -85.68557,
      city: 'Schoolcraft',
      state: 'MI',
    },
    49088: {
      lat: 42.18602,
      lng: -85.42503,
      city: 'Scotts',
      state: 'MI',
    },
    49089: {
      lat: 42.00181,
      lng: -85.23113,
      city: 'Sherwood',
      state: 'MI',
    },
    49090: {
      lat: 42.40853,
      lng: -86.2168,
      city: 'South Haven',
      state: 'MI',
    },
    49091: {
      lat: 41.82509,
      lng: -85.44627,
      city: 'Sturgis',
      state: 'MI',
    },
    49092: {
      lat: 42.09822,
      lng: -84.97335,
      city: 'Tekonsha',
      state: 'MI',
    },
    49093: {
      lat: 41.97375,
      lng: -85.64665,
      city: 'Three Rivers',
      state: 'MI',
    },
    49094: {
      lat: 42.04696,
      lng: -85.11752,
      city: 'Union City',
      state: 'MI',
    },
    49095: {
      lat: 41.91008,
      lng: -85.8826,
      city: 'Vandalia',
      state: 'MI',
    },
    49096: {
      lat: 42.63794,
      lng: -85.01409,
      city: 'Vermontville',
      state: 'MI',
    },
    49097: {
      lat: 42.11491,
      lng: -85.48985,
      city: 'Vicksburg',
      state: 'MI',
    },
    49098: {
      lat: 42.16756,
      lng: -86.2425,
      city: 'Watervliet',
      state: 'MI',
    },
    49099: {
      lat: 41.79139,
      lng: -85.67437,
      city: 'White Pigeon',
      state: 'MI',
    },
    49101: {
      lat: 41.94462,
      lng: -86.48429,
      city: 'Baroda',
      state: 'MI',
    },
    49102: {
      lat: 41.95194,
      lng: -86.26037,
      city: 'Berrien Center',
      state: 'MI',
    },
    49103: {
      lat: 41.94559,
      lng: -86.37519,
      city: 'Berrien Springs',
      state: 'MI',
    },
    49104: {
      lat: 41.96348,
      lng: -86.35877,
      city: 'Berrien Springs',
      state: 'MI',
    },
    49106: {
      lat: 41.93452,
      lng: -86.55105,
      city: 'Bridgman',
      state: 'MI',
    },
    49107: {
      lat: 41.84804,
      lng: -86.41396,
      city: 'Buchanan',
      state: 'MI',
    },
    49111: {
      lat: 42.02544,
      lng: -86.28621,
      city: 'Eau Claire',
      state: 'MI',
    },
    49112: {
      lat: 41.79833,
      lng: -86.03425,
      city: 'Edwardsburg',
      state: 'MI',
    },
    49113: {
      lat: 41.80259,
      lng: -86.50044,
      city: 'Galien',
      state: 'MI',
    },
    49115: {
      lat: 41.87537,
      lng: -86.63389,
      city: 'Harbert',
      state: 'MI',
    },
    49116: {
      lat: 41.84836,
      lng: -86.66637,
      city: 'Lakeside',
      state: 'MI',
    },
    49117: {
      lat: 41.78028,
      lng: -86.72696,
      city: 'New Buffalo',
      state: 'MI',
    },
    49119: {
      lat: 41.87564,
      lng: -86.54862,
      city: 'New Troy',
      state: 'MI',
    },
    49120: {
      lat: 41.83148,
      lng: -86.23162,
      city: 'Niles',
      state: 'MI',
    },
    49125: {
      lat: 41.88698,
      lng: -86.57946,
      city: 'Sawyer',
      state: 'MI',
    },
    49126: {
      lat: 42.02593,
      lng: -86.36946,
      city: 'Sodus',
      state: 'MI',
    },
    49127: {
      lat: 42.00411,
      lng: -86.51597,
      city: 'Stevensville',
      state: 'MI',
    },
    49128: {
      lat: 41.81706,
      lng: -86.60457,
      city: 'Three Oaks',
      state: 'MI',
    },
    49129: {
      lat: 41.81699,
      lng: -86.68839,
      city: 'Union Pier',
      state: 'MI',
    },
    49130: {
      lat: 41.78086,
      lng: -85.85997,
      city: 'Union',
      state: 'MI',
    },
    49201: {
      lat: 42.25213,
      lng: -84.38301,
      city: 'Jackson',
      state: 'MI',
    },
    49202: {
      lat: 42.26737,
      lng: -84.4107,
      city: 'Jackson',
      state: 'MI',
    },
    49203: {
      lat: 42.22096,
      lng: -84.40347,
      city: 'Jackson',
      state: 'MI',
    },
    49220: {
      lat: 41.99511,
      lng: -84.34108,
      city: 'Addison',
      state: 'MI',
    },
    49221: {
      lat: 41.90343,
      lng: -84.06372,
      city: 'Adrian',
      state: 'MI',
    },
    49224: {
      lat: 42.27982,
      lng: -84.74852,
      city: 'Albion',
      state: 'MI',
    },
    49227: { lat: 41.9498, lng: -84.77036, city: 'Allen', state: 'MI' },
    49228: {
      lat: 41.8039,
      lng: -83.88247,
      city: 'Blissfield',
      state: 'MI',
    },
    49229: {
      lat: 41.99005,
      lng: -83.82655,
      city: 'Britton',
      state: 'MI',
    },
    49230: {
      lat: 42.09701,
      lng: -84.22266,
      city: 'Brooklyn',
      state: 'MI',
    },
    49232: {
      lat: 41.74049,
      lng: -84.66858,
      city: 'Camden',
      state: 'MI',
    },
    49233: {
      lat: 42.0644,
      lng: -84.36512,
      city: 'Cement City',
      state: 'MI',
    },
    49234: {
      lat: 42.12114,
      lng: -84.3673,
      city: 'Clarklake',
      state: 'MI',
    },
    49235: {
      lat: 41.86348,
      lng: -84.21348,
      city: 'Clayton',
      state: 'MI',
    },
    49236: {
      lat: 42.07792,
      lng: -83.95035,
      city: 'Clinton',
      state: 'MI',
    },
    49237: {
      lat: 42.1731,
      lng: -84.64906,
      city: 'Concord',
      state: 'MI',
    },
    49238: {
      lat: 41.90593,
      lng: -83.78809,
      city: 'Deerfield',
      state: 'MI',
    },
    49240: {
      lat: 42.27821,
      lng: -84.18027,
      city: 'Grass Lake',
      state: 'MI',
    },
    49241: {
      lat: 42.10121,
      lng: -84.60149,
      city: 'Hanover',
      state: 'MI',
    },
    49242: {
      lat: 41.88434,
      lng: -84.61581,
      city: 'Hillsdale',
      state: 'MI',
    },
    49245: {
      lat: 42.14085,
      lng: -84.81797,
      city: 'Homer',
      state: 'MI',
    },
    49246: {
      lat: 42.12268,
      lng: -84.50366,
      city: 'Horton',
      state: 'MI',
    },
    49247: {
      lat: 41.84712,
      lng: -84.34189,
      city: 'Hudson',
      state: 'MI',
    },
    49248: {
      lat: 41.75651,
      lng: -84.01137,
      city: 'Jasper',
      state: 'MI',
    },
    49249: {
      lat: 42.02275,
      lng: -84.45575,
      city: 'Jerome',
      state: 'MI',
    },
    49250: {
      lat: 42.01875,
      lng: -84.62168,
      city: 'Jonesville',
      state: 'MI',
    },
    49251: {
      lat: 42.46561,
      lng: -84.40916,
      city: 'Leslie',
      state: 'MI',
    },
    49252: {
      lat: 42.03853,
      lng: -84.76683,
      city: 'Litchfield',
      state: 'MI',
    },
    49253: {
      lat: 41.96443,
      lng: -84.2697,
      city: 'Manitou Beach',
      state: 'MI',
    },
    49254: {
      lat: 42.2268,
      lng: -84.32165,
      city: 'Michigan Center',
      state: 'MI',
    },
    49255: {
      lat: 41.77152,
      lng: -84.85499,
      city: 'Montgomery',
      state: 'MI',
    },
    49256: {
      lat: 41.74989,
      lng: -84.22122,
      city: 'Morenci',
      state: 'MI',
    },
    49258: {
      lat: 42.06,
      lng: -84.65833,
      city: 'Mosherville',
      state: 'MI',
    },
    49259: {
      lat: 42.38099,
      lng: -84.25763,
      city: 'Munith',
      state: 'MI',
    },
    49261: {
      lat: 42.16174,
      lng: -84.2472,
      city: 'Napoleon',
      state: 'MI',
    },
    49262: {
      lat: 41.96162,
      lng: -84.4673,
      city: 'North Adams',
      state: 'MI',
    },
    49263: {
      lat: 42.15817,
      lng: -84.18225,
      city: 'Norvell',
      state: 'MI',
    },
    49264: {
      lat: 42.44118,
      lng: -84.55122,
      city: 'Onondaga',
      state: 'MI',
    },
    49265: {
      lat: 42.01022,
      lng: -84.17432,
      city: 'Onsted',
      state: 'MI',
    },
    49266: {
      lat: 41.84247,
      lng: -84.54641,
      city: 'Osseo',
      state: 'MI',
    },
    49267: {
      lat: 41.75979,
      lng: -83.72341,
      city: 'Ottawa Lake',
      state: 'MI',
    },
    49268: {
      lat: 41.87156,
      lng: -83.92565,
      city: 'Palmyra',
      state: 'MI',
    },
    49269: { lat: 42.29307, lng: -84.586, city: 'Parma', state: 'MI' },
    49270: {
      lat: 41.86864,
      lng: -83.68206,
      city: 'Petersburg',
      state: 'MI',
    },
    49271: {
      lat: 41.83813,
      lng: -84.45382,
      city: 'Pittsford',
      state: 'MI',
    },
    49272: {
      lat: 42.39299,
      lng: -84.34974,
      city: 'Pleasant Lake',
      state: 'MI',
    },
    49274: {
      lat: 41.83689,
      lng: -84.76419,
      city: 'Reading',
      state: 'MI',
    },
    49276: { lat: 41.79733, lng: -83.7843, city: 'Riga', state: 'MI' },
    49277: {
      lat: 42.38871,
      lng: -84.46679,
      city: 'Rives Junction',
      state: 'MI',
    },
    49279: {
      lat: 41.77965,
      lng: -84.10409,
      city: 'Sand Creek',
      state: 'MI',
    },
    49282: {
      lat: 42.03496,
      lng: -84.40518,
      city: 'Somerset Center',
      state: 'MI',
    },
    49283: {
      lat: 42.20486,
      lng: -84.55478,
      city: 'Spring Arbor',
      state: 'MI',
    },
    49284: {
      lat: 42.38507,
      lng: -84.69128,
      city: 'Springport',
      state: 'MI',
    },
    49285: {
      lat: 42.46855,
      lng: -84.20752,
      city: 'Stockbridge',
      state: 'MI',
    },
    49286: {
      lat: 42.01443,
      lng: -83.93447,
      city: 'Tecumseh',
      state: 'MI',
    },
    49287: {
      lat: 42.02177,
      lng: -84.08263,
      city: 'Tipton',
      state: 'MI',
    },
    49288: {
      lat: 41.73534,
      lng: -84.43211,
      city: 'Waldron',
      state: 'MI',
    },
    49289: {
      lat: 41.77144,
      lng: -84.10702,
      city: 'Weston',
      state: 'MI',
    },
    49301: { lat: 42.97716, lng: -85.47671, city: 'Ada', state: 'MI' },
    49302: { lat: 42.82307, lng: -85.39871, city: 'Alto', state: 'MI' },
    49303: {
      lat: 43.27803,
      lng: -85.85824,
      city: 'Bailey',
      state: 'MI',
    },
    49304: {
      lat: 43.91027,
      lng: -85.87417,
      city: 'Baldwin',
      state: 'MI',
    },
    49305: {
      lat: 43.74926,
      lng: -85.15023,
      city: 'Barryton',
      state: 'MI',
    },
    49306: {
      lat: 43.07296,
      lng: -85.56939,
      city: 'Belmont',
      state: 'MI',
    },
    49307: {
      lat: 43.69797,
      lng: -85.48272,
      city: 'Big Rapids',
      state: 'MI',
    },
    49309: {
      lat: 43.76021,
      lng: -85.87842,
      city: 'Bitely',
      state: 'MI',
    },
    49310: {
      lat: 43.51525,
      lng: -85.05448,
      city: 'Blanchard',
      state: 'MI',
    },
    49312: {
      lat: 43.69009,
      lng: -85.79992,
      city: 'Brohman',
      state: 'MI',
    },
    49315: {
      lat: 42.80101,
      lng: -85.73589,
      city: 'Byron Center',
      state: 'MI',
    },
    49316: {
      lat: 42.79644,
      lng: -85.55564,
      city: 'Caledonia',
      state: 'MI',
    },
    49318: {
      lat: 43.22319,
      lng: -85.82981,
      city: 'Casnovia',
      state: 'MI',
    },
    49319: {
      lat: 43.22767,
      lng: -85.5281,
      city: 'Cedar Springs',
      state: 'MI',
    },
    49320: {
      lat: 43.75264,
      lng: -85.29276,
      city: 'Chippewa Lake',
      state: 'MI',
    },
    49321: {
      lat: 43.0737,
      lng: -85.68198,
      city: 'Comstock Park',
      state: 'MI',
    },
    49322: {
      lat: 43.35685,
      lng: -85.35261,
      city: 'Coral',
      state: 'MI',
    },
    49323: { lat: 42.72541, lng: -85.7871, city: 'Dorr', state: 'MI' },
    49325: {
      lat: 42.76155,
      lng: -85.30162,
      city: 'Freeport',
      state: 'MI',
    },
    49326: {
      lat: 43.25069,
      lng: -85.32083,
      city: 'Gowen',
      state: 'MI',
    },
    49327: {
      lat: 43.33507,
      lng: -85.84558,
      city: 'Grant',
      state: 'MI',
    },
    49328: {
      lat: 42.6304,
      lng: -85.75703,
      city: 'Hopkins',
      state: 'MI',
    },
    49329: {
      lat: 43.40567,
      lng: -85.48771,
      city: 'Howard City',
      state: 'MI',
    },
    49330: {
      lat: 43.24463,
      lng: -85.73721,
      city: 'Kent City',
      state: 'MI',
    },
    49331: {
      lat: 42.95161,
      lng: -85.35139,
      city: 'Lowell',
      state: 'MI',
    },
    49332: {
      lat: 43.62323,
      lng: -85.24468,
      city: 'Mecosta',
      state: 'MI',
    },
    49333: {
      lat: 42.69546,
      lng: -85.46343,
      city: 'Middleville',
      state: 'MI',
    },
    49335: {
      lat: 42.74626,
      lng: -85.66907,
      city: 'Moline',
      state: 'MI',
    },
    49336: {
      lat: 43.50752,
      lng: -85.42862,
      city: 'Morley',
      state: 'MI',
    },
    49337: {
      lat: 43.43472,
      lng: -85.71437,
      city: 'Newaygo',
      state: 'MI',
    },
    49338: {
      lat: 43.75888,
      lng: -85.61033,
      city: 'Paris',
      state: 'MI',
    },
    49339: {
      lat: 43.33574,
      lng: -85.49454,
      city: 'Pierson',
      state: 'MI',
    },
    49340: {
      lat: 43.62213,
      lng: -85.11222,
      city: 'Remus',
      state: 'MI',
    },
    49341: {
      lat: 43.12433,
      lng: -85.49246,
      city: 'Rockford',
      state: 'MI',
    },
    49342: {
      lat: 43.69634,
      lng: -85.32156,
      city: 'Rodney',
      state: 'MI',
    },
    49343: {
      lat: 43.29579,
      lng: -85.53115,
      city: 'Sand Lake',
      state: 'MI',
    },
    49344: {
      lat: 42.5919,
      lng: -85.59028,
      city: 'Shelbyville',
      state: 'MI',
    },
    49345: {
      lat: 43.15419,
      lng: -85.70224,
      city: 'Sparta',
      state: 'MI',
    },
    49346: {
      lat: 43.58616,
      lng: -85.42213,
      city: 'Stanwood',
      state: 'MI',
    },
    49347: {
      lat: 43.31488,
      lng: -85.34352,
      city: 'Trufant',
      state: 'MI',
    },
    49348: {
      lat: 42.68379,
      lng: -85.61619,
      city: 'Wayland',
      state: 'MI',
    },
    49349: {
      lat: 43.58291,
      lng: -85.74167,
      city: 'White Cloud',
      state: 'MI',
    },
    49401: {
      lat: 42.9763,
      lng: -85.95604,
      city: 'Allendale',
      state: 'MI',
    },
    49402: {
      lat: 43.94228,
      lng: -86.03284,
      city: 'Branch',
      state: 'MI',
    },
    49403: {
      lat: 43.13316,
      lng: -85.85022,
      city: 'Conklin',
      state: 'MI',
    },
    49404: {
      lat: 43.06602,
      lng: -85.95129,
      city: 'Coopersville',
      state: 'MI',
    },
    49405: {
      lat: 43.91051,
      lng: -86.18931,
      city: 'Custer',
      state: 'MI',
    },
    49406: {
      lat: 42.64224,
      lng: -86.209,
      city: 'Douglas',
      state: 'MI',
    },
    49408: {
      lat: 42.57077,
      lng: -86.11479,
      city: 'Fennville',
      state: 'MI',
    },
    49409: {
      lat: 43.08077,
      lng: -86.21826,
      city: 'Ferrysburg',
      state: 'MI',
    },
    49410: {
      lat: 44.01986,
      lng: -86.14265,
      city: 'Fountain',
      state: 'MI',
    },
    49411: {
      lat: 44.11006,
      lng: -86.25385,
      city: 'Free Soil',
      state: 'MI',
    },
    49412: {
      lat: 43.4699,
      lng: -85.94747,
      city: 'Fremont',
      state: 'MI',
    },
    49415: {
      lat: 43.15238,
      lng: -86.1166,
      city: 'Fruitport',
      state: 'MI',
    },
    49417: {
      lat: 43.01519,
      lng: -86.14387,
      city: 'Grand Haven',
      state: 'MI',
    },
    49418: {
      lat: 42.8774,
      lng: -85.77226,
      city: 'Grandville',
      state: 'MI',
    },
    49419: {
      lat: 42.67768,
      lng: -85.97826,
      city: 'Hamilton',
      state: 'MI',
    },
    49420: { lat: 43.71958, lng: -86.2811, city: 'Hart', state: 'MI' },
    49421: {
      lat: 43.59945,
      lng: -86.06658,
      city: 'Hesperia',
      state: 'MI',
    },
    49423: {
      lat: 42.73964,
      lng: -86.09015,
      city: 'Holland',
      state: 'MI',
    },
    49424: {
      lat: 42.84269,
      lng: -86.12431,
      city: 'Holland',
      state: 'MI',
    },
    49425: {
      lat: 43.43941,
      lng: -86.09981,
      city: 'Holton',
      state: 'MI',
    },
    49426: {
      lat: 42.86636,
      lng: -85.89038,
      city: 'Hudsonville',
      state: 'MI',
    },
    49428: {
      lat: 42.92042,
      lng: -85.83712,
      city: 'Jenison',
      state: 'MI',
    },
    49430: {
      lat: 43.01192,
      lng: -85.90053,
      city: 'Lamont',
      state: 'MI',
    },
    49431: {
      lat: 43.97251,
      lng: -86.39792,
      city: 'Ludington',
      state: 'MI',
    },
    49434: {
      lat: 42.76975,
      lng: -86.20774,
      city: 'Macatawa',
      state: 'MI',
    },
    49435: {
      lat: 43.02683,
      lng: -85.84049,
      city: 'Marne',
      state: 'MI',
    },
    49436: {
      lat: 43.68042,
      lng: -86.46203,
      city: 'Mears',
      state: 'MI',
    },
    49437: {
      lat: 43.4588,
      lng: -86.38121,
      city: 'Montague',
      state: 'MI',
    },
    49440: {
      lat: 43.23852,
      lng: -86.25387,
      city: 'Muskegon',
      state: 'MI',
    },
    49441: {
      lat: 43.18745,
      lng: -86.2736,
      city: 'Muskegon',
      state: 'MI',
    },
    49442: {
      lat: 43.23995,
      lng: -86.13278,
      city: 'Muskegon',
      state: 'MI',
    },
    49443: {
      lat: 43.22204,
      lng: -86.24155,
      city: 'Muskegon',
      state: 'MI',
    },
    49444: {
      lat: 43.17671,
      lng: -86.19892,
      city: 'Muskegon',
      state: 'MI',
    },
    49445: {
      lat: 43.297,
      lng: -86.27481,
      city: 'Muskegon',
      state: 'MI',
    },
    49446: {
      lat: 43.55306,
      lng: -86.39096,
      city: 'New Era',
      state: 'MI',
    },
    49448: {
      lat: 43.09074,
      lng: -86.07432,
      city: 'Nunica',
      state: 'MI',
    },
    49449: {
      lat: 43.79927,
      lng: -86.37496,
      city: 'Pentwater',
      state: 'MI',
    },
    49450: {
      lat: 42.48333,
      lng: -86.08051,
      city: 'Pullman',
      state: 'MI',
    },
    49451: {
      lat: 43.21177,
      lng: -85.96966,
      city: 'Ravenna',
      state: 'MI',
    },
    49452: {
      lat: 43.51316,
      lng: -86.25548,
      city: 'Rothbury',
      state: 'MI',
    },
    49453: {
      lat: 42.66511,
      lng: -86.17388,
      city: 'Saugatuck',
      state: 'MI',
    },
    49454: {
      lat: 43.95208,
      lng: -86.28638,
      city: 'Scottville',
      state: 'MI',
    },
    49455: {
      lat: 43.60976,
      lng: -86.36723,
      city: 'Shelby',
      state: 'MI',
    },
    49456: {
      lat: 43.09026,
      lng: -86.19347,
      city: 'Spring Lake',
      state: 'MI',
    },
    49457: {
      lat: 43.35105,
      lng: -86.14455,
      city: 'Twin Lake',
      state: 'MI',
    },
    49458: {
      lat: 43.94818,
      lng: -86.11529,
      city: 'Walhalla',
      state: 'MI',
    },
    49459: {
      lat: 43.73678,
      lng: -86.10805,
      city: 'Walkerville',
      state: 'MI',
    },
    49460: {
      lat: 42.93473,
      lng: -86.1429,
      city: 'West Olive',
      state: 'MI',
    },
    49461: {
      lat: 43.38215,
      lng: -86.32317,
      city: 'Whitehall',
      state: 'MI',
    },
    49464: {
      lat: 42.84183,
      lng: -85.98338,
      city: 'Zeeland',
      state: 'MI',
    },
    49503: {
      lat: 42.96282,
      lng: -85.65872,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49504: {
      lat: 42.98047,
      lng: -85.70937,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49505: {
      lat: 42.9984,
      lng: -85.63699,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49506: {
      lat: 42.94515,
      lng: -85.6136,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49507: {
      lat: 42.93054,
      lng: -85.65524,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49508: {
      lat: 42.87459,
      lng: -85.62586,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49509: {
      lat: 42.89773,
      lng: -85.69299,
      city: 'Wyoming',
      state: 'MI',
    },
    49512: {
      lat: 42.8821,
      lng: -85.54764,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49519: {
      lat: 42.89914,
      lng: -85.7192,
      city: 'Wyoming',
      state: 'MI',
    },
    49525: {
      lat: 43.02144,
      lng: -85.5918,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49534: {
      lat: 42.97106,
      lng: -85.78834,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49544: {
      lat: 43.04591,
      lng: -85.7417,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49546: {
      lat: 42.92815,
      lng: -85.53629,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49548: {
      lat: 42.86795,
      lng: -85.66273,
      city: 'Grand Rapids',
      state: 'MI',
    },
    49601: {
      lat: 44.2385,
      lng: -85.5211,
      city: 'Cadillac',
      state: 'MI',
    },
    49611: { lat: 44.97995, lng: -84.97873, city: 'Alba', state: 'MI' },
    49612: {
      lat: 44.86212,
      lng: -85.22561,
      city: 'Alden',
      state: 'MI',
    },
    49613: {
      lat: 44.50538,
      lng: -86.20412,
      city: 'Arcadia',
      state: 'MI',
    },
    49614: {
      lat: 44.43886,
      lng: -86.10879,
      city: 'Bear Lake',
      state: 'MI',
    },
    49615: {
      lat: 44.97219,
      lng: -85.20683,
      city: 'Bellaire',
      state: 'MI',
    },
    49616: {
      lat: 44.57491,
      lng: -86.08448,
      city: 'Benzonia',
      state: 'MI',
    },
    49617: {
      lat: 44.64105,
      lng: -86.03505,
      city: 'Beulah',
      state: 'MI',
    },
    49618: { lat: 44.30096, lng: -85.59853, city: 'Boon', state: 'MI' },
    49619: {
      lat: 44.30331,
      lng: -85.98784,
      city: 'Brethren',
      state: 'MI',
    },
    49620: {
      lat: 44.5188,
      lng: -85.68976,
      city: 'Buckley',
      state: 'MI',
    },
    49621: {
      lat: 44.86229,
      lng: -85.77555,
      city: 'Cedar',
      state: 'MI',
    },
    49622: {
      lat: 45.0785,
      lng: -85.26583,
      city: 'Central Lake',
      state: 'MI',
    },
    49623: {
      lat: 43.90367,
      lng: -85.68285,
      city: 'Chase',
      state: 'MI',
    },
    49625: {
      lat: 44.42785,
      lng: -85.87348,
      city: 'Copemish',
      state: 'MI',
    },
    49626: {
      lat: 44.24284,
      lng: -86.29252,
      city: 'Eastlake',
      state: 'MI',
    },
    49627: {
      lat: 45.11261,
      lng: -85.35677,
      city: 'Eastport',
      state: 'MI',
    },
    49628: {
      lat: 44.62087,
      lng: -86.23095,
      city: 'Elberta',
      state: 'MI',
    },
    49629: {
      lat: 44.90835,
      lng: -85.39424,
      city: 'Elk Rapids',
      state: 'MI',
    },
    49630: {
      lat: 44.92262,
      lng: -86.00074,
      city: 'Empire',
      state: 'MI',
    },
    49631: {
      lat: 43.91707,
      lng: -85.25788,
      city: 'Evart',
      state: 'MI',
    },
    49632: {
      lat: 44.23993,
      lng: -84.97268,
      city: 'Falmouth',
      state: 'MI',
    },
    49633: {
      lat: 44.55709,
      lng: -85.19318,
      city: 'Fife Lake',
      state: 'MI',
    },
    49634: {
      lat: 44.2149,
      lng: -86.28896,
      city: 'Filer City',
      state: 'MI',
    },
    49635: {
      lat: 44.62261,
      lng: -86.18914,
      city: 'Frankfort',
      state: 'MI',
    },
    49636: {
      lat: 44.87261,
      lng: -85.99292,
      city: 'Glen Arbor',
      state: 'MI',
    },
    49637: {
      lat: 44.62897,
      lng: -85.71083,
      city: 'Grawn',
      state: 'MI',
    },
    49638: {
      lat: 44.28463,
      lng: -85.75073,
      city: 'Harrietta',
      state: 'MI',
    },
    49639: {
      lat: 43.8467,
      lng: -85.39768,
      city: 'Hersey',
      state: 'MI',
    },
    49640: {
      lat: 44.72088,
      lng: -86.03664,
      city: 'Honor',
      state: 'MI',
    },
    49642: {
      lat: 43.87927,
      lng: -85.77059,
      city: 'Idlewild',
      state: 'MI',
    },
    49643: {
      lat: 44.63959,
      lng: -85.83357,
      city: 'Interlochen',
      state: 'MI',
    },
    49644: {
      lat: 44.10787,
      lng: -85.91989,
      city: 'Irons',
      state: 'MI',
    },
    49645: {
      lat: 44.37157,
      lng: -86.02411,
      city: 'Kaleva',
      state: 'MI',
    },
    49646: {
      lat: 44.72727,
      lng: -85.04926,
      city: 'Kalkaska',
      state: 'MI',
    },
    49648: {
      lat: 44.98359,
      lng: -85.3435,
      city: 'Kewadin',
      state: 'MI',
    },
    49649: {
      lat: 44.5685,
      lng: -85.52596,
      city: 'Kingsley',
      state: 'MI',
    },
    49650: {
      lat: 44.73359,
      lng: -85.88033,
      city: 'Lake Ann',
      state: 'MI',
    },
    49651: {
      lat: 44.39603,
      lng: -85.10862,
      city: 'Lake City',
      state: 'MI',
    },
    49653: {
      lat: 44.97184,
      lng: -85.7302,
      city: 'Lake Leelanau',
      state: 'MI',
    },
    49654: {
      lat: 45.0268,
      lng: -86.00875,
      city: 'Leland',
      state: 'MI',
    },
    49655: {
      lat: 44.02718,
      lng: -85.43875,
      city: 'Leroy',
      state: 'MI',
    },
    49656: {
      lat: 44.05248,
      lng: -85.69789,
      city: 'Luther',
      state: 'MI',
    },
    49657: {
      lat: 44.21523,
      lng: -85.17653,
      city: 'McBain',
      state: 'MI',
    },
    49659: {
      lat: 44.90265,
      lng: -85.02679,
      city: 'Mancelona',
      state: 'MI',
    },
    49660: {
      lat: 44.23236,
      lng: -86.21125,
      city: 'Manistee',
      state: 'MI',
    },
    49663: {
      lat: 44.42494,
      lng: -85.39147,
      city: 'Manton',
      state: 'MI',
    },
    49664: {
      lat: 44.87457,
      lng: -85.89058,
      city: 'Maple City',
      state: 'MI',
    },
    49665: { lat: 44.0995, lng: -85.1309, city: 'Marion', state: 'MI' },
    49667: {
      lat: 44.34892,
      lng: -84.91898,
      city: 'Merritt',
      state: 'MI',
    },
    49668: {
      lat: 44.4028,
      lng: -85.70965,
      city: 'Mesick',
      state: 'MI',
    },
    49670: {
      lat: 45.12088,
      lng: -85.62096,
      city: 'Northport',
      state: 'MI',
    },
    49674: { lat: 45.0544, lng: -85.58882, city: 'Omena', state: 'MI' },
    49675: {
      lat: 44.37506,
      lng: -86.21978,
      city: 'Onekama',
      state: 'MI',
    },
    49676: {
      lat: 44.84147,
      lng: -85.29028,
      city: 'Rapid City',
      state: 'MI',
    },
    49677: {
      lat: 43.89533,
      lng: -85.54254,
      city: 'Reed City',
      state: 'MI',
    },
    49679: { lat: 43.8747, lng: -85.14895, city: 'Sears', state: 'MI' },
    49680: {
      lat: 44.64174,
      lng: -85.25089,
      city: 'South Boardman',
      state: 'MI',
    },
    49682: {
      lat: 44.97954,
      lng: -85.6512,
      city: 'Suttons Bay',
      state: 'MI',
    },
    49683: {
      lat: 44.53948,
      lng: -85.93009,
      city: 'Thompsonville',
      state: 'MI',
    },
    49684: {
      lat: 44.80191,
      lng: -85.70549,
      city: 'Traverse City',
      state: 'MI',
    },
    49685: {
      lat: 44.69859,
      lng: -85.70425,
      city: 'Traverse City',
      state: 'MI',
    },
    49686: {
      lat: 44.82841,
      lng: -85.53597,
      city: 'Traverse City',
      state: 'MI',
    },
    49688: {
      lat: 44.12115,
      lng: -85.44349,
      city: 'Tustin',
      state: 'MI',
    },
    49689: {
      lat: 44.21165,
      lng: -85.91484,
      city: 'Wellston',
      state: 'MI',
    },
    49690: {
      lat: 44.77915,
      lng: -85.38964,
      city: 'Williamsburg',
      state: 'MI',
    },
    49696: {
      lat: 44.6764,
      lng: -85.48413,
      city: 'Traverse City',
      state: 'MI',
    },
    49701: {
      lat: 45.75195,
      lng: -84.7426,
      city: 'Mackinaw City',
      state: 'MI',
    },
    49705: {
      lat: 45.34826,
      lng: -84.45824,
      city: 'Afton',
      state: 'MI',
    },
    49706: {
      lat: 45.44129,
      lng: -84.77915,
      city: 'Alanson',
      state: 'MI',
    },
    49707: {
      lat: 45.09375,
      lng: -83.50186,
      city: 'Alpena',
      state: 'MI',
    },
    49709: {
      lat: 45.03102,
      lng: -84.17094,
      city: 'Atlanta',
      state: 'MI',
    },
    49710: {
      lat: 46.27717,
      lng: -84.18478,
      city: 'Barbeau',
      state: 'MI',
    },
    49712: {
      lat: 45.2113,
      lng: -85.01089,
      city: 'Boyne City',
      state: 'MI',
    },
    49713: {
      lat: 45.21602,
      lng: -84.85185,
      city: 'Boyne Falls',
      state: 'MI',
    },
    49715: {
      lat: 46.3923,
      lng: -84.69493,
      city: 'Brimley',
      state: 'MI',
    },
    49716: {
      lat: 45.50975,
      lng: -84.75104,
      city: 'Brutus',
      state: 'MI',
    },
    49717: {
      lat: 45.43671,
      lng: -84.69796,
      city: 'Burt Lake',
      state: 'MI',
    },
    49718: {
      lat: 45.71438,
      lng: -84.81275,
      city: 'Carp Lake',
      state: 'MI',
    },
    49719: {
      lat: 46.0073,
      lng: -84.29304,
      city: 'Cedarville',
      state: 'MI',
    },
    49720: {
      lat: 45.27555,
      lng: -85.23984,
      city: 'Charlevoix',
      state: 'MI',
    },
    49721: {
      lat: 45.57181,
      lng: -84.45711,
      city: 'Cheboygan',
      state: 'MI',
    },
    49722: {
      lat: 45.42135,
      lng: -84.86459,
      city: 'Conway',
      state: 'MI',
    },
    49724: {
      lat: 46.32744,
      lng: -84.3884,
      city: 'Dafter',
      state: 'MI',
    },
    49725: {
      lat: 46.00337,
      lng: -84.01308,
      city: 'De Tour Village',
      state: 'MI',
    },
    49726: {
      lat: 46.00169,
      lng: -83.66277,
      city: 'Drummond Island',
      state: 'MI',
    },
    49727: {
      lat: 45.13452,
      lng: -85.10679,
      city: 'East Jordan',
      state: 'MI',
    },
    49728: {
      lat: 46.3702,
      lng: -84.99387,
      city: 'Eckerman',
      state: 'MI',
    },
    49729: {
      lat: 45.16123,
      lng: -85.28255,
      city: 'Ellsworth',
      state: 'MI',
    },
    49730: {
      lat: 45.04641,
      lng: -84.87644,
      city: 'Elmira',
      state: 'MI',
    },
    49733: {
      lat: 44.80368,
      lng: -84.7291,
      city: 'Frederic',
      state: 'MI',
    },
    49735: {
      lat: 44.99576,
      lng: -84.67117,
      city: 'Gaylord',
      state: 'MI',
    },
    49736: {
      lat: 46.07877,
      lng: -84.14573,
      city: 'Goetzville',
      state: 'MI',
    },
    49738: {
      lat: 44.69296,
      lng: -84.60578,
      city: 'Grayling',
      state: 'MI',
    },
    49740: {
      lat: 45.52303,
      lng: -84.99887,
      city: 'Harbor Springs',
      state: 'MI',
    },
    49743: {
      lat: 45.27124,
      lng: -83.89394,
      city: 'Hawks',
      state: 'MI',
    },
    49744: {
      lat: 45.00354,
      lng: -83.66405,
      city: 'Herron',
      state: 'MI',
    },
    49745: { lat: 46.05065, lng: -84.519, city: 'Hessel', state: 'MI' },
    49746: {
      lat: 45.06437,
      lng: -83.95239,
      city: 'Hillman',
      state: 'MI',
    },
    49747: {
      lat: 44.85132,
      lng: -83.64516,
      city: 'Hubbard Lake',
      state: 'MI',
    },
    49748: {
      lat: 46.37491,
      lng: -85.18039,
      city: 'Hulbert',
      state: 'MI',
    },
    49749: {
      lat: 45.41634,
      lng: -84.57891,
      city: 'Indian River',
      state: 'MI',
    },
    49751: {
      lat: 44.99496,
      lng: -84.41309,
      city: 'Johannesburg',
      state: 'MI',
    },
    49752: {
      lat: 46.27629,
      lng: -84.46091,
      city: 'Kinross',
      state: 'MI',
    },
    49753: {
      lat: 45.01566,
      lng: -83.77644,
      city: 'Lachine',
      state: 'MI',
    },
    49755: {
      lat: 45.63886,
      lng: -84.80524,
      city: 'Levering',
      state: 'MI',
    },
    49756: {
      lat: 44.81482,
      lng: -84.27339,
      city: 'Lewiston',
      state: 'MI',
    },
    49757: {
      lat: 45.85933,
      lng: -84.62533,
      city: 'Mackinac Island',
      state: 'MI',
    },
    49759: {
      lat: 45.40545,
      lng: -84.10055,
      city: 'Millersburg',
      state: 'MI',
    },
    49760: {
      lat: 46.05715,
      lng: -84.88881,
      city: 'Moran',
      state: 'MI',
    },
    49762: {
      lat: 46.13126,
      lng: -85.24115,
      city: 'Naubinway',
      state: 'MI',
    },
    49764: { lat: 45.42356, lng: -84.82824, city: 'Oden', state: 'MI' },
    49765: {
      lat: 45.33379,
      lng: -84.25229,
      city: 'Onaway',
      state: 'MI',
    },
    49766: {
      lat: 44.90643,
      lng: -83.4512,
      city: 'Ossineke',
      state: 'MI',
    },
    49768: {
      lat: 46.63217,
      lng: -85.13434,
      city: 'Paradise',
      state: 'MI',
    },
    49769: {
      lat: 45.56797,
      lng: -84.85681,
      city: 'Pellston',
      state: 'MI',
    },
    49770: {
      lat: 45.34396,
      lng: -84.88903,
      city: 'Petoskey',
      state: 'MI',
    },
    49774: {
      lat: 46.16081,
      lng: -84.33798,
      city: 'Pickford',
      state: 'MI',
    },
    49775: {
      lat: 45.76941,
      lng: -84.46333,
      city: 'Pointe Aux Pins',
      state: 'MI',
    },
    49776: { lat: 45.24634, lng: -83.6873, city: 'Posen', state: 'MI' },
    49777: {
      lat: 45.28323,
      lng: -83.49624,
      city: 'Presque Isle',
      state: 'MI',
    },
    49779: {
      lat: 45.39651,
      lng: -83.87153,
      city: 'Rogers City',
      state: 'MI',
    },
    49780: {
      lat: 46.20913,
      lng: -84.6854,
      city: 'Rudyard',
      state: 'MI',
    },
    49781: {
      lat: 45.95272,
      lng: -84.77652,
      city: 'Saint Ignace',
      state: 'MI',
    },
    49782: {
      lat: 45.65547,
      lng: -85.55199,
      city: 'Beaver Island',
      state: 'MI',
    },
    49783: {
      lat: 46.41495,
      lng: -84.28006,
      city: 'Sault Sainte Marie',
      state: 'MI',
    },
    49784: {
      lat: 46.2577,
      lng: -84.45483,
      city: 'Kincheloe',
      state: 'MI',
    },
    49788: {
      lat: 46.26418,
      lng: -84.46779,
      city: 'Kincheloe',
      state: 'MI',
    },
    49791: {
      lat: 45.48093,
      lng: -84.59945,
      city: 'Topinabee',
      state: 'MI',
    },
    49793: {
      lat: 46.21221,
      lng: -85.04417,
      city: 'Trout Lake',
      state: 'MI',
    },
    49795: {
      lat: 45.15835,
      lng: -84.57563,
      city: 'Vanderbilt',
      state: 'MI',
    },
    49796: {
      lat: 45.27407,
      lng: -84.94712,
      city: 'Walloon Lake',
      state: 'MI',
    },
    49799: {
      lat: 45.26597,
      lng: -84.58177,
      city: 'Wolverine',
      state: 'MI',
    },
    49801: {
      lat: 45.9191,
      lng: -88.01795,
      city: 'Iron Mountain',
      state: 'MI',
    },
    49802: {
      lat: 45.80053,
      lng: -88.07718,
      city: 'Kingsford',
      state: 'MI',
    },
    49805: {
      lat: 47.3481,
      lng: -88.38535,
      city: 'Allouez',
      state: 'MI',
    },
    49806: {
      lat: 46.43802,
      lng: -86.90056,
      city: 'Au Train',
      state: 'MI',
    },
    49807: {
      lat: 45.71211,
      lng: -87.28885,
      city: 'Bark River',
      state: 'MI',
    },
    49808: {
      lat: 46.8109,
      lng: -87.8764,
      city: 'Big Bay',
      state: 'MI',
    },
    49812: {
      lat: 45.59094,
      lng: -87.50269,
      city: 'Carney',
      state: 'MI',
    },
    49814: {
      lat: 46.54459,
      lng: -87.94395,
      city: 'Champion',
      state: 'MI',
    },
    49815: {
      lat: 46.18465,
      lng: -87.95967,
      city: 'Channing',
      state: 'MI',
    },
    49816: {
      lat: 46.27517,
      lng: -86.89863,
      city: 'Chatham',
      state: 'MI',
    },
    49817: {
      lat: 45.94711,
      lng: -86.49946,
      city: 'Cooks',
      state: 'MI',
    },
    49818: {
      lat: 45.98223,
      lng: -87.34745,
      city: 'Cornell',
      state: 'MI',
    },
    49820: {
      lat: 46.18965,
      lng: -85.67951,
      city: 'Curtis',
      state: 'MI',
    },
    49821: {
      lat: 45.52167,
      lng: -87.63052,
      city: 'Daggett',
      state: 'MI',
    },
    49822: {
      lat: 46.45095,
      lng: -87.02812,
      city: 'Deerton',
      state: 'MI',
    },
    49825: {
      lat: 46.36039,
      lng: -86.98462,
      city: 'Eben Junction',
      state: 'MI',
    },
    49826: {
      lat: 46.32316,
      lng: -87.04567,
      city: 'Rumely',
      state: 'MI',
    },
    49827: {
      lat: 46.1751,
      lng: -85.53607,
      city: 'Engadine',
      state: 'MI',
    },
    49829: {
      lat: 45.76773,
      lng: -87.16522,
      city: 'Escanaba',
      state: 'MI',
    },
    49831: { lat: 46.05777, lng: -87.7356, city: 'Felch', state: 'MI' },
    49833: {
      lat: 46.27504,
      lng: -87.29142,
      city: 'Little Lake',
      state: 'MI',
    },
    49834: {
      lat: 45.96829,
      lng: -87.70292,
      city: 'Foster City',
      state: 'MI',
    },
    49835: {
      lat: 45.74396,
      lng: -86.57555,
      city: 'Garden',
      state: 'MI',
    },
    49836: {
      lat: 46.1971,
      lng: -85.88725,
      city: 'Germfask',
      state: 'MI',
    },
    49837: {
      lat: 45.87129,
      lng: -87.09781,
      city: 'Gladstone',
      state: 'MI',
    },
    49838: {
      lat: 46.06473,
      lng: -85.75035,
      city: 'Gould City',
      state: 'MI',
    },
    49839: {
      lat: 46.59994,
      lng: -86.11555,
      city: 'Grand Marais',
      state: 'MI',
    },
    49840: {
      lat: 46.13742,
      lng: -86.06009,
      city: 'Gulliver',
      state: 'MI',
    },
    49841: {
      lat: 46.27145,
      lng: -87.49873,
      city: 'Gwinn',
      state: 'MI',
    },
    49847: {
      lat: 45.70585,
      lng: -87.63267,
      city: 'Hermansville',
      state: 'MI',
    },
    49848: {
      lat: 45.37926,
      lng: -87.63576,
      city: 'Ingalls',
      state: 'MI',
    },
    49849: {
      lat: 46.423,
      lng: -87.76797,
      city: 'Ishpeming',
      state: 'MI',
    },
    49852: {
      lat: 45.78399,
      lng: -87.81807,
      city: 'Loretto',
      state: 'MI',
    },
    49853: {
      lat: 46.36112,
      lng: -85.74494,
      city: 'McMillan',
      state: 'MI',
    },
    49854: {
      lat: 46.09581,
      lng: -86.36453,
      city: 'Manistique',
      state: 'MI',
    },
    49855: {
      lat: 46.56603,
      lng: -87.45932,
      city: 'Marquette',
      state: 'MI',
    },
    49858: {
      lat: 45.21989,
      lng: -87.58325,
      city: 'Menominee',
      state: 'MI',
    },
    49861: {
      lat: 46.57639,
      lng: -88.16043,
      city: 'Michigamme',
      state: 'MI',
    },
    49862: {
      lat: 46.38021,
      lng: -86.7153,
      city: 'Munising',
      state: 'MI',
    },
    49863: {
      lat: 45.61295,
      lng: -87.55163,
      city: 'Nadeau',
      state: 'MI',
    },
    49864: {
      lat: 45.84444,
      lng: -86.65104,
      city: 'Nahma',
      state: 'MI',
    },
    49866: {
      lat: 46.54989,
      lng: -87.66246,
      city: 'Negaunee',
      state: 'MI',
    },
    49868: {
      lat: 46.49317,
      lng: -85.50586,
      city: 'Newberry',
      state: 'MI',
    },
    49870: {
      lat: 45.87315,
      lng: -87.89964,
      city: 'Norway',
      state: 'MI',
    },
    49871: {
      lat: 46.42433,
      lng: -87.53282,
      city: 'Palmer',
      state: 'MI',
    },
    49872: {
      lat: 46.01824,
      lng: -87.08222,
      city: 'Perkins',
      state: 'MI',
    },
    49873: {
      lat: 45.876,
      lng: -87.51824,
      city: 'Perronville',
      state: 'MI',
    },
    49874: {
      lat: 45.70394,
      lng: -87.50568,
      city: 'Powers',
      state: 'MI',
    },
    49876: {
      lat: 45.80211,
      lng: -87.97729,
      city: 'Quinnesec',
      state: 'MI',
    },
    49877: { lat: 46.15651, lng: -87.7425, city: 'Ralph', state: 'MI' },
    49878: {
      lat: 45.95852,
      lng: -86.86516,
      city: 'Rapid River',
      state: 'MI',
    },
    49879: {
      lat: 46.3437,
      lng: -88.08432,
      city: 'Republic',
      state: 'MI',
    },
    49880: { lat: 46.11693, lng: -87.22515, city: 'Rock', state: 'MI' },
    49881: {
      lat: 46.07852,
      lng: -88.03259,
      city: 'Sagola',
      state: 'MI',
    },
    49883: { lat: 46.42885, lng: -86.0318, city: 'Seney', state: 'MI' },
    49884: {
      lat: 46.41734,
      lng: -86.35554,
      city: 'Shingleton',
      state: 'MI',
    },
    49885: {
      lat: 46.34658,
      lng: -87.19238,
      city: 'Skandia',
      state: 'MI',
    },
    49886: {
      lat: 45.69748,
      lng: -87.49409,
      city: 'Spalding',
      state: 'MI',
    },
    49887: {
      lat: 45.43104,
      lng: -87.56773,
      city: 'Stephenson',
      state: 'MI',
    },
    49891: {
      lat: 46.23206,
      lng: -87.02828,
      city: 'Trenary',
      state: 'MI',
    },
    49892: {
      lat: 45.77547,
      lng: -87.77243,
      city: 'Vulcan',
      state: 'MI',
    },
    49893: {
      lat: 45.29767,
      lng: -87.59927,
      city: 'Wallace',
      state: 'MI',
    },
    49894: {
      lat: 45.78292,
      lng: -87.07634,
      city: 'Wells',
      state: 'MI',
    },
    49895: {
      lat: 46.16807,
      lng: -86.66593,
      city: 'Wetmore',
      state: 'MI',
    },
    49896: {
      lat: 45.67748,
      lng: -87.38953,
      city: 'Wilson',
      state: 'MI',
    },
    49901: {
      lat: 47.29384,
      lng: -88.39094,
      city: 'Ahmeek',
      state: 'MI',
    },
    49902: {
      lat: 46.04905,
      lng: -88.37744,
      city: 'Alpha',
      state: 'MI',
    },
    49903: {
      lat: 46.33455,
      lng: -88.48496,
      city: 'Amasa',
      state: 'MI',
    },
    49905: {
      lat: 47.10694,
      lng: -88.71395,
      city: 'Atlantic Mine',
      state: 'MI',
    },
    49908: {
      lat: 46.75276,
      lng: -88.57034,
      city: 'Baraga',
      state: 'MI',
    },
    49910: {
      lat: 46.61815,
      lng: -89.54308,
      city: 'Bergland',
      state: 'MI',
    },
    49911: {
      lat: 46.49247,
      lng: -90.05235,
      city: 'Bessemer',
      state: 'MI',
    },
    49912: {
      lat: 46.49393,
      lng: -89.20017,
      city: 'Bruce Crossing',
      state: 'MI',
    },
    49913: {
      lat: 47.23746,
      lng: -88.44994,
      city: 'Calumet',
      state: 'MI',
    },
    49915: {
      lat: 46.06464,
      lng: -88.62708,
      city: 'Caspian',
      state: 'MI',
    },
    49916: {
      lat: 46.97891,
      lng: -88.59519,
      city: 'Chassell',
      state: 'MI',
    },
    49917: {
      lat: 47.28138,
      lng: -88.35908,
      city: 'Copper City',
      state: 'MI',
    },
    49918: {
      lat: 47.43585,
      lng: -87.81238,
      city: 'Copper Harbor',
      state: 'MI',
    },
    49919: {
      lat: 46.50995,
      lng: -88.43518,
      city: 'Covington',
      state: 'MI',
    },
    49920: {
      lat: 46.14087,
      lng: -88.30079,
      city: 'Crystal Falls',
      state: 'MI',
    },
    49921: {
      lat: 47.09084,
      lng: -88.5818,
      city: 'Dodgeville',
      state: 'MI',
    },
    49922: {
      lat: 47.12369,
      lng: -88.49522,
      city: 'Dollar Bay',
      state: 'MI',
    },
    49925: { lat: 46.55088, lng: -89.37681, city: 'Ewen', state: 'MI' },
    49927: {
      lat: 46.04475,
      lng: -88.57108,
      city: 'Gaastra',
      state: 'MI',
    },
    49929: {
      lat: 46.79874,
      lng: -89.09567,
      city: 'Greenland',
      state: 'MI',
    },
    49930: {
      lat: 47.15577,
      lng: -88.53542,
      city: 'Hancock',
      state: 'MI',
    },
    49931: {
      lat: 47.13652,
      lng: -88.6063,
      city: 'Houghton',
      state: 'MI',
    },
    49934: {
      lat: 47.16539,
      lng: -88.43967,
      city: 'Hubbell',
      state: 'MI',
    },
    49935: {
      lat: 46.19493,
      lng: -88.7463,
      city: 'Iron River',
      state: 'MI',
    },
    49938: {
      lat: 46.51493,
      lng: -90.13264,
      city: 'Ironwood',
      state: 'MI',
    },
    49942: {
      lat: 47.26179,
      lng: -88.4175,
      city: 'Kearsarge',
      state: 'MI',
    },
    49945: {
      lat: 47.16956,
      lng: -88.30752,
      city: 'Lake Linden',
      state: 'MI',
    },
    49946: { lat: 46.7189, lng: -88.33725, city: 'Lanse', state: 'MI' },
    49947: {
      lat: 46.42589,
      lng: -89.60536,
      city: 'Marenisco',
      state: 'MI',
    },
    49948: {
      lat: 46.74567,
      lng: -88.99919,
      city: 'Mass City',
      state: 'MI',
    },
    49950: {
      lat: 47.37834,
      lng: -88.12035,
      city: 'Mohawk',
      state: 'MI',
    },
    49952: {
      lat: 46.68309,
      lng: -88.78408,
      city: 'Nisula',
      state: 'MI',
    },
    49953: {
      lat: 46.79328,
      lng: -89.42477,
      city: 'Ontonagon',
      state: 'MI',
    },
    49955: {
      lat: 47.00788,
      lng: -88.69373,
      city: 'Painesdale',
      state: 'MI',
    },
    49958: {
      lat: 46.8165,
      lng: -88.67595,
      city: 'Pelkie',
      state: 'MI',
    },
    49959: {
      lat: 46.46987,
      lng: -89.99803,
      city: 'Ramsay',
      state: 'MI',
    },
    49960: {
      lat: 46.71139,
      lng: -89.25085,
      city: 'Rockland',
      state: 'MI',
    },
    49961: {
      lat: 46.50667,
      lng: -88.74358,
      city: 'Sidnaw',
      state: 'MI',
    },
    49962: {
      lat: 46.82142,
      lng: -88.1409,
      city: 'Skanee',
      state: 'MI',
    },
    49963: {
      lat: 47.04897,
      lng: -88.64002,
      city: 'South Range',
      state: 'MI',
    },
    49965: {
      lat: 46.94752,
      lng: -88.85878,
      city: 'Toivola',
      state: 'MI',
    },
    49967: {
      lat: 46.49377,
      lng: -88.95551,
      city: 'Trout Creek',
      state: 'MI',
    },
    49968: {
      lat: 46.50199,
      lng: -89.88314,
      city: 'Wakefield',
      state: 'MI',
    },
    49969: {
      lat: 46.24608,
      lng: -89.21431,
      city: 'Watersmeet',
      state: 'MI',
    },
    49970: {
      lat: 46.53555,
      lng: -88.59783,
      city: 'Watton',
      state: 'MI',
    },
    49971: {
      lat: 46.71043,
      lng: -89.62866,
      city: 'White Pine',
      state: 'MI',
    },
    50001: {
      lat: 41.3611,
      lng: -93.43321,
      city: 'Ackworth',
      state: 'IA',
    },
    50002: {
      lat: 41.51236,
      lng: -94.64801,
      city: 'Adair',
      state: 'IA',
    },
    50003: { lat: 41.61192, lng: -94.04352, city: 'Adel', state: 'IA' },
    50005: {
      lat: 42.1295,
      lng: -93.02683,
      city: 'Albion',
      state: 'IA',
    },
    50006: {
      lat: 42.51354,
      lng: -93.40227,
      city: 'Alden',
      state: 'IA',
    },
    50007: {
      lat: 41.80365,
      lng: -93.60043,
      city: 'Alleman',
      state: 'IA',
    },
    50008: {
      lat: 40.67847,
      lng: -93.38549,
      city: 'Allerton',
      state: 'IA',
    },
    50009: {
      lat: 41.64313,
      lng: -93.45945,
      city: 'Altoona',
      state: 'IA',
    },
    50010: { lat: 42.03316, lng: -93.58761, city: 'Ames', state: 'IA' },
    50012: { lat: 42.02904, lng: -93.65086, city: 'Ames', state: 'IA' },
    50013: { lat: 42.01732, lng: -93.64809, city: 'Ames', state: 'IA' },
    50014: { lat: 42.04993, lng: -93.69342, city: 'Ames', state: 'IA' },
    50020: {
      lat: 41.43364,
      lng: -94.75215,
      city: 'Anita',
      state: 'IA',
    },
    50021: {
      lat: 41.7207,
      lng: -93.56838,
      city: 'Ankeny',
      state: 'IA',
    },
    50022: {
      lat: 41.41737,
      lng: -95.00352,
      city: 'Atlantic',
      state: 'IA',
    },
    50023: {
      lat: 41.72768,
      lng: -93.63412,
      city: 'Ankeny',
      state: 'IA',
    },
    50025: {
      lat: 41.75628,
      lng: -94.92034,
      city: 'Audubon',
      state: 'IA',
    },
    50026: {
      lat: 41.85823,
      lng: -94.44958,
      city: 'Bagley',
      state: 'IA',
    },
    50027: {
      lat: 41.4739,
      lng: -92.47194,
      city: 'Barnes City',
      state: 'IA',
    },
    50028: {
      lat: 41.81729,
      lng: -93.15102,
      city: 'Baxter',
      state: 'IA',
    },
    50029: {
      lat: 41.82497,
      lng: -94.55075,
      city: 'Bayard',
      state: 'IA',
    },
    50031: {
      lat: 42.03881,
      lng: -94.14003,
      city: 'Beaver',
      state: 'IA',
    },
    50032: {
      lat: 41.66591,
      lng: -93.54301,
      city: 'Berwick',
      state: 'IA',
    },
    50033: {
      lat: 41.36048,
      lng: -93.79416,
      city: 'Bevington',
      state: 'IA',
    },
    50034: {
      lat: 42.52921,
      lng: -93.65438,
      city: 'Blairsburg',
      state: 'IA',
    },
    50035: {
      lat: 41.7248,
      lng: -93.45957,
      city: 'Bondurant',
      state: 'IA',
    },
    50036: { lat: 42.0892, lng: -93.85378, city: 'Boone', state: 'IA' },
    50038: {
      lat: 41.52186,
      lng: -93.90064,
      city: 'Booneville',
      state: 'IA',
    },
    50039: {
      lat: 41.82615,
      lng: -94.00653,
      city: 'Bouton',
      state: 'IA',
    },
    50040: {
      lat: 42.17363,
      lng: -94.10121,
      city: 'Boxholm',
      state: 'IA',
    },
    50041: {
      lat: 42.63429,
      lng: -93.24662,
      city: 'Bradford',
      state: 'IA',
    },
    50042: {
      lat: 41.53326,
      lng: -94.90004,
      city: 'Brayton',
      state: 'IA',
    },
    50044: {
      lat: 41.20247,
      lng: -92.88907,
      city: 'Bussey',
      state: 'IA',
    },
    50046: {
      lat: 41.88974,
      lng: -93.5234,
      city: 'Cambridge',
      state: 'IA',
    },
    50047: {
      lat: 41.47551,
      lng: -93.46556,
      city: 'Carlisle',
      state: 'IA',
    },
    50048: {
      lat: 41.51167,
      lng: -94.51936,
      city: 'Casey',
      state: 'IA',
    },
    50049: {
      lat: 41.03741,
      lng: -93.29566,
      city: 'Chariton',
      state: 'IA',
    },
    50050: {
      lat: 42.16459,
      lng: -94.51054,
      city: 'Churdan',
      state: 'IA',
    },
    50051: {
      lat: 42.13875,
      lng: -93.13736,
      city: 'Clemons',
      state: 'IA',
    },
    50052: { lat: 40.64417, lng: -93.44538, city: 'Clio', state: 'IA' },
    50054: {
      lat: 41.68729,
      lng: -93.23196,
      city: 'Colfax',
      state: 'IA',
    },
    50055: {
      lat: 41.88456,
      lng: -93.2889,
      city: 'Collins',
      state: 'IA',
    },
    50056: { lat: 42.01925, lng: -93.29701, city: 'Colo', state: 'IA' },
    50057: {
      lat: 41.17859,
      lng: -93.14554,
      city: 'Columbia',
      state: 'IA',
    },
    50058: {
      lat: 41.85773,
      lng: -94.69044,
      city: 'Coon Rapids',
      state: 'IA',
    },
    50060: {
      lat: 40.75508,
      lng: -93.31922,
      city: 'Corydon',
      state: 'IA',
    },
    50061: {
      lat: 41.48022,
      lng: -93.79343,
      city: 'Cumming',
      state: 'IA',
    },
    50062: {
      lat: 41.23499,
      lng: -93.24425,
      city: 'Melcher Dallas',
      state: 'IA',
    },
    50063: {
      lat: 41.69669,
      lng: -93.93677,
      city: 'Dallas Center',
      state: 'IA',
    },
    50064: { lat: 42.10671, lng: -94.229, city: 'Dana', state: 'IA' },
    50065: {
      lat: 40.60859,
      lng: -93.78089,
      city: 'Davis City',
      state: 'IA',
    },
    50066: {
      lat: 41.8051,
      lng: -94.22708,
      city: 'Dawson',
      state: 'IA',
    },
    50067: {
      lat: 40.70452,
      lng: -93.85914,
      city: 'Decatur',
      state: 'IA',
    },
    50068: {
      lat: 40.94229,
      lng: -93.46704,
      city: 'Derby',
      state: 'IA',
    },
    50069: {
      lat: 41.52104,
      lng: -94.02993,
      city: 'De Soto',
      state: 'IA',
    },
    50070: {
      lat: 41.45402,
      lng: -94.23745,
      city: 'Dexter',
      state: 'IA',
    },
    50071: { lat: 42.64931, lng: -93.50597, city: 'Dows', state: 'IA' },
    50072: {
      lat: 41.46984,
      lng: -94.12115,
      city: 'Earlham',
      state: 'IA',
    },
    50073: {
      lat: 41.79234,
      lng: -93.52463,
      city: 'Elkhart',
      state: 'IA',
    },
    50074: {
      lat: 40.85685,
      lng: -94.07524,
      city: 'Ellston',
      state: 'IA',
    },
    50075: {
      lat: 42.33516,
      lng: -93.56054,
      city: 'Ellsworth',
      state: 'IA',
    },
    50076: { lat: 41.595, lng: -94.8576, city: 'Exira', state: 'IA' },
    50078: {
      lat: 41.93862,
      lng: -92.8629,
      city: 'Ferguson',
      state: 'IA',
    },
    50101: { lat: 42.66713, lng: -93.63423, city: 'Galt', state: 'IA' },
    50102: {
      lat: 42.24554,
      lng: -93.40022,
      city: 'Garden City',
      state: 'IA',
    },
    50103: {
      lat: 40.79292,
      lng: -93.61079,
      city: 'Garden Grove',
      state: 'IA',
    },
    50104: {
      lat: 41.49458,
      lng: -92.38841,
      city: 'Gibson',
      state: 'IA',
    },
    50105: {
      lat: 42.11754,
      lng: -93.63912,
      city: 'Gilbert',
      state: 'IA',
    },
    50106: {
      lat: 41.88213,
      lng: -92.80749,
      city: 'Gilman',
      state: 'IA',
    },
    50107: {
      lat: 42.03547,
      lng: -94.2191,
      city: 'Grand Junction',
      state: 'IA',
    },
    50108: {
      lat: 40.82479,
      lng: -93.9577,
      city: 'Grand River',
      state: 'IA',
    },
    50109: {
      lat: 41.76802,
      lng: -93.80775,
      city: 'Granger',
      state: 'IA',
    },
    50110: { lat: 41.84424, lng: -94.98612, city: 'Gray', state: 'IA' },
    50111: {
      lat: 41.69353,
      lng: -93.80358,
      city: 'Grimes',
      state: 'IA',
    },
    50112: {
      lat: 41.7319,
      lng: -92.71591,
      city: 'Grinnell',
      state: 'IA',
    },
    50115: {
      lat: 41.68765,
      lng: -94.55501,
      city: 'Guthrie Center',
      state: 'IA',
    },
    50116: {
      lat: 41.17812,
      lng: -92.98247,
      city: 'Hamilton',
      state: 'IA',
    },
    50117: {
      lat: 41.66903,
      lng: -94.85419,
      city: 'Hamlin',
      state: 'IA',
    },
    50118: {
      lat: 41.45957,
      lng: -93.37673,
      city: 'Hartford',
      state: 'IA',
    },
    50119: {
      lat: 41.31089,
      lng: -92.94587,
      city: 'Harvey',
      state: 'IA',
    },
    50120: {
      lat: 41.93373,
      lng: -92.9828,
      city: 'Haverhill',
      state: 'IA',
    },
    50122: {
      lat: 42.30218,
      lng: -93.3087,
      city: 'Hubbard',
      state: 'IA',
    },
    50123: {
      lat: 40.83938,
      lng: -93.50634,
      city: 'Humeston',
      state: 'IA',
    },
    50124: {
      lat: 41.88516,
      lng: -93.60446,
      city: 'Huxley',
      state: 'IA',
    },
    50125: {
      lat: 41.33286,
      lng: -93.57957,
      city: 'Indianola',
      state: 'IA',
    },
    50126: {
      lat: 42.50875,
      lng: -93.24815,
      city: 'Iowa Falls',
      state: 'IA',
    },
    50127: { lat: 41.78122, lng: -93.21129, city: 'Ira', state: 'IA' },
    50128: {
      lat: 41.85173,
      lng: -94.29853,
      city: 'Jamaica',
      state: 'IA',
    },
    50129: {
      lat: 42.01335,
      lng: -94.3764,
      city: 'Jefferson',
      state: 'IA',
    },
    50130: {
      lat: 42.30074,
      lng: -93.67331,
      city: 'Jewell',
      state: 'IA',
    },
    50131: {
      lat: 41.69749,
      lng: -93.72021,
      city: 'Johnston',
      state: 'IA',
    },
    50132: {
      lat: 42.39392,
      lng: -93.68256,
      city: 'Kamrar',
      state: 'IA',
    },
    50133: {
      lat: 40.71592,
      lng: -94.07576,
      city: 'Kellerton',
      state: 'IA',
    },
    50134: {
      lat: 41.93992,
      lng: -93.67429,
      city: 'Kelley',
      state: 'IA',
    },
    50135: {
      lat: 41.7475,
      lng: -92.89096,
      city: 'Kellogg',
      state: 'IA',
    },
    50136: {
      lat: 41.46984,
      lng: -92.28927,
      city: 'Keswick',
      state: 'IA',
    },
    50137: {
      lat: 41.60872,
      lng: -92.92126,
      city: 'Killduff',
      state: 'IA',
    },
    50138: {
      lat: 41.30246,
      lng: -93.10534,
      city: 'Knoxville',
      state: 'IA',
    },
    50139: {
      lat: 41.19237,
      lng: -93.36683,
      city: 'Lacona',
      state: 'IA',
    },
    50140: {
      lat: 40.63753,
      lng: -93.99198,
      city: 'Lamoni',
      state: 'IA',
    },
    50141: {
      lat: 41.86889,
      lng: -92.95634,
      city: 'Laurel',
      state: 'IA',
    },
    50142: {
      lat: 42.0055,
      lng: -92.77582,
      city: 'Le Grand',
      state: 'IA',
    },
    50143: {
      lat: 41.34245,
      lng: -92.79893,
      city: 'Leighton',
      state: 'IA',
    },
    50144: { lat: 40.73327, lng: -93.73615, city: 'Leon', state: 'IA' },
    50145: {
      lat: 41.20387,
      lng: -93.48515,
      city: 'Liberty Center',
      state: 'IA',
    },
    50146: {
      lat: 41.68038,
      lng: -94.24905,
      city: 'Linden',
      state: 'IA',
    },
    50147: {
      lat: 40.62381,
      lng: -93.54597,
      city: 'Lineville',
      state: 'IA',
    },
    50148: {
      lat: 42.18142,
      lng: -92.99567,
      city: 'Liscomb',
      state: 'IA',
    },
    50149: {
      lat: 41.1512,
      lng: -94.08194,
      city: 'Lorimor',
      state: 'IA',
    },
    50150: {
      lat: 41.12771,
      lng: -92.97404,
      city: 'Lovilia',
      state: 'IA',
    },
    50151: {
      lat: 41.08074,
      lng: -93.49717,
      city: 'Lucas',
      state: 'IA',
    },
    50152: {
      lat: 41.96873,
      lng: -93.81765,
      city: 'Luther',
      state: 'IA',
    },
    50153: {
      lat: 41.57669,
      lng: -92.80033,
      city: 'Lynnville',
      state: 'IA',
    },
    50154: {
      lat: 42.17625,
      lng: -93.39068,
      city: 'McCallsburg',
      state: 'IA',
    },
    50155: {
      lat: 41.21288,
      lng: -94.19713,
      city: 'Macksburg',
      state: 'IA',
    },
    50156: {
      lat: 41.90349,
      lng: -93.80001,
      city: 'Madrid',
      state: 'IA',
    },
    50157: {
      lat: 41.74545,
      lng: -92.56183,
      city: 'Malcom',
      state: 'IA',
    },
    50158: {
      lat: 42.05016,
      lng: -92.90143,
      city: 'Marshalltown',
      state: 'IA',
    },
    50160: {
      lat: 41.37385,
      lng: -93.7387,
      city: 'Martensdale',
      state: 'IA',
    },
    50161: {
      lat: 41.84764,
      lng: -93.39914,
      city: 'Maxwell',
      state: 'IA',
    },
    50162: {
      lat: 41.92563,
      lng: -93.06781,
      city: 'Melbourne',
      state: 'IA',
    },
    50163: {
      lat: 41.2223,
      lng: -93.24101,
      city: 'Melcher Dallas',
      state: 'IA',
    },
    50164: {
      lat: 41.49921,
      lng: -94.42176,
      city: 'Menlo',
      state: 'IA',
    },
    50165: {
      lat: 40.84808,
      lng: -93.30937,
      city: 'Millerton',
      state: 'IA',
    },
    50166: { lat: 41.28289, lng: -93.42629, city: 'Milo', state: 'IA' },
    50167: {
      lat: 41.74613,
      lng: -94.04871,
      city: 'Minburn',
      state: 'IA',
    },
    50168: {
      lat: 41.77725,
      lng: -93.27248,
      city: 'Mingo',
      state: 'IA',
    },
    50169: {
      lat: 41.65617,
      lng: -93.35062,
      city: 'Mitchellville',
      state: 'IA',
    },
    50170: {
      lat: 41.52911,
      lng: -93.11546,
      city: 'Monroe',
      state: 'IA',
    },
    50171: {
      lat: 41.58434,
      lng: -92.52584,
      city: 'Montezuma',
      state: 'IA',
    },
    50173: {
      lat: 41.97461,
      lng: -92.71044,
      city: 'Montour',
      state: 'IA',
    },
    50174: {
      lat: 41.03723,
      lng: -93.96167,
      city: 'Murray',
      state: 'IA',
    },
    50201: {
      lat: 42.03337,
      lng: -93.44434,
      city: 'Nevada',
      state: 'IA',
    },
    50206: {
      lat: 42.25018,
      lng: -93.18941,
      city: 'New Providence',
      state: 'IA',
    },
    50207: {
      lat: 41.43344,
      lng: -92.6381,
      city: 'New Sharon',
      state: 'IA',
    },
    50208: {
      lat: 41.71129,
      lng: -93.03924,
      city: 'Newton',
      state: 'IA',
    },
    50210: {
      lat: 41.18998,
      lng: -93.6978,
      city: 'New Virginia',
      state: 'IA',
    },
    50211: {
      lat: 41.45614,
      lng: -93.6887,
      city: 'Norwalk',
      state: 'IA',
    },
    50212: {
      lat: 42.03515,
      lng: -94.05996,
      city: 'Ogden',
      state: 'IA',
    },
    50213: {
      lat: 41.03292,
      lng: -93.76557,
      city: 'Osceola',
      state: 'IA',
    },
    50214: {
      lat: 41.46246,
      lng: -93.05528,
      city: 'Otley',
      state: 'IA',
    },
    50216: {
      lat: 41.69798,
      lng: -94.35023,
      city: 'Panora',
      state: 'IA',
    },
    50217: {
      lat: 42.17206,
      lng: -94.24984,
      city: 'Paton',
      state: 'IA',
    },
    50218: {
      lat: 41.34844,
      lng: -93.87755,
      city: 'Patterson',
      state: 'IA',
    },
    50219: {
      lat: 41.42243,
      lng: -92.90132,
      city: 'Pella',
      state: 'IA',
    },
    50220: { lat: 41.8366, lng: -94.12293, city: 'Perry', state: 'IA' },
    50222: { lat: 41.21701, lng: -93.95369, city: 'Peru', state: 'IA' },
    50223: {
      lat: 42.17117,
      lng: -94.02831,
      city: 'Pilot Mound',
      state: 'IA',
    },
    50225: {
      lat: 41.3823,
      lng: -93.27103,
      city: 'Pleasantville',
      state: 'IA',
    },
    50226: {
      lat: 41.79716,
      lng: -93.70566,
      city: 'Polk City',
      state: 'IA',
    },
    50227: {
      lat: 42.59388,
      lng: -93.42818,
      city: 'Popejoy',
      state: 'IA',
    },
    50228: {
      lat: 41.57008,
      lng: -93.24154,
      city: 'Prairie City',
      state: 'IA',
    },
    50229: {
      lat: 41.37416,
      lng: -93.77906,
      city: 'Prole',
      state: 'IA',
    },
    50230: {
      lat: 42.30655,
      lng: -93.44718,
      city: 'Radcliffe',
      state: 'IA',
    },
    50231: {
      lat: 42.23765,
      lng: -93.60257,
      city: 'Randall',
      state: 'IA',
    },
    50232: {
      lat: 41.55761,
      lng: -92.96868,
      city: 'Reasnor',
      state: 'IA',
    },
    50233: {
      lat: 41.61221,
      lng: -94.21095,
      city: 'Redfield',
      state: 'IA',
    },
    50234: {
      lat: 41.89165,
      lng: -93.17916,
      city: 'Rhodes',
      state: 'IA',
    },
    50235: {
      lat: 41.92974,
      lng: -94.20873,
      city: 'Rippey',
      state: 'IA',
    },
    50236: { lat: 42.1675, lng: -93.4834, city: 'Roland', state: 'IA' },
    50237: {
      lat: 41.54238,
      lng: -93.36859,
      city: 'Runnells',
      state: 'IA',
    },
    50238: {
      lat: 40.97124,
      lng: -93.17502,
      city: 'Russell',
      state: 'IA',
    },
    50239: {
      lat: 42.14431,
      lng: -93.20144,
      city: 'Saint Anthony',
      state: 'IA',
    },
    50240: {
      lat: 41.29368,
      lng: -93.796,
      city: 'Saint Charles',
      state: 'IA',
    },
    50241: {
      lat: 41.30771,
      lng: -93.73368,
      city: 'Saint Marys',
      state: 'IA',
    },
    50242: {
      lat: 41.56332,
      lng: -92.69428,
      city: 'Searsboro',
      state: 'IA',
    },
    50243: {
      lat: 41.86457,
      lng: -93.6967,
      city: 'Sheldahl',
      state: 'IA',
    },
    50244: {
      lat: 41.85083,
      lng: -93.65126,
      city: 'Slater',
      state: 'IA',
    },
    50246: {
      lat: 42.28411,
      lng: -93.78198,
      city: 'Stanhope',
      state: 'IA',
    },
    50247: {
      lat: 42.01832,
      lng: -93.1665,
      city: 'State Center',
      state: 'IA',
    },
    50248: {
      lat: 42.19256,
      lng: -93.61297,
      city: 'Story City',
      state: 'IA',
    },
    50249: {
      lat: 42.26991,
      lng: -93.89973,
      city: 'Stratford',
      state: 'IA',
    },
    50250: {
      lat: 41.48088,
      lng: -94.34062,
      city: 'Stuart',
      state: 'IA',
    },
    50251: {
      lat: 41.56883,
      lng: -92.87007,
      city: 'Sully',
      state: 'IA',
    },
    50252: { lat: 41.45881, lng: -93.31518, city: 'Swan', state: 'IA' },
    50254: {
      lat: 40.99045,
      lng: -94.06917,
      city: 'Thayer',
      state: 'IA',
    },
    50255: {
      lat: 41.45593,
      lng: -92.33207,
      city: 'Thornburg',
      state: 'IA',
    },
    50256: { lat: 41.2664, lng: -92.91114, city: 'Tracy', state: 'IA' },
    50257: {
      lat: 41.19092,
      lng: -93.85048,
      city: 'Truro',
      state: 'IA',
    },
    50258: {
      lat: 42.22781,
      lng: -93.07788,
      city: 'Union',
      state: 'IA',
    },
    50261: {
      lat: 41.47014,
      lng: -93.92286,
      city: 'Van Meter',
      state: 'IA',
    },
    50262: {
      lat: 40.85625,
      lng: -93.82091,
      city: 'Van Wert',
      state: 'IA',
    },
    50263: {
      lat: 41.60391,
      lng: -93.87989,
      city: 'Waukee',
      state: 'IA',
    },
    50264: {
      lat: 40.89919,
      lng: -93.70071,
      city: 'Weldon',
      state: 'IA',
    },
    50265: {
      lat: 41.55129,
      lng: -93.73821,
      city: 'West Des Moines',
      state: 'IA',
    },
    50266: {
      lat: 41.56323,
      lng: -93.81107,
      city: 'West Des Moines',
      state: 'IA',
    },
    50268: {
      lat: 41.40695,
      lng: -92.36227,
      city: 'What Cheer',
      state: 'IA',
    },
    50269: {
      lat: 42.26236,
      lng: -93.01064,
      city: 'Whitten',
      state: 'IA',
    },
    50271: {
      lat: 42.48444,
      lng: -93.54489,
      city: 'Williams',
      state: 'IA',
    },
    50272: {
      lat: 41.08809,
      lng: -93.25682,
      city: 'Williamson',
      state: 'IA',
    },
    50273: {
      lat: 41.32838,
      lng: -94.05606,
      city: 'Winterset',
      state: 'IA',
    },
    50274: {
      lat: 41.38261,
      lng: -94.85756,
      city: 'Wiota',
      state: 'IA',
    },
    50275: {
      lat: 41.04375,
      lng: -93.58852,
      city: 'Woodburn',
      state: 'IA',
    },
    50276: {
      lat: 41.85997,
      lng: -93.923,
      city: 'Woodward',
      state: 'IA',
    },
    50277: { lat: 41.77867, lng: -94.347, city: 'Yale', state: 'IA' },
    50278: {
      lat: 42.1528,
      lng: -93.29926,
      city: 'Zearing',
      state: 'IA',
    },
    50309: {
      lat: 41.58448,
      lng: -93.62152,
      city: 'Des Moines',
      state: 'IA',
    },
    50310: {
      lat: 41.62777,
      lng: -93.67297,
      city: 'Des Moines',
      state: 'IA',
    },
    50311: {
      lat: 41.601,
      lng: -93.67335,
      city: 'Des Moines',
      state: 'IA',
    },
    50312: {
      lat: 41.58064,
      lng: -93.67622,
      city: 'Des Moines',
      state: 'IA',
    },
    50313: {
      lat: 41.65538,
      lng: -93.62287,
      city: 'Des Moines',
      state: 'IA',
    },
    50314: {
      lat: 41.6049,
      lng: -93.63102,
      city: 'Des Moines',
      state: 'IA',
    },
    50315: {
      lat: 41.5449,
      lng: -93.62063,
      city: 'Des Moines',
      state: 'IA',
    },
    50316: {
      lat: 41.60877,
      lng: -93.59775,
      city: 'Des Moines',
      state: 'IA',
    },
    50317: {
      lat: 41.61572,
      lng: -93.54881,
      city: 'Des Moines',
      state: 'IA',
    },
    50319: {
      lat: 41.59115,
      lng: -93.60314,
      city: 'Des Moines',
      state: 'IA',
    },
    50320: {
      lat: 41.53208,
      lng: -93.57217,
      city: 'Des Moines',
      state: 'IA',
    },
    50321: {
      lat: 41.53997,
      lng: -93.67184,
      city: 'Des Moines',
      state: 'IA',
    },
    50322: {
      lat: 41.63383,
      lng: -93.73589,
      city: 'Urbandale',
      state: 'IA',
    },
    50323: {
      lat: 41.63894,
      lng: -93.8185,
      city: 'Urbandale',
      state: 'IA',
    },
    50324: {
      lat: 41.60471,
      lng: -93.71273,
      city: 'Windsor Heights',
      state: 'IA',
    },
    50325: {
      lat: 41.61475,
      lng: -93.79864,
      city: 'Clive',
      state: 'IA',
    },
    50327: {
      lat: 41.58402,
      lng: -93.48856,
      city: 'Pleasant Hill',
      state: 'IA',
    },
    50401: {
      lat: 43.15389,
      lng: -93.20006,
      city: 'Mason City',
      state: 'IA',
    },
    50420: {
      lat: 42.80621,
      lng: -93.45677,
      city: 'Alexander',
      state: 'IA',
    },
    50421: {
      lat: 42.84246,
      lng: -93.62148,
      city: 'Belmond',
      state: 'IA',
    },
    50423: {
      lat: 43.11054,
      lng: -93.81244,
      city: 'Britt',
      state: 'IA',
    },
    50424: {
      lat: 43.40366,
      lng: -93.94939,
      city: 'Buffalo Center',
      state: 'IA',
    },
    50426: {
      lat: 43.41507,
      lng: -93.01718,
      city: 'Carpenter',
      state: 'IA',
    },
    50427: {
      lat: 42.83557,
      lng: -93.22116,
      city: 'Chapin',
      state: 'IA',
    },
    50428: {
      lat: 43.13573,
      lng: -93.38815,
      city: 'Clear Lake',
      state: 'IA',
    },
    50430: {
      lat: 42.97734,
      lng: -93.95653,
      city: 'Corwith',
      state: 'IA',
    },
    50431: {
      lat: 42.73648,
      lng: -93.36989,
      city: 'Coulter',
      state: 'IA',
    },
    50432: {
      lat: 43.22394,
      lng: -93.79794,
      city: 'Crystal Lake',
      state: 'IA',
    },
    50433: {
      lat: 42.92852,
      lng: -93.04774,
      city: 'Dougherty',
      state: 'IA',
    },
    50434: {
      lat: 43.25857,
      lng: -93.45758,
      city: 'Fertile',
      state: 'IA',
    },
    50435: {
      lat: 43.15752,
      lng: -92.76877,
      city: 'Floyd',
      state: 'IA',
    },
    50436: {
      lat: 43.26256,
      lng: -93.66242,
      city: 'Forest City',
      state: 'IA',
    },
    50438: {
      lat: 43.11018,
      lng: -93.61841,
      city: 'Garner',
      state: 'IA',
    },
    50439: {
      lat: 42.94578,
      lng: -93.6345,
      city: 'Goodell',
      state: 'IA',
    },
    50440: {
      lat: 43.33213,
      lng: -93.06891,
      city: 'Grafton',
      state: 'IA',
    },
    50441: {
      lat: 42.73938,
      lng: -93.22146,
      city: 'Hampton',
      state: 'IA',
    },
    50444: {
      lat: 43.29258,
      lng: -93.38601,
      city: 'Hanlontown',
      state: 'IA',
    },
    50446: {
      lat: 43.33821,
      lng: -93.45394,
      city: 'Joice',
      state: 'IA',
    },
    50447: {
      lat: 42.92719,
      lng: -93.80118,
      city: 'Kanawha',
      state: 'IA',
    },
    50448: {
      lat: 43.35053,
      lng: -93.24753,
      city: 'Kensett',
      state: 'IA',
    },
    50449: {
      lat: 43.00129,
      lng: -93.55629,
      city: 'Klemme',
      state: 'IA',
    },
    50450: {
      lat: 43.42045,
      lng: -93.51998,
      city: 'Lake Mills',
      state: 'IA',
    },
    50451: {
      lat: 43.37746,
      lng: -94.09134,
      city: 'Lakota',
      state: 'IA',
    },
    50452: {
      lat: 42.77969,
      lng: -93.37542,
      city: 'Latimer',
      state: 'IA',
    },
    50453: {
      lat: 43.3664,
      lng: -93.65258,
      city: 'Leland',
      state: 'IA',
    },
    50454: {
      lat: 43.38505,
      lng: -92.73935,
      city: 'Little Cedar',
      state: 'IA',
    },
    50455: {
      lat: 43.45695,
      lng: -92.64981,
      city: 'McIntire',
      state: 'IA',
    },
    50456: {
      lat: 43.28812,
      lng: -93.21579,
      city: 'Manly',
      state: 'IA',
    },
    50457: {
      lat: 42.91418,
      lng: -93.4928,
      city: 'Meservey',
      state: 'IA',
    },
    50458: {
      lat: 43.15969,
      lng: -93.00609,
      city: 'Nora Springs',
      state: 'IA',
    },
    50459: {
      lat: 43.4458,
      lng: -93.25445,
      city: 'Northwood',
      state: 'IA',
    },
    50460: {
      lat: 43.2259,
      lng: -92.6902,
      city: 'Orchard',
      state: 'IA',
    },
    50461: {
      lat: 43.29995,
      lng: -92.81412,
      city: 'Osage',
      state: 'IA',
    },
    50464: {
      lat: 43.25146,
      lng: -93.0765,
      city: 'Plymouth',
      state: 'IA',
    },
    50465: { lat: 43.48703, lng: -93.91056, city: 'Rake', state: 'IA' },
    50466: {
      lat: 43.38782,
      lng: -92.53815,
      city: 'Riceville',
      state: 'IA',
    },
    50467: {
      lat: 43.20916,
      lng: -93.06608,
      city: 'Rock Falls',
      state: 'IA',
    },
    50468: {
      lat: 43.04631,
      lng: -92.95703,
      city: 'Rockford',
      state: 'IA',
    },
    50469: {
      lat: 42.99922,
      lng: -93.19767,
      city: 'Rockwell',
      state: 'IA',
    },
    50470: {
      lat: 42.74652,
      lng: -93.55374,
      city: 'Rowan',
      state: 'IA',
    },
    50471: { lat: 43.16486, lng: -92.87834, city: 'Rudd', state: 'IA' },
    50472: {
      lat: 43.42265,
      lng: -92.94644,
      city: 'Saint Ansgar',
      state: 'IA',
    },
    50473: {
      lat: 43.46402,
      lng: -93.67429,
      city: 'Scarville',
      state: 'IA',
    },
    50475: {
      lat: 42.87902,
      lng: -93.21852,
      city: 'Sheffield',
      state: 'IA',
    },
    50476: {
      lat: 43.4527,
      lng: -92.76311,
      city: 'Stacyville',
      state: 'IA',
    },
    50477: {
      lat: 42.98743,
      lng: -93.32731,
      city: 'Swaledale',
      state: 'IA',
    },
    50478: {
      lat: 43.40377,
      lng: -93.78448,
      city: 'Thompson',
      state: 'IA',
    },
    50479: {
      lat: 42.94121,
      lng: -93.4082,
      city: 'Thornton',
      state: 'IA',
    },
    50480: {
      lat: 43.25097,
      lng: -94.05272,
      city: 'Titonka',
      state: 'IA',
    },
    50482: {
      lat: 43.12356,
      lng: -93.48167,
      city: 'Ventura',
      state: 'IA',
    },
    50483: {
      lat: 43.11174,
      lng: -93.99719,
      city: 'Wesley',
      state: 'IA',
    },
    50484: {
      lat: 43.23609,
      lng: -93.91084,
      city: 'Woden',
      state: 'IA',
    },
    50501: {
      lat: 42.49244,
      lng: -94.18967,
      city: 'Fort Dodge',
      state: 'IA',
    },
    50510: {
      lat: 42.76084,
      lng: -94.98216,
      city: 'Albert City',
      state: 'IA',
    },
    50511: {
      lat: 43.07483,
      lng: -94.21894,
      city: 'Algona',
      state: 'IA',
    },
    50514: {
      lat: 43.41374,
      lng: -94.47361,
      city: 'Armstrong',
      state: 'IA',
    },
    50515: {
      lat: 43.01993,
      lng: -94.87812,
      city: 'Ayrshire',
      state: 'IA',
    },
    50516: {
      lat: 42.62566,
      lng: -94.13545,
      city: 'Badger',
      state: 'IA',
    },
    50517: {
      lat: 43.30099,
      lng: -94.23452,
      city: 'Bancroft',
      state: 'IA',
    },
    50518: {
      lat: 42.51212,
      lng: -94.38241,
      city: 'Barnum',
      state: 'IA',
    },
    50519: { lat: 42.90314, lng: -94.26542, city: 'Bode', state: 'IA' },
    50520: {
      lat: 42.80524,
      lng: -94.39772,
      city: 'Bradgate',
      state: 'IA',
    },
    50521: {
      lat: 42.3462,
      lng: -94.10276,
      city: 'Burnside',
      state: 'IA',
    },
    50522: { lat: 43.18562, lng: -94.18576, city: 'Burt', state: 'IA' },
    50523: {
      lat: 42.36783,
      lng: -94.29357,
      city: 'Callender',
      state: 'IA',
    },
    50524: {
      lat: 42.59992,
      lng: -94.35799,
      city: 'Clare',
      state: 'IA',
    },
    50525: {
      lat: 42.73875,
      lng: -93.74907,
      city: 'Clarion',
      state: 'IA',
    },
    50527: {
      lat: 42.96899,
      lng: -94.79288,
      city: 'Curlew',
      state: 'IA',
    },
    50528: {
      lat: 43.13717,
      lng: -94.54023,
      city: 'Cylinder',
      state: 'IA',
    },
    50529: {
      lat: 42.72216,
      lng: -94.20101,
      city: 'Dakota City',
      state: 'IA',
    },
    50530: {
      lat: 42.27021,
      lng: -94.04173,
      city: 'Dayton',
      state: 'IA',
    },
    50531: {
      lat: 43.45841,
      lng: -94.61417,
      city: 'Dolliver',
      state: 'IA',
    },
    50532: {
      lat: 42.46212,
      lng: -94.00175,
      city: 'Duncombe',
      state: 'IA',
    },
    50533: {
      lat: 42.65251,
      lng: -93.91109,
      city: 'Eagle Grove',
      state: 'IA',
    },
    50535: {
      lat: 42.45138,
      lng: -95.16167,
      city: 'Early',
      state: 'IA',
    },
    50536: {
      lat: 43.11605,
      lng: -94.69716,
      city: 'Emmetsburg',
      state: 'IA',
    },
    50538: {
      lat: 42.28422,
      lng: -94.43024,
      city: 'Farnhamville',
      state: 'IA',
    },
    50539: {
      lat: 43.23543,
      lng: -94.41849,
      city: 'Fenton',
      state: 'IA',
    },
    50540: { lat: 42.60019, lng: -94.8407, city: 'Fonda', state: 'IA' },
    50541: {
      lat: 42.71569,
      lng: -94.44715,
      city: 'Gilmore City',
      state: 'IA',
    },
    50542: {
      lat: 42.7794,
      lng: -93.94594,
      city: 'Goldfield',
      state: 'IA',
    },
    50543: {
      lat: 42.27434,
      lng: -94.30666,
      city: 'Gowrie',
      state: 'IA',
    },
    50544: {
      lat: 42.2537,
      lng: -94.17042,
      city: 'Harcourt',
      state: 'IA',
    },
    50545: {
      lat: 42.79651,
      lng: -94.08111,
      city: 'Hardy',
      state: 'IA',
    },
    50546: {
      lat: 42.84388,
      lng: -94.70347,
      city: 'Havelock',
      state: 'IA',
    },
    50548: {
      lat: 42.71275,
      lng: -94.22645,
      city: 'Humboldt',
      state: 'IA',
    },
    50551: {
      lat: 42.48242,
      lng: -94.75484,
      city: 'Jolley',
      state: 'IA',
    },
    50552: {
      lat: 42.45638,
      lng: -94.46068,
      city: 'Knierim',
      state: 'IA',
    },
    50554: {
      lat: 42.84648,
      lng: -94.84118,
      city: 'Laurens',
      state: 'IA',
    },
    50556: {
      lat: 43.45276,
      lng: -94.20114,
      city: 'Ledyard',
      state: 'IA',
    },
    50557: {
      lat: 42.35544,
      lng: -94.04409,
      city: 'Lehigh',
      state: 'IA',
    },
    50558: {
      lat: 42.86717,
      lng: -94.16607,
      city: 'Livermore',
      state: 'IA',
    },
    50559: {
      lat: 43.20912,
      lng: -94.32477,
      city: 'Lone Rock',
      state: 'IA',
    },
    50560: {
      lat: 42.95581,
      lng: -94.1043,
      city: 'Lu Verne',
      state: 'IA',
    },
    50561: {
      lat: 42.42122,
      lng: -94.84794,
      city: 'Lytton',
      state: 'IA',
    },
    50562: {
      lat: 42.96158,
      lng: -94.64173,
      city: 'Mallard',
      state: 'IA',
    },
    50563: {
      lat: 42.52802,
      lng: -94.52515,
      city: 'Manson',
      state: 'IA',
    },
    50565: {
      lat: 42.86118,
      lng: -94.99698,
      city: 'Marathon',
      state: 'IA',
    },
    50566: {
      lat: 42.43925,
      lng: -94.32889,
      city: 'Moorland',
      state: 'IA',
    },
    50567: {
      lat: 42.52146,
      lng: -95.08935,
      city: 'Nemaha',
      state: 'IA',
    },
    50568: {
      lat: 42.62171,
      lng: -94.99395,
      city: 'Newell',
      state: 'IA',
    },
    50569: { lat: 42.4032, lng: -94.13841, city: 'Otho', state: 'IA' },
    50570: {
      lat: 42.89359,
      lng: -94.36585,
      city: 'Ottosen',
      state: 'IA',
    },
    50571: {
      lat: 42.63485,
      lng: -94.5806,
      city: 'Palmer',
      state: 'IA',
    },
    50573: {
      lat: 42.8768,
      lng: -94.62255,
      city: 'Plover',
      state: 'IA',
    },
    50574: {
      lat: 42.71891,
      lng: -94.69967,
      city: 'Pocahontas',
      state: 'IA',
    },
    50575: {
      lat: 42.5588,
      lng: -94.70065,
      city: 'Pomeroy',
      state: 'IA',
    },
    50576: {
      lat: 42.80267,
      lng: -95.17727,
      city: 'Rembrandt',
      state: 'IA',
    },
    50577: {
      lat: 42.85774,
      lng: -93.97902,
      city: 'Renwick',
      state: 'IA',
    },
    50578: {
      lat: 43.30081,
      lng: -94.54775,
      city: 'Ringsted',
      state: 'IA',
    },
    50579: {
      lat: 42.39356,
      lng: -94.6345,
      city: 'Rockwell City',
      state: 'IA',
    },
    50581: {
      lat: 42.84735,
      lng: -94.54506,
      city: 'Rolfe',
      state: 'IA',
    },
    50582: {
      lat: 42.79338,
      lng: -94.30217,
      city: 'Rutland',
      state: 'IA',
    },
    50583: {
      lat: 42.43049,
      lng: -94.98595,
      city: 'Sac City',
      state: 'IA',
    },
    50585: {
      lat: 42.91154,
      lng: -95.1407,
      city: 'Sioux Rapids',
      state: 'IA',
    },
    50586: {
      lat: 42.40472,
      lng: -94.43204,
      city: 'Somers',
      state: 'IA',
    },
    50588: {
      lat: 42.65777,
      lng: -95.16391,
      city: 'Storm Lake',
      state: 'IA',
    },
    50590: {
      lat: 43.40647,
      lng: -94.30303,
      city: 'Swea City',
      state: 'IA',
    },
    50591: { lat: 42.67683, lng: -94.05478, city: 'Thor', state: 'IA' },
    50592: {
      lat: 42.72927,
      lng: -95.18345,
      city: 'Truesdale',
      state: 'IA',
    },
    50593: {
      lat: 42.65796,
      lng: -94.89832,
      city: 'Varina',
      state: 'IA',
    },
    50594: {
      lat: 42.58561,
      lng: -94.03949,
      city: 'Vincent',
      state: 'IA',
    },
    50595: {
      lat: 42.46097,
      lng: -93.82154,
      city: 'Webster City',
      state: 'IA',
    },
    50597: {
      lat: 42.97379,
      lng: -94.45048,
      city: 'West Bend',
      state: 'IA',
    },
    50598: {
      lat: 43.08023,
      lng: -94.41841,
      city: 'Whittemore',
      state: 'IA',
    },
    50599: {
      lat: 42.58089,
      lng: -93.81471,
      city: 'Woolstock',
      state: 'IA',
    },
    50601: {
      lat: 42.56612,
      lng: -93.05665,
      city: 'Ackley',
      state: 'IA',
    },
    50602: {
      lat: 42.74036,
      lng: -92.80581,
      city: 'Allison',
      state: 'IA',
    },
    50603: {
      lat: 43.18893,
      lng: -92.45834,
      city: 'Alta Vista',
      state: 'IA',
    },
    50604: {
      lat: 42.60484,
      lng: -92.90299,
      city: 'Aplington',
      state: 'IA',
    },
    50605: {
      lat: 42.83559,
      lng: -93.03276,
      city: 'Aredale',
      state: 'IA',
    },
    50606: {
      lat: 42.74669,
      lng: -91.68299,
      city: 'Arlington',
      state: 'IA',
    },
    50607: {
      lat: 42.60872,
      lng: -91.74747,
      city: 'Aurora',
      state: 'IA',
    },
    50608: {
      lat: 42.58815,
      lng: -92.96075,
      city: 'Austinville',
      state: 'IA',
    },
    50609: {
      lat: 42.23066,
      lng: -92.81046,
      city: 'Beaman',
      state: 'IA',
    },
    50611: {
      lat: 42.8193,
      lng: -92.91114,
      city: 'Bristow',
      state: 'IA',
    },
    50612: {
      lat: 42.28546,
      lng: -92.38687,
      city: 'Buckingham',
      state: 'IA',
    },
    50613: {
      lat: 42.53358,
      lng: -92.49069,
      city: 'Cedar Falls',
      state: 'IA',
    },
    50616: {
      lat: 43.07978,
      lng: -92.66104,
      city: 'Charles City',
      state: 'IA',
    },
    50619: {
      lat: 42.79725,
      lng: -92.66266,
      city: 'Clarksville',
      state: 'IA',
    },
    50620: {
      lat: 43.15712,
      lng: -92.5912,
      city: 'Colwell',
      state: 'IA',
    },
    50621: {
      lat: 42.24738,
      lng: -92.91904,
      city: 'Conrad',
      state: 'IA',
    },
    50622: {
      lat: 42.67358,
      lng: -92.33358,
      city: 'Denver',
      state: 'IA',
    },
    50623: {
      lat: 42.52693,
      lng: -92.20962,
      city: 'Dewar',
      state: 'IA',
    },
    50624: { lat: 42.46874, lng: -92.65879, city: 'Dike', state: 'IA' },
    50625: {
      lat: 42.75071,
      lng: -92.98691,
      city: 'Dumont',
      state: 'IA',
    },
    50626: {
      lat: 42.58028,
      lng: -92.17049,
      city: 'Dunkerton',
      state: 'IA',
    },
    50627: { lat: 42.34982, lng: -93.095, city: 'Eldora', state: 'IA' },
    50628: { lat: 43.26655, lng: -92.39134, city: 'Elma', state: 'IA' },
    50629: {
      lat: 42.64222,
      lng: -92.081,
      city: 'Fairbank',
      state: 'IA',
    },
    50630: {
      lat: 42.95651,
      lng: -92.21346,
      city: 'Fredericksburg',
      state: 'IA',
    },
    50631: {
      lat: 42.88254,
      lng: -92.30929,
      city: 'Frederika',
      state: 'IA',
    },
    50632: {
      lat: 42.07667,
      lng: -92.70299,
      city: 'Garwin',
      state: 'IA',
    },
    50633: {
      lat: 42.67203,
      lng: -93.12664,
      city: 'Geneva',
      state: 'IA',
    },
    50634: {
      lat: 42.4181,
      lng: -92.21422,
      city: 'Gilbertville',
      state: 'IA',
    },
    50635: {
      lat: 42.19886,
      lng: -92.69896,
      city: 'Gladbrook',
      state: 'IA',
    },
    50636: {
      lat: 42.89439,
      lng: -92.80745,
      city: 'Greene',
      state: 'IA',
    },
    50638: {
      lat: 42.35654,
      lng: -92.79495,
      city: 'Grundy Center',
      state: 'IA',
    },
    50641: {
      lat: 42.59746,
      lng: -91.91961,
      city: 'Hazleton',
      state: 'IA',
    },
    50642: {
      lat: 42.44024,
      lng: -92.81243,
      city: 'Holland',
      state: 'IA',
    },
    50643: {
      lat: 42.36413,
      lng: -92.47402,
      city: 'Hudson',
      state: 'IA',
    },
    50644: {
      lat: 42.47751,
      lng: -91.89721,
      city: 'Independence',
      state: 'IA',
    },
    50645: {
      lat: 43.02174,
      lng: -92.44952,
      city: 'Ionia',
      state: 'IA',
    },
    50647: {
      lat: 42.64787,
      lng: -92.48026,
      city: 'Janesville',
      state: 'IA',
    },
    50648: {
      lat: 42.45182,
      lng: -92.09465,
      city: 'Jesup',
      state: 'IA',
    },
    50649: {
      lat: 42.66249,
      lng: -92.90973,
      city: 'Kesley',
      state: 'IA',
    },
    50650: {
      lat: 42.61915,
      lng: -91.65429,
      city: 'Lamont',
      state: 'IA',
    },
    50651: {
      lat: 42.32271,
      lng: -92.19993,
      city: 'La Porte City',
      state: 'IA',
    },
    50652: {
      lat: 42.26454,
      lng: -92.69234,
      city: 'Lincoln',
      state: 'IA',
    },
    50653: {
      lat: 42.96737,
      lng: -92.88927,
      city: 'Marble Rock',
      state: 'IA',
    },
    50654: {
      lat: 42.45812,
      lng: -91.60933,
      city: 'Masonville',
      state: 'IA',
    },
    50655: {
      lat: 42.77232,
      lng: -91.9016,
      city: 'Maynard',
      state: 'IA',
    },
    50657: {
      lat: 42.34454,
      lng: -92.67399,
      city: 'Morrison',
      state: 'IA',
    },
    50658: {
      lat: 42.95024,
      lng: -92.53782,
      city: 'Nashua',
      state: 'IA',
    },
    50659: {
      lat: 43.08749,
      lng: -92.3221,
      city: 'New Hampton',
      state: 'IA',
    },
    50660: {
      lat: 42.58099,
      lng: -92.63752,
      city: 'New Hartford',
      state: 'IA',
    },
    50662: {
      lat: 42.68748,
      lng: -91.93396,
      city: 'Oelwein',
      state: 'IA',
    },
    50664: { lat: 42.70698, lng: -92.07222, city: 'Oran', state: 'IA' },
    50665: {
      lat: 42.58078,
      lng: -92.7717,
      city: 'Parkersburg',
      state: 'IA',
    },
    50666: {
      lat: 42.85995,
      lng: -92.50933,
      city: 'Plainfield',
      state: 'IA',
    },
    50667: {
      lat: 42.47003,
      lng: -92.22545,
      city: 'Raymond',
      state: 'IA',
    },
    50668: {
      lat: 42.69087,
      lng: -92.2265,
      city: 'Readlyn',
      state: 'IA',
    },
    50669: {
      lat: 42.33309,
      lng: -92.6124,
      city: 'Reinbeck',
      state: 'IA',
    },
    50670: {
      lat: 42.68425,
      lng: -92.61586,
      city: 'Shell Rock',
      state: 'IA',
    },
    50671: {
      lat: 42.6523,
      lng: -91.7997,
      city: 'Stanley',
      state: 'IA',
    },
    50672: {
      lat: 42.4247,
      lng: -93.06545,
      city: 'Steamboat Rock',
      state: 'IA',
    },
    50673: { lat: 42.52505, lng: -92.7113, city: 'Stout', state: 'IA' },
    50674: {
      lat: 42.84202,
      lng: -92.11746,
      city: 'Sumner',
      state: 'IA',
    },
    50675: { lat: 42.19884, lng: -92.4928, city: 'Traer', state: 'IA' },
    50676: {
      lat: 42.7943,
      lng: -92.27895,
      city: 'Tripoli',
      state: 'IA',
    },
    50677: {
      lat: 42.76363,
      lng: -92.43775,
      city: 'Waverly',
      state: 'IA',
    },
    50680: {
      lat: 42.44956,
      lng: -92.92505,
      city: 'Wellsburg',
      state: 'IA',
    },
    50681: {
      lat: 42.77754,
      lng: -92.00931,
      city: 'Westgate',
      state: 'IA',
    },
    50682: {
      lat: 42.45241,
      lng: -91.69893,
      city: 'Winthrop',
      state: 'IA',
    },
    50701: {
      lat: 42.4171,
      lng: -92.34448,
      city: 'Waterloo',
      state: 'IA',
    },
    50702: {
      lat: 42.45745,
      lng: -92.31283,
      city: 'Waterloo',
      state: 'IA',
    },
    50703: {
      lat: 42.54817,
      lng: -92.28195,
      city: 'Waterloo',
      state: 'IA',
    },
    50707: {
      lat: 42.4771,
      lng: -92.27918,
      city: 'Evansdale',
      state: 'IA',
    },
    50801: {
      lat: 41.07959,
      lng: -94.39224,
      city: 'Creston',
      state: 'IA',
    },
    50830: { lat: 41.0242, lng: -94.19037, city: 'Afton', state: 'IA' },
    50831: {
      lat: 40.94938,
      lng: -94.22341,
      city: 'Arispe',
      state: 'IA',
    },
    50833: {
      lat: 40.66956,
      lng: -94.70309,
      city: 'Bedford',
      state: 'IA',
    },
    50835: {
      lat: 40.70769,
      lng: -94.3675,
      city: 'Benton',
      state: 'IA',
    },
    50836: {
      lat: 40.63116,
      lng: -94.49689,
      city: 'Blockton',
      state: 'IA',
    },
    50837: {
      lat: 41.22878,
      lng: -94.68367,
      city: 'Bridgewater',
      state: 'IA',
    },
    50839: {
      lat: 41.04874,
      lng: -94.82206,
      city: 'Carbon',
      state: 'IA',
    },
    50840: {
      lat: 40.79378,
      lng: -94.48413,
      city: 'Clearfield',
      state: 'IA',
    },
    50841: {
      lat: 41.01147,
      lng: -94.76342,
      city: 'Corning',
      state: 'IA',
    },
    50842: {
      lat: 41.03991,
      lng: -94.4618,
      city: 'Cromwell',
      state: 'IA',
    },
    50843: {
      lat: 41.24057,
      lng: -94.88913,
      city: 'Cumberland',
      state: 'IA',
    },
    50845: {
      lat: 40.81683,
      lng: -94.3523,
      city: 'Diagonal',
      state: 'IA',
    },
    50846: {
      lat: 41.31081,
      lng: -94.56839,
      city: 'Fontanelle',
      state: 'IA',
    },
    50847: {
      lat: 41.14168,
      lng: -94.98405,
      city: 'Grant',
      state: 'IA',
    },
    50848: {
      lat: 40.78685,
      lng: -94.76758,
      city: 'Gravity',
      state: 'IA',
    },
    50849: {
      lat: 41.30004,
      lng: -94.40176,
      city: 'Greenfield',
      state: 'IA',
    },
    50851: {
      lat: 40.90327,
      lng: -94.53637,
      city: 'Lenox',
      state: 'IA',
    },
    50853: {
      lat: 41.23869,
      lng: -94.77455,
      city: 'Massena',
      state: 'IA',
    },
    50854: {
      lat: 40.69089,
      lng: -94.21977,
      city: 'Mount Ayr',
      state: 'IA',
    },
    50857: {
      lat: 40.94045,
      lng: -94.88011,
      city: 'Nodaway',
      state: 'IA',
    },
    50858: {
      lat: 41.20961,
      lng: -94.40902,
      city: 'Orient',
      state: 'IA',
    },
    50859: {
      lat: 41.06505,
      lng: -94.60027,
      city: 'Prescott',
      state: 'IA',
    },
    50860: {
      lat: 40.62253,
      lng: -94.34949,
      city: 'Redding',
      state: 'IA',
    },
    50861: {
      lat: 40.91163,
      lng: -94.25685,
      city: 'Shannon City',
      state: 'IA',
    },
    50862: {
      lat: 40.81304,
      lng: -94.65136,
      city: 'Sharpsburg',
      state: 'IA',
    },
    50863: {
      lat: 40.84701,
      lng: -94.17318,
      city: 'Tingley',
      state: 'IA',
    },
    50864: {
      lat: 40.97648,
      lng: -94.98009,
      city: 'Villisca',
      state: 'IA',
    },
    51001: {
      lat: 42.83854,
      lng: -96.54551,
      city: 'Akron',
      state: 'IA',
    },
    51002: { lat: 42.69859, lng: -95.31863, city: 'Alta', state: 'IA' },
    51003: {
      lat: 42.98355,
      lng: -95.98642,
      city: 'Alton',
      state: 'IA',
    },
    51004: { lat: 42.3762, lng: -95.916, city: 'Anthon', state: 'IA' },
    51005: {
      lat: 42.72044,
      lng: -95.42405,
      city: 'Aurelia',
      state: 'IA',
    },
    51006: {
      lat: 42.31088,
      lng: -95.61299,
      city: 'Battle Creek',
      state: 'IA',
    },
    51007: {
      lat: 42.40225,
      lng: -96.18857,
      city: 'Bronson',
      state: 'IA',
    },
    51008: {
      lat: 42.81127,
      lng: -96.26724,
      city: 'Brunsville',
      state: 'IA',
    },
    51009: {
      lat: 42.94499,
      lng: -95.55142,
      city: 'Calumet',
      state: 'IA',
    },
    51010: {
      lat: 42.09309,
      lng: -95.92044,
      city: 'Castana',
      state: 'IA',
    },
    51011: {
      lat: 42.91562,
      lng: -96.51388,
      city: 'Chatsworth',
      state: 'IA',
    },
    51012: {
      lat: 42.74474,
      lng: -95.56171,
      city: 'Cherokee',
      state: 'IA',
    },
    51014: {
      lat: 42.78428,
      lng: -95.71155,
      city: 'Cleghorn',
      state: 'IA',
    },
    51015: {
      lat: 42.33936,
      lng: -96.08107,
      city: 'Climbing Hill',
      state: 'IA',
    },
    51016: {
      lat: 42.46907,
      lng: -95.80113,
      city: 'Correctionville',
      state: 'IA',
    },
    51018: {
      lat: 42.44884,
      lng: -95.67814,
      city: 'Cushing',
      state: 'IA',
    },
    51019: {
      lat: 42.2721,
      lng: -95.72615,
      city: 'Danbury',
      state: 'IA',
    },
    51020: {
      lat: 42.51215,
      lng: -95.41249,
      city: 'Galva',
      state: 'IA',
    },
    51022: {
      lat: 42.97819,
      lng: -95.84722,
      city: 'Granville',
      state: 'IA',
    },
    51023: {
      lat: 43.00893,
      lng: -96.46845,
      city: 'Hawarden',
      state: 'IA',
    },
    51024: {
      lat: 42.6201,
      lng: -96.26564,
      city: 'Hinton',
      state: 'IA',
    },
    51025: {
      lat: 42.4973,
      lng: -95.55853,
      city: 'Holstein',
      state: 'IA',
    },
    51026: {
      lat: 42.24857,
      lng: -96.07592,
      city: 'Hornick',
      state: 'IA',
    },
    51027: {
      lat: 42.96575,
      lng: -96.32791,
      city: 'Ireton',
      state: 'IA',
    },
    51028: {
      lat: 42.59909,
      lng: -95.99199,
      city: 'Kingsley',
      state: 'IA',
    },
    51029: {
      lat: 42.88549,
      lng: -95.5414,
      city: 'Larrabee',
      state: 'IA',
    },
    51030: {
      lat: 42.49791,
      lng: -96.19365,
      city: 'Lawton',
      state: 'IA',
    },
    51031: {
      lat: 42.80282,
      lng: -96.18092,
      city: 'Le Mars',
      state: 'IA',
    },
    51033: {
      lat: 42.91653,
      lng: -95.25324,
      city: 'Linn Grove',
      state: 'IA',
    },
    51034: {
      lat: 42.16241,
      lng: -95.78947,
      city: 'Mapleton',
      state: 'IA',
    },
    51035: {
      lat: 42.78086,
      lng: -95.80054,
      city: 'Marcus',
      state: 'IA',
    },
    51036: {
      lat: 42.97074,
      lng: -96.18938,
      city: 'Maurice',
      state: 'IA',
    },
    51037: {
      lat: 42.82567,
      lng: -95.64269,
      city: 'Meriden',
      state: 'IA',
    },
    51038: {
      lat: 42.7056,
      lng: -96.31569,
      city: 'Merrill',
      state: 'IA',
    },
    51039: {
      lat: 42.45655,
      lng: -96.05381,
      city: 'Moville',
      state: 'IA',
    },
    51040: { lat: 42.03073, lng: -96.0706, city: 'Onawa', state: 'IA' },
    51041: {
      lat: 43.02443,
      lng: -96.07701,
      city: 'Orange City',
      state: 'IA',
    },
    51044: { lat: 42.30113, lng: -95.91716, city: 'Oto', state: 'IA' },
    51045: {
      lat: 42.82019,
      lng: -96.05772,
      city: 'Oyens',
      state: 'IA',
    },
    51046: {
      lat: 42.96621,
      lng: -95.67949,
      city: 'Paullina',
      state: 'IA',
    },
    51047: {
      lat: 42.93523,
      lng: -95.35752,
      city: 'Peterson',
      state: 'IA',
    },
    51048: {
      lat: 42.57963,
      lng: -95.86412,
      city: 'Pierson',
      state: 'IA',
    },
    51049: {
      lat: 42.63005,
      lng: -95.64463,
      city: 'Quimby',
      state: 'IA',
    },
    51050: {
      lat: 42.79545,
      lng: -95.94772,
      city: 'Remsen',
      state: 'IA',
    },
    51051: {
      lat: 42.20443,
      lng: -95.96988,
      city: 'Rodney',
      state: 'IA',
    },
    51052: {
      lat: 42.31063,
      lng: -96.26343,
      city: 'Salix',
      state: 'IA',
    },
    51053: {
      lat: 42.4938,
      lng: -95.28842,
      city: 'Schaller',
      state: 'IA',
    },
    51054: {
      lat: 42.37832,
      lng: -96.33159,
      city: 'Sergeant Bluff',
      state: 'IA',
    },
    51055: {
      lat: 42.21162,
      lng: -96.26069,
      city: 'Sloan',
      state: 'IA',
    },
    51056: {
      lat: 42.23443,
      lng: -95.95391,
      city: 'Smithland',
      state: 'IA',
    },
    51058: {
      lat: 42.98367,
      lng: -95.46879,
      city: 'Sutherland',
      state: 'IA',
    },
    51060: { lat: 42.04189, lng: -95.69844, city: 'Ute', state: 'IA' },
    51061: {
      lat: 42.57983,
      lng: -95.72036,
      city: 'Washta',
      state: 'IA',
    },
    51062: {
      lat: 42.70623,
      lng: -96.53511,
      city: 'Westfield',
      state: 'IA',
    },
    51063: {
      lat: 42.13103,
      lng: -96.18611,
      city: 'Whiting',
      state: 'IA',
    },
    51101: {
      lat: 42.49413,
      lng: -96.39361,
      city: 'Sioux City',
      state: 'IA',
    },
    51103: {
      lat: 42.52173,
      lng: -96.44642,
      city: 'Sioux City',
      state: 'IA',
    },
    51104: {
      lat: 42.53518,
      lng: -96.40389,
      city: 'Sioux City',
      state: 'IA',
    },
    51105: {
      lat: 42.50808,
      lng: -96.3671,
      city: 'Sioux City',
      state: 'IA',
    },
    51106: {
      lat: 42.46292,
      lng: -96.32,
      city: 'Sioux City',
      state: 'IA',
    },
    51108: {
      lat: 42.56625,
      lng: -96.34858,
      city: 'Sioux City',
      state: 'IA',
    },
    51109: {
      lat: 42.59873,
      lng: -96.47804,
      city: 'Sioux City',
      state: 'IA',
    },
    51111: {
      lat: 42.41129,
      lng: -96.38606,
      city: 'Sioux City',
      state: 'IA',
    },
    51201: {
      lat: 43.18331,
      lng: -95.8657,
      city: 'Sheldon',
      state: 'IA',
    },
    51230: {
      lat: 43.3562,
      lng: -96.32217,
      city: 'Alvord',
      state: 'IA',
    },
    51231: {
      lat: 43.10567,
      lng: -95.74131,
      city: 'Archer',
      state: 'IA',
    },
    51232: {
      lat: 43.30097,
      lng: -95.8162,
      city: 'Ashton',
      state: 'IA',
    },
    51234: {
      lat: 43.19412,
      lng: -96.01992,
      city: 'Boyden',
      state: 'IA',
    },
    51235: { lat: 43.29488, lng: -96.21531, city: 'Doon', state: 'IA' },
    51237: {
      lat: 43.33885,
      lng: -96.0005,
      city: 'George',
      state: 'IA',
    },
    51238: {
      lat: 43.0758,
      lng: -95.89071,
      city: 'Hospers',
      state: 'IA',
    },
    51239: { lat: 43.20238, lng: -96.14522, city: 'Hull', state: 'IA' },
    51240: {
      lat: 43.30986,
      lng: -96.45727,
      city: 'Inwood',
      state: 'IA',
    },
    51241: {
      lat: 43.44992,
      lng: -96.46001,
      city: 'Larchwood',
      state: 'IA',
    },
    51242: {
      lat: 43.43227,
      lng: -96.33806,
      city: 'Lester',
      state: 'IA',
    },
    51243: {
      lat: 43.4425,
      lng: -95.90287,
      city: 'Little Rock',
      state: 'IA',
    },
    51244: {
      lat: 43.2443,
      lng: -95.93436,
      city: 'Matlock',
      state: 'IA',
    },
    51245: {
      lat: 43.07788,
      lng: -95.60149,
      city: 'Primghar',
      state: 'IA',
    },
    51246: {
      lat: 43.42764,
      lng: -96.16564,
      city: 'Rock Rapids',
      state: 'IA',
    },
    51247: {
      lat: 43.19259,
      lng: -96.33723,
      city: 'Rock Valley',
      state: 'IA',
    },
    51248: {
      lat: 43.20445,
      lng: -95.65911,
      city: 'Sanborn',
      state: 'IA',
    },
    51249: {
      lat: 43.41068,
      lng: -95.73203,
      city: 'Sibley',
      state: 'IA',
    },
    51250: {
      lat: 43.08437,
      lng: -96.21232,
      city: 'Sioux Center',
      state: 'IA',
    },
    51301: {
      lat: 43.15314,
      lng: -95.14973,
      city: 'Spencer',
      state: 'IA',
    },
    51331: {
      lat: 43.36431,
      lng: -95.13028,
      city: 'Arnolds Park',
      state: 'IA',
    },
    51333: {
      lat: 43.1324,
      lng: -95.00437,
      city: 'Dickens',
      state: 'IA',
    },
    51334: {
      lat: 43.40379,
      lng: -94.80698,
      city: 'Estherville',
      state: 'IA',
    },
    51338: {
      lat: 43.2065,
      lng: -95.32378,
      city: 'Everly',
      state: 'IA',
    },
    51340: {
      lat: 43.2404,
      lng: -95.15613,
      city: 'Fostoria',
      state: 'IA',
    },
    51341: {
      lat: 43.01665,
      lng: -95.04132,
      city: 'Gillett Grove',
      state: 'IA',
    },
    51342: {
      lat: 43.24359,
      lng: -94.73832,
      city: 'Graettinger',
      state: 'IA',
    },
    51343: {
      lat: 43.01804,
      lng: -95.11445,
      city: 'Greenville',
      state: 'IA',
    },
    51345: {
      lat: 43.4181,
      lng: -95.43209,
      city: 'Harris',
      state: 'IA',
    },
    51346: {
      lat: 43.18638,
      lng: -95.45927,
      city: 'Hartley',
      state: 'IA',
    },
    51347: {
      lat: 43.42547,
      lng: -95.31363,
      city: 'Lake Park',
      state: 'IA',
    },
    51350: {
      lat: 43.30752,
      lng: -95.59827,
      city: 'Melvin',
      state: 'IA',
    },
    51351: {
      lat: 43.31656,
      lng: -95.18626,
      city: 'Milford',
      state: 'IA',
    },
    51354: {
      lat: 43.41267,
      lng: -95.54352,
      city: 'Ocheyedan',
      state: 'IA',
    },
    51355: {
      lat: 43.38996,
      lng: -95.14082,
      city: 'Okoboji',
      state: 'IA',
    },
    51357: {
      lat: 43.06215,
      lng: -95.28762,
      city: 'Royal',
      state: 'IA',
    },
    51358: {
      lat: 43.13033,
      lng: -94.89579,
      city: 'Ruthven',
      state: 'IA',
    },
    51360: {
      lat: 43.43626,
      lng: -95.08556,
      city: 'Spirit Lake',
      state: 'IA',
    },
    51363: {
      lat: 43.43363,
      lng: -94.94678,
      city: 'Superior',
      state: 'IA',
    },
    51364: {
      lat: 43.2993,
      lng: -94.96293,
      city: 'Terril',
      state: 'IA',
    },
    51365: {
      lat: 43.30652,
      lng: -94.7349,
      city: 'Wallingford',
      state: 'IA',
    },
    51366: { lat: 42.96008, lng: -95.0095, city: 'Webb', state: 'IA' },
    51401: {
      lat: 42.06516,
      lng: -94.87068,
      city: 'Carroll',
      state: 'IA',
    },
    51430: {
      lat: 42.0869,
      lng: -95.02633,
      city: 'Arcadia',
      state: 'IA',
    },
    51431: {
      lat: 42.34446,
      lng: -95.35146,
      city: 'Arthur',
      state: 'IA',
    },
    51432: {
      lat: 41.91009,
      lng: -95.13795,
      city: 'Aspinwall',
      state: 'IA',
    },
    51433: {
      lat: 42.27635,
      lng: -94.87057,
      city: 'Auburn',
      state: 'IA',
    },
    51436: {
      lat: 42.18254,
      lng: -95.01247,
      city: 'Breda',
      state: 'IA',
    },
    51439: {
      lat: 42.08298,
      lng: -95.58347,
      city: 'Charter Oak',
      state: 'IA',
    },
    51440: {
      lat: 41.9117,
      lng: -94.81379,
      city: 'Dedham',
      state: 'IA',
    },
    51441: {
      lat: 42.11784,
      lng: -95.3157,
      city: 'Deloit',
      state: 'IA',
    },
    51442: {
      lat: 42.02696,
      lng: -95.35915,
      city: 'Denison',
      state: 'IA',
    },
    51443: {
      lat: 42.07748,
      lng: -94.70517,
      city: 'Glidden',
      state: 'IA',
    },
    51444: {
      lat: 42.01228,
      lng: -94.97352,
      city: 'Halbur',
      state: 'IA',
    },
    51445: {
      lat: 42.32719,
      lng: -95.46724,
      city: 'Ida Grove',
      state: 'IA',
    },
    51446: {
      lat: 41.77663,
      lng: -95.19277,
      city: 'Irwin',
      state: 'IA',
    },
    51447: {
      lat: 41.72101,
      lng: -95.19196,
      city: 'Kirkman',
      state: 'IA',
    },
    51448: {
      lat: 42.20238,
      lng: -95.30839,
      city: 'Kiron',
      state: 'IA',
    },
    51449: {
      lat: 42.26614,
      lng: -94.73475,
      city: 'Lake City',
      state: 'IA',
    },
    51450: {
      lat: 42.31205,
      lng: -95.02722,
      city: 'Lake View',
      state: 'IA',
    },
    51451: {
      lat: 42.1846,
      lng: -94.69471,
      city: 'Lanesboro',
      state: 'IA',
    },
    51452: {
      lat: 42.12242,
      lng: -94.78366,
      city: 'Lidderdale',
      state: 'IA',
    },
    51453: {
      lat: 42.26366,
      lng: -94.55155,
      city: 'Lohrville',
      state: 'IA',
    },
    51454: {
      lat: 41.8798,
      lng: -95.2072,
      city: 'Manilla',
      state: 'IA',
    },
    51455: {
      lat: 41.90829,
      lng: -95.0559,
      city: 'Manning',
      state: 'IA',
    },
    51458: {
      lat: 42.32141,
      lng: -95.23524,
      city: 'Odebolt',
      state: 'IA',
    },
    51459: {
      lat: 42.04495,
      lng: -94.63779,
      city: 'Ralston',
      state: 'IA',
    },
    51460: {
      lat: 42.1324,
      lng: -95.571,
      city: 'Ricketts',
      state: 'IA',
    },
    51461: {
      lat: 42.172,
      lng: -95.47579,
      city: 'Schleswig',
      state: 'IA',
    },
    51462: {
      lat: 42.01319,
      lng: -94.55744,
      city: 'Scranton',
      state: 'IA',
    },
    51463: {
      lat: 41.90743,
      lng: -94.91265,
      city: 'Templeton',
      state: 'IA',
    },
    51465: { lat: 42.08964, lng: -95.19872, city: 'Vail', state: 'IA' },
    51466: {
      lat: 42.25302,
      lng: -95.09837,
      city: 'Wall Lake',
      state: 'IA',
    },
    51467: {
      lat: 42.07377,
      lng: -95.10919,
      city: 'Westside',
      state: 'IA',
    },
    51501: {
      lat: 41.22828,
      lng: -95.87889,
      city: 'Council Bluffs',
      state: 'IA',
    },
    51503: {
      lat: 41.2304,
      lng: -95.77795,
      city: 'Council Bluffs',
      state: 'IA',
    },
    51510: {
      lat: 41.28844,
      lng: -95.91715,
      city: 'Carter Lake',
      state: 'IA',
    },
    51520: { lat: 41.9692, lng: -95.4593, city: 'Arion', state: 'IA' },
    51521: {
      lat: 41.47987,
      lng: -95.34526,
      city: 'Avoca',
      state: 'IA',
    },
    51523: {
      lat: 41.9122,
      lng: -96.07172,
      city: 'Blencoe',
      state: 'IA',
    },
    51525: {
      lat: 41.23443,
      lng: -95.41999,
      city: 'Carson',
      state: 'IA',
    },
    51526: {
      lat: 41.37186,
      lng: -95.87818,
      city: 'Crescent',
      state: 'IA',
    },
    51527: {
      lat: 41.82875,
      lng: -95.348,
      city: 'Defiance',
      state: 'IA',
    },
    51528: {
      lat: 41.92724,
      lng: -95.50185,
      city: 'Dow City',
      state: 'IA',
    },
    51529: {
      lat: 41.8618,
      lng: -95.62591,
      city: 'Dunlap',
      state: 'IA',
    },
    51530: {
      lat: 41.78247,
      lng: -95.42613,
      city: 'Earling',
      state: 'IA',
    },
    51531: {
      lat: 41.58751,
      lng: -95.08837,
      city: 'Elk Horn',
      state: 'IA',
    },
    51532: {
      lat: 41.13408,
      lng: -95.11167,
      city: 'Elliott',
      state: 'IA',
    },
    51533: {
      lat: 41.03606,
      lng: -95.37933,
      city: 'Emerson',
      state: 'IA',
    },
    51534: {
      lat: 41.04092,
      lng: -95.71853,
      city: 'Glenwood',
      state: 'IA',
    },
    51535: {
      lat: 41.2264,
      lng: -95.13241,
      city: 'Griswold',
      state: 'IA',
    },
    51536: {
      lat: 41.38385,
      lng: -95.36025,
      city: 'Hancock',
      state: 'IA',
    },
    51537: {
      lat: 41.64646,
      lng: -95.29531,
      city: 'Harlan',
      state: 'IA',
    },
    51540: {
      lat: 41.02488,
      lng: -95.49802,
      city: 'Hastings',
      state: 'IA',
    },
    51541: {
      lat: 41.13664,
      lng: -95.41623,
      city: 'Henderson',
      state: 'IA',
    },
    51542: {
      lat: 41.43354,
      lng: -95.84072,
      city: 'Honey Creek',
      state: 'IA',
    },
    51543: {
      lat: 41.65792,
      lng: -95.07317,
      city: 'Kimballton',
      state: 'IA',
    },
    51544: {
      lat: 41.31316,
      lng: -95.10817,
      city: 'Lewis',
      state: 'IA',
    },
    51545: {
      lat: 41.83897,
      lng: -96.02072,
      city: 'Little Sioux',
      state: 'IA',
    },
    51546: { lat: 41.6399, lng: -95.77209, city: 'Logan', state: 'IA' },
    51548: {
      lat: 41.31713,
      lng: -95.63553,
      city: 'McClelland',
      state: 'IA',
    },
    51549: {
      lat: 41.18253,
      lng: -95.45445,
      city: 'Macedonia',
      state: 'IA',
    },
    51550: {
      lat: 41.69203,
      lng: -95.87399,
      city: 'Magnolia',
      state: 'IA',
    },
    51551: {
      lat: 40.99129,
      lng: -95.58907,
      city: 'Malvern',
      state: 'IA',
    },
    51552: {
      lat: 41.48613,
      lng: -95.11439,
      city: 'Marne',
      state: 'IA',
    },
    51553: {
      lat: 41.41577,
      lng: -95.54638,
      city: 'Minden',
      state: 'IA',
    },
    51554: {
      lat: 41.14512,
      lng: -95.68122,
      city: 'Mineola',
      state: 'IA',
    },
    51555: {
      lat: 41.55937,
      lng: -95.91398,
      city: 'Missouri Valley',
      state: 'IA',
    },
    51556: {
      lat: 41.63548,
      lng: -96.04198,
      city: 'Modale',
      state: 'IA',
    },
    51557: {
      lat: 41.72903,
      lng: -96.00233,
      city: 'Mondamin',
      state: 'IA',
    },
    51558: {
      lat: 41.91497,
      lng: -95.87023,
      city: 'Moorhead',
      state: 'IA',
    },
    51559: {
      lat: 41.46034,
      lng: -95.66049,
      city: 'Neola',
      state: 'IA',
    },
    51560: {
      lat: 41.31746,
      lng: -95.39838,
      city: 'Oakland',
      state: 'IA',
    },
    51561: {
      lat: 41.00571,
      lng: -95.80992,
      city: 'Pacific Junction',
      state: 'IA',
    },
    51562: {
      lat: 41.72591,
      lng: -95.50128,
      city: 'Panama',
      state: 'IA',
    },
    51563: {
      lat: 41.5769,
      lng: -95.59405,
      city: 'Persia',
      state: 'IA',
    },
    51564: {
      lat: 41.81309,
      lng: -95.90801,
      city: 'Pisgah',
      state: 'IA',
    },
    51565: {
      lat: 41.64555,
      lng: -95.52391,
      city: 'Portsmouth',
      state: 'IA',
    },
    51566: {
      lat: 41.0151,
      lng: -95.22994,
      city: 'Red Oak',
      state: 'IA',
    },
    51570: {
      lat: 41.52271,
      lng: -95.46039,
      city: 'Shelby',
      state: 'IA',
    },
    51571: {
      lat: 41.14582,
      lng: -95.60994,
      city: 'Silver City',
      state: 'IA',
    },
    51572: {
      lat: 41.9896,
      lng: -95.79053,
      city: 'Soldier',
      state: 'IA',
    },
    51573: {
      lat: 40.97949,
      lng: -95.09451,
      city: 'Stanton',
      state: 'IA',
    },
    51575: {
      lat: 41.24111,
      lng: -95.60218,
      city: 'Treynor',
      state: 'IA',
    },
    51576: {
      lat: 41.3777,
      lng: -95.70945,
      city: 'Underwood',
      state: 'IA',
    },
    51577: {
      lat: 41.4644,
      lng: -95.20296,
      city: 'Walnut',
      state: 'IA',
    },
    51578: {
      lat: 41.72002,
      lng: -95.39508,
      city: 'Westphalia',
      state: 'IA',
    },
    51579: {
      lat: 41.75171,
      lng: -95.7069,
      city: 'Woodbine',
      state: 'IA',
    },
    51601: {
      lat: 40.72938,
      lng: -95.35037,
      city: 'Shenandoah',
      state: 'IA',
    },
    51630: {
      lat: 40.60101,
      lng: -95.19427,
      city: 'Blanchard',
      state: 'IA',
    },
    51631: {
      lat: 40.60267,
      lng: -95.00918,
      city: 'Braddyville',
      state: 'IA',
    },
    51632: {
      lat: 40.7478,
      lng: -95.04939,
      city: 'Clarinda',
      state: 'IA',
    },
    51636: { lat: 40.66713, lng: -95.23519, city: 'Coin', state: 'IA' },
    51637: {
      lat: 40.62046,
      lng: -95.11832,
      city: 'College Springs',
      state: 'IA',
    },
    51638: {
      lat: 40.83919,
      lng: -95.26663,
      city: 'Essex',
      state: 'IA',
    },
    51639: {
      lat: 40.71859,
      lng: -95.47362,
      city: 'Farragut',
      state: 'IA',
    },
    51640: {
      lat: 40.62262,
      lng: -95.6634,
      city: 'Hamburg',
      state: 'IA',
    },
    51645: {
      lat: 40.88206,
      lng: -95.4229,
      city: 'Imogene',
      state: 'IA',
    },
    51646: {
      lat: 40.75315,
      lng: -94.8725,
      city: 'New Market',
      state: 'IA',
    },
    51647: {
      lat: 40.60539,
      lng: -95.34509,
      city: 'Northboro',
      state: 'IA',
    },
    51648: {
      lat: 40.74215,
      lng: -95.80325,
      city: 'Percival',
      state: 'IA',
    },
    51649: {
      lat: 40.8518,
      lng: -95.53565,
      city: 'Randolph',
      state: 'IA',
    },
    51650: {
      lat: 40.673,
      lng: -95.5492,
      city: 'Riverton',
      state: 'IA',
    },
    51651: {
      lat: 40.65652,
      lng: -95.03473,
      city: 'Shambaugh',
      state: 'IA',
    },
    51652: {
      lat: 40.77032,
      lng: -95.62815,
      city: 'Sidney',
      state: 'IA',
    },
    51653: {
      lat: 40.88444,
      lng: -95.67179,
      city: 'Tabor',
      state: 'IA',
    },
    51654: {
      lat: 40.831,
      lng: -95.76038,
      city: 'Thurman',
      state: 'IA',
    },
    51656: {
      lat: 40.73527,
      lng: -95.15437,
      city: 'Yorktown',
      state: 'IA',
    },
    52001: {
      lat: 42.54819,
      lng: -90.69348,
      city: 'Dubuque',
      state: 'IA',
    },
    52002: {
      lat: 42.52481,
      lng: -90.77184,
      city: 'Dubuque',
      state: 'IA',
    },
    52003: {
      lat: 42.43434,
      lng: -90.67506,
      city: 'Dubuque',
      state: 'IA',
    },
    52030: {
      lat: 42.15322,
      lng: -90.59219,
      city: 'Andrew',
      state: 'IA',
    },
    52031: {
      lat: 42.24874,
      lng: -90.48922,
      city: 'Bellevue',
      state: 'IA',
    },
    52032: {
      lat: 42.29006,
      lng: -90.84317,
      city: 'Bernard',
      state: 'IA',
    },
    52033: {
      lat: 42.27941,
      lng: -91.00177,
      city: 'Cascade',
      state: 'IA',
    },
    52035: {
      lat: 42.65996,
      lng: -91.18784,
      city: 'Colesburg',
      state: 'IA',
    },
    52036: {
      lat: 42.47285,
      lng: -91.34086,
      city: 'Delaware',
      state: 'IA',
    },
    52037: {
      lat: 41.96712,
      lng: -90.62899,
      city: 'Delmar',
      state: 'IA',
    },
    52038: {
      lat: 42.58586,
      lng: -91.55685,
      city: 'Dundee',
      state: 'IA',
    },
    52039: {
      lat: 42.53807,
      lng: -90.85745,
      city: 'Durango',
      state: 'IA',
    },
    52040: {
      lat: 42.51114,
      lng: -91.13884,
      city: 'Dyersville',
      state: 'IA',
    },
    52041: {
      lat: 42.5051,
      lng: -91.25515,
      city: 'Earlville',
      state: 'IA',
    },
    52042: {
      lat: 42.68098,
      lng: -91.36517,
      city: 'Edgewood',
      state: 'IA',
    },
    52043: {
      lat: 42.84403,
      lng: -91.42201,
      city: 'Elkader',
      state: 'IA',
    },
    52044: {
      lat: 42.76808,
      lng: -91.32328,
      city: 'Elkport',
      state: 'IA',
    },
    52045: {
      lat: 42.45485,
      lng: -90.93113,
      city: 'Epworth',
      state: 'IA',
    },
    52046: {
      lat: 42.44024,
      lng: -91.0119,
      city: 'Farley',
      state: 'IA',
    },
    52047: {
      lat: 42.96401,
      lng: -91.34301,
      city: 'Farmersburg',
      state: 'IA',
    },
    52048: {
      lat: 42.73735,
      lng: -91.25584,
      city: 'Garber',
      state: 'IA',
    },
    52049: {
      lat: 42.8823,
      lng: -91.20521,
      city: 'Garnavillo',
      state: 'IA',
    },
    52050: {
      lat: 42.60532,
      lng: -91.33204,
      city: 'Greeley',
      state: 'IA',
    },
    52052: {
      lat: 42.7411,
      lng: -91.12083,
      city: 'Guttenberg',
      state: 'IA',
    },
    52053: {
      lat: 42.60656,
      lng: -90.96399,
      city: 'Holy Cross',
      state: 'IA',
    },
    52054: {
      lat: 42.30569,
      lng: -90.62811,
      city: 'La Motte',
      state: 'IA',
    },
    52056: {
      lat: 42.60401,
      lng: -91.07257,
      city: 'Luxemburg',
      state: 'IA',
    },
    52057: {
      lat: 42.4916,
      lng: -91.45333,
      city: 'Manchester',
      state: 'IA',
    },
    52060: {
      lat: 42.10217,
      lng: -90.67961,
      city: 'Maquoketa',
      state: 'IA',
    },
    52064: {
      lat: 42.08977,
      lng: -90.31076,
      city: 'Miles',
      state: 'IA',
    },
    52065: {
      lat: 42.58353,
      lng: -91.10202,
      city: 'New Vienna',
      state: 'IA',
    },
    52066: {
      lat: 42.693,
      lng: -90.96925,
      city: 'North Buena Vista',
      state: 'IA',
    },
    52068: {
      lat: 42.42382,
      lng: -90.81128,
      city: 'Peosta',
      state: 'IA',
    },
    52069: {
      lat: 42.05772,
      lng: -90.4279,
      city: 'Preston',
      state: 'IA',
    },
    52070: {
      lat: 42.08146,
      lng: -90.21771,
      city: 'Sabula',
      state: 'IA',
    },
    52071: {
      lat: 42.36245,
      lng: -90.54234,
      city: 'Saint Donatus',
      state: 'IA',
    },
    52072: {
      lat: 42.92022,
      lng: -91.38025,
      city: 'Saint Olaf',
      state: 'IA',
    },
    52073: {
      lat: 42.62331,
      lng: -90.82069,
      city: 'Sherrill',
      state: 'IA',
    },
    52074: {
      lat: 42.10966,
      lng: -90.4724,
      city: 'Spragueville',
      state: 'IA',
    },
    52075: {
      lat: 42.16577,
      lng: -90.47916,
      city: 'Springbrook',
      state: 'IA',
    },
    52076: {
      lat: 42.69805,
      lng: -91.50984,
      city: 'Strawberry Point',
      state: 'IA',
    },
    52077: {
      lat: 42.82258,
      lng: -91.55393,
      city: 'Volga',
      state: 'IA',
    },
    52078: {
      lat: 42.39506,
      lng: -91.11534,
      city: 'Worthington',
      state: 'IA',
    },
    52079: {
      lat: 42.28781,
      lng: -90.71908,
      city: 'Zwingle',
      state: 'IA',
    },
    52101: {
      lat: 43.35741,
      lng: -91.76494,
      city: 'Decorah',
      state: 'IA',
    },
    52132: {
      lat: 43.2067,
      lng: -91.91881,
      city: 'Calmar',
      state: 'IA',
    },
    52133: {
      lat: 43.10702,
      lng: -91.66854,
      city: 'Castalia',
      state: 'IA',
    },
    52134: {
      lat: 43.47156,
      lng: -92.40078,
      city: 'Chester',
      state: 'IA',
    },
    52135: {
      lat: 43.01945,
      lng: -91.66204,
      city: 'Clermont',
      state: 'IA',
    },
    52136: {
      lat: 43.37139,
      lng: -92.11289,
      city: 'Cresco',
      state: 'IA',
    },
    52140: {
      lat: 43.44173,
      lng: -91.53108,
      city: 'Dorchester',
      state: 'IA',
    },
    52141: {
      lat: 42.93924,
      lng: -91.63007,
      city: 'Elgin',
      state: 'IA',
    },
    52142: {
      lat: 42.82752,
      lng: -91.79441,
      city: 'Fayette',
      state: 'IA',
    },
    52144: {
      lat: 43.13961,
      lng: -91.96075,
      city: 'Fort Atkinson',
      state: 'IA',
    },
    52146: {
      lat: 43.18826,
      lng: -91.19588,
      city: 'Harpers Ferry',
      state: 'IA',
    },
    52147: {
      lat: 42.94957,
      lng: -91.96106,
      city: 'Hawkeye',
      state: 'IA',
    },
    52151: {
      lat: 43.34706,
      lng: -91.28013,
      city: 'Lansing',
      state: 'IA',
    },
    52154: {
      lat: 43.12201,
      lng: -92.16519,
      city: 'Lawler',
      state: 'IA',
    },
    52155: {
      lat: 43.41412,
      lng: -92.29683,
      city: 'Lime Springs',
      state: 'IA',
    },
    52156: {
      lat: 43.05316,
      lng: -91.46534,
      city: 'Luana',
      state: 'IA',
    },
    52157: {
      lat: 43.01661,
      lng: -91.23216,
      city: 'McGregor',
      state: 'IA',
    },
    52158: {
      lat: 43.04388,
      lng: -91.18647,
      city: 'Marquette',
      state: 'IA',
    },
    52159: {
      lat: 43.07979,
      lng: -91.36134,
      city: 'Monona',
      state: 'IA',
    },
    52160: {
      lat: 43.46733,
      lng: -91.3521,
      city: 'New Albin',
      state: 'IA',
    },
    52161: {
      lat: 43.12733,
      lng: -91.75623,
      city: 'Ossian',
      state: 'IA',
    },
    52162: {
      lat: 43.11062,
      lng: -91.55266,
      city: 'Postville',
      state: 'IA',
    },
    52163: {
      lat: 43.21976,
      lng: -92.10315,
      city: 'Protivin',
      state: 'IA',
    },
    52164: {
      lat: 42.85212,
      lng: -91.89941,
      city: 'Randalia',
      state: 'IA',
    },
    52165: {
      lat: 43.30671,
      lng: -91.98465,
      city: 'Ridgeway',
      state: 'IA',
    },
    52166: {
      lat: 43.06791,
      lng: -91.93547,
      city: 'Saint Lucas',
      state: 'IA',
    },
    52168: {
      lat: 43.20339,
      lng: -91.95338,
      city: 'Spillville',
      state: 'IA',
    },
    52169: {
      lat: 42.84739,
      lng: -91.65971,
      city: 'Wadena',
      state: 'IA',
    },
    52170: {
      lat: 43.22886,
      lng: -91.30485,
      city: 'Waterville',
      state: 'IA',
    },
    52171: {
      lat: 43.07451,
      lng: -92.04808,
      city: 'Waucoma',
      state: 'IA',
    },
    52172: {
      lat: 43.27117,
      lng: -91.48382,
      city: 'Waukon',
      state: 'IA',
    },
    52175: {
      lat: 42.99372,
      lng: -91.82389,
      city: 'West Union',
      state: 'IA',
    },
    52201: {
      lat: 41.33218,
      lng: -91.54704,
      city: 'Ainsworth',
      state: 'IA',
    },
    52202: {
      lat: 42.15836,
      lng: -91.63792,
      city: 'Alburnett',
      state: 'IA',
    },
    52203: { lat: 41.81035, lng: -91.8906, city: 'Amana', state: 'IA' },
    52205: {
      lat: 42.11754,
      lng: -91.28806,
      city: 'Anamosa',
      state: 'IA',
    },
    52206: {
      lat: 41.99187,
      lng: -91.88718,
      city: 'Atkins',
      state: 'IA',
    },
    52207: { lat: 42.09286, lng: -90.83, city: 'Baldwin', state: 'IA' },
    52208: {
      lat: 41.88568,
      lng: -92.26475,
      city: 'Belle Plaine',
      state: 'IA',
    },
    52209: {
      lat: 41.90809,
      lng: -92.09462,
      city: 'Blairstown',
      state: 'IA',
    },
    52210: {
      lat: 42.33905,
      lng: -92.00537,
      city: 'Brandon',
      state: 'IA',
    },
    52211: {
      lat: 41.75978,
      lng: -92.45062,
      city: 'Brooklyn',
      state: 'IA',
    },
    52212: {
      lat: 42.10954,
      lng: -91.08929,
      city: 'Center Junction',
      state: 'IA',
    },
    52213: {
      lat: 42.1995,
      lng: -91.77222,
      city: 'Center Point',
      state: 'IA',
    },
    52214: {
      lat: 42.19056,
      lng: -91.50704,
      city: 'Central City',
      state: 'IA',
    },
    52215: {
      lat: 41.91018,
      lng: -92.40106,
      city: 'Chelsea',
      state: 'IA',
    },
    52216: {
      lat: 41.89321,
      lng: -91.0431,
      city: 'Clarence',
      state: 'IA',
    },
    52217: {
      lat: 42.07948,
      lng: -92.41067,
      city: 'Clutier',
      state: 'IA',
    },
    52218: {
      lat: 42.2946,
      lng: -91.53826,
      city: 'Coggon',
      state: 'IA',
    },
    52219: {
      lat: 42.23758,
      lng: -91.42576,
      city: 'Prairieburg',
      state: 'IA',
    },
    52220: {
      lat: 41.72661,
      lng: -91.99853,
      city: 'Conroy',
      state: 'IA',
    },
    52221: {
      lat: 41.64151,
      lng: -92.33215,
      city: 'Guernsey',
      state: 'IA',
    },
    52222: {
      lat: 41.57623,
      lng: -92.33693,
      city: 'Deep River',
      state: 'IA',
    },
    52223: {
      lat: 42.41315,
      lng: -91.32408,
      city: 'Delhi',
      state: 'IA',
    },
    52224: {
      lat: 42.16386,
      lng: -92.29836,
      city: 'Dysart',
      state: 'IA',
    },
    52225: {
      lat: 42.01289,
      lng: -92.32636,
      city: 'Elberon',
      state: 'IA',
    },
    52227: { lat: 41.89647, lng: -91.5626, city: 'Ely', state: 'IA' },
    52228: {
      lat: 41.90282,
      lng: -91.79132,
      city: 'Fairfax',
      state: 'IA',
    },
    52229: {
      lat: 42.14609,
      lng: -92.16884,
      city: 'Garrison',
      state: 'IA',
    },
    52231: {
      lat: 41.36371,
      lng: -92.05912,
      city: 'Harper',
      state: 'IA',
    },
    52232: {
      lat: 41.80128,
      lng: -92.34043,
      city: 'Hartwick',
      state: 'IA',
    },
    52233: {
      lat: 42.04934,
      lng: -91.68732,
      city: 'Hiawatha',
      state: 'IA',
    },
    52235: {
      lat: 41.57024,
      lng: -91.53281,
      city: 'Hills',
      state: 'IA',
    },
    52236: {
      lat: 41.73143,
      lng: -91.87647,
      city: 'Homestead',
      state: 'IA',
    },
    52237: {
      lat: 42.34368,
      lng: -91.25193,
      city: 'Hopkinton',
      state: 'IA',
    },
    52240: {
      lat: 41.63143,
      lng: -91.49924,
      city: 'Iowa City',
      state: 'IA',
    },
    52241: {
      lat: 41.69708,
      lng: -91.59746,
      city: 'Coralville',
      state: 'IA',
    },
    52242: {
      lat: 41.66195,
      lng: -91.54317,
      city: 'Iowa City',
      state: 'IA',
    },
    52245: {
      lat: 41.67071,
      lng: -91.50666,
      city: 'Iowa City',
      state: 'IA',
    },
    52246: {
      lat: 41.64903,
      lng: -91.59718,
      city: 'Iowa City',
      state: 'IA',
    },
    52247: {
      lat: 41.51503,
      lng: -91.71774,
      city: 'Kalona',
      state: 'IA',
    },
    52248: {
      lat: 41.34386,
      lng: -91.93891,
      city: 'Keota',
      state: 'IA',
    },
    52249: {
      lat: 42.01202,
      lng: -92.20517,
      city: 'Keystone',
      state: 'IA',
    },
    52251: {
      lat: 41.71218,
      lng: -92.20314,
      city: 'Ladora',
      state: 'IA',
    },
    52252: {
      lat: 42.19712,
      lng: -91.22727,
      city: 'Langworthy',
      state: 'IA',
    },
    52253: {
      lat: 41.89713,
      lng: -91.35154,
      city: 'Lisbon',
      state: 'IA',
    },
    52254: {
      lat: 41.95232,
      lng: -90.80538,
      city: 'Lost Nation',
      state: 'IA',
    },
    52255: {
      lat: 41.86294,
      lng: -90.96129,
      city: 'Lowden',
      state: 'IA',
    },
    52257: {
      lat: 41.91836,
      lng: -92.17742,
      city: 'Luzerne',
      state: 'IA',
    },
    52301: {
      lat: 41.78658,
      lng: -92.09013,
      city: 'Marengo',
      state: 'IA',
    },
    52302: {
      lat: 42.0656,
      lng: -91.57091,
      city: 'Marion',
      state: 'IA',
    },
    52305: {
      lat: 42.01801,
      lng: -91.34207,
      city: 'Martelle',
      state: 'IA',
    },
    52306: {
      lat: 41.89729,
      lng: -91.25296,
      city: 'Mechanicsville',
      state: 'IA',
    },
    52307: {
      lat: 41.79158,
      lng: -91.90244,
      city: 'Middle Amana',
      state: 'IA',
    },
    52308: {
      lat: 41.5733,
      lng: -92.1592,
      city: 'Millersburg',
      state: 'IA',
    },
    52309: {
      lat: 42.12415,
      lng: -90.88733,
      city: 'Monmouth',
      state: 'IA',
    },
    52310: {
      lat: 42.21983,
      lng: -91.19737,
      city: 'Monticello',
      state: 'IA',
    },
    52312: {
      lat: 42.00617,
      lng: -91.24526,
      city: 'Morley',
      state: 'IA',
    },
    52313: {
      lat: 42.25955,
      lng: -92.09726,
      city: 'Mount Auburn',
      state: 'IA',
    },
    52314: {
      lat: 41.93439,
      lng: -91.44985,
      city: 'Mount Vernon',
      state: 'IA',
    },
    52315: {
      lat: 42.00181,
      lng: -91.96985,
      city: 'Newhall',
      state: 'IA',
    },
    52316: {
      lat: 41.53727,
      lng: -92.09367,
      city: 'North English',
      state: 'IA',
    },
    52317: {
      lat: 41.76277,
      lng: -91.63142,
      city: 'North Liberty',
      state: 'IA',
    },
    52318: {
      lat: 41.89012,
      lng: -91.89656,
      city: 'Norway',
      state: 'IA',
    },
    52320: { lat: 41.99997, lng: -91.14643, city: 'Olin', state: 'IA' },
    52321: {
      lat: 42.14168,
      lng: -90.98307,
      city: 'Onslow',
      state: 'IA',
    },
    52322: {
      lat: 41.69221,
      lng: -91.76284,
      city: 'Oxford',
      state: 'IA',
    },
    52323: {
      lat: 41.98445,
      lng: -90.95344,
      city: 'Oxford Junction',
      state: 'IA',
    },
    52324: { lat: 42.06421, lng: -91.80583, city: 'Palo', state: 'IA' },
    52325: {
      lat: 41.58028,
      lng: -91.92305,
      city: 'Parnell',
      state: 'IA',
    },
    52326: {
      lat: 42.39513,
      lng: -91.75641,
      city: 'Quasqueton',
      state: 'IA',
    },
    52327: {
      lat: 41.477,
      lng: -91.58046,
      city: 'Riverside',
      state: 'IA',
    },
    52328: {
      lat: 42.07195,
      lng: -91.66641,
      city: 'Robins',
      state: 'IA',
    },
    52329: {
      lat: 42.35894,
      lng: -91.85405,
      city: 'Rowley',
      state: 'IA',
    },
    52330: { lat: 42.35261, lng: -91.50076, city: 'Ryan', state: 'IA' },
    52332: {
      lat: 42.09861,
      lng: -91.88772,
      city: 'Shellsburg',
      state: 'IA',
    },
    52333: {
      lat: 41.80376,
      lng: -91.48685,
      city: 'Solon',
      state: 'IA',
    },
    52334: {
      lat: 41.73084,
      lng: -91.94355,
      city: 'South Amana',
      state: 'IA',
    },
    52335: {
      lat: 41.45935,
      lng: -92.05895,
      city: 'South English',
      state: 'IA',
    },
    52336: {
      lat: 42.0689,
      lng: -91.44111,
      city: 'Springville',
      state: 'IA',
    },
    52337: {
      lat: 41.88217,
      lng: -91.14282,
      city: 'Stanwood',
      state: 'IA',
    },
    52338: {
      lat: 41.82904,
      lng: -91.70858,
      city: 'Swisher',
      state: 'IA',
    },
    52339: { lat: 41.92313, lng: -92.58307, city: 'Tama', state: 'IA' },
    52340: {
      lat: 41.70615,
      lng: -91.68019,
      city: 'Tiffin',
      state: 'IA',
    },
    52341: {
      lat: 42.10535,
      lng: -91.7341,
      city: 'Toddville',
      state: 'IA',
    },
    52342: {
      lat: 42.05047,
      lng: -92.55849,
      city: 'Toledo',
      state: 'IA',
    },
    52344: {
      lat: 42.29202,
      lng: -91.68325,
      city: 'Troy Mills',
      state: 'IA',
    },
    52345: {
      lat: 42.22239,
      lng: -91.89177,
      city: 'Urbana',
      state: 'IA',
    },
    52346: {
      lat: 42.01298,
      lng: -92.08119,
      city: 'Van Horne',
      state: 'IA',
    },
    52347: {
      lat: 41.71345,
      lng: -92.28591,
      city: 'Victor',
      state: 'IA',
    },
    52348: {
      lat: 41.99331,
      lng: -92.38597,
      city: 'Vining',
      state: 'IA',
    },
    52349: {
      lat: 42.17246,
      lng: -92.00566,
      city: 'Vinton',
      state: 'IA',
    },
    52351: {
      lat: 41.87496,
      lng: -91.83529,
      city: 'Walford',
      state: 'IA',
    },
    52352: {
      lat: 42.28706,
      lng: -91.77897,
      city: 'Walker',
      state: 'IA',
    },
    52353: {
      lat: 41.29923,
      lng: -91.71596,
      city: 'Washington',
      state: 'IA',
    },
    52354: {
      lat: 41.91883,
      lng: -91.98856,
      city: 'Watkins',
      state: 'IA',
    },
    52355: {
      lat: 41.4576,
      lng: -92.18387,
      city: 'Webster',
      state: 'IA',
    },
    52356: {
      lat: 41.4749,
      lng: -91.84769,
      city: 'Wellman',
      state: 'IA',
    },
    52358: {
      lat: 41.69644,
      lng: -91.32097,
      city: 'West Branch',
      state: 'IA',
    },
    52359: {
      lat: 41.35428,
      lng: -91.81006,
      city: 'West Chester',
      state: 'IA',
    },
    52361: {
      lat: 41.6446,
      lng: -92.0154,
      city: 'Williamsburg',
      state: 'IA',
    },
    52362: {
      lat: 42.05881,
      lng: -91.00258,
      city: 'Wyoming',
      state: 'IA',
    },
    52401: {
      lat: 41.97534,
      lng: -91.65871,
      city: 'Cedar Rapids',
      state: 'IA',
    },
    52402: {
      lat: 42.02272,
      lng: -91.66001,
      city: 'Cedar Rapids',
      state: 'IA',
    },
    52403: {
      lat: 41.96813,
      lng: -91.57964,
      city: 'Cedar Rapids',
      state: 'IA',
    },
    52404: {
      lat: 41.92153,
      lng: -91.69077,
      city: 'Cedar Rapids',
      state: 'IA',
    },
    52405: {
      lat: 41.98644,
      lng: -91.74774,
      city: 'Cedar Rapids',
      state: 'IA',
    },
    52411: {
      lat: 42.05093,
      lng: -91.72723,
      city: 'Cedar Rapids',
      state: 'IA',
    },
    52501: {
      lat: 41.03828,
      lng: -92.43252,
      city: 'Ottumwa',
      state: 'IA',
    },
    52530: {
      lat: 40.98953,
      lng: -92.29492,
      city: 'Agency',
      state: 'IA',
    },
    52531: {
      lat: 41.02684,
      lng: -92.81562,
      city: 'Albia',
      state: 'IA',
    },
    52533: {
      lat: 41.01228,
      lng: -92.15043,
      city: 'Batavia',
      state: 'IA',
    },
    52534: {
      lat: 41.27342,
      lng: -92.6806,
      city: 'Beacon',
      state: 'IA',
    },
    52535: {
      lat: 40.86533,
      lng: -91.96407,
      city: 'Birmingham',
      state: 'IA',
    },
    52536: {
      lat: 40.95291,
      lng: -92.61802,
      city: 'Blakesburg',
      state: 'IA',
    },
    52537: {
      lat: 40.7355,
      lng: -92.41426,
      city: 'Bloomfield',
      state: 'IA',
    },
    52540: {
      lat: 41.15769,
      lng: -91.82281,
      city: 'Brighton',
      state: 'IA',
    },
    52542: {
      lat: 40.62712,
      lng: -92.05164,
      city: 'Cantril',
      state: 'IA',
    },
    52543: {
      lat: 41.20065,
      lng: -92.51773,
      city: 'Cedar',
      state: 'IA',
    },
    52544: {
      lat: 40.71774,
      lng: -92.90831,
      city: 'Centerville',
      state: 'IA',
    },
    52548: {
      lat: 41.0855,
      lng: -92.5306,
      city: 'Chillicothe',
      state: 'IA',
    },
    52549: {
      lat: 40.61392,
      lng: -92.94602,
      city: 'Cincinnati',
      state: 'IA',
    },
    52550: {
      lat: 41.31635,
      lng: -92.36254,
      city: 'Delta',
      state: 'IA',
    },
    52551: { lat: 40.81877, lng: -92.1058, city: 'Douds', state: 'IA' },
    52552: {
      lat: 40.83552,
      lng: -92.53633,
      city: 'Drakesville',
      state: 'IA',
    },
    52553: {
      lat: 41.14865,
      lng: -92.65668,
      city: 'Eddyville',
      state: 'IA',
    },
    52554: {
      lat: 40.92562,
      lng: -92.23387,
      city: 'Eldon',
      state: 'IA',
    },
    52555: {
      lat: 40.63905,
      lng: -92.81046,
      city: 'Exline',
      state: 'IA',
    },
    52556: {
      lat: 41.01842,
      lng: -91.9264,
      city: 'Fairfield',
      state: 'IA',
    },
    52557: {
      lat: 41.02026,
      lng: -91.96653,
      city: 'Fairfield',
      state: 'IA',
    },
    52560: {
      lat: 40.85491,
      lng: -92.25312,
      city: 'Floris',
      state: 'IA',
    },
    52561: {
      lat: 41.22135,
      lng: -92.44081,
      city: 'Fremont',
      state: 'IA',
    },
    52562: {
      lat: 41.26539,
      lng: -92.24858,
      city: 'Hayesville',
      state: 'IA',
    },
    52563: {
      lat: 41.17295,
      lng: -92.27765,
      city: 'Hedrick',
      state: 'IA',
    },
    52565: {
      lat: 40.74005,
      lng: -91.94332,
      city: 'Keosauqua',
      state: 'IA',
    },
    52566: {
      lat: 41.12887,
      lng: -92.50547,
      city: 'Kirkville',
      state: 'IA',
    },
    52567: {
      lat: 40.93174,
      lng: -92.07224,
      city: 'Libertyville',
      state: 'IA',
    },
    52568: {
      lat: 41.1779,
      lng: -92.25077,
      city: 'Martinsburg',
      state: 'IA',
    },
    52569: {
      lat: 40.97968,
      lng: -93.04939,
      city: 'Melrose',
      state: 'IA',
    },
    52570: {
      lat: 40.68656,
      lng: -92.14853,
      city: 'Milton',
      state: 'IA',
    },
    52571: {
      lat: 40.87739,
      lng: -92.83114,
      city: 'Moravia',
      state: 'IA',
    },
    52572: {
      lat: 40.67795,
      lng: -92.69163,
      city: 'Moulton',
      state: 'IA',
    },
    52573: {
      lat: 40.62378,
      lng: -91.92979,
      city: 'Mount Sterling',
      state: 'IA',
    },
    52574: {
      lat: 40.80822,
      lng: -92.96948,
      city: 'Mystic',
      state: 'IA',
    },
    52576: { lat: 41.21337, lng: -92.1125, city: 'Ollie', state: 'IA' },
    52577: {
      lat: 41.27174,
      lng: -92.6602,
      city: 'Oskaloosa',
      state: 'IA',
    },
    52580: {
      lat: 41.12039,
      lng: -92.09069,
      city: 'Packwood',
      state: 'IA',
    },
    52581: {
      lat: 40.80504,
      lng: -93.06265,
      city: 'Plano',
      state: 'IA',
    },
    52583: {
      lat: 40.80284,
      lng: -93.1555,
      city: 'Promise City',
      state: 'IA',
    },
    52584: {
      lat: 40.65273,
      lng: -92.24936,
      city: 'Pulaski',
      state: 'IA',
    },
    52585: {
      lat: 41.18902,
      lng: -91.98863,
      city: 'Richland',
      state: 'IA',
    },
    52586: {
      lat: 41.34573,
      lng: -92.46694,
      city: 'Rose Hill',
      state: 'IA',
    },
    52588: {
      lat: 40.86325,
      lng: -92.16315,
      city: 'Selma',
      state: 'IA',
    },
    52590: {
      lat: 40.6654,
      lng: -93.13594,
      city: 'Seymour',
      state: 'IA',
    },
    52591: {
      lat: 41.31988,
      lng: -92.19465,
      city: 'Sigourney',
      state: 'IA',
    },
    52593: {
      lat: 40.77363,
      lng: -92.73955,
      city: 'Udell',
      state: 'IA',
    },
    52594: {
      lat: 40.83561,
      lng: -92.64957,
      city: 'Unionville',
      state: 'IA',
    },
    52595: {
      lat: 41.28847,
      lng: -92.61506,
      city: 'University Park',
      state: 'IA',
    },
    52601: {
      lat: 40.8551,
      lng: -91.111,
      city: 'Burlington',
      state: 'IA',
    },
    52619: {
      lat: 40.5246,
      lng: -91.56566,
      city: 'Argyle',
      state: 'IA',
    },
    52620: {
      lat: 40.7032,
      lng: -91.79563,
      city: 'Bonaparte',
      state: 'IA',
    },
    52621: {
      lat: 41.20821,
      lng: -91.53209,
      city: 'Crawfordsville',
      state: 'IA',
    },
    52623: {
      lat: 40.85569,
      lng: -91.33998,
      city: 'Danville',
      state: 'IA',
    },
    52624: {
      lat: 40.7348,
      lng: -91.33609,
      city: 'Denmark',
      state: 'IA',
    },
    52625: {
      lat: 40.66604,
      lng: -91.57094,
      city: 'Donnellson',
      state: 'IA',
    },
    52626: {
      lat: 40.63201,
      lng: -91.72119,
      city: 'Farmington',
      state: 'IA',
    },
    52627: {
      lat: 40.65973,
      lng: -91.35145,
      city: 'Fort Madison',
      state: 'IA',
    },
    52630: {
      lat: 40.80375,
      lng: -91.7038,
      city: 'Hillsboro',
      state: 'IA',
    },
    52631: {
      lat: 40.78308,
      lng: -91.61209,
      city: 'Houghton',
      state: 'IA',
    },
    52632: {
      lat: 40.44072,
      lng: -91.43973,
      city: 'Keokuk',
      state: 'IA',
    },
    52635: {
      lat: 40.99117,
      lng: -91.75843,
      city: 'Lockridge',
      state: 'IA',
    },
    52637: {
      lat: 41.01949,
      lng: -91.13774,
      city: 'Mediapolis',
      state: 'IA',
    },
    52638: {
      lat: 40.79864,
      lng: -91.24577,
      city: 'Middletown',
      state: 'IA',
    },
    52639: {
      lat: 40.52763,
      lng: -91.46409,
      city: 'Montrose',
      state: 'IA',
    },
    52640: {
      lat: 41.09649,
      lng: -91.28205,
      city: 'Morning Sun',
      state: 'IA',
    },
    52641: {
      lat: 40.98799,
      lng: -91.58531,
      city: 'Mount Pleasant',
      state: 'IA',
    },
    52644: {
      lat: 41.03988,
      lng: -91.41535,
      city: 'Mount Union',
      state: 'IA',
    },
    52645: {
      lat: 40.91429,
      lng: -91.40138,
      city: 'New London',
      state: 'IA',
    },
    52646: {
      lat: 41.06706,
      lng: -91.00072,
      city: 'Oakville',
      state: 'IA',
    },
    52647: { lat: 41.1342, lng: -91.54506, city: 'Olds', state: 'IA' },
    52649: {
      lat: 40.83827,
      lng: -91.60647,
      city: 'Salem',
      state: 'IA',
    },
    52650: {
      lat: 40.94782,
      lng: -91.15687,
      city: 'Sperry',
      state: 'IA',
    },
    52651: {
      lat: 40.85819,
      lng: -91.81323,
      city: 'Stockport',
      state: 'IA',
    },
    52652: {
      lat: 41.09796,
      lng: -91.5542,
      city: 'Swedesburg',
      state: 'IA',
    },
    52653: {
      lat: 41.16642,
      lng: -91.15597,
      city: 'Wapello',
      state: 'IA',
    },
    52654: {
      lat: 41.13581,
      lng: -91.66898,
      city: 'Wayland',
      state: 'IA',
    },
    52655: {
      lat: 40.84768,
      lng: -91.21174,
      city: 'West Burlington',
      state: 'IA',
    },
    52656: {
      lat: 40.73844,
      lng: -91.4611,
      city: 'West Point',
      state: 'IA',
    },
    52657: {
      lat: 40.76784,
      lng: -91.5164,
      city: 'Saint Paul',
      state: 'IA',
    },
    52658: {
      lat: 40.69998,
      lng: -91.22657,
      city: 'Wever',
      state: 'IA',
    },
    52659: {
      lat: 41.13285,
      lng: -91.43658,
      city: 'Winfield',
      state: 'IA',
    },
    52660: {
      lat: 40.99146,
      lng: -91.29998,
      city: 'Yarmouth',
      state: 'IA',
    },
    52701: {
      lat: 41.98198,
      lng: -90.24908,
      city: 'Andover',
      state: 'IA',
    },
    52720: {
      lat: 41.59064,
      lng: -91.16578,
      city: 'Atalissa',
      state: 'IA',
    },
    52721: {
      lat: 41.75588,
      lng: -90.9633,
      city: 'Bennett',
      state: 'IA',
    },
    52722: {
      lat: 41.56633,
      lng: -90.4673,
      city: 'Bettendorf',
      state: 'IA',
    },
    52726: {
      lat: 41.50092,
      lng: -90.78419,
      city: 'Blue Grass',
      state: 'IA',
    },
    52727: {
      lat: 41.95834,
      lng: -90.33341,
      city: 'Bryant',
      state: 'IA',
    },
    52728: {
      lat: 41.45907,
      lng: -90.72592,
      city: 'Buffalo',
      state: 'IA',
    },
    52729: {
      lat: 41.80063,
      lng: -90.74312,
      city: 'Calamus',
      state: 'IA',
    },
    52730: {
      lat: 41.77366,
      lng: -90.34364,
      city: 'Camanche',
      state: 'IA',
    },
    52731: {
      lat: 41.96637,
      lng: -90.48551,
      city: 'Charlotte',
      state: 'IA',
    },
    52732: {
      lat: 41.90126,
      lng: -90.25083,
      city: 'Clinton',
      state: 'IA',
    },
    52737: {
      lat: 41.25918,
      lng: -91.37478,
      city: 'Columbus City',
      state: 'IA',
    },
    52738: {
      lat: 41.2736,
      lng: -91.38072,
      city: 'Columbus Junction',
      state: 'IA',
    },
    52739: {
      lat: 41.3728,
      lng: -91.36823,
      city: 'Conesville',
      state: 'IA',
    },
    52742: {
      lat: 41.83616,
      lng: -90.51076,
      city: 'De Witt',
      state: 'IA',
    },
    52745: {
      lat: 41.71502,
      lng: -90.75783,
      city: 'Dixon',
      state: 'IA',
    },
    52746: {
      lat: 41.71945,
      lng: -90.67172,
      city: 'Donahue',
      state: 'IA',
    },
    52747: {
      lat: 41.59758,
      lng: -90.91147,
      city: 'Durant',
      state: 'IA',
    },
    52748: {
      lat: 41.66471,
      lng: -90.55713,
      city: 'Eldridge',
      state: 'IA',
    },
    52749: {
      lat: 41.34608,
      lng: -91.13112,
      city: 'Fruitland',
      state: 'IA',
    },
    52750: {
      lat: 41.933,
      lng: -90.39302,
      city: 'Goose Lake',
      state: 'IA',
    },
    52751: {
      lat: 41.8519,
      lng: -90.68398,
      city: 'Grand Mound',
      state: 'IA',
    },
    52752: {
      lat: 41.27465,
      lng: -91.1911,
      city: 'Grandview',
      state: 'IA',
    },
    52753: {
      lat: 41.62544,
      lng: -90.38989,
      city: 'Le Claire',
      state: 'IA',
    },
    52754: {
      lat: 41.34254,
      lng: -91.24144,
      city: 'Letts',
      state: 'IA',
    },
    52755: {
      lat: 41.48015,
      lng: -91.43349,
      city: 'Lone Tree',
      state: 'IA',
    },
    52756: {
      lat: 41.72966,
      lng: -90.54084,
      city: 'Long Grove',
      state: 'IA',
    },
    52757: {
      lat: 41.80337,
      lng: -90.3552,
      city: 'Low Moor',
      state: 'IA',
    },
    52758: {
      lat: 41.74405,
      lng: -90.44104,
      city: 'McCausland',
      state: 'IA',
    },
    52759: {
      lat: 41.46043,
      lng: -90.80989,
      city: 'Montpelier',
      state: 'IA',
    },
    52760: {
      lat: 41.58255,
      lng: -91.09853,
      city: 'Moscow',
      state: 'IA',
    },
    52761: {
      lat: 41.43798,
      lng: -91.05802,
      city: 'Muscatine',
      state: 'IA',
    },
    52765: {
      lat: 41.72182,
      lng: -90.87274,
      city: 'New Liberty',
      state: 'IA',
    },
    52766: {
      lat: 41.46407,
      lng: -91.30061,
      city: 'Nichols',
      state: 'IA',
    },
    52767: {
      lat: 41.57356,
      lng: -90.42326,
      city: 'Pleasant Valley',
      state: 'IA',
    },
    52768: {
      lat: 41.69906,
      lng: -90.38977,
      city: 'Princeton',
      state: 'IA',
    },
    52769: {
      lat: 41.60836,
      lng: -90.8416,
      city: 'Stockton',
      state: 'IA',
    },
    52771: {
      lat: 42.01958,
      lng: -90.25707,
      city: 'Teeds Grove',
      state: 'IA',
    },
    52772: {
      lat: 41.75006,
      lng: -91.14083,
      city: 'Tipton',
      state: 'IA',
    },
    52773: {
      lat: 41.62187,
      lng: -90.75529,
      city: 'Walcott',
      state: 'IA',
    },
    52774: {
      lat: 41.90743,
      lng: -90.59596,
      city: 'Welton',
      state: 'IA',
    },
    52776: {
      lat: 41.5795,
      lng: -91.275,
      city: 'West Liberty',
      state: 'IA',
    },
    52777: {
      lat: 41.84739,
      lng: -90.85529,
      city: 'Wheatland',
      state: 'IA',
    },
    52778: {
      lat: 41.61115,
      lng: -91.00066,
      city: 'Wilton',
      state: 'IA',
    },
    52801: {
      lat: 41.52082,
      lng: -90.57483,
      city: 'Davenport',
      state: 'IA',
    },
    52802: {
      lat: 41.49908,
      lng: -90.63621,
      city: 'Davenport',
      state: 'IA',
    },
    52803: {
      lat: 41.53871,
      lng: -90.55623,
      city: 'Davenport',
      state: 'IA',
    },
    52804: {
      lat: 41.53584,
      lng: -90.68081,
      city: 'Davenport',
      state: 'IA',
    },
    52806: {
      lat: 41.58918,
      lng: -90.62553,
      city: 'Davenport',
      state: 'IA',
    },
    52807: {
      lat: 41.61061,
      lng: -90.51634,
      city: 'Davenport',
      state: 'IA',
    },
    53001: {
      lat: 43.60562,
      lng: -88.05217,
      city: 'Adell',
      state: 'WI',
    },
    53002: {
      lat: 43.46896,
      lng: -88.35454,
      city: 'Allenton',
      state: 'WI',
    },
    53003: {
      lat: 43.21226,
      lng: -88.51957,
      city: 'Ashippun',
      state: 'WI',
    },
    53004: {
      lat: 43.4995,
      lng: -87.8805,
      city: 'Belgium',
      state: 'WI',
    },
    53005: {
      lat: 43.0625,
      lng: -88.0992,
      city: 'Brookfield',
      state: 'WI',
    },
    53006: {
      lat: 43.62103,
      lng: -88.53534,
      city: 'Brownsville',
      state: 'WI',
    },
    53007: {
      lat: 43.10825,
      lng: -88.07116,
      city: 'Butler',
      state: 'WI',
    },
    53010: {
      lat: 43.61121,
      lng: -88.27901,
      city: 'Campbellsport',
      state: 'WI',
    },
    53011: {
      lat: 43.65755,
      lng: -88.08123,
      city: 'Cascade',
      state: 'WI',
    },
    53012: {
      lat: 43.31369,
      lng: -88.03704,
      city: 'Cedarburg',
      state: 'WI',
    },
    53013: {
      lat: 43.56585,
      lng: -87.85172,
      city: 'Cedar Grove',
      state: 'WI',
    },
    53014: {
      lat: 44.02298,
      lng: -88.17549,
      city: 'Chilton',
      state: 'WI',
    },
    53015: {
      lat: 43.90534,
      lng: -87.79296,
      city: 'Cleveland',
      state: 'WI',
    },
    53016: {
      lat: 43.31104,
      lng: -88.71794,
      city: 'Clyman',
      state: 'WI',
    },
    53017: {
      lat: 43.2044,
      lng: -88.2526,
      city: 'Colgate',
      state: 'WI',
    },
    53018: {
      lat: 43.04809,
      lng: -88.39278,
      city: 'Delafield',
      state: 'WI',
    },
    53019: { lat: 43.69667, lng: -88.30752, city: 'Eden', state: 'WI' },
    53020: {
      lat: 43.86231,
      lng: -88.01364,
      city: 'Elkhart Lake',
      state: 'WI',
    },
    53021: {
      lat: 43.49425,
      lng: -88.00111,
      city: 'Fredonia',
      state: 'WI',
    },
    53022: {
      lat: 43.23062,
      lng: -88.11286,
      city: 'Germantown',
      state: 'WI',
    },
    53023: {
      lat: 43.78198,
      lng: -88.10745,
      city: 'Glenbeulah',
      state: 'WI',
    },
    53024: {
      lat: 43.33233,
      lng: -87.93299,
      city: 'Grafton',
      state: 'WI',
    },
    53027: {
      lat: 43.31697,
      lng: -88.37356,
      city: 'Hartford',
      state: 'WI',
    },
    53029: {
      lat: 43.14381,
      lng: -88.34624,
      city: 'Hartland',
      state: 'WI',
    },
    53031: {
      lat: 43.64036,
      lng: -87.91778,
      city: 'Hingham',
      state: 'WI',
    },
    53032: {
      lat: 43.44708,
      lng: -88.6234,
      city: 'Horicon',
      state: 'WI',
    },
    53033: {
      lat: 43.23716,
      lng: -88.25634,
      city: 'Hubertus',
      state: 'WI',
    },
    53034: {
      lat: 43.34014,
      lng: -88.60746,
      city: 'Hustisford',
      state: 'WI',
    },
    53035: {
      lat: 43.39135,
      lng: -88.54355,
      city: 'Iron Ridge',
      state: 'WI',
    },
    53036: {
      lat: 43.17141,
      lng: -88.57693,
      city: 'Ixonia',
      state: 'WI',
    },
    53037: {
      lat: 43.31255,
      lng: -88.16108,
      city: 'Jackson',
      state: 'WI',
    },
    53038: {
      lat: 43.08592,
      lng: -88.79031,
      city: 'Johnson Creek',
      state: 'WI',
    },
    53039: {
      lat: 43.36931,
      lng: -88.71148,
      city: 'Juneau',
      state: 'WI',
    },
    53040: {
      lat: 43.52126,
      lng: -88.19198,
      city: 'Kewaskum',
      state: 'WI',
    },
    53042: { lat: 43.95144, lng: -87.98216, city: 'Kiel', state: 'WI' },
    53044: {
      lat: 43.73793,
      lng: -87.78327,
      city: 'Kohler',
      state: 'WI',
    },
    53045: {
      lat: 43.061,
      lng: -88.15145,
      city: 'Brookfield',
      state: 'WI',
    },
    53046: {
      lat: 43.15215,
      lng: -88.16045,
      city: 'Lannon',
      state: 'WI',
    },
    53047: {
      lat: 43.25904,
      lng: -88.63002,
      city: 'Lebanon',
      state: 'WI',
    },
    53048: {
      lat: 43.57935,
      lng: -88.45554,
      city: 'Lomira',
      state: 'WI',
    },
    53049: {
      lat: 43.8853,
      lng: -88.28917,
      city: 'Malone',
      state: 'WI',
    },
    53050: {
      lat: 43.50992,
      lng: -88.54734,
      city: 'Mayville',
      state: 'WI',
    },
    53051: {
      lat: 43.14905,
      lng: -88.12348,
      city: 'Menomonee Falls',
      state: 'WI',
    },
    53057: {
      lat: 43.79099,
      lng: -88.24671,
      city: 'Mount Calvary',
      state: 'WI',
    },
    53058: {
      lat: 43.11003,
      lng: -88.40624,
      city: 'Nashotah',
      state: 'WI',
    },
    53059: {
      lat: 43.28954,
      lng: -88.53012,
      city: 'Neosho',
      state: 'WI',
    },
    53061: {
      lat: 43.94588,
      lng: -88.11803,
      city: 'New Holstein',
      state: 'WI',
    },
    53063: {
      lat: 43.96297,
      lng: -87.80232,
      city: 'Newton',
      state: 'WI',
    },
    53065: {
      lat: 43.68227,
      lng: -88.56866,
      city: 'Oakfield',
      state: 'WI',
    },
    53066: {
      lat: 43.11451,
      lng: -88.49068,
      city: 'Oconomowoc',
      state: 'WI',
    },
    53069: {
      lat: 43.1131,
      lng: -88.43094,
      city: 'Okauchee',
      state: 'WI',
    },
    53070: {
      lat: 43.62429,
      lng: -87.80872,
      city: 'Oostburg',
      state: 'WI',
    },
    53072: {
      lat: 43.07989,
      lng: -88.26638,
      city: 'Pewaukee',
      state: 'WI',
    },
    53073: {
      lat: 43.75011,
      lng: -87.99191,
      city: 'Plymouth',
      state: 'WI',
    },
    53074: {
      lat: 43.42716,
      lng: -87.88265,
      city: 'Port Washington',
      state: 'WI',
    },
    53075: {
      lat: 43.56318,
      lng: -87.99559,
      city: 'Random Lake',
      state: 'WI',
    },
    53076: {
      lat: 43.26966,
      lng: -88.20905,
      city: 'Richfield',
      state: 'WI',
    },
    53078: {
      lat: 43.31386,
      lng: -88.45924,
      city: 'Rubicon',
      state: 'WI',
    },
    53079: {
      lat: 43.81132,
      lng: -88.1888,
      city: 'Saint Cloud',
      state: 'WI',
    },
    53080: {
      lat: 43.40733,
      lng: -87.98789,
      city: 'Saukville',
      state: 'WI',
    },
    53081: {
      lat: 43.71343,
      lng: -87.73725,
      city: 'Sheboygan',
      state: 'WI',
    },
    53083: {
      lat: 43.81511,
      lng: -87.77108,
      city: 'Sheboygan',
      state: 'WI',
    },
    53085: {
      lat: 43.73562,
      lng: -87.85137,
      city: 'Sheboygan Falls',
      state: 'WI',
    },
    53086: {
      lat: 43.32508,
      lng: -88.27124,
      city: 'Slinger',
      state: 'WI',
    },
    53088: {
      lat: 44.07178,
      lng: -88.29966,
      city: 'Stockbridge',
      state: 'WI',
    },
    53089: {
      lat: 43.14646,
      lng: -88.23594,
      city: 'Sussex',
      state: 'WI',
    },
    53090: {
      lat: 43.45685,
      lng: -88.17985,
      city: 'West Bend',
      state: 'WI',
    },
    53091: {
      lat: 43.49667,
      lng: -88.43674,
      city: 'Theresa',
      state: 'WI',
    },
    53092: {
      lat: 43.22139,
      lng: -87.95351,
      city: 'Mequon',
      state: 'WI',
    },
    53093: {
      lat: 43.65348,
      lng: -87.93894,
      city: 'Waldo',
      state: 'WI',
    },
    53094: {
      lat: 43.1462,
      lng: -88.73365,
      city: 'Watertown',
      state: 'WI',
    },
    53095: {
      lat: 43.38838,
      lng: -88.15895,
      city: 'West Bend',
      state: 'WI',
    },
    53097: {
      lat: 43.24574,
      lng: -88.0069,
      city: 'Mequon',
      state: 'WI',
    },
    53098: {
      lat: 43.24955,
      lng: -88.71461,
      city: 'Watertown',
      state: 'WI',
    },
    53099: {
      lat: 43.38035,
      lng: -88.52525,
      city: 'Woodland',
      state: 'WI',
    },
    53103: {
      lat: 42.88175,
      lng: -88.21542,
      city: 'Big Bend',
      state: 'WI',
    },
    53104: {
      lat: 42.55731,
      lng: -88.03416,
      city: 'Bristol',
      state: 'WI',
    },
    53105: {
      lat: 42.66412,
      lng: -88.28045,
      city: 'Burlington',
      state: 'WI',
    },
    53108: {
      lat: 42.82043,
      lng: -87.94696,
      city: 'Caledonia',
      state: 'WI',
    },
    53110: {
      lat: 42.94671,
      lng: -87.86403,
      city: 'Cudahy',
      state: 'WI',
    },
    53114: {
      lat: 42.60721,
      lng: -88.75089,
      city: 'Darien',
      state: 'WI',
    },
    53115: {
      lat: 42.65443,
      lng: -88.6713,
      city: 'Delavan',
      state: 'WI',
    },
    53118: {
      lat: 42.9725,
      lng: -88.48859,
      city: 'Dousman',
      state: 'WI',
    },
    53119: { lat: 42.88356, lng: -88.4862, city: 'Eagle', state: 'WI' },
    53120: {
      lat: 42.80066,
      lng: -88.41783,
      city: 'East Troy',
      state: 'WI',
    },
    53121: {
      lat: 42.70989,
      lng: -88.53028,
      city: 'Elkhorn',
      state: 'WI',
    },
    53122: {
      lat: 43.04811,
      lng: -88.08674,
      city: 'Elm Grove',
      state: 'WI',
    },
    53125: {
      lat: 42.54532,
      lng: -88.56216,
      city: 'Fontana',
      state: 'WI',
    },
    53126: {
      lat: 42.78848,
      lng: -87.9966,
      city: 'Franksville',
      state: 'WI',
    },
    53127: {
      lat: 42.95991,
      lng: -88.3777,
      city: 'Genesee Depot',
      state: 'WI',
    },
    53128: {
      lat: 42.52979,
      lng: -88.33857,
      city: 'Genoa City',
      state: 'WI',
    },
    53129: {
      lat: 42.93795,
      lng: -88.00003,
      city: 'Greendale',
      state: 'WI',
    },
    53130: {
      lat: 42.94092,
      lng: -88.04909,
      city: 'Hales Corners',
      state: 'WI',
    },
    53132: {
      lat: 42.88589,
      lng: -88.01063,
      city: 'Franklin',
      state: 'WI',
    },
    53137: {
      lat: 43.00472,
      lng: -88.66975,
      city: 'Helenville',
      state: 'WI',
    },
    53139: {
      lat: 42.68795,
      lng: -88.12,
      city: 'Kansasville',
      state: 'WI',
    },
    53140: {
      lat: 42.61963,
      lng: -87.82987,
      city: 'Kenosha',
      state: 'WI',
    },
    53142: {
      lat: 42.54464,
      lng: -87.93765,
      city: 'Kenosha',
      state: 'WI',
    },
    53143: {
      lat: 42.56109,
      lng: -87.83038,
      city: 'Kenosha',
      state: 'WI',
    },
    53144: {
      lat: 42.61991,
      lng: -87.92291,
      city: 'Kenosha',
      state: 'WI',
    },
    53146: {
      lat: 42.9718,
      lng: -88.15885,
      city: 'New Berlin',
      state: 'WI',
    },
    53147: {
      lat: 42.57075,
      lng: -88.45024,
      city: 'Lake Geneva',
      state: 'WI',
    },
    53149: {
      lat: 42.87436,
      lng: -88.34077,
      city: 'Mukwonago',
      state: 'WI',
    },
    53150: {
      lat: 42.8845,
      lng: -88.12824,
      city: 'Muskego',
      state: 'WI',
    },
    53151: {
      lat: 42.97467,
      lng: -88.09845,
      city: 'New Berlin',
      state: 'WI',
    },
    53153: {
      lat: 42.94056,
      lng: -88.40237,
      city: 'North Prairie',
      state: 'WI',
    },
    53154: {
      lat: 42.8803,
      lng: -87.90092,
      city: 'Oak Creek',
      state: 'WI',
    },
    53156: {
      lat: 42.89069,
      lng: -88.59012,
      city: 'Palmyra',
      state: 'WI',
    },
    53157: {
      lat: 42.5377,
      lng: -88.36855,
      city: 'Pell Lake',
      state: 'WI',
    },
    53158: {
      lat: 42.52418,
      lng: -87.88869,
      city: 'Pleasant Prairie',
      state: 'WI',
    },
    53167: {
      lat: 42.73526,
      lng: -88.22552,
      city: 'Rochester',
      state: 'WI',
    },
    53168: {
      lat: 42.57858,
      lng: -88.12999,
      city: 'Salem',
      state: 'WI',
    },
    53170: {
      lat: 42.5519,
      lng: -88.16149,
      city: 'Silver Lake',
      state: 'WI',
    },
    53172: {
      lat: 42.91197,
      lng: -87.86266,
      city: 'South Milwaukee',
      state: 'WI',
    },
    53176: {
      lat: 42.64307,
      lng: -88.41444,
      city: 'Springfield',
      state: 'WI',
    },
    53177: {
      lat: 42.70047,
      lng: -87.93474,
      city: 'Sturtevant',
      state: 'WI',
    },
    53178: {
      lat: 43.02335,
      lng: -88.59894,
      city: 'Sullivan',
      state: 'WI',
    },
    53179: {
      lat: 42.5133,
      lng: -88.13159,
      city: 'Trevor',
      state: 'WI',
    },
    53181: {
      lat: 42.51711,
      lng: -88.24305,
      city: 'Twin Lakes',
      state: 'WI',
    },
    53182: {
      lat: 42.69815,
      lng: -88.04096,
      city: 'Union Grove',
      state: 'WI',
    },
    53183: { lat: 43.00441, lng: -88.3739, city: 'Wales', state: 'WI' },
    53184: {
      lat: 42.53075,
      lng: -88.60945,
      city: 'Walworth',
      state: 'WI',
    },
    53185: {
      lat: 42.79519,
      lng: -88.19313,
      city: 'Waterford',
      state: 'WI',
    },
    53186: {
      lat: 43.02305,
      lng: -88.20319,
      city: 'Waukesha',
      state: 'WI',
    },
    53188: {
      lat: 43.02106,
      lng: -88.29265,
      city: 'Waukesha',
      state: 'WI',
    },
    53189: {
      lat: 42.95216,
      lng: -88.28068,
      city: 'Waukesha',
      state: 'WI',
    },
    53190: {
      lat: 42.80775,
      lng: -88.73129,
      city: 'Whitewater',
      state: 'WI',
    },
    53191: {
      lat: 42.5776,
      lng: -88.54574,
      city: 'Williams Bay',
      state: 'WI',
    },
    53192: {
      lat: 42.51172,
      lng: -88.18412,
      city: 'Wilmot',
      state: 'WI',
    },
    53195: {
      lat: 42.51397,
      lng: -88.49016,
      city: 'Zenda',
      state: 'WI',
    },
    53202: {
      lat: 43.04561,
      lng: -87.89893,
      city: 'Milwaukee',
      state: 'WI',
    },
    53203: {
      lat: 43.03828,
      lng: -87.91603,
      city: 'Milwaukee',
      state: 'WI',
    },
    53204: {
      lat: 43.01806,
      lng: -87.92552,
      city: 'Milwaukee',
      state: 'WI',
    },
    53205: {
      lat: 43.05359,
      lng: -87.93363,
      city: 'Milwaukee',
      state: 'WI',
    },
    53206: {
      lat: 43.07524,
      lng: -87.93349,
      city: 'Milwaukee',
      state: 'WI',
    },
    53207: {
      lat: 42.97148,
      lng: -87.90152,
      city: 'Milwaukee',
      state: 'WI',
    },
    53208: {
      lat: 43.04556,
      lng: -87.96556,
      city: 'Milwaukee',
      state: 'WI',
    },
    53209: {
      lat: 43.12871,
      lng: -87.94717,
      city: 'Milwaukee',
      state: 'WI',
    },
    53210: {
      lat: 43.06889,
      lng: -87.97381,
      city: 'Milwaukee',
      state: 'WI',
    },
    53211: {
      lat: 43.08308,
      lng: -87.88485,
      city: 'Milwaukee',
      state: 'WI',
    },
    53212: {
      lat: 43.07464,
      lng: -87.90845,
      city: 'Milwaukee',
      state: 'WI',
    },
    53213: {
      lat: 43.04889,
      lng: -88.00176,
      city: 'Milwaukee',
      state: 'WI',
    },
    53214: {
      lat: 43.02061,
      lng: -88.01634,
      city: 'Milwaukee',
      state: 'WI',
    },
    53215: {
      lat: 42.99931,
      lng: -87.94625,
      city: 'Milwaukee',
      state: 'WI',
    },
    53216: {
      lat: 43.08634,
      lng: -87.97636,
      city: 'Milwaukee',
      state: 'WI',
    },
    53217: {
      lat: 43.15767,
      lng: -87.91633,
      city: 'Milwaukee',
      state: 'WI',
    },
    53218: {
      lat: 43.11469,
      lng: -87.99301,
      city: 'Milwaukee',
      state: 'WI',
    },
    53219: {
      lat: 42.99537,
      lng: -87.99171,
      city: 'Milwaukee',
      state: 'WI',
    },
    53220: {
      lat: 42.96515,
      lng: -87.99142,
      city: 'Milwaukee',
      state: 'WI',
    },
    53221: {
      lat: 42.95445,
      lng: -87.94374,
      city: 'Milwaukee',
      state: 'WI',
    },
    53222: {
      lat: 43.08205,
      lng: -88.03496,
      city: 'Milwaukee',
      state: 'WI',
    },
    53223: {
      lat: 43.16304,
      lng: -87.98996,
      city: 'Milwaukee',
      state: 'WI',
    },
    53224: {
      lat: 43.16357,
      lng: -88.03894,
      city: 'Milwaukee',
      state: 'WI',
    },
    53225: {
      lat: 43.11443,
      lng: -88.042,
      city: 'Milwaukee',
      state: 'WI',
    },
    53226: {
      lat: 43.04975,
      lng: -88.04209,
      city: 'Milwaukee',
      state: 'WI',
    },
    53227: {
      lat: 42.99562,
      lng: -88.04264,
      city: 'Milwaukee',
      state: 'WI',
    },
    53228: {
      lat: 42.96678,
      lng: -88.04333,
      city: 'Milwaukee',
      state: 'WI',
    },
    53233: {
      lat: 43.03694,
      lng: -87.93364,
      city: 'Milwaukee',
      state: 'WI',
    },
    53235: {
      lat: 42.97161,
      lng: -87.87298,
      city: 'Saint Francis',
      state: 'WI',
    },
    53295: {
      lat: 43.02222,
      lng: -87.97665,
      city: 'Milwaukee',
      state: 'WI',
    },
    53402: {
      lat: 42.7989,
      lng: -87.82385,
      city: 'Racine',
      state: 'WI',
    },
    53403: {
      lat: 42.68738,
      lng: -87.82587,
      city: 'Racine',
      state: 'WI',
    },
    53404: {
      lat: 42.7572,
      lng: -87.81246,
      city: 'Racine',
      state: 'WI',
    },
    53405: {
      lat: 42.72712,
      lng: -87.82588,
      city: 'Racine',
      state: 'WI',
    },
    53406: {
      lat: 42.73014,
      lng: -87.86549,
      city: 'Racine',
      state: 'WI',
    },
    53501: {
      lat: 42.60484,
      lng: -89.07165,
      city: 'Afton',
      state: 'WI',
    },
    53502: {
      lat: 42.73219,
      lng: -89.44071,
      city: 'Albany',
      state: 'WI',
    },
    53503: {
      lat: 43.13935,
      lng: -89.95623,
      city: 'Arena',
      state: 'WI',
    },
    53504: {
      lat: 42.70388,
      lng: -89.85801,
      city: 'Argyle',
      state: 'WI',
    },
    53505: {
      lat: 42.66063,
      lng: -88.8176,
      city: 'Avalon',
      state: 'WI',
    },
    53506: {
      lat: 43.15017,
      lng: -90.28422,
      city: 'Avoca',
      state: 'WI',
    },
    53507: {
      lat: 43.00153,
      lng: -89.90173,
      city: 'Barneveld',
      state: 'WI',
    },
    53508: {
      lat: 42.86783,
      lng: -89.5618,
      city: 'Belleville',
      state: 'WI',
    },
    53510: {
      lat: 42.74065,
      lng: -90.32147,
      city: 'Belmont',
      state: 'WI',
    },
    53511: {
      lat: 42.54627,
      lng: -89.09792,
      city: 'Beloit',
      state: 'WI',
    },
    53515: {
      lat: 43.11708,
      lng: -89.74915,
      city: 'Black Earth',
      state: 'WI',
    },
    53516: {
      lat: 42.80073,
      lng: -89.8676,
      city: 'Blanchardville',
      state: 'WI',
    },
    53517: {
      lat: 43.03686,
      lng: -89.82967,
      city: 'Blue Mounds',
      state: 'WI',
    },
    53518: {
      lat: 43.2458,
      lng: -90.59108,
      city: 'Blue River',
      state: 'WI',
    },
    53520: {
      lat: 42.60133,
      lng: -89.36123,
      city: 'Brodhead',
      state: 'WI',
    },
    53521: {
      lat: 42.84217,
      lng: -89.40814,
      city: 'Brooklyn',
      state: 'WI',
    },
    53522: {
      lat: 42.55674,
      lng: -89.78983,
      city: 'Browntown',
      state: 'WI',
    },
    53523: {
      lat: 42.98338,
      lng: -89.03172,
      city: 'Cambridge',
      state: 'WI',
    },
    53525: {
      lat: 42.54579,
      lng: -88.84907,
      city: 'Clinton',
      state: 'WI',
    },
    53526: { lat: 42.97433, lng: -90.34438, city: 'Cobb', state: 'WI' },
    53527: {
      lat: 43.06346,
      lng: -89.19141,
      city: 'Cottage Grove',
      state: 'WI',
    },
    53528: {
      lat: 43.11854,
      lng: -89.63739,
      city: 'Cross Plains',
      state: 'WI',
    },
    53529: { lat: 43.24075, lng: -89.52496, city: 'Dane', state: 'WI' },
    53530: {
      lat: 42.69356,
      lng: -90.11163,
      city: 'Darlington',
      state: 'WI',
    },
    53531: {
      lat: 43.05696,
      lng: -89.10114,
      city: 'Deerfield',
      state: 'WI',
    },
    53532: {
      lat: 43.24881,
      lng: -89.32547,
      city: 'Deforest',
      state: 'WI',
    },
    53533: {
      lat: 42.99285,
      lng: -90.14915,
      city: 'Dodgeville',
      state: 'WI',
    },
    53534: {
      lat: 42.84763,
      lng: -89.09032,
      city: 'Edgerton',
      state: 'WI',
    },
    53536: {
      lat: 42.76366,
      lng: -89.26983,
      city: 'Evansville',
      state: 'WI',
    },
    53537: {
      lat: 42.67267,
      lng: -89.21056,
      city: 'Footville',
      state: 'WI',
    },
    53538: {
      lat: 42.91697,
      lng: -88.87194,
      city: 'Fort Atkinson',
      state: 'WI',
    },
    53540: {
      lat: 43.22173,
      lng: -90.29356,
      city: 'Gotham',
      state: 'WI',
    },
    53541: {
      lat: 42.56969,
      lng: -90.02963,
      city: 'Gratiot',
      state: 'WI',
    },
    53543: {
      lat: 43.0556,
      lng: -90.36233,
      city: 'Highland',
      state: 'WI',
    },
    53544: {
      lat: 42.88199,
      lng: -89.9153,
      city: 'Hollandale',
      state: 'WI',
    },
    53545: {
      lat: 42.73113,
      lng: -89.03613,
      city: 'Janesville',
      state: 'WI',
    },
    53546: {
      lat: 42.65126,
      lng: -88.94854,
      city: 'Janesville',
      state: 'WI',
    },
    53548: {
      lat: 42.68907,
      lng: -89.13095,
      city: 'Janesville',
      state: 'WI',
    },
    53549: {
      lat: 42.99056,
      lng: -88.77675,
      city: 'Jefferson',
      state: 'WI',
    },
    53550: { lat: 42.5581, lng: -89.49348, city: 'Juda', state: 'WI' },
    53551: {
      lat: 43.07831,
      lng: -88.91508,
      city: 'Lake Mills',
      state: 'WI',
    },
    53553: {
      lat: 42.91235,
      lng: -90.29693,
      city: 'Linden',
      state: 'WI',
    },
    53554: {
      lat: 42.90205,
      lng: -90.44919,
      city: 'Livingston',
      state: 'WI',
    },
    53555: { lat: 43.32163, lng: -89.55625, city: 'Lodi', state: 'WI' },
    53556: {
      lat: 43.24014,
      lng: -90.24525,
      city: 'Lone Rock',
      state: 'WI',
    },
    53557: {
      lat: 43.33996,
      lng: -88.79449,
      city: 'Lowell',
      state: 'WI',
    },
    53558: {
      lat: 43.00286,
      lng: -89.28409,
      city: 'Mcfarland',
      state: 'WI',
    },
    53559: {
      lat: 43.16557,
      lng: -89.08066,
      city: 'Marshall',
      state: 'WI',
    },
    53560: {
      lat: 43.19418,
      lng: -89.75413,
      city: 'Mazomanie',
      state: 'WI',
    },
    53561: {
      lat: 43.39594,
      lng: -89.64414,
      city: 'Merrimac',
      state: 'WI',
    },
    53562: {
      lat: 43.11592,
      lng: -89.54188,
      city: 'Middleton',
      state: 'WI',
    },
    53563: {
      lat: 42.78276,
      lng: -88.93127,
      city: 'Milton',
      state: 'WI',
    },
    53565: {
      lat: 42.84025,
      lng: -90.16982,
      city: 'Mineral Point',
      state: 'WI',
    },
    53566: {
      lat: 42.60912,
      lng: -89.64817,
      city: 'Monroe',
      state: 'WI',
    },
    53569: {
      lat: 42.98105,
      lng: -90.42966,
      city: 'Montfort',
      state: 'WI',
    },
    53570: {
      lat: 42.73857,
      lng: -89.61966,
      city: 'Monticello',
      state: 'WI',
    },
    53571: {
      lat: 43.27793,
      lng: -89.35358,
      city: 'Morrisonville',
      state: 'WI',
    },
    53572: {
      lat: 42.96016,
      lng: -89.73754,
      city: 'Mount Horeb',
      state: 'WI',
    },
    53573: {
      lat: 43.18196,
      lng: -90.46326,
      city: 'Muscoda',
      state: 'WI',
    },
    53574: {
      lat: 42.81851,
      lng: -89.65181,
      city: 'New Glarus',
      state: 'WI',
    },
    53575: {
      lat: 42.93148,
      lng: -89.38627,
      city: 'Oregon',
      state: 'WI',
    },
    53576: {
      lat: 42.62543,
      lng: -89.23949,
      city: 'Orfordville',
      state: 'WI',
    },
    53577: {
      lat: 43.31428,
      lng: -90.07117,
      city: 'Plain',
      state: 'WI',
    },
    53578: {
      lat: 43.31356,
      lng: -89.77408,
      city: 'Prairie Du Sac',
      state: 'WI',
    },
    53579: {
      lat: 43.2894,
      lng: -88.87545,
      city: 'Reeseville',
      state: 'WI',
    },
    53580: {
      lat: 42.85943,
      lng: -90.38131,
      city: 'Rewey',
      state: 'WI',
    },
    53581: {
      lat: 43.37504,
      lng: -90.41637,
      city: 'Richland Center',
      state: 'WI',
    },
    53582: {
      lat: 43.01791,
      lng: -89.99106,
      city: 'Ridgeway',
      state: 'WI',
    },
    53583: {
      lat: 43.25671,
      lng: -89.81178,
      city: 'Sauk City',
      state: 'WI',
    },
    53584: {
      lat: 43.27937,
      lng: -90.29477,
      city: 'Sextonville',
      state: 'WI',
    },
    53585: {
      lat: 42.53049,
      lng: -88.72056,
      city: 'Sharon',
      state: 'WI',
    },
    53586: {
      lat: 42.57271,
      lng: -90.22702,
      city: 'Shullsburg',
      state: 'WI',
    },
    53587: {
      lat: 42.57736,
      lng: -89.90511,
      city: 'South Wayne',
      state: 'WI',
    },
    53588: {
      lat: 43.18949,
      lng: -90.08201,
      city: 'Spring Green',
      state: 'WI',
    },
    53589: {
      lat: 42.92684,
      lng: -89.21182,
      city: 'Stoughton',
      state: 'WI',
    },
    53590: {
      lat: 43.19936,
      lng: -89.20804,
      city: 'Sun Prairie',
      state: 'WI',
    },
    53593: {
      lat: 42.98533,
      lng: -89.57992,
      city: 'Verona',
      state: 'WI',
    },
    53594: {
      lat: 43.18291,
      lng: -88.97168,
      city: 'Waterloo',
      state: 'WI',
    },
    53597: {
      lat: 43.18258,
      lng: -89.45726,
      city: 'Waunakee',
      state: 'WI',
    },
    53598: {
      lat: 43.20641,
      lng: -89.3387,
      city: 'Windsor',
      state: 'WI',
    },
    53599: {
      lat: 42.64765,
      lng: -89.85986,
      city: 'Woodford',
      state: 'WI',
    },
    53703: {
      lat: 43.07981,
      lng: -89.37734,
      city: 'Madison',
      state: 'WI',
    },
    53704: {
      lat: 43.13633,
      lng: -89.34684,
      city: 'Madison',
      state: 'WI',
    },
    53705: {
      lat: 43.07427,
      lng: -89.46246,
      city: 'Madison',
      state: 'WI',
    },
    53706: {
      lat: 43.07505,
      lng: -89.40987,
      city: 'Madison',
      state: 'WI',
    },
    53711: {
      lat: 43.01521,
      lng: -89.40641,
      city: 'Madison',
      state: 'WI',
    },
    53713: {
      lat: 43.03743,
      lng: -89.39254,
      city: 'Madison',
      state: 'WI',
    },
    53714: {
      lat: 43.09884,
      lng: -89.31287,
      city: 'Madison',
      state: 'WI',
    },
    53715: {
      lat: 43.06038,
      lng: -89.39861,
      city: 'Madison',
      state: 'WI',
    },
    53716: {
      lat: 43.06506,
      lng: -89.31454,
      city: 'Madison',
      state: 'WI',
    },
    53717: {
      lat: 43.07372,
      lng: -89.51945,
      city: 'Madison',
      state: 'WI',
    },
    53718: {
      lat: 43.0977,
      lng: -89.27332,
      city: 'Madison',
      state: 'WI',
    },
    53719: {
      lat: 43.0281,
      lng: -89.50003,
      city: 'Madison',
      state: 'WI',
    },
    53726: {
      lat: 43.07036,
      lng: -89.4223,
      city: 'Madison',
      state: 'WI',
    },
    53792: {
      lat: 43.07711,
      lng: -89.43044,
      city: 'Madison',
      state: 'WI',
    },
    53801: {
      lat: 42.92505,
      lng: -91.06959,
      city: 'Bagley',
      state: 'WI',
    },
    53802: {
      lat: 42.80538,
      lng: -90.89358,
      city: 'Beetown',
      state: 'WI',
    },
    53803: {
      lat: 42.55915,
      lng: -90.35508,
      city: 'Benton',
      state: 'WI',
    },
    53804: {
      lat: 42.87645,
      lng: -90.90798,
      city: 'Bloomington',
      state: 'WI',
    },
    53805: {
      lat: 43.14236,
      lng: -90.68445,
      city: 'Boscobel',
      state: 'WI',
    },
    53806: {
      lat: 42.73554,
      lng: -90.92544,
      city: 'Cassville',
      state: 'WI',
    },
    53807: {
      lat: 42.61123,
      lng: -90.46668,
      city: 'Cuba City',
      state: 'WI',
    },
    53808: {
      lat: 42.63745,
      lng: -90.59204,
      city: 'Dickeyville',
      state: 'WI',
    },
    53809: {
      lat: 42.99362,
      lng: -90.63323,
      city: 'Fennimore',
      state: 'WI',
    },
    53810: {
      lat: 42.82144,
      lng: -90.9984,
      city: 'Glen Haven',
      state: 'WI',
    },
    53811: {
      lat: 42.53576,
      lng: -90.51541,
      city: 'Hazel Green',
      state: 'WI',
    },
    53812: {
      lat: 42.5808,
      lng: -90.60239,
      city: 'Kieler',
      state: 'WI',
    },
    53813: {
      lat: 42.84402,
      lng: -90.71641,
      city: 'Lancaster',
      state: 'WI',
    },
    53816: {
      lat: 42.97794,
      lng: -90.85773,
      city: 'Mount Hope',
      state: 'WI',
    },
    53817: {
      lat: 42.94028,
      lng: -90.97234,
      city: 'Patch Grove',
      state: 'WI',
    },
    53818: {
      lat: 42.74725,
      lng: -90.49177,
      city: 'Platteville',
      state: 'WI',
    },
    53820: {
      lat: 42.69649,
      lng: -90.70984,
      city: 'Potosi',
      state: 'WI',
    },
    53821: {
      lat: 43.04769,
      lng: -91.06569,
      city: 'Prairie Du Chien',
      state: 'WI',
    },
    53825: {
      lat: 42.91833,
      lng: -90.58851,
      city: 'Stitzer',
      state: 'WI',
    },
    53826: {
      lat: 43.12225,
      lng: -90.91039,
      city: 'Wauzeka',
      state: 'WI',
    },
    53827: {
      lat: 43.05049,
      lng: -90.83702,
      city: 'Woodman',
      state: 'WI',
    },
    53901: {
      lat: 43.55606,
      lng: -89.48606,
      city: 'Portage',
      state: 'WI',
    },
    53910: {
      lat: 43.89073,
      lng: -89.80708,
      city: 'Adams',
      state: 'WI',
    },
    53911: {
      lat: 43.3258,
      lng: -89.36478,
      city: 'Arlington',
      state: 'WI',
    },
    53913: {
      lat: 43.49213,
      lng: -89.72858,
      city: 'Baraboo',
      state: 'WI',
    },
    53916: {
      lat: 43.45538,
      lng: -88.85813,
      city: 'Beaver Dam',
      state: 'WI',
    },
    53919: {
      lat: 43.73462,
      lng: -88.77564,
      city: 'Brandon',
      state: 'WI',
    },
    53920: {
      lat: 43.66579,
      lng: -89.60364,
      city: 'Briggsville',
      state: 'WI',
    },
    53922: {
      lat: 43.51558,
      lng: -88.70916,
      city: 'Burnett',
      state: 'WI',
    },
    53923: {
      lat: 43.57326,
      lng: -89.13753,
      city: 'Cambria',
      state: 'WI',
    },
    53924: {
      lat: 43.47971,
      lng: -90.25455,
      city: 'Cazenovia',
      state: 'WI',
    },
    53925: {
      lat: 43.3325,
      lng: -89.05075,
      city: 'Columbus',
      state: 'WI',
    },
    53926: {
      lat: 43.67164,
      lng: -89.21497,
      city: 'Dalton',
      state: 'WI',
    },
    53928: {
      lat: 43.42051,
      lng: -89.1547,
      city: 'Doylestown',
      state: 'WI',
    },
    53929: {
      lat: 43.75415,
      lng: -90.28376,
      city: 'Elroy',
      state: 'WI',
    },
    53930: {
      lat: 43.683,
      lng: -89.49506,
      city: 'Endeavor',
      state: 'WI',
    },
    53931: {
      lat: 43.74236,
      lng: -88.86798,
      city: 'Fairwater',
      state: 'WI',
    },
    53932: {
      lat: 43.4202,
      lng: -89.06543,
      city: 'Fall River',
      state: 'WI',
    },
    53933: {
      lat: 43.57235,
      lng: -88.88338,
      city: 'Fox Lake',
      state: 'WI',
    },
    53934: {
      lat: 43.98044,
      lng: -89.82184,
      city: 'Friendship',
      state: 'WI',
    },
    53935: {
      lat: 43.58864,
      lng: -89.06697,
      city: 'Friesland',
      state: 'WI',
    },
    53936: {
      lat: 43.87477,
      lng: -89.71071,
      city: 'Grand Marsh',
      state: 'WI',
    },
    53937: {
      lat: 43.38841,
      lng: -90.15753,
      city: 'Hillpoint',
      state: 'WI',
    },
    53939: {
      lat: 43.69185,
      lng: -89.13199,
      city: 'Kingston',
      state: 'WI',
    },
    53940: {
      lat: 43.59126,
      lng: -89.7944,
      city: 'Lake Delton',
      state: 'WI',
    },
    53941: {
      lat: 43.57947,
      lng: -90.13694,
      city: 'La Valle',
      state: 'WI',
    },
    53942: {
      lat: 43.46393,
      lng: -90.16536,
      city: 'Lime Ridge',
      state: 'WI',
    },
    53943: {
      lat: 43.38999,
      lng: -90.03018,
      city: 'Loganville',
      state: 'WI',
    },
    53944: {
      lat: 43.68759,
      lng: -89.92791,
      city: 'Lyndon Station',
      state: 'WI',
    },
    53946: {
      lat: 43.71822,
      lng: -89.02189,
      city: 'Markesan',
      state: 'WI',
    },
    53947: {
      lat: 43.74676,
      lng: -89.13769,
      city: 'Marquette',
      state: 'WI',
    },
    53948: {
      lat: 43.77846,
      lng: -90.05161,
      city: 'Mauston',
      state: 'WI',
    },
    53949: {
      lat: 43.78323,
      lng: -89.33978,
      city: 'Montello',
      state: 'WI',
    },
    53950: {
      lat: 43.90477,
      lng: -90.13463,
      city: 'New Lisbon',
      state: 'WI',
    },
    53951: {
      lat: 43.39087,
      lng: -89.86633,
      city: 'North Freedom',
      state: 'WI',
    },
    53952: {
      lat: 43.78718,
      lng: -89.5963,
      city: 'Oxford',
      state: 'WI',
    },
    53953: {
      lat: 43.76606,
      lng: -89.458,
      city: 'Packwaukee',
      state: 'WI',
    },
    53954: {
      lat: 43.55518,
      lng: -89.31325,
      city: 'Pardeeville',
      state: 'WI',
    },
    53955: {
      lat: 43.40025,
      lng: -89.40051,
      city: 'Poynette',
      state: 'WI',
    },
    53956: {
      lat: 43.54166,
      lng: -89.01849,
      city: 'Randolph',
      state: 'WI',
    },
    53959: {
      lat: 43.53407,
      lng: -89.99702,
      city: 'Reedsburg',
      state: 'WI',
    },
    53960: { lat: 43.4224, lng: -89.23212, city: 'Rio', state: 'WI' },
    53961: {
      lat: 43.46055,
      lng: -89.93858,
      city: 'Rock Springs',
      state: 'WI',
    },
    53962: {
      lat: 43.68352,
      lng: -90.2652,
      city: 'Union Center',
      state: 'WI',
    },
    53963: {
      lat: 43.63492,
      lng: -88.73828,
      city: 'Waupun',
      state: 'WI',
    },
    53964: {
      lat: 43.91253,
      lng: -89.5025,
      city: 'Westfield',
      state: 'WI',
    },
    53965: {
      lat: 43.67651,
      lng: -89.75681,
      city: 'Wisconsin Dells',
      state: 'WI',
    },
    53968: {
      lat: 43.64072,
      lng: -90.2326,
      city: 'Wonewoc',
      state: 'WI',
    },
    53969: {
      lat: 43.49567,
      lng: -89.30176,
      city: 'Wyocena',
      state: 'WI',
    },
    54001: {
      lat: 45.33417,
      lng: -92.38703,
      city: 'Amery',
      state: 'WI',
    },
    54002: {
      lat: 44.95871,
      lng: -92.37121,
      city: 'Baldwin',
      state: 'WI',
    },
    54003: {
      lat: 44.79019,
      lng: -92.44218,
      city: 'Beldenville',
      state: 'WI',
    },
    54004: {
      lat: 45.30699,
      lng: -92.12414,
      city: 'Clayton',
      state: 'WI',
    },
    54005: {
      lat: 45.23447,
      lng: -92.23051,
      city: 'Clear Lake',
      state: 'WI',
    },
    54006: {
      lat: 45.58958,
      lng: -92.64824,
      city: 'Cushing',
      state: 'WI',
    },
    54007: {
      lat: 45.18781,
      lng: -92.35734,
      city: 'Deer Park',
      state: 'WI',
    },
    54009: {
      lat: 45.35358,
      lng: -92.57693,
      city: 'Dresser',
      state: 'WI',
    },
    54011: {
      lat: 44.71648,
      lng: -92.46279,
      city: 'Ellsworth',
      state: 'WI',
    },
    54013: {
      lat: 45.08134,
      lng: -92.22929,
      city: 'Glenwood City',
      state: 'WI',
    },
    54014: {
      lat: 44.64578,
      lng: -92.57048,
      city: 'Hager City',
      state: 'WI',
    },
    54015: {
      lat: 44.96899,
      lng: -92.45419,
      city: 'Hammond',
      state: 'WI',
    },
    54016: {
      lat: 44.97949,
      lng: -92.69305,
      city: 'Hudson',
      state: 'WI',
    },
    54017: {
      lat: 45.11907,
      lng: -92.52091,
      city: 'New Richmond',
      state: 'WI',
    },
    54020: {
      lat: 45.27937,
      lng: -92.65235,
      city: 'Osceola',
      state: 'WI',
    },
    54021: {
      lat: 44.74048,
      lng: -92.71144,
      city: 'Prescott',
      state: 'WI',
    },
    54022: {
      lat: 44.84796,
      lng: -92.6096,
      city: 'River Falls',
      state: 'WI',
    },
    54023: {
      lat: 44.97708,
      lng: -92.55218,
      city: 'Roberts',
      state: 'WI',
    },
    54024: {
      lat: 45.49873,
      lng: -92.64781,
      city: 'Saint Croix Falls',
      state: 'WI',
    },
    54025: {
      lat: 45.14184,
      lng: -92.68997,
      city: 'Somerset',
      state: 'WI',
    },
    54026: {
      lat: 45.22688,
      lng: -92.53459,
      city: 'Star Prairie',
      state: 'WI',
    },
    54027: {
      lat: 44.92403,
      lng: -92.19985,
      city: 'Wilson',
      state: 'WI',
    },
    54028: {
      lat: 44.95532,
      lng: -92.28279,
      city: 'Woodville',
      state: 'WI',
    },
    54082: {
      lat: 45.0696,
      lng: -92.75403,
      city: 'Houlton',
      state: 'WI',
    },
    54101: {
      lat: 44.78786,
      lng: -88.05927,
      city: 'Abrams',
      state: 'WI',
    },
    54102: {
      lat: 45.50278,
      lng: -88.00524,
      city: 'Amberg',
      state: 'WI',
    },
    54103: {
      lat: 45.64972,
      lng: -88.49885,
      city: 'Armstrong Creek',
      state: 'WI',
    },
    54104: {
      lat: 45.44018,
      lng: -88.26518,
      city: 'Athelstane',
      state: 'WI',
    },
    54106: {
      lat: 44.48172,
      lng: -88.45551,
      city: 'Black Creek',
      state: 'WI',
    },
    54107: {
      lat: 44.70068,
      lng: -88.44436,
      city: 'Bonduel',
      state: 'WI',
    },
    54110: {
      lat: 44.18533,
      lng: -88.08105,
      city: 'Brillion',
      state: 'WI',
    },
    54111: {
      lat: 44.81572,
      lng: -88.37992,
      city: 'Cecil',
      state: 'WI',
    },
    54112: {
      lat: 45.04527,
      lng: -88.04743,
      city: 'Coleman',
      state: 'WI',
    },
    54113: {
      lat: 44.26404,
      lng: -88.30705,
      city: 'Combined Locks',
      state: 'WI',
    },
    54114: {
      lat: 45.25254,
      lng: -88.12197,
      city: 'Crivitz',
      state: 'WI',
    },
    54115: {
      lat: 44.40552,
      lng: -88.09195,
      city: 'De Pere',
      state: 'WI',
    },
    54119: {
      lat: 45.62488,
      lng: -88.18285,
      city: 'Dunbar',
      state: 'WI',
    },
    54120: {
      lat: 45.75892,
      lng: -88.49089,
      city: 'Fence',
      state: 'WI',
    },
    54121: {
      lat: 45.86385,
      lng: -88.32899,
      city: 'Florence',
      state: 'WI',
    },
    54123: {
      lat: 44.20642,
      lng: -88.15201,
      city: 'Forest Junction',
      state: 'WI',
    },
    54124: {
      lat: 44.90701,
      lng: -88.36616,
      city: 'Gillett',
      state: 'WI',
    },
    54125: {
      lat: 45.58995,
      lng: -88.38325,
      city: 'Goodman',
      state: 'WI',
    },
    54126: {
      lat: 44.28925,
      lng: -88.03218,
      city: 'Greenleaf',
      state: 'WI',
    },
    54127: {
      lat: 44.79693,
      lng: -88.26815,
      city: 'Green Valley',
      state: 'WI',
    },
    54128: {
      lat: 44.86675,
      lng: -88.80525,
      city: 'Gresham',
      state: 'WI',
    },
    54129: {
      lat: 44.12902,
      lng: -88.20146,
      city: 'Hilbert',
      state: 'WI',
    },
    54130: {
      lat: 44.31194,
      lng: -88.24301,
      city: 'Kaukauna',
      state: 'WI',
    },
    54135: {
      lat: 44.91468,
      lng: -88.61209,
      city: 'Keshena',
      state: 'WI',
    },
    54136: {
      lat: 44.26834,
      lng: -88.33852,
      city: 'Kimberly',
      state: 'WI',
    },
    54137: {
      lat: 44.75843,
      lng: -88.25605,
      city: 'Krakow',
      state: 'WI',
    },
    54138: {
      lat: 45.33064,
      lng: -88.42783,
      city: 'Lakewood',
      state: 'WI',
    },
    54139: { lat: 44.9539, lng: -88.07152, city: 'Lena', state: 'WI' },
    54140: {
      lat: 44.28689,
      lng: -88.31045,
      city: 'Little Chute',
      state: 'WI',
    },
    54141: {
      lat: 44.72753,
      lng: -88.00567,
      city: 'Little Suamico',
      state: 'WI',
    },
    54143: {
      lat: 45.08708,
      lng: -87.70045,
      city: 'Marinette',
      state: 'WI',
    },
    54149: {
      lat: 45.204,
      lng: -88.47445,
      city: 'Mountain',
      state: 'WI',
    },
    54150: {
      lat: 44.97237,
      lng: -88.7906,
      city: 'Neopit',
      state: 'WI',
    },
    54151: {
      lat: 45.72352,
      lng: -87.99752,
      city: 'Niagara',
      state: 'WI',
    },
    54152: {
      lat: 44.56645,
      lng: -88.47049,
      city: 'Nichols',
      state: 'WI',
    },
    54153: {
      lat: 44.89939,
      lng: -87.92516,
      city: 'Oconto',
      state: 'WI',
    },
    54154: {
      lat: 44.86702,
      lng: -88.19058,
      city: 'Oconto Falls',
      state: 'WI',
    },
    54155: {
      lat: 44.51979,
      lng: -88.19038,
      city: 'Oneida',
      state: 'WI',
    },
    54156: {
      lat: 45.60099,
      lng: -87.93963,
      city: 'Pembine',
      state: 'WI',
    },
    54157: {
      lat: 45.05001,
      lng: -87.81217,
      city: 'Peshtigo',
      state: 'WI',
    },
    54159: {
      lat: 45.20027,
      lng: -87.82682,
      city: 'Porterfield',
      state: 'WI',
    },
    54160: {
      lat: 44.12029,
      lng: -88.09895,
      city: 'Potter',
      state: 'WI',
    },
    54161: {
      lat: 45.12449,
      lng: -88.15696,
      city: 'Pound',
      state: 'WI',
    },
    54162: {
      lat: 44.66417,
      lng: -88.27689,
      city: 'Pulaski',
      state: 'WI',
    },
    54165: {
      lat: 44.52063,
      lng: -88.31683,
      city: 'Seymour',
      state: 'WI',
    },
    54166: {
      lat: 44.77894,
      lng: -88.63567,
      city: 'Shawano',
      state: 'WI',
    },
    54169: {
      lat: 44.17799,
      lng: -88.2772,
      city: 'Sherwood',
      state: 'WI',
    },
    54170: {
      lat: 44.51783,
      lng: -88.57241,
      city: 'Shiocton',
      state: 'WI',
    },
    54171: {
      lat: 44.71957,
      lng: -88.12017,
      city: 'Sobieski',
      state: 'WI',
    },
    54173: {
      lat: 44.64228,
      lng: -88.03212,
      city: 'Suamico',
      state: 'WI',
    },
    54174: {
      lat: 45.03553,
      lng: -88.43492,
      city: 'Suring',
      state: 'WI',
    },
    54175: {
      lat: 45.30552,
      lng: -88.62394,
      city: 'Townsend',
      state: 'WI',
    },
    54177: {
      lat: 45.36428,
      lng: -87.89183,
      city: 'Wausaukee',
      state: 'WI',
    },
    54180: {
      lat: 44.33139,
      lng: -88.17462,
      city: 'Wrightstown',
      state: 'WI',
    },
    54201: {
      lat: 44.61987,
      lng: -87.49399,
      city: 'Algoma',
      state: 'WI',
    },
    54202: {
      lat: 45.0779,
      lng: -87.13837,
      city: 'Baileys Harbor',
      state: 'WI',
    },
    54204: {
      lat: 44.76189,
      lng: -87.61552,
      city: 'Brussels',
      state: 'WI',
    },
    54205: {
      lat: 44.60444,
      lng: -87.62912,
      city: 'Casco',
      state: 'WI',
    },
    54207: {
      lat: 44.08425,
      lng: -87.98093,
      city: 'Collins',
      state: 'WI',
    },
    54208: {
      lat: 44.36239,
      lng: -87.79619,
      city: 'Denmark',
      state: 'WI',
    },
    54209: {
      lat: 45.01126,
      lng: -87.27278,
      city: 'Egg Harbor',
      state: 'WI',
    },
    54210: {
      lat: 45.2535,
      lng: -87.0352,
      city: 'Ellison Bay',
      state: 'WI',
    },
    54211: {
      lat: 45.15942,
      lng: -87.16783,
      city: 'Ephraim',
      state: 'WI',
    },
    54212: {
      lat: 45.12169,
      lng: -87.23024,
      city: 'Fish Creek',
      state: 'WI',
    },
    54213: {
      lat: 44.69926,
      lng: -87.52114,
      city: 'Forestville',
      state: 'WI',
    },
    54214: {
      lat: 44.19893,
      lng: -87.71441,
      city: 'Francis Creek',
      state: 'WI',
    },
    54215: {
      lat: 44.22608,
      lng: -87.80357,
      city: 'Kellnersville',
      state: 'WI',
    },
    54216: {
      lat: 44.43651,
      lng: -87.58069,
      city: 'Kewaunee',
      state: 'WI',
    },
    54217: {
      lat: 44.54694,
      lng: -87.71748,
      city: 'Luxemburg',
      state: 'WI',
    },
    54220: {
      lat: 44.09644,
      lng: -87.73131,
      city: 'Manitowoc',
      state: 'WI',
    },
    54227: {
      lat: 44.28096,
      lng: -87.80656,
      city: 'Maribel',
      state: 'WI',
    },
    54228: {
      lat: 44.27677,
      lng: -87.64875,
      city: 'Mishicot',
      state: 'WI',
    },
    54229: {
      lat: 44.56411,
      lng: -87.82081,
      city: 'New Franken',
      state: 'WI',
    },
    54230: {
      lat: 44.15749,
      lng: -87.91202,
      city: 'Reedsville',
      state: 'WI',
    },
    54232: {
      lat: 44.00668,
      lng: -87.92462,
      city: 'Saint Nazianz',
      state: 'WI',
    },
    54234: {
      lat: 45.18377,
      lng: -87.09239,
      city: 'Sister Bay',
      state: 'WI',
    },
    54235: {
      lat: 44.84218,
      lng: -87.37677,
      city: 'Sturgeon Bay',
      state: 'WI',
    },
    54241: {
      lat: 44.21758,
      lng: -87.59723,
      city: 'Two Rivers',
      state: 'WI',
    },
    54245: {
      lat: 44.02728,
      lng: -87.8977,
      city: 'Valders',
      state: 'WI',
    },
    54246: {
      lat: 45.37538,
      lng: -86.89721,
      city: 'Washington Island',
      state: 'WI',
    },
    54247: {
      lat: 44.19686,
      lng: -87.78564,
      city: 'Whitelaw',
      state: 'WI',
    },
    54301: {
      lat: 44.48193,
      lng: -88.02029,
      city: 'Green Bay',
      state: 'WI',
    },
    54302: {
      lat: 44.50663,
      lng: -87.97668,
      city: 'Green Bay',
      state: 'WI',
    },
    54303: {
      lat: 44.53931,
      lng: -88.05115,
      city: 'Green Bay',
      state: 'WI',
    },
    54304: {
      lat: 44.49419,
      lng: -88.06758,
      city: 'Green Bay',
      state: 'WI',
    },
    54307: {
      lat: 44.47049,
      lng: -88.0366,
      city: 'Green Bay',
      state: 'WI',
    },
    54311: {
      lat: 44.48377,
      lng: -87.89483,
      city: 'Green Bay',
      state: 'WI',
    },
    54313: {
      lat: 44.58923,
      lng: -88.11088,
      city: 'Green Bay',
      state: 'WI',
    },
    54401: {
      lat: 44.96647,
      lng: -89.70794,
      city: 'Wausau',
      state: 'WI',
    },
    54403: {
      lat: 45.03142,
      lng: -89.51343,
      city: 'Wausau',
      state: 'WI',
    },
    54405: {
      lat: 44.96289,
      lng: -90.2797,
      city: 'Abbotsford',
      state: 'WI',
    },
    54406: {
      lat: 44.41122,
      lng: -89.31211,
      city: 'Amherst',
      state: 'WI',
    },
    54407: {
      lat: 44.52712,
      lng: -89.29418,
      city: 'Amherst Junction',
      state: 'WI',
    },
    54408: {
      lat: 45.02861,
      lng: -89.30922,
      city: 'Aniwa',
      state: 'WI',
    },
    54409: {
      lat: 45.11928,
      lng: -89.16909,
      city: 'Antigo',
      state: 'WI',
    },
    54410: {
      lat: 44.54158,
      lng: -90.02877,
      city: 'Arpin',
      state: 'WI',
    },
    54411: {
      lat: 45.05023,
      lng: -90.02837,
      city: 'Athens',
      state: 'WI',
    },
    54412: {
      lat: 44.66859,
      lng: -89.98238,
      city: 'Auburndale',
      state: 'WI',
    },
    54413: {
      lat: 44.28363,
      lng: -90.15185,
      city: 'Babcock',
      state: 'WI',
    },
    54414: {
      lat: 44.95589,
      lng: -89.18202,
      city: 'Birnamwood',
      state: 'WI',
    },
    54416: {
      lat: 44.8822,
      lng: -88.94153,
      city: 'Bowler',
      state: 'WI',
    },
    54417: {
      lat: 45.02749,
      lng: -89.65418,
      city: 'Brokaw',
      state: 'WI',
    },
    54418: {
      lat: 45.21566,
      lng: -88.98003,
      city: 'Bryant',
      state: 'WI',
    },
    54420: {
      lat: 44.61043,
      lng: -90.35036,
      city: 'Chili',
      state: 'WI',
    },
    54421: {
      lat: 44.89866,
      lng: -90.29532,
      city: 'Colby',
      state: 'WI',
    },
    54422: {
      lat: 44.98279,
      lng: -90.45264,
      city: 'Curtiss',
      state: 'WI',
    },
    54423: {
      lat: 44.58049,
      lng: -89.41642,
      city: 'Custer',
      state: 'WI',
    },
    54424: {
      lat: 45.28471,
      lng: -89.18701,
      city: 'Deerbrook',
      state: 'WI',
    },
    54425: {
      lat: 45.0086,
      lng: -90.34249,
      city: 'Dorchester',
      state: 'WI',
    },
    54426: {
      lat: 44.90804,
      lng: -89.98832,
      city: 'Edgar',
      state: 'WI',
    },
    54427: {
      lat: 44.82401,
      lng: -89.25789,
      city: 'Eland',
      state: 'WI',
    },
    54428: {
      lat: 45.43511,
      lng: -89.17282,
      city: 'Elcho',
      state: 'WI',
    },
    54430: { lat: 45.1395, lng: -88.8872, city: 'Elton', state: 'WI' },
    54433: {
      lat: 45.20649,
      lng: -90.81756,
      city: 'Gilman',
      state: 'WI',
    },
    54435: {
      lat: 45.35084,
      lng: -89.44349,
      city: 'Gleason',
      state: 'WI',
    },
    54436: {
      lat: 44.54149,
      lng: -90.43101,
      city: 'Granton',
      state: 'WI',
    },
    54437: {
      lat: 44.77867,
      lng: -90.63866,
      city: 'Greenwood',
      state: 'WI',
    },
    54440: {
      lat: 44.80621,
      lng: -89.38838,
      city: 'Hatley',
      state: 'WI',
    },
    54441: {
      lat: 44.64202,
      lng: -90.10604,
      city: 'Hewitt',
      state: 'WI',
    },
    54442: { lat: 45.36532, lng: -89.65439, city: 'Irma', state: 'WI' },
    54443: {
      lat: 44.61243,
      lng: -89.75264,
      city: 'Junction City',
      state: 'WI',
    },
    54446: { lat: 44.7533, lng: -90.47149, city: 'Loyal', state: 'WI' },
    54447: {
      lat: 45.09061,
      lng: -90.72911,
      city: 'Lublin',
      state: 'WI',
    },
    54448: {
      lat: 44.93094,
      lng: -89.83422,
      city: 'Marathon',
      state: 'WI',
    },
    54449: {
      lat: 44.63914,
      lng: -90.19755,
      city: 'Marshfield',
      state: 'WI',
    },
    54450: {
      lat: 45.00367,
      lng: -89.04172,
      city: 'Mattoon',
      state: 'WI',
    },
    54451: {
      lat: 45.19131,
      lng: -90.40172,
      city: 'Medford',
      state: 'WI',
    },
    54452: {
      lat: 45.20879,
      lng: -89.76743,
      city: 'Merrill',
      state: 'WI',
    },
    54454: {
      lat: 44.60259,
      lng: -89.8846,
      city: 'Milladore',
      state: 'WI',
    },
    54455: {
      lat: 44.7625,
      lng: -89.68559,
      city: 'Mosinee',
      state: 'WI',
    },
    54456: {
      lat: 44.54511,
      lng: -90.63824,
      city: 'Neillsville',
      state: 'WI',
    },
    54457: {
      lat: 44.22965,
      lng: -89.89502,
      city: 'Nekoosa',
      state: 'WI',
    },
    54458: {
      lat: 44.49867,
      lng: -89.31027,
      city: 'Nelsonville',
      state: 'WI',
    },
    54459: {
      lat: 45.42515,
      lng: -90.26924,
      city: 'Ogema',
      state: 'WI',
    },
    54460: { lat: 44.94703, lng: -90.53728, city: 'Owen', state: 'WI' },
    54462: {
      lat: 45.39408,
      lng: -89.00031,
      city: 'Pearson',
      state: 'WI',
    },
    54463: {
      lat: 45.50876,
      lng: -89.22536,
      city: 'Pelican Lake',
      state: 'WI',
    },
    54464: {
      lat: 45.05486,
      lng: -89.01748,
      city: 'Phlox',
      state: 'WI',
    },
    54465: {
      lat: 45.39327,
      lng: -88.88334,
      city: 'Pickerel',
      state: 'WI',
    },
    54466: {
      lat: 44.3855,
      lng: -90.31687,
      city: 'Pittsville',
      state: 'WI',
    },
    54467: {
      lat: 44.41575,
      lng: -89.54062,
      city: 'Plover',
      state: 'WI',
    },
    54469: {
      lat: 44.34642,
      lng: -89.87028,
      city: 'Port Edwards',
      state: 'WI',
    },
    54470: {
      lat: 45.29419,
      lng: -90.13852,
      city: 'Rib Lake',
      state: 'WI',
    },
    54471: {
      lat: 44.93361,
      lng: -89.41914,
      city: 'Ringle',
      state: 'WI',
    },
    54473: {
      lat: 44.64984,
      lng: -89.35634,
      city: 'Rosholt',
      state: 'WI',
    },
    54474: {
      lat: 44.87395,
      lng: -89.62223,
      city: 'Rothschild',
      state: 'WI',
    },
    54475: {
      lat: 44.48204,
      lng: -89.78825,
      city: 'Rudolph',
      state: 'WI',
    },
    54476: {
      lat: 44.88816,
      lng: -89.52389,
      city: 'Schofield',
      state: 'WI',
    },
    54479: {
      lat: 44.75688,
      lng: -90.32525,
      city: 'Spencer',
      state: 'WI',
    },
    54480: {
      lat: 45.05908,
      lng: -90.27597,
      city: 'Stetsonville',
      state: 'WI',
    },
    54481: {
      lat: 44.51935,
      lng: -89.63678,
      city: 'Stevens Point',
      state: 'WI',
    },
    54482: {
      lat: 44.55174,
      lng: -89.5092,
      city: 'Stevens Point',
      state: 'WI',
    },
    54484: {
      lat: 44.79988,
      lng: -90.07962,
      city: 'Stratford',
      state: 'WI',
    },
    54485: {
      lat: 45.39373,
      lng: -89.21894,
      city: 'Summit Lake',
      state: 'WI',
    },
    54486: {
      lat: 44.72595,
      lng: -89.05124,
      city: 'Tigerton',
      state: 'WI',
    },
    54487: {
      lat: 45.50429,
      lng: -89.75114,
      city: 'Tomahawk',
      state: 'WI',
    },
    54488: {
      lat: 44.84335,
      lng: -90.33778,
      city: 'Unity',
      state: 'WI',
    },
    54489: {
      lat: 44.45445,
      lng: -90.0075,
      city: 'Vesper',
      state: 'WI',
    },
    54490: {
      lat: 45.33622,
      lng: -90.41102,
      city: 'Westboro',
      state: 'WI',
    },
    54491: {
      lat: 45.2343,
      lng: -88.76541,
      city: 'White Lake',
      state: 'WI',
    },
    54493: {
      lat: 44.70083,
      lng: -90.80107,
      city: 'Willard',
      state: 'WI',
    },
    54494: {
      lat: 44.34414,
      lng: -89.74054,
      city: 'Wisconsin Rapids',
      state: 'WI',
    },
    54495: {
      lat: 44.39081,
      lng: -89.95351,
      city: 'Wisconsin Rapids',
      state: 'WI',
    },
    54498: {
      lat: 45.03278,
      lng: -90.62105,
      city: 'Withee',
      state: 'WI',
    },
    54499: {
      lat: 44.78963,
      lng: -89.1801,
      city: 'Wittenberg',
      state: 'WI',
    },
    54501: {
      lat: 45.65973,
      lng: -89.34303,
      city: 'Rhinelander',
      state: 'WI',
    },
    54511: {
      lat: 45.72365,
      lng: -88.83177,
      city: 'Argonne',
      state: 'WI',
    },
    54512: {
      lat: 46.0957,
      lng: -89.66748,
      city: 'Boulder Junction',
      state: 'WI',
    },
    54513: {
      lat: 45.58246,
      lng: -90.11986,
      city: 'Brantwood',
      state: 'WI',
    },
    54514: {
      lat: 46.03761,
      lng: -90.49378,
      city: 'Butternut',
      state: 'WI',
    },
    54515: {
      lat: 45.5146,
      lng: -90.50217,
      city: 'Catawba',
      state: 'WI',
    },
    54517: {
      lat: 46.11899,
      lng: -90.93035,
      city: 'Clam Lake',
      state: 'WI',
    },
    54519: {
      lat: 46.05355,
      lng: -89.26877,
      city: 'Conover',
      state: 'WI',
    },
    54520: {
      lat: 45.53773,
      lng: -88.92303,
      city: 'Crandon',
      state: 'WI',
    },
    54521: {
      lat: 45.9162,
      lng: -89.25451,
      city: 'Eagle River',
      state: 'WI',
    },
    54524: {
      lat: 45.84416,
      lng: -90.38922,
      city: 'Fifield',
      state: 'WI',
    },
    54525: { lat: 46.4335, lng: -90.22603, city: 'Gile', state: 'WI' },
    54526: {
      lat: 45.50969,
      lng: -90.85872,
      city: 'Glen Flora',
      state: 'WI',
    },
    54527: {
      lat: 46.13543,
      lng: -90.6366,
      city: 'Glidden',
      state: 'WI',
    },
    54529: {
      lat: 45.67821,
      lng: -89.66006,
      city: 'Harshaw',
      state: 'WI',
    },
    54530: {
      lat: 45.53202,
      lng: -90.71537,
      city: 'Hawkins',
      state: 'WI',
    },
    54531: {
      lat: 45.74968,
      lng: -89.78822,
      city: 'Hazelhurst',
      state: 'WI',
    },
    54534: {
      lat: 46.3545,
      lng: -90.23769,
      city: 'Hurley',
      state: 'WI',
    },
    54536: {
      lat: 46.33603,
      lng: -90.34488,
      city: 'Iron Belt',
      state: 'WI',
    },
    54537: {
      lat: 45.53136,
      lng: -90.60213,
      city: 'Kennan',
      state: 'WI',
    },
    54538: {
      lat: 45.97244,
      lng: -89.91484,
      city: 'Lac Du Flambeau',
      state: 'WI',
    },
    54539: {
      lat: 45.80705,
      lng: -89.5762,
      city: 'Lake Tomahawk',
      state: 'WI',
    },
    54540: {
      lat: 46.15505,
      lng: -89.38216,
      city: "Land O'Lakes",
      state: 'WI',
    },
    54541: {
      lat: 45.55218,
      lng: -88.65937,
      city: 'Laona',
      state: 'WI',
    },
    54542: {
      lat: 45.92127,
      lng: -88.72536,
      city: 'Long Lake',
      state: 'WI',
    },
    54545: {
      lat: 46.1326,
      lng: -89.85208,
      city: 'Manitowish Waters',
      state: 'WI',
    },
    54546: {
      lat: 46.28685,
      lng: -90.70977,
      city: 'Mellen',
      state: 'WI',
    },
    54547: {
      lat: 46.18951,
      lng: -90.0994,
      city: 'Mercer',
      state: 'WI',
    },
    54548: {
      lat: 45.86218,
      lng: -89.82663,
      city: 'Minocqua',
      state: 'WI',
    },
    54550: {
      lat: 46.35775,
      lng: -90.26409,
      city: 'Montreal',
      state: 'WI',
    },
    54552: {
      lat: 45.93615,
      lng: -90.29081,
      city: 'Park Falls',
      state: 'WI',
    },
    54554: {
      lat: 46.05481,
      lng: -89.06138,
      city: 'Phelps',
      state: 'WI',
    },
    54555: {
      lat: 45.71977,
      lng: -90.36403,
      city: 'Phillips',
      state: 'WI',
    },
    54556: {
      lat: 45.53586,
      lng: -90.31386,
      city: 'Prentice',
      state: 'WI',
    },
    54557: {
      lat: 46.22178,
      lng: -89.75453,
      city: 'Presque Isle',
      state: 'WI',
    },
    54558: {
      lat: 45.92417,
      lng: -89.49746,
      city: 'Saint Germain',
      state: 'WI',
    },
    54559: {
      lat: 46.49382,
      lng: -90.47083,
      city: 'Saxon',
      state: 'WI',
    },
    54560: {
      lat: 46.00997,
      lng: -89.54365,
      city: 'Sayner',
      state: 'WI',
    },
    54561: {
      lat: 46.07102,
      lng: -89.46451,
      city: 'Star Lake',
      state: 'WI',
    },
    54562: {
      lat: 45.81081,
      lng: -89.0813,
      city: 'Three Lakes',
      state: 'WI',
    },
    54563: { lat: 45.46448, lng: -90.97428, city: 'Tony', state: 'WI' },
    54564: {
      lat: 45.6105,
      lng: -89.97223,
      city: 'Tripoli',
      state: 'WI',
    },
    54565: {
      lat: 46.30474,
      lng: -90.45182,
      city: 'Upson',
      state: 'WI',
    },
    54566: {
      lat: 45.42481,
      lng: -88.63263,
      city: 'Wabeno',
      state: 'WI',
    },
    54568: {
      lat: 45.94876,
      lng: -89.67546,
      city: 'Woodruff',
      state: 'WI',
    },
    54601: {
      lat: 43.80199,
      lng: -91.14559,
      city: 'La Crosse',
      state: 'WI',
    },
    54603: {
      lat: 43.85729,
      lng: -91.24791,
      city: 'La Crosse',
      state: 'WI',
    },
    54610: { lat: 44.37077, lng: -91.82549, city: 'Alma', state: 'WI' },
    54611: {
      lat: 44.44624,
      lng: -90.95164,
      city: 'Alma Center',
      state: 'WI',
    },
    54612: {
      lat: 44.24882,
      lng: -91.50066,
      city: 'Arcadia',
      state: 'WI',
    },
    54613: {
      lat: 44.07549,
      lng: -89.92131,
      city: 'Arkdale',
      state: 'WI',
    },
    54614: {
      lat: 43.90242,
      lng: -90.9729,
      city: 'Bangor',
      state: 'WI',
    },
    54615: {
      lat: 44.26158,
      lng: -90.78049,
      city: 'Black River Falls',
      state: 'WI',
    },
    54616: { lat: 44.30402, lng: -91.2283, city: 'Blair', state: 'WI' },
    54618: {
      lat: 43.95921,
      lng: -90.28967,
      city: 'Camp Douglas',
      state: 'WI',
    },
    54619: {
      lat: 43.75562,
      lng: -90.77968,
      city: 'Cashton',
      state: 'WI',
    },
    54621: {
      lat: 43.65632,
      lng: -91.08199,
      city: 'Chaseburg',
      state: 'WI',
    },
    54622: {
      lat: 44.26953,
      lng: -91.76629,
      city: 'Cochrane',
      state: 'WI',
    },
    54623: {
      lat: 43.72894,
      lng: -91.03065,
      city: 'Coon Valley',
      state: 'WI',
    },
    54624: {
      lat: 43.46575,
      lng: -91.14066,
      city: 'De Soto',
      state: 'WI',
    },
    54625: {
      lat: 44.13287,
      lng: -91.52507,
      city: 'Dodge',
      state: 'WI',
    },
    54626: {
      lat: 43.2162,
      lng: -91.03608,
      city: 'Eastman',
      state: 'WI',
    },
    54627: {
      lat: 44.16873,
      lng: -91.23634,
      city: 'Ettrick',
      state: 'WI',
    },
    54628: {
      lat: 43.37572,
      lng: -91.02618,
      city: 'Ferryville',
      state: 'WI',
    },
    54629: {
      lat: 44.15928,
      lng: -91.66661,
      city: 'Fountain City',
      state: 'WI',
    },
    54630: {
      lat: 44.10339,
      lng: -91.35881,
      city: 'Galesville',
      state: 'WI',
    },
    54631: {
      lat: 43.28795,
      lng: -90.8327,
      city: 'Gays Mills',
      state: 'WI',
    },
    54632: {
      lat: 43.56249,
      lng: -91.14695,
      city: 'Genoa',
      state: 'WI',
    },
    54634: {
      lat: 43.59753,
      lng: -90.42553,
      city: 'Hillsboro',
      state: 'WI',
    },
    54635: {
      lat: 44.40871,
      lng: -91.04455,
      city: 'Hixton',
      state: 'WI',
    },
    54636: {
      lat: 44.00732,
      lng: -91.22682,
      city: 'Holmen',
      state: 'WI',
    },
    54637: {
      lat: 43.87874,
      lng: -90.27076,
      city: 'Hustler',
      state: 'WI',
    },
    54638: {
      lat: 43.79088,
      lng: -90.3875,
      city: 'Kendall',
      state: 'WI',
    },
    54639: {
      lat: 43.60566,
      lng: -90.62021,
      city: 'La Farge',
      state: 'WI',
    },
    54642: {
      lat: 44.14978,
      lng: -91.05303,
      city: 'Melrose',
      state: 'WI',
    },
    54643: {
      lat: 44.18758,
      lng: -90.63432,
      city: 'Millston',
      state: 'WI',
    },
    54644: {
      lat: 44.03089,
      lng: -91.04326,
      city: 'Mindoro',
      state: 'WI',
    },
    54645: {
      lat: 43.32084,
      lng: -90.92901,
      city: 'Mount Sterling',
      state: 'WI',
    },
    54646: {
      lat: 44.08807,
      lng: -90.0912,
      city: 'Necedah',
      state: 'WI',
    },
    54648: {
      lat: 43.83544,
      lng: -90.63553,
      city: 'Norwalk',
      state: 'WI',
    },
    54650: {
      lat: 43.9099,
      lng: -91.22424,
      city: 'Onalaska',
      state: 'WI',
    },
    54651: {
      lat: 43.72368,
      lng: -90.57004,
      city: 'Ontario',
      state: 'WI',
    },
    54652: {
      lat: 43.46133,
      lng: -90.76825,
      city: 'Readstown',
      state: 'WI',
    },
    54653: {
      lat: 43.82586,
      lng: -90.9129,
      city: 'Rockland',
      state: 'WI',
    },
    54654: {
      lat: 43.26853,
      lng: -90.95419,
      city: 'Seneca',
      state: 'WI',
    },
    54655: {
      lat: 43.37877,
      lng: -90.76423,
      city: 'Soldiers Grove',
      state: 'WI',
    },
    54656: {
      lat: 44.00291,
      lng: -90.79601,
      city: 'Sparta',
      state: 'WI',
    },
    54657: {
      lat: 43.19447,
      lng: -90.90678,
      city: 'Steuben',
      state: 'WI',
    },
    54658: {
      lat: 43.67768,
      lng: -91.19248,
      city: 'Stoddard',
      state: 'WI',
    },
    54659: {
      lat: 44.29704,
      lng: -91.10065,
      city: 'Taylor',
      state: 'WI',
    },
    54660: {
      lat: 43.98525,
      lng: -90.50496,
      city: 'Tomah',
      state: 'WI',
    },
    54661: {
      lat: 44.05943,
      lng: -91.47133,
      city: 'Trempealeau',
      state: 'WI',
    },
    54664: {
      lat: 43.49912,
      lng: -90.64076,
      city: 'Viola',
      state: 'WI',
    },
    54665: {
      lat: 43.5319,
      lng: -90.92134,
      city: 'Viroqua',
      state: 'WI',
    },
    54666: {
      lat: 44.16201,
      lng: -90.4399,
      city: 'Warrens',
      state: 'WI',
    },
    54667: {
      lat: 43.66703,
      lng: -90.86166,
      city: 'Westby',
      state: 'WI',
    },
    54669: {
      lat: 43.91171,
      lng: -91.09046,
      city: 'West Salem',
      state: 'WI',
    },
    54670: {
      lat: 43.83802,
      lng: -90.48273,
      city: 'Wilton',
      state: 'WI',
    },
    54701: {
      lat: 44.7432,
      lng: -91.50899,
      city: 'Eau Claire',
      state: 'WI',
    },
    54703: {
      lat: 44.83311,
      lng: -91.5185,
      city: 'Eau Claire',
      state: 'WI',
    },
    54720: {
      lat: 44.80511,
      lng: -91.43646,
      city: 'Altoona',
      state: 'WI',
    },
    54721: {
      lat: 44.6304,
      lng: -92.07098,
      city: 'Arkansaw',
      state: 'WI',
    },
    54722: {
      lat: 44.71702,
      lng: -91.09874,
      city: 'Augusta',
      state: 'WI',
    },
    54723: {
      lat: 44.61063,
      lng: -92.42893,
      city: 'Bay City',
      state: 'WI',
    },
    54724: {
      lat: 45.10793,
      lng: -91.48458,
      city: 'Bloomer',
      state: 'WI',
    },
    54725: {
      lat: 45.07103,
      lng: -92.01062,
      city: 'Boyceville',
      state: 'WI',
    },
    54726: { lat: 44.94151, lng: -91.01975, city: 'Boyd', state: 'WI' },
    54727: {
      lat: 44.95827,
      lng: -91.15873,
      city: 'Cadott',
      state: 'WI',
    },
    54728: {
      lat: 45.30778,
      lng: -91.6417,
      city: 'Chetek',
      state: 'WI',
    },
    54729: {
      lat: 44.94533,
      lng: -91.39342,
      city: 'Chippewa Falls',
      state: 'WI',
    },
    54730: {
      lat: 45.01836,
      lng: -91.72143,
      city: 'Colfax',
      state: 'WI',
    },
    54731: {
      lat: 45.34656,
      lng: -91.08304,
      city: 'Conrath',
      state: 'WI',
    },
    54732: {
      lat: 45.14571,
      lng: -91.16543,
      city: 'Cornell',
      state: 'WI',
    },
    54733: {
      lat: 45.28331,
      lng: -91.85641,
      city: 'Dallas',
      state: 'WI',
    },
    54734: {
      lat: 45.09045,
      lng: -92.11358,
      city: 'Downing',
      state: 'WI',
    },
    54736: {
      lat: 44.60345,
      lng: -91.90455,
      city: 'Durand',
      state: 'WI',
    },
    54737: {
      lat: 44.71593,
      lng: -91.98298,
      city: 'Eau Galle',
      state: 'WI',
    },
    54738: {
      lat: 44.59478,
      lng: -91.47892,
      city: 'Eleva',
      state: 'WI',
    },
    54739: {
      lat: 44.86177,
      lng: -91.70097,
      city: 'Elk Mound',
      state: 'WI',
    },
    54740: {
      lat: 44.74427,
      lng: -92.15853,
      city: 'Elmwood',
      state: 'WI',
    },
    54741: {
      lat: 44.61237,
      lng: -90.98121,
      city: 'Fairchild',
      state: 'WI',
    },
    54742: {
      lat: 44.76319,
      lng: -91.2722,
      city: 'Fall Creek',
      state: 'WI',
    },
    54745: {
      lat: 45.24875,
      lng: -91.13494,
      city: 'Holcombe',
      state: 'WI',
    },
    54746: {
      lat: 44.54543,
      lng: -90.87217,
      city: 'Humbird',
      state: 'WI',
    },
    54747: {
      lat: 44.39438,
      lng: -91.5035,
      city: 'Independence',
      state: 'WI',
    },
    54748: {
      lat: 45.07919,
      lng: -91.26586,
      city: 'Jim Falls',
      state: 'WI',
    },
    54749: {
      lat: 44.94862,
      lng: -92.09785,
      city: 'Knapp',
      state: 'WI',
    },
    54750: {
      lat: 44.62052,
      lng: -92.29513,
      city: 'Maiden Rock',
      state: 'WI',
    },
    54751: {
      lat: 44.84971,
      lng: -91.92994,
      city: 'Menomonie',
      state: 'WI',
    },
    54754: {
      lat: 44.42913,
      lng: -90.77527,
      city: 'Merrillan',
      state: 'WI',
    },
    54755: {
      lat: 44.57513,
      lng: -91.67971,
      city: 'Mondovi',
      state: 'WI',
    },
    54756: {
      lat: 44.46153,
      lng: -91.94552,
      city: 'Nelson',
      state: 'WI',
    },
    54757: {
      lat: 45.23085,
      lng: -91.52498,
      city: 'New Auburn',
      state: 'WI',
    },
    54758: { lat: 44.54546, lng: -91.2173, city: 'Osseo', state: 'WI' },
    54759: { lat: 44.48936, lng: -92.1232, city: 'Pepin', state: 'WI' },
    54760: {
      lat: 44.42412,
      lng: -91.20626,
      city: 'Pigeon Falls',
      state: 'WI',
    },
    54761: {
      lat: 44.62667,
      lng: -92.17908,
      city: 'Plum City',
      state: 'WI',
    },
    54762: {
      lat: 45.25107,
      lng: -91.98967,
      city: 'Prairie Farm',
      state: 'WI',
    },
    54763: {
      lat: 45.179,
      lng: -91.87683,
      city: 'Ridgeland',
      state: 'WI',
    },
    54766: {
      lat: 45.33667,
      lng: -90.86566,
      city: 'Sheldon',
      state: 'WI',
    },
    54767: {
      lat: 44.82645,
      lng: -92.25513,
      city: 'Spring Valley',
      state: 'WI',
    },
    54768: {
      lat: 44.96312,
      lng: -90.92761,
      city: 'Stanley',
      state: 'WI',
    },
    54769: {
      lat: 44.50897,
      lng: -92.23304,
      city: 'Stockholm',
      state: 'WI',
    },
    54770: {
      lat: 44.55121,
      lng: -91.37259,
      city: 'Strum',
      state: 'WI',
    },
    54771: {
      lat: 44.92356,
      lng: -90.80579,
      city: 'Thorp',
      state: 'WI',
    },
    54772: {
      lat: 45.09668,
      lng: -91.87303,
      city: 'Wheeler',
      state: 'WI',
    },
    54773: {
      lat: 44.39301,
      lng: -91.3002,
      city: 'Whitehall',
      state: 'WI',
    },
    54801: {
      lat: 45.86711,
      lng: -91.92266,
      city: 'Spooner',
      state: 'WI',
    },
    54805: {
      lat: 45.41465,
      lng: -92.02311,
      city: 'Almena',
      state: 'WI',
    },
    54806: {
      lat: 46.54784,
      lng: -90.85061,
      city: 'Ashland',
      state: 'WI',
    },
    54810: {
      lat: 45.46126,
      lng: -92.38403,
      city: 'Balsam Lake',
      state: 'WI',
    },
    54812: {
      lat: 45.39911,
      lng: -91.88103,
      city: 'Barron',
      state: 'WI',
    },
    54813: {
      lat: 45.65477,
      lng: -92.03071,
      city: 'Barronett',
      state: 'WI',
    },
    54814: {
      lat: 46.85672,
      lng: -90.91876,
      city: 'Bayfield',
      state: 'WI',
    },
    54817: {
      lat: 45.67056,
      lng: -91.53942,
      city: 'Birchwood',
      state: 'WI',
    },
    54819: {
      lat: 45.47636,
      lng: -91.30307,
      city: 'Bruce',
      state: 'WI',
    },
    54820: {
      lat: 46.56024,
      lng: -91.55475,
      city: 'Brule',
      state: 'WI',
    },
    54821: {
      lat: 46.22428,
      lng: -91.16508,
      city: 'Cable',
      state: 'WI',
    },
    54822: {
      lat: 45.3944,
      lng: -91.69995,
      city: 'Cameron',
      state: 'WI',
    },
    54824: {
      lat: 45.4753,
      lng: -92.54017,
      city: 'Centuria',
      state: 'WI',
    },
    54826: {
      lat: 45.49971,
      lng: -92.16422,
      city: 'Comstock',
      state: 'WI',
    },
    54827: {
      lat: 46.80215,
      lng: -91.10069,
      city: 'Cornucopia',
      state: 'WI',
    },
    54828: {
      lat: 45.84201,
      lng: -91.2934,
      city: 'Couderay',
      state: 'WI',
    },
    54829: {
      lat: 45.5592,
      lng: -92.06603,
      city: 'Cumberland',
      state: 'WI',
    },
    54830: {
      lat: 46.10571,
      lng: -92.22305,
      city: 'Danbury',
      state: 'WI',
    },
    54832: {
      lat: 46.32661,
      lng: -91.33073,
      city: 'Drummond',
      state: 'WI',
    },
    54835: {
      lat: 45.67974,
      lng: -91.2522,
      city: 'Exeland',
      state: 'WI',
    },
    54836: {
      lat: 46.43238,
      lng: -92.19225,
      city: 'Foxboro',
      state: 'WI',
    },
    54837: {
      lat: 45.6746,
      lng: -92.35754,
      city: 'Frederic',
      state: 'WI',
    },
    54838: {
      lat: 46.24836,
      lng: -91.79179,
      city: 'Gordon',
      state: 'WI',
    },
    54839: {
      lat: 46.35683,
      lng: -91.14689,
      city: 'Grand View',
      state: 'WI',
    },
    54840: {
      lat: 45.7556,
      lng: -92.68928,
      city: 'Grantsburg',
      state: 'WI',
    },
    54841: {
      lat: 45.60776,
      lng: -91.77887,
      city: 'Haugen',
      state: 'WI',
    },
    54842: {
      lat: 46.49891,
      lng: -91.86234,
      city: 'Hawthorne',
      state: 'WI',
    },
    54843: {
      lat: 46.0251,
      lng: -91.29494,
      city: 'Hayward',
      state: 'WI',
    },
    54844: {
      lat: 46.78649,
      lng: -91.21441,
      city: 'Herbster',
      state: 'WI',
    },
    54845: {
      lat: 45.80724,
      lng: -92.13786,
      city: 'Hertel',
      state: 'WI',
    },
    54846: {
      lat: 46.37737,
      lng: -90.72481,
      city: 'High Bridge',
      state: 'WI',
    },
    54847: {
      lat: 46.57047,
      lng: -91.40055,
      city: 'Iron River',
      state: 'WI',
    },
    54848: {
      lat: 45.51546,
      lng: -91.10284,
      city: 'Ladysmith',
      state: 'WI',
    },
    54849: {
      lat: 46.47881,
      lng: -91.71642,
      city: 'Lake Nebagamon',
      state: 'WI',
    },
    54850: {
      lat: 46.92255,
      lng: -90.62389,
      city: 'La Pointe',
      state: 'WI',
    },
    54853: { lat: 45.57106, lng: -92.45211, city: 'Luck', state: 'WI' },
    54854: {
      lat: 46.64127,
      lng: -91.66864,
      city: 'Maple',
      state: 'WI',
    },
    54855: {
      lat: 46.37492,
      lng: -90.84397,
      city: 'Marengo',
      state: 'WI',
    },
    54856: {
      lat: 46.43401,
      lng: -91.10279,
      city: 'Mason',
      state: 'WI',
    },
    54857: {
      lat: 45.59347,
      lng: -91.59927,
      city: 'Mikana',
      state: 'WI',
    },
    54858: {
      lat: 45.51824,
      lng: -92.46031,
      city: 'Milltown',
      state: 'WI',
    },
    54859: {
      lat: 46.12121,
      lng: -91.82337,
      city: 'Minong',
      state: 'WI',
    },
    54861: {
      lat: 46.59896,
      lng: -90.64345,
      city: 'Odanah',
      state: 'WI',
    },
    54862: {
      lat: 45.80385,
      lng: -91.12132,
      city: 'Ojibwa',
      state: 'WI',
    },
    54864: {
      lat: 46.60431,
      lng: -91.79443,
      city: 'Poplar',
      state: 'WI',
    },
    54865: {
      lat: 46.74787,
      lng: -91.37517,
      city: 'Port Wing',
      state: 'WI',
    },
    54867: {
      lat: 45.78195,
      lng: -91.21521,
      city: 'Radisson',
      state: 'WI',
    },
    54868: {
      lat: 45.52723,
      lng: -91.70977,
      city: 'Rice Lake',
      state: 'WI',
    },
    54870: {
      lat: 45.71205,
      lng: -91.76967,
      city: 'Sarona',
      state: 'WI',
    },
    54871: {
      lat: 45.7412,
      lng: -92.01503,
      city: 'Shell Lake',
      state: 'WI',
    },
    54872: {
      lat: 45.76965,
      lng: -92.39622,
      city: 'Siren',
      state: 'WI',
    },
    54873: {
      lat: 46.3448,
      lng: -91.69292,
      city: 'Solon Springs',
      state: 'WI',
    },
    54874: {
      lat: 46.56911,
      lng: -91.94304,
      city: 'South Range',
      state: 'WI',
    },
    54875: {
      lat: 45.97405,
      lng: -91.6948,
      city: 'Springbrook',
      state: 'WI',
    },
    54876: {
      lat: 45.82811,
      lng: -91.51439,
      city: 'Stone Lake',
      state: 'WI',
    },
    54880: {
      lat: 46.59342,
      lng: -92.12701,
      city: 'Superior',
      state: 'WI',
    },
    54888: {
      lat: 45.98046,
      lng: -91.88803,
      city: 'Trego',
      state: 'WI',
    },
    54889: {
      lat: 45.41374,
      lng: -92.17112,
      city: 'Turtle Lake',
      state: 'WI',
    },
    54891: {
      lat: 46.69727,
      lng: -90.9688,
      city: 'Washburn',
      state: 'WI',
    },
    54893: {
      lat: 45.87094,
      lng: -92.30837,
      city: 'Webster',
      state: 'WI',
    },
    54895: {
      lat: 45.44233,
      lng: -91.44605,
      city: 'Weyerhaeuser',
      state: 'WI',
    },
    54896: {
      lat: 45.81178,
      lng: -90.87888,
      city: 'Winter',
      state: 'WI',
    },
    54901: {
      lat: 44.06265,
      lng: -88.53335,
      city: 'Oshkosh',
      state: 'WI',
    },
    54902: {
      lat: 43.94934,
      lng: -88.53969,
      city: 'Oshkosh',
      state: 'WI',
    },
    54904: {
      lat: 44.02346,
      lng: -88.62592,
      city: 'Oshkosh',
      state: 'WI',
    },
    54909: {
      lat: 44.28575,
      lng: -89.35343,
      city: 'Almond',
      state: 'WI',
    },
    54911: {
      lat: 44.28174,
      lng: -88.38086,
      city: 'Appleton',
      state: 'WI',
    },
    54913: {
      lat: 44.34145,
      lng: -88.40136,
      city: 'Appleton',
      state: 'WI',
    },
    54914: {
      lat: 44.26353,
      lng: -88.48239,
      city: 'Appleton',
      state: 'WI',
    },
    54915: {
      lat: 44.24279,
      lng: -88.35928,
      city: 'Appleton',
      state: 'WI',
    },
    54921: {
      lat: 44.30155,
      lng: -89.57341,
      city: 'Bancroft',
      state: 'WI',
    },
    54922: {
      lat: 44.54281,
      lng: -88.74529,
      city: 'Bear Creek',
      state: 'WI',
    },
    54923: {
      lat: 43.99456,
      lng: -88.96883,
      city: 'Berlin',
      state: 'WI',
    },
    54926: {
      lat: 44.61553,
      lng: -89.01823,
      city: 'Big Falls',
      state: 'WI',
    },
    54927: {
      lat: 44.10192,
      lng: -88.6548,
      city: 'Butte Des Morts',
      state: 'WI',
    },
    54928: {
      lat: 44.73894,
      lng: -88.88546,
      city: 'Caroline',
      state: 'WI',
    },
    54929: {
      lat: 44.63704,
      lng: -88.7372,
      city: 'Clintonville',
      state: 'WI',
    },
    54930: {
      lat: 44.02884,
      lng: -89.56383,
      city: 'Coloma',
      state: 'WI',
    },
    54931: { lat: 44.2703, lng: -88.66835, city: 'Dale', state: 'WI' },
    54932: {
      lat: 43.83143,
      lng: -88.61861,
      city: 'Eldorado',
      state: 'WI',
    },
    54933: {
      lat: 44.66535,
      lng: -88.70966,
      city: 'Embarrass',
      state: 'WI',
    },
    54935: {
      lat: 43.77542,
      lng: -88.43396,
      city: 'Fond Du Lac',
      state: 'WI',
    },
    54937: {
      lat: 43.75959,
      lng: -88.43335,
      city: 'Fond Du Lac',
      state: 'WI',
    },
    54940: {
      lat: 44.22696,
      lng: -88.84446,
      city: 'Fremont',
      state: 'WI',
    },
    54941: {
      lat: 43.85067,
      lng: -88.99103,
      city: 'Green Lake',
      state: 'WI',
    },
    54942: {
      lat: 44.29904,
      lng: -88.54265,
      city: 'Greenville',
      state: 'WI',
    },
    54943: {
      lat: 44.12377,
      lng: -89.58199,
      city: 'Hancock',
      state: 'WI',
    },
    54944: {
      lat: 44.32409,
      lng: -88.6274,
      city: 'Hortonville',
      state: 'WI',
    },
    54945: { lat: 44.57604, lng: -89.13739, city: 'Iola', state: 'WI' },
    54946: { lat: 44.33704, lng: -89.14503, city: 'King', state: 'WI' },
    54947: {
      lat: 44.20003,
      lng: -88.68995,
      city: 'Larsen',
      state: 'WI',
    },
    54948: {
      lat: 44.7774,
      lng: -88.88823,
      city: 'Leopolis',
      state: 'WI',
    },
    54949: {
      lat: 44.48561,
      lng: -88.91416,
      city: 'Manawa',
      state: 'WI',
    },
    54950: {
      lat: 44.65408,
      lng: -88.90614,
      city: 'Marion',
      state: 'WI',
    },
    54952: {
      lat: 44.20086,
      lng: -88.33719,
      city: 'Menasha',
      state: 'WI',
    },
    54956: {
      lat: 44.18444,
      lng: -88.52778,
      city: 'Neenah',
      state: 'WI',
    },
    54960: {
      lat: 43.94569,
      lng: -89.22386,
      city: 'Neshkoro',
      state: 'WI',
    },
    54961: {
      lat: 44.41173,
      lng: -88.7628,
      city: 'New London',
      state: 'WI',
    },
    54962: {
      lat: 44.48059,
      lng: -89.02486,
      city: 'Ogdensburg',
      state: 'WI',
    },
    54963: { lat: 44.04524, lng: -88.76661, city: 'Omro', state: 'WI' },
    54964: {
      lat: 43.9178,
      lng: -88.71836,
      city: 'Pickett',
      state: 'WI',
    },
    54965: {
      lat: 44.16429,
      lng: -89.0324,
      city: 'Pine River',
      state: 'WI',
    },
    54966: {
      lat: 44.21276,
      lng: -89.50579,
      city: 'Plainfield',
      state: 'WI',
    },
    54967: {
      lat: 44.12954,
      lng: -88.98487,
      city: 'Poy Sippi',
      state: 'WI',
    },
    54968: {
      lat: 43.84872,
      lng: -89.13255,
      city: 'Princeton',
      state: 'WI',
    },
    54970: {
      lat: 44.07091,
      lng: -89.09352,
      city: 'Redgranite',
      state: 'WI',
    },
    54971: {
      lat: 43.85546,
      lng: -88.83109,
      city: 'Ripon',
      state: 'WI',
    },
    54974: {
      lat: 43.7801,
      lng: -88.65997,
      city: 'Rosendale',
      state: 'WI',
    },
    54977: {
      lat: 44.45696,
      lng: -89.16471,
      city: 'Scandinavia',
      state: 'WI',
    },
    54978: {
      lat: 44.79423,
      lng: -88.91405,
      city: 'Tilleda',
      state: 'WI',
    },
    54979: {
      lat: 43.8688,
      lng: -88.53875,
      city: 'Van Dyne',
      state: 'WI',
    },
    54980: {
      lat: 43.98625,
      lng: -88.76906,
      city: 'Waukau',
      state: 'WI',
    },
    54981: {
      lat: 44.32733,
      lng: -89.12558,
      city: 'Waupaca',
      state: 'WI',
    },
    54982: {
      lat: 44.07007,
      lng: -89.29223,
      city: 'Wautoma',
      state: 'WI',
    },
    54983: {
      lat: 44.31651,
      lng: -88.93614,
      city: 'Weyauwega',
      state: 'WI',
    },
    54984: {
      lat: 44.18728,
      lng: -89.21967,
      city: 'Wild Rose',
      state: 'WI',
    },
    54985: {
      lat: 44.07562,
      lng: -88.51953,
      city: 'Winnebago',
      state: 'WI',
    },
    54986: {
      lat: 44.11981,
      lng: -88.75127,
      city: 'Winneconne',
      state: 'WI',
    },
    55001: {
      lat: 44.90138,
      lng: -92.81969,
      city: 'Afton',
      state: 'MN',
    },
    55003: {
      lat: 45.01183,
      lng: -92.77883,
      city: 'Bayport',
      state: 'MN',
    },
    55005: {
      lat: 45.39281,
      lng: -93.21725,
      city: 'Bethel',
      state: 'MN',
    },
    55006: {
      lat: 45.74002,
      lng: -93.20504,
      city: 'Braham',
      state: 'MN',
    },
    55007: {
      lat: 45.97511,
      lng: -93.12223,
      city: 'Brook Park',
      state: 'MN',
    },
    55008: {
      lat: 45.57149,
      lng: -93.26734,
      city: 'Cambridge',
      state: 'MN',
    },
    55009: {
      lat: 44.48378,
      lng: -92.86686,
      city: 'Cannon Falls',
      state: 'MN',
    },
    55011: { lat: 45.34108, lng: -93.2521, city: 'Cedar', state: 'MN' },
    55012: {
      lat: 45.44166,
      lng: -92.78952,
      city: 'Center City',
      state: 'MN',
    },
    55013: {
      lat: 45.35558,
      lng: -92.89469,
      city: 'Chisago City',
      state: 'MN',
    },
    55014: {
      lat: 45.16802,
      lng: -93.12431,
      city: 'Circle Pines',
      state: 'MN',
    },
    55016: {
      lat: 44.8172,
      lng: -92.93373,
      city: 'Cottage Grove',
      state: 'MN',
    },
    55017: { lat: 45.67291, lng: -93.4314, city: 'Dalbo', state: 'MN' },
    55018: {
      lat: 44.41832,
      lng: -92.98822,
      city: 'Dennison',
      state: 'MN',
    },
    55019: {
      lat: 44.41318,
      lng: -93.25016,
      city: 'Dundas',
      state: 'MN',
    },
    55020: {
      lat: 44.57707,
      lng: -93.37387,
      city: 'Elko New Market',
      state: 'MN',
    },
    55021: {
      lat: 44.2973,
      lng: -93.27414,
      city: 'Faribault',
      state: 'MN',
    },
    55024: {
      lat: 44.62738,
      lng: -93.13605,
      city: 'Farmington',
      state: 'MN',
    },
    55025: {
      lat: 45.26391,
      lng: -93.0212,
      city: 'Forest Lake',
      state: 'MN',
    },
    55026: {
      lat: 44.52179,
      lng: -92.3369,
      city: 'Frontenac',
      state: 'MN',
    },
    55027: {
      lat: 44.40868,
      lng: -92.62058,
      city: 'Goodhue',
      state: 'MN',
    },
    55029: {
      lat: 45.63573,
      lng: -93.20098,
      city: 'Grandy',
      state: 'MN',
    },
    55030: {
      lat: 45.83911,
      lng: -93.11493,
      city: 'Grasston',
      state: 'MN',
    },
    55031: {
      lat: 44.60772,
      lng: -92.96647,
      city: 'Hampton',
      state: 'MN',
    },
    55032: {
      lat: 45.58851,
      lng: -92.99279,
      city: 'Harris',
      state: 'MN',
    },
    55033: {
      lat: 44.71139,
      lng: -92.8585,
      city: 'Hastings',
      state: 'MN',
    },
    55036: {
      lat: 45.87114,
      lng: -93.11999,
      city: 'Henriette',
      state: 'MN',
    },
    55037: {
      lat: 46.01071,
      lng: -92.78934,
      city: 'Hinckley',
      state: 'MN',
    },
    55038: { lat: 45.169, lng: -92.98059, city: 'Hugo', state: 'MN' },
    55040: {
      lat: 45.4678,
      lng: -93.27951,
      city: 'Isanti',
      state: 'MN',
    },
    55041: {
      lat: 44.39544,
      lng: -92.31923,
      city: 'Lake City',
      state: 'MN',
    },
    55042: {
      lat: 44.99253,
      lng: -92.898,
      city: 'Lake Elmo',
      state: 'MN',
    },
    55043: {
      lat: 44.94064,
      lng: -92.76902,
      city: 'Lakeland',
      state: 'MN',
    },
    55044: {
      lat: 44.64156,
      lng: -93.27858,
      city: 'Lakeville',
      state: 'MN',
    },
    55045: {
      lat: 45.39777,
      lng: -92.83074,
      city: 'Lindstrom',
      state: 'MN',
    },
    55046: {
      lat: 44.44629,
      lng: -93.4162,
      city: 'Lonsdale',
      state: 'MN',
    },
    55047: {
      lat: 45.20553,
      lng: -92.82137,
      city: 'Marine On Saint Croix',
      state: 'MN',
    },
    55049: {
      lat: 44.17628,
      lng: -93.24101,
      city: 'Medford',
      state: 'MN',
    },
    55051: { lat: 45.93818, lng: -93.33201, city: 'Mora', state: 'MN' },
    55052: {
      lat: 44.23369,
      lng: -93.4398,
      city: 'Morristown',
      state: 'MN',
    },
    55053: {
      lat: 44.34957,
      lng: -93.06579,
      city: 'Nerstrand',
      state: 'MN',
    },
    55054: {
      lat: 44.57148,
      lng: -93.35087,
      city: 'Elko New Market',
      state: 'MN',
    },
    55055: {
      lat: 44.87437,
      lng: -92.99746,
      city: 'Newport',
      state: 'MN',
    },
    55056: {
      lat: 45.50915,
      lng: -92.9543,
      city: 'North Branch',
      state: 'MN',
    },
    55057: {
      lat: 44.47281,
      lng: -93.17939,
      city: 'Northfield',
      state: 'MN',
    },
    55060: {
      lat: 44.05966,
      lng: -93.22639,
      city: 'Owatonna',
      state: 'MN',
    },
    55063: {
      lat: 45.83263,
      lng: -92.90584,
      city: 'Pine City',
      state: 'MN',
    },
    55065: {
      lat: 44.54662,
      lng: -93.01835,
      city: 'Randolph',
      state: 'MN',
    },
    55066: {
      lat: 44.51581,
      lng: -92.5441,
      city: 'Red Wing',
      state: 'MN',
    },
    55068: {
      lat: 44.73555,
      lng: -93.06876,
      city: 'Rosemount',
      state: 'MN',
    },
    55069: {
      lat: 45.6928,
      lng: -92.96768,
      city: 'Rush City',
      state: 'MN',
    },
    55070: {
      lat: 45.41413,
      lng: -93.3849,
      city: 'Saint Francis',
      state: 'MN',
    },
    55071: {
      lat: 44.82257,
      lng: -92.99677,
      city: 'Saint Paul Park',
      state: 'MN',
    },
    55072: {
      lat: 46.15118,
      lng: -92.59274,
      city: 'Sandstone',
      state: 'MN',
    },
    55073: {
      lat: 45.27363,
      lng: -92.82783,
      city: 'Scandia',
      state: 'MN',
    },
    55074: {
      lat: 45.38351,
      lng: -92.73292,
      city: 'Shafer',
      state: 'MN',
    },
    55075: {
      lat: 44.88794,
      lng: -93.0411,
      city: 'South Saint Paul',
      state: 'MN',
    },
    55076: {
      lat: 44.83318,
      lng: -93.0334,
      city: 'Inver Grove Heights',
      state: 'MN',
    },
    55077: {
      lat: 44.81989,
      lng: -93.07574,
      city: 'Inver Grove Heights',
      state: 'MN',
    },
    55079: {
      lat: 45.41385,
      lng: -93.03117,
      city: 'Stacy',
      state: 'MN',
    },
    55080: {
      lat: 45.66322,
      lng: -93.21881,
      city: 'Stanchfield',
      state: 'MN',
    },
    55082: {
      lat: 45.07446,
      lng: -92.84006,
      city: 'Stillwater',
      state: 'MN',
    },
    55084: {
      lat: 45.46029,
      lng: -92.70747,
      city: 'Taylors Falls',
      state: 'MN',
    },
    55085: {
      lat: 44.6731,
      lng: -92.96432,
      city: 'Vermillion',
      state: 'MN',
    },
    55087: {
      lat: 44.23894,
      lng: -93.40614,
      city: 'Warsaw',
      state: 'MN',
    },
    55088: {
      lat: 44.52945,
      lng: -93.38479,
      city: 'Webster',
      state: 'MN',
    },
    55089: {
      lat: 44.59296,
      lng: -92.70223,
      city: 'Welch',
      state: 'MN',
    },
    55090: {
      lat: 45.05382,
      lng: -92.9573,
      city: 'Willernie',
      state: 'MN',
    },
    55092: {
      lat: 45.33647,
      lng: -93.07214,
      city: 'Wyoming',
      state: 'MN',
    },
    55101: {
      lat: 44.95111,
      lng: -93.08739,
      city: 'Saint Paul',
      state: 'MN',
    },
    55102: {
      lat: 44.93216,
      lng: -93.12149,
      city: 'Saint Paul',
      state: 'MN',
    },
    55103: {
      lat: 44.96399,
      lng: -93.12418,
      city: 'Saint Paul',
      state: 'MN',
    },
    55104: {
      lat: 44.95423,
      lng: -93.16283,
      city: 'Saint Paul',
      state: 'MN',
    },
    55105: {
      lat: 44.93465,
      lng: -93.16689,
      city: 'Saint Paul',
      state: 'MN',
    },
    55106: {
      lat: 44.95743,
      lng: -93.04679,
      city: 'Saint Paul',
      state: 'MN',
    },
    55107: {
      lat: 44.93105,
      lng: -93.07945,
      city: 'Saint Paul',
      state: 'MN',
    },
    55108: {
      lat: 44.98095,
      lng: -93.1749,
      city: 'Saint Paul',
      state: 'MN',
    },
    55109: {
      lat: 45.0144,
      lng: -93.02616,
      city: 'Saint Paul',
      state: 'MN',
    },
    55110: {
      lat: 45.08961,
      lng: -93.00418,
      city: 'Saint Paul',
      state: 'MN',
    },
    55111: {
      lat: 44.885,
      lng: -93.19518,
      city: 'Saint Paul',
      state: 'MN',
    },
    55112: {
      lat: 45.07443,
      lng: -93.19169,
      city: 'Saint Paul',
      state: 'MN',
    },
    55113: {
      lat: 45.01295,
      lng: -93.15512,
      city: 'Saint Paul',
      state: 'MN',
    },
    55114: {
      lat: 44.9662,
      lng: -93.19407,
      city: 'Saint Paul',
      state: 'MN',
    },
    55115: {
      lat: 45.06675,
      lng: -92.95087,
      city: 'Saint Paul',
      state: 'MN',
    },
    55116: {
      lat: 44.91001,
      lng: -93.17033,
      city: 'Saint Paul',
      state: 'MN',
    },
    55117: {
      lat: 44.99918,
      lng: -93.09713,
      city: 'Saint Paul',
      state: 'MN',
    },
    55118: {
      lat: 44.8968,
      lng: -93.10365,
      city: 'Saint Paul',
      state: 'MN',
    },
    55119: {
      lat: 44.93972,
      lng: -93.0066,
      city: 'Saint Paul',
      state: 'MN',
    },
    55120: {
      lat: 44.87259,
      lng: -93.14881,
      city: 'Saint Paul',
      state: 'MN',
    },
    55121: {
      lat: 44.84591,
      lng: -93.15932,
      city: 'Saint Paul',
      state: 'MN',
    },
    55122: {
      lat: 44.80326,
      lng: -93.1962,
      city: 'Saint Paul',
      state: 'MN',
    },
    55123: {
      lat: 44.8043,
      lng: -93.13699,
      city: 'Saint Paul',
      state: 'MN',
    },
    55124: {
      lat: 44.74566,
      lng: -93.20042,
      city: 'Saint Paul',
      state: 'MN',
    },
    55125: {
      lat: 44.91998,
      lng: -92.94388,
      city: 'Saint Paul',
      state: 'MN',
    },
    55126: {
      lat: 45.08614,
      lng: -93.13581,
      city: 'Saint Paul',
      state: 'MN',
    },
    55127: {
      lat: 45.08394,
      lng: -93.08243,
      city: 'Saint Paul',
      state: 'MN',
    },
    55128: {
      lat: 44.98812,
      lng: -92.96405,
      city: 'Saint Paul',
      state: 'MN',
    },
    55129: {
      lat: 44.89422,
      lng: -92.90638,
      city: 'Saint Paul',
      state: 'MN',
    },
    55130: {
      lat: 44.97315,
      lng: -93.08241,
      city: 'Saint Paul',
      state: 'MN',
    },
    55150: {
      lat: 44.88717,
      lng: -93.16157,
      city: 'Mendota',
      state: 'MN',
    },
    55155: {
      lat: 44.95419,
      lng: -93.09756,
      city: 'Saint Paul',
      state: 'MN',
    },
    55301: {
      lat: 45.24448,
      lng: -93.66219,
      city: 'Albertville',
      state: 'MN',
    },
    55302: {
      lat: 45.24464,
      lng: -94.11638,
      city: 'Annandale',
      state: 'MN',
    },
    55303: {
      lat: 45.28872,
      lng: -93.42333,
      city: 'Anoka',
      state: 'MN',
    },
    55304: {
      lat: 45.25583,
      lng: -93.26455,
      city: 'Andover',
      state: 'MN',
    },
    55305: {
      lat: 44.95477,
      lng: -93.43229,
      city: 'Hopkins',
      state: 'MN',
    },
    55306: {
      lat: 44.73071,
      lng: -93.29195,
      city: 'Burnsville',
      state: 'MN',
    },
    55307: {
      lat: 44.59871,
      lng: -94.10475,
      city: 'Arlington',
      state: 'MN',
    },
    55308: {
      lat: 45.42944,
      lng: -93.83561,
      city: 'Becker',
      state: 'MN',
    },
    55309: {
      lat: 45.37758,
      lng: -93.74107,
      city: 'Big Lake',
      state: 'MN',
    },
    55310: {
      lat: 44.75599,
      lng: -94.87624,
      city: 'Bird Island',
      state: 'MN',
    },
    55311: {
      lat: 45.1052,
      lng: -93.49495,
      city: 'Maple Grove',
      state: 'MN',
    },
    55312: {
      lat: 44.70853,
      lng: -94.34489,
      city: 'Brownton',
      state: 'MN',
    },
    55313: {
      lat: 45.17382,
      lng: -93.84859,
      city: 'Buffalo',
      state: 'MN',
    },
    55314: {
      lat: 44.77214,
      lng: -94.6,
      city: 'Buffalo Lake',
      state: 'MN',
    },
    55315: {
      lat: 44.71731,
      lng: -93.68347,
      city: 'Carver',
      state: 'MN',
    },
    55316: {
      lat: 45.17021,
      lng: -93.39032,
      city: 'Champlin',
      state: 'MN',
    },
    55317: {
      lat: 44.85711,
      lng: -93.55096,
      city: 'Chanhassen',
      state: 'MN',
    },
    55318: {
      lat: 44.81169,
      lng: -93.63703,
      city: 'Chaska',
      state: 'MN',
    },
    55319: {
      lat: 45.47181,
      lng: -93.94359,
      city: 'Clear Lake',
      state: 'MN',
    },
    55320: {
      lat: 45.39613,
      lng: -94.08366,
      city: 'Clearwater',
      state: 'MN',
    },
    55321: { lat: 45.08406, lng: -94.193, city: 'Cokato', state: 'MN' },
    55322: {
      lat: 44.77006,
      lng: -93.7861,
      city: 'Cologne',
      state: 'MN',
    },
    55324: {
      lat: 45.05676,
      lng: -94.42554,
      city: 'Darwin',
      state: 'MN',
    },
    55325: {
      lat: 45.08432,
      lng: -94.32031,
      city: 'Dassel',
      state: 'MN',
    },
    55327: {
      lat: 45.19922,
      lng: -93.4753,
      city: 'Dayton',
      state: 'MN',
    },
    55328: {
      lat: 45.03246,
      lng: -93.80981,
      city: 'Delano',
      state: 'MN',
    },
    55329: {
      lat: 45.30798,
      lng: -94.57039,
      city: 'Eden Valley',
      state: 'MN',
    },
    55330: {
      lat: 45.33359,
      lng: -93.5679,
      city: 'Elk River',
      state: 'MN',
    },
    55331: {
      lat: 44.90124,
      lng: -93.60832,
      city: 'Excelsior',
      state: 'MN',
    },
    55332: {
      lat: 44.51852,
      lng: -94.70761,
      city: 'Fairfax',
      state: 'MN',
    },
    55333: {
      lat: 44.55987,
      lng: -94.86593,
      city: 'Franklin',
      state: 'MN',
    },
    55334: {
      lat: 44.53578,
      lng: -94.20772,
      city: 'Gaylord',
      state: 'MN',
    },
    55335: {
      lat: 44.52969,
      lng: -94.54621,
      city: 'Gibbon',
      state: 'MN',
    },
    55336: {
      lat: 44.76627,
      lng: -94.17803,
      city: 'Glencoe',
      state: 'MN',
    },
    55337: {
      lat: 44.77673,
      lng: -93.27491,
      city: 'Burnsville',
      state: 'MN',
    },
    55338: {
      lat: 44.66996,
      lng: -93.99688,
      city: 'Green Isle',
      state: 'MN',
    },
    55339: {
      lat: 44.7289,
      lng: -93.95905,
      city: 'Hamburg',
      state: 'MN',
    },
    55340: {
      lat: 45.07761,
      lng: -93.57508,
      city: 'Hamel',
      state: 'MN',
    },
    55341: {
      lat: 45.15917,
      lng: -93.65913,
      city: 'Hanover',
      state: 'MN',
    },
    55342: {
      lat: 44.74152,
      lng: -94.72982,
      city: 'Hector',
      state: 'MN',
    },
    55343: {
      lat: 44.91433,
      lng: -93.41568,
      city: 'Hopkins',
      state: 'MN',
    },
    55344: {
      lat: 44.86452,
      lng: -93.43037,
      city: 'Eden Prairie',
      state: 'MN',
    },
    55345: {
      lat: 44.91526,
      lng: -93.4838,
      city: 'Minnetonka',
      state: 'MN',
    },
    55346: {
      lat: 44.87882,
      lng: -93.48157,
      city: 'Eden Prairie',
      state: 'MN',
    },
    55347: {
      lat: 44.82931,
      lng: -93.46638,
      city: 'Eden Prairie',
      state: 'MN',
    },
    55349: {
      lat: 45.06238,
      lng: -94.07438,
      city: 'Howard Lake',
      state: 'MN',
    },
    55350: {
      lat: 44.89743,
      lng: -94.39195,
      city: 'Hutchinson',
      state: 'MN',
    },
    55352: {
      lat: 44.65505,
      lng: -93.5862,
      city: 'Jordan',
      state: 'MN',
    },
    55353: {
      lat: 45.32034,
      lng: -94.31607,
      city: 'Kimball',
      state: 'MN',
    },
    55354: {
      lat: 44.88466,
      lng: -94.0701,
      city: 'Lester Prairie',
      state: 'MN',
    },
    55355: {
      lat: 45.09709,
      lng: -94.54017,
      city: 'Litchfield',
      state: 'MN',
    },
    55356: {
      lat: 44.99565,
      lng: -93.58628,
      city: 'Long Lake',
      state: 'MN',
    },
    55357: {
      lat: 45.09906,
      lng: -93.65913,
      city: 'Loretto',
      state: 'MN',
    },
    55358: {
      lat: 45.24637,
      lng: -94.00049,
      city: 'Maple Lake',
      state: 'MN',
    },
    55359: {
      lat: 45.00464,
      lng: -93.70112,
      city: 'Maple Plain',
      state: 'MN',
    },
    55360: {
      lat: 44.91331,
      lng: -93.92065,
      city: 'Mayer',
      state: 'MN',
    },
    55362: {
      lat: 45.30099,
      lng: -93.83563,
      city: 'Monticello',
      state: 'MN',
    },
    55363: {
      lat: 45.04385,
      lng: -93.91352,
      city: 'Montrose',
      state: 'MN',
    },
    55364: {
      lat: 44.93974,
      lng: -93.67486,
      city: 'Mound',
      state: 'MN',
    },
    55366: {
      lat: 44.67233,
      lng: -94.2346,
      city: 'New Auburn',
      state: 'MN',
    },
    55367: {
      lat: 44.89142,
      lng: -93.97499,
      city: 'New Germany',
      state: 'MN',
    },
    55368: {
      lat: 44.7458,
      lng: -93.89679,
      city: 'Norwood Young America',
      state: 'MN',
    },
    55369: {
      lat: 45.12834,
      lng: -93.44837,
      city: 'Osseo',
      state: 'MN',
    },
    55370: {
      lat: 44.78077,
      lng: -94.03892,
      city: 'Plato',
      state: 'MN',
    },
    55371: {
      lat: 45.57677,
      lng: -93.59581,
      city: 'Princeton',
      state: 'MN',
    },
    55372: {
      lat: 44.67791,
      lng: -93.41237,
      city: 'Prior Lake',
      state: 'MN',
    },
    55373: {
      lat: 45.08779,
      lng: -93.72282,
      city: 'Rockford',
      state: 'MN',
    },
    55374: {
      lat: 45.17715,
      lng: -93.58035,
      city: 'Rogers',
      state: 'MN',
    },
    55375: {
      lat: 44.89475,
      lng: -93.73129,
      city: 'Saint Bonifacius',
      state: 'MN',
    },
    55376: {
      lat: 45.21161,
      lng: -93.68508,
      city: 'Saint Michael',
      state: 'MN',
    },
    55378: {
      lat: 44.75685,
      lng: -93.36209,
      city: 'Savage',
      state: 'MN',
    },
    55379: {
      lat: 44.75492,
      lng: -93.51713,
      city: 'Shakopee',
      state: 'MN',
    },
    55381: {
      lat: 44.92763,
      lng: -94.18623,
      city: 'Silver Lake',
      state: 'MN',
    },
    55382: {
      lat: 45.30521,
      lng: -94.20438,
      city: 'South Haven',
      state: 'MN',
    },
    55384: {
      lat: 44.9364,
      lng: -93.63201,
      city: 'Spring Park',
      state: 'MN',
    },
    55385: {
      lat: 44.73732,
      lng: -94.49281,
      city: 'Stewart',
      state: 'MN',
    },
    55386: {
      lat: 44.85686,
      lng: -93.66435,
      city: 'Victoria',
      state: 'MN',
    },
    55387: {
      lat: 44.85643,
      lng: -93.77903,
      city: 'Waconia',
      state: 'MN',
    },
    55388: {
      lat: 44.95,
      lng: -93.84729,
      city: 'Watertown',
      state: 'MN',
    },
    55389: {
      lat: 45.2998,
      lng: -94.43748,
      city: 'Watkins',
      state: 'MN',
    },
    55390: {
      lat: 45.06812,
      lng: -93.97912,
      city: 'Waverly',
      state: 'MN',
    },
    55391: {
      lat: 44.96009,
      lng: -93.54225,
      city: 'Wayzata',
      state: 'MN',
    },
    55395: {
      lat: 44.9572,
      lng: -94.06085,
      city: 'Winsted',
      state: 'MN',
    },
    55396: {
      lat: 44.54473,
      lng: -94.36755,
      city: 'Winthrop',
      state: 'MN',
    },
    55397: {
      lat: 44.81712,
      lng: -93.93452,
      city: 'Young America',
      state: 'MN',
    },
    55398: {
      lat: 45.45787,
      lng: -93.5903,
      city: 'Zimmerman',
      state: 'MN',
    },
    55401: {
      lat: 44.98526,
      lng: -93.26984,
      city: 'Minneapolis',
      state: 'MN',
    },
    55402: {
      lat: 44.97608,
      lng: -93.27119,
      city: 'Minneapolis',
      state: 'MN',
    },
    55403: {
      lat: 44.97023,
      lng: -93.28588,
      city: 'Minneapolis',
      state: 'MN',
    },
    55404: {
      lat: 44.9619,
      lng: -93.26145,
      city: 'Minneapolis',
      state: 'MN',
    },
    55405: {
      lat: 44.97034,
      lng: -93.30461,
      city: 'Minneapolis',
      state: 'MN',
    },
    55406: {
      lat: 44.93932,
      lng: -93.22071,
      city: 'Minneapolis',
      state: 'MN',
    },
    55407: {
      lat: 44.93484,
      lng: -93.25263,
      city: 'Minneapolis',
      state: 'MN',
    },
    55408: {
      lat: 44.94364,
      lng: -93.2937,
      city: 'Minneapolis',
      state: 'MN',
    },
    55409: {
      lat: 44.93018,
      lng: -93.28146,
      city: 'Minneapolis',
      state: 'MN',
    },
    55410: {
      lat: 44.91364,
      lng: -93.31725,
      city: 'Minneapolis',
      state: 'MN',
    },
    55411: {
      lat: 44.9993,
      lng: -93.2979,
      city: 'Minneapolis',
      state: 'MN',
    },
    55412: {
      lat: 45.02612,
      lng: -93.29952,
      city: 'Minneapolis',
      state: 'MN',
    },
    55413: {
      lat: 44.99826,
      lng: -93.24276,
      city: 'Minneapolis',
      state: 'MN',
    },
    55414: {
      lat: 44.97915,
      lng: -93.2259,
      city: 'Minneapolis',
      state: 'MN',
    },
    55415: {
      lat: 44.97463,
      lng: -93.2578,
      city: 'Minneapolis',
      state: 'MN',
    },
    55416: {
      lat: 44.94961,
      lng: -93.34157,
      city: 'Minneapolis',
      state: 'MN',
    },
    55417: {
      lat: 44.9059,
      lng: -93.23534,
      city: 'Minneapolis',
      state: 'MN',
    },
    55418: {
      lat: 45.0208,
      lng: -93.24244,
      city: 'Minneapolis',
      state: 'MN',
    },
    55419: {
      lat: 44.90586,
      lng: -93.28743,
      city: 'Minneapolis',
      state: 'MN',
    },
    55420: {
      lat: 44.83564,
      lng: -93.27745,
      city: 'Minneapolis',
      state: 'MN',
    },
    55421: {
      lat: 45.05083,
      lng: -93.25249,
      city: 'Minneapolis',
      state: 'MN',
    },
    55422: {
      lat: 45.00922,
      lng: -93.33941,
      city: 'Minneapolis',
      state: 'MN',
    },
    55423: {
      lat: 44.87663,
      lng: -93.28144,
      city: 'Minneapolis',
      state: 'MN',
    },
    55424: {
      lat: 44.90509,
      lng: -93.33978,
      city: 'Minneapolis',
      state: 'MN',
    },
    55425: {
      lat: 44.84249,
      lng: -93.23601,
      city: 'Minneapolis',
      state: 'MN',
    },
    55426: {
      lat: 44.95635,
      lng: -93.38139,
      city: 'Minneapolis',
      state: 'MN',
    },
    55427: {
      lat: 45.00665,
      lng: -93.38031,
      city: 'Minneapolis',
      state: 'MN',
    },
    55428: {
      lat: 45.06354,
      lng: -93.38094,
      city: 'Minneapolis',
      state: 'MN',
    },
    55429: {
      lat: 45.06443,
      lng: -93.34105,
      city: 'Minneapolis',
      state: 'MN',
    },
    55430: {
      lat: 45.0632,
      lng: -93.30148,
      city: 'Minneapolis',
      state: 'MN',
    },
    55431: {
      lat: 44.82607,
      lng: -93.31195,
      city: 'Minneapolis',
      state: 'MN',
    },
    55432: {
      lat: 45.09693,
      lng: -93.25466,
      city: 'Minneapolis',
      state: 'MN',
    },
    55433: {
      lat: 45.16132,
      lng: -93.31638,
      city: 'Minneapolis',
      state: 'MN',
    },
    55434: {
      lat: 45.1681,
      lng: -93.25069,
      city: 'Minneapolis',
      state: 'MN',
    },
    55435: {
      lat: 44.87343,
      lng: -93.3347,
      city: 'Minneapolis',
      state: 'MN',
    },
    55436: {
      lat: 44.90345,
      lng: -93.37409,
      city: 'Minneapolis',
      state: 'MN',
    },
    55437: {
      lat: 44.82434,
      lng: -93.34488,
      city: 'Minneapolis',
      state: 'MN',
    },
    55438: {
      lat: 44.82489,
      lng: -93.37826,
      city: 'Minneapolis',
      state: 'MN',
    },
    55439: {
      lat: 44.87452,
      lng: -93.37475,
      city: 'Minneapolis',
      state: 'MN',
    },
    55441: {
      lat: 45.00475,
      lng: -93.42845,
      city: 'Minneapolis',
      state: 'MN',
    },
    55442: {
      lat: 45.04856,
      lng: -93.4269,
      city: 'Minneapolis',
      state: 'MN',
    },
    55443: {
      lat: 45.11894,
      lng: -93.33802,
      city: 'Minneapolis',
      state: 'MN',
    },
    55444: {
      lat: 45.10747,
      lng: -93.30234,
      city: 'Minneapolis',
      state: 'MN',
    },
    55445: {
      lat: 45.12317,
      lng: -93.3798,
      city: 'Minneapolis',
      state: 'MN',
    },
    55446: {
      lat: 45.04427,
      lng: -93.4878,
      city: 'Minneapolis',
      state: 'MN',
    },
    55447: {
      lat: 45.00132,
      lng: -93.48888,
      city: 'Minneapolis',
      state: 'MN',
    },
    55448: {
      lat: 45.1908,
      lng: -93.30209,
      city: 'Minneapolis',
      state: 'MN',
    },
    55449: {
      lat: 45.17284,
      lng: -93.1953,
      city: 'Minneapolis',
      state: 'MN',
    },
    55450: {
      lat: 44.87901,
      lng: -93.22549,
      city: 'Minneapolis',
      state: 'MN',
    },
    55454: {
      lat: 44.97075,
      lng: -93.24381,
      city: 'Minneapolis',
      state: 'MN',
    },
    55455: {
      lat: 44.97453,
      lng: -93.23432,
      city: 'Minneapolis',
      state: 'MN',
    },
    55601: {
      lat: 47.23404,
      lng: -91.36425,
      city: 'Beaver Bay',
      state: 'MN',
    },
    55602: {
      lat: 47.33207,
      lng: -91.91047,
      city: 'Brimson',
      state: 'MN',
    },
    55603: {
      lat: 47.5441,
      lng: -91.19441,
      city: 'Finland',
      state: 'MN',
    },
    55604: {
      lat: 47.96201,
      lng: -90.55792,
      city: 'Grand Marais',
      state: 'MN',
    },
    55605: {
      lat: 47.96242,
      lng: -89.75529,
      city: 'Grand Portage',
      state: 'MN',
    },
    55606: {
      lat: 47.96785,
      lng: -90.02818,
      city: 'Hovland',
      state: 'MN',
    },
    55607: {
      lat: 47.63363,
      lng: -91.51106,
      city: 'Isabella',
      state: 'MN',
    },
    55609: {
      lat: 46.9636,
      lng: -91.78554,
      city: 'Knife River',
      state: 'MN',
    },
    55612: {
      lat: 47.77937,
      lng: -90.70401,
      city: 'Lutsen',
      state: 'MN',
    },
    55613: {
      lat: 47.57023,
      lng: -90.97293,
      city: 'Schroeder',
      state: 'MN',
    },
    55614: {
      lat: 47.36412,
      lng: -91.40387,
      city: 'Silver Bay',
      state: 'MN',
    },
    55615: {
      lat: 47.74561,
      lng: -90.87695,
      city: 'Tofte',
      state: 'MN',
    },
    55616: {
      lat: 47.25095,
      lng: -91.66982,
      city: 'Two Harbors',
      state: 'MN',
    },
    55702: {
      lat: 46.97121,
      lng: -92.6448,
      city: 'Alborn',
      state: 'MN',
    },
    55703: {
      lat: 47.73932,
      lng: -92.78119,
      city: 'Angora',
      state: 'MN',
    },
    55704: { lat: 46.2057, lng: -92.76581, city: 'Askov', state: 'MN' },
    55705: {
      lat: 47.46789,
      lng: -92.24446,
      city: 'Aurora',
      state: 'MN',
    },
    55706: {
      lat: 47.67432,
      lng: -91.95261,
      city: 'Babbitt',
      state: 'MN',
    },
    55707: {
      lat: 46.55076,
      lng: -92.64655,
      city: 'Barnum',
      state: 'MN',
    },
    55708: {
      lat: 47.53527,
      lng: -92.34766,
      city: 'Biwabik',
      state: 'MN',
    },
    55709: { lat: 47.40431, lng: -93.3804, city: 'Bovey', state: 'MN' },
    55710: {
      lat: 47.64542,
      lng: -92.69457,
      city: 'Britt',
      state: 'MN',
    },
    55711: {
      lat: 46.84066,
      lng: -92.69498,
      city: 'Brookston',
      state: 'MN',
    },
    55712: {
      lat: 46.29398,
      lng: -92.52513,
      city: 'Bruno',
      state: 'MN',
    },
    55713: { lat: 47.4894, lng: -92.76583, city: 'Buhl', state: 'MN' },
    55716: {
      lat: 47.32169,
      lng: -93.26432,
      city: 'Calumet',
      state: 'MN',
    },
    55717: {
      lat: 47.05026,
      lng: -92.38642,
      city: 'Canyon',
      state: 'MN',
    },
    55718: {
      lat: 46.6249,
      lng: -92.48505,
      city: 'Carlton',
      state: 'MN',
    },
    55719: {
      lat: 47.55408,
      lng: -92.85807,
      city: 'Chisholm',
      state: 'MN',
    },
    55720: {
      lat: 46.75138,
      lng: -92.5399,
      city: 'Cloquet',
      state: 'MN',
    },
    55721: {
      lat: 47.21837,
      lng: -93.68841,
      city: 'Cohasset',
      state: 'MN',
    },
    55722: {
      lat: 47.28253,
      lng: -93.4338,
      city: 'Coleraine',
      state: 'MN',
    },
    55723: { lat: 47.83764, lng: -92.86715, city: 'Cook', state: 'MN' },
    55724: {
      lat: 47.17874,
      lng: -92.37277,
      city: 'Cotton',
      state: 'MN',
    },
    55725: {
      lat: 48.33849,
      lng: -92.5811,
      city: 'Crane Lake',
      state: 'MN',
    },
    55726: {
      lat: 46.67027,
      lng: -92.83209,
      city: 'Cromwell',
      state: 'MN',
    },
    55731: { lat: 47.98832, lng: -91.7489, city: 'Ely', state: 'MN' },
    55732: {
      lat: 47.6791,
      lng: -92.22125,
      city: 'Embarrass',
      state: 'MN',
    },
    55733: { lat: 46.71141, lng: -92.36297, city: 'Esko', state: 'MN' },
    55734: {
      lat: 47.38274,
      lng: -92.4938,
      city: 'Eveleth',
      state: 'MN',
    },
    55735: {
      lat: 46.21756,
      lng: -93.01688,
      city: 'Finlayson',
      state: 'MN',
    },
    55736: {
      lat: 46.97542,
      lng: -92.92136,
      city: 'Floodwood',
      state: 'MN',
    },
    55738: {
      lat: 47.27342,
      lng: -92.65826,
      city: 'Forbes',
      state: 'MN',
    },
    55741: {
      lat: 47.4582,
      lng: -92.38683,
      city: 'Gilbert',
      state: 'MN',
    },
    55742: {
      lat: 47.17195,
      lng: -93.14076,
      city: 'Goodland',
      state: 'MN',
    },
    55744: {
      lat: 47.23306,
      lng: -93.51299,
      city: 'Grand Rapids',
      state: 'MN',
    },
    55746: {
      lat: 47.35766,
      lng: -92.95698,
      city: 'Hibbing',
      state: 'MN',
    },
    55748: {
      lat: 46.99372,
      lng: -93.59693,
      city: 'Hill City',
      state: 'MN',
    },
    55749: {
      lat: 46.44701,
      lng: -92.43128,
      city: 'Holyoke',
      state: 'MN',
    },
    55750: {
      lat: 47.53251,
      lng: -91.99517,
      city: 'Hoyt Lakes',
      state: 'MN',
    },
    55751: { lat: 47.39283, lng: -92.66815, city: 'Iron', state: 'MN' },
    55752: {
      lat: 46.95494,
      lng: -93.25323,
      city: 'Jacobson',
      state: 'MN',
    },
    55753: {
      lat: 47.39692,
      lng: -93.07991,
      city: 'Keewatin',
      state: 'MN',
    },
    55756: {
      lat: 46.37535,
      lng: -92.60128,
      city: 'Kerrick',
      state: 'MN',
    },
    55757: {
      lat: 46.51594,
      lng: -92.93475,
      city: 'Kettle River',
      state: 'MN',
    },
    55758: {
      lat: 47.50823,
      lng: -92.72141,
      city: 'Kinney',
      state: 'MN',
    },
    55760: {
      lat: 46.62491,
      lng: -93.27049,
      city: 'Mcgregor',
      state: 'MN',
    },
    55763: {
      lat: 47.33127,
      lng: -92.22262,
      city: 'Makinen',
      state: 'MN',
    },
    55764: {
      lat: 47.33041,
      lng: -93.29298,
      city: 'Marble',
      state: 'MN',
    },
    55765: {
      lat: 47.10057,
      lng: -92.74545,
      city: 'Meadowlands',
      state: 'MN',
    },
    55767: {
      lat: 46.45236,
      lng: -92.76536,
      city: 'Moose Lake',
      state: 'MN',
    },
    55768: {
      lat: 47.51444,
      lng: -92.67263,
      city: 'Mountain Iron',
      state: 'MN',
    },
    55769: {
      lat: 47.47994,
      lng: -93.18579,
      city: 'Nashwauk',
      state: 'MN',
    },
    55771: { lat: 48.12398, lng: -92.93101, city: 'Orr', state: 'MN' },
    55772: {
      lat: 48.11019,
      lng: -93.10032,
      city: 'Nett Lake',
      state: 'MN',
    },
    55775: {
      lat: 47.30588,
      lng: -93.1646,
      city: 'Pengilly',
      state: 'MN',
    },
    55779: {
      lat: 46.90915,
      lng: -92.4548,
      city: 'Saginaw',
      state: 'MN',
    },
    55780: {
      lat: 46.67505,
      lng: -92.68321,
      city: 'Sawyer',
      state: 'MN',
    },
    55781: {
      lat: 47.67587,
      lng: -93.06524,
      city: 'Side Lake',
      state: 'MN',
    },
    55782: {
      lat: 47.82314,
      lng: -92.20524,
      city: 'Soudan',
      state: 'MN',
    },
    55783: {
      lat: 46.40836,
      lng: -92.92625,
      city: 'Sturgeon Lake',
      state: 'MN',
    },
    55784: {
      lat: 47.05306,
      lng: -93.18769,
      city: 'Swan River',
      state: 'MN',
    },
    55785: {
      lat: 46.92332,
      lng: -93.74591,
      city: 'Swatara',
      state: 'MN',
    },
    55786: {
      lat: 47.31916,
      lng: -93.38227,
      city: 'Taconite',
      state: 'MN',
    },
    55787: {
      lat: 46.73707,
      lng: -93.11756,
      city: 'Tamarack',
      state: 'MN',
    },
    55790: { lat: 47.8288, lng: -92.37596, city: 'Tower', state: 'MN' },
    55792: {
      lat: 47.60027,
      lng: -92.48261,
      city: 'Virginia',
      state: 'MN',
    },
    55793: {
      lat: 47.11585,
      lng: -93.28442,
      city: 'Warba',
      state: 'MN',
    },
    55795: {
      lat: 46.31337,
      lng: -92.87031,
      city: 'Willow River',
      state: 'MN',
    },
    55796: {
      lat: 47.92905,
      lng: -91.79732,
      city: 'Winton',
      state: 'MN',
    },
    55797: {
      lat: 46.56978,
      lng: -92.36431,
      city: 'Wrenshall',
      state: 'MN',
    },
    55798: {
      lat: 46.72138,
      lng: -92.97907,
      city: 'Wright',
      state: 'MN',
    },
    55802: {
      lat: 46.75267,
      lng: -92.08197,
      city: 'Duluth',
      state: 'MN',
    },
    55803: {
      lat: 47.03398,
      lng: -92.07104,
      city: 'Duluth',
      state: 'MN',
    },
    55804: {
      lat: 46.9381,
      lng: -91.93089,
      city: 'Duluth',
      state: 'MN',
    },
    55805: {
      lat: 46.80065,
      lng: -92.09552,
      city: 'Duluth',
      state: 'MN',
    },
    55806: {
      lat: 46.76826,
      lng: -92.12835,
      city: 'Duluth',
      state: 'MN',
    },
    55807: {
      lat: 46.73508,
      lng: -92.1686,
      city: 'Duluth',
      state: 'MN',
    },
    55808: {
      lat: 46.6792,
      lng: -92.23686,
      city: 'Duluth',
      state: 'MN',
    },
    55810: {
      lat: 46.75894,
      lng: -92.27445,
      city: 'Duluth',
      state: 'MN',
    },
    55811: {
      lat: 46.83767,
      lng: -92.21993,
      city: 'Duluth',
      state: 'MN',
    },
    55812: {
      lat: 46.81236,
      lng: -92.07564,
      city: 'Duluth',
      state: 'MN',
    },
    55814: {
      lat: 46.83519,
      lng: -92.19716,
      city: 'Duluth',
      state: 'MN',
    },
    55901: {
      lat: 44.07679,
      lng: -92.5018,
      city: 'Rochester',
      state: 'MN',
    },
    55902: {
      lat: 43.9671,
      lng: -92.51584,
      city: 'Rochester',
      state: 'MN',
    },
    55903: {
      lat: 44.02391,
      lng: -92.43657,
      city: 'Rochester',
      state: 'MN',
    },
    55904: {
      lat: 43.96204,
      lng: -92.39931,
      city: 'Rochester',
      state: 'MN',
    },
    55905: {
      lat: 44.05595,
      lng: -92.52589,
      city: 'Rochester',
      state: 'MN',
    },
    55906: {
      lat: 44.10751,
      lng: -92.40142,
      city: 'Rochester',
      state: 'MN',
    },
    55909: {
      lat: 43.56312,
      lng: -92.73461,
      city: 'Adams',
      state: 'MN',
    },
    55910: {
      lat: 44.13285,
      lng: -91.97285,
      city: 'Altura',
      state: 'MN',
    },
    55912: {
      lat: 43.6827,
      lng: -92.99148,
      city: 'Austin',
      state: 'MN',
    },
    55917: {
      lat: 43.88164,
      lng: -93.0716,
      city: 'Blooming Prairie',
      state: 'MN',
    },
    55918: {
      lat: 43.74236,
      lng: -92.84673,
      city: 'Brownsdale',
      state: 'MN',
    },
    55919: {
      lat: 43.61443,
      lng: -91.2941,
      city: 'Brownsville',
      state: 'MN',
    },
    55920: {
      lat: 44.01246,
      lng: -92.62111,
      city: 'Byron',
      state: 'MN',
    },
    55921: {
      lat: 43.62265,
      lng: -91.46388,
      city: 'Caledonia',
      state: 'MN',
    },
    55922: {
      lat: 43.5621,
      lng: -91.89562,
      city: 'Canton',
      state: 'MN',
    },
    55923: {
      lat: 43.8494,
      lng: -92.18315,
      city: 'Chatfield',
      state: 'MN',
    },
    55924: {
      lat: 44.0354,
      lng: -93.01838,
      city: 'Claremont',
      state: 'MN',
    },
    55925: {
      lat: 43.90962,
      lng: -91.46503,
      city: 'Dakota',
      state: 'MN',
    },
    55926: {
      lat: 43.74279,
      lng: -92.70689,
      city: 'Dexter',
      state: 'MN',
    },
    55927: {
      lat: 44.0335,
      lng: -92.87291,
      city: 'Dodge Center',
      state: 'MN',
    },
    55929: {
      lat: 43.97765,
      lng: -92.14412,
      city: 'Dover',
      state: 'MN',
    },
    55931: {
      lat: 43.51424,
      lng: -91.39703,
      city: 'Eitzen',
      state: 'MN',
    },
    55932: {
      lat: 44.13804,
      lng: -92.30081,
      city: 'Elgin',
      state: 'MN',
    },
    55933: {
      lat: 43.64787,
      lng: -92.68504,
      city: 'Elkton',
      state: 'MN',
    },
    55934: {
      lat: 44.00388,
      lng: -92.26573,
      city: 'Eyota',
      state: 'MN',
    },
    55935: {
      lat: 43.74737,
      lng: -92.12734,
      city: 'Fountain',
      state: 'MN',
    },
    55936: {
      lat: 43.70943,
      lng: -92.58524,
      city: 'Grand Meadow',
      state: 'MN',
    },
    55939: {
      lat: 43.54682,
      lng: -92.08144,
      city: 'Harmony',
      state: 'MN',
    },
    55940: {
      lat: 43.89417,
      lng: -92.80775,
      city: 'Hayfield',
      state: 'MN',
    },
    55941: {
      lat: 43.72558,
      lng: -91.35382,
      city: 'Hokah',
      state: 'MN',
    },
    55943: {
      lat: 43.7903,
      lng: -91.58343,
      city: 'Houston',
      state: 'MN',
    },
    55944: {
      lat: 43.98754,
      lng: -92.73758,
      city: 'Kasson',
      state: 'MN',
    },
    55945: {
      lat: 44.26788,
      lng: -92.05591,
      city: 'Kellogg',
      state: 'MN',
    },
    55946: {
      lat: 44.26129,
      lng: -92.95998,
      city: 'Kenyon',
      state: 'MN',
    },
    55947: {
      lat: 43.82412,
      lng: -91.35666,
      city: 'La Crescent',
      state: 'MN',
    },
    55949: {
      lat: 43.71156,
      lng: -91.94032,
      city: 'Lanesboro',
      state: 'MN',
    },
    55950: {
      lat: 43.74341,
      lng: -92.96618,
      city: 'Lansing',
      state: 'MN',
    },
    55951: {
      lat: 43.53529,
      lng: -92.48414,
      city: 'Le Roy',
      state: 'MN',
    },
    55952: {
      lat: 43.94399,
      lng: -91.84337,
      city: 'Lewiston',
      state: 'MN',
    },
    55953: { lat: 43.52772, lng: -92.96521, city: 'Lyle', state: 'MN' },
    55954: {
      lat: 43.56422,
      lng: -91.78455,
      city: 'Mabel',
      state: 'MN',
    },
    55955: {
      lat: 44.09179,
      lng: -92.73985,
      city: 'Mantorville',
      state: 'MN',
    },
    55956: {
      lat: 44.28382,
      lng: -92.53068,
      city: 'Mazeppa',
      state: 'MN',
    },
    55957: {
      lat: 44.25494,
      lng: -92.28373,
      city: 'Millville',
      state: 'MN',
    },
    55959: {
      lat: 44.11287,
      lng: -91.78365,
      city: 'Minnesota City',
      state: 'MN',
    },
    55960: {
      lat: 44.14226,
      lng: -92.53564,
      city: 'Oronoco',
      state: 'MN',
    },
    55961: {
      lat: 43.60541,
      lng: -92.47544,
      city: 'Ostrander',
      state: 'MN',
    },
    55962: {
      lat: 43.76213,
      lng: -91.84219,
      city: 'Peterson',
      state: 'MN',
    },
    55963: {
      lat: 44.18784,
      lng: -92.66227,
      city: 'Pine Island',
      state: 'MN',
    },
    55964: {
      lat: 44.16448,
      lng: -92.15483,
      city: 'Plainview',
      state: 'MN',
    },
    55965: {
      lat: 43.62913,
      lng: -92.13282,
      city: 'Preston',
      state: 'MN',
    },
    55967: {
      lat: 43.79343,
      lng: -92.51354,
      city: 'Racine',
      state: 'MN',
    },
    55968: {
      lat: 44.39793,
      lng: -92.0898,
      city: 'Reads Landing',
      state: 'MN',
    },
    55969: {
      lat: 44.08263,
      lng: -91.87658,
      city: 'Rollingstone',
      state: 'MN',
    },
    55970: {
      lat: 43.59681,
      lng: -92.83118,
      city: 'Rose Creek',
      state: 'MN',
    },
    55971: {
      lat: 43.81968,
      lng: -91.75527,
      city: 'Rushford',
      state: 'MN',
    },
    55972: {
      lat: 43.99396,
      lng: -92.05092,
      city: 'Saint Charles',
      state: 'MN',
    },
    55973: {
      lat: 43.80794,
      lng: -92.772,
      city: 'Sargeant',
      state: 'MN',
    },
    55974: {
      lat: 43.57968,
      lng: -91.64382,
      city: 'Spring Grove',
      state: 'MN',
    },
    55975: {
      lat: 43.67569,
      lng: -92.37296,
      city: 'Spring Valley',
      state: 'MN',
    },
    55976: {
      lat: 43.86378,
      lng: -92.48394,
      city: 'Stewartville',
      state: 'MN',
    },
    55977: {
      lat: 43.55371,
      lng: -92.64792,
      city: 'Taopi',
      state: 'MN',
    },
    55979: {
      lat: 43.92312,
      lng: -91.95132,
      city: 'Utica',
      state: 'MN',
    },
    55981: {
      lat: 44.35375,
      lng: -92.06518,
      city: 'Wabasha',
      state: 'MN',
    },
    55982: {
      lat: 43.81582,
      lng: -92.886,
      city: 'Waltham',
      state: 'MN',
    },
    55983: {
      lat: 44.29344,
      lng: -92.80827,
      city: 'Wanamingo',
      state: 'MN',
    },
    55985: {
      lat: 44.15669,
      lng: -92.90259,
      city: 'West Concord',
      state: 'MN',
    },
    55987: {
      lat: 43.98469,
      lng: -91.63143,
      city: 'Winona',
      state: 'MN',
    },
    55990: { lat: 43.7285, lng: -92.2591, city: 'Wykoff', state: 'MN' },
    55991: {
      lat: 44.25903,
      lng: -92.41986,
      city: 'Zumbro Falls',
      state: 'MN',
    },
    55992: {
      lat: 44.30557,
      lng: -92.6827,
      city: 'Zumbrota',
      state: 'MN',
    },
    56001: {
      lat: 44.13193,
      lng: -93.97789,
      city: 'Mankato',
      state: 'MN',
    },
    56003: {
      lat: 44.21273,
      lng: -94.08877,
      city: 'Mankato',
      state: 'MN',
    },
    56007: {
      lat: 43.66085,
      lng: -93.3238,
      city: 'Albert Lea',
      state: 'MN',
    },
    56009: {
      lat: 43.66125,
      lng: -93.55904,
      city: 'Alden',
      state: 'MN',
    },
    56010: {
      lat: 43.89112,
      lng: -94.17869,
      city: 'Amboy',
      state: 'MN',
    },
    56011: {
      lat: 44.60344,
      lng: -93.777,
      city: 'Belle Plaine',
      state: 'MN',
    },
    56013: {
      lat: 43.62142,
      lng: -94.10734,
      city: 'Blue Earth',
      state: 'MN',
    },
    56014: {
      lat: 43.58363,
      lng: -93.82568,
      city: 'Bricelyn',
      state: 'MN',
    },
    56016: {
      lat: 43.76378,
      lng: -93.34855,
      city: 'Clarks Grove',
      state: 'MN',
    },
    56017: {
      lat: 44.30535,
      lng: -93.81915,
      city: 'Cleveland',
      state: 'MN',
    },
    56019: {
      lat: 44.11476,
      lng: -94.89016,
      city: 'Comfrey',
      state: 'MN',
    },
    56020: {
      lat: 43.6146,
      lng: -93.53019,
      city: 'Conger',
      state: 'MN',
    },
    56021: {
      lat: 44.28339,
      lng: -94.31391,
      city: 'Courtland',
      state: 'MN',
    },
    56022: {
      lat: 44.05264,
      lng: -94.83869,
      city: 'Darfur',
      state: 'MN',
    },
    56023: {
      lat: 43.77224,
      lng: -94.01402,
      city: 'Delavan',
      state: 'MN',
    },
    56024: {
      lat: 44.14768,
      lng: -93.84419,
      city: 'Eagle Lake',
      state: 'MN',
    },
    56025: {
      lat: 43.7516,
      lng: -93.90877,
      city: 'Easton',
      state: 'MN',
    },
    56026: {
      lat: 43.87485,
      lng: -93.29124,
      city: 'Ellendale',
      state: 'MN',
    },
    56027: {
      lat: 43.51935,
      lng: -94.12432,
      city: 'Elmore',
      state: 'MN',
    },
    56028: {
      lat: 44.22435,
      lng: -93.71052,
      city: 'Elysian',
      state: 'MN',
    },
    56029: {
      lat: 43.52519,
      lng: -93.53003,
      city: 'Emmons',
      state: 'MN',
    },
    56030: {
      lat: 44.32485,
      lng: -94.60485,
      city: 'Essig',
      state: 'MN',
    },
    56031: {
      lat: 43.62246,
      lng: -94.46757,
      city: 'Fairmont',
      state: 'MN',
    },
    56032: {
      lat: 43.77583,
      lng: -93.56078,
      city: 'Freeborn',
      state: 'MN',
    },
    56033: {
      lat: 43.55926,
      lng: -93.93118,
      city: 'Frost',
      state: 'MN',
    },
    56034: {
      lat: 44.03036,
      lng: -94.18285,
      city: 'Garden City',
      state: 'MN',
    },
    56035: {
      lat: 43.82187,
      lng: -93.26875,
      city: 'Geneva',
      state: 'MN',
    },
    56036: {
      lat: 43.5513,
      lng: -93.22152,
      city: 'Glenville',
      state: 'MN',
    },
    56037: {
      lat: 44.0172,
      lng: -94.0465,
      city: 'Good Thunder',
      state: 'MN',
    },
    56039: {
      lat: 43.66246,
      lng: -94.32287,
      city: 'Granada',
      state: 'MN',
    },
    56041: {
      lat: 44.15514,
      lng: -94.52238,
      city: 'Hanska',
      state: 'MN',
    },
    56042: {
      lat: 43.80408,
      lng: -93.48387,
      city: 'Hartland',
      state: 'MN',
    },
    56043: {
      lat: 43.65236,
      lng: -93.22809,
      city: 'Hayward',
      state: 'MN',
    },
    56044: {
      lat: 44.56173,
      lng: -93.93539,
      city: 'Henderson',
      state: 'MN',
    },
    56045: {
      lat: 43.75977,
      lng: -93.20948,
      city: 'Hollandale',
      state: 'MN',
    },
    56046: { lat: 43.9678, lng: -93.26889, city: 'Hope', state: 'MN' },
    56048: {
      lat: 44.09478,
      lng: -93.71589,
      city: 'Janesville',
      state: 'MN',
    },
    56050: {
      lat: 44.26006,
      lng: -93.94706,
      city: 'Kasota',
      state: 'MN',
    },
    56051: {
      lat: 43.53649,
      lng: -93.70868,
      city: 'Kiester',
      state: 'MN',
    },
    56052: {
      lat: 44.32158,
      lng: -93.5576,
      city: 'Kilkenny',
      state: 'MN',
    },
    56054: {
      lat: 44.4357,
      lng: -94.37043,
      city: 'Lafayette',
      state: 'MN',
    },
    56055: {
      lat: 44.12693,
      lng: -94.24531,
      city: 'Lake Crystal',
      state: 'MN',
    },
    56056: {
      lat: 44.07278,
      lng: -94.57945,
      city: 'La Salle',
      state: 'MN',
    },
    56057: {
      lat: 44.3954,
      lng: -93.72045,
      city: 'Le Center',
      state: 'MN',
    },
    56058: {
      lat: 44.44424,
      lng: -93.91441,
      city: 'Le Sueur',
      state: 'MN',
    },
    56060: {
      lat: 43.93221,
      lng: -94.43691,
      city: 'Lewisville',
      state: 'MN',
    },
    56062: {
      lat: 44.0483,
      lng: -94.41469,
      city: 'Madelia',
      state: 'MN',
    },
    56063: {
      lat: 44.218,
      lng: -93.81791,
      city: 'Madison Lake',
      state: 'MN',
    },
    56065: {
      lat: 43.93376,
      lng: -93.91799,
      city: 'Mapleton',
      state: 'MN',
    },
    56068: {
      lat: 43.86303,
      lng: -93.80601,
      city: 'Minnesota Lake',
      state: 'MN',
    },
    56069: {
      lat: 44.41801,
      lng: -93.54988,
      city: 'Montgomery',
      state: 'MN',
    },
    56071: {
      lat: 44.53459,
      lng: -93.58209,
      city: 'New Prague',
      state: 'MN',
    },
    56072: {
      lat: 43.89677,
      lng: -93.5125,
      city: 'New Richland',
      state: 'MN',
    },
    56073: {
      lat: 44.30757,
      lng: -94.46878,
      city: 'New Ulm',
      state: 'MN',
    },
    56074: {
      lat: 44.32344,
      lng: -94.20181,
      city: 'Nicollet',
      state: 'MN',
    },
    56075: {
      lat: 43.73516,
      lng: -94.43663,
      city: 'Northrop',
      state: 'MN',
    },
    56078: {
      lat: 44.00525,
      lng: -93.76757,
      city: 'Pemberton',
      state: 'MN',
    },
    56080: {
      lat: 44.08166,
      lng: -93.85781,
      city: 'Saint Clair',
      state: 'MN',
    },
    56081: {
      lat: 43.99345,
      lng: -94.63062,
      city: 'Saint James',
      state: 'MN',
    },
    56082: {
      lat: 44.35217,
      lng: -94.03329,
      city: 'Saint Peter',
      state: 'MN',
    },
    56083: {
      lat: 44.20734,
      lng: -95.13434,
      city: 'Sanborn',
      state: 'MN',
    },
    56085: {
      lat: 44.29279,
      lng: -94.73547,
      city: 'Sleepy Eye',
      state: 'MN',
    },
    56087: {
      lat: 44.24012,
      lng: -94.97538,
      city: 'Springfield',
      state: 'MN',
    },
    56088: {
      lat: 43.82647,
      lng: -94.45031,
      city: 'Truman',
      state: 'MN',
    },
    56089: {
      lat: 43.55631,
      lng: -93.4508,
      city: 'Twin Lakes',
      state: 'MN',
    },
    56090: {
      lat: 43.9584,
      lng: -94.23303,
      city: 'Vernon Center',
      state: 'MN',
    },
    56091: {
      lat: 43.91813,
      lng: -93.68831,
      city: 'Waldorf',
      state: 'MN',
    },
    56093: {
      lat: 44.06342,
      lng: -93.51683,
      city: 'Waseca',
      state: 'MN',
    },
    56096: {
      lat: 44.24393,
      lng: -93.60097,
      city: 'Waterville',
      state: 'MN',
    },
    56097: {
      lat: 43.72261,
      lng: -93.71593,
      city: 'Wells',
      state: 'MN',
    },
    56098: {
      lat: 43.77565,
      lng: -94.18528,
      city: 'Winnebago',
      state: 'MN',
    },
    56101: {
      lat: 43.88404,
      lng: -95.13991,
      city: 'Windom',
      state: 'MN',
    },
    56110: {
      lat: 43.62115,
      lng: -95.94093,
      city: 'Adrian',
      state: 'MN',
    },
    56111: { lat: 43.64155, lng: -94.8799, city: 'Alpha', state: 'MN' },
    56113: { lat: 44.40218, lng: -96.14904, city: 'Arco', state: 'MN' },
    56114: {
      lat: 43.97897,
      lng: -95.59345,
      city: 'Avoca',
      state: 'MN',
    },
    56115: {
      lat: 44.21514,
      lng: -95.90038,
      city: 'Balaton',
      state: 'MN',
    },
    56116: {
      lat: 43.61782,
      lng: -96.37893,
      city: 'Beaver Creek',
      state: 'MN',
    },
    56117: {
      lat: 43.52662,
      lng: -95.68607,
      city: 'Bigelow',
      state: 'MN',
    },
    56118: {
      lat: 43.93616,
      lng: -95.03555,
      city: 'Bingham Lake',
      state: 'MN',
    },
    56119: {
      lat: 43.71159,
      lng: -95.46702,
      city: 'Brewster',
      state: 'MN',
    },
    56120: {
      lat: 43.96083,
      lng: -94.79139,
      city: 'Butterfield',
      state: 'MN',
    },
    56121: {
      lat: 43.53858,
      lng: -94.61051,
      city: 'Ceylon',
      state: 'MN',
    },
    56122: {
      lat: 43.89729,
      lng: -95.95026,
      city: 'Chandler',
      state: 'MN',
    },
    56123: {
      lat: 44.08467,
      lng: -95.59624,
      city: 'Currie',
      state: 'MN',
    },
    56125: {
      lat: 44.05452,
      lng: -95.54774,
      city: 'Dovray',
      state: 'MN',
    },
    56127: {
      lat: 43.54892,
      lng: -94.77261,
      city: 'Dunnell',
      state: 'MN',
    },
    56128: {
      lat: 43.88199,
      lng: -96.11579,
      city: 'Edgerton',
      state: 'MN',
    },
    56129: {
      lat: 43.52775,
      lng: -96.04327,
      city: 'Ellsworth',
      state: 'MN',
    },
    56131: {
      lat: 43.86305,
      lng: -95.57219,
      city: 'Fulda',
      state: 'MN',
    },
    56132: {
      lat: 44.20196,
      lng: -95.75792,
      city: 'Garvin',
      state: 'MN',
    },
    56134: {
      lat: 43.78956,
      lng: -96.23126,
      city: 'Hardwick',
      state: 'MN',
    },
    56136: {
      lat: 44.50187,
      lng: -96.41645,
      city: 'Hendricks',
      state: 'MN',
    },
    56137: {
      lat: 43.83246,
      lng: -95.32424,
      city: 'Heron Lake',
      state: 'MN',
    },
    56138: {
      lat: 43.53279,
      lng: -96.38348,
      city: 'Hills',
      state: 'MN',
    },
    56139: {
      lat: 44.10368,
      lng: -96.1757,
      city: 'Holland',
      state: 'MN',
    },
    56140: {
      lat: 43.90959,
      lng: -96.36703,
      city: 'Ihlen',
      state: 'MN',
    },
    56141: { lat: 43.88184, lng: -95.78962, city: 'Iona', state: 'MN' },
    56142: {
      lat: 44.4711,
      lng: -96.2327,
      city: 'Ivanhoe',
      state: 'MN',
    },
    56143: {
      lat: 43.62803,
      lng: -95.00759,
      city: 'Jackson',
      state: 'MN',
    },
    56144: {
      lat: 43.84078,
      lng: -96.38702,
      city: 'Jasper',
      state: 'MN',
    },
    56145: {
      lat: 44.05577,
      lng: -95.17384,
      city: 'Jeffers',
      state: 'MN',
    },
    56146: {
      lat: 43.58006,
      lng: -96.10343,
      city: 'Kanaranzi',
      state: 'MN',
    },
    56147: {
      lat: 43.75726,
      lng: -96.05985,
      city: 'Kenneth',
      state: 'MN',
    },
    56149: {
      lat: 44.30059,
      lng: -96.30454,
      city: 'Lake Benton',
      state: 'MN',
    },
    56150: {
      lat: 43.63469,
      lng: -95.21266,
      city: 'Lakefield',
      state: 'MN',
    },
    56151: {
      lat: 44.03304,
      lng: -95.92833,
      city: 'Lake Wilson',
      state: 'MN',
    },
    56152: {
      lat: 44.22702,
      lng: -95.2733,
      city: 'Lamberton',
      state: 'MN',
    },
    56153: {
      lat: 43.83012,
      lng: -96.01506,
      city: 'Leota',
      state: 'MN',
    },
    56155: {
      lat: 43.76707,
      lng: -95.94117,
      city: 'Lismore',
      state: 'MN',
    },
    56156: {
      lat: 43.66196,
      lng: -96.22466,
      city: 'Luverne',
      state: 'MN',
    },
    56157: { lat: 44.39702, lng: -95.94805, city: 'Lynd', state: 'MN' },
    56158: {
      lat: 43.6337,
      lng: -96.07465,
      city: 'Magnolia',
      state: 'MN',
    },
    56159: {
      lat: 43.94363,
      lng: -94.93598,
      city: 'Mountain Lake',
      state: 'MN',
    },
    56160: { lat: 43.83311, lng: -94.81041, city: 'Odin', state: 'MN' },
    56161: {
      lat: 43.70637,
      lng: -95.32519,
      city: 'Okabena',
      state: 'MN',
    },
    56162: {
      lat: 43.84034,
      lng: -94.66736,
      city: 'Ormsby',
      state: 'MN',
    },
    56164: {
      lat: 44.04936,
      lng: -96.33361,
      city: 'Pipestone',
      state: 'MN',
    },
    56165: {
      lat: 43.7251,
      lng: -95.7097,
      city: 'Reading',
      state: 'MN',
    },
    56166: {
      lat: 44.20248,
      lng: -95.37401,
      city: 'Revere',
      state: 'MN',
    },
    56167: {
      lat: 43.55082,
      lng: -95.40524,
      city: 'Round Lake',
      state: 'MN',
    },
    56168: {
      lat: 43.61552,
      lng: -95.80358,
      city: 'Rushmore',
      state: 'MN',
    },
    56169: {
      lat: 44.33136,
      lng: -96.00555,
      city: 'Russell',
      state: 'MN',
    },
    56170: {
      lat: 44.16694,
      lng: -96.08647,
      city: 'Ruthton',
      state: 'MN',
    },
    56171: {
      lat: 43.66902,
      lng: -94.75925,
      city: 'Sherburn',
      state: 'MN',
    },
    56172: {
      lat: 44.01212,
      lng: -95.7702,
      city: 'Slayton',
      state: 'MN',
    },
    56173: {
      lat: 43.52505,
      lng: -96.24186,
      city: 'Steen',
      state: 'MN',
    },
    56174: {
      lat: 44.01525,
      lng: -95.30466,
      city: 'Storden',
      state: 'MN',
    },
    56175: {
      lat: 44.25595,
      lng: -95.63733,
      city: 'Tracy',
      state: 'MN',
    },
    56176: {
      lat: 43.78319,
      lng: -94.72835,
      city: 'Trimont',
      state: 'MN',
    },
    56178: {
      lat: 44.28593,
      lng: -96.12997,
      city: 'Tyler',
      state: 'MN',
    },
    56180: {
      lat: 44.24424,
      lng: -95.46655,
      city: 'Walnut Grove',
      state: 'MN',
    },
    56181: {
      lat: 43.67951,
      lng: -94.61549,
      city: 'Welcome',
      state: 'MN',
    },
    56183: {
      lat: 44.03404,
      lng: -95.42334,
      city: 'Westbrook',
      state: 'MN',
    },
    56185: {
      lat: 43.7932,
      lng: -95.82659,
      city: 'Wilmont',
      state: 'MN',
    },
    56186: {
      lat: 44.02823,
      lng: -96.08131,
      city: 'Woodstock',
      state: 'MN',
    },
    56187: {
      lat: 43.62357,
      lng: -95.58507,
      city: 'Worthington',
      state: 'MN',
    },
    56201: {
      lat: 45.10181,
      lng: -95.04226,
      city: 'Willmar',
      state: 'MN',
    },
    56207: {
      lat: 45.50266,
      lng: -96.0708,
      city: 'Alberta',
      state: 'MN',
    },
    56208: {
      lat: 45.24422,
      lng: -96.00961,
      city: 'Appleton',
      state: 'MN',
    },
    56209: {
      lat: 45.124,
      lng: -94.79931,
      city: 'Atwater',
      state: 'MN',
    },
    56210: {
      lat: 45.55739,
      lng: -96.55958,
      city: 'Barry',
      state: 'MN',
    },
    56211: {
      lat: 45.58641,
      lng: -96.6828,
      city: 'Beardsley',
      state: 'MN',
    },
    56212: {
      lat: 45.13499,
      lng: -96.32459,
      city: 'Bellingham',
      state: 'MN',
    },
    56214: {
      lat: 44.60804,
      lng: -95.32943,
      city: 'Belview',
      state: 'MN',
    },
    56215: {
      lat: 45.31077,
      lng: -95.57233,
      city: 'Benson',
      state: 'MN',
    },
    56216: {
      lat: 44.93886,
      lng: -95.05532,
      city: 'Blomkest',
      state: 'MN',
    },
    56218: { lat: 44.82642, lng: -95.94363, city: 'Boyd', state: 'MN' },
    56219: {
      lat: 45.5977,
      lng: -96.81913,
      city: 'Browns Valley',
      state: 'MN',
    },
    56220: {
      lat: 44.73125,
      lng: -96.29339,
      city: 'Canby',
      state: 'MN',
    },
    56221: {
      lat: 45.55583,
      lng: -96.1938,
      city: 'Chokio',
      state: 'MN',
    },
    56222: {
      lat: 44.98586,
      lng: -95.36229,
      city: 'Clara City',
      state: 'MN',
    },
    56223: {
      lat: 44.76259,
      lng: -95.81691,
      city: 'Clarkfield',
      state: 'MN',
    },
    56224: {
      lat: 44.37628,
      lng: -95.05854,
      city: 'Clements',
      state: 'MN',
    },
    56225: {
      lat: 45.45325,
      lng: -96.4482,
      city: 'Clinton',
      state: 'MN',
    },
    56226: {
      lat: 45.41065,
      lng: -95.67705,
      city: 'Clontarf',
      state: 'MN',
    },
    56227: {
      lat: 45.29436,
      lng: -96.16938,
      city: 'Correll',
      state: 'MN',
    },
    56228: {
      lat: 44.93619,
      lng: -94.67715,
      city: 'Cosmos',
      state: 'MN',
    },
    56229: {
      lat: 44.59385,
      lng: -95.72398,
      city: 'Cottonwood',
      state: 'MN',
    },
    56230: {
      lat: 44.79284,
      lng: -95.09288,
      city: 'Danube',
      state: 'MN',
    },
    56231: {
      lat: 45.2788,
      lng: -95.76381,
      city: 'Danvers',
      state: 'MN',
    },
    56232: {
      lat: 44.92836,
      lng: -96.03968,
      city: 'Dawson',
      state: 'MN',
    },
    56235: {
      lat: 45.70588,
      lng: -96.01079,
      city: 'Donnelly',
      state: 'MN',
    },
    56236: {
      lat: 45.66341,
      lng: -96.40312,
      city: 'Dumont',
      state: 'MN',
    },
    56237: { lat: 44.61956, lng: -95.43489, city: 'Echo', state: 'MN' },
    56239: {
      lat: 44.50416,
      lng: -95.91079,
      city: 'Ghent',
      state: 'MN',
    },
    56240: {
      lat: 45.56327,
      lng: -96.47475,
      city: 'Graceville',
      state: 'MN',
    },
    56241: {
      lat: 44.79654,
      lng: -95.57944,
      city: 'Granite Falls',
      state: 'MN',
    },
    56243: {
      lat: 45.16868,
      lng: -94.6799,
      city: 'Grove City',
      state: 'MN',
    },
    56244: {
      lat: 45.48372,
      lng: -95.77963,
      city: 'Hancock',
      state: 'MN',
    },
    56245: {
      lat: 44.68508,
      lng: -95.67574,
      city: 'Hanley Falls',
      state: 'MN',
    },
    56248: {
      lat: 45.79317,
      lng: -96.13677,
      city: 'Herman',
      state: 'MN',
    },
    56249: {
      lat: 45.31795,
      lng: -95.89899,
      city: 'Holloway',
      state: 'MN',
    },
    56251: {
      lat: 45.14046,
      lng: -94.9198,
      city: 'Kandiyohi',
      state: 'MN',
    },
    56252: {
      lat: 45.17785,
      lng: -95.30397,
      city: 'Kerkhoven',
      state: 'MN',
    },
    56253: {
      lat: 44.9628,
      lng: -94.87889,
      city: 'Lake Lillian',
      state: 'MN',
    },
    56255: {
      lat: 44.39426,
      lng: -95.41707,
      city: 'Lucan',
      state: 'MN',
    },
    56256: {
      lat: 45.02306,
      lng: -96.19986,
      city: 'Madison',
      state: 'MN',
    },
    56257: {
      lat: 44.9684,
      lng: -96.40275,
      city: 'Marietta',
      state: 'MN',
    },
    56258: {
      lat: 44.44121,
      lng: -95.75906,
      city: 'Marshall',
      state: 'MN',
    },
    56260: {
      lat: 44.96338,
      lng: -95.48571,
      city: 'Maynard',
      state: 'MN',
    },
    56262: {
      lat: 45.12276,
      lng: -95.86843,
      city: 'Milan',
      state: 'MN',
    },
    56263: {
      lat: 44.41577,
      lng: -95.54086,
      city: 'Milroy',
      state: 'MN',
    },
    56264: {
      lat: 44.57529,
      lng: -95.98001,
      city: 'Minneota',
      state: 'MN',
    },
    56265: {
      lat: 45.0064,
      lng: -95.70098,
      city: 'Montevideo',
      state: 'MN',
    },
    56266: {
      lat: 44.41637,
      lng: -94.9133,
      city: 'Morgan',
      state: 'MN',
    },
    56267: {
      lat: 45.58512,
      lng: -95.9322,
      city: 'Morris',
      state: 'MN',
    },
    56270: {
      lat: 44.56013,
      lng: -94.97962,
      city: 'Morton',
      state: 'MN',
    },
    56271: {
      lat: 45.24125,
      lng: -95.41288,
      city: 'Murdock',
      state: 'MN',
    },
    56273: {
      lat: 45.32163,
      lng: -94.96777,
      city: 'New London',
      state: 'MN',
    },
    56274: {
      lat: 45.90145,
      lng: -96.2383,
      city: 'Norcross',
      state: 'MN',
    },
    56276: {
      lat: 45.24134,
      lng: -96.31249,
      city: 'Odessa',
      state: 'MN',
    },
    56277: {
      lat: 44.76742,
      lng: -94.99036,
      city: 'Olivia',
      state: 'MN',
    },
    56278: {
      lat: 45.345,
      lng: -96.37718,
      city: 'Ortonville',
      state: 'MN',
    },
    56279: {
      lat: 45.21651,
      lng: -95.17585,
      city: 'Pennock',
      state: 'MN',
    },
    56280: {
      lat: 44.63212,
      lng: -96.16719,
      city: 'Porter',
      state: 'MN',
    },
    56281: {
      lat: 44.94833,
      lng: -95.16479,
      city: 'Prinsburg',
      state: 'MN',
    },
    56282: {
      lat: 45.03089,
      lng: -95.21862,
      city: 'Raymond',
      state: 'MN',
    },
    56283: {
      lat: 44.53208,
      lng: -95.14225,
      city: 'Redwood Falls',
      state: 'MN',
    },
    56284: {
      lat: 44.77729,
      lng: -95.20204,
      city: 'Renville',
      state: 'MN',
    },
    56285: {
      lat: 44.78837,
      lng: -95.34797,
      city: 'Sacred Heart',
      state: 'MN',
    },
    56287: {
      lat: 44.47537,
      lng: -95.3262,
      city: 'Seaforth',
      state: 'MN',
    },
    56288: {
      lat: 45.24218,
      lng: -94.94847,
      city: 'Spicer',
      state: 'MN',
    },
    56289: {
      lat: 45.33334,
      lng: -95.2236,
      city: 'Sunburg',
      state: 'MN',
    },
    56291: {
      lat: 44.62254,
      lng: -96.06933,
      city: 'Taunton',
      state: 'MN',
    },
    56292: {
      lat: 44.49734,
      lng: -95.44863,
      city: 'Vesta',
      state: 'MN',
    },
    56293: {
      lat: 44.41598,
      lng: -95.26559,
      city: 'Wabasso',
      state: 'MN',
    },
    56294: {
      lat: 44.31481,
      lng: -95.21313,
      city: 'Wanda',
      state: 'MN',
    },
    56295: {
      lat: 45.0437,
      lng: -95.84292,
      city: 'Watson',
      state: 'MN',
    },
    56296: {
      lat: 45.82981,
      lng: -96.46953,
      city: 'Wheaton',
      state: 'MN',
    },
    56297: {
      lat: 44.63005,
      lng: -95.54428,
      city: 'Wood Lake',
      state: 'MN',
    },
    56301: {
      lat: 45.48718,
      lng: -94.24307,
      city: 'Saint Cloud',
      state: 'MN',
    },
    56303: {
      lat: 45.57422,
      lng: -94.21117,
      city: 'Saint Cloud',
      state: 'MN',
    },
    56304: {
      lat: 45.52512,
      lng: -94.05199,
      city: 'Saint Cloud',
      state: 'MN',
    },
    56307: {
      lat: 45.62666,
      lng: -94.58769,
      city: 'Albany',
      state: 'MN',
    },
    56308: {
      lat: 45.87047,
      lng: -95.38985,
      city: 'Alexandria',
      state: 'MN',
    },
    56309: {
      lat: 46.08035,
      lng: -95.80202,
      city: 'Ashby',
      state: 'MN',
    },
    56310: { lat: 45.63399, lng: -94.44352, city: 'Avon', state: 'MN' },
    56311: {
      lat: 45.89756,
      lng: -95.89852,
      city: 'Barrett',
      state: 'MN',
    },
    56312: {
      lat: 45.46285,
      lng: -94.95323,
      city: 'Belgrade',
      state: 'MN',
    },
    56313: { lat: 45.78403, lng: -93.55273, city: 'Bock', state: 'MN' },
    56314: {
      lat: 45.81139,
      lng: -94.43189,
      city: 'Bowlus',
      state: 'MN',
    },
    56315: {
      lat: 45.97996,
      lng: -95.59211,
      city: 'Brandon',
      state: 'MN',
    },
    56316: {
      lat: 45.49959,
      lng: -95.14992,
      city: 'Brooten',
      state: 'MN',
    },
    56318: {
      lat: 45.85332,
      lng: -94.67085,
      city: 'Burtrum',
      state: 'MN',
    },
    56319: {
      lat: 46.00488,
      lng: -95.22322,
      city: 'Carlos',
      state: 'MN',
    },
    56320: {
      lat: 45.4629,
      lng: -94.4171,
      city: 'Cold Spring',
      state: 'MN',
    },
    56321: {
      lat: 45.57969,
      lng: -94.39288,
      city: 'Collegeville',
      state: 'MN',
    },
    56323: {
      lat: 45.63321,
      lng: -95.72586,
      city: 'Cyrus',
      state: 'MN',
    },
    56324: {
      lat: 46.16385,
      lng: -95.90766,
      city: 'Dalton',
      state: 'MN',
    },
    56325: {
      lat: 45.56303,
      lng: -94.94732,
      city: 'Elrosa',
      state: 'MN',
    },
    56326: {
      lat: 46.02506,
      lng: -95.68596,
      city: 'Evansville',
      state: 'MN',
    },
    56327: {
      lat: 45.75893,
      lng: -95.61336,
      city: 'Farwell',
      state: 'MN',
    },
    56328: {
      lat: 45.9519,
      lng: -94.52147,
      city: 'Flensburg',
      state: 'MN',
    },
    56329: {
      lat: 45.70934,
      lng: -93.90577,
      city: 'Foley',
      state: 'MN',
    },
    56330: {
      lat: 45.75018,
      lng: -93.75117,
      city: 'Foreston',
      state: 'MN',
    },
    56331: {
      lat: 45.67926,
      lng: -94.6742,
      city: 'Freeport',
      state: 'MN',
    },
    56332: {
      lat: 45.96993,
      lng: -95.50554,
      city: 'Garfield',
      state: 'MN',
    },
    56334: {
      lat: 45.58402,
      lng: -95.34765,
      city: 'Glenwood',
      state: 'MN',
    },
    56335: {
      lat: 45.60137,
      lng: -94.85941,
      city: 'Greenwald',
      state: 'MN',
    },
    56336: {
      lat: 45.82349,
      lng: -94.76175,
      city: 'Grey Eagle',
      state: 'MN',
    },
    56338: {
      lat: 46.00631,
      lng: -93.88089,
      city: 'Hillman',
      state: 'MN',
    },
    56339: {
      lat: 45.8258,
      lng: -95.82129,
      city: 'Hoffman',
      state: 'MN',
    },
    56340: {
      lat: 45.75534,
      lng: -94.44871,
      city: 'Holdingford',
      state: 'MN',
    },
    56342: { lat: 46.18347, lng: -93.40392, city: 'Isle', state: 'MN' },
    56343: {
      lat: 45.79088,
      lng: -95.69765,
      city: 'Kensington',
      state: 'MN',
    },
    56345: {
      lat: 46.00748,
      lng: -94.38032,
      city: 'Little Falls',
      state: 'MN',
    },
    56347: {
      lat: 45.96978,
      lng: -94.86155,
      city: 'Long Prairie',
      state: 'MN',
    },
    56349: {
      lat: 45.72373,
      lng: -95.52456,
      city: 'Lowry',
      state: 'MN',
    },
    56350: {
      lat: 46.31101,
      lng: -93.23922,
      city: 'McGrath',
      state: 'MN',
    },
    56352: {
      lat: 45.65078,
      lng: -94.80076,
      city: 'Melrose',
      state: 'MN',
    },
    56353: {
      lat: 45.79667,
      lng: -93.62587,
      city: 'Milaca',
      state: 'MN',
    },
    56354: {
      lat: 46.06034,
      lng: -95.27496,
      city: 'Miltona',
      state: 'MN',
    },
    56355: {
      lat: 45.94007,
      lng: -95.24031,
      city: 'Nelson',
      state: 'MN',
    },
    56356: {
      lat: 45.63334,
      lng: -94.73332,
      city: 'New Munich',
      state: 'MN',
    },
    56357: {
      lat: 45.69684,
      lng: -93.8007,
      city: 'Oak Park',
      state: 'MN',
    },
    56358: {
      lat: 45.84069,
      lng: -93.4488,
      city: 'Ogilvie',
      state: 'MN',
    },
    56359: {
      lat: 46.08979,
      lng: -93.67786,
      city: 'Onamia',
      state: 'MN',
    },
    56360: {
      lat: 45.87482,
      lng: -95.12686,
      city: 'Osakis',
      state: 'MN',
    },
    56361: {
      lat: 46.16146,
      lng: -95.3351,
      city: 'Parkers Prairie',
      state: 'MN',
    },
    56362: {
      lat: 45.40524,
      lng: -94.71525,
      city: 'Paynesville',
      state: 'MN',
    },
    56363: {
      lat: 45.69627,
      lng: -93.65409,
      city: 'Pease',
      state: 'MN',
    },
    56364: {
      lat: 46.01605,
      lng: -94.06704,
      city: 'Pierz',
      state: 'MN',
    },
    56367: { lat: 45.76107, lng: -94.17026, city: 'Rice', state: 'MN' },
    56368: {
      lat: 45.45158,
      lng: -94.5392,
      city: 'Richmond',
      state: 'MN',
    },
    56369: {
      lat: 45.46904,
      lng: -94.3359,
      city: 'Rockville',
      state: 'MN',
    },
    56371: {
      lat: 45.43333,
      lng: -94.63707,
      city: 'Roscoe',
      state: 'MN',
    },
    56373: {
      lat: 45.85456,
      lng: -94.23789,
      city: 'Royalton',
      state: 'MN',
    },
    56374: {
      lat: 45.60381,
      lng: -94.33816,
      city: 'Saint Joseph',
      state: 'MN',
    },
    56375: {
      lat: 45.70122,
      lng: -94.27458,
      city: 'Saint Stephen',
      state: 'MN',
    },
    56376: {
      lat: 45.50241,
      lng: -94.66731,
      city: 'Saint Martin',
      state: 'MN',
    },
    56377: {
      lat: 45.6405,
      lng: -94.23033,
      city: 'Sartell',
      state: 'MN',
    },
    56378: {
      lat: 45.72308,
      lng: -94.97982,
      city: 'Sauk Centre',
      state: 'MN',
    },
    56379: {
      lat: 45.63966,
      lng: -94.08394,
      city: 'Sauk Rapids',
      state: 'MN',
    },
    56381: {
      lat: 45.55523,
      lng: -95.54669,
      city: 'Starbuck',
      state: 'MN',
    },
    56382: {
      lat: 45.9146,
      lng: -94.611,
      city: 'Swanville',
      state: 'MN',
    },
    56384: {
      lat: 45.8099,
      lng: -94.56759,
      city: 'Upsala',
      state: 'MN',
    },
    56385: {
      lat: 45.70349,
      lng: -95.20681,
      city: 'Villard',
      state: 'MN',
    },
    56386: {
      lat: 46.11402,
      lng: -93.52176,
      city: 'Wahkon',
      state: 'MN',
    },
    56387: {
      lat: 45.53215,
      lng: -94.24141,
      city: 'Waite Park',
      state: 'MN',
    },
    56389: {
      lat: 45.79961,
      lng: -95.08832,
      city: 'West Union',
      state: 'MN',
    },
    56401: {
      lat: 46.32259,
      lng: -94.11974,
      city: 'Brainerd',
      state: 'MN',
    },
    56425: {
      lat: 46.34162,
      lng: -94.28308,
      city: 'Baxter',
      state: 'MN',
    },
    56431: {
      lat: 46.4828,
      lng: -93.64356,
      city: 'Aitkin',
      state: 'MN',
    },
    56433: {
      lat: 46.9598,
      lng: -94.68519,
      city: 'Akeley',
      state: 'MN',
    },
    56434: {
      lat: 46.33797,
      lng: -94.93437,
      city: 'Aldrich',
      state: 'MN',
    },
    56435: {
      lat: 46.80872,
      lng: -94.53089,
      city: 'Backus',
      state: 'MN',
    },
    56437: {
      lat: 46.25049,
      lng: -95.05654,
      city: 'Bertha',
      state: 'MN',
    },
    56438: {
      lat: 46.13873,
      lng: -94.80874,
      city: 'Browerville',
      state: 'MN',
    },
    56440: {
      lat: 46.14658,
      lng: -94.9573,
      city: 'Clarissa',
      state: 'MN',
    },
    56441: {
      lat: 46.58625,
      lng: -93.9865,
      city: 'Crosby',
      state: 'MN',
    },
    56442: {
      lat: 46.67629,
      lng: -94.09726,
      city: 'Crosslake',
      state: 'MN',
    },
    56443: {
      lat: 46.1916,
      lng: -94.57565,
      city: 'Cushing',
      state: 'MN',
    },
    56444: {
      lat: 46.4175,
      lng: -93.87804,
      city: 'Deerwood',
      state: 'MN',
    },
    56446: {
      lat: 46.12448,
      lng: -95.08966,
      city: 'Eagle Bend',
      state: 'MN',
    },
    56447: {
      lat: 46.74151,
      lng: -93.90262,
      city: 'Emily',
      state: 'MN',
    },
    56448: {
      lat: 46.78604,
      lng: -94.09014,
      city: 'Fifty Lakes',
      state: 'MN',
    },
    56449: {
      lat: 46.17436,
      lng: -94.27214,
      city: 'Fort Ripley',
      state: 'MN',
    },
    56450: {
      lat: 46.23341,
      lng: -93.8361,
      city: 'Garrison',
      state: 'MN',
    },
    56452: {
      lat: 46.94808,
      lng: -94.46123,
      city: 'Hackensack',
      state: 'MN',
    },
    56453: {
      lat: 46.31374,
      lng: -95.16161,
      city: 'Hewitt',
      state: 'MN',
    },
    56455: {
      lat: 46.44953,
      lng: -94.00681,
      city: 'Ironton',
      state: 'MN',
    },
    56458: {
      lat: 47.19924,
      lng: -94.99341,
      city: 'Lake George',
      state: 'MN',
    },
    56461: {
      lat: 47.22817,
      lng: -94.85877,
      city: 'Laporte',
      state: 'MN',
    },
    56464: {
      lat: 46.76538,
      lng: -95.10382,
      city: 'Menahga',
      state: 'MN',
    },
    56465: {
      lat: 46.53459,
      lng: -94.11381,
      city: 'Merrifield',
      state: 'MN',
    },
    56466: {
      lat: 46.41522,
      lng: -94.63137,
      city: 'Motley',
      state: 'MN',
    },
    56467: {
      lat: 46.97714,
      lng: -94.83753,
      city: 'Nevis',
      state: 'MN',
    },
    56468: {
      lat: 46.49141,
      lng: -94.29856,
      city: 'Nisswa',
      state: 'MN',
    },
    56469: {
      lat: 46.77701,
      lng: -93.5463,
      city: 'Palisade',
      state: 'MN',
    },
    56470: {
      lat: 47.02368,
      lng: -95.09433,
      city: 'Park Rapids',
      state: 'MN',
    },
    56472: {
      lat: 46.57507,
      lng: -94.34884,
      city: 'Pequot Lakes',
      state: 'MN',
    },
    56473: {
      lat: 46.35649,
      lng: -94.48355,
      city: 'Pillager',
      state: 'MN',
    },
    56474: {
      lat: 46.72238,
      lng: -94.39597,
      city: 'Pine River',
      state: 'MN',
    },
    56475: {
      lat: 46.08361,
      lng: -94.53985,
      city: 'Randall',
      state: 'MN',
    },
    56477: {
      lat: 46.64363,
      lng: -94.99653,
      city: 'Sebeka',
      state: 'MN',
    },
    56479: {
      lat: 46.43719,
      lng: -94.78939,
      city: 'Staples',
      state: 'MN',
    },
    56481: {
      lat: 46.46706,
      lng: -94.93446,
      city: 'Verndale',
      state: 'MN',
    },
    56482: {
      lat: 46.46555,
      lng: -95.14307,
      city: 'Wadena',
      state: 'MN',
    },
    56484: {
      lat: 47.09744,
      lng: -94.47416,
      city: 'Walker',
      state: 'MN',
    },
    56501: {
      lat: 46.83665,
      lng: -95.82233,
      city: 'Detroit Lakes',
      state: 'MN',
    },
    56510: { lat: 47.35811, lng: -96.52316, city: 'Ada', state: 'MN' },
    56511: {
      lat: 46.8532,
      lng: -96.00763,
      city: 'Audubon',
      state: 'MN',
    },
    56514: {
      lat: 46.64892,
      lng: -96.43486,
      city: 'Barnesville',
      state: 'MN',
    },
    56515: {
      lat: 46.29444,
      lng: -95.708,
      city: 'Battle Lake',
      state: 'MN',
    },
    56516: {
      lat: 47.44368,
      lng: -95.96663,
      city: 'Bejou',
      state: 'MN',
    },
    56517: {
      lat: 47.55474,
      lng: -96.53825,
      city: 'Beltrami',
      state: 'MN',
    },
    56518: {
      lat: 46.48926,
      lng: -95.24506,
      city: 'Bluffton',
      state: 'MN',
    },
    56519: { lat: 47.15914, lng: -96.4877, city: 'Borup', state: 'MN' },
    56520: {
      lat: 46.28645,
      lng: -96.50361,
      city: 'Breckenridge',
      state: 'MN',
    },
    56521: {
      lat: 46.99691,
      lng: -95.89783,
      city: 'Callaway',
      state: 'MN',
    },
    56522: {
      lat: 46.1352,
      lng: -96.3887,
      city: 'Campbell',
      state: 'MN',
    },
    56523: {
      lat: 47.63838,
      lng: -96.80871,
      city: 'Climax',
      state: 'MN',
    },
    56524: {
      lat: 46.21115,
      lng: -95.606,
      city: 'Clitherall',
      state: 'MN',
    },
    56525: {
      lat: 46.6716,
      lng: -96.75195,
      city: 'Comstock',
      state: 'MN',
    },
    56527: {
      lat: 46.37383,
      lng: -95.31806,
      city: 'Deer Creek',
      state: 'MN',
    },
    56528: { lat: 46.53066, lng: -95.80983, city: 'Dent', state: 'MN' },
    56529: {
      lat: 46.88027,
      lng: -96.69754,
      city: 'Dilworth',
      state: 'MN',
    },
    56531: {
      lat: 45.98967,
      lng: -95.98376,
      city: 'Elbow Lake',
      state: 'MN',
    },
    56533: {
      lat: 46.40393,
      lng: -96.15144,
      city: 'Elizabeth',
      state: 'MN',
    },
    56534: {
      lat: 46.46325,
      lng: -96.01431,
      city: 'Erhard',
      state: 'MN',
    },
    56535: {
      lat: 47.67541,
      lng: -96.04611,
      city: 'Erskine',
      state: 'MN',
    },
    56536: {
      lat: 47.04596,
      lng: -96.53173,
      city: 'Felton',
      state: 'MN',
    },
    56537: {
      lat: 46.27697,
      lng: -96.08977,
      city: 'Fergus Falls',
      state: 'MN',
    },
    56540: {
      lat: 47.54255,
      lng: -96.27671,
      city: 'Fertile',
      state: 'MN',
    },
    56541: { lat: 47.16606, lng: -96.13081, city: 'Flom', state: 'MN' },
    56542: {
      lat: 47.56679,
      lng: -95.72331,
      city: 'Fosston',
      state: 'MN',
    },
    56543: {
      lat: 46.28541,
      lng: -96.33654,
      city: 'Foxhome',
      state: 'MN',
    },
    56544: {
      lat: 46.75894,
      lng: -95.59693,
      city: 'Frazee',
      state: 'MN',
    },
    56545: { lat: 47.38527, lng: -96.2399, city: 'Gary', state: 'MN' },
    56546: {
      lat: 47.10107,
      lng: -96.73038,
      city: 'Georgetown',
      state: 'MN',
    },
    56547: {
      lat: 46.89068,
      lng: -96.55043,
      city: 'Glyndon',
      state: 'MN',
    },
    56548: {
      lat: 47.37025,
      lng: -96.75392,
      city: 'Halstad',
      state: 'MN',
    },
    56549: {
      lat: 46.85287,
      lng: -96.31285,
      city: 'Hawley',
      state: 'MN',
    },
    56550: {
      lat: 47.2697,
      lng: -96.74867,
      city: 'Hendrum',
      state: 'MN',
    },
    56551: {
      lat: 46.31856,
      lng: -95.45081,
      city: 'Henning',
      state: 'MN',
    },
    56552: {
      lat: 46.9814,
      lng: -96.21602,
      city: 'Hitterdal',
      state: 'MN',
    },
    56553: { lat: 46.42946, lng: -96.61757, city: 'Kent', state: 'MN' },
    56554: {
      lat: 46.88408,
      lng: -96.11483,
      city: 'Lake Park',
      state: 'MN',
    },
    56556: {
      lat: 47.66403,
      lng: -95.88349,
      city: 'Mcintosh',
      state: 'MN',
    },
    56557: {
      lat: 47.32422,
      lng: -95.837,
      city: 'Mahnomen',
      state: 'MN',
    },
    56560: {
      lat: 46.83637,
      lng: -96.73467,
      city: 'Moorhead',
      state: 'MN',
    },
    56562: {
      lat: 46.86458,
      lng: -96.77021,
      city: 'Moorhead',
      state: 'MN',
    },
    56563: {
      lat: 46.8668,
      lng: -96.75522,
      city: 'Moorhead',
      state: 'MN',
    },
    56565: {
      lat: 46.04861,
      lng: -96.31511,
      city: 'Nashua',
      state: 'MN',
    },
    56566: {
      lat: 47.24724,
      lng: -95.61322,
      city: 'Naytahwaush',
      state: 'MN',
    },
    56567: {
      lat: 46.54896,
      lng: -95.36707,
      city: 'New York Mills',
      state: 'MN',
    },
    56568: {
      lat: 47.5292,
      lng: -96.77925,
      city: 'Nielsville',
      state: 'MN',
    },
    56569: {
      lat: 47.07482,
      lng: -95.81956,
      city: 'Ogema',
      state: 'MN',
    },
    56570: {
      lat: 46.91051,
      lng: -95.35552,
      city: 'Osage',
      state: 'MN',
    },
    56571: {
      lat: 46.43856,
      lng: -95.53939,
      city: 'Ottertail',
      state: 'MN',
    },
    56572: {
      lat: 46.60485,
      lng: -96.08682,
      city: 'Pelican Rapids',
      state: 'MN',
    },
    56573: {
      lat: 46.61443,
      lng: -95.55216,
      city: 'Perham',
      state: 'MN',
    },
    56574: {
      lat: 47.20085,
      lng: -96.70242,
      city: 'Perley',
      state: 'MN',
    },
    56575: {
      lat: 47.03827,
      lng: -95.43146,
      city: 'Ponsford',
      state: 'MN',
    },
    56576: {
      lat: 46.47164,
      lng: -95.70537,
      city: 'Richville',
      state: 'MN',
    },
    56578: {
      lat: 46.9264,
      lng: -95.65563,
      city: 'Rochert',
      state: 'MN',
    },
    56579: {
      lat: 46.4688,
      lng: -96.34229,
      city: 'Rothsay',
      state: 'MN',
    },
    56580: {
      lat: 46.73944,
      lng: -96.61004,
      city: 'Sabin',
      state: 'MN',
    },
    56581: {
      lat: 47.45725,
      lng: -96.76141,
      city: 'Shelly',
      state: 'MN',
    },
    56583: {
      lat: 46.00332,
      lng: -96.40667,
      city: 'Tintah',
      state: 'MN',
    },
    56584: {
      lat: 47.23087,
      lng: -96.23496,
      city: 'Twin Valley',
      state: 'MN',
    },
    56585: { lat: 47.08896, lng: -96.24492, city: 'Ulen', state: 'MN' },
    56586: {
      lat: 46.32324,
      lng: -95.84,
      city: 'Underwood',
      state: 'MN',
    },
    56587: {
      lat: 46.63721,
      lng: -95.8628,
      city: 'Vergas',
      state: 'MN',
    },
    56588: {
      lat: 46.22028,
      lng: -95.5006,
      city: 'Vining',
      state: 'MN',
    },
    56589: {
      lat: 47.17959,
      lng: -95.71984,
      city: 'Waubun',
      state: 'MN',
    },
    56590: {
      lat: 46.03514,
      lng: -96.16093,
      city: 'Wendell',
      state: 'MN',
    },
    56591: {
      lat: 47.09968,
      lng: -95.84357,
      city: 'White Earth',
      state: 'MN',
    },
    56592: {
      lat: 47.53542,
      lng: -96.00769,
      city: 'Winger',
      state: 'MN',
    },
    56594: {
      lat: 46.54906,
      lng: -96.64493,
      city: 'Wolverton',
      state: 'MN',
    },
    56601: {
      lat: 47.50985,
      lng: -94.84921,
      city: 'Bemidji',
      state: 'MN',
    },
    56621: {
      lat: 47.40975,
      lng: -95.41597,
      city: 'Bagley',
      state: 'MN',
    },
    56623: {
      lat: 48.59722,
      lng: -94.56913,
      city: 'Baudette',
      state: 'MN',
    },
    56626: { lat: 47.36404, lng: -94.18421, city: 'Bena', state: 'MN' },
    56627: {
      lat: 48.1953,
      lng: -94.04118,
      city: 'Big Falls',
      state: 'MN',
    },
    56628: {
      lat: 47.69575,
      lng: -93.5215,
      city: 'Bigfork',
      state: 'MN',
    },
    56629: {
      lat: 48.51494,
      lng: -94.12546,
      city: 'Birchdale',
      state: 'MN',
    },
    56630: {
      lat: 47.74432,
      lng: -94.49852,
      city: 'Blackduck',
      state: 'MN',
    },
    56633: {
      lat: 47.31957,
      lng: -94.50911,
      city: 'Cass Lake',
      state: 'MN',
    },
    56634: {
      lat: 47.68577,
      lng: -95.40169,
      city: 'Clearbrook',
      state: 'MN',
    },
    56636: {
      lat: 47.40283,
      lng: -93.86518,
      city: 'Deer River',
      state: 'MN',
    },
    56637: {
      lat: 47.61872,
      lng: -93.83941,
      city: 'Talmoon',
      state: 'MN',
    },
    56639: {
      lat: 47.90275,
      lng: -93.53399,
      city: 'Effie',
      state: 'MN',
    },
    56641: {
      lat: 47.19848,
      lng: -94.25804,
      city: 'Federal Dam',
      state: 'MN',
    },
    56644: {
      lat: 47.78095,
      lng: -95.51263,
      city: 'Gonvick',
      state: 'MN',
    },
    56646: {
      lat: 47.78925,
      lng: -95.62865,
      city: 'Gully',
      state: 'MN',
    },
    56647: {
      lat: 47.68782,
      lng: -94.62187,
      city: 'Hines',
      state: 'MN',
    },
    56649: {
      lat: 48.53122,
      lng: -93.13224,
      city: 'International Falls',
      state: 'MN',
    },
    56650: {
      lat: 47.9979,
      lng: -94.56351,
      city: 'Kelliher',
      state: 'MN',
    },
    56651: {
      lat: 47.43883,
      lng: -95.6172,
      city: 'Lengby',
      state: 'MN',
    },
    56652: {
      lat: 47.71104,
      lng: -95.23721,
      city: 'Leonard',
      state: 'MN',
    },
    56653: {
      lat: 48.28327,
      lng: -93.58558,
      city: 'Littlefork',
      state: 'MN',
    },
    56654: {
      lat: 48.47285,
      lng: -93.94153,
      city: 'Loman',
      state: 'MN',
    },
    56655: {
      lat: 46.98292,
      lng: -94.23014,
      city: 'Longville',
      state: 'MN',
    },
    56657: {
      lat: 47.57237,
      lng: -93.61544,
      city: 'Marcell',
      state: 'MN',
    },
    56658: {
      lat: 48.11248,
      lng: -93.93302,
      city: 'Margie',
      state: 'MN',
    },
    56659: { lat: 47.62116, lng: -94.01899, city: 'Max', state: 'MN' },
    56660: {
      lat: 47.96653,
      lng: -94.17022,
      city: 'Mizpah',
      state: 'MN',
    },
    56661: {
      lat: 47.84302,
      lng: -94.13856,
      city: 'Northome',
      state: 'MN',
    },
    56662: {
      lat: 46.84524,
      lng: -93.89926,
      city: 'Outing',
      state: 'MN',
    },
    56663: {
      lat: 47.49997,
      lng: -94.45431,
      city: 'Pennington',
      state: 'MN',
    },
    56666: {
      lat: 48.03391,
      lng: -94.8365,
      city: 'Ponemah',
      state: 'MN',
    },
    56667: {
      lat: 47.73569,
      lng: -94.94199,
      city: 'Puposky',
      state: 'MN',
    },
    56668: {
      lat: 48.61454,
      lng: -93.3467,
      city: 'Ranier',
      state: 'MN',
    },
    56669: {
      lat: 48.37718,
      lng: -93.22655,
      city: 'Kabetogama',
      state: 'MN',
    },
    56670: {
      lat: 47.85001,
      lng: -94.86912,
      city: 'Redby',
      state: 'MN',
    },
    56671: {
      lat: 47.94006,
      lng: -95.31791,
      city: 'Redlake',
      state: 'MN',
    },
    56672: { lat: 47.0734, lng: -94.02267, city: 'Remer', state: 'MN' },
    56673: {
      lat: 48.74365,
      lng: -95.13896,
      city: 'Roosevelt',
      state: 'MN',
    },
    56676: {
      lat: 47.52188,
      lng: -95.19877,
      city: 'Shevlin',
      state: 'MN',
    },
    56678: {
      lat: 47.4298,
      lng: -95.11768,
      city: 'Solway',
      state: 'MN',
    },
    56680: {
      lat: 47.6456,
      lng: -93.96124,
      city: 'Spring Lake',
      state: 'MN',
    },
    56681: {
      lat: 47.5488,
      lng: -94.25132,
      city: 'Squaw Lake',
      state: 'MN',
    },
    56683: {
      lat: 47.72124,
      lng: -94.7512,
      city: 'Tenstrike',
      state: 'MN',
    },
    56684: {
      lat: 47.87109,
      lng: -95.70401,
      city: 'Trail',
      state: 'MN',
    },
    56685: {
      lat: 48.23841,
      lng: -94.51997,
      city: 'Waskish',
      state: 'MN',
    },
    56686: {
      lat: 48.78595,
      lng: -94.91001,
      city: 'Williams',
      state: 'MN',
    },
    56688: { lat: 47.7699, lng: -93.89172, city: 'Wirt', state: 'MN' },
    56701: {
      lat: 48.12021,
      lng: -96.1994,
      city: 'Thief River Falls',
      state: 'MN',
    },
    56710: {
      lat: 48.2182,
      lng: -96.99776,
      city: 'Alvarado',
      state: 'MN',
    },
    56711: {
      lat: 49.09907,
      lng: -94.97591,
      city: 'Angle Inlet',
      state: 'MN',
    },
    56713: {
      lat: 48.3487,
      lng: -96.81717,
      city: 'Argyle',
      state: 'MN',
    },
    56714: {
      lat: 48.78845,
      lng: -96.00529,
      city: 'Badger',
      state: 'MN',
    },
    56715: {
      lat: 47.80636,
      lng: -95.94904,
      city: 'Brooks',
      state: 'MN',
    },
    56716: {
      lat: 47.74189,
      lng: -96.55522,
      city: 'Crookston',
      state: 'MN',
    },
    56720: {
      lat: 48.56788,
      lng: -96.90632,
      city: 'Donaldson',
      state: 'MN',
    },
    56721: {
      lat: 47.97108,
      lng: -96.94336,
      city: 'East Grand Forks',
      state: 'MN',
    },
    56722: {
      lat: 47.9825,
      lng: -96.64644,
      city: 'Euclid',
      state: 'MN',
    },
    56723: {
      lat: 47.80493,
      lng: -96.8059,
      city: 'Fisher',
      state: 'MN',
    },
    56724: {
      lat: 48.44534,
      lng: -95.73473,
      city: 'Gatzke',
      state: 'MN',
    },
    56725: {
      lat: 48.1477,
      lng: -95.70485,
      city: 'Goodridge',
      state: 'MN',
    },
    56726: {
      lat: 48.7258,
      lng: -96.22712,
      city: 'Greenbush',
      state: 'MN',
    },
    56727: {
      lat: 48.34945,
      lng: -95.38946,
      city: 'Grygla',
      state: 'MN',
    },
    56728: {
      lat: 48.78736,
      lng: -96.99149,
      city: 'Hallock',
      state: 'MN',
    },
    56729: {
      lat: 48.66558,
      lng: -96.59438,
      city: 'Halma',
      state: 'MN',
    },
    56731: {
      lat: 48.90771,
      lng: -97.07475,
      city: 'Humboldt',
      state: 'MN',
    },
    56732: {
      lat: 48.5863,
      lng: -96.49276,
      city: 'Karlstad',
      state: 'MN',
    },
    56733: {
      lat: 48.63342,
      lng: -96.89149,
      city: 'Kennedy',
      state: 'MN',
    },
    56734: {
      lat: 48.76776,
      lng: -96.58134,
      city: 'Lake Bronson',
      state: 'MN',
    },
    56735: {
      lat: 48.91328,
      lng: -96.63659,
      city: 'Lancaster',
      state: 'MN',
    },
    56736: {
      lat: 47.71212,
      lng: -96.20567,
      city: 'Mentor',
      state: 'MN',
    },
    56737: {
      lat: 48.41144,
      lng: -96.01081,
      city: 'Middle River',
      state: 'MN',
    },
    56738: {
      lat: 48.34448,
      lng: -96.33456,
      city: 'Newfolden',
      state: 'MN',
    },
    56742: {
      lat: 47.92728,
      lng: -95.82076,
      city: 'Oklee',
      state: 'MN',
    },
    56744: { lat: 48.23456, lng: -97.1089, city: 'Oslo', state: 'MN' },
    56748: {
      lat: 47.92066,
      lng: -96.00743,
      city: 'Plummer',
      state: 'MN',
    },
    56750: {
      lat: 47.89027,
      lng: -96.30172,
      city: 'Red Lake Falls',
      state: 'MN',
    },
    56751: {
      lat: 48.80929,
      lng: -95.76035,
      city: 'Roseau',
      state: 'MN',
    },
    56754: {
      lat: 47.99065,
      lng: -96.18199,
      city: 'Saint Hilaire',
      state: 'MN',
    },
    56755: {
      lat: 48.97141,
      lng: -97.1152,
      city: 'Saint Vincent',
      state: 'MN',
    },
    56756: {
      lat: 48.90302,
      lng: -95.56388,
      city: 'Salol',
      state: 'MN',
    },
    56757: {
      lat: 48.47641,
      lng: -96.90596,
      city: 'Stephen',
      state: 'MN',
    },
    56758: {
      lat: 48.46201,
      lng: -96.51891,
      city: 'Strandquist',
      state: 'MN',
    },
    56759: {
      lat: 48.55188,
      lng: -96.08011,
      city: 'Strathcona',
      state: 'MN',
    },
    56760: {
      lat: 48.24037,
      lng: -96.44586,
      city: 'Viking',
      state: 'MN',
    },
    56761: {
      lat: 48.60862,
      lng: -95.69725,
      city: 'Wannaska',
      state: 'MN',
    },
    56762: { lat: 48.1688, lng: -96.7415, city: 'Warren', state: 'MN' },
    56763: {
      lat: 48.80879,
      lng: -95.34167,
      city: 'Warroad',
      state: 'MN',
    },
    57001: {
      lat: 42.9981,
      lng: -96.65492,
      city: 'Alcester',
      state: 'SD',
    },
    57002: {
      lat: 44.28785,
      lng: -96.67752,
      city: 'Aurora',
      state: 'SD',
    },
    57003: {
      lat: 43.73705,
      lng: -96.75784,
      city: 'Baltic',
      state: 'SD',
    },
    57004: {
      lat: 43.07571,
      lng: -96.79376,
      city: 'Beresford',
      state: 'SD',
    },
    57005: {
      lat: 43.59281,
      lng: -96.58728,
      city: 'Brandon',
      state: 'SD',
    },
    57006: {
      lat: 44.31588,
      lng: -96.79518,
      city: 'Brookings',
      state: 'SD',
    },
    57007: {
      lat: 44.31924,
      lng: -96.78408,
      city: 'Brookings',
      state: 'SD',
    },
    57010: {
      lat: 42.8119,
      lng: -96.82403,
      city: 'Burbank',
      state: 'SD',
    },
    57012: {
      lat: 43.59683,
      lng: -97.27986,
      city: 'Canistota',
      state: 'SD',
    },
    57013: {
      lat: 43.28595,
      lng: -96.62178,
      city: 'Canton',
      state: 'SD',
    },
    57014: {
      lat: 43.11238,
      lng: -96.95347,
      city: 'Centerville',
      state: 'SD',
    },
    57015: {
      lat: 43.42182,
      lng: -96.98006,
      city: 'Chancellor',
      state: 'SD',
    },
    57016: {
      lat: 43.88874,
      lng: -96.96139,
      city: 'Chester',
      state: 'SD',
    },
    57017: {
      lat: 44.02246,
      lng: -96.82788,
      city: 'Colman',
      state: 'SD',
    },
    57018: {
      lat: 43.79995,
      lng: -96.97901,
      city: 'Colton',
      state: 'SD',
    },
    57020: {
      lat: 43.67796,
      lng: -96.82665,
      city: 'Crooks',
      state: 'SD',
    },
    57021: { lat: 43.2723, lng: -96.97797, city: 'Davis', state: 'SD' },
    57022: {
      lat: 43.85081,
      lng: -96.7327,
      city: 'Dell Rapids',
      state: 'SD',
    },
    57024: { lat: 43.98538, lng: -96.68146, city: 'Egan', state: 'SD' },
    57025: {
      lat: 42.71718,
      lng: -96.68894,
      city: 'Elk Point',
      state: 'SD',
    },
    57026: {
      lat: 44.24996,
      lng: -96.50149,
      city: 'Elkton',
      state: 'SD',
    },
    57027: {
      lat: 43.19461,
      lng: -96.50933,
      city: 'Fairview',
      state: 'SD',
    },
    57028: {
      lat: 44.05766,
      lng: -96.60443,
      city: 'Flandreau',
      state: 'SD',
    },
    57029: {
      lat: 43.33696,
      lng: -97.47936,
      city: 'Freeman',
      state: 'SD',
    },
    57030: {
      lat: 43.73435,
      lng: -96.53549,
      city: 'Garretson',
      state: 'SD',
    },
    57031: {
      lat: 42.87058,
      lng: -97.18655,
      city: 'Gayville',
      state: 'SD',
    },
    57032: {
      lat: 43.42326,
      lng: -96.68708,
      city: 'Harrisburg',
      state: 'SD',
    },
    57033: {
      lat: 43.61613,
      lng: -96.96037,
      city: 'Hartford',
      state: 'SD',
    },
    57034: {
      lat: 43.12493,
      lng: -96.54575,
      city: 'Hudson',
      state: 'SD',
    },
    57035: {
      lat: 43.64651,
      lng: -97.07627,
      city: 'Humboldt',
      state: 'SD',
    },
    57036: {
      lat: 43.27067,
      lng: -97.14364,
      city: 'Hurley',
      state: 'SD',
    },
    57037: {
      lat: 43.10146,
      lng: -97.25318,
      city: 'Irene',
      state: 'SD',
    },
    57038: {
      lat: 42.57924,
      lng: -96.57957,
      city: 'Jefferson',
      state: 'SD',
    },
    57039: {
      lat: 43.33659,
      lng: -96.86755,
      city: 'Lennox',
      state: 'SD',
    },
    57040: {
      lat: 43.06206,
      lng: -97.58582,
      city: 'Lesterville',
      state: 'SD',
    },
    57041: {
      lat: 43.72184,
      lng: -96.88415,
      city: 'Lyons',
      state: 'SD',
    },
    57042: {
      lat: 43.97744,
      lng: -97.15133,
      city: 'Madison',
      state: 'SD',
    },
    57043: {
      lat: 43.41954,
      lng: -97.30889,
      city: 'Marion',
      state: 'SD',
    },
    57045: {
      lat: 43.20051,
      lng: -97.52425,
      city: 'Menno',
      state: 'SD',
    },
    57046: {
      lat: 42.9563,
      lng: -97.30113,
      city: 'Mission Hill',
      state: 'SD',
    },
    57047: {
      lat: 43.51755,
      lng: -97.21525,
      city: 'Monroe',
      state: 'SD',
    },
    57048: {
      lat: 43.74081,
      lng: -97.20034,
      city: 'Montrose',
      state: 'SD',
    },
    57049: {
      lat: 42.52486,
      lng: -96.50732,
      city: 'North Sioux City',
      state: 'SD',
    },
    57050: {
      lat: 44.15725,
      lng: -97.01467,
      city: 'Nunda',
      state: 'SD',
    },
    57051: {
      lat: 44.21741,
      lng: -97.35066,
      city: 'Oldham',
      state: 'SD',
    },
    57052: {
      lat: 43.29471,
      lng: -97.7054,
      city: 'Olivet',
      state: 'SD',
    },
    57053: {
      lat: 43.40963,
      lng: -97.13716,
      city: 'Parker',
      state: 'SD',
    },
    57054: {
      lat: 44.12162,
      lng: -97.25799,
      city: 'Ramona',
      state: 'SD',
    },
    57055: {
      lat: 43.66638,
      lng: -96.73973,
      city: 'Renner',
      state: 'SD',
    },
    57057: {
      lat: 44.10261,
      lng: -96.95717,
      city: 'Rutland',
      state: 'SD',
    },
    57058: {
      lat: 43.74602,
      lng: -97.40468,
      city: 'Salem',
      state: 'SD',
    },
    57059: {
      lat: 43.12476,
      lng: -97.76365,
      city: 'Scotland',
      state: 'SD',
    },
    57061: {
      lat: 44.24452,
      lng: -97.04399,
      city: 'Sinai',
      state: 'SD',
    },
    57062: {
      lat: 42.85046,
      lng: -97.95327,
      city: 'Springfield',
      state: 'SD',
    },
    57063: {
      lat: 42.92483,
      lng: -97.68668,
      city: 'Tabor',
      state: 'SD',
    },
    57064: { lat: 43.45563, lng: -96.87489, city: 'Tea', state: 'SD' },
    57065: {
      lat: 43.90041,
      lng: -96.60631,
      city: 'Trent',
      state: 'SD',
    },
    57066: {
      lat: 42.98874,
      lng: -97.86703,
      city: 'Tyndall',
      state: 'SD',
    },
    57067: {
      lat: 43.04095,
      lng: -97.47169,
      city: 'Utica',
      state: 'SD',
    },
    57068: {
      lat: 43.57435,
      lng: -96.49361,
      city: 'Valley Springs',
      state: 'SD',
    },
    57069: {
      lat: 42.84348,
      lng: -96.98774,
      city: 'Vermillion',
      state: 'SD',
    },
    57070: { lat: 43.182, lng: -97.1702, city: 'Viborg', state: 'SD' },
    57071: {
      lat: 44.26986,
      lng: -96.95934,
      city: 'Volga',
      state: 'SD',
    },
    57072: {
      lat: 42.98747,
      lng: -97.20363,
      city: 'Volin',
      state: 'SD',
    },
    57073: {
      lat: 43.00227,
      lng: -97.05754,
      city: 'Wakonda',
      state: 'SD',
    },
    57075: {
      lat: 43.99605,
      lng: -96.96749,
      city: 'Wentworth',
      state: 'SD',
    },
    57076: {
      lat: 43.97673,
      lng: -97.35307,
      city: 'Winfred',
      state: 'SD',
    },
    57077: {
      lat: 43.31574,
      lng: -96.75699,
      city: 'Worthing',
      state: 'SD',
    },
    57078: {
      lat: 42.91782,
      lng: -97.44702,
      city: 'Yankton',
      state: 'SD',
    },
    57103: {
      lat: 43.538,
      lng: -96.69241,
      city: 'Sioux Falls',
      state: 'SD',
    },
    57104: {
      lat: 43.6041,
      lng: -96.70442,
      city: 'Sioux Falls',
      state: 'SD',
    },
    57105: {
      lat: 43.52125,
      lng: -96.73587,
      city: 'Sioux Falls',
      state: 'SD',
    },
    57106: {
      lat: 43.50953,
      lng: -96.83919,
      city: 'Sioux Falls',
      state: 'SD',
    },
    57107: {
      lat: 43.59468,
      lng: -96.81962,
      city: 'Sioux Falls',
      state: 'SD',
    },
    57108: {
      lat: 43.47776,
      lng: -96.70571,
      city: 'Sioux Falls',
      state: 'SD',
    },
    57110: {
      lat: 43.54445,
      lng: -96.63812,
      city: 'Sioux Falls',
      state: 'SD',
    },
    57117: {
      lat: 43.53124,
      lng: -96.75497,
      city: 'Sioux Falls',
      state: 'SD',
    },
    57197: {
      lat: 43.52455,
      lng: -96.73862,
      city: 'Sioux Falls',
      state: 'SD',
    },
    57201: {
      lat: 44.93412,
      lng: -97.09424,
      city: 'Watertown',
      state: 'SD',
    },
    57212: {
      lat: 44.38111,
      lng: -97.14344,
      city: 'Arlington',
      state: 'SD',
    },
    57213: {
      lat: 44.57112,
      lng: -96.5323,
      city: 'Astoria',
      state: 'SD',
    },
    57214: {
      lat: 44.47764,
      lng: -97.19575,
      city: 'Badger',
      state: 'SD',
    },
    57216: {
      lat: 45.30041,
      lng: -96.52471,
      city: 'Big Stone City',
      state: 'SD',
    },
    57217: {
      lat: 45.0845,
      lng: -97.67004,
      city: 'Bradley',
      state: 'SD',
    },
    57218: {
      lat: 44.65787,
      lng: -96.59624,
      city: 'Brandt',
      state: 'SD',
    },
    57219: { lat: 45.2877, lng: -97.801, city: 'Bristol', state: 'SD' },
    57220: {
      lat: 44.45927,
      lng: -96.92022,
      city: 'Bruce',
      state: 'SD',
    },
    57221: {
      lat: 44.60654,
      lng: -97.44853,
      city: 'Bryant',
      state: 'SD',
    },
    57223: {
      lat: 44.71019,
      lng: -97.00567,
      city: 'Castlewood',
      state: 'SD',
    },
    57224: {
      lat: 45.81332,
      lng: -97.15672,
      city: 'Claire City',
      state: 'SD',
    },
    57225: {
      lat: 44.88359,
      lng: -97.72676,
      city: 'Clark',
      state: 'SD',
    },
    57226: {
      lat: 44.78565,
      lng: -96.72368,
      city: 'Clear Lake',
      state: 'SD',
    },
    57227: {
      lat: 45.36184,
      lng: -96.72105,
      city: 'Corona',
      state: 'SD',
    },
    57231: {
      lat: 44.3749,
      lng: -97.58243,
      city: 'De Smet',
      state: 'SD',
    },
    57232: { lat: 45.61752, lng: -97.38393, city: 'Eden', state: 'SD' },
    57233: { lat: 44.50992, lng: -97.3993, city: 'Erwin', state: 'SD' },
    57234: {
      lat: 44.59719,
      lng: -96.89929,
      city: 'Estelline',
      state: 'SD',
    },
    57235: {
      lat: 45.06896,
      lng: -97.31338,
      city: 'Florence',
      state: 'SD',
    },
    57236: {
      lat: 44.98724,
      lng: -97.59033,
      city: 'Garden City',
      state: 'SD',
    },
    57237: { lat: 44.79323, lng: -96.51991, city: 'Gary', state: 'SD' },
    57238: {
      lat: 44.87287,
      lng: -96.86231,
      city: 'Goodwin',
      state: 'SD',
    },
    57239: {
      lat: 45.51223,
      lng: -97.30938,
      city: 'Grenville',
      state: 'SD',
    },
    57241: {
      lat: 44.69742,
      lng: -97.21747,
      city: 'Hayti',
      state: 'SD',
    },
    57242: {
      lat: 44.77352,
      lng: -97.35902,
      city: 'Hazel',
      state: 'SD',
    },
    57243: {
      lat: 44.91177,
      lng: -97.42072,
      city: 'Henry',
      state: 'SD',
    },
    57245: {
      lat: 44.89365,
      lng: -96.91835,
      city: 'Kranzburg',
      state: 'SD',
    },
    57246: {
      lat: 45.05639,
      lng: -96.66914,
      city: 'Labolt',
      state: 'SD',
    },
    57247: {
      lat: 45.71675,
      lng: -97.45817,
      city: 'Lake City',
      state: 'SD',
    },
    57248: {
      lat: 44.58063,
      lng: -97.19328,
      city: 'Lake Norden',
      state: 'SD',
    },
    57249: {
      lat: 44.37111,
      lng: -97.3646,
      city: 'Lake Preston',
      state: 'SD',
    },
    57251: {
      lat: 45.27717,
      lng: -96.93677,
      city: 'Marvin',
      state: 'SD',
    },
    57252: {
      lat: 45.20507,
      lng: -96.61053,
      city: 'Milbank',
      state: 'SD',
    },
    57255: {
      lat: 45.86702,
      lng: -96.94985,
      city: 'New Effington',
      state: 'SD',
    },
    57256: {
      lat: 45.24918,
      lng: -97.20156,
      city: 'Ortley',
      state: 'SD',
    },
    57257: {
      lat: 45.49975,
      lng: -97.02982,
      city: 'Peever',
      state: 'SD',
    },
    57258: {
      lat: 44.88434,
      lng: -97.9246,
      city: 'Raymond',
      state: 'SD',
    },
    57259: {
      lat: 45.00047,
      lng: -96.56786,
      city: 'Revillo',
      state: 'SD',
    },
    57260: {
      lat: 45.83641,
      lng: -96.7172,
      city: 'Rosholt',
      state: 'SD',
    },
    57261: {
      lat: 45.54051,
      lng: -97.49412,
      city: 'Roslyn',
      state: 'SD',
    },
    57262: {
      lat: 45.67288,
      lng: -97.06641,
      city: 'Sisseton',
      state: 'SD',
    },
    57263: {
      lat: 45.14106,
      lng: -97.00589,
      city: 'South Shore',
      state: 'SD',
    },
    57264: {
      lat: 45.10863,
      lng: -96.80254,
      city: 'Stockholm',
      state: 'SD',
    },
    57265: {
      lat: 44.98866,
      lng: -96.79591,
      city: 'Strandburg',
      state: 'SD',
    },
    57266: {
      lat: 45.28619,
      lng: -97.08985,
      city: 'Summit',
      state: 'SD',
    },
    57268: {
      lat: 44.56885,
      lng: -96.68781,
      city: 'Toronto',
      state: 'SD',
    },
    57269: {
      lat: 45.22273,
      lng: -96.80182,
      city: 'Twin Brooks',
      state: 'SD',
    },
    57270: {
      lat: 45.84824,
      lng: -97.32999,
      city: 'Veblen',
      state: 'SD',
    },
    57271: {
      lat: 44.75179,
      lng: -97.55161,
      city: 'Vienna',
      state: 'SD',
    },
    57272: {
      lat: 45.09564,
      lng: -97.48002,
      city: 'Wallace',
      state: 'SD',
    },
    57273: {
      lat: 45.33334,
      lng: -97.27574,
      city: 'Waubay',
      state: 'SD',
    },
    57274: {
      lat: 45.30943,
      lng: -97.55533,
      city: 'Webster',
      state: 'SD',
    },
    57276: {
      lat: 44.43308,
      lng: -96.61983,
      city: 'White',
      state: 'SD',
    },
    57278: {
      lat: 44.64689,
      lng: -97.68227,
      city: 'Willow Lake',
      state: 'SD',
    },
    57279: {
      lat: 45.41816,
      lng: -96.87274,
      city: 'Wilmot',
      state: 'SD',
    },
    57301: {
      lat: 43.71136,
      lng: -98.04155,
      city: 'Mitchell',
      state: 'SD',
    },
    57311: {
      lat: 43.65112,
      lng: -97.76483,
      city: 'Alexandria',
      state: 'SD',
    },
    57312: {
      lat: 44.19646,
      lng: -98.38564,
      city: 'Alpena',
      state: 'SD',
    },
    57313: {
      lat: 43.31085,
      lng: -98.36327,
      city: 'Armour',
      state: 'SD',
    },
    57314: {
      lat: 44.0509,
      lng: -97.98904,
      city: 'Artesian',
      state: 'SD',
    },
    57315: { lat: 42.99391, lng: -98.04852, city: 'Avon', state: 'SD' },
    57317: {
      lat: 43.13153,
      lng: -98.97891,
      city: 'Bonesteel',
      state: 'SD',
    },
    57319: {
      lat: 43.53853,
      lng: -97.48353,
      city: 'Bridgewater',
      state: 'SD',
    },
    57321: {
      lat: 43.87294,
      lng: -97.54864,
      city: 'Canova',
      state: 'SD',
    },
    57322: {
      lat: 44.63498,
      lng: -97.96129,
      city: 'Carpenter',
      state: 'SD',
    },
    57323: {
      lat: 44.16259,
      lng: -97.72115,
      city: 'Carthage',
      state: 'SD',
    },
    57324: {
      lat: 44.30154,
      lng: -98.04535,
      city: 'Cavour',
      state: 'SD',
    },
    57325: {
      lat: 43.83961,
      lng: -99.31144,
      city: 'Chamberlain',
      state: 'SD',
    },
    57328: {
      lat: 43.43413,
      lng: -98.44207,
      city: 'Corsica',
      state: 'SD',
    },
    57329: { lat: 42.97814, lng: -98.1522, city: 'Dante', state: 'SD' },
    57330: {
      lat: 43.26147,
      lng: -98.16082,
      city: 'Delmont',
      state: 'SD',
    },
    57331: {
      lat: 43.47337,
      lng: -98.04619,
      city: 'Dimock',
      state: 'SD',
    },
    57332: { lat: 43.5465, lng: -97.64762, city: 'Emery', state: 'SD' },
    57334: {
      lat: 43.54199,
      lng: -97.99492,
      city: 'Ethan',
      state: 'SD',
    },
    57335: {
      lat: 43.06005,
      lng: -98.77255,
      city: 'Fairfax',
      state: 'SD',
    },
    57337: {
      lat: 44.00108,
      lng: -97.79048,
      city: 'Fedora',
      state: 'SD',
    },
    57339: {
      lat: 44.11233,
      lng: -99.47862,
      city: 'Fort Thompson',
      state: 'SD',
    },
    57340: {
      lat: 43.79392,
      lng: -97.85036,
      city: 'Fulton',
      state: 'SD',
    },
    57341: {
      lat: 44.08077,
      lng: -99.08974,
      city: 'Gann Valley',
      state: 'SD',
    },
    57342: {
      lat: 43.24561,
      lng: -98.70054,
      city: 'Geddes',
      state: 'SD',
    },
    57344: {
      lat: 43.45575,
      lng: -98.61796,
      city: 'Harrison',
      state: 'SD',
    },
    57345: {
      lat: 44.5687,
      lng: -99.45009,
      city: 'Highmore',
      state: 'SD',
    },
    57346: {
      lat: 44.24818,
      lng: -99.45756,
      city: 'Stephan',
      state: 'SD',
    },
    57348: {
      lat: 44.62147,
      lng: -98.36483,
      city: 'Hitchcock',
      state: 'SD',
    },
    57349: {
      lat: 44.02671,
      lng: -97.57521,
      city: 'Howard',
      state: 'SD',
    },
    57350: { lat: 44.3948, lng: -98.20037, city: 'Huron', state: 'SD' },
    57353: {
      lat: 44.36944,
      lng: -97.83438,
      city: 'Iroquois',
      state: 'SD',
    },
    57354: {
      lat: 43.19099,
      lng: -97.84236,
      city: 'Kaylor',
      state: 'SD',
    },
    57355: {
      lat: 43.7747,
      lng: -98.95045,
      city: 'Kimball',
      state: 'SD',
    },
    57356: {
      lat: 43.1468,
      lng: -98.51803,
      city: 'Lake Andes',
      state: 'SD',
    },
    57358: { lat: 44.07521, lng: -98.42201, city: 'Lane', state: 'SD' },
    57359: {
      lat: 43.90105,
      lng: -98.15589,
      city: 'Letcher',
      state: 'SD',
    },
    57361: {
      lat: 42.99459,
      lng: -98.43463,
      city: 'Marty',
      state: 'SD',
    },
    57362: {
      lat: 44.47488,
      lng: -99.06447,
      city: 'Miller',
      state: 'SD',
    },
    57363: {
      lat: 43.68723,
      lng: -98.25491,
      city: 'Mount Vernon',
      state: 'SD',
    },
    57364: {
      lat: 43.43442,
      lng: -98.61786,
      city: 'New Holland',
      state: 'SD',
    },
    57365: {
      lat: 43.81148,
      lng: -99.41723,
      city: 'Oacoma',
      state: 'SD',
    },
    57366: {
      lat: 43.3855,
      lng: -97.94306,
      city: 'Parkston',
      state: 'SD',
    },
    57367: {
      lat: 43.06348,
      lng: -98.5133,
      city: 'Pickstown',
      state: 'SD',
    },
    57368: {
      lat: 43.75117,
      lng: -98.47593,
      city: 'Plankinton',
      state: 'SD',
    },
    57369: {
      lat: 43.42544,
      lng: -98.94606,
      city: 'Platte',
      state: 'SD',
    },
    57370: {
      lat: 43.80508,
      lng: -99.17211,
      city: 'Pukwana',
      state: 'SD',
    },
    57371: {
      lat: 44.43146,
      lng: -99.23104,
      city: 'Ree Heights',
      state: 'SD',
    },
    57373: {
      lat: 44.52936,
      lng: -98.86987,
      city: 'Saint Lawrence',
      state: 'SD',
    },
    57374: {
      lat: 43.75582,
      lng: -97.60361,
      city: 'Spencer',
      state: 'SD',
    },
    57375: {
      lat: 43.56222,
      lng: -98.47877,
      city: 'Stickney',
      state: 'SD',
    },
    57376: {
      lat: 43.21948,
      lng: -97.94176,
      city: 'Tripp',
      state: 'SD',
    },
    57379: {
      lat: 44.26428,
      lng: -98.53795,
      city: 'Virgil',
      state: 'SD',
    },
    57380: {
      lat: 43.03387,
      lng: -98.28916,
      city: 'Wagner',
      state: 'SD',
    },
    57381: {
      lat: 44.38717,
      lng: -98.73267,
      city: 'Wessington',
      state: 'SD',
    },
    57382: {
      lat: 44.05561,
      lng: -98.67303,
      city: 'Wessington Springs',
      state: 'SD',
    },
    57383: {
      lat: 43.7177,
      lng: -98.71382,
      city: 'White Lake',
      state: 'SD',
    },
    57384: {
      lat: 44.42044,
      lng: -98.48477,
      city: 'Wolsey',
      state: 'SD',
    },
    57385: {
      lat: 44.04433,
      lng: -98.29507,
      city: 'Woonsocket',
      state: 'SD',
    },
    57386: { lat: 44.50908, lng: -97.99845, city: 'Yale', state: 'SD' },
    57401: {
      lat: 45.4753,
      lng: -98.53042,
      city: 'Aberdeen',
      state: 'SD',
    },
    57420: {
      lat: 45.2852,
      lng: -100.14319,
      city: 'Akaska',
      state: 'SD',
    },
    57421: {
      lat: 45.75045,
      lng: -97.90618,
      city: 'Amherst',
      state: 'SD',
    },
    57422: {
      lat: 45.42288,
      lng: -97.92199,
      city: 'Andover',
      state: 'SD',
    },
    57424: {
      lat: 45.02012,
      lng: -98.53101,
      city: 'Ashton',
      state: 'SD',
    },
    57426: {
      lat: 45.73485,
      lng: -98.4936,
      city: 'Barnard',
      state: 'SD',
    },
    57427: { lat: 45.48109, lng: -98.31786, city: 'Bath', state: 'SD' },
    57428: {
      lat: 45.44984,
      lng: -99.67922,
      city: 'Bowdle',
      state: 'SD',
    },
    57429: {
      lat: 45.16116,
      lng: -98.3005,
      city: 'Brentford',
      state: 'SD',
    },
    57430: {
      lat: 45.82203,
      lng: -97.72205,
      city: 'Britton',
      state: 'SD',
    },
    57432: {
      lat: 45.6839,
      lng: -98.02453,
      city: 'Claremont',
      state: 'SD',
    },
    57433: {
      lat: 45.64311,
      lng: -98.32104,
      city: 'Columbia',
      state: 'SD',
    },
    57434: {
      lat: 45.16669,
      lng: -98.07049,
      city: 'Conde',
      state: 'SD',
    },
    57435: {
      lat: 45.16916,
      lng: -98.90975,
      city: 'Cresbard',
      state: 'SD',
    },
    57436: {
      lat: 44.84864,
      lng: -98.09131,
      city: 'Doland',
      state: 'SD',
    },
    57437: { lat: 45.78368, lng: -99.618, city: 'Eureka', state: 'SD' },
    57438: {
      lat: 45.06899,
      lng: -99.15462,
      city: 'Faulkton',
      state: 'SD',
    },
    57439: {
      lat: 45.33464,
      lng: -98.10007,
      city: 'Ferney',
      state: 'SD',
    },
    57440: {
      lat: 44.8502,
      lng: -98.25956,
      city: 'Frankfort',
      state: 'SD',
    },
    57441: {
      lat: 45.82275,
      lng: -98.51474,
      city: 'Frederick',
      state: 'SD',
    },
    57442: {
      lat: 45.05721,
      lng: -100.14997,
      city: 'Gettysburg',
      state: 'SD',
    },
    57445: {
      lat: 45.45828,
      lng: -98.12094,
      city: 'Groton',
      state: 'SD',
    },
    57446: {
      lat: 45.86698,
      lng: -98.15423,
      city: 'Hecla',
      state: 'SD',
    },
    57448: {
      lat: 45.61196,
      lng: -99.41859,
      city: 'Hosmer',
      state: 'SD',
    },
    57449: {
      lat: 45.73101,
      lng: -98.17553,
      city: 'Houghton',
      state: 'SD',
    },
    57450: {
      lat: 45.21599,
      lng: -99.83696,
      city: 'Hoven',
      state: 'SD',
    },
    57451: {
      lat: 45.43488,
      lng: -98.93473,
      city: 'Ipswich',
      state: 'SD',
    },
    57452: { lat: 45.52873, lng: -99.87334, city: 'Java', state: 'SD' },
    57454: {
      lat: 45.61058,
      lng: -97.77286,
      city: 'Langford',
      state: 'SD',
    },
    57455: {
      lat: 44.99301,
      lng: -99.74011,
      city: 'Lebanon',
      state: 'SD',
    },
    57456: {
      lat: 45.72104,
      lng: -98.99589,
      city: 'Leola',
      state: 'SD',
    },
    57457: {
      lat: 45.87281,
      lng: -99.15812,
      city: 'Long Lake',
      state: 'SD',
    },
    57460: {
      lat: 45.27323,
      lng: -98.66585,
      city: 'Mansfield',
      state: 'SD',
    },
    57461: {
      lat: 45.16085,
      lng: -98.43327,
      city: 'Mellette',
      state: 'SD',
    },
    57465: {
      lat: 45.14443,
      lng: -98.68573,
      city: 'Northville',
      state: 'SD',
    },
    57466: {
      lat: 45.20045,
      lng: -99.45885,
      city: 'Onaka',
      state: 'SD',
    },
    57467: {
      lat: 44.82754,
      lng: -99.14221,
      city: 'Orient',
      state: 'SD',
    },
    57468: {
      lat: 45.49797,
      lng: -97.75318,
      city: 'Pierpont',
      state: 'SD',
    },
    57469: {
      lat: 44.85858,
      lng: -98.57535,
      city: 'Redfield',
      state: 'SD',
    },
    57470: {
      lat: 44.87726,
      lng: -98.86709,
      city: 'Rockham',
      state: 'SD',
    },
    57471: {
      lat: 45.40967,
      lng: -99.31359,
      city: 'Roscoe',
      state: 'SD',
    },
    57472: {
      lat: 45.4284,
      lng: -100.10393,
      city: 'Selby',
      state: 'SD',
    },
    57473: { lat: 44.98993, lng: -99.483, city: 'Seneca', state: 'SD' },
    57474: {
      lat: 45.28577,
      lng: -98.27526,
      city: 'Stratford',
      state: 'SD',
    },
    57475: {
      lat: 45.22843,
      lng: -99.62031,
      city: 'Tolstoy',
      state: 'SD',
    },
    57476: {
      lat: 44.71277,
      lng: -98.62127,
      city: 'Tulare',
      state: 'SD',
    },
    57477: {
      lat: 45.03363,
      lng: -98.15024,
      city: 'Turton',
      state: 'SD',
    },
    57479: {
      lat: 45.30415,
      lng: -98.4382,
      city: 'Warner',
      state: 'SD',
    },
    57481: {
      lat: 45.65279,
      lng: -98.66821,
      city: 'Westport',
      state: 'SD',
    },
    57501: {
      lat: 44.53525,
      lng: -100.31906,
      city: 'Pierre',
      state: 'SD',
    },
    57520: {
      lat: 44.84159,
      lng: -100.16447,
      city: 'Agar',
      state: 'SD',
    },
    57521: {
      lat: 43.79349,
      lng: -101.18682,
      city: 'Belvidere',
      state: 'SD',
    },
    57522: {
      lat: 44.51014,
      lng: -99.96352,
      city: 'Blunt',
      state: 'SD',
    },
    57523: {
      lat: 43.24437,
      lng: -99.23795,
      city: 'Burke',
      state: 'SD',
    },
    57528: {
      lat: 43.17814,
      lng: -99.75901,
      city: 'Colome',
      state: 'SD',
    },
    57529: {
      lat: 43.22931,
      lng: -99.57019,
      city: 'Dallas',
      state: 'SD',
    },
    57531: {
      lat: 43.98038,
      lng: -100.48601,
      city: 'Draper',
      state: 'SD',
    },
    57532: {
      lat: 44.36129,
      lng: -100.56405,
      city: 'Fort Pierre',
      state: 'SD',
    },
    57533: {
      lat: 43.3463,
      lng: -99.41692,
      city: 'Gregory',
      state: 'SD',
    },
    57534: {
      lat: 43.5709,
      lng: -99.65975,
      city: 'Hamill',
      state: 'SD',
    },
    57536: {
      lat: 44.41502,
      lng: -99.76694,
      city: 'Harrold',
      state: 'SD',
    },
    57537: {
      lat: 44.58366,
      lng: -101.07399,
      city: 'Hayes',
      state: 'SD',
    },
    57538: {
      lat: 43.12167,
      lng: -99.15551,
      city: 'Herrick',
      state: 'SD',
    },
    57540: {
      lat: 44.46477,
      lng: -99.60377,
      city: 'Holabird',
      state: 'SD',
    },
    57541: {
      lat: 43.60001,
      lng: -99.90895,
      city: 'Ideal',
      state: 'SD',
    },
    57543: {
      lat: 43.74785,
      lng: -101.52268,
      city: 'Kadoka',
      state: 'SD',
    },
    57544: {
      lat: 43.88374,
      lng: -99.83647,
      city: 'Kennebec',
      state: 'SD',
    },
    57547: {
      lat: 43.48337,
      lng: -101.44054,
      city: 'Long Valley',
      state: 'SD',
    },
    57548: {
      lat: 44.08429,
      lng: -99.62373,
      city: 'Lower Brule',
      state: 'SD',
    },
    57551: {
      lat: 43.18087,
      lng: -101.70012,
      city: 'Martin',
      state: 'SD',
    },
    57552: {
      lat: 44.25903,
      lng: -101.20833,
      city: 'Midland',
      state: 'SD',
    },
    57553: {
      lat: 44.48547,
      lng: -101.64489,
      city: 'Milesville',
      state: 'SD',
    },
    57555: {
      lat: 43.29828,
      lng: -100.6601,
      city: 'Mission',
      state: 'SD',
    },
    57559: {
      lat: 43.93654,
      lng: -100.72054,
      city: 'Murdo',
      state: 'SD',
    },
    57560: {
      lat: 43.44006,
      lng: -101.21226,
      city: 'Norris',
      state: 'SD',
    },
    57562: {
      lat: 43.89962,
      lng: -100.95816,
      city: 'Okaton',
      state: 'SD',
    },
    57563: {
      lat: 43.36039,
      lng: -100.40149,
      city: 'Okreek',
      state: 'SD',
    },
    57564: {
      lat: 44.72401,
      lng: -100.0121,
      city: 'Onida',
      state: 'SD',
    },
    57566: {
      lat: 43.31237,
      lng: -101.10019,
      city: 'Parmelee',
      state: 'SD',
    },
    57567: {
      lat: 44.13519,
      lng: -101.7152,
      city: 'Philip',
      state: 'SD',
    },
    57568: {
      lat: 43.88498,
      lng: -100.08857,
      city: 'Presho',
      state: 'SD',
    },
    57569: {
      lat: 43.8605,
      lng: -99.57582,
      city: 'Reliance',
      state: 'SD',
    },
    57570: {
      lat: 43.24284,
      lng: -100.87127,
      city: 'Rosebud',
      state: 'SD',
    },
    57571: {
      lat: 43.04152,
      lng: -99.09042,
      city: 'Saint Charles',
      state: 'SD',
    },
    57572: {
      lat: 43.12009,
      lng: -101.08491,
      city: 'Saint Francis',
      state: 'SD',
    },
    57574: {
      lat: 43.10954,
      lng: -101.36238,
      city: 'Tuthill',
      state: 'SD',
    },
    57576: {
      lat: 43.90804,
      lng: -100.29411,
      city: 'Vivian',
      state: 'SD',
    },
    57577: {
      lat: 43.52353,
      lng: -101.66029,
      city: 'Wanblee',
      state: 'SD',
    },
    57579: {
      lat: 43.57484,
      lng: -100.78578,
      city: 'White River',
      state: 'SD',
    },
    57580: {
      lat: 43.28987,
      lng: -100.05772,
      city: 'Winner',
      state: 'SD',
    },
    57584: {
      lat: 43.49115,
      lng: -100.06382,
      city: 'Witten',
      state: 'SD',
    },
    57585: {
      lat: 43.55508,
      lng: -100.39752,
      city: 'Wood',
      state: 'SD',
    },
    57601: {
      lat: 45.36646,
      lng: -100.51108,
      city: 'Mobridge',
      state: 'SD',
    },
    57620: {
      lat: 45.38425,
      lng: -102.53897,
      city: 'Bison',
      state: 'SD',
    },
    57621: {
      lat: 45.76891,
      lng: -101.0808,
      city: 'Bullhead',
      state: 'SD',
    },
    57622: {
      lat: 44.65177,
      lng: -101.54572,
      city: 'Cherry Creek',
      state: 'SD',
    },
    57623: {
      lat: 45.01587,
      lng: -101.65925,
      city: 'Dupree',
      state: 'SD',
    },
    57625: {
      lat: 44.95738,
      lng: -101.00369,
      city: 'Eagle Butte',
      state: 'SD',
    },
    57626: {
      lat: 44.99662,
      lng: -102.17571,
      city: 'Faith',
      state: 'SD',
    },
    57630: {
      lat: 45.36358,
      lng: -100.89192,
      city: 'Glencross',
      state: 'SD',
    },
    57631: {
      lat: 45.58195,
      lng: -100.28779,
      city: 'Glenham',
      state: 'SD',
    },
    57632: {
      lat: 45.82924,
      lng: -100.04927,
      city: 'Herreid',
      state: 'SD',
    },
    57633: {
      lat: 45.44584,
      lng: -101.50046,
      city: 'Isabel',
      state: 'SD',
    },
    57634: {
      lat: 45.75231,
      lng: -101.87895,
      city: 'Keldron',
      state: 'SD',
    },
    57636: {
      lat: 45.02905,
      lng: -101.45414,
      city: 'Lantry',
      state: 'SD',
    },
    57638: {
      lat: 45.86899,
      lng: -102.20758,
      city: 'Lemmon',
      state: 'SD',
    },
    57639: {
      lat: 45.679,
      lng: -100.80324,
      city: 'Little Eagle',
      state: 'SD',
    },
    57640: {
      lat: 45.7656,
      lng: -102.70435,
      city: 'Lodgepole',
      state: 'SD',
    },
    57641: {
      lat: 45.89317,
      lng: -101.30624,
      city: 'McIntosh',
      state: 'SD',
    },
    57642: {
      lat: 45.78609,
      lng: -100.72458,
      city: 'McLaughlin',
      state: 'SD',
    },
    57644: {
      lat: 45.45155,
      lng: -102.08224,
      city: 'Meadow',
      state: 'SD',
    },
    57645: {
      lat: 45.9119,
      lng: -101.71855,
      city: 'Morristown',
      state: 'SD',
    },
    57646: {
      lat: 45.68753,
      lng: -100.05395,
      city: 'Mound City',
      state: 'SD',
    },
    57648: {
      lat: 45.92722,
      lng: -100.31755,
      city: 'Pollock',
      state: 'SD',
    },
    57649: {
      lat: 45.48999,
      lng: -102.86149,
      city: 'Prairie City',
      state: 'SD',
    },
    57650: {
      lat: 45.8346,
      lng: -103.03033,
      city: 'Ralph',
      state: 'SD',
    },
    57651: {
      lat: 45.48394,
      lng: -103.07746,
      city: 'Reva',
      state: 'SD',
    },
    57652: {
      lat: 45.13787,
      lng: -100.68883,
      city: 'Ridgeview',
      state: 'SD',
    },
    57656: {
      lat: 45.42387,
      lng: -101.10696,
      city: 'Timber Lake',
      state: 'SD',
    },
    57657: {
      lat: 45.51599,
      lng: -100.7776,
      city: 'Trail City',
      state: 'SD',
    },
    57658: {
      lat: 45.65744,
      lng: -100.55835,
      city: 'Wakpala',
      state: 'SD',
    },
    57659: {
      lat: 45.8958,
      lng: -101.09808,
      city: 'Walker',
      state: 'SD',
    },
    57660: {
      lat: 45.83052,
      lng: -101.53311,
      city: 'Watauga',
      state: 'SD',
    },
    57661: {
      lat: 45.27005,
      lng: -100.96433,
      city: 'Whitehorse',
      state: 'SD',
    },
    57701: {
      lat: 44.1209,
      lng: -103.20562,
      city: 'Rapid City',
      state: 'SD',
    },
    57702: {
      lat: 44.03085,
      lng: -103.38433,
      city: 'Rapid City',
      state: 'SD',
    },
    57703: {
      lat: 44.00331,
      lng: -103.04528,
      city: 'Rapid City',
      state: 'SD',
    },
    57706: {
      lat: 44.1564,
      lng: -103.09561,
      city: 'Ellsworth Afb',
      state: 'SD',
    },
    57714: {
      lat: 43.32689,
      lng: -101.91843,
      city: 'Allen',
      state: 'SD',
    },
    57716: {
      lat: 43.07753,
      lng: -102.2179,
      city: 'Batesland',
      state: 'SD',
    },
    57717: {
      lat: 44.96667,
      lng: -103.92396,
      city: 'Belle Fourche',
      state: 'SD',
    },
    57718: {
      lat: 44.17925,
      lng: -103.36801,
      city: 'Black Hawk',
      state: 'SD',
    },
    57719: {
      lat: 44.23059,
      lng: -102.98312,
      city: 'Box Elder',
      state: 'SD',
    },
    57720: {
      lat: 45.5497,
      lng: -103.60655,
      city: 'Buffalo',
      state: 'SD',
    },
    57722: {
      lat: 43.45722,
      lng: -102.98149,
      city: 'Buffalo Gap',
      state: 'SD',
    },
    57724: {
      lat: 45.66696,
      lng: -104.06056,
      city: 'Camp Crook',
      state: 'SD',
    },
    57725: {
      lat: 43.93723,
      lng: -102.80324,
      city: 'Caputa',
      state: 'SD',
    },
    57730: {
      lat: 43.72561,
      lng: -103.63733,
      city: 'Custer',
      state: 'SD',
    },
    57732: {
      lat: 44.23782,
      lng: -103.64335,
      city: 'Deadwood',
      state: 'SD',
    },
    57735: {
      lat: 43.29086,
      lng: -103.85626,
      city: 'Edgemont',
      state: 'SD',
    },
    57737: {
      lat: 44.55618,
      lng: -102.52096,
      city: 'Enning',
      state: 'SD',
    },
    57738: {
      lat: 43.66281,
      lng: -103.13024,
      city: 'Fairburn',
      state: 'SD',
    },
    57741: {
      lat: 44.41044,
      lng: -103.47273,
      city: 'Fort Meade',
      state: 'SD',
    },
    57744: {
      lat: 43.7773,
      lng: -103.02415,
      city: 'Hermosa',
      state: 'SD',
    },
    57745: {
      lat: 44.01304,
      lng: -103.78616,
      city: 'Hill City',
      state: 'SD',
    },
    57747: {
      lat: 43.37973,
      lng: -103.5231,
      city: 'Hot Springs',
      state: 'SD',
    },
    57748: {
      lat: 44.58484,
      lng: -102.01082,
      city: 'Howes',
      state: 'SD',
    },
    57750: {
      lat: 43.60109,
      lng: -101.94858,
      city: 'Interior',
      state: 'SD',
    },
    57751: {
      lat: 43.86767,
      lng: -103.42346,
      city: 'Keystone',
      state: 'SD',
    },
    57752: {
      lat: 43.43593,
      lng: -102.20738,
      city: 'Kyle',
      state: 'SD',
    },
    57754: {
      lat: 44.26193,
      lng: -103.87478,
      city: 'Lead',
      state: 'SD',
    },
    57755: {
      lat: 45.83763,
      lng: -103.35784,
      city: 'Ludlow',
      state: 'SD',
    },
    57756: {
      lat: 43.23729,
      lng: -102.47544,
      city: 'Manderson',
      state: 'SD',
    },
    57758: {
      lat: 44.98737,
      lng: -102.75985,
      city: 'Mud Butte',
      state: 'SD',
    },
    57759: { lat: 44.2202, lng: -103.55373, city: 'Nemo', state: 'SD' },
    57760: {
      lat: 44.93063,
      lng: -103.30329,
      city: 'Newell',
      state: 'SD',
    },
    57761: {
      lat: 44.21858,
      lng: -102.75943,
      city: 'New Underwood',
      state: 'SD',
    },
    57762: {
      lat: 44.75285,
      lng: -103.60902,
      city: 'Nisland',
      state: 'SD',
    },
    57763: {
      lat: 43.12,
      lng: -103.24944,
      city: 'Oelrichs',
      state: 'SD',
    },
    57764: {
      lat: 43.23676,
      lng: -102.75539,
      city: 'Oglala',
      state: 'SD',
    },
    57766: {
      lat: 43.35772,
      lng: -103.18252,
      city: 'Oral',
      state: 'SD',
    },
    57767: {
      lat: 44.13841,
      lng: -102.5721,
      city: 'Owanka',
      state: 'SD',
    },
    57769: {
      lat: 44.24326,
      lng: -103.29704,
      city: 'Piedmont',
      state: 'SD',
    },
    57770: {
      lat: 43.07579,
      lng: -102.6258,
      city: 'Pine Ridge',
      state: 'SD',
    },
    57772: {
      lat: 43.48182,
      lng: -102.51903,
      city: 'Porcupine',
      state: 'SD',
    },
    57773: {
      lat: 43.59443,
      lng: -103.59246,
      city: 'Pringle',
      state: 'SD',
    },
    57775: {
      lat: 44.08368,
      lng: -102.00607,
      city: 'Quinn',
      state: 'SD',
    },
    57776: {
      lat: 45.24878,
      lng: -103.51704,
      city: 'Redig',
      state: 'SD',
    },
    57779: {
      lat: 44.56449,
      lng: -103.75969,
      city: 'Saint Onge',
      state: 'SD',
    },
    57780: {
      lat: 43.7773,
      lng: -102.45844,
      city: 'Scenic',
      state: 'SD',
    },
    57782: {
      lat: 43.25182,
      lng: -103.1844,
      city: 'Smithwick',
      state: 'SD',
    },
    57783: {
      lat: 44.47106,
      lng: -103.9219,
      city: 'Spearfish',
      state: 'SD',
    },
    57785: {
      lat: 44.41905,
      lng: -103.21534,
      city: 'Sturgis',
      state: 'SD',
    },
    57787: {
      lat: 44.6643,
      lng: -102.66097,
      city: 'Union Center',
      state: 'SD',
    },
    57788: { lat: 44.61855, lng: -103.2167, city: 'Vale', state: 'SD' },
    57790: {
      lat: 44.10378,
      lng: -102.20683,
      city: 'Wall',
      state: 'SD',
    },
    57791: {
      lat: 44.24388,
      lng: -102.46514,
      city: 'Wasta',
      state: 'SD',
    },
    57792: {
      lat: 44.59559,
      lng: -102.36376,
      city: 'White Owl',
      state: 'SD',
    },
    57793: {
      lat: 44.51824,
      lng: -103.581,
      city: 'Whitewood',
      state: 'SD',
    },
    57794: {
      lat: 43.12071,
      lng: -102.40695,
      city: 'Wounded Knee',
      state: 'SD',
    },
    57799: {
      lat: 44.49817,
      lng: -103.87256,
      city: 'Spearfish',
      state: 'SD',
    },
    58001: {
      lat: 46.44837,
      lng: -96.74134,
      city: 'Abercrombie',
      state: 'ND',
    },
    58002: {
      lat: 46.97562,
      lng: -97.40785,
      city: 'Absaraka',
      state: 'ND',
    },
    58004: {
      lat: 47.02713,
      lng: -97.26212,
      city: 'Amenia',
      state: 'ND',
    },
    58005: {
      lat: 47.05261,
      lng: -96.951,
      city: 'Argusville',
      state: 'ND',
    },
    58006: {
      lat: 47.09682,
      lng: -97.20359,
      city: 'Arthur',
      state: 'ND',
    },
    58007: { lat: 47.02519, lng: -97.45724, city: 'Ayr', state: 'ND' },
    58008: {
      lat: 46.30816,
      lng: -97.00237,
      city: 'Barney',
      state: 'ND',
    },
    58009: {
      lat: 47.32658,
      lng: -97.24435,
      city: 'Blanchard',
      state: 'ND',
    },
    58011: {
      lat: 46.92711,
      lng: -97.54465,
      city: 'Buffalo',
      state: 'ND',
    },
    58012: {
      lat: 46.93639,
      lng: -97.16113,
      city: 'Casselton',
      state: 'ND',
    },
    58013: {
      lat: 46.06037,
      lng: -97.35877,
      city: 'Cayuga',
      state: 'ND',
    },
    58015: {
      lat: 46.59225,
      lng: -96.81544,
      city: 'Christine',
      state: 'ND',
    },
    58016: {
      lat: 47.36609,
      lng: -97.46293,
      city: 'Clifford',
      state: 'ND',
    },
    58017: {
      lat: 46.04157,
      lng: -97.84535,
      city: 'Cogswell',
      state: 'ND',
    },
    58018: {
      lat: 46.43458,
      lng: -96.8887,
      city: 'Colfax',
      state: 'ND',
    },
    58021: {
      lat: 46.73837,
      lng: -97.08102,
      city: 'Davenport',
      state: 'ND',
    },
    58027: {
      lat: 46.63217,
      lng: -97.61816,
      city: 'Enderlin',
      state: 'ND',
    },
    58029: { lat: 47.11726, lng: -97.39001, city: 'Erie', state: 'ND' },
    58030: {
      lat: 46.03035,
      lng: -96.66818,
      city: 'Fairmount',
      state: 'ND',
    },
    58031: {
      lat: 46.76222,
      lng: -97.6686,
      city: 'Fingal',
      state: 'ND',
    },
    58032: {
      lat: 46.07071,
      lng: -97.64413,
      city: 'Forman',
      state: 'ND',
    },
    58033: {
      lat: 46.44019,
      lng: -97.92628,
      city: 'Fort Ransom',
      state: 'ND',
    },
    58035: {
      lat: 47.25599,
      lng: -97.41589,
      city: 'Galesburg',
      state: 'ND',
    },
    58036: {
      lat: 47.15463,
      lng: -96.96318,
      city: 'Gardner',
      state: 'ND',
    },
    58038: {
      lat: 47.24505,
      lng: -96.99484,
      city: 'Grandin',
      state: 'ND',
    },
    58040: {
      lat: 46.21618,
      lng: -97.75204,
      city: 'Gwinner',
      state: 'ND',
    },
    58041: {
      lat: 46.04775,
      lng: -96.91006,
      city: 'Hankinson',
      state: 'ND',
    },
    58042: {
      lat: 46.96952,
      lng: -96.95719,
      city: 'Harwood',
      state: 'ND',
    },
    58043: {
      lat: 45.97356,
      lng: -97.53205,
      city: 'Havana',
      state: 'ND',
    },
    58045: {
      lat: 47.38241,
      lng: -97.02686,
      city: 'Hillsboro',
      state: 'ND',
    },
    58046: { lat: 47.32203, lng: -97.72747, city: 'Hope', state: 'ND' },
    58047: {
      lat: 46.7159,
      lng: -96.87946,
      city: 'Horace',
      state: 'ND',
    },
    58048: {
      lat: 47.19882,
      lng: -97.22742,
      city: 'Hunter',
      state: 'ND',
    },
    58049: {
      lat: 46.66291,
      lng: -98.00054,
      city: 'Kathryn',
      state: 'ND',
    },
    58051: {
      lat: 46.63952,
      lng: -97.0302,
      city: 'Kindred',
      state: 'ND',
    },
    58052: {
      lat: 46.63552,
      lng: -97.28011,
      city: 'Leonard',
      state: 'ND',
    },
    58053: {
      lat: 46.05058,
      lng: -97.18007,
      city: 'Lidgerwood',
      state: 'ND',
    },
    58054: {
      lat: 46.42622,
      lng: -97.66845,
      city: 'Lisbon',
      state: 'ND',
    },
    58056: {
      lat: 47.23868,
      lng: -97.93621,
      city: 'Luverne',
      state: 'ND',
    },
    58057: {
      lat: 46.42548,
      lng: -97.26747,
      city: 'Mcleod',
      state: 'ND',
    },
    58058: {
      lat: 46.1806,
      lng: -96.97159,
      city: 'Mantador',
      state: 'ND',
    },
    58059: {
      lat: 46.83825,
      lng: -97.11812,
      city: 'Mapleton',
      state: 'ND',
    },
    58060: {
      lat: 46.26745,
      lng: -97.43423,
      city: 'Milnor',
      state: 'ND',
    },
    58061: {
      lat: 46.27721,
      lng: -96.89564,
      city: 'Mooreton',
      state: 'ND',
    },
    58062: { lat: 46.64361, lng: -97.82937, city: 'Nome', state: 'ND' },
    58063: {
      lat: 46.96773,
      lng: -97.80471,
      city: 'Oriska',
      state: 'ND',
    },
    58064: { lat: 47.15036, lng: -97.62761, city: 'Page', state: 'ND' },
    58065: {
      lat: 47.20035,
      lng: -97.76612,
      city: 'Pillsbury',
      state: 'ND',
    },
    58067: {
      lat: 46.06826,
      lng: -97.48906,
      city: 'Rutland',
      state: 'ND',
    },
    58068: {
      lat: 46.54619,
      lng: -97.40896,
      city: 'Sheldon',
      state: 'ND',
    },
    58069: {
      lat: 46.24102,
      lng: -97.83194,
      city: 'Stirum',
      state: 'ND',
    },
    58071: {
      lat: 46.9436,
      lng: -97.68278,
      city: 'Tower City',
      state: 'ND',
    },
    58072: {
      lat: 46.92731,
      lng: -98.00791,
      city: 'Valley City',
      state: 'ND',
    },
    58074: {
      lat: 46.21807,
      lng: -96.87603,
      city: 'Wahpeton',
      state: 'ND',
    },
    58075: {
      lat: 46.27426,
      lng: -96.72211,
      city: 'Wahpeton',
      state: 'ND',
    },
    58076: {
      lat: 46.27416,
      lng: -96.60746,
      city: 'Wahpeton',
      state: 'ND',
    },
    58077: {
      lat: 46.52457,
      lng: -96.97593,
      city: 'Walcott',
      state: 'ND',
    },
    58078: {
      lat: 46.86276,
      lng: -96.93125,
      city: 'West Fargo',
      state: 'ND',
    },
    58079: {
      lat: 46.86816,
      lng: -97.34827,
      city: 'Wheatland',
      state: 'ND',
    },
    58081: {
      lat: 46.31392,
      lng: -97.14378,
      city: 'Wyndmere',
      state: 'ND',
    },
    58102: { lat: 46.9207, lng: -96.83175, city: 'Fargo', state: 'ND' },
    58103: {
      lat: 46.85639,
      lng: -96.82234,
      city: 'Fargo',
      state: 'ND',
    },
    58104: {
      lat: 46.79371,
      lng: -96.83838,
      city: 'Fargo',
      state: 'ND',
    },
    58105: {
      lat: 46.89586,
      lng: -96.80363,
      city: 'Fargo',
      state: 'ND',
    },
    58201: {
      lat: 47.86938,
      lng: -97.14013,
      city: 'Grand Forks',
      state: 'ND',
    },
    58202: {
      lat: 47.92224,
      lng: -97.07435,
      city: 'Grand Forks',
      state: 'ND',
    },
    58203: {
      lat: 47.97181,
      lng: -97.15425,
      city: 'Grand Forks',
      state: 'ND',
    },
    58204: {
      lat: 47.96046,
      lng: -97.37079,
      city: 'Grand Forks Afb',
      state: 'ND',
    },
    58205: {
      lat: 47.95475,
      lng: -97.39525,
      city: 'Grand Forks Afb',
      state: 'ND',
    },
    58210: { lat: 48.4081, lng: -98.11446, city: 'Adams', state: 'ND' },
    58212: { lat: 47.6987, lng: -97.99541, city: 'Aneta', state: 'ND' },
    58214: {
      lat: 47.92694,
      lng: -97.49747,
      city: 'Arvilla',
      state: 'ND',
    },
    58216: {
      lat: 48.87937,
      lng: -97.4429,
      city: 'Bathgate',
      state: 'ND',
    },
    58218: {
      lat: 47.59689,
      lng: -97.05412,
      city: 'Buxton',
      state: 'ND',
    },
    58219: {
      lat: 47.47606,
      lng: -96.87831,
      city: 'Caledonia',
      state: 'ND',
    },
    58220: {
      lat: 48.79446,
      lng: -97.73789,
      city: 'Cavalier',
      state: 'ND',
    },
    58222: {
      lat: 48.61853,
      lng: -97.68221,
      city: 'Crystal',
      state: 'ND',
    },
    58223: {
      lat: 47.51384,
      lng: -97.02573,
      city: 'Cummings',
      state: 'ND',
    },
    58224: {
      lat: 48.16777,
      lng: -97.94307,
      city: 'Dahlen',
      state: 'ND',
    },
    58225: {
      lat: 48.61208,
      lng: -97.21753,
      city: 'Drayton',
      state: 'ND',
    },
    58227: {
      lat: 48.55247,
      lng: -97.90549,
      city: 'Edinburg',
      state: 'ND',
    },
    58228: {
      lat: 47.87394,
      lng: -97.34278,
      city: 'Emerado',
      state: 'ND',
    },
    58229: {
      lat: 48.5182,
      lng: -98.25087,
      city: 'Fairdale',
      state: 'ND',
    },
    58230: {
      lat: 47.49097,
      lng: -97.82622,
      city: 'Finley',
      state: 'ND',
    },
    58231: {
      lat: 48.20208,
      lng: -97.82544,
      city: 'Fordville',
      state: 'ND',
    },
    58233: {
      lat: 48.20253,
      lng: -97.54982,
      city: 'Forest River',
      state: 'ND',
    },
    58235: {
      lat: 48.09007,
      lng: -97.48307,
      city: 'Gilby',
      state: 'ND',
    },
    58236: {
      lat: 48.71848,
      lng: -97.50479,
      city: 'Glasston',
      state: 'ND',
    },
    58237: {
      lat: 48.41522,
      lng: -97.40086,
      city: 'Grafton',
      state: 'ND',
    },
    58238: {
      lat: 48.77953,
      lng: -97.39791,
      city: 'Hamilton',
      state: 'ND',
    },
    58239: {
      lat: 48.93842,
      lng: -98.70877,
      city: 'Hannah',
      state: 'ND',
    },
    58240: {
      lat: 47.63491,
      lng: -97.47675,
      city: 'Hatton',
      state: 'ND',
    },
    58241: {
      lat: 48.70442,
      lng: -97.69772,
      city: 'Hensel',
      state: 'ND',
    },
    58243: {
      lat: 48.51423,
      lng: -97.67956,
      city: 'Hoople',
      state: 'ND',
    },
    58244: {
      lat: 48.11035,
      lng: -97.65752,
      city: 'Inkster',
      state: 'ND',
    },
    58249: {
      lat: 48.82113,
      lng: -98.32514,
      city: 'Langdon',
      state: 'ND',
    },
    58250: {
      lat: 48.27496,
      lng: -98.02897,
      city: 'Lankin',
      state: 'ND',
    },
    58251: {
      lat: 47.94945,
      lng: -97.6906,
      city: 'Larimore',
      state: 'ND',
    },
    58254: {
      lat: 47.77111,
      lng: -98.14748,
      city: 'Mcville',
      state: 'ND',
    },
    58255: {
      lat: 48.99683,
      lng: -98.35392,
      city: 'Maida',
      state: 'ND',
    },
    58256: {
      lat: 48.08739,
      lng: -97.20203,
      city: 'Manvel',
      state: 'ND',
    },
    58257: {
      lat: 47.49033,
      lng: -97.28505,
      city: 'Mayville',
      state: 'ND',
    },
    58258: {
      lat: 48.01142,
      lng: -97.33984,
      city: 'Mekinock',
      state: 'ND',
    },
    58259: {
      lat: 48.02053,
      lng: -98.12168,
      city: 'Michigan',
      state: 'ND',
    },
    58260: {
      lat: 48.63254,
      lng: -98.04709,
      city: 'Milton',
      state: 'ND',
    },
    58261: {
      lat: 48.22385,
      lng: -97.31663,
      city: 'Minto',
      state: 'ND',
    },
    58262: {
      lat: 48.681,
      lng: -97.87759,
      city: 'Mountain',
      state: 'ND',
    },
    58265: { lat: 48.95321, lng: -97.5959, city: 'Neche', state: 'ND' },
    58266: {
      lat: 48.00127,
      lng: -97.84967,
      city: 'Niagara',
      state: 'ND',
    },
    58267: {
      lat: 47.75614,
      lng: -97.62651,
      city: 'Northwood',
      state: 'ND',
    },
    58269: {
      lat: 48.70158,
      lng: -98.15518,
      city: 'Osnabrock',
      state: 'ND',
    },
    58270: {
      lat: 48.39336,
      lng: -97.78848,
      city: 'Park River',
      state: 'ND',
    },
    58271: {
      lat: 48.90905,
      lng: -97.28932,
      city: 'Pembina',
      state: 'ND',
    },
    58272: {
      lat: 48.00868,
      lng: -97.9854,
      city: 'Petersburg',
      state: 'ND',
    },
    58273: {
      lat: 48.29747,
      lng: -97.66275,
      city: 'Pisek',
      state: 'ND',
    },
    58274: {
      lat: 47.49158,
      lng: -97.48989,
      city: 'Portland',
      state: 'ND',
    },
    58275: {
      lat: 47.68956,
      lng: -97.10609,
      city: 'Reynolds',
      state: 'ND',
    },
    58276: {
      lat: 48.62239,
      lng: -97.45675,
      city: 'Saint Thomas',
      state: 'ND',
    },
    58277: {
      lat: 47.61403,
      lng: -97.83176,
      city: 'Sharon',
      state: 'ND',
    },
    58278: {
      lat: 47.77761,
      lng: -97.13084,
      city: 'Thompson',
      state: 'ND',
    },
    58281: {
      lat: 48.85941,
      lng: -98.61086,
      city: 'Wales',
      state: 'ND',
    },
    58282: {
      lat: 48.90897,
      lng: -97.93252,
      city: 'Walhalla',
      state: 'ND',
    },
    58301: {
      lat: 48.13015,
      lng: -98.88798,
      city: 'Devils Lake',
      state: 'ND',
    },
    58310: { lat: 48.57485, lng: -99.5307, city: 'Agate', state: 'ND' },
    58311: {
      lat: 48.65111,
      lng: -98.57312,
      city: 'Alsen',
      state: 'ND',
    },
    58313: {
      lat: 48.16615,
      lng: -100.03685,
      city: 'Balta',
      state: 'ND',
    },
    58316: {
      lat: 48.83532,
      lng: -99.78207,
      city: 'Belcourt',
      state: 'ND',
    },
    58317: {
      lat: 48.62576,
      lng: -99.38679,
      city: 'Bisbee',
      state: 'ND',
    },
    58318: {
      lat: 48.85121,
      lng: -100.40303,
      city: 'Bottineau',
      state: 'ND',
    },
    58321: {
      lat: 48.2025,
      lng: -98.32707,
      city: 'Brocket',
      state: 'ND',
    },
    58323: {
      lat: 48.84252,
      lng: -98.91923,
      city: 'Calvin',
      state: 'ND',
    },
    58324: {
      lat: 48.48213,
      lng: -99.21931,
      city: 'Cando',
      state: 'ND',
    },
    58325: {
      lat: 48.28613,
      lng: -99.15569,
      city: 'Churchs Ferry',
      state: 'ND',
    },
    58327: { lat: 48.10137, lng: -98.5731, city: 'Crary', state: 'ND' },
    58329: {
      lat: 48.8682,
      lng: -100.08927,
      city: 'Dunseith',
      state: 'ND',
    },
    58330: {
      lat: 48.44208,
      lng: -98.48275,
      city: 'Edmore',
      state: 'ND',
    },
    58331: {
      lat: 48.64014,
      lng: -99.09293,
      city: 'Egeland',
      state: 'ND',
    },
    58332: {
      lat: 48.04596,
      lng: -99.76725,
      city: 'Esmond',
      state: 'ND',
    },
    58335: {
      lat: 47.97229,
      lng: -99.00412,
      city: 'Fort Totten',
      state: 'ND',
    },
    58338: {
      lat: 48.52879,
      lng: -98.6313,
      city: 'Hampden',
      state: 'ND',
    },
    58339: {
      lat: 48.97175,
      lng: -99.32709,
      city: 'Hansboro',
      state: 'ND',
    },
    58341: {
      lat: 47.77117,
      lng: -99.85599,
      city: 'Harvey',
      state: 'ND',
    },
    58343: { lat: 48.29643, lng: -99.72583, city: 'Knox', state: 'ND' },
    58344: {
      lat: 48.01471,
      lng: -98.33857,
      city: 'Lakota',
      state: 'ND',
    },
    58345: {
      lat: 48.30564,
      lng: -98.37436,
      city: 'Lawton',
      state: 'ND',
    },
    58346: {
      lat: 48.28953,
      lng: -99.42218,
      city: 'Leeds',
      state: 'ND',
    },
    58348: {
      lat: 47.95509,
      lng: -99.52365,
      city: 'Maddock',
      state: 'ND',
    },
    58351: {
      lat: 48.10218,
      lng: -99.31394,
      city: 'Minnewaukan',
      state: 'ND',
    },
    58352: {
      lat: 48.70188,
      lng: -98.86099,
      city: 'Munich',
      state: 'ND',
    },
    58353: { lat: 48.64005, lng: -99.59928, city: 'Mylo', state: 'ND' },
    58355: {
      lat: 48.57504,
      lng: -98.38528,
      city: 'Nekoma',
      state: 'ND',
    },
    58356: {
      lat: 47.67103,
      lng: -99.07804,
      city: 'New Rockford',
      state: 'ND',
    },
    58357: {
      lat: 47.94429,
      lng: -99.21831,
      city: 'Oberon',
      state: 'ND',
    },
    58361: {
      lat: 47.75841,
      lng: -98.35458,
      city: 'Pekin',
      state: 'ND',
    },
    58362: { lat: 48.23099, lng: -99.0554, city: 'Penn', state: 'ND' },
    58363: {
      lat: 48.76417,
      lng: -99.46669,
      city: 'Perth',
      state: 'ND',
    },
    58365: {
      lat: 48.83655,
      lng: -99.23626,
      city: 'Rocklake',
      state: 'ND',
    },
    58366: {
      lat: 48.65242,
      lng: -99.86654,
      city: 'Rolette',
      state: 'ND',
    },
    58367: {
      lat: 48.88179,
      lng: -99.55163,
      city: 'Rolla',
      state: 'ND',
    },
    58368: {
      lat: 48.25124,
      lng: -100.04105,
      city: 'Rugby',
      state: 'ND',
    },
    58369: {
      lat: 48.95257,
      lng: -99.83603,
      city: 'Saint John',
      state: 'ND',
    },
    58370: {
      lat: 47.9842,
      lng: -98.85977,
      city: 'Saint Michael',
      state: 'ND',
    },
    58372: {
      lat: 48.93906,
      lng: -99.00628,
      city: 'Sarles',
      state: 'ND',
    },
    58374: {
      lat: 47.82865,
      lng: -99.09171,
      city: 'Sheyenne',
      state: 'ND',
    },
    58377: {
      lat: 48.46481,
      lng: -98.87416,
      city: 'Starkweather',
      state: 'ND',
    },
    58379: {
      lat: 47.90707,
      lng: -98.81907,
      city: 'Tokio',
      state: 'ND',
    },
    58380: {
      lat: 47.82872,
      lng: -98.52029,
      city: 'Tolna',
      state: 'ND',
    },
    58381: {
      lat: 47.82731,
      lng: -98.74476,
      city: 'Warwick',
      state: 'ND',
    },
    58382: {
      lat: 48.31727,
      lng: -98.79252,
      city: 'Webster',
      state: 'ND',
    },
    58384: {
      lat: 48.59401,
      lng: -100.26465,
      city: 'Willow City',
      state: 'ND',
    },
    58385: {
      lat: 48.47945,
      lng: -99.65989,
      city: 'Wolford',
      state: 'ND',
    },
    58386: { lat: 48.31696, lng: -99.59227, city: 'York', state: 'ND' },
    58401: {
      lat: 46.88145,
      lng: -98.76883,
      city: 'Jamestown',
      state: 'ND',
    },
    58402: {
      lat: 46.88191,
      lng: -98.68755,
      city: 'Jamestown',
      state: 'ND',
    },
    58405: {
      lat: 46.91454,
      lng: -98.69673,
      city: 'Jamestown',
      state: 'ND',
    },
    58413: {
      lat: 46.02963,
      lng: -99.34235,
      city: 'Ashley',
      state: 'ND',
    },
    58415: {
      lat: 46.36602,
      lng: -98.48587,
      city: 'Berlin',
      state: 'ND',
    },
    58416: {
      lat: 47.54767,
      lng: -98.35694,
      city: 'Binford',
      state: 'ND',
    },
    58418: {
      lat: 47.41493,
      lng: -99.63524,
      city: 'Bowdon',
      state: 'ND',
    },
    58420: {
      lat: 47.06072,
      lng: -98.86734,
      city: 'Buchanan',
      state: 'ND',
    },
    58421: {
      lat: 47.43974,
      lng: -99.06968,
      city: 'Carrington',
      state: 'ND',
    },
    58422: {
      lat: 47.59853,
      lng: -99.39531,
      city: 'Cathay',
      state: 'ND',
    },
    58423: {
      lat: 47.41899,
      lng: -99.82951,
      city: 'Chaseley',
      state: 'ND',
    },
    58424: {
      lat: 46.87554,
      lng: -99.10217,
      city: 'Cleveland',
      state: 'ND',
    },
    58425: {
      lat: 47.45391,
      lng: -98.12378,
      city: 'Cooperstown',
      state: 'ND',
    },
    58426: {
      lat: 47.21972,
      lng: -98.58075,
      city: 'Courtenay',
      state: 'ND',
    },
    58428: {
      lat: 46.81162,
      lng: -99.75809,
      city: 'Dawson',
      state: 'ND',
    },
    58429: {
      lat: 47.18768,
      lng: -98.16282,
      city: 'Dazey',
      state: 'ND',
    },
    58430: {
      lat: 47.50831,
      lng: -100.25954,
      city: 'Denhoff',
      state: 'ND',
    },
    58431: {
      lat: 46.50522,
      lng: -98.49658,
      city: 'Dickey',
      state: 'ND',
    },
    58433: {
      lat: 46.36989,
      lng: -98.72157,
      city: 'Edgeley',
      state: 'ND',
    },
    58436: {
      lat: 46.08203,
      lng: -98.58773,
      city: 'Ellendale',
      state: 'ND',
    },
    58438: {
      lat: 47.66247,
      lng: -99.57983,
      city: 'Fessenden',
      state: 'ND',
    },
    58439: {
      lat: 45.96738,
      lng: -98.82994,
      city: 'Forbes',
      state: 'ND',
    },
    58440: {
      lat: 46.27941,
      lng: -99.16324,
      city: 'Fredonia',
      state: 'ND',
    },
    58441: {
      lat: 46.17119,
      lng: -98.37844,
      city: 'Fullerton',
      state: 'ND',
    },
    58442: {
      lat: 46.56518,
      lng: -99.18184,
      city: 'Gackle',
      state: 'ND',
    },
    58443: {
      lat: 47.46374,
      lng: -98.67239,
      city: 'Glenfield',
      state: 'ND',
    },
    58444: {
      lat: 47.48278,
      lng: -100.13156,
      city: 'Goodrich',
      state: 'ND',
    },
    58445: {
      lat: 47.57036,
      lng: -98.78869,
      city: 'Grace City',
      state: 'ND',
    },
    58448: {
      lat: 47.30944,
      lng: -98.21451,
      city: 'Hannaford',
      state: 'ND',
    },
    58451: {
      lat: 47.45167,
      lng: -99.95275,
      city: 'Hurdsfield',
      state: 'ND',
    },
    58452: {
      lat: 47.54019,
      lng: -98.22911,
      city: 'Jessie',
      state: 'ND',
    },
    58454: { lat: 46.5978, lng: -98.94038, city: 'Jud', state: 'ND' },
    58455: {
      lat: 47.30094,
      lng: -98.72667,
      city: 'Kensal',
      state: 'ND',
    },
    58456: { lat: 46.25674, lng: -98.95475, city: 'Kulm', state: 'ND' },
    58458: {
      lat: 46.36921,
      lng: -98.29257,
      city: 'Lamoure',
      state: 'ND',
    },
    58460: { lat: 46.30991, lng: -99.31907, city: 'Lehr', state: 'ND' },
    58461: {
      lat: 46.64958,
      lng: -98.19248,
      city: 'Litchville',
      state: 'ND',
    },
    58463: {
      lat: 47.49662,
      lng: -100.46281,
      city: 'Mcclusky',
      state: 'ND',
    },
    58464: {
      lat: 47.60786,
      lng: -98.58849,
      city: 'Mchenry',
      state: 'ND',
    },
    58466: {
      lat: 46.61489,
      lng: -98.36493,
      city: 'Marion',
      state: 'ND',
    },
    58467: {
      lat: 46.8746,
      lng: -99.34103,
      city: 'Medina',
      state: 'ND',
    },
    58472: {
      lat: 46.64135,
      lng: -98.66055,
      city: 'Montpelier',
      state: 'ND',
    },
    58474: {
      lat: 46.09588,
      lng: -98.13574,
      city: 'Oakes',
      state: 'ND',
    },
    58475: {
      lat: 47.14118,
      lng: -99.53717,
      city: 'Pettibone',
      state: 'ND',
    },
    58476: {
      lat: 47.19398,
      lng: -99.00263,
      city: 'Pingree',
      state: 'ND',
    },
    58477: {
      lat: 47.2208,
      lng: -100.52294,
      city: 'Regan',
      state: 'ND',
    },
    58478: {
      lat: 47.16008,
      lng: -99.74543,
      city: 'Robinson',
      state: 'ND',
    },
    58479: {
      lat: 47.09254,
      lng: -98.19948,
      city: 'Rogers',
      state: 'ND',
    },
    58480: {
      lat: 46.94707,
      lng: -98.25685,
      city: 'Sanborn',
      state: 'ND',
    },
    58481: {
      lat: 46.9346,
      lng: -98.42814,
      city: 'Spiritwood',
      state: 'ND',
    },
    58482: {
      lat: 46.86669,
      lng: -99.90465,
      city: 'Steele',
      state: 'ND',
    },
    58483: {
      lat: 46.66176,
      lng: -99.42186,
      city: 'Streeter',
      state: 'ND',
    },
    58484: {
      lat: 47.37821,
      lng: -98.4687,
      city: 'Sutton',
      state: 'ND',
    },
    58486: {
      lat: 47.39421,
      lng: -99.39279,
      city: 'Sykeston',
      state: 'ND',
    },
    58487: {
      lat: 46.85278,
      lng: -99.60283,
      city: 'Tappen',
      state: 'ND',
    },
    58488: {
      lat: 47.17552,
      lng: -99.99709,
      city: 'Tuttle',
      state: 'ND',
    },
    58490: {
      lat: 46.38137,
      lng: -98.06063,
      city: 'Verona',
      state: 'ND',
    },
    58492: {
      lat: 47.15793,
      lng: -98.43787,
      city: 'Wimbledon',
      state: 'ND',
    },
    58494: { lat: 47.16306, lng: -100.2744, city: 'Wing', state: 'ND' },
    58495: {
      lat: 46.27202,
      lng: -99.60077,
      city: 'Wishek',
      state: 'ND',
    },
    58496: {
      lat: 47.14063,
      lng: -99.30794,
      city: 'Woodworth',
      state: 'ND',
    },
    58497: {
      lat: 46.76738,
      lng: -98.54076,
      city: 'Ypsilanti',
      state: 'ND',
    },
    58501: {
      lat: 46.8189,
      lng: -100.70234,
      city: 'Bismarck',
      state: 'ND',
    },
    58503: {
      lat: 46.90587,
      lng: -100.75262,
      city: 'Bismarck',
      state: 'ND',
    },
    58504: {
      lat: 46.7273,
      lng: -100.6808,
      city: 'Bismarck',
      state: 'ND',
    },
    58505: {
      lat: 46.82035,
      lng: -100.78106,
      city: 'Bismarck',
      state: 'ND',
    },
    58520: {
      lat: 46.6758,
      lng: -101.5409,
      city: 'Almont',
      state: 'ND',
    },
    58521: {
      lat: 47.0269,
      lng: -100.66963,
      city: 'Baldwin',
      state: 'ND',
    },
    58523: {
      lat: 47.26581,
      lng: -101.78138,
      city: 'Beulah',
      state: 'ND',
    },
    58524: {
      lat: 46.61297,
      lng: -100.07506,
      city: 'Braddock',
      state: 'ND',
    },
    58528: {
      lat: 46.34174,
      lng: -100.62751,
      city: 'Cannon Ball',
      state: 'ND',
    },
    58529: {
      lat: 46.30116,
      lng: -101.56952,
      city: 'Carson',
      state: 'ND',
    },
    58530: {
      lat: 47.13357,
      lng: -101.15257,
      city: 'Center',
      state: 'ND',
    },
    58531: {
      lat: 47.58387,
      lng: -101.20289,
      city: 'Coleharbor',
      state: 'ND',
    },
    58532: {
      lat: 46.84571,
      lng: -100.10631,
      city: 'Driscoll',
      state: 'ND',
    },
    58533: {
      lat: 46.39433,
      lng: -101.79598,
      city: 'Elgin',
      state: 'ND',
    },
    58535: {
      lat: 46.48724,
      lng: -101.24558,
      city: 'Flasher',
      state: 'ND',
    },
    58538: {
      lat: 46.08833,
      lng: -100.66983,
      city: 'Fort Yates',
      state: 'ND',
    },
    58540: {
      lat: 47.62427,
      lng: -101.72538,
      city: 'Garrison',
      state: 'ND',
    },
    58541: {
      lat: 47.33929,
      lng: -102.05177,
      city: 'Golden Valley',
      state: 'ND',
    },
    58542: {
      lat: 46.04976,
      lng: -100.00269,
      city: 'Hague',
      state: 'ND',
    },
    58544: {
      lat: 46.49833,
      lng: -100.37062,
      city: 'Hazelton',
      state: 'ND',
    },
    58545: {
      lat: 47.34916,
      lng: -101.58921,
      city: 'Hazen',
      state: 'ND',
    },
    58549: {
      lat: 46.46853,
      lng: -99.93305,
      city: 'Kintyre',
      state: 'ND',
    },
    58552: {
      lat: 46.26042,
      lng: -100.29955,
      city: 'Linton',
      state: 'ND',
    },
    58554: {
      lat: 46.75148,
      lng: -100.95036,
      city: 'Mandan',
      state: 'ND',
    },
    58558: {
      lat: 46.82106,
      lng: -100.52127,
      city: 'Menoken',
      state: 'ND',
    },
    58559: {
      lat: 47.4849,
      lng: -100.71295,
      city: 'Mercer',
      state: 'ND',
    },
    58560: {
      lat: 46.65182,
      lng: -100.32407,
      city: 'Moffit',
      state: 'ND',
    },
    58561: {
      lat: 46.49706,
      lng: -99.68118,
      city: 'Napoleon',
      state: 'ND',
    },
    58562: {
      lat: 46.36354,
      lng: -102.01095,
      city: 'New Leipzig',
      state: 'ND',
    },
    58563: {
      lat: 46.90178,
      lng: -101.43257,
      city: 'New Salem',
      state: 'ND',
    },
    58564: {
      lat: 46.26252,
      lng: -101.35,
      city: 'Raleigh',
      state: 'ND',
    },
    58565: {
      lat: 47.49639,
      lng: -101.37148,
      city: 'Riverdale',
      state: 'ND',
    },
    58566: {
      lat: 46.57835,
      lng: -100.90673,
      city: 'Saint Anthony',
      state: 'ND',
    },
    58568: {
      lat: 46.09274,
      lng: -101.01538,
      city: 'Selfridge',
      state: 'ND',
    },
    58569: {
      lat: 46.25777,
      lng: -101.19276,
      city: 'Shields',
      state: 'ND',
    },
    58570: {
      lat: 46.37243,
      lng: -100.87237,
      city: 'Solen',
      state: 'ND',
    },
    58571: {
      lat: 47.29134,
      lng: -101.41043,
      city: 'Stanton',
      state: 'ND',
    },
    58572: {
      lat: 46.86455,
      lng: -100.34313,
      city: 'Sterling',
      state: 'ND',
    },
    58573: {
      lat: 46.12542,
      lng: -100.20709,
      city: 'Strasburg',
      state: 'ND',
    },
    58575: {
      lat: 47.55749,
      lng: -100.89971,
      city: 'Turtle Lake',
      state: 'ND',
    },
    58576: {
      lat: 47.4406,
      lng: -101.21597,
      city: 'Underwood',
      state: 'ND',
    },
    58577: {
      lat: 47.31983,
      lng: -101.05319,
      city: 'Washburn',
      state: 'ND',
    },
    58579: {
      lat: 47.18059,
      lng: -100.74422,
      city: 'Wilton',
      state: 'ND',
    },
    58580: { lat: 47.2665, lng: -101.93301, city: 'Zap', state: 'ND' },
    58581: {
      lat: 46.04646,
      lng: -99.7983,
      city: 'Zeeland',
      state: 'ND',
    },
    58601: {
      lat: 46.90964,
      lng: -102.82766,
      city: 'Dickinson',
      state: 'ND',
    },
    58620: {
      lat: 46.49732,
      lng: -103.3551,
      city: 'Amidon',
      state: 'ND',
    },
    58621: {
      lat: 47.09771,
      lng: -103.92652,
      city: 'Beach',
      state: 'ND',
    },
    58622: {
      lat: 46.88035,
      lng: -103.24434,
      city: 'Belfield',
      state: 'ND',
    },
    58623: {
      lat: 46.16001,
      lng: -103.42168,
      city: 'Bowman',
      state: 'ND',
    },
    58625: {
      lat: 47.28146,
      lng: -102.19748,
      city: 'Dodge',
      state: 'ND',
    },
    58626: {
      lat: 47.37739,
      lng: -102.60575,
      city: 'Dunn Center',
      state: 'ND',
    },
    58627: {
      lat: 47.23532,
      lng: -103.29713,
      city: 'Fairfield',
      state: 'ND',
    },
    58630: {
      lat: 46.91981,
      lng: -102.57603,
      city: 'Gladstone',
      state: 'ND',
    },
    58631: {
      lat: 46.7969,
      lng: -101.82156,
      city: 'Glen Ullin',
      state: 'ND',
    },
    58632: {
      lat: 46.68316,
      lng: -103.95078,
      city: 'Golva',
      state: 'ND',
    },
    58634: {
      lat: 47.43683,
      lng: -103.35647,
      city: 'Grassy Butte',
      state: 'ND',
    },
    58636: {
      lat: 47.38924,
      lng: -102.35351,
      city: 'Halliday',
      state: 'ND',
    },
    58638: {
      lat: 46.92201,
      lng: -102.04867,
      city: 'Hebron',
      state: 'ND',
    },
    58639: {
      lat: 46.04056,
      lng: -102.63905,
      city: 'Hettinger',
      state: 'ND',
    },
    58640: {
      lat: 47.44409,
      lng: -102.89681,
      city: 'Killdeer',
      state: 'ND',
    },
    58641: {
      lat: 46.66732,
      lng: -102.48551,
      city: 'Lefor',
      state: 'ND',
    },
    58642: {
      lat: 47.20145,
      lng: -102.83667,
      city: 'Manning',
      state: 'ND',
    },
    58643: {
      lat: 46.22974,
      lng: -103.95091,
      city: 'Marmarth',
      state: 'ND',
    },
    58644: {
      lat: 47.14095,
      lng: -102.35867,
      city: 'Marshall',
      state: 'ND',
    },
    58645: {
      lat: 46.95845,
      lng: -103.52945,
      city: 'Medora',
      state: 'ND',
    },
    58646: {
      lat: 46.39826,
      lng: -102.28782,
      city: 'Mott',
      state: 'ND',
    },
    58647: {
      lat: 46.51695,
      lng: -102.93466,
      city: 'New England',
      state: 'ND',
    },
    58649: {
      lat: 46.13762,
      lng: -102.90857,
      city: 'Reeder',
      state: 'ND',
    },
    58650: {
      lat: 46.43241,
      lng: -102.59033,
      city: 'Regent',
      state: 'ND',
    },
    58651: {
      lat: 46.26201,
      lng: -103.74228,
      city: 'Rhame',
      state: 'ND',
    },
    58652: {
      lat: 46.88854,
      lng: -102.27037,
      city: 'Richardton',
      state: 'ND',
    },
    58653: {
      lat: 46.09645,
      lng: -103.12946,
      city: 'Scranton',
      state: 'ND',
    },
    58654: {
      lat: 46.77817,
      lng: -103.81757,
      city: 'Sentinel Butte',
      state: 'ND',
    },
    58655: {
      lat: 46.77921,
      lng: -103.03163,
      city: 'South Heart',
      state: 'ND',
    },
    58656: {
      lat: 46.95207,
      lng: -102.46801,
      city: 'Taylor',
      state: 'ND',
    },
    58701: {
      lat: 48.14027,
      lng: -101.32406,
      city: 'Minot',
      state: 'ND',
    },
    58703: {
      lat: 48.32646,
      lng: -101.31637,
      city: 'Minot',
      state: 'ND',
    },
    58704: {
      lat: 48.4232,
      lng: -101.33152,
      city: 'Minot Afb',
      state: 'ND',
    },
    58705: {
      lat: 48.41608,
      lng: -101.35182,
      city: 'Minot Afb',
      state: 'ND',
    },
    58707: {
      lat: 48.24767,
      lng: -101.30127,
      city: 'Minot',
      state: 'ND',
    },
    58710: {
      lat: 47.86959,
      lng: -100.23912,
      city: 'Anamoose',
      state: 'ND',
    },
    58711: {
      lat: 48.93262,
      lng: -101.2752,
      city: 'Antler',
      state: 'ND',
    },
    58712: {
      lat: 47.96799,
      lng: -100.54559,
      city: 'Balfour',
      state: 'ND',
    },
    58713: {
      lat: 48.51876,
      lng: -100.60497,
      city: 'Bantry',
      state: 'ND',
    },
    58716: {
      lat: 47.80049,
      lng: -101.06003,
      city: 'Benedict',
      state: 'ND',
    },
    58718: {
      lat: 48.31128,
      lng: -101.82989,
      city: 'Berthold',
      state: 'ND',
    },
    58721: {
      lat: 48.82069,
      lng: -102.25667,
      city: 'Bowbells',
      state: 'ND',
    },
    58722: {
      lat: 48.25238,
      lng: -101.50079,
      city: 'Burlington',
      state: 'ND',
    },
    58723: {
      lat: 47.75433,
      lng: -100.60281,
      city: 'Butte',
      state: 'ND',
    },
    58725: {
      lat: 48.48699,
      lng: -101.68361,
      city: 'Carpio',
      state: 'ND',
    },
    58727: {
      lat: 48.85542,
      lng: -102.80479,
      city: 'Columbus',
      state: 'ND',
    },
    58730: {
      lat: 48.86171,
      lng: -103.30652,
      city: 'Crosby',
      state: 'ND',
    },
    58731: {
      lat: 48.44752,
      lng: -100.98029,
      city: 'Deering',
      state: 'ND',
    },
    58733: {
      lat: 48.16911,
      lng: -101.59463,
      city: 'Des Lacs',
      state: 'ND',
    },
    58734: {
      lat: 48.51508,
      lng: -101.95757,
      city: 'Donnybrook',
      state: 'ND',
    },
    58735: {
      lat: 47.87771,
      lng: -101.48366,
      city: 'Douglas',
      state: 'ND',
    },
    58736: {
      lat: 47.92804,
      lng: -100.38732,
      city: 'Drake',
      state: 'ND',
    },
    58737: {
      lat: 48.90743,
      lng: -102.39392,
      city: 'Flaxton',
      state: 'ND',
    },
    58740: {
      lat: 48.4711,
      lng: -101.22744,
      city: 'Glenburn',
      state: 'ND',
    },
    58741: {
      lat: 48.27336,
      lng: -100.83352,
      city: 'Granville',
      state: 'ND',
    },
    58744: {
      lat: 48.12073,
      lng: -100.58774,
      city: 'Karlsruhe',
      state: 'ND',
    },
    58746: {
      lat: 48.71921,
      lng: -102.10115,
      city: 'Kenmare',
      state: 'ND',
    },
    58748: {
      lat: 48.66895,
      lng: -100.64487,
      city: 'Kramer',
      state: 'ND',
    },
    58750: {
      lat: 48.6166,
      lng: -101.39941,
      city: 'Lansford',
      state: 'ND',
    },
    58752: {
      lat: 48.82146,
      lng: -102.59927,
      city: 'Lignite',
      state: 'ND',
    },
    58755: {
      lat: 48.62857,
      lng: -102.94666,
      city: 'Mcgregor',
      state: 'ND',
    },
    58756: {
      lat: 47.96746,
      lng: -101.81431,
      city: 'Makoti',
      state: 'ND',
    },
    58757: {
      lat: 47.67643,
      lng: -102.54685,
      city: 'Mandaree',
      state: 'ND',
    },
    58758: {
      lat: 47.76643,
      lng: -100.10424,
      city: 'Martin',
      state: 'ND',
    },
    58759: { lat: 47.84776, lng: -101.25026, city: 'Max', state: 'ND' },
    58760: {
      lat: 48.69854,
      lng: -101.14158,
      city: 'Maxbass',
      state: 'ND',
    },
    58761: {
      lat: 48.78736,
      lng: -101.53019,
      city: 'Mohall',
      state: 'ND',
    },
    58762: {
      lat: 48.67409,
      lng: -100.94225,
      city: 'Newburg',
      state: 'ND',
    },
    58763: {
      lat: 47.98362,
      lng: -102.58401,
      city: 'New Town',
      state: 'ND',
    },
    58765: {
      lat: 48.87261,
      lng: -103.03805,
      city: 'Noonan',
      state: 'ND',
    },
    58768: {
      lat: 48.25115,
      lng: -101.01456,
      city: 'Norwich',
      state: 'ND',
    },
    58769: {
      lat: 48.30082,
      lng: -102.22721,
      city: 'Palermo',
      state: 'ND',
    },
    58770: {
      lat: 47.86531,
      lng: -102.16251,
      city: 'Parshall',
      state: 'ND',
    },
    58771: {
      lat: 48.08711,
      lng: -101.98534,
      city: 'Plaza',
      state: 'ND',
    },
    58772: {
      lat: 48.96035,
      lng: -102.61236,
      city: 'Portal',
      state: 'ND',
    },
    58773: {
      lat: 48.58631,
      lng: -102.66852,
      city: 'Powers Lake',
      state: 'ND',
    },
    58775: {
      lat: 47.71424,
      lng: -101.85203,
      city: 'Roseglen',
      state: 'ND',
    },
    58776: {
      lat: 48.25079,
      lng: -102.61543,
      city: 'Ross',
      state: 'ND',
    },
    58778: {
      lat: 47.78307,
      lng: -100.91268,
      city: 'Ruso',
      state: 'ND',
    },
    58779: {
      lat: 47.85507,
      lng: -101.7621,
      city: 'Ryder',
      state: 'ND',
    },
    58781: {
      lat: 48.02451,
      lng: -101.13468,
      city: 'Sawyer',
      state: 'ND',
    },
    58782: {
      lat: 48.94055,
      lng: -101.71325,
      city: 'Sherwood',
      state: 'ND',
    },
    58783: {
      lat: 48.88375,
      lng: -100.75035,
      city: 'Souris',
      state: 'ND',
    },
    58784: {
      lat: 48.36212,
      lng: -102.42273,
      city: 'Stanley',
      state: 'ND',
    },
    58785: {
      lat: 48.31121,
      lng: -101.09706,
      city: 'Surrey',
      state: 'ND',
    },
    58787: {
      lat: 48.76057,
      lng: -101.80832,
      city: 'Tolley',
      state: 'ND',
    },
    58788: {
      lat: 48.33198,
      lng: -100.48045,
      city: 'Towner',
      state: 'ND',
    },
    58789: {
      lat: 48.54778,
      lng: -100.81898,
      city: 'Upham',
      state: 'ND',
    },
    58790: {
      lat: 48.03071,
      lng: -100.93952,
      city: 'Velva',
      state: 'ND',
    },
    58792: {
      lat: 47.97385,
      lng: -100.7424,
      city: 'Voltaire',
      state: 'ND',
    },
    58793: {
      lat: 48.86442,
      lng: -101.05424,
      city: 'Westhope',
      state: 'ND',
    },
    58794: {
      lat: 48.25916,
      lng: -102.76683,
      city: 'White Earth',
      state: 'ND',
    },
    58795: {
      lat: 48.64198,
      lng: -103.15094,
      city: 'Wildrose',
      state: 'ND',
    },
    58801: {
      lat: 48.23522,
      lng: -103.76747,
      city: 'Williston',
      state: 'ND',
    },
    58830: {
      lat: 48.60272,
      lng: -103.46608,
      city: 'Alamo',
      state: 'ND',
    },
    58831: {
      lat: 47.84878,
      lng: -103.65843,
      city: 'Alexander',
      state: 'ND',
    },
    58833: {
      lat: 48.89186,
      lng: -103.52517,
      city: 'Ambrose',
      state: 'ND',
    },
    58835: {
      lat: 47.7328,
      lng: -103.45268,
      city: 'Arnegard',
      state: 'ND',
    },
    58838: {
      lat: 47.7721,
      lng: -103.90289,
      city: 'Cartwright',
      state: 'ND',
    },
    58843: {
      lat: 48.23141,
      lng: -103.38142,
      city: 'Epping',
      state: 'ND',
    },
    58844: {
      lat: 48.89167,
      lng: -103.74304,
      city: 'Fortuna',
      state: 'ND',
    },
    58845: {
      lat: 48.63928,
      lng: -103.92207,
      city: 'Grenora',
      state: 'ND',
    },
    58847: {
      lat: 47.89012,
      lng: -102.90094,
      city: 'Keene',
      state: 'ND',
    },
    58849: { lat: 48.30906, lng: -103.15697, city: 'Ray', state: 'ND' },
    58852: {
      lat: 48.38037,
      lng: -102.93276,
      city: 'Tioga',
      state: 'ND',
    },
    58853: {
      lat: 48.06237,
      lng: -103.78588,
      city: 'Trenton',
      state: 'ND',
    },
    58854: {
      lat: 47.81412,
      lng: -103.15229,
      city: 'Watford City',
      state: 'ND',
    },
    58856: {
      lat: 48.59123,
      lng: -103.72546,
      city: 'Zahl',
      state: 'ND',
    },
    59001: {
      lat: 45.52952,
      lng: -109.54022,
      city: 'Absarokee',
      state: 'MT',
    },
    59002: {
      lat: 45.99832,
      lng: -108.6649,
      city: 'Acton',
      state: 'MT',
    },
    59003: {
      lat: 45.48179,
      lng: -106.20153,
      city: 'Ashland',
      state: 'MT',
    },
    59006: {
      lat: 45.90755,
      lng: -108.08535,
      city: 'Ballantine',
      state: 'MT',
    },
    59007: {
      lat: 45.15555,
      lng: -109.15805,
      city: 'Bearcreek',
      state: 'MT',
    },
    59008: {
      lat: 45.07982,
      lng: -109.07496,
      city: 'Belfry',
      state: 'MT',
    },
    59010: {
      lat: 45.97505,
      lng: -107.3037,
      city: 'Bighorn',
      state: 'MT',
    },
    59011: {
      lat: 45.92453,
      lng: -109.97244,
      city: 'Big Timber',
      state: 'MT',
    },
    59012: {
      lat: 45.29922,
      lng: -106.6006,
      city: 'Birney',
      state: 'MT',
    },
    59013: {
      lat: 45.46131,
      lng: -109.08054,
      city: 'Boyd',
      state: 'MT',
    },
    59014: {
      lat: 45.15351,
      lng: -108.66421,
      city: 'Bridger',
      state: 'MT',
    },
    59015: {
      lat: 46.08464,
      lng: -108.83464,
      city: 'Broadview',
      state: 'MT',
    },
    59016: {
      lat: 45.41871,
      lng: -106.93872,
      city: 'Busby',
      state: 'MT',
    },
    59018: {
      lat: 45.85791,
      lng: -110.62326,
      city: 'Clyde Park',
      state: 'MT',
    },
    59019: {
      lat: 45.62995,
      lng: -109.24208,
      city: 'Columbus',
      state: 'MT',
    },
    59020: {
      lat: 45.08831,
      lng: -110.01499,
      city: 'Cooke City',
      state: 'MT',
    },
    59022: {
      lat: 45.57541,
      lng: -107.26756,
      city: 'Crow Agency',
      state: 'MT',
    },
    59024: {
      lat: 46.20549,
      lng: -107.71464,
      city: 'Custer',
      state: 'MT',
    },
    59025: {
      lat: 45.09503,
      lng: -106.7178,
      city: 'Decker',
      state: 'MT',
    },
    59026: {
      lat: 45.46385,
      lng: -108.78392,
      city: 'Edgar',
      state: 'MT',
    },
    59027: {
      lat: 45.26118,
      lng: -110.90674,
      city: 'Emigrant',
      state: 'MT',
    },
    59028: {
      lat: 45.32093,
      lng: -109.7434,
      city: 'Fishtail',
      state: 'MT',
    },
    59029: {
      lat: 45.4085,
      lng: -108.80467,
      city: 'Fromberg',
      state: 'MT',
    },
    59030: {
      lat: 45.08882,
      lng: -110.59376,
      city: 'Gardiner',
      state: 'MT',
    },
    59031: {
      lat: 45.50005,
      lng: -107.37964,
      city: 'Garryowen',
      state: 'MT',
    },
    59032: {
      lat: 47.0106,
      lng: -108.75808,
      city: 'Grass Range',
      state: 'MT',
    },
    59033: {
      lat: 45.72296,
      lng: -109.73246,
      city: 'Greycliff',
      state: 'MT',
    },
    59034: {
      lat: 45.70121,
      lng: -107.7078,
      city: 'Hardin',
      state: 'MT',
    },
    59035: {
      lat: 45.29814,
      lng: -107.96042,
      city: 'Fort Smith',
      state: 'MT',
    },
    59036: {
      lat: 46.46606,
      lng: -109.89035,
      city: 'Harlowton',
      state: 'MT',
    },
    59037: {
      lat: 45.852,
      lng: -108.20927,
      city: 'Huntley',
      state: 'MT',
    },
    59038: {
      lat: 46.18917,
      lng: -107.23069,
      city: 'Hysham',
      state: 'MT',
    },
    59039: {
      lat: 46.68533,
      lng: -107.28458,
      city: 'Ingomar',
      state: 'MT',
    },
    59041: {
      lat: 45.50239,
      lng: -108.9663,
      city: 'Joliet',
      state: 'MT',
    },
    59043: {
      lat: 45.53266,
      lng: -106.6568,
      city: 'Lame Deer',
      state: 'MT',
    },
    59044: {
      lat: 45.64251,
      lng: -108.74975,
      city: 'Laurel',
      state: 'MT',
    },
    59046: {
      lat: 46.39147,
      lng: -108.98604,
      city: 'Lavina',
      state: 'MT',
    },
    59047: {
      lat: 45.56852,
      lng: -110.55174,
      city: 'Livingston',
      state: 'MT',
    },
    59050: {
      lat: 45.25013,
      lng: -107.47849,
      city: 'Lodge Grass',
      state: 'MT',
    },
    59052: {
      lat: 45.43651,
      lng: -110.18676,
      city: 'McLeod',
      state: 'MT',
    },
    59053: {
      lat: 46.45713,
      lng: -110.43492,
      city: 'Martinsdale',
      state: 'MT',
    },
    59054: {
      lat: 46.66809,
      lng: -107.7691,
      city: 'Melstone',
      state: 'MT',
    },
    59055: {
      lat: 46.10282,
      lng: -109.78662,
      city: 'Melville',
      state: 'MT',
    },
    59057: {
      lat: 45.85358,
      lng: -108.95377,
      city: 'Molt',
      state: 'MT',
    },
    59058: {
      lat: 46.98517,
      lng: -107.79652,
      city: 'Mosby',
      state: 'MT',
    },
    59059: {
      lat: 46.51161,
      lng: -108.088,
      city: 'Musselshell',
      state: 'MT',
    },
    59061: { lat: 45.39634, lng: -109.87728, city: 'Nye', state: 'MT' },
    59062: {
      lat: 45.15773,
      lng: -106.20895,
      city: 'Otter',
      state: 'MT',
    },
    59063: {
      lat: 45.65079,
      lng: -108.97028,
      city: 'Park City',
      state: 'MT',
    },
    59064: {
      lat: 45.88011,
      lng: -107.87245,
      city: 'Pompeys Pillar',
      state: 'MT',
    },
    59065: { lat: 45.26883, lng: -110.6784, city: 'Pray', state: 'MT' },
    59066: {
      lat: 45.33907,
      lng: -108.49718,
      city: 'Pryor',
      state: 'MT',
    },
    59067: {
      lat: 45.96827,
      lng: -109.23433,
      city: 'Rapelje',
      state: 'MT',
    },
    59068: {
      lat: 45.15929,
      lng: -109.45626,
      city: 'Red Lodge',
      state: 'MT',
    },
    59069: {
      lat: 45.72204,
      lng: -109.57945,
      city: 'Reed Point',
      state: 'MT',
    },
    59070: {
      lat: 45.36289,
      lng: -109.17961,
      city: 'Roberts',
      state: 'MT',
    },
    59071: {
      lat: 45.26582,
      lng: -109.57003,
      city: 'Roscoe',
      state: 'MT',
    },
    59072: {
      lat: 46.51839,
      lng: -108.54969,
      city: 'Roundup',
      state: 'MT',
    },
    59074: {
      lat: 46.36062,
      lng: -109.30181,
      city: 'Ryegate',
      state: 'MT',
    },
    59075: {
      lat: 45.40457,
      lng: -107.85051,
      city: 'Saint Xavier',
      state: 'MT',
    },
    59076: {
      lat: 46.28252,
      lng: -107.06847,
      city: 'Sanders',
      state: 'MT',
    },
    59077: {
      lat: 47.22224,
      lng: -107.58999,
      city: 'Sand Springs',
      state: 'MT',
    },
    59078: {
      lat: 46.32585,
      lng: -109.5442,
      city: 'Shawmut',
      state: 'MT',
    },
    59079: {
      lat: 46.0607,
      lng: -108.36718,
      city: 'Shepherd',
      state: 'MT',
    },
    59081: {
      lat: 45.01979,
      lng: -109.98295,
      city: 'Silver Gate',
      state: 'MT',
    },
    59082: {
      lat: 45.84041,
      lng: -110.29227,
      city: 'Springdale',
      state: 'MT',
    },
    59083: {
      lat: 46.55133,
      lng: -107.56312,
      city: 'Sumatra',
      state: 'MT',
    },
    59085: {
      lat: 46.36172,
      lng: -110.14019,
      city: 'Two Dot',
      state: 'MT',
    },
    59086: {
      lat: 46.05608,
      lng: -110.65293,
      city: 'Wilsall',
      state: 'MT',
    },
    59087: {
      lat: 47.16806,
      lng: -108.22779,
      city: 'Winnett',
      state: 'MT',
    },
    59088: {
      lat: 46.13006,
      lng: -108.02933,
      city: 'Worden',
      state: 'MT',
    },
    59089: {
      lat: 45.09259,
      lng: -107.34476,
      city: 'Wyola',
      state: 'MT',
    },
    59101: {
      lat: 45.63013,
      lng: -108.36445,
      city: 'Billings',
      state: 'MT',
    },
    59102: {
      lat: 45.77479,
      lng: -108.58133,
      city: 'Billings',
      state: 'MT',
    },
    59105: {
      lat: 45.87708,
      lng: -108.50145,
      city: 'Billings',
      state: 'MT',
    },
    59106: {
      lat: 45.80637,
      lng: -108.6948,
      city: 'Billings',
      state: 'MT',
    },
    59201: {
      lat: 48.15981,
      lng: -105.64647,
      city: 'Wolf Point',
      state: 'MT',
    },
    59211: {
      lat: 48.69582,
      lng: -104.35493,
      city: 'Antelope',
      state: 'MT',
    },
    59212: {
      lat: 48.19231,
      lng: -104.16968,
      city: 'Bainville',
      state: 'MT',
    },
    59213: {
      lat: 48.17553,
      lng: -104.85612,
      city: 'Brockton',
      state: 'MT',
    },
    59214: {
      lat: 47.28325,
      lng: -106.02221,
      city: 'Brockway',
      state: 'MT',
    },
    59215: {
      lat: 47.50431,
      lng: -105.74543,
      city: 'Circle',
      state: 'MT',
    },
    59217: {
      lat: 47.58449,
      lng: -104.24455,
      city: 'Crane',
      state: 'MT',
    },
    59218: {
      lat: 48.09993,
      lng: -104.52361,
      city: 'Culbertson',
      state: 'MT',
    },
    59219: {
      lat: 48.5579,
      lng: -104.15541,
      city: 'Dagmar',
      state: 'MT',
    },
    59221: {
      lat: 47.91952,
      lng: -104.1816,
      city: 'Fairview',
      state: 'MT',
    },
    59222: {
      lat: 48.7007,
      lng: -105.1557,
      city: 'Flaxville',
      state: 'MT',
    },
    59223: {
      lat: 47.90319,
      lng: -106.19348,
      city: 'Fort Peck',
      state: 'MT',
    },
    59225: {
      lat: 48.27723,
      lng: -105.97413,
      city: 'Frazer',
      state: 'MT',
    },
    59226: {
      lat: 48.34283,
      lng: -104.51545,
      city: 'Froid',
      state: 'MT',
    },
    59230: {
      lat: 48.09992,
      lng: -106.80196,
      city: 'Glasgow',
      state: 'MT',
    },
    59231: {
      lat: 48.40395,
      lng: -106.54411,
      city: 'Saint Marie',
      state: 'MT',
    },
    59240: {
      lat: 48.84222,
      lng: -106.24046,
      city: 'Glentana',
      state: 'MT',
    },
    59241: {
      lat: 48.46125,
      lng: -107.04602,
      city: 'Hinsdale',
      state: 'MT',
    },
    59242: {
      lat: 48.4387,
      lng: -104.55787,
      city: 'Homestead',
      state: 'MT',
    },
    59243: {
      lat: 47.76062,
      lng: -104.67387,
      city: 'Lambert',
      state: 'MT',
    },
    59244: {
      lat: 48.62832,
      lng: -106.30945,
      city: 'Larslan',
      state: 'MT',
    },
    59247: {
      lat: 48.51848,
      lng: -104.52209,
      city: 'Medicine Lake',
      state: 'MT',
    },
    59248: {
      lat: 48.27555,
      lng: -106.25841,
      city: 'Nashua',
      state: 'MT',
    },
    59250: {
      lat: 48.86715,
      lng: -106.63905,
      city: 'Opheim',
      state: 'MT',
    },
    59252: {
      lat: 48.92728,
      lng: -104.82152,
      city: 'Outlook',
      state: 'MT',
    },
    59253: {
      lat: 48.70422,
      lng: -105.83496,
      city: 'Peerless',
      state: 'MT',
    },
    59254: {
      lat: 48.75753,
      lng: -104.61123,
      city: 'Plentywood',
      state: 'MT',
    },
    59255: {
      lat: 48.23115,
      lng: -105.16825,
      city: 'Poplar',
      state: 'MT',
    },
    59256: {
      lat: 48.91919,
      lng: -104.54965,
      city: 'Raymond',
      state: 'MT',
    },
    59257: {
      lat: 48.75364,
      lng: -104.94239,
      city: 'Redstone',
      state: 'MT',
    },
    59258: {
      lat: 48.57025,
      lng: -104.735,
      city: 'Reserve',
      state: 'MT',
    },
    59259: {
      lat: 47.69109,
      lng: -105.08416,
      city: 'Richey',
      state: 'MT',
    },
    59260: {
      lat: 48.84027,
      lng: -106.10217,
      city: 'Richland',
      state: 'MT',
    },
    59261: { lat: 48.53327, lng: -107.3599, city: 'Saco', state: 'MT' },
    59262: {
      lat: 47.49341,
      lng: -104.50518,
      city: 'Savage',
      state: 'MT',
    },
    59263: {
      lat: 48.78921,
      lng: -105.51838,
      city: 'Scobey',
      state: 'MT',
    },
    59270: {
      lat: 47.57233,
      lng: -104.08264,
      city: 'Sidney',
      state: 'MT',
    },
    59273: {
      lat: 48.29491,
      lng: -106.93589,
      city: 'Vandalia',
      state: 'MT',
    },
    59274: {
      lat: 47.83944,
      lng: -105.42204,
      city: 'Vida',
      state: 'MT',
    },
    59275: {
      lat: 48.87415,
      lng: -104.17865,
      city: 'Westby',
      state: 'MT',
    },
    59276: {
      lat: 48.92979,
      lng: -105.20108,
      city: 'Whitetail',
      state: 'MT',
    },
    59301: {
      lat: 46.36022,
      lng: -105.85055,
      city: 'Miles City',
      state: 'MT',
    },
    59311: {
      lat: 45.16944,
      lng: -104.53017,
      city: 'Alzada',
      state: 'MT',
    },
    59312: {
      lat: 47.0541,
      lng: -106.26872,
      city: 'Angela',
      state: 'MT',
    },
    59313: {
      lat: 46.27497,
      lng: -104.25016,
      city: 'Baker',
      state: 'MT',
    },
    59314: {
      lat: 45.07847,
      lng: -105.43933,
      city: 'Biddle',
      state: 'MT',
    },
    59315: {
      lat: 47.44241,
      lng: -104.87483,
      city: 'Bloomfield',
      state: 'MT',
    },
    59316: {
      lat: 45.33726,
      lng: -105.04973,
      city: 'Boyes',
      state: 'MT',
    },
    59317: {
      lat: 45.33477,
      lng: -105.52106,
      city: 'Broadus',
      state: 'MT',
    },
    59318: {
      lat: 47.48955,
      lng: -107.4784,
      city: 'Brusett',
      state: 'MT',
    },
    59319: {
      lat: 45.40696,
      lng: -104.17379,
      city: 'Capitol',
      state: 'MT',
    },
    59322: {
      lat: 47.03521,
      lng: -106.64069,
      city: 'Cohagen',
      state: 'MT',
    },
    59323: {
      lat: 45.91234,
      lng: -106.7032,
      city: 'Colstrip',
      state: 'MT',
    },
    59324: {
      lat: 45.76652,
      lng: -104.58732,
      city: 'Ekalaka',
      state: 'MT',
    },
    59326: {
      lat: 46.77178,
      lng: -104.85183,
      city: 'Fallon',
      state: 'MT',
    },
    59327: {
      lat: 46.2389,
      lng: -106.7082,
      city: 'Forsyth',
      state: 'MT',
    },
    59330: {
      lat: 47.11812,
      lng: -104.76469,
      city: 'Glendive',
      state: 'MT',
    },
    59332: {
      lat: 45.37589,
      lng: -104.72542,
      city: 'Hammond',
      state: 'MT',
    },
    59333: {
      lat: 46.28123,
      lng: -106.24765,
      city: 'Hathaway',
      state: 'MT',
    },
    59336: { lat: 46.342, lng: -104.95063, city: 'Ismay', state: 'MT' },
    59337: {
      lat: 47.39098,
      lng: -106.81486,
      city: 'Jordan',
      state: 'MT',
    },
    59338: {
      lat: 46.63392,
      lng: -105.65584,
      city: 'Kinsey',
      state: 'MT',
    },
    59339: {
      lat: 47.22587,
      lng: -105.19934,
      city: 'Lindsay',
      state: 'MT',
    },
    59343: {
      lat: 45.65602,
      lng: -105.43296,
      city: 'Olive',
      state: 'MT',
    },
    59344: {
      lat: 46.3516,
      lng: -104.5713,
      city: 'Plevna',
      state: 'MT',
    },
    59345: {
      lat: 45.85367,
      lng: -105.20436,
      city: 'Powderville',
      state: 'MT',
    },
    59347: {
      lat: 46.45434,
      lng: -106.34614,
      city: 'Rosebud',
      state: 'MT',
    },
    59349: {
      lat: 46.87278,
      lng: -105.48388,
      city: 'Terry',
      state: 'MT',
    },
    59351: {
      lat: 45.7633,
      lng: -105.70844,
      city: 'Volborg',
      state: 'MT',
    },
    59353: {
      lat: 46.96394,
      lng: -104.28218,
      city: 'Wibaux',
      state: 'MT',
    },
    59354: {
      lat: 46.18924,
      lng: -104.41939,
      city: 'Willard',
      state: 'MT',
    },
    59401: {
      lat: 47.51083,
      lng: -111.27691,
      city: 'Great Falls',
      state: 'MT',
    },
    59404: {
      lat: 47.63197,
      lng: -111.34807,
      city: 'Great Falls',
      state: 'MT',
    },
    59405: {
      lat: 47.28102,
      lng: -111.25937,
      city: 'Great Falls',
      state: 'MT',
    },
    59410: {
      lat: 47.49442,
      lng: -112.64299,
      city: 'Augusta',
      state: 'MT',
    },
    59411: {
      lat: 48.88965,
      lng: -113.41403,
      city: 'Babb',
      state: 'MT',
    },
    59412: { lat: 47.32538, lng: -110.8762, city: 'Belt', state: 'MT' },
    59414: {
      lat: 47.5276,
      lng: -111.26871,
      city: 'Black Eagle',
      state: 'MT',
    },
    59416: {
      lat: 48.04059,
      lng: -111.52359,
      city: 'Brady',
      state: 'MT',
    },
    59417: {
      lat: 48.54483,
      lng: -113.0086,
      city: 'Browning',
      state: 'MT',
    },
    59418: {
      lat: 46.80258,
      lng: -109.87849,
      city: 'Buffalo',
      state: 'MT',
    },
    59419: {
      lat: 48.00641,
      lng: -112.40855,
      city: 'Bynum',
      state: 'MT',
    },
    59420: {
      lat: 47.89688,
      lng: -111.04034,
      city: 'Carter',
      state: 'MT',
    },
    59421: {
      lat: 47.20179,
      lng: -111.72,
      city: 'Cascade',
      state: 'MT',
    },
    59422: {
      lat: 47.83957,
      lng: -112.48266,
      city: 'Choteau',
      state: 'MT',
    },
    59424: {
      lat: 47.34929,
      lng: -110.1022,
      city: 'Coffee Creek',
      state: 'MT',
    },
    59425: {
      lat: 48.18641,
      lng: -111.88738,
      city: 'Conrad',
      state: 'MT',
    },
    59427: {
      lat: 48.77357,
      lng: -112.61639,
      city: 'Cut Bank',
      state: 'MT',
    },
    59430: {
      lat: 47.43089,
      lng: -109.83434,
      city: 'Denton',
      state: 'MT',
    },
    59432: {
      lat: 48.14595,
      lng: -112.58853,
      city: 'Dupuyer',
      state: 'MT',
    },
    59433: {
      lat: 47.89565,
      lng: -111.68483,
      city: 'Dutton',
      state: 'MT',
    },
    59434: {
      lat: 48.68682,
      lng: -113.55678,
      city: 'East Glacier Park',
      state: 'MT',
    },
    59435: {
      lat: 48.5616,
      lng: -112.06059,
      city: 'Ethridge',
      state: 'MT',
    },
    59436: {
      lat: 47.6135,
      lng: -112.0907,
      city: 'Fairfield',
      state: 'MT',
    },
    59440: {
      lat: 47.72489,
      lng: -111.14261,
      city: 'Floweree',
      state: 'MT',
    },
    59441: {
      lat: 46.852,
      lng: -108.98933,
      city: 'Forest Grove',
      state: 'MT',
    },
    59442: {
      lat: 47.93005,
      lng: -110.64697,
      city: 'Fort Benton',
      state: 'MT',
    },
    59443: {
      lat: 47.5621,
      lng: -111.82647,
      city: 'Fort Shaw',
      state: 'MT',
    },
    59444: {
      lat: 48.65227,
      lng: -111.34881,
      city: 'Galata',
      state: 'MT',
    },
    59446: {
      lat: 47.64083,
      lng: -110.17894,
      city: 'Geraldine',
      state: 'MT',
    },
    59447: {
      lat: 47.28116,
      lng: -110.45955,
      city: 'Geyser',
      state: 'MT',
    },
    59448: {
      lat: 48.25789,
      lng: -112.86703,
      city: 'Heart Butte',
      state: 'MT',
    },
    59450: {
      lat: 47.5992,
      lng: -110.73923,
      city: 'Highwood',
      state: 'MT',
    },
    59451: {
      lat: 47.38984,
      lng: -109.39779,
      city: 'Hilger',
      state: 'MT',
    },
    59452: {
      lat: 46.86946,
      lng: -110.09831,
      city: 'Hobson',
      state: 'MT',
    },
    59453: {
      lat: 46.65903,
      lng: -109.66996,
      city: 'Judith Gap',
      state: 'MT',
    },
    59454: {
      lat: 48.74481,
      lng: -111.97769,
      city: 'Kevin',
      state: 'MT',
    },
    59456: {
      lat: 48.27221,
      lng: -111.35432,
      city: 'Ledger',
      state: 'MT',
    },
    59457: {
      lat: 47.08027,
      lng: -109.38656,
      city: 'Lewistown',
      state: 'MT',
    },
    59460: {
      lat: 48.07682,
      lng: -110.50908,
      city: 'Loma',
      state: 'MT',
    },
    59461: {
      lat: 48.4894,
      lng: -111.19985,
      city: 'Lothair',
      state: 'MT',
    },
    59462: {
      lat: 47.10833,
      lng: -109.88513,
      city: 'Moccasin',
      state: 'MT',
    },
    59463: {
      lat: 47.0489,
      lng: -110.86668,
      city: 'Monarch',
      state: 'MT',
    },
    59464: {
      lat: 46.87409,
      lng: -109.60589,
      city: 'Moore',
      state: 'MT',
    },
    59465: {
      lat: 46.93777,
      lng: -110.70788,
      city: 'Neihart',
      state: 'MT',
    },
    59466: {
      lat: 48.78746,
      lng: -111.69124,
      city: 'Oilmont',
      state: 'MT',
    },
    59467: {
      lat: 48.07149,
      lng: -112.37794,
      city: 'Pendroy',
      state: 'MT',
    },
    59468: {
      lat: 47.71253,
      lng: -111.62842,
      city: 'Power',
      state: 'MT',
    },
    59469: {
      lat: 47.19087,
      lng: -110.68378,
      city: 'Raynesford',
      state: 'MT',
    },
    59471: { lat: 47.4161, lng: -108.77191, city: 'Roy', state: 'MT' },
    59472: {
      lat: 47.37939,
      lng: -111.17095,
      city: 'Sand Coulee',
      state: 'MT',
    },
    59474: {
      lat: 48.53314,
      lng: -111.69254,
      city: 'Shelby',
      state: 'MT',
    },
    59477: {
      lat: 47.44467,
      lng: -111.97419,
      city: 'Simms',
      state: 'MT',
    },
    59479: {
      lat: 47.016,
      lng: -110.33696,
      city: 'Stanford',
      state: 'MT',
    },
    59480: {
      lat: 47.22696,
      lng: -111.12013,
      city: 'Stockett',
      state: 'MT',
    },
    59482: {
      lat: 48.88446,
      lng: -111.86622,
      city: 'Sunburst',
      state: 'MT',
    },
    59483: {
      lat: 47.47212,
      lng: -111.72261,
      city: 'Sun River',
      state: 'MT',
    },
    59484: {
      lat: 48.95074,
      lng: -111.76795,
      city: 'Sweet Grass',
      state: 'MT',
    },
    59485: { lat: 47.43264, lng: -111.56667, city: 'Ulm', state: 'MT' },
    59486: {
      lat: 48.33709,
      lng: -112.35552,
      city: 'Valier',
      state: 'MT',
    },
    59487: {
      lat: 47.58393,
      lng: -111.64592,
      city: 'Vaughn',
      state: 'MT',
    },
    59489: {
      lat: 47.64007,
      lng: -109.25586,
      city: 'Winifred',
      state: 'MT',
    },
    59501: {
      lat: 48.69591,
      lng: -109.78556,
      city: 'Havre',
      state: 'MT',
    },
    59520: {
      lat: 48.03822,
      lng: -109.93749,
      city: 'Big Sandy',
      state: 'MT',
    },
    59521: {
      lat: 48.30489,
      lng: -109.972,
      city: 'Box Elder',
      state: 'MT',
    },
    59522: {
      lat: 48.56497,
      lng: -110.98827,
      city: 'Chester',
      state: 'MT',
    },
    59523: {
      lat: 48.52013,
      lng: -109.20829,
      city: 'Chinook',
      state: 'MT',
    },
    59524: {
      lat: 48.12273,
      lng: -108.40374,
      city: 'Dodson',
      state: 'MT',
    },
    59525: {
      lat: 48.6524,
      lng: -110.27405,
      city: 'Gildford',
      state: 'MT',
    },
    59526: {
      lat: 48.4414,
      lng: -108.67155,
      city: 'Harlem',
      state: 'MT',
    },
    59527: {
      lat: 48.04527,
      lng: -108.74677,
      city: 'Hays',
      state: 'MT',
    },
    59528: {
      lat: 48.6233,
      lng: -110.42628,
      city: 'Hingham',
      state: 'MT',
    },
    59529: {
      lat: 48.79655,
      lng: -108.69059,
      city: 'Hogeland',
      state: 'MT',
    },
    59530: {
      lat: 48.60212,
      lng: -110.68802,
      city: 'Inverness',
      state: 'MT',
    },
    59531: {
      lat: 48.77294,
      lng: -110.78334,
      city: 'Joplin',
      state: 'MT',
    },
    59532: {
      lat: 48.58837,
      lng: -110.08515,
      city: 'Kremlin',
      state: 'MT',
    },
    59535: {
      lat: 47.94123,
      lng: -109.26059,
      city: 'Lloyd',
      state: 'MT',
    },
    59537: {
      lat: 48.84762,
      lng: -107.93725,
      city: 'Loring',
      state: 'MT',
    },
    59538: {
      lat: 48.02231,
      lng: -107.8657,
      city: 'Malta',
      state: 'MT',
    },
    59540: {
      lat: 48.60665,
      lng: -110.56418,
      city: 'Rudyard',
      state: 'MT',
    },
    59542: {
      lat: 48.81907,
      lng: -108.33693,
      city: 'Turner',
      state: 'MT',
    },
    59544: {
      lat: 48.81294,
      lng: -107.59147,
      city: 'Whitewater',
      state: 'MT',
    },
    59545: {
      lat: 48.94067,
      lng: -111.21768,
      city: 'Whitlash',
      state: 'MT',
    },
    59546: {
      lat: 47.88208,
      lng: -108.55654,
      city: 'Zortman',
      state: 'MT',
    },
    59547: {
      lat: 48.70576,
      lng: -108.98747,
      city: 'Zurich',
      state: 'MT',
    },
    59601: {
      lat: 46.54199,
      lng: -112.17474,
      city: 'Helena',
      state: 'MT',
    },
    59602: {
      lat: 46.73577,
      lng: -111.90753,
      city: 'Helena',
      state: 'MT',
    },
    59623: {
      lat: 46.58991,
      lng: -112.04068,
      city: 'Helena',
      state: 'MT',
    },
    59625: {
      lat: 46.60135,
      lng: -112.03891,
      city: 'Helena',
      state: 'MT',
    },
    59631: {
      lat: 46.29168,
      lng: -112.38687,
      city: 'Basin',
      state: 'MT',
    },
    59632: {
      lat: 46.16522,
      lng: -111.99918,
      city: 'Boulder',
      state: 'MT',
    },
    59633: {
      lat: 46.82338,
      lng: -112.36362,
      city: 'Canyon Creek',
      state: 'MT',
    },
    59634: {
      lat: 46.43583,
      lng: -112.00986,
      city: 'Clancy',
      state: 'MT',
    },
    59635: {
      lat: 46.5546,
      lng: -111.81749,
      city: 'East Helena',
      state: 'MT',
    },
    59636: {
      lat: 46.61614,
      lng: -112.11399,
      city: 'Fort Harrison',
      state: 'MT',
    },
    59638: {
      lat: 46.3579,
      lng: -112.02772,
      city: 'Jefferson City',
      state: 'MT',
    },
    59639: {
      lat: 47.09436,
      lng: -112.69371,
      city: 'Lincoln',
      state: 'MT',
    },
    59640: {
      lat: 46.76814,
      lng: -112.34922,
      city: 'Marysville',
      state: 'MT',
    },
    59642: {
      lat: 46.28045,
      lng: -110.75624,
      city: 'Ringling',
      state: 'MT',
    },
    59643: {
      lat: 46.13283,
      lng: -111.57884,
      city: 'Toston',
      state: 'MT',
    },
    59644: {
      lat: 46.419,
      lng: -111.43885,
      city: 'Townsend',
      state: 'MT',
    },
    59645: {
      lat: 46.69476,
      lng: -111.04729,
      city: 'White Sulphur Springs',
      state: 'MT',
    },
    59647: {
      lat: 46.46726,
      lng: -111.65138,
      city: 'Winston',
      state: 'MT',
    },
    59648: {
      lat: 47.07221,
      lng: -112.14059,
      city: 'Wolf Creek',
      state: 'MT',
    },
    59701: {
      lat: 46.03497,
      lng: -112.46087,
      city: 'Butte',
      state: 'MT',
    },
    59710: {
      lat: 45.06801,
      lng: -112.08613,
      city: 'Alder',
      state: 'MT',
    },
    59711: {
      lat: 46.09734,
      lng: -113.10317,
      city: 'Anaconda',
      state: 'MT',
    },
    59713: {
      lat: 46.67973,
      lng: -112.61228,
      city: 'Avon',
      state: 'MT',
    },
    59714: {
      lat: 45.97538,
      lng: -111.13112,
      city: 'Belgrade',
      state: 'MT',
    },
    59715: {
      lat: 45.6928,
      lng: -110.9065,
      city: 'Bozeman',
      state: 'MT',
    },
    59716: {
      lat: 45.28578,
      lng: -111.3873,
      city: 'Big Sky',
      state: 'MT',
    },
    59718: {
      lat: 45.63999,
      lng: -111.16978,
      city: 'Bozeman',
      state: 'MT',
    },
    59720: {
      lat: 44.95821,
      lng: -111.59366,
      city: 'Cameron',
      state: 'MT',
    },
    59721: {
      lat: 45.85189,
      lng: -111.88177,
      city: 'Cardwell',
      state: 'MT',
    },
    59722: {
      lat: 46.35143,
      lng: -112.75684,
      city: 'Deer Lodge',
      state: 'MT',
    },
    59724: {
      lat: 44.67716,
      lng: -112.79359,
      city: 'Dell',
      state: 'MT',
    },
    59725: {
      lat: 45.06267,
      lng: -112.85714,
      city: 'Dillon',
      state: 'MT',
    },
    59727: {
      lat: 45.78797,
      lng: -112.79394,
      city: 'Divide',
      state: 'MT',
    },
    59728: {
      lat: 46.50487,
      lng: -112.41135,
      city: 'Elliston',
      state: 'MT',
    },
    59729: {
      lat: 45.31343,
      lng: -111.64827,
      city: 'Ennis',
      state: 'MT',
    },
    59730: {
      lat: 45.30988,
      lng: -111.19025,
      city: 'Gallatin Gateway',
      state: 'MT',
    },
    59731: {
      lat: 46.58455,
      lng: -112.76326,
      city: 'Garrison',
      state: 'MT',
    },
    59732: {
      lat: 45.48899,
      lng: -112.69457,
      city: 'Glen',
      state: 'MT',
    },
    59733: {
      lat: 46.54922,
      lng: -112.94987,
      city: 'Gold Creek',
      state: 'MT',
    },
    59735: {
      lat: 45.65775,
      lng: -111.84615,
      city: 'Harrison',
      state: 'MT',
    },
    59736: {
      lat: 45.34242,
      lng: -113.3458,
      city: 'Jackson',
      state: 'MT',
    },
    59739: {
      lat: 44.65378,
      lng: -112.16963,
      city: 'Lima',
      state: 'MT',
    },
    59740: {
      lat: 45.49871,
      lng: -111.81159,
      city: 'McAllister',
      state: 'MT',
    },
    59741: {
      lat: 45.77265,
      lng: -111.3638,
      city: 'Manhattan',
      state: 'MT',
    },
    59743: {
      lat: 45.60068,
      lng: -112.74964,
      city: 'Melrose',
      state: 'MT',
    },
    59745: {
      lat: 45.5941,
      lng: -111.60658,
      city: 'Norris',
      state: 'MT',
    },
    59746: {
      lat: 45.37137,
      lng: -113.05332,
      city: 'Polaris',
      state: 'MT',
    },
    59747: {
      lat: 45.69862,
      lng: -111.93281,
      city: 'Pony',
      state: 'MT',
    },
    59748: {
      lat: 46.04611,
      lng: -112.69601,
      city: 'Ramsay',
      state: 'MT',
    },
    59749: {
      lat: 45.44332,
      lng: -112.09548,
      city: 'Sheridan',
      state: 'MT',
    },
    59750: {
      lat: 45.91857,
      lng: -112.68323,
      city: 'Butte',
      state: 'MT',
    },
    59751: {
      lat: 45.69001,
      lng: -112.29755,
      city: 'Silver Star',
      state: 'MT',
    },
    59752: {
      lat: 45.92412,
      lng: -111.52577,
      city: 'Three Forks',
      state: 'MT',
    },
    59754: {
      lat: 45.52435,
      lng: -112.35339,
      city: 'Twin Bridges',
      state: 'MT',
    },
    59755: {
      lat: 45.27174,
      lng: -111.94274,
      city: 'Virginia City',
      state: 'MT',
    },
    59756: {
      lat: 46.17418,
      lng: -112.83453,
      city: 'Warm Springs',
      state: 'MT',
    },
    59758: {
      lat: 44.78962,
      lng: -111.19348,
      city: 'West Yellowstone',
      state: 'MT',
    },
    59759: {
      lat: 45.83972,
      lng: -112.16233,
      city: 'Whitehall',
      state: 'MT',
    },
    59760: {
      lat: 45.76267,
      lng: -111.60386,
      city: 'Willow Creek',
      state: 'MT',
    },
    59761: {
      lat: 45.60427,
      lng: -113.52661,
      city: 'Wisdom',
      state: 'MT',
    },
    59762: {
      lat: 45.82231,
      lng: -113.15271,
      city: 'Wise River',
      state: 'MT',
    },
    59801: {
      lat: 46.85505,
      lng: -114.01009,
      city: 'Missoula',
      state: 'MT',
    },
    59802: {
      lat: 46.90569,
      lng: -113.92077,
      city: 'Missoula',
      state: 'MT',
    },
    59803: {
      lat: 46.77781,
      lng: -113.95826,
      city: 'Missoula',
      state: 'MT',
    },
    59804: {
      lat: 46.90084,
      lng: -114.25066,
      city: 'Missoula',
      state: 'MT',
    },
    59808: {
      lat: 46.97984,
      lng: -114.064,
      city: 'Missoula',
      state: 'MT',
    },
    59812: {
      lat: 46.86121,
      lng: -113.98442,
      city: 'Missoula',
      state: 'MT',
    },
    59820: {
      lat: 46.89803,
      lng: -114.61474,
      city: 'Alberton',
      state: 'MT',
    },
    59821: {
      lat: 47.17603,
      lng: -114.00431,
      city: 'Arlee',
      state: 'MT',
    },
    59823: {
      lat: 46.96334,
      lng: -113.59753,
      city: 'Bonner',
      state: 'MT',
    },
    59824: {
      lat: 47.41817,
      lng: -114.20362,
      city: 'Charlo',
      state: 'MT',
    },
    59825: {
      lat: 46.66009,
      lng: -113.66421,
      city: 'Clinton',
      state: 'MT',
    },
    59826: {
      lat: 47.46339,
      lng: -113.69827,
      city: 'Condon',
      state: 'MT',
    },
    59827: {
      lat: 45.88649,
      lng: -114.08346,
      city: 'Conner',
      state: 'MT',
    },
    59828: {
      lat: 46.33478,
      lng: -113.96444,
      city: 'Corvallis',
      state: 'MT',
    },
    59829: {
      lat: 45.79814,
      lng: -114.2614,
      city: 'Darby',
      state: 'MT',
    },
    59830: {
      lat: 47.43419,
      lng: -115.45896,
      city: 'De Borgia',
      state: 'MT',
    },
    59831: {
      lat: 47.27732,
      lng: -114.36296,
      city: 'Dixon',
      state: 'MT',
    },
    59832: {
      lat: 46.67864,
      lng: -113.22904,
      city: 'Drummond',
      state: 'MT',
    },
    59833: {
      lat: 46.64437,
      lng: -114.07678,
      city: 'Florence',
      state: 'MT',
    },
    59834: {
      lat: 47.06979,
      lng: -114.2221,
      city: 'Frenchtown',
      state: 'MT',
    },
    59837: {
      lat: 46.53898,
      lng: -113.27688,
      city: 'Hall',
      state: 'MT',
    },
    59840: {
      lat: 46.17558,
      lng: -114.11674,
      city: 'Hamilton',
      state: 'MT',
    },
    59841: {
      lat: 46.33384,
      lng: -114.22319,
      city: 'Pinesdale',
      state: 'MT',
    },
    59842: {
      lat: 47.32526,
      lng: -115.40107,
      city: 'Haugan',
      state: 'MT',
    },
    59843: {
      lat: 46.84897,
      lng: -113.01114,
      city: 'Helmville',
      state: 'MT',
    },
    59844: {
      lat: 48.00803,
      lng: -115.9707,
      city: 'Heron',
      state: 'MT',
    },
    59845: {
      lat: 47.65892,
      lng: -114.54734,
      city: 'Hot Springs',
      state: 'MT',
    },
    59846: {
      lat: 47.14899,
      lng: -114.50615,
      city: 'Huson',
      state: 'MT',
    },
    59847: {
      lat: 46.73105,
      lng: -114.37782,
      city: 'Lolo',
      state: 'MT',
    },
    59848: {
      lat: 47.71526,
      lng: -114.68162,
      city: 'Lonepine',
      state: 'MT',
    },
    59851: {
      lat: 46.87215,
      lng: -113.87348,
      city: 'Milltown',
      state: 'MT',
    },
    59853: {
      lat: 48.0571,
      lng: -115.81522,
      city: 'Noxon',
      state: 'MT',
    },
    59854: {
      lat: 47.26481,
      lng: -113.18997,
      city: 'Ovando',
      state: 'MT',
    },
    59855: {
      lat: 47.60032,
      lng: -114.11787,
      city: 'Pablo',
      state: 'MT',
    },
    59856: {
      lat: 47.38237,
      lng: -114.73627,
      city: 'Paradise',
      state: 'MT',
    },
    59858: {
      lat: 46.29584,
      lng: -113.48371,
      city: 'Philipsburg',
      state: 'MT',
    },
    59859: {
      lat: 47.5205,
      lng: -114.80396,
      city: 'Plains',
      state: 'MT',
    },
    59860: {
      lat: 47.71314,
      lng: -114.17832,
      city: 'Polson',
      state: 'MT',
    },
    59863: {
      lat: 47.29397,
      lng: -114.17637,
      city: 'Ravalli',
      state: 'MT',
    },
    59864: {
      lat: 47.53324,
      lng: -114.11741,
      city: 'Ronan',
      state: 'MT',
    },
    59865: {
      lat: 47.33184,
      lng: -114.0045,
      city: 'Saint Ignatius',
      state: 'MT',
    },
    59866: {
      lat: 47.32207,
      lng: -115.12329,
      city: 'Saint Regis',
      state: 'MT',
    },
    59867: {
      lat: 47.4028,
      lng: -115.54993,
      city: 'Saltese',
      state: 'MT',
    },
    59868: {
      lat: 47.21401,
      lng: -113.53535,
      city: 'Seeley Lake',
      state: 'MT',
    },
    59870: {
      lat: 46.51596,
      lng: -114.0618,
      city: 'Stevensville',
      state: 'MT',
    },
    59871: {
      lat: 45.89666,
      lng: -113.81366,
      city: 'Sula',
      state: 'MT',
    },
    59872: {
      lat: 47.10282,
      lng: -114.90339,
      city: 'Superior',
      state: 'MT',
    },
    59873: {
      lat: 47.6972,
      lng: -115.27357,
      city: 'Thompson Falls',
      state: 'MT',
    },
    59874: {
      lat: 47.80675,
      lng: -115.60749,
      city: 'Trout Creek',
      state: 'MT',
    },
    59875: {
      lat: 46.40793,
      lng: -114.26151,
      city: 'Victor',
      state: 'MT',
    },
    59901: {
      lat: 48.22873,
      lng: -114.40564,
      city: 'Kalispell',
      state: 'MT',
    },
    59910: {
      lat: 47.82409,
      lng: -114.23271,
      city: 'Big Arm',
      state: 'MT',
    },
    59911: {
      lat: 47.86041,
      lng: -113.88552,
      city: 'Bigfork',
      state: 'MT',
    },
    59912: {
      lat: 48.43879,
      lng: -114.16898,
      city: 'Columbia Falls',
      state: 'MT',
    },
    59913: {
      lat: 48.41391,
      lng: -113.95045,
      city: 'Coram',
      state: 'MT',
    },
    59914: {
      lat: 47.86878,
      lng: -114.29546,
      city: 'Dayton',
      state: 'MT',
    },
    59915: {
      lat: 47.82025,
      lng: -114.41124,
      city: 'Elmo',
      state: 'MT',
    },
    59916: {
      lat: 48.31716,
      lng: -113.65779,
      city: 'Essex',
      state: 'MT',
    },
    59917: {
      lat: 48.90614,
      lng: -114.91359,
      city: 'Eureka',
      state: 'MT',
    },
    59918: {
      lat: 48.75766,
      lng: -114.8077,
      city: 'Fortine',
      state: 'MT',
    },
    59919: {
      lat: 48.3776,
      lng: -114.05645,
      city: 'Hungry Horse',
      state: 'MT',
    },
    59920: {
      lat: 48.06412,
      lng: -114.53464,
      city: 'Kila',
      state: 'MT',
    },
    59922: {
      lat: 47.99962,
      lng: -114.22227,
      city: 'Lakeside',
      state: 'MT',
    },
    59923: {
      lat: 48.26996,
      lng: -115.31073,
      city: 'Libby',
      state: 'MT',
    },
    59925: {
      lat: 48.04732,
      lng: -114.83045,
      city: 'Marion',
      state: 'MT',
    },
    59926: {
      lat: 48.36294,
      lng: -114.00459,
      city: 'Martin City',
      state: 'MT',
    },
    59927: { lat: 48.56021, lng: -114.717, city: 'Olney', state: 'MT' },
    59928: {
      lat: 48.79745,
      lng: -114.29117,
      city: 'Polebridge',
      state: 'MT',
    },
    59929: {
      lat: 47.92365,
      lng: -114.40272,
      city: 'Proctor',
      state: 'MT',
    },
    59930: {
      lat: 48.86277,
      lng: -115.14514,
      city: 'Rexford',
      state: 'MT',
    },
    59931: {
      lat: 47.9208,
      lng: -114.25486,
      city: 'Rollins',
      state: 'MT',
    },
    59932: {
      lat: 48.0502,
      lng: -114.24624,
      city: 'Somers',
      state: 'MT',
    },
    59933: {
      lat: 48.70858,
      lng: -114.76694,
      city: 'Stryker',
      state: 'MT',
    },
    59934: {
      lat: 48.63437,
      lng: -114.94446,
      city: 'Trego',
      state: 'MT',
    },
    59935: {
      lat: 48.64726,
      lng: -115.83505,
      city: 'Troy',
      state: 'MT',
    },
    59936: {
      lat: 48.17178,
      lng: -113.46357,
      city: 'West Glacier',
      state: 'MT',
    },
    59937: {
      lat: 48.43893,
      lng: -114.49769,
      city: 'Whitefish',
      state: 'MT',
    },
    60002: {
      lat: 42.4674,
      lng: -88.09419,
      city: 'Antioch',
      state: 'IL',
    },
    60004: {
      lat: 42.11206,
      lng: -87.97895,
      city: 'Arlington Heights',
      state: 'IL',
    },
    60005: {
      lat: 42.06518,
      lng: -87.98676,
      city: 'Arlington Heights',
      state: 'IL',
    },
    60007: {
      lat: 42.01431,
      lng: -87.99828,
      city: 'Elk Grove Village',
      state: 'IL',
    },
    60008: {
      lat: 42.0739,
      lng: -88.0229,
      city: 'Rolling Meadows',
      state: 'IL',
    },
    60010: {
      lat: 42.15255,
      lng: -88.16519,
      city: 'Barrington',
      state: 'IL',
    },
    60012: {
      lat: 42.27199,
      lng: -88.31157,
      city: 'Crystal Lake',
      state: 'IL',
    },
    60013: { lat: 42.22027, lng: -88.23568, city: 'Cary', state: 'IL' },
    60014: {
      lat: 42.23126,
      lng: -88.32679,
      city: 'Crystal Lake',
      state: 'IL',
    },
    60015: {
      lat: 42.17239,
      lng: -87.87486,
      city: 'Deerfield',
      state: 'IL',
    },
    60016: {
      lat: 42.05232,
      lng: -87.8899,
      city: 'Des Plaines',
      state: 'IL',
    },
    60018: {
      lat: 41.99629,
      lng: -87.89846,
      city: 'Des Plaines',
      state: 'IL',
    },
    60020: {
      lat: 42.39543,
      lng: -88.17349,
      city: 'Fox Lake',
      state: 'IL',
    },
    60021: {
      lat: 42.19511,
      lng: -88.21768,
      city: 'Fox River Grove',
      state: 'IL',
    },
    60022: {
      lat: 42.13637,
      lng: -87.7664,
      city: 'Glencoe',
      state: 'IL',
    },
    60025: {
      lat: 42.07466,
      lng: -87.81204,
      city: 'Glenview',
      state: 'IL',
    },
    60026: {
      lat: 42.09221,
      lng: -87.83789,
      city: 'Glenview',
      state: 'IL',
    },
    60029: { lat: 42.05857, lng: -87.78496, city: 'Golf', state: 'IL' },
    60030: {
      lat: 42.33536,
      lng: -88.04519,
      city: 'Grayslake',
      state: 'IL',
    },
    60031: {
      lat: 42.37494,
      lng: -87.94119,
      city: 'Gurnee',
      state: 'IL',
    },
    60033: {
      lat: 42.42333,
      lng: -88.61104,
      city: 'Harvard',
      state: 'IL',
    },
    60034: {
      lat: 42.45869,
      lng: -88.42665,
      city: 'Hebron',
      state: 'IL',
    },
    60035: {
      lat: 42.18358,
      lng: -87.8106,
      city: 'Highland Park',
      state: 'IL',
    },
    60040: {
      lat: 42.20652,
      lng: -87.81274,
      city: 'Highwood',
      state: 'IL',
    },
    60041: {
      lat: 42.36939,
      lng: -88.15309,
      city: 'Ingleside',
      state: 'IL',
    },
    60042: {
      lat: 42.27913,
      lng: -88.19777,
      city: 'Island Lake',
      state: 'IL',
    },
    60043: {
      lat: 42.08882,
      lng: -87.71445,
      city: 'Kenilworth',
      state: 'IL',
    },
    60044: {
      lat: 42.2842,
      lng: -87.86392,
      city: 'Lake Bluff',
      state: 'IL',
    },
    60045: {
      lat: 42.23807,
      lng: -87.87006,
      city: 'Lake Forest',
      state: 'IL',
    },
    60046: {
      lat: 42.41572,
      lng: -88.06094,
      city: 'Lake Villa',
      state: 'IL',
    },
    60047: {
      lat: 42.20202,
      lng: -88.04522,
      city: 'Lake Zurich',
      state: 'IL',
    },
    60048: {
      lat: 42.29494,
      lng: -87.9466,
      city: 'Libertyville',
      state: 'IL',
    },
    60050: {
      lat: 42.32915,
      lng: -88.29363,
      city: 'Mchenry',
      state: 'IL',
    },
    60051: {
      lat: 42.35099,
      lng: -88.22412,
      city: 'Mchenry',
      state: 'IL',
    },
    60053: {
      lat: 42.04224,
      lng: -87.78895,
      city: 'Morton Grove',
      state: 'IL',
    },
    60056: {
      lat: 42.06618,
      lng: -87.93473,
      city: 'Mount Prospect',
      state: 'IL',
    },
    60060: {
      lat: 42.26945,
      lng: -88.03997,
      city: 'Mundelein',
      state: 'IL',
    },
    60061: {
      lat: 42.2334,
      lng: -87.96046,
      city: 'Vernon Hills',
      state: 'IL',
    },
    60062: {
      lat: 42.12663,
      lng: -87.84235,
      city: 'Northbrook',
      state: 'IL',
    },
    60064: {
      lat: 42.3237,
      lng: -87.86122,
      city: 'North Chicago',
      state: 'IL',
    },
    60067: {
      lat: 42.10773,
      lng: -88.06478,
      city: 'Palatine',
      state: 'IL',
    },
    60068: {
      lat: 42.01276,
      lng: -87.84435,
      city: 'Park Ridge',
      state: 'IL',
    },
    60069: {
      lat: 42.19588,
      lng: -87.92439,
      city: 'Lincolnshire',
      state: 'IL',
    },
    60070: {
      lat: 42.10406,
      lng: -87.92768,
      city: 'Prospect Heights',
      state: 'IL',
    },
    60071: {
      lat: 42.46403,
      lng: -88.30975,
      city: 'Richmond',
      state: 'IL',
    },
    60072: {
      lat: 42.40647,
      lng: -88.30637,
      city: 'Ringwood',
      state: 'IL',
    },
    60073: {
      lat: 42.3516,
      lng: -88.10597,
      city: 'Round Lake',
      state: 'IL',
    },
    60074: {
      lat: 42.13179,
      lng: -88.02658,
      city: 'Palatine',
      state: 'IL',
    },
    60075: {
      lat: 42.49245,
      lng: -87.91205,
      city: 'Russell',
      state: 'IL',
    },
    60076: {
      lat: 42.03412,
      lng: -87.72943,
      city: 'Skokie',
      state: 'IL',
    },
    60077: {
      lat: 42.03617,
      lng: -87.75633,
      city: 'Skokie',
      state: 'IL',
    },
    60081: {
      lat: 42.45115,
      lng: -88.22173,
      city: 'Spring Grove',
      state: 'IL',
    },
    60083: {
      lat: 42.43716,
      lng: -87.94663,
      city: 'Wadsworth',
      state: 'IL',
    },
    60084: {
      lat: 42.26807,
      lng: -88.13856,
      city: 'Wauconda',
      state: 'IL',
    },
    60085: {
      lat: 42.35498,
      lng: -87.86467,
      city: 'Waukegan',
      state: 'IL',
    },
    60087: {
      lat: 42.40444,
      lng: -87.85631,
      city: 'Waukegan',
      state: 'IL',
    },
    60088: {
      lat: 42.30685,
      lng: -87.85232,
      city: 'Great Lakes',
      state: 'IL',
    },
    60089: {
      lat: 42.16736,
      lng: -87.96076,
      city: 'Buffalo Grove',
      state: 'IL',
    },
    60090: {
      lat: 42.13031,
      lng: -87.92268,
      city: 'Wheeling',
      state: 'IL',
    },
    60091: {
      lat: 42.07693,
      lng: -87.72812,
      city: 'Wilmette',
      state: 'IL',
    },
    60093: {
      lat: 42.10333,
      lng: -87.75826,
      city: 'Winnetka',
      state: 'IL',
    },
    60096: {
      lat: 42.48091,
      lng: -87.82978,
      city: 'Winthrop Harbor',
      state: 'IL',
    },
    60097: {
      lat: 42.39343,
      lng: -88.36164,
      city: 'Wonder Lake',
      state: 'IL',
    },
    60098: {
      lat: 42.32468,
      lng: -88.45672,
      city: 'Woodstock',
      state: 'IL',
    },
    60099: { lat: 42.46053, lng: -87.86901, city: 'Zion', state: 'IL' },
    60101: {
      lat: 41.931,
      lng: -88.01072,
      city: 'Addison',
      state: 'IL',
    },
    60102: {
      lat: 42.16406,
      lng: -88.30928,
      city: 'Algonquin',
      state: 'IL',
    },
    60103: {
      lat: 41.97816,
      lng: -88.20511,
      city: 'Bartlett',
      state: 'IL',
    },
    60104: {
      lat: 41.88256,
      lng: -87.87646,
      city: 'Bellwood',
      state: 'IL',
    },
    60106: {
      lat: 41.9611,
      lng: -87.94194,
      city: 'Bensenville',
      state: 'IL',
    },
    60107: {
      lat: 42.02148,
      lng: -88.1783,
      city: 'Streamwood',
      state: 'IL',
    },
    60108: {
      lat: 41.94905,
      lng: -88.09232,
      city: 'Bloomingdale',
      state: 'IL',
    },
    60109: {
      lat: 42.04448,
      lng: -88.56261,
      city: 'Burlington',
      state: 'IL',
    },
    60110: {
      lat: 42.12296,
      lng: -88.2883,
      city: 'Carpentersville',
      state: 'IL',
    },
    60111: {
      lat: 42.00721,
      lng: -88.83114,
      city: 'Clare',
      state: 'IL',
    },
    60112: {
      lat: 41.92391,
      lng: -88.68994,
      city: 'Cortland',
      state: 'IL',
    },
    60113: {
      lat: 41.93142,
      lng: -88.96421,
      city: 'Creston',
      state: 'IL',
    },
    60115: {
      lat: 41.90109,
      lng: -88.75469,
      city: 'Dekalb',
      state: 'IL',
    },
    60118: {
      lat: 42.10793,
      lng: -88.30614,
      city: 'Dundee',
      state: 'IL',
    },
    60119: {
      lat: 41.86154,
      lng: -88.47702,
      city: 'Elburn',
      state: 'IL',
    },
    60120: { lat: 42.03317, lng: -88.2374, city: 'Elgin', state: 'IL' },
    60123: {
      lat: 42.04011,
      lng: -88.31166,
      city: 'Elgin',
      state: 'IL',
    },
    60124: {
      lat: 42.02621,
      lng: -88.39691,
      city: 'Elgin',
      state: 'IL',
    },
    60126: {
      lat: 41.89627,
      lng: -87.94194,
      city: 'Elmhurst',
      state: 'IL',
    },
    60129: {
      lat: 42.02963,
      lng: -88.95371,
      city: 'Esmond',
      state: 'IL',
    },
    60130: {
      lat: 41.86704,
      lng: -87.81795,
      city: 'Forest Park',
      state: 'IL',
    },
    60131: {
      lat: 41.9378,
      lng: -87.88304,
      city: 'Franklin Park',
      state: 'IL',
    },
    60133: {
      lat: 41.97805,
      lng: -88.14291,
      city: 'Hanover Park',
      state: 'IL',
    },
    60134: {
      lat: 41.8784,
      lng: -88.34189,
      city: 'Geneva',
      state: 'IL',
    },
    60135: {
      lat: 42.11265,
      lng: -88.67867,
      city: 'Genoa',
      state: 'IL',
    },
    60136: {
      lat: 42.1053,
      lng: -88.37808,
      city: 'Gilberts',
      state: 'IL',
    },
    60137: {
      lat: 41.86539,
      lng: -88.06225,
      city: 'Glen Ellyn',
      state: 'IL',
    },
    60139: {
      lat: 41.91916,
      lng: -88.07735,
      city: 'Glendale Heights',
      state: 'IL',
    },
    60140: {
      lat: 42.08003,
      lng: -88.5086,
      city: 'Hampshire',
      state: 'IL',
    },
    60141: { lat: 41.85834, lng: -87.8379, city: 'Hines', state: 'IL' },
    60142: {
      lat: 42.17517,
      lng: -88.44486,
      city: 'Huntley',
      state: 'IL',
    },
    60143: {
      lat: 41.97351,
      lng: -88.02071,
      city: 'Itasca',
      state: 'IL',
    },
    60144: {
      lat: 41.83507,
      lng: -88.51984,
      city: 'Kaneville',
      state: 'IL',
    },
    60145: {
      lat: 42.09396,
      lng: -88.77719,
      city: 'Kingston',
      state: 'IL',
    },
    60146: {
      lat: 42.10091,
      lng: -88.88019,
      city: 'Kirkland',
      state: 'IL',
    },
    60148: {
      lat: 41.87505,
      lng: -88.01859,
      city: 'Lombard',
      state: 'IL',
    },
    60150: {
      lat: 41.92106,
      lng: -88.88982,
      city: 'Malta',
      state: 'IL',
    },
    60151: {
      lat: 41.9172,
      lng: -88.56613,
      city: 'Maple Park',
      state: 'IL',
    },
    60152: {
      lat: 42.23882,
      lng: -88.62597,
      city: 'Marengo',
      state: 'IL',
    },
    60153: {
      lat: 41.87984,
      lng: -87.84389,
      city: 'Maywood',
      state: 'IL',
    },
    60154: {
      lat: 41.84923,
      lng: -87.88986,
      city: 'Westchester',
      state: 'IL',
    },
    60155: {
      lat: 41.8578,
      lng: -87.85631,
      city: 'Broadview',
      state: 'IL',
    },
    60156: {
      lat: 42.19121,
      lng: -88.34788,
      city: 'Lake In The Hills',
      state: 'IL',
    },
    60157: {
      lat: 41.974,
      lng: -88.05476,
      city: 'Medinah',
      state: 'IL',
    },
    60160: {
      lat: 41.90348,
      lng: -87.86176,
      city: 'Melrose Park',
      state: 'IL',
    },
    60162: {
      lat: 41.86753,
      lng: -87.90196,
      city: 'Hillside',
      state: 'IL',
    },
    60163: {
      lat: 41.88794,
      lng: -87.90948,
      city: 'Berkeley',
      state: 'IL',
    },
    60164: {
      lat: 41.91667,
      lng: -87.90112,
      city: 'Melrose Park',
      state: 'IL',
    },
    60165: {
      lat: 41.90328,
      lng: -87.88056,
      city: 'Stone Park',
      state: 'IL',
    },
    60169: {
      lat: 42.05192,
      lng: -88.11656,
      city: 'Hoffman Estates',
      state: 'IL',
    },
    60171: {
      lat: 41.92504,
      lng: -87.83832,
      city: 'River Grove',
      state: 'IL',
    },
    60172: {
      lat: 41.97976,
      lng: -88.08937,
      city: 'Roselle',
      state: 'IL',
    },
    60173: {
      lat: 42.05287,
      lng: -88.0526,
      city: 'Schaumburg',
      state: 'IL',
    },
    60174: {
      lat: 41.92759,
      lng: -88.29906,
      city: 'Saint Charles',
      state: 'IL',
    },
    60175: {
      lat: 41.94695,
      lng: -88.3913,
      city: 'Saint Charles',
      state: 'IL',
    },
    60176: {
      lat: 41.9583,
      lng: -87.8697,
      city: 'Schiller Park',
      state: 'IL',
    },
    60177: {
      lat: 41.98931,
      lng: -88.30987,
      city: 'South Elgin',
      state: 'IL',
    },
    60178: {
      lat: 42.00625,
      lng: -88.67205,
      city: 'Sycamore',
      state: 'IL',
    },
    60180: {
      lat: 42.22342,
      lng: -88.52023,
      city: 'Union',
      state: 'IL',
    },
    60181: {
      lat: 41.87795,
      lng: -87.97626,
      city: 'Villa Park',
      state: 'IL',
    },
    60183: {
      lat: 41.94019,
      lng: -88.40908,
      city: 'Wasco',
      state: 'IL',
    },
    60184: {
      lat: 41.95208,
      lng: -88.25323,
      city: 'Wayne',
      state: 'IL',
    },
    60185: {
      lat: 41.89308,
      lng: -88.21498,
      city: 'West Chicago',
      state: 'IL',
    },
    60187: {
      lat: 41.87231,
      lng: -88.1126,
      city: 'Wheaton',
      state: 'IL',
    },
    60188: {
      lat: 41.91562,
      lng: -88.12958,
      city: 'Carol Stream',
      state: 'IL',
    },
    60189: {
      lat: 41.83957,
      lng: -88.12128,
      city: 'Wheaton',
      state: 'IL',
    },
    60190: {
      lat: 41.87319,
      lng: -88.1576,
      city: 'Winfield',
      state: 'IL',
    },
    60191: {
      lat: 41.9647,
      lng: -87.98087,
      city: 'Wood Dale',
      state: 'IL',
    },
    60192: {
      lat: 42.07265,
      lng: -88.16797,
      city: 'Hoffman Estates',
      state: 'IL',
    },
    60193: {
      lat: 42.01195,
      lng: -88.09552,
      city: 'Schaumburg',
      state: 'IL',
    },
    60194: {
      lat: 42.03339,
      lng: -88.11125,
      city: 'Schaumburg',
      state: 'IL',
    },
    60195: {
      lat: 42.06541,
      lng: -88.08657,
      city: 'Schaumburg',
      state: 'IL',
    },
    60201: {
      lat: 42.05665,
      lng: -87.69827,
      city: 'Evanston',
      state: 'IL',
    },
    60202: {
      lat: 42.03043,
      lng: -87.68958,
      city: 'Evanston',
      state: 'IL',
    },
    60203: {
      lat: 42.04905,
      lng: -87.71748,
      city: 'Evanston',
      state: 'IL',
    },
    60208: {
      lat: 42.05457,
      lng: -87.67412,
      city: 'Evanston',
      state: 'IL',
    },
    60301: {
      lat: 41.88865,
      lng: -87.79648,
      city: 'Oak Park',
      state: 'IL',
    },
    60302: {
      lat: 41.8948,
      lng: -87.78983,
      city: 'Oak Park',
      state: 'IL',
    },
    60304: {
      lat: 41.87249,
      lng: -87.78933,
      city: 'Oak Park',
      state: 'IL',
    },
    60305: {
      lat: 41.89501,
      lng: -87.81943,
      city: 'River Forest',
      state: 'IL',
    },
    60401: {
      lat: 41.34683,
      lng: -87.61306,
      city: 'Beecher',
      state: 'IL',
    },
    60402: {
      lat: 41.83237,
      lng: -87.78983,
      city: 'Berwyn',
      state: 'IL',
    },
    60403: {
      lat: 41.56696,
      lng: -88.12438,
      city: 'Crest Hill',
      state: 'IL',
    },
    60404: {
      lat: 41.50864,
      lng: -88.22269,
      city: 'Shorewood',
      state: 'IL',
    },
    60406: {
      lat: 41.65509,
      lng: -87.68184,
      city: 'Blue Island',
      state: 'IL',
    },
    60407: {
      lat: 41.23037,
      lng: -88.26415,
      city: 'Braceville',
      state: 'IL',
    },
    60408: {
      lat: 41.26684,
      lng: -88.21733,
      city: 'Braidwood',
      state: 'IL',
    },
    60409: {
      lat: 41.61303,
      lng: -87.55142,
      city: 'Calumet City',
      state: 'IL',
    },
    60410: {
      lat: 41.42119,
      lng: -88.2171,
      city: 'Channahon',
      state: 'IL',
    },
    60411: {
      lat: 41.50791,
      lng: -87.59031,
      city: 'Chicago Heights',
      state: 'IL',
    },
    60415: {
      lat: 41.70341,
      lng: -87.77953,
      city: 'Chicago Ridge',
      state: 'IL',
    },
    60416: {
      lat: 41.29357,
      lng: -88.28197,
      city: 'Coal City',
      state: 'IL',
    },
    60417: {
      lat: 41.43056,
      lng: -87.59268,
      city: 'Crete',
      state: 'IL',
    },
    60418: {
      lat: 41.64568,
      lng: -87.74084,
      city: 'Crestwood',
      state: 'IL',
    },
    60419: {
      lat: 41.62752,
      lng: -87.59871,
      city: 'Dolton',
      state: 'IL',
    },
    60420: {
      lat: 41.08795,
      lng: -88.42127,
      city: 'Dwight',
      state: 'IL',
    },
    60421: {
      lat: 41.40851,
      lng: -88.08936,
      city: 'Elwood',
      state: 'IL',
    },
    60422: {
      lat: 41.53861,
      lng: -87.68443,
      city: 'Flossmoor',
      state: 'IL',
    },
    60423: {
      lat: 41.47922,
      lng: -87.83961,
      city: 'Frankfort',
      state: 'IL',
    },
    60424: {
      lat: 41.16604,
      lng: -88.32941,
      city: 'Gardner',
      state: 'IL',
    },
    60425: {
      lat: 41.54343,
      lng: -87.61073,
      city: 'Glenwood',
      state: 'IL',
    },
    60426: {
      lat: 41.61026,
      lng: -87.65309,
      city: 'Harvey',
      state: 'IL',
    },
    60428: {
      lat: 41.5998,
      lng: -87.69062,
      city: 'Markham',
      state: 'IL',
    },
    60429: {
      lat: 41.5737,
      lng: -87.68331,
      city: 'Hazel Crest',
      state: 'IL',
    },
    60430: {
      lat: 41.5584,
      lng: -87.66214,
      city: 'Homewood',
      state: 'IL',
    },
    60431: {
      lat: 41.53355,
      lng: -88.20568,
      city: 'Joliet',
      state: 'IL',
    },
    60432: {
      lat: 41.54087,
      lng: -88.0406,
      city: 'Joliet',
      state: 'IL',
    },
    60433: {
      lat: 41.49895,
      lng: -88.04248,
      city: 'Joliet',
      state: 'IL',
    },
    60435: {
      lat: 41.54492,
      lng: -88.1288,
      city: 'Joliet',
      state: 'IL',
    },
    60436: {
      lat: 41.48973,
      lng: -88.12392,
      city: 'Joliet',
      state: 'IL',
    },
    60437: {
      lat: 41.16168,
      lng: -88.54981,
      city: 'Kinsman',
      state: 'IL',
    },
    60438: {
      lat: 41.56625,
      lng: -87.55065,
      city: 'Lansing',
      state: 'IL',
    },
    60439: {
      lat: 41.67692,
      lng: -87.97623,
      city: 'Lemont',
      state: 'IL',
    },
    60440: {
      lat: 41.70125,
      lng: -88.07572,
      city: 'Bolingbrook',
      state: 'IL',
    },
    60441: {
      lat: 41.59238,
      lng: -88.05106,
      city: 'Lockport',
      state: 'IL',
    },
    60442: {
      lat: 41.39189,
      lng: -87.96062,
      city: 'Manhattan',
      state: 'IL',
    },
    60443: {
      lat: 41.50369,
      lng: -87.74637,
      city: 'Matteson',
      state: 'IL',
    },
    60444: {
      lat: 41.24246,
      lng: -88.40403,
      city: 'Mazon',
      state: 'IL',
    },
    60445: {
      lat: 41.62833,
      lng: -87.73542,
      city: 'Midlothian',
      state: 'IL',
    },
    60446: {
      lat: 41.63304,
      lng: -88.09855,
      city: 'Romeoville',
      state: 'IL',
    },
    60447: {
      lat: 41.48796,
      lng: -88.32498,
      city: 'Minooka',
      state: 'IL',
    },
    60448: {
      lat: 41.53821,
      lng: -87.89396,
      city: 'Mokena',
      state: 'IL',
    },
    60449: {
      lat: 41.41392,
      lng: -87.76978,
      city: 'Monee',
      state: 'IL',
    },
    60450: {
      lat: 41.37011,
      lng: -88.43417,
      city: 'Morris',
      state: 'IL',
    },
    60451: {
      lat: 41.50717,
      lng: -87.96088,
      city: 'New Lenox',
      state: 'IL',
    },
    60452: {
      lat: 41.60851,
      lng: -87.75434,
      city: 'Oak Forest',
      state: 'IL',
    },
    60453: {
      lat: 41.71412,
      lng: -87.75278,
      city: 'Oak Lawn',
      state: 'IL',
    },
    60455: {
      lat: 41.74102,
      lng: -87.8068,
      city: 'Bridgeview',
      state: 'IL',
    },
    60456: {
      lat: 41.73125,
      lng: -87.73111,
      city: 'Hometown',
      state: 'IL',
    },
    60457: {
      lat: 41.72475,
      lng: -87.82987,
      city: 'Hickory Hills',
      state: 'IL',
    },
    60458: {
      lat: 41.74892,
      lng: -87.83484,
      city: 'Justice',
      state: 'IL',
    },
    60459: {
      lat: 41.74436,
      lng: -87.76872,
      city: 'Burbank',
      state: 'IL',
    },
    60460: { lat: 40.9997, lng: -88.53393, city: 'Odell', state: 'IL' },
    60461: {
      lat: 41.51752,
      lng: -87.69148,
      city: 'Olympia Fields',
      state: 'IL',
    },
    60462: {
      lat: 41.62527,
      lng: -87.83548,
      city: 'Orland Park',
      state: 'IL',
    },
    60463: {
      lat: 41.66084,
      lng: -87.79033,
      city: 'Palos Heights',
      state: 'IL',
    },
    60464: {
      lat: 41.67088,
      lng: -87.86869,
      city: 'Palos Park',
      state: 'IL',
    },
    60465: {
      lat: 41.69878,
      lng: -87.8267,
      city: 'Palos Hills',
      state: 'IL',
    },
    60466: {
      lat: 41.47966,
      lng: -87.68276,
      city: 'Park Forest',
      state: 'IL',
    },
    60467: {
      lat: 41.59855,
      lng: -87.88848,
      city: 'Orland Park',
      state: 'IL',
    },
    60468: {
      lat: 41.33636,
      lng: -87.80991,
      city: 'Peotone',
      state: 'IL',
    },
    60469: {
      lat: 41.62871,
      lng: -87.68634,
      city: 'Posen',
      state: 'IL',
    },
    60470: {
      lat: 41.16592,
      lng: -88.63876,
      city: 'Ransom',
      state: 'IL',
    },
    60471: {
      lat: 41.48009,
      lng: -87.72981,
      city: 'Richton Park',
      state: 'IL',
    },
    60472: {
      lat: 41.6437,
      lng: -87.70836,
      city: 'Robbins',
      state: 'IL',
    },
    60473: {
      lat: 41.59733,
      lng: -87.60115,
      city: 'South Holland',
      state: 'IL',
    },
    60474: {
      lat: 41.1765,
      lng: -88.26983,
      city: 'South Wilmington',
      state: 'IL',
    },
    60475: {
      lat: 41.47193,
      lng: -87.6283,
      city: 'Steger',
      state: 'IL',
    },
    60476: {
      lat: 41.5694,
      lng: -87.60562,
      city: 'Thornton',
      state: 'IL',
    },
    60477: {
      lat: 41.57347,
      lng: -87.78597,
      city: 'Tinley Park',
      state: 'IL',
    },
    60478: {
      lat: 41.55381,
      lng: -87.73588,
      city: 'Country Club Hills',
      state: 'IL',
    },
    60479: {
      lat: 41.23174,
      lng: -88.52078,
      city: 'Verona',
      state: 'IL',
    },
    60480: {
      lat: 41.71909,
      lng: -87.87675,
      city: 'Willow Springs',
      state: 'IL',
    },
    60481: {
      lat: 41.28795,
      lng: -88.12952,
      city: 'Wilmington',
      state: 'IL',
    },
    60482: {
      lat: 41.68737,
      lng: -87.79239,
      city: 'Worth',
      state: 'IL',
    },
    60484: {
      lat: 41.44317,
      lng: -87.70948,
      city: 'University Park',
      state: 'IL',
    },
    60487: {
      lat: 41.56236,
      lng: -87.83095,
      city: 'Tinley Park',
      state: 'IL',
    },
    60490: {
      lat: 41.67575,
      lng: -88.14118,
      city: 'Bolingbrook',
      state: 'IL',
    },
    60491: {
      lat: 41.60313,
      lng: -87.9595,
      city: 'Homer Glen',
      state: 'IL',
    },
    60501: {
      lat: 41.77979,
      lng: -87.82393,
      city: 'Summit Argo',
      state: 'IL',
    },
    60502: {
      lat: 41.78911,
      lng: -88.25952,
      city: 'Aurora',
      state: 'IL',
    },
    60503: {
      lat: 41.7079,
      lng: -88.25475,
      city: 'Aurora',
      state: 'IL',
    },
    60504: {
      lat: 41.74649,
      lng: -88.23957,
      city: 'Aurora',
      state: 'IL',
    },
    60505: {
      lat: 41.76527,
      lng: -88.29576,
      city: 'Aurora',
      state: 'IL',
    },
    60506: {
      lat: 41.76176,
      lng: -88.37125,
      city: 'Aurora',
      state: 'IL',
    },
    60510: {
      lat: 41.84283,
      lng: -88.31189,
      city: 'Batavia',
      state: 'IL',
    },
    60511: {
      lat: 41.75778,
      lng: -88.55601,
      city: 'Big Rock',
      state: 'IL',
    },
    60512: {
      lat: 41.70134,
      lng: -88.43986,
      city: 'Bristol',
      state: 'IL',
    },
    60513: {
      lat: 41.82459,
      lng: -87.8469,
      city: 'Brookfield',
      state: 'IL',
    },
    60514: {
      lat: 41.79655,
      lng: -87.95683,
      city: 'Clarendon Hills',
      state: 'IL',
    },
    60515: {
      lat: 41.80991,
      lng: -88.0247,
      city: 'Downers Grove',
      state: 'IL',
    },
    60516: {
      lat: 41.76327,
      lng: -88.01314,
      city: 'Downers Grove',
      state: 'IL',
    },
    60517: {
      lat: 41.73913,
      lng: -88.04161,
      city: 'Woodridge',
      state: 'IL',
    },
    60518: {
      lat: 41.59997,
      lng: -88.92215,
      city: 'Earlville',
      state: 'IL',
    },
    60519: { lat: 41.77724, lng: -88.24255, city: 'Eola', state: 'IL' },
    60520: {
      lat: 41.7826,
      lng: -88.65808,
      city: 'Hinckley',
      state: 'IL',
    },
    60521: {
      lat: 41.80019,
      lng: -87.92883,
      city: 'Hinsdale',
      state: 'IL',
    },
    60523: {
      lat: 41.83713,
      lng: -87.95406,
      city: 'Oak Brook',
      state: 'IL',
    },
    60525: {
      lat: 41.78371,
      lng: -87.86778,
      city: 'La Grange',
      state: 'IL',
    },
    60526: {
      lat: 41.83002,
      lng: -87.8836,
      city: 'La Grange Park',
      state: 'IL',
    },
    60527: {
      lat: 41.74472,
      lng: -87.93383,
      city: 'Willowbrook',
      state: 'IL',
    },
    60530: { lat: 41.79613, lng: -88.95988, city: 'Lee', state: 'IL' },
    60531: {
      lat: 41.61563,
      lng: -88.78639,
      city: 'Leland',
      state: 'IL',
    },
    60532: {
      lat: 41.79153,
      lng: -88.08576,
      city: 'Lisle',
      state: 'IL',
    },
    60534: {
      lat: 41.81311,
      lng: -87.82066,
      city: 'Lyons',
      state: 'IL',
    },
    60536: {
      lat: 41.59853,
      lng: -88.55212,
      city: 'Millbrook',
      state: 'IL',
    },
    60537: {
      lat: 41.56158,
      lng: -88.60246,
      city: 'Millington',
      state: 'IL',
    },
    60538: {
      lat: 41.72165,
      lng: -88.35637,
      city: 'Montgomery',
      state: 'IL',
    },
    60539: {
      lat: 41.825,
      lng: -88.33529,
      city: 'Mooseheart',
      state: 'IL',
    },
    60540: {
      lat: 41.76361,
      lng: -88.14476,
      city: 'Naperville',
      state: 'IL',
    },
    60541: {
      lat: 41.5193,
      lng: -88.52552,
      city: 'Newark',
      state: 'IL',
    },
    60542: {
      lat: 41.80878,
      lng: -88.3489,
      city: 'North Aurora',
      state: 'IL',
    },
    60543: {
      lat: 41.66537,
      lng: -88.32442,
      city: 'Oswego',
      state: 'IL',
    },
    60544: {
      lat: 41.61393,
      lng: -88.21777,
      city: 'Plainfield',
      state: 'IL',
    },
    60545: {
      lat: 41.67815,
      lng: -88.53587,
      city: 'Plano',
      state: 'IL',
    },
    60546: {
      lat: 41.83834,
      lng: -87.82132,
      city: 'Riverside',
      state: 'IL',
    },
    60548: {
      lat: 41.64811,
      lng: -88.63471,
      city: 'Sandwich',
      state: 'IL',
    },
    60549: {
      lat: 41.4908,
      lng: -88.74251,
      city: 'Serena',
      state: 'IL',
    },
    60550: {
      lat: 41.77708,
      lng: -88.87777,
      city: 'Shabbona',
      state: 'IL',
    },
    60551: {
      lat: 41.52463,
      lng: -88.68992,
      city: 'Sheridan',
      state: 'IL',
    },
    60552: {
      lat: 41.66144,
      lng: -88.70343,
      city: 'Somonauk',
      state: 'IL',
    },
    60553: {
      lat: 41.82399,
      lng: -89.02806,
      city: 'Steward',
      state: 'IL',
    },
    60554: {
      lat: 41.77852,
      lng: -88.45838,
      city: 'Sugar Grove',
      state: 'IL',
    },
    60555: {
      lat: 41.82261,
      lng: -88.18854,
      city: 'Warrenville',
      state: 'IL',
    },
    60556: {
      lat: 41.76507,
      lng: -88.77373,
      city: 'Waterman',
      state: 'IL',
    },
    60557: {
      lat: 41.44383,
      lng: -88.76654,
      city: 'Wedron',
      state: 'IL',
    },
    60558: {
      lat: 41.80207,
      lng: -87.90076,
      city: 'Western Springs',
      state: 'IL',
    },
    60559: {
      lat: 41.79496,
      lng: -87.97464,
      city: 'Westmont',
      state: 'IL',
    },
    60560: {
      lat: 41.60799,
      lng: -88.42841,
      city: 'Yorkville',
      state: 'IL',
    },
    60561: {
      lat: 41.74518,
      lng: -87.98174,
      city: 'Darien',
      state: 'IL',
    },
    60563: {
      lat: 41.79933,
      lng: -88.16884,
      city: 'Naperville',
      state: 'IL',
    },
    60564: {
      lat: 41.70901,
      lng: -88.19961,
      city: 'Naperville',
      state: 'IL',
    },
    60565: {
      lat: 41.7297,
      lng: -88.12312,
      city: 'Naperville',
      state: 'IL',
    },
    60585: {
      lat: 41.65864,
      lng: -88.22339,
      city: 'Plainfield',
      state: 'IL',
    },
    60586: {
      lat: 41.57036,
      lng: -88.23162,
      city: 'Plainfield',
      state: 'IL',
    },
    60601: {
      lat: 41.88527,
      lng: -87.62197,
      city: 'Chicago',
      state: 'IL',
    },
    60602: {
      lat: 41.88304,
      lng: -87.62906,
      city: 'Chicago',
      state: 'IL',
    },
    60603: {
      lat: 41.88018,
      lng: -87.6274,
      city: 'Chicago',
      state: 'IL',
    },
    60604: {
      lat: 41.87799,
      lng: -87.62928,
      city: 'Chicago',
      state: 'IL',
    },
    60605: {
      lat: 41.86644,
      lng: -87.61956,
      city: 'Chicago',
      state: 'IL',
    },
    60606: {
      lat: 41.88178,
      lng: -87.63724,
      city: 'Chicago',
      state: 'IL',
    },
    60607: {
      lat: 41.87467,
      lng: -87.65175,
      city: 'Chicago',
      state: 'IL',
    },
    60608: {
      lat: 41.84887,
      lng: -87.67139,
      city: 'Chicago',
      state: 'IL',
    },
    60609: {
      lat: 41.81263,
      lng: -87.65559,
      city: 'Chicago',
      state: 'IL',
    },
    60610: {
      lat: 41.90388,
      lng: -87.63738,
      city: 'Chicago',
      state: 'IL',
    },
    60611: {
      lat: 41.89483,
      lng: -87.61944,
      city: 'Chicago',
      state: 'IL',
    },
    60612: {
      lat: 41.8805,
      lng: -87.68783,
      city: 'Chicago',
      state: 'IL',
    },
    60613: {
      lat: 41.9558,
      lng: -87.65706,
      city: 'Chicago',
      state: 'IL',
    },
    60614: {
      lat: 41.92267,
      lng: -87.6506,
      city: 'Chicago',
      state: 'IL',
    },
    60615: {
      lat: 41.80226,
      lng: -87.60293,
      city: 'Chicago',
      state: 'IL',
    },
    60616: {
      lat: 41.84502,
      lng: -87.62745,
      city: 'Chicago',
      state: 'IL',
    },
    60617: {
      lat: 41.71337,
      lng: -87.55618,
      city: 'Chicago',
      state: 'IL',
    },
    60618: {
      lat: 41.94694,
      lng: -87.70263,
      city: 'Chicago',
      state: 'IL',
    },
    60619: {
      lat: 41.74346,
      lng: -87.60532,
      city: 'Chicago',
      state: 'IL',
    },
    60620: {
      lat: 41.74096,
      lng: -87.65257,
      city: 'Chicago',
      state: 'IL',
    },
    60621: {
      lat: 41.77624,
      lng: -87.63955,
      city: 'Chicago',
      state: 'IL',
    },
    60622: {
      lat: 41.90267,
      lng: -87.68355,
      city: 'Chicago',
      state: 'IL',
    },
    60623: {
      lat: 41.84793,
      lng: -87.71753,
      city: 'Chicago',
      state: 'IL',
    },
    60624: {
      lat: 41.88045,
      lng: -87.72366,
      city: 'Chicago',
      state: 'IL',
    },
    60625: {
      lat: 41.97318,
      lng: -87.69963,
      city: 'Chicago',
      state: 'IL',
    },
    60626: {
      lat: 42.00904,
      lng: -87.66955,
      city: 'Chicago',
      state: 'IL',
    },
    60628: {
      lat: 41.69165,
      lng: -87.61828,
      city: 'Chicago',
      state: 'IL',
    },
    60629: {
      lat: 41.7754,
      lng: -87.71239,
      city: 'Chicago',
      state: 'IL',
    },
    60630: {
      lat: 41.97222,
      lng: -87.75758,
      city: 'Chicago',
      state: 'IL',
    },
    60631: {
      lat: 41.99473,
      lng: -87.8131,
      city: 'Chicago',
      state: 'IL',
    },
    60632: {
      lat: 41.81141,
      lng: -87.71348,
      city: 'Chicago',
      state: 'IL',
    },
    60633: {
      lat: 41.66315,
      lng: -87.56044,
      city: 'Chicago',
      state: 'IL',
    },
    60634: {
      lat: 41.94627,
      lng: -87.80639,
      city: 'Chicago',
      state: 'IL',
    },
    60636: {
      lat: 41.77578,
      lng: -87.66909,
      city: 'Chicago',
      state: 'IL',
    },
    60637: {
      lat: 41.78156,
      lng: -87.6032,
      city: 'Chicago',
      state: 'IL',
    },
    60638: {
      lat: 41.78175,
      lng: -87.77057,
      city: 'Chicago',
      state: 'IL',
    },
    60639: {
      lat: 41.9206,
      lng: -87.75593,
      city: 'Chicago',
      state: 'IL',
    },
    60640: {
      lat: 41.97253,
      lng: -87.66372,
      city: 'Chicago',
      state: 'IL',
    },
    60641: {
      lat: 41.94667,
      lng: -87.74693,
      city: 'Chicago',
      state: 'IL',
    },
    60642: {
      lat: 41.90193,
      lng: -87.65804,
      city: 'Chicago',
      state: 'IL',
    },
    60643: {
      lat: 41.70026,
      lng: -87.663,
      city: 'Chicago',
      state: 'IL',
    },
    60644: {
      lat: 41.88013,
      lng: -87.75756,
      city: 'Chicago',
      state: 'IL',
    },
    60645: {
      lat: 42.00859,
      lng: -87.69457,
      city: 'Chicago',
      state: 'IL',
    },
    60646: {
      lat: 41.99365,
      lng: -87.76128,
      city: 'Chicago',
      state: 'IL',
    },
    60647: {
      lat: 41.92074,
      lng: -87.702,
      city: 'Chicago',
      state: 'IL',
    },
    60649: {
      lat: 41.75885,
      lng: -87.56368,
      city: 'Chicago',
      state: 'IL',
    },
    60651: {
      lat: 41.90202,
      lng: -87.74104,
      city: 'Chicago',
      state: 'IL',
    },
    60652: {
      lat: 41.74744,
      lng: -87.71445,
      city: 'Chicago',
      state: 'IL',
    },
    60653: {
      lat: 41.81924,
      lng: -87.61017,
      city: 'Chicago',
      state: 'IL',
    },
    60654: {
      lat: 41.89209,
      lng: -87.63673,
      city: 'Chicago',
      state: 'IL',
    },
    60655: {
      lat: 41.69479,
      lng: -87.70374,
      city: 'Chicago',
      state: 'IL',
    },
    60656: {
      lat: 41.97424,
      lng: -87.82709,
      city: 'Chicago',
      state: 'IL',
    },
    60657: {
      lat: 41.93965,
      lng: -87.65495,
      city: 'Chicago',
      state: 'IL',
    },
    60659: {
      lat: 41.98946,
      lng: -87.70668,
      city: 'Chicago',
      state: 'IL',
    },
    60660: {
      lat: 41.99113,
      lng: -87.66609,
      city: 'Chicago',
      state: 'IL',
    },
    60661: {
      lat: 41.88291,
      lng: -87.64409,
      city: 'Chicago',
      state: 'IL',
    },
    60706: {
      lat: 41.96384,
      lng: -87.81672,
      city: 'Harwood Heights',
      state: 'IL',
    },
    60707: {
      lat: 41.92175,
      lng: -87.80744,
      city: 'Elmwood Park',
      state: 'IL',
    },
    60712: {
      lat: 42.00543,
      lng: -87.73296,
      city: 'Lincolnwood',
      state: 'IL',
    },
    60714: {
      lat: 42.02834,
      lng: -87.81131,
      city: 'Niles',
      state: 'IL',
    },
    60803: {
      lat: 41.67233,
      lng: -87.73652,
      city: 'Alsip',
      state: 'IL',
    },
    60804: {
      lat: 41.84005,
      lng: -87.75925,
      city: 'Cicero',
      state: 'IL',
    },
    60805: {
      lat: 41.72201,
      lng: -87.70243,
      city: 'Evergreen Park',
      state: 'IL',
    },
    60827: {
      lat: 41.64967,
      lng: -87.63013,
      city: 'Riverdale',
      state: 'IL',
    },
    60901: {
      lat: 41.11029,
      lng: -87.90049,
      city: 'Kankakee',
      state: 'IL',
    },
    60910: {
      lat: 41.06426,
      lng: -87.79991,
      city: 'Aroma Park',
      state: 'IL',
    },
    60911: {
      lat: 40.87856,
      lng: -87.98059,
      city: 'Ashkum',
      state: 'IL',
    },
    60912: {
      lat: 40.97252,
      lng: -87.60245,
      city: 'Beaverville',
      state: 'IL',
    },
    60913: {
      lat: 41.14835,
      lng: -88.06206,
      city: 'Bonfield',
      state: 'IL',
    },
    60914: {
      lat: 41.18706,
      lng: -87.86194,
      city: 'Bourbonnais',
      state: 'IL',
    },
    60915: {
      lat: 41.14758,
      lng: -87.86039,
      city: 'Bradley',
      state: 'IL',
    },
    60917: {
      lat: 41.04644,
      lng: -88.19179,
      city: 'Buckingham',
      state: 'IL',
    },
    60918: {
      lat: 40.59681,
      lng: -88.02968,
      city: 'Buckley',
      state: 'IL',
    },
    60919: {
      lat: 40.98753,
      lng: -88.24012,
      city: 'Cabery',
      state: 'IL',
    },
    60920: {
      lat: 41.03021,
      lng: -88.30378,
      city: 'Campus',
      state: 'IL',
    },
    60921: {
      lat: 40.73513,
      lng: -88.28894,
      city: 'Chatsworth',
      state: 'IL',
    },
    60922: {
      lat: 41.00394,
      lng: -87.92184,
      city: 'Chebanse',
      state: 'IL',
    },
    60924: {
      lat: 40.55855,
      lng: -87.88454,
      city: 'Cissna Park',
      state: 'IL',
    },
    60926: {
      lat: 40.56937,
      lng: -87.81208,
      city: 'Claytonville',
      state: 'IL',
    },
    60927: {
      lat: 40.93933,
      lng: -87.96744,
      city: 'Clifton',
      state: 'IL',
    },
    60928: {
      lat: 40.74568,
      lng: -87.85078,
      city: 'Crescent City',
      state: 'IL',
    },
    60929: {
      lat: 40.87601,
      lng: -88.29242,
      city: 'Cullom',
      state: 'IL',
    },
    60930: {
      lat: 40.83231,
      lng: -88.00286,
      city: 'Danforth',
      state: 'IL',
    },
    60931: {
      lat: 40.8919,
      lng: -87.58654,
      city: 'Donovan',
      state: 'IL',
    },
    60932: {
      lat: 40.46513,
      lng: -87.80013,
      city: 'East Lynn',
      state: 'IL',
    },
    60933: {
      lat: 40.46258,
      lng: -88.27381,
      city: 'Elliott',
      state: 'IL',
    },
    60934: {
      lat: 40.96119,
      lng: -88.34862,
      city: 'Emington',
      state: 'IL',
    },
    60935: {
      lat: 41.17165,
      lng: -88.16853,
      city: 'Essex',
      state: 'IL',
    },
    60936: {
      lat: 40.46456,
      lng: -88.36103,
      city: 'Gibson City',
      state: 'IL',
    },
    60938: {
      lat: 40.77456,
      lng: -87.98355,
      city: 'Gilman',
      state: 'IL',
    },
    60939: {
      lat: 40.56696,
      lng: -87.77714,
      city: 'Goodwine',
      state: 'IL',
    },
    60940: {
      lat: 41.25188,
      lng: -87.64259,
      city: 'Grant Park',
      state: 'IL',
    },
    60941: {
      lat: 41.03865,
      lng: -88.0859,
      city: 'Herscher',
      state: 'IL',
    },
    60942: {
      lat: 40.46678,
      lng: -87.66134,
      city: 'Hoopeston',
      state: 'IL',
    },
    60945: {
      lat: 40.8315,
      lng: -87.59236,
      city: 'Iroquois',
      state: 'IL',
    },
    60946: {
      lat: 40.91307,
      lng: -88.20072,
      city: 'Kempton',
      state: 'IL',
    },
    60948: { lat: 40.52413, lng: -88.07698, city: 'Loda', state: 'IL' },
    60949: {
      lat: 40.38042,
      lng: -88.10239,
      city: 'Ludlow',
      state: 'IL',
    },
    60950: {
      lat: 41.25135,
      lng: -87.88494,
      city: 'Manteno',
      state: 'IL',
    },
    60951: {
      lat: 40.91721,
      lng: -87.74757,
      city: 'Martinton',
      state: 'IL',
    },
    60952: {
      lat: 40.56373,
      lng: -88.25142,
      city: 'Melvin',
      state: 'IL',
    },
    60953: {
      lat: 40.62854,
      lng: -87.6894,
      city: 'Milford',
      state: 'IL',
    },
    60954: {
      lat: 41.15446,
      lng: -87.62827,
      city: 'Momence',
      state: 'IL',
    },
    60955: {
      lat: 40.7004,
      lng: -87.98008,
      city: 'Onarga',
      state: 'IL',
    },
    60956: {
      lat: 40.96775,
      lng: -87.7163,
      city: 'Papineau',
      state: 'IL',
    },
    60957: {
      lat: 40.44037,
      lng: -88.13977,
      city: 'Paxton',
      state: 'IL',
    },
    60958: {
      lat: 41.06523,
      lng: -87.5916,
      city: 'Pembroke Township',
      state: 'IL',
    },
    60959: {
      lat: 40.7895,
      lng: -88.1832,
      city: 'Piper City',
      state: 'IL',
    },
    60960: {
      lat: 40.43879,
      lng: -87.89132,
      city: 'Rankin',
      state: 'IL',
    },
    60961: {
      lat: 41.10611,
      lng: -88.23098,
      city: 'Reddick',
      state: 'IL',
    },
    60962: {
      lat: 40.62248,
      lng: -88.18442,
      city: 'Roberts',
      state: 'IL',
    },
    60963: {
      lat: 40.36848,
      lng: -87.65115,
      city: 'Rossville',
      state: 'IL',
    },
    60964: {
      lat: 41.03401,
      lng: -87.7418,
      city: 'Saint Anne',
      state: 'IL',
    },
    60966: {
      lat: 40.75144,
      lng: -87.58321,
      city: 'Sheldon',
      state: 'IL',
    },
    60967: {
      lat: 40.6241,
      lng: -87.58123,
      city: 'Stockland',
      state: 'IL',
    },
    60968: {
      lat: 40.6746,
      lng: -88.12049,
      city: 'Thawville',
      state: 'IL',
    },
    60969: {
      lat: 41.11101,
      lng: -88.14975,
      city: 'Union Hill',
      state: 'IL',
    },
    60970: {
      lat: 40.79221,
      lng: -87.74039,
      city: 'Watseka',
      state: 'IL',
    },
    60973: {
      lat: 40.53654,
      lng: -87.66265,
      city: 'Wellington',
      state: 'IL',
    },
    60974: {
      lat: 40.71807,
      lng: -87.73588,
      city: 'Woodland',
      state: 'IL',
    },
    61001: {
      lat: 42.4612,
      lng: -90.12226,
      city: 'Apple River',
      state: 'IL',
    },
    61006: {
      lat: 41.86078,
      lng: -89.20836,
      city: 'Ashton',
      state: 'IL',
    },
    61007: {
      lat: 42.19941,
      lng: -89.59109,
      city: 'Baileyville',
      state: 'IL',
    },
    61008: {
      lat: 42.24579,
      lng: -88.83949,
      city: 'Belvidere',
      state: 'IL',
    },
    61010: { lat: 42.136, lng: -89.26751, city: 'Byron', state: 'IL' },
    61011: {
      lat: 42.38926,
      lng: -88.91266,
      city: 'Caledonia',
      state: 'IL',
    },
    61012: {
      lat: 42.40749,
      lng: -88.75446,
      city: 'Capron',
      state: 'IL',
    },
    61013: {
      lat: 42.37544,
      lng: -89.63623,
      city: 'Cedarville',
      state: 'IL',
    },
    61014: {
      lat: 41.97501,
      lng: -89.8865,
      city: 'Chadwick',
      state: 'IL',
    },
    61015: {
      lat: 41.99443,
      lng: -89.20011,
      city: 'Chana',
      state: 'IL',
    },
    61016: {
      lat: 42.19761,
      lng: -88.94704,
      city: 'Cherry Valley',
      state: 'IL',
    },
    61018: {
      lat: 42.41599,
      lng: -89.55558,
      city: 'Dakota',
      state: 'IL',
    },
    61019: {
      lat: 42.44093,
      lng: -89.40863,
      city: 'Davis',
      state: 'IL',
    },
    61020: {
      lat: 42.11035,
      lng: -89.09807,
      city: 'Davis Junction',
      state: 'IL',
    },
    61021: {
      lat: 41.82908,
      lng: -89.47764,
      city: 'Dixon',
      state: 'IL',
    },
    61024: {
      lat: 42.43629,
      lng: -89.29183,
      city: 'Durand',
      state: 'IL',
    },
    61025: {
      lat: 42.46958,
      lng: -90.55904,
      city: 'East Dubuque',
      state: 'IL',
    },
    61027: {
      lat: 42.3333,
      lng: -89.75553,
      city: 'Eleroy',
      state: 'IL',
    },
    61028: {
      lat: 42.29366,
      lng: -90.16935,
      city: 'Elizabeth',
      state: 'IL',
    },
    61030: {
      lat: 42.11578,
      lng: -89.58898,
      city: 'Forreston',
      state: 'IL',
    },
    61031: {
      lat: 41.83263,
      lng: -89.31626,
      city: 'Franklin Grove',
      state: 'IL',
    },
    61032: {
      lat: 42.31509,
      lng: -89.63598,
      city: 'Freeport',
      state: 'IL',
    },
    61036: {
      lat: 42.4172,
      lng: -90.39816,
      city: 'Galena',
      state: 'IL',
    },
    61037: { lat: 41.7882, lng: -89.75978, city: 'Galt', state: 'IL' },
    61038: {
      lat: 42.26344,
      lng: -88.73586,
      city: 'Garden Prairie',
      state: 'IL',
    },
    61039: {
      lat: 42.20805,
      lng: -89.46931,
      city: 'German Valley',
      state: 'IL',
    },
    61041: { lat: 42.272, lng: -90.3202, city: 'Hanover', state: 'IL' },
    61042: {
      lat: 41.68819,
      lng: -89.56015,
      city: 'Harmon',
      state: 'IL',
    },
    61043: {
      lat: 42.06676,
      lng: -89.10499,
      city: 'Holcomb',
      state: 'IL',
    },
    61044: { lat: 42.32336, lng: -89.9099, city: 'Kent', state: 'IL' },
    61046: {
      lat: 42.10404,
      lng: -89.81329,
      city: 'Lanark',
      state: 'IL',
    },
    61047: {
      lat: 42.15305,
      lng: -89.39597,
      city: 'Leaf River',
      state: 'IL',
    },
    61048: { lat: 42.38524, lng: -89.83526, city: 'Lena', state: 'IL' },
    61049: {
      lat: 42.04417,
      lng: -89.00753,
      city: 'Lindenwood',
      state: 'IL',
    },
    61050: {
      lat: 42.43932,
      lng: -89.7319,
      city: 'McConnell',
      state: 'IL',
    },
    61051: {
      lat: 41.98289,
      lng: -89.7619,
      city: 'Milledgeville',
      state: 'IL',
    },
    61052: {
      lat: 42.11146,
      lng: -89.00011,
      city: 'Monroe Center',
      state: 'IL',
    },
    61053: {
      lat: 42.11506,
      lng: -89.98335,
      city: 'Mount Carroll',
      state: 'IL',
    },
    61054: {
      lat: 42.05573,
      lng: -89.44548,
      city: 'Mount Morris',
      state: 'IL',
    },
    61057: {
      lat: 41.83357,
      lng: -89.38401,
      city: 'Nachusa',
      state: 'IL',
    },
    61059: { lat: 42.45465, lng: -89.94084, city: 'Nora', state: 'IL' },
    61060: {
      lat: 42.47876,
      lng: -89.61819,
      city: 'Orangeville',
      state: 'IL',
    },
    61061: {
      lat: 41.99851,
      lng: -89.33819,
      city: 'Oregon',
      state: 'IL',
    },
    61062: {
      lat: 42.25254,
      lng: -89.84263,
      city: 'Pearl City',
      state: 'IL',
    },
    61063: {
      lat: 42.31305,
      lng: -89.34691,
      city: 'Pecatonica',
      state: 'IL',
    },
    61064: { lat: 41.9893, lng: -89.5873, city: 'Polo', state: 'IL' },
    61065: {
      lat: 42.40786,
      lng: -88.82747,
      city: 'Poplar Grove',
      state: 'IL',
    },
    61067: {
      lat: 42.30472,
      lng: -89.47454,
      city: 'Ridott',
      state: 'IL',
    },
    61068: {
      lat: 41.94928,
      lng: -89.0652,
      city: 'Rochelle',
      state: 'IL',
    },
    61070: {
      lat: 42.41571,
      lng: -89.47177,
      city: 'Rock City',
      state: 'IL',
    },
    61071: {
      lat: 41.72599,
      lng: -89.70006,
      city: 'Rock Falls',
      state: 'IL',
    },
    61072: {
      lat: 42.44661,
      lng: -89.14109,
      city: 'Rockton',
      state: 'IL',
    },
    61073: {
      lat: 42.42434,
      lng: -88.99346,
      city: 'Roscoe',
      state: 'IL',
    },
    61074: {
      lat: 42.12615,
      lng: -90.13061,
      city: 'Savanna',
      state: 'IL',
    },
    61075: {
      lat: 42.46546,
      lng: -90.26444,
      city: 'Scales Mound',
      state: 'IL',
    },
    61077: {
      lat: 42.24012,
      lng: -89.34806,
      city: 'Seward',
      state: 'IL',
    },
    61078: {
      lat: 42.16729,
      lng: -89.73062,
      city: 'Shannon',
      state: 'IL',
    },
    61079: {
      lat: 42.44681,
      lng: -89.20955,
      city: 'Shirland',
      state: 'IL',
    },
    61080: {
      lat: 42.47992,
      lng: -88.98989,
      city: 'South Beloit',
      state: 'IL',
    },
    61081: {
      lat: 41.84425,
      lng: -89.73613,
      city: 'Sterling',
      state: 'IL',
    },
    61084: {
      lat: 42.11843,
      lng: -89.18867,
      city: 'Stillman Valley',
      state: 'IL',
    },
    61085: {
      lat: 42.34268,
      lng: -90.03077,
      city: 'Stockton',
      state: 'IL',
    },
    61087: {
      lat: 42.48046,
      lng: -89.99351,
      city: 'Warren',
      state: 'IL',
    },
    61088: {
      lat: 42.26995,
      lng: -89.25509,
      city: 'Winnebago',
      state: 'IL',
    },
    61089: {
      lat: 42.47349,
      lng: -89.82335,
      city: 'Winslow',
      state: 'IL',
    },
    61091: {
      lat: 41.90818,
      lng: -89.55234,
      city: 'Woosung',
      state: 'IL',
    },
    61101: {
      lat: 42.33727,
      lng: -89.14725,
      city: 'Rockford',
      state: 'IL',
    },
    61102: {
      lat: 42.22995,
      lng: -89.15632,
      city: 'Rockford',
      state: 'IL',
    },
    61103: {
      lat: 42.3453,
      lng: -89.08271,
      city: 'Rockford',
      state: 'IL',
    },
    61104: {
      lat: 42.25186,
      lng: -89.07911,
      city: 'Rockford',
      state: 'IL',
    },
    61107: {
      lat: 42.28344,
      lng: -89.00348,
      city: 'Rockford',
      state: 'IL',
    },
    61108: {
      lat: 42.25515,
      lng: -89.00236,
      city: 'Rockford',
      state: 'IL',
    },
    61109: {
      lat: 42.1924,
      lng: -89.05392,
      city: 'Rockford',
      state: 'IL',
    },
    61111: {
      lat: 42.33538,
      lng: -89.00369,
      city: 'Loves Park',
      state: 'IL',
    },
    61112: {
      lat: 42.24193,
      lng: -88.97523,
      city: 'Rockford',
      state: 'IL',
    },
    61114: {
      lat: 42.30854,
      lng: -88.9892,
      city: 'Rockford',
      state: 'IL',
    },
    61115: {
      lat: 42.36365,
      lng: -89.02869,
      city: 'Machesney Park',
      state: 'IL',
    },
    61201: {
      lat: 41.47799,
      lng: -90.57608,
      city: 'Rock Island',
      state: 'IL',
    },
    61230: {
      lat: 41.74074,
      lng: -90.21447,
      city: 'Albany',
      state: 'IL',
    },
    61231: { lat: 41.2128, lng: -90.72294, city: 'Aledo', state: 'IL' },
    61232: {
      lat: 41.43168,
      lng: -90.72734,
      city: 'Andalusia',
      state: 'IL',
    },
    61233: {
      lat: 41.29464,
      lng: -90.29129,
      city: 'Andover',
      state: 'IL',
    },
    61234: {
      lat: 41.41804,
      lng: -89.91947,
      city: 'Annawan',
      state: 'IL',
    },
    61235: {
      lat: 41.39972,
      lng: -90.0237,
      city: 'Atkinson',
      state: 'IL',
    },
    61236: {
      lat: 41.51242,
      lng: -90.35586,
      city: 'Barstow',
      state: 'IL',
    },
    61237: {
      lat: 41.34175,
      lng: -90.86221,
      city: 'Buffalo Prairie',
      state: 'IL',
    },
    61238: {
      lat: 41.28727,
      lng: -90.17647,
      city: 'Cambridge',
      state: 'IL',
    },
    61239: {
      lat: 41.49479,
      lng: -90.38966,
      city: 'Carbon Cliff',
      state: 'IL',
    },
    61240: {
      lat: 41.42579,
      lng: -90.4325,
      city: 'Coal Valley',
      state: 'IL',
    },
    61241: {
      lat: 41.48782,
      lng: -90.31997,
      city: 'Colona',
      state: 'IL',
    },
    61242: {
      lat: 41.70363,
      lng: -90.27858,
      city: 'Cordova',
      state: 'IL',
    },
    61243: {
      lat: 41.61849,
      lng: -89.6784,
      city: 'Deer Grove',
      state: 'IL',
    },
    61244: {
      lat: 41.52138,
      lng: -90.39424,
      city: 'East Moline',
      state: 'IL',
    },
    61250: { lat: 41.65884, lng: -90.10081, city: 'Erie', state: 'IL' },
    61251: {
      lat: 41.72909,
      lng: -90.07291,
      city: 'Fenton',
      state: 'IL',
    },
    61252: {
      lat: 41.8435,
      lng: -90.12272,
      city: 'Fulton',
      state: 'IL',
    },
    61254: {
      lat: 41.46692,
      lng: -90.15551,
      city: 'Geneseo',
      state: 'IL',
    },
    61256: {
      lat: 41.56017,
      lng: -90.40151,
      city: 'Hampton',
      state: 'IL',
    },
    61257: {
      lat: 41.59025,
      lng: -90.22322,
      city: 'Hillsdale',
      state: 'IL',
    },
    61258: {
      lat: 41.52185,
      lng: -89.91396,
      city: 'Hooppole',
      state: 'IL',
    },
    61259: {
      lat: 41.38839,
      lng: -90.92733,
      city: 'Illinois City',
      state: 'IL',
    },
    61260: { lat: 41.24141, lng: -90.88407, city: 'Joy', state: 'IL' },
    61261: {
      lat: 41.72928,
      lng: -89.91166,
      city: 'Lyndon',
      state: 'IL',
    },
    61262: {
      lat: 41.27799,
      lng: -90.35699,
      city: 'Lynn Center',
      state: 'IL',
    },
    61263: {
      lat: 41.26409,
      lng: -90.59878,
      city: 'Matherville',
      state: 'IL',
    },
    61264: {
      lat: 41.39765,
      lng: -90.59452,
      city: 'Milan',
      state: 'IL',
    },
    61265: {
      lat: 41.48195,
      lng: -90.48895,
      city: 'Moline',
      state: 'IL',
    },
    61270: {
      lat: 41.82971,
      lng: -89.9656,
      city: 'Morrison',
      state: 'IL',
    },
    61272: {
      lat: 41.24817,
      lng: -91.00865,
      city: 'New Boston',
      state: 'IL',
    },
    61273: {
      lat: 41.36426,
      lng: -90.39907,
      city: 'Orion',
      state: 'IL',
    },
    61274: { lat: 41.37031, lng: -90.27666, city: 'Osco', state: 'IL' },
    61275: {
      lat: 41.60016,
      lng: -90.29781,
      city: 'Port Byron',
      state: 'IL',
    },
    61276: {
      lat: 41.31667,
      lng: -90.57638,
      city: 'Preemption',
      state: 'IL',
    },
    61277: {
      lat: 41.60425,
      lng: -89.93251,
      city: 'Prophetstown',
      state: 'IL',
    },
    61278: {
      lat: 41.57938,
      lng: -90.34138,
      city: 'Rapids City',
      state: 'IL',
    },
    61279: {
      lat: 41.32574,
      lng: -90.72863,
      city: 'Reynolds',
      state: 'IL',
    },
    61281: {
      lat: 41.3009,
      lng: -90.51992,
      city: 'Sherrard',
      state: 'IL',
    },
    61282: {
      lat: 41.49673,
      lng: -90.41172,
      city: 'Silvis',
      state: 'IL',
    },
    61283: {
      lat: 41.59094,
      lng: -89.78854,
      city: 'Tampico',
      state: 'IL',
    },
    61284: {
      lat: 41.3927,
      lng: -90.74874,
      city: 'Taylor Ridge',
      state: 'IL',
    },
    61285: {
      lat: 41.98307,
      lng: -90.0811,
      city: 'Thomson',
      state: 'IL',
    },
    61301: {
      lat: 41.39368,
      lng: -89.07827,
      city: 'La Salle',
      state: 'IL',
    },
    61310: {
      lat: 41.69815,
      lng: -89.34769,
      city: 'Amboy',
      state: 'IL',
    },
    61311: {
      lat: 41.04018,
      lng: -88.86219,
      city: 'Ancona',
      state: 'IL',
    },
    61312: {
      lat: 41.43403,
      lng: -89.22544,
      city: 'Arlington',
      state: 'IL',
    },
    61313: {
      lat: 41.07237,
      lng: -88.66993,
      city: 'Blackstone',
      state: 'IL',
    },
    61314: { lat: 41.29745, lng: -89.67474, city: 'Buda', state: 'IL' },
    61315: {
      lat: 41.2865,
      lng: -89.36477,
      city: 'Bureau',
      state: 'IL',
    },
    61316: {
      lat: 41.25718,
      lng: -89.12538,
      city: 'Cedar Point',
      state: 'IL',
    },
    61317: {
      lat: 41.43201,
      lng: -89.20813,
      city: 'Cherry',
      state: 'IL',
    },
    61318: {
      lat: 41.71202,
      lng: -89.08039,
      city: 'Compton',
      state: 'IL',
    },
    61319: {
      lat: 41.01291,
      lng: -88.73942,
      city: 'Cornell',
      state: 'IL',
    },
    61320: {
      lat: 41.3553,
      lng: -89.17047,
      city: 'Dalzell',
      state: 'IL',
    },
    61321: { lat: 40.96754, lng: -88.97621, city: 'Dana', state: 'IL' },
    61322: { lat: 41.3185, lng: -89.30566, city: 'Depue', state: 'IL' },
    61323: {
      lat: 41.43544,
      lng: -89.39605,
      city: 'Dover',
      state: 'IL',
    },
    61324: {
      lat: 41.76994,
      lng: -89.4136,
      city: 'Eldena',
      state: 'IL',
    },
    61325: {
      lat: 41.23673,
      lng: -88.80973,
      city: 'Grand Ridge',
      state: 'IL',
    },
    61326: {
      lat: 41.25276,
      lng: -89.22083,
      city: 'Granville',
      state: 'IL',
    },
    61327: {
      lat: 41.22827,
      lng: -89.30876,
      city: 'Hennepin',
      state: 'IL',
    },
    61328: {
      lat: 41.50924,
      lng: -89.459,
      city: 'Kasbeer',
      state: 'IL',
    },
    61329: { lat: 41.37709, lng: -89.20791, city: 'Ladd', state: 'IL' },
    61330: {
      lat: 41.53602,
      lng: -89.27756,
      city: 'La Moille',
      state: 'IL',
    },
    61331: {
      lat: 41.74771,
      lng: -89.27754,
      city: 'Lee Center',
      state: 'IL',
    },
    61332: {
      lat: 41.18884,
      lng: -88.98456,
      city: 'Leonore',
      state: 'IL',
    },
    61333: {
      lat: 40.98845,
      lng: -88.88931,
      city: 'Long Point',
      state: 'IL',
    },
    61334: {
      lat: 41.14731,
      lng: -89.09935,
      city: 'Lostant',
      state: 'IL',
    },
    61335: {
      lat: 41.16141,
      lng: -89.23228,
      city: 'McNabb',
      state: 'IL',
    },
    61336: {
      lat: 41.1073,
      lng: -89.21727,
      city: 'Magnolia',
      state: 'IL',
    },
    61337: {
      lat: 41.43827,
      lng: -89.3171,
      city: 'Malden',
      state: 'IL',
    },
    61338: {
      lat: 41.47014,
      lng: -89.66517,
      city: 'Manlius',
      state: 'IL',
    },
    61340: { lat: 41.26396, lng: -89.25123, city: 'Mark', state: 'IL' },
    61341: {
      lat: 41.34015,
      lng: -88.69246,
      city: 'Marseilles',
      state: 'IL',
    },
    61342: {
      lat: 41.54089,
      lng: -89.0938,
      city: 'Mendota',
      state: 'IL',
    },
    61344: {
      lat: 41.41245,
      lng: -89.8344,
      city: 'Mineral',
      state: 'IL',
    },
    61345: {
      lat: 41.27981,
      lng: -89.78878,
      city: 'Neponset',
      state: 'IL',
    },
    61346: {
      lat: 41.50659,
      lng: -89.7199,
      city: 'New Bedford',
      state: 'IL',
    },
    61348: {
      lat: 41.2812,
      lng: -89.03685,
      city: 'Oglesby',
      state: 'IL',
    },
    61349: { lat: 41.55295, lng: -89.44192, city: 'Ohio', state: 'IL' },
    61350: {
      lat: 41.37152,
      lng: -88.86157,
      city: 'Ottawa',
      state: 'IL',
    },
    61353: {
      lat: 41.6967,
      lng: -88.99623,
      city: 'Paw Paw',
      state: 'IL',
    },
    61354: { lat: 41.33158, lng: -89.14281, city: 'Peru', state: 'IL' },
    61356: {
      lat: 41.38914,
      lng: -89.43406,
      city: 'Princeton',
      state: 'IL',
    },
    61358: {
      lat: 40.98166,
      lng: -89.04299,
      city: 'Rutland',
      state: 'IL',
    },
    61359: {
      lat: 41.36341,
      lng: -89.26962,
      city: 'Seatonville',
      state: 'IL',
    },
    61360: {
      lat: 41.33439,
      lng: -88.60522,
      city: 'Seneca',
      state: 'IL',
    },
    61361: {
      lat: 41.40913,
      lng: -89.76778,
      city: 'Sheffield',
      state: 'IL',
    },
    61362: {
      lat: 41.35138,
      lng: -89.22819,
      city: 'Spring Valley',
      state: 'IL',
    },
    61363: {
      lat: 41.25425,
      lng: -89.18248,
      city: 'Standard',
      state: 'IL',
    },
    61364: {
      lat: 41.12822,
      lng: -88.85097,
      city: 'Streator',
      state: 'IL',
    },
    61367: {
      lat: 41.62611,
      lng: -89.26221,
      city: 'Sublette',
      state: 'IL',
    },
    61368: {
      lat: 41.26113,
      lng: -89.51981,
      city: 'Tiskilwa',
      state: 'IL',
    },
    61369: {
      lat: 40.9827,
      lng: -89.15397,
      city: 'Toluca',
      state: 'IL',
    },
    61370: {
      lat: 41.20854,
      lng: -89.03476,
      city: 'Tonica',
      state: 'IL',
    },
    61371: {
      lat: 41.49936,
      lng: -89.01986,
      city: 'Triumph',
      state: 'IL',
    },
    61372: {
      lat: 41.46582,
      lng: -89.08239,
      city: 'Troy Grove',
      state: 'IL',
    },
    61373: { lat: 41.4004, lng: -88.99456, city: 'Utica', state: 'IL' },
    61374: {
      lat: 41.5473,
      lng: -89.35759,
      city: 'Van Orin',
      state: 'IL',
    },
    61375: {
      lat: 41.03254,
      lng: -89.24688,
      city: 'Varna',
      state: 'IL',
    },
    61376: {
      lat: 41.54065,
      lng: -89.60726,
      city: 'Walnut',
      state: 'IL',
    },
    61377: {
      lat: 41.05847,
      lng: -89.04444,
      city: 'Wenona',
      state: 'IL',
    },
    61378: {
      lat: 41.72614,
      lng: -89.158,
      city: 'West Brooklyn',
      state: 'IL',
    },
    61379: {
      lat: 41.38144,
      lng: -89.61077,
      city: 'Wyanet',
      state: 'IL',
    },
    61401: {
      lat: 40.94451,
      lng: -90.38888,
      city: 'Galesburg',
      state: 'IL',
    },
    61402: {
      lat: 40.93993,
      lng: -90.40627,
      city: 'Galesburg',
      state: 'IL',
    },
    61410: {
      lat: 40.79779,
      lng: -90.40703,
      city: 'Abingdon',
      state: 'IL',
    },
    61411: {
      lat: 40.40105,
      lng: -90.50544,
      city: 'Adair',
      state: 'IL',
    },
    61412: {
      lat: 41.07092,
      lng: -90.58853,
      city: 'Alexis',
      state: 'IL',
    },
    61413: {
      lat: 41.19135,
      lng: -90.37227,
      city: 'Alpha',
      state: 'IL',
    },
    61414: {
      lat: 41.12597,
      lng: -90.15495,
      city: 'Altona',
      state: 'IL',
    },
    61415: { lat: 40.65425, lng: -90.42801, city: 'Avon', state: 'IL' },
    61416: {
      lat: 40.49592,
      lng: -90.56308,
      city: 'Bardolph',
      state: 'IL',
    },
    61417: {
      lat: 40.77222,
      lng: -90.54413,
      city: 'Berwick',
      state: 'IL',
    },
    61418: {
      lat: 40.84714,
      lng: -90.86492,
      city: 'Biggsville',
      state: 'IL',
    },
    61419: {
      lat: 41.19982,
      lng: -90.11751,
      city: 'Bishop Hill',
      state: 'IL',
    },
    61420: {
      lat: 40.5491,
      lng: -90.86679,
      city: 'Blandinsville',
      state: 'IL',
    },
    61421: {
      lat: 41.1792,
      lng: -89.65083,
      city: 'Bradford',
      state: 'IL',
    },
    61422: {
      lat: 40.55084,
      lng: -90.53113,
      city: 'Bushnell',
      state: 'IL',
    },
    61423: {
      lat: 40.8855,
      lng: -90.50232,
      city: 'Cameron',
      state: 'IL',
    },
    61424: {
      lat: 41.07671,
      lng: -89.63513,
      city: 'Camp Grove',
      state: 'IL',
    },
    61425: {
      lat: 40.76297,
      lng: -91.04575,
      city: 'Carman',
      state: 'IL',
    },
    61426: {
      lat: 41.11964,
      lng: -89.70601,
      city: 'Castleton',
      state: 'IL',
    },
    61427: { lat: 40.49823, lng: -90.19319, city: 'Cuba', state: 'IL' },
    61428: {
      lat: 40.93262,
      lng: -90.10977,
      city: 'Dahinda',
      state: 'IL',
    },
    61430: {
      lat: 40.94393,
      lng: -90.31135,
      city: 'East Galesburg',
      state: 'IL',
    },
    61431: {
      lat: 40.60315,
      lng: -90.2776,
      city: 'Ellisville',
      state: 'IL',
    },
    61432: {
      lat: 40.65055,
      lng: -90.16086,
      city: 'Fairview',
      state: 'IL',
    },
    61433: {
      lat: 40.55749,
      lng: -90.16866,
      city: 'Fiatt',
      state: 'IL',
    },
    61434: {
      lat: 41.17387,
      lng: -90.03598,
      city: 'Galva',
      state: 'IL',
    },
    61435: {
      lat: 40.99559,
      lng: -90.5686,
      city: 'Gerlaw',
      state: 'IL',
    },
    61436: {
      lat: 40.86067,
      lng: -90.21739,
      city: 'Gilson',
      state: 'IL',
    },
    61437: {
      lat: 40.84911,
      lng: -90.98905,
      city: 'Gladstone',
      state: 'IL',
    },
    61438: {
      lat: 40.58538,
      lng: -90.64942,
      city: 'Good Hope',
      state: 'IL',
    },
    61439: {
      lat: 41.02923,
      lng: -90.35019,
      city: 'Henderson',
      state: 'IL',
    },
    61440: {
      lat: 40.3079,
      lng: -90.59786,
      city: 'Industry',
      state: 'IL',
    },
    61441: {
      lat: 40.33957,
      lng: -90.29336,
      city: 'Ipava',
      state: 'IL',
    },
    61442: {
      lat: 41.10237,
      lng: -90.92303,
      city: 'Keithsburg',
      state: 'IL',
    },
    61443: {
      lat: 41.25972,
      lng: -89.94242,
      city: 'Kewanee',
      state: 'IL',
    },
    61447: {
      lat: 40.87105,
      lng: -90.76674,
      city: 'Kirkwood',
      state: 'IL',
    },
    61448: {
      lat: 40.93385,
      lng: -90.24374,
      city: 'Knoxville',
      state: 'IL',
    },
    61449: {
      lat: 41.08926,
      lng: -89.97051,
      city: 'La Fayette',
      state: 'IL',
    },
    61450: {
      lat: 40.57629,
      lng: -90.98088,
      city: 'La Harpe',
      state: 'IL',
    },
    61451: {
      lat: 40.94117,
      lng: -89.93555,
      city: 'Laura',
      state: 'IL',
    },
    61452: {
      lat: 40.23836,
      lng: -90.66022,
      city: 'Littleton',
      state: 'IL',
    },
    61453: {
      lat: 41.01345,
      lng: -90.76322,
      city: 'Little York',
      state: 'IL',
    },
    61454: {
      lat: 40.68152,
      lng: -91.04535,
      city: 'Lomax',
      state: 'IL',
    },
    61455: {
      lat: 40.44047,
      lng: -90.65115,
      city: 'Macomb',
      state: 'IL',
    },
    61458: {
      lat: 40.78027,
      lng: -90.18107,
      city: 'Maquon',
      state: 'IL',
    },
    61459: {
      lat: 40.50497,
      lng: -90.41453,
      city: 'Marietta',
      state: 'IL',
    },
    61460: {
      lat: 40.72159,
      lng: -90.82774,
      city: 'Media',
      state: 'IL',
    },
    61462: {
      lat: 40.91014,
      lng: -90.65176,
      city: 'Monmouth',
      state: 'IL',
    },
    61465: {
      lat: 41.20618,
      lng: -90.46744,
      city: 'New Windsor',
      state: 'IL',
    },
    61466: {
      lat: 41.11103,
      lng: -90.48222,
      city: 'North Henderson',
      state: 'IL',
    },
    61467: {
      lat: 41.07115,
      lng: -90.24338,
      city: 'Oneida',
      state: 'IL',
    },
    61468: {
      lat: 41.2565,
      lng: -90.38751,
      city: 'Ophiem',
      state: 'IL',
    },
    61469: {
      lat: 40.96262,
      lng: -90.91066,
      city: 'Oquawka',
      state: 'IL',
    },
    61470: {
      lat: 40.60613,
      lng: -90.50623,
      city: 'Prairie City',
      state: 'IL',
    },
    61471: {
      lat: 40.69583,
      lng: -90.82507,
      city: 'Raritan',
      state: 'IL',
    },
    61472: { lat: 41.10327, lng: -90.38345, city: 'Rio', state: 'IL' },
    61473: {
      lat: 40.70914,
      lng: -90.64975,
      city: 'Roseville',
      state: 'IL',
    },
    61474: {
      lat: 40.73419,
      lng: -90.39724,
      city: 'Saint Augustine',
      state: 'IL',
    },
    61475: { lat: 40.5969, lng: -90.7527, city: 'Sciota', state: 'IL' },
    61476: {
      lat: 41.09191,
      lng: -90.83259,
      city: 'Seaton',
      state: 'IL',
    },
    61477: {
      lat: 40.4945,
      lng: -90.31402,
      city: 'Smithfield',
      state: 'IL',
    },
    61478: {
      lat: 40.75633,
      lng: -90.76035,
      city: 'Smithshire',
      state: 'IL',
    },
    61479: {
      lat: 41.00192,
      lng: -89.63939,
      city: 'Speer',
      state: 'IL',
    },
    61480: {
      lat: 40.72323,
      lng: -90.91827,
      city: 'Stronghurst',
      state: 'IL',
    },
    61482: {
      lat: 40.39362,
      lng: -90.40766,
      city: 'Table Grove',
      state: 'IL',
    },
    61483: {
      lat: 41.08157,
      lng: -89.87186,
      city: 'Toulon',
      state: 'IL',
    },
    61484: {
      lat: 40.29696,
      lng: -90.44153,
      city: 'Vermont',
      state: 'IL',
    },
    61485: {
      lat: 41.01677,
      lng: -90.09764,
      city: 'Victoria',
      state: 'IL',
    },
    61486: {
      lat: 41.19609,
      lng: -90.57614,
      city: 'Viola',
      state: 'IL',
    },
    61488: {
      lat: 41.03004,
      lng: -90.32071,
      city: 'Wataga',
      state: 'IL',
    },
    61489: {
      lat: 40.93462,
      lng: -90.02664,
      city: 'Williamsfield',
      state: 'IL',
    },
    61490: {
      lat: 41.18263,
      lng: -90.26518,
      city: 'Woodhull',
      state: 'IL',
    },
    61491: {
      lat: 41.05982,
      lng: -89.72525,
      city: 'Wyoming',
      state: 'IL',
    },
    61501: {
      lat: 40.22797,
      lng: -90.31233,
      city: 'Astoria',
      state: 'IL',
    },
    61516: {
      lat: 40.85555,
      lng: -89.13197,
      city: 'Benson',
      state: 'IL',
    },
    61517: {
      lat: 40.823,
      lng: -89.85155,
      city: 'Brimfield',
      state: 'IL',
    },
    61519: {
      lat: 40.46463,
      lng: -90.09309,
      city: 'Bryant',
      state: 'IL',
    },
    61520: {
      lat: 40.54197,
      lng: -90.02156,
      city: 'Canton',
      state: 'IL',
    },
    61523: {
      lat: 40.91751,
      lng: -89.53562,
      city: 'Chillicothe',
      state: 'IL',
    },
    61524: {
      lat: 40.49125,
      lng: -90.03468,
      city: 'Dunfermline',
      state: 'IL',
    },
    61525: {
      lat: 40.84776,
      lng: -89.67047,
      city: 'Dunlap',
      state: 'IL',
    },
    61526: {
      lat: 40.92899,
      lng: -89.62599,
      city: 'Edelstein',
      state: 'IL',
    },
    61528: {
      lat: 40.7757,
      lng: -89.7227,
      city: 'Edwards',
      state: 'IL',
    },
    61529: {
      lat: 40.7809,
      lng: -89.93673,
      city: 'Elmwood',
      state: 'IL',
    },
    61530: {
      lat: 40.71146,
      lng: -89.26287,
      city: 'Eureka',
      state: 'IL',
    },
    61531: {
      lat: 40.68291,
      lng: -90.03482,
      city: 'Farmington',
      state: 'IL',
    },
    61532: {
      lat: 40.34851,
      lng: -89.80692,
      city: 'Forest City',
      state: 'IL',
    },
    61533: {
      lat: 40.58736,
      lng: -89.84029,
      city: 'Glasford',
      state: 'IL',
    },
    61534: {
      lat: 40.41499,
      lng: -89.66464,
      city: 'Green Valley',
      state: 'IL',
    },
    61535: {
      lat: 40.58578,
      lng: -89.52316,
      city: 'Groveland',
      state: 'IL',
    },
    61536: {
      lat: 40.69051,
      lng: -89.78688,
      city: 'Hanna City',
      state: 'IL',
    },
    61537: {
      lat: 41.11607,
      lng: -89.47035,
      city: 'Henry',
      state: 'IL',
    },
    61539: {
      lat: 40.55375,
      lng: -89.77561,
      city: 'Kingston Mines',
      state: 'IL',
    },
    61540: {
      lat: 41.01856,
      lng: -89.36867,
      city: 'Lacon',
      state: 'IL',
    },
    61541: {
      lat: 40.98039,
      lng: -89.23497,
      city: 'La Rose',
      state: 'IL',
    },
    61542: {
      lat: 40.38487,
      lng: -90.13402,
      city: 'Lewistown',
      state: 'IL',
    },
    61543: {
      lat: 40.42213,
      lng: -89.96842,
      city: 'Liverpool',
      state: 'IL',
    },
    61544: {
      lat: 40.69753,
      lng: -90.24273,
      city: 'London Mills',
      state: 'IL',
    },
    61545: {
      lat: 40.86837,
      lng: -89.35253,
      city: 'Lowpoint',
      state: 'IL',
    },
    61546: {
      lat: 40.45212,
      lng: -89.80172,
      city: 'Manito',
      state: 'IL',
    },
    61547: {
      lat: 40.60962,
      lng: -89.73847,
      city: 'Mapleton',
      state: 'IL',
    },
    61548: {
      lat: 40.80887,
      lng: -89.41311,
      city: 'Metamora',
      state: 'IL',
    },
    61550: {
      lat: 40.61054,
      lng: -89.44049,
      city: 'Morton',
      state: 'IL',
    },
    61552: {
      lat: 40.82133,
      lng: -89.56496,
      city: 'Mossville',
      state: 'IL',
    },
    61553: {
      lat: 40.62136,
      lng: -90.02686,
      city: 'Norris',
      state: 'IL',
    },
    61554: {
      lat: 40.54441,
      lng: -89.62181,
      city: 'Pekin',
      state: 'IL',
    },
    61559: {
      lat: 40.92101,
      lng: -89.77438,
      city: 'Princeville',
      state: 'IL',
    },
    61560: {
      lat: 41.18581,
      lng: -89.40077,
      city: 'Putnam',
      state: 'IL',
    },
    61561: {
      lat: 40.80582,
      lng: -89.20475,
      city: 'Roanoke',
      state: 'IL',
    },
    61562: { lat: 40.88102, lng: -89.50144, city: 'Rome', state: 'IL' },
    61563: {
      lat: 40.4931,
      lng: -90.05108,
      city: 'Saint David',
      state: 'IL',
    },
    61564: {
      lat: 40.49848,
      lng: -89.65624,
      city: 'South Pekin',
      state: 'IL',
    },
    61565: {
      lat: 41.032,
      lng: -89.50718,
      city: 'Sparland',
      state: 'IL',
    },
    61567: {
      lat: 40.35077,
      lng: -89.89899,
      city: 'Topeka',
      state: 'IL',
    },
    61568: {
      lat: 40.50484,
      lng: -89.47876,
      city: 'Tremont',
      state: 'IL',
    },
    61569: {
      lat: 40.67788,
      lng: -89.89324,
      city: 'Trivoli',
      state: 'IL',
    },
    61570: {
      lat: 40.91933,
      lng: -89.31578,
      city: 'Washburn',
      state: 'IL',
    },
    61571: {
      lat: 40.70506,
      lng: -89.42294,
      city: 'Washington',
      state: 'IL',
    },
    61572: {
      lat: 40.79768,
      lng: -90.0343,
      city: 'Yates City',
      state: 'IL',
    },
    61602: {
      lat: 40.68005,
      lng: -89.60395,
      city: 'Peoria',
      state: 'IL',
    },
    61603: {
      lat: 40.7122,
      lng: -89.57465,
      city: 'Peoria',
      state: 'IL',
    },
    61604: {
      lat: 40.70525,
      lng: -89.65297,
      city: 'Peoria',
      state: 'IL',
    },
    61605: {
      lat: 40.6765,
      lng: -89.63317,
      city: 'Peoria',
      state: 'IL',
    },
    61606: {
      lat: 40.70011,
      lng: -89.61187,
      city: 'Peoria',
      state: 'IL',
    },
    61607: {
      lat: 40.62895,
      lng: -89.68627,
      city: 'Peoria',
      state: 'IL',
    },
    61610: {
      lat: 40.64294,
      lng: -89.59882,
      city: 'Creve Coeur',
      state: 'IL',
    },
    61611: {
      lat: 40.71185,
      lng: -89.53469,
      city: 'East Peoria',
      state: 'IL',
    },
    61614: {
      lat: 40.75872,
      lng: -89.60486,
      city: 'Peoria',
      state: 'IL',
    },
    61615: {
      lat: 40.78088,
      lng: -89.63102,
      city: 'Peoria',
      state: 'IL',
    },
    61616: {
      lat: 40.74682,
      lng: -89.57195,
      city: 'Peoria Heights',
      state: 'IL',
    },
    61625: {
      lat: 40.6978,
      lng: -89.61455,
      city: 'Peoria',
      state: 'IL',
    },
    61701: {
      lat: 40.47697,
      lng: -88.99278,
      city: 'Bloomington',
      state: 'IL',
    },
    61704: {
      lat: 40.46951,
      lng: -88.94281,
      city: 'Bloomington',
      state: 'IL',
    },
    61705: {
      lat: 40.46099,
      lng: -88.99981,
      city: 'Bloomington',
      state: 'IL',
    },
    61720: {
      lat: 40.55228,
      lng: -88.50498,
      city: 'Anchor',
      state: 'IL',
    },
    61721: {
      lat: 40.35349,
      lng: -89.32238,
      city: 'Armington',
      state: 'IL',
    },
    61722: {
      lat: 40.42018,
      lng: -88.63049,
      city: 'Arrowsmith',
      state: 'IL',
    },
    61723: {
      lat: 40.26139,
      lng: -89.26703,
      city: 'Atlanta',
      state: 'IL',
    },
    61724: {
      lat: 40.33418,
      lng: -88.52654,
      city: 'Bellflower',
      state: 'IL',
    },
    61725: {
      lat: 40.61201,
      lng: -89.11717,
      city: 'Carlock',
      state: 'IL',
    },
    61726: {
      lat: 40.74013,
      lng: -88.70014,
      city: 'Chenoa',
      state: 'IL',
    },
    61727: {
      lat: 40.14701,
      lng: -88.95245,
      city: 'Clinton',
      state: 'IL',
    },
    61728: {
      lat: 40.57546,
      lng: -88.62539,
      city: 'Colfax',
      state: 'IL',
    },
    61729: {
      lat: 40.62799,
      lng: -89.22393,
      city: 'Congerville',
      state: 'IL',
    },
    61730: {
      lat: 40.53262,
      lng: -88.73437,
      city: 'Cooksville',
      state: 'IL',
    },
    61731: {
      lat: 40.61255,
      lng: -88.48386,
      city: 'Cropsey',
      state: 'IL',
    },
    61732: {
      lat: 40.53123,
      lng: -89.20646,
      city: 'Danvers',
      state: 'IL',
    },
    61733: {
      lat: 40.62421,
      lng: -89.33573,
      city: 'Deer Creek',
      state: 'IL',
    },
    61734: {
      lat: 40.37431,
      lng: -89.52549,
      city: 'Delavan',
      state: 'IL',
    },
    61735: {
      lat: 40.20561,
      lng: -88.81072,
      city: 'Dewitt',
      state: 'IL',
    },
    61736: {
      lat: 40.40335,
      lng: -88.83804,
      city: 'Downs',
      state: 'IL',
    },
    61737: {
      lat: 40.45646,
      lng: -88.73331,
      city: 'Ellsworth',
      state: 'IL',
    },
    61738: {
      lat: 40.74925,
      lng: -89.02821,
      city: 'El Paso',
      state: 'IL',
    },
    61739: {
      lat: 40.73342,
      lng: -88.51938,
      city: 'Fairbury',
      state: 'IL',
    },
    61740: {
      lat: 40.88779,
      lng: -88.86397,
      city: 'Flanagan',
      state: 'IL',
    },
    61741: {
      lat: 40.76045,
      lng: -88.40057,
      city: 'Forrest',
      state: 'IL',
    },
    61742: {
      lat: 40.63341,
      lng: -89.27322,
      city: 'Goodfield',
      state: 'IL',
    },
    61743: {
      lat: 40.87805,
      lng: -88.78142,
      city: 'Graymont',
      state: 'IL',
    },
    61744: {
      lat: 40.74377,
      lng: -88.88118,
      city: 'Gridley',
      state: 'IL',
    },
    61745: {
      lat: 40.32152,
      lng: -88.96684,
      city: 'Heyworth',
      state: 'IL',
    },
    61747: {
      lat: 40.4174,
      lng: -89.43295,
      city: 'Hopedale',
      state: 'IL',
    },
    61748: {
      lat: 40.61483,
      lng: -88.98898,
      city: 'Hudson',
      state: 'IL',
    },
    61749: {
      lat: 40.09209,
      lng: -89.10943,
      city: 'Kenney',
      state: 'IL',
    },
    61750: { lat: 40.12251, lng: -88.8557, city: 'Lane', state: 'IL' },
    61751: {
      lat: 40.21455,
      lng: -89.29926,
      city: 'Lawndale',
      state: 'IL',
    },
    61752: {
      lat: 40.33703,
      lng: -88.76168,
      city: 'Le Roy',
      state: 'IL',
    },
    61753: {
      lat: 40.63568,
      lng: -88.78883,
      city: 'Lexington',
      state: 'IL',
    },
    61754: {
      lat: 40.3287,
      lng: -89.15744,
      city: 'McLean',
      state: 'IL',
    },
    61755: {
      lat: 40.52661,
      lng: -89.33909,
      city: 'Mackinaw',
      state: 'IL',
    },
    61756: {
      lat: 40.03311,
      lng: -88.96368,
      city: 'Maroa',
      state: 'IL',
    },
    61759: {
      lat: 40.4347,
      lng: -89.32418,
      city: 'Minier',
      state: 'IL',
    },
    61760: {
      lat: 40.88713,
      lng: -89.03263,
      city: 'Minonk',
      state: 'IL',
    },
    61761: {
      lat: 40.52503,
      lng: -88.95152,
      city: 'Normal',
      state: 'IL',
    },
    61764: {
      lat: 40.88343,
      lng: -88.6263,
      city: 'Pontiac',
      state: 'IL',
    },
    61769: {
      lat: 40.88106,
      lng: -88.39804,
      city: 'Saunemin',
      state: 'IL',
    },
    61770: {
      lat: 40.43965,
      lng: -88.52457,
      city: 'Saybrook',
      state: 'IL',
    },
    61771: {
      lat: 40.70725,
      lng: -89.14184,
      city: 'Secor',
      state: 'IL',
    },
    61772: {
      lat: 40.386,
      lng: -89.06351,
      city: 'Shirley',
      state: 'IL',
    },
    61773: {
      lat: 40.57209,
      lng: -88.37605,
      city: 'Sibley',
      state: 'IL',
    },
    61774: {
      lat: 40.42945,
      lng: -89.20145,
      city: 'Stanford',
      state: 'IL',
    },
    61775: {
      lat: 40.64971,
      lng: -88.37069,
      city: 'Strawn',
      state: 'IL',
    },
    61776: {
      lat: 40.5713,
      lng: -88.86706,
      city: 'Towanda',
      state: 'IL',
    },
    61777: {
      lat: 40.243,
      lng: -88.93948,
      city: 'Wapella',
      state: 'IL',
    },
    61778: {
      lat: 40.22575,
      lng: -89.09104,
      city: 'Waynesville',
      state: 'IL',
    },
    61801: {
      lat: 40.10937,
      lng: -88.21175,
      city: 'Urbana',
      state: 'IL',
    },
    61802: {
      lat: 40.12288,
      lng: -88.14863,
      city: 'Urbana',
      state: 'IL',
    },
    61810: {
      lat: 39.91019,
      lng: -87.91837,
      city: 'Allerton',
      state: 'IL',
    },
    61811: {
      lat: 40.29531,
      lng: -87.62162,
      city: 'Alvin',
      state: 'IL',
    },
    61812: {
      lat: 40.25869,
      lng: -87.88989,
      city: 'Armstrong',
      state: 'IL',
    },
    61813: {
      lat: 39.90933,
      lng: -88.55796,
      city: 'Bement',
      state: 'IL',
    },
    61814: {
      lat: 40.23191,
      lng: -87.57018,
      city: 'Bismarck',
      state: 'IL',
    },
    61815: {
      lat: 40.11075,
      lng: -88.3679,
      city: 'Bondville',
      state: 'IL',
    },
    61816: {
      lat: 39.92444,
      lng: -88.00398,
      city: 'Broadlands',
      state: 'IL',
    },
    61817: {
      lat: 40.03088,
      lng: -87.71991,
      city: 'Catlin',
      state: 'IL',
    },
    61818: {
      lat: 39.88455,
      lng: -88.71798,
      city: 'Cerro Gordo',
      state: 'IL',
    },
    61820: {
      lat: 40.112,
      lng: -88.24423,
      city: 'Champaign',
      state: 'IL',
    },
    61821: {
      lat: 40.11041,
      lng: -88.27611,
      city: 'Champaign',
      state: 'IL',
    },
    61822: {
      lat: 40.13693,
      lng: -88.30453,
      city: 'Champaign',
      state: 'IL',
    },
    61830: {
      lat: 40.01122,
      lng: -88.72926,
      city: 'Cisco',
      state: 'IL',
    },
    61831: {
      lat: 40.22623,
      lng: -87.79705,
      city: 'Collison',
      state: 'IL',
    },
    61832: {
      lat: 40.13186,
      lng: -87.63256,
      city: 'Danville',
      state: 'IL',
    },
    61833: {
      lat: 40.09914,
      lng: -87.64718,
      city: 'Tilton',
      state: 'IL',
    },
    61834: {
      lat: 40.16428,
      lng: -87.64028,
      city: 'Danville',
      state: 'IL',
    },
    61839: {
      lat: 40.13184,
      lng: -88.63694,
      city: 'De Land',
      state: 'IL',
    },
    61840: {
      lat: 40.29663,
      lng: -88.30187,
      city: 'Dewey',
      state: 'IL',
    },
    61841: {
      lat: 40.02775,
      lng: -87.82459,
      city: 'Fairmount',
      state: 'IL',
    },
    61842: {
      lat: 40.23889,
      lng: -88.66411,
      city: 'Farmer City',
      state: 'IL',
    },
    61843: {
      lat: 40.31593,
      lng: -88.38053,
      city: 'Fisher',
      state: 'IL',
    },
    61844: {
      lat: 40.14431,
      lng: -87.8711,
      city: 'Fithian',
      state: 'IL',
    },
    61845: {
      lat: 40.37308,
      lng: -88.40076,
      city: 'Foosland',
      state: 'IL',
    },
    61846: {
      lat: 39.97601,
      lng: -87.61222,
      city: 'Georgetown',
      state: 'IL',
    },
    61847: {
      lat: 40.31148,
      lng: -88.01581,
      city: 'Gifford',
      state: 'IL',
    },
    61848: {
      lat: 40.30694,
      lng: -87.70096,
      city: 'Henning',
      state: 'IL',
    },
    61849: {
      lat: 40.01145,
      lng: -87.96185,
      city: 'Homer',
      state: 'IL',
    },
    61850: {
      lat: 39.9301,
      lng: -87.74346,
      city: 'Indianola',
      state: 'IL',
    },
    61851: {
      lat: 39.96507,
      lng: -88.43544,
      city: 'Ivesdale',
      state: 'IL',
    },
    61852: {
      lat: 39.89951,
      lng: -88.07277,
      city: 'Longview',
      state: 'IL',
    },
    61853: {
      lat: 40.22344,
      lng: -88.41555,
      city: 'Mahomet',
      state: 'IL',
    },
    61854: {
      lat: 40.20654,
      lng: -88.52129,
      city: 'Mansfield',
      state: 'IL',
    },
    61855: {
      lat: 39.92438,
      lng: -88.66056,
      city: 'Milmine',
      state: 'IL',
    },
    61856: {
      lat: 40.02838,
      lng: -88.58211,
      city: 'Monticello',
      state: 'IL',
    },
    61857: {
      lat: 40.11862,
      lng: -87.84337,
      city: 'Muncie',
      state: 'IL',
    },
    61858: {
      lat: 40.13166,
      lng: -87.76438,
      city: 'Oakwood',
      state: 'IL',
    },
    61859: {
      lat: 40.15065,
      lng: -87.96163,
      city: 'Ogden',
      state: 'IL',
    },
    61862: {
      lat: 40.30474,
      lng: -87.95103,
      city: 'Penfield',
      state: 'IL',
    },
    61863: {
      lat: 39.90575,
      lng: -88.28194,
      city: 'Pesotum',
      state: 'IL',
    },
    61864: {
      lat: 39.97989,
      lng: -88.14437,
      city: 'Philo',
      state: 'IL',
    },
    61865: {
      lat: 40.30362,
      lng: -87.80047,
      city: 'Potomac',
      state: 'IL',
    },
    61866: {
      lat: 40.31148,
      lng: -88.15837,
      city: 'Rantoul',
      state: 'IL',
    },
    61870: {
      lat: 39.90325,
      lng: -87.61562,
      city: 'Ridge Farm',
      state: 'IL',
    },
    61871: {
      lat: 40.19118,
      lng: -87.97925,
      city: 'Royal',
      state: 'IL',
    },
    61872: {
      lat: 39.93586,
      lng: -88.38018,
      city: 'Sadorus',
      state: 'IL',
    },
    61873: {
      lat: 40.14069,
      lng: -88.03817,
      city: 'Saint Joseph',
      state: 'IL',
    },
    61874: {
      lat: 40.05062,
      lng: -88.26078,
      city: 'Savoy',
      state: 'IL',
    },
    61875: {
      lat: 40.10421,
      lng: -88.42267,
      city: 'Seymour',
      state: 'IL',
    },
    61876: {
      lat: 39.90534,
      lng: -87.8398,
      city: 'Sidell',
      state: 'IL',
    },
    61877: {
      lat: 39.99456,
      lng: -88.07764,
      city: 'Sidney',
      state: 'IL',
    },
    61878: {
      lat: 40.23953,
      lng: -88.14945,
      city: 'Thomasboro',
      state: 'IL',
    },
    61880: {
      lat: 39.97482,
      lng: -88.2458,
      city: 'Tolono',
      state: 'IL',
    },
    61882: {
      lat: 40.11203,
      lng: -88.75969,
      city: 'Weldon',
      state: 'IL',
    },
    61883: {
      lat: 40.03494,
      lng: -87.63992,
      city: 'Westville',
      state: 'IL',
    },
    61884: {
      lat: 40.09115,
      lng: -88.48266,
      city: 'White Heath',
      state: 'IL',
    },
    61910: {
      lat: 39.67283,
      lng: -88.30035,
      city: 'Arcola',
      state: 'IL',
    },
    61911: {
      lat: 39.70452,
      lng: -88.47068,
      city: 'Arthur',
      state: 'IL',
    },
    61912: {
      lat: 39.52825,
      lng: -88.03357,
      city: 'Ashmore',
      state: 'IL',
    },
    61913: {
      lat: 39.82657,
      lng: -88.47156,
      city: 'Atwood',
      state: 'IL',
    },
    61914: {
      lat: 39.63409,
      lng: -88.76783,
      city: 'Bethany',
      state: 'IL',
    },
    61917: {
      lat: 39.71746,
      lng: -87.8941,
      city: 'Brocton',
      state: 'IL',
    },
    61919: {
      lat: 39.7789,
      lng: -88.13727,
      city: 'Camargo',
      state: 'IL',
    },
    61920: {
      lat: 39.50415,
      lng: -88.16448,
      city: 'Charleston',
      state: 'IL',
    },
    61924: {
      lat: 39.79269,
      lng: -87.6506,
      city: 'Chrisman',
      state: 'IL',
    },
    61925: {
      lat: 39.72444,
      lng: -88.83548,
      city: 'Dalton City',
      state: 'IL',
    },
    61928: { lat: 39.45524, lng: -88.50284, city: 'Gays', state: 'IL' },
    61929: {
      lat: 39.82338,
      lng: -88.62271,
      city: 'Hammond',
      state: 'IL',
    },
    61930: {
      lat: 39.67935,
      lng: -88.12899,
      city: 'Hindsboro',
      state: 'IL',
    },
    61931: {
      lat: 39.59067,
      lng: -88.35883,
      city: 'Humboldt',
      state: 'IL',
    },
    61932: { lat: 39.79968, lng: -87.87493, city: 'Hume', state: 'IL' },
    61933: {
      lat: 39.55099,
      lng: -87.91502,
      city: 'Kansas',
      state: 'IL',
    },
    61936: {
      lat: 39.80319,
      lng: -88.72684,
      city: 'La Place',
      state: 'IL',
    },
    61937: {
      lat: 39.73872,
      lng: -88.65542,
      city: 'Lovington',
      state: 'IL',
    },
    61938: {
      lat: 39.48004,
      lng: -88.37571,
      city: 'Mattoon',
      state: 'IL',
    },
    61940: {
      lat: 39.80395,
      lng: -87.81249,
      city: 'Metcalf',
      state: 'IL',
    },
    61941: {
      lat: 39.80457,
      lng: -88.07505,
      city: 'Murdock',
      state: 'IL',
    },
    61942: {
      lat: 39.81515,
      lng: -88.00818,
      city: 'Newman',
      state: 'IL',
    },
    61943: {
      lat: 39.6608,
      lng: -88.0248,
      city: 'Oakland',
      state: 'IL',
    },
    61944: {
      lat: 39.59969,
      lng: -87.70146,
      city: 'Paris',
      state: 'IL',
    },
    61949: {
      lat: 39.65753,
      lng: -87.85844,
      city: 'Redmon',
      state: 'IL',
    },
    61951: {
      lat: 39.59118,
      lng: -88.5865,
      city: 'Sullivan',
      state: 'IL',
    },
    61953: {
      lat: 39.79803,
      lng: -88.28991,
      city: 'Tuscola',
      state: 'IL',
    },
    61955: {
      lat: 39.58162,
      lng: -87.5875,
      city: 'Vermilion',
      state: 'IL',
    },
    61956: {
      lat: 39.8537,
      lng: -88.14433,
      city: 'Villa Grove',
      state: 'IL',
    },
    61957: {
      lat: 39.4397,
      lng: -88.60495,
      city: 'Windsor',
      state: 'IL',
    },
    62001: {
      lat: 38.87971,
      lng: -89.74562,
      city: 'Alhambra',
      state: 'IL',
    },
    62002: {
      lat: 38.93988,
      lng: -90.12829,
      city: 'Alton',
      state: 'IL',
    },
    62006: {
      lat: 39.06433,
      lng: -90.66714,
      city: 'Batchtown',
      state: 'IL',
    },
    62009: {
      lat: 39.09351,
      lng: -89.79856,
      city: 'Benld',
      state: 'IL',
    },
    62010: {
      lat: 38.91944,
      lng: -90.04789,
      city: 'Bethalto',
      state: 'IL',
    },
    62011: {
      lat: 39.12862,
      lng: -89.21755,
      city: 'Bingham',
      state: 'IL',
    },
    62012: {
      lat: 39.04225,
      lng: -90.15027,
      city: 'Brighton',
      state: 'IL',
    },
    62013: {
      lat: 38.96421,
      lng: -90.56518,
      city: 'Brussels',
      state: 'IL',
    },
    62014: {
      lat: 39.04313,
      lng: -89.94999,
      city: 'Bunker Hill',
      state: 'IL',
    },
    62015: {
      lat: 39.22011,
      lng: -89.54203,
      city: 'Butler',
      state: 'IL',
    },
    62016: {
      lat: 39.29517,
      lng: -90.41462,
      city: 'Carrollton',
      state: 'IL',
    },
    62017: {
      lat: 39.06597,
      lng: -89.36945,
      city: 'Coffeen',
      state: 'IL',
    },
    62018: {
      lat: 38.90847,
      lng: -90.08574,
      city: 'Cottage Hills',
      state: 'IL',
    },
    62019: {
      lat: 39.01598,
      lng: -89.45195,
      city: 'Donnellson',
      state: 'IL',
    },
    62021: { lat: 38.97797, lng: -89.981, city: 'Dorsey', state: 'IL' },
    62022: { lat: 39.00139, lng: -90.32972, city: 'Dow', state: 'IL' },
    62023: {
      lat: 39.10794,
      lng: -89.78278,
      city: 'Eagarville',
      state: 'IL',
    },
    62024: {
      lat: 38.88343,
      lng: -90.08879,
      city: 'East Alton',
      state: 'IL',
    },
    62025: {
      lat: 38.83004,
      lng: -89.93298,
      city: 'Edwardsville',
      state: 'IL',
    },
    62026: {
      lat: 38.79062,
      lng: -90.00394,
      city: 'Edwardsville',
      state: 'IL',
    },
    62027: {
      lat: 39.24686,
      lng: -90.55725,
      city: 'Eldred',
      state: 'IL',
    },
    62028: {
      lat: 38.95932,
      lng: -90.35353,
      city: 'Elsah',
      state: 'IL',
    },
    62030: {
      lat: 39.15463,
      lng: -90.16445,
      city: 'Fidelity',
      state: 'IL',
    },
    62031: {
      lat: 39.1123,
      lng: -90.53355,
      city: 'Fieldon',
      state: 'IL',
    },
    62032: {
      lat: 39.1158,
      lng: -89.28833,
      city: 'Fillmore',
      state: 'IL',
    },
    62033: {
      lat: 39.13561,
      lng: -89.84447,
      city: 'Gillespie',
      state: 'IL',
    },
    62034: {
      lat: 38.75639,
      lng: -89.96897,
      city: 'Glen Carbon',
      state: 'IL',
    },
    62035: {
      lat: 38.96375,
      lng: -90.23917,
      city: 'Godfrey',
      state: 'IL',
    },
    62036: {
      lat: 38.91866,
      lng: -90.59079,
      city: 'Golden Eagle',
      state: 'IL',
    },
    62037: {
      lat: 39.00826,
      lng: -90.4664,
      city: 'Grafton',
      state: 'IL',
    },
    62040: {
      lat: 38.71592,
      lng: -90.10078,
      city: 'Granite City',
      state: 'IL',
    },
    62044: {
      lat: 39.36618,
      lng: -90.20907,
      city: 'Greenfield',
      state: 'IL',
    },
    62045: {
      lat: 39.26297,
      lng: -90.70921,
      city: 'Hamburg',
      state: 'IL',
    },
    62046: {
      lat: 38.88935,
      lng: -89.84397,
      city: 'Hamel',
      state: 'IL',
    },
    62047: {
      lat: 39.13314,
      lng: -90.62273,
      city: 'Hardin',
      state: 'IL',
    },
    62048: {
      lat: 38.77004,
      lng: -90.13895,
      city: 'Hartford',
      state: 'IL',
    },
    62049: {
      lat: 39.1295,
      lng: -89.48572,
      city: 'Hillsboro',
      state: 'IL',
    },
    62050: {
      lat: 39.43734,
      lng: -90.55544,
      city: 'Hillview',
      state: 'IL',
    },
    62051: {
      lat: 39.20309,
      lng: -89.40244,
      city: 'Irving',
      state: 'IL',
    },
    62052: {
      lat: 39.11179,
      lng: -90.32004,
      city: 'Jerseyville',
      state: 'IL',
    },
    62053: {
      lat: 39.31572,
      lng: -90.65763,
      city: 'Kampsville',
      state: 'IL',
    },
    62054: { lat: 39.2012, lng: -90.34693, city: 'Kane', state: 'IL' },
    62056: {
      lat: 39.1765,
      lng: -89.67165,
      city: 'Litchfield',
      state: 'IL',
    },
    62058: {
      lat: 38.96793,
      lng: -89.76491,
      city: 'Livingston',
      state: 'IL',
    },
    62059: {
      lat: 38.65434,
      lng: -90.17097,
      city: 'Lovejoy',
      state: 'IL',
    },
    62060: {
      lat: 38.67664,
      lng: -90.14686,
      city: 'Madison',
      state: 'IL',
    },
    62061: {
      lat: 38.79077,
      lng: -89.77624,
      city: 'Marine',
      state: 'IL',
    },
    62062: {
      lat: 38.72719,
      lng: -89.96253,
      city: 'Maryville',
      state: 'IL',
    },
    62063: {
      lat: 39.1982,
      lng: -90.14836,
      city: 'Medora',
      state: 'IL',
    },
    62067: { lat: 38.92961, lng: -89.98837, city: 'Moro', state: 'IL' },
    62069: {
      lat: 39.08892,
      lng: -89.74044,
      city: 'Mount Olive',
      state: 'IL',
    },
    62074: {
      lat: 38.95594,
      lng: -89.68338,
      city: 'New Douglas',
      state: 'IL',
    },
    62075: {
      lat: 39.30143,
      lng: -89.29676,
      city: 'Nokomis',
      state: 'IL',
    },
    62076: {
      lat: 39.34186,
      lng: -89.23383,
      city: 'Ohlman',
      state: 'IL',
    },
    62077: {
      lat: 39.03133,
      lng: -89.51605,
      city: 'Panama',
      state: 'IL',
    },
    62078: {
      lat: 39.47984,
      lng: -90.48686,
      city: 'Patterson',
      state: 'IL',
    },
    62079: {
      lat: 39.11457,
      lng: -90.12642,
      city: 'Piasa',
      state: 'IL',
    },
    62080: {
      lat: 39.14115,
      lng: -89.10569,
      city: 'Ramsey',
      state: 'IL',
    },
    62081: {
      lat: 39.26262,
      lng: -90.23452,
      city: 'Rockbridge',
      state: 'IL',
    },
    62082: {
      lat: 39.49392,
      lng: -90.32157,
      city: 'Roodhouse',
      state: 'IL',
    },
    62083: {
      lat: 39.35122,
      lng: -89.19842,
      city: 'Rosamond',
      state: 'IL',
    },
    62084: {
      lat: 38.84082,
      lng: -90.06365,
      city: 'Roxana',
      state: 'IL',
    },
    62085: {
      lat: 39.07839,
      lng: -89.80308,
      city: 'Sawyerville',
      state: 'IL',
    },
    62086: {
      lat: 38.98302,
      lng: -89.58556,
      city: 'Sorento',
      state: 'IL',
    },
    62087: {
      lat: 38.81754,
      lng: -90.06767,
      city: 'South Roxana',
      state: 'IL',
    },
    62088: {
      lat: 39.01318,
      lng: -89.79764,
      city: 'Staunton',
      state: 'IL',
    },
    62089: {
      lat: 39.12111,
      lng: -89.48673,
      city: 'Taylor Springs',
      state: 'IL',
    },
    62090: {
      lat: 38.66925,
      lng: -90.17044,
      city: 'Venice',
      state: 'IL',
    },
    62091: {
      lat: 39.05594,
      lng: -89.61222,
      city: 'Walshville',
      state: 'IL',
    },
    62092: {
      lat: 39.41839,
      lng: -90.41291,
      city: 'White Hall',
      state: 'IL',
    },
    62093: {
      lat: 39.06858,
      lng: -89.84573,
      city: 'Wilsonville',
      state: 'IL',
    },
    62094: { lat: 39.2441, lng: -89.34871, city: 'Witt', state: 'IL' },
    62095: {
      lat: 38.86307,
      lng: -90.07545,
      city: 'Wood River',
      state: 'IL',
    },
    62097: {
      lat: 38.93719,
      lng: -89.85507,
      city: 'Worden',
      state: 'IL',
    },
    62098: {
      lat: 39.38377,
      lng: -90.29946,
      city: 'Wrights',
      state: 'IL',
    },
    62201: {
      lat: 38.63567,
      lng: -90.14162,
      city: 'East Saint Louis',
      state: 'IL',
    },
    62203: {
      lat: 38.60223,
      lng: -90.07534,
      city: 'East Saint Louis',
      state: 'IL',
    },
    62204: {
      lat: 38.63033,
      lng: -90.09642,
      city: 'East Saint Louis',
      state: 'IL',
    },
    62205: {
      lat: 38.60259,
      lng: -90.11469,
      city: 'East Saint Louis',
      state: 'IL',
    },
    62206: {
      lat: 38.56796,
      lng: -90.16981,
      city: 'East Saint Louis',
      state: 'IL',
    },
    62207: {
      lat: 38.58327,
      lng: -90.12544,
      city: 'East Saint Louis',
      state: 'IL',
    },
    62208: {
      lat: 38.59641,
      lng: -90.00528,
      city: 'Fairview Heights',
      state: 'IL',
    },
    62214: {
      lat: 38.37682,
      lng: -89.60529,
      city: 'Addieville',
      state: 'IL',
    },
    62215: {
      lat: 38.50672,
      lng: -89.60515,
      city: 'Albers',
      state: 'IL',
    },
    62216: {
      lat: 38.62559,
      lng: -89.60313,
      city: 'Aviston',
      state: 'IL',
    },
    62217: {
      lat: 38.1732,
      lng: -89.83954,
      city: 'Baldwin',
      state: 'IL',
    },
    62218: {
      lat: 38.52069,
      lng: -89.47738,
      city: 'Bartelso',
      state: 'IL',
    },
    62219: {
      lat: 38.60371,
      lng: -89.43698,
      city: 'Beckemeyer',
      state: 'IL',
    },
    62220: {
      lat: 38.47247,
      lng: -89.98704,
      city: 'Belleville',
      state: 'IL',
    },
    62221: {
      lat: 38.51187,
      lng: -89.89922,
      city: 'Belleville',
      state: 'IL',
    },
    62223: {
      lat: 38.53491,
      lng: -90.06027,
      city: 'Belleville',
      state: 'IL',
    },
    62225: {
      lat: 38.54065,
      lng: -89.85503,
      city: 'Scott Air Force Base',
      state: 'IL',
    },
    62226: {
      lat: 38.53634,
      lng: -90.00057,
      city: 'Belleville',
      state: 'IL',
    },
    62230: {
      lat: 38.63855,
      lng: -89.52742,
      city: 'Breese',
      state: 'IL',
    },
    62231: {
      lat: 38.64402,
      lng: -89.32642,
      city: 'Carlyle',
      state: 'IL',
    },
    62232: {
      lat: 38.63266,
      lng: -90.00509,
      city: 'Caseyville',
      state: 'IL',
    },
    62233: {
      lat: 37.93719,
      lng: -89.78842,
      city: 'Chester',
      state: 'IL',
    },
    62234: {
      lat: 38.68214,
      lng: -89.9815,
      city: 'Collinsville',
      state: 'IL',
    },
    62236: {
      lat: 38.43391,
      lng: -90.21141,
      city: 'Columbia',
      state: 'IL',
    },
    62237: {
      lat: 38.1916,
      lng: -89.57046,
      city: 'Coulterville',
      state: 'IL',
    },
    62238: {
      lat: 38.04499,
      lng: -89.5319,
      city: 'Cutler',
      state: 'IL',
    },
    62239: { lat: 38.528, lng: -90.1803, city: 'Dupo', state: 'IL' },
    62240: {
      lat: 38.52084,
      lng: -90.21011,
      city: 'East Carondelet',
      state: 'IL',
    },
    62241: {
      lat: 38.01474,
      lng: -89.88845,
      city: 'Ellis Grove',
      state: 'IL',
    },
    62242: {
      lat: 38.09257,
      lng: -89.94339,
      city: 'Evansville',
      state: 'IL',
    },
    62243: {
      lat: 38.41841,
      lng: -89.88978,
      city: 'Freeburg',
      state: 'IL',
    },
    62244: { lat: 38.19142, lng: -90.1965, city: 'Fults', state: 'IL' },
    62245: {
      lat: 38.54783,
      lng: -89.57446,
      city: 'Germantown',
      state: 'IL',
    },
    62246: {
      lat: 38.88834,
      lng: -89.42533,
      city: 'Greenville',
      state: 'IL',
    },
    62248: {
      lat: 38.30543,
      lng: -89.99341,
      city: 'Hecker',
      state: 'IL',
    },
    62249: {
      lat: 38.75469,
      lng: -89.66436,
      city: 'Highland',
      state: 'IL',
    },
    62250: {
      lat: 38.54042,
      lng: -89.26436,
      city: 'Hoffman',
      state: 'IL',
    },
    62253: {
      lat: 38.77554,
      lng: -89.29738,
      city: 'Keyesport',
      state: 'IL',
    },
    62254: {
      lat: 38.6098,
      lng: -89.81901,
      city: 'Lebanon',
      state: 'IL',
    },
    62255: {
      lat: 38.31244,
      lng: -89.77842,
      city: 'Lenzburg',
      state: 'IL',
    },
    62256: {
      lat: 38.22623,
      lng: -90.23152,
      city: 'Maeystown',
      state: 'IL',
    },
    62257: {
      lat: 38.28048,
      lng: -89.73262,
      city: 'Marissa',
      state: 'IL',
    },
    62258: {
      lat: 38.46014,
      lng: -89.77287,
      city: 'Mascoutah',
      state: 'IL',
    },
    62260: {
      lat: 38.46097,
      lng: -90.09891,
      city: 'Millstadt',
      state: 'IL',
    },
    62261: {
      lat: 38.01218,
      lng: -89.99774,
      city: 'Modoc',
      state: 'IL',
    },
    62262: {
      lat: 38.9371,
      lng: -89.26916,
      city: 'Mulberry Grove',
      state: 'IL',
    },
    62263: {
      lat: 38.33168,
      lng: -89.39706,
      city: 'Nashville',
      state: 'IL',
    },
    62264: {
      lat: 38.31612,
      lng: -89.90721,
      city: 'New Athens',
      state: 'IL',
    },
    62265: {
      lat: 38.50808,
      lng: -89.67797,
      city: 'New Baden',
      state: 'IL',
    },
    62266: {
      lat: 38.47481,
      lng: -89.64988,
      city: 'New Memphis',
      state: 'IL',
    },
    62268: {
      lat: 38.27208,
      lng: -89.52612,
      city: 'Oakdale',
      state: 'IL',
    },
    62269: {
      lat: 38.59844,
      lng: -89.91294,
      city: "O'Fallon",
      state: 'IL',
    },
    62271: {
      lat: 38.44028,
      lng: -89.52209,
      city: 'Okawville',
      state: 'IL',
    },
    62272: {
      lat: 38.00241,
      lng: -89.61562,
      city: 'Percy',
      state: 'IL',
    },
    62273: {
      lat: 38.78673,
      lng: -89.58389,
      city: 'Pierron',
      state: 'IL',
    },
    62274: {
      lat: 38.09312,
      lng: -89.40064,
      city: 'Pinckneyville',
      state: 'IL',
    },
    62275: {
      lat: 38.81507,
      lng: -89.54496,
      city: 'Pocahontas',
      state: 'IL',
    },
    62277: {
      lat: 38.09844,
      lng: -90.11081,
      city: 'Prairie Du Rocher',
      state: 'IL',
    },
    62278: {
      lat: 38.20528,
      lng: -89.99116,
      city: 'Red Bud',
      state: 'IL',
    },
    62279: {
      lat: 38.13796,
      lng: -90.13695,
      city: 'Renault',
      state: 'IL',
    },
    62280: {
      lat: 37.83179,
      lng: -89.65952,
      city: 'Rockwood',
      state: 'IL',
    },
    62281: {
      lat: 38.70405,
      lng: -89.78288,
      city: 'Saint Jacob',
      state: 'IL',
    },
    62282: {
      lat: 38.35644,
      lng: -89.71334,
      city: 'Saint Libory',
      state: 'IL',
    },
    62284: {
      lat: 38.86867,
      lng: -89.31107,
      city: 'Smithboro',
      state: 'IL',
    },
    62285: {
      lat: 38.38845,
      lng: -90.00773,
      city: 'Smithton',
      state: 'IL',
    },
    62286: {
      lat: 38.11756,
      lng: -89.7154,
      city: 'Sparta',
      state: 'IL',
    },
    62288: {
      lat: 37.99267,
      lng: -89.69106,
      city: 'Steeleville',
      state: 'IL',
    },
    62289: {
      lat: 38.59788,
      lng: -89.75096,
      city: 'Summerfield',
      state: 'IL',
    },
    62292: {
      lat: 38.21299,
      lng: -89.68261,
      city: 'Tilden',
      state: 'IL',
    },
    62293: {
      lat: 38.61838,
      lng: -89.69748,
      city: 'Trenton',
      state: 'IL',
    },
    62294: { lat: 38.70374, lng: -89.87242, city: 'Troy', state: 'IL' },
    62295: {
      lat: 38.27126,
      lng: -90.30895,
      city: 'Valmeyer',
      state: 'IL',
    },
    62297: {
      lat: 38.04938,
      lng: -89.81239,
      city: 'Walsh',
      state: 'IL',
    },
    62298: {
      lat: 38.30906,
      lng: -90.15132,
      city: 'Waterloo',
      state: 'IL',
    },
    62301: {
      lat: 39.93139,
      lng: -91.38741,
      city: 'Quincy',
      state: 'IL',
    },
    62305: {
      lat: 39.92915,
      lng: -91.34441,
      city: 'Quincy',
      state: 'IL',
    },
    62311: {
      lat: 40.22195,
      lng: -90.9212,
      city: 'Augusta',
      state: 'IL',
    },
    62312: {
      lat: 39.71035,
      lng: -91.02518,
      city: 'Barry',
      state: 'IL',
    },
    62313: { lat: 40.31782, lng: -91.2251, city: 'Basco', state: 'IL' },
    62314: {
      lat: 39.76967,
      lng: -90.89204,
      city: 'Baylis',
      state: 'IL',
    },
    62316: {
      lat: 40.23341,
      lng: -91.05495,
      city: 'Bowen',
      state: 'IL',
    },
    62319: {
      lat: 40.15293,
      lng: -90.72997,
      city: 'Camden',
      state: 'IL',
    },
    62320: {
      lat: 40.02343,
      lng: -91.08295,
      city: 'Camp Point',
      state: 'IL',
    },
    62321: {
      lat: 40.40948,
      lng: -91.10606,
      city: 'Carthage',
      state: 'IL',
    },
    62323: {
      lat: 39.81306,
      lng: -90.68607,
      city: 'Chambersburg',
      state: 'IL',
    },
    62324: {
      lat: 39.9901,
      lng: -90.96302,
      city: 'Clayton',
      state: 'IL',
    },
    62325: {
      lat: 40.05307,
      lng: -91.16564,
      city: 'Coatsburg',
      state: 'IL',
    },
    62326: {
      lat: 40.42248,
      lng: -90.81501,
      city: 'Colchester',
      state: 'IL',
    },
    62330: {
      lat: 40.57217,
      lng: -91.12187,
      city: 'Dallas City',
      state: 'IL',
    },
    62334: {
      lat: 40.39598,
      lng: -91.24912,
      city: 'Elvaston',
      state: 'IL',
    },
    62336: {
      lat: 40.46759,
      lng: -91.17333,
      city: 'Ferris',
      state: 'IL',
    },
    62338: {
      lat: 39.99309,
      lng: -91.23889,
      city: 'Fowler',
      state: 'IL',
    },
    62339: {
      lat: 40.12898,
      lng: -91.04196,
      city: 'Golden',
      state: 'IL',
    },
    62340: {
      lat: 39.7228,
      lng: -90.72588,
      city: 'Griggsville',
      state: 'IL',
    },
    62341: {
      lat: 40.42566,
      lng: -91.30555,
      city: 'Hamilton',
      state: 'IL',
    },
    62343: { lat: 39.70275, lng: -91.2418, city: 'Hull', state: 'IL' },
    62344: {
      lat: 40.1799,
      lng: -90.82497,
      city: 'Huntsville',
      state: 'IL',
    },
    62345: {
      lat: 39.70655,
      lng: -91.12758,
      city: 'Kinderhook',
      state: 'IL',
    },
    62346: {
      lat: 40.14367,
      lng: -90.93418,
      city: 'La Prairie',
      state: 'IL',
    },
    62347: {
      lat: 39.86648,
      lng: -91.08905,
      city: 'Liberty',
      state: 'IL',
    },
    62348: { lat: 40.18836, lng: -91.37126, city: 'Lima', state: 'IL' },
    62349: {
      lat: 40.16958,
      lng: -91.19986,
      city: 'Loraine',
      state: 'IL',
    },
    62351: {
      lat: 40.11374,
      lng: -91.26751,
      city: 'Mendon',
      state: 'IL',
    },
    62352: {
      lat: 39.56818,
      lng: -90.65455,
      city: 'Milton',
      state: 'IL',
    },
    62353: {
      lat: 39.97277,
      lng: -90.75735,
      city: 'Mount Sterling',
      state: 'IL',
    },
    62354: {
      lat: 40.52087,
      lng: -91.33305,
      city: 'Nauvoo',
      state: 'IL',
    },
    62355: { lat: 39.40963, lng: -90.77936, city: 'Nebo', state: 'IL' },
    62356: {
      lat: 39.60092,
      lng: -91.08508,
      city: 'New Canton',
      state: 'IL',
    },
    62357: {
      lat: 39.68671,
      lng: -90.86374,
      city: 'New Salem',
      state: 'IL',
    },
    62358: {
      lat: 40.58527,
      lng: -91.26113,
      city: 'Niota',
      state: 'IL',
    },
    62359: {
      lat: 40.03239,
      lng: -91.20851,
      city: 'Paloma',
      state: 'IL',
    },
    62360: {
      lat: 39.81731,
      lng: -91.26143,
      city: 'Payson',
      state: 'IL',
    },
    62361: {
      lat: 39.48734,
      lng: -90.64191,
      city: 'Pearl',
      state: 'IL',
    },
    62362: {
      lat: 39.77618,
      lng: -90.76429,
      city: 'Perry',
      state: 'IL',
    },
    62363: {
      lat: 39.59163,
      lng: -90.7784,
      city: 'Pittsfield',
      state: 'IL',
    },
    62365: {
      lat: 39.79559,
      lng: -91.16018,
      city: 'Plainville',
      state: 'IL',
    },
    62366: {
      lat: 39.46253,
      lng: -90.89402,
      city: 'Pleasant Hill',
      state: 'IL',
    },
    62367: {
      lat: 40.30325,
      lng: -90.86687,
      city: 'Plymouth',
      state: 'IL',
    },
    62370: {
      lat: 39.51818,
      lng: -90.99881,
      city: 'Rockport',
      state: 'IL',
    },
    62373: {
      lat: 40.23643,
      lng: -91.35863,
      city: 'Sutter',
      state: 'IL',
    },
    62374: {
      lat: 40.40765,
      lng: -90.91052,
      city: 'Tennessee',
      state: 'IL',
    },
    62375: {
      lat: 39.99782,
      lng: -90.87176,
      city: 'Timewell',
      state: 'IL',
    },
    62376: { lat: 40.1148, lng: -91.40662, city: 'Ursa', state: 'IL' },
    62378: {
      lat: 39.89602,
      lng: -90.63652,
      city: 'Versailles',
      state: 'IL',
    },
    62379: {
      lat: 40.26867,
      lng: -91.4061,
      city: 'Warsaw',
      state: 'IL',
    },
    62380: {
      lat: 40.24249,
      lng: -91.19174,
      city: 'West Point',
      state: 'IL',
    },
    62401: {
      lat: 39.11843,
      lng: -88.56467,
      city: 'Effingham',
      state: 'IL',
    },
    62410: {
      lat: 38.53254,
      lng: -87.72783,
      city: 'Allendale',
      state: 'IL',
    },
    62411: {
      lat: 39.06865,
      lng: -88.72936,
      city: 'Altamont',
      state: 'IL',
    },
    62413: {
      lat: 39.14081,
      lng: -87.84012,
      city: 'Annapolis',
      state: 'IL',
    },
    62414: {
      lat: 39.17277,
      lng: -88.82611,
      city: 'Beecher City',
      state: 'IL',
    },
    62417: {
      lat: 38.70304,
      lng: -87.76779,
      city: 'Bridgeport',
      state: 'IL',
    },
    62418: {
      lat: 39.02009,
      lng: -88.97463,
      city: 'Brownstown',
      state: 'IL',
    },
    62419: {
      lat: 38.62201,
      lng: -87.98425,
      city: 'Calhoun',
      state: 'IL',
    },
    62420: { lat: 39.29065, lng: -88.0005, city: 'Casey', state: 'IL' },
    62421: {
      lat: 38.75219,
      lng: -87.94345,
      city: 'Claremont',
      state: 'IL',
    },
    62422: {
      lat: 39.23327,
      lng: -88.88855,
      city: 'Cowden',
      state: 'IL',
    },
    62423: {
      lat: 39.46208,
      lng: -87.5819,
      city: 'Dennison',
      state: 'IL',
    },
    62424: {
      lat: 38.98784,
      lng: -88.42124,
      city: 'Dieterich',
      state: 'IL',
    },
    62425: {
      lat: 38.83363,
      lng: -88.10383,
      city: 'Dundas',
      state: 'IL',
    },
    62426: {
      lat: 38.8999,
      lng: -88.66639,
      city: 'Edgewood',
      state: 'IL',
    },
    62427: {
      lat: 38.87301,
      lng: -87.65891,
      city: 'Flat Rock',
      state: 'IL',
    },
    62428: {
      lat: 39.26396,
      lng: -88.1302,
      city: 'Greenup',
      state: 'IL',
    },
    62431: {
      lat: 39.22981,
      lng: -88.99382,
      city: 'Herrick',
      state: 'IL',
    },
    62432: {
      lat: 39.13353,
      lng: -88.14176,
      city: 'Hidalgo',
      state: 'IL',
    },
    62433: {
      lat: 39.11276,
      lng: -87.70514,
      city: 'Hutsonville',
      state: 'IL',
    },
    62434: {
      lat: 38.84217,
      lng: -88.3253,
      city: 'Ingraham',
      state: 'IL',
    },
    62436: {
      lat: 39.18247,
      lng: -88.25613,
      city: 'Jewett',
      state: 'IL',
    },
    62438: {
      lat: 39.32553,
      lng: -88.87005,
      city: 'Lakewood',
      state: 'IL',
    },
    62439: {
      lat: 38.74668,
      lng: -87.63754,
      city: 'Lawrenceville',
      state: 'IL',
    },
    62440: {
      lat: 39.39433,
      lng: -88.26325,
      city: 'Lerna',
      state: 'IL',
    },
    62441: {
      lat: 39.39392,
      lng: -87.69795,
      city: 'Marshall',
      state: 'IL',
    },
    62442: {
      lat: 39.31269,
      lng: -87.85602,
      city: 'Martinsville',
      state: 'IL',
    },
    62443: {
      lat: 38.95397,
      lng: -88.62902,
      city: 'Mason',
      state: 'IL',
    },
    62444: { lat: 39.27853, lng: -88.73474, city: 'Mode', state: 'IL' },
    62445: {
      lat: 39.16687,
      lng: -88.32322,
      city: 'Montrose',
      state: 'IL',
    },
    62446: {
      lat: 38.49115,
      lng: -88.21478,
      city: 'Mount Erie',
      state: 'IL',
    },
    62447: {
      lat: 39.31303,
      lng: -88.44456,
      city: 'Neoga',
      state: 'IL',
    },
    62448: {
      lat: 38.96741,
      lng: -88.17988,
      city: 'Newton',
      state: 'IL',
    },
    62449: {
      lat: 39.00419,
      lng: -87.91069,
      city: 'Oblong',
      state: 'IL',
    },
    62450: {
      lat: 38.71436,
      lng: -88.08777,
      city: 'Olney',
      state: 'IL',
    },
    62451: {
      lat: 38.99394,
      lng: -87.6066,
      city: 'Palestine',
      state: 'IL',
    },
    62452: {
      lat: 38.58715,
      lng: -88.01774,
      city: 'Parkersburg',
      state: 'IL',
    },
    62454: {
      lat: 38.99725,
      lng: -87.75767,
      city: 'Robinson',
      state: 'IL',
    },
    62458: {
      lat: 39.02531,
      lng: -88.85441,
      city: 'Saint Elmo',
      state: 'IL',
    },
    62459: {
      lat: 38.92997,
      lng: -88.0278,
      city: 'Sainte Marie',
      state: 'IL',
    },
    62460: {
      lat: 38.60661,
      lng: -87.69639,
      city: 'Saint Francisville',
      state: 'IL',
    },
    62461: {
      lat: 39.20067,
      lng: -88.6759,
      city: 'Shumway',
      state: 'IL',
    },
    62462: {
      lat: 39.23291,
      lng: -88.47066,
      city: 'Sigel',
      state: 'IL',
    },
    62463: {
      lat: 39.27519,
      lng: -88.59683,
      city: 'Stewardson',
      state: 'IL',
    },
    62464: { lat: 38.99793, lng: -87.82385, city: 'Stoy', state: 'IL' },
    62465: {
      lat: 39.34614,
      lng: -88.63827,
      city: 'Strasburg',
      state: 'IL',
    },
    62466: {
      lat: 38.72707,
      lng: -87.84957,
      city: 'Sumner',
      state: 'IL',
    },
    62467: {
      lat: 39.12809,
      lng: -88.43543,
      city: 'Teutopolis',
      state: 'IL',
    },
    62468: {
      lat: 39.27779,
      lng: -88.27155,
      city: 'Toledo',
      state: 'IL',
    },
    62469: {
      lat: 39.34843,
      lng: -88.33911,
      city: 'Trilla',
      state: 'IL',
    },
    62471: {
      lat: 38.95859,
      lng: -89.13257,
      city: 'Vandalia',
      state: 'IL',
    },
    62473: {
      lat: 39.01986,
      lng: -88.56556,
      city: 'Watson',
      state: 'IL',
    },
    62474: {
      lat: 39.43526,
      lng: -87.99462,
      city: 'Westfield',
      state: 'IL',
    },
    62475: {
      lat: 38.88441,
      lng: -88.04645,
      city: 'West Liberty',
      state: 'IL',
    },
    62476: {
      lat: 38.53412,
      lng: -88.00509,
      city: 'West Salem',
      state: 'IL',
    },
    62477: {
      lat: 39.23369,
      lng: -87.66024,
      city: 'West Union',
      state: 'IL',
    },
    62478: {
      lat: 39.17874,
      lng: -87.74367,
      city: 'West York',
      state: 'IL',
    },
    62479: {
      lat: 39.03731,
      lng: -88.31239,
      city: 'Wheeler',
      state: 'IL',
    },
    62480: {
      lat: 38.99864,
      lng: -88.00129,
      city: 'Willow Hill',
      state: 'IL',
    },
    62481: { lat: 39.12657, lng: -88.02293, city: 'Yale', state: 'IL' },
    62501: {
      lat: 39.92973,
      lng: -88.80528,
      city: 'Argenta',
      state: 'IL',
    },
    62510: {
      lat: 39.5315,
      lng: -89.02706,
      city: 'Assumption',
      state: 'IL',
    },
    62512: {
      lat: 40.14601,
      lng: -89.20312,
      city: 'Beason',
      state: 'IL',
    },
    62513: {
      lat: 39.72341,
      lng: -89.15296,
      city: 'Blue Mound',
      state: 'IL',
    },
    62514: {
      lat: 39.76753,
      lng: -89.04508,
      city: 'Boody',
      state: 'IL',
    },
    62515: {
      lat: 39.8561,
      lng: -89.36622,
      city: 'Buffalo',
      state: 'IL',
    },
    62517: {
      lat: 39.59365,
      lng: -89.43285,
      city: 'Bulpitt',
      state: 'IL',
    },
    62518: {
      lat: 40.05154,
      lng: -89.19037,
      city: 'Chestnut',
      state: 'IL',
    },
    62519: {
      lat: 39.92879,
      lng: -89.3953,
      city: 'Cornland',
      state: 'IL',
    },
    62520: {
      lat: 39.82185,
      lng: -89.45904,
      city: 'Dawson',
      state: 'IL',
    },
    62521: {
      lat: 39.81435,
      lng: -88.93086,
      city: 'Decatur',
      state: 'IL',
    },
    62522: {
      lat: 39.83008,
      lng: -89.04743,
      city: 'Decatur',
      state: 'IL',
    },
    62523: {
      lat: 39.84372,
      lng: -88.95289,
      city: 'Decatur',
      state: 'IL',
    },
    62526: {
      lat: 39.90322,
      lng: -88.9902,
      city: 'Decatur',
      state: 'IL',
    },
    62530: {
      lat: 39.56206,
      lng: -89.65626,
      city: 'Divernon',
      state: 'IL',
    },
    62531: {
      lat: 39.66754,
      lng: -89.37395,
      city: 'Edinburg',
      state: 'IL',
    },
    62532: {
      lat: 39.77633,
      lng: -88.97781,
      city: 'Elwin',
      state: 'IL',
    },
    62533: {
      lat: 39.44483,
      lng: -89.626,
      city: 'Farmersville',
      state: 'IL',
    },
    62534: {
      lat: 39.53519,
      lng: -88.79603,
      city: 'Findlay',
      state: 'IL',
    },
    62535: {
      lat: 39.92549,
      lng: -88.96604,
      city: 'Forsyth',
      state: 'IL',
    },
    62536: {
      lat: 39.63022,
      lng: -89.65023,
      city: 'Glenarm',
      state: 'IL',
    },
    62537: {
      lat: 39.85263,
      lng: -89.09324,
      city: 'Harristown',
      state: 'IL',
    },
    62538: {
      lat: 39.35631,
      lng: -89.52051,
      city: 'Harvel',
      state: 'IL',
    },
    62539: {
      lat: 39.86681,
      lng: -89.24914,
      city: 'Illiopolis',
      state: 'IL',
    },
    62540: {
      lat: 39.58496,
      lng: -89.41938,
      city: 'Kincaid',
      state: 'IL',
    },
    62541: {
      lat: 39.95657,
      lng: -89.37085,
      city: 'Lake Fork',
      state: 'IL',
    },
    62543: {
      lat: 39.97084,
      lng: -89.1518,
      city: 'Latham',
      state: 'IL',
    },
    62544: {
      lat: 39.70854,
      lng: -88.97375,
      city: 'Macon',
      state: 'IL',
    },
    62545: {
      lat: 39.7661,
      lng: -89.38737,
      city: 'Mechanicsburg',
      state: 'IL',
    },
    62546: {
      lat: 39.43042,
      lng: -89.44281,
      city: 'Morrisonville',
      state: 'IL',
    },
    62547: {
      lat: 39.76883,
      lng: -89.24929,
      city: 'Mount Auburn',
      state: 'IL',
    },
    62548: {
      lat: 39.97849,
      lng: -89.27988,
      city: 'Mount Pulaski',
      state: 'IL',
    },
    62549: {
      lat: 39.76985,
      lng: -88.8657,
      city: 'Mt Zion',
      state: 'IL',
    },
    62550: {
      lat: 39.61154,
      lng: -88.99283,
      city: 'Moweaqua',
      state: 'IL',
    },
    62551: {
      lat: 39.85357,
      lng: -89.15631,
      city: 'Niantic',
      state: 'IL',
    },
    62553: {
      lat: 39.27182,
      lng: -89.10509,
      city: 'Oconee',
      state: 'IL',
    },
    62554: {
      lat: 39.94976,
      lng: -88.87107,
      city: 'Oreana',
      state: 'IL',
    },
    62555: {
      lat: 39.46755,
      lng: -89.21982,
      city: 'Owaneco',
      state: 'IL',
    },
    62556: {
      lat: 39.46674,
      lng: -89.37049,
      city: 'Palmer',
      state: 'IL',
    },
    62557: { lat: 39.39974, lng: -89.10503, city: 'Pana', state: 'IL' },
    62558: {
      lat: 39.58967,
      lng: -89.54239,
      city: 'Pawnee',
      state: 'IL',
    },
    62560: {
      lat: 39.3005,
      lng: -89.60377,
      city: 'Raymond',
      state: 'IL',
    },
    62561: {
      lat: 39.86754,
      lng: -89.50498,
      city: 'Riverton',
      state: 'IL',
    },
    62563: {
      lat: 39.71675,
      lng: -89.50017,
      city: 'Rochester',
      state: 'IL',
    },
    62565: {
      lat: 39.41334,
      lng: -88.8026,
      city: 'Shelbyville',
      state: 'IL',
    },
    62567: {
      lat: 39.64668,
      lng: -89.19082,
      city: 'Stonington',
      state: 'IL',
    },
    62568: {
      lat: 39.55974,
      lng: -89.29016,
      city: 'Taylorville',
      state: 'IL',
    },
    62570: {
      lat: 39.58023,
      lng: -89.45107,
      city: 'Tovey',
      state: 'IL',
    },
    62571: {
      lat: 39.38155,
      lng: -88.95998,
      city: 'Tower Hill',
      state: 'IL',
    },
    62572: {
      lat: 39.35908,
      lng: -89.69778,
      city: 'Waggoner',
      state: 'IL',
    },
    62573: {
      lat: 39.94551,
      lng: -89.07264,
      city: 'Warrensburg',
      state: 'IL',
    },
    62601: {
      lat: 39.75032,
      lng: -90.03904,
      city: 'Alexander',
      state: 'IL',
    },
    62610: { lat: 39.5526, lng: -90.42708, city: 'Alsey', state: 'IL' },
    62611: {
      lat: 39.88493,
      lng: -90.39984,
      city: 'Arenzville',
      state: 'IL',
    },
    62612: {
      lat: 39.89864,
      lng: -90.0683,
      city: 'Ashland',
      state: 'IL',
    },
    62613: {
      lat: 39.99009,
      lng: -89.67036,
      city: 'Athens',
      state: 'IL',
    },
    62615: {
      lat: 39.58568,
      lng: -89.75406,
      city: 'Auburn',
      state: 'IL',
    },
    62617: { lat: 40.14948, lng: -90.18109, city: 'Bath', state: 'IL' },
    62618: {
      lat: 40.00534,
      lng: -90.39542,
      city: 'Beardstown',
      state: 'IL',
    },
    62621: {
      lat: 39.73174,
      lng: -90.5309,
      city: 'Bluffs',
      state: 'IL',
    },
    62622: {
      lat: 39.97786,
      lng: -90.34302,
      city: 'Bluff Springs',
      state: 'IL',
    },
    62624: {
      lat: 40.14993,
      lng: -90.34478,
      city: 'Browning',
      state: 'IL',
    },
    62625: {
      lat: 39.90424,
      lng: -89.70185,
      city: 'Cantrall',
      state: 'IL',
    },
    62626: {
      lat: 39.28459,
      lng: -89.88362,
      city: 'Carlinville',
      state: 'IL',
    },
    62627: {
      lat: 40.05425,
      lng: -90.12887,
      city: 'Chandlerville',
      state: 'IL',
    },
    62628: {
      lat: 39.7794,
      lng: -90.40199,
      city: 'Chapin',
      state: 'IL',
    },
    62629: {
      lat: 39.67858,
      lng: -89.71978,
      city: 'Chatham',
      state: 'IL',
    },
    62630: {
      lat: 39.26255,
      lng: -90.08315,
      city: 'Chesterfield',
      state: 'IL',
    },
    62631: {
      lat: 39.82531,
      lng: -90.36122,
      city: 'Concord',
      state: 'IL',
    },
    62633: {
      lat: 40.22577,
      lng: -89.8763,
      city: 'Easton',
      state: 'IL',
    },
    62634: {
      lat: 40.0166,
      lng: -89.45107,
      city: 'Elkhart',
      state: 'IL',
    },
    62635: {
      lat: 40.29653,
      lng: -89.46573,
      city: 'Emden',
      state: 'IL',
    },
    62638: {
      lat: 39.60714,
      lng: -90.08226,
      city: 'Franklin',
      state: 'IL',
    },
    62639: {
      lat: 40.0435,
      lng: -90.48753,
      city: 'Frederick',
      state: 'IL',
    },
    62640: {
      lat: 39.43183,
      lng: -89.80746,
      city: 'Girard',
      state: 'IL',
    },
    62642: {
      lat: 40.09022,
      lng: -89.71303,
      city: 'Greenview',
      state: 'IL',
    },
    62643: {
      lat: 40.24154,
      lng: -89.45774,
      city: 'Hartsburg',
      state: 'IL',
    },
    62644: {
      lat: 40.28553,
      lng: -90.06022,
      city: 'Havana',
      state: 'IL',
    },
    62649: {
      lat: 39.36451,
      lng: -90.0664,
      city: 'Hettick',
      state: 'IL',
    },
    62650: {
      lat: 39.73799,
      lng: -90.22027,
      city: 'Jacksonville',
      state: 'IL',
    },
    62655: {
      lat: 40.16523,
      lng: -89.99515,
      city: 'Kilbourne',
      state: 'IL',
    },
    62656: {
      lat: 40.13948,
      lng: -89.36622,
      city: 'Lincoln',
      state: 'IL',
    },
    62661: {
      lat: 39.66513,
      lng: -89.84057,
      city: 'Loami',
      state: 'IL',
    },
    62663: {
      lat: 39.54106,
      lng: -90.32748,
      city: 'Manchester',
      state: 'IL',
    },
    62664: {
      lat: 40.21106,
      lng: -89.72629,
      city: 'Mason City',
      state: 'IL',
    },
    62665: {
      lat: 39.81627,
      lng: -90.53462,
      city: 'Meredosia',
      state: 'IL',
    },
    62666: {
      lat: 40.08384,
      lng: -89.54364,
      city: 'Middletown',
      state: 'IL',
    },
    62667: {
      lat: 39.49372,
      lng: -89.99016,
      city: 'Modesto',
      state: 'IL',
    },
    62668: {
      lat: 39.57474,
      lng: -90.23667,
      city: 'Murrayville',
      state: 'IL',
    },
    62670: {
      lat: 39.74431,
      lng: -89.89601,
      city: 'New Berlin',
      state: 'IL',
    },
    62671: {
      lat: 40.16707,
      lng: -89.55755,
      city: 'New Holland',
      state: 'IL',
    },
    62672: {
      lat: 39.39802,
      lng: -89.80444,
      city: 'Nilwood',
      state: 'IL',
    },
    62673: {
      lat: 40.09586,
      lng: -89.9674,
      city: 'Oakford',
      state: 'IL',
    },
    62674: {
      lat: 39.43486,
      lng: -90.02952,
      city: 'Palmyra',
      state: 'IL',
    },
    62675: {
      lat: 40.02109,
      lng: -89.86146,
      city: 'Petersburg',
      state: 'IL',
    },
    62677: {
      lat: 39.85168,
      lng: -89.87694,
      city: 'Pleasant Plains',
      state: 'IL',
    },
    62681: {
      lat: 40.13841,
      lng: -90.55885,
      city: 'Rushville',
      state: 'IL',
    },
    62682: {
      lat: 40.29487,
      lng: -89.63541,
      city: 'San Jose',
      state: 'IL',
    },
    62684: {
      lat: 39.91019,
      lng: -89.58474,
      city: 'Sherman',
      state: 'IL',
    },
    62685: {
      lat: 39.14209,
      lng: -89.99689,
      city: 'Shipman',
      state: 'IL',
    },
    62688: {
      lat: 39.945,
      lng: -89.92415,
      city: 'Tallula',
      state: 'IL',
    },
    62689: {
      lat: 39.53402,
      lng: -89.76555,
      city: 'Thayer',
      state: 'IL',
    },
    62690: {
      lat: 39.50572,
      lng: -89.76662,
      city: 'Virden',
      state: 'IL',
    },
    62691: {
      lat: 39.95731,
      lng: -90.22246,
      city: 'Virginia',
      state: 'IL',
    },
    62692: {
      lat: 39.5832,
      lng: -89.93936,
      city: 'Waverly',
      state: 'IL',
    },
    62693: {
      lat: 39.95761,
      lng: -89.52041,
      city: 'Williamsville',
      state: 'IL',
    },
    62694: {
      lat: 39.62463,
      lng: -90.47274,
      city: 'Winchester',
      state: 'IL',
    },
    62695: {
      lat: 39.61943,
      lng: -90.22418,
      city: 'Woodson',
      state: 'IL',
    },
    62701: {
      lat: 39.80063,
      lng: -89.64902,
      city: 'Springfield',
      state: 'IL',
    },
    62702: {
      lat: 39.82377,
      lng: -89.64286,
      city: 'Springfield',
      state: 'IL',
    },
    62703: {
      lat: 39.76276,
      lng: -89.63106,
      city: 'Springfield',
      state: 'IL',
    },
    62704: {
      lat: 39.77358,
      lng: -89.68503,
      city: 'Springfield',
      state: 'IL',
    },
    62706: {
      lat: 39.79789,
      lng: -89.6554,
      city: 'Springfield',
      state: 'IL',
    },
    62707: {
      lat: 39.85506,
      lng: -89.64989,
      city: 'Springfield',
      state: 'IL',
    },
    62711: {
      lat: 39.76662,
      lng: -89.73195,
      city: 'Springfield',
      state: 'IL',
    },
    62712: {
      lat: 39.74883,
      lng: -89.58344,
      city: 'Springfield',
      state: 'IL',
    },
    62801: {
      lat: 38.51097,
      lng: -89.1412,
      city: 'Centralia',
      state: 'IL',
    },
    62803: {
      lat: 38.45461,
      lng: -89.29822,
      city: 'Hoyleton',
      state: 'IL',
    },
    62806: {
      lat: 38.38405,
      lng: -88.07492,
      city: 'Albion',
      state: 'IL',
    },
    62807: { lat: 38.74767, lng: -88.93412, city: 'Alma', state: 'IL' },
    62808: {
      lat: 38.30328,
      lng: -89.1884,
      city: 'Ashley',
      state: 'IL',
    },
    62809: {
      lat: 38.28023,
      lng: -88.33756,
      city: 'Barnhill',
      state: 'IL',
    },
    62810: {
      lat: 38.20515,
      lng: -88.74388,
      city: 'Belle Rive',
      state: 'IL',
    },
    62811: {
      lat: 38.38739,
      lng: -87.90361,
      city: 'Bellmont',
      state: 'IL',
    },
    62812: {
      lat: 38.00487,
      lng: -88.92249,
      city: 'Benton',
      state: 'IL',
    },
    62814: {
      lat: 38.37641,
      lng: -88.73054,
      city: 'Bluford',
      state: 'IL',
    },
    62815: {
      lat: 38.45707,
      lng: -87.98698,
      city: 'Bone Gap',
      state: 'IL',
    },
    62816: {
      lat: 38.18156,
      lng: -88.92473,
      city: 'Bonnie',
      state: 'IL',
    },
    62817: {
      lat: 37.9545,
      lng: -88.47762,
      city: 'Broughton',
      state: 'IL',
    },
    62818: {
      lat: 38.37772,
      lng: -87.96749,
      city: 'Browns',
      state: 'IL',
    },
    62819: {
      lat: 37.97507,
      lng: -89.00283,
      city: 'Buckner',
      state: 'IL',
    },
    62820: {
      lat: 38.24873,
      lng: -88.23561,
      city: 'Burnt Prairie',
      state: 'IL',
    },
    62821: {
      lat: 38.07301,
      lng: -88.13357,
      city: 'Carmi',
      state: 'IL',
    },
    62822: {
      lat: 37.97473,
      lng: -89.05358,
      city: 'Christopher',
      state: 'IL',
    },
    62823: {
      lat: 38.51864,
      lng: -88.43816,
      city: 'Cisne',
      state: 'IL',
    },
    62824: {
      lat: 38.67076,
      lng: -88.34686,
      city: 'Clay City',
      state: 'IL',
    },
    62825: {
      lat: 37.99832,
      lng: -89.06474,
      city: 'Coello',
      state: 'IL',
    },
    62827: {
      lat: 38.16573,
      lng: -88.04157,
      city: 'Crossville',
      state: 'IL',
    },
    62828: {
      lat: 38.19536,
      lng: -88.60864,
      city: 'Dahlgren',
      state: 'IL',
    },
    62829: { lat: 37.99956, lng: -88.49061, city: 'Dale', state: 'IL' },
    62830: { lat: 38.44559, lng: -88.96424, city: 'Dix', state: 'IL' },
    62831: {
      lat: 38.23042,
      lng: -89.22167,
      city: 'Du Bois',
      state: 'IL',
    },
    62832: {
      lat: 38.01001,
      lng: -89.24969,
      city: 'Du Quoin',
      state: 'IL',
    },
    62833: {
      lat: 38.34071,
      lng: -88.16355,
      city: 'Ellery',
      state: 'IL',
    },
    62835: {
      lat: 38.10573,
      lng: -88.32406,
      city: 'Enfield',
      state: 'IL',
    },
    62836: { lat: 38.0973, lng: -88.80568, city: 'Ewing', state: 'IL' },
    62837: {
      lat: 38.36608,
      lng: -88.35999,
      city: 'Fairfield',
      state: 'IL',
    },
    62838: {
      lat: 38.86855,
      lng: -88.74889,
      city: 'Farina',
      state: 'IL',
    },
    62839: {
      lat: 38.66403,
      lng: -88.49689,
      city: 'Flora',
      state: 'IL',
    },
    62841: {
      lat: 37.8588,
      lng: -88.99639,
      city: 'Freeman Spur',
      state: 'IL',
    },
    62842: { lat: 38.47038, lng: -88.38007, city: 'Geff', state: 'IL' },
    62843: {
      lat: 38.3828,
      lng: -88.19893,
      city: 'Golden Gate',
      state: 'IL',
    },
    62844: {
      lat: 38.25525,
      lng: -88.05018,
      city: 'Grayville',
      state: 'IL',
    },
    62846: { lat: 38.15042, lng: -88.85485, city: 'Ina', state: 'IL' },
    62848: {
      lat: 38.43794,
      lng: -89.16665,
      city: 'Irvington',
      state: 'IL',
    },
    62849: { lat: 38.58803, lng: -88.77221, city: 'Iuka', state: 'IL' },
    62850: {
      lat: 38.49552,
      lng: -88.58841,
      city: 'Johnsonville',
      state: 'IL',
    },
    62851: {
      lat: 38.42185,
      lng: -88.66237,
      city: 'Keenes',
      state: 'IL',
    },
    62852: {
      lat: 38.34992,
      lng: -87.86676,
      city: 'Keensburg',
      state: 'IL',
    },
    62853: { lat: 38.51734, lng: -88.91571, city: 'Kell', state: 'IL' },
    62854: {
      lat: 38.76458,
      lng: -88.81172,
      city: 'Kinmundy',
      state: 'IL',
    },
    62856: {
      lat: 37.95246,
      lng: -88.83549,
      city: 'Logan',
      state: 'IL',
    },
    62858: {
      lat: 38.81271,
      lng: -88.49353,
      city: 'Louisville',
      state: 'IL',
    },
    62859: {
      lat: 38.07094,
      lng: -88.54289,
      city: 'McLeansboro',
      state: 'IL',
    },
    62860: {
      lat: 38.04022,
      lng: -88.72921,
      city: 'Macedonia',
      state: 'IL',
    },
    62861: {
      lat: 38.03543,
      lng: -88.04569,
      city: 'Maunie',
      state: 'IL',
    },
    62862: {
      lat: 38.2166,
      lng: -88.29685,
      city: 'Mill Shoals',
      state: 'IL',
    },
    62863: {
      lat: 38.41575,
      lng: -87.85526,
      city: 'Mount Carmel',
      state: 'IL',
    },
    62864: {
      lat: 38.33041,
      lng: -88.90272,
      city: 'Mount Vernon',
      state: 'IL',
    },
    62865: {
      lat: 37.97528,
      lng: -89.08351,
      city: 'Mulkeytown',
      state: 'IL',
    },
    62867: {
      lat: 37.90503,
      lng: -88.10091,
      city: 'New Haven',
      state: 'IL',
    },
    62868: {
      lat: 38.68073,
      lng: -88.22939,
      city: 'Noble',
      state: 'IL',
    },
    62869: {
      lat: 37.96608,
      lng: -88.28336,
      city: 'Norris City',
      state: 'IL',
    },
    62870: { lat: 38.63463, lng: -89.05063, city: 'Odin', state: 'IL' },
    62871: { lat: 37.8757, lng: -88.26499, city: 'Omaha', state: 'IL' },
    62872: {
      lat: 38.26776,
      lng: -88.8032,
      city: 'Opdyke',
      state: 'IL',
    },
    62874: {
      lat: 37.91767,
      lng: -88.97802,
      city: 'Orient',
      state: 'IL',
    },
    62875: {
      lat: 38.75789,
      lng: -89.12482,
      city: 'Patoka',
      state: 'IL',
    },
    62876: {
      lat: 38.27713,
      lng: -89.19487,
      city: 'Radom',
      state: 'IL',
    },
    62877: {
      lat: 38.38983,
      lng: -89.2057,
      city: 'Richview',
      state: 'IL',
    },
    62878: {
      lat: 38.57718,
      lng: -88.49378,
      city: 'Rinard',
      state: 'IL',
    },
    62879: {
      lat: 38.7651,
      lng: -88.35907,
      city: 'Sailor Springs',
      state: 'IL',
    },
    62880: {
      lat: 38.86751,
      lng: -88.88593,
      city: 'Saint Peter',
      state: 'IL',
    },
    62881: { lat: 38.63544, lng: -88.9288, city: 'Salem', state: 'IL' },
    62882: {
      lat: 38.63474,
      lng: -89.1197,
      city: 'Sandoval',
      state: 'IL',
    },
    62883: {
      lat: 38.162,
      lng: -89.11192,
      city: 'Scheller',
      state: 'IL',
    },
    62884: {
      lat: 38.08482,
      lng: -89.04196,
      city: 'Sesser',
      state: 'IL',
    },
    62885: {
      lat: 38.86289,
      lng: -89.06286,
      city: 'Shobonier',
      state: 'IL',
    },
    62886: { lat: 38.37486, lng: -88.53572, city: 'Sims', state: 'IL' },
    62887: {
      lat: 38.18478,
      lng: -88.36871,
      city: 'Springerton',
      state: 'IL',
    },
    62888: {
      lat: 38.12665,
      lng: -89.22828,
      city: 'Tamaroa',
      state: 'IL',
    },
    62889: {
      lat: 38.46011,
      lng: -88.82893,
      city: 'Texico',
      state: 'IL',
    },
    62890: {
      lat: 37.90574,
      lng: -88.73944,
      city: 'Thompsonville',
      state: 'IL',
    },
    62891: {
      lat: 38.01569,
      lng: -89.04067,
      city: 'Valier',
      state: 'IL',
    },
    62892: {
      lat: 38.80623,
      lng: -89.0771,
      city: 'Vernon',
      state: 'IL',
    },
    62893: {
      lat: 38.46776,
      lng: -89.03087,
      city: 'Walnut Hill',
      state: 'IL',
    },
    62894: {
      lat: 38.20952,
      lng: -89.03931,
      city: 'Waltonville',
      state: 'IL',
    },
    62895: {
      lat: 38.30364,
      lng: -88.54702,
      city: 'Wayne City',
      state: 'IL',
    },
    62896: {
      lat: 37.89287,
      lng: -88.91183,
      city: 'West Frankfort',
      state: 'IL',
    },
    62897: {
      lat: 38.09763,
      lng: -88.91504,
      city: 'Whittington',
      state: 'IL',
    },
    62898: {
      lat: 38.35041,
      lng: -89.06209,
      city: 'Woodlawn',
      state: 'IL',
    },
    62899: {
      lat: 38.63032,
      lng: -88.64367,
      city: 'Xenia',
      state: 'IL',
    },
    62901: {
      lat: 37.74003,
      lng: -89.20946,
      city: 'Carbondale',
      state: 'IL',
    },
    62902: {
      lat: 37.67199,
      lng: -89.11509,
      city: 'Carbondale',
      state: 'IL',
    },
    62903: {
      lat: 37.67162,
      lng: -89.27568,
      city: 'Carbondale',
      state: 'IL',
    },
    62905: {
      lat: 37.57381,
      lng: -89.35867,
      city: 'Alto Pass',
      state: 'IL',
    },
    62906: { lat: 37.45746, lng: -89.18899, city: 'Anna', state: 'IL' },
    62907: { lat: 37.86503, lng: -89.50009, city: 'Ava', state: 'IL' },
    62908: {
      lat: 37.30827,
      lng: -88.86603,
      city: 'Belknap',
      state: 'IL',
    },
    62910: {
      lat: 37.1562,
      lng: -88.53075,
      city: 'Brookport',
      state: 'IL',
    },
    62912: {
      lat: 37.47598,
      lng: -89.0388,
      city: 'Buncombe',
      state: 'IL',
    },
    62914: {
      lat: 37.04076,
      lng: -89.20329,
      city: 'Cairo',
      state: 'IL',
    },
    62915: {
      lat: 37.78413,
      lng: -89.12238,
      city: 'Cambria',
      state: 'IL',
    },
    62916: {
      lat: 37.93722,
      lng: -89.56947,
      city: 'Campbell Hill',
      state: 'IL',
    },
    62917: {
      lat: 37.69752,
      lng: -88.64882,
      city: 'Carrier Mills',
      state: 'IL',
    },
    62918: {
      lat: 37.78561,
      lng: -89.08641,
      city: 'Carterville',
      state: 'IL',
    },
    62919: {
      lat: 37.52057,
      lng: -88.14653,
      city: 'Cave In Rock',
      state: 'IL',
    },
    62920: {
      lat: 37.55095,
      lng: -89.23208,
      city: 'Cobden',
      state: 'IL',
    },
    62921: { lat: 37.80217, lng: -89.08215, city: 'Colp', state: 'IL' },
    62922: {
      lat: 37.6215,
      lng: -88.81531,
      city: 'Creal Springs',
      state: 'IL',
    },
    62923: {
      lat: 37.33709,
      lng: -89.03671,
      city: 'Cypress',
      state: 'IL',
    },
    62924: {
      lat: 37.82112,
      lng: -89.20448,
      city: 'De Soto',
      state: 'IL',
    },
    62926: {
      lat: 37.3692,
      lng: -89.13589,
      city: 'Dongola',
      state: 'IL',
    },
    62927: {
      lat: 37.94129,
      lng: -89.24837,
      city: 'Dowell',
      state: 'IL',
    },
    62928: {
      lat: 37.47959,
      lng: -88.5668,
      city: 'Eddyville',
      state: 'IL',
    },
    62930: {
      lat: 37.84379,
      lng: -88.44139,
      city: 'Eldorado',
      state: 'IL',
    },
    62931: {
      lat: 37.52813,
      lng: -88.27953,
      city: 'Elizabethtown',
      state: 'IL',
    },
    62932: {
      lat: 37.89878,
      lng: -89.21048,
      city: 'Elkville',
      state: 'IL',
    },
    62933: {
      lat: 37.77363,
      lng: -89.02003,
      city: 'Energy',
      state: 'IL',
    },
    62934: {
      lat: 37.70876,
      lng: -88.36175,
      city: 'Equality',
      state: 'IL',
    },
    62935: {
      lat: 37.84281,
      lng: -88.63194,
      city: 'Galatia',
      state: 'IL',
    },
    62938: {
      lat: 37.38249,
      lng: -88.54676,
      city: 'Golconda',
      state: 'IL',
    },
    62939: {
      lat: 37.5489,
      lng: -88.98088,
      city: 'Goreville',
      state: 'IL',
    },
    62940: {
      lat: 37.7292,
      lng: -89.46279,
      city: 'Gorham',
      state: 'IL',
    },
    62941: {
      lat: 37.24142,
      lng: -88.97186,
      city: 'Grand Chain',
      state: 'IL',
    },
    62942: {
      lat: 37.6348,
      lng: -89.47681,
      city: 'Grand Tower',
      state: 'IL',
    },
    62943: {
      lat: 37.35586,
      lng: -88.74669,
      city: 'Grantsburg',
      state: 'IL',
    },
    62946: {
      lat: 37.7028,
      lng: -88.52887,
      city: 'Harrisburg',
      state: 'IL',
    },
    62947: { lat: 37.55676, lng: -88.4348, city: 'Herod', state: 'IL' },
    62948: {
      lat: 37.8204,
      lng: -89.02542,
      city: 'Herrin',
      state: 'IL',
    },
    62949: { lat: 37.8278, lng: -89.14409, city: 'Hurst', state: 'IL' },
    62950: {
      lat: 37.75184,
      lng: -89.55573,
      city: 'Jacob',
      state: 'IL',
    },
    62951: {
      lat: 37.82637,
      lng: -88.92609,
      city: 'Johnston City',
      state: 'IL',
    },
    62952: {
      lat: 37.42065,
      lng: -89.33187,
      city: 'Jonesboro',
      state: 'IL',
    },
    62953: {
      lat: 37.20684,
      lng: -88.84533,
      city: 'Joppa',
      state: 'IL',
    },
    62954: {
      lat: 37.69462,
      lng: -88.27428,
      city: 'Junction',
      state: 'IL',
    },
    62956: {
      lat: 37.27805,
      lng: -88.93127,
      city: 'Karnak',
      state: 'IL',
    },
    62957: {
      lat: 37.31355,
      lng: -89.42669,
      city: 'McClure',
      state: 'IL',
    },
    62958: {
      lat: 37.60389,
      lng: -89.14917,
      city: 'Makanda',
      state: 'IL',
    },
    62959: {
      lat: 37.71442,
      lng: -88.91463,
      city: 'Marion',
      state: 'IL',
    },
    62960: {
      lat: 37.22515,
      lng: -88.71097,
      city: 'Metropolis',
      state: 'IL',
    },
    62961: {
      lat: 37.34063,
      lng: -89.25378,
      city: 'Millcreek',
      state: 'IL',
    },
    62962: {
      lat: 37.07431,
      lng: -89.33537,
      city: 'Miller City',
      state: 'IL',
    },
    62963: {
      lat: 37.0933,
      lng: -89.16263,
      city: 'Mound City',
      state: 'IL',
    },
    62964: {
      lat: 37.12705,
      lng: -89.21526,
      city: 'Mounds',
      state: 'IL',
    },
    62965: { lat: 37.76753, lng: -88.5177, city: 'Muddy', state: 'IL' },
    62966: {
      lat: 37.7693,
      lng: -89.35198,
      city: 'Murphysboro',
      state: 'IL',
    },
    62967: {
      lat: 37.58092,
      lng: -88.75089,
      city: 'New Burnside',
      state: 'IL',
    },
    62969: {
      lat: 37.15181,
      lng: -89.34674,
      city: 'Olive Branch',
      state: 'IL',
    },
    62970: {
      lat: 37.21362,
      lng: -89.10119,
      city: 'Olmsted',
      state: 'IL',
    },
    62972: { lat: 37.5419, lng: -88.8162, city: 'Ozark', state: 'IL' },
    62974: {
      lat: 37.78158,
      lng: -88.79094,
      city: 'Pittsburg',
      state: 'IL',
    },
    62975: {
      lat: 37.64307,
      lng: -89.37274,
      city: 'Pomona',
      state: 'IL',
    },
    62976: {
      lat: 37.21954,
      lng: -89.2095,
      city: 'Pulaski',
      state: 'IL',
    },
    62977: {
      lat: 37.85179,
      lng: -88.54384,
      city: 'Raleigh',
      state: 'IL',
    },
    62979: {
      lat: 37.80813,
      lng: -88.2365,
      city: 'Ridgway',
      state: 'IL',
    },
    62982: {
      lat: 37.42808,
      lng: -88.35967,
      city: 'Rosiclare',
      state: 'IL',
    },
    62983: {
      lat: 37.90208,
      lng: -89.10883,
      city: 'Royalton',
      state: 'IL',
    },
    62984: {
      lat: 37.73152,
      lng: -88.14903,
      city: 'Shawneetown',
      state: 'IL',
    },
    62985: {
      lat: 37.46768,
      lng: -88.68933,
      city: 'Simpson',
      state: 'IL',
    },
    62987: {
      lat: 37.61114,
      lng: -88.65695,
      city: 'Stonefort',
      state: 'IL',
    },
    62988: {
      lat: 37.23184,
      lng: -89.29629,
      city: 'Tamms',
      state: 'IL',
    },
    62990: {
      lat: 37.22924,
      lng: -89.40493,
      city: 'Thebes',
      state: 'IL',
    },
    62992: { lat: 37.27855, lng: -89.1556, city: 'Ullin', state: 'IL' },
    62994: {
      lat: 37.90593,
      lng: -89.34664,
      city: 'Vergennes',
      state: 'IL',
    },
    62995: {
      lat: 37.41945,
      lng: -88.88244,
      city: 'Vienna',
      state: 'IL',
    },
    62996: {
      lat: 37.16624,
      lng: -89.15984,
      city: 'Villa Ridge',
      state: 'IL',
    },
    62997: {
      lat: 37.98357,
      lng: -89.59013,
      city: 'Willisville',
      state: 'IL',
    },
    62998: {
      lat: 37.51649,
      lng: -89.44698,
      city: 'Wolf Lake',
      state: 'IL',
    },
    62999: {
      lat: 37.89122,
      lng: -89.05275,
      city: 'Zeigler',
      state: 'IL',
    },
    63005: {
      lat: 38.64306,
      lng: -90.64982,
      city: 'Chesterfield',
      state: 'MO',
    },
    63010: {
      lat: 38.42964,
      lng: -90.3932,
      city: 'Arnold',
      state: 'MO',
    },
    63011: {
      lat: 38.60337,
      lng: -90.55847,
      city: 'Ballwin',
      state: 'MO',
    },
    63012: {
      lat: 38.33532,
      lng: -90.44832,
      city: 'Barnhart',
      state: 'MO',
    },
    63013: {
      lat: 38.41776,
      lng: -91.15182,
      city: 'Beaufort',
      state: 'MO',
    },
    63014: {
      lat: 38.64892,
      lng: -91.31566,
      city: 'Berger',
      state: 'MO',
    },
    63015: {
      lat: 38.39633,
      lng: -90.75274,
      city: 'Catawissa',
      state: 'MO',
    },
    63016: {
      lat: 38.35692,
      lng: -90.65014,
      city: 'Cedar Hill',
      state: 'MO',
    },
    63017: {
      lat: 38.65143,
      lng: -90.53722,
      city: 'Chesterfield',
      state: 'MO',
    },
    63019: {
      lat: 38.229,
      lng: -90.37684,
      city: 'Crystal City',
      state: 'MO',
    },
    63020: {
      lat: 38.10706,
      lng: -90.5718,
      city: 'De Soto',
      state: 'MO',
    },
    63021: {
      lat: 38.5689,
      lng: -90.54564,
      city: 'Ballwin',
      state: 'MO',
    },
    63023: {
      lat: 38.2659,
      lng: -90.71292,
      city: 'Dittmer',
      state: 'MO',
    },
    63025: {
      lat: 38.4896,
      lng: -90.61666,
      city: 'Eureka',
      state: 'MO',
    },
    63026: {
      lat: 38.50195,
      lng: -90.45995,
      city: 'Fenton',
      state: 'MO',
    },
    63028: {
      lat: 38.14545,
      lng: -90.39423,
      city: 'Festus',
      state: 'MO',
    },
    63030: {
      lat: 38.14321,
      lng: -90.7522,
      city: 'Fletcher',
      state: 'MO',
    },
    63031: {
      lat: 38.81392,
      lng: -90.35224,
      city: 'Florissant',
      state: 'MO',
    },
    63033: {
      lat: 38.7958,
      lng: -90.27786,
      city: 'Florissant',
      state: 'MO',
    },
    63034: {
      lat: 38.84795,
      lng: -90.28692,
      city: 'Florissant',
      state: 'MO',
    },
    63036: {
      lat: 37.9817,
      lng: -90.372,
      city: 'French Village',
      state: 'MO',
    },
    63037: {
      lat: 38.42497,
      lng: -91.3165,
      city: 'Gerald',
      state: 'MO',
    },
    63038: {
      lat: 38.58071,
      lng: -90.67037,
      city: 'Wildwood',
      state: 'MO',
    },
    63039: {
      lat: 38.49532,
      lng: -90.83527,
      city: 'Gray Summit',
      state: 'MO',
    },
    63040: {
      lat: 38.57298,
      lng: -90.63512,
      city: 'Wildwood',
      state: 'MO',
    },
    63041: {
      lat: 38.2522,
      lng: -90.79059,
      city: 'Grubville',
      state: 'MO',
    },
    63042: {
      lat: 38.78133,
      lng: -90.37909,
      city: 'Hazelwood',
      state: 'MO',
    },
    63043: {
      lat: 38.72962,
      lng: -90.45659,
      city: 'Maryland Heights',
      state: 'MO',
    },
    63044: {
      lat: 38.77455,
      lng: -90.42825,
      city: 'Bridgeton',
      state: 'MO',
    },
    63045: {
      lat: 38.76863,
      lng: -90.46556,
      city: 'Earth City',
      state: 'MO',
    },
    63047: {
      lat: 38.1954,
      lng: -90.48763,
      city: 'Hematite',
      state: 'MO',
    },
    63048: {
      lat: 38.25924,
      lng: -90.39194,
      city: 'Herculaneum',
      state: 'MO',
    },
    63049: {
      lat: 38.47795,
      lng: -90.52867,
      city: 'High Ridge',
      state: 'MO',
    },
    63050: {
      lat: 38.25977,
      lng: -90.57628,
      city: 'Hillsboro',
      state: 'MO',
    },
    63051: {
      lat: 38.39886,
      lng: -90.57303,
      city: 'House Springs',
      state: 'MO',
    },
    63052: {
      lat: 38.38983,
      lng: -90.4358,
      city: 'Imperial',
      state: 'MO',
    },
    63053: {
      lat: 38.36651,
      lng: -90.36402,
      city: 'Kimmswick',
      state: 'MO',
    },
    63055: {
      lat: 38.53254,
      lng: -90.8382,
      city: 'Labadie',
      state: 'MO',
    },
    63056: {
      lat: 38.38964,
      lng: -91.22072,
      city: 'Leslie',
      state: 'MO',
    },
    63060: {
      lat: 38.25656,
      lng: -90.86815,
      city: 'Lonedell',
      state: 'MO',
    },
    63061: {
      lat: 38.2679,
      lng: -90.81053,
      city: 'Luebbering',
      state: 'MO',
    },
    63068: {
      lat: 38.55747,
      lng: -91.24761,
      city: 'New Haven',
      state: 'MO',
    },
    63069: {
      lat: 38.48286,
      lng: -90.7292,
      city: 'Pacific',
      state: 'MO',
    },
    63070: {
      lat: 38.28625,
      lng: -90.41822,
      city: 'Pevely',
      state: 'MO',
    },
    63071: {
      lat: 38.15635,
      lng: -90.82558,
      city: 'Richwoods',
      state: 'MO',
    },
    63072: {
      lat: 38.36635,
      lng: -90.81354,
      city: 'Robertsville',
      state: 'MO',
    },
    63073: {
      lat: 38.58367,
      lng: -90.76933,
      city: 'Saint Albans',
      state: 'MO',
    },
    63074: {
      lat: 38.72637,
      lng: -90.38895,
      city: 'Saint Ann',
      state: 'MO',
    },
    63077: {
      lat: 38.32648,
      lng: -90.98537,
      city: 'Saint Clair',
      state: 'MO',
    },
    63079: {
      lat: 38.26632,
      lng: -91.1001,
      city: 'Stanton',
      state: 'MO',
    },
    63080: {
      lat: 38.20828,
      lng: -91.08802,
      city: 'Sullivan',
      state: 'MO',
    },
    63084: {
      lat: 38.41749,
      lng: -91.01438,
      city: 'Union',
      state: 'MO',
    },
    63087: {
      lat: 38.00888,
      lng: -90.45835,
      city: 'Valles Mines',
      state: 'MO',
    },
    63088: {
      lat: 38.54862,
      lng: -90.50111,
      city: 'Valley Park',
      state: 'MO',
    },
    63089: {
      lat: 38.45846,
      lng: -90.88286,
      city: 'Villa Ridge',
      state: 'MO',
    },
    63090: {
      lat: 38.53176,
      lng: -91.05047,
      city: 'Washington',
      state: 'MO',
    },
    63091: {
      lat: 38.36581,
      lng: -91.39013,
      city: 'Rosebud',
      state: 'MO',
    },
    63101: {
      lat: 38.63127,
      lng: -90.19275,
      city: 'Saint Louis',
      state: 'MO',
    },
    63102: {
      lat: 38.63453,
      lng: -90.18627,
      city: 'Saint Louis',
      state: 'MO',
    },
    63103: {
      lat: 38.62986,
      lng: -90.217,
      city: 'Saint Louis',
      state: 'MO',
    },
    63104: {
      lat: 38.61078,
      lng: -90.21276,
      city: 'Saint Louis',
      state: 'MO',
    },
    63105: {
      lat: 38.64427,
      lng: -90.32812,
      city: 'Saint Louis',
      state: 'MO',
    },
    63106: {
      lat: 38.64445,
      lng: -90.20795,
      city: 'Saint Louis',
      state: 'MO',
    },
    63107: {
      lat: 38.66373,
      lng: -90.21201,
      city: 'Saint Louis',
      state: 'MO',
    },
    63108: {
      lat: 38.64485,
      lng: -90.25337,
      city: 'Saint Louis',
      state: 'MO',
    },
    63109: {
      lat: 38.58483,
      lng: -90.29563,
      city: 'Saint Louis',
      state: 'MO',
    },
    63110: {
      lat: 38.62538,
      lng: -90.26645,
      city: 'Saint Louis',
      state: 'MO',
    },
    63111: {
      lat: 38.55845,
      lng: -90.24993,
      city: 'Saint Louis',
      state: 'MO',
    },
    63112: {
      lat: 38.66,
      lng: -90.28331,
      city: 'Saint Louis',
      state: 'MO',
    },
    63113: {
      lat: 38.65797,
      lng: -90.2475,
      city: 'Saint Louis',
      state: 'MO',
    },
    63114: {
      lat: 38.70218,
      lng: -90.36224,
      city: 'Saint Louis',
      state: 'MO',
    },
    63115: {
      lat: 38.68211,
      lng: -90.23994,
      city: 'Saint Louis',
      state: 'MO',
    },
    63116: {
      lat: 38.57999,
      lng: -90.26501,
      city: 'Saint Louis',
      state: 'MO',
    },
    63117: {
      lat: 38.63077,
      lng: -90.33037,
      city: 'Saint Louis',
      state: 'MO',
    },
    63118: {
      lat: 38.59237,
      lng: -90.22534,
      city: 'Saint Louis',
      state: 'MO',
    },
    63119: {
      lat: 38.58868,
      lng: -90.35104,
      city: 'Saint Louis',
      state: 'MO',
    },
    63120: {
      lat: 38.69061,
      lng: -90.26211,
      city: 'Saint Louis',
      state: 'MO',
    },
    63121: {
      lat: 38.70739,
      lng: -90.30023,
      city: 'Saint Louis',
      state: 'MO',
    },
    63122: {
      lat: 38.57853,
      lng: -90.42133,
      city: 'Saint Louis',
      state: 'MO',
    },
    63123: {
      lat: 38.54949,
      lng: -90.32739,
      city: 'Saint Louis',
      state: 'MO',
    },
    63124: {
      lat: 38.63832,
      lng: -90.38023,
      city: 'Saint Louis',
      state: 'MO',
    },
    63125: {
      lat: 38.51867,
      lng: -90.29269,
      city: 'Saint Louis',
      state: 'MO',
    },
    63126: {
      lat: 38.5496,
      lng: -90.37868,
      city: 'Saint Louis',
      state: 'MO',
    },
    63127: {
      lat: 38.53544,
      lng: -90.41297,
      city: 'Saint Louis',
      state: 'MO',
    },
    63128: {
      lat: 38.49369,
      lng: -90.38306,
      city: 'Saint Louis',
      state: 'MO',
    },
    63129: {
      lat: 38.45654,
      lng: -90.32202,
      city: 'Saint Louis',
      state: 'MO',
    },
    63130: {
      lat: 38.66491,
      lng: -90.32495,
      city: 'Saint Louis',
      state: 'MO',
    },
    63131: {
      lat: 38.6172,
      lng: -90.44365,
      city: 'Saint Louis',
      state: 'MO',
    },
    63132: {
      lat: 38.67607,
      lng: -90.37745,
      city: 'Saint Louis',
      state: 'MO',
    },
    63133: {
      lat: 38.68226,
      lng: -90.30677,
      city: 'Saint Louis',
      state: 'MO',
    },
    63134: {
      lat: 38.74319,
      lng: -90.34549,
      city: 'Saint Louis',
      state: 'MO',
    },
    63135: {
      lat: 38.75012,
      lng: -90.29934,
      city: 'Saint Louis',
      state: 'MO',
    },
    63136: {
      lat: 38.74418,
      lng: -90.25979,
      city: 'Saint Louis',
      state: 'MO',
    },
    63137: {
      lat: 38.74974,
      lng: -90.21297,
      city: 'Saint Louis',
      state: 'MO',
    },
    63138: {
      lat: 38.79904,
      lng: -90.1822,
      city: 'Saint Louis',
      state: 'MO',
    },
    63139: {
      lat: 38.61041,
      lng: -90.29191,
      city: 'Saint Louis',
      state: 'MO',
    },
    63140: {
      lat: 38.73929,
      lng: -90.32399,
      city: 'Saint Louis',
      state: 'MO',
    },
    63141: {
      lat: 38.65751,
      lng: -90.45904,
      city: 'Saint Louis',
      state: 'MO',
    },
    63143: {
      lat: 38.61169,
      lng: -90.32017,
      city: 'Saint Louis',
      state: 'MO',
    },
    63144: {
      lat: 38.61943,
      lng: -90.3482,
      city: 'Saint Louis',
      state: 'MO',
    },
    63146: {
      lat: 38.70135,
      lng: -90.47634,
      city: 'Saint Louis',
      state: 'MO',
    },
    63147: {
      lat: 38.69567,
      lng: -90.21589,
      city: 'Saint Louis',
      state: 'MO',
    },
    63155: {
      lat: 38.62788,
      lng: -90.20545,
      city: 'Saint Louis',
      state: 'MO',
    },
    63301: {
      lat: 38.86159,
      lng: -90.47207,
      city: 'Saint Charles',
      state: 'MO',
    },
    63303: {
      lat: 38.74013,
      lng: -90.54266,
      city: 'Saint Charles',
      state: 'MO',
    },
    63304: {
      lat: 38.70583,
      lng: -90.68666,
      city: 'Saint Charles',
      state: 'MO',
    },
    63330: {
      lat: 39.26151,
      lng: -90.79214,
      city: 'Annada',
      state: 'MO',
    },
    63332: {
      lat: 38.60137,
      lng: -90.89195,
      city: 'Augusta',
      state: 'MO',
    },
    63333: {
      lat: 39.0351,
      lng: -91.32014,
      city: 'Bellflower',
      state: 'MO',
    },
    63334: {
      lat: 39.30747,
      lng: -91.18838,
      city: 'Bowling Green',
      state: 'MO',
    },
    63336: {
      lat: 39.33335,
      lng: -90.9388,
      city: 'Clarksville',
      state: 'MO',
    },
    63339: {
      lat: 39.33673,
      lng: -91.36384,
      city: 'Curryville',
      state: 'MO',
    },
    63341: {
      lat: 38.66493,
      lng: -90.81783,
      city: 'Defiance',
      state: 'MO',
    },
    63343: {
      lat: 39.15775,
      lng: -90.82815,
      city: 'Elsberry',
      state: 'MO',
    },
    63344: {
      lat: 39.23078,
      lng: -91.01115,
      city: 'Eolia',
      state: 'MO',
    },
    63345: {
      lat: 39.27599,
      lng: -91.57311,
      city: 'Farber',
      state: 'MO',
    },
    63347: { lat: 39.0708, lng: -90.76352, city: 'Foley', state: 'MO' },
    63348: {
      lat: 38.78969,
      lng: -90.94131,
      city: 'Foristell',
      state: 'MO',
    },
    63349: {
      lat: 38.97979,
      lng: -91.15083,
      city: 'Hawk Point',
      state: 'MO',
    },
    63350: {
      lat: 38.90243,
      lng: -91.35686,
      city: 'High Hill',
      state: 'MO',
    },
    63351: {
      lat: 38.86041,
      lng: -91.31186,
      city: 'Jonesburg',
      state: 'MO',
    },
    63352: {
      lat: 39.25097,
      lng: -91.65526,
      city: 'Laddonia',
      state: 'MO',
    },
    63353: {
      lat: 39.44375,
      lng: -91.12,
      city: 'Louisiana',
      state: 'MO',
    },
    63357: {
      lat: 38.65518,
      lng: -91.0947,
      city: 'Marthasville',
      state: 'MO',
    },
    63359: {
      lat: 39.1564,
      lng: -91.34652,
      city: 'Middletown',
      state: 'MO',
    },
    63361: {
      lat: 38.95802,
      lng: -91.56056,
      city: 'Montgomery City',
      state: 'MO',
    },
    63362: {
      lat: 38.93276,
      lng: -90.88227,
      city: 'Moscow Mills',
      state: 'MO',
    },
    63363: {
      lat: 38.88187,
      lng: -91.45755,
      city: 'New Florence',
      state: 'MO',
    },
    63365: {
      lat: 38.70961,
      lng: -90.87334,
      city: 'New Melle',
      state: 'MO',
    },
    63366: {
      lat: 38.85918,
      lng: -90.71964,
      city: "O'Fallon",
      state: 'MO',
    },
    63367: {
      lat: 38.77805,
      lng: -90.79536,
      city: 'Lake Saint Louis',
      state: 'MO',
    },
    63368: {
      lat: 38.7516,
      lng: -90.72895,
      city: "O'Fallon",
      state: 'MO',
    },
    63369: {
      lat: 38.93758,
      lng: -90.77766,
      city: 'Old Monroe',
      state: 'MO',
    },
    63373: {
      lat: 38.92776,
      lng: -90.39563,
      city: 'Portage Des Sioux',
      state: 'MO',
    },
    63376: {
      lat: 38.80022,
      lng: -90.61636,
      city: 'Saint Peters',
      state: 'MO',
    },
    63377: {
      lat: 39.11016,
      lng: -91.10053,
      city: 'Silex',
      state: 'MO',
    },
    63379: { lat: 38.99817, lng: -90.99037, city: 'Troy', state: 'MO' },
    63380: {
      lat: 38.81262,
      lng: -91.12205,
      city: 'Truesdale',
      state: 'MO',
    },
    63381: {
      lat: 38.98237,
      lng: -91.25429,
      city: 'Truxton',
      state: 'MO',
    },
    63382: {
      lat: 39.31104,
      lng: -91.49042,
      city: 'Vandalia',
      state: 'MO',
    },
    63383: {
      lat: 38.80726,
      lng: -91.19319,
      city: 'Warrenton',
      state: 'MO',
    },
    63384: {
      lat: 39.08678,
      lng: -91.56349,
      city: 'Wellsville',
      state: 'MO',
    },
    63385: {
      lat: 38.79732,
      lng: -90.85649,
      city: 'Wentzville',
      state: 'MO',
    },
    63386: {
      lat: 38.87104,
      lng: -90.22009,
      city: 'West Alton',
      state: 'MO',
    },
    63387: {
      lat: 39.18415,
      lng: -91.01677,
      city: 'Whiteside',
      state: 'MO',
    },
    63388: {
      lat: 38.88816,
      lng: -91.74995,
      city: 'Williamsburg',
      state: 'MO',
    },
    63389: {
      lat: 38.99969,
      lng: -90.76875,
      city: 'Winfield',
      state: 'MO',
    },
    63390: {
      lat: 38.80499,
      lng: -91.0413,
      city: 'Wright City',
      state: 'MO',
    },
    63401: {
      lat: 39.69046,
      lng: -91.45986,
      city: 'Hannibal',
      state: 'MO',
    },
    63430: {
      lat: 40.38972,
      lng: -91.53958,
      city: 'Alexandria',
      state: 'MO',
    },
    63431: {
      lat: 39.74887,
      lng: -92.32893,
      city: 'Anabel',
      state: 'MO',
    },
    63432: {
      lat: 40.50058,
      lng: -91.99551,
      city: 'Arbela',
      state: 'MO',
    },
    63433: {
      lat: 39.51838,
      lng: -91.11816,
      city: 'Ashburn',
      state: 'MO',
    },
    63434: {
      lat: 39.90591,
      lng: -91.9507,
      city: 'Bethel',
      state: 'MO',
    },
    63435: {
      lat: 40.20299,
      lng: -91.58267,
      city: 'Canton',
      state: 'MO',
    },
    63436: {
      lat: 39.50093,
      lng: -91.54524,
      city: 'Center',
      state: 'MO',
    },
    63437: {
      lat: 39.72576,
      lng: -92.23679,
      city: 'Clarence',
      state: 'MO',
    },
    63438: {
      lat: 39.96084,
      lng: -91.68828,
      city: 'Durham',
      state: 'MO',
    },
    63439: {
      lat: 39.80382,
      lng: -91.85963,
      city: 'Emden',
      state: 'MO',
    },
    63440: {
      lat: 39.97551,
      lng: -91.75376,
      city: 'Ewing',
      state: 'MO',
    },
    63441: {
      lat: 39.4766,
      lng: -91.31575,
      city: 'Frankford',
      state: 'MO',
    },
    63443: {
      lat: 39.66222,
      lng: -91.86697,
      city: 'Hunnewell',
      state: 'MO',
    },
    63445: {
      lat: 40.39681,
      lng: -91.72044,
      city: 'Kahoka',
      state: 'MO',
    },
    63446: {
      lat: 40.13972,
      lng: -92.0055,
      city: 'Knox City',
      state: 'MO',
    },
    63447: {
      lat: 40.09251,
      lng: -91.90494,
      city: 'La Belle',
      state: 'MO',
    },
    63448: {
      lat: 40.02168,
      lng: -91.55416,
      city: 'La Grange',
      state: 'MO',
    },
    63450: {
      lat: 39.66808,
      lng: -92.14857,
      city: 'Lentner',
      state: 'MO',
    },
    63451: {
      lat: 39.91135,
      lng: -92.19728,
      city: 'Leonard',
      state: 'MO',
    },
    63452: {
      lat: 40.09316,
      lng: -91.78551,
      city: 'Lewistown',
      state: 'MO',
    },
    63453: {
      lat: 40.49511,
      lng: -91.86495,
      city: 'Luray',
      state: 'MO',
    },
    63454: {
      lat: 39.92389,
      lng: -91.64897,
      city: 'Maywood',
      state: 'MO',
    },
    63456: {
      lat: 39.65364,
      lng: -91.72693,
      city: 'Monroe City',
      state: 'MO',
    },
    63457: {
      lat: 40.17272,
      lng: -91.70115,
      city: 'Monticello',
      state: 'MO',
    },
    63458: {
      lat: 39.98751,
      lng: -92.01745,
      city: 'Newark',
      state: 'MO',
    },
    63459: {
      lat: 39.58392,
      lng: -91.3795,
      city: 'New London',
      state: 'MO',
    },
    63460: {
      lat: 40.00237,
      lng: -92.21002,
      city: 'Novelty',
      state: 'MO',
    },
    63461: {
      lat: 39.79757,
      lng: -91.55577,
      city: 'Palmyra',
      state: 'MO',
    },
    63462: {
      lat: 39.42279,
      lng: -91.69977,
      city: 'Perry',
      state: 'MO',
    },
    63463: {
      lat: 39.84533,
      lng: -91.75976,
      city: 'Philadelphia',
      state: 'MO',
    },
    63465: {
      lat: 40.52307,
      lng: -91.6898,
      city: 'Revere',
      state: 'MO',
    },
    63466: {
      lat: 40.26627,
      lng: -91.61751,
      city: 'Saint Patrick',
      state: 'MO',
    },
    63467: {
      lat: 39.64849,
      lng: -91.26655,
      city: 'Saverton',
      state: 'MO',
    },
    63468: {
      lat: 39.67857,
      lng: -92.01267,
      city: 'Shelbina',
      state: 'MO',
    },
    63469: {
      lat: 39.8323,
      lng: -92.04401,
      city: 'Shelbyville',
      state: 'MO',
    },
    63471: {
      lat: 39.92511,
      lng: -91.50652,
      city: 'Taylor',
      state: 'MO',
    },
    63472: {
      lat: 40.4053,
      lng: -91.57734,
      city: 'Wayland',
      state: 'MO',
    },
    63473: {
      lat: 40.25263,
      lng: -91.77803,
      city: 'Williamstown',
      state: 'MO',
    },
    63474: {
      lat: 40.3473,
      lng: -91.89674,
      city: 'Wyaconda',
      state: 'MO',
    },
    63501: {
      lat: 40.16745,
      lng: -92.59078,
      city: 'Kirksville',
      state: 'MO',
    },
    63530: {
      lat: 39.90732,
      lng: -92.45037,
      city: 'Atlanta',
      state: 'MO',
    },
    63531: {
      lat: 40.30002,
      lng: -92.25248,
      city: 'Baring',
      state: 'MO',
    },
    63532: {
      lat: 39.7677,
      lng: -92.57434,
      city: 'Bevier',
      state: 'MO',
    },
    63533: {
      lat: 40.20471,
      lng: -92.39608,
      city: 'Brashear',
      state: 'MO',
    },
    63534: {
      lat: 39.74061,
      lng: -92.64838,
      city: 'Callao',
      state: 'MO',
    },
    63535: {
      lat: 40.57244,
      lng: -92.67946,
      city: 'Coatsville',
      state: 'MO',
    },
    63536: {
      lat: 40.48423,
      lng: -92.3574,
      city: 'Downing',
      state: 'MO',
    },
    63537: {
      lat: 40.14776,
      lng: -92.16172,
      city: 'Edina',
      state: 'MO',
    },
    63538: { lat: 39.9526, lng: -92.67327, city: 'Elmer', state: 'MO' },
    63539: { lat: 39.9279, lng: -92.76781, city: 'Ethel', state: 'MO' },
    63540: {
      lat: 40.09782,
      lng: -92.40792,
      city: 'Gibbs',
      state: 'MO',
    },
    63541: {
      lat: 40.50355,
      lng: -92.63331,
      city: 'Glenwood',
      state: 'MO',
    },
    63543: {
      lat: 40.35765,
      lng: -92.00976,
      city: 'Gorin',
      state: 'MO',
    },
    63544: {
      lat: 40.24603,
      lng: -92.84943,
      city: 'Green Castle',
      state: 'MO',
    },
    63545: {
      lat: 40.26721,
      lng: -92.9677,
      city: 'Green City',
      state: 'MO',
    },
    63546: {
      lat: 40.33129,
      lng: -92.50882,
      city: 'Greentop',
      state: 'MO',
    },
    63547: {
      lat: 40.13492,
      lng: -92.30651,
      city: 'Hurdland',
      state: 'MO',
    },
    63548: {
      lat: 40.53405,
      lng: -92.4953,
      city: 'Lancaster',
      state: 'MO',
    },
    63549: {
      lat: 40.0167,
      lng: -92.49172,
      city: 'La Plata',
      state: 'MO',
    },
    63551: {
      lat: 40.49921,
      lng: -92.74252,
      city: 'Livonia',
      state: 'MO',
    },
    63552: {
      lat: 39.75349,
      lng: -92.45075,
      city: 'Macon',
      state: 'MO',
    },
    63555: {
      lat: 40.48458,
      lng: -92.18698,
      city: 'Memphis',
      state: 'MO',
    },
    63556: {
      lat: 40.18251,
      lng: -93.11723,
      city: 'Milan',
      state: 'MO',
    },
    63557: {
      lat: 39.98297,
      lng: -92.88045,
      city: 'New Boston',
      state: 'MO',
    },
    63558: {
      lat: 39.74168,
      lng: -92.75244,
      city: 'New Cambria',
      state: 'MO',
    },
    63559: {
      lat: 40.22674,
      lng: -92.74528,
      city: 'Novinger',
      state: 'MO',
    },
    63560: {
      lat: 40.3574,
      lng: -93.12291,
      city: 'Pollock',
      state: 'MO',
    },
    63561: {
      lat: 40.41408,
      lng: -92.54317,
      city: 'Queen City',
      state: 'MO',
    },
    63563: {
      lat: 40.30387,
      lng: -92.07439,
      city: 'Rutledge',
      state: 'MO',
    },
    63565: {
      lat: 40.48263,
      lng: -92.95447,
      city: 'Unionville',
      state: 'MO',
    },
    63566: {
      lat: 40.03139,
      lng: -92.94793,
      city: 'Winigan',
      state: 'MO',
    },
    63567: {
      lat: 40.41332,
      lng: -92.70461,
      city: 'Worthington',
      state: 'MO',
    },
    63601: {
      lat: 37.8212,
      lng: -90.5439,
      city: 'Park Hills',
      state: 'MO',
    },
    63620: {
      lat: 37.40101,
      lng: -90.6521,
      city: 'Annapolis',
      state: 'MO',
    },
    63621: {
      lat: 37.48225,
      lng: -90.57916,
      city: 'Arcadia',
      state: 'MO',
    },
    63622: {
      lat: 37.78901,
      lng: -90.90584,
      city: 'Belgrade',
      state: 'MO',
    },
    63623: {
      lat: 37.67532,
      lng: -90.8941,
      city: 'Belleview',
      state: 'MO',
    },
    63624: {
      lat: 37.7531,
      lng: -90.63923,
      city: 'Bismarck',
      state: 'MO',
    },
    63625: {
      lat: 37.56374,
      lng: -90.98734,
      city: 'Black',
      state: 'MO',
    },
    63626: {
      lat: 38.07721,
      lng: -90.71471,
      city: 'Blackwell',
      state: 'MO',
    },
    63627: {
      lat: 38.03625,
      lng: -90.25317,
      city: 'Bloomsdale',
      state: 'MO',
    },
    63628: {
      lat: 37.9392,
      lng: -90.52466,
      city: 'Bonne Terre',
      state: 'MO',
    },
    63629: {
      lat: 37.39847,
      lng: -91.23861,
      city: 'Bunker',
      state: 'MO',
    },
    63630: {
      lat: 38.01874,
      lng: -90.72727,
      city: 'Cadet',
      state: 'MO',
    },
    63631: {
      lat: 37.74912,
      lng: -90.78873,
      city: 'Caledonia',
      state: 'MO',
    },
    63633: {
      lat: 37.41993,
      lng: -91.01082,
      city: 'Centerville',
      state: 'MO',
    },
    63636: {
      lat: 37.29542,
      lng: -90.57549,
      city: 'Des Arc',
      state: 'MO',
    },
    63637: {
      lat: 37.70511,
      lng: -90.51875,
      city: 'Doe Run',
      state: 'MO',
    },
    63638: {
      lat: 37.21727,
      lng: -91.01135,
      city: 'Ellington',
      state: 'MO',
    },
    63640: {
      lat: 37.75446,
      lng: -90.36525,
      city: 'Farmington',
      state: 'MO',
    },
    63645: {
      lat: 37.54204,
      lng: -90.32428,
      city: 'Fredericktown',
      state: 'MO',
    },
    63648: {
      lat: 37.8187,
      lng: -90.69878,
      city: 'Irondale',
      state: 'MO',
    },
    63650: {
      lat: 37.6103,
      lng: -90.62994,
      city: 'Ironton',
      state: 'MO',
    },
    63653: {
      lat: 37.86139,
      lng: -90.58789,
      city: 'Leadwood',
      state: 'MO',
    },
    63654: {
      lat: 37.45941,
      lng: -90.85156,
      city: 'Lesterville',
      state: 'MO',
    },
    63655: {
      lat: 37.39312,
      lng: -90.20552,
      city: 'Marquand',
      state: 'MO',
    },
    63656: {
      lat: 37.59664,
      lng: -90.79483,
      city: 'Middle Brook',
      state: 'MO',
    },
    63660: {
      lat: 37.91241,
      lng: -90.70241,
      city: 'Mineral Point',
      state: 'MO',
    },
    63662: {
      lat: 37.53782,
      lng: -90.05321,
      city: 'Patton',
      state: 'MO',
    },
    63663: {
      lat: 37.62446,
      lng: -90.64638,
      city: 'Pilot Knob',
      state: 'MO',
    },
    63664: {
      lat: 37.92161,
      lng: -90.91661,
      city: 'Potosi',
      state: 'MO',
    },
    63665: {
      lat: 37.29055,
      lng: -90.84087,
      city: 'Redford',
      state: 'MO',
    },
    63670: {
      lat: 37.89667,
      lng: -90.16245,
      city: 'Sainte Genevieve',
      state: 'MO',
    },
    63673: {
      lat: 37.83641,
      lng: -89.98861,
      city: 'Saint Mary',
      state: 'MO',
    },
    63674: { lat: 38.03126, lng: -90.65933, city: 'Tiff', state: 'MO' },
    63675: { lat: 37.30112, lng: -90.697, city: 'Vulcan', state: 'MO' },
    63701: {
      lat: 37.33522,
      lng: -89.58043,
      city: 'Cape Girardeau',
      state: 'MO',
    },
    63703: {
      lat: 37.27373,
      lng: -89.53981,
      city: 'Cape Girardeau',
      state: 'MO',
    },
    63730: {
      lat: 37.09496,
      lng: -89.91841,
      city: 'Advance',
      state: 'MO',
    },
    63732: {
      lat: 37.59428,
      lng: -89.59123,
      city: 'Altenburg',
      state: 'MO',
    },
    63735: {
      lat: 36.98015,
      lng: -89.78139,
      city: 'Bell City',
      state: 'MO',
    },
    63736: {
      lat: 37.08033,
      lng: -89.52072,
      city: 'Benton',
      state: 'MO',
    },
    63738: {
      lat: 37.08936,
      lng: -89.95729,
      city: 'Brownwood',
      state: 'MO',
    },
    63739: {
      lat: 37.35686,
      lng: -89.80216,
      city: 'Burfordville',
      state: 'MO',
    },
    63740: {
      lat: 37.17564,
      lng: -89.68557,
      city: 'Chaffee',
      state: 'MO',
    },
    63742: {
      lat: 37.1583,
      lng: -89.44672,
      city: 'Commerce',
      state: 'MO',
    },
    63743: {
      lat: 37.51886,
      lng: -89.83261,
      city: 'Daisy',
      state: 'MO',
    },
    63744: {
      lat: 37.19331,
      lng: -89.71831,
      city: 'Delta',
      state: 'MO',
    },
    63745: {
      lat: 37.25596,
      lng: -89.65946,
      city: 'Dutchtown',
      state: 'MO',
    },
    63746: {
      lat: 37.70783,
      lng: -89.69484,
      city: 'Farrar',
      state: 'MO',
    },
    63747: {
      lat: 37.55993,
      lng: -89.81121,
      city: 'Friedheim',
      state: 'MO',
    },
    63748: {
      lat: 37.66912,
      lng: -89.61282,
      city: 'Frohna',
      state: 'MO',
    },
    63750: { lat: 37.1508, lng: -90.16917, city: 'Gipsy', state: 'MO' },
    63751: {
      lat: 37.2742,
      lng: -90.12404,
      city: 'Glenallen',
      state: 'MO',
    },
    63755: {
      lat: 37.44351,
      lng: -89.64193,
      city: 'Jackson',
      state: 'MO',
    },
    63758: {
      lat: 37.19212,
      lng: -89.55064,
      city: 'Kelso',
      state: 'MO',
    },
    63760: {
      lat: 37.25084,
      lng: -89.90374,
      city: 'Leopold',
      state: 'MO',
    },
    63763: {
      lat: 37.05885,
      lng: -90.18058,
      city: 'McGee',
      state: 'MO',
    },
    63764: {
      lat: 37.32944,
      lng: -89.9837,
      city: 'Marble Hill',
      state: 'MO',
    },
    63766: {
      lat: 37.43558,
      lng: -89.84424,
      city: 'Millersville',
      state: 'MO',
    },
    63767: {
      lat: 37.04356,
      lng: -89.61038,
      city: 'Morley',
      state: 'MO',
    },
    63769: {
      lat: 37.52312,
      lng: -89.74203,
      city: 'Oak Ridge',
      state: 'MO',
    },
    63770: {
      lat: 37.59456,
      lng: -89.70049,
      city: 'Old Appleton',
      state: 'MO',
    },
    63771: { lat: 37.07431, lng: -89.71299, city: 'Oran', state: 'MO' },
    63774: {
      lat: 37.09424,
      lng: -89.77469,
      city: 'Perkins',
      state: 'MO',
    },
    63775: {
      lat: 37.71051,
      lng: -89.87919,
      city: 'Perryville',
      state: 'MO',
    },
    63780: {
      lat: 37.17797,
      lng: -89.50138,
      city: 'Scott City',
      state: 'MO',
    },
    63781: {
      lat: 37.5329,
      lng: -89.93078,
      city: 'Sedgewickville',
      state: 'MO',
    },
    63782: {
      lat: 37.11369,
      lng: -90.02788,
      city: 'Sturdivant',
      state: 'MO',
    },
    63783: {
      lat: 37.60958,
      lng: -89.6823,
      city: 'Uniontown',
      state: 'MO',
    },
    63784: {
      lat: 36.99497,
      lng: -89.69601,
      city: 'Vanduser',
      state: 'MO',
    },
    63785: {
      lat: 37.26336,
      lng: -89.81554,
      city: 'Whitewater',
      state: 'MO',
    },
    63787: {
      lat: 37.12284,
      lng: -90.12263,
      city: 'Zalma',
      state: 'MO',
    },
    63801: {
      lat: 36.89698,
      lng: -89.60672,
      city: 'Sikeston',
      state: 'MO',
    },
    63820: {
      lat: 36.82427,
      lng: -89.32564,
      city: 'Anniston',
      state: 'MO',
    },
    63821: {
      lat: 36.04878,
      lng: -90.23963,
      city: 'Arbyrd',
      state: 'MO',
    },
    63822: {
      lat: 36.65904,
      lng: -90.00103,
      city: 'Bernie',
      state: 'MO',
    },
    63823: {
      lat: 36.89628,
      lng: -89.45367,
      city: 'Bertrand',
      state: 'MO',
    },
    63824: {
      lat: 37.00513,
      lng: -89.52638,
      city: 'Blodgett',
      state: 'MO',
    },
    63825: {
      lat: 36.93033,
      lng: -89.94432,
      city: 'Bloomfield',
      state: 'MO',
    },
    63826: {
      lat: 36.18361,
      lng: -89.84486,
      city: 'Braggadocio',
      state: 'MO',
    },
    63827: {
      lat: 36.26133,
      lng: -89.89226,
      city: 'Bragg City',
      state: 'MO',
    },
    63828: {
      lat: 36.75038,
      lng: -89.69182,
      city: 'Canalou',
      state: 'MO',
    },
    63829: {
      lat: 36.03652,
      lng: -90.31173,
      city: 'Cardwell',
      state: 'MO',
    },
    63830: {
      lat: 36.14065,
      lng: -89.6871,
      city: 'Caruthersville',
      state: 'MO',
    },
    63833: {
      lat: 36.64779,
      lng: -89.73023,
      city: 'Catron',
      state: 'MO',
    },
    63834: {
      lat: 36.91989,
      lng: -89.27885,
      city: 'Charleston',
      state: 'MO',
    },
    63837: {
      lat: 36.44907,
      lng: -89.98023,
      city: 'Clarkton',
      state: 'MO',
    },
    63839: {
      lat: 36.04748,
      lng: -89.8097,
      city: 'Cooter',
      state: 'MO',
    },
    63840: {
      lat: 36.19113,
      lng: -89.88426,
      city: 'Deering',
      state: 'MO',
    },
    63841: {
      lat: 36.77198,
      lng: -89.98169,
      city: 'Dexter',
      state: 'MO',
    },
    63845: {
      lat: 36.70944,
      lng: -89.31505,
      city: 'East Prairie',
      state: 'MO',
    },
    63846: {
      lat: 36.82229,
      lng: -89.80199,
      city: 'Essex',
      state: 'MO',
    },
    63847: {
      lat: 36.44271,
      lng: -90.03474,
      city: 'Gibson',
      state: 'MO',
    },
    63848: {
      lat: 36.44053,
      lng: -89.88219,
      city: 'Gideon',
      state: 'MO',
    },
    63849: {
      lat: 36.13287,
      lng: -89.99109,
      city: 'Gobler',
      state: 'MO',
    },
    63850: {
      lat: 36.82227,
      lng: -89.78214,
      city: 'Grayridge',
      state: 'MO',
    },
    63851: {
      lat: 36.25762,
      lng: -89.72352,
      city: 'Hayti',
      state: 'MO',
    },
    63852: {
      lat: 36.36748,
      lng: -90.01435,
      city: 'Holcomb',
      state: 'MO',
    },
    63853: {
      lat: 36.05834,
      lng: -89.86738,
      city: 'Holland',
      state: 'MO',
    },
    63855: {
      lat: 36.05332,
      lng: -90.07123,
      city: 'Hornersville',
      state: 'MO',
    },
    63857: {
      lat: 36.22402,
      lng: -90.04612,
      city: 'Kennett',
      state: 'MO',
    },
    63860: {
      lat: 36.67462,
      lng: -89.59449,
      city: 'Kewanee',
      state: 'MO',
    },
    63862: {
      lat: 36.58974,
      lng: -89.67345,
      city: 'Lilbourn',
      state: 'MO',
    },
    63863: {
      lat: 36.56282,
      lng: -89.97671,
      city: 'Malden',
      state: 'MO',
    },
    63866: {
      lat: 36.52606,
      lng: -89.60654,
      city: 'Marston',
      state: 'MO',
    },
    63867: {
      lat: 36.74475,
      lng: -89.5465,
      city: 'Matthews',
      state: 'MO',
    },
    63868: {
      lat: 36.84579,
      lng: -89.68971,
      city: 'Morehouse',
      state: 'MO',
    },
    63869: {
      lat: 36.60768,
      lng: -89.48111,
      city: 'New Madrid',
      state: 'MO',
    },
    63870: {
      lat: 36.61942,
      lng: -89.84471,
      city: 'Parma',
      state: 'MO',
    },
    63873: {
      lat: 36.42791,
      lng: -89.65965,
      city: 'Portageville',
      state: 'MO',
    },
    63874: {
      lat: 36.55566,
      lng: -89.81645,
      city: 'Risco',
      state: 'MO',
    },
    63876: {
      lat: 36.13206,
      lng: -90.16487,
      city: 'Senath',
      state: 'MO',
    },
    63877: {
      lat: 36.07377,
      lng: -89.85118,
      city: 'Steele',
      state: 'MO',
    },
    63878: {
      lat: 36.50302,
      lng: -89.82234,
      city: 'Tallapoosa',
      state: 'MO',
    },
    63879: {
      lat: 36.35752,
      lng: -89.80945,
      city: 'Wardell',
      state: 'MO',
    },
    63880: {
      lat: 36.32986,
      lng: -90.02585,
      city: 'Whiteoak',
      state: 'MO',
    },
    63882: {
      lat: 36.91639,
      lng: -89.21974,
      city: 'Wyatt',
      state: 'MO',
    },
    63901: {
      lat: 36.77168,
      lng: -90.45012,
      city: 'Poplar Bluff',
      state: 'MO',
    },
    63932: {
      lat: 36.6879,
      lng: -90.24533,
      city: 'Broseley',
      state: 'MO',
    },
    63933: {
      lat: 36.52102,
      lng: -90.10876,
      city: 'Campbell',
      state: 'MO',
    },
    63934: {
      lat: 37.20014,
      lng: -90.37254,
      city: 'Clubb',
      state: 'MO',
    },
    63935: {
      lat: 36.67053,
      lng: -90.92055,
      city: 'Doniphan',
      state: 'MO',
    },
    63936: {
      lat: 36.80731,
      lng: -90.12979,
      city: 'Dudley',
      state: 'MO',
    },
    63937: {
      lat: 36.94403,
      lng: -90.74907,
      city: 'Ellsinore',
      state: 'MO',
    },
    63939: {
      lat: 36.68456,
      lng: -90.68253,
      city: 'Fairdealing',
      state: 'MO',
    },
    63940: { lat: 36.77218, lng: -90.22892, city: 'Fisk', state: 'MO' },
    63941: {
      lat: 36.8711,
      lng: -91.16607,
      city: 'Fremont',
      state: 'MO',
    },
    63942: {
      lat: 36.55434,
      lng: -91.07821,
      city: 'Gatewood',
      state: 'MO',
    },
    63943: {
      lat: 36.84046,
      lng: -90.79995,
      city: 'Grandin',
      state: 'MO',
    },
    63944: {
      lat: 37.10876,
      lng: -90.36286,
      city: 'Greenville',
      state: 'MO',
    },
    63945: {
      lat: 36.6381,
      lng: -90.5524,
      city: 'Harviell',
      state: 'MO',
    },
    63950: { lat: 37.26924, lng: -90.43474, city: 'Lodi', state: 'MO' },
    63951: {
      lat: 37.10875,
      lng: -90.26055,
      city: 'Lowndes',
      state: 'MO',
    },
    63952: {
      lat: 37.00828,
      lng: -90.64916,
      city: 'Mill Spring',
      state: 'MO',
    },
    63953: {
      lat: 36.59134,
      lng: -90.62995,
      city: 'Naylor',
      state: 'MO',
    },
    63954: {
      lat: 36.56365,
      lng: -90.47337,
      city: 'Neelyville',
      state: 'MO',
    },
    63955: { lat: 36.60211, lng: -90.67864, city: 'Oxly', state: 'MO' },
    63956: {
      lat: 37.21741,
      lng: -90.51665,
      city: 'Patterson',
      state: 'MO',
    },
    63957: {
      lat: 37.14218,
      lng: -90.68903,
      city: 'Piedmont',
      state: 'MO',
    },
    63960: {
      lat: 36.96074,
      lng: -90.12434,
      city: 'Puxico',
      state: 'MO',
    },
    63961: { lat: 36.56848, lng: -90.2749, city: 'Qulin', state: 'MO' },
    63962: {
      lat: 36.84406,
      lng: -90.28149,
      city: 'Rombauer',
      state: 'MO',
    },
    63964: {
      lat: 37.23954,
      lng: -90.39662,
      city: 'Silva',
      state: 'MO',
    },
    63965: {
      lat: 36.97391,
      lng: -91.02294,
      city: 'Van Buren',
      state: 'MO',
    },
    63966: {
      lat: 36.96956,
      lng: -90.26216,
      city: 'Wappapello',
      state: 'MO',
    },
    63967: {
      lat: 36.94867,
      lng: -90.47721,
      city: 'Williamsville',
      state: 'MO',
    },
    64001: { lat: 39.10464, lng: -93.53683, city: 'Alma', state: 'MO' },
    64011: {
      lat: 38.95963,
      lng: -94.06688,
      city: 'Bates City',
      state: 'MO',
    },
    64012: {
      lat: 38.78906,
      lng: -94.55204,
      city: 'Belton',
      state: 'MO',
    },
    64014: {
      lat: 39.00893,
      lng: -94.25533,
      city: 'Blue Springs',
      state: 'MO',
    },
    64015: {
      lat: 39.02099,
      lng: -94.3006,
      city: 'Blue Springs',
      state: 'MO',
    },
    64016: {
      lat: 39.11642,
      lng: -94.21385,
      city: 'Buckner',
      state: 'MO',
    },
    64017: { lat: 39.199, lng: -94.02951, city: 'Camden', state: 'MO' },
    64018: {
      lat: 39.45052,
      lng: -94.73329,
      city: 'Camden Point',
      state: 'MO',
    },
    64019: {
      lat: 38.77543,
      lng: -93.86665,
      city: 'Centerview',
      state: 'MO',
    },
    64020: {
      lat: 38.96795,
      lng: -93.59098,
      city: 'Concordia',
      state: 'MO',
    },
    64021: { lat: 39.11498, lng: -93.624, city: 'Corder', state: 'MO' },
    64022: {
      lat: 39.20191,
      lng: -93.67836,
      city: 'Dover',
      state: 'MO',
    },
    64024: {
      lat: 39.32523,
      lng: -94.22707,
      city: 'Excelsior Springs',
      state: 'MO',
    },
    64028: {
      lat: 39.29658,
      lng: -94.82153,
      city: 'Farley',
      state: 'MO',
    },
    64029: {
      lat: 39.00634,
      lng: -94.21473,
      city: 'Grain Valley',
      state: 'MO',
    },
    64030: {
      lat: 38.87996,
      lng: -94.52312,
      city: 'Grandview',
      state: 'MO',
    },
    64034: {
      lat: 38.85052,
      lng: -94.30203,
      city: 'Greenwood',
      state: 'MO',
    },
    64035: {
      lat: 39.28796,
      lng: -93.81972,
      city: 'Hardin',
      state: 'MO',
    },
    64036: {
      lat: 39.20047,
      lng: -93.937,
      city: 'Henrietta',
      state: 'MO',
    },
    64037: {
      lat: 39.05116,
      lng: -93.73386,
      city: 'Higginsville',
      state: 'MO',
    },
    64040: {
      lat: 38.71928,
      lng: -93.98725,
      city: 'Holden',
      state: 'MO',
    },
    64048: { lat: 39.44439, lng: -94.3634, city: 'Holt', state: 'MO' },
    64050: {
      lat: 39.11424,
      lng: -94.40641,
      city: 'Independence',
      state: 'MO',
    },
    64052: {
      lat: 39.07355,
      lng: -94.45082,
      city: 'Independence',
      state: 'MO',
    },
    64053: {
      lat: 39.10791,
      lng: -94.46735,
      city: 'Independence',
      state: 'MO',
    },
    64054: {
      lat: 39.11627,
      lng: -94.44003,
      city: 'Independence',
      state: 'MO',
    },
    64055: {
      lat: 39.05061,
      lng: -94.39848,
      city: 'Independence',
      state: 'MO',
    },
    64056: {
      lat: 39.11114,
      lng: -94.32008,
      city: 'Independence',
      state: 'MO',
    },
    64057: {
      lat: 39.0692,
      lng: -94.32203,
      city: 'Independence',
      state: 'MO',
    },
    64058: {
      lat: 39.17805,
      lng: -94.31408,
      city: 'Independence',
      state: 'MO',
    },
    64060: {
      lat: 39.37134,
      lng: -94.36821,
      city: 'Kearney',
      state: 'MO',
    },
    64061: {
      lat: 38.77981,
      lng: -94.0885,
      city: 'Kingsville',
      state: 'MO',
    },
    64062: {
      lat: 39.45533,
      lng: -94.17244,
      city: 'Lawson',
      state: 'MO',
    },
    64063: {
      lat: 38.91224,
      lng: -94.35341,
      city: 'Lees Summit',
      state: 'MO',
    },
    64064: {
      lat: 38.98222,
      lng: -94.34284,
      city: 'Lees Summit',
      state: 'MO',
    },
    64065: {
      lat: 38.95146,
      lng: -94.40467,
      city: 'Lees Summit',
      state: 'MO',
    },
    64066: {
      lat: 39.13961,
      lng: -94.13857,
      city: 'Levasy',
      state: 'MO',
    },
    64067: {
      lat: 39.15594,
      lng: -93.83747,
      city: 'Lexington',
      state: 'MO',
    },
    64068: {
      lat: 39.25924,
      lng: -94.39042,
      city: 'Liberty',
      state: 'MO',
    },
    64070: {
      lat: 38.8897,
      lng: -94.14863,
      city: 'Lone Jack',
      state: 'MO',
    },
    64071: {
      lat: 39.02961,
      lng: -93.83379,
      city: 'Mayview',
      state: 'MO',
    },
    64072: {
      lat: 39.23286,
      lng: -94.30021,
      city: 'Missouri City',
      state: 'MO',
    },
    64074: {
      lat: 39.08106,
      lng: -94.07658,
      city: 'Napoleon',
      state: 'MO',
    },
    64075: {
      lat: 38.99751,
      lng: -94.14544,
      city: 'Oak Grove',
      state: 'MO',
    },
    64076: {
      lat: 38.98145,
      lng: -93.94536,
      city: 'Odessa',
      state: 'MO',
    },
    64077: {
      lat: 39.2224,
      lng: -94.13887,
      city: 'Orrick',
      state: 'MO',
    },
    64078: {
      lat: 38.70711,
      lng: -94.46375,
      city: 'Peculiar',
      state: 'MO',
    },
    64079: {
      lat: 39.36267,
      lng: -94.79351,
      city: 'Platte City',
      state: 'MO',
    },
    64080: {
      lat: 38.7691,
      lng: -94.25639,
      city: 'Pleasant Hill',
      state: 'MO',
    },
    64081: {
      lat: 38.90971,
      lng: -94.40953,
      city: 'Lees Summit',
      state: 'MO',
    },
    64082: {
      lat: 38.86601,
      lng: -94.4039,
      city: 'Lees Summit',
      state: 'MO',
    },
    64083: {
      lat: 38.80082,
      lng: -94.4483,
      city: 'Raymore',
      state: 'MO',
    },
    64084: {
      lat: 39.38697,
      lng: -94.0653,
      city: 'Rayville',
      state: 'MO',
    },
    64085: {
      lat: 39.33838,
      lng: -93.95158,
      city: 'Richmond',
      state: 'MO',
    },
    64086: {
      lat: 38.91181,
      lng: -94.29293,
      city: 'Lees Summit',
      state: 'MO',
    },
    64088: {
      lat: 39.16173,
      lng: -94.1848,
      city: 'Sibley',
      state: 'MO',
    },
    64089: {
      lat: 39.39007,
      lng: -94.56483,
      city: 'Smithville',
      state: 'MO',
    },
    64090: {
      lat: 38.75991,
      lng: -94.16387,
      city: 'Strasburg',
      state: 'MO',
    },
    64092: {
      lat: 39.22248,
      lng: -94.8088,
      city: 'Waldron',
      state: 'MO',
    },
    64093: {
      lat: 38.79057,
      lng: -93.73206,
      city: 'Warrensburg',
      state: 'MO',
    },
    64096: {
      lat: 39.20176,
      lng: -93.55611,
      city: 'Waverly',
      state: 'MO',
    },
    64097: {
      lat: 39.11237,
      lng: -93.99146,
      city: 'Wellington',
      state: 'MO',
    },
    64098: {
      lat: 39.45661,
      lng: -94.89456,
      city: 'Weston',
      state: 'MO',
    },
    64101: {
      lat: 39.10355,
      lng: -94.60051,
      city: 'Kansas City',
      state: 'MO',
    },
    64102: {
      lat: 39.09433,
      lng: -94.60329,
      city: 'Kansas City',
      state: 'MO',
    },
    64105: {
      lat: 39.10599,
      lng: -94.58985,
      city: 'Kansas City',
      state: 'MO',
    },
    64106: {
      lat: 39.10526,
      lng: -94.57013,
      city: 'Kansas City',
      state: 'MO',
    },
    64108: {
      lat: 39.08413,
      lng: -94.58449,
      city: 'Kansas City',
      state: 'MO',
    },
    64109: {
      lat: 39.06646,
      lng: -94.56709,
      city: 'Kansas City',
      state: 'MO',
    },
    64110: {
      lat: 39.03445,
      lng: -94.57265,
      city: 'Kansas City',
      state: 'MO',
    },
    64111: {
      lat: 39.05707,
      lng: -94.59393,
      city: 'Kansas City',
      state: 'MO',
    },
    64112: {
      lat: 39.03599,
      lng: -94.59529,
      city: 'Kansas City',
      state: 'MO',
    },
    64113: {
      lat: 39.01414,
      lng: -94.5956,
      city: 'Kansas City',
      state: 'MO',
    },
    64114: {
      lat: 38.9581,
      lng: -94.59715,
      city: 'Kansas City',
      state: 'MO',
    },
    64116: {
      lat: 39.14875,
      lng: -94.57444,
      city: 'Kansas City',
      state: 'MO',
    },
    64117: {
      lat: 39.16522,
      lng: -94.52251,
      city: 'Kansas City',
      state: 'MO',
    },
    64118: {
      lat: 39.21301,
      lng: -94.57497,
      city: 'Kansas City',
      state: 'MO',
    },
    64119: {
      lat: 39.21137,
      lng: -94.5186,
      city: 'Kansas City',
      state: 'MO',
    },
    64120: {
      lat: 39.13142,
      lng: -94.51489,
      city: 'Kansas City',
      state: 'MO',
    },
    64123: {
      lat: 39.11436,
      lng: -94.52167,
      city: 'Kansas City',
      state: 'MO',
    },
    64124: {
      lat: 39.10718,
      lng: -94.5391,
      city: 'Kansas City',
      state: 'MO',
    },
    64125: {
      lat: 39.1065,
      lng: -94.49388,
      city: 'Kansas City',
      state: 'MO',
    },
    64126: {
      lat: 39.09095,
      lng: -94.49581,
      city: 'Kansas City',
      state: 'MO',
    },
    64127: {
      lat: 39.08912,
      lng: -94.53837,
      city: 'Kansas City',
      state: 'MO',
    },
    64128: {
      lat: 39.06555,
      lng: -94.53484,
      city: 'Kansas City',
      state: 'MO',
    },
    64129: {
      lat: 39.04935,
      lng: -94.49682,
      city: 'Kansas City',
      state: 'MO',
    },
    64130: {
      lat: 39.03345,
      lng: -94.54119,
      city: 'Kansas City',
      state: 'MO',
    },
    64131: {
      lat: 38.95723,
      lng: -94.57449,
      city: 'Kansas City',
      state: 'MO',
    },
    64132: {
      lat: 38.98551,
      lng: -94.5396,
      city: 'Kansas City',
      state: 'MO',
    },
    64133: {
      lat: 39.01406,
      lng: -94.45689,
      city: 'Kansas City',
      state: 'MO',
    },
    64134: {
      lat: 38.9308,
      lng: -94.48667,
      city: 'Kansas City',
      state: 'MO',
    },
    64136: {
      lat: 39.01361,
      lng: -94.40181,
      city: 'Kansas City',
      state: 'MO',
    },
    64137: {
      lat: 38.93196,
      lng: -94.54287,
      city: 'Kansas City',
      state: 'MO',
    },
    64138: {
      lat: 38.96852,
      lng: -94.47088,
      city: 'Kansas City',
      state: 'MO',
    },
    64139: {
      lat: 38.96832,
      lng: -94.40677,
      city: 'Kansas City',
      state: 'MO',
    },
    64145: {
      lat: 38.88046,
      lng: -94.59652,
      city: 'Kansas City',
      state: 'MO',
    },
    64146: {
      lat: 38.87888,
      lng: -94.57436,
      city: 'Kansas City',
      state: 'MO',
    },
    64147: {
      lat: 38.85387,
      lng: -94.54688,
      city: 'Kansas City',
      state: 'MO',
    },
    64149: {
      lat: 38.86524,
      lng: -94.46666,
      city: 'Kansas City',
      state: 'MO',
    },
    64150: {
      lat: 39.17052,
      lng: -94.6303,
      city: 'Riverside',
      state: 'MO',
    },
    64151: {
      lat: 39.21494,
      lng: -94.63115,
      city: 'Kansas City',
      state: 'MO',
    },
    64152: {
      lat: 39.21278,
      lng: -94.72271,
      city: 'Kansas City',
      state: 'MO',
    },
    64153: {
      lat: 39.27999,
      lng: -94.73148,
      city: 'Kansas City',
      state: 'MO',
    },
    64154: {
      lat: 39.27653,
      lng: -94.62967,
      city: 'Kansas City',
      state: 'MO',
    },
    64155: {
      lat: 39.28031,
      lng: -94.56801,
      city: 'Kansas City',
      state: 'MO',
    },
    64156: {
      lat: 39.2762,
      lng: -94.5195,
      city: 'Kansas City',
      state: 'MO',
    },
    64157: {
      lat: 39.27657,
      lng: -94.47103,
      city: 'Kansas City',
      state: 'MO',
    },
    64158: {
      lat: 39.23493,
      lng: -94.47839,
      city: 'Kansas City',
      state: 'MO',
    },
    64161: {
      lat: 39.16184,
      lng: -94.44943,
      city: 'Kansas City',
      state: 'MO',
    },
    64163: {
      lat: 39.33674,
      lng: -94.68922,
      city: 'Kansas City',
      state: 'MO',
    },
    64164: {
      lat: 39.32988,
      lng: -94.63113,
      city: 'Kansas City',
      state: 'MO',
    },
    64165: {
      lat: 39.32081,
      lng: -94.57749,
      city: 'Kansas City',
      state: 'MO',
    },
    64166: {
      lat: 39.32215,
      lng: -94.52301,
      city: 'Kansas City',
      state: 'MO',
    },
    64167: {
      lat: 39.31939,
      lng: -94.48187,
      city: 'Kansas City',
      state: 'MO',
    },
    64401: {
      lat: 39.63452,
      lng: -94.71522,
      city: 'Agency',
      state: 'MO',
    },
    64402: {
      lat: 40.25201,
      lng: -94.32276,
      city: 'Albany',
      state: 'MO',
    },
    64420: {
      lat: 40.48645,
      lng: -94.2892,
      city: 'Allendale',
      state: 'MO',
    },
    64421: {
      lat: 39.9082,
      lng: -94.92779,
      city: 'Amazonia',
      state: 'MO',
    },
    64422: { lat: 39.90022, lng: -94.4941, city: 'Amity', state: 'MO' },
    64423: {
      lat: 40.18695,
      lng: -94.8552,
      city: 'Barnard',
      state: 'MO',
    },
    64424: {
      lat: 40.26453,
      lng: -94.03242,
      city: 'Bethany',
      state: 'MO',
    },
    64426: {
      lat: 40.51747,
      lng: -93.86158,
      city: 'Blythedale',
      state: 'MO',
    },
    64427: {
      lat: 40.10828,
      lng: -94.84136,
      city: 'Bolckow',
      state: 'MO',
    },
    64428: {
      lat: 40.4418,
      lng: -95.08836,
      city: 'Burlington Junction',
      state: 'MO',
    },
    64429: {
      lat: 39.73895,
      lng: -94.2285,
      city: 'Cameron',
      state: 'MO',
    },
    64430: {
      lat: 39.83771,
      lng: -94.56924,
      city: 'Clarksdale',
      state: 'MO',
    },
    64431: {
      lat: 40.53046,
      lng: -94.99357,
      city: 'Clearmont',
      state: 'MO',
    },
    64432: {
      lat: 40.26399,
      lng: -94.66119,
      city: 'Clyde',
      state: 'MO',
    },
    64433: {
      lat: 40.24107,
      lng: -94.68053,
      city: 'Conception',
      state: 'MO',
    },
    64434: {
      lat: 40.2584,
      lng: -94.73615,
      city: 'Conception Junction',
      state: 'MO',
    },
    64436: {
      lat: 39.85362,
      lng: -94.69034,
      city: 'Cosby',
      state: 'MO',
    },
    64437: {
      lat: 40.14292,
      lng: -95.35546,
      city: 'Craig',
      state: 'MO',
    },
    64438: {
      lat: 40.16106,
      lng: -94.41091,
      city: 'Darlington',
      state: 'MO',
    },
    64439: {
      lat: 39.52371,
      lng: -94.74986,
      city: 'Dearborn',
      state: 'MO',
    },
    64440: {
      lat: 39.57849,
      lng: -94.91841,
      city: 'De Kalb',
      state: 'MO',
    },
    64441: {
      lat: 40.39883,
      lng: -94.2642,
      city: 'Denver',
      state: 'MO',
    },
    64442: {
      lat: 40.49935,
      lng: -94.00645,
      city: 'Eagleville',
      state: 'MO',
    },
    64443: {
      lat: 39.74724,
      lng: -94.65205,
      city: 'Easton',
      state: 'MO',
    },
    64444: {
      lat: 39.48334,
      lng: -94.63856,
      city: 'Edgerton',
      state: 'MO',
    },
    64445: { lat: 40.53533, lng: -95.13881, city: 'Elmo', state: 'MO' },
    64446: {
      lat: 40.32176,
      lng: -95.40297,
      city: 'Fairfax',
      state: 'MO',
    },
    64448: {
      lat: 39.60258,
      lng: -94.81856,
      city: 'Faucett',
      state: 'MO',
    },
    64449: {
      lat: 40.04135,
      lng: -94.97754,
      city: 'Fillmore',
      state: 'MO',
    },
    64451: {
      lat: 39.99067,
      lng: -95.20664,
      city: 'Forest City',
      state: 'MO',
    },
    64453: {
      lat: 40.34471,
      lng: -94.46947,
      city: 'Gentry',
      state: 'MO',
    },
    64454: {
      lat: 39.60771,
      lng: -94.59423,
      city: 'Gower',
      state: 'MO',
    },
    64455: {
      lat: 40.18592,
      lng: -95.00594,
      city: 'Graham',
      state: 'MO',
    },
    64456: {
      lat: 40.49433,
      lng: -94.38733,
      city: 'Grant City',
      state: 'MO',
    },
    64457: {
      lat: 40.15886,
      lng: -94.68368,
      city: 'Guilford',
      state: 'MO',
    },
    64458: {
      lat: 40.52246,
      lng: -94.14491,
      city: 'Hatfield',
      state: 'MO',
    },
    64459: {
      lat: 39.93129,
      lng: -94.65017,
      city: 'Helena',
      state: 'MO',
    },
    64461: {
      lat: 40.52459,
      lng: -94.81063,
      city: 'Hopkins',
      state: 'MO',
    },
    64463: {
      lat: 40.06526,
      lng: -94.4869,
      city: 'King City',
      state: 'MO',
    },
    64465: {
      lat: 39.54853,
      lng: -94.29525,
      city: 'Lathrop',
      state: 'MO',
    },
    64466: {
      lat: 40.16865,
      lng: -95.1067,
      city: 'Maitland',
      state: 'MO',
    },
    64467: {
      lat: 40.39772,
      lng: -94.16236,
      city: 'Martinsville',
      state: 'MO',
    },
    64468: {
      lat: 40.34241,
      lng: -94.8811,
      city: 'Maryville',
      state: 'MO',
    },
    64469: {
      lat: 39.93085,
      lng: -94.35762,
      city: 'Maysville',
      state: 'MO',
    },
    64470: {
      lat: 40.17036,
      lng: -95.22138,
      city: 'Mound City',
      state: 'MO',
    },
    64471: {
      lat: 40.26548,
      lng: -94.1936,
      city: 'New Hampton',
      state: 'MO',
    },
    64473: {
      lat: 39.97606,
      lng: -95.08446,
      city: 'Oregon',
      state: 'MO',
    },
    64474: {
      lat: 39.75829,
      lng: -94.38103,
      city: 'Osborn',
      state: 'MO',
    },
    64475: {
      lat: 40.43561,
      lng: -94.63049,
      city: 'Parnell',
      state: 'MO',
    },
    64476: {
      lat: 40.45115,
      lng: -94.83034,
      city: 'Pickering',
      state: 'MO',
    },
    64477: {
      lat: 39.57872,
      lng: -94.45289,
      city: 'Plattsburg',
      state: 'MO',
    },
    64479: {
      lat: 40.34853,
      lng: -94.66636,
      city: 'Ravenwood',
      state: 'MO',
    },
    64480: { lat: 40.06102, lng: -94.70826, city: 'Rea', state: 'MO' },
    64481: {
      lat: 40.35622,
      lng: -93.8957,
      city: 'Ridgeway',
      state: 'MO',
    },
    64482: {
      lat: 40.45345,
      lng: -95.54329,
      city: 'Rock Port',
      state: 'MO',
    },
    64483: {
      lat: 40.04335,
      lng: -94.83471,
      city: 'Rosendale',
      state: 'MO',
    },
    64484: {
      lat: 39.55638,
      lng: -95.01974,
      city: 'Rushville',
      state: 'MO',
    },
    64485: {
      lat: 39.95325,
      lng: -94.8184,
      city: 'Savannah',
      state: 'MO',
    },
    64486: {
      lat: 40.52951,
      lng: -94.61471,
      city: 'Sheridan',
      state: 'MO',
    },
    64487: {
      lat: 40.31218,
      lng: -95.10293,
      city: 'Skidmore',
      state: 'MO',
    },
    64489: {
      lat: 40.23543,
      lng: -94.54969,
      city: 'Stanberry',
      state: 'MO',
    },
    64490: {
      lat: 39.73837,
      lng: -94.50984,
      city: 'Stewartsville',
      state: 'MO',
    },
    64491: {
      lat: 40.44868,
      lng: -95.34499,
      city: 'Tarkio',
      state: 'MO',
    },
    64492: {
      lat: 39.48067,
      lng: -94.53363,
      city: 'Trimble',
      state: 'MO',
    },
    64493: {
      lat: 39.63058,
      lng: -94.30454,
      city: 'Turney',
      state: 'MO',
    },
    64494: {
      lat: 39.99234,
      lng: -94.61465,
      city: 'Union Star',
      state: 'MO',
    },
    64496: {
      lat: 40.49563,
      lng: -95.62372,
      city: 'Watson',
      state: 'MO',
    },
    64497: {
      lat: 39.92929,
      lng: -94.22275,
      city: 'Weatherby',
      state: 'MO',
    },
    64498: {
      lat: 40.54023,
      lng: -95.31255,
      city: 'Westboro',
      state: 'MO',
    },
    64499: {
      lat: 40.39707,
      lng: -94.41521,
      city: 'Worth',
      state: 'MO',
    },
    64501: {
      lat: 39.76584,
      lng: -94.84448,
      city: 'Saint Joseph',
      state: 'MO',
    },
    64503: {
      lat: 39.74973,
      lng: -94.84545,
      city: 'Saint Joseph',
      state: 'MO',
    },
    64504: {
      lat: 39.68605,
      lng: -94.91264,
      city: 'Saint Joseph',
      state: 'MO',
    },
    64505: {
      lat: 39.84431,
      lng: -94.83354,
      city: 'Saint Joseph',
      state: 'MO',
    },
    64506: {
      lat: 39.79048,
      lng: -94.80277,
      city: 'Saint Joseph',
      state: 'MO',
    },
    64507: {
      lat: 39.73324,
      lng: -94.75735,
      city: 'Saint Joseph',
      state: 'MO',
    },
    64601: {
      lat: 39.8044,
      lng: -93.57334,
      city: 'Chillicothe',
      state: 'MO',
    },
    64620: {
      lat: 39.89617,
      lng: -94.09094,
      city: 'Altamont',
      state: 'MO',
    },
    64622: {
      lat: 39.49535,
      lng: -93.54569,
      city: 'Bogard',
      state: 'MO',
    },
    64623: {
      lat: 39.47,
      lng: -93.33344,
      city: 'Bosworth',
      state: 'MO',
    },
    64624: {
      lat: 39.57189,
      lng: -93.7848,
      city: 'Braymer',
      state: 'MO',
    },
    64625: {
      lat: 39.74378,
      lng: -93.80527,
      city: 'Breckenridge',
      state: 'MO',
    },
    64628: {
      lat: 39.79711,
      lng: -93.05002,
      city: 'Brookfield',
      state: 'MO',
    },
    64630: {
      lat: 40.03726,
      lng: -93.15435,
      city: 'Browning',
      state: 'MO',
    },
    64631: {
      lat: 39.82018,
      lng: -92.88213,
      city: 'Bucklin',
      state: 'MO',
    },
    64632: {
      lat: 40.46915,
      lng: -93.76862,
      city: 'Cainsville',
      state: 'MO',
    },
    64633: {
      lat: 39.35951,
      lng: -93.48171,
      city: 'Carrollton',
      state: 'MO',
    },
    64635: {
      lat: 39.93943,
      lng: -93.43632,
      city: 'Chula',
      state: 'MO',
    },
    64636: {
      lat: 40.11989,
      lng: -93.98769,
      city: 'Coffey',
      state: 'MO',
    },
    64637: {
      lat: 39.57439,
      lng: -93.91573,
      city: 'Cowgill',
      state: 'MO',
    },
    64638: { lat: 39.61894, lng: -93.61498, city: 'Dawn', state: 'MO' },
    64639: {
      lat: 39.3962,
      lng: -93.22276,
      city: 'De Witt',
      state: 'MO',
    },
    64640: {
      lat: 39.90172,
      lng: -93.94542,
      city: 'Gallatin',
      state: 'MO',
    },
    64641: { lat: 40.16221, lng: -93.3992, city: 'Galt', state: 'MO' },
    64642: {
      lat: 40.16979,
      lng: -93.83574,
      city: 'Gilman City',
      state: 'MO',
    },
    64643: { lat: 39.61366, lng: -93.36883, city: 'Hale', state: 'MO' },
    64644: {
      lat: 39.7418,
      lng: -93.97384,
      city: 'Hamilton',
      state: 'MO',
    },
    64645: {
      lat: 40.29834,
      lng: -93.32575,
      city: 'Harris',
      state: 'MO',
    },
    64646: {
      lat: 40.10456,
      lng: -93.30819,
      city: 'Humphreys',
      state: 'MO',
    },
    64647: {
      lat: 40.03016,
      lng: -93.96862,
      city: 'Jameson',
      state: 'MO',
    },
    64648: {
      lat: 39.98517,
      lng: -93.79922,
      city: 'Jamesport',
      state: 'MO',
    },
    64649: {
      lat: 39.77428,
      lng: -94.09183,
      city: 'Kidder',
      state: 'MO',
    },
    64650: {
      lat: 39.63716,
      lng: -94.0548,
      city: 'Kingston',
      state: 'MO',
    },
    64651: {
      lat: 39.7759,
      lng: -93.1797,
      city: 'Laclede',
      state: 'MO',
    },
    64652: {
      lat: 40.01979,
      lng: -93.42103,
      city: 'Laredo',
      state: 'MO',
    },
    64653: {
      lat: 39.89403,
      lng: -93.20403,
      city: 'Linneus',
      state: 'MO',
    },
    64654: {
      lat: 39.84864,
      lng: -93.77552,
      city: 'Lock Springs',
      state: 'MO',
    },
    64655: {
      lat: 40.44017,
      lng: -93.23684,
      city: 'Lucerne',
      state: 'MO',
    },
    64656: {
      lat: 39.67803,
      lng: -93.69375,
      city: 'Ludlow',
      state: 'MO',
    },
    64657: {
      lat: 40.13269,
      lng: -94.21292,
      city: 'McFall',
      state: 'MO',
    },
    64658: {
      lat: 39.66124,
      lng: -92.92181,
      city: 'Marceline',
      state: 'MO',
    },
    64659: {
      lat: 39.78138,
      lng: -93.29224,
      city: 'Meadville',
      state: 'MO',
    },
    64660: {
      lat: 39.57226,
      lng: -93.08923,
      city: 'Mendon',
      state: 'MO',
    },
    64661: {
      lat: 40.53031,
      lng: -93.51901,
      city: 'Mercer',
      state: 'MO',
    },
    64664: {
      lat: 39.74601,
      lng: -93.66027,
      city: 'Mooresville',
      state: 'MO',
    },
    64667: {
      lat: 40.39434,
      lng: -93.33618,
      city: 'Newtown',
      state: 'MO',
    },
    64668: {
      lat: 39.35226,
      lng: -93.70704,
      city: 'Norborne',
      state: 'MO',
    },
    64670: {
      lat: 40.04986,
      lng: -94.13833,
      city: 'Pattonsburg',
      state: 'MO',
    },
    64671: { lat: 39.53655, lng: -94.0518, city: 'Polo', state: 'MO' },
    64672: {
      lat: 40.53869,
      lng: -93.26015,
      city: 'Powersville',
      state: 'MO',
    },
    64673: {
      lat: 40.38001,
      lng: -93.59094,
      city: 'Princeton',
      state: 'MO',
    },
    64674: {
      lat: 39.96491,
      lng: -93.15884,
      city: 'Purdin',
      state: 'MO',
    },
    64676: {
      lat: 39.6471,
      lng: -93.03729,
      city: 'Rothville',
      state: 'MO',
    },
    64679: {
      lat: 40.23246,
      lng: -93.58181,
      city: 'Spickard',
      state: 'MO',
    },
    64681: {
      lat: 39.64706,
      lng: -93.21375,
      city: 'Sumner',
      state: 'MO',
    },
    64682: { lat: 39.55251, lng: -93.49132, city: 'Tina', state: 'MO' },
    64683: {
      lat: 40.07395,
      lng: -93.60346,
      city: 'Trenton',
      state: 'MO',
    },
    64686: {
      lat: 39.75005,
      lng: -93.62449,
      city: 'Utica',
      state: 'MO',
    },
    64688: {
      lat: 39.8139,
      lng: -93.37664,
      city: 'Wheeling',
      state: 'MO',
    },
    64689: {
      lat: 39.87461,
      lng: -94.152,
      city: 'Winston',
      state: 'MO',
    },
    64701: {
      lat: 38.62053,
      lng: -94.3378,
      city: 'Harrisonville',
      state: 'MO',
    },
    64720: {
      lat: 38.40533,
      lng: -94.33742,
      city: 'Adrian',
      state: 'MO',
    },
    64722: {
      lat: 38.27388,
      lng: -94.55188,
      city: 'Amoret',
      state: 'MO',
    },
    64723: {
      lat: 38.3615,
      lng: -94.55298,
      city: 'Amsterdam',
      state: 'MO',
    },
    64724: {
      lat: 38.15188,
      lng: -94.01025,
      city: 'Appleton City',
      state: 'MO',
    },
    64725: {
      lat: 38.49482,
      lng: -94.36609,
      city: 'Archie',
      state: 'MO',
    },
    64726: {
      lat: 38.52921,
      lng: -93.92492,
      city: 'Blairstown',
      state: 'MO',
    },
    64728: {
      lat: 37.70217,
      lng: -94.5174,
      city: 'Bronaugh',
      state: 'MO',
    },
    64730: {
      lat: 38.25789,
      lng: -94.29236,
      city: 'Butler',
      state: 'MO',
    },
    64733: {
      lat: 38.58016,
      lng: -93.8283,
      city: 'Chilhowee',
      state: 'MO',
    },
    64734: {
      lat: 38.64888,
      lng: -94.57405,
      city: 'Cleveland',
      state: 'MO',
    },
    64735: {
      lat: 38.36612,
      lng: -93.73644,
      city: 'Clinton',
      state: 'MO',
    },
    64738: {
      lat: 37.90098,
      lng: -93.65987,
      city: 'Collins',
      state: 'MO',
    },
    64739: {
      lat: 38.51139,
      lng: -94.09389,
      city: 'Creighton',
      state: 'MO',
    },
    64740: {
      lat: 38.22349,
      lng: -93.71204,
      city: 'Deepwater',
      state: 'MO',
    },
    64741: {
      lat: 37.81671,
      lng: -94.5629,
      city: 'Deerfield',
      state: 'MO',
    },
    64742: {
      lat: 38.50034,
      lng: -94.54329,
      city: 'Drexel',
      state: 'MO',
    },
    64743: {
      lat: 38.66596,
      lng: -94.21827,
      city: 'East Lynne',
      state: 'MO',
    },
    64744: {
      lat: 37.84963,
      lng: -93.97755,
      city: 'El Dorado Springs',
      state: 'MO',
    },
    64745: {
      lat: 38.15662,
      lng: -94.50384,
      city: 'Foster',
      state: 'MO',
    },
    64746: {
      lat: 38.61054,
      lng: -94.49304,
      city: 'Freeman',
      state: 'MO',
    },
    64747: {
      lat: 38.57353,
      lng: -94.17399,
      city: 'Garden City',
      state: 'MO',
    },
    64748: {
      lat: 37.36497,
      lng: -94.10017,
      city: 'Golden City',
      state: 'MO',
    },
    64750: {
      lat: 37.93033,
      lng: -94.11427,
      city: 'Harwood',
      state: 'MO',
    },
    64752: { lat: 38.0689, lng: -94.56862, city: 'Hume', state: 'MO' },
    64755: {
      lat: 37.33685,
      lng: -94.31112,
      city: 'Jasper',
      state: 'MO',
    },
    64756: {
      lat: 37.61931,
      lng: -94.01462,
      city: 'Jerico Springs',
      state: 'MO',
    },
    64759: {
      lat: 37.52655,
      lng: -94.26775,
      city: 'Lamar',
      state: 'MO',
    },
    64761: {
      lat: 38.59463,
      lng: -93.68657,
      city: 'Leeton',
      state: 'MO',
    },
    64762: {
      lat: 37.54628,
      lng: -94.50176,
      city: 'Liberal',
      state: 'MO',
    },
    64763: {
      lat: 38.14354,
      lng: -93.71573,
      city: 'Lowry City',
      state: 'MO',
    },
    64765: { lat: 37.9965, lng: -94.44297, city: 'Metz', state: 'MO' },
    64767: { lat: 37.7499, lng: -94.1997, city: 'Milo', state: 'MO' },
    64769: {
      lat: 37.48028,
      lng: -94.56688,
      city: 'Mindenmines',
      state: 'MO',
    },
    64770: {
      lat: 38.27528,
      lng: -93.98662,
      city: 'Montrose',
      state: 'MO',
    },
    64771: {
      lat: 37.77091,
      lng: -94.45967,
      city: 'Moundville',
      state: 'MO',
    },
    64772: {
      lat: 37.82712,
      lng: -94.33355,
      city: 'Nevada',
      state: 'MO',
    },
    64776: {
      lat: 38.04242,
      lng: -93.67469,
      city: 'Osceola',
      state: 'MO',
    },
    64778: {
      lat: 37.92283,
      lng: -94.4841,
      city: 'Richards',
      state: 'MO',
    },
    64779: {
      lat: 38.09214,
      lng: -94.40152,
      city: 'Rich Hill',
      state: 'MO',
    },
    64780: {
      lat: 38.06246,
      lng: -94.04276,
      city: 'Rockville',
      state: 'MO',
    },
    64781: {
      lat: 37.97837,
      lng: -93.81242,
      city: 'Roscoe',
      state: 'MO',
    },
    64783: {
      lat: 37.99972,
      lng: -94.10688,
      city: 'Schell City',
      state: 'MO',
    },
    64784: {
      lat: 37.66624,
      lng: -94.23131,
      city: 'Sheldon',
      state: 'MO',
    },
    64788: {
      lat: 38.42868,
      lng: -94.02045,
      city: 'Urich',
      state: 'MO',
    },
    64790: {
      lat: 37.92924,
      lng: -94.22969,
      city: 'Walker',
      state: 'MO',
    },
    64801: {
      lat: 37.11056,
      lng: -94.4974,
      city: 'Joplin',
      state: 'MO',
    },
    64804: {
      lat: 37.01955,
      lng: -94.51137,
      city: 'Joplin',
      state: 'MO',
    },
    64830: { lat: 37.2308, lng: -94.41053, city: 'Alba', state: 'MO' },
    64831: {
      lat: 36.65598,
      lng: -94.4541,
      city: 'Anderson',
      state: 'MO',
    },
    64832: {
      lat: 37.33951,
      lng: -94.57264,
      city: 'Asbury',
      state: 'MO',
    },
    64833: {
      lat: 37.1938,
      lng: -94.12982,
      city: 'Avilla',
      state: 'MO',
    },
    64834: {
      lat: 37.18714,
      lng: -94.56865,
      city: 'Carl Junction',
      state: 'MO',
    },
    64835: {
      lat: 37.14749,
      lng: -94.43824,
      city: 'Carterville',
      state: 'MO',
    },
    64836: {
      lat: 37.18886,
      lng: -94.28898,
      city: 'Carthage',
      state: 'MO',
    },
    64840: {
      lat: 37.01826,
      lng: -94.33536,
      city: 'Diamond',
      state: 'MO',
    },
    64841: {
      lat: 37.07873,
      lng: -94.41101,
      city: 'Duenweg',
      state: 'MO',
    },
    64842: {
      lat: 36.7914,
      lng: -94.10427,
      city: 'Fairview',
      state: 'MO',
    },
    64843: {
      lat: 36.73345,
      lng: -94.45319,
      city: 'Goodman',
      state: 'MO',
    },
    64844: {
      lat: 36.91388,
      lng: -94.24038,
      city: 'Granby',
      state: 'MO',
    },
    64847: {
      lat: 36.59217,
      lng: -94.43863,
      city: 'Lanagan',
      state: 'MO',
    },
    64848: {
      lat: 37.18061,
      lng: -93.99523,
      city: 'La Russell',
      state: 'MO',
    },
    64849: {
      lat: 37.2544,
      lng: -94.44529,
      city: 'Neck City',
      state: 'MO',
    },
    64850: {
      lat: 36.86008,
      lng: -94.3975,
      city: 'Neosho',
      state: 'MO',
    },
    64854: { lat: 36.54369, lng: -94.44144, city: 'Noel', state: 'MO' },
    64855: {
      lat: 37.29548,
      lng: -94.48637,
      city: 'Oronogo',
      state: 'MO',
    },
    64856: {
      lat: 36.5652,
      lng: -94.26346,
      city: 'Pineville',
      state: 'MO',
    },
    64857: {
      lat: 37.24587,
      lng: -94.43861,
      city: 'Purcell',
      state: 'MO',
    },
    64858: {
      lat: 36.89981,
      lng: -94.52888,
      city: 'Racine',
      state: 'MO',
    },
    64859: {
      lat: 37.15822,
      lng: -94.14703,
      city: 'Reeds',
      state: 'MO',
    },
    64861: {
      lat: 36.6953,
      lng: -94.14864,
      city: 'Rocky Comfort',
      state: 'MO',
    },
    64862: {
      lat: 37.10128,
      lng: -94.11469,
      city: 'Sarcoxie',
      state: 'MO',
    },
    64863: {
      lat: 36.55511,
      lng: -94.58444,
      city: 'South West City',
      state: 'MO',
    },
    64865: {
      lat: 36.84253,
      lng: -94.57797,
      city: 'Seneca',
      state: 'MO',
    },
    64866: {
      lat: 36.8677,
      lng: -94.14317,
      city: 'Stark City',
      state: 'MO',
    },
    64867: {
      lat: 36.73023,
      lng: -94.23316,
      city: 'Stella',
      state: 'MO',
    },
    64868: {
      lat: 36.68742,
      lng: -94.61285,
      city: 'Tiff City',
      state: 'MO',
    },
    64870: {
      lat: 37.18474,
      lng: -94.48136,
      city: 'Webb City',
      state: 'MO',
    },
    64873: {
      lat: 37.01565,
      lng: -94.05358,
      city: 'Wentworth',
      state: 'MO',
    },
    64874: {
      lat: 36.75617,
      lng: -94.05444,
      city: 'Wheaton',
      state: 'MO',
    },
    65001: {
      lat: 38.27141,
      lng: -92.00077,
      city: 'Argyle',
      state: 'MO',
    },
    65010: {
      lat: 38.78757,
      lng: -92.24301,
      city: 'Ashland',
      state: 'MO',
    },
    65011: {
      lat: 38.39493,
      lng: -92.70815,
      city: 'Barnett',
      state: 'MO',
    },
    65013: {
      lat: 38.26868,
      lng: -91.75493,
      city: 'Belle',
      state: 'MO',
    },
    65014: { lat: 38.32184, lng: -91.6247, city: 'Bland', state: 'MO' },
    65016: {
      lat: 38.56167,
      lng: -91.91896,
      city: 'Bonnots Mill',
      state: 'MO',
    },
    65017: {
      lat: 38.09185,
      lng: -92.49548,
      city: 'Brumley',
      state: 'MO',
    },
    65018: {
      lat: 38.62038,
      lng: -92.56314,
      city: 'California',
      state: 'MO',
    },
    65020: {
      lat: 38.00517,
      lng: -92.78193,
      city: 'Camdenton',
      state: 'MO',
    },
    65023: {
      lat: 38.65274,
      lng: -92.39915,
      city: 'Centertown',
      state: 'MO',
    },
    65024: {
      lat: 38.62444,
      lng: -91.75712,
      city: 'Chamois',
      state: 'MO',
    },
    65025: {
      lat: 38.66618,
      lng: -92.68119,
      city: 'Clarksburg',
      state: 'MO',
    },
    65026: {
      lat: 38.32105,
      lng: -92.57099,
      city: 'Eldon',
      state: 'MO',
    },
    65032: {
      lat: 38.34024,
      lng: -92.37942,
      city: 'Eugene',
      state: 'MO',
    },
    65034: {
      lat: 38.55637,
      lng: -92.82183,
      city: 'Fortuna',
      state: 'MO',
    },
    65035: {
      lat: 38.36338,
      lng: -91.93585,
      city: 'Freeburg',
      state: 'MO',
    },
    65037: {
      lat: 38.23845,
      lng: -92.85749,
      city: 'Gravois Mills',
      state: 'MO',
    },
    65039: {
      lat: 38.71388,
      lng: -92.2984,
      city: 'Hartsburg',
      state: 'MO',
    },
    65040: {
      lat: 38.36367,
      lng: -92.31252,
      city: 'Henley',
      state: 'MO',
    },
    65041: {
      lat: 38.64088,
      lng: -91.47712,
      city: 'Hermann',
      state: 'MO',
    },
    65043: {
      lat: 38.62563,
      lng: -92.10774,
      city: 'Holts Summit',
      state: 'MO',
    },
    65046: {
      lat: 38.77658,
      lng: -92.47889,
      city: 'Jamestown',
      state: 'MO',
    },
    65047: {
      lat: 38.15483,
      lng: -92.57774,
      city: 'Kaiser',
      state: 'MO',
    },
    65048: {
      lat: 38.35745,
      lng: -92.0365,
      city: 'Koeltztown',
      state: 'MO',
    },
    65049: {
      lat: 38.20127,
      lng: -92.67067,
      city: 'Lake Ozark',
      state: 'MO',
    },
    65050: {
      lat: 38.5305,
      lng: -92.68867,
      city: 'Latham',
      state: 'MO',
    },
    65051: { lat: 38.46519, lng: -91.79758, city: 'Linn', state: 'MO' },
    65052: {
      lat: 38.05068,
      lng: -92.65568,
      city: 'Linn Creek',
      state: 'MO',
    },
    65053: {
      lat: 38.54132,
      lng: -92.36966,
      city: 'Lohman',
      state: 'MO',
    },
    65054: {
      lat: 38.49103,
      lng: -91.95003,
      city: 'Loose Creek',
      state: 'MO',
    },
    65058: { lat: 38.25777, lng: -92.12946, city: 'Meta', state: 'MO' },
    65059: {
      lat: 38.70365,
      lng: -91.88447,
      city: 'Mokane',
      state: 'MO',
    },
    65061: {
      lat: 38.59773,
      lng: -91.638,
      city: 'Morrison',
      state: 'MO',
    },
    65062: {
      lat: 38.49486,
      lng: -91.65059,
      city: 'Mount Sterling',
      state: 'MO',
    },
    65063: {
      lat: 38.72635,
      lng: -92.08743,
      city: 'New Bloomfield',
      state: 'MO',
    },
    65064: {
      lat: 38.40634,
      lng: -92.47664,
      city: 'Olean',
      state: 'MO',
    },
    65065: {
      lat: 38.13462,
      lng: -92.66593,
      city: 'Osage Beach',
      state: 'MO',
    },
    65066: {
      lat: 38.3454,
      lng: -91.48898,
      city: 'Owensville',
      state: 'MO',
    },
    65067: {
      lat: 38.75365,
      lng: -91.72369,
      city: 'Portland',
      state: 'MO',
    },
    65068: {
      lat: 38.82757,
      lng: -92.59909,
      city: 'Prairie Home',
      state: 'MO',
    },
    65069: {
      lat: 38.75573,
      lng: -91.5966,
      city: 'Rhineland',
      state: 'MO',
    },
    65072: {
      lat: 38.26635,
      lng: -92.72178,
      city: 'Rocky Mount',
      state: 'MO',
    },
    65074: {
      lat: 38.47967,
      lng: -92.47306,
      city: 'Russellville',
      state: 'MO',
    },
    65075: {
      lat: 38.24849,
      lng: -92.26388,
      city: 'Saint Elizabeth',
      state: 'MO',
    },
    65076: {
      lat: 38.37064,
      lng: -92.20376,
      city: 'Saint Thomas',
      state: 'MO',
    },
    65077: {
      lat: 38.7691,
      lng: -91.80305,
      city: 'Steedman',
      state: 'MO',
    },
    65078: {
      lat: 38.38473,
      lng: -93.01085,
      city: 'Stover',
      state: 'MO',
    },
    65079: {
      lat: 38.15283,
      lng: -92.75225,
      city: 'Sunrise Beach',
      state: 'MO',
    },
    65080: {
      lat: 38.63231,
      lng: -91.97713,
      city: 'Tebbetts',
      state: 'MO',
    },
    65081: {
      lat: 38.64086,
      lng: -92.78424,
      city: 'Tipton',
      state: 'MO',
    },
    65082: {
      lat: 38.22243,
      lng: -92.42632,
      city: 'Tuscumbia',
      state: 'MO',
    },
    65083: {
      lat: 38.14318,
      lng: -92.44089,
      city: 'Ulman',
      state: 'MO',
    },
    65084: {
      lat: 38.43574,
      lng: -92.85119,
      city: 'Versailles',
      state: 'MO',
    },
    65085: {
      lat: 38.40304,
      lng: -92.07272,
      city: 'Westphalia',
      state: 'MO',
    },
    65101: {
      lat: 38.49143,
      lng: -92.15009,
      city: 'Jefferson City',
      state: 'MO',
    },
    65109: {
      lat: 38.57365,
      lng: -92.27791,
      city: 'Jefferson City',
      state: 'MO',
    },
    65201: {
      lat: 38.89595,
      lng: -92.24365,
      city: 'Columbia',
      state: 'MO',
    },
    65202: {
      lat: 39.01721,
      lng: -92.29822,
      city: 'Columbia',
      state: 'MO',
    },
    65203: {
      lat: 38.89497,
      lng: -92.39928,
      city: 'Columbia',
      state: 'MO',
    },
    65215: {
      lat: 38.95171,
      lng: -92.32043,
      city: 'Columbia',
      state: 'MO',
    },
    65216: {
      lat: 38.95818,
      lng: -92.32629,
      city: 'Columbia',
      state: 'MO',
    },
    65230: {
      lat: 39.28715,
      lng: -92.69218,
      city: 'Armstrong',
      state: 'MO',
    },
    65231: {
      lat: 39.01773,
      lng: -91.90803,
      city: 'Auxvasse',
      state: 'MO',
    },
    65232: {
      lat: 39.15901,
      lng: -91.75516,
      city: 'Benton City',
      state: 'MO',
    },
    65233: {
      lat: 38.91528,
      lng: -92.72035,
      city: 'Boonville',
      state: 'MO',
    },
    65236: {
      lat: 39.44596,
      lng: -93.08305,
      city: 'Brunswick',
      state: 'MO',
    },
    65237: {
      lat: 38.78078,
      lng: -92.78438,
      city: 'Bunceton',
      state: 'MO',
    },
    65239: {
      lat: 39.53312,
      lng: -92.42334,
      city: 'Cairo',
      state: 'MO',
    },
    65240: {
      lat: 39.22085,
      lng: -92.13026,
      city: 'Centralia',
      state: 'MO',
    },
    65243: {
      lat: 39.27654,
      lng: -92.35207,
      city: 'Clark',
      state: 'MO',
    },
    65244: {
      lat: 39.4807,
      lng: -92.65982,
      city: 'Clifton Hill',
      state: 'MO',
    },
    65246: {
      lat: 39.37725,
      lng: -93.00059,
      city: 'Dalton',
      state: 'MO',
    },
    65247: {
      lat: 39.63502,
      lng: -92.47296,
      city: 'Excello',
      state: 'MO',
    },
    65248: {
      lat: 39.13952,
      lng: -92.65894,
      city: 'Fayette',
      state: 'MO',
    },
    65250: {
      lat: 39.03524,
      lng: -92.83991,
      city: 'Franklin',
      state: 'MO',
    },
    65251: {
      lat: 38.84902,
      lng: -91.97911,
      city: 'Fulton',
      state: 'MO',
    },
    65254: {
      lat: 39.21228,
      lng: -92.85351,
      city: 'Glasgow',
      state: 'MO',
    },
    65255: {
      lat: 39.09632,
      lng: -92.23463,
      city: 'Hallsville',
      state: 'MO',
    },
    65256: {
      lat: 39.12713,
      lng: -92.45568,
      city: 'Harrisburg',
      state: 'MO',
    },
    65257: {
      lat: 39.28404,
      lng: -92.53112,
      city: 'Higbee',
      state: 'MO',
    },
    65258: {
      lat: 39.52339,
      lng: -92.13706,
      city: 'Holliday',
      state: 'MO',
    },
    65259: {
      lat: 39.47122,
      lng: -92.56536,
      city: 'Huntsville',
      state: 'MO',
    },
    65260: {
      lat: 39.58806,
      lng: -92.40227,
      city: 'Jacksonville',
      state: 'MO',
    },
    65261: {
      lat: 39.47542,
      lng: -92.92388,
      city: 'Keytesville',
      state: 'MO',
    },
    65262: {
      lat: 38.9548,
      lng: -91.95285,
      city: 'Kingdom City',
      state: 'MO',
    },
    65263: {
      lat: 39.45244,
      lng: -92.22062,
      city: 'Madison',
      state: 'MO',
    },
    65264: {
      lat: 39.08852,
      lng: -91.69544,
      city: 'Martinsburg',
      state: 'MO',
    },
    65265: {
      lat: 39.20531,
      lng: -91.88728,
      city: 'Mexico',
      state: 'MO',
    },
    65270: {
      lat: 39.41552,
      lng: -92.39917,
      city: 'Moberly',
      state: 'MO',
    },
    65274: {
      lat: 39.01342,
      lng: -92.68321,
      city: 'New Franklin',
      state: 'MO',
    },
    65275: {
      lat: 39.45935,
      lng: -91.99414,
      city: 'Paris',
      state: 'MO',
    },
    65276: {
      lat: 38.84581,
      lng: -92.95498,
      city: 'Pilot Grove',
      state: 'MO',
    },
    65278: {
      lat: 39.34217,
      lng: -92.41251,
      city: 'Renick',
      state: 'MO',
    },
    65279: {
      lat: 39.01284,
      lng: -92.52062,
      city: 'Rocheport',
      state: 'MO',
    },
    65280: {
      lat: 39.23487,
      lng: -91.75048,
      city: 'Rush Hill',
      state: 'MO',
    },
    65281: {
      lat: 39.46431,
      lng: -92.79099,
      city: 'Salisbury',
      state: 'MO',
    },
    65282: {
      lat: 39.38975,
      lng: -91.83302,
      city: 'Santa Fe',
      state: 'MO',
    },
    65283: {
      lat: 39.50811,
      lng: -91.83449,
      city: 'Stoutsville',
      state: 'MO',
    },
    65284: {
      lat: 39.19658,
      lng: -92.30361,
      city: 'Sturgeon',
      state: 'MO',
    },
    65285: {
      lat: 39.2067,
      lng: -92.02056,
      city: 'Thompson',
      state: 'MO',
    },
    65286: {
      lat: 39.50616,
      lng: -93.22059,
      city: 'Triplett',
      state: 'MO',
    },
    65287: {
      lat: 38.88443,
      lng: -92.51953,
      city: 'Wooldridge',
      state: 'MO',
    },
    65301: {
      lat: 38.69922,
      lng: -93.22104,
      city: 'Sedalia',
      state: 'MO',
    },
    65305: {
      lat: 38.72944,
      lng: -93.55446,
      city: 'Whiteman Air Force Base',
      state: 'MO',
    },
    65320: {
      lat: 39.06606,
      lng: -92.94415,
      city: 'Arrow Rock',
      state: 'MO',
    },
    65321: {
      lat: 39.08604,
      lng: -93.4526,
      city: 'Blackburn',
      state: 'MO',
    },
    65322: {
      lat: 38.9729,
      lng: -92.95535,
      city: 'Blackwater',
      state: 'MO',
    },
    65323: {
      lat: 38.45289,
      lng: -93.6212,
      city: 'Calhoun',
      state: 'MO',
    },
    65324: {
      lat: 38.11104,
      lng: -92.99997,
      city: 'Climax Springs',
      state: 'MO',
    },
    65325: {
      lat: 38.43239,
      lng: -93.18535,
      city: 'Cole Camp',
      state: 'MO',
    },
    65326: {
      lat: 38.16271,
      lng: -93.1458,
      city: 'Edwards',
      state: 'MO',
    },
    65327: { lat: 38.97525, lng: -93.48909, city: 'Emma', state: 'MO' },
    65329: {
      lat: 38.60937,
      lng: -92.96132,
      city: 'Florence',
      state: 'MO',
    },
    65330: {
      lat: 39.22096,
      lng: -92.94945,
      city: 'Gilliam',
      state: 'MO',
    },
    65332: {
      lat: 38.61973,
      lng: -93.41324,
      city: 'Green Ridge',
      state: 'MO',
    },
    65333: {
      lat: 38.90687,
      lng: -93.30724,
      city: 'Houstonia',
      state: 'MO',
    },
    65334: {
      lat: 38.8348,
      lng: -93.24558,
      city: 'Hughesville',
      state: 'MO',
    },
    65335: {
      lat: 38.51338,
      lng: -93.32667,
      city: 'Ionia',
      state: 'MO',
    },
    65336: {
      lat: 38.78169,
      lng: -93.56241,
      city: 'Knob Noster',
      state: 'MO',
    },
    65337: {
      lat: 38.79202,
      lng: -93.42573,
      city: 'La Monte',
      state: 'MO',
    },
    65338: {
      lat: 38.36227,
      lng: -93.29523,
      city: 'Lincoln',
      state: 'MO',
    },
    65339: {
      lat: 39.19407,
      lng: -93.37671,
      city: 'Malta Bend',
      state: 'MO',
    },
    65340: {
      lat: 39.09149,
      lng: -93.18041,
      city: 'Marshall',
      state: 'MO',
    },
    65344: { lat: 39.31174, lng: -93.1976, city: 'Miami', state: 'MO' },
    65345: { lat: 38.54111, lng: -93.1254, city: 'Mora', state: 'MO' },
    65347: {
      lat: 38.98453,
      lng: -93.0495,
      city: 'Nelson',
      state: 'MO',
    },
    65348: {
      lat: 38.70963,
      lng: -92.98344,
      city: 'Otterville',
      state: 'MO',
    },
    65349: {
      lat: 39.22863,
      lng: -93.05581,
      city: 'Slater',
      state: 'MO',
    },
    65350: {
      lat: 38.65557,
      lng: -93.10038,
      city: 'Smithton',
      state: 'MO',
    },
    65351: {
      lat: 38.98445,
      lng: -93.38628,
      city: 'Sweet Springs',
      state: 'MO',
    },
    65354: {
      lat: 38.64841,
      lng: -92.88786,
      city: 'Syracuse',
      state: 'MO',
    },
    65355: {
      lat: 38.22137,
      lng: -93.37144,
      city: 'Warsaw',
      state: 'MO',
    },
    65360: {
      lat: 38.51989,
      lng: -93.51334,
      city: 'Windsor',
      state: 'MO',
    },
    65401: {
      lat: 37.90691,
      lng: -91.76374,
      city: 'Rolla',
      state: 'MO',
    },
    65409: {
      lat: 37.95103,
      lng: -91.77948,
      city: 'Rolla',
      state: 'MO',
    },
    65436: {
      lat: 37.62239,
      lng: -91.9726,
      city: 'Beulah',
      state: 'MO',
    },
    65438: {
      lat: 36.9375,
      lng: -91.50739,
      city: 'Birch Tree',
      state: 'MO',
    },
    65439: {
      lat: 37.65718,
      lng: -91.09535,
      city: 'Bixby',
      state: 'MO',
    },
    65440: { lat: 37.60388, lng: -91.18539, city: 'Boss', state: 'MO' },
    65441: {
      lat: 38.09394,
      lng: -91.15842,
      city: 'Bourbon',
      state: 'MO',
    },
    65443: {
      lat: 38.13089,
      lng: -92.0918,
      city: 'Brinktown',
      state: 'MO',
    },
    65444: {
      lat: 37.37772,
      lng: -92.06308,
      city: 'Bucyrus',
      state: 'MO',
    },
    65446: {
      lat: 37.80902,
      lng: -91.26999,
      city: 'Cherryville',
      state: 'MO',
    },
    65449: {
      lat: 37.84745,
      lng: -91.48722,
      city: 'Cook Sta',
      state: 'MO',
    },
    65452: {
      lat: 37.96271,
      lng: -92.26702,
      city: 'Crocker',
      state: 'MO',
    },
    65453: { lat: 38.10706, lng: -91.42509, city: 'Cuba', state: 'MO' },
    65456: {
      lat: 37.78048,
      lng: -91.20267,
      city: 'Davisville',
      state: 'MO',
    },
    65457: {
      lat: 37.82647,
      lng: -92.05047,
      city: 'Devils Elbow',
      state: 'MO',
    },
    65459: {
      lat: 38.02919,
      lng: -92.08337,
      city: 'Dixon',
      state: 'MO',
    },
    65461: { lat: 37.68406, lng: -92.02379, city: 'Duke', state: 'MO' },
    65462: {
      lat: 37.68654,
      lng: -91.86417,
      city: 'Edgar Springs',
      state: 'MO',
    },
    65463: {
      lat: 37.84536,
      lng: -92.7811,
      city: 'Eldridge',
      state: 'MO',
    },
    65464: {
      lat: 37.18353,
      lng: -91.89236,
      city: 'Elk Creek',
      state: 'MO',
    },
    65466: {
      lat: 37.2113,
      lng: -91.38645,
      city: 'Eminence',
      state: 'MO',
    },
    65468: {
      lat: 37.25434,
      lng: -91.79824,
      city: 'Eunice',
      state: 'MO',
    },
    65470: {
      lat: 37.54872,
      lng: -92.40287,
      city: 'Falcon',
      state: 'MO',
    },
    65473: {
      lat: 37.71215,
      lng: -92.14811,
      city: 'Fort Leonard Wood',
      state: 'MO',
    },
    65479: {
      lat: 37.3368,
      lng: -91.63382,
      city: 'Hartshorn',
      state: 'MO',
    },
    65483: {
      lat: 37.30463,
      lng: -91.94911,
      city: 'Houston',
      state: 'MO',
    },
    65484: {
      lat: 37.36348,
      lng: -92.22052,
      city: 'Huggins',
      state: 'MO',
    },
    65486: {
      lat: 38.10007,
      lng: -92.3107,
      city: 'Iberia',
      state: 'MO',
    },
    65501: {
      lat: 37.47319,
      lng: -91.5549,
      city: 'Jadwin',
      state: 'MO',
    },
    65529: {
      lat: 37.92912,
      lng: -91.98926,
      city: 'Jerome',
      state: 'MO',
    },
    65534: {
      lat: 37.70236,
      lng: -92.27656,
      city: 'Laquey',
      state: 'MO',
    },
    65535: {
      lat: 38.07194,
      lng: -91.2752,
      city: 'Leasburg',
      state: 'MO',
    },
    65536: {
      lat: 37.68942,
      lng: -92.64974,
      city: 'Lebanon',
      state: 'MO',
    },
    65541: {
      lat: 37.64401,
      lng: -91.75624,
      city: 'Lenox',
      state: 'MO',
    },
    65542: {
      lat: 37.50497,
      lng: -91.88432,
      city: 'Licking',
      state: 'MO',
    },
    65543: {
      lat: 37.47172,
      lng: -92.3045,
      city: 'Lynchburg',
      state: 'MO',
    },
    65548: {
      lat: 37.00545,
      lng: -91.71131,
      city: 'Mountain View',
      state: 'MO',
    },
    65550: {
      lat: 37.84425,
      lng: -91.9433,
      city: 'Newburg',
      state: 'MO',
    },
    65552: {
      lat: 37.53842,
      lng: -92.16701,
      city: 'Plato',
      state: 'MO',
    },
    65555: {
      lat: 37.35906,
      lng: -91.77723,
      city: 'Raymondville',
      state: 'MO',
    },
    65556: {
      lat: 37.83582,
      lng: -92.40541,
      city: 'Richland',
      state: 'MO',
    },
    65557: { lat: 37.51778, lng: -92.14024, city: 'Roby', state: 'MO' },
    65559: {
      lat: 38.0181,
      lng: -91.60664,
      city: 'Saint James',
      state: 'MO',
    },
    65560: {
      lat: 37.59609,
      lng: -91.49943,
      city: 'Salem',
      state: 'MO',
    },
    65564: { lat: 37.23476, lng: -91.95781, city: 'Solo', state: 'MO' },
    65565: {
      lat: 37.90573,
      lng: -91.23841,
      city: 'Steelville',
      state: 'MO',
    },
    65566: {
      lat: 37.76618,
      lng: -91.14689,
      city: 'Viburnum',
      state: 'MO',
    },
    65567: {
      lat: 37.85594,
      lng: -92.53699,
      city: 'Stoutland',
      state: 'MO',
    },
    65570: {
      lat: 37.45702,
      lng: -92.10115,
      city: 'Success',
      state: 'MO',
    },
    65571: {
      lat: 37.18501,
      lng: -91.64546,
      city: 'Summersville',
      state: 'MO',
    },
    65580: {
      lat: 38.11656,
      lng: -91.78617,
      city: 'Vichy',
      state: 'MO',
    },
    65582: {
      lat: 38.19177,
      lng: -91.92463,
      city: 'Vienna',
      state: 'MO',
    },
    65583: {
      lat: 37.82171,
      lng: -92.2541,
      city: 'Waynesville',
      state: 'MO',
    },
    65584: {
      lat: 37.82901,
      lng: -92.13086,
      city: 'Saint Robert',
      state: 'MO',
    },
    65586: {
      lat: 37.86483,
      lng: -91.43294,
      city: 'Wesco',
      state: 'MO',
    },
    65588: {
      lat: 36.96196,
      lng: -91.29987,
      city: 'Winona',
      state: 'MO',
    },
    65589: {
      lat: 37.23729,
      lng: -91.82747,
      city: 'Yukon',
      state: 'MO',
    },
    65590: {
      lat: 37.61687,
      lng: -92.95008,
      city: 'Long Lane',
      state: 'MO',
    },
    65591: {
      lat: 37.97941,
      lng: -92.59076,
      city: 'Montreal',
      state: 'MO',
    },
    65601: {
      lat: 37.53155,
      lng: -93.5869,
      city: 'Aldrich',
      state: 'MO',
    },
    65603: {
      lat: 37.54574,
      lng: -93.86072,
      city: 'Arcola',
      state: 'MO',
    },
    65604: {
      lat: 37.27348,
      lng: -93.60367,
      city: 'Ash Grove',
      state: 'MO',
    },
    65605: {
      lat: 36.88408,
      lng: -93.69132,
      city: 'Aurora',
      state: 'MO',
    },
    65606: { lat: 36.69634, lng: -91.3586, city: 'Alton', state: 'MO' },
    65608: { lat: 36.90456, lng: -92.66662, city: 'Ava', state: 'MO' },
    65609: {
      lat: 36.52593,
      lng: -92.1609,
      city: 'Bakersfield',
      state: 'MO',
    },
    65610: {
      lat: 37.03897,
      lng: -93.52986,
      city: 'Billings',
      state: 'MO',
    },
    65611: {
      lat: 36.54339,
      lng: -93.36127,
      city: 'Blue Eye',
      state: 'MO',
    },
    65612: {
      lat: 37.21897,
      lng: -93.54694,
      city: 'Bois D Arc',
      state: 'MO',
    },
    65613: {
      lat: 37.63557,
      lng: -93.39205,
      city: 'Bolivar',
      state: 'MO',
    },
    65614: {
      lat: 36.73739,
      lng: -92.88684,
      city: 'Bradleyville',
      state: 'MO',
    },
    65616: {
      lat: 36.67133,
      lng: -93.24887,
      city: 'Branson',
      state: 'MO',
    },
    65617: {
      lat: 37.4376,
      lng: -93.34063,
      city: 'Brighton',
      state: 'MO',
    },
    65618: {
      lat: 36.75157,
      lng: -92.38641,
      city: 'Brixey',
      state: 'MO',
    },
    65619: {
      lat: 37.12312,
      lng: -93.38864,
      city: 'Brookline',
      state: 'MO',
    },
    65620: {
      lat: 37.02045,
      lng: -92.95594,
      city: 'Bruner',
      state: 'MO',
    },
    65622: {
      lat: 37.6337,
      lng: -93.10775,
      city: 'Buffalo',
      state: 'MO',
    },
    65623: {
      lat: 36.7489,
      lng: -93.90659,
      city: 'Butterfield',
      state: 'MO',
    },
    65624: {
      lat: 36.73276,
      lng: -93.56241,
      city: 'Cape Fair',
      state: 'MO',
    },
    65625: {
      lat: 36.67635,
      lng: -93.8174,
      city: 'Cassville',
      state: 'MO',
    },
    65626: {
      lat: 36.59088,
      lng: -92.11697,
      city: 'Caulfield',
      state: 'MO',
    },
    65627: {
      lat: 36.56247,
      lng: -93.00613,
      city: 'Cedarcreek',
      state: 'MO',
    },
    65629: {
      lat: 36.89371,
      lng: -93.00294,
      city: 'Chadwick',
      state: 'MO',
    },
    65630: {
      lat: 36.83463,
      lng: -93.2204,
      city: 'Chestnutridge',
      state: 'MO',
    },
    65631: {
      lat: 37.00594,
      lng: -93.4232,
      city: 'Clever',
      state: 'MO',
    },
    65632: {
      lat: 37.49961,
      lng: -92.81612,
      city: 'Conway',
      state: 'MO',
    },
    65633: { lat: 36.89841, lng: -93.5346, city: 'Crane', state: 'MO' },
    65634: {
      lat: 38.02355,
      lng: -93.18827,
      city: 'Cross Timbers',
      state: 'MO',
    },
    65635: {
      lat: 37.53487,
      lng: -93.71812,
      city: 'Dadeville',
      state: 'MO',
    },
    65637: { lat: 36.76964, lng: -92.20371, city: 'Dora', state: 'MO' },
    65638: {
      lat: 36.84319,
      lng: -92.32418,
      city: 'Drury',
      state: 'MO',
    },
    65640: {
      lat: 37.69956,
      lng: -93.57165,
      city: 'Dunnegan',
      state: 'MO',
    },
    65641: {
      lat: 36.5481,
      lng: -93.76198,
      city: 'Eagle Rock',
      state: 'MO',
    },
    65644: {
      lat: 37.50999,
      lng: -93.03625,
      city: 'Elkland',
      state: 'MO',
    },
    65646: {
      lat: 37.33511,
      lng: -93.7039,
      city: 'Everton',
      state: 'MO',
    },
    65647: {
      lat: 36.68968,
      lng: -94.00624,
      city: 'Exeter',
      state: 'MO',
    },
    65648: {
      lat: 37.40297,
      lng: -93.15402,
      city: 'Fair Grove',
      state: 'MO',
    },
    65649: {
      lat: 37.621,
      lng: -93.61839,
      city: 'Fair Play',
      state: 'MO',
    },
    65650: {
      lat: 37.81388,
      lng: -93.44749,
      city: 'Flemington',
      state: 'MO',
    },
    65652: {
      lat: 37.14264,
      lng: -92.94642,
      city: 'Fordland',
      state: 'MO',
    },
    65653: {
      lat: 36.78118,
      lng: -93.11631,
      city: 'Forsyth',
      state: 'MO',
    },
    65654: {
      lat: 37.02164,
      lng: -93.8975,
      city: 'Freistatt',
      state: 'MO',
    },
    65655: {
      lat: 36.60262,
      lng: -92.42379,
      city: 'Gainesville',
      state: 'MO',
    },
    65656: {
      lat: 36.79552,
      lng: -93.46626,
      city: 'Galena',
      state: 'MO',
    },
    65657: {
      lat: 36.83934,
      lng: -93.01952,
      city: 'Garrison',
      state: 'MO',
    },
    65658: {
      lat: 36.5426,
      lng: -93.63984,
      city: 'Golden',
      state: 'MO',
    },
    65660: {
      lat: 37.32624,
      lng: -92.26998,
      city: 'Graff',
      state: 'MO',
    },
    65661: {
      lat: 37.46095,
      lng: -93.82722,
      city: 'Greenfield',
      state: 'MO',
    },
    65662: {
      lat: 37.43421,
      lng: -92.5698,
      city: 'Grovespring',
      state: 'MO',
    },
    65663: {
      lat: 37.62732,
      lng: -93.24486,
      city: 'Half Way',
      state: 'MO',
    },
    65664: {
      lat: 37.19728,
      lng: -93.61732,
      city: 'Halltown',
      state: 'MO',
    },
    65666: {
      lat: 36.57829,
      lng: -92.36065,
      city: 'Hardenville',
      state: 'MO',
    },
    65667: {
      lat: 37.30574,
      lng: -92.51435,
      city: 'Hartville',
      state: 'MO',
    },
    65668: {
      lat: 37.9364,
      lng: -93.29177,
      city: 'Hermitage',
      state: 'MO',
    },
    65669: {
      lat: 36.91339,
      lng: -93.30137,
      city: 'Highlandville',
      state: 'MO',
    },
    65672: {
      lat: 36.56446,
      lng: -93.21351,
      city: 'Hollister',
      state: 'MO',
    },
    65674: {
      lat: 37.78872,
      lng: -93.61018,
      city: 'Humansville',
      state: 'MO',
    },
    65675: {
      lat: 36.93101,
      lng: -93.49245,
      city: 'Hurley',
      state: 'MO',
    },
    65676: {
      lat: 36.58187,
      lng: -92.60531,
      city: 'Isabella',
      state: 'MO',
    },
    65679: {
      lat: 36.57738,
      lng: -93.10407,
      city: 'Kirbyville',
      state: 'MO',
    },
    65680: {
      lat: 36.64518,
      lng: -92.98551,
      city: 'Kissee Mills',
      state: 'MO',
    },
    65681: {
      lat: 36.55211,
      lng: -93.46658,
      city: 'Lampe',
      state: 'MO',
    },
    65682: {
      lat: 37.41722,
      lng: -93.97293,
      city: 'Lockwood',
      state: 'MO',
    },
    65685: {
      lat: 37.75642,
      lng: -93.1511,
      city: 'Louisburg',
      state: 'MO',
    },
    65686: {
      lat: 36.62275,
      lng: -93.43625,
      city: 'Kimberling City',
      state: 'MO',
    },
    65688: {
      lat: 36.64928,
      lng: -91.69328,
      city: 'Brandsville',
      state: 'MO',
    },
    65689: {
      lat: 37.13665,
      lng: -92.09794,
      city: 'Cabool',
      state: 'MO',
    },
    65690: { lat: 36.5856, lng: -91.26657, city: 'Couch', state: 'MO' },
    65692: {
      lat: 36.61801,
      lng: -91.65592,
      city: 'Koshkonong',
      state: 'MO',
    },
    65702: { lat: 37.05726, lng: -92.488, city: 'Macomb', state: 'MO' },
    65704: {
      lat: 37.12657,
      lng: -92.58323,
      city: 'Mansfield',
      state: 'MO',
    },
    65705: {
      lat: 37.02607,
      lng: -93.60835,
      city: 'Marionville',
      state: 'MO',
    },
    65706: {
      lat: 37.3224,
      lng: -92.89421,
      city: 'Marshfield',
      state: 'MO',
    },
    65707: {
      lat: 37.22862,
      lng: -93.84574,
      city: 'Miller',
      state: 'MO',
    },
    65708: {
      lat: 36.91048,
      lng: -93.90899,
      city: 'Monett',
      state: 'MO',
    },
    65710: {
      lat: 37.48707,
      lng: -93.42099,
      city: 'Morrisville',
      state: 'MO',
    },
    65711: {
      lat: 37.17355,
      lng: -92.27747,
      city: 'Mountain Grove',
      state: 'MO',
    },
    65712: {
      lat: 37.10964,
      lng: -93.80645,
      city: 'Mount Vernon',
      state: 'MO',
    },
    65713: {
      lat: 37.40403,
      lng: -92.73971,
      city: 'Niangua',
      state: 'MO',
    },
    65714: { lat: 37.03801, lng: -93.31976, city: 'Nixa', state: 'MO' },
    65715: {
      lat: 36.73192,
      lng: -92.57631,
      city: 'Noble',
      state: 'MO',
    },
    65717: {
      lat: 37.06017,
      lng: -92.41472,
      city: 'Norwood',
      state: 'MO',
    },
    65720: {
      lat: 36.95513,
      lng: -92.95998,
      city: 'Oldfield',
      state: 'MO',
    },
    65721: { lat: 36.9805, lng: -93.21045, city: 'Ozark', state: 'MO' },
    65722: {
      lat: 37.58694,
      lng: -92.79967,
      city: 'Phillipsburg',
      state: 'MO',
    },
    65723: {
      lat: 36.95635,
      lng: -94.04648,
      city: 'Pierce City',
      state: 'MO',
    },
    65724: {
      lat: 37.83924,
      lng: -93.31488,
      city: 'Pittsburg',
      state: 'MO',
    },
    65725: {
      lat: 37.42986,
      lng: -93.27424,
      city: 'Pleasant Hope',
      state: 'MO',
    },
    65726: {
      lat: 36.61947,
      lng: -93.2427,
      city: 'Point Lookout',
      state: 'MO',
    },
    65727: { lat: 37.76266, lng: -93.28889, city: 'Polk', state: 'MO' },
    65728: {
      lat: 36.87712,
      lng: -93.33533,
      city: 'Ponce De Leon',
      state: 'MO',
    },
    65729: {
      lat: 36.53842,
      lng: -92.55661,
      city: 'Pontiac',
      state: 'MO',
    },
    65730: {
      lat: 36.61549,
      lng: -94.17001,
      city: 'Powell',
      state: 'MO',
    },
    65731: {
      lat: 36.65458,
      lng: -93.12222,
      city: 'Powersite',
      state: 'MO',
    },
    65732: {
      lat: 37.93938,
      lng: -93.16763,
      city: 'Preston',
      state: 'MO',
    },
    65733: {
      lat: 36.53157,
      lng: -92.85077,
      city: 'Protem',
      state: 'MO',
    },
    65734: {
      lat: 36.79841,
      lng: -93.95233,
      city: 'Purdy',
      state: 'MO',
    },
    65735: {
      lat: 38.03728,
      lng: -93.48391,
      city: 'Quincy',
      state: 'MO',
    },
    65737: {
      lat: 36.7146,
      lng: -93.35796,
      city: 'Reeds Spring',
      state: 'MO',
    },
    65738: {
      lat: 37.13503,
      lng: -93.49424,
      city: 'Republic',
      state: 'MO',
    },
    65739: {
      lat: 36.51925,
      lng: -93.26864,
      city: 'Ridgedale',
      state: 'MO',
    },
    65740: {
      lat: 36.71288,
      lng: -93.16169,
      city: 'Rockaway Beach',
      state: 'MO',
    },
    65741: {
      lat: 36.79545,
      lng: -92.40127,
      city: 'Rockbridge',
      state: 'MO',
    },
    65742: {
      lat: 37.12543,
      lng: -93.08001,
      city: 'Rogersville',
      state: 'MO',
    },
    65744: {
      lat: 36.62449,
      lng: -92.89337,
      city: 'Rueter',
      state: 'MO',
    },
    65745: {
      lat: 36.52928,
      lng: -93.97917,
      city: 'Seligman',
      state: 'MO',
    },
    65746: {
      lat: 37.14278,
      lng: -92.78144,
      city: 'Seymour',
      state: 'MO',
    },
    65747: {
      lat: 36.60248,
      lng: -93.57971,
      city: 'Shell Knob',
      state: 'MO',
    },
    65752: {
      lat: 37.32742,
      lng: -93.83842,
      city: 'South Greenfield',
      state: 'MO',
    },
    65753: {
      lat: 36.9955,
      lng: -93.05386,
      city: 'Sparta',
      state: 'MO',
    },
    65754: {
      lat: 36.85032,
      lng: -93.29595,
      city: 'Spokane',
      state: 'MO',
    },
    65755: {
      lat: 36.7996,
      lng: -92.62858,
      city: 'Squires',
      state: 'MO',
    },
    65756: {
      lat: 37.0981,
      lng: -93.96315,
      city: 'Stotts City',
      state: 'MO',
    },
    65757: {
      lat: 37.2838,
      lng: -93.09861,
      city: 'Strafford',
      state: 'MO',
    },
    65759: {
      lat: 36.75762,
      lng: -93.03167,
      city: 'Taneyville',
      state: 'MO',
    },
    65760: {
      lat: 36.62236,
      lng: -92.27943,
      city: 'Tecumseh',
      state: 'MO',
    },
    65761: {
      lat: 36.56974,
      lng: -92.72561,
      city: 'Theodosia',
      state: 'MO',
    },
    65762: {
      lat: 36.68613,
      lng: -92.65634,
      city: 'Thornfield',
      state: 'MO',
    },
    65764: {
      lat: 37.82189,
      lng: -92.95682,
      city: 'Tunas',
      state: 'MO',
    },
    65766: {
      lat: 36.53933,
      lng: -92.25948,
      city: 'Udall',
      state: 'MO',
    },
    65767: {
      lat: 37.84495,
      lng: -93.1529,
      city: 'Urbana',
      state: 'MO',
    },
    65768: {
      lat: 36.92072,
      lng: -92.26456,
      city: 'Vanzant',
      state: 'MO',
    },
    65769: {
      lat: 36.91676,
      lng: -93.79511,
      city: 'Verona',
      state: 'MO',
    },
    65770: {
      lat: 37.42165,
      lng: -93.5456,
      city: 'Walnut Grove',
      state: 'MO',
    },
    65771: {
      lat: 36.77484,
      lng: -93.2042,
      city: 'Walnut Shade',
      state: 'MO',
    },
    65772: {
      lat: 36.59624,
      lng: -94.03479,
      city: 'Washburn',
      state: 'MO',
    },
    65773: {
      lat: 36.75551,
      lng: -92.51332,
      city: 'Wasola',
      state: 'MO',
    },
    65774: {
      lat: 37.92806,
      lng: -93.49529,
      city: 'Weaubleau',
      state: 'MO',
    },
    65775: {
      lat: 36.6995,
      lng: -91.85999,
      city: 'West Plains',
      state: 'MO',
    },
    65777: {
      lat: 36.51768,
      lng: -91.98356,
      city: 'Moody',
      state: 'MO',
    },
    65778: {
      lat: 36.52846,
      lng: -91.2719,
      city: 'Myrtle',
      state: 'MO',
    },
    65779: {
      lat: 37.9789,
      lng: -93.38561,
      city: 'Wheatland',
      state: 'MO',
    },
    65781: {
      lat: 37.35035,
      lng: -93.41778,
      city: 'Willard',
      state: 'MO',
    },
    65783: {
      lat: 37.7153,
      lng: -92.91623,
      city: 'Windyville',
      state: 'MO',
    },
    65784: {
      lat: 36.72588,
      lng: -92.31538,
      city: 'Zanoni',
      state: 'MO',
    },
    65785: {
      lat: 37.70296,
      lng: -93.82338,
      city: 'Stockton',
      state: 'MO',
    },
    65786: {
      lat: 37.96715,
      lng: -92.96516,
      city: 'Macks Creek',
      state: 'MO',
    },
    65787: {
      lat: 38.07306,
      lng: -92.89213,
      city: 'Roach',
      state: 'MO',
    },
    65788: {
      lat: 36.81442,
      lng: -91.69626,
      city: 'Peace Valley',
      state: 'MO',
    },
    65789: {
      lat: 36.85719,
      lng: -91.88968,
      city: 'Pomona',
      state: 'MO',
    },
    65790: {
      lat: 36.69784,
      lng: -92.11326,
      city: 'Pottersville',
      state: 'MO',
    },
    65791: {
      lat: 36.56651,
      lng: -91.49583,
      city: 'Thayer',
      state: 'MO',
    },
    65793: {
      lat: 36.98975,
      lng: -91.9787,
      city: 'Willow Springs',
      state: 'MO',
    },
    65801: {
      lat: 37.21421,
      lng: -93.29521,
      city: 'Springfield',
      state: 'MO',
    },
    65802: {
      lat: 37.21048,
      lng: -93.35167,
      city: 'Springfield',
      state: 'MO',
    },
    65803: {
      lat: 37.28464,
      lng: -93.29036,
      city: 'Springfield',
      state: 'MO',
    },
    65804: {
      lat: 37.14822,
      lng: -93.25294,
      city: 'Springfield',
      state: 'MO',
    },
    65806: {
      lat: 37.20567,
      lng: -93.29847,
      city: 'Springfield',
      state: 'MO',
    },
    65807: {
      lat: 37.166,
      lng: -93.32483,
      city: 'Springfield',
      state: 'MO',
    },
    65809: {
      lat: 37.17071,
      lng: -93.1936,
      city: 'Springfield',
      state: 'MO',
    },
    65810: {
      lat: 37.1174,
      lng: -93.31707,
      city: 'Springfield',
      state: 'MO',
    },
    65897: {
      lat: 37.19985,
      lng: -93.28055,
      city: 'Springfield',
      state: 'MO',
    },
    66002: {
      lat: 39.5488,
      lng: -95.13881,
      city: 'Atchison',
      state: 'KS',
    },
    66006: {
      lat: 38.79433,
      lng: -95.23936,
      city: 'Baldwin City',
      state: 'KS',
    },
    66007: {
      lat: 39.1405,
      lng: -94.94548,
      city: 'Basehor',
      state: 'KS',
    },
    66008: {
      lat: 39.71283,
      lng: -95.17564,
      city: 'Bendena',
      state: 'KS',
    },
    66010: {
      lat: 38.09231,
      lng: -94.98853,
      city: 'Blue Mound',
      state: 'KS',
    },
    66012: {
      lat: 39.06091,
      lng: -94.91658,
      city: 'Bonner Springs',
      state: 'KS',
    },
    66013: {
      lat: 38.73428,
      lng: -94.68948,
      city: 'Bucyrus',
      state: 'KS',
    },
    66014: {
      lat: 38.22305,
      lng: -94.98371,
      city: 'Centerville',
      state: 'KS',
    },
    66015: {
      lat: 38.06786,
      lng: -95.40395,
      city: 'Colony',
      state: 'KS',
    },
    66016: {
      lat: 39.47038,
      lng: -95.23158,
      city: 'Cummings',
      state: 'KS',
    },
    66017: {
      lat: 39.70599,
      lng: -95.27591,
      city: 'Denton',
      state: 'KS',
    },
    66018: {
      lat: 38.96524,
      lng: -94.97232,
      city: 'De Soto',
      state: 'KS',
    },
    66020: {
      lat: 39.34142,
      lng: -95.10779,
      city: 'Easton',
      state: 'KS',
    },
    66021: {
      lat: 38.75939,
      lng: -95.00692,
      city: 'Edgerton',
      state: 'KS',
    },
    66023: {
      lat: 39.51375,
      lng: -95.40005,
      city: 'Effingham',
      state: 'KS',
    },
    66024: {
      lat: 39.76211,
      lng: -94.87719,
      city: 'Elwood',
      state: 'KS',
    },
    66025: {
      lat: 38.89824,
      lng: -95.07863,
      city: 'Eudora',
      state: 'KS',
    },
    66026: {
      lat: 38.39047,
      lng: -94.86056,
      city: 'Fontana',
      state: 'KS',
    },
    66027: {
      lat: 39.36454,
      lng: -94.91708,
      city: 'Fort Leavenworth',
      state: 'KS',
    },
    66030: {
      lat: 38.81519,
      lng: -94.94114,
      city: 'Gardner',
      state: 'KS',
    },
    66031: {
      lat: 38.82975,
      lng: -94.89142,
      city: 'New Century',
      state: 'KS',
    },
    66032: {
      lat: 38.2806,
      lng: -95.27237,
      city: 'Garnett',
      state: 'KS',
    },
    66033: {
      lat: 38.35855,
      lng: -95.12842,
      city: 'Greeley',
      state: 'KS',
    },
    66035: {
      lat: 39.87693,
      lng: -95.26054,
      city: 'Highland',
      state: 'KS',
    },
    66039: {
      lat: 38.0987,
      lng: -95.17011,
      city: 'Kincaid',
      state: 'KS',
    },
    66040: {
      lat: 38.35396,
      lng: -94.73949,
      city: 'La Cygne',
      state: 'KS',
    },
    66041: {
      lat: 39.59618,
      lng: -95.30623,
      city: 'Lancaster',
      state: 'KS',
    },
    66042: { lat: 38.43368, lng: -95.08032, city: 'Lane', state: 'KS' },
    66043: {
      lat: 39.24967,
      lng: -94.90193,
      city: 'Lansing',
      state: 'KS',
    },
    66044: {
      lat: 39.03187,
      lng: -95.20991,
      city: 'Lawrence',
      state: 'KS',
    },
    66045: {
      lat: 38.95408,
      lng: -95.26078,
      city: 'Lawrence',
      state: 'KS',
    },
    66046: {
      lat: 38.909,
      lng: -95.21061,
      city: 'Lawrence',
      state: 'KS',
    },
    66047: {
      lat: 38.88484,
      lng: -95.35096,
      city: 'Lawrence',
      state: 'KS',
    },
    66048: {
      lat: 39.27891,
      lng: -94.98527,
      city: 'Leavenworth',
      state: 'KS',
    },
    66049: {
      lat: 38.97773,
      lng: -95.34729,
      city: 'Lawrence',
      state: 'KS',
    },
    66050: {
      lat: 39.01961,
      lng: -95.43697,
      city: 'Lecompton',
      state: 'KS',
    },
    66052: {
      lat: 39.00998,
      lng: -95.04678,
      city: 'Linwood',
      state: 'KS',
    },
    66053: {
      lat: 38.57753,
      lng: -94.67029,
      city: 'Louisburg',
      state: 'KS',
    },
    66054: {
      lat: 39.2055,
      lng: -95.19518,
      city: 'McLouth',
      state: 'KS',
    },
    66056: {
      lat: 38.1509,
      lng: -94.84705,
      city: 'Mound City',
      state: 'KS',
    },
    66058: {
      lat: 39.54018,
      lng: -95.51894,
      city: 'Muscotah',
      state: 'KS',
    },
    66060: {
      lat: 39.41866,
      lng: -95.3325,
      city: 'Nortonville',
      state: 'KS',
    },
    66061: {
      lat: 38.89633,
      lng: -94.88029,
      city: 'Olathe',
      state: 'KS',
    },
    66062: {
      lat: 38.83677,
      lng: -94.78455,
      city: 'Olathe',
      state: 'KS',
    },
    66064: {
      lat: 38.47829,
      lng: -94.98688,
      city: 'Osawatomie',
      state: 'KS',
    },
    66066: {
      lat: 39.20359,
      lng: -95.33142,
      city: 'Oskaloosa',
      state: 'KS',
    },
    66067: {
      lat: 38.61877,
      lng: -95.27566,
      city: 'Ottawa',
      state: 'KS',
    },
    66070: {
      lat: 39.21135,
      lng: -95.45132,
      city: 'Ozawkie',
      state: 'KS',
    },
    66071: {
      lat: 38.57367,
      lng: -94.86575,
      city: 'Paola',
      state: 'KS',
    },
    66072: {
      lat: 38.31772,
      lng: -94.9741,
      city: 'Parker',
      state: 'KS',
    },
    66073: {
      lat: 39.09208,
      lng: -95.38144,
      city: 'Perry',
      state: 'KS',
    },
    66075: {
      lat: 38.20362,
      lng: -94.69272,
      city: 'Pleasanton',
      state: 'KS',
    },
    66076: {
      lat: 38.62811,
      lng: -95.43917,
      city: 'Pomona',
      state: 'KS',
    },
    66078: {
      lat: 38.48321,
      lng: -95.2621,
      city: 'Princeton',
      state: 'KS',
    },
    66079: {
      lat: 38.53828,
      lng: -95.11371,
      city: 'Rantoul',
      state: 'KS',
    },
    66080: {
      lat: 38.4053,
      lng: -95.25646,
      city: 'Richmond',
      state: 'KS',
    },
    66083: {
      lat: 38.73518,
      lng: -94.83397,
      city: 'Spring Hill',
      state: 'KS',
    },
    66085: {
      lat: 38.80069,
      lng: -94.64729,
      city: 'Stilwell',
      state: 'KS',
    },
    66086: {
      lat: 39.11578,
      lng: -95.08043,
      city: 'Tonganoxie',
      state: 'KS',
    },
    66087: { lat: 39.80641, lng: -95.1378, city: 'Troy', state: 'KS' },
    66088: {
      lat: 39.3416,
      lng: -95.4563,
      city: 'Valley Falls',
      state: 'KS',
    },
    66090: {
      lat: 39.78391,
      lng: -94.97041,
      city: 'Wathena',
      state: 'KS',
    },
    66091: {
      lat: 38.16952,
      lng: -95.32701,
      city: 'Welda',
      state: 'KS',
    },
    66092: {
      lat: 38.69305,
      lng: -95.10548,
      city: 'Wellsville',
      state: 'KS',
    },
    66093: {
      lat: 38.21003,
      lng: -95.49771,
      city: 'Westphalia',
      state: 'KS',
    },
    66094: {
      lat: 39.95912,
      lng: -95.34298,
      city: 'White Cloud',
      state: 'KS',
    },
    66095: {
      lat: 38.44631,
      lng: -95.43015,
      city: 'Williamsburg',
      state: 'KS',
    },
    66097: {
      lat: 39.32966,
      lng: -95.23992,
      city: 'Winchester',
      state: 'KS',
    },
    66101: {
      lat: 39.11871,
      lng: -94.62592,
      city: 'Kansas City',
      state: 'KS',
    },
    66102: {
      lat: 39.11246,
      lng: -94.68842,
      city: 'Kansas City',
      state: 'KS',
    },
    66103: {
      lat: 39.06045,
      lng: -94.62707,
      city: 'Kansas City',
      state: 'KS',
    },
    66104: {
      lat: 39.15043,
      lng: -94.68861,
      city: 'Kansas City',
      state: 'KS',
    },
    66105: {
      lat: 39.08593,
      lng: -94.63646,
      city: 'Kansas City',
      state: 'KS',
    },
    66106: {
      lat: 39.06631,
      lng: -94.70384,
      city: 'Kansas City',
      state: 'KS',
    },
    66109: {
      lat: 39.16367,
      lng: -94.8283,
      city: 'Kansas City',
      state: 'KS',
    },
    66111: {
      lat: 39.08529,
      lng: -94.79621,
      city: 'Kansas City',
      state: 'KS',
    },
    66112: {
      lat: 39.11494,
      lng: -94.7701,
      city: 'Kansas City',
      state: 'KS',
    },
    66115: {
      lat: 39.14402,
      lng: -94.61104,
      city: 'Kansas City',
      state: 'KS',
    },
    66118: {
      lat: 39.09946,
      lng: -94.61348,
      city: 'Kansas City',
      state: 'KS',
    },
    66160: {
      lat: 39.05576,
      lng: -94.60963,
      city: 'Kansas City',
      state: 'KS',
    },
    66202: {
      lat: 39.02392,
      lng: -94.66909,
      city: 'Mission',
      state: 'KS',
    },
    66203: {
      lat: 39.0216,
      lng: -94.70558,
      city: 'Shawnee',
      state: 'KS',
    },
    66204: {
      lat: 38.99282,
      lng: -94.67741,
      city: 'Overland Park',
      state: 'KS',
    },
    66205: {
      lat: 39.03122,
      lng: -94.6307,
      city: 'Mission',
      state: 'KS',
    },
    66206: {
      lat: 38.95851,
      lng: -94.61923,
      city: 'Leawood',
      state: 'KS',
    },
    66207: {
      lat: 38.95625,
      lng: -94.64439,
      city: 'Overland Park',
      state: 'KS',
    },
    66208: {
      lat: 38.99936,
      lng: -94.62998,
      city: 'Prairie Village',
      state: 'KS',
    },
    66209: {
      lat: 38.89874,
      lng: -94.63778,
      city: 'Leawood',
      state: 'KS',
    },
    66210: {
      lat: 38.92372,
      lng: -94.70483,
      city: 'Overland Park',
      state: 'KS',
    },
    66211: {
      lat: 38.92422,
      lng: -94.6363,
      city: 'Leawood',
      state: 'KS',
    },
    66212: {
      lat: 38.9563,
      lng: -94.68154,
      city: 'Overland Park',
      state: 'KS',
    },
    66213: {
      lat: 38.89885,
      lng: -94.70517,
      city: 'Overland Park',
      state: 'KS',
    },
    66214: {
      lat: 38.96356,
      lng: -94.71397,
      city: 'Overland Park',
      state: 'KS',
    },
    66215: {
      lat: 38.95649,
      lng: -94.74304,
      city: 'Lenexa',
      state: 'KS',
    },
    66216: {
      lat: 39.01405,
      lng: -94.74136,
      city: 'Shawnee',
      state: 'KS',
    },
    66217: {
      lat: 39.01148,
      lng: -94.77741,
      city: 'Shawnee',
      state: 'KS',
    },
    66218: {
      lat: 39.01239,
      lng: -94.81647,
      city: 'Shawnee',
      state: 'KS',
    },
    66219: {
      lat: 38.95124,
      lng: -94.77807,
      city: 'Lenexa',
      state: 'KS',
    },
    66220: {
      lat: 38.96187,
      lng: -94.8218,
      city: 'Lenexa',
      state: 'KS',
    },
    66221: {
      lat: 38.84786,
      lng: -94.71437,
      city: 'Overland Park',
      state: 'KS',
    },
    66223: {
      lat: 38.86176,
      lng: -94.66831,
      city: 'Overland Park',
      state: 'KS',
    },
    66224: {
      lat: 38.86213,
      lng: -94.62866,
      city: 'Overland Park',
      state: 'KS',
    },
    66226: {
      lat: 39.03039,
      lng: -94.8603,
      city: 'Shawnee',
      state: 'KS',
    },
    66227: {
      lat: 38.97248,
      lng: -94.87446,
      city: 'Lenexa',
      state: 'KS',
    },
    66251: {
      lat: 38.91712,
      lng: -94.65891,
      city: 'Overland Park',
      state: 'KS',
    },
    66401: { lat: 38.96894, lng: -96.30539, city: 'Alma', state: 'KS' },
    66402: {
      lat: 38.91782,
      lng: -95.84926,
      city: 'Auburn',
      state: 'KS',
    },
    66403: {
      lat: 39.89424,
      lng: -96.27166,
      city: 'Axtell',
      state: 'KS',
    },
    66404: {
      lat: 39.86786,
      lng: -96.17804,
      city: 'Baileyville',
      state: 'KS',
    },
    66406: {
      lat: 39.8927,
      lng: -96.42014,
      city: 'Beattie',
      state: 'KS',
    },
    66407: {
      lat: 39.22517,
      lng: -96.19218,
      city: 'Belvue',
      state: 'KS',
    },
    66408: { lat: 39.95306, lng: -95.97607, city: 'Bern', state: 'KS' },
    66409: {
      lat: 38.92508,
      lng: -95.55589,
      city: 'Berryton',
      state: 'KS',
    },
    66411: {
      lat: 39.64083,
      lng: -96.63948,
      city: 'Blue Rapids',
      state: 'KS',
    },
    66412: {
      lat: 39.90837,
      lng: -96.77794,
      city: 'Bremen',
      state: 'KS',
    },
    66413: {
      lat: 38.77375,
      lng: -95.88305,
      city: 'Burlingame',
      state: 'KS',
    },
    66414: {
      lat: 38.82631,
      lng: -95.68563,
      city: 'Carbondale',
      state: 'KS',
    },
    66415: {
      lat: 39.69193,
      lng: -96.14381,
      city: 'Centralia',
      state: 'KS',
    },
    66416: {
      lat: 39.52944,
      lng: -95.86353,
      city: 'Circleville',
      state: 'KS',
    },
    66417: {
      lat: 39.65524,
      lng: -96.03824,
      city: 'Corning',
      state: 'KS',
    },
    66418: {
      lat: 39.28861,
      lng: -95.93528,
      city: 'Delia',
      state: 'KS',
    },
    66419: {
      lat: 39.35643,
      lng: -95.59908,
      city: 'Denison',
      state: 'KS',
    },
    66422: {
      lat: 39.33317,
      lng: -96.08496,
      city: 'Emmett',
      state: 'KS',
    },
    66423: {
      lat: 38.8492,
      lng: -96.12934,
      city: 'Eskridge',
      state: 'KS',
    },
    66424: {
      lat: 39.67789,
      lng: -95.40072,
      city: 'Everest',
      state: 'KS',
    },
    66425: {
      lat: 39.81099,
      lng: -95.72225,
      city: 'Fairview',
      state: 'KS',
    },
    66427: {
      lat: 39.66261,
      lng: -96.449,
      city: 'Frankfort',
      state: 'KS',
    },
    66428: { lat: 39.67687, lng: -95.92504, city: 'Goff', state: 'KS' },
    66429: {
      lat: 39.0989,
      lng: -95.53725,
      city: 'Grantville',
      state: 'KS',
    },
    66431: {
      lat: 38.80851,
      lng: -96.00241,
      city: 'Harveyville',
      state: 'KS',
    },
    66432: {
      lat: 39.48768,
      lng: -96.07348,
      city: 'Havensville',
      state: 'KS',
    },
    66434: {
      lat: 39.86731,
      lng: -95.54679,
      city: 'Hiawatha',
      state: 'KS',
    },
    66436: {
      lat: 39.4655,
      lng: -95.70278,
      city: 'Holton',
      state: 'KS',
    },
    66438: { lat: 39.83335, lng: -96.50165, city: 'Home', state: 'KS' },
    66439: {
      lat: 39.65518,
      lng: -95.54327,
      city: 'Horton',
      state: 'KS',
    },
    66440: { lat: 39.26326, lng: -95.68168, city: 'Hoyt', state: 'KS' },
    66441: {
      lat: 38.98199,
      lng: -96.80289,
      city: 'Junction City',
      state: 'KS',
    },
    66442: {
      lat: 39.09302,
      lng: -96.78963,
      city: 'Fort Riley',
      state: 'KS',
    },
    66449: {
      lat: 39.38833,
      lng: -96.86007,
      city: 'Leonardville',
      state: 'KS',
    },
    66451: {
      lat: 38.60841,
      lng: -95.67236,
      city: 'Lyndon',
      state: 'KS',
    },
    66501: {
      lat: 39.05279,
      lng: -96.23688,
      city: 'McFarland',
      state: 'KS',
    },
    66502: {
      lat: 39.14146,
      lng: -96.52906,
      city: 'Manhattan',
      state: 'KS',
    },
    66503: {
      lat: 39.26287,
      lng: -96.68663,
      city: 'Manhattan',
      state: 'KS',
    },
    66506: {
      lat: 39.19596,
      lng: -96.58148,
      city: 'Manhattan',
      state: 'KS',
    },
    66507: {
      lat: 39.04712,
      lng: -96.03942,
      city: 'Maple Hill',
      state: 'KS',
    },
    66508: {
      lat: 39.85803,
      lng: -96.64287,
      city: 'Marysville',
      state: 'KS',
    },
    66509: {
      lat: 39.33239,
      lng: -95.78097,
      city: 'Mayetta',
      state: 'KS',
    },
    66510: {
      lat: 38.49297,
      lng: -95.6104,
      city: 'Melvern',
      state: 'KS',
    },
    66512: {
      lat: 39.20255,
      lng: -95.54701,
      city: 'Meriden',
      state: 'KS',
    },
    66514: {
      lat: 39.12635,
      lng: -96.87826,
      city: 'Milford',
      state: 'KS',
    },
    66515: {
      lat: 39.94181,
      lng: -95.70681,
      city: 'Morrill',
      state: 'KS',
    },
    66516: {
      lat: 39.62193,
      lng: -95.7195,
      city: 'Netawaka',
      state: 'KS',
    },
    66517: {
      lat: 39.11253,
      lng: -96.70465,
      city: 'Ogden',
      state: 'KS',
    },
    66518: {
      lat: 39.97008,
      lng: -96.55533,
      city: 'Oketo',
      state: 'KS',
    },
    66520: {
      lat: 39.40963,
      lng: -96.61617,
      city: 'Olsburg',
      state: 'KS',
    },
    66521: {
      lat: 39.47668,
      lng: -96.22128,
      city: 'Onaga',
      state: 'KS',
    },
    66522: {
      lat: 39.86609,
      lng: -95.93826,
      city: 'Oneida',
      state: 'KS',
    },
    66523: {
      lat: 38.60993,
      lng: -95.83791,
      city: 'Osage City',
      state: 'KS',
    },
    66524: {
      lat: 38.78948,
      lng: -95.50695,
      city: 'Overbrook',
      state: 'KS',
    },
    66526: {
      lat: 39.06349,
      lng: -96.15567,
      city: 'Paxico',
      state: 'KS',
    },
    66527: {
      lat: 39.74499,
      lng: -95.67744,
      city: 'Powhattan',
      state: 'KS',
    },
    66528: {
      lat: 38.59711,
      lng: -95.5344,
      city: 'Quenemo',
      state: 'KS',
    },
    66531: {
      lat: 39.31146,
      lng: -96.83416,
      city: 'Riley',
      state: 'KS',
    },
    66532: {
      lat: 39.82656,
      lng: -95.37597,
      city: 'Robinson',
      state: 'KS',
    },
    66533: {
      lat: 39.16097,
      lng: -95.95045,
      city: 'Rossville',
      state: 'KS',
    },
    66534: {
      lat: 39.87782,
      lng: -95.83635,
      city: 'Sabetha',
      state: 'KS',
    },
    66535: {
      lat: 39.26169,
      lng: -96.43896,
      city: 'Saint George',
      state: 'KS',
    },
    66536: {
      lat: 39.2247,
      lng: -96.07396,
      city: 'Saint Marys',
      state: 'KS',
    },
    66537: {
      lat: 38.75118,
      lng: -95.72255,
      city: 'Scranton',
      state: 'KS',
    },
    66538: {
      lat: 39.84942,
      lng: -96.04591,
      city: 'Seneca',
      state: 'KS',
    },
    66539: {
      lat: 39.14937,
      lng: -95.84707,
      city: 'Silver Lake',
      state: 'KS',
    },
    66540: {
      lat: 39.48484,
      lng: -95.97332,
      city: 'Soldier',
      state: 'KS',
    },
    66541: {
      lat: 39.97891,
      lng: -96.34781,
      city: 'Summerfield',
      state: 'KS',
    },
    66542: {
      lat: 39.01273,
      lng: -95.54442,
      city: 'Tecumseh',
      state: 'KS',
    },
    66543: {
      lat: 38.64175,
      lng: -95.60029,
      city: 'Vassar',
      state: 'KS',
    },
    66544: {
      lat: 39.68807,
      lng: -96.27783,
      city: 'Vermillion',
      state: 'KS',
    },
    66546: {
      lat: 38.8941,
      lng: -95.72589,
      city: 'Wakarusa',
      state: 'KS',
    },
    66547: {
      lat: 39.23469,
      lng: -96.30384,
      city: 'Wamego',
      state: 'KS',
    },
    66548: {
      lat: 39.67551,
      lng: -96.76146,
      city: 'Waterville',
      state: 'KS',
    },
    66549: {
      lat: 39.42978,
      lng: -96.43045,
      city: 'Westmoreland',
      state: 'KS',
    },
    66550: {
      lat: 39.65537,
      lng: -95.82113,
      city: 'Wetmore',
      state: 'KS',
    },
    66552: {
      lat: 39.58736,
      lng: -95.621,
      city: 'Whiting',
      state: 'KS',
    },
    66554: {
      lat: 39.48899,
      lng: -96.78445,
      city: 'Randolph',
      state: 'KS',
    },
    66603: {
      lat: 39.05742,
      lng: -95.67539,
      city: 'Topeka',
      state: 'KS',
    },
    66604: {
      lat: 39.03875,
      lng: -95.72623,
      city: 'Topeka',
      state: 'KS',
    },
    66605: {
      lat: 39.01167,
      lng: -95.63283,
      city: 'Topeka',
      state: 'KS',
    },
    66606: {
      lat: 39.06191,
      lng: -95.72296,
      city: 'Topeka',
      state: 'KS',
    },
    66607: {
      lat: 39.0422,
      lng: -95.63502,
      city: 'Topeka',
      state: 'KS',
    },
    66608: {
      lat: 39.07984,
      lng: -95.66708,
      city: 'Topeka',
      state: 'KS',
    },
    66609: {
      lat: 38.98193,
      lng: -95.66974,
      city: 'Topeka',
      state: 'KS',
    },
    66610: {
      lat: 38.97277,
      lng: -95.84718,
      city: 'Topeka',
      state: 'KS',
    },
    66611: {
      lat: 39.01526,
      lng: -95.69601,
      city: 'Topeka',
      state: 'KS',
    },
    66612: {
      lat: 39.04026,
      lng: -95.68037,
      city: 'Topeka',
      state: 'KS',
    },
    66614: {
      lat: 39.01362,
      lng: -95.84115,
      city: 'Topeka',
      state: 'KS',
    },
    66615: {
      lat: 39.05214,
      lng: -95.89491,
      city: 'Topeka',
      state: 'KS',
    },
    66616: {
      lat: 39.06845,
      lng: -95.62388,
      city: 'Topeka',
      state: 'KS',
    },
    66617: { lat: 39.13876, lng: -95.625, city: 'Topeka', state: 'KS' },
    66618: {
      lat: 39.14188,
      lng: -95.74997,
      city: 'Topeka',
      state: 'KS',
    },
    66619: {
      lat: 38.94661,
      lng: -95.68993,
      city: 'Topeka',
      state: 'KS',
    },
    66621: {
      lat: 39.03313,
      lng: -95.70237,
      city: 'Topeka',
      state: 'KS',
    },
    66622: {
      lat: 39.02631,
      lng: -95.72231,
      city: 'Topeka',
      state: 'KS',
    },
    66701: {
      lat: 37.82862,
      lng: -94.75999,
      city: 'Fort Scott',
      state: 'KS',
    },
    66710: {
      lat: 37.55301,
      lng: -95.63104,
      city: 'Altoona',
      state: 'KS',
    },
    66711: {
      lat: 37.63974,
      lng: -94.691,
      city: 'Arcadia',
      state: 'KS',
    },
    66712: { lat: 37.5636, lng: -94.71657, city: 'Arma', state: 'KS' },
    66713: {
      lat: 37.03323,
      lng: -94.78637,
      city: 'Baxter Springs',
      state: 'KS',
    },
    66714: {
      lat: 37.63369,
      lng: -95.70484,
      city: 'Benedict',
      state: 'KS',
    },
    66716: {
      lat: 37.93923,
      lng: -95.04001,
      city: 'Bronson',
      state: 'KS',
    },
    66717: {
      lat: 37.69671,
      lng: -95.68672,
      city: 'Buffalo',
      state: 'KS',
    },
    66720: {
      lat: 37.64631,
      lng: -95.459,
      city: 'Chanute',
      state: 'KS',
    },
    66724: {
      lat: 37.34891,
      lng: -94.86333,
      city: 'Cherokee',
      state: 'KS',
    },
    66725: {
      lat: 37.15906,
      lng: -94.88892,
      city: 'Columbus',
      state: 'KS',
    },
    66732: {
      lat: 37.80097,
      lng: -95.1947,
      city: 'Elsmore',
      state: 'KS',
    },
    66733: { lat: 37.59014, lng: -95.25282, city: 'Erie', state: 'KS' },
    66734: {
      lat: 37.61534,
      lng: -94.82172,
      city: 'Farlington',
      state: 'KS',
    },
    66735: {
      lat: 37.52091,
      lng: -94.71313,
      city: 'Franklin',
      state: 'KS',
    },
    66736: {
      lat: 37.55335,
      lng: -95.86381,
      city: 'Fredonia',
      state: 'KS',
    },
    66738: {
      lat: 38.01473,
      lng: -94.7162,
      city: 'Fulton',
      state: 'KS',
    },
    66739: {
      lat: 37.12084,
      lng: -94.66595,
      city: 'Galena',
      state: 'KS',
    },
    66740: {
      lat: 37.4621,
      lng: -95.36641,
      city: 'Galesburg',
      state: 'KS',
    },
    66741: {
      lat: 37.71745,
      lng: -94.66467,
      city: 'Garland',
      state: 'KS',
    },
    66742: { lat: 37.92023, lng: -95.34755, city: 'Gas', state: 'KS' },
    66743: {
      lat: 37.50551,
      lng: -94.88786,
      city: 'Girard',
      state: 'KS',
    },
    66746: {
      lat: 37.6708,
      lng: -94.95317,
      city: 'Hepler',
      state: 'KS',
    },
    66748: {
      lat: 37.79882,
      lng: -95.43577,
      city: 'Humboldt',
      state: 'KS',
    },
    66749: { lat: 37.93597, lng: -95.41069, city: 'Iola', state: 'KS' },
    66751: {
      lat: 37.93421,
      lng: -95.28466,
      city: 'La Harpe',
      state: 'KS',
    },
    66753: {
      lat: 37.36529,
      lng: -95.01444,
      city: 'McCune',
      state: 'KS',
    },
    66754: {
      lat: 38.00377,
      lng: -94.88848,
      city: 'Mapleton',
      state: 'KS',
    },
    66755: {
      lat: 37.91211,
      lng: -95.16727,
      city: 'Moran',
      state: 'KS',
    },
    66756: {
      lat: 37.53928,
      lng: -94.64869,
      city: 'Mulberry',
      state: 'KS',
    },
    66757: {
      lat: 37.41602,
      lng: -95.69874,
      city: 'Neodesha',
      state: 'KS',
    },
    66758: {
      lat: 38.01862,
      lng: -95.59602,
      city: 'Neosho Falls',
      state: 'KS',
    },
    66760: {
      lat: 37.34487,
      lng: -94.6245,
      city: 'Opolis',
      state: 'KS',
    },
    66761: {
      lat: 37.91469,
      lng: -95.56694,
      city: 'Piqua',
      state: 'KS',
    },
    66762: {
      lat: 37.39443,
      lng: -94.71092,
      city: 'Pittsburg',
      state: 'KS',
    },
    66763: {
      lat: 37.46063,
      lng: -94.6965,
      city: 'Frontenac',
      state: 'KS',
    },
    66767: {
      lat: 38.07741,
      lng: -94.71159,
      city: 'Prescott',
      state: 'KS',
    },
    66769: {
      lat: 37.85317,
      lng: -94.89379,
      city: 'Redfield',
      state: 'KS',
    },
    66770: {
      lat: 37.08014,
      lng: -94.71601,
      city: 'Riverton',
      state: 'KS',
    },
    66771: {
      lat: 37.49211,
      lng: -95.14883,
      city: 'Saint Paul',
      state: 'KS',
    },
    66772: {
      lat: 37.74559,
      lng: -95.18813,
      city: 'Savonburg',
      state: 'KS',
    },
    66773: {
      lat: 37.26599,
      lng: -94.81262,
      city: 'Scammon',
      state: 'KS',
    },
    66775: {
      lat: 37.69957,
      lng: -95.14855,
      city: 'Stark',
      state: 'KS',
    },
    66776: {
      lat: 37.46848,
      lng: -95.48893,
      city: 'Thayer',
      state: 'KS',
    },
    66777: {
      lat: 37.79087,
      lng: -95.9684,
      city: 'Toronto',
      state: 'KS',
    },
    66778: { lat: 37.00048, lng: -94.839, city: 'Treece', state: 'KS' },
    66779: {
      lat: 37.83751,
      lng: -94.97867,
      city: 'Uniontown',
      state: 'KS',
    },
    66780: {
      lat: 37.60895,
      lng: -95.05881,
      city: 'Walnut',
      state: 'KS',
    },
    66781: { lat: 37.29114, lng: -94.72806, city: 'Weir', state: 'KS' },
    66782: {
      lat: 37.28493,
      lng: -94.92609,
      city: 'West Mineral',
      state: 'KS',
    },
    66783: {
      lat: 37.87682,
      lng: -95.73322,
      city: 'Yates Center',
      state: 'KS',
    },
    66801: {
      lat: 38.41105,
      lng: -96.2221,
      city: 'Emporia',
      state: 'KS',
    },
    66830: {
      lat: 38.59733,
      lng: -96.08755,
      city: 'Admire',
      state: 'KS',
    },
    66833: {
      lat: 38.67694,
      lng: -96.18374,
      city: 'Allen',
      state: 'KS',
    },
    66834: {
      lat: 38.86528,
      lng: -96.44822,
      city: 'Alta Vista',
      state: 'KS',
    },
    66835: {
      lat: 38.53458,
      lng: -96.26266,
      city: 'Americus',
      state: 'KS',
    },
    66838: {
      lat: 38.56207,
      lng: -96.79665,
      city: 'Burdick',
      state: 'KS',
    },
    66839: {
      lat: 38.21655,
      lng: -95.75371,
      city: 'Burlington',
      state: 'KS',
    },
    66840: { lat: 38.07283, lng: -96.8719, city: 'Burns', state: 'KS' },
    66842: {
      lat: 38.0236,
      lng: -96.65836,
      city: 'Cassoday',
      state: 'KS',
    },
    66843: {
      lat: 38.22846,
      lng: -96.74329,
      city: 'Cedar Point',
      state: 'KS',
    },
    66845: {
      lat: 38.29546,
      lng: -96.50762,
      city: 'Cottonwood Falls',
      state: 'KS',
    },
    66846: {
      lat: 38.64383,
      lng: -96.47187,
      city: 'Council Grove',
      state: 'KS',
    },
    66849: {
      lat: 38.87427,
      lng: -96.59789,
      city: 'Dwight',
      state: 'KS',
    },
    66850: {
      lat: 38.40648,
      lng: -96.71764,
      city: 'Elmdale',
      state: 'KS',
    },
    66851: {
      lat: 38.22463,
      lng: -96.92084,
      city: 'Florence',
      state: 'KS',
    },
    66852: {
      lat: 38.0716,
      lng: -95.89874,
      city: 'Gridley',
      state: 'KS',
    },
    66853: {
      lat: 38.00653,
      lng: -96.24232,
      city: 'Hamilton',
      state: 'KS',
    },
    66854: {
      lat: 38.25872,
      lng: -95.98735,
      city: 'Hartford',
      state: 'KS',
    },
    66856: { lat: 38.39853, lng: -95.81838, city: 'Lebo', state: 'KS' },
    66857: {
      lat: 38.10202,
      lng: -95.63526,
      city: 'Le Roy',
      state: 'KS',
    },
    66858: {
      lat: 38.47462,
      lng: -96.9207,
      city: 'Lincolnville',
      state: 'KS',
    },
    66859: {
      lat: 38.55653,
      lng: -96.96315,
      city: 'Lost Springs',
      state: 'KS',
    },
    66860: {
      lat: 38.12775,
      lng: -96.1652,
      city: 'Madison',
      state: 'KS',
    },
    66861: {
      lat: 38.36415,
      lng: -97.00617,
      city: 'Marion',
      state: 'KS',
    },
    66862: {
      lat: 38.15532,
      lng: -96.55403,
      city: 'Matfield Green',
      state: 'KS',
    },
    66863: { lat: 37.83368, lng: -96.07633, city: 'Neal', state: 'KS' },
    66864: {
      lat: 38.36132,
      lng: -95.98207,
      city: 'Neosho Rapids',
      state: 'KS',
    },
    66865: { lat: 38.2181, lng: -96.27993, city: 'Olpe', state: 'KS' },
    66866: {
      lat: 38.17438,
      lng: -97.10385,
      city: 'Peabody',
      state: 'KS',
    },
    66868: {
      lat: 38.54284,
      lng: -95.98387,
      city: 'Reading',
      state: 'KS',
    },
    66869: {
      lat: 38.4548,
      lng: -96.54444,
      city: 'Strong City',
      state: 'KS',
    },
    66870: {
      lat: 37.93809,
      lng: -95.99316,
      city: 'Virgil',
      state: 'KS',
    },
    66871: {
      lat: 38.35941,
      lng: -95.61815,
      city: 'Waverly',
      state: 'KS',
    },
    66872: {
      lat: 38.79878,
      lng: -96.76147,
      city: 'White City',
      state: 'KS',
    },
    66873: {
      lat: 38.61747,
      lng: -96.66774,
      city: 'Wilsey',
      state: 'KS',
    },
    66901: {
      lat: 39.55618,
      lng: -97.64317,
      city: 'Concordia',
      state: 'KS',
    },
    66930: {
      lat: 39.71137,
      lng: -97.48553,
      city: 'Agenda',
      state: 'KS',
    },
    66932: {
      lat: 39.77925,
      lng: -98.93227,
      city: 'Athol',
      state: 'KS',
    },
    66933: {
      lat: 39.67124,
      lng: -96.86706,
      city: 'Barnes',
      state: 'KS',
    },
    66935: {
      lat: 39.82694,
      lng: -97.63538,
      city: 'Belleville',
      state: 'KS',
    },
    66936: {
      lat: 39.9213,
      lng: -98.30091,
      city: 'Burr Oak',
      state: 'KS',
    },
    66937: {
      lat: 39.58959,
      lng: -97.26493,
      city: 'Clifton',
      state: 'KS',
    },
    66938: {
      lat: 39.58018,
      lng: -97.41081,
      city: 'Clyde',
      state: 'KS',
    },
    66939: {
      lat: 39.85179,
      lng: -97.89754,
      city: 'Courtland',
      state: 'KS',
    },
    66940: { lat: 39.80343, lng: -97.45381, city: 'Cuba', state: 'KS' },
    66941: {
      lat: 39.83135,
      lng: -98.44777,
      city: 'Esbon',
      state: 'KS',
    },
    66942: {
      lat: 39.79278,
      lng: -98.00448,
      city: 'Formoso',
      state: 'KS',
    },
    66943: {
      lat: 39.67209,
      lng: -96.97313,
      city: 'Greenleaf',
      state: 'KS',
    },
    66944: {
      lat: 39.84007,
      lng: -97.29354,
      city: 'Haddam',
      state: 'KS',
    },
    66945: {
      lat: 39.88601,
      lng: -96.87761,
      city: 'Hanover',
      state: 'KS',
    },
    66946: {
      lat: 39.96684,
      lng: -97.00192,
      city: 'Hollenberg',
      state: 'KS',
    },
    66948: {
      lat: 39.59743,
      lng: -97.85141,
      city: 'Jamestown',
      state: 'KS',
    },
    66949: { lat: 39.646, lng: -98.19755, city: 'Jewell', state: 'KS' },
    66951: {
      lat: 39.86239,
      lng: -99.03072,
      city: 'Kensington',
      state: 'KS',
    },
    66952: {
      lat: 39.84269,
      lng: -98.60214,
      city: 'Lebanon',
      state: 'KS',
    },
    66953: { lat: 39.67877, lng: -97.11934, city: 'Linn', state: 'KS' },
    66955: {
      lat: 39.97984,
      lng: -97.31715,
      city: 'Mahaska',
      state: 'KS',
    },
    66956: {
      lat: 39.79625,
      lng: -98.21581,
      city: 'Mankato',
      state: 'KS',
    },
    66958: {
      lat: 39.88657,
      lng: -97.1787,
      city: 'Morrowville',
      state: 'KS',
    },
    66959: {
      lat: 39.92276,
      lng: -97.53551,
      city: 'Munden',
      state: 'KS',
    },
    66960: {
      lat: 39.93042,
      lng: -97.42134,
      city: 'Narka',
      state: 'KS',
    },
    66961: {
      lat: 39.69337,
      lng: -97.77559,
      city: 'Norway',
      state: 'KS',
    },
    66962: {
      lat: 39.59928,
      lng: -97.12547,
      city: 'Palmer',
      state: 'KS',
    },
    66963: {
      lat: 39.64133,
      lng: -98.01064,
      city: 'Randall',
      state: 'KS',
    },
    66964: {
      lat: 39.93465,
      lng: -97.80004,
      city: 'Republic',
      state: 'KS',
    },
    66966: {
      lat: 39.7793,
      lng: -97.76206,
      city: 'Scandia',
      state: 'KS',
    },
    66967: {
      lat: 39.82571,
      lng: -98.79366,
      city: 'Smith Center',
      state: 'KS',
    },
    66968: {
      lat: 39.8294,
      lng: -97.06524,
      city: 'Washington',
      state: 'KS',
    },
    66970: {
      lat: 39.94533,
      lng: -98.02336,
      city: 'Webber',
      state: 'KS',
    },
    67001: {
      lat: 37.76069,
      lng: -97.62953,
      city: 'Andale',
      state: 'KS',
    },
    67002: {
      lat: 37.69575,
      lng: -97.10725,
      city: 'Andover',
      state: 'KS',
    },
    67003: {
      lat: 37.1109,
      lng: -98.06319,
      city: 'Anthony',
      state: 'KS',
    },
    67004: {
      lat: 37.28317,
      lng: -97.76401,
      city: 'Argonia',
      state: 'KS',
    },
    67005: {
      lat: 37.06722,
      lng: -96.99645,
      city: 'Arkansas City',
      state: 'KS',
    },
    67008: {
      lat: 37.47796,
      lng: -96.76983,
      city: 'Atlanta',
      state: 'KS',
    },
    67009: {
      lat: 37.22153,
      lng: -98.26369,
      city: 'Attica',
      state: 'KS',
    },
    67010: {
      lat: 37.66099,
      lng: -96.97388,
      city: 'Augusta',
      state: 'KS',
    },
    67012: {
      lat: 37.66175,
      lng: -96.54701,
      city: 'Beaumont',
      state: 'KS',
    },
    67013: {
      lat: 37.37904,
      lng: -97.27378,
      city: 'Belle Plaine',
      state: 'KS',
    },
    67016: {
      lat: 37.88882,
      lng: -97.51812,
      city: 'Bentley',
      state: 'KS',
    },
    67017: {
      lat: 37.82778,
      lng: -97.11165,
      city: 'Benton',
      state: 'KS',
    },
    67018: {
      lat: 37.07108,
      lng: -97.85872,
      city: 'Bluff City',
      state: 'KS',
    },
    67019: {
      lat: 37.31225,
      lng: -96.78174,
      city: 'Burden',
      state: 'KS',
    },
    67020: {
      lat: 38.00881,
      lng: -97.66903,
      city: 'Burrton',
      state: 'KS',
    },
    67021: {
      lat: 37.77615,
      lng: -98.91045,
      city: 'Byers',
      state: 'KS',
    },
    67022: {
      lat: 37.07352,
      lng: -97.63347,
      city: 'Caldwell',
      state: 'KS',
    },
    67023: {
      lat: 37.33467,
      lng: -96.61288,
      city: 'Cambridge',
      state: 'KS',
    },
    67024: {
      lat: 37.11397,
      lng: -96.49164,
      city: 'Cedar Vale',
      state: 'KS',
    },
    67025: {
      lat: 37.64146,
      lng: -97.78789,
      city: 'Cheney',
      state: 'KS',
    },
    67026: {
      lat: 37.51047,
      lng: -97.49239,
      city: 'Clearwater',
      state: 'KS',
    },
    67028: {
      lat: 37.47226,
      lng: -98.96561,
      city: 'Coats',
      state: 'KS',
    },
    67029: {
      lat: 37.16925,
      lng: -99.28026,
      city: 'Coldwater',
      state: 'KS',
    },
    67030: {
      lat: 37.78548,
      lng: -97.54661,
      city: 'Colwich',
      state: 'KS',
    },
    67031: {
      lat: 37.40444,
      lng: -97.63848,
      city: 'Conway Springs',
      state: 'KS',
    },
    67035: {
      lat: 37.64845,
      lng: -98.39338,
      city: 'Cunningham',
      state: 'KS',
    },
    67036: {
      lat: 37.28987,
      lng: -97.88262,
      city: 'Danville',
      state: 'KS',
    },
    67037: {
      lat: 37.56646,
      lng: -97.22095,
      city: 'Derby',
      state: 'KS',
    },
    67038: {
      lat: 37.11702,
      lng: -96.70905,
      city: 'Dexter',
      state: 'KS',
    },
    67039: {
      lat: 37.52163,
      lng: -96.97322,
      city: 'Douglass',
      state: 'KS',
    },
    67041: {
      lat: 38.05404,
      lng: -97.12755,
      city: 'Elbing',
      state: 'KS',
    },
    67042: {
      lat: 37.84868,
      lng: -96.79809,
      city: 'El Dorado',
      state: 'KS',
    },
    67045: {
      lat: 37.85131,
      lng: -96.33863,
      city: 'Eureka',
      state: 'KS',
    },
    67047: {
      lat: 37.63921,
      lng: -96.05327,
      city: 'Fall River',
      state: 'KS',
    },
    67049: {
      lat: 37.17059,
      lng: -97.84646,
      city: 'Freeport',
      state: 'KS',
    },
    67050: {
      lat: 37.675,
      lng: -97.6769,
      city: 'Garden Plain',
      state: 'KS',
    },
    67051: {
      lat: 37.08932,
      lng: -97.19399,
      city: 'Geuda Springs',
      state: 'KS',
    },
    67052: {
      lat: 37.65812,
      lng: -97.57357,
      city: 'Goddard',
      state: 'KS',
    },
    67053: {
      lat: 38.24728,
      lng: -97.34545,
      city: 'Goessel',
      state: 'KS',
    },
    67054: {
      lat: 37.57658,
      lng: -99.31884,
      city: 'Greensburg',
      state: 'KS',
    },
    67055: {
      lat: 37.78325,
      lng: -97.2037,
      city: 'Greenwich',
      state: 'KS',
    },
    67056: {
      lat: 38.01936,
      lng: -97.52345,
      city: 'Halstead',
      state: 'KS',
    },
    67057: {
      lat: 37.05954,
      lng: -98.77864,
      city: 'Hardtner',
      state: 'KS',
    },
    67058: {
      lat: 37.32006,
      lng: -98.06493,
      city: 'Harper',
      state: 'KS',
    },
    67059: {
      lat: 37.65102,
      lng: -99.10046,
      city: 'Haviland',
      state: 'KS',
    },
    67060: {
      lat: 37.54601,
      lng: -97.35952,
      city: 'Haysville',
      state: 'KS',
    },
    67061: {
      lat: 37.08593,
      lng: -98.37673,
      city: 'Hazelton',
      state: 'KS',
    },
    67062: {
      lat: 38.1431,
      lng: -97.43296,
      city: 'Hesston',
      state: 'KS',
    },
    67063: {
      lat: 38.34244,
      lng: -97.23019,
      city: 'Hillsboro',
      state: 'KS',
    },
    67065: {
      lat: 37.48295,
      lng: -98.52989,
      city: 'Isabel',
      state: 'KS',
    },
    67066: { lat: 37.77374, lng: -98.75798, city: 'Iuka', state: 'KS' },
    67067: { lat: 37.7951, lng: -97.27044, city: 'Kechi', state: 'KS' },
    67068: {
      lat: 37.61991,
      lng: -98.11473,
      city: 'Kingman',
      state: 'KS',
    },
    67070: { lat: 37.0696, lng: -98.53204, city: 'Kiowa', state: 'KS' },
    67071: {
      lat: 37.21423,
      lng: -98.88522,
      city: 'Lake City',
      state: 'KS',
    },
    67072: {
      lat: 37.5445,
      lng: -96.61632,
      city: 'Latham',
      state: 'KS',
    },
    67073: {
      lat: 38.39167,
      lng: -97.32624,
      city: 'Lehigh',
      state: 'KS',
    },
    67074: { lat: 37.67038, lng: -96.72945, city: 'Leon', state: 'KS' },
    67101: {
      lat: 37.78489,
      lng: -97.46356,
      city: 'Maize',
      state: 'KS',
    },
    67102: {
      lat: 37.03857,
      lng: -96.78839,
      city: 'Maple City',
      state: 'KS',
    },
    67103: {
      lat: 37.26439,
      lng: -97.55977,
      city: 'Mayfield',
      state: 'KS',
    },
    67104: {
      lat: 37.27286,
      lng: -98.65223,
      city: 'Medicine Lodge',
      state: 'KS',
    },
    67105: {
      lat: 37.22001,
      lng: -97.67825,
      city: 'Milan',
      state: 'KS',
    },
    67106: {
      lat: 37.46335,
      lng: -97.75804,
      city: 'Milton',
      state: 'KS',
    },
    67107: {
      lat: 38.19496,
      lng: -97.53911,
      city: 'Moundridge',
      state: 'KS',
    },
    67108: {
      lat: 37.81428,
      lng: -97.68621,
      city: 'Mount Hope',
      state: 'KS',
    },
    67109: {
      lat: 37.56448,
      lng: -99.47914,
      city: 'Mullinville',
      state: 'KS',
    },
    67110: {
      lat: 37.47022,
      lng: -97.22425,
      city: 'Mulvane',
      state: 'KS',
    },
    67111: {
      lat: 37.6151,
      lng: -97.93453,
      city: 'Murdock',
      state: 'KS',
    },
    67112: {
      lat: 37.44915,
      lng: -98.41941,
      city: 'Nashville',
      state: 'KS',
    },
    67114: {
      lat: 38.07034,
      lng: -97.28359,
      city: 'Newton',
      state: 'KS',
    },
    67117: {
      lat: 38.07416,
      lng: -97.34544,
      city: 'North Newton',
      state: 'KS',
    },
    67118: {
      lat: 37.47829,
      lng: -97.8605,
      city: 'Norwich',
      state: 'KS',
    },
    67119: {
      lat: 37.24202,
      lng: -97.18152,
      city: 'Oxford',
      state: 'KS',
    },
    67120: { lat: 37.46591, lng: -97.37592, city: 'Peck', state: 'KS' },
    67122: {
      lat: 37.62015,
      lng: -96.42392,
      city: 'Piedmont',
      state: 'KS',
    },
    67123: {
      lat: 37.94676,
      lng: -96.99352,
      city: 'Potwin',
      state: 'KS',
    },
    67124: {
      lat: 37.63644,
      lng: -98.77236,
      city: 'Pratt',
      state: 'KS',
    },
    67127: {
      lat: 37.16009,
      lng: -99.49057,
      city: 'Protection',
      state: 'KS',
    },
    67131: { lat: 37.43261, lng: -96.94958, city: 'Rock', state: 'KS' },
    67132: {
      lat: 37.8504,
      lng: -96.58385,
      city: 'Rosalia',
      state: 'KS',
    },
    67133: {
      lat: 37.57677,
      lng: -97.10451,
      city: 'Rose Hill',
      state: 'KS',
    },
    67134: {
      lat: 37.48474,
      lng: -98.6824,
      city: 'Sawyer',
      state: 'KS',
    },
    67135: {
      lat: 37.91362,
      lng: -97.45942,
      city: 'Sedgwick',
      state: 'KS',
    },
    67137: {
      lat: 37.63145,
      lng: -96.22196,
      city: 'Severy',
      state: 'KS',
    },
    67138: {
      lat: 37.25996,
      lng: -98.41858,
      city: 'Sharon',
      state: 'KS',
    },
    67140: {
      lat: 37.07992,
      lng: -97.35748,
      city: 'South Haven',
      state: 'KS',
    },
    67142: {
      lat: 37.45655,
      lng: -98.1094,
      city: 'Spivey',
      state: 'KS',
    },
    67143: {
      lat: 37.33284,
      lng: -98.94434,
      city: 'Sun City',
      state: 'KS',
    },
    67144: {
      lat: 37.81673,
      lng: -97.00539,
      city: 'Towanda',
      state: 'KS',
    },
    67146: {
      lat: 37.39016,
      lng: -97.10398,
      city: 'Udall',
      state: 'KS',
    },
    67147: {
      lat: 37.86062,
      lng: -97.3058,
      city: 'Valley Center',
      state: 'KS',
    },
    67149: {
      lat: 37.54243,
      lng: -97.62915,
      city: 'Viola',
      state: 'KS',
    },
    67150: {
      lat: 37.03207,
      lng: -98.20187,
      city: 'Waldron',
      state: 'KS',
    },
    67151: {
      lat: 38.14499,
      lng: -97.24934,
      city: 'Walton',
      state: 'KS',
    },
    67152: {
      lat: 37.2623,
      lng: -97.41104,
      city: 'Wellington',
      state: 'KS',
    },
    67154: {
      lat: 37.97079,
      lng: -97.12117,
      city: 'Whitewater',
      state: 'KS',
    },
    67155: {
      lat: 37.28171,
      lng: -99.13233,
      city: 'Wilmore',
      state: 'KS',
    },
    67156: {
      lat: 37.25099,
      lng: -96.96503,
      city: 'Winfield',
      state: 'KS',
    },
    67159: {
      lat: 37.42053,
      lng: -98.30948,
      city: 'Zenda',
      state: 'KS',
    },
    67202: {
      lat: 37.68668,
      lng: -97.33521,
      city: 'Wichita',
      state: 'KS',
    },
    67203: {
      lat: 37.70402,
      lng: -97.36497,
      city: 'Wichita',
      state: 'KS',
    },
    67204: {
      lat: 37.77167,
      lng: -97.36341,
      city: 'Wichita',
      state: 'KS',
    },
    67205: {
      lat: 37.75012,
      lng: -97.42317,
      city: 'Wichita',
      state: 'KS',
    },
    67206: {
      lat: 37.70363,
      lng: -97.22461,
      city: 'Wichita',
      state: 'KS',
    },
    67207: {
      lat: 37.66567,
      lng: -97.22923,
      city: 'Wichita',
      state: 'KS',
    },
    67208: {
      lat: 37.70449,
      lng: -97.27928,
      city: 'Wichita',
      state: 'KS',
    },
    67209: {
      lat: 37.6639,
      lng: -97.43463,
      city: 'Wichita',
      state: 'KS',
    },
    67210: {
      lat: 37.62944,
      lng: -97.24604,
      city: 'Wichita',
      state: 'KS',
    },
    67211: {
      lat: 37.66708,
      lng: -97.31706,
      city: 'Wichita',
      state: 'KS',
    },
    67212: {
      lat: 37.70247,
      lng: -97.43658,
      city: 'Wichita',
      state: 'KS',
    },
    67213: {
      lat: 37.66722,
      lng: -97.36498,
      city: 'Wichita',
      state: 'KS',
    },
    67214: {
      lat: 37.70661,
      lng: -97.31755,
      city: 'Wichita',
      state: 'KS',
    },
    67215: {
      lat: 37.61637,
      lng: -97.42707,
      city: 'Wichita',
      state: 'KS',
    },
    67216: {
      lat: 37.6089,
      lng: -97.3143,
      city: 'Wichita',
      state: 'KS',
    },
    67217: {
      lat: 37.61535,
      lng: -97.36239,
      city: 'Wichita',
      state: 'KS',
    },
    67218: {
      lat: 37.66851,
      lng: -97.28039,
      city: 'Wichita',
      state: 'KS',
    },
    67219: {
      lat: 37.76931,
      lng: -97.31643,
      city: 'Wichita',
      state: 'KS',
    },
    67220: {
      lat: 37.75191,
      lng: -97.27909,
      city: 'Wichita',
      state: 'KS',
    },
    67221: {
      lat: 37.63544,
      lng: -97.25819,
      city: 'Mcconnell Afb',
      state: 'KS',
    },
    67223: {
      lat: 37.74195,
      lng: -97.49797,
      city: 'Wichita',
      state: 'KS',
    },
    67226: {
      lat: 37.76898,
      lng: -97.21915,
      city: 'Wichita',
      state: 'KS',
    },
    67227: {
      lat: 37.62693,
      lng: -97.50109,
      city: 'Wichita',
      state: 'KS',
    },
    67228: {
      lat: 37.77749,
      lng: -97.17274,
      city: 'Wichita',
      state: 'KS',
    },
    67230: {
      lat: 37.68655,
      lng: -97.17087,
      city: 'Wichita',
      state: 'KS',
    },
    67232: {
      lat: 37.6368,
      lng: -97.16802,
      city: 'Wichita',
      state: 'KS',
    },
    67235: {
      lat: 37.68951,
      lng: -97.49929,
      city: 'Wichita',
      state: 'KS',
    },
    67260: {
      lat: 37.71931,
      lng: -97.2935,
      city: 'Wichita',
      state: 'KS',
    },
    67301: {
      lat: 37.21092,
      lng: -95.75224,
      city: 'Independence',
      state: 'KS',
    },
    67330: {
      lat: 37.163,
      lng: -95.31238,
      city: 'Altamont',
      state: 'KS',
    },
    67332: {
      lat: 37.04345,
      lng: -95.23281,
      city: 'Bartlett',
      state: 'KS',
    },
    67333: {
      lat: 37.04695,
      lng: -95.87253,
      city: 'Caney',
      state: 'KS',
    },
    67334: {
      lat: 37.0241,
      lng: -96.17736,
      city: 'Chautauqua',
      state: 'KS',
    },
    67335: {
      lat: 37.28005,
      lng: -95.53381,
      city: 'Cherryvale',
      state: 'KS',
    },
    67336: {
      lat: 37.05385,
      lng: -95.07489,
      city: 'Chetopa',
      state: 'KS',
    },
    67337: {
      lat: 37.05486,
      lng: -95.5993,
      city: 'Coffeyville',
      state: 'KS',
    },
    67340: {
      lat: 37.05948,
      lng: -95.71207,
      city: 'Dearing',
      state: 'KS',
    },
    67341: {
      lat: 37.34406,
      lng: -95.42963,
      city: 'Dennis',
      state: 'KS',
    },
    67342: { lat: 37.05877, lng: -95.35513, city: 'Edna', state: 'KS' },
    67344: {
      lat: 37.28875,
      lng: -95.9514,
      city: 'Elk City',
      state: 'KS',
    },
    67345: {
      lat: 37.37122,
      lng: -96.20075,
      city: 'Elk Falls',
      state: 'KS',
    },
    67346: {
      lat: 37.31774,
      lng: -96.44511,
      city: 'Grenola',
      state: 'KS',
    },
    67347: {
      lat: 37.12559,
      lng: -95.95188,
      city: 'Havana',
      state: 'KS',
    },
    67349: {
      lat: 37.48811,
      lng: -96.28066,
      city: 'Howard',
      state: 'KS',
    },
    67351: {
      lat: 37.14066,
      lng: -95.56698,
      city: 'Liberty',
      state: 'KS',
    },
    67352: {
      lat: 37.3574,
      lng: -96.08154,
      city: 'Longton',
      state: 'KS',
    },
    67353: {
      lat: 37.31371,
      lng: -96.31151,
      city: 'Moline',
      state: 'KS',
    },
    67354: {
      lat: 37.20727,
      lng: -95.41126,
      city: 'Mound Valley',
      state: 'KS',
    },
    67355: {
      lat: 37.03275,
      lng: -96.00974,
      city: 'Niotaze',
      state: 'KS',
    },
    67356: {
      lat: 37.17886,
      lng: -95.14161,
      city: 'Oswego',
      state: 'KS',
    },
    67357: {
      lat: 37.33951,
      lng: -95.25261,
      city: 'Parsons',
      state: 'KS',
    },
    67360: { lat: 37.05148, lng: -96.09702, city: 'Peru', state: 'KS' },
    67361: {
      lat: 37.13733,
      lng: -96.21714,
      city: 'Sedan',
      state: 'KS',
    },
    67363: {
      lat: 37.32934,
      lng: -95.71819,
      city: 'Sycamore',
      state: 'KS',
    },
    67364: { lat: 37.03683, lng: -95.82184, city: 'Tyro', state: 'KS' },
    67401: {
      lat: 38.83554,
      lng: -97.64621,
      city: 'Salina',
      state: 'KS',
    },
    67410: {
      lat: 38.95632,
      lng: -97.22104,
      city: 'Abilene',
      state: 'KS',
    },
    67416: {
      lat: 38.66505,
      lng: -97.5718,
      city: 'Assaria',
      state: 'KS',
    },
    67417: {
      lat: 39.43382,
      lng: -97.55015,
      city: 'Aurora',
      state: 'KS',
    },
    67418: {
      lat: 39.18692,
      lng: -98.05214,
      city: 'Barnard',
      state: 'KS',
    },
    67420: {
      lat: 39.40035,
      lng: -98.08527,
      city: 'Beloit',
      state: 'KS',
    },
    67422: {
      lat: 39.03127,
      lng: -97.59849,
      city: 'Bennington',
      state: 'KS',
    },
    67423: {
      lat: 38.97018,
      lng: -97.98058,
      city: 'Beverly',
      state: 'KS',
    },
    67425: {
      lat: 38.77991,
      lng: -97.92492,
      city: 'Brookville',
      state: 'KS',
    },
    67427: {
      lat: 38.50364,
      lng: -98.39977,
      city: 'Bushton',
      state: 'KS',
    },
    67428: {
      lat: 38.3832,
      lng: -97.41405,
      city: 'Canton',
      state: 'KS',
    },
    67430: {
      lat: 39.57571,
      lng: -98.44193,
      city: 'Cawker City',
      state: 'KS',
    },
    67431: {
      lat: 38.95833,
      lng: -97.01179,
      city: 'Chapman',
      state: 'KS',
    },
    67432: {
      lat: 39.33503,
      lng: -97.17997,
      city: 'Clay Center',
      state: 'KS',
    },
    67436: {
      lat: 39.28071,
      lng: -97.72454,
      city: 'Delphos',
      state: 'KS',
    },
    67437: {
      lat: 39.50705,
      lng: -98.55857,
      city: 'Downs',
      state: 'KS',
    },
    67438: {
      lat: 38.50366,
      lng: -97.27861,
      city: 'Durham',
      state: 'KS',
    },
    67439: {
      lat: 38.76169,
      lng: -98.2201,
      city: 'Ellsworth',
      state: 'KS',
    },
    67441: {
      lat: 38.87712,
      lng: -97.10399,
      city: 'Enterprise',
      state: 'KS',
    },
    67442: {
      lat: 38.64939,
      lng: -97.78073,
      city: 'Falun',
      state: 'KS',
    },
    67443: { lat: 38.37801, lng: -97.5208, city: 'Galva', state: 'KS' },
    67444: {
      lat: 38.54759,
      lng: -98.12702,
      city: 'Geneseo',
      state: 'KS',
    },
    67445: {
      lat: 39.37789,
      lng: -97.8172,
      city: 'Glasco',
      state: 'KS',
    },
    67446: {
      lat: 39.47442,
      lng: -98.32363,
      city: 'Glen Elder',
      state: 'KS',
    },
    67447: {
      lat: 39.48048,
      lng: -96.97153,
      city: 'Green',
      state: 'KS',
    },
    67448: {
      lat: 38.65826,
      lng: -97.40756,
      city: 'Gypsum',
      state: 'KS',
    },
    67449: {
      lat: 38.68214,
      lng: -96.89569,
      city: 'Herington',
      state: 'KS',
    },
    67450: {
      lat: 38.62746,
      lng: -98.45788,
      city: 'Holyrood',
      state: 'KS',
    },
    67451: { lat: 38.70094, lng: -97.13157, city: 'Hope', state: 'KS' },
    67452: {
      lat: 39.23549,
      lng: -98.36935,
      city: 'Hunter',
      state: 'KS',
    },
    67454: {
      lat: 38.69808,
      lng: -98.11308,
      city: 'Kanopolis',
      state: 'KS',
    },
    67455: {
      lat: 39.02765,
      lng: -98.16478,
      city: 'Lincoln',
      state: 'KS',
    },
    67456: {
      lat: 38.5949,
      lng: -97.6769,
      city: 'Lindsborg',
      state: 'KS',
    },
    67457: {
      lat: 38.38746,
      lng: -97.99143,
      city: 'Little River',
      state: 'KS',
    },
    67458: {
      lat: 39.16595,
      lng: -97.30441,
      city: 'Longford',
      state: 'KS',
    },
    67459: {
      lat: 38.60781,
      lng: -98.33913,
      city: 'Lorraine',
      state: 'KS',
    },
    67460: {
      lat: 38.38431,
      lng: -97.69719,
      city: 'Mcpherson',
      state: 'KS',
    },
    67464: {
      lat: 38.56537,
      lng: -97.90143,
      city: 'Marquette',
      state: 'KS',
    },
    67466: {
      lat: 39.32787,
      lng: -97.47905,
      city: 'Miltonvale',
      state: 'KS',
    },
    67467: {
      lat: 39.14639,
      lng: -97.69907,
      city: 'Minneapolis',
      state: 'KS',
    },
    67468: {
      lat: 39.45791,
      lng: -97.25697,
      city: 'Morganville',
      state: 'KS',
    },
    67470: {
      lat: 38.91077,
      lng: -97.49513,
      city: 'New Cambria',
      state: 'KS',
    },
    67473: {
      lat: 39.37567,
      lng: -98.73356,
      city: 'Osborne',
      state: 'KS',
    },
    67474: {
      lat: 39.58523,
      lng: -98.70771,
      city: 'Portis',
      state: 'KS',
    },
    67475: {
      lat: 38.58761,
      lng: -97.05853,
      city: 'Ramona',
      state: 'KS',
    },
    67476: {
      lat: 38.54867,
      lng: -97.43047,
      city: 'Roxbury',
      state: 'KS',
    },
    67478: {
      lat: 39.38572,
      lng: -97.94988,
      city: 'Simpson',
      state: 'KS',
    },
    67480: {
      lat: 38.97073,
      lng: -97.40282,
      city: 'Solomon',
      state: 'KS',
    },
    67481: {
      lat: 39.01244,
      lng: -98.38903,
      city: 'Sylvan Grove',
      state: 'KS',
    },
    67482: {
      lat: 39.02686,
      lng: -97.25976,
      city: 'Talmage',
      state: 'KS',
    },
    67483: {
      lat: 38.55322,
      lng: -97.19856,
      city: 'Tampa',
      state: 'KS',
    },
    67484: {
      lat: 38.99603,
      lng: -97.83617,
      city: 'Tescott',
      state: 'KS',
    },
    67485: {
      lat: 39.31474,
      lng: -98.49492,
      city: 'Tipton',
      state: 'KS',
    },
    67487: {
      lat: 39.17628,
      lng: -97.06653,
      city: 'Wakefield',
      state: 'KS',
    },
    67490: {
      lat: 38.79223,
      lng: -98.44722,
      city: 'Wilson',
      state: 'KS',
    },
    67491: {
      lat: 38.38837,
      lng: -97.90051,
      city: 'Windom',
      state: 'KS',
    },
    67492: {
      lat: 38.81558,
      lng: -96.96418,
      city: 'Woodbine',
      state: 'KS',
    },
    67501: {
      lat: 37.97989,
      lng: -97.9309,
      city: 'Hutchinson',
      state: 'KS',
    },
    67502: {
      lat: 38.12146,
      lng: -97.92673,
      city: 'Hutchinson',
      state: 'KS',
    },
    67505: {
      lat: 38.02855,
      lng: -97.94333,
      city: 'South Hutchinson',
      state: 'KS',
    },
    67510: {
      lat: 37.99839,
      lng: -98.21127,
      city: 'Abbyville',
      state: 'KS',
    },
    67511: {
      lat: 38.43365,
      lng: -99.05058,
      city: 'Albert',
      state: 'KS',
    },
    67512: {
      lat: 38.23289,
      lng: -98.32645,
      city: 'Alden',
      state: 'KS',
    },
    67513: {
      lat: 38.43892,
      lng: -99.53846,
      city: 'Alexander',
      state: 'KS',
    },
    67514: {
      lat: 37.86278,
      lng: -98.19714,
      city: 'Arlington',
      state: 'KS',
    },
    67515: {
      lat: 38.59935,
      lng: -100.06518,
      city: 'Arnold',
      state: 'KS',
    },
    67516: {
      lat: 38.38768,
      lng: -99.68486,
      city: 'Bazine',
      state: 'KS',
    },
    67518: {
      lat: 38.40403,
      lng: -100.17343,
      city: 'Beeler',
      state: 'KS',
    },
    67519: {
      lat: 37.93573,
      lng: -99.10292,
      city: 'Belpre',
      state: 'KS',
    },
    67520: {
      lat: 38.58781,
      lng: -99.18302,
      city: 'Bison',
      state: 'KS',
    },
    67521: {
      lat: 38.62575,
      lng: -99.72253,
      city: 'Brownell',
      state: 'KS',
    },
    67522: {
      lat: 38.12294,
      lng: -97.74608,
      city: 'Buhler',
      state: 'KS',
    },
    67523: {
      lat: 38.19687,
      lng: -99.55256,
      city: 'Burdett',
      state: 'KS',
    },
    67524: {
      lat: 38.37797,
      lng: -98.37398,
      city: 'Chase',
      state: 'KS',
    },
    67525: {
      lat: 38.57205,
      lng: -98.57111,
      city: 'Claflin',
      state: 'KS',
    },
    67526: {
      lat: 38.29764,
      lng: -98.54711,
      city: 'Ellinwood',
      state: 'KS',
    },
    67529: {
      lat: 38.0728,
      lng: -99.25436,
      city: 'Garfield',
      state: 'KS',
    },
    67530: {
      lat: 38.35149,
      lng: -98.79678,
      city: 'Great Bend',
      state: 'KS',
    },
    67543: {
      lat: 37.88346,
      lng: -97.79511,
      city: 'Haven',
      state: 'KS',
    },
    67544: {
      lat: 38.57584,
      lng: -98.75456,
      city: 'Hoisington',
      state: 'KS',
    },
    67545: {
      lat: 38.17644,
      lng: -98.6417,
      city: 'Hudson',
      state: 'KS',
    },
    67546: {
      lat: 38.22811,
      lng: -97.82116,
      city: 'Inman',
      state: 'KS',
    },
    67547: {
      lat: 37.93926,
      lng: -99.46526,
      city: 'Kinsley',
      state: 'KS',
    },
    67548: {
      lat: 38.59299,
      lng: -99.33579,
      city: 'La Crosse',
      state: 'KS',
    },
    67550: {
      lat: 38.18121,
      lng: -99.12487,
      city: 'Larned',
      state: 'KS',
    },
    67552: { lat: 37.8606, lng: -99.23651, city: 'Lewis', state: 'KS' },
    67553: {
      lat: 38.65444,
      lng: -99.32017,
      city: 'Liebenthal',
      state: 'KS',
    },
    67554: { lat: 38.37489, lng: -98.1858, city: 'Lyons', state: 'KS' },
    67556: {
      lat: 38.60719,
      lng: -99.52436,
      city: 'McCracken',
      state: 'KS',
    },
    67557: {
      lat: 37.91557,
      lng: -98.96678,
      city: 'Macksville',
      state: 'KS',
    },
    67559: {
      lat: 38.37645,
      lng: -99.44966,
      city: 'Nekoma',
      state: 'KS',
    },
    67560: {
      lat: 38.39781,
      lng: -99.94818,
      city: 'Ness City',
      state: 'KS',
    },
    67561: {
      lat: 38.10427,
      lng: -98.09921,
      city: 'Nickerson',
      state: 'KS',
    },
    67563: {
      lat: 37.84627,
      lng: -99.55931,
      city: 'Offerle',
      state: 'KS',
    },
    67564: {
      lat: 38.52855,
      lng: -98.92845,
      city: 'Olmitz',
      state: 'KS',
    },
    67565: { lat: 38.60428, lng: -99.04439, city: 'Otis', state: 'KS' },
    67566: {
      lat: 37.944,
      lng: -98.09938,
      city: 'Partridge',
      state: 'KS',
    },
    67567: {
      lat: 38.26632,
      lng: -98.97241,
      city: 'Pawnee Rock',
      state: 'KS',
    },
    67568: {
      lat: 38.01729,
      lng: -98.30888,
      city: 'Plevna',
      state: 'KS',
    },
    67570: {
      lat: 37.78686,
      lng: -97.968,
      city: 'Pretty Prairie',
      state: 'KS',
    },
    67572: {
      lat: 38.68663,
      lng: -99.90825,
      city: 'Ransom',
      state: 'KS',
    },
    67573: {
      lat: 38.26215,
      lng: -98.41518,
      city: 'Raymond',
      state: 'KS',
    },
    67574: {
      lat: 38.22045,
      lng: -99.39185,
      city: 'Rozel',
      state: 'KS',
    },
    67575: {
      lat: 38.40711,
      lng: -99.25681,
      city: 'Rush Center',
      state: 'KS',
    },
    67576: {
      lat: 38.03228,
      lng: -98.79343,
      city: 'St John',
      state: 'KS',
    },
    67578: {
      lat: 37.96922,
      lng: -98.59105,
      city: 'Stafford',
      state: 'KS',
    },
    67579: {
      lat: 38.18615,
      lng: -98.20861,
      city: 'Sterling',
      state: 'KS',
    },
    67581: {
      lat: 37.99316,
      lng: -98.41068,
      city: 'Sylvia',
      state: 'KS',
    },
    67583: { lat: 37.79997, lng: -98.4714, city: 'Turon', state: 'KS' },
    67584: {
      lat: 38.6847,
      lng: -100.16072,
      city: 'Utica',
      state: 'KS',
    },
    67601: { lat: 38.873, lng: -99.34032, city: 'Hays', state: 'KS' },
    67621: { lat: 39.82866, lng: -99.12891, city: 'Agra', state: 'KS' },
    67622: {
      lat: 39.89983,
      lng: -99.72522,
      city: 'Almena',
      state: 'KS',
    },
    67623: {
      lat: 39.45363,
      lng: -98.95627,
      city: 'Alton',
      state: 'KS',
    },
    67625: {
      lat: 39.39145,
      lng: -99.68293,
      city: 'Bogue',
      state: 'KS',
    },
    67626: {
      lat: 38.88375,
      lng: -98.70808,
      city: 'Bunker Hill',
      state: 'KS',
    },
    67627: {
      lat: 38.92224,
      lng: -99.21343,
      city: 'Catharine',
      state: 'KS',
    },
    67628: {
      lat: 39.62741,
      lng: -98.99598,
      city: 'Cedar',
      state: 'KS',
    },
    67629: {
      lat: 39.70199,
      lng: -100.18031,
      city: 'Clayton',
      state: 'KS',
    },
    67631: {
      lat: 39.00837,
      lng: -100.08569,
      city: 'Collyer',
      state: 'KS',
    },
    67632: {
      lat: 39.33557,
      lng: -99.58362,
      city: 'Damar',
      state: 'KS',
    },
    67634: {
      lat: 38.82654,
      lng: -98.6073,
      city: 'Dorrance',
      state: 'KS',
    },
    67635: {
      lat: 39.60025,
      lng: -100.45484,
      city: 'Dresden',
      state: 'KS',
    },
    67637: {
      lat: 38.94008,
      lng: -99.59197,
      city: 'Ellis',
      state: 'KS',
    },
    67638: {
      lat: 39.63418,
      lng: -98.8534,
      city: 'Gaylord',
      state: 'KS',
    },
    67639: {
      lat: 39.62649,
      lng: -99.32133,
      city: 'Glade',
      state: 'KS',
    },
    67640: {
      lat: 38.89853,
      lng: -99.04925,
      city: 'Gorham',
      state: 'KS',
    },
    67642: {
      lat: 39.36989,
      lng: -99.8315,
      city: 'Hill City',
      state: 'KS',
    },
    67643: {
      lat: 39.6406,
      lng: -100.29135,
      city: 'Jennings',
      state: 'KS',
    },
    67644: {
      lat: 39.63104,
      lng: -99.15993,
      city: 'Kirwin',
      state: 'KS',
    },
    67645: {
      lat: 39.60144,
      lng: -99.89604,
      city: 'Lenora',
      state: 'KS',
    },
    67646: {
      lat: 39.63921,
      lng: -99.58654,
      city: 'Logan',
      state: 'KS',
    },
    67647: {
      lat: 39.93794,
      lng: -99.53162,
      city: 'Long Island',
      state: 'KS',
    },
    67648: {
      lat: 39.07746,
      lng: -98.57278,
      city: 'Lucas',
      state: 'KS',
    },
    67649: {
      lat: 39.13404,
      lng: -98.69232,
      city: 'Luray',
      state: 'KS',
    },
    67650: {
      lat: 39.37477,
      lng: -100.09925,
      city: 'Morland',
      state: 'KS',
    },
    67651: {
      lat: 39.20926,
      lng: -99.03969,
      city: 'Natoma',
      state: 'KS',
    },
    67653: {
      lat: 39.90873,
      lng: -100.20554,
      city: 'Norcatur',
      state: 'KS',
    },
    67654: {
      lat: 39.83067,
      lng: -99.95573,
      city: 'Norton',
      state: 'KS',
    },
    67656: {
      lat: 38.98565,
      lng: -99.75549,
      city: 'Ogallah',
      state: 'KS',
    },
    67657: {
      lat: 39.21126,
      lng: -99.60811,
      city: 'Palco',
      state: 'KS',
    },
    67658: {
      lat: 39.11524,
      lng: -98.92786,
      city: 'Paradise',
      state: 'KS',
    },
    67659: {
      lat: 39.31804,
      lng: -100.00496,
      city: 'Penokee',
      state: 'KS',
    },
    67660: {
      lat: 38.71752,
      lng: -99.14806,
      city: 'Pfeifer',
      state: 'KS',
    },
    67661: {
      lat: 39.82546,
      lng: -99.33039,
      city: 'Phillipsburg',
      state: 'KS',
    },
    67663: {
      lat: 39.20597,
      lng: -99.34222,
      city: 'Plainville',
      state: 'KS',
    },
    67664: {
      lat: 39.81609,
      lng: -99.5875,
      city: 'Prairie View',
      state: 'KS',
    },
    67665: {
      lat: 38.847,
      lng: -98.88022,
      city: 'Russell',
      state: 'KS',
    },
    67667: {
      lat: 38.71304,
      lng: -99.33249,
      city: 'Schoenchen',
      state: 'KS',
    },
    67669: {
      lat: 39.45631,
      lng: -99.34914,
      city: 'Stockton',
      state: 'KS',
    },
    67671: {
      lat: 38.87559,
      lng: -99.14432,
      city: 'Victoria',
      state: 'KS',
    },
    67672: {
      lat: 38.99882,
      lng: -99.90746,
      city: 'Wakeeney',
      state: 'KS',
    },
    67673: {
      lat: 39.14708,
      lng: -98.80714,
      city: 'Waldo',
      state: 'KS',
    },
    67674: {
      lat: 38.87446,
      lng: -99.08197,
      city: 'Walker',
      state: 'KS',
    },
    67675: {
      lat: 39.42612,
      lng: -99.09994,
      city: 'Woodston',
      state: 'KS',
    },
    67701: {
      lat: 39.39509,
      lng: -101.05034,
      city: 'Colby',
      state: 'KS',
    },
    67730: {
      lat: 39.80366,
      lng: -101.11624,
      city: 'Atwood',
      state: 'KS',
    },
    67731: {
      lat: 39.76277,
      lng: -101.53858,
      city: 'Bird City',
      state: 'KS',
    },
    67732: {
      lat: 39.37333,
      lng: -101.3708,
      city: 'Brewster',
      state: 'KS',
    },
    67733: {
      lat: 39.32998,
      lng: -101.50653,
      city: 'Edson',
      state: 'KS',
    },
    67734: { lat: 39.54241, lng: -100.89298, city: 'Gem', state: 'KS' },
    67735: {
      lat: 39.35119,
      lng: -101.76646,
      city: 'Goodland',
      state: 'KS',
    },
    67736: {
      lat: 38.85492,
      lng: -100.44715,
      city: 'Gove',
      state: 'KS',
    },
    67737: {
      lat: 39.08798,
      lng: -100.49367,
      city: 'Grainfield',
      state: 'KS',
    },
    67738: {
      lat: 39.0027,
      lng: -100.65348,
      city: 'Grinnell',
      state: 'KS',
    },
    67739: {
      lat: 39.8848,
      lng: -100.80581,
      city: 'Herndon',
      state: 'KS',
    },
    67740: {
      lat: 39.35576,
      lng: -100.36089,
      city: 'Hoxie',
      state: 'KS',
    },
    67741: {
      lat: 39.38269,
      lng: -102.00822,
      city: 'Kanorado',
      state: 'KS',
    },
    67743: {
      lat: 39.38826,
      lng: -101.24121,
      city: 'Levant',
      state: 'KS',
    },
    67744: {
      lat: 39.85952,
      lng: -100.94095,
      city: 'Ludell',
      state: 'KS',
    },
    67745: {
      lat: 39.8147,
      lng: -101.34795,
      city: 'McDonald',
      state: 'KS',
    },
    67747: {
      lat: 39.04208,
      lng: -101.06099,
      city: 'Monument',
      state: 'KS',
    },
    67748: {
      lat: 39.06507,
      lng: -100.85643,
      city: 'Oakley',
      state: 'KS',
    },
    67749: {
      lat: 39.83214,
      lng: -100.56728,
      city: 'Oberlin',
      state: 'KS',
    },
    67751: {
      lat: 39.11799,
      lng: -100.35572,
      city: 'Park',
      state: 'KS',
    },
    67752: {
      lat: 38.9924,
      lng: -100.23145,
      city: 'Quinter',
      state: 'KS',
    },
    67753: {
      lat: 39.43164,
      lng: -100.74805,
      city: 'Rexford',
      state: 'KS',
    },
    67756: {
      lat: 39.79815,
      lng: -101.84265,
      city: 'Saint Francis',
      state: 'KS',
    },
    67757: {
      lat: 39.54527,
      lng: -100.60881,
      city: 'Selden',
      state: 'KS',
    },
    67758: {
      lat: 38.89658,
      lng: -101.7842,
      city: 'Sharon Springs',
      state: 'KS',
    },
    67761: {
      lat: 38.95066,
      lng: -101.52523,
      city: 'Wallace',
      state: 'KS',
    },
    67762: {
      lat: 38.84166,
      lng: -101.98611,
      city: 'Weskan',
      state: 'KS',
    },
    67764: {
      lat: 38.89135,
      lng: -101.15041,
      city: 'Winona',
      state: 'KS',
    },
    67801: {
      lat: 37.72961,
      lng: -100.05406,
      city: 'Dodge City',
      state: 'KS',
    },
    67831: {
      lat: 37.17956,
      lng: -99.8018,
      city: 'Ashland',
      state: 'KS',
    },
    67834: {
      lat: 37.53174,
      lng: -99.61886,
      city: 'Bucklin',
      state: 'KS',
    },
    67835: {
      lat: 37.98071,
      lng: -100.31746,
      city: 'Cimarron',
      state: 'KS',
    },
    67836: {
      lat: 38.03977,
      lng: -101.9942,
      city: 'Coolidge',
      state: 'KS',
    },
    67837: {
      lat: 37.55615,
      lng: -100.66289,
      city: 'Copeland',
      state: 'KS',
    },
    67838: {
      lat: 38.05641,
      lng: -101.13086,
      city: 'Deerfield',
      state: 'KS',
    },
    67839: {
      lat: 38.47911,
      lng: -100.43961,
      city: 'Dighton',
      state: 'KS',
    },
    67840: {
      lat: 37.0736,
      lng: -100.01097,
      city: 'Englewood',
      state: 'KS',
    },
    67841: {
      lat: 37.62997,
      lng: -100.22878,
      city: 'Ensign',
      state: 'KS',
    },
    67842: { lat: 37.54766, lng: -99.77138, city: 'Ford', state: 'KS' },
    67843: {
      lat: 37.73027,
      lng: -99.93728,
      city: 'Fort Dodge',
      state: 'KS',
    },
    67844: {
      lat: 37.37798,
      lng: -100.19562,
      city: 'Fowler',
      state: 'KS',
    },
    67846: {
      lat: 38.02311,
      lng: -100.77756,
      city: 'Garden City',
      state: 'KS',
    },
    67849: {
      lat: 38.15061,
      lng: -99.71725,
      city: 'Hanston',
      state: 'KS',
    },
    67850: {
      lat: 38.6315,
      lng: -100.63896,
      city: 'Healy',
      state: 'KS',
    },
    67851: {
      lat: 38.08796,
      lng: -101.01972,
      city: 'Holcomb',
      state: 'KS',
    },
    67853: {
      lat: 37.88416,
      lng: -100.51616,
      city: 'Ingalls',
      state: 'KS',
    },
    67854: {
      lat: 38.09034,
      lng: -100.0023,
      city: 'Jetmore',
      state: 'KS',
    },
    67855: {
      lat: 37.54551,
      lng: -101.69477,
      city: 'Johnson',
      state: 'KS',
    },
    67857: {
      lat: 37.99598,
      lng: -101.55154,
      city: 'Kendall',
      state: 'KS',
    },
    67859: {
      lat: 37.23636,
      lng: -100.77421,
      city: 'Kismet',
      state: 'KS',
    },
    67860: {
      lat: 38.0185,
      lng: -101.29831,
      city: 'Lakin',
      state: 'KS',
    },
    67861: {
      lat: 38.46768,
      lng: -101.38914,
      city: 'Leoti',
      state: 'KS',
    },
    67862: {
      lat: 37.47961,
      lng: -101.95409,
      city: 'Manter',
      state: 'KS',
    },
    67863: {
      lat: 38.56533,
      lng: -101.20829,
      city: 'Marienthal',
      state: 'KS',
    },
    67864: {
      lat: 37.17178,
      lng: -100.31807,
      city: 'Meade',
      state: 'KS',
    },
    67865: {
      lat: 37.4368,
      lng: -99.96506,
      city: 'Minneola',
      state: 'KS',
    },
    67867: {
      lat: 37.56812,
      lng: -100.45804,
      city: 'Montezuma',
      state: 'KS',
    },
    67868: {
      lat: 37.82028,
      lng: -100.71029,
      city: 'Pierceville',
      state: 'KS',
    },
    67869: {
      lat: 37.24489,
      lng: -100.57358,
      city: 'Plains',
      state: 'KS',
    },
    67870: {
      lat: 37.53255,
      lng: -101.01829,
      city: 'Satanta',
      state: 'KS',
    },
    67871: {
      lat: 38.49162,
      lng: -100.91145,
      city: 'Scott City',
      state: 'KS',
    },
    67876: {
      lat: 37.86154,
      lng: -99.73654,
      city: 'Spearville',
      state: 'KS',
    },
    67877: {
      lat: 37.54887,
      lng: -100.84342,
      city: 'Sublette',
      state: 'KS',
    },
    67878: {
      lat: 38.00218,
      lng: -101.80824,
      city: 'Syracuse',
      state: 'KS',
    },
    67879: {
      lat: 38.47091,
      lng: -101.79459,
      city: 'Tribune',
      state: 'KS',
    },
    67880: {
      lat: 37.60489,
      lng: -101.32384,
      city: 'Ulysses',
      state: 'KS',
    },
    67882: {
      lat: 37.8019,
      lng: -99.88505,
      city: 'Wright',
      state: 'KS',
    },
    67901: {
      lat: 37.0943,
      lng: -100.89864,
      city: 'Liberal',
      state: 'KS',
    },
    67950: {
      lat: 37.06277,
      lng: -101.86765,
      city: 'Elkhart',
      state: 'KS',
    },
    67951: {
      lat: 37.1639,
      lng: -101.33486,
      city: 'Hugoton',
      state: 'KS',
    },
    67952: {
      lat: 37.29886,
      lng: -101.13934,
      city: 'Moscow',
      state: 'KS',
    },
    67953: {
      lat: 37.22653,
      lng: -101.86093,
      city: 'Richfield',
      state: 'KS',
    },
    67954: {
      lat: 37.17999,
      lng: -101.61179,
      city: 'Rolla',
      state: 'KS',
    },
    68001: { lat: 41.33369, lng: -96.95489, city: 'Abie', state: 'NE' },
    68002: {
      lat: 41.50163,
      lng: -96.33975,
      city: 'Arlington',
      state: 'NE',
    },
    68003: {
      lat: 41.07161,
      lng: -96.38013,
      city: 'Ashland',
      state: 'NE',
    },
    68004: {
      lat: 42.00888,
      lng: -96.62978,
      city: 'Bancroft',
      state: 'NE',
    },
    68005: {
      lat: 41.14161,
      lng: -95.89394,
      city: 'Bellevue',
      state: 'NE',
    },
    68007: {
      lat: 41.37184,
      lng: -96.19524,
      city: 'Bennington',
      state: 'NE',
    },
    68008: {
      lat: 41.54573,
      lng: -96.17421,
      city: 'Blair',
      state: 'NE',
    },
    68010: {
      lat: 41.25616,
      lng: -96.13352,
      city: 'Boys Town',
      state: 'NE',
    },
    68014: {
      lat: 41.28219,
      lng: -96.96458,
      city: 'Bruno',
      state: 'NE',
    },
    68015: {
      lat: 41.38351,
      lng: -96.64737,
      city: 'Cedar Bluffs',
      state: 'NE',
    },
    68016: {
      lat: 41.02518,
      lng: -96.1063,
      city: 'Cedar Creek',
      state: 'NE',
    },
    68017: {
      lat: 41.05938,
      lng: -96.66179,
      city: 'Ceresco',
      state: 'NE',
    },
    68018: {
      lat: 41.30207,
      lng: -96.61573,
      city: 'Colon',
      state: 'NE',
    },
    68019: {
      lat: 41.77965,
      lng: -96.37323,
      city: 'Craig',
      state: 'NE',
    },
    68020: {
      lat: 41.9762,
      lng: -96.25627,
      city: 'Decatur',
      state: 'NE',
    },
    68022: {
      lat: 41.27744,
      lng: -96.24558,
      city: 'Elkhorn',
      state: 'NE',
    },
    68023: {
      lat: 41.46191,
      lng: -96.02226,
      city: 'Fort Calhoun',
      state: 'NE',
    },
    68025: {
      lat: 41.43749,
      lng: -96.49158,
      city: 'Fremont',
      state: 'NE',
    },
    68028: {
      lat: 41.10513,
      lng: -96.26098,
      city: 'Gretna',
      state: 'NE',
    },
    68029: {
      lat: 41.65873,
      lng: -96.27394,
      city: 'Herman',
      state: 'NE',
    },
    68030: { lat: 42.3184, lng: -96.45057, city: 'Homer', state: 'NE' },
    68031: {
      lat: 41.64041,
      lng: -96.53046,
      city: 'Hooper',
      state: 'NE',
    },
    68033: {
      lat: 41.12933,
      lng: -96.52425,
      city: 'Ithaca',
      state: 'NE',
    },
    68034: {
      lat: 41.45558,
      lng: -96.2203,
      city: 'Kennard',
      state: 'NE',
    },
    68036: {
      lat: 41.39031,
      lng: -96.95086,
      city: 'Linwood',
      state: 'NE',
    },
    68037: {
      lat: 40.9844,
      lng: -96.12673,
      city: 'Louisville',
      state: 'NE',
    },
    68038: { lat: 41.95458, lng: -96.4432, city: 'Lyons', state: 'NE' },
    68039: { lat: 42.112, lng: -96.34397, city: 'Macy', state: 'NE' },
    68040: {
      lat: 41.28555,
      lng: -96.73815,
      city: 'Malmo',
      state: 'NE',
    },
    68041: { lat: 41.23633, lng: -96.48793, city: 'Mead', state: 'NE' },
    68042: {
      lat: 41.09426,
      lng: -96.43683,
      city: 'Memphis',
      state: 'NE',
    },
    68044: {
      lat: 41.54798,
      lng: -96.44871,
      city: 'Nickerson',
      state: 'NE',
    },
    68045: {
      lat: 41.8273,
      lng: -96.49523,
      city: 'Oakland',
      state: 'NE',
    },
    68046: {
      lat: 41.12206,
      lng: -96.06138,
      city: 'Papillion',
      state: 'NE',
    },
    68047: {
      lat: 42.10592,
      lng: -96.7325,
      city: 'Pender',
      state: 'NE',
    },
    68048: {
      lat: 40.98304,
      lng: -95.9345,
      city: 'Plattsmouth',
      state: 'NE',
    },
    68050: {
      lat: 41.30593,
      lng: -96.84303,
      city: 'Prague',
      state: 'NE',
    },
    68055: {
      lat: 42.05747,
      lng: -96.47192,
      city: 'Rosalie',
      state: 'NE',
    },
    68056: {
      lat: 41.14123,
      lng: -95.90438,
      city: 'St Columbans',
      state: 'NE',
    },
    68057: {
      lat: 41.65888,
      lng: -96.71893,
      city: 'Scribner',
      state: 'NE',
    },
    68058: {
      lat: 41.00842,
      lng: -96.2483,
      city: 'South Bend',
      state: 'NE',
    },
    68059: {
      lat: 41.06673,
      lng: -96.1595,
      city: 'Springfield',
      state: 'NE',
    },
    68061: {
      lat: 41.80078,
      lng: -96.21165,
      city: 'Tekamah',
      state: 'NE',
    },
    68062: {
      lat: 42.19538,
      lng: -96.6743,
      city: 'Thurston',
      state: 'NE',
    },
    68063: {
      lat: 41.72124,
      lng: -96.49345,
      city: 'Uehling',
      state: 'NE',
    },
    68064: {
      lat: 41.34925,
      lng: -96.35545,
      city: 'Valley',
      state: 'NE',
    },
    68065: {
      lat: 41.0766,
      lng: -96.84854,
      city: 'Valparaiso',
      state: 'NE',
    },
    68066: {
      lat: 41.18451,
      lng: -96.61996,
      city: 'Wahoo',
      state: 'NE',
    },
    68067: {
      lat: 42.1519,
      lng: -96.48603,
      city: 'Walthill',
      state: 'NE',
    },
    68068: {
      lat: 41.39759,
      lng: -96.20741,
      city: 'Washington',
      state: 'NE',
    },
    68069: {
      lat: 41.24166,
      lng: -96.31542,
      city: 'Waterloo',
      state: 'NE',
    },
    68070: {
      lat: 41.1924,
      lng: -96.79973,
      city: 'Weston',
      state: 'NE',
    },
    68071: {
      lat: 42.23605,
      lng: -96.47446,
      city: 'Winnebago',
      state: 'NE',
    },
    68072: {
      lat: 41.61016,
      lng: -96.50473,
      city: 'Winslow',
      state: 'NE',
    },
    68073: {
      lat: 41.23931,
      lng: -96.40931,
      city: 'Yutan',
      state: 'NE',
    },
    68102: {
      lat: 41.26231,
      lng: -95.93224,
      city: 'Omaha',
      state: 'NE',
    },
    68104: {
      lat: 41.29525,
      lng: -96.00104,
      city: 'Omaha',
      state: 'NE',
    },
    68105: { lat: 41.2406, lng: -95.96429, city: 'Omaha', state: 'NE' },
    68106: {
      lat: 41.23885,
      lng: -96.00244,
      city: 'Omaha',
      state: 'NE',
    },
    68107: {
      lat: 41.20888,
      lng: -95.95126,
      city: 'Omaha',
      state: 'NE',
    },
    68108: {
      lat: 41.23753,
      lng: -95.93118,
      city: 'Omaha',
      state: 'NE',
    },
    68110: {
      lat: 41.29867,
      lng: -95.91143,
      city: 'Omaha',
      state: 'NE',
    },
    68111: {
      lat: 41.29564,
      lng: -95.96395,
      city: 'Omaha',
      state: 'NE',
    },
    68112: {
      lat: 41.38183,
      lng: -95.95804,
      city: 'Omaha',
      state: 'NE',
    },
    68113: {
      lat: 41.11745,
      lng: -95.90889,
      city: 'Offutt Afb',
      state: 'NE',
    },
    68114: {
      lat: 41.26318,
      lng: -96.05208,
      city: 'Omaha',
      state: 'NE',
    },
    68116: {
      lat: 41.29842,
      lng: -96.16629,
      city: 'Omaha',
      state: 'NE',
    },
    68117: { lat: 41.2062, lng: -96.00138, city: 'Omaha', state: 'NE' },
    68118: { lat: 41.263, lng: -96.1772, city: 'Omaha', state: 'NE' },
    68122: {
      lat: 41.36451,
      lng: -96.05036,
      city: 'Omaha',
      state: 'NE',
    },
    68123: {
      lat: 41.10108,
      lng: -95.94774,
      city: 'Bellevue',
      state: 'NE',
    },
    68124: { lat: 41.23515, lng: -96.0522, city: 'Omaha', state: 'NE' },
    68127: { lat: 41.2061, lng: -96.05245, city: 'Omaha', state: 'NE' },
    68128: {
      lat: 41.17972,
      lng: -96.07081,
      city: 'La Vista',
      state: 'NE',
    },
    68130: {
      lat: 41.23394,
      lng: -96.19613,
      city: 'Omaha',
      state: 'NE',
    },
    68131: {
      lat: 41.26435,
      lng: -95.96479,
      city: 'Omaha',
      state: 'NE',
    },
    68132: {
      lat: 41.26506,
      lng: -96.00153,
      city: 'Omaha',
      state: 'NE',
    },
    68133: {
      lat: 41.11754,
      lng: -96.00292,
      city: 'Papillion',
      state: 'NE',
    },
    68134: { lat: 41.29776, lng: -96.0503, city: 'Omaha', state: 'NE' },
    68135: { lat: 41.2052, lng: -96.19576, city: 'Omaha', state: 'NE' },
    68136: {
      lat: 41.16932,
      lng: -96.18783,
      city: 'Omaha',
      state: 'NE',
    },
    68137: {
      lat: 41.20595,
      lng: -96.11924,
      city: 'Omaha',
      state: 'NE',
    },
    68138: {
      lat: 41.15661,
      lng: -96.13891,
      city: 'Omaha',
      state: 'NE',
    },
    68142: {
      lat: 41.37012,
      lng: -96.10276,
      city: 'Omaha',
      state: 'NE',
    },
    68144: {
      lat: 41.23473,
      lng: -96.11909,
      city: 'Omaha',
      state: 'NE',
    },
    68147: {
      lat: 41.17655,
      lng: -95.95678,
      city: 'Bellevue',
      state: 'NE',
    },
    68152: {
      lat: 41.36282,
      lng: -95.99995,
      city: 'Omaha',
      state: 'NE',
    },
    68154: {
      lat: 41.26408,
      lng: -96.11665,
      city: 'Omaha',
      state: 'NE',
    },
    68157: {
      lat: 41.17902,
      lng: -95.99536,
      city: 'Omaha',
      state: 'NE',
    },
    68164: {
      lat: 41.29886,
      lng: -96.10949,
      city: 'Omaha',
      state: 'NE',
    },
    68178: {
      lat: 41.26535,
      lng: -95.94535,
      city: 'Omaha',
      state: 'NE',
    },
    68182: {
      lat: 41.25775,
      lng: -96.01072,
      city: 'Omaha',
      state: 'NE',
    },
    68183: {
      lat: 41.25709,
      lng: -95.93977,
      city: 'Omaha',
      state: 'NE',
    },
    68301: {
      lat: 40.46847,
      lng: -96.52244,
      city: 'Adams',
      state: 'NE',
    },
    68303: {
      lat: 40.25753,
      lng: -97.41033,
      city: 'Alexandria',
      state: 'NE',
    },
    68304: { lat: 40.88971, lng: -96.38781, city: 'Alvo', state: 'NE' },
    68305: {
      lat: 40.36392,
      lng: -95.85788,
      city: 'Auburn',
      state: 'NE',
    },
    68307: {
      lat: 40.78769,
      lng: -96.14054,
      city: 'Avoca',
      state: 'NE',
    },
    68309: {
      lat: 40.04907,
      lng: -96.57285,
      city: 'Barneston',
      state: 'NE',
    },
    68310: {
      lat: 40.24957,
      lng: -96.74943,
      city: 'Beatrice',
      state: 'NE',
    },
    68313: {
      lat: 40.78142,
      lng: -97.27193,
      city: 'Beaver Crossing',
      state: 'NE',
    },
    68314: { lat: 41.01643, lng: -97.0248, city: 'Bee', state: 'NE' },
    68315: {
      lat: 40.24605,
      lng: -97.53841,
      city: 'Belvidere',
      state: 'NE',
    },
    68316: {
      lat: 41.01688,
      lng: -97.61577,
      city: 'Benedict',
      state: 'NE',
    },
    68317: {
      lat: 40.67325,
      lng: -96.50451,
      city: 'Bennet',
      state: 'NE',
    },
    68318: {
      lat: 40.15598,
      lng: -96.64439,
      city: 'Blue Springs',
      state: 'NE',
    },
    68319: {
      lat: 40.93656,
      lng: -97.75817,
      city: 'Bradshaw',
      state: 'NE',
    },
    68320: { lat: 40.48148, lng: -95.9533, city: 'Brock', state: 'NE' },
    68321: {
      lat: 40.39491,
      lng: -95.69371,
      city: 'Brownville',
      state: 'NE',
    },
    68322: {
      lat: 40.3346,
      lng: -97.55356,
      city: 'Bruning',
      state: 'NE',
    },
    68323: {
      lat: 40.13197,
      lng: -96.37034,
      city: 'Burchard',
      state: 'NE',
    },
    68324: { lat: 40.55407, lng: -96.29014, city: 'Burr', state: 'NE' },
    68325: { lat: 40.0131, lng: -97.75156, city: 'Byron', state: 'NE' },
    68326: {
      lat: 40.28569,
      lng: -97.68243,
      city: 'Carleton',
      state: 'NE',
    },
    68327: {
      lat: 40.01079,
      lng: -97.62113,
      city: 'Chester',
      state: 'NE',
    },
    68328: {
      lat: 40.47694,
      lng: -96.8384,
      city: 'Clatonia',
      state: 'NE',
    },
    68329: { lat: 40.50089, lng: -96.16772, city: 'Cook', state: 'NE' },
    68330: {
      lat: 40.70651,
      lng: -97.34041,
      city: 'Cordova',
      state: 'NE',
    },
    68331: {
      lat: 40.4712,
      lng: -96.70863,
      city: 'Cortland',
      state: 'NE',
    },
    68332: {
      lat: 40.32829,
      lng: -96.41123,
      city: 'Crab Orchard',
      state: 'NE',
    },
    68333: {
      lat: 40.62126,
      lng: -96.94901,
      city: 'Crete',
      state: 'NE',
    },
    68335: {
      lat: 40.28243,
      lng: -97.803,
      city: 'Davenport',
      state: 'NE',
    },
    68336: { lat: 40.9615, lng: -96.68405, city: 'Davey', state: 'NE' },
    68337: {
      lat: 40.09504,
      lng: -95.83418,
      city: 'Dawson',
      state: 'NE',
    },
    68338: {
      lat: 40.31865,
      lng: -97.24863,
      city: 'Daykin',
      state: 'NE',
    },
    68339: {
      lat: 40.73214,
      lng: -96.86696,
      city: 'Denton',
      state: 'NE',
    },
    68340: {
      lat: 40.12525,
      lng: -97.74126,
      city: 'Deshler',
      state: 'NE',
    },
    68341: {
      lat: 40.3855,
      lng: -96.91378,
      city: 'De Witt',
      state: 'NE',
    },
    68342: {
      lat: 40.1034,
      lng: -96.94496,
      city: 'Diller',
      state: 'NE',
    },
    68343: {
      lat: 40.61989,
      lng: -97.13194,
      city: 'Dorchester',
      state: 'NE',
    },
    68344: {
      lat: 40.57159,
      lng: -96.39544,
      city: 'Douglas',
      state: 'NE',
    },
    68345: {
      lat: 40.03692,
      lng: -96.01928,
      city: 'Du Bois',
      state: 'NE',
    },
    68346: {
      lat: 40.66195,
      lng: -96.03066,
      city: 'Dunbar',
      state: 'NE',
    },
    68347: {
      lat: 40.80347,
      lng: -96.42274,
      city: 'Eagle',
      state: 'NE',
    },
    68348: {
      lat: 40.29569,
      lng: -96.12208,
      city: 'Elk Creek',
      state: 'NE',
    },
    68349: {
      lat: 40.82962,
      lng: -96.30028,
      city: 'Elmwood',
      state: 'NE',
    },
    68350: {
      lat: 40.03961,
      lng: -97.09166,
      city: 'Endicott',
      state: 'NE',
    },
    68351: {
      lat: 40.65393,
      lng: -97.43015,
      city: 'Exeter',
      state: 'NE',
    },
    68352: {
      lat: 40.15255,
      lng: -97.21931,
      city: 'Fairbury',
      state: 'NE',
    },
    68354: {
      lat: 40.63935,
      lng: -97.58032,
      city: 'Fairmont',
      state: 'NE',
    },
    68355: {
      lat: 40.11677,
      lng: -95.56702,
      city: 'Falls City',
      state: 'NE',
    },
    68357: {
      lat: 40.31541,
      lng: -96.54658,
      city: 'Filley',
      state: 'NE',
    },
    68358: {
      lat: 40.53321,
      lng: -96.60973,
      city: 'Firth',
      state: 'NE',
    },
    68359: {
      lat: 40.63059,
      lng: -97.28333,
      city: 'Friend',
      state: 'NE',
    },
    68360: {
      lat: 40.94613,
      lng: -96.95661,
      city: 'Garland',
      state: 'NE',
    },
    68361: {
      lat: 40.50756,
      lng: -97.60146,
      city: 'Geneva',
      state: 'NE',
    },
    68362: {
      lat: 40.15323,
      lng: -97.42553,
      city: 'Gilead',
      state: 'NE',
    },
    68364: {
      lat: 40.83656,
      lng: -97.22563,
      city: 'Goehner',
      state: 'NE',
    },
    68365: {
      lat: 40.61565,
      lng: -97.73065,
      city: 'Grafton',
      state: 'NE',
    },
    68366: {
      lat: 40.98215,
      lng: -96.44222,
      city: 'Greenwood',
      state: 'NE',
    },
    68367: {
      lat: 41.03807,
      lng: -97.40128,
      city: 'Gresham',
      state: 'NE',
    },
    68368: {
      lat: 40.55598,
      lng: -96.81665,
      city: 'Hallam',
      state: 'NE',
    },
    68370: {
      lat: 40.14148,
      lng: -97.55567,
      city: 'Hebron',
      state: 'NE',
    },
    68371: {
      lat: 40.77304,
      lng: -97.78417,
      city: 'Henderson',
      state: 'NE',
    },
    68372: {
      lat: 40.60498,
      lng: -96.60539,
      city: 'Hickman',
      state: 'NE',
    },
    68375: {
      lat: 40.03939,
      lng: -97.48826,
      city: 'Hubbell',
      state: 'NE',
    },
    68376: {
      lat: 40.171,
      lng: -95.93809,
      city: 'Humboldt',
      state: 'NE',
    },
    68377: { lat: 40.217, lng: -97.02303, city: 'Jansen', state: 'NE' },
    68378: {
      lat: 40.39355,
      lng: -96.02615,
      city: 'Johnson',
      state: 'NE',
    },
    68379: {
      lat: 40.52908,
      lng: -95.86979,
      city: 'Julian',
      state: 'NE',
    },
    68380: {
      lat: 40.24283,
      lng: -96.4059,
      city: 'Lewiston',
      state: 'NE',
    },
    68381: {
      lat: 40.07979,
      lng: -96.48176,
      city: 'Liberty',
      state: 'NE',
    },
    68382: {
      lat: 40.59724,
      lng: -96.02425,
      city: 'Lorton',
      state: 'NE',
    },
    68401: {
      lat: 40.74233,
      lng: -97.58569,
      city: 'McCool Junction',
      state: 'NE',
    },
    68402: {
      lat: 40.90829,
      lng: -96.8571,
      city: 'Malcolm',
      state: 'NE',
    },
    68403: {
      lat: 40.92045,
      lng: -96.16707,
      city: 'Manley',
      state: 'NE',
    },
    68404: {
      lat: 40.619,
      lng: -96.75566,
      city: 'Martell',
      state: 'NE',
    },
    68405: {
      lat: 40.76522,
      lng: -97.07315,
      city: 'Milford',
      state: 'NE',
    },
    68406: {
      lat: 40.50566,
      lng: -97.37874,
      city: 'Milligan',
      state: 'NE',
    },
    68407: {
      lat: 40.921,
      lng: -96.26263,
      city: 'Murdock',
      state: 'NE',
    },
    68409: {
      lat: 40.90906,
      lng: -95.95074,
      city: 'Murray',
      state: 'NE',
    },
    68410: {
      lat: 40.63729,
      lng: -95.88584,
      city: 'Nebraska City',
      state: 'NE',
    },
    68413: {
      lat: 40.83775,
      lng: -96.01053,
      city: 'Nehawka',
      state: 'NE',
    },
    68414: {
      lat: 40.31966,
      lng: -95.6991,
      city: 'Nemaha',
      state: 'NE',
    },
    68415: {
      lat: 40.06249,
      lng: -96.82157,
      city: 'Odell',
      state: 'NE',
    },
    68416: {
      lat: 40.41093,
      lng: -97.46185,
      city: 'Ohiowa',
      state: 'NE',
    },
    68417: { lat: 40.7359, lng: -96.09199, city: 'Otoe', state: 'NE' },
    68418: {
      lat: 40.69568,
      lng: -96.38435,
      city: 'Palmyra',
      state: 'NE',
    },
    68419: {
      lat: 40.60297,
      lng: -96.51129,
      city: 'Panama',
      state: 'NE',
    },
    68420: {
      lat: 40.08556,
      lng: -96.19125,
      city: 'Pawnee City',
      state: 'NE',
    },
    68421: { lat: 40.492, lng: -95.74426, city: 'Peru', state: 'NE' },
    68422: {
      lat: 40.38863,
      lng: -96.69755,
      city: 'Pickrell',
      state: 'NE',
    },
    68423: {
      lat: 40.80112,
      lng: -96.95117,
      city: 'Pleasant Dale',
      state: 'NE',
    },
    68424: {
      lat: 40.30111,
      lng: -97.01818,
      city: 'Plymouth',
      state: 'NE',
    },
    68428: {
      lat: 40.97244,
      lng: -96.79624,
      city: 'Raymond',
      state: 'NE',
    },
    68429: {
      lat: 40.06406,
      lng: -97.37437,
      city: 'Reynolds',
      state: 'NE',
    },
    68430: { lat: 40.66599, lng: -96.66012, city: 'Roca', state: 'NE' },
    68431: { lat: 40.0677, lng: -95.43402, city: 'Rulo', state: 'NE' },
    68433: {
      lat: 40.05251,
      lng: -95.73969,
      city: 'Salem',
      state: 'NE',
    },
    68434: {
      lat: 40.90947,
      lng: -97.11675,
      city: 'Seward',
      state: 'NE',
    },
    68436: {
      lat: 40.43357,
      lng: -97.74427,
      city: 'Shickley',
      state: 'NE',
    },
    68437: {
      lat: 40.2513,
      lng: -95.63534,
      city: 'Shubert',
      state: 'NE',
    },
    68438: {
      lat: 40.62656,
      lng: -96.74463,
      city: 'Sprague',
      state: 'NE',
    },
    68439: {
      lat: 40.99937,
      lng: -97.21136,
      city: 'Staplehurst',
      state: 'NE',
    },
    68440: {
      lat: 40.05266,
      lng: -97.01168,
      city: 'Steele City',
      state: 'NE',
    },
    68441: {
      lat: 40.22581,
      lng: -96.25461,
      city: 'Steinauer',
      state: 'NE',
    },
    68442: { lat: 40.239, lng: -95.7729, city: 'Stella', state: 'NE' },
    68443: {
      lat: 40.45627,
      lng: -96.36758,
      city: 'Sterling',
      state: 'NE',
    },
    68444: {
      lat: 40.41117,
      lng: -97.5847,
      city: 'Strang',
      state: 'NE',
    },
    68445: {
      lat: 40.40146,
      lng: -97.08662,
      city: 'Swanton',
      state: 'NE',
    },
    68446: {
      lat: 40.65002,
      lng: -96.17535,
      city: 'Syracuse',
      state: 'NE',
    },
    68447: {
      lat: 40.20044,
      lng: -96.07366,
      city: 'Table Rock',
      state: 'NE',
    },
    68448: {
      lat: 40.54429,
      lng: -96.035,
      city: 'Talmage',
      state: 'NE',
    },
    68450: {
      lat: 40.36197,
      lng: -96.22383,
      city: 'Tecumseh',
      state: 'NE',
    },
    68452: { lat: 40.39755, lng: -97.85036, city: 'Ong', state: 'NE' },
    68453: {
      lat: 40.40774,
      lng: -97.33455,
      city: 'Tobias',
      state: 'NE',
    },
    68454: {
      lat: 40.69275,
      lng: -96.28686,
      city: 'Unadilla',
      state: 'NE',
    },
    68455: {
      lat: 40.82286,
      lng: -95.90123,
      city: 'Union',
      state: 'NE',
    },
    68456: {
      lat: 40.91278,
      lng: -97.31007,
      city: 'Utica',
      state: 'NE',
    },
    68457: {
      lat: 40.15618,
      lng: -95.71095,
      city: 'Verdon',
      state: 'NE',
    },
    68458: {
      lat: 40.23107,
      lng: -96.48742,
      city: 'Virginia',
      state: 'NE',
    },
    68460: { lat: 40.89596, lng: -97.43169, city: 'Waco', state: 'NE' },
    68461: {
      lat: 40.76682,
      lng: -96.51419,
      city: 'Walton',
      state: 'NE',
    },
    68462: {
      lat: 40.94804,
      lng: -96.52806,
      city: 'Waverly',
      state: 'NE',
    },
    68463: {
      lat: 40.8759,
      lng: -96.13518,
      city: 'Weeping Water',
      state: 'NE',
    },
    68464: {
      lat: 40.42232,
      lng: -97.1955,
      city: 'Western',
      state: 'NE',
    },
    68465: {
      lat: 40.49248,
      lng: -97.01987,
      city: 'Wilber',
      state: 'NE',
    },
    68466: {
      lat: 40.07109,
      lng: -96.63773,
      city: 'Wymore',
      state: 'NE',
    },
    68467: { lat: 40.86528, lng: -97.59778, city: 'York', state: 'NE' },
    68502: {
      lat: 40.78361,
      lng: -96.70083,
      city: 'Lincoln',
      state: 'NE',
    },
    68503: {
      lat: 40.82447,
      lng: -96.67387,
      city: 'Lincoln',
      state: 'NE',
    },
    68504: {
      lat: 40.85451,
      lng: -96.66104,
      city: 'Lincoln',
      state: 'NE',
    },
    68505: {
      lat: 40.82446,
      lng: -96.61562,
      city: 'Lincoln',
      state: 'NE',
    },
    68506: {
      lat: 40.78422,
      lng: -96.63959,
      city: 'Lincoln',
      state: 'NE',
    },
    68507: {
      lat: 40.85954,
      lng: -96.61172,
      city: 'Lincoln',
      state: 'NE',
    },
    68508: {
      lat: 40.81831,
      lng: -96.70621,
      city: 'Lincoln',
      state: 'NE',
    },
    68510: {
      lat: 40.80654,
      lng: -96.64835,
      city: 'Lincoln',
      state: 'NE',
    },
    68512: {
      lat: 40.73746,
      lng: -96.70567,
      city: 'Lincoln',
      state: 'NE',
    },
    68514: {
      lat: 40.93408,
      lng: -96.65076,
      city: 'Lincoln',
      state: 'NE',
    },
    68516: {
      lat: 40.73493,
      lng: -96.6428,
      city: 'Lincoln',
      state: 'NE',
    },
    68517: {
      lat: 40.93341,
      lng: -96.60487,
      city: 'Lincoln',
      state: 'NE',
    },
    68520: {
      lat: 40.79827,
      lng: -96.55313,
      city: 'Lincoln',
      state: 'NE',
    },
    68521: {
      lat: 40.8618,
      lng: -96.71519,
      city: 'Lincoln',
      state: 'NE',
    },
    68522: {
      lat: 40.78941,
      lng: -96.76214,
      city: 'Lincoln',
      state: 'NE',
    },
    68523: {
      lat: 40.73555,
      lng: -96.75708,
      city: 'Lincoln',
      state: 'NE',
    },
    68524: {
      lat: 40.87145,
      lng: -96.80264,
      city: 'Lincoln',
      state: 'NE',
    },
    68526: {
      lat: 40.7324,
      lng: -96.58269,
      city: 'Lincoln',
      state: 'NE',
    },
    68527: {
      lat: 40.84573,
      lng: -96.51753,
      city: 'Lincoln',
      state: 'NE',
    },
    68528: {
      lat: 40.82244,
      lng: -96.81762,
      city: 'Lincoln',
      state: 'NE',
    },
    68531: {
      lat: 40.90211,
      lng: -96.71415,
      city: 'Lincoln',
      state: 'NE',
    },
    68532: {
      lat: 40.79544,
      lng: -96.84332,
      city: 'Lincoln',
      state: 'NE',
    },
    68588: {
      lat: 40.82482,
      lng: -96.69644,
      city: 'Lincoln',
      state: 'NE',
    },
    68601: {
      lat: 41.45205,
      lng: -97.39477,
      city: 'Columbus',
      state: 'NE',
    },
    68620: {
      lat: 41.72452,
      lng: -98.05814,
      city: 'Albion',
      state: 'NE',
    },
    68621: { lat: 41.48963, lng: -96.63911, city: 'Ames', state: 'NE' },
    68622: {
      lat: 41.92209,
      lng: -98.53311,
      city: 'Bartlett',
      state: 'NE',
    },
    68623: {
      lat: 41.44327,
      lng: -98.11602,
      city: 'Belgrade',
      state: 'NE',
    },
    68624: {
      lat: 41.34974,
      lng: -97.22858,
      city: 'Bellwood',
      state: 'NE',
    },
    68626: {
      lat: 41.16821,
      lng: -96.9921,
      city: 'Brainard',
      state: 'NE',
    },
    68627: {
      lat: 41.54445,
      lng: -98.17041,
      city: 'Cedar Rapids',
      state: 'NE',
    },
    68628: {
      lat: 41.21694,
      lng: -97.84355,
      city: 'Clarks',
      state: 'NE',
    },
    68629: {
      lat: 41.71336,
      lng: -97.12936,
      city: 'Clarkson',
      state: 'NE',
    },
    68631: {
      lat: 41.68753,
      lng: -97.35074,
      city: 'Creston',
      state: 'NE',
    },
    68632: {
      lat: 41.24266,
      lng: -97.13288,
      city: 'David City',
      state: 'NE',
    },
    68633: {
      lat: 41.70585,
      lng: -96.89668,
      city: 'Dodge',
      state: 'NE',
    },
    68634: {
      lat: 41.39073,
      lng: -97.50659,
      city: 'Duncan',
      state: 'NE',
    },
    68635: {
      lat: 41.08544,
      lng: -97.02298,
      city: 'Dwight',
      state: 'NE',
    },
    68636: {
      lat: 42.00494,
      lng: -98.15674,
      city: 'Elgin',
      state: 'NE',
    },
    68637: {
      lat: 41.78778,
      lng: -98.68997,
      city: 'Ericson',
      state: 'NE',
    },
    68638: {
      lat: 41.36848,
      lng: -97.98977,
      city: 'Fullerton',
      state: 'NE',
    },
    68640: {
      lat: 41.46111,
      lng: -97.77237,
      city: 'Genoa',
      state: 'NE',
    },
    68641: {
      lat: 41.73452,
      lng: -97.00829,
      city: 'Howells',
      state: 'NE',
    },
    68642: {
      lat: 41.68717,
      lng: -97.51232,
      city: 'Humphrey',
      state: 'NE',
    },
    68643: {
      lat: 41.70231,
      lng: -97.25918,
      city: 'Leigh',
      state: 'NE',
    },
    68644: {
      lat: 41.7079,
      lng: -97.67579,
      city: 'Lindsay',
      state: 'NE',
    },
    68647: {
      lat: 41.51971,
      lng: -97.62021,
      city: 'Monroe',
      state: 'NE',
    },
    68648: {
      lat: 41.40482,
      lng: -96.78949,
      city: 'Morse Bluff',
      state: 'NE',
    },
    68649: {
      lat: 41.50784,
      lng: -96.78896,
      city: 'North Bend',
      state: 'NE',
    },
    68651: {
      lat: 41.22443,
      lng: -97.57472,
      city: 'Osceola',
      state: 'NE',
    },
    68652: {
      lat: 41.86174,
      lng: -98.08906,
      city: 'Petersburg',
      state: 'NE',
    },
    68653: {
      lat: 41.55551,
      lng: -97.4888,
      city: 'Platte Center',
      state: 'NE',
    },
    68654: { lat: 41.06791, lng: -97.7772, city: 'Polk', state: 'NE' },
    68655: {
      lat: 41.64881,
      lng: -98.26222,
      city: 'Primrose',
      state: 'NE',
    },
    68658: {
      lat: 41.19971,
      lng: -97.2937,
      city: 'Rising City',
      state: 'NE',
    },
    68659: {
      lat: 41.54388,
      lng: -96.94438,
      city: 'Rogers',
      state: 'NE',
    },
    68660: {
      lat: 41.57398,
      lng: -97.86792,
      city: 'Saint Edward',
      state: 'NE',
    },
    68661: {
      lat: 41.49559,
      lng: -97.08347,
      city: 'Schuyler',
      state: 'NE',
    },
    68662: {
      lat: 41.20698,
      lng: -97.41535,
      city: 'Shelby',
      state: 'NE',
    },
    68663: {
      lat: 41.31193,
      lng: -97.69927,
      city: 'Silver Creek',
      state: 'NE',
    },
    68664: {
      lat: 41.70453,
      lng: -96.78694,
      city: 'Snyder',
      state: 'NE',
    },
    68665: {
      lat: 41.70543,
      lng: -98.41445,
      city: 'Spalding',
      state: 'NE',
    },
    68666: {
      lat: 41.10775,
      lng: -97.61369,
      city: 'Stromsburg',
      state: 'NE',
    },
    68667: {
      lat: 41.11231,
      lng: -97.30278,
      city: 'Surprise',
      state: 'NE',
    },
    68669: {
      lat: 41.07789,
      lng: -97.23612,
      city: 'Ulysses',
      state: 'NE',
    },
    68701: {
      lat: 42.02024,
      lng: -97.44099,
      city: 'Norfolk',
      state: 'NE',
    },
    68710: { lat: 42.439, lng: -96.84784, city: 'Allen', state: 'NE' },
    68711: {
      lat: 42.2034,
      lng: -99.02407,
      city: 'Amelia',
      state: 'NE',
    },
    68713: {
      lat: 42.54061,
      lng: -98.9718,
      city: 'Atkinson',
      state: 'NE',
    },
    68714: {
      lat: 42.36957,
      lng: -99.50258,
      city: 'Bassett',
      state: 'NE',
    },
    68715: {
      lat: 41.97003,
      lng: -97.60883,
      city: 'Battle Creek',
      state: 'NE',
    },
    68716: {
      lat: 41.9314,
      lng: -96.82337,
      city: 'Beemer',
      state: 'NE',
    },
    68717: {
      lat: 42.38962,
      lng: -97.2192,
      city: 'Belden',
      state: 'NE',
    },
    68718: {
      lat: 42.6619,
      lng: -97.69844,
      city: 'Bloomfield',
      state: 'NE',
    },
    68719: {
      lat: 42.96446,
      lng: -98.57426,
      city: 'Bristow',
      state: 'NE',
    },
    68720: {
      lat: 42.34659,
      lng: -98.01418,
      city: 'Brunswick',
      state: 'NE',
    },
    68722: {
      lat: 42.92074,
      lng: -98.87123,
      city: 'Butte',
      state: 'NE',
    },
    68723: {
      lat: 42.28759,
      lng: -97.21456,
      city: 'Carroll',
      state: 'NE',
    },
    68724: {
      lat: 42.6276,
      lng: -97.88496,
      city: 'Center',
      state: 'NE',
    },
    68725: {
      lat: 42.20118,
      lng: -98.77703,
      city: 'Chambers',
      state: 'NE',
    },
    68726: {
      lat: 42.17877,
      lng: -98.19954,
      city: 'Clearwater',
      state: 'NE',
    },
    68727: {
      lat: 42.5116,
      lng: -97.20596,
      city: 'Coleridge',
      state: 'NE',
    },
    68728: {
      lat: 42.38085,
      lng: -96.93641,
      city: 'Concord',
      state: 'NE',
    },
    68729: {
      lat: 42.47479,
      lng: -97.90784,
      city: 'Creighton',
      state: 'NE',
    },
    68730: {
      lat: 42.76688,
      lng: -97.53488,
      city: 'Crofton',
      state: 'NE',
    },
    68731: {
      lat: 42.40993,
      lng: -96.48341,
      city: 'Dakota City',
      state: 'NE',
    },
    68732: {
      lat: 42.48176,
      lng: -96.97387,
      city: 'Dixon',
      state: 'NE',
    },
    68733: {
      lat: 42.28278,
      lng: -96.72245,
      city: 'Emerson',
      state: 'NE',
    },
    68734: { lat: 42.42528, lng: -98.8044, city: 'Emmet', state: 'NE' },
    68735: {
      lat: 42.14911,
      lng: -98.45402,
      city: 'Ewing',
      state: 'NE',
    },
    68736: {
      lat: 42.74481,
      lng: -97.37136,
      city: 'Fordyce',
      state: 'NE',
    },
    68739: {
      lat: 42.6284,
      lng: -97.26265,
      city: 'Hartington',
      state: 'NE',
    },
    68740: {
      lat: 42.14561,
      lng: -97.29245,
      city: 'Hoskins',
      state: 'NE',
    },
    68741: {
      lat: 42.35711,
      lng: -96.59375,
      city: 'Hubbard',
      state: 'NE',
    },
    68742: { lat: 42.3572, lng: -98.51459, city: 'Inman', state: 'NE' },
    68743: {
      lat: 42.47271,
      lng: -96.62586,
      city: 'Jackson',
      state: 'NE',
    },
    68745: {
      lat: 42.44662,
      lng: -97.08781,
      city: 'Laurel',
      state: 'NE',
    },
    68746: { lat: 42.80726, lng: -98.4354, city: 'Lynch', state: 'NE' },
    68747: {
      lat: 42.39398,
      lng: -97.48873,
      city: 'Mclean',
      state: 'NE',
    },
    68748: {
      lat: 41.83883,
      lng: -97.47638,
      city: 'Madison',
      state: 'NE',
    },
    68749: {
      lat: 42.46032,
      lng: -97.47531,
      city: 'Magnet',
      state: 'NE',
    },
    68751: {
      lat: 42.69035,
      lng: -96.98138,
      city: 'Maskell',
      state: 'NE',
    },
    68752: {
      lat: 42.02269,
      lng: -97.7154,
      city: 'Meadow Grove',
      state: 'NE',
    },
    68753: {
      lat: 42.84464,
      lng: -99.45588,
      city: 'Mills',
      state: 'NE',
    },
    68755: {
      lat: 42.92329,
      lng: -99.12963,
      city: 'Naper',
      state: 'NE',
    },
    68756: {
      lat: 42.18544,
      lng: -97.97985,
      city: 'Neligh',
      state: 'NE',
    },
    68757: {
      lat: 42.65247,
      lng: -96.92554,
      city: 'Newcastle',
      state: 'NE',
    },
    68758: {
      lat: 41.7829,
      lng: -97.78863,
      city: 'Newman Grove',
      state: 'NE',
    },
    68759: {
      lat: 42.75096,
      lng: -99.33762,
      city: 'Newport',
      state: 'NE',
    },
    68760: {
      lat: 42.76616,
      lng: -98.03831,
      city: 'Niobrara',
      state: 'NE',
    },
    68761: {
      lat: 42.02287,
      lng: -97.9601,
      city: 'Oakdale',
      state: 'NE',
    },
    68763: {
      lat: 42.51809,
      lng: -98.58254,
      city: 'Oneill',
      state: 'NE',
    },
    68764: {
      lat: 42.39881,
      lng: -98.23177,
      city: 'Orchard',
      state: 'NE',
    },
    68765: {
      lat: 42.33397,
      lng: -97.6232,
      city: 'Osmond',
      state: 'NE',
    },
    68766: { lat: 42.42375, lng: -98.40321, city: 'Page', state: 'NE' },
    68767: {
      lat: 42.1969,
      lng: -97.53622,
      city: 'Pierce',
      state: 'NE',
    },
    68768: {
      lat: 41.99871,
      lng: -97.06871,
      city: 'Pilger',
      state: 'NE',
    },
    68769: {
      lat: 42.3158,
      lng: -97.81636,
      city: 'Plainview',
      state: 'NE',
    },
    68770: {
      lat: 42.56287,
      lng: -96.76898,
      city: 'Ponca',
      state: 'NE',
    },
    68771: {
      lat: 42.38419,
      lng: -97.35706,
      city: 'Randolph',
      state: 'NE',
    },
    68773: { lat: 42.36245, lng: -98.1199, city: 'Royal', state: 'NE' },
    68774: {
      lat: 42.81403,
      lng: -97.27747,
      city: 'Saint Helena',
      state: 'NE',
    },
    68776: {
      lat: 42.4601,
      lng: -96.44424,
      city: 'South Sioux City',
      state: 'NE',
    },
    68777: {
      lat: 42.86088,
      lng: -98.68504,
      city: 'Spencer',
      state: 'NE',
    },
    68778: {
      lat: 42.86963,
      lng: -99.79483,
      city: 'Springview',
      state: 'NE',
    },
    68779: {
      lat: 41.9516,
      lng: -97.21175,
      city: 'Stanton',
      state: 'NE',
    },
    68780: {
      lat: 42.64621,
      lng: -99.14062,
      city: 'Stuart',
      state: 'NE',
    },
    68781: {
      lat: 42.02743,
      lng: -97.84561,
      city: 'Tilden',
      state: 'NE',
    },
    68783: {
      lat: 42.62523,
      lng: -98.14612,
      city: 'Verdigre',
      state: 'NE',
    },
    68784: {
      lat: 42.25763,
      lng: -96.88657,
      city: 'Wakefield',
      state: 'NE',
    },
    68785: {
      lat: 42.42796,
      lng: -96.7224,
      city: 'Waterbury',
      state: 'NE',
    },
    68786: {
      lat: 42.50351,
      lng: -97.55855,
      city: 'Wausa',
      state: 'NE',
    },
    68787: {
      lat: 42.21372,
      lng: -97.03351,
      city: 'Wayne',
      state: 'NE',
    },
    68788: {
      lat: 41.8387,
      lng: -96.72571,
      city: 'West Point',
      state: 'NE',
    },
    68789: {
      lat: 42.53642,
      lng: -97.99182,
      city: 'Winnetoon',
      state: 'NE',
    },
    68790: {
      lat: 42.17384,
      lng: -97.18616,
      city: 'Winside',
      state: 'NE',
    },
    68791: {
      lat: 41.99103,
      lng: -96.93245,
      city: 'Wisner',
      state: 'NE',
    },
    68792: {
      lat: 42.73323,
      lng: -97.11427,
      city: 'Wynot',
      state: 'NE',
    },
    68801: {
      lat: 40.94949,
      lng: -98.29295,
      city: 'Grand Island',
      state: 'NE',
    },
    68803: {
      lat: 40.95357,
      lng: -98.41593,
      city: 'Grand Island',
      state: 'NE',
    },
    68810: { lat: 40.86238, lng: -98.4675, city: 'Alda', state: 'NE' },
    68812: { lat: 40.9051, lng: -99.279, city: 'Amherst', state: 'NE' },
    68813: {
      lat: 41.70477,
      lng: -99.8355,
      city: 'Anselmo',
      state: 'NE',
    },
    68814: { lat: 41.3086, lng: -99.4102, city: 'Ansley', state: 'NE' },
    68815: {
      lat: 41.42941,
      lng: -99.16045,
      city: 'Arcadia',
      state: 'NE',
    },
    68816: {
      lat: 41.17484,
      lng: -98.13373,
      city: 'Archer',
      state: 'NE',
    },
    68817: {
      lat: 41.26125,
      lng: -98.7937,
      city: 'Ashton',
      state: 'NE',
    },
    68818: {
      lat: 40.82211,
      lng: -98.01036,
      city: 'Aurora',
      state: 'NE',
    },
    68820: {
      lat: 41.1066,
      lng: -98.70056,
      city: 'Boelus',
      state: 'NE',
    },
    68821: {
      lat: 42.00177,
      lng: -99.81628,
      city: 'Brewster',
      state: 'NE',
    },
    68822: {
      lat: 41.38899,
      lng: -99.62766,
      city: 'Broken Bow',
      state: 'NE',
    },
    68823: {
      lat: 41.93653,
      lng: -99.13255,
      city: 'Burwell',
      state: 'NE',
    },
    68824: {
      lat: 41.00535,
      lng: -98.61402,
      city: 'Cairo',
      state: 'NE',
    },
    68825: {
      lat: 41.27347,
      lng: -99.95758,
      city: 'Callaway',
      state: 'NE',
    },
    68826: {
      lat: 41.16341,
      lng: -98.0191,
      city: 'Central City',
      state: 'NE',
    },
    68827: {
      lat: 41.04247,
      lng: -98.1811,
      city: 'Chapman',
      state: 'NE',
    },
    68828: {
      lat: 41.55626,
      lng: -99.24776,
      city: 'Comstock',
      state: 'NE',
    },
    68831: {
      lat: 41.10937,
      lng: -98.54661,
      city: 'Dannebrog',
      state: 'NE',
    },
    68832: {
      lat: 40.76963,
      lng: -98.36608,
      city: 'Doniphan',
      state: 'NE',
    },
    68833: {
      lat: 41.80281,
      lng: -100.09433,
      city: 'Dunning',
      state: 'NE',
    },
    68834: {
      lat: 41.01834,
      lng: -99.65049,
      city: 'Eddyville',
      state: 'NE',
    },
    68835: { lat: 41.33326, lng: -98.61769, city: 'Elba', state: 'NE' },
    68836: {
      lat: 40.70709,
      lng: -99.37865,
      city: 'Elm Creek',
      state: 'NE',
    },
    68837: {
      lat: 41.66139,
      lng: -99.06063,
      city: 'Elyria',
      state: 'NE',
    },
    68838: {
      lat: 41.2156,
      lng: -98.66755,
      city: 'Farwell',
      state: 'NE',
    },
    68840: {
      lat: 40.75728,
      lng: -98.88027,
      city: 'Gibbon',
      state: 'NE',
    },
    68841: {
      lat: 40.76316,
      lng: -98.16677,
      city: 'Giltner',
      state: 'NE',
    },
    68842: {
      lat: 41.58068,
      lng: -98.53462,
      city: 'Greeley',
      state: 'NE',
    },
    68843: {
      lat: 40.90334,
      lng: -97.88018,
      city: 'Hampton',
      state: 'NE',
    },
    68844: {
      lat: 41.05659,
      lng: -99.05938,
      city: 'Hazard',
      state: 'NE',
    },
    68845: {
      lat: 40.71583,
      lng: -99.17059,
      city: 'Kearney',
      state: 'NE',
    },
    68846: {
      lat: 41.06499,
      lng: -97.88533,
      city: 'Hordville',
      state: 'NE',
    },
    68847: {
      lat: 40.73465,
      lng: -99.02272,
      city: 'Kearney',
      state: 'NE',
    },
    68849: {
      lat: 40.70148,
      lng: -99.10082,
      city: 'Kearney',
      state: 'NE',
    },
    68850: {
      lat: 40.82548,
      lng: -99.73574,
      city: 'Lexington',
      state: 'NE',
    },
    68852: {
      lat: 41.1672,
      lng: -99.14628,
      city: 'Litchfield',
      state: 'NE',
    },
    68853: {
      lat: 41.28975,
      lng: -98.98616,
      city: 'Loup City',
      state: 'NE',
    },
    68854: {
      lat: 41.00798,
      lng: -98.00239,
      city: 'Marquette',
      state: 'NE',
    },
    68855: {
      lat: 41.16248,
      lng: -99.32635,
      city: 'Mason City',
      state: 'NE',
    },
    68856: { lat: 41.50167, lng: -99.8168, city: 'Merna', state: 'NE' },
    68858: {
      lat: 40.96171,
      lng: -99.37797,
      city: 'Miller',
      state: 'NE',
    },
    68859: {
      lat: 41.46103,
      lng: -98.81931,
      city: 'North Loup',
      state: 'NE',
    },
    68860: {
      lat: 41.13378,
      lng: -99.79167,
      city: 'Oconto',
      state: 'NE',
    },
    68861: {
      lat: 40.69892,
      lng: -99.2639,
      city: 'Odessa',
      state: 'NE',
    },
    68862: { lat: 41.60362, lng: -98.93841, city: 'Ord', state: 'NE' },
    68863: {
      lat: 40.76628,
      lng: -99.5252,
      city: 'Overton',
      state: 'NE',
    },
    68864: {
      lat: 41.2608,
      lng: -98.24046,
      city: 'Palmer',
      state: 'NE',
    },
    68865: {
      lat: 40.89337,
      lng: -98.187,
      city: 'Phillips',
      state: 'NE',
    },
    68866: {
      lat: 40.96873,
      lng: -99.12297,
      city: 'Pleasanton',
      state: 'NE',
    },
    68869: {
      lat: 40.99944,
      lng: -98.88325,
      city: 'Ravenna',
      state: 'NE',
    },
    68870: {
      lat: 40.8453,
      lng: -99.15835,
      city: 'Riverdale',
      state: 'NE',
    },
    68871: {
      lat: 41.14039,
      lng: -98.84788,
      city: 'Rockville',
      state: 'NE',
    },
    68872: {
      lat: 41.09286,
      lng: -98.33216,
      city: 'Saint Libory',
      state: 'NE',
    },
    68873: {
      lat: 41.24696,
      lng: -98.45149,
      city: 'Saint Paul',
      state: 'NE',
    },
    68874: {
      lat: 41.63368,
      lng: -99.42635,
      city: 'Sargent',
      state: 'NE',
    },
    68875: {
      lat: 41.51328,
      lng: -98.68197,
      city: 'Scotia',
      state: 'NE',
    },
    68876: {
      lat: 40.82492,
      lng: -98.74663,
      city: 'Shelton',
      state: 'NE',
    },
    68878: {
      lat: 40.98623,
      lng: -99.50567,
      city: 'Sumner',
      state: 'NE',
    },
    68879: {
      lat: 41.87411,
      lng: -99.50104,
      city: 'Taylor',
      state: 'NE',
    },
    68881: {
      lat: 41.43472,
      lng: -99.35957,
      city: 'Westerville',
      state: 'NE',
    },
    68882: {
      lat: 41.42362,
      lng: -98.40366,
      city: 'Wolbach',
      state: 'NE',
    },
    68883: {
      lat: 40.81823,
      lng: -98.58558,
      city: 'Wood River',
      state: 'NE',
    },
    68901: {
      lat: 40.59092,
      lng: -98.36599,
      city: 'Hastings',
      state: 'NE',
    },
    68920: { lat: 40.15482, lng: -99.33521, city: 'Alma', state: 'NE' },
    68922: {
      lat: 40.33653,
      lng: -99.89976,
      city: 'Arapahoe',
      state: 'NE',
    },
    68923: {
      lat: 40.36835,
      lng: -99.47314,
      city: 'Atlanta',
      state: 'NE',
    },
    68924: {
      lat: 40.49557,
      lng: -99.12469,
      city: 'Axtell',
      state: 'NE',
    },
    68925: { lat: 40.42366, lng: -98.43821, city: 'Ayr', state: 'NE' },
    68926: {
      lat: 40.09311,
      lng: -99.85139,
      city: 'Beaver City',
      state: 'NE',
    },
    68927: {
      lat: 40.51146,
      lng: -99.62665,
      city: 'Bertrand',
      state: 'NE',
    },
    68928: {
      lat: 40.2874,
      lng: -98.60728,
      city: 'Bladen',
      state: 'NE',
    },
    68929: {
      lat: 40.15245,
      lng: -99.07124,
      city: 'Bloomington',
      state: 'NE',
    },
    68930: {
      lat: 40.28514,
      lng: -98.41846,
      city: 'Blue Hill',
      state: 'NE',
    },
    68932: {
      lat: 40.30258,
      lng: -98.73851,
      city: 'Campbell',
      state: 'NE',
    },
    68933: {
      lat: 40.51786,
      lng: -98.04211,
      city: 'Clay Center',
      state: 'NE',
    },
    68934: {
      lat: 40.33831,
      lng: -98.15962,
      city: 'Deweese',
      state: 'NE',
    },
    68935: {
      lat: 40.38058,
      lng: -97.95859,
      city: 'Edgar',
      state: 'NE',
    },
    68936: {
      lat: 40.31655,
      lng: -99.77868,
      city: 'Edison',
      state: 'NE',
    },
    68937: {
      lat: 40.57262,
      lng: -99.85494,
      city: 'Elwood',
      state: 'NE',
    },
    68938: {
      lat: 40.42093,
      lng: -98.12344,
      city: 'Fairfield',
      state: 'NE',
    },
    68939: {
      lat: 40.10977,
      lng: -98.96169,
      city: 'Franklin',
      state: 'NE',
    },
    68940: { lat: 40.53815, lng: -99.25086, city: 'Funk', state: 'NE' },
    68941: {
      lat: 40.46882,
      lng: -98.26792,
      city: 'Glenvil',
      state: 'NE',
    },
    68942: {
      lat: 40.09552,
      lng: -98.31143,
      city: 'Guide Rock',
      state: 'NE',
    },
    68943: {
      lat: 40.03916,
      lng: -97.88589,
      city: 'Hardy',
      state: 'NE',
    },
    68944: {
      lat: 40.63741,
      lng: -98.0949,
      city: 'Harvard',
      state: 'NE',
    },
    68945: {
      lat: 40.56098,
      lng: -98.77327,
      city: 'Heartwell',
      state: 'NE',
    },
    68946: {
      lat: 40.1067,
      lng: -99.99048,
      city: 'Hendley',
      state: 'NE',
    },
    68947: {
      lat: 40.30139,
      lng: -99.05188,
      city: 'Hildreth',
      state: 'NE',
    },
    68948: {
      lat: 40.34151,
      lng: -100.02639,
      city: 'Holbrook',
      state: 'NE',
    },
    68949: {
      lat: 40.43193,
      lng: -99.39481,
      city: 'Holdrege',
      state: 'NE',
    },
    68950: {
      lat: 40.45586,
      lng: -98.66413,
      city: 'Holstein',
      state: 'NE',
    },
    68952: {
      lat: 40.08443,
      lng: -98.67587,
      city: 'Inavale',
      state: 'NE',
    },
    68954: {
      lat: 40.61215,
      lng: -98.23512,
      city: 'Inland',
      state: 'NE',
    },
    68955: {
      lat: 40.59288,
      lng: -98.53966,
      city: 'Juniata',
      state: 'NE',
    },
    68956: {
      lat: 40.63088,
      lng: -98.6891,
      city: 'Kenesaw',
      state: 'NE',
    },
    68957: {
      lat: 40.26745,
      lng: -98.24176,
      city: 'Lawrence',
      state: 'NE',
    },
    68958: {
      lat: 40.52772,
      lng: -99.50394,
      city: 'Loomis',
      state: 'NE',
    },
    68959: {
      lat: 40.49009,
      lng: -98.91487,
      city: 'Minden',
      state: 'NE',
    },
    68960: {
      lat: 40.08549,
      lng: -99.13866,
      city: 'Naponee',
      state: 'NE',
    },
    68961: {
      lat: 40.20679,
      lng: -98.03502,
      city: 'Nelson',
      state: 'NE',
    },
    68964: { lat: 40.23541, lng: -97.90207, city: 'Oak', state: 'NE' },
    68966: {
      lat: 40.15765,
      lng: -99.46262,
      city: 'Orleans',
      state: 'NE',
    },
    68967: {
      lat: 40.25263,
      lng: -99.63085,
      city: 'Oxford',
      state: 'NE',
    },
    68970: {
      lat: 40.10157,
      lng: -98.52292,
      city: 'Red Cloud',
      state: 'NE',
    },
    68971: {
      lat: 40.11931,
      lng: -99.24478,
      city: 'Republican City',
      state: 'NE',
    },
    68972: {
      lat: 40.10361,
      lng: -98.80258,
      city: 'Riverton',
      state: 'NE',
    },
    68973: {
      lat: 40.45014,
      lng: -98.55962,
      city: 'Roseland',
      state: 'NE',
    },
    68974: {
      lat: 40.13235,
      lng: -97.86457,
      city: 'Ruskin',
      state: 'NE',
    },
    68975: {
      lat: 40.61705,
      lng: -97.95483,
      city: 'Saronville',
      state: 'NE',
    },
    68976: {
      lat: 40.6023,
      lng: -99.70918,
      city: 'Smithfield',
      state: 'NE',
    },
    68977: {
      lat: 40.06697,
      lng: -99.63045,
      city: 'Stamford',
      state: 'NE',
    },
    68978: {
      lat: 40.06339,
      lng: -98.09379,
      city: 'Superior',
      state: 'NE',
    },
    68979: {
      lat: 40.59717,
      lng: -97.85319,
      city: 'Sutton',
      state: 'NE',
    },
    68980: {
      lat: 40.68213,
      lng: -98.25814,
      city: 'Trumbull',
      state: 'NE',
    },
    68981: {
      lat: 40.28601,
      lng: -98.89237,
      city: 'Upland',
      state: 'NE',
    },
    68982: {
      lat: 40.33081,
      lng: -99.19521,
      city: 'Wilcox',
      state: 'NE',
    },
    69001: {
      lat: 40.25232,
      lng: -100.65782,
      city: 'McCook',
      state: 'NE',
    },
    69020: {
      lat: 40.23522,
      lng: -100.31448,
      city: 'Bartley',
      state: 'NE',
    },
    69021: {
      lat: 40.13945,
      lng: -101.53594,
      city: 'Benkelman',
      state: 'NE',
    },
    69022: {
      lat: 40.33938,
      lng: -100.18413,
      city: 'Cambridge',
      state: 'NE',
    },
    69023: {
      lat: 40.48132,
      lng: -101.89668,
      city: 'Champion',
      state: 'NE',
    },
    69024: {
      lat: 40.23918,
      lng: -100.856,
      city: 'Culbertson',
      state: 'NE',
    },
    69025: {
      lat: 40.61957,
      lng: -100.50814,
      city: 'Curtis',
      state: 'NE',
    },
    69026: {
      lat: 40.01975,
      lng: -100.41332,
      city: 'Danbury',
      state: 'NE',
    },
    69027: {
      lat: 40.49841,
      lng: -101.52638,
      city: 'Enders',
      state: 'NE',
    },
    69028: {
      lat: 40.64107,
      lng: -100.04708,
      city: 'Eustis',
      state: 'NE',
    },
    69029: {
      lat: 40.68537,
      lng: -100.24277,
      city: 'Farnam',
      state: 'NE',
    },
    69030: {
      lat: 40.20318,
      lng: -101.92592,
      city: 'Haigler',
      state: 'NE',
    },
    69032: {
      lat: 40.56766,
      lng: -101.0471,
      city: 'Hayes Center',
      state: 'NE',
    },
    69033: {
      lat: 40.56447,
      lng: -101.68679,
      city: 'Imperial',
      state: 'NE',
    },
    69034: {
      lat: 40.24929,
      lng: -100.43866,
      city: 'Indianola',
      state: 'NE',
    },
    69036: {
      lat: 40.02788,
      lng: -100.29782,
      city: 'Lebanon',
      state: 'NE',
    },
    69037: { lat: 40.17759, lng: -101.40394, city: 'Max', state: 'NE' },
    69038: {
      lat: 40.60932,
      lng: -100.73703,
      city: 'Maywood',
      state: 'NE',
    },
    69039: {
      lat: 40.7013,
      lng: -100.38529,
      city: 'Moorefield',
      state: 'NE',
    },
    69040: {
      lat: 40.35565,
      lng: -101.12027,
      city: 'Palisade',
      state: 'NE',
    },
    69041: {
      lat: 40.17288,
      lng: -101.73415,
      city: 'Parks',
      state: 'NE',
    },
    69042: {
      lat: 40.47547,
      lng: -100.37903,
      city: 'Stockville',
      state: 'NE',
    },
    69043: {
      lat: 40.13493,
      lng: -101.23754,
      city: 'Stratton',
      state: 'NE',
    },
    69044: {
      lat: 40.13171,
      lng: -101.02837,
      city: 'Trenton',
      state: 'NE',
    },
    69045: {
      lat: 40.44582,
      lng: -101.36639,
      city: 'Wauneta',
      state: 'NE',
    },
    69046: {
      lat: 40.06821,
      lng: -100.10403,
      city: 'Wilsonville',
      state: 'NE',
    },
    69101: {
      lat: 41.1502,
      lng: -100.80319,
      city: 'North Platte',
      state: 'NE',
    },
    69120: {
      lat: 41.42592,
      lng: -100.23384,
      city: 'Arnold',
      state: 'NE',
    },
    69121: {
      lat: 41.56631,
      lng: -101.71983,
      city: 'Arthur',
      state: 'NE',
    },
    69122: {
      lat: 41.09713,
      lng: -102.08834,
      city: 'Big Springs',
      state: 'NE',
    },
    69123: {
      lat: 41.04981,
      lng: -100.36627,
      city: 'Brady',
      state: 'NE',
    },
    69125: {
      lat: 41.59087,
      lng: -102.85835,
      city: 'Broadwater',
      state: 'NE',
    },
    69127: {
      lat: 41.10231,
      lng: -101.90774,
      city: 'Brule',
      state: 'NE',
    },
    69128: {
      lat: 41.23252,
      lng: -103.90613,
      city: 'Bushnell',
      state: 'NE',
    },
    69129: {
      lat: 41.14089,
      lng: -102.37823,
      city: 'Chappell',
      state: 'NE',
    },
    69130: {
      lat: 40.89924,
      lng: -99.96628,
      city: 'Cozad',
      state: 'NE',
    },
    69131: {
      lat: 41.4216,
      lng: -103.00047,
      city: 'Dalton',
      state: 'NE',
    },
    69132: {
      lat: 40.81495,
      lng: -101.00664,
      city: 'Dickens',
      state: 'NE',
    },
    69133: { lat: 41.21975, lng: -103.47178, city: 'Dix', state: 'NE' },
    69134: {
      lat: 40.80733,
      lng: -101.36968,
      city: 'Elsie',
      state: 'NE',
    },
    69135: {
      lat: 42.21111,
      lng: -100.31028,
      city: 'Elsmere',
      state: 'NE',
    },
    69138: {
      lat: 40.99923,
      lng: -100.17684,
      city: 'Gothenburg',
      state: 'NE',
    },
    69140: {
      lat: 40.84129,
      lng: -101.7724,
      city: 'Grant',
      state: 'NE',
    },
    69141: {
      lat: 41.30409,
      lng: -102.93602,
      city: 'Gurley',
      state: 'NE',
    },
    69142: {
      lat: 41.91585,
      lng: -100.31719,
      city: 'Halsey',
      state: 'NE',
    },
    69143: {
      lat: 41.19184,
      lng: -101.01934,
      city: 'Hershey',
      state: 'NE',
    },
    69144: {
      lat: 41.317,
      lng: -101.60261,
      city: 'Keystone',
      state: 'NE',
    },
    69145: {
      lat: 41.24913,
      lng: -103.67097,
      city: 'Kimball',
      state: 'NE',
    },
    69146: {
      lat: 41.34986,
      lng: -101.82892,
      city: 'Lemoyne',
      state: 'NE',
    },
    69147: {
      lat: 41.51514,
      lng: -102.08813,
      city: 'Lewellen',
      state: 'NE',
    },
    69148: {
      lat: 41.61329,
      lng: -102.58773,
      city: 'Lisco',
      state: 'NE',
    },
    69149: {
      lat: 41.22416,
      lng: -102.66362,
      city: 'Lodgepole',
      state: 'NE',
    },
    69150: {
      lat: 40.82763,
      lng: -101.54599,
      city: 'Madrid',
      state: 'NE',
    },
    69151: {
      lat: 41.10153,
      lng: -100.52011,
      city: 'Maxwell',
      state: 'NE',
    },
    69152: {
      lat: 42.08697,
      lng: -101.12216,
      city: 'Mullen',
      state: 'NE',
    },
    69153: {
      lat: 41.08018,
      lng: -101.67866,
      city: 'Ogallala',
      state: 'NE',
    },
    69154: {
      lat: 41.4642,
      lng: -102.35349,
      city: 'Oshkosh',
      state: 'NE',
    },
    69155: {
      lat: 41.18974,
      lng: -101.39008,
      city: 'Paxton',
      state: 'NE',
    },
    69156: {
      lat: 41.27689,
      lng: -103.34587,
      city: 'Potter',
      state: 'NE',
    },
    69157: {
      lat: 42.02451,
      lng: -100.14824,
      city: 'Purdum',
      state: 'NE',
    },
    69161: {
      lat: 42.12034,
      lng: -100.76679,
      city: 'Seneca',
      state: 'NE',
    },
    69162: {
      lat: 41.13001,
      lng: -103.02815,
      city: 'Sidney',
      state: 'NE',
    },
    69163: {
      lat: 41.59328,
      lng: -100.56795,
      city: 'Stapleton',
      state: 'NE',
    },
    69165: {
      lat: 41.35102,
      lng: -101.27268,
      city: 'Sutherland',
      state: 'NE',
    },
    69166: {
      lat: 42.0791,
      lng: -100.56581,
      city: 'Thedford',
      state: 'NE',
    },
    69167: {
      lat: 41.58758,
      lng: -101.02296,
      city: 'Tryon',
      state: 'NE',
    },
    69168: {
      lat: 40.74817,
      lng: -101.98129,
      city: 'Venango',
      state: 'NE',
    },
    69169: {
      lat: 40.82144,
      lng: -101.17663,
      city: 'Wallace',
      state: 'NE',
    },
    69170: {
      lat: 40.79563,
      lng: -100.74068,
      city: 'Wellfleet',
      state: 'NE',
    },
    69171: {
      lat: 40.89065,
      lng: -100.06814,
      city: 'Willow Island',
      state: 'NE',
    },
    69201: {
      lat: 42.70004,
      lng: -100.59663,
      city: 'Valentine',
      state: 'NE',
    },
    69210: {
      lat: 42.39766,
      lng: -99.84932,
      city: 'Ainsworth',
      state: 'NE',
    },
    69211: {
      lat: 42.83965,
      lng: -101.31068,
      city: 'Cody',
      state: 'NE',
    },
    69212: {
      lat: 42.98729,
      lng: -100.80438,
      city: 'Crookston',
      state: 'NE',
    },
    69214: {
      lat: 42.49121,
      lng: -100.07914,
      city: 'Johnstown',
      state: 'NE',
    },
    69216: {
      lat: 42.94069,
      lng: -100.96897,
      city: 'Kilgore',
      state: 'NE',
    },
    69217: {
      lat: 42.42045,
      lng: -99.71314,
      city: 'Long Pine',
      state: 'NE',
    },
    69218: {
      lat: 42.78005,
      lng: -101.63227,
      city: 'Merriman',
      state: 'NE',
    },
    69219: {
      lat: 42.70144,
      lng: -101.0886,
      city: 'Nenzel',
      state: 'NE',
    },
    69220: {
      lat: 42.92256,
      lng: -100.17198,
      city: 'Sparks',
      state: 'NE',
    },
    69221: {
      lat: 42.63515,
      lng: -100.3104,
      city: 'Wood Lake',
      state: 'NE',
    },
    69301: {
      lat: 42.12297,
      lng: -102.88593,
      city: 'Alliance',
      state: 'NE',
    },
    69331: {
      lat: 41.85235,
      lng: -102.96047,
      city: 'Angora',
      state: 'NE',
    },
    69333: {
      lat: 42.04483,
      lng: -101.96962,
      city: 'Ashby',
      state: 'NE',
    },
    69334: {
      lat: 41.82364,
      lng: -103.2842,
      city: 'Bayard',
      state: 'NE',
    },
    69335: {
      lat: 42.02418,
      lng: -102.13908,
      city: 'Bingham',
      state: 'NE',
    },
    69336: {
      lat: 41.61845,
      lng: -103.15152,
      city: 'Bridgeport',
      state: 'NE',
    },
    69337: {
      lat: 42.86566,
      lng: -102.99513,
      city: 'Chadron',
      state: 'NE',
    },
    69339: {
      lat: 42.60649,
      lng: -103.42939,
      city: 'Crawford',
      state: 'NE',
    },
    69340: {
      lat: 42.15192,
      lng: -102.21378,
      city: 'Ellsworth',
      state: 'NE',
    },
    69341: {
      lat: 41.74154,
      lng: -103.67937,
      city: 'Gering',
      state: 'NE',
    },
    69343: {
      lat: 42.66842,
      lng: -101.94084,
      city: 'Gordon',
      state: 'NE',
    },
    69345: {
      lat: 41.58164,
      lng: -103.7234,
      city: 'Harrisburg',
      state: 'NE',
    },
    69346: {
      lat: 42.66459,
      lng: -103.80651,
      city: 'Harrison',
      state: 'NE',
    },
    69347: {
      lat: 42.59693,
      lng: -102.6753,
      city: 'Hay Springs',
      state: 'NE',
    },
    69348: {
      lat: 42.3299,
      lng: -103.18777,
      city: 'Hemingford',
      state: 'NE',
    },
    69350: {
      lat: 41.98667,
      lng: -101.73311,
      city: 'Hyannis',
      state: 'NE',
    },
    69351: {
      lat: 42.1171,
      lng: -102.42096,
      city: 'Lakeside',
      state: 'NE',
    },
    69352: {
      lat: 41.80396,
      lng: -103.97174,
      city: 'Lyman',
      state: 'NE',
    },
    69353: {
      lat: 41.74703,
      lng: -103.41898,
      city: 'Mcgrew',
      state: 'NE',
    },
    69354: {
      lat: 42.40812,
      lng: -103.42469,
      city: 'Marsland',
      state: 'NE',
    },
    69355: {
      lat: 41.78192,
      lng: -103.51709,
      city: 'Melbeta',
      state: 'NE',
    },
    69356: {
      lat: 41.89318,
      lng: -103.45074,
      city: 'Minatare',
      state: 'NE',
    },
    69357: {
      lat: 42.0776,
      lng: -103.79553,
      city: 'Mitchell',
      state: 'NE',
    },
    69358: {
      lat: 42.07503,
      lng: -103.96617,
      city: 'Morrill',
      state: 'NE',
    },
    69360: {
      lat: 42.74359,
      lng: -102.48979,
      city: 'Rushville',
      state: 'NE',
    },
    69361: {
      lat: 42.01254,
      lng: -103.56802,
      city: 'Scottsbluff',
      state: 'NE',
    },
    69365: {
      lat: 42.94744,
      lng: -102.46095,
      city: 'Whiteclay',
      state: 'NE',
    },
    69366: {
      lat: 42.13175,
      lng: -101.5421,
      city: 'Whitman',
      state: 'NE',
    },
    69367: {
      lat: 42.81651,
      lng: -103.32332,
      city: 'Whitney',
      state: 'NE',
    },
    70001: {
      lat: 29.98272,
      lng: -90.16881,
      city: 'Metairie',
      state: 'LA',
    },
    70002: {
      lat: 30.0102,
      lng: -90.16241,
      city: 'Metairie',
      state: 'LA',
    },
    70003: {
      lat: 29.99874,
      lng: -90.21418,
      city: 'Metairie',
      state: 'LA',
    },
    70005: {
      lat: 30.00024,
      lng: -90.13422,
      city: 'Metairie',
      state: 'LA',
    },
    70006: {
      lat: 30.01393,
      lng: -90.19171,
      city: 'Metairie',
      state: 'LA',
    },
    70030: {
      lat: 29.81635,
      lng: -90.43526,
      city: 'Des Allemands',
      state: 'LA',
    },
    70031: { lat: 29.94401, lng: -90.29666, city: 'Ama', state: 'LA' },
    70032: { lat: 29.95128, lng: -89.9958, city: 'Arabi', state: 'LA' },
    70036: {
      lat: 29.69855,
      lng: -90.12667,
      city: 'Barataria',
      state: 'LA',
    },
    70037: {
      lat: 29.78669,
      lng: -90.01523,
      city: 'Belle Chasse',
      state: 'LA',
    },
    70038: {
      lat: 29.33105,
      lng: -89.4007,
      city: 'Boothville',
      state: 'LA',
    },
    70039: {
      lat: 29.88274,
      lng: -90.37925,
      city: 'Boutte',
      state: 'LA',
    },
    70040: {
      lat: 29.75806,
      lng: -89.92327,
      city: 'Braithwaite',
      state: 'LA',
    },
    70041: {
      lat: 29.33194,
      lng: -89.46978,
      city: 'Buras',
      state: 'LA',
    },
    70043: {
      lat: 29.9571,
      lng: -89.96481,
      city: 'Chalmette',
      state: 'LA',
    },
    70047: {
      lat: 29.96686,
      lng: -90.36552,
      city: 'Destrehan',
      state: 'LA',
    },
    70049: {
      lat: 30.03062,
      lng: -90.55911,
      city: 'Edgard',
      state: 'LA',
    },
    70050: {
      lat: 29.38351,
      lng: -89.59601,
      city: 'Empire',
      state: 'LA',
    },
    70051: {
      lat: 30.07356,
      lng: -90.63999,
      city: 'Garyville',
      state: 'LA',
    },
    70052: {
      lat: 30.07103,
      lng: -90.68731,
      city: 'Gramercy',
      state: 'LA',
    },
    70053: {
      lat: 29.91456,
      lng: -90.05318,
      city: 'Gretna',
      state: 'LA',
    },
    70056: {
      lat: 29.88848,
      lng: -90.03028,
      city: 'Gretna',
      state: 'LA',
    },
    70057: {
      lat: 29.95949,
      lng: -90.45897,
      city: 'Hahnville',
      state: 'LA',
    },
    70058: {
      lat: 29.8701,
      lng: -90.06708,
      city: 'Harvey',
      state: 'LA',
    },
    70062: {
      lat: 29.98924,
      lng: -90.25579,
      city: 'Kenner',
      state: 'LA',
    },
    70065: {
      lat: 30.02767,
      lng: -90.25405,
      city: 'Kenner',
      state: 'LA',
    },
    70067: {
      lat: 29.72008,
      lng: -90.10383,
      city: 'Lafitte',
      state: 'LA',
    },
    70068: {
      lat: 30.14113,
      lng: -90.43502,
      city: 'La Place',
      state: 'LA',
    },
    70070: {
      lat: 29.83302,
      lng: -90.3052,
      city: 'Luling',
      state: 'LA',
    },
    70071: {
      lat: 30.05123,
      lng: -90.70291,
      city: 'Lutcher',
      state: 'LA',
    },
    70072: {
      lat: 29.83012,
      lng: -90.12724,
      city: 'Marrero',
      state: 'LA',
    },
    70075: {
      lat: 29.93158,
      lng: -89.92081,
      city: 'Meraux',
      state: 'LA',
    },
    70076: {
      lat: 30.04945,
      lng: -90.63678,
      city: 'Mount Airy',
      state: 'LA',
    },
    70079: {
      lat: 30.00172,
      lng: -90.41218,
      city: 'Norco',
      state: 'LA',
    },
    70080: {
      lat: 29.8743,
      lng: -90.43227,
      city: 'Paradis',
      state: 'LA',
    },
    70082: {
      lat: 29.5658,
      lng: -89.75677,
      city: 'Pointe A La Hache',
      state: 'LA',
    },
    70083: {
      lat: 29.52342,
      lng: -89.85211,
      city: 'Port Sulphur',
      state: 'LA',
    },
    70084: {
      lat: 30.07242,
      lng: -90.57251,
      city: 'Reserve',
      state: 'LA',
    },
    70085: {
      lat: 29.81629,
      lng: -89.76127,
      city: 'Saint Bernard',
      state: 'LA',
    },
    70086: {
      lat: 30.02628,
      lng: -90.88198,
      city: 'Saint James',
      state: 'LA',
    },
    70087: {
      lat: 29.98092,
      lng: -90.31264,
      city: 'Saint Rose',
      state: 'LA',
    },
    70090: {
      lat: 29.96725,
      lng: -90.6934,
      city: 'Vacherie',
      state: 'LA',
    },
    70091: {
      lat: 29.17723,
      lng: -89.23204,
      city: 'Venice',
      state: 'LA',
    },
    70092: {
      lat: 29.90026,
      lng: -89.89598,
      city: 'Violet',
      state: 'LA',
    },
    70094: {
      lat: 29.91162,
      lng: -90.20876,
      city: 'Westwego',
      state: 'LA',
    },
    70112: {
      lat: 29.95608,
      lng: -90.07733,
      city: 'New Orleans',
      state: 'LA',
    },
    70113: {
      lat: 29.9422,
      lng: -90.08382,
      city: 'New Orleans',
      state: 'LA',
    },
    70114: {
      lat: 29.93704,
      lng: -90.0321,
      city: 'New Orleans',
      state: 'LA',
    },
    70115: {
      lat: 29.92375,
      lng: -90.10219,
      city: 'New Orleans',
      state: 'LA',
    },
    70116: {
      lat: 29.96697,
      lng: -90.06448,
      city: 'New Orleans',
      state: 'LA',
    },
    70117: {
      lat: 29.9685,
      lng: -90.03004,
      city: 'New Orleans',
      state: 'LA',
    },
    70118: {
      lat: 29.9451,
      lng: -90.1257,
      city: 'New Orleans',
      state: 'LA',
    },
    70119: {
      lat: 29.97567,
      lng: -90.08729,
      city: 'New Orleans',
      state: 'LA',
    },
    70121: {
      lat: 29.9608,
      lng: -90.15881,
      city: 'New Orleans',
      state: 'LA',
    },
    70122: {
      lat: 30.00835,
      lng: -90.06467,
      city: 'New Orleans',
      state: 'LA',
    },
    70123: {
      lat: 29.951,
      lng: -90.20501,
      city: 'New Orleans',
      state: 'LA',
    },
    70124: {
      lat: 30.00747,
      lng: -90.10378,
      city: 'New Orleans',
      state: 'LA',
    },
    70125: {
      lat: 29.95202,
      lng: -90.10338,
      city: 'New Orleans',
      state: 'LA',
    },
    70126: {
      lat: 30.01948,
      lng: -90.01739,
      city: 'New Orleans',
      state: 'LA',
    },
    70127: {
      lat: 30.02966,
      lng: -89.9761,
      city: 'New Orleans',
      state: 'LA',
    },
    70128: {
      lat: 30.04896,
      lng: -89.95398,
      city: 'New Orleans',
      state: 'LA',
    },
    70129: {
      lat: 30.08239,
      lng: -89.81617,
      city: 'New Orleans',
      state: 'LA',
    },
    70130: {
      lat: 29.93671,
      lng: -90.06949,
      city: 'New Orleans',
      state: 'LA',
    },
    70131: {
      lat: 29.9081,
      lng: -89.96044,
      city: 'New Orleans',
      state: 'LA',
    },
    70139: {
      lat: 29.95023,
      lng: -90.071,
      city: 'New Orleans',
      state: 'LA',
    },
    70148: {
      lat: 30.0287,
      lng: -90.06753,
      city: 'New Orleans',
      state: 'LA',
    },
    70163: {
      lat: 29.94998,
      lng: -90.07546,
      city: 'New Orleans',
      state: 'LA',
    },
    70301: {
      lat: 29.81431,
      lng: -90.7411,
      city: 'Thibodaux',
      state: 'LA',
    },
    70310: {
      lat: 29.79048,
      lng: -90.80301,
      city: 'Thibodaux',
      state: 'LA',
    },
    70339: {
      lat: 29.92987,
      lng: -91.18357,
      city: 'Pierre Part',
      state: 'LA',
    },
    70340: {
      lat: 29.66974,
      lng: -91.1043,
      city: 'Amelia',
      state: 'LA',
    },
    70341: {
      lat: 30.02579,
      lng: -91.08267,
      city: 'Belle Rose',
      state: 'LA',
    },
    70342: {
      lat: 29.69486,
      lng: -91.23772,
      city: 'Berwick',
      state: 'LA',
    },
    70343: {
      lat: 29.55308,
      lng: -90.56402,
      city: 'Bourg',
      state: 'LA',
    },
    70344: {
      lat: 29.39711,
      lng: -90.63777,
      city: 'Chauvin',
      state: 'LA',
    },
    70345: {
      lat: 29.51815,
      lng: -90.23763,
      city: 'Cut Off',
      state: 'LA',
    },
    70346: {
      lat: 30.10774,
      lng: -91.02061,
      city: 'Donaldsonville',
      state: 'LA',
    },
    70352: {
      lat: 29.68561,
      lng: -90.94486,
      city: 'Donner',
      state: 'LA',
    },
    70353: {
      lat: 29.36598,
      lng: -90.70554,
      city: 'Dulac',
      state: 'LA',
    },
    70354: {
      lat: 29.43136,
      lng: -90.34336,
      city: 'Galliano',
      state: 'LA',
    },
    70355: {
      lat: 29.69491,
      lng: -90.42916,
      city: 'Gheens',
      state: 'LA',
    },
    70356: {
      lat: 29.64142,
      lng: -90.95953,
      city: 'Gibson',
      state: 'LA',
    },
    70357: {
      lat: 29.28451,
      lng: -90.17463,
      city: 'Golden Meadow',
      state: 'LA',
    },
    70358: {
      lat: 29.21367,
      lng: -90.0306,
      city: 'Grand Isle',
      state: 'LA',
    },
    70359: { lat: 29.68649, lng: -90.77859, city: 'Gray', state: 'LA' },
    70360: {
      lat: 29.58396,
      lng: -90.81001,
      city: 'Houma',
      state: 'LA',
    },
    70363: {
      lat: 29.51516,
      lng: -90.70404,
      city: 'Houma',
      state: 'LA',
    },
    70364: {
      lat: 29.63419,
      lng: -90.68752,
      city: 'Houma',
      state: 'LA',
    },
    70372: {
      lat: 29.78249,
      lng: -90.9889,
      city: 'Labadieville',
      state: 'LA',
    },
    70373: {
      lat: 29.61335,
      lng: -90.31919,
      city: 'Larose',
      state: 'LA',
    },
    70374: {
      lat: 29.59924,
      lng: -90.50319,
      city: 'Lockport',
      state: 'LA',
    },
    70375: {
      lat: 29.69491,
      lng: -90.53257,
      city: 'Mathews',
      state: 'LA',
    },
    70377: {
      lat: 29.45808,
      lng: -90.51361,
      city: 'Montegut',
      state: 'LA',
    },
    70380: {
      lat: 29.71944,
      lng: -91.12634,
      city: 'Morgan City',
      state: 'LA',
    },
    70390: {
      lat: 29.89068,
      lng: -91.03616,
      city: 'Napoleonville',
      state: 'LA',
    },
    70391: {
      lat: 29.99516,
      lng: -91.06029,
      city: 'Paincourtville',
      state: 'LA',
    },
    70392: {
      lat: 29.69847,
      lng: -91.32164,
      city: 'Patterson',
      state: 'LA',
    },
    70393: {
      lat: 29.9959,
      lng: -90.99897,
      city: 'Plattenville',
      state: 'LA',
    },
    70394: {
      lat: 29.69448,
      lng: -90.61399,
      city: 'Raceland',
      state: 'LA',
    },
    70395: {
      lat: 29.69309,
      lng: -90.8513,
      city: 'Schriever',
      state: 'LA',
    },
    70397: {
      lat: 29.38444,
      lng: -90.823,
      city: 'Theriot',
      state: 'LA',
    },
    70401: {
      lat: 30.5281,
      lng: -90.45472,
      city: 'Hammond',
      state: 'LA',
    },
    70402: {
      lat: 30.51652,
      lng: -90.46932,
      city: 'Hammond',
      state: 'LA',
    },
    70403: {
      lat: 30.47989,
      lng: -90.48639,
      city: 'Hammond',
      state: 'LA',
    },
    70420: {
      lat: 30.48767,
      lng: -89.95907,
      city: 'Abita Springs',
      state: 'LA',
    },
    70422: { lat: 30.73699, lng: -90.491, city: 'Amite', state: 'LA' },
    70426: {
      lat: 30.93142,
      lng: -89.85807,
      city: 'Angie',
      state: 'LA',
    },
    70427: {
      lat: 30.74587,
      lng: -89.9125,
      city: 'Bogalusa',
      state: 'LA',
    },
    70431: { lat: 30.61211, lng: -89.97215, city: 'Bush', state: 'LA' },
    70433: {
      lat: 30.46184,
      lng: -90.13796,
      city: 'Covington',
      state: 'LA',
    },
    70435: {
      lat: 30.55955,
      lng: -90.101,
      city: 'Covington',
      state: 'LA',
    },
    70436: {
      lat: 30.83057,
      lng: -90.53315,
      city: 'Fluker',
      state: 'LA',
    },
    70437: {
      lat: 30.60909,
      lng: -90.20928,
      city: 'Folsom',
      state: 'LA',
    },
    70438: {
      lat: 30.84139,
      lng: -90.11186,
      city: 'Franklinton',
      state: 'LA',
    },
    70441: {
      lat: 30.85987,
      lng: -90.7489,
      city: 'Greensburg',
      state: 'LA',
    },
    70442: {
      lat: 30.70307,
      lng: -90.33433,
      city: 'Husser',
      state: 'LA',
    },
    70443: {
      lat: 30.62885,
      lng: -90.54257,
      city: 'Independence',
      state: 'LA',
    },
    70444: {
      lat: 30.90833,
      lng: -90.48707,
      city: 'Kentwood',
      state: 'LA',
    },
    70445: {
      lat: 30.36352,
      lng: -89.91265,
      city: 'Lacombe',
      state: 'LA',
    },
    70446: {
      lat: 30.63612,
      lng: -90.35159,
      city: 'Loranger',
      state: 'LA',
    },
    70447: {
      lat: 30.42144,
      lng: -90.20257,
      city: 'Madisonville',
      state: 'LA',
    },
    70448: {
      lat: 30.36278,
      lng: -90.03943,
      city: 'Mandeville',
      state: 'LA',
    },
    70449: {
      lat: 30.26363,
      lng: -90.66345,
      city: 'Maurepas',
      state: 'LA',
    },
    70450: {
      lat: 30.93667,
      lng: -90.26755,
      city: 'Mount Hermon',
      state: 'LA',
    },
    70451: {
      lat: 30.54964,
      lng: -90.48111,
      city: 'Natalbany',
      state: 'LA',
    },
    70452: {
      lat: 30.41424,
      lng: -89.76048,
      city: 'Pearl River',
      state: 'LA',
    },
    70453: {
      lat: 30.69385,
      lng: -90.76918,
      city: 'Pine Grove',
      state: 'LA',
    },
    70454: {
      lat: 30.39525,
      lng: -90.3682,
      city: 'Ponchatoula',
      state: 'LA',
    },
    70455: {
      lat: 30.52438,
      lng: -90.31883,
      city: 'Robert',
      state: 'LA',
    },
    70456: {
      lat: 30.79332,
      lng: -90.50702,
      city: 'Roseland',
      state: 'LA',
    },
    70457: {
      lat: 30.52743,
      lng: -90.11332,
      city: 'Saint Benedict',
      state: 'LA',
    },
    70458: {
      lat: 30.25864,
      lng: -89.79441,
      city: 'Slidell',
      state: 'LA',
    },
    70460: {
      lat: 30.29925,
      lng: -89.83455,
      city: 'Slidell',
      state: 'LA',
    },
    70461: {
      lat: 30.2326,
      lng: -89.70931,
      city: 'Slidell',
      state: 'LA',
    },
    70462: {
      lat: 30.3656,
      lng: -90.58368,
      city: 'Springfield',
      state: 'LA',
    },
    70463: { lat: 30.6551, lng: -89.90162, city: 'Sun', state: 'LA' },
    70464: {
      lat: 30.54416,
      lng: -89.89221,
      city: 'Talisheek',
      state: 'LA',
    },
    70465: {
      lat: 30.87577,
      lng: -90.51384,
      city: 'Tangipahoa',
      state: 'LA',
    },
    70466: {
      lat: 30.5685,
      lng: -90.50195,
      city: 'Tickfaw',
      state: 'LA',
    },
    70471: {
      lat: 30.407,
      lng: -90.06346,
      city: 'Mandeville',
      state: 'LA',
    },
    70501: {
      lat: 30.2391,
      lng: -91.99122,
      city: 'Lafayette',
      state: 'LA',
    },
    70503: {
      lat: 30.17353,
      lng: -92.05901,
      city: 'Lafayette',
      state: 'LA',
    },
    70506: {
      lat: 30.19586,
      lng: -92.08075,
      city: 'Lafayette',
      state: 'LA',
    },
    70507: {
      lat: 30.28293,
      lng: -92.02825,
      city: 'Lafayette',
      state: 'LA',
    },
    70508: {
      lat: 30.15627,
      lng: -92.02913,
      city: 'Lafayette',
      state: 'LA',
    },
    70510: {
      lat: 29.89493,
      lng: -92.19569,
      city: 'Abbeville',
      state: 'LA',
    },
    70512: {
      lat: 30.41333,
      lng: -91.92337,
      city: 'Arnaudville',
      state: 'LA',
    },
    70513: {
      lat: 29.90272,
      lng: -91.90106,
      city: 'Avery Island',
      state: 'LA',
    },
    70514: {
      lat: 29.8452,
      lng: -91.55301,
      city: 'Baldwin',
      state: 'LA',
    },
    70515: {
      lat: 30.44843,
      lng: -92.57154,
      city: 'Basile',
      state: 'LA',
    },
    70516: {
      lat: 30.3674,
      lng: -92.30289,
      city: 'Branch',
      state: 'LA',
    },
    70517: {
      lat: 30.28421,
      lng: -91.79615,
      city: 'Breaux Bridge',
      state: 'LA',
    },
    70518: {
      lat: 30.13503,
      lng: -91.92922,
      city: 'Broussard',
      state: 'LA',
    },
    70519: { lat: 30.08178, lng: -91.90705, city: 'Cade', state: 'LA' },
    70520: {
      lat: 30.33265,
      lng: -92.03619,
      city: 'Carencro',
      state: 'LA',
    },
    70522: {
      lat: 29.74949,
      lng: -91.43287,
      city: 'Centerville',
      state: 'LA',
    },
    70523: {
      lat: 29.87383,
      lng: -91.52956,
      city: 'Charenton',
      state: 'LA',
    },
    70524: {
      lat: 30.55737,
      lng: -92.31859,
      city: 'Chataignier',
      state: 'LA',
    },
    70525: {
      lat: 30.40765,
      lng: -92.21761,
      city: 'Church Point',
      state: 'LA',
    },
    70526: {
      lat: 30.21051,
      lng: -92.37951,
      city: 'Crowley',
      state: 'LA',
    },
    70528: {
      lat: 29.89766,
      lng: -91.96692,
      city: 'Delcambre',
      state: 'LA',
    },
    70529: {
      lat: 30.19593,
      lng: -92.16327,
      city: 'Duson',
      state: 'LA',
    },
    70531: { lat: 30.23101, lng: -92.49678, city: 'Egan', state: 'LA' },
    70532: { lat: 30.4705, lng: -92.6993, city: 'Elton', state: 'LA' },
    70533: {
      lat: 29.90439,
      lng: -92.03536,
      city: 'Erath',
      state: 'LA',
    },
    70534: {
      lat: 30.16564,
      lng: -92.45499,
      city: 'Estherwood',
      state: 'LA',
    },
    70535: {
      lat: 30.47999,
      lng: -92.42367,
      city: 'Eunice',
      state: 'LA',
    },
    70537: {
      lat: 30.25747,
      lng: -92.56642,
      city: 'Evangeline',
      state: 'LA',
    },
    70538: {
      lat: 29.74051,
      lng: -91.54092,
      city: 'Franklin',
      state: 'LA',
    },
    70541: {
      lat: 30.42062,
      lng: -92.0426,
      city: 'Grand Coteau',
      state: 'LA',
    },
    70542: {
      lat: 30.00442,
      lng: -92.5886,
      city: 'Gueydan',
      state: 'LA',
    },
    70543: { lat: 30.33532, lng: -92.5014, city: 'Iota', state: 'LA' },
    70544: {
      lat: 29.9059,
      lng: -91.6575,
      city: 'Jeanerette',
      state: 'LA',
    },
    70546: {
      lat: 30.26756,
      lng: -92.66747,
      city: 'Jennings',
      state: 'LA',
    },
    70548: {
      lat: 29.79733,
      lng: -92.41875,
      city: 'Kaplan',
      state: 'LA',
    },
    70549: {
      lat: 30.04988,
      lng: -92.83139,
      city: 'Lake Arthur',
      state: 'LA',
    },
    70550: {
      lat: 30.5157,
      lng: -92.18966,
      city: 'Lawtell',
      state: 'LA',
    },
    70551: {
      lat: 30.46675,
      lng: -91.98028,
      city: 'Leonville',
      state: 'LA',
    },
    70552: {
      lat: 30.0617,
      lng: -91.66641,
      city: 'Loreauville',
      state: 'LA',
    },
    70554: {
      lat: 30.63633,
      lng: -92.48885,
      city: 'Mamou',
      state: 'LA',
    },
    70555: {
      lat: 30.08188,
      lng: -92.14123,
      city: 'Maurice',
      state: 'LA',
    },
    70556: {
      lat: 30.19072,
      lng: -92.58325,
      city: 'Mermentau',
      state: 'LA',
    },
    70558: {
      lat: 30.10042,
      lng: -92.07573,
      city: 'Milton',
      state: 'LA',
    },
    70559: {
      lat: 30.12876,
      lng: -92.50903,
      city: 'Morse',
      state: 'LA',
    },
    70560: {
      lat: 29.93965,
      lng: -91.8642,
      city: 'New Iberia',
      state: 'LA',
    },
    70563: {
      lat: 30.02454,
      lng: -91.73053,
      city: 'New Iberia',
      state: 'LA',
    },
    70570: {
      lat: 30.5315,
      lng: -92.10852,
      city: 'Opelousas',
      state: 'LA',
    },
    70575: {
      lat: 29.94892,
      lng: -92.15808,
      city: 'Perry',
      state: 'LA',
    },
    70576: {
      lat: 30.78097,
      lng: -92.41826,
      city: 'Pine Prairie',
      state: 'LA',
    },
    70577: {
      lat: 30.54848,
      lng: -91.92018,
      city: 'Port Barre',
      state: 'LA',
    },
    70578: { lat: 30.2291, lng: -92.26261, city: 'Rayne', state: 'LA' },
    70580: {
      lat: 30.67551,
      lng: -92.4284,
      city: 'Reddell',
      state: 'LA',
    },
    70581: {
      lat: 30.25496,
      lng: -92.73588,
      city: 'Roanoke',
      state: 'LA',
    },
    70582: {
      lat: 30.15961,
      lng: -91.7854,
      city: 'Saint Martinville',
      state: 'LA',
    },
    70583: {
      lat: 30.25846,
      lng: -92.12204,
      city: 'Scott',
      state: 'LA',
    },
    70584: {
      lat: 30.38856,
      lng: -92.10193,
      city: 'Sunset',
      state: 'LA',
    },
    70585: {
      lat: 30.87832,
      lng: -92.4077,
      city: 'Turkey Creek',
      state: 'LA',
    },
    70586: {
      lat: 30.74504,
      lng: -92.34151,
      city: 'Ville Platte',
      state: 'LA',
    },
    70589: {
      lat: 30.68584,
      lng: -92.04098,
      city: 'Washington',
      state: 'LA',
    },
    70591: { lat: 30.25604, lng: -92.8397, city: 'Welsh', state: 'LA' },
    70592: {
      lat: 30.08024,
      lng: -92.01338,
      city: 'Youngsville',
      state: 'LA',
    },
    70601: {
      lat: 30.22627,
      lng: -93.21525,
      city: 'Lake Charles',
      state: 'LA',
    },
    70605: {
      lat: 30.13048,
      lng: -93.27418,
      city: 'Lake Charles',
      state: 'LA',
    },
    70607: {
      lat: 30.04982,
      lng: -93.20606,
      city: 'Lake Charles',
      state: 'LA',
    },
    70609: {
      lat: 30.17907,
      lng: -93.21482,
      city: 'Lake Charles',
      state: 'LA',
    },
    70611: {
      lat: 30.34594,
      lng: -93.20156,
      city: 'Lake Charles',
      state: 'LA',
    },
    70615: {
      lat: 30.25609,
      lng: -93.12346,
      city: 'Lake Charles',
      state: 'LA',
    },
    70629: {
      lat: 30.23107,
      lng: -93.21864,
      city: 'Lake Charles',
      state: 'LA',
    },
    70630: {
      lat: 30.02723,
      lng: -93.04966,
      city: 'Bell City',
      state: 'LA',
    },
    70631: {
      lat: 29.86692,
      lng: -93.55404,
      city: 'Cameron',
      state: 'LA',
    },
    70632: {
      lat: 29.86747,
      lng: -92.87987,
      city: 'Creole',
      state: 'LA',
    },
    70633: {
      lat: 30.42762,
      lng: -93.3925,
      city: 'Dequincy',
      state: 'LA',
    },
    70634: {
      lat: 30.80142,
      lng: -93.24309,
      city: 'Deridder',
      state: 'LA',
    },
    70637: {
      lat: 30.70508,
      lng: -92.97207,
      city: 'Dry Creek',
      state: 'LA',
    },
    70638: {
      lat: 30.84948,
      lng: -92.78436,
      city: 'Elizabeth',
      state: 'LA',
    },
    70639: {
      lat: 30.98235,
      lng: -93.49793,
      city: 'Evans',
      state: 'LA',
    },
    70640: {
      lat: 30.36587,
      lng: -92.91652,
      city: 'Fenton',
      state: 'LA',
    },
    70643: {
      lat: 29.73649,
      lng: -92.7939,
      city: 'Grand Chenier',
      state: 'LA',
    },
    70644: {
      lat: 30.79078,
      lng: -92.94924,
      city: 'Grant',
      state: 'LA',
    },
    70645: {
      lat: 29.96154,
      lng: -93.40854,
      city: 'Hackberry',
      state: 'LA',
    },
    70646: {
      lat: 30.10767,
      lng: -92.92938,
      city: 'Hayes',
      state: 'LA',
    },
    70647: { lat: 30.24752, lng: -93.01605, city: 'Iowa', state: 'LA' },
    70648: {
      lat: 30.50568,
      lng: -92.88387,
      city: 'Kinder',
      state: 'LA',
    },
    70650: {
      lat: 30.23376,
      lng: -92.92067,
      city: 'Lacassine',
      state: 'LA',
    },
    70651: {
      lat: 30.54333,
      lng: -92.95127,
      city: 'Leblanc',
      state: 'LA',
    },
    70652: {
      lat: 30.60547,
      lng: -93.24646,
      city: 'Longville',
      state: 'LA',
    },
    70653: {
      lat: 30.66744,
      lng: -93.55994,
      city: 'Merryville',
      state: 'LA',
    },
    70654: {
      lat: 30.69212,
      lng: -92.88075,
      city: 'Mittie',
      state: 'LA',
    },
    70655: {
      lat: 30.63022,
      lng: -92.70234,
      city: 'Oberlin',
      state: 'LA',
    },
    70656: {
      lat: 30.91647,
      lng: -92.94272,
      city: 'Pitkin',
      state: 'LA',
    },
    70657: {
      lat: 30.5004,
      lng: -93.14279,
      city: 'Ragley',
      state: 'LA',
    },
    70658: {
      lat: 30.52324,
      lng: -93.03297,
      city: 'Reeves',
      state: 'LA',
    },
    70659: {
      lat: 30.92161,
      lng: -93.284,
      city: 'Rosepine',
      state: 'LA',
    },
    70660: { lat: 30.575, lng: -93.44478, city: 'Singer', state: 'LA' },
    70661: {
      lat: 30.36683,
      lng: -93.64833,
      city: 'Starks',
      state: 'LA',
    },
    70662: {
      lat: 30.80072,
      lng: -92.99834,
      city: 'Sugartown',
      state: 'LA',
    },
    70663: {
      lat: 30.2772,
      lng: -93.39086,
      city: 'Sulphur',
      state: 'LA',
    },
    70665: {
      lat: 30.12756,
      lng: -93.44555,
      city: 'Sulphur',
      state: 'LA',
    },
    70668: {
      lat: 30.17678,
      lng: -93.60488,
      city: 'Vinton',
      state: 'LA',
    },
    70669: {
      lat: 30.24216,
      lng: -93.27731,
      city: 'Westlake',
      state: 'LA',
    },
    70706: {
      lat: 30.61453,
      lng: -90.90086,
      city: 'Denham Springs',
      state: 'LA',
    },
    70710: { lat: 30.3559, lng: -91.26773, city: 'Addis', state: 'LA' },
    70711: {
      lat: 30.53065,
      lng: -90.60229,
      city: 'Albany',
      state: 'LA',
    },
    70712: {
      lat: 30.96574,
      lng: -91.58573,
      city: 'Angola',
      state: 'LA',
    },
    70714: {
      lat: 30.58634,
      lng: -91.12812,
      city: 'Baker',
      state: 'LA',
    },
    70715: {
      lat: 30.81204,
      lng: -91.66262,
      city: 'Batchelor',
      state: 'LA',
    },
    70719: {
      lat: 30.37734,
      lng: -91.29077,
      city: 'Brusly',
      state: 'LA',
    },
    70721: {
      lat: 30.21639,
      lng: -91.09301,
      city: 'Carville',
      state: 'LA',
    },
    70722: {
      lat: 30.84337,
      lng: -90.93244,
      city: 'Clinton',
      state: 'LA',
    },
    70723: {
      lat: 30.06379,
      lng: -90.84111,
      city: 'Convent',
      state: 'LA',
    },
    70725: {
      lat: 30.13652,
      lng: -90.97255,
      city: 'Darrow',
      state: 'LA',
    },
    70726: {
      lat: 30.42994,
      lng: -90.90361,
      city: 'Denham Springs',
      state: 'LA',
    },
    70729: {
      lat: 30.58891,
      lng: -91.34671,
      city: 'Erwinville',
      state: 'LA',
    },
    70730: {
      lat: 30.81588,
      lng: -91.10869,
      city: 'Ethel',
      state: 'LA',
    },
    70732: {
      lat: 30.6322,
      lng: -91.60678,
      city: 'Fordoche',
      state: 'LA',
    },
    70733: {
      lat: 30.30437,
      lng: -90.79487,
      city: 'French Settlement',
      state: 'LA',
    },
    70734: {
      lat: 30.20812,
      lng: -91.00095,
      city: 'Geismar',
      state: 'LA',
    },
    70736: {
      lat: 30.63748,
      lng: -91.33915,
      city: 'Glynn',
      state: 'LA',
    },
    70737: {
      lat: 30.22587,
      lng: -90.92271,
      city: 'Gonzales',
      state: 'LA',
    },
    70739: {
      lat: 30.60107,
      lng: -90.9684,
      city: 'Greenwell Springs',
      state: 'LA',
    },
    70740: {
      lat: 30.33777,
      lng: -91.43311,
      city: 'Grosse Tete',
      state: 'LA',
    },
    70743: {
      lat: 30.02655,
      lng: -90.7729,
      city: 'Hester',
      state: 'LA',
    },
    70744: {
      lat: 30.56173,
      lng: -90.67689,
      city: 'Holden',
      state: 'LA',
    },
    70747: {
      lat: 30.87291,
      lng: -91.68245,
      city: 'Innis',
      state: 'LA',
    },
    70748: {
      lat: 30.8164,
      lng: -91.20613,
      city: 'Jackson',
      state: 'LA',
    },
    70749: {
      lat: 30.6326,
      lng: -91.40985,
      city: 'Jarreau',
      state: 'LA',
    },
    70750: {
      lat: 30.46433,
      lng: -91.7865,
      city: 'Krotz Springs',
      state: 'LA',
    },
    70752: {
      lat: 30.57986,
      lng: -91.41915,
      city: 'Lakeland',
      state: 'LA',
    },
    70753: {
      lat: 30.7999,
      lng: -91.72106,
      city: 'Lettsworth',
      state: 'LA',
    },
    70754: {
      lat: 30.40542,
      lng: -90.74462,
      city: 'Livingston',
      state: 'LA',
    },
    70755: {
      lat: 30.59473,
      lng: -91.52981,
      city: 'Livonia',
      state: 'LA',
    },
    70756: {
      lat: 30.53256,
      lng: -91.64705,
      city: 'Lottie',
      state: 'LA',
    },
    70757: {
      lat: 30.37204,
      lng: -91.53468,
      city: 'Maringouin',
      state: 'LA',
    },
    70759: {
      lat: 30.71346,
      lng: -91.58762,
      city: 'Morganza',
      state: 'LA',
    },
    70760: {
      lat: 30.69839,
      lng: -91.48085,
      city: 'New Roads',
      state: 'LA',
    },
    70761: {
      lat: 30.97429,
      lng: -91.03129,
      city: 'Norwood',
      state: 'LA',
    },
    70762: {
      lat: 30.57843,
      lng: -91.46695,
      city: 'Oscar',
      state: 'LA',
    },
    70763: {
      lat: 30.04929,
      lng: -90.73736,
      city: 'Paulina',
      state: 'LA',
    },
    70764: {
      lat: 30.22505,
      lng: -91.30656,
      city: 'Plaquemine',
      state: 'LA',
    },
    70767: {
      lat: 30.47737,
      lng: -91.32787,
      city: 'Port Allen',
      state: 'LA',
    },
    70769: {
      lat: 30.30802,
      lng: -90.93967,
      city: 'Prairieville',
      state: 'LA',
    },
    70770: {
      lat: 30.65134,
      lng: -90.99316,
      city: 'Pride',
      state: 'LA',
    },
    70772: {
      lat: 30.43748,
      lng: -91.46484,
      city: 'Rosedale',
      state: 'LA',
    },
    70773: {
      lat: 30.59678,
      lng: -91.37333,
      city: 'Rougon',
      state: 'LA',
    },
    70774: {
      lat: 30.22741,
      lng: -90.78815,
      city: 'Saint Amant',
      state: 'LA',
    },
    70775: {
      lat: 30.85982,
      lng: -91.36847,
      city: 'Saint Francisville',
      state: 'LA',
    },
    70776: {
      lat: 30.26051,
      lng: -91.08923,
      city: 'Saint Gabriel',
      state: 'LA',
    },
    70777: {
      lat: 30.73898,
      lng: -91.07319,
      city: 'Slaughter',
      state: 'LA',
    },
    70778: {
      lat: 30.16085,
      lng: -90.81777,
      city: 'Sorrento',
      state: 'LA',
    },
    70780: {
      lat: 30.29538,
      lng: -91.16993,
      city: 'Sunshine',
      state: 'LA',
    },
    70782: {
      lat: 30.94378,
      lng: -91.51856,
      city: 'Tunica',
      state: 'LA',
    },
    70783: {
      lat: 30.67032,
      lng: -91.4087,
      city: 'Ventress',
      state: 'LA',
    },
    70785: {
      lat: 30.55621,
      lng: -90.81858,
      city: 'Walker',
      state: 'LA',
    },
    70786: {
      lat: 30.58236,
      lng: -90.95429,
      city: 'Watson',
      state: 'LA',
    },
    70787: {
      lat: 30.9629,
      lng: -91.44342,
      city: 'Weyanoke',
      state: 'LA',
    },
    70788: {
      lat: 30.13618,
      lng: -91.17939,
      city: 'White Castle',
      state: 'LA',
    },
    70789: {
      lat: 30.92973,
      lng: -91.07109,
      city: 'Wilson',
      state: 'LA',
    },
    70791: {
      lat: 30.65013,
      lng: -91.15734,
      city: 'Zachary',
      state: 'LA',
    },
    70801: {
      lat: 30.44954,
      lng: -91.18608,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70802: {
      lat: 30.44691,
      lng: -91.17554,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70803: {
      lat: 30.41165,
      lng: -91.17783,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70805: {
      lat: 30.48831,
      lng: -91.15951,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70806: {
      lat: 30.44879,
      lng: -91.1247,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70807: {
      lat: 30.5425,
      lng: -91.2068,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70808: {
      lat: 30.4057,
      lng: -91.14236,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70809: {
      lat: 30.39408,
      lng: -91.07063,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70810: {
      lat: 30.34637,
      lng: -91.07998,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70811: {
      lat: 30.53122,
      lng: -91.11617,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70812: {
      lat: 30.50018,
      lng: -91.11051,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70813: {
      lat: 30.52777,
      lng: -91.19742,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70814: {
      lat: 30.48502,
      lng: -91.06164,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70815: {
      lat: 30.45537,
      lng: -91.06648,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70816: {
      lat: 30.4288,
      lng: -91.02144,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70817: {
      lat: 30.37558,
      lng: -90.98026,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70818: {
      lat: 30.54233,
      lng: -91.05006,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70819: {
      lat: 30.47133,
      lng: -91.00748,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70820: {
      lat: 30.36812,
      lng: -91.18279,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70825: {
      lat: 30.45197,
      lng: -91.18776,
      city: 'Baton Rouge',
      state: 'LA',
    },
    70836: {
      lat: 30.38758,
      lng: -91.08357,
      city: 'Baton Rouge',
      state: 'LA',
    },
    71001: {
      lat: 32.59188,
      lng: -92.90028,
      city: 'Arcadia',
      state: 'LA',
    },
    71002: {
      lat: 32.11675,
      lng: -93.116,
      city: 'Ashland',
      state: 'LA',
    },
    71003: {
      lat: 32.62969,
      lng: -93.02787,
      city: 'Athens',
      state: 'LA',
    },
    71004: {
      lat: 32.74403,
      lng: -93.85843,
      city: 'Belcher',
      state: 'LA',
    },
    71006: {
      lat: 32.72871,
      lng: -93.63511,
      city: 'Benton',
      state: 'LA',
    },
    71007: {
      lat: 32.36854,
      lng: -94.00502,
      city: 'Bethany',
      state: 'LA',
    },
    71008: {
      lat: 32.33318,
      lng: -92.96396,
      city: 'Bienville',
      state: 'LA',
    },
    71009: {
      lat: 32.57793,
      lng: -93.89185,
      city: 'Blanchard',
      state: 'LA',
    },
    71016: {
      lat: 32.22899,
      lng: -93.10818,
      city: 'Castor',
      state: 'LA',
    },
    71018: {
      lat: 32.82178,
      lng: -93.42972,
      city: 'Cotton Valley',
      state: 'LA',
    },
    71019: {
      lat: 32.03951,
      lng: -93.30701,
      city: 'Coushatta',
      state: 'LA',
    },
    71021: {
      lat: 32.96721,
      lng: -93.44597,
      city: 'Cullen',
      state: 'LA',
    },
    71023: {
      lat: 32.4662,
      lng: -93.40797,
      city: 'Doyline',
      state: 'LA',
    },
    71024: {
      lat: 32.47897,
      lng: -93.20336,
      city: 'Dubberly',
      state: 'LA',
    },
    71027: {
      lat: 32.24953,
      lng: -93.68698,
      city: 'Frierson',
      state: 'LA',
    },
    71028: {
      lat: 32.48961,
      lng: -93.07315,
      city: 'Gibsland',
      state: 'LA',
    },
    71029: {
      lat: 32.83733,
      lng: -93.83446,
      city: 'Gilliam',
      state: 'LA',
    },
    71030: {
      lat: 32.19267,
      lng: -93.78932,
      city: 'Gloster',
      state: 'LA',
    },
    71031: {
      lat: 32.01097,
      lng: -92.89372,
      city: 'Goldonna',
      state: 'LA',
    },
    71032: {
      lat: 32.09383,
      lng: -93.79247,
      city: 'Grand Cane',
      state: 'LA',
    },
    71033: {
      lat: 32.44121,
      lng: -94.00364,
      city: 'Greenwood',
      state: 'LA',
    },
    71034: {
      lat: 32.18442,
      lng: -93.30553,
      city: 'Hall Summit',
      state: 'LA',
    },
    71037: {
      lat: 32.56359,
      lng: -93.52804,
      city: 'Haughton',
      state: 'LA',
    },
    71038: {
      lat: 32.94372,
      lng: -93.0699,
      city: 'Haynesville',
      state: 'LA',
    },
    71039: {
      lat: 32.4265,
      lng: -93.29064,
      city: 'Heflin',
      state: 'LA',
    },
    71040: {
      lat: 32.78564,
      lng: -93.01238,
      city: 'Homer',
      state: 'LA',
    },
    71043: {
      lat: 32.88789,
      lng: -93.88906,
      city: 'Hosston',
      state: 'LA',
    },
    71044: { lat: 32.96662, lng: -93.88083, city: 'Ida', state: 'LA' },
    71045: {
      lat: 32.3461,
      lng: -93.16595,
      city: 'Jamestown',
      state: 'LA',
    },
    71046: {
      lat: 32.16647,
      lng: -93.94589,
      city: 'Keatchie',
      state: 'LA',
    },
    71047: {
      lat: 32.29972,
      lng: -93.91673,
      city: 'Keithville',
      state: 'LA',
    },
    71048: {
      lat: 32.83597,
      lng: -92.85071,
      city: 'Lisbon',
      state: 'LA',
    },
    71049: {
      lat: 32.01603,
      lng: -93.96663,
      city: 'Logansport',
      state: 'LA',
    },
    71051: {
      lat: 32.3417,
      lng: -93.49639,
      city: 'Elm Grove',
      state: 'LA',
    },
    71052: {
      lat: 32.01814,
      lng: -93.66243,
      city: 'Mansfield',
      state: 'LA',
    },
    71055: {
      lat: 32.67335,
      lng: -93.29713,
      city: 'Minden',
      state: 'LA',
    },
    71060: {
      lat: 32.63933,
      lng: -93.987,
      city: 'Mooringsport',
      state: 'LA',
    },
    71061: {
      lat: 32.75342,
      lng: -93.97742,
      city: 'Oil City',
      state: 'LA',
    },
    71063: {
      lat: 31.91051,
      lng: -93.51569,
      city: 'Pelican',
      state: 'LA',
    },
    71064: {
      lat: 32.91356,
      lng: -93.68761,
      city: 'Plain Dealing',
      state: 'LA',
    },
    71065: {
      lat: 31.81028,
      lng: -93.49957,
      city: 'Pleasant Hill',
      state: 'LA',
    },
    71066: {
      lat: 31.87478,
      lng: -93.19829,
      city: 'Powhatan',
      state: 'LA',
    },
    71067: {
      lat: 32.59836,
      lng: -93.49118,
      city: 'Princeton',
      state: 'LA',
    },
    71068: {
      lat: 32.28464,
      lng: -93.32186,
      city: 'Ringgold',
      state: 'LA',
    },
    71069: {
      lat: 32.9726,
      lng: -93.9887,
      city: 'Rodessa',
      state: 'LA',
    },
    71070: {
      lat: 32.08629,
      lng: -92.99618,
      city: 'Saline',
      state: 'LA',
    },
    71071: {
      lat: 32.91747,
      lng: -93.45099,
      city: 'Sarepta',
      state: 'LA',
    },
    71072: {
      lat: 32.9373,
      lng: -93.31141,
      city: 'Shongaloo',
      state: 'LA',
    },
    71073: {
      lat: 32.5241,
      lng: -93.30547,
      city: 'Sibley',
      state: 'LA',
    },
    71075: {
      lat: 32.99349,
      lng: -93.49199,
      city: 'Springhill',
      state: 'LA',
    },
    71078: {
      lat: 32.27721,
      lng: -93.7976,
      city: 'Stonewall',
      state: 'LA',
    },
    71079: {
      lat: 32.94811,
      lng: -92.81941,
      city: 'Summerfield',
      state: 'LA',
    },
    71082: {
      lat: 32.84389,
      lng: -93.97763,
      city: 'Vivian',
      state: 'LA',
    },
    71101: {
      lat: 32.5062,
      lng: -93.74822,
      city: 'Shreveport',
      state: 'LA',
    },
    71103: {
      lat: 32.49154,
      lng: -93.7717,
      city: 'Shreveport',
      state: 'LA',
    },
    71104: {
      lat: 32.48525,
      lng: -93.73171,
      city: 'Shreveport',
      state: 'LA',
    },
    71105: {
      lat: 32.45512,
      lng: -93.71012,
      city: 'Shreveport',
      state: 'LA',
    },
    71106: {
      lat: 32.38507,
      lng: -93.73558,
      city: 'Shreveport',
      state: 'LA',
    },
    71107: {
      lat: 32.59509,
      lng: -93.85909,
      city: 'Shreveport',
      state: 'LA',
    },
    71108: {
      lat: 32.44236,
      lng: -93.78881,
      city: 'Shreveport',
      state: 'LA',
    },
    71109: {
      lat: 32.46859,
      lng: -93.814,
      city: 'Shreveport',
      state: 'LA',
    },
    71110: {
      lat: 32.50289,
      lng: -93.63027,
      city: 'Barksdale Afb',
      state: 'LA',
    },
    71111: {
      lat: 32.57904,
      lng: -93.70379,
      city: 'Bossier City',
      state: 'LA',
    },
    71112: {
      lat: 32.44373,
      lng: -93.63874,
      city: 'Bossier City',
      state: 'LA',
    },
    71115: {
      lat: 32.26472,
      lng: -93.56247,
      city: 'Shreveport',
      state: 'LA',
    },
    71118: {
      lat: 32.39262,
      lng: -93.80498,
      city: 'Shreveport',
      state: 'LA',
    },
    71119: {
      lat: 32.48542,
      lng: -93.92296,
      city: 'Shreveport',
      state: 'LA',
    },
    71129: {
      lat: 32.38697,
      lng: -93.92685,
      city: 'Shreveport',
      state: 'LA',
    },
    71201: {
      lat: 32.53915,
      lng: -92.1069,
      city: 'Monroe',
      state: 'LA',
    },
    71202: {
      lat: 32.39549,
      lng: -92.05663,
      city: 'Monroe',
      state: 'LA',
    },
    71203: {
      lat: 32.58398,
      lng: -92.01351,
      city: 'Monroe',
      state: 'LA',
    },
    71209: {
      lat: 32.53036,
      lng: -92.07206,
      city: 'Monroe',
      state: 'LA',
    },
    71212: {
      lat: 32.52725,
      lng: -92.0734,
      city: 'Monroe',
      state: 'LA',
    },
    71218: {
      lat: 32.35081,
      lng: -91.78739,
      city: 'Archibald',
      state: 'LA',
    },
    71219: {
      lat: 32.32344,
      lng: -91.6842,
      city: 'Baskin',
      state: 'LA',
    },
    71220: {
      lat: 32.8622,
      lng: -91.91236,
      city: 'Bastrop',
      state: 'LA',
    },
    71222: {
      lat: 32.82859,
      lng: -92.65918,
      city: 'Bernice',
      state: 'LA',
    },
    71223: {
      lat: 32.90084,
      lng: -91.67443,
      city: 'Bonita',
      state: 'LA',
    },
    71225: {
      lat: 32.50784,
      lng: -92.3477,
      city: 'Calhoun',
      state: 'LA',
    },
    71226: {
      lat: 32.24729,
      lng: -92.44234,
      city: 'Chatham',
      state: 'LA',
    },
    71227: {
      lat: 32.52866,
      lng: -92.48353,
      city: 'Choudrant',
      state: 'LA',
    },
    71229: {
      lat: 32.6374,
      lng: -91.89251,
      city: 'Collinston',
      state: 'LA',
    },
    71230: {
      lat: 32.2403,
      lng: -91.59269,
      city: 'Crowville',
      state: 'LA',
    },
    71232: { lat: 32.41554, lng: -91.493, city: 'Delhi', state: 'LA' },
    71233: {
      lat: 32.32509,
      lng: -90.93177,
      city: 'Delta',
      state: 'LA',
    },
    71234: {
      lat: 32.64306,
      lng: -92.33954,
      city: 'Downsville',
      state: 'LA',
    },
    71235: {
      lat: 32.68988,
      lng: -92.6726,
      city: 'Dubach',
      state: 'LA',
    },
    71237: { lat: 32.58413, lng: -91.48024, city: 'Epps', state: 'LA' },
    71238: { lat: 32.36716, lng: -92.38139, city: 'Eros', state: 'LA' },
    71241: {
      lat: 32.77107,
      lng: -92.36531,
      city: 'Farmerville',
      state: 'LA',
    },
    71242: {
      lat: 32.79223,
      lng: -91.41291,
      city: 'Forest',
      state: 'LA',
    },
    71243: {
      lat: 31.96055,
      lng: -91.80745,
      city: 'Fort Necessity',
      state: 'LA',
    },
    71245: {
      lat: 32.52697,
      lng: -92.7145,
      city: 'Grambling',
      state: 'LA',
    },
    71247: {
      lat: 32.27255,
      lng: -92.72077,
      city: 'Hodge',
      state: 'LA',
    },
    71250: {
      lat: 32.95983,
      lng: -91.57327,
      city: 'Jones',
      state: 'LA',
    },
    71251: {
      lat: 32.21891,
      lng: -92.67162,
      city: 'Jonesboro',
      state: 'LA',
    },
    71253: {
      lat: 32.99597,
      lng: -91.31576,
      city: 'Kilbourne',
      state: 'LA',
    },
    71254: {
      lat: 32.79429,
      lng: -91.23914,
      city: 'Lake Providence',
      state: 'LA',
    },
    71256: {
      lat: 32.94852,
      lng: -92.71471,
      city: 'Lillie',
      state: 'LA',
    },
    71259: {
      lat: 32.2713,
      lng: -91.84558,
      city: 'Mangham',
      state: 'LA',
    },
    71260: {
      lat: 32.90615,
      lng: -92.23793,
      city: 'Marion',
      state: 'LA',
    },
    71261: {
      lat: 32.77802,
      lng: -91.69515,
      city: 'Mer Rouge',
      state: 'LA',
    },
    71263: {
      lat: 32.87497,
      lng: -91.428,
      city: 'Oak Grove',
      state: 'LA',
    },
    71264: {
      lat: 32.6029,
      lng: -91.7734,
      city: 'Oak Ridge',
      state: 'LA',
    },
    71266: {
      lat: 32.70359,
      lng: -91.48362,
      city: 'Pioneer',
      state: 'LA',
    },
    71268: {
      lat: 32.34696,
      lng: -92.74418,
      city: 'Quitman',
      state: 'LA',
    },
    71269: {
      lat: 32.44592,
      lng: -91.78718,
      city: 'Rayville',
      state: 'LA',
    },
    71270: {
      lat: 32.49378,
      lng: -92.64139,
      city: 'Ruston',
      state: 'LA',
    },
    71272: {
      lat: 32.52928,
      lng: -92.6517,
      city: 'Ruston',
      state: 'LA',
    },
    71275: {
      lat: 32.51276,
      lng: -92.81991,
      city: 'Simsboro',
      state: 'LA',
    },
    71276: {
      lat: 32.57727,
      lng: -91.16277,
      city: 'Sondheimer',
      state: 'LA',
    },
    71277: {
      lat: 32.94902,
      lng: -92.55554,
      city: 'Spearsville',
      state: 'LA',
    },
    71279: {
      lat: 32.48667,
      lng: -91.85671,
      city: 'Start',
      state: 'LA',
    },
    71280: {
      lat: 32.72344,
      lng: -92.10389,
      city: 'Sterlington',
      state: 'LA',
    },
    71282: {
      lat: 32.35662,
      lng: -91.20042,
      city: 'Tallulah',
      state: 'LA',
    },
    71286: {
      lat: 32.65244,
      lng: -91.23397,
      city: 'Transylvania',
      state: 'LA',
    },
    71291: {
      lat: 32.56977,
      lng: -92.18277,
      city: 'West Monroe',
      state: 'LA',
    },
    71292: {
      lat: 32.40147,
      lng: -92.21569,
      city: 'West Monroe',
      state: 'LA',
    },
    71295: {
      lat: 32.14565,
      lng: -91.70795,
      city: 'Winnsboro',
      state: 'LA',
    },
    71301: {
      lat: 31.27446,
      lng: -92.46864,
      city: 'Alexandria',
      state: 'LA',
    },
    71302: {
      lat: 31.2053,
      lng: -92.36667,
      city: 'Alexandria',
      state: 'LA',
    },
    71303: {
      lat: 31.28081,
      lng: -92.54308,
      city: 'Alexandria',
      state: 'LA',
    },
    71316: { lat: 31.27665, lng: -91.76231, city: 'Acme', state: 'LA' },
    71320: {
      lat: 31.09114,
      lng: -91.76985,
      city: 'Bordelonville',
      state: 'LA',
    },
    71322: {
      lat: 30.86731,
      lng: -92.15188,
      city: 'Bunkie',
      state: 'LA',
    },
    71323: {
      lat: 31.26656,
      lng: -92.21037,
      city: 'Center Point',
      state: 'LA',
    },
    71325: {
      lat: 31.01153,
      lng: -92.32434,
      city: 'Cheneyville',
      state: 'LA',
    },
    71326: {
      lat: 31.77363,
      lng: -91.58702,
      city: 'Clayton',
      state: 'LA',
    },
    71327: {
      lat: 30.97895,
      lng: -92.03863,
      city: 'Cottonport',
      state: 'LA',
    },
    71328: {
      lat: 31.35276,
      lng: -92.19097,
      city: 'Deville',
      state: 'LA',
    },
    71330: { lat: 31.11002, lng: -92.24145, city: 'Echo', state: 'LA' },
    71331: { lat: 31.2578, lng: -92.07969, city: 'Effie', state: 'LA' },
    71333: {
      lat: 30.90315,
      lng: -92.0754,
      city: 'Evergreen',
      state: 'LA',
    },
    71334: {
      lat: 31.66978,
      lng: -91.56864,
      city: 'Ferriday',
      state: 'LA',
    },
    71336: {
      lat: 32.02126,
      lng: -91.5935,
      city: 'Gilbert',
      state: 'LA',
    },
    71339: {
      lat: 31.0058,
      lng: -91.93637,
      city: 'Hamburg',
      state: 'LA',
    },
    71340: {
      lat: 31.7553,
      lng: -91.80696,
      city: 'Harrisonburg',
      state: 'LA',
    },
    71341: {
      lat: 31.05969,
      lng: -92.16267,
      city: 'Hessmer',
      state: 'LA',
    },
    71342: { lat: 31.60042, lng: -92.1403, city: 'Jena', state: 'LA' },
    71343: {
      lat: 31.51117,
      lng: -91.90723,
      city: 'Jonesville',
      state: 'LA',
    },
    71345: {
      lat: 30.72867,
      lng: -91.97531,
      city: 'Lebeau',
      state: 'LA',
    },
    71346: {
      lat: 31.11686,
      lng: -92.38446,
      city: 'Lecompte',
      state: 'LA',
    },
    71350: {
      lat: 31.06871,
      lng: -92.06053,
      city: 'Mansura',
      state: 'LA',
    },
    71351: {
      lat: 31.17227,
      lng: -91.9604,
      city: 'Marksville',
      state: 'LA',
    },
    71353: {
      lat: 30.67949,
      lng: -91.79664,
      city: 'Melville',
      state: 'LA',
    },
    71354: {
      lat: 31.38364,
      lng: -91.73548,
      city: 'Monterey',
      state: 'LA',
    },
    71355: {
      lat: 31.05257,
      lng: -91.88531,
      city: 'Moreauville',
      state: 'LA',
    },
    71356: {
      lat: 30.83423,
      lng: -92.02988,
      city: 'Morrow',
      state: 'LA',
    },
    71357: {
      lat: 32.1084,
      lng: -91.29717,
      city: 'Newellton',
      state: 'LA',
    },
    71358: {
      lat: 30.72631,
      lng: -91.87553,
      city: 'Palmetto',
      state: 'LA',
    },
    71359: {
      lat: 31.32468,
      lng: -92.4264,
      city: 'Pineville',
      state: 'LA',
    },
    71360: {
      lat: 31.31597,
      lng: -92.34593,
      city: 'Pineville',
      state: 'LA',
    },
    71362: {
      lat: 30.88839,
      lng: -91.95225,
      city: 'Plaucheville',
      state: 'LA',
    },
    71366: {
      lat: 31.95051,
      lng: -91.35591,
      city: 'Saint Joseph',
      state: 'LA',
    },
    71367: {
      lat: 30.88784,
      lng: -92.29426,
      city: 'Saint Landry',
      state: 'LA',
    },
    71368: {
      lat: 31.86392,
      lng: -91.68494,
      city: 'Sicily Island',
      state: 'LA',
    },
    71369: {
      lat: 30.92949,
      lng: -91.85605,
      city: 'Simmesport',
      state: 'LA',
    },
    71371: { lat: 31.67542, lng: -92.2587, city: 'Trout', state: 'LA' },
    71373: {
      lat: 31.35934,
      lng: -91.58607,
      city: 'Vidalia',
      state: 'LA',
    },
    71375: {
      lat: 31.84632,
      lng: -91.47226,
      city: 'Waterproof',
      state: 'LA',
    },
    71378: {
      lat: 31.95481,
      lng: -91.71211,
      city: 'Wisner',
      state: 'LA',
    },
    71401: {
      lat: 31.79477,
      lng: -91.94461,
      city: 'Aimwell',
      state: 'LA',
    },
    71403: {
      lat: 31.21047,
      lng: -93.44061,
      city: 'Anacoco',
      state: 'LA',
    },
    71404: {
      lat: 31.75404,
      lng: -92.75257,
      city: 'Atlanta',
      state: 'LA',
    },
    71405: { lat: 31.40971, lng: -92.39771, city: 'Ball', state: 'LA' },
    71406: {
      lat: 31.73425,
      lng: -93.50536,
      city: 'Belmont',
      state: 'LA',
    },
    71407: {
      lat: 31.51837,
      lng: -92.48402,
      city: 'Bentley',
      state: 'LA',
    },
    71409: {
      lat: 31.31287,
      lng: -92.69954,
      city: 'Boyce',
      state: 'LA',
    },
    71410: {
      lat: 31.96419,
      lng: -92.77125,
      city: 'Calvin',
      state: 'LA',
    },
    71411: {
      lat: 31.90466,
      lng: -93.08894,
      city: 'Campti',
      state: 'LA',
    },
    71414: {
      lat: 31.82593,
      lng: -93.02515,
      city: 'Clarence',
      state: 'LA',
    },
    71415: {
      lat: 32.0253,
      lng: -92.14241,
      city: 'Clarks',
      state: 'LA',
    },
    71416: {
      lat: 31.53875,
      lng: -92.89558,
      city: 'Cloutierville',
      state: 'LA',
    },
    71417: {
      lat: 31.51611,
      lng: -92.65361,
      city: 'Colfax',
      state: 'LA',
    },
    71418: {
      lat: 32.13405,
      lng: -92.04589,
      city: 'Columbia',
      state: 'LA',
    },
    71419: {
      lat: 31.80188,
      lng: -93.72614,
      city: 'Converse',
      state: 'LA',
    },
    71422: {
      lat: 32.07653,
      lng: -92.65767,
      city: 'Dodson',
      state: 'LA',
    },
    71423: {
      lat: 31.60012,
      lng: -92.56341,
      city: 'Dry Prong',
      state: 'LA',
    },
    71424: {
      lat: 31.17976,
      lng: -92.69207,
      city: 'Elmer',
      state: 'LA',
    },
    71425: {
      lat: 31.89017,
      lng: -91.89786,
      city: 'Enterprise',
      state: 'LA',
    },
    71426: {
      lat: 31.49182,
      lng: -93.46165,
      city: 'Fisher',
      state: 'LA',
    },
    71427: {
      lat: 31.38879,
      lng: -92.89388,
      city: 'Flatwoods',
      state: 'LA',
    },
    71428: {
      lat: 31.60921,
      lng: -93.08855,
      city: 'Flora',
      state: 'LA',
    },
    71429: {
      lat: 31.39843,
      lng: -93.43218,
      city: 'Florien',
      state: 'LA',
    },
    71430: {
      lat: 31.06764,
      lng: -92.53135,
      city: 'Forest Hill',
      state: 'LA',
    },
    71432: {
      lat: 31.75368,
      lng: -92.47435,
      city: 'Georgetown',
      state: 'LA',
    },
    71433: {
      lat: 31.00208,
      lng: -92.64803,
      city: 'Glenmora',
      state: 'LA',
    },
    71434: { lat: 31.44618, lng: -92.944, city: 'Gorum', state: 'LA' },
    71435: {
      lat: 32.02456,
      lng: -92.1539,
      city: 'Grayson',
      state: 'LA',
    },
    71438: {
      lat: 31.10092,
      lng: -92.87511,
      city: 'Hineston',
      state: 'LA',
    },
    71439: {
      lat: 31.33707,
      lng: -93.36597,
      city: 'Hornbeck',
      state: 'LA',
    },
    71441: {
      lat: 31.94706,
      lng: -92.13805,
      city: 'Kelly',
      state: 'LA',
    },
    71446: {
      lat: 31.15585,
      lng: -93.17187,
      city: 'Leesville',
      state: 'LA',
    },
    71447: { lat: 31.43202, lng: -92.8073, city: 'Lena', state: 'LA' },
    71449: { lat: 31.5153, lng: -93.54383, city: 'Many', state: 'LA' },
    71450: {
      lat: 31.78137,
      lng: -93.40367,
      city: 'Marthaville',
      state: 'LA',
    },
    71452: {
      lat: 31.58021,
      lng: -92.9414,
      city: 'Melrose',
      state: 'LA',
    },
    71454: {
      lat: 31.69334,
      lng: -92.86131,
      city: 'Montgomery',
      state: 'LA',
    },
    71455: { lat: 31.40125, lng: -92.98074, city: 'Mora', state: 'LA' },
    71456: {
      lat: 31.63534,
      lng: -92.97429,
      city: 'Natchez',
      state: 'LA',
    },
    71457: {
      lat: 31.74045,
      lng: -93.09375,
      city: 'Natchitoches',
      state: 'LA',
    },
    71459: {
      lat: 31.07854,
      lng: -93.21723,
      city: 'Fort Johnson',
      state: 'LA',
    },
    71461: {
      lat: 31.12099,
      lng: -93.29775,
      city: 'New Llano',
      state: 'LA',
    },
    71462: { lat: 31.66625, lng: -93.7324, city: 'Noble', state: 'LA' },
    71463: {
      lat: 30.80854,
      lng: -92.64853,
      city: 'Oakdale',
      state: 'LA',
    },
    71465: { lat: 31.86499, lng: -92.19408, city: 'Olla', state: 'LA' },
    71466: { lat: 31.21593, lng: -92.74886, city: 'Otis', state: 'LA' },
    71467: {
      lat: 31.55789,
      lng: -92.39338,
      city: 'Pollock',
      state: 'LA',
    },
    71468: {
      lat: 31.48086,
      lng: -93.14518,
      city: 'Provencal',
      state: 'LA',
    },
    71469: {
      lat: 31.67755,
      lng: -93.2671,
      city: 'Robeline',
      state: 'LA',
    },
    71472: {
      lat: 31.19834,
      lng: -92.79216,
      city: 'Sieper',
      state: 'LA',
    },
    71473: {
      lat: 32.06989,
      lng: -92.42748,
      city: 'Sikes',
      state: 'LA',
    },
    71474: {
      lat: 31.27724,
      lng: -93.01394,
      city: 'Simpson',
      state: 'LA',
    },
    71475: {
      lat: 31.20123,
      lng: -93.12461,
      city: 'Slagle',
      state: 'LA',
    },
    71479: {
      lat: 31.85129,
      lng: -92.36106,
      city: 'Tullos',
      state: 'LA',
    },
    71480: {
      lat: 31.86661,
      lng: -92.28415,
      city: 'Urania',
      state: 'LA',
    },
    71483: {
      lat: 31.89482,
      lng: -92.66373,
      city: 'Winnfield',
      state: 'LA',
    },
    71485: {
      lat: 31.17811,
      lng: -92.53186,
      city: 'Woodworth',
      state: 'LA',
    },
    71486: {
      lat: 31.62679,
      lng: -93.65876,
      city: 'Zwolle',
      state: 'LA',
    },
    71497: {
      lat: 31.74902,
      lng: -93.09795,
      city: 'Natchitoches',
      state: 'LA',
    },
    71601: {
      lat: 34.17409,
      lng: -91.89811,
      city: 'Pine Bluff',
      state: 'AR',
    },
    71602: {
      lat: 34.27463,
      lng: -92.13302,
      city: 'White Hall',
      state: 'AR',
    },
    71603: {
      lat: 34.12881,
      lng: -92.08492,
      city: 'Pine Bluff',
      state: 'AR',
    },
    71630: {
      lat: 33.61827,
      lng: -91.23004,
      city: 'Arkansas City',
      state: 'AR',
    },
    71631: {
      lat: 33.58205,
      lng: -92.26415,
      city: 'Banks',
      state: 'AR',
    },
    71635: {
      lat: 33.1369,
      lng: -92.00133,
      city: 'Crossett',
      state: 'AR',
    },
    71638: {
      lat: 33.52008,
      lng: -91.47927,
      city: 'Dermott',
      state: 'AR',
    },
    71639: {
      lat: 33.89143,
      lng: -91.54414,
      city: 'Dumas',
      state: 'AR',
    },
    71640: {
      lat: 33.1253,
      lng: -91.24753,
      city: 'Eudora',
      state: 'AR',
    },
    71642: {
      lat: 33.38842,
      lng: -91.8788,
      city: 'Fountain Hill',
      state: 'AR',
    },
    71643: {
      lat: 34.01953,
      lng: -91.57945,
      city: 'Gould',
      state: 'AR',
    },
    71644: {
      lat: 34.11045,
      lng: -91.71213,
      city: 'Grady',
      state: 'AR',
    },
    71646: {
      lat: 33.20444,
      lng: -91.77837,
      city: 'Hamburg',
      state: 'AR',
    },
    71647: {
      lat: 33.36678,
      lng: -92.12219,
      city: 'Hermitage',
      state: 'AR',
    },
    71651: {
      lat: 33.31472,
      lng: -92.28377,
      city: 'Jersey',
      state: 'AR',
    },
    71652: {
      lat: 33.89338,
      lng: -92.31562,
      city: 'Kingsland',
      state: 'AR',
    },
    71653: {
      lat: 33.36919,
      lng: -91.27676,
      city: 'Lake Village',
      state: 'AR',
    },
    71654: {
      lat: 33.62476,
      lng: -91.37234,
      city: 'McGehee',
      state: 'AR',
    },
    71655: {
      lat: 33.62874,
      lng: -91.7486,
      city: 'Monticello',
      state: 'AR',
    },
    71658: {
      lat: 33.32018,
      lng: -91.57635,
      city: 'Montrose',
      state: 'AR',
    },
    71659: {
      lat: 34.13505,
      lng: -91.78071,
      city: 'Moscow',
      state: 'AR',
    },
    71660: {
      lat: 33.7627,
      lng: -92.19728,
      city: 'New Edinburg',
      state: 'AR',
    },
    71661: {
      lat: 33.14338,
      lng: -91.51333,
      city: 'Parkdale',
      state: 'AR',
    },
    71662: {
      lat: 33.82355,
      lng: -91.50391,
      city: 'Pickens',
      state: 'AR',
    },
    71663: {
      lat: 33.22755,
      lng: -91.42665,
      city: 'Portland',
      state: 'AR',
    },
    71665: {
      lat: 33.93812,
      lng: -92.13163,
      city: 'Rison',
      state: 'AR',
    },
    71666: {
      lat: 33.7372,
      lng: -91.24736,
      city: 'Rohwer',
      state: 'AR',
    },
    71667: {
      lat: 33.93588,
      lng: -91.84412,
      city: 'Star City',
      state: 'AR',
    },
    71670: {
      lat: 33.74439,
      lng: -91.40974,
      city: 'Tillar',
      state: 'AR',
    },
    71671: {
      lat: 33.60652,
      lng: -92.10006,
      city: 'Warren',
      state: 'AR',
    },
    71674: {
      lat: 33.82694,
      lng: -91.18261,
      city: 'Watson',
      state: 'AR',
    },
    71675: {
      lat: 33.60424,
      lng: -91.93805,
      city: 'Wilmar',
      state: 'AR',
    },
    71676: {
      lat: 33.05156,
      lng: -91.56023,
      city: 'Wilmot',
      state: 'AR',
    },
    71677: {
      lat: 33.76572,
      lng: -91.4884,
      city: 'Winchester',
      state: 'AR',
    },
    71678: {
      lat: 34.0254,
      lng: -91.81548,
      city: 'Yorktown',
      state: 'AR',
    },
    71701: {
      lat: 33.57837,
      lng: -92.81309,
      city: 'Camden',
      state: 'AR',
    },
    71720: {
      lat: 33.79075,
      lng: -92.64163,
      city: 'Bearden',
      state: 'AR',
    },
    71721: {
      lat: 33.89091,
      lng: -93.20635,
      city: 'Beirne',
      state: 'AR',
    },
    71722: {
      lat: 33.71045,
      lng: -93.15537,
      city: 'Bluff City',
      state: 'AR',
    },
    71724: { lat: 33.326, lng: -92.54053, city: 'Calion', state: 'AR' },
    71725: {
      lat: 34.03791,
      lng: -92.53252,
      city: 'Carthage',
      state: 'AR',
    },
    71726: {
      lat: 33.68016,
      lng: -92.99584,
      city: 'Chidester',
      state: 'AR',
    },
    71728: { lat: 34.0089, lng: -93.1053, city: 'Curtis', state: 'AR' },
    71730: {
      lat: 33.20303,
      lng: -92.63458,
      city: 'El Dorado',
      state: 'AR',
    },
    71740: {
      lat: 33.08121,
      lng: -93.16268,
      city: 'Emerson',
      state: 'AR',
    },
    71742: {
      lat: 33.83435,
      lng: -92.44545,
      city: 'Fordyce',
      state: 'AR',
    },
    71743: {
      lat: 33.88674,
      lng: -93.11724,
      city: 'Gurdon',
      state: 'AR',
    },
    71744: {
      lat: 33.49201,
      lng: -92.47536,
      city: 'Hampton',
      state: 'AR',
    },
    71745: {
      lat: 33.4997,
      lng: -92.39496,
      city: 'Harrell',
      state: 'AR',
    },
    71747: {
      lat: 33.05892,
      lng: -92.22238,
      city: 'Huttig',
      state: 'AR',
    },
    71748: { lat: 33.90296, lng: -92.43322, city: 'Ivan', state: 'AR' },
    71749: {
      lat: 33.07049,
      lng: -92.81964,
      city: 'Junction City',
      state: 'AR',
    },
    71751: {
      lat: 33.40835,
      lng: -92.77252,
      city: 'Louann',
      state: 'AR',
    },
    71752: {
      lat: 33.40061,
      lng: -93.19454,
      city: 'McNeil',
      state: 'AR',
    },
    71753: {
      lat: 33.22974,
      lng: -93.18461,
      city: 'Magnolia',
      state: 'AR',
    },
    71758: {
      lat: 33.30899,
      lng: -92.91435,
      city: 'Mount Holly',
      state: 'AR',
    },
    71759: {
      lat: 33.31506,
      lng: -92.65967,
      city: 'Norphlet',
      state: 'AR',
    },
    71762: {
      lat: 33.33668,
      lng: -92.76767,
      city: 'Smackover',
      state: 'AR',
    },
    71763: {
      lat: 33.90885,
      lng: -92.80648,
      city: 'Sparkman',
      state: 'AR',
    },
    71764: {
      lat: 33.43041,
      lng: -93.04475,
      city: 'Stephens',
      state: 'AR',
    },
    71765: {
      lat: 33.13358,
      lng: -92.33628,
      city: 'Strong',
      state: 'AR',
    },
    71766: {
      lat: 33.7362,
      lng: -92.50205,
      city: 'Thornton',
      state: 'AR',
    },
    71770: {
      lat: 33.38847,
      lng: -93.31607,
      city: 'Waldo',
      state: 'AR',
    },
    71772: {
      lat: 33.83379,
      lng: -93.12646,
      city: 'Whelen Springs',
      state: 'AR',
    },
    71801: { lat: 33.63306, lng: -93.59833, city: 'Hope', state: 'AR' },
    71820: {
      lat: 33.78886,
      lng: -94.24421,
      city: 'Alleene',
      state: 'AR',
    },
    71822: {
      lat: 33.66946,
      lng: -94.14755,
      city: 'Ashdown',
      state: 'AR',
    },
    71823: {
      lat: 33.83101,
      lng: -94.12547,
      city: 'Ben Lomond',
      state: 'AR',
    },
    71825: {
      lat: 33.87554,
      lng: -93.56489,
      city: 'Blevins',
      state: 'AR',
    },
    71826: {
      lat: 33.08994,
      lng: -93.72536,
      city: 'Bradley',
      state: 'AR',
    },
    71827: {
      lat: 33.41186,
      lng: -93.41463,
      city: 'Buckner',
      state: 'AR',
    },
    71831: {
      lat: 33.7762,
      lng: -93.85971,
      city: 'Columbus',
      state: 'AR',
    },
    71832: {
      lat: 34.0397,
      lng: -94.34791,
      city: 'De Queen',
      state: 'AR',
    },
    71833: {
      lat: 34.13409,
      lng: -94.02665,
      city: 'Dierks',
      state: 'AR',
    },
    71834: {
      lat: 33.11552,
      lng: -93.9577,
      city: 'Doddridge',
      state: 'AR',
    },
    71835: { lat: 33.66724, lng: -93.4425, city: 'Emmet', state: 'AR' },
    71836: {
      lat: 33.71297,
      lng: -94.39584,
      city: 'Foreman',
      state: 'AR',
    },
    71837: {
      lat: 33.25543,
      lng: -93.85693,
      city: 'Fouke',
      state: 'AR',
    },
    71838: {
      lat: 33.64916,
      lng: -93.79753,
      city: 'Fulton',
      state: 'AR',
    },
    71839: {
      lat: 33.30619,
      lng: -93.73891,
      city: 'Garland City',
      state: 'AR',
    },
    71841: {
      lat: 34.1639,
      lng: -94.34718,
      city: 'Gillham',
      state: 'AR',
    },
    71842: {
      lat: 33.90051,
      lng: -94.29297,
      city: 'Horatio',
      state: 'AR',
    },
    71845: {
      lat: 33.32005,
      lng: -93.63365,
      city: 'Lewisville',
      state: 'AR',
    },
    71846: {
      lat: 33.9265,
      lng: -94.12367,
      city: 'Lockesburg',
      state: 'AR',
    },
    71847: {
      lat: 33.92616,
      lng: -93.63361,
      city: 'McCaskill',
      state: 'AR',
    },
    71851: {
      lat: 33.85728,
      lng: -93.94658,
      city: 'Mineral Springs',
      state: 'AR',
    },
    71852: {
      lat: 33.99489,
      lng: -93.85831,
      city: 'Nashville',
      state: 'AR',
    },
    71853: {
      lat: 33.59442,
      lng: -93.95635,
      city: 'Ogden',
      state: 'AR',
    },
    71854: {
      lat: 33.44445,
      lng: -93.90504,
      city: 'Texarkana',
      state: 'AR',
    },
    71855: { lat: 33.86612, lng: -93.74351, city: 'Ozan', state: 'AR' },
    71857: {
      lat: 33.80422,
      lng: -93.34795,
      city: 'Prescott',
      state: 'AR',
    },
    71858: {
      lat: 33.58543,
      lng: -93.26706,
      city: 'Rosston',
      state: 'AR',
    },
    71859: {
      lat: 33.7143,
      lng: -93.92379,
      city: 'Saratoga',
      state: 'AR',
    },
    71860: { lat: 33.307, lng: -93.49286, city: 'Stamps', state: 'AR' },
    71861: { lat: 33.1039, lng: -93.4941, city: 'Taylor', state: 'AR' },
    71862: {
      lat: 33.76814,
      lng: -93.72321,
      city: 'Washington',
      state: 'AR',
    },
    71864: {
      lat: 33.50997,
      lng: -93.28868,
      city: 'Willisville',
      state: 'AR',
    },
    71865: {
      lat: 33.74169,
      lng: -94.14598,
      city: 'Wilton',
      state: 'AR',
    },
    71866: {
      lat: 33.86738,
      lng: -94.38042,
      city: 'Winthrop',
      state: 'AR',
    },
    71901: {
      lat: 34.52682,
      lng: -92.97247,
      city: 'Hot Springs National Park',
      state: 'AR',
    },
    71909: {
      lat: 34.64106,
      lng: -92.99608,
      city: 'Hot Springs Village',
      state: 'AR',
    },
    71913: {
      lat: 34.44677,
      lng: -93.09981,
      city: 'Hot Springs National Park',
      state: 'AR',
    },
    71921: {
      lat: 34.25435,
      lng: -93.40109,
      city: 'Amity',
      state: 'AR',
    },
    71922: {
      lat: 34.02444,
      lng: -93.41943,
      city: 'Antoine',
      state: 'AR',
    },
    71923: {
      lat: 34.08424,
      lng: -93.05551,
      city: 'Arkadelphia',
      state: 'AR',
    },
    71929: {
      lat: 34.30527,
      lng: -93.1553,
      city: 'Bismarck',
      state: 'AR',
    },
    71933: {
      lat: 34.41037,
      lng: -93.39124,
      city: 'Bonnerdale',
      state: 'AR',
    },
    71935: {
      lat: 34.39338,
      lng: -93.75009,
      city: 'Caddo Gap',
      state: 'AR',
    },
    71937: { lat: 34.40177, lng: -94.38751, city: 'Cove', state: 'AR' },
    71940: {
      lat: 34.01527,
      lng: -93.50705,
      city: 'Delight',
      state: 'AR',
    },
    71941: {
      lat: 34.2487,
      lng: -92.95312,
      city: 'Donaldson',
      state: 'AR',
    },
    71942: {
      lat: 34.22124,
      lng: -92.99429,
      city: 'Friendship',
      state: 'AR',
    },
    71943: {
      lat: 34.33488,
      lng: -93.60633,
      city: 'Glenwood',
      state: 'AR',
    },
    71944: {
      lat: 34.23922,
      lng: -94.32517,
      city: 'Grannis',
      state: 'AR',
    },
    71945: {
      lat: 34.49826,
      lng: -94.38492,
      city: 'Hatfield',
      state: 'AR',
    },
    71949: {
      lat: 34.68938,
      lng: -93.21552,
      city: 'Jessieville',
      state: 'AR',
    },
    71950: {
      lat: 34.25072,
      lng: -93.72767,
      city: 'Kirby',
      state: 'AR',
    },
    71952: {
      lat: 34.31067,
      lng: -93.84992,
      city: 'Langley',
      state: 'AR',
    },
    71953: { lat: 34.61291, lng: -94.2118, city: 'Mena', state: 'AR' },
    71956: {
      lat: 34.60129,
      lng: -93.14977,
      city: 'Mountain Pine',
      state: 'AR',
    },
    71957: {
      lat: 34.55799,
      lng: -93.59682,
      city: 'Mount Ida',
      state: 'AR',
    },
    71958: {
      lat: 34.1114,
      lng: -93.6556,
      city: 'Murfreesboro',
      state: 'AR',
    },
    71959: {
      lat: 34.25081,
      lng: -93.92633,
      city: 'Newhope',
      state: 'AR',
    },
    71960: {
      lat: 34.47408,
      lng: -93.69281,
      city: 'Norman',
      state: 'AR',
    },
    71961: { lat: 34.59722, lng: -93.84676, city: 'Oden', state: 'AR' },
    71962: {
      lat: 34.03756,
      lng: -93.33288,
      city: 'Okolona',
      state: 'AR',
    },
    71964: {
      lat: 34.42252,
      lng: -93.24451,
      city: 'Pearcy',
      state: 'AR',
    },
    71965: {
      lat: 34.65506,
      lng: -93.74053,
      city: 'Pencil Bluff',
      state: 'AR',
    },
    71968: {
      lat: 34.52719,
      lng: -93.29767,
      city: 'Royal',
      state: 'AR',
    },
    71969: { lat: 34.66671, lng: -93.63442, city: 'Sims', state: 'AR' },
    71970: {
      lat: 34.66686,
      lng: -93.49024,
      city: 'Story',
      state: 'AR',
    },
    71971: {
      lat: 34.30411,
      lng: -94.0313,
      city: 'Umpire',
      state: 'AR',
    },
    71972: {
      lat: 34.39447,
      lng: -94.24695,
      city: 'Vandervoort',
      state: 'AR',
    },
    71973: {
      lat: 34.28755,
      lng: -94.30523,
      city: 'Wickes',
      state: 'AR',
    },
    71998: {
      lat: 34.12609,
      lng: -93.05275,
      city: 'Arkadelphia',
      state: 'AR',
    },
    71999: {
      lat: 34.12732,
      lng: -93.05941,
      city: 'Arkadelphia',
      state: 'AR',
    },
    72001: {
      lat: 35.07225,
      lng: -92.87602,
      city: 'Adona',
      state: 'AR',
    },
    72002: {
      lat: 34.6646,
      lng: -92.5362,
      city: 'Alexander',
      state: 'AR',
    },
    72003: {
      lat: 34.38276,
      lng: -91.40754,
      city: 'Almyra',
      state: 'AR',
    },
    72004: {
      lat: 34.29722,
      lng: -91.7856,
      city: 'Altheimer',
      state: 'AR',
    },
    72005: {
      lat: 35.53911,
      lng: -91.07271,
      city: 'Amagon',
      state: 'AR',
    },
    72006: {
      lat: 35.24602,
      lng: -91.34495,
      city: 'Augusta',
      state: 'AR',
    },
    72007: {
      lat: 35.0004,
      lng: -91.97902,
      city: 'Austin',
      state: 'AR',
    },
    72010: {
      lat: 35.30555,
      lng: -91.52112,
      city: 'Bald Knob',
      state: 'AR',
    },
    72011: {
      lat: 34.50967,
      lng: -92.46628,
      city: 'Bauxite',
      state: 'AR',
    },
    72012: {
      lat: 35.10199,
      lng: -91.91789,
      city: 'Beebe',
      state: 'AR',
    },
    72013: {
      lat: 35.45709,
      lng: -92.3644,
      city: 'Bee Branch',
      state: 'AR',
    },
    72014: {
      lat: 35.43029,
      lng: -91.11213,
      city: 'Beedeville',
      state: 'AR',
    },
    72015: {
      lat: 34.49298,
      lng: -92.58974,
      city: 'Benton',
      state: 'AR',
    },
    72016: {
      lat: 34.97702,
      lng: -92.62763,
      city: 'Bigelow',
      state: 'AR',
    },
    72017: {
      lat: 34.89215,
      lng: -91.40714,
      city: 'Biscoe',
      state: 'AR',
    },
    72019: {
      lat: 34.63498,
      lng: -92.69926,
      city: 'Benton',
      state: 'AR',
    },
    72020: {
      lat: 35.48054,
      lng: -91.47669,
      city: 'Bradford',
      state: 'AR',
    },
    72021: {
      lat: 34.85953,
      lng: -91.21193,
      city: 'Brinkley',
      state: 'AR',
    },
    72022: {
      lat: 34.60859,
      lng: -92.49651,
      city: 'Bryant',
      state: 'AR',
    },
    72023: {
      lat: 34.95909,
      lng: -92.05925,
      city: 'Cabot',
      state: 'AR',
    },
    72024: {
      lat: 34.75168,
      lng: -91.74902,
      city: 'Carlisle',
      state: 'AR',
    },
    72025: { lat: 35.0551, lng: -92.99604, city: 'Casa', state: 'AR' },
    72026: {
      lat: 34.51397,
      lng: -91.31687,
      city: 'Casscoe',
      state: 'AR',
    },
    72027: {
      lat: 35.37021,
      lng: -92.57494,
      city: 'Center Ridge',
      state: 'AR',
    },
    72028: {
      lat: 35.53771,
      lng: -92.43266,
      city: 'Choctaw',
      state: 'AR',
    },
    72029: {
      lat: 34.71029,
      lng: -91.27679,
      city: 'Clarendon',
      state: 'AR',
    },
    72030: {
      lat: 35.43428,
      lng: -92.68003,
      city: 'Cleveland',
      state: 'AR',
    },
    72031: {
      lat: 35.60717,
      lng: -92.52904,
      city: 'Clinton',
      state: 'AR',
    },
    72032: {
      lat: 35.07393,
      lng: -92.36451,
      city: 'Conway',
      state: 'AR',
    },
    72034: {
      lat: 35.05405,
      lng: -92.47945,
      city: 'Conway',
      state: 'AR',
    },
    72035: {
      lat: 35.07902,
      lng: -92.45829,
      city: 'Conway',
      state: 'AR',
    },
    72036: {
      lat: 35.00673,
      lng: -91.27205,
      city: 'Cotton Plant',
      state: 'AR',
    },
    72038: {
      lat: 34.44606,
      lng: -91.2349,
      city: 'Crocketts Bluff',
      state: 'AR',
    },
    72039: {
      lat: 35.35636,
      lng: -92.40338,
      city: 'Damascus',
      state: 'AR',
    },
    72040: {
      lat: 34.96957,
      lng: -91.52129,
      city: 'Des Arc',
      state: 'AR',
    },
    72041: {
      lat: 34.73348,
      lng: -91.46812,
      city: 'De Valls Bluff',
      state: 'AR',
    },
    72042: {
      lat: 34.26937,
      lng: -91.31567,
      city: 'De Witt',
      state: 'AR',
    },
    72044: {
      lat: 35.67688,
      lng: -92.16243,
      city: 'Edgemont',
      state: 'AR',
    },
    72045: {
      lat: 35.12618,
      lng: -92.05483,
      city: 'El Paso',
      state: 'AR',
    },
    72046: {
      lat: 34.55542,
      lng: -91.9403,
      city: 'England',
      state: 'AR',
    },
    72047: {
      lat: 35.22809,
      lng: -92.21055,
      city: 'Enola',
      state: 'AR',
    },
    72048: {
      lat: 34.23436,
      lng: -91.12433,
      city: 'Ethel',
      state: 'AR',
    },
    72051: { lat: 35.79475, lng: -92.31501, city: 'Fox', state: 'AR' },
    72052: {
      lat: 35.13965,
      lng: -91.78097,
      city: 'Garner',
      state: 'AR',
    },
    72053: {
      lat: 34.70579,
      lng: -92.22852,
      city: 'College Station',
      state: 'AR',
    },
    72055: {
      lat: 34.10391,
      lng: -91.36196,
      city: 'Gillett',
      state: 'AR',
    },
    72057: {
      lat: 34.14417,
      lng: -92.31507,
      city: 'Grapevine',
      state: 'AR',
    },
    72058: {
      lat: 35.24273,
      lng: -92.36998,
      city: 'Greenbrier',
      state: 'AR',
    },
    72059: {
      lat: 35.15455,
      lng: -91.34209,
      city: 'Gregory',
      state: 'AR',
    },
    72060: {
      lat: 35.07609,
      lng: -91.57779,
      city: 'Griffithville',
      state: 'AR',
    },
    72061: { lat: 35.32437, lng: -92.28992, city: 'Guy', state: 'AR' },
    72063: {
      lat: 35.32205,
      lng: -92.77249,
      city: 'Hattieville',
      state: 'AR',
    },
    72064: {
      lat: 34.79457,
      lng: -91.61226,
      city: 'Hazen',
      state: 'AR',
    },
    72065: {
      lat: 34.52102,
      lng: -92.28648,
      city: 'Hensley',
      state: 'AR',
    },
    72066: {
      lat: 34.98141,
      lng: -91.7171,
      city: 'Hickory Plains',
      state: 'AR',
    },
    72067: {
      lat: 35.56369,
      lng: -92.16589,
      city: 'Higden',
      state: 'AR',
    },
    72068: {
      lat: 35.15766,
      lng: -91.71547,
      city: 'Higginson',
      state: 'AR',
    },
    72069: {
      lat: 34.53904,
      lng: -91.13242,
      city: 'Holly Grove',
      state: 'AR',
    },
    72070: {
      lat: 35.01346,
      lng: -92.6995,
      city: 'Houston',
      state: 'AR',
    },
    72072: {
      lat: 34.51279,
      lng: -91.75802,
      city: 'Humnoke',
      state: 'AR',
    },
    72073: {
      lat: 34.36529,
      lng: -91.65251,
      city: 'Humphrey',
      state: 'AR',
    },
    72074: {
      lat: 35.06919,
      lng: -91.10538,
      city: 'Hunter',
      state: 'AR',
    },
    72075: {
      lat: 35.64084,
      lng: -91.31089,
      city: 'Jacksonport',
      state: 'AR',
    },
    72076: {
      lat: 34.91072,
      lng: -92.14303,
      city: 'Jacksonville',
      state: 'AR',
    },
    72079: {
      lat: 34.38958,
      lng: -92.19919,
      city: 'Jefferson',
      state: 'AR',
    },
    72080: {
      lat: 35.52604,
      lng: -92.80909,
      city: 'Jerusalem',
      state: 'AR',
    },
    72081: {
      lat: 35.37827,
      lng: -91.66947,
      city: 'Judsonia',
      state: 'AR',
    },
    72082: {
      lat: 35.23713,
      lng: -91.66963,
      city: 'Kensett',
      state: 'AR',
    },
    72083: { lat: 34.6049, lng: -92.01189, city: 'Keo', state: 'AR' },
    72084: { lat: 34.13687, lng: -92.6887, city: 'Leola', state: 'AR' },
    72085: {
      lat: 35.36665,
      lng: -91.82772,
      city: 'Letona',
      state: 'AR',
    },
    72086: {
      lat: 34.80418,
      lng: -91.90619,
      city: 'Lonoke',
      state: 'AR',
    },
    72087: {
      lat: 34.59877,
      lng: -92.83391,
      city: 'Lonsdale',
      state: 'AR',
    },
    72088: {
      lat: 35.59803,
      lng: -92.26239,
      city: 'Fairfield Bay',
      state: 'AR',
    },
    72099: {
      lat: 34.90103,
      lng: -92.15202,
      city: 'Little Rock Air Force Base',
      state: 'AR',
    },
    72101: {
      lat: 35.23228,
      lng: -91.15242,
      city: 'McCrory',
      state: 'AR',
    },
    72102: {
      lat: 35.14556,
      lng: -91.83352,
      city: 'McRae',
      state: 'AR',
    },
    72103: {
      lat: 34.59133,
      lng: -92.38316,
      city: 'Mabelvale',
      state: 'AR',
    },
    72104: {
      lat: 34.34774,
      lng: -92.81814,
      city: 'Malvern',
      state: 'AR',
    },
    72106: {
      lat: 34.96997,
      lng: -92.48727,
      city: 'Mayflower',
      state: 'AR',
    },
    72107: {
      lat: 35.14255,
      lng: -92.53844,
      city: 'Menifee',
      state: 'AR',
    },
    72108: {
      lat: 34.72803,
      lng: -91.10349,
      city: 'Monroe',
      state: 'AR',
    },
    72110: {
      lat: 35.15514,
      lng: -92.77273,
      city: 'Morrilton',
      state: 'AR',
    },
    72111: {
      lat: 35.23688,
      lng: -92.13307,
      city: 'Mount Vernon',
      state: 'AR',
    },
    72112: {
      lat: 35.57383,
      lng: -91.22918,
      city: 'Newport',
      state: 'AR',
    },
    72113: {
      lat: 34.85926,
      lng: -92.39875,
      city: 'Maumelle',
      state: 'AR',
    },
    72114: {
      lat: 34.7645,
      lng: -92.26024,
      city: 'North Little Rock',
      state: 'AR',
    },
    72116: {
      lat: 34.80025,
      lng: -92.24505,
      city: 'North Little Rock',
      state: 'AR',
    },
    72117: {
      lat: 34.78076,
      lng: -92.14548,
      city: 'North Little Rock',
      state: 'AR',
    },
    72118: {
      lat: 34.83872,
      lng: -92.32694,
      city: 'North Little Rock',
      state: 'AR',
    },
    72119: {
      lat: 34.8369,
      lng: -92.29505,
      city: 'North Little Rock',
      state: 'AR',
    },
    72120: {
      lat: 34.89776,
      lng: -92.24431,
      city: 'Sherwood',
      state: 'AR',
    },
    72121: {
      lat: 35.45561,
      lng: -91.78525,
      city: 'Pangburn',
      state: 'AR',
    },
    72122: {
      lat: 34.77955,
      lng: -92.77549,
      city: 'Paron',
      state: 'AR',
    },
    72123: {
      lat: 35.25774,
      lng: -91.23675,
      city: 'Patterson',
      state: 'AR',
    },
    72125: { lat: 35.06421, lng: -92.794, city: 'Perry', state: 'AR' },
    72126: {
      lat: 34.9376,
      lng: -92.91024,
      city: 'Perryville',
      state: 'AR',
    },
    72127: {
      lat: 35.16237,
      lng: -92.59419,
      city: 'Plumerville',
      state: 'AR',
    },
    72128: {
      lat: 34.31535,
      lng: -92.63846,
      city: 'Poyen',
      state: 'AR',
    },
    72129: {
      lat: 34.31215,
      lng: -92.55406,
      city: 'Prattsville',
      state: 'AR',
    },
    72130: { lat: 35.6673, lng: -92.06997, city: 'Prim', state: 'AR' },
    72131: {
      lat: 35.40792,
      lng: -92.19362,
      city: 'Quitman',
      state: 'AR',
    },
    72132: {
      lat: 34.44,
      lng: -92.19468,
      city: 'Redfield',
      state: 'AR',
    },
    72133: {
      lat: 34.10821,
      lng: -91.53718,
      city: 'Reydell',
      state: 'AR',
    },
    72134: { lat: 34.61189, lng: -91.37247, city: 'Roe', state: 'AR' },
    72135: {
      lat: 34.87698,
      lng: -92.56087,
      city: 'Roland',
      state: 'AR',
    },
    72136: {
      lat: 35.23538,
      lng: -92.02129,
      city: 'Romance',
      state: 'AR',
    },
    72137: {
      lat: 35.33756,
      lng: -92.02933,
      city: 'Rose Bud',
      state: 'AR',
    },
    72139: {
      lat: 35.36212,
      lng: -91.50997,
      city: 'Russell',
      state: 'AR',
    },
    72140: {
      lat: 34.36052,
      lng: -91.1549,
      city: 'Saint Charles',
      state: 'AR',
    },
    72141: {
      lat: 35.51824,
      lng: -92.69158,
      city: 'Scotland',
      state: 'AR',
    },
    72142: {
      lat: 34.67681,
      lng: -92.07488,
      city: 'Scott',
      state: 'AR',
    },
    72143: {
      lat: 35.2279,
      lng: -91.73613,
      city: 'Searcy',
      state: 'AR',
    },
    72150: {
      lat: 34.30999,
      lng: -92.39642,
      city: 'Sheridan',
      state: 'AR',
    },
    72152: {
      lat: 34.35194,
      lng: -91.98009,
      city: 'Sherrill',
      state: 'AR',
    },
    72153: {
      lat: 35.65651,
      lng: -92.31988,
      city: 'Shirley',
      state: 'AR',
    },
    72156: {
      lat: 35.28595,
      lng: -92.68331,
      city: 'Solgohachia',
      state: 'AR',
    },
    72157: {
      lat: 35.2714,
      lng: -92.56412,
      city: 'Springfield',
      state: 'AR',
    },
    72160: {
      lat: 34.44284,
      lng: -91.53136,
      city: 'Stuttgart',
      state: 'AR',
    },
    72165: {
      lat: 35.57657,
      lng: -91.46647,
      city: 'Thida',
      state: 'AR',
    },
    72166: {
      lat: 34.04821,
      lng: -91.23711,
      city: 'Tichnor',
      state: 'AR',
    },
    72167: {
      lat: 34.42223,
      lng: -92.65477,
      city: 'Traskwood',
      state: 'AR',
    },
    72168: {
      lat: 34.43719,
      lng: -91.96771,
      city: 'Tucker',
      state: 'AR',
    },
    72169: {
      lat: 35.3946,
      lng: -91.22703,
      city: 'Tupelo',
      state: 'AR',
    },
    72170: { lat: 34.57819, lng: -91.42655, city: 'Ulm', state: 'AR' },
    72173: {
      lat: 35.10001,
      lng: -92.20807,
      city: 'Vilonia',
      state: 'AR',
    },
    72175: {
      lat: 34.33792,
      lng: -91.75495,
      city: 'Wabbaseka',
      state: 'AR',
    },
    72176: { lat: 35.00279, lng: -91.84212, city: 'Ward', state: 'AR' },
    72178: {
      lat: 35.20381,
      lng: -91.61265,
      city: 'West Point',
      state: 'AR',
    },
    72179: {
      lat: 35.49773,
      lng: -91.86565,
      city: 'Wilburn',
      state: 'AR',
    },
    72180: {
      lat: 34.53104,
      lng: -92.21495,
      city: 'Woodson',
      state: 'AR',
    },
    72181: {
      lat: 35.16237,
      lng: -92.44503,
      city: 'Wooster',
      state: 'AR',
    },
    72182: {
      lat: 34.43161,
      lng: -92.08721,
      city: 'Wright',
      state: 'AR',
    },
    72183: {
      lat: 34.60002,
      lng: -92.19305,
      city: 'Wrightsville',
      state: 'AR',
    },
    72199: {
      lat: 34.82816,
      lng: -92.28608,
      city: 'North Little Rock',
      state: 'AR',
    },
    72201: {
      lat: 34.74725,
      lng: -92.28053,
      city: 'Little Rock',
      state: 'AR',
    },
    72202: {
      lat: 34.74626,
      lng: -92.27016,
      city: 'Little Rock',
      state: 'AR',
    },
    72204: {
      lat: 34.71739,
      lng: -92.3582,
      city: 'Little Rock',
      state: 'AR',
    },
    72205: {
      lat: 34.74948,
      lng: -92.3524,
      city: 'Little Rock',
      state: 'AR',
    },
    72206: {
      lat: 34.63042,
      lng: -92.23833,
      city: 'Little Rock',
      state: 'AR',
    },
    72207: {
      lat: 34.77493,
      lng: -92.34271,
      city: 'Little Rock',
      state: 'AR',
    },
    72209: {
      lat: 34.6805,
      lng: -92.33974,
      city: 'Little Rock',
      state: 'AR',
    },
    72210: {
      lat: 34.71506,
      lng: -92.50378,
      city: 'Little Rock',
      state: 'AR',
    },
    72211: {
      lat: 34.74792,
      lng: -92.41675,
      city: 'Little Rock',
      state: 'AR',
    },
    72212: {
      lat: 34.78498,
      lng: -92.4141,
      city: 'Little Rock',
      state: 'AR',
    },
    72223: {
      lat: 34.79251,
      lng: -92.49091,
      city: 'Little Rock',
      state: 'AR',
    },
    72227: {
      lat: 34.7782,
      lng: -92.37498,
      city: 'Little Rock',
      state: 'AR',
    },
    72301: {
      lat: 35.13363,
      lng: -90.17883,
      city: 'West Memphis',
      state: 'AR',
    },
    72311: {
      lat: 34.72072,
      lng: -90.89688,
      city: 'Aubrey',
      state: 'AR',
    },
    72313: {
      lat: 35.53077,
      lng: -90.13952,
      city: 'Bassett',
      state: 'AR',
    },
    72315: {
      lat: 35.90093,
      lng: -89.9029,
      city: 'Blytheville',
      state: 'AR',
    },
    72320: {
      lat: 34.81439,
      lng: -90.5411,
      city: 'Brickeys',
      state: 'AR',
    },
    72322: {
      lat: 35.0688,
      lng: -90.808,
      city: 'Caldwell',
      state: 'AR',
    },
    72324: {
      lat: 35.38872,
      lng: -90.78116,
      city: 'Cherry Valley',
      state: 'AR',
    },
    72325: {
      lat: 35.33067,
      lng: -90.25395,
      city: 'Clarkedale',
      state: 'AR',
    },
    72326: { lat: 35.11183, lng: -90.89333, city: 'Colt', state: 'AR' },
    72327: {
      lat: 35.2401,
      lng: -90.32783,
      city: 'Crawfordsville',
      state: 'AR',
    },
    72328: {
      lat: 34.0684,
      lng: -91.0057,
      city: 'Crumrod',
      state: 'AR',
    },
    72329: {
      lat: 35.62386,
      lng: -89.97143,
      city: 'Driver',
      state: 'AR',
    },
    72330: {
      lat: 35.59136,
      lng: -90.20184,
      city: 'Dyess',
      state: 'AR',
    },
    72331: {
      lat: 35.28537,
      lng: -90.46733,
      city: 'Earle',
      state: 'AR',
    },
    72332: {
      lat: 35.10117,
      lng: -90.30238,
      city: 'Edmondson',
      state: 'AR',
    },
    72333: {
      lat: 34.29004,
      lng: -90.86971,
      city: 'Elaine',
      state: 'AR',
    },
    72335: {
      lat: 34.99042,
      lng: -90.75645,
      city: 'Forrest City',
      state: 'AR',
    },
    72338: {
      lat: 35.43657,
      lng: -90.13863,
      city: 'Frenchmans Bayou',
      state: 'AR',
    },
    72339: {
      lat: 35.41606,
      lng: -90.26492,
      city: 'Gilmore',
      state: 'AR',
    },
    72340: {
      lat: 34.95687,
      lng: -91.03202,
      city: 'Goodwin',
      state: 'AR',
    },
    72341: {
      lat: 34.88746,
      lng: -90.7705,
      city: 'Haynes',
      state: 'AR',
    },
    72342: {
      lat: 34.44492,
      lng: -90.67733,
      city: 'Helena',
      state: 'AR',
    },
    72346: { lat: 35.08462, lng: -90.48198, city: 'Heth', state: 'AR' },
    72347: {
      lat: 35.38415,
      lng: -90.99892,
      city: 'Hickory Ridge',
      state: 'AR',
    },
    72348: {
      lat: 34.93739,
      lng: -90.42016,
      city: 'Hughes',
      state: 'AR',
    },
    72350: {
      lat: 35.50181,
      lng: -90.11782,
      city: 'Joiner',
      state: 'AR',
    },
    72351: {
      lat: 35.67595,
      lng: -90.09771,
      city: 'Keiser',
      state: 'AR',
    },
    72352: {
      lat: 34.65562,
      lng: -90.7344,
      city: 'La Grange',
      state: 'AR',
    },
    72353: {
      lat: 34.3162,
      lng: -91.00708,
      city: 'Lambrook',
      state: 'AR',
    },
    72354: {
      lat: 35.63118,
      lng: -90.31797,
      city: 'Lepanto',
      state: 'AR',
    },
    72355: { lat: 34.53902, lng: -90.77789, city: 'Lexa', state: 'AR' },
    72358: {
      lat: 35.79446,
      lng: -89.87723,
      city: 'Luxora',
      state: 'AR',
    },
    72359: {
      lat: 35.01422,
      lng: -90.7239,
      city: 'Madison',
      state: 'AR',
    },
    72360: {
      lat: 34.75352,
      lng: -90.78875,
      city: 'Marianna',
      state: 'AR',
    },
    72364: {
      lat: 35.2126,
      lng: -90.18573,
      city: 'Marion',
      state: 'AR',
    },
    72365: {
      lat: 35.56181,
      lng: -90.41857,
      city: 'Marked Tree',
      state: 'AR',
    },
    72366: {
      lat: 34.52612,
      lng: -90.95307,
      city: 'Marvell',
      state: 'AR',
    },
    72367: {
      lat: 34.1891,
      lng: -91.02567,
      city: 'Mellwood',
      state: 'AR',
    },
    72368: { lat: 34.81146, lng: -91.01996, city: 'Moro', state: 'AR' },
    72369: {
      lat: 34.45522,
      lng: -90.78954,
      city: 'Oneida',
      state: 'AR',
    },
    72370: {
      lat: 35.68443,
      lng: -90.07734,
      city: 'Osceola',
      state: 'AR',
    },
    72372: {
      lat: 34.96623,
      lng: -90.94726,
      city: 'Palestine',
      state: 'AR',
    },
    72373: {
      lat: 35.28861,
      lng: -90.60269,
      city: 'Parkin',
      state: 'AR',
    },
    72374: {
      lat: 34.56126,
      lng: -90.83996,
      city: 'Poplar Grove',
      state: 'AR',
    },
    72376: {
      lat: 35.0883,
      lng: -90.30625,
      city: 'Proctor',
      state: 'AR',
    },
    72377: {
      lat: 35.67392,
      lng: -90.34158,
      city: 'Rivervale',
      state: 'AR',
    },
    72379: {
      lat: 34.07479,
      lng: -91.05019,
      city: 'Snow Lake',
      state: 'AR',
    },
    72383: {
      lat: 34.49117,
      lng: -91.03962,
      city: 'Turner',
      state: 'AR',
    },
    72384: {
      lat: 35.37487,
      lng: -90.21825,
      city: 'Turrell',
      state: 'AR',
    },
    72386: {
      lat: 35.45548,
      lng: -90.36183,
      city: 'Tyronza',
      state: 'AR',
    },
    72387: {
      lat: 35.31572,
      lng: -90.77274,
      city: 'Vanndale',
      state: 'AR',
    },
    72389: {
      lat: 34.36985,
      lng: -90.88596,
      city: 'Wabash',
      state: 'AR',
    },
    72390: {
      lat: 34.55426,
      lng: -90.67756,
      city: 'West Helena',
      state: 'AR',
    },
    72391: {
      lat: 35.6765,
      lng: -90.26953,
      city: 'West Ridge',
      state: 'AR',
    },
    72392: {
      lat: 34.95551,
      lng: -91.09466,
      city: 'Wheatley',
      state: 'AR',
    },
    72394: {
      lat: 35.05271,
      lng: -90.61599,
      city: 'Widener',
      state: 'AR',
    },
    72395: {
      lat: 35.59149,
      lng: -90.02036,
      city: 'Wilson',
      state: 'AR',
    },
    72396: {
      lat: 35.22966,
      lng: -90.83559,
      city: 'Wynne',
      state: 'AR',
    },
    72401: {
      lat: 35.86835,
      lng: -90.66193,
      city: 'Jonesboro',
      state: 'AR',
    },
    72404: {
      lat: 35.77656,
      lng: -90.78356,
      city: 'Jonesboro',
      state: 'AR',
    },
    72405: {
      lat: 35.90446,
      lng: -90.64869,
      city: 'Jonesboro',
      state: 'AR',
    },
    72410: {
      lat: 35.93854,
      lng: -91.09432,
      city: 'Alicia',
      state: 'AR',
    },
    72411: { lat: 35.74165, lng: -90.58019, city: 'Bay', state: 'AR' },
    72412: {
      lat: 36.12572,
      lng: -90.69172,
      city: 'Beech Grove',
      state: 'AR',
    },
    72413: {
      lat: 36.30564,
      lng: -90.81251,
      city: 'Biggers',
      state: 'AR',
    },
    72414: {
      lat: 35.82288,
      lng: -90.368,
      city: 'Black Oak',
      state: 'AR',
    },
    72415: {
      lat: 36.12643,
      lng: -91.17745,
      city: 'Black Rock',
      state: 'AR',
    },
    72416: { lat: 35.95731, lng: -90.79699, city: 'Bono', state: 'AR' },
    72417: {
      lat: 35.91994,
      lng: -90.54791,
      city: 'Brookland',
      state: 'AR',
    },
    72419: {
      lat: 35.74158,
      lng: -90.35544,
      city: 'Caraway',
      state: 'AR',
    },
    72421: { lat: 35.77851, lng: -90.97869, city: 'Cash', state: 'AR' },
    72422: {
      lat: 36.41893,
      lng: -90.54385,
      city: 'Corning',
      state: 'AR',
    },
    72424: {
      lat: 36.39185,
      lng: -90.72874,
      city: 'Datto',
      state: 'AR',
    },
    72425: {
      lat: 36.22138,
      lng: -90.74308,
      city: 'Delaplaine',
      state: 'AR',
    },
    72426: { lat: 35.85608, lng: -90.04159, city: 'Dell', state: 'AR' },
    72427: {
      lat: 35.86872,
      lng: -90.94901,
      city: 'Egypt',
      state: 'AR',
    },
    72428: {
      lat: 35.7414,
      lng: -90.21672,
      city: 'Etowah',
      state: 'AR',
    },
    72429: {
      lat: 35.48895,
      lng: -90.93972,
      city: 'Fisher',
      state: 'AR',
    },
    72430: {
      lat: 36.336,
      lng: -90.17366,
      city: 'Greenway',
      state: 'AR',
    },
    72431: {
      lat: 35.63396,
      lng: -91.08184,
      city: 'Grubbs',
      state: 'AR',
    },
    72432: {
      lat: 35.55438,
      lng: -90.72457,
      city: 'Harrisburg',
      state: 'AR',
    },
    72433: {
      lat: 36.02582,
      lng: -91.04446,
      city: 'Hoxie',
      state: 'AR',
    },
    72434: {
      lat: 36.21847,
      lng: -91.16025,
      city: 'Imboden',
      state: 'AR',
    },
    72435: {
      lat: 36.31177,
      lng: -90.54593,
      city: 'Knobel',
      state: 'AR',
    },
    72436: { lat: 36.23574, lng: -90.48281, city: 'Lafe', state: 'AR' },
    72437: {
      lat: 35.8343,
      lng: -90.45401,
      city: 'Lake City',
      state: 'AR',
    },
    72438: {
      lat: 35.94375,
      lng: -90.22787,
      city: 'Leachville',
      state: 'AR',
    },
    72440: { lat: 35.9924, lng: -91.26466, city: 'Lynn', state: 'AR' },
    72441: {
      lat: 36.4355,
      lng: -90.38944,
      city: 'McDougal',
      state: 'AR',
    },
    72442: {
      lat: 35.82458,
      lng: -90.17948,
      city: 'Manila',
      state: 'AR',
    },
    72443: {
      lat: 36.1812,
      lng: -90.41037,
      city: 'Marmaduke',
      state: 'AR',
    },
    72444: {
      lat: 36.4266,
      lng: -90.86106,
      city: 'Maynard',
      state: 'AR',
    },
    72445: {
      lat: 35.97812,
      lng: -91.02669,
      city: 'Minturn',
      state: 'AR',
    },
    72447: {
      lat: 35.9102,
      lng: -90.33428,
      city: 'Monette',
      state: 'AR',
    },
    72449: {
      lat: 36.16662,
      lng: -90.81719,
      city: "O'Kean",
      state: 'AR',
    },
    72450: {
      lat: 36.0784,
      lng: -90.51695,
      city: 'Paragould',
      state: 'AR',
    },
    72453: {
      lat: 36.30909,
      lng: -90.67887,
      city: 'Peach Orchard',
      state: 'AR',
    },
    72454: {
      lat: 36.40083,
      lng: -90.20623,
      city: 'Piggott',
      state: 'AR',
    },
    72455: {
      lat: 36.3229,
      lng: -91.04909,
      city: 'Pocahontas',
      state: 'AR',
    },
    72456: {
      lat: 36.45362,
      lng: -90.32217,
      city: 'Pollard',
      state: 'AR',
    },
    72457: {
      lat: 36.08545,
      lng: -91.06827,
      city: 'Portia',
      state: 'AR',
    },
    72458: {
      lat: 36.06844,
      lng: -91.15619,
      city: 'Powhatan',
      state: 'AR',
    },
    72459: {
      lat: 36.27904,
      lng: -91.29976,
      city: 'Ravenden',
      state: 'AR',
    },
    72460: {
      lat: 36.35792,
      lng: -91.15967,
      city: 'Ravenden Springs',
      state: 'AR',
    },
    72461: {
      lat: 36.26679,
      lng: -90.28254,
      city: 'Rector',
      state: 'AR',
    },
    72462: {
      lat: 36.38243,
      lng: -90.75916,
      city: 'Reyno',
      state: 'AR',
    },
    72464: {
      lat: 36.45405,
      lng: -90.1434,
      city: 'Saint Francis',
      state: 'AR',
    },
    72466: {
      lat: 36.05121,
      lng: -91.32157,
      city: 'Smithville',
      state: 'AR',
    },
    72467: {
      lat: 35.84223,
      lng: -90.6776,
      city: 'State University',
      state: 'AR',
    },
    72469: {
      lat: 35.95367,
      lng: -91.29949,
      city: 'Strawberry',
      state: 'AR',
    },
    72470: {
      lat: 36.4668,
      lng: -90.70309,
      city: 'Success',
      state: 'AR',
    },
    72471: {
      lat: 35.82591,
      lng: -91.11996,
      city: 'Swifton',
      state: 'AR',
    },
    72472: {
      lat: 35.59264,
      lng: -90.54459,
      city: 'Trumann',
      state: 'AR',
    },
    72473: {
      lat: 35.73531,
      lng: -91.17356,
      city: 'Tuckerman',
      state: 'AR',
    },
    72476: {
      lat: 36.04533,
      lng: -90.91692,
      city: 'Walnut Ridge',
      state: 'AR',
    },
    72478: {
      lat: 36.46249,
      lng: -91.05373,
      city: 'Warm Springs',
      state: 'AR',
    },
    72479: {
      lat: 35.62924,
      lng: -90.90535,
      city: 'Weiner',
      state: 'AR',
    },
    72482: {
      lat: 36.26933,
      lng: -91.36402,
      city: 'Williford',
      state: 'AR',
    },
    72501: {
      lat: 35.79167,
      lng: -91.65399,
      city: 'Batesville',
      state: 'AR',
    },
    72512: {
      lat: 36.22326,
      lng: -91.7514,
      city: 'Horseshoe Bend',
      state: 'AR',
    },
    72513: {
      lat: 36.23118,
      lng: -91.64722,
      city: 'Ash Flat',
      state: 'AR',
    },
    72515: {
      lat: 36.27634,
      lng: -92.05644,
      city: 'Bexar',
      state: 'AR',
    },
    72517: {
      lat: 36.1463,
      lng: -91.95403,
      city: 'Brockwell',
      state: 'AR',
    },
    72519: {
      lat: 36.11876,
      lng: -92.17802,
      city: 'Calico Rock',
      state: 'AR',
    },
    72520: { lat: 36.39816, lng: -91.72476, city: 'Camp', state: 'AR' },
    72521: {
      lat: 35.95196,
      lng: -91.54522,
      city: 'Cave City',
      state: 'AR',
    },
    72522: {
      lat: 35.80471,
      lng: -91.46683,
      city: 'Charlotte',
      state: 'AR',
    },
    72523: {
      lat: 35.64808,
      lng: -91.83632,
      city: 'Concord',
      state: 'AR',
    },
    72524: { lat: 35.83551, lng: -91.30318, city: 'Cord', state: 'AR' },
    72526: {
      lat: 35.87762,
      lng: -91.76623,
      city: 'Cushman',
      state: 'AR',
    },
    72527: {
      lat: 35.74038,
      lng: -91.68323,
      city: 'Desha',
      state: 'AR',
    },
    72528: { lat: 36.23816, lng: -92.115, city: 'Dolph', state: 'AR' },
    72529: {
      lat: 36.29696,
      lng: -91.56676,
      city: 'Cherokee Village',
      state: 'AR',
    },
    72530: {
      lat: 35.61381,
      lng: -91.95715,
      city: 'Drasco',
      state: 'AR',
    },
    72531: {
      lat: 36.32052,
      lng: -92.15121,
      city: 'Elizabeth',
      state: 'AR',
    },
    72532: {
      lat: 36.09905,
      lng: -91.59508,
      city: 'Evening Shade',
      state: 'AR',
    },
    72533: {
      lat: 35.97921,
      lng: -92.24513,
      city: 'Fifty Six',
      state: 'AR',
    },
    72534: {
      lat: 35.59272,
      lng: -91.73265,
      city: 'Floral',
      state: 'AR',
    },
    72536: {
      lat: 36.14673,
      lng: -91.76115,
      city: 'Franklin',
      state: 'AR',
    },
    72537: {
      lat: 36.45018,
      lng: -92.23371,
      city: 'Gamaliel',
      state: 'AR',
    },
    72538: { lat: 36.43622, lng: -92.10612, city: 'Gepp', state: 'AR' },
    72539: {
      lat: 36.33015,
      lng: -91.72259,
      city: 'Glencoe',
      state: 'AR',
    },
    72540: { lat: 35.93355, lng: -91.9096, city: 'Guion', state: 'AR' },
    72542: {
      lat: 36.29656,
      lng: -91.46925,
      city: 'Hardy',
      state: 'AR',
    },
    72543: {
      lat: 35.45528,
      lng: -91.99407,
      city: 'Heber Springs',
      state: 'AR',
    },
    72544: {
      lat: 36.40395,
      lng: -92.19022,
      city: 'Henderson',
      state: 'AR',
    },
    72546: { lat: 35.57711, lng: -91.94487, city: 'Ida', state: 'AR' },
    72550: {
      lat: 35.70836,
      lng: -91.78387,
      city: 'Locust Grove',
      state: 'AR',
    },
    72553: {
      lat: 35.70093,
      lng: -91.48212,
      city: 'Magness',
      state: 'AR',
    },
    72554: {
      lat: 36.43902,
      lng: -91.55668,
      city: 'Mammoth Spring',
      state: 'AR',
    },
    72555: {
      lat: 35.77165,
      lng: -91.88287,
      city: 'Marcella',
      state: 'AR',
    },
    72556: {
      lat: 36.02472,
      lng: -91.95831,
      city: 'Melbourne',
      state: 'AR',
    },
    72560: {
      lat: 35.84023,
      lng: -92.08032,
      city: 'Mountain View',
      state: 'AR',
    },
    72561: {
      lat: 35.9428,
      lng: -91.81093,
      city: 'Mount Pleasant',
      state: 'AR',
    },
    72562: {
      lat: 35.73074,
      lng: -91.39514,
      city: 'Newark',
      state: 'AR',
    },
    72564: {
      lat: 35.60034,
      lng: -91.45585,
      city: 'Oil Trough',
      state: 'AR',
    },
    72565: {
      lat: 36.21871,
      lng: -91.90686,
      city: 'Oxford',
      state: 'AR',
    },
    72566: {
      lat: 36.20792,
      lng: -92.06468,
      city: 'Pineville',
      state: 'AR',
    },
    72567: {
      lat: 35.81742,
      lng: -91.91975,
      city: 'Pleasant Grove',
      state: 'AR',
    },
    72568: {
      lat: 35.56676,
      lng: -91.62147,
      city: 'Pleasant Plains',
      state: 'AR',
    },
    72569: {
      lat: 36.08121,
      lng: -91.45817,
      city: 'Poughkeepsie',
      state: 'AR',
    },
    72571: {
      lat: 35.64059,
      lng: -91.53568,
      city: 'Rosie',
      state: 'AR',
    },
    72572: {
      lat: 35.89762,
      lng: -91.29173,
      city: 'Saffell',
      state: 'AR',
    },
    72573: { lat: 36.07442, lng: -91.79624, city: 'Sage', state: 'AR' },
    72576: {
      lat: 36.35004,
      lng: -91.85081,
      city: 'Salem',
      state: 'AR',
    },
    72577: {
      lat: 36.03094,
      lng: -91.71379,
      city: 'Sidney',
      state: 'AR',
    },
    72578: {
      lat: 36.45734,
      lng: -91.87894,
      city: 'Sturkie',
      state: 'AR',
    },
    72579: {
      lat: 35.84458,
      lng: -91.44428,
      city: 'Sulphur Rock',
      state: 'AR',
    },
    72581: {
      lat: 35.54722,
      lng: -91.97035,
      city: 'Tumbling Shoals',
      state: 'AR',
    },
    72583: {
      lat: 36.40646,
      lng: -91.98797,
      city: 'Viola',
      state: 'AR',
    },
    72584: {
      lat: 36.14421,
      lng: -91.84427,
      city: 'Violet Hill',
      state: 'AR',
    },
    72585: {
      lat: 36.17861,
      lng: -92.01454,
      city: 'Wideman',
      state: 'AR',
    },
    72587: {
      lat: 36.21977,
      lng: -91.8173,
      city: 'Wiseman',
      state: 'AR',
    },
    72601: {
      lat: 36.23669,
      lng: -93.09181,
      city: 'Harrison',
      state: 'AR',
    },
    72611: {
      lat: 36.24532,
      lng: -93.32705,
      city: 'Alpena',
      state: 'AR',
    },
    72615: {
      lat: 36.31532,
      lng: -93.01207,
      city: 'Bergman',
      state: 'AR',
    },
    72616: {
      lat: 36.35047,
      lng: -93.55736,
      city: 'Berryville',
      state: 'AR',
    },
    72617: {
      lat: 36.0208,
      lng: -92.36566,
      city: 'Big Flat',
      state: 'AR',
    },
    72619: {
      lat: 36.3781,
      lng: -92.58517,
      city: 'Bull Shoals',
      state: 'AR',
    },
    72623: {
      lat: 36.46386,
      lng: -92.3172,
      city: 'Clarkridge',
      state: 'AR',
    },
    72624: {
      lat: 36.08208,
      lng: -93.35655,
      city: 'Compton',
      state: 'AR',
    },
    72626: {
      lat: 36.30451,
      lng: -92.53994,
      city: 'Cotter',
      state: 'AR',
    },
    72628: { lat: 35.82717, lng: -93.28785, city: 'Deer', state: 'AR' },
    72629: {
      lat: 35.73615,
      lng: -92.56625,
      city: 'Dennard',
      state: 'AR',
    },
    72630: {
      lat: 36.45856,
      lng: -92.92032,
      city: 'Diamond City',
      state: 'AR',
    },
    72631: {
      lat: 36.45146,
      lng: -93.79131,
      city: 'Eureka Springs',
      state: 'AR',
    },
    72632: {
      lat: 36.37248,
      lng: -93.74355,
      city: 'Eureka Springs',
      state: 'AR',
    },
    72633: {
      lat: 36.14023,
      lng: -92.87572,
      city: 'Everton',
      state: 'AR',
    },
    72634: {
      lat: 36.23693,
      lng: -92.54891,
      city: 'Flippin',
      state: 'AR',
    },
    72635: {
      lat: 36.31637,
      lng: -92.48484,
      city: 'Gassville',
      state: 'AR',
    },
    72636: {
      lat: 35.99121,
      lng: -92.71651,
      city: 'Gilbert',
      state: 'AR',
    },
    72638: {
      lat: 36.3245,
      lng: -93.40496,
      city: 'Green Forest',
      state: 'AR',
    },
    72639: {
      lat: 36.01948,
      lng: -92.48937,
      city: 'Harriet',
      state: 'AR',
    },
    72640: {
      lat: 36.00708,
      lng: -93.05061,
      city: 'Hasty',
      state: 'AR',
    },
    72641: {
      lat: 35.98829,
      lng: -93.21978,
      city: 'Jasper',
      state: 'AR',
    },
    72642: {
      lat: 36.37013,
      lng: -92.54347,
      city: 'Lakeview',
      state: 'AR',
    },
    72644: {
      lat: 36.42477,
      lng: -92.96673,
      city: 'Lead Hill',
      state: 'AR',
    },
    72645: {
      lat: 35.80249,
      lng: -92.55778,
      city: 'Leslie',
      state: 'AR',
    },
    72648: {
      lat: 36.07516,
      lng: -93.14399,
      city: 'Marble Falls',
      state: 'AR',
    },
    72650: {
      lat: 35.87938,
      lng: -92.69308,
      city: 'Marshall',
      state: 'AR',
    },
    72651: { lat: 36.388, lng: -92.47661, city: 'Midway', state: 'AR' },
    72653: {
      lat: 36.34292,
      lng: -92.38603,
      city: 'Mountain Home',
      state: 'AR',
    },
    72655: {
      lat: 35.89346,
      lng: -93.03421,
      city: 'Mount Judea',
      state: 'AR',
    },
    72658: {
      lat: 36.15468,
      lng: -92.32301,
      city: 'Norfork',
      state: 'AR',
    },
    72660: {
      lat: 36.48115,
      lng: -93.37454,
      city: 'Oak Grove',
      state: 'AR',
    },
    72661: {
      lat: 36.45195,
      lng: -92.58963,
      city: 'Oakland',
      state: 'AR',
    },
    72662: {
      lat: 36.43184,
      lng: -93.18297,
      city: 'Omaha',
      state: 'AR',
    },
    72663: { lat: 35.93617, lng: -92.33192, city: 'Onia', state: 'AR' },
    72666: {
      lat: 35.94876,
      lng: -93.28706,
      city: 'Parthenon',
      state: 'AR',
    },
    72668: { lat: 36.41914, lng: -92.77163, city: 'Peel', state: 'AR' },
    72669: {
      lat: 36.06477,
      lng: -92.87359,
      city: 'Pindall',
      state: 'AR',
    },
    72670: {
      lat: 35.98726,
      lng: -93.37775,
      city: 'Ponca',
      state: 'AR',
    },
    72672: {
      lat: 36.27725,
      lng: -92.83685,
      city: 'Pyatt',
      state: 'AR',
    },
    72675: {
      lat: 36.00711,
      lng: -92.79283,
      city: 'Saint Joe',
      state: 'AR',
    },
    72677: {
      lat: 36.26442,
      lng: -92.70074,
      city: 'Summit',
      state: 'AR',
    },
    72679: {
      lat: 35.73555,
      lng: -92.80206,
      city: 'Tilly',
      state: 'AR',
    },
    72680: {
      lat: 35.88286,
      lng: -92.32986,
      city: 'Timbo',
      state: 'AR',
    },
    72682: {
      lat: 36.13405,
      lng: -92.72911,
      city: 'Valley Springs',
      state: 'AR',
    },
    72683: {
      lat: 35.93102,
      lng: -93.11629,
      city: 'Vendor',
      state: 'AR',
    },
    72685: {
      lat: 36.05539,
      lng: -92.98194,
      city: 'Western Grove',
      state: 'AR',
    },
    72686: {
      lat: 35.70352,
      lng: -92.918,
      city: 'Witts Springs',
      state: 'AR',
    },
    72687: {
      lat: 36.25628,
      lng: -92.68679,
      city: 'Yellville',
      state: 'AR',
    },
    72701: {
      lat: 35.9873,
      lng: -94.08179,
      city: 'Fayetteville',
      state: 'AR',
    },
    72703: {
      lat: 36.1155,
      lng: -94.04897,
      city: 'Fayetteville',
      state: 'AR',
    },
    72704: {
      lat: 36.10475,
      lng: -94.29539,
      city: 'Fayetteville',
      state: 'AR',
    },
    72712: {
      lat: 36.39837,
      lng: -94.22196,
      city: 'Bentonville',
      state: 'AR',
    },
    72713: {
      lat: 36.30036,
      lng: -94.28372,
      city: 'Bentonville',
      state: 'AR',
    },
    72714: {
      lat: 36.46841,
      lng: -94.21649,
      city: 'Bella Vista',
      state: 'AR',
    },
    72715: {
      lat: 36.46505,
      lng: -94.30545,
      city: 'Bella Vista',
      state: 'AR',
    },
    72717: {
      lat: 35.84054,
      lng: -94.42503,
      city: 'Canehill',
      state: 'AR',
    },
    72718: {
      lat: 36.26347,
      lng: -94.22147,
      city: 'Cave Springs',
      state: 'AR',
    },
    72719: {
      lat: 36.36837,
      lng: -94.29551,
      city: 'Centerton',
      state: 'AR',
    },
    72721: {
      lat: 35.78383,
      lng: -93.78402,
      city: 'Combs',
      state: 'AR',
    },
    72722: {
      lat: 36.34042,
      lng: -94.46397,
      city: 'Decatur',
      state: 'AR',
    },
    72727: {
      lat: 35.91456,
      lng: -93.92612,
      city: 'Elkins',
      state: 'AR',
    },
    72729: {
      lat: 35.78992,
      lng: -94.46338,
      city: 'Evansville',
      state: 'AR',
    },
    72730: {
      lat: 36.03057,
      lng: -94.26588,
      city: 'Farmington',
      state: 'AR',
    },
    72732: {
      lat: 36.43187,
      lng: -93.95968,
      city: 'Garfield',
      state: 'AR',
    },
    72734: {
      lat: 36.27217,
      lng: -94.45355,
      city: 'Gentry',
      state: 'AR',
    },
    72736: {
      lat: 36.41402,
      lng: -94.46548,
      city: 'Gravette',
      state: 'AR',
    },
    72738: {
      lat: 36.16258,
      lng: -93.885,
      city: 'Hindsville',
      state: 'AR',
    },
    72739: {
      lat: 36.42715,
      lng: -94.32801,
      city: 'Hiwasse',
      state: 'AR',
    },
    72740: {
      lat: 36.10889,
      lng: -93.67599,
      city: 'Huntsville',
      state: 'AR',
    },
    72742: {
      lat: 35.98159,
      lng: -93.49528,
      city: 'Kingston',
      state: 'AR',
    },
    72744: {
      lat: 35.96287,
      lng: -94.43851,
      city: 'Lincoln',
      state: 'AR',
    },
    72745: {
      lat: 36.2477,
      lng: -94.10046,
      city: 'Lowell',
      state: 'AR',
    },
    72747: {
      lat: 36.37023,
      lng: -94.5807,
      city: 'Maysville',
      state: 'AR',
    },
    72749: {
      lat: 35.86712,
      lng: -94.43932,
      city: 'Morrow',
      state: 'AR',
    },
    72751: {
      lat: 36.46255,
      lng: -94.11825,
      city: 'Pea Ridge',
      state: 'AR',
    },
    72752: {
      lat: 35.82077,
      lng: -93.56357,
      city: 'Pettigrew',
      state: 'AR',
    },
    72753: {
      lat: 35.93477,
      lng: -94.32607,
      city: 'Prairie Grove',
      state: 'AR',
    },
    72756: {
      lat: 36.32214,
      lng: -93.9922,
      city: 'Rogers',
      state: 'AR',
    },
    72758: {
      lat: 36.29898,
      lng: -94.14099,
      city: 'Rogers',
      state: 'AR',
    },
    72760: {
      lat: 35.82898,
      lng: -93.69804,
      city: 'Saint Paul',
      state: 'AR',
    },
    72761: {
      lat: 36.17074,
      lng: -94.45778,
      city: 'Siloam Springs',
      state: 'AR',
    },
    72762: {
      lat: 36.18747,
      lng: -94.22794,
      city: 'Springdale',
      state: 'AR',
    },
    72764: {
      lat: 36.17746,
      lng: -94.051,
      city: 'Springdale',
      state: 'AR',
    },
    72768: {
      lat: 36.47884,
      lng: -94.4938,
      city: 'Sulphur Springs',
      state: 'AR',
    },
    72769: {
      lat: 36.0347,
      lng: -94.49702,
      city: 'Summers',
      state: 'AR',
    },
    72773: {
      lat: 36.01409,
      lng: -93.84723,
      city: 'Wesley',
      state: 'AR',
    },
    72774: {
      lat: 35.87777,
      lng: -94.21533,
      city: 'West Fork',
      state: 'AR',
    },
    72776: {
      lat: 35.90913,
      lng: -93.67307,
      city: 'Witter',
      state: 'AR',
    },
    72801: {
      lat: 35.28057,
      lng: -93.14161,
      city: 'Russellville',
      state: 'AR',
    },
    72802: {
      lat: 35.31334,
      lng: -93.06726,
      city: 'Russellville',
      state: 'AR',
    },
    72821: { lat: 35.4545, lng: -93.72024, city: 'Altus', state: 'AR' },
    72823: {
      lat: 35.25213,
      lng: -92.89847,
      city: 'Atkins',
      state: 'AR',
    },
    72824: {
      lat: 35.12768,
      lng: -93.41146,
      city: 'Belleville',
      state: 'AR',
    },
    72826: {
      lat: 35.13,
      lng: -93.71025,
      city: 'Blue Mountain',
      state: 'AR',
    },
    72827: {
      lat: 34.87758,
      lng: -93.61948,
      city: 'Bluffton',
      state: 'AR',
    },
    72828: {
      lat: 34.92613,
      lng: -93.53951,
      city: 'Briggsville',
      state: 'AR',
    },
    72829: {
      lat: 35.10351,
      lng: -93.1753,
      city: 'Centerville',
      state: 'AR',
    },
    72830: {
      lat: 35.51685,
      lng: -93.50859,
      city: 'Clarksville',
      state: 'AR',
    },
    72832: {
      lat: 35.42748,
      lng: -93.67043,
      city: 'Coal Hill',
      state: 'AR',
    },
    72833: {
      lat: 35.01845,
      lng: -93.49748,
      city: 'Danville',
      state: 'AR',
    },
    72834: {
      lat: 35.17202,
      lng: -93.19401,
      city: 'Dardanelle',
      state: 'AR',
    },
    72835: {
      lat: 35.27634,
      lng: -93.3425,
      city: 'Delaware',
      state: 'AR',
    },
    72837: {
      lat: 35.51387,
      lng: -93.10709,
      city: 'Dover',
      state: 'AR',
    },
    72838: {
      lat: 34.88926,
      lng: -93.68009,
      city: 'Gravelly',
      state: 'AR',
    },
    72839: {
      lat: 35.65131,
      lng: -93.26384,
      city: 'Hagarville',
      state: 'AR',
    },
    72840: {
      lat: 35.46164,
      lng: -93.61442,
      city: 'Hartman',
      state: 'AR',
    },
    72841: {
      lat: 34.86736,
      lng: -93.78515,
      city: 'Harvey',
      state: 'AR',
    },
    72842: {
      lat: 35.09626,
      lng: -93.59664,
      city: 'Havana',
      state: 'AR',
    },
    72843: {
      lat: 35.55608,
      lng: -92.91932,
      city: 'Hector',
      state: 'AR',
    },
    72845: {
      lat: 35.36262,
      lng: -93.37619,
      city: 'Knoxville',
      state: 'AR',
    },
    72846: {
      lat: 35.48166,
      lng: -93.31885,
      city: 'Lamar',
      state: 'AR',
    },
    72847: {
      lat: 35.37043,
      lng: -93.27749,
      city: 'London',
      state: 'AR',
    },
    72851: {
      lat: 35.26092,
      lng: -93.45693,
      city: 'New Blaine',
      state: 'AR',
    },
    72852: { lat: 35.68243, lng: -93.56684, city: 'Oark', state: 'AR' },
    72853: { lat: 35.01146, lng: -93.23358, city: 'Ola', state: 'AR' },
    72854: {
      lat: 35.69644,
      lng: -93.43491,
      city: 'Ozone',
      state: 'AR',
    },
    72855: {
      lat: 35.25249,
      lng: -93.67553,
      city: 'Paris',
      state: 'AR',
    },
    72856: {
      lat: 35.76773,
      lng: -93.05658,
      city: 'Pelsor',
      state: 'AR',
    },
    72857: {
      lat: 34.86217,
      lng: -93.33826,
      city: 'Plainview',
      state: 'AR',
    },
    72858: {
      lat: 35.20582,
      lng: -93.04433,
      city: 'Pottsville',
      state: 'AR',
    },
    72860: {
      lat: 34.94556,
      lng: -93.43144,
      city: 'Rover',
      state: 'AR',
    },
    72863: {
      lat: 35.36642,
      lng: -93.50878,
      city: 'Scranton',
      state: 'AR',
    },
    72865: {
      lat: 35.31143,
      lng: -93.5673,
      city: 'Subiaco',
      state: 'AR',
    },
    72901: {
      lat: 35.3652,
      lng: -94.4155,
      city: 'Fort Smith',
      state: 'AR',
    },
    72903: {
      lat: 35.35241,
      lng: -94.36042,
      city: 'Fort Smith',
      state: 'AR',
    },
    72904: {
      lat: 35.4151,
      lng: -94.38338,
      city: 'Fort Smith',
      state: 'AR',
    },
    72905: {
      lat: 35.26004,
      lng: -94.24265,
      city: 'Fort Smith',
      state: 'AR',
    },
    72908: {
      lat: 35.30595,
      lng: -94.4099,
      city: 'Fort Smith',
      state: 'AR',
    },
    72916: {
      lat: 35.27236,
      lng: -94.37256,
      city: 'Fort Smith',
      state: 'AR',
    },
    72921: { lat: 35.46953, lng: -94.19686, city: 'Alma', state: 'AR' },
    72923: {
      lat: 35.30904,
      lng: -94.28954,
      city: 'Barling',
      state: 'AR',
    },
    72926: { lat: 34.72909, lng: -94.0376, city: 'Boles', state: 'AR' },
    72927: {
      lat: 35.07254,
      lng: -93.94333,
      city: 'Booneville',
      state: 'AR',
    },
    72928: {
      lat: 35.29127,
      lng: -93.93206,
      city: 'Branch',
      state: 'AR',
    },
    72930: {
      lat: 35.43248,
      lng: -94.00737,
      city: 'Cecil',
      state: 'AR',
    },
    72932: {
      lat: 35.60479,
      lng: -94.37682,
      city: 'Cedarville',
      state: 'AR',
    },
    72933: {
      lat: 35.33408,
      lng: -94.01432,
      city: 'Charleston',
      state: 'AR',
    },
    72934: {
      lat: 35.68957,
      lng: -94.2703,
      city: 'Chester',
      state: 'AR',
    },
    72935: { lat: 35.49431, lng: -94.13277, city: 'Dyer', state: 'AR' },
    72936: {
      lat: 35.1896,
      lng: -94.23069,
      city: 'Greenwood',
      state: 'AR',
    },
    72937: {
      lat: 35.13764,
      lng: -94.39545,
      city: 'Hackett',
      state: 'AR',
    },
    72938: {
      lat: 35.00665,
      lng: -94.38097,
      city: 'Hartford',
      state: 'AR',
    },
    72940: {
      lat: 35.11488,
      lng: -94.26427,
      city: 'Huntington',
      state: 'AR',
    },
    72941: {
      lat: 35.35018,
      lng: -94.16214,
      city: 'Lavaca',
      state: 'AR',
    },
    72943: {
      lat: 35.18326,
      lng: -93.78721,
      city: 'Magazine',
      state: 'AR',
    },
    72944: {
      lat: 35.03366,
      lng: -94.24173,
      city: 'Mansfield',
      state: 'AR',
    },
    72945: {
      lat: 35.09559,
      lng: -94.36297,
      city: 'Midland',
      state: 'AR',
    },
    72946: {
      lat: 35.65254,
      lng: -94.13706,
      city: 'Mountainburg',
      state: 'AR',
    },
    72947: {
      lat: 35.54706,
      lng: -94.04021,
      city: 'Mulberry',
      state: 'AR',
    },
    72948: {
      lat: 35.70692,
      lng: -94.4154,
      city: 'Natural Dam',
      state: 'AR',
    },
    72949: {
      lat: 35.55755,
      lng: -93.80813,
      city: 'Ozark',
      state: 'AR',
    },
    72950: {
      lat: 34.79317,
      lng: -93.91077,
      city: 'Parks',
      state: 'AR',
    },
    72951: {
      lat: 35.31812,
      lng: -93.87593,
      city: 'Ratcliff',
      state: 'AR',
    },
    72952: { lat: 35.56861, lng: -94.30139, city: 'Rudy', state: 'AR' },
    72955: {
      lat: 35.6081,
      lng: -94.44445,
      city: 'Uniontown',
      state: 'AR',
    },
    72956: {
      lat: 35.46895,
      lng: -94.3489,
      city: 'Van Buren',
      state: 'AR',
    },
    72958: {
      lat: 34.90173,
      lng: -94.14659,
      city: 'Waldron',
      state: 'AR',
    },
    72959: {
      lat: 35.77157,
      lng: -94.06686,
      city: 'Winslow',
      state: 'AR',
    },
    73001: {
      lat: 35.2262,
      lng: -98.41654,
      city: 'Albert',
      state: 'OK',
    },
    73002: { lat: 34.96402, lng: -97.73237, city: 'Alex', state: 'OK' },
    73003: {
      lat: 35.66952,
      lng: -97.49693,
      city: 'Edmond',
      state: 'OK',
    },
    73004: {
      lat: 35.14362,
      lng: -97.83875,
      city: 'Amber',
      state: 'OK',
    },
    73005: {
      lat: 35.05439,
      lng: -98.23762,
      city: 'Anadarko',
      state: 'OK',
    },
    73006: {
      lat: 34.90587,
      lng: -98.40583,
      city: 'Apache',
      state: 'OK',
    },
    73007: {
      lat: 35.69001,
      lng: -97.32009,
      city: 'Arcadia',
      state: 'OK',
    },
    73008: {
      lat: 35.51383,
      lng: -97.64536,
      city: 'Bethany',
      state: 'OK',
    },
    73009: {
      lat: 35.28544,
      lng: -98.37016,
      city: 'Binger',
      state: 'OK',
    },
    73010: {
      lat: 35.09989,
      lng: -97.67197,
      city: 'Blanchard',
      state: 'OK',
    },
    73011: {
      lat: 34.84378,
      lng: -97.74498,
      city: 'Bradley',
      state: 'OK',
    },
    73012: {
      lat: 35.66815,
      lng: -97.59315,
      city: 'Edmond',
      state: 'OK',
    },
    73013: {
      lat: 35.62149,
      lng: -97.49614,
      city: 'Edmond',
      state: 'OK',
    },
    73014: {
      lat: 35.58152,
      lng: -98.1409,
      city: 'Calumet',
      state: 'OK',
    },
    73015: {
      lat: 35.07648,
      lng: -98.59016,
      city: 'Carnegie',
      state: 'OK',
    },
    73016: {
      lat: 35.79778,
      lng: -97.68831,
      city: 'Cashion',
      state: 'OK',
    },
    73017: {
      lat: 34.92651,
      lng: -98.09546,
      city: 'Cement',
      state: 'OK',
    },
    73018: {
      lat: 35.0484,
      lng: -97.93839,
      city: 'Chickasha',
      state: 'OK',
    },
    73019: {
      lat: 35.20681,
      lng: -97.44213,
      city: 'Norman',
      state: 'OK',
    },
    73020: {
      lat: 35.45588,
      lng: -97.2619,
      city: 'Choctaw',
      state: 'OK',
    },
    73021: {
      lat: 35.33802,
      lng: -98.67007,
      city: 'Colony',
      state: 'OK',
    },
    73022: {
      lat: 35.61692,
      lng: -97.99224,
      city: 'Concho',
      state: 'OK',
    },
    73024: { lat: 35.36608, lng: -98.81508, city: 'Corn', state: 'OK' },
    73025: {
      lat: 35.73199,
      lng: -97.5727,
      city: 'Edmond',
      state: 'OK',
    },
    73026: {
      lat: 35.23433,
      lng: -97.2791,
      city: 'Norman',
      state: 'OK',
    },
    73027: {
      lat: 35.97102,
      lng: -97.24455,
      city: 'Coyle',
      state: 'OK',
    },
    73028: {
      lat: 35.9771,
      lng: -97.62418,
      city: 'Crescent',
      state: 'OK',
    },
    73029: {
      lat: 34.89999,
      lng: -98.21016,
      city: 'Cyril',
      state: 'OK',
    },
    73030: {
      lat: 34.47142,
      lng: -97.18584,
      city: 'Davis',
      state: 'OK',
    },
    73031: {
      lat: 35.03376,
      lng: -97.63139,
      city: 'Dibble',
      state: 'OK',
    },
    73032: {
      lat: 34.40051,
      lng: -97.05137,
      city: 'Dougherty',
      state: 'OK',
    },
    73033: {
      lat: 35.30709,
      lng: -98.55502,
      city: 'Eakly',
      state: 'OK',
    },
    73034: {
      lat: 35.69877,
      lng: -97.42665,
      city: 'Edmond',
      state: 'OK',
    },
    73036: {
      lat: 35.50752,
      lng: -97.95771,
      city: 'El Reno',
      state: 'OK',
    },
    73038: {
      lat: 35.1214,
      lng: -98.44306,
      city: 'Fort Cobb',
      state: 'OK',
    },
    73040: {
      lat: 35.64442,
      lng: -98.37861,
      city: 'Geary',
      state: 'OK',
    },
    73041: {
      lat: 35.03594,
      lng: -98.88064,
      city: 'Gotebo',
      state: 'OK',
    },
    73042: {
      lat: 35.23019,
      lng: -98.25151,
      city: 'Gracemont',
      state: 'OK',
    },
    73043: {
      lat: 35.73609,
      lng: -98.3939,
      city: 'Greenfield',
      state: 'OK',
    },
    73044: {
      lat: 35.86664,
      lng: -97.43476,
      city: 'Guthrie',
      state: 'OK',
    },
    73045: {
      lat: 35.51201,
      lng: -97.1414,
      city: 'Harrah',
      state: 'OK',
    },
    73047: {
      lat: 35.45658,
      lng: -98.31553,
      city: 'Hinton',
      state: 'OK',
    },
    73048: {
      lat: 35.48929,
      lng: -98.55378,
      city: 'Hydro',
      state: 'OK',
    },
    73049: {
      lat: 35.58617,
      lng: -97.29695,
      city: 'Jones',
      state: 'OK',
    },
    73050: {
      lat: 35.93825,
      lng: -97.25595,
      city: 'Langston',
      state: 'OK',
    },
    73051: {
      lat: 35.03175,
      lng: -97.24629,
      city: 'Lexington',
      state: 'OK',
    },
    73052: {
      lat: 34.83359,
      lng: -97.59526,
      city: 'Lindsay',
      state: 'OK',
    },
    73053: {
      lat: 35.36248,
      lng: -98.41841,
      city: 'Lookeba',
      state: 'OK',
    },
    73054: {
      lat: 35.67508,
      lng: -97.19487,
      city: 'Luther',
      state: 'OK',
    },
    73055: {
      lat: 34.64301,
      lng: -97.91624,
      city: 'Marlow',
      state: 'OK',
    },
    73056: {
      lat: 36.17036,
      lng: -97.62097,
      city: 'Marshall',
      state: 'OK',
    },
    73057: {
      lat: 34.82998,
      lng: -97.43176,
      city: 'Maysville',
      state: 'OK',
    },
    73058: {
      lat: 35.8175,
      lng: -97.22571,
      city: 'Meridian',
      state: 'OK',
    },
    73059: { lat: 35.3251, lng: -98.05431, city: 'Minco', state: 'OK' },
    73061: {
      lat: 36.34904,
      lng: -97.01765,
      city: 'Morrison',
      state: 'OK',
    },
    73062: {
      lat: 35.02929,
      lng: -98.73478,
      city: 'Mountain View',
      state: 'OK',
    },
    73063: {
      lat: 36.06195,
      lng: -97.41832,
      city: 'Mulhall',
      state: 'OK',
    },
    73064: {
      lat: 35.37377,
      lng: -97.75275,
      city: 'Mustang',
      state: 'OK',
    },
    73065: {
      lat: 35.25008,
      lng: -97.61209,
      city: 'Newcastle',
      state: 'OK',
    },
    73066: {
      lat: 35.50167,
      lng: -97.32832,
      city: 'Nicoma Park',
      state: 'OK',
    },
    73067: {
      lat: 34.90315,
      lng: -97.90612,
      city: 'Ninnekah',
      state: 'OK',
    },
    73068: { lat: 35.1384, lng: -97.28264, city: 'Noble', state: 'OK' },
    73069: {
      lat: 35.25028,
      lng: -97.4633,
      city: 'Norman',
      state: 'OK',
    },
    73070: {
      lat: 35.22763,
      lng: -97.42729,
      city: 'Norman',
      state: 'OK',
    },
    73071: {
      lat: 35.23728,
      lng: -97.4131,
      city: 'Norman',
      state: 'OK',
    },
    73072: {
      lat: 35.21106,
      lng: -97.50518,
      city: 'Norman',
      state: 'OK',
    },
    73073: {
      lat: 36.16302,
      lng: -97.39614,
      city: 'Orlando',
      state: 'OK',
    },
    73074: {
      lat: 34.83269,
      lng: -97.27917,
      city: 'Paoli',
      state: 'OK',
    },
    73075: {
      lat: 34.75187,
      lng: -97.23428,
      city: 'Pauls Valley',
      state: 'OK',
    },
    73077: {
      lat: 36.30428,
      lng: -97.26981,
      city: 'Perry',
      state: 'OK',
    },
    73078: {
      lat: 35.66867,
      lng: -97.75304,
      city: 'Piedmont',
      state: 'OK',
    },
    73079: {
      lat: 35.20941,
      lng: -98.01397,
      city: 'Pocasset',
      state: 'OK',
    },
    73080: {
      lat: 35.00725,
      lng: -97.452,
      city: 'Purcell',
      state: 'OK',
    },
    73082: {
      lat: 34.77266,
      lng: -97.90385,
      city: 'Rush Springs',
      state: 'OK',
    },
    73084: {
      lat: 35.52567,
      lng: -97.34645,
      city: 'Spencer',
      state: 'OK',
    },
    73086: {
      lat: 34.48915,
      lng: -96.97251,
      city: 'Sulphur',
      state: 'OK',
    },
    73089: {
      lat: 35.2661,
      lng: -97.79638,
      city: 'Tuttle',
      state: 'OK',
    },
    73090: {
      lat: 35.39706,
      lng: -97.96116,
      city: 'Union City',
      state: 'OK',
    },
    73092: {
      lat: 35.1238,
      lng: -98.08735,
      city: 'Verden',
      state: 'OK',
    },
    73093: {
      lat: 35.10321,
      lng: -97.4966,
      city: 'Washington',
      state: 'OK',
    },
    73095: {
      lat: 34.90677,
      lng: -97.28423,
      city: 'Wayne',
      state: 'OK',
    },
    73096: {
      lat: 35.51305,
      lng: -98.7295,
      city: 'Weatherford',
      state: 'OK',
    },
    73097: {
      lat: 35.39893,
      lng: -97.64501,
      city: 'Wheatland',
      state: 'OK',
    },
    73098: {
      lat: 34.62753,
      lng: -97.16537,
      city: 'Wynnewood',
      state: 'OK',
    },
    73099: {
      lat: 35.52583,
      lng: -97.76343,
      city: 'Yukon',
      state: 'OK',
    },
    73102: {
      lat: 35.47065,
      lng: -97.5191,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73103: {
      lat: 35.48906,
      lng: -97.51897,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73104: {
      lat: 35.47508,
      lng: -97.5038,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73105: {
      lat: 35.51919,
      lng: -97.50331,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73106: {
      lat: 35.4823,
      lng: -97.53662,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73107: {
      lat: 35.48201,
      lng: -97.5744,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73108: {
      lat: 35.45034,
      lng: -97.56774,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73109: {
      lat: 35.43279,
      lng: -97.52452,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73110: {
      lat: 35.44808,
      lng: -97.3945,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73111: {
      lat: 35.51889,
      lng: -97.47722,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73112: {
      lat: 35.51823,
      lng: -97.5745,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73114: {
      lat: 35.57916,
      lng: -97.51784,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73115: {
      lat: 35.44232,
      lng: -97.44153,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73116: {
      lat: 35.54684,
      lng: -97.56519,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73117: {
      lat: 35.47474,
      lng: -97.45961,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73118: {
      lat: 35.51788,
      lng: -97.53007,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73119: {
      lat: 35.4215,
      lng: -97.56896,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73120: {
      lat: 35.58049,
      lng: -97.57248,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73121: {
      lat: 35.52181,
      lng: -97.44236,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73122: {
      lat: 35.5202,
      lng: -97.61391,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73127: {
      lat: 35.47785,
      lng: -97.64836,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73128: {
      lat: 35.44899,
      lng: -97.64595,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73129: {
      lat: 35.43165,
      lng: -97.48433,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73130: {
      lat: 35.45879,
      lng: -97.34586,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73131: {
      lat: 35.57653,
      lng: -97.46465,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73132: {
      lat: 35.54984,
      lng: -97.64074,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73134: {
      lat: 35.6151,
      lng: -97.56935,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73135: {
      lat: 35.39644,
      lng: -97.42692,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73139: {
      lat: 35.38457,
      lng: -97.52572,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73141: {
      lat: 35.51702,
      lng: -97.39876,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73142: {
      lat: 35.60937,
      lng: -97.63895,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73145: {
      lat: 35.42427,
      lng: -97.41403,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73149: {
      lat: 35.3892,
      lng: -97.48908,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73150: {
      lat: 35.40059,
      lng: -97.33214,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73151: {
      lat: 35.56783,
      lng: -97.41049,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73159: {
      lat: 35.38563,
      lng: -97.5794,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73160: {
      lat: 35.33574,
      lng: -97.47651,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73162: {
      lat: 35.58095,
      lng: -97.6415,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73165: {
      lat: 35.34181,
      lng: -97.36138,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73169: {
      lat: 35.3815,
      lng: -97.64538,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73170: {
      lat: 35.32743,
      lng: -97.55068,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73173: {
      lat: 35.34033,
      lng: -97.62463,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73179: {
      lat: 35.4231,
      lng: -97.64521,
      city: 'Oklahoma City',
      state: 'OK',
    },
    73401: {
      lat: 34.21045,
      lng: -97.11434,
      city: 'Ardmore',
      state: 'OK',
    },
    73425: {
      lat: 34.45838,
      lng: -97.56239,
      city: 'Countyline',
      state: 'OK',
    },
    73430: {
      lat: 33.91,
      lng: -97.36106,
      city: 'Burneyville',
      state: 'OK',
    },
    73432: {
      lat: 34.25605,
      lng: -96.42311,
      city: 'Coleman',
      state: 'OK',
    },
    73433: {
      lat: 34.62352,
      lng: -97.40868,
      city: 'Elmore City',
      state: 'OK',
    },
    73434: {
      lat: 34.62083,
      lng: -97.58286,
      city: 'Foster',
      state: 'OK',
    },
    73435: { lat: 34.36008, lng: -97.49281, city: 'Fox', state: 'OK' },
    73436: {
      lat: 34.28406,
      lng: -97.03856,
      city: 'Gene Autry',
      state: 'OK',
    },
    73437: {
      lat: 34.36193,
      lng: -97.41558,
      city: 'Graham',
      state: 'OK',
    },
    73438: {
      lat: 34.27967,
      lng: -97.49808,
      city: 'Healdton',
      state: 'OK',
    },
    73439: {
      lat: 33.93643,
      lng: -96.74708,
      city: 'Kingston',
      state: 'OK',
    },
    73440: {
      lat: 33.95163,
      lng: -96.89635,
      city: 'Lebanon',
      state: 'OK',
    },
    73441: { lat: 33.8674, lng: -97.44021, city: 'Leon', state: 'OK' },
    73442: { lat: 34.31982, lng: -97.69569, city: 'Loco', state: 'OK' },
    73443: {
      lat: 34.16258,
      lng: -97.29081,
      city: 'Lone Grove',
      state: 'OK',
    },
    73444: {
      lat: 34.4875,
      lng: -97.38256,
      city: 'Hennepin',
      state: 'OK',
    },
    73446: {
      lat: 34.0891,
      lng: -96.77806,
      city: 'Madill',
      state: 'OK',
    },
    73447: {
      lat: 34.22463,
      lng: -96.84616,
      city: 'Mannsville',
      state: 'OK',
    },
    73448: {
      lat: 33.94813,
      lng: -97.10581,
      city: 'Marietta',
      state: 'OK',
    },
    73449: { lat: 33.99471, lng: -96.54114, city: 'Mead', state: 'OK' },
    73450: {
      lat: 34.26689,
      lng: -96.53801,
      city: 'Milburn',
      state: 'OK',
    },
    73453: {
      lat: 34.03473,
      lng: -97.27046,
      city: 'Overbrook',
      state: 'OK',
    },
    73455: {
      lat: 34.22651,
      lng: -96.75997,
      city: 'Ravia',
      state: 'OK',
    },
    73456: {
      lat: 34.12211,
      lng: -97.62412,
      city: 'Ringling',
      state: 'OK',
    },
    73458: {
      lat: 34.35818,
      lng: -97.29782,
      city: 'Springer',
      state: 'OK',
    },
    73459: {
      lat: 33.77534,
      lng: -97.14213,
      city: 'Thackerville',
      state: 'OK',
    },
    73460: {
      lat: 34.28789,
      lng: -96.66689,
      city: 'Tishomingo',
      state: 'OK',
    },
    73461: {
      lat: 34.35726,
      lng: -96.43418,
      city: 'Wapanucka',
      state: 'OK',
    },
    73463: {
      lat: 34.11584,
      lng: -97.42905,
      city: 'Wilson',
      state: 'OK',
    },
    73481: {
      lat: 34.42229,
      lng: -97.51955,
      city: 'Ratliff City',
      state: 'OK',
    },
    73487: {
      lat: 34.47327,
      lng: -97.4428,
      city: 'Tatums',
      state: 'OK',
    },
    73488: {
      lat: 34.49502,
      lng: -97.54724,
      city: 'Tussy',
      state: 'OK',
    },
    73491: { lat: 34.4637, lng: -97.65845, city: 'Velma', state: 'OK' },
    73501: {
      lat: 34.56211,
      lng: -98.2864,
      city: 'Lawton',
      state: 'OK',
    },
    73503: {
      lat: 34.67348,
      lng: -98.49649,
      city: 'Fort Sill',
      state: 'OK',
    },
    73505: { lat: 34.5718, lng: -98.4834, city: 'Lawton', state: 'OK' },
    73507: {
      lat: 34.74802,
      lng: -98.50201,
      city: 'Lawton',
      state: 'OK',
    },
    73520: {
      lat: 34.26148,
      lng: -97.97856,
      city: 'Addington',
      state: 'OK',
    },
    73521: {
      lat: 34.63547,
      lng: -99.31538,
      city: 'Altus',
      state: 'OK',
    },
    73523: {
      lat: 34.65526,
      lng: -99.28527,
      city: 'Altus Afb',
      state: 'OK',
    },
    73526: {
      lat: 34.79244,
      lng: -99.26704,
      city: 'Blair',
      state: 'OK',
    },
    73527: { lat: 34.5724, lng: -98.62704, city: 'Cache', state: 'OK' },
    73528: {
      lat: 34.4033,
      lng: -98.6452,
      city: 'Chattanooga',
      state: 'OK',
    },
    73529: {
      lat: 34.34146,
      lng: -97.94619,
      city: 'Comanche',
      state: 'OK',
    },
    73530: {
      lat: 34.25845,
      lng: -99.05664,
      city: 'Davidson',
      state: 'OK',
    },
    73531: {
      lat: 34.18845,
      lng: -98.52703,
      city: 'Devol',
      state: 'OK',
    },
    73532: { lat: 34.62768, lng: -99.59637, city: 'Duke', state: 'OK' },
    73533: {
      lat: 34.49484,
      lng: -97.84774,
      city: 'Duncan',
      state: 'OK',
    },
    73537: {
      lat: 34.46994,
      lng: -99.67189,
      city: 'Eldorado',
      state: 'OK',
    },
    73538: {
      lat: 34.74056,
      lng: -98.30743,
      city: 'Elgin',
      state: 'OK',
    },
    73539: {
      lat: 34.45202,
      lng: -99.27649,
      city: 'Elmer',
      state: 'OK',
    },
    73540: {
      lat: 34.47099,
      lng: -98.55756,
      city: 'Faxon',
      state: 'OK',
    },
    73541: {
      lat: 34.78716,
      lng: -98.1788,
      city: 'Fletcher',
      state: 'OK',
    },
    73542: {
      lat: 34.4024,
      lng: -98.93893,
      city: 'Frederick',
      state: 'OK',
    },
    73543: {
      lat: 34.46389,
      lng: -98.38385,
      city: 'Geronimo',
      state: 'OK',
    },
    73544: {
      lat: 34.65379,
      lng: -99.75016,
      city: 'Gould',
      state: 'OK',
    },
    73546: {
      lat: 34.22336,
      lng: -98.73053,
      city: 'Grandfield',
      state: 'OK',
    },
    73547: {
      lat: 34.98651,
      lng: -99.40507,
      city: 'Granite',
      state: 'OK',
    },
    73548: {
      lat: 34.21427,
      lng: -98.13818,
      city: 'Hastings',
      state: 'OK',
    },
    73549: {
      lat: 34.65222,
      lng: -99.15607,
      city: 'Headrick',
      state: 'OK',
    },
    73550: {
      lat: 34.7101,
      lng: -99.89631,
      city: 'Hollis',
      state: 'OK',
    },
    73551: {
      lat: 34.30754,
      lng: -98.87487,
      city: 'Hollister',
      state: 'OK',
    },
    73552: {
      lat: 34.62709,
      lng: -98.75631,
      city: 'Indiahoma',
      state: 'OK',
    },
    73553: {
      lat: 34.33066,
      lng: -98.70295,
      city: 'Loveland',
      state: 'OK',
    },
    73554: {
      lat: 34.85562,
      lng: -99.60118,
      city: 'Mangum',
      state: 'OK',
    },
    73555: {
      lat: 34.50539,
      lng: -98.9639,
      city: 'Manitou',
      state: 'OK',
    },
    73556: {
      lat: 34.72617,
      lng: -99.38673,
      city: 'Martha',
      state: 'OK',
    },
    73557: {
      lat: 34.73072,
      lng: -98.50022,
      city: 'Medicine Park',
      state: 'OK',
    },
    73559: {
      lat: 34.73473,
      lng: -98.94317,
      city: 'Mountain Park',
      state: 'OK',
    },
    73560: {
      lat: 34.54307,
      lng: -99.45024,
      city: 'Olustee',
      state: 'OK',
    },
    73562: {
      lat: 34.19469,
      lng: -98.41203,
      city: 'Randlett',
      state: 'OK',
    },
    73564: {
      lat: 34.84444,
      lng: -99.00729,
      city: 'Roosevelt',
      state: 'OK',
    },
    73565: { lat: 34.02326, lng: -97.8751, city: 'Ryan', state: 'OK' },
    73566: {
      lat: 34.61647,
      lng: -98.94258,
      city: 'Snyder',
      state: 'OK',
    },
    73567: {
      lat: 34.7474,
      lng: -98.17093,
      city: 'Sterling',
      state: 'OK',
    },
    73568: {
      lat: 34.26327,
      lng: -98.21629,
      city: 'Temple',
      state: 'OK',
    },
    73569: {
      lat: 33.93772,
      lng: -97.80363,
      city: 'Terral',
      state: 'OK',
    },
    73570: {
      lat: 34.50988,
      lng: -99.1071,
      city: 'Tipton',
      state: 'OK',
    },
    73571: {
      lat: 34.9325,
      lng: -99.92476,
      city: 'Vinson',
      state: 'OK',
    },
    73572: {
      lat: 34.37318,
      lng: -98.32638,
      city: 'Walters',
      state: 'OK',
    },
    73573: {
      lat: 34.1556,
      lng: -97.95412,
      city: 'Waurika',
      state: 'OK',
    },
    73601: {
      lat: 35.49859,
      lng: -98.94694,
      city: 'Clinton',
      state: 'OK',
    },
    73620: {
      lat: 35.61387,
      lng: -99.02278,
      city: 'Arapaho',
      state: 'OK',
    },
    73622: {
      lat: 35.39518,
      lng: -98.99791,
      city: 'Bessie',
      state: 'OK',
    },
    73624: {
      lat: 35.35969,
      lng: -99.1719,
      city: 'Burns Flat',
      state: 'OK',
    },
    73625: {
      lat: 35.6964,
      lng: -99.19698,
      city: 'Butler',
      state: 'OK',
    },
    73626: {
      lat: 35.43965,
      lng: -99.27941,
      city: 'Canute',
      state: 'OK',
    },
    73627: {
      lat: 35.20169,
      lng: -99.45218,
      city: 'Carter',
      state: 'OK',
    },
    73628: {
      lat: 35.62668,
      lng: -99.68783,
      city: 'Cheyenne',
      state: 'OK',
    },
    73632: {
      lat: 35.27036,
      lng: -98.90853,
      city: 'Cordell',
      state: 'OK',
    },
    73638: {
      lat: 35.86532,
      lng: -99.79059,
      city: 'Crawford',
      state: 'OK',
    },
    73639: {
      lat: 35.72666,
      lng: -98.95578,
      city: 'Custer City',
      state: 'OK',
    },
    73641: {
      lat: 35.26875,
      lng: -99.20252,
      city: 'Dill City',
      state: 'OK',
    },
    73642: {
      lat: 35.81909,
      lng: -99.90887,
      city: 'Durham',
      state: 'OK',
    },
    73644: {
      lat: 35.40327,
      lng: -99.42718,
      city: 'Elk City',
      state: 'OK',
    },
    73645: {
      lat: 35.16954,
      lng: -99.87038,
      city: 'Erick',
      state: 'OK',
    },
    73646: { lat: 35.82624, lng: -98.61928, city: 'Fay', state: 'OK' },
    73647: { lat: 35.44786, lng: -99.16124, city: 'Foss', state: 'OK' },
    73650: {
      lat: 35.64173,
      lng: -99.41729,
      city: 'Hammon',
      state: 'OK',
    },
    73651: {
      lat: 35.02479,
      lng: -99.06957,
      city: 'Hobart',
      state: 'OK',
    },
    73654: {
      lat: 35.85908,
      lng: -99.37483,
      city: 'Leedey',
      state: 'OK',
    },
    73655: {
      lat: 34.98189,
      lng: -99.25267,
      city: 'Lone Wolf',
      state: 'OK',
    },
    73658: {
      lat: 35.94776,
      lng: -98.71784,
      city: 'Oakwood',
      state: 'OK',
    },
    73659: {
      lat: 35.87337,
      lng: -98.94582,
      city: 'Putnam',
      state: 'OK',
    },
    73660: {
      lat: 35.62561,
      lng: -99.91688,
      city: 'Reydon',
      state: 'OK',
    },
    73661: { lat: 35.17033, lng: -99.0176, city: 'Rocky', state: 'OK' },
    73662: {
      lat: 35.31533,
      lng: -99.66273,
      city: 'Sayre',
      state: 'OK',
    },
    73663: {
      lat: 36.10622,
      lng: -98.90291,
      city: 'Seiling',
      state: 'OK',
    },
    73664: {
      lat: 35.17475,
      lng: -99.2369,
      city: 'Sentinel',
      state: 'OK',
    },
    73666: {
      lat: 35.42036,
      lng: -99.92412,
      city: 'Sweetwater',
      state: 'OK',
    },
    73667: {
      lat: 36.00523,
      lng: -99.03062,
      city: 'Taloga',
      state: 'OK',
    },
    73668: {
      lat: 35.22329,
      lng: -99.9802,
      city: 'Texola',
      state: 'OK',
    },
    73669: {
      lat: 35.75095,
      lng: -98.74281,
      city: 'Thomas',
      state: 'OK',
    },
    73673: {
      lat: 35.04649,
      lng: -99.63762,
      city: 'Willow',
      state: 'OK',
    },
    73701: { lat: 36.42323, lng: -97.78833, city: 'Enid', state: 'OK' },
    73703: { lat: 36.44022, lng: -97.9634, city: 'Enid', state: 'OK' },
    73705: { lat: 36.33918, lng: -97.90208, city: 'Enid', state: 'OK' },
    73716: {
      lat: 36.48417,
      lng: -98.55316,
      city: 'Aline',
      state: 'OK',
    },
    73717: { lat: 36.84161, lng: -98.77807, city: 'Alva', state: 'OK' },
    73718: { lat: 36.20799, lng: -98.16898, city: 'Ames', state: 'OK' },
    73719: {
      lat: 36.96026,
      lng: -98.26746,
      city: 'Amorita',
      state: 'OK',
    },
    73720: { lat: 36.1973, lng: -97.87038, city: 'Bison', state: 'OK' },
    73722: {
      lat: 36.90196,
      lng: -98.36445,
      city: 'Burlington',
      state: 'OK',
    },
    73724: {
      lat: 36.03757,
      lng: -98.67235,
      city: 'Canton',
      state: 'OK',
    },
    73726: {
      lat: 36.58022,
      lng: -98.50405,
      city: 'Carmen',
      state: 'OK',
    },
    73727: {
      lat: 36.48309,
      lng: -98.03839,
      city: 'Carrier',
      state: 'OK',
    },
    73728: {
      lat: 36.73508,
      lng: -98.39376,
      city: 'Cherokee',
      state: 'OK',
    },
    73729: {
      lat: 36.39723,
      lng: -98.45256,
      city: 'Cleo Springs',
      state: 'OK',
    },
    73730: {
      lat: 36.31754,
      lng: -97.54765,
      city: 'Covington',
      state: 'OK',
    },
    73731: {
      lat: 36.64397,
      lng: -98.59915,
      city: 'Dacoma',
      state: 'OK',
    },
    73733: {
      lat: 36.2497,
      lng: -97.69104,
      city: 'Douglas',
      state: 'OK',
    },
    73734: { lat: 35.98453, lng: -97.8534, city: 'Dover', state: 'OK' },
    73735: {
      lat: 36.27594,
      lng: -98.05132,
      city: 'Drummond',
      state: 'OK',
    },
    73736: {
      lat: 36.34607,
      lng: -97.68751,
      city: 'Fairmont',
      state: 'OK',
    },
    73737: {
      lat: 36.29133,
      lng: -98.58517,
      city: 'Fairview',
      state: 'OK',
    },
    73738: {
      lat: 36.44463,
      lng: -97.55315,
      city: 'Garber',
      state: 'OK',
    },
    73739: {
      lat: 36.51999,
      lng: -98.13697,
      city: 'Goltry',
      state: 'OK',
    },
    73741: {
      lat: 36.55071,
      lng: -98.26619,
      city: 'Helena',
      state: 'OK',
    },
    73742: {
      lat: 36.09632,
      lng: -97.91907,
      city: 'Hennessey',
      state: 'OK',
    },
    73743: {
      lat: 36.56324,
      lng: -97.9927,
      city: 'Hillsdale',
      state: 'OK',
    },
    73744: {
      lat: 35.94912,
      lng: -98.29643,
      city: 'Hitchcock',
      state: 'OK',
    },
    73746: {
      lat: 36.6882,
      lng: -98.66202,
      city: 'Hopeton',
      state: 'OK',
    },
    73747: {
      lat: 36.23358,
      lng: -98.33964,
      city: 'Isabella',
      state: 'OK',
    },
    73749: { lat: 36.70566, lng: -98.16283, city: 'Jet', state: 'OK' },
    73750: {
      lat: 35.8534,
      lng: -97.94435,
      city: 'Kingfisher',
      state: 'OK',
    },
    73753: {
      lat: 36.55277,
      lng: -97.86976,
      city: 'Kremlin',
      state: 'OK',
    },
    73754: {
      lat: 36.39016,
      lng: -98.09455,
      city: 'Lahoma',
      state: 'OK',
    },
    73755: {
      lat: 36.13717,
      lng: -98.5889,
      city: 'Longdale',
      state: 'OK',
    },
    73756: {
      lat: 35.97507,
      lng: -98.11439,
      city: 'Loyal',
      state: 'OK',
    },
    73757: {
      lat: 36.27601,
      lng: -97.44935,
      city: 'Lucien',
      state: 'OK',
    },
    73758: {
      lat: 36.93239,
      lng: -98.0875,
      city: 'Manchester',
      state: 'OK',
    },
    73759: {
      lat: 36.85815,
      lng: -97.71362,
      city: 'Medford',
      state: 'OK',
    },
    73760: { lat: 36.38658, lng: -98.1693, city: 'Meno', state: 'OK' },
    73761: { lat: 36.68452, lng: -98.03647, city: 'Nash', state: 'OK' },
    73762: {
      lat: 35.71197,
      lng: -97.97442,
      city: 'Okarche',
      state: 'OK',
    },
    73763: {
      lat: 36.10302,
      lng: -98.3201,
      city: 'Okeene',
      state: 'OK',
    },
    73764: {
      lat: 35.85738,
      lng: -98.20307,
      city: 'Omega',
      state: 'OK',
    },
    73766: {
      lat: 36.65004,
      lng: -97.83846,
      city: 'Pond Creek',
      state: 'OK',
    },
    73768: {
      lat: 36.36265,
      lng: -98.27907,
      city: 'Ringwood',
      state: 'OK',
    },
    73770: {
      lat: 36.05406,
      lng: -98.49791,
      city: 'Southard',
      state: 'OK',
    },
    73771: {
      lat: 36.87533,
      lng: -97.95653,
      city: 'Wakita',
      state: 'OK',
    },
    73772: {
      lat: 35.86327,
      lng: -98.42369,
      city: 'Watonga',
      state: 'OK',
    },
    73773: {
      lat: 36.25804,
      lng: -97.91922,
      city: 'Waukomis',
      state: 'OK',
    },
    73801: {
      lat: 36.4733,
      lng: -99.4307,
      city: 'Woodward',
      state: 'OK',
    },
    73832: {
      lat: 36.05298,
      lng: -99.65687,
      city: 'Arnett',
      state: 'OK',
    },
    73834: {
      lat: 36.84807,
      lng: -99.57156,
      city: 'Buffalo',
      state: 'OK',
    },
    73835: {
      lat: 35.97327,
      lng: -99.21279,
      city: 'Camargo',
      state: 'OK',
    },
    73838: {
      lat: 36.24575,
      lng: -98.87579,
      city: 'Chester',
      state: 'OK',
    },
    73840: {
      lat: 36.39436,
      lng: -99.64581,
      city: 'Fargo',
      state: 'OK',
    },
    73841: {
      lat: 36.54799,
      lng: -99.61924,
      city: 'Fort Supply',
      state: 'OK',
    },
    73842: {
      lat: 36.8212,
      lng: -99.21452,
      city: 'Freedom',
      state: 'OK',
    },
    73843: { lat: 36.39805, lng: -99.83566, city: 'Gage', state: 'OK' },
    73844: {
      lat: 36.89715,
      lng: -100.16763,
      city: 'Gate',
      state: 'OK',
    },
    73848: {
      lat: 36.64843,
      lng: -100.023,
      city: 'Laverne',
      state: 'OK',
    },
    73851: { lat: 36.57298, lng: -99.78406, city: 'May', state: 'OK' },
    73852: {
      lat: 36.4873,
      lng: -99.10798,
      city: 'Mooreland',
      state: 'OK',
    },
    73853: {
      lat: 36.24333,
      lng: -99.15664,
      city: 'Mutual',
      state: 'OK',
    },
    73855: {
      lat: 36.88481,
      lng: -99.85877,
      city: 'Rosston',
      state: 'OK',
    },
    73857: {
      lat: 36.27223,
      lng: -99.33235,
      city: 'Sharon',
      state: 'OK',
    },
    73858: {
      lat: 36.17037,
      lng: -99.92442,
      city: 'Shattuck',
      state: 'OK',
    },
    73859: { lat: 36.13309, lng: -99.2797, city: 'Vici', state: 'OK' },
    73860: {
      lat: 36.53573,
      lng: -98.86265,
      city: 'Waynoka',
      state: 'OK',
    },
    73901: {
      lat: 36.75458,
      lng: -101.07517,
      city: 'Adams',
      state: 'OK',
    },
    73931: {
      lat: 36.60097,
      lng: -100.80329,
      city: 'Balko',
      state: 'OK',
    },
    73932: { lat: 36.7, lng: -100.51914, city: 'Beaver', state: 'OK' },
    73933: {
      lat: 36.7436,
      lng: -102.58198,
      city: 'Boise City',
      state: 'OK',
    },
    73937: { lat: 36.5642, lng: -102.76703, city: 'Felt', state: 'OK' },
    73938: {
      lat: 36.92585,
      lng: -100.58497,
      city: 'Forgan',
      state: 'OK',
    },
    73939: {
      lat: 36.76824,
      lng: -101.78344,
      city: 'Goodwell',
      state: 'OK',
    },
    73942: {
      lat: 36.74452,
      lng: -101.49555,
      city: 'Guymon',
      state: 'OK',
    },
    73944: {
      lat: 36.58511,
      lng: -101.10339,
      city: 'Hardesty',
      state: 'OK',
    },
    73945: {
      lat: 36.8321,
      lng: -101.22474,
      city: 'Hooker',
      state: 'OK',
    },
    73946: {
      lat: 36.92316,
      lng: -102.81258,
      city: 'Kenton',
      state: 'OK',
    },
    73947: {
      lat: 36.84672,
      lng: -102.17316,
      city: 'Keyes',
      state: 'OK',
    },
    73949: {
      lat: 36.5929,
      lng: -101.98559,
      city: 'Texhoma',
      state: 'OK',
    },
    73950: {
      lat: 36.85193,
      lng: -100.88621,
      city: 'Turpin',
      state: 'OK',
    },
    73951: {
      lat: 36.95004,
      lng: -101.05463,
      city: 'Tyrone',
      state: 'OK',
    },
    73960: {
      lat: 36.49167,
      lng: -101.79265,
      city: 'Texhoma',
      state: 'TX',
    },
    74001: { lat: 36.48857, lng: -96.0625, city: 'Avant', state: 'OK' },
    74002: {
      lat: 36.5613,
      lng: -96.14528,
      city: 'Barnsdall',
      state: 'OK',
    },
    74003: {
      lat: 36.73107,
      lng: -96.06675,
      city: 'Bartlesville',
      state: 'OK',
    },
    74006: {
      lat: 36.71507,
      lng: -95.89391,
      city: 'Bartlesville',
      state: 'OK',
    },
    74008: {
      lat: 35.92614,
      lng: -95.87575,
      city: 'Bixby',
      state: 'OK',
    },
    74010: {
      lat: 35.80945,
      lng: -96.36101,
      city: 'Bristow',
      state: 'OK',
    },
    74011: {
      lat: 35.98154,
      lng: -95.81007,
      city: 'Broken Arrow',
      state: 'OK',
    },
    74012: {
      lat: 36.05381,
      lng: -95.80509,
      city: 'Broken Arrow',
      state: 'OK',
    },
    74014: {
      lat: 36.05538,
      lng: -95.66819,
      city: 'Broken Arrow',
      state: 'OK',
    },
    74015: {
      lat: 36.18012,
      lng: -95.70952,
      city: 'Catoosa',
      state: 'OK',
    },
    74016: {
      lat: 36.54093,
      lng: -95.45283,
      city: 'Chelsea',
      state: 'OK',
    },
    74017: {
      lat: 36.39359,
      lng: -95.57986,
      city: 'Claremore',
      state: 'OK',
    },
    74019: {
      lat: 36.28326,
      lng: -95.60684,
      city: 'Claremore',
      state: 'OK',
    },
    74020: {
      lat: 36.24562,
      lng: -96.41934,
      city: 'Cleveland',
      state: 'OK',
    },
    74021: {
      lat: 36.37915,
      lng: -95.84758,
      city: 'Collinsville',
      state: 'OK',
    },
    74022: {
      lat: 36.93055,
      lng: -95.98054,
      city: 'Copan',
      state: 'OK',
    },
    74023: {
      lat: 35.95708,
      lng: -96.74963,
      city: 'Cushing',
      state: 'OK',
    },
    74026: {
      lat: 35.72266,
      lng: -96.78282,
      city: 'Davenport',
      state: 'OK',
    },
    74027: {
      lat: 36.80285,
      lng: -95.58628,
      city: 'Delaware',
      state: 'OK',
    },
    74028: {
      lat: 35.79554,
      lng: -96.52259,
      city: 'Depew',
      state: 'OK',
    },
    74029: {
      lat: 36.82558,
      lng: -95.88172,
      city: 'Dewey',
      state: 'OK',
    },
    74030: {
      lat: 35.98508,
      lng: -96.5673,
      city: 'Drumright',
      state: 'OK',
    },
    74032: {
      lat: 36.2115,
      lng: -96.90402,
      city: 'Glencoe',
      state: 'OK',
    },
    74033: {
      lat: 35.94711,
      lng: -96.00636,
      city: 'Glenpool',
      state: 'OK',
    },
    74034: {
      lat: 36.23266,
      lng: -96.56288,
      city: 'Hallett',
      state: 'OK',
    },
    74035: {
      lat: 36.40368,
      lng: -96.37607,
      city: 'Hominy',
      state: 'OK',
    },
    74036: {
      lat: 36.15713,
      lng: -95.50497,
      city: 'Inola',
      state: 'OK',
    },
    74037: {
      lat: 36.00149,
      lng: -95.98171,
      city: 'Jenks',
      state: 'OK',
    },
    74038: {
      lat: 36.1552,
      lng: -96.56151,
      city: 'Jennings',
      state: 'OK',
    },
    74039: {
      lat: 35.89822,
      lng: -96.22842,
      city: 'Kellyville',
      state: 'OK',
    },
    74041: {
      lat: 35.93808,
      lng: -96.05545,
      city: 'Kiefer',
      state: 'OK',
    },
    74042: {
      lat: 36.88381,
      lng: -95.55223,
      city: 'Lenapah',
      state: 'OK',
    },
    74044: {
      lat: 36.08964,
      lng: -96.38117,
      city: 'Mannford',
      state: 'OK',
    },
    74045: {
      lat: 36.22153,
      lng: -96.70049,
      city: 'Maramec',
      state: 'OK',
    },
    74046: {
      lat: 35.75455,
      lng: -96.56969,
      city: 'Milfay',
      state: 'OK',
    },
    74047: {
      lat: 35.85522,
      lng: -96.02583,
      city: 'Mounds',
      state: 'OK',
    },
    74048: {
      lat: 36.69265,
      lng: -95.65192,
      city: 'Nowata',
      state: 'OK',
    },
    74050: {
      lat: 36.07221,
      lng: -96.06546,
      city: 'Oakhurst',
      state: 'OK',
    },
    74051: {
      lat: 36.61412,
      lng: -95.96532,
      city: 'Ochelata',
      state: 'OK',
    },
    74052: {
      lat: 36.07076,
      lng: -96.5738,
      city: 'Oilton',
      state: 'OK',
    },
    74053: {
      lat: 36.43419,
      lng: -95.73282,
      city: 'Oologah',
      state: 'OK',
    },
    74054: {
      lat: 36.27299,
      lng: -96.32962,
      city: 'Osage',
      state: 'OK',
    },
    74055: {
      lat: 36.27735,
      lng: -95.81998,
      city: 'Owasso',
      state: 'OK',
    },
    74056: {
      lat: 36.79706,
      lng: -96.33873,
      city: 'Pawhuska',
      state: 'OK',
    },
    74058: {
      lat: 36.35499,
      lng: -96.75909,
      city: 'Pawnee',
      state: 'OK',
    },
    74059: {
      lat: 35.95736,
      lng: -97.06255,
      city: 'Perkins',
      state: 'OK',
    },
    74060: { lat: 36.2472, lng: -96.25434, city: 'Prue', state: 'OK' },
    74061: {
      lat: 36.53814,
      lng: -95.89974,
      city: 'Ramona',
      state: 'OK',
    },
    74062: {
      lat: 36.03481,
      lng: -96.89727,
      city: 'Ripley',
      state: 'OK',
    },
    74063: {
      lat: 36.15201,
      lng: -96.19206,
      city: 'Sand Springs',
      state: 'OK',
    },
    74066: {
      lat: 35.99379,
      lng: -96.1676,
      city: 'Sapulpa',
      state: 'OK',
    },
    74068: {
      lat: 35.90829,
      lng: -96.57498,
      city: 'Shamrock',
      state: 'OK',
    },
    74070: {
      lat: 36.3881,
      lng: -96.07253,
      city: 'Skiatook',
      state: 'OK',
    },
    74071: {
      lat: 35.77835,
      lng: -96.26467,
      city: 'Slick',
      state: 'OK',
    },
    74072: {
      lat: 36.94209,
      lng: -95.54436,
      city: 'S Coffeyville',
      state: 'OK',
    },
    74073: {
      lat: 36.29818,
      lng: -96.01326,
      city: 'Sperry',
      state: 'OK',
    },
    74074: {
      lat: 36.07832,
      lng: -97.06452,
      city: 'Stillwater',
      state: 'OK',
    },
    74075: {
      lat: 36.17151,
      lng: -97.0774,
      city: 'Stillwater',
      state: 'OK',
    },
    74077: {
      lat: 36.12398,
      lng: -97.06901,
      city: 'Stillwater',
      state: 'OK',
    },
    74078: {
      lat: 36.12528,
      lng: -97.07969,
      city: 'Stillwater',
      state: 'OK',
    },
    74079: {
      lat: 35.74368,
      lng: -96.65592,
      city: 'Stroud',
      state: 'OK',
    },
    74080: {
      lat: 36.52849,
      lng: -95.74192,
      city: 'Talala',
      state: 'OK',
    },
    74081: {
      lat: 36.17536,
      lng: -96.45875,
      city: 'Terlton',
      state: 'OK',
    },
    74082: { lat: 36.44889, lng: -95.88091, city: 'Vera', state: 'OK' },
    74083: { lat: 36.91504, lng: -95.77093, city: 'Wann', state: 'OK' },
    74084: {
      lat: 36.52849,
      lng: -96.33325,
      city: 'Wynona',
      state: 'OK',
    },
    74085: { lat: 36.10795, lng: -96.715, city: 'Yale', state: 'OK' },
    74103: { lat: 36.15617, lng: -95.9957, city: 'Tulsa', state: 'OK' },
    74104: { lat: 36.14627, lng: -95.9542, city: 'Tulsa', state: 'OK' },
    74105: { lat: 36.0975, lng: -95.9641, city: 'Tulsa', state: 'OK' },
    74106: {
      lat: 36.19191,
      lng: -95.98416,
      city: 'Tulsa',
      state: 'OK',
    },
    74107: {
      lat: 36.10737,
      lng: -96.04095,
      city: 'Tulsa',
      state: 'OK',
    },
    74108: {
      lat: 36.14723,
      lng: -95.79154,
      city: 'Tulsa',
      state: 'OK',
    },
    74110: {
      lat: 36.18856,
      lng: -95.95377,
      city: 'Tulsa',
      state: 'OK',
    },
    74112: {
      lat: 36.14696,
      lng: -95.90437,
      city: 'Tulsa',
      state: 'OK',
    },
    74114: {
      lat: 36.12696,
      lng: -95.94661,
      city: 'Tulsa',
      state: 'OK',
    },
    74115: { lat: 36.1923, lng: -95.90903, city: 'Tulsa', state: 'OK' },
    74116: {
      lat: 36.18814,
      lng: -95.83621,
      city: 'Tulsa',
      state: 'OK',
    },
    74117: {
      lat: 36.24146,
      lng: -95.89854,
      city: 'Tulsa',
      state: 'OK',
    },
    74119: { lat: 36.14205, lng: -95.9894, city: 'Tulsa', state: 'OK' },
    74120: { lat: 36.1504, lng: -95.97689, city: 'Tulsa', state: 'OK' },
    74126: { lat: 36.23502, lng: -96.0331, city: 'Tulsa', state: 'OK' },
    74127: {
      lat: 36.17462,
      lng: -96.04511,
      city: 'Tulsa',
      state: 'OK',
    },
    74128: { lat: 36.1472, lng: -95.85089, city: 'Tulsa', state: 'OK' },
    74129: { lat: 36.12669, lng: -95.8701, city: 'Tulsa', state: 'OK' },
    74130: {
      lat: 36.24172,
      lng: -95.95536,
      city: 'Tulsa',
      state: 'OK',
    },
    74131: {
      lat: 36.05172,
      lng: -96.07291,
      city: 'Tulsa',
      state: 'OK',
    },
    74132: {
      lat: 36.04869,
      lng: -96.01315,
      city: 'Tulsa',
      state: 'OK',
    },
    74133: {
      lat: 36.04113,
      lng: -95.87955,
      city: 'Tulsa',
      state: 'OK',
    },
    74134: { lat: 36.1106, lng: -95.80156, city: 'Tulsa', state: 'OK' },
    74135: {
      lat: 36.09725,
      lng: -95.92315,
      city: 'Tulsa',
      state: 'OK',
    },
    74136: { lat: 36.06188, lng: -95.9419, city: 'Tulsa', state: 'OK' },
    74137: {
      lat: 36.02038,
      lng: -95.92932,
      city: 'Tulsa',
      state: 'OK',
    },
    74145: {
      lat: 36.09728,
      lng: -95.88731,
      city: 'Tulsa',
      state: 'OK',
    },
    74146: {
      lat: 36.09784,
      lng: -95.85344,
      city: 'Tulsa',
      state: 'OK',
    },
    74171: {
      lat: 36.05064,
      lng: -95.95253,
      city: 'Tulsa',
      state: 'OK',
    },
    74301: {
      lat: 36.68157,
      lng: -95.23687,
      city: 'Vinita',
      state: 'OK',
    },
    74330: { lat: 36.4404, lng: -95.26851, city: 'Adair', state: 'OK' },
    74331: { lat: 36.64683, lng: -94.9459, city: 'Afton', state: 'OK' },
    74332: {
      lat: 36.52111,
      lng: -95.23864,
      city: 'Big Cabin',
      state: 'OK',
    },
    74333: {
      lat: 36.79386,
      lng: -95.08408,
      city: 'Bluejacket',
      state: 'OK',
    },
    74337: {
      lat: 36.14122,
      lng: -95.33505,
      city: 'Chouteau',
      state: 'OK',
    },
    74338: {
      lat: 36.26234,
      lng: -94.71572,
      city: 'Colcord',
      state: 'OK',
    },
    74339: {
      lat: 36.934,
      lng: -94.87975,
      city: 'Commerce',
      state: 'OK',
    },
    74340: {
      lat: 36.4777,
      lng: -95.01944,
      city: 'Disney',
      state: 'OK',
    },
    74342: {
      lat: 36.42227,
      lng: -94.94113,
      city: 'Eucha',
      state: 'OK',
    },
    74343: {
      lat: 36.74287,
      lng: -94.825,
      city: 'Fairland',
      state: 'OK',
    },
    74344: {
      lat: 36.59604,
      lng: -94.73845,
      city: 'Grove',
      state: 'OK',
    },
    74346: { lat: 36.43427, lng: -94.75066, city: 'Jay', state: 'OK' },
    74347: {
      lat: 36.19573,
      lng: -94.78525,
      city: 'Kansas',
      state: 'OK',
    },
    74349: {
      lat: 36.51234,
      lng: -95.02557,
      city: 'Ketchum',
      state: 'OK',
    },
    74350: {
      lat: 36.46643,
      lng: -95.04776,
      city: 'Langley',
      state: 'OK',
    },
    74352: {
      lat: 36.13693,
      lng: -95.18783,
      city: 'Locust Grove',
      state: 'OK',
    },
    74354: {
      lat: 36.88962,
      lng: -94.88096,
      city: 'Miami',
      state: 'OK',
    },
    74358: {
      lat: 36.91798,
      lng: -94.88052,
      city: 'North Miami',
      state: 'OK',
    },
    74359: { lat: 36.16846, lng: -94.85218, city: 'Oaks', state: 'OK' },
    74360: {
      lat: 36.9801,
      lng: -94.83636,
      city: 'Picher',
      state: 'OK',
    },
    74361: {
      lat: 36.30914,
      lng: -95.30732,
      city: 'Pryor',
      state: 'OK',
    },
    74363: {
      lat: 36.95341,
      lng: -94.70965,
      city: 'Quapaw',
      state: 'OK',
    },
    74364: { lat: 36.19915, lng: -94.95683, city: 'Rose', state: 'OK' },
    74365: {
      lat: 36.29072,
      lng: -95.05461,
      city: 'Salina',
      state: 'OK',
    },
    74366: {
      lat: 36.37887,
      lng: -95.04449,
      city: 'Spavinaw',
      state: 'OK',
    },
    74367: {
      lat: 36.4222,
      lng: -95.10674,
      city: 'Strang',
      state: 'OK',
    },
    74368: {
      lat: 36.20725,
      lng: -94.84822,
      city: 'Twin Oaks',
      state: 'OK',
    },
    74369: {
      lat: 36.92237,
      lng: -95.19776,
      city: 'Welch',
      state: 'OK',
    },
    74370: {
      lat: 36.76429,
      lng: -94.68017,
      city: 'Wyandotte',
      state: 'OK',
    },
    74401: {
      lat: 35.72304,
      lng: -95.45431,
      city: 'Muskogee',
      state: 'OK',
    },
    74403: {
      lat: 35.67427,
      lng: -95.305,
      city: 'Muskogee',
      state: 'OK',
    },
    74421: {
      lat: 35.75928,
      lng: -96.03615,
      city: 'Beggs',
      state: 'OK',
    },
    74422: {
      lat: 35.64156,
      lng: -95.69143,
      city: 'Boynton',
      state: 'OK',
    },
    74423: {
      lat: 35.64251,
      lng: -95.22339,
      city: 'Braggs',
      state: 'OK',
    },
    74425: {
      lat: 35.17184,
      lng: -95.62299,
      city: 'Canadian',
      state: 'OK',
    },
    74426: {
      lat: 35.44553,
      lng: -95.52759,
      city: 'Checotah',
      state: 'OK',
    },
    74427: {
      lat: 35.68638,
      lng: -94.91056,
      city: 'Cookson',
      state: 'OK',
    },
    74428: {
      lat: 35.54835,
      lng: -95.64683,
      city: 'Council Hill',
      state: 'OK',
    },
    74429: {
      lat: 35.94872,
      lng: -95.63065,
      city: 'Coweta',
      state: 'OK',
    },
    74430: {
      lat: 35.13175,
      lng: -95.64826,
      city: 'Crowder',
      state: 'OK',
    },
    74431: {
      lat: 35.45743,
      lng: -95.93934,
      city: 'Dewar',
      state: 'OK',
    },
    74432: {
      lat: 35.28371,
      lng: -95.64911,
      city: 'Eufaula',
      state: 'OK',
    },
    74434: {
      lat: 35.78174,
      lng: -95.20027,
      city: 'Fort Gibson',
      state: 'OK',
    },
    74435: { lat: 35.57862, lng: -95.09405, city: 'Gore', state: 'OK' },
    74436: {
      lat: 35.80034,
      lng: -95.70175,
      city: 'Haskell',
      state: 'OK',
    },
    74437: {
      lat: 35.44825,
      lng: -95.94417,
      city: 'Henryetta',
      state: 'OK',
    },
    74438: {
      lat: 35.51382,
      lng: -95.75935,
      city: 'Hitchita',
      state: 'OK',
    },
    74441: {
      lat: 35.97458,
      lng: -95.14613,
      city: 'Hulbert',
      state: 'OK',
    },
    74442: {
      lat: 35.12983,
      lng: -95.8151,
      city: 'Indianola',
      state: 'OK',
    },
    74445: {
      lat: 35.62729,
      lng: -95.82605,
      city: 'Morris',
      state: 'OK',
    },
    74446: { lat: 35.85117, lng: -95.32264, city: 'Okay', state: 'OK' },
    74447: {
      lat: 35.64975,
      lng: -96.00628,
      city: 'Okmulgee',
      state: 'OK',
    },
    74450: {
      lat: 35.59714,
      lng: -95.47541,
      city: 'Oktaha',
      state: 'OK',
    },
    74451: {
      lat: 35.72886,
      lng: -95.00166,
      city: 'Park Hill',
      state: 'OK',
    },
    74452: {
      lat: 36.12137,
      lng: -95.06829,
      city: 'Peggs',
      state: 'OK',
    },
    74454: {
      lat: 35.85464,
      lng: -95.51253,
      city: 'Porter',
      state: 'OK',
    },
    74455: {
      lat: 35.36238,
      lng: -95.26867,
      city: 'Porum',
      state: 'OK',
    },
    74456: {
      lat: 35.71083,
      lng: -95.99262,
      city: 'Preston',
      state: 'OK',
    },
    74457: {
      lat: 36.03063,
      lng: -94.82065,
      city: 'Proctor',
      state: 'OK',
    },
    74458: {
      lat: 35.88231,
      lng: -95.58786,
      city: 'Redbird',
      state: 'OK',
    },
    74459: {
      lat: 35.51878,
      lng: -95.49217,
      city: 'Rentiesville',
      state: 'OK',
    },
    74460: {
      lat: 35.50473,
      lng: -95.96452,
      city: 'Schulter',
      state: 'OK',
    },
    74462: {
      lat: 35.28328,
      lng: -95.15041,
      city: 'Stigler',
      state: 'OK',
    },
    74463: { lat: 35.76943, lng: -95.54843, city: 'Taft', state: 'OK' },
    74464: {
      lat: 35.94419,
      lng: -94.97365,
      city: 'Tahlequah',
      state: 'OK',
    },
    74467: {
      lat: 35.96681,
      lng: -95.39181,
      city: 'Wagoner',
      state: 'OK',
    },
    74468: {
      lat: 35.62121,
      lng: -95.59025,
      city: 'Wainwright',
      state: 'OK',
    },
    74469: {
      lat: 35.5083,
      lng: -95.31545,
      city: 'Warner',
      state: 'OK',
    },
    74470: {
      lat: 35.47906,
      lng: -95.16879,
      city: 'Webbers Falls',
      state: 'OK',
    },
    74471: {
      lat: 35.82749,
      lng: -94.86454,
      city: 'Welling',
      state: 'OK',
    },
    74472: {
      lat: 35.25028,
      lng: -95.263,
      city: 'Whitefield',
      state: 'OK',
    },
    74477: {
      lat: 36.00394,
      lng: -95.26644,
      city: 'Wagoner',
      state: 'OK',
    },
    74501: {
      lat: 34.98501,
      lng: -95.78597,
      city: 'Mcalester',
      state: 'OK',
    },
    74521: {
      lat: 34.66222,
      lng: -95.09934,
      city: 'Albion',
      state: 'OK',
    },
    74522: {
      lat: 34.88253,
      lng: -95.6767,
      city: 'Alderson',
      state: 'OK',
    },
    74523: {
      lat: 34.25171,
      lng: -95.60018,
      city: 'Antlers',
      state: 'OK',
    },
    74525: {
      lat: 34.33441,
      lng: -96.05563,
      city: 'Atoka',
      state: 'OK',
    },
    74528: { lat: 34.71644, lng: -95.723, city: 'Blanco', state: 'OK' },
    74530: {
      lat: 34.42677,
      lng: -96.5152,
      city: 'Bromide',
      state: 'OK',
    },
    74531: {
      lat: 34.8777,
      lng: -96.24035,
      city: 'Calvin',
      state: 'OK',
    },
    74533: {
      lat: 34.20521,
      lng: -96.18893,
      city: 'Caney',
      state: 'OK',
    },
    74534: {
      lat: 34.61965,
      lng: -96.36634,
      city: 'Centrahoma',
      state: 'OK',
    },
    74535: {
      lat: 34.48384,
      lng: -96.44202,
      city: 'Clarita',
      state: 'OK',
    },
    74536: {
      lat: 34.55697,
      lng: -95.45955,
      city: 'Clayton',
      state: 'OK',
    },
    74538: {
      lat: 34.57453,
      lng: -96.25575,
      city: 'Coalgate',
      state: 'OK',
    },
    74540: {
      lat: 34.55776,
      lng: -95.70167,
      city: 'Daisy',
      state: 'OK',
    },
    74543: {
      lat: 34.35927,
      lng: -95.37907,
      city: 'Finley',
      state: 'OK',
    },
    74546: {
      lat: 34.85186,
      lng: -95.58001,
      city: 'Haileyville',
      state: 'OK',
    },
    74547: {
      lat: 34.81019,
      lng: -95.59782,
      city: 'Hartshorne',
      state: 'OK',
    },
    74549: {
      lat: 34.57483,
      lng: -94.90993,
      city: 'Honobia',
      state: 'OK',
    },
    74552: {
      lat: 35.11282,
      lng: -95.24734,
      city: 'Kinta',
      state: 'OK',
    },
    74553: {
      lat: 34.70808,
      lng: -95.91859,
      city: 'Kiowa',
      state: 'OK',
    },
    74554: { lat: 34.92552, lng: -95.7203, city: 'Krebs', state: 'OK' },
    74555: { lat: 34.2459, lng: -95.96944, city: 'Lane', state: 'OK' },
    74556: {
      lat: 34.46102,
      lng: -96.19878,
      city: 'Lehigh',
      state: 'OK',
    },
    74557: {
      lat: 34.40355,
      lng: -95.69344,
      city: 'Moyers',
      state: 'OK',
    },
    74558: {
      lat: 34.49174,
      lng: -95.15081,
      city: 'Nashoba',
      state: 'OK',
    },
    74560: {
      lat: 34.64717,
      lng: -95.74679,
      city: 'Pittsburg',
      state: 'OK',
    },
    74561: {
      lat: 35.12489,
      lng: -95.42929,
      city: 'Quinton',
      state: 'OK',
    },
    74562: {
      lat: 34.29683,
      lng: -95.29637,
      city: 'Rattan',
      state: 'OK',
    },
    74563: {
      lat: 34.97903,
      lng: -95.07556,
      city: 'Red Oak',
      state: 'OK',
    },
    74565: {
      lat: 34.82222,
      lng: -95.83301,
      city: 'Savanna',
      state: 'OK',
    },
    74567: { lat: 34.44871, lng: -95.35575, city: 'Snow', state: 'OK' },
    74569: {
      lat: 34.47921,
      lng: -95.90315,
      city: 'Stringtown',
      state: 'OK',
    },
    74570: {
      lat: 34.86574,
      lng: -96.10432,
      city: 'Stuart',
      state: 'OK',
    },
    74571: {
      lat: 34.71915,
      lng: -95.04948,
      city: 'Talihina',
      state: 'OK',
    },
    74572: {
      lat: 34.63831,
      lng: -96.42594,
      city: 'Tupelo',
      state: 'OK',
    },
    74574: {
      lat: 34.67885,
      lng: -95.27335,
      city: 'Tuskahoma',
      state: 'OK',
    },
    74576: {
      lat: 34.67046,
      lng: -96.04259,
      city: 'Wardville',
      state: 'OK',
    },
    74577: {
      lat: 34.71967,
      lng: -94.85689,
      city: 'Whitesboro',
      state: 'OK',
    },
    74578: {
      lat: 34.90045,
      lng: -95.34872,
      city: 'Wilburton',
      state: 'OK',
    },
    74601: {
      lat: 36.70436,
      lng: -97.14604,
      city: 'Ponca City',
      state: 'OK',
    },
    74604: {
      lat: 36.69672,
      lng: -96.98132,
      city: 'Ponca City',
      state: 'OK',
    },
    74630: {
      lat: 36.49736,
      lng: -97.4296,
      city: 'Billings',
      state: 'OK',
    },
    74631: {
      lat: 36.79858,
      lng: -97.30585,
      city: 'Blackwell',
      state: 'OK',
    },
    74632: {
      lat: 36.93958,
      lng: -97.34144,
      city: 'Braman',
      state: 'OK',
    },
    74633: {
      lat: 36.70478,
      lng: -96.79216,
      city: 'Burbank',
      state: 'OK',
    },
    74636: {
      lat: 36.82257,
      lng: -97.52222,
      city: 'Deer Creek',
      state: 'OK',
    },
    74637: {
      lat: 36.59083,
      lng: -96.68172,
      city: 'Fairfax',
      state: 'OK',
    },
    74640: {
      lat: 36.56589,
      lng: -97.65032,
      city: 'Hunter',
      state: 'OK',
    },
    74641: {
      lat: 36.8181,
      lng: -96.85709,
      city: 'Kaw City',
      state: 'OK',
    },
    74643: {
      lat: 36.68457,
      lng: -97.55379,
      city: 'Lamont',
      state: 'OK',
    },
    74644: {
      lat: 36.55713,
      lng: -97.09768,
      city: 'Marland',
      state: 'OK',
    },
    74646: {
      lat: 36.82498,
      lng: -97.43993,
      city: 'Nardin',
      state: 'OK',
    },
    74647: {
      lat: 36.91601,
      lng: -97.00272,
      city: 'Newkirk',
      state: 'OK',
    },
    74650: {
      lat: 36.47993,
      lng: -96.72282,
      city: 'Ralston',
      state: 'OK',
    },
    74651: {
      lat: 36.46985,
      lng: -97.17197,
      city: 'Red Rock',
      state: 'OK',
    },
    74652: {
      lat: 36.85639,
      lng: -96.65438,
      city: 'Shidler',
      state: 'OK',
    },
    74653: {
      lat: 36.64683,
      lng: -97.3442,
      city: 'Tonkawa',
      state: 'OK',
    },
    74701: {
      lat: 34.00974,
      lng: -96.3769,
      city: 'Durant',
      state: 'OK',
    },
    74720: {
      lat: 33.83141,
      lng: -96.37768,
      city: 'Achille',
      state: 'OK',
    },
    74721: {
      lat: 33.87977,
      lng: -96.16266,
      city: 'Albany',
      state: 'OK',
    },
    74722: {
      lat: 34.38938,
      lng: -94.91671,
      city: 'Battiest',
      state: 'OK',
    },
    74723: {
      lat: 33.98958,
      lng: -96.0233,
      city: 'Bennington',
      state: 'OK',
    },
    74724: {
      lat: 34.33926,
      lng: -94.85833,
      city: 'Bethel',
      state: 'OK',
    },
    74726: {
      lat: 33.94966,
      lng: -96.18135,
      city: 'Bokchito',
      state: 'OK',
    },
    74727: {
      lat: 34.01907,
      lng: -95.86018,
      city: 'Boswell',
      state: 'OK',
    },
    74728: {
      lat: 34.15411,
      lng: -94.81959,
      city: 'Broken Bow',
      state: 'OK',
    },
    74729: {
      lat: 34.14674,
      lng: -96.25289,
      city: 'Caddo',
      state: 'OK',
    },
    74730: {
      lat: 33.90466,
      lng: -96.43361,
      city: 'Calera',
      state: 'OK',
    },
    74731: {
      lat: 33.8753,
      lng: -96.57313,
      city: 'Cartwright',
      state: 'OK',
    },
    74733: {
      lat: 33.83053,
      lng: -96.47546,
      city: 'Colbert',
      state: 'OK',
    },
    74734: {
      lat: 34.0888,
      lng: -94.54786,
      city: 'Eagletown',
      state: 'OK',
    },
    74735: {
      lat: 34.10142,
      lng: -95.25595,
      city: 'Fort Towson',
      state: 'OK',
    },
    74736: {
      lat: 33.92436,
      lng: -94.98772,
      city: 'Garvin',
      state: 'OK',
    },
    74737: { lat: 34.0311, lng: -94.8995, city: 'Golden', state: 'OK' },
    74738: { lat: 33.90131, lng: -95.444, city: 'Grant', state: 'OK' },
    74740: {
      lat: 33.7938,
      lng: -94.59614,
      city: 'Haworth',
      state: 'OK',
    },
    74741: {
      lat: 33.79571,
      lng: -96.29281,
      city: 'Hendrix',
      state: 'OK',
    },
    74743: { lat: 34.00647, lng: -95.5107, city: 'Hugo', state: 'OK' },
    74745: {
      lat: 33.86017,
      lng: -94.81319,
      city: 'Idabel',
      state: 'OK',
    },
    74747: { lat: 33.75491, lng: -96.31612, city: 'Kemp', state: 'OK' },
    74748: {
      lat: 34.16082,
      lng: -96.44879,
      city: 'Kenefic',
      state: 'OK',
    },
    74750: {
      lat: 33.98514,
      lng: -95.01175,
      city: 'Millerton',
      state: 'OK',
    },
    74753: {
      lat: 33.90777,
      lng: -96.53496,
      city: 'Platter',
      state: 'OK',
    },
    74754: {
      lat: 34.19367,
      lng: -95.12231,
      city: 'Ringold',
      state: 'OK',
    },
    74755: { lat: 34.11799, lng: -95.14196, city: 'Rufe', state: 'OK' },
    74756: {
      lat: 34.07049,
      lng: -95.37367,
      city: 'Sawyer',
      state: 'OK',
    },
    74759: {
      lat: 34.06088,
      lng: -95.71278,
      city: 'Soper',
      state: 'OK',
    },
    74760: {
      lat: 34.15996,
      lng: -95.3612,
      city: 'Spencerville',
      state: 'OK',
    },
    74761: {
      lat: 34.00938,
      lng: -95.20134,
      city: 'Swink',
      state: 'OK',
    },
    74764: {
      lat: 34.09405,
      lng: -95.05512,
      city: 'Valliant',
      state: 'OK',
    },
    74766: {
      lat: 34.08597,
      lng: -94.97271,
      city: 'Wright City',
      state: 'OK',
    },
    74801: {
      lat: 35.31749,
      lng: -96.96824,
      city: 'Shawnee',
      state: 'OK',
    },
    74804: {
      lat: 35.39358,
      lng: -96.92399,
      city: 'Shawnee',
      state: 'OK',
    },
    74820: { lat: 34.80691, lng: -96.71348, city: 'Ada', state: 'OK' },
    74824: { lat: 35.8873, lng: -96.87358, city: 'Agra', state: 'OK' },
    74825: { lat: 34.80591, lng: -96.4028, city: 'Allen', state: 'OK' },
    74826: {
      lat: 35.01735,
      lng: -96.90606,
      city: 'Asher',
      state: 'OK',
    },
    74827: {
      lat: 34.94101,
      lng: -96.34208,
      city: 'Atwood',
      state: 'OK',
    },
    74829: {
      lat: 35.51332,
      lng: -96.47392,
      city: 'Boley',
      state: 'OK',
    },
    74830: {
      lat: 35.14452,
      lng: -96.66464,
      city: 'Bowlegs',
      state: 'OK',
    },
    74831: {
      lat: 34.89841,
      lng: -97.04298,
      city: 'Byars',
      state: 'OK',
    },
    74832: {
      lat: 35.82534,
      lng: -97.01077,
      city: 'Carney',
      state: 'OK',
    },
    74833: {
      lat: 35.58262,
      lng: -96.41193,
      city: 'Castle',
      state: 'OK',
    },
    74834: {
      lat: 35.6961,
      lng: -96.87902,
      city: 'Chandler',
      state: 'OK',
    },
    74836: {
      lat: 34.44436,
      lng: -96.62581,
      city: 'Connerville',
      state: 'OK',
    },
    74837: {
      lat: 35.34321,
      lng: -96.4561,
      city: 'Cromwell',
      state: 'OK',
    },
    74839: {
      lat: 35.2413,
      lng: -96.04642,
      city: 'Dustin',
      state: 'OK',
    },
    74840: {
      lat: 35.30488,
      lng: -96.7665,
      city: 'Earlsboro',
      state: 'OK',
    },
    74842: {
      lat: 34.6166,
      lng: -96.61456,
      city: 'Fittstown',
      state: 'OK',
    },
    74843: {
      lat: 34.65763,
      lng: -96.7125,
      city: 'Fitzhugh',
      state: 'OK',
    },
    74844: {
      lat: 34.87421,
      lng: -96.58869,
      city: 'Francis',
      state: 'OK',
    },
    74845: {
      lat: 35.25856,
      lng: -95.88302,
      city: 'Hanna',
      state: 'OK',
    },
    74848: {
      lat: 35.08197,
      lng: -96.34804,
      city: 'Holdenville',
      state: 'OK',
    },
    74849: {
      lat: 34.9846,
      lng: -96.73597,
      city: 'Konawa',
      state: 'OK',
    },
    74850: {
      lat: 35.11169,
      lng: -96.09045,
      city: 'Lamar',
      state: 'OK',
    },
    74851: {
      lat: 35.42653,
      lng: -97.08072,
      city: 'Mcloud',
      state: 'OK',
    },
    74852: {
      lat: 35.12844,
      lng: -97.01301,
      city: 'Macomb',
      state: 'OK',
    },
    74854: { lat: 35.11527, lng: -96.762, city: 'Maud', state: 'OK' },
    74855: {
      lat: 35.51911,
      lng: -96.90376,
      city: 'Meeker',
      state: 'OK',
    },
    74856: {
      lat: 34.39853,
      lng: -96.81829,
      city: 'Mill Creek',
      state: 'OK',
    },
    74857: {
      lat: 35.34132,
      lng: -97.19576,
      city: 'Newalla',
      state: 'OK',
    },
    74859: {
      lat: 35.46237,
      lng: -96.31792,
      city: 'Okemah',
      state: 'OK',
    },
    74860: {
      lat: 35.52788,
      lng: -96.56913,
      city: 'Paden',
      state: 'OK',
    },
    74864: {
      lat: 35.4921,
      lng: -96.70269,
      city: 'Prague',
      state: 'OK',
    },
    74865: { lat: 34.60599, lng: -96.80922, city: 'Roff', state: 'OK' },
    74866: {
      lat: 35.06647,
      lng: -96.84691,
      city: 'Saint Louis',
      state: 'OK',
    },
    74867: {
      lat: 34.96335,
      lng: -96.54663,
      city: 'Sasakwa',
      state: 'OK',
    },
    74868: {
      lat: 35.26699,
      lng: -96.64193,
      city: 'Seminole',
      state: 'OK',
    },
    74869: {
      lat: 35.59117,
      lng: -96.75763,
      city: 'Sparks',
      state: 'OK',
    },
    74871: {
      lat: 34.59942,
      lng: -96.5528,
      city: 'Stonewall',
      state: 'OK',
    },
    74872: {
      lat: 34.77989,
      lng: -96.97913,
      city: 'Stratford',
      state: 'OK',
    },
    74873: {
      lat: 35.22396,
      lng: -96.97975,
      city: 'Tecumseh',
      state: 'OK',
    },
    74875: { lat: 35.8751, lng: -96.95877, city: 'Tryon', state: 'OK' },
    74878: {
      lat: 35.00768,
      lng: -97.06999,
      city: 'Wanette',
      state: 'OK',
    },
    74880: {
      lat: 35.38039,
      lng: -96.1212,
      city: 'Weleetka',
      state: 'OK',
    },
    74881: {
      lat: 35.72136,
      lng: -97.08264,
      city: 'Wellston',
      state: 'OK',
    },
    74883: {
      lat: 35.22766,
      lng: -96.24346,
      city: 'Wetumka',
      state: 'OK',
    },
    74884: {
      lat: 35.18635,
      lng: -96.51882,
      city: 'Wewoka',
      state: 'OK',
    },
    74901: {
      lat: 35.32798,
      lng: -94.47166,
      city: 'Arkoma',
      state: 'OK',
    },
    74902: {
      lat: 35.24568,
      lng: -94.47715,
      city: 'Pocola',
      state: 'OK',
    },
    74930: {
      lat: 35.18409,
      lng: -94.77573,
      city: 'Bokoshe',
      state: 'OK',
    },
    74931: {
      lat: 35.69226,
      lng: -94.76459,
      city: 'Bunch',
      state: 'OK',
    },
    74932: {
      lat: 35.14701,
      lng: -94.53501,
      city: 'Cameron',
      state: 'OK',
    },
    74935: {
      lat: 34.95433,
      lng: -94.89687,
      city: 'Fanshawe',
      state: 'OK',
    },
    74936: { lat: 35.39042, lng: -94.72772, city: 'Gans', state: 'OK' },
    74937: {
      lat: 34.83303,
      lng: -94.58321,
      city: 'Heavener',
      state: 'OK',
    },
    74939: {
      lat: 34.70875,
      lng: -94.63843,
      city: 'Hodgen',
      state: 'OK',
    },
    74940: { lat: 34.94979, lng: -94.63167, city: 'Howe', state: 'OK' },
    74941: {
      lat: 35.26453,
      lng: -94.87854,
      city: 'Keota',
      state: 'OK',
    },
    74942: {
      lat: 34.89799,
      lng: -94.97661,
      city: 'Leflore',
      state: 'OK',
    },
    74943: { lat: 35.1007, lng: -95.106, city: 'Lequire', state: 'OK' },
    74944: {
      lat: 35.12421,
      lng: -95.00779,
      city: 'Mccurtain',
      state: 'OK',
    },
    74945: {
      lat: 35.58232,
      lng: -94.81864,
      city: 'Marble City',
      state: 'OK',
    },
    74946: {
      lat: 35.39613,
      lng: -94.45093,
      city: 'Moffett',
      state: 'OK',
    },
    74947: {
      lat: 34.99149,
      lng: -94.51589,
      city: 'Monroe',
      state: 'OK',
    },
    74948: {
      lat: 35.46207,
      lng: -94.56284,
      city: 'Muldrow',
      state: 'OK',
    },
    74949: { lat: 34.68324, lng: -94.7739, city: 'Muse', state: 'OK' },
    74951: {
      lat: 35.17099,
      lng: -94.66482,
      city: 'Panama',
      state: 'OK',
    },
    74953: {
      lat: 35.03931,
      lng: -94.57156,
      city: 'Poteau',
      state: 'OK',
    },
    74954: {
      lat: 35.41596,
      lng: -94.49043,
      city: 'Roland',
      state: 'OK',
    },
    74955: {
      lat: 35.50213,
      lng: -94.75547,
      city: 'Sallisaw',
      state: 'OK',
    },
    74956: {
      lat: 35.09487,
      lng: -94.77334,
      city: 'Shady Point',
      state: 'OK',
    },
    74957: {
      lat: 34.52522,
      lng: -94.65558,
      city: 'Smithville',
      state: 'OK',
    },
    74959: {
      lat: 35.26288,
      lng: -94.63326,
      city: 'Spiro',
      state: 'OK',
    },
    74960: {
      lat: 35.80995,
      lng: -94.65027,
      city: 'Stilwell',
      state: 'OK',
    },
    74962: { lat: 35.53703, lng: -94.94717, city: 'Vian', state: 'OK' },
    74963: {
      lat: 34.41424,
      lng: -94.56322,
      city: 'Watson',
      state: 'OK',
    },
    74964: { lat: 36.11493, lng: -94.6665, city: 'Watts', state: 'OK' },
    74965: {
      lat: 35.99605,
      lng: -94.62553,
      city: 'Westville',
      state: 'OK',
    },
    74966: {
      lat: 34.89556,
      lng: -94.86894,
      city: 'Wister',
      state: 'OK',
    },
    75001: {
      lat: 32.96015,
      lng: -96.83808,
      city: 'Addison',
      state: 'TX',
    },
    75002: {
      lat: 33.08946,
      lng: -96.60639,
      city: 'Allen',
      state: 'TX',
    },
    75006: {
      lat: 32.96165,
      lng: -96.89717,
      city: 'Carrollton',
      state: 'TX',
    },
    75007: {
      lat: 33.00498,
      lng: -96.8959,
      city: 'Carrollton',
      state: 'TX',
    },
    75009: {
      lat: 33.3384,
      lng: -96.75185,
      city: 'Celina',
      state: 'TX',
    },
    75010: {
      lat: 33.03058,
      lng: -96.90148,
      city: 'Carrollton',
      state: 'TX',
    },
    75013: {
      lat: 33.11463,
      lng: -96.69413,
      city: 'Allen',
      state: 'TX',
    },
    75019: {
      lat: 32.96183,
      lng: -96.984,
      city: 'Coppell',
      state: 'TX',
    },
    75020: {
      lat: 33.77264,
      lng: -96.60391,
      city: 'Denison',
      state: 'TX',
    },
    75021: {
      lat: 33.72686,
      lng: -96.47552,
      city: 'Denison',
      state: 'TX',
    },
    75022: {
      lat: 33.02554,
      lng: -97.11871,
      city: 'Flower Mound',
      state: 'TX',
    },
    75023: {
      lat: 33.05657,
      lng: -96.73286,
      city: 'Plano',
      state: 'TX',
    },
    75024: {
      lat: 33.07712,
      lng: -96.80826,
      city: 'Plano',
      state: 'TX',
    },
    75025: {
      lat: 33.09057,
      lng: -96.74061,
      city: 'Plano',
      state: 'TX',
    },
    75028: {
      lat: 33.03382,
      lng: -97.0629,
      city: 'Flower Mound',
      state: 'TX',
    },
    75032: {
      lat: 32.85756,
      lng: -96.42858,
      city: 'Rockwall',
      state: 'TX',
    },
    75033: {
      lat: 33.18471,
      lng: -96.84404,
      city: 'Frisco',
      state: 'TX',
    },
    75034: {
      lat: 33.12084,
      lng: -96.83778,
      city: 'Frisco',
      state: 'TX',
    },
    75035: {
      lat: 33.15814,
      lng: -96.7734,
      city: 'Frisco',
      state: 'TX',
    },
    75036: {
      lat: 33.13339,
      lng: -96.91453,
      city: 'Frisco',
      state: 'TX',
    },
    75038: {
      lat: 32.87231,
      lng: -96.97946,
      city: 'Irving',
      state: 'TX',
    },
    75039: {
      lat: 32.88592,
      lng: -96.94042,
      city: 'Irving',
      state: 'TX',
    },
    75040: {
      lat: 32.92797,
      lng: -96.62006,
      city: 'Garland',
      state: 'TX',
    },
    75041: {
      lat: 32.88101,
      lng: -96.65149,
      city: 'Garland',
      state: 'TX',
    },
    75042: {
      lat: 32.91401,
      lng: -96.67466,
      city: 'Garland',
      state: 'TX',
    },
    75043: {
      lat: 32.8571,
      lng: -96.57986,
      city: 'Garland',
      state: 'TX',
    },
    75044: {
      lat: 32.96299,
      lng: -96.6531,
      city: 'Garland',
      state: 'TX',
    },
    75048: {
      lat: 32.9704,
      lng: -96.58139,
      city: 'Sachse',
      state: 'TX',
    },
    75050: {
      lat: 32.77428,
      lng: -97.0037,
      city: 'Grand Prairie',
      state: 'TX',
    },
    75051: {
      lat: 32.72635,
      lng: -96.99375,
      city: 'Grand Prairie',
      state: 'TX',
    },
    75052: {
      lat: 32.66455,
      lng: -97.02809,
      city: 'Grand Prairie',
      state: 'TX',
    },
    75054: {
      lat: 32.60036,
      lng: -97.03409,
      city: 'Grand Prairie',
      state: 'TX',
    },
    75056: {
      lat: 33.07541,
      lng: -96.90978,
      city: 'The Colony',
      state: 'TX',
    },
    75057: {
      lat: 33.05012,
      lng: -96.9772,
      city: 'Lewisville',
      state: 'TX',
    },
    75058: {
      lat: 33.4473,
      lng: -96.73546,
      city: 'Gunter',
      state: 'TX',
    },
    75060: {
      lat: 32.79774,
      lng: -96.95295,
      city: 'Irving',
      state: 'TX',
    },
    75061: {
      lat: 32.82607,
      lng: -96.96563,
      city: 'Irving',
      state: 'TX',
    },
    75062: { lat: 32.847, lng: -96.95813, city: 'Irving', state: 'TX' },
    75063: {
      lat: 32.90797,
      lng: -96.9961,
      city: 'Irving',
      state: 'TX',
    },
    75065: {
      lat: 33.11108,
      lng: -97.01277,
      city: 'Lake Dallas',
      state: 'TX',
    },
    75067: {
      lat: 33.01416,
      lng: -97.00268,
      city: 'Lewisville',
      state: 'TX',
    },
    75068: {
      lat: 33.17713,
      lng: -96.95228,
      city: 'Little Elm',
      state: 'TX',
    },
    75069: {
      lat: 33.16805,
      lng: -96.59671,
      city: 'Mckinney',
      state: 'TX',
    },
    75070: {
      lat: 33.15587,
      lng: -96.69742,
      city: 'Mckinney',
      state: 'TX',
    },
    75071: {
      lat: 33.24558,
      lng: -96.62951,
      city: 'Mckinney',
      state: 'TX',
    },
    75072: {
      lat: 33.1875,
      lng: -96.70099,
      city: 'Mckinney',
      state: 'TX',
    },
    75074: {
      lat: 33.03298,
      lng: -96.67304,
      city: 'Plano',
      state: 'TX',
    },
    75075: {
      lat: 33.02163,
      lng: -96.74043,
      city: 'Plano',
      state: 'TX',
    },
    75076: {
      lat: 33.79608,
      lng: -96.71913,
      city: 'Pottsboro',
      state: 'TX',
    },
    75077: {
      lat: 33.07921,
      lng: -97.06401,
      city: 'Lewisville',
      state: 'TX',
    },
    75078: {
      lat: 33.24526,
      lng: -96.80791,
      city: 'Prosper',
      state: 'TX',
    },
    75080: {
      lat: 32.97382,
      lng: -96.7416,
      city: 'Richardson',
      state: 'TX',
    },
    75081: {
      lat: 32.94969,
      lng: -96.70899,
      city: 'Richardson',
      state: 'TX',
    },
    75082: {
      lat: 32.99208,
      lng: -96.66536,
      city: 'Richardson',
      state: 'TX',
    },
    75087: {
      lat: 32.94581,
      lng: -96.44136,
      city: 'Rockwall',
      state: 'TX',
    },
    75088: {
      lat: 32.89368,
      lng: -96.53929,
      city: 'Rowlett',
      state: 'TX',
    },
    75089: {
      lat: 32.93195,
      lng: -96.54435,
      city: 'Rowlett',
      state: 'TX',
    },
    75090: {
      lat: 33.6054,
      lng: -96.54657,
      city: 'Sherman',
      state: 'TX',
    },
    75092: {
      lat: 33.64869,
      lng: -96.70011,
      city: 'Sherman',
      state: 'TX',
    },
    75093: {
      lat: 33.03521,
      lng: -96.80943,
      city: 'Plano',
      state: 'TX',
    },
    75094: {
      lat: 33.01871,
      lng: -96.61481,
      city: 'Plano',
      state: 'TX',
    },
    75098: {
      lat: 33.01432,
      lng: -96.53531,
      city: 'Wylie',
      state: 'TX',
    },
    75101: {
      lat: 32.27003,
      lng: -96.70239,
      city: 'Bardwell',
      state: 'TX',
    },
    75102: {
      lat: 32.08274,
      lng: -96.63999,
      city: 'Barry',
      state: 'TX',
    },
    75103: {
      lat: 32.51201,
      lng: -95.88363,
      city: 'Canton',
      state: 'TX',
    },
    75104: {
      lat: 32.58295,
      lng: -96.96712,
      city: 'Cedar Hill',
      state: 'TX',
    },
    75105: {
      lat: 32.26717,
      lng: -96.36682,
      city: 'Chatfield',
      state: 'TX',
    },
    75109: {
      lat: 32.04246,
      lng: -96.35622,
      city: 'Corsicana',
      state: 'TX',
    },
    75110: {
      lat: 32.08071,
      lng: -96.52344,
      city: 'Corsicana',
      state: 'TX',
    },
    75114: {
      lat: 32.60769,
      lng: -96.44322,
      city: 'Crandall',
      state: 'TX',
    },
    75115: {
      lat: 32.59831,
      lng: -96.86433,
      city: 'Desoto',
      state: 'TX',
    },
    75116: {
      lat: 32.66013,
      lng: -96.91402,
      city: 'Duncanville',
      state: 'TX',
    },
    75117: {
      lat: 32.71005,
      lng: -95.85268,
      city: 'Edgewood',
      state: 'TX',
    },
    75119: {
      lat: 32.32293,
      lng: -96.59107,
      city: 'Ennis',
      state: 'TX',
    },
    75124: {
      lat: 32.32579,
      lng: -95.96932,
      city: 'Eustace',
      state: 'TX',
    },
    75125: {
      lat: 32.52983,
      lng: -96.61563,
      city: 'Ferris',
      state: 'TX',
    },
    75126: {
      lat: 32.74361,
      lng: -96.45289,
      city: 'Forney',
      state: 'TX',
    },
    75127: {
      lat: 32.6926,
      lng: -95.79262,
      city: 'Fruitvale',
      state: 'TX',
    },
    75132: { lat: 32.93865, lng: -96.38046, city: 'Fate', state: 'TX' },
    75134: {
      lat: 32.62308,
      lng: -96.78007,
      city: 'Lancaster',
      state: 'TX',
    },
    75135: {
      lat: 33.06666,
      lng: -96.22567,
      city: 'Caddo Mills',
      state: 'TX',
    },
    75137: {
      lat: 32.63421,
      lng: -96.91224,
      city: 'Duncanville',
      state: 'TX',
    },
    75140: {
      lat: 32.64485,
      lng: -95.68854,
      city: 'Grand Saline',
      state: 'TX',
    },
    75141: {
      lat: 32.63984,
      lng: -96.69257,
      city: 'Hutchins',
      state: 'TX',
    },
    75142: {
      lat: 32.57004,
      lng: -96.24637,
      city: 'Kaufman',
      state: 'TX',
    },
    75143: { lat: 32.36334, lng: -96.24929, city: 'Kemp', state: 'TX' },
    75144: {
      lat: 32.11157,
      lng: -96.20954,
      city: 'Kerens',
      state: 'TX',
    },
    75146: {
      lat: 32.57608,
      lng: -96.75137,
      city: 'Lancaster',
      state: 'TX',
    },
    75147: {
      lat: 32.42256,
      lng: -96.06517,
      city: 'Mabank',
      state: 'TX',
    },
    75148: {
      lat: 32.12491,
      lng: -96.01173,
      city: 'Malakoff',
      state: 'TX',
    },
    75149: {
      lat: 32.76994,
      lng: -96.6151,
      city: 'Mesquite',
      state: 'TX',
    },
    75150: {
      lat: 32.81545,
      lng: -96.6308,
      city: 'Mesquite',
      state: 'TX',
    },
    75152: {
      lat: 32.43598,
      lng: -96.67444,
      city: 'Palmer',
      state: 'TX',
    },
    75153: {
      lat: 32.17149,
      lng: -96.35331,
      city: 'Powell',
      state: 'TX',
    },
    75154: {
      lat: 32.52319,
      lng: -96.80885,
      city: 'Red Oak',
      state: 'TX',
    },
    75155: { lat: 32.2232, lng: -96.46906, city: 'Rice', state: 'TX' },
    75156: {
      lat: 32.28759,
      lng: -96.09999,
      city: 'Mabank',
      state: 'TX',
    },
    75157: {
      lat: 32.46168,
      lng: -96.44914,
      city: 'Rosser',
      state: 'TX',
    },
    75158: {
      lat: 32.46694,
      lng: -96.39315,
      city: 'Scurry',
      state: 'TX',
    },
    75159: {
      lat: 32.60652,
      lng: -96.53939,
      city: 'Seagoville',
      state: 'TX',
    },
    75160: {
      lat: 32.76466,
      lng: -96.29835,
      city: 'Terrell',
      state: 'TX',
    },
    75161: {
      lat: 32.73609,
      lng: -96.17129,
      city: 'Terrell',
      state: 'TX',
    },
    75163: {
      lat: 32.1672,
      lng: -96.10999,
      city: 'Trinidad',
      state: 'TX',
    },
    75164: {
      lat: 33.06963,
      lng: -96.3041,
      city: 'Josephine',
      state: 'TX',
    },
    75165: {
      lat: 32.36687,
      lng: -96.79677,
      city: 'Waxahachie',
      state: 'TX',
    },
    75166: { lat: 33.01671, lng: -96.4495, city: 'Lavon', state: 'TX' },
    75167: {
      lat: 32.36123,
      lng: -96.91547,
      city: 'Waxahachie',
      state: 'TX',
    },
    75169: {
      lat: 32.70335,
      lng: -95.99754,
      city: 'Wills Point',
      state: 'TX',
    },
    75172: {
      lat: 32.60369,
      lng: -96.67458,
      city: 'Wilmer',
      state: 'TX',
    },
    75173: {
      lat: 33.05986,
      lng: -96.38673,
      city: 'Nevada',
      state: 'TX',
    },
    75180: {
      lat: 32.71937,
      lng: -96.61841,
      city: 'Balch Springs',
      state: 'TX',
    },
    75181: {
      lat: 32.72696,
      lng: -96.555,
      city: 'Mesquite',
      state: 'TX',
    },
    75182: {
      lat: 32.80033,
      lng: -96.55425,
      city: 'Sunnyvale',
      state: 'TX',
    },
    75189: {
      lat: 32.94821,
      lng: -96.30941,
      city: 'Royse City',
      state: 'TX',
    },
    75201: {
      lat: 32.78826,
      lng: -96.79953,
      city: 'Dallas',
      state: 'TX',
    },
    75202: {
      lat: 32.77843,
      lng: -96.8037,
      city: 'Dallas',
      state: 'TX',
    },
    75203: {
      lat: 32.74439,
      lng: -96.80206,
      city: 'Dallas',
      state: 'TX',
    },
    75204: {
      lat: 32.80216,
      lng: -96.78885,
      city: 'Dallas',
      state: 'TX',
    },
    75205: {
      lat: 32.83657,
      lng: -96.79617,
      city: 'Dallas',
      state: 'TX',
    },
    75206: {
      lat: 32.83161,
      lng: -96.77032,
      city: 'Dallas',
      state: 'TX',
    },
    75207: {
      lat: 32.78693,
      lng: -96.82181,
      city: 'Dallas',
      state: 'TX',
    },
    75208: {
      lat: 32.75409,
      lng: -96.83943,
      city: 'Dallas',
      state: 'TX',
    },
    75209: { lat: 32.8487, lng: -96.8257, city: 'Dallas', state: 'TX' },
    75210: {
      lat: 32.77141,
      lng: -96.74618,
      city: 'Dallas',
      state: 'TX',
    },
    75211: {
      lat: 32.73649,
      lng: -96.90214,
      city: 'Dallas',
      state: 'TX',
    },
    75212: {
      lat: 32.78203,
      lng: -96.87853,
      city: 'Dallas',
      state: 'TX',
    },
    75214: {
      lat: 32.82899,
      lng: -96.74348,
      city: 'Dallas',
      state: 'TX',
    },
    75215: {
      lat: 32.75259,
      lng: -96.76075,
      city: 'Dallas',
      state: 'TX',
    },
    75216: {
      lat: 32.71158,
      lng: -96.78256,
      city: 'Dallas',
      state: 'TX',
    },
    75217: {
      lat: 32.71291,
      lng: -96.68243,
      city: 'Dallas',
      state: 'TX',
    },
    75218: {
      lat: 32.84136,
      lng: -96.70357,
      city: 'Dallas',
      state: 'TX',
    },
    75219: {
      lat: 32.81087,
      lng: -96.81315,
      city: 'Dallas',
      state: 'TX',
    },
    75220: {
      lat: 32.86692,
      lng: -96.87571,
      city: 'Dallas',
      state: 'TX',
    },
    75223: {
      lat: 32.79255,
      lng: -96.74556,
      city: 'Dallas',
      state: 'TX',
    },
    75224: {
      lat: 32.71117,
      lng: -96.83953,
      city: 'Dallas',
      state: 'TX',
    },
    75225: {
      lat: 32.86511,
      lng: -96.79109,
      city: 'Dallas',
      state: 'TX',
    },
    75226: {
      lat: 32.78315,
      lng: -96.77654,
      city: 'Dallas',
      state: 'TX',
    },
    75227: {
      lat: 32.77044,
      lng: -96.68831,
      city: 'Dallas',
      state: 'TX',
    },
    75228: {
      lat: 32.82456,
      lng: -96.6805,
      city: 'Dallas',
      state: 'TX',
    },
    75229: {
      lat: 32.8939,
      lng: -96.86517,
      city: 'Dallas',
      state: 'TX',
    },
    75230: {
      lat: 32.90202,
      lng: -96.79226,
      city: 'Dallas',
      state: 'TX',
    },
    75231: {
      lat: 32.87695,
      lng: -96.74967,
      city: 'Dallas',
      state: 'TX',
    },
    75232: {
      lat: 32.65878,
      lng: -96.83968,
      city: 'Dallas',
      state: 'TX',
    },
    75233: {
      lat: 32.70444,
      lng: -96.87271,
      city: 'Dallas',
      state: 'TX',
    },
    75234: {
      lat: 32.92423,
      lng: -96.88904,
      city: 'Dallas',
      state: 'TX',
    },
    75235: {
      lat: 32.83219,
      lng: -96.84798,
      city: 'Dallas',
      state: 'TX',
    },
    75236: {
      lat: 32.6866,
      lng: -96.93455,
      city: 'Dallas',
      state: 'TX',
    },
    75237: {
      lat: 32.66489,
      lng: -96.87238,
      city: 'Dallas',
      state: 'TX',
    },
    75238: {
      lat: 32.87855,
      lng: -96.70785,
      city: 'Dallas',
      state: 'TX',
    },
    75240: {
      lat: 32.93194,
      lng: -96.78924,
      city: 'Dallas',
      state: 'TX',
    },
    75241: { lat: 32.667, lng: -96.75903, city: 'Dallas', state: 'TX' },
    75243: {
      lat: 32.91232,
      lng: -96.73633,
      city: 'Dallas',
      state: 'TX',
    },
    75244: {
      lat: 32.92544,
      lng: -96.83671,
      city: 'Dallas',
      state: 'TX',
    },
    75246: {
      lat: 32.79296,
      lng: -96.77322,
      city: 'Dallas',
      state: 'TX',
    },
    75247: {
      lat: 32.81417,
      lng: -96.87803,
      city: 'Dallas',
      state: 'TX',
    },
    75248: {
      lat: 32.96957,
      lng: -96.79542,
      city: 'Dallas',
      state: 'TX',
    },
    75249: {
      lat: 32.6449,
      lng: -96.96257,
      city: 'Dallas',
      state: 'TX',
    },
    75251: {
      lat: 32.91984,
      lng: -96.77308,
      city: 'Dallas',
      state: 'TX',
    },
    75252: {
      lat: 32.99696,
      lng: -96.78983,
      city: 'Dallas',
      state: 'TX',
    },
    75253: {
      lat: 32.67278,
      lng: -96.61171,
      city: 'Dallas',
      state: 'TX',
    },
    75254: {
      lat: 32.94571,
      lng: -96.80127,
      city: 'Dallas',
      state: 'TX',
    },
    75261: {
      lat: 32.88568,
      lng: -97.05419,
      city: 'Dallas',
      state: 'TX',
    },
    75270: {
      lat: 32.78126,
      lng: -96.80192,
      city: 'Dallas',
      state: 'TX',
    },
    75287: {
      lat: 33.00015,
      lng: -96.84075,
      city: 'Dallas',
      state: 'TX',
    },
    75390: { lat: 32.814, lng: -96.84283, city: 'Dallas', state: 'TX' },
    75401: {
      lat: 33.18506,
      lng: -96.11556,
      city: 'Greenville',
      state: 'TX',
    },
    75402: {
      lat: 33.06724,
      lng: -96.08576,
      city: 'Greenville',
      state: 'TX',
    },
    75407: {
      lat: 33.1512,
      lng: -96.48808,
      city: 'Princeton',
      state: 'TX',
    },
    75409: { lat: 33.35223, lng: -96.52808, city: 'Anna', state: 'TX' },
    75410: { lat: 32.7921, lng: -95.63705, city: 'Alba', state: 'TX' },
    75411: {
      lat: 33.86275,
      lng: -95.63955,
      city: 'Arthur City',
      state: 'TX',
    },
    75412: {
      lat: 33.80295,
      lng: -95.14232,
      city: 'Bagwell',
      state: 'TX',
    },
    75413: {
      lat: 33.43596,
      lng: -96.16477,
      city: 'Bailey',
      state: 'TX',
    },
    75414: {
      lat: 33.62504,
      lng: -96.43193,
      city: 'Bells',
      state: 'TX',
    },
    75415: {
      lat: 33.4645,
      lng: -95.74603,
      city: 'Ben Franklin',
      state: 'TX',
    },
    75416: {
      lat: 33.67255,
      lng: -95.35431,
      city: 'Blossom',
      state: 'TX',
    },
    75417: {
      lat: 33.43827,
      lng: -95.13114,
      city: 'Bogata',
      state: 'TX',
    },
    75418: {
      lat: 33.57235,
      lng: -96.1773,
      city: 'Bonham',
      state: 'TX',
    },
    75420: {
      lat: 33.05782,
      lng: -95.72503,
      city: 'Brashear',
      state: 'TX',
    },
    75421: {
      lat: 33.6448,
      lng: -95.71809,
      city: 'Brookston',
      state: 'TX',
    },
    75422: {
      lat: 33.13889,
      lng: -95.93059,
      city: 'Campbell',
      state: 'TX',
    },
    75423: {
      lat: 33.29279,
      lng: -96.19918,
      city: 'Celeste',
      state: 'TX',
    },
    75424: {
      lat: 33.30535,
      lng: -96.39059,
      city: 'Blue Ridge',
      state: 'TX',
    },
    75426: {
      lat: 33.65608,
      lng: -95.01093,
      city: 'Clarksville',
      state: 'TX',
    },
    75428: {
      lat: 33.27132,
      lng: -95.91799,
      city: 'Commerce',
      state: 'TX',
    },
    75429: {
      lat: 33.27847,
      lng: -95.89231,
      city: 'Commerce',
      state: 'TX',
    },
    75431: { lat: 33.0073, lng: -95.4628, city: 'Como', state: 'TX' },
    75432: {
      lat: 33.38912,
      lng: -95.66804,
      city: 'Cooper',
      state: 'TX',
    },
    75433: {
      lat: 33.10883,
      lng: -95.80571,
      city: 'Cumby',
      state: 'TX',
    },
    75434: {
      lat: 33.41258,
      lng: -95.35171,
      city: 'Cunningham',
      state: 'TX',
    },
    75435: {
      lat: 33.50175,
      lng: -95.29522,
      city: 'Deport',
      state: 'TX',
    },
    75436: {
      lat: 33.73507,
      lng: -95.24904,
      city: 'Detroit',
      state: 'TX',
    },
    75437: { lat: 33.25605, lng: -95.46567, city: 'Dike', state: 'TX' },
    75438: {
      lat: 33.60701,
      lng: -96.07462,
      city: 'Dodd City',
      state: 'TX',
    },
    75439: {
      lat: 33.55261,
      lng: -96.27796,
      city: 'Ector',
      state: 'TX',
    },
    75440: {
      lat: 32.87507,
      lng: -95.75262,
      city: 'Emory',
      state: 'TX',
    },
    75441: { lat: 33.4296, lng: -95.65456, city: 'Enloe', state: 'TX' },
    75442: {
      lat: 33.17717,
      lng: -96.35035,
      city: 'Farmersville',
      state: 'TX',
    },
    75446: {
      lat: 33.61385,
      lng: -95.89177,
      city: 'Honey Grove',
      state: 'TX',
    },
    75447: {
      lat: 33.765,
      lng: -96.11966,
      city: 'Ivanhoe',
      state: 'TX',
    },
    75448: {
      lat: 33.31478,
      lng: -95.79681,
      city: 'Klondike',
      state: 'TX',
    },
    75449: { lat: 33.423, lng: -95.9493, city: 'Ladonia', state: 'TX' },
    75450: {
      lat: 33.40465,
      lng: -95.47481,
      city: 'Lake Creek',
      state: 'TX',
    },
    75451: {
      lat: 32.96247,
      lng: -95.11835,
      city: 'Leesburg',
      state: 'TX',
    },
    75452: {
      lat: 33.39284,
      lng: -96.24469,
      city: 'Leonard',
      state: 'TX',
    },
    75453: {
      lat: 33.00025,
      lng: -95.92817,
      city: 'Lone Oak',
      state: 'TX',
    },
    75454: {
      lat: 33.283,
      lng: -96.56073,
      city: 'Melissa',
      state: 'TX',
    },
    75455: {
      lat: 33.21725,
      lng: -94.98022,
      city: 'Mount Pleasant',
      state: 'TX',
    },
    75457: {
      lat: 33.1746,
      lng: -95.21125,
      city: 'Mount Vernon',
      state: 'TX',
    },
    75459: { lat: 33.52988, lng: -96.6831, city: 'Howe', state: 'TX' },
    75460: { lat: 33.6627, lng: -95.60296, city: 'Paris', state: 'TX' },
    75462: {
      lat: 33.62166,
      lng: -95.48362,
      city: 'Paris',
      state: 'TX',
    },
    75468: {
      lat: 33.51175,
      lng: -95.40747,
      city: 'Pattonville',
      state: 'TX',
    },
    75469: {
      lat: 33.41269,
      lng: -95.8248,
      city: 'Pecan Gap',
      state: 'TX',
    },
    75470: {
      lat: 33.58341,
      lng: -95.79836,
      city: 'Petty',
      state: 'TX',
    },
    75471: {
      lat: 33.04431,
      lng: -95.38883,
      city: 'Pickton',
      state: 'TX',
    },
    75472: {
      lat: 32.89177,
      lng: -95.87239,
      city: 'Point',
      state: 'TX',
    },
    75473: {
      lat: 33.81461,
      lng: -95.48433,
      city: 'Powderly',
      state: 'TX',
    },
    75474: {
      lat: 32.91709,
      lng: -96.10611,
      city: 'Quinlan',
      state: 'TX',
    },
    75475: {
      lat: 33.48593,
      lng: -96.25757,
      city: 'Randolph',
      state: 'TX',
    },
    75476: {
      lat: 33.70793,
      lng: -96.24342,
      city: 'Ravenna',
      state: 'TX',
    },
    75477: {
      lat: 33.53118,
      lng: -95.73429,
      city: 'Roxton',
      state: 'TX',
    },
    75478: {
      lat: 33.17276,
      lng: -95.35226,
      city: 'Saltillo',
      state: 'TX',
    },
    75479: { lat: 33.63007, lng: -96.3386, city: 'Savoy', state: 'TX' },
    75480: {
      lat: 33.02129,
      lng: -95.19159,
      city: 'Scroggins',
      state: 'TX',
    },
    75481: {
      lat: 33.31751,
      lng: -95.37485,
      city: 'Sulphur Bluff',
      state: 'TX',
    },
    75482: {
      lat: 33.16929,
      lng: -95.60946,
      city: 'Sulphur Springs',
      state: 'TX',
    },
    75486: {
      lat: 33.75757,
      lng: -95.74663,
      city: 'Sumner',
      state: 'TX',
    },
    75487: {
      lat: 33.33584,
      lng: -95.16567,
      city: 'Talco',
      state: 'TX',
    },
    75488: {
      lat: 33.77628,
      lng: -95.98899,
      city: 'Telephone',
      state: 'TX',
    },
    75489: {
      lat: 33.5204,
      lng: -96.48467,
      city: 'Tom Bean',
      state: 'TX',
    },
    75490: {
      lat: 33.4363,
      lng: -96.30771,
      city: 'Trenton',
      state: 'TX',
    },
    75491: {
      lat: 33.49741,
      lng: -96.39485,
      city: 'Whitewright',
      state: 'TX',
    },
    75492: {
      lat: 33.59139,
      lng: -96.0004,
      city: 'Windom',
      state: 'TX',
    },
    75493: {
      lat: 33.14073,
      lng: -95.11316,
      city: 'Winfield',
      state: 'TX',
    },
    75494: {
      lat: 32.90898,
      lng: -95.26126,
      city: 'Winnsboro',
      state: 'TX',
    },
    75495: {
      lat: 33.43246,
      lng: -96.55041,
      city: 'Van Alstyne',
      state: 'TX',
    },
    75496: {
      lat: 33.36276,
      lng: -96.06616,
      city: 'Wolfe City',
      state: 'TX',
    },
    75497: {
      lat: 32.92098,
      lng: -95.5764,
      city: 'Yantis',
      state: 'TX',
    },
    75501: {
      lat: 33.37413,
      lng: -94.13168,
      city: 'Texarkana',
      state: 'TX',
    },
    75503: {
      lat: 33.51345,
      lng: -94.13038,
      city: 'Texarkana',
      state: 'TX',
    },
    75550: {
      lat: 33.49966,
      lng: -94.88454,
      city: 'Annona',
      state: 'TX',
    },
    75551: {
      lat: 33.11648,
      lng: -94.20325,
      city: 'Atlanta',
      state: 'TX',
    },
    75554: {
      lat: 33.58534,
      lng: -94.78563,
      city: 'Avery',
      state: 'TX',
    },
    75555: {
      lat: 32.94564,
      lng: -94.13696,
      city: 'Bivins',
      state: 'TX',
    },
    75556: {
      lat: 33.1512,
      lng: -94.06263,
      city: 'Bloomburg',
      state: 'TX',
    },
    75558: {
      lat: 33.25017,
      lng: -94.85564,
      city: 'Cookville',
      state: 'TX',
    },
    75559: {
      lat: 33.49929,
      lng: -94.63931,
      city: 'De Kalb',
      state: 'TX',
    },
    75560: {
      lat: 33.1957,
      lng: -94.36699,
      city: 'Douglassville',
      state: 'TX',
    },
    75561: { lat: 33.517, lng: -94.27855, city: 'Hooks', state: 'TX' },
    75562: {
      lat: 32.93588,
      lng: -94.25302,
      city: 'Kildare',
      state: 'TX',
    },
    75563: {
      lat: 33.01835,
      lng: -94.38871,
      city: 'Linden',
      state: 'TX',
    },
    75564: { lat: 32.88784, lng: -94.24962, city: 'Lodi', state: 'TX' },
    75565: {
      lat: 32.95202,
      lng: -94.07527,
      city: 'McLeod',
      state: 'TX',
    },
    75566: {
      lat: 33.15256,
      lng: -94.49018,
      city: 'Marietta',
      state: 'TX',
    },
    75567: { lat: 33.31688, lng: -94.31935, city: 'Maud', state: 'TX' },
    75568: {
      lat: 33.19607,
      lng: -94.59362,
      city: 'Naples',
      state: 'TX',
    },
    75569: { lat: 33.44127, lng: -94.12292, city: 'Nash', state: 'TX' },
    75570: {
      lat: 33.4649,
      lng: -94.4389,
      city: 'New Boston',
      state: 'TX',
    },
    75571: {
      lat: 33.20704,
      lng: -94.76407,
      city: 'Omaha',
      state: 'TX',
    },
    75572: {
      lat: 33.23114,
      lng: -94.12934,
      city: 'Queen City',
      state: 'TX',
    },
    75573: {
      lat: 33.34214,
      lng: -94.22454,
      city: 'Redwater',
      state: 'TX',
    },
    75574: {
      lat: 33.32892,
      lng: -94.55229,
      city: 'Simms',
      state: 'TX',
    },
    75601: {
      lat: 32.5086,
      lng: -94.72414,
      city: 'Longview',
      state: 'TX',
    },
    75602: {
      lat: 32.45803,
      lng: -94.65712,
      city: 'Longview',
      state: 'TX',
    },
    75603: {
      lat: 32.40448,
      lng: -94.71754,
      city: 'Longview',
      state: 'TX',
    },
    75604: {
      lat: 32.51374,
      lng: -94.82919,
      city: 'Longview',
      state: 'TX',
    },
    75605: {
      lat: 32.58385,
      lng: -94.71554,
      city: 'Longview',
      state: 'TX',
    },
    75630: {
      lat: 32.87588,
      lng: -94.5572,
      city: 'Avinger',
      state: 'TX',
    },
    75631: {
      lat: 32.25343,
      lng: -94.44957,
      city: 'Beckville',
      state: 'TX',
    },
    75633: {
      lat: 32.12788,
      lng: -94.28109,
      city: 'Carthage',
      state: 'TX',
    },
    75638: {
      lat: 33.01869,
      lng: -94.73173,
      city: 'Daingerfield',
      state: 'TX',
    },
    75639: {
      lat: 32.28441,
      lng: -94.16897,
      city: 'De Berry',
      state: 'TX',
    },
    75640: { lat: 32.7152, lng: -94.68133, city: 'Diana', state: 'TX' },
    75641: { lat: 32.3846, lng: -94.5757, city: 'Easton', state: 'TX' },
    75642: {
      lat: 32.35818,
      lng: -94.17821,
      city: 'Elysian Fields',
      state: 'TX',
    },
    75643: { lat: 32.01041, lng: -94.35518, city: 'Gary', state: 'TX' },
    75644: {
      lat: 32.79171,
      lng: -94.99498,
      city: 'Gilmer',
      state: 'TX',
    },
    75645: {
      lat: 32.68375,
      lng: -94.87317,
      city: 'Gilmer',
      state: 'TX',
    },
    75647: {
      lat: 32.5167,
      lng: -94.96429,
      city: 'Gladewater',
      state: 'TX',
    },
    75650: {
      lat: 32.51529,
      lng: -94.55358,
      city: 'Hallsville',
      state: 'TX',
    },
    75651: {
      lat: 32.67061,
      lng: -94.53064,
      city: 'Harleton',
      state: 'TX',
    },
    75652: {
      lat: 32.2301,
      lng: -94.7281,
      city: 'Henderson',
      state: 'TX',
    },
    75654: {
      lat: 32.08299,
      lng: -94.82961,
      city: 'Henderson',
      state: 'TX',
    },
    75656: {
      lat: 33.01471,
      lng: -94.58686,
      city: 'Hughes Springs',
      state: 'TX',
    },
    75657: {
      lat: 32.79437,
      lng: -94.30498,
      city: 'Jefferson',
      state: 'TX',
    },
    75658: {
      lat: 32.17545,
      lng: -94.90362,
      city: 'Joinerville',
      state: 'TX',
    },
    75659: {
      lat: 32.50725,
      lng: -94.09249,
      city: 'Jonesville',
      state: 'TX',
    },
    75661: {
      lat: 32.63908,
      lng: -94.15655,
      city: 'Karnack',
      state: 'TX',
    },
    75662: {
      lat: 32.38076,
      lng: -94.87503,
      city: 'Kilgore',
      state: 'TX',
    },
    75667: {
      lat: 31.988,
      lng: -94.83729,
      city: 'Laneville',
      state: 'TX',
    },
    75668: {
      lat: 32.90846,
      lng: -94.68485,
      city: 'Lone Star',
      state: 'TX',
    },
    75669: {
      lat: 32.03348,
      lng: -94.55678,
      city: 'Long Branch',
      state: 'TX',
    },
    75670: {
      lat: 32.56456,
      lng: -94.42326,
      city: 'Marshall',
      state: 'TX',
    },
    75671: {
      lat: 32.48907,
      lng: -94.31494,
      city: 'Marshall',
      state: 'TX',
    },
    75672: {
      lat: 32.47076,
      lng: -94.29353,
      city: 'Marshall',
      state: 'TX',
    },
    75680: {
      lat: 32.01035,
      lng: -94.71824,
      city: 'Minden',
      state: 'TX',
    },
    75681: {
      lat: 31.93597,
      lng: -94.685,
      city: 'Mount Enterprise',
      state: 'TX',
    },
    75682: {
      lat: 32.25152,
      lng: -94.94713,
      city: 'New London',
      state: 'TX',
    },
    75683: {
      lat: 32.82182,
      lng: -94.74035,
      city: 'Ore City',
      state: 'TX',
    },
    75684: {
      lat: 32.27085,
      lng: -94.93147,
      city: 'Overton',
      state: 'TX',
    },
    75686: {
      lat: 32.96921,
      lng: -94.94697,
      city: 'Pittsburg',
      state: 'TX',
    },
    75687: {
      lat: 32.10575,
      lng: -94.96352,
      city: 'Price',
      state: 'TX',
    },
    75689: {
      lat: 32.18196,
      lng: -94.95771,
      city: 'Selman City',
      state: 'TX',
    },
    75691: {
      lat: 32.31108,
      lng: -94.55725,
      city: 'Tatum',
      state: 'TX',
    },
    75692: {
      lat: 32.4718,
      lng: -94.11936,
      city: 'Waskom',
      state: 'TX',
    },
    75693: {
      lat: 32.53364,
      lng: -94.86083,
      city: 'White Oak',
      state: 'TX',
    },
    75694: {
      lat: 32.6383,
      lng: -94.34442,
      city: 'Woodlawn',
      state: 'TX',
    },
    75701: {
      lat: 32.32297,
      lng: -95.29679,
      city: 'Tyler',
      state: 'TX',
    },
    75702: {
      lat: 32.36403,
      lng: -95.31207,
      city: 'Tyler',
      state: 'TX',
    },
    75703: {
      lat: 32.23825,
      lng: -95.31947,
      city: 'Tyler',
      state: 'TX',
    },
    75704: { lat: 32.40616, lng: -95.4407, city: 'Tyler', state: 'TX' },
    75705: {
      lat: 32.35905,
      lng: -95.10149,
      city: 'Tyler',
      state: 'TX',
    },
    75706: {
      lat: 32.46822,
      lng: -95.30402,
      city: 'Tyler',
      state: 'TX',
    },
    75707: { lat: 32.30479, lng: -95.171, city: 'Tyler', state: 'TX' },
    75708: {
      lat: 32.41436,
      lng: -95.20717,
      city: 'Tyler',
      state: 'TX',
    },
    75709: {
      lat: 32.31323,
      lng: -95.38904,
      city: 'Tyler',
      state: 'TX',
    },
    75711: {
      lat: 32.33698,
      lng: -95.28382,
      city: 'Tyler',
      state: 'TX',
    },
    75750: { lat: 32.27222, lng: -95.07124, city: 'Arp', state: 'TX' },
    75751: {
      lat: 32.07875,
      lng: -95.91661,
      city: 'Athens',
      state: 'TX',
    },
    75752: {
      lat: 32.24239,
      lng: -95.80751,
      city: 'Athens',
      state: 'TX',
    },
    75754: {
      lat: 32.42431,
      lng: -95.66462,
      city: 'Ben Wheeler',
      state: 'TX',
    },
    75755: {
      lat: 32.63236,
      lng: -95.08662,
      city: 'Big Sandy',
      state: 'TX',
    },
    75756: {
      lat: 32.30031,
      lng: -95.59771,
      city: 'Brownsboro',
      state: 'TX',
    },
    75757: {
      lat: 32.11584,
      lng: -95.34933,
      city: 'Bullard',
      state: 'TX',
    },
    75758: {
      lat: 32.27375,
      lng: -95.50484,
      city: 'Chandler',
      state: 'TX',
    },
    75759: {
      lat: 32.03617,
      lng: -95.41573,
      city: 'Cuney',
      state: 'TX',
    },
    75760: {
      lat: 31.80738,
      lng: -94.85758,
      city: 'Cushing',
      state: 'TX',
    },
    75762: {
      lat: 32.21926,
      lng: -95.41218,
      city: 'Flint',
      state: 'TX',
    },
    75763: {
      lat: 32.04154,
      lng: -95.52231,
      city: 'Frankston',
      state: 'TX',
    },
    75764: {
      lat: 31.89522,
      lng: -95.14369,
      city: 'Gallatin',
      state: 'TX',
    },
    75765: {
      lat: 32.64119,
      lng: -95.2359,
      city: 'Hawkins',
      state: 'TX',
    },
    75766: {
      lat: 31.94592,
      lng: -95.25181,
      city: 'Jacksonville',
      state: 'TX',
    },
    75770: {
      lat: 32.14058,
      lng: -95.64694,
      city: 'Larue',
      state: 'TX',
    },
    75771: {
      lat: 32.53542,
      lng: -95.43006,
      city: 'Lindale',
      state: 'TX',
    },
    75773: {
      lat: 32.67969,
      lng: -95.44654,
      city: 'Mineola',
      state: 'TX',
    },
    75778: {
      lat: 32.30239,
      lng: -95.71367,
      city: 'Murchison',
      state: 'TX',
    },
    75779: {
      lat: 31.86434,
      lng: -95.48486,
      city: 'Neches',
      state: 'TX',
    },
    75780: {
      lat: 31.98905,
      lng: -95.09122,
      city: 'New Summerfield',
      state: 'TX',
    },
    75782: {
      lat: 32.0693,
      lng: -95.58777,
      city: 'Poynor',
      state: 'TX',
    },
    75783: {
      lat: 32.82227,
      lng: -95.42386,
      city: 'Quitman',
      state: 'TX',
    },
    75784: {
      lat: 31.87344,
      lng: -94.96234,
      city: 'Reklaw',
      state: 'TX',
    },
    75785: { lat: 31.76641, lng: -95.17446, city: 'Rusk', state: 'TX' },
    75788: {
      lat: 31.82252,
      lng: -94.91617,
      city: 'Sacul',
      state: 'TX',
    },
    75789: { lat: 32.1126, lng: -95.09405, city: 'Troup', state: 'TX' },
    75790: { lat: 32.52749, lng: -95.64255, city: 'Van', state: 'TX' },
    75791: {
      lat: 32.22214,
      lng: -95.22103,
      city: 'Whitehouse',
      state: 'TX',
    },
    75792: {
      lat: 32.4679,
      lng: -95.10623,
      city: 'Winona',
      state: 'TX',
    },
    75801: {
      lat: 31.74004,
      lng: -95.55116,
      city: 'Palestine',
      state: 'TX',
    },
    75803: {
      lat: 31.87513,
      lng: -95.66704,
      city: 'Palestine',
      state: 'TX',
    },
    75831: {
      lat: 31.45745,
      lng: -96.02275,
      city: 'Buffalo',
      state: 'TX',
    },
    75833: {
      lat: 31.26304,
      lng: -95.83999,
      city: 'Centerville',
      state: 'TX',
    },
    75835: {
      lat: 31.29758,
      lng: -95.48834,
      city: 'Crockett',
      state: 'TX',
    },
    75838: {
      lat: 31.47956,
      lng: -96.22844,
      city: 'Donie',
      state: 'TX',
    },
    75839: {
      lat: 31.6181,
      lng: -95.55738,
      city: 'Elkhart',
      state: 'TX',
    },
    75840: {
      lat: 31.78412,
      lng: -96.08552,
      city: 'Fairfield',
      state: 'TX',
    },
    75844: {
      lat: 31.51921,
      lng: -95.43481,
      city: 'Grapeland',
      state: 'TX',
    },
    75845: {
      lat: 31.09488,
      lng: -95.08707,
      city: 'Groveton',
      state: 'TX',
    },
    75846: {
      lat: 31.34683,
      lng: -96.18118,
      city: 'Jewett',
      state: 'TX',
    },
    75847: {
      lat: 31.35204,
      lng: -95.13564,
      city: 'Kennard',
      state: 'TX',
    },
    75848: {
      lat: 31.77281,
      lng: -96.33408,
      city: 'Kirvin',
      state: 'TX',
    },
    75849: {
      lat: 31.39624,
      lng: -95.47618,
      city: 'Latexo',
      state: 'TX',
    },
    75850: {
      lat: 31.13209,
      lng: -95.91595,
      city: 'Leona',
      state: 'TX',
    },
    75851: {
      lat: 31.09458,
      lng: -95.49555,
      city: 'Lovelady',
      state: 'TX',
    },
    75852: {
      lat: 30.98723,
      lng: -95.72023,
      city: 'Midway',
      state: 'TX',
    },
    75853: {
      lat: 31.93488,
      lng: -95.80963,
      city: 'Montalba',
      state: 'TX',
    },
    75855: {
      lat: 31.5592,
      lng: -95.85447,
      city: 'Oakwood',
      state: 'TX',
    },
    75856: {
      lat: 31.20138,
      lng: -95.2537,
      city: 'Pennington',
      state: 'TX',
    },
    75858: {
      lat: 31.38083,
      lng: -95.13676,
      city: 'Ratcliff',
      state: 'TX',
    },
    75859: {
      lat: 31.8842,
      lng: -96.25512,
      city: 'Streetman',
      state: 'TX',
    },
    75860: {
      lat: 31.62342,
      lng: -96.24615,
      city: 'Teague',
      state: 'TX',
    },
    75861: {
      lat: 31.8524,
      lng: -95.88771,
      city: 'Tennessee Colony',
      state: 'TX',
    },
    75862: {
      lat: 30.96193,
      lng: -95.32951,
      city: 'Trinity',
      state: 'TX',
    },
    75865: {
      lat: 31.05808,
      lng: -94.96228,
      city: 'Woodlake',
      state: 'TX',
    },
    75884: {
      lat: 31.78928,
      lng: -95.89263,
      city: 'Tennessee Colony',
      state: 'TX',
    },
    75886: {
      lat: 31.78698,
      lng: -95.91234,
      city: 'Tennessee Colony',
      state: 'TX',
    },
    75901: {
      lat: 31.30264,
      lng: -94.65481,
      city: 'Lufkin',
      state: 'TX',
    },
    75903: {
      lat: 31.37428,
      lng: -94.74796,
      city: 'Lufkin',
      state: 'TX',
    },
    75904: {
      lat: 31.33872,
      lng: -94.83467,
      city: 'Lufkin',
      state: 'TX',
    },
    75925: { lat: 31.61018, lng: -95.05327, city: 'Alto', state: 'TX' },
    75926: {
      lat: 31.23281,
      lng: -94.96944,
      city: 'Apple Springs',
      state: 'TX',
    },
    75928: {
      lat: 30.67111,
      lng: -93.7064,
      city: 'Bon Wier',
      state: 'TX',
    },
    75929: {
      lat: 31.23579,
      lng: -94.19735,
      city: 'Broaddus',
      state: 'TX',
    },
    75930: {
      lat: 31.33641,
      lng: -94.03522,
      city: 'Bronson',
      state: 'TX',
    },
    75931: {
      lat: 31.09978,
      lng: -93.98953,
      city: 'Brookeland',
      state: 'TX',
    },
    75932: {
      lat: 31.0551,
      lng: -93.61396,
      city: 'Burkeville',
      state: 'TX',
    },
    75933: { lat: 30.55314, lng: -93.81608, city: 'Call', state: 'TX' },
    75934: {
      lat: 30.89688,
      lng: -94.75153,
      city: 'Camden',
      state: 'TX',
    },
    75935: {
      lat: 31.72999,
      lng: -94.20077,
      city: 'Center',
      state: 'TX',
    },
    75936: {
      lat: 30.95147,
      lng: -94.58883,
      city: 'Chester',
      state: 'TX',
    },
    75937: {
      lat: 31.47805,
      lng: -94.38259,
      city: 'Chireno',
      state: 'TX',
    },
    75938: {
      lat: 30.93436,
      lng: -94.35605,
      city: 'Colmesneil',
      state: 'TX',
    },
    75939: {
      lat: 31.01496,
      lng: -94.80501,
      city: 'Corrigan',
      state: 'TX',
    },
    75941: {
      lat: 31.18078,
      lng: -94.76219,
      city: 'Diboll',
      state: 'TX',
    },
    75942: {
      lat: 30.82567,
      lng: -94.41657,
      city: 'Doucette',
      state: 'TX',
    },
    75943: {
      lat: 31.65451,
      lng: -94.88935,
      city: 'Douglass',
      state: 'TX',
    },
    75944: {
      lat: 31.34514,
      lng: -94.38636,
      city: 'Etoile',
      state: 'TX',
    },
    75946: {
      lat: 31.81372,
      lng: -94.52392,
      city: 'Garrison',
      state: 'TX',
    },
    75948: {
      lat: 31.29389,
      lng: -93.77573,
      city: 'Hemphill',
      state: 'TX',
    },
    75949: {
      lat: 31.22907,
      lng: -94.51782,
      city: 'Huntington',
      state: 'TX',
    },
    75951: {
      lat: 30.93098,
      lng: -94.05762,
      city: 'Jasper',
      state: 'TX',
    },
    75954: {
      lat: 31.92169,
      lng: -94.05523,
      city: 'Joaquin',
      state: 'TX',
    },
    75956: {
      lat: 30.6721,
      lng: -93.96706,
      city: 'Kirbyville',
      state: 'TX',
    },
    75959: {
      lat: 31.51021,
      lng: -93.83994,
      city: 'Milam',
      state: 'TX',
    },
    75960: {
      lat: 30.90372,
      lng: -94.8618,
      city: 'Moscow',
      state: 'TX',
    },
    75961: {
      lat: 31.5576,
      lng: -94.51098,
      city: 'Nacogdoches',
      state: 'TX',
    },
    75962: {
      lat: 31.61981,
      lng: -94.6484,
      city: 'Nacogdoches',
      state: 'TX',
    },
    75964: {
      lat: 31.63334,
      lng: -94.75057,
      city: 'Nacogdoches',
      state: 'TX',
    },
    75965: {
      lat: 31.7284,
      lng: -94.62629,
      city: 'Nacogdoches',
      state: 'TX',
    },
    75966: {
      lat: 30.83783,
      lng: -93.72611,
      city: 'Newton',
      state: 'TX',
    },
    75968: {
      lat: 31.25882,
      lng: -93.95924,
      city: 'Pineland',
      state: 'TX',
    },
    75969: {
      lat: 31.44765,
      lng: -94.89758,
      city: 'Pollok',
      state: 'TX',
    },
    75972: {
      lat: 31.49407,
      lng: -94.12173,
      city: 'San Augustine',
      state: 'TX',
    },
    75973: {
      lat: 31.71977,
      lng: -93.93259,
      city: 'Shelbyville',
      state: 'TX',
    },
    75974: {
      lat: 31.95674,
      lng: -94.23713,
      city: 'Tenaha',
      state: 'TX',
    },
    75975: {
      lat: 31.89535,
      lng: -94.4122,
      city: 'Timpson',
      state: 'TX',
    },
    75976: {
      lat: 31.51546,
      lng: -94.93863,
      city: 'Wells',
      state: 'TX',
    },
    75977: {
      lat: 31.05997,
      lng: -93.78455,
      city: 'Wiergate',
      state: 'TX',
    },
    75979: {
      lat: 30.77477,
      lng: -94.4026,
      city: 'Woodville',
      state: 'TX',
    },
    75980: {
      lat: 31.12387,
      lng: -94.34703,
      city: 'Zavalla',
      state: 'TX',
    },
    76001: {
      lat: 32.63076,
      lng: -97.15408,
      city: 'Arlington',
      state: 'TX',
    },
    76002: {
      lat: 32.62233,
      lng: -97.09265,
      city: 'Arlington',
      state: 'TX',
    },
    76005: {
      lat: 32.80057,
      lng: -97.08001,
      city: 'Arlington',
      state: 'TX',
    },
    76006: {
      lat: 32.78511,
      lng: -97.10019,
      city: 'Arlington',
      state: 'TX',
    },
    76008: {
      lat: 32.68182,
      lng: -97.62738,
      city: 'Aledo',
      state: 'TX',
    },
    76009: {
      lat: 32.41635,
      lng: -97.20115,
      city: 'Alvarado',
      state: 'TX',
    },
    76010: {
      lat: 32.72271,
      lng: -97.08065,
      city: 'Arlington',
      state: 'TX',
    },
    76011: {
      lat: 32.7543,
      lng: -97.08223,
      city: 'Arlington',
      state: 'TX',
    },
    76012: {
      lat: 32.75459,
      lng: -97.13901,
      city: 'Arlington',
      state: 'TX',
    },
    76013: {
      lat: 32.72027,
      lng: -97.15652,
      city: 'Arlington',
      state: 'TX',
    },
    76014: {
      lat: 32.69201,
      lng: -97.08814,
      city: 'Arlington',
      state: 'TX',
    },
    76015: {
      lat: 32.69204,
      lng: -97.13341,
      city: 'Arlington',
      state: 'TX',
    },
    76016: {
      lat: 32.68907,
      lng: -97.18868,
      city: 'Arlington',
      state: 'TX',
    },
    76017: {
      lat: 32.66223,
      lng: -97.16237,
      city: 'Arlington',
      state: 'TX',
    },
    76018: {
      lat: 32.66074,
      lng: -97.08967,
      city: 'Arlington',
      state: 'TX',
    },
    76020: { lat: 32.90102, lng: -97.56483, city: 'Azle', state: 'TX' },
    76021: {
      lat: 32.8523,
      lng: -97.13128,
      city: 'Bedford',
      state: 'TX',
    },
    76022: {
      lat: 32.83063,
      lng: -97.14506,
      city: 'Bedford',
      state: 'TX',
    },
    76023: { lat: 33.04777, lng: -97.60425, city: 'Boyd', state: 'TX' },
    76028: {
      lat: 32.52603,
      lng: -97.30491,
      city: 'Burleson',
      state: 'TX',
    },
    76031: {
      lat: 32.34873,
      lng: -97.33267,
      city: 'Cleburne',
      state: 'TX',
    },
    76033: {
      lat: 32.28663,
      lng: -97.52075,
      city: 'Cleburne',
      state: 'TX',
    },
    76034: {
      lat: 32.89121,
      lng: -97.1488,
      city: 'Colleyville',
      state: 'TX',
    },
    76035: {
      lat: 32.56172,
      lng: -97.64251,
      city: 'Cresson',
      state: 'TX',
    },
    76036: {
      lat: 32.57366,
      lng: -97.41434,
      city: 'Crowley',
      state: 'TX',
    },
    76039: {
      lat: 32.8592,
      lng: -97.08422,
      city: 'Euless',
      state: 'TX',
    },
    76040: {
      lat: 32.82133,
      lng: -97.10488,
      city: 'Euless',
      state: 'TX',
    },
    76041: {
      lat: 32.23911,
      lng: -96.85724,
      city: 'Forreston',
      state: 'TX',
    },
    76043: {
      lat: 32.20039,
      lng: -97.76129,
      city: 'Glen Rose',
      state: 'TX',
    },
    76044: {
      lat: 32.42961,
      lng: -97.54286,
      city: 'Godley',
      state: 'TX',
    },
    76048: {
      lat: 32.40791,
      lng: -97.80117,
      city: 'Granbury',
      state: 'TX',
    },
    76049: {
      lat: 32.46759,
      lng: -97.70645,
      city: 'Granbury',
      state: 'TX',
    },
    76050: {
      lat: 32.27262,
      lng: -97.16326,
      city: 'Grandview',
      state: 'TX',
    },
    76051: {
      lat: 32.94192,
      lng: -97.07888,
      city: 'Grapevine',
      state: 'TX',
    },
    76052: {
      lat: 32.98205,
      lng: -97.38037,
      city: 'Haslet',
      state: 'TX',
    },
    76053: {
      lat: 32.81683,
      lng: -97.17898,
      city: 'Hurst',
      state: 'TX',
    },
    76054: {
      lat: 32.85904,
      lng: -97.17789,
      city: 'Hurst',
      state: 'TX',
    },
    76055: {
      lat: 32.1596,
      lng: -97.14812,
      city: 'Itasca',
      state: 'TX',
    },
    76058: {
      lat: 32.47072,
      lng: -97.4251,
      city: 'Joshua',
      state: 'TX',
    },
    76059: { lat: 32.3921, lng: -97.32897, city: 'Keene', state: 'TX' },
    76060: {
      lat: 32.63987,
      lng: -97.2161,
      city: 'Kennedale',
      state: 'TX',
    },
    76061: {
      lat: 32.5026,
      lng: -97.19813,
      city: 'Lillian',
      state: 'TX',
    },
    76063: {
      lat: 32.56913,
      lng: -97.14376,
      city: 'Mansfield',
      state: 'TX',
    },
    76064: {
      lat: 32.30757,
      lng: -97.03225,
      city: 'Maypearl',
      state: 'TX',
    },
    76065: {
      lat: 32.47042,
      lng: -96.98861,
      city: 'Midlothian',
      state: 'TX',
    },
    76066: {
      lat: 32.7058,
      lng: -97.99913,
      city: 'Millsap',
      state: 'TX',
    },
    76067: {
      lat: 32.80303,
      lng: -98.1449,
      city: 'Mineral Wells',
      state: 'TX',
    },
    76070: { lat: 32.24291, lng: -97.64991, city: 'Nemo', state: 'TX' },
    76071: {
      lat: 33.00613,
      lng: -97.47821,
      city: 'Newark',
      state: 'TX',
    },
    76073: {
      lat: 33.10416,
      lng: -97.72571,
      city: 'Paradise',
      state: 'TX',
    },
    76077: {
      lat: 32.27886,
      lng: -97.70891,
      city: 'Rainbow',
      state: 'TX',
    },
    76078: { lat: 33.0896, lng: -97.46647, city: 'Rhome', state: 'TX' },
    76082: {
      lat: 32.97098,
      lng: -97.71783,
      city: 'Springtown',
      state: 'TX',
    },
    76084: { lat: 32.43258, lng: -97.0888, city: 'Venus', state: 'TX' },
    76085: {
      lat: 32.84907,
      lng: -97.70051,
      city: 'Weatherford',
      state: 'TX',
    },
    76086: {
      lat: 32.75345,
      lng: -97.78909,
      city: 'Weatherford',
      state: 'TX',
    },
    76087: {
      lat: 32.66995,
      lng: -97.79065,
      city: 'Weatherford',
      state: 'TX',
    },
    76088: {
      lat: 32.84554,
      lng: -97.90345,
      city: 'Weatherford',
      state: 'TX',
    },
    76092: {
      lat: 32.95562,
      lng: -97.14879,
      city: 'Southlake',
      state: 'TX',
    },
    76093: {
      lat: 32.21459,
      lng: -97.40098,
      city: 'Rio Vista',
      state: 'TX',
    },
    76102: {
      lat: 32.75799,
      lng: -97.32858,
      city: 'Fort Worth',
      state: 'TX',
    },
    76103: {
      lat: 32.75282,
      lng: -97.26627,
      city: 'Fort Worth',
      state: 'TX',
    },
    76104: {
      lat: 32.72848,
      lng: -97.31819,
      city: 'Fort Worth',
      state: 'TX',
    },
    76105: {
      lat: 32.72327,
      lng: -97.26999,
      city: 'Fort Worth',
      state: 'TX',
    },
    76106: {
      lat: 32.81417,
      lng: -97.35209,
      city: 'Fort Worth',
      state: 'TX',
    },
    76107: {
      lat: 32.74176,
      lng: -97.38202,
      city: 'Fort Worth',
      state: 'TX',
    },
    76108: {
      lat: 32.77943,
      lng: -97.51811,
      city: 'Fort Worth',
      state: 'TX',
    },
    76109: {
      lat: 32.70115,
      lng: -97.38378,
      city: 'Fort Worth',
      state: 'TX',
    },
    76110: {
      lat: 32.70678,
      lng: -97.33843,
      city: 'Fort Worth',
      state: 'TX',
    },
    76111: {
      lat: 32.7804,
      lng: -97.30116,
      city: 'Fort Worth',
      state: 'TX',
    },
    76112: {
      lat: 32.74831,
      lng: -97.21828,
      city: 'Fort Worth',
      state: 'TX',
    },
    76114: {
      lat: 32.77365,
      lng: -97.40417,
      city: 'Fort Worth',
      state: 'TX',
    },
    76115: {
      lat: 32.67861,
      lng: -97.33117,
      city: 'Fort Worth',
      state: 'TX',
    },
    76116: {
      lat: 32.72128,
      lng: -97.44766,
      city: 'Fort Worth',
      state: 'TX',
    },
    76117: {
      lat: 32.80374,
      lng: -97.26766,
      city: 'Haltom City',
      state: 'TX',
    },
    76118: {
      lat: 32.79606,
      lng: -97.1972,
      city: 'Fort Worth',
      state: 'TX',
    },
    76119: {
      lat: 32.69026,
      lng: -97.26176,
      city: 'Fort Worth',
      state: 'TX',
    },
    76120: {
      lat: 32.76742,
      lng: -97.18286,
      city: 'Fort Worth',
      state: 'TX',
    },
    76123: {
      lat: 32.62298,
      lng: -97.40262,
      city: 'Fort Worth',
      state: 'TX',
    },
    76126: {
      lat: 32.64374,
      lng: -97.51767,
      city: 'Fort Worth',
      state: 'TX',
    },
    76127: {
      lat: 32.77663,
      lng: -97.43044,
      city: 'Naval Air Station Jrb',
      state: 'TX',
    },
    76129: {
      lat: 32.70934,
      lng: -97.36255,
      city: 'Fort Worth',
      state: 'TX',
    },
    76131: {
      lat: 32.88227,
      lng: -97.34895,
      city: 'Fort Worth',
      state: 'TX',
    },
    76132: {
      lat: 32.66743,
      lng: -97.41827,
      city: 'Fort Worth',
      state: 'TX',
    },
    76133: {
      lat: 32.6538,
      lng: -97.37746,
      city: 'Fort Worth',
      state: 'TX',
    },
    76134: {
      lat: 32.64331,
      lng: -97.33103,
      city: 'Fort Worth',
      state: 'TX',
    },
    76135: {
      lat: 32.83708,
      lng: -97.46621,
      city: 'Fort Worth',
      state: 'TX',
    },
    76137: {
      lat: 32.85864,
      lng: -97.29137,
      city: 'Fort Worth',
      state: 'TX',
    },
    76140: {
      lat: 32.62012,
      lng: -97.27036,
      city: 'Fort Worth',
      state: 'TX',
    },
    76148: {
      lat: 32.86789,
      lng: -97.25197,
      city: 'Fort Worth',
      state: 'TX',
    },
    76155: {
      lat: 32.82404,
      lng: -97.04911,
      city: 'Fort Worth',
      state: 'TX',
    },
    76164: {
      lat: 32.7808,
      lng: -97.35411,
      city: 'Fort Worth',
      state: 'TX',
    },
    76177: {
      lat: 32.96999,
      lng: -97.30611,
      city: 'Fort Worth',
      state: 'TX',
    },
    76179: {
      lat: 32.91397,
      lng: -97.43947,
      city: 'Fort Worth',
      state: 'TX',
    },
    76180: {
      lat: 32.83994,
      lng: -97.22468,
      city: 'North Richland Hills',
      state: 'TX',
    },
    76182: {
      lat: 32.88257,
      lng: -97.20969,
      city: 'North Richland Hills',
      state: 'TX',
    },
    76201: {
      lat: 33.22122,
      lng: -97.14715,
      city: 'Denton',
      state: 'TX',
    },
    76203: {
      lat: 33.21213,
      lng: -97.15181,
      city: 'Denton',
      state: 'TX',
    },
    76205: {
      lat: 33.19074,
      lng: -97.12894,
      city: 'Denton',
      state: 'TX',
    },
    76207: {
      lat: 33.22174,
      lng: -97.18505,
      city: 'Denton',
      state: 'TX',
    },
    76208: {
      lat: 33.20917,
      lng: -97.05786,
      city: 'Denton',
      state: 'TX',
    },
    76209: { lat: 33.2315, lng: -97.11, city: 'Denton', state: 'TX' },
    76210: {
      lat: 33.14985,
      lng: -97.0962,
      city: 'Denton',
      state: 'TX',
    },
    76225: {
      lat: 33.36616,
      lng: -97.67345,
      city: 'Alvord',
      state: 'TX',
    },
    76226: {
      lat: 33.10775,
      lng: -97.17196,
      city: 'Argyle',
      state: 'TX',
    },
    76227: {
      lat: 33.27465,
      lng: -96.99075,
      city: 'Aubrey',
      state: 'TX',
    },
    76228: {
      lat: 33.61438,
      lng: -98.06305,
      city: 'Bellevue',
      state: 'TX',
    },
    76230: {
      lat: 33.52264,
      lng: -97.94497,
      city: 'Bowie',
      state: 'TX',
    },
    76233: {
      lat: 33.54025,
      lng: -96.89696,
      city: 'Collinsville',
      state: 'TX',
    },
    76234: {
      lat: 33.27587,
      lng: -97.51404,
      city: 'Decatur',
      state: 'TX',
    },
    76238: { lat: 33.4861, lng: -97.34148, city: 'Era', state: 'TX' },
    76239: {
      lat: 33.524,
      lng: -97.57033,
      city: 'Forestburg',
      state: 'TX',
    },
    76240: {
      lat: 33.65654,
      lng: -97.17766,
      city: 'Gainesville',
      state: 'TX',
    },
    76241: {
      lat: 33.64542,
      lng: -97.17628,
      city: 'Gainesville',
      state: 'TX',
    },
    76244: {
      lat: 32.93101,
      lng: -97.28076,
      city: 'Keller',
      state: 'TX',
    },
    76245: {
      lat: 33.82885,
      lng: -96.85422,
      city: 'Gordonville',
      state: 'TX',
    },
    76247: {
      lat: 33.0965,
      lng: -97.33154,
      city: 'Justin',
      state: 'TX',
    },
    76248: {
      lat: 32.92988,
      lng: -97.22972,
      city: 'Keller',
      state: 'TX',
    },
    76249: { lat: 33.27993, lng: -97.3026, city: 'Krum', state: 'TX' },
    76250: {
      lat: 33.63006,
      lng: -97.24237,
      city: 'Lindsay',
      state: 'TX',
    },
    76251: {
      lat: 33.65455,
      lng: -97.69076,
      city: 'Montague',
      state: 'TX',
    },
    76252: {
      lat: 33.67788,
      lng: -97.38695,
      city: 'Muenster',
      state: 'TX',
    },
    76253: { lat: 33.62516, lng: -97.31327, city: 'Myra', state: 'TX' },
    76255: {
      lat: 33.8179,
      lng: -97.71625,
      city: 'Nocona',
      state: 'TX',
    },
    76258: {
      lat: 33.37202,
      lng: -96.9204,
      city: 'Pilot Point',
      state: 'TX',
    },
    76259: {
      lat: 33.19977,
      lng: -97.31603,
      city: 'Ponder',
      state: 'TX',
    },
    76261: {
      lat: 33.80291,
      lng: -97.96233,
      city: 'Ringgold',
      state: 'TX',
    },
    76262: {
      lat: 33.01137,
      lng: -97.22101,
      city: 'Roanoke',
      state: 'TX',
    },
    76263: {
      lat: 33.50435,
      lng: -97.43735,
      city: 'Rosston',
      state: 'TX',
    },
    76264: {
      lat: 33.74172,
      lng: -96.83509,
      city: 'Sadler',
      state: 'TX',
    },
    76265: {
      lat: 33.74079,
      lng: -97.53922,
      city: 'Saint Jo',
      state: 'TX',
    },
    76266: {
      lat: 33.36818,
      lng: -97.21513,
      city: 'Sanger',
      state: 'TX',
    },
    76267: {
      lat: 33.36935,
      lng: -97.39711,
      city: 'Slidell',
      state: 'TX',
    },
    76268: {
      lat: 33.62785,
      lng: -96.76539,
      city: 'Southmayd',
      state: 'TX',
    },
    76270: {
      lat: 33.45471,
      lng: -97.76247,
      city: 'Sunset',
      state: 'TX',
    },
    76271: {
      lat: 33.46953,
      lng: -96.91412,
      city: 'Tioga',
      state: 'TX',
    },
    76272: {
      lat: 33.46421,
      lng: -97.12721,
      city: 'Valley View',
      state: 'TX',
    },
    76273: {
      lat: 33.74794,
      lng: -96.93935,
      city: 'Whitesboro',
      state: 'TX',
    },
    76301: {
      lat: 33.90507,
      lng: -98.48043,
      city: 'Wichita Falls',
      state: 'TX',
    },
    76302: {
      lat: 33.86607,
      lng: -98.48487,
      city: 'Wichita Falls',
      state: 'TX',
    },
    76305: {
      lat: 33.99979,
      lng: -98.3909,
      city: 'Wichita Falls',
      state: 'TX',
    },
    76306: {
      lat: 33.95237,
      lng: -98.52745,
      city: 'Wichita Falls',
      state: 'TX',
    },
    76308: {
      lat: 33.85684,
      lng: -98.5378,
      city: 'Wichita Falls',
      state: 'TX',
    },
    76309: {
      lat: 33.89551,
      lng: -98.544,
      city: 'Wichita Falls',
      state: 'TX',
    },
    76310: {
      lat: 33.81115,
      lng: -98.51178,
      city: 'Wichita Falls',
      state: 'TX',
    },
    76311: {
      lat: 33.97324,
      lng: -98.50823,
      city: 'Sheppard Afb',
      state: 'TX',
    },
    76351: {
      lat: 33.55553,
      lng: -98.71145,
      city: 'Archer City',
      state: 'TX',
    },
    76354: {
      lat: 34.09521,
      lng: -98.62276,
      city: 'Burkburnett',
      state: 'TX',
    },
    76357: {
      lat: 34.07875,
      lng: -98.18512,
      city: 'Byers',
      state: 'TX',
    },
    76360: {
      lat: 33.97361,
      lng: -99.00302,
      city: 'Electra',
      state: 'TX',
    },
    76363: {
      lat: 33.50762,
      lng: -99.53122,
      city: 'Goree',
      state: 'TX',
    },
    76364: {
      lat: 34.13998,
      lng: -99.00529,
      city: 'Harrold',
      state: 'TX',
    },
    76365: {
      lat: 33.754,
      lng: -98.2076,
      city: 'Henrietta',
      state: 'TX',
    },
    76366: {
      lat: 33.71439,
      lng: -98.77436,
      city: 'Holliday',
      state: 'TX',
    },
    76367: {
      lat: 33.97361,
      lng: -98.7168,
      city: 'Iowa Park',
      state: 'TX',
    },
    76370: {
      lat: 33.45624,
      lng: -98.89407,
      city: 'Megargel',
      state: 'TX',
    },
    76371: {
      lat: 33.48668,
      lng: -99.65453,
      city: 'Munday',
      state: 'TX',
    },
    76372: {
      lat: 33.25173,
      lng: -98.93643,
      city: 'Newcastle',
      state: 'TX',
    },
    76373: {
      lat: 34.15541,
      lng: -99.11973,
      city: 'Oklaunion',
      state: 'TX',
    },
    76374: { lat: 33.34823, lng: -98.7648, city: 'Olney', state: 'TX' },
    76377: {
      lat: 34.0101,
      lng: -98.24191,
      city: 'Petrolia',
      state: 'TX',
    },
    76379: {
      lat: 33.67058,
      lng: -98.47453,
      city: 'Scotland',
      state: 'TX',
    },
    76380: {
      lat: 33.60434,
      lng: -99.22305,
      city: 'Seymour',
      state: 'TX',
    },
    76384: {
      lat: 34.14355,
      lng: -99.32067,
      city: 'Vernon',
      state: 'TX',
    },
    76388: {
      lat: 33.31785,
      lng: -99.62931,
      city: 'Weinert',
      state: 'TX',
    },
    76389: {
      lat: 33.49713,
      lng: -98.47568,
      city: 'Windthorst',
      state: 'TX',
    },
    76401: {
      lat: 32.27918,
      lng: -98.19994,
      city: 'Stephenville',
      state: 'TX',
    },
    76402: {
      lat: 32.21728,
      lng: -98.21702,
      city: 'Stephenville',
      state: 'TX',
    },
    76424: {
      lat: 32.80431,
      lng: -98.89236,
      city: 'Breckenridge',
      state: 'TX',
    },
    76426: {
      lat: 33.16493,
      lng: -97.83772,
      city: 'Bridgeport',
      state: 'TX',
    },
    76427: {
      lat: 33.13145,
      lng: -98.39271,
      city: 'Bryson',
      state: 'TX',
    },
    76429: {
      lat: 32.74742,
      lng: -98.67428,
      city: 'Caddo',
      state: 'TX',
    },
    76430: {
      lat: 32.80303,
      lng: -99.23519,
      city: 'Albany',
      state: 'TX',
    },
    76431: {
      lat: 33.31066,
      lng: -97.86228,
      city: 'Chico',
      state: 'TX',
    },
    76432: {
      lat: 31.83945,
      lng: -98.78705,
      city: 'Blanket',
      state: 'TX',
    },
    76433: {
      lat: 32.30662,
      lng: -98.01096,
      city: 'Bluff Dale',
      state: 'TX',
    },
    76435: {
      lat: 32.21754,
      lng: -98.85349,
      city: 'Carbon',
      state: 'TX',
    },
    76436: {
      lat: 31.87404,
      lng: -98.22271,
      city: 'Carlton',
      state: 'TX',
    },
    76437: { lat: 32.388, lng: -99.01935, city: 'Cisco', state: 'TX' },
    76439: {
      lat: 32.6176,
      lng: -97.92708,
      city: 'Dennis',
      state: 'TX',
    },
    76442: {
      lat: 31.89015,
      lng: -98.61242,
      city: 'Comanche',
      state: 'TX',
    },
    76443: {
      lat: 32.11907,
      lng: -99.20174,
      city: 'Cross Plains',
      state: 'TX',
    },
    76444: {
      lat: 32.11781,
      lng: -98.5672,
      city: 'De Leon',
      state: 'TX',
    },
    76445: {
      lat: 32.30318,
      lng: -98.53829,
      city: 'Desdemona',
      state: 'TX',
    },
    76446: {
      lat: 32.07572,
      lng: -98.35311,
      city: 'Dublin',
      state: 'TX',
    },
    76448: {
      lat: 32.39257,
      lng: -98.79541,
      city: 'Eastland',
      state: 'TX',
    },
    76449: {
      lat: 32.91592,
      lng: -98.32512,
      city: 'Graford',
      state: 'TX',
    },
    76450: {
      lat: 33.05968,
      lng: -98.64152,
      city: 'Graham',
      state: 'TX',
    },
    76452: {
      lat: 31.77119,
      lng: -98.39198,
      city: 'Energy',
      state: 'TX',
    },
    76453: {
      lat: 32.57202,
      lng: -98.33908,
      city: 'Gordon',
      state: 'TX',
    },
    76454: {
      lat: 32.22983,
      lng: -98.69621,
      city: 'Gorman',
      state: 'TX',
    },
    76455: {
      lat: 31.82142,
      lng: -98.36676,
      city: 'Gustine',
      state: 'TX',
    },
    76457: { lat: 31.94968, lng: -98.02773, city: 'Hico', state: 'TX' },
    76458: {
      lat: 33.21518,
      lng: -98.18944,
      city: 'Jacksboro',
      state: 'TX',
    },
    76459: {
      lat: 33.27395,
      lng: -98.38972,
      city: 'Jermyn',
      state: 'TX',
    },
    76460: {
      lat: 33.30796,
      lng: -98.49798,
      city: 'Loving',
      state: 'TX',
    },
    76462: {
      lat: 32.52316,
      lng: -98.03421,
      city: 'Lipan',
      state: 'TX',
    },
    76463: { lat: 32.455, lng: -98.41516, city: 'Mingus', state: 'TX' },
    76464: { lat: 32.57554, lng: -99.1542, city: 'Moran', state: 'TX' },
    76466: { lat: 32.42887, lng: -98.7332, city: 'Olden', state: 'TX' },
    76469: {
      lat: 32.34696,
      lng: -99.21707,
      city: 'Putnam',
      state: 'TX',
    },
    76470: {
      lat: 32.49977,
      lng: -98.6686,
      city: 'Ranger',
      state: 'TX',
    },
    76471: {
      lat: 32.11117,
      lng: -98.98499,
      city: 'Rising Star',
      state: 'TX',
    },
    76472: {
      lat: 32.60631,
      lng: -98.17095,
      city: 'Santo',
      state: 'TX',
    },
    76474: {
      lat: 31.97036,
      lng: -98.79142,
      city: 'Sidney',
      state: 'TX',
    },
    76475: {
      lat: 32.65296,
      lng: -98.51281,
      city: 'Strawn',
      state: 'TX',
    },
    76476: {
      lat: 32.37055,
      lng: -97.92017,
      city: 'Tolar',
      state: 'TX',
    },
    76481: {
      lat: 32.99706,
      lng: -98.70977,
      city: 'South Bend',
      state: 'TX',
    },
    76483: {
      lat: 33.16667,
      lng: -99.25111,
      city: 'Throckmorton',
      state: 'TX',
    },
    76484: {
      lat: 32.73147,
      lng: -98.30551,
      city: 'Palo Pinto',
      state: 'TX',
    },
    76486: {
      lat: 33.02138,
      lng: -98.05618,
      city: 'Perrin',
      state: 'TX',
    },
    76487: {
      lat: 32.97912,
      lng: -97.88346,
      city: 'Poolville',
      state: 'TX',
    },
    76490: {
      lat: 32.95721,
      lng: -98.04033,
      city: 'Whitt',
      state: 'TX',
    },
    76491: {
      lat: 33.05754,
      lng: -99.03834,
      city: 'Woodson',
      state: 'TX',
    },
    76501: {
      lat: 31.07555,
      lng: -97.25469,
      city: 'Temple',
      state: 'TX',
    },
    76502: {
      lat: 31.09388,
      lng: -97.41082,
      city: 'Temple',
      state: 'TX',
    },
    76504: {
      lat: 31.14135,
      lng: -97.37748,
      city: 'Temple',
      state: 'TX',
    },
    76511: {
      lat: 30.79955,
      lng: -97.42293,
      city: 'Bartlett',
      state: 'TX',
    },
    76513: {
      lat: 31.05777,
      lng: -97.50369,
      city: 'Belton',
      state: 'TX',
    },
    76518: {
      lat: 30.85091,
      lng: -97.13957,
      city: 'Buckholts',
      state: 'TX',
    },
    76519: {
      lat: 30.98978,
      lng: -97.03579,
      city: 'Burlington',
      state: 'TX',
    },
    76520: {
      lat: 30.87266,
      lng: -96.89972,
      city: 'Cameron',
      state: 'TX',
    },
    76522: {
      lat: 31.21577,
      lng: -97.97215,
      city: 'Copperas Cove',
      state: 'TX',
    },
    76523: {
      lat: 30.80366,
      lng: -97.27795,
      city: 'Davilla',
      state: 'TX',
    },
    76524: { lat: 31.27445, lng: -97.20736, city: 'Eddy', state: 'TX' },
    76525: {
      lat: 31.47643,
      lng: -98.17321,
      city: 'Evant',
      state: 'TX',
    },
    76527: {
      lat: 30.8379,
      lng: -97.79531,
      city: 'Florence',
      state: 'TX',
    },
    76528: {
      lat: 31.43589,
      lng: -97.7844,
      city: 'Gatesville',
      state: 'TX',
    },
    76530: {
      lat: 30.71357,
      lng: -97.42434,
      city: 'Granger',
      state: 'TX',
    },
    76531: {
      lat: 31.68423,
      lng: -98.19058,
      city: 'Hamilton',
      state: 'TX',
    },
    76534: {
      lat: 30.88225,
      lng: -97.36952,
      city: 'Holland',
      state: 'TX',
    },
    76537: {
      lat: 30.81779,
      lng: -97.60645,
      city: 'Jarrell',
      state: 'TX',
    },
    76538: {
      lat: 31.64261,
      lng: -97.8896,
      city: 'Jonesboro',
      state: 'TX',
    },
    76539: {
      lat: 31.09193,
      lng: -97.9992,
      city: 'Kempner',
      state: 'TX',
    },
    76541: {
      lat: 31.11447,
      lng: -97.72922,
      city: 'Killeen',
      state: 'TX',
    },
    76542: {
      lat: 31.00062,
      lng: -97.7274,
      city: 'Killeen',
      state: 'TX',
    },
    76543: {
      lat: 31.12343,
      lng: -97.64077,
      city: 'Killeen',
      state: 'TX',
    },
    76544: {
      lat: 31.23134,
      lng: -97.75867,
      city: 'Fort Cavazos',
      state: 'TX',
    },
    76548: {
      lat: 31.05299,
      lng: -97.64613,
      city: 'Harker Heights',
      state: 'TX',
    },
    76549: {
      lat: 31.01583,
      lng: -97.82546,
      city: 'Killeen',
      state: 'TX',
    },
    76550: {
      lat: 31.07714,
      lng: -98.22165,
      city: 'Lampasas',
      state: 'TX',
    },
    76554: {
      lat: 30.97583,
      lng: -97.3677,
      city: 'Little River Academy',
      state: 'TX',
    },
    76556: {
      lat: 30.69481,
      lng: -96.84487,
      city: 'Milano',
      state: 'TX',
    },
    76557: {
      lat: 31.29075,
      lng: -97.39814,
      city: 'Moody',
      state: 'TX',
    },
    76559: {
      lat: 31.07987,
      lng: -97.61079,
      city: 'Nolanville',
      state: 'TX',
    },
    76561: {
      lat: 31.42531,
      lng: -97.54098,
      city: 'Oglesby',
      state: 'TX',
    },
    76565: {
      lat: 31.64508,
      lng: -98.3492,
      city: 'Pottsville',
      state: 'TX',
    },
    76566: {
      lat: 31.51664,
      lng: -97.99881,
      city: 'Purmela',
      state: 'TX',
    },
    76567: {
      lat: 30.64535,
      lng: -97.01363,
      city: 'Rockdale',
      state: 'TX',
    },
    76569: {
      lat: 30.93873,
      lng: -97.21904,
      city: 'Rogers',
      state: 'TX',
    },
    76570: {
      lat: 31.0818,
      lng: -96.94173,
      city: 'Rosebud',
      state: 'TX',
    },
    76571: {
      lat: 30.93305,
      lng: -97.58448,
      city: 'Salado',
      state: 'TX',
    },
    76573: {
      lat: 30.81864,
      lng: -97.50226,
      city: 'Schwertner',
      state: 'TX',
    },
    76574: {
      lat: 30.58297,
      lng: -97.38967,
      city: 'Taylor',
      state: 'TX',
    },
    76577: {
      lat: 30.62128,
      lng: -97.1787,
      city: 'Thorndale',
      state: 'TX',
    },
    76578: {
      lat: 30.53488,
      lng: -97.24092,
      city: 'Thrall',
      state: 'TX',
    },
    76579: { lat: 31.19894, lng: -97.26046, city: 'Troy', state: 'TX' },
    76596: {
      lat: 31.47626,
      lng: -97.72696,
      city: 'Gatesville',
      state: 'TX',
    },
    76597: {
      lat: 31.48446,
      lng: -97.70983,
      city: 'Gatesville',
      state: 'TX',
    },
    76598: {
      lat: 31.47543,
      lng: -97.73488,
      city: 'Gatesville',
      state: 'TX',
    },
    76599: {
      lat: 31.47092,
      lng: -97.73361,
      city: 'Gatesville',
      state: 'TX',
    },
    76621: {
      lat: 31.87789,
      lng: -97.08891,
      city: 'Abbott',
      state: 'TX',
    },
    76622: {
      lat: 31.82032,
      lng: -97.24942,
      city: 'Aquilla',
      state: 'TX',
    },
    76623: { lat: 32.219, lng: -96.78879, city: 'Avalon', state: 'TX' },
    76624: {
      lat: 31.66671,
      lng: -96.95099,
      city: 'Axtell',
      state: 'TX',
    },
    76626: {
      lat: 32.10522,
      lng: -96.71615,
      city: 'Blooming Grove',
      state: 'TX',
    },
    76627: { lat: 32.10511, lng: -97.3881, city: 'Blum', state: 'TX' },
    76628: {
      lat: 32.03007,
      lng: -96.97179,
      city: 'Brandon',
      state: 'TX',
    },
    76629: {
      lat: 31.15114,
      lng: -96.65499,
      city: 'Bremond',
      state: 'TX',
    },
    76630: {
      lat: 31.33993,
      lng: -97.22405,
      city: 'Bruceville',
      state: 'TX',
    },
    76631: {
      lat: 31.97905,
      lng: -96.96277,
      city: 'Bynum',
      state: 'TX',
    },
    76632: {
      lat: 31.29602,
      lng: -97.06158,
      city: 'Chilton',
      state: 'TX',
    },
    76633: {
      lat: 31.6857,
      lng: -97.32503,
      city: 'China Spring',
      state: 'TX',
    },
    76634: {
      lat: 31.80953,
      lng: -97.56866,
      city: 'Clifton',
      state: 'TX',
    },
    76635: {
      lat: 31.72956,
      lng: -96.66483,
      city: 'Coolidge',
      state: 'TX',
    },
    76636: {
      lat: 32.17213,
      lng: -97.28307,
      city: 'Covington',
      state: 'TX',
    },
    76637: {
      lat: 31.76631,
      lng: -97.81817,
      city: 'Cranfills Gap',
      state: 'TX',
    },
    76638: {
      lat: 31.55487,
      lng: -97.446,
      city: 'Crawford',
      state: 'TX',
    },
    76639: {
      lat: 31.87254,
      lng: -96.65551,
      city: 'Dawson',
      state: 'TX',
    },
    76640: {
      lat: 31.69029,
      lng: -97.07323,
      city: 'Elm Mott',
      state: 'TX',
    },
    76641: {
      lat: 32.03847,
      lng: -96.78723,
      city: 'Frost',
      state: 'TX',
    },
    76642: {
      lat: 31.50442,
      lng: -96.53271,
      city: 'Groesbeck',
      state: 'TX',
    },
    76643: {
      lat: 31.45011,
      lng: -97.19732,
      city: 'Hewitt',
      state: 'TX',
    },
    76645: {
      lat: 32.01224,
      lng: -97.12816,
      city: 'Hillsboro',
      state: 'TX',
    },
    76648: {
      lat: 31.84942,
      lng: -96.80659,
      city: 'Hubbard',
      state: 'TX',
    },
    76649: {
      lat: 32.00132,
      lng: -97.87816,
      city: 'Iredell',
      state: 'TX',
    },
    76650: {
      lat: 31.98994,
      lng: -96.86678,
      city: 'Irene',
      state: 'TX',
    },
    76651: {
      lat: 32.18313,
      lng: -96.87158,
      city: 'Italy',
      state: 'TX',
    },
    76652: {
      lat: 32.11331,
      lng: -97.57648,
      city: 'Kopperl',
      state: 'TX',
    },
    76653: {
      lat: 31.29963,
      lng: -96.60536,
      city: 'Kosse',
      state: 'TX',
    },
    76654: {
      lat: 31.72708,
      lng: -97.01346,
      city: 'Leroy',
      state: 'TX',
    },
    76655: {
      lat: 31.39295,
      lng: -97.1728,
      city: 'Lorena',
      state: 'TX',
    },
    76656: { lat: 31.18555, lng: -97.06161, city: 'Lott', state: 'TX' },
    76657: {
      lat: 31.43851,
      lng: -97.39971,
      city: 'Mcgregor',
      state: 'TX',
    },
    76660: {
      lat: 31.93015,
      lng: -96.89331,
      city: 'Malone',
      state: 'TX',
    },
    76661: {
      lat: 31.30785,
      lng: -96.84584,
      city: 'Marlin',
      state: 'TX',
    },
    76664: { lat: 31.56202, lng: -96.79552, city: 'Mart', state: 'TX' },
    76665: {
      lat: 31.91784,
      lng: -97.72049,
      city: 'Meridian',
      state: 'TX',
    },
    76666: {
      lat: 32.04653,
      lng: -96.89178,
      city: 'Mertens',
      state: 'TX',
    },
    76667: {
      lat: 31.65668,
      lng: -96.46511,
      city: 'Mexia',
      state: 'TX',
    },
    76670: {
      lat: 32.12887,
      lng: -96.98836,
      city: 'Milford',
      state: 'TX',
    },
    76671: {
      lat: 31.99351,
      lng: -97.52768,
      city: 'Morgan',
      state: 'TX',
    },
    76673: {
      lat: 31.75371,
      lng: -96.89105,
      city: 'Mount Calm',
      state: 'TX',
    },
    76676: {
      lat: 31.87169,
      lng: -96.95847,
      city: 'Penelope',
      state: 'TX',
    },
    76678: {
      lat: 31.65009,
      lng: -96.75087,
      city: 'Prairie Hill',
      state: 'TX',
    },
    76679: {
      lat: 31.94557,
      lng: -96.59358,
      city: 'Purdon',
      state: 'TX',
    },
    76680: {
      lat: 31.18798,
      lng: -96.80383,
      city: 'Reagan',
      state: 'TX',
    },
    76681: {
      lat: 31.91071,
      lng: -96.44006,
      city: 'Richland',
      state: 'TX',
    },
    76682: {
      lat: 31.45235,
      lng: -96.90825,
      city: 'Riesel',
      state: 'TX',
    },
    76685: { lat: 31.34973, lng: -97.0101, city: 'Satin', state: 'TX' },
    76686: {
      lat: 31.74178,
      lng: -96.54464,
      city: 'Tehuacana',
      state: 'TX',
    },
    76687: {
      lat: 31.36914,
      lng: -96.48691,
      city: 'Thornton',
      state: 'TX',
    },
    76689: {
      lat: 31.66624,
      lng: -97.50471,
      city: 'Valley Mills',
      state: 'TX',
    },
    76690: {
      lat: 32.1031,
      lng: -97.79219,
      city: 'Walnut Springs',
      state: 'TX',
    },
    76691: { lat: 31.77564, lng: -97.09721, city: 'West', state: 'TX' },
    76692: {
      lat: 31.95902,
      lng: -97.33382,
      city: 'Whitney',
      state: 'TX',
    },
    76693: {
      lat: 31.80977,
      lng: -96.43843,
      city: 'Wortham',
      state: 'TX',
    },
    76701: { lat: 31.55201, lng: -97.13852, city: 'Waco', state: 'TX' },
    76704: { lat: 31.57558, lng: -97.12417, city: 'Waco', state: 'TX' },
    76705: { lat: 31.62448, lng: -97.08814, city: 'Waco', state: 'TX' },
    76706: { lat: 31.465, lng: -97.09131, city: 'Waco', state: 'TX' },
    76707: { lat: 31.55365, lng: -97.15829, city: 'Waco', state: 'TX' },
    76708: { lat: 31.62749, lng: -97.21186, city: 'Waco', state: 'TX' },
    76710: { lat: 31.5387, lng: -97.19554, city: 'Waco', state: 'TX' },
    76711: { lat: 31.51422, lng: -97.15374, city: 'Waco', state: 'TX' },
    76712: {
      lat: 31.52411,
      lng: -97.25514,
      city: 'Woodway',
      state: 'TX',
    },
    76798: { lat: 31.54606, lng: -97.12085, city: 'Waco', state: 'TX' },
    76801: {
      lat: 31.75471,
      lng: -99.05462,
      city: 'Brownwood',
      state: 'TX',
    },
    76802: { lat: 31.7194, lng: -98.8949, city: 'Early', state: 'TX' },
    76820: { lat: 30.79295, lng: -99.04508, city: 'Art', state: 'TX' },
    76821: {
      lat: 31.73862,
      lng: -99.94963,
      city: 'Ballinger',
      state: 'TX',
    },
    76823: {
      lat: 31.68718,
      lng: -99.16205,
      city: 'Bangs',
      state: 'TX',
    },
    76824: { lat: 30.9411, lng: -98.46339, city: 'Bend', state: 'TX' },
    76825: {
      lat: 31.09493,
      lng: -99.39329,
      city: 'Brady',
      state: 'TX',
    },
    76827: {
      lat: 31.52589,
      lng: -99.13031,
      city: 'Brookesmith',
      state: 'TX',
    },
    76828: {
      lat: 32.00728,
      lng: -99.28435,
      city: 'Burkett',
      state: 'TX',
    },
    76831: {
      lat: 30.74599,
      lng: -98.95543,
      city: 'Castell',
      state: 'TX',
    },
    76832: {
      lat: 30.97485,
      lng: -98.74384,
      city: 'Cherokee',
      state: 'TX',
    },
    76834: {
      lat: 31.87885,
      lng: -99.46843,
      city: 'Coleman',
      state: 'TX',
    },
    76836: {
      lat: 31.42409,
      lng: -99.59196,
      city: 'Doole',
      state: 'TX',
    },
    76837: { lat: 31.21098, lng: -99.88176, city: 'Eden', state: 'TX' },
    76841: {
      lat: 30.86695,
      lng: -100.14226,
      city: 'Fort McKavett',
      state: 'TX',
    },
    76842: {
      lat: 30.91888,
      lng: -99.1023,
      city: 'Fredonia',
      state: 'TX',
    },
    76844: {
      lat: 31.44387,
      lng: -98.49348,
      city: 'Goldthwaite',
      state: 'TX',
    },
    76845: {
      lat: 31.54305,
      lng: -99.48192,
      city: 'Gouldbusk',
      state: 'TX',
    },
    76848: { lat: 30.83928, lng: -99.5637, city: 'Hext', state: 'TX' },
    76849: {
      lat: 30.45849,
      lng: -99.83805,
      city: 'Junction',
      state: 'TX',
    },
    76852: { lat: 31.35558, lng: -99.44335, city: 'Lohn', state: 'TX' },
    76853: {
      lat: 31.24774,
      lng: -98.39047,
      city: 'Lometa',
      state: 'TX',
    },
    76854: {
      lat: 30.57125,
      lng: -99.53945,
      city: 'London',
      state: 'TX',
    },
    76856: {
      lat: 30.70296,
      lng: -99.26668,
      city: 'Mason',
      state: 'TX',
    },
    76857: { lat: 31.93361, lng: -98.93313, city: 'May', state: 'TX' },
    76858: {
      lat: 31.20758,
      lng: -99.62002,
      city: 'Melvin',
      state: 'TX',
    },
    76859: {
      lat: 30.87106,
      lng: -99.83123,
      city: 'Menard',
      state: 'TX',
    },
    76861: {
      lat: 31.59216,
      lng: -100.19637,
      city: 'Miles',
      state: 'TX',
    },
    76862: {
      lat: 31.4138,
      lng: -99.70944,
      city: 'Millersview',
      state: 'TX',
    },
    76864: {
      lat: 31.54561,
      lng: -98.73637,
      city: 'Mullin',
      state: 'TX',
    },
    76865: {
      lat: 31.84944,
      lng: -100.13663,
      city: 'Norton',
      state: 'TX',
    },
    76866: {
      lat: 31.45181,
      lng: -99.92922,
      city: 'Paint Rock',
      state: 'TX',
    },
    76869: {
      lat: 30.90951,
      lng: -98.97464,
      city: 'Pontotoc',
      state: 'TX',
    },
    76870: {
      lat: 31.60386,
      lng: -98.53151,
      city: 'Priddy',
      state: 'TX',
    },
    76871: {
      lat: 31.27333,
      lng: -98.995,
      city: 'Richland Springs',
      state: 'TX',
    },
    76872: {
      lat: 31.3357,
      lng: -99.1603,
      city: 'Rochelle',
      state: 'TX',
    },
    76873: {
      lat: 31.50459,
      lng: -99.3696,
      city: 'Rockwood',
      state: 'TX',
    },
    76874: {
      lat: 30.47693,
      lng: -100.12271,
      city: 'Roosevelt',
      state: 'TX',
    },
    76875: {
      lat: 31.59928,
      lng: -100.00749,
      city: 'Rowena',
      state: 'TX',
    },
    76877: {
      lat: 31.15807,
      lng: -98.75361,
      city: 'San Saba',
      state: 'TX',
    },
    76878: {
      lat: 31.66659,
      lng: -99.29611,
      city: 'Santa Anna',
      state: 'TX',
    },
    76880: { lat: 31.44491, lng: -98.3151, city: 'Star', state: 'TX' },
    76882: {
      lat: 31.80059,
      lng: -99.71448,
      city: 'Talpa',
      state: 'TX',
    },
    76884: {
      lat: 31.69654,
      lng: -99.56407,
      city: 'Valera',
      state: 'TX',
    },
    76885: {
      lat: 30.87304,
      lng: -98.85742,
      city: 'Valley Spring',
      state: 'TX',
    },
    76887: { lat: 31.02696, lng: -99.15806, city: 'Voca', state: 'TX' },
    76888: { lat: 31.60919, lng: -99.63742, city: 'Voss', state: 'TX' },
    76890: {
      lat: 31.69229,
      lng: -98.7649,
      city: 'Zephyr',
      state: 'TX',
    },
    76901: {
      lat: 31.52352,
      lng: -100.63993,
      city: 'San Angelo',
      state: 'TX',
    },
    76902: {
      lat: 31.54439,
      lng: -100.72459,
      city: 'San Angelo',
      state: 'TX',
    },
    76903: {
      lat: 31.48168,
      lng: -100.43787,
      city: 'San Angelo',
      state: 'TX',
    },
    76904: {
      lat: 31.28844,
      lng: -100.41695,
      city: 'San Angelo',
      state: 'TX',
    },
    76905: {
      lat: 31.49987,
      lng: -100.31583,
      city: 'San Angelo',
      state: 'TX',
    },
    76908: {
      lat: 31.43226,
      lng: -100.40314,
      city: 'Goodfellow Afb',
      state: 'TX',
    },
    76909: {
      lat: 31.44135,
      lng: -100.46624,
      city: 'San Angelo',
      state: 'TX',
    },
    76930: {
      lat: 31.11075,
      lng: -101.11975,
      city: 'Barnhart',
      state: 'TX',
    },
    76932: {
      lat: 31.19124,
      lng: -101.54984,
      city: 'Big Lake',
      state: 'TX',
    },
    76933: {
      lat: 31.87618,
      lng: -100.28544,
      city: 'Bronte',
      state: 'TX',
    },
    76934: {
      lat: 31.61491,
      lng: -100.67661,
      city: 'Carlsbad',
      state: 'TX',
    },
    76935: {
      lat: 31.07744,
      lng: -100.42261,
      city: 'Christoval',
      state: 'TX',
    },
    76936: {
      lat: 30.88811,
      lng: -100.64723,
      city: 'Eldorado',
      state: 'TX',
    },
    76937: {
      lat: 31.39109,
      lng: -100.15025,
      city: 'Eola',
      state: 'TX',
    },
    76939: {
      lat: 31.26855,
      lng: -100.59028,
      city: 'Knickerbocker',
      state: 'TX',
    },
    76940: {
      lat: 31.43681,
      lng: -100.12694,
      city: 'Mereta',
      state: 'TX',
    },
    76941: {
      lat: 31.24731,
      lng: -100.89845,
      city: 'Mertzon',
      state: 'TX',
    },
    76943: {
      lat: 30.48057,
      lng: -101.32623,
      city: 'Ozona',
      state: 'TX',
    },
    76945: {
      lat: 31.87297,
      lng: -100.60292,
      city: 'Robert Lee',
      state: 'TX',
    },
    76950: {
      lat: 30.43439,
      lng: -100.56564,
      city: 'Sonora',
      state: 'TX',
    },
    76951: {
      lat: 31.81255,
      lng: -101.04709,
      city: 'Sterling City',
      state: 'TX',
    },
    76953: {
      lat: 31.72871,
      lng: -100.32078,
      city: 'Tennyson',
      state: 'TX',
    },
    76955: {
      lat: 31.29919,
      lng: -100.1389,
      city: 'Vancourt',
      state: 'TX',
    },
    76957: {
      lat: 31.37232,
      lng: -100.30514,
      city: 'Wall',
      state: 'TX',
    },
    76958: {
      lat: 31.64467,
      lng: -100.75515,
      city: 'Water Valley',
      state: 'TX',
    },
    77002: {
      lat: 29.75635,
      lng: -95.36538,
      city: 'Houston',
      state: 'TX',
    },
    77003: {
      lat: 29.74961,
      lng: -95.34521,
      city: 'Houston',
      state: 'TX',
    },
    77004: {
      lat: 29.72477,
      lng: -95.36498,
      city: 'Houston',
      state: 'TX',
    },
    77005: {
      lat: 29.71824,
      lng: -95.42413,
      city: 'Houston',
      state: 'TX',
    },
    77006: {
      lat: 29.74086,
      lng: -95.39126,
      city: 'Houston',
      state: 'TX',
    },
    77007: {
      lat: 29.77152,
      lng: -95.40919,
      city: 'Houston',
      state: 'TX',
    },
    77008: {
      lat: 29.79856,
      lng: -95.41766,
      city: 'Houston',
      state: 'TX',
    },
    77009: {
      lat: 29.79499,
      lng: -95.36758,
      city: 'Houston',
      state: 'TX',
    },
    77010: {
      lat: 29.75368,
      lng: -95.35987,
      city: 'Houston',
      state: 'TX',
    },
    77011: {
      lat: 29.743,
      lng: -95.30785,
      city: 'Houston',
      state: 'TX',
    },
    77012: {
      lat: 29.71904,
      lng: -95.27449,
      city: 'Houston',
      state: 'TX',
    },
    77013: {
      lat: 29.79516,
      lng: -95.23895,
      city: 'Houston',
      state: 'TX',
    },
    77014: {
      lat: 29.98025,
      lng: -95.46363,
      city: 'Houston',
      state: 'TX',
    },
    77015: {
      lat: 29.76485,
      lng: -95.17216,
      city: 'Houston',
      state: 'TX',
    },
    77016: {
      lat: 29.86147,
      lng: -95.29951,
      city: 'Houston',
      state: 'TX',
    },
    77017: {
      lat: 29.69153,
      lng: -95.25093,
      city: 'Houston',
      state: 'TX',
    },
    77018: {
      lat: 29.82703,
      lng: -95.42571,
      city: 'Houston',
      state: 'TX',
    },
    77019: {
      lat: 29.75318,
      lng: -95.41212,
      city: 'Houston',
      state: 'TX',
    },
    77020: {
      lat: 29.76842,
      lng: -95.30878,
      city: 'Houston',
      state: 'TX',
    },
    77021: {
      lat: 29.69713,
      lng: -95.35751,
      city: 'Houston',
      state: 'TX',
    },
    77022: {
      lat: 29.8306,
      lng: -95.3768,
      city: 'Houston',
      state: 'TX',
    },
    77023: {
      lat: 29.7216,
      lng: -95.31827,
      city: 'Houston',
      state: 'TX',
    },
    77024: {
      lat: 29.77073,
      lng: -95.50869,
      city: 'Houston',
      state: 'TX',
    },
    77025: {
      lat: 29.68584,
      lng: -95.43512,
      city: 'Houston',
      state: 'TX',
    },
    77026: {
      lat: 29.80013,
      lng: -95.32934,
      city: 'Houston',
      state: 'TX',
    },
    77027: {
      lat: 29.73919,
      lng: -95.44609,
      city: 'Houston',
      state: 'TX',
    },
    77028: {
      lat: 29.82471,
      lng: -95.28668,
      city: 'Houston',
      state: 'TX',
    },
    77029: {
      lat: 29.76382,
      lng: -95.26326,
      city: 'Houston',
      state: 'TX',
    },
    77030: {
      lat: 29.70597,
      lng: -95.40253,
      city: 'Houston',
      state: 'TX',
    },
    77031: {
      lat: 29.65434,
      lng: -95.54622,
      city: 'Houston',
      state: 'TX',
    },
    77032: {
      lat: 29.96523,
      lng: -95.34004,
      city: 'Houston',
      state: 'TX',
    },
    77033: {
      lat: 29.66801,
      lng: -95.33801,
      city: 'Houston',
      state: 'TX',
    },
    77034: {
      lat: 29.61522,
      lng: -95.19237,
      city: 'Houston',
      state: 'TX',
    },
    77035: {
      lat: 29.65269,
      lng: -95.47688,
      city: 'Houston',
      state: 'TX',
    },
    77036: {
      lat: 29.70119,
      lng: -95.53659,
      city: 'Houston',
      state: 'TX',
    },
    77037: {
      lat: 29.8918,
      lng: -95.39438,
      city: 'Houston',
      state: 'TX',
    },
    77038: {
      lat: 29.91906,
      lng: -95.44121,
      city: 'Houston',
      state: 'TX',
    },
    77039: {
      lat: 29.90923,
      lng: -95.34137,
      city: 'Houston',
      state: 'TX',
    },
    77040: {
      lat: 29.87539,
      lng: -95.52861,
      city: 'Houston',
      state: 'TX',
    },
    77041: {
      lat: 29.86203,
      lng: -95.58187,
      city: 'Houston',
      state: 'TX',
    },
    77042: {
      lat: 29.74125,
      lng: -95.56015,
      city: 'Houston',
      state: 'TX',
    },
    77043: {
      lat: 29.8112,
      lng: -95.57981,
      city: 'Houston',
      state: 'TX',
    },
    77044: {
      lat: 29.89808,
      lng: -95.17685,
      city: 'Houston',
      state: 'TX',
    },
    77045: {
      lat: 29.63634,
      lng: -95.43279,
      city: 'Houston',
      state: 'TX',
    },
    77046: {
      lat: 29.73438,
      lng: -95.43301,
      city: 'Houston',
      state: 'TX',
    },
    77047: {
      lat: 29.60843,
      lng: -95.38662,
      city: 'Houston',
      state: 'TX',
    },
    77048: {
      lat: 29.62154,
      lng: -95.32986,
      city: 'Houston',
      state: 'TX',
    },
    77049: {
      lat: 29.83417,
      lng: -95.14827,
      city: 'Houston',
      state: 'TX',
    },
    77050: {
      lat: 29.90286,
      lng: -95.27013,
      city: 'Houston',
      state: 'TX',
    },
    77051: {
      lat: 29.65586,
      lng: -95.38023,
      city: 'Houston',
      state: 'TX',
    },
    77053: {
      lat: 29.58549,
      lng: -95.45991,
      city: 'Houston',
      state: 'TX',
    },
    77054: {
      lat: 29.68102,
      lng: -95.40424,
      city: 'Houston',
      state: 'TX',
    },
    77055: {
      lat: 29.79757,
      lng: -95.4928,
      city: 'Houston',
      state: 'TX',
    },
    77056: {
      lat: 29.74849,
      lng: -95.46806,
      city: 'Houston',
      state: 'TX',
    },
    77057: {
      lat: 29.74522,
      lng: -95.48885,
      city: 'Houston',
      state: 'TX',
    },
    77058: {
      lat: 29.56297,
      lng: -95.09303,
      city: 'Houston',
      state: 'TX',
    },
    77059: {
      lat: 29.60929,
      lng: -95.12417,
      city: 'Houston',
      state: 'TX',
    },
    77060: {
      lat: 29.93567,
      lng: -95.39697,
      city: 'Houston',
      state: 'TX',
    },
    77061: {
      lat: 29.65436,
      lng: -95.28481,
      city: 'Houston',
      state: 'TX',
    },
    77062: {
      lat: 29.57584,
      lng: -95.13406,
      city: 'Houston',
      state: 'TX',
    },
    77063: {
      lat: 29.7356,
      lng: -95.52157,
      city: 'Houston',
      state: 'TX',
    },
    77064: {
      lat: 29.92279,
      lng: -95.5467,
      city: 'Houston',
      state: 'TX',
    },
    77065: {
      lat: 29.92673,
      lng: -95.60324,
      city: 'Houston',
      state: 'TX',
    },
    77066: {
      lat: 29.95714,
      lng: -95.50277,
      city: 'Houston',
      state: 'TX',
    },
    77067: {
      lat: 29.95283,
      lng: -95.44719,
      city: 'Houston',
      state: 'TX',
    },
    77068: {
      lat: 30.00628,
      lng: -95.48694,
      city: 'Houston',
      state: 'TX',
    },
    77069: {
      lat: 29.98631,
      lng: -95.52398,
      city: 'Houston',
      state: 'TX',
    },
    77070: {
      lat: 29.97783,
      lng: -95.57377,
      city: 'Houston',
      state: 'TX',
    },
    77071: {
      lat: 29.65265,
      lng: -95.52046,
      city: 'Houston',
      state: 'TX',
    },
    77072: {
      lat: 29.70006,
      lng: -95.58539,
      city: 'Houston',
      state: 'TX',
    },
    77073: {
      lat: 29.99849,
      lng: -95.39804,
      city: 'Houston',
      state: 'TX',
    },
    77074: {
      lat: 29.68821,
      lng: -95.51633,
      city: 'Houston',
      state: 'TX',
    },
    77075: {
      lat: 29.62119,
      lng: -95.26749,
      city: 'Houston',
      state: 'TX',
    },
    77076: {
      lat: 29.86002,
      lng: -95.38328,
      city: 'Houston',
      state: 'TX',
    },
    77077: {
      lat: 29.75375,
      lng: -95.64189,
      city: 'Houston',
      state: 'TX',
    },
    77078: {
      lat: 29.8529,
      lng: -95.25089,
      city: 'Houston',
      state: 'TX',
    },
    77079: {
      lat: 29.77632,
      lng: -95.6037,
      city: 'Houston',
      state: 'TX',
    },
    77080: {
      lat: 29.81662,
      lng: -95.52375,
      city: 'Houston',
      state: 'TX',
    },
    77081: {
      lat: 29.71219,
      lng: -95.48026,
      city: 'Houston',
      state: 'TX',
    },
    77082: {
      lat: 29.72383,
      lng: -95.6414,
      city: 'Houston',
      state: 'TX',
    },
    77083: {
      lat: 29.69316,
      lng: -95.64858,
      city: 'Houston',
      state: 'TX',
    },
    77084: {
      lat: 29.82698,
      lng: -95.6612,
      city: 'Houston',
      state: 'TX',
    },
    77085: {
      lat: 29.62465,
      lng: -95.48411,
      city: 'Houston',
      state: 'TX',
    },
    77086: {
      lat: 29.9186,
      lng: -95.49064,
      city: 'Houston',
      state: 'TX',
    },
    77087: {
      lat: 29.68631,
      lng: -95.3034,
      city: 'Houston',
      state: 'TX',
    },
    77088: {
      lat: 29.88148,
      lng: -95.45316,
      city: 'Houston',
      state: 'TX',
    },
    77089: {
      lat: 29.58698,
      lng: -95.2257,
      city: 'Houston',
      state: 'TX',
    },
    77090: {
      lat: 30.00845,
      lng: -95.44317,
      city: 'Houston',
      state: 'TX',
    },
    77091: {
      lat: 29.85382,
      lng: -95.43969,
      city: 'Houston',
      state: 'TX',
    },
    77092: {
      lat: 29.83153,
      lng: -95.47521,
      city: 'Houston',
      state: 'TX',
    },
    77093: {
      lat: 29.8623,
      lng: -95.34097,
      city: 'Houston',
      state: 'TX',
    },
    77094: {
      lat: 29.7738,
      lng: -95.69955,
      city: 'Houston',
      state: 'TX',
    },
    77095: {
      lat: 29.90834,
      lng: -95.65492,
      city: 'Houston',
      state: 'TX',
    },
    77096: {
      lat: 29.67464,
      lng: -95.47965,
      city: 'Houston',
      state: 'TX',
    },
    77098: {
      lat: 29.73482,
      lng: -95.41526,
      city: 'Houston',
      state: 'TX',
    },
    77099: {
      lat: 29.67067,
      lng: -95.58485,
      city: 'Houston',
      state: 'TX',
    },
    77204: {
      lat: 29.72168,
      lng: -95.34356,
      city: 'Houston',
      state: 'TX',
    },
    77301: {
      lat: 30.30897,
      lng: -95.43281,
      city: 'Conroe',
      state: 'TX',
    },
    77302: {
      lat: 30.22626,
      lng: -95.36014,
      city: 'Conroe',
      state: 'TX',
    },
    77303: {
      lat: 30.37569,
      lng: -95.37937,
      city: 'Conroe',
      state: 'TX',
    },
    77304: {
      lat: 30.33097,
      lng: -95.51538,
      city: 'Conroe',
      state: 'TX',
    },
    77306: {
      lat: 30.2799,
      lng: -95.31337,
      city: 'Conroe',
      state: 'TX',
    },
    77316: {
      lat: 30.30974,
      lng: -95.68528,
      city: 'Montgomery',
      state: 'TX',
    },
    77318: {
      lat: 30.44709,
      lng: -95.54364,
      city: 'Willis',
      state: 'TX',
    },
    77320: {
      lat: 30.80672,
      lng: -95.55687,
      city: 'Huntsville',
      state: 'TX',
    },
    77326: { lat: 30.50445, lng: -94.81661, city: 'Ace', state: 'TX' },
    77327: {
      lat: 30.3262,
      lng: -94.91826,
      city: 'Cleveland',
      state: 'TX',
    },
    77328: {
      lat: 30.40767,
      lng: -95.18888,
      city: 'Cleveland',
      state: 'TX',
    },
    77331: {
      lat: 30.61928,
      lng: -95.17706,
      city: 'Coldspring',
      state: 'TX',
    },
    77334: {
      lat: 30.77996,
      lng: -95.36465,
      city: 'Dodge',
      state: 'TX',
    },
    77335: {
      lat: 30.58901,
      lng: -94.92353,
      city: 'Goodrich',
      state: 'TX',
    },
    77336: {
      lat: 30.06842,
      lng: -95.10306,
      city: 'Huffman',
      state: 'TX',
    },
    77338: {
      lat: 30.00858,
      lng: -95.28351,
      city: 'Humble',
      state: 'TX',
    },
    77339: {
      lat: 30.04788,
      lng: -95.22139,
      city: 'Kingwood',
      state: 'TX',
    },
    77340: {
      lat: 30.64601,
      lng: -95.53441,
      city: 'Huntsville',
      state: 'TX',
    },
    77341: {
      lat: 30.69818,
      lng: -95.55886,
      city: 'Huntsville',
      state: 'TX',
    },
    77342: {
      lat: 30.73877,
      lng: -95.55911,
      city: 'Huntsville',
      state: 'TX',
    },
    77345: {
      lat: 30.05392,
      lng: -95.15884,
      city: 'Kingwood',
      state: 'TX',
    },
    77346: {
      lat: 29.99027,
      lng: -95.17777,
      city: 'Humble',
      state: 'TX',
    },
    77350: {
      lat: 30.81432,
      lng: -94.90086,
      city: 'Leggett',
      state: 'TX',
    },
    77351: {
      lat: 30.70411,
      lng: -94.81682,
      city: 'Livingston',
      state: 'TX',
    },
    77354: {
      lat: 30.21113,
      lng: -95.65003,
      city: 'Magnolia',
      state: 'TX',
    },
    77355: {
      lat: 30.1579,
      lng: -95.75014,
      city: 'Magnolia',
      state: 'TX',
    },
    77356: {
      lat: 30.44807,
      lng: -95.70358,
      city: 'Montgomery',
      state: 'TX',
    },
    77357: {
      lat: 30.15694,
      lng: -95.18173,
      city: 'New Caney',
      state: 'TX',
    },
    77358: {
      lat: 30.55061,
      lng: -95.45419,
      city: 'New Waverly',
      state: 'TX',
    },
    77359: {
      lat: 30.76262,
      lng: -95.30441,
      city: 'Oakhurst',
      state: 'TX',
    },
    77360: {
      lat: 30.8471,
      lng: -95.11394,
      city: 'Onalaska',
      state: 'TX',
    },
    77362: {
      lat: 30.16003,
      lng: -95.67237,
      city: 'Pinehurst',
      state: 'TX',
    },
    77363: {
      lat: 30.3267,
      lng: -95.85732,
      city: 'Plantersville',
      state: 'TX',
    },
    77364: {
      lat: 30.76592,
      lng: -95.22168,
      city: 'Pointblank',
      state: 'TX',
    },
    77365: {
      lat: 30.11638,
      lng: -95.2747,
      city: 'Porter',
      state: 'TX',
    },
    77367: {
      lat: 30.8504,
      lng: -95.39147,
      city: 'Riverside',
      state: 'TX',
    },
    77368: {
      lat: 30.45114,
      lng: -94.8407,
      city: 'Romayor',
      state: 'TX',
    },
    77369: { lat: 30.44296, lng: -94.75084, city: 'Rye', state: 'TX' },
    77371: {
      lat: 30.49376,
      lng: -94.98146,
      city: 'Shepherd',
      state: 'TX',
    },
    77372: {
      lat: 30.24472,
      lng: -95.18281,
      city: 'Splendora',
      state: 'TX',
    },
    77373: {
      lat: 30.06143,
      lng: -95.38423,
      city: 'Spring',
      state: 'TX',
    },
    77374: {
      lat: 30.38226,
      lng: -94.63069,
      city: 'Thicket',
      state: 'TX',
    },
    77375: {
      lat: 30.09393,
      lng: -95.59095,
      city: 'Tomball',
      state: 'TX',
    },
    77376: { lat: 30.44422, lng: -94.6779, city: 'Votaw', state: 'TX' },
    77377: {
      lat: 30.06141,
      lng: -95.67608,
      city: 'Tomball',
      state: 'TX',
    },
    77378: {
      lat: 30.45873,
      lng: -95.37454,
      city: 'Willis',
      state: 'TX',
    },
    77379: {
      lat: 30.03966,
      lng: -95.53305,
      city: 'Spring',
      state: 'TX',
    },
    77380: {
      lat: 30.1365,
      lng: -95.46863,
      city: 'Spring',
      state: 'TX',
    },
    77381: {
      lat: 30.17791,
      lng: -95.50266,
      city: 'Spring',
      state: 'TX',
    },
    77382: {
      lat: 30.19961,
      lng: -95.5455,
      city: 'Spring',
      state: 'TX',
    },
    77384: {
      lat: 30.23453,
      lng: -95.49675,
      city: 'Conroe',
      state: 'TX',
    },
    77385: {
      lat: 30.1894,
      lng: -95.42264,
      city: 'Conroe',
      state: 'TX',
    },
    77386: {
      lat: 30.10153,
      lng: -95.3561,
      city: 'Spring',
      state: 'TX',
    },
    77388: {
      lat: 30.05957,
      lng: -95.46764,
      city: 'Spring',
      state: 'TX',
    },
    77389: {
      lat: 30.11509,
      lng: -95.50752,
      city: 'Spring',
      state: 'TX',
    },
    77396: {
      lat: 29.94778,
      lng: -95.26129,
      city: 'Humble',
      state: 'TX',
    },
    77401: {
      lat: 29.70398,
      lng: -95.46192,
      city: 'Bellaire',
      state: 'TX',
    },
    77406: {
      lat: 29.64571,
      lng: -95.79184,
      city: 'Richmond',
      state: 'TX',
    },
    77407: {
      lat: 29.67658,
      lng: -95.71455,
      city: 'Richmond',
      state: 'TX',
    },
    77412: {
      lat: 29.56693,
      lng: -96.46031,
      city: 'Altair',
      state: 'TX',
    },
    77414: {
      lat: 28.90601,
      lng: -95.8935,
      city: 'Bay City',
      state: 'TX',
    },
    77415: {
      lat: 28.93425,
      lng: -95.72035,
      city: 'Cedar Lane',
      state: 'TX',
    },
    77417: {
      lat: 29.46633,
      lng: -95.9621,
      city: 'Beasley',
      state: 'TX',
    },
    77418: {
      lat: 29.97922,
      lng: -96.25063,
      city: 'Bellville',
      state: 'TX',
    },
    77419: {
      lat: 28.8692,
      lng: -96.25603,
      city: 'Blessing',
      state: 'TX',
    },
    77420: {
      lat: 29.23404,
      lng: -95.91908,
      city: 'Boling',
      state: 'TX',
    },
    77422: {
      lat: 28.96492,
      lng: -95.57268,
      city: 'Brazoria',
      state: 'TX',
    },
    77423: {
      lat: 29.83386,
      lng: -95.98219,
      city: 'Brookshire',
      state: 'TX',
    },
    77426: {
      lat: 30.11633,
      lng: -96.24721,
      city: 'Chappell Hill',
      state: 'TX',
    },
    77428: {
      lat: 28.67447,
      lng: -96.15682,
      city: 'Collegeport',
      state: 'TX',
    },
    77429: {
      lat: 29.99068,
      lng: -95.65808,
      city: 'Cypress',
      state: 'TX',
    },
    77430: {
      lat: 29.26487,
      lng: -95.65616,
      city: 'Damon',
      state: 'TX',
    },
    77431: {
      lat: 29.13239,
      lng: -95.82309,
      city: 'Danciger',
      state: 'TX',
    },
    77432: {
      lat: 29.05808,
      lng: -96.21608,
      city: 'Danevang',
      state: 'TX',
    },
    77433: {
      lat: 29.9492,
      lng: -95.73979,
      city: 'Cypress',
      state: 'TX',
    },
    77434: {
      lat: 29.52577,
      lng: -96.31983,
      city: 'Eagle Lake',
      state: 'TX',
    },
    77435: {
      lat: 29.51111,
      lng: -96.12395,
      city: 'East Bernard',
      state: 'TX',
    },
    77436: {
      lat: 29.38874,
      lng: -96.23832,
      city: 'Egypt',
      state: 'TX',
    },
    77437: {
      lat: 29.20452,
      lng: -96.27328,
      city: 'El Campo',
      state: 'TX',
    },
    77440: {
      lat: 28.88819,
      lng: -96.14603,
      city: 'Elmaton',
      state: 'TX',
    },
    77441: {
      lat: 29.6955,
      lng: -95.90896,
      city: 'Fulshear',
      state: 'TX',
    },
    77442: {
      lat: 29.43708,
      lng: -96.49757,
      city: 'Garwood',
      state: 'TX',
    },
    77443: {
      lat: 29.35835,
      lng: -96.18255,
      city: 'Glen Flora',
      state: 'TX',
    },
    77444: { lat: 29.29641, lng: -95.77794, city: 'Guy', state: 'TX' },
    77445: {
      lat: 30.09562,
      lng: -96.06247,
      city: 'Hempstead',
      state: 'TX',
    },
    77446: {
      lat: 30.0871,
      lng: -95.9895,
      city: 'Prairie View',
      state: 'TX',
    },
    77447: {
      lat: 30.05178,
      lng: -95.82868,
      city: 'Hockley',
      state: 'TX',
    },
    77448: {
      lat: 29.4147,
      lng: -96.08746,
      city: 'Hungerford',
      state: 'TX',
    },
    77449: { lat: 29.83674, lng: -95.73547, city: 'Katy', state: 'TX' },
    77450: { lat: 29.74753, lng: -95.73696, city: 'Katy', state: 'TX' },
    77451: {
      lat: 29.46173,
      lng: -96.02688,
      city: 'Kendleton',
      state: 'TX',
    },
    77453: {
      lat: 29.23303,
      lng: -96.01802,
      city: 'Lane City',
      state: 'TX',
    },
    77454: {
      lat: 29.54304,
      lng: -96.23044,
      city: 'Lissie',
      state: 'TX',
    },
    77455: {
      lat: 29.14017,
      lng: -96.43782,
      city: 'Louise',
      state: 'TX',
    },
    77456: {
      lat: 28.9511,
      lng: -96.1136,
      city: 'Markham',
      state: 'TX',
    },
    77457: {
      lat: 28.70119,
      lng: -95.94076,
      city: 'Matagorda',
      state: 'TX',
    },
    77458: {
      lat: 28.95409,
      lng: -96.25489,
      city: 'Midfield',
      state: 'TX',
    },
    77459: {
      lat: 29.52691,
      lng: -95.53407,
      city: 'Missouri City',
      state: 'TX',
    },
    77460: { lat: 29.39744, lng: -96.38451, city: 'Nada', state: 'TX' },
    77461: {
      lat: 29.39159,
      lng: -95.77345,
      city: 'Needville',
      state: 'TX',
    },
    77464: {
      lat: 29.61807,
      lng: -95.94148,
      city: 'Orchard',
      state: 'TX',
    },
    77465: {
      lat: 28.71152,
      lng: -96.22545,
      city: 'Palacios',
      state: 'TX',
    },
    77466: {
      lat: 29.80003,
      lng: -96.01085,
      city: 'Pattison',
      state: 'TX',
    },
    77467: {
      lat: 29.23345,
      lng: -96.18563,
      city: 'Pierce',
      state: 'TX',
    },
    77468: {
      lat: 29.14681,
      lng: -95.85173,
      city: 'Pledger',
      state: 'TX',
    },
    77469: {
      lat: 29.49229,
      lng: -95.70571,
      city: 'Richmond',
      state: 'TX',
    },
    77470: {
      lat: 29.52775,
      lng: -96.55305,
      city: 'Rock Island',
      state: 'TX',
    },
    77471: {
      lat: 29.55159,
      lng: -95.86785,
      city: 'Rosenberg',
      state: 'TX',
    },
    77473: {
      lat: 29.79459,
      lng: -96.1038,
      city: 'San Felipe',
      state: 'TX',
    },
    77474: {
      lat: 29.77583,
      lng: -96.18518,
      city: 'Sealy',
      state: 'TX',
    },
    77475: {
      lat: 29.50419,
      lng: -96.66026,
      city: 'Sheridan',
      state: 'TX',
    },
    77476: {
      lat: 29.67551,
      lng: -95.97105,
      city: 'Simonton',
      state: 'TX',
    },
    77477: {
      lat: 29.62625,
      lng: -95.56718,
      city: 'Stafford',
      state: 'TX',
    },
    77478: {
      lat: 29.61896,
      lng: -95.6077,
      city: 'Sugar Land',
      state: 'TX',
    },
    77479: {
      lat: 29.56503,
      lng: -95.6346,
      city: 'Sugar Land',
      state: 'TX',
    },
    77480: {
      lat: 29.09388,
      lng: -95.76692,
      city: 'Sweeny',
      state: 'TX',
    },
    77481: {
      lat: 29.47053,
      lng: -95.56975,
      city: 'Thompsons',
      state: 'TX',
    },
    77482: {
      lat: 29.09351,
      lng: -95.88411,
      city: 'Van Vleck',
      state: 'TX',
    },
    77483: {
      lat: 28.77467,
      lng: -95.86638,
      city: 'Wadsworth',
      state: 'TX',
    },
    77484: {
      lat: 30.07924,
      lng: -95.92812,
      city: 'Waller',
      state: 'TX',
    },
    77485: {
      lat: 29.64118,
      lng: -96.06236,
      city: 'Wallis',
      state: 'TX',
    },
    77486: {
      lat: 29.15495,
      lng: -95.6805,
      city: 'West Columbia',
      state: 'TX',
    },
    77488: {
      lat: 29.29833,
      lng: -96.09821,
      city: 'Wharton',
      state: 'TX',
    },
    77489: {
      lat: 29.60068,
      lng: -95.51605,
      city: 'Missouri City',
      state: 'TX',
    },
    77493: { lat: 29.85193, lng: -95.83025, city: 'Katy', state: 'TX' },
    77494: { lat: 29.74566, lng: -95.82302, city: 'Katy', state: 'TX' },
    77498: {
      lat: 29.64224,
      lng: -95.65244,
      city: 'Sugar Land',
      state: 'TX',
    },
    77502: {
      lat: 29.67984,
      lng: -95.19953,
      city: 'Pasadena',
      state: 'TX',
    },
    77503: {
      lat: 29.69078,
      lng: -95.15781,
      city: 'Pasadena',
      state: 'TX',
    },
    77504: {
      lat: 29.64766,
      lng: -95.19007,
      city: 'Pasadena',
      state: 'TX',
    },
    77505: {
      lat: 29.64895,
      lng: -95.14284,
      city: 'Pasadena',
      state: 'TX',
    },
    77506: {
      lat: 29.71786,
      lng: -95.19018,
      city: 'Pasadena',
      state: 'TX',
    },
    77507: {
      lat: 29.62332,
      lng: -95.06582,
      city: 'Pasadena',
      state: 'TX',
    },
    77510: {
      lat: 29.34788,
      lng: -95.09089,
      city: 'Santa Fe',
      state: 'TX',
    },
    77511: {
      lat: 29.38638,
      lng: -95.23769,
      city: 'Alvin',
      state: 'TX',
    },
    77514: {
      lat: 29.69096,
      lng: -94.62598,
      city: 'Anahuac',
      state: 'TX',
    },
    77515: {
      lat: 29.17604,
      lng: -95.44955,
      city: 'Angleton',
      state: 'TX',
    },
    77517: {
      lat: 29.36253,
      lng: -95.1369,
      city: 'Santa Fe',
      state: 'TX',
    },
    77518: {
      lat: 29.50544,
      lng: -94.98664,
      city: 'Bacliff',
      state: 'TX',
    },
    77519: {
      lat: 30.21124,
      lng: -94.58596,
      city: 'Batson',
      state: 'TX',
    },
    77520: {
      lat: 29.74599,
      lng: -95.00388,
      city: 'Baytown',
      state: 'TX',
    },
    77521: {
      lat: 29.81298,
      lng: -94.96233,
      city: 'Baytown',
      state: 'TX',
    },
    77523: {
      lat: 29.77598,
      lng: -94.8632,
      city: 'Baytown',
      state: 'TX',
    },
    77530: {
      lat: 29.78948,
      lng: -95.11159,
      city: 'Channelview',
      state: 'TX',
    },
    77531: {
      lat: 29.03895,
      lng: -95.40071,
      city: 'Clute',
      state: 'TX',
    },
    77532: {
      lat: 29.93177,
      lng: -95.05602,
      city: 'Crosby',
      state: 'TX',
    },
    77533: {
      lat: 30.09139,
      lng: -94.59488,
      city: 'Daisetta',
      state: 'TX',
    },
    77534: {
      lat: 29.21335,
      lng: -95.30387,
      city: 'Danbury',
      state: 'TX',
    },
    77535: {
      lat: 30.05129,
      lng: -94.91569,
      city: 'Dayton',
      state: 'TX',
    },
    77536: {
      lat: 29.69021,
      lng: -95.11891,
      city: 'Deer Park',
      state: 'TX',
    },
    77538: {
      lat: 29.99217,
      lng: -94.53976,
      city: 'Devers',
      state: 'TX',
    },
    77539: {
      lat: 29.45578,
      lng: -95.02723,
      city: 'Dickinson',
      state: 'TX',
    },
    77541: {
      lat: 29.01496,
      lng: -95.32667,
      city: 'Freeport',
      state: 'TX',
    },
    77545: {
      lat: 29.53729,
      lng: -95.47242,
      city: 'Fresno',
      state: 'TX',
    },
    77546: {
      lat: 29.5132,
      lng: -95.19352,
      city: 'Friendswood',
      state: 'TX',
    },
    77547: {
      lat: 29.74018,
      lng: -95.23836,
      city: 'Galena Park',
      state: 'TX',
    },
    77550: {
      lat: 29.31021,
      lng: -94.77706,
      city: 'Galveston',
      state: 'TX',
    },
    77551: {
      lat: 29.27903,
      lng: -94.83502,
      city: 'Galveston',
      state: 'TX',
    },
    77554: {
      lat: 29.23187,
      lng: -94.92544,
      city: 'Galveston',
      state: 'TX',
    },
    77555: {
      lat: 29.31082,
      lng: -94.77641,
      city: 'Galveston',
      state: 'TX',
    },
    77560: {
      lat: 29.86399,
      lng: -94.59643,
      city: 'Hankamer',
      state: 'TX',
    },
    77561: {
      lat: 30.13459,
      lng: -94.73551,
      city: 'Hardin',
      state: 'TX',
    },
    77562: {
      lat: 29.82917,
      lng: -95.05473,
      city: 'Highlands',
      state: 'TX',
    },
    77563: {
      lat: 29.31123,
      lng: -95.01822,
      city: 'Hitchcock',
      state: 'TX',
    },
    77564: { lat: 30.21811, lng: -94.65989, city: 'Hull', state: 'TX' },
    77565: {
      lat: 29.53925,
      lng: -95.03229,
      city: 'Kemah',
      state: 'TX',
    },
    77566: {
      lat: 29.04971,
      lng: -95.47511,
      city: 'Lake Jackson',
      state: 'TX',
    },
    77568: {
      lat: 29.36653,
      lng: -94.98773,
      city: 'La Marque',
      state: 'TX',
    },
    77571: {
      lat: 29.69287,
      lng: -95.06012,
      city: 'La Porte',
      state: 'TX',
    },
    77573: {
      lat: 29.50213,
      lng: -95.08636,
      city: 'League City',
      state: 'TX',
    },
    77575: {
      lat: 30.07738,
      lng: -94.73666,
      city: 'Liberty',
      state: 'TX',
    },
    77577: {
      lat: 29.25966,
      lng: -95.26731,
      city: 'Liverpool',
      state: 'TX',
    },
    77578: {
      lat: 29.47794,
      lng: -95.36757,
      city: 'Manvel',
      state: 'TX',
    },
    77580: {
      lat: 29.85706,
      lng: -94.88081,
      city: 'Mont Belvieu',
      state: 'TX',
    },
    77581: {
      lat: 29.5624,
      lng: -95.27982,
      city: 'Pearland',
      state: 'TX',
    },
    77582: {
      lat: 30.01371,
      lng: -94.65497,
      city: 'Raywood',
      state: 'TX',
    },
    77583: {
      lat: 29.37763,
      lng: -95.4585,
      city: 'Rosharon',
      state: 'TX',
    },
    77584: {
      lat: 29.54577,
      lng: -95.35013,
      city: 'Pearland',
      state: 'TX',
    },
    77585: {
      lat: 30.29949,
      lng: -94.5294,
      city: 'Saratoga',
      state: 'TX',
    },
    77586: {
      lat: 29.58267,
      lng: -95.03391,
      city: 'Seabrook',
      state: 'TX',
    },
    77587: {
      lat: 29.66117,
      lng: -95.22865,
      city: 'South Houston',
      state: 'TX',
    },
    77590: {
      lat: 29.3804,
      lng: -94.91759,
      city: 'Texas City',
      state: 'TX',
    },
    77591: {
      lat: 29.39971,
      lng: -95.00074,
      city: 'Texas City',
      state: 'TX',
    },
    77597: {
      lat: 29.82811,
      lng: -94.69521,
      city: 'Wallisville',
      state: 'TX',
    },
    77598: {
      lat: 29.53766,
      lng: -95.13714,
      city: 'Webster',
      state: 'TX',
    },
    77611: {
      lat: 30.01259,
      lng: -93.8222,
      city: 'Bridge City',
      state: 'TX',
    },
    77612: { lat: 30.39851, lng: -93.93276, city: 'Buna', state: 'TX' },
    77613: {
      lat: 30.02493,
      lng: -94.35393,
      city: 'China',
      state: 'TX',
    },
    77614: {
      lat: 30.3055,
      lng: -93.76033,
      city: 'Deweyville',
      state: 'TX',
    },
    77615: {
      lat: 30.35713,
      lng: -94.06563,
      city: 'Evadale',
      state: 'TX',
    },
    77616: { lat: 30.56804, lng: -94.2117, city: 'Fred', state: 'TX' },
    77617: {
      lat: 29.51128,
      lng: -94.51052,
      city: 'Gilchrist',
      state: 'TX',
    },
    77619: {
      lat: 29.94549,
      lng: -93.9165,
      city: 'Groves',
      state: 'TX',
    },
    77622: {
      lat: 29.85741,
      lng: -94.29467,
      city: 'Hamshire',
      state: 'TX',
    },
    77623: {
      lat: 29.55957,
      lng: -94.41621,
      city: 'High Island',
      state: 'TX',
    },
    77624: {
      lat: 30.68674,
      lng: -94.2822,
      city: 'Hillister',
      state: 'TX',
    },
    77625: {
      lat: 30.37032,
      lng: -94.3923,
      city: 'Kountze',
      state: 'TX',
    },
    77627: {
      lat: 29.99095,
      lng: -94.00772,
      city: 'Nederland',
      state: 'TX',
    },
    77629: { lat: 29.97476, lng: -94.42727, city: 'Nome', state: 'TX' },
    77630: {
      lat: 30.06534,
      lng: -93.86844,
      city: 'Orange',
      state: 'TX',
    },
    77632: {
      lat: 30.2014,
      lng: -93.80477,
      city: 'Orange',
      state: 'TX',
    },
    77640: {
      lat: 29.88021,
      lng: -93.94152,
      city: 'Port Arthur',
      state: 'TX',
    },
    77642: {
      lat: 29.93917,
      lng: -93.91606,
      city: 'Port Arthur',
      state: 'TX',
    },
    77650: {
      lat: 29.42995,
      lng: -94.68096,
      city: 'Port Bolivar',
      state: 'TX',
    },
    77651: {
      lat: 29.98036,
      lng: -93.94821,
      city: 'Port Neches',
      state: 'TX',
    },
    77655: {
      lat: 29.71338,
      lng: -93.95306,
      city: 'Sabine Pass',
      state: 'TX',
    },
    77656: {
      lat: 30.42866,
      lng: -94.16887,
      city: 'Silsbee',
      state: 'TX',
    },
    77657: {
      lat: 30.22549,
      lng: -94.18862,
      city: 'Lumberton',
      state: 'TX',
    },
    77659: {
      lat: 30.15775,
      lng: -94.43279,
      city: 'Sour Lake',
      state: 'TX',
    },
    77660: {
      lat: 30.63993,
      lng: -94.14105,
      city: 'Spurger',
      state: 'TX',
    },
    77661: {
      lat: 29.78021,
      lng: -94.36861,
      city: 'Stowell',
      state: 'TX',
    },
    77662: { lat: 30.1793, lng: -94.01876, city: 'Vidor', state: 'TX' },
    77663: {
      lat: 30.51033,
      lng: -94.4052,
      city: 'Village Mills',
      state: 'TX',
    },
    77664: {
      lat: 30.58901,
      lng: -94.39529,
      city: 'Warren',
      state: 'TX',
    },
    77665: {
      lat: 29.78697,
      lng: -94.40969,
      city: 'Winnie',
      state: 'TX',
    },
    77701: {
      lat: 30.07321,
      lng: -94.10579,
      city: 'Beaumont',
      state: 'TX',
    },
    77702: {
      lat: 30.08624,
      lng: -94.12825,
      city: 'Beaumont',
      state: 'TX',
    },
    77703: {
      lat: 30.11522,
      lng: -94.10906,
      city: 'Beaumont',
      state: 'TX',
    },
    77705: {
      lat: 29.90652,
      lng: -94.16961,
      city: 'Beaumont',
      state: 'TX',
    },
    77706: {
      lat: 30.10049,
      lng: -94.16717,
      city: 'Beaumont',
      state: 'TX',
    },
    77707: {
      lat: 30.05323,
      lng: -94.1748,
      city: 'Beaumont',
      state: 'TX',
    },
    77708: {
      lat: 30.14747,
      lng: -94.15128,
      city: 'Beaumont',
      state: 'TX',
    },
    77713: {
      lat: 30.06777,
      lng: -94.25716,
      city: 'Beaumont',
      state: 'TX',
    },
    77801: {
      lat: 30.63968,
      lng: -96.36451,
      city: 'Bryan',
      state: 'TX',
    },
    77802: { lat: 30.6609, lng: -96.32233, city: 'Bryan', state: 'TX' },
    77803: { lat: 30.67835, lng: -96.3867, city: 'Bryan', state: 'TX' },
    77807: { lat: 30.6783, lng: -96.47982, city: 'Bryan', state: 'TX' },
    77808: {
      lat: 30.78965,
      lng: -96.31362,
      city: 'Bryan',
      state: 'TX',
    },
    77830: {
      lat: 30.52648,
      lng: -96.00849,
      city: 'Anderson',
      state: 'TX',
    },
    77831: {
      lat: 30.73144,
      lng: -95.90933,
      city: 'Bedias',
      state: 'TX',
    },
    77833: {
      lat: 30.20842,
      lng: -96.40797,
      city: 'Brenham',
      state: 'TX',
    },
    77835: {
      lat: 30.19439,
      lng: -96.59257,
      city: 'Burton',
      state: 'TX',
    },
    77836: {
      lat: 30.53798,
      lng: -96.67813,
      city: 'Caldwell',
      state: 'TX',
    },
    77837: {
      lat: 31.00704,
      lng: -96.67718,
      city: 'Calvert',
      state: 'TX',
    },
    77840: {
      lat: 30.6115,
      lng: -96.32332,
      city: 'College Station',
      state: 'TX',
    },
    77843: {
      lat: 30.61527,
      lng: -96.34027,
      city: 'College Station',
      state: 'TX',
    },
    77845: {
      lat: 30.56212,
      lng: -96.27607,
      city: 'College Station',
      state: 'TX',
    },
    77852: {
      lat: 30.42833,
      lng: -96.7573,
      city: 'Deanville',
      state: 'TX',
    },
    77853: {
      lat: 30.3623,
      lng: -96.84035,
      city: 'Dime Box',
      state: 'TX',
    },
    77855: {
      lat: 31.14741,
      lng: -96.11217,
      city: 'Flynn',
      state: 'TX',
    },
    77856: {
      lat: 31.07714,
      lng: -96.41499,
      city: 'Franklin',
      state: 'TX',
    },
    77857: {
      lat: 30.78246,
      lng: -96.73927,
      city: 'Gause',
      state: 'TX',
    },
    77859: {
      lat: 30.88012,
      lng: -96.51707,
      city: 'Hearne',
      state: 'TX',
    },
    77861: { lat: 30.72477, lng: -96.09908, city: 'Iola', state: 'TX' },
    77863: {
      lat: 30.39306,
      lng: -96.55946,
      city: 'Lyons',
      state: 'TX',
    },
    77864: {
      lat: 30.96087,
      lng: -95.89297,
      city: 'Madisonville',
      state: 'TX',
    },
    77865: {
      lat: 31.22275,
      lng: -96.22553,
      city: 'Marquez',
      state: 'TX',
    },
    77866: {
      lat: 30.49356,
      lng: -96.21034,
      city: 'Millican',
      state: 'TX',
    },
    77867: {
      lat: 30.74163,
      lng: -96.56992,
      city: 'Mumford',
      state: 'TX',
    },
    77868: {
      lat: 30.36954,
      lng: -96.05815,
      city: 'Navasota',
      state: 'TX',
    },
    77871: {
      lat: 31.05129,
      lng: -96.10903,
      city: 'Normangee',
      state: 'TX',
    },
    77872: {
      lat: 30.88539,
      lng: -96.0971,
      city: 'North Zulch',
      state: 'TX',
    },
    77873: {
      lat: 30.57489,
      lng: -95.79934,
      city: 'Richards',
      state: 'TX',
    },
    77875: {
      lat: 30.58119,
      lng: -95.94675,
      city: 'Roans Prairie',
      state: 'TX',
    },
    77876: {
      lat: 30.60369,
      lng: -95.85656,
      city: 'Shiro',
      state: 'TX',
    },
    77878: {
      lat: 30.47222,
      lng: -96.45089,
      city: 'Snook',
      state: 'TX',
    },
    77879: {
      lat: 30.40078,
      lng: -96.49378,
      city: 'Somerville',
      state: 'TX',
    },
    77880: {
      lat: 30.28283,
      lng: -96.1863,
      city: 'Washington',
      state: 'TX',
    },
    77882: {
      lat: 30.91139,
      lng: -96.37909,
      city: 'Wheelock',
      state: 'TX',
    },
    77901: {
      lat: 28.80629,
      lng: -96.98553,
      city: 'Victoria',
      state: 'TX',
    },
    77904: {
      lat: 28.91788,
      lng: -97.0311,
      city: 'Victoria',
      state: 'TX',
    },
    77905: {
      lat: 28.72789,
      lng: -97.0508,
      city: 'Victoria',
      state: 'TX',
    },
    77950: {
      lat: 28.26427,
      lng: -96.89666,
      city: 'Austwell',
      state: 'TX',
    },
    77951: {
      lat: 28.63476,
      lng: -96.91183,
      city: 'Bloomington',
      state: 'TX',
    },
    77954: { lat: 29.0966, lng: -97.29533, city: 'Cuero', state: 'TX' },
    77957: { lat: 29.03022, lng: -96.70606, city: 'Edna', state: 'TX' },
    77960: {
      lat: 28.65306,
      lng: -97.2461,
      city: 'Fannin',
      state: 'TX',
    },
    77961: {
      lat: 28.85684,
      lng: -96.35947,
      city: 'Francitas',
      state: 'TX',
    },
    77962: {
      lat: 29.03253,
      lng: -96.5023,
      city: 'Ganado',
      state: 'TX',
    },
    77963: {
      lat: 28.65589,
      lng: -97.40093,
      city: 'Goliad',
      state: 'TX',
    },
    77964: {
      lat: 29.38023,
      lng: -96.84301,
      city: 'Hallettsville',
      state: 'TX',
    },
    77968: { lat: 28.90619, lng: -96.84952, city: 'Inez', state: 'TX' },
    77969: {
      lat: 28.77579,
      lng: -96.64719,
      city: 'La Salle',
      state: 'TX',
    },
    77970: {
      lat: 28.84533,
      lng: -96.43153,
      city: 'La Ward',
      state: 'TX',
    },
    77971: {
      lat: 28.83063,
      lng: -96.52176,
      city: 'Lolita',
      state: 'TX',
    },
    77973: {
      lat: 28.54094,
      lng: -96.95512,
      city: 'Mcfaddin',
      state: 'TX',
    },
    77974: {
      lat: 28.89295,
      lng: -97.29275,
      city: 'Meyersville',
      state: 'TX',
    },
    77975: {
      lat: 29.56843,
      lng: -97.08818,
      city: 'Moulton',
      state: 'TX',
    },
    77976: {
      lat: 28.94057,
      lng: -97.1342,
      city: 'Nursery',
      state: 'TX',
    },
    77977: {
      lat: 28.68082,
      lng: -96.80183,
      city: 'Placedo',
      state: 'TX',
    },
    77978: {
      lat: 28.69152,
      lng: -96.5991,
      city: 'Point Comfort',
      state: 'TX',
    },
    77979: {
      lat: 28.60248,
      lng: -96.6017,
      city: 'Port Lavaca',
      state: 'TX',
    },
    77982: {
      lat: 28.44903,
      lng: -96.48657,
      city: "Port O'Connor",
      state: 'TX',
    },
    77983: {
      lat: 28.39874,
      lng: -96.64049,
      city: 'Seadrift',
      state: 'TX',
    },
    77984: {
      lat: 29.45373,
      lng: -97.18849,
      city: 'Shiner',
      state: 'TX',
    },
    77986: {
      lat: 29.47661,
      lng: -96.79607,
      city: 'Sublime',
      state: 'TX',
    },
    77987: {
      lat: 29.34569,
      lng: -97.08019,
      city: 'Sweet Home',
      state: 'TX',
    },
    77988: {
      lat: 28.84701,
      lng: -96.87547,
      city: 'Telferner',
      state: 'TX',
    },
    77990: {
      lat: 28.48098,
      lng: -96.93438,
      city: 'Tivoli',
      state: 'TX',
    },
    77991: {
      lat: 28.7866,
      lng: -96.59467,
      city: 'Vanderbilt',
      state: 'TX',
    },
    77993: {
      lat: 28.85158,
      lng: -97.433,
      city: 'Weesatche',
      state: 'TX',
    },
    77994: {
      lat: 29.17977,
      lng: -97.46563,
      city: 'Westhoff',
      state: 'TX',
    },
    77995: {
      lat: 29.20384,
      lng: -97.08814,
      city: 'Yoakum',
      state: 'TX',
    },
    78001: {
      lat: 28.25327,
      lng: -99.22731,
      city: 'Artesia Wells',
      state: 'TX',
    },
    78002: {
      lat: 29.28305,
      lng: -98.73652,
      city: 'Atascosa',
      state: 'TX',
    },
    78003: {
      lat: 29.71092,
      lng: -99.09823,
      city: 'Bandera',
      state: 'TX',
    },
    78004: {
      lat: 29.85767,
      lng: -98.53773,
      city: 'Bergheim',
      state: 'TX',
    },
    78005: {
      lat: 28.92848,
      lng: -98.84668,
      city: 'Bigfoot',
      state: 'TX',
    },
    78006: {
      lat: 29.86869,
      lng: -98.71368,
      city: 'Boerne',
      state: 'TX',
    },
    78007: {
      lat: 28.48212,
      lng: -98.40373,
      city: 'Calliham',
      state: 'TX',
    },
    78008: {
      lat: 28.72478,
      lng: -98.25911,
      city: 'Campbellton',
      state: 'TX',
    },
    78009: {
      lat: 29.36251,
      lng: -98.88442,
      city: 'Castroville',
      state: 'TX',
    },
    78010: {
      lat: 29.91122,
      lng: -99.04698,
      city: 'Center Point',
      state: 'TX',
    },
    78011: {
      lat: 28.80267,
      lng: -98.73441,
      city: 'Charlotte',
      state: 'TX',
    },
    78012: {
      lat: 28.75792,
      lng: -98.45578,
      city: 'Christine',
      state: 'TX',
    },
    78013: {
      lat: 29.97798,
      lng: -98.90647,
      city: 'Comfort',
      state: 'TX',
    },
    78014: {
      lat: 28.39837,
      lng: -99.10946,
      city: 'Cotulla',
      state: 'TX',
    },
    78015: {
      lat: 29.7439,
      lng: -98.64785,
      city: 'Boerne',
      state: 'TX',
    },
    78016: {
      lat: 29.18499,
      lng: -98.95692,
      city: 'Devine',
      state: 'TX',
    },
    78017: {
      lat: 28.72456,
      lng: -99.20455,
      city: 'Dilley',
      state: 'TX',
    },
    78019: {
      lat: 28.02401,
      lng: -99.28461,
      city: 'Encinal',
      state: 'TX',
    },
    78021: {
      lat: 28.4318,
      lng: -98.83489,
      city: 'Fowlerton',
      state: 'TX',
    },
    78022: {
      lat: 28.24222,
      lng: -98.16421,
      city: 'George West',
      state: 'TX',
    },
    78023: {
      lat: 29.61549,
      lng: -98.75443,
      city: 'Helotes',
      state: 'TX',
    },
    78024: { lat: 29.9964, lng: -99.48622, city: 'Hunt', state: 'TX' },
    78025: {
      lat: 30.07971,
      lng: -99.28562,
      city: 'Ingram',
      state: 'TX',
    },
    78026: {
      lat: 28.75573,
      lng: -98.54754,
      city: 'Jourdanton',
      state: 'TX',
    },
    78027: {
      lat: 29.98193,
      lng: -98.53868,
      city: 'Kendalia',
      state: 'TX',
    },
    78028: {
      lat: 30.04115,
      lng: -99.16276,
      city: 'Kerrville',
      state: 'TX',
    },
    78029: {
      lat: 29.90078,
      lng: -99.1898,
      city: 'Kerrville',
      state: 'TX',
    },
    78039: {
      lat: 29.31324,
      lng: -98.82528,
      city: 'La Coste',
      state: 'TX',
    },
    78040: {
      lat: 27.51349,
      lng: -99.50232,
      city: 'Laredo',
      state: 'TX',
    },
    78041: {
      lat: 27.55489,
      lng: -99.46889,
      city: 'Laredo',
      state: 'TX',
    },
    78043: {
      lat: 27.54037,
      lng: -99.27447,
      city: 'Laredo',
      state: 'TX',
    },
    78044: {
      lat: 27.75613,
      lng: -99.22748,
      city: 'Laredo',
      state: 'TX',
    },
    78045: {
      lat: 27.83197,
      lng: -99.65963,
      city: 'Laredo',
      state: 'TX',
    },
    78046: {
      lat: 27.38371,
      lng: -99.36803,
      city: 'Laredo',
      state: 'TX',
    },
    78050: {
      lat: 29.08924,
      lng: -98.50258,
      city: 'Leming',
      state: 'TX',
    },
    78052: {
      lat: 29.20582,
      lng: -98.77427,
      city: 'Lytle',
      state: 'TX',
    },
    78055: {
      lat: 29.8184,
      lng: -99.31923,
      city: 'Medina',
      state: 'TX',
    },
    78056: { lat: 29.53896, lng: -98.94184, city: 'Mico', state: 'TX' },
    78057: {
      lat: 29.03924,
      lng: -99.02185,
      city: 'Moore',
      state: 'TX',
    },
    78058: {
      lat: 30.13188,
      lng: -99.59042,
      city: 'Mountain Home',
      state: 'TX',
    },
    78059: {
      lat: 29.19236,
      lng: -98.84143,
      city: 'Natalia',
      state: 'TX',
    },
    78061: {
      lat: 28.90162,
      lng: -99.13104,
      city: 'Pearsall',
      state: 'TX',
    },
    78062: {
      lat: 28.74599,
      lng: -98.19711,
      city: 'Peggy',
      state: 'TX',
    },
    78063: {
      lat: 29.68793,
      lng: -98.91424,
      city: 'Pipe Creek',
      state: 'TX',
    },
    78064: {
      lat: 28.9206,
      lng: -98.4249,
      city: 'Pleasanton',
      state: 'TX',
    },
    78065: {
      lat: 29.08152,
      lng: -98.64437,
      city: 'Poteet',
      state: 'TX',
    },
    78066: {
      lat: 29.49065,
      lng: -98.89042,
      city: 'Rio Medina',
      state: 'TX',
    },
    78067: {
      lat: 27.11312,
      lng: -99.32793,
      city: 'San Ygnacio',
      state: 'TX',
    },
    78069: {
      lat: 29.18973,
      lng: -98.68045,
      city: 'Somerset',
      state: 'TX',
    },
    78070: {
      lat: 29.89291,
      lng: -98.40732,
      city: 'Spring Branch',
      state: 'TX',
    },
    78071: {
      lat: 28.47756,
      lng: -98.19733,
      city: 'Three Rivers',
      state: 'TX',
    },
    78072: {
      lat: 28.32631,
      lng: -98.58357,
      city: 'Tilden',
      state: 'TX',
    },
    78073: {
      lat: 29.24569,
      lng: -98.6213,
      city: 'Von Ormy',
      state: 'TX',
    },
    78074: {
      lat: 29.94234,
      lng: -98.79214,
      city: 'Waring',
      state: 'TX',
    },
    78075: {
      lat: 28.58862,
      lng: -98.26823,
      city: 'Whitsett',
      state: 'TX',
    },
    78076: {
      lat: 26.93149,
      lng: -99.13382,
      city: 'Zapata',
      state: 'TX',
    },
    78101: {
      lat: 29.34444,
      lng: -98.23316,
      city: 'Adkins',
      state: 'TX',
    },
    78102: {
      lat: 28.4094,
      lng: -97.71733,
      city: 'Beeville',
      state: 'TX',
    },
    78104: {
      lat: 28.52361,
      lng: -97.76197,
      city: 'Beeville',
      state: 'TX',
    },
    78107: {
      lat: 28.57827,
      lng: -97.59484,
      city: 'Berclair',
      state: 'TX',
    },
    78108: {
      lat: 29.57354,
      lng: -98.22068,
      city: 'Cibolo',
      state: 'TX',
    },
    78109: {
      lat: 29.47977,
      lng: -98.29295,
      city: 'Converse',
      state: 'TX',
    },
    78111: {
      lat: 29.03696,
      lng: -97.74074,
      city: 'Ecleto',
      state: 'TX',
    },
    78112: {
      lat: 29.21353,
      lng: -98.37266,
      city: 'Elmendorf',
      state: 'TX',
    },
    78113: {
      lat: 28.94563,
      lng: -98.13611,
      city: 'Falls City',
      state: 'TX',
    },
    78114: {
      lat: 29.12124,
      lng: -98.19708,
      city: 'Floresville',
      state: 'TX',
    },
    78116: {
      lat: 29.11275,
      lng: -97.75677,
      city: 'Gillett',
      state: 'TX',
    },
    78117: {
      lat: 28.95092,
      lng: -97.95736,
      city: 'Hobson',
      state: 'TX',
    },
    78118: {
      lat: 28.87574,
      lng: -97.94729,
      city: 'Karnes City',
      state: 'TX',
    },
    78119: {
      lat: 28.71739,
      lng: -97.91187,
      city: 'Kenedy',
      state: 'TX',
    },
    78121: {
      lat: 29.35118,
      lng: -98.10241,
      city: 'La Vernia',
      state: 'TX',
    },
    78122: {
      lat: 29.44512,
      lng: -97.72212,
      city: 'Leesville',
      state: 'TX',
    },
    78123: {
      lat: 29.60056,
      lng: -98.04236,
      city: 'McQueeney',
      state: 'TX',
    },
    78124: {
      lat: 29.5585,
      lng: -98.15176,
      city: 'Marion',
      state: 'TX',
    },
    78125: {
      lat: 28.53184,
      lng: -97.96656,
      city: 'Mineral',
      state: 'TX',
    },
    78130: {
      lat: 29.69224,
      lng: -98.06877,
      city: 'New Braunfels',
      state: 'TX',
    },
    78132: {
      lat: 29.76299,
      lng: -98.19,
      city: 'New Braunfels',
      state: 'TX',
    },
    78133: {
      lat: 29.88119,
      lng: -98.24624,
      city: 'Canyon Lake',
      state: 'TX',
    },
    78140: { lat: 29.30758, lng: -97.7619, city: 'Nixon', state: 'TX' },
    78141: {
      lat: 28.90484,
      lng: -97.62365,
      city: 'Nordheim',
      state: 'TX',
    },
    78142: {
      lat: 28.53632,
      lng: -97.81297,
      city: 'Normanna',
      state: 'TX',
    },
    78143: {
      lat: 29.26267,
      lng: -97.86085,
      city: 'Pandora',
      state: 'TX',
    },
    78144: {
      lat: 28.95506,
      lng: -97.88795,
      city: 'Panna Maria',
      state: 'TX',
    },
    78145: {
      lat: 28.64539,
      lng: -97.99248,
      city: 'Pawnee',
      state: 'TX',
    },
    78146: {
      lat: 28.6038,
      lng: -97.83989,
      city: 'Pettus',
      state: 'TX',
    },
    78147: { lat: 29.07375, lng: -98.08017, city: 'Poth', state: 'TX' },
    78148: {
      lat: 29.54331,
      lng: -98.29609,
      city: 'Universal City',
      state: 'TX',
    },
    78150: {
      lat: 29.5381,
      lng: -98.2815,
      city: 'Jbsa Randolph',
      state: 'TX',
    },
    78151: {
      lat: 28.86788,
      lng: -97.70986,
      city: 'Runge',
      state: 'TX',
    },
    78152: {
      lat: 29.43046,
      lng: -98.20171,
      city: 'Saint Hedwig',
      state: 'TX',
    },
    78154: {
      lat: 29.54774,
      lng: -98.26574,
      city: 'Schertz',
      state: 'TX',
    },
    78155: {
      lat: 29.53305,
      lng: -97.93832,
      city: 'Seguin',
      state: 'TX',
    },
    78159: {
      lat: 29.23031,
      lng: -97.58247,
      city: 'Smiley',
      state: 'TX',
    },
    78160: {
      lat: 29.24182,
      lng: -97.9285,
      city: 'Stockdale',
      state: 'TX',
    },
    78161: {
      lat: 29.28741,
      lng: -98.05012,
      city: 'Sutherland Springs',
      state: 'TX',
    },
    78162: {
      lat: 28.57816,
      lng: -97.79447,
      city: 'Tuleta',
      state: 'TX',
    },
    78163: {
      lat: 29.7663,
      lng: -98.44514,
      city: 'Bulverde',
      state: 'TX',
    },
    78164: {
      lat: 28.9792,
      lng: -97.5188,
      city: 'Yorktown',
      state: 'TX',
    },
    78201: {
      lat: 29.46901,
      lng: -98.52861,
      city: 'San Antonio',
      state: 'TX',
    },
    78202: {
      lat: 29.42821,
      lng: -98.46078,
      city: 'San Antonio',
      state: 'TX',
    },
    78203: {
      lat: 29.41513,
      lng: -98.4593,
      city: 'San Antonio',
      state: 'TX',
    },
    78204: {
      lat: 29.40327,
      lng: -98.50368,
      city: 'San Antonio',
      state: 'TX',
    },
    78205: {
      lat: 29.42364,
      lng: -98.48647,
      city: 'San Antonio',
      state: 'TX',
    },
    78207: {
      lat: 29.42223,
      lng: -98.52545,
      city: 'San Antonio',
      state: 'TX',
    },
    78208: {
      lat: 29.4399,
      lng: -98.45823,
      city: 'San Antonio',
      state: 'TX',
    },
    78209: {
      lat: 29.48899,
      lng: -98.45675,
      city: 'San Antonio',
      state: 'TX',
    },
    78210: {
      lat: 29.39597,
      lng: -98.46433,
      city: 'San Antonio',
      state: 'TX',
    },
    78211: {
      lat: 29.34644,
      lng: -98.56327,
      city: 'San Antonio',
      state: 'TX',
    },
    78212: {
      lat: 29.46447,
      lng: -98.49222,
      city: 'San Antonio',
      state: 'TX',
    },
    78213: {
      lat: 29.51745,
      lng: -98.52358,
      city: 'San Antonio',
      state: 'TX',
    },
    78214: {
      lat: 29.34944,
      lng: -98.47971,
      city: 'San Antonio',
      state: 'TX',
    },
    78215: {
      lat: 29.4404,
      lng: -98.48031,
      city: 'San Antonio',
      state: 'TX',
    },
    78216: {
      lat: 29.53641,
      lng: -98.48999,
      city: 'San Antonio',
      state: 'TX',
    },
    78217: {
      lat: 29.54073,
      lng: -98.42097,
      city: 'San Antonio',
      state: 'TX',
    },
    78218: {
      lat: 29.49354,
      lng: -98.39948,
      city: 'San Antonio',
      state: 'TX',
    },
    78219: {
      lat: 29.44137,
      lng: -98.38459,
      city: 'San Antonio',
      state: 'TX',
    },
    78220: {
      lat: 29.41308,
      lng: -98.40152,
      city: 'San Antonio',
      state: 'TX',
    },
    78221: {
      lat: 29.28097,
      lng: -98.47529,
      city: 'San Antonio',
      state: 'TX',
    },
    78222: {
      lat: 29.3679,
      lng: -98.38334,
      city: 'San Antonio',
      state: 'TX',
    },
    78223: {
      lat: 29.31102,
      lng: -98.38675,
      city: 'San Antonio',
      state: 'TX',
    },
    78224: {
      lat: 29.30831,
      lng: -98.53751,
      city: 'San Antonio',
      state: 'TX',
    },
    78225: {
      lat: 29.38795,
      lng: -98.52586,
      city: 'San Antonio',
      state: 'TX',
    },
    78226: {
      lat: 29.38442,
      lng: -98.56885,
      city: 'San Antonio',
      state: 'TX',
    },
    78227: {
      lat: 29.39654,
      lng: -98.64019,
      city: 'San Antonio',
      state: 'TX',
    },
    78228: {
      lat: 29.46072,
      lng: -98.57201,
      city: 'San Antonio',
      state: 'TX',
    },
    78229: {
      lat: 29.5056,
      lng: -98.57261,
      city: 'San Antonio',
      state: 'TX',
    },
    78230: {
      lat: 29.5455,
      lng: -98.55571,
      city: 'San Antonio',
      state: 'TX',
    },
    78231: {
      lat: 29.57978,
      lng: -98.54504,
      city: 'San Antonio',
      state: 'TX',
    },
    78232: {
      lat: 29.58792,
      lng: -98.47503,
      city: 'San Antonio',
      state: 'TX',
    },
    78233: {
      lat: 29.55483,
      lng: -98.36323,
      city: 'San Antonio',
      state: 'TX',
    },
    78234: {
      lat: 29.46108,
      lng: -98.43715,
      city: 'Jbsa Ft Sam Houston',
      state: 'TX',
    },
    78235: {
      lat: 29.34244,
      lng: -98.43934,
      city: 'San Antonio',
      state: 'TX',
    },
    78236: {
      lat: 29.3859,
      lng: -98.62256,
      city: 'Jbsa Lackland',
      state: 'TX',
    },
    78237: {
      lat: 29.42079,
      lng: -98.56832,
      city: 'San Antonio',
      state: 'TX',
    },
    78238: {
      lat: 29.47088,
      lng: -98.61925,
      city: 'San Antonio',
      state: 'TX',
    },
    78239: {
      lat: 29.51664,
      lng: -98.3625,
      city: 'San Antonio',
      state: 'TX',
    },
    78240: {
      lat: 29.5244,
      lng: -98.60813,
      city: 'San Antonio',
      state: 'TX',
    },
    78242: {
      lat: 29.35067,
      lng: -98.60751,
      city: 'San Antonio',
      state: 'TX',
    },
    78243: {
      lat: 29.37213,
      lng: -98.59349,
      city: 'San Antonio',
      state: 'TX',
    },
    78244: {
      lat: 29.47702,
      lng: -98.35075,
      city: 'San Antonio',
      state: 'TX',
    },
    78245: {
      lat: 29.40106,
      lng: -98.73549,
      city: 'San Antonio',
      state: 'TX',
    },
    78247: {
      lat: 29.58005,
      lng: -98.41196,
      city: 'San Antonio',
      state: 'TX',
    },
    78248: {
      lat: 29.59022,
      lng: -98.52553,
      city: 'San Antonio',
      state: 'TX',
    },
    78249: {
      lat: 29.56752,
      lng: -98.614,
      city: 'San Antonio',
      state: 'TX',
    },
    78250: {
      lat: 29.50341,
      lng: -98.66668,
      city: 'San Antonio',
      state: 'TX',
    },
    78251: {
      lat: 29.46292,
      lng: -98.67763,
      city: 'San Antonio',
      state: 'TX',
    },
    78252: {
      lat: 29.34167,
      lng: -98.69682,
      city: 'San Antonio',
      state: 'TX',
    },
    78253: {
      lat: 29.46899,
      lng: -98.78885,
      city: 'San Antonio',
      state: 'TX',
    },
    78254: {
      lat: 29.53011,
      lng: -98.72805,
      city: 'San Antonio',
      state: 'TX',
    },
    78255: {
      lat: 29.64814,
      lng: -98.66405,
      city: 'San Antonio',
      state: 'TX',
    },
    78256: {
      lat: 29.62272,
      lng: -98.6262,
      city: 'San Antonio',
      state: 'TX',
    },
    78257: {
      lat: 29.64373,
      lng: -98.57713,
      city: 'San Antonio',
      state: 'TX',
    },
    78258: {
      lat: 29.63574,
      lng: -98.49282,
      city: 'San Antonio',
      state: 'TX',
    },
    78259: {
      lat: 29.62512,
      lng: -98.42031,
      city: 'San Antonio',
      state: 'TX',
    },
    78260: {
      lat: 29.69737,
      lng: -98.49212,
      city: 'San Antonio',
      state: 'TX',
    },
    78261: {
      lat: 29.69189,
      lng: -98.40072,
      city: 'San Antonio',
      state: 'TX',
    },
    78263: {
      lat: 29.35084,
      lng: -98.31219,
      city: 'San Antonio',
      state: 'TX',
    },
    78264: {
      lat: 29.19868,
      lng: -98.50534,
      city: 'San Antonio',
      state: 'TX',
    },
    78266: {
      lat: 29.65611,
      lng: -98.3295,
      city: 'San Antonio',
      state: 'TX',
    },
    78330: {
      lat: 27.7656,
      lng: -97.91378,
      city: 'Agua Dulce',
      state: 'TX',
    },
    78332: {
      lat: 27.71671,
      lng: -98.13208,
      city: 'Alice',
      state: 'TX',
    },
    78336: {
      lat: 27.94356,
      lng: -97.1862,
      city: 'Aransas Pass',
      state: 'TX',
    },
    78338: {
      lat: 26.86821,
      lng: -97.73585,
      city: 'Armstrong',
      state: 'TX',
    },
    78339: {
      lat: 27.80036,
      lng: -97.7912,
      city: 'Banquete',
      state: 'TX',
    },
    78340: {
      lat: 28.12472,
      lng: -97.20982,
      city: 'Bayside',
      state: 'TX',
    },
    78341: {
      lat: 27.57551,
      lng: -98.46636,
      city: 'Benavides',
      state: 'TX',
    },
    78342: {
      lat: 27.66012,
      lng: -98.07572,
      city: 'Ben Bolt',
      state: 'TX',
    },
    78343: {
      lat: 27.62163,
      lng: -97.77216,
      city: 'Bishop',
      state: 'TX',
    },
    78344: {
      lat: 27.56303,
      lng: -98.85966,
      city: 'Bruni',
      state: 'TX',
    },
    78347: {
      lat: 27.57337,
      lng: -97.48642,
      city: 'Chapman Ranch',
      state: 'TX',
    },
    78349: {
      lat: 27.37501,
      lng: -98.29983,
      city: 'Concepcion',
      state: 'TX',
    },
    78351: {
      lat: 27.66779,
      lng: -97.75258,
      city: 'Driscoll',
      state: 'TX',
    },
    78352: {
      lat: 27.98154,
      lng: -97.68978,
      city: 'Edroy',
      state: 'TX',
    },
    78353: {
      lat: 26.88621,
      lng: -98.23927,
      city: 'Encino',
      state: 'TX',
    },
    78355: {
      lat: 27.18855,
      lng: -98.20304,
      city: 'Falfurrias',
      state: 'TX',
    },
    78357: { lat: 27.93286, lng: -98.5629, city: 'Freer', state: 'TX' },
    78358: {
      lat: 28.06841,
      lng: -97.04325,
      city: 'Fulton',
      state: 'TX',
    },
    78359: {
      lat: 27.93021,
      lng: -97.30056,
      city: 'Gregory',
      state: 'TX',
    },
    78360: {
      lat: 26.8605,
      lng: -98.83204,
      city: 'Guerra',
      state: 'TX',
    },
    78361: {
      lat: 27.08457,
      lng: -98.73973,
      city: 'Hebbronville',
      state: 'TX',
    },
    78362: {
      lat: 27.86668,
      lng: -97.20167,
      city: 'Ingleside',
      state: 'TX',
    },
    78363: {
      lat: 27.43836,
      lng: -97.85532,
      city: 'Kingsville',
      state: 'TX',
    },
    78368: {
      lat: 28.11299,
      lng: -97.81201,
      city: 'Mathis',
      state: 'TX',
    },
    78369: {
      lat: 27.42418,
      lng: -99.08229,
      city: 'Mirando City',
      state: 'TX',
    },
    78370: { lat: 27.94238, lng: -97.6033, city: 'Odem', state: 'TX' },
    78371: {
      lat: 27.5493,
      lng: -99.04716,
      city: 'Oilton',
      state: 'TX',
    },
    78372: {
      lat: 27.9979,
      lng: -98.09669,
      city: 'Orange Grove',
      state: 'TX',
    },
    78373: {
      lat: 27.78075,
      lng: -97.11737,
      city: 'Port Aransas',
      state: 'TX',
    },
    78374: {
      lat: 27.88849,
      lng: -97.29366,
      city: 'Portland',
      state: 'TX',
    },
    78375: {
      lat: 27.38282,
      lng: -98.16351,
      city: 'Premont',
      state: 'TX',
    },
    78376: {
      lat: 27.37163,
      lng: -98.47721,
      city: 'Realitos',
      state: 'TX',
    },
    78377: {
      lat: 28.3418,
      lng: -97.18051,
      city: 'Refugio',
      state: 'TX',
    },
    78379: {
      lat: 27.30211,
      lng: -97.79361,
      city: 'Riviera',
      state: 'TX',
    },
    78380: {
      lat: 27.77872,
      lng: -97.7363,
      city: 'Robstown',
      state: 'TX',
    },
    78382: {
      lat: 28.11489,
      lng: -97.06342,
      city: 'Rockport',
      state: 'TX',
    },
    78383: {
      lat: 28.07713,
      lng: -97.94425,
      city: 'Sandia',
      state: 'TX',
    },
    78384: {
      lat: 27.73068,
      lng: -98.4707,
      city: 'San Diego',
      state: 'TX',
    },
    78385: { lat: 27.1276, lng: -97.8369, city: 'Sarita', state: 'TX' },
    78387: {
      lat: 28.08224,
      lng: -97.55255,
      city: 'Sinton',
      state: 'TX',
    },
    78389: {
      lat: 28.244,
      lng: -97.66619,
      city: 'Skidmore',
      state: 'TX',
    },
    78390: { lat: 28.00198, lng: -97.35631, city: 'Taft', state: 'TX' },
    78391: {
      lat: 28.18297,
      lng: -97.72743,
      city: 'Tynan',
      state: 'TX',
    },
    78393: {
      lat: 28.18097,
      lng: -97.35427,
      city: 'Woodsboro',
      state: 'TX',
    },
    78401: {
      lat: 27.79734,
      lng: -97.40118,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78402: {
      lat: 27.82413,
      lng: -97.4091,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78404: {
      lat: 27.76798,
      lng: -97.39946,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78405: {
      lat: 27.77404,
      lng: -97.43872,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78406: {
      lat: 27.77197,
      lng: -97.51543,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78407: {
      lat: 27.80762,
      lng: -97.44772,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78408: {
      lat: 27.80081,
      lng: -97.44339,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78409: {
      lat: 27.81729,
      lng: -97.51458,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78410: {
      lat: 27.83798,
      lng: -97.59388,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78411: {
      lat: 27.7293,
      lng: -97.38504,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78412: {
      lat: 27.70364,
      lng: -97.34264,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78413: {
      lat: 27.68345,
      lng: -97.40409,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78414: {
      lat: 27.65992,
      lng: -97.369,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78415: {
      lat: 27.65437,
      lng: -97.48712,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78416: {
      lat: 27.74933,
      lng: -97.43759,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78417: {
      lat: 27.73753,
      lng: -97.46771,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78418: {
      lat: 27.52732,
      lng: -97.29313,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78419: {
      lat: 27.69689,
      lng: -97.26881,
      city: 'Corpus Christi',
      state: 'TX',
    },
    78501: {
      lat: 26.21599,
      lng: -98.2391,
      city: 'Mcallen',
      state: 'TX',
    },
    78503: {
      lat: 26.16229,
      lng: -98.25132,
      city: 'Mcallen',
      state: 'TX',
    },
    78504: {
      lat: 26.27758,
      lng: -98.23728,
      city: 'Mcallen',
      state: 'TX',
    },
    78516: {
      lat: 26.14256,
      lng: -98.11977,
      city: 'Alamo',
      state: 'TX',
    },
    78520: {
      lat: 25.96564,
      lng: -97.55103,
      city: 'Brownsville',
      state: 'TX',
    },
    78521: {
      lat: 25.95109,
      lng: -97.32078,
      city: 'Brownsville',
      state: 'TX',
    },
    78526: {
      lat: 25.99587,
      lng: -97.44991,
      city: 'Brownsville',
      state: 'TX',
    },
    78535: {
      lat: 26.24948,
      lng: -97.73124,
      city: 'Combes',
      state: 'TX',
    },
    78536: {
      lat: 26.6432,
      lng: -98.44369,
      city: 'Delmita',
      state: 'TX',
    },
    78537: {
      lat: 26.17322,
      lng: -98.05744,
      city: 'Donna',
      state: 'TX',
    },
    78538: {
      lat: 26.36812,
      lng: -97.97152,
      city: 'Edcouch',
      state: 'TX',
    },
    78539: {
      lat: 26.28,
      lng: -98.18311,
      city: 'Edinburg',
      state: 'TX',
    },
    78540: {
      lat: 26.54911,
      lng: -97.99164,
      city: 'Edinburg',
      state: 'TX',
    },
    78541: {
      lat: 26.4528,
      lng: -98.28632,
      city: 'Edinburg',
      state: 'TX',
    },
    78542: {
      lat: 26.44412,
      lng: -98.09163,
      city: 'Edinburg',
      state: 'TX',
    },
    78543: { lat: 26.30117, lng: -98.00508, city: 'Elsa', state: 'TX' },
    78545: {
      lat: 26.591,
      lng: -99.13889,
      city: 'Falcon Heights',
      state: 'TX',
    },
    78547: {
      lat: 26.30868,
      lng: -98.69723,
      city: 'Garciasville',
      state: 'TX',
    },
    78548: {
      lat: 26.27654,
      lng: -98.65527,
      city: 'Grulla',
      state: 'TX',
    },
    78549: {
      lat: 26.48528,
      lng: -98.02329,
      city: 'Hargill',
      state: 'TX',
    },
    78550: {
      lat: 26.25469,
      lng: -97.66406,
      city: 'Harlingen',
      state: 'TX',
    },
    78552: {
      lat: 26.19664,
      lng: -97.75083,
      city: 'Harlingen',
      state: 'TX',
    },
    78557: {
      lat: 26.10844,
      lng: -98.25118,
      city: 'Hidalgo',
      state: 'TX',
    },
    78558: {
      lat: 26.29276,
      lng: -98.02366,
      city: 'La Blanca',
      state: 'TX',
    },
    78559: {
      lat: 26.14549,
      lng: -97.82765,
      city: 'La Feria',
      state: 'TX',
    },
    78560: {
      lat: 26.25545,
      lng: -98.49515,
      city: 'La Joya',
      state: 'TX',
    },
    78561: {
      lat: 26.4687,
      lng: -97.91595,
      city: 'Lasara',
      state: 'TX',
    },
    78562: {
      lat: 26.28809,
      lng: -97.89293,
      city: 'La Villa',
      state: 'TX',
    },
    78563: { lat: 26.66557, lng: -98.2517, city: 'Linn', state: 'TX' },
    78564: {
      lat: 26.67801,
      lng: -99.13171,
      city: 'Lopeno',
      state: 'TX',
    },
    78565: {
      lat: 26.24453,
      lng: -98.561,
      city: 'Los Ebanos',
      state: 'TX',
    },
    78566: {
      lat: 26.13543,
      lng: -97.42184,
      city: 'Los Fresnos',
      state: 'TX',
    },
    78567: {
      lat: 26.04607,
      lng: -97.73866,
      city: 'Los Indios',
      state: 'TX',
    },
    78569: {
      lat: 26.37417,
      lng: -97.73851,
      city: 'Lyford',
      state: 'TX',
    },
    78570: {
      lat: 26.17149,
      lng: -97.9112,
      city: 'Mercedes',
      state: 'TX',
    },
    78572: {
      lat: 26.19848,
      lng: -98.35633,
      city: 'Mission',
      state: 'TX',
    },
    78573: {
      lat: 26.29745,
      lng: -98.30242,
      city: 'Mission',
      state: 'TX',
    },
    78574: {
      lat: 26.31118,
      lng: -98.37293,
      city: 'Mission',
      state: 'TX',
    },
    78575: {
      lat: 26.03234,
      lng: -97.55083,
      city: 'Olmito',
      state: 'TX',
    },
    78576: {
      lat: 26.32584,
      lng: -98.46799,
      city: 'Penitas',
      state: 'TX',
    },
    78577: { lat: 26.1665, lng: -98.19082, city: 'Pharr', state: 'TX' },
    78578: {
      lat: 26.05336,
      lng: -97.31004,
      city: 'Port Isabel',
      state: 'TX',
    },
    78579: {
      lat: 26.0892,
      lng: -97.94627,
      city: 'Progreso',
      state: 'TX',
    },
    78580: {
      lat: 26.50065,
      lng: -97.75087,
      city: 'Raymondville',
      state: 'TX',
    },
    78582: {
      lat: 26.53937,
      lng: -98.73985,
      city: 'Rio Grande City',
      state: 'TX',
    },
    78583: {
      lat: 26.28166,
      lng: -97.4686,
      city: 'Rio Hondo',
      state: 'TX',
    },
    78584: { lat: 26.56226, lng: -98.98471, city: 'Roma', state: 'TX' },
    78585: {
      lat: 26.4856,
      lng: -99.07737,
      city: 'Salineno',
      state: 'TX',
    },
    78586: {
      lat: 26.10715,
      lng: -97.64576,
      city: 'San Benito',
      state: 'TX',
    },
    78588: {
      lat: 26.73966,
      lng: -98.39852,
      city: 'San Isidro',
      state: 'TX',
    },
    78589: {
      lat: 26.15349,
      lng: -98.15979,
      city: 'San Juan',
      state: 'TX',
    },
    78590: {
      lat: 26.50401,
      lng: -97.63486,
      city: 'San Perlita',
      state: 'TX',
    },
    78591: {
      lat: 26.65351,
      lng: -98.53408,
      city: 'Santa Elena',
      state: 'TX',
    },
    78592: {
      lat: 26.06516,
      lng: -97.8461,
      city: 'Santa Maria',
      state: 'TX',
    },
    78593: {
      lat: 26.26999,
      lng: -97.82187,
      city: 'Santa Rosa',
      state: 'TX',
    },
    78594: {
      lat: 26.34704,
      lng: -97.8037,
      city: 'Sebastian',
      state: 'TX',
    },
    78595: {
      lat: 26.31457,
      lng: -98.54038,
      city: 'Sullivan City',
      state: 'TX',
    },
    78596: {
      lat: 26.10668,
      lng: -97.99856,
      city: 'Weslaco',
      state: 'TX',
    },
    78597: {
      lat: 26.2474,
      lng: -97.19688,
      city: 'South Padre Island',
      state: 'TX',
    },
    78598: {
      lat: 26.52363,
      lng: -97.42327,
      city: 'Port Mansfield',
      state: 'TX',
    },
    78599: {
      lat: 26.2181,
      lng: -97.99087,
      city: 'Weslaco',
      state: 'TX',
    },
    78602: {
      lat: 30.12735,
      lng: -97.32801,
      city: 'Bastrop',
      state: 'TX',
    },
    78605: {
      lat: 30.77366,
      lng: -98.04663,
      city: 'Bertram',
      state: 'TX',
    },
    78606: {
      lat: 30.09213,
      lng: -98.45789,
      city: 'Blanco',
      state: 'TX',
    },
    78607: {
      lat: 30.81961,
      lng: -98.488,
      city: 'Bluffton',
      state: 'TX',
    },
    78608: {
      lat: 30.9341,
      lng: -97.93255,
      city: 'Briggs',
      state: 'TX',
    },
    78609: {
      lat: 30.75975,
      lng: -98.45271,
      city: 'Buchanan Dam',
      state: 'TX',
    },
    78610: { lat: 30.07962, lng: -97.84047, city: 'Buda', state: 'TX' },
    78611: {
      lat: 30.79188,
      lng: -98.26663,
      city: 'Burnet',
      state: 'TX',
    },
    78612: {
      lat: 30.09301,
      lng: -97.49017,
      city: 'Cedar Creek',
      state: 'TX',
    },
    78613: {
      lat: 30.50683,
      lng: -97.81939,
      city: 'Cedar Park',
      state: 'TX',
    },
    78614: { lat: 29.41314, lng: -97.59429, city: 'Cost', state: 'TX' },
    78615: {
      lat: 30.46426,
      lng: -97.39061,
      city: 'Coupland',
      state: 'TX',
    },
    78616: { lat: 29.92687, lng: -97.55655, city: 'Dale', state: 'TX' },
    78617: {
      lat: 30.1508,
      lng: -97.59928,
      city: 'Del Valle',
      state: 'TX',
    },
    78618: { lat: 30.48624, lng: -99.16794, city: 'Doss', state: 'TX' },
    78619: {
      lat: 30.11215,
      lng: -98.03101,
      city: 'Driftwood',
      state: 'TX',
    },
    78620: {
      lat: 30.22214,
      lng: -98.13491,
      city: 'Dripping Springs',
      state: 'TX',
    },
    78621: {
      lat: 30.33737,
      lng: -97.36053,
      city: 'Elgin',
      state: 'TX',
    },
    78622: {
      lat: 29.77225,
      lng: -97.78229,
      city: 'Fentress',
      state: 'TX',
    },
    78623: {
      lat: 29.95973,
      lng: -98.21933,
      city: 'Fischer',
      state: 'TX',
    },
    78624: {
      lat: 30.29227,
      lng: -98.87141,
      city: 'Fredericksburg',
      state: 'TX',
    },
    78626: {
      lat: 30.66664,
      lng: -97.59878,
      city: 'Georgetown',
      state: 'TX',
    },
    78628: {
      lat: 30.63981,
      lng: -97.74636,
      city: 'Georgetown',
      state: 'TX',
    },
    78629: {
      lat: 29.47762,
      lng: -97.45093,
      city: 'Gonzales',
      state: 'TX',
    },
    78631: {
      lat: 30.34576,
      lng: -99.31161,
      city: 'Harper',
      state: 'TX',
    },
    78632: {
      lat: 29.68866,
      lng: -97.46858,
      city: 'Harwood',
      state: 'TX',
    },
    78633: {
      lat: 30.73803,
      lng: -97.75465,
      city: 'Georgetown',
      state: 'TX',
    },
    78634: { lat: 30.56, lng: -97.54404, city: 'Hutto', state: 'TX' },
    78635: { lat: 30.20824, lng: -98.5485, city: 'Hye', state: 'TX' },
    78636: {
      lat: 30.31649,
      lng: -98.42376,
      city: 'Johnson City',
      state: 'TX',
    },
    78638: {
      lat: 29.65821,
      lng: -97.79595,
      city: 'Kingsbury',
      state: 'TX',
    },
    78639: {
      lat: 30.65823,
      lng: -98.45506,
      city: 'Kingsland',
      state: 'TX',
    },
    78640: { lat: 29.99619, lng: -97.82355, city: 'Kyle', state: 'TX' },
    78641: {
      lat: 30.55851,
      lng: -97.91473,
      city: 'Leander',
      state: 'TX',
    },
    78642: {
      lat: 30.6946,
      lng: -97.94087,
      city: 'Liberty Hill',
      state: 'TX',
    },
    78643: {
      lat: 30.68161,
      lng: -98.71281,
      city: 'Llano',
      state: 'TX',
    },
    78644: {
      lat: 29.87111,
      lng: -97.67292,
      city: 'Lockhart',
      state: 'TX',
    },
    78645: {
      lat: 30.4502,
      lng: -97.96934,
      city: 'Leander',
      state: 'TX',
    },
    78648: {
      lat: 29.69594,
      lng: -97.63827,
      city: 'Luling',
      state: 'TX',
    },
    78650: {
      lat: 30.30536,
      lng: -97.21574,
      city: 'McDade',
      state: 'TX',
    },
    78652: {
      lat: 30.13462,
      lng: -97.8606,
      city: 'Manchaca',
      state: 'TX',
    },
    78653: {
      lat: 30.33783,
      lng: -97.52006,
      city: 'Manor',
      state: 'TX',
    },
    78654: {
      lat: 30.57112,
      lng: -98.20982,
      city: 'Marble Falls',
      state: 'TX',
    },
    78655: {
      lat: 29.82346,
      lng: -97.83517,
      city: 'Martindale',
      state: 'TX',
    },
    78656: {
      lat: 29.90275,
      lng: -97.8089,
      city: 'Maxwell',
      state: 'TX',
    },
    78657: {
      lat: 30.53328,
      lng: -98.37906,
      city: 'Horseshoe Bay',
      state: 'TX',
    },
    78658: {
      lat: 29.59339,
      lng: -97.62661,
      city: 'Ottine',
      state: 'TX',
    },
    78659: {
      lat: 30.20667,
      lng: -97.12624,
      city: 'Paige',
      state: 'TX',
    },
    78660: {
      lat: 30.44361,
      lng: -97.59558,
      city: 'Pflugerville',
      state: 'TX',
    },
    78661: {
      lat: 29.72925,
      lng: -97.74212,
      city: 'Prairie Lea',
      state: 'TX',
    },
    78662: {
      lat: 29.94316,
      lng: -97.44231,
      city: 'Red Rock',
      state: 'TX',
    },
    78663: {
      lat: 30.41582,
      lng: -98.31144,
      city: 'Round Mountain',
      state: 'TX',
    },
    78664: {
      lat: 30.50461,
      lng: -97.64583,
      city: 'Round Rock',
      state: 'TX',
    },
    78665: {
      lat: 30.55038,
      lng: -97.63681,
      city: 'Round Rock',
      state: 'TX',
    },
    78666: {
      lat: 29.8826,
      lng: -97.97974,
      city: 'San Marcos',
      state: 'TX',
    },
    78669: {
      lat: 30.4347,
      lng: -98.13066,
      city: 'Spicewood',
      state: 'TX',
    },
    78670: {
      lat: 29.76267,
      lng: -97.84608,
      city: 'Staples',
      state: 'TX',
    },
    78671: {
      lat: 30.20409,
      lng: -98.63275,
      city: 'Stonewall',
      state: 'TX',
    },
    78672: { lat: 30.88151, lng: -98.49128, city: 'Tow', state: 'TX' },
    78674: { lat: 30.67213, lng: -97.59653, city: 'Weir', state: 'TX' },
    78675: {
      lat: 30.4585,
      lng: -98.70534,
      city: 'Willow City',
      state: 'TX',
    },
    78676: {
      lat: 30.04141,
      lng: -98.15051,
      city: 'Wimberley',
      state: 'TX',
    },
    78677: {
      lat: 29.34174,
      lng: -97.59335,
      city: 'Wrightsboro',
      state: 'TX',
    },
    78681: {
      lat: 30.5355,
      lng: -97.71981,
      city: 'Round Rock',
      state: 'TX',
    },
    78701: {
      lat: 30.27022,
      lng: -97.74248,
      city: 'Austin',
      state: 'TX',
    },
    78702: {
      lat: 30.26339,
      lng: -97.7144,
      city: 'Austin',
      state: 'TX',
    },
    78703: {
      lat: 30.29385,
      lng: -97.76554,
      city: 'Austin',
      state: 'TX',
    },
    78704: {
      lat: 30.24298,
      lng: -97.76537,
      city: 'Austin',
      state: 'TX',
    },
    78705: { lat: 30.2947, lng: -97.739, city: 'Austin', state: 'TX' },
    78712: {
      lat: 30.28393,
      lng: -97.73294,
      city: 'Austin',
      state: 'TX',
    },
    78717: {
      lat: 30.48988,
      lng: -97.75393,
      city: 'Austin',
      state: 'TX',
    },
    78719: {
      lat: 30.14465,
      lng: -97.67148,
      city: 'Austin',
      state: 'TX',
    },
    78721: {
      lat: 30.27127,
      lng: -97.68345,
      city: 'Austin',
      state: 'TX',
    },
    78722: {
      lat: 30.29005,
      lng: -97.71458,
      city: 'Austin',
      state: 'TX',
    },
    78723: {
      lat: 30.30423,
      lng: -97.68578,
      city: 'Austin',
      state: 'TX',
    },
    78724: {
      lat: 30.29474,
      lng: -97.61439,
      city: 'Austin',
      state: 'TX',
    },
    78725: {
      lat: 30.23554,
      lng: -97.60859,
      city: 'Austin',
      state: 'TX',
    },
    78726: {
      lat: 30.42898,
      lng: -97.84254,
      city: 'Austin',
      state: 'TX',
    },
    78727: {
      lat: 30.42894,
      lng: -97.71736,
      city: 'Austin',
      state: 'TX',
    },
    78728: {
      lat: 30.45537,
      lng: -97.68948,
      city: 'Austin',
      state: 'TX',
    },
    78729: {
      lat: 30.45738,
      lng: -97.75516,
      city: 'Austin',
      state: 'TX',
    },
    78730: {
      lat: 30.36322,
      lng: -97.83847,
      city: 'Austin',
      state: 'TX',
    },
    78731: {
      lat: 30.34766,
      lng: -97.7687,
      city: 'Austin',
      state: 'TX',
    },
    78732: {
      lat: 30.3802,
      lng: -97.89291,
      city: 'Austin',
      state: 'TX',
    },
    78733: {
      lat: 30.32338,
      lng: -97.87762,
      city: 'Austin',
      state: 'TX',
    },
    78734: {
      lat: 30.38079,
      lng: -97.94676,
      city: 'Austin',
      state: 'TX',
    },
    78735: {
      lat: 30.26634,
      lng: -97.86775,
      city: 'Austin',
      state: 'TX',
    },
    78736: {
      lat: 30.24833,
      lng: -97.94965,
      city: 'Austin',
      state: 'TX',
    },
    78737: {
      lat: 30.18935,
      lng: -97.96059,
      city: 'Austin',
      state: 'TX',
    },
    78738: {
      lat: 30.30668,
      lng: -97.99002,
      city: 'Austin',
      state: 'TX',
    },
    78739: {
      lat: 30.17596,
      lng: -97.89087,
      city: 'Austin',
      state: 'TX',
    },
    78741: {
      lat: 30.23007,
      lng: -97.71389,
      city: 'Austin',
      state: 'TX',
    },
    78742: {
      lat: 30.24375,
      lng: -97.65869,
      city: 'Austin',
      state: 'TX',
    },
    78744: {
      lat: 30.18024,
      lng: -97.72888,
      city: 'Austin',
      state: 'TX',
    },
    78745: {
      lat: 30.20743,
      lng: -97.79822,
      city: 'Austin',
      state: 'TX',
    },
    78746: {
      lat: 30.29759,
      lng: -97.81136,
      city: 'Austin',
      state: 'TX',
    },
    78747: {
      lat: 30.12521,
      lng: -97.74117,
      city: 'Austin',
      state: 'TX',
    },
    78748: {
      lat: 30.16144,
      lng: -97.8227,
      city: 'Austin',
      state: 'TX',
    },
    78749: {
      lat: 30.21329,
      lng: -97.85732,
      city: 'Austin',
      state: 'TX',
    },
    78750: {
      lat: 30.41685,
      lng: -97.80223,
      city: 'Austin',
      state: 'TX',
    },
    78751: {
      lat: 30.3108,
      lng: -97.72278,
      city: 'Austin',
      state: 'TX',
    },
    78752: {
      lat: 30.33141,
      lng: -97.70364,
      city: 'Austin',
      state: 'TX',
    },
    78753: {
      lat: 30.38353,
      lng: -97.67335,
      city: 'Austin',
      state: 'TX',
    },
    78754: {
      lat: 30.35633,
      lng: -97.63925,
      city: 'Austin',
      state: 'TX',
    },
    78756: {
      lat: 30.32219,
      lng: -97.7402,
      city: 'Austin',
      state: 'TX',
    },
    78757: {
      lat: 30.35164,
      lng: -97.73267,
      city: 'Austin',
      state: 'TX',
    },
    78758: {
      lat: 30.38768,
      lng: -97.70665,
      city: 'Austin',
      state: 'TX',
    },
    78759: {
      lat: 30.4027,
      lng: -97.76093,
      city: 'Austin',
      state: 'TX',
    },
    78801: {
      lat: 29.36407,
      lng: -99.88476,
      city: 'Uvalde',
      state: 'TX',
    },
    78802: {
      lat: 29.14481,
      lng: -99.91983,
      city: 'Uvalde',
      state: 'TX',
    },
    78827: {
      lat: 28.42865,
      lng: -99.72393,
      city: 'Asherton',
      state: 'TX',
    },
    78828: {
      lat: 29.84372,
      lng: -99.99375,
      city: 'Barksdale',
      state: 'TX',
    },
    78829: {
      lat: 28.89351,
      lng: -99.57401,
      city: 'Batesville',
      state: 'TX',
    },
    78830: {
      lat: 28.53226,
      lng: -99.48219,
      city: 'Big Wells',
      state: 'TX',
    },
    78832: {
      lat: 29.39512,
      lng: -100.4069,
      city: 'Brackettville',
      state: 'TX',
    },
    78833: {
      lat: 29.69785,
      lng: -99.97248,
      city: 'Camp Wood',
      state: 'TX',
    },
    78834: {
      lat: 28.54776,
      lng: -99.87286,
      city: 'Carrizo Springs',
      state: 'TX',
    },
    78836: {
      lat: 28.32336,
      lng: -99.64569,
      city: 'Catarina',
      state: 'TX',
    },
    78837: {
      lat: 29.92708,
      lng: -101.28057,
      city: 'Comstock',
      state: 'TX',
    },
    78838: {
      lat: 29.53476,
      lng: -99.70757,
      city: 'Concan',
      state: 'TX',
    },
    78839: {
      lat: 28.71282,
      lng: -99.75516,
      city: 'Crystal City',
      state: 'TX',
    },
    78840: {
      lat: 29.74499,
      lng: -100.92362,
      city: 'Del Rio',
      state: 'TX',
    },
    78841: {
      lat: 29.8459,
      lng: -101.4653,
      city: 'Del Rio',
      state: 'TX',
    },
    78842: {
      lat: 29.63677,
      lng: -101.01044,
      city: 'Del Rio',
      state: 'TX',
    },
    78843: {
      lat: 29.34904,
      lng: -100.79188,
      city: 'Laughlin Afb',
      state: 'TX',
    },
    78850: {
      lat: 29.27937,
      lng: -99.33581,
      city: 'D Hanis',
      state: 'TX',
    },
    78851: {
      lat: 30.22441,
      lng: -101.91733,
      city: 'Dryden',
      state: 'TX',
    },
    78852: {
      lat: 28.82698,
      lng: -100.31543,
      city: 'Eagle Pass',
      state: 'TX',
    },
    78860: {
      lat: 28.38168,
      lng: -100.22962,
      city: 'El Indio',
      state: 'TX',
    },
    78861: {
      lat: 29.41778,
      lng: -99.15663,
      city: 'Hondo',
      state: 'TX',
    },
    78870: {
      lat: 29.29829,
      lng: -99.61255,
      city: 'Knippa',
      state: 'TX',
    },
    78871: {
      lat: 29.90185,
      lng: -101.62018,
      city: 'Langtry',
      state: 'TX',
    },
    78872: {
      lat: 28.90751,
      lng: -99.88039,
      city: 'La Pryor',
      state: 'TX',
    },
    78873: {
      lat: 29.80966,
      lng: -99.73953,
      city: 'Leakey',
      state: 'TX',
    },
    78877: {
      lat: 28.9533,
      lng: -100.60429,
      city: 'Quemado',
      state: 'TX',
    },
    78879: {
      lat: 29.6435,
      lng: -99.71733,
      city: 'Rio Frio',
      state: 'TX',
    },
    78880: {
      lat: 29.96486,
      lng: -100.27779,
      city: 'Rocksprings',
      state: 'TX',
    },
    78881: {
      lat: 29.39333,
      lng: -99.50972,
      city: 'Sabinal',
      state: 'TX',
    },
    78883: {
      lat: 29.67707,
      lng: -99.31851,
      city: 'Tarpley',
      state: 'TX',
    },
    78884: {
      lat: 29.63823,
      lng: -99.49308,
      city: 'Utopia',
      state: 'TX',
    },
    78885: {
      lat: 29.82682,
      lng: -99.54502,
      city: 'Vanderpool',
      state: 'TX',
    },
    78886: {
      lat: 29.15453,
      lng: -99.18749,
      city: 'Yancey',
      state: 'TX',
    },
    78931: {
      lat: 30.03117,
      lng: -96.44291,
      city: 'Bleiblerville',
      state: 'TX',
    },
    78932: {
      lat: 30.1656,
      lng: -96.70793,
      city: 'Carmine',
      state: 'TX',
    },
    78933: {
      lat: 29.77209,
      lng: -96.33829,
      city: 'Cat Spring',
      state: 'TX',
    },
    78934: {
      lat: 29.7023,
      lng: -96.56741,
      city: 'Columbus',
      state: 'TX',
    },
    78935: {
      lat: 29.68797,
      lng: -96.43006,
      city: 'Alleyton',
      state: 'TX',
    },
    78938: {
      lat: 29.82757,
      lng: -96.7041,
      city: 'Ellinger',
      state: 'TX',
    },
    78940: {
      lat: 29.93139,
      lng: -96.66701,
      city: 'Fayetteville',
      state: 'TX',
    },
    78941: {
      lat: 29.74201,
      lng: -97.1561,
      city: 'Flatonia',
      state: 'TX',
    },
    78942: {
      lat: 30.16803,
      lng: -96.93153,
      city: 'Giddings',
      state: 'TX',
    },
    78943: {
      lat: 29.69721,
      lng: -96.59538,
      city: 'Glidden',
      state: 'TX',
    },
    78944: {
      lat: 30.00401,
      lng: -96.48945,
      city: 'Industry',
      state: 'TX',
    },
    78945: {
      lat: 29.91594,
      lng: -96.89031,
      city: 'La Grange',
      state: 'TX',
    },
    78946: {
      lat: 30.18947,
      lng: -96.77412,
      city: 'Ledbetter',
      state: 'TX',
    },
    78947: {
      lat: 30.42378,
      lng: -97.04183,
      city: 'Lexington',
      state: 'TX',
    },
    78948: {
      lat: 30.30748,
      lng: -96.97022,
      city: 'Lincoln',
      state: 'TX',
    },
    78949: {
      lat: 29.83681,
      lng: -97.08062,
      city: 'Muldoon',
      state: 'TX',
    },
    78950: {
      lat: 29.90735,
      lng: -96.48518,
      city: 'New Ulm',
      state: 'TX',
    },
    78951: {
      lat: 29.61361,
      lng: -96.83746,
      city: 'Oakland',
      state: 'TX',
    },
    78953: {
      lat: 29.85552,
      lng: -97.35214,
      city: 'Rosanky',
      state: 'TX',
    },
    78954: {
      lat: 30.04507,
      lng: -96.69774,
      city: 'Round Top',
      state: 'TX',
    },
    78956: {
      lat: 29.68322,
      lng: -96.93454,
      city: 'Schulenburg',
      state: 'TX',
    },
    78957: {
      lat: 30.01279,
      lng: -97.17481,
      city: 'Smithville',
      state: 'TX',
    },
    78959: {
      lat: 29.69877,
      lng: -97.30153,
      city: 'Waelder',
      state: 'TX',
    },
    78962: {
      lat: 29.66951,
      lng: -96.73815,
      city: 'Weimar',
      state: 'TX',
    },
    78963: {
      lat: 29.92586,
      lng: -97.02767,
      city: 'West Point',
      state: 'TX',
    },
    79001: {
      lat: 35.2824,
      lng: -102.8023,
      city: 'Adrian',
      state: 'TX',
    },
    79002: {
      lat: 35.19819,
      lng: -100.76956,
      city: 'Alanreed',
      state: 'TX',
    },
    79003: {
      lat: 35.63873,
      lng: -100.0881,
      city: 'Allison',
      state: 'TX',
    },
    79005: {
      lat: 36.3874,
      lng: -100.50627,
      city: 'Booker',
      state: 'TX',
    },
    79007: {
      lat: 35.66635,
      lng: -101.41444,
      city: 'Borger',
      state: 'TX',
    },
    79008: {
      lat: 35.69845,
      lng: -101.42383,
      city: 'Borger',
      state: 'TX',
    },
    79009: {
      lat: 34.53016,
      lng: -102.88533,
      city: 'Bovina',
      state: 'TX',
    },
    79010: {
      lat: 35.45283,
      lng: -102.12013,
      city: 'Boys Ranch',
      state: 'TX',
    },
    79011: {
      lat: 35.63111,
      lng: -100.20866,
      city: 'Briscoe',
      state: 'TX',
    },
    79012: {
      lat: 35.11701,
      lng: -102.1072,
      city: 'Bushland',
      state: 'TX',
    },
    79013: {
      lat: 36.04183,
      lng: -101.99335,
      city: 'Cactus',
      state: 'TX',
    },
    79014: {
      lat: 35.91912,
      lng: -100.32123,
      city: 'Canadian',
      state: 'TX',
    },
    79015: {
      lat: 34.95259,
      lng: -101.89925,
      city: 'Canyon',
      state: 'TX',
    },
    79016: {
      lat: 34.98338,
      lng: -101.9148,
      city: 'Canyon',
      state: 'TX',
    },
    79018: {
      lat: 35.69322,
      lng: -102.33767,
      city: 'Channing',
      state: 'TX',
    },
    79019: {
      lat: 34.98099,
      lng: -101.39288,
      city: 'Claude',
      state: 'TX',
    },
    79021: {
      lat: 33.98366,
      lng: -101.99302,
      city: 'Cotton Center',
      state: 'TX',
    },
    79022: {
      lat: 36.10004,
      lng: -102.61443,
      city: 'Dalhart',
      state: 'TX',
    },
    79024: {
      lat: 36.43877,
      lng: -100.35188,
      city: 'Darrouzett',
      state: 'TX',
    },
    79025: {
      lat: 34.94943,
      lng: -102.20117,
      city: 'Dawn',
      state: 'TX',
    },
    79027: {
      lat: 34.49238,
      lng: -102.3378,
      city: 'Dimmitt',
      state: 'TX',
    },
    79029: {
      lat: 35.89513,
      lng: -102.02756,
      city: 'Dumas',
      state: 'TX',
    },
    79031: {
      lat: 34.24501,
      lng: -102.45225,
      city: 'Earth',
      state: 'TX',
    },
    79032: {
      lat: 34.2878,
      lng: -101.89492,
      city: 'Edmonson',
      state: 'TX',
    },
    79033: {
      lat: 36.30684,
      lng: -100.97661,
      city: 'Farnsworth',
      state: 'TX',
    },
    79034: {
      lat: 36.37524,
      lng: -100.16581,
      city: 'Follett',
      state: 'TX',
    },
    79035: {
      lat: 34.68301,
      lng: -102.78573,
      city: 'Friona',
      state: 'TX',
    },
    79036: {
      lat: 35.59727,
      lng: -101.54826,
      city: 'Fritch',
      state: 'TX',
    },
    79039: {
      lat: 35.25237,
      lng: -101.12206,
      city: 'Groom',
      state: 'TX',
    },
    79040: {
      lat: 36.30113,
      lng: -101.57841,
      city: 'Gruver',
      state: 'TX',
    },
    79041: {
      lat: 34.0376,
      lng: -101.93466,
      city: 'Hale Center',
      state: 'TX',
    },
    79042: {
      lat: 34.74802,
      lng: -101.89449,
      city: 'Happy',
      state: 'TX',
    },
    79043: { lat: 34.36761, lng: -102.1374, city: 'Hart', state: 'TX' },
    79044: {
      lat: 35.87456,
      lng: -102.37903,
      city: 'Hartley',
      state: 'TX',
    },
    79045: {
      lat: 34.89831,
      lng: -102.51228,
      city: 'Hereford',
      state: 'TX',
    },
    79046: {
      lat: 36.14237,
      lng: -100.10709,
      city: 'Higgins',
      state: 'TX',
    },
    79051: {
      lat: 36.45421,
      lng: -102.23423,
      city: 'Kerrick',
      state: 'TX',
    },
    79052: {
      lat: 34.35869,
      lng: -101.76276,
      city: 'Kress',
      state: 'TX',
    },
    79053: {
      lat: 34.40284,
      lng: -102.59106,
      city: 'Lazbuddie',
      state: 'TX',
    },
    79054: {
      lat: 35.42894,
      lng: -100.78086,
      city: 'Lefors',
      state: 'TX',
    },
    79056: {
      lat: 36.21724,
      lng: -100.22139,
      city: 'Lipscomb',
      state: 'TX',
    },
    79057: {
      lat: 35.20638,
      lng: -100.62157,
      city: 'Mclean',
      state: 'TX',
    },
    79058: {
      lat: 35.59509,
      lng: -101.94925,
      city: 'Masterson',
      state: 'TX',
    },
    79059: {
      lat: 35.79825,
      lng: -100.73531,
      city: 'Miami',
      state: 'TX',
    },
    79061: {
      lat: 35.51987,
      lng: -100.45706,
      city: 'Mobeetie',
      state: 'TX',
    },
    79062: {
      lat: 35.99616,
      lng: -101.52733,
      city: 'Morse',
      state: 'TX',
    },
    79063: {
      lat: 34.51063,
      lng: -102.115,
      city: 'Nazareth',
      state: 'TX',
    },
    79064: {
      lat: 34.17738,
      lng: -102.12699,
      city: 'Olton',
      state: 'TX',
    },
    79065: {
      lat: 35.52363,
      lng: -100.90378,
      city: 'Pampa',
      state: 'TX',
    },
    79066: {
      lat: 35.49929,
      lng: -101.03423,
      city: 'Pampa',
      state: 'TX',
    },
    79068: {
      lat: 35.33633,
      lng: -101.45226,
      city: 'Panhandle',
      state: 'TX',
    },
    79070: {
      lat: 36.25346,
      lng: -100.80731,
      city: 'Perryton',
      state: 'TX',
    },
    79072: {
      lat: 34.1719,
      lng: -101.745,
      city: 'Plainview',
      state: 'TX',
    },
    79073: {
      lat: 33.95608,
      lng: -101.94924,
      city: 'Plainview',
      state: 'TX',
    },
    79078: {
      lat: 35.69856,
      lng: -101.55,
      city: 'Sanford',
      state: 'TX',
    },
    79079: {
      lat: 35.20719,
      lng: -100.24884,
      city: 'Shamrock',
      state: 'TX',
    },
    79080: {
      lat: 35.70341,
      lng: -101.21116,
      city: 'Skellytown',
      state: 'TX',
    },
    79081: {
      lat: 36.21559,
      lng: -101.21054,
      city: 'Spearman',
      state: 'TX',
    },
    79082: {
      lat: 34.21881,
      lng: -102.2862,
      city: 'Springlake',
      state: 'TX',
    },
    79083: {
      lat: 35.87359,
      lng: -101.51422,
      city: 'Stinnett',
      state: 'TX',
    },
    79084: {
      lat: 36.25502,
      lng: -102.01825,
      city: 'Stratford',
      state: 'TX',
    },
    79085: {
      lat: 34.73977,
      lng: -102.50956,
      city: 'Summerfield',
      state: 'TX',
    },
    79086: {
      lat: 36.13538,
      lng: -101.79255,
      city: 'Sunray',
      state: 'TX',
    },
    79087: {
      lat: 36.32788,
      lng: -102.93667,
      city: 'Texline',
      state: 'TX',
    },
    79088: {
      lat: 34.57463,
      lng: -101.69315,
      city: 'Tulia',
      state: 'TX',
    },
    79091: {
      lat: 34.87053,
      lng: -102.11431,
      city: 'Umbarger',
      state: 'TX',
    },
    79092: {
      lat: 35.28133,
      lng: -102.48784,
      city: 'Vega',
      state: 'TX',
    },
    79093: {
      lat: 36.26637,
      lng: -101.02997,
      city: 'Waka',
      state: 'TX',
    },
    79094: {
      lat: 34.84546,
      lng: -101.52493,
      city: 'Wayside',
      state: 'TX',
    },
    79095: {
      lat: 34.88485,
      lng: -100.19561,
      city: 'Wellington',
      state: 'TX',
    },
    79096: {
      lat: 35.45133,
      lng: -100.1984,
      city: 'Wheeler',
      state: 'TX',
    },
    79097: {
      lat: 35.43104,
      lng: -101.17405,
      city: 'White Deer',
      state: 'TX',
    },
    79098: {
      lat: 35.15671,
      lng: -102.21646,
      city: 'Wildorado',
      state: 'TX',
    },
    79101: {
      lat: 35.20605,
      lng: -101.83946,
      city: 'Amarillo',
      state: 'TX',
    },
    79102: {
      lat: 35.19948,
      lng: -101.84661,
      city: 'Amarillo',
      state: 'TX',
    },
    79103: {
      lat: 35.18297,
      lng: -101.7999,
      city: 'Amarillo',
      state: 'TX',
    },
    79104: {
      lat: 35.2011,
      lng: -101.79017,
      city: 'Amarillo',
      state: 'TX',
    },
    79106: {
      lat: 35.20369,
      lng: -101.89178,
      city: 'Amarillo',
      state: 'TX',
    },
    79107: {
      lat: 35.23119,
      lng: -101.7946,
      city: 'Amarillo',
      state: 'TX',
    },
    79108: {
      lat: 35.37511,
      lng: -101.80307,
      city: 'Amarillo',
      state: 'TX',
    },
    79109: {
      lat: 35.1665,
      lng: -101.88633,
      city: 'Amarillo',
      state: 'TX',
    },
    79110: {
      lat: 35.14946,
      lng: -101.87109,
      city: 'Amarillo',
      state: 'TX',
    },
    79111: {
      lat: 35.21934,
      lng: -101.68957,
      city: 'Amarillo',
      state: 'TX',
    },
    79118: {
      lat: 35.10103,
      lng: -101.74863,
      city: 'Amarillo',
      state: 'TX',
    },
    79119: {
      lat: 35.10876,
      lng: -102.01968,
      city: 'Amarillo',
      state: 'TX',
    },
    79121: {
      lat: 35.17447,
      lng: -101.92922,
      city: 'Amarillo',
      state: 'TX',
    },
    79124: {
      lat: 35.26891,
      lng: -102.02705,
      city: 'Amarillo',
      state: 'TX',
    },
    79201: {
      lat: 34.4101,
      lng: -100.33521,
      city: 'Childress',
      state: 'TX',
    },
    79220: {
      lat: 33.7658,
      lng: -100.76614,
      city: 'Afton',
      state: 'TX',
    },
    79223: {
      lat: 34.2317,
      lng: -100.48085,
      city: 'Cee Vee',
      state: 'TX',
    },
    79225: {
      lat: 34.25058,
      lng: -99.50831,
      city: 'Chillicothe',
      state: 'TX',
    },
    79226: {
      lat: 34.91965,
      lng: -100.99229,
      city: 'Clarendon',
      state: 'TX',
    },
    79227: {
      lat: 33.87702,
      lng: -99.74893,
      city: 'Crowell',
      state: 'TX',
    },
    79229: {
      lat: 33.67379,
      lng: -100.744,
      city: 'Dickens',
      state: 'TX',
    },
    79230: {
      lat: 34.71353,
      lng: -100.04878,
      city: 'Dodson',
      state: 'TX',
    },
    79231: {
      lat: 33.94164,
      lng: -101.06579,
      city: 'Dougherty',
      state: 'TX',
    },
    79233: {
      lat: 34.50195,
      lng: -100.49165,
      city: 'Estelline',
      state: 'TX',
    },
    79234: {
      lat: 34.22108,
      lng: -100.95509,
      city: 'Flomot',
      state: 'TX',
    },
    79235: {
      lat: 33.94794,
      lng: -101.26088,
      city: 'Floydada',
      state: 'TX',
    },
    79236: {
      lat: 33.5992,
      lng: -100.28095,
      city: 'Guthrie',
      state: 'TX',
    },
    79237: {
      lat: 34.85566,
      lng: -100.66588,
      city: 'Hedley',
      state: 'TX',
    },
    79239: {
      lat: 34.64837,
      lng: -100.77015,
      city: 'Lakeview',
      state: 'TX',
    },
    79240: {
      lat: 34.8836,
      lng: -100.76797,
      city: 'Lelia Lake',
      state: 'TX',
    },
    79241: {
      lat: 34.18069,
      lng: -101.38507,
      city: 'Lockney',
      state: 'TX',
    },
    79243: {
      lat: 33.76426,
      lng: -101.02557,
      city: 'Mcadoo',
      state: 'TX',
    },
    79244: {
      lat: 34.0383,
      lng: -100.8059,
      city: 'Matador',
      state: 'TX',
    },
    79245: {
      lat: 34.68001,
      lng: -100.47552,
      city: 'Memphis',
      state: 'TX',
    },
    79247: { lat: 34.39704, lng: -99.3807, city: 'Odell', state: 'TX' },
    79248: {
      lat: 33.94146,
      lng: -100.22159,
      city: 'Paducah',
      state: 'TX',
    },
    79250: {
      lat: 33.88134,
      lng: -101.59881,
      city: 'Petersburg',
      state: 'TX',
    },
    79251: {
      lat: 34.96174,
      lng: -100.45441,
      city: 'Quail',
      state: 'TX',
    },
    79252: {
      lat: 34.31001,
      lng: -99.80874,
      city: 'Quanah',
      state: 'TX',
    },
    79255: {
      lat: 34.36113,
      lng: -101.04924,
      city: 'Quitaque',
      state: 'TX',
    },
    79256: {
      lat: 33.87463,
      lng: -100.82078,
      city: 'Roaring Springs',
      state: 'TX',
    },
    79257: {
      lat: 34.44868,
      lng: -101.2995,
      city: 'Silverton',
      state: 'TX',
    },
    79258: {
      lat: 34.24344,
      lng: -101.29647,
      city: 'South Plains',
      state: 'TX',
    },
    79259: {
      lat: 34.36243,
      lng: -100.45594,
      city: 'Tell',
      state: 'TX',
    },
    79261: {
      lat: 34.45525,
      lng: -100.86876,
      city: 'Turkey',
      state: 'TX',
    },
    79311: {
      lat: 33.85781,
      lng: -101.88228,
      city: 'Abernathy',
      state: 'TX',
    },
    79312: {
      lat: 34.06218,
      lng: -102.36794,
      city: 'Amherst',
      state: 'TX',
    },
    79313: {
      lat: 33.8126,
      lng: -102.16093,
      city: 'Anton',
      state: 'TX',
    },
    79314: {
      lat: 33.63016,
      lng: -103.03051,
      city: 'Bledsoe',
      state: 'TX',
    },
    79316: {
      lat: 33.13478,
      lng: -102.3058,
      city: 'Brownfield',
      state: 'TX',
    },
    79322: {
      lat: 33.65887,
      lng: -101.19814,
      city: 'Crosbyton',
      state: 'TX',
    },
    79323: {
      lat: 32.94678,
      lng: -102.89674,
      city: 'Denver City',
      state: 'TX',
    },
    79324: {
      lat: 33.88759,
      lng: -102.73534,
      city: 'Enochs',
      state: 'TX',
    },
    79325: {
      lat: 34.40798,
      lng: -102.93821,
      city: 'Farwell',
      state: 'TX',
    },
    79326: {
      lat: 34.09913,
      lng: -102.21433,
      city: 'Fieldton',
      state: 'TX',
    },
    79329: {
      lat: 33.72503,
      lng: -101.68807,
      city: 'Idalou',
      state: 'TX',
    },
    79330: {
      lat: 33.03072,
      lng: -101.21727,
      city: 'Justiceburg',
      state: 'TX',
    },
    79331: {
      lat: 32.66775,
      lng: -101.97488,
      city: 'Lamesa',
      state: 'TX',
    },
    79336: {
      lat: 33.59962,
      lng: -102.40214,
      city: 'Levelland',
      state: 'TX',
    },
    79339: {
      lat: 33.87938,
      lng: -102.34682,
      city: 'Littlefield',
      state: 'TX',
    },
    79342: {
      lat: 32.90313,
      lng: -102.33188,
      city: 'Loop',
      state: 'TX',
    },
    79343: {
      lat: 33.65096,
      lng: -101.54459,
      city: 'Lorenzo',
      state: 'TX',
    },
    79344: {
      lat: 33.85677,
      lng: -103.0143,
      city: 'Maple',
      state: 'TX',
    },
    79345: {
      lat: 33.33611,
      lng: -102.32789,
      city: 'Meadow',
      state: 'TX',
    },
    79346: {
      lat: 33.64805,
      lng: -102.82309,
      city: 'Morton',
      state: 'TX',
    },
    79347: {
      lat: 34.18804,
      lng: -102.79682,
      city: 'Muleshoe',
      state: 'TX',
    },
    79350: {
      lat: 33.73115,
      lng: -101.82296,
      city: 'New Deal',
      state: 'TX',
    },
    79351: {
      lat: 32.93191,
      lng: -101.78581,
      city: 'Odonnell',
      state: 'TX',
    },
    79353: { lat: 33.78723, lng: -102.58723, city: 'Pep', state: 'TX' },
    79355: {
      lat: 33.16605,
      lng: -102.89485,
      city: 'Plains',
      state: 'TX',
    },
    79356: { lat: 33.2562, lng: -101.33245, city: 'Post', state: 'TX' },
    79357: {
      lat: 33.64659,
      lng: -101.40577,
      city: 'Ralls',
      state: 'TX',
    },
    79358: {
      lat: 33.4321,
      lng: -102.20017,
      city: 'Ropesville',
      state: 'TX',
    },
    79359: {
      lat: 32.92872,
      lng: -102.54718,
      city: 'Seagraves',
      state: 'TX',
    },
    79360: {
      lat: 32.69018,
      lng: -102.72482,
      city: 'Seminole',
      state: 'TX',
    },
    79363: {
      lat: 33.70769,
      lng: -102.03804,
      city: 'Shallowater',
      state: 'TX',
    },
    79364: {
      lat: 33.44681,
      lng: -101.6355,
      city: 'Slaton',
      state: 'TX',
    },
    79366: {
      lat: 33.53016,
      lng: -101.68367,
      city: 'Ransom Canyon',
      state: 'TX',
    },
    79367: {
      lat: 33.58938,
      lng: -102.16805,
      city: 'Smyer',
      state: 'TX',
    },
    79369: {
      lat: 33.94322,
      lng: -102.15916,
      city: 'Spade',
      state: 'TX',
    },
    79370: { lat: 33.4638, lng: -100.92328, city: 'Spur', state: 'TX' },
    79371: {
      lat: 33.97942,
      lng: -102.69414,
      city: 'Sudan',
      state: 'TX',
    },
    79372: {
      lat: 33.42482,
      lng: -102.50108,
      city: 'Sundown',
      state: 'TX',
    },
    79373: {
      lat: 33.15905,
      lng: -101.83628,
      city: 'Tahoka',
      state: 'TX',
    },
    79376: {
      lat: 33.25506,
      lng: -102.64143,
      city: 'Tokio',
      state: 'TX',
    },
    79377: {
      lat: 32.9309,
      lng: -102.13653,
      city: 'Welch',
      state: 'TX',
    },
    79378: {
      lat: 33.03075,
      lng: -102.44482,
      city: 'Wellman',
      state: 'TX',
    },
    79379: {
      lat: 33.49523,
      lng: -102.65345,
      city: 'Whiteface',
      state: 'TX',
    },
    79380: {
      lat: 33.73913,
      lng: -102.32784,
      city: 'Whitharral',
      state: 'TX',
    },
    79381: {
      lat: 33.32312,
      lng: -101.76982,
      city: 'Wilson',
      state: 'TX',
    },
    79382: {
      lat: 33.46578,
      lng: -102.02282,
      city: 'Wolfforth',
      state: 'TX',
    },
    79383: {
      lat: 33.32429,
      lng: -101.90801,
      city: 'New Home',
      state: 'TX',
    },
    79401: {
      lat: 33.58683,
      lng: -101.85179,
      city: 'Lubbock',
      state: 'TX',
    },
    79402: {
      lat: 33.58124,
      lng: -101.84201,
      city: 'Lubbock',
      state: 'TX',
    },
    79403: {
      lat: 33.6433,
      lng: -101.76595,
      city: 'Lubbock',
      state: 'TX',
    },
    79404: {
      lat: 33.52651,
      lng: -101.79856,
      city: 'Lubbock',
      state: 'TX',
    },
    79406: {
      lat: 33.58336,
      lng: -101.88082,
      city: 'Lubbock',
      state: 'TX',
    },
    79407: {
      lat: 33.56566,
      lng: -102.08895,
      city: 'Lubbock',
      state: 'TX',
    },
    79410: {
      lat: 33.56962,
      lng: -101.89124,
      city: 'Lubbock',
      state: 'TX',
    },
    79411: {
      lat: 33.57012,
      lng: -101.85773,
      city: 'Lubbock',
      state: 'TX',
    },
    79412: {
      lat: 33.5464,
      lng: -101.85765,
      city: 'Lubbock',
      state: 'TX',
    },
    79413: {
      lat: 33.54576,
      lng: -101.88775,
      city: 'Lubbock',
      state: 'TX',
    },
    79414: {
      lat: 33.54862,
      lng: -101.91943,
      city: 'Lubbock',
      state: 'TX',
    },
    79415: {
      lat: 33.69863,
      lng: -101.90497,
      city: 'Lubbock',
      state: 'TX',
    },
    79416: {
      lat: 33.60473,
      lng: -101.97658,
      city: 'Lubbock',
      state: 'TX',
    },
    79423: {
      lat: 33.43376,
      lng: -101.84751,
      city: 'Lubbock',
      state: 'TX',
    },
    79424: {
      lat: 33.46865,
      lng: -101.93881,
      city: 'Lubbock',
      state: 'TX',
    },
    79501: {
      lat: 32.74332,
      lng: -99.89332,
      city: 'Anson',
      state: 'TX',
    },
    79502: {
      lat: 33.18305,
      lng: -100.26499,
      city: 'Aspermont',
      state: 'TX',
    },
    79503: {
      lat: 32.89003,
      lng: -99.69033,
      city: 'Avoca',
      state: 'TX',
    },
    79504: {
      lat: 32.31336,
      lng: -99.35271,
      city: 'Baird',
      state: 'TX',
    },
    79505: {
      lat: 33.56323,
      lng: -99.84003,
      city: 'Benjamin',
      state: 'TX',
    },
    79506: {
      lat: 32.11366,
      lng: -100.32613,
      city: 'Blackwell',
      state: 'TX',
    },
    79508: {
      lat: 32.26999,
      lng: -99.83032,
      city: 'Buffalo Gap',
      state: 'TX',
    },
    79510: {
      lat: 32.26006,
      lng: -99.52872,
      city: 'Clyde',
      state: 'TX',
    },
    79511: {
      lat: 32.42982,
      lng: -101.26699,
      city: 'Coahoma',
      state: 'TX',
    },
    79512: {
      lat: 32.32614,
      lng: -100.92807,
      city: 'Colorado City',
      state: 'TX',
    },
    79517: {
      lat: 32.87754,
      lng: -101.24144,
      city: 'Fluvanna',
      state: 'TX',
    },
    79518: {
      lat: 33.31644,
      lng: -100.74382,
      city: 'Girard',
      state: 'TX',
    },
    79519: {
      lat: 32.04926,
      lng: -99.69857,
      city: 'Goldsboro',
      state: 'TX',
    },
    79520: {
      lat: 32.87581,
      lng: -100.13012,
      city: 'Hamlin',
      state: 'TX',
    },
    79521: {
      lat: 33.12478,
      lng: -99.64061,
      city: 'Haskell',
      state: 'TX',
    },
    79525: {
      lat: 32.62745,
      lng: -99.82711,
      city: 'Hawley',
      state: 'TX',
    },
    79526: {
      lat: 32.6443,
      lng: -100.75192,
      city: 'Hermleigh',
      state: 'TX',
    },
    79527: { lat: 32.59637, lng: -101.09939, city: 'Ira', state: 'TX' },
    79528: {
      lat: 33.15936,
      lng: -100.57982,
      city: 'Jayton',
      state: 'TX',
    },
    79529: {
      lat: 33.42911,
      lng: -99.82915,
      city: 'Knox City',
      state: 'TX',
    },
    79530: { lat: 32.09981, lng: -99.78178, city: 'Lawn', state: 'TX' },
    79532: {
      lat: 32.40804,
      lng: -100.71357,
      city: 'Loraine',
      state: 'TX',
    },
    79533: {
      lat: 32.84898,
      lng: -99.54739,
      city: 'Lueders',
      state: 'TX',
    },
    79534: {
      lat: 32.75559,
      lng: -100.22273,
      city: 'McCaulley',
      state: 'TX',
    },
    79535: {
      lat: 32.2219,
      lng: -100.48421,
      city: 'Maryneal',
      state: 'TX',
    },
    79536: {
      lat: 32.47333,
      lng: -100.02859,
      city: 'Merkel',
      state: 'TX',
    },
    79537: {
      lat: 32.28033,
      lng: -100.21402,
      city: 'Nolan',
      state: 'TX',
    },
    79538: {
      lat: 31.97891,
      lng: -99.69177,
      city: 'Novice',
      state: 'TX',
    },
    79539: {
      lat: 33.36965,
      lng: -99.89207,
      city: "O'Brien",
      state: 'TX',
    },
    79540: {
      lat: 33.15173,
      lng: -100.06289,
      city: 'Old Glory',
      state: 'TX',
    },
    79541: {
      lat: 32.15018,
      lng: -99.81617,
      city: 'Ovalo',
      state: 'TX',
    },
    79543: {
      lat: 32.71864,
      lng: -100.38724,
      city: 'Roby',
      state: 'TX',
    },
    79544: {
      lat: 33.30557,
      lng: -99.86565,
      city: 'Rochester',
      state: 'TX',
    },
    79545: {
      lat: 32.41806,
      lng: -100.57753,
      city: 'Roscoe',
      state: 'TX',
    },
    79546: {
      lat: 32.8792,
      lng: -100.49136,
      city: 'Rotan',
      state: 'TX',
    },
    79547: { lat: 33.20478, lng: -99.91023, city: 'Rule', state: 'TX' },
    79548: { lat: 33.06989, lng: -99.93005, city: 'Rule', state: 'TX' },
    79549: {
      lat: 32.90152,
      lng: -100.90407,
      city: 'Snyder',
      state: 'TX',
    },
    79553: {
      lat: 32.93785,
      lng: -99.84022,
      city: 'Stamford',
      state: 'TX',
    },
    79556: {
      lat: 32.48451,
      lng: -100.39263,
      city: 'Sweetwater',
      state: 'TX',
    },
    79560: {
      lat: 32.67514,
      lng: -100.2094,
      city: 'Sylvester',
      state: 'TX',
    },
    79561: {
      lat: 32.51974,
      lng: -100.18369,
      city: 'Trent',
      state: 'TX',
    },
    79562: {
      lat: 32.24168,
      lng: -99.86766,
      city: 'Tuscola',
      state: 'TX',
    },
    79563: { lat: 32.42222, lng: -99.90929, city: 'Tye', state: 'TX' },
    79565: {
      lat: 32.37637,
      lng: -101.08415,
      city: 'Westbrook',
      state: 'TX',
    },
    79566: {
      lat: 32.15454,
      lng: -100.1098,
      city: 'Wingate',
      state: 'TX',
    },
    79567: {
      lat: 31.97678,
      lng: -99.97787,
      city: 'Winters',
      state: 'TX',
    },
    79601: {
      lat: 32.57226,
      lng: -99.64921,
      city: 'Abilene',
      state: 'TX',
    },
    79602: {
      lat: 32.33568,
      lng: -99.6699,
      city: 'Abilene',
      state: 'TX',
    },
    79603: {
      lat: 32.39904,
      lng: -99.89222,
      city: 'Abilene',
      state: 'TX',
    },
    79605: {
      lat: 32.43394,
      lng: -99.78182,
      city: 'Abilene',
      state: 'TX',
    },
    79606: {
      lat: 32.35477,
      lng: -99.83139,
      city: 'Abilene',
      state: 'TX',
    },
    79607: {
      lat: 32.42289,
      lng: -99.83904,
      city: 'Dyess Afb',
      state: 'TX',
    },
    79699: {
      lat: 32.46221,
      lng: -99.71502,
      city: 'Abilene',
      state: 'TX',
    },
    79701: {
      lat: 31.99239,
      lng: -102.08105,
      city: 'Midland',
      state: 'TX',
    },
    79703: {
      lat: 31.97998,
      lng: -102.13158,
      city: 'Midland',
      state: 'TX',
    },
    79705: {
      lat: 32.09619,
      lng: -102.0508,
      city: 'Midland',
      state: 'TX',
    },
    79706: {
      lat: 31.83622,
      lng: -101.97025,
      city: 'Midland',
      state: 'TX',
    },
    79707: {
      lat: 32.03304,
      lng: -102.21189,
      city: 'Midland',
      state: 'TX',
    },
    79713: {
      lat: 32.49861,
      lng: -101.74554,
      city: 'Ackerly',
      state: 'TX',
    },
    79714: {
      lat: 32.32947,
      lng: -102.71323,
      city: 'Andrews',
      state: 'TX',
    },
    79718: {
      lat: 30.99474,
      lng: -103.73293,
      city: 'Balmorhea',
      state: 'TX',
    },
    79719: {
      lat: 31.46042,
      lng: -103.4051,
      city: 'Barstow',
      state: 'TX',
    },
    79720: {
      lat: 32.20171,
      lng: -101.51395,
      city: 'Big Spring',
      state: 'TX',
    },
    79721: {
      lat: 32.22491,
      lng: -101.52737,
      city: 'Big Spring',
      state: 'TX',
    },
    79730: {
      lat: 31.19335,
      lng: -103.06396,
      city: 'Coyanosa',
      state: 'TX',
    },
    79731: {
      lat: 31.4477,
      lng: -102.56095,
      city: 'Crane',
      state: 'TX',
    },
    79733: {
      lat: 32.11036,
      lng: -101.36683,
      city: 'Forsan',
      state: 'TX',
    },
    79734: {
      lat: 30.68526,
      lng: -103.97911,
      city: 'Fort Davis',
      state: 'TX',
    },
    79735: {
      lat: 30.74837,
      lng: -102.85518,
      city: 'Fort Stockton',
      state: 'TX',
    },
    79738: {
      lat: 32.73625,
      lng: -101.48269,
      city: 'Gail',
      state: 'TX',
    },
    79739: {
      lat: 31.75515,
      lng: -101.50668,
      city: 'Garden City',
      state: 'TX',
    },
    79741: {
      lat: 32.00833,
      lng: -102.65675,
      city: 'Goldsmith',
      state: 'TX',
    },
    79742: {
      lat: 31.3605,
      lng: -102.86027,
      city: 'Grandfalls',
      state: 'TX',
    },
    79743: {
      lat: 31.24911,
      lng: -102.76766,
      city: 'Imperial',
      state: 'TX',
    },
    79744: {
      lat: 30.89083,
      lng: -101.91305,
      city: 'Iraan',
      state: 'TX',
    },
    79745: {
      lat: 31.85302,
      lng: -103.03325,
      city: 'Kermit',
      state: 'TX',
    },
    79748: {
      lat: 32.38784,
      lng: -101.66889,
      city: 'Knott',
      state: 'TX',
    },
    79749: {
      lat: 32.25551,
      lng: -101.83419,
      city: 'Lenorah',
      state: 'TX',
    },
    79752: {
      lat: 31.10993,
      lng: -102.19225,
      city: 'McCamey',
      state: 'TX',
    },
    79754: {
      lat: 31.81735,
      lng: -103.49163,
      city: 'Mentone',
      state: 'TX',
    },
    79755: {
      lat: 31.51298,
      lng: -101.96934,
      city: 'Midkiff',
      state: 'TX',
    },
    79756: {
      lat: 31.54626,
      lng: -102.88338,
      city: 'Monahans',
      state: 'TX',
    },
    79758: {
      lat: 32.0112,
      lng: -102.33569,
      city: 'Gardendale',
      state: 'TX',
    },
    79759: {
      lat: 31.80913,
      lng: -102.69819,
      city: 'Notrees',
      state: 'TX',
    },
    79761: {
      lat: 31.85603,
      lng: -102.3485,
      city: 'Odessa',
      state: 'TX',
    },
    79762: {
      lat: 31.92573,
      lng: -102.35502,
      city: 'Odessa',
      state: 'TX',
    },
    79763: {
      lat: 31.80766,
      lng: -102.46744,
      city: 'Odessa',
      state: 'TX',
    },
    79764: {
      lat: 31.88546,
      lng: -102.47378,
      city: 'Odessa',
      state: 'TX',
    },
    79765: {
      lat: 31.92205,
      lng: -102.29878,
      city: 'Odessa',
      state: 'TX',
    },
    79766: {
      lat: 31.71014,
      lng: -102.36185,
      city: 'Odessa',
      state: 'TX',
    },
    79769: {
      lat: 31.68212,
      lng: -102.61973,
      city: 'Odessa',
      state: 'TX',
    },
    79770: {
      lat: 31.85389,
      lng: -103.90585,
      city: 'Orla',
      state: 'TX',
    },
    79772: {
      lat: 31.39852,
      lng: -103.5867,
      city: 'Pecos',
      state: 'TX',
    },
    79776: {
      lat: 31.77513,
      lng: -102.55573,
      city: 'Penwell',
      state: 'TX',
    },
    79777: {
      lat: 31.47772,
      lng: -103.16337,
      city: 'Pyote',
      state: 'TX',
    },
    79778: {
      lat: 31.25857,
      lng: -101.97359,
      city: 'Rankin',
      state: 'TX',
    },
    79780: {
      lat: 31.03937,
      lng: -103.65754,
      city: 'Saragosa',
      state: 'TX',
    },
    79781: {
      lat: 30.60111,
      lng: -101.75348,
      city: 'Sheffield',
      state: 'TX',
    },
    79782: {
      lat: 32.18599,
      lng: -101.88823,
      city: 'Stanton',
      state: 'TX',
    },
    79783: {
      lat: 32.3321,
      lng: -102.05007,
      city: 'Tarzan',
      state: 'TX',
    },
    79785: {
      lat: 31.32135,
      lng: -103.94006,
      city: 'Toyah',
      state: 'TX',
    },
    79788: {
      lat: 31.60544,
      lng: -102.98261,
      city: 'Wickett',
      state: 'TX',
    },
    79789: { lat: 31.75047, lng: -103.1948, city: 'Wink', state: 'TX' },
    79821: {
      lat: 31.97677,
      lng: -106.59822,
      city: 'Anthony',
      state: 'TX',
    },
    79830: {
      lat: 29.89491,
      lng: -103.49925,
      city: 'Alpine',
      state: 'TX',
    },
    79832: {
      lat: 30.36316,
      lng: -103.64934,
      city: 'Alpine',
      state: 'TX',
    },
    79834: {
      lat: 29.17102,
      lng: -103.28736,
      city: 'Big Bend National Park',
      state: 'TX',
    },
    79835: {
      lat: 31.93555,
      lng: -106.59491,
      city: 'Canutillo',
      state: 'TX',
    },
    79836: {
      lat: 31.56975,
      lng: -106.2112,
      city: 'Clint',
      state: 'TX',
    },
    79837: {
      lat: 32.11472,
      lng: -105.22792,
      city: 'Dell City',
      state: 'TX',
    },
    79838: {
      lat: 31.49362,
      lng: -106.17024,
      city: 'Fabens',
      state: 'TX',
    },
    79839: {
      lat: 31.36476,
      lng: -105.68693,
      city: 'Fort Hancock',
      state: 'TX',
    },
    79842: {
      lat: 30.0151,
      lng: -103.02301,
      city: 'Marathon',
      state: 'TX',
    },
    79843: {
      lat: 30.11987,
      lng: -104.30359,
      city: 'Marfa',
      state: 'TX',
    },
    79845: {
      lat: 29.65332,
      lng: -104.39724,
      city: 'Presidio',
      state: 'TX',
    },
    79846: {
      lat: 29.41684,
      lng: -103.98559,
      city: 'Redford',
      state: 'TX',
    },
    79847: {
      lat: 31.78975,
      lng: -104.99032,
      city: 'Salt Flat',
      state: 'TX',
    },
    79848: {
      lat: 30.27747,
      lng: -102.26187,
      city: 'Sanderson',
      state: 'TX',
    },
    79849: {
      lat: 31.56449,
      lng: -106.25275,
      city: 'San Elizario',
      state: 'TX',
    },
    79851: {
      lat: 31.2687,
      lng: -105.26255,
      city: 'Sierra Blanca',
      state: 'TX',
    },
    79852: {
      lat: 29.36484,
      lng: -103.6029,
      city: 'Terlingua',
      state: 'TX',
    },
    79853: {
      lat: 31.43559,
      lng: -106.06493,
      city: 'Tornillo',
      state: 'TX',
    },
    79854: {
      lat: 30.6109,
      lng: -104.62469,
      city: 'Valentine',
      state: 'TX',
    },
    79855: {
      lat: 30.92331,
      lng: -104.85583,
      city: 'Van Horn',
      state: 'TX',
    },
    79901: {
      lat: 31.7597,
      lng: -106.48049,
      city: 'El Paso',
      state: 'TX',
    },
    79902: {
      lat: 31.78431,
      lng: -106.49768,
      city: 'El Paso',
      state: 'TX',
    },
    79903: {
      lat: 31.78622,
      lng: -106.44194,
      city: 'El Paso',
      state: 'TX',
    },
    79904: {
      lat: 31.85005,
      lng: -106.45417,
      city: 'El Paso',
      state: 'TX',
    },
    79905: {
      lat: 31.76665,
      lng: -106.42523,
      city: 'El Paso',
      state: 'TX',
    },
    79906: {
      lat: 31.80906,
      lng: -106.41954,
      city: 'El Paso',
      state: 'TX',
    },
    79907: {
      lat: 31.7074,
      lng: -106.32681,
      city: 'El Paso',
      state: 'TX',
    },
    79908: {
      lat: 31.82974,
      lng: -106.37763,
      city: 'El Paso',
      state: 'TX',
    },
    79911: {
      lat: 31.9361,
      lng: -106.53569,
      city: 'El Paso',
      state: 'TX',
    },
    79912: {
      lat: 31.84839,
      lng: -106.53272,
      city: 'El Paso',
      state: 'TX',
    },
    79915: {
      lat: 31.74593,
      lng: -106.37245,
      city: 'El Paso',
      state: 'TX',
    },
    79916: {
      lat: 31.86268,
      lng: -106.38912,
      city: 'Fort Bliss',
      state: 'TX',
    },
    79918: {
      lat: 31.84691,
      lng: -106.36936,
      city: 'Fort Bliss',
      state: 'TX',
    },
    79920: {
      lat: 31.82261,
      lng: -106.46037,
      city: 'El Paso',
      state: 'TX',
    },
    79922: {
      lat: 31.81126,
      lng: -106.55823,
      city: 'El Paso',
      state: 'TX',
    },
    79924: {
      lat: 31.90224,
      lng: -106.41355,
      city: 'El Paso',
      state: 'TX',
    },
    79925: {
      lat: 31.79851,
      lng: -106.36327,
      city: 'El Paso',
      state: 'TX',
    },
    79927: {
      lat: 31.64566,
      lng: -106.27359,
      city: 'El Paso',
      state: 'TX',
    },
    79928: {
      lat: 31.67896,
      lng: -106.11511,
      city: 'El Paso',
      state: 'TX',
    },
    79930: {
      lat: 31.81078,
      lng: -106.46846,
      city: 'El Paso',
      state: 'TX',
    },
    79932: {
      lat: 31.87753,
      lng: -106.6068,
      city: 'El Paso',
      state: 'TX',
    },
    79934: {
      lat: 31.95206,
      lng: -106.434,
      city: 'El Paso',
      state: 'TX',
    },
    79935: {
      lat: 31.76804,
      lng: -106.33029,
      city: 'El Paso',
      state: 'TX',
    },
    79936: {
      lat: 31.77373,
      lng: -106.29631,
      city: 'El Paso',
      state: 'TX',
    },
    79938: {
      lat: 31.85764,
      lng: -106.03895,
      city: 'El Paso',
      state: 'TX',
    },
    80002: {
      lat: 39.79405,
      lng: -105.10845,
      city: 'Arvada',
      state: 'CO',
    },
    80003: {
      lat: 39.827,
      lng: -105.06412,
      city: 'Arvada',
      state: 'CO',
    },
    80004: {
      lat: 39.81443,
      lng: -105.12293,
      city: 'Arvada',
      state: 'CO',
    },
    80005: {
      lat: 39.85053,
      lng: -105.13031,
      city: 'Arvada',
      state: 'CO',
    },
    80007: {
      lat: 39.8665,
      lng: -105.19875,
      city: 'Arvada',
      state: 'CO',
    },
    80010: {
      lat: 39.73979,
      lng: -104.86193,
      city: 'Aurora',
      state: 'CO',
    },
    80011: {
      lat: 39.74393,
      lng: -104.78994,
      city: 'Aurora',
      state: 'CO',
    },
    80012: {
      lat: 39.69976,
      lng: -104.83784,
      city: 'Aurora',
      state: 'CO',
    },
    80013: {
      lat: 39.66145,
      lng: -104.76577,
      city: 'Aurora',
      state: 'CO',
    },
    80014: {
      lat: 39.66353,
      lng: -104.83781,
      city: 'Aurora',
      state: 'CO',
    },
    80015: {
      lat: 39.62737,
      lng: -104.79497,
      city: 'Aurora',
      state: 'CO',
    },
    80016: {
      lat: 39.59391,
      lng: -104.72311,
      city: 'Aurora',
      state: 'CO',
    },
    80017: {
      lat: 39.69914,
      lng: -104.7735,
      city: 'Aurora',
      state: 'CO',
    },
    80018: {
      lat: 39.69071,
      lng: -104.68096,
      city: 'Aurora',
      state: 'CO',
    },
    80019: {
      lat: 39.78653,
      lng: -104.6972,
      city: 'Aurora',
      state: 'CO',
    },
    80020: {
      lat: 39.93121,
      lng: -105.07407,
      city: 'Broomfield',
      state: 'CO',
    },
    80021: {
      lat: 39.89105,
      lng: -105.11448,
      city: 'Broomfield',
      state: 'CO',
    },
    80022: {
      lat: 39.86448,
      lng: -104.78868,
      city: 'Commerce City',
      state: 'CO',
    },
    80023: {
      lat: 39.97407,
      lng: -105.01011,
      city: 'Broomfield',
      state: 'CO',
    },
    80024: {
      lat: 39.84403,
      lng: -104.91842,
      city: 'Dupont',
      state: 'CO',
    },
    80025: {
      lat: 39.92444,
      lng: -105.29226,
      city: 'Eldorado Springs',
      state: 'CO',
    },
    80026: {
      lat: 40.01188,
      lng: -105.0985,
      city: 'Lafayette',
      state: 'CO',
    },
    80027: {
      lat: 39.9508,
      lng: -105.16354,
      city: 'Louisville',
      state: 'CO',
    },
    80030: {
      lat: 39.83006,
      lng: -105.03695,
      city: 'Westminster',
      state: 'CO',
    },
    80031: {
      lat: 39.87522,
      lng: -105.04039,
      city: 'Westminster',
      state: 'CO',
    },
    80033: {
      lat: 39.77315,
      lng: -105.10222,
      city: 'Wheat Ridge',
      state: 'CO',
    },
    80045: {
      lat: 39.74702,
      lng: -104.83843,
      city: 'Aurora',
      state: 'CO',
    },
    80101: {
      lat: 39.42503,
      lng: -103.95395,
      city: 'Agate',
      state: 'CO',
    },
    80102: {
      lat: 39.73237,
      lng: -104.43874,
      city: 'Bennett',
      state: 'CO',
    },
    80103: {
      lat: 39.78035,
      lng: -104.09576,
      city: 'Byers',
      state: 'CO',
    },
    80104: {
      lat: 39.30213,
      lng: -104.81967,
      city: 'Castle Rock',
      state: 'CO',
    },
    80105: {
      lat: 39.62679,
      lng: -103.96078,
      city: 'Deer Trail',
      state: 'CO',
    },
    80106: {
      lat: 39.17646,
      lng: -104.52975,
      city: 'Elbert',
      state: 'CO',
    },
    80107: {
      lat: 39.41195,
      lng: -104.57226,
      city: 'Elizabeth',
      state: 'CO',
    },
    80108: {
      lat: 39.44441,
      lng: -104.85321,
      city: 'Castle Rock',
      state: 'CO',
    },
    80109: {
      lat: 39.36473,
      lng: -104.90056,
      city: 'Castle Rock',
      state: 'CO',
    },
    80110: {
      lat: 39.64612,
      lng: -105.00928,
      city: 'Englewood',
      state: 'CO',
    },
    80111: {
      lat: 39.6124,
      lng: -104.88025,
      city: 'Englewood',
      state: 'CO',
    },
    80112: {
      lat: 39.57288,
      lng: -104.85854,
      city: 'Englewood',
      state: 'CO',
    },
    80113: {
      lat: 39.64224,
      lng: -104.96189,
      city: 'Englewood',
      state: 'CO',
    },
    80116: {
      lat: 39.30711,
      lng: -104.71947,
      city: 'Franktown',
      state: 'CO',
    },
    80117: {
      lat: 39.37203,
      lng: -104.39978,
      city: 'Kiowa',
      state: 'CO',
    },
    80118: {
      lat: 39.19132,
      lng: -104.8848,
      city: 'Larkspur',
      state: 'CO',
    },
    80120: {
      lat: 39.5929,
      lng: -105.00949,
      city: 'Littleton',
      state: 'CO',
    },
    80121: {
      lat: 39.61045,
      lng: -104.95421,
      city: 'Littleton',
      state: 'CO',
    },
    80122: {
      lat: 39.5806,
      lng: -104.95573,
      city: 'Littleton',
      state: 'CO',
    },
    80123: {
      lat: 39.61578,
      lng: -105.0692,
      city: 'Littleton',
      state: 'CO',
    },
    80124: {
      lat: 39.53197,
      lng: -104.89173,
      city: 'Lone Tree',
      state: 'CO',
    },
    80125: {
      lat: 39.48546,
      lng: -105.05737,
      city: 'Littleton',
      state: 'CO',
    },
    80126: {
      lat: 39.54127,
      lng: -104.96251,
      city: 'Littleton',
      state: 'CO',
    },
    80127: {
      lat: 39.52249,
      lng: -105.15773,
      city: 'Littleton',
      state: 'CO',
    },
    80128: {
      lat: 39.56167,
      lng: -105.07914,
      city: 'Littleton',
      state: 'CO',
    },
    80129: {
      lat: 39.54521,
      lng: -105.01203,
      city: 'Littleton',
      state: 'CO',
    },
    80130: {
      lat: 39.52918,
      lng: -104.92448,
      city: 'Littleton',
      state: 'CO',
    },
    80131: {
      lat: 39.47627,
      lng: -105.00899,
      city: 'Louviers',
      state: 'CO',
    },
    80132: {
      lat: 39.09183,
      lng: -104.8576,
      city: 'Monument',
      state: 'CO',
    },
    80133: {
      lat: 39.1155,
      lng: -104.90443,
      city: 'Palmer Lake',
      state: 'CO',
    },
    80134: {
      lat: 39.48282,
      lng: -104.77957,
      city: 'Parker',
      state: 'CO',
    },
    80135: {
      lat: 39.27763,
      lng: -105.13494,
      city: 'Sedalia',
      state: 'CO',
    },
    80136: {
      lat: 39.80729,
      lng: -104.29613,
      city: 'Strasburg',
      state: 'CO',
    },
    80137: {
      lat: 39.70901,
      lng: -104.59235,
      city: 'Watkins',
      state: 'CO',
    },
    80138: {
      lat: 39.51649,
      lng: -104.67114,
      city: 'Parker',
      state: 'CO',
    },
    80202: {
      lat: 39.75156,
      lng: -104.99768,
      city: 'Denver',
      state: 'CO',
    },
    80203: {
      lat: 39.73142,
      lng: -104.98274,
      city: 'Denver',
      state: 'CO',
    },
    80204: {
      lat: 39.73476,
      lng: -105.0207,
      city: 'Denver',
      state: 'CO',
    },
    80205: {
      lat: 39.75891,
      lng: -104.96353,
      city: 'Denver',
      state: 'CO',
    },
    80206: {
      lat: 39.73041,
      lng: -104.95258,
      city: 'Denver',
      state: 'CO',
    },
    80207: {
      lat: 39.76137,
      lng: -104.91674,
      city: 'Denver',
      state: 'CO',
    },
    80209: {
      lat: 39.70608,
      lng: -104.9656,
      city: 'Denver',
      state: 'CO',
    },
    80210: {
      lat: 39.6781,
      lng: -104.96275,
      city: 'Denver',
      state: 'CO',
    },
    80211: {
      lat: 39.76715,
      lng: -105.02006,
      city: 'Denver',
      state: 'CO',
    },
    80212: {
      lat: 39.77174,
      lng: -105.04819,
      city: 'Denver',
      state: 'CO',
    },
    80214: {
      lat: 39.74205,
      lng: -105.07123,
      city: 'Denver',
      state: 'CO',
    },
    80215: {
      lat: 39.74412,
      lng: -105.1158,
      city: 'Denver',
      state: 'CO',
    },
    80216: {
      lat: 39.78659,
      lng: -104.96187,
      city: 'Denver',
      state: 'CO',
    },
    80218: {
      lat: 39.73119,
      lng: -104.97073,
      city: 'Denver',
      state: 'CO',
    },
    80219: {
      lat: 39.69496,
      lng: -105.03432,
      city: 'Denver',
      state: 'CO',
    },
    80220: {
      lat: 39.73375,
      lng: -104.91648,
      city: 'Denver',
      state: 'CO',
    },
    80221: {
      lat: 39.81555,
      lng: -105.0103,
      city: 'Denver',
      state: 'CO',
    },
    80222: {
      lat: 39.6713,
      lng: -104.92828,
      city: 'Denver',
      state: 'CO',
    },
    80223: {
      lat: 39.69646,
      lng: -105.00216,
      city: 'Denver',
      state: 'CO',
    },
    80224: {
      lat: 39.68803,
      lng: -104.91161,
      city: 'Denver',
      state: 'CO',
    },
    80226: {
      lat: 39.71081,
      lng: -105.09155,
      city: 'Denver',
      state: 'CO',
    },
    80227: {
      lat: 39.66331,
      lng: -105.10536,
      city: 'Denver',
      state: 'CO',
    },
    80228: {
      lat: 39.69186,
      lng: -105.15726,
      city: 'Denver',
      state: 'CO',
    },
    80229: {
      lat: 39.85472,
      lng: -104.95867,
      city: 'Denver',
      state: 'CO',
    },
    80230: {
      lat: 39.72032,
      lng: -104.88933,
      city: 'Denver',
      state: 'CO',
    },
    80231: {
      lat: 39.67123,
      lng: -104.88787,
      city: 'Denver',
      state: 'CO',
    },
    80232: {
      lat: 39.68892,
      lng: -105.08983,
      city: 'Denver',
      state: 'CO',
    },
    80233: {
      lat: 39.89937,
      lng: -104.94697,
      city: 'Denver',
      state: 'CO',
    },
    80234: {
      lat: 39.91208,
      lng: -105.00526,
      city: 'Denver',
      state: 'CO',
    },
    80235: {
      lat: 39.64615,
      lng: -105.08909,
      city: 'Denver',
      state: 'CO',
    },
    80236: {
      lat: 39.65169,
      lng: -105.03966,
      city: 'Denver',
      state: 'CO',
    },
    80237: {
      lat: 39.63996,
      lng: -104.90178,
      city: 'Denver',
      state: 'CO',
    },
    80238: {
      lat: 39.77722,
      lng: -104.8808,
      city: 'Denver',
      state: 'CO',
    },
    80239: {
      lat: 39.78801,
      lng: -104.8333,
      city: 'Denver',
      state: 'CO',
    },
    80241: {
      lat: 39.92876,
      lng: -104.95492,
      city: 'Thornton',
      state: 'CO',
    },
    80246: {
      lat: 39.70461,
      lng: -104.9314,
      city: 'Denver',
      state: 'CO',
    },
    80247: {
      lat: 39.69684,
      lng: -104.88127,
      city: 'Denver',
      state: 'CO',
    },
    80249: {
      lat: 39.84964,
      lng: -104.69496,
      city: 'Denver',
      state: 'CO',
    },
    80260: {
      lat: 39.86705,
      lng: -105.00569,
      city: 'Denver',
      state: 'CO',
    },
    80264: {
      lat: 39.74248,
      lng: -104.98548,
      city: 'Denver',
      state: 'CO',
    },
    80266: {
      lat: 39.79002,
      lng: -104.90021,
      city: 'Denver',
      state: 'CO',
    },
    80290: {
      lat: 39.74411,
      lng: -104.98675,
      city: 'Denver',
      state: 'CO',
    },
    80293: {
      lat: 39.74608,
      lng: -104.98972,
      city: 'Denver',
      state: 'CO',
    },
    80294: {
      lat: 39.74979,
      lng: -104.98971,
      city: 'Denver',
      state: 'CO',
    },
    80301: {
      lat: 40.04795,
      lng: -105.19876,
      city: 'Boulder',
      state: 'CO',
    },
    80302: {
      lat: 40.0306,
      lng: -105.35542,
      city: 'Boulder',
      state: 'CO',
    },
    80303: {
      lat: 39.97422,
      lng: -105.21206,
      city: 'Boulder',
      state: 'CO',
    },
    80304: {
      lat: 40.04557,
      lng: -105.29006,
      city: 'Boulder',
      state: 'CO',
    },
    80305: {
      lat: 39.97733,
      lng: -105.24877,
      city: 'Boulder',
      state: 'CO',
    },
    80310: {
      lat: 40.00465,
      lng: -105.26254,
      city: 'Boulder',
      state: 'CO',
    },
    80401: {
      lat: 39.71672,
      lng: -105.23286,
      city: 'Golden',
      state: 'CO',
    },
    80403: {
      lat: 39.83229,
      lng: -105.32013,
      city: 'Golden',
      state: 'CO',
    },
    80419: {
      lat: 39.7284,
      lng: -105.20239,
      city: 'Golden',
      state: 'CO',
    },
    80420: {
      lat: 39.31451,
      lng: -106.10553,
      city: 'Alma',
      state: 'CO',
    },
    80421: {
      lat: 39.48166,
      lng: -105.50462,
      city: 'Bailey',
      state: 'CO',
    },
    80422: {
      lat: 39.8635,
      lng: -105.53846,
      city: 'Black Hawk',
      state: 'CO',
    },
    80423: {
      lat: 39.84776,
      lng: -106.55359,
      city: 'Bond',
      state: 'CO',
    },
    80424: {
      lat: 39.47089,
      lng: -106.01685,
      city: 'Breckenridge',
      state: 'CO',
    },
    80425: {
      lat: 39.34714,
      lng: -105.23162,
      city: 'Buffalo Creek',
      state: 'CO',
    },
    80426: {
      lat: 39.87393,
      lng: -106.98452,
      city: 'Burns',
      state: 'CO',
    },
    80427: {
      lat: 39.79366,
      lng: -105.52413,
      city: 'Central City',
      state: 'CO',
    },
    80428: {
      lat: 40.85326,
      lng: -106.91373,
      city: 'Clark',
      state: 'CO',
    },
    80430: {
      lat: 40.50925,
      lng: -106.44688,
      city: 'Coalmont',
      state: 'CO',
    },
    80432: {
      lat: 39.22256,
      lng: -105.82969,
      city: 'Como',
      state: 'CO',
    },
    80433: {
      lat: 39.49636,
      lng: -105.29586,
      city: 'Conifer',
      state: 'CO',
    },
    80434: {
      lat: 40.91209,
      lng: -106.28228,
      city: 'Cowdrey',
      state: 'CO',
    },
    80435: {
      lat: 39.60161,
      lng: -105.92452,
      city: 'Dillon',
      state: 'CO',
    },
    80436: {
      lat: 39.78203,
      lng: -105.64349,
      city: 'Dumont',
      state: 'CO',
    },
    80438: {
      lat: 39.7602,
      lng: -105.78356,
      city: 'Empire',
      state: 'CO',
    },
    80439: {
      lat: 39.6343,
      lng: -105.42994,
      city: 'Evergreen',
      state: 'CO',
    },
    80440: {
      lat: 39.13412,
      lng: -106.04137,
      city: 'Fairplay',
      state: 'CO',
    },
    80442: {
      lat: 39.91633,
      lng: -105.89813,
      city: 'Fraser',
      state: 'CO',
    },
    80443: {
      lat: 39.50165,
      lng: -106.16673,
      city: 'Frisco',
      state: 'CO',
    },
    80444: {
      lat: 39.63138,
      lng: -105.73078,
      city: 'Georgetown',
      state: 'CO',
    },
    80446: {
      lat: 40.16335,
      lng: -105.937,
      city: 'Granby',
      state: 'CO',
    },
    80447: {
      lat: 40.30116,
      lng: -105.81712,
      city: 'Grand Lake',
      state: 'CO',
    },
    80448: {
      lat: 39.41769,
      lng: -105.63747,
      city: 'Grant',
      state: 'CO',
    },
    80449: {
      lat: 38.93059,
      lng: -105.79169,
      city: 'Hartsel',
      state: 'CO',
    },
    80451: {
      lat: 40.04007,
      lng: -106.04456,
      city: 'Hot Sulphur Springs',
      state: 'CO',
    },
    80452: {
      lat: 39.70969,
      lng: -105.59515,
      city: 'Idaho Springs',
      state: 'CO',
    },
    80453: {
      lat: 39.66477,
      lng: -105.24285,
      city: 'Idledale',
      state: 'CO',
    },
    80454: {
      lat: 39.63109,
      lng: -105.25204,
      city: 'Indian Hills',
      state: 'CO',
    },
    80455: {
      lat: 40.11071,
      lng: -105.39071,
      city: 'Jamestown',
      state: 'CO',
    },
    80456: {
      lat: 39.30643,
      lng: -105.69211,
      city: 'Jefferson',
      state: 'CO',
    },
    80457: {
      lat: 39.65091,
      lng: -105.29972,
      city: 'Kittredge',
      state: 'CO',
    },
    80459: {
      lat: 40.1843,
      lng: -106.41877,
      city: 'Kremmling',
      state: 'CO',
    },
    80461: {
      lat: 39.24244,
      lng: -106.29016,
      city: 'Leadville',
      state: 'CO',
    },
    80463: {
      lat: 39.95523,
      lng: -106.73888,
      city: 'McCoy',
      state: 'CO',
    },
    80465: {
      lat: 39.60437,
      lng: -105.21521,
      city: 'Morrison',
      state: 'CO',
    },
    80466: {
      lat: 39.99008,
      lng: -105.546,
      city: 'Nederland',
      state: 'CO',
    },
    80467: {
      lat: 40.25692,
      lng: -106.87326,
      city: 'Oak Creek',
      state: 'CO',
    },
    80468: {
      lat: 39.91447,
      lng: -106.10979,
      city: 'Parshall',
      state: 'CO',
    },
    80469: {
      lat: 40.21448,
      lng: -106.96797,
      city: 'Phippsburg',
      state: 'CO',
    },
    80470: {
      lat: 39.40104,
      lng: -105.34527,
      city: 'Pine',
      state: 'CO',
    },
    80471: {
      lat: 39.93507,
      lng: -105.42048,
      city: 'Pinecliffe',
      state: 'CO',
    },
    80473: { lat: 40.45096, lng: -106.1555, city: 'Rand', state: 'CO' },
    80474: {
      lat: 39.92353,
      lng: -105.56404,
      city: 'Rollinsville',
      state: 'CO',
    },
    80475: {
      lat: 39.43012,
      lng: -105.59234,
      city: 'Shawnee',
      state: 'CO',
    },
    80476: {
      lat: 39.68522,
      lng: -105.80972,
      city: 'Silver Plume',
      state: 'CO',
    },
    80478: {
      lat: 40.02218,
      lng: -105.85693,
      city: 'Tabernash',
      state: 'CO',
    },
    80479: {
      lat: 40.02078,
      lng: -106.86356,
      city: 'Toponas',
      state: 'CO',
    },
    80480: {
      lat: 40.67188,
      lng: -106.34175,
      city: 'Walden',
      state: 'CO',
    },
    80481: {
      lat: 40.10351,
      lng: -105.52253,
      city: 'Ward',
      state: 'CO',
    },
    80482: {
      lat: 39.90429,
      lng: -105.78404,
      city: 'Winter Park',
      state: 'CO',
    },
    80483: {
      lat: 40.12482,
      lng: -106.92124,
      city: 'Yampa',
      state: 'CO',
    },
    80487: {
      lat: 40.58803,
      lng: -106.89321,
      city: 'Steamboat Springs',
      state: 'CO',
    },
    80498: {
      lat: 39.7794,
      lng: -106.22338,
      city: 'Silverthorne',
      state: 'CO',
    },
    80501: {
      lat: 40.16479,
      lng: -105.10208,
      city: 'Longmont',
      state: 'CO',
    },
    80503: {
      lat: 40.16922,
      lng: -105.20725,
      city: 'Longmont',
      state: 'CO',
    },
    80504: {
      lat: 40.16523,
      lng: -105.03029,
      city: 'Longmont',
      state: 'CO',
    },
    80510: {
      lat: 40.20964,
      lng: -105.57878,
      city: 'Allenspark',
      state: 'CO',
    },
    80512: {
      lat: 40.62989,
      lng: -105.56828,
      city: 'Bellvue',
      state: 'CO',
    },
    80513: {
      lat: 40.29789,
      lng: -105.10913,
      city: 'Berthoud',
      state: 'CO',
    },
    80514: {
      lat: 40.07152,
      lng: -104.95401,
      city: 'Dacono',
      state: 'CO',
    },
    80515: {
      lat: 40.45659,
      lng: -105.37726,
      city: 'Drake',
      state: 'CO',
    },
    80516: {
      lat: 40.05333,
      lng: -105.01869,
      city: 'Erie',
      state: 'CO',
    },
    80517: {
      lat: 40.39731,
      lng: -105.61165,
      city: 'Estes Park',
      state: 'CO',
    },
    80520: {
      lat: 40.11387,
      lng: -104.93347,
      city: 'Firestone',
      state: 'CO',
    },
    80521: {
      lat: 40.59372,
      lng: -105.1268,
      city: 'Fort Collins',
      state: 'CO',
    },
    80524: {
      lat: 40.64814,
      lng: -105.02155,
      city: 'Fort Collins',
      state: 'CO',
    },
    80525: {
      lat: 40.52795,
      lng: -105.04249,
      city: 'Fort Collins',
      state: 'CO',
    },
    80526: {
      lat: 40.5174,
      lng: -105.13262,
      city: 'Fort Collins',
      state: 'CO',
    },
    80528: {
      lat: 40.49343,
      lng: -105.00075,
      city: 'Fort Collins',
      state: 'CO',
    },
    80530: {
      lat: 40.10153,
      lng: -104.92302,
      city: 'Frederick',
      state: 'CO',
    },
    80532: {
      lat: 40.4775,
      lng: -105.46048,
      city: 'Glen Haven',
      state: 'CO',
    },
    80534: {
      lat: 40.33192,
      lng: -104.93522,
      city: 'Johnstown',
      state: 'CO',
    },
    80535: {
      lat: 40.73718,
      lng: -105.19292,
      city: 'Laporte',
      state: 'CO',
    },
    80536: {
      lat: 40.87458,
      lng: -105.38842,
      city: 'Livermore',
      state: 'CO',
    },
    80537: {
      lat: 40.38304,
      lng: -105.20277,
      city: 'Loveland',
      state: 'CO',
    },
    80538: {
      lat: 40.48174,
      lng: -105.16825,
      city: 'Loveland',
      state: 'CO',
    },
    80540: {
      lat: 40.23186,
      lng: -105.39341,
      city: 'Lyons',
      state: 'CO',
    },
    80542: {
      lat: 40.23431,
      lng: -104.99882,
      city: 'Mead',
      state: 'CO',
    },
    80543: {
      lat: 40.34645,
      lng: -104.8532,
      city: 'Milliken',
      state: 'CO',
    },
    80544: {
      lat: 40.10305,
      lng: -105.17178,
      city: 'Niwot',
      state: 'CO',
    },
    80545: {
      lat: 40.85911,
      lng: -105.69644,
      city: 'Red Feather Lakes',
      state: 'CO',
    },
    80546: {
      lat: 40.52415,
      lng: -104.84986,
      city: 'Severance',
      state: 'CO',
    },
    80547: {
      lat: 40.52948,
      lng: -104.96347,
      city: 'Timnath',
      state: 'CO',
    },
    80549: {
      lat: 40.84341,
      lng: -105.05499,
      city: 'Wellington',
      state: 'CO',
    },
    80550: {
      lat: 40.48213,
      lng: -104.90223,
      city: 'Windsor',
      state: 'CO',
    },
    80601: {
      lat: 39.96288,
      lng: -104.80777,
      city: 'Brighton',
      state: 'CO',
    },
    80602: {
      lat: 39.96369,
      lng: -104.90661,
      city: 'Brighton',
      state: 'CO',
    },
    80603: {
      lat: 39.9845,
      lng: -104.74539,
      city: 'Brighton',
      state: 'CO',
    },
    80610: {
      lat: 40.66996,
      lng: -104.58269,
      city: 'Ault',
      state: 'CO',
    },
    80611: {
      lat: 40.62015,
      lng: -104.24964,
      city: 'Briggsdale',
      state: 'CO',
    },
    80612: {
      lat: 40.89924,
      lng: -104.82287,
      city: 'Carr',
      state: 'CO',
    },
    80615: {
      lat: 40.5444,
      lng: -104.64236,
      city: 'Eaton',
      state: 'CO',
    },
    80620: {
      lat: 40.37425,
      lng: -104.71337,
      city: 'Evans',
      state: 'CO',
    },
    80621: {
      lat: 40.10732,
      lng: -104.79841,
      city: 'Fort Lupton',
      state: 'CO',
    },
    80622: {
      lat: 40.54235,
      lng: -104.44732,
      city: 'Galeton',
      state: 'CO',
    },
    80623: {
      lat: 40.28531,
      lng: -104.78253,
      city: 'Gilcrest',
      state: 'CO',
    },
    80624: {
      lat: 40.45683,
      lng: -104.45635,
      city: 'Gill',
      state: 'CO',
    },
    80631: {
      lat: 40.44216,
      lng: -104.67809,
      city: 'Greeley',
      state: 'CO',
    },
    80634: {
      lat: 40.40075,
      lng: -104.79222,
      city: 'Greeley',
      state: 'CO',
    },
    80640: {
      lat: 39.88536,
      lng: -104.88202,
      city: 'Henderson',
      state: 'CO',
    },
    80642: {
      lat: 40.05353,
      lng: -104.61565,
      city: 'Hudson',
      state: 'CO',
    },
    80643: {
      lat: 40.11186,
      lng: -104.47164,
      city: 'Keenesburg',
      state: 'CO',
    },
    80644: {
      lat: 40.3371,
      lng: -104.43866,
      city: 'Kersey',
      state: 'CO',
    },
    80645: {
      lat: 40.28409,
      lng: -104.64731,
      city: 'La Salle',
      state: 'CO',
    },
    80646: {
      lat: 40.47537,
      lng: -104.69761,
      city: 'Lucerne',
      state: 'CO',
    },
    80648: {
      lat: 40.76559,
      lng: -104.72816,
      city: 'Nunn',
      state: 'CO',
    },
    80649: {
      lat: 40.37924,
      lng: -104.17595,
      city: 'Orchard',
      state: 'CO',
    },
    80650: {
      lat: 40.6484,
      lng: -104.77666,
      city: 'Pierce',
      state: 'CO',
    },
    80651: {
      lat: 40.23717,
      lng: -104.81409,
      city: 'Platteville',
      state: 'CO',
    },
    80652: {
      lat: 40.11048,
      lng: -104.2751,
      city: 'Roggen',
      state: 'CO',
    },
    80653: {
      lat: 40.3931,
      lng: -103.97685,
      city: 'Weldona',
      state: 'CO',
    },
    80654: {
      lat: 40.13501,
      lng: -104.07642,
      city: 'Wiggins',
      state: 'CO',
    },
    80701: {
      lat: 40.13494,
      lng: -103.81102,
      city: 'Fort Morgan',
      state: 'CO',
    },
    80705: {
      lat: 40.27116,
      lng: -103.83428,
      city: 'Log Lane Village',
      state: 'CO',
    },
    80720: {
      lat: 40.07457,
      lng: -103.18878,
      city: 'Akron',
      state: 'CO',
    },
    80721: {
      lat: 40.68821,
      lng: -102.15297,
      city: 'Amherst',
      state: 'CO',
    },
    80722: {
      lat: 40.49515,
      lng: -103.23833,
      city: 'Atwood',
      state: 'CO',
    },
    80723: {
      lat: 40.15981,
      lng: -103.55046,
      city: 'Brush',
      state: 'CO',
    },
    80726: {
      lat: 40.91044,
      lng: -102.7773,
      city: 'Crook',
      state: 'CO',
    },
    80727: {
      lat: 40.02185,
      lng: -102.51484,
      city: 'Eckley',
      state: 'CO',
    },
    80728: {
      lat: 40.64,
      lng: -102.87285,
      city: 'Fleming',
      state: 'CO',
    },
    80729: {
      lat: 40.87439,
      lng: -104.22632,
      city: 'Grover',
      state: 'CO',
    },
    80731: {
      lat: 40.58734,
      lng: -102.60558,
      city: 'Haxtun',
      state: 'CO',
    },
    80732: {
      lat: 40.9884,
      lng: -104.30525,
      city: 'Hereford',
      state: 'CO',
    },
    80733: {
      lat: 40.35695,
      lng: -103.47989,
      city: 'Hillrose',
      state: 'CO',
    },
    80734: {
      lat: 40.52472,
      lng: -102.29259,
      city: 'Holyoke',
      state: 'CO',
    },
    80735: {
      lat: 39.71549,
      lng: -102.25029,
      city: 'Idalia',
      state: 'CO',
    },
    80736: {
      lat: 40.81635,
      lng: -103.01907,
      city: 'Iliff',
      state: 'CO',
    },
    80737: {
      lat: 40.88974,
      lng: -102.19267,
      city: 'Julesburg',
      state: 'CO',
    },
    80740: {
      lat: 39.74256,
      lng: -103.38744,
      city: 'Lindon',
      state: 'CO',
    },
    80741: {
      lat: 40.5598,
      lng: -103.47339,
      city: 'Merino',
      state: 'CO',
    },
    80742: {
      lat: 40.74855,
      lng: -103.81371,
      city: 'New Raymer',
      state: 'CO',
    },
    80743: {
      lat: 40.21588,
      lng: -102.95679,
      city: 'Otis',
      state: 'CO',
    },
    80744: {
      lat: 40.87471,
      lng: -102.38355,
      city: 'Ovid',
      state: 'CO',
    },
    80745: {
      lat: 40.88801,
      lng: -103.40077,
      city: 'Padroni',
      state: 'CO',
    },
    80746: {
      lat: 40.60706,
      lng: -102.45695,
      city: 'Paoli',
      state: 'CO',
    },
    80747: {
      lat: 40.94709,
      lng: -103.16392,
      city: 'Peetz',
      state: 'CO',
    },
    80749: {
      lat: 40.87574,
      lng: -102.55354,
      city: 'Sedgwick',
      state: 'CO',
    },
    80750: {
      lat: 40.41144,
      lng: -103.60681,
      city: 'Snyder',
      state: 'CO',
    },
    80751: {
      lat: 40.65263,
      lng: -103.24504,
      city: 'Sterling',
      state: 'CO',
    },
    80754: {
      lat: 40.69052,
      lng: -103.64694,
      city: 'Stoneham',
      state: 'CO',
    },
    80755: {
      lat: 39.89966,
      lng: -102.35155,
      city: 'Vernon',
      state: 'CO',
    },
    80757: {
      lat: 39.79244,
      lng: -103.58588,
      city: 'Woodrow',
      state: 'CO',
    },
    80758: { lat: 40.11998, lng: -102.2122, city: 'Wray', state: 'CO' },
    80759: {
      lat: 40.10892,
      lng: -102.68759,
      city: 'Yuma',
      state: 'CO',
    },
    80801: {
      lat: 39.73943,
      lng: -103.11354,
      city: 'Anton',
      state: 'CO',
    },
    80802: {
      lat: 38.84317,
      lng: -102.16393,
      city: 'Arapahoe',
      state: 'CO',
    },
    80804: {
      lat: 39.35644,
      lng: -103.26331,
      city: 'Arriba',
      state: 'CO',
    },
    80805: {
      lat: 39.29378,
      lng: -102.44923,
      city: 'Bethune',
      state: 'CO',
    },
    80807: {
      lat: 39.31575,
      lng: -102.23303,
      city: 'Burlington',
      state: 'CO',
    },
    80808: {
      lat: 39.00043,
      lng: -104.30638,
      city: 'Calhan',
      state: 'CO',
    },
    80809: {
      lat: 38.86503,
      lng: -104.99733,
      city: 'Cascade',
      state: 'CO',
    },
    80810: {
      lat: 38.82589,
      lng: -102.4454,
      city: 'Cheyenne Wells',
      state: 'CO',
    },
    80812: {
      lat: 39.67901,
      lng: -102.89161,
      city: 'Cope',
      state: 'CO',
    },
    80813: {
      lat: 38.76307,
      lng: -105.09278,
      city: 'Cripple Creek',
      state: 'CO',
    },
    80814: {
      lat: 38.94731,
      lng: -105.17604,
      city: 'Divide',
      state: 'CO',
    },
    80815: {
      lat: 39.40174,
      lng: -103.08191,
      city: 'Flagler',
      state: 'CO',
    },
    80816: {
      lat: 38.85162,
      lng: -105.31591,
      city: 'Florissant',
      state: 'CO',
    },
    80817: {
      lat: 38.58347,
      lng: -104.67412,
      city: 'Fountain',
      state: 'CO',
    },
    80818: {
      lat: 39.41539,
      lng: -103.48119,
      city: 'Genoa',
      state: 'CO',
    },
    80819: {
      lat: 38.94076,
      lng: -105.00249,
      city: 'Green Mountain Falls',
      state: 'CO',
    },
    80820: {
      lat: 38.77781,
      lng: -105.56613,
      city: 'Guffey',
      state: 'CO',
    },
    80821: {
      lat: 38.97366,
      lng: -103.43248,
      city: 'Hugo',
      state: 'CO',
    },
    80822: {
      lat: 39.67088,
      lng: -102.67815,
      city: 'Joes',
      state: 'CO',
    },
    80823: {
      lat: 38.69026,
      lng: -103.46738,
      city: 'Karval',
      state: 'CO',
    },
    80824: {
      lat: 39.66302,
      lng: -102.49943,
      city: 'Kirk',
      state: 'CO',
    },
    80825: {
      lat: 38.81864,
      lng: -102.80624,
      city: 'Kit Carson',
      state: 'CO',
    },
    80826: {
      lat: 39.21874,
      lng: -103.69129,
      city: 'Limon',
      state: 'CO',
    },
    80827: {
      lat: 39.05821,
      lng: -105.46272,
      city: 'Lake George',
      state: 'CO',
    },
    80828: {
      lat: 39.26643,
      lng: -103.70983,
      city: 'Limon',
      state: 'CO',
    },
    80829: {
      lat: 38.82922,
      lng: -104.93289,
      city: 'Manitou Springs',
      state: 'CO',
    },
    80830: {
      lat: 39.11313,
      lng: -103.91139,
      city: 'Matheson',
      state: 'CO',
    },
    80831: {
      lat: 38.98867,
      lng: -104.51172,
      city: 'Peyton',
      state: 'CO',
    },
    80832: {
      lat: 39.06344,
      lng: -104.02769,
      city: 'Ramah',
      state: 'CO',
    },
    80833: {
      lat: 38.73874,
      lng: -103.93699,
      city: 'Rush',
      state: 'CO',
    },
    80834: {
      lat: 39.29735,
      lng: -102.88617,
      city: 'Seibert',
      state: 'CO',
    },
    80835: {
      lat: 39.20933,
      lng: -104.07223,
      city: 'Simla',
      state: 'CO',
    },
    80836: {
      lat: 39.30518,
      lng: -102.58239,
      city: 'Stratton',
      state: 'CO',
    },
    80840: {
      lat: 39.00041,
      lng: -104.85446,
      city: 'Usaf Academy',
      state: 'CO',
    },
    80860: {
      lat: 38.69307,
      lng: -105.09876,
      city: 'Victor',
      state: 'CO',
    },
    80861: {
      lat: 39.32645,
      lng: -102.74844,
      city: 'Vona',
      state: 'CO',
    },
    80862: {
      lat: 38.90926,
      lng: -103.06782,
      city: 'Wild Horse',
      state: 'CO',
    },
    80863: {
      lat: 39.03004,
      lng: -105.07132,
      city: 'Woodland Park',
      state: 'CO',
    },
    80864: {
      lat: 38.69923,
      lng: -104.18757,
      city: 'Yoder',
      state: 'CO',
    },
    80902: {
      lat: 38.68683,
      lng: -104.8127,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80903: {
      lat: 38.83249,
      lng: -104.81342,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80904: {
      lat: 38.86321,
      lng: -104.87197,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80905: {
      lat: 38.81855,
      lng: -104.83649,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80906: {
      lat: 38.76744,
      lng: -104.87023,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80907: {
      lat: 38.87912,
      lng: -104.82137,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80908: {
      lat: 39.0463,
      lng: -104.69094,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80909: {
      lat: 38.85255,
      lng: -104.77434,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80910: {
      lat: 38.81178,
      lng: -104.7743,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80911: {
      lat: 38.75325,
      lng: -104.71955,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80913: {
      lat: 38.70005,
      lng: -104.76268,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80914: {
      lat: 38.82401,
      lng: -104.70546,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80915: {
      lat: 38.85271,
      lng: -104.71652,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80916: {
      lat: 38.80327,
      lng: -104.71208,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80917: {
      lat: 38.88684,
      lng: -104.74049,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80918: {
      lat: 38.91156,
      lng: -104.78032,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80919: {
      lat: 38.92797,
      lng: -104.88025,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80920: {
      lat: 38.95579,
      lng: -104.77191,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80921: {
      lat: 39.02648,
      lng: -104.88156,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80922: {
      lat: 38.88996,
      lng: -104.70084,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80923: {
      lat: 38.92673,
      lng: -104.71433,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80924: {
      lat: 38.96727,
      lng: -104.72199,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80925: {
      lat: 38.76156,
      lng: -104.64441,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80926: {
      lat: 38.64318,
      lng: -104.92439,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80927: {
      lat: 38.92833,
      lng: -104.65878,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80928: {
      lat: 38.64517,
      lng: -104.44268,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80929: {
      lat: 38.82453,
      lng: -104.60833,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80930: {
      lat: 38.80507,
      lng: -104.50644,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80938: {
      lat: 38.90157,
      lng: -104.65926,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80939: {
      lat: 38.87772,
      lng: -104.67742,
      city: 'Colorado Springs',
      state: 'CO',
    },
    80951: {
      lat: 38.88396,
      lng: -104.6558,
      city: 'Colorado Springs',
      state: 'CO',
    },
    81001: {
      lat: 38.29525,
      lng: -104.5378,
      city: 'Pueblo',
      state: 'CO',
    },
    81003: {
      lat: 38.27782,
      lng: -104.65668,
      city: 'Pueblo',
      state: 'CO',
    },
    81004: {
      lat: 38.05121,
      lng: -104.71595,
      city: 'Pueblo',
      state: 'CO',
    },
    81005: {
      lat: 38.21054,
      lng: -104.84608,
      city: 'Pueblo',
      state: 'CO',
    },
    81006: {
      lat: 38.24059,
      lng: -104.47413,
      city: 'Pueblo',
      state: 'CO',
    },
    81007: {
      lat: 38.35858,
      lng: -104.78472,
      city: 'Pueblo',
      state: 'CO',
    },
    81008: {
      lat: 38.40765,
      lng: -104.59354,
      city: 'Pueblo',
      state: 'CO',
    },
    81019: {
      lat: 37.94152,
      lng: -104.83714,
      city: 'Colorado City',
      state: 'CO',
    },
    81020: {
      lat: 37.43821,
      lng: -104.6253,
      city: 'Aguilar',
      state: 'CO',
    },
    81021: {
      lat: 38.43332,
      lng: -103.36732,
      city: 'Arlington',
      state: 'CO',
    },
    81022: {
      lat: 38.03279,
      lng: -104.43768,
      city: 'Avondale',
      state: 'CO',
    },
    81023: {
      lat: 38.06352,
      lng: -104.92966,
      city: 'Beulah',
      state: 'CO',
    },
    81024: {
      lat: 37.2293,
      lng: -104.71162,
      city: 'Boncarbo',
      state: 'CO',
    },
    81025: {
      lat: 38.34567,
      lng: -104.26422,
      city: 'Boone',
      state: 'CO',
    },
    81027: {
      lat: 37.24687,
      lng: -103.72308,
      city: 'Branson',
      state: 'CO',
    },
    81029: {
      lat: 37.08921,
      lng: -102.48274,
      city: 'Campo',
      state: 'CO',
    },
    81030: {
      lat: 38.10322,
      lng: -103.51876,
      city: 'Cheraw',
      state: 'CO',
    },
    81033: {
      lat: 38.19575,
      lng: -103.85557,
      city: 'Crowley',
      state: 'CO',
    },
    81036: {
      lat: 38.42984,
      lng: -102.79292,
      city: 'Eads',
      state: 'CO',
    },
    81039: {
      lat: 37.94079,
      lng: -104.08615,
      city: 'Fowler',
      state: 'CO',
    },
    81040: {
      lat: 37.79283,
      lng: -105.2493,
      city: 'Gardner',
      state: 'CO',
    },
    81041: {
      lat: 37.844,
      lng: -102.3839,
      city: 'Granada',
      state: 'CO',
    },
    81043: {
      lat: 38.11982,
      lng: -102.22312,
      city: 'Hartman',
      state: 'CO',
    },
    81044: {
      lat: 37.90328,
      lng: -102.93628,
      city: 'Hasty',
      state: 'CO',
    },
    81045: {
      lat: 38.55956,
      lng: -103.16093,
      city: 'Haswell',
      state: 'CO',
    },
    81046: {
      lat: 37.29597,
      lng: -104.36867,
      city: 'Hoehne',
      state: 'CO',
    },
    81047: {
      lat: 38.03226,
      lng: -102.19206,
      city: 'Holly',
      state: 'CO',
    },
    81049: { lat: 37.31338, lng: -103.36308, city: 'Kim', state: 'CO' },
    81050: {
      lat: 37.92266,
      lng: -103.51528,
      city: 'La Junta',
      state: 'CO',
    },
    81052: {
      lat: 37.94242,
      lng: -102.65625,
      city: 'Lamar',
      state: 'CO',
    },
    81054: {
      lat: 37.88583,
      lng: -103.19493,
      city: 'Las Animas',
      state: 'CO',
    },
    81055: {
      lat: 37.47727,
      lng: -105.06635,
      city: 'La Veta',
      state: 'CO',
    },
    81057: {
      lat: 38.12525,
      lng: -102.89675,
      city: 'McClave',
      state: 'CO',
    },
    81058: {
      lat: 38.10727,
      lng: -103.88161,
      city: 'Manzanola',
      state: 'CO',
    },
    81059: {
      lat: 37.56259,
      lng: -103.99322,
      city: 'Model',
      state: 'CO',
    },
    81062: {
      lat: 38.29345,
      lng: -103.94995,
      city: 'Olney Springs',
      state: 'CO',
    },
    81063: {
      lat: 38.45232,
      lng: -103.78494,
      city: 'Ordway',
      state: 'CO',
    },
    81064: {
      lat: 37.24593,
      lng: -102.99753,
      city: 'Pritchett',
      state: 'CO',
    },
    81067: {
      lat: 38.01674,
      lng: -103.72326,
      city: 'Rocky Ford',
      state: 'CO',
    },
    81069: { lat: 37.88998, lng: -104.88951, city: 'Rye', state: 'CO' },
    81071: {
      lat: 38.44838,
      lng: -102.2816,
      city: 'Sheridan Lake',
      state: 'CO',
    },
    81073: {
      lat: 37.42414,
      lng: -102.71496,
      city: 'Springfield',
      state: 'CO',
    },
    81076: {
      lat: 38.32338,
      lng: -103.6167,
      city: 'Sugar City',
      state: 'CO',
    },
    81077: {
      lat: 38.01374,
      lng: -103.60985,
      city: 'Swink',
      state: 'CO',
    },
    81081: {
      lat: 37.07295,
      lng: -104.14954,
      city: 'Trinchera',
      state: 'CO',
    },
    81082: {
      lat: 37.20343,
      lng: -104.41717,
      city: 'Trinidad',
      state: 'CO',
    },
    81084: {
      lat: 37.57031,
      lng: -102.41929,
      city: 'Two Buttes',
      state: 'CO',
    },
    81087: {
      lat: 37.39168,
      lng: -102.41277,
      city: 'Vilas',
      state: 'CO',
    },
    81089: {
      lat: 37.6642,
      lng: -104.66427,
      city: 'Walsenburg',
      state: 'CO',
    },
    81090: {
      lat: 37.36311,
      lng: -102.22434,
      city: 'Walsh',
      state: 'CO',
    },
    81091: {
      lat: 37.14869,
      lng: -104.97774,
      city: 'Weston',
      state: 'CO',
    },
    81092: {
      lat: 38.20283,
      lng: -102.86779,
      city: 'Wiley',
      state: 'CO',
    },
    81101: {
      lat: 37.47197,
      lng: -105.85243,
      city: 'Alamosa',
      state: 'CO',
    },
    81120: {
      lat: 37.14248,
      lng: -106.26715,
      city: 'Antonito',
      state: 'CO',
    },
    81121: {
      lat: 37.01861,
      lng: -107.42041,
      city: 'Arboles',
      state: 'CO',
    },
    81122: {
      lat: 37.31839,
      lng: -107.50574,
      city: 'Bayfield',
      state: 'CO',
    },
    81123: {
      lat: 37.40469,
      lng: -105.5659,
      city: 'Blanca',
      state: 'CO',
    },
    81124: {
      lat: 37.28543,
      lng: -106.10688,
      city: 'Capulin',
      state: 'CO',
    },
    81125: {
      lat: 37.81745,
      lng: -106.06678,
      city: 'Center',
      state: 'CO',
    },
    81126: {
      lat: 37.16146,
      lng: -105.34724,
      city: 'Chama',
      state: 'CO',
    },
    81128: {
      lat: 37.06529,
      lng: -106.65223,
      city: 'Chromo',
      state: 'CO',
    },
    81129: {
      lat: 37.10495,
      lng: -106.02167,
      city: 'Conejos',
      state: 'CO',
    },
    81130: {
      lat: 37.7179,
      lng: -107.04471,
      city: 'Creede',
      state: 'CO',
    },
    81131: {
      lat: 37.87706,
      lng: -105.66651,
      city: 'Crestone',
      state: 'CO',
    },
    81132: {
      lat: 37.71656,
      lng: -106.41009,
      city: 'Del Norte',
      state: 'CO',
    },
    81133: {
      lat: 37.44964,
      lng: -105.32747,
      city: 'Fort Garland',
      state: 'CO',
    },
    81136: {
      lat: 37.72672,
      lng: -105.85355,
      city: 'Hooper',
      state: 'CO',
    },
    81137: {
      lat: 37.05555,
      lng: -107.61596,
      city: 'Ignacio',
      state: 'CO',
    },
    81138: {
      lat: 37.02302,
      lng: -105.62737,
      city: 'Jaroso',
      state: 'CO',
    },
    81140: {
      lat: 37.28692,
      lng: -106.1543,
      city: 'La Jara',
      state: 'CO',
    },
    81141: {
      lat: 37.16256,
      lng: -105.89507,
      city: 'Manassa',
      state: 'CO',
    },
    81143: {
      lat: 38.14477,
      lng: -105.87112,
      city: 'Moffat',
      state: 'CO',
    },
    81144: {
      lat: 37.5516,
      lng: -106.14851,
      city: 'Monte Vista',
      state: 'CO',
    },
    81146: {
      lat: 37.64616,
      lng: -105.6776,
      city: 'Mosca',
      state: 'CO',
    },
    81147: {
      lat: 37.28675,
      lng: -107.08333,
      city: 'Pagosa Springs',
      state: 'CO',
    },
    81148: {
      lat: 37.17183,
      lng: -105.98542,
      city: 'Romeo',
      state: 'CO',
    },
    81149: {
      lat: 38.1035,
      lng: -106.33858,
      city: 'Saguache',
      state: 'CO',
    },
    81151: {
      lat: 37.23376,
      lng: -105.71506,
      city: 'Sanford',
      state: 'CO',
    },
    81152: {
      lat: 37.12609,
      lng: -105.39411,
      city: 'San Luis',
      state: 'CO',
    },
    81154: {
      lat: 37.63473,
      lng: -106.67179,
      city: 'South Fork',
      state: 'CO',
    },
    81155: {
      lat: 38.26671,
      lng: -106.09603,
      city: 'Villa Grove',
      state: 'CO',
    },
    81201: {
      lat: 38.55129,
      lng: -106.08157,
      city: 'Salida',
      state: 'CO',
    },
    81210: {
      lat: 38.81898,
      lng: -106.62219,
      city: 'Almont',
      state: 'CO',
    },
    81211: {
      lat: 38.89548,
      lng: -106.24128,
      city: 'Buena Vista',
      state: 'CO',
    },
    81212: {
      lat: 38.54409,
      lng: -105.42498,
      city: 'Canon City',
      state: 'CO',
    },
    81220: {
      lat: 38.29861,
      lng: -107.48414,
      city: 'Cimarron',
      state: 'CO',
    },
    81221: {
      lat: 38.36243,
      lng: -105.14238,
      city: 'Coal Creek',
      state: 'CO',
    },
    81222: {
      lat: 38.35492,
      lng: -105.81612,
      city: 'Coaldale',
      state: 'CO',
    },
    81223: {
      lat: 38.39372,
      lng: -105.6031,
      city: 'Cotopaxi',
      state: 'CO',
    },
    81224: {
      lat: 38.87985,
      lng: -106.90957,
      city: 'Crested Butte',
      state: 'CO',
    },
    81225: {
      lat: 38.94115,
      lng: -107.00664,
      city: 'Crested Butte',
      state: 'CO',
    },
    81226: {
      lat: 38.32213,
      lng: -105.10193,
      city: 'Florence',
      state: 'CO',
    },
    81227: {
      lat: 38.54934,
      lng: -106.29009,
      city: 'Monarch',
      state: 'CO',
    },
    81228: {
      lat: 39.03402,
      lng: -106.27723,
      city: 'Granite',
      state: 'CO',
    },
    81230: {
      lat: 38.40752,
      lng: -106.98372,
      city: 'Gunnison',
      state: 'CO',
    },
    81232: {
      lat: 38.27535,
      lng: -105.60895,
      city: 'Hillside',
      state: 'CO',
    },
    81233: {
      lat: 38.49244,
      lng: -105.81369,
      city: 'Howard',
      state: 'CO',
    },
    81235: {
      lat: 37.99363,
      lng: -107.33098,
      city: 'Lake City',
      state: 'CO',
    },
    81236: {
      lat: 38.6955,
      lng: -106.25424,
      city: 'Nathrop',
      state: 'CO',
    },
    81237: {
      lat: 38.62842,
      lng: -106.59108,
      city: 'Ohio City',
      state: 'CO',
    },
    81239: {
      lat: 38.53667,
      lng: -106.60825,
      city: 'Parlin',
      state: 'CO',
    },
    81240: {
      lat: 38.4995,
      lng: -105.03076,
      city: 'Penrose',
      state: 'CO',
    },
    81241: {
      lat: 38.62675,
      lng: -106.48848,
      city: 'Pitkin',
      state: 'CO',
    },
    81242: {
      lat: 38.47181,
      lng: -106.07288,
      city: 'Poncha Springs',
      state: 'CO',
    },
    81243: {
      lat: 38.26041,
      lng: -107.17654,
      city: 'Powderhorn',
      state: 'CO',
    },
    81244: {
      lat: 38.36393,
      lng: -105.16495,
      city: 'Rockvale',
      state: 'CO',
    },
    81248: {
      lat: 38.3991,
      lng: -106.47763,
      city: 'Sargents',
      state: 'CO',
    },
    81251: {
      lat: 39.13482,
      lng: -106.43723,
      city: 'Twin Lakes',
      state: 'CO',
    },
    81252: {
      lat: 38.10891,
      lng: -105.42143,
      city: 'Westcliffe',
      state: 'CO',
    },
    81253: {
      lat: 38.18763,
      lng: -105.07497,
      city: 'Wetmore',
      state: 'CO',
    },
    81301: {
      lat: 37.51095,
      lng: -107.82066,
      city: 'Durango',
      state: 'CO',
    },
    81303: {
      lat: 37.12903,
      lng: -107.85677,
      city: 'Durango',
      state: 'CO',
    },
    81320: {
      lat: 37.70115,
      lng: -108.72654,
      city: 'Cahone',
      state: 'CO',
    },
    81321: {
      lat: 37.31826,
      lng: -108.76267,
      city: 'Cortez',
      state: 'CO',
    },
    81323: {
      lat: 37.64874,
      lng: -108.27823,
      city: 'Dolores',
      state: 'CO',
    },
    81324: {
      lat: 37.63734,
      lng: -109.02911,
      city: 'Dove Creek',
      state: 'CO',
    },
    81325: {
      lat: 37.89605,
      lng: -108.72011,
      city: 'Egnar',
      state: 'CO',
    },
    81326: {
      lat: 37.14635,
      lng: -108.11525,
      city: 'Hesperus',
      state: 'CO',
    },
    81327: {
      lat: 37.5155,
      lng: -108.66488,
      city: 'Lewis',
      state: 'CO',
    },
    81328: {
      lat: 37.30054,
      lng: -108.3101,
      city: 'Mancos',
      state: 'CO',
    },
    81330: {
      lat: 37.23484,
      lng: -108.47828,
      city: 'Mesa Verde National Park',
      state: 'CO',
    },
    81331: {
      lat: 37.51387,
      lng: -108.86252,
      city: 'Pleasant View',
      state: 'CO',
    },
    81332: {
      lat: 37.69857,
      lng: -107.99292,
      city: 'Rico',
      state: 'CO',
    },
    81334: {
      lat: 37.11594,
      lng: -108.63674,
      city: 'Towaoc',
      state: 'CO',
    },
    81335: {
      lat: 37.54381,
      lng: -108.71978,
      city: 'Yellow Jacket',
      state: 'CO',
    },
    81401: {
      lat: 38.51559,
      lng: -107.78472,
      city: 'Montrose',
      state: 'CO',
    },
    81403: {
      lat: 38.32308,
      lng: -107.92816,
      city: 'Montrose',
      state: 'CO',
    },
    81410: {
      lat: 38.81261,
      lng: -107.97595,
      city: 'Austin',
      state: 'CO',
    },
    81411: {
      lat: 38.25188,
      lng: -108.97862,
      city: 'Bedrock',
      state: 'CO',
    },
    81413: {
      lat: 38.97171,
      lng: -107.90845,
      city: 'Cedaredge',
      state: 'CO',
    },
    81415: {
      lat: 38.64027,
      lng: -107.6368,
      city: 'Crawford',
      state: 'CO',
    },
    81416: {
      lat: 38.66482,
      lng: -108.20403,
      city: 'Delta',
      state: 'CO',
    },
    81418: {
      lat: 38.90479,
      lng: -108.05687,
      city: 'Eckert',
      state: 'CO',
    },
    81419: {
      lat: 38.86332,
      lng: -107.74872,
      city: 'Hotchkiss',
      state: 'CO',
    },
    81422: {
      lat: 38.32039,
      lng: -108.70843,
      city: 'Naturita',
      state: 'CO',
    },
    81423: {
      lat: 38.03835,
      lng: -108.45816,
      city: 'Norwood',
      state: 'CO',
    },
    81424: {
      lat: 38.34098,
      lng: -108.47346,
      city: 'Nucla',
      state: 'CO',
    },
    81425: {
      lat: 38.49363,
      lng: -108.10469,
      city: 'Olathe',
      state: 'CO',
    },
    81426: {
      lat: 37.87339,
      lng: -107.83191,
      city: 'Ophir',
      state: 'CO',
    },
    81427: {
      lat: 37.99301,
      lng: -107.64387,
      city: 'Ouray',
      state: 'CO',
    },
    81428: {
      lat: 38.95928,
      lng: -107.59899,
      city: 'Paonia',
      state: 'CO',
    },
    81429: {
      lat: 38.34121,
      lng: -108.90732,
      city: 'Paradox',
      state: 'CO',
    },
    81430: {
      lat: 38.04013,
      lng: -108.05764,
      city: 'Placerville',
      state: 'CO',
    },
    81431: {
      lat: 38.13808,
      lng: -108.51429,
      city: 'Redvale',
      state: 'CO',
    },
    81432: {
      lat: 38.12494,
      lng: -107.74273,
      city: 'Ridgway',
      state: 'CO',
    },
    81433: {
      lat: 37.79346,
      lng: -107.59448,
      city: 'Silverton',
      state: 'CO',
    },
    81434: {
      lat: 38.95384,
      lng: -107.35954,
      city: 'Somerset',
      state: 'CO',
    },
    81435: {
      lat: 37.90014,
      lng: -107.92397,
      city: 'Telluride',
      state: 'CO',
    },
    81501: {
      lat: 39.07174,
      lng: -108.54569,
      city: 'Grand Junction',
      state: 'CO',
    },
    81503: {
      lat: 39.03094,
      lng: -108.48682,
      city: 'Grand Junction',
      state: 'CO',
    },
    81504: {
      lat: 39.1198,
      lng: -108.49542,
      city: 'Grand Junction',
      state: 'CO',
    },
    81505: {
      lat: 39.18593,
      lng: -108.59997,
      city: 'Grand Junction',
      state: 'CO',
    },
    81506: {
      lat: 39.15785,
      lng: -108.53454,
      city: 'Grand Junction',
      state: 'CO',
    },
    81507: {
      lat: 39.0079,
      lng: -108.62644,
      city: 'Grand Junction',
      state: 'CO',
    },
    81520: {
      lat: 39.10962,
      lng: -108.43561,
      city: 'Clifton',
      state: 'CO',
    },
    81521: {
      lat: 39.12529,
      lng: -108.85049,
      city: 'Fruita',
      state: 'CO',
    },
    81522: {
      lat: 38.66026,
      lng: -108.93025,
      city: 'Gateway',
      state: 'CO',
    },
    81523: {
      lat: 38.93803,
      lng: -108.87362,
      city: 'Glade Park',
      state: 'CO',
    },
    81524: { lat: 39.2842, lng: -108.73257, city: 'Loma', state: 'CO' },
    81525: {
      lat: 39.26984,
      lng: -108.95075,
      city: 'Mack',
      state: 'CO',
    },
    81526: {
      lat: 39.07741,
      lng: -108.33056,
      city: 'Palisade',
      state: 'CO',
    },
    81527: {
      lat: 38.75758,
      lng: -108.52466,
      city: 'Whitewater',
      state: 'CO',
    },
    81601: {
      lat: 39.59357,
      lng: -107.32815,
      city: 'Glenwood Springs',
      state: 'CO',
    },
    81610: {
      lat: 40.29418,
      lng: -108.82468,
      city: 'Dinosaur',
      state: 'CO',
    },
    81611: {
      lat: 39.14561,
      lng: -106.74885,
      city: 'Aspen',
      state: 'CO',
    },
    81612: {
      lat: 39.05048,
      lng: -106.82335,
      city: 'Aspen',
      state: 'CO',
    },
    81615: {
      lat: 39.21437,
      lng: -106.94172,
      city: 'Snowmass Village',
      state: 'CO',
    },
    81620: {
      lat: 39.61485,
      lng: -106.50555,
      city: 'Avon',
      state: 'CO',
    },
    81621: {
      lat: 39.37803,
      lng: -106.8772,
      city: 'Basalt',
      state: 'CO',
    },
    81623: {
      lat: 39.2451,
      lng: -107.20921,
      city: 'Carbondale',
      state: 'CO',
    },
    81624: {
      lat: 39.24611,
      lng: -107.77259,
      city: 'Collbran',
      state: 'CO',
    },
    81625: {
      lat: 40.70295,
      lng: -107.74652,
      city: 'Craig',
      state: 'CO',
    },
    81630: {
      lat: 39.45769,
      lng: -108.54627,
      city: 'De Beque',
      state: 'CO',
    },
    81631: {
      lat: 39.63116,
      lng: -106.77136,
      city: 'Eagle',
      state: 'CO',
    },
    81632: {
      lat: 39.62015,
      lng: -106.61132,
      city: 'Edwards',
      state: 'CO',
    },
    81633: {
      lat: 40.37644,
      lng: -108.50983,
      city: 'Dinosaur',
      state: 'CO',
    },
    81635: {
      lat: 39.53722,
      lng: -108.06022,
      city: 'Parachute',
      state: 'CO',
    },
    81637: {
      lat: 39.77162,
      lng: -107.10065,
      city: 'Gypsum',
      state: 'CO',
    },
    81638: {
      lat: 40.30589,
      lng: -107.64205,
      city: 'Hamilton',
      state: 'CO',
    },
    81639: {
      lat: 40.52636,
      lng: -107.24457,
      city: 'Hayden',
      state: 'CO',
    },
    81640: {
      lat: 40.69357,
      lng: -108.51791,
      city: 'Maybell',
      state: 'CO',
    },
    81641: {
      lat: 40.09112,
      lng: -107.84699,
      city: 'Meeker',
      state: 'CO',
    },
    81642: {
      lat: 39.31202,
      lng: -106.58448,
      city: 'Meredith',
      state: 'CO',
    },
    81643: {
      lat: 39.11968,
      lng: -108.15001,
      city: 'Mesa',
      state: 'CO',
    },
    81645: {
      lat: 39.47634,
      lng: -106.46779,
      city: 'Minturn',
      state: 'CO',
    },
    81646: {
      lat: 39.10875,
      lng: -107.99199,
      city: 'Molina',
      state: 'CO',
    },
    81647: {
      lat: 39.57972,
      lng: -107.5364,
      city: 'New Castle',
      state: 'CO',
    },
    81648: {
      lat: 39.95096,
      lng: -108.78498,
      city: 'Rangely',
      state: 'CO',
    },
    81649: {
      lat: 39.46433,
      lng: -106.31954,
      city: 'Red Cliff',
      state: 'CO',
    },
    81650: {
      lat: 39.75926,
      lng: -108.11201,
      city: 'Rifle',
      state: 'CO',
    },
    81652: { lat: 39.472, lng: -107.67082, city: 'Silt', state: 'CO' },
    81653: {
      lat: 40.9144,
      lng: -107.2242,
      city: 'Slater',
      state: 'CO',
    },
    81654: {
      lat: 39.21462,
      lng: -107.02597,
      city: 'Snowmass',
      state: 'CO',
    },
    81655: {
      lat: 39.7477,
      lng: -106.67821,
      city: 'Wolcott',
      state: 'CO',
    },
    81656: {
      lat: 39.27948,
      lng: -106.78711,
      city: 'Woody Creek',
      state: 'CO',
    },
    81657: {
      lat: 39.64864,
      lng: -106.32608,
      city: 'Vail',
      state: 'CO',
    },
    82001: {
      lat: 41.095,
      lng: -104.93265,
      city: 'Cheyenne',
      state: 'WY',
    },
    82005: {
      lat: 41.14631,
      lng: -104.86787,
      city: 'Fe Warren Afb',
      state: 'WY',
    },
    82007: {
      lat: 41.06231,
      lng: -104.75591,
      city: 'Cheyenne',
      state: 'WY',
    },
    82009: {
      lat: 41.38431,
      lng: -104.85415,
      city: 'Cheyenne',
      state: 'WY',
    },
    82050: {
      lat: 41.43517,
      lng: -104.21477,
      city: 'Albin',
      state: 'WY',
    },
    82051: {
      lat: 41.60053,
      lng: -105.64743,
      city: 'Bosler',
      state: 'WY',
    },
    82052: {
      lat: 41.12399,
      lng: -105.34802,
      city: 'Buford',
      state: 'WY',
    },
    82053: { lat: 41.24392, lng: -104.348, city: 'Burns', state: 'WY' },
    82054: {
      lat: 41.0647,
      lng: -104.35899,
      city: 'Carpenter',
      state: 'WY',
    },
    82055: {
      lat: 41.3483,
      lng: -106.18191,
      city: 'Centennial',
      state: 'WY',
    },
    82058: {
      lat: 42.13817,
      lng: -105.63358,
      city: 'Garrett',
      state: 'WY',
    },
    82059: {
      lat: 41.05681,
      lng: -105.17714,
      city: 'Granite Canon',
      state: 'WY',
    },
    82060: {
      lat: 41.22526,
      lng: -104.51505,
      city: 'Hillsdale',
      state: 'WY',
    },
    82061: {
      lat: 41.43791,
      lng: -105.15495,
      city: 'Horse Creek',
      state: 'WY',
    },
    82063: { lat: 40.87554, lng: -105.9889, city: 'Jelm', state: 'WY' },
    82070: {
      lat: 41.21404,
      lng: -105.85037,
      city: 'Laramie',
      state: 'WY',
    },
    82072: {
      lat: 41.42557,
      lng: -105.55849,
      city: 'Laramie',
      state: 'WY',
    },
    82073: {
      lat: 41.16408,
      lng: -105.43228,
      city: 'Laramie',
      state: 'WY',
    },
    82081: {
      lat: 41.52319,
      lng: -104.40653,
      city: 'Meriden',
      state: 'WY',
    },
    82082: {
      lat: 41.2632,
      lng: -104.09359,
      city: 'Pine Bluffs',
      state: 'WY',
    },
    82083: {
      lat: 41.72291,
      lng: -105.99118,
      city: 'Rock River',
      state: 'WY',
    },
    82084: {
      lat: 41.04764,
      lng: -105.52494,
      city: 'Tie Siding',
      state: 'WY',
    },
    82190: {
      lat: 44.57439,
      lng: -110.51807,
      city: 'Yellowstone National Park',
      state: 'WY',
    },
    82201: {
      lat: 42.01049,
      lng: -105.11328,
      city: 'Wheatland',
      state: 'WY',
    },
    82210: {
      lat: 41.73214,
      lng: -104.77981,
      city: 'Chugwater',
      state: 'WY',
    },
    82212: {
      lat: 42.26126,
      lng: -104.51995,
      city: 'Fort Laramie',
      state: 'WY',
    },
    82213: {
      lat: 42.50216,
      lng: -105.00672,
      city: 'Glendo',
      state: 'WY',
    },
    82214: {
      lat: 42.26421,
      lng: -104.75104,
      city: 'Guernsey',
      state: 'WY',
    },
    82215: {
      lat: 42.39738,
      lng: -104.71507,
      city: 'Hartville',
      state: 'WY',
    },
    82217: {
      lat: 41.76223,
      lng: -104.17504,
      city: 'Hawk Springs',
      state: 'WY',
    },
    82218: {
      lat: 41.93623,
      lng: -104.14004,
      city: 'Huntley',
      state: 'WY',
    },
    82219: {
      lat: 42.4973,
      lng: -104.50627,
      city: 'Jay Em',
      state: 'WY',
    },
    82221: {
      lat: 41.63816,
      lng: -104.34264,
      city: 'Lagrange',
      state: 'WY',
    },
    82222: {
      lat: 43.16311,
      lng: -104.64988,
      city: 'Lance Creek',
      state: 'WY',
    },
    82223: {
      lat: 42.12035,
      lng: -104.38503,
      city: 'Lingle',
      state: 'WY',
    },
    82224: {
      lat: 42.93677,
      lng: -104.88811,
      city: 'Lost Springs',
      state: 'WY',
    },
    82225: {
      lat: 42.95428,
      lng: -104.31385,
      city: 'Lusk',
      state: 'WY',
    },
    82227: {
      lat: 42.72083,
      lng: -104.72875,
      city: 'Manville',
      state: 'WY',
    },
    82229: {
      lat: 42.85613,
      lng: -104.9863,
      city: 'Shawnee',
      state: 'WY',
    },
    82240: {
      lat: 42.23064,
      lng: -104.17824,
      city: 'Torrington',
      state: 'WY',
    },
    82242: {
      lat: 42.67694,
      lng: -104.13886,
      city: 'Van Tassell',
      state: 'WY',
    },
    82243: {
      lat: 41.99442,
      lng: -104.41451,
      city: 'Veteran',
      state: 'WY',
    },
    82244: {
      lat: 41.87602,
      lng: -104.44228,
      city: 'Yoder',
      state: 'WY',
    },
    82301: {
      lat: 41.98163,
      lng: -107.47745,
      city: 'Rawlins',
      state: 'WY',
    },
    82310: {
      lat: 42.44566,
      lng: -107.8775,
      city: 'Jeffrey City',
      state: 'WY',
    },
    82321: {
      lat: 41.15921,
      lng: -107.69697,
      city: 'Baggs',
      state: 'WY',
    },
    82322: {
      lat: 42.24643,
      lng: -107.54816,
      city: 'Bairoil',
      state: 'WY',
    },
    82323: {
      lat: 41.0308,
      lng: -107.52201,
      city: 'Dixon',
      state: 'WY',
    },
    82324: {
      lat: 41.64509,
      lng: -106.43721,
      city: 'Elk Mountain',
      state: 'WY',
    },
    82325: {
      lat: 41.1628,
      lng: -106.7919,
      city: 'Encampment',
      state: 'WY',
    },
    82327: {
      lat: 42.17354,
      lng: -106.69289,
      city: 'Hanna',
      state: 'WY',
    },
    82329: {
      lat: 42.09073,
      lng: -106.09959,
      city: 'Medicine Bow',
      state: 'WY',
    },
    82331: {
      lat: 41.38607,
      lng: -106.82252,
      city: 'Saratoga',
      state: 'WY',
    },
    82332: {
      lat: 41.06314,
      lng: -107.3016,
      city: 'Savery',
      state: 'WY',
    },
    82334: {
      lat: 41.92837,
      lng: -106.9679,
      city: 'Sinclair',
      state: 'WY',
    },
    82335: {
      lat: 41.76174,
      lng: -106.82553,
      city: 'Walcott',
      state: 'WY',
    },
    82336: {
      lat: 41.71046,
      lng: -108.16368,
      city: 'Wamsutter',
      state: 'WY',
    },
    82401: {
      lat: 44.00089,
      lng: -108.1677,
      city: 'Worland',
      state: 'WY',
    },
    82410: {
      lat: 44.37297,
      lng: -108.15093,
      city: 'Basin',
      state: 'WY',
    },
    82411: {
      lat: 44.44995,
      lng: -108.47663,
      city: 'Burlington',
      state: 'WY',
    },
    82412: {
      lat: 44.79014,
      lng: -108.55286,
      city: 'Byron',
      state: 'WY',
    },
    82414: {
      lat: 44.45727,
      lng: -109.59671,
      city: 'Cody',
      state: 'WY',
    },
    82420: {
      lat: 44.92254,
      lng: -108.46149,
      city: 'Cowley',
      state: 'WY',
    },
    82421: {
      lat: 44.88399,
      lng: -108.60497,
      city: 'Deaver',
      state: 'WY',
    },
    82422: {
      lat: 44.49768,
      lng: -108.37943,
      city: 'Emblem',
      state: 'WY',
    },
    82423: {
      lat: 44.98029,
      lng: -108.58432,
      city: 'Frannie',
      state: 'WY',
    },
    82426: {
      lat: 44.51595,
      lng: -108.02938,
      city: 'Greybull',
      state: 'WY',
    },
    82428: {
      lat: 44.29866,
      lng: -107.59032,
      city: 'Hyattville',
      state: 'WY',
    },
    82430: {
      lat: 43.80433,
      lng: -108.17898,
      city: 'Kirby',
      state: 'WY',
    },
    82431: {
      lat: 44.87059,
      lng: -108.16218,
      city: 'Lovell',
      state: 'WY',
    },
    82432: {
      lat: 44.23641,
      lng: -107.88175,
      city: 'Manderson',
      state: 'WY',
    },
    82433: {
      lat: 44.0616,
      lng: -109.12558,
      city: 'Meeteetse',
      state: 'WY',
    },
    82434: {
      lat: 44.34726,
      lng: -108.30767,
      city: 'Otto',
      state: 'WY',
    },
    82435: {
      lat: 44.82409,
      lng: -109.0348,
      city: 'Powell',
      state: 'WY',
    },
    82440: {
      lat: 44.71743,
      lng: -108.86781,
      city: 'Ralston',
      state: 'WY',
    },
    82441: {
      lat: 44.58025,
      lng: -107.60799,
      city: 'Shell',
      state: 'WY',
    },
    82442: {
      lat: 43.92096,
      lng: -107.31087,
      city: 'Ten Sleep',
      state: 'WY',
    },
    82443: {
      lat: 43.71794,
      lng: -108.48023,
      city: 'Thermopolis',
      state: 'WY',
    },
    82450: {
      lat: 44.50398,
      lng: -109.43379,
      city: 'Wapiti',
      state: 'WY',
    },
    82501: {
      lat: 43.03325,
      lng: -108.24936,
      city: 'Riverton',
      state: 'WY',
    },
    82510: {
      lat: 42.99294,
      lng: -108.6055,
      city: 'Arapahoe',
      state: 'WY',
    },
    82512: {
      lat: 43.1639,
      lng: -109.24974,
      city: 'Crowheart',
      state: 'WY',
    },
    82513: {
      lat: 43.59737,
      lng: -109.61913,
      city: 'Dubois',
      state: 'WY',
    },
    82514: {
      lat: 43.01997,
      lng: -109.01,
      city: 'Fort Washakie',
      state: 'WY',
    },
    82515: {
      lat: 42.89717,
      lng: -108.56688,
      city: 'Hudson',
      state: 'WY',
    },
    82516: {
      lat: 43.21485,
      lng: -108.83195,
      city: 'Kinnear',
      state: 'WY',
    },
    82520: {
      lat: 42.65919,
      lng: -108.63282,
      city: 'Lander',
      state: 'WY',
    },
    82523: {
      lat: 43.41253,
      lng: -108.85636,
      city: 'Pavillion',
      state: 'WY',
    },
    82601: {
      lat: 43.0655,
      lng: -106.34618,
      city: 'Casper',
      state: 'WY',
    },
    82604: {
      lat: 42.91751,
      lng: -106.7084,
      city: 'Casper',
      state: 'WY',
    },
    82609: {
      lat: 42.77773,
      lng: -106.18937,
      city: 'Casper',
      state: 'WY',
    },
    82620: {
      lat: 42.52434,
      lng: -107.08132,
      city: 'Alcova',
      state: 'WY',
    },
    82630: {
      lat: 43.33432,
      lng: -107.25595,
      city: 'Arminto',
      state: 'WY',
    },
    82633: {
      lat: 42.99525,
      lng: -105.39874,
      city: 'Douglas',
      state: 'WY',
    },
    82635: {
      lat: 43.4218,
      lng: -106.21834,
      city: 'Edgerton',
      state: 'WY',
    },
    82636: {
      lat: 42.83473,
      lng: -106.14286,
      city: 'Evansville',
      state: 'WY',
    },
    82637: {
      lat: 42.76679,
      lng: -105.88228,
      city: 'Glenrock',
      state: 'WY',
    },
    82638: {
      lat: 43.0667,
      lng: -107.25585,
      city: 'Hiland',
      state: 'WY',
    },
    82639: {
      lat: 43.73555,
      lng: -106.6978,
      city: 'Kaycee',
      state: 'WY',
    },
    82640: {
      lat: 43.55214,
      lng: -106.11811,
      city: 'Linch',
      state: 'WY',
    },
    82642: {
      lat: 43.2799,
      lng: -107.65797,
      city: 'Lysite',
      state: 'WY',
    },
    82643: {
      lat: 43.43973,
      lng: -106.27359,
      city: 'Midwest',
      state: 'WY',
    },
    82644: {
      lat: 42.84303,
      lng: -106.37437,
      city: 'Mills',
      state: 'WY',
    },
    82646: {
      lat: 42.96297,
      lng: -106.79617,
      city: 'Natrona',
      state: 'WY',
    },
    82648: {
      lat: 43.14749,
      lng: -107.04194,
      city: 'Powder River',
      state: 'WY',
    },
    82649: {
      lat: 43.27229,
      lng: -108.04155,
      city: 'Shoshoni',
      state: 'WY',
    },
    82701: {
      lat: 43.71185,
      lng: -104.4451,
      city: 'Newcastle',
      state: 'WY',
    },
    82710: {
      lat: 44.71488,
      lng: -104.28465,
      city: 'Aladdin',
      state: 'WY',
    },
    82711: {
      lat: 44.71157,
      lng: -104.48062,
      city: 'Alva',
      state: 'WY',
    },
    82712: {
      lat: 44.52594,
      lng: -104.12372,
      city: 'Beulah',
      state: 'WY',
    },
    82714: {
      lat: 44.5519,
      lng: -104.68009,
      city: 'Devils Tower',
      state: 'WY',
    },
    82716: {
      lat: 44.54033,
      lng: -105.64601,
      city: 'Gillette',
      state: 'WY',
    },
    82718: {
      lat: 43.91777,
      lng: -105.61603,
      city: 'Gillette',
      state: 'WY',
    },
    82720: {
      lat: 44.79324,
      lng: -104.67241,
      city: 'Hulett',
      state: 'WY',
    },
    82721: {
      lat: 44.46199,
      lng: -104.89808,
      city: 'Moorcroft',
      state: 'WY',
    },
    82723: {
      lat: 43.98898,
      lng: -104.42397,
      city: 'Osage',
      state: 'WY',
    },
    82725: {
      lat: 44.87752,
      lng: -105.70974,
      city: 'Recluse',
      state: 'WY',
    },
    82727: {
      lat: 44.33595,
      lng: -105.19426,
      city: 'Rozet',
      state: 'WY',
    },
    82729: {
      lat: 44.36384,
      lng: -104.38734,
      city: 'Sundance',
      state: 'WY',
    },
    82730: {
      lat: 44.04378,
      lng: -104.70031,
      city: 'Upton',
      state: 'WY',
    },
    82731: {
      lat: 44.80203,
      lng: -105.23982,
      city: 'Weston',
      state: 'WY',
    },
    82732: {
      lat: 43.725,
      lng: -105.57871,
      city: 'Wright',
      state: 'WY',
    },
    82801: {
      lat: 44.83618,
      lng: -106.89258,
      city: 'Sheridan',
      state: 'WY',
    },
    82831: {
      lat: 44.66424,
      lng: -106.0669,
      city: 'Arvada',
      state: 'WY',
    },
    82832: {
      lat: 44.59442,
      lng: -106.75233,
      city: 'Banner',
      state: 'WY',
    },
    82833: {
      lat: 44.61601,
      lng: -107.12966,
      city: 'Big Horn',
      state: 'WY',
    },
    82834: {
      lat: 44.25386,
      lng: -106.74481,
      city: 'Buffalo',
      state: 'WY',
    },
    82835: {
      lat: 44.75856,
      lng: -106.36918,
      city: 'Clearmont',
      state: 'WY',
    },
    82836: {
      lat: 44.83701,
      lng: -107.57589,
      city: 'Dayton',
      state: 'WY',
    },
    82837: {
      lat: 44.72549,
      lng: -106.28169,
      city: 'Leiter',
      state: 'WY',
    },
    82838: {
      lat: 44.93793,
      lng: -107.4839,
      city: 'Parkman',
      state: 'WY',
    },
    82839: {
      lat: 44.92887,
      lng: -107.15762,
      city: 'Ranchester',
      state: 'WY',
    },
    82842: {
      lat: 44.57143,
      lng: -106.93956,
      city: 'Story',
      state: 'WY',
    },
    82844: {
      lat: 44.80405,
      lng: -107.19965,
      city: 'Wolf',
      state: 'WY',
    },
    82845: {
      lat: 44.76416,
      lng: -106.70382,
      city: 'Wyarno',
      state: 'WY',
    },
    82901: {
      lat: 41.38121,
      lng: -109.02469,
      city: 'Rock Springs',
      state: 'WY',
    },
    82922: {
      lat: 43.13507,
      lng: -110.43426,
      city: 'Bondurant',
      state: 'WY',
    },
    82923: {
      lat: 42.61609,
      lng: -109.40099,
      city: 'Boulder',
      state: 'WY',
    },
    82925: {
      lat: 43.23123,
      lng: -109.89386,
      city: 'Cora',
      state: 'WY',
    },
    82929: {
      lat: 41.54765,
      lng: -109.87134,
      city: 'Little America',
      state: 'WY',
    },
    82930: {
      lat: 40.98028,
      lng: -110.5964,
      city: 'Evanston',
      state: 'WY',
    },
    82932: {
      lat: 42.1219,
      lng: -109.38993,
      city: 'Farson',
      state: 'WY',
    },
    82933: {
      lat: 41.2686,
      lng: -110.45446,
      city: 'Fort Bridger',
      state: 'WY',
    },
    82934: {
      lat: 41.61247,
      lng: -109.99564,
      city: 'Granger',
      state: 'WY',
    },
    82935: {
      lat: 41.67746,
      lng: -109.66001,
      city: 'Green River',
      state: 'WY',
    },
    82936: {
      lat: 41.05725,
      lng: -110.18239,
      city: 'Lonetree',
      state: 'WY',
    },
    82937: { lat: 41.3491, lng: -110.2634, city: 'Lyman', state: 'WY' },
    82938: {
      lat: 41.09622,
      lng: -109.73764,
      city: 'McKinnon',
      state: 'WY',
    },
    82939: {
      lat: 41.22068,
      lng: -110.32357,
      city: 'Mountain View',
      state: 'WY',
    },
    82941: {
      lat: 42.99919,
      lng: -109.90869,
      city: 'Pinedale',
      state: 'WY',
    },
    82942: {
      lat: 41.70234,
      lng: -108.77539,
      city: 'Point Of Rocks',
      state: 'WY',
    },
    82943: {
      lat: 41.70197,
      lng: -109.18399,
      city: 'Reliance',
      state: 'WY',
    },
    82944: {
      lat: 41.12199,
      lng: -110.49746,
      city: 'Robertson',
      state: 'WY',
    },
    82945: {
      lat: 41.72007,
      lng: -108.9389,
      city: 'Superior',
      state: 'WY',
    },
    83001: {
      lat: 43.4094,
      lng: -110.60884,
      city: 'Jackson',
      state: 'WY',
    },
    83011: {
      lat: 43.58837,
      lng: -110.43192,
      city: 'Kelly',
      state: 'WY',
    },
    83012: {
      lat: 43.7073,
      lng: -110.75036,
      city: 'Moose',
      state: 'WY',
    },
    83013: {
      lat: 43.95666,
      lng: -110.45191,
      city: 'Moran',
      state: 'WY',
    },
    83014: {
      lat: 43.458,
      lng: -110.93746,
      city: 'Wilson',
      state: 'WY',
    },
    83025: {
      lat: 43.59371,
      lng: -110.82814,
      city: 'Teton Village',
      state: 'WY',
    },
    83101: {
      lat: 41.97455,
      lng: -110.5468,
      city: 'Kemmerer',
      state: 'WY',
    },
    83110: {
      lat: 42.72421,
      lng: -110.90108,
      city: 'Afton',
      state: 'WY',
    },
    83111: {
      lat: 42.819,
      lng: -111.01143,
      city: 'Auburn',
      state: 'WY',
    },
    83112: {
      lat: 42.88191,
      lng: -110.8833,
      city: 'Bedford',
      state: 'WY',
    },
    83113: {
      lat: 42.55189,
      lng: -110.25204,
      city: 'Big Piney',
      state: 'WY',
    },
    83114: {
      lat: 42.09736,
      lng: -110.956,
      city: 'Cokeville',
      state: 'WY',
    },
    83115: {
      lat: 42.92003,
      lng: -110.24036,
      city: 'Daniel',
      state: 'WY',
    },
    83116: {
      lat: 41.76333,
      lng: -110.54415,
      city: 'Diamondville',
      state: 'WY',
    },
    83118: { lat: 43.06638, lng: -110.9246, city: 'Etna', state: 'WY' },
    83119: {
      lat: 42.64394,
      lng: -110.98828,
      city: 'Fairview',
      state: 'WY',
    },
    83120: {
      lat: 43.06595,
      lng: -111.13529,
      city: 'Freedom',
      state: 'WY',
    },
    83121: {
      lat: 41.84982,
      lng: -110.54023,
      city: 'Frontier',
      state: 'WY',
    },
    83122: {
      lat: 42.82175,
      lng: -110.95843,
      city: 'Grover',
      state: 'WY',
    },
    83123: {
      lat: 42.23985,
      lng: -110.25947,
      city: 'La Barge',
      state: 'WY',
    },
    83124: {
      lat: 41.75002,
      lng: -110.20865,
      city: 'Opal',
      state: 'WY',
    },
    83126: {
      lat: 42.58697,
      lng: -110.90916,
      city: 'Smoot',
      state: 'WY',
    },
    83127: {
      lat: 42.91815,
      lng: -110.99756,
      city: 'Thayne',
      state: 'WY',
    },
    83128: {
      lat: 43.14762,
      lng: -110.7918,
      city: 'Alpine',
      state: 'WY',
    },
    83201: {
      lat: 42.88949,
      lng: -112.38324,
      city: 'Pocatello',
      state: 'ID',
    },
    83202: {
      lat: 42.97057,
      lng: -112.34734,
      city: 'Pocatello',
      state: 'ID',
    },
    83203: {
      lat: 43.0252,
      lng: -112.42813,
      city: 'Fort Hall',
      state: 'ID',
    },
    83204: {
      lat: 42.79219,
      lng: -112.51877,
      city: 'Pocatello',
      state: 'ID',
    },
    83209: {
      lat: 42.85782,
      lng: -112.42619,
      city: 'Pocatello',
      state: 'ID',
    },
    83210: {
      lat: 43.02069,
      lng: -112.86048,
      city: 'Aberdeen',
      state: 'ID',
    },
    83211: {
      lat: 42.66257,
      lng: -113.06186,
      city: 'American Falls',
      state: 'ID',
    },
    83212: {
      lat: 42.50847,
      lng: -112.54828,
      city: 'Arbon',
      state: 'ID',
    },
    83213: {
      lat: 43.55171,
      lng: -113.35809,
      city: 'Arco',
      state: 'ID',
    },
    83214: {
      lat: 42.54495,
      lng: -112.29362,
      city: 'Arimo',
      state: 'ID',
    },
    83215: {
      lat: 43.44314,
      lng: -112.81239,
      city: 'Atomic City',
      state: 'ID',
    },
    83217: {
      lat: 42.77525,
      lng: -111.93559,
      city: 'Bancroft',
      state: 'ID',
    },
    83218: {
      lat: 43.31803,
      lng: -112.16453,
      city: 'Basalt',
      state: 'ID',
    },
    83220: {
      lat: 42.34465,
      lng: -111.38008,
      city: 'Bern',
      state: 'ID',
    },
    83221: {
      lat: 43.30874,
      lng: -112.53217,
      city: 'Blackfoot',
      state: 'ID',
    },
    83223: {
      lat: 42.18249,
      lng: -111.40327,
      city: 'Bloomington',
      state: 'ID',
    },
    83226: {
      lat: 44.40775,
      lng: -114.06951,
      city: 'Challis',
      state: 'ID',
    },
    83227: {
      lat: 44.16563,
      lng: -114.49424,
      city: 'Clayton',
      state: 'ID',
    },
    83228: {
      lat: 42.19496,
      lng: -112.03658,
      city: 'Clifton',
      state: 'ID',
    },
    83232: {
      lat: 42.13341,
      lng: -111.97245,
      city: 'Dayton',
      state: 'ID',
    },
    83233: {
      lat: 42.15664,
      lng: -111.25256,
      city: 'Dingle',
      state: 'ID',
    },
    83234: {
      lat: 42.41661,
      lng: -112.09482,
      city: 'Downey',
      state: 'ID',
    },
    83235: {
      lat: 44.60163,
      lng: -114.03404,
      city: 'Ellis',
      state: 'ID',
    },
    83236: {
      lat: 43.22819,
      lng: -111.94894,
      city: 'Firth',
      state: 'ID',
    },
    83237: {
      lat: 42.02737,
      lng: -111.77626,
      city: 'Franklin',
      state: 'ID',
    },
    83238: {
      lat: 42.37942,
      lng: -111.10593,
      city: 'Geneva',
      state: 'ID',
    },
    83239: {
      lat: 42.53167,
      lng: -111.36528,
      city: 'Georgetown',
      state: 'ID',
    },
    83241: {
      lat: 42.51527,
      lng: -111.76214,
      city: 'Grace',
      state: 'ID',
    },
    83243: {
      lat: 42.24444,
      lng: -112.67292,
      city: 'Holbrook',
      state: 'ID',
    },
    83244: {
      lat: 43.94076,
      lng: -113.11287,
      city: 'Howe',
      state: 'ID',
    },
    83245: {
      lat: 42.82286,
      lng: -112.21713,
      city: 'Inkom',
      state: 'ID',
    },
    83246: {
      lat: 42.60616,
      lng: -112.03051,
      city: 'Lava Hot Springs',
      state: 'ID',
    },
    83250: {
      lat: 42.67228,
      lng: -112.20865,
      city: 'Mccammon',
      state: 'ID',
    },
    83251: {
      lat: 43.90738,
      lng: -113.86425,
      city: 'Mackay',
      state: 'ID',
    },
    83252: {
      lat: 42.18979,
      lng: -112.38411,
      city: 'Malad City',
      state: 'ID',
    },
    83253: { lat: 44.54647, lng: -113.77167, city: 'May', state: 'ID' },
    83254: {
      lat: 42.36403,
      lng: -111.2655,
      city: 'Montpelier',
      state: 'ID',
    },
    83255: {
      lat: 43.72829,
      lng: -113.45702,
      city: 'Moore',
      state: 'ID',
    },
    83261: {
      lat: 42.22112,
      lng: -111.40661,
      city: 'Paris',
      state: 'ID',
    },
    83262: {
      lat: 43.18419,
      lng: -112.7658,
      city: 'Pingree',
      state: 'ID',
    },
    83263: {
      lat: 42.17757,
      lng: -111.76101,
      city: 'Preston',
      state: 'ID',
    },
    83271: {
      lat: 42.4574,
      lng: -112.84551,
      city: 'Rockland',
      state: 'ID',
    },
    83272: {
      lat: 42.09156,
      lng: -111.36835,
      city: 'Saint Charles',
      state: 'ID',
    },
    83274: {
      lat: 43.35049,
      lng: -112.09904,
      city: 'Shelley',
      state: 'ID',
    },
    83276: {
      lat: 42.75942,
      lng: -111.44451,
      city: 'Soda Springs',
      state: 'ID',
    },
    83277: {
      lat: 43.07429,
      lng: -112.68127,
      city: 'Springfield',
      state: 'ID',
    },
    83278: {
      lat: 44.36917,
      lng: -114.85619,
      city: 'Stanley',
      state: 'ID',
    },
    83281: {
      lat: 42.31044,
      lng: -111.95953,
      city: 'Swanlake',
      state: 'ID',
    },
    83283: {
      lat: 42.35709,
      lng: -111.66445,
      city: 'Thatcher',
      state: 'ID',
    },
    83285: { lat: 43.061, lng: -111.37199, city: 'Wayan', state: 'ID' },
    83286: {
      lat: 42.0533,
      lng: -112.0148,
      city: 'Weston',
      state: 'ID',
    },
    83287: {
      lat: 42.03671,
      lng: -111.45894,
      city: 'Fish Haven',
      state: 'ID',
    },
    83301: {
      lat: 42.39472,
      lng: -114.52203,
      city: 'Twin Falls',
      state: 'ID',
    },
    83302: {
      lat: 42.10012,
      lng: -114.82857,
      city: 'Rogerson',
      state: 'ID',
    },
    83311: {
      lat: 42.40396,
      lng: -113.55864,
      city: 'Albion',
      state: 'ID',
    },
    83312: { lat: 42.10296, lng: -113.6319, city: 'Almo', state: 'ID' },
    83313: {
      lat: 43.3586,
      lng: -114.20511,
      city: 'Bellevue',
      state: 'ID',
    },
    83314: {
      lat: 42.97376,
      lng: -114.95228,
      city: 'Bliss',
      state: 'ID',
    },
    83316: {
      lat: 42.60622,
      lng: -114.88507,
      city: 'Buhl',
      state: 'ID',
    },
    83318: {
      lat: 42.43434,
      lng: -113.82007,
      city: 'Burley',
      state: 'ID',
    },
    83320: {
      lat: 43.40515,
      lng: -113.88309,
      city: 'Carey',
      state: 'ID',
    },
    83321: {
      lat: 42.35955,
      lng: -114.86312,
      city: 'Castleford',
      state: 'ID',
    },
    83322: {
      lat: 43.31475,
      lng: -114.96811,
      city: 'Corral',
      state: 'ID',
    },
    83323: {
      lat: 42.46902,
      lng: -113.4081,
      city: 'Declo',
      state: 'ID',
    },
    83324: {
      lat: 42.87725,
      lng: -114.2615,
      city: 'Dietrich',
      state: 'ID',
    },
    83325: {
      lat: 42.58675,
      lng: -114.24282,
      city: 'Eden',
      state: 'ID',
    },
    83327: {
      lat: 43.51145,
      lng: -114.7961,
      city: 'Fairfield',
      state: 'ID',
    },
    83328: {
      lat: 42.56962,
      lng: -114.61665,
      city: 'Filer',
      state: 'ID',
    },
    83330: {
      lat: 42.94207,
      lng: -114.69291,
      city: 'Gooding',
      state: 'ID',
    },
    83332: {
      lat: 42.79758,
      lng: -114.92962,
      city: 'Hagerman',
      state: 'ID',
    },
    83333: {
      lat: 43.57589,
      lng: -114.2214,
      city: 'Hailey',
      state: 'ID',
    },
    83334: {
      lat: 42.4243,
      lng: -114.29126,
      city: 'Hansen',
      state: 'ID',
    },
    83335: {
      lat: 42.58726,
      lng: -114.07347,
      city: 'Hazelton',
      state: 'ID',
    },
    83336: {
      lat: 42.5626,
      lng: -113.81699,
      city: 'Heyburn',
      state: 'ID',
    },
    83337: {
      lat: 43.21807,
      lng: -115.1575,
      city: 'Hill City',
      state: 'ID',
    },
    83338: {
      lat: 42.72501,
      lng: -114.43273,
      city: 'Jerome',
      state: 'ID',
    },
    83340: {
      lat: 43.77178,
      lng: -114.58138,
      city: 'Ketchum',
      state: 'ID',
    },
    83341: {
      lat: 42.42875,
      lng: -114.37052,
      city: 'Kimberly',
      state: 'ID',
    },
    83342: {
      lat: 42.15014,
      lng: -113.22915,
      city: 'Malta',
      state: 'ID',
    },
    83344: {
      lat: 42.40437,
      lng: -114.11768,
      city: 'Murtaugh',
      state: 'ID',
    },
    83346: {
      lat: 42.19491,
      lng: -113.9046,
      city: 'Oakley',
      state: 'ID',
    },
    83347: {
      lat: 42.73812,
      lng: -113.87705,
      city: 'Paul',
      state: 'ID',
    },
    83348: {
      lat: 43.28489,
      lng: -114.10592,
      city: 'Picabo',
      state: 'ID',
    },
    83349: {
      lat: 43.10148,
      lng: -114.17784,
      city: 'Richfield',
      state: 'ID',
    },
    83350: {
      lat: 42.70996,
      lng: -113.59812,
      city: 'Rupert',
      state: 'ID',
    },
    83352: {
      lat: 43.05133,
      lng: -114.40584,
      city: 'Shoshone',
      state: 'ID',
    },
    83353: {
      lat: 43.68625,
      lng: -114.33354,
      city: 'Sun Valley',
      state: 'ID',
    },
    83354: {
      lat: 43.67012,
      lng: -114.32235,
      city: 'Sun Valley',
      state: 'ID',
    },
    83355: {
      lat: 42.75515,
      lng: -114.72676,
      city: 'Wendell',
      state: 'ID',
    },
    83401: {
      lat: 43.53222,
      lng: -111.86516,
      city: 'Idaho Falls',
      state: 'ID',
    },
    83402: {
      lat: 43.53011,
      lng: -112.16786,
      city: 'Idaho Falls',
      state: 'ID',
    },
    83404: {
      lat: 43.42818,
      lng: -112.01432,
      city: 'Idaho Falls',
      state: 'ID',
    },
    83406: {
      lat: 43.43768,
      lng: -111.93382,
      city: 'Idaho Falls',
      state: 'ID',
    },
    83414: {
      lat: 43.88847,
      lng: -110.94924,
      city: 'Alta',
      state: 'WY',
    },
    83420: {
      lat: 44.06001,
      lng: -111.32243,
      city: 'Ashton',
      state: 'ID',
    },
    83421: {
      lat: 44.00349,
      lng: -111.54057,
      city: 'Chester',
      state: 'ID',
    },
    83422: {
      lat: 43.70463,
      lng: -111.23914,
      city: 'Driggs',
      state: 'ID',
    },
    83423: {
      lat: 44.33967,
      lng: -112.33199,
      city: 'Dubois',
      state: 'ID',
    },
    83424: {
      lat: 43.90488,
      lng: -111.19136,
      city: 'Felt',
      state: 'ID',
    },
    83425: {
      lat: 43.93106,
      lng: -112.21677,
      city: 'Hamer',
      state: 'ID',
    },
    83427: {
      lat: 43.35936,
      lng: -111.75064,
      city: 'Iona',
      state: 'ID',
    },
    83428: {
      lat: 43.3833,
      lng: -111.16093,
      city: 'Irwin',
      state: 'ID',
    },
    83429: {
      lat: 44.48674,
      lng: -111.37366,
      city: 'Island Park',
      state: 'ID',
    },
    83431: {
      lat: 43.68808,
      lng: -112.03012,
      city: 'Lewisville',
      state: 'ID',
    },
    83433: {
      lat: 44.49284,
      lng: -111.31788,
      city: 'Macks Inn',
      state: 'ID',
    },
    83434: {
      lat: 43.75636,
      lng: -112.00758,
      city: 'Menan',
      state: 'ID',
    },
    83435: {
      lat: 43.98469,
      lng: -112.58043,
      city: 'Monteview',
      state: 'ID',
    },
    83436: {
      lat: 43.8588,
      lng: -111.46665,
      city: 'Newdale',
      state: 'ID',
    },
    83438: {
      lat: 43.9597,
      lng: -111.75932,
      city: 'Parker',
      state: 'ID',
    },
    83440: {
      lat: 43.81092,
      lng: -111.80779,
      city: 'Rexburg',
      state: 'ID',
    },
    83442: {
      lat: 43.62931,
      lng: -111.82507,
      city: 'Rigby',
      state: 'ID',
    },
    83443: {
      lat: 43.50942,
      lng: -111.56155,
      city: 'Ririe',
      state: 'ID',
    },
    83444: {
      lat: 43.69748,
      lng: -112.24406,
      city: 'Roberts',
      state: 'ID',
    },
    83445: {
      lat: 44.07014,
      lng: -111.73075,
      city: 'Saint Anthony',
      state: 'ID',
    },
    83446: {
      lat: 44.37841,
      lng: -112.21887,
      city: 'Spencer',
      state: 'ID',
    },
    83448: {
      lat: 43.85082,
      lng: -111.70529,
      city: 'Sugar City',
      state: 'ID',
    },
    83449: {
      lat: 43.42487,
      lng: -111.35966,
      city: 'Swan Valley',
      state: 'ID',
    },
    83450: {
      lat: 43.8463,
      lng: -112.41356,
      city: 'Terreton',
      state: 'ID',
    },
    83451: {
      lat: 43.86595,
      lng: -111.63489,
      city: 'Teton',
      state: 'ID',
    },
    83452: {
      lat: 43.83619,
      lng: -111.21239,
      city: 'Tetonia',
      state: 'ID',
    },
    83454: { lat: 43.5946, lng: -111.95698, city: 'Ucon', state: 'ID' },
    83455: {
      lat: 43.60638,
      lng: -111.14077,
      city: 'Victor',
      state: 'ID',
    },
    83460: {
      lat: 43.81639,
      lng: -111.7826,
      city: 'Rexburg',
      state: 'ID',
    },
    83462: {
      lat: 45.30698,
      lng: -113.81069,
      city: 'Carmen',
      state: 'ID',
    },
    83463: {
      lat: 45.5706,
      lng: -113.97716,
      city: 'Gibbonsville',
      state: 'ID',
    },
    83464: {
      lat: 44.48105,
      lng: -113.20271,
      city: 'Leadore',
      state: 'ID',
    },
    83465: {
      lat: 44.77581,
      lng: -113.69949,
      city: 'Lemhi',
      state: 'ID',
    },
    83466: {
      lat: 45.44184,
      lng: -114.05549,
      city: 'North Fork',
      state: 'ID',
    },
    83467: {
      lat: 45.02006,
      lng: -113.89828,
      city: 'Salmon',
      state: 'ID',
    },
    83468: {
      lat: 44.92444,
      lng: -113.61293,
      city: 'Tendoy',
      state: 'ID',
    },
    83469: {
      lat: 45.1127,
      lng: -114.47772,
      city: 'Shoup',
      state: 'ID',
    },
    83501: {
      lat: 46.2283,
      lng: -116.90608,
      city: 'Lewiston',
      state: 'ID',
    },
    83520: {
      lat: 46.53936,
      lng: -116.32366,
      city: 'Ahsahka',
      state: 'ID',
    },
    83522: {
      lat: 45.91273,
      lng: -116.50102,
      city: 'Cottonwood',
      state: 'ID',
    },
    83523: {
      lat: 46.22939,
      lng: -116.47224,
      city: 'Craigmont',
      state: 'ID',
    },
    83524: {
      lat: 46.3586,
      lng: -116.65439,
      city: 'Culdesac',
      state: 'ID',
    },
    83525: {
      lat: 45.73798,
      lng: -115.39367,
      city: 'Elk City',
      state: 'ID',
    },
    83526: {
      lat: 46.14759,
      lng: -116.4169,
      city: 'Ferdinand',
      state: 'ID',
    },
    83530: {
      lat: 45.91516,
      lng: -116.02117,
      city: 'Grangeville',
      state: 'ID',
    },
    83533: {
      lat: 46.11707,
      lng: -116.24063,
      city: 'Greencreek',
      state: 'ID',
    },
    83535: {
      lat: 46.54011,
      lng: -116.72599,
      city: 'Juliaetta',
      state: 'ID',
    },
    83536: {
      lat: 46.21794,
      lng: -116.02665,
      city: 'Kamiah',
      state: 'ID',
    },
    83537: {
      lat: 46.62877,
      lng: -116.52348,
      city: 'Kendrick',
      state: 'ID',
    },
    83539: {
      lat: 46.20361,
      lng: -114.93684,
      city: 'Kooskia',
      state: 'ID',
    },
    83540: {
      lat: 46.34649,
      lng: -116.78273,
      city: 'Lapwai',
      state: 'ID',
    },
    83541: {
      lat: 46.49297,
      lng: -116.50156,
      city: 'Lenore',
      state: 'ID',
    },
    83542: {
      lat: 45.55291,
      lng: -116.3115,
      city: 'Lucile',
      state: 'ID',
    },
    83543: {
      lat: 46.27358,
      lng: -116.23815,
      city: 'Nezperce',
      state: 'ID',
    },
    83544: {
      lat: 46.49365,
      lng: -116.1525,
      city: 'Orofino',
      state: 'ID',
    },
    83545: { lat: 46.42664, lng: -116.4213, city: 'Peck', state: 'ID' },
    83546: {
      lat: 46.57326,
      lng: -115.81115,
      city: 'Pierce',
      state: 'ID',
    },
    83547: {
      lat: 45.31165,
      lng: -116.33062,
      city: 'Pollock',
      state: 'ID',
    },
    83548: {
      lat: 46.34878,
      lng: -116.50843,
      city: 'Reubens',
      state: 'ID',
    },
    83549: {
      lat: 45.36641,
      lng: -116.2549,
      city: 'Riggins',
      state: 'ID',
    },
    83552: {
      lat: 45.9777,
      lng: -115.93948,
      city: 'Stites',
      state: 'ID',
    },
    83553: {
      lat: 46.38365,
      lng: -115.9038,
      city: 'Weippe',
      state: 'ID',
    },
    83554: {
      lat: 45.68522,
      lng: -116.29936,
      city: 'White Bird',
      state: 'ID',
    },
    83555: {
      lat: 46.08662,
      lng: -116.72221,
      city: 'Winchester',
      state: 'ID',
    },
    83602: {
      lat: 44.09676,
      lng: -116.15049,
      city: 'Banks',
      state: 'ID',
    },
    83604: {
      lat: 42.52727,
      lng: -115.84966,
      city: 'Bruneau',
      state: 'ID',
    },
    83605: {
      lat: 43.66442,
      lng: -116.65171,
      city: 'Caldwell',
      state: 'ID',
    },
    83607: {
      lat: 43.7116,
      lng: -116.74362,
      city: 'Caldwell',
      state: 'ID',
    },
    83610: {
      lat: 44.70231,
      lng: -116.78239,
      city: 'Cambridge',
      state: 'ID',
    },
    83611: {
      lat: 45.03935,
      lng: -115.24709,
      city: 'Cascade',
      state: 'ID',
    },
    83612: {
      lat: 44.8438,
      lng: -116.53296,
      city: 'Council',
      state: 'ID',
    },
    83615: {
      lat: 44.70288,
      lng: -116.0484,
      city: 'Donnelly',
      state: 'ID',
    },
    83616: {
      lat: 43.77667,
      lng: -116.38977,
      city: 'Eagle',
      state: 'ID',
    },
    83617: {
      lat: 43.93303,
      lng: -116.50368,
      city: 'Emmett',
      state: 'ID',
    },
    83619: {
      lat: 43.96982,
      lng: -116.91204,
      city: 'Fruitland',
      state: 'ID',
    },
    83622: {
      lat: 44.1065,
      lng: -115.88953,
      city: 'Garden Valley',
      state: 'ID',
    },
    83623: {
      lat: 43.04932,
      lng: -115.39485,
      city: 'Glenns Ferry',
      state: 'ID',
    },
    83624: {
      lat: 42.92441,
      lng: -116.14266,
      city: 'Grand View',
      state: 'ID',
    },
    83626: {
      lat: 43.66876,
      lng: -116.82815,
      city: 'Greenleaf',
      state: 'ID',
    },
    83627: {
      lat: 42.91336,
      lng: -115.53238,
      city: 'Hammett',
      state: 'ID',
    },
    83628: {
      lat: 43.57934,
      lng: -116.96975,
      city: 'Homedale',
      state: 'ID',
    },
    83629: {
      lat: 43.91115,
      lng: -116.16947,
      city: 'Horseshoe Bend',
      state: 'ID',
    },
    83631: {
      lat: 43.88543,
      lng: -115.77369,
      city: 'Idaho City',
      state: 'ID',
    },
    83632: {
      lat: 44.53228,
      lng: -116.40994,
      city: 'Indian Valley',
      state: 'ID',
    },
    83633: {
      lat: 43.0297,
      lng: -115.21253,
      city: 'King Hill',
      state: 'ID',
    },
    83634: {
      lat: 43.44824,
      lng: -116.33287,
      city: 'Kuna',
      state: 'ID',
    },
    83636: {
      lat: 43.90832,
      lng: -116.66097,
      city: 'Letha',
      state: 'ID',
    },
    83637: {
      lat: 44.13249,
      lng: -115.29625,
      city: 'Lowman',
      state: 'ID',
    },
    83638: {
      lat: 45.1504,
      lng: -115.82001,
      city: 'Mccall',
      state: 'ID',
    },
    83639: {
      lat: 43.36878,
      lng: -116.92472,
      city: 'Marsing',
      state: 'ID',
    },
    83641: {
      lat: 43.35079,
      lng: -116.6357,
      city: 'Melba',
      state: 'ID',
    },
    83642: {
      lat: 43.57334,
      lng: -116.40116,
      city: 'Meridian',
      state: 'ID',
    },
    83643: {
      lat: 44.62983,
      lng: -116.45439,
      city: 'Mesa',
      state: 'ID',
    },
    83644: {
      lat: 43.74823,
      lng: -116.58455,
      city: 'Middleton',
      state: 'ID',
    },
    83645: {
      lat: 44.36016,
      lng: -116.56454,
      city: 'Midvale',
      state: 'ID',
    },
    83646: {
      lat: 43.65,
      lng: -116.43247,
      city: 'Meridian',
      state: 'ID',
    },
    83647: {
      lat: 43.39487,
      lng: -115.51159,
      city: 'Mountain Home',
      state: 'ID',
    },
    83648: {
      lat: 43.04962,
      lng: -115.86554,
      city: 'Mountain Home Afb',
      state: 'ID',
    },
    83650: {
      lat: 42.87041,
      lng: -116.65518,
      city: 'Murphy',
      state: 'ID',
    },
    83651: {
      lat: 43.58438,
      lng: -116.62884,
      city: 'Nampa',
      state: 'ID',
    },
    83654: {
      lat: 45.13647,
      lng: -116.39626,
      city: 'New Meadows',
      state: 'ID',
    },
    83655: {
      lat: 43.95135,
      lng: -116.79171,
      city: 'New Plymouth',
      state: 'ID',
    },
    83656: {
      lat: 43.7255,
      lng: -116.79852,
      city: 'Notus',
      state: 'ID',
    },
    83657: { lat: 44.23554, lng: -116.30057, city: 'Ola', state: 'ID' },
    83660: {
      lat: 43.80016,
      lng: -116.9301,
      city: 'Parma',
      state: 'ID',
    },
    83661: {
      lat: 44.10617,
      lng: -116.69337,
      city: 'Payette',
      state: 'ID',
    },
    83666: {
      lat: 43.96157,
      lng: -115.97398,
      city: 'Placerville',
      state: 'ID',
    },
    83669: {
      lat: 43.71899,
      lng: -116.50147,
      city: 'Star',
      state: 'ID',
    },
    83670: {
      lat: 44.0189,
      lng: -116.30793,
      city: 'Sweet',
      state: 'ID',
    },
    83672: {
      lat: 44.38095,
      lng: -116.97188,
      city: 'Weiser',
      state: 'ID',
    },
    83676: {
      lat: 43.65743,
      lng: -116.91156,
      city: 'Wilder',
      state: 'ID',
    },
    83677: {
      lat: 45.03763,
      lng: -115.38691,
      city: 'Yellow Pine',
      state: 'ID',
    },
    83686: {
      lat: 43.49643,
      lng: -116.61207,
      city: 'Nampa',
      state: 'ID',
    },
    83687: {
      lat: 43.60998,
      lng: -116.52997,
      city: 'Nampa',
      state: 'ID',
    },
    83702: {
      lat: 43.66821,
      lng: -116.16493,
      city: 'Boise',
      state: 'ID',
    },
    83703: {
      lat: 43.66189,
      lng: -116.23906,
      city: 'Boise',
      state: 'ID',
    },
    83704: {
      lat: 43.62765,
      lng: -116.28716,
      city: 'Boise',
      state: 'ID',
    },
    83705: {
      lat: 43.55609,
      lng: -116.22002,
      city: 'Boise',
      state: 'ID',
    },
    83706: {
      lat: 43.59108,
      lng: -116.19427,
      city: 'Boise',
      state: 'ID',
    },
    83709: {
      lat: 43.55212,
      lng: -116.28847,
      city: 'Boise',
      state: 'ID',
    },
    83712: {
      lat: 43.61663,
      lng: -116.11766,
      city: 'Boise',
      state: 'ID',
    },
    83713: {
      lat: 43.63897,
      lng: -116.33464,
      city: 'Boise',
      state: 'ID',
    },
    83714: {
      lat: 43.72444,
      lng: -116.27017,
      city: 'Garden City',
      state: 'ID',
    },
    83716: {
      lat: 43.67271,
      lng: -115.6548,
      city: 'Boise',
      state: 'ID',
    },
    83720: {
      lat: 43.61771,
      lng: -116.19973,
      city: 'Boise',
      state: 'ID',
    },
    83725: {
      lat: 43.60484,
      lng: -116.20569,
      city: 'Boise',
      state: 'ID',
    },
    83801: {
      lat: 47.95631,
      lng: -116.6434,
      city: 'Athol',
      state: 'ID',
    },
    83802: {
      lat: 47.26665,
      lng: -115.72815,
      city: 'Avery',
      state: 'ID',
    },
    83803: {
      lat: 48.02261,
      lng: -116.39878,
      city: 'Bayview',
      state: 'ID',
    },
    83804: {
      lat: 48.03714,
      lng: -116.97315,
      city: 'Blanchard',
      state: 'ID',
    },
    83805: {
      lat: 48.83203,
      lng: -116.49248,
      city: 'Bonners Ferry',
      state: 'ID',
    },
    83806: {
      lat: 46.88243,
      lng: -116.37038,
      city: 'Bovill',
      state: 'ID',
    },
    83808: {
      lat: 47.31276,
      lng: -116.07659,
      city: 'Calder',
      state: 'ID',
    },
    83809: {
      lat: 48.06234,
      lng: -116.58116,
      city: 'Careywood',
      state: 'ID',
    },
    83810: {
      lat: 47.54326,
      lng: -116.45525,
      city: 'Cataldo',
      state: 'ID',
    },
    83811: {
      lat: 48.11296,
      lng: -116.15574,
      city: 'Clark Fork',
      state: 'ID',
    },
    83812: {
      lat: 47.00311,
      lng: -116.28295,
      city: 'Clarkia',
      state: 'ID',
    },
    83813: {
      lat: 48.10462,
      lng: -116.63734,
      city: 'Cocolalla',
      state: 'ID',
    },
    83814: {
      lat: 47.63717,
      lng: -116.75811,
      city: 'Coeur D Alene',
      state: 'ID',
    },
    83815: {
      lat: 47.72498,
      lng: -116.78732,
      city: 'Coeur D Alene',
      state: 'ID',
    },
    83821: {
      lat: 48.59365,
      lng: -116.8367,
      city: 'Coolin',
      state: 'ID',
    },
    83822: {
      lat: 48.16785,
      lng: -117.0014,
      city: 'Oldtown',
      state: 'ID',
    },
    83823: {
      lat: 46.80554,
      lng: -116.51934,
      city: 'Deary',
      state: 'ID',
    },
    83824: {
      lat: 47.10638,
      lng: -116.93901,
      city: 'Desmet',
      state: 'ID',
    },
    83825: {
      lat: 48.24694,
      lng: -116.6076,
      city: 'Dover',
      state: 'ID',
    },
    83826: {
      lat: 48.98382,
      lng: -116.15188,
      city: 'Eastport',
      state: 'ID',
    },
    83827: {
      lat: 46.78035,
      lng: -116.17469,
      city: 'Elk River',
      state: 'ID',
    },
    83830: {
      lat: 47.07651,
      lng: -116.35272,
      city: 'Fernwood',
      state: 'ID',
    },
    83832: {
      lat: 46.55223,
      lng: -116.90666,
      city: 'Genesee',
      state: 'ID',
    },
    83833: {
      lat: 47.51484,
      lng: -116.73284,
      city: 'Harrison',
      state: 'ID',
    },
    83834: {
      lat: 46.97566,
      lng: -116.67484,
      city: 'Harvard',
      state: 'ID',
    },
    83835: {
      lat: 47.79557,
      lng: -116.66843,
      city: 'Hayden',
      state: 'ID',
    },
    83836: {
      lat: 48.27046,
      lng: -116.24804,
      city: 'Hope',
      state: 'ID',
    },
    83837: {
      lat: 47.52835,
      lng: -116.09103,
      city: 'Kellogg',
      state: 'ID',
    },
    83839: {
      lat: 47.61647,
      lng: -116.17949,
      city: 'Kingston',
      state: 'ID',
    },
    83840: {
      lat: 48.31263,
      lng: -116.51506,
      city: 'Kootenai',
      state: 'ID',
    },
    83841: {
      lat: 48.19911,
      lng: -116.77851,
      city: 'Laclede',
      state: 'ID',
    },
    83842: {
      lat: 47.44046,
      lng: -116.55056,
      city: 'Medimont',
      state: 'ID',
    },
    83843: {
      lat: 46.72257,
      lng: -116.94132,
      city: 'Moscow',
      state: 'ID',
    },
    83844: {
      lat: 46.72823,
      lng: -117.01946,
      city: 'Moscow',
      state: 'ID',
    },
    83845: {
      lat: 48.74854,
      lng: -116.11302,
      city: 'Moyie Springs',
      state: 'ID',
    },
    83846: {
      lat: 47.47308,
      lng: -115.75009,
      city: 'Mullan',
      state: 'ID',
    },
    83847: {
      lat: 48.57699,
      lng: -116.43207,
      city: 'Naples',
      state: 'ID',
    },
    83848: {
      lat: 48.7067,
      lng: -116.92766,
      city: 'Nordman',
      state: 'ID',
    },
    83849: {
      lat: 47.51723,
      lng: -115.99531,
      city: 'Osburn',
      state: 'ID',
    },
    83850: {
      lat: 47.4421,
      lng: -116.20674,
      city: 'Pinehurst',
      state: 'ID',
    },
    83851: {
      lat: 47.29899,
      lng: -116.90513,
      city: 'Plummer',
      state: 'ID',
    },
    83852: {
      lat: 48.31105,
      lng: -116.53933,
      city: 'Ponderay',
      state: 'ID',
    },
    83854: {
      lat: 47.7267,
      lng: -116.97511,
      city: 'Post Falls',
      state: 'ID',
    },
    83855: {
      lat: 46.97188,
      lng: -116.92464,
      city: 'Potlatch',
      state: 'ID',
    },
    83856: {
      lat: 48.34228,
      lng: -116.91058,
      city: 'Priest River',
      state: 'ID',
    },
    83857: {
      lat: 46.8895,
      lng: -116.79776,
      city: 'Princeton',
      state: 'ID',
    },
    83858: {
      lat: 47.85245,
      lng: -116.90173,
      city: 'Rathdrum',
      state: 'ID',
    },
    83860: {
      lat: 48.19234,
      lng: -116.52918,
      city: 'Sagle',
      state: 'ID',
    },
    83861: {
      lat: 47.2488,
      lng: -116.57821,
      city: 'Saint Maries',
      state: 'ID',
    },
    83864: {
      lat: 48.43432,
      lng: -116.50559,
      city: 'Sandpoint',
      state: 'ID',
    },
    83866: {
      lat: 47.14286,
      lng: -116.43995,
      city: 'Santa',
      state: 'ID',
    },
    83867: {
      lat: 47.50624,
      lng: -115.94696,
      city: 'Silverton',
      state: 'ID',
    },
    83868: {
      lat: 47.52441,
      lng: -116.18439,
      city: 'Smelterville',
      state: 'ID',
    },
    83869: {
      lat: 47.98095,
      lng: -116.89239,
      city: 'Spirit Lake',
      state: 'ID',
    },
    83870: {
      lat: 47.13862,
      lng: -116.85372,
      city: 'Tensed',
      state: 'ID',
    },
    83871: {
      lat: 46.74589,
      lng: -116.73865,
      city: 'Troy',
      state: 'ID',
    },
    83872: {
      lat: 46.85772,
      lng: -116.97251,
      city: 'Viola',
      state: 'ID',
    },
    83873: {
      lat: 47.5982,
      lng: -115.90899,
      city: 'Wallace',
      state: 'ID',
    },
    83874: {
      lat: 47.63563,
      lng: -115.78882,
      city: 'Murray',
      state: 'ID',
    },
    83876: {
      lat: 47.44842,
      lng: -116.93161,
      city: 'Worley',
      state: 'ID',
    },
    84001: {
      lat: 40.34525,
      lng: -110.27241,
      city: 'Altamont',
      state: 'UT',
    },
    84002: {
      lat: 40.42467,
      lng: -110.27784,
      city: 'Altonah',
      state: 'UT',
    },
    84003: {
      lat: 40.46629,
      lng: -111.69334,
      city: 'American Fork',
      state: 'UT',
    },
    84004: {
      lat: 40.48444,
      lng: -111.74447,
      city: 'Alpine',
      state: 'UT',
    },
    84005: {
      lat: 40.32277,
      lng: -111.99942,
      city: 'Eagle Mountain',
      state: 'UT',
    },
    84006: {
      lat: 40.59682,
      lng: -112.12373,
      city: 'Bingham Canyon',
      state: 'UT',
    },
    84007: {
      lat: 40.34115,
      lng: -110.21717,
      city: 'Bluebell',
      state: 'UT',
    },
    84008: {
      lat: 40.03341,
      lng: -109.19376,
      city: 'Bonanza',
      state: 'UT',
    },
    84009: {
      lat: 40.55221,
      lng: -112.05362,
      city: 'South Jordan',
      state: 'UT',
    },
    84010: {
      lat: 40.87433,
      lng: -111.83042,
      city: 'Bountiful',
      state: 'UT',
    },
    84013: {
      lat: 40.2958,
      lng: -112.0913,
      city: 'Cedar Valley',
      state: 'UT',
    },
    84014: {
      lat: 40.93311,
      lng: -111.85308,
      city: 'Centerville',
      state: 'UT',
    },
    84015: {
      lat: 41.12073,
      lng: -112.06129,
      city: 'Clearfield',
      state: 'UT',
    },
    84017: {
      lat: 40.9549,
      lng: -111.20582,
      city: 'Coalville',
      state: 'UT',
    },
    84018: {
      lat: 41.20535,
      lng: -111.42716,
      city: 'Croydon',
      state: 'UT',
    },
    84020: {
      lat: 40.49885,
      lng: -111.8635,
      city: 'Draper',
      state: 'UT',
    },
    84021: {
      lat: 40.10998,
      lng: -110.51593,
      city: 'Duchesne',
      state: 'UT',
    },
    84022: {
      lat: 40.17921,
      lng: -112.81371,
      city: 'Dugway',
      state: 'UT',
    },
    84023: {
      lat: 40.83781,
      lng: -109.20665,
      city: 'Dutch John',
      state: 'UT',
    },
    84024: {
      lat: 41.00765,
      lng: -111.44721,
      city: 'Echo',
      state: 'UT',
    },
    84025: {
      lat: 40.97667,
      lng: -111.89874,
      city: 'Farmington',
      state: 'UT',
    },
    84026: {
      lat: 40.29122,
      lng: -109.83882,
      city: 'Fort Duchesne',
      state: 'UT',
    },
    84027: {
      lat: 40.15643,
      lng: -110.79453,
      city: 'Fruitland',
      state: 'UT',
    },
    84028: {
      lat: 41.93107,
      lng: -111.38267,
      city: 'Garden City',
      state: 'UT',
    },
    84029: {
      lat: 40.6483,
      lng: -112.98298,
      city: 'Grantsville',
      state: 'UT',
    },
    84031: {
      lat: 40.60493,
      lng: -110.63735,
      city: 'Hanna',
      state: 'UT',
    },
    84032: {
      lat: 40.35839,
      lng: -111.188,
      city: 'Heber City',
      state: 'UT',
    },
    84033: {
      lat: 40.98002,
      lng: -111.49148,
      city: 'Henefer',
      state: 'UT',
    },
    84034: {
      lat: 39.99427,
      lng: -113.9575,
      city: 'Ibapah',
      state: 'UT',
    },
    84035: {
      lat: 40.39024,
      lng: -109.28208,
      city: 'Jensen',
      state: 'UT',
    },
    84036: {
      lat: 40.66022,
      lng: -111.1093,
      city: 'Kamas',
      state: 'UT',
    },
    84037: {
      lat: 40.99281,
      lng: -111.91444,
      city: 'Kaysville',
      state: 'UT',
    },
    84038: {
      lat: 41.87926,
      lng: -111.29692,
      city: 'Laketown',
      state: 'UT',
    },
    84039: {
      lat: 40.44602,
      lng: -109.80972,
      city: 'Lapoint',
      state: 'UT',
    },
    84040: {
      lat: 41.09121,
      lng: -111.89674,
      city: 'Layton',
      state: 'UT',
    },
    84041: {
      lat: 41.07137,
      lng: -111.98416,
      city: 'Layton',
      state: 'UT',
    },
    84042: {
      lat: 40.34113,
      lng: -111.72466,
      city: 'Lindon',
      state: 'UT',
    },
    84043: {
      lat: 40.41209,
      lng: -111.87377,
      city: 'Lehi',
      state: 'UT',
    },
    84044: { lat: 40.73, lng: -112.1474, city: 'Magna', state: 'UT' },
    84045: {
      lat: 40.28574,
      lng: -111.91384,
      city: 'Saratoga Springs',
      state: 'UT',
    },
    84046: {
      lat: 40.95339,
      lng: -109.82786,
      city: 'Manila',
      state: 'UT',
    },
    84047: {
      lat: 40.61533,
      lng: -111.89176,
      city: 'Midvale',
      state: 'UT',
    },
    84049: {
      lat: 40.5136,
      lng: -111.51657,
      city: 'Midway',
      state: 'UT',
    },
    84050: {
      lat: 41.02244,
      lng: -111.67229,
      city: 'Morgan',
      state: 'UT',
    },
    84051: {
      lat: 40.39552,
      lng: -110.43131,
      city: 'Mountain Home',
      state: 'UT',
    },
    84052: {
      lat: 40.16829,
      lng: -109.99089,
      city: 'Myton',
      state: 'UT',
    },
    84053: {
      lat: 40.43325,
      lng: -109.98941,
      city: 'Neola',
      state: 'UT',
    },
    84054: {
      lat: 40.84078,
      lng: -111.91754,
      city: 'North Salt Lake',
      state: 'UT',
    },
    84055: {
      lat: 40.71889,
      lng: -111.16106,
      city: 'Oakley',
      state: 'UT',
    },
    84056: {
      lat: 41.12679,
      lng: -111.99084,
      city: 'Hill Afb',
      state: 'UT',
    },
    84057: {
      lat: 40.31427,
      lng: -111.70948,
      city: 'Orem',
      state: 'UT',
    },
    84058: {
      lat: 40.27474,
      lng: -111.71468,
      city: 'Orem',
      state: 'UT',
    },
    84059: {
      lat: 40.30353,
      lng: -111.74853,
      city: 'Vineyard',
      state: 'UT',
    },
    84060: {
      lat: 40.6418,
      lng: -111.50332,
      city: 'Park City',
      state: 'UT',
    },
    84061: { lat: 40.74721, lng: -111.3417, city: 'Peoa', state: 'UT' },
    84062: {
      lat: 40.38586,
      lng: -111.68421,
      city: 'Pleasant Grove',
      state: 'UT',
    },
    84063: {
      lat: 40.21007,
      lng: -109.69285,
      city: 'Randlett',
      state: 'UT',
    },
    84064: {
      lat: 41.67086,
      lng: -111.12293,
      city: 'Randolph',
      state: 'UT',
    },
    84065: {
      lat: 40.495,
      lng: -111.9444,
      city: 'Riverton',
      state: 'UT',
    },
    84066: {
      lat: 40.34671,
      lng: -110.05171,
      city: 'Roosevelt',
      state: 'UT',
    },
    84067: { lat: 41.17189, lng: -112.04803, city: 'Roy', state: 'UT' },
    84069: {
      lat: 40.36521,
      lng: -112.48548,
      city: 'Rush Valley',
      state: 'UT',
    },
    84070: {
      lat: 40.57707,
      lng: -111.88916,
      city: 'Sandy',
      state: 'UT',
    },
    84071: {
      lat: 40.4191,
      lng: -112.32475,
      city: 'Stockton',
      state: 'UT',
    },
    84072: {
      lat: 40.3641,
      lng: -110.633,
      city: 'Tabiona',
      state: 'UT',
    },
    84073: {
      lat: 40.3456,
      lng: -110.41394,
      city: 'Talmage',
      state: 'UT',
    },
    84074: {
      lat: 40.57379,
      lng: -112.29914,
      city: 'Tooele',
      state: 'UT',
    },
    84075: {
      lat: 41.05975,
      lng: -112.06933,
      city: 'Syracuse',
      state: 'UT',
    },
    84076: {
      lat: 40.44718,
      lng: -109.84017,
      city: 'Tridell',
      state: 'UT',
    },
    84078: {
      lat: 40.46403,
      lng: -109.5084,
      city: 'Vernal',
      state: 'UT',
    },
    84080: {
      lat: 40.02168,
      lng: -112.44818,
      city: 'Vernon',
      state: 'UT',
    },
    84081: {
      lat: 40.60275,
      lng: -112.03821,
      city: 'West Jordan',
      state: 'UT',
    },
    84082: {
      lat: 40.3404,
      lng: -111.36184,
      city: 'Wallsburg',
      state: 'UT',
    },
    84083: {
      lat: 40.84354,
      lng: -113.6944,
      city: 'Wendover',
      state: 'UT',
    },
    84084: {
      lat: 40.62139,
      lng: -111.96603,
      city: 'West Jordan',
      state: 'UT',
    },
    84085: {
      lat: 40.58609,
      lng: -109.92642,
      city: 'Whiterocks',
      state: 'UT',
    },
    84086: {
      lat: 41.41644,
      lng: -111.20858,
      city: 'Woodruff',
      state: 'UT',
    },
    84087: {
      lat: 40.8891,
      lng: -111.92898,
      city: 'Woods Cross',
      state: 'UT',
    },
    84088: {
      lat: 40.59507,
      lng: -111.96077,
      city: 'West Jordan',
      state: 'UT',
    },
    84092: {
      lat: 40.55803,
      lng: -111.74205,
      city: 'Sandy',
      state: 'UT',
    },
    84093: {
      lat: 40.59473,
      lng: -111.82863,
      city: 'Sandy',
      state: 'UT',
    },
    84094: {
      lat: 40.57177,
      lng: -111.86201,
      city: 'Sandy',
      state: 'UT',
    },
    84095: {
      lat: 40.55856,
      lng: -111.94165,
      city: 'South Jordan',
      state: 'UT',
    },
    84096: {
      lat: 40.50539,
      lng: -112.08546,
      city: 'Herriman',
      state: 'UT',
    },
    84097: { lat: 40.3048, lng: -111.67318, city: 'Orem', state: 'UT' },
    84098: {
      lat: 40.73988,
      lng: -111.53504,
      city: 'Park City',
      state: 'UT',
    },
    84101: {
      lat: 40.75628,
      lng: -111.89996,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84102: {
      lat: 40.75992,
      lng: -111.86367,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84103: {
      lat: 40.8034,
      lng: -111.84708,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84104: {
      lat: 40.74846,
      lng: -111.98286,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84105: {
      lat: 40.73841,
      lng: -111.85982,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84106: {
      lat: 40.70678,
      lng: -111.85571,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84107: {
      lat: 40.65799,
      lng: -111.88447,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84108: {
      lat: 40.78811,
      lng: -111.7365,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84109: {
      lat: 40.70196,
      lng: -111.7051,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84111: {
      lat: 40.75575,
      lng: -111.88431,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84112: {
      lat: 40.7652,
      lng: -111.84123,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84113: {
      lat: 40.76248,
      lng: -111.83291,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84114: {
      lat: 40.77794,
      lng: -111.88831,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84115: {
      lat: 40.71467,
      lng: -111.89266,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84116: {
      lat: 40.83258,
      lng: -112.01691,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84117: {
      lat: 40.66167,
      lng: -111.83561,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84118: {
      lat: 40.65598,
      lng: -112.03812,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84119: {
      lat: 40.70289,
      lng: -111.94591,
      city: 'West Valley City',
      state: 'UT',
    },
    84120: {
      lat: 40.69686,
      lng: -112.00178,
      city: 'West Valley City',
      state: 'UT',
    },
    84121: {
      lat: 40.62557,
      lng: -111.69864,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84123: {
      lat: 40.65837,
      lng: -111.92177,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84124: {
      lat: 40.67113,
      lng: -111.80705,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84128: {
      lat: 40.70518,
      lng: -112.04259,
      city: 'West Valley City',
      state: 'UT',
    },
    84129: {
      lat: 40.6536,
      lng: -111.96209,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84138: {
      lat: 40.76675,
      lng: -111.88724,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84150: {
      lat: 40.77151,
      lng: -111.89107,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84180: {
      lat: 40.77044,
      lng: -111.9011,
      city: 'Salt Lake City',
      state: 'UT',
    },
    84301: {
      lat: 41.61368,
      lng: -112.12723,
      city: 'Bear River City',
      state: 'UT',
    },
    84302: {
      lat: 41.57956,
      lng: -112.04769,
      city: 'Brigham City',
      state: 'UT',
    },
    84304: {
      lat: 41.82722,
      lng: -111.99867,
      city: 'Cache Junction',
      state: 'UT',
    },
    84305: {
      lat: 41.94388,
      lng: -112.05804,
      city: 'Clarkston',
      state: 'UT',
    },
    84306: {
      lat: 41.78272,
      lng: -112.06323,
      city: 'Collinston',
      state: 'UT',
    },
    84307: {
      lat: 41.5535,
      lng: -112.49192,
      city: 'Corinne',
      state: 'UT',
    },
    84308: {
      lat: 41.9656,
      lng: -111.96681,
      city: 'Cornish',
      state: 'UT',
    },
    84309: {
      lat: 41.71375,
      lng: -112.09908,
      city: 'Deweyville',
      state: 'UT',
    },
    84310: { lat: 41.3371, lng: -111.84598, city: 'Eden', state: 'UT' },
    84311: {
      lat: 41.82012,
      lng: -112.1106,
      city: 'Fielding',
      state: 'UT',
    },
    84312: {
      lat: 41.77976,
      lng: -112.15681,
      city: 'Garland',
      state: 'UT',
    },
    84313: {
      lat: 41.34615,
      lng: -113.71268,
      city: 'Grouse Creek',
      state: 'UT',
    },
    84314: {
      lat: 41.64929,
      lng: -112.09292,
      city: 'Honeyville',
      state: 'UT',
    },
    84315: {
      lat: 41.16681,
      lng: -112.13672,
      city: 'Hooper',
      state: 'UT',
    },
    84316: {
      lat: 41.77328,
      lng: -112.44504,
      city: 'Howell',
      state: 'UT',
    },
    84317: {
      lat: 41.30359,
      lng: -111.62178,
      city: 'Huntsville',
      state: 'UT',
    },
    84318: {
      lat: 41.80167,
      lng: -111.80422,
      city: 'Hyde Park',
      state: 'UT',
    },
    84319: { lat: 41.59401, lng: -111.659, city: 'Hyrum', state: 'UT' },
    84320: {
      lat: 41.96011,
      lng: -111.85252,
      city: 'Lewiston',
      state: 'UT',
    },
    84321: {
      lat: 41.77938,
      lng: -111.68316,
      city: 'Logan',
      state: 'UT',
    },
    84322: {
      lat: 41.75822,
      lng: -111.81256,
      city: 'Logan',
      state: 'UT',
    },
    84324: {
      lat: 41.49253,
      lng: -111.93868,
      city: 'Mantua',
      state: 'UT',
    },
    84325: {
      lat: 41.73869,
      lng: -111.99303,
      city: 'Mendon',
      state: 'UT',
    },
    84326: {
      lat: 41.67228,
      lng: -111.81772,
      city: 'Millville',
      state: 'UT',
    },
    84327: {
      lat: 41.86555,
      lng: -111.99512,
      city: 'Newton',
      state: 'UT',
    },
    84328: {
      lat: 41.53937,
      lng: -111.8444,
      city: 'Paradise',
      state: 'UT',
    },
    84329: {
      lat: 41.79229,
      lng: -113.47079,
      city: 'Park Valley',
      state: 'UT',
    },
    84330: {
      lat: 41.9063,
      lng: -112.17019,
      city: 'Plymouth',
      state: 'UT',
    },
    84331: {
      lat: 41.94354,
      lng: -112.35869,
      city: 'Portage',
      state: 'UT',
    },
    84332: {
      lat: 41.70313,
      lng: -111.81205,
      city: 'Providence',
      state: 'UT',
    },
    84333: {
      lat: 41.91008,
      lng: -111.79882,
      city: 'Richmond',
      state: 'UT',
    },
    84334: {
      lat: 41.78955,
      lng: -112.15189,
      city: 'Riverside',
      state: 'UT',
    },
    84335: {
      lat: 41.82907,
      lng: -111.88074,
      city: 'Smithfield',
      state: 'UT',
    },
    84336: {
      lat: 41.87159,
      lng: -112.74697,
      city: 'Snowville',
      state: 'UT',
    },
    84337: {
      lat: 41.69223,
      lng: -112.28486,
      city: 'Tremonton',
      state: 'UT',
    },
    84338: {
      lat: 41.91133,
      lng: -111.93527,
      city: 'Trenton',
      state: 'UT',
    },
    84339: {
      lat: 41.62546,
      lng: -111.939,
      city: 'Wellsville',
      state: 'UT',
    },
    84340: {
      lat: 41.39312,
      lng: -112.15705,
      city: 'Willard',
      state: 'UT',
    },
    84341: {
      lat: 41.77197,
      lng: -111.81128,
      city: 'Logan',
      state: 'UT',
    },
    84401: {
      lat: 41.21962,
      lng: -112.03352,
      city: 'Ogden',
      state: 'UT',
    },
    84403: {
      lat: 41.19379,
      lng: -111.9038,
      city: 'Ogden',
      state: 'UT',
    },
    84404: {
      lat: 41.27198,
      lng: -112.11705,
      city: 'Ogden',
      state: 'UT',
    },
    84405: {
      lat: 41.15747,
      lng: -111.96499,
      city: 'Ogden',
      state: 'UT',
    },
    84408: {
      lat: 41.19252,
      lng: -111.93966,
      city: 'Ogden',
      state: 'UT',
    },
    84414: {
      lat: 41.32578,
      lng: -111.96547,
      city: 'Ogden',
      state: 'UT',
    },
    84501: {
      lat: 39.53214,
      lng: -110.80393,
      city: 'Price',
      state: 'UT',
    },
    84511: {
      lat: 37.52687,
      lng: -109.42054,
      city: 'Blanding',
      state: 'UT',
    },
    84512: {
      lat: 37.16255,
      lng: -109.55921,
      city: 'Bluff',
      state: 'UT',
    },
    84513: {
      lat: 39.24932,
      lng: -111.00912,
      city: 'Castle Dale',
      state: 'UT',
    },
    84515: {
      lat: 38.96824,
      lng: -109.23876,
      city: 'Cisco',
      state: 'UT',
    },
    84516: {
      lat: 39.12842,
      lng: -111.06703,
      city: 'Clawson',
      state: 'UT',
    },
    84518: {
      lat: 39.3917,
      lng: -110.88478,
      city: 'Cleveland',
      state: 'UT',
    },
    84520: {
      lat: 39.53392,
      lng: -110.41949,
      city: 'East Carbon',
      state: 'UT',
    },
    84521: { lat: 39.4213, lng: -110.78709, city: 'Elmo', state: 'UT' },
    84522: {
      lat: 38.90991,
      lng: -111.20987,
      city: 'Emery',
      state: 'UT',
    },
    84523: {
      lat: 39.0946,
      lng: -111.2014,
      city: 'Ferron',
      state: 'UT',
    },
    84525: {
      lat: 38.87947,
      lng: -110.33217,
      city: 'Green River',
      state: 'UT',
    },
    84526: {
      lat: 39.72395,
      lng: -111.04571,
      city: 'Helper',
      state: 'UT',
    },
    84528: {
      lat: 39.43404,
      lng: -111.08778,
      city: 'Huntington',
      state: 'UT',
    },
    84529: {
      lat: 39.71151,
      lng: -110.7733,
      city: 'Kenilworth',
      state: 'UT',
    },
    84530: {
      lat: 38.23736,
      lng: -109.40846,
      city: 'La Sal',
      state: 'UT',
    },
    84531: {
      lat: 37.0683,
      lng: -109.86371,
      city: 'Mexican Hat',
      state: 'UT',
    },
    84532: {
      lat: 38.53898,
      lng: -109.45916,
      city: 'Moab',
      state: 'UT',
    },
    84533: {
      lat: 37.40827,
      lng: -110.56844,
      city: 'Lake Powell',
      state: 'UT',
    },
    84534: {
      lat: 37.21283,
      lng: -109.26826,
      city: 'Montezuma Creek',
      state: 'UT',
    },
    84535: {
      lat: 37.84244,
      lng: -109.28673,
      city: 'Monticello',
      state: 'UT',
    },
    84536: {
      lat: 37.14017,
      lng: -110.23104,
      city: 'Monument Valley New',
      state: 'UT',
    },
    84537: {
      lat: 39.29966,
      lng: -111.17345,
      city: 'Orangeville',
      state: 'UT',
    },
    84539: {
      lat: 39.5761,
      lng: -110.4007,
      city: 'Sunnyside',
      state: 'UT',
    },
    84540: {
      lat: 39.20039,
      lng: -109.55281,
      city: 'Thompson',
      state: 'UT',
    },
    84542: {
      lat: 39.64433,
      lng: -110.59916,
      city: 'Wellington',
      state: 'UT',
    },
    84601: {
      lat: 40.22593,
      lng: -111.6955,
      city: 'Provo',
      state: 'UT',
    },
    84602: {
      lat: 40.25412,
      lng: -111.65009,
      city: 'Provo',
      state: 'UT',
    },
    84604: {
      lat: 40.3313,
      lng: -111.57284,
      city: 'Provo',
      state: 'UT',
    },
    84606: {
      lat: 40.2149,
      lng: -111.62619,
      city: 'Provo',
      state: 'UT',
    },
    84620: {
      lat: 38.92244,
      lng: -111.92427,
      city: 'Aurora',
      state: 'UT',
    },
    84621: {
      lat: 39.05109,
      lng: -111.82959,
      city: 'Axtell',
      state: 'UT',
    },
    84622: {
      lat: 39.09816,
      lng: -111.81353,
      city: 'Centerfield',
      state: 'UT',
    },
    84623: {
      lat: 39.45608,
      lng: -111.59063,
      city: 'Chester',
      state: 'UT',
    },
    84624: {
      lat: 39.38429,
      lng: -112.58756,
      city: 'Delta',
      state: 'UT',
    },
    84626: {
      lat: 39.93359,
      lng: -111.98545,
      city: 'Elberta',
      state: 'UT',
    },
    84627: {
      lat: 39.35515,
      lng: -111.58457,
      city: 'Ephraim',
      state: 'UT',
    },
    84628: {
      lat: 39.93583,
      lng: -112.13419,
      city: 'Eureka',
      state: 'UT',
    },
    84629: {
      lat: 39.76574,
      lng: -111.44752,
      city: 'Fairview',
      state: 'UT',
    },
    84630: {
      lat: 39.31069,
      lng: -111.8342,
      city: 'Fayette',
      state: 'UT',
    },
    84631: {
      lat: 38.99771,
      lng: -112.39675,
      city: 'Fillmore',
      state: 'UT',
    },
    84632: {
      lat: 39.64498,
      lng: -111.64649,
      city: 'Fountain Green',
      state: 'UT',
    },
    84633: {
      lat: 39.94026,
      lng: -111.89281,
      city: 'Goshen',
      state: 'UT',
    },
    84634: {
      lat: 39.18028,
      lng: -111.83561,
      city: 'Gunnison',
      state: 'UT',
    },
    84635: {
      lat: 39.39181,
      lng: -112.72132,
      city: 'Hinckley',
      state: 'UT',
    },
    84636: {
      lat: 39.13961,
      lng: -112.3389,
      city: 'Holden',
      state: 'UT',
    },
    84637: {
      lat: 38.72183,
      lng: -112.48706,
      city: 'Kanosh',
      state: 'UT',
    },
    84638: {
      lat: 39.50192,
      lng: -112.25773,
      city: 'Leamington',
      state: 'UT',
    },
    84639: {
      lat: 39.51071,
      lng: -111.90225,
      city: 'Levan',
      state: 'UT',
    },
    84640: {
      lat: 39.47622,
      lng: -112.39942,
      city: 'Lynndyl',
      state: 'UT',
    },
    84642: {
      lat: 39.20135,
      lng: -111.60893,
      city: 'Manti',
      state: 'UT',
    },
    84643: {
      lat: 39.12873,
      lng: -111.68485,
      city: 'Mayfield',
      state: 'UT',
    },
    84644: {
      lat: 38.87008,
      lng: -112.37511,
      city: 'Meadow',
      state: 'UT',
    },
    84645: {
      lat: 39.85159,
      lng: -111.83946,
      city: 'Mona',
      state: 'UT',
    },
    84646: {
      lat: 39.54565,
      lng: -111.61121,
      city: 'Moroni',
      state: 'UT',
    },
    84647: {
      lat: 39.52708,
      lng: -111.40658,
      city: 'Mount Pleasant',
      state: 'UT',
    },
    84648: {
      lat: 39.68659,
      lng: -111.86819,
      city: 'Nephi',
      state: 'UT',
    },
    84649: {
      lat: 39.34661,
      lng: -112.32992,
      city: 'Oak City',
      state: 'UT',
    },
    84651: {
      lat: 39.95633,
      lng: -111.69342,
      city: 'Payson',
      state: 'UT',
    },
    84652: {
      lat: 39.01706,
      lng: -111.8785,
      city: 'Redmond',
      state: 'UT',
    },
    84653: {
      lat: 40.04803,
      lng: -111.66804,
      city: 'Salem',
      state: 'UT',
    },
    84654: {
      lat: 38.89144,
      lng: -111.64442,
      city: 'Salina',
      state: 'UT',
    },
    84655: {
      lat: 39.97427,
      lng: -111.80716,
      city: 'Santaquin',
      state: 'UT',
    },
    84656: {
      lat: 39.23513,
      lng: -112.1088,
      city: 'Scipio',
      state: 'UT',
    },
    84657: {
      lat: 38.81817,
      lng: -111.94022,
      city: 'Sigurd',
      state: 'UT',
    },
    84660: {
      lat: 40.00724,
      lng: -111.4268,
      city: 'Spanish Fork',
      state: 'UT',
    },
    84662: {
      lat: 39.43978,
      lng: -111.48573,
      city: 'Spring City',
      state: 'UT',
    },
    84663: {
      lat: 40.19266,
      lng: -111.48917,
      city: 'Springville',
      state: 'UT',
    },
    84664: {
      lat: 40.12627,
      lng: -111.57712,
      city: 'Mapleton',
      state: 'UT',
    },
    84665: {
      lat: 39.19527,
      lng: -111.66109,
      city: 'Sterling',
      state: 'UT',
    },
    84667: {
      lat: 39.48926,
      lng: -111.65115,
      city: 'Wales',
      state: 'UT',
    },
    84701: {
      lat: 38.69586,
      lng: -111.90474,
      city: 'Richfield',
      state: 'UT',
    },
    84710: {
      lat: 37.46925,
      lng: -112.51251,
      city: 'Alton',
      state: 'UT',
    },
    84711: {
      lat: 38.71294,
      lng: -112.0606,
      city: 'Annabella',
      state: 'UT',
    },
    84712: {
      lat: 38.14076,
      lng: -111.97783,
      city: 'Antimony',
      state: 'UT',
    },
    84713: {
      lat: 38.3292,
      lng: -112.54441,
      city: 'Beaver',
      state: 'UT',
    },
    84714: {
      lat: 37.84815,
      lng: -113.75547,
      city: 'Beryl',
      state: 'UT',
    },
    84715: {
      lat: 38.33221,
      lng: -111.60317,
      city: 'Bicknell',
      state: 'UT',
    },
    84716: {
      lat: 37.97152,
      lng: -111.4493,
      city: 'Boulder',
      state: 'UT',
    },
    84718: {
      lat: 37.57954,
      lng: -112.08306,
      city: 'Cannonville',
      state: 'UT',
    },
    84719: {
      lat: 37.65532,
      lng: -112.76974,
      city: 'Brian Head',
      state: 'UT',
    },
    84720: {
      lat: 37.55477,
      lng: -113.25665,
      city: 'Cedar City',
      state: 'UT',
    },
    84721: {
      lat: 37.77021,
      lng: -113.13308,
      city: 'Cedar City',
      state: 'UT',
    },
    84722: {
      lat: 37.48553,
      lng: -113.57582,
      city: 'Central',
      state: 'UT',
    },
    84723: {
      lat: 38.17611,
      lng: -112.2519,
      city: 'Circleville',
      state: 'UT',
    },
    84724: {
      lat: 38.67417,
      lng: -112.15015,
      city: 'Elsinore',
      state: 'UT',
    },
    84725: {
      lat: 37.53928,
      lng: -113.78042,
      city: 'Enterprise',
      state: 'UT',
    },
    84726: {
      lat: 37.7514,
      lng: -111.57911,
      city: 'Escalante',
      state: 'UT',
    },
    84728: {
      lat: 39.23887,
      lng: -113.95719,
      city: 'Garrison',
      state: 'UT',
    },
    84729: {
      lat: 37.35858,
      lng: -112.61527,
      city: 'Glendale',
      state: 'UT',
    },
    84730: {
      lat: 38.74504,
      lng: -112.00084,
      city: 'Glenwood',
      state: 'UT',
    },
    84731: {
      lat: 38.19714,
      lng: -112.76195,
      city: 'Greenville',
      state: 'UT',
    },
    84732: {
      lat: 38.4453,
      lng: -111.90408,
      city: 'Greenwich',
      state: 'UT',
    },
    84733: {
      lat: 37.29597,
      lng: -113.79775,
      city: 'Gunlock',
      state: 'UT',
    },
    84734: {
      lat: 38.36353,
      lng: -110.5658,
      city: 'Hanksville',
      state: 'UT',
    },
    84735: {
      lat: 37.64627,
      lng: -112.44997,
      city: 'Hatch',
      state: 'UT',
    },
    84736: {
      lat: 37.63732,
      lng: -111.7938,
      city: 'Henrieville',
      state: 'UT',
    },
    84737: {
      lat: 37.08321,
      lng: -113.26032,
      city: 'Hurricane',
      state: 'UT',
    },
    84738: {
      lat: 37.20987,
      lng: -113.70888,
      city: 'Ivins',
      state: 'UT',
    },
    84739: {
      lat: 38.64204,
      lng: -112.22299,
      city: 'Joseph',
      state: 'UT',
    },
    84740: {
      lat: 38.27504,
      lng: -112.26689,
      city: 'Junction',
      state: 'UT',
    },
    84741: {
      lat: 37.19749,
      lng: -112.25533,
      city: 'Kanab',
      state: 'UT',
    },
    84742: {
      lat: 37.52102,
      lng: -113.14592,
      city: 'Kanarraville',
      state: 'UT',
    },
    84743: {
      lat: 38.20827,
      lng: -112.18572,
      city: 'Kingston',
      state: 'UT',
    },
    84744: {
      lat: 38.64207,
      lng: -111.93627,
      city: 'Koosharem',
      state: 'UT',
    },
    84745: {
      lat: 37.20838,
      lng: -113.26266,
      city: 'La Verkin',
      state: 'UT',
    },
    84746: {
      lat: 37.23867,
      lng: -113.35055,
      city: 'Leeds',
      state: 'UT',
    },
    84747: { lat: 38.44628, lng: -111.63773, city: 'Loa', state: 'UT' },
    84749: {
      lat: 38.36687,
      lng: -111.47667,
      city: 'Lyman',
      state: 'UT',
    },
    84750: {
      lat: 38.44205,
      lng: -112.1947,
      city: 'Marysvale',
      state: 'UT',
    },
    84751: {
      lat: 38.44392,
      lng: -113.0618,
      city: 'Milford',
      state: 'UT',
    },
    84752: {
      lat: 38.24975,
      lng: -112.88434,
      city: 'Minersville',
      state: 'UT',
    },
    84753: {
      lat: 37.95019,
      lng: -113.87967,
      city: 'Modena',
      state: 'UT',
    },
    84754: {
      lat: 38.60521,
      lng: -112.11747,
      city: 'Monroe',
      state: 'UT',
    },
    84755: {
      lat: 37.22652,
      lng: -112.78532,
      city: 'Mount Carmel',
      state: 'UT',
    },
    84756: {
      lat: 37.74249,
      lng: -113.54772,
      city: 'Newcastle',
      state: 'UT',
    },
    84757: {
      lat: 37.45227,
      lng: -113.25166,
      city: 'New Harmony',
      state: 'UT',
    },
    84758: {
      lat: 37.27041,
      lng: -112.68529,
      city: 'Orderville',
      state: 'UT',
    },
    84759: {
      lat: 37.92238,
      lng: -112.38423,
      city: 'Panguitch',
      state: 'UT',
    },
    84760: {
      lat: 37.96299,
      lng: -112.68551,
      city: 'Paragonah',
      state: 'UT',
    },
    84761: {
      lat: 37.84603,
      lng: -112.87124,
      city: 'Parowan',
      state: 'UT',
    },
    84762: {
      lat: 37.46882,
      lng: -112.72644,
      city: 'Duck Creek Village',
      state: 'UT',
    },
    84763: {
      lat: 37.14667,
      lng: -113.06947,
      city: 'Rockville',
      state: 'UT',
    },
    84764: {
      lat: 37.72035,
      lng: -112.06689,
      city: 'Bryce',
      state: 'UT',
    },
    84765: {
      lat: 37.31012,
      lng: -113.92535,
      city: 'Santa Clara',
      state: 'UT',
    },
    84766: {
      lat: 38.59762,
      lng: -112.33567,
      city: 'Sevier',
      state: 'UT',
    },
    84767: {
      lat: 37.20152,
      lng: -112.9858,
      city: 'Springdale',
      state: 'UT',
    },
    84770: {
      lat: 37.13818,
      lng: -113.6294,
      city: 'Saint George',
      state: 'UT',
    },
    84772: {
      lat: 37.79706,
      lng: -112.94168,
      city: 'Summit',
      state: 'UT',
    },
    84773: {
      lat: 38.24958,
      lng: -111.47045,
      city: 'Teasdale',
      state: 'UT',
    },
    84774: {
      lat: 37.24324,
      lng: -113.28089,
      city: 'Toquerville',
      state: 'UT',
    },
    84775: {
      lat: 38.28881,
      lng: -111.04073,
      city: 'Torrey',
      state: 'UT',
    },
    84776: {
      lat: 37.62956,
      lng: -112.08217,
      city: 'Tropic',
      state: 'UT',
    },
    84779: {
      lat: 37.33381,
      lng: -113.11869,
      city: 'Virgin',
      state: 'UT',
    },
    84780: {
      lat: 37.12798,
      lng: -113.48108,
      city: 'Washington',
      state: 'UT',
    },
    84781: {
      lat: 37.41197,
      lng: -113.47217,
      city: 'Pine Valley',
      state: 'UT',
    },
    84782: {
      lat: 37.35939,
      lng: -113.67522,
      city: 'Veyo',
      state: 'UT',
    },
    84783: {
      lat: 37.28556,
      lng: -113.65639,
      city: 'Dammeron Valley',
      state: 'UT',
    },
    84784: {
      lat: 37.01858,
      lng: -112.96941,
      city: 'Hildale',
      state: 'UT',
    },
    84790: {
      lat: 37.04615,
      lng: -113.55738,
      city: 'Saint George',
      state: 'UT',
    },
    85003: {
      lat: 33.45059,
      lng: -112.07833,
      city: 'Phoenix',
      state: 'AZ',
    },
    85004: {
      lat: 33.45159,
      lng: -112.06988,
      city: 'Phoenix',
      state: 'AZ',
    },
    85006: {
      lat: 33.46511,
      lng: -112.04778,
      city: 'Phoenix',
      state: 'AZ',
    },
    85007: {
      lat: 33.44682,
      lng: -112.09073,
      city: 'Phoenix',
      state: 'AZ',
    },
    85008: {
      lat: 33.46355,
      lng: -111.98746,
      city: 'Phoenix',
      state: 'AZ',
    },
    85009: {
      lat: 33.44457,
      lng: -112.12661,
      city: 'Phoenix',
      state: 'AZ',
    },
    85012: {
      lat: 33.50719,
      lng: -112.07024,
      city: 'Phoenix',
      state: 'AZ',
    },
    85013: {
      lat: 33.51001,
      lng: -112.08294,
      city: 'Phoenix',
      state: 'AZ',
    },
    85014: {
      lat: 33.50903,
      lng: -112.05665,
      city: 'Phoenix',
      state: 'AZ',
    },
    85015: {
      lat: 33.50908,
      lng: -112.10184,
      city: 'Phoenix',
      state: 'AZ',
    },
    85016: {
      lat: 33.50953,
      lng: -112.03041,
      city: 'Phoenix',
      state: 'AZ',
    },
    85017: {
      lat: 33.50898,
      lng: -112.12381,
      city: 'Phoenix',
      state: 'AZ',
    },
    85018: {
      lat: 33.5194,
      lng: -111.99309,
      city: 'Phoenix',
      state: 'AZ',
    },
    85019: {
      lat: 33.50952,
      lng: -112.14307,
      city: 'Phoenix',
      state: 'AZ',
    },
    85020: {
      lat: 33.56766,
      lng: -112.05485,
      city: 'Phoenix',
      state: 'AZ',
    },
    85021: {
      lat: 33.56046,
      lng: -112.09442,
      city: 'Phoenix',
      state: 'AZ',
    },
    85022: {
      lat: 33.62685,
      lng: -112.05113,
      city: 'Phoenix',
      state: 'AZ',
    },
    85023: {
      lat: 33.63156,
      lng: -112.09452,
      city: 'Phoenix',
      state: 'AZ',
    },
    85024: {
      lat: 33.69042,
      lng: -112.04632,
      city: 'Phoenix',
      state: 'AZ',
    },
    85026: {
      lat: 33.44891,
      lng: -111.97356,
      city: 'Phoenix',
      state: 'AZ',
    },
    85027: {
      lat: 33.6771,
      lng: -112.10206,
      city: 'Phoenix',
      state: 'AZ',
    },
    85028: {
      lat: 33.5816,
      lng: -112.00811,
      city: 'Phoenix',
      state: 'AZ',
    },
    85029: {
      lat: 33.59546,
      lng: -112.11002,
      city: 'Phoenix',
      state: 'AZ',
    },
    85031: {
      lat: 33.49491,
      lng: -112.16835,
      city: 'Phoenix',
      state: 'AZ',
    },
    85032: {
      lat: 33.62624,
      lng: -112.00368,
      city: 'Phoenix',
      state: 'AZ',
    },
    85033: {
      lat: 33.49443,
      lng: -112.21193,
      city: 'Phoenix',
      state: 'AZ',
    },
    85034: {
      lat: 33.43465,
      lng: -112.01989,
      city: 'Phoenix',
      state: 'AZ',
    },
    85035: {
      lat: 33.47167,
      lng: -112.19538,
      city: 'Phoenix',
      state: 'AZ',
    },
    85037: {
      lat: 33.49038,
      lng: -112.26487,
      city: 'Phoenix',
      state: 'AZ',
    },
    85040: {
      lat: 33.40585,
      lng: -112.02658,
      city: 'Phoenix',
      state: 'AZ',
    },
    85041: {
      lat: 33.38643,
      lng: -112.10928,
      city: 'Phoenix',
      state: 'AZ',
    },
    85042: {
      lat: 33.35911,
      lng: -112.04949,
      city: 'Phoenix',
      state: 'AZ',
    },
    85043: {
      lat: 33.4323,
      lng: -112.19744,
      city: 'Phoenix',
      state: 'AZ',
    },
    85044: {
      lat: 33.33997,
      lng: -112.00617,
      city: 'Phoenix',
      state: 'AZ',
    },
    85045: {
      lat: 33.29976,
      lng: -112.1062,
      city: 'Phoenix',
      state: 'AZ',
    },
    85048: {
      lat: 33.30362,
      lng: -112.03192,
      city: 'Phoenix',
      state: 'AZ',
    },
    85050: {
      lat: 33.68739,
      lng: -111.99854,
      city: 'Phoenix',
      state: 'AZ',
    },
    85051: {
      lat: 33.55914,
      lng: -112.13324,
      city: 'Phoenix',
      state: 'AZ',
    },
    85053: {
      lat: 33.6296,
      lng: -112.13184,
      city: 'Phoenix',
      state: 'AZ',
    },
    85054: {
      lat: 33.67515,
      lng: -111.94763,
      city: 'Phoenix',
      state: 'AZ',
    },
    85083: {
      lat: 33.74032,
      lng: -112.16308,
      city: 'Phoenix',
      state: 'AZ',
    },
    85085: {
      lat: 33.75637,
      lng: -112.07426,
      city: 'Phoenix',
      state: 'AZ',
    },
    85086: {
      lat: 33.82639,
      lng: -112.12876,
      city: 'Phoenix',
      state: 'AZ',
    },
    85087: {
      lat: 33.92635,
      lng: -112.14908,
      city: 'New River',
      state: 'AZ',
    },
    85118: {
      lat: 33.3737,
      lng: -111.36545,
      city: 'Gold Canyon',
      state: 'AZ',
    },
    85119: {
      lat: 33.38682,
      lng: -111.50824,
      city: 'Apache Junction',
      state: 'AZ',
    },
    85120: {
      lat: 33.38706,
      lng: -111.56311,
      city: 'Apache Junction',
      state: 'AZ',
    },
    85121: {
      lat: 33.13751,
      lng: -111.91316,
      city: 'Bapchule',
      state: 'AZ',
    },
    85122: {
      lat: 32.91672,
      lng: -111.74798,
      city: 'Casa Grande',
      state: 'AZ',
    },
    85123: {
      lat: 32.70917,
      lng: -111.6802,
      city: 'Arizona City',
      state: 'AZ',
    },
    85128: {
      lat: 32.96468,
      lng: -111.51028,
      city: 'Coolidge',
      state: 'AZ',
    },
    85131: {
      lat: 32.65542,
      lng: -111.57649,
      city: 'Eloy',
      state: 'AZ',
    },
    85132: {
      lat: 32.92358,
      lng: -111.19119,
      city: 'Florence',
      state: 'AZ',
    },
    85135: {
      lat: 32.9987,
      lng: -110.78242,
      city: 'Hayden',
      state: 'AZ',
    },
    85137: {
      lat: 33.11275,
      lng: -110.98353,
      city: 'Kearny',
      state: 'AZ',
    },
    85138: {
      lat: 33.0151,
      lng: -111.98681,
      city: 'Maricopa',
      state: 'AZ',
    },
    85139: {
      lat: 32.92096,
      lng: -112.21117,
      city: 'Maricopa',
      state: 'AZ',
    },
    85140: {
      lat: 33.24805,
      lng: -111.48921,
      city: 'San Tan Valley',
      state: 'AZ',
    },
    85141: {
      lat: 32.64707,
      lng: -111.42792,
      city: 'Picacho',
      state: 'AZ',
    },
    85142: {
      lat: 33.19302,
      lng: -111.66645,
      city: 'Queen Creek',
      state: 'AZ',
    },
    85143: {
      lat: 33.15602,
      lng: -111.51974,
      city: 'San Tan Valley',
      state: 'AZ',
    },
    85145: {
      lat: 32.54322,
      lng: -111.39415,
      city: 'Red Rock',
      state: 'AZ',
    },
    85147: {
      lat: 33.09454,
      lng: -111.7209,
      city: 'Sacaton',
      state: 'AZ',
    },
    85172: {
      lat: 32.89974,
      lng: -111.95205,
      city: 'Stanfield',
      state: 'AZ',
    },
    85173: {
      lat: 33.26827,
      lng: -111.13651,
      city: 'Superior',
      state: 'AZ',
    },
    85191: {
      lat: 32.98498,
      lng: -111.44686,
      city: 'Valley Farms',
      state: 'AZ',
    },
    85192: {
      lat: 32.96134,
      lng: -110.66501,
      city: 'Winkelman',
      state: 'AZ',
    },
    85193: {
      lat: 32.79822,
      lng: -111.82809,
      city: 'Casa Grande',
      state: 'AZ',
    },
    85194: {
      lat: 32.89675,
      lng: -111.62439,
      city: 'Casa Grande',
      state: 'AZ',
    },
    85201: { lat: 33.4336, lng: -111.84929, city: 'Mesa', state: 'AZ' },
    85202: {
      lat: 33.38252,
      lng: -111.87482,
      city: 'Mesa',
      state: 'AZ',
    },
    85203: {
      lat: 33.44997,
      lng: -111.80403,
      city: 'Mesa',
      state: 'AZ',
    },
    85204: {
      lat: 33.39666,
      lng: -111.78786,
      city: 'Mesa',
      state: 'AZ',
    },
    85205: {
      lat: 33.43227,
      lng: -111.71804,
      city: 'Mesa',
      state: 'AZ',
    },
    85206: {
      lat: 33.39685,
      lng: -111.71758,
      city: 'Mesa',
      state: 'AZ',
    },
    85207: {
      lat: 33.45362,
      lng: -111.63631,
      city: 'Mesa',
      state: 'AZ',
    },
    85208: {
      lat: 33.40301,
      lng: -111.63194,
      city: 'Mesa',
      state: 'AZ',
    },
    85209: {
      lat: 33.37877,
      lng: -111.63476,
      city: 'Mesa',
      state: 'AZ',
    },
    85210: {
      lat: 33.38955,
      lng: -111.84337,
      city: 'Mesa',
      state: 'AZ',
    },
    85212: {
      lat: 33.32064,
      lng: -111.63088,
      city: 'Mesa',
      state: 'AZ',
    },
    85213: {
      lat: 33.44764,
      lng: -111.76962,
      city: 'Mesa',
      state: 'AZ',
    },
    85215: {
      lat: 33.51101,
      lng: -111.58027,
      city: 'Mesa',
      state: 'AZ',
    },
    85224: {
      lat: 33.32351,
      lng: -111.87616,
      city: 'Chandler',
      state: 'AZ',
    },
    85225: {
      lat: 33.31748,
      lng: -111.83167,
      city: 'Chandler',
      state: 'AZ',
    },
    85226: {
      lat: 33.26519,
      lng: -111.94641,
      city: 'Chandler',
      state: 'AZ',
    },
    85233: {
      lat: 33.35242,
      lng: -111.8115,
      city: 'Gilbert',
      state: 'AZ',
    },
    85234: {
      lat: 33.36479,
      lng: -111.73924,
      city: 'Gilbert',
      state: 'AZ',
    },
    85236: {
      lat: 33.32301,
      lng: -111.71951,
      city: 'Higley',
      state: 'AZ',
    },
    85248: {
      lat: 33.2145,
      lng: -111.86667,
      city: 'Chandler',
      state: 'AZ',
    },
    85249: {
      lat: 33.22652,
      lng: -111.79918,
      city: 'Chandler',
      state: 'AZ',
    },
    85250: {
      lat: 33.52986,
      lng: -111.88991,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85251: {
      lat: 33.49406,
      lng: -111.92025,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85253: {
      lat: 33.54479,
      lng: -111.95873,
      city: 'Paradise Valley',
      state: 'AZ',
    },
    85254: {
      lat: 33.61474,
      lng: -111.95176,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85255: {
      lat: 33.6824,
      lng: -111.8177,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85256: {
      lat: 33.50053,
      lng: -111.83524,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85257: {
      lat: 33.46354,
      lng: -111.91486,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85258: {
      lat: 33.56337,
      lng: -111.89268,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85259: {
      lat: 33.60105,
      lng: -111.80952,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85260: {
      lat: 33.60943,
      lng: -111.89113,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85262: {
      lat: 33.85834,
      lng: -111.80169,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85263: {
      lat: 33.90026,
      lng: -111.62412,
      city: 'Rio Verde',
      state: 'AZ',
    },
    85264: {
      lat: 33.69996,
      lng: -111.50123,
      city: 'Fort Mcdowell',
      state: 'AZ',
    },
    85266: {
      lat: 33.76034,
      lng: -111.92503,
      city: 'Scottsdale',
      state: 'AZ',
    },
    85268: {
      lat: 33.60717,
      lng: -111.73999,
      city: 'Fountain Hills',
      state: 'AZ',
    },
    85281: {
      lat: 33.42805,
      lng: -111.93157,
      city: 'Tempe',
      state: 'AZ',
    },
    85282: {
      lat: 33.3943,
      lng: -111.93242,
      city: 'Tempe',
      state: 'AZ',
    },
    85283: {
      lat: 33.36461,
      lng: -111.93196,
      city: 'Tempe',
      state: 'AZ',
    },
    85284: {
      lat: 33.33719,
      lng: -111.93443,
      city: 'Tempe',
      state: 'AZ',
    },
    85286: {
      lat: 33.27146,
      lng: -111.83156,
      city: 'Chandler',
      state: 'AZ',
    },
    85295: {
      lat: 33.30639,
      lng: -111.73804,
      city: 'Gilbert',
      state: 'AZ',
    },
    85296: {
      lat: 33.33554,
      lng: -111.74006,
      city: 'Gilbert',
      state: 'AZ',
    },
    85297: {
      lat: 33.27843,
      lng: -111.73638,
      city: 'Gilbert',
      state: 'AZ',
    },
    85298: {
      lat: 33.23983,
      lng: -111.72819,
      city: 'Gilbert',
      state: 'AZ',
    },
    85301: {
      lat: 33.53219,
      lng: -112.17817,
      city: 'Glendale',
      state: 'AZ',
    },
    85302: {
      lat: 33.56777,
      lng: -112.17819,
      city: 'Glendale',
      state: 'AZ',
    },
    85303: {
      lat: 33.53398,
      lng: -112.22084,
      city: 'Glendale',
      state: 'AZ',
    },
    85304: {
      lat: 33.59566,
      lng: -112.17815,
      city: 'Glendale',
      state: 'AZ',
    },
    85305: {
      lat: 33.52996,
      lng: -112.25563,
      city: 'Glendale',
      state: 'AZ',
    },
    85306: {
      lat: 33.62333,
      lng: -112.17569,
      city: 'Glendale',
      state: 'AZ',
    },
    85307: {
      lat: 33.53396,
      lng: -112.30867,
      city: 'Glendale',
      state: 'AZ',
    },
    85308: {
      lat: 33.66029,
      lng: -112.18486,
      city: 'Glendale',
      state: 'AZ',
    },
    85309: {
      lat: 33.53556,
      lng: -112.37637,
      city: 'Luke Air Force Base',
      state: 'AZ',
    },
    85310: {
      lat: 33.69532,
      lng: -112.17162,
      city: 'Glendale',
      state: 'AZ',
    },
    85320: {
      lat: 33.83563,
      lng: -113.23214,
      city: 'Aguila',
      state: 'AZ',
    },
    85321: { lat: 32.30062, lng: -112.63169, city: 'Ajo', state: 'AZ' },
    85322: {
      lat: 33.1766,
      lng: -112.85207,
      city: 'Arlington',
      state: 'AZ',
    },
    85323: {
      lat: 33.41888,
      lng: -112.32615,
      city: 'Avondale',
      state: 'AZ',
    },
    85324: {
      lat: 34.10456,
      lng: -112.07094,
      city: 'Black Canyon City',
      state: 'AZ',
    },
    85325: {
      lat: 33.98328,
      lng: -113.84323,
      city: 'Bouse',
      state: 'AZ',
    },
    85326: {
      lat: 33.23735,
      lng: -112.58538,
      city: 'Buckeye',
      state: 'AZ',
    },
    85328: {
      lat: 33.22795,
      lng: -114.63413,
      city: 'Cibola',
      state: 'AZ',
    },
    85329: {
      lat: 33.43171,
      lng: -112.2965,
      city: 'Cashion',
      state: 'AZ',
    },
    85331: {
      lat: 33.88685,
      lng: -111.94276,
      city: 'Cave Creek',
      state: 'AZ',
    },
    85332: {
      lat: 34.19209,
      lng: -112.89444,
      city: 'Congress',
      state: 'AZ',
    },
    85333: {
      lat: 32.97896,
      lng: -113.30714,
      city: 'Dateland',
      state: 'AZ',
    },
    85334: {
      lat: 33.60535,
      lng: -114.4075,
      city: 'Ehrenberg',
      state: 'AZ',
    },
    85335: {
      lat: 33.58721,
      lng: -112.32836,
      city: 'El Mirage',
      state: 'AZ',
    },
    85336: {
      lat: 32.51904,
      lng: -114.77977,
      city: 'San Luis',
      state: 'AZ',
    },
    85337: {
      lat: 32.97553,
      lng: -112.866,
      city: 'Gila Bend',
      state: 'AZ',
    },
    85338: {
      lat: 33.37252,
      lng: -112.40826,
      city: 'Goodyear',
      state: 'AZ',
    },
    85339: {
      lat: 33.23985,
      lng: -112.14322,
      city: 'Laveen',
      state: 'AZ',
    },
    85340: {
      lat: 33.5179,
      lng: -112.42361,
      city: 'Litchfield Park',
      state: 'AZ',
    },
    85341: {
      lat: 31.9726,
      lng: -112.71943,
      city: 'Lukeville',
      state: 'AZ',
    },
    85342: {
      lat: 33.95308,
      lng: -112.48358,
      city: 'Morristown',
      state: 'AZ',
    },
    85343: {
      lat: 33.33774,
      lng: -112.69087,
      city: 'Palo Verde',
      state: 'AZ',
    },
    85344: {
      lat: 33.98527,
      lng: -114.23267,
      city: 'Parker',
      state: 'AZ',
    },
    85345: {
      lat: 33.57215,
      lng: -112.24838,
      city: 'Peoria',
      state: 'AZ',
    },
    85346: {
      lat: 33.60992,
      lng: -114.15835,
      city: 'Quartzsite',
      state: 'AZ',
    },
    85347: {
      lat: 32.78612,
      lng: -113.80633,
      city: 'Roll',
      state: 'AZ',
    },
    85348: {
      lat: 33.69914,
      lng: -113.66372,
      city: 'Salome',
      state: 'AZ',
    },
    85349: {
      lat: 32.47983,
      lng: -114.69647,
      city: 'San Luis',
      state: 'AZ',
    },
    85350: {
      lat: 32.56651,
      lng: -114.70896,
      city: 'Somerton',
      state: 'AZ',
    },
    85351: {
      lat: 33.60638,
      lng: -112.28213,
      city: 'Sun City',
      state: 'AZ',
    },
    85352: {
      lat: 32.69118,
      lng: -113.8963,
      city: 'Tacna',
      state: 'AZ',
    },
    85353: {
      lat: 33.41721,
      lng: -112.27283,
      city: 'Tolleson',
      state: 'AZ',
    },
    85354: {
      lat: 33.40735,
      lng: -113.06637,
      city: 'Tonopah',
      state: 'AZ',
    },
    85355: {
      lat: 33.57519,
      lng: -112.45374,
      city: 'Waddell',
      state: 'AZ',
    },
    85356: {
      lat: 32.68933,
      lng: -114.1852,
      city: 'Wellton',
      state: 'AZ',
    },
    85357: {
      lat: 34.02498,
      lng: -113.49055,
      city: 'Wenden',
      state: 'AZ',
    },
    85360: {
      lat: 34.54833,
      lng: -113.57275,
      city: 'Wikieup',
      state: 'AZ',
    },
    85361: {
      lat: 33.76617,
      lng: -112.63093,
      city: 'Wittmann',
      state: 'AZ',
    },
    85362: {
      lat: 34.28558,
      lng: -112.84337,
      city: 'Yarnell',
      state: 'AZ',
    },
    85363: {
      lat: 33.58458,
      lng: -112.30489,
      city: 'Youngtown',
      state: 'AZ',
    },
    85364: {
      lat: 32.70469,
      lng: -114.65664,
      city: 'Yuma',
      state: 'AZ',
    },
    85365: {
      lat: 33.07067,
      lng: -114.13801,
      city: 'Yuma',
      state: 'AZ',
    },
    85367: {
      lat: 32.66082,
      lng: -114.39361,
      city: 'Yuma',
      state: 'AZ',
    },
    85371: {
      lat: 33.96732,
      lng: -114.39005,
      city: 'Poston',
      state: 'AZ',
    },
    85373: {
      lat: 33.67257,
      lng: -112.29983,
      city: 'Sun City',
      state: 'AZ',
    },
    85374: {
      lat: 33.64449,
      lng: -112.395,
      city: 'Surprise',
      state: 'AZ',
    },
    85375: {
      lat: 33.6716,
      lng: -112.3565,
      city: 'Sun City West',
      state: 'AZ',
    },
    85377: {
      lat: 33.82376,
      lng: -111.91515,
      city: 'Carefree',
      state: 'AZ',
    },
    85378: {
      lat: 33.64162,
      lng: -112.32012,
      city: 'Surprise',
      state: 'AZ',
    },
    85379: {
      lat: 33.60207,
      lng: -112.3736,
      city: 'Surprise',
      state: 'AZ',
    },
    85381: {
      lat: 33.60957,
      lng: -112.23175,
      city: 'Peoria',
      state: 'AZ',
    },
    85382: {
      lat: 33.65548,
      lng: -112.24771,
      city: 'Peoria',
      state: 'AZ',
    },
    85383: {
      lat: 33.80297,
      lng: -112.31734,
      city: 'Peoria',
      state: 'AZ',
    },
    85387: {
      lat: 33.71025,
      lng: -112.44869,
      city: 'Surprise',
      state: 'AZ',
    },
    85388: {
      lat: 33.60743,
      lng: -112.43413,
      city: 'Surprise',
      state: 'AZ',
    },
    85390: {
      lat: 33.92391,
      lng: -112.86739,
      city: 'Wickenburg',
      state: 'AZ',
    },
    85392: {
      lat: 33.47713,
      lng: -112.30981,
      city: 'Avondale',
      state: 'AZ',
    },
    85395: {
      lat: 33.48208,
      lng: -112.40518,
      city: 'Goodyear',
      state: 'AZ',
    },
    85396: {
      lat: 33.5376,
      lng: -112.64635,
      city: 'Buckeye',
      state: 'AZ',
    },
    85501: {
      lat: 33.54569,
      lng: -110.74788,
      city: 'Globe',
      state: 'AZ',
    },
    85530: {
      lat: 33.09361,
      lng: -110.23109,
      city: 'Bylas',
      state: 'AZ',
    },
    85531: {
      lat: 32.87392,
      lng: -109.7849,
      city: 'Central',
      state: 'AZ',
    },
    85532: {
      lat: 33.40779,
      lng: -110.8454,
      city: 'Claypool',
      state: 'AZ',
    },
    85533: {
      lat: 33.29208,
      lng: -109.40219,
      city: 'Clifton',
      state: 'AZ',
    },
    85534: {
      lat: 32.72939,
      lng: -109.14475,
      city: 'Duncan',
      state: 'AZ',
    },
    85535: {
      lat: 32.98583,
      lng: -109.91284,
      city: 'Eden',
      state: 'AZ',
    },
    85536: {
      lat: 33.14063,
      lng: -110.00331,
      city: 'Fort Thomas',
      state: 'AZ',
    },
    85539: {
      lat: 33.33282,
      lng: -110.92883,
      city: 'Miami',
      state: 'AZ',
    },
    85540: {
      lat: 32.9781,
      lng: -109.35704,
      city: 'Morenci',
      state: 'AZ',
    },
    85541: {
      lat: 34.21921,
      lng: -111.34387,
      city: 'Payson',
      state: 'AZ',
    },
    85542: {
      lat: 33.28633,
      lng: -110.30145,
      city: 'Peridot',
      state: 'AZ',
    },
    85543: { lat: 32.9593, lng: -109.90123, city: 'Pima', state: 'AZ' },
    85544: { lat: 34.38609, lng: -111.4732, city: 'Pine', state: 'AZ' },
    85545: {
      lat: 33.53759,
      lng: -111.11955,
      city: 'Roosevelt',
      state: 'AZ',
    },
    85546: {
      lat: 32.71743,
      lng: -109.58175,
      city: 'Safford',
      state: 'AZ',
    },
    85550: {
      lat: 33.54833,
      lng: -110.51244,
      city: 'San Carlos',
      state: 'AZ',
    },
    85551: {
      lat: 32.73048,
      lng: -109.56252,
      city: 'Solomon',
      state: 'AZ',
    },
    85552: {
      lat: 32.81399,
      lng: -109.78953,
      city: 'Thatcher',
      state: 'AZ',
    },
    85553: {
      lat: 33.79932,
      lng: -111.22438,
      city: 'Tonto Basin',
      state: 'AZ',
    },
    85554: {
      lat: 33.99679,
      lng: -110.97834,
      city: 'Young',
      state: 'AZ',
    },
    85601: {
      lat: 31.58453,
      lng: -111.33359,
      city: 'Arivaca',
      state: 'AZ',
    },
    85602: {
      lat: 32.17296,
      lng: -110.3865,
      city: 'Benson',
      state: 'AZ',
    },
    85603: {
      lat: 31.43525,
      lng: -109.91127,
      city: 'Bisbee',
      state: 'AZ',
    },
    85605: {
      lat: 32.29784,
      lng: -109.47644,
      city: 'Bowie',
      state: 'AZ',
    },
    85606: {
      lat: 32.01521,
      lng: -109.93326,
      city: 'Cochise',
      state: 'AZ',
    },
    85607: {
      lat: 31.49008,
      lng: -109.34052,
      city: 'Douglas',
      state: 'AZ',
    },
    85608: {
      lat: 31.45624,
      lng: -109.58635,
      city: 'Douglas',
      state: 'AZ',
    },
    85609: {
      lat: 32.06867,
      lng: -110.09714,
      city: 'Dragoon',
      state: 'AZ',
    },
    85610: {
      lat: 31.73144,
      lng: -109.65957,
      city: 'Elfrida',
      state: 'AZ',
    },
    85611: {
      lat: 31.64266,
      lng: -110.51334,
      city: 'Elgin',
      state: 'AZ',
    },
    85613: {
      lat: 31.55393,
      lng: -110.38305,
      city: 'Fort Huachuca',
      state: 'AZ',
    },
    85614: {
      lat: 31.81241,
      lng: -110.92972,
      city: 'Green Valley',
      state: 'AZ',
    },
    85615: {
      lat: 31.39678,
      lng: -110.2108,
      city: 'Hereford',
      state: 'AZ',
    },
    85616: {
      lat: 31.70768,
      lng: -110.32184,
      city: 'Huachuca City',
      state: 'AZ',
    },
    85617: {
      lat: 31.55767,
      lng: -109.71548,
      city: 'McNeal',
      state: 'AZ',
    },
    85618: {
      lat: 32.77631,
      lng: -110.69433,
      city: 'Mammoth',
      state: 'AZ',
    },
    85619: {
      lat: 32.39574,
      lng: -110.73663,
      city: 'Mount Lemmon',
      state: 'AZ',
    },
    85620: { lat: 31.33835, lng: -109.9431, city: 'Naco', state: 'AZ' },
    85621: {
      lat: 31.42345,
      lng: -111.03764,
      city: 'Nogales',
      state: 'AZ',
    },
    85622: {
      lat: 31.80301,
      lng: -111.09104,
      city: 'Green Valley',
      state: 'AZ',
    },
    85623: {
      lat: 32.65407,
      lng: -110.78447,
      city: 'Oracle',
      state: 'AZ',
    },
    85624: {
      lat: 31.51768,
      lng: -110.71039,
      city: 'Patagonia',
      state: 'AZ',
    },
    85625: {
      lat: 31.88021,
      lng: -109.66692,
      city: 'Pearce',
      state: 'AZ',
    },
    85626: {
      lat: 31.35932,
      lng: -109.56827,
      city: 'Pirtleville',
      state: 'AZ',
    },
    85627: {
      lat: 32.00707,
      lng: -110.24695,
      city: 'Pomerene',
      state: 'AZ',
    },
    85629: {
      lat: 31.91413,
      lng: -111.01334,
      city: 'Sahuarita',
      state: 'AZ',
    },
    85630: {
      lat: 31.88335,
      lng: -110.19447,
      city: 'Saint David',
      state: 'AZ',
    },
    85631: {
      lat: 32.58133,
      lng: -110.5627,
      city: 'San Manuel',
      state: 'AZ',
    },
    85632: {
      lat: 32.05855,
      lng: -109.18305,
      city: 'San Simon',
      state: 'AZ',
    },
    85633: {
      lat: 31.60632,
      lng: -111.52827,
      city: 'Sasabe',
      state: 'AZ',
    },
    85634: {
      lat: 32.02817,
      lng: -112.03296,
      city: 'Sells',
      state: 'AZ',
    },
    85635: {
      lat: 31.55322,
      lng: -110.17304,
      city: 'Sierra Vista',
      state: 'AZ',
    },
    85637: {
      lat: 31.72108,
      lng: -110.69258,
      city: 'Sonoita',
      state: 'AZ',
    },
    85638: {
      lat: 31.68588,
      lng: -110.05887,
      city: 'Tombstone',
      state: 'AZ',
    },
    85639: {
      lat: 31.77175,
      lng: -111.68228,
      city: 'Topawa',
      state: 'AZ',
    },
    85640: {
      lat: 31.56755,
      lng: -111.04473,
      city: 'Tumacacori',
      state: 'AZ',
    },
    85641: {
      lat: 32.02125,
      lng: -110.64617,
      city: 'Vail',
      state: 'AZ',
    },
    85643: {
      lat: 32.43757,
      lng: -109.98521,
      city: 'Willcox',
      state: 'AZ',
    },
    85645: {
      lat: 31.69497,
      lng: -111.14655,
      city: 'Amado',
      state: 'AZ',
    },
    85646: {
      lat: 31.62009,
      lng: -111.05192,
      city: 'Tubac',
      state: 'AZ',
    },
    85648: {
      lat: 31.51262,
      lng: -111.00807,
      city: 'Rio Rico',
      state: 'AZ',
    },
    85650: {
      lat: 31.49759,
      lng: -110.19906,
      city: 'Sierra Vista',
      state: 'AZ',
    },
    85653: {
      lat: 32.38979,
      lng: -111.37908,
      city: 'Marana',
      state: 'AZ',
    },
    85654: {
      lat: 32.41529,
      lng: -111.15467,
      city: 'Rillito',
      state: 'AZ',
    },
    85658: {
      lat: 32.52455,
      lng: -111.13603,
      city: 'Marana',
      state: 'AZ',
    },
    85701: {
      lat: 32.21702,
      lng: -110.97138,
      city: 'Tucson',
      state: 'AZ',
    },
    85704: {
      lat: 32.33885,
      lng: -110.98483,
      city: 'Tucson',
      state: 'AZ',
    },
    85705: {
      lat: 32.27119,
      lng: -110.99172,
      city: 'Tucson',
      state: 'AZ',
    },
    85706: {
      lat: 32.14783,
      lng: -110.93541,
      city: 'Tucson',
      state: 'AZ',
    },
    85707: {
      lat: 32.18022,
      lng: -110.88966,
      city: 'Tucson',
      state: 'AZ',
    },
    85708: {
      lat: 32.17575,
      lng: -110.86019,
      city: 'Tucson',
      state: 'AZ',
    },
    85709: {
      lat: 32.14136,
      lng: -110.99008,
      city: 'Tucson',
      state: 'AZ',
    },
    85710: {
      lat: 32.21439,
      lng: -110.82365,
      city: 'Tucson',
      state: 'AZ',
    },
    85711: {
      lat: 32.21504,
      lng: -110.88348,
      city: 'Tucson',
      state: 'AZ',
    },
    85712: {
      lat: 32.25229,
      lng: -110.88636,
      city: 'Tucson',
      state: 'AZ',
    },
    85713: {
      lat: 32.19525,
      lng: -111.0151,
      city: 'Tucson',
      state: 'AZ',
    },
    85714: {
      lat: 32.16997,
      lng: -110.94653,
      city: 'Tucson',
      state: 'AZ',
    },
    85715: {
      lat: 32.24898,
      lng: -110.83118,
      city: 'Tucson',
      state: 'AZ',
    },
    85716: {
      lat: 32.24222,
      lng: -110.92351,
      city: 'Tucson',
      state: 'AZ',
    },
    85718: {
      lat: 32.31078,
      lng: -110.91819,
      city: 'Tucson',
      state: 'AZ',
    },
    85719: {
      lat: 32.2462,
      lng: -110.94876,
      city: 'Tucson',
      state: 'AZ',
    },
    85721: {
      lat: 32.23107,
      lng: -110.94957,
      city: 'Tucson',
      state: 'AZ',
    },
    85723: {
      lat: 32.18189,
      lng: -110.96496,
      city: 'Tucson',
      state: 'AZ',
    },
    85724: {
      lat: 32.24072,
      lng: -110.94555,
      city: 'Tucson',
      state: 'AZ',
    },
    85726: {
      lat: 32.20437,
      lng: -110.94529,
      city: 'Tucson',
      state: 'AZ',
    },
    85730: {
      lat: 32.19064,
      lng: -110.72976,
      city: 'Tucson',
      state: 'AZ',
    },
    85734: {
      lat: 32.06761,
      lng: -110.86717,
      city: 'Tucson',
      state: 'AZ',
    },
    85735: {
      lat: 32.10389,
      lng: -111.33437,
      city: 'Tucson',
      state: 'AZ',
    },
    85736: {
      lat: 31.87623,
      lng: -111.37472,
      city: 'Tucson',
      state: 'AZ',
    },
    85737: {
      lat: 32.41425,
      lng: -110.94683,
      city: 'Tucson',
      state: 'AZ',
    },
    85739: {
      lat: 32.6137,
      lng: -111.00758,
      city: 'Tucson',
      state: 'AZ',
    },
    85741: {
      lat: 32.3364,
      lng: -111.04077,
      city: 'Tucson',
      state: 'AZ',
    },
    85742: {
      lat: 32.3898,
      lng: -111.05113,
      city: 'Tucson',
      state: 'AZ',
    },
    85743: {
      lat: 32.30501,
      lng: -111.18548,
      city: 'Tucson',
      state: 'AZ',
    },
    85745: {
      lat: 32.25014,
      lng: -111.06253,
      city: 'Tucson',
      state: 'AZ',
    },
    85746: {
      lat: 32.06964,
      lng: -111.09047,
      city: 'Tucson',
      state: 'AZ',
    },
    85747: {
      lat: 32.09318,
      lng: -110.7723,
      city: 'Tucson',
      state: 'AZ',
    },
    85748: {
      lat: 32.21904,
      lng: -110.75205,
      city: 'Tucson',
      state: 'AZ',
    },
    85749: {
      lat: 32.28129,
      lng: -110.72604,
      city: 'Tucson',
      state: 'AZ',
    },
    85750: {
      lat: 32.29952,
      lng: -110.84288,
      city: 'Tucson',
      state: 'AZ',
    },
    85755: {
      lat: 32.46836,
      lng: -110.98152,
      city: 'Tucson',
      state: 'AZ',
    },
    85756: {
      lat: 32.07034,
      lng: -110.90625,
      city: 'Tucson',
      state: 'AZ',
    },
    85757: {
      lat: 32.12835,
      lng: -111.12228,
      city: 'Tucson',
      state: 'AZ',
    },
    85901: {
      lat: 34.29348,
      lng: -109.99878,
      city: 'Show Low',
      state: 'AZ',
    },
    85902: {
      lat: 34.11509,
      lng: -110.16233,
      city: 'Show Low',
      state: 'AZ',
    },
    85911: {
      lat: 34.14844,
      lng: -110.4588,
      city: 'Cibecue',
      state: 'AZ',
    },
    85912: {
      lat: 34.41962,
      lng: -109.91624,
      city: 'White Mountain Lake',
      state: 'AZ',
    },
    85920: {
      lat: 33.85995,
      lng: -109.1753,
      city: 'Alpine',
      state: 'AZ',
    },
    85922: {
      lat: 33.49535,
      lng: -109.18864,
      city: 'Blue',
      state: 'AZ',
    },
    85923: {
      lat: 34.32313,
      lng: -110.34036,
      city: 'Clay Springs',
      state: 'AZ',
    },
    85924: {
      lat: 34.5635,
      lng: -109.67553,
      city: 'Concho',
      state: 'AZ',
    },
    85925: { lat: 34.0162, lng: -109.3557, city: 'Eagar', state: 'AZ' },
    85926: {
      lat: 33.77507,
      lng: -109.9788,
      city: 'Fort Apache',
      state: 'AZ',
    },
    85927: {
      lat: 33.99572,
      lng: -109.48079,
      city: 'Greer',
      state: 'AZ',
    },
    85928: {
      lat: 34.52276,
      lng: -110.5506,
      city: 'Heber',
      state: 'AZ',
    },
    85929: {
      lat: 34.16512,
      lng: -109.95948,
      city: 'Lakeside',
      state: 'AZ',
    },
    85930: {
      lat: 34.02744,
      lng: -109.77141,
      city: 'Mcnary',
      state: 'AZ',
    },
    85931: {
      lat: 34.45238,
      lng: -110.87003,
      city: 'Forest Lakes',
      state: 'AZ',
    },
    85932: {
      lat: 33.95958,
      lng: -109.1943,
      city: 'Nutrioso',
      state: 'AZ',
    },
    85933: {
      lat: 34.36089,
      lng: -110.50955,
      city: 'Overgaard',
      state: 'AZ',
    },
    85934: {
      lat: 34.30251,
      lng: -110.24432,
      city: 'Pinedale',
      state: 'AZ',
    },
    85935: {
      lat: 34.14166,
      lng: -109.90453,
      city: 'Pinetop',
      state: 'AZ',
    },
    85936: {
      lat: 34.60662,
      lng: -109.29134,
      city: 'Saint Johns',
      state: 'AZ',
    },
    85937: {
      lat: 34.56927,
      lng: -110.01285,
      city: 'Snowflake',
      state: 'AZ',
    },
    85938: {
      lat: 34.17814,
      lng: -109.40089,
      city: 'Springerville',
      state: 'AZ',
    },
    85939: {
      lat: 34.43729,
      lng: -110.08754,
      city: 'Taylor',
      state: 'AZ',
    },
    85940: {
      lat: 34.25265,
      lng: -109.69729,
      city: 'Vernon',
      state: 'AZ',
    },
    85941: {
      lat: 33.88756,
      lng: -110.0648,
      city: 'Whiteriver',
      state: 'AZ',
    },
    85942: {
      lat: 34.77442,
      lng: -110.03549,
      city: 'Woodruff',
      state: 'AZ',
    },
    86001: {
      lat: 35.35119,
      lng: -111.75607,
      city: 'Flagstaff',
      state: 'AZ',
    },
    86003: {
      lat: 35.24259,
      lng: -111.20202,
      city: 'Flagstaff',
      state: 'AZ',
    },
    86004: {
      lat: 35.37546,
      lng: -111.37515,
      city: 'Flagstaff',
      state: 'AZ',
    },
    86005: {
      lat: 35.08125,
      lng: -111.62832,
      city: 'Flagstaff',
      state: 'AZ',
    },
    86011: {
      lat: 35.18953,
      lng: -111.65469,
      city: 'Flagstaff',
      state: 'AZ',
    },
    86015: {
      lat: 35.22408,
      lng: -111.80972,
      city: 'Bellemont',
      state: 'AZ',
    },
    86016: {
      lat: 35.74486,
      lng: -111.6373,
      city: 'Gray Mountain',
      state: 'AZ',
    },
    86017: {
      lat: 34.91938,
      lng: -111.66077,
      city: 'Munds Park',
      state: 'AZ',
    },
    86018: {
      lat: 35.24716,
      lng: -111.96204,
      city: 'Parks',
      state: 'AZ',
    },
    86020: {
      lat: 36.00133,
      lng: -111.51263,
      city: 'Cameron',
      state: 'AZ',
    },
    86021: {
      lat: 36.92707,
      lng: -113.10205,
      city: 'Colorado City',
      state: 'AZ',
    },
    86022: {
      lat: 36.7702,
      lng: -112.5449,
      city: 'Fredonia',
      state: 'AZ',
    },
    86023: {
      lat: 36.13498,
      lng: -112.23438,
      city: 'Grand Canyon',
      state: 'AZ',
    },
    86024: {
      lat: 34.68509,
      lng: -111.20244,
      city: 'Happy Jack',
      state: 'AZ',
    },
    86025: {
      lat: 34.90484,
      lng: -110.08557,
      city: 'Holbrook',
      state: 'AZ',
    },
    86028: {
      lat: 35.02873,
      lng: -109.81056,
      city: 'Petrified Forest Natl Pk',
      state: 'AZ',
    },
    86029: {
      lat: 34.96834,
      lng: -110.01175,
      city: 'Sun Valley',
      state: 'AZ',
    },
    86030: {
      lat: 35.87005,
      lng: -110.80709,
      city: 'Hotevilla',
      state: 'AZ',
    },
    86031: {
      lat: 35.3534,
      lng: -110.0647,
      city: 'Indian Wells',
      state: 'AZ',
    },
    86032: {
      lat: 35.04288,
      lng: -110.32234,
      city: 'Joseph City',
      state: 'AZ',
    },
    86033: {
      lat: 36.67692,
      lng: -110.25536,
      city: 'Kayenta',
      state: 'AZ',
    },
    86034: {
      lat: 35.77145,
      lng: -110.138,
      city: 'Keams Canyon',
      state: 'AZ',
    },
    86035: {
      lat: 35.39631,
      lng: -111.02806,
      city: 'Leupp',
      state: 'AZ',
    },
    86036: {
      lat: 36.72434,
      lng: -111.82083,
      city: 'Marble Canyon',
      state: 'AZ',
    },
    86038: {
      lat: 34.9018,
      lng: -111.49161,
      city: 'Mormon Lake',
      state: 'AZ',
    },
    86039: {
      lat: 35.98813,
      lng: -110.56688,
      city: 'Kykotsmovi Village',
      state: 'AZ',
    },
    86040: { lat: 36.66294, lng: -111.3852, city: 'Page', state: 'AZ' },
    86042: {
      lat: 35.86498,
      lng: -110.38447,
      city: 'Polacca',
      state: 'AZ',
    },
    86043: {
      lat: 35.81049,
      lng: -110.52904,
      city: 'Second Mesa',
      state: 'AZ',
    },
    86044: {
      lat: 36.75137,
      lng: -110.82626,
      city: 'Tonalea',
      state: 'AZ',
    },
    86045: {
      lat: 36.14775,
      lng: -111.13344,
      city: 'Tuba City',
      state: 'AZ',
    },
    86046: {
      lat: 35.43882,
      lng: -112.17428,
      city: 'Williams',
      state: 'AZ',
    },
    86047: {
      lat: 35.18181,
      lng: -110.63785,
      city: 'Winslow',
      state: 'AZ',
    },
    86052: {
      lat: 36.29112,
      lng: -112.12543,
      city: 'North Rim',
      state: 'AZ',
    },
    86053: {
      lat: 36.75699,
      lng: -111.05597,
      city: 'Kaibeto',
      state: 'AZ',
    },
    86054: {
      lat: 36.67546,
      lng: -110.61259,
      city: 'Shonto',
      state: 'AZ',
    },
    86301: {
      lat: 34.59452,
      lng: -112.42454,
      city: 'Prescott',
      state: 'AZ',
    },
    86303: {
      lat: 34.4894,
      lng: -112.42897,
      city: 'Prescott',
      state: 'AZ',
    },
    86305: {
      lat: 34.7906,
      lng: -112.75229,
      city: 'Prescott',
      state: 'AZ',
    },
    86313: {
      lat: 34.55416,
      lng: -112.45288,
      city: 'Prescott',
      state: 'AZ',
    },
    86314: {
      lat: 34.59823,
      lng: -112.33079,
      city: 'Prescott Valley',
      state: 'AZ',
    },
    86315: {
      lat: 34.67403,
      lng: -112.2891,
      city: 'Prescott Valley',
      state: 'AZ',
    },
    86320: {
      lat: 35.36745,
      lng: -112.63995,
      city: 'Ash Fork',
      state: 'AZ',
    },
    86321: {
      lat: 34.55367,
      lng: -113.08895,
      city: 'Bagdad',
      state: 'AZ',
    },
    86322: {
      lat: 34.47486,
      lng: -111.80587,
      city: 'Camp Verde',
      state: 'AZ',
    },
    86323: {
      lat: 34.76606,
      lng: -112.40424,
      city: 'Chino Valley',
      state: 'AZ',
    },
    86324: {
      lat: 34.836,
      lng: -112.1304,
      city: 'Clarkdale',
      state: 'AZ',
    },
    86325: {
      lat: 34.73347,
      lng: -111.89884,
      city: 'Cornville',
      state: 'AZ',
    },
    86326: {
      lat: 34.69857,
      lng: -112.01383,
      city: 'Cottonwood',
      state: 'AZ',
    },
    86327: {
      lat: 34.55682,
      lng: -112.11089,
      city: 'Dewey',
      state: 'AZ',
    },
    86329: {
      lat: 34.48589,
      lng: -112.27666,
      city: 'Humboldt',
      state: 'AZ',
    },
    86331: {
      lat: 34.76009,
      lng: -112.11704,
      city: 'Jerome',
      state: 'AZ',
    },
    86332: {
      lat: 34.30403,
      lng: -112.61809,
      city: 'Kirkland',
      state: 'AZ',
    },
    86333: {
      lat: 34.32195,
      lng: -112.08352,
      city: 'Mayer',
      state: 'AZ',
    },
    86334: {
      lat: 34.94847,
      lng: -112.53946,
      city: 'Paulden',
      state: 'AZ',
    },
    86335: {
      lat: 34.65216,
      lng: -111.68044,
      city: 'Rimrock',
      state: 'AZ',
    },
    86336: {
      lat: 34.8902,
      lng: -111.85157,
      city: 'Sedona',
      state: 'AZ',
    },
    86337: {
      lat: 35.24422,
      lng: -113.0505,
      city: 'Seligman',
      state: 'AZ',
    },
    86338: {
      lat: 34.57306,
      lng: -112.75756,
      city: 'Skull Valley',
      state: 'AZ',
    },
    86343: {
      lat: 34.17937,
      lng: -112.31236,
      city: 'Crown King',
      state: 'AZ',
    },
    86351: {
      lat: 34.77846,
      lng: -111.78514,
      city: 'Sedona',
      state: 'AZ',
    },
    86401: {
      lat: 35.11024,
      lng: -113.70423,
      city: 'Kingman',
      state: 'AZ',
    },
    86403: {
      lat: 34.47703,
      lng: -114.34423,
      city: 'Lake Havasu City',
      state: 'AZ',
    },
    86404: {
      lat: 34.61156,
      lng: -114.29452,
      city: 'Lake Havasu City',
      state: 'AZ',
    },
    86406: {
      lat: 34.42269,
      lng: -114.12418,
      city: 'Lake Havasu City',
      state: 'AZ',
    },
    86409: {
      lat: 35.46429,
      lng: -114.03245,
      city: 'Kingman',
      state: 'AZ',
    },
    86411: {
      lat: 35.44761,
      lng: -113.74381,
      city: 'Hackberry',
      state: 'AZ',
    },
    86413: {
      lat: 35.16995,
      lng: -114.25695,
      city: 'Golden Valley',
      state: 'AZ',
    },
    86426: {
      lat: 35.0134,
      lng: -114.54968,
      city: 'Fort Mohave',
      state: 'AZ',
    },
    86429: {
      lat: 35.28769,
      lng: -114.51261,
      city: 'Bullhead City',
      state: 'AZ',
    },
    86431: {
      lat: 35.42521,
      lng: -114.24738,
      city: 'Chloride',
      state: 'AZ',
    },
    86432: {
      lat: 36.79085,
      lng: -113.73783,
      city: 'Littlefield',
      state: 'AZ',
    },
    86433: {
      lat: 34.92862,
      lng: -114.35853,
      city: 'Oatman',
      state: 'AZ',
    },
    86434: {
      lat: 35.65959,
      lng: -113.58538,
      city: 'Peach Springs',
      state: 'AZ',
    },
    86435: {
      lat: 36.00915,
      lng: -112.94567,
      city: 'Supai',
      state: 'AZ',
    },
    86436: {
      lat: 34.73914,
      lng: -114.42273,
      city: 'Topock',
      state: 'AZ',
    },
    86437: {
      lat: 35.39511,
      lng: -113.59784,
      city: 'Valentine',
      state: 'AZ',
    },
    86438: {
      lat: 34.68595,
      lng: -113.98175,
      city: 'Yucca',
      state: 'AZ',
    },
    86440: {
      lat: 34.88855,
      lng: -114.54071,
      city: 'Mohave Valley',
      state: 'AZ',
    },
    86441: {
      lat: 35.56205,
      lng: -114.30384,
      city: 'Dolan Springs',
      state: 'AZ',
    },
    86442: {
      lat: 35.09139,
      lng: -114.57731,
      city: 'Bullhead City',
      state: 'AZ',
    },
    86443: {
      lat: 35.97186,
      lng: -114.46386,
      city: 'Temple Bar Marina',
      state: 'AZ',
    },
    86444: {
      lat: 35.90199,
      lng: -114.05969,
      city: 'Meadview',
      state: 'AZ',
    },
    86445: {
      lat: 35.70747,
      lng: -114.53764,
      city: 'Willow Beach',
      state: 'AZ',
    },
    86502: {
      lat: 35.1212,
      lng: -109.61705,
      city: 'Chambers',
      state: 'AZ',
    },
    86503: {
      lat: 36.20258,
      lng: -109.68224,
      city: 'Chinle',
      state: 'AZ',
    },
    86504: {
      lat: 35.86087,
      lng: -109.13995,
      city: 'Fort Defiance',
      state: 'AZ',
    },
    86505: {
      lat: 35.61872,
      lng: -109.71274,
      city: 'Ganado',
      state: 'AZ',
    },
    86506: {
      lat: 35.35206,
      lng: -109.25868,
      city: 'Houck',
      state: 'AZ',
    },
    86507: {
      lat: 36.41985,
      lng: -109.28019,
      city: 'Lukachukai',
      state: 'AZ',
    },
    86508: {
      lat: 35.35086,
      lng: -109.08737,
      city: 'Lupton',
      state: 'AZ',
    },
    86510: {
      lat: 36.23061,
      lng: -110.27182,
      city: 'Pinon',
      state: 'AZ',
    },
    86511: {
      lat: 35.66536,
      lng: -109.26397,
      city: 'Saint Michaels',
      state: 'AZ',
    },
    86512: {
      lat: 35.079,
      lng: -109.24737,
      city: 'Sanders',
      state: 'AZ',
    },
    86514: {
      lat: 36.90487,
      lng: -109.32028,
      city: 'Teec Nos Pos',
      state: 'AZ',
    },
    86515: {
      lat: 35.70301,
      lng: -108.99057,
      city: 'Window Rock',
      state: 'AZ',
    },
    86520: {
      lat: 36.05891,
      lng: -109.95208,
      city: 'Blue Gap',
      state: 'AZ',
    },
    86535: {
      lat: 36.82372,
      lng: -109.86199,
      city: 'Dennehotso',
      state: 'AZ',
    },
    86538: {
      lat: 36.43323,
      lng: -109.63752,
      city: 'Many Farms',
      state: 'AZ',
    },
    86540: {
      lat: 35.93953,
      lng: -109.44886,
      city: 'Nazlini',
      state: 'AZ',
    },
    86544: {
      lat: 36.58162,
      lng: -109.13818,
      city: 'Red Valley',
      state: 'AZ',
    },
    86545: {
      lat: 36.71125,
      lng: -109.58943,
      city: 'Rock Point',
      state: 'AZ',
    },
    86547: {
      lat: 36.52759,
      lng: -109.46019,
      city: 'Round Rock',
      state: 'AZ',
    },
    86556: {
      lat: 36.24093,
      lng: -109.21631,
      city: 'Tsaile',
      state: 'AZ',
    },
    87001: {
      lat: 35.41231,
      lng: -106.37944,
      city: 'Algodones',
      state: 'NM',
    },
    87002: {
      lat: 34.63619,
      lng: -106.82517,
      city: 'Belen',
      state: 'NM',
    },
    87004: {
      lat: 35.38555,
      lng: -106.58493,
      city: 'Bernalillo',
      state: 'NM',
    },
    87005: {
      lat: 35.25669,
      lng: -107.98867,
      city: 'Bluewater',
      state: 'NM',
    },
    87006: {
      lat: 34.48616,
      lng: -106.84202,
      city: 'Bosque',
      state: 'NM',
    },
    87007: {
      lat: 34.96582,
      lng: -107.46717,
      city: 'Casa Blanca',
      state: 'NM',
    },
    87008: {
      lat: 35.14779,
      lng: -106.39133,
      city: 'Cedar Crest',
      state: 'NM',
    },
    87010: {
      lat: 35.40517,
      lng: -106.13493,
      city: 'Cerrillos',
      state: 'NM',
    },
    87011: {
      lat: 34.08236,
      lng: -105.97225,
      city: 'Claunch',
      state: 'NM',
    },
    87012: {
      lat: 36.13341,
      lng: -106.67312,
      city: 'Coyote',
      state: 'NM',
    },
    87013: {
      lat: 35.90014,
      lng: -107.33509,
      city: 'Cuba',
      state: 'NM',
    },
    87014: {
      lat: 35.1372,
      lng: -107.45315,
      city: 'Cubero',
      state: 'NM',
    },
    87015: {
      lat: 35.07535,
      lng: -106.19123,
      city: 'Edgewood',
      state: 'NM',
    },
    87016: {
      lat: 34.75117,
      lng: -106.01074,
      city: 'Estancia',
      state: 'NM',
    },
    87017: {
      lat: 36.30624,
      lng: -106.73759,
      city: 'Gallina',
      state: 'NM',
    },
    87018: {
      lat: 36.11644,
      lng: -107.51745,
      city: 'Counselor',
      state: 'NM',
    },
    87020: {
      lat: 35.0589,
      lng: -107.92797,
      city: 'Grants',
      state: 'NM',
    },
    87021: {
      lat: 35.19673,
      lng: -107.88621,
      city: 'Milan',
      state: 'NM',
    },
    87022: {
      lat: 34.90986,
      lng: -106.69501,
      city: 'Isleta',
      state: 'NM',
    },
    87023: {
      lat: 34.59583,
      lng: -106.7633,
      city: 'Jarales',
      state: 'NM',
    },
    87024: {
      lat: 35.6765,
      lng: -106.75031,
      city: 'Jemez Pueblo',
      state: 'NM',
    },
    87025: {
      lat: 35.84308,
      lng: -106.61862,
      city: 'Jemez Springs',
      state: 'NM',
    },
    87026: {
      lat: 34.97119,
      lng: -107.17556,
      city: 'Laguna',
      state: 'NM',
    },
    87027: {
      lat: 36.12734,
      lng: -106.94737,
      city: 'La Jara',
      state: 'NM',
    },
    87028: {
      lat: 34.35076,
      lng: -106.78789,
      city: 'La Joya',
      state: 'NM',
    },
    87029: {
      lat: 36.39394,
      lng: -107.03949,
      city: 'Lindrith',
      state: 'NM',
    },
    87031: {
      lat: 34.78007,
      lng: -106.72649,
      city: 'Los Lunas',
      state: 'NM',
    },
    87032: {
      lat: 34.86786,
      lng: -106.04201,
      city: 'Mcintosh',
      state: 'NM',
    },
    87034: {
      lat: 34.91939,
      lng: -107.6559,
      city: 'Pueblo Of Acoma',
      state: 'NM',
    },
    87035: {
      lat: 34.95116,
      lng: -105.90273,
      city: 'Moriarty',
      state: 'NM',
    },
    87036: {
      lat: 34.35591,
      lng: -106.24227,
      city: 'Mountainair',
      state: 'NM',
    },
    87037: {
      lat: 36.18392,
      lng: -107.78087,
      city: 'Nageezi',
      state: 'NM',
    },
    87038: {
      lat: 35.0944,
      lng: -107.43259,
      city: 'New Laguna',
      state: 'NM',
    },
    87040: {
      lat: 35.14192,
      lng: -107.39887,
      city: 'Paguate',
      state: 'NM',
    },
    87041: {
      lat: 35.60202,
      lng: -106.31054,
      city: 'Pena Blanca',
      state: 'NM',
    },
    87042: {
      lat: 34.82827,
      lng: -106.68619,
      city: 'Peralta',
      state: 'NM',
    },
    87043: {
      lat: 35.28978,
      lng: -106.44418,
      city: 'Placitas',
      state: 'NM',
    },
    87044: {
      lat: 35.71254,
      lng: -106.65652,
      city: 'Ponderosa',
      state: 'NM',
    },
    87045: {
      lat: 35.40018,
      lng: -108.00182,
      city: 'Prewitt',
      state: 'NM',
    },
    87046: {
      lat: 36.27773,
      lng: -106.91643,
      city: 'Regina',
      state: 'NM',
    },
    87047: {
      lat: 35.24866,
      lng: -106.27629,
      city: 'Sandia Park',
      state: 'NM',
    },
    87048: {
      lat: 35.23624,
      lng: -106.61977,
      city: 'Corrales',
      state: 'NM',
    },
    87049: {
      lat: 35.11699,
      lng: -107.61026,
      city: 'San Fidel',
      state: 'NM',
    },
    87051: {
      lat: 35.06188,
      lng: -107.85426,
      city: 'San Rafael',
      state: 'NM',
    },
    87052: {
      lat: 35.52867,
      lng: -106.36935,
      city: 'Santo Domingo Pueblo',
      state: 'NM',
    },
    87053: {
      lat: 35.57537,
      lng: -106.87832,
      city: 'San Ysidro',
      state: 'NM',
    },
    87056: {
      lat: 35.16197,
      lng: -105.89566,
      city: 'Stanley',
      state: 'NM',
    },
    87059: {
      lat: 34.99647,
      lng: -106.29764,
      city: 'Tijeras',
      state: 'NM',
    },
    87060: {
      lat: 34.74292,
      lng: -106.73571,
      city: 'Tome',
      state: 'NM',
    },
    87061: {
      lat: 34.705,
      lng: -106.38145,
      city: 'Torreon',
      state: 'NM',
    },
    87062: {
      lat: 34.46187,
      lng: -106.69719,
      city: 'Veguita',
      state: 'NM',
    },
    87063: {
      lat: 34.49265,
      lng: -105.85432,
      city: 'Willard',
      state: 'NM',
    },
    87064: {
      lat: 36.1849,
      lng: -106.53923,
      city: 'Youngsville',
      state: 'NM',
    },
    87068: {
      lat: 34.90354,
      lng: -106.64321,
      city: 'Bosque Farms',
      state: 'NM',
    },
    87070: {
      lat: 35.02255,
      lng: -105.74037,
      city: 'Clines Corners',
      state: 'NM',
    },
    87072: {
      lat: 35.60828,
      lng: -106.3955,
      city: 'Cochiti Pueblo',
      state: 'NM',
    },
    87083: {
      lat: 35.64496,
      lng: -106.36366,
      city: 'Cochiti Lake',
      state: 'NM',
    },
    87101: {
      lat: 35.09669,
      lng: -106.64339,
      city: 'Albuquerque',
      state: 'NM',
    },
    87102: {
      lat: 35.08116,
      lng: -106.64636,
      city: 'Albuquerque',
      state: 'NM',
    },
    87104: {
      lat: 35.10278,
      lng: -106.67492,
      city: 'Albuquerque',
      state: 'NM',
    },
    87105: {
      lat: 34.99512,
      lng: -106.68644,
      city: 'Albuquerque',
      state: 'NM',
    },
    87106: {
      lat: 35.00818,
      lng: -106.61185,
      city: 'Albuquerque',
      state: 'NM',
    },
    87107: {
      lat: 35.13747,
      lng: -106.64363,
      city: 'Albuquerque',
      state: 'NM',
    },
    87108: {
      lat: 35.07235,
      lng: -106.57835,
      city: 'Albuquerque',
      state: 'NM',
    },
    87109: {
      lat: 35.15285,
      lng: -106.57543,
      city: 'Albuquerque',
      state: 'NM',
    },
    87110: {
      lat: 35.10812,
      lng: -106.57816,
      city: 'Albuquerque',
      state: 'NM',
    },
    87111: {
      lat: 35.13824,
      lng: -106.51589,
      city: 'Albuquerque',
      state: 'NM',
    },
    87112: {
      lat: 35.10093,
      lng: -106.51622,
      city: 'Albuquerque',
      state: 'NM',
    },
    87113: {
      lat: 35.19249,
      lng: -106.5708,
      city: 'Albuquerque',
      state: 'NM',
    },
    87114: {
      lat: 35.19718,
      lng: -106.68216,
      city: 'Albuquerque',
      state: 'NM',
    },
    87116: {
      lat: 35.05056,
      lng: -106.56028,
      city: 'Albuquerque',
      state: 'NM',
    },
    87117: {
      lat: 34.99486,
      lng: -106.51697,
      city: 'Kirtland Afb',
      state: 'NM',
    },
    87120: {
      lat: 35.14097,
      lng: -106.76522,
      city: 'Albuquerque',
      state: 'NM',
    },
    87121: {
      lat: 35.01031,
      lng: -106.87816,
      city: 'Albuquerque',
      state: 'NM',
    },
    87122: {
      lat: 35.18685,
      lng: -106.48827,
      city: 'Albuquerque',
      state: 'NM',
    },
    87123: {
      lat: 35.06206,
      lng: -106.46991,
      city: 'Albuquerque',
      state: 'NM',
    },
    87124: {
      lat: 35.27144,
      lng: -106.79627,
      city: 'Rio Rancho',
      state: 'NM',
    },
    87144: {
      lat: 35.33074,
      lng: -106.71248,
      city: 'Rio Rancho',
      state: 'NM',
    },
    87151: {
      lat: 35.00772,
      lng: -106.86035,
      city: 'Albuquerque',
      state: 'NM',
    },
    87301: {
      lat: 35.52153,
      lng: -108.76524,
      city: 'Gallup',
      state: 'NM',
    },
    87305: {
      lat: 35.40327,
      lng: -108.73151,
      city: 'Gallup',
      state: 'NM',
    },
    87310: {
      lat: 35.75365,
      lng: -108.60267,
      city: 'Brimhall',
      state: 'NM',
    },
    87311: {
      lat: 35.60501,
      lng: -108.50061,
      city: 'Church Rock',
      state: 'NM',
    },
    87312: {
      lat: 35.4291,
      lng: -108.34543,
      city: 'Continental Divide',
      state: 'NM',
    },
    87313: {
      lat: 35.79538,
      lng: -108.22129,
      city: 'Crownpoint',
      state: 'NM',
    },
    87315: {
      lat: 34.66247,
      lng: -108.55075,
      city: 'Fence Lake',
      state: 'NM',
    },
    87316: {
      lat: 35.48684,
      lng: -108.53328,
      city: 'Fort Wingate',
      state: 'NM',
    },
    87317: {
      lat: 35.58024,
      lng: -108.75244,
      city: 'Gamerco',
      state: 'NM',
    },
    87319: {
      lat: 35.4769,
      lng: -108.94232,
      city: 'Mentmore',
      state: 'NM',
    },
    87320: {
      lat: 35.80762,
      lng: -108.87136,
      city: 'Mexican Springs',
      state: 'NM',
    },
    87321: {
      lat: 35.06791,
      lng: -108.40638,
      city: 'Ramah',
      state: 'NM',
    },
    87322: {
      lat: 35.48531,
      lng: -108.66253,
      city: 'Rehoboth',
      state: 'NM',
    },
    87323: {
      lat: 35.45214,
      lng: -108.17531,
      city: 'Thoreau',
      state: 'NM',
    },
    87325: {
      lat: 35.97495,
      lng: -108.58746,
      city: 'Tohatchi',
      state: 'NM',
    },
    87326: {
      lat: 35.27223,
      lng: -108.88765,
      city: 'Vanderwagen',
      state: 'NM',
    },
    87327: {
      lat: 35.06529,
      lng: -108.77813,
      city: 'Zuni',
      state: 'NM',
    },
    87328: {
      lat: 36.02541,
      lng: -109.03638,
      city: 'Navajo',
      state: 'NM',
    },
    87347: {
      lat: 35.38502,
      lng: -108.47052,
      city: 'Jamestown',
      state: 'NM',
    },
    87357: {
      lat: 34.82644,
      lng: -108.41435,
      city: 'Pinehill',
      state: 'NM',
    },
    87364: {
      lat: 36.13538,
      lng: -108.70875,
      city: 'Sheep Springs',
      state: 'NM',
    },
    87365: {
      lat: 35.53585,
      lng: -108.13796,
      city: 'Smith Lake',
      state: 'NM',
    },
    87375: {
      lat: 35.66114,
      lng: -108.79477,
      city: 'Yatahey',
      state: 'NM',
    },
    87401: {
      lat: 36.73824,
      lng: -108.18377,
      city: 'Farmington',
      state: 'NM',
    },
    87402: {
      lat: 36.79174,
      lng: -108.14831,
      city: 'Farmington',
      state: 'NM',
    },
    87410: {
      lat: 36.86708,
      lng: -107.89433,
      city: 'Aztec',
      state: 'NM',
    },
    87412: {
      lat: 36.6569,
      lng: -107.51956,
      city: 'Blanco',
      state: 'NM',
    },
    87413: {
      lat: 36.45914,
      lng: -107.96408,
      city: 'Bloomfield',
      state: 'NM',
    },
    87415: {
      lat: 36.82823,
      lng: -108.10125,
      city: 'Flora Vista',
      state: 'NM',
    },
    87416: {
      lat: 36.58687,
      lng: -108.43897,
      city: 'Fruitland',
      state: 'NM',
    },
    87417: {
      lat: 36.74456,
      lng: -108.34551,
      city: 'Kirtland',
      state: 'NM',
    },
    87418: {
      lat: 36.94938,
      lng: -108.14728,
      city: 'La Plata',
      state: 'NM',
    },
    87419: {
      lat: 36.88767,
      lng: -107.55195,
      city: 'Navajo Dam',
      state: 'NM',
    },
    87420: {
      lat: 36.72142,
      lng: -108.84406,
      city: 'Shiprock',
      state: 'NM',
    },
    87421: {
      lat: 36.7772,
      lng: -108.53676,
      city: 'Waterflow',
      state: 'NM',
    },
    87455: {
      lat: 36.27109,
      lng: -108.71936,
      city: 'Newcomb',
      state: 'NM',
    },
    87461: {
      lat: 36.43717,
      lng: -108.91099,
      city: 'Sanostee',
      state: 'NM',
    },
    87499: {
      lat: 36.32165,
      lng: -108.24657,
      city: 'Farmington',
      state: 'NM',
    },
    87501: {
      lat: 35.71273,
      lng: -105.86523,
      city: 'Santa Fe',
      state: 'NM',
    },
    87505: {
      lat: 35.62307,
      lng: -105.86891,
      city: 'Santa Fe',
      state: 'NM',
    },
    87506: {
      lat: 35.82119,
      lng: -105.96802,
      city: 'Santa Fe',
      state: 'NM',
    },
    87507: {
      lat: 35.621,
      lng: -106.11061,
      city: 'Santa Fe',
      state: 'NM',
    },
    87508: {
      lat: 35.52064,
      lng: -105.94593,
      city: 'Santa Fe',
      state: 'NM',
    },
    87510: {
      lat: 36.35906,
      lng: -106.48312,
      city: 'Abiquiu',
      state: 'NM',
    },
    87511: {
      lat: 36.08905,
      lng: -105.96448,
      city: 'Alcalde',
      state: 'NM',
    },
    87512: {
      lat: 36.90885,
      lng: -105.36515,
      city: 'Amalia',
      state: 'NM',
    },
    87513: {
      lat: 36.58367,
      lng: -105.70121,
      city: 'Arroyo Hondo',
      state: 'NM',
    },
    87514: {
      lat: 36.52957,
      lng: -105.60055,
      city: 'Arroyo Seco',
      state: 'NM',
    },
    87515: {
      lat: 36.56257,
      lng: -106.3696,
      city: 'Canjilon',
      state: 'NM',
    },
    87516: {
      lat: 36.18116,
      lng: -106.46149,
      city: 'Canones',
      state: 'NM',
    },
    87517: {
      lat: 36.39035,
      lng: -105.79269,
      city: 'Carson',
      state: 'NM',
    },
    87518: {
      lat: 36.51197,
      lng: -106.54235,
      city: 'Cebolla',
      state: 'NM',
    },
    87519: {
      lat: 36.76472,
      lng: -105.63502,
      city: 'Cerro',
      state: 'NM',
    },
    87520: {
      lat: 36.88419,
      lng: -106.63552,
      city: 'Chama',
      state: 'NM',
    },
    87521: {
      lat: 36.08506,
      lng: -105.70477,
      city: 'Chamisal',
      state: 'NM',
    },
    87522: {
      lat: 35.96889,
      lng: -105.84639,
      city: 'Chimayo',
      state: 'NM',
    },
    87523: {
      lat: 36.01115,
      lng: -105.86273,
      city: 'Cordova',
      state: 'NM',
    },
    87524: {
      lat: 36.96986,
      lng: -105.5375,
      city: 'Costilla',
      state: 'NM',
    },
    87525: {
      lat: 36.5985,
      lng: -105.49812,
      city: 'Taos Ski Valley',
      state: 'NM',
    },
    87527: {
      lat: 36.16875,
      lng: -105.86352,
      city: 'Dixon',
      state: 'NM',
    },
    87528: {
      lat: 36.79432,
      lng: -106.87989,
      city: 'Dulce',
      state: 'NM',
    },
    87529: {
      lat: 36.45001,
      lng: -105.66147,
      city: 'El Prado',
      state: 'NM',
    },
    87530: {
      lat: 36.34088,
      lng: -106.20198,
      city: 'El Rito',
      state: 'NM',
    },
    87531: {
      lat: 36.25326,
      lng: -105.84584,
      city: 'Embudo',
      state: 'NM',
    },
    87532: {
      lat: 35.97965,
      lng: -106.1048,
      city: 'Espanola',
      state: 'NM',
    },
    87535: {
      lat: 35.56675,
      lng: -105.74719,
      city: 'Glorieta',
      state: 'NM',
    },
    87537: {
      lat: 36.1043,
      lng: -106.2454,
      city: 'Hernandez',
      state: 'NM',
    },
    87538: {
      lat: 35.49323,
      lng: -105.53767,
      city: 'Ilfeld',
      state: 'NM',
    },
    87539: {
      lat: 36.41192,
      lng: -106.02393,
      city: 'La Madera',
      state: 'NM',
    },
    87540: { lat: 35.39987, lng: -105.8759, city: 'Lamy', state: 'NM' },
    87543: {
      lat: 36.10399,
      lng: -105.65781,
      city: 'Llano',
      state: 'NM',
    },
    87544: {
      lat: 35.7869,
      lng: -106.31914,
      city: 'Los Alamos',
      state: 'NM',
    },
    87547: {
      lat: 35.81046,
      lng: -106.20579,
      city: 'White Rock',
      state: 'NM',
    },
    87548: {
      lat: 36.1756,
      lng: -106.18491,
      city: 'Medanales',
      state: 'NM',
    },
    87549: {
      lat: 36.23237,
      lng: -106.0568,
      city: 'Ojo Caliente',
      state: 'NM',
    },
    87551: {
      lat: 36.6925,
      lng: -106.66872,
      city: 'Los Ojos',
      state: 'NM',
    },
    87552: {
      lat: 35.67026,
      lng: -105.61382,
      city: 'Pecos',
      state: 'NM',
    },
    87553: {
      lat: 36.20389,
      lng: -105.72094,
      city: 'Penasco',
      state: 'NM',
    },
    87554: {
      lat: 36.54573,
      lng: -106.0633,
      city: 'Petaca',
      state: 'NM',
    },
    87556: {
      lat: 36.81848,
      lng: -105.5575,
      city: 'Questa',
      state: 'NM',
    },
    87557: {
      lat: 36.28603,
      lng: -105.6331,
      city: 'Ranchos De Taos',
      state: 'NM',
    },
    87558: {
      lat: 36.67279,
      lng: -105.40523,
      city: 'Red River',
      state: 'NM',
    },
    87560: {
      lat: 35.22567,
      lng: -105.50185,
      city: 'Ribera',
      state: 'NM',
    },
    87562: {
      lat: 35.40668,
      lng: -105.65522,
      city: 'Rowe',
      state: 'NM',
    },
    87564: {
      lat: 36.61566,
      lng: -105.61797,
      city: 'San Cristobal',
      state: 'NM',
    },
    87565: {
      lat: 35.4442,
      lng: -105.49384,
      city: 'San Jose',
      state: 'NM',
    },
    87566: {
      lat: 36.07242,
      lng: -106.07185,
      city: 'Ohkay Owingeh',
      state: 'NM',
    },
    87567: {
      lat: 35.99401,
      lng: -106.01872,
      city: 'Santa Cruz',
      state: 'NM',
    },
    87569: {
      lat: 35.35026,
      lng: -105.30509,
      city: 'Serafina',
      state: 'NM',
    },
    87571: {
      lat: 36.42507,
      lng: -105.48523,
      city: 'Taos',
      state: 'NM',
    },
    87573: {
      lat: 35.77176,
      lng: -105.70715,
      city: 'Tererro',
      state: 'NM',
    },
    87575: {
      lat: 36.64203,
      lng: -106.59341,
      city: 'Tierra Amarilla',
      state: 'NM',
    },
    87577: {
      lat: 36.63237,
      lng: -105.97755,
      city: 'Tres Piedras',
      state: 'NM',
    },
    87578: {
      lat: 36.04392,
      lng: -105.78539,
      city: 'Truchas',
      state: 'NM',
    },
    87579: {
      lat: 36.12885,
      lng: -105.56729,
      city: 'Vadito',
      state: 'NM',
    },
    87580: {
      lat: 36.55956,
      lng: -105.57459,
      city: 'Valdez',
      state: 'NM',
    },
    87581: {
      lat: 36.51531,
      lng: -106.16017,
      city: 'Vallecitos',
      state: 'NM',
    },
    87582: {
      lat: 36.15323,
      lng: -106.00246,
      city: 'Velarde',
      state: 'NM',
    },
    87583: {
      lat: 35.28221,
      lng: -105.35226,
      city: 'Villanueva',
      state: 'NM',
    },
    87701: {
      lat: 35.53091,
      lng: -104.94419,
      city: 'Las Vegas',
      state: 'NM',
    },
    87710: {
      lat: 36.3729,
      lng: -105.23873,
      city: 'Angel Fire',
      state: 'NM',
    },
    87711: {
      lat: 35.22458,
      lng: -105.14673,
      city: 'Anton Chico',
      state: 'NM',
    },
    87712: {
      lat: 35.88076,
      lng: -105.17612,
      city: 'Buena Vista',
      state: 'NM',
    },
    87713: {
      lat: 36.1708,
      lng: -105.36111,
      city: 'Chacon',
      state: 'NM',
    },
    87714: {
      lat: 36.4463,
      lng: -104.99079,
      city: 'Cimarron',
      state: 'NM',
    },
    87715: {
      lat: 36.01839,
      lng: -105.42535,
      city: 'Cleveland',
      state: 'NM',
    },
    87718: {
      lat: 36.59931,
      lng: -105.24097,
      city: 'Eagle Nest',
      state: 'NM',
    },
    87722: {
      lat: 36.12837,
      lng: -105.24424,
      city: 'Guadalupita',
      state: 'NM',
    },
    87723: {
      lat: 36.05883,
      lng: -105.37556,
      city: 'Holman',
      state: 'NM',
    },
    87724: {
      lat: 35.16044,
      lng: -104.97133,
      city: 'La Loma',
      state: 'NM',
    },
    87728: {
      lat: 36.60334,
      lng: -104.59473,
      city: 'Maxwell',
      state: 'NM',
    },
    87729: {
      lat: 36.31405,
      lng: -104.80642,
      city: 'Miami',
      state: 'NM',
    },
    87730: {
      lat: 36.10704,
      lng: -104.23485,
      city: 'Mills',
      state: 'NM',
    },
    87731: {
      lat: 35.70411,
      lng: -105.39881,
      city: 'Montezuma',
      state: 'NM',
    },
    87732: { lat: 35.942, lng: -105.47009, city: 'Mora', state: 'NM' },
    87733: {
      lat: 35.77075,
      lng: -103.82959,
      city: 'Mosquero',
      state: 'NM',
    },
    87734: {
      lat: 36.17323,
      lng: -105.09363,
      city: 'Ocate',
      state: 'NM',
    },
    87735: {
      lat: 36.04867,
      lng: -105.11978,
      city: 'Ojo Feliz',
      state: 'NM',
    },
    87736: {
      lat: 35.98431,
      lng: -105.19238,
      city: 'Rainsville',
      state: 'NM',
    },
    87740: {
      lat: 36.76707,
      lng: -104.37953,
      city: 'Raton',
      state: 'NM',
    },
    87742: {
      lat: 35.87161,
      lng: -105.44427,
      city: 'Rociada',
      state: 'NM',
    },
    87743: { lat: 35.96714, lng: -104.01174, city: 'Roy', state: 'NM' },
    87745: {
      lat: 35.7877,
      lng: -105.26635,
      city: 'Sapello',
      state: 'NM',
    },
    87746: {
      lat: 35.66271,
      lng: -104.12715,
      city: 'Solano',
      state: 'NM',
    },
    87747: {
      lat: 36.35856,
      lng: -104.44472,
      city: 'Springer',
      state: 'NM',
    },
    87749: {
      lat: 36.57827,
      lng: -105.13028,
      city: 'Ute Park',
      state: 'NM',
    },
    87750: {
      lat: 35.82498,
      lng: -104.92704,
      city: 'Valmora',
      state: 'NM',
    },
    87752: {
      lat: 36.01339,
      lng: -104.62763,
      city: 'Wagon Mound',
      state: 'NM',
    },
    87753: {
      lat: 35.85796,
      lng: -104.95477,
      city: 'Watrous',
      state: 'NM',
    },
    87801: {
      lat: 34.04824,
      lng: -106.96668,
      city: 'Socorro',
      state: 'NM',
    },
    87820: {
      lat: 33.90448,
      lng: -108.55837,
      city: 'Aragon',
      state: 'NM',
    },
    87821: {
      lat: 33.93515,
      lng: -108.0929,
      city: 'Datil',
      state: 'NM',
    },
    87823: {
      lat: 34.18348,
      lng: -106.95901,
      city: 'Lemitar',
      state: 'NM',
    },
    87824: {
      lat: 33.82708,
      lng: -108.97731,
      city: 'Luna',
      state: 'NM',
    },
    87825: {
      lat: 33.94509,
      lng: -107.6603,
      city: 'Magdalena',
      state: 'NM',
    },
    87827: {
      lat: 34.36366,
      lng: -108.12546,
      city: 'Pie Town',
      state: 'NM',
    },
    87828: {
      lat: 34.20567,
      lng: -106.92056,
      city: 'Polvadera',
      state: 'NM',
    },
    87829: {
      lat: 34.25215,
      lng: -108.64369,
      city: 'Quemado',
      state: 'NM',
    },
    87830: {
      lat: 33.70362,
      lng: -108.73884,
      city: 'Reserve',
      state: 'NM',
    },
    87831: {
      lat: 34.28303,
      lng: -106.88722,
      city: 'San Acacia',
      state: 'NM',
    },
    87832: {
      lat: 33.83949,
      lng: -106.83284,
      city: 'San Antonio',
      state: 'NM',
    },
    87901: {
      lat: 33.1753,
      lng: -107.01908,
      city: 'Truth Or Consequences',
      state: 'NM',
    },
    87930: {
      lat: 32.84962,
      lng: -107.33762,
      city: 'Arrey',
      state: 'NM',
    },
    87931: {
      lat: 32.93379,
      lng: -107.33657,
      city: 'Caballo',
      state: 'NM',
    },
    87933: {
      lat: 32.79115,
      lng: -107.27885,
      city: 'Derry',
      state: 'NM',
    },
    87935: {
      lat: 33.25423,
      lng: -107.22903,
      city: 'Elephant Butte',
      state: 'NM',
    },
    87936: {
      lat: 32.7544,
      lng: -107.26284,
      city: 'Garfield',
      state: 'NM',
    },
    87937: {
      lat: 32.60008,
      lng: -107.1972,
      city: 'Hatch',
      state: 'NM',
    },
    87939: {
      lat: 33.40339,
      lng: -107.36566,
      city: 'Monticello',
      state: 'NM',
    },
    87940: {
      lat: 32.60986,
      lng: -106.99465,
      city: 'Rincon',
      state: 'NM',
    },
    87941: {
      lat: 32.7055,
      lng: -107.21424,
      city: 'Salem',
      state: 'NM',
    },
    87942: {
      lat: 33.09447,
      lng: -107.42951,
      city: 'Williamsburg',
      state: 'NM',
    },
    87943: {
      lat: 33.44312,
      lng: -107.6817,
      city: 'Winston',
      state: 'NM',
    },
    88001: {
      lat: 32.29026,
      lng: -106.75318,
      city: 'Las Cruces',
      state: 'NM',
    },
    88002: {
      lat: 32.38275,
      lng: -106.45804,
      city: 'White Sands Missile Range',
      state: 'NM',
    },
    88003: {
      lat: 32.27868,
      lng: -106.74566,
      city: 'Las Cruces',
      state: 'NM',
    },
    88004: {
      lat: 32.25022,
      lng: -106.94908,
      city: 'Las Cruces',
      state: 'NM',
    },
    88005: {
      lat: 32.22757,
      lng: -106.99027,
      city: 'Las Cruces',
      state: 'NM',
    },
    88007: {
      lat: 32.37103,
      lng: -106.9984,
      city: 'Las Cruces',
      state: 'NM',
    },
    88008: {
      lat: 31.81867,
      lng: -106.74188,
      city: 'Santa Teresa',
      state: 'NM',
    },
    88009: {
      lat: 31.91248,
      lng: -108.53627,
      city: 'Playas',
      state: 'NM',
    },
    88011: {
      lat: 32.39533,
      lng: -106.58519,
      city: 'Las Cruces',
      state: 'NM',
    },
    88012: {
      lat: 32.58898,
      lng: -106.77135,
      city: 'Las Cruces',
      state: 'NM',
    },
    88020: {
      lat: 31.78203,
      lng: -108.82192,
      city: 'Animas',
      state: 'NM',
    },
    88021: {
      lat: 31.97338,
      lng: -106.81639,
      city: 'Anthony',
      state: 'NM',
    },
    88022: {
      lat: 32.80238,
      lng: -108.18766,
      city: 'Arenas Valley',
      state: 'NM',
    },
    88023: {
      lat: 32.71106,
      lng: -108.07749,
      city: 'Bayard',
      state: 'NM',
    },
    88024: {
      lat: 32.06539,
      lng: -106.61803,
      city: 'Berino',
      state: 'NM',
    },
    88025: {
      lat: 33.10087,
      lng: -108.71984,
      city: 'Buckhorn',
      state: 'NM',
    },
    88026: {
      lat: 32.75833,
      lng: -108.15741,
      city: 'Santa Clara',
      state: 'NM',
    },
    88027: {
      lat: 32.03625,
      lng: -106.68143,
      city: 'Chamberino',
      state: 'NM',
    },
    88028: {
      lat: 32.95131,
      lng: -108.67807,
      city: 'Cliff',
      state: 'NM',
    },
    88029: {
      lat: 31.84339,
      lng: -107.70225,
      city: 'Columbus',
      state: 'NM',
    },
    88030: {
      lat: 32.19039,
      lng: -107.72768,
      city: 'Deming',
      state: 'NM',
    },
    88034: {
      lat: 32.58114,
      lng: -107.96764,
      city: 'Faywood',
      state: 'NM',
    },
    88038: { lat: 33.0722, lng: -108.42625, city: 'Gila', state: 'NM' },
    88039: {
      lat: 33.35081,
      lng: -108.71909,
      city: 'Glenwood',
      state: 'NM',
    },
    88040: {
      lat: 31.6197,
      lng: -108.44025,
      city: 'Hachita',
      state: 'NM',
    },
    88041: {
      lat: 32.78367,
      lng: -107.89654,
      city: 'Hanover',
      state: 'NM',
    },
    88042: {
      lat: 32.80979,
      lng: -107.56385,
      city: 'Hillsboro',
      state: 'NM',
    },
    88043: {
      lat: 32.61358,
      lng: -108.11762,
      city: 'Hurley',
      state: 'NM',
    },
    88044: {
      lat: 32.01977,
      lng: -107.08116,
      city: 'La Mesa',
      state: 'NM',
    },
    88045: {
      lat: 32.3298,
      lng: -108.71057,
      city: 'Lordsburg',
      state: 'NM',
    },
    88046: {
      lat: 32.26797,
      lng: -106.79674,
      city: 'Mesilla',
      state: 'NM',
    },
    88047: {
      lat: 32.21444,
      lng: -106.71556,
      city: 'Mesilla Park',
      state: 'NM',
    },
    88048: {
      lat: 32.14966,
      lng: -106.6581,
      city: 'Mesquite',
      state: 'NM',
    },
    88049: {
      lat: 33.04814,
      lng: -108.06782,
      city: 'Mimbres',
      state: 'NM',
    },
    88051: {
      lat: 33.01471,
      lng: -108.94299,
      city: 'Mule Creek',
      state: 'NM',
    },
    88052: {
      lat: 32.45034,
      lng: -106.60186,
      city: 'Organ',
      state: 'NM',
    },
    88053: {
      lat: 32.89601,
      lng: -108.21378,
      city: 'Pinos Altos',
      state: 'NM',
    },
    88055: {
      lat: 32.7497,
      lng: -108.73985,
      city: 'Redrock',
      state: 'NM',
    },
    88056: {
      lat: 31.84062,
      lng: -109.00295,
      city: 'Rodeo',
      state: 'NM',
    },
    88061: {
      lat: 32.6211,
      lng: -108.40888,
      city: 'Silver City',
      state: 'NM',
    },
    88063: {
      lat: 31.80715,
      lng: -106.58259,
      city: 'Sunland Park',
      state: 'NM',
    },
    88065: {
      lat: 32.71375,
      lng: -108.30231,
      city: 'Tyrone',
      state: 'NM',
    },
    88072: {
      lat: 32.14242,
      lng: -106.61329,
      city: 'Vado',
      state: 'NM',
    },
    88081: {
      lat: 32.24958,
      lng: -106.28821,
      city: 'Chaparral',
      state: 'NM',
    },
    88101: {
      lat: 34.50114,
      lng: -103.26616,
      city: 'Clovis',
      state: 'NM',
    },
    88103: {
      lat: 34.38418,
      lng: -103.31551,
      city: 'Cannon Afb',
      state: 'NM',
    },
    88112: {
      lat: 34.91023,
      lng: -103.15863,
      city: 'Broadview',
      state: 'NM',
    },
    88113: {
      lat: 33.81968,
      lng: -103.08894,
      city: 'Causey',
      state: 'NM',
    },
    88114: {
      lat: 33.51026,
      lng: -103.43052,
      city: 'Crossroads',
      state: 'NM',
    },
    88115: {
      lat: 33.93185,
      lng: -103.34466,
      city: 'Dora',
      state: 'NM',
    },
    88116: {
      lat: 33.89425,
      lng: -103.82335,
      city: 'Elida',
      state: 'NM',
    },
    88118: {
      lat: 34.23626,
      lng: -103.6645,
      city: 'Floyd',
      state: 'NM',
    },
    88119: {
      lat: 34.34782,
      lng: -104.40041,
      city: 'Fort Sumner',
      state: 'NM',
    },
    88120: {
      lat: 34.90329,
      lng: -103.42523,
      city: 'Grady',
      state: 'NM',
    },
    88121: {
      lat: 34.67979,
      lng: -103.97721,
      city: 'House',
      state: 'NM',
    },
    88124: {
      lat: 34.52771,
      lng: -103.6473,
      city: 'Melrose',
      state: 'NM',
    },
    88125: {
      lat: 33.67137,
      lng: -103.24997,
      city: 'Milnesand',
      state: 'NM',
    },
    88126: { lat: 33.81642, lng: -103.35359, city: 'Pep', state: 'NM' },
    88130: {
      lat: 34.1285,
      lng: -103.31825,
      city: 'Portales',
      state: 'NM',
    },
    88132: {
      lat: 33.92382,
      lng: -103.18855,
      city: 'Rogers',
      state: 'NM',
    },
    88134: {
      lat: 34.37413,
      lng: -104.00487,
      city: 'Taiban',
      state: 'NM',
    },
    88135: {
      lat: 34.5623,
      lng: -103.08204,
      city: 'Texico',
      state: 'NM',
    },
    88136: {
      lat: 34.25356,
      lng: -104.83347,
      city: 'Yeso',
      state: 'NM',
    },
    88201: {
      lat: 33.66517,
      lng: -104.48932,
      city: 'Roswell',
      state: 'NM',
    },
    88203: {
      lat: 33.22169,
      lng: -104.43904,
      city: 'Roswell',
      state: 'NM',
    },
    88210: {
      lat: 32.77272,
      lng: -104.22567,
      city: 'Artesia',
      state: 'NM',
    },
    88220: {
      lat: 32.31421,
      lng: -104.41732,
      city: 'Carlsbad',
      state: 'NM',
    },
    88230: {
      lat: 33.21396,
      lng: -104.39764,
      city: 'Dexter',
      state: 'NM',
    },
    88231: {
      lat: 32.42376,
      lng: -103.26873,
      city: 'Eunice',
      state: 'NM',
    },
    88232: {
      lat: 33.06398,
      lng: -104.1568,
      city: 'Hagerman',
      state: 'NM',
    },
    88240: {
      lat: 32.67285,
      lng: -103.43454,
      city: 'Hobbs',
      state: 'NM',
    },
    88242: {
      lat: 32.81654,
      lng: -103.16014,
      city: 'Hobbs',
      state: 'NM',
    },
    88244: {
      lat: 32.76412,
      lng: -103.22498,
      city: 'Hobbs',
      state: 'NM',
    },
    88250: {
      lat: 32.72882,
      lng: -104.91851,
      city: 'Hope',
      state: 'NM',
    },
    88252: { lat: 32.16345, lng: -103.39452, city: 'Jal', state: 'NM' },
    88253: {
      lat: 32.98767,
      lng: -104.54852,
      city: 'Lake Arthur',
      state: 'NM',
    },
    88254: {
      lat: 32.62876,
      lng: -104.40779,
      city: 'Lakewood',
      state: 'NM',
    },
    88255: {
      lat: 32.81091,
      lng: -103.99923,
      city: 'Loco Hills',
      state: 'NM',
    },
    88256: {
      lat: 32.19543,
      lng: -103.88924,
      city: 'Loving',
      state: 'NM',
    },
    88260: {
      lat: 32.98698,
      lng: -103.39801,
      city: 'Lovington',
      state: 'NM',
    },
    88262: {
      lat: 33.12871,
      lng: -103.40694,
      city: 'Mcdonald',
      state: 'NM',
    },
    88263: {
      lat: 32.09091,
      lng: -104.09006,
      city: 'Malaga',
      state: 'NM',
    },
    88264: {
      lat: 32.86351,
      lng: -103.70089,
      city: 'Maljamar',
      state: 'NM',
    },
    88265: {
      lat: 32.58421,
      lng: -103.34642,
      city: 'Monument',
      state: 'NM',
    },
    88267: {
      lat: 33.30983,
      lng: -103.39329,
      city: 'Tatum',
      state: 'NM',
    },
    88268: {
      lat: 32.17632,
      lng: -104.37939,
      city: 'Whites City',
      state: 'NM',
    },
    88301: {
      lat: 33.7494,
      lng: -105.85191,
      city: 'Carrizozo',
      state: 'NM',
    },
    88310: {
      lat: 32.71809,
      lng: -106.07231,
      city: 'Alamogordo',
      state: 'NM',
    },
    88311: {
      lat: 32.80689,
      lng: -105.98875,
      city: 'Alamogordo',
      state: 'NM',
    },
    88312: {
      lat: 33.43186,
      lng: -105.64397,
      city: 'Alto',
      state: 'NM',
    },
    88314: {
      lat: 33.10061,
      lng: -105.86725,
      city: 'Bent',
      state: 'NM',
    },
    88316: {
      lat: 33.77306,
      lng: -105.36895,
      city: 'Capitan',
      state: 'NM',
    },
    88317: {
      lat: 32.86081,
      lng: -105.67442,
      city: 'Cloudcroft',
      state: 'NM',
    },
    88318: {
      lat: 34.22491,
      lng: -105.44166,
      city: 'Corona',
      state: 'NM',
    },
    88321: {
      lat: 34.76815,
      lng: -105.45564,
      city: 'Encino',
      state: 'NM',
    },
    88323: {
      lat: 33.48534,
      lng: -105.56488,
      city: 'Fort Stanton',
      state: 'NM',
    },
    88324: {
      lat: 33.38666,
      lng: -105.47268,
      city: 'Glencoe',
      state: 'NM',
    },
    88325: {
      lat: 32.8529,
      lng: -105.86027,
      city: 'High Rolls Mountain Park',
      state: 'NM',
    },
    88330: {
      lat: 32.87046,
      lng: -106.09951,
      city: 'Holloman Air Force Base',
      state: 'NM',
    },
    88336: {
      lat: 33.31883,
      lng: -105.28552,
      city: 'Hondo',
      state: 'NM',
    },
    88337: {
      lat: 32.99141,
      lng: -105.89025,
      city: 'La Luz',
      state: 'NM',
    },
    88338: {
      lat: 33.53723,
      lng: -105.38468,
      city: 'Lincoln',
      state: 'NM',
    },
    88339: {
      lat: 32.9561,
      lng: -105.32141,
      city: 'Mayhill',
      state: 'NM',
    },
    88340: {
      lat: 33.17859,
      lng: -105.61211,
      city: 'Mescalero',
      state: 'NM',
    },
    88341: {
      lat: 33.50811,
      lng: -105.79161,
      city: 'Nogal',
      state: 'NM',
    },
    88342: {
      lat: 32.38926,
      lng: -106.10469,
      city: 'Orogrande',
      state: 'NM',
    },
    88343: {
      lat: 33.23504,
      lng: -105.06772,
      city: 'Picacho',
      state: 'NM',
    },
    88344: { lat: 32.5105, lng: -105.2235, city: 'Pinon', state: 'NM' },
    88345: {
      lat: 33.35127,
      lng: -105.67092,
      city: 'Ruidoso',
      state: 'NM',
    },
    88346: {
      lat: 33.34814,
      lng: -105.54073,
      city: 'Ruidoso Downs',
      state: 'NM',
    },
    88347: {
      lat: 32.72757,
      lng: -105.64704,
      city: 'Sacramento',
      state: 'NM',
    },
    88348: {
      lat: 33.41675,
      lng: -105.39858,
      city: 'San Patricio',
      state: 'NM',
    },
    88349: {
      lat: 32.795,
      lng: -105.80313,
      city: 'Sunspot',
      state: 'NM',
    },
    88350: {
      lat: 32.6031,
      lng: -105.67669,
      city: 'Timberon',
      state: 'NM',
    },
    88351: {
      lat: 33.47154,
      lng: -105.09777,
      city: 'Tinnie',
      state: 'NM',
    },
    88352: {
      lat: 33.18745,
      lng: -106.00712,
      city: 'Tularosa',
      state: 'NM',
    },
    88353: {
      lat: 34.49912,
      lng: -105.1034,
      city: 'Vaughn',
      state: 'NM',
    },
    88354: {
      lat: 32.73771,
      lng: -105.49297,
      city: 'Weed',
      state: 'NM',
    },
    88355: {
      lat: 33.36288,
      lng: -105.6694,
      city: 'Ruidoso',
      state: 'NM',
    },
    88401: {
      lat: 35.08984,
      lng: -103.80942,
      city: 'Tucumcari',
      state: 'NM',
    },
    88410: {
      lat: 35.90263,
      lng: -103.30767,
      city: 'Amistad',
      state: 'NM',
    },
    88411: {
      lat: 35.23488,
      lng: -103.16248,
      city: 'Bard',
      state: 'NM',
    },
    88414: {
      lat: 36.77484,
      lng: -104.02073,
      city: 'Capulin',
      state: 'NM',
    },
    88415: {
      lat: 36.35171,
      lng: -103.32643,
      city: 'Clayton',
      state: 'NM',
    },
    88416: {
      lat: 35.44255,
      lng: -104.21511,
      city: 'Conchas Dam',
      state: 'NM',
    },
    88417: {
      lat: 34.93076,
      lng: -104.25309,
      city: 'Cuervo',
      state: 'NM',
    },
    88418: {
      lat: 36.74995,
      lng: -103.63874,
      city: 'Des Moines',
      state: 'NM',
    },
    88419: {
      lat: 36.91839,
      lng: -103.54734,
      city: 'Folsom',
      state: 'NM',
    },
    88421: {
      lat: 35.32691,
      lng: -104.49731,
      city: 'Garita',
      state: 'NM',
    },
    88422: {
      lat: 36.33791,
      lng: -103.98655,
      city: 'Gladstone',
      state: 'NM',
    },
    88424: {
      lat: 36.43714,
      lng: -103.6554,
      city: 'Grenville',
      state: 'NM',
    },
    88426: {
      lat: 35.52366,
      lng: -103.47607,
      city: 'Logan',
      state: 'NM',
    },
    88427: {
      lat: 34.78172,
      lng: -103.76417,
      city: 'Mcalister',
      state: 'NM',
    },
    88430: {
      lat: 35.65348,
      lng: -103.21357,
      city: 'Nara Visa',
      state: 'NM',
    },
    88431: {
      lat: 35.21256,
      lng: -104.33677,
      city: 'Newkirk',
      state: 'NM',
    },
    88434: {
      lat: 35.12367,
      lng: -103.34013,
      city: 'San Jon',
      state: 'NM',
    },
    88435: {
      lat: 34.90262,
      lng: -104.8133,
      city: 'Santa Rosa',
      state: 'NM',
    },
    88436: {
      lat: 36.14965,
      lng: -103.13345,
      city: 'Sedan',
      state: 'NM',
    },
    88439: {
      lat: 35.48908,
      lng: -104.35627,
      city: 'Trementina',
      state: 'NM',
    },
    89001: {
      lat: 37.40035,
      lng: -115.39564,
      city: 'Alamo',
      state: 'NV',
    },
    89002: {
      lat: 35.99846,
      lng: -114.96181,
      city: 'Henderson',
      state: 'NV',
    },
    89003: {
      lat: 36.94925,
      lng: -116.7331,
      city: 'Beatty',
      state: 'NV',
    },
    89004: {
      lat: 36.04164,
      lng: -115.41261,
      city: 'Blue Diamond',
      state: 'NV',
    },
    89005: {
      lat: 36.00875,
      lng: -114.84805,
      city: 'Boulder City',
      state: 'NV',
    },
    89007: {
      lat: 36.68605,
      lng: -114.22915,
      city: 'Bunkerville',
      state: 'NV',
    },
    89008: {
      lat: 37.26441,
      lng: -114.50414,
      city: 'Caliente',
      state: 'NV',
    },
    89010: {
      lat: 37.82511,
      lng: -118.08079,
      city: 'Dyer',
      state: 'NV',
    },
    89011: {
      lat: 36.0848,
      lng: -114.9651,
      city: 'Henderson',
      state: 'NV',
    },
    89012: {
      lat: 36.01192,
      lng: -115.04314,
      city: 'Henderson',
      state: 'NV',
    },
    89013: {
      lat: 37.34574,
      lng: -117.34393,
      city: 'Goldfield',
      state: 'NV',
    },
    89014: {
      lat: 36.06153,
      lng: -115.0577,
      city: 'Henderson',
      state: 'NV',
    },
    89015: {
      lat: 36.03887,
      lng: -114.9282,
      city: 'Henderson',
      state: 'NV',
    },
    89017: {
      lat: 37.70485,
      lng: -115.30344,
      city: 'Hiko',
      state: 'NV',
    },
    89018: {
      lat: 36.57063,
      lng: -115.66802,
      city: 'Indian Springs',
      state: 'NV',
    },
    89019: {
      lat: 35.76949,
      lng: -115.51606,
      city: 'Jean',
      state: 'NV',
    },
    89020: {
      lat: 36.56232,
      lng: -116.42454,
      city: 'Amargosa Valley',
      state: 'NV',
    },
    89021: {
      lat: 36.6255,
      lng: -114.47863,
      city: 'Logandale',
      state: 'NV',
    },
    89022: {
      lat: 38.51839,
      lng: -117.01489,
      city: 'Manhattan',
      state: 'NV',
    },
    89025: {
      lat: 36.71387,
      lng: -114.74777,
      city: 'Moapa',
      state: 'NV',
    },
    89026: {
      lat: 35.74922,
      lng: -115.28475,
      city: 'Jean',
      state: 'NV',
    },
    89027: {
      lat: 36.81374,
      lng: -114.08857,
      city: 'Mesquite',
      state: 'NV',
    },
    89029: {
      lat: 35.12561,
      lng: -114.68607,
      city: 'Laughlin',
      state: 'NV',
    },
    89030: {
      lat: 36.21235,
      lng: -115.12313,
      city: 'North Las Vegas',
      state: 'NV',
    },
    89031: {
      lat: 36.25846,
      lng: -115.17075,
      city: 'North Las Vegas',
      state: 'NV',
    },
    89032: {
      lat: 36.22239,
      lng: -115.17358,
      city: 'North Las Vegas',
      state: 'NV',
    },
    89034: {
      lat: 36.80924,
      lng: -114.14978,
      city: 'Mesquite',
      state: 'NV',
    },
    89039: {
      lat: 35.26581,
      lng: -114.8725,
      city: 'Cal Nev Ari',
      state: 'NV',
    },
    89040: {
      lat: 36.35322,
      lng: -114.53541,
      city: 'Overton',
      state: 'NV',
    },
    89042: {
      lat: 37.79819,
      lng: -114.35862,
      city: 'Panaca',
      state: 'NV',
    },
    89043: {
      lat: 38.30434,
      lng: -114.39018,
      city: 'Pioche',
      state: 'NV',
    },
    89044: {
      lat: 35.93986,
      lng: -115.05632,
      city: 'Henderson',
      state: 'NV',
    },
    89045: {
      lat: 38.85618,
      lng: -117.06987,
      city: 'Round Mountain',
      state: 'NV',
    },
    89046: {
      lat: 35.51207,
      lng: -114.8338,
      city: 'Searchlight',
      state: 'NV',
    },
    89047: {
      lat: 37.75776,
      lng: -117.6386,
      city: 'Silverpeak',
      state: 'NV',
    },
    89048: {
      lat: 36.16452,
      lng: -115.98833,
      city: 'Pahrump',
      state: 'NV',
    },
    89049: {
      lat: 38.37741,
      lng: -116.41205,
      city: 'Tonopah',
      state: 'NV',
    },
    89052: {
      lat: 35.97988,
      lng: -115.10788,
      city: 'Henderson',
      state: 'NV',
    },
    89054: {
      lat: 35.92093,
      lng: -115.19055,
      city: 'Sloan',
      state: 'NV',
    },
    89060: {
      lat: 36.38854,
      lng: -116.05769,
      city: 'Pahrump',
      state: 'NV',
    },
    89061: {
      lat: 36.07143,
      lng: -115.89957,
      city: 'Pahrump',
      state: 'NV',
    },
    89070: {
      lat: 36.50083,
      lng: -115.63952,
      city: 'Indian Springs',
      state: 'NV',
    },
    89074: {
      lat: 36.03639,
      lng: -115.08113,
      city: 'Henderson',
      state: 'NV',
    },
    89081: {
      lat: 36.2586,
      lng: -115.10703,
      city: 'North Las Vegas',
      state: 'NV',
    },
    89084: {
      lat: 36.29749,
      lng: -115.1569,
      city: 'North Las Vegas',
      state: 'NV',
    },
    89085: {
      lat: 36.30955,
      lng: -115.19817,
      city: 'North Las Vegas',
      state: 'NV',
    },
    89086: {
      lat: 36.28361,
      lng: -115.10997,
      city: 'North Las Vegas',
      state: 'NV',
    },
    89101: {
      lat: 36.17279,
      lng: -115.12234,
      city: 'Las Vegas',
      state: 'NV',
    },
    89102: {
      lat: 36.1453,
      lng: -115.18662,
      city: 'Las Vegas',
      state: 'NV',
    },
    89103: {
      lat: 36.11236,
      lng: -115.21215,
      city: 'Las Vegas',
      state: 'NV',
    },
    89104: {
      lat: 36.15118,
      lng: -115.10932,
      city: 'Las Vegas',
      state: 'NV',
    },
    89106: {
      lat: 36.18207,
      lng: -115.16253,
      city: 'Las Vegas',
      state: 'NV',
    },
    89107: {
      lat: 36.17049,
      lng: -115.209,
      city: 'Las Vegas',
      state: 'NV',
    },
    89108: {
      lat: 36.20508,
      lng: -115.2237,
      city: 'Las Vegas',
      state: 'NV',
    },
    89109: {
      lat: 36.12603,
      lng: -115.16327,
      city: 'Las Vegas',
      state: 'NV',
    },
    89110: {
      lat: 36.17367,
      lng: -115.05549,
      city: 'Las Vegas',
      state: 'NV',
    },
    89113: {
      lat: 36.06017,
      lng: -115.26236,
      city: 'Las Vegas',
      state: 'NV',
    },
    89115: {
      lat: 36.25374,
      lng: -115.04285,
      city: 'Las Vegas',
      state: 'NV',
    },
    89117: {
      lat: 36.14267,
      lng: -115.28001,
      city: 'Las Vegas',
      state: 'NV',
    },
    89118: {
      lat: 36.07778,
      lng: -115.21306,
      city: 'Las Vegas',
      state: 'NV',
    },
    89119: {
      lat: 36.08452,
      lng: -115.14606,
      city: 'Las Vegas',
      state: 'NV',
    },
    89120: {
      lat: 36.08065,
      lng: -115.09585,
      city: 'Las Vegas',
      state: 'NV',
    },
    89121: {
      lat: 36.12141,
      lng: -115.09135,
      city: 'Las Vegas',
      state: 'NV',
    },
    89122: {
      lat: 36.10399,
      lng: -115.03783,
      city: 'Las Vegas',
      state: 'NV',
    },
    89123: {
      lat: 36.0352,
      lng: -115.14908,
      city: 'Las Vegas',
      state: 'NV',
    },
    89124: {
      lat: 36.34074,
      lng: -115.60096,
      city: 'Las Vegas',
      state: 'NV',
    },
    89128: {
      lat: 36.19678,
      lng: -115.26439,
      city: 'Las Vegas',
      state: 'NV',
    },
    89129: {
      lat: 36.23325,
      lng: -115.29025,
      city: 'Las Vegas',
      state: 'NV',
    },
    89130: {
      lat: 36.25348,
      lng: -115.22671,
      city: 'Las Vegas',
      state: 'NV',
    },
    89131: {
      lat: 36.3064,
      lng: -115.24256,
      city: 'Las Vegas',
      state: 'NV',
    },
    89134: {
      lat: 36.20262,
      lng: -115.3078,
      city: 'Las Vegas',
      state: 'NV',
    },
    89135: {
      lat: 36.09531,
      lng: -115.37163,
      city: 'Las Vegas',
      state: 'NV',
    },
    89138: {
      lat: 36.20212,
      lng: -115.36499,
      city: 'Las Vegas',
      state: 'NV',
    },
    89139: {
      lat: 36.03429,
      lng: -115.21117,
      city: 'Las Vegas',
      state: 'NV',
    },
    89141: {
      lat: 35.98813,
      lng: -115.2074,
      city: 'Las Vegas',
      state: 'NV',
    },
    89142: {
      lat: 36.14812,
      lng: -115.031,
      city: 'Las Vegas',
      state: 'NV',
    },
    89143: {
      lat: 36.32168,
      lng: -115.29288,
      city: 'Las Vegas',
      state: 'NV',
    },
    89144: {
      lat: 36.17746,
      lng: -115.31943,
      city: 'Las Vegas',
      state: 'NV',
    },
    89145: {
      lat: 36.16748,
      lng: -115.27618,
      city: 'Las Vegas',
      state: 'NV',
    },
    89146: {
      lat: 36.14323,
      lng: -115.22679,
      city: 'Las Vegas',
      state: 'NV',
    },
    89147: {
      lat: 36.11289,
      lng: -115.27985,
      city: 'Las Vegas',
      state: 'NV',
    },
    89148: {
      lat: 36.06337,
      lng: -115.29676,
      city: 'Las Vegas',
      state: 'NV',
    },
    89149: {
      lat: 36.2708,
      lng: -115.29462,
      city: 'Las Vegas',
      state: 'NV',
    },
    89156: {
      lat: 36.15906,
      lng: -114.98176,
      city: 'Las Vegas',
      state: 'NV',
    },
    89158: {
      lat: 36.10481,
      lng: -115.17665,
      city: 'Las Vegas',
      state: 'NV',
    },
    89161: {
      lat: 36.03552,
      lng: -115.48284,
      city: 'Las Vegas',
      state: 'NV',
    },
    89166: {
      lat: 36.33733,
      lng: -115.4686,
      city: 'Las Vegas',
      state: 'NV',
    },
    89169: {
      lat: 36.12405,
      lng: -115.14184,
      city: 'Las Vegas',
      state: 'NV',
    },
    89178: {
      lat: 35.98783,
      lng: -115.29541,
      city: 'Las Vegas',
      state: 'NV',
    },
    89179: {
      lat: 35.8916,
      lng: -115.32794,
      city: 'Las Vegas',
      state: 'NV',
    },
    89183: {
      lat: 35.99619,
      lng: -115.15799,
      city: 'Las Vegas',
      state: 'NV',
    },
    89191: {
      lat: 36.2473,
      lng: -115.00407,
      city: 'Nellis Afb',
      state: 'NV',
    },
    89301: { lat: 39.52872, lng: -114.76615, city: 'Ely', state: 'NV' },
    89310: {
      lat: 39.47836,
      lng: -117.13758,
      city: 'Austin',
      state: 'NV',
    },
    89311: {
      lat: 39.16715,
      lng: -114.19288,
      city: 'Baker',
      state: 'NV',
    },
    89314: {
      lat: 38.86222,
      lng: -115.64186,
      city: 'Duckwater',
      state: 'NV',
    },
    89315: { lat: 39.1855, lng: -114.84489, city: 'Ely', state: 'NV' },
    89316: {
      lat: 39.67956,
      lng: -116.01497,
      city: 'Eureka',
      state: 'NV',
    },
    89317: {
      lat: 38.87915,
      lng: -115.08418,
      city: 'Lund',
      state: 'NV',
    },
    89318: {
      lat: 39.43783,
      lng: -114.8188,
      city: 'McGill',
      state: 'NV',
    },
    89319: {
      lat: 39.27915,
      lng: -114.98724,
      city: 'Ruth',
      state: 'NV',
    },
    89402: {
      lat: 39.23075,
      lng: -120.00259,
      city: 'Crystal Bay',
      state: 'NV',
    },
    89403: {
      lat: 39.25838,
      lng: -119.48633,
      city: 'Dayton',
      state: 'NV',
    },
    89404: {
      lat: 41.80704,
      lng: -118.88807,
      city: 'Denio',
      state: 'NV',
    },
    89405: {
      lat: 40.45057,
      lng: -119.46926,
      city: 'Empire',
      state: 'NV',
    },
    89406: {
      lat: 39.46614,
      lng: -118.27364,
      city: 'Fallon',
      state: 'NV',
    },
    89408: {
      lat: 39.71675,
      lng: -119.02706,
      city: 'Fernley',
      state: 'NV',
    },
    89409: {
      lat: 38.83828,
      lng: -117.98088,
      city: 'Gabbs',
      state: 'NV',
    },
    89410: {
      lat: 38.78368,
      lng: -119.54802,
      city: 'Gardnerville',
      state: 'NV',
    },
    89411: {
      lat: 39.01837,
      lng: -119.85301,
      city: 'Genoa',
      state: 'NV',
    },
    89412: {
      lat: 41.13904,
      lng: -119.69501,
      city: 'Gerlach',
      state: 'NV',
    },
    89413: {
      lat: 39.07389,
      lng: -119.92095,
      city: 'Glenbrook',
      state: 'NV',
    },
    89414: {
      lat: 41.04934,
      lng: -117.22765,
      city: 'Golconda',
      state: 'NV',
    },
    89415: {
      lat: 38.50993,
      lng: -118.77958,
      city: 'Hawthorne',
      state: 'NV',
    },
    89418: {
      lat: 40.5669,
      lng: -118.03851,
      city: 'Imlay',
      state: 'NV',
    },
    89419: {
      lat: 40.34309,
      lng: -118.29831,
      city: 'Lovelock',
      state: 'NV',
    },
    89420: {
      lat: 38.50802,
      lng: -118.18051,
      city: 'Luning',
      state: 'NV',
    },
    89421: {
      lat: 42.04485,
      lng: -117.94724,
      city: 'McDermitt',
      state: 'NV',
    },
    89422: {
      lat: 38.42008,
      lng: -118.17911,
      city: 'Mina',
      state: 'NV',
    },
    89423: {
      lat: 39.01561,
      lng: -119.74636,
      city: 'Minden',
      state: 'NV',
    },
    89424: {
      lat: 40.10964,
      lng: -119.49396,
      city: 'Nixon',
      state: 'NV',
    },
    89425: {
      lat: 41.65695,
      lng: -117.88461,
      city: 'Orovada',
      state: 'NV',
    },
    89426: {
      lat: 41.45402,
      lng: -117.59307,
      city: 'Paradise Valley',
      state: 'NV',
    },
    89427: {
      lat: 38.94556,
      lng: -118.70553,
      city: 'Schurz',
      state: 'NV',
    },
    89428: {
      lat: 39.25691,
      lng: -119.63099,
      city: 'Silver City',
      state: 'NV',
    },
    89429: {
      lat: 39.36221,
      lng: -119.29063,
      city: 'Silver Springs',
      state: 'NV',
    },
    89430: {
      lat: 38.79567,
      lng: -119.31785,
      city: 'Smith',
      state: 'NV',
    },
    89431: {
      lat: 39.53865,
      lng: -119.75005,
      city: 'Sparks',
      state: 'NV',
    },
    89433: {
      lat: 39.59758,
      lng: -119.77817,
      city: 'Sun Valley',
      state: 'NV',
    },
    89434: {
      lat: 39.54019,
      lng: -119.53318,
      city: 'Sparks',
      state: 'NV',
    },
    89436: {
      lat: 39.61086,
      lng: -119.69854,
      city: 'Sparks',
      state: 'NV',
    },
    89437: {
      lat: 39.50101,
      lng: -119.46864,
      city: 'Sparks',
      state: 'NV',
    },
    89438: {
      lat: 40.79671,
      lng: -117.12004,
      city: 'Valmy',
      state: 'NV',
    },
    89439: {
      lat: 39.52291,
      lng: -119.99008,
      city: 'Verdi',
      state: 'NV',
    },
    89440: {
      lat: 39.29597,
      lng: -119.66212,
      city: 'Virginia City',
      state: 'NV',
    },
    89441: {
      lat: 39.68131,
      lng: -119.68755,
      city: 'Sparks',
      state: 'NV',
    },
    89442: {
      lat: 39.69511,
      lng: -119.35082,
      city: 'Wadsworth',
      state: 'NV',
    },
    89444: {
      lat: 38.72695,
      lng: -119.35101,
      city: 'Wellington',
      state: 'NV',
    },
    89445: {
      lat: 41.06767,
      lng: -118.02515,
      city: 'Winnemucca',
      state: 'NV',
    },
    89446: {
      lat: 40.87879,
      lng: -117.9532,
      city: 'Winnemucca',
      state: 'NV',
    },
    89447: {
      lat: 38.83148,
      lng: -119.11712,
      city: 'Yerington',
      state: 'NV',
    },
    89448: {
      lat: 39.00789,
      lng: -119.92412,
      city: 'Zephyr Cove',
      state: 'NV',
    },
    89449: {
      lat: 38.96433,
      lng: -119.90688,
      city: 'Stateline',
      state: 'NV',
    },
    89450: {
      lat: 39.24135,
      lng: -119.93832,
      city: 'Incline Village',
      state: 'NV',
    },
    89451: {
      lat: 39.26775,
      lng: -119.94534,
      city: 'Incline Village',
      state: 'NV',
    },
    89460: {
      lat: 38.90136,
      lng: -119.79085,
      city: 'Gardnerville',
      state: 'NV',
    },
    89496: {
      lat: 39.19949,
      lng: -118.57103,
      city: 'Fallon',
      state: 'NV',
    },
    89501: {
      lat: 39.52602,
      lng: -119.81262,
      city: 'Reno',
      state: 'NV',
    },
    89502: {
      lat: 39.49383,
      lng: -119.74701,
      city: 'Reno',
      state: 'NV',
    },
    89503: {
      lat: 39.54112,
      lng: -119.83991,
      city: 'Reno',
      state: 'NV',
    },
    89506: {
      lat: 39.70084,
      lng: -119.82646,
      city: 'Reno',
      state: 'NV',
    },
    89508: {
      lat: 39.78504,
      lng: -119.92728,
      city: 'Reno',
      state: 'NV',
    },
    89509: { lat: 39.49637, lng: -119.8273, city: 'Reno', state: 'NV' },
    89510: {
      lat: 39.93619,
      lng: -119.73734,
      city: 'Reno',
      state: 'NV',
    },
    89511: {
      lat: 39.40747,
      lng: -119.88822,
      city: 'Reno',
      state: 'NV',
    },
    89512: {
      lat: 39.55322,
      lng: -119.80074,
      city: 'Reno',
      state: 'NV',
    },
    89519: {
      lat: 39.48518,
      lng: -119.86003,
      city: 'Reno',
      state: 'NV',
    },
    89521: {
      lat: 39.37215,
      lng: -119.67611,
      city: 'Reno',
      state: 'NV',
    },
    89523: {
      lat: 39.52387,
      lng: -119.91309,
      city: 'Reno',
      state: 'NV',
    },
    89557: {
      lat: 39.54247,
      lng: -119.81584,
      city: 'Reno',
      state: 'NV',
    },
    89701: {
      lat: 39.13944,
      lng: -119.72087,
      city: 'Carson City',
      state: 'NV',
    },
    89703: {
      lat: 39.15359,
      lng: -119.8486,
      city: 'Carson City',
      state: 'NV',
    },
    89704: {
      lat: 39.26789,
      lng: -119.81712,
      city: 'Washoe Valley',
      state: 'NV',
    },
    89705: {
      lat: 39.09003,
      lng: -119.83689,
      city: 'Carson City',
      state: 'NV',
    },
    89706: {
      lat: 39.22002,
      lng: -119.69974,
      city: 'Carson City',
      state: 'NV',
    },
    89801: {
      lat: 41.18096,
      lng: -115.76076,
      city: 'Elko',
      state: 'NV',
    },
    89815: {
      lat: 40.56652,
      lng: -115.59504,
      city: 'Spring Creek',
      state: 'NV',
    },
    89820: {
      lat: 40.43192,
      lng: -116.97134,
      city: 'Battle Mountain',
      state: 'NV',
    },
    89821: {
      lat: 40.55585,
      lng: -116.41218,
      city: 'Crescent Valley',
      state: 'NV',
    },
    89822: {
      lat: 40.49237,
      lng: -116.189,
      city: 'Carlin',
      state: 'NV',
    },
    89823: {
      lat: 40.96431,
      lng: -115.22821,
      city: 'Deeth',
      state: 'NV',
    },
    89825: {
      lat: 41.90422,
      lng: -114.71103,
      city: 'Jackpot',
      state: 'NV',
    },
    89826: {
      lat: 41.93262,
      lng: -115.41386,
      city: 'Jarbidge',
      state: 'NV',
    },
    89828: {
      lat: 40.77384,
      lng: -115.36403,
      city: 'Lamoille',
      state: 'NV',
    },
    89830: {
      lat: 41.32794,
      lng: -114.22677,
      city: 'Montello',
      state: 'NV',
    },
    89831: {
      lat: 41.75992,
      lng: -116.03935,
      city: 'Mountain City',
      state: 'NV',
    },
    89832: {
      lat: 41.98001,
      lng: -116.19537,
      city: 'Owyhee',
      state: 'NV',
    },
    89833: {
      lat: 40.4692,
      lng: -115.34201,
      city: 'Ruby Valley',
      state: 'NV',
    },
    89834: {
      lat: 41.42358,
      lng: -116.24843,
      city: 'Tuscarora',
      state: 'NV',
    },
    89835: {
      lat: 41.2848,
      lng: -114.87765,
      city: 'Wells',
      state: 'NV',
    },
    89883: {
      lat: 40.84711,
      lng: -114.18217,
      city: 'West Wendover',
      state: 'NV',
    },
    90001: {
      lat: 33.97365,
      lng: -118.24904,
      city: 'Los Angeles',
      state: 'CA',
    },
    90002: {
      lat: 33.94906,
      lng: -118.24679,
      city: 'Los Angeles',
      state: 'CA',
    },
    90003: {
      lat: 33.96409,
      lng: -118.27368,
      city: 'Los Angeles',
      state: 'CA',
    },
    90004: {
      lat: 34.07624,
      lng: -118.31101,
      city: 'Los Angeles',
      state: 'CA',
    },
    90005: {
      lat: 34.05939,
      lng: -118.30909,
      city: 'Los Angeles',
      state: 'CA',
    },
    90006: {
      lat: 34.04807,
      lng: -118.29411,
      city: 'Los Angeles',
      state: 'CA',
    },
    90007: {
      lat: 34.02819,
      lng: -118.28486,
      city: 'Los Angeles',
      state: 'CA',
    },
    90008: {
      lat: 34.01037,
      lng: -118.3428,
      city: 'Los Angeles',
      state: 'CA',
    },
    90010: {
      lat: 34.06209,
      lng: -118.31262,
      city: 'Los Angeles',
      state: 'CA',
    },
    90011: {
      lat: 34.00689,
      lng: -118.25861,
      city: 'Los Angeles',
      state: 'CA',
    },
    90012: {
      lat: 34.06679,
      lng: -118.23878,
      city: 'Los Angeles',
      state: 'CA',
    },
    90013: {
      lat: 34.04435,
      lng: -118.24,
      city: 'Los Angeles',
      state: 'CA',
    },
    90014: {
      lat: 34.04302,
      lng: -118.25182,
      city: 'Los Angeles',
      state: 'CA',
    },
    90015: {
      lat: 34.03932,
      lng: -118.26625,
      city: 'Los Angeles',
      state: 'CA',
    },
    90016: {
      lat: 34.02881,
      lng: -118.35436,
      city: 'Los Angeles',
      state: 'CA',
    },
    90017: {
      lat: 34.05306,
      lng: -118.26447,
      city: 'Los Angeles',
      state: 'CA',
    },
    90018: {
      lat: 34.0286,
      lng: -118.31737,
      city: 'Los Angeles',
      state: 'CA',
    },
    90019: {
      lat: 34.04861,
      lng: -118.33874,
      city: 'Los Angeles',
      state: 'CA',
    },
    90020: {
      lat: 34.06638,
      lng: -118.30986,
      city: 'Los Angeles',
      state: 'CA',
    },
    90021: {
      lat: 34.02909,
      lng: -118.23889,
      city: 'Los Angeles',
      state: 'CA',
    },
    90022: {
      lat: 34.02455,
      lng: -118.15608,
      city: 'Los Angeles',
      state: 'CA',
    },
    90023: {
      lat: 34.02264,
      lng: -118.19986,
      city: 'Los Angeles',
      state: 'CA',
    },
    90024: {
      lat: 34.06565,
      lng: -118.43521,
      city: 'Los Angeles',
      state: 'CA',
    },
    90025: {
      lat: 34.04551,
      lng: -118.44583,
      city: 'Los Angeles',
      state: 'CA',
    },
    90026: {
      lat: 34.07883,
      lng: -118.2637,
      city: 'Los Angeles',
      state: 'CA',
    },
    90027: {
      lat: 34.12515,
      lng: -118.29049,
      city: 'Los Angeles',
      state: 'CA',
    },
    90028: {
      lat: 34.09982,
      lng: -118.3268,
      city: 'Los Angeles',
      state: 'CA',
    },
    90029: {
      lat: 34.08983,
      lng: -118.29467,
      city: 'Los Angeles',
      state: 'CA',
    },
    90031: {
      lat: 34.08122,
      lng: -118.21081,
      city: 'Los Angeles',
      state: 'CA',
    },
    90032: {
      lat: 34.0793,
      lng: -118.17835,
      city: 'Los Angeles',
      state: 'CA',
    },
    90033: {
      lat: 34.05117,
      lng: -118.21165,
      city: 'Los Angeles',
      state: 'CA',
    },
    90034: {
      lat: 34.03059,
      lng: -118.39936,
      city: 'Los Angeles',
      state: 'CA',
    },
    90035: {
      lat: 34.05139,
      lng: -118.38577,
      city: 'Los Angeles',
      state: 'CA',
    },
    90036: {
      lat: 34.07013,
      lng: -118.34978,
      city: 'Los Angeles',
      state: 'CA',
    },
    90037: {
      lat: 34.00271,
      lng: -118.28747,
      city: 'Los Angeles',
      state: 'CA',
    },
    90038: {
      lat: 34.08882,
      lng: -118.32671,
      city: 'Los Angeles',
      state: 'CA',
    },
    90039: {
      lat: 34.11242,
      lng: -118.26153,
      city: 'Los Angeles',
      state: 'CA',
    },
    90040: {
      lat: 33.99318,
      lng: -118.1489,
      city: 'Los Angeles',
      state: 'CA',
    },
    90041: {
      lat: 34.13749,
      lng: -118.20746,
      city: 'Los Angeles',
      state: 'CA',
    },
    90042: {
      lat: 34.11475,
      lng: -118.192,
      city: 'Los Angeles',
      state: 'CA',
    },
    90043: {
      lat: 33.98876,
      lng: -118.33519,
      city: 'Los Angeles',
      state: 'CA',
    },
    90044: {
      lat: 33.95253,
      lng: -118.29194,
      city: 'Los Angeles',
      state: 'CA',
    },
    90045: {
      lat: 33.95302,
      lng: -118.40028,
      city: 'Los Angeles',
      state: 'CA',
    },
    90046: {
      lat: 34.10752,
      lng: -118.36575,
      city: 'Los Angeles',
      state: 'CA',
    },
    90047: {
      lat: 33.95456,
      lng: -118.30899,
      city: 'Los Angeles',
      state: 'CA',
    },
    90048: {
      lat: 34.07293,
      lng: -118.3727,
      city: 'Los Angeles',
      state: 'CA',
    },
    90049: {
      lat: 34.08749,
      lng: -118.48976,
      city: 'Los Angeles',
      state: 'CA',
    },
    90052: {
      lat: 33.9783,
      lng: -118.25891,
      city: 'Los Angeles',
      state: 'CA',
    },
    90056: {
      lat: 33.99361,
      lng: -118.36884,
      city: 'Los Angeles',
      state: 'CA',
    },
    90057: {
      lat: 34.06205,
      lng: -118.27704,
      city: 'Los Angeles',
      state: 'CA',
    },
    90058: {
      lat: 34.00264,
      lng: -118.21452,
      city: 'Los Angeles',
      state: 'CA',
    },
    90059: {
      lat: 33.92709,
      lng: -118.2491,
      city: 'Los Angeles',
      state: 'CA',
    },
    90061: {
      lat: 33.92037,
      lng: -118.27383,
      city: 'Los Angeles',
      state: 'CA',
    },
    90062: {
      lat: 34.0035,
      lng: -118.30904,
      city: 'Los Angeles',
      state: 'CA',
    },
    90063: {
      lat: 34.0452,
      lng: -118.18583,
      city: 'Los Angeles',
      state: 'CA',
    },
    90064: {
      lat: 34.0358,
      lng: -118.42653,
      city: 'Los Angeles',
      state: 'CA',
    },
    90065: {
      lat: 34.10867,
      lng: -118.22813,
      city: 'Los Angeles',
      state: 'CA',
    },
    90066: {
      lat: 34.00166,
      lng: -118.43104,
      city: 'Los Angeles',
      state: 'CA',
    },
    90067: {
      lat: 34.0565,
      lng: -118.41373,
      city: 'Los Angeles',
      state: 'CA',
    },
    90068: {
      lat: 34.12794,
      lng: -118.32914,
      city: 'Los Angeles',
      state: 'CA',
    },
    90069: {
      lat: 34.09401,
      lng: -118.38154,
      city: 'West Hollywood',
      state: 'CA',
    },
    90071: {
      lat: 34.05239,
      lng: -118.25518,
      city: 'Los Angeles',
      state: 'CA',
    },
    90073: {
      lat: 34.05738,
      lng: -118.45956,
      city: 'Los Angeles',
      state: 'CA',
    },
    90077: {
      lat: 34.10536,
      lng: -118.45591,
      city: 'Los Angeles',
      state: 'CA',
    },
    90079: {
      lat: 34.04058,
      lng: -118.25541,
      city: 'Los Angeles',
      state: 'CA',
    },
    90089: {
      lat: 34.02158,
      lng: -118.2856,
      city: 'Los Angeles',
      state: 'CA',
    },
    90094: {
      lat: 33.97523,
      lng: -118.41837,
      city: 'Playa Vista',
      state: 'CA',
    },
    90095: {
      lat: 34.07121,
      lng: -118.44334,
      city: 'Los Angeles',
      state: 'CA',
    },
    90201: {
      lat: 33.97113,
      lng: -118.1709,
      city: 'Bell Gardens',
      state: 'CA',
    },
    90210: {
      lat: 34.10251,
      lng: -118.41505,
      city: 'Beverly Hills',
      state: 'CA',
    },
    90211: {
      lat: 34.06498,
      lng: -118.38293,
      city: 'Beverly Hills',
      state: 'CA',
    },
    90212: {
      lat: 34.06208,
      lng: -118.40173,
      city: 'Beverly Hills',
      state: 'CA',
    },
    90220: {
      lat: 33.88058,
      lng: -118.23591,
      city: 'Compton',
      state: 'CA',
    },
    90221: {
      lat: 33.88542,
      lng: -118.20614,
      city: 'Compton',
      state: 'CA',
    },
    90222: {
      lat: 33.91213,
      lng: -118.23721,
      city: 'Compton',
      state: 'CA',
    },
    90230: {
      lat: 33.99691,
      lng: -118.39487,
      city: 'Culver City',
      state: 'CA',
    },
    90232: {
      lat: 34.01859,
      lng: -118.39157,
      city: 'Culver City',
      state: 'CA',
    },
    90240: {
      lat: 33.9564,
      lng: -118.11803,
      city: 'Downey',
      state: 'CA',
    },
    90241: {
      lat: 33.94076,
      lng: -118.1295,
      city: 'Downey',
      state: 'CA',
    },
    90242: {
      lat: 33.92216,
      lng: -118.14167,
      city: 'Downey',
      state: 'CA',
    },
    90245: {
      lat: 33.91709,
      lng: -118.40161,
      city: 'El Segundo',
      state: 'CA',
    },
    90247: {
      lat: 33.89159,
      lng: -118.29735,
      city: 'Gardena',
      state: 'CA',
    },
    90248: {
      lat: 33.87689,
      lng: -118.28362,
      city: 'Gardena',
      state: 'CA',
    },
    90249: {
      lat: 33.90152,
      lng: -118.31707,
      city: 'Gardena',
      state: 'CA',
    },
    90250: {
      lat: 33.91383,
      lng: -118.34996,
      city: 'Hawthorne',
      state: 'CA',
    },
    90254: {
      lat: 33.86532,
      lng: -118.39616,
      city: 'Hermosa Beach',
      state: 'CA',
    },
    90255: {
      lat: 33.97697,
      lng: -118.21725,
      city: 'Huntington Park',
      state: 'CA',
    },
    90260: {
      lat: 33.88768,
      lng: -118.34952,
      city: 'Lawndale',
      state: 'CA',
    },
    90262: {
      lat: 33.92384,
      lng: -118.20133,
      city: 'Lynwood',
      state: 'CA',
    },
    90263: {
      lat: 34.03919,
      lng: -118.70809,
      city: 'Malibu',
      state: 'CA',
    },
    90265: {
      lat: 34.07282,
      lng: -118.84995,
      city: 'Malibu',
      state: 'CA',
    },
    90266: {
      lat: 33.88968,
      lng: -118.39705,
      city: 'Manhattan Beach',
      state: 'CA',
    },
    90270: {
      lat: 33.98856,
      lng: -118.18774,
      city: 'Maywood',
      state: 'CA',
    },
    90272: {
      lat: 34.08066,
      lng: -118.54459,
      city: 'Pacific Palisades',
      state: 'CA',
    },
    90274: {
      lat: 33.77732,
      lng: -118.3684,
      city: 'Palos Verdes Peninsula',
      state: 'CA',
    },
    90275: {
      lat: 33.7555,
      lng: -118.36338,
      city: 'Rancho Palos Verdes',
      state: 'CA',
    },
    90277: {
      lat: 33.83099,
      lng: -118.38441,
      city: 'Redondo Beach',
      state: 'CA',
    },
    90278: {
      lat: 33.87362,
      lng: -118.37028,
      city: 'Redondo Beach',
      state: 'CA',
    },
    90280: {
      lat: 33.94453,
      lng: -118.19256,
      city: 'South Gate',
      state: 'CA',
    },
    90290: {
      lat: 34.09725,
      lng: -118.6125,
      city: 'Topanga',
      state: 'CA',
    },
    90291: {
      lat: 33.99447,
      lng: -118.46315,
      city: 'Venice',
      state: 'CA',
    },
    90292: {
      lat: 33.97865,
      lng: -118.44707,
      city: 'Marina Del Rey',
      state: 'CA',
    },
    90293: {
      lat: 33.95071,
      lng: -118.43745,
      city: 'Playa Del Rey',
      state: 'CA',
    },
    90301: {
      lat: 33.95647,
      lng: -118.35863,
      city: 'Inglewood',
      state: 'CA',
    },
    90302: {
      lat: 33.97472,
      lng: -118.35565,
      city: 'Inglewood',
      state: 'CA',
    },
    90303: {
      lat: 33.93601,
      lng: -118.33112,
      city: 'Inglewood',
      state: 'CA',
    },
    90304: {
      lat: 33.93736,
      lng: -118.35879,
      city: 'Inglewood',
      state: 'CA',
    },
    90305: {
      lat: 33.9583,
      lng: -118.33113,
      city: 'Inglewood',
      state: 'CA',
    },
    90401: {
      lat: 34.01584,
      lng: -118.49251,
      city: 'Santa Monica',
      state: 'CA',
    },
    90402: {
      lat: 34.03598,
      lng: -118.50391,
      city: 'Santa Monica',
      state: 'CA',
    },
    90403: {
      lat: 34.03164,
      lng: -118.48975,
      city: 'Santa Monica',
      state: 'CA',
    },
    90404: {
      lat: 34.02665,
      lng: -118.47381,
      city: 'Santa Monica',
      state: 'CA',
    },
    90405: {
      lat: 34.01208,
      lng: -118.4679,
      city: 'Santa Monica',
      state: 'CA',
    },
    90501: {
      lat: 33.8336,
      lng: -118.31419,
      city: 'Torrance',
      state: 'CA',
    },
    90502: {
      lat: 33.83497,
      lng: -118.29291,
      city: 'Torrance',
      state: 'CA',
    },
    90503: {
      lat: 33.84089,
      lng: -118.3538,
      city: 'Torrance',
      state: 'CA',
    },
    90504: {
      lat: 33.86693,
      lng: -118.33135,
      city: 'Torrance',
      state: 'CA',
    },
    90505: {
      lat: 33.80904,
      lng: -118.34775,
      city: 'Torrance',
      state: 'CA',
    },
    90506: {
      lat: 33.88493,
      lng: -118.33044,
      city: 'Torrance',
      state: 'CA',
    },
    90601: {
      lat: 34.0078,
      lng: -118.03287,
      city: 'Whittier',
      state: 'CA',
    },
    90602: {
      lat: 33.97317,
      lng: -118.02341,
      city: 'Whittier',
      state: 'CA',
    },
    90603: {
      lat: 33.94487,
      lng: -117.99239,
      city: 'Whittier',
      state: 'CA',
    },
    90604: {
      lat: 33.93012,
      lng: -118.01226,
      city: 'Whittier',
      state: 'CA',
    },
    90605: {
      lat: 33.95209,
      lng: -118.02085,
      city: 'Whittier',
      state: 'CA',
    },
    90606: {
      lat: 33.97742,
      lng: -118.0661,
      city: 'Whittier',
      state: 'CA',
    },
    90620: {
      lat: 33.84616,
      lng: -118.01176,
      city: 'Buena Park',
      state: 'CA',
    },
    90621: {
      lat: 33.87396,
      lng: -117.99346,
      city: 'Buena Park',
      state: 'CA',
    },
    90623: {
      lat: 33.85038,
      lng: -118.04088,
      city: 'La Palma',
      state: 'CA',
    },
    90630: {
      lat: 33.81814,
      lng: -118.03814,
      city: 'Cypress',
      state: 'CA',
    },
    90631: {
      lat: 33.94266,
      lng: -117.95126,
      city: 'La Habra',
      state: 'CA',
    },
    90638: {
      lat: 33.90236,
      lng: -118.00907,
      city: 'La Mirada',
      state: 'CA',
    },
    90639: {
      lat: 33.90565,
      lng: -118.01499,
      city: 'La Mirada',
      state: 'CA',
    },
    90640: {
      lat: 34.01506,
      lng: -118.111,
      city: 'Montebello',
      state: 'CA',
    },
    90650: {
      lat: 33.90703,
      lng: -118.08249,
      city: 'Norwalk',
      state: 'CA',
    },
    90660: {
      lat: 33.98868,
      lng: -118.09111,
      city: 'Pico Rivera',
      state: 'CA',
    },
    90670: {
      lat: 33.93279,
      lng: -118.0626,
      city: 'Santa Fe Springs',
      state: 'CA',
    },
    90680: {
      lat: 33.80115,
      lng: -117.99532,
      city: 'Stanton',
      state: 'CA',
    },
    90701: {
      lat: 33.8676,
      lng: -118.08055,
      city: 'Artesia',
      state: 'CA',
    },
    90703: {
      lat: 33.86797,
      lng: -118.06874,
      city: 'Cerritos',
      state: 'CA',
    },
    90704: {
      lat: 33.3765,
      lng: -118.43678,
      city: 'Avalon',
      state: 'CA',
    },
    90706: {
      lat: 33.88802,
      lng: -118.12709,
      city: 'Bellflower',
      state: 'CA',
    },
    90710: {
      lat: 33.79806,
      lng: -118.29918,
      city: 'Harbor City',
      state: 'CA',
    },
    90712: {
      lat: 33.849,
      lng: -118.14677,
      city: 'Lakewood',
      state: 'CA',
    },
    90713: {
      lat: 33.84797,
      lng: -118.11268,
      city: 'Lakewood',
      state: 'CA',
    },
    90715: {
      lat: 33.84037,
      lng: -118.07887,
      city: 'Lakewood',
      state: 'CA',
    },
    90716: {
      lat: 33.8305,
      lng: -118.07295,
      city: 'Hawaiian Gardens',
      state: 'CA',
    },
    90717: {
      lat: 33.79345,
      lng: -118.31706,
      city: 'Lomita',
      state: 'CA',
    },
    90720: {
      lat: 33.79573,
      lng: -118.06342,
      city: 'Los Alamitos',
      state: 'CA',
    },
    90723: {
      lat: 33.89768,
      lng: -118.16508,
      city: 'Paramount',
      state: 'CA',
    },
    90731: {
      lat: 33.7334,
      lng: -118.27398,
      city: 'San Pedro',
      state: 'CA',
    },
    90732: {
      lat: 33.74319,
      lng: -118.31048,
      city: 'San Pedro',
      state: 'CA',
    },
    90740: {
      lat: 33.75461,
      lng: -118.07129,
      city: 'Seal Beach',
      state: 'CA',
    },
    90742: {
      lat: 33.71834,
      lng: -118.07174,
      city: 'Sunset Beach',
      state: 'CA',
    },
    90743: {
      lat: 33.72963,
      lng: -118.08594,
      city: 'Surfside',
      state: 'CA',
    },
    90744: {
      lat: 33.77821,
      lng: -118.26087,
      city: 'Wilmington',
      state: 'CA',
    },
    90745: {
      lat: 33.82117,
      lng: -118.26458,
      city: 'Carson',
      state: 'CA',
    },
    90746: {
      lat: 33.85855,
      lng: -118.25518,
      city: 'Carson',
      state: 'CA',
    },
    90747: {
      lat: 33.86372,
      lng: -118.25142,
      city: 'Carson',
      state: 'CA',
    },
    90755: {
      lat: 33.80274,
      lng: -118.16747,
      city: 'Signal Hill',
      state: 'CA',
    },
    90802: {
      lat: 33.75035,
      lng: -118.21143,
      city: 'Long Beach',
      state: 'CA',
    },
    90803: {
      lat: 33.76162,
      lng: -118.12178,
      city: 'Long Beach',
      state: 'CA',
    },
    90804: {
      lat: 33.78223,
      lng: -118.14917,
      city: 'Long Beach',
      state: 'CA',
    },
    90805: {
      lat: 33.86494,
      lng: -118.18051,
      city: 'Long Beach',
      state: 'CA',
    },
    90806: {
      lat: 33.80819,
      lng: -118.17797,
      city: 'Long Beach',
      state: 'CA',
    },
    90807: {
      lat: 33.83098,
      lng: -118.18113,
      city: 'Long Beach',
      state: 'CA',
    },
    90808: {
      lat: 33.82406,
      lng: -118.11236,
      city: 'Long Beach',
      state: 'CA',
    },
    90810: {
      lat: 33.81912,
      lng: -118.22096,
      city: 'Long Beach',
      state: 'CA',
    },
    90813: {
      lat: 33.78237,
      lng: -118.19656,
      city: 'Long Beach',
      state: 'CA',
    },
    90814: {
      lat: 33.7716,
      lng: -118.14351,
      city: 'Long Beach',
      state: 'CA',
    },
    90815: {
      lat: 33.79572,
      lng: -118.11592,
      city: 'Long Beach',
      state: 'CA',
    },
    90822: {
      lat: 33.77825,
      lng: -118.11871,
      city: 'Long Beach',
      state: 'CA',
    },
    90831: {
      lat: 33.76824,
      lng: -118.20055,
      city: 'Long Beach',
      state: 'CA',
    },
    90840: {
      lat: 33.78449,
      lng: -118.12133,
      city: 'Long Beach',
      state: 'CA',
    },
    91001: {
      lat: 34.19522,
      lng: -118.13866,
      city: 'Altadena',
      state: 'CA',
    },
    91006: {
      lat: 34.1352,
      lng: -118.02667,
      city: 'Arcadia',
      state: 'CA',
    },
    91007: {
      lat: 34.12842,
      lng: -118.04818,
      city: 'Arcadia',
      state: 'CA',
    },
    91008: {
      lat: 34.15363,
      lng: -117.96862,
      city: 'Duarte',
      state: 'CA',
    },
    91010: {
      lat: 34.14114,
      lng: -117.95918,
      city: 'Duarte',
      state: 'CA',
    },
    91011: {
      lat: 34.21757,
      lng: -118.20346,
      city: 'La Canada Flintridge',
      state: 'CA',
    },
    91016: {
      lat: 34.15246,
      lng: -118.00065,
      city: 'Monrovia',
      state: 'CA',
    },
    91020: {
      lat: 34.21144,
      lng: -118.23065,
      city: 'Montrose',
      state: 'CA',
    },
    91024: {
      lat: 34.17177,
      lng: -118.04654,
      city: 'Sierra Madre',
      state: 'CA',
    },
    91030: {
      lat: 34.11029,
      lng: -118.15748,
      city: 'South Pasadena',
      state: 'CA',
    },
    91040: {
      lat: 34.25861,
      lng: -118.33468,
      city: 'Sunland',
      state: 'CA',
    },
    91042: {
      lat: 34.30687,
      lng: -118.25701,
      city: 'Tujunga',
      state: 'CA',
    },
    91046: {
      lat: 34.2117,
      lng: -118.24092,
      city: 'Verdugo City',
      state: 'CA',
    },
    91101: {
      lat: 34.14671,
      lng: -118.13895,
      city: 'Pasadena',
      state: 'CA',
    },
    91103: {
      lat: 34.16738,
      lng: -118.16446,
      city: 'Pasadena',
      state: 'CA',
    },
    91104: {
      lat: 34.16766,
      lng: -118.12341,
      city: 'Pasadena',
      state: 'CA',
    },
    91105: {
      lat: 34.14045,
      lng: -118.16716,
      city: 'Pasadena',
      state: 'CA',
    },
    91106: {
      lat: 34.13861,
      lng: -118.12825,
      city: 'Pasadena',
      state: 'CA',
    },
    91107: {
      lat: 34.15919,
      lng: -118.08688,
      city: 'Pasadena',
      state: 'CA',
    },
    91108: {
      lat: 34.12232,
      lng: -118.11363,
      city: 'San Marino',
      state: 'CA',
    },
    91125: {
      lat: 34.13772,
      lng: -118.12492,
      city: 'Pasadena',
      state: 'CA',
    },
    91201: {
      lat: 34.17041,
      lng: -118.28902,
      city: 'Glendale',
      state: 'CA',
    },
    91202: {
      lat: 34.18221,
      lng: -118.26761,
      city: 'Glendale',
      state: 'CA',
    },
    91203: {
      lat: 34.15287,
      lng: -118.26355,
      city: 'Glendale',
      state: 'CA',
    },
    91204: {
      lat: 34.13554,
      lng: -118.26093,
      city: 'Glendale',
      state: 'CA',
    },
    91205: {
      lat: 34.13679,
      lng: -118.24339,
      city: 'Glendale',
      state: 'CA',
    },
    91206: {
      lat: 34.16076,
      lng: -118.21311,
      city: 'Glendale',
      state: 'CA',
    },
    91207: {
      lat: 34.16872,
      lng: -118.24477,
      city: 'Glendale',
      state: 'CA',
    },
    91208: {
      lat: 34.19333,
      lng: -118.23839,
      city: 'Glendale',
      state: 'CA',
    },
    91210: {
      lat: 34.14468,
      lng: -118.25809,
      city: 'Glendale',
      state: 'CA',
    },
    91214: {
      lat: 34.23759,
      lng: -118.24778,
      city: 'La Crescenta',
      state: 'CA',
    },
    91301: {
      lat: 34.12314,
      lng: -118.75685,
      city: 'Agoura Hills',
      state: 'CA',
    },
    91302: {
      lat: 34.12295,
      lng: -118.67193,
      city: 'Calabasas',
      state: 'CA',
    },
    91303: {
      lat: 34.19784,
      lng: -118.6017,
      city: 'Canoga Park',
      state: 'CA',
    },
    91304: {
      lat: 34.22472,
      lng: -118.63261,
      city: 'Canoga Park',
      state: 'CA',
    },
    91306: {
      lat: 34.20926,
      lng: -118.5755,
      city: 'Winnetka',
      state: 'CA',
    },
    91307: {
      lat: 34.20177,
      lng: -118.66226,
      city: 'West Hills',
      state: 'CA',
    },
    91311: {
      lat: 34.29011,
      lng: -118.60776,
      city: 'Chatsworth',
      state: 'CA',
    },
    91316: {
      lat: 34.15836,
      lng: -118.51658,
      city: 'Encino',
      state: 'CA',
    },
    91320: {
      lat: 34.17339,
      lng: -118.94795,
      city: 'Newbury Park',
      state: 'CA',
    },
    91321: {
      lat: 34.36479,
      lng: -118.4922,
      city: 'Newhall',
      state: 'CA',
    },
    91324: {
      lat: 34.23855,
      lng: -118.55015,
      city: 'Northridge',
      state: 'CA',
    },
    91325: {
      lat: 34.23636,
      lng: -118.51777,
      city: 'Northridge',
      state: 'CA',
    },
    91326: {
      lat: 34.28022,
      lng: -118.5566,
      city: 'Porter Ranch',
      state: 'CA',
    },
    91330: {
      lat: 34.2446,
      lng: -118.52669,
      city: 'Northridge',
      state: 'CA',
    },
    91331: {
      lat: 34.25602,
      lng: -118.41939,
      city: 'Pacoima',
      state: 'CA',
    },
    91335: {
      lat: 34.20107,
      lng: -118.5407,
      city: 'Reseda',
      state: 'CA',
    },
    91340: {
      lat: 34.28668,
      lng: -118.43479,
      city: 'San Fernando',
      state: 'CA',
    },
    91342: {
      lat: 34.31778,
      lng: -118.39207,
      city: 'Sylmar',
      state: 'CA',
    },
    91343: {
      lat: 34.23837,
      lng: -118.48076,
      city: 'North Hills',
      state: 'CA',
    },
    91344: {
      lat: 34.28991,
      lng: -118.5047,
      city: 'Granada Hills',
      state: 'CA',
    },
    91345: {
      lat: 34.26591,
      lng: -118.45948,
      city: 'Mission Hills',
      state: 'CA',
    },
    91350: {
      lat: 34.43028,
      lng: -118.50283,
      city: 'Santa Clarita',
      state: 'CA',
    },
    91351: {
      lat: 34.43269,
      lng: -118.46276,
      city: 'Canyon Country',
      state: 'CA',
    },
    91352: {
      lat: 34.23183,
      lng: -118.36596,
      city: 'Sun Valley',
      state: 'CA',
    },
    91354: {
      lat: 34.46039,
      lng: -118.5528,
      city: 'Valencia',
      state: 'CA',
    },
    91355: {
      lat: 34.42379,
      lng: -118.58437,
      city: 'Valencia',
      state: 'CA',
    },
    91356: {
      lat: 34.15504,
      lng: -118.54757,
      city: 'Tarzana',
      state: 'CA',
    },
    91360: {
      lat: 34.21475,
      lng: -118.87735,
      city: 'Thousand Oaks',
      state: 'CA',
    },
    91361: {
      lat: 34.14016,
      lng: -118.88398,
      city: 'Westlake Village',
      state: 'CA',
    },
    91362: {
      lat: 34.19244,
      lng: -118.81949,
      city: 'Thousand Oaks',
      state: 'CA',
    },
    91364: {
      lat: 34.1548,
      lng: -118.59492,
      city: 'Woodland Hills',
      state: 'CA',
    },
    91367: {
      lat: 34.17708,
      lng: -118.61518,
      city: 'Woodland Hills',
      state: 'CA',
    },
    91371: {
      lat: 34.18505,
      lng: -118.57605,
      city: 'Woodland Hills',
      state: 'CA',
    },
    91377: {
      lat: 34.1847,
      lng: -118.76652,
      city: 'Oak Park',
      state: 'CA',
    },
    91381: {
      lat: 34.3753,
      lng: -118.60744,
      city: 'Stevenson Ranch',
      state: 'CA',
    },
    91384: {
      lat: 34.51604,
      lng: -118.67289,
      city: 'Castaic',
      state: 'CA',
    },
    91387: {
      lat: 34.39708,
      lng: -118.3707,
      city: 'Canyon Country',
      state: 'CA',
    },
    91390: {
      lat: 34.53159,
      lng: -118.40668,
      city: 'Santa Clarita',
      state: 'CA',
    },
    91401: {
      lat: 34.17801,
      lng: -118.43183,
      city: 'Van Nuys',
      state: 'CA',
    },
    91402: {
      lat: 34.22413,
      lng: -118.4448,
      city: 'Panorama City',
      state: 'CA',
    },
    91403: {
      lat: 34.14716,
      lng: -118.46311,
      city: 'Sherman Oaks',
      state: 'CA',
    },
    91405: {
      lat: 34.20153,
      lng: -118.44863,
      city: 'Van Nuys',
      state: 'CA',
    },
    91406: {
      lat: 34.19791,
      lng: -118.48987,
      city: 'Van Nuys',
      state: 'CA',
    },
    91411: {
      lat: 34.17853,
      lng: -118.45956,
      city: 'Van Nuys',
      state: 'CA',
    },
    91423: {
      lat: 34.14924,
      lng: -118.43239,
      city: 'Sherman Oaks',
      state: 'CA',
    },
    91436: {
      lat: 34.15219,
      lng: -118.49053,
      city: 'Encino',
      state: 'CA',
    },
    91501: {
      lat: 34.19469,
      lng: -118.29431,
      city: 'Burbank',
      state: 'CA',
    },
    91502: {
      lat: 34.17684,
      lng: -118.30936,
      city: 'Burbank',
      state: 'CA',
    },
    91504: {
      lat: 34.21349,
      lng: -118.31478,
      city: 'Burbank',
      state: 'CA',
    },
    91505: {
      lat: 34.17583,
      lng: -118.34692,
      city: 'Burbank',
      state: 'CA',
    },
    91506: {
      lat: 34.17134,
      lng: -118.32384,
      city: 'Burbank',
      state: 'CA',
    },
    91601: {
      lat: 34.16827,
      lng: -118.37222,
      city: 'North Hollywood',
      state: 'CA',
    },
    91602: {
      lat: 34.15078,
      lng: -118.36633,
      city: 'North Hollywood',
      state: 'CA',
    },
    91604: {
      lat: 34.13985,
      lng: -118.39435,
      city: 'Studio City',
      state: 'CA',
    },
    91605: {
      lat: 34.20699,
      lng: -118.40067,
      city: 'North Hollywood',
      state: 'CA',
    },
    91606: {
      lat: 34.18655,
      lng: -118.38867,
      city: 'North Hollywood',
      state: 'CA',
    },
    91607: {
      lat: 34.16633,
      lng: -118.39982,
      city: 'Valley Village',
      state: 'CA',
    },
    91608: {
      lat: 34.13839,
      lng: -118.35272,
      city: 'Universal City',
      state: 'CA',
    },
    91701: {
      lat: 34.13784,
      lng: -117.60028,
      city: 'Rancho Cucamonga',
      state: 'CA',
    },
    91702: {
      lat: 34.24129,
      lng: -117.82614,
      city: 'Azusa',
      state: 'CA',
    },
    91706: {
      lat: 34.09627,
      lng: -117.96855,
      city: 'Baldwin Park',
      state: 'CA',
    },
    91708: {
      lat: 33.95141,
      lng: -117.64506,
      city: 'Chino',
      state: 'CA',
    },
    91709: {
      lat: 33.95105,
      lng: -117.72549,
      city: 'Chino Hills',
      state: 'CA',
    },
    91710: {
      lat: 34.00507,
      lng: -117.68249,
      city: 'Chino',
      state: 'CA',
    },
    91711: {
      lat: 34.12836,
      lng: -117.71523,
      city: 'Claremont',
      state: 'CA',
    },
    91722: {
      lat: 34.09731,
      lng: -117.90612,
      city: 'Covina',
      state: 'CA',
    },
    91723: {
      lat: 34.08495,
      lng: -117.88652,
      city: 'Covina',
      state: 'CA',
    },
    91724: {
      lat: 34.0805,
      lng: -117.85515,
      city: 'Covina',
      state: 'CA',
    },
    91730: {
      lat: 34.10023,
      lng: -117.5811,
      city: 'Rancho Cucamonga',
      state: 'CA',
    },
    91731: {
      lat: 34.07914,
      lng: -118.04047,
      city: 'El Monte',
      state: 'CA',
    },
    91732: {
      lat: 34.07239,
      lng: -118.01455,
      city: 'El Monte',
      state: 'CA',
    },
    91733: {
      lat: 34.0454,
      lng: -118.05314,
      city: 'South El Monte',
      state: 'CA',
    },
    91737: {
      lat: 34.15523,
      lng: -117.57808,
      city: 'Rancho Cucamonga',
      state: 'CA',
    },
    91739: {
      lat: 34.1622,
      lng: -117.51558,
      city: 'Rancho Cucamonga',
      state: 'CA',
    },
    91740: {
      lat: 34.11884,
      lng: -117.8534,
      city: 'Glendora',
      state: 'CA',
    },
    91741: {
      lat: 34.1647,
      lng: -117.83197,
      city: 'Glendora',
      state: 'CA',
    },
    91744: {
      lat: 34.02914,
      lng: -117.93735,
      city: 'La Puente',
      state: 'CA',
    },
    91745: {
      lat: 33.99926,
      lng: -117.97225,
      city: 'Hacienda Heights',
      state: 'CA',
    },
    91746: {
      lat: 34.04397,
      lng: -117.98785,
      city: 'La Puente',
      state: 'CA',
    },
    91748: {
      lat: 33.9769,
      lng: -117.89924,
      city: 'Rowland Heights',
      state: 'CA',
    },
    91750: {
      lat: 34.14927,
      lng: -117.76647,
      city: 'La Verne',
      state: 'CA',
    },
    91752: {
      lat: 33.99575,
      lng: -117.53399,
      city: 'Mira Loma',
      state: 'CA',
    },
    91754: {
      lat: 34.051,
      lng: -118.14451,
      city: 'Monterey Park',
      state: 'CA',
    },
    91755: {
      lat: 34.04832,
      lng: -118.11496,
      city: 'Monterey Park',
      state: 'CA',
    },
    91759: {
      lat: 34.2541,
      lng: -117.69194,
      city: 'Mt Baldy',
      state: 'CA',
    },
    91761: {
      lat: 34.03541,
      lng: -117.5915,
      city: 'Ontario',
      state: 'CA',
    },
    91762: {
      lat: 34.02764,
      lng: -117.64308,
      city: 'Ontario',
      state: 'CA',
    },
    91763: {
      lat: 34.07208,
      lng: -117.69828,
      city: 'Montclair',
      state: 'CA',
    },
    91764: {
      lat: 34.07533,
      lng: -117.60164,
      city: 'Ontario',
      state: 'CA',
    },
    91765: {
      lat: 33.98725,
      lng: -117.81473,
      city: 'Diamond Bar',
      state: 'CA',
    },
    91766: {
      lat: 34.04192,
      lng: -117.75698,
      city: 'Pomona',
      state: 'CA',
    },
    91767: {
      lat: 34.08143,
      lng: -117.73868,
      city: 'Pomona',
      state: 'CA',
    },
    91768: {
      lat: 34.06358,
      lng: -117.78941,
      city: 'Pomona',
      state: 'CA',
    },
    91770: {
      lat: 34.06395,
      lng: -118.08361,
      city: 'Rosemead',
      state: 'CA',
    },
    91773: {
      lat: 34.1072,
      lng: -117.80928,
      city: 'San Dimas',
      state: 'CA',
    },
    91775: {
      lat: 34.11454,
      lng: -118.08946,
      city: 'San Gabriel',
      state: 'CA',
    },
    91776: {
      lat: 34.08987,
      lng: -118.09519,
      city: 'San Gabriel',
      state: 'CA',
    },
    91780: {
      lat: 34.10152,
      lng: -118.05584,
      city: 'Temple City',
      state: 'CA',
    },
    91784: {
      lat: 34.14103,
      lng: -117.65831,
      city: 'Upland',
      state: 'CA',
    },
    91786: {
      lat: 34.10525,
      lng: -117.66206,
      city: 'Upland',
      state: 'CA',
    },
    91789: {
      lat: 34.01858,
      lng: -117.85408,
      city: 'Walnut',
      state: 'CA',
    },
    91790: {
      lat: 34.06722,
      lng: -117.93788,
      city: 'West Covina',
      state: 'CA',
    },
    91791: {
      lat: 34.06134,
      lng: -117.89425,
      city: 'West Covina',
      state: 'CA',
    },
    91792: {
      lat: 34.02586,
      lng: -117.89989,
      city: 'West Covina',
      state: 'CA',
    },
    91801: {
      lat: 34.09093,
      lng: -118.12776,
      city: 'Alhambra',
      state: 'CA',
    },
    91803: {
      lat: 34.0749,
      lng: -118.14624,
      city: 'Alhambra',
      state: 'CA',
    },
    91901: {
      lat: 32.81467,
      lng: -116.71929,
      city: 'Alpine',
      state: 'CA',
    },
    91902: {
      lat: 32.67479,
      lng: -117.00475,
      city: 'Bonita',
      state: 'CA',
    },
    91905: {
      lat: 32.67773,
      lng: -116.30734,
      city: 'Boulevard',
      state: 'CA',
    },
    91906: {
      lat: 32.66241,
      lng: -116.47255,
      city: 'Campo',
      state: 'CA',
    },
    91910: {
      lat: 32.63653,
      lng: -117.06301,
      city: 'Chula Vista',
      state: 'CA',
    },
    91911: {
      lat: 32.60686,
      lng: -117.04984,
      city: 'Chula Vista',
      state: 'CA',
    },
    91913: {
      lat: 32.62174,
      lng: -116.98609,
      city: 'Chula Vista',
      state: 'CA',
    },
    91914: {
      lat: 32.66604,
      lng: -116.95426,
      city: 'Chula Vista',
      state: 'CA',
    },
    91915: {
      lat: 32.62266,
      lng: -116.95013,
      city: 'Chula Vista',
      state: 'CA',
    },
    91916: {
      lat: 32.91103,
      lng: -116.63201,
      city: 'Descanso',
      state: 'CA',
    },
    91917: {
      lat: 32.6267,
      lng: -116.71894,
      city: 'Dulzura',
      state: 'CA',
    },
    91931: {
      lat: 32.83758,
      lng: -116.58029,
      city: 'Guatay',
      state: 'CA',
    },
    91932: {
      lat: 32.56923,
      lng: -117.11798,
      city: 'Imperial Beach',
      state: 'CA',
    },
    91934: {
      lat: 32.66488,
      lng: -116.16828,
      city: 'Jacumba',
      state: 'CA',
    },
    91935: {
      lat: 32.70214,
      lng: -116.78721,
      city: 'Jamul',
      state: 'CA',
    },
    91941: {
      lat: 32.75964,
      lng: -116.99253,
      city: 'La Mesa',
      state: 'CA',
    },
    91942: {
      lat: 32.77728,
      lng: -117.02129,
      city: 'La Mesa',
      state: 'CA',
    },
    91945: {
      lat: 32.73315,
      lng: -117.03402,
      city: 'Lemon Grove',
      state: 'CA',
    },
    91948: {
      lat: 32.87509,
      lng: -116.47076,
      city: 'Mount Laguna',
      state: 'CA',
    },
    91950: {
      lat: 32.66952,
      lng: -117.09313,
      city: 'National City',
      state: 'CA',
    },
    91962: {
      lat: 32.79248,
      lng: -116.48044,
      city: 'Pine Valley',
      state: 'CA',
    },
    91963: {
      lat: 32.64798,
      lng: -116.5964,
      city: 'Potrero',
      state: 'CA',
    },
    91977: {
      lat: 32.72387,
      lng: -116.99631,
      city: 'Spring Valley',
      state: 'CA',
    },
    91978: {
      lat: 32.71209,
      lng: -116.94103,
      city: 'Spring Valley',
      state: 'CA',
    },
    91980: {
      lat: 32.58685,
      lng: -116.61969,
      city: 'Tecate',
      state: 'CA',
    },
    92003: {
      lat: 33.28701,
      lng: -117.19977,
      city: 'Bonsall',
      state: 'CA',
    },
    92004: {
      lat: 33.18481,
      lng: -116.15688,
      city: 'Borrego Springs',
      state: 'CA',
    },
    92007: {
      lat: 33.02216,
      lng: -117.27389,
      city: 'Cardiff By The Sea',
      state: 'CA',
    },
    92008: {
      lat: 33.14564,
      lng: -117.31691,
      city: 'Carlsbad',
      state: 'CA',
    },
    92009: {
      lat: 33.09535,
      lng: -117.24574,
      city: 'Carlsbad',
      state: 'CA',
    },
    92010: {
      lat: 33.15676,
      lng: -117.28423,
      city: 'Carlsbad',
      state: 'CA',
    },
    92011: {
      lat: 33.10698,
      lng: -117.29507,
      city: 'Carlsbad',
      state: 'CA',
    },
    92014: {
      lat: 32.96797,
      lng: -117.24879,
      city: 'Del Mar',
      state: 'CA',
    },
    92019: {
      lat: 32.77886,
      lng: -116.87142,
      city: 'El Cajon',
      state: 'CA',
    },
    92020: {
      lat: 32.79557,
      lng: -116.9697,
      city: 'El Cajon',
      state: 'CA',
    },
    92021: {
      lat: 32.83514,
      lng: -116.87462,
      city: 'El Cajon',
      state: 'CA',
    },
    92024: {
      lat: 33.05716,
      lng: -117.25651,
      city: 'Encinitas',
      state: 'CA',
    },
    92025: {
      lat: 33.08492,
      lng: -117.02967,
      city: 'Escondido',
      state: 'CA',
    },
    92026: {
      lat: 33.2111,
      lng: -117.11157,
      city: 'Escondido',
      state: 'CA',
    },
    92027: {
      lat: 33.13263,
      lng: -116.98335,
      city: 'Escondido',
      state: 'CA',
    },
    92028: {
      lat: 33.38613,
      lng: -117.22658,
      city: 'Fallbrook',
      state: 'CA',
    },
    92029: {
      lat: 33.08555,
      lng: -117.12885,
      city: 'Escondido',
      state: 'CA',
    },
    92036: {
      lat: 33.01137,
      lng: -116.37672,
      city: 'Julian',
      state: 'CA',
    },
    92037: {
      lat: 32.85257,
      lng: -117.25104,
      city: 'La Jolla',
      state: 'CA',
    },
    92040: {
      lat: 32.90665,
      lng: -116.88875,
      city: 'Lakeside',
      state: 'CA',
    },
    92054: {
      lat: 33.19377,
      lng: -117.35508,
      city: 'Oceanside',
      state: 'CA',
    },
    92055: {
      lat: 33.38123,
      lng: -117.42874,
      city: 'Camp Pendleton',
      state: 'CA',
    },
    92056: {
      lat: 33.20197,
      lng: -117.28939,
      city: 'Oceanside',
      state: 'CA',
    },
    92057: {
      lat: 33.25326,
      lng: -117.28704,
      city: 'Oceanside',
      state: 'CA',
    },
    92058: {
      lat: 33.28379,
      lng: -117.34182,
      city: 'Oceanside',
      state: 'CA',
    },
    92059: {
      lat: 33.38277,
      lng: -117.05193,
      city: 'Pala',
      state: 'CA',
    },
    92060: {
      lat: 33.35043,
      lng: -116.86451,
      city: 'Palomar Mountain',
      state: 'CA',
    },
    92061: {
      lat: 33.30036,
      lng: -116.93048,
      city: 'Pauma Valley',
      state: 'CA',
    },
    92064: {
      lat: 32.98038,
      lng: -117.01746,
      city: 'Poway',
      state: 'CA',
    },
    92065: {
      lat: 33.04736,
      lng: -116.84386,
      city: 'Ramona',
      state: 'CA',
    },
    92066: {
      lat: 33.21541,
      lng: -116.53583,
      city: 'Ranchita',
      state: 'CA',
    },
    92067: {
      lat: 33.02087,
      lng: -117.19209,
      city: 'Rancho Santa Fe',
      state: 'CA',
    },
    92069: {
      lat: 33.17536,
      lng: -117.15761,
      city: 'San Marcos',
      state: 'CA',
    },
    92070: {
      lat: 33.18513,
      lng: -116.74071,
      city: 'Santa Ysabel',
      state: 'CA',
    },
    92071: {
      lat: 32.85045,
      lng: -116.99479,
      city: 'Santee',
      state: 'CA',
    },
    92075: {
      lat: 32.99735,
      lng: -117.25875,
      city: 'Solana Beach',
      state: 'CA',
    },
    92078: {
      lat: 33.11834,
      lng: -117.18554,
      city: 'San Marcos',
      state: 'CA',
    },
    92081: {
      lat: 33.16451,
      lng: -117.24056,
      city: 'Vista',
      state: 'CA',
    },
    92082: {
      lat: 33.2518,
      lng: -117.00284,
      city: 'Valley Center',
      state: 'CA',
    },
    92083: {
      lat: 33.19808,
      lng: -117.24844,
      city: 'Vista',
      state: 'CA',
    },
    92084: {
      lat: 33.22454,
      lng: -117.20334,
      city: 'Vista',
      state: 'CA',
    },
    92086: {
      lat: 33.29718,
      lng: -116.64176,
      city: 'Warner Springs',
      state: 'CA',
    },
    92091: {
      lat: 33.03432,
      lng: -117.19704,
      city: 'Rancho Santa Fe',
      state: 'CA',
    },
    92092: {
      lat: 32.87544,
      lng: -117.23621,
      city: 'La Jolla',
      state: 'CA',
    },
    92093: {
      lat: 32.88219,
      lng: -117.23706,
      city: 'La Jolla',
      state: 'CA',
    },
    92096: {
      lat: 33.12819,
      lng: -117.15631,
      city: 'San Marcos',
      state: 'CA',
    },
    92101: {
      lat: 32.7162,
      lng: -117.18589,
      city: 'San Diego',
      state: 'CA',
    },
    92102: {
      lat: 32.71595,
      lng: -117.11749,
      city: 'San Diego',
      state: 'CA',
    },
    92103: {
      lat: 32.74782,
      lng: -117.16738,
      city: 'San Diego',
      state: 'CA',
    },
    92104: {
      lat: 32.74191,
      lng: -117.12781,
      city: 'San Diego',
      state: 'CA',
    },
    92105: {
      lat: 32.73756,
      lng: -117.09193,
      city: 'San Diego',
      state: 'CA',
    },
    92106: {
      lat: 32.71334,
      lng: -117.23617,
      city: 'San Diego',
      state: 'CA',
    },
    92107: {
      lat: 32.7402,
      lng: -117.24357,
      city: 'San Diego',
      state: 'CA',
    },
    92108: {
      lat: 32.77423,
      lng: -117.14097,
      city: 'San Diego',
      state: 'CA',
    },
    92109: {
      lat: 32.78699,
      lng: -117.23273,
      city: 'San Diego',
      state: 'CA',
    },
    92110: {
      lat: 32.76533,
      lng: -117.19965,
      city: 'San Diego',
      state: 'CA',
    },
    92111: {
      lat: 32.8065,
      lng: -117.16897,
      city: 'San Diego',
      state: 'CA',
    },
    92113: {
      lat: 32.69591,
      lng: -117.12012,
      city: 'San Diego',
      state: 'CA',
    },
    92114: {
      lat: 32.70778,
      lng: -117.05529,
      city: 'San Diego',
      state: 'CA',
    },
    92115: {
      lat: 32.76154,
      lng: -117.07187,
      city: 'San Diego',
      state: 'CA',
    },
    92116: {
      lat: 32.76484,
      lng: -117.12303,
      city: 'San Diego',
      state: 'CA',
    },
    92117: {
      lat: 32.82493,
      lng: -117.19972,
      city: 'San Diego',
      state: 'CA',
    },
    92118: {
      lat: 32.67466,
      lng: -117.16915,
      city: 'Coronado',
      state: 'CA',
    },
    92119: {
      lat: 32.80896,
      lng: -117.03232,
      city: 'San Diego',
      state: 'CA',
    },
    92120: {
      lat: 32.79526,
      lng: -117.07317,
      city: 'San Diego',
      state: 'CA',
    },
    92121: {
      lat: 32.8973,
      lng: -117.20231,
      city: 'San Diego',
      state: 'CA',
    },
    92122: {
      lat: 32.85681,
      lng: -117.21017,
      city: 'San Diego',
      state: 'CA',
    },
    92123: {
      lat: 32.80958,
      lng: -117.13419,
      city: 'San Diego',
      state: 'CA',
    },
    92124: {
      lat: 32.82823,
      lng: -117.08528,
      city: 'San Diego',
      state: 'CA',
    },
    92126: {
      lat: 32.912,
      lng: -117.14344,
      city: 'San Diego',
      state: 'CA',
    },
    92127: {
      lat: 33.01913,
      lng: -117.12339,
      city: 'San Diego',
      state: 'CA',
    },
    92128: {
      lat: 32.99687,
      lng: -117.07308,
      city: 'San Diego',
      state: 'CA',
    },
    92129: {
      lat: 32.96354,
      lng: -117.12622,
      city: 'San Diego',
      state: 'CA',
    },
    92130: {
      lat: 32.94763,
      lng: -117.20586,
      city: 'San Diego',
      state: 'CA',
    },
    92131: {
      lat: 32.89604,
      lng: -117.05737,
      city: 'San Diego',
      state: 'CA',
    },
    92132: {
      lat: 32.71368,
      lng: -117.17201,
      city: 'San Diego',
      state: 'CA',
    },
    92134: {
      lat: 32.72512,
      lng: -117.14541,
      city: 'San Diego',
      state: 'CA',
    },
    92135: {
      lat: 32.69487,
      lng: -117.19461,
      city: 'San Diego',
      state: 'CA',
    },
    92136: {
      lat: 32.68254,
      lng: -117.12307,
      city: 'San Diego',
      state: 'CA',
    },
    92139: {
      lat: 32.68006,
      lng: -117.04889,
      city: 'San Diego',
      state: 'CA',
    },
    92140: {
      lat: 32.74041,
      lng: -117.1984,
      city: 'San Diego',
      state: 'CA',
    },
    92145: {
      lat: 32.86292,
      lng: -117.14579,
      city: 'San Diego',
      state: 'CA',
    },
    92147: {
      lat: 32.72478,
      lng: -117.21895,
      city: 'San Diego',
      state: 'CA',
    },
    92154: {
      lat: 32.578,
      lng: -116.96621,
      city: 'San Diego',
      state: 'CA',
    },
    92155: {
      lat: 32.67148,
      lng: -117.16714,
      city: 'San Diego',
      state: 'CA',
    },
    92161: {
      lat: 32.8742,
      lng: -117.23159,
      city: 'San Diego',
      state: 'CA',
    },
    92173: {
      lat: 32.55552,
      lng: -117.05114,
      city: 'San Ysidro',
      state: 'CA',
    },
    92179: {
      lat: 32.58409,
      lng: -116.93349,
      city: 'San Diego',
      state: 'CA',
    },
    92182: {
      lat: 32.77498,
      lng: -117.07424,
      city: 'San Diego',
      state: 'CA',
    },
    92201: {
      lat: 33.70804,
      lng: -116.23502,
      city: 'Indio',
      state: 'CA',
    },
    92203: {
      lat: 33.75159,
      lng: -116.24139,
      city: 'Indio',
      state: 'CA',
    },
    92210: {
      lat: 33.70357,
      lng: -116.33953,
      city: 'Indian Wells',
      state: 'CA',
    },
    92211: {
      lat: 33.76593,
      lng: -116.33241,
      city: 'Palm Desert',
      state: 'CA',
    },
    92220: {
      lat: 33.93992,
      lng: -116.83935,
      city: 'Banning',
      state: 'CA',
    },
    92222: {
      lat: 32.78346,
      lng: -114.54965,
      city: 'Bard',
      state: 'CA',
    },
    92223: {
      lat: 33.9382,
      lng: -116.98137,
      city: 'Beaumont',
      state: 'CA',
    },
    92225: {
      lat: 33.71642,
      lng: -114.65606,
      city: 'Blythe',
      state: 'CA',
    },
    92227: {
      lat: 32.98677,
      lng: -115.3341,
      city: 'Brawley',
      state: 'CA',
    },
    92230: {
      lat: 33.9088,
      lng: -116.76879,
      city: 'Cabazon',
      state: 'CA',
    },
    92231: {
      lat: 32.68751,
      lng: -115.56433,
      city: 'Calexico',
      state: 'CA',
    },
    92233: {
      lat: 33.14736,
      lng: -115.50686,
      city: 'Calipatria',
      state: 'CA',
    },
    92234: {
      lat: 33.81386,
      lng: -116.46663,
      city: 'Cathedral City',
      state: 'CA',
    },
    92236: {
      lat: 33.70276,
      lng: -116.07254,
      city: 'Coachella',
      state: 'CA',
    },
    92239: {
      lat: 33.87424,
      lng: -115.24396,
      city: 'Desert Center',
      state: 'CA',
    },
    92240: {
      lat: 33.95291,
      lng: -116.5194,
      city: 'Desert Hot Springs',
      state: 'CA',
    },
    92241: {
      lat: 33.84634,
      lng: -116.28342,
      city: 'Desert Hot Springs',
      state: 'CA',
    },
    92242: {
      lat: 34.19019,
      lng: -114.30755,
      city: 'Earp',
      state: 'CA',
    },
    92243: {
      lat: 32.77285,
      lng: -115.60065,
      city: 'El Centro',
      state: 'CA',
    },
    92249: {
      lat: 32.72606,
      lng: -115.48218,
      city: 'Heber',
      state: 'CA',
    },
    92250: {
      lat: 32.79188,
      lng: -115.23454,
      city: 'Holtville',
      state: 'CA',
    },
    92251: {
      lat: 32.87129,
      lng: -115.71804,
      city: 'Imperial',
      state: 'CA',
    },
    92252: {
      lat: 34.14801,
      lng: -116.28422,
      city: 'Joshua Tree',
      state: 'CA',
    },
    92253: {
      lat: 33.65562,
      lng: -116.28785,
      city: 'La Quinta',
      state: 'CA',
    },
    92254: {
      lat: 33.54371,
      lng: -115.99995,
      city: 'Mecca',
      state: 'CA',
    },
    92256: {
      lat: 34.07207,
      lng: -116.61761,
      city: 'Morongo Valley',
      state: 'CA',
    },
    92257: {
      lat: 33.33976,
      lng: -115.52814,
      city: 'Niland',
      state: 'CA',
    },
    92258: {
      lat: 33.91791,
      lng: -116.56002,
      city: 'North Palm Springs',
      state: 'CA',
    },
    92259: {
      lat: 32.73478,
      lng: -115.94632,
      city: 'Ocotillo',
      state: 'CA',
    },
    92260: {
      lat: 33.69841,
      lng: -116.40519,
      city: 'Palm Desert',
      state: 'CA',
    },
    92262: {
      lat: 33.86287,
      lng: -116.55736,
      city: 'Palm Springs',
      state: 'CA',
    },
    92264: {
      lat: 33.73575,
      lng: -116.50544,
      city: 'Palm Springs',
      state: 'CA',
    },
    92266: {
      lat: 33.17507,
      lng: -114.92594,
      city: 'Palo Verde',
      state: 'CA',
    },
    92267: {
      lat: 34.29765,
      lng: -114.20138,
      city: 'Parker Dam',
      state: 'CA',
    },
    92268: {
      lat: 34.16146,
      lng: -116.55002,
      city: 'Pioneertown',
      state: 'CA',
    },
    92270: {
      lat: 33.76399,
      lng: -116.42705,
      city: 'Rancho Mirage',
      state: 'CA',
    },
    92273: {
      lat: 32.80798,
      lng: -115.69592,
      city: 'Seeley',
      state: 'CA',
    },
    92274: {
      lat: 33.47031,
      lng: -116.11387,
      city: 'Thermal',
      state: 'CA',
    },
    92275: {
      lat: 33.20783,
      lng: -115.88084,
      city: 'Salton City',
      state: 'CA',
    },
    92276: {
      lat: 33.83234,
      lng: -116.38648,
      city: 'Thousand Palms',
      state: 'CA',
    },
    92277: {
      lat: 34.11248,
      lng: -115.68752,
      city: 'Twentynine Palms',
      state: 'CA',
    },
    92278: {
      lat: 34.23505,
      lng: -116.05825,
      city: 'Twentynine Palms',
      state: 'CA',
    },
    92280: {
      lat: 34.13963,
      lng: -114.84652,
      city: 'Vidal',
      state: 'CA',
    },
    92281: {
      lat: 33.05033,
      lng: -115.61264,
      city: 'Westmorland',
      state: 'CA',
    },
    92282: {
      lat: 33.96253,
      lng: -116.65829,
      city: 'Whitewater',
      state: 'CA',
    },
    92283: {
      lat: 32.83741,
      lng: -114.79462,
      city: 'Winterhaven',
      state: 'CA',
    },
    92284: {
      lat: 34.16013,
      lng: -116.42474,
      city: 'Yucca Valley',
      state: 'CA',
    },
    92285: {
      lat: 34.33762,
      lng: -116.51364,
      city: 'Landers',
      state: 'CA',
    },
    92301: {
      lat: 34.65183,
      lng: -117.52617,
      city: 'Adelanto',
      state: 'CA',
    },
    92304: {
      lat: 34.53062,
      lng: -115.65933,
      city: 'Amboy',
      state: 'CA',
    },
    92305: {
      lat: 34.17018,
      lng: -116.83231,
      city: 'Angelus Oaks',
      state: 'CA',
    },
    92307: {
      lat: 34.59078,
      lng: -117.14127,
      city: 'Apple Valley',
      state: 'CA',
    },
    92308: {
      lat: 34.42005,
      lng: -117.15558,
      city: 'Apple Valley',
      state: 'CA',
    },
    92309: {
      lat: 35.24942,
      lng: -116.15578,
      city: 'Baker',
      state: 'CA',
    },
    92310: {
      lat: 35.33443,
      lng: -116.76306,
      city: 'Fort Irwin',
      state: 'CA',
    },
    92311: {
      lat: 34.97204,
      lng: -117.0101,
      city: 'Barstow',
      state: 'CA',
    },
    92313: {
      lat: 34.03142,
      lng: -117.31373,
      city: 'Grand Terrace',
      state: 'CA',
    },
    92314: {
      lat: 34.2576,
      lng: -116.80075,
      city: 'Big Bear City',
      state: 'CA',
    },
    92315: {
      lat: 34.22011,
      lng: -116.95301,
      city: 'Big Bear Lake',
      state: 'CA',
    },
    92316: {
      lat: 34.0576,
      lng: -117.39076,
      city: 'Bloomington',
      state: 'CA',
    },
    92317: {
      lat: 34.24507,
      lng: -117.20874,
      city: 'Blue Jay',
      state: 'CA',
    },
    92320: {
      lat: 33.9874,
      lng: -117.05024,
      city: 'Calimesa',
      state: 'CA',
    },
    92321: {
      lat: 34.24748,
      lng: -117.14694,
      city: 'Cedar Glen',
      state: 'CA',
    },
    92322: {
      lat: 34.25419,
      lng: -117.32259,
      city: 'Cedarpines Park',
      state: 'CA',
    },
    92324: {
      lat: 34.03398,
      lng: -117.29008,
      city: 'Colton',
      state: 'CA',
    },
    92325: {
      lat: 34.24554,
      lng: -117.27506,
      city: 'Crestline',
      state: 'CA',
    },
    92327: {
      lat: 34.86632,
      lng: -116.84347,
      city: 'Daggett',
      state: 'CA',
    },
    92328: {
      lat: 36.39482,
      lng: -117.02718,
      city: 'Death Valley',
      state: 'CA',
    },
    92332: {
      lat: 34.90728,
      lng: -115.36409,
      city: 'Essex',
      state: 'CA',
    },
    92333: {
      lat: 34.26951,
      lng: -116.96277,
      city: 'Fawnskin',
      state: 'CA',
    },
    92335: {
      lat: 34.08733,
      lng: -117.46519,
      city: 'Fontana',
      state: 'CA',
    },
    92336: {
      lat: 34.13686,
      lng: -117.46033,
      city: 'Fontana',
      state: 'CA',
    },
    92337: {
      lat: 34.04967,
      lng: -117.47145,
      city: 'Fontana',
      state: 'CA',
    },
    92338: {
      lat: 34.78773,
      lng: -116.25117,
      city: 'Ludlow',
      state: 'CA',
    },
    92339: {
      lat: 34.08189,
      lng: -116.83838,
      city: 'Forest Falls',
      state: 'CA',
    },
    92341: {
      lat: 34.24009,
      lng: -117.06798,
      city: 'Green Valley Lake',
      state: 'CA',
    },
    92342: {
      lat: 34.76078,
      lng: -117.33795,
      city: 'Helendale',
      state: 'CA',
    },
    92344: {
      lat: 34.39176,
      lng: -117.40657,
      city: 'Hesperia',
      state: 'CA',
    },
    92345: {
      lat: 34.36415,
      lng: -117.30984,
      city: 'Hesperia',
      state: 'CA',
    },
    92346: {
      lat: 34.12629,
      lng: -117.1792,
      city: 'Highland',
      state: 'CA',
    },
    92347: {
      lat: 34.95003,
      lng: -117.25802,
      city: 'Hinkley',
      state: 'CA',
    },
    92350: {
      lat: 34.05296,
      lng: -117.2617,
      city: 'Loma Linda',
      state: 'CA',
    },
    92352: {
      lat: 34.26824,
      lng: -117.18486,
      city: 'Lake Arrowhead',
      state: 'CA',
    },
    92354: {
      lat: 34.04933,
      lng: -117.25119,
      city: 'Loma Linda',
      state: 'CA',
    },
    92356: {
      lat: 34.49633,
      lng: -116.89265,
      city: 'Lucerne Valley',
      state: 'CA',
    },
    92358: {
      lat: 34.24624,
      lng: -117.56762,
      city: 'Lytle Creek',
      state: 'CA',
    },
    92359: {
      lat: 34.08296,
      lng: -117.07026,
      city: 'Mentone',
      state: 'CA',
    },
    92363: {
      lat: 34.70863,
      lng: -114.57562,
      city: 'Needles',
      state: 'CA',
    },
    92364: {
      lat: 35.35678,
      lng: -115.43741,
      city: 'Nipton',
      state: 'CA',
    },
    92365: {
      lat: 34.88843,
      lng: -116.54129,
      city: 'Newberry Springs',
      state: 'CA',
    },
    92368: {
      lat: 34.65508,
      lng: -117.30893,
      city: 'Oro Grande',
      state: 'CA',
    },
    92369: {
      lat: 34.13941,
      lng: -117.21966,
      city: 'Patton',
      state: 'CA',
    },
    92371: {
      lat: 34.43837,
      lng: -117.54266,
      city: 'Phelan',
      state: 'CA',
    },
    92372: {
      lat: 34.45097,
      lng: -117.62859,
      city: 'Pinon Hills',
      state: 'CA',
    },
    92373: {
      lat: 34.00648,
      lng: -117.15351,
      city: 'Redlands',
      state: 'CA',
    },
    92374: {
      lat: 34.06738,
      lng: -117.17482,
      city: 'Redlands',
      state: 'CA',
    },
    92376: {
      lat: 34.11053,
      lng: -117.37997,
      city: 'Rialto',
      state: 'CA',
    },
    92377: {
      lat: 34.15234,
      lng: -117.40412,
      city: 'Rialto',
      state: 'CA',
    },
    92378: {
      lat: 34.23206,
      lng: -117.22483,
      city: 'Rimforest',
      state: 'CA',
    },
    92382: {
      lat: 34.20604,
      lng: -117.11276,
      city: 'Running Springs',
      state: 'CA',
    },
    92384: {
      lat: 35.98255,
      lng: -116.1505,
      city: 'Shoshone',
      state: 'CA',
    },
    92385: {
      lat: 34.21345,
      lng: -117.23057,
      city: 'Skyforest',
      state: 'CA',
    },
    92386: {
      lat: 34.23702,
      lng: -116.82523,
      city: 'Sugarloaf',
      state: 'CA',
    },
    92389: {
      lat: 35.82902,
      lng: -116.24813,
      city: 'Tecopa',
      state: 'CA',
    },
    92391: {
      lat: 34.23958,
      lng: -117.23665,
      city: 'Twin Peaks',
      state: 'CA',
    },
    92392: {
      lat: 34.479,
      lng: -117.40884,
      city: 'Victorville',
      state: 'CA',
    },
    92394: {
      lat: 34.563,
      lng: -117.3542,
      city: 'Victorville',
      state: 'CA',
    },
    92395: {
      lat: 34.50032,
      lng: -117.29479,
      city: 'Victorville',
      state: 'CA',
    },
    92397: {
      lat: 34.36212,
      lng: -117.69136,
      city: 'Wrightwood',
      state: 'CA',
    },
    92398: {
      lat: 34.97178,
      lng: -116.83808,
      city: 'Yermo',
      state: 'CA',
    },
    92399: {
      lat: 34.04469,
      lng: -117.004,
      city: 'Yucaipa',
      state: 'CA',
    },
    92401: {
      lat: 34.10586,
      lng: -117.29188,
      city: 'San Bernardino',
      state: 'CA',
    },
    92404: {
      lat: 34.17862,
      lng: -117.23891,
      city: 'San Bernardino',
      state: 'CA',
    },
    92405: {
      lat: 34.14578,
      lng: -117.30225,
      city: 'San Bernardino',
      state: 'CA',
    },
    92407: {
      lat: 34.22394,
      lng: -117.38839,
      city: 'San Bernardino',
      state: 'CA',
    },
    92408: {
      lat: 34.0845,
      lng: -117.2653,
      city: 'San Bernardino',
      state: 'CA',
    },
    92410: {
      lat: 34.10655,
      lng: -117.29574,
      city: 'San Bernardino',
      state: 'CA',
    },
    92411: {
      lat: 34.12214,
      lng: -117.32291,
      city: 'San Bernardino',
      state: 'CA',
    },
    92415: {
      lat: 34.10506,
      lng: -117.28685,
      city: 'San Bernardino',
      state: 'CA',
    },
    92501: {
      lat: 33.99496,
      lng: -117.37367,
      city: 'Riverside',
      state: 'CA',
    },
    92503: {
      lat: 33.88308,
      lng: -117.45222,
      city: 'Riverside',
      state: 'CA',
    },
    92504: {
      lat: 33.90607,
      lng: -117.39854,
      city: 'Riverside',
      state: 'CA',
    },
    92505: {
      lat: 33.92663,
      lng: -117.4928,
      city: 'Riverside',
      state: 'CA',
    },
    92506: {
      lat: 33.93478,
      lng: -117.36689,
      city: 'Riverside',
      state: 'CA',
    },
    92507: {
      lat: 33.97464,
      lng: -117.32234,
      city: 'Riverside',
      state: 'CA',
    },
    92508: {
      lat: 33.89039,
      lng: -117.3282,
      city: 'Riverside',
      state: 'CA',
    },
    92509: {
      lat: 34.00293,
      lng: -117.44547,
      city: 'Jurupa Valley',
      state: 'CA',
    },
    92518: {
      lat: 33.89602,
      lng: -117.27705,
      city: 'March Air Reserve Base',
      state: 'CA',
    },
    92521: {
      lat: 33.96794,
      lng: -117.32731,
      city: 'Riverside',
      state: 'CA',
    },
    92530: {
      lat: 33.63386,
      lng: -117.37949,
      city: 'Lake Elsinore',
      state: 'CA',
    },
    92532: {
      lat: 33.69052,
      lng: -117.30241,
      city: 'Lake Elsinore',
      state: 'CA',
    },
    92536: {
      lat: 33.48791,
      lng: -116.81528,
      city: 'Aguanga',
      state: 'CA',
    },
    92539: {
      lat: 33.51579,
      lng: -116.64432,
      city: 'Anza',
      state: 'CA',
    },
    92543: {
      lat: 33.69867,
      lng: -116.97916,
      city: 'Hemet',
      state: 'CA',
    },
    92544: {
      lat: 33.64581,
      lng: -116.86033,
      city: 'Hemet',
      state: 'CA',
    },
    92545: { lat: 33.7311, lng: -117.0359, city: 'Hemet', state: 'CA' },
    92548: {
      lat: 33.76392,
      lng: -117.10907,
      city: 'Homeland',
      state: 'CA',
    },
    92549: {
      lat: 33.80886,
      lng: -116.71212,
      city: 'Idyllwild',
      state: 'CA',
    },
    92551: {
      lat: 33.88148,
      lng: -117.22521,
      city: 'Moreno Valley',
      state: 'CA',
    },
    92553: {
      lat: 33.92335,
      lng: -117.24532,
      city: 'Moreno Valley',
      state: 'CA',
    },
    92555: {
      lat: 33.9133,
      lng: -117.10867,
      city: 'Moreno Valley',
      state: 'CA',
    },
    92557: {
      lat: 33.96855,
      lng: -117.25704,
      city: 'Moreno Valley',
      state: 'CA',
    },
    92561: {
      lat: 33.63704,
      lng: -116.57441,
      city: 'Mountain Center',
      state: 'CA',
    },
    92562: {
      lat: 33.53579,
      lng: -117.35098,
      city: 'Murrieta',
      state: 'CA',
    },
    92563: {
      lat: 33.58435,
      lng: -117.14458,
      city: 'Murrieta',
      state: 'CA',
    },
    92567: {
      lat: 33.82378,
      lng: -117.10305,
      city: 'Nuevo',
      state: 'CA',
    },
    92570: {
      lat: 33.78377,
      lng: -117.31737,
      city: 'Perris',
      state: 'CA',
    },
    92571: {
      lat: 33.84182,
      lng: -117.19522,
      city: 'Perris',
      state: 'CA',
    },
    92582: {
      lat: 33.80489,
      lng: -117.01873,
      city: 'San Jacinto',
      state: 'CA',
    },
    92583: {
      lat: 33.79662,
      lng: -116.93188,
      city: 'San Jacinto',
      state: 'CA',
    },
    92584: {
      lat: 33.66167,
      lng: -117.18079,
      city: 'Menifee',
      state: 'CA',
    },
    92585: {
      lat: 33.74217,
      lng: -117.16723,
      city: 'Menifee',
      state: 'CA',
    },
    92586: {
      lat: 33.70773,
      lng: -117.20045,
      city: 'Menifee',
      state: 'CA',
    },
    92587: {
      lat: 33.69466,
      lng: -117.25196,
      city: 'Menifee',
      state: 'CA',
    },
    92590: {
      lat: 33.48391,
      lng: -117.22075,
      city: 'Temecula',
      state: 'CA',
    },
    92591: {
      lat: 33.53432,
      lng: -117.1092,
      city: 'Temecula',
      state: 'CA',
    },
    92592: {
      lat: 33.511,
      lng: -117.0242,
      city: 'Temecula',
      state: 'CA',
    },
    92595: {
      lat: 33.61638,
      lng: -117.26028,
      city: 'Wildomar',
      state: 'CA',
    },
    92596: {
      lat: 33.64739,
      lng: -117.07641,
      city: 'Winchester',
      state: 'CA',
    },
    92602: {
      lat: 33.72645,
      lng: -117.7287,
      city: 'Irvine',
      state: 'CA',
    },
    92603: {
      lat: 33.62927,
      lng: -117.79408,
      city: 'Irvine',
      state: 'CA',
    },
    92604: {
      lat: 33.6879,
      lng: -117.7889,
      city: 'Irvine',
      state: 'CA',
    },
    92606: {
      lat: 33.69849,
      lng: -117.8123,
      city: 'Irvine',
      state: 'CA',
    },
    92610: {
      lat: 33.68336,
      lng: -117.65404,
      city: 'Foothill Ranch',
      state: 'CA',
    },
    92612: {
      lat: 33.65984,
      lng: -117.82457,
      city: 'Irvine',
      state: 'CA',
    },
    92614: {
      lat: 33.68173,
      lng: -117.83316,
      city: 'Irvine',
      state: 'CA',
    },
    92617: {
      lat: 33.64197,
      lng: -117.84103,
      city: 'Irvine',
      state: 'CA',
    },
    92618: {
      lat: 33.66729,
      lng: -117.73362,
      city: 'Irvine',
      state: 'CA',
    },
    92620: {
      lat: 33.70661,
      lng: -117.75695,
      city: 'Irvine',
      state: 'CA',
    },
    92624: {
      lat: 33.46082,
      lng: -117.66656,
      city: 'Capistrano Beach',
      state: 'CA',
    },
    92625: {
      lat: 33.60229,
      lng: -117.86551,
      city: 'Corona Del Mar',
      state: 'CA',
    },
    92626: {
      lat: 33.67896,
      lng: -117.90844,
      city: 'Costa Mesa',
      state: 'CA',
    },
    92627: {
      lat: 33.64786,
      lng: -117.9205,
      city: 'Costa Mesa',
      state: 'CA',
    },
    92629: {
      lat: 33.47678,
      lng: -117.70459,
      city: 'Dana Point',
      state: 'CA',
    },
    92630: {
      lat: 33.64709,
      lng: -117.68428,
      city: 'Lake Forest',
      state: 'CA',
    },
    92637: {
      lat: 33.60796,
      lng: -117.72799,
      city: 'Laguna Woods',
      state: 'CA',
    },
    92646: {
      lat: 33.66525,
      lng: -117.96755,
      city: 'Huntington Beach',
      state: 'CA',
    },
    92647: {
      lat: 33.72395,
      lng: -118.00685,
      city: 'Huntington Beach',
      state: 'CA',
    },
    92648: {
      lat: 33.68212,
      lng: -118.0101,
      city: 'Huntington Beach',
      state: 'CA',
    },
    92649: {
      lat: 33.72037,
      lng: -118.04423,
      city: 'Huntington Beach',
      state: 'CA',
    },
    92651: {
      lat: 33.56535,
      lng: -117.77399,
      city: 'Laguna Beach',
      state: 'CA',
    },
    92653: {
      lat: 33.59276,
      lng: -117.69994,
      city: 'Laguna Hills',
      state: 'CA',
    },
    92655: {
      lat: 33.74606,
      lng: -117.98765,
      city: 'Midway City',
      state: 'CA',
    },
    92656: {
      lat: 33.57518,
      lng: -117.73036,
      city: 'Aliso Viejo',
      state: 'CA',
    },
    92657: {
      lat: 33.59571,
      lng: -117.83069,
      city: 'Newport Coast',
      state: 'CA',
    },
    92660: {
      lat: 33.63375,
      lng: -117.87504,
      city: 'Newport Beach',
      state: 'CA',
    },
    92661: {
      lat: 33.60231,
      lng: -117.89872,
      city: 'Newport Beach',
      state: 'CA',
    },
    92662: {
      lat: 33.60547,
      lng: -117.89218,
      city: 'Newport Beach',
      state: 'CA',
    },
    92663: {
      lat: 33.62328,
      lng: -117.93022,
      city: 'Newport Beach',
      state: 'CA',
    },
    92672: {
      lat: 33.3825,
      lng: -117.5403,
      city: 'San Clemente',
      state: 'CA',
    },
    92673: {
      lat: 33.46611,
      lng: -117.61204,
      city: 'San Clemente',
      state: 'CA',
    },
    92675: {
      lat: 33.49977,
      lng: -117.65366,
      city: 'San Juan Capistrano',
      state: 'CA',
    },
    92676: {
      lat: 33.75306,
      lng: -117.63534,
      city: 'Silverado',
      state: 'CA',
    },
    92677: {
      lat: 33.52777,
      lng: -117.70523,
      city: 'Laguna Niguel',
      state: 'CA',
    },
    92678: {
      lat: 33.64627,
      lng: -117.50662,
      city: 'Trabuco Canyon',
      state: 'CA',
    },
    92679: {
      lat: 33.65274,
      lng: -117.56048,
      city: 'Trabuco Canyon',
      state: 'CA',
    },
    92683: {
      lat: 33.75242,
      lng: -117.99383,
      city: 'Westminster',
      state: 'CA',
    },
    92688: {
      lat: 33.61737,
      lng: -117.61131,
      city: 'Rancho Santa Margarita',
      state: 'CA',
    },
    92691: {
      lat: 33.61051,
      lng: -117.66627,
      city: 'Mission Viejo',
      state: 'CA',
    },
    92692: {
      lat: 33.60948,
      lng: -117.64396,
      city: 'Mission Viejo',
      state: 'CA',
    },
    92694: {
      lat: 33.51502,
      lng: -117.58065,
      city: 'Ladera Ranch',
      state: 'CA',
    },
    92697: {
      lat: 33.65061,
      lng: -117.85091,
      city: 'Irvine',
      state: 'CA',
    },
    92701: {
      lat: 33.7486,
      lng: -117.85877,
      city: 'Santa Ana',
      state: 'CA',
    },
    92703: {
      lat: 33.74853,
      lng: -117.90621,
      city: 'Santa Ana',
      state: 'CA',
    },
    92704: {
      lat: 33.72038,
      lng: -117.90807,
      city: 'Santa Ana',
      state: 'CA',
    },
    92705: {
      lat: 33.755,
      lng: -117.81488,
      city: 'Santa Ana',
      state: 'CA',
    },
    92706: {
      lat: 33.76602,
      lng: -117.88174,
      city: 'Santa Ana',
      state: 'CA',
    },
    92707: {
      lat: 33.70991,
      lng: -117.87045,
      city: 'Santa Ana',
      state: 'CA',
    },
    92708: {
      lat: 33.71046,
      lng: -117.95132,
      city: 'Fountain Valley',
      state: 'CA',
    },
    92780: {
      lat: 33.73685,
      lng: -117.81968,
      city: 'Tustin',
      state: 'CA',
    },
    92782: {
      lat: 33.72833,
      lng: -117.79753,
      city: 'Tustin',
      state: 'CA',
    },
    92801: {
      lat: 33.84441,
      lng: -117.95175,
      city: 'Anaheim',
      state: 'CA',
    },
    92802: {
      lat: 33.80831,
      lng: -117.9237,
      city: 'Anaheim',
      state: 'CA',
    },
    92804: {
      lat: 33.81801,
      lng: -117.97517,
      city: 'Anaheim',
      state: 'CA',
    },
    92805: {
      lat: 33.83003,
      lng: -117.90568,
      city: 'Anaheim',
      state: 'CA',
    },
    92806: {
      lat: 33.83884,
      lng: -117.87054,
      city: 'Anaheim',
      state: 'CA',
    },
    92807: {
      lat: 33.85001,
      lng: -117.78946,
      city: 'Anaheim',
      state: 'CA',
    },
    92808: {
      lat: 33.85623,
      lng: -117.74018,
      city: 'Anaheim',
      state: 'CA',
    },
    92821: {
      lat: 33.92997,
      lng: -117.86986,
      city: 'Brea',
      state: 'CA',
    },
    92823: {
      lat: 33.92536,
      lng: -117.80024,
      city: 'Brea',
      state: 'CA',
    },
    92831: {
      lat: 33.8795,
      lng: -117.89607,
      city: 'Fullerton',
      state: 'CA',
    },
    92832: {
      lat: 33.86871,
      lng: -117.92914,
      city: 'Fullerton',
      state: 'CA',
    },
    92833: {
      lat: 33.88051,
      lng: -117.96112,
      city: 'Fullerton',
      state: 'CA',
    },
    92835: {
      lat: 33.90171,
      lng: -117.91529,
      city: 'Fullerton',
      state: 'CA',
    },
    92840: {
      lat: 33.78584,
      lng: -117.93202,
      city: 'Garden Grove',
      state: 'CA',
    },
    92841: {
      lat: 33.7868,
      lng: -117.98207,
      city: 'Garden Grove',
      state: 'CA',
    },
    92843: {
      lat: 33.76399,
      lng: -117.93153,
      city: 'Garden Grove',
      state: 'CA',
    },
    92844: {
      lat: 33.76561,
      lng: -117.96952,
      city: 'Garden Grove',
      state: 'CA',
    },
    92845: {
      lat: 33.78292,
      lng: -118.0264,
      city: 'Garden Grove',
      state: 'CA',
    },
    92860: {
      lat: 33.9262,
      lng: -117.54913,
      city: 'Norco',
      state: 'CA',
    },
    92861: {
      lat: 33.81801,
      lng: -117.81031,
      city: 'Villa Park',
      state: 'CA',
    },
    92865: {
      lat: 33.82887,
      lng: -117.84925,
      city: 'Orange',
      state: 'CA',
    },
    92866: {
      lat: 33.78453,
      lng: -117.84479,
      city: 'Orange',
      state: 'CA',
    },
    92867: {
      lat: 33.81443,
      lng: -117.79617,
      city: 'Orange',
      state: 'CA',
    },
    92868: {
      lat: 33.78799,
      lng: -117.87637,
      city: 'Orange',
      state: 'CA',
    },
    92869: {
      lat: 33.79479,
      lng: -117.7621,
      city: 'Orange',
      state: 'CA',
    },
    92870: {
      lat: 33.88087,
      lng: -117.85522,
      city: 'Placentia',
      state: 'CA',
    },
    92879: {
      lat: 33.87979,
      lng: -117.5357,
      city: 'Corona',
      state: 'CA',
    },
    92880: {
      lat: 33.92096,
      lng: -117.61052,
      city: 'Eastvale',
      state: 'CA',
    },
    92881: {
      lat: 33.82163,
      lng: -117.56763,
      city: 'Corona',
      state: 'CA',
    },
    92882: {
      lat: 33.86367,
      lng: -117.59989,
      city: 'Corona',
      state: 'CA',
    },
    92883: {
      lat: 33.75994,
      lng: -117.47827,
      city: 'Corona',
      state: 'CA',
    },
    92886: {
      lat: 33.89517,
      lng: -117.80154,
      city: 'Yorba Linda',
      state: 'CA',
    },
    92887: {
      lat: 33.88727,
      lng: -117.73389,
      city: 'Yorba Linda',
      state: 'CA',
    },
    93001: {
      lat: 34.35321,
      lng: -119.31868,
      city: 'Ventura',
      state: 'CA',
    },
    93003: {
      lat: 34.28434,
      lng: -119.22256,
      city: 'Ventura',
      state: 'CA',
    },
    93004: {
      lat: 34.27901,
      lng: -119.16537,
      city: 'Ventura',
      state: 'CA',
    },
    93010: {
      lat: 34.23007,
      lng: -119.07437,
      city: 'Camarillo',
      state: 'CA',
    },
    93012: {
      lat: 34.20518,
      lng: -118.99309,
      city: 'Camarillo',
      state: 'CA',
    },
    93013: {
      lat: 34.42697,
      lng: -119.48032,
      city: 'Carpinteria',
      state: 'CA',
    },
    93015: {
      lat: 34.40969,
      lng: -118.87807,
      city: 'Fillmore',
      state: 'CA',
    },
    93021: {
      lat: 34.30417,
      lng: -118.88274,
      city: 'Moorpark',
      state: 'CA',
    },
    93022: {
      lat: 34.40324,
      lng: -119.29836,
      city: 'Oak View',
      state: 'CA',
    },
    93023: {
      lat: 34.50372,
      lng: -119.24856,
      city: 'Ojai',
      state: 'CA',
    },
    93030: {
      lat: 34.2051,
      lng: -119.1753,
      city: 'Oxnard',
      state: 'CA',
    },
    93033: {
      lat: 34.15401,
      lng: -119.12296,
      city: 'Oxnard',
      state: 'CA',
    },
    93035: {
      lat: 34.19034,
      lng: -119.22879,
      city: 'Oxnard',
      state: 'CA',
    },
    93036: {
      lat: 34.24911,
      lng: -119.15977,
      city: 'Oxnard',
      state: 'CA',
    },
    93040: {
      lat: 34.59337,
      lng: -118.93657,
      city: 'Piru',
      state: 'CA',
    },
    93041: {
      lat: 34.14956,
      lng: -119.17458,
      city: 'Port Hueneme',
      state: 'CA',
    },
    93042: {
      lat: 33.4077,
      lng: -119.43365,
      city: 'Point Mugu Nawc',
      state: 'CA',
    },
    93043: {
      lat: 34.17001,
      lng: -119.2026,
      city: 'Port Hueneme Cbc Base',
      state: 'CA',
    },
    93060: {
      lat: 34.36543,
      lng: -119.09563,
      city: 'Santa Paula',
      state: 'CA',
    },
    93063: {
      lat: 34.30922,
      lng: -118.69041,
      city: 'Simi Valley',
      state: 'CA',
    },
    93064: {
      lat: 34.24527,
      lng: -118.69984,
      city: 'Brandeis',
      state: 'CA',
    },
    93065: {
      lat: 34.26206,
      lng: -118.77151,
      city: 'Simi Valley',
      state: 'CA',
    },
    93066: {
      lat: 34.28851,
      lng: -119.02848,
      city: 'Somis',
      state: 'CA',
    },
    93067: {
      lat: 34.42162,
      lng: -119.59277,
      city: 'Summerland',
      state: 'CA',
    },
    93101: {
      lat: 34.41925,
      lng: -119.70808,
      city: 'Santa Barbara',
      state: 'CA',
    },
    93103: {
      lat: 34.43888,
      lng: -119.68173,
      city: 'Santa Barbara',
      state: 'CA',
    },
    93105: {
      lat: 34.52702,
      lng: -119.76082,
      city: 'Santa Barbara',
      state: 'CA',
    },
    93106: {
      lat: 34.41719,
      lng: -119.85111,
      city: 'Santa Barbara',
      state: 'CA',
    },
    93108: {
      lat: 34.44934,
      lng: -119.60397,
      city: 'Santa Barbara',
      state: 'CA',
    },
    93109: {
      lat: 34.40662,
      lng: -119.72682,
      city: 'Santa Barbara',
      state: 'CA',
    },
    93110: {
      lat: 34.43913,
      lng: -119.7666,
      city: 'Santa Barbara',
      state: 'CA',
    },
    93111: {
      lat: 34.44932,
      lng: -119.80223,
      city: 'Santa Barbara',
      state: 'CA',
    },
    93117: {
      lat: 34.49102,
      lng: -120.08201,
      city: 'Goleta',
      state: 'CA',
    },
    93201: {
      lat: 35.8555,
      lng: -119.48338,
      city: 'Alpaugh',
      state: 'CA',
    },
    93202: {
      lat: 36.3187,
      lng: -119.7076,
      city: 'Armona',
      state: 'CA',
    },
    93203: {
      lat: 35.12158,
      lng: -118.8468,
      city: 'Arvin',
      state: 'CA',
    },
    93204: {
      lat: 35.90819,
      lng: -120.06776,
      city: 'Avenal',
      state: 'CA',
    },
    93205: {
      lat: 35.5387,
      lng: -118.45463,
      city: 'Bodfish',
      state: 'CA',
    },
    93206: {
      lat: 35.44402,
      lng: -119.47578,
      city: 'Buttonwillow',
      state: 'CA',
    },
    93207: {
      lat: 35.88721,
      lng: -118.63153,
      city: 'California Hot Springs',
      state: 'CA',
    },
    93208: {
      lat: 36.1298,
      lng: -118.63982,
      city: 'Camp Nelson',
      state: 'CA',
    },
    93210: {
      lat: 36.19519,
      lng: -120.43245,
      city: 'Coalinga',
      state: 'CA',
    },
    93212: {
      lat: 36.01649,
      lng: -119.56937,
      city: 'Corcoran',
      state: 'CA',
    },
    93215: {
      lat: 35.7764,
      lng: -119.18835,
      city: 'Delano',
      state: 'CA',
    },
    93218: {
      lat: 35.86194,
      lng: -119.03822,
      city: 'Ducor',
      state: 'CA',
    },
    93219: {
      lat: 35.87908,
      lng: -119.28818,
      city: 'Earlimart',
      state: 'CA',
    },
    93220: {
      lat: 35.3705,
      lng: -118.78369,
      city: 'Edison',
      state: 'CA',
    },
    93221: {
      lat: 36.29637,
      lng: -119.02992,
      city: 'Exeter',
      state: 'CA',
    },
    93222: {
      lat: 34.89835,
      lng: -119.25983,
      city: 'Pine Mountain Club',
      state: 'CA',
    },
    93223: {
      lat: 36.30117,
      lng: -119.20789,
      city: 'Farmersville',
      state: 'CA',
    },
    93224: {
      lat: 35.21687,
      lng: -119.58087,
      city: 'Fellows',
      state: 'CA',
    },
    93225: {
      lat: 34.78532,
      lng: -119.04196,
      city: 'Frazier Park',
      state: 'CA',
    },
    93226: {
      lat: 35.7476,
      lng: -118.73462,
      city: 'Glennville',
      state: 'CA',
    },
    93227: {
      lat: 36.34441,
      lng: -119.41856,
      city: 'Goshen',
      state: 'CA',
    },
    93230: {
      lat: 36.28967,
      lng: -119.62514,
      city: 'Hanford',
      state: 'CA',
    },
    93234: {
      lat: 36.19608,
      lng: -120.08477,
      city: 'Huron',
      state: 'CA',
    },
    93235: {
      lat: 36.38507,
      lng: -119.22314,
      city: 'Ivanhoe',
      state: 'CA',
    },
    93237: {
      lat: 36.47452,
      lng: -118.90426,
      city: 'Kaweah',
      state: 'CA',
    },
    93238: {
      lat: 35.82799,
      lng: -118.47137,
      city: 'Kernville',
      state: 'CA',
    },
    93239: {
      lat: 36.03293,
      lng: -119.98645,
      city: 'Kettleman City',
      state: 'CA',
    },
    93240: {
      lat: 35.62425,
      lng: -118.4645,
      city: 'Lake Isabella',
      state: 'CA',
    },
    93241: {
      lat: 35.24922,
      lng: -118.91224,
      city: 'Lamont',
      state: 'CA',
    },
    93242: {
      lat: 36.44115,
      lng: -119.73024,
      city: 'Laton',
      state: 'CA',
    },
    93243: {
      lat: 34.84057,
      lng: -118.81769,
      city: 'Lebec',
      state: 'CA',
    },
    93244: {
      lat: 36.3908,
      lng: -118.99733,
      city: 'Lemon Cove',
      state: 'CA',
    },
    93245: {
      lat: 36.28614,
      lng: -119.83405,
      city: 'Lemoore',
      state: 'CA',
    },
    93246: {
      lat: 36.31921,
      lng: -119.93135,
      city: 'Lemoore',
      state: 'CA',
    },
    93247: {
      lat: 36.20234,
      lng: -119.08277,
      city: 'Lindsay',
      state: 'CA',
    },
    93249: {
      lat: 35.67033,
      lng: -119.85814,
      city: 'Lost Hills',
      state: 'CA',
    },
    93250: {
      lat: 35.66456,
      lng: -119.20278,
      city: 'McFarland',
      state: 'CA',
    },
    93251: {
      lat: 35.37059,
      lng: -119.69735,
      city: 'McKittrick',
      state: 'CA',
    },
    93252: {
      lat: 34.80685,
      lng: -119.34664,
      city: 'Maricopa',
      state: 'CA',
    },
    93254: {
      lat: 34.92498,
      lng: -119.74834,
      city: 'New Cuyama',
      state: 'CA',
    },
    93255: { lat: 35.68292, lng: -118.0798, city: 'Onyx', state: 'CA' },
    93256: {
      lat: 35.96805,
      lng: -119.31251,
      city: 'Pixley',
      state: 'CA',
    },
    93257: {
      lat: 35.98708,
      lng: -118.89493,
      city: 'Porterville',
      state: 'CA',
    },
    93258: {
      lat: 36.05833,
      lng: -119.15209,
      city: 'Porterville',
      state: 'CA',
    },
    93260: {
      lat: 35.81909,
      lng: -118.66116,
      city: 'Posey',
      state: 'CA',
    },
    93261: {
      lat: 35.81147,
      lng: -119.11673,
      city: 'Richgrove',
      state: 'CA',
    },
    93262: {
      lat: 36.50256,
      lng: -118.68109,
      city: 'Sequoia National Park',
      state: 'CA',
    },
    93263: {
      lat: 35.48722,
      lng: -119.27835,
      city: 'Shafter',
      state: 'CA',
    },
    93265: {
      lat: 36.20713,
      lng: -118.70876,
      city: 'Springville',
      state: 'CA',
    },
    93266: {
      lat: 36.17176,
      lng: -119.85628,
      city: 'Stratford',
      state: 'CA',
    },
    93267: {
      lat: 36.14279,
      lng: -119.0742,
      city: 'Strathmore',
      state: 'CA',
    },
    93268: {
      lat: 35.16725,
      lng: -119.40785,
      city: 'Taft',
      state: 'CA',
    },
    93270: {
      lat: 35.94703,
      lng: -119.05669,
      city: 'Terra Bella',
      state: 'CA',
    },
    93271: {
      lat: 36.43661,
      lng: -118.86903,
      city: 'Three Rivers',
      state: 'CA',
    },
    93272: {
      lat: 36.04818,
      lng: -119.34535,
      city: 'Tipton',
      state: 'CA',
    },
    93274: {
      lat: 36.17978,
      lng: -119.37949,
      city: 'Tulare',
      state: 'CA',
    },
    93276: {
      lat: 35.31756,
      lng: -119.4092,
      city: 'Tupman',
      state: 'CA',
    },
    93277: {
      lat: 36.29963,
      lng: -119.38139,
      city: 'Visalia',
      state: 'CA',
    },
    93280: {
      lat: 35.65204,
      lng: -119.44716,
      city: 'Wasco',
      state: 'CA',
    },
    93283: {
      lat: 35.58478,
      lng: -118.28854,
      city: 'Weldon',
      state: 'CA',
    },
    93285: {
      lat: 35.72233,
      lng: -118.50871,
      city: 'Wofford Heights',
      state: 'CA',
    },
    93286: {
      lat: 36.45149,
      lng: -119.1079,
      city: 'Woodlake',
      state: 'CA',
    },
    93287: {
      lat: 35.72387,
      lng: -118.91958,
      city: 'Woody',
      state: 'CA',
    },
    93291: {
      lat: 36.38931,
      lng: -119.36838,
      city: 'Visalia',
      state: 'CA',
    },
    93292: {
      lat: 36.3763,
      lng: -119.22183,
      city: 'Visalia',
      state: 'CA',
    },
    93301: {
      lat: 35.38428,
      lng: -119.02057,
      city: 'Bakersfield',
      state: 'CA',
    },
    93304: {
      lat: 35.33938,
      lng: -119.02368,
      city: 'Bakersfield',
      state: 'CA',
    },
    93305: {
      lat: 35.38989,
      lng: -118.98515,
      city: 'Bakersfield',
      state: 'CA',
    },
    93306: {
      lat: 35.46595,
      lng: -118.78445,
      city: 'Bakersfield',
      state: 'CA',
    },
    93307: {
      lat: 35.2487,
      lng: -118.93253,
      city: 'Bakersfield',
      state: 'CA',
    },
    93308: {
      lat: 35.5498,
      lng: -118.93389,
      city: 'Bakersfield',
      state: 'CA',
    },
    93309: {
      lat: 35.34266,
      lng: -119.06461,
      city: 'Bakersfield',
      state: 'CA',
    },
    93311: {
      lat: 35.15378,
      lng: -119.16567,
      city: 'Bakersfield',
      state: 'CA',
    },
    93312: {
      lat: 35.39186,
      lng: -119.11954,
      city: 'Bakersfield',
      state: 'CA',
    },
    93313: {
      lat: 35.16363,
      lng: -119.04715,
      city: 'Bakersfield',
      state: 'CA',
    },
    93314: {
      lat: 35.385,
      lng: -119.23741,
      city: 'Bakersfield',
      state: 'CA',
    },
    93401: {
      lat: 35.23539,
      lng: -120.61834,
      city: 'San Luis Obispo',
      state: 'CA',
    },
    93402: {
      lat: 35.29082,
      lng: -120.8392,
      city: 'Los Osos',
      state: 'CA',
    },
    93405: {
      lat: 35.29672,
      lng: -120.72618,
      city: 'San Luis Obispo',
      state: 'CA',
    },
    93407: {
      lat: 35.30747,
      lng: -120.66986,
      city: 'San Luis Obispo',
      state: 'CA',
    },
    93408: {
      lat: 35.32117,
      lng: -120.71889,
      city: 'San Luis Obispo',
      state: 'CA',
    },
    93409: {
      lat: 35.32352,
      lng: -120.69743,
      city: 'San Luis Obispo',
      state: 'CA',
    },
    93410: {
      lat: 35.30428,
      lng: -120.65683,
      city: 'San Luis Obispo',
      state: 'CA',
    },
    93420: {
      lat: 35.16682,
      lng: -120.46617,
      city: 'Arroyo Grande',
      state: 'CA',
    },
    93422: {
      lat: 35.4638,
      lng: -120.69007,
      city: 'Atascadero',
      state: 'CA',
    },
    93424: {
      lat: 35.20141,
      lng: -120.78394,
      city: 'Avila Beach',
      state: 'CA',
    },
    93426: {
      lat: 35.84682,
      lng: -120.97233,
      city: 'Bradley',
      state: 'CA',
    },
    93427: {
      lat: 34.62724,
      lng: -120.22465,
      city: 'Buellton',
      state: 'CA',
    },
    93428: {
      lat: 35.58995,
      lng: -121.02943,
      city: 'Cambria',
      state: 'CA',
    },
    93429: {
      lat: 34.86682,
      lng: -120.53582,
      city: 'Casmalia',
      state: 'CA',
    },
    93430: {
      lat: 35.49214,
      lng: -120.92422,
      city: 'Cayucos',
      state: 'CA',
    },
    93432: {
      lat: 35.47686,
      lng: -120.47228,
      city: 'Creston',
      state: 'CA',
    },
    93433: {
      lat: 35.12045,
      lng: -120.61894,
      city: 'Grover Beach',
      state: 'CA',
    },
    93434: {
      lat: 34.94086,
      lng: -120.6113,
      city: 'Guadalupe',
      state: 'CA',
    },
    93436: {
      lat: 34.60888,
      lng: -120.4002,
      city: 'Lompoc',
      state: 'CA',
    },
    93437: {
      lat: 34.76049,
      lng: -120.51102,
      city: 'Lompoc',
      state: 'CA',
    },
    93440: {
      lat: 34.74606,
      lng: -120.25292,
      city: 'Los Alamos',
      state: 'CA',
    },
    93441: {
      lat: 34.73296,
      lng: -120.09721,
      city: 'Los Olivos',
      state: 'CA',
    },
    93442: {
      lat: 35.4,
      lng: -120.80495,
      city: 'Morro Bay',
      state: 'CA',
    },
    93444: {
      lat: 35.03553,
      lng: -120.50293,
      city: 'Nipomo',
      state: 'CA',
    },
    93445: {
      lat: 35.03319,
      lng: -120.62002,
      city: 'Oceano',
      state: 'CA',
    },
    93446: {
      lat: 35.65861,
      lng: -120.71888,
      city: 'Paso Robles',
      state: 'CA',
    },
    93449: {
      lat: 35.15803,
      lng: -120.6504,
      city: 'Pismo Beach',
      state: 'CA',
    },
    93450: {
      lat: 36.05791,
      lng: -120.82894,
      city: 'San Ardo',
      state: 'CA',
    },
    93451: {
      lat: 35.89398,
      lng: -120.56733,
      city: 'San Miguel',
      state: 'CA',
    },
    93452: {
      lat: 35.71078,
      lng: -121.20396,
      city: 'San Simeon',
      state: 'CA',
    },
    93453: {
      lat: 35.31339,
      lng: -120.1655,
      city: 'Santa Margarita',
      state: 'CA',
    },
    93454: {
      lat: 34.94834,
      lng: -120.23425,
      city: 'Santa Maria',
      state: 'CA',
    },
    93455: {
      lat: 34.84195,
      lng: -120.45522,
      city: 'Santa Maria',
      state: 'CA',
    },
    93458: {
      lat: 34.95477,
      lng: -120.49042,
      city: 'Santa Maria',
      state: 'CA',
    },
    93460: {
      lat: 34.66214,
      lng: -119.90091,
      city: 'Santa Ynez',
      state: 'CA',
    },
    93461: {
      lat: 35.63519,
      lng: -120.26951,
      city: 'Shandon',
      state: 'CA',
    },
    93463: {
      lat: 34.60688,
      lng: -120.13495,
      city: 'Solvang',
      state: 'CA',
    },
    93465: {
      lat: 35.54267,
      lng: -120.73417,
      city: 'Templeton',
      state: 'CA',
    },
    93501: {
      lat: 35.0411,
      lng: -118.22922,
      city: 'Mojave',
      state: 'CA',
    },
    93505: {
      lat: 35.15708,
      lng: -117.90934,
      city: 'California City',
      state: 'CA',
    },
    93510: {
      lat: 34.45627,
      lng: -118.2109,
      city: 'Acton',
      state: 'CA',
    },
    93512: {
      lat: 37.87379,
      lng: -118.53455,
      city: 'Benton',
      state: 'CA',
    },
    93513: {
      lat: 37.18401,
      lng: -118.17129,
      city: 'Big Pine',
      state: 'CA',
    },
    93514: {
      lat: 37.4947,
      lng: -118.38514,
      city: 'Bishop',
      state: 'CA',
    },
    93516: {
      lat: 35.0081,
      lng: -117.64268,
      city: 'Boron',
      state: 'CA',
    },
    93517: {
      lat: 38.27091,
      lng: -119.27372,
      city: 'Bridgeport',
      state: 'CA',
    },
    93518: {
      lat: 35.37675,
      lng: -118.47314,
      city: 'Caliente',
      state: 'CA',
    },
    93519: {
      lat: 35.3171,
      lng: -117.95596,
      city: 'Cantil',
      state: 'CA',
    },
    93522: {
      lat: 36.29836,
      lng: -117.59681,
      city: 'Darwin',
      state: 'CA',
    },
    93523: {
      lat: 34.99788,
      lng: -117.88308,
      city: 'Edwards',
      state: 'CA',
    },
    93524: {
      lat: 34.90993,
      lng: -117.85007,
      city: 'Edwards',
      state: 'CA',
    },
    93526: {
      lat: 36.85941,
      lng: -118.25378,
      city: 'Independence',
      state: 'CA',
    },
    93527: {
      lat: 35.80294,
      lng: -117.98504,
      city: 'Inyokern',
      state: 'CA',
    },
    93528: {
      lat: 35.37194,
      lng: -117.64193,
      city: 'Johannesburg',
      state: 'CA',
    },
    93529: {
      lat: 37.7832,
      lng: -119.1375,
      city: 'June Lake',
      state: 'CA',
    },
    93530: {
      lat: 36.48458,
      lng: -117.87201,
      city: 'Keeler',
      state: 'CA',
    },
    93531: {
      lat: 35.22937,
      lng: -118.60781,
      city: 'Keene',
      state: 'CA',
    },
    93532: {
      lat: 34.68571,
      lng: -118.55914,
      city: 'Lake Hughes',
      state: 'CA',
    },
    93534: {
      lat: 34.71759,
      lng: -118.15079,
      city: 'Lancaster',
      state: 'CA',
    },
    93535: {
      lat: 34.69908,
      lng: -117.89613,
      city: 'Lancaster',
      state: 'CA',
    },
    93536: {
      lat: 34.75267,
      lng: -118.3889,
      city: 'Lancaster',
      state: 'CA',
    },
    93541: {
      lat: 37.99678,
      lng: -119.01847,
      city: 'Lee Vining',
      state: 'CA',
    },
    93543: {
      lat: 34.47916,
      lng: -117.97357,
      city: 'Littlerock',
      state: 'CA',
    },
    93544: {
      lat: 34.49063,
      lng: -117.75505,
      city: 'Llano',
      state: 'CA',
    },
    93545: {
      lat: 36.6046,
      lng: -118.02721,
      city: 'Lone Pine',
      state: 'CA',
    },
    93546: {
      lat: 37.60573,
      lng: -118.85219,
      city: 'Mammoth Lakes',
      state: 'CA',
    },
    93549: {
      lat: 36.27728,
      lng: -117.96016,
      city: 'Olancha',
      state: 'CA',
    },
    93550: {
      lat: 34.38305,
      lng: -118.04926,
      city: 'Palmdale',
      state: 'CA',
    },
    93551: {
      lat: 34.59404,
      lng: -118.22634,
      city: 'Palmdale',
      state: 'CA',
    },
    93552: {
      lat: 34.58412,
      lng: -118.02386,
      city: 'Palmdale',
      state: 'CA',
    },
    93553: {
      lat: 34.44515,
      lng: -117.89611,
      city: 'Pearblossom',
      state: 'CA',
    },
    93554: {
      lat: 35.36914,
      lng: -117.75876,
      city: 'Randsburg',
      state: 'CA',
    },
    93555: {
      lat: 35.58596,
      lng: -117.68446,
      city: 'Ridgecrest',
      state: 'CA',
    },
    93558: {
      lat: 35.33945,
      lng: -117.58517,
      city: 'Red Mountain',
      state: 'CA',
    },
    93560: {
      lat: 34.87116,
      lng: -118.32594,
      city: 'Rosamond',
      state: 'CA',
    },
    93561: {
      lat: 35.10904,
      lng: -118.52016,
      city: 'Tehachapi',
      state: 'CA',
    },
    93562: {
      lat: 35.70667,
      lng: -117.34263,
      city: 'Trona',
      state: 'CA',
    },
    93563: {
      lat: 34.38662,
      lng: -117.83646,
      city: 'Valyermo',
      state: 'CA',
    },
    93581: {
      lat: 35.11288,
      lng: -118.57035,
      city: 'Tehachapi',
      state: 'CA',
    },
    93591: {
      lat: 34.59656,
      lng: -117.80955,
      city: 'Palmdale',
      state: 'CA',
    },
    93601: {
      lat: 37.39395,
      lng: -119.73702,
      city: 'Ahwahnee',
      state: 'CA',
    },
    93602: {
      lat: 37.0644,
      lng: -119.37484,
      city: 'Auberry',
      state: 'CA',
    },
    93603: {
      lat: 36.55118,
      lng: -118.99607,
      city: 'Badger',
      state: 'CA',
    },
    93604: {
      lat: 37.3717,
      lng: -119.52215,
      city: 'Bass Lake',
      state: 'CA',
    },
    93605: {
      lat: 37.17622,
      lng: -119.24953,
      city: 'Big Creek',
      state: 'CA',
    },
    93606: {
      lat: 36.79871,
      lng: -120.01625,
      city: 'Biola',
      state: 'CA',
    },
    93608: {
      lat: 36.5055,
      lng: -120.3418,
      city: 'Cantua Creek',
      state: 'CA',
    },
    93609: {
      lat: 36.52616,
      lng: -119.86388,
      city: 'Caruthers',
      state: 'CA',
    },
    93610: {
      lat: 37.09047,
      lng: -120.29036,
      city: 'Chowchilla',
      state: 'CA',
    },
    93611: {
      lat: 36.82552,
      lng: -119.68115,
      city: 'Clovis',
      state: 'CA',
    },
    93612: {
      lat: 36.81548,
      lng: -119.71076,
      city: 'Clovis',
      state: 'CA',
    },
    93614: {
      lat: 37.21282,
      lng: -119.72659,
      city: 'Coarsegold',
      state: 'CA',
    },
    93615: {
      lat: 36.50374,
      lng: -119.28385,
      city: 'Cutler',
      state: 'CA',
    },
    93616: {
      lat: 36.65468,
      lng: -119.59437,
      city: 'Del Rey',
      state: 'CA',
    },
    93618: {
      lat: 36.52213,
      lng: -119.38661,
      city: 'Dinuba',
      state: 'CA',
    },
    93619: {
      lat: 36.91656,
      lng: -119.57372,
      city: 'Clovis',
      state: 'CA',
    },
    93620: {
      lat: 37.0448,
      lng: -120.6223,
      city: 'Dos Palos',
      state: 'CA',
    },
    93621: {
      lat: 36.8067,
      lng: -119.13602,
      city: 'Dunlap',
      state: 'CA',
    },
    93622: {
      lat: 36.79283,
      lng: -120.62434,
      city: 'Firebaugh',
      state: 'CA',
    },
    93623: {
      lat: 37.47478,
      lng: -119.66071,
      city: 'Fish Camp',
      state: 'CA',
    },
    93624: {
      lat: 36.37258,
      lng: -120.12125,
      city: 'Five Points',
      state: 'CA',
    },
    93625: {
      lat: 36.62423,
      lng: -119.67173,
      city: 'Fowler',
      state: 'CA',
    },
    93626: {
      lat: 37.03278,
      lng: -119.68601,
      city: 'Friant',
      state: 'CA',
    },
    93627: {
      lat: 36.51568,
      lng: -120.10635,
      city: 'Helm',
      state: 'CA',
    },
    93628: {
      lat: 36.78149,
      lng: -118.83839,
      city: 'Hume',
      state: 'CA',
    },
    93630: {
      lat: 36.71831,
      lng: -120.12912,
      city: 'Kerman',
      state: 'CA',
    },
    93631: {
      lat: 36.47653,
      lng: -119.52131,
      city: 'Kingsburg',
      state: 'CA',
    },
    93633: {
      lat: 36.72912,
      lng: -118.95627,
      city: 'Kings Canyon National Pk',
      state: 'CA',
    },
    93634: {
      lat: 37.20583,
      lng: -118.93652,
      city: 'Lakeshore',
      state: 'CA',
    },
    93635: {
      lat: 37.016,
      lng: -120.88294,
      city: 'Los Banos',
      state: 'CA',
    },
    93636: {
      lat: 36.98171,
      lng: -119.87706,
      city: 'Madera',
      state: 'CA',
    },
    93637: {
      lat: 36.91099,
      lng: -120.1922,
      city: 'Madera',
      state: 'CA',
    },
    93638: {
      lat: 37.0424,
      lng: -120.03816,
      city: 'Madera',
      state: 'CA',
    },
    93640: {
      lat: 36.67215,
      lng: -120.43778,
      city: 'Mendota',
      state: 'CA',
    },
    93641: {
      lat: 36.70481,
      lng: -119.02167,
      city: 'Miramonte',
      state: 'CA',
    },
    93643: {
      lat: 37.27155,
      lng: -119.45004,
      city: 'North Fork',
      state: 'CA',
    },
    93644: {
      lat: 37.41245,
      lng: -119.59431,
      city: 'Oakhurst',
      state: 'CA',
    },
    93645: {
      lat: 37.1641,
      lng: -119.61824,
      city: "O'Neals",
      state: 'CA',
    },
    93646: {
      lat: 36.63218,
      lng: -119.28938,
      city: 'Orange Cove',
      state: 'CA',
    },
    93647: {
      lat: 36.58449,
      lng: -119.17773,
      city: 'Orosi',
      state: 'CA',
    },
    93648: {
      lat: 36.6206,
      lng: -119.52062,
      city: 'Parlier',
      state: 'CA',
    },
    93650: {
      lat: 36.84026,
      lng: -119.80038,
      city: 'Fresno',
      state: 'CA',
    },
    93651: {
      lat: 37.00296,
      lng: -119.521,
      city: 'Prather',
      state: 'CA',
    },
    93652: {
      lat: 36.59502,
      lng: -119.90458,
      city: 'Raisin City',
      state: 'CA',
    },
    93653: {
      lat: 37.24769,
      lng: -119.91882,
      city: 'Raymond',
      state: 'CA',
    },
    93654: {
      lat: 36.65747,
      lng: -119.402,
      city: 'Reedley',
      state: 'CA',
    },
    93656: {
      lat: 36.45173,
      lng: -119.93615,
      city: 'Riverdale',
      state: 'CA',
    },
    93657: {
      lat: 36.78853,
      lng: -119.4378,
      city: 'Sanger',
      state: 'CA',
    },
    93660: {
      lat: 36.60932,
      lng: -120.16514,
      city: 'San Joaquin',
      state: 'CA',
    },
    93662: {
      lat: 36.53963,
      lng: -119.64287,
      city: 'Selma',
      state: 'CA',
    },
    93664: {
      lat: 37.05671,
      lng: -119.17981,
      city: 'Shaver Lake',
      state: 'CA',
    },
    93665: {
      lat: 36.96334,
      lng: -120.65034,
      city: 'South Dos Palos',
      state: 'CA',
    },
    93666: {
      lat: 36.54623,
      lng: -119.33629,
      city: 'Sultana',
      state: 'CA',
    },
    93667: {
      lat: 36.96585,
      lng: -119.31511,
      city: 'Tollhouse',
      state: 'CA',
    },
    93668: {
      lat: 36.65663,
      lng: -120.28997,
      city: 'Tranquillity',
      state: 'CA',
    },
    93669: {
      lat: 37.28334,
      lng: -119.54297,
      city: 'Wishon',
      state: 'CA',
    },
    93670: {
      lat: 36.48564,
      lng: -119.25442,
      city: 'Yettem',
      state: 'CA',
    },
    93673: {
      lat: 36.45229,
      lng: -119.48232,
      city: 'Traver',
      state: 'CA',
    },
    93675: {
      lat: 36.73251,
      lng: -119.19285,
      city: 'Squaw Valley',
      state: 'CA',
    },
    93701: {
      lat: 36.74955,
      lng: -119.78772,
      city: 'Fresno',
      state: 'CA',
    },
    93702: {
      lat: 36.73942,
      lng: -119.75422,
      city: 'Fresno',
      state: 'CA',
    },
    93703: {
      lat: 36.76884,
      lng: -119.76249,
      city: 'Fresno',
      state: 'CA',
    },
    93704: {
      lat: 36.80012,
      lng: -119.80202,
      city: 'Fresno',
      state: 'CA',
    },
    93705: {
      lat: 36.78667,
      lng: -119.82851,
      city: 'Fresno',
      state: 'CA',
    },
    93706: {
      lat: 36.65344,
      lng: -119.90216,
      city: 'Fresno',
      state: 'CA',
    },
    93710: {
      lat: 36.82335,
      lng: -119.76251,
      city: 'Fresno',
      state: 'CA',
    },
    93711: {
      lat: 36.83508,
      lng: -119.83309,
      city: 'Fresno',
      state: 'CA',
    },
    93720: {
      lat: 36.8606,
      lng: -119.76228,
      city: 'Fresno',
      state: 'CA',
    },
    93721: {
      lat: 36.73282,
      lng: -119.78383,
      city: 'Fresno',
      state: 'CA',
    },
    93722: {
      lat: 36.80003,
      lng: -119.87791,
      city: 'Fresno',
      state: 'CA',
    },
    93723: {
      lat: 36.78693,
      lng: -119.95271,
      city: 'Fresno',
      state: 'CA',
    },
    93725: {
      lat: 36.62667,
      lng: -119.73501,
      city: 'Fresno',
      state: 'CA',
    },
    93726: {
      lat: 36.79374,
      lng: -119.76161,
      city: 'Fresno',
      state: 'CA',
    },
    93727: {
      lat: 36.75119,
      lng: -119.69736,
      city: 'Fresno',
      state: 'CA',
    },
    93728: {
      lat: 36.75729,
      lng: -119.81816,
      city: 'Fresno',
      state: 'CA',
    },
    93730: {
      lat: 36.90354,
      lng: -119.75512,
      city: 'Fresno',
      state: 'CA',
    },
    93737: {
      lat: 36.75358,
      lng: -119.6456,
      city: 'Fresno',
      state: 'CA',
    },
    93740: {
      lat: 36.81638,
      lng: -119.74613,
      city: 'Fresno',
      state: 'CA',
    },
    93741: {
      lat: 36.76742,
      lng: -119.79602,
      city: 'Fresno',
      state: 'CA',
    },
    93901: {
      lat: 36.64794,
      lng: -121.63044,
      city: 'Salinas',
      state: 'CA',
    },
    93905: {
      lat: 36.6849,
      lng: -121.60562,
      city: 'Salinas',
      state: 'CA',
    },
    93906: {
      lat: 36.72258,
      lng: -121.63218,
      city: 'Salinas',
      state: 'CA',
    },
    93907: {
      lat: 36.76643,
      lng: -121.66816,
      city: 'Salinas',
      state: 'CA',
    },
    93908: {
      lat: 36.62153,
      lng: -121.60048,
      city: 'Salinas',
      state: 'CA',
    },
    93920: {
      lat: 36.03164,
      lng: -121.52592,
      city: 'Big Sur',
      state: 'CA',
    },
    93921: {
      lat: 36.55359,
      lng: -121.92259,
      city: 'Carmel By The Sea',
      state: 'CA',
    },
    93923: {
      lat: 36.40394,
      lng: -121.79867,
      city: 'Carmel',
      state: 'CA',
    },
    93924: {
      lat: 36.39489,
      lng: -121.63406,
      city: 'Carmel Valley',
      state: 'CA',
    },
    93925: {
      lat: 36.60209,
      lng: -121.42526,
      city: 'Chualar',
      state: 'CA',
    },
    93926: {
      lat: 36.52567,
      lng: -121.3991,
      city: 'Gonzales',
      state: 'CA',
    },
    93927: {
      lat: 36.26978,
      lng: -121.38018,
      city: 'Greenfield',
      state: 'CA',
    },
    93928: {
      lat: 36.06484,
      lng: -121.38102,
      city: 'Jolon',
      state: 'CA',
    },
    93930: {
      lat: 36.19924,
      lng: -121.07282,
      city: 'King City',
      state: 'CA',
    },
    93932: {
      lat: 36.00143,
      lng: -121.05757,
      city: 'Lockwood',
      state: 'CA',
    },
    93933: {
      lat: 36.67566,
      lng: -121.78237,
      city: 'Marina',
      state: 'CA',
    },
    93940: {
      lat: 36.45294,
      lng: -121.8498,
      city: 'Monterey',
      state: 'CA',
    },
    93943: {
      lat: 36.59736,
      lng: -121.87332,
      city: 'Monterey',
      state: 'CA',
    },
    93944: {
      lat: 36.60058,
      lng: -121.91858,
      city: 'Monterey',
      state: 'CA',
    },
    93950: {
      lat: 36.61899,
      lng: -121.92575,
      city: 'Pacific Grove',
      state: 'CA',
    },
    93953: {
      lat: 36.58566,
      lng: -121.94329,
      city: 'Pebble Beach',
      state: 'CA',
    },
    93954: {
      lat: 36.16434,
      lng: -120.92441,
      city: 'San Lucas',
      state: 'CA',
    },
    93955: {
      lat: 36.62153,
      lng: -121.79562,
      city: 'Seaside',
      state: 'CA',
    },
    93960: {
      lat: 36.40489,
      lng: -121.32121,
      city: 'Soledad',
      state: 'CA',
    },
    93962: {
      lat: 36.62472,
      lng: -121.64649,
      city: 'Spreckels',
      state: 'CA',
    },
    94002: {
      lat: 37.51366,
      lng: -122.29853,
      city: 'Belmont',
      state: 'CA',
    },
    94005: {
      lat: 37.6882,
      lng: -122.40873,
      city: 'Brisbane',
      state: 'CA',
    },
    94010: {
      lat: 37.57306,
      lng: -122.37828,
      city: 'Burlingame',
      state: 'CA',
    },
    94014: {
      lat: 37.6907,
      lng: -122.44769,
      city: 'Daly City',
      state: 'CA',
    },
    94015: {
      lat: 37.68121,
      lng: -122.48074,
      city: 'Daly City',
      state: 'CA',
    },
    94018: {
      lat: 37.50603,
      lng: -122.47321,
      city: 'El Granada',
      state: 'CA',
    },
    94019: {
      lat: 37.44826,
      lng: -122.4023,
      city: 'Half Moon Bay',
      state: 'CA',
    },
    94020: {
      lat: 37.28473,
      lng: -122.21969,
      city: 'La Honda',
      state: 'CA',
    },
    94021: {
      lat: 37.25028,
      lng: -122.24958,
      city: 'Loma Mar',
      state: 'CA',
    },
    94022: {
      lat: 37.35534,
      lng: -122.14494,
      city: 'Los Altos',
      state: 'CA',
    },
    94024: {
      lat: 37.35037,
      lng: -122.09863,
      city: 'Los Altos',
      state: 'CA',
    },
    94025: {
      lat: 37.45087,
      lng: -122.1829,
      city: 'Menlo Park',
      state: 'CA',
    },
    94027: {
      lat: 37.45399,
      lng: -122.20313,
      city: 'Atherton',
      state: 'CA',
    },
    94028: {
      lat: 37.37492,
      lng: -122.21136,
      city: 'Portola Valley',
      state: 'CA',
    },
    94030: {
      lat: 37.59969,
      lng: -122.40343,
      city: 'Millbrae',
      state: 'CA',
    },
    94037: {
      lat: 37.55327,
      lng: -122.49584,
      city: 'Montara',
      state: 'CA',
    },
    94038: {
      lat: 37.52946,
      lng: -122.4849,
      city: 'Moss Beach',
      state: 'CA',
    },
    94040: {
      lat: 37.38034,
      lng: -122.08531,
      city: 'Mountain View',
      state: 'CA',
    },
    94041: {
      lat: 37.38822,
      lng: -122.07529,
      city: 'Mountain View',
      state: 'CA',
    },
    94043: {
      lat: 37.41188,
      lng: -122.06892,
      city: 'Mountain View',
      state: 'CA',
    },
    94044: {
      lat: 37.60544,
      lng: -122.47993,
      city: 'Pacifica',
      state: 'CA',
    },
    94060: {
      lat: 37.22297,
      lng: -122.33572,
      city: 'Pescadero',
      state: 'CA',
    },
    94061: {
      lat: 37.46136,
      lng: -122.23731,
      city: 'Redwood City',
      state: 'CA',
    },
    94062: {
      lat: 37.42562,
      lng: -122.29653,
      city: 'Redwood City',
      state: 'CA',
    },
    94063: {
      lat: 37.49367,
      lng: -122.20823,
      city: 'Redwood City',
      state: 'CA',
    },
    94065: {
      lat: 37.53504,
      lng: -122.24683,
      city: 'Redwood City',
      state: 'CA',
    },
    94066: {
      lat: 37.62539,
      lng: -122.43229,
      city: 'San Bruno',
      state: 'CA',
    },
    94070: {
      lat: 37.49739,
      lng: -122.27021,
      city: 'San Carlos',
      state: 'CA',
    },
    94074: {
      lat: 37.32817,
      lng: -122.34164,
      city: 'San Gregorio',
      state: 'CA',
    },
    94080: {
      lat: 37.65541,
      lng: -122.42083,
      city: 'South San Francisco',
      state: 'CA',
    },
    94085: {
      lat: 37.38862,
      lng: -122.01793,
      city: 'Sunnyvale',
      state: 'CA',
    },
    94086: {
      lat: 37.37164,
      lng: -122.02316,
      city: 'Sunnyvale',
      state: 'CA',
    },
    94087: {
      lat: 37.35164,
      lng: -122.03669,
      city: 'Sunnyvale',
      state: 'CA',
    },
    94089: {
      lat: 37.41192,
      lng: -122.01441,
      city: 'Sunnyvale',
      state: 'CA',
    },
    94102: {
      lat: 37.77956,
      lng: -122.41931,
      city: 'San Francisco',
      state: 'CA',
    },
    94103: {
      lat: 37.77299,
      lng: -122.41136,
      city: 'San Francisco',
      state: 'CA',
    },
    94104: {
      lat: 37.79144,
      lng: -122.40211,
      city: 'San Francisco',
      state: 'CA',
    },
    94105: {
      lat: 37.79239,
      lng: -122.39707,
      city: 'San Francisco',
      state: 'CA',
    },
    94107: {
      lat: 37.76473,
      lng: -122.39508,
      city: 'San Francisco',
      state: 'CA',
    },
    94108: {
      lat: 37.79189,
      lng: -122.40854,
      city: 'San Francisco',
      state: 'CA',
    },
    94109: {
      lat: 37.79334,
      lng: -122.42138,
      city: 'San Francisco',
      state: 'CA',
    },
    94110: {
      lat: 37.74975,
      lng: -122.41528,
      city: 'San Francisco',
      state: 'CA',
    },
    94111: {
      lat: 37.79847,
      lng: -122.3998,
      city: 'San Francisco',
      state: 'CA',
    },
    94112: {
      lat: 37.7206,
      lng: -122.44311,
      city: 'San Francisco',
      state: 'CA',
    },
    94114: {
      lat: 37.75814,
      lng: -122.43547,
      city: 'San Francisco',
      state: 'CA',
    },
    94115: {
      lat: 37.78608,
      lng: -122.43742,
      city: 'San Francisco',
      state: 'CA',
    },
    94116: {
      lat: 37.74457,
      lng: -122.48611,
      city: 'San Francisco',
      state: 'CA',
    },
    94117: {
      lat: 37.77002,
      lng: -122.44425,
      city: 'San Francisco',
      state: 'CA',
    },
    94118: {
      lat: 37.7801,
      lng: -122.46185,
      city: 'San Francisco',
      state: 'CA',
    },
    94121: {
      lat: 37.77849,
      lng: -122.49548,
      city: 'San Francisco',
      state: 'CA',
    },
    94122: {
      lat: 37.76113,
      lng: -122.48433,
      city: 'San Francisco',
      state: 'CA',
    },
    94123: {
      lat: 37.80112,
      lng: -122.43645,
      city: 'San Francisco',
      state: 'CA',
    },
    94124: {
      lat: 37.73204,
      lng: -122.3858,
      city: 'San Francisco',
      state: 'CA',
    },
    94127: {
      lat: 37.73642,
      lng: -122.45961,
      city: 'San Francisco',
      state: 'CA',
    },
    94128: {
      lat: 37.62197,
      lng: -122.38388,
      city: 'San Francisco',
      state: 'CA',
    },
    94129: {
      lat: 37.79743,
      lng: -122.46662,
      city: 'San Francisco',
      state: 'CA',
    },
    94130: {
      lat: 37.81975,
      lng: -122.36898,
      city: 'San Francisco',
      state: 'CA',
    },
    94131: {
      lat: 37.74569,
      lng: -122.44243,
      city: 'San Francisco',
      state: 'CA',
    },
    94132: {
      lat: 37.72219,
      lng: -122.48475,
      city: 'San Francisco',
      state: 'CA',
    },
    94133: {
      lat: 37.80193,
      lng: -122.41092,
      city: 'San Francisco',
      state: 'CA',
    },
    94134: {
      lat: 37.71887,
      lng: -122.41124,
      city: 'San Francisco',
      state: 'CA',
    },
    94158: {
      lat: 37.77116,
      lng: -122.39153,
      city: 'San Francisco',
      state: 'CA',
    },
    94188: {
      lat: 37.7407,
      lng: -122.38091,
      city: 'San Francisco',
      state: 'CA',
    },
    94301: {
      lat: 37.44424,
      lng: -122.14992,
      city: 'Palo Alto',
      state: 'CA',
    },
    94303: {
      lat: 37.45064,
      lng: -122.11928,
      city: 'Palo Alto',
      state: 'CA',
    },
    94304: {
      lat: 37.39744,
      lng: -122.16699,
      city: 'Palo Alto',
      state: 'CA',
    },
    94305: {
      lat: 37.41623,
      lng: -122.17069,
      city: 'Stanford',
      state: 'CA',
    },
    94306: {
      lat: 37.41627,
      lng: -122.13088,
      city: 'Palo Alto',
      state: 'CA',
    },
    94401: {
      lat: 37.57445,
      lng: -122.31942,
      city: 'San Mateo',
      state: 'CA',
    },
    94402: {
      lat: 37.54035,
      lng: -122.33388,
      city: 'San Mateo',
      state: 'CA',
    },
    94403: {
      lat: 37.53844,
      lng: -122.30454,
      city: 'San Mateo',
      state: 'CA',
    },
    94404: {
      lat: 37.5562,
      lng: -122.26905,
      city: 'San Mateo',
      state: 'CA',
    },
    94501: {
      lat: 37.77525,
      lng: -122.27493,
      city: 'Alameda',
      state: 'CA',
    },
    94502: {
      lat: 37.73686,
      lng: -122.24114,
      city: 'Alameda',
      state: 'CA',
    },
    94503: {
      lat: 38.19454,
      lng: -122.23042,
      city: 'American Canyon',
      state: 'CA',
    },
    94505: {
      lat: 37.86638,
      lng: -121.5868,
      city: 'Discovery Bay',
      state: 'CA',
    },
    94506: {
      lat: 37.80862,
      lng: -121.90498,
      city: 'Danville',
      state: 'CA',
    },
    94507: {
      lat: 37.85429,
      lng: -122.00664,
      city: 'Alamo',
      state: 'CA',
    },
    94508: {
      lat: 38.57909,
      lng: -122.44451,
      city: 'Angwin',
      state: 'CA',
    },
    94509: {
      lat: 37.99214,
      lng: -121.8142,
      city: 'Antioch',
      state: 'CA',
    },
    94510: {
      lat: 38.09756,
      lng: -122.13189,
      city: 'Benicia',
      state: 'CA',
    },
    94511: {
      lat: 38.02883,
      lng: -121.64039,
      city: 'Bethel Island',
      state: 'CA',
    },
    94512: {
      lat: 38.1263,
      lng: -121.83793,
      city: 'Birds Landing',
      state: 'CA',
    },
    94513: {
      lat: 37.92541,
      lng: -121.68452,
      city: 'Brentwood',
      state: 'CA',
    },
    94514: {
      lat: 37.83071,
      lng: -121.63392,
      city: 'Byron',
      state: 'CA',
    },
    94515: {
      lat: 38.62449,
      lng: -122.62503,
      city: 'Calistoga',
      state: 'CA',
    },
    94516: {
      lat: 37.82748,
      lng: -122.16629,
      city: 'Canyon',
      state: 'CA',
    },
    94517: {
      lat: 37.8926,
      lng: -121.88252,
      city: 'Clayton',
      state: 'CA',
    },
    94518: {
      lat: 37.95052,
      lng: -122.02209,
      city: 'Concord',
      state: 'CA',
    },
    94519: {
      lat: 37.98881,
      lng: -122.01275,
      city: 'Concord',
      state: 'CA',
    },
    94520: {
      lat: 37.99344,
      lng: -122.03532,
      city: 'Concord',
      state: 'CA',
    },
    94521: {
      lat: 37.95293,
      lng: -121.96971,
      city: 'Concord',
      state: 'CA',
    },
    94523: {
      lat: 37.95403,
      lng: -122.07608,
      city: 'Pleasant Hill',
      state: 'CA',
    },
    94525: {
      lat: 38.04811,
      lng: -122.2292,
      city: 'Crockett',
      state: 'CA',
    },
    94526: {
      lat: 37.81472,
      lng: -121.98451,
      city: 'Danville',
      state: 'CA',
    },
    94528: {
      lat: 37.84149,
      lng: -121.95819,
      city: 'Diablo',
      state: 'CA',
    },
    94530: {
      lat: 37.92076,
      lng: -122.30068,
      city: 'El Cerrito',
      state: 'CA',
    },
    94531: {
      lat: 37.95665,
      lng: -121.77777,
      city: 'Antioch',
      state: 'CA',
    },
    94533: {
      lat: 38.28132,
      lng: -122.00693,
      city: 'Fairfield',
      state: 'CA',
    },
    94534: {
      lat: 38.24112,
      lng: -122.12142,
      city: 'Fairfield',
      state: 'CA',
    },
    94535: {
      lat: 38.27048,
      lng: -121.93851,
      city: 'Travis Afb',
      state: 'CA',
    },
    94536: {
      lat: 37.57072,
      lng: -121.98555,
      city: 'Fremont',
      state: 'CA',
    },
    94538: {
      lat: 37.50653,
      lng: -121.96361,
      city: 'Fremont',
      state: 'CA',
    },
    94539: {
      lat: 37.51635,
      lng: -121.9119,
      city: 'Fremont',
      state: 'CA',
    },
    94541: {
      lat: 37.67376,
      lng: -122.08707,
      city: 'Hayward',
      state: 'CA',
    },
    94542: {
      lat: 37.6553,
      lng: -122.02584,
      city: 'Hayward',
      state: 'CA',
    },
    94544: {
      lat: 37.63352,
      lng: -122.05678,
      city: 'Hayward',
      state: 'CA',
    },
    94545: {
      lat: 37.62957,
      lng: -122.12048,
      city: 'Hayward',
      state: 'CA',
    },
    94546: {
      lat: 37.73441,
      lng: -122.09056,
      city: 'Castro Valley',
      state: 'CA',
    },
    94547: {
      lat: 38.00637,
      lng: -122.26117,
      city: 'Hercules',
      state: 'CA',
    },
    94548: {
      lat: 37.97481,
      lng: -121.65265,
      city: 'Knightsen',
      state: 'CA',
    },
    94549: {
      lat: 37.90133,
      lng: -122.11933,
      city: 'Lafayette',
      state: 'CA',
    },
    94550: {
      lat: 37.51746,
      lng: -121.59406,
      city: 'Livermore',
      state: 'CA',
    },
    94551: {
      lat: 37.75902,
      lng: -121.76026,
      city: 'Livermore',
      state: 'CA',
    },
    94552: {
      lat: 37.70456,
      lng: -122.01359,
      city: 'Castro Valley',
      state: 'CA',
    },
    94553: {
      lat: 37.97999,
      lng: -122.15368,
      city: 'Martinez',
      state: 'CA',
    },
    94555: {
      lat: 37.55083,
      lng: -122.08043,
      city: 'Fremont',
      state: 'CA',
    },
    94556: {
      lat: 37.83102,
      lng: -122.10425,
      city: 'Moraga',
      state: 'CA',
    },
    94558: {
      lat: 38.50846,
      lng: -122.26497,
      city: 'Napa',
      state: 'CA',
    },
    94559: {
      lat: 38.25238,
      lng: -122.29382,
      city: 'Napa',
      state: 'CA',
    },
    94560: {
      lat: 37.50771,
      lng: -122.02523,
      city: 'Newark',
      state: 'CA',
    },
    94561: {
      lat: 37.99311,
      lng: -121.69172,
      city: 'Oakley',
      state: 'CA',
    },
    94562: {
      lat: 38.43071,
      lng: -122.40874,
      city: 'Oakville',
      state: 'CA',
    },
    94563: {
      lat: 37.88196,
      lng: -122.19419,
      city: 'Orinda',
      state: 'CA',
    },
    94564: {
      lat: 37.98896,
      lng: -122.27018,
      city: 'Pinole',
      state: 'CA',
    },
    94565: {
      lat: 38.00926,
      lng: -121.92886,
      city: 'Pittsburg',
      state: 'CA',
    },
    94566: {
      lat: 37.65032,
      lng: -121.85881,
      city: 'Pleasanton',
      state: 'CA',
    },
    94567: {
      lat: 38.66342,
      lng: -122.4349,
      city: 'Pope Valley',
      state: 'CA',
    },
    94568: {
      lat: 37.71596,
      lng: -121.90113,
      city: 'Dublin',
      state: 'CA',
    },
    94569: {
      lat: 38.04411,
      lng: -122.19201,
      city: 'Port Costa',
      state: 'CA',
    },
    94571: {
      lat: 38.1566,
      lng: -121.7482,
      city: 'Rio Vista',
      state: 'CA',
    },
    94572: {
      lat: 38.02392,
      lng: -122.23285,
      city: 'Rodeo',
      state: 'CA',
    },
    94573: {
      lat: 38.45726,
      lng: -122.42348,
      city: 'Rutherford',
      state: 'CA',
    },
    94574: {
      lat: 38.52742,
      lng: -122.39261,
      city: 'Saint Helena',
      state: 'CA',
    },
    94575: {
      lat: 37.84235,
      lng: -122.11012,
      city: 'Moraga',
      state: 'CA',
    },
    94576: {
      lat: 38.54932,
      lng: -122.47581,
      city: 'Deer Park',
      state: 'CA',
    },
    94577: {
      lat: 37.71595,
      lng: -122.16615,
      city: 'San Leandro',
      state: 'CA',
    },
    94578: {
      lat: 37.70675,
      lng: -122.1256,
      city: 'San Leandro',
      state: 'CA',
    },
    94579: {
      lat: 37.68656,
      lng: -122.15709,
      city: 'San Leandro',
      state: 'CA',
    },
    94580: {
      lat: 37.67803,
      lng: -122.13334,
      city: 'San Lorenzo',
      state: 'CA',
    },
    94582: {
      lat: 37.76357,
      lng: -121.91469,
      city: 'San Ramon',
      state: 'CA',
    },
    94583: {
      lat: 37.77245,
      lng: -121.99157,
      city: 'San Ramon',
      state: 'CA',
    },
    94585: {
      lat: 38.19061,
      lng: -121.94216,
      city: 'Suisun City',
      state: 'CA',
    },
    94586: {
      lat: 37.57327,
      lng: -121.85472,
      city: 'Sunol',
      state: 'CA',
    },
    94587: {
      lat: 37.59142,
      lng: -122.06993,
      city: 'Union City',
      state: 'CA',
    },
    94588: {
      lat: 37.73801,
      lng: -121.88178,
      city: 'Pleasanton',
      state: 'CA',
    },
    94589: {
      lat: 38.14162,
      lng: -122.24596,
      city: 'Vallejo',
      state: 'CA',
    },
    94590: {
      lat: 38.09546,
      lng: -122.24986,
      city: 'Vallejo',
      state: 'CA',
    },
    94591: {
      lat: 38.12702,
      lng: -122.19635,
      city: 'Vallejo',
      state: 'CA',
    },
    94592: {
      lat: 38.12009,
      lng: -122.3117,
      city: 'Vallejo',
      state: 'CA',
    },
    94595: {
      lat: 37.87206,
      lng: -122.06892,
      city: 'Walnut Creek',
      state: 'CA',
    },
    94596: {
      lat: 37.88902,
      lng: -122.03676,
      city: 'Walnut Creek',
      state: 'CA',
    },
    94597: {
      lat: 37.91768,
      lng: -122.0718,
      city: 'Walnut Creek',
      state: 'CA',
    },
    94598: {
      lat: 37.89238,
      lng: -121.98283,
      city: 'Walnut Creek',
      state: 'CA',
    },
    94599: {
      lat: 38.41012,
      lng: -122.37365,
      city: 'Yountville',
      state: 'CA',
    },
    94601: {
      lat: 37.77684,
      lng: -122.21822,
      city: 'Oakland',
      state: 'CA',
    },
    94602: {
      lat: 37.80517,
      lng: -122.20422,
      city: 'Oakland',
      state: 'CA',
    },
    94603: {
      lat: 37.73923,
      lng: -122.17554,
      city: 'Oakland',
      state: 'CA',
    },
    94605: {
      lat: 37.76062,
      lng: -122.14903,
      city: 'Oakland',
      state: 'CA',
    },
    94606: {
      lat: 37.79179,
      lng: -122.24455,
      city: 'Oakland',
      state: 'CA',
    },
    94607: {
      lat: 37.80721,
      lng: -122.30024,
      city: 'Oakland',
      state: 'CA',
    },
    94608: {
      lat: 37.83611,
      lng: -122.28621,
      city: 'Emeryville',
      state: 'CA',
    },
    94609: {
      lat: 37.83476,
      lng: -122.2642,
      city: 'Oakland',
      state: 'CA',
    },
    94610: {
      lat: 37.81229,
      lng: -122.24048,
      city: 'Oakland',
      state: 'CA',
    },
    94611: {
      lat: 37.83191,
      lng: -122.21592,
      city: 'Oakland',
      state: 'CA',
    },
    94612: {
      lat: 37.80789,
      lng: -122.2662,
      city: 'Oakland',
      state: 'CA',
    },
    94613: {
      lat: 37.78075,
      lng: -122.18308,
      city: 'Oakland',
      state: 'CA',
    },
    94618: {
      lat: 37.8442,
      lng: -122.23868,
      city: 'Oakland',
      state: 'CA',
    },
    94619: {
      lat: 37.79262,
      lng: -122.16001,
      city: 'Oakland',
      state: 'CA',
    },
    94621: {
      lat: 37.7379,
      lng: -122.20794,
      city: 'Oakland',
      state: 'CA',
    },
    94702: {
      lat: 37.86619,
      lng: -122.28634,
      city: 'Berkeley',
      state: 'CA',
    },
    94703: {
      lat: 37.86397,
      lng: -122.27573,
      city: 'Berkeley',
      state: 'CA',
    },
    94704: {
      lat: 37.8664,
      lng: -122.26088,
      city: 'Berkeley',
      state: 'CA',
    },
    94705: {
      lat: 37.86523,
      lng: -122.2384,
      city: 'Berkeley',
      state: 'CA',
    },
    94706: {
      lat: 37.88969,
      lng: -122.29581,
      city: 'Albany',
      state: 'CA',
    },
    94707: {
      lat: 37.89843,
      lng: -122.27917,
      city: 'Berkeley',
      state: 'CA',
    },
    94708: {
      lat: 37.90276,
      lng: -122.26202,
      city: 'Berkeley',
      state: 'CA',
    },
    94709: {
      lat: 37.87835,
      lng: -122.26613,
      city: 'Berkeley',
      state: 'CA',
    },
    94710: {
      lat: 37.86705,
      lng: -122.303,
      city: 'Berkeley',
      state: 'CA',
    },
    94720: {
      lat: 37.87185,
      lng: -122.25226,
      city: 'Berkeley',
      state: 'CA',
    },
    94801: {
      lat: 37.95217,
      lng: -122.38025,
      city: 'Richmond',
      state: 'CA',
    },
    94803: {
      lat: 37.95683,
      lng: -122.28235,
      city: 'El Sobrante',
      state: 'CA',
    },
    94804: {
      lat: 37.92074,
      lng: -122.34204,
      city: 'Richmond',
      state: 'CA',
    },
    94805: {
      lat: 37.94215,
      lng: -122.32364,
      city: 'Richmond',
      state: 'CA',
    },
    94806: {
      lat: 37.97811,
      lng: -122.33618,
      city: 'San Pablo',
      state: 'CA',
    },
    94901: {
      lat: 37.98053,
      lng: -122.50249,
      city: 'San Rafael',
      state: 'CA',
    },
    94903: {
      lat: 38.02485,
      lng: -122.5522,
      city: 'San Rafael',
      state: 'CA',
    },
    94904: {
      lat: 37.94841,
      lng: -122.55288,
      city: 'Greenbrae',
      state: 'CA',
    },
    94920: {
      lat: 37.88732,
      lng: -122.46549,
      city: 'Belvedere Tiburon',
      state: 'CA',
    },
    94922: {
      lat: 38.35683,
      lng: -122.95098,
      city: 'Bodega',
      state: 'CA',
    },
    94923: {
      lat: 38.33588,
      lng: -123.02647,
      city: 'Bodega Bay',
      state: 'CA',
    },
    94924: {
      lat: 37.95411,
      lng: -122.73171,
      city: 'Bolinas',
      state: 'CA',
    },
    94925: {
      lat: 37.92386,
      lng: -122.5132,
      city: 'Corte Madera',
      state: 'CA',
    },
    94928: {
      lat: 38.3459,
      lng: -122.6935,
      city: 'Rohnert Park',
      state: 'CA',
    },
    94929: {
      lat: 38.25444,
      lng: -122.96243,
      city: 'Dillon Beach',
      state: 'CA',
    },
    94930: {
      lat: 37.97048,
      lng: -122.61108,
      city: 'Fairfax',
      state: 'CA',
    },
    94931: {
      lat: 38.32229,
      lng: -122.71497,
      city: 'Cotati',
      state: 'CA',
    },
    94933: {
      lat: 38.01753,
      lng: -122.68814,
      city: 'Forest Knolls',
      state: 'CA',
    },
    94937: {
      lat: 38.09466,
      lng: -122.92719,
      city: 'Inverness',
      state: 'CA',
    },
    94938: {
      lat: 38.03024,
      lng: -122.72953,
      city: 'Lagunitas',
      state: 'CA',
    },
    94939: {
      lat: 37.93725,
      lng: -122.53518,
      city: 'Larkspur',
      state: 'CA',
    },
    94940: {
      lat: 38.15754,
      lng: -122.86376,
      city: 'Marshall',
      state: 'CA',
    },
    94941: {
      lat: 37.92562,
      lng: -122.60083,
      city: 'Mill Valley',
      state: 'CA',
    },
    94945: {
      lat: 38.1258,
      lng: -122.55443,
      city: 'Novato',
      state: 'CA',
    },
    94946: {
      lat: 38.07176,
      lng: -122.68785,
      city: 'Nicasio',
      state: 'CA',
    },
    94947: {
      lat: 38.10705,
      lng: -122.62813,
      city: 'Novato',
      state: 'CA',
    },
    94949: {
      lat: 38.06576,
      lng: -122.53172,
      city: 'Novato',
      state: 'CA',
    },
    94950: {
      lat: 38.02122,
      lng: -122.76187,
      city: 'Olema',
      state: 'CA',
    },
    94951: {
      lat: 38.32252,
      lng: -122.64492,
      city: 'Penngrove',
      state: 'CA',
    },
    94952: {
      lat: 38.23007,
      lng: -122.76338,
      city: 'Petaluma',
      state: 'CA',
    },
    94954: {
      lat: 38.24624,
      lng: -122.56627,
      city: 'Petaluma',
      state: 'CA',
    },
    94956: {
      lat: 38.0476,
      lng: -122.81046,
      city: 'Point Reyes Station',
      state: 'CA',
    },
    94957: {
      lat: 37.96343,
      lng: -122.56174,
      city: 'Ross',
      state: 'CA',
    },
    94960: {
      lat: 37.99707,
      lng: -122.57838,
      city: 'San Anselmo',
      state: 'CA',
    },
    94963: {
      lat: 38.00765,
      lng: -122.66484,
      city: 'San Geronimo',
      state: 'CA',
    },
    94964: {
      lat: 37.94235,
      lng: -122.49244,
      city: 'San Quentin',
      state: 'CA',
    },
    94965: {
      lat: 37.85263,
      lng: -122.52699,
      city: 'Sausalito',
      state: 'CA',
    },
    94970: {
      lat: 37.91694,
      lng: -122.65553,
      city: 'Stinson Beach',
      state: 'CA',
    },
    94971: {
      lat: 38.23724,
      lng: -122.89006,
      city: 'Tomales',
      state: 'CA',
    },
    94972: {
      lat: 38.33665,
      lng: -122.93934,
      city: 'Valley Ford',
      state: 'CA',
    },
    94973: {
      lat: 38.01259,
      lng: -122.63947,
      city: 'Woodacre',
      state: 'CA',
    },
    95002: {
      lat: 37.44075,
      lng: -122.00047,
      city: 'Alviso',
      state: 'CA',
    },
    95003: {
      lat: 37.0089,
      lng: -121.87775,
      city: 'Aptos',
      state: 'CA',
    },
    95004: {
      lat: 36.86818,
      lng: -121.63237,
      city: 'Aromas',
      state: 'CA',
    },
    95005: {
      lat: 37.09835,
      lng: -122.08931,
      city: 'Ben Lomond',
      state: 'CA',
    },
    95006: {
      lat: 37.17129,
      lng: -122.151,
      city: 'Boulder Creek',
      state: 'CA',
    },
    95007: {
      lat: 37.11019,
      lng: -122.11434,
      city: 'Brookdale',
      state: 'CA',
    },
    95008: {
      lat: 37.27977,
      lng: -121.95481,
      city: 'Campbell',
      state: 'CA',
    },
    95010: {
      lat: 36.97721,
      lng: -121.95466,
      city: 'Capitola',
      state: 'CA',
    },
    95012: {
      lat: 36.78373,
      lng: -121.74164,
      city: 'Castroville',
      state: 'CA',
    },
    95013: {
      lat: 37.21531,
      lng: -121.73962,
      city: 'Coyote',
      state: 'CA',
    },
    95014: {
      lat: 37.30827,
      lng: -122.07981,
      city: 'Cupertino',
      state: 'CA',
    },
    95017: {
      lat: 37.10529,
      lng: -122.22913,
      city: 'Davenport',
      state: 'CA',
    },
    95018: {
      lat: 37.08027,
      lng: -122.05824,
      city: 'Felton',
      state: 'CA',
    },
    95019: {
      lat: 36.93575,
      lng: -121.78458,
      city: 'Freedom',
      state: 'CA',
    },
    95020: {
      lat: 37.01461,
      lng: -121.55718,
      city: 'Gilroy',
      state: 'CA',
    },
    95023: {
      lat: 36.92113,
      lng: -121.36117,
      city: 'Hollister',
      state: 'CA',
    },
    95030: {
      lat: 37.22168,
      lng: -121.98326,
      city: 'Los Gatos',
      state: 'CA',
    },
    95032: {
      lat: 37.20859,
      lng: -121.92359,
      city: 'Los Gatos',
      state: 'CA',
    },
    95033: {
      lat: 37.15701,
      lng: -121.97771,
      city: 'Los Gatos',
      state: 'CA',
    },
    95035: {
      lat: 37.44319,
      lng: -121.87632,
      city: 'Milpitas',
      state: 'CA',
    },
    95037: {
      lat: 37.13213,
      lng: -121.67886,
      city: 'Morgan Hill',
      state: 'CA',
    },
    95039: {
      lat: 36.81515,
      lng: -121.7779,
      city: 'Moss Landing',
      state: 'CA',
    },
    95041: {
      lat: 37.05052,
      lng: -122.05828,
      city: 'Mount Hermon',
      state: 'CA',
    },
    95043: {
      lat: 36.51652,
      lng: -120.97677,
      city: 'Paicines',
      state: 'CA',
    },
    95045: {
      lat: 36.83281,
      lng: -121.52576,
      city: 'San Juan Bautista',
      state: 'CA',
    },
    95046: {
      lat: 37.09576,
      lng: -121.59649,
      city: 'San Martin',
      state: 'CA',
    },
    95050: {
      lat: 37.3504,
      lng: -121.95238,
      city: 'Santa Clara',
      state: 'CA',
    },
    95051: {
      lat: 37.3483,
      lng: -121.98444,
      city: 'Santa Clara',
      state: 'CA',
    },
    95053: {
      lat: 37.34848,
      lng: -121.93737,
      city: 'Santa Clara',
      state: 'CA',
    },
    95054: {
      lat: 37.39308,
      lng: -121.96483,
      city: 'Santa Clara',
      state: 'CA',
    },
    95060: {
      lat: 37.01352,
      lng: -122.10176,
      city: 'Santa Cruz',
      state: 'CA',
    },
    95062: {
      lat: 36.97279,
      lng: -121.99008,
      city: 'Santa Cruz',
      state: 'CA',
    },
    95064: {
      lat: 36.99158,
      lng: -122.05886,
      city: 'Santa Cruz',
      state: 'CA',
    },
    95065: {
      lat: 37.03229,
      lng: -121.98444,
      city: 'Santa Cruz',
      state: 'CA',
    },
    95066: {
      lat: 37.0672,
      lng: -122.01453,
      city: 'Scotts Valley',
      state: 'CA',
    },
    95070: {
      lat: 37.25624,
      lng: -122.05151,
      city: 'Saratoga',
      state: 'CA',
    },
    95073: {
      lat: 37.04068,
      lng: -121.93174,
      city: 'Soquel',
      state: 'CA',
    },
    95075: {
      lat: 36.80112,
      lng: -121.19258,
      city: 'Tres Pinos',
      state: 'CA',
    },
    95076: {
      lat: 36.94029,
      lng: -121.75089,
      city: 'Watsonville',
      state: 'CA',
    },
    95110: {
      lat: 37.34656,
      lng: -121.9097,
      city: 'San Jose',
      state: 'CA',
    },
    95111: {
      lat: 37.28401,
      lng: -121.82731,
      city: 'San Jose',
      state: 'CA',
    },
    95112: {
      lat: 37.34461,
      lng: -121.88346,
      city: 'San Jose',
      state: 'CA',
    },
    95113: {
      lat: 37.3339,
      lng: -121.8905,
      city: 'San Jose',
      state: 'CA',
    },
    95116: {
      lat: 37.35043,
      lng: -121.85255,
      city: 'San Jose',
      state: 'CA',
    },
    95117: {
      lat: 37.31008,
      lng: -121.96225,
      city: 'San Jose',
      state: 'CA',
    },
    95118: {
      lat: 37.25589,
      lng: -121.88991,
      city: 'San Jose',
      state: 'CA',
    },
    95119: {
      lat: 37.22756,
      lng: -121.79077,
      city: 'San Jose',
      state: 'CA',
    },
    95120: {
      lat: 37.18428,
      lng: -121.83658,
      city: 'San Jose',
      state: 'CA',
    },
    95121: {
      lat: 37.30463,
      lng: -121.80981,
      city: 'San Jose',
      state: 'CA',
    },
    95122: {
      lat: 37.32925,
      lng: -121.83481,
      city: 'San Jose',
      state: 'CA',
    },
    95123: {
      lat: 37.24479,
      lng: -121.83306,
      city: 'San Jose',
      state: 'CA',
    },
    95124: {
      lat: 37.25684,
      lng: -121.92286,
      city: 'San Jose',
      state: 'CA',
    },
    95125: {
      lat: 37.29554,
      lng: -121.89408,
      city: 'San Jose',
      state: 'CA',
    },
    95126: {
      lat: 37.32687,
      lng: -121.91677,
      city: 'San Jose',
      state: 'CA',
    },
    95127: {
      lat: 37.37453,
      lng: -121.78733,
      city: 'San Jose',
      state: 'CA',
    },
    95128: {
      lat: 37.31579,
      lng: -121.93647,
      city: 'San Jose',
      state: 'CA',
    },
    95129: {
      lat: 37.30597,
      lng: -122.00072,
      city: 'San Jose',
      state: 'CA',
    },
    95130: {
      lat: 37.28803,
      lng: -121.98146,
      city: 'San Jose',
      state: 'CA',
    },
    95131: {
      lat: 37.38729,
      lng: -121.89793,
      city: 'San Jose',
      state: 'CA',
    },
    95132: {
      lat: 37.41994,
      lng: -121.79059,
      city: 'San Jose',
      state: 'CA',
    },
    95133: {
      lat: 37.37171,
      lng: -121.86057,
      city: 'San Jose',
      state: 'CA',
    },
    95134: {
      lat: 37.43003,
      lng: -121.94528,
      city: 'San Jose',
      state: 'CA',
    },
    95135: {
      lat: 37.22285,
      lng: -121.55913,
      city: 'San Jose',
      state: 'CA',
    },
    95136: {
      lat: 37.27192,
      lng: -121.85237,
      city: 'San Jose',
      state: 'CA',
    },
    95138: {
      lat: 37.2462,
      lng: -121.73287,
      city: 'San Jose',
      state: 'CA',
    },
    95139: {
      lat: 37.22489,
      lng: -121.76393,
      city: 'San Jose',
      state: 'CA',
    },
    95140: {
      lat: 37.3483,
      lng: -121.64085,
      city: 'Mount Hamilton',
      state: 'CA',
    },
    95148: {
      lat: 37.33252,
      lng: -121.78142,
      city: 'San Jose',
      state: 'CA',
    },
    95202: {
      lat: 37.95968,
      lng: -121.28772,
      city: 'Stockton',
      state: 'CA',
    },
    95203: {
      lat: 37.95237,
      lng: -121.33098,
      city: 'Stockton',
      state: 'CA',
    },
    95204: {
      lat: 37.97403,
      lng: -121.31906,
      city: 'Stockton',
      state: 'CA',
    },
    95205: {
      lat: 37.96425,
      lng: -121.25955,
      city: 'Stockton',
      state: 'CA',
    },
    95206: {
      lat: 37.8961,
      lng: -121.41051,
      city: 'Stockton',
      state: 'CA',
    },
    95207: {
      lat: 38.00242,
      lng: -121.325,
      city: 'Stockton',
      state: 'CA',
    },
    95209: {
      lat: 38.04717,
      lng: -121.35121,
      city: 'Stockton',
      state: 'CA',
    },
    95210: {
      lat: 38.03215,
      lng: -121.29782,
      city: 'Stockton',
      state: 'CA',
    },
    95211: {
      lat: 37.98078,
      lng: -121.31198,
      city: 'Stockton',
      state: 'CA',
    },
    95212: {
      lat: 38.04897,
      lng: -121.22684,
      city: 'Stockton',
      state: 'CA',
    },
    95215: {
      lat: 37.9444,
      lng: -121.15276,
      city: 'Stockton',
      state: 'CA',
    },
    95219: {
      lat: 38.0207,
      lng: -121.51584,
      city: 'Stockton',
      state: 'CA',
    },
    95220: {
      lat: 38.19779,
      lng: -121.23603,
      city: 'Acampo',
      state: 'CA',
    },
    95221: {
      lat: 38.0724,
      lng: -120.54852,
      city: 'Altaville',
      state: 'CA',
    },
    95222: {
      lat: 38.06127,
      lng: -120.61791,
      city: 'Angels Camp',
      state: 'CA',
    },
    95223: {
      lat: 38.4644,
      lng: -119.85939,
      city: 'Arnold',
      state: 'CA',
    },
    95224: {
      lat: 38.22706,
      lng: -120.32562,
      city: 'Avery',
      state: 'CA',
    },
    95225: {
      lat: 38.20399,
      lng: -120.86488,
      city: 'Burson',
      state: 'CA',
    },
    95226: {
      lat: 38.23377,
      lng: -120.86232,
      city: 'Campo Seco',
      state: 'CA',
    },
    95227: {
      lat: 38.20678,
      lng: -121.04615,
      city: 'Clements',
      state: 'CA',
    },
    95228: {
      lat: 37.94669,
      lng: -120.68067,
      city: 'Copperopolis',
      state: 'CA',
    },
    95230: {
      lat: 37.97361,
      lng: -120.86492,
      city: 'Farmington',
      state: 'CA',
    },
    95231: {
      lat: 37.87331,
      lng: -121.29272,
      city: 'French Camp',
      state: 'CA',
    },
    95232: {
      lat: 38.36051,
      lng: -120.57722,
      city: 'Glencoe',
      state: 'CA',
    },
    95233: {
      lat: 38.18622,
      lng: -120.36859,
      city: 'Hathaway Pines',
      state: 'CA',
    },
    95236: {
      lat: 38.04218,
      lng: -121.03176,
      city: 'Linden',
      state: 'CA',
    },
    95237: {
      lat: 38.15953,
      lng: -121.14845,
      city: 'Lockeford',
      state: 'CA',
    },
    95240: { lat: 38.1177, lng: -121.1627, city: 'Lodi', state: 'CA' },
    95242: {
      lat: 38.13204,
      lng: -121.38875,
      city: 'Lodi',
      state: 'CA',
    },
    95245: {
      lat: 38.30189,
      lng: -120.61975,
      city: 'Mokelumne Hill',
      state: 'CA',
    },
    95246: {
      lat: 38.23662,
      lng: -120.49589,
      city: 'Mountain Ranch',
      state: 'CA',
    },
    95247: {
      lat: 38.14264,
      lng: -120.44359,
      city: 'Murphys',
      state: 'CA',
    },
    95248: {
      lat: 38.32433,
      lng: -120.46051,
      city: 'Rail Road Flat',
      state: 'CA',
    },
    95249: {
      lat: 38.18986,
      lng: -120.61784,
      city: 'San Andreas',
      state: 'CA',
    },
    95251: {
      lat: 38.08024,
      lng: -120.45769,
      city: 'Vallecito',
      state: 'CA',
    },
    95252: {
      lat: 38.15871,
      lng: -120.84214,
      city: 'Valley Springs',
      state: 'CA',
    },
    95253: {
      lat: 38.14725,
      lng: -121.19525,
      city: 'Victor',
      state: 'CA',
    },
    95254: {
      lat: 38.20323,
      lng: -120.95857,
      city: 'Wallace',
      state: 'CA',
    },
    95255: {
      lat: 38.42055,
      lng: -120.47506,
      city: 'West Point',
      state: 'CA',
    },
    95257: {
      lat: 38.38068,
      lng: -120.45255,
      city: 'Wilseyville',
      state: 'CA',
    },
    95258: {
      lat: 38.16811,
      lng: -121.31011,
      city: 'Woodbridge',
      state: 'CA',
    },
    95301: {
      lat: 37.30634,
      lng: -120.65119,
      city: 'Atwater',
      state: 'CA',
    },
    95303: {
      lat: 37.4714,
      lng: -120.67744,
      city: 'Ballico',
      state: 'CA',
    },
    95304: { lat: 37.6773, lng: -121.3948, city: 'Tracy', state: 'CA' },
    95305: {
      lat: 37.8306,
      lng: -120.34821,
      city: 'Big Oak Flat',
      state: 'CA',
    },
    95306: {
      lat: 37.36015,
      lng: -120.11581,
      city: 'Catheys Valley',
      state: 'CA',
    },
    95307: {
      lat: 37.55582,
      lng: -120.95385,
      city: 'Ceres',
      state: 'CA',
    },
    95309: {
      lat: 37.87154,
      lng: -120.43299,
      city: 'Chinese Camp',
      state: 'CA',
    },
    95310: {
      lat: 38.09944,
      lng: -120.36171,
      city: 'Columbia',
      state: 'CA',
    },
    95311: {
      lat: 37.70443,
      lng: -120.08344,
      city: 'Coulterville',
      state: 'CA',
    },
    95312: {
      lat: 37.42107,
      lng: -120.65295,
      city: 'Cressey',
      state: 'CA',
    },
    95313: {
      lat: 37.43511,
      lng: -121.03769,
      city: 'Crows Landing',
      state: 'CA',
    },
    95315: {
      lat: 37.42243,
      lng: -120.7733,
      city: 'Delhi',
      state: 'CA',
    },
    95316: {
      lat: 37.54613,
      lng: -120.69212,
      city: 'Denair',
      state: 'CA',
    },
    95317: {
      lat: 37.12947,
      lng: -120.5074,
      city: 'El Nido',
      state: 'CA',
    },
    95318: {
      lat: 37.66618,
      lng: -119.8064,
      city: 'El Portal',
      state: 'CA',
    },
    95319: {
      lat: 37.6309,
      lng: -120.87728,
      city: 'Empire',
      state: 'CA',
    },
    95320: {
      lat: 37.82453,
      lng: -121.01545,
      city: 'Escalon',
      state: 'CA',
    },
    95321: {
      lat: 37.97719,
      lng: -119.75012,
      city: 'Groveland',
      state: 'CA',
    },
    95322: {
      lat: 37.17622,
      lng: -121.01861,
      city: 'Gustine',
      state: 'CA',
    },
    95323: {
      lat: 37.60881,
      lng: -120.68464,
      city: 'Hickman',
      state: 'CA',
    },
    95324: {
      lat: 37.39452,
      lng: -120.89605,
      city: 'Hilmar',
      state: 'CA',
    },
    95325: {
      lat: 37.48058,
      lng: -120.24208,
      city: 'Hornitos',
      state: 'CA',
    },
    95326: {
      lat: 37.58998,
      lng: -120.8531,
      city: 'Hughson',
      state: 'CA',
    },
    95327: {
      lat: 37.84912,
      lng: -120.4858,
      city: 'Jamestown',
      state: 'CA',
    },
    95328: {
      lat: 37.55797,
      lng: -120.90932,
      city: 'Keyes',
      state: 'CA',
    },
    95329: {
      lat: 37.67726,
      lng: -120.41363,
      city: 'La Grange',
      state: 'CA',
    },
    95330: {
      lat: 37.8117,
      lng: -121.31331,
      city: 'Lathrop',
      state: 'CA',
    },
    95333: {
      lat: 37.2447,
      lng: -120.24277,
      city: 'Le Grand',
      state: 'CA',
    },
    95334: {
      lat: 37.34756,
      lng: -120.74624,
      city: 'Livingston',
      state: 'CA',
    },
    95335: {
      lat: 38.11894,
      lng: -120.09239,
      city: 'Long Barn',
      state: 'CA',
    },
    95336: {
      lat: 37.83351,
      lng: -121.1988,
      city: 'Manteca',
      state: 'CA',
    },
    95337: {
      lat: 37.74171,
      lng: -121.23834,
      city: 'Manteca',
      state: 'CA',
    },
    95338: {
      lat: 37.50911,
      lng: -119.98659,
      city: 'Mariposa',
      state: 'CA',
    },
    95340: {
      lat: 37.34128,
      lng: -120.40658,
      city: 'Merced',
      state: 'CA',
    },
    95341: {
      lat: 37.23037,
      lng: -120.50715,
      city: 'Merced',
      state: 'CA',
    },
    95345: {
      lat: 37.58784,
      lng: -119.96408,
      city: 'Midpines',
      state: 'CA',
    },
    95346: {
      lat: 38.07045,
      lng: -120.17291,
      city: 'Mi Wuk Village',
      state: 'CA',
    },
    95348: {
      lat: 37.41525,
      lng: -120.4979,
      city: 'Merced',
      state: 'CA',
    },
    95350: {
      lat: 37.67175,
      lng: -121.00732,
      city: 'Modesto',
      state: 'CA',
    },
    95351: {
      lat: 37.6236,
      lng: -120.99828,
      city: 'Modesto',
      state: 'CA',
    },
    95354: {
      lat: 37.63849,
      lng: -120.96428,
      city: 'Modesto',
      state: 'CA',
    },
    95355: {
      lat: 37.6727,
      lng: -120.94717,
      city: 'Modesto',
      state: 'CA',
    },
    95356: {
      lat: 37.71945,
      lng: -121.02786,
      city: 'Modesto',
      state: 'CA',
    },
    95357: {
      lat: 37.66985,
      lng: -120.87924,
      city: 'Modesto',
      state: 'CA',
    },
    95358: {
      lat: 37.61578,
      lng: -121.10059,
      city: 'Modesto',
      state: 'CA',
    },
    95360: {
      lat: 37.32918,
      lng: -121.03827,
      city: 'Newman',
      state: 'CA',
    },
    95361: {
      lat: 37.79342,
      lng: -120.77654,
      city: 'Oakdale',
      state: 'CA',
    },
    95363: {
      lat: 37.37251,
      lng: -121.27192,
      city: 'Patterson',
      state: 'CA',
    },
    95364: {
      lat: 38.22908,
      lng: -119.79174,
      city: 'Pinecrest',
      state: 'CA',
    },
    95365: {
      lat: 37.3012,
      lng: -120.33152,
      city: 'Planada',
      state: 'CA',
    },
    95366: {
      lat: 37.76223,
      lng: -121.12574,
      city: 'Ripon',
      state: 'CA',
    },
    95367: {
      lat: 37.73089,
      lng: -120.94669,
      city: 'Riverbank',
      state: 'CA',
    },
    95368: {
      lat: 37.71544,
      lng: -121.09047,
      city: 'Salida',
      state: 'CA',
    },
    95369: {
      lat: 37.52435,
      lng: -120.41301,
      city: 'Snelling',
      state: 'CA',
    },
    95370: {
      lat: 37.98484,
      lng: -120.34515,
      city: 'Sonora',
      state: 'CA',
    },
    95372: {
      lat: 37.98927,
      lng: -120.26272,
      city: 'Soulsbyville',
      state: 'CA',
    },
    95374: {
      lat: 37.31636,
      lng: -120.85683,
      city: 'Stevinson',
      state: 'CA',
    },
    95375: {
      lat: 38.21493,
      lng: -119.97031,
      city: 'Strawberry',
      state: 'CA',
    },
    95376: {
      lat: 37.73678,
      lng: -121.43404,
      city: 'Tracy',
      state: 'CA',
    },
    95377: {
      lat: 37.65293,
      lng: -121.49598,
      city: 'Tracy',
      state: 'CA',
    },
    95379: {
      lat: 37.96174,
      lng: -120.21635,
      city: 'Tuolumne',
      state: 'CA',
    },
    95380: {
      lat: 37.47226,
      lng: -120.86978,
      city: 'Turlock',
      state: 'CA',
    },
    95382: {
      lat: 37.52831,
      lng: -120.85214,
      city: 'Turlock',
      state: 'CA',
    },
    95383: {
      lat: 38.09534,
      lng: -120.22602,
      city: 'Twain Harte',
      state: 'CA',
    },
    95385: {
      lat: 37.612,
      lng: -121.24773,
      city: 'Vernalis',
      state: 'CA',
    },
    95386: {
      lat: 37.67391,
      lng: -120.63045,
      city: 'Waterford',
      state: 'CA',
    },
    95387: {
      lat: 37.55429,
      lng: -121.2451,
      city: 'Westley',
      state: 'CA',
    },
    95388: {
      lat: 37.42171,
      lng: -120.59715,
      city: 'Winton',
      state: 'CA',
    },
    95389: {
      lat: 37.73784,
      lng: -119.52556,
      city: 'Yosemite National Park',
      state: 'CA',
    },
    95391: {
      lat: 37.76952,
      lng: -121.5912,
      city: 'Tracy',
      state: 'CA',
    },
    95401: {
      lat: 38.44922,
      lng: -122.79307,
      city: 'Santa Rosa',
      state: 'CA',
    },
    95403: {
      lat: 38.50326,
      lng: -122.75358,
      city: 'Santa Rosa',
      state: 'CA',
    },
    95404: {
      lat: 38.46488,
      lng: -122.65709,
      city: 'Santa Rosa',
      state: 'CA',
    },
    95405: {
      lat: 38.43926,
      lng: -122.66908,
      city: 'Santa Rosa',
      state: 'CA',
    },
    95407: {
      lat: 38.39465,
      lng: -122.74591,
      city: 'Santa Rosa',
      state: 'CA',
    },
    95409: {
      lat: 38.46116,
      lng: -122.60425,
      city: 'Santa Rosa',
      state: 'CA',
    },
    95410: {
      lat: 39.21616,
      lng: -123.70012,
      city: 'Albion',
      state: 'CA',
    },
    95412: {
      lat: 38.71168,
      lng: -123.31967,
      city: 'Annapolis',
      state: 'CA',
    },
    95415: {
      lat: 39.0205,
      lng: -123.36751,
      city: 'Boonville',
      state: 'CA',
    },
    95417: {
      lat: 39.68791,
      lng: -123.65075,
      city: 'Branscomb',
      state: 'CA',
    },
    95419: {
      lat: 38.42336,
      lng: -122.9582,
      city: 'Camp Meeker',
      state: 'CA',
    },
    95420: {
      lat: 39.35638,
      lng: -123.76832,
      city: 'Caspar',
      state: 'CA',
    },
    95421: {
      lat: 38.58276,
      lng: -123.18037,
      city: 'Cazadero',
      state: 'CA',
    },
    95422: {
      lat: 38.97829,
      lng: -122.6423,
      city: 'Clearlake',
      state: 'CA',
    },
    95423: {
      lat: 39.06749,
      lng: -122.62753,
      city: 'Clearlake Oaks',
      state: 'CA',
    },
    95425: {
      lat: 38.81792,
      lng: -123.02566,
      city: 'Cloverdale',
      state: 'CA',
    },
    95426: { lat: 38.83449, lng: -122.7361, city: 'Cobb', state: 'CA' },
    95427: {
      lat: 39.25326,
      lng: -123.56932,
      city: 'Comptche',
      state: 'CA',
    },
    95428: {
      lat: 39.82489,
      lng: -123.18126,
      city: 'Covelo',
      state: 'CA',
    },
    95429: {
      lat: 39.73292,
      lng: -123.34841,
      city: 'Dos Rios',
      state: 'CA',
    },
    95430: {
      lat: 38.46279,
      lng: -123.04476,
      city: 'Duncans Mills',
      state: 'CA',
    },
    95431: {
      lat: 38.34897,
      lng: -122.51625,
      city: 'Eldridge',
      state: 'CA',
    },
    95432: { lat: 39.1083, lng: -123.63829, city: 'Elk', state: 'CA' },
    95435: {
      lat: 39.00315,
      lng: -122.8748,
      city: 'Finley',
      state: 'CA',
    },
    95436: {
      lat: 38.49108,
      lng: -122.91425,
      city: 'Forestville',
      state: 'CA',
    },
    95437: {
      lat: 39.44535,
      lng: -123.69402,
      city: 'Fort Bragg',
      state: 'CA',
    },
    95439: {
      lat: 38.49055,
      lng: -122.77591,
      city: 'Fulton',
      state: 'CA',
    },
    95441: {
      lat: 38.73775,
      lng: -123.0354,
      city: 'Geyserville',
      state: 'CA',
    },
    95442: {
      lat: 38.3785,
      lng: -122.51549,
      city: 'Glen Ellen',
      state: 'CA',
    },
    95443: {
      lat: 39.05013,
      lng: -122.73962,
      city: 'Glenhaven',
      state: 'CA',
    },
    95444: {
      lat: 38.43261,
      lng: -122.86922,
      city: 'Graton',
      state: 'CA',
    },
    95445: {
      lat: 38.83762,
      lng: -123.49404,
      city: 'Gualala',
      state: 'CA',
    },
    95446: {
      lat: 38.52525,
      lng: -123.00423,
      city: 'Guerneville',
      state: 'CA',
    },
    95448: {
      lat: 38.62309,
      lng: -122.89089,
      city: 'Healdsburg',
      state: 'CA',
    },
    95449: {
      lat: 38.94331,
      lng: -123.09653,
      city: 'Hopland',
      state: 'CA',
    },
    95450: {
      lat: 38.5229,
      lng: -123.21508,
      city: 'Jenner',
      state: 'CA',
    },
    95451: {
      lat: 38.9388,
      lng: -122.80484,
      city: 'Kelseyville',
      state: 'CA',
    },
    95452: {
      lat: 38.42969,
      lng: -122.5362,
      city: 'Kenwood',
      state: 'CA',
    },
    95453: {
      lat: 39.04346,
      lng: -122.93918,
      city: 'Lakeport',
      state: 'CA',
    },
    95454: {
      lat: 39.78063,
      lng: -123.51631,
      city: 'Laytonville',
      state: 'CA',
    },
    95456: {
      lat: 39.25851,
      lng: -123.74585,
      city: 'Little River',
      state: 'CA',
    },
    95457: {
      lat: 38.87967,
      lng: -122.52753,
      city: 'Lower Lake',
      state: 'CA',
    },
    95458: {
      lat: 39.11667,
      lng: -122.78204,
      city: 'Lucerne',
      state: 'CA',
    },
    95459: {
      lat: 38.99068,
      lng: -123.62627,
      city: 'Manchester',
      state: 'CA',
    },
    95460: {
      lat: 39.30386,
      lng: -123.73737,
      city: 'Mendocino',
      state: 'CA',
    },
    95461: {
      lat: 38.762,
      lng: -122.59314,
      city: 'Middletown',
      state: 'CA',
    },
    95462: {
      lat: 38.47263,
      lng: -123.02064,
      city: 'Monte Rio',
      state: 'CA',
    },
    95463: {
      lat: 39.18957,
      lng: -123.58059,
      city: 'Navarro',
      state: 'CA',
    },
    95464: {
      lat: 39.12736,
      lng: -122.84733,
      city: 'Nice',
      state: 'CA',
    },
    95465: {
      lat: 38.40556,
      lng: -123.01963,
      city: 'Occidental',
      state: 'CA',
    },
    95466: {
      lat: 39.08868,
      lng: -123.51058,
      city: 'Philo',
      state: 'CA',
    },
    95467: {
      lat: 38.80453,
      lng: -122.54948,
      city: 'Hidden Valley Lake',
      state: 'CA',
    },
    95468: {
      lat: 38.9094,
      lng: -123.60372,
      city: 'Point Arena',
      state: 'CA',
    },
    95469: {
      lat: 39.38853,
      lng: -123.05972,
      city: 'Potter Valley',
      state: 'CA',
    },
    95470: {
      lat: 39.30019,
      lng: -123.22984,
      city: 'Redwood Valley',
      state: 'CA',
    },
    95471: {
      lat: 38.53218,
      lng: -122.97362,
      city: 'Rio Nido',
      state: 'CA',
    },
    95472: {
      lat: 38.39738,
      lng: -122.86668,
      city: 'Sebastopol',
      state: 'CA',
    },
    95476: {
      lat: 38.25531,
      lng: -122.46055,
      city: 'Sonoma',
      state: 'CA',
    },
    95480: {
      lat: 38.68463,
      lng: -123.36802,
      city: 'Stewarts Point',
      state: 'CA',
    },
    95482: {
      lat: 39.1618,
      lng: -123.23925,
      city: 'Ukiah',
      state: 'CA',
    },
    95485: {
      lat: 39.20828,
      lng: -122.9259,
      city: 'Upper Lake',
      state: 'CA',
    },
    95486: {
      lat: 38.4741,
      lng: -123.02403,
      city: 'Villa Grande',
      state: 'CA',
    },
    95488: {
      lat: 39.73679,
      lng: -123.77199,
      city: 'Westport',
      state: 'CA',
    },
    95490: {
      lat: 39.4883,
      lng: -123.34725,
      city: 'Willits',
      state: 'CA',
    },
    95492: {
      lat: 38.53052,
      lng: -122.81254,
      city: 'Windsor',
      state: 'CA',
    },
    95493: {
      lat: 39.20241,
      lng: -122.97948,
      city: 'Witter Springs',
      state: 'CA',
    },
    95494: {
      lat: 38.89646,
      lng: -123.34324,
      city: 'Yorkville',
      state: 'CA',
    },
    95497: {
      lat: 38.71976,
      lng: -123.45601,
      city: 'The Sea Ranch',
      state: 'CA',
    },
    95501: {
      lat: 40.80435,
      lng: -124.14034,
      city: 'Eureka',
      state: 'CA',
    },
    95503: {
      lat: 40.72406,
      lng: -124.09178,
      city: 'Eureka',
      state: 'CA',
    },
    95511: {
      lat: 40.16975,
      lng: -123.59539,
      city: 'Alderpoint',
      state: 'CA',
    },
    95514: {
      lat: 40.29151,
      lng: -123.67119,
      city: 'Blocksburg',
      state: 'CA',
    },
    95519: {
      lat: 40.95833,
      lng: -124.04983,
      city: 'Mckinleyville',
      state: 'CA',
    },
    95521: {
      lat: 40.84586,
      lng: -124.04745,
      city: 'Arcata',
      state: 'CA',
    },
    95524: {
      lat: 40.82346,
      lng: -124.05271,
      city: 'Bayside',
      state: 'CA',
    },
    95525: {
      lat: 40.94845,
      lng: -123.82629,
      city: 'Blue Lake',
      state: 'CA',
    },
    95526: {
      lat: 40.44705,
      lng: -123.6628,
      city: 'Bridgeville',
      state: 'CA',
    },
    95527: {
      lat: 40.93593,
      lng: -123.41966,
      city: 'Burnt Ranch',
      state: 'CA',
    },
    95528: {
      lat: 40.4936,
      lng: -123.92393,
      city: 'Carlotta',
      state: 'CA',
    },
    95531: {
      lat: 41.79288,
      lng: -124.00473,
      city: 'Crescent City',
      state: 'CA',
    },
    95536: {
      lat: 40.51069,
      lng: -124.26547,
      city: 'Ferndale',
      state: 'CA',
    },
    95537: {
      lat: 40.745,
      lng: -124.21472,
      city: 'Fields Landing',
      state: 'CA',
    },
    95538: {
      lat: 41.86833,
      lng: -124.15433,
      city: 'Fort Dick',
      state: 'CA',
    },
    95540: {
      lat: 40.56671,
      lng: -124.12651,
      city: 'Fortuna',
      state: 'CA',
    },
    95542: {
      lat: 40.08074,
      lng: -123.77369,
      city: 'Garberville',
      state: 'CA',
    },
    95543: {
      lat: 41.88847,
      lng: -123.80238,
      city: 'Gasquet',
      state: 'CA',
    },
    95545: {
      lat: 40.26159,
      lng: -124.08599,
      city: 'Honeydew',
      state: 'CA',
    },
    95546: {
      lat: 41.18777,
      lng: -123.74535,
      city: 'Hoopa',
      state: 'CA',
    },
    95547: {
      lat: 40.56952,
      lng: -124.07751,
      city: 'Hydesville',
      state: 'CA',
    },
    95548: {
      lat: 41.58633,
      lng: -124.05859,
      city: 'Klamath',
      state: 'CA',
    },
    95549: {
      lat: 40.65838,
      lng: -123.89485,
      city: 'Kneeland',
      state: 'CA',
    },
    95550: {
      lat: 40.77695,
      lng: -123.82951,
      city: 'Korbel',
      state: 'CA',
    },
    95551: {
      lat: 40.66977,
      lng: -124.21961,
      city: 'Loleta',
      state: 'CA',
    },
    95552: {
      lat: 40.34899,
      lng: -123.42248,
      city: 'Mad River',
      state: 'CA',
    },
    95553: {
      lat: 40.22981,
      lng: -123.87133,
      city: 'Miranda',
      state: 'CA',
    },
    95554: {
      lat: 40.27573,
      lng: -123.78414,
      city: 'Myers Flat',
      state: 'CA',
    },
    95555: {
      lat: 41.29519,
      lng: -123.97774,
      city: 'Orick',
      state: 'CA',
    },
    95556: {
      lat: 41.31444,
      lng: -123.59043,
      city: 'Orleans',
      state: 'CA',
    },
    95558: {
      lat: 40.32673,
      lng: -124.24017,
      city: 'Petrolia',
      state: 'CA',
    },
    95559: {
      lat: 40.18977,
      lng: -123.7512,
      city: 'Phillipsville',
      state: 'CA',
    },
    95560: {
      lat: 40.14606,
      lng: -123.83816,
      city: 'Redway',
      state: 'CA',
    },
    95562: {
      lat: 40.45808,
      lng: -124.1072,
      city: 'Rio Dell',
      state: 'CA',
    },
    95563: {
      lat: 40.84248,
      lng: -123.51225,
      city: 'Salyer',
      state: 'CA',
    },
    95564: {
      lat: 40.79023,
      lng: -124.20312,
      city: 'Samoa',
      state: 'CA',
    },
    95565: {
      lat: 40.45723,
      lng: -124.01099,
      city: 'Scotia',
      state: 'CA',
    },
    95567: {
      lat: 41.9498,
      lng: -124.1118,
      city: 'Smith River',
      state: 'CA',
    },
    95568: {
      lat: 41.48454,
      lng: -123.48543,
      city: 'Somes Bar',
      state: 'CA',
    },
    95569: {
      lat: 40.36078,
      lng: -123.85818,
      city: 'Redcrest',
      state: 'CA',
    },
    95570: {
      lat: 41.11375,
      lng: -124.08037,
      city: 'Trinidad',
      state: 'CA',
    },
    95571: {
      lat: 40.36495,
      lng: -123.94157,
      city: 'Weott',
      state: 'CA',
    },
    95573: {
      lat: 40.89567,
      lng: -123.65873,
      city: 'Willow Creek',
      state: 'CA',
    },
    95585: {
      lat: 39.85872,
      lng: -123.66619,
      city: 'Leggett',
      state: 'CA',
    },
    95587: {
      lat: 39.96969,
      lng: -123.75537,
      city: 'Piercy',
      state: 'CA',
    },
    95589: {
      lat: 40.07927,
      lng: -123.98596,
      city: 'Whitethorn',
      state: 'CA',
    },
    95595: {
      lat: 40.11074,
      lng: -123.26642,
      city: 'Zenia',
      state: 'CA',
    },
    95601: {
      lat: 38.42713,
      lng: -120.82997,
      city: 'Amador City',
      state: 'CA',
    },
    95602: {
      lat: 38.99039,
      lng: -121.10991,
      city: 'Auburn',
      state: 'CA',
    },
    95603: {
      lat: 38.91167,
      lng: -121.08559,
      city: 'Auburn',
      state: 'CA',
    },
    95604: {
      lat: 38.9112,
      lng: -121.0982,
      city: 'Auburn',
      state: 'CA',
    },
    95605: {
      lat: 38.59289,
      lng: -121.53929,
      city: 'West Sacramento',
      state: 'CA',
    },
    95606: {
      lat: 38.75604,
      lng: -122.19387,
      city: 'Brooks',
      state: 'CA',
    },
    95607: {
      lat: 38.7899,
      lng: -122.12585,
      city: 'Capay',
      state: 'CA',
    },
    95608: {
      lat: 38.62611,
      lng: -121.32886,
      city: 'Carmichael',
      state: 'CA',
    },
    95610: {
      lat: 38.69494,
      lng: -121.27219,
      city: 'Citrus Heights',
      state: 'CA',
    },
    95612: {
      lat: 38.38644,
      lng: -121.58118,
      city: 'Clarksburg',
      state: 'CA',
    },
    95613: {
      lat: 38.81272,
      lng: -120.89173,
      city: 'Coloma',
      state: 'CA',
    },
    95614: {
      lat: 38.88649,
      lng: -120.98896,
      city: 'Cool',
      state: 'CA',
    },
    95615: {
      lat: 38.31113,
      lng: -121.54595,
      city: 'Courtland',
      state: 'CA',
    },
    95616: {
      lat: 38.55819,
      lng: -121.80044,
      city: 'Davis',
      state: 'CA',
    },
    95618: {
      lat: 38.54348,
      lng: -121.6917,
      city: 'Davis',
      state: 'CA',
    },
    95619: {
      lat: 38.68218,
      lng: -120.81401,
      city: 'Diamond Springs',
      state: 'CA',
    },
    95620: {
      lat: 38.40599,
      lng: -121.75689,
      city: 'Dixon',
      state: 'CA',
    },
    95621: {
      lat: 38.69551,
      lng: -121.30862,
      city: 'Citrus Heights',
      state: 'CA',
    },
    95623: {
      lat: 38.6075,
      lng: -120.84873,
      city: 'El Dorado',
      state: 'CA',
    },
    95624: {
      lat: 38.43203,
      lng: -121.30506,
      city: 'Elk Grove',
      state: 'CA',
    },
    95625: {
      lat: 38.35728,
      lng: -121.90861,
      city: 'Elmira',
      state: 'CA',
    },
    95626: {
      lat: 38.73378,
      lng: -121.48898,
      city: 'Elverta',
      state: 'CA',
    },
    95627: {
      lat: 38.72936,
      lng: -122.00959,
      city: 'Esparto',
      state: 'CA',
    },
    95628: {
      lat: 38.65212,
      lng: -121.2543,
      city: 'Fair Oaks',
      state: 'CA',
    },
    95629: {
      lat: 38.51277,
      lng: -120.6875,
      city: 'Fiddletown',
      state: 'CA',
    },
    95630: {
      lat: 38.66558,
      lng: -121.141,
      city: 'Folsom',
      state: 'CA',
    },
    95631: {
      lat: 39.05453,
      lng: -120.75365,
      city: 'Foresthill',
      state: 'CA',
    },
    95632: {
      lat: 38.27705,
      lng: -121.26128,
      city: 'Galt',
      state: 'CA',
    },
    95633: {
      lat: 38.84919,
      lng: -120.82328,
      city: 'Garden Valley',
      state: 'CA',
    },
    95634: {
      lat: 38.9123,
      lng: -120.69443,
      city: 'Georgetown',
      state: 'CA',
    },
    95635: {
      lat: 38.91111,
      lng: -120.90043,
      city: 'Greenwood',
      state: 'CA',
    },
    95636: {
      lat: 38.6274,
      lng: -120.51852,
      city: 'Grizzly Flats',
      state: 'CA',
    },
    95637: {
      lat: 38.85283,
      lng: -122.2729,
      city: 'Guinda',
      state: 'CA',
    },
    95638: {
      lat: 38.33969,
      lng: -121.11481,
      city: 'Herald',
      state: 'CA',
    },
    95639: {
      lat: 38.39202,
      lng: -121.49599,
      city: 'Hood',
      state: 'CA',
    },
    95640: {
      lat: 38.33922,
      lng: -120.94024,
      city: 'Ione',
      state: 'CA',
    },
    95641: {
      lat: 38.15046,
      lng: -121.60271,
      city: 'Isleton',
      state: 'CA',
    },
    95642: {
      lat: 38.3434,
      lng: -120.76398,
      city: 'Jackson',
      state: 'CA',
    },
    95645: {
      lat: 38.89025,
      lng: -121.79992,
      city: 'Knights Landing',
      state: 'CA',
    },
    95646: {
      lat: 38.63828,
      lng: -120.01813,
      city: 'Kirkwood',
      state: 'CA',
    },
    95648: {
      lat: 38.92643,
      lng: -121.31101,
      city: 'Lincoln',
      state: 'CA',
    },
    95650: {
      lat: 38.81339,
      lng: -121.16831,
      city: 'Loomis',
      state: 'CA',
    },
    95651: {
      lat: 38.82088,
      lng: -120.93515,
      city: 'Lotus',
      state: 'CA',
    },
    95652: {
      lat: 38.66265,
      lng: -121.40079,
      city: 'Mcclellan',
      state: 'CA',
    },
    95653: {
      lat: 38.69151,
      lng: -121.97723,
      city: 'Madison',
      state: 'CA',
    },
    95655: {
      lat: 38.54976,
      lng: -121.27898,
      city: 'Mather',
      state: 'CA',
    },
    95658: {
      lat: 38.87938,
      lng: -121.15173,
      city: 'Newcastle',
      state: 'CA',
    },
    95659: {
      lat: 38.84594,
      lng: -121.57679,
      city: 'Nicolaus',
      state: 'CA',
    },
    95660: {
      lat: 38.67883,
      lng: -121.3805,
      city: 'North Highlands',
      state: 'CA',
    },
    95661: {
      lat: 38.74129,
      lng: -121.24925,
      city: 'Roseville',
      state: 'CA',
    },
    95662: {
      lat: 38.68917,
      lng: -121.21988,
      city: 'Orangevale',
      state: 'CA',
    },
    95663: {
      lat: 38.85739,
      lng: -121.18251,
      city: 'Penryn',
      state: 'CA',
    },
    95664: {
      lat: 38.79755,
      lng: -121.05136,
      city: 'Pilot Hill',
      state: 'CA',
    },
    95665: {
      lat: 38.39587,
      lng: -120.65238,
      city: 'Pine Grove',
      state: 'CA',
    },
    95666: {
      lat: 38.54113,
      lng: -120.33076,
      city: 'Pioneer',
      state: 'CA',
    },
    95667: {
      lat: 38.73407,
      lng: -120.79216,
      city: 'Placerville',
      state: 'CA',
    },
    95668: {
      lat: 38.82708,
      lng: -121.49378,
      city: 'Pleasant Grove',
      state: 'CA',
    },
    95669: {
      lat: 38.49522,
      lng: -120.89157,
      city: 'Plymouth',
      state: 'CA',
    },
    95670: {
      lat: 38.60461,
      lng: -121.27963,
      city: 'Rancho Cordova',
      state: 'CA',
    },
    95671: {
      lat: 38.69529,
      lng: -121.15671,
      city: 'Represa',
      state: 'CA',
    },
    95672: {
      lat: 38.72664,
      lng: -120.99638,
      city: 'Rescue',
      state: 'CA',
    },
    95673: {
      lat: 38.69029,
      lng: -121.46603,
      city: 'Rio Linda',
      state: 'CA',
    },
    95674: {
      lat: 38.95396,
      lng: -121.48187,
      city: 'Rio Oso',
      state: 'CA',
    },
    95675: {
      lat: 38.54548,
      lng: -120.74277,
      city: 'River Pines',
      state: 'CA',
    },
    95676: {
      lat: 38.87224,
      lng: -121.71664,
      city: 'Robbins',
      state: 'CA',
    },
    95677: {
      lat: 38.79298,
      lng: -121.23133,
      city: 'Rocklin',
      state: 'CA',
    },
    95678: {
      lat: 38.76536,
      lng: -121.28847,
      city: 'Roseville',
      state: 'CA',
    },
    95679: {
      lat: 38.90524,
      lng: -122.26727,
      city: 'Rumsey',
      state: 'CA',
    },
    95680: {
      lat: 38.24142,
      lng: -121.57816,
      city: 'Ryde',
      state: 'CA',
    },
    95681: {
      lat: 38.99809,
      lng: -121.35422,
      city: 'Sheridan',
      state: 'CA',
    },
    95682: {
      lat: 38.60537,
      lng: -120.96175,
      city: 'Shingle Springs',
      state: 'CA',
    },
    95683: {
      lat: 38.51018,
      lng: -121.09894,
      city: 'Sloughhouse',
      state: 'CA',
    },
    95684: {
      lat: 38.59098,
      lng: -120.55066,
      city: 'Somerset',
      state: 'CA',
    },
    95685: {
      lat: 38.42716,
      lng: -120.76698,
      city: 'Sutter Creek',
      state: 'CA',
    },
    95686: {
      lat: 38.19039,
      lng: -121.48543,
      city: 'Thornton',
      state: 'CA',
    },
    95687: {
      lat: 38.3344,
      lng: -121.91765,
      city: 'Vacaville',
      state: 'CA',
    },
    95688: {
      lat: 38.41901,
      lng: -122.02571,
      city: 'Vacaville',
      state: 'CA',
    },
    95689: {
      lat: 38.47088,
      lng: -120.61566,
      city: 'Volcano',
      state: 'CA',
    },
    95690: {
      lat: 38.23429,
      lng: -121.58672,
      city: 'Walnut Grove',
      state: 'CA',
    },
    95691: {
      lat: 38.62394,
      lng: -121.58272,
      city: 'West Sacramento',
      state: 'CA',
    },
    95692: {
      lat: 39.04699,
      lng: -121.40471,
      city: 'Wheatland',
      state: 'CA',
    },
    95693: {
      lat: 38.39851,
      lng: -121.21956,
      city: 'Wilton',
      state: 'CA',
    },
    95694: {
      lat: 38.56163,
      lng: -122.00609,
      city: 'Winters',
      state: 'CA',
    },
    95695: {
      lat: 38.68847,
      lng: -121.85666,
      city: 'Woodland',
      state: 'CA',
    },
    95697: {
      lat: 38.73284,
      lng: -121.80701,
      city: 'Yolo',
      state: 'CA',
    },
    95698: {
      lat: 38.8187,
      lng: -121.91012,
      city: 'Zamora',
      state: 'CA',
    },
    95699: {
      lat: 38.44074,
      lng: -120.85702,
      city: 'Drytown',
      state: 'CA',
    },
    95701: {
      lat: 39.22633,
      lng: -120.76741,
      city: 'Alta',
      state: 'CA',
    },
    95703: {
      lat: 38.99156,
      lng: -120.9864,
      city: 'Applegate',
      state: 'CA',
    },
    95709: {
      lat: 38.74886,
      lng: -120.68134,
      city: 'Camino',
      state: 'CA',
    },
    95713: {
      lat: 39.08477,
      lng: -120.92788,
      city: 'Colfax',
      state: 'CA',
    },
    95714: {
      lat: 39.2013,
      lng: -120.83677,
      city: 'Dutch Flat',
      state: 'CA',
    },
    95715: {
      lat: 39.27495,
      lng: -120.64388,
      city: 'Emigrant Gap',
      state: 'CA',
    },
    95717: {
      lat: 39.14258,
      lng: -120.84268,
      city: 'Gold Run',
      state: 'CA',
    },
    95720: {
      lat: 38.84536,
      lng: -120.26021,
      city: 'Kyburz',
      state: 'CA',
    },
    95721: {
      lat: 38.82341,
      lng: -120.09379,
      city: 'Echo Lake',
      state: 'CA',
    },
    95722: {
      lat: 39.00467,
      lng: -121.02891,
      city: 'Meadow Vista',
      state: 'CA',
    },
    95726: {
      lat: 38.75418,
      lng: -120.41989,
      city: 'Pollock Pines',
      state: 'CA',
    },
    95728: {
      lat: 39.29673,
      lng: -120.40358,
      city: 'Soda Springs',
      state: 'CA',
    },
    95735: {
      lat: 38.82986,
      lng: -120.17936,
      city: 'Twin Bridges',
      state: 'CA',
    },
    95736: {
      lat: 39.03905,
      lng: -120.97738,
      city: 'Weimar',
      state: 'CA',
    },
    95742: {
      lat: 38.5735,
      lng: -121.19854,
      city: 'Rancho Cordova',
      state: 'CA',
    },
    95746: {
      lat: 38.75009,
      lng: -121.16712,
      city: 'Granite Bay',
      state: 'CA',
    },
    95747: {
      lat: 38.77963,
      lng: -121.36798,
      city: 'Roseville',
      state: 'CA',
    },
    95757: {
      lat: 38.33886,
      lng: -121.43643,
      city: 'Elk Grove',
      state: 'CA',
    },
    95758: {
      lat: 38.42932,
      lng: -121.44603,
      city: 'Elk Grove',
      state: 'CA',
    },
    95762: {
      lat: 38.67694,
      lng: -121.05755,
      city: 'El Dorado Hills',
      state: 'CA',
    },
    95765: {
      lat: 38.81982,
      lng: -121.27772,
      city: 'Rocklin',
      state: 'CA',
    },
    95776: {
      lat: 38.68889,
      lng: -121.70776,
      city: 'Woodland',
      state: 'CA',
    },
    95811: {
      lat: 38.58761,
      lng: -121.49077,
      city: 'Sacramento',
      state: 'CA',
    },
    95814: {
      lat: 38.58064,
      lng: -121.49488,
      city: 'Sacramento',
      state: 'CA',
    },
    95815: {
      lat: 38.60544,
      lng: -121.44497,
      city: 'Sacramento',
      state: 'CA',
    },
    95816: {
      lat: 38.57555,
      lng: -121.46543,
      city: 'Sacramento',
      state: 'CA',
    },
    95817: {
      lat: 38.55057,
      lng: -121.45713,
      city: 'Sacramento',
      state: 'CA',
    },
    95818: {
      lat: 38.55579,
      lng: -121.49669,
      city: 'Sacramento',
      state: 'CA',
    },
    95819: {
      lat: 38.56816,
      lng: -121.43772,
      city: 'Sacramento',
      state: 'CA',
    },
    95820: {
      lat: 38.53491,
      lng: -121.4442,
      city: 'Sacramento',
      state: 'CA',
    },
    95821: {
      lat: 38.62541,
      lng: -121.38479,
      city: 'Sacramento',
      state: 'CA',
    },
    95822: {
      lat: 38.51273,
      lng: -121.49556,
      city: 'Sacramento',
      state: 'CA',
    },
    95823: {
      lat: 38.47473,
      lng: -121.44266,
      city: 'Sacramento',
      state: 'CA',
    },
    95824: {
      lat: 38.51746,
      lng: -121.44147,
      city: 'Sacramento',
      state: 'CA',
    },
    95825: {
      lat: 38.5901,
      lng: -121.40644,
      city: 'Sacramento',
      state: 'CA',
    },
    95826: {
      lat: 38.54554,
      lng: -121.37885,
      city: 'Sacramento',
      state: 'CA',
    },
    95827: {
      lat: 38.55151,
      lng: -121.3253,
      city: 'Sacramento',
      state: 'CA',
    },
    95828: {
      lat: 38.48943,
      lng: -121.39598,
      city: 'Sacramento',
      state: 'CA',
    },
    95829: {
      lat: 38.48759,
      lng: -121.33326,
      city: 'Sacramento',
      state: 'CA',
    },
    95830: {
      lat: 38.49415,
      lng: -121.27229,
      city: 'Sacramento',
      state: 'CA',
    },
    95831: {
      lat: 38.49599,
      lng: -121.52948,
      city: 'Sacramento',
      state: 'CA',
    },
    95832: {
      lat: 38.43979,
      lng: -121.49888,
      city: 'Sacramento',
      state: 'CA',
    },
    95833: {
      lat: 38.61529,
      lng: -121.51558,
      city: 'Sacramento',
      state: 'CA',
    },
    95834: {
      lat: 38.64207,
      lng: -121.51967,
      city: 'Sacramento',
      state: 'CA',
    },
    95835: {
      lat: 38.67088,
      lng: -121.52464,
      city: 'Sacramento',
      state: 'CA',
    },
    95837: {
      lat: 38.68925,
      lng: -121.59537,
      city: 'Sacramento',
      state: 'CA',
    },
    95838: {
      lat: 38.64708,
      lng: -121.44312,
      city: 'Sacramento',
      state: 'CA',
    },
    95841: {
      lat: 38.66031,
      lng: -121.34812,
      city: 'Sacramento',
      state: 'CA',
    },
    95842: {
      lat: 38.687,
      lng: -121.34889,
      city: 'Sacramento',
      state: 'CA',
    },
    95843: {
      lat: 38.71532,
      lng: -121.36366,
      city: 'Antelope',
      state: 'CA',
    },
    95864: {
      lat: 38.58512,
      lng: -121.37655,
      city: 'Sacramento',
      state: 'CA',
    },
    95901: {
      lat: 39.2225,
      lng: -121.48855,
      city: 'Marysville',
      state: 'CA',
    },
    95903: {
      lat: 39.11337,
      lng: -121.37192,
      city: 'Beale Afb',
      state: 'CA',
    },
    95910: {
      lat: 39.46742,
      lng: -120.83158,
      city: 'Alleghany',
      state: 'CA',
    },
    95912: {
      lat: 39.0016,
      lng: -122.07462,
      city: 'Arbuckle',
      state: 'CA',
    },
    95913: {
      lat: 39.62899,
      lng: -122.19215,
      city: 'Artois',
      state: 'CA',
    },
    95914: {
      lat: 39.40756,
      lng: -121.37878,
      city: 'Bangor',
      state: 'CA',
    },
    95915: {
      lat: 39.97926,
      lng: -121.21492,
      city: 'Belden',
      state: 'CA',
    },
    95916: {
      lat: 39.65945,
      lng: -121.37375,
      city: 'Berry Creek',
      state: 'CA',
    },
    95917: {
      lat: 39.42392,
      lng: -121.77178,
      city: 'Biggs',
      state: 'CA',
    },
    95918: {
      lat: 39.301,
      lng: -121.34547,
      city: 'Browns Valley',
      state: 'CA',
    },
    95919: {
      lat: 39.43445,
      lng: -121.26554,
      city: 'Brownsville',
      state: 'CA',
    },
    95920: {
      lat: 39.46071,
      lng: -121.93702,
      city: 'Butte City',
      state: 'CA',
    },
    95922: {
      lat: 39.47033,
      lng: -121.07736,
      city: 'Camptonville',
      state: 'CA',
    },
    95923: {
      lat: 40.18385,
      lng: -121.13672,
      city: 'Canyon Dam',
      state: 'CA',
    },
    95925: {
      lat: 39.46798,
      lng: -121.18006,
      city: 'Challenge',
      state: 'CA',
    },
    95926: {
      lat: 39.74573,
      lng: -121.84396,
      city: 'Chico',
      state: 'CA',
    },
    95928: {
      lat: 39.68317,
      lng: -121.83528,
      city: 'Chico',
      state: 'CA',
    },
    95929: {
      lat: 39.72578,
      lng: -121.85022,
      city: 'Chico',
      state: 'CA',
    },
    95930: {
      lat: 39.54679,
      lng: -121.1606,
      city: 'Clipper Mills',
      state: 'CA',
    },
    95932: {
      lat: 39.26839,
      lng: -122.00426,
      city: 'Colusa',
      state: 'CA',
    },
    95934: {
      lat: 40.07463,
      lng: -120.93108,
      city: 'Crescent Mills',
      state: 'CA',
    },
    95935: {
      lat: 39.37369,
      lng: -121.19615,
      city: 'Dobbins',
      state: 'CA',
    },
    95936: {
      lat: 39.56336,
      lng: -120.79279,
      city: 'Downieville',
      state: 'CA',
    },
    95937: {
      lat: 38.88403,
      lng: -121.99847,
      city: 'Dunnigan',
      state: 'CA',
    },
    95938: {
      lat: 39.60504,
      lng: -121.79708,
      city: 'Durham',
      state: 'CA',
    },
    95939: {
      lat: 39.56755,
      lng: -122.60224,
      city: 'Elk Creek',
      state: 'CA',
    },
    95941: {
      lat: 39.5588,
      lng: -121.24157,
      city: 'Forbestown',
      state: 'CA',
    },
    95942: {
      lat: 39.98954,
      lng: -121.58872,
      city: 'Forest Ranch',
      state: 'CA',
    },
    95943: {
      lat: 39.57692,
      lng: -122.0367,
      city: 'Glenn',
      state: 'CA',
    },
    95944: {
      lat: 39.53482,
      lng: -120.86159,
      city: 'Goodyears Bar',
      state: 'CA',
    },
    95945: {
      lat: 39.19426,
      lng: -120.98199,
      city: 'Grass Valley',
      state: 'CA',
    },
    95946: {
      lat: 39.21052,
      lng: -121.20467,
      city: 'Penn Valley',
      state: 'CA',
    },
    95947: {
      lat: 40.15942,
      lng: -120.84409,
      city: 'Greenville',
      state: 'CA',
    },
    95948: {
      lat: 39.34289,
      lng: -121.75818,
      city: 'Gridley',
      state: 'CA',
    },
    95949: {
      lat: 39.102,
      lng: -121.13343,
      city: 'Grass Valley',
      state: 'CA',
    },
    95950: {
      lat: 39.05144,
      lng: -121.92196,
      city: 'Grimes',
      state: 'CA',
    },
    95951: {
      lat: 39.71473,
      lng: -122.00197,
      city: 'Hamilton City',
      state: 'CA',
    },
    95953: {
      lat: 39.25018,
      lng: -121.77309,
      city: 'Live Oak',
      state: 'CA',
    },
    95954: {
      lat: 39.94916,
      lng: -121.54088,
      city: 'Magalia',
      state: 'CA',
    },
    95955: {
      lat: 39.29657,
      lng: -122.20454,
      city: 'Maxwell',
      state: 'CA',
    },
    95956: {
      lat: 39.8751,
      lng: -121.12209,
      city: 'Meadow Valley',
      state: 'CA',
    },
    95957: {
      lat: 39.05366,
      lng: -121.82379,
      city: 'Meridian',
      state: 'CA',
    },
    95958: {
      lat: 39.56417,
      lng: -121.79804,
      city: 'Nelson',
      state: 'CA',
    },
    95959: {
      lat: 39.34509,
      lng: -120.86797,
      city: 'Nevada City',
      state: 'CA',
    },
    95960: {
      lat: 39.39121,
      lng: -121.0583,
      city: 'North San Juan',
      state: 'CA',
    },
    95961: {
      lat: 39.03343,
      lng: -121.55754,
      city: 'Olivehurst',
      state: 'CA',
    },
    95962: {
      lat: 39.32669,
      lng: -121.26876,
      city: 'Oregon House',
      state: 'CA',
    },
    95963: {
      lat: 39.73682,
      lng: -122.30329,
      city: 'Orland',
      state: 'CA',
    },
    95965: {
      lat: 39.59169,
      lng: -121.59034,
      city: 'Oroville',
      state: 'CA',
    },
    95966: {
      lat: 39.47332,
      lng: -121.43284,
      city: 'Oroville',
      state: 'CA',
    },
    95968: {
      lat: 39.43514,
      lng: -121.55179,
      city: 'Palermo',
      state: 'CA',
    },
    95969: {
      lat: 39.72178,
      lng: -121.63388,
      city: 'Paradise',
      state: 'CA',
    },
    95970: {
      lat: 39.41159,
      lng: -122.05176,
      city: 'Princeton',
      state: 'CA',
    },
    95971: {
      lat: 39.92895,
      lng: -120.91608,
      city: 'Quincy',
      state: 'CA',
    },
    95972: {
      lat: 39.42955,
      lng: -121.33624,
      city: 'Rackerby',
      state: 'CA',
    },
    95973: {
      lat: 39.88099,
      lng: -121.8516,
      city: 'Chico',
      state: 'CA',
    },
    95974: {
      lat: 39.4973,
      lng: -121.75569,
      city: 'Richvale',
      state: 'CA',
    },
    95975: {
      lat: 39.22436,
      lng: -121.15367,
      city: 'Rough And Ready',
      state: 'CA',
    },
    95977: {
      lat: 39.17967,
      lng: -121.29007,
      city: 'Smartsville',
      state: 'CA',
    },
    95978: {
      lat: 39.90826,
      lng: -121.52941,
      city: 'Stirling City',
      state: 'CA',
    },
    95979: {
      lat: 39.29771,
      lng: -122.47913,
      city: 'Stonyford',
      state: 'CA',
    },
    95981: {
      lat: 39.65237,
      lng: -121.03447,
      city: 'Strawberry Valley',
      state: 'CA',
    },
    95982: {
      lat: 39.18051,
      lng: -121.80746,
      city: 'Sutter',
      state: 'CA',
    },
    95983: {
      lat: 40.05827,
      lng: -120.73897,
      city: 'Taylorsville',
      state: 'CA',
    },
    95984: {
      lat: 39.99654,
      lng: -121.11951,
      city: 'Twain',
      state: 'CA',
    },
    95986: {
      lat: 39.36149,
      lng: -120.7652,
      city: 'Washington',
      state: 'CA',
    },
    95987: {
      lat: 39.10299,
      lng: -122.2726,
      city: 'Williams',
      state: 'CA',
    },
    95988: {
      lat: 39.49825,
      lng: -122.28698,
      city: 'Willows',
      state: 'CA',
    },
    95991: {
      lat: 39.02205,
      lng: -121.61317,
      city: 'Yuba City',
      state: 'CA',
    },
    95993: {
      lat: 39.08171,
      lng: -121.68798,
      city: 'Yuba City',
      state: 'CA',
    },
    96001: {
      lat: 40.57731,
      lng: -122.45443,
      city: 'Redding',
      state: 'CA',
    },
    96002: {
      lat: 40.5299,
      lng: -122.31679,
      city: 'Redding',
      state: 'CA',
    },
    96003: {
      lat: 40.66664,
      lng: -122.31564,
      city: 'Redding',
      state: 'CA',
    },
    96006: {
      lat: 41.15575,
      lng: -120.83952,
      city: 'Adin',
      state: 'CA',
    },
    96007: {
      lat: 40.45507,
      lng: -122.30468,
      city: 'Anderson',
      state: 'CA',
    },
    96008: {
      lat: 40.73559,
      lng: -122.11091,
      city: 'Bella Vista',
      state: 'CA',
    },
    96009: {
      lat: 41.06183,
      lng: -121.04434,
      city: 'Bieber',
      state: 'CA',
    },
    96010: {
      lat: 40.89735,
      lng: -123.24365,
      city: 'Big Bar',
      state: 'CA',
    },
    96011: {
      lat: 41.05762,
      lng: -121.89157,
      city: 'Big Bend',
      state: 'CA',
    },
    96013: {
      lat: 40.97495,
      lng: -121.68316,
      city: 'Burney',
      state: 'CA',
    },
    96014: {
      lat: 41.3579,
      lng: -122.77305,
      city: 'Callahan',
      state: 'CA',
    },
    96015: {
      lat: 41.49672,
      lng: -120.87162,
      city: 'Canby',
      state: 'CA',
    },
    96016: {
      lat: 40.90675,
      lng: -121.52032,
      city: 'Cassel',
      state: 'CA',
    },
    96017: {
      lat: 41.10776,
      lng: -122.30751,
      city: 'Castella',
      state: 'CA',
    },
    96019: {
      lat: 40.68729,
      lng: -122.39927,
      city: 'Shasta Lake',
      state: 'CA',
    },
    96020: {
      lat: 40.30089,
      lng: -121.26286,
      city: 'Chester',
      state: 'CA',
    },
    96021: {
      lat: 39.91871,
      lng: -122.26813,
      city: 'Corning',
      state: 'CA',
    },
    96022: {
      lat: 40.34232,
      lng: -122.44814,
      city: 'Cottonwood',
      state: 'CA',
    },
    96023: {
      lat: 41.92108,
      lng: -121.97592,
      city: 'Dorris',
      state: 'CA',
    },
    96024: {
      lat: 40.58767,
      lng: -122.90143,
      city: 'Douglas City',
      state: 'CA',
    },
    96025: {
      lat: 41.20409,
      lng: -122.32523,
      city: 'Dunsmuir',
      state: 'CA',
    },
    96027: {
      lat: 41.36558,
      lng: -123.07799,
      city: 'Etna',
      state: 'CA',
    },
    96028: {
      lat: 41.00761,
      lng: -121.45731,
      city: 'Fall River Mills',
      state: 'CA',
    },
    96029: {
      lat: 39.94003,
      lng: -122.48743,
      city: 'Flournoy',
      state: 'CA',
    },
    96031: {
      lat: 41.17262,
      lng: -123.18029,
      city: 'Forks Of Salmon',
      state: 'CA',
    },
    96032: {
      lat: 41.58503,
      lng: -122.98291,
      city: 'Fort Jones',
      state: 'CA',
    },
    96033: {
      lat: 40.75076,
      lng: -122.60004,
      city: 'French Gulch',
      state: 'CA',
    },
    96034: {
      lat: 41.44177,
      lng: -122.60772,
      city: 'Gazelle',
      state: 'CA',
    },
    96035: {
      lat: 40.04168,
      lng: -122.18254,
      city: 'Gerber',
      state: 'CA',
    },
    96037: {
      lat: 41.54368,
      lng: -122.93177,
      city: 'Greenview',
      state: 'CA',
    },
    96038: {
      lat: 41.60827,
      lng: -122.54576,
      city: 'Grenada',
      state: 'CA',
    },
    96039: {
      lat: 41.66376,
      lng: -123.37711,
      city: 'Happy Camp',
      state: 'CA',
    },
    96040: {
      lat: 40.79393,
      lng: -121.47397,
      city: 'Hat Creek',
      state: 'CA',
    },
    96041: {
      lat: 40.52059,
      lng: -123.19991,
      city: 'Hayfork',
      state: 'CA',
    },
    96044: {
      lat: 41.95384,
      lng: -122.64853,
      city: 'Hornbrook',
      state: 'CA',
    },
    96046: {
      lat: 40.61118,
      lng: -123.43151,
      city: 'Hyampom',
      state: 'CA',
    },
    96047: { lat: 40.47695, lng: -122.69889, city: 'Igo', state: 'CA' },
    96048: {
      lat: 40.82613,
      lng: -123.05594,
      city: 'Junction City',
      state: 'CA',
    },
    96050: {
      lat: 41.85389,
      lng: -122.95689,
      city: 'Klamath River',
      state: 'CA',
    },
    96051: {
      lat: 40.90112,
      lng: -122.35664,
      city: 'Lakehead',
      state: 'CA',
    },
    96052: {
      lat: 40.68515,
      lng: -122.80342,
      city: 'Lewiston',
      state: 'CA',
    },
    96054: {
      lat: 41.26665,
      lng: -121.10633,
      city: 'Lookout',
      state: 'CA',
    },
    96055: {
      lat: 40.10249,
      lng: -122.03213,
      city: 'Los Molinos',
      state: 'CA',
    },
    96056: {
      lat: 41.07178,
      lng: -121.32607,
      city: 'Mcarthur',
      state: 'CA',
    },
    96057: {
      lat: 41.26144,
      lng: -122.0118,
      city: 'Mccloud',
      state: 'CA',
    },
    96058: {
      lat: 41.74536,
      lng: -121.9337,
      city: 'Macdoel',
      state: 'CA',
    },
    96059: {
      lat: 40.42947,
      lng: -121.83465,
      city: 'Manton',
      state: 'CA',
    },
    96061: {
      lat: 40.34407,
      lng: -121.48767,
      city: 'Mill Creek',
      state: 'CA',
    },
    96062: {
      lat: 40.56373,
      lng: -122.06646,
      city: 'Millville',
      state: 'CA',
    },
    96063: {
      lat: 40.35708,
      lng: -121.60236,
      city: 'Mineral',
      state: 'CA',
    },
    96064: {
      lat: 41.76371,
      lng: -122.36433,
      city: 'Montague',
      state: 'CA',
    },
    96065: {
      lat: 40.88056,
      lng: -121.95433,
      city: 'Montgomery Creek',
      state: 'CA',
    },
    96067: {
      lat: 41.31664,
      lng: -122.31736,
      city: 'Mount Shasta',
      state: 'CA',
    },
    96068: {
      lat: 41.10525,
      lng: -121.20455,
      city: 'Nubieber',
      state: 'CA',
    },
    96069: {
      lat: 40.68226,
      lng: -122.02341,
      city: 'Oak Run',
      state: 'CA',
    },
    96071: {
      lat: 40.68134,
      lng: -121.45275,
      city: 'Old Station',
      state: 'CA',
    },
    96073: {
      lat: 40.59047,
      lng: -122.20537,
      city: 'Palo Cedro',
      state: 'CA',
    },
    96074: {
      lat: 39.87196,
      lng: -122.58599,
      city: 'Paskenta',
      state: 'CA',
    },
    96075: {
      lat: 40.35582,
      lng: -121.87196,
      city: 'Paynes Creek',
      state: 'CA',
    },
    96076: {
      lat: 40.37547,
      lng: -122.92996,
      city: 'Platina',
      state: 'CA',
    },
    96078: {
      lat: 40.08245,
      lng: -122.17607,
      city: 'Proberta',
      state: 'CA',
    },
    96080: {
      lat: 40.16386,
      lng: -122.39171,
      city: 'Red Bluff',
      state: 'CA',
    },
    96084: {
      lat: 40.79395,
      lng: -121.91519,
      city: 'Round Mountain',
      state: 'CA',
    },
    96085: {
      lat: 41.73245,
      lng: -123.05145,
      city: 'Scott Bar',
      state: 'CA',
    },
    96086: {
      lat: 41.87407,
      lng: -123.23012,
      city: 'Seiad Valley',
      state: 'CA',
    },
    96087: {
      lat: 40.63296,
      lng: -122.58067,
      city: 'Shasta',
      state: 'CA',
    },
    96088: {
      lat: 40.50983,
      lng: -121.88848,
      city: 'Shingletown',
      state: 'CA',
    },
    96090: {
      lat: 40.00681,
      lng: -122.12142,
      city: 'Tehama',
      state: 'CA',
    },
    96091: {
      lat: 41.04567,
      lng: -122.78053,
      city: 'Trinity Center',
      state: 'CA',
    },
    96092: {
      lat: 39.97344,
      lng: -121.97342,
      city: 'Vina',
      state: 'CA',
    },
    96093: {
      lat: 40.77237,
      lng: -122.94329,
      city: 'Weaverville',
      state: 'CA',
    },
    96094: {
      lat: 41.51214,
      lng: -122.36253,
      city: 'Weed',
      state: 'CA',
    },
    96096: {
      lat: 40.63738,
      lng: -121.87552,
      city: 'Whitmore',
      state: 'CA',
    },
    96097: {
      lat: 41.76234,
      lng: -122.65921,
      city: 'Yreka',
      state: 'CA',
    },
    96101: {
      lat: 41.45803,
      lng: -120.5043,
      city: 'Alturas',
      state: 'CA',
    },
    96103: {
      lat: 39.78446,
      lng: -120.66969,
      city: 'Blairsden Graeagle',
      state: 'CA',
    },
    96104: {
      lat: 41.48248,
      lng: -120.12725,
      city: 'Cedarville',
      state: 'CA',
    },
    96105: {
      lat: 39.82478,
      lng: -120.1821,
      city: 'Chilcoot',
      state: 'CA',
    },
    96106: {
      lat: 39.73552,
      lng: -120.54388,
      city: 'Clio',
      state: 'CA',
    },
    96107: {
      lat: 38.45215,
      lng: -119.51421,
      city: 'Coleville',
      state: 'CA',
    },
    96108: {
      lat: 41.77687,
      lng: -120.42351,
      city: 'Davis Creek',
      state: 'CA',
    },
    96109: {
      lat: 39.99747,
      lng: -120.09521,
      city: 'Doyle',
      state: 'CA',
    },
    96110: {
      lat: 41.26637,
      lng: -120.10763,
      city: 'Eagleville',
      state: 'CA',
    },
    96111: {
      lat: 39.41571,
      lng: -120.01648,
      city: 'Floriston',
      state: 'CA',
    },
    96112: {
      lat: 41.8372,
      lng: -120.09403,
      city: 'Fort Bidwell',
      state: 'CA',
    },
    96113: {
      lat: 40.18577,
      lng: -120.14609,
      city: 'Herlong',
      state: 'CA',
    },
    96114: {
      lat: 40.2945,
      lng: -120.49359,
      city: 'Janesville',
      state: 'CA',
    },
    96115: {
      lat: 41.70753,
      lng: -120.14047,
      city: 'Lake City',
      state: 'CA',
    },
    96116: {
      lat: 41.2603,
      lng: -120.44841,
      city: 'Likely',
      state: 'CA',
    },
    96117: {
      lat: 40.54556,
      lng: -120.23978,
      city: 'Litchfield',
      state: 'CA',
    },
    96118: {
      lat: 39.64823,
      lng: -120.25392,
      city: 'Loyalton',
      state: 'CA',
    },
    96119: {
      lat: 41.03353,
      lng: -120.55264,
      city: 'Madeline',
      state: 'CA',
    },
    96120: {
      lat: 38.745,
      lng: -119.82485,
      city: 'Markleeville',
      state: 'CA',
    },
    96121: {
      lat: 40.16938,
      lng: -120.37659,
      city: 'Milford',
      state: 'CA',
    },
    96122: {
      lat: 39.82082,
      lng: -120.47652,
      city: 'Portola',
      state: 'CA',
    },
    96123: {
      lat: 40.80392,
      lng: -120.30799,
      city: 'Ravendale',
      state: 'CA',
    },
    96124: {
      lat: 39.63918,
      lng: -120.46194,
      city: 'Calpine',
      state: 'CA',
    },
    96125: {
      lat: 39.61526,
      lng: -120.60493,
      city: 'Sierra City',
      state: 'CA',
    },
    96126: {
      lat: 39.54764,
      lng: -120.38999,
      city: 'Sierraville',
      state: 'CA',
    },
    96128: {
      lat: 40.34948,
      lng: -120.40198,
      city: 'Standish',
      state: 'CA',
    },
    96129: {
      lat: 39.81848,
      lng: -120.35346,
      city: 'Beckwourth',
      state: 'CA',
    },
    96130: {
      lat: 40.54992,
      lng: -120.63064,
      city: 'Susanville',
      state: 'CA',
    },
    96132: {
      lat: 40.89371,
      lng: -120.5389,
      city: 'Termo',
      state: 'CA',
    },
    96133: {
      lat: 38.63181,
      lng: -119.50423,
      city: 'Topaz',
      state: 'CA',
    },
    96134: {
      lat: 41.8444,
      lng: -121.41231,
      city: 'Tulelake',
      state: 'CA',
    },
    96135: {
      lat: 39.81303,
      lng: -120.20927,
      city: 'Vinton',
      state: 'CA',
    },
    96136: {
      lat: 40.31263,
      lng: -120.12425,
      city: 'Wendel',
      state: 'CA',
    },
    96137: {
      lat: 40.38554,
      lng: -121.12478,
      city: 'Westwood',
      state: 'CA',
    },
    96140: {
      lat: 39.23244,
      lng: -120.10364,
      city: 'Carnelian Bay',
      state: 'CA',
    },
    96141: {
      lat: 39.10297,
      lng: -120.19872,
      city: 'Homewood',
      state: 'CA',
    },
    96142: {
      lat: 39.0138,
      lng: -120.09592,
      city: 'Tahoma',
      state: 'CA',
    },
    96143: {
      lat: 39.26174,
      lng: -120.02817,
      city: 'Kings Beach',
      state: 'CA',
    },
    96145: {
      lat: 39.18203,
      lng: -120.15143,
      city: 'Tahoe City',
      state: 'CA',
    },
    96146: {
      lat: 39.22556,
      lng: -120.23681,
      city: 'Olympic Valley',
      state: 'CA',
    },
    96148: {
      lat: 39.24978,
      lng: -120.05767,
      city: 'Tahoe Vista',
      state: 'CA',
    },
    96150: {
      lat: 38.88971,
      lng: -120.04409,
      city: 'South Lake Tahoe',
      state: 'CA',
    },
    96155: {
      lat: 38.75294,
      lng: -120.09222,
      city: 'South Lake Tahoe',
      state: 'CA',
    },
    96161: {
      lat: 39.34848,
      lng: -120.22603,
      city: 'Truckee',
      state: 'CA',
    },
    96701: {
      lat: 21.40368,
      lng: -157.89875,
      city: 'Aiea',
      state: 'HI',
    },
    96703: {
      lat: 22.1349,
      lng: -159.37152,
      city: 'Anahola',
      state: 'HI',
    },
    96704: {
      lat: 19.30612,
      lng: -155.83884,
      city: 'Captain Cook',
      state: 'HI',
    },
    96705: {
      lat: 21.89973,
      lng: -159.56252,
      city: 'Eleele',
      state: 'HI',
    },
    96706: {
      lat: 21.34033,
      lng: -158.01704,
      city: 'Ewa Beach',
      state: 'HI',
    },
    96707: {
      lat: 21.36455,
      lng: -158.08141,
      city: 'Kapolei',
      state: 'HI',
    },
    96708: {
      lat: 20.88083,
      lng: -156.23763,
      city: 'Haiku',
      state: 'HI',
    },
    96710: {
      lat: 19.85516,
      lng: -155.21784,
      city: 'Hakalau',
      state: 'HI',
    },
    96712: {
      lat: 21.626,
      lng: -158.03534,
      city: 'Haleiwa',
      state: 'HI',
    },
    96713: { lat: 20.73684, lng: -156.1159, city: 'Hana', state: 'HI' },
    96714: {
      lat: 22.15734,
      lng: -159.54472,
      city: 'Hanalei',
      state: 'HI',
    },
    96716: {
      lat: 21.91167,
      lng: -159.60134,
      city: 'Hanapepe',
      state: 'HI',
    },
    96717: {
      lat: 21.55556,
      lng: -157.89605,
      city: 'Hauula',
      state: 'HI',
    },
    96719: {
      lat: 20.24005,
      lng: -155.83344,
      city: 'Hawi',
      state: 'HI',
    },
    96720: {
      lat: 19.67022,
      lng: -155.21718,
      city: 'Hilo',
      state: 'HI',
    },
    96722: {
      lat: 22.16689,
      lng: -159.45826,
      city: 'Princeville',
      state: 'HI',
    },
    96725: {
      lat: 19.61243,
      lng: -155.8824,
      city: 'Holualoa',
      state: 'HI',
    },
    96726: {
      lat: 19.42147,
      lng: -155.81094,
      city: 'Honaunau',
      state: 'HI',
    },
    96727: {
      lat: 20.00803,
      lng: -155.54842,
      city: 'Honokaa',
      state: 'HI',
    },
    96728: {
      lat: 19.85991,
      lng: -155.13339,
      city: 'Honomu',
      state: 'HI',
    },
    96729: {
      lat: 21.17214,
      lng: -157.11722,
      city: 'Hoolehua',
      state: 'HI',
    },
    96730: {
      lat: 21.54615,
      lng: -157.85112,
      city: 'Kaaawa',
      state: 'HI',
    },
    96731: {
      lat: 21.68488,
      lng: -157.96999,
      city: 'Kahuku',
      state: 'HI',
    },
    96732: {
      lat: 20.87593,
      lng: -156.46092,
      city: 'Kahului',
      state: 'HI',
    },
    96734: {
      lat: 21.39002,
      lng: -157.75387,
      city: 'Kailua',
      state: 'HI',
    },
    96737: {
      lat: 19.10735,
      lng: -155.78062,
      city: 'Ocean View',
      state: 'HI',
    },
    96738: {
      lat: 19.90341,
      lng: -155.79392,
      city: 'Waikoloa',
      state: 'HI',
    },
    96740: {
      lat: 19.74422,
      lng: -155.89951,
      city: 'Kailua Kona',
      state: 'HI',
    },
    96741: {
      lat: 21.94986,
      lng: -159.51847,
      city: 'Kalaheo',
      state: 'HI',
    },
    96742: {
      lat: 21.17089,
      lng: -156.94753,
      city: 'Kalaupapa',
      state: 'HI',
    },
    96743: {
      lat: 20.02619,
      lng: -155.72266,
      city: 'Kamuela',
      state: 'HI',
    },
    96744: {
      lat: 21.4427,
      lng: -157.83328,
      city: 'Kaneohe',
      state: 'HI',
    },
    96746: {
      lat: 22.09167,
      lng: -159.34951,
      city: 'Kapaa',
      state: 'HI',
    },
    96747: {
      lat: 21.9169,
      lng: -159.62788,
      city: 'Kaumakani',
      state: 'HI',
    },
    96748: {
      lat: 21.1138,
      lng: -156.8794,
      city: 'Kaunakakai',
      state: 'HI',
    },
    96749: {
      lat: 19.61031,
      lng: -155.00281,
      city: 'Keaau',
      state: 'HI',
    },
    96750: {
      lat: 19.52404,
      lng: -155.91463,
      city: 'Kealakekua',
      state: 'HI',
    },
    96751: {
      lat: 22.10668,
      lng: -159.30516,
      city: 'Kealia',
      state: 'HI',
    },
    96752: {
      lat: 22.07823,
      lng: -159.72077,
      city: 'Kekaha',
      state: 'HI',
    },
    96753: {
      lat: 20.7317,
      lng: -156.43606,
      city: 'Kihei',
      state: 'HI',
    },
    96754: {
      lat: 22.18537,
      lng: -159.40246,
      city: 'Kilauea',
      state: 'HI',
    },
    96755: {
      lat: 20.19602,
      lng: -155.78158,
      city: 'Kapaau',
      state: 'HI',
    },
    96756: {
      lat: 21.9085,
      lng: -159.45368,
      city: 'Koloa',
      state: 'HI',
    },
    96757: {
      lat: 21.15536,
      lng: -156.99513,
      city: 'Kualapuu',
      state: 'HI',
    },
    96759: {
      lat: 21.46015,
      lng: -158.05995,
      city: 'Kunia',
      state: 'HI',
    },
    96760: {
      lat: 19.55234,
      lng: -155.05483,
      city: 'Kurtistown',
      state: 'HI',
    },
    96761: {
      lat: 20.90961,
      lng: -156.62226,
      city: 'Lahaina',
      state: 'HI',
    },
    96762: {
      lat: 21.62468,
      lng: -157.94201,
      city: 'Laie',
      state: 'HI',
    },
    96763: {
      lat: 20.83512,
      lng: -156.92717,
      city: 'Lanai City',
      state: 'HI',
    },
    96764: {
      lat: 19.97766,
      lng: -155.23985,
      city: 'Laupahoehoe',
      state: 'HI',
    },
    96765: {
      lat: 21.92608,
      lng: -159.49831,
      city: 'Lawai',
      state: 'HI',
    },
    96766: {
      lat: 22.00556,
      lng: -159.41931,
      city: 'Lihue',
      state: 'HI',
    },
    96768: {
      lat: 20.83231,
      lng: -156.29787,
      city: 'Makawao',
      state: 'HI',
    },
    96769: {
      lat: 21.95501,
      lng: -159.61655,
      city: 'Makaweli',
      state: 'HI',
    },
    96770: {
      lat: 21.13465,
      lng: -157.20354,
      city: 'Maunaloa',
      state: 'HI',
    },
    96771: {
      lat: 19.53348,
      lng: -155.13573,
      city: 'Mountain View',
      state: 'HI',
    },
    96772: {
      lat: 19.0683,
      lng: -155.66077,
      city: 'Naalehu',
      state: 'HI',
    },
    96773: {
      lat: 19.90677,
      lng: -155.25218,
      city: 'Ninole',
      state: 'HI',
    },
    96774: {
      lat: 19.96871,
      lng: -155.31503,
      city: 'Ookala',
      state: 'HI',
    },
    96776: {
      lat: 20.00156,
      lng: -155.38777,
      city: 'Paauilo',
      state: 'HI',
    },
    96777: {
      lat: 19.23137,
      lng: -155.4705,
      city: 'Pahala',
      state: 'HI',
    },
    96778: {
      lat: 19.45221,
      lng: -154.98624,
      city: 'Pahoa',
      state: 'HI',
    },
    96779: {
      lat: 20.90534,
      lng: -156.38208,
      city: 'Paia',
      state: 'HI',
    },
    96780: {
      lat: 19.96592,
      lng: -155.225,
      city: 'Papaaloa',
      state: 'HI',
    },
    96781: {
      lat: 19.80066,
      lng: -155.18194,
      city: 'Papaikou',
      state: 'HI',
    },
    96782: {
      lat: 21.43471,
      lng: -157.94694,
      city: 'Pearl City',
      state: 'HI',
    },
    96783: {
      lat: 19.84293,
      lng: -155.1074,
      city: 'Pepeekeo',
      state: 'HI',
    },
    96785: {
      lat: 19.47818,
      lng: -155.23017,
      city: 'Volcano',
      state: 'HI',
    },
    96786: {
      lat: 21.53733,
      lng: -158.02331,
      city: 'Wahiawa',
      state: 'HI',
    },
    96789: {
      lat: 21.47823,
      lng: -157.97264,
      city: 'Mililani',
      state: 'HI',
    },
    96790: { lat: 20.6975, lng: -156.31121, city: 'Kula', state: 'HI' },
    96791: {
      lat: 21.55072,
      lng: -158.15857,
      city: 'Waialua',
      state: 'HI',
    },
    96792: {
      lat: 21.46311,
      lng: -158.16418,
      city: 'Waianae',
      state: 'HI',
    },
    96793: {
      lat: 20.88998,
      lng: -156.53215,
      city: 'Wailuku',
      state: 'HI',
    },
    96795: {
      lat: 21.33934,
      lng: -157.71643,
      city: 'Waimanalo',
      state: 'HI',
    },
    96796: {
      lat: 21.96512,
      lng: -159.66793,
      city: 'Waimea',
      state: 'HI',
    },
    96797: {
      lat: 21.40734,
      lng: -158.01658,
      city: 'Waipahu',
      state: 'HI',
    },
    96799: {
      lat: -14.21984,
      lng: -170.37005,
      city: 'Pago Pago',
      state: 'AS',
    },
    96813: {
      lat: 21.31628,
      lng: -157.84359,
      city: 'Honolulu',
      state: 'HI',
    },
    96814: {
      lat: 21.29405,
      lng: -157.84637,
      city: 'Honolulu',
      state: 'HI',
    },
    96815: {
      lat: 21.27375,
      lng: -157.82108,
      city: 'Honolulu',
      state: 'HI',
    },
    96816: {
      lat: 21.29379,
      lng: -157.79009,
      city: 'Honolulu',
      state: 'HI',
    },
    96817: {
      lat: 21.34016,
      lng: -157.83749,
      city: 'Honolulu',
      state: 'HI',
    },
    96818: {
      lat: 21.34637,
      lng: -157.93704,
      city: 'Honolulu',
      state: 'HI',
    },
    96819: {
      lat: 21.34968,
      lng: -157.87527,
      city: 'Honolulu',
      state: 'HI',
    },
    96820: {
      lat: 21.33633,
      lng: -157.92071,
      city: 'Honolulu',
      state: 'HI',
    },
    96821: {
      lat: 21.29952,
      lng: -157.75004,
      city: 'Honolulu',
      state: 'HI',
    },
    96822: {
      lat: 21.32046,
      lng: -157.81037,
      city: 'Honolulu',
      state: 'HI',
    },
    96825: {
      lat: 21.29575,
      lng: -157.69234,
      city: 'Honolulu',
      state: 'HI',
    },
    96826: {
      lat: 21.29171,
      lng: -157.82698,
      city: 'Honolulu',
      state: 'HI',
    },
    96848: {
      lat: 21.30131,
      lng: -157.81419,
      city: 'Honolulu',
      state: 'HI',
    },
    96850: {
      lat: 21.30385,
      lng: -157.86258,
      city: 'Honolulu',
      state: 'HI',
    },
    96853: { lat: 21.335, lng: -157.94032, city: 'Jbphh', state: 'HI' },
    96857: {
      lat: 21.48798,
      lng: -158.05293,
      city: 'Schofield Barracks',
      state: 'HI',
    },
    96858: {
      lat: 21.34459,
      lng: -157.88342,
      city: 'Fort Shafter',
      state: 'HI',
    },
    96859: {
      lat: 21.35987,
      lng: -157.88985,
      city: 'Tripler Army Medical Center',
      state: 'HI',
    },
    96860: {
      lat: 21.35687,
      lng: -157.94829,
      city: 'Jbphh',
      state: 'HI',
    },
    96863: {
      lat: 21.4351,
      lng: -157.75002,
      city: 'Mcbh Kaneohe Bay',
      state: 'HI',
    },
    96910: {
      lat: 13.45472,
      lng: 144.75128,
      city: 'Hagatna',
      state: 'GU',
    },
    96913: {
      lat: 13.47825,
      lng: 144.81519,
      city: 'Barrigada',
      state: 'GU',
    },
    96915: {
      lat: 13.37526,
      lng: 144.70714,
      city: 'Santa Rita',
      state: 'GU',
    },
    96916: {
      lat: 13.26593,
      lng: 144.68915,
      city: 'Merizo',
      state: 'GU',
    },
    96917: {
      lat: 13.28469,
      lng: 144.73991,
      city: 'Inarajan',
      state: 'GU',
    },
    96928: { lat: 13.35626, lng: 144.65731, city: 'Agat', state: 'GU' },
    96929: { lat: 13.56521, lng: 144.87637, city: 'Yigo', state: 'GU' },
    96950: {
      lat: 15.18888,
      lng: 145.75349,
      city: 'Saipan',
      state: 'MP',
    },
    96951: { lat: 14.15733, lng: 145.2145, city: 'Rota', state: 'MP' },
    96952: {
      lat: 15.01311,
      lng: 145.63149,
      city: 'Tinian',
      state: 'MP',
    },
    97001: {
      lat: 44.93328,
      lng: -120.64169,
      city: 'Antelope',
      state: 'OR',
    },
    97002: {
      lat: 45.23774,
      lng: -122.8003,
      city: 'Aurora',
      state: 'OR',
    },
    97003: {
      lat: 45.50372,
      lng: -122.86482,
      city: 'Beaverton',
      state: 'OR',
    },
    97004: {
      lat: 45.25068,
      lng: -122.45399,
      city: 'Beavercreek',
      state: 'OR',
    },
    97005: {
      lat: 45.49144,
      lng: -122.80397,
      city: 'Beaverton',
      state: 'OR',
    },
    97006: {
      lat: 45.52598,
      lng: -122.85591,
      city: 'Beaverton',
      state: 'OR',
    },
    97007: {
      lat: 45.44282,
      lng: -122.87112,
      city: 'Beaverton',
      state: 'OR',
    },
    97008: {
      lat: 45.46044,
      lng: -122.80252,
      city: 'Beaverton',
      state: 'OR',
    },
    97009: {
      lat: 45.42409,
      lng: -122.33962,
      city: 'Boring',
      state: 'OR',
    },
    97011: {
      lat: 45.38738,
      lng: -122.03671,
      city: 'Brightwood',
      state: 'OR',
    },
    97013: {
      lat: 45.21799,
      lng: -122.67277,
      city: 'Canby',
      state: 'OR',
    },
    97014: {
      lat: 45.61354,
      lng: -121.87405,
      city: 'Cascade Locks',
      state: 'OR',
    },
    97015: {
      lat: 45.41477,
      lng: -122.53767,
      city: 'Clackamas',
      state: 'OR',
    },
    97016: {
      lat: 46.05573,
      lng: -123.27032,
      city: 'Clatskanie',
      state: 'OR',
    },
    97017: {
      lat: 45.17017,
      lng: -122.37676,
      city: 'Colton',
      state: 'OR',
    },
    97018: {
      lat: 45.8957,
      lng: -122.81165,
      city: 'Columbia City',
      state: 'OR',
    },
    97019: {
      lat: 45.494,
      lng: -122.18885,
      city: 'Corbett',
      state: 'OR',
    },
    97020: {
      lat: 45.22267,
      lng: -122.83887,
      city: 'Donald',
      state: 'OR',
    },
    97021: {
      lat: 45.39048,
      lng: -121.13425,
      city: 'Dufur',
      state: 'OR',
    },
    97022: {
      lat: 45.34562,
      lng: -122.3306,
      city: 'Eagle Creek',
      state: 'OR',
    },
    97023: {
      lat: 45.19147,
      lng: -122.20501,
      city: 'Estacada',
      state: 'OR',
    },
    97024: {
      lat: 45.54576,
      lng: -122.44142,
      city: 'Fairview',
      state: 'OR',
    },
    97026: {
      lat: 45.10409,
      lng: -122.94251,
      city: 'Gervais',
      state: 'OR',
    },
    97027: {
      lat: 45.38697,
      lng: -122.59271,
      city: 'Gladstone',
      state: 'OR',
    },
    97028: {
      lat: 45.28225,
      lng: -121.75274,
      city: 'Government Camp',
      state: 'OR',
    },
    97029: {
      lat: 45.29901,
      lng: -120.81003,
      city: 'Grass Valley',
      state: 'OR',
    },
    97030: {
      lat: 45.50896,
      lng: -122.43135,
      city: 'Gresham',
      state: 'OR',
    },
    97031: {
      lat: 45.61193,
      lng: -121.61094,
      city: 'Hood River',
      state: 'OR',
    },
    97032: {
      lat: 45.17949,
      lng: -122.78559,
      city: 'Hubbard',
      state: 'OR',
    },
    97033: { lat: 45.23361, lng: -120.5876, city: 'Kent', state: 'OR' },
    97034: {
      lat: 45.4095,
      lng: -122.68467,
      city: 'Lake Oswego',
      state: 'OR',
    },
    97035: {
      lat: 45.41291,
      lng: -122.72616,
      city: 'Lake Oswego',
      state: 'OR',
    },
    97037: {
      lat: 45.06567,
      lng: -121.03141,
      city: 'Maupin',
      state: 'OR',
    },
    97038: {
      lat: 45.08575,
      lng: -122.55288,
      city: 'Molalla',
      state: 'OR',
    },
    97039: {
      lat: 45.47226,
      lng: -120.62428,
      city: 'Moro',
      state: 'OR',
    },
    97040: {
      lat: 45.62648,
      lng: -121.38476,
      city: 'Mosier',
      state: 'OR',
    },
    97041: {
      lat: 45.41655,
      lng: -121.59617,
      city: 'Mount Hood Parkdale',
      state: 'OR',
    },
    97042: {
      lat: 45.20679,
      lng: -122.53247,
      city: 'Mulino',
      state: 'OR',
    },
    97045: {
      lat: 45.32834,
      lng: -122.53058,
      city: 'Oregon City',
      state: 'OR',
    },
    97048: {
      lat: 46.03905,
      lng: -122.99483,
      city: 'Rainier',
      state: 'OR',
    },
    97049: {
      lat: 45.35931,
      lng: -121.86829,
      city: 'Rhododendron',
      state: 'OR',
    },
    97050: {
      lat: 45.68256,
      lng: -120.7727,
      city: 'Rufus',
      state: 'OR',
    },
    97051: {
      lat: 45.88902,
      lng: -122.92011,
      city: 'Saint Helens',
      state: 'OR',
    },
    97053: {
      lat: 45.82695,
      lng: -122.88401,
      city: 'Warren',
      state: 'OR',
    },
    97054: {
      lat: 45.95406,
      lng: -122.92995,
      city: 'Deer Island',
      state: 'OR',
    },
    97055: {
      lat: 45.35975,
      lng: -122.15396,
      city: 'Sandy',
      state: 'OR',
    },
    97056: {
      lat: 45.78935,
      lng: -122.96941,
      city: 'Scappoose',
      state: 'OR',
    },
    97057: {
      lat: 45.00422,
      lng: -120.7516,
      city: 'Shaniko',
      state: 'OR',
    },
    97058: {
      lat: 45.54239,
      lng: -121.15447,
      city: 'The Dalles',
      state: 'OR',
    },
    97060: {
      lat: 45.5342,
      lng: -122.36989,
      city: 'Troutdale',
      state: 'OR',
    },
    97062: {
      lat: 45.36821,
      lng: -122.76169,
      city: 'Tualatin',
      state: 'OR',
    },
    97063: {
      lat: 45.22319,
      lng: -121.28306,
      city: 'Tygh Valley',
      state: 'OR',
    },
    97064: {
      lat: 45.87276,
      lng: -123.2309,
      city: 'Vernonia',
      state: 'OR',
    },
    97065: {
      lat: 45.59803,
      lng: -120.65696,
      city: 'Wasco',
      state: 'OR',
    },
    97067: {
      lat: 45.31302,
      lng: -121.97733,
      city: 'Welches',
      state: 'OR',
    },
    97068: {
      lat: 45.35195,
      lng: -122.66876,
      city: 'West Linn',
      state: 'OR',
    },
    97070: {
      lat: 45.308,
      lng: -122.77059,
      city: 'Wilsonville',
      state: 'OR',
    },
    97071: {
      lat: 45.13588,
      lng: -122.82344,
      city: 'Woodburn',
      state: 'OR',
    },
    97078: {
      lat: 45.47628,
      lng: -122.89088,
      city: 'Beaverton',
      state: 'OR',
    },
    97080: {
      lat: 45.47848,
      lng: -122.39001,
      city: 'Gresham',
      state: 'OR',
    },
    97086: {
      lat: 45.44531,
      lng: -122.52793,
      city: 'Happy Valley',
      state: 'OR',
    },
    97089: {
      lat: 45.4256,
      lng: -122.44342,
      city: 'Damascus',
      state: 'OR',
    },
    97101: {
      lat: 45.09225,
      lng: -123.21557,
      city: 'Amity',
      state: 'OR',
    },
    97102: {
      lat: 45.7934,
      lng: -123.94793,
      city: 'Arch Cape',
      state: 'OR',
    },
    97103: {
      lat: 46.13327,
      lng: -123.69648,
      city: 'Astoria',
      state: 'OR',
    },
    97106: {
      lat: 45.65419,
      lng: -123.13417,
      city: 'Banks',
      state: 'OR',
    },
    97107: {
      lat: 45.56172,
      lng: -123.88291,
      city: 'Bay City',
      state: 'OR',
    },
    97108: {
      lat: 45.27423,
      lng: -123.67544,
      city: 'Beaver',
      state: 'OR',
    },
    97109: {
      lat: 45.73777,
      lng: -123.1946,
      city: 'Buxton',
      state: 'OR',
    },
    97110: {
      lat: 45.89186,
      lng: -123.95128,
      city: 'Cannon Beach',
      state: 'OR',
    },
    97111: {
      lat: 45.29012,
      lng: -123.19855,
      city: 'Carlton',
      state: 'OR',
    },
    97112: {
      lat: 45.26218,
      lng: -123.86629,
      city: 'Cloverdale',
      state: 'OR',
    },
    97113: {
      lat: 45.50746,
      lng: -123.04544,
      city: 'Cornelius',
      state: 'OR',
    },
    97114: {
      lat: 45.18381,
      lng: -123.07172,
      city: 'Dayton',
      state: 'OR',
    },
    97115: {
      lat: 45.27573,
      lng: -123.02682,
      city: 'Dundee',
      state: 'OR',
    },
    97116: {
      lat: 45.59054,
      lng: -123.18876,
      city: 'Forest Grove',
      state: 'OR',
    },
    97117: {
      lat: 45.62189,
      lng: -123.31132,
      city: 'Gales Creek',
      state: 'OR',
    },
    97118: {
      lat: 45.55697,
      lng: -123.91619,
      city: 'Garibaldi',
      state: 'OR',
    },
    97119: {
      lat: 45.4717,
      lng: -123.20781,
      city: 'Gaston',
      state: 'OR',
    },
    97121: {
      lat: 46.19347,
      lng: -123.97141,
      city: 'Hammond',
      state: 'OR',
    },
    97122: { lat: 45.17331, lng: -123.8164, city: 'Hebo', state: 'OR' },
    97123: {
      lat: 45.44353,
      lng: -122.98048,
      city: 'Hillsboro',
      state: 'OR',
    },
    97124: {
      lat: 45.57088,
      lng: -122.94584,
      city: 'Hillsboro',
      state: 'OR',
    },
    97125: {
      lat: 45.66243,
      lng: -123.21117,
      city: 'Manning',
      state: 'OR',
    },
    97127: {
      lat: 45.24629,
      lng: -123.11159,
      city: 'Lafayette',
      state: 'OR',
    },
    97128: {
      lat: 45.19814,
      lng: -123.25812,
      city: 'Mcminnville',
      state: 'OR',
    },
    97130: {
      lat: 45.71592,
      lng: -123.93318,
      city: 'Manzanita',
      state: 'OR',
    },
    97131: {
      lat: 45.71495,
      lng: -123.8107,
      city: 'Nehalem',
      state: 'OR',
    },
    97132: {
      lat: 45.32272,
      lng: -122.98854,
      city: 'Newberg',
      state: 'OR',
    },
    97133: {
      lat: 45.68719,
      lng: -123.02861,
      city: 'North Plains',
      state: 'OR',
    },
    97134: {
      lat: 45.45587,
      lng: -123.96513,
      city: 'Oceanside',
      state: 'OR',
    },
    97135: {
      lat: 45.18749,
      lng: -123.95494,
      city: 'Pacific City',
      state: 'OR',
    },
    97136: {
      lat: 45.6341,
      lng: -123.91353,
      city: 'Rockaway Beach',
      state: 'OR',
    },
    97137: {
      lat: 45.21028,
      lng: -122.9635,
      city: 'Saint Paul',
      state: 'OR',
    },
    97138: {
      lat: 45.90946,
      lng: -123.70036,
      city: 'Seaside',
      state: 'OR',
    },
    97140: {
      lat: 45.35359,
      lng: -122.86339,
      city: 'Sherwood',
      state: 'OR',
    },
    97141: {
      lat: 45.47142,
      lng: -123.73791,
      city: 'Tillamook',
      state: 'OR',
    },
    97144: {
      lat: 45.74349,
      lng: -123.30305,
      city: 'Timber',
      state: 'OR',
    },
    97145: {
      lat: 45.84357,
      lng: -123.9516,
      city: 'Tolovana Park',
      state: 'OR',
    },
    97146: {
      lat: 46.11849,
      lng: -123.91399,
      city: 'Warrenton',
      state: 'OR',
    },
    97147: {
      lat: 45.6871,
      lng: -123.8873,
      city: 'Wheeler',
      state: 'OR',
    },
    97148: {
      lat: 45.35988,
      lng: -123.26553,
      city: 'Yamhill',
      state: 'OR',
    },
    97149: {
      lat: 45.09957,
      lng: -123.94455,
      city: 'Neskowin',
      state: 'OR',
    },
    97201: {
      lat: 45.50796,
      lng: -122.68959,
      city: 'Portland',
      state: 'OR',
    },
    97202: {
      lat: 45.48232,
      lng: -122.64365,
      city: 'Portland',
      state: 'OR',
    },
    97203: {
      lat: 45.60764,
      lng: -122.74923,
      city: 'Portland',
      state: 'OR',
    },
    97204: {
      lat: 45.5183,
      lng: -122.67378,
      city: 'Portland',
      state: 'OR',
    },
    97205: {
      lat: 45.51924,
      lng: -122.70127,
      city: 'Portland',
      state: 'OR',
    },
    97206: {
      lat: 45.48173,
      lng: -122.5988,
      city: 'Portland',
      state: 'OR',
    },
    97208: {
      lat: 45.52866,
      lng: -122.67898,
      city: 'Portland',
      state: 'OR',
    },
    97209: {
      lat: 45.53093,
      lng: -122.68429,
      city: 'Portland',
      state: 'OR',
    },
    97210: {
      lat: 45.54954,
      lng: -122.73549,
      city: 'Portland',
      state: 'OR',
    },
    97211: {
      lat: 45.58174,
      lng: -122.63548,
      city: 'Portland',
      state: 'OR',
    },
    97212: {
      lat: 45.54428,
      lng: -122.64322,
      city: 'Portland',
      state: 'OR',
    },
    97213: {
      lat: 45.53776,
      lng: -122.59957,
      city: 'Portland',
      state: 'OR',
    },
    97214: {
      lat: 45.51395,
      lng: -122.64424,
      city: 'Portland',
      state: 'OR',
    },
    97215: {
      lat: 45.51436,
      lng: -122.59923,
      city: 'Portland',
      state: 'OR',
    },
    97216: {
      lat: 45.51393,
      lng: -122.5585,
      city: 'Portland',
      state: 'OR',
    },
    97217: {
      lat: 45.59686,
      lng: -122.69717,
      city: 'Portland',
      state: 'OR',
    },
    97218: {
      lat: 45.5759,
      lng: -122.60023,
      city: 'Portland',
      state: 'OR',
    },
    97219: {
      lat: 45.45413,
      lng: -122.69972,
      city: 'Portland',
      state: 'OR',
    },
    97220: {
      lat: 45.55107,
      lng: -122.55915,
      city: 'Portland',
      state: 'OR',
    },
    97221: {
      lat: 45.49799,
      lng: -122.72749,
      city: 'Portland',
      state: 'OR',
    },
    97222: {
      lat: 45.44114,
      lng: -122.61682,
      city: 'Portland',
      state: 'OR',
    },
    97223: {
      lat: 45.44023,
      lng: -122.77838,
      city: 'Portland',
      state: 'OR',
    },
    97224: {
      lat: 45.40568,
      lng: -122.7958,
      city: 'Portland',
      state: 'OR',
    },
    97225: {
      lat: 45.502,
      lng: -122.76994,
      city: 'Portland',
      state: 'OR',
    },
    97227: {
      lat: 45.54432,
      lng: -122.67721,
      city: 'Portland',
      state: 'OR',
    },
    97229: {
      lat: 45.55032,
      lng: -122.81026,
      city: 'Portland',
      state: 'OR',
    },
    97230: {
      lat: 45.5582,
      lng: -122.506,
      city: 'Portland',
      state: 'OR',
    },
    97231: {
      lat: 45.68197,
      lng: -122.82687,
      city: 'Portland',
      state: 'OR',
    },
    97232: {
      lat: 45.5292,
      lng: -122.64376,
      city: 'Portland',
      state: 'OR',
    },
    97233: {
      lat: 45.51391,
      lng: -122.49887,
      city: 'Portland',
      state: 'OR',
    },
    97236: {
      lat: 45.48474,
      lng: -122.51055,
      city: 'Portland',
      state: 'OR',
    },
    97239: {
      lat: 45.48893,
      lng: -122.69053,
      city: 'Portland',
      state: 'OR',
    },
    97266: {
      lat: 45.48268,
      lng: -122.55838,
      city: 'Portland',
      state: 'OR',
    },
    97267: {
      lat: 45.40744,
      lng: -122.61472,
      city: 'Portland',
      state: 'OR',
    },
    97301: {
      lat: 44.94948,
      lng: -123.00439,
      city: 'Salem',
      state: 'OR',
    },
    97302: {
      lat: 44.90191,
      lng: -123.06719,
      city: 'Salem',
      state: 'OR',
    },
    97303: {
      lat: 45.03036,
      lng: -123.02449,
      city: 'Salem',
      state: 'OR',
    },
    97304: { lat: 45.0078, lng: -123.1078, city: 'Salem', state: 'OR' },
    97305: {
      lat: 45.01573,
      lng: -122.92909,
      city: 'Salem',
      state: 'OR',
    },
    97306: {
      lat: 44.84585,
      lng: -123.07972,
      city: 'Salem',
      state: 'OR',
    },
    97310: {
      lat: 44.90048,
      lng: -122.97436,
      city: 'Salem',
      state: 'OR',
    },
    97317: {
      lat: 44.90242,
      lng: -122.90628,
      city: 'Salem',
      state: 'OR',
    },
    97321: {
      lat: 44.64994,
      lng: -123.13474,
      city: 'Albany',
      state: 'OR',
    },
    97322: {
      lat: 44.62524,
      lng: -123.01975,
      city: 'Albany',
      state: 'OR',
    },
    97324: {
      lat: 44.34345,
      lng: -123.63042,
      city: 'Alsea',
      state: 'OR',
    },
    97325: {
      lat: 44.83141,
      lng: -122.85195,
      city: 'Aumsville',
      state: 'OR',
    },
    97326: {
      lat: 44.62794,
      lng: -123.61573,
      city: 'Blodgett',
      state: 'OR',
    },
    97327: {
      lat: 44.37567,
      lng: -122.94783,
      city: 'Brownsville',
      state: 'OR',
    },
    97329: {
      lat: 44.3956,
      lng: -122.33698,
      city: 'Cascadia',
      state: 'OR',
    },
    97330: {
      lat: 44.63796,
      lng: -123.27814,
      city: 'Corvallis',
      state: 'OR',
    },
    97331: {
      lat: 44.56343,
      lng: -123.28113,
      city: 'Corvallis',
      state: 'OR',
    },
    97333: {
      lat: 44.4735,
      lng: -123.3067,
      city: 'Corvallis',
      state: 'OR',
    },
    97335: {
      lat: 44.63776,
      lng: -122.88045,
      city: 'Crabtree',
      state: 'OR',
    },
    97336: {
      lat: 44.35669,
      lng: -122.85092,
      city: 'Crawfordsville',
      state: 'OR',
    },
    97338: {
      lat: 44.93654,
      lng: -123.33868,
      city: 'Dallas',
      state: 'OR',
    },
    97341: {
      lat: 44.81525,
      lng: -124.04513,
      city: 'Depoe Bay',
      state: 'OR',
    },
    97342: {
      lat: 44.73382,
      lng: -121.94524,
      city: 'Detroit',
      state: 'OR',
    },
    97343: {
      lat: 44.56934,
      lng: -123.73091,
      city: 'Eddyville',
      state: 'OR',
    },
    97344: {
      lat: 44.86887,
      lng: -123.46596,
      city: 'Falls City',
      state: 'OR',
    },
    97345: {
      lat: 44.4126,
      lng: -122.57644,
      city: 'Foster',
      state: 'OR',
    },
    97346: {
      lat: 44.76948,
      lng: -122.37857,
      city: 'Gates',
      state: 'OR',
    },
    97347: {
      lat: 45.07836,
      lng: -123.65943,
      city: 'Grand Ronde',
      state: 'OR',
    },
    97348: {
      lat: 44.38768,
      lng: -123.12582,
      city: 'Halsey',
      state: 'OR',
    },
    97350: {
      lat: 44.64786,
      lng: -121.96146,
      city: 'Idanha',
      state: 'OR',
    },
    97351: {
      lat: 44.83857,
      lng: -123.17449,
      city: 'Independence',
      state: 'OR',
    },
    97352: {
      lat: 44.75009,
      lng: -123.02419,
      city: 'Jefferson',
      state: 'OR',
    },
    97355: {
      lat: 44.5315,
      lng: -122.81701,
      city: 'Lebanon',
      state: 'OR',
    },
    97357: {
      lat: 44.73934,
      lng: -123.81592,
      city: 'Logsden',
      state: 'OR',
    },
    97358: {
      lat: 44.80681,
      lng: -122.40702,
      city: 'Lyons',
      state: 'OR',
    },
    97360: {
      lat: 44.7515,
      lng: -122.48529,
      city: 'Mill City',
      state: 'OR',
    },
    97361: {
      lat: 44.77251,
      lng: -123.32999,
      city: 'Monmouth',
      state: 'OR',
    },
    97362: {
      lat: 45.06903,
      lng: -122.7692,
      city: 'Mount Angel',
      state: 'OR',
    },
    97364: {
      lat: 44.99889,
      lng: -123.98703,
      city: 'Neotsu',
      state: 'OR',
    },
    97365: {
      lat: 44.67113,
      lng: -124.01825,
      city: 'Newport',
      state: 'OR',
    },
    97366: {
      lat: 44.58288,
      lng: -124.04748,
      city: 'South Beach',
      state: 'OR',
    },
    97367: {
      lat: 44.9122,
      lng: -123.96914,
      city: 'Lincoln City',
      state: 'OR',
    },
    97368: { lat: 45.0073, lng: -123.89155, city: 'Otis', state: 'OR' },
    97369: {
      lat: 44.76015,
      lng: -124.05676,
      city: 'Otter Rock',
      state: 'OR',
    },
    97370: {
      lat: 44.57901,
      lng: -123.46907,
      city: 'Philomath',
      state: 'OR',
    },
    97371: {
      lat: 44.98985,
      lng: -123.1994,
      city: 'Rickreall',
      state: 'OR',
    },
    97373: {
      lat: 45.05819,
      lng: -122.77677,
      city: 'Saint Benedict',
      state: 'OR',
    },
    97374: {
      lat: 44.69285,
      lng: -122.77379,
      city: 'Scio',
      state: 'OR',
    },
    97375: {
      lat: 44.97922,
      lng: -122.6021,
      city: 'Scotts Mills',
      state: 'OR',
    },
    97376: {
      lat: 44.50854,
      lng: -123.9768,
      city: 'Seal Rock',
      state: 'OR',
    },
    97377: {
      lat: 44.46403,
      lng: -123.10156,
      city: 'Shedd',
      state: 'OR',
    },
    97378: {
      lat: 45.08058,
      lng: -123.43127,
      city: 'Sheridan',
      state: 'OR',
    },
    97380: {
      lat: 44.77268,
      lng: -123.94202,
      city: 'Siletz',
      state: 'OR',
    },
    97381: {
      lat: 44.97014,
      lng: -122.74749,
      city: 'Silverton',
      state: 'OR',
    },
    97383: {
      lat: 44.80014,
      lng: -122.71836,
      city: 'Stayton',
      state: 'OR',
    },
    97384: {
      lat: 44.79109,
      lng: -122.62007,
      city: 'Mehama',
      state: 'OR',
    },
    97385: {
      lat: 44.86633,
      lng: -122.72924,
      city: 'Sublimity',
      state: 'OR',
    },
    97386: {
      lat: 44.36062,
      lng: -122.75076,
      city: 'Sweet Home',
      state: 'OR',
    },
    97388: {
      lat: 44.87418,
      lng: -124.01169,
      city: 'Gleneden Beach',
      state: 'OR',
    },
    97389: {
      lat: 44.53269,
      lng: -123.09028,
      city: 'Tangent',
      state: 'OR',
    },
    97390: {
      lat: 44.33781,
      lng: -123.85717,
      city: 'Tidewater',
      state: 'OR',
    },
    97391: {
      lat: 44.59593,
      lng: -123.8816,
      city: 'Toledo',
      state: 'OR',
    },
    97392: {
      lat: 44.79096,
      lng: -122.9413,
      city: 'Turner',
      state: 'OR',
    },
    97394: {
      lat: 44.40607,
      lng: -124.00258,
      city: 'Waldport',
      state: 'OR',
    },
    97396: {
      lat: 45.11528,
      lng: -123.5275,
      city: 'Willamina',
      state: 'OR',
    },
    97401: {
      lat: 44.06803,
      lng: -123.08171,
      city: 'Eugene',
      state: 'OR',
    },
    97402: {
      lat: 44.05193,
      lng: -123.23022,
      city: 'Eugene',
      state: 'OR',
    },
    97403: {
      lat: 44.03541,
      lng: -123.05247,
      city: 'Eugene',
      state: 'OR',
    },
    97404: {
      lat: 44.10518,
      lng: -123.13315,
      city: 'Eugene',
      state: 'OR',
    },
    97405: {
      lat: 43.95528,
      lng: -123.167,
      city: 'Eugene',
      state: 'OR',
    },
    97406: {
      lat: 42.62638,
      lng: -123.99867,
      city: 'Agness',
      state: 'OR',
    },
    97408: {
      lat: 44.14435,
      lng: -123.05452,
      city: 'Eugene',
      state: 'OR',
    },
    97410: {
      lat: 42.80159,
      lng: -123.11931,
      city: 'Azalea',
      state: 'OR',
    },
    97411: {
      lat: 43.07557,
      lng: -124.3624,
      city: 'Bandon',
      state: 'OR',
    },
    97412: {
      lat: 44.19625,
      lng: -123.56106,
      city: 'Blachly',
      state: 'OR',
    },
    97413: {
      lat: 44.09633,
      lng: -122.01513,
      city: 'Blue River',
      state: 'OR',
    },
    97414: {
      lat: 42.96653,
      lng: -124.15026,
      city: 'Broadbent',
      state: 'OR',
    },
    97415: {
      lat: 42.13977,
      lng: -124.13506,
      city: 'Brookings',
      state: 'OR',
    },
    97416: {
      lat: 43.03524,
      lng: -123.69123,
      city: 'Camas Valley',
      state: 'OR',
    },
    97417: {
      lat: 42.94014,
      lng: -123.21802,
      city: 'Canyonville',
      state: 'OR',
    },
    97419: {
      lat: 44.1793,
      lng: -123.40719,
      city: 'Cheshire',
      state: 'OR',
    },
    97420: {
      lat: 43.34839,
      lng: -124.10825,
      city: 'Coos Bay',
      state: 'OR',
    },
    97423: {
      lat: 43.19137,
      lng: -124.17177,
      city: 'Coquille',
      state: 'OR',
    },
    97424: {
      lat: 43.74712,
      lng: -123.05496,
      city: 'Cottage Grove',
      state: 'OR',
    },
    97426: {
      lat: 43.90313,
      lng: -123.02781,
      city: 'Creswell',
      state: 'OR',
    },
    97429: {
      lat: 42.96778,
      lng: -123.05442,
      city: 'Days Creek',
      state: 'OR',
    },
    97430: {
      lat: 44.15507,
      lng: -123.71175,
      city: 'Deadwood',
      state: 'OR',
    },
    97431: {
      lat: 43.87135,
      lng: -122.81576,
      city: 'Dexter',
      state: 'OR',
    },
    97432: {
      lat: 43.10445,
      lng: -123.4294,
      city: 'Dillard',
      state: 'OR',
    },
    97434: {
      lat: 43.66553,
      lng: -122.78211,
      city: 'Dorena',
      state: 'OR',
    },
    97435: {
      lat: 43.7016,
      lng: -123.33839,
      city: 'Drain',
      state: 'OR',
    },
    97436: {
      lat: 43.63958,
      lng: -123.58256,
      city: 'Elkton',
      state: 'OR',
    },
    97437: {
      lat: 44.09734,
      lng: -123.38731,
      city: 'Elmira',
      state: 'OR',
    },
    97438: {
      lat: 43.96068,
      lng: -122.65546,
      city: 'Fall Creek',
      state: 'OR',
    },
    97439: {
      lat: 44.06573,
      lng: -124.03705,
      city: 'Florence',
      state: 'OR',
    },
    97441: {
      lat: 43.77659,
      lng: -124.14414,
      city: 'Gardiner',
      state: 'OR',
    },
    97442: {
      lat: 42.76982,
      lng: -123.40365,
      city: 'Glendale',
      state: 'OR',
    },
    97443: {
      lat: 43.25014,
      lng: -123.00255,
      city: 'Glide',
      state: 'OR',
    },
    97444: {
      lat: 42.44453,
      lng: -124.33223,
      city: 'Gold Beach',
      state: 'OR',
    },
    97446: {
      lat: 44.26804,
      lng: -123.05548,
      city: 'Harrisburg',
      state: 'OR',
    },
    97447: {
      lat: 43.28409,
      lng: -122.49807,
      city: 'Idleyld Park',
      state: 'OR',
    },
    97448: {
      lat: 44.2105,
      lng: -123.27882,
      city: 'Junction City',
      state: 'OR',
    },
    97449: {
      lat: 43.57571,
      lng: -124.08581,
      city: 'Lakeside',
      state: 'OR',
    },
    97450: {
      lat: 42.91026,
      lng: -124.37398,
      city: 'Langlois',
      state: 'OR',
    },
    97451: {
      lat: 43.83998,
      lng: -123.31383,
      city: 'Lorane',
      state: 'OR',
    },
    97452: {
      lat: 43.88564,
      lng: -122.72259,
      city: 'Lowell',
      state: 'OR',
    },
    97453: {
      lat: 43.99422,
      lng: -123.86344,
      city: 'Mapleton',
      state: 'OR',
    },
    97454: {
      lat: 44.21806,
      lng: -122.8191,
      city: 'Marcola',
      state: 'OR',
    },
    97455: {
      lat: 43.96212,
      lng: -122.91493,
      city: 'Pleasant Hill',
      state: 'OR',
    },
    97456: {
      lat: 44.34036,
      lng: -123.35893,
      city: 'Monroe',
      state: 'OR',
    },
    97457: {
      lat: 43.05484,
      lng: -123.2229,
      city: 'Myrtle Creek',
      state: 'OR',
    },
    97458: {
      lat: 43.06372,
      lng: -124.01287,
      city: 'Myrtle Point',
      state: 'OR',
    },
    97459: {
      lat: 43.47444,
      lng: -124.18216,
      city: 'North Bend',
      state: 'OR',
    },
    97461: {
      lat: 44.09789,
      lng: -123.48993,
      city: 'Noti',
      state: 'OR',
    },
    97462: {
      lat: 43.49047,
      lng: -123.38828,
      city: 'Oakland',
      state: 'OR',
    },
    97463: {
      lat: 43.73951,
      lng: -122.40591,
      city: 'Oakridge',
      state: 'OR',
    },
    97465: {
      lat: 42.7376,
      lng: -124.3795,
      city: 'Port Orford',
      state: 'OR',
    },
    97466: {
      lat: 42.86459,
      lng: -124.08632,
      city: 'Powers',
      state: 'OR',
    },
    97467: {
      lat: 43.73557,
      lng: -123.90442,
      city: 'Reedsport',
      state: 'OR',
    },
    97469: {
      lat: 42.91994,
      lng: -123.41459,
      city: 'Riddle',
      state: 'OR',
    },
    97470: {
      lat: 43.23288,
      lng: -123.23111,
      city: 'Roseburg',
      state: 'OR',
    },
    97471: {
      lat: 43.22215,
      lng: -123.47486,
      city: 'Roseburg',
      state: 'OR',
    },
    97473: {
      lat: 43.6773,
      lng: -123.8726,
      city: 'Scottsburg',
      state: 'OR',
    },
    97476: {
      lat: 42.84075,
      lng: -124.43806,
      city: 'Sixes',
      state: 'OR',
    },
    97477: {
      lat: 44.05861,
      lng: -123.01126,
      city: 'Springfield',
      state: 'OR',
    },
    97478: {
      lat: 44.08781,
      lng: -122.84478,
      city: 'Springfield',
      state: 'OR',
    },
    97479: {
      lat: 43.40194,
      lng: -123.19426,
      city: 'Sutherlin',
      state: 'OR',
    },
    97480: {
      lat: 44.1303,
      lng: -123.83213,
      city: 'Swisshome',
      state: 'OR',
    },
    97481: {
      lat: 43.11376,
      lng: -123.55237,
      city: 'Tenmile',
      state: 'OR',
    },
    97484: {
      lat: 42.93373,
      lng: -122.84585,
      city: 'Tiller',
      state: 'OR',
    },
    97486: {
      lat: 43.37778,
      lng: -123.5351,
      city: 'Umpqua',
      state: 'OR',
    },
    97487: {
      lat: 43.99441,
      lng: -123.39303,
      city: 'Veneta',
      state: 'OR',
    },
    97488: {
      lat: 44.13077,
      lng: -122.41198,
      city: 'Vida',
      state: 'OR',
    },
    97489: {
      lat: 44.10986,
      lng: -122.61962,
      city: 'Walterville',
      state: 'OR',
    },
    97490: {
      lat: 44.00795,
      lng: -123.60522,
      city: 'Walton',
      state: 'OR',
    },
    97492: {
      lat: 43.77168,
      lng: -122.49868,
      city: 'Westfir',
      state: 'OR',
    },
    97493: {
      lat: 43.90256,
      lng: -124.01602,
      city: 'Westlake',
      state: 'OR',
    },
    97494: {
      lat: 43.33059,
      lng: -123.32779,
      city: 'Wilbur',
      state: 'OR',
    },
    97495: {
      lat: 43.28765,
      lng: -123.30463,
      city: 'Winchester',
      state: 'OR',
    },
    97496: {
      lat: 43.05261,
      lng: -123.49992,
      city: 'Winston',
      state: 'OR',
    },
    97497: {
      lat: 42.66015,
      lng: -123.40929,
      city: 'Wolf Creek',
      state: 'OR',
    },
    97498: {
      lat: 44.27728,
      lng: -124.01901,
      city: 'Yachats',
      state: 'OR',
    },
    97499: {
      lat: 43.59495,
      lng: -123.23356,
      city: 'Yoncalla',
      state: 'OR',
    },
    97501: {
      lat: 42.2856,
      lng: -122.89459,
      city: 'Medford',
      state: 'OR',
    },
    97502: {
      lat: 42.40927,
      lng: -122.94718,
      city: 'Central Point',
      state: 'OR',
    },
    97503: {
      lat: 42.5836,
      lng: -122.92721,
      city: 'White City',
      state: 'OR',
    },
    97504: {
      lat: 42.32757,
      lng: -122.80068,
      city: 'Medford',
      state: 'OR',
    },
    97520: {
      lat: 42.15472,
      lng: -122.53168,
      city: 'Ashland',
      state: 'OR',
    },
    97522: {
      lat: 42.56594,
      lng: -122.46886,
      city: 'Butte Falls',
      state: 'OR',
    },
    97523: {
      lat: 42.11349,
      lng: -123.62961,
      city: 'Cave Junction',
      state: 'OR',
    },
    97524: {
      lat: 42.46271,
      lng: -122.66327,
      city: 'Eagle Point',
      state: 'OR',
    },
    97525: {
      lat: 42.43557,
      lng: -123.07606,
      city: 'Gold Hill',
      state: 'OR',
    },
    97526: {
      lat: 42.51275,
      lng: -123.33964,
      city: 'Grants Pass',
      state: 'OR',
    },
    97527: {
      lat: 42.37615,
      lng: -123.38288,
      city: 'Grants Pass',
      state: 'OR',
    },
    97530: {
      lat: 42.13778,
      lng: -123.10507,
      city: 'Jacksonville',
      state: 'OR',
    },
    97531: {
      lat: 42.20739,
      lng: -123.64391,
      city: 'Kerby',
      state: 'OR',
    },
    97532: {
      lat: 42.56142,
      lng: -123.58586,
      city: 'Merlin',
      state: 'OR',
    },
    97534: {
      lat: 42.03616,
      lng: -123.71641,
      city: "O'Brien",
      state: 'OR',
    },
    97535: {
      lat: 42.26799,
      lng: -122.81238,
      city: 'Phoenix',
      state: 'OR',
    },
    97536: {
      lat: 42.78455,
      lng: -122.50802,
      city: 'Prospect',
      state: 'OR',
    },
    97537: {
      lat: 42.55041,
      lng: -123.13357,
      city: 'Rogue River',
      state: 'OR',
    },
    97538: {
      lat: 42.29623,
      lng: -123.65723,
      city: 'Selma',
      state: 'OR',
    },
    97539: {
      lat: 42.59999,
      lng: -122.79024,
      city: 'Shady Cove',
      state: 'OR',
    },
    97540: {
      lat: 42.18615,
      lng: -122.80027,
      city: 'Talent',
      state: 'OR',
    },
    97541: {
      lat: 42.72983,
      lng: -122.75359,
      city: 'Trail',
      state: 'OR',
    },
    97543: {
      lat: 42.37724,
      lng: -123.56031,
      city: 'Wilderville',
      state: 'OR',
    },
    97544: {
      lat: 42.207,
      lng: -123.30006,
      city: 'Williams',
      state: 'OR',
    },
    97601: {
      lat: 42.44404,
      lng: -122.06741,
      city: 'Klamath Falls',
      state: 'OR',
    },
    97603: {
      lat: 42.13704,
      lng: -121.67766,
      city: 'Klamath Falls',
      state: 'OR',
    },
    97604: {
      lat: 42.97778,
      lng: -122.20674,
      city: 'Crater Lake',
      state: 'OR',
    },
    97620: { lat: 42.223, lng: -119.89677, city: 'Adel', state: 'OR' },
    97621: {
      lat: 42.51504,
      lng: -121.2881,
      city: 'Beatty',
      state: 'OR',
    },
    97622: { lat: 42.39667, lng: -121.01032, city: 'Bly', state: 'OR' },
    97623: {
      lat: 42.19905,
      lng: -121.31016,
      city: 'Bonanza',
      state: 'OR',
    },
    97624: {
      lat: 42.72121,
      lng: -121.69415,
      city: 'Chiloquin',
      state: 'OR',
    },
    97625: {
      lat: 42.31507,
      lng: -121.58402,
      city: 'Dairy',
      state: 'OR',
    },
    97626: {
      lat: 42.72488,
      lng: -122.02011,
      city: 'Fort Klamath',
      state: 'OR',
    },
    97627: {
      lat: 42.08036,
      lng: -122.05796,
      city: 'Keno',
      state: 'OR',
    },
    97630: {
      lat: 42.29832,
      lng: -120.39166,
      city: 'Lakeview',
      state: 'OR',
    },
    97632: {
      lat: 42.04175,
      lng: -121.40597,
      city: 'Malin',
      state: 'OR',
    },
    97633: {
      lat: 42.03014,
      lng: -121.56328,
      city: 'Merrill',
      state: 'OR',
    },
    97634: {
      lat: 42.12747,
      lng: -121.80348,
      city: 'Midland',
      state: 'OR',
    },
    97635: {
      lat: 41.93173,
      lng: -120.29974,
      city: 'New Pine Creek',
      state: 'OR',
    },
    97636: {
      lat: 42.68775,
      lng: -120.56746,
      city: 'Paisley',
      state: 'OR',
    },
    97637: {
      lat: 42.46142,
      lng: -119.92486,
      city: 'Plush',
      state: 'OR',
    },
    97638: {
      lat: 43.1458,
      lng: -121.01156,
      city: 'Silver Lake',
      state: 'OR',
    },
    97639: {
      lat: 42.43435,
      lng: -121.49845,
      city: 'Sprague River',
      state: 'OR',
    },
    97640: {
      lat: 42.90742,
      lng: -120.65381,
      city: 'Summer Lake',
      state: 'OR',
    },
    97641: {
      lat: 43.28742,
      lng: -120.53809,
      city: 'Christmas Valley',
      state: 'OR',
    },
    97701: {
      lat: 44.00065,
      lng: -121.03289,
      city: 'Bend',
      state: 'OR',
    },
    97702: {
      lat: 43.98336,
      lng: -121.21083,
      city: 'Bend',
      state: 'OR',
    },
    97703: {
      lat: 44.07281,
      lng: -121.51971,
      city: 'Bend',
      state: 'OR',
    },
    97707: {
      lat: 43.83031,
      lng: -121.49365,
      city: 'Bend',
      state: 'OR',
    },
    97709: {
      lat: 43.89338,
      lng: -121.85627,
      city: 'Bend',
      state: 'OR',
    },
    97710: {
      lat: 42.15095,
      lng: -118.53902,
      city: 'Fields',
      state: 'OR',
    },
    97711: {
      lat: 44.7239,
      lng: -120.63489,
      city: 'Ashwood',
      state: 'OR',
    },
    97712: {
      lat: 43.77825,
      lng: -120.42866,
      city: 'Brothers',
      state: 'OR',
    },
    97720: {
      lat: 43.58799,
      lng: -118.85806,
      city: 'Burns',
      state: 'OR',
    },
    97721: {
      lat: 42.88772,
      lng: -118.52635,
      city: 'Princeton',
      state: 'OR',
    },
    97722: {
      lat: 42.95733,
      lng: -118.69241,
      city: 'Diamond',
      state: 'OR',
    },
    97730: {
      lat: 44.52265,
      lng: -121.64535,
      city: 'Camp Sherman',
      state: 'OR',
    },
    97731: {
      lat: 43.1315,
      lng: -121.92089,
      city: 'Chemult',
      state: 'OR',
    },
    97732: {
      lat: 43.41369,
      lng: -118.40265,
      city: 'Crane',
      state: 'OR',
    },
    97733: {
      lat: 43.38441,
      lng: -121.94778,
      city: 'Crescent',
      state: 'OR',
    },
    97734: {
      lat: 44.53502,
      lng: -121.33395,
      city: 'Culver',
      state: 'OR',
    },
    97735: {
      lat: 43.39014,
      lng: -120.99224,
      city: 'Fort Rock',
      state: 'OR',
    },
    97736: {
      lat: 42.66655,
      lng: -118.92058,
      city: 'Frenchglen',
      state: 'OR',
    },
    97737: {
      lat: 43.50897,
      lng: -121.71917,
      city: 'Gilchrist',
      state: 'OR',
    },
    97738: {
      lat: 43.51218,
      lng: -119.25078,
      city: 'Hines',
      state: 'OR',
    },
    97739: {
      lat: 43.67259,
      lng: -121.48693,
      city: 'La Pine',
      state: 'OR',
    },
    97741: {
      lat: 44.65771,
      lng: -121.04291,
      city: 'Madras',
      state: 'OR',
    },
    97750: {
      lat: 44.67037,
      lng: -120.12704,
      city: 'Mitchell',
      state: 'OR',
    },
    97751: {
      lat: 44.14856,
      lng: -119.81945,
      city: 'Paulina',
      state: 'OR',
    },
    97752: {
      lat: 44.14094,
      lng: -120.20584,
      city: 'Post',
      state: 'OR',
    },
    97753: {
      lat: 44.22625,
      lng: -120.99996,
      city: 'Powell Butte',
      state: 'OR',
    },
    97754: {
      lat: 44.13577,
      lng: -120.52848,
      city: 'Prineville',
      state: 'OR',
    },
    97756: {
      lat: 44.28079,
      lng: -121.19335,
      city: 'Redmond',
      state: 'OR',
    },
    97758: {
      lat: 43.41449,
      lng: -119.74859,
      city: 'Riley',
      state: 'OR',
    },
    97759: {
      lat: 44.44428,
      lng: -121.76823,
      city: 'Sisters',
      state: 'OR',
    },
    97760: {
      lat: 44.38662,
      lng: -121.23323,
      city: 'Terrebonne',
      state: 'OR',
    },
    97761: {
      lat: 44.8433,
      lng: -121.29807,
      city: 'Warm Springs',
      state: 'OR',
    },
    97801: {
      lat: 45.67317,
      lng: -118.83362,
      city: 'Pendleton',
      state: 'OR',
    },
    97810: {
      lat: 45.6725,
      lng: -118.39642,
      city: 'Adams',
      state: 'OR',
    },
    97812: {
      lat: 45.58138,
      lng: -120.21907,
      city: 'Arlington',
      state: 'OR',
    },
    97813: {
      lat: 45.85305,
      lng: -118.52802,
      city: 'Athena',
      state: 'OR',
    },
    97814: {
      lat: 44.80075,
      lng: -117.77034,
      city: 'Baker City',
      state: 'OR',
    },
    97817: {
      lat: 44.6555,
      lng: -118.60816,
      city: 'Bates',
      state: 'OR',
    },
    97818: {
      lat: 45.7567,
      lng: -119.78444,
      city: 'Boardman',
      state: 'OR',
    },
    97819: {
      lat: 44.48603,
      lng: -117.76317,
      city: 'Bridgeport',
      state: 'OR',
    },
    97820: {
      lat: 44.16327,
      lng: -119.21436,
      city: 'Canyon City',
      state: 'OR',
    },
    97823: {
      lat: 45.24334,
      lng: -120.19097,
      city: 'Condon',
      state: 'OR',
    },
    97824: {
      lat: 45.34992,
      lng: -117.75506,
      city: 'Cove',
      state: 'OR',
    },
    97825: {
      lat: 44.38804,
      lng: -119.51852,
      city: 'Dayville',
      state: 'OR',
    },
    97826: {
      lat: 45.65508,
      lng: -119.22586,
      city: 'Echo',
      state: 'OR',
    },
    97827: {
      lat: 45.60854,
      lng: -117.85886,
      city: 'Elgin',
      state: 'OR',
    },
    97828: {
      lat: 45.71382,
      lng: -117.25971,
      city: 'Enterprise',
      state: 'OR',
    },
    97830: {
      lat: 44.9779,
      lng: -120.17165,
      city: 'Fossil',
      state: 'OR',
    },
    97833: {
      lat: 44.93471,
      lng: -118.05951,
      city: 'Haines',
      state: 'OR',
    },
    97834: {
      lat: 44.97251,
      lng: -117.20509,
      city: 'Halfway',
      state: 'OR',
    },
    97835: { lat: 45.8992, lng: -118.772, city: 'Helix', state: 'OR' },
    97836: {
      lat: 45.29166,
      lng: -119.50144,
      city: 'Heppner',
      state: 'OR',
    },
    97837: {
      lat: 44.51127,
      lng: -118.0483,
      city: 'Hereford',
      state: 'OR',
    },
    97838: {
      lat: 45.85266,
      lng: -119.28878,
      city: 'Hermiston',
      state: 'OR',
    },
    97839: {
      lat: 45.58272,
      lng: -119.60005,
      city: 'Lexington',
      state: 'OR',
    },
    97840: {
      lat: 44.90218,
      lng: -116.93608,
      city: 'Oxbow',
      state: 'OR',
    },
    97841: {
      lat: 45.46823,
      lng: -117.94035,
      city: 'Imbler',
      state: 'OR',
    },
    97842: {
      lat: 45.57223,
      lng: -116.82384,
      city: 'Imnaha',
      state: 'OR',
    },
    97843: {
      lat: 45.50479,
      lng: -119.88066,
      city: 'Ione',
      state: 'OR',
    },
    97844: {
      lat: 45.87867,
      lng: -119.54676,
      city: 'Irrigon',
      state: 'OR',
    },
    97845: {
      lat: 44.41445,
      lng: -118.90372,
      city: 'John Day',
      state: 'OR',
    },
    97846: {
      lat: 45.27465,
      lng: -117.10956,
      city: 'Joseph',
      state: 'OR',
    },
    97848: {
      lat: 44.69761,
      lng: -119.57336,
      city: 'Kimberly',
      state: 'OR',
    },
    97850: {
      lat: 45.30689,
      lng: -118.13265,
      city: 'La Grande',
      state: 'OR',
    },
    97856: {
      lat: 44.78426,
      lng: -119.12976,
      city: 'Long Creek',
      state: 'OR',
    },
    97857: {
      lat: 45.37273,
      lng: -117.50418,
      city: 'Lostine',
      state: 'OR',
    },
    97859: {
      lat: 45.5258,
      lng: -118.43209,
      city: 'Meacham',
      state: 'OR',
    },
    97862: {
      lat: 45.92712,
      lng: -118.31797,
      city: 'Milton Freewater',
      state: 'OR',
    },
    97864: {
      lat: 44.83842,
      lng: -119.45471,
      city: 'Monument',
      state: 'OR',
    },
    97865: {
      lat: 44.42949,
      lng: -119.19192,
      city: 'Mount Vernon',
      state: 'OR',
    },
    97867: {
      lat: 45.0627,
      lng: -117.997,
      city: 'North Powder',
      state: 'OR',
    },
    97868: {
      lat: 45.40815,
      lng: -118.86964,
      city: 'Pilot Rock',
      state: 'OR',
    },
    97869: {
      lat: 44.37993,
      lng: -118.61425,
      city: 'Prairie City',
      state: 'OR',
    },
    97870: {
      lat: 44.79434,
      lng: -117.19033,
      city: 'Richland',
      state: 'OR',
    },
    97873: {
      lat: 44.12837,
      lng: -118.99505,
      city: 'Seneca',
      state: 'OR',
    },
    97874: {
      lat: 44.75783,
      lng: -119.84828,
      city: 'Spray',
      state: 'OR',
    },
    97875: {
      lat: 45.81481,
      lng: -119.14898,
      city: 'Stanfield',
      state: 'OR',
    },
    97876: {
      lat: 45.52237,
      lng: -118.02811,
      city: 'Summerville',
      state: 'OR',
    },
    97877: {
      lat: 44.80662,
      lng: -118.38766,
      city: 'Sumpter',
      state: 'OR',
    },
    97880: {
      lat: 45.08631,
      lng: -118.89269,
      city: 'Ukiah',
      state: 'OR',
    },
    97882: {
      lat: 45.90741,
      lng: -119.34944,
      city: 'Umatilla',
      state: 'OR',
    },
    97883: {
      lat: 45.14264,
      lng: -117.64047,
      city: 'Union',
      state: 'OR',
    },
    97884: {
      lat: 44.44975,
      lng: -118.21895,
      city: 'Unity',
      state: 'OR',
    },
    97885: {
      lat: 45.65073,
      lng: -117.50972,
      city: 'Wallowa',
      state: 'OR',
    },
    97886: {
      lat: 45.79604,
      lng: -118.24924,
      city: 'Weston',
      state: 'OR',
    },
    97901: {
      lat: 43.5996,
      lng: -117.09602,
      city: 'Adrian',
      state: 'OR',
    },
    97902: {
      lat: 42.91332,
      lng: -117.49412,
      city: 'Arock',
      state: 'OR',
    },
    97903: {
      lat: 44.27772,
      lng: -117.59775,
      city: 'Brogan',
      state: 'OR',
    },
    97904: {
      lat: 43.89136,
      lng: -118.50581,
      city: 'Drewsey',
      state: 'OR',
    },
    97905: {
      lat: 44.58183,
      lng: -117.4654,
      city: 'Durkee',
      state: 'OR',
    },
    97906: {
      lat: 43.46049,
      lng: -117.81783,
      city: 'Harper',
      state: 'OR',
    },
    97907: {
      lat: 44.41893,
      lng: -117.35568,
      city: 'Huntington',
      state: 'OR',
    },
    97908: {
      lat: 44.25679,
      lng: -117.94828,
      city: 'Ironside',
      state: 'OR',
    },
    97909: {
      lat: 44.21096,
      lng: -117.37909,
      city: 'Jamieson',
      state: 'OR',
    },
    97910: {
      lat: 42.85733,
      lng: -117.50909,
      city: 'Jordan Valley',
      state: 'OR',
    },
    97911: {
      lat: 43.85547,
      lng: -118.02605,
      city: 'Juntura',
      state: 'OR',
    },
    97913: {
      lat: 43.64688,
      lng: -117.28131,
      city: 'Nyssa',
      state: 'OR',
    },
    97914: {
      lat: 44.11085,
      lng: -117.07383,
      city: 'Ontario',
      state: 'OR',
    },
    97917: {
      lat: 43.5415,
      lng: -118.11651,
      city: 'Riverside',
      state: 'OR',
    },
    97918: {
      lat: 43.96255,
      lng: -117.38073,
      city: 'Vale',
      state: 'OR',
    },
    97920: {
      lat: 44.06595,
      lng: -117.75997,
      city: 'Westfall',
      state: 'OR',
    },
    98001: {
      lat: 47.30919,
      lng: -122.26426,
      city: 'Auburn',
      state: 'WA',
    },
    98002: {
      lat: 47.3082,
      lng: -122.21567,
      city: 'Auburn',
      state: 'WA',
    },
    98003: {
      lat: 47.30596,
      lng: -122.31465,
      city: 'Federal Way',
      state: 'WA',
    },
    98004: {
      lat: 47.61865,
      lng: -122.20548,
      city: 'Bellevue',
      state: 'WA',
    },
    98005: {
      lat: 47.61494,
      lng: -122.16814,
      city: 'Bellevue',
      state: 'WA',
    },
    98006: {
      lat: 47.55772,
      lng: -122.14957,
      city: 'Bellevue',
      state: 'WA',
    },
    98007: {
      lat: 47.61392,
      lng: -122.14378,
      city: 'Bellevue',
      state: 'WA',
    },
    98008: {
      lat: 47.60551,
      lng: -122.11219,
      city: 'Bellevue',
      state: 'WA',
    },
    98010: {
      lat: 47.31173,
      lng: -122.00326,
      city: 'Black Diamond',
      state: 'WA',
    },
    98011: {
      lat: 47.75736,
      lng: -122.19871,
      city: 'Bothell',
      state: 'WA',
    },
    98012: {
      lat: 47.84205,
      lng: -122.19813,
      city: 'Bothell',
      state: 'WA',
    },
    98014: {
      lat: 47.69664,
      lng: -121.84024,
      city: 'Carnation',
      state: 'WA',
    },
    98019: {
      lat: 47.74083,
      lng: -121.93494,
      city: 'Duvall',
      state: 'WA',
    },
    98020: {
      lat: 47.80015,
      lng: -122.37267,
      city: 'Edmonds',
      state: 'WA',
    },
    98021: {
      lat: 47.79257,
      lng: -122.20846,
      city: 'Bothell',
      state: 'WA',
    },
    98022: {
      lat: 47.1714,
      lng: -121.67958,
      city: 'Enumclaw',
      state: 'WA',
    },
    98023: {
      lat: 47.30881,
      lng: -122.36198,
      city: 'Federal Way',
      state: 'WA',
    },
    98024: {
      lat: 47.57301,
      lng: -121.90219,
      city: 'Fall City',
      state: 'WA',
    },
    98025: {
      lat: 47.43441,
      lng: -121.9524,
      city: 'Hobart',
      state: 'WA',
    },
    98026: {
      lat: 47.83555,
      lng: -122.3316,
      city: 'Edmonds',
      state: 'WA',
    },
    98027: {
      lat: 47.50387,
      lng: -122.00176,
      city: 'Issaquah',
      state: 'WA',
    },
    98028: {
      lat: 47.75162,
      lng: -122.24892,
      city: 'Kenmore',
      state: 'WA',
    },
    98029: {
      lat: 47.5576,
      lng: -122.0091,
      city: 'Issaquah',
      state: 'WA',
    },
    98030: {
      lat: 47.36817,
      lng: -122.19749,
      city: 'Kent',
      state: 'WA',
    },
    98031: { lat: 47.4055, lng: -122.1943, city: 'Kent', state: 'WA' },
    98032: { lat: 47.3922, lng: -122.25819, city: 'Kent', state: 'WA' },
    98033: {
      lat: 47.67616,
      lng: -122.19267,
      city: 'Kirkland',
      state: 'WA',
    },
    98034: {
      lat: 47.71612,
      lng: -122.21332,
      city: 'Kirkland',
      state: 'WA',
    },
    98036: {
      lat: 47.81094,
      lng: -122.28155,
      city: 'Lynnwood',
      state: 'WA',
    },
    98037: {
      lat: 47.83873,
      lng: -122.28463,
      city: 'Lynnwood',
      state: 'WA',
    },
    98038: {
      lat: 47.41861,
      lng: -121.95589,
      city: 'Maple Valley',
      state: 'WA',
    },
    98039: {
      lat: 47.63308,
      lng: -122.23963,
      city: 'Medina',
      state: 'WA',
    },
    98040: {
      lat: 47.56611,
      lng: -122.232,
      city: 'Mercer Island',
      state: 'WA',
    },
    98042: {
      lat: 47.36508,
      lng: -122.11623,
      city: 'Kent',
      state: 'WA',
    },
    98043: {
      lat: 47.7921,
      lng: -122.30758,
      city: 'Mountlake Terrace',
      state: 'WA',
    },
    98045: {
      lat: 47.48276,
      lng: -121.65678,
      city: 'North Bend',
      state: 'WA',
    },
    98047: {
      lat: 47.26079,
      lng: -122.24822,
      city: 'Pacific',
      state: 'WA',
    },
    98050: {
      lat: 47.54764,
      lng: -121.93663,
      city: 'Preston',
      state: 'WA',
    },
    98051: {
      lat: 47.3396,
      lng: -121.89049,
      city: 'Ravensdale',
      state: 'WA',
    },
    98052: {
      lat: 47.68148,
      lng: -122.1203,
      city: 'Redmond',
      state: 'WA',
    },
    98053: {
      lat: 47.66491,
      lng: -122.02071,
      city: 'Redmond',
      state: 'WA',
    },
    98055: {
      lat: 47.44775,
      lng: -122.20205,
      city: 'Renton',
      state: 'WA',
    },
    98056: {
      lat: 47.51281,
      lng: -122.18923,
      city: 'Renton',
      state: 'WA',
    },
    98057: {
      lat: 47.47188,
      lng: -122.21961,
      city: 'Renton',
      state: 'WA',
    },
    98058: {
      lat: 47.44082,
      lng: -122.12503,
      city: 'Renton',
      state: 'WA',
    },
    98059: {
      lat: 47.49563,
      lng: -122.12474,
      city: 'Renton',
      state: 'WA',
    },
    98065: {
      lat: 47.59234,
      lng: -121.78931,
      city: 'Snoqualmie',
      state: 'WA',
    },
    98068: {
      lat: 47.45154,
      lng: -121.35795,
      city: 'Snoqualmie Pass',
      state: 'WA',
    },
    98070: {
      lat: 47.41219,
      lng: -122.4726,
      city: 'Vashon',
      state: 'WA',
    },
    98072: {
      lat: 47.76151,
      lng: -122.13153,
      city: 'Woodinville',
      state: 'WA',
    },
    98074: {
      lat: 47.62206,
      lng: -122.04437,
      city: 'Sammamish',
      state: 'WA',
    },
    98075: {
      lat: 47.58683,
      lng: -122.03796,
      city: 'Sammamish',
      state: 'WA',
    },
    98077: {
      lat: 47.75263,
      lng: -122.05754,
      city: 'Woodinville',
      state: 'WA',
    },
    98087: {
      lat: 47.8624,
      lng: -122.2664,
      city: 'Lynnwood',
      state: 'WA',
    },
    98092: {
      lat: 47.28851,
      lng: -122.13123,
      city: 'Auburn',
      state: 'WA',
    },
    98101: {
      lat: 47.61119,
      lng: -122.33454,
      city: 'Seattle',
      state: 'WA',
    },
    98102: {
      lat: 47.63627,
      lng: -122.32337,
      city: 'Seattle',
      state: 'WA',
    },
    98103: {
      lat: 47.6732,
      lng: -122.34258,
      city: 'Seattle',
      state: 'WA',
    },
    98104: {
      lat: 47.60044,
      lng: -122.33004,
      city: 'Seattle',
      state: 'WA',
    },
    98105: {
      lat: 47.66094,
      lng: -122.28321,
      city: 'Seattle',
      state: 'WA',
    },
    98106: {
      lat: 47.54339,
      lng: -122.35366,
      city: 'Seattle',
      state: 'WA',
    },
    98107: {
      lat: 47.66757,
      lng: -122.37789,
      city: 'Seattle',
      state: 'WA',
    },
    98108: {
      lat: 47.54113,
      lng: -122.3126,
      city: 'Seattle',
      state: 'WA',
    },
    98109: {
      lat: 47.63128,
      lng: -122.34486,
      city: 'Seattle',
      state: 'WA',
    },
    98110: {
      lat: 47.64389,
      lng: -122.54335,
      city: 'Bainbridge Island',
      state: 'WA',
    },
    98112: {
      lat: 47.63242,
      lng: -122.28732,
      city: 'Seattle',
      state: 'WA',
    },
    98115: {
      lat: 47.68547,
      lng: -122.28234,
      city: 'Seattle',
      state: 'WA',
    },
    98116: {
      lat: 47.57399,
      lng: -122.39503,
      city: 'Seattle',
      state: 'WA',
    },
    98117: {
      lat: 47.6882,
      lng: -122.38157,
      city: 'Seattle',
      state: 'WA',
    },
    98118: {
      lat: 47.54235,
      lng: -122.26872,
      city: 'Seattle',
      state: 'WA',
    },
    98119: {
      lat: 47.63943,
      lng: -122.36932,
      city: 'Seattle',
      state: 'WA',
    },
    98121: {
      lat: 47.61548,
      lng: -122.34668,
      city: 'Seattle',
      state: 'WA',
    },
    98122: {
      lat: 47.60985,
      lng: -122.29569,
      city: 'Seattle',
      state: 'WA',
    },
    98125: {
      lat: 47.71706,
      lng: -122.29969,
      city: 'Seattle',
      state: 'WA',
    },
    98126: {
      lat: 47.54732,
      lng: -122.37454,
      city: 'Seattle',
      state: 'WA',
    },
    98133: {
      lat: 47.7399,
      lng: -122.34432,
      city: 'Seattle',
      state: 'WA',
    },
    98134: {
      lat: 47.57691,
      lng: -122.33686,
      city: 'Seattle',
      state: 'WA',
    },
    98136: {
      lat: 47.53686,
      lng: -122.38992,
      city: 'Seattle',
      state: 'WA',
    },
    98144: {
      lat: 47.58582,
      lng: -122.29205,
      city: 'Seattle',
      state: 'WA',
    },
    98146: {
      lat: 47.50008,
      lng: -122.35768,
      city: 'Seattle',
      state: 'WA',
    },
    98148: {
      lat: 47.44494,
      lng: -122.32587,
      city: 'Seattle',
      state: 'WA',
    },
    98154: {
      lat: 47.60621,
      lng: -122.33379,
      city: 'Seattle',
      state: 'WA',
    },
    98155: {
      lat: 47.75609,
      lng: -122.30039,
      city: 'Seattle',
      state: 'WA',
    },
    98158: {
      lat: 47.45043,
      lng: -122.30805,
      city: 'Seattle',
      state: 'WA',
    },
    98164: {
      lat: 47.60596,
      lng: -122.33203,
      city: 'Seattle',
      state: 'WA',
    },
    98166: {
      lat: 47.45256,
      lng: -122.34978,
      city: 'Seattle',
      state: 'WA',
    },
    98168: {
      lat: 47.48898,
      lng: -122.30175,
      city: 'Seattle',
      state: 'WA',
    },
    98174: {
      lat: 47.60477,
      lng: -122.33528,
      city: 'Seattle',
      state: 'WA',
    },
    98177: {
      lat: 47.74237,
      lng: -122.37085,
      city: 'Seattle',
      state: 'WA',
    },
    98178: {
      lat: 47.49921,
      lng: -122.24735,
      city: 'Seattle',
      state: 'WA',
    },
    98188: {
      lat: 47.44754,
      lng: -122.27398,
      city: 'Seattle',
      state: 'WA',
    },
    98195: {
      lat: 47.65466,
      lng: -122.30752,
      city: 'Seattle',
      state: 'WA',
    },
    98198: {
      lat: 47.39469,
      lng: -122.31111,
      city: 'Seattle',
      state: 'WA',
    },
    98199: {
      lat: 47.65139,
      lng: -122.40223,
      city: 'Seattle',
      state: 'WA',
    },
    98201: {
      lat: 47.99373,
      lng: -122.18563,
      city: 'Everett',
      state: 'WA',
    },
    98203: {
      lat: 47.94236,
      lng: -122.23651,
      city: 'Everett',
      state: 'WA',
    },
    98204: {
      lat: 47.90105,
      lng: -122.2586,
      city: 'Everett',
      state: 'WA',
    },
    98207: {
      lat: 47.98793,
      lng: -122.2229,
      city: 'Everett',
      state: 'WA',
    },
    98208: {
      lat: 47.90072,
      lng: -122.18852,
      city: 'Everett',
      state: 'WA',
    },
    98220: {
      lat: 48.68526,
      lng: -122.19678,
      city: 'Acme',
      state: 'WA',
    },
    98221: {
      lat: 48.50401,
      lng: -122.65435,
      city: 'Anacortes',
      state: 'WA',
    },
    98222: {
      lat: 48.56085,
      lng: -122.80263,
      city: 'Blakely Island',
      state: 'WA',
    },
    98223: {
      lat: 48.20994,
      lng: -121.95081,
      city: 'Arlington',
      state: 'WA',
    },
    98224: {
      lat: 47.7357,
      lng: -121.56859,
      city: 'Baring',
      state: 'WA',
    },
    98225: {
      lat: 48.75461,
      lng: -122.50307,
      city: 'Bellingham',
      state: 'WA',
    },
    98226: {
      lat: 48.80064,
      lng: -122.42299,
      city: 'Bellingham',
      state: 'WA',
    },
    98229: {
      lat: 48.69615,
      lng: -122.40979,
      city: 'Bellingham',
      state: 'WA',
    },
    98230: {
      lat: 48.95688,
      lng: -122.7084,
      city: 'Blaine',
      state: 'WA',
    },
    98232: { lat: 48.56531, lng: -122.41498, city: 'Bow', state: 'WA' },
    98233: {
      lat: 48.50309,
      lng: -122.35102,
      city: 'Burlington',
      state: 'WA',
    },
    98235: {
      lat: 48.4602,
      lng: -122.22846,
      city: 'Clearlake',
      state: 'WA',
    },
    98236: {
      lat: 47.95804,
      lng: -122.40468,
      city: 'Clinton',
      state: 'WA',
    },
    98237: {
      lat: 48.53272,
      lng: -121.69748,
      city: 'Concrete',
      state: 'WA',
    },
    98238: {
      lat: 48.33563,
      lng: -122.34444,
      city: 'Conway',
      state: 'WA',
    },
    98239: {
      lat: 48.18926,
      lng: -122.65756,
      city: 'Coupeville',
      state: 'WA',
    },
    98240: {
      lat: 48.94711,
      lng: -122.62061,
      city: 'Custer',
      state: 'WA',
    },
    98241: {
      lat: 48.21425,
      lng: -121.33219,
      city: 'Darrington',
      state: 'WA',
    },
    98243: {
      lat: 48.61512,
      lng: -122.99882,
      city: 'Deer Harbor',
      state: 'WA',
    },
    98244: {
      lat: 48.79423,
      lng: -121.93557,
      city: 'Deming',
      state: 'WA',
    },
    98245: {
      lat: 48.66241,
      lng: -122.91616,
      city: 'Eastsound',
      state: 'WA',
    },
    98247: {
      lat: 48.90459,
      lng: -122.31732,
      city: 'Everson',
      state: 'WA',
    },
    98248: {
      lat: 48.86494,
      lng: -122.62211,
      city: 'Ferndale',
      state: 'WA',
    },
    98249: {
      lat: 48.01888,
      lng: -122.53947,
      city: 'Freeland',
      state: 'WA',
    },
    98250: {
      lat: 48.55264,
      lng: -123.09631,
      city: 'Friday Harbor',
      state: 'WA',
    },
    98251: {
      lat: 47.89812,
      lng: -121.55752,
      city: 'Gold Bar',
      state: 'WA',
    },
    98252: {
      lat: 48.06173,
      lng: -121.70939,
      city: 'Granite Falls',
      state: 'WA',
    },
    98253: {
      lat: 48.079,
      lng: -122.57568,
      city: 'Greenbank',
      state: 'WA',
    },
    98255: {
      lat: 48.52353,
      lng: -121.99203,
      city: 'Hamilton',
      state: 'WA',
    },
    98256: {
      lat: 47.82883,
      lng: -121.41464,
      city: 'Index',
      state: 'WA',
    },
    98257: {
      lat: 48.415,
      lng: -122.50879,
      city: 'La Conner',
      state: 'WA',
    },
    98258: {
      lat: 48.03252,
      lng: -122.07762,
      city: 'Lake Stevens',
      state: 'WA',
    },
    98260: {
      lat: 48.03074,
      lng: -122.45106,
      city: 'Langley',
      state: 'WA',
    },
    98261: {
      lat: 48.48381,
      lng: -122.88302,
      city: 'Lopez Island',
      state: 'WA',
    },
    98262: {
      lat: 48.69317,
      lng: -122.66406,
      city: 'Lummi Island',
      state: 'WA',
    },
    98263: {
      lat: 48.52281,
      lng: -122.0657,
      city: 'Lyman',
      state: 'WA',
    },
    98264: {
      lat: 48.94972,
      lng: -122.45672,
      city: 'Lynden',
      state: 'WA',
    },
    98266: {
      lat: 48.9576,
      lng: -122.06189,
      city: 'Maple Falls',
      state: 'WA',
    },
    98267: {
      lat: 48.43756,
      lng: -121.30841,
      city: 'Marblemount',
      state: 'WA',
    },
    98270: {
      lat: 48.05952,
      lng: -122.1443,
      city: 'Marysville',
      state: 'WA',
    },
    98271: {
      lat: 48.09373,
      lng: -122.23559,
      city: 'Marysville',
      state: 'WA',
    },
    98272: {
      lat: 47.85834,
      lng: -121.88457,
      city: 'Monroe',
      state: 'WA',
    },
    98273: {
      lat: 48.40522,
      lng: -122.36841,
      city: 'Mount Vernon',
      state: 'WA',
    },
    98274: {
      lat: 48.36013,
      lng: -122.14346,
      city: 'Mount Vernon',
      state: 'WA',
    },
    98275: {
      lat: 47.90994,
      lng: -122.30113,
      city: 'Mukilteo',
      state: 'WA',
    },
    98276: {
      lat: 48.92765,
      lng: -122.32096,
      city: 'Nooksack',
      state: 'WA',
    },
    98277: {
      lat: 48.31688,
      lng: -122.63152,
      city: 'Oak Harbor',
      state: 'WA',
    },
    98278: {
      lat: 48.34388,
      lng: -122.66491,
      city: 'Oak Harbor',
      state: 'WA',
    },
    98279: {
      lat: 48.64474,
      lng: -122.80602,
      city: 'Olga',
      state: 'WA',
    },
    98280: {
      lat: 48.60866,
      lng: -122.90554,
      city: 'Orcas',
      state: 'WA',
    },
    98281: {
      lat: 48.98817,
      lng: -123.05767,
      city: 'Point Roberts',
      state: 'WA',
    },
    98282: {
      lat: 48.18719,
      lng: -122.46966,
      city: 'Camano Island',
      state: 'WA',
    },
    98283: {
      lat: 48.80137,
      lng: -121.29997,
      city: 'Rockport',
      state: 'WA',
    },
    98284: {
      lat: 48.55211,
      lng: -122.11763,
      city: 'Sedro Woolley',
      state: 'WA',
    },
    98286: {
      lat: 48.57262,
      lng: -122.95712,
      city: 'Shaw Island',
      state: 'WA',
    },
    98288: {
      lat: 47.65204,
      lng: -121.3574,
      city: 'Skykomish',
      state: 'WA',
    },
    98290: {
      lat: 47.95141,
      lng: -121.98421,
      city: 'Snohomish',
      state: 'WA',
    },
    98292: {
      lat: 48.22455,
      lng: -122.3032,
      city: 'Stanwood',
      state: 'WA',
    },
    98294: {
      lat: 47.84431,
      lng: -121.75822,
      city: 'Sultan',
      state: 'WA',
    },
    98295: {
      lat: 48.97163,
      lng: -122.2213,
      city: 'Sumas',
      state: 'WA',
    },
    98296: {
      lat: 47.84266,
      lng: -122.10199,
      city: 'Snohomish',
      state: 'WA',
    },
    98297: {
      lat: 48.7005,
      lng: -123.02834,
      city: 'Waldron',
      state: 'WA',
    },
    98303: {
      lat: 47.15783,
      lng: -122.70387,
      city: 'Anderson Island',
      state: 'WA',
    },
    98304: {
      lat: 46.87036,
      lng: -121.73985,
      city: 'Ashford',
      state: 'WA',
    },
    98305: {
      lat: 48.03148,
      lng: -124.22934,
      city: 'Beaver',
      state: 'WA',
    },
    98310: {
      lat: 47.58939,
      lng: -122.6175,
      city: 'Bremerton',
      state: 'WA',
    },
    98311: {
      lat: 47.62829,
      lng: -122.63793,
      city: 'Bremerton',
      state: 'WA',
    },
    98312: {
      lat: 47.56435,
      lng: -122.75706,
      city: 'Bremerton',
      state: 'WA',
    },
    98314: {
      lat: 47.56243,
      lng: -122.63893,
      city: 'Bremerton',
      state: 'WA',
    },
    98315: {
      lat: 47.72263,
      lng: -122.71444,
      city: 'Silverdale',
      state: 'WA',
    },
    98320: {
      lat: 47.69861,
      lng: -123.01742,
      city: 'Brinnon',
      state: 'WA',
    },
    98321: {
      lat: 47.15564,
      lng: -122.06576,
      city: 'Buckley',
      state: 'WA',
    },
    98323: {
      lat: 46.98059,
      lng: -122.006,
      city: 'Carbonado',
      state: 'WA',
    },
    98325: {
      lat: 47.96093,
      lng: -122.77911,
      city: 'Chimacum',
      state: 'WA',
    },
    98326: {
      lat: 48.17364,
      lng: -124.45278,
      city: 'Clallam Bay',
      state: 'WA',
    },
    98327: {
      lat: 47.10203,
      lng: -122.66061,
      city: 'Dupont',
      state: 'WA',
    },
    98328: {
      lat: 46.86055,
      lng: -122.17227,
      city: 'Eatonville',
      state: 'WA',
    },
    98329: {
      lat: 47.37731,
      lng: -122.72523,
      city: 'Gig Harbor',
      state: 'WA',
    },
    98330: {
      lat: 46.75874,
      lng: -122.15894,
      city: 'Elbe',
      state: 'WA',
    },
    98331: {
      lat: 47.77292,
      lng: -124.1273,
      city: 'Forks',
      state: 'WA',
    },
    98332: {
      lat: 47.36514,
      lng: -122.59858,
      city: 'Gig Harbor',
      state: 'WA',
    },
    98333: {
      lat: 47.24771,
      lng: -122.62561,
      city: 'Fox Island',
      state: 'WA',
    },
    98335: {
      lat: 47.29909,
      lng: -122.61911,
      city: 'Gig Harbor',
      state: 'WA',
    },
    98336: {
      lat: 46.56382,
      lng: -122.10939,
      city: 'Glenoma',
      state: 'WA',
    },
    98337: {
      lat: 47.56345,
      lng: -122.63405,
      city: 'Bremerton',
      state: 'WA',
    },
    98338: {
      lat: 47.01927,
      lng: -122.28347,
      city: 'Graham',
      state: 'WA',
    },
    98339: {
      lat: 48.02991,
      lng: -122.74364,
      city: 'Port Hadlock',
      state: 'WA',
    },
    98340: {
      lat: 47.90622,
      lng: -122.56406,
      city: 'Hansville',
      state: 'WA',
    },
    98342: {
      lat: 47.75475,
      lng: -122.51952,
      city: 'Indianola',
      state: 'WA',
    },
    98345: {
      lat: 47.69972,
      lng: -122.62366,
      city: 'Keyport',
      state: 'WA',
    },
    98346: {
      lat: 47.82005,
      lng: -122.53399,
      city: 'Kingston',
      state: 'WA',
    },
    98349: {
      lat: 47.26583,
      lng: -122.77366,
      city: 'Lakebay',
      state: 'WA',
    },
    98350: {
      lat: 47.90683,
      lng: -124.62509,
      city: 'La Push',
      state: 'WA',
    },
    98351: {
      lat: 47.20353,
      lng: -122.76773,
      city: 'Longbranch',
      state: 'WA',
    },
    98353: {
      lat: 47.53885,
      lng: -122.49294,
      city: 'Manchester',
      state: 'WA',
    },
    98354: {
      lat: 47.25113,
      lng: -122.31557,
      city: 'Milton',
      state: 'WA',
    },
    98355: {
      lat: 46.69179,
      lng: -122.03978,
      city: 'Mineral',
      state: 'WA',
    },
    98356: {
      lat: 46.57943,
      lng: -122.26913,
      city: 'Morton',
      state: 'WA',
    },
    98357: {
      lat: 48.33188,
      lng: -124.62816,
      city: 'Neah Bay',
      state: 'WA',
    },
    98358: {
      lat: 48.05796,
      lng: -122.70008,
      city: 'Nordland',
      state: 'WA',
    },
    98359: {
      lat: 47.43047,
      lng: -122.57631,
      city: 'Olalla',
      state: 'WA',
    },
    98360: {
      lat: 47.03246,
      lng: -122.1575,
      city: 'Orting',
      state: 'WA',
    },
    98361: {
      lat: 46.62786,
      lng: -121.56385,
      city: 'Packwood',
      state: 'WA',
    },
    98362: {
      lat: 48.01639,
      lng: -123.31951,
      city: 'Port Angeles',
      state: 'WA',
    },
    98363: {
      lat: 48.03477,
      lng: -123.76952,
      city: 'Port Angeles',
      state: 'WA',
    },
    98364: {
      lat: 47.85455,
      lng: -122.58638,
      city: 'Port Gamble',
      state: 'WA',
    },
    98365: {
      lat: 47.89545,
      lng: -122.70271,
      city: 'Port Ludlow',
      state: 'WA',
    },
    98366: {
      lat: 47.53732,
      lng: -122.58647,
      city: 'Port Orchard',
      state: 'WA',
    },
    98367: {
      lat: 47.46141,
      lng: -122.67662,
      city: 'Port Orchard',
      state: 'WA',
    },
    98368: {
      lat: 48.03879,
      lng: -122.84406,
      city: 'Port Townsend',
      state: 'WA',
    },
    98370: {
      lat: 47.75114,
      lng: -122.62373,
      city: 'Poulsbo',
      state: 'WA',
    },
    98371: {
      lat: 47.19957,
      lng: -122.32191,
      city: 'Puyallup',
      state: 'WA',
    },
    98372: {
      lat: 47.2064,
      lng: -122.26689,
      city: 'Puyallup',
      state: 'WA',
    },
    98373: {
      lat: 47.14761,
      lng: -122.32506,
      city: 'Puyallup',
      state: 'WA',
    },
    98374: {
      lat: 47.12904,
      lng: -122.26156,
      city: 'Puyallup',
      state: 'WA',
    },
    98375: {
      lat: 47.10394,
      lng: -122.32383,
      city: 'Puyallup',
      state: 'WA',
    },
    98376: {
      lat: 47.8455,
      lng: -122.85916,
      city: 'Quilcene',
      state: 'WA',
    },
    98377: {
      lat: 46.50586,
      lng: -121.81912,
      city: 'Randle',
      state: 'WA',
    },
    98380: {
      lat: 47.5743,
      lng: -122.90287,
      city: 'Seabeck',
      state: 'WA',
    },
    98381: {
      lat: 48.28863,
      lng: -124.45681,
      city: 'Sekiu',
      state: 'WA',
    },
    98382: {
      lat: 48.0131,
      lng: -123.07437,
      city: 'Sequim',
      state: 'WA',
    },
    98383: {
      lat: 47.66212,
      lng: -122.71652,
      city: 'Silverdale',
      state: 'WA',
    },
    98385: {
      lat: 47.13791,
      lng: -122.09469,
      city: 'South Prairie',
      state: 'WA',
    },
    98387: {
      lat: 47.0562,
      lng: -122.39111,
      city: 'Spanaway',
      state: 'WA',
    },
    98388: {
      lat: 47.19892,
      lng: -122.66268,
      city: 'Steilacoom',
      state: 'WA',
    },
    98390: {
      lat: 47.21195,
      lng: -122.22894,
      city: 'Sumner',
      state: 'WA',
    },
    98391: {
      lat: 47.17654,
      lng: -122.17362,
      city: 'Bonney Lake',
      state: 'WA',
    },
    98392: {
      lat: 47.73425,
      lng: -122.5631,
      city: 'Suquamish',
      state: 'WA',
    },
    98394: {
      lat: 47.32011,
      lng: -122.77791,
      city: 'Vaughn',
      state: 'WA',
    },
    98396: {
      lat: 47.10669,
      lng: -122.01052,
      city: 'Wilkeson',
      state: 'WA',
    },
    98402: {
      lat: 47.25021,
      lng: -122.44056,
      city: 'Tacoma',
      state: 'WA',
    },
    98403: {
      lat: 47.26581,
      lng: -122.45921,
      city: 'Tacoma',
      state: 'WA',
    },
    98404: {
      lat: 47.20927,
      lng: -122.41139,
      city: 'Tacoma',
      state: 'WA',
    },
    98405: {
      lat: 47.24599,
      lng: -122.47222,
      city: 'Tacoma',
      state: 'WA',
    },
    98406: {
      lat: 47.26283,
      lng: -122.50857,
      city: 'Tacoma',
      state: 'WA',
    },
    98407: {
      lat: 47.28889,
      lng: -122.5111,
      city: 'Tacoma',
      state: 'WA',
    },
    98408: {
      lat: 47.19811,
      lng: -122.44638,
      city: 'Tacoma',
      state: 'WA',
    },
    98409: {
      lat: 47.21084,
      lng: -122.48197,
      city: 'Tacoma',
      state: 'WA',
    },
    98416: {
      lat: 47.26271,
      lng: -122.48196,
      city: 'Tacoma',
      state: 'WA',
    },
    98418: {
      lat: 47.22325,
      lng: -122.44642,
      city: 'Tacoma',
      state: 'WA',
    },
    98421: {
      lat: 47.25819,
      lng: -122.40107,
      city: 'Tacoma',
      state: 'WA',
    },
    98422: {
      lat: 47.28907,
      lng: -122.39123,
      city: 'Tacoma',
      state: 'WA',
    },
    98424: { lat: 47.2343, lng: -122.3523, city: 'Fife', state: 'WA' },
    98430: {
      lat: 47.11733,
      lng: -122.56896,
      city: 'Camp Murray',
      state: 'WA',
    },
    98433: {
      lat: 47.09121,
      lng: -122.60772,
      city: 'Tacoma',
      state: 'WA',
    },
    98438: {
      lat: 47.13514,
      lng: -122.49211,
      city: 'Mcchord Afb',
      state: 'WA',
    },
    98439: {
      lat: 47.13164,
      lng: -122.49603,
      city: 'Lakewood',
      state: 'WA',
    },
    98443: {
      lat: 47.20522,
      lng: -122.37592,
      city: 'Tacoma',
      state: 'WA',
    },
    98444: {
      lat: 47.15228,
      lng: -122.44887,
      city: 'Tacoma',
      state: 'WA',
    },
    98445: {
      lat: 47.13985,
      lng: -122.40965,
      city: 'Tacoma',
      state: 'WA',
    },
    98446: {
      lat: 47.12935,
      lng: -122.37424,
      city: 'Tacoma',
      state: 'WA',
    },
    98447: {
      lat: 47.14531,
      lng: -122.44392,
      city: 'Tacoma',
      state: 'WA',
    },
    98465: {
      lat: 47.24868,
      lng: -122.53043,
      city: 'Tacoma',
      state: 'WA',
    },
    98466: {
      lat: 47.22842,
      lng: -122.53479,
      city: 'Tacoma',
      state: 'WA',
    },
    98467: {
      lat: 47.2038,
      lng: -122.5447,
      city: 'University Place',
      state: 'WA',
    },
    98498: {
      lat: 47.16001,
      lng: -122.55971,
      city: 'Lakewood',
      state: 'WA',
    },
    98499: {
      lat: 47.16662,
      lng: -122.50624,
      city: 'Lakewood',
      state: 'WA',
    },
    98501: {
      lat: 46.9783,
      lng: -122.86989,
      city: 'Olympia',
      state: 'WA',
    },
    98502: {
      lat: 47.08466,
      lng: -123.02958,
      city: 'Olympia',
      state: 'WA',
    },
    98503: { lat: 47.02528, lng: -122.799, city: 'Lacey', state: 'WA' },
    98505: {
      lat: 47.07321,
      lng: -122.97358,
      city: 'Olympia',
      state: 'WA',
    },
    98506: {
      lat: 47.10286,
      lng: -122.87307,
      city: 'Olympia',
      state: 'WA',
    },
    98512: {
      lat: 46.95326,
      lng: -123.04259,
      city: 'Olympia',
      state: 'WA',
    },
    98513: {
      lat: 46.99074,
      lng: -122.73298,
      city: 'Olympia',
      state: 'WA',
    },
    98516: {
      lat: 47.09647,
      lng: -122.78265,
      city: 'Olympia',
      state: 'WA',
    },
    98520: {
      lat: 46.99478,
      lng: -123.81054,
      city: 'Aberdeen',
      state: 'WA',
    },
    98524: {
      lat: 47.36703,
      lng: -122.84548,
      city: 'Allyn',
      state: 'WA',
    },
    98526: {
      lat: 47.47749,
      lng: -123.99227,
      city: 'Amanda Park',
      state: 'WA',
    },
    98527: {
      lat: 46.58917,
      lng: -123.90939,
      city: 'Bay Center',
      state: 'WA',
    },
    98528: {
      lat: 47.44605,
      lng: -122.89618,
      city: 'Belfair',
      state: 'WA',
    },
    98530: {
      lat: 46.80519,
      lng: -122.86804,
      city: 'Bucoda',
      state: 'WA',
    },
    98531: {
      lat: 46.73646,
      lng: -122.91364,
      city: 'Centralia',
      state: 'WA',
    },
    98532: {
      lat: 46.63628,
      lng: -123.03444,
      city: 'Chehalis',
      state: 'WA',
    },
    98533: {
      lat: 46.67304,
      lng: -122.43115,
      city: 'Cinebar',
      state: 'WA',
    },
    98535: {
      lat: 47.0994,
      lng: -124.11973,
      city: 'Copalis Beach',
      state: 'WA',
    },
    98536: {
      lat: 47.18939,
      lng: -124.111,
      city: 'Copalis Crossing',
      state: 'WA',
    },
    98537: {
      lat: 46.81719,
      lng: -123.59768,
      city: 'Cosmopolis',
      state: 'WA',
    },
    98538: {
      lat: 46.47422,
      lng: -123.15884,
      city: 'Curtis',
      state: 'WA',
    },
    98539: {
      lat: 46.63772,
      lng: -123.27315,
      city: 'Doty',
      state: 'WA',
    },
    98541: {
      lat: 47.06877,
      lng: -123.40459,
      city: 'Elma',
      state: 'WA',
    },
    98542: {
      lat: 46.5293,
      lng: -122.73155,
      city: 'Ethel',
      state: 'WA',
    },
    98544: {
      lat: 46.73912,
      lng: -123.02712,
      city: 'Galvin',
      state: 'WA',
    },
    98546: {
      lat: 47.32439,
      lng: -122.92362,
      city: 'Grapeview',
      state: 'WA',
    },
    98547: {
      lat: 46.78622,
      lng: -124.01956,
      city: 'Grayland',
      state: 'WA',
    },
    98548: {
      lat: 47.47064,
      lng: -123.29273,
      city: 'Hoodsport',
      state: 'WA',
    },
    98550: {
      lat: 47.14081,
      lng: -123.90122,
      city: 'Hoquiam',
      state: 'WA',
    },
    98552: {
      lat: 47.3136,
      lng: -123.9444,
      city: 'Humptulips',
      state: 'WA',
    },
    98555: {
      lat: 47.54634,
      lng: -123.06369,
      city: 'Lilliwaup',
      state: 'WA',
    },
    98556: {
      lat: 46.88565,
      lng: -123.1409,
      city: 'Littlerock',
      state: 'WA',
    },
    98557: {
      lat: 47.04332,
      lng: -123.2681,
      city: 'Mccleary',
      state: 'WA',
    },
    98558: {
      lat: 46.93579,
      lng: -122.55998,
      city: 'Mckenna',
      state: 'WA',
    },
    98559: {
      lat: 46.95884,
      lng: -123.32749,
      city: 'Malone',
      state: 'WA',
    },
    98560: {
      lat: 47.30577,
      lng: -123.43948,
      city: 'Matlock',
      state: 'WA',
    },
    98562: {
      lat: 47.23877,
      lng: -124.20336,
      city: 'Moclips',
      state: 'WA',
    },
    98563: {
      lat: 47.2064,
      lng: -123.60824,
      city: 'Montesano',
      state: 'WA',
    },
    98564: {
      lat: 46.45666,
      lng: -122.43627,
      city: 'Mossyrock',
      state: 'WA',
    },
    98565: {
      lat: 46.57838,
      lng: -122.90693,
      city: 'Napavine',
      state: 'WA',
    },
    98566: {
      lat: 47.39616,
      lng: -123.95976,
      city: 'Neilton',
      state: 'WA',
    },
    98568: {
      lat: 46.86467,
      lng: -123.30235,
      city: 'Oakville',
      state: 'WA',
    },
    98569: {
      lat: 47.00206,
      lng: -124.15479,
      city: 'Ocean Shores',
      state: 'WA',
    },
    98570: {
      lat: 46.63556,
      lng: -122.63534,
      city: 'Onalaska',
      state: 'WA',
    },
    98571: {
      lat: 47.20356,
      lng: -124.19532,
      city: 'Pacific Beach',
      state: 'WA',
    },
    98572: {
      lat: 46.55256,
      lng: -123.32429,
      city: 'Pe Ell',
      state: 'WA',
    },
    98575: {
      lat: 47.43607,
      lng: -123.79848,
      city: 'Quinault',
      state: 'WA',
    },
    98576: {
      lat: 46.84417,
      lng: -122.64687,
      city: 'Rainier',
      state: 'WA',
    },
    98577: {
      lat: 46.6418,
      lng: -123.60031,
      city: 'Raymond',
      state: 'WA',
    },
    98579: {
      lat: 46.79406,
      lng: -123.11952,
      city: 'Rochester',
      state: 'WA',
    },
    98580: { lat: 46.9918, lng: -122.50101, city: 'Roy', state: 'WA' },
    98581: {
      lat: 46.35875,
      lng: -123.06865,
      city: 'Ryderwood',
      state: 'WA',
    },
    98582: {
      lat: 46.51686,
      lng: -122.62693,
      city: 'Salkum',
      state: 'WA',
    },
    98583: {
      lat: 47.00122,
      lng: -123.4875,
      city: 'Satsop',
      state: 'WA',
    },
    98584: {
      lat: 47.24608,
      lng: -123.16209,
      city: 'Shelton',
      state: 'WA',
    },
    98585: {
      lat: 46.55436,
      lng: -122.5022,
      city: 'Silver Creek',
      state: 'WA',
    },
    98586: {
      lat: 46.55909,
      lng: -123.8391,
      city: 'South Bend',
      state: 'WA',
    },
    98587: {
      lat: 47.32039,
      lng: -124.1562,
      city: 'Taholah',
      state: 'WA',
    },
    98588: {
      lat: 47.43555,
      lng: -123.02261,
      city: 'Tahuya',
      state: 'WA',
    },
    98589: {
      lat: 46.84133,
      lng: -122.82087,
      city: 'Tenino',
      state: 'WA',
    },
    98590: {
      lat: 46.71882,
      lng: -124.00272,
      city: 'Tokeland',
      state: 'WA',
    },
    98591: {
      lat: 46.44224,
      lng: -122.74671,
      city: 'Toledo',
      state: 'WA',
    },
    98592: {
      lat: 47.32895,
      lng: -123.07907,
      city: 'Union',
      state: 'WA',
    },
    98593: {
      lat: 46.40767,
      lng: -123.00807,
      city: 'Vader',
      state: 'WA',
    },
    98595: {
      lat: 46.88302,
      lng: -124.1088,
      city: 'Westport',
      state: 'WA',
    },
    98596: {
      lat: 46.49556,
      lng: -122.95364,
      city: 'Winlock',
      state: 'WA',
    },
    98597: {
      lat: 46.85241,
      lng: -122.49212,
      city: 'Yelm',
      state: 'WA',
    },
    98601: {
      lat: 45.93439,
      lng: -122.43857,
      city: 'Amboy',
      state: 'WA',
    },
    98602: {
      lat: 45.8693,
      lng: -121.27966,
      city: 'Appleton',
      state: 'WA',
    },
    98603: {
      lat: 46.04223,
      lng: -122.42001,
      city: 'Ariel',
      state: 'WA',
    },
    98604: {
      lat: 45.80013,
      lng: -122.5054,
      city: 'Battle Ground',
      state: 'WA',
    },
    98605: {
      lat: 45.77846,
      lng: -121.6393,
      city: 'Bingen',
      state: 'WA',
    },
    98606: {
      lat: 45.72921,
      lng: -122.45615,
      city: 'Brush Prairie',
      state: 'WA',
    },
    98607: {
      lat: 45.64395,
      lng: -122.38242,
      city: 'Camas',
      state: 'WA',
    },
    98609: {
      lat: 46.0717,
      lng: -122.86569,
      city: 'Carrolls',
      state: 'WA',
    },
    98610: {
      lat: 45.77765,
      lng: -122.01401,
      city: 'Carson',
      state: 'WA',
    },
    98611: {
      lat: 46.30642,
      lng: -122.90479,
      city: 'Castle Rock',
      state: 'WA',
    },
    98612: {
      lat: 46.23462,
      lng: -123.31194,
      city: 'Cathlamet',
      state: 'WA',
    },
    98613: {
      lat: 45.7542,
      lng: -120.97235,
      city: 'Centerville',
      state: 'WA',
    },
    98614: {
      lat: 46.29459,
      lng: -123.9034,
      city: 'Chinook',
      state: 'WA',
    },
    98616: {
      lat: 46.06227,
      lng: -122.14599,
      city: 'Cougar',
      state: 'WA',
    },
    98617: {
      lat: 45.64283,
      lng: -121.14229,
      city: 'Dallesport',
      state: 'WA',
    },
    98619: {
      lat: 45.97693,
      lng: -121.26568,
      city: 'Glenwood',
      state: 'WA',
    },
    98620: {
      lat: 45.87096,
      lng: -120.77846,
      city: 'Goldendale',
      state: 'WA',
    },
    98621: {
      lat: 46.4006,
      lng: -123.54428,
      city: 'Grays River',
      state: 'WA',
    },
    98623: {
      lat: 45.79956,
      lng: -121.49347,
      city: 'Husum',
      state: 'WA',
    },
    98624: {
      lat: 46.3578,
      lng: -123.94094,
      city: 'Ilwaco',
      state: 'WA',
    },
    98625: {
      lat: 46.10871,
      lng: -122.56093,
      city: 'Kalama',
      state: 'WA',
    },
    98626: {
      lat: 46.15317,
      lng: -122.78564,
      city: 'Kelso',
      state: 'WA',
    },
    98628: {
      lat: 45.85015,
      lng: -121.09887,
      city: 'Klickitat',
      state: 'WA',
    },
    98629: {
      lat: 45.88109,
      lng: -122.6148,
      city: 'La Center',
      state: 'WA',
    },
    98631: {
      lat: 46.37988,
      lng: -124.02241,
      city: 'Long Beach',
      state: 'WA',
    },
    98632: {
      lat: 46.21223,
      lng: -123.06855,
      city: 'Longview',
      state: 'WA',
    },
    98635: {
      lat: 45.74364,
      lng: -121.20915,
      city: 'Lyle',
      state: 'WA',
    },
    98638: {
      lat: 46.4047,
      lng: -123.73588,
      city: 'Naselle',
      state: 'WA',
    },
    98639: {
      lat: 45.64364,
      lng: -121.96288,
      city: 'North Bonneville',
      state: 'WA',
    },
    98640: {
      lat: 46.5429,
      lng: -124.04938,
      city: 'Ocean Park',
      state: 'WA',
    },
    98641: {
      lat: 46.56484,
      lng: -124.03932,
      city: 'Oysterville',
      state: 'WA',
    },
    98642: {
      lat: 45.80262,
      lng: -122.7019,
      city: 'Ridgefield',
      state: 'WA',
    },
    98643: {
      lat: 46.30326,
      lng: -123.62117,
      city: 'Rosburg',
      state: 'WA',
    },
    98644: {
      lat: 46.32861,
      lng: -124.04009,
      city: 'Seaview',
      state: 'WA',
    },
    98645: {
      lat: 46.32509,
      lng: -122.77993,
      city: 'Silverlake',
      state: 'WA',
    },
    98647: {
      lat: 46.32137,
      lng: -123.4354,
      city: 'Skamokawa',
      state: 'WA',
    },
    98648: {
      lat: 45.70563,
      lng: -121.9489,
      city: 'Stevenson',
      state: 'WA',
    },
    98649: {
      lat: 46.31504,
      lng: -122.53415,
      city: 'Toutle',
      state: 'WA',
    },
    98650: {
      lat: 45.97117,
      lng: -121.52732,
      city: 'Trout Lake',
      state: 'WA',
    },
    98651: {
      lat: 45.73419,
      lng: -121.58718,
      city: 'Underwood',
      state: 'WA',
    },
    98660: {
      lat: 45.6866,
      lng: -122.73152,
      city: 'Vancouver',
      state: 'WA',
    },
    98661: {
      lat: 45.63935,
      lng: -122.62586,
      city: 'Vancouver',
      state: 'WA',
    },
    98662: {
      lat: 45.68911,
      lng: -122.57742,
      city: 'Vancouver',
      state: 'WA',
    },
    98663: {
      lat: 45.65449,
      lng: -122.66228,
      city: 'Vancouver',
      state: 'WA',
    },
    98664: {
      lat: 45.61966,
      lng: -122.57738,
      city: 'Vancouver',
      state: 'WA',
    },
    98665: {
      lat: 45.68155,
      lng: -122.65417,
      city: 'Vancouver',
      state: 'WA',
    },
    98670: {
      lat: 45.86372,
      lng: -121.14599,
      city: 'Wahkiacus',
      state: 'WA',
    },
    98671: {
      lat: 45.61143,
      lng: -122.24955,
      city: 'Washougal',
      state: 'WA',
    },
    98672: {
      lat: 45.81269,
      lng: -121.47393,
      city: 'White Salmon',
      state: 'WA',
    },
    98673: {
      lat: 45.67367,
      lng: -120.98129,
      city: 'Wishram',
      state: 'WA',
    },
    98674: {
      lat: 45.94672,
      lng: -122.65568,
      city: 'Woodland',
      state: 'WA',
    },
    98675: {
      lat: 45.86349,
      lng: -122.33104,
      city: 'Yacolt',
      state: 'WA',
    },
    98682: {
      lat: 45.67296,
      lng: -122.48327,
      city: 'Vancouver',
      state: 'WA',
    },
    98683: {
      lat: 45.6027,
      lng: -122.50984,
      city: 'Vancouver',
      state: 'WA',
    },
    98684: {
      lat: 45.6308,
      lng: -122.51615,
      city: 'Vancouver',
      state: 'WA',
    },
    98685: {
      lat: 45.71596,
      lng: -122.69105,
      city: 'Vancouver',
      state: 'WA',
    },
    98686: {
      lat: 45.7239,
      lng: -122.62305,
      city: 'Vancouver',
      state: 'WA',
    },
    98801: {
      lat: 47.43183,
      lng: -120.34356,
      city: 'Wenatchee',
      state: 'WA',
    },
    98802: {
      lat: 47.49308,
      lng: -120.16493,
      city: 'East Wenatchee',
      state: 'WA',
    },
    98811: {
      lat: 47.73425,
      lng: -120.37379,
      city: 'Ardenvoir',
      state: 'WA',
    },
    98812: {
      lat: 48.13119,
      lng: -119.80687,
      city: 'Brewster',
      state: 'WA',
    },
    98813: {
      lat: 48.01107,
      lng: -119.61063,
      city: 'Bridgeport',
      state: 'WA',
    },
    98814: {
      lat: 48.21001,
      lng: -120.22884,
      city: 'Carlton',
      state: 'WA',
    },
    98815: {
      lat: 47.52893,
      lng: -120.48459,
      city: 'Cashmere',
      state: 'WA',
    },
    98816: {
      lat: 48.0229,
      lng: -120.34361,
      city: 'Chelan',
      state: 'WA',
    },
    98817: {
      lat: 47.79049,
      lng: -119.99588,
      city: 'Chelan Falls',
      state: 'WA',
    },
    98819: {
      lat: 48.59797,
      lng: -119.82327,
      city: 'Conconully',
      state: 'WA',
    },
    98821: {
      lat: 47.55199,
      lng: -120.56155,
      city: 'Dryden',
      state: 'WA',
    },
    98822: {
      lat: 47.86509,
      lng: -120.47694,
      city: 'Entiat',
      state: 'WA',
    },
    98823: {
      lat: 47.33853,
      lng: -119.63536,
      city: 'Ephrata',
      state: 'WA',
    },
    98824: {
      lat: 47.06857,
      lng: -119.86653,
      city: 'George',
      state: 'WA',
    },
    98826: {
      lat: 47.78772,
      lng: -120.85454,
      city: 'Leavenworth',
      state: 'WA',
    },
    98827: {
      lat: 48.86359,
      lng: -119.76726,
      city: 'Loomis',
      state: 'WA',
    },
    98828: {
      lat: 47.30741,
      lng: -120.1833,
      city: 'Malaga',
      state: 'WA',
    },
    98829: {
      lat: 48.31103,
      lng: -119.80822,
      city: 'Malott',
      state: 'WA',
    },
    98830: {
      lat: 47.88702,
      lng: -119.52278,
      city: 'Mansfield',
      state: 'WA',
    },
    98831: {
      lat: 47.93451,
      lng: -120.12998,
      city: 'Manson',
      state: 'WA',
    },
    98832: {
      lat: 47.34669,
      lng: -119.02033,
      city: 'Marlin',
      state: 'WA',
    },
    98833: {
      lat: 48.77199,
      lng: -120.49447,
      city: 'Mazama',
      state: 'WA',
    },
    98834: {
      lat: 48.11712,
      lng: -120.08935,
      city: 'Methow',
      state: 'WA',
    },
    98836: {
      lat: 47.47615,
      lng: -120.42909,
      city: 'Monitor',
      state: 'WA',
    },
    98837: {
      lat: 47.14809,
      lng: -119.3163,
      city: 'Moses Lake',
      state: 'WA',
    },
    98840: {
      lat: 48.27395,
      lng: -119.51838,
      city: 'Okanogan',
      state: 'WA',
    },
    98841: {
      lat: 48.37602,
      lng: -119.29817,
      city: 'Omak',
      state: 'WA',
    },
    98843: {
      lat: 47.73688,
      lng: -120.08618,
      city: 'Orondo',
      state: 'WA',
    },
    98844: {
      lat: 48.91618,
      lng: -119.24767,
      city: 'Oroville',
      state: 'WA',
    },
    98845: {
      lat: 47.43039,
      lng: -119.90923,
      city: 'Palisades',
      state: 'WA',
    },
    98846: {
      lat: 48.03481,
      lng: -119.97528,
      city: 'Pateros',
      state: 'WA',
    },
    98847: {
      lat: 47.42236,
      lng: -120.62152,
      city: 'Peshastin',
      state: 'WA',
    },
    98848: {
      lat: 47.17182,
      lng: -119.84109,
      city: 'Quincy',
      state: 'WA',
    },
    98849: {
      lat: 48.54821,
      lng: -119.40789,
      city: 'Riverside',
      state: 'WA',
    },
    98850: {
      lat: 47.40048,
      lng: -120.03459,
      city: 'Rock Island',
      state: 'WA',
    },
    98851: {
      lat: 47.42002,
      lng: -119.43462,
      city: 'Soap Lake',
      state: 'WA',
    },
    98852: {
      lat: 48.32169,
      lng: -120.63881,
      city: 'Stehekin',
      state: 'WA',
    },
    98853: {
      lat: 47.44655,
      lng: -119.28061,
      city: 'Stratford',
      state: 'WA',
    },
    98855: {
      lat: 48.67641,
      lng: -119.25634,
      city: 'Tonasket',
      state: 'WA',
    },
    98856: {
      lat: 48.37065,
      lng: -120.22762,
      city: 'Twisp',
      state: 'WA',
    },
    98857: {
      lat: 46.98656,
      lng: -119.03698,
      city: 'Warden',
      state: 'WA',
    },
    98858: {
      lat: 47.67938,
      lng: -119.87974,
      city: 'Waterville',
      state: 'WA',
    },
    98859: {
      lat: 48.81181,
      lng: -118.90871,
      city: 'Wauconda',
      state: 'WA',
    },
    98860: {
      lat: 47.46899,
      lng: -119.12391,
      city: 'Wilson Creek',
      state: 'WA',
    },
    98862: {
      lat: 48.58491,
      lng: -120.22792,
      city: 'Winthrop',
      state: 'WA',
    },
    98901: {
      lat: 46.66935,
      lng: -120.33816,
      city: 'Yakima',
      state: 'WA',
    },
    98902: {
      lat: 46.59778,
      lng: -120.53393,
      city: 'Yakima',
      state: 'WA',
    },
    98903: {
      lat: 46.54807,
      lng: -120.90226,
      city: 'Yakima',
      state: 'WA',
    },
    98908: {
      lat: 46.62345,
      lng: -120.70582,
      city: 'Yakima',
      state: 'WA',
    },
    98921: {
      lat: 46.43082,
      lng: -120.31719,
      city: 'Buena',
      state: 'WA',
    },
    98922: {
      lat: 47.25056,
      lng: -120.8821,
      city: 'Cle Elum',
      state: 'WA',
    },
    98923: {
      lat: 46.66608,
      lng: -120.71723,
      city: 'Cowiche',
      state: 'WA',
    },
    98925: {
      lat: 47.28793,
      lng: -121.28089,
      city: 'Easton',
      state: 'WA',
    },
    98926: {
      lat: 46.99054,
      lng: -120.34902,
      city: 'Ellensburg',
      state: 'WA',
    },
    98930: {
      lat: 46.26903,
      lng: -119.8953,
      city: 'Grandview',
      state: 'WA',
    },
    98932: {
      lat: 46.33217,
      lng: -120.16091,
      city: 'Granger',
      state: 'WA',
    },
    98933: {
      lat: 46.4029,
      lng: -120.63756,
      city: 'Harrah',
      state: 'WA',
    },
    98934: {
      lat: 46.98067,
      lng: -120.41941,
      city: 'Kittitas',
      state: 'WA',
    },
    98935: {
      lat: 46.13417,
      lng: -120.06877,
      city: 'Mabton',
      state: 'WA',
    },
    98936: {
      lat: 46.53597,
      lng: -120.15391,
      city: 'Moxee',
      state: 'WA',
    },
    98937: {
      lat: 46.77523,
      lng: -121.17808,
      city: 'Naches',
      state: 'WA',
    },
    98938: {
      lat: 46.38842,
      lng: -120.09662,
      city: 'Outlook',
      state: 'WA',
    },
    98939: {
      lat: 46.50105,
      lng: -120.47077,
      city: 'Parker',
      state: 'WA',
    },
    98940: {
      lat: 47.39682,
      lng: -121.06683,
      city: 'Ronald',
      state: 'WA',
    },
    98941: {
      lat: 47.22464,
      lng: -121.00021,
      city: 'Roslyn',
      state: 'WA',
    },
    98942: {
      lat: 46.80322,
      lng: -120.67454,
      city: 'Selah',
      state: 'WA',
    },
    98943: {
      lat: 47.18461,
      lng: -120.96089,
      city: 'South Cle Elum',
      state: 'WA',
    },
    98944: {
      lat: 46.49998,
      lng: -119.66533,
      city: 'Sunnyside',
      state: 'WA',
    },
    98946: {
      lat: 47.06515,
      lng: -120.76512,
      city: 'Thorp',
      state: 'WA',
    },
    98947: {
      lat: 46.68759,
      lng: -120.80916,
      city: 'Tieton',
      state: 'WA',
    },
    98948: {
      lat: 46.23067,
      lng: -120.37168,
      city: 'Toppenish',
      state: 'WA',
    },
    98950: {
      lat: 46.84642,
      lng: -119.9877,
      city: 'Vantage',
      state: 'WA',
    },
    98951: {
      lat: 46.46046,
      lng: -120.51551,
      city: 'Wapato',
      state: 'WA',
    },
    98952: {
      lat: 46.34991,
      lng: -120.81561,
      city: 'White Swan',
      state: 'WA',
    },
    98953: {
      lat: 46.44149,
      lng: -120.23284,
      city: 'Zillah',
      state: 'WA',
    },
    99001: {
      lat: 47.63874,
      lng: -117.58582,
      city: 'Airway Heights',
      state: 'WA',
    },
    99003: {
      lat: 47.91997,
      lng: -117.27608,
      city: 'Chattaroy',
      state: 'WA',
    },
    99004: {
      lat: 47.40869,
      lng: -117.63575,
      city: 'Cheney',
      state: 'WA',
    },
    99005: {
      lat: 47.84405,
      lng: -117.36346,
      city: 'Colbert',
      state: 'WA',
    },
    99006: {
      lat: 47.95208,
      lng: -117.51016,
      city: 'Deer Park',
      state: 'WA',
    },
    99008: {
      lat: 47.50449,
      lng: -117.93533,
      city: 'Edwall',
      state: 'WA',
    },
    99009: { lat: 48.0253, lng: -117.24993, city: 'Elk', state: 'WA' },
    99011: {
      lat: 47.61867,
      lng: -117.64839,
      city: 'Fairchild Air Force Base',
      state: 'WA',
    },
    99012: {
      lat: 47.38137,
      lng: -117.19145,
      city: 'Fairfield',
      state: 'WA',
    },
    99013: {
      lat: 47.88498,
      lng: -117.83495,
      city: 'Ford',
      state: 'WA',
    },
    99014: {
      lat: 47.56111,
      lng: -117.59666,
      city: 'Four Lakes',
      state: 'WA',
    },
    99016: {
      lat: 47.6217,
      lng: -117.14623,
      city: 'Greenacres',
      state: 'WA',
    },
    99017: {
      lat: 47.1829,
      lng: -117.87611,
      city: 'Lamont',
      state: 'WA',
    },
    99018: {
      lat: 47.2917,
      lng: -117.15212,
      city: 'Latah',
      state: 'WA',
    },
    99019: {
      lat: 47.64222,
      lng: -117.07688,
      city: 'Liberty Lake',
      state: 'WA',
    },
    99020: {
      lat: 47.56314,
      lng: -117.49702,
      city: 'Marshall',
      state: 'WA',
    },
    99021: {
      lat: 47.84951,
      lng: -117.18721,
      city: 'Mead',
      state: 'WA',
    },
    99022: {
      lat: 47.60084,
      lng: -117.70869,
      city: 'Medical Lake',
      state: 'WA',
    },
    99023: {
      lat: 47.56474,
      lng: -117.14466,
      city: 'Mica',
      state: 'WA',
    },
    99025: {
      lat: 47.78963,
      lng: -117.08751,
      city: 'Newman Lake',
      state: 'WA',
    },
    99026: {
      lat: 47.80375,
      lng: -117.625,
      city: 'Nine Mile Falls',
      state: 'WA',
    },
    99027: {
      lat: 47.71107,
      lng: -117.12795,
      city: 'Otis Orchards',
      state: 'WA',
    },
    99029: {
      lat: 47.70639,
      lng: -117.82406,
      city: 'Reardan',
      state: 'WA',
    },
    99030: {
      lat: 47.47782,
      lng: -117.11302,
      city: 'Rockford',
      state: 'WA',
    },
    99031: {
      lat: 47.41973,
      lng: -117.35991,
      city: 'Spangle',
      state: 'WA',
    },
    99032: {
      lat: 47.32405,
      lng: -118.04613,
      city: 'Sprague',
      state: 'WA',
    },
    99033: {
      lat: 47.23061,
      lng: -117.09782,
      city: 'Tekoa',
      state: 'WA',
    },
    99034: {
      lat: 47.8862,
      lng: -117.76245,
      city: 'Tumtum',
      state: 'WA',
    },
    99036: {
      lat: 47.52005,
      lng: -117.27197,
      city: 'Valleyford',
      state: 'WA',
    },
    99037: {
      lat: 47.63734,
      lng: -117.19756,
      city: 'Veradale',
      state: 'WA',
    },
    99039: {
      lat: 47.33808,
      lng: -117.23131,
      city: 'Waverly',
      state: 'WA',
    },
    99040: {
      lat: 47.90355,
      lng: -117.99885,
      city: 'Wellpinit',
      state: 'WA',
    },
    99101: { lat: 48.31889, lng: -117.9483, city: 'Addy', state: 'WA' },
    99102: {
      lat: 46.79218,
      lng: -117.25029,
      city: 'Albion',
      state: 'WA',
    },
    99103: {
      lat: 47.73041,
      lng: -118.93171,
      city: 'Almira',
      state: 'WA',
    },
    99105: {
      lat: 46.9213,
      lng: -118.08264,
      city: 'Benge',
      state: 'WA',
    },
    99109: {
      lat: 48.29787,
      lng: -117.69852,
      city: 'Chewelah',
      state: 'WA',
    },
    99110: {
      lat: 48.0083,
      lng: -117.58112,
      city: 'Clayton',
      state: 'WA',
    },
    99111: {
      lat: 46.86281,
      lng: -117.44132,
      city: 'Colfax',
      state: 'WA',
    },
    99113: {
      lat: 46.58628,
      lng: -117.1935,
      city: 'Colton',
      state: 'WA',
    },
    99114: {
      lat: 48.66067,
      lng: -117.74333,
      city: 'Colville',
      state: 'WA',
    },
    99115: {
      lat: 47.67326,
      lng: -119.38965,
      city: 'Coulee City',
      state: 'WA',
    },
    99116: {
      lat: 48.01429,
      lng: -118.93063,
      city: 'Coulee Dam',
      state: 'WA',
    },
    99117: {
      lat: 47.72818,
      lng: -118.50108,
      city: 'Creston',
      state: 'WA',
    },
    99118: {
      lat: 48.89746,
      lng: -118.63797,
      city: 'Curlew',
      state: 'WA',
    },
    99119: {
      lat: 48.50946,
      lng: -117.29994,
      city: 'Cusick',
      state: 'WA',
    },
    99121: {
      lat: 48.94382,
      lng: -118.45568,
      city: 'Danville',
      state: 'WA',
    },
    99122: {
      lat: 47.67615,
      lng: -118.21774,
      city: 'Davenport',
      state: 'WA',
    },
    99123: {
      lat: 47.89738,
      lng: -119.04017,
      city: 'Electric City',
      state: 'WA',
    },
    99124: {
      lat: 48.00284,
      lng: -118.95243,
      city: 'Elmer City',
      state: 'WA',
    },
    99125: {
      lat: 46.95223,
      lng: -117.78556,
      city: 'Endicott',
      state: 'WA',
    },
    99126: {
      lat: 48.73574,
      lng: -117.97447,
      city: 'Evans',
      state: 'WA',
    },
    99128: {
      lat: 47.10045,
      lng: -117.08523,
      city: 'Farmington',
      state: 'WA',
    },
    99129: {
      lat: 48.00281,
      lng: -118.18342,
      city: 'Fruitland',
      state: 'WA',
    },
    99130: {
      lat: 46.99917,
      lng: -117.18556,
      city: 'Garfield',
      state: 'WA',
    },
    99131: {
      lat: 48.29511,
      lng: -118.11731,
      city: 'Gifford',
      state: 'WA',
    },
    99133: {
      lat: 48.0067,
      lng: -119.10613,
      city: 'Grand Coulee',
      state: 'WA',
    },
    99134: {
      lat: 47.43793,
      lng: -118.32347,
      city: 'Harrington',
      state: 'WA',
    },
    99135: {
      lat: 47.65658,
      lng: -119.09608,
      city: 'Hartline',
      state: 'WA',
    },
    99136: { lat: 46.6747, lng: -117.94889, city: 'Hay', state: 'WA' },
    99137: {
      lat: 48.1659,
      lng: -118.09992,
      city: 'Hunters',
      state: 'WA',
    },
    99138: {
      lat: 48.23093,
      lng: -118.38876,
      city: 'Inchelium',
      state: 'WA',
    },
    99139: {
      lat: 48.73161,
      lng: -117.31371,
      city: 'Ione',
      state: 'WA',
    },
    99140: {
      lat: 48.135,
      lng: -118.68722,
      city: 'Keller',
      state: 'WA',
    },
    99141: {
      lat: 48.72571,
      lng: -118.17509,
      city: 'Kettle Falls',
      state: 'WA',
    },
    99143: {
      lat: 46.74627,
      lng: -117.85869,
      city: 'Lacrosse',
      state: 'WA',
    },
    99144: {
      lat: 47.3681,
      lng: -118.49041,
      city: 'Lamona',
      state: 'WA',
    },
    99146: {
      lat: 48.95766,
      lng: -118.29768,
      city: 'Laurier',
      state: 'WA',
    },
    99147: {
      lat: 47.83604,
      lng: -118.43675,
      city: 'Lincoln',
      state: 'WA',
    },
    99148: {
      lat: 48.10874,
      lng: -117.57514,
      city: 'Loon Lake',
      state: 'WA',
    },
    99149: {
      lat: 47.225,
      lng: -117.46794,
      city: 'Malden',
      state: 'WA',
    },
    99150: {
      lat: 48.80622,
      lng: -118.61071,
      city: 'Malo',
      state: 'WA',
    },
    99151: {
      lat: 48.66443,
      lng: -118.06125,
      city: 'Marcus',
      state: 'WA',
    },
    99152: {
      lat: 48.85066,
      lng: -117.39748,
      city: 'Metaline',
      state: 'WA',
    },
    99153: {
      lat: 48.90951,
      lng: -117.25229,
      city: 'Metaline Falls',
      state: 'WA',
    },
    99154: {
      lat: 47.39132,
      lng: -118.35576,
      city: 'Mohler',
      state: 'WA',
    },
    99155: {
      lat: 48.27737,
      lng: -118.9618,
      city: 'Nespelem',
      state: 'WA',
    },
    99156: {
      lat: 48.16211,
      lng: -117.18826,
      city: 'Newport',
      state: 'WA',
    },
    99157: {
      lat: 48.93007,
      lng: -117.81753,
      city: 'Northport',
      state: 'WA',
    },
    99158: {
      lat: 47.13305,
      lng: -117.24008,
      city: 'Oakesdale',
      state: 'WA',
    },
    99159: {
      lat: 47.32872,
      lng: -118.74338,
      city: 'Odessa',
      state: 'WA',
    },
    99160: {
      lat: 48.86895,
      lng: -118.30122,
      city: 'Orient',
      state: 'WA',
    },
    99161: {
      lat: 46.89496,
      lng: -117.13169,
      city: 'Palouse',
      state: 'WA',
    },
    99163: {
      lat: 46.72798,
      lng: -117.21654,
      city: 'Pullman',
      state: 'WA',
    },
    99166: {
      lat: 48.61033,
      lng: -118.6715,
      city: 'Republic',
      state: 'WA',
    },
    99167: {
      lat: 48.41307,
      lng: -118.11733,
      city: 'Rice',
      state: 'WA',
    },
    99169: {
      lat: 47.11875,
      lng: -118.37424,
      city: 'Ritzville',
      state: 'WA',
    },
    99170: {
      lat: 47.25132,
      lng: -117.40203,
      city: 'Rosalia',
      state: 'WA',
    },
    99171: {
      lat: 47.10485,
      lng: -117.6495,
      city: 'Saint John',
      state: 'WA',
    },
    99173: {
      lat: 48.03702,
      lng: -117.85708,
      city: 'Springdale',
      state: 'WA',
    },
    99174: {
      lat: 47.00719,
      lng: -117.35682,
      city: 'Steptoe',
      state: 'WA',
    },
    99176: {
      lat: 47.09221,
      lng: -117.41224,
      city: 'Thornton',
      state: 'WA',
    },
    99179: {
      lat: 46.49239,
      lng: -117.10762,
      city: 'Uniontown',
      state: 'WA',
    },
    99180: { lat: 48.30925, lng: -117.30663, city: 'Usk', state: 'WA' },
    99181: {
      lat: 48.1456,
      lng: -117.76526,
      city: 'Valley',
      state: 'WA',
    },
    99185: {
      lat: 47.74014,
      lng: -118.71697,
      city: 'Wilbur',
      state: 'WA',
    },
    99201: {
      lat: 47.66271,
      lng: -117.43609,
      city: 'Spokane',
      state: 'WA',
    },
    99202: {
      lat: 47.65709,
      lng: -117.37854,
      city: 'Spokane',
      state: 'WA',
    },
    99203: {
      lat: 47.62945,
      lng: -117.40291,
      city: 'Spokane',
      state: 'WA',
    },
    99204: {
      lat: 47.64726,
      lng: -117.42665,
      city: 'Spokane',
      state: 'WA',
    },
    99205: {
      lat: 47.69657,
      lng: -117.44609,
      city: 'Spokane',
      state: 'WA',
    },
    99206: {
      lat: 47.62755,
      lng: -117.25448,
      city: 'Spokane',
      state: 'WA',
    },
    99207: {
      lat: 47.68941,
      lng: -117.38911,
      city: 'Spokane',
      state: 'WA',
    },
    99208: {
      lat: 47.78071,
      lng: -117.46135,
      city: 'Spokane',
      state: 'WA',
    },
    99212: {
      lat: 47.66504,
      lng: -117.30914,
      city: 'Spokane',
      state: 'WA',
    },
    99216: {
      lat: 47.68142,
      lng: -117.20826,
      city: 'Spokane',
      state: 'WA',
    },
    99217: {
      lat: 47.7406,
      lng: -117.26241,
      city: 'Spokane',
      state: 'WA',
    },
    99218: {
      lat: 47.75894,
      lng: -117.4087,
      city: 'Spokane',
      state: 'WA',
    },
    99223: {
      lat: 47.58876,
      lng: -117.334,
      city: 'Spokane',
      state: 'WA',
    },
    99224: {
      lat: 47.64729,
      lng: -117.53552,
      city: 'Spokane',
      state: 'WA',
    },
    99251: {
      lat: 47.75385,
      lng: -117.41797,
      city: 'Spokane',
      state: 'WA',
    },
    99258: {
      lat: 47.66612,
      lng: -117.40246,
      city: 'Spokane',
      state: 'WA',
    },
    99301: {
      lat: 46.39184,
      lng: -118.93509,
      city: 'Pasco',
      state: 'WA',
    },
    99320: {
      lat: 46.32706,
      lng: -119.52483,
      city: 'Benton City',
      state: 'WA',
    },
    99321: {
      lat: 46.86576,
      lng: -119.88925,
      city: 'Beverly',
      state: 'WA',
    },
    99322: {
      lat: 45.93176,
      lng: -120.14083,
      city: 'Bickleton',
      state: 'WA',
    },
    99323: {
      lat: 46.18677,
      lng: -118.85061,
      city: 'Burbank',
      state: 'WA',
    },
    99324: {
      lat: 46.04376,
      lng: -118.38542,
      city: 'College Place',
      state: 'WA',
    },
    99326: {
      lat: 46.66705,
      lng: -118.83769,
      city: 'Connell',
      state: 'WA',
    },
    99328: {
      lat: 46.26937,
      lng: -117.87956,
      city: 'Dayton',
      state: 'WA',
    },
    99329: {
      lat: 46.13943,
      lng: -118.14732,
      city: 'Dixie',
      state: 'WA',
    },
    99330: {
      lat: 46.49447,
      lng: -119.03021,
      city: 'Eltopia',
      state: 'WA',
    },
    99333: {
      lat: 46.68112,
      lng: -118.15262,
      city: 'Hooper',
      state: 'WA',
    },
    99335: {
      lat: 46.65544,
      lng: -118.47686,
      city: 'Kahlotus',
      state: 'WA',
    },
    99336: {
      lat: 46.21302,
      lng: -119.17954,
      city: 'Kennewick',
      state: 'WA',
    },
    99337: {
      lat: 46.06235,
      lng: -119.09127,
      city: 'Kennewick',
      state: 'WA',
    },
    99338: {
      lat: 46.17362,
      lng: -119.27403,
      city: 'Kennewick',
      state: 'WA',
    },
    99341: {
      lat: 46.90663,
      lng: -118.64224,
      city: 'Lind',
      state: 'WA',
    },
    99343: {
      lat: 46.59188,
      lng: -119.17065,
      city: 'Mesa',
      state: 'WA',
    },
    99344: {
      lat: 46.82223,
      lng: -119.17221,
      city: 'Othello',
      state: 'WA',
    },
    99345: {
      lat: 45.93623,
      lng: -119.65919,
      city: 'Paterson',
      state: 'WA',
    },
    99346: {
      lat: 46.02685,
      lng: -119.306,
      city: 'Plymouth',
      state: 'WA',
    },
    99347: {
      lat: 46.42531,
      lng: -117.57128,
      city: 'Pomeroy',
      state: 'WA',
    },
    99348: {
      lat: 46.38681,
      lng: -118.44731,
      city: 'Prescott',
      state: 'WA',
    },
    99349: {
      lat: 46.73078,
      lng: -119.6996,
      city: 'Mattawa',
      state: 'WA',
    },
    99350: {
      lat: 46.14477,
      lng: -119.70944,
      city: 'Prosser',
      state: 'WA',
    },
    99352: {
      lat: 46.24199,
      lng: -119.29067,
      city: 'Richland',
      state: 'WA',
    },
    99353: {
      lat: 46.31747,
      lng: -119.37942,
      city: 'West Richland',
      state: 'WA',
    },
    99354: {
      lat: 46.33153,
      lng: -119.30106,
      city: 'Richland',
      state: 'WA',
    },
    99356: {
      lat: 45.83459,
      lng: -120.36258,
      city: 'Roosevelt',
      state: 'WA',
    },
    99357: {
      lat: 46.89295,
      lng: -119.67902,
      city: 'Royal City',
      state: 'WA',
    },
    99359: {
      lat: 46.53325,
      lng: -118.15697,
      city: 'Starbuck',
      state: 'WA',
    },
    99360: {
      lat: 46.09044,
      lng: -118.66745,
      city: 'Touchet',
      state: 'WA',
    },
    99361: {
      lat: 46.23691,
      lng: -118.14333,
      city: 'Waitsburg',
      state: 'WA',
    },
    99362: {
      lat: 46.08727,
      lng: -118.31269,
      city: 'Walla Walla',
      state: 'WA',
    },
    99363: {
      lat: 46.04821,
      lng: -118.89575,
      city: 'Wallula',
      state: 'WA',
    },
    99371: {
      lat: 46.79144,
      lng: -118.30614,
      city: 'Washtucna',
      state: 'WA',
    },
    99401: {
      lat: 46.09041,
      lng: -117.27336,
      city: 'Anatone',
      state: 'WA',
    },
    99402: {
      lat: 46.1868,
      lng: -117.14922,
      city: 'Asotin',
      state: 'WA',
    },
    99403: {
      lat: 46.38114,
      lng: -117.25974,
      city: 'Clarkston',
      state: 'WA',
    },
    99501: {
      lat: 61.22005,
      lng: -149.85872,
      city: 'Anchorage',
      state: 'AK',
    },
    99502: {
      lat: 61.16323,
      lng: -149.98644,
      city: 'Anchorage',
      state: 'AK',
    },
    99503: {
      lat: 61.90744,
      lng: -156.85203,
      city: 'Anchorage',
      state: 'AK',
    },
    99504: {
      lat: 61.19195,
      lng: -149.64255,
      city: 'Anchorage',
      state: 'AK',
    },
    99505: {
      lat: 61.27512,
      lng: -149.65197,
      city: 'Jber',
      state: 'AK',
    },
    99506: {
      lat: 61.26505,
      lng: -149.80067,
      city: 'Jber',
      state: 'AK',
    },
    99507: {
      lat: 61.13336,
      lng: -149.69001,
      city: 'Anchorage',
      state: 'AK',
    },
    99508: {
      lat: 61.20145,
      lng: -149.8146,
      city: 'Anchorage',
      state: 'AK',
    },
    99513: {
      lat: 61.21477,
      lng: -149.88566,
      city: 'Anchorage',
      state: 'AK',
    },
    99515: {
      lat: 61.11733,
      lng: -149.88894,
      city: 'Anchorage',
      state: 'AK',
    },
    99516: {
      lat: 61.0726,
      lng: -149.71812,
      city: 'Anchorage',
      state: 'AK',
    },
    99517: {
      lat: 61.19018,
      lng: -149.93676,
      city: 'Anchorage',
      state: 'AK',
    },
    99518: {
      lat: 61.15949,
      lng: -149.88479,
      city: 'Anchorage',
      state: 'AK',
    },
    99519: {
      lat: 70.1006,
      lng: -150.00014,
      city: 'Anchorage',
      state: 'AK',
    },
    99530: {
      lat: 61.18465,
      lng: -149.98356,
      city: 'Anchorage',
      state: 'AK',
    },
    99540: {
      lat: 61.01464,
      lng: -149.36859,
      city: 'Indian',
      state: 'AK',
    },
    99546: {
      lat: 51.88145,
      lng: -176.62962,
      city: 'Adak',
      state: 'AK',
    },
    99547: {
      lat: 52.22888,
      lng: -174.20613,
      city: 'Atka',
      state: 'AK',
    },
    99548: {
      lat: 56.25468,
      lng: -158.76004,
      city: 'Chignik Lake',
      state: 'AK',
    },
    99549: {
      lat: 56.94253,
      lng: -158.60474,
      city: 'Port Heiden',
      state: 'AK',
    },
    99550: {
      lat: 57.8758,
      lng: -152.8737,
      city: 'Port Lions',
      state: 'AK',
    },
    99551: {
      lat: 60.92101,
      lng: -161.40212,
      city: 'Akiachak',
      state: 'AK',
    },
    99552: {
      lat: 60.90614,
      lng: -161.21223,
      city: 'Akiak',
      state: 'AK',
    },
    99553: {
      lat: 54.1475,
      lng: -165.83687,
      city: 'Akutan',
      state: 'AK',
    },
    99554: {
      lat: 62.69018,
      lng: -164.63054,
      city: 'Alakanuk',
      state: 'AK',
    },
    99555: {
      lat: 59.28859,
      lng: -158.67996,
      city: 'Aleknagik',
      state: 'AK',
    },
    99556: {
      lat: 59.82955,
      lng: -151.64298,
      city: 'Anchor Point',
      state: 'AK',
    },
    99557: {
      lat: 61.44142,
      lng: -155.90217,
      city: 'Aniak',
      state: 'AK',
    },
    99558: {
      lat: 62.65411,
      lng: -160.21396,
      city: 'Anvik',
      state: 'AK',
    },
    99559: {
      lat: 60.76487,
      lng: -161.90671,
      city: 'Bethel',
      state: 'AK',
    },
    99561: {
      lat: 60.15504,
      lng: -164.25495,
      city: 'Chefornak',
      state: 'AK',
    },
    99563: {
      lat: 61.55009,
      lng: -165.35813,
      city: 'Chevak',
      state: 'AK',
    },
    99564: {
      lat: 56.30032,
      lng: -158.41315,
      city: 'Chignik',
      state: 'AK',
    },
    99565: {
      lat: 56.30415,
      lng: -158.50039,
      city: 'Chignik Lagoon',
      state: 'AK',
    },
    99566: {
      lat: 61.28176,
      lng: -142.79338,
      city: 'Chitina',
      state: 'AK',
    },
    99567: {
      lat: 61.26625,
      lng: -148.86933,
      city: 'Chugiak',
      state: 'AK',
    },
    99568: {
      lat: 60.24952,
      lng: -150.38501,
      city: 'Clam Gulch',
      state: 'AK',
    },
    99569: {
      lat: 58.81714,
      lng: -158.52914,
      city: 'Clarks Point',
      state: 'AK',
    },
    99571: {
      lat: 55.29661,
      lng: -162.58634,
      city: 'Cold Bay',
      state: 'AK',
    },
    99572: {
      lat: 60.49348,
      lng: -150.0567,
      city: 'Cooper Landing',
      state: 'AK',
    },
    99573: {
      lat: 61.60036,
      lng: -145.92446,
      city: 'Copper Center',
      state: 'AK',
    },
    99574: {
      lat: 60.73927,
      lng: -146.4061,
      city: 'Cordova',
      state: 'AK',
    },
    99575: {
      lat: 61.84391,
      lng: -158.10825,
      city: 'Crooked Creek',
      state: 'AK',
    },
    99576: {
      lat: 59.77339,
      lng: -158.69309,
      city: 'Dillingham',
      state: 'AK',
    },
    99577: {
      lat: 61.1824,
      lng: -149.29229,
      city: 'Eagle River',
      state: 'AK',
    },
    99578: { lat: 60.21644, lng: -162.03311, city: 'Eek', state: 'AK' },
    99579: {
      lat: 58.21813,
      lng: -157.40364,
      city: 'Egegik',
      state: 'AK',
    },
    99580: {
      lat: 59.34619,
      lng: -157.48607,
      city: 'Ekwok',
      state: 'AK',
    },
    99581: {
      lat: 62.82476,
      lng: -164.68692,
      city: 'Emmonak',
      state: 'AK',
    },
    99583: {
      lat: 54.84305,
      lng: -163.39945,
      city: 'False Pass',
      state: 'AK',
    },
    99585: {
      lat: 61.87532,
      lng: -162.04873,
      city: 'Marshall',
      state: 'AK',
    },
    99586: {
      lat: 62.67463,
      lng: -144.13884,
      city: 'Gakona',
      state: 'AK',
    },
    99587: {
      lat: 60.9103,
      lng: -148.84466,
      city: 'Girdwood',
      state: 'AK',
    },
    99588: {
      lat: 62.42192,
      lng: -146.4813,
      city: 'Glennallen',
      state: 'AK',
    },
    99589: {
      lat: 59.21186,
      lng: -161.70123,
      city: 'Goodnews Bay',
      state: 'AK',
    },
    99590: {
      lat: 62.89978,
      lng: -160.10782,
      city: 'Grayling',
      state: 'AK',
    },
    99591: {
      lat: 56.58075,
      lng: -169.61599,
      city: 'Saint George Island',
      state: 'AK',
    },
    99602: {
      lat: 62.1868,
      lng: -159.8198,
      city: 'Holy Cross',
      state: 'AK',
    },
    99603: {
      lat: 59.52364,
      lng: -151.22855,
      city: 'Homer',
      state: 'AK',
    },
    99604: {
      lat: 61.52613,
      lng: -166.11458,
      city: 'Hooper Bay',
      state: 'AK',
    },
    99605: {
      lat: 60.83286,
      lng: -149.44797,
      city: 'Hope',
      state: 'AK',
    },
    99606: {
      lat: 59.66803,
      lng: -154.83362,
      city: 'Iliamna',
      state: 'AK',
    },
    99607: {
      lat: 61.5416,
      lng: -160.3337,
      city: 'Kalskag',
      state: 'AK',
    },
    99608: {
      lat: 57.58454,
      lng: -154.32545,
      city: 'Karluk',
      state: 'AK',
    },
    99609: {
      lat: 60.87848,
      lng: -162.52725,
      city: 'Kasigluk',
      state: 'AK',
    },
    99610: {
      lat: 60.29172,
      lng: -151.27167,
      city: 'Kasilof',
      state: 'AK',
    },
    99611: { lat: 60.74777, lng: -150.89, city: 'Kenai', state: 'AK' },
    99612: {
      lat: 55.08587,
      lng: -162.2978,
      city: 'King Cove',
      state: 'AK',
    },
    99613: {
      lat: 59.13496,
      lng: -155.42696,
      city: 'King Salmon',
      state: 'AK',
    },
    99614: {
      lat: 59.9373,
      lng: -164.06158,
      city: 'Kipnuk',
      state: 'AK',
    },
    99615: {
      lat: 57.57705,
      lng: -153.39119,
      city: 'Kodiak',
      state: 'AK',
    },
    99620: {
      lat: 63.03047,
      lng: -163.553,
      city: 'Kotlik',
      state: 'AK',
    },
    99621: {
      lat: 60.79781,
      lng: -161.41469,
      city: 'Kwethluk',
      state: 'AK',
    },
    99622: {
      lat: 59.87667,
      lng: -163.17154,
      city: 'Kwigillingok',
      state: 'AK',
    },
    99623: {
      lat: 61.42919,
      lng: -149.91226,
      city: 'Wasilla',
      state: 'AK',
    },
    99624: {
      lat: 57.44866,
      lng: -153.85811,
      city: 'Larsen Bay',
      state: 'AK',
    },
    99625: {
      lat: 59.0683,
      lng: -156.90093,
      city: 'Levelock',
      state: 'AK',
    },
    99626: {
      lat: 61.51633,
      lng: -160.35564,
      city: 'Lower Kalskag',
      state: 'AK',
    },
    99627: {
      lat: 63.18657,
      lng: -154.75609,
      city: 'McGrath',
      state: 'AK',
    },
    99628: {
      lat: 58.9033,
      lng: -158.90867,
      city: 'Manokotak',
      state: 'AK',
    },
    99630: {
      lat: 60.37041,
      lng: -166.2672,
      city: 'Mekoryuk',
      state: 'AK',
    },
    99631: {
      lat: 60.60673,
      lng: -149.58071,
      city: 'Moose Pass',
      state: 'AK',
    },
    99632: {
      lat: 62.11232,
      lng: -163.66455,
      city: 'Mountain Village',
      state: 'AK',
    },
    99633: {
      lat: 58.78297,
      lng: -156.8984,
      city: 'Naknek',
      state: 'AK',
    },
    99634: {
      lat: 60.68682,
      lng: -162.00737,
      city: 'Napakiak',
      state: 'AK',
    },
    99635: {
      lat: 60.8062,
      lng: -152.67734,
      city: 'Nikiski',
      state: 'AK',
    },
    99636: {
      lat: 59.47618,
      lng: -157.27099,
      city: 'New Stuyahok',
      state: 'AK',
    },
    99637: {
      lat: 60.5333,
      lng: -165.1557,
      city: 'Toksook Bay',
      state: 'AK',
    },
    99638: {
      lat: 53.01329,
      lng: -168.75359,
      city: 'Nikolski',
      state: 'AK',
    },
    99639: {
      lat: 59.99602,
      lng: -151.36177,
      city: 'Ninilchik',
      state: 'AK',
    },
    99640: {
      lat: 60.29813,
      lng: -155.40518,
      city: 'Nondalton',
      state: 'AK',
    },
    99641: {
      lat: 60.87722,
      lng: -162.46152,
      city: 'Nunapitchuk',
      state: 'AK',
    },
    99643: {
      lat: 57.21669,
      lng: -153.32658,
      city: 'Old Harbor',
      state: 'AK',
    },
    99644: {
      lat: 57.92512,
      lng: -152.4231,
      city: 'Ouzinkie',
      state: 'AK',
    },
    99645: {
      lat: 61.60763,
      lng: -148.90394,
      city: 'Palmer',
      state: 'AK',
    },
    99647: {
      lat: 59.86411,
      lng: -154.01511,
      city: 'Pedro Bay',
      state: 'AK',
    },
    99648: {
      lat: 55.93137,
      lng: -159.15598,
      city: 'Perryville',
      state: 'AK',
    },
    99649: {
      lat: 57.51501,
      lng: -157.31076,
      city: 'Pilot Point',
      state: 'AK',
    },
    99650: {
      lat: 61.94255,
      lng: -162.88467,
      city: 'Pilot Station',
      state: 'AK',
    },
    99651: {
      lat: 58.97803,
      lng: -161.71853,
      city: 'Platinum',
      state: 'AK',
    },
    99652: {
      lat: 61.52621,
      lng: -149.9616,
      city: 'Big Lake',
      state: 'AK',
    },
    99653: {
      lat: 60.16884,
      lng: -154.32328,
      city: 'Port Alsworth',
      state: 'AK',
    },
    99654: {
      lat: 61.607,
      lng: -149.41443,
      city: 'Wasilla',
      state: 'AK',
    },
    99655: {
      lat: 59.74903,
      lng: -161.89437,
      city: 'Quinhagak',
      state: 'AK',
    },
    99656: {
      lat: 61.86309,
      lng: -157.41759,
      city: 'Red Devil',
      state: 'AK',
    },
    99657: {
      lat: 61.78916,
      lng: -161.42698,
      city: 'Russian Mission',
      state: 'AK',
    },
    99658: {
      lat: 62.0761,
      lng: -163.28111,
      city: 'Saint Marys',
      state: 'AK',
    },
    99659: {
      lat: 63.4738,
      lng: -162.12295,
      city: 'Saint Michael',
      state: 'AK',
    },
    99660: {
      lat: 57.17993,
      lng: -170.27914,
      city: 'Saint Paul Island',
      state: 'AK',
    },
    99661: {
      lat: 55.27131,
      lng: -160.67431,
      city: 'Sand Point',
      state: 'AK',
    },
    99662: {
      lat: 61.84197,
      lng: -165.58329,
      city: 'Scammon Bay',
      state: 'AK',
    },
    99663: {
      lat: 59.39488,
      lng: -151.62729,
      city: 'Seldovia',
      state: 'AK',
    },
    99664: {
      lat: 60.13714,
      lng: -149.47123,
      city: 'Seward',
      state: 'AK',
    },
    99665: {
      lat: 62.66843,
      lng: -159.56298,
      city: 'Shageluk',
      state: 'AK',
    },
    99666: {
      lat: 62.45498,
      lng: -165.09492,
      city: 'Nunam Iqua',
      state: 'AK',
    },
    99667: {
      lat: 61.85447,
      lng: -151.82056,
      city: 'Skwentna',
      state: 'AK',
    },
    99668: {
      lat: 61.65158,
      lng: -157.12508,
      city: 'Sleetmute',
      state: 'AK',
    },
    99669: {
      lat: 60.41497,
      lng: -151.00207,
      city: 'Soldotna',
      state: 'AK',
    },
    99670: {
      lat: 58.66541,
      lng: -156.98831,
      city: 'South Naknek',
      state: 'AK',
    },
    99671: {
      lat: 63.47048,
      lng: -162.25993,
      city: 'Stebbins',
      state: 'AK',
    },
    99672: {
      lat: 60.55026,
      lng: -150.77907,
      city: 'Sterling',
      state: 'AK',
    },
    99674: {
      lat: 61.69166,
      lng: -147.81997,
      city: 'Sutton',
      state: 'AK',
    },
    99675: {
      lat: 62.98268,
      lng: -156.08383,
      city: 'Takotna',
      state: 'AK',
    },
    99676: {
      lat: 62.49792,
      lng: -149.65848,
      city: 'Talkeetna',
      state: 'AK',
    },
    99677: {
      lat: 60.89217,
      lng: -146.67249,
      city: 'Tatitlek',
      state: 'AK',
    },
    99678: {
      lat: 59.20738,
      lng: -160.52622,
      city: 'Togiak',
      state: 'AK',
    },
    99679: {
      lat: 61.10509,
      lng: -160.94865,
      city: 'Tuluksak',
      state: 'AK',
    },
    99680: {
      lat: 60.34447,
      lng: -162.66367,
      city: 'Tuntutuliak',
      state: 'AK',
    },
    99681: {
      lat: 60.57902,
      lng: -165.20767,
      city: 'Tununak',
      state: 'AK',
    },
    99682: {
      lat: 61.17145,
      lng: -151.16797,
      city: 'Tyonek',
      state: 'AK',
    },
    99683: {
      lat: 62.45165,
      lng: -150.425,
      city: 'Trapper Creek',
      state: 'AK',
    },
    99684: {
      lat: 64.09938,
      lng: -160.32776,
      city: 'Unalakleet',
      state: 'AK',
    },
    99685: {
      lat: 53.87869,
      lng: -166.47125,
      city: 'Unalaska',
      state: 'AK',
    },
    99686: {
      lat: 61.04886,
      lng: -146.19038,
      city: 'Valdez',
      state: 'AK',
    },
    99688: {
      lat: 61.89412,
      lng: -150.04749,
      city: 'Willow',
      state: 'AK',
    },
    99689: {
      lat: 59.51819,
      lng: -139.4869,
      city: 'Yakutat',
      state: 'AK',
    },
    99690: {
      lat: 60.48738,
      lng: -164.81953,
      city: 'Nightmute',
      state: 'AK',
    },
    99691: {
      lat: 62.74385,
      lng: -153.84293,
      city: 'Nikolai',
      state: 'AK',
    },
    99692: {
      lat: 53.88393,
      lng: -166.54498,
      city: 'Dutch Harbor',
      state: 'AK',
    },
    99693: {
      lat: 60.83738,
      lng: -148.15024,
      city: 'Whittier',
      state: 'AK',
    },
    99694: {
      lat: 61.61854,
      lng: -149.8255,
      city: 'Houston',
      state: 'AK',
    },
    99695: {
      lat: 55.91828,
      lng: -159.49335,
      city: 'Anchorage',
      state: 'AK',
    },
    99701: {
      lat: 67.25435,
      lng: -149.87754,
      city: 'Fairbanks',
      state: 'AK',
    },
    99702: {
      lat: 64.64835,
      lng: -147.03879,
      city: 'Eielson Afb',
      state: 'AK',
    },
    99703: {
      lat: 64.83542,
      lng: -147.62612,
      city: 'Fort Wainwright',
      state: 'AK',
    },
    99704: {
      lat: 64.29023,
      lng: -149.20423,
      city: 'Clear',
      state: 'AK',
    },
    99705: {
      lat: 64.77356,
      lng: -147.27055,
      city: 'North Pole',
      state: 'AK',
    },
    99706: {
      lat: 64.0281,
      lng: -144.6331,
      city: 'Fairbanks',
      state: 'AK',
    },
    99707: {
      lat: 65.1551,
      lng: -146.25238,
      city: 'Fairbanks',
      state: 'AK',
    },
    99709: {
      lat: 64.87402,
      lng: -148.22458,
      city: 'Fairbanks',
      state: 'AK',
    },
    99712: {
      lat: 64.98311,
      lng: -146.13946,
      city: 'Fairbanks',
      state: 'AK',
    },
    99714: {
      lat: 64.4368,
      lng: -146.69689,
      city: 'Salcha',
      state: 'AK',
    },
    99720: {
      lat: 66.56395,
      lng: -152.76395,
      city: 'Allakaket',
      state: 'AK',
    },
    99721: {
      lat: 68.14999,
      lng: -151.71616,
      city: 'Anaktuvuk Pass',
      state: 'AK',
    },
    99722: {
      lat: 68.14208,
      lng: -145.49557,
      city: 'Arctic Village',
      state: 'AK',
    },
    99723: {
      lat: 71.27434,
      lng: -156.75394,
      city: 'Barrow',
      state: 'AK',
    },
    99724: {
      lat: 66.32218,
      lng: -146.96636,
      city: 'Beaver',
      state: 'AK',
    },
    99725: {
      lat: 64.8612,
      lng: -148.02387,
      city: 'Ester',
      state: 'AK',
    },
    99726: {
      lat: 66.73615,
      lng: -151.47757,
      city: 'Bettles Field',
      state: 'AK',
    },
    99727: {
      lat: 65.97654,
      lng: -161.13918,
      city: 'Buckland',
      state: 'AK',
    },
    99729: {
      lat: 63.22686,
      lng: -148.10122,
      city: 'Cantwell',
      state: 'AK',
    },
    99730: {
      lat: 65.67807,
      lng: -145.54055,
      city: 'Central',
      state: 'AK',
    },
    99732: {
      lat: 64.07945,
      lng: -141.97039,
      city: 'Chicken',
      state: 'AK',
    },
    99733: {
      lat: 65.79284,
      lng: -144.18282,
      city: 'Circle',
      state: 'AK',
    },
    99734: {
      lat: 70.36201,
      lng: -149.0021,
      city: 'Prudhoe Bay',
      state: 'AK',
    },
    99736: {
      lat: 66.07222,
      lng: -162.74349,
      city: 'Deering',
      state: 'AK',
    },
    99737: {
      lat: 63.62717,
      lng: -145.07539,
      city: 'Delta Junction',
      state: 'AK',
    },
    99738: {
      lat: 65.14953,
      lng: -142.43307,
      city: 'Eagle',
      state: 'AK',
    },
    99739: { lat: 64.97626, lng: -162.8397, city: 'Elim', state: 'AK' },
    99740: {
      lat: 67.70759,
      lng: -143.95132,
      city: 'Fort Yukon',
      state: 'AK',
    },
    99741: {
      lat: 64.73808,
      lng: -156.86351,
      city: 'Galena',
      state: 'AK',
    },
    99742: {
      lat: 63.75375,
      lng: -171.68811,
      city: 'Gambell',
      state: 'AK',
    },
    99743: {
      lat: 63.90955,
      lng: -149.91736,
      city: 'Healy',
      state: 'AK',
    },
    99744: {
      lat: 64.31361,
      lng: -149.13217,
      city: 'Anderson',
      state: 'AK',
    },
    99745: {
      lat: 66.0472,
      lng: -154.24096,
      city: 'Hughes',
      state: 'AK',
    },
    99746: {
      lat: 65.70013,
      lng: -156.33856,
      city: 'Huslia',
      state: 'AK',
    },
    99747: {
      lat: 70.11882,
      lng: -143.67029,
      city: 'Kaktovik',
      state: 'AK',
    },
    99748: {
      lat: 64.32229,
      lng: -158.74356,
      city: 'Kaltag',
      state: 'AK',
    },
    99749: {
      lat: 66.9786,
      lng: -160.43178,
      city: 'Kiana',
      state: 'AK',
    },
    99750: {
      lat: 67.73299,
      lng: -164.52122,
      city: 'Kivalina',
      state: 'AK',
    },
    99751: {
      lat: 66.93103,
      lng: -156.89386,
      city: 'Kobuk',
      state: 'AK',
    },
    99752: {
      lat: 67.29316,
      lng: -161.91744,
      city: 'Kotzebue',
      state: 'AK',
    },
    99753: {
      lat: 64.94112,
      lng: -161.14536,
      city: 'Koyuk',
      state: 'AK',
    },
    99754: {
      lat: 64.9008,
      lng: -157.69462,
      city: 'Koyukuk',
      state: 'AK',
    },
    99755: {
      lat: 63.68849,
      lng: -148.88742,
      city: 'Denali National Park',
      state: 'AK',
    },
    99756: {
      lat: 64.93645,
      lng: -150.66654,
      city: 'Manley Hot Springs',
      state: 'AK',
    },
    99757: {
      lat: 63.83208,
      lng: -152.49481,
      city: 'Lake Minchumina',
      state: 'AK',
    },
    99758: {
      lat: 65.15175,
      lng: -149.3724,
      city: 'Minto',
      state: 'AK',
    },
    99759: {
      lat: 69.73723,
      lng: -162.83741,
      city: 'Point Lay',
      state: 'AK',
    },
    99760: {
      lat: 64.37035,
      lng: -149.17513,
      city: 'Nenana',
      state: 'AK',
    },
    99761: {
      lat: 67.6004,
      lng: -163.03459,
      city: 'Noatak',
      state: 'AK',
    },
    99762: {
      lat: 64.85078,
      lng: -164.80261,
      city: 'Nome',
      state: 'AK',
    },
    99763: {
      lat: 66.82801,
      lng: -161.03735,
      city: 'Noorvik',
      state: 'AK',
    },
    99764: {
      lat: 62.76664,
      lng: -141.67447,
      city: 'Northway',
      state: 'AK',
    },
    99765: {
      lat: 64.73066,
      lng: -158.13108,
      city: 'Nulato',
      state: 'AK',
    },
    99766: {
      lat: 68.29104,
      lng: -166.04257,
      city: 'Point Hope',
      state: 'AK',
    },
    99767: {
      lat: 65.49664,
      lng: -150.14393,
      city: 'Rampart',
      state: 'AK',
    },
    99768: {
      lat: 65.38635,
      lng: -153.53826,
      city: 'Ruby',
      state: 'AK',
    },
    99769: {
      lat: 63.67965,
      lng: -170.48309,
      city: 'Savoonga',
      state: 'AK',
    },
    99770: {
      lat: 66.60708,
      lng: -160.07136,
      city: 'Selawik',
      state: 'AK',
    },
    99771: {
      lat: 64.35764,
      lng: -161.1989,
      city: 'Shaktoolik',
      state: 'AK',
    },
    99772: {
      lat: 65.94811,
      lng: -165.80122,
      city: 'Shishmaref',
      state: 'AK',
    },
    99773: {
      lat: 66.88053,
      lng: -157.14993,
      city: 'Shungnak',
      state: 'AK',
    },
    99774: {
      lat: 66.0232,
      lng: -149.07982,
      city: 'Stevens Village',
      state: 'AK',
    },
    99775: {
      lat: 64.85744,
      lng: -147.83483,
      city: 'Fairbanks',
      state: 'AK',
    },
    99776: {
      lat: 63.35232,
      lng: -143.43181,
      city: 'Tanacross',
      state: 'AK',
    },
    99777: {
      lat: 65.18392,
      lng: -152.06673,
      city: 'Tanana',
      state: 'AK',
    },
    99778: {
      lat: 65.25309,
      lng: -166.342,
      city: 'Teller',
      state: 'AK',
    },
    99780: { lat: 63.46295, lng: -142.1701, city: 'Tok', state: 'AK' },
    99781: {
      lat: 67.32073,
      lng: -145.98835,
      city: 'Venetie',
      state: 'AK',
    },
    99782: {
      lat: 70.63804,
      lng: -159.97121,
      city: 'Wainwright',
      state: 'AK',
    },
    99783: {
      lat: 65.61191,
      lng: -168.07732,
      city: 'Wales',
      state: 'AK',
    },
    99784: {
      lat: 64.682,
      lng: -163.4067,
      city: 'White Mountain',
      state: 'AK',
    },
    99785: {
      lat: 65.34151,
      lng: -166.4942,
      city: 'Brevig Mission',
      state: 'AK',
    },
    99786: {
      lat: 67.08971,
      lng: -157.87253,
      city: 'Ambler',
      state: 'AK',
    },
    99788: {
      lat: 66.64629,
      lng: -143.78137,
      city: 'Chalkyitsik',
      state: 'AK',
    },
    99789: {
      lat: 70.21348,
      lng: -150.98966,
      city: 'Nuiqsut',
      state: 'AK',
    },
    99790: {
      lat: 65.42642,
      lng: -148.18738,
      city: 'Fairbanks',
      state: 'AK',
    },
    99791: {
      lat: 70.48101,
      lng: -157.3835,
      city: 'Atqasuk',
      state: 'AK',
    },
    99801: {
      lat: 58.39785,
      lng: -134.05472,
      city: 'Juneau',
      state: 'AK',
    },
    99820: {
      lat: 57.43552,
      lng: -134.28926,
      city: 'Angoon',
      state: 'AK',
    },
    99824: {
      lat: 58.27234,
      lng: -134.40082,
      city: 'Douglas',
      state: 'AK',
    },
    99825: {
      lat: 58.12864,
      lng: -136.19507,
      city: 'Elfin Cove',
      state: 'AK',
    },
    99826: {
      lat: 58.39816,
      lng: -135.83456,
      city: 'Gustavus',
      state: 'AK',
    },
    99827: {
      lat: 59.02659,
      lng: -135.88483,
      city: 'Haines',
      state: 'AK',
    },
    99829: {
      lat: 58.03502,
      lng: -135.33856,
      city: 'Hoonah',
      state: 'AK',
    },
    99830: {
      lat: 56.78305,
      lng: -134.16688,
      city: 'Kake',
      state: 'AK',
    },
    99832: {
      lat: 57.96611,
      lng: -136.08265,
      city: 'Pelican',
      state: 'AK',
    },
    99833: {
      lat: 56.82952,
      lng: -133.17986,
      city: 'Petersburg',
      state: 'AK',
    },
    99835: {
      lat: 57.23891,
      lng: -135.2633,
      city: 'Sitka',
      state: 'AK',
    },
    99836: {
      lat: 56.23558,
      lng: -134.65333,
      city: 'Port Alexander',
      state: 'AK',
    },
    99840: {
      lat: 59.56297,
      lng: -135.33626,
      city: 'Skagway',
      state: 'AK',
    },
    99841: {
      lat: 58.00866,
      lng: -135.57397,
      city: 'Tenakee Springs',
      state: 'AK',
    },
    99850: {
      lat: 58.48821,
      lng: -135.48432,
      city: 'Juneau',
      state: 'AK',
    },
    99901: {
      lat: 55.6227,
      lng: -131.67157,
      city: 'Ketchikan',
      state: 'AK',
    },
    99903: {
      lat: 55.73414,
      lng: -132.10669,
      city: 'Meyers Chuck',
      state: 'AK',
    },
    99918: {
      lat: 55.94605,
      lng: -132.74233,
      city: 'Coffman Cove',
      state: 'AK',
    },
    99919: {
      lat: 55.64983,
      lng: -132.50337,
      city: 'Thorne Bay',
      state: 'AK',
    },
    99921: {
      lat: 55.51989,
      lng: -132.81656,
      city: 'Craig',
      state: 'AK',
    },
    99922: {
      lat: 55.30218,
      lng: -133.03264,
      city: 'Hydaburg',
      state: 'AK',
    },
    99923: {
      lat: 55.98043,
      lng: -130.03803,
      city: 'Hyder',
      state: 'AK',
    },
    99925: {
      lat: 55.55398,
      lng: -132.96276,
      city: 'Klawock',
      state: 'AK',
    },
    99926: {
      lat: 55.12617,
      lng: -131.48928,
      city: 'Metlakatla',
      state: 'AK',
    },
    99927: {
      lat: 56.33305,
      lng: -133.60044,
      city: 'Point Baker',
      state: 'AK',
    },
    99929: {
      lat: 56.36089,
      lng: -132.00635,
      city: 'Wrangell',
      state: 'AK',
    },
    '00601': {
      lat: 18.18027,
      lng: -66.75266,
      city: 'Adjuntas',
      state: 'PR',
    },
    '00602': {
      lat: 18.36075,
      lng: -67.17541,
      city: 'Aguada',
      state: 'PR',
    },
    '00603': {
      lat: 18.45744,
      lng: -67.12225,
      city: 'Aguadilla',
      state: 'PR',
    },
    '00606': {
      lat: 18.16585,
      lng: -66.93716,
      city: 'Maricao',
      state: 'PR',
    },
    '00610': {
      lat: 18.2911,
      lng: -67.12243,
      city: 'Anasco',
      state: 'PR',
    },
    '00611': {
      lat: 18.27698,
      lng: -66.80688,
      city: 'Angeles',
      state: 'PR',
    },
    '00612': {
      lat: 18.41283,
      lng: -66.7051,
      city: 'Arecibo',
      state: 'PR',
    },
    '00616': {
      lat: 18.41878,
      lng: -66.6679,
      city: 'Bajadero',
      state: 'PR',
    },
    '00617': {
      lat: 18.44598,
      lng: -66.56006,
      city: 'Barceloneta',
      state: 'PR',
    },
    '00622': {
      lat: 17.98892,
      lng: -67.1566,
      city: 'Boqueron',
      state: 'PR',
    },
    '00623': {
      lat: 18.08429,
      lng: -67.15336,
      city: 'Cabo Rojo',
      state: 'PR',
    },
    '00624': {
      lat: 18.05905,
      lng: -66.71932,
      city: 'Penuelas',
      state: 'PR',
    },
    '00627': {
      lat: 18.41905,
      lng: -66.86037,
      city: 'Camuy',
      state: 'PR',
    },
    '00631': {
      lat: 18.1852,
      lng: -66.83169,
      city: 'Castaner',
      state: 'PR',
    },
    '00636': {
      lat: 18.16354,
      lng: -67.08014,
      city: 'Rosario',
      state: 'PR',
    },
    '00637': {
      lat: 18.081,
      lng: -66.94659,
      city: 'Sabana Grande',
      state: 'PR',
    },
    '00638': {
      lat: 18.28462,
      lng: -66.5137,
      city: 'Ciales',
      state: 'PR',
    },
    '00641': {
      lat: 18.26742,
      lng: -66.70212,
      city: 'Utuado',
      state: 'PR',
    },
    '00646': {
      lat: 18.43251,
      lng: -66.28429,
      city: 'Dorado',
      state: 'PR',
    },
    '00647': {
      lat: 17.96667,
      lng: -66.94266,
      city: 'Ensenada',
      state: 'PR',
    },
    '00650': {
      lat: 18.34487,
      lng: -66.58347,
      city: 'Florida',
      state: 'PR',
    },
    '00652': {
      lat: 18.45614,
      lng: -66.60194,
      city: 'Garrochales',
      state: 'PR',
    },
    '00653': {
      lat: 17.99413,
      lng: -66.90266,
      city: 'Guanica',
      state: 'PR',
    },
    '00656': {
      lat: 18.04186,
      lng: -66.79123,
      city: 'Guayanilla',
      state: 'PR',
    },
    '00659': {
      lat: 18.41036,
      lng: -66.79691,
      city: 'Hatillo',
      state: 'PR',
    },
    '00660': {
      lat: 18.13412,
      lng: -67.11399,
      city: 'Hormigueros',
      state: 'PR',
    },
    '00662': {
      lat: 18.46622,
      lng: -67.01508,
      city: 'Isabela',
      state: 'PR',
    },
    '00664': {
      lat: 18.21064,
      lng: -66.59066,
      city: 'Jayuya',
      state: 'PR',
    },
    '00667': { lat: 18.01246, lng: -67.0417, city: 'Lajas', state: 'PR' },
    '00669': {
      lat: 18.27019,
      lng: -66.86727,
      city: 'Lares',
      state: 'PR',
    },
    '00670': {
      lat: 18.23782,
      lng: -66.98852,
      city: 'Las Marias',
      state: 'PR',
    },
    '00674': {
      lat: 18.42229,
      lng: -66.4891,
      city: 'Manati',
      state: 'PR',
    },
    '00676': { lat: 18.37897, lng: -67.08098, city: 'Moca', state: 'PR' },
    '00677': {
      lat: 18.33535,
      lng: -67.23155,
      city: 'Rincon',
      state: 'PR',
    },
    '00678': {
      lat: 18.42949,
      lng: -66.93016,
      city: 'Quebradillas',
      state: 'PR',
    },
    '00680': {
      lat: 18.16307,
      lng: -67.36122,
      city: 'Mayaguez',
      state: 'PR',
    },
    '00682': {
      lat: 18.22415,
      lng: -67.15309,
      city: 'Mayaguez',
      state: 'PR',
    },
    '00683': {
      lat: 18.10926,
      lng: -67.03803,
      city: 'San German',
      state: 'PR',
    },
    '00685': {
      lat: 18.33656,
      lng: -66.97518,
      city: 'San Sebastian',
      state: 'PR',
    },
    '00687': {
      lat: 18.31707,
      lng: -66.42029,
      city: 'Morovis',
      state: 'PR',
    },
    '00688': {
      lat: 18.3807,
      lng: -66.62496,
      city: 'Sabana Hoyos',
      state: 'PR',
    },
    '00690': {
      lat: 18.49363,
      lng: -67.09734,
      city: 'San Antonio',
      state: 'PR',
    },
    '00692': {
      lat: 18.40905,
      lng: -66.33768,
      city: 'Vega Alta',
      state: 'PR',
    },
    '00693': {
      lat: 18.42633,
      lng: -66.39811,
      city: 'Vega Baja',
      state: 'PR',
    },
    '00694': {
      lat: 18.48327,
      lng: -66.39269,
      city: 'Vega Baja',
      state: 'PR',
    },
    '00698': {
      lat: 18.07274,
      lng: -66.85519,
      city: 'Yauco',
      state: 'PR',
    },
    '00703': {
      lat: 18.24739,
      lng: -66.13143,
      city: 'Aguas Buenas',
      state: 'PR',
    },
    '00704': {
      lat: 17.97442,
      lng: -66.22888,
      city: 'Aguirre',
      state: 'PR',
    },
    '00705': {
      lat: 18.1294,
      lng: -66.26653,
      city: 'Aibonito',
      state: 'PR',
    },
    '00707': {
      lat: 18.01752,
      lng: -65.92127,
      city: 'Maunabo',
      state: 'PR',
    },
    '00714': {
      lat: 17.9981,
      lng: -66.05635,
      city: 'Arroyo',
      state: 'PR',
    },
    '00715': {
      lat: 17.99738,
      lng: -66.56104,
      city: 'Mercedita',
      state: 'PR',
    },
    '00716': {
      lat: 17.99942,
      lng: -66.59868,
      city: 'Ponce',
      state: 'PR',
    },
    '00717': {
      lat: 18.00272,
      lng: -66.61146,
      city: 'Ponce',
      state: 'PR',
    },
    '00718': {
      lat: 18.23145,
      lng: -65.76067,
      city: 'Naguabo',
      state: 'PR',
    },
    '00719': {
      lat: 18.28879,
      lng: -66.25316,
      city: 'Naranjito',
      state: 'PR',
    },
    '00720': {
      lat: 18.21559,
      lng: -66.42421,
      city: 'Orocovis',
      state: 'PR',
    },
    '00723': {
      lat: 18.03139,
      lng: -66.01122,
      city: 'Patillas',
      state: 'PR',
    },
    '00725': {
      lat: 18.22052,
      lng: -66.03962,
      city: 'Caguas',
      state: 'PR',
    },
    '00727': {
      lat: 18.21142,
      lng: -66.07374,
      city: 'Caguas',
      state: 'PR',
    },
    '00728': {
      lat: 18.00697,
      lng: -66.65995,
      city: 'Ponce',
      state: 'PR',
    },
    '00729': {
      lat: 18.33524,
      lng: -65.88677,
      city: 'Canovanas',
      state: 'PR',
    },
    '00730': {
      lat: 18.03256,
      lng: -66.61384,
      city: 'Ponce',
      state: 'PR',
    },
    '00731': {
      lat: 18.09914,
      lng: -66.63279,
      city: 'Ponce',
      state: 'PR',
    },
    '00735': { lat: 18.2525, lng: -65.67534, city: 'Ceiba', state: 'PR' },
    '00736': {
      lat: 18.10736,
      lng: -66.14994,
      city: 'Cayey',
      state: 'PR',
    },
    '00738': {
      lat: 18.31793,
      lng: -65.66793,
      city: 'Fajardo',
      state: 'PR',
    },
    '00739': {
      lat: 18.17543,
      lng: -66.16198,
      city: 'Cidra',
      state: 'PR',
    },
    '00740': {
      lat: 18.33251,
      lng: -65.63419,
      city: 'Puerto Real',
      state: 'PR',
    },
    '00741': {
      lat: 18.16569,
      lng: -65.75506,
      city: 'Punta Santiago',
      state: 'PR',
    },
    '00745': {
      lat: 18.3466,
      lng: -65.81382,
      city: 'Rio Grande',
      state: 'PR',
    },
    '00751': {
      lat: 18.01205,
      lng: -66.25871,
      city: 'Salinas',
      state: 'PR',
    },
    '00754': {
      lat: 18.1447,
      lng: -65.97565,
      city: 'San Lorenzo',
      state: 'PR',
    },
    '00757': {
      lat: 17.9933,
      lng: -66.39056,
      city: 'Santa Isabel',
      state: 'PR',
    },
    '00765': {
      lat: 18.12365,
      lng: -65.43013,
      city: 'Vieques',
      state: 'PR',
    },
    '00766': {
      lat: 18.13039,
      lng: -66.4757,
      city: 'Villalba',
      state: 'PR',
    },
    '00767': {
      lat: 18.07033,
      lng: -65.89618,
      city: 'Yabucoa',
      state: 'PR',
    },
    '00769': { lat: 18.09459, lng: -66.3607, city: 'Coamo', state: 'PR' },
    '00771': {
      lat: 18.18797,
      lng: -65.86916,
      city: 'Las Piedras',
      state: 'PR',
    },
    '00772': {
      lat: 18.43048,
      lng: -65.90377,
      city: 'Loiza',
      state: 'PR',
    },
    '00773': {
      lat: 18.34073,
      lng: -65.72756,
      city: 'Luquillo',
      state: 'PR',
    },
    '00775': {
      lat: 18.31387,
      lng: -65.28618,
      city: 'Culebra',
      state: 'PR',
    },
    '00777': {
      lat: 18.22365,
      lng: -65.90904,
      city: 'Juncos',
      state: 'PR',
    },
    '00778': {
      lat: 18.26319,
      lng: -65.97938,
      city: 'Gurabo',
      state: 'PR',
    },
    '00780': {
      lat: 18.09998,
      lng: -66.57032,
      city: 'Coto Laurel',
      state: 'PR',
    },
    '00782': {
      lat: 18.22501,
      lng: -66.22452,
      city: 'Comerio',
      state: 'PR',
    },
    '00783': {
      lat: 18.30348,
      lng: -66.32851,
      city: 'Corozal',
      state: 'PR',
    },
    '00784': {
      lat: 18.00558,
      lng: -66.13651,
      city: 'Guayama',
      state: 'PR',
    },
    '00786': {
      lat: 18.1357,
      lng: -66.22881,
      city: 'La Plata',
      state: 'PR',
    },
    '00791': {
      lat: 18.14391,
      lng: -65.81398,
      city: 'Humacao',
      state: 'PR',
    },
    '00794': {
      lat: 18.19934,
      lng: -66.31179,
      city: 'Barranquitas',
      state: 'PR',
    },
    '00795': {
      lat: 18.05128,
      lng: -66.49485,
      city: 'Juana Diaz',
      state: 'PR',
    },
    '00802': {
      lat: 18.34349,
      lng: -64.9287,
      city: 'St Thomas',
      state: 'VI',
    },
    '00820': {
      lat: 17.7388,
      lng: -64.70695,
      city: 'Christiansted',
      state: 'VI',
    },
    '00830': {
      lat: 18.33857,
      lng: -64.73802,
      city: 'St John',
      state: 'VI',
    },
    '00840': {
      lat: 17.72649,
      lng: -64.84717,
      city: 'Frederiksted',
      state: 'VI',
    },
    '00850': {
      lat: 17.72763,
      lng: -64.79122,
      city: 'Kingshill',
      state: 'VI',
    },
    '00851': {
      lat: 17.74757,
      lng: -64.78737,
      city: 'Kingshill',
      state: 'VI',
    },
    '00901': {
      lat: 18.46478,
      lng: -66.10277,
      city: 'San Juan',
      state: 'PR',
    },
    '00906': {
      lat: 18.46446,
      lng: -66.09499,
      city: 'San Juan',
      state: 'PR',
    },
    '00907': {
      lat: 18.45287,
      lng: -66.08381,
      city: 'San Juan',
      state: 'PR',
    },
    '00909': {
      lat: 18.44131,
      lng: -66.06866,
      city: 'San Juan',
      state: 'PR',
    },
    '00911': {
      lat: 18.45133,
      lng: -66.05636,
      city: 'San Juan',
      state: 'PR',
    },
    '00912': {
      lat: 18.44503,
      lng: -66.05995,
      city: 'San Juan',
      state: 'PR',
    },
    '00913': {
      lat: 18.45024,
      lng: -66.04275,
      city: 'San Juan',
      state: 'PR',
    },
    '00915': {
      lat: 18.43673,
      lng: -66.04576,
      city: 'San Juan',
      state: 'PR',
    },
    '00917': {
      lat: 18.4208,
      lng: -66.04989,
      city: 'San Juan',
      state: 'PR',
    },
    '00918': {
      lat: 18.42139,
      lng: -66.06774,
      city: 'San Juan',
      state: 'PR',
    },
    '00920': {
      lat: 18.41904,
      lng: -66.09023,
      city: 'San Juan',
      state: 'PR',
    },
    '00921': {
      lat: 18.39206,
      lng: -66.0871,
      city: 'San Juan',
      state: 'PR',
    },
    '00923': {
      lat: 18.41146,
      lng: -66.03715,
      city: 'San Juan',
      state: 'PR',
    },
    '00924': {
      lat: 18.39987,
      lng: -66.01344,
      city: 'San Juan',
      state: 'PR',
    },
    '00925': {
      lat: 18.40028,
      lng: -66.05058,
      city: 'San Juan',
      state: 'PR',
    },
    '00926': {
      lat: 18.34691,
      lng: -66.05434,
      city: 'San Juan',
      state: 'PR',
    },
    '00927': {
      lat: 18.38884,
      lng: -66.07149,
      city: 'San Juan',
      state: 'PR',
    },
    '00934': {
      lat: 18.41372,
      lng: -66.11998,
      city: 'Fort Buchanan',
      state: 'PR',
    },
    '00949': {
      lat: 18.43166,
      lng: -66.21349,
      city: 'Toa Baja',
      state: 'PR',
    },
    '00950': {
      lat: 18.46201,
      lng: -66.23917,
      city: 'Toa Baja',
      state: 'PR',
    },
    '00951': {
      lat: 18.43175,
      lng: -66.25628,
      city: 'Toa Baja',
      state: 'PR',
    },
    '00952': {
      lat: 18.42874,
      lng: -66.18897,
      city: 'Sabana Seca',
      state: 'PR',
    },
    '00953': {
      lat: 18.36319,
      lng: -66.24764,
      city: 'Toa Alta',
      state: 'PR',
    },
    '00956': {
      lat: 18.32121,
      lng: -66.17185,
      city: 'Bayamon',
      state: 'PR',
    },
    '00957': {
      lat: 18.36696,
      lng: -66.18857,
      city: 'Bayamon',
      state: 'PR',
    },
    '00959': {
      lat: 18.3852,
      lng: -66.15583,
      city: 'Bayamon',
      state: 'PR',
    },
    '00960': {
      lat: 18.41674,
      lng: -66.14365,
      city: 'Bayamon',
      state: 'PR',
    },
    '00961': {
      lat: 18.40987,
      lng: -66.16415,
      city: 'Bayamon',
      state: 'PR',
    },
    '00962': {
      lat: 18.43514,
      lng: -66.13949,
      city: 'Catano',
      state: 'PR',
    },
    '00965': {
      lat: 18.42853,
      lng: -66.11175,
      city: 'Guaynabo',
      state: 'PR',
    },
    '00966': {
      lat: 18.39784,
      lng: -66.11394,
      city: 'Guaynabo',
      state: 'PR',
    },
    '00968': {
      lat: 18.40609,
      lng: -66.10123,
      city: 'Guaynabo',
      state: 'PR',
    },
    '00969': {
      lat: 18.36696,
      lng: -66.11,
      city: 'Guaynabo',
      state: 'PR',
    },
    '00971': {
      lat: 18.32067,
      lng: -66.11751,
      city: 'Guaynabo',
      state: 'PR',
    },
    '00976': {
      lat: 18.33903,
      lng: -65.99286,
      city: 'Trujillo Alto',
      state: 'PR',
    },
    '00979': {
      lat: 18.43734,
      lng: -66.0089,
      city: 'Carolina',
      state: 'PR',
    },
    '00982': {
      lat: 18.40961,
      lng: -65.99143,
      city: 'Carolina',
      state: 'PR',
    },
    '00983': {
      lat: 18.41788,
      lng: -65.98104,
      city: 'Carolina',
      state: 'PR',
    },
    '00985': {
      lat: 18.40628,
      lng: -65.94767,
      city: 'Carolina',
      state: 'PR',
    },
    '00987': {
      lat: 18.34115,
      lng: -65.94257,
      city: 'Carolina',
      state: 'PR',
    },
    '01001': {
      lat: 42.06262,
      lng: -72.62521,
      city: 'Agawam',
      state: 'MA',
    },
    '01002': {
      lat: 42.37633,
      lng: -72.46462,
      city: 'Amherst',
      state: 'MA',
    },
    '01003': {
      lat: 42.39135,
      lng: -72.52327,
      city: 'Amherst',
      state: 'MA',
    },
    '01005': {
      lat: 42.42117,
      lng: -72.10655,
      city: 'Barre',
      state: 'MA',
    },
    '01007': {
      lat: 42.28163,
      lng: -72.40009,
      city: 'Belchertown',
      state: 'MA',
    },
    '01008': {
      lat: 42.18234,
      lng: -72.95819,
      city: 'Blandford',
      state: 'MA',
    },
    '01009': {
      lat: 42.21133,
      lng: -72.34189,
      city: 'Bondsville',
      state: 'MA',
    },
    '01010': {
      lat: 42.12904,
      lng: -72.20597,
      city: 'Brimfield',
      state: 'MA',
    },
    '01011': {
      lat: 42.30233,
      lng: -72.96448,
      city: 'Chester',
      state: 'MA',
    },
    '01012': {
      lat: 42.38495,
      lng: -72.84675,
      city: 'Chesterfield',
      state: 'MA',
    },
    '01013': {
      lat: 42.16059,
      lng: -72.60788,
      city: 'Chicopee',
      state: 'MA',
    },
    '01020': {
      lat: 42.17618,
      lng: -72.56538,
      city: 'Chicopee',
      state: 'MA',
    },
    '01022': {
      lat: 42.19721,
      lng: -72.54249,
      city: 'Chicopee',
      state: 'MA',
    },
    '01026': {
      lat: 42.46858,
      lng: -72.92296,
      city: 'Cummington',
      state: 'MA',
    },
    '01027': {
      lat: 42.29506,
      lng: -72.74303,
      city: 'Easthampton',
      state: 'MA',
    },
    '01028': {
      lat: 42.05967,
      lng: -72.49899,
      city: 'East Longmeadow',
      state: 'MA',
    },
    '01029': {
      lat: 42.19402,
      lng: -73.05067,
      city: 'East Otis',
      state: 'MA',
    },
    '01030': {
      lat: 42.06869,
      lng: -72.67967,
      city: 'Feeding Hills',
      state: 'MA',
    },
    '01031': {
      lat: 42.32782,
      lng: -72.19191,
      city: 'Gilbertville',
      state: 'MA',
    },
    '01032': {
      lat: 42.45313,
      lng: -72.81965,
      city: 'Goshen',
      state: 'MA',
    },
    '01033': {
      lat: 42.26082,
      lng: -72.50361,
      city: 'Granby',
      state: 'MA',
    },
    '01034': {
      lat: 42.0826,
      lng: -72.95611,
      city: 'Granville',
      state: 'MA',
    },
    '01035': {
      lat: 42.35563,
      lng: -72.5692,
      city: 'Hadley',
      state: 'MA',
    },
    '01036': {
      lat: 42.0638,
      lng: -72.4157,
      city: 'Hampden',
      state: 'MA',
    },
    '01037': {
      lat: 42.37063,
      lng: -72.19466,
      city: 'Hardwick',
      state: 'MA',
    },
    '01038': {
      lat: 42.38466,
      lng: -72.60559,
      city: 'Hatfield',
      state: 'MA',
    },
    '01039': {
      lat: 42.41049,
      lng: -72.69188,
      city: 'Haydenville',
      state: 'MA',
    },
    '01040': {
      lat: 42.21251,
      lng: -72.64109,
      city: 'Holyoke',
      state: 'MA',
    },
    '01050': {
      lat: 42.29092,
      lng: -72.87526,
      city: 'Huntington',
      state: 'MA',
    },
    '01053': {
      lat: 42.35205,
      lng: -72.71032,
      city: 'Leeds',
      state: 'MA',
    },
    '01054': {
      lat: 42.47381,
      lng: -72.48768,
      city: 'Leverett',
      state: 'MA',
    },
    '01056': {
      lat: 42.19211,
      lng: -72.45805,
      city: 'Ludlow',
      state: 'MA',
    },
    '01057': {
      lat: 42.09118,
      lng: -72.32392,
      city: 'Monson',
      state: 'MA',
    },
    '01060': {
      lat: 42.32218,
      lng: -72.63126,
      city: 'Northampton',
      state: 'MA',
    },
    '01062': {
      lat: 42.32146,
      lng: -72.69244,
      city: 'Florence',
      state: 'MA',
    },
    '01063': {
      lat: 42.31962,
      lng: -72.63951,
      city: 'Northampton',
      state: 'MA',
    },
    '01066': {
      lat: 42.40742,
      lng: -72.64032,
      city: 'North Hatfield',
      state: 'MA',
    },
    '01068': {
      lat: 42.35125,
      lng: -72.04776,
      city: 'Oakham',
      state: 'MA',
    },
    '01069': {
      lat: 42.18798,
      lng: -72.30578,
      city: 'Palmer',
      state: 'MA',
    },
    '01070': {
      lat: 42.51958,
      lng: -72.92504,
      city: 'Plainfield',
      state: 'MA',
    },
    '01071': {
      lat: 42.16029,
      lng: -72.8598,
      city: 'Russell',
      state: 'MA',
    },
    '01072': {
      lat: 42.46327,
      lng: -72.41893,
      city: 'Shutesbury',
      state: 'MA',
    },
    '01073': {
      lat: 42.23084,
      lng: -72.73856,
      city: 'Southampton',
      state: 'MA',
    },
    '01074': {
      lat: 42.3837,
      lng: -72.10021,
      city: 'South Barre',
      state: 'MA',
    },
    '01075': {
      lat: 42.25672,
      lng: -72.57928,
      city: 'South Hadley',
      state: 'MA',
    },
    '01077': {
      lat: 42.05441,
      lng: -72.77851,
      city: 'Southwick',
      state: 'MA',
    },
    '01079': {
      lat: 42.18508,
      lng: -72.33406,
      city: 'Thorndike',
      state: 'MA',
    },
    '01080': {
      lat: 42.18228,
      lng: -72.36515,
      city: 'Three Rivers',
      state: 'MA',
    },
    '01081': {
      lat: 42.06232,
      lng: -72.23085,
      city: 'Wales',
      state: 'MA',
    },
    '01082': { lat: 42.29816, lng: -72.27676, city: 'Ware', state: 'MA' },
    '01083': {
      lat: 42.20652,
      lng: -72.19906,
      city: 'Warren',
      state: 'MA',
    },
    '01084': {
      lat: 42.38589,
      lng: -72.88165,
      city: 'West Chesterfield',
      state: 'MA',
    },
    '01085': {
      lat: 42.15375,
      lng: -72.76961,
      city: 'Westfield',
      state: 'MA',
    },
    '01088': {
      lat: 42.38981,
      lng: -72.6465,
      city: 'West Hatfield',
      state: 'MA',
    },
    '01089': {
      lat: 42.12532,
      lng: -72.65025,
      city: 'West Springfield',
      state: 'MA',
    },
    '01092': {
      lat: 42.20008,
      lng: -72.23715,
      city: 'West Warren',
      state: 'MA',
    },
    '01093': {
      lat: 42.44335,
      lng: -72.65099,
      city: 'Whately',
      state: 'MA',
    },
    '01094': {
      lat: 42.36481,
      lng: -72.13548,
      city: 'Wheelwright',
      state: 'MA',
    },
    '01095': {
      lat: 42.12763,
      lng: -72.42994,
      city: 'Wilbraham',
      state: 'MA',
    },
    '01096': {
      lat: 42.40968,
      lng: -72.77213,
      city: 'Williamsburg',
      state: 'MA',
    },
    '01097': {
      lat: 42.17722,
      lng: -72.83175,
      city: 'Woronoco',
      state: 'MA',
    },
    '01098': {
      lat: 42.39551,
      lng: -72.94315,
      city: 'Worthington',
      state: 'MA',
    },
    '01103': {
      lat: 42.10443,
      lng: -72.58997,
      city: 'Springfield',
      state: 'MA',
    },
    '01104': {
      lat: 42.13326,
      lng: -72.56733,
      city: 'Springfield',
      state: 'MA',
    },
    '01105': {
      lat: 42.09911,
      lng: -72.57865,
      city: 'Springfield',
      state: 'MA',
    },
    '01106': {
      lat: 42.04877,
      lng: -72.57163,
      city: 'Longmeadow',
      state: 'MA',
    },
    '01107': {
      lat: 42.12116,
      lng: -72.60642,
      city: 'Springfield',
      state: 'MA',
    },
    '01108': {
      lat: 42.0814,
      lng: -72.55866,
      city: 'Springfield',
      state: 'MA',
    },
    '01109': {
      lat: 42.11839,
      lng: -72.54876,
      city: 'Springfield',
      state: 'MA',
    },
    '01118': {
      lat: 42.09476,
      lng: -72.5253,
      city: 'Springfield',
      state: 'MA',
    },
    '01119': {
      lat: 42.12452,
      lng: -72.51126,
      city: 'Springfield',
      state: 'MA',
    },
    '01128': {
      lat: 42.09497,
      lng: -72.48806,
      city: 'Springfield',
      state: 'MA',
    },
    '01129': {
      lat: 42.1211,
      lng: -72.4877,
      city: 'Springfield',
      state: 'MA',
    },
    '01144': {
      lat: 42.10189,
      lng: -72.59091,
      city: 'Springfield',
      state: 'MA',
    },
    '01151': {
      lat: 42.15294,
      lng: -72.50883,
      city: 'Indian Orchard',
      state: 'MA',
    },
    '01201': {
      lat: 42.45614,
      lng: -73.28114,
      city: 'Pittsfield',
      state: 'MA',
    },
    '01220': {
      lat: 42.62686,
      lng: -73.11873,
      city: 'Adams',
      state: 'MA',
    },
    '01222': {
      lat: 42.06242,
      lng: -73.32146,
      city: 'Ashley Falls',
      state: 'MA',
    },
    '01223': {
      lat: 42.31118,
      lng: -73.09835,
      city: 'Becket',
      state: 'MA',
    },
    '01224': {
      lat: 42.50622,
      lng: -73.19841,
      city: 'Berkshire',
      state: 'MA',
    },
    '01225': {
      lat: 42.56385,
      lng: -73.1512,
      city: 'Cheshire',
      state: 'MA',
    },
    '01226': {
      lat: 42.47954,
      lng: -73.15329,
      city: 'Dalton',
      state: 'MA',
    },
    '01229': {
      lat: 42.29411,
      lng: -73.35227,
      city: 'Glendale',
      state: 'MA',
    },
    '01230': {
      lat: 42.18197,
      lng: -73.34244,
      city: 'Great Barrington',
      state: 'MA',
    },
    '01235': {
      lat: 42.43181,
      lng: -73.07336,
      city: 'Hinsdale',
      state: 'MA',
    },
    '01236': {
      lat: 42.26923,
      lng: -73.37999,
      city: 'Housatonic',
      state: 'MA',
    },
    '01237': {
      lat: 42.5529,
      lng: -73.25373,
      city: 'Lanesborough',
      state: 'MA',
    },
    '01238': { lat: 42.30072, lng: -73.22553, city: 'Lee', state: 'MA' },
    '01240': {
      lat: 42.37567,
      lng: -73.26466,
      city: 'Lenox',
      state: 'MA',
    },
    '01242': {
      lat: 42.3334,
      lng: -73.24967,
      city: 'Lenox Dale',
      state: 'MA',
    },
    '01243': {
      lat: 42.34752,
      lng: -73.03467,
      city: 'Middlefield',
      state: 'MA',
    },
    '01244': {
      lat: 42.10924,
      lng: -73.27475,
      city: 'Mill River',
      state: 'MA',
    },
    '01245': {
      lat: 42.19043,
      lng: -73.21579,
      city: 'Monterey',
      state: 'MA',
    },
    '01247': {
      lat: 42.69813,
      lng: -73.09264,
      city: 'North Adams',
      state: 'MA',
    },
    '01253': { lat: 42.20751, lng: -73.1118, city: 'Otis', state: 'MA' },
    '01254': {
      lat: 42.37972,
      lng: -73.36608,
      city: 'Richmond',
      state: 'MA',
    },
    '01255': {
      lat: 42.1068,
      lng: -73.12213,
      city: 'Sandisfield',
      state: 'MA',
    },
    '01256': {
      lat: 42.59627,
      lng: -73.01958,
      city: 'Savoy',
      state: 'MA',
    },
    '01257': {
      lat: 42.0976,
      lng: -73.37241,
      city: 'Sheffield',
      state: 'MA',
    },
    '01258': {
      lat: 42.09396,
      lng: -73.46616,
      city: 'South Egremont',
      state: 'MA',
    },
    '01259': {
      lat: 42.07316,
      lng: -73.23282,
      city: 'Southfield',
      state: 'MA',
    },
    '01260': {
      lat: 42.26663,
      lng: -73.2712,
      city: 'South Lee',
      state: 'MA',
    },
    '01262': {
      lat: 42.30433,
      lng: -73.32013,
      city: 'Stockbridge',
      state: 'MA',
    },
    '01264': {
      lat: 42.23997,
      lng: -73.19686,
      city: 'Tyringham',
      state: 'MA',
    },
    '01266': {
      lat: 42.30264,
      lng: -73.39846,
      city: 'West Stockbridge',
      state: 'MA',
    },
    '01267': {
      lat: 42.66593,
      lng: -73.24209,
      city: 'Williamstown',
      state: 'MA',
    },
    '01270': {
      lat: 42.51297,
      lng: -73.04859,
      city: 'Windsor',
      state: 'MA',
    },
    '01301': {
      lat: 42.62375,
      lng: -72.60379,
      city: 'Greenfield',
      state: 'MA',
    },
    '01330': {
      lat: 42.51772,
      lng: -72.81556,
      city: 'Ashfield',
      state: 'MA',
    },
    '01331': {
      lat: 42.56793,
      lng: -72.18324,
      city: 'Athol',
      state: 'MA',
    },
    '01337': {
      lat: 42.69512,
      lng: -72.57731,
      city: 'Bernardston',
      state: 'MA',
    },
    '01338': {
      lat: 42.5814,
      lng: -72.80309,
      city: 'Buckland',
      state: 'MA',
    },
    '01339': {
      lat: 42.61099,
      lng: -72.88846,
      city: 'Charlemont',
      state: 'MA',
    },
    '01340': {
      lat: 42.68805,
      lng: -72.73205,
      city: 'Colrain',
      state: 'MA',
    },
    '01341': {
      lat: 42.50734,
      lng: -72.70221,
      city: 'Conway',
      state: 'MA',
    },
    '01342': {
      lat: 42.54384,
      lng: -72.6058,
      city: 'Deerfield',
      state: 'MA',
    },
    '01343': {
      lat: 42.66766,
      lng: -72.98822,
      city: 'Drury',
      state: 'MA',
    },
    '01344': {
      lat: 42.60756,
      lng: -72.42916,
      city: 'Erving',
      state: 'MA',
    },
    '01346': { lat: 42.6706, lng: -72.81323, city: 'Heath', state: 'MA' },
    '01347': {
      lat: 42.56027,
      lng: -72.51321,
      city: 'Lake Pleasant',
      state: 'MA',
    },
    '01349': {
      lat: 42.56656,
      lng: -72.47045,
      city: 'Millers Falls',
      state: 'MA',
    },
    '01350': {
      lat: 42.72259,
      lng: -72.98291,
      city: 'Monroe Bridge',
      state: 'MA',
    },
    '01351': {
      lat: 42.54162,
      lng: -72.52069,
      city: 'Montague',
      state: 'MA',
    },
    '01354': { lat: 42.63282, lng: -72.51136, city: 'Gill', state: 'MA' },
    '01355': {
      lat: 42.45101,
      lng: -72.33141,
      city: 'New Salem',
      state: 'MA',
    },
    '01360': {
      lat: 42.67575,
      lng: -72.446,
      city: 'Northfield',
      state: 'MA',
    },
    '01364': {
      lat: 42.60449,
      lng: -72.29263,
      city: 'Orange',
      state: 'MA',
    },
    '01366': {
      lat: 42.48858,
      lng: -72.18564,
      city: 'Petersham',
      state: 'MA',
    },
    '01367': { lat: 42.69674, lng: -72.91387, city: 'Rowe', state: 'MA' },
    '01368': {
      lat: 42.67815,
      lng: -72.18557,
      city: 'Royalston',
      state: 'MA',
    },
    '01370': {
      lat: 42.59607,
      lng: -72.72915,
      city: 'Shelburne Falls',
      state: 'MA',
    },
    '01373': {
      lat: 42.47292,
      lng: -72.61516,
      city: 'South Deerfield',
      state: 'MA',
    },
    '01375': {
      lat: 42.46475,
      lng: -72.55489,
      city: 'Sunderland',
      state: 'MA',
    },
    '01376': {
      lat: 42.59273,
      lng: -72.54591,
      city: 'Turners Falls',
      state: 'MA',
    },
    '01378': {
      lat: 42.67711,
      lng: -72.34057,
      city: 'Warwick',
      state: 'MA',
    },
    '01379': {
      lat: 42.54149,
      lng: -72.3989,
      city: 'Wendell',
      state: 'MA',
    },
    '01380': {
      lat: 42.58675,
      lng: -72.40264,
      city: 'Wendell Depot',
      state: 'MA',
    },
    '01420': {
      lat: 42.58559,
      lng: -71.81693,
      city: 'Fitchburg',
      state: 'MA',
    },
    '01430': {
      lat: 42.66069,
      lng: -71.9232,
      city: 'Ashburnham',
      state: 'MA',
    },
    '01431': {
      lat: 42.67652,
      lng: -71.82322,
      city: 'Ashby',
      state: 'MA',
    },
    '01432': { lat: 42.56351, lng: -71.57059, city: 'Ayer', state: 'MA' },
    '01434': {
      lat: 42.53149,
      lng: -71.61663,
      city: 'Devens',
      state: 'MA',
    },
    '01436': {
      lat: 42.60579,
      lng: -72.08958,
      city: 'Baldwinville',
      state: 'MA',
    },
    '01438': {
      lat: 42.56028,
      lng: -72.03583,
      city: 'East Templeton',
      state: 'MA',
    },
    '01440': {
      lat: 42.58797,
      lng: -71.98649,
      city: 'Gardner',
      state: 'MA',
    },
    '01450': {
      lat: 42.61368,
      lng: -71.56139,
      city: 'Groton',
      state: 'MA',
    },
    '01451': {
      lat: 42.50005,
      lng: -71.57754,
      city: 'Harvard',
      state: 'MA',
    },
    '01452': {
      lat: 42.48378,
      lng: -72.00514,
      city: 'Hubbardston',
      state: 'MA',
    },
    '01453': {
      lat: 42.51961,
      lng: -71.76583,
      city: 'Leominster',
      state: 'MA',
    },
    '01460': {
      lat: 42.53503,
      lng: -71.4891,
      city: 'Littleton',
      state: 'MA',
    },
    '01462': {
      lat: 42.58994,
      lng: -71.72036,
      city: 'Lunenburg',
      state: 'MA',
    },
    '01463': {
      lat: 42.67126,
      lng: -71.60429,
      city: 'Pepperell',
      state: 'MA',
    },
    '01464': {
      lat: 42.57411,
      lng: -71.6475,
      city: 'Shirley',
      state: 'MA',
    },
    '01468': {
      lat: 42.55324,
      lng: -72.06879,
      city: 'Templeton',
      state: 'MA',
    },
    '01469': {
      lat: 42.66514,
      lng: -71.69161,
      city: 'Townsend',
      state: 'MA',
    },
    '01473': {
      lat: 42.55122,
      lng: -71.90266,
      city: 'Westminster',
      state: 'MA',
    },
    '01474': {
      lat: 42.67088,
      lng: -71.7508,
      city: 'West Townsend',
      state: 'MA',
    },
    '01475': {
      lat: 42.66675,
      lng: -72.04902,
      city: 'Winchendon',
      state: 'MA',
    },
    '01501': {
      lat: 42.19689,
      lng: -71.84549,
      city: 'Auburn',
      state: 'MA',
    },
    '01503': {
      lat: 42.38378,
      lng: -71.63552,
      city: 'Berlin',
      state: 'MA',
    },
    '01504': {
      lat: 42.0399,
      lng: -71.53125,
      city: 'Blackstone',
      state: 'MA',
    },
    '01505': {
      lat: 42.35633,
      lng: -71.72123,
      city: 'Boylston',
      state: 'MA',
    },
    '01506': {
      lat: 42.19364,
      lng: -72.10441,
      city: 'Brookfield',
      state: 'MA',
    },
    '01507': {
      lat: 42.13509,
      lng: -71.96803,
      city: 'Charlton',
      state: 'MA',
    },
    '01510': {
      lat: 42.4119,
      lng: -71.68883,
      city: 'Clinton',
      state: 'MA',
    },
    '01515': {
      lat: 42.20328,
      lng: -72.04669,
      city: 'East Brookfield',
      state: 'MA',
    },
    '01516': {
      lat: 42.0528,
      lng: -71.75106,
      city: 'Douglas',
      state: 'MA',
    },
    '01518': {
      lat: 42.11342,
      lng: -72.11632,
      city: 'Fiskdale',
      state: 'MA',
    },
    '01519': {
      lat: 42.20077,
      lng: -71.67892,
      city: 'Grafton',
      state: 'MA',
    },
    '01520': {
      lat: 42.33685,
      lng: -71.85181,
      city: 'Holden',
      state: 'MA',
    },
    '01521': {
      lat: 42.06012,
      lng: -72.16592,
      city: 'Holland',
      state: 'MA',
    },
    '01522': {
      lat: 42.38142,
      lng: -71.87111,
      city: 'Jefferson',
      state: 'MA',
    },
    '01523': {
      lat: 42.48176,
      lng: -71.68079,
      city: 'Lancaster',
      state: 'MA',
    },
    '01524': {
      lat: 42.24919,
      lng: -71.91989,
      city: 'Leicester',
      state: 'MA',
    },
    '01525': {
      lat: 42.09899,
      lng: -71.64296,
      city: 'Linwood',
      state: 'MA',
    },
    '01527': {
      lat: 42.19228,
      lng: -71.77397,
      city: 'Millbury',
      state: 'MA',
    },
    '01529': {
      lat: 42.03906,
      lng: -71.57767,
      city: 'Millville',
      state: 'MA',
    },
    '01531': {
      lat: 42.31929,
      lng: -72.12794,
      city: 'New Braintree',
      state: 'MA',
    },
    '01532': {
      lat: 42.32314,
      lng: -71.64621,
      city: 'Northborough',
      state: 'MA',
    },
    '01534': {
      lat: 42.13991,
      lng: -71.64433,
      city: 'Northbridge',
      state: 'MA',
    },
    '01535': {
      lat: 42.2695,
      lng: -72.07794,
      city: 'North Brookfield',
      state: 'MA',
    },
    '01536': {
      lat: 42.23189,
      lng: -71.69219,
      city: 'North Grafton',
      state: 'MA',
    },
    '01537': {
      lat: 42.16177,
      lng: -71.89217,
      city: 'North Oxford',
      state: 'MA',
    },
    '01540': {
      lat: 42.11649,
      lng: -71.85819,
      city: 'Oxford',
      state: 'MA',
    },
    '01541': {
      lat: 42.45649,
      lng: -71.88128,
      city: 'Princeton',
      state: 'MA',
    },
    '01542': {
      lat: 42.20241,
      lng: -71.90995,
      city: 'Rochdale',
      state: 'MA',
    },
    '01543': {
      lat: 42.38482,
      lng: -71.96726,
      city: 'Rutland',
      state: 'MA',
    },
    '01545': {
      lat: 42.28419,
      lng: -71.71558,
      city: 'Shrewsbury',
      state: 'MA',
    },
    '01550': {
      lat: 42.06039,
      lng: -72.03377,
      city: 'Southbridge',
      state: 'MA',
    },
    '01560': {
      lat: 42.17496,
      lng: -71.67762,
      city: 'South Grafton',
      state: 'MA',
    },
    '01561': {
      lat: 42.44581,
      lng: -71.68845,
      city: 'South Lancaster',
      state: 'MA',
    },
    '01562': {
      lat: 42.24715,
      lng: -71.99189,
      city: 'Spencer',
      state: 'MA',
    },
    '01564': {
      lat: 42.44124,
      lng: -71.77297,
      city: 'Sterling',
      state: 'MA',
    },
    '01566': {
      lat: 42.1047,
      lng: -72.07745,
      city: 'Sturbridge',
      state: 'MA',
    },
    '01568': {
      lat: 42.17694,
      lng: -71.60516,
      city: 'Upton',
      state: 'MA',
    },
    '01569': {
      lat: 42.05895,
      lng: -71.63741,
      city: 'Uxbridge',
      state: 'MA',
    },
    '01570': {
      lat: 42.05206,
      lng: -71.8485,
      city: 'Webster',
      state: 'MA',
    },
    '01571': {
      lat: 42.05501,
      lng: -71.93519,
      city: 'Dudley',
      state: 'MA',
    },
    '01581': {
      lat: 42.26815,
      lng: -71.61402,
      city: 'Westborough',
      state: 'MA',
    },
    '01583': {
      lat: 42.37034,
      lng: -71.78502,
      city: 'West Boylston',
      state: 'MA',
    },
    '01585': {
      lat: 42.23762,
      lng: -72.16311,
      city: 'West Brookfield',
      state: 'MA',
    },
    '01588': {
      lat: 42.11837,
      lng: -71.66836,
      city: 'Whitinsville',
      state: 'MA',
    },
    '01590': {
      lat: 42.13387,
      lng: -71.75179,
      city: 'Sutton',
      state: 'MA',
    },
    '01602': {
      lat: 42.27219,
      lng: -71.84946,
      city: 'Worcester',
      state: 'MA',
    },
    '01603': {
      lat: 42.24373,
      lng: -71.84429,
      city: 'Worcester',
      state: 'MA',
    },
    '01604': {
      lat: 42.25085,
      lng: -71.7683,
      city: 'Worcester',
      state: 'MA',
    },
    '01605': {
      lat: 42.28979,
      lng: -71.78802,
      city: 'Worcester',
      state: 'MA',
    },
    '01606': {
      lat: 42.31367,
      lng: -71.79614,
      city: 'Worcester',
      state: 'MA',
    },
    '01607': {
      lat: 42.22684,
      lng: -71.79037,
      city: 'Worcester',
      state: 'MA',
    },
    '01608': {
      lat: 42.2621,
      lng: -71.80145,
      city: 'Worcester',
      state: 'MA',
    },
    '01609': {
      lat: 42.28592,
      lng: -71.82853,
      city: 'Worcester',
      state: 'MA',
    },
    '01610': {
      lat: 42.24645,
      lng: -71.80968,
      city: 'Worcester',
      state: 'MA',
    },
    '01611': {
      lat: 42.23945,
      lng: -71.87702,
      city: 'Cherry Valley',
      state: 'MA',
    },
    '01612': {
      lat: 42.31037,
      lng: -71.93095,
      city: 'Paxton',
      state: 'MA',
    },
    '01701': {
      lat: 42.32195,
      lng: -71.43796,
      city: 'Framingham',
      state: 'MA',
    },
    '01702': {
      lat: 42.28242,
      lng: -71.43428,
      city: 'Framingham',
      state: 'MA',
    },
    '01718': {
      lat: 42.52165,
      lng: -71.42983,
      city: 'Acton',
      state: 'MA',
    },
    '01719': {
      lat: 42.48837,
      lng: -71.51779,
      city: 'Boxborough',
      state: 'MA',
    },
    '01720': {
      lat: 42.48397,
      lng: -71.43809,
      city: 'Acton',
      state: 'MA',
    },
    '01721': {
      lat: 42.25726,
      lng: -71.46867,
      city: 'Ashland',
      state: 'MA',
    },
    '01730': {
      lat: 42.4995,
      lng: -71.27799,
      city: 'Bedford',
      state: 'MA',
    },
    '01731': {
      lat: 42.46422,
      lng: -71.28202,
      city: 'Hanscom Afb',
      state: 'MA',
    },
    '01740': {
      lat: 42.43616,
      lng: -71.60728,
      city: 'Bolton',
      state: 'MA',
    },
    '01741': {
      lat: 42.53001,
      lng: -71.35128,
      city: 'Carlisle',
      state: 'MA',
    },
    '01742': {
      lat: 42.46221,
      lng: -71.36504,
      city: 'Concord',
      state: 'MA',
    },
    '01745': {
      lat: 42.29155,
      lng: -71.50101,
      city: 'Fayville',
      state: 'MA',
    },
    '01746': {
      lat: 42.19775,
      lng: -71.44499,
      city: 'Holliston',
      state: 'MA',
    },
    '01747': {
      lat: 42.12477,
      lng: -71.535,
      city: 'Hopedale',
      state: 'MA',
    },
    '01748': {
      lat: 42.2255,
      lng: -71.53781,
      city: 'Hopkinton',
      state: 'MA',
    },
    '01749': {
      lat: 42.38868,
      lng: -71.54648,
      city: 'Hudson',
      state: 'MA',
    },
    '01752': {
      lat: 42.3494,
      lng: -71.54681,
      city: 'Marlborough',
      state: 'MA',
    },
    '01754': {
      lat: 42.4264,
      lng: -71.45607,
      city: 'Maynard',
      state: 'MA',
    },
    '01756': {
      lat: 42.09301,
      lng: -71.55146,
      city: 'Mendon',
      state: 'MA',
    },
    '01757': {
      lat: 42.15723,
      lng: -71.51978,
      city: 'Milford',
      state: 'MA',
    },
    '01760': {
      lat: 42.28436,
      lng: -71.34966,
      city: 'Natick',
      state: 'MA',
    },
    '01770': {
      lat: 42.23416,
      lng: -71.37532,
      city: 'Sherborn',
      state: 'MA',
    },
    '01772': {
      lat: 42.30144,
      lng: -71.5304,
      city: 'Southborough',
      state: 'MA',
    },
    '01773': {
      lat: 42.42572,
      lng: -71.31013,
      city: 'Lincoln',
      state: 'MA',
    },
    '01775': { lat: 42.42983, lng: -71.51146, city: 'Stow', state: 'MA' },
    '01776': {
      lat: 42.38456,
      lng: -71.42316,
      city: 'Sudbury',
      state: 'MA',
    },
    '01778': {
      lat: 42.3583,
      lng: -71.35895,
      city: 'Wayland',
      state: 'MA',
    },
    '01801': {
      lat: 42.48694,
      lng: -71.15415,
      city: 'Woburn',
      state: 'MA',
    },
    '01803': {
      lat: 42.5028,
      lng: -71.20147,
      city: 'Burlington',
      state: 'MA',
    },
    '01810': {
      lat: 42.64669,
      lng: -71.16517,
      city: 'Andover',
      state: 'MA',
    },
    '01821': {
      lat: 42.54999,
      lng: -71.25206,
      city: 'Billerica',
      state: 'MA',
    },
    '01824': {
      lat: 42.59156,
      lng: -71.35553,
      city: 'Chelmsford',
      state: 'MA',
    },
    '01826': {
      lat: 42.68323,
      lng: -71.30063,
      city: 'Dracut',
      state: 'MA',
    },
    '01827': {
      lat: 42.67643,
      lng: -71.50052,
      city: 'Dunstable',
      state: 'MA',
    },
    '01830': {
      lat: 42.7959,
      lng: -71.05323,
      city: 'Haverhill',
      state: 'MA',
    },
    '01832': {
      lat: 42.79099,
      lng: -71.13133,
      city: 'Haverhill',
      state: 'MA',
    },
    '01833': {
      lat: 42.72409,
      lng: -70.9821,
      city: 'Georgetown',
      state: 'MA',
    },
    '01834': {
      lat: 42.75198,
      lng: -71.01486,
      city: 'Groveland',
      state: 'MA',
    },
    '01835': {
      lat: 42.75292,
      lng: -71.08602,
      city: 'Haverhill',
      state: 'MA',
    },
    '01840': {
      lat: 42.70695,
      lng: -71.16014,
      city: 'Lawrence',
      state: 'MA',
    },
    '01841': {
      lat: 42.71077,
      lng: -71.16494,
      city: 'Lawrence',
      state: 'MA',
    },
    '01843': {
      lat: 42.68985,
      lng: -71.16095,
      city: 'Lawrence',
      state: 'MA',
    },
    '01844': {
      lat: 42.73426,
      lng: -71.18858,
      city: 'Methuen',
      state: 'MA',
    },
    '01845': {
      lat: 42.67132,
      lng: -71.08669,
      city: 'North Andover',
      state: 'MA',
    },
    '01850': {
      lat: 42.65569,
      lng: -71.30292,
      city: 'Lowell',
      state: 'MA',
    },
    '01851': {
      lat: 42.62915,
      lng: -71.33674,
      city: 'Lowell',
      state: 'MA',
    },
    '01852': {
      lat: 42.63231,
      lng: -71.29538,
      city: 'Lowell',
      state: 'MA',
    },
    '01854': {
      lat: 42.64939,
      lng: -71.34827,
      city: 'Lowell',
      state: 'MA',
    },
    '01860': {
      lat: 42.83858,
      lng: -71.01194,
      city: 'Merrimac',
      state: 'MA',
    },
    '01862': {
      lat: 42.57427,
      lng: -71.29437,
      city: 'North Billerica',
      state: 'MA',
    },
    '01863': {
      lat: 42.63274,
      lng: -71.39132,
      city: 'North Chelmsford',
      state: 'MA',
    },
    '01864': {
      lat: 42.58156,
      lng: -71.08759,
      city: 'North Reading',
      state: 'MA',
    },
    '01867': {
      lat: 42.53505,
      lng: -71.1055,
      city: 'Reading',
      state: 'MA',
    },
    '01876': {
      lat: 42.61206,
      lng: -71.22733,
      city: 'Tewksbury',
      state: 'MA',
    },
    '01879': {
      lat: 42.66626,
      lng: -71.42911,
      city: 'Tyngsboro',
      state: 'MA',
    },
    '01880': {
      lat: 42.50348,
      lng: -71.06559,
      city: 'Wakefield',
      state: 'MA',
    },
    '01886': {
      lat: 42.58642,
      lng: -71.44008,
      city: 'Westford',
      state: 'MA',
    },
    '01887': {
      lat: 42.56091,
      lng: -71.16541,
      city: 'Wilmington',
      state: 'MA',
    },
    '01890': {
      lat: 42.45177,
      lng: -71.14628,
      city: 'Winchester',
      state: 'MA',
    },
    '01901': { lat: 42.46125, lng: -70.94639, city: 'Lynn', state: 'MA' },
    '01902': { lat: 42.4712, lng: -70.93983, city: 'Lynn', state: 'MA' },
    '01904': { lat: 42.49151, lng: -70.97166, city: 'Lynn', state: 'MA' },
    '01905': { lat: 42.46355, lng: -70.9733, city: 'Lynn', state: 'MA' },
    '01906': {
      lat: 42.46922,
      lng: -71.01299,
      city: 'Saugus',
      state: 'MA',
    },
    '01907': {
      lat: 42.47572,
      lng: -70.90684,
      city: 'Swampscott',
      state: 'MA',
    },
    '01908': {
      lat: 42.42856,
      lng: -70.92614,
      city: 'Nahant',
      state: 'MA',
    },
    '01913': {
      lat: 42.85297,
      lng: -70.94459,
      city: 'Amesbury',
      state: 'MA',
    },
    '01915': {
      lat: 42.56841,
      lng: -70.8647,
      city: 'Beverly',
      state: 'MA',
    },
    '01921': {
      lat: 42.6815,
      lng: -71.01887,
      city: 'Boxford',
      state: 'MA',
    },
    '01922': {
      lat: 42.75862,
      lng: -70.91758,
      city: 'Byfield',
      state: 'MA',
    },
    '01923': {
      lat: 42.57398,
      lng: -70.94923,
      city: 'Danvers',
      state: 'MA',
    },
    '01929': {
      lat: 42.63478,
      lng: -70.77684,
      city: 'Essex',
      state: 'MA',
    },
    '01930': {
      lat: 42.62605,
      lng: -70.68966,
      city: 'Gloucester',
      state: 'MA',
    },
    '01937': {
      lat: 42.58579,
      lng: -70.98406,
      city: 'Hathorne',
      state: 'MA',
    },
    '01938': {
      lat: 42.68568,
      lng: -70.83989,
      city: 'Ipswich',
      state: 'MA',
    },
    '01940': {
      lat: 42.53523,
      lng: -71.03695,
      city: 'Lynnfield',
      state: 'MA',
    },
    '01944': {
      lat: 42.58154,
      lng: -70.76825,
      city: 'Manchester',
      state: 'MA',
    },
    '01945': {
      lat: 42.49924,
      lng: -70.86348,
      city: 'Marblehead',
      state: 'MA',
    },
    '01949': {
      lat: 42.6043,
      lng: -71.01641,
      city: 'Middleton',
      state: 'MA',
    },
    '01950': {
      lat: 42.81237,
      lng: -70.88785,
      city: 'Newburyport',
      state: 'MA',
    },
    '01951': {
      lat: 42.77742,
      lng: -70.85022,
      city: 'Newbury',
      state: 'MA',
    },
    '01952': {
      lat: 42.84655,
      lng: -70.86163,
      city: 'Salisbury',
      state: 'MA',
    },
    '01960': {
      lat: 42.53374,
      lng: -70.97222,
      city: 'Peabody',
      state: 'MA',
    },
    '01965': {
      lat: 42.56294,
      lng: -70.82996,
      city: 'Prides Crossing',
      state: 'MA',
    },
    '01966': {
      lat: 42.65432,
      lng: -70.62227,
      city: 'Rockport',
      state: 'MA',
    },
    '01969': {
      lat: 42.72235,
      lng: -70.88832,
      city: 'Rowley',
      state: 'MA',
    },
    '01970': {
      lat: 42.51298,
      lng: -70.90266,
      city: 'Salem',
      state: 'MA',
    },
    '01982': {
      lat: 42.62669,
      lng: -70.85789,
      city: 'South Hamilton',
      state: 'MA',
    },
    '01983': {
      lat: 42.63728,
      lng: -70.94251,
      city: 'Topsfield',
      state: 'MA',
    },
    '01984': {
      lat: 42.60078,
      lng: -70.8826,
      city: 'Wenham',
      state: 'MA',
    },
    '01985': {
      lat: 42.7939,
      lng: -70.96906,
      city: 'West Newbury',
      state: 'MA',
    },
    '02019': {
      lat: 42.0777,
      lng: -71.47407,
      city: 'Bellingham',
      state: 'MA',
    },
    '02021': {
      lat: 42.17504,
      lng: -71.12645,
      city: 'Canton',
      state: 'MA',
    },
    '02025': {
      lat: 42.23603,
      lng: -70.81906,
      city: 'Cohasset',
      state: 'MA',
    },
    '02026': {
      lat: 42.24665,
      lng: -71.1777,
      city: 'Dedham',
      state: 'MA',
    },
    '02030': { lat: 42.2366, lng: -71.28421, city: 'Dover', state: 'MA' },
    '02032': {
      lat: 42.15489,
      lng: -71.2155,
      city: 'East Walpole',
      state: 'MA',
    },
    '02035': {
      lat: 42.06266,
      lng: -71.24613,
      city: 'Foxboro',
      state: 'MA',
    },
    '02038': {
      lat: 42.08622,
      lng: -71.41126,
      city: 'Franklin',
      state: 'MA',
    },
    '02043': {
      lat: 42.21678,
      lng: -70.88494,
      city: 'Hingham',
      state: 'MA',
    },
    '02045': { lat: 42.28437, lng: -70.88266, city: 'Hull', state: 'MA' },
    '02047': {
      lat: 42.14582,
      lng: -70.69603,
      city: 'Humarock',
      state: 'MA',
    },
    '02048': {
      lat: 42.01626,
      lng: -71.21868,
      city: 'Mansfield',
      state: 'MA',
    },
    '02050': {
      lat: 42.11443,
      lng: -70.71487,
      city: 'Marshfield',
      state: 'MA',
    },
    '02052': {
      lat: 42.1848,
      lng: -71.30501,
      city: 'Medfield',
      state: 'MA',
    },
    '02053': {
      lat: 42.15352,
      lng: -71.42906,
      city: 'Medway',
      state: 'MA',
    },
    '02054': {
      lat: 42.1693,
      lng: -71.36261,
      city: 'Millis',
      state: 'MA',
    },
    '02056': {
      lat: 42.11632,
      lng: -71.32952,
      city: 'Norfolk',
      state: 'MA',
    },
    '02061': {
      lat: 42.16075,
      lng: -70.81772,
      city: 'Norwell',
      state: 'MA',
    },
    '02062': {
      lat: 42.18563,
      lng: -71.19464,
      city: 'Norwood',
      state: 'MA',
    },
    '02066': {
      lat: 42.20129,
      lng: -70.76155,
      city: 'Scituate',
      state: 'MA',
    },
    '02067': {
      lat: 42.10778,
      lng: -71.18183,
      city: 'Sharon',
      state: 'MA',
    },
    '02071': {
      lat: 42.10392,
      lng: -71.27002,
      city: 'South Walpole',
      state: 'MA',
    },
    '02072': {
      lat: 42.1192,
      lng: -71.10189,
      city: 'Stoughton',
      state: 'MA',
    },
    '02081': {
      lat: 42.14689,
      lng: -71.25916,
      city: 'Walpole',
      state: 'MA',
    },
    '02090': {
      lat: 42.22024,
      lng: -71.21057,
      city: 'Westwood',
      state: 'MA',
    },
    '02093': {
      lat: 42.05129,
      lng: -71.35522,
      city: 'Wrentham',
      state: 'MA',
    },
    '02108': {
      lat: 42.35657,
      lng: -71.06516,
      city: 'Boston',
      state: 'MA',
    },
    '02109': {
      lat: 42.36498,
      lng: -71.05277,
      city: 'Boston',
      state: 'MA',
    },
    '02110': {
      lat: 42.35797,
      lng: -71.05189,
      city: 'Boston',
      state: 'MA',
    },
    '02111': {
      lat: 42.35067,
      lng: -71.06054,
      city: 'Boston',
      state: 'MA',
    },
    '02113': {
      lat: 42.36526,
      lng: -71.05524,
      city: 'Boston',
      state: 'MA',
    },
    '02114': {
      lat: 42.36337,
      lng: -71.06686,
      city: 'Boston',
      state: 'MA',
    },
    '02115': {
      lat: 42.34046,
      lng: -71.09481,
      city: 'Boston',
      state: 'MA',
    },
    '02116': {
      lat: 42.35105,
      lng: -71.07638,
      city: 'Boston',
      state: 'MA',
    },
    '02118': {
      lat: 42.33806,
      lng: -71.07057,
      city: 'Boston',
      state: 'MA',
    },
    '02119': {
      lat: 42.32405,
      lng: -71.08522,
      city: 'Roxbury',
      state: 'MA',
    },
    '02120': {
      lat: 42.33212,
      lng: -71.09654,
      city: 'Roxbury Crossing',
      state: 'MA',
    },
    '02121': {
      lat: 42.3057,
      lng: -71.08693,
      city: 'Dorchester',
      state: 'MA',
    },
    '02122': {
      lat: 42.29427,
      lng: -71.05185,
      city: 'Dorchester',
      state: 'MA',
    },
    '02124': {
      lat: 42.28583,
      lng: -71.07114,
      city: 'Dorchester Center',
      state: 'MA',
    },
    '02125': {
      lat: 42.31578,
      lng: -71.0562,
      city: 'Dorchester',
      state: 'MA',
    },
    '02126': {
      lat: 42.27588,
      lng: -71.0918,
      city: 'Mattapan',
      state: 'MA',
    },
    '02127': {
      lat: 42.33455,
      lng: -71.03875,
      city: 'South Boston',
      state: 'MA',
    },
    '02128': {
      lat: 42.37324,
      lng: -71.01616,
      city: 'East Boston',
      state: 'MA',
    },
    '02129': {
      lat: 42.37975,
      lng: -71.06167,
      city: 'Charlestown',
      state: 'MA',
    },
    '02130': {
      lat: 42.30823,
      lng: -71.11515,
      city: 'Jamaica Plain',
      state: 'MA',
    },
    '02131': {
      lat: 42.28392,
      lng: -71.12468,
      city: 'Roslindale',
      state: 'MA',
    },
    '02132': {
      lat: 42.27931,
      lng: -71.16072,
      city: 'West Roxbury',
      state: 'MA',
    },
    '02133': {
      lat: 42.35838,
      lng: -71.06383,
      city: 'Boston',
      state: 'MA',
    },
    '02134': {
      lat: 42.35855,
      lng: -71.12931,
      city: 'Allston',
      state: 'MA',
    },
    '02135': {
      lat: 42.34989,
      lng: -71.15417,
      city: 'Brighton',
      state: 'MA',
    },
    '02136': {
      lat: 42.25505,
      lng: -71.12662,
      city: 'Hyde Park',
      state: 'MA',
    },
    '02138': {
      lat: 42.37997,
      lng: -71.13446,
      city: 'Cambridge',
      state: 'MA',
    },
    '02139': {
      lat: 42.36224,
      lng: -71.10253,
      city: 'Cambridge',
      state: 'MA',
    },
    '02140': {
      lat: 42.39332,
      lng: -71.13342,
      city: 'Cambridge',
      state: 'MA',
    },
    '02141': {
      lat: 42.37041,
      lng: -71.08218,
      city: 'Cambridge',
      state: 'MA',
    },
    '02142': {
      lat: 42.36295,
      lng: -71.08189,
      city: 'Cambridge',
      state: 'MA',
    },
    '02143': {
      lat: 42.38142,
      lng: -71.09677,
      city: 'Somerville',
      state: 'MA',
    },
    '02144': {
      lat: 42.40088,
      lng: -71.122,
      city: 'Somerville',
      state: 'MA',
    },
    '02145': {
      lat: 42.39206,
      lng: -71.0895,
      city: 'Somerville',
      state: 'MA',
    },
    '02148': {
      lat: 42.43048,
      lng: -71.05762,
      city: 'Malden',
      state: 'MA',
    },
    '02149': {
      lat: 42.40643,
      lng: -71.05446,
      city: 'Everett',
      state: 'MA',
    },
    '02150': {
      lat: 42.39587,
      lng: -71.03251,
      city: 'Chelsea',
      state: 'MA',
    },
    '02151': { lat: 42.41887, lng: -71.004, city: 'Revere', state: 'MA' },
    '02152': {
      lat: 42.37724,
      lng: -70.98115,
      city: 'Winthrop',
      state: 'MA',
    },
    '02155': {
      lat: 42.42324,
      lng: -71.10878,
      city: 'Medford',
      state: 'MA',
    },
    '02163': {
      lat: 42.36717,
      lng: -71.12509,
      city: 'Boston',
      state: 'MA',
    },
    '02169': {
      lat: 42.23971,
      lng: -71.01802,
      city: 'Quincy',
      state: 'MA',
    },
    '02170': {
      lat: 42.26644,
      lng: -71.01621,
      city: 'Quincy',
      state: 'MA',
    },
    '02171': {
      lat: 42.28709,
      lng: -71.02604,
      city: 'Quincy',
      state: 'MA',
    },
    '02176': {
      lat: 42.45561,
      lng: -71.05901,
      city: 'Melrose',
      state: 'MA',
    },
    '02180': {
      lat: 42.47396,
      lng: -71.09705,
      city: 'Stoneham',
      state: 'MA',
    },
    '02184': {
      lat: 42.20386,
      lng: -71.0022,
      city: 'Braintree',
      state: 'MA',
    },
    '02186': {
      lat: 42.23854,
      lng: -71.08464,
      city: 'Milton',
      state: 'MA',
    },
    '02188': {
      lat: 42.20724,
      lng: -70.95764,
      city: 'Weymouth',
      state: 'MA',
    },
    '02189': {
      lat: 42.2111,
      lng: -70.93135,
      city: 'East Weymouth',
      state: 'MA',
    },
    '02190': {
      lat: 42.16625,
      lng: -70.95253,
      city: 'South Weymouth',
      state: 'MA',
    },
    '02191': {
      lat: 42.24434,
      lng: -70.94378,
      city: 'North Weymouth',
      state: 'MA',
    },
    '02199': {
      lat: 42.34748,
      lng: -71.08199,
      city: 'Boston',
      state: 'MA',
    },
    '02203': {
      lat: 42.36144,
      lng: -71.05945,
      city: 'Boston',
      state: 'MA',
    },
    '02205': {
      lat: 42.34935,
      lng: -71.05564,
      city: 'Boston',
      state: 'MA',
    },
    '02210': {
      lat: 42.34815,
      lng: -71.03942,
      city: 'Boston',
      state: 'MA',
    },
    '02215': {
      lat: 42.34741,
      lng: -71.10276,
      city: 'Boston',
      state: 'MA',
    },
    '02301': {
      lat: 42.07791,
      lng: -71.04223,
      city: 'Brockton',
      state: 'MA',
    },
    '02302': {
      lat: 42.08792,
      lng: -70.99864,
      city: 'Brockton',
      state: 'MA',
    },
    '02322': { lat: 42.12666, lng: -71.04922, city: 'Avon', state: 'MA' },
    '02324': {
      lat: 41.97272,
      lng: -70.97498,
      city: 'Bridgewater',
      state: 'MA',
    },
    '02325': {
      lat: 41.98875,
      lng: -70.96449,
      city: 'Bridgewater',
      state: 'MA',
    },
    '02330': {
      lat: 41.87388,
      lng: -70.7563,
      city: 'Carver',
      state: 'MA',
    },
    '02332': {
      lat: 42.04651,
      lng: -70.71388,
      city: 'Duxbury',
      state: 'MA',
    },
    '02333': {
      lat: 42.03521,
      lng: -70.94235,
      city: 'East Bridgewater',
      state: 'MA',
    },
    '02338': {
      lat: 41.99138,
      lng: -70.86326,
      city: 'Halifax',
      state: 'MA',
    },
    '02339': {
      lat: 42.12241,
      lng: -70.85665,
      city: 'Hanover',
      state: 'MA',
    },
    '02341': {
      lat: 42.05604,
      lng: -70.87251,
      city: 'Hanson',
      state: 'MA',
    },
    '02343': {
      lat: 42.14733,
      lng: -71.00484,
      city: 'Holbrook',
      state: 'MA',
    },
    '02346': {
      lat: 41.88034,
      lng: -70.87446,
      city: 'Middleboro',
      state: 'MA',
    },
    '02347': {
      lat: 41.83101,
      lng: -70.9594,
      city: 'Lakeville',
      state: 'MA',
    },
    '02350': {
      lat: 42.02043,
      lng: -70.84562,
      city: 'Monponsett',
      state: 'MA',
    },
    '02351': {
      lat: 42.11805,
      lng: -70.95905,
      city: 'Abington',
      state: 'MA',
    },
    '02356': {
      lat: 42.05519,
      lng: -71.12071,
      city: 'North Easton',
      state: 'MA',
    },
    '02357': {
      lat: 42.06224,
      lng: -71.07751,
      city: 'North Easton',
      state: 'MA',
    },
    '02359': {
      lat: 42.06547,
      lng: -70.8014,
      city: 'Pembroke',
      state: 'MA',
    },
    '02360': {
      lat: 41.87817,
      lng: -70.63089,
      city: 'Plymouth',
      state: 'MA',
    },
    '02364': {
      lat: 41.98616,
      lng: -70.74823,
      city: 'Kingston',
      state: 'MA',
    },
    '02366': {
      lat: 41.84868,
      lng: -70.6613,
      city: 'South Carver',
      state: 'MA',
    },
    '02367': {
      lat: 41.96345,
      lng: -70.80975,
      city: 'Plympton',
      state: 'MA',
    },
    '02368': {
      lat: 42.17506,
      lng: -71.05011,
      city: 'Randolph',
      state: 'MA',
    },
    '02370': {
      lat: 42.1295,
      lng: -70.91002,
      city: 'Rockland',
      state: 'MA',
    },
    '02375': {
      lat: 42.01954,
      lng: -71.10381,
      city: 'South Easton',
      state: 'MA',
    },
    '02379': {
      lat: 42.02139,
      lng: -71.0267,
      city: 'West Bridgewater',
      state: 'MA',
    },
    '02382': { lat: 42.08, lng: -70.93993, city: 'Whitman', state: 'MA' },
    '02420': {
      lat: 42.45821,
      lng: -71.21762,
      city: 'Lexington',
      state: 'MA',
    },
    '02421': {
      lat: 42.438,
      lng: -71.23922,
      city: 'Lexington',
      state: 'MA',
    },
    '02445': {
      lat: 42.32581,
      lng: -71.13319,
      city: 'Brookline',
      state: 'MA',
    },
    '02446': {
      lat: 42.3435,
      lng: -71.12179,
      city: 'Brookline',
      state: 'MA',
    },
    '02451': {
      lat: 42.39859,
      lng: -71.2573,
      city: 'Waltham',
      state: 'MA',
    },
    '02452': {
      lat: 42.39365,
      lng: -71.2202,
      city: 'Waltham',
      state: 'MA',
    },
    '02453': {
      lat: 42.36918,
      lng: -71.24101,
      city: 'Waltham',
      state: 'MA',
    },
    '02457': {
      lat: 42.29777,
      lng: -71.26887,
      city: 'Babson Park',
      state: 'MA',
    },
    '02458': {
      lat: 42.35294,
      lng: -71.18763,
      city: 'Newton',
      state: 'MA',
    },
    '02459': {
      lat: 42.31515,
      lng: -71.19174,
      city: 'Newton Center',
      state: 'MA',
    },
    '02460': {
      lat: 42.35206,
      lng: -71.20835,
      city: 'Newtonville',
      state: 'MA',
    },
    '02461': {
      lat: 42.31693,
      lng: -71.20875,
      city: 'Newton Highlands',
      state: 'MA',
    },
    '02462': {
      lat: 42.33102,
      lng: -71.25599,
      city: 'Newton Lower Falls',
      state: 'MA',
    },
    '02464': {
      lat: 42.31274,
      lng: -71.21981,
      city: 'Newton Upper Falls',
      state: 'MA',
    },
    '02465': {
      lat: 42.34969,
      lng: -71.22646,
      city: 'West Newton',
      state: 'MA',
    },
    '02466': {
      lat: 42.34541,
      lng: -71.24759,
      city: 'Auburndale',
      state: 'MA',
    },
    '02467': {
      lat: 42.31725,
      lng: -71.16096,
      city: 'Chestnut Hill',
      state: 'MA',
    },
    '02468': {
      lat: 42.32777,
      lng: -71.23142,
      city: 'Waban',
      state: 'MA',
    },
    '02472': {
      lat: 42.37,
      lng: -71.17713,
      city: 'Watertown',
      state: 'MA',
    },
    '02474': {
      lat: 42.42053,
      lng: -71.15666,
      city: 'Arlington',
      state: 'MA',
    },
    '02476': {
      lat: 42.41597,
      lng: -71.17437,
      city: 'Arlington',
      state: 'MA',
    },
    '02478': {
      lat: 42.39602,
      lng: -71.17955,
      city: 'Belmont',
      state: 'MA',
    },
    '02481': {
      lat: 42.31064,
      lng: -71.27779,
      city: 'Wellesley Hills',
      state: 'MA',
    },
    '02482': {
      lat: 42.29455,
      lng: -71.29859,
      city: 'Wellesley',
      state: 'MA',
    },
    '02492': {
      lat: 42.27615,
      lng: -71.24387,
      city: 'Needham',
      state: 'MA',
    },
    '02493': {
      lat: 42.35893,
      lng: -71.30009,
      city: 'Weston',
      state: 'MA',
    },
    '02494': {
      lat: 42.29959,
      lng: -71.2317,
      city: 'Needham Heights',
      state: 'MA',
    },
    '02532': {
      lat: 41.75111,
      lng: -70.59713,
      city: 'Buzzards Bay',
      state: 'MA',
    },
    '02534': {
      lat: 41.66626,
      lng: -70.61628,
      city: 'Cataumet',
      state: 'MA',
    },
    '02535': {
      lat: 41.34896,
      lng: -70.74629,
      city: 'Chilmark',
      state: 'MA',
    },
    '02536': {
      lat: 41.59706,
      lng: -70.56677,
      city: 'East Falmouth',
      state: 'MA',
    },
    '02537': {
      lat: 41.72882,
      lng: -70.44317,
      city: 'East Sandwich',
      state: 'MA',
    },
    '02538': {
      lat: 41.77872,
      lng: -70.65077,
      city: 'East Wareham',
      state: 'MA',
    },
    '02539': {
      lat: 41.37994,
      lng: -70.53227,
      city: 'Edgartown',
      state: 'MA',
    },
    '02540': {
      lat: 41.57128,
      lng: -70.62807,
      city: 'Falmouth',
      state: 'MA',
    },
    '02542': {
      lat: 41.70194,
      lng: -70.54824,
      city: 'Buzzards Bay',
      state: 'MA',
    },
    '02543': {
      lat: 41.48731,
      lng: -70.74525,
      city: 'Woods Hole',
      state: 'MA',
    },
    '02553': {
      lat: 41.71297,
      lng: -70.62011,
      city: 'Monument Beach',
      state: 'MA',
    },
    '02554': {
      lat: 41.28555,
      lng: -70.08043,
      city: 'Nantucket',
      state: 'MA',
    },
    '02556': {
      lat: 41.63903,
      lng: -70.62521,
      city: 'North Falmouth',
      state: 'MA',
    },
    '02557': {
      lat: 41.44218,
      lng: -70.56648,
      city: 'Oak Bluffs',
      state: 'MA',
    },
    '02558': {
      lat: 41.74453,
      lng: -70.65377,
      city: 'Onset',
      state: 'MA',
    },
    '02559': {
      lat: 41.69237,
      lng: -70.61374,
      city: 'Pocasset',
      state: 'MA',
    },
    '02561': {
      lat: 41.76782,
      lng: -70.53281,
      city: 'Sagamore',
      state: 'MA',
    },
    '02562': {
      lat: 41.78753,
      lng: -70.52746,
      city: 'Sagamore Beach',
      state: 'MA',
    },
    '02563': {
      lat: 41.71995,
      lng: -70.4818,
      city: 'Sandwich',
      state: 'MA',
    },
    '02564': {
      lat: 41.26552,
      lng: -69.98608,
      city: 'Siasconset',
      state: 'MA',
    },
    '02568': {
      lat: 41.44341,
      lng: -70.61534,
      city: 'Vineyard Haven',
      state: 'MA',
    },
    '02571': {
      lat: 41.76184,
      lng: -70.70138,
      city: 'Wareham',
      state: 'MA',
    },
    '02575': {
      lat: 41.39651,
      lng: -70.65496,
      city: 'West Tisbury',
      state: 'MA',
    },
    '02576': {
      lat: 41.77807,
      lng: -70.76338,
      city: 'West Wareham',
      state: 'MA',
    },
    '02584': {
      lat: 41.25753,
      lng: -70.00522,
      city: 'Nantucket',
      state: 'MA',
    },
    '02601': { lat: 41.66, lng: -70.29327, city: 'Hyannis', state: 'MA' },
    '02630': {
      lat: 41.69674,
      lng: -70.3052,
      city: 'Barnstable',
      state: 'MA',
    },
    '02631': {
      lat: 41.74673,
      lng: -70.06894,
      city: 'Brewster',
      state: 'MA',
    },
    '02632': {
      lat: 41.66074,
      lng: -70.34826,
      city: 'Centerville',
      state: 'MA',
    },
    '02633': {
      lat: 41.68949,
      lng: -69.97397,
      city: 'Chatham',
      state: 'MA',
    },
    '02635': {
      lat: 41.62348,
      lng: -70.44051,
      city: 'Cotuit',
      state: 'MA',
    },
    '02637': {
      lat: 41.70493,
      lng: -70.27245,
      city: 'Cummaquid',
      state: 'MA',
    },
    '02638': {
      lat: 41.73225,
      lng: -70.19459,
      city: 'Dennis',
      state: 'MA',
    },
    '02639': {
      lat: 41.66639,
      lng: -70.13535,
      city: 'Dennis Port',
      state: 'MA',
    },
    '02641': {
      lat: 41.75221,
      lng: -70.15176,
      city: 'East Dennis',
      state: 'MA',
    },
    '02642': {
      lat: 41.83976,
      lng: -69.97711,
      city: 'Eastham',
      state: 'MA',
    },
    '02643': {
      lat: 41.75534,
      lng: -69.94002,
      city: 'East Orleans',
      state: 'MA',
    },
    '02644': {
      lat: 41.68361,
      lng: -70.51202,
      city: 'Forestdale',
      state: 'MA',
    },
    '02645': {
      lat: 41.70352,
      lng: -70.06215,
      city: 'Harwich',
      state: 'MA',
    },
    '02646': {
      lat: 41.67103,
      lng: -70.07144,
      city: 'Harwich Port',
      state: 'MA',
    },
    '02647': {
      lat: 41.63127,
      lng: -70.30909,
      city: 'Hyannis Port',
      state: 'MA',
    },
    '02648': {
      lat: 41.67237,
      lng: -70.41545,
      city: 'Marstons Mills',
      state: 'MA',
    },
    '02649': {
      lat: 41.61778,
      lng: -70.49077,
      city: 'Mashpee',
      state: 'MA',
    },
    '02650': {
      lat: 41.7036,
      lng: -69.96589,
      city: 'North Chatham',
      state: 'MA',
    },
    '02652': {
      lat: 42.0428,
      lng: -70.09276,
      city: 'North Truro',
      state: 'MA',
    },
    '02653': {
      lat: 41.76901,
      lng: -69.9737,
      city: 'Orleans',
      state: 'MA',
    },
    '02655': {
      lat: 41.62972,
      lng: -70.39263,
      city: 'Osterville',
      state: 'MA',
    },
    '02657': {
      lat: 42.06139,
      lng: -70.19813,
      city: 'Provincetown',
      state: 'MA',
    },
    '02659': {
      lat: 41.68258,
      lng: -70.02234,
      city: 'South Chatham',
      state: 'MA',
    },
    '02660': {
      lat: 41.70828,
      lng: -70.15517,
      city: 'South Dennis',
      state: 'MA',
    },
    '02663': {
      lat: 41.90576,
      lng: -69.989,
      city: 'South Wellfleet',
      state: 'MA',
    },
    '02664': {
      lat: 41.67486,
      lng: -70.19446,
      city: 'South Yarmouth',
      state: 'MA',
    },
    '02666': {
      lat: 41.98817,
      lng: -70.04603,
      city: 'Truro',
      state: 'MA',
    },
    '02667': {
      lat: 41.92937,
      lng: -70.02074,
      city: 'Wellfleet',
      state: 'MA',
    },
    '02668': {
      lat: 41.70713,
      lng: -70.36246,
      city: 'West Barnstable',
      state: 'MA',
    },
    '02670': {
      lat: 41.66,
      lng: -70.17098,
      city: 'West Dennis',
      state: 'MA',
    },
    '02671': {
      lat: 41.66938,
      lng: -70.11087,
      city: 'West Harwich',
      state: 'MA',
    },
    '02672': {
      lat: 41.6357,
      lng: -70.31878,
      city: 'West Hyannisport',
      state: 'MA',
    },
    '02673': {
      lat: 41.6557,
      lng: -70.24447,
      city: 'West Yarmouth',
      state: 'MA',
    },
    '02675': {
      lat: 41.70401,
      lng: -70.22883,
      city: 'Yarmouth Port',
      state: 'MA',
    },
    '02702': {
      lat: 41.78452,
      lng: -71.06112,
      city: 'Assonet',
      state: 'MA',
    },
    '02703': {
      lat: 41.93108,
      lng: -71.29499,
      city: 'Attleboro',
      state: 'MA',
    },
    '02713': {
      lat: 41.42818,
      lng: -70.89102,
      city: 'Cuttyhunk',
      state: 'MA',
    },
    '02715': {
      lat: 41.81681,
      lng: -71.15064,
      city: 'Dighton',
      state: 'MA',
    },
    '02717': {
      lat: 41.75405,
      lng: -70.98312,
      city: 'East Freetown',
      state: 'MA',
    },
    '02718': {
      lat: 41.86902,
      lng: -71.01128,
      city: 'East Taunton',
      state: 'MA',
    },
    '02719': {
      lat: 41.63945,
      lng: -70.87322,
      city: 'Fairhaven',
      state: 'MA',
    },
    '02720': {
      lat: 41.73095,
      lng: -71.10932,
      city: 'Fall River',
      state: 'MA',
    },
    '02721': {
      lat: 41.67451,
      lng: -71.14869,
      city: 'Fall River',
      state: 'MA',
    },
    '02723': {
      lat: 41.69237,
      lng: -71.1309,
      city: 'Fall River',
      state: 'MA',
    },
    '02724': {
      lat: 41.68372,
      lng: -71.17949,
      city: 'Fall River',
      state: 'MA',
    },
    '02725': {
      lat: 41.71253,
      lng: -71.18426,
      city: 'Somerset',
      state: 'MA',
    },
    '02726': {
      lat: 41.76073,
      lng: -71.14444,
      city: 'Somerset',
      state: 'MA',
    },
    '02738': {
      lat: 41.7092,
      lng: -70.76362,
      city: 'Marion',
      state: 'MA',
    },
    '02739': {
      lat: 41.6687,
      lng: -70.81705,
      city: 'Mattapoisett',
      state: 'MA',
    },
    '02740': {
      lat: 41.63802,
      lng: -70.93839,
      city: 'New Bedford',
      state: 'MA',
    },
    '02743': {
      lat: 41.71323,
      lng: -70.90054,
      city: 'Acushnet',
      state: 'MA',
    },
    '02744': {
      lat: 41.60789,
      lng: -70.91286,
      city: 'New Bedford',
      state: 'MA',
    },
    '02745': {
      lat: 41.70284,
      lng: -70.94695,
      city: 'New Bedford',
      state: 'MA',
    },
    '02746': {
      lat: 41.66474,
      lng: -70.945,
      city: 'New Bedford',
      state: 'MA',
    },
    '02747': {
      lat: 41.6577,
      lng: -71.01202,
      city: 'North Dartmouth',
      state: 'MA',
    },
    '02748': {
      lat: 41.56096,
      lng: -70.97963,
      city: 'South Dartmouth',
      state: 'MA',
    },
    '02760': {
      lat: 41.97004,
      lng: -71.33586,
      city: 'North Attleboro',
      state: 'MA',
    },
    '02762': {
      lat: 42.01409,
      lng: -71.33642,
      city: 'Plainville',
      state: 'MA',
    },
    '02763': {
      lat: 41.96666,
      lng: -71.30743,
      city: 'Attleboro Falls',
      state: 'MA',
    },
    '02764': {
      lat: 41.8562,
      lng: -71.16022,
      city: 'North Dighton',
      state: 'MA',
    },
    '02766': {
      lat: 41.96402,
      lng: -71.18418,
      city: 'Norton',
      state: 'MA',
    },
    '02767': {
      lat: 41.93939,
      lng: -71.05191,
      city: 'Raynham',
      state: 'MA',
    },
    '02769': {
      lat: 41.84394,
      lng: -71.24559,
      city: 'Rehoboth',
      state: 'MA',
    },
    '02770': {
      lat: 41.75875,
      lng: -70.84048,
      city: 'Rochester',
      state: 'MA',
    },
    '02771': {
      lat: 41.83787,
      lng: -71.31736,
      city: 'Seekonk',
      state: 'MA',
    },
    '02777': {
      lat: 41.75699,
      lng: -71.21208,
      city: 'Swansea',
      state: 'MA',
    },
    '02779': {
      lat: 41.83492,
      lng: -71.07543,
      city: 'Berkley',
      state: 'MA',
    },
    '02780': {
      lat: 41.90743,
      lng: -71.11939,
      city: 'Taunton',
      state: 'MA',
    },
    '02790': {
      lat: 41.59967,
      lng: -71.08266,
      city: 'Westport',
      state: 'MA',
    },
    '02791': {
      lat: 41.52262,
      lng: -71.08266,
      city: 'Westport Point',
      state: 'MA',
    },
    '02802': {
      lat: 41.95221,
      lng: -71.4559,
      city: 'Albion',
      state: 'RI',
    },
    '02804': {
      lat: 41.43546,
      lng: -71.77196,
      city: 'Ashaway',
      state: 'RI',
    },
    '02806': {
      lat: 41.7443,
      lng: -71.31453,
      city: 'Barrington',
      state: 'RI',
    },
    '02807': {
      lat: 41.17762,
      lng: -71.57854,
      city: 'Block Island',
      state: 'RI',
    },
    '02808': {
      lat: 41.40606,
      lng: -71.74802,
      city: 'Bradford',
      state: 'RI',
    },
    '02809': {
      lat: 41.68266,
      lng: -71.26938,
      city: 'Bristol',
      state: 'RI',
    },
    '02812': {
      lat: 41.47625,
      lng: -71.66094,
      city: 'Carolina',
      state: 'RI',
    },
    '02813': {
      lat: 41.3971,
      lng: -71.67003,
      city: 'Charlestown',
      state: 'RI',
    },
    '02814': {
      lat: 41.89959,
      lng: -71.69888,
      city: 'Chepachet',
      state: 'RI',
    },
    '02815': {
      lat: 41.77723,
      lng: -71.66177,
      city: 'Clayville',
      state: 'RI',
    },
    '02816': {
      lat: 41.69035,
      lng: -71.61954,
      city: 'Coventry',
      state: 'RI',
    },
    '02817': {
      lat: 41.6291,
      lng: -71.66711,
      city: 'West Greenwich',
      state: 'RI',
    },
    '02818': {
      lat: 41.64214,
      lng: -71.49307,
      city: 'East Greenwich',
      state: 'RI',
    },
    '02822': {
      lat: 41.56736,
      lng: -71.63348,
      city: 'Exeter',
      state: 'RI',
    },
    '02823': {
      lat: 41.73328,
      lng: -71.54479,
      city: 'Fiskeville',
      state: 'RI',
    },
    '02824': {
      lat: 41.99934,
      lng: -71.56322,
      city: 'Forestdale',
      state: 'RI',
    },
    '02825': {
      lat: 41.78212,
      lng: -71.72596,
      city: 'Foster',
      state: 'RI',
    },
    '02826': {
      lat: 41.98248,
      lng: -71.64539,
      city: 'Glendale',
      state: 'RI',
    },
    '02827': {
      lat: 41.69792,
      lng: -71.73847,
      city: 'Greene',
      state: 'RI',
    },
    '02828': {
      lat: 41.87672,
      lng: -71.56065,
      city: 'Greenville',
      state: 'RI',
    },
    '02829': {
      lat: 41.89377,
      lng: -71.59129,
      city: 'Harmony',
      state: 'RI',
    },
    '02830': {
      lat: 41.98016,
      lng: -71.6484,
      city: 'Harrisville',
      state: 'RI',
    },
    '02831': { lat: 41.74461, lng: -71.58442, city: 'Hope', state: 'RI' },
    '02832': {
      lat: 41.50469,
      lng: -71.72807,
      city: 'Hope Valley',
      state: 'RI',
    },
    '02833': {
      lat: 41.48117,
      lng: -71.77787,
      city: 'Hopkinton',
      state: 'RI',
    },
    '02835': {
      lat: 41.51479,
      lng: -71.37742,
      city: 'Jamestown',
      state: 'RI',
    },
    '02836': {
      lat: 41.45145,
      lng: -71.62027,
      city: 'Kenyon',
      state: 'RI',
    },
    '02837': {
      lat: 41.51524,
      lng: -71.16455,
      city: 'Little Compton',
      state: 'RI',
    },
    '02838': {
      lat: 41.96552,
      lng: -71.47628,
      city: 'Manville',
      state: 'RI',
    },
    '02839': {
      lat: 41.9433,
      lng: -71.64581,
      city: 'Mapleville',
      state: 'RI',
    },
    '02840': {
      lat: 41.47838,
      lng: -71.31993,
      city: 'Newport',
      state: 'RI',
    },
    '02841': {
      lat: 41.51261,
      lng: -71.32522,
      city: 'Newport',
      state: 'RI',
    },
    '02842': {
      lat: 41.51739,
      lng: -71.27667,
      city: 'Middletown',
      state: 'RI',
    },
    '02852': {
      lat: 41.58809,
      lng: -71.46094,
      city: 'North Kingstown',
      state: 'RI',
    },
    '02857': {
      lat: 41.82705,
      lng: -71.63083,
      city: 'North Scituate',
      state: 'RI',
    },
    '02858': {
      lat: 41.96354,
      lng: -71.64893,
      city: 'Oakland',
      state: 'RI',
    },
    '02859': {
      lat: 41.9665,
      lng: -71.7464,
      city: 'Pascoag',
      state: 'RI',
    },
    '02860': {
      lat: 41.87036,
      lng: -71.38855,
      city: 'Pawtucket',
      state: 'RI',
    },
    '02861': {
      lat: 41.87863,
      lng: -71.35228,
      city: 'Pawtucket',
      state: 'RI',
    },
    '02863': {
      lat: 41.89005,
      lng: -71.39336,
      city: 'Central Falls',
      state: 'RI',
    },
    '02864': {
      lat: 41.97025,
      lng: -71.41978,
      city: 'Cumberland',
      state: 'RI',
    },
    '02865': {
      lat: 41.91251,
      lng: -71.44822,
      city: 'Lincoln',
      state: 'RI',
    },
    '02871': {
      lat: 41.58384,
      lng: -71.2564,
      city: 'Portsmouth',
      state: 'RI',
    },
    '02872': {
      lat: 41.61718,
      lng: -71.32886,
      city: 'Prudence Island',
      state: 'RI',
    },
    '02873': {
      lat: 41.5257,
      lng: -71.77734,
      city: 'Rockville',
      state: 'RI',
    },
    '02874': {
      lat: 41.51543,
      lng: -71.46985,
      city: 'Saunderstown',
      state: 'RI',
    },
    '02875': {
      lat: 41.46052,
      lng: -71.63848,
      city: 'Shannock',
      state: 'RI',
    },
    '02876': {
      lat: 41.99801,
      lng: -71.58301,
      city: 'Slatersville',
      state: 'RI',
    },
    '02878': {
      lat: 41.60905,
      lng: -71.17413,
      city: 'Tiverton',
      state: 'RI',
    },
    '02879': {
      lat: 41.42865,
      lng: -71.53837,
      city: 'Wakefield',
      state: 'RI',
    },
    '02881': {
      lat: 41.4825,
      lng: -71.52694,
      city: 'Kingston',
      state: 'RI',
    },
    '02882': {
      lat: 41.4253,
      lng: -71.46816,
      city: 'Narragansett',
      state: 'RI',
    },
    '02885': {
      lat: 41.72821,
      lng: -71.26287,
      city: 'Warren',
      state: 'RI',
    },
    '02886': {
      lat: 41.70493,
      lng: -71.45892,
      city: 'Warwick',
      state: 'RI',
    },
    '02888': {
      lat: 41.74909,
      lng: -71.41123,
      city: 'Warwick',
      state: 'RI',
    },
    '02889': {
      lat: 41.70602,
      lng: -71.39106,
      city: 'Warwick',
      state: 'RI',
    },
    '02891': {
      lat: 41.36043,
      lng: -71.79429,
      city: 'Westerly',
      state: 'RI',
    },
    '02892': {
      lat: 41.50226,
      lng: -71.60174,
      city: 'West Kingston',
      state: 'RI',
    },
    '02893': {
      lat: 41.69856,
      lng: -71.51566,
      city: 'West Warwick',
      state: 'RI',
    },
    '02894': {
      lat: 41.44761,
      lng: -71.69759,
      city: 'Wood River Junction',
      state: 'RI',
    },
    '02895': {
      lat: 42.00103,
      lng: -71.49923,
      city: 'Woonsocket',
      state: 'RI',
    },
    '02896': {
      lat: 41.97285,
      lng: -71.55027,
      city: 'North Smithfield',
      state: 'RI',
    },
    '02898': {
      lat: 41.52184,
      lng: -71.67166,
      city: 'Wyoming',
      state: 'RI',
    },
    '02903': {
      lat: 41.81902,
      lng: -71.41003,
      city: 'Providence',
      state: 'RI',
    },
    '02904': {
      lat: 41.86058,
      lng: -71.43539,
      city: 'Providence',
      state: 'RI',
    },
    '02905': {
      lat: 41.78769,
      lng: -71.39886,
      city: 'Providence',
      state: 'RI',
    },
    '02906': {
      lat: 41.83964,
      lng: -71.38963,
      city: 'Providence',
      state: 'RI',
    },
    '02907': {
      lat: 41.79867,
      lng: -71.42581,
      city: 'Providence',
      state: 'RI',
    },
    '02908': {
      lat: 41.83877,
      lng: -71.43926,
      city: 'Providence',
      state: 'RI',
    },
    '02909': {
      lat: 41.81825,
      lng: -71.45078,
      city: 'Providence',
      state: 'RI',
    },
    '02910': {
      lat: 41.77552,
      lng: -71.43564,
      city: 'Cranston',
      state: 'RI',
    },
    '02911': {
      lat: 41.85467,
      lng: -71.47325,
      city: 'North Providence',
      state: 'RI',
    },
    '02912': {
      lat: 41.82783,
      lng: -71.39959,
      city: 'Providence',
      state: 'RI',
    },
    '02914': {
      lat: 41.81326,
      lng: -71.36608,
      city: 'East Providence',
      state: 'RI',
    },
    '02915': {
      lat: 41.77725,
      lng: -71.34906,
      city: 'Riverside',
      state: 'RI',
    },
    '02916': {
      lat: 41.84438,
      lng: -71.35258,
      city: 'Rumford',
      state: 'RI',
    },
    '02917': {
      lat: 41.90615,
      lng: -71.52365,
      city: 'Smithfield',
      state: 'RI',
    },
    '02918': {
      lat: 41.84386,
      lng: -71.43499,
      city: 'Providence',
      state: 'RI',
    },
    '02919': {
      lat: 41.82733,
      lng: -71.52002,
      city: 'Johnston',
      state: 'RI',
    },
    '02920': {
      lat: 41.76719,
      lng: -71.4655,
      city: 'Cranston',
      state: 'RI',
    },
    '02921': {
      lat: 41.76292,
      lng: -71.51571,
      city: 'Cranston',
      state: 'RI',
    },
    '03031': {
      lat: 42.87056,
      lng: -71.60683,
      city: 'Amherst',
      state: 'NH',
    },
    '03032': {
      lat: 42.99063,
      lng: -71.34383,
      city: 'Auburn',
      state: 'NH',
    },
    '03033': {
      lat: 42.74643,
      lng: -71.67054,
      city: 'Brookline',
      state: 'NH',
    },
    '03034': {
      lat: 43.06269,
      lng: -71.31106,
      city: 'Candia',
      state: 'NH',
    },
    '03036': {
      lat: 42.96719,
      lng: -71.25083,
      city: 'Chester',
      state: 'NH',
    },
    '03037': {
      lat: 43.1395,
      lng: -71.2535,
      city: 'Deerfield',
      state: 'NH',
    },
    '03038': {
      lat: 42.88867,
      lng: -71.27932,
      city: 'Derry',
      state: 'NH',
    },
    '03042': {
      lat: 43.05009,
      lng: -71.07467,
      city: 'Epping',
      state: 'NH',
    },
    '03043': {
      lat: 42.99581,
      lng: -71.81514,
      city: 'Francestown',
      state: 'NH',
    },
    '03044': {
      lat: 42.98899,
      lng: -71.12494,
      city: 'Fremont',
      state: 'NH',
    },
    '03045': {
      lat: 43.01994,
      lng: -71.57035,
      city: 'Goffstown',
      state: 'NH',
    },
    '03046': {
      lat: 43.10178,
      lng: -71.60548,
      city: 'Dunbarton',
      state: 'NH',
    },
    '03047': {
      lat: 42.94029,
      lng: -71.87053,
      city: 'Greenfield',
      state: 'NH',
    },
    '03048': {
      lat: 42.74668,
      lng: -71.76268,
      city: 'Greenville',
      state: 'NH',
    },
    '03049': {
      lat: 42.7494,
      lng: -71.58338,
      city: 'Hollis',
      state: 'NH',
    },
    '03051': {
      lat: 42.76394,
      lng: -71.40716,
      city: 'Hudson',
      state: 'NH',
    },
    '03052': {
      lat: 42.84302,
      lng: -71.455,
      city: 'Litchfield',
      state: 'NH',
    },
    '03053': {
      lat: 42.87927,
      lng: -71.38702,
      city: 'Londonderry',
      state: 'NH',
    },
    '03054': {
      lat: 42.85471,
      lng: -71.51878,
      city: 'Merrimack',
      state: 'NH',
    },
    '03055': {
      lat: 42.81783,
      lng: -71.67361,
      city: 'Milford',
      state: 'NH',
    },
    '03057': {
      lat: 42.90265,
      lng: -71.6861,
      city: 'Mont Vernon',
      state: 'NH',
    },
    '03060': {
      lat: 42.74135,
      lng: -71.45936,
      city: 'Nashua',
      state: 'NH',
    },
    '03062': {
      lat: 42.72212,
      lng: -71.49556,
      city: 'Nashua',
      state: 'NH',
    },
    '03063': {
      lat: 42.7795,
      lng: -71.51939,
      city: 'Nashua',
      state: 'NH',
    },
    '03064': {
      lat: 42.77886,
      lng: -71.47466,
      city: 'Nashua',
      state: 'NH',
    },
    '03070': {
      lat: 42.97702,
      lng: -71.68664,
      city: 'New Boston',
      state: 'NH',
    },
    '03071': {
      lat: 42.74892,
      lng: -71.8747,
      city: 'New Ipswich',
      state: 'NH',
    },
    '03076': { lat: 42.73354, lng: -71.324, city: 'Pelham', state: 'NH' },
    '03077': {
      lat: 43.03219,
      lng: -71.19945,
      city: 'Raymond',
      state: 'NH',
    },
    '03079': {
      lat: 42.79023,
      lng: -71.22024,
      city: 'Salem',
      state: 'NH',
    },
    '03082': {
      lat: 42.90197,
      lng: -71.77545,
      city: 'Lyndeborough',
      state: 'NH',
    },
    '03084': {
      lat: 42.82856,
      lng: -71.85495,
      city: 'Temple',
      state: 'NH',
    },
    '03086': {
      lat: 42.82741,
      lng: -71.76967,
      city: 'Wilton',
      state: 'NH',
    },
    '03087': {
      lat: 42.80758,
      lng: -71.29945,
      city: 'Windham',
      state: 'NH',
    },
    '03101': {
      lat: 42.98881,
      lng: -71.4659,
      city: 'Manchester',
      state: 'NH',
    },
    '03102': {
      lat: 43.00797,
      lng: -71.49134,
      city: 'Manchester',
      state: 'NH',
    },
    '03103': {
      lat: 42.95536,
      lng: -71.44463,
      city: 'Manchester',
      state: 'NH',
    },
    '03104': {
      lat: 43.00926,
      lng: -71.4411,
      city: 'Manchester',
      state: 'NH',
    },
    '03106': {
      lat: 43.07097,
      lng: -71.43651,
      city: 'Hooksett',
      state: 'NH',
    },
    '03109': {
      lat: 42.96594,
      lng: -71.40167,
      city: 'Manchester',
      state: 'NH',
    },
    '03110': {
      lat: 42.94065,
      lng: -71.53023,
      city: 'Bedford',
      state: 'NH',
    },
    '03215': {
      lat: 44.03605,
      lng: -71.48109,
      city: 'Waterville Valley',
      state: 'NH',
    },
    '03216': {
      lat: 43.44656,
      lng: -71.79374,
      city: 'Andover',
      state: 'NH',
    },
    '03217': {
      lat: 43.71492,
      lng: -71.63033,
      city: 'Ashland',
      state: 'NH',
    },
    '03218': {
      lat: 43.33465,
      lng: -71.28803,
      city: 'Barnstead',
      state: 'NH',
    },
    '03220': {
      lat: 43.46793,
      lng: -71.4758,
      city: 'Belmont',
      state: 'NH',
    },
    '03221': {
      lat: 43.25482,
      lng: -71.96122,
      city: 'Bradford',
      state: 'NH',
    },
    '03222': {
      lat: 43.62895,
      lng: -71.78234,
      city: 'Bristol',
      state: 'NH',
    },
    '03223': {
      lat: 43.84784,
      lng: -71.67597,
      city: 'Campton',
      state: 'NH',
    },
    '03224': {
      lat: 43.35171,
      lng: -71.54691,
      city: 'Canterbury',
      state: 'NH',
    },
    '03225': {
      lat: 43.36001,
      lng: -71.23828,
      city: 'Center Barnstead',
      state: 'NH',
    },
    '03226': {
      lat: 43.70259,
      lng: -71.51086,
      city: 'Center Harbor',
      state: 'NH',
    },
    '03227': {
      lat: 43.81419,
      lng: -71.45712,
      city: 'Center Sandwich',
      state: 'NH',
    },
    '03229': {
      lat: 43.19792,
      lng: -71.69677,
      city: 'Contoocook',
      state: 'NH',
    },
    '03230': {
      lat: 43.52685,
      lng: -71.8622,
      city: 'Danbury',
      state: 'NH',
    },
    '03231': {
      lat: 43.45555,
      lng: -71.75551,
      city: 'East Andover',
      state: 'NH',
    },
    '03233': {
      lat: 43.42325,
      lng: -71.93322,
      city: 'Elkins',
      state: 'NH',
    },
    '03234': {
      lat: 43.21484,
      lng: -71.33914,
      city: 'Epsom',
      state: 'NH',
    },
    '03235': {
      lat: 43.44831,
      lng: -71.67208,
      city: 'Franklin',
      state: 'NH',
    },
    '03237': {
      lat: 43.42997,
      lng: -71.38233,
      city: 'Gilmanton',
      state: 'NH',
    },
    '03238': {
      lat: 43.98168,
      lng: -71.88701,
      city: 'Glencliff',
      state: 'NH',
    },
    '03240': {
      lat: 43.57599,
      lng: -71.97221,
      city: 'Grafton',
      state: 'NH',
    },
    '03241': {
      lat: 43.71149,
      lng: -71.81915,
      city: 'Hebron',
      state: 'NH',
    },
    '03242': {
      lat: 43.17365,
      lng: -71.8224,
      city: 'Henniker',
      state: 'NH',
    },
    '03243': { lat: 43.5276, lng: -71.7644, city: 'Hill', state: 'NH' },
    '03244': {
      lat: 43.11822,
      lng: -71.9192,
      city: 'Hillsborough',
      state: 'NH',
    },
    '03245': {
      lat: 43.75026,
      lng: -71.58852,
      city: 'Holderness',
      state: 'NH',
    },
    '03246': {
      lat: 43.57239,
      lng: -71.4775,
      city: 'Laconia',
      state: 'NH',
    },
    '03249': {
      lat: 43.55806,
      lng: -71.38505,
      city: 'Gilford',
      state: 'NH',
    },
    '03251': {
      lat: 44.0796,
      lng: -71.65276,
      city: 'Lincoln',
      state: 'NH',
    },
    '03253': {
      lat: 43.63193,
      lng: -71.49749,
      city: 'Meredith',
      state: 'NH',
    },
    '03254': {
      lat: 43.72505,
      lng: -71.37782,
      city: 'Moultonborough',
      state: 'NH',
    },
    '03255': {
      lat: 43.3149,
      lng: -72.02795,
      city: 'Newbury',
      state: 'NH',
    },
    '03256': {
      lat: 43.62425,
      lng: -71.63514,
      city: 'New Hampton',
      state: 'NH',
    },
    '03257': {
      lat: 43.41768,
      lng: -71.99101,
      city: 'New London',
      state: 'NH',
    },
    '03258': {
      lat: 43.25744,
      lng: -71.39956,
      city: 'Chichester',
      state: 'NH',
    },
    '03259': {
      lat: 43.87921,
      lng: -71.44527,
      city: 'North Sandwich',
      state: 'NH',
    },
    '03260': {
      lat: 43.36016,
      lng: -71.92457,
      city: 'North Sutton',
      state: 'NH',
    },
    '03261': {
      lat: 43.21322,
      lng: -71.20966,
      city: 'Northwood',
      state: 'NH',
    },
    '03262': {
      lat: 43.99885,
      lng: -71.73813,
      city: 'North Woodstock',
      state: 'NH',
    },
    '03263': {
      lat: 43.29483,
      lng: -71.31133,
      city: 'Pittsfield',
      state: 'NH',
    },
    '03264': {
      lat: 43.72967,
      lng: -71.71159,
      city: 'Plymouth',
      state: 'NH',
    },
    '03266': {
      lat: 43.79407,
      lng: -71.89035,
      city: 'Rumney',
      state: 'NH',
    },
    '03268': {
      lat: 43.38505,
      lng: -71.7574,
      city: 'Salisbury',
      state: 'NH',
    },
    '03269': {
      lat: 43.53,
      lng: -71.60219,
      city: 'Sanbornton',
      state: 'NH',
    },
    '03273': {
      lat: 43.30337,
      lng: -71.92413,
      city: 'South Sutton',
      state: 'NH',
    },
    '03275': {
      lat: 43.15999,
      lng: -71.41794,
      city: 'Suncook',
      state: 'NH',
    },
    '03276': {
      lat: 43.42783,
      lng: -71.57514,
      city: 'Tilton',
      state: 'NH',
    },
    '03278': {
      lat: 43.29313,
      lng: -71.82934,
      city: 'Warner',
      state: 'NH',
    },
    '03279': {
      lat: 43.94468,
      lng: -71.88231,
      city: 'Warren',
      state: 'NH',
    },
    '03280': {
      lat: 43.18339,
      lng: -72.08979,
      city: 'Washington',
      state: 'NH',
    },
    '03281': {
      lat: 43.08126,
      lng: -71.72189,
      city: 'Weare',
      state: 'NH',
    },
    '03282': {
      lat: 43.86006,
      lng: -71.9295,
      city: 'Wentworth',
      state: 'NH',
    },
    '03284': {
      lat: 43.49361,
      lng: -72.03057,
      city: 'Springfield',
      state: 'NH',
    },
    '03285': {
      lat: 43.92408,
      lng: -71.64359,
      city: 'Thornton',
      state: 'NH',
    },
    '03287': {
      lat: 43.45168,
      lng: -71.91795,
      city: 'Wilmot',
      state: 'NH',
    },
    '03290': {
      lat: 43.12391,
      lng: -71.12089,
      city: 'Nottingham',
      state: 'NH',
    },
    '03291': {
      lat: 43.17976,
      lng: -71.14205,
      city: 'West Nottingham',
      state: 'NH',
    },
    '03293': {
      lat: 43.97696,
      lng: -71.67721,
      city: 'Woodstock',
      state: 'NH',
    },
    '03301': {
      lat: 43.22423,
      lng: -71.54342,
      city: 'Concord',
      state: 'NH',
    },
    '03303': {
      lat: 43.29819,
      lng: -71.67186,
      city: 'Concord',
      state: 'NH',
    },
    '03304': { lat: 43.13078, lng: -71.53066, city: 'Bow', state: 'NH' },
    '03307': {
      lat: 43.32257,
      lng: -71.44265,
      city: 'Loudon',
      state: 'NH',
    },
    '03431': {
      lat: 42.96785,
      lng: -72.29862,
      city: 'Keene',
      state: 'NH',
    },
    '03440': {
      lat: 43.06023,
      lng: -71.97446,
      city: 'Antrim',
      state: 'NH',
    },
    '03441': {
      lat: 42.77737,
      lng: -72.44263,
      city: 'Ashuelot',
      state: 'NH',
    },
    '03442': {
      lat: 43.01268,
      lng: -71.90781,
      city: 'Bennington',
      state: 'NH',
    },
    '03443': {
      lat: 42.8767,
      lng: -72.46148,
      city: 'Chesterfield',
      state: 'NH',
    },
    '03444': {
      lat: 42.89403,
      lng: -72.07178,
      city: 'Dublin',
      state: 'NH',
    },
    '03445': {
      lat: 43.01555,
      lng: -72.2137,
      city: 'Sullivan',
      state: 'NH',
    },
    '03446': {
      lat: 42.86163,
      lng: -72.29639,
      city: 'Swanzey',
      state: 'NH',
    },
    '03447': {
      lat: 42.7592,
      lng: -72.14794,
      city: 'Fitzwilliam',
      state: 'NH',
    },
    '03448': {
      lat: 43.03996,
      lng: -72.26448,
      city: 'Gilsum',
      state: 'NH',
    },
    '03449': {
      lat: 42.98222,
      lng: -72.00037,
      city: 'Hancock',
      state: 'NH',
    },
    '03450': {
      lat: 42.94157,
      lng: -72.09107,
      city: 'Harrisville',
      state: 'NH',
    },
    '03451': {
      lat: 42.80907,
      lng: -72.50029,
      city: 'Hinsdale',
      state: 'NH',
    },
    '03452': {
      lat: 42.82922,
      lng: -72.05962,
      city: 'Jaffrey',
      state: 'NH',
    },
    '03455': {
      lat: 42.89816,
      lng: -72.18308,
      city: 'Marlborough',
      state: 'NH',
    },
    '03456': {
      lat: 43.13092,
      lng: -72.21505,
      city: 'Marlow',
      state: 'NH',
    },
    '03457': {
      lat: 43.00282,
      lng: -72.12933,
      city: 'Nelson',
      state: 'NH',
    },
    '03458': {
      lat: 42.86906,
      lng: -71.93797,
      city: 'Peterborough',
      state: 'NH',
    },
    '03461': {
      lat: 42.75234,
      lng: -72.01071,
      city: 'Rindge',
      state: 'NH',
    },
    '03462': {
      lat: 42.8949,
      lng: -72.40507,
      city: 'Spofford',
      state: 'NH',
    },
    '03464': {
      lat: 43.08256,
      lng: -72.11085,
      city: 'Stoddard',
      state: 'NH',
    },
    '03465': { lat: 42.82677, lng: -72.18988, city: 'Troy', state: 'NH' },
    '03466': {
      lat: 42.89525,
      lng: -72.50964,
      city: 'West Chesterfield',
      state: 'NH',
    },
    '03467': {
      lat: 42.96894,
      lng: -72.43203,
      city: 'Westmoreland',
      state: 'NH',
    },
    '03470': {
      lat: 42.77869,
      lng: -72.34413,
      city: 'Winchester',
      state: 'NH',
    },
    '03561': {
      lat: 44.33222,
      lng: -71.80973,
      city: 'Littleton',
      state: 'NH',
    },
    '03570': {
      lat: 44.49953,
      lng: -71.20396,
      city: 'Berlin',
      state: 'NH',
    },
    '03574': {
      lat: 44.25386,
      lng: -71.59924,
      city: 'Bethlehem',
      state: 'NH',
    },
    '03575': {
      lat: 44.28946,
      lng: -71.41722,
      city: 'Bretton Woods',
      state: 'NH',
    },
    '03576': {
      lat: 44.89839,
      lng: -71.37178,
      city: 'Colebrook',
      state: 'NH',
    },
    '03579': {
      lat: 44.95234,
      lng: -71.04182,
      city: 'Errol',
      state: 'NH',
    },
    '03580': {
      lat: 44.17347,
      lng: -71.69519,
      city: 'Franconia',
      state: 'NH',
    },
    '03581': {
      lat: 44.34227,
      lng: -71.13413,
      city: 'Gorham',
      state: 'NH',
    },
    '03582': {
      lat: 44.59777,
      lng: -71.43703,
      city: 'Groveton',
      state: 'NH',
    },
    '03583': {
      lat: 44.40148,
      lng: -71.4583,
      city: 'Jefferson',
      state: 'NH',
    },
    '03584': {
      lat: 44.48696,
      lng: -71.54278,
      city: 'Lancaster',
      state: 'NH',
    },
    '03585': {
      lat: 44.22538,
      lng: -71.89703,
      city: 'Lisbon',
      state: 'NH',
    },
    '03586': {
      lat: 44.22058,
      lng: -71.7963,
      city: 'Sugar Hill',
      state: 'NH',
    },
    '03588': {
      lat: 44.60545,
      lng: -71.23204,
      city: 'Milan',
      state: 'NH',
    },
    '03590': {
      lat: 44.74195,
      lng: -71.51485,
      city: 'North Stratford',
      state: 'NH',
    },
    '03592': {
      lat: 45.1128,
      lng: -71.25493,
      city: 'Pittsburg',
      state: 'NH',
    },
    '03593': {
      lat: 44.29816,
      lng: -71.31774,
      city: 'Randolph',
      state: 'NH',
    },
    '03595': {
      lat: 44.30616,
      lng: -71.527,
      city: 'Twin Mountain',
      state: 'NH',
    },
    '03597': {
      lat: 44.73439,
      lng: -71.38243,
      city: 'West Stewartstown',
      state: 'NH',
    },
    '03598': {
      lat: 44.37998,
      lng: -71.63179,
      city: 'Whitefield',
      state: 'NH',
    },
    '03601': {
      lat: 43.23933,
      lng: -72.29855,
      city: 'Acworth',
      state: 'NH',
    },
    '03602': {
      lat: 43.13751,
      lng: -72.32938,
      city: 'Alstead',
      state: 'NH',
    },
    '03603': {
      lat: 43.25339,
      lng: -72.38059,
      city: 'Charlestown',
      state: 'NH',
    },
    '03604': {
      lat: 43.13232,
      lng: -72.38984,
      city: 'Drewsville',
      state: 'NH',
    },
    '03605': {
      lat: 43.22815,
      lng: -72.18032,
      city: 'Lempster',
      state: 'NH',
    },
    '03607': {
      lat: 43.19698,
      lng: -72.26084,
      city: 'South Acworth',
      state: 'NH',
    },
    '03608': {
      lat: 43.07128,
      lng: -72.40604,
      city: 'Walpole',
      state: 'NH',
    },
    '03609': {
      lat: 43.1441,
      lng: -72.44512,
      city: 'North Walpole',
      state: 'NH',
    },
    '03740': { lat: 44.18025, lng: -71.98626, city: 'Bath', state: 'NH' },
    '03741': {
      lat: 43.67134,
      lng: -72.01559,
      city: 'Canaan',
      state: 'NH',
    },
    '03743': {
      lat: 43.36026,
      lng: -72.32489,
      city: 'Claremont',
      state: 'NH',
    },
    '03745': {
      lat: 43.47407,
      lng: -72.32001,
      city: 'Cornish',
      state: 'NH',
    },
    '03748': {
      lat: 43.60207,
      lng: -72.11713,
      city: 'Enfield',
      state: 'NH',
    },
    '03750': { lat: 43.70681, lng: -72.19308, city: 'Etna', state: 'NH' },
    '03751': {
      lat: 43.44363,
      lng: -72.08394,
      city: 'Georges Mills',
      state: 'NH',
    },
    '03752': {
      lat: 43.28909,
      lng: -72.11489,
      city: 'Goshen',
      state: 'NH',
    },
    '03753': {
      lat: 43.51534,
      lng: -72.14798,
      city: 'Grantham',
      state: 'NH',
    },
    '03755': {
      lat: 43.72377,
      lng: -72.19563,
      city: 'Hanover',
      state: 'NH',
    },
    '03765': {
      lat: 44.03452,
      lng: -72.05231,
      city: 'Haverhill',
      state: 'NH',
    },
    '03766': {
      lat: 43.63491,
      lng: -72.23819,
      city: 'Lebanon',
      state: 'NH',
    },
    '03768': { lat: 43.80529, lng: -72.11573, city: 'Lyme', state: 'NH' },
    '03770': {
      lat: 43.5336,
      lng: -72.26469,
      city: 'Meriden',
      state: 'NH',
    },
    '03771': {
      lat: 44.28443,
      lng: -72.0098,
      city: 'Monroe',
      state: 'NH',
    },
    '03773': {
      lat: 43.38484,
      lng: -72.19898,
      city: 'Newport',
      state: 'NH',
    },
    '03774': {
      lat: 44.09097,
      lng: -71.9947,
      city: 'North Haverhill',
      state: 'NH',
    },
    '03777': {
      lat: 43.89548,
      lng: -72.06672,
      city: 'Orford',
      state: 'NH',
    },
    '03779': {
      lat: 43.97477,
      lng: -72.03089,
      city: 'Piermont',
      state: 'NH',
    },
    '03780': { lat: 44.03169, lng: -71.97142, city: 'Pike', state: 'NH' },
    '03781': {
      lat: 43.5526,
      lng: -72.28399,
      city: 'Plainfield',
      state: 'NH',
    },
    '03782': {
      lat: 43.38962,
      lng: -72.09149,
      city: 'Sunapee',
      state: 'NH',
    },
    '03784': {
      lat: 43.63675,
      lng: -72.30608,
      city: 'West Lebanon',
      state: 'NH',
    },
    '03785': {
      lat: 44.06525,
      lng: -71.87017,
      city: 'Woodsville',
      state: 'NH',
    },
    '03801': {
      lat: 43.07293,
      lng: -70.8052,
      city: 'Portsmouth',
      state: 'NH',
    },
    '03809': {
      lat: 43.45321,
      lng: -71.21184,
      city: 'Alton',
      state: 'NH',
    },
    '03810': {
      lat: 43.52692,
      lng: -71.27941,
      city: 'Alton Bay',
      state: 'NH',
    },
    '03811': {
      lat: 42.83693,
      lng: -71.16042,
      city: 'Atkinson',
      state: 'NH',
    },
    '03812': {
      lat: 44.08148,
      lng: -71.29643,
      city: 'Bartlett',
      state: 'NH',
    },
    '03813': {
      lat: 44.09408,
      lng: -71.04573,
      city: 'Center Conway',
      state: 'NH',
    },
    '03814': {
      lat: 43.77341,
      lng: -71.18717,
      city: 'Center Ossipee',
      state: 'NH',
    },
    '03816': {
      lat: 43.69753,
      lng: -71.24218,
      city: 'Center Tuftonboro',
      state: 'NH',
    },
    '03817': {
      lat: 43.8885,
      lng: -71.23358,
      city: 'Chocorua',
      state: 'NH',
    },
    '03818': {
      lat: 43.97302,
      lng: -71.25261,
      city: 'Conway',
      state: 'NH',
    },
    '03819': {
      lat: 42.93012,
      lng: -71.12197,
      city: 'Danville',
      state: 'NH',
    },
    '03820': {
      lat: 43.18933,
      lng: -70.88428,
      city: 'Dover',
      state: 'NH',
    },
    '03823': {
      lat: 43.17488,
      lng: -70.94089,
      city: 'Madbury',
      state: 'NH',
    },
    '03824': {
      lat: 43.11744,
      lng: -70.91937,
      city: 'Durham',
      state: 'NH',
    },
    '03825': {
      lat: 43.21366,
      lng: -71.04227,
      city: 'Barrington',
      state: 'NH',
    },
    '03826': {
      lat: 42.88878,
      lng: -71.12881,
      city: 'East Hampstead',
      state: 'NH',
    },
    '03827': {
      lat: 42.90691,
      lng: -70.98774,
      city: 'East Kingston',
      state: 'NH',
    },
    '03830': {
      lat: 43.63549,
      lng: -70.99568,
      city: 'East Wakefield',
      state: 'NH',
    },
    '03832': {
      lat: 43.90249,
      lng: -71.04859,
      city: 'Eaton Center',
      state: 'NH',
    },
    '03833': {
      lat: 42.97464,
      lng: -70.98885,
      city: 'Exeter',
      state: 'NH',
    },
    '03835': {
      lat: 43.36319,
      lng: -71.07605,
      city: 'Farmington',
      state: 'NH',
    },
    '03836': {
      lat: 43.82608,
      lng: -71.07118,
      city: 'Freedom',
      state: 'NH',
    },
    '03837': {
      lat: 43.42271,
      lng: -71.32766,
      city: 'Gilmanton Iron Works',
      state: 'NH',
    },
    '03838': { lat: 44.11257, lng: -71.24036, city: 'Glen', state: 'NH' },
    '03839': {
      lat: 43.26256,
      lng: -70.98254,
      city: 'Rochester',
      state: 'NH',
    },
    '03840': {
      lat: 43.03853,
      lng: -70.84803,
      city: 'Greenland',
      state: 'NH',
    },
    '03841': {
      lat: 42.88148,
      lng: -71.17856,
      city: 'Hampstead',
      state: 'NH',
    },
    '03842': {
      lat: 42.93941,
      lng: -70.83697,
      city: 'Hampton',
      state: 'NH',
    },
    '03844': {
      lat: 42.92693,
      lng: -70.88603,
      city: 'Hampton Falls',
      state: 'NH',
    },
    '03845': {
      lat: 44.10317,
      lng: -71.1271,
      city: 'Intervale',
      state: 'NH',
    },
    '03846': {
      lat: 44.18445,
      lng: -71.18899,
      city: 'Jackson',
      state: 'NH',
    },
    '03847': {
      lat: 44.06875,
      lng: -71.12162,
      city: 'Kearsarge',
      state: 'NH',
    },
    '03848': {
      lat: 42.91562,
      lng: -71.06647,
      city: 'Kingston',
      state: 'NH',
    },
    '03849': {
      lat: 43.89454,
      lng: -71.13101,
      city: 'Madison',
      state: 'NH',
    },
    '03850': {
      lat: 43.69739,
      lng: -71.29983,
      city: 'Melvin Village',
      state: 'NH',
    },
    '03851': {
      lat: 43.43606,
      lng: -71.01633,
      city: 'Milton',
      state: 'NH',
    },
    '03852': {
      lat: 43.4982,
      lng: -70.97547,
      city: 'Milton Mills',
      state: 'NH',
    },
    '03853': {
      lat: 43.63818,
      lng: -71.29371,
      city: 'Mirror Lake',
      state: 'NH',
    },
    '03854': {
      lat: 43.06558,
      lng: -70.72003,
      city: 'New Castle',
      state: 'NH',
    },
    '03855': {
      lat: 43.46343,
      lng: -71.14172,
      city: 'New Durham',
      state: 'NH',
    },
    '03856': {
      lat: 43.03916,
      lng: -70.96627,
      city: 'Newfields',
      state: 'NH',
    },
    '03857': {
      lat: 43.0691,
      lng: -70.95164,
      city: 'Newmarket',
      state: 'NH',
    },
    '03858': {
      lat: 42.86839,
      lng: -71.04357,
      city: 'Newton',
      state: 'NH',
    },
    '03860': {
      lat: 44.0427,
      lng: -71.12422,
      city: 'North Conway',
      state: 'NH',
    },
    '03861': { lat: 43.11931, lng: -71.0068, city: 'Lee', state: 'NH' },
    '03862': {
      lat: 42.97914,
      lng: -70.83025,
      city: 'North Hampton',
      state: 'NH',
    },
    '03864': {
      lat: 43.69044,
      lng: -71.10631,
      city: 'Ossipee',
      state: 'NH',
    },
    '03865': {
      lat: 42.84032,
      lng: -71.09603,
      city: 'Plaistow',
      state: 'NH',
    },
    '03867': {
      lat: 43.3031,
      lng: -70.98891,
      city: 'Rochester',
      state: 'NH',
    },
    '03868': {
      lat: 43.31879,
      lng: -70.9415,
      city: 'Rochester',
      state: 'NH',
    },
    '03869': {
      lat: 43.22092,
      lng: -70.83995,
      city: 'Rollinsford',
      state: 'NH',
    },
    '03870': { lat: 43.0157, lng: -70.76052, city: 'Rye', state: 'NH' },
    '03871': {
      lat: 42.9807,
      lng: -70.77358,
      city: 'Rye Beach',
      state: 'NH',
    },
    '03872': {
      lat: 43.57225,
      lng: -71.04249,
      city: 'Sanbornville',
      state: 'NH',
    },
    '03873': {
      lat: 42.93321,
      lng: -71.1833,
      city: 'Sandown',
      state: 'NH',
    },
    '03874': {
      lat: 42.88689,
      lng: -70.8607,
      city: 'Seabrook',
      state: 'NH',
    },
    '03875': {
      lat: 43.89944,
      lng: -71.18636,
      city: 'Silver Lake',
      state: 'NH',
    },
    '03878': {
      lat: 43.25335,
      lng: -70.88555,
      city: 'Somersworth',
      state: 'NH',
    },
    '03882': {
      lat: 43.74727,
      lng: -71.04254,
      city: 'Effingham',
      state: 'NH',
    },
    '03883': {
      lat: 43.81252,
      lng: -71.30789,
      city: 'South Tamworth',
      state: 'NH',
    },
    '03884': {
      lat: 43.28018,
      lng: -71.14885,
      city: 'Strafford',
      state: 'NH',
    },
    '03885': {
      lat: 43.01579,
      lng: -70.90051,
      city: 'Stratham',
      state: 'NH',
    },
    '03886': {
      lat: 43.86399,
      lng: -71.28783,
      city: 'Tamworth',
      state: 'NH',
    },
    '03887': { lat: 43.48485, lng: -71.0662, city: 'Union', state: 'NH' },
    '03890': {
      lat: 43.81528,
      lng: -71.20581,
      city: 'West Ossipee',
      state: 'NH',
    },
    '03894': {
      lat: 43.61109,
      lng: -71.17788,
      city: 'Wolfeboro',
      state: 'NH',
    },
    '03897': {
      lat: 43.90841,
      lng: -71.32453,
      city: 'Wonalancet',
      state: 'NH',
    },
    '03901': {
      lat: 43.30063,
      lng: -70.84397,
      city: 'Berwick',
      state: 'ME',
    },
    '03902': {
      lat: 43.22246,
      lng: -70.63788,
      city: 'Cape Neddick',
      state: 'ME',
    },
    '03903': { lat: 43.14885, lng: -70.786, city: 'Eliot', state: 'ME' },
    '03904': {
      lat: 43.11025,
      lng: -70.73201,
      city: 'Kittery',
      state: 'ME',
    },
    '03905': {
      lat: 43.08481,
      lng: -70.68449,
      city: 'Kittery Point',
      state: 'ME',
    },
    '03906': {
      lat: 43.3447,
      lng: -70.77759,
      city: 'North Berwick',
      state: 'ME',
    },
    '03907': {
      lat: 43.25474,
      lng: -70.611,
      city: 'Ogunquit',
      state: 'ME',
    },
    '03908': {
      lat: 43.23838,
      lng: -70.74795,
      city: 'South Berwick',
      state: 'ME',
    },
    '03909': { lat: 43.16786, lng: -70.68105, city: 'York', state: 'ME' },
    '03910': {
      lat: 43.17374,
      lng: -70.60842,
      city: 'York Beach',
      state: 'ME',
    },
    '03911': {
      lat: 43.13424,
      lng: -70.63695,
      city: 'York Harbor',
      state: 'ME',
    },
    '04001': {
      lat: 43.52991,
      lng: -70.92078,
      city: 'Acton',
      state: 'ME',
    },
    '04002': {
      lat: 43.49572,
      lng: -70.67922,
      city: 'Alfred',
      state: 'ME',
    },
    '04003': {
      lat: 43.73362,
      lng: -69.98724,
      city: 'Bailey Island',
      state: 'ME',
    },
    '04005': {
      lat: 43.49591,
      lng: -70.49537,
      city: 'Biddeford',
      state: 'ME',
    },
    '04006': {
      lat: 43.44397,
      lng: -70.34272,
      city: 'Biddeford Pool',
      state: 'ME',
    },
    '04008': {
      lat: 44.03664,
      lng: -69.86337,
      city: 'Bowdoinham',
      state: 'ME',
    },
    '04009': {
      lat: 44.04791,
      lng: -70.73632,
      city: 'Bridgton',
      state: 'ME',
    },
    '04010': {
      lat: 43.93341,
      lng: -70.91718,
      city: 'Brownfield',
      state: 'ME',
    },
    '04011': {
      lat: 43.90068,
      lng: -69.97607,
      city: 'Brunswick',
      state: 'ME',
    },
    '04015': { lat: 43.96503, lng: -70.5205, city: 'Casco', state: 'ME' },
    '04017': {
      lat: 43.72974,
      lng: -70.11317,
      city: 'Chebeague Island',
      state: 'ME',
    },
    '04019': {
      lat: 43.69149,
      lng: -70.0999,
      city: 'Cliff Island',
      state: 'ME',
    },
    '04020': {
      lat: 43.76811,
      lng: -70.81028,
      city: 'Cornish',
      state: 'ME',
    },
    '04021': {
      lat: 43.79756,
      lng: -70.26519,
      city: 'Cumberland Center',
      state: 'ME',
    },
    '04022': {
      lat: 43.98055,
      lng: -70.80718,
      city: 'Denmark',
      state: 'ME',
    },
    '04024': {
      lat: 43.83556,
      lng: -70.67701,
      city: 'East Baldwin',
      state: 'ME',
    },
    '04027': {
      lat: 43.40333,
      lng: -70.91155,
      city: 'Lebanon',
      state: 'ME',
    },
    '04029': {
      lat: 43.89479,
      lng: -70.66007,
      city: 'Sebago',
      state: 'ME',
    },
    '04030': {
      lat: 43.59301,
      lng: -70.69553,
      city: 'East Waterboro',
      state: 'ME',
    },
    '04032': {
      lat: 43.85561,
      lng: -70.10085,
      city: 'Freeport',
      state: 'ME',
    },
    '04037': {
      lat: 44.09129,
      lng: -70.94942,
      city: 'Fryeburg',
      state: 'ME',
    },
    '04038': {
      lat: 43.70343,
      lng: -70.4581,
      city: 'Gorham',
      state: 'ME',
    },
    '04039': { lat: 43.88744, lng: -70.34532, city: 'Gray', state: 'ME' },
    '04040': {
      lat: 44.10922,
      lng: -70.71784,
      city: 'Harrison',
      state: 'ME',
    },
    '04041': {
      lat: 43.86416,
      lng: -70.82631,
      city: 'Hiram',
      state: 'ME',
    },
    '04042': {
      lat: 43.62953,
      lng: -70.62453,
      city: 'Hollis Center',
      state: 'ME',
    },
    '04043': {
      lat: 43.39721,
      lng: -70.57071,
      city: 'Kennebunk',
      state: 'ME',
    },
    '04046': {
      lat: 43.42027,
      lng: -70.4921,
      city: 'Kennebunkport',
      state: 'ME',
    },
    '04047': {
      lat: 43.73552,
      lng: -70.91152,
      city: 'Parsonsfield',
      state: 'ME',
    },
    '04048': {
      lat: 43.68862,
      lng: -70.78642,
      city: 'Limerick',
      state: 'ME',
    },
    '04049': {
      lat: 43.7356,
      lng: -70.70263,
      city: 'Limington',
      state: 'ME',
    },
    '04050': {
      lat: 43.69148,
      lng: -70.15714,
      city: 'Long Island',
      state: 'ME',
    },
    '04051': {
      lat: 44.18945,
      lng: -70.88477,
      city: 'Lovell',
      state: 'ME',
    },
    '04055': {
      lat: 43.97065,
      lng: -70.61444,
      city: 'Naples',
      state: 'ME',
    },
    '04056': {
      lat: 43.66066,
      lng: -70.85875,
      city: 'Newfield',
      state: 'ME',
    },
    '04057': {
      lat: 44.09563,
      lng: -70.70177,
      city: 'North Bridgton',
      state: 'ME',
    },
    '04061': {
      lat: 43.63409,
      lng: -70.74312,
      city: 'North Waterboro',
      state: 'ME',
    },
    '04062': {
      lat: 43.79798,
      lng: -70.40547,
      city: 'Windham',
      state: 'ME',
    },
    '04063': {
      lat: 43.50145,
      lng: -70.39551,
      city: 'Ocean Park',
      state: 'ME',
    },
    '04064': {
      lat: 43.52635,
      lng: -70.38985,
      city: 'Old Orchard Beach',
      state: 'ME',
    },
    '04066': {
      lat: 43.77332,
      lng: -69.96808,
      city: 'Orrs Island',
      state: 'ME',
    },
    '04068': {
      lat: 43.84009,
      lng: -70.9409,
      city: 'Porter',
      state: 'ME',
    },
    '04069': {
      lat: 43.89918,
      lng: -70.18191,
      city: 'Pownal',
      state: 'ME',
    },
    '04071': {
      lat: 43.89598,
      lng: -70.48133,
      city: 'Raymond',
      state: 'ME',
    },
    '04072': { lat: 43.53898, lng: -70.46236, city: 'Saco', state: 'ME' },
    '04073': {
      lat: 43.41301,
      lng: -70.74237,
      city: 'Sanford',
      state: 'ME',
    },
    '04074': {
      lat: 43.59103,
      lng: -70.3683,
      city: 'Scarborough',
      state: 'ME',
    },
    '04076': {
      lat: 43.55248,
      lng: -70.83793,
      city: 'Shapleigh',
      state: 'ME',
    },
    '04079': {
      lat: 43.80343,
      lng: -69.94972,
      city: 'Harpswell',
      state: 'ME',
    },
    '04083': {
      lat: 43.46728,
      lng: -70.81359,
      city: 'Springvale',
      state: 'ME',
    },
    '04084': {
      lat: 43.76637,
      lng: -70.55893,
      city: 'Standish',
      state: 'ME',
    },
    '04085': {
      lat: 43.7809,
      lng: -70.62553,
      city: 'Steep Falls',
      state: 'ME',
    },
    '04086': {
      lat: 43.96145,
      lng: -69.95872,
      city: 'Topsham',
      state: 'ME',
    },
    '04087': {
      lat: 43.56234,
      lng: -70.74529,
      city: 'Waterboro',
      state: 'ME',
    },
    '04088': {
      lat: 44.19352,
      lng: -70.71906,
      city: 'Waterford',
      state: 'ME',
    },
    '04090': {
      lat: 43.32669,
      lng: -70.63356,
      city: 'Wells',
      state: 'ME',
    },
    '04091': {
      lat: 43.83035,
      lng: -70.74858,
      city: 'West Baldwin',
      state: 'ME',
    },
    '04092': {
      lat: 43.69542,
      lng: -70.35389,
      city: 'Westbrook',
      state: 'ME',
    },
    '04093': {
      lat: 43.64282,
      lng: -70.53765,
      city: 'Buxton',
      state: 'ME',
    },
    '04095': {
      lat: 43.63925,
      lng: -70.90749,
      city: 'West Newfield',
      state: 'ME',
    },
    '04096': {
      lat: 43.7978,
      lng: -70.1719,
      city: 'Yarmouth',
      state: 'ME',
    },
    '04097': {
      lat: 43.85123,
      lng: -70.23636,
      city: 'North Yarmouth',
      state: 'ME',
    },
    '04101': {
      lat: 43.6624,
      lng: -70.2586,
      city: 'Portland',
      state: 'ME',
    },
    '04102': {
      lat: 43.65877,
      lng: -70.30622,
      city: 'Portland',
      state: 'ME',
    },
    '04103': {
      lat: 43.69392,
      lng: -70.2909,
      city: 'Portland',
      state: 'ME',
    },
    '04105': {
      lat: 43.74753,
      lng: -70.2825,
      city: 'Falmouth',
      state: 'ME',
    },
    '04106': {
      lat: 43.63081,
      lng: -70.28972,
      city: 'South Portland',
      state: 'ME',
    },
    '04107': {
      lat: 43.5891,
      lng: -70.23801,
      city: 'Cape Elizabeth',
      state: 'ME',
    },
    '04108': {
      lat: 43.66057,
      lng: -70.18901,
      city: 'Peaks Island',
      state: 'ME',
    },
    '04109': {
      lat: 43.66732,
      lng: -70.19984,
      city: 'Portland',
      state: 'ME',
    },
    '04110': {
      lat: 43.75834,
      lng: -70.19905,
      city: 'Cumberland Foreside',
      state: 'ME',
    },
    '04210': {
      lat: 44.08505,
      lng: -70.24923,
      city: 'Auburn',
      state: 'ME',
    },
    '04216': {
      lat: 44.70046,
      lng: -70.8142,
      city: 'Andover',
      state: 'ME',
    },
    '04217': {
      lat: 44.36726,
      lng: -70.83364,
      city: 'Bethel',
      state: 'ME',
    },
    '04219': {
      lat: 44.40924,
      lng: -70.59285,
      city: 'Bryant Pond',
      state: 'ME',
    },
    '04220': {
      lat: 44.33511,
      lng: -70.35286,
      city: 'Buckfield',
      state: 'ME',
    },
    '04221': {
      lat: 44.47242,
      lng: -70.29857,
      city: 'Canton',
      state: 'ME',
    },
    '04222': {
      lat: 43.9644,
      lng: -70.12954,
      city: 'Durham',
      state: 'ME',
    },
    '04224': {
      lat: 44.58001,
      lng: -70.40058,
      city: 'Dixfield',
      state: 'ME',
    },
    '04226': {
      lat: 44.62555,
      lng: -70.70823,
      city: 'East Andover',
      state: 'ME',
    },
    '04227': {
      lat: 44.56866,
      lng: -70.29471,
      city: 'East Dixfield',
      state: 'ME',
    },
    '04231': {
      lat: 44.26438,
      lng: -70.88777,
      city: 'Stoneham',
      state: 'ME',
    },
    '04234': {
      lat: 44.61624,
      lng: -70.19359,
      city: 'East Wilton',
      state: 'ME',
    },
    '04236': {
      lat: 44.19036,
      lng: -70.14461,
      city: 'Greene',
      state: 'ME',
    },
    '04237': {
      lat: 44.49665,
      lng: -70.7256,
      city: 'Hanover',
      state: 'ME',
    },
    '04238': {
      lat: 44.20785,
      lng: -70.37937,
      city: 'Hebron',
      state: 'ME',
    },
    '04239': { lat: 44.53062, lng: -70.20935, city: 'Jay', state: 'ME' },
    '04240': {
      lat: 44.09147,
      lng: -70.16808,
      city: 'Lewiston',
      state: 'ME',
    },
    '04250': {
      lat: 44.02582,
      lng: -70.11706,
      city: 'Lisbon',
      state: 'ME',
    },
    '04252': {
      lat: 44.02719,
      lng: -70.06011,
      city: 'Lisbon Falls',
      state: 'ME',
    },
    '04253': {
      lat: 44.4079,
      lng: -70.21503,
      city: 'Livermore',
      state: 'ME',
    },
    '04254': {
      lat: 44.43249,
      lng: -70.14265,
      city: 'Livermore Falls',
      state: 'ME',
    },
    '04255': {
      lat: 44.33505,
      lng: -70.66863,
      city: 'Greenwood',
      state: 'ME',
    },
    '04256': {
      lat: 44.10294,
      lng: -70.40733,
      city: 'Mechanic Falls',
      state: 'ME',
    },
    '04257': {
      lat: 44.57347,
      lng: -70.51358,
      city: 'Mexico',
      state: 'ME',
    },
    '04258': {
      lat: 44.14829,
      lng: -70.33794,
      city: 'Minot',
      state: 'ME',
    },
    '04259': {
      lat: 44.22762,
      lng: -70.00868,
      city: 'Monmouth',
      state: 'ME',
    },
    '04260': {
      lat: 43.96409,
      lng: -70.29589,
      city: 'New Gloucester',
      state: 'ME',
    },
    '04261': { lat: 44.5631, lng: -70.91907, city: 'Newry', state: 'ME' },
    '04263': {
      lat: 44.29476,
      lng: -70.13061,
      city: 'Leeds',
      state: 'ME',
    },
    '04265': {
      lat: 44.27542,
      lng: -70.04276,
      city: 'North Monmouth',
      state: 'ME',
    },
    '04267': {
      lat: 44.22478,
      lng: -70.77084,
      city: 'North Waterford',
      state: 'ME',
    },
    '04268': {
      lat: 44.22842,
      lng: -70.60651,
      city: 'Norway',
      state: 'ME',
    },
    '04270': {
      lat: 44.11241,
      lng: -70.51559,
      city: 'Oxford',
      state: 'ME',
    },
    '04271': {
      lat: 44.26099,
      lng: -70.49871,
      city: 'Paris',
      state: 'ME',
    },
    '04274': {
      lat: 44.04634,
      lng: -70.38999,
      city: 'Poland',
      state: 'ME',
    },
    '04275': {
      lat: 44.68966,
      lng: -70.63729,
      city: 'Roxbury',
      state: 'ME',
    },
    '04276': {
      lat: 44.53469,
      lng: -70.61541,
      city: 'Rumford',
      state: 'ME',
    },
    '04280': {
      lat: 44.1215,
      lng: -70.06152,
      city: 'Sabattus',
      state: 'ME',
    },
    '04281': {
      lat: 44.2445,
      lng: -70.48832,
      city: 'South Paris',
      state: 'ME',
    },
    '04282': {
      lat: 44.26709,
      lng: -70.24291,
      city: 'Turner',
      state: 'ME',
    },
    '04284': { lat: 44.34757, lng: -70.0651, city: 'Wayne', state: 'ME' },
    '04285': { lat: 44.70625, lng: -70.4523, city: 'Weld', state: 'ME' },
    '04286': {
      lat: 44.4032,
      lng: -70.86019,
      city: 'West Bethel',
      state: 'ME',
    },
    '04287': {
      lat: 44.05812,
      lng: -69.9694,
      city: 'Bowdoin',
      state: 'ME',
    },
    '04289': {
      lat: 44.32108,
      lng: -70.53248,
      city: 'West Paris',
      state: 'ME',
    },
    '04290': { lat: 44.47354, lng: -70.45058, city: 'Peru', state: 'ME' },
    '04292': {
      lat: 44.37461,
      lng: -70.44674,
      city: 'Sumner',
      state: 'ME',
    },
    '04294': {
      lat: 44.62346,
      lng: -70.27075,
      city: 'Wilton',
      state: 'ME',
    },
    '04330': {
      lat: 44.36387,
      lng: -69.73729,
      city: 'Augusta',
      state: 'ME',
    },
    '04341': {
      lat: 44.25997,
      lng: -69.55485,
      city: 'Coopers Mills',
      state: 'ME',
    },
    '04342': {
      lat: 44.07942,
      lng: -69.73939,
      city: 'Dresden',
      state: 'ME',
    },
    '04344': {
      lat: 44.2588,
      lng: -69.82125,
      city: 'Farmingdale',
      state: 'ME',
    },
    '04345': {
      lat: 44.19816,
      lng: -69.7841,
      city: 'Gardiner',
      state: 'ME',
    },
    '04346': {
      lat: 44.23469,
      lng: -69.75068,
      city: 'Randolph',
      state: 'ME',
    },
    '04347': {
      lat: 44.29047,
      lng: -69.81399,
      city: 'Hallowell',
      state: 'ME',
    },
    '04348': {
      lat: 44.21521,
      lng: -69.49922,
      city: 'Jefferson',
      state: 'ME',
    },
    '04349': {
      lat: 44.43734,
      lng: -70.07014,
      city: 'Kents Hill',
      state: 'ME',
    },
    '04350': {
      lat: 44.16266,
      lng: -69.93942,
      city: 'Litchfield',
      state: 'ME',
    },
    '04351': {
      lat: 44.33174,
      lng: -69.86082,
      city: 'Manchester',
      state: 'ME',
    },
    '04352': {
      lat: 44.46478,
      lng: -69.96225,
      city: 'Mount Vernon',
      state: 'ME',
    },
    '04353': {
      lat: 44.2052,
      lng: -69.6126,
      city: 'Whitefield',
      state: 'ME',
    },
    '04354': {
      lat: 44.39602,
      lng: -69.42709,
      city: 'Palermo',
      state: 'ME',
    },
    '04355': {
      lat: 44.38227,
      lng: -69.94915,
      city: 'Readfield',
      state: 'ME',
    },
    '04357': {
      lat: 44.12366,
      lng: -69.82883,
      city: 'Richmond',
      state: 'ME',
    },
    '04358': {
      lat: 44.41821,
      lng: -69.53286,
      city: 'South China',
      state: 'ME',
    },
    '04359': {
      lat: 44.17947,
      lng: -69.76134,
      city: 'South Gardiner',
      state: 'ME',
    },
    '04360': {
      lat: 44.54741,
      lng: -70.00299,
      city: 'Vienna',
      state: 'ME',
    },
    '04363': {
      lat: 44.31101,
      lng: -69.57807,
      city: 'Windsor',
      state: 'ME',
    },
    '04364': {
      lat: 44.31158,
      lng: -69.96153,
      city: 'Winthrop',
      state: 'ME',
    },
    '04401': {
      lat: 44.84711,
      lng: -68.84654,
      city: 'Bangor',
      state: 'ME',
    },
    '04406': { lat: 45.2186, lng: -69.57816, city: 'Abbot', state: 'ME' },
    '04408': {
      lat: 44.9877,
      lng: -68.23246,
      city: 'Aurora',
      state: 'ME',
    },
    '04410': {
      lat: 45.08821,
      lng: -68.90696,
      city: 'Bradford',
      state: 'ME',
    },
    '04411': {
      lat: 44.88634,
      lng: -68.56672,
      city: 'Bradley',
      state: 'ME',
    },
    '04412': {
      lat: 44.78354,
      lng: -68.73517,
      city: 'Brewer',
      state: 'ME',
    },
    '04413': {
      lat: 45.55647,
      lng: -67.8274,
      city: 'Brookton',
      state: 'ME',
    },
    '04414': {
      lat: 45.40654,
      lng: -69.08568,
      city: 'Brownville',
      state: 'ME',
    },
    '04415': {
      lat: 45.35308,
      lng: -69.05832,
      city: 'Brownville Junction',
      state: 'ME',
    },
    '04416': {
      lat: 44.62725,
      lng: -68.75286,
      city: 'Bucksport',
      state: 'ME',
    },
    '04417': {
      lat: 45.19923,
      lng: -68.36728,
      city: 'Burlington',
      state: 'ME',
    },
    '04418': {
      lat: 45.07572,
      lng: -68.50689,
      city: 'Greenbush',
      state: 'ME',
    },
    '04419': {
      lat: 44.7969,
      lng: -69.03154,
      city: 'Carmel',
      state: 'ME',
    },
    '04421': {
      lat: 44.4111,
      lng: -68.79524,
      city: 'Castine',
      state: 'ME',
    },
    '04422': {
      lat: 45.07097,
      lng: -69.03408,
      city: 'Charleston',
      state: 'ME',
    },
    '04424': {
      lat: 45.67003,
      lng: -67.86144,
      city: 'Danforth',
      state: 'ME',
    },
    '04426': {
      lat: 45.1849,
      lng: -69.15827,
      city: 'Dover Foxcroft',
      state: 'ME',
    },
    '04427': {
      lat: 44.9805,
      lng: -69.01063,
      city: 'Corinth',
      state: 'ME',
    },
    '04428': {
      lat: 44.80643,
      lng: -68.55584,
      city: 'Eddington',
      state: 'ME',
    },
    '04429': {
      lat: 44.72039,
      lng: -68.6171,
      city: 'Holden',
      state: 'ME',
    },
    '04430': {
      lat: 45.64662,
      lng: -68.58646,
      city: 'East Millinocket',
      state: 'ME',
    },
    '04431': {
      lat: 44.56453,
      lng: -68.6678,
      city: 'East Orland',
      state: 'ME',
    },
    '04434': { lat: 44.78529, lng: -69.13385, city: 'Etna', state: 'ME' },
    '04435': {
      lat: 44.96435,
      lng: -69.13539,
      city: 'Exeter',
      state: 'ME',
    },
    '04438': {
      lat: 44.59856,
      lng: -68.91904,
      city: 'Frankfort',
      state: 'ME',
    },
    '04441': {
      lat: 45.68984,
      lng: -69.32252,
      city: 'Greenville',
      state: 'ME',
    },
    '04442': {
      lat: 45.47446,
      lng: -69.69538,
      city: 'Greenville Junction',
      state: 'ME',
    },
    '04443': {
      lat: 45.24412,
      lng: -69.40572,
      city: 'Guilford',
      state: 'ME',
    },
    '04444': {
      lat: 44.72591,
      lng: -68.94585,
      city: 'Hampden',
      state: 'ME',
    },
    '04448': {
      lat: 45.31208,
      lng: -68.71977,
      city: 'Howland',
      state: 'ME',
    },
    '04449': {
      lat: 44.99761,
      lng: -68.88357,
      city: 'Hudson',
      state: 'ME',
    },
    '04450': {
      lat: 44.91593,
      lng: -68.92797,
      city: 'Kenduskeag',
      state: 'ME',
    },
    '04451': {
      lat: 45.6035,
      lng: -68.21915,
      city: 'Kingman',
      state: 'ME',
    },
    '04453': {
      lat: 45.18229,
      lng: -68.78244,
      city: 'Lagrange',
      state: 'ME',
    },
    '04454': {
      lat: 45.5222,
      lng: -67.52516,
      city: 'Lambert Lake',
      state: 'ME',
    },
    '04455': { lat: 45.3223, lng: -68.27511, city: 'Lee', state: 'ME' },
    '04456': {
      lat: 44.88645,
      lng: -68.9977,
      city: 'Levant',
      state: 'ME',
    },
    '04457': {
      lat: 45.43432,
      lng: -68.52821,
      city: 'Lincoln',
      state: 'ME',
    },
    '04459': {
      lat: 45.58898,
      lng: -68.33948,
      city: 'Mattawamkeag',
      state: 'ME',
    },
    '04460': {
      lat: 45.64134,
      lng: -68.52638,
      city: 'Medway',
      state: 'ME',
    },
    '04461': {
      lat: 44.98057,
      lng: -68.57243,
      city: 'Milford',
      state: 'ME',
    },
    '04462': {
      lat: 45.86541,
      lng: -68.93072,
      city: 'Millinocket',
      state: 'ME',
    },
    '04463': { lat: 45.28117, lng: -68.90569, city: 'Milo', state: 'ME' },
    '04464': {
      lat: 45.29104,
      lng: -69.50202,
      city: 'Monson',
      state: 'ME',
    },
    '04468': {
      lat: 45.0263,
      lng: -68.72323,
      city: 'Old Town',
      state: 'ME',
    },
    '04469': {
      lat: 44.90251,
      lng: -68.66624,
      city: 'Orono',
      state: 'ME',
    },
    '04471': { lat: 45.8912, lng: -67.8471, city: 'Orient', state: 'ME' },
    '04472': {
      lat: 44.57334,
      lng: -68.67736,
      city: 'Orland',
      state: 'ME',
    },
    '04473': {
      lat: 44.88594,
      lng: -68.71888,
      city: 'Orono',
      state: 'ME',
    },
    '04474': {
      lat: 44.71501,
      lng: -68.77901,
      city: 'Orrington',
      state: 'ME',
    },
    '04475': {
      lat: 45.1845,
      lng: -68.58681,
      city: 'Passadumkeag',
      state: 'ME',
    },
    '04476': {
      lat: 44.47474,
      lng: -68.70521,
      city: 'Penobscot',
      state: 'ME',
    },
    '04478': {
      lat: 45.7299,
      lng: -69.76857,
      city: 'Rockwood',
      state: 'ME',
    },
    '04479': {
      lat: 45.12755,
      lng: -69.30688,
      city: 'Sangerville',
      state: 'ME',
    },
    '04481': {
      lat: 45.24298,
      lng: -69.10423,
      city: 'Sebec',
      state: 'ME',
    },
    '04485': {
      lat: 45.362,
      lng: -69.61562,
      city: 'Shirley Mills',
      state: 'ME',
    },
    '04487': {
      lat: 45.39371,
      lng: -68.1006,
      city: 'Springfield',
      state: 'ME',
    },
    '04488': {
      lat: 44.87586,
      lng: -69.11143,
      city: 'Stetson',
      state: 'ME',
    },
    '04489': {
      lat: 44.91074,
      lng: -68.69119,
      city: 'Stillwater',
      state: 'ME',
    },
    '04490': {
      lat: 45.39527,
      lng: -67.82458,
      city: 'Topsfield',
      state: 'ME',
    },
    '04491': {
      lat: 45.56705,
      lng: -67.51353,
      city: 'Vanceboro',
      state: 'ME',
    },
    '04492': { lat: 45.34738, lng: -67.6658, city: 'Waite', state: 'ME' },
    '04493': {
      lat: 45.23853,
      lng: -68.53301,
      city: 'West Enfield',
      state: 'ME',
    },
    '04495': { lat: 45.45464, lng: -68.32985, city: 'Winn', state: 'ME' },
    '04496': {
      lat: 44.65839,
      lng: -68.91189,
      city: 'Winterport',
      state: 'ME',
    },
    '04497': {
      lat: 45.7249,
      lng: -68.06619,
      city: 'Wytopitlock',
      state: 'ME',
    },
    '04530': { lat: 43.89156, lng: -69.8306, city: 'Bath', state: 'ME' },
    '04535': { lat: 44.08778, lng: -69.63436, city: 'Alna', state: 'ME' },
    '04537': {
      lat: 43.90241,
      lng: -69.62498,
      city: 'Boothbay',
      state: 'ME',
    },
    '04538': {
      lat: 43.85632,
      lng: -69.62807,
      city: 'Boothbay Harbor',
      state: 'ME',
    },
    '04539': {
      lat: 43.96429,
      lng: -69.50513,
      city: 'Bristol',
      state: 'ME',
    },
    '04541': {
      lat: 43.88671,
      lng: -69.48411,
      city: 'Chamberlain',
      state: 'ME',
    },
    '04543': {
      lat: 44.03446,
      lng: -69.49211,
      city: 'Damariscotta',
      state: 'ME',
    },
    '04544': {
      lat: 43.83316,
      lng: -69.58997,
      city: 'East Boothbay',
      state: 'ME',
    },
    '04547': {
      lat: 43.99388,
      lng: -69.32779,
      city: 'Friendship',
      state: 'ME',
    },
    '04548': {
      lat: 43.81499,
      lng: -69.74804,
      city: 'Georgetown',
      state: 'ME',
    },
    '04551': {
      lat: 44.00648,
      lng: -69.42701,
      city: 'Bremen',
      state: 'ME',
    },
    '04553': {
      lat: 44.04787,
      lng: -69.57145,
      city: 'Newcastle',
      state: 'ME',
    },
    '04554': {
      lat: 43.86348,
      lng: -69.50481,
      city: 'New Harbor',
      state: 'ME',
    },
    '04555': {
      lat: 44.10597,
      lng: -69.47687,
      city: 'Nobleboro',
      state: 'ME',
    },
    '04556': {
      lat: 43.96867,
      lng: -69.62311,
      city: 'Edgecomb',
      state: 'ME',
    },
    '04558': {
      lat: 43.89974,
      lng: -69.51499,
      city: 'Pemaquid',
      state: 'ME',
    },
    '04562': {
      lat: 43.78758,
      lng: -69.82764,
      city: 'Phippsburg',
      state: 'ME',
    },
    '04563': {
      lat: 44.0151,
      lng: -69.25655,
      city: 'Cushing',
      state: 'ME',
    },
    '04564': {
      lat: 43.93089,
      lng: -69.46761,
      city: 'Round Pond',
      state: 'ME',
    },
    '04568': {
      lat: 43.89652,
      lng: -69.56153,
      city: 'South Bristol',
      state: 'ME',
    },
    '04570': {
      lat: 43.80844,
      lng: -69.63088,
      city: 'Squirrel Island',
      state: 'ME',
    },
    '04571': {
      lat: 43.89949,
      lng: -69.67516,
      city: 'Trevett',
      state: 'ME',
    },
    '04572': {
      lat: 44.10984,
      lng: -69.36956,
      city: 'Waldoboro',
      state: 'ME',
    },
    '04573': {
      lat: 43.95063,
      lng: -69.55025,
      city: 'Walpole',
      state: 'ME',
    },
    '04574': {
      lat: 44.2734,
      lng: -69.39105,
      city: 'Washington',
      state: 'ME',
    },
    '04575': {
      lat: 43.85383,
      lng: -69.66016,
      city: 'West Boothbay Harbor',
      state: 'ME',
    },
    '04576': {
      lat: 43.82244,
      lng: -69.6671,
      city: 'Southport',
      state: 'ME',
    },
    '04578': {
      lat: 43.98136,
      lng: -69.6926,
      city: 'Wiscasset',
      state: 'ME',
    },
    '04579': {
      lat: 43.96216,
      lng: -69.76872,
      city: 'Woolwich',
      state: 'ME',
    },
    '04605': {
      lat: 44.66038,
      lng: -68.41623,
      city: 'Ellsworth',
      state: 'ME',
    },
    '04606': {
      lat: 44.57697,
      lng: -67.70281,
      city: 'Addison',
      state: 'ME',
    },
    '04607': {
      lat: 44.47689,
      lng: -68.05228,
      city: 'Gouldsboro',
      state: 'ME',
    },
    '04609': {
      lat: 44.38518,
      lng: -68.27108,
      city: 'Bar Harbor',
      state: 'ME',
    },
    '04611': {
      lat: 44.48771,
      lng: -67.59633,
      city: 'Beals',
      state: 'ME',
    },
    '04612': {
      lat: 44.25954,
      lng: -68.37722,
      city: 'Bernard',
      state: 'ME',
    },
    '04613': {
      lat: 44.37498,
      lng: -68.03593,
      city: 'Birch Harbor',
      state: 'ME',
    },
    '04614': {
      lat: 44.41819,
      lng: -68.58223,
      city: 'Blue Hill',
      state: 'ME',
    },
    '04616': {
      lat: 44.28346,
      lng: -68.57069,
      city: 'Brooklin',
      state: 'ME',
    },
    '04617': {
      lat: 44.36929,
      lng: -68.73081,
      city: 'Brooksville',
      state: 'ME',
    },
    '04619': {
      lat: 45.13397,
      lng: -67.22401,
      city: 'Calais',
      state: 'ME',
    },
    '04622': {
      lat: 44.75555,
      lng: -67.95143,
      city: 'Cherryfield',
      state: 'ME',
    },
    '04623': {
      lat: 44.70786,
      lng: -67.70824,
      city: 'Columbia Falls',
      state: 'ME',
    },
    '04624': { lat: 44.42438, lng: -67.9877, city: 'Corea', state: 'ME' },
    '04625': {
      lat: 44.25041,
      lng: -68.25503,
      city: 'Cranberry Isles',
      state: 'ME',
    },
    '04626': {
      lat: 44.68494,
      lng: -67.22169,
      city: 'Cutler',
      state: 'ME',
    },
    '04627': {
      lat: 44.22275,
      lng: -68.66581,
      city: 'Deer Isle',
      state: 'ME',
    },
    '04628': {
      lat: 44.87275,
      lng: -67.28238,
      city: 'Dennysville',
      state: 'ME',
    },
    '04630': {
      lat: 44.8471,
      lng: -67.47287,
      city: 'East Machias',
      state: 'ME',
    },
    '04631': {
      lat: 44.91373,
      lng: -67.01834,
      city: 'Eastport',
      state: 'ME',
    },
    '04634': {
      lat: 44.63457,
      lng: -68.22253,
      city: 'Franklin',
      state: 'ME',
    },
    '04635': {
      lat: 44.11376,
      lng: -68.35479,
      city: 'Frenchboro',
      state: 'ME',
    },
    '04640': {
      lat: 44.53803,
      lng: -68.30573,
      city: 'Hancock',
      state: 'ME',
    },
    '04642': {
      lat: 44.33906,
      lng: -68.79591,
      city: 'Harborside',
      state: 'ME',
    },
    '04643': {
      lat: 44.57876,
      lng: -67.80562,
      city: 'Harrington',
      state: 'ME',
    },
    '04645': {
      lat: 44.05408,
      lng: -68.6282,
      city: 'Isle Au Haut',
      state: 'ME',
    },
    '04646': {
      lat: 44.26007,
      lng: -68.22812,
      city: 'Islesford',
      state: 'ME',
    },
    '04648': {
      lat: 44.65899,
      lng: -67.59585,
      city: 'Jonesboro',
      state: 'ME',
    },
    '04649': {
      lat: 44.56606,
      lng: -67.5817,
      city: 'Jonesport',
      state: 'ME',
    },
    '04650': {
      lat: 44.28924,
      lng: -68.71412,
      city: 'Little Deer Isle',
      state: 'ME',
    },
    '04652': {
      lat: 44.82234,
      lng: -67.07457,
      city: 'Lubec',
      state: 'ME',
    },
    '04653': {
      lat: 44.23705,
      lng: -68.3431,
      city: 'Bass Harbor',
      state: 'ME',
    },
    '04654': {
      lat: 44.78128,
      lng: -67.57716,
      city: 'Machias',
      state: 'ME',
    },
    '04655': {
      lat: 44.66467,
      lng: -67.39318,
      city: 'Machiasport',
      state: 'ME',
    },
    '04657': {
      lat: 44.97624,
      lng: -67.41965,
      city: 'Meddybemps',
      state: 'ME',
    },
    '04658': {
      lat: 44.53873,
      lng: -67.87257,
      city: 'Milbridge',
      state: 'ME',
    },
    '04660': {
      lat: 44.34026,
      lng: -68.33231,
      city: 'Mount Desert',
      state: 'ME',
    },
    '04662': {
      lat: 44.30766,
      lng: -68.28735,
      city: 'Northeast Harbor',
      state: 'ME',
    },
    '04664': {
      lat: 44.53906,
      lng: -68.12797,
      city: 'Sullivan',
      state: 'ME',
    },
    '04666': {
      lat: 44.97646,
      lng: -67.22204,
      city: 'Pembroke',
      state: 'ME',
    },
    '04667': {
      lat: 44.97853,
      lng: -67.10413,
      city: 'Perry',
      state: 'ME',
    },
    '04668': {
      lat: 45.19846,
      lng: -67.70543,
      city: 'Princeton',
      state: 'ME',
    },
    '04669': {
      lat: 44.40014,
      lng: -68.01841,
      city: 'Prospect Harbor',
      state: 'ME',
    },
    '04671': {
      lat: 45.06618,
      lng: -67.16247,
      city: 'Robbinston',
      state: 'ME',
    },
    '04673': {
      lat: 44.30484,
      lng: -68.67028,
      city: 'Sargentville',
      state: 'ME',
    },
    '04674': {
      lat: 44.29347,
      lng: -68.40883,
      city: 'Seal Cove',
      state: 'ME',
    },
    '04675': {
      lat: 44.30083,
      lng: -68.24223,
      city: 'Seal Harbor',
      state: 'ME',
    },
    '04676': {
      lat: 44.34741,
      lng: -68.64238,
      city: 'Sedgwick',
      state: 'ME',
    },
    '04677': {
      lat: 44.49016,
      lng: -68.17945,
      city: 'Sorrento',
      state: 'ME',
    },
    '04679': {
      lat: 44.27843,
      lng: -68.33247,
      city: 'Southwest Harbor',
      state: 'ME',
    },
    '04680': {
      lat: 44.50699,
      lng: -67.94485,
      city: 'Steuben',
      state: 'ME',
    },
    '04681': {
      lat: 44.16655,
      lng: -68.65646,
      city: 'Stonington',
      state: 'ME',
    },
    '04683': {
      lat: 44.23596,
      lng: -68.82368,
      city: 'Sunset',
      state: 'ME',
    },
    '04684': {
      lat: 44.49335,
      lng: -68.52796,
      city: 'Surry',
      state: 'ME',
    },
    '04685': {
      lat: 44.15869,
      lng: -68.44015,
      city: 'Swans Island',
      state: 'ME',
    },
    '04686': {
      lat: 44.98619,
      lng: -67.69015,
      city: 'Wesley',
      state: 'ME',
    },
    '04691': {
      lat: 44.76178,
      lng: -67.25217,
      city: 'Whiting',
      state: 'ME',
    },
    '04693': {
      lat: 44.38479,
      lng: -68.07828,
      city: 'Winter Harbor',
      state: 'ME',
    },
    '04694': {
      lat: 45.09987,
      lng: -67.46518,
      city: 'Baileyville',
      state: 'ME',
    },
    '04730': {
      lat: 46.12574,
      lng: -67.91471,
      city: 'Houlton',
      state: 'ME',
    },
    '04732': {
      lat: 46.56679,
      lng: -68.49326,
      city: 'Ashland',
      state: 'ME',
    },
    '04733': {
      lat: 45.74275,
      lng: -68.40311,
      city: 'Benedicta',
      state: 'ME',
    },
    '04734': {
      lat: 46.48479,
      lng: -67.91955,
      city: 'Blaine',
      state: 'ME',
    },
    '04735': {
      lat: 46.38072,
      lng: -68.02865,
      city: 'Bridgewater',
      state: 'ME',
    },
    '04736': {
      lat: 46.90661,
      lng: -68.01998,
      city: 'Caribou',
      state: 'ME',
    },
    '04737': {
      lat: 46.61641,
      lng: -69.52178,
      city: 'Clayton Lake',
      state: 'ME',
    },
    '04739': {
      lat: 46.97137,
      lng: -68.61049,
      city: 'Eagle Lake',
      state: 'ME',
    },
    '04740': {
      lat: 46.64325,
      lng: -67.85487,
      city: 'Easton',
      state: 'ME',
    },
    '04742': {
      lat: 46.77766,
      lng: -67.85526,
      city: 'Fort Fairfield',
      state: 'ME',
    },
    '04743': {
      lat: 47.15427,
      lng: -68.72512,
      city: 'Fort Kent',
      state: 'ME',
    },
    '04745': {
      lat: 47.27371,
      lng: -68.39936,
      city: 'Frenchville',
      state: 'ME',
    },
    '04746': {
      lat: 47.24885,
      lng: -68.12472,
      city: 'Grand Isle',
      state: 'ME',
    },
    '04747': {
      lat: 45.94854,
      lng: -68.19649,
      city: 'Island Falls',
      state: 'ME',
    },
    '04750': {
      lat: 46.9582,
      lng: -67.85718,
      city: 'Limestone',
      state: 'ME',
    },
    '04756': {
      lat: 47.33054,
      lng: -68.30176,
      city: 'Madawaska',
      state: 'ME',
    },
    '04757': {
      lat: 46.6141,
      lng: -68.18278,
      city: 'Mapleton',
      state: 'ME',
    },
    '04758': {
      lat: 46.55426,
      lng: -67.84732,
      city: 'Mars Hill',
      state: 'ME',
    },
    '04760': {
      lat: 46.32354,
      lng: -67.90928,
      city: 'Monticello',
      state: 'ME',
    },
    '04761': {
      lat: 46.11857,
      lng: -67.97183,
      city: 'New Limerick',
      state: 'ME',
    },
    '04762': {
      lat: 46.97574,
      lng: -68.12094,
      city: 'New Sweden',
      state: 'ME',
    },
    '04763': {
      lat: 46.08068,
      lng: -68.10371,
      city: 'Oakfield',
      state: 'ME',
    },
    '04764': {
      lat: 46.36843,
      lng: -68.34229,
      city: 'Oxbow',
      state: 'ME',
    },
    '04765': {
      lat: 46.06884,
      lng: -68.64477,
      city: 'Patten',
      state: 'ME',
    },
    '04766': {
      lat: 46.88162,
      lng: -68.23911,
      city: 'Perham',
      state: 'ME',
    },
    '04768': {
      lat: 46.7878,
      lng: -68.82875,
      city: 'Portage',
      state: 'ME',
    },
    '04769': {
      lat: 46.68676,
      lng: -67.98737,
      city: 'Presque Isle',
      state: 'ME',
    },
    '04772': {
      lat: 47.23375,
      lng: -68.32732,
      city: 'Saint Agatha',
      state: 'ME',
    },
    '04773': {
      lat: 47.27426,
      lng: -68.22649,
      city: 'Saint David',
      state: 'ME',
    },
    '04774': {
      lat: 47.10285,
      lng: -69.05048,
      city: 'Saint Francis',
      state: 'ME',
    },
    '04776': {
      lat: 45.8105,
      lng: -68.31818,
      city: 'Sherman',
      state: 'ME',
    },
    '04777': {
      lat: 45.83515,
      lng: -68.48952,
      city: 'Stacyville',
      state: 'ME',
    },
    '04779': {
      lat: 47.11615,
      lng: -68.31162,
      city: 'Sinclair',
      state: 'ME',
    },
    '04780': {
      lat: 46.1677,
      lng: -68.26924,
      city: 'Smyrna Mills',
      state: 'ME',
    },
    '04781': {
      lat: 47.14634,
      lng: -68.61883,
      city: 'Wallagrass',
      state: 'ME',
    },
    '04783': {
      lat: 47.03586,
      lng: -68.18907,
      city: 'Stockholm',
      state: 'ME',
    },
    '04785': {
      lat: 47.12678,
      lng: -68.00368,
      city: 'Van Buren',
      state: 'ME',
    },
    '04786': {
      lat: 46.79207,
      lng: -68.17878,
      city: 'Washburn',
      state: 'ME',
    },
    '04787': {
      lat: 46.55166,
      lng: -67.97685,
      city: 'Westfield',
      state: 'ME',
    },
    '04841': {
      lat: 44.12739,
      lng: -69.13686,
      city: 'Rockland',
      state: 'ME',
    },
    '04843': {
      lat: 44.22929,
      lng: -69.08924,
      city: 'Camden',
      state: 'ME',
    },
    '04847': { lat: 44.25104, lng: -69.18962, city: 'Hope', state: 'ME' },
    '04848': {
      lat: 44.31083,
      lng: -68.90896,
      city: 'Islesboro',
      state: 'ME',
    },
    '04849': {
      lat: 44.3179,
      lng: -69.04753,
      city: 'Lincolnville',
      state: 'ME',
    },
    '04851': {
      lat: 43.85227,
      lng: -68.8916,
      city: 'Matinicus',
      state: 'ME',
    },
    '04852': {
      lat: 43.76561,
      lng: -69.31235,
      city: 'Monhegan',
      state: 'ME',
    },
    '04853': {
      lat: 44.15172,
      lng: -68.86311,
      city: 'North Haven',
      state: 'ME',
    },
    '04854': {
      lat: 44.04344,
      lng: -69.08504,
      city: 'Owls Head',
      state: 'ME',
    },
    '04855': {
      lat: 43.93797,
      lng: -69.26958,
      city: 'Port Clyde',
      state: 'ME',
    },
    '04856': {
      lat: 44.17798,
      lng: -69.12007,
      city: 'Rockport',
      state: 'ME',
    },
    '04858': {
      lat: 44.04638,
      lng: -69.1452,
      city: 'South Thomaston',
      state: 'ME',
    },
    '04859': {
      lat: 44.00068,
      lng: -69.16112,
      city: 'Spruce Head',
      state: 'ME',
    },
    '04860': {
      lat: 43.968,
      lng: -69.22817,
      city: 'Tenants Harbor',
      state: 'ME',
    },
    '04861': {
      lat: 44.09302,
      lng: -69.17483,
      city: 'Thomaston',
      state: 'ME',
    },
    '04862': {
      lat: 44.26186,
      lng: -69.27703,
      city: 'Union',
      state: 'ME',
    },
    '04863': {
      lat: 44.07898,
      lng: -68.84384,
      city: 'Vinalhaven',
      state: 'ME',
    },
    '04864': {
      lat: 44.12946,
      lng: -69.24481,
      city: 'Warren',
      state: 'ME',
    },
    '04901': {
      lat: 44.55449,
      lng: -69.57037,
      city: 'Waterville',
      state: 'ME',
    },
    '04910': { lat: 44.517, lng: -69.44123, city: 'Albion', state: 'ME' },
    '04911': {
      lat: 44.78039,
      lng: -69.96112,
      city: 'Anson',
      state: 'ME',
    },
    '04912': {
      lat: 44.97286,
      lng: -69.68045,
      city: 'Athens',
      state: 'ME',
    },
    '04915': {
      lat: 44.46643,
      lng: -69.04156,
      city: 'Belfast',
      state: 'ME',
    },
    '04917': {
      lat: 44.48959,
      lng: -69.84167,
      city: 'Belgrade',
      state: 'ME',
    },
    '04918': {
      lat: 44.55027,
      lng: -69.86651,
      city: 'Belgrade Lakes',
      state: 'ME',
    },
    '04920': {
      lat: 45.11323,
      lng: -69.83863,
      city: 'Bingham',
      state: 'ME',
    },
    '04921': {
      lat: 44.57256,
      lng: -69.13326,
      city: 'Brooks',
      state: 'ME',
    },
    '04922': {
      lat: 44.68739,
      lng: -69.38255,
      city: 'Burnham',
      state: 'ME',
    },
    '04923': {
      lat: 45.03722,
      lng: -69.44533,
      city: 'Cambridge',
      state: 'ME',
    },
    '04924': {
      lat: 44.77922,
      lng: -69.54967,
      city: 'Canaan',
      state: 'ME',
    },
    '04925': {
      lat: 45.21477,
      lng: -69.90509,
      city: 'Caratunk',
      state: 'ME',
    },
    '04926': {
      lat: 44.48035,
      lng: -69.51521,
      city: 'China Village',
      state: 'ME',
    },
    '04927': {
      lat: 44.67021,
      lng: -69.53589,
      city: 'Clinton',
      state: 'ME',
    },
    '04928': {
      lat: 44.94973,
      lng: -69.25695,
      city: 'Corinna',
      state: 'ME',
    },
    '04929': {
      lat: 44.76238,
      lng: -69.31131,
      city: 'Detroit',
      state: 'ME',
    },
    '04930': {
      lat: 45.02656,
      lng: -69.32835,
      city: 'Dexter',
      state: 'ME',
    },
    '04932': {
      lat: 44.6957,
      lng: -69.13335,
      city: 'Dixmont',
      state: 'ME',
    },
    '04936': {
      lat: 45.32923,
      lng: -70.62715,
      city: 'Eustis',
      state: 'ME',
    },
    '04937': {
      lat: 44.63546,
      lng: -69.67635,
      city: 'Fairfield',
      state: 'ME',
    },
    '04938': {
      lat: 44.65881,
      lng: -70.10329,
      city: 'Farmington',
      state: 'ME',
    },
    '04939': {
      lat: 45.05436,
      lng: -69.15859,
      city: 'Garland',
      state: 'ME',
    },
    '04940': {
      lat: 44.62018,
      lng: -70.07508,
      city: 'Farmington Falls',
      state: 'ME',
    },
    '04941': {
      lat: 44.4654,
      lng: -69.3017,
      city: 'Freedom',
      state: 'ME',
    },
    '04942': {
      lat: 45.04148,
      lng: -69.59241,
      city: 'Harmony',
      state: 'ME',
    },
    '04943': {
      lat: 44.87994,
      lng: -69.52054,
      city: 'Hartland',
      state: 'ME',
    },
    '04944': {
      lat: 44.68737,
      lng: -69.64237,
      city: 'Hinckley',
      state: 'ME',
    },
    '04945': {
      lat: 45.57669,
      lng: -70.27974,
      city: 'Jackman',
      state: 'ME',
    },
    '04947': {
      lat: 45.03319,
      lng: -70.2391,
      city: 'Kingfield',
      state: 'ME',
    },
    '04949': {
      lat: 44.36712,
      lng: -69.34029,
      city: 'Liberty',
      state: 'ME',
    },
    '04950': {
      lat: 44.83241,
      lng: -69.80556,
      city: 'Madison',
      state: 'ME',
    },
    '04951': {
      lat: 44.60716,
      lng: -69.04575,
      city: 'Monroe',
      state: 'ME',
    },
    '04952': {
      lat: 44.40934,
      lng: -69.14261,
      city: 'Morrill',
      state: 'ME',
    },
    '04953': {
      lat: 44.86017,
      lng: -69.23297,
      city: 'Newport',
      state: 'ME',
    },
    '04955': {
      lat: 44.63998,
      lng: -70.00799,
      city: 'New Sharon',
      state: 'ME',
    },
    '04956': {
      lat: 44.80533,
      lng: -70.11215,
      city: 'New Vineyard',
      state: 'ME',
    },
    '04957': {
      lat: 44.70419,
      lng: -69.8471,
      city: 'Norridgewock',
      state: 'ME',
    },
    '04958': {
      lat: 44.90561,
      lng: -69.92494,
      city: 'North Anson',
      state: 'ME',
    },
    '04961': {
      lat: 45.16024,
      lng: -70.1665,
      city: 'New Portland',
      state: 'ME',
    },
    '04963': {
      lat: 44.56621,
      lng: -69.81463,
      city: 'Oakland',
      state: 'ME',
    },
    '04964': {
      lat: 44.9355,
      lng: -70.83584,
      city: 'Oquossoc',
      state: 'ME',
    },
    '04965': {
      lat: 44.84563,
      lng: -69.36272,
      city: 'Palmyra',
      state: 'ME',
    },
    '04966': {
      lat: 44.82476,
      lng: -70.47877,
      city: 'Phillips',
      state: 'ME',
    },
    '04967': {
      lat: 44.77138,
      lng: -69.43866,
      city: 'Pittsfield',
      state: 'ME',
    },
    '04969': {
      lat: 44.77019,
      lng: -69.22461,
      city: 'Plymouth',
      state: 'ME',
    },
    '04970': {
      lat: 45.00032,
      lng: -70.63519,
      city: 'Rangeley',
      state: 'ME',
    },
    '04971': {
      lat: 44.93191,
      lng: -69.39225,
      city: 'Saint Albans',
      state: 'ME',
    },
    '04973': {
      lat: 44.36229,
      lng: -69.19303,
      city: 'Searsmont',
      state: 'ME',
    },
    '04974': {
      lat: 44.4893,
      lng: -68.92996,
      city: 'Searsport',
      state: 'ME',
    },
    '04975': {
      lat: 44.62549,
      lng: -69.58837,
      city: 'Shawmut',
      state: 'ME',
    },
    '04976': {
      lat: 44.79873,
      lng: -69.66951,
      city: 'Skowhegan',
      state: 'ME',
    },
    '04978': {
      lat: 44.63466,
      lng: -69.80789,
      city: 'Smithfield',
      state: 'ME',
    },
    '04979': {
      lat: 44.94105,
      lng: -69.79731,
      city: 'Solon',
      state: 'ME',
    },
    '04981': {
      lat: 44.53228,
      lng: -68.85941,
      city: 'Stockton Springs',
      state: 'ME',
    },
    '04982': {
      lat: 45.11443,
      lng: -70.40494,
      city: 'Stratton',
      state: 'ME',
    },
    '04983': {
      lat: 44.92171,
      lng: -70.30599,
      city: 'Strong',
      state: 'ME',
    },
    '04984': {
      lat: 44.69648,
      lng: -70.28054,
      city: 'Temple',
      state: 'ME',
    },
    '04985': {
      lat: 45.3751,
      lng: -69.8746,
      city: 'West Forks',
      state: 'ME',
    },
    '04986': {
      lat: 44.55286,
      lng: -69.22354,
      city: 'Thorndike',
      state: 'ME',
    },
    '04987': { lat: 44.67915, lng: -69.25361, city: 'Troy', state: 'ME' },
    '04988': {
      lat: 44.59588,
      lng: -69.35959,
      city: 'Unity',
      state: 'ME',
    },
    '04989': {
      lat: 44.4299,
      lng: -69.64784,
      city: 'Vassalboro',
      state: 'ME',
    },
    '04992': {
      lat: 44.65983,
      lng: -70.15584,
      city: 'West Farmington',
      state: 'ME',
    },
    '05001': {
      lat: 43.67309,
      lng: -72.37943,
      city: 'White River Junction',
      state: 'VT',
    },
    '05009': {
      lat: 43.64769,
      lng: -72.34395,
      city: 'White River Junction',
      state: 'VT',
    },
    '05030': {
      lat: 43.39989,
      lng: -72.40981,
      city: 'Ascutney',
      state: 'VT',
    },
    '05031': {
      lat: 43.72361,
      lng: -72.5872,
      city: 'Barnard',
      state: 'VT',
    },
    '05032': {
      lat: 43.80193,
      lng: -72.65708,
      city: 'Bethel',
      state: 'VT',
    },
    '05033': {
      lat: 44.00229,
      lng: -72.16412,
      city: 'Bradford',
      state: 'VT',
    },
    '05034': {
      lat: 43.58921,
      lng: -72.62178,
      city: 'Bridgewater',
      state: 'VT',
    },
    '05035': {
      lat: 43.61723,
      lng: -72.67998,
      city: 'Bridgewater Corners',
      state: 'VT',
    },
    '05036': {
      lat: 44.026,
      lng: -72.57843,
      city: 'Brookfield',
      state: 'VT',
    },
    '05037': {
      lat: 43.46033,
      lng: -72.48371,
      city: 'Brownsville',
      state: 'VT',
    },
    '05038': {
      lat: 43.99313,
      lng: -72.45859,
      city: 'Chelsea',
      state: 'VT',
    },
    '05039': {
      lat: 44.03407,
      lng: -72.2975,
      city: 'Corinth',
      state: 'VT',
    },
    '05040': {
      lat: 44.06796,
      lng: -72.20438,
      city: 'East Corinth',
      state: 'VT',
    },
    '05041': {
      lat: 43.96702,
      lng: -72.54264,
      city: 'East Randolph',
      state: 'VT',
    },
    '05042': {
      lat: 44.24013,
      lng: -72.1053,
      city: 'East Ryegate',
      state: 'VT',
    },
    '05043': {
      lat: 43.80658,
      lng: -72.21194,
      city: 'East Thetford',
      state: 'VT',
    },
    '05045': {
      lat: 43.9144,
      lng: -72.19881,
      city: 'Fairlee',
      state: 'VT',
    },
    '05046': {
      lat: 44.23864,
      lng: -72.25024,
      city: 'Groton',
      state: 'VT',
    },
    '05048': {
      lat: 43.5798,
      lng: -72.42692,
      city: 'Hartland',
      state: 'VT',
    },
    '05050': {
      lat: 44.26442,
      lng: -72.06414,
      city: 'McIndoe Falls',
      state: 'VT',
    },
    '05051': {
      lat: 44.08525,
      lng: -72.11753,
      city: 'Newbury',
      state: 'VT',
    },
    '05052': {
      lat: 43.6008,
      lng: -72.35698,
      city: 'North Hartland',
      state: 'VT',
    },
    '05053': {
      lat: 43.71799,
      lng: -72.48362,
      city: 'North Pomfret',
      state: 'VT',
    },
    '05055': {
      lat: 43.75481,
      lng: -72.31145,
      city: 'Norwich',
      state: 'VT',
    },
    '05056': {
      lat: 43.52895,
      lng: -72.72397,
      city: 'Plymouth',
      state: 'VT',
    },
    '05058': {
      lat: 43.87917,
      lng: -72.26999,
      city: 'Post Mills',
      state: 'VT',
    },
    '05059': {
      lat: 43.64233,
      lng: -72.43061,
      city: 'Quechee',
      state: 'VT',
    },
    '05060': {
      lat: 43.96199,
      lng: -72.68594,
      city: 'Randolph',
      state: 'VT',
    },
    '05061': {
      lat: 43.93023,
      lng: -72.58087,
      city: 'Randolph Center',
      state: 'VT',
    },
    '05062': {
      lat: 43.5012,
      lng: -72.58358,
      city: 'Reading',
      state: 'VT',
    },
    '05065': {
      lat: 43.79079,
      lng: -72.41617,
      city: 'Sharon',
      state: 'VT',
    },
    '05067': {
      lat: 43.69162,
      lng: -72.53944,
      city: 'South Pomfret',
      state: 'VT',
    },
    '05068': {
      lat: 43.79188,
      lng: -72.52931,
      city: 'South Royalton',
      state: 'VT',
    },
    '05069': {
      lat: 44.17366,
      lng: -72.14317,
      city: 'South Ryegate',
      state: 'VT',
    },
    '05070': {
      lat: 43.84225,
      lng: -72.35099,
      city: 'South Strafford',
      state: 'VT',
    },
    '05071': {
      lat: 43.56461,
      lng: -72.56842,
      city: 'South Woodstock',
      state: 'VT',
    },
    '05072': {
      lat: 43.88132,
      lng: -72.38545,
      city: 'Strafford',
      state: 'VT',
    },
    '05073': {
      lat: 43.62901,
      lng: -72.46108,
      city: 'Taftsville',
      state: 'VT',
    },
    '05075': {
      lat: 43.84356,
      lng: -72.28255,
      city: 'Thetford Center',
      state: 'VT',
    },
    '05076': {
      lat: 44.14503,
      lng: -72.22659,
      city: 'Topsham',
      state: 'VT',
    },
    '05077': {
      lat: 43.89961,
      lng: -72.47789,
      city: 'Tunbridge',
      state: 'VT',
    },
    '05079': {
      lat: 43.95622,
      lng: -72.32214,
      city: 'Vershire',
      state: 'VT',
    },
    '05081': {
      lat: 44.14292,
      lng: -72.08451,
      city: 'Wells River',
      state: 'VT',
    },
    '05083': {
      lat: 43.9184,
      lng: -72.26782,
      city: 'West Fairlee',
      state: 'VT',
    },
    '05084': {
      lat: 43.72791,
      lng: -72.45177,
      city: 'West Hartford',
      state: 'VT',
    },
    '05086': {
      lat: 44.1182,
      lng: -72.30249,
      city: 'West Topsham',
      state: 'VT',
    },
    '05088': {
      lat: 43.67332,
      lng: -72.30726,
      city: 'Wilder',
      state: 'VT',
    },
    '05089': {
      lat: 43.49686,
      lng: -72.4515,
      city: 'Windsor',
      state: 'VT',
    },
    '05091': {
      lat: 43.64707,
      lng: -72.55165,
      city: 'Woodstock',
      state: 'VT',
    },
    '05101': {
      lat: 43.1783,
      lng: -72.48333,
      city: 'Bellows Falls',
      state: 'VT',
    },
    '05141': {
      lat: 43.15615,
      lng: -72.55929,
      city: 'Cambridgeport',
      state: 'VT',
    },
    '05142': {
      lat: 43.40454,
      lng: -72.58457,
      city: 'Cavendish',
      state: 'VT',
    },
    '05143': {
      lat: 43.26267,
      lng: -72.63095,
      city: 'Chester',
      state: 'VT',
    },
    '05146': {
      lat: 43.17867,
      lng: -72.62307,
      city: 'Grafton',
      state: 'VT',
    },
    '05148': {
      lat: 43.24503,
      lng: -72.81224,
      city: 'Londonderry',
      state: 'VT',
    },
    '05149': {
      lat: 43.40368,
      lng: -72.7029,
      city: 'Ludlow',
      state: 'VT',
    },
    '05150': {
      lat: 43.33653,
      lng: -72.52727,
      city: 'North Springfield',
      state: 'VT',
    },
    '05151': {
      lat: 43.39374,
      lng: -72.49566,
      city: 'Perkinsville',
      state: 'VT',
    },
    '05152': { lat: 43.251, lng: -72.92008, city: 'Peru', state: 'VT' },
    '05153': {
      lat: 43.42885,
      lng: -72.63487,
      city: 'Proctorsville',
      state: 'VT',
    },
    '05154': {
      lat: 43.13558,
      lng: -72.53424,
      city: 'Saxtons River',
      state: 'VT',
    },
    '05155': {
      lat: 43.17717,
      lng: -72.8228,
      city: 'South Londonderry',
      state: 'VT',
    },
    '05156': {
      lat: 43.30719,
      lng: -72.46928,
      city: 'Springfield',
      state: 'VT',
    },
    '05158': {
      lat: 43.10299,
      lng: -72.47296,
      city: 'Westminster',
      state: 'VT',
    },
    '05161': { lat: 43.3166, lng: -72.8067, city: 'Weston', state: 'VT' },
    '05201': {
      lat: 42.87652,
      lng: -73.1426,
      city: 'Bennington',
      state: 'VT',
    },
    '05250': {
      lat: 43.12048,
      lng: -73.19337,
      city: 'Arlington',
      state: 'VT',
    },
    '05251': {
      lat: 43.26189,
      lng: -73.08269,
      city: 'Dorset',
      state: 'VT',
    },
    '05252': {
      lat: 43.06642,
      lng: -73.06132,
      city: 'East Arlington',
      state: 'VT',
    },
    '05253': {
      lat: 43.25235,
      lng: -72.99913,
      city: 'East Dorset',
      state: 'VT',
    },
    '05254': {
      lat: 43.15937,
      lng: -73.06911,
      city: 'Manchester',
      state: 'VT',
    },
    '05255': {
      lat: 43.16696,
      lng: -73.06685,
      city: 'Manchester Center',
      state: 'VT',
    },
    '05257': {
      lat: 42.96538,
      lng: -73.25156,
      city: 'North Bennington',
      state: 'VT',
    },
    '05260': {
      lat: 42.81594,
      lng: -73.26173,
      city: 'North Pownal',
      state: 'VT',
    },
    '05261': {
      lat: 42.77516,
      lng: -73.21273,
      city: 'Pownal',
      state: 'VT',
    },
    '05262': {
      lat: 42.97935,
      lng: -73.12066,
      city: 'Shaftsbury',
      state: 'VT',
    },
    '05301': {
      lat: 42.84346,
      lng: -72.65901,
      city: 'Brattleboro',
      state: 'VT',
    },
    '05340': {
      lat: 43.16215,
      lng: -72.93486,
      city: 'Bondville',
      state: 'VT',
    },
    '05341': {
      lat: 42.96407,
      lng: -72.7972,
      city: 'East Dover',
      state: 'VT',
    },
    '05342': {
      lat: 42.77468,
      lng: -72.80015,
      city: 'Jacksonville',
      state: 'VT',
    },
    '05343': {
      lat: 43.09744,
      lng: -72.80105,
      city: 'Jamaica',
      state: 'VT',
    },
    '05344': {
      lat: 42.83896,
      lng: -72.73257,
      city: 'Marlboro',
      state: 'VT',
    },
    '05345': {
      lat: 42.99516,
      lng: -72.66747,
      city: 'Newfane',
      state: 'VT',
    },
    '05346': {
      lat: 43.02337,
      lng: -72.53043,
      city: 'Putney',
      state: 'VT',
    },
    '05350': {
      lat: 42.79879,
      lng: -72.97187,
      city: 'Readsboro',
      state: 'VT',
    },
    '05351': {
      lat: 42.92867,
      lng: -72.73728,
      city: 'South Newfane',
      state: 'VT',
    },
    '05352': {
      lat: 42.78796,
      lng: -73.07862,
      city: 'Stamford',
      state: 'VT',
    },
    '05353': {
      lat: 43.06501,
      lng: -72.66484,
      city: 'Townshend',
      state: 'VT',
    },
    '05354': {
      lat: 42.76211,
      lng: -72.52553,
      city: 'Vernon',
      state: 'VT',
    },
    '05355': {
      lat: 43.02079,
      lng: -72.80645,
      city: 'Wardsboro',
      state: 'VT',
    },
    '05356': {
      lat: 42.95423,
      lng: -72.86952,
      city: 'West Dover',
      state: 'VT',
    },
    '05358': {
      lat: 42.77396,
      lng: -72.73164,
      city: 'West Halifax',
      state: 'VT',
    },
    '05359': {
      lat: 43.15184,
      lng: -72.72121,
      city: 'West Townshend',
      state: 'VT',
    },
    '05360': {
      lat: 43.02778,
      lng: -72.94378,
      city: 'West Wardsboro',
      state: 'VT',
    },
    '05361': {
      lat: 42.78021,
      lng: -72.87935,
      city: 'Whitingham',
      state: 'VT',
    },
    '05362': {
      lat: 42.94609,
      lng: -72.6816,
      city: 'Williamsville',
      state: 'VT',
    },
    '05363': {
      lat: 42.88301,
      lng: -72.89554,
      city: 'Wilmington',
      state: 'VT',
    },
    '05401': {
      lat: 44.47484,
      lng: -73.21758,
      city: 'Burlington',
      state: 'VT',
    },
    '05403': {
      lat: 44.447,
      lng: -73.17229,
      city: 'South Burlington',
      state: 'VT',
    },
    '05404': {
      lat: 44.49483,
      lng: -73.18467,
      city: 'Winooski',
      state: 'VT',
    },
    '05405': {
      lat: 44.47475,
      lng: -73.19649,
      city: 'Burlington',
      state: 'VT',
    },
    '05408': {
      lat: 44.48969,
      lng: -73.27139,
      city: 'Burlington',
      state: 'VT',
    },
    '05439': {
      lat: 44.49573,
      lng: -73.16524,
      city: 'Colchester',
      state: 'VT',
    },
    '05440': {
      lat: 44.95214,
      lng: -73.2848,
      city: 'Alburgh',
      state: 'VT',
    },
    '05441': {
      lat: 44.7686,
      lng: -72.78669,
      city: 'Bakersfield',
      state: 'VT',
    },
    '05442': {
      lat: 44.76164,
      lng: -72.68043,
      city: 'Belvidere Center',
      state: 'VT',
    },
    '05443': {
      lat: 44.12976,
      lng: -73.03347,
      city: 'Bristol',
      state: 'VT',
    },
    '05444': {
      lat: 44.62628,
      lng: -72.88242,
      city: 'Cambridge',
      state: 'VT',
    },
    '05445': {
      lat: 44.30896,
      lng: -73.23593,
      city: 'Charlotte',
      state: 'VT',
    },
    '05446': {
      lat: 44.5541,
      lng: -73.21647,
      city: 'Colchester',
      state: 'VT',
    },
    '05447': {
      lat: 44.93615,
      lng: -72.70447,
      city: 'East Berkshire',
      state: 'VT',
    },
    '05448': {
      lat: 44.75374,
      lng: -72.8872,
      city: 'East Fairfield',
      state: 'VT',
    },
    '05450': {
      lat: 44.89075,
      lng: -72.79074,
      city: 'Enosburg Falls',
      state: 'VT',
    },
    '05452': {
      lat: 44.5238,
      lng: -73.06479,
      city: 'Essex Junction',
      state: 'VT',
    },
    '05454': {
      lat: 44.7056,
      lng: -73.02294,
      city: 'Fairfax',
      state: 'VT',
    },
    '05455': {
      lat: 44.80352,
      lng: -72.96568,
      city: 'Fairfield',
      state: 'VT',
    },
    '05456': {
      lat: 44.20776,
      lng: -73.25796,
      city: 'Ferrisburgh',
      state: 'VT',
    },
    '05457': {
      lat: 44.97294,
      lng: -72.91832,
      city: 'Franklin',
      state: 'VT',
    },
    '05458': {
      lat: 44.71838,
      lng: -73.29837,
      city: 'Grand Isle',
      state: 'VT',
    },
    '05459': {
      lat: 44.95568,
      lng: -73.01861,
      city: 'Highgate Center',
      state: 'VT',
    },
    '05461': {
      lat: 44.32094,
      lng: -73.09176,
      city: 'Hinesburg',
      state: 'VT',
    },
    '05462': {
      lat: 44.29909,
      lng: -72.95228,
      city: 'Huntington',
      state: 'VT',
    },
    '05463': {
      lat: 44.86297,
      lng: -73.34254,
      city: 'Isle La Motte',
      state: 'VT',
    },
    '05464': {
      lat: 44.65469,
      lng: -72.81897,
      city: 'Jeffersonville',
      state: 'VT',
    },
    '05465': {
      lat: 44.45882,
      lng: -72.93058,
      city: 'Jericho',
      state: 'VT',
    },
    '05468': {
      lat: 44.65378,
      lng: -73.15343,
      city: 'Milton',
      state: 'VT',
    },
    '05471': {
      lat: 44.85739,
      lng: -72.58942,
      city: 'Montgomery Center',
      state: 'VT',
    },
    '05472': {
      lat: 44.11377,
      lng: -73.1611,
      city: 'New Haven',
      state: 'VT',
    },
    '05473': {
      lat: 44.24119,
      lng: -73.19346,
      city: 'North Ferrisburgh',
      state: 'VT',
    },
    '05474': {
      lat: 44.83445,
      lng: -73.26687,
      city: 'North Hero',
      state: 'VT',
    },
    '05476': {
      lat: 44.96055,
      lng: -72.6457,
      city: 'Richford',
      state: 'VT',
    },
    '05477': {
      lat: 44.3936,
      lng: -72.99152,
      city: 'Richmond',
      state: 'VT',
    },
    '05478': {
      lat: 44.7941,
      lng: -73.11714,
      city: 'Saint Albans',
      state: 'VT',
    },
    '05481': {
      lat: 44.80833,
      lng: -73.1396,
      city: 'Saint Albans Bay',
      state: 'VT',
    },
    '05482': {
      lat: 44.39057,
      lng: -73.24134,
      city: 'Shelburne',
      state: 'VT',
    },
    '05483': {
      lat: 44.88322,
      lng: -72.98484,
      city: 'Sheldon',
      state: 'VT',
    },
    '05485': {
      lat: 44.90405,
      lng: -72.97567,
      city: 'Sheldon Springs',
      state: 'VT',
    },
    '05486': {
      lat: 44.62958,
      lng: -73.31444,
      city: 'South Hero',
      state: 'VT',
    },
    '05487': {
      lat: 44.23082,
      lng: -73.01096,
      city: 'Starksboro',
      state: 'VT',
    },
    '05488': {
      lat: 44.92214,
      lng: -73.14286,
      city: 'Swanton',
      state: 'VT',
    },
    '05489': {
      lat: 44.5458,
      lng: -72.89471,
      city: 'Underhill',
      state: 'VT',
    },
    '05491': {
      lat: 44.12101,
      lng: -73.32111,
      city: 'Vergennes',
      state: 'VT',
    },
    '05492': {
      lat: 44.71324,
      lng: -72.75853,
      city: 'Waterville',
      state: 'VT',
    },
    '05494': {
      lat: 44.60472,
      lng: -73.01523,
      city: 'Westford',
      state: 'VT',
    },
    '05495': {
      lat: 44.42901,
      lng: -73.09197,
      city: 'Williston',
      state: 'VT',
    },
    '05602': {
      lat: 44.2848,
      lng: -72.61498,
      city: 'Montpelier',
      state: 'VT',
    },
    '05640': {
      lat: 44.34734,
      lng: -72.49675,
      city: 'Adamant',
      state: 'VT',
    },
    '05641': {
      lat: 44.18602,
      lng: -72.45108,
      city: 'Barre',
      state: 'VT',
    },
    '05647': {
      lat: 44.41001,
      lng: -72.29126,
      city: 'Cabot',
      state: 'VT',
    },
    '05648': {
      lat: 44.38372,
      lng: -72.49355,
      city: 'Calais',
      state: 'VT',
    },
    '05649': {
      lat: 44.14004,
      lng: -72.36589,
      city: 'East Barre',
      state: 'VT',
    },
    '05650': {
      lat: 44.4002,
      lng: -72.44235,
      city: 'East Calais',
      state: 'VT',
    },
    '05651': {
      lat: 44.27777,
      lng: -72.49246,
      city: 'East Montpelier',
      state: 'VT',
    },
    '05652': { lat: 44.72824, lng: -72.58674, city: 'Eden', state: 'VT' },
    '05653': {
      lat: 44.70141,
      lng: -72.49275,
      city: 'Eden Mills',
      state: 'VT',
    },
    '05654': {
      lat: 44.14053,
      lng: -72.48092,
      city: 'Graniteville',
      state: 'VT',
    },
    '05655': {
      lat: 44.62461,
      lng: -72.56462,
      city: 'Hyde Park',
      state: 'VT',
    },
    '05656': {
      lat: 44.64898,
      lng: -72.68825,
      city: 'Johnson',
      state: 'VT',
    },
    '05658': {
      lat: 44.35921,
      lng: -72.35399,
      city: 'Marshfield',
      state: 'VT',
    },
    '05660': {
      lat: 44.24733,
      lng: -72.76808,
      city: 'Moretown',
      state: 'VT',
    },
    '05661': {
      lat: 44.53743,
      lng: -72.61724,
      city: 'Morrisville',
      state: 'VT',
    },
    '05663': {
      lat: 44.14946,
      lng: -72.67239,
      city: 'Northfield',
      state: 'VT',
    },
    '05664': {
      lat: 44.16718,
      lng: -72.65316,
      city: 'Northfield Falls',
      state: 'VT',
    },
    '05666': {
      lat: 44.34125,
      lng: -72.43873,
      city: 'North Montpelier',
      state: 'VT',
    },
    '05667': {
      lat: 44.28122,
      lng: -72.3986,
      city: 'Plainfield',
      state: 'VT',
    },
    '05669': {
      lat: 44.05325,
      lng: -72.75155,
      city: 'Roxbury',
      state: 'VT',
    },
    '05670': {
      lat: 44.17799,
      lng: -72.50558,
      city: 'South Barre',
      state: 'VT',
    },
    '05672': {
      lat: 44.48151,
      lng: -72.72174,
      city: 'Stowe',
      state: 'VT',
    },
    '05673': {
      lat: 44.19371,
      lng: -72.84356,
      city: 'Waitsfield',
      state: 'VT',
    },
    '05674': {
      lat: 44.11112,
      lng: -72.86081,
      city: 'Warren',
      state: 'VT',
    },
    '05675': {
      lat: 44.07386,
      lng: -72.41821,
      city: 'Washington',
      state: 'VT',
    },
    '05676': {
      lat: 44.34825,
      lng: -72.80984,
      city: 'Waterbury',
      state: 'VT',
    },
    '05677': {
      lat: 44.39194,
      lng: -72.70841,
      city: 'Waterbury Center',
      state: 'VT',
    },
    '05678': {
      lat: 44.15577,
      lng: -72.47445,
      city: 'Websterville',
      state: 'VT',
    },
    '05679': {
      lat: 44.10677,
      lng: -72.54052,
      city: 'Williamstown',
      state: 'VT',
    },
    '05680': {
      lat: 44.53785,
      lng: -72.46838,
      city: 'Wolcott',
      state: 'VT',
    },
    '05681': {
      lat: 44.45377,
      lng: -72.38016,
      city: 'Woodbury',
      state: 'VT',
    },
    '05682': {
      lat: 44.40354,
      lng: -72.57219,
      city: 'Worcester',
      state: 'VT',
    },
    '05701': {
      lat: 43.63365,
      lng: -72.91368,
      city: 'Rutland',
      state: 'VT',
    },
    '05730': {
      lat: 43.40262,
      lng: -72.81508,
      city: 'Belmont',
      state: 'VT',
    },
    '05731': {
      lat: 43.7532,
      lng: -73.28857,
      city: 'Benson',
      state: 'VT',
    },
    '05732': {
      lat: 43.67998,
      lng: -73.18648,
      city: 'Bomoseen',
      state: 'VT',
    },
    '05733': {
      lat: 43.81746,
      lng: -73.09216,
      city: 'Brandon',
      state: 'VT',
    },
    '05734': {
      lat: 43.98243,
      lng: -73.33286,
      city: 'Bridport',
      state: 'VT',
    },
    '05735': {
      lat: 43.64475,
      lng: -73.15456,
      city: 'Castleton',
      state: 'VT',
    },
    '05736': {
      lat: 43.60483,
      lng: -73.01273,
      city: 'Center Rutland',
      state: 'VT',
    },
    '05737': {
      lat: 43.76778,
      lng: -72.90434,
      city: 'Chittenden',
      state: 'VT',
    },
    '05738': {
      lat: 43.52895,
      lng: -72.85637,
      city: 'Cuttingsville',
      state: 'VT',
    },
    '05739': { lat: 43.34488, lng: -73.0295, city: 'Danby', state: 'VT' },
    '05742': {
      lat: 43.43685,
      lng: -72.9045,
      city: 'East Wallingford',
      state: 'VT',
    },
    '05743': {
      lat: 43.67118,
      lng: -73.30726,
      city: 'Fair Haven',
      state: 'VT',
    },
    '05744': {
      lat: 43.69034,
      lng: -73.08272,
      city: 'Florence',
      state: 'VT',
    },
    '05746': {
      lat: 43.72098,
      lng: -72.78836,
      city: 'Gaysville',
      state: 'VT',
    },
    '05747': {
      lat: 44.00354,
      lng: -72.85494,
      city: 'Granville',
      state: 'VT',
    },
    '05748': {
      lat: 43.92004,
      lng: -72.91064,
      city: 'Hancock',
      state: 'VT',
    },
    '05751': {
      lat: 43.6542,
      lng: -72.78941,
      city: 'Killington',
      state: 'VT',
    },
    '05753': {
      lat: 43.9991,
      lng: -73.17403,
      city: 'Middlebury',
      state: 'VT',
    },
    '05757': {
      lat: 43.48279,
      lng: -73.12325,
      city: 'Middletown Springs',
      state: 'VT',
    },
    '05758': {
      lat: 43.44797,
      lng: -72.78386,
      city: 'Mount Holly',
      state: 'VT',
    },
    '05759': {
      lat: 43.53926,
      lng: -72.96483,
      city: 'North Clarendon',
      state: 'VT',
    },
    '05760': {
      lat: 43.80359,
      lng: -73.29517,
      city: 'Orwell',
      state: 'VT',
    },
    '05761': {
      lat: 43.34786,
      lng: -73.14855,
      city: 'Pawlet',
      state: 'VT',
    },
    '05762': {
      lat: 43.79246,
      lng: -72.8291,
      city: 'Pittsfield',
      state: 'VT',
    },
    '05763': {
      lat: 43.73678,
      lng: -72.98946,
      city: 'Pittsford',
      state: 'VT',
    },
    '05764': {
      lat: 43.52854,
      lng: -73.19401,
      city: 'Poultney',
      state: 'VT',
    },
    '05765': {
      lat: 43.65068,
      lng: -73.03648,
      city: 'Proctor',
      state: 'VT',
    },
    '05766': {
      lat: 43.98833,
      lng: -72.99072,
      city: 'Ripton',
      state: 'VT',
    },
    '05767': {
      lat: 43.87513,
      lng: -72.82878,
      city: 'Rochester',
      state: 'VT',
    },
    '05768': {
      lat: 43.29846,
      lng: -73.22168,
      city: 'Rupert',
      state: 'VT',
    },
    '05769': {
      lat: 43.91948,
      lng: -73.11651,
      city: 'Salisbury',
      state: 'VT',
    },
    '05770': {
      lat: 43.88662,
      lng: -73.32771,
      city: 'Shoreham',
      state: 'VT',
    },
    '05772': {
      lat: 43.75989,
      lng: -72.74003,
      city: 'Stockbridge',
      state: 'VT',
    },
    '05773': {
      lat: 43.40902,
      lng: -72.96858,
      city: 'Wallingford',
      state: 'VT',
    },
    '05774': {
      lat: 43.42708,
      lng: -73.17435,
      city: 'Wells',
      state: 'VT',
    },
    '05775': {
      lat: 43.35437,
      lng: -73.22804,
      city: 'West Pawlet',
      state: 'VT',
    },
    '05776': {
      lat: 43.24494,
      lng: -73.20392,
      city: 'West Rupert',
      state: 'VT',
    },
    '05777': {
      lat: 43.5552,
      lng: -73.05504,
      city: 'West Rutland',
      state: 'VT',
    },
    '05778': {
      lat: 43.87279,
      lng: -73.21228,
      city: 'Whiting',
      state: 'VT',
    },
    '05819': {
      lat: 44.40397,
      lng: -71.98837,
      city: 'Saint Johnsbury',
      state: 'VT',
    },
    '05820': {
      lat: 44.74862,
      lng: -72.37351,
      city: 'Albany',
      state: 'VT',
    },
    '05821': {
      lat: 44.3175,
      lng: -72.10866,
      city: 'Barnet',
      state: 'VT',
    },
    '05822': {
      lat: 44.75555,
      lng: -72.15478,
      city: 'Barton',
      state: 'VT',
    },
    '05824': {
      lat: 44.43741,
      lng: -71.86449,
      city: 'Concord',
      state: 'VT',
    },
    '05825': {
      lat: 44.86019,
      lng: -72.25664,
      city: 'Coventry',
      state: 'VT',
    },
    '05826': {
      lat: 44.6444,
      lng: -72.40404,
      city: 'Craftsbury',
      state: 'VT',
    },
    '05827': {
      lat: 44.68135,
      lng: -72.36198,
      city: 'Craftsbury Common',
      state: 'VT',
    },
    '05828': {
      lat: 44.43748,
      lng: -72.12334,
      city: 'Danville',
      state: 'VT',
    },
    '05829': { lat: 44.95349, lng: -72.0759, city: 'Derby', state: 'VT' },
    '05830': {
      lat: 44.97717,
      lng: -71.98278,
      city: 'Derby Line',
      state: 'VT',
    },
    '05832': {
      lat: 44.59546,
      lng: -71.91027,
      city: 'East Burke',
      state: 'VT',
    },
    '05833': {
      lat: 44.83182,
      lng: -71.96965,
      city: 'East Charleston',
      state: 'VT',
    },
    '05836': {
      lat: 44.5196,
      lng: -72.25173,
      city: 'East Hardwick',
      state: 'VT',
    },
    '05837': {
      lat: 44.66868,
      lng: -71.8386,
      city: 'East Haven',
      state: 'VT',
    },
    '05839': {
      lat: 44.67342,
      lng: -72.21171,
      city: 'Glover',
      state: 'VT',
    },
    '05840': {
      lat: 44.61297,
      lng: -71.73129,
      city: 'Granby',
      state: 'VT',
    },
    '05841': {
      lat: 44.60794,
      lng: -72.30232,
      city: 'Greensboro',
      state: 'VT',
    },
    '05842': {
      lat: 44.57966,
      lng: -72.24453,
      city: 'Greensboro Bend',
      state: 'VT',
    },
    '05843': {
      lat: 44.50355,
      lng: -72.33366,
      city: 'Hardwick',
      state: 'VT',
    },
    '05845': {
      lat: 44.79146,
      lng: -72.30699,
      city: 'Irasburg',
      state: 'VT',
    },
    '05846': {
      lat: 44.82671,
      lng: -71.82795,
      city: 'Island Pond',
      state: 'VT',
    },
    '05847': {
      lat: 44.79165,
      lng: -72.45679,
      city: 'Lowell',
      state: 'VT',
    },
    '05850': {
      lat: 44.55955,
      lng: -72.03417,
      city: 'Lyndon Center',
      state: 'VT',
    },
    '05851': {
      lat: 44.55297,
      lng: -72.05732,
      city: 'Lyndonville',
      state: 'VT',
    },
    '05853': {
      lat: 44.89499,
      lng: -71.96167,
      city: 'Morgan',
      state: 'VT',
    },
    '05855': {
      lat: 44.92829,
      lng: -72.1943,
      city: 'Newport',
      state: 'VT',
    },
    '05857': {
      lat: 44.93381,
      lng: -72.3092,
      city: 'Newport Center',
      state: 'VT',
    },
    '05858': {
      lat: 44.53587,
      lng: -71.8046,
      city: 'North Concord',
      state: 'VT',
    },
    '05859': {
      lat: 44.95987,
      lng: -72.43833,
      city: 'North Troy',
      state: 'VT',
    },
    '05860': {
      lat: 44.80018,
      lng: -72.10353,
      city: 'Orleans',
      state: 'VT',
    },
    '05862': {
      lat: 44.31971,
      lng: -72.23133,
      city: 'Peacham',
      state: 'VT',
    },
    '05866': {
      lat: 44.63863,
      lng: -72.12699,
      city: 'Sheffield',
      state: 'VT',
    },
    '05867': {
      lat: 44.66505,
      lng: -72.04366,
      city: 'Sutton',
      state: 'VT',
    },
    '05868': { lat: 44.91881, lng: -72.3675, city: 'Troy', state: 'VT' },
    '05871': {
      lat: 44.68367,
      lng: -71.95193,
      city: 'West Burke',
      state: 'VT',
    },
    '05872': {
      lat: 44.86059,
      lng: -72.03473,
      city: 'West Charleston',
      state: 'VT',
    },
    '05873': {
      lat: 44.40619,
      lng: -72.21841,
      city: 'West Danville',
      state: 'VT',
    },
    '05874': {
      lat: 44.87546,
      lng: -72.46172,
      city: 'Westfield',
      state: 'VT',
    },
    '05875': {
      lat: 44.70947,
      lng: -72.26753,
      city: 'Barton',
      state: 'VT',
    },
    '05901': {
      lat: 44.94197,
      lng: -71.68079,
      city: 'Averill',
      state: 'VT',
    },
    '05902': {
      lat: 45.00881,
      lng: -71.49472,
      city: 'Beecher Falls',
      state: 'VT',
    },
    '05903': {
      lat: 44.9379,
      lng: -71.58286,
      city: 'Canaan',
      state: 'VT',
    },
    '05904': {
      lat: 44.41597,
      lng: -71.70762,
      city: 'Gilman',
      state: 'VT',
    },
    '05905': {
      lat: 44.70151,
      lng: -71.66672,
      city: 'Guildhall',
      state: 'VT',
    },
    '05906': {
      lat: 44.47431,
      lng: -71.71538,
      city: 'Lunenburg',
      state: 'VT',
    },
    '05907': {
      lat: 44.9502,
      lng: -71.82577,
      city: 'Norton',
      state: 'VT',
    },
    '06001': { lat: 41.79185, lng: -72.85107, city: 'Avon', state: 'CT' },
    '06002': {
      lat: 41.84286,
      lng: -72.73714,
      city: 'Bloomfield',
      state: 'CT',
    },
    '06010': {
      lat: 41.681,
      lng: -72.94065,
      city: 'Bristol',
      state: 'CT',
    },
    '06013': {
      lat: 41.7598,
      lng: -72.95895,
      city: 'Burlington',
      state: 'CT',
    },
    '06016': {
      lat: 41.90417,
      lng: -72.54438,
      city: 'Broad Brook',
      state: 'CT',
    },
    '06018': {
      lat: 42.02872,
      lng: -73.29807,
      city: 'Canaan',
      state: 'CT',
    },
    '06019': {
      lat: 41.8592,
      lng: -72.90717,
      city: 'Canton',
      state: 'CT',
    },
    '06020': {
      lat: 41.86283,
      lng: -72.90991,
      city: 'Canton Center',
      state: 'CT',
    },
    '06021': {
      lat: 42.00152,
      lng: -73.08078,
      city: 'Colebrook',
      state: 'CT',
    },
    '06022': {
      lat: 41.86595,
      lng: -72.92749,
      city: 'Collinsville',
      state: 'CT',
    },
    '06023': {
      lat: 41.61355,
      lng: -72.72072,
      city: 'East Berlin',
      state: 'CT',
    },
    '06024': {
      lat: 42.00812,
      lng: -73.28374,
      city: 'East Canaan',
      state: 'CT',
    },
    '06026': {
      lat: 41.94242,
      lng: -72.74093,
      city: 'East Granby',
      state: 'CT',
    },
    '06027': {
      lat: 42.00403,
      lng: -72.91112,
      city: 'East Hartland',
      state: 'CT',
    },
    '06029': {
      lat: 41.91439,
      lng: -72.4535,
      city: 'Ellington',
      state: 'CT',
    },
    '06031': {
      lat: 41.95788,
      lng: -73.31733,
      city: 'Falls Village',
      state: 'CT',
    },
    '06032': {
      lat: 41.72528,
      lng: -72.83147,
      city: 'Farmington',
      state: 'CT',
    },
    '06033': {
      lat: 41.70593,
      lng: -72.53915,
      city: 'Glastonbury',
      state: 'CT',
    },
    '06035': {
      lat: 41.9608,
      lng: -72.80179,
      city: 'Granby',
      state: 'CT',
    },
    '06037': {
      lat: 41.61148,
      lng: -72.7775,
      city: 'Berlin',
      state: 'CT',
    },
    '06039': {
      lat: 41.95001,
      lng: -73.44379,
      city: 'Lakeville',
      state: 'CT',
    },
    '06040': {
      lat: 41.7616,
      lng: -72.52247,
      city: 'Manchester',
      state: 'CT',
    },
    '06042': {
      lat: 41.79743,
      lng: -72.52704,
      city: 'Manchester',
      state: 'CT',
    },
    '06043': {
      lat: 41.76647,
      lng: -72.43903,
      city: 'Bolton',
      state: 'CT',
    },
    '06051': {
      lat: 41.66519,
      lng: -72.76983,
      city: 'New Britain',
      state: 'CT',
    },
    '06052': {
      lat: 41.65736,
      lng: -72.80506,
      city: 'New Britain',
      state: 'CT',
    },
    '06053': {
      lat: 41.68874,
      lng: -72.7941,
      city: 'New Britain',
      state: 'CT',
    },
    '06057': {
      lat: 41.84417,
      lng: -73.00552,
      city: 'New Hartford',
      state: 'CT',
    },
    '06058': {
      lat: 41.97186,
      lng: -73.1958,
      city: 'Norfolk',
      state: 'CT',
    },
    '06059': {
      lat: 41.89054,
      lng: -72.88833,
      city: 'North Canton',
      state: 'CT',
    },
    '06060': {
      lat: 42.00687,
      lng: -72.84594,
      city: 'North Granby',
      state: 'CT',
    },
    '06061': {
      lat: 41.87524,
      lng: -72.96716,
      city: 'Pine Meadow',
      state: 'CT',
    },
    '06062': {
      lat: 41.67338,
      lng: -72.86079,
      city: 'Plainville',
      state: 'CT',
    },
    '06063': {
      lat: 41.93053,
      lng: -72.97096,
      city: 'Barkhamsted',
      state: 'CT',
    },
    '06065': {
      lat: 41.97795,
      lng: -73.00054,
      city: 'Riverton',
      state: 'CT',
    },
    '06066': {
      lat: 41.83641,
      lng: -72.46063,
      city: 'Vernon Rockville',
      state: 'CT',
    },
    '06067': {
      lat: 41.6574,
      lng: -72.66259,
      city: 'Rocky Hill',
      state: 'CT',
    },
    '06068': {
      lat: 42.01404,
      lng: -73.42121,
      city: 'Salisbury',
      state: 'CT',
    },
    '06069': {
      lat: 41.85392,
      lng: -73.4448,
      city: 'Sharon',
      state: 'CT',
    },
    '06070': {
      lat: 41.87674,
      lng: -72.81626,
      city: 'Simsbury',
      state: 'CT',
    },
    '06071': {
      lat: 41.99489,
      lng: -72.45213,
      city: 'Somers',
      state: 'CT',
    },
    '06072': {
      lat: 41.98465,
      lng: -72.4876,
      city: 'Somersville',
      state: 'CT',
    },
    '06073': {
      lat: 41.65753,
      lng: -72.56742,
      city: 'South Glastonbury',
      state: 'CT',
    },
    '06074': {
      lat: 41.83528,
      lng: -72.57332,
      city: 'South Windsor',
      state: 'CT',
    },
    '06076': {
      lat: 41.98906,
      lng: -72.26224,
      city: 'Stafford Springs',
      state: 'CT',
    },
    '06078': {
      lat: 41.98903,
      lng: -72.64875,
      city: 'Suffield',
      state: 'CT',
    },
    '06079': {
      lat: 42.04174,
      lng: -73.39755,
      city: 'Taconic',
      state: 'CT',
    },
    '06080': {
      lat: 41.95044,
      lng: -72.63519,
      city: 'Suffield',
      state: 'CT',
    },
    '06081': {
      lat: 41.90708,
      lng: -72.76839,
      city: 'Tariffville',
      state: 'CT',
    },
    '06082': {
      lat: 41.98393,
      lng: -72.55475,
      city: 'Enfield',
      state: 'CT',
    },
    '06084': {
      lat: 41.87917,
      lng: -72.36443,
      city: 'Tolland',
      state: 'CT',
    },
    '06085': {
      lat: 41.74717,
      lng: -72.88883,
      city: 'Unionville',
      state: 'CT',
    },
    '06088': {
      lat: 41.9058,
      lng: -72.59646,
      city: 'East Windsor',
      state: 'CT',
    },
    '06089': {
      lat: 41.83801,
      lng: -72.82343,
      city: 'Weatogue',
      state: 'CT',
    },
    '06090': {
      lat: 41.94685,
      lng: -72.86403,
      city: 'West Granby',
      state: 'CT',
    },
    '06091': {
      lat: 42.01419,
      lng: -72.97423,
      city: 'West Hartland',
      state: 'CT',
    },
    '06092': {
      lat: 41.87266,
      lng: -72.86101,
      city: 'West Simsbury',
      state: 'CT',
    },
    '06093': {
      lat: 42.00265,
      lng: -72.72285,
      city: 'West Suffield',
      state: 'CT',
    },
    '06095': {
      lat: 41.87229,
      lng: -72.67348,
      city: 'Windsor',
      state: 'CT',
    },
    '06096': {
      lat: 41.92671,
      lng: -72.65443,
      city: 'Windsor Locks',
      state: 'CT',
    },
    '06098': {
      lat: 41.92379,
      lng: -73.10003,
      city: 'Winsted',
      state: 'CT',
    },
    '06103': {
      lat: 41.76477,
      lng: -72.67012,
      city: 'Hartford',
      state: 'CT',
    },
    '06105': {
      lat: 41.77322,
      lng: -72.70442,
      city: 'Hartford',
      state: 'CT',
    },
    '06106': {
      lat: 41.74818,
      lng: -72.69683,
      city: 'Hartford',
      state: 'CT',
    },
    '06107': {
      lat: 41.75375,
      lng: -72.75966,
      city: 'West Hartford',
      state: 'CT',
    },
    '06108': {
      lat: 41.78078,
      lng: -72.62165,
      city: 'East Hartford',
      state: 'CT',
    },
    '06109': {
      lat: 41.70131,
      lng: -72.67035,
      city: 'Wethersfield',
      state: 'CT',
    },
    '06110': {
      lat: 41.73247,
      lng: -72.73378,
      city: 'West Hartford',
      state: 'CT',
    },
    '06111': {
      lat: 41.68677,
      lng: -72.73057,
      city: 'Newington',
      state: 'CT',
    },
    '06112': {
      lat: 41.79317,
      lng: -72.69741,
      city: 'Hartford',
      state: 'CT',
    },
    '06114': {
      lat: 41.7394,
      lng: -72.66949,
      city: 'Hartford',
      state: 'CT',
    },
    '06117': {
      lat: 41.7875,
      lng: -72.76203,
      city: 'West Hartford',
      state: 'CT',
    },
    '06118': {
      lat: 41.74871,
      lng: -72.60976,
      city: 'East Hartford',
      state: 'CT',
    },
    '06119': {
      lat: 41.76351,
      lng: -72.72711,
      city: 'West Hartford',
      state: 'CT',
    },
    '06120': {
      lat: 41.78944,
      lng: -72.66523,
      city: 'Hartford',
      state: 'CT',
    },
    '06226': {
      lat: 41.70816,
      lng: -72.20731,
      city: 'Willimantic',
      state: 'CT',
    },
    '06231': {
      lat: 41.62512,
      lng: -72.37055,
      city: 'Amston',
      state: 'CT',
    },
    '06232': {
      lat: 41.73309,
      lng: -72.37603,
      city: 'Andover',
      state: 'CT',
    },
    '06234': {
      lat: 41.78938,
      lng: -71.95266,
      city: 'Brooklyn',
      state: 'CT',
    },
    '06235': {
      lat: 41.79292,
      lng: -72.1275,
      city: 'Chaplin',
      state: 'CT',
    },
    '06237': {
      lat: 41.69387,
      lng: -72.30722,
      city: 'Columbia',
      state: 'CT',
    },
    '06238': {
      lat: 41.78281,
      lng: -72.33941,
      city: 'Coventry',
      state: 'CT',
    },
    '06239': {
      lat: 41.79116,
      lng: -71.85711,
      city: 'Danielson',
      state: 'CT',
    },
    '06241': {
      lat: 41.85635,
      lng: -71.84904,
      city: 'Dayville',
      state: 'CT',
    },
    '06242': {
      lat: 41.89326,
      lng: -72.09706,
      city: 'Eastford',
      state: 'CT',
    },
    '06243': {
      lat: 41.84743,
      lng: -71.80701,
      city: 'East Killingly',
      state: 'CT',
    },
    '06247': {
      lat: 41.76829,
      lng: -72.06548,
      city: 'Hampton',
      state: 'CT',
    },
    '06248': {
      lat: 41.68468,
      lng: -72.40523,
      city: 'Hebron',
      state: 'CT',
    },
    '06249': {
      lat: 41.63128,
      lng: -72.24019,
      city: 'Lebanon',
      state: 'CT',
    },
    '06250': {
      lat: 41.77434,
      lng: -72.19669,
      city: 'Mansfield Center',
      state: 'CT',
    },
    '06254': {
      lat: 41.61585,
      lng: -72.14313,
      city: 'North Franklin',
      state: 'CT',
    },
    '06255': {
      lat: 41.98791,
      lng: -71.90299,
      city: 'North Grosvenordale',
      state: 'CT',
    },
    '06256': {
      lat: 41.73699,
      lng: -72.15723,
      city: 'North Windham',
      state: 'CT',
    },
    '06258': {
      lat: 41.88554,
      lng: -71.96353,
      city: 'Pomfret',
      state: 'CT',
    },
    '06259': {
      lat: 41.8703,
      lng: -71.99248,
      city: 'Pomfret Center',
      state: 'CT',
    },
    '06260': {
      lat: 41.90898,
      lng: -71.87181,
      city: 'Putnam',
      state: 'CT',
    },
    '06262': {
      lat: 42.01871,
      lng: -71.94959,
      city: 'Quinebaug',
      state: 'CT',
    },
    '06263': {
      lat: 41.83738,
      lng: -71.90444,
      city: 'Rogers',
      state: 'CT',
    },
    '06264': {
      lat: 41.69869,
      lng: -72.10487,
      city: 'Scotland',
      state: 'CT',
    },
    '06266': {
      lat: 41.67136,
      lng: -72.17048,
      city: 'South Windham',
      state: 'CT',
    },
    '06268': {
      lat: 41.79852,
      lng: -72.24843,
      city: 'Storrs Mansfield',
      state: 'CT',
    },
    '06269': {
      lat: 41.80998,
      lng: -72.25283,
      city: 'Storrs Mansfield',
      state: 'CT',
    },
    '06277': {
      lat: 41.96895,
      lng: -71.83927,
      city: 'Thompson',
      state: 'CT',
    },
    '06278': {
      lat: 41.89126,
      lng: -72.17003,
      city: 'Ashford',
      state: 'CT',
    },
    '06279': {
      lat: 41.88959,
      lng: -72.25933,
      city: 'Willington',
      state: 'CT',
    },
    '06280': {
      lat: 41.69237,
      lng: -72.13829,
      city: 'Windham',
      state: 'CT',
    },
    '06281': {
      lat: 41.97253,
      lng: -72.01458,
      city: 'Woodstock',
      state: 'CT',
    },
    '06282': {
      lat: 41.94556,
      lng: -72.07928,
      city: 'Woodstock Valley',
      state: 'CT',
    },
    '06320': {
      lat: 41.35026,
      lng: -72.10225,
      city: 'New London',
      state: 'CT',
    },
    '06330': {
      lat: 41.64199,
      lng: -72.07541,
      city: 'Baltic',
      state: 'CT',
    },
    '06331': {
      lat: 41.69717,
      lng: -72.00109,
      city: 'Canterbury',
      state: 'CT',
    },
    '06332': {
      lat: 41.72845,
      lng: -71.91008,
      city: 'Central Village',
      state: 'CT',
    },
    '06333': {
      lat: 41.38602,
      lng: -72.24527,
      city: 'East Lyme',
      state: 'CT',
    },
    '06334': {
      lat: 41.54431,
      lng: -72.17424,
      city: 'Bozrah',
      state: 'CT',
    },
    '06335': {
      lat: 41.42943,
      lng: -72.06247,
      city: 'Gales Ferry',
      state: 'CT',
    },
    '06336': {
      lat: 41.57934,
      lng: -72.19505,
      city: 'Gilman',
      state: 'CT',
    },
    '06338': {
      lat: 41.46473,
      lng: -71.97281,
      city: 'Mashantucket',
      state: 'CT',
    },
    '06339': {
      lat: 41.44408,
      lng: -71.99851,
      city: 'Ledyard',
      state: 'CT',
    },
    '06340': {
      lat: 41.35643,
      lng: -72.04477,
      city: 'Groton',
      state: 'CT',
    },
    '06350': {
      lat: 41.64829,
      lng: -72.0693,
      city: 'Hanover',
      state: 'CT',
    },
    '06351': {
      lat: 41.58873,
      lng: -71.94854,
      city: 'Jewett City',
      state: 'CT',
    },
    '06353': {
      lat: 41.45612,
      lng: -72.13928,
      city: 'Montville',
      state: 'CT',
    },
    '06354': {
      lat: 41.69836,
      lng: -71.85016,
      city: 'Moosup',
      state: 'CT',
    },
    '06355': {
      lat: 41.36857,
      lng: -71.97426,
      city: 'Mystic',
      state: 'CT',
    },
    '06357': {
      lat: 41.32711,
      lng: -72.21478,
      city: 'Niantic',
      state: 'CT',
    },
    '06359': {
      lat: 41.46966,
      lng: -71.87546,
      city: 'North Stonington',
      state: 'CT',
    },
    '06360': {
      lat: 41.54883,
      lng: -72.08914,
      city: 'Norwich',
      state: 'CT',
    },
    '06365': {
      lat: 41.52281,
      lng: -72.00072,
      city: 'Preston',
      state: 'CT',
    },
    '06370': {
      lat: 41.46541,
      lng: -72.18659,
      city: 'Oakdale',
      state: 'CT',
    },
    '06371': {
      lat: 41.36361,
      lng: -72.32582,
      city: 'Old Lyme',
      state: 'CT',
    },
    '06373': {
      lat: 41.68394,
      lng: -71.79881,
      city: 'Oneco',
      state: 'CT',
    },
    '06374': {
      lat: 41.6798,
      lng: -71.91441,
      city: 'Plainfield',
      state: 'CT',
    },
    '06375': {
      lat: 41.40544,
      lng: -72.12554,
      city: 'Quaker Hill',
      state: 'CT',
    },
    '06376': {
      lat: 41.29461,
      lng: -72.2528,
      city: 'South Lyme',
      state: 'CT',
    },
    '06377': {
      lat: 41.72708,
      lng: -71.81679,
      city: 'Sterling',
      state: 'CT',
    },
    '06378': {
      lat: 41.38039,
      lng: -71.91376,
      city: 'Stonington',
      state: 'CT',
    },
    '06379': {
      lat: 41.36685,
      lng: -71.85826,
      city: 'Pawcatuck',
      state: 'CT',
    },
    '06380': {
      lat: 41.56533,
      lng: -72.05308,
      city: 'Taftville',
      state: 'CT',
    },
    '06382': {
      lat: 41.46137,
      lng: -72.11762,
      city: 'Uncasville',
      state: 'CT',
    },
    '06384': {
      lat: 41.58188,
      lng: -71.83139,
      city: 'Voluntown',
      state: 'CT',
    },
    '06385': {
      lat: 41.35735,
      lng: -72.15581,
      city: 'Waterford',
      state: 'CT',
    },
    '06387': {
      lat: 41.74418,
      lng: -71.90981,
      city: 'Wauregan',
      state: 'CT',
    },
    '06389': {
      lat: 41.56036,
      lng: -72.12338,
      city: 'Yantic',
      state: 'CT',
    },
    '06390': {
      lat: 41.27027,
      lng: -71.98886,
      city: 'Fishers Island',
      state: 'NY',
    },
    '06401': {
      lat: 41.34424,
      lng: -73.06896,
      city: 'Ansonia',
      state: 'CT',
    },
    '06403': {
      lat: 41.43902,
      lng: -73.05679,
      city: 'Beacon Falls',
      state: 'CT',
    },
    '06405': {
      lat: 41.28405,
      lng: -72.79809,
      city: 'Branford',
      state: 'CT',
    },
    '06409': {
      lat: 41.35169,
      lng: -72.42056,
      city: 'Centerbrook',
      state: 'CT',
    },
    '06410': {
      lat: 41.51183,
      lng: -72.90441,
      city: 'Cheshire',
      state: 'CT',
    },
    '06412': {
      lat: 41.40594,
      lng: -72.48319,
      city: 'Chester',
      state: 'CT',
    },
    '06413': {
      lat: 41.29801,
      lng: -72.53003,
      city: 'Clinton',
      state: 'CT',
    },
    '06414': {
      lat: 41.57702,
      lng: -72.55231,
      city: 'Cobalt',
      state: 'CT',
    },
    '06415': {
      lat: 41.55563,
      lng: -72.34694,
      city: 'Colchester',
      state: 'CT',
    },
    '06416': {
      lat: 41.61218,
      lng: -72.66382,
      city: 'Cromwell',
      state: 'CT',
    },
    '06417': {
      lat: 41.36894,
      lng: -72.46227,
      city: 'Deep River',
      state: 'CT',
    },
    '06418': {
      lat: 41.32647,
      lng: -73.08324,
      city: 'Derby',
      state: 'CT',
    },
    '06419': {
      lat: 41.37923,
      lng: -72.57847,
      city: 'Killingworth',
      state: 'CT',
    },
    '06420': {
      lat: 41.48492,
      lng: -72.26733,
      city: 'Salem',
      state: 'CT',
    },
    '06422': {
      lat: 41.46221,
      lng: -72.68285,
      city: 'Durham',
      state: 'CT',
    },
    '06423': {
      lat: 41.46654,
      lng: -72.39054,
      city: 'East Haddam',
      state: 'CT',
    },
    '06424': {
      lat: 41.5588,
      lng: -72.50452,
      city: 'East Hampton',
      state: 'CT',
    },
    '06426': {
      lat: 41.35346,
      lng: -72.39791,
      city: 'Essex',
      state: 'CT',
    },
    '06437': {
      lat: 41.33444,
      lng: -72.70051,
      city: 'Guilford',
      state: 'CT',
    },
    '06438': {
      lat: 41.45516,
      lng: -72.50701,
      city: 'Haddam',
      state: 'CT',
    },
    '06439': {
      lat: 41.41573,
      lng: -72.39964,
      city: 'Hadlyme',
      state: 'CT',
    },
    '06441': {
      lat: 41.46674,
      lng: -72.58038,
      city: 'Higganum',
      state: 'CT',
    },
    '06442': {
      lat: 41.34261,
      lng: -72.44429,
      city: 'Ivoryton',
      state: 'CT',
    },
    '06443': {
      lat: 41.33976,
      lng: -72.62783,
      city: 'Madison',
      state: 'CT',
    },
    '06444': {
      lat: 41.5634,
      lng: -72.93038,
      city: 'Marion',
      state: 'CT',
    },
    '06447': {
      lat: 41.63309,
      lng: -72.45472,
      city: 'Marlborough',
      state: 'CT',
    },
    '06450': {
      lat: 41.53332,
      lng: -72.77399,
      city: 'Meriden',
      state: 'CT',
    },
    '06451': {
      lat: 41.54203,
      lng: -72.82364,
      city: 'Meriden',
      state: 'CT',
    },
    '06455': {
      lat: 41.51468,
      lng: -72.71732,
      city: 'Middlefield',
      state: 'CT',
    },
    '06456': {
      lat: 41.541,
      lng: -72.5483,
      city: 'Middle Haddam',
      state: 'CT',
    },
    '06457': {
      lat: 41.54759,
      lng: -72.65484,
      city: 'Middletown',
      state: 'CT',
    },
    '06459': {
      lat: 41.5516,
      lng: -72.66017,
      city: 'Middletown',
      state: 'CT',
    },
    '06460': {
      lat: 41.21505,
      lng: -73.05129,
      city: 'Milford',
      state: 'CT',
    },
    '06461': {
      lat: 41.23937,
      lng: -73.0752,
      city: 'Milford',
      state: 'CT',
    },
    '06467': {
      lat: 41.56548,
      lng: -72.90022,
      city: 'Milldale',
      state: 'CT',
    },
    '06468': {
      lat: 41.33791,
      lng: -73.22499,
      city: 'Monroe',
      state: 'CT',
    },
    '06469': {
      lat: 41.50757,
      lng: -72.44924,
      city: 'Moodus',
      state: 'CT',
    },
    '06470': {
      lat: 41.3943,
      lng: -73.31918,
      city: 'Newtown',
      state: 'CT',
    },
    '06471': {
      lat: 41.33454,
      lng: -72.77372,
      city: 'North Branford',
      state: 'CT',
    },
    '06472': {
      lat: 41.38416,
      lng: -72.77911,
      city: 'Northford',
      state: 'CT',
    },
    '06473': {
      lat: 41.38069,
      lng: -72.85598,
      city: 'North Haven',
      state: 'CT',
    },
    '06475': {
      lat: 41.30174,
      lng: -72.38569,
      city: 'Old Saybrook',
      state: 'CT',
    },
    '06477': { lat: 41.2827, lng: -73.0272, city: 'Orange', state: 'CT' },
    '06478': {
      lat: 41.43133,
      lng: -73.13499,
      city: 'Oxford',
      state: 'CT',
    },
    '06479': {
      lat: 41.57799,
      lng: -72.90398,
      city: 'Plantsville',
      state: 'CT',
    },
    '06480': {
      lat: 41.59885,
      lng: -72.5893,
      city: 'Portland',
      state: 'CT',
    },
    '06481': {
      lat: 41.53435,
      lng: -72.70067,
      city: 'Rockfall',
      state: 'CT',
    },
    '06482': {
      lat: 41.4067,
      lng: -73.24474,
      city: 'Sandy Hook',
      state: 'CT',
    },
    '06483': {
      lat: 41.381,
      lng: -73.08726,
      city: 'Seymour',
      state: 'CT',
    },
    '06484': {
      lat: 41.30601,
      lng: -73.13828,
      city: 'Shelton',
      state: 'CT',
    },
    '06488': {
      lat: 41.47451,
      lng: -73.23288,
      city: 'Southbury',
      state: 'CT',
    },
    '06489': {
      lat: 41.61312,
      lng: -72.87233,
      city: 'Southington',
      state: 'CT',
    },
    '06492': {
      lat: 41.45927,
      lng: -72.80473,
      city: 'Wallingford',
      state: 'CT',
    },
    '06498': {
      lat: 41.30688,
      lng: -72.46652,
      city: 'Westbrook',
      state: 'CT',
    },
    '06510': {
      lat: 41.3062,
      lng: -72.92652,
      city: 'New Haven',
      state: 'CT',
    },
    '06511': {
      lat: 41.31663,
      lng: -72.92587,
      city: 'New Haven',
      state: 'CT',
    },
    '06512': {
      lat: 41.28111,
      lng: -72.8719,
      city: 'East Haven',
      state: 'CT',
    },
    '06513': {
      lat: 41.31953,
      lng: -72.86856,
      city: 'New Haven',
      state: 'CT',
    },
    '06514': {
      lat: 41.3729,
      lng: -72.94159,
      city: 'Hamden',
      state: 'CT',
    },
    '06515': {
      lat: 41.32794,
      lng: -72.96954,
      city: 'New Haven',
      state: 'CT',
    },
    '06516': {
      lat: 41.27393,
      lng: -72.96718,
      city: 'West Haven',
      state: 'CT',
    },
    '06517': {
      lat: 41.34834,
      lng: -72.90494,
      city: 'Hamden',
      state: 'CT',
    },
    '06518': {
      lat: 41.42744,
      lng: -72.91339,
      city: 'Hamden',
      state: 'CT',
    },
    '06519': {
      lat: 41.29597,
      lng: -72.93764,
      city: 'New Haven',
      state: 'CT',
    },
    '06524': {
      lat: 41.42606,
      lng: -72.99308,
      city: 'Bethany',
      state: 'CT',
    },
    '06525': {
      lat: 41.35662,
      lng: -73.01017,
      city: 'Woodbridge',
      state: 'CT',
    },
    '06604': {
      lat: 41.17684,
      lng: -73.20027,
      city: 'Bridgeport',
      state: 'CT',
    },
    '06605': {
      lat: 41.16348,
      lng: -73.21763,
      city: 'Bridgeport',
      state: 'CT',
    },
    '06606': {
      lat: 41.21218,
      lng: -73.20829,
      city: 'Bridgeport',
      state: 'CT',
    },
    '06607': {
      lat: 41.1736,
      lng: -73.16696,
      city: 'Bridgeport',
      state: 'CT',
    },
    '06608': {
      lat: 41.18675,
      lng: -73.1809,
      city: 'Bridgeport',
      state: 'CT',
    },
    '06610': {
      lat: 41.20457,
      lng: -73.16933,
      city: 'Bridgeport',
      state: 'CT',
    },
    '06611': {
      lat: 41.26009,
      lng: -73.20823,
      city: 'Trumbull',
      state: 'CT',
    },
    '06612': {
      lat: 41.26493,
      lng: -73.30006,
      city: 'Easton',
      state: 'CT',
    },
    '06614': {
      lat: 41.22989,
      lng: -73.12785,
      city: 'Stratford',
      state: 'CT',
    },
    '06615': {
      lat: 41.17082,
      lng: -73.13477,
      city: 'Stratford',
      state: 'CT',
    },
    '06702': {
      lat: 41.55713,
      lng: -73.04539,
      city: 'Waterbury',
      state: 'CT',
    },
    '06704': {
      lat: 41.58601,
      lng: -73.03296,
      city: 'Waterbury',
      state: 'CT',
    },
    '06705': {
      lat: 41.549,
      lng: -72.992,
      city: 'Waterbury',
      state: 'CT',
    },
    '06706': {
      lat: 41.53285,
      lng: -73.02389,
      city: 'Waterbury',
      state: 'CT',
    },
    '06708': {
      lat: 41.54939,
      lng: -73.06738,
      city: 'Waterbury',
      state: 'CT',
    },
    '06710': {
      lat: 41.56958,
      lng: -73.04598,
      city: 'Waterbury',
      state: 'CT',
    },
    '06712': {
      lat: 41.49927,
      lng: -72.97609,
      city: 'Prospect',
      state: 'CT',
    },
    '06716': {
      lat: 41.60102,
      lng: -72.97325,
      city: 'Wolcott',
      state: 'CT',
    },
    '06750': {
      lat: 41.72327,
      lng: -73.2624,
      city: 'Bantam',
      state: 'CT',
    },
    '06751': {
      lat: 41.63792,
      lng: -73.2122,
      city: 'Bethlehem',
      state: 'CT',
    },
    '06752': {
      lat: 41.52082,
      lng: -73.35965,
      city: 'Bridgewater',
      state: 'CT',
    },
    '06753': {
      lat: 41.82514,
      lng: -73.34471,
      city: 'Cornwall',
      state: 'CT',
    },
    '06754': {
      lat: 41.76841,
      lng: -73.36127,
      city: 'Cornwall Bridge',
      state: 'CT',
    },
    '06755': {
      lat: 41.64915,
      lng: -73.48143,
      city: 'Gaylordsville',
      state: 'CT',
    },
    '06756': {
      lat: 41.84714,
      lng: -73.23715,
      city: 'Goshen',
      state: 'CT',
    },
    '06757': { lat: 41.73951, lng: -73.46637, city: 'Kent', state: 'CT' },
    '06758': {
      lat: 41.67672,
      lng: -73.2286,
      city: 'Lakeside',
      state: 'CT',
    },
    '06759': {
      lat: 41.75757,
      lng: -73.21544,
      city: 'Litchfield',
      state: 'CT',
    },
    '06762': {
      lat: 41.5271,
      lng: -73.1228,
      city: 'Middlebury',
      state: 'CT',
    },
    '06763': {
      lat: 41.69027,
      lng: -73.20714,
      city: 'Morris',
      state: 'CT',
    },
    '06770': {
      lat: 41.48913,
      lng: -73.05181,
      city: 'Naugatuck',
      state: 'CT',
    },
    '06776': {
      lat: 41.60011,
      lng: -73.4162,
      city: 'New Milford',
      state: 'CT',
    },
    '06777': {
      lat: 41.69412,
      lng: -73.33931,
      city: 'New Preston Marble Dale',
      state: 'CT',
    },
    '06778': {
      lat: 41.71469,
      lng: -73.10459,
      city: 'Northfield',
      state: 'CT',
    },
    '06779': {
      lat: 41.59562,
      lng: -73.08058,
      city: 'Oakville',
      state: 'CT',
    },
    '06782': {
      lat: 41.65448,
      lng: -73.04731,
      city: 'Plymouth',
      state: 'CT',
    },
    '06783': {
      lat: 41.55424,
      lng: -73.29998,
      city: 'Roxbury',
      state: 'CT',
    },
    '06784': {
      lat: 41.58018,
      lng: -73.49853,
      city: 'Sherman',
      state: 'CT',
    },
    '06785': {
      lat: 41.70491,
      lng: -73.43815,
      city: 'South Kent',
      state: 'CT',
    },
    '06786': {
      lat: 41.66912,
      lng: -73.01502,
      city: 'Terryville',
      state: 'CT',
    },
    '06787': {
      lat: 41.67033,
      lng: -73.08568,
      city: 'Thomaston',
      state: 'CT',
    },
    '06790': {
      lat: 41.83747,
      lng: -73.12874,
      city: 'Torrington',
      state: 'CT',
    },
    '06791': {
      lat: 41.75509,
      lng: -73.05838,
      city: 'Harwinton',
      state: 'CT',
    },
    '06793': {
      lat: 41.63089,
      lng: -73.28835,
      city: 'Washington',
      state: 'CT',
    },
    '06794': {
      lat: 41.64932,
      lng: -73.32511,
      city: 'Washington Depot',
      state: 'CT',
    },
    '06795': {
      lat: 41.61873,
      lng: -73.12336,
      city: 'Watertown',
      state: 'CT',
    },
    '06796': {
      lat: 41.87595,
      lng: -73.33255,
      city: 'West Cornwall',
      state: 'CT',
    },
    '06798': {
      lat: 41.56149,
      lng: -73.20703,
      city: 'Woodbury',
      state: 'CT',
    },
    '06801': {
      lat: 41.37468,
      lng: -73.39275,
      city: 'Bethel',
      state: 'CT',
    },
    '06804': {
      lat: 41.46731,
      lng: -73.39232,
      city: 'Brookfield',
      state: 'CT',
    },
    '06807': {
      lat: 41.0611,
      lng: -73.59215,
      city: 'Cos Cob',
      state: 'CT',
    },
    '06810': {
      lat: 41.37627,
      lng: -73.45904,
      city: 'Danbury',
      state: 'CT',
    },
    '06811': {
      lat: 41.42427,
      lng: -73.4821,
      city: 'Danbury',
      state: 'CT',
    },
    '06812': {
      lat: 41.48796,
      lng: -73.48827,
      city: 'New Fairfield',
      state: 'CT',
    },
    '06820': {
      lat: 41.07983,
      lng: -73.48242,
      city: 'Darien',
      state: 'CT',
    },
    '06824': {
      lat: 41.17571,
      lng: -73.28111,
      city: 'Fairfield',
      state: 'CT',
    },
    '06825': {
      lat: 41.19685,
      lng: -73.24221,
      city: 'Fairfield',
      state: 'CT',
    },
    '06830': {
      lat: 41.048,
      lng: -73.624,
      city: 'Greenwich',
      state: 'CT',
    },
    '06831': {
      lat: 41.08782,
      lng: -73.66078,
      city: 'Greenwich',
      state: 'CT',
    },
    '06840': {
      lat: 41.15925,
      lng: -73.49916,
      city: 'New Canaan',
      state: 'CT',
    },
    '06850': {
      lat: 41.1263,
      lng: -73.44316,
      city: 'Norwalk',
      state: 'CT',
    },
    '06851': {
      lat: 41.13948,
      lng: -73.40387,
      city: 'Norwalk',
      state: 'CT',
    },
    '06853': {
      lat: 41.06962,
      lng: -73.43775,
      city: 'Norwalk',
      state: 'CT',
    },
    '06854': {
      lat: 41.09148,
      lng: -73.42972,
      city: 'Norwalk',
      state: 'CT',
    },
    '06855': {
      lat: 41.08922,
      lng: -73.39615,
      city: 'Norwalk',
      state: 'CT',
    },
    '06870': {
      lat: 41.02881,
      lng: -73.56964,
      city: 'Old Greenwich',
      state: 'CT',
    },
    '06877': {
      lat: 41.30642,
      lng: -73.50216,
      city: 'Ridgefield',
      state: 'CT',
    },
    '06878': {
      lat: 41.03191,
      lng: -73.58283,
      city: 'Riverside',
      state: 'CT',
    },
    '06880': {
      lat: 41.14379,
      lng: -73.34698,
      city: 'Westport',
      state: 'CT',
    },
    '06883': {
      lat: 41.22842,
      lng: -73.37256,
      city: 'Weston',
      state: 'CT',
    },
    '06890': {
      lat: 41.14639,
      lng: -73.28944,
      city: 'Southport',
      state: 'CT',
    },
    '06896': {
      lat: 41.3051,
      lng: -73.39139,
      city: 'Redding',
      state: 'CT',
    },
    '06897': {
      lat: 41.20705,
      lng: -73.44009,
      city: 'Wilton',
      state: 'CT',
    },
    '06901': {
      lat: 41.05349,
      lng: -73.53845,
      city: 'Stamford',
      state: 'CT',
    },
    '06902': {
      lat: 41.05949,
      lng: -73.54751,
      city: 'Stamford',
      state: 'CT',
    },
    '06903': {
      lat: 41.13571,
      lng: -73.57207,
      city: 'Stamford',
      state: 'CT',
    },
    '06905': {
      lat: 41.08792,
      lng: -73.54351,
      city: 'Stamford',
      state: 'CT',
    },
    '06906': {
      lat: 41.07244,
      lng: -73.52217,
      city: 'Stamford',
      state: 'CT',
    },
    '06907': {
      lat: 41.1021,
      lng: -73.52033,
      city: 'Stamford',
      state: 'CT',
    },
    '07001': {
      lat: 40.58342,
      lng: -74.26992,
      city: 'Avenel',
      state: 'NJ',
    },
    '07002': {
      lat: 40.66681,
      lng: -74.11582,
      city: 'Bayonne',
      state: 'NJ',
    },
    '07003': {
      lat: 40.80976,
      lng: -74.18683,
      city: 'Bloomfield',
      state: 'NJ',
    },
    '07004': {
      lat: 40.88282,
      lng: -74.30413,
      city: 'Fairfield',
      state: 'NJ',
    },
    '07005': {
      lat: 40.92738,
      lng: -74.41706,
      city: 'Boonton',
      state: 'NJ',
    },
    '07006': {
      lat: 40.85221,
      lng: -74.2817,
      city: 'Caldwell',
      state: 'NJ',
    },
    '07008': {
      lat: 40.58489,
      lng: -74.2284,
      city: 'Carteret',
      state: 'NJ',
    },
    '07009': {
      lat: 40.85651,
      lng: -74.22871,
      city: 'Cedar Grove',
      state: 'NJ',
    },
    '07010': {
      lat: 40.82217,
      lng: -73.98792,
      city: 'Cliffside Park',
      state: 'NJ',
    },
    '07011': {
      lat: 40.8801,
      lng: -74.14449,
      city: 'Clifton',
      state: 'NJ',
    },
    '07012': {
      lat: 40.84694,
      lng: -74.1596,
      city: 'Clifton',
      state: 'NJ',
    },
    '07013': {
      lat: 40.86935,
      lng: -74.17267,
      city: 'Clifton',
      state: 'NJ',
    },
    '07014': {
      lat: 40.83162,
      lng: -74.13532,
      city: 'Clifton',
      state: 'NJ',
    },
    '07016': {
      lat: 40.65678,
      lng: -74.30354,
      city: 'Cranford',
      state: 'NJ',
    },
    '07017': {
      lat: 40.77192,
      lng: -74.20712,
      city: 'East Orange',
      state: 'NJ',
    },
    '07018': {
      lat: 40.75572,
      lng: -74.21793,
      city: 'East Orange',
      state: 'NJ',
    },
    '07020': {
      lat: 40.82374,
      lng: -73.97401,
      city: 'Edgewater',
      state: 'NJ',
    },
    '07021': {
      lat: 40.82708,
      lng: -74.27981,
      city: 'Essex Fells',
      state: 'NJ',
    },
    '07022': {
      lat: 40.81821,
      lng: -74.0022,
      city: 'Fairview',
      state: 'NJ',
    },
    '07023': {
      lat: 40.64171,
      lng: -74.38575,
      city: 'Fanwood',
      state: 'NJ',
    },
    '07024': {
      lat: 40.85088,
      lng: -73.97128,
      city: 'Fort Lee',
      state: 'NJ',
    },
    '07026': {
      lat: 40.87914,
      lng: -74.1085,
      city: 'Garfield',
      state: 'NJ',
    },
    '07027': {
      lat: 40.65132,
      lng: -74.32313,
      city: 'Garwood',
      state: 'NJ',
    },
    '07028': {
      lat: 40.80421,
      lng: -74.20428,
      city: 'Glen Ridge',
      state: 'NJ',
    },
    '07029': {
      lat: 40.74377,
      lng: -74.15389,
      city: 'Harrison',
      state: 'NJ',
    },
    '07030': {
      lat: 40.7451,
      lng: -74.02815,
      city: 'Hoboken',
      state: 'NJ',
    },
    '07031': {
      lat: 40.78745,
      lng: -74.12731,
      city: 'North Arlington',
      state: 'NJ',
    },
    '07032': {
      lat: 40.75281,
      lng: -74.12016,
      city: 'Kearny',
      state: 'NJ',
    },
    '07033': {
      lat: 40.67812,
      lng: -74.28898,
      city: 'Kenilworth',
      state: 'NJ',
    },
    '07034': {
      lat: 40.87953,
      lng: -74.37965,
      city: 'Lake Hiawatha',
      state: 'NJ',
    },
    '07035': {
      lat: 40.925,
      lng: -74.30462,
      city: 'Lincoln Park',
      state: 'NJ',
    },
    '07036': {
      lat: 40.62526,
      lng: -74.23917,
      city: 'Linden',
      state: 'NJ',
    },
    '07039': {
      lat: 40.78554,
      lng: -74.32909,
      city: 'Livingston',
      state: 'NJ',
    },
    '07040': {
      lat: 40.73302,
      lng: -74.27117,
      city: 'Maplewood',
      state: 'NJ',
    },
    '07041': {
      lat: 40.72301,
      lng: -74.30065,
      city: 'Millburn',
      state: 'NJ',
    },
    '07042': {
      lat: 40.81382,
      lng: -74.21775,
      city: 'Montclair',
      state: 'NJ',
    },
    '07043': {
      lat: 40.84411,
      lng: -74.20041,
      city: 'Montclair',
      state: 'NJ',
    },
    '07044': {
      lat: 40.83228,
      lng: -74.24308,
      city: 'Verona',
      state: 'NJ',
    },
    '07045': {
      lat: 40.90736,
      lng: -74.3671,
      city: 'Montville',
      state: 'NJ',
    },
    '07046': {
      lat: 40.89067,
      lng: -74.44072,
      city: 'Mountain Lakes',
      state: 'NJ',
    },
    '07047': {
      lat: 40.79376,
      lng: -74.02416,
      city: 'North Bergen',
      state: 'NJ',
    },
    '07050': {
      lat: 40.76817,
      lng: -74.2348,
      city: 'Orange',
      state: 'NJ',
    },
    '07052': {
      lat: 40.78928,
      lng: -74.26281,
      city: 'West Orange',
      state: 'NJ',
    },
    '07054': {
      lat: 40.85527,
      lng: -74.40239,
      city: 'Parsippany',
      state: 'NJ',
    },
    '07055': {
      lat: 40.85746,
      lng: -74.12821,
      city: 'Passaic',
      state: 'NJ',
    },
    '07057': {
      lat: 40.85355,
      lng: -74.10691,
      city: 'Wallington',
      state: 'NJ',
    },
    '07058': {
      lat: 40.86701,
      lng: -74.34283,
      city: 'Pine Brook',
      state: 'NJ',
    },
    '07059': {
      lat: 40.63199,
      lng: -74.51463,
      city: 'Warren',
      state: 'NJ',
    },
    '07060': {
      lat: 40.61606,
      lng: -74.42164,
      city: 'Plainfield',
      state: 'NJ',
    },
    '07062': {
      lat: 40.63174,
      lng: -74.40391,
      city: 'Plainfield',
      state: 'NJ',
    },
    '07063': {
      lat: 40.60524,
      lng: -74.44615,
      city: 'Plainfield',
      state: 'NJ',
    },
    '07064': {
      lat: 40.56865,
      lng: -74.2464,
      city: 'Port Reading',
      state: 'NJ',
    },
    '07065': {
      lat: 40.60765,
      lng: -74.28066,
      city: 'Rahway',
      state: 'NJ',
    },
    '07066': {
      lat: 40.62061,
      lng: -74.31335,
      city: 'Clark',
      state: 'NJ',
    },
    '07067': {
      lat: 40.59255,
      lng: -74.31479,
      city: 'Colonia',
      state: 'NJ',
    },
    '07068': {
      lat: 40.82077,
      lng: -74.30862,
      city: 'Roseland',
      state: 'NJ',
    },
    '07069': {
      lat: 40.64249,
      lng: -74.4396,
      city: 'Watchung',
      state: 'NJ',
    },
    '07070': {
      lat: 40.82034,
      lng: -74.10574,
      city: 'Rutherford',
      state: 'NJ',
    },
    '07071': {
      lat: 40.79646,
      lng: -74.1099,
      city: 'Lyndhurst',
      state: 'NJ',
    },
    '07072': {
      lat: 40.82472,
      lng: -74.06126,
      city: 'Carlstadt',
      state: 'NJ',
    },
    '07073': {
      lat: 40.81792,
      lng: -74.08539,
      city: 'East Rutherford',
      state: 'NJ',
    },
    '07074': {
      lat: 40.83877,
      lng: -74.05705,
      city: 'Moonachie',
      state: 'NJ',
    },
    '07075': {
      lat: 40.85076,
      lng: -74.08779,
      city: 'Wood Ridge',
      state: 'NJ',
    },
    '07076': {
      lat: 40.63308,
      lng: -74.37387,
      city: 'Scotch Plains',
      state: 'NJ',
    },
    '07077': {
      lat: 40.55415,
      lng: -74.25489,
      city: 'Sewaren',
      state: 'NJ',
    },
    '07078': {
      lat: 40.74196,
      lng: -74.3277,
      city: 'Short Hills',
      state: 'NJ',
    },
    '07079': {
      lat: 40.74908,
      lng: -74.26022,
      city: 'South Orange',
      state: 'NJ',
    },
    '07080': {
      lat: 40.57483,
      lng: -74.41542,
      city: 'South Plainfield',
      state: 'NJ',
    },
    '07081': {
      lat: 40.69949,
      lng: -74.32542,
      city: 'Springfield',
      state: 'NJ',
    },
    '07082': {
      lat: 40.92643,
      lng: -74.34524,
      city: 'Towaco',
      state: 'NJ',
    },
    '07083': { lat: 40.69443, lng: -74.2692, city: 'Union', state: 'NJ' },
    '07086': {
      lat: 40.76761,
      lng: -74.01693,
      city: 'Weehawken',
      state: 'NJ',
    },
    '07087': {
      lat: 40.76723,
      lng: -74.03243,
      city: 'Union City',
      state: 'NJ',
    },
    '07088': {
      lat: 40.71832,
      lng: -74.28375,
      city: 'Vauxhall',
      state: 'NJ',
    },
    '07090': {
      lat: 40.65172,
      lng: -74.34328,
      city: 'Westfield',
      state: 'NJ',
    },
    '07092': {
      lat: 40.68106,
      lng: -74.36038,
      city: 'Mountainside',
      state: 'NJ',
    },
    '07093': {
      lat: 40.78675,
      lng: -74.00871,
      city: 'West New York',
      state: 'NJ',
    },
    '07094': {
      lat: 40.78109,
      lng: -74.06588,
      city: 'Secaucus',
      state: 'NJ',
    },
    '07095': {
      lat: 40.55358,
      lng: -74.28743,
      city: 'Woodbridge',
      state: 'NJ',
    },
    '07102': {
      lat: 40.73576,
      lng: -74.17343,
      city: 'Newark',
      state: 'NJ',
    },
    '07103': {
      lat: 40.73886,
      lng: -74.19529,
      city: 'Newark',
      state: 'NJ',
    },
    '07104': {
      lat: 40.76735,
      lng: -74.16831,
      city: 'Newark',
      state: 'NJ',
    },
    '07105': {
      lat: 40.72266,
      lng: -74.13874,
      city: 'Newark',
      state: 'NJ',
    },
    '07106': {
      lat: 40.74177,
      lng: -74.23047,
      city: 'Newark',
      state: 'NJ',
    },
    '07107': {
      lat: 40.76181,
      lng: -74.18653,
      city: 'Newark',
      state: 'NJ',
    },
    '07108': {
      lat: 40.72306,
      lng: -74.20039,
      city: 'Newark',
      state: 'NJ',
    },
    '07109': {
      lat: 40.79502,
      lng: -74.16161,
      city: 'Belleville',
      state: 'NJ',
    },
    '07110': {
      lat: 40.81926,
      lng: -74.15715,
      city: 'Nutley',
      state: 'NJ',
    },
    '07111': {
      lat: 40.72426,
      lng: -74.23173,
      city: 'Irvington',
      state: 'NJ',
    },
    '07112': {
      lat: 40.71137,
      lng: -74.21164,
      city: 'Newark',
      state: 'NJ',
    },
    '07114': {
      lat: 40.70018,
      lng: -74.16618,
      city: 'Newark',
      state: 'NJ',
    },
    '07201': {
      lat: 40.67237,
      lng: -74.17643,
      city: 'Elizabeth',
      state: 'NJ',
    },
    '07202': {
      lat: 40.6531,
      lng: -74.21677,
      city: 'Elizabeth',
      state: 'NJ',
    },
    '07203': {
      lat: 40.65269,
      lng: -74.2599,
      city: 'Roselle',
      state: 'NJ',
    },
    '07204': {
      lat: 40.66532,
      lng: -74.2666,
      city: 'Roselle Park',
      state: 'NJ',
    },
    '07205': {
      lat: 40.69612,
      lng: -74.22857,
      city: 'Hillside',
      state: 'NJ',
    },
    '07206': {
      lat: 40.65238,
      lng: -74.18385,
      city: 'Elizabethport',
      state: 'NJ',
    },
    '07208': {
      lat: 40.67434,
      lng: -74.22498,
      city: 'Elizabeth',
      state: 'NJ',
    },
    '07302': {
      lat: 40.71894,
      lng: -74.04462,
      city: 'Jersey City',
      state: 'NJ',
    },
    '07304': {
      lat: 40.71741,
      lng: -74.07637,
      city: 'Jersey City',
      state: 'NJ',
    },
    '07305': {
      lat: 40.69839,
      lng: -74.08158,
      city: 'Jersey City',
      state: 'NJ',
    },
    '07306': {
      lat: 40.73674,
      lng: -74.07132,
      city: 'Jersey City',
      state: 'NJ',
    },
    '07307': {
      lat: 40.75192,
      lng: -74.05429,
      city: 'Jersey City',
      state: 'NJ',
    },
    '07310': {
      lat: 40.72893,
      lng: -74.03391,
      city: 'Jersey City',
      state: 'NJ',
    },
    '07311': {
      lat: 40.71878,
      lng: -74.02933,
      city: 'Jersey City',
      state: 'NJ',
    },
    '07401': {
      lat: 41.03321,
      lng: -74.13328,
      city: 'Allendale',
      state: 'NJ',
    },
    '07403': {
      lat: 41.02059,
      lng: -74.33239,
      city: 'Bloomingdale',
      state: 'NJ',
    },
    '07405': {
      lat: 40.98646,
      lng: -74.38302,
      city: 'Butler',
      state: 'NJ',
    },
    '07407': {
      lat: 40.90493,
      lng: -74.12007,
      city: 'Elmwood Park',
      state: 'NJ',
    },
    '07410': {
      lat: 40.93587,
      lng: -74.11771,
      city: 'Fair Lawn',
      state: 'NJ',
    },
    '07416': {
      lat: 41.10892,
      lng: -74.58891,
      city: 'Franklin',
      state: 'NJ',
    },
    '07417': {
      lat: 41.00859,
      lng: -74.20836,
      city: 'Franklin Lakes',
      state: 'NJ',
    },
    '07418': {
      lat: 41.2387,
      lng: -74.4873,
      city: 'Glenwood',
      state: 'NJ',
    },
    '07419': {
      lat: 41.15394,
      lng: -74.57106,
      city: 'Hamburg',
      state: 'NJ',
    },
    '07420': {
      lat: 41.02926,
      lng: -74.30281,
      city: 'Haskell',
      state: 'NJ',
    },
    '07421': {
      lat: 41.1624,
      lng: -74.35586,
      city: 'Hewitt',
      state: 'NJ',
    },
    '07422': {
      lat: 41.17984,
      lng: -74.44177,
      city: 'Highland Lakes',
      state: 'NJ',
    },
    '07423': {
      lat: 41.00007,
      lng: -74.09718,
      city: 'Ho Ho Kus',
      state: 'NJ',
    },
    '07424': {
      lat: 40.88361,
      lng: -74.2059,
      city: 'Little Falls',
      state: 'NJ',
    },
    '07430': {
      lat: 41.08157,
      lng: -74.18562,
      city: 'Mahwah',
      state: 'NJ',
    },
    '07432': {
      lat: 40.99516,
      lng: -74.14109,
      city: 'Midland Park',
      state: 'NJ',
    },
    '07435': {
      lat: 41.0425,
      lng: -74.44159,
      city: 'Newfoundland',
      state: 'NJ',
    },
    '07436': {
      lat: 41.03135,
      lng: -74.24081,
      city: 'Oakland',
      state: 'NJ',
    },
    '07438': {
      lat: 41.0287,
      lng: -74.52038,
      city: 'Oak Ridge',
      state: 'NJ',
    },
    '07439': {
      lat: 41.0769,
      lng: -74.59734,
      city: 'Ogdensburg',
      state: 'NJ',
    },
    '07440': {
      lat: 40.94668,
      lng: -74.29421,
      city: 'Pequannock',
      state: 'NJ',
    },
    '07442': {
      lat: 41.0024,
      lng: -74.28589,
      city: 'Pompton Lakes',
      state: 'NJ',
    },
    '07444': {
      lat: 40.96782,
      lng: -74.30724,
      city: 'Pompton Plains',
      state: 'NJ',
    },
    '07446': {
      lat: 41.05944,
      lng: -74.14532,
      city: 'Ramsey',
      state: 'NJ',
    },
    '07450': {
      lat: 40.98219,
      lng: -74.11275,
      city: 'Ridgewood',
      state: 'NJ',
    },
    '07452': {
      lat: 40.96008,
      lng: -74.12498,
      city: 'Glen Rock',
      state: 'NJ',
    },
    '07456': {
      lat: 41.10647,
      lng: -74.27487,
      city: 'Ringwood',
      state: 'NJ',
    },
    '07457': {
      lat: 40.99212,
      lng: -74.3125,
      city: 'Riverdale',
      state: 'NJ',
    },
    '07458': {
      lat: 41.04583,
      lng: -74.0971,
      city: 'Saddle River',
      state: 'NJ',
    },
    '07460': {
      lat: 41.11341,
      lng: -74.49006,
      city: 'Stockholm',
      state: 'NJ',
    },
    '07461': {
      lat: 41.24213,
      lng: -74.60648,
      city: 'Sussex',
      state: 'NJ',
    },
    '07462': {
      lat: 41.19873,
      lng: -74.48911,
      city: 'Vernon',
      state: 'NJ',
    },
    '07463': {
      lat: 41.01334,
      lng: -74.12585,
      city: 'Waldwick',
      state: 'NJ',
    },
    '07465': {
      lat: 41.05015,
      lng: -74.29849,
      city: 'Wanaque',
      state: 'NJ',
    },
    '07470': {
      lat: 40.94805,
      lng: -74.24504,
      city: 'Wayne',
      state: 'NJ',
    },
    '07480': {
      lat: 41.08713,
      lng: -74.3779,
      city: 'West Milford',
      state: 'NJ',
    },
    '07481': {
      lat: 40.99891,
      lng: -74.16757,
      city: 'Wyckoff',
      state: 'NJ',
    },
    '07501': {
      lat: 40.9125,
      lng: -74.17099,
      city: 'Paterson',
      state: 'NJ',
    },
    '07502': {
      lat: 40.91903,
      lng: -74.19461,
      city: 'Paterson',
      state: 'NJ',
    },
    '07503': {
      lat: 40.89768,
      lng: -74.15353,
      city: 'Paterson',
      state: 'NJ',
    },
    '07504': {
      lat: 40.912,
      lng: -74.14121,
      city: 'Paterson',
      state: 'NJ',
    },
    '07505': {
      lat: 40.91694,
      lng: -74.17222,
      city: 'Paterson',
      state: 'NJ',
    },
    '07506': {
      lat: 40.95794,
      lng: -74.15815,
      city: 'Hawthorne',
      state: 'NJ',
    },
    '07508': {
      lat: 40.95448,
      lng: -74.18423,
      city: 'Haledon',
      state: 'NJ',
    },
    '07512': {
      lat: 40.90385,
      lng: -74.22118,
      city: 'Totowa',
      state: 'NJ',
    },
    '07513': {
      lat: 40.90565,
      lng: -74.14869,
      city: 'Paterson',
      state: 'NJ',
    },
    '07514': {
      lat: 40.92596,
      lng: -74.14452,
      city: 'Paterson',
      state: 'NJ',
    },
    '07522': {
      lat: 40.92413,
      lng: -74.17928,
      city: 'Paterson',
      state: 'NJ',
    },
    '07524': {
      lat: 40.93226,
      lng: -74.15674,
      city: 'Paterson',
      state: 'NJ',
    },
    '07601': {
      lat: 40.8891,
      lng: -74.04614,
      city: 'Hackensack',
      state: 'NJ',
    },
    '07603': {
      lat: 40.87507,
      lng: -74.02934,
      city: 'Bogota',
      state: 'NJ',
    },
    '07604': {
      lat: 40.86166,
      lng: -74.07413,
      city: 'Hasbrouck Heights',
      state: 'NJ',
    },
    '07605': {
      lat: 40.86362,
      lng: -73.9899,
      city: 'Leonia',
      state: 'NJ',
    },
    '07606': {
      lat: 40.85783,
      lng: -74.05177,
      city: 'South Hackensack',
      state: 'NJ',
    },
    '07607': {
      lat: 40.90249,
      lng: -74.06337,
      city: 'Maywood',
      state: 'NJ',
    },
    '07608': {
      lat: 40.85297,
      lng: -74.06045,
      city: 'Teterboro',
      state: 'NJ',
    },
    '07620': {
      lat: 40.96165,
      lng: -73.91983,
      city: 'Alpine',
      state: 'NJ',
    },
    '07621': {
      lat: 40.92357,
      lng: -73.99824,
      city: 'Bergenfield',
      state: 'NJ',
    },
    '07624': {
      lat: 40.97329,
      lng: -73.96044,
      city: 'Closter',
      state: 'NJ',
    },
    '07626': {
      lat: 40.94054,
      lng: -73.9596,
      city: 'Cresskill',
      state: 'NJ',
    },
    '07627': {
      lat: 40.95504,
      lng: -73.95678,
      city: 'Demarest',
      state: 'NJ',
    },
    '07628': {
      lat: 40.94524,
      lng: -73.99227,
      city: 'Dumont',
      state: 'NJ',
    },
    '07630': {
      lat: 40.97484,
      lng: -74.024,
      city: 'Emerson',
      state: 'NJ',
    },
    '07631': {
      lat: 40.89161,
      lng: -73.97368,
      city: 'Englewood',
      state: 'NJ',
    },
    '07632': {
      lat: 40.88227,
      lng: -73.94672,
      city: 'Englewood Cliffs',
      state: 'NJ',
    },
    '07640': {
      lat: 40.9899,
      lng: -73.98037,
      city: 'Harrington Park',
      state: 'NJ',
    },
    '07641': {
      lat: 40.96224,
      lng: -73.9976,
      city: 'Haworth',
      state: 'NJ',
    },
    '07642': {
      lat: 41.00742,
      lng: -74.04402,
      city: 'Hillsdale',
      state: 'NJ',
    },
    '07643': {
      lat: 40.84635,
      lng: -74.03879,
      city: 'Little Ferry',
      state: 'NJ',
    },
    '07644': { lat: 40.87836, lng: -74.08145, city: 'Lodi', state: 'NJ' },
    '07645': {
      lat: 41.05293,
      lng: -74.04985,
      city: 'Montvale',
      state: 'NJ',
    },
    '07646': {
      lat: 40.93368,
      lng: -74.01956,
      city: 'New Milford',
      state: 'NJ',
    },
    '07647': {
      lat: 41.00674,
      lng: -73.94255,
      city: 'Northvale',
      state: 'NJ',
    },
    '07648': {
      lat: 40.99335,
      lng: -73.95103,
      city: 'Norwood',
      state: 'NJ',
    },
    '07649': {
      lat: 40.95622,
      lng: -74.03141,
      city: 'Oradell',
      state: 'NJ',
    },
    '07650': {
      lat: 40.84716,
      lng: -73.99664,
      city: 'Palisades Park',
      state: 'NJ',
    },
    '07652': {
      lat: 40.94553,
      lng: -74.07122,
      city: 'Paramus',
      state: 'NJ',
    },
    '07656': {
      lat: 41.03524,
      lng: -74.04232,
      city: 'Park Ridge',
      state: 'NJ',
    },
    '07657': {
      lat: 40.83127,
      lng: -74.01471,
      city: 'Ridgefield',
      state: 'NJ',
    },
    '07660': {
      lat: 40.85434,
      lng: -74.02013,
      city: 'Ridgefield Park',
      state: 'NJ',
    },
    '07661': {
      lat: 40.92697,
      lng: -74.03874,
      city: 'River Edge',
      state: 'NJ',
    },
    '07662': {
      lat: 40.90698,
      lng: -74.07911,
      city: 'Rochelle Park',
      state: 'NJ',
    },
    '07663': {
      lat: 40.90328,
      lng: -74.09545,
      city: 'Saddle Brook',
      state: 'NJ',
    },
    '07666': {
      lat: 40.88998,
      lng: -74.01067,
      city: 'Teaneck',
      state: 'NJ',
    },
    '07670': {
      lat: 40.91758,
      lng: -73.95323,
      city: 'Tenafly',
      state: 'NJ',
    },
    '07675': {
      lat: 41.00918,
      lng: -74.00428,
      city: 'Westwood',
      state: 'NJ',
    },
    '07676': {
      lat: 40.98843,
      lng: -74.06359,
      city: 'Township Of Washington',
      state: 'NJ',
    },
    '07677': {
      lat: 41.0253,
      lng: -74.06038,
      city: 'Woodcliff Lake',
      state: 'NJ',
    },
    '07701': {
      lat: 40.36046,
      lng: -74.07866,
      city: 'Red Bank',
      state: 'NJ',
    },
    '07702': {
      lat: 40.32489,
      lng: -74.06002,
      city: 'Shrewsbury',
      state: 'NJ',
    },
    '07703': {
      lat: 40.31411,
      lng: -74.046,
      city: 'Fort Monmouth',
      state: 'NJ',
    },
    '07704': {
      lat: 40.36176,
      lng: -74.03926,
      city: 'Fair Haven',
      state: 'NJ',
    },
    '07711': {
      lat: 40.2379,
      lng: -74.0076,
      city: 'Allenhurst',
      state: 'NJ',
    },
    '07712': {
      lat: 40.24805,
      lng: -74.05531,
      city: 'Asbury Park',
      state: 'NJ',
    },
    '07716': {
      lat: 40.40237,
      lng: -74.03533,
      city: 'Atlantic Highlands',
      state: 'NJ',
    },
    '07717': {
      lat: 40.19129,
      lng: -74.01657,
      city: 'Avon By The Sea',
      state: 'NJ',
    },
    '07718': {
      lat: 40.42045,
      lng: -74.08488,
      city: 'Belford',
      state: 'NJ',
    },
    '07719': {
      lat: 40.16864,
      lng: -74.07271,
      city: 'Belmar',
      state: 'NJ',
    },
    '07720': {
      lat: 40.20176,
      lng: -74.01276,
      city: 'Bradley Beach',
      state: 'NJ',
    },
    '07721': {
      lat: 40.43556,
      lng: -74.23555,
      city: 'Cliffwood',
      state: 'NJ',
    },
    '07722': {
      lat: 40.28765,
      lng: -74.16745,
      city: 'Colts Neck',
      state: 'NJ',
    },
    '07723': { lat: 40.25016, lng: -74.00081, city: 'Deal', state: 'NJ' },
    '07724': {
      lat: 40.29801,
      lng: -74.07517,
      city: 'Eatontown',
      state: 'NJ',
    },
    '07726': {
      lat: 40.28318,
      lng: -74.34232,
      city: 'Englishtown',
      state: 'NJ',
    },
    '07727': {
      lat: 40.20352,
      lng: -74.15863,
      city: 'Farmingdale',
      state: 'NJ',
    },
    '07728': {
      lat: 40.22522,
      lng: -74.28447,
      city: 'Freehold',
      state: 'NJ',
    },
    '07730': {
      lat: 40.42611,
      lng: -74.17422,
      city: 'Hazlet',
      state: 'NJ',
    },
    '07731': {
      lat: 40.15014,
      lng: -74.20316,
      city: 'Howell',
      state: 'NJ',
    },
    '07732': {
      lat: 40.43115,
      lng: -73.99192,
      city: 'Highlands',
      state: 'NJ',
    },
    '07733': {
      lat: 40.37683,
      lng: -74.17259,
      city: 'Holmdel',
      state: 'NJ',
    },
    '07734': {
      lat: 40.44309,
      lng: -74.13517,
      city: 'Keansburg',
      state: 'NJ',
    },
    '07735': {
      lat: 40.44108,
      lng: -74.2028,
      city: 'Keyport',
      state: 'NJ',
    },
    '07737': {
      lat: 40.40953,
      lng: -74.06444,
      city: 'Leonardo',
      state: 'NJ',
    },
    '07738': {
      lat: 40.33918,
      lng: -74.12841,
      city: 'Lincroft',
      state: 'NJ',
    },
    '07739': {
      lat: 40.33569,
      lng: -74.03452,
      city: 'Little Silver',
      state: 'NJ',
    },
    '07740': {
      lat: 40.29581,
      lng: -73.9919,
      city: 'Long Branch',
      state: 'NJ',
    },
    '07746': {
      lat: 40.31675,
      lng: -74.25029,
      city: 'Marlboro',
      state: 'NJ',
    },
    '07747': {
      lat: 40.41312,
      lng: -74.2521,
      city: 'Matawan',
      state: 'NJ',
    },
    '07748': {
      lat: 40.39524,
      lng: -74.11573,
      city: 'Middletown',
      state: 'NJ',
    },
    '07750': {
      lat: 40.33639,
      lng: -73.98632,
      city: 'Monmouth Beach',
      state: 'NJ',
    },
    '07751': {
      lat: 40.36191,
      lng: -74.25821,
      city: 'Morganville',
      state: 'NJ',
    },
    '07753': {
      lat: 40.21184,
      lng: -74.07421,
      city: 'Neptune',
      state: 'NJ',
    },
    '07755': {
      lat: 40.26351,
      lng: -74.02344,
      city: 'Oakhurst',
      state: 'NJ',
    },
    '07756': {
      lat: 40.21184,
      lng: -74.00832,
      city: 'Ocean Grove',
      state: 'NJ',
    },
    '07757': {
      lat: 40.31583,
      lng: -74.01855,
      city: 'Oceanport',
      state: 'NJ',
    },
    '07758': {
      lat: 40.431,
      lng: -74.103,
      city: 'Port Monmouth',
      state: 'NJ',
    },
    '07760': {
      lat: 40.37053,
      lng: -74.00495,
      city: 'Rumson',
      state: 'NJ',
    },
    '07762': {
      lat: 40.15294,
      lng: -74.0359,
      city: 'Spring Lake',
      state: 'NJ',
    },
    '07764': {
      lat: 40.28832,
      lng: -74.01854,
      city: 'West Long Branch',
      state: 'NJ',
    },
    '07801': {
      lat: 40.93167,
      lng: -74.54643,
      city: 'Dover',
      state: 'NJ',
    },
    '07803': {
      lat: 40.87819,
      lng: -74.60003,
      city: 'Mine Hill',
      state: 'NJ',
    },
    '07820': {
      lat: 40.93104,
      lng: -74.80424,
      city: 'Allamuchy',
      state: 'NJ',
    },
    '07821': {
      lat: 40.96461,
      lng: -74.74807,
      city: 'Andover',
      state: 'NJ',
    },
    '07822': {
      lat: 41.1409,
      lng: -74.70373,
      city: 'Augusta',
      state: 'NJ',
    },
    '07823': {
      lat: 40.82589,
      lng: -75.03781,
      city: 'Belvidere',
      state: 'NJ',
    },
    '07825': {
      lat: 40.98308,
      lng: -74.9623,
      city: 'Blairstown',
      state: 'NJ',
    },
    '07826': {
      lat: 41.17633,
      lng: -74.79042,
      city: 'Branchville',
      state: 'NJ',
    },
    '07827': {
      lat: 41.28918,
      lng: -74.74351,
      city: 'Montague',
      state: 'NJ',
    },
    '07828': {
      lat: 40.88559,
      lng: -74.75237,
      city: 'Budd Lake',
      state: 'NJ',
    },
    '07830': {
      lat: 40.72099,
      lng: -74.80033,
      city: 'Califon',
      state: 'NJ',
    },
    '07831': {
      lat: 40.73909,
      lng: -74.94678,
      city: 'Changewater',
      state: 'NJ',
    },
    '07832': {
      lat: 40.94755,
      lng: -75.06385,
      city: 'Columbia',
      state: 'NJ',
    },
    '07833': {
      lat: 40.89422,
      lng: -75.06979,
      city: 'Delaware',
      state: 'NJ',
    },
    '07834': {
      lat: 40.88752,
      lng: -74.48689,
      city: 'Denville',
      state: 'NJ',
    },
    '07836': {
      lat: 40.84767,
      lng: -74.70163,
      city: 'Flanders',
      state: 'NJ',
    },
    '07838': {
      lat: 40.88925,
      lng: -74.91042,
      city: 'Great Meadows',
      state: 'NJ',
    },
    '07840': {
      lat: 40.86034,
      lng: -74.83192,
      city: 'Hackettstown',
      state: 'NJ',
    },
    '07842': {
      lat: 40.94477,
      lng: -74.50065,
      city: 'Hibernia',
      state: 'NJ',
    },
    '07843': {
      lat: 40.93989,
      lng: -74.65928,
      city: 'Hopatcong',
      state: 'NJ',
    },
    '07844': { lat: 40.90861, lng: -74.96961, city: 'Hope', state: 'NJ' },
    '07846': {
      lat: 40.96694,
      lng: -74.87671,
      city: 'Johnsonburg',
      state: 'NJ',
    },
    '07847': { lat: 40.8856, lng: -74.6237, city: 'Kenvil', state: 'NJ' },
    '07848': {
      lat: 41.10339,
      lng: -74.68312,
      city: 'Lafayette',
      state: 'NJ',
    },
    '07849': {
      lat: 40.96861,
      lng: -74.61066,
      city: 'Lake Hopatcong',
      state: 'NJ',
    },
    '07850': {
      lat: 40.90716,
      lng: -74.66158,
      city: 'Landing',
      state: 'NJ',
    },
    '07851': {
      lat: 41.19883,
      lng: -74.85923,
      city: 'Layton',
      state: 'NJ',
    },
    '07852': {
      lat: 40.88374,
      lng: -74.66531,
      city: 'Ledgewood',
      state: 'NJ',
    },
    '07853': {
      lat: 40.78785,
      lng: -74.78315,
      city: 'Long Valley',
      state: 'NJ',
    },
    '07856': {
      lat: 40.9189,
      lng: -74.6336,
      city: 'Mount Arlington',
      state: 'NJ',
    },
    '07857': {
      lat: 40.89733,
      lng: -74.69998,
      city: 'Netcong',
      state: 'NJ',
    },
    '07860': {
      lat: 41.0595,
      lng: -74.81159,
      city: 'Newton',
      state: 'NJ',
    },
    '07863': {
      lat: 40.81515,
      lng: -74.96562,
      city: 'Oxford',
      state: 'NJ',
    },
    '07865': {
      lat: 40.78722,
      lng: -74.89845,
      city: 'Port Murray',
      state: 'NJ',
    },
    '07866': {
      lat: 40.9531,
      lng: -74.48639,
      city: 'Rockaway',
      state: 'NJ',
    },
    '07869': {
      lat: 40.84528,
      lng: -74.5807,
      city: 'Randolph',
      state: 'NJ',
    },
    '07870': {
      lat: 40.80535,
      lng: -74.82303,
      city: 'Schooleys Mountain',
      state: 'NJ',
    },
    '07871': {
      lat: 41.04666,
      lng: -74.62793,
      city: 'Sparta',
      state: 'NJ',
    },
    '07874': {
      lat: 40.9285,
      lng: -74.70994,
      city: 'Stanhope',
      state: 'NJ',
    },
    '07876': {
      lat: 40.85302,
      lng: -74.65627,
      city: 'Succasunna',
      state: 'NJ',
    },
    '07878': {
      lat: 40.87152,
      lng: -74.47826,
      city: 'Mount Tabor',
      state: 'NJ',
    },
    '07880': { lat: 40.874, lng: -74.88503, city: 'Vienna', state: 'NJ' },
    '07881': {
      lat: 41.12213,
      lng: -74.93249,
      city: 'Wallpack Center',
      state: 'NJ',
    },
    '07882': {
      lat: 40.75327,
      lng: -75.00748,
      city: 'Washington',
      state: 'NJ',
    },
    '07885': {
      lat: 40.93172,
      lng: -74.57688,
      city: 'Wharton',
      state: 'NJ',
    },
    '07901': {
      lat: 40.71462,
      lng: -74.36604,
      city: 'Summit',
      state: 'NJ',
    },
    '07920': {
      lat: 40.68374,
      lng: -74.55444,
      city: 'Basking Ridge',
      state: 'NJ',
    },
    '07921': {
      lat: 40.65599,
      lng: -74.6771,
      city: 'Bedminster',
      state: 'NJ',
    },
    '07922': {
      lat: 40.67525,
      lng: -74.42806,
      city: 'Berkeley Heights',
      state: 'NJ',
    },
    '07924': {
      lat: 40.727,
      lng: -74.58941,
      city: 'Bernardsville',
      state: 'NJ',
    },
    '07926': {
      lat: 40.80029,
      lng: -74.56879,
      city: 'Brookside',
      state: 'NJ',
    },
    '07927': {
      lat: 40.82085,
      lng: -74.45479,
      city: 'Cedar Knolls',
      state: 'NJ',
    },
    '07928': {
      lat: 40.7262,
      lng: -74.41381,
      city: 'Chatham',
      state: 'NJ',
    },
    '07930': {
      lat: 40.78222,
      lng: -74.68642,
      city: 'Chester',
      state: 'NJ',
    },
    '07931': {
      lat: 40.70791,
      lng: -74.65732,
      city: 'Far Hills',
      state: 'NJ',
    },
    '07932': {
      lat: 40.77717,
      lng: -74.39246,
      city: 'Florham Park',
      state: 'NJ',
    },
    '07933': {
      lat: 40.69198,
      lng: -74.46658,
      city: 'Gillette',
      state: 'NJ',
    },
    '07934': {
      lat: 40.72044,
      lng: -74.68159,
      city: 'Gladstone',
      state: 'NJ',
    },
    '07935': {
      lat: 40.7371,
      lng: -74.45296,
      city: 'Green Village',
      state: 'NJ',
    },
    '07936': {
      lat: 40.81918,
      lng: -74.36382,
      city: 'East Hanover',
      state: 'NJ',
    },
    '07939': {
      lat: 40.66723,
      lng: -74.55215,
      city: 'Lyons',
      state: 'NJ',
    },
    '07940': {
      lat: 40.76006,
      lng: -74.41889,
      city: 'Madison',
      state: 'NJ',
    },
    '07945': {
      lat: 40.78065,
      lng: -74.59928,
      city: 'Mendham',
      state: 'NJ',
    },
    '07946': {
      lat: 40.67698,
      lng: -74.51143,
      city: 'Millington',
      state: 'NJ',
    },
    '07950': {
      lat: 40.84569,
      lng: -74.48271,
      city: 'Morris Plains',
      state: 'NJ',
    },
    '07960': {
      lat: 40.78452,
      lng: -74.49813,
      city: 'Morristown',
      state: 'NJ',
    },
    '07961': {
      lat: 40.78105,
      lng: -74.43517,
      city: 'Convent Station',
      state: 'NJ',
    },
    '07970': {
      lat: 40.80831,
      lng: -74.5727,
      city: 'Mount Freedom',
      state: 'NJ',
    },
    '07974': {
      lat: 40.69732,
      lng: -74.40321,
      city: 'New Providence',
      state: 'NJ',
    },
    '07976': {
      lat: 40.72747,
      lng: -74.47704,
      city: 'New Vernon',
      state: 'NJ',
    },
    '07977': {
      lat: 40.70969,
      lng: -74.64786,
      city: 'Peapack',
      state: 'NJ',
    },
    '07979': {
      lat: 40.71225,
      lng: -74.72581,
      city: 'Pottersville',
      state: 'NJ',
    },
    '07980': {
      lat: 40.6765,
      lng: -74.49465,
      city: 'Stirling',
      state: 'NJ',
    },
    '07981': {
      lat: 40.82375,
      lng: -74.42029,
      city: 'Whippany',
      state: 'NJ',
    },
    '08001': {
      lat: 39.55423,
      lng: -75.35683,
      city: 'Alloway',
      state: 'NJ',
    },
    '08002': {
      lat: 39.93142,
      lng: -75.02515,
      city: 'Cherry Hill',
      state: 'NJ',
    },
    '08003': {
      lat: 39.88334,
      lng: -74.97191,
      city: 'Cherry Hill',
      state: 'NJ',
    },
    '08004': { lat: 39.75494, lng: -74.83007, city: 'Atco', state: 'NJ' },
    '08005': {
      lat: 39.75503,
      lng: -74.30626,
      city: 'Barnegat',
      state: 'NJ',
    },
    '08006': {
      lat: 39.75299,
      lng: -74.10953,
      city: 'Barnegat Light',
      state: 'NJ',
    },
    '08007': {
      lat: 39.86509,
      lng: -75.05379,
      city: 'Barrington',
      state: 'NJ',
    },
    '08008': {
      lat: 39.62188,
      lng: -74.22421,
      city: 'Beach Haven',
      state: 'NJ',
    },
    '08009': {
      lat: 39.76154,
      lng: -74.93279,
      city: 'Berlin',
      state: 'NJ',
    },
    '08010': {
      lat: 40.05484,
      lng: -74.91407,
      city: 'Beverly',
      state: 'NJ',
    },
    '08011': {
      lat: 39.97504,
      lng: -74.71371,
      city: 'Birmingham',
      state: 'NJ',
    },
    '08012': {
      lat: 39.78409,
      lng: -75.05586,
      city: 'Blackwood',
      state: 'NJ',
    },
    '08014': {
      lat: 39.81186,
      lng: -75.36286,
      city: 'Bridgeport',
      state: 'NJ',
    },
    '08015': {
      lat: 39.92981,
      lng: -74.53966,
      city: 'Browns Mills',
      state: 'NJ',
    },
    '08016': {
      lat: 40.06926,
      lng: -74.83198,
      city: 'Burlington',
      state: 'NJ',
    },
    '08019': {
      lat: 39.76733,
      lng: -74.52781,
      city: 'Chatsworth',
      state: 'NJ',
    },
    '08020': {
      lat: 39.79674,
      lng: -75.22271,
      city: 'Clarksboro',
      state: 'NJ',
    },
    '08021': {
      lat: 39.80604,
      lng: -75.00025,
      city: 'Clementon',
      state: 'NJ',
    },
    '08022': {
      lat: 40.06516,
      lng: -74.70905,
      city: 'Columbus',
      state: 'NJ',
    },
    '08023': {
      lat: 39.68825,
      lng: -75.49761,
      city: 'Deepwater',
      state: 'NJ',
    },
    '08026': {
      lat: 39.83329,
      lng: -74.9657,
      city: 'Gibbsboro',
      state: 'NJ',
    },
    '08027': {
      lat: 39.82883,
      lng: -75.29345,
      city: 'Gibbstown',
      state: 'NJ',
    },
    '08028': {
      lat: 39.69336,
      lng: -75.11949,
      city: 'Glassboro',
      state: 'NJ',
    },
    '08029': {
      lat: 39.84097,
      lng: -75.06717,
      city: 'Glendora',
      state: 'NJ',
    },
    '08030': {
      lat: 39.89023,
      lng: -75.1178,
      city: 'Gloucester City',
      state: 'NJ',
    },
    '08031': {
      lat: 39.86655,
      lng: -75.09408,
      city: 'Bellmawr',
      state: 'NJ',
    },
    '08032': {
      lat: 39.78048,
      lng: -75.05952,
      city: 'Grenloch',
      state: 'NJ',
    },
    '08033': {
      lat: 39.89332,
      lng: -75.03723,
      city: 'Haddonfield',
      state: 'NJ',
    },
    '08034': {
      lat: 39.90593,
      lng: -74.99935,
      city: 'Cherry Hill',
      state: 'NJ',
    },
    '08035': {
      lat: 39.87914,
      lng: -75.06435,
      city: 'Haddon Heights',
      state: 'NJ',
    },
    '08036': {
      lat: 39.97669,
      lng: -74.83708,
      city: 'Hainesport',
      state: 'NJ',
    },
    '08037': {
      lat: 39.63272,
      lng: -74.77226,
      city: 'Hammonton',
      state: 'NJ',
    },
    '08038': {
      lat: 39.47391,
      lng: -75.48852,
      city: 'Hancocks Bridge',
      state: 'NJ',
    },
    '08039': {
      lat: 39.68662,
      lng: -75.27661,
      city: 'Harrisonville',
      state: 'NJ',
    },
    '08041': {
      lat: 40.03852,
      lng: -74.68263,
      city: 'Jobstown',
      state: 'NJ',
    },
    '08042': {
      lat: 40.01363,
      lng: -74.662,
      city: 'Juliustown',
      state: 'NJ',
    },
    '08043': {
      lat: 39.84502,
      lng: -74.95492,
      city: 'Voorhees',
      state: 'NJ',
    },
    '08045': {
      lat: 39.86722,
      lng: -75.02912,
      city: 'Lawnside',
      state: 'NJ',
    },
    '08046': {
      lat: 40.02787,
      lng: -74.88709,
      city: 'Willingboro',
      state: 'NJ',
    },
    '08048': {
      lat: 39.95644,
      lng: -74.80627,
      city: 'Lumberton',
      state: 'NJ',
    },
    '08049': {
      lat: 39.85463,
      lng: -75.03816,
      city: 'Magnolia',
      state: 'NJ',
    },
    '08050': {
      lat: 39.70291,
      lng: -74.24576,
      city: 'Manahawkin',
      state: 'NJ',
    },
    '08051': {
      lat: 39.78583,
      lng: -75.17903,
      city: 'Mantua',
      state: 'NJ',
    },
    '08052': {
      lat: 39.952,
      lng: -74.99502,
      city: 'Maple Shade',
      state: 'NJ',
    },
    '08053': {
      lat: 39.86051,
      lng: -74.89469,
      city: 'Marlton',
      state: 'NJ',
    },
    '08054': {
      lat: 39.94835,
      lng: -74.90472,
      city: 'Mount Laurel',
      state: 'NJ',
    },
    '08055': {
      lat: 39.86373,
      lng: -74.82249,
      city: 'Medford',
      state: 'NJ',
    },
    '08056': {
      lat: 39.78355,
      lng: -75.25155,
      city: 'Mickleton',
      state: 'NJ',
    },
    '08057': {
      lat: 39.97948,
      lng: -74.94105,
      city: 'Moorestown',
      state: 'NJ',
    },
    '08059': {
      lat: 39.88462,
      lng: -75.09309,
      city: 'Mount Ephraim',
      state: 'NJ',
    },
    '08060': {
      lat: 40.00983,
      lng: -74.79105,
      city: 'Mount Holly',
      state: 'NJ',
    },
    '08061': {
      lat: 39.80459,
      lng: -75.207,
      city: 'Mount Royal',
      state: 'NJ',
    },
    '08062': {
      lat: 39.71465,
      lng: -75.22124,
      city: 'Mullica Hill',
      state: 'NJ',
    },
    '08063': {
      lat: 39.86758,
      lng: -75.18509,
      city: 'National Park',
      state: 'NJ',
    },
    '08064': {
      lat: 39.93657,
      lng: -74.61095,
      city: 'New Lisbon',
      state: 'NJ',
    },
    '08065': {
      lat: 40.00234,
      lng: -75.03589,
      city: 'Palmyra',
      state: 'NJ',
    },
    '08066': {
      lat: 39.83475,
      lng: -75.23017,
      city: 'Paulsboro',
      state: 'NJ',
    },
    '08067': {
      lat: 39.7388,
      lng: -75.41281,
      city: 'Pedricktown',
      state: 'NJ',
    },
    '08068': {
      lat: 39.9617,
      lng: -74.667,
      city: 'Pemberton',
      state: 'NJ',
    },
    '08069': {
      lat: 39.70209,
      lng: -75.4501,
      city: 'Penns Grove',
      state: 'NJ',
    },
    '08070': {
      lat: 39.62494,
      lng: -75.50902,
      city: 'Pennsville',
      state: 'NJ',
    },
    '08071': {
      lat: 39.73227,
      lng: -75.13457,
      city: 'Pitman',
      state: 'NJ',
    },
    '08072': {
      lat: 39.54495,
      lng: -75.41258,
      city: 'Quinton',
      state: 'NJ',
    },
    '08073': {
      lat: 40.00952,
      lng: -74.86698,
      city: 'Rancocas',
      state: 'NJ',
    },
    '08074': {
      lat: 39.71289,
      lng: -75.16379,
      city: 'Richwood',
      state: 'NJ',
    },
    '08075': {
      lat: 40.02937,
      lng: -74.94999,
      city: 'Riverside',
      state: 'NJ',
    },
    '08077': {
      lat: 40.00193,
      lng: -74.99515,
      city: 'Riverton',
      state: 'NJ',
    },
    '08078': {
      lat: 39.85202,
      lng: -75.07542,
      city: 'Runnemede',
      state: 'NJ',
    },
    '08079': {
      lat: 39.54284,
      lng: -75.43184,
      city: 'Salem',
      state: 'NJ',
    },
    '08080': {
      lat: 39.75714,
      lng: -75.11801,
      city: 'Sewell',
      state: 'NJ',
    },
    '08081': {
      lat: 39.73421,
      lng: -74.97564,
      city: 'Sicklerville',
      state: 'NJ',
    },
    '08083': {
      lat: 39.84138,
      lng: -75.02867,
      city: 'Somerdale',
      state: 'NJ',
    },
    '08084': {
      lat: 39.82905,
      lng: -75.01557,
      city: 'Stratford',
      state: 'NJ',
    },
    '08085': {
      lat: 39.75579,
      lng: -75.33017,
      city: 'Swedesboro',
      state: 'NJ',
    },
    '08086': {
      lat: 39.85136,
      lng: -75.18582,
      city: 'Thorofare',
      state: 'NJ',
    },
    '08087': {
      lat: 39.60751,
      lng: -74.37994,
      city: 'Tuckerton',
      state: 'NJ',
    },
    '08088': {
      lat: 39.84196,
      lng: -74.69075,
      city: 'Vincentown',
      state: 'NJ',
    },
    '08089': {
      lat: 39.71591,
      lng: -74.82686,
      city: 'Waterford Works',
      state: 'NJ',
    },
    '08090': {
      lat: 39.79777,
      lng: -75.14849,
      city: 'Wenonah',
      state: 'NJ',
    },
    '08091': {
      lat: 39.80545,
      lng: -74.92558,
      city: 'West Berlin',
      state: 'NJ',
    },
    '08092': {
      lat: 39.65438,
      lng: -74.28428,
      city: 'West Creek',
      state: 'NJ',
    },
    '08093': {
      lat: 39.86098,
      lng: -75.13265,
      city: 'Westville',
      state: 'NJ',
    },
    '08094': {
      lat: 39.65096,
      lng: -74.96529,
      city: 'Williamstown',
      state: 'NJ',
    },
    '08095': {
      lat: 39.64514,
      lng: -74.85576,
      city: 'Winslow',
      state: 'NJ',
    },
    '08096': {
      lat: 39.82921,
      lng: -75.12871,
      city: 'Woodbury',
      state: 'NJ',
    },
    '08097': {
      lat: 39.81571,
      lng: -75.15117,
      city: 'Woodbury Heights',
      state: 'NJ',
    },
    '08098': {
      lat: 39.63934,
      lng: -75.3316,
      city: 'Woodstown',
      state: 'NJ',
    },
    '08102': {
      lat: 39.95203,
      lng: -75.12134,
      city: 'Camden',
      state: 'NJ',
    },
    '08103': {
      lat: 39.93533,
      lng: -75.11275,
      city: 'Camden',
      state: 'NJ',
    },
    '08104': {
      lat: 39.91672,
      lng: -75.11203,
      city: 'Camden',
      state: 'NJ',
    },
    '08105': {
      lat: 39.95174,
      lng: -75.09107,
      city: 'Camden',
      state: 'NJ',
    },
    '08106': {
      lat: 39.89119,
      lng: -75.07388,
      city: 'Audubon',
      state: 'NJ',
    },
    '08107': {
      lat: 39.90767,
      lng: -75.08344,
      city: 'Oaklyn',
      state: 'NJ',
    },
    '08108': {
      lat: 39.91453,
      lng: -75.06192,
      city: 'Collingswood',
      state: 'NJ',
    },
    '08109': {
      lat: 39.95006,
      lng: -75.05094,
      city: 'Merchantville',
      state: 'NJ',
    },
    '08110': {
      lat: 39.97243,
      lng: -75.06021,
      city: 'Pennsauken',
      state: 'NJ',
    },
    '08201': {
      lat: 39.42257,
      lng: -74.49511,
      city: 'Absecon',
      state: 'NJ',
    },
    '08202': {
      lat: 39.09659,
      lng: -74.74806,
      city: 'Avalon',
      state: 'NJ',
    },
    '08203': {
      lat: 39.41281,
      lng: -74.38006,
      city: 'Brigantine',
      state: 'NJ',
    },
    '08204': {
      lat: 38.97131,
      lng: -74.92054,
      city: 'Cape May',
      state: 'NJ',
    },
    '08205': {
      lat: 39.47455,
      lng: -74.45698,
      city: 'Absecon',
      state: 'NJ',
    },
    '08210': {
      lat: 39.11974,
      lng: -74.82248,
      city: 'Cape May Court House',
      state: 'NJ',
    },
    '08212': {
      lat: 38.93721,
      lng: -74.96509,
      city: 'Cape May Point',
      state: 'NJ',
    },
    '08215': {
      lat: 39.58962,
      lng: -74.58716,
      city: 'Egg Harbor City',
      state: 'NJ',
    },
    '08217': {
      lat: 39.57702,
      lng: -74.71879,
      city: 'Elwood',
      state: 'NJ',
    },
    '08221': {
      lat: 39.33879,
      lng: -74.5652,
      city: 'Linwood',
      state: 'NJ',
    },
    '08223': {
      lat: 39.27021,
      lng: -74.6571,
      city: 'Marmora',
      state: 'NJ',
    },
    '08224': {
      lat: 39.59548,
      lng: -74.46352,
      city: 'New Gretna',
      state: 'NJ',
    },
    '08225': {
      lat: 39.36167,
      lng: -74.53963,
      city: 'Northfield',
      state: 'NJ',
    },
    '08226': {
      lat: 39.26812,
      lng: -74.60196,
      city: 'Ocean City',
      state: 'NJ',
    },
    '08230': {
      lat: 39.20381,
      lng: -74.70342,
      city: 'Ocean View',
      state: 'NJ',
    },
    '08232': {
      lat: 39.38771,
      lng: -74.51489,
      city: 'Pleasantville',
      state: 'NJ',
    },
    '08234': {
      lat: 39.38662,
      lng: -74.62369,
      city: 'Egg Harbor Township',
      state: 'NJ',
    },
    '08240': {
      lat: 39.48676,
      lng: -74.53633,
      city: 'Pomona',
      state: 'NJ',
    },
    '08241': {
      lat: 39.53278,
      lng: -74.48523,
      city: 'Port Republic',
      state: 'NJ',
    },
    '08242': {
      lat: 39.01786,
      lng: -74.88627,
      city: 'Rio Grande',
      state: 'NJ',
    },
    '08243': {
      lat: 39.15235,
      lng: -74.69758,
      city: 'Sea Isle City',
      state: 'NJ',
    },
    '08244': {
      lat: 39.31503,
      lng: -74.59808,
      city: 'Somers Point',
      state: 'NJ',
    },
    '08245': {
      lat: 39.1765,
      lng: -74.81902,
      city: 'South Dennis',
      state: 'NJ',
    },
    '08246': {
      lat: 39.18098,
      lng: -74.76583,
      city: 'South Seaville',
      state: 'NJ',
    },
    '08247': {
      lat: 39.05279,
      lng: -74.78508,
      city: 'Stone Harbor',
      state: 'NJ',
    },
    '08248': {
      lat: 39.1944,
      lng: -74.66175,
      city: 'Strathmere',
      state: 'NJ',
    },
    '08251': {
      lat: 39.02766,
      lng: -74.92573,
      city: 'Villas',
      state: 'NJ',
    },
    '08260': {
      lat: 38.99555,
      lng: -74.84088,
      city: 'Wildwood',
      state: 'NJ',
    },
    '08270': {
      lat: 39.26437,
      lng: -74.80861,
      city: 'Woodbine',
      state: 'NJ',
    },
    '08302': {
      lat: 39.4397,
      lng: -75.2616,
      city: 'Bridgeton',
      state: 'NJ',
    },
    '08310': {
      lat: 39.52723,
      lng: -74.89769,
      city: 'Buena',
      state: 'NJ',
    },
    '08311': {
      lat: 39.32685,
      lng: -75.20804,
      city: 'Cedarville',
      state: 'NJ',
    },
    '08312': {
      lat: 39.66143,
      lng: -75.08375,
      city: 'Clayton',
      state: 'NJ',
    },
    '08314': {
      lat: 39.21683,
      lng: -74.94113,
      city: 'Delmont',
      state: 'NJ',
    },
    '08316': {
      lat: 39.28009,
      lng: -74.93186,
      city: 'Dorchester',
      state: 'NJ',
    },
    '08317': {
      lat: 39.40398,
      lng: -74.82696,
      city: 'Dorothy',
      state: 'NJ',
    },
    '08318': {
      lat: 39.55657,
      lng: -75.17776,
      city: 'Elmer',
      state: 'NJ',
    },
    '08319': {
      lat: 39.3645,
      lng: -74.81753,
      city: 'Estell Manor',
      state: 'NJ',
    },
    '08320': {
      lat: 39.3851,
      lng: -75.16059,
      city: 'Fairton',
      state: 'NJ',
    },
    '08321': {
      lat: 39.21961,
      lng: -75.14107,
      city: 'Fortescue',
      state: 'NJ',
    },
    '08322': {
      lat: 39.61463,
      lng: -75.03385,
      city: 'Franklinville',
      state: 'NJ',
    },
    '08323': {
      lat: 39.39557,
      lng: -75.36605,
      city: 'Greenwich',
      state: 'NJ',
    },
    '08324': {
      lat: 39.21859,
      lng: -74.99999,
      city: 'Heislerville',
      state: 'NJ',
    },
    '08326': {
      lat: 39.53847,
      lng: -74.92761,
      city: 'Landisville',
      state: 'NJ',
    },
    '08327': {
      lat: 39.25458,
      lng: -74.94657,
      city: 'Leesburg',
      state: 'NJ',
    },
    '08328': {
      lat: 39.5797,
      lng: -75.06015,
      city: 'Malaga',
      state: 'NJ',
    },
    '08329': {
      lat: 39.2857,
      lng: -75.00338,
      city: 'Mauricetown',
      state: 'NJ',
    },
    '08330': {
      lat: 39.4566,
      lng: -74.73814,
      city: 'Mays Landing',
      state: 'NJ',
    },
    '08332': {
      lat: 39.3705,
      lng: -75.0293,
      city: 'Millville',
      state: 'NJ',
    },
    '08340': {
      lat: 39.40087,
      lng: -74.88407,
      city: 'Milmay',
      state: 'NJ',
    },
    '08341': {
      lat: 39.52002,
      lng: -74.9524,
      city: 'Minotola',
      state: 'NJ',
    },
    '08343': {
      lat: 39.64057,
      lng: -75.1699,
      city: 'Monroeville',
      state: 'NJ',
    },
    '08344': {
      lat: 39.56707,
      lng: -74.99185,
      city: 'Newfield',
      state: 'NJ',
    },
    '08345': {
      lat: 39.28816,
      lng: -75.14668,
      city: 'Newport',
      state: 'NJ',
    },
    '08346': {
      lat: 39.56926,
      lng: -74.85827,
      city: 'Newtonville',
      state: 'NJ',
    },
    '08348': {
      lat: 39.31381,
      lng: -74.96594,
      city: 'Port Elizabeth',
      state: 'NJ',
    },
    '08349': {
      lat: 39.266,
      lng: -75.06337,
      city: 'Port Norris',
      state: 'NJ',
    },
    '08350': {
      lat: 39.49134,
      lng: -74.87413,
      city: 'Richland',
      state: 'NJ',
    },
    '08352': {
      lat: 39.47545,
      lng: -75.12724,
      city: 'Rosenhayn',
      state: 'NJ',
    },
    '08353': {
      lat: 39.45954,
      lng: -75.29695,
      city: 'Shiloh',
      state: 'NJ',
    },
    '08360': {
      lat: 39.49374,
      lng: -75.00352,
      city: 'Vineland',
      state: 'NJ',
    },
    '08361': {
      lat: 39.44843,
      lng: -74.95943,
      city: 'Vineland',
      state: 'NJ',
    },
    '08401': {
      lat: 39.37964,
      lng: -74.45284,
      city: 'Atlantic City',
      state: 'NJ',
    },
    '08402': {
      lat: 39.33069,
      lng: -74.50706,
      city: 'Margate City',
      state: 'NJ',
    },
    '08403': {
      lat: 39.31915,
      lng: -74.53535,
      city: 'Longport',
      state: 'NJ',
    },
    '08406': {
      lat: 39.34565,
      lng: -74.48603,
      city: 'Ventnor City',
      state: 'NJ',
    },
    '08501': {
      lat: 40.16022,
      lng: -74.55237,
      city: 'Allentown',
      state: 'NJ',
    },
    '08502': {
      lat: 40.44815,
      lng: -74.65763,
      city: 'Belle Mead',
      state: 'NJ',
    },
    '08505': {
      lat: 40.10413,
      lng: -74.73959,
      city: 'Bordentown',
      state: 'NJ',
    },
    '08510': {
      lat: 40.18798,
      lng: -74.42351,
      city: 'Millstone Township',
      state: 'NJ',
    },
    '08511': {
      lat: 40.04441,
      lng: -74.55384,
      city: 'Cookstown',
      state: 'NJ',
    },
    '08512': {
      lat: 40.32166,
      lng: -74.52586,
      city: 'Cranbury',
      state: 'NJ',
    },
    '08514': {
      lat: 40.13269,
      lng: -74.49811,
      city: 'Cream Ridge',
      state: 'NJ',
    },
    '08515': {
      lat: 40.1163,
      lng: -74.64568,
      city: 'Chesterfield',
      state: 'NJ',
    },
    '08518': {
      lat: 40.11541,
      lng: -74.80357,
      city: 'Florence',
      state: 'NJ',
    },
    '08520': {
      lat: 40.25555,
      lng: -74.53269,
      city: 'Hightstown',
      state: 'NJ',
    },
    '08525': {
      lat: 40.39641,
      lng: -74.78185,
      city: 'Hopewell',
      state: 'NJ',
    },
    '08527': {
      lat: 40.10478,
      lng: -74.35035,
      city: 'Jackson',
      state: 'NJ',
    },
    '08528': {
      lat: 40.38791,
      lng: -74.61744,
      city: 'Kingston',
      state: 'NJ',
    },
    '08530': {
      lat: 40.37288,
      lng: -74.89747,
      city: 'Lambertville',
      state: 'NJ',
    },
    '08533': {
      lat: 40.01293,
      lng: -74.47138,
      city: 'New Egypt',
      state: 'NJ',
    },
    '08534': {
      lat: 40.33026,
      lng: -74.79422,
      city: 'Pennington',
      state: 'NJ',
    },
    '08535': {
      lat: 40.23506,
      lng: -74.437,
      city: 'Millstone Township',
      state: 'NJ',
    },
    '08536': {
      lat: 40.33392,
      lng: -74.58488,
      city: 'Plainsboro',
      state: 'NJ',
    },
    '08540': {
      lat: 40.36423,
      lng: -74.65661,
      city: 'Princeton',
      state: 'NJ',
    },
    '08542': {
      lat: 40.35452,
      lng: -74.66064,
      city: 'Princeton',
      state: 'NJ',
    },
    '08544': {
      lat: 40.34641,
      lng: -74.65787,
      city: 'Princeton',
      state: 'NJ',
    },
    '08550': {
      lat: 40.28125,
      lng: -74.61926,
      city: 'Princeton Junction',
      state: 'NJ',
    },
    '08551': {
      lat: 40.44425,
      lng: -74.83907,
      city: 'Ringoes',
      state: 'NJ',
    },
    '08553': {
      lat: 40.40011,
      lng: -74.64051,
      city: 'Rocky Hill',
      state: 'NJ',
    },
    '08554': {
      lat: 40.11556,
      lng: -74.77966,
      city: 'Roebling',
      state: 'NJ',
    },
    '08555': {
      lat: 40.22116,
      lng: -74.473,
      city: 'Roosevelt',
      state: 'NJ',
    },
    '08558': {
      lat: 40.41382,
      lng: -74.70236,
      city: 'Skillman',
      state: 'NJ',
    },
    '08559': {
      lat: 40.4387,
      lng: -74.9716,
      city: 'Stockton',
      state: 'NJ',
    },
    '08560': {
      lat: 40.31453,
      lng: -74.85946,
      city: 'Titusville',
      state: 'NJ',
    },
    '08561': {
      lat: 40.24659,
      lng: -74.58138,
      city: 'Windsor',
      state: 'NJ',
    },
    '08562': {
      lat: 40.06812,
      lng: -74.59401,
      city: 'Wrightstown',
      state: 'NJ',
    },
    '08608': {
      lat: 40.21947,
      lng: -74.76693,
      city: 'Trenton',
      state: 'NJ',
    },
    '08609': {
      lat: 40.22594,
      lng: -74.74109,
      city: 'Trenton',
      state: 'NJ',
    },
    '08610': {
      lat: 40.19377,
      lng: -74.71565,
      city: 'Trenton',
      state: 'NJ',
    },
    '08611': {
      lat: 40.19107,
      lng: -74.74299,
      city: 'Trenton',
      state: 'NJ',
    },
    '08618': {
      lat: 40.24878,
      lng: -74.78734,
      city: 'Trenton',
      state: 'NJ',
    },
    '08619': {
      lat: 40.2414,
      lng: -74.69669,
      city: 'Trenton',
      state: 'NJ',
    },
    '08620': {
      lat: 40.16825,
      lng: -74.64956,
      city: 'Trenton',
      state: 'NJ',
    },
    '08628': {
      lat: 40.26705,
      lng: -74.82235,
      city: 'Trenton',
      state: 'NJ',
    },
    '08629': {
      lat: 40.22066,
      lng: -74.73127,
      city: 'Trenton',
      state: 'NJ',
    },
    '08638': {
      lat: 40.25531,
      lng: -74.76222,
      city: 'Trenton',
      state: 'NJ',
    },
    '08640': {
      lat: 40.0093,
      lng: -74.58424,
      city: 'Joint Base Mdl',
      state: 'NJ',
    },
    '08641': {
      lat: 40.02819,
      lng: -74.58715,
      city: 'Joint Base Mdl',
      state: 'NJ',
    },
    '08648': {
      lat: 40.28488,
      lng: -74.72201,
      city: 'Lawrence Township',
      state: 'NJ',
    },
    '08690': {
      lat: 40.22512,
      lng: -74.66081,
      city: 'Trenton',
      state: 'NJ',
    },
    '08691': {
      lat: 40.20953,
      lng: -74.59919,
      city: 'Robbinsville',
      state: 'NJ',
    },
    '08701': {
      lat: 40.07635,
      lng: -74.20311,
      city: 'Lakewood',
      state: 'NJ',
    },
    '08720': {
      lat: 40.14073,
      lng: -74.11431,
      city: 'Allenwood',
      state: 'NJ',
    },
    '08721': {
      lat: 39.9071,
      lng: -74.1846,
      city: 'Bayville',
      state: 'NJ',
    },
    '08722': {
      lat: 39.92863,
      lng: -74.20224,
      city: 'Beachwood',
      state: 'NJ',
    },
    '08723': {
      lat: 40.03896,
      lng: -74.11091,
      city: 'Brick',
      state: 'NJ',
    },
    '08724': { lat: 40.09034, lng: -74.115, city: 'Brick', state: 'NJ' },
    '08730': {
      lat: 40.10483,
      lng: -74.06366,
      city: 'Brielle',
      state: 'NJ',
    },
    '08731': {
      lat: 39.85946,
      lng: -74.29363,
      city: 'Forked River',
      state: 'NJ',
    },
    '08732': {
      lat: 39.94177,
      lng: -74.14497,
      city: 'Island Heights',
      state: 'NJ',
    },
    '08733': {
      lat: 40.0262,
      lng: -74.3713,
      city: 'Lakehurst',
      state: 'NJ',
    },
    '08734': {
      lat: 39.86331,
      lng: -74.16608,
      city: 'Lanoka Harbor',
      state: 'NJ',
    },
    '08735': {
      lat: 39.98288,
      lng: -74.08121,
      city: 'Lavallette',
      state: 'NJ',
    },
    '08736': {
      lat: 40.12028,
      lng: -74.06862,
      city: 'Manasquan',
      state: 'NJ',
    },
    '08738': {
      lat: 40.02101,
      lng: -74.06282,
      city: 'Mantoloking',
      state: 'NJ',
    },
    '08740': {
      lat: 39.93006,
      lng: -74.12811,
      city: 'Ocean Gate',
      state: 'NJ',
    },
    '08741': {
      lat: 39.93632,
      lng: -74.16779,
      city: 'Pine Beach',
      state: 'NJ',
    },
    '08742': {
      lat: 40.08076,
      lng: -74.06213,
      city: 'Point Pleasant Beach',
      state: 'NJ',
    },
    '08750': {
      lat: 40.13389,
      lng: -74.0444,
      city: 'Sea Girt',
      state: 'NJ',
    },
    '08751': {
      lat: 39.95012,
      lng: -74.08147,
      city: 'Seaside Heights',
      state: 'NJ',
    },
    '08752': {
      lat: 39.85233,
      lng: -74.0963,
      city: 'Seaside Park',
      state: 'NJ',
    },
    '08753': {
      lat: 39.98091,
      lng: -74.15055,
      city: 'Toms River',
      state: 'NJ',
    },
    '08755': {
      lat: 40.00968,
      lng: -74.22457,
      city: 'Toms River',
      state: 'NJ',
    },
    '08757': {
      lat: 39.96061,
      lng: -74.25884,
      city: 'Toms River',
      state: 'NJ',
    },
    '08758': {
      lat: 39.79529,
      lng: -74.25296,
      city: 'Waretown',
      state: 'NJ',
    },
    '08759': {
      lat: 39.96239,
      lng: -74.35902,
      city: 'Manchester Township',
      state: 'NJ',
    },
    '08801': {
      lat: 40.62774,
      lng: -74.88655,
      city: 'Annandale',
      state: 'NJ',
    },
    '08802': {
      lat: 40.6783,
      lng: -75.02515,
      city: 'Asbury',
      state: 'NJ',
    },
    '08804': {
      lat: 40.64412,
      lng: -75.09936,
      city: 'Bloomsbury',
      state: 'NJ',
    },
    '08805': {
      lat: 40.57165,
      lng: -74.53783,
      city: 'Bound Brook',
      state: 'NJ',
    },
    '08807': {
      lat: 40.59241,
      lng: -74.6202,
      city: 'Bridgewater',
      state: 'NJ',
    },
    '08809': {
      lat: 40.64973,
      lng: -74.92503,
      city: 'Clinton',
      state: 'NJ',
    },
    '08810': {
      lat: 40.37073,
      lng: -74.49641,
      city: 'Dayton',
      state: 'NJ',
    },
    '08812': {
      lat: 40.5999,
      lng: -74.47979,
      city: 'Dunellen',
      state: 'NJ',
    },
    '08816': {
      lat: 40.42943,
      lng: -74.41602,
      city: 'East Brunswick',
      state: 'NJ',
    },
    '08817': {
      lat: 40.51559,
      lng: -74.3939,
      city: 'Edison',
      state: 'NJ',
    },
    '08820': {
      lat: 40.57725,
      lng: -74.36599,
      city: 'Edison',
      state: 'NJ',
    },
    '08821': {
      lat: 40.52074,
      lng: -74.68835,
      city: 'Flagtown',
      state: 'NJ',
    },
    '08822': {
      lat: 40.52281,
      lng: -74.86576,
      city: 'Flemington',
      state: 'NJ',
    },
    '08823': {
      lat: 40.44218,
      lng: -74.56318,
      city: 'Franklin Park',
      state: 'NJ',
    },
    '08824': {
      lat: 40.42058,
      lng: -74.5538,
      city: 'Kendall Park',
      state: 'NJ',
    },
    '08825': {
      lat: 40.51353,
      lng: -75.02341,
      city: 'Frenchtown',
      state: 'NJ',
    },
    '08826': {
      lat: 40.71912,
      lng: -74.90527,
      city: 'Glen Gardner',
      state: 'NJ',
    },
    '08827': {
      lat: 40.66775,
      lng: -74.97351,
      city: 'Hampton',
      state: 'NJ',
    },
    '08828': {
      lat: 40.37767,
      lng: -74.42383,
      city: 'Helmetta',
      state: 'NJ',
    },
    '08829': {
      lat: 40.66828,
      lng: -74.89414,
      city: 'High Bridge',
      state: 'NJ',
    },
    '08830': {
      lat: 40.56964,
      lng: -74.31719,
      city: 'Iselin',
      state: 'NJ',
    },
    '08831': {
      lat: 40.32908,
      lng: -74.43066,
      city: 'Monroe Township',
      state: 'NJ',
    },
    '08832': {
      lat: 40.51138,
      lng: -74.31387,
      city: 'Keasbey',
      state: 'NJ',
    },
    '08833': {
      lat: 40.645,
      lng: -74.81989,
      city: 'Lebanon',
      state: 'NJ',
    },
    '08835': {
      lat: 40.54147,
      lng: -74.58876,
      city: 'Manville',
      state: 'NJ',
    },
    '08836': {
      lat: 40.59705,
      lng: -74.55446,
      city: 'Martinsville',
      state: 'NJ',
    },
    '08837': {
      lat: 40.51432,
      lng: -74.34477,
      city: 'Edison',
      state: 'NJ',
    },
    '08840': {
      lat: 40.54361,
      lng: -74.35856,
      city: 'Metuchen',
      state: 'NJ',
    },
    '08844': {
      lat: 40.49858,
      lng: -74.67274,
      city: 'Hillsborough',
      state: 'NJ',
    },
    '08846': {
      lat: 40.57439,
      lng: -74.5011,
      city: 'Middlesex',
      state: 'NJ',
    },
    '08848': {
      lat: 40.59386,
      lng: -75.09569,
      city: 'Milford',
      state: 'NJ',
    },
    '08850': {
      lat: 40.44826,
      lng: -74.43994,
      city: 'Milltown',
      state: 'NJ',
    },
    '08852': {
      lat: 40.38978,
      lng: -74.54461,
      city: 'Monmouth Junction',
      state: 'NJ',
    },
    '08853': {
      lat: 40.52879,
      lng: -74.74246,
      city: 'Neshanic Station',
      state: 'NJ',
    },
    '08854': {
      lat: 40.54648,
      lng: -74.46342,
      city: 'Piscataway',
      state: 'NJ',
    },
    '08857': {
      lat: 40.39188,
      lng: -74.33059,
      city: 'Old Bridge',
      state: 'NJ',
    },
    '08858': {
      lat: 40.67903,
      lng: -74.73748,
      city: 'Oldwick',
      state: 'NJ',
    },
    '08859': {
      lat: 40.45897,
      lng: -74.30401,
      city: 'Parlin',
      state: 'NJ',
    },
    '08861': {
      lat: 40.52087,
      lng: -74.27397,
      city: 'Perth Amboy',
      state: 'NJ',
    },
    '08863': {
      lat: 40.53733,
      lng: -74.31216,
      city: 'Fords',
      state: 'NJ',
    },
    '08865': {
      lat: 40.7094,
      lng: -75.15109,
      city: 'Phillipsburg',
      state: 'NJ',
    },
    '08867': {
      lat: 40.57513,
      lng: -74.96777,
      city: 'Pittstown',
      state: 'NJ',
    },
    '08869': {
      lat: 40.57316,
      lng: -74.64305,
      city: 'Raritan',
      state: 'NJ',
    },
    '08872': {
      lat: 40.46482,
      lng: -74.34002,
      city: 'Sayreville',
      state: 'NJ',
    },
    '08873': {
      lat: 40.49865,
      lng: -74.53284,
      city: 'Somerset',
      state: 'NJ',
    },
    '08876': {
      lat: 40.58925,
      lng: -74.68871,
      city: 'Somerville',
      state: 'NJ',
    },
    '08879': {
      lat: 40.46532,
      lng: -74.27691,
      city: 'South Amboy',
      state: 'NJ',
    },
    '08880': {
      lat: 40.55354,
      lng: -74.52771,
      city: 'South Bound Brook',
      state: 'NJ',
    },
    '08882': {
      lat: 40.44556,
      lng: -74.37842,
      city: 'South River',
      state: 'NJ',
    },
    '08884': {
      lat: 40.39493,
      lng: -74.39196,
      city: 'Spotswood',
      state: 'NJ',
    },
    '08886': {
      lat: 40.69302,
      lng: -75.10565,
      city: 'Stewartsville',
      state: 'NJ',
    },
    '08887': {
      lat: 40.52219,
      lng: -74.79428,
      city: 'Three Bridges',
      state: 'NJ',
    },
    '08889': {
      lat: 40.60744,
      lng: -74.75936,
      city: 'Whitehouse Station',
      state: 'NJ',
    },
    '08890': {
      lat: 40.53877,
      lng: -74.57531,
      city: 'Zarephath',
      state: 'NJ',
    },
    '08901': {
      lat: 40.48426,
      lng: -74.44275,
      city: 'New Brunswick',
      state: 'NJ',
    },
    '08902': {
      lat: 40.44236,
      lng: -74.48579,
      city: 'North Brunswick',
      state: 'NJ',
    },
    '08904': {
      lat: 40.50057,
      lng: -74.42832,
      city: 'Highland Park',
      state: 'NJ',
    },
  },
};
