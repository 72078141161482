import { Link } from 'react-router-dom';
import './DetailsBlock.scss';

export default function DetailsBlock({ children, header, edit, onClick }) {
  return (
    <div className='details-block'>
      <div className='header-wrapper'>
        <h6>{header}</h6>
        <div className={'icon-button-wrapper' + (edit ? ' edit' : '')}>
          <Link to='#' onClick={onClick}>
            Edit
          </Link>
        </div>
      </div>
      <div className='info-wrapper'>{children}</div>
    </div>
  );
}
