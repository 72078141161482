import { useState } from 'react';
import Button from './Button';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import { adminState, getDisplayState } from '../utils/states';
import CancelConfirmation from './CancelConfirmation';
import AddVideo from './AddVideo';
import IconVideo from '../assets/images/video.png';
import './Pending.scss';

export default function Pending({
  status,
  onNext,
  orderId,
  state,
  assigns,
  onCancel,
  onAddVideo,
  awaitFlag,
  formik,
  uploadingProgress,
}) {
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [showAddVideo, setShowAddVideo] = useState(false);

  const clappingHands = state === adminState.new;

  return (
    <OrderFormTemplate1Btn
      header={clappingHands ? 'Well Done!' : 'Your Quote Status'}
      btnText='SEE DETAILS'
      onClick={onNext}
      status={status}
      btnBackground='var(--primary-color)'
      btnColor='whitesmoke'
      small
      spinner={awaitFlag}
    >
      <div className='pending-wrapper'>
        {clappingHands && (
          <div className='clapping-hands-block'>
            <div className='clap-icon' />
            <label>
              You have completed the necessary steps to obtain
              the&nbsp;most&nbsp;competitive&nbsp;quote
            </label>
          </div>
        )}
        {!clappingHands && <h3>{getDisplayState(state, assigns)}</h3>}
        <label>{`Tracking ID: ${orderId}`}</label>
        {state !== adminState.canceled && (
          <>
            <Button
              icon={IconVideo}
              small
              background='var(--primary-color)'
              color='whitesmoke'
              onClick={() => setShowAddVideo(true)}
            >
              ADD VIDEO
            </Button>
            <Button
              color='red'
              background='transparent'
              nohover
              small
              onClick={() => setShowCancelConfirmation(true)}
            >
              CANCEL
            </Button>
          </>
        )}
      </div>
      <AddVideo
        show={showAddVideo}
        onClose={() => setShowAddVideo(false)}
        onAddVideo={onAddVideo}
        formik={formik}
        uploadingProgress={uploadingProgress}
      />
      <CancelConfirmation
        show={showCancelConfirmation}
        onCancel={onCancel}
        onClose={() => setShowCancelConfirmation(false)}
      />
    </OrderFormTemplate1Btn>
  );
}
