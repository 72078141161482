import './Button.scss';

export default function Button({
  children,
  background = 'var(--primary-color)',
  color = 'whitesmoke',
  nohover,
  small,
  onClick,
  className,
  type = 'button',
  icon,
  disabled,
  form,
}) {
  return (
    <button
      className={'button' + (className ? ' ' + className : '')}
      style={{
        backgroundColor: disabled ? 'gray' : background,
        color,
        outline: nohover ? 'none' : undefined,
        padding: small ? '8px 16px' : undefined,
        borderRadius: small ? '4px' : undefined,
      }}
      onClick={onClick}
      type={type}
      disabled={disabled}
      form={form}
    >
      <div className='content-wrapper'>
        {icon && (
          <div className='icon' style={{ backgroundImage: `url(${icon})` }} />
        )}
        {children && <div className='children-wrapper'>{children}</div>}
      </div>
    </button>
  );
}
