import { useState } from 'react';
import './ChatMessage.scss';

export default function ChatMessage({ message }) {
  const { isUser, text, moment } = message;
  const [showTime, setShowTime] = useState(false);

  return (
    <div
      className={'chat-message ' + (isUser ? 'user' : 'not-user')}
      onMouseEnter={() => setShowTime(true)}
      onMouseLeave={() => setShowTime(false)}
    >
      <div className='message-bubble'>
        <p>{text}</p>
      </div>
      <div className={'time' + (showTime ? ' show' : '')}>
        <label>{moment.format('h:mma').slice(0, -1)}</label>
      </div>
    </div>
  );
}
