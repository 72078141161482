import { useState, useEffect, useRef } from 'react';
import Button from './Button';
import SendIcon from '../assets/images/sendmsg.png';
import './ChatInput.scss';

export default function ChatInput({ onSend, show }) {
  const [message, setMessage] = useState('');
  const inputRef = useRef(null);

  useEffect(() => {
    if (show && inputRef.current)
      setTimeout(() => inputRef.current.focus(), 500);
  }, [show]);

  const onClickSend = () => {
    onSend(message);
    setMessage('');
    inputRef.current.focus();
  };

  const onChangeInput = (e) => setMessage(e.target.value);

  const onKeyInput = (e) => {
    if (e.key === 'Enter') onClickSend();
  };

  return (
    <div className='chat-input'>
      <input
        value={message}
        onChange={onChangeInput}
        onKeyDown={onKeyInput}
        ref={inputRef}
        placeholder='Chat with admin ...'
      />
      <Button
        className='send-button'
        icon={SendIcon}
        nohover
        background='transparent'
        onClick={onClickSend}
      />
    </div>
  );
}
