import Spinner from './Spinner';
import Button from './Button';
import './OrderFormTemplate1Btn.scss';

export default function OrderFormTemplate1Btn({
  children,
  header,
  btnText,
  btnBackground,
  btnColor,
  btnNoshadow,
  btnType,
  onClick,
  status,
  small,
  underBtnEl,
  noMarginTop,
  spinner,
  disabled,
  form,
}) {
  return (
    <div
      className={
        'order-form-1-btn ' +
        (status ?? '') +
        (noMarginTop ? ' no-margin-top' : '')
      }
    >
      <Spinner show={spinner} />
      <h4 className='header'>{header}</h4>
      <div className='children-wrapper'>{children}</div>
      {btnText && (
        <Button
          small={small}
          onClick={onClick}
          type={btnType}
          background={btnBackground}
          color={btnColor}
          nohover={btnNoshadow}
          disabled={disabled}
          form={form}
        >
          {btnText}
        </Button>
      )}
      {underBtnEl && <div className='under-btn-el-wrapper'>{underBtnEl}</div>}
    </div>
  );
}
