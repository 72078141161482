import { useState } from 'react';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import Details from './Details';
import { Link } from 'react-router-dom';
import CheckBox from './CheckBox';
import TermsConditions from '../assets/docs/terms-of-service.pdf';
import './Review.scss';

export default function Review({
  status,
  onNext,
  setStep,
  formik,
  videosUrls,
}) {
  const [showSpinner, setShowSpinner] = useState(false);
  const [submitErr, setSubmitErr] = useState(undefined);
  const [agreeTC, setAgreeTC] = useState('false');

  const handleOnClick = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    setSubmitErr(await onNext());
    setShowSpinner(false);
  };

  return (
    <OrderFormTemplate1Btn
      header='Review Details'
      btnText='SUBMIT'
      onClick={handleOnClick}
      btnType='submit'
      status={status}
      spinner={showSpinner}
      disabled={agreeTC === 'false'}
    >
      <Details
        edit
        setStep={(step) => {
          setSubmitErr(undefined);
          setStep(step);
        }}
        formik={formik}
        videosUrls={videosUrls}
        error={submitErr}
      />
      <div className='tc-checkbox-wrap'>
        <CheckBox
          label='I agree with'
          checked={agreeTC === 'true'}
          value={agreeTC}
          onChange={() =>
            setAgreeTC((curAgreeTC) =>
              curAgreeTC === 'false' ? 'true' : 'false'
            )
          }
        />
        <Link to={TermsConditions} target='_blank'>
          Terms & Conditions
        </Link>
      </div>
    </OrderFormTemplate1Btn>
  );
}
