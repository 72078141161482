import { useState, useEffect } from 'react';
import {
  getAdditionalUserInfo,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth';
import { useSignInWithGoogle } from 'react-firebase-hooks/auth';
import { useHttpsCallable } from 'react-firebase-hooks/functions';
import Button from './Button';
import GoogleLogo from '../assets/images/google-logo.png';
import EmailIcon from '../assets/images/email.png';
import GetEmailPopup from './GetEmailPopup';
import InfoPopup from './InfoPopup';
import { useNavigate } from 'react-router-dom';
import { auth, functions } from '../utils/firebase';
import './SignInPanel.scss';

export default function SignInPanel({ navigatePath }) {
  const navigate = useNavigate();
  const [showGetEmail, setShowGetEmail] = useState(false);
  const [getEmailHeader, setGetEmailHeader] = useState('');
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [infoPopupHeader, setInfoPopupHeader] = useState('');
  const [infoPopupMsg, setInfoPopupMsg] = useState('');
  const [spinner, setSpinner] = useState(false);
  const [signInWithGoogle] = useSignInWithGoogle(auth);
  const [userInit] = useHttpsCallable(functions, 'user-init');
  const [onLoginEmailClickFunc, setOnLoginEmailClickFunc] = useState(() => {});
  const [onLoginEmailCloseFunc, setOnLoginEmailCloseFunc] = useState(() => {});

  // Send auth link to email
  const onSendEmailLink = async ({ email }) => {
    if (!email) return;

    try {
      setInfoPopupHeader('Sending auth link...');
      setInfoPopupMsg('Please wait for a while');
      setShowInfoPopup(true);
      setSpinner(true);
      await sendSignInLinkToEmail(auth, email, {
        url: window.location.href,
        handleCodeInApp: true,
      });
      window.localStorage.setItem('emailForSignIn', email);
      setInfoPopupHeader('Auth link sent');
      setInfoPopupMsg('Please check the mailbox for auth link');
      setSpinner(false);
    } catch (error) {
      console.error(error.message);
      setSpinner(false);
    }
  };

  // New user's initialization
  const initNewUser = async (userCred) => {
    if (!userCred) return;

    const { isNewUser } = getAdditionalUserInfo(userCred);
    if (!isNewUser) return;

    const { displayName: name, email } = userCred.user;
    const fcmToken = undefined;
    const deviceInfo = undefined;

    await userInit({
      name: name ?? '',
      email,
      fcmToken,
      deviceInfo,
    });
  };

  // Navigation on auth
  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {
      if (user) navigate(navigatePath);
    });

    return () => unsub();
  }, [navigatePath]);

  // Getting email as a Promise via LoginEmailLink component
  const getEmail = (header) => {
    return new Promise((res, rej) => {
      setOnLoginEmailClickFunc(() => (args) => {
        res(args);
      });
      setOnLoginEmailCloseFunc(() => () => {
        setShowGetEmail(false);
        res({});
      });
      setGetEmailHeader(header);
      setShowGetEmail(true);
    });
  };

  // Actions when we have sign in via email link case
  useEffect(() => {
    const signInWithEmailLinkWrap = async () => {
      if (!isSignInWithEmailLink(auth, window.location.href)) return;

      try {
        let email = window.localStorage.getItem('emailForSignIn');
        if (!email) email = (await getEmail('Confirm your email')).email;

        setInfoPopupHeader('Checking auth link...');
        setInfoPopupMsg('Please wait for a while');
        setShowInfoPopup(true);
        setSpinner(true);

        initNewUser(
          await signInWithEmailLink(auth, email, window.location.href)
        );
        window.localStorage.removeItem('emailForSignIn');
        setShowInfoPopup(false);
      } catch (error) {
        setInfoPopupHeader('Auth error');
        setInfoPopupMsg(error.code.split('/')[1].replace(/-/g, ' '));
        setSpinner(false);
        setShowInfoPopup(true);
      }
    };

    signInWithEmailLinkWrap();
  }, []);

  return (
    <div className='signin-panel'>
      <Button
        text='Login with Google'
        icon={GoogleLogo}
        background='whitesmoke'
        color='black'
        onClick={async () => initNewUser(await signInWithGoogle())}
      >
        <p className='btn-text'>Login with Google</p>
      </Button>
      <Button
        color='black'
        background='whitesmoke'
        onClick={async () =>
          onSendEmailLink(await getEmail('Provide your email'))
        }
        icon={EmailIcon}
      >
        <p className='btn-text'>Login with Email</p>
      </Button>
      <GetEmailPopup
        show={showGetEmail}
        onClose={onLoginEmailCloseFunc}
        onClick={onLoginEmailClickFunc}
        header={getEmailHeader}
      />
      <InfoPopup
        show={showInfoPopup}
        header={infoPopupHeader}
        message={infoPopupMsg}
        spinner={spinner}
        onClose={() => setShowInfoPopup(false)}
      />
    </div>
  );
}
