import './LabelSelect.scss';

export default function LabelSelect({
  children,
  label,
  value,
  onChange,
  onBlur,
  name,
  error,
  disabled,
  className,
}) {
  return (
    <div className={'label-select' + (className ? ' ' + className : '')}>
      <label className={error ? 'error' : ''}>
        {error ? label + ': ' + error : label}
      </label>
      <select
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        name={name}
        disabled={disabled}
      >
        {children}
      </select>
    </div>
  );
}
