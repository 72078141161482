import { useState } from 'react';
import OrderFormTemplate from './OrderFormTemplate';
import OrderForm45Content from './OrderForm45Content';
import Spinner from './Spinner';

export default function OrderForm4({
  onNext,
  onBack,
  status,
  formik,
  addressValidate,
}) {
  const [awaitFlag, setAwaitFlag] = useState(false);

  const onClickNextHandler = async () => {
    setAwaitFlag(true);
    await addressValidate(
      `${formik.values.addressFrom}, ${formik.values.cityFrom},  ${formik.values.stateFrom}, ${formik.values.countryFrom}, ${formik.values.zipFrom}`
    );
    setAwaitFlag(false);

    formik.setTouched({
      ...formik.touched,
      zipFrom: true,
      countryFrom: true,
      addressFrom: true,
      suiteFrom: true,
      cityFrom: true,
      stateFrom: true,
      bedroomsFrom: true,
      parkingFrom: true,
    });

    const errors = await formik.validateForm();
    if (
      !errors.zipFrom &&
      !errors.countryFrom &&
      !errors.addressFrom &&
      !errors.suiteFrom &&
      !errors.cityFrom &&
      !errors.stateFrom &&
      !errors.bedroomsFrom &&
      !errors.parkingFrom
    )
      onNext();
  };

  return (
    <OrderFormTemplate
      header='Pick Up Address'
      status={status}
      onNext={onClickNextHandler}
      onBack={onBack}
      disabled={
        formik.errors.zipFrom ||
        !formik.values.zipFrom ||
        formik.errors.addressFrom ||
        !formik.values.addressFrom ||
        formik.errors.cityFrom ||
        !formik.values.cityFrom ||
        formik.errors.bedroomsFrom ||
        !formik.values.bedroomsFrom ||
        formik.errors.parkingFrom ||
        !formik.values.parkingFrom
      }
    >
      <OrderForm45Content
        formik={formik}
        zip='zipFrom'
        country='countryFrom'
        address='addressFrom'
        suite='suiteFrom'
        state='stateFrom'
        city='cityFrom'
        bedrooms='bedroomsFrom'
        parking='parkingFrom'
      />
      <Spinner show={awaitFlag} />
    </OrderFormTemplate>
  );
}
