import './FilesPreview.scss';

export default function FilesPreview({ videosUrls }) {
  return (
    <div className='preview-wrapper'>
      {videosUrls &&
        videosUrls.map((media, index) => {
          if (media.type === 'image')
            return (
              <div
                key={index}
                className='preview'
                style={{ backgroundImage: `url(${media.url})` }}
              />
            );
          if (media.type === 'video')
            return (
              <video
                src={media.url}
                key={index}
                autoPlay
                muted='true'
                playsInline
              />
            );
          return <div key={index}></div>;
        })}
    </div>
  );
}
