import Button from './Button';
import ArrowIcon from '../assets/images/arrow_left_icon.png';
import './BackBtn.scss';

export default function BackBtn({ onClick, invisible, text }) {
  return (
    <Button
      onClick={onClick}
      icon={ArrowIcon}
      background='transparent'
      nohover
      className={'back-btn' + (invisible ? ' invisible' : '')}
    >
      {text}
    </Button>
  );
}
