import { useState } from 'react';
import OrderFormTemplate from './OrderFormTemplate';
import OrderForm45Content from './OrderForm45Content';
import Spinner from './Spinner';

export default function OrderForm5({
  onNext,
  onBack,
  status,
  formik,
  addressValidate,
}) {
  const [awaitFlag, setAwaitFlag] = useState(false);

  const onClickNextHandler = async () => {
    setAwaitFlag(true);
    await addressValidate(
      `${formik.values.addressTo}, ${formik.values.cityTo},  ${formik.values.stateTo}, ${formik.values.countryTo}, ${formik.values.zipTo}`
    );
    setAwaitFlag(false);

    formik.setTouched({
      ...formik.touched,
      zipTo: true,
      countryTo: true,
      addressTo: true,
      suiteTo: true,
      cityTo: true,
      stateTo: true,
      bedroomsTo: true,
      parkingTo: true,
    });

    const errors = await formik.validateForm();
    if (
      !errors.zipTo &&
      !errors.countryTo &&
      !errors.addressTo &&
      !errors.suiteTo &&
      !errors.cityTo &&
      !errors.stateTo &&
      !errors.bedroomsTo &&
      !errors.parkingTo
    )
      onNext();
  };

  return (
    <OrderFormTemplate
      header='Destination Address'
      status={status}
      onNext={onClickNextHandler}
      onBack={onBack}
      disabled={
        formik.errors.zipTo ||
        !formik.values.zipTo ||
        formik.errors.addressTo ||
        !formik.values.addressTo ||
        formik.errors.cityTo ||
        !formik.values.cityTo ||
        formik.errors.bedroomsTo ||
        !formik.values.bedroomsTo ||
        formik.errors.parkingTo ||
        !formik.values.parkingTo
      }
    >
      <OrderForm45Content
        formik={formik}
        zip='zipTo'
        country='countryTo'
        address='addressTo'
        suite='suiteTo'
        city='cityTo'
        state='stateTo'
        bedrooms='bedroomsTo'
        parking='parkingTo'
      />
      <Spinner show={awaitFlag} />
    </OrderFormTemplate>
  );
}
