import './ShipSmartMilitary.scss';

export default function ShipSmartMilitary({ image }) {
  return (
    <div className='intro-text-ship-smart-military'>
      <div className={'sign ' + image} />
      <h1>
        Welcome to
        <br />
        our&nbsp;booking platform
        <br />
        for&nbsp;Military Personel
      </h1>
      <h4>
        Ship Smart are the&nbsp;experts&nbsp;for&nbsp;specialty
        <br />
        household moves and&nbsp;shipments.
        <br />
        US military personnel, use&nbsp;this&nbsp;page
        <br />
        for discounted pricing
      </h4>
    </div>
  );
}
