export const adminState = {
  init: 'init',
  new: 'new',
  review: 'review',
  booked: 'booked',
  ongoing: 'ongoing',
  completed: 'completed',
  adminRejected: 'adminRejected',
  canceled: 'canceled',
};

const assignState = {
  assigned: 'assigned',
  providerExpired: 'providerExpired',
  providerRejected: 'providerRejected',
  quoted: 'quoted',
  customerExpired: 'customerExpired',
  customerRejected: 'customerRejected',
  booked: 'booked',
  canceled: 'canceled',
};

export const cancelCode = {
  authFailure: 'authFailure',
  betterOption: 'betterOption',
  noNeed: 'noNeed',
  other: 'other',
};

export const getDisplayState = (state, assigns) => {
  switch (state) {
    case adminState.init:
      return 'Draft';
    case adminState.review:
      for (let key in assigns)
        if (
          assigns[key].state === assignState.quoted ||
          assigns[key].state === assignState.customerExpired
        )
          return 'Quoted';
      return 'In Review';
    case adminState.booked:
      return 'Booked';
    case adminState.ongoing:
      return 'In Progress';
    case adminState.canceled:
      return 'Canceled';
    case adminState.completed:
      return 'Complete';
    default:
      return 'Pending';
  }
};
