import './UploadingProgressBar.scss';

export default function UploadingProgressBar({ show, percent }) {
  return (
    <div className={'uploading-progress-bar' + (show ? ' show' : '')}>
      <div className='progress' style={{ width: percent + '%' }} />
      <div className='percent-wrap'>
        <p className='percent'>{`uploaded ${percent}%`}</p>
      </div>
    </div>
  );
}
