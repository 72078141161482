import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import Favicon from 'react-favicon';
import Home from '../components/Home';
import Order from './Order';
import Market from './Market';
import Login from '../components/Login';
import Payment from './Payment';
import { auth } from '../utils/firebase';
import { whiteLabelData } from '../utils/whitelabeling';

const ProtectedRouteRedirect = ({ children, navigateTo }) => {
  const redirect = '?redirect=' + window.location.pathname;

  return (
    <ProtectedRoute navigateTo={navigateTo + redirect}>
      {children}
    </ProtectedRoute>
  );
};

const ProtectedRoute = ({ children, navigateTo }) => {
  const [user, loading, error] = useAuthState(auth);
  if (error) console.error(error);

  if (!user && !loading) return <Navigate to={navigateTo} replace />;
  return <>{children}</>;
};

const router = createBrowserRouter([
  {
    path: '/',
    element: whiteLabelData().home ?? <Home />,
  },
  {
    path: '/order',
    element: (
      <ProtectedRoute navigateTo='/'>
        <Order />
      </ProtectedRoute>
    ),
  },
  {
    path: '/market',
    element: (
      <ProtectedRoute navigateTo='/'>
        <Market />
      </ProtectedRoute>
    ),
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/process-payment/:linkId',
    element: (
      <ProtectedRouteRedirect navigateTo='/login'>
        <Payment />
      </ProtectedRouteRedirect>
    ),
  },
]);

function App() {
  document.title = whiteLabelData().title;
  return (
    <>
      <Favicon url={whiteLabelData().favIconUrl} />
      <RouterProvider router={router} />
    </>
  );
}

export default App;
