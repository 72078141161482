import { useState, useEffect, useContext, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import { MarketContext } from '../containers/Market';
import LabelDate from './LabelDate';
import LabelInput from './LabelInput';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import Radio from './Radio';
import './Availability.scss';

const initialValues = {
  pickup: '',
  destination: '',
  pickupStartAt: new Date(),
  pickupEndAt: new Date(),
  insurance: undefined,
  insuranceCost: '',
  deductible: '',
};

export default function Availability({ status }) {
  const {
    mover,
    availability,
    availabilityCreate,
    availabilityUpdate,
    setCheckStepFlag,
    currentAvailId,
  } = useContext(MarketContext);

  const [spinner, setSpinner] = useState(true);

  const avail = useMemo(
    () => availability?.find((avail) => avail.id === currentAvailId),
    [availability, currentAvailId]
  );

  const saveAvailabilityInfo = async ({
    pickup,
    destination,
    pickupStartAt,
    pickupEndAt,
    insurance,
    insuranceCost,
    deductible,
  }) => {
    pickupStartAt = moment(pickupStartAt).startOf('day').toDate();
    pickupEndAt = moment(pickupEndAt).endOf('day').toDate();

    console.log('saveAvailabilityInfo');

    if (
      avail &&
      avail.pickup === pickup &&
      avail.destination === destination &&
      avail.insurance === insurance &&
      (!insurance || avail.insuranceCost === insuranceCost) &&
      (!insurance || avail.deductible === deductible) &&
      moment(avail.pickupStartAt.toDate()).isSame(pickupStartAt, 'day') &&
      moment(avail.pickupEndAt.toDate()).isSame(pickupEndAt, 'day')
    ) {
      setCheckStepFlag();
      return;
    }

    const saveObj = {
      pickup,
      destination,
      pickupStartAt,
      pickupEndAt,
      insurance,
    };

    if (insurance) {
      saveObj.insuranceCost = insuranceCost;
      saveObj.deductible = deductible;
    }

    try {
      if (avail) {
        await availabilityUpdate({
          availabilityID: avail.id,
          ...saveObj,
        });
      } else {
        await availabilityCreate({
          proID: mover.id,
          ...saveObj,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const validationSchema = new Yup.ObjectSchema({
    pickup: Yup.string().max(100, '100 characters is max').required('Required'),
    destination: Yup.string()
      .max(100, '100 characters is max')
      .required('Required'),
    insuranceCost: Yup.mixed().when('insurance', {
      is: (value) => value === true,
      then: () =>
        Yup.number()
          .test(
            'is-number',
            'Must be a number',
            (value) => value !== undefined && value !== null && !isNaN(value)
          )
          .integer('Not an integer')
          .min(1, 'Must be greater than 0')
          .max(999999999999, 'Number too big'),
    }),
    deductible: Yup.mixed().when('insurance', {
      is: (value) => value === true,
      then: () =>
        Yup.number()
          .test(
            'is-number',
            'Must be a number',
            (value) => value !== undefined && value !== null && !isNaN(value)
          )
          .integer('Not an integer')
          .min(0, 'Must be a positive number or zero')
          .max(999999999999, 'Number too big'),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setSpinner(true);
      await saveAvailabilityInfo(values);
      setSpinner(false);
    },
  });

  useEffect(() => {
    const isLoading = availability === undefined;

    setSpinner(isLoading);

    if (isLoading) {
      formik.setValues(initialValues);
      return;
    }

    formik.resetForm();

    if (avail) {
      formik.setValues({
        pickup: avail.pickup,
        destination: avail.destination,
        pickupStartAt: avail.pickupStartAt.toDate(),
        pickupEndAt: avail.pickupEndAt.toDate(),
        insurance: avail.insurance,
        insuranceCost: avail.insuranceCost || '',
        deductible: avail.deductible || '',
      });
    } else formik.setValues(initialValues);
  }, [availability, avail]);

  return (
    <OrderFormTemplate1Btn
      header='Your availability'
      status={status}
      btnText='CONTINUE'
      btnType='submit'
      form='availability-form'
      spinner={spinner}
      disabled={
        !formik.values.pickup ||
        !formik.values.destination ||
        formik.values.insurance === undefined ||
        (formik.values.insurance &&
          (formik.values.insuranceCost === '' ||
            formik.values.deductible === '')) ||
        Object.keys(formik.errors).length > 0
      }
    >
      <form id='availability-form' onSubmit={formik.handleSubmit}>
        <div className='block'>
          <p>Where are you driving</p>
          <div className='horizont'>
            <LabelInput
              className='width-50'
              label='From*'
              placeholder='Driving from'
              name='pickup'
              {...formik.getFieldProps('pickup')}
              error={formik.touched.pickup && formik.errors.pickup}
            />
            <LabelInput
              className='width-50'
              label='To*'
              placeholder='Driving to'
              name='destination'
              {...formik.getFieldProps('destination')}
              error={formik.touched.destination && formik.errors.destination}
            />
          </div>
        </div>
        <div className='block'>
          <p>When you can pickup the goods</p>
          <div className='horizont'>
            <LabelDate
              label='Earliest'
              className='width-50'
              minDate={new Date()}
              value={formik.values.pickupStartAt}
              onChange={(newVal) => {
                formik.setFieldValue('pickupStartAt', newVal);
                if (newVal > formik.values.pickupEndAt)
                  formik.setFieldValue('pickupEndAt', newVal);
              }}
            />
            <LabelDate
              label='Latest'
              className='width-50'
              minDate={formik.values.pickupStartAt}
              value={formik.values.pickupEndAt}
              onChange={(newVal) => formik.setFieldValue('pickupEndAt', newVal)}
            />
          </div>
        </div>
        <div className='block'>
          <label>Can you provide full coverage insurance?*</label>
          <div className='insurance-radio-wrapper'>
            <Radio
              value='yes'
              label='Yes'
              name='insurance'
              checked={formik.values.insurance === true}
              onChange={async () => {
                await formik.setFieldValue('insurance', true);
                await formik.setFieldValue('insuranceCost', '');
                await formik.setFieldValue('deductible', '');
                formik.setFieldTouched('insuranceCost', false);
                formik.setFieldTouched('deductible', false);
              }}
            />
            <Radio
              value='no'
              label='No'
              name='insurance'
              checked={formik.values.insurance === false}
              onChange={async () => {
                await formik.setFieldValue('insurance', false);
                await formik.setFieldValue('insuranceCost', '');
                await formik.setFieldValue('deductible', '');
              }}
            />
          </div>
          <div className='horizont'>
            <LabelInput
              label='Cost for $20k insurance*'
              placeholder='Cost'
              name='insuranceCost'
              onBlur={formik.handleBlur}
              value={formik.values.insuranceCost}
              error={
                formik.touched.insuranceCost && formik.errors.insuranceCost
              }
              type='number'
              step={1}
              min={1}
              max={999999999999}
              disabled={!formik.values.insurance}
              onChange={(e) =>
                formik.setFieldValue('insuranceCost', e.target.value)
              }
              className='insurance-cost'
            />
            <LabelInput
              label='Your deductible*'
              placeholder='Deductible'
              name='deductible'
              onBlur={formik.handleBlur}
              value={formik.values.deductible}
              error={formik.touched.deductible && formik.errors.deductible}
              type='number'
              step={1}
              min={0}
              max={999999999999}
              disabled={!formik.values.insurance}
              onChange={(e) =>
                formik.setFieldValue('deductible', e.target.value)
              }
              className='insurance-cost'
            />
          </div>
        </div>
      </form>
    </OrderFormTemplate1Btn>
  );
}
