import { useState, useEffect } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import LabelInput from './LabelInput';
import LabelSelect from './LabelSelect';
import LabelAutocomplete from './LabelAutocomplete';
import { apiKey, countries, states } from '../utils/googleMaps';
import { zips } from '../utils/zips';
import { isMuvaFreight } from '../utils/whitelabeling';
import { zipPostCode } from '../utils/miscellaneous';
import './OrderForm45Content.scss';

const parkOptions = {
  Household: ['Street', 'Designated', 'Driveway'],
  Freight: ['Street', 'Designated', 'Loading Dock'],
};

export default function OrderForm45Content({
  formik,
  zip,
  country,
  address,
  suite,
  state,
  city,
  bedrooms,
  parking,
}) {
  const [awaitFlag, setAwaitFlag] = useState(false);
  const [predictions, setPredictions] = useState([]);

  const [parkSel, setParkSel] = useState('');
  const [parkDesc, setParkDesc] = useState('');

  useEffect(() => {
    const park = formik.values[parking];

    if (
      (parkSel === park && parkDesc === '') ||
      (parkSel === 'Other' && parkDesc === park)
    )
      return;

    if (park === '' || parkOptions[formik.values.moveType]?.includes(park)) {
      setParkSel(park);
      setParkDesc('');
    } else {
      setParkSel('Other');
      setParkDesc(park);
    }
  }, [formik.values[parking]]);

  useEffect(() => {
    if (parkSel !== 'Other') {
      formik.setFieldValue(parking, parkSel);
    } else formik.setFieldValue(parking, parkDesc);
  }, [parkSel, parkDesc]);

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey,
    });

  const onCountryChange = (e) => {
    formik.setFieldValue(state, states[e.target.value][0].name);
    formik.handleChange(e);
  };

  useEffect(() => {
    if (placePredictions.length)
      setPredictions(
        placePredictions
          .filter(
            (pred) =>
              pred.description.split(', ').pop() ===
              countries[formik.values[country]]
          )
          .map((pred) => pred.description)
      );
  }, [placePredictions]);

  useEffect(() => {
    if (!isPlacePredictionsLoading) setAwaitFlag(false);
  }, [isPlacePredictionsLoading]);

  const getAutoComplete = async (adr) => {
    if (!adr) return;

    try {
      setAwaitFlag(true);

      const zipData = zips[formik.values[country]][formik.values[zip]];
      if (zipData) {
        getPlacePredictions({
          input: adr ?? formik.values[address],
          locationBias: {
            center: {
              lat: zipData.lat,
              lng: zipData.lng,
            },
            radius: 100000,
          },
        });
      } else setAwaitFlag(false);
    } catch (error) {
      setAwaitFlag(false);
      console.error(error);
    }
  };

  const onAddressChange = async (e) => {
    formik.handleChange(e);
    getAutoComplete(e.target.value);
  };

  const parseAdr = (source) => {
    const completionArr = source.split(', ');
    const length = completionArr.length;

    let address = '',
      city = '',
      state = '';

    switch (formik.values[country]) {
      case 'US':
        address = completionArr.slice(0, length - 3).join(', ');
        city = completionArr[length - 3];
        state = completionArr[length - 2];
        break;
      case 'AU':
        address = completionArr.slice(0, length - 2).join(', ');
        const cityState = completionArr[length - 2].split(' ');
        city = cityState.slice(0, -1).join(' ');
        state = cityState.at(-1);
        break;
      default:
        break;
    }

    return [address, city, state];
  };

  const onAdrSelectHandler = (value) => {
    const [newAddress, newCity, newState] = parseAdr(value);
    const newVal = { ...formik.values };
    newVal[address] = newAddress;
    newVal[city] = newCity;
    newVal[state] = states[formik.values[country]].find(
      (item) => item.abbreviation === newState
    )?.name;
    formik.setFieldTouched(state, true);
    formik.setValues(newVal);
  };

  const onAdrMenuVisibilityChange = (isOpen) => {
    if (isOpen && placePredictions.length === 0) getAutoComplete();
  };

  return (
    <div className='order-form-4-5-wrapper'>
      <div className='row1'>
        <LabelAutocomplete
          name={address}
          label='Address*'
          value={formik.values[address]}
          onSelect={onAdrSelectHandler}
          onChange={onAddressChange}
          onBlur={formik.handleBlur}
          onMenuVisibilityChange={onAdrMenuVisibilityChange}
          error={
            formik.touched[address] && formik.errors[address]
              ? formik.errors[address]
              : null
          }
          items={predictions}
          awaitFlag={awaitFlag}
        />
        <LabelInput
          label='Suite'
          name={suite}
          {...formik.getFieldProps(suite)}
        />
      </div>
      <div className='row2'>
        <LabelInput
          name={city}
          label='City*'
          {...formik.getFieldProps(city)}
          error={
            formik.touched[city] && formik.errors[city]
              ? formik.errors[city]
              : null
          }
        />
        <LabelSelect
          label='State*'
          name={state}
          {...formik.getFieldProps(state)}
          error={
            formik.touched[state] && formik.errors[state]
              ? formik.errors[state]
              : null
          }
        >
          {states[formik.values[country]].map((state, index) => (
            <option key={index} value={state.name}>
              {state.name}
            </option>
          ))}
        </LabelSelect>
      </div>
      <div className='row3'>
        <LabelInput
          label={zipPostCode(formik.values[country]) + '*'}
          name={zip}
          {...formik.getFieldProps(zip)}
          error={
            formik.touched[zip] && formik.errors[zip]
              ? formik.errors[zip]
              : null
          }
        />
        <LabelSelect
          name={country}
          label='Country*'
          onChange={onCountryChange}
          value={formik.values[country]}
          disabled={!isMuvaFreight()}
        >
          <option value='US'>USA</option>
          <option value='AU'>AUS</option>
        </LabelSelect>
      </div>
      <div
        className={
          'row4' + (formik.values.moveType === 'Freight' ? ' freight' : '')
        }
      >
        {formik.values.moveType !== 'Freight' && (
          <LabelSelect
            name={bedrooms}
            label='Bedrooms*'
            className='bedrooms-label-select'
            {...formik.getFieldProps(bedrooms)}
            error={
              formik.touched[bedrooms] && formik.errors[bedrooms]
                ? formik.errors[bedrooms]
                : null
            }
          >
            <option value='' disabled selected></option>
            <option value='0'>0</option>
            <option value='1'>1</option>
            <option value='2'>2</option>
            <option value='3'>3</option>
            <option value='4'>4</option>
            <option value='5'>5</option>
            <option value='6'>6+</option>
          </LabelSelect>
        )}
        <LabelSelect
          label={
            formik.values.moveType === 'Freight'
              ? 'Truck Parking*'
              : 'Mover Parking*'
          }
          name={parking}
          className='parking-label-select'
          value={parkSel}
          onChange={(e) => setParkSel(e.target.value)}
        >
          <option value='' disabled selected></option>
          {parkOptions[formik.values.moveType]?.map((item, i) => (
            <option key={i} value={item}>
              {item}
            </option>
          ))}
          <option value='Other'>Other</option>
        </LabelSelect>
      </div>
      <div className={'row5' + (parkSel === 'Other' ? ' show' : '')}>
        <LabelInput
          value={parkDesc}
          onChange={(e) => setParkDesc(e.target.value)}
          label='Describe*'
          error={
            parkDesc && formik.errors[parking] ? formik.errors[parking] : null
          }
        />
      </div>
    </div>
  );
}
