import LabelInput from './LabelInput';
import Radio from './Radio';
import { accountTypes, accountOwnerTypes } from '../containers/Payment';
import './PaymentFormBank.scss';

export default function PaymentFormBank({ formik }) {
  const onAccountTypeChange = (e) =>
    formik.setFieldValue('account_type', e.target.value);
  const onAccountOwnerTypeChange = (e) =>
    formik.setFieldValue('account_owner_type', e.target.value);

  return (
    <form className='payment-bank-form'>
      <LabelInput
        label='Owner name'
        placeholder='Owner name'
        className='owner-name-input'
        {...formik.getFieldProps('account_owner_name')}
        error={
          formik.touched['account_owner_name'] &&
          formik.errors['account_owner_name']
            ? formik.errors['account_owner_name']
            : null
        }
      />
      <div className='horizont no-mob'>
        <LabelInput
          label='Account number'
          placeholder='Account number'
          className='account-type'
          {...formik.getFieldProps('account_number')}
          error={
            formik.touched['account_number'] && formik.errors['account_number']
              ? formik.errors['account_number']
              : null
          }
        />
        <LabelInput
          label='Routing number'
          placeholder='Routing number'
          className='owner-type'
          {...formik.getFieldProps('routing_number')}
          error={
            formik.touched['routing_number'] && formik.errors['routing_number']
              ? formik.errors['routing_number']
              : null
          }
        />
      </div>
      <div className='horizont'>
        <div className='account-type'>
          <p>Account type</p>
          <Radio
            label='Checking'
            name='account_type'
            value={accountTypes.checking}
            checked={formik.values.account_type === accountTypes.checking}
            onChange={onAccountTypeChange}
          />
          <Radio
            label='Savings'
            name='account_type'
            value={accountTypes.savings}
            checked={formik.values.account_type === accountTypes.savings}
            onChange={onAccountTypeChange}
          />
        </div>
        <div className='owner-type'>
          <p>Owner type</p>
          <Radio
            label='Individual'
            name='account_owner_type'
            value={accountOwnerTypes.individual}
            checked={
              formik.values.account_owner_type === accountOwnerTypes.individual
            }
            onChange={onAccountOwnerTypeChange}
          />
          <Radio
            label='Company'
            name='account_owner_type'
            value={accountOwnerTypes.company}
            checked={
              formik.values.account_owner_type === accountOwnerTypes.company
            }
            onChange={onAccountOwnerTypeChange}
          />
        </div>
      </div>
    </form>
  );
}
