import Container from './Container';
import { Link } from 'react-router-dom';
import { auth } from '../utils/firebase';
import { useSignOut, useAuthState } from 'react-firebase-hooks/auth';
import { whiteLabelData } from '../utils/whitelabeling';
import './Navigation.scss';

export default function Navigation() {
  const [user] = useAuthState(auth);
  const [signOut] = useSignOut(auth);

  return (
    <div className='navigation'>
      <Container>
        <div className={'nav-wrapper' + (!user ? ' no-auth' : '')}>
          <div className={'logo-email-wrapper' + (user ? '' : ' no-user')}>
            <Link to='#'>
              <div
                className='logo'
                style={{
                  backgroundImage: whiteLabelData().logo,
                }}
              />
            </Link>
            {whiteLabelData().email && (
              <div className='email-wrapper'>
                <div className='email-icon' />
                <Link to={`mailto: ${whiteLabelData().email}`}>
                  {whiteLabelData().email}
                </Link>
              </div>
            )}
          </div>
          {user && (
            <div className='user-block'>
              <div className='username-wrapper'>
                <div className='user-icon' />
                <p>{user?.displayName ? user?.displayName : user?.email}</p>
              </div>
              <Link to='#' onClick={() => signOut()}>
                Log out
              </Link>
            </div>
          )}
        </div>
      </Container>
    </div>
  );
}
