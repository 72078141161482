import { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import Button from './Button';
import RecIcon from '../assets/images/rec.png';
import StopRecIcon from '../assets/images/stoprec.png';
import './VideoRecorder.scss';
import ModalPopup from './ModalPopup';

const mimeTypes = [
  'video/mp4;codecs=h264',
  'video/mp4;codecs:h264',
  'video/mp4',
  'video/webm;codecs=vp9',
  'video/webm;codecs:vp9',
  'video/webm;codecs=vp8',
  'video/webm;codecs:vp8',
  'video/webm',
];

export default function VideoRecorder({ onVideoStopRec, show, onClose }) {
  const [showCameraBtn, setShowCameraBtn] = useState(true);
  const [showStartBtn, setShowStartBtn] = useState(false);
  const [showStopBtn, setShowStopBtn] = useState(false);
  const [showAllowMsg, setShowAllowMsg] = useState(false);
  const [showErrMsg, setShowErrMsg] = useState(false);
  const stream = useRef(null);
  const mediaRecorder = useRef(null);
  const blobsRecorded = useRef([]);
  const videoRef = useRef(null);

  useEffect(() => {
    if (show) {
      setShowCameraBtn(true);
      blobsRecorded.current = [];
      videoRef.current.srcObject = null;
    }
  }, [show]);

  const onClickStartCamera = async () => {
    try {
      setShowCameraBtn(false);
      setShowErrMsg(false);
      setShowAllowMsg(true);
      stream.current = await navigator.mediaDevices.getUserMedia({
        video: {
          facingMode: 'environment',
        },
        audio: true,
      });
      setShowAllowMsg(false);
      setShowStartBtn(true);
      videoRef.current.srcObject = stream.current;
    } catch (error) {
      setShowErrMsg(true);
      setShowCameraBtn(true);
      setShowAllowMsg(false);
      console.error('Unable to get media stream');
      console.error(error);
    }
  };

  const onClickStartRec = () => {
    setShowStartBtn(false);
    setShowStopBtn(true);

    const mimeType = mimeTypes.filter((item) =>
      MediaRecorder.isTypeSupported(item)
    )[0];

    mediaRecorder.current = new MediaRecorder(stream.current, {
      mimeType: mimeType,
    });

    mediaRecorder.current.addEventListener('dataavailable', (e) => {
      blobsRecorded.current.push(e.data);
    });

    mediaRecorder.current.addEventListener('stop', () => {
      const videoBlob = new Blob(blobsRecorded.current, {
        type: mimeType,
      });
      onVideoStopRec(videoBlob);
    });

    mediaRecorder.current.start(1000);
  };

  const onClickStopRec = () => {
    setShowStopBtn(false);
    mediaRecorder.current.stop();
    stream.current.getTracks().forEach((track) => track.stop());
  };

  const onClickClose = () => {
    if (mediaRecorder.current) mediaRecorder.current.stop();
    if (stream.current)
      stream.current.getTracks().forEach((track) => track.stop());
    setShowStartBtn(false);
    setShowStopBtn(false);
    setShowCameraBtn(false);
    setShowAllowMsg(false);
    setShowErrMsg(false);
    onClose();
  };

  return (
    <>
      {document.getElementById('order-form') &&
        createPortal(
          <ModalPopup
            show={show}
            onClose={onClickClose}
            className='video-recorder'
          >
            <div className='video-frame'>
              <video
                ref={videoRef}
                id='video'
                width='320'
                height='240'
                autoPlay
                muted='true'
                playsInline
              />
            </div>
            <div className='start-stop-btns-wrapper'>
              <Button
                className={'start-button' + (showStartBtn ? ' show' : '')}
                onClick={onClickStartRec}
                background='var(--primary-color)'
                color='whitesmoke'
                icon={RecIcon}
              >
                START&nbsp;REC
              </Button>
              <Button
                className={'start-button' + (showStopBtn ? ' show' : '')}
                onClick={onClickStopRec}
                text='STOP REC'
                background='var(--primary-color)'
                color='whitesmoke'
                icon={StopRecIcon}
              >
                STOP&nbsp;REC
              </Button>
            </div>
            <div
              className={'horizontal-warning' + (showCameraBtn ? ' show' : '')}
            >
              <p className='please-hold'>
                Please hold camera as shown below
                <br />
                to start video
              </p>
              <div className='how-to-hold-camera'>
                <div className='dont'>
                  <p>Don't:</p>
                  <div className='phone-wrap'>
                    <div className='phone-vert' />
                  </div>
                  <div className='cross-circle' />
                </div>
                <div className='do'>
                  <p>Do:</p>
                  <div className='phone-wrap'>
                    <div className='phone-hor' />
                  </div>
                  <div className='check-circle' />
                </div>
              </div>
            </div>
            <Button
              className={'camera-button' + (showCameraBtn ? ' show' : '')}
              onClick={onClickStartCamera}
            >
              START
              <br />
              CAMERA
            </Button>
            <p className={'allow-msg' + (showAllowMsg ? ' show' : '')}>
              Please allow the browser
              <br />
              to use your camera
            </p>
            <p className={'err-msg' + (showErrMsg ? ' show' : '')}>
              Browser unable
              <br />
              to use camera
            </p>
          </ModalPopup>,
          document.getElementById('order-form')
        )}
    </>
  );
}
