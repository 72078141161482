import './IntroTextMuvaFreight.scss';

export default function IntroTextMuvaFreight() {
  return (
    <div className='intro-text-muva-freight'>
      <h1 className='welcome'>Welcome to Muva Freight</h1>
      <h3>
        Muva technology connects&nbsp;you&nbsp;with&nbsp;the
        <br />
        expert network for all&nbsp;your&nbsp;business
        <br />
        transportation needs
      </h3>
    </div>
  );
}
