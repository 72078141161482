import './LabelTextArea.scss';

export default function LabelTextArea({
  label,
  maxLength,
  placeholder,
  name,
  value,
  onChange,
  onBlur,
  error,
  autoFocus,
  className,
  disabled,
  rows,
  cols,
}) {
  return (
    <div
      className={
        'label-textarea' +
        (disabled ? ' disabled' : '') +
        (className ? ' ' + className : '')
      }
    >
      <label className={error ? 'error' : ''}>
        {error ? label + ': ' + error : label}
      </label>
      <textarea
        ref={(el) => {
          if (autoFocus && el) el.focus();
        }}
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        maxLength={maxLength}
        rows={rows}
        cols={cols}
      />
    </div>
  );
}
