import { useFormik } from 'formik';
import * as Yup from 'yup';
import ModalPopup from './ModalPopup';
import OrderFormTemplate1Btn from './OrderFormTemplate1Btn';
import LabelInput from './LabelInput';
import { emailRegExp } from '../utils/miscellaneous';

const initialValues = {
  email: '',
};

export default function GetEmailPopup({ show, onClose, onClick, header }) {
  const validationSchema = new Yup.ObjectSchema({
    email: Yup.string()
      .matches(emailRegExp, 'Invalid email')
      .required('Required'),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      onClick({ email: values.email });
      onClose();
    },
  });

  return (
    <ModalPopup
      show={show}
      className='login-email-link'
      onClose={() => {
        formik.setTouched({});
        onClose();
      }}
    >
      <OrderFormTemplate1Btn
        header={header}
        btnText='OK'
        onClick={formik.submitForm}
        noMarginTop
        small
        disabled={!formik.values.email || formik.errors.email}
      >
        <LabelInput
          label='Email*'
          type='email'
          name='email'
          {...formik.getFieldProps('email')}
          error={
            formik.touched.email && formik.errors.email
              ? formik.errors.email
              : null
          }
        />
      </OrderFormTemplate1Btn>
    </ModalPopup>
  );
}
